import { translate } from 'language/Language'
import { Component } from 'react'
import * as DefinitionUtils from 'utils/DefinitionUtils'

import {
  Button, Card, Column, DataTable, MetaDataGrid, Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as Utils from 'utils/Utils'

class VerifySearchArgumentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  /**
   * @description Gets the index of a specific header.
   * @param {String} header specific header of the verified output channel
   */
  headerData = header => this.props.verify.header.indexOf(header)

  /**
   * @description Gets the data for the datatable
   */
  getData = () => {
    const { verify } = this.props
    const locationTrans = DefinitionUtils.SEARCH_ARGUMENT_VERIFY_BURST_VALUES
    const buffer = []
    verify.data.forEach(d => {
      const msgText = locationTrans.find(el => el.key === d[this.headerData('MSGTEXT')])
      buffer.push([
        msgText ? translate(msgText.translationKey) : d[this.headerData('MSGTEXT')],
        d[this.headerData('FORM')],
        d[this.headerData('EXT')],
        d[this.headerData('REPORT')],
        d[this.headerData('SMODE')]
      ])
    })
    return buffer
  }

  render = () => {
    const { id, onClose, searchArgument, lang, datemask } = this.props
    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={id}
          title={translate('definition.search_argument_verify')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('definition.searchargument_id'), value: searchArgument[0]['SASID'] },
              { label: translate('general.type'), value: translate(DefinitionUtils.SEARCH_ARGUMENT_TYPES.filter(d => d.key === searchArgument[0].SASTYPE)[0].translationKey) },
              { label: translate('general.title'), value: searchArgument[0]['SASTITLE'] },
            ]}
            columns={4}
          />
        </Header>
        <Main>
          <Card className={Utils.getSpecificContainerStyle()}>
            <Row className={'bux_modal_container'}>
              <Column colMD={12} className={'bux_full_height'}>
                <DataTable
                  id={`${id}_verify`}
                  header={[
                    translate('general.found_in'),
                    translate('general.form'),
                    translate('general.extension'),
                    translate('general.report'),
                    translate('general.search')
                  ]}
                  columnSortDefs={['string', 'string', 'string', 'string', 'string']}
                  data={this.getData()}
                  noAction
                  language={lang}
                  datemask={datemask}
                  translate={key => translate(key)}
                  menu={false}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}


const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    searchArgument: state.definitions.searcharguments.searchArgument,
    verify: state.definitions.searcharguments.verify
  }
}

export default connect(mapStateToProps)(VerifySearchArgumentDialog)