import * as TYPES_DOC_VIEWER from '../types/DocumentViewer.types';

import { getChangedTabsBasedOnIndexData } from './utils';

export const isEqualDocViewerTabsState = (oldState: TYPES_DOC_VIEWER.ITabData[], newState: TYPES_DOC_VIEWER.ITabData[]) => {
  if (oldState.length === newState.length) { // Comparing lengths will ignore changes to fetching state of tab and avoid redundant re-renderings
    const changedIndexTabs = getChangedTabsBasedOnIndexData(oldState, newState);
    return changedIndexTabs.length === 0; // No changes in either count of tabs or index data in tabs results in an "equal" (= true) state and no re-rendering is triggered
  }

  return false;
};