import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'

import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as ResultTableDefinitionActions from 'redux/actions/ResultTableDefinitionActions'

import {translate} from 'language/Language'
import * as Preferences from 'redux/general/Preferences'
import * as UserUtils from 'utils/UserUtils'
import {getAvailableJobTypes} from 'utils/CustomDialogSystemUtils'

import {connect} from 'react-redux'

class DefinitionResultTable extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  }

  defaultState = {
    resultTableID: '',
    description: '',
    owner: '',
    showResultTableDialog: false,
    jobType: 0
  }

  state = {
    ...this.defaultState,
  }

  /**
     * @description Initialize the search values from preferences.
     */
  componentDidMount() {
    // initialize search fields
    const {prefs} = this.props

    if (prefs) {
      this.setState({
        resultTableID: prefs[Preferences.DEFINITION_RESULT_TABLE_ID] || this.defaultState.resultTableID,
        description: prefs[Preferences.DEFINITION_RESULT_TABLE_DESCRIPTION] || this.defaultState.description,
        owner: prefs[Preferences.DEFINITION_RESULT_TABLE_OWNER] || this.defaultState.owner,
        jobType: Math.max([{key: ''}, ...getAvailableJobTypes()].findIndex(e => e.key === prefs[Preferences.DEFINITION_RESULT_TABLE_JOBTYPE]), 0)
      })
    }
  }

  /**
     * @description Resets the search values to default values.
     */
  resetSearchCriteria = () => this.setState(this.defaultState)

  /**
     * @description Handles the changes on inputfield.
     * @param key Key for inputfield in state
     * @param val New value of inputfield
     */
  handleInputChanged = (key, val) => this.setState({[key]: val})

  /**
     * @description Handles the search event. Saves the search values in preferences
     * and call the rest api to search.
     * @param event The event
     */
  handleSubmitSearch = (event) => {
    event.preventDefault()
    const {resultTableID, description, owner, jobType} = this.state

    let type = ''
    if (jobType > -1) {
      type = [{key: ''}, ...getAvailableJobTypes()][jobType].key
    }

    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_RESULT_TABLE_ID]: resultTableID,
      [Preferences.DEFINITION_RESULT_TABLE_DESCRIPTION]: description,
      [Preferences.DEFINITION_RESULT_TABLE_OWNER]: owner,
      [Preferences.DEFINITION_RESULT_TABLE_JOBTYPE]: type
    }

    this.props.changePrefs(prefsToChange)
    this.props.getResultTables(resultTableID, description, owner, type)
  }

  /**
     * @description Renders the general card.
     */
  renderGeneralCard = () => {
    const {id} = this.props
    const {resultTableID, description, owner, jobType} = this.state

    return (
      <Card title={translate('general.general')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_resulttable_resulttableid`}
              onInputChanged={val => this.handleInputChanged('resultTableID', val)}
              value={resultTableID}
              title={translate('definition.result_table_id')}
              maxLength={16}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_resulttable_description`}
              onInputChanged={val => this.handleInputChanged('description', val)}
              value={description}
              title={translate('general.description')}
              maxLength={64}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_resulttable_owner`}
              onInputChanged={val => this.handleInputChanged('owner', val)}
              value={owner}
              title={translate('general.owner')}
              maxLength={8}
            />
          </Column>
          {UserUtils.isLOGX() && (
            <Column colMD={6}>
              <Dropdown
                id={`${id}_jobtype`}
                title={translate('job.jobtype')}
                items={[{translation: 'general.any'}, ...getAvailableJobTypes()].map(({translation}) => translate(translation))}
                onChange={value => this.setState({jobType: value})}
                activeIndex={jobType}
              />
            </Column>
          )}
        </Row>
      </Card>
    )
  }

  /**
     * @description Renders the components which are in main.
     */
  renderMain = () => {
    const {id} = this.props

    return (
      <div
        id={`${id}_resulttable_main`}
        className={'bux_drawer_main'}>
        {/* general card */}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
     * @description Renders the footer.
     */
  renderFooter = () => {
    const {id} = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const {id} = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {/* main */}
        {this.renderMain()}
        {/* footer */}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    selectorResultTable: state.selector.resulttables
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: prefs => {
      PreferenceActions.changePrefs(prefs)(dispatch)
    },
    getResultTables: (resultTableID, description, owner, jobType) => {
      ResultTableDefinitionActions.getResultTables(resultTableID, description, owner, jobType)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionResultTable)