import PropTypes from 'prop-types'
import { Component } from 'react'

// components
import { DataTable, DownloadWrapper, EmptySearchResult, Link, NoSearch, ResultContainer, TableButton } from 'BetaUX2Web-Components/src/'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as LPDQueueActions from 'redux/actions/LPDQueueActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as SortUtils from 'utils/SortUtils'
import { RequeueLpdQueueDialog, RequeueLpdQueueDialogMode } from 'components/dialogs/requeue_lpd_queue_dialog/RequeueLpdQueueDialog'
import { convertIntoObject } from 'utils/Utils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';


const LPD_QUEUE_ID = 'QUEUE'
const STATUS = 'STATUS'
const CLIENT = 'IPCLIENT'
const REQUEST_STATUS = 'STATUSR'
const JOB = 'JOB'
const FILE = 'CONFN'
const SIZE = 'SIZE'
const DATETIME = 'DATETIME'
const RETURNCODE = 'RETURNCODE'
const SPATH = 'SPATH'

const DATE = 'DATE'
const TIME = 'TIME'
const RC = 'RC'
const IRC = 'IRC'
const DFA = 'DFA'
const CFA = 'CFA'
const CONFP = 'CONFP'

class SearchResultDatabaseQuery extends Component {
  state = {
    showTableSettingsDialog: false,
    showRequeueDialog: false,
    requeueDialogData: null,
    header: this.fillHeaderInformation()
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    return [
      { rest: LPD_QUEUE_ID, translation: 'definition.lpd_queue.id', default: true },
      { rest: STATUS, translation: 'general.status', default: true },
      { rest: CLIENT, translation: 'general.client', default: true },
      { rest: REQUEST_STATUS, translation: 'general.request_status', default: true },
      { rest: JOB, translation: 'general.job', default: true },
      { rest: FILE, translation: 'general.file', default: true },
      { rest: SIZE, translation: 'database.size', default: true },
      { rest: DATETIME, translation: 'general.date', default: true },
      { rest: RETURNCODE, translation: 'general.return_code', default: true },
      { rest: SPATH, translation: 'general.spath', default: true },
      { rest: DATE },
      { rest: TIME },
      { rest: RC },
      { rest: IRC },
      { rest: DFA },
      { rest: CFA },
      { rest: CONFP }
    ]
  }

  /**
   * @description Gets the header for the table.
   */
  getHeader = () => this.props.lpdQueues?.header

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_LPD]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_LPD].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_LPD]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_LPD].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    }
    else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />
      ]
    )
  }

  openRerunDialog = (rowIndex) => {
    const { lpdQueues } = this.props
    const data = lpdQueues?.data[rowIndex] || null
    this.setState({ showRequeueDialog: true, requeueDialogData: data, requeueDialogMode: RequeueLpdQueueDialogMode.PRINT })
  }

  openRemoveDialog = (rowIndex) => {
    const { lpdQueues } = this.props
    const data = lpdQueues?.data[rowIndex] || null
    this.setState({ showRequeueDialog: true, requeueDialogData: data, requeueDialogMode: RequeueLpdQueueDialogMode.REMOVE})
  }

  createActionButtons = (rowIndex) => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_table_button_rerun_completly_${rowIndex}`}
        iconName='rerun'
        title={translate('queue.rerun')}
        onClick={() => this.openRerunDialog(rowIndex)}
      />,
      <TableButton
        id={`${id}_table_button_remove_completly_${rowIndex}`}
        iconName='delete'
        title={translate('queue.remove')}
        onClick={() => this.openRemoveDialog(rowIndex)}
      />
    ]
  }

  /**
   * @description gets the index of the header in redux state documents.header
   * @param {String} header header name of the header in redux state documents.header
   */
  headerData = header => this.props.lpdQueues.header.indexOf(header)

  /**
     * @description We need 'clean data' for download as csv (data in textual representation)
     * @returns {Array} The clean data.
     */
  getCleanData = () => {
    const { datemask, lpdQueues } = this.props
    const data = []
    const headers = this.getUsedHeader()
    lpdQueues.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === DATETIME) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData(DATE)].substring(0, 10), el[this.headerData(TIME)].substring(0, 8)))
        }
        else if (header === TIME) {
          buffer.push(DateUtils.getDate(' ', el[this.headerData(DATE)], el[this.headerData(TIME)].substring(0, 8)))
        }
        else if (header === DATE) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData(DATE)], el[this.headerData(TIME)].substring(0, 8), false))
        }
        else if (header === RETURNCODE) {
          buffer.push(`${el[this.headerData(RC)]} - ${el[this.headerData(IRC)]}`)
        }
        else if (header === STATUS) {
          // Maybe we need to handle this value differently in the future and translate it.
          buffer.push(el[this.headerData(STATUS)])
        }
        else if (header === REQUEST_STATUS) {
          const translatedStatus = {
            'SB': translate('queue.spooling_begin'),
            'SE': translate('queue.spooling_end'),
            'SF': translate('queue.spooling_failed'),
            'TB': translate('queue.transfer_begin'),
            'TE': translate('queue.transfer_end'),
            'TF': translate('queue.transfer_failed'),
            'CW': translate('queue.waiting_for_autoprint'),
            'S': translate('definition.lpd_queue.spooling'),
          }[el[this.headerData(REQUEST_STATUS)]]
          buffer.push(translatedStatus || el[this.headerData(REQUEST_STATUS)])
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            buffer.push(`${val}:00`)
          }
          else {
            buffer.push(val)
          }
        }
      })
      data.push(buffer)
    })
    return data
  }

  handleRefresh = () => {
    const { getLPDQueues, preferences } = this.props
    const searchObj = {
      QUEUE: preferences[Preferences.QUEUE_LPD_LPD_QUEUE_ID]
    }
    getLPDQueues(searchObj)
  }

  onCloseRequeueDialog = (succeed) => {
    this.setState({ showRequeueDialog: false }, succeed && this.handleRefresh())
  }
  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  render = () => {
    const { showTableSettingsDialog, showRequeueDialog, requeueDialogData, requeueDialogMode } = this.state
    const { id, drawerExpanded, autoDrawer, lang, datemask, keepPagination, lpdQueues } = this.props
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    const data = lpdQueues?.data ? this.getCleanData() : null

    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_QUEUE_LPD }}
          />
        )}
        {
          showRequeueDialog &&
          <RequeueLpdQueueDialog
            id={`${id}_requeue_output_queue`}
            mode={requeueDialogMode}
            lpdData={convertIntoObject(lpdQueues?.header, requeueDialogData)}
            onClose={() => this.onCloseRequeueDialog()}
          />
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if documents not exist in redux
            lpdQueues
              ? data
                ? <DataTable
                  id={id}
                  header={translatedHeaders}
                  data={data}
                  cleanData={data}
                  selectable={true}
                  columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                  createActionButtons={this.createActionButtons}
                  additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                  fillPage={fillPage}
                  translate={key => translate(key)}
                  language={lang}
                  datemask={datemask}
                  keepPagination={keepPagination}
                />
                : <EmptySearchResult
                  id={`${id}_emptysearchresult`}
                  description={translate('emptysearchresult.description')}
                />
              : <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
          }
        </ResultContainer>
      </>
    )
  }
}

SearchResultDatabaseQuery.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    lpdQueues: state.queue.lpd?.lpdQueues,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLPDQueues: (searchObj, callback) => LPDQueueActions.getLPDQueues(undefined, searchObj, callback)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultDatabaseQuery)