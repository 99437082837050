import * as SearchActions from './SearchActions'


export const GET_CONTROLM_JOBS_START = 'GET_CONTROLM_JOBS_START'
export const GET_CONTROLM_JOBS_SUCCESS = 'GET_CONTROLM_JOBS_SUCCESS'
export const GET_CONTROLM_JOBS_FAILED = 'GET_CONTROLM_JOBS_FAILED'
export const NO_CONTROLM_JOBS_FOUND = 'NO_CONTROLM_JOBS_FOUND'

export const CONTROLM_JOBS_DOWNLOAD_JOB_START = 'CONTROLM_JOBS_DOWNLOAD_JOB_START'
export const CONTROLM_JOBS_DOWNLOAD_JOB_SUCCESS = 'CONTROLM_JOBS_DOWNLOAD_JOB_SUCCESS'
export const CONTROLM_JOBS_DOWNLOAD_JOB_FAILED = 'CONTROLM_JOBS_DOWNLOAD_JOB_FAILED'

export const GET_CONTROLM_JOB_START = 'GET_CONTROLM_JOB_START'
export const GET_CONTROLM_JOB_SUCCESS = 'GET_CONTROLM_JOB_SUCCESS'
export const GET_CONTROLM_JOB_FAILED = 'GET_CONTROLM_JOB_FAILED'

export const GET_CONTROLM_JOB_LOGS_START = 'GET_CONTROLM_JOB_LOGS_START'
export const GET_CONTROLM_JOB_LOGS_SUCCESS = 'GET_CONTROLM_JOB_LOGS_SUCCESS'
export const GET_CONTROLM_JOB_LOGS_FAILED = 'GET_CONTROLM_JOB_LOGS_FAILED'
export const NO_CONTROLM_JOB_LOGS= 'GET_CONTROLM_JOB_LOGS_FAILED'

export const CONTROLM_GET_JOB_LOG_INFORMATION_START = 'CONTROLM_GET_JOB_LOG_INFORMATION_START'
export const CONTROLM_GET_JOB_LOG_INFORMATION_SUCCESS = 'CONTROLM_GET_JOB_LOG_INFORMATION_SUCCESS'
export const CONTROLM_GET_JOB_LOG_INFORMATION_FAILED = 'CONTROLM_GET_JOB_LOG_INFORMATION_FAILED'

export const CONTROLM_JOBS_GET_LOG_START = 'CONTROLM_JOBS_GET_LOG_START'
export const CONTROLM_JOBS_GET_LOG_SUCCESS = 'CONTROLM_JOBS_GET_LOG_SUCCESS'
export const CONTROLM_JOBS_GET_LOG_FAILED = 'CONTROLM_JOBS_GET_LOG_FAILED'

export const CONTROLM_JOBS_GET_LOG_PRINT_INFO_START = 'CONTROLM_JOBS_GET_LOG_PRINT_INFO_START'
export const CONTROLM_JOBS_GET_LOG_PRINT_INFO_SUCCESS = 'CONTROLM_JOBS_GET_LOG_PRINT_INFO_SUCCESS'
export const CONTROLM_JOBS_GET_LOG_PRINT_INFO_FAILED = 'CONTROLM_JOBS_GET_LOG_PRINT_INFO_FAILED'

export const CONTROLM_JOBS_DOWNLOAD_LOG_START = 'CONTROLM_JOBS_DOWNLOAD_LOG_START'
export const CONTROLM_JOBS_DOWNLOAD_LOG_SUCCESS = 'CONTROLM_JOBS_DOWNLOAD_LOG_SUCCESS'
export const CONTROLM_JOBS_DOWNLOAD_LOG_FAILED = 'CONTROLM_JOBS_DOWNLOAD_LOG_FAILED'

export const CONTROLM_LOG_UPDATE_LOG_START = 'CONTROLM_LOG_UPDATE_LOG_START'
export const CONTROLM_LOG_UPDATE_LOG_SUCCESS = 'CONTROLM_LOG_UPDATE_LOG_SUCCESS'
export const CONTROLM_LOG_UPDATE_LOG_FAILED = 'CONTROLM_LOG_UPDATE_LOG_FAILED'


export function getControlMJobs(fields, searchObj, callback = undefined, keepPagination = false) {
  return dispatch => {
    SearchActions.getJobs(fields, searchObj, callback, keepPagination, {
      START: GET_CONTROLM_JOBS_START,
      SUCCESS: GET_CONTROLM_JOBS_SUCCESS,
      FAILED: GET_CONTROLM_JOBS_FAILED,
      NOT_FOUND: NO_CONTROLM_JOBS_FOUND,
      error: 'search.controlm_loading_error'
    })(dispatch)
  }
}

export function downloadJob(fields, searchObj, callback) {
  return dispatch => {
    SearchActions.downloadJob(fields, searchObj, callback, {
      START: CONTROLM_JOBS_DOWNLOAD_JOB_START,
      SUCCESS: CONTROLM_JOBS_DOWNLOAD_JOB_SUCCESS,
      FAILED: CONTROLM_JOBS_DOWNLOAD_JOB_FAILED,
    })(dispatch)
  }
}

export function displayJob(bwebaseurl, bwesystemname, startDate, startTime, systemName, runNumber, ukey, callback) {
  return dispatch => {
    SearchActions.displayJob(bwebaseurl, bwesystemname, startDate, startTime, systemName, runNumber, ukey, callback)(dispatch)
  }
}

export function getCtmJobDetails(fields, jobObj, callback) {
  return dispatch => {
    SearchActions.getJobDetails(fields, jobObj, callback, {
      START: GET_CONTROLM_JOB_START,
      SUCCESS: GET_CONTROLM_JOB_SUCCESS,
      FAILED: GET_CONTROLM_JOB_FAILED,
      error: 'search.ctm_details_error'
    })(dispatch)
  }
}

export function getControlMJobLogs(jobObj, callback) {
  return dispatch => {
    SearchActions.getJobLogs(jobObj, callback, {
      START: GET_CONTROLM_JOB_LOGS_START,
      SUCCESS: GET_CONTROLM_JOB_LOGS_SUCCESS,
      FAILED: GET_CONTROLM_JOB_LOGS_FAILED,
      NOT_FOUND: NO_CONTROLM_JOB_LOGS,
      error: 'search.zos_logs_loading_error'
    })(dispatch)
  }
}

export function displayLog(docid, betaviewBaseurl, betaviewFavname, ukey, callback) {
  return dispatch => {
    SearchActions.displayLog(docid, betaviewBaseurl, betaviewFavname, ukey, callback)(dispatch)
  }
}

export function getJobLogInformation(fields, joblogObj, callback) {
  return dispatch => {
    SearchActions.getJobLogInformation(fields, joblogObj, callback, {
      START: CONTROLM_GET_JOB_LOG_INFORMATION_START,
      SUCCESS: CONTROLM_GET_JOB_LOG_INFORMATION_SUCCESS,
      FAILED: CONTROLM_GET_JOB_LOG_INFORMATION_FAILED,
      error: 'search.zos_details_information_error'
    })(dispatch)
  }
}


export function getLog(fields, docid, process, callback) {
  return dispatch => {
    SearchActions.getLog(fields, docid, process, callback, {
      START: CONTROLM_JOBS_GET_LOG_START,
      SUCCESS: CONTROLM_JOBS_GET_LOG_SUCCESS,
      FAILED: CONTROLM_JOBS_GET_LOG_FAILED,
    })(dispatch)
  }
}

export function getPrintInfo(docids, callback) {
  return dispatch => {
    SearchActions.getPrintInfo(docids, callback, {
      START: CONTROLM_JOBS_GET_LOG_PRINT_INFO_START,
      SUCCESS: CONTROLM_JOBS_GET_LOG_PRINT_INFO_SUCCESS,
      FAILED: CONTROLM_JOBS_GET_LOG_PRINT_INFO_FAILED,
    })(dispatch)
  }
}

export function downloadLog(docid, downloadname, callback) {
  return dispatch => {
    SearchActions.downloadLog(docid, downloadname, callback, {
      START: CONTROLM_JOBS_DOWNLOAD_LOG_START,
      SUCCESS: CONTROLM_JOBS_DOWNLOAD_LOG_SUCCESS,
      FAILED: CONTROLM_JOBS_DOWNLOAD_LOG_FAILED,
    })(dispatch)
  }
}

export function downloadLogText(docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback) {
  return dispatch => {
    SearchActions.downloadLogText(docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback, {
      START: CONTROLM_JOBS_DOWNLOAD_LOG_START,
      SUCCESS: CONTROLM_JOBS_DOWNLOAD_LOG_SUCCESS,
      FAILED: CONTROLM_JOBS_DOWNLOAD_LOG_FAILED,
    })(dispatch)
  }
}

export function downloadLogHex(docid, downloadname, displayMode, showPageHeader, showResources, callback) {
  return dispatch => {
    SearchActions.downloadLogHex(docid, downloadname, displayMode, showPageHeader, showResources, callback, {
      START: CONTROLM_JOBS_DOWNLOAD_LOG_START,
      SUCCESS: CONTROLM_JOBS_DOWNLOAD_LOG_SUCCESS,
      FAILED: CONTROLM_JOBS_DOWNLOAD_LOG_FAILED,
    })(dispatch)
  }
}

export function markArchive(logDefinition, searchObj, callback) {
  return dispatch => {
    SearchActions.markArchive(logDefinition, searchObj, callback, {
      START: CONTROLM_LOG_UPDATE_LOG_START,
      SUCCESS: CONTROLM_LOG_UPDATE_LOG_SUCCESS,
      FAILED: CONTROLM_LOG_UPDATE_LOG_FAILED,
    })(dispatch)
  }
}

export function markDelete(logDefinition, searchObj, callback) {
  return dispatch => {
    SearchActions.markDelete(logDefinition, searchObj, callback, {
      START: CONTROLM_LOG_UPDATE_LOG_START,
      SUCCESS: CONTROLM_LOG_UPDATE_LOG_SUCCESS,
      FAILED: CONTROLM_LOG_UPDATE_LOG_FAILED,
    })(dispatch)
  }
}