import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'

import { DownloadWrapper, Link, TableButton } from 'BetaUX2Web-Components/src/'
import SyslogJobInformationDialog from 'components/dialogs/syslog_job_information_dialog/SyslogJobInformationDialog'
import * as SEARCH_CONSTANTS from 'components/drawer/content/search/search_body/search_standard_search/SearchStandardSearch'
import { SelectionTable } from '../common/SelectionTable';
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'

import * as Preferences from 'redux/general/Preferences'
import * as DocViewerActions from 'redux/actions/DocViewerActions'
import * as SearchSyslogActions from 'redux/actions/SearchSyslogActions'

import { EResultTableType, getColumnsObjects, translateHeaderColumns } from 'utils/ColumnUtils'
import * as CommonUtils from 'utils/CommonUtils'
import * as DateUtils from 'utils/DateUtils'
import { SEARCH_TYPE_SYSLOG_ALL } from 'utils/DocViewerUtils'
import * as SortUtils from 'utils/SortUtils'

const JOBNAME = 'JOBNAME'
const SYSTEM = 'SYSNAME'
const READERDATE = 'SRCSUBDT'
const JOBGROUP = 'JOBGROUP'
const JOBID = 'JOBID'

class SearchResultSyslog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }

  state = {
    header: this.fillHeaderInformation(),
    showTableSettingsDialog: false,
    showJobInfoDialog: false,
    translatedHeaders: [],
    settingsTranslatedHeaders: [],
  }

  updateHeadersTranslation() {
    const header = this.getUsedHeader()

    translateHeaderColumns(header, EResultTableType.BRWSYSL).then(translatedHeaders => {
      this.setState({translatedHeaders})
    })

    translateHeaderColumns(this.state.header.map(v => v.rest), EResultTableType.BRWSYSL).then(translatedHeaders => {
      const settingsTranslatedHeaders = translatedHeaders.map((v, index) => {
        return {
          rest: this.state.header[index].rest,
          translated: v
        }
      })
      this.setState({settingsTranslatedHeaders})
    })

  }

  componentDidMount() {
    this.updateHeadersTranslation()
    this.setState({ header: this.fillHeaderInformation() })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const prevHeaders = prevProps.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION]?.displayedHeaders
    const currHeaders = this.props.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION]?.displayedHeaders

    const headersChanged = prevHeaders && currHeaders && prevHeaders !== currHeaders
    const langChanged = prevProps.lang !== this.props.lang

    if (headersChanged || langChanged) this.updateHeadersTranslation()

    if (headersChanged || langChanged) {
      this.updateHeadersTranslation()
    }

    if (this.props.syslogJobs !== prevProps.syslogJobs) {
      this.setState({ header: this.fillHeaderInformation() })
    }

    if (prevState.header.length === 0 && this.state.header.length !== 0) {
      this.updateHeadersTranslation();
    }
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    const { syslogJobs } = this.props
    const defaultOrderedHeaders = [JOBNAME, SYSTEM, READERDATE, JOBGROUP, JOBID]

    return CommonUtils.hasProperties(syslogJobs) ? getColumnsObjects(syslogJobs.header, defaultOrderedHeaders) : []
  }

  /**
   * @description Refreshes the table.
   */
  handleRefresh = () => {
    const { preferences, getSyslogJobs } = this.props
    const activeTabIndex = preferences[Preferences.SEARCH_SYSLOG_ACTIVE_TAB]
    let lastTimeMode = preferences[Preferences.SEARCH_SYSLOG_LASTTIME_MODE]
    let sdate = ''
    let stime = ''
    let edate = ''
    let etime = ''
    let customLast = ''
    let customUnit = ''

    if (activeTabIndex === 0) {
      if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_TODAY || lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_YESTERDAY) {
        sdate = preferences[Preferences.SEARCH_SYSLOG_LASTTIME_MODE].toUpperCase()
      } else if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_CUSTOM) {
        customLast = preferences[Preferences.SEARCH_SYSLOG_TIME_CUSTOM_LAST]
        customUnit = preferences[Preferences.SEARCH_SYSLOG_TIME_CUSTOM_UNIT]
      }
    } else if (activeTabIndex === 1) {
      sdate = preferences[Preferences.SEARCH_SYSLOG_START_DATE] !== '' ? moment(preferences[Preferences.SEARCH_SYSLOG_START_DATE], DateUtils.DDMMYYYY_DOT).format(DateUtils.DDMMYYYY_DOT) : ''
      stime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_SYSLOG_START_TIME])
      edate = preferences[Preferences.SEARCH_SYSLOG_END_DATE] !== '' ? moment(preferences[Preferences.SEARCH_SYSLOG_END_DATE], DateUtils.DDMMYYYY_DOT).format(DateUtils.DDMMYYYY_DOT) : ''
      etime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_SYSLOG_END_TIME])
    }

    const searchParams = {
      FROMLAST: customLast,
      TUNITS: customUnit,
      SDATE: sdate,
      STIME: stime,
      EDATE: edate,
      ETIME: etime,
      LOGSOURCE: 'SYSL',
      JOBGROUP: preferences[Preferences.SEARCH_SYSLOG_JOBGROUP],
      JOBID: preferences[Preferences.SEARCH_SYSLOG_JOBID],
      SYSTEMNAME: preferences[Preferences.SEARCH_SYSLOG_SYSTEM],
      RUNUSER: preferences[Preferences.SEARCH_SYSLOG_SUBMIT_USER]
    }

    getSyslogJobs(searchParams)
  }

  /**
   * @description gets the index of the header in redux state documents.header
   * @param {String} header header name of the header in redux state documents.header
   */
  headerData = header => this.props.syslogJobs.header.indexOf(header)

  /**
  * @description Gets the fill page info.
  * @returns {Boolean} The fill page info.
  */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_SYSLOG]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_SYSLOG].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_SYSLOG]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_SYSLOG].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to show.
   * @param {Array} header The headers of the table.
   * @returns {Array} The column sort definitions.
   */
  getColumnSortDefs = (data, header) => {
    return SortUtils.getSortTypes(data, header.length)
  }

  /**
    * @description Handles the download functionality of logs in binary format.
    * @param {Number} rowIndex The current row.
    */
  handleJobDownloadSyslog = rowIndex => {
    const { syslogJobs, downloadJobSyslog } = this.props
    const ukey = syslogJobs.data[rowIndex][this.headerData('UKEY')]
    const jobname = syslogJobs.data[rowIndex][this.headerData('JOBNAME')]
    const jobid = syslogJobs.data[rowIndex][this.headerData('JOBID')]
    const queryParams = {
      LOGSOURCE: 'SYSL',
      UKEY: ukey
    }

    // Generate name which will be suggested as download-filename
    const filename = [jobname, jobid].filter(entry => entry !== undefined).join('.') + '.log'
    downloadJobSyslog(queryParams, filename)
  }

  handleDisplayJobInformation = rowIndex => {
    const { syslogJobs, getSyslogJobDetails, getSyslogJobLogs } = this.props
    const jobObj = {
      LOGSOURCE: 'SYSL',
      SRCSUBD: syslogJobs.data[rowIndex][this.headerData('SRCSUBD')],
      SRCSUBT: syslogJobs.data[rowIndex][this.headerData('SRCSUBT')],
      JOBNAME: syslogJobs.data[rowIndex][this.headerData('JOBNAME')],
      JOBID: syslogJobs.data[rowIndex][this.headerData('JOBID')]
    }

    getSyslogJobDetails(jobObj, () => {
      getSyslogJobLogs(jobObj, () => this.setState({ showJobInfoDialog: true }))
    })
  }

  /**
   * @description This function will open all selected rows as tabs in the DocViewer.
   * @param {Array} selectedRowsIndices This array contains the indices of the selected rows.
   */
  openSelectedRowsInDocViewer = (selectedRowsIndices) => {
    selectedRowsIndices.forEach(rowIndex => this.openDocumentViewer(rowIndex))
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewer = (rowIndex) => {
    const { addTabToDocViewer, syslogJobs } = this.props

    addTabToDocViewer({
      id: syslogJobs.data[rowIndex][this.headerData('UKEY')],
      type: SEARCH_TYPE_SYSLOG_ALL,
      header: syslogJobs.header,
      data: syslogJobs.data[rowIndex],
      pageCount: 0 // Will be populated in document viewer to avoid callback problems with multi-display
    })
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => this.handleDisplayJobInformation(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${rowIndex}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => this.openDocumentViewer(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButton_download_${rowIndex}`}
        iconType={'material'}
        iconName={'download'}
        title={translate('general.download')}
        onClick={() => this.handleJobDownloadSyslog(rowIndex)}
      />
    ]
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   * @returns {Array} The clean data.
   */
  getCleanData = () => {
    const { syslogJobs, datemask } = this.props
    const data = []
    const headers = this.getUsedHeader()
    syslogJobs.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === READERDATE) {
          if (el[this.headerData(READERDATE)] !== '') {
            const [date, time] = el[this.headerData(READERDATE)].split(' ')
            buffer.push(DateUtils.getDate(datemask, date, time))
          } else {
            buffer.push(el[this.headerData(header)])
          }
        } else {
          buffer.push(el[this.headerData(header)])
        }
      })
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader.
   * @returns {Array} The interaction buttons of the table above the headers.
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={() => { this.handleRefresh() }}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={data}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  render = () => {
    const { id, drawerExpanded, autoDrawer, keepPagination, syslogJobs } = this.props
    const { showTableSettingsDialog, showJobInfoDialog } = this.state
    const header = this.getUsedHeader()
    const fillPage = this.getFillPageInfo()
    const data = syslogJobs && syslogJobs.data ? this.getCleanData() : null

    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.settingsTranslatedHeaders.length ? this.state.settingsTranslatedHeaders : this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_SEARCH_SYSLOG }}
          />
        )}
        {showJobInfoDialog && (
          <SyslogJobInformationDialog
            id={id}
            onClose={() => this.setState({ showJobInfoDialog: false })}
            history={this.props.history}
          />
        )}
        <SelectionTable
          id={this.props.id}
          headers={header}
          documents={syslogJobs}
          keepPagination={keepPagination}
          fillPage={fillPage}
          createActionButtons={this.createActionButtons}
          createTableRowAction={this.handleDisplayJobInformation}
          additionalInteraction={this.createInteractionButtons(data, this.state.translatedHeaders)}
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}
          openDocViewerCallback={this.openSelectedRowsInDocViewer}
          resultTableType={EResultTableType.BRWSYSL}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    keepPagination: state.search.syslog.keepPagination,
    usertoken: state.auth.serverdata.token,
    syslogJobs: state.search.syslog.syslogJobs,
    syslogJobDetails: state.search.syslog.syslogJobDetails,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    bwesystemname: state.auth.serverdata.bwesystemname,
    bwebaseurl: state.auth.serverdata.bwebaseurl,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSyslogJobs: (searchObj, callback) => SearchSyslogActions.getSyslogJobs(undefined, searchObj, callback)(dispatch),
    downloadJobSyslog: (queryParams, downloadname, callback) => SearchSyslogActions.downloadJob(queryParams, downloadname, callback)(dispatch),
    displayJobSyslog: (betaviewBaseurl, betaviewFavname, ukey, callback) => SearchSyslogActions.displayJob(betaviewBaseurl, betaviewFavname, ukey, callback)(dispatch),
    getSyslogJobDetails: (jobObj, callback) => SearchSyslogActions.getSyslogJobDetails(undefined, jobObj, callback)(dispatch),
    getSyslogJobLogs: (jobObj, callback) => SearchSyslogActions.getSyslogJobLogs(jobObj, callback)(dispatch),
    addTabToDocViewer: (tabData, callback) => {
      DocViewerActions.addTabToDocViewer(tabData, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultSyslog))