import { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// Style
import './UserProfileDialog.scss'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import * as UserUtils from 'utils/UserUtils'

// Components
import {
  Button, Checkbox, Column, Dropdown, Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row,
  Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import * as UserProfileActions from 'redux/actions/UserProfileActions'
import * as Preferences from 'redux/general/Preferences'

class UserProfileDialog extends Component {
  state = {
    basicTab: {
      subadmin: false,
      startPanelIndex: 0,
      betaViewRole: {
        value: '',
        errorkey: '',
      },
      rerunAutoprint: false,
      rerunBundleRequest: false,
      modifyReloadRequest: false,
      modifyArchiveRequest: false,
      modifyDeleteRequest: false,
      modifyRetentionPeriod: false,
      modifyStatus: false,
    },
    viewTab: {
      folder: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      recipient: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      form: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      extension: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      report: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      jobname: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
    },
    printTab: {
      outputChannel: {
        value: '',
        errorkey: '',
        required: true,
        disabled: false,
      },
      outputFormat: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      postProcessingNote: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      recipient: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      printLimitPage: 0,
      printParameterIndex: 0,
      allowHighPrioPrint: false,
    },
    outputQueueTab: {
      enableOutputQueue: false,
      selectOutputChannel: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      requeueOutputChannel: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
    },
    importTab: {
      enableStandardImport: false,
      form: {
        value: '',
        errorkey: '',
        required: true,
        disabled: false,
      },
      extension: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      jobname: {
        value: '',
        errorkey: '',
        required: true,
        disabled: false,
      },
    },
    otherTab: {
      enableMonitor: false,
      outputChannel: {
        value: '',
        errorkey: '',
        required: false,
        disabled: false,
      },
      ouputChannelRequired: false,
      ouputChannelDisabled: false,
      enableMessageLog: false,
      enableExternalCommands: false,
    },
  }

  /**
   * @description Initializes state values.
   */
  componentDidMount = () => {
    /* If userProfileToModify exists. We are in "Modify" mode otherwise in create */
    if (this.props.userProfileToModify) {
      this.userProfileToState(this.props.userProfileToModify)
    }
  }

  /**
   * @description gets the keys of userprofile VCISTART
   */
  userprofileStartKeys = () => {
    const { basicTab, importTab, outputQueueTab, otherTab } = this.state
    const startPanelRoutes = ['BRW', 'IDX']
    if (basicTab.subadmin) {
      if (importTab.enableStandardImport) {
        startPanelRoutes.push('IMP')
      }
      if (outputQueueTab.enableOutputQueue) {
        if (UserUtils.isDOCX()) {
          startPanelRoutes.push('PRT', 'BST')
        }
        else {
          startPanelRoutes.push('PRT')
        }
      }
      if (otherTab.enableMessageLog) {
        startPanelRoutes.push('MSG', 'PDI')
      }
    } else {
      if (UserUtils.isDOCX()) {
        startPanelRoutes.push('IMP', 'PRT', 'BST', 'MSG', 'PDI')
      }
      else {
        startPanelRoutes.push('IMP', 'PRT', 'MSG', 'PDI')
      }
    }
    return startPanelRoutes
  }

  /**
   * @description Handles the checkboxes that enables the startpanel dropdown (enableStandardImport, enableOutputQueue, enableMessageLog)
   * @param {String} tab
   * @param {String} id
   * @param {Boolean} value
   */
  handleCheckboxForStartpanels = (tab, id, value, startPanelKey) => {
    this.setState({
      [tab]: {
        ...this.state[tab],
        [id]: value
      }
    }, () => {
      const startPanelKeyIndex = this.userprofileStartKeys().indexOf(startPanelKey)
      let newStartPanelIndex
      if (startPanelKeyIndex !== -1) {
        newStartPanelIndex = startPanelKeyIndex
      }
      else {
        newStartPanelIndex = 0
      }
      if (this.state.startPanelIndex !== newStartPanelIndex) {
        this.setState({
          basicTab: {
            ...this.state.basicTab,
            startPanelIndex: newStartPanelIndex
          }
        })
      }
    })
  }

  /* ************************************************************************************** */
  // Basic tab

  /**
   * @description Changes the value of a checkbox in basic tab.
   * @param {String} id The id of the checkbox which will changed. This string must be the same as the field in state.
   * @param {Boolean} value The new value of the checkbox.
   */
  onBasicTabCheckboxChange = (id, value) => {
    this.setState({
      basicTab: {
        ...this.state.basicTab,
        [id]: value
      }
    })
  }

  /**
   * @description Chaanges the value of the subadmin checkbox.
   * @param {Boolean} val The new value of the checkbox.
   */
  handleSubadminCheck = val => {
    this.setState({
      basicTab: {
        ...this.state.basicTab,
        subadmin: val
      }
    }, () => {
      !this.state.basicTab.subadmin && this.setState({
        basicTab: {
          ...this.state.basicTab,
          startPanelIndex: 0
        }
      })
    })
  }

  /**
   * @description Changes the start panel in basic tab.
   * @param {Number} index The new selected position.
   */
  handleBasicTabStartPanelChange = (index) => {
    this.setState({
      basicTab: {
        ...this.state.basicTab,
        startPanelIndex: index,
      }
    })
  }

  /**
   * @description Changes the beta view role in basic tab.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleBasicTabBetaViewRoleChange = (value, errorkey) => {
    this.setState({
      basicTab: {
        ...this.state.basicTab,
        betaViewRole: {
          value: value,
          errorkey: errorkey,
        }
      }
    })
  }

  /**
   * @description Gets the translated items for the startpanel dropdown in basic tab.
   */
  getTranslatedBasicTabStartPanelItems = () => {
    const { basicTab, importTab, outputQueueTab, otherTab } = this.state
    let items = [
      translate('usermanagement.userprofile_basic_tab.standard_selection'),
      translate('usermanagement.userprofile_basic_tab.search_dialogs'),
    ]
    if (basicTab.subadmin) {
      if (importTab.enableStandardImport) {
        items.push(translate('usermanagement.userprofile_basic_tab.standard_import'))
      }
      if (outputQueueTab.enableOutputQueue) {
        UserUtils.isDOCX() && items.push(translate('usermanagement.userprofile_basic_tab.bundle_queue'))
        items.push(translate('usermanagement.userprofile_basic_tab.output_queue'))
      }
      if (otherTab.enableMessageLog) {
        items.push(translate('usermanagement.userprofile_basic_tab.message_log'))
        UserUtils.isDOCX() && items.push(translate('usermanagement.userprofile_basic_tab.printer_device_info'))
      }
    } else {
      items = [translate('usermanagement.userprofile_basic_tab.standard_selection')]
    }

    return items
  }

  /**
   * @description Renders the basic tab.
   */
  renderBasicTab = () => {
    const { id, lang } = this.props
    const { basicTab } = this.state

    return (
      <Fragment>
        <Row className={'bux_remove_padding'}>
          <Column
            colMD={6}
            removePadding>
            <Row isTitle>
              <Column colMD={12}>
                <label id={`${id}_basic_tab`}>
                  {translate('general.general', lang)}
                </label>
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <Checkbox
                  id={`${id}_basic_tab_subadmin`}
                  label={translate('usermanagement.userprofile_basic_tab.subadmin', lang)}
                  value={basicTab.subadmin}
                  onCheck={isChecked => this.handleSubadminCheck(isChecked)}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <Dropdown
                  id={`${id}_basic_tab_startpanel`}
                  items={this.getTranslatedBasicTabStartPanelItems()}
                  activeIndex={basicTab.startPanelIndex}
                  onChange={this.handleBasicTabStartPanelChange}
                  title={translate('usermanagement.userprofile_basic_tab.start_panel', lang)}
                  disabled={!basicTab.subadmin}
                  tooltip={!basicTab.subadmin && translate('usermanagement.user_subadmin_required')}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <Input
                  id={`${id}_basic_tab_betaview_role`}
                  value={basicTab.betaViewRole.value}
                  title={translate('usermanagement.userprofile_basic_tab.betaview_role', lang)}
                  maxLength={32}
                  onInputChanged={this.handleBasicTabBetaViewRoleChange}
                  error={
                    basicTab.betaViewRole.errorkey
                      ? translate(basicTab.betaViewRole.errorkey, lang)
                      : null
                  }
                />
              </Column>
            </Row>
          </Column>
          {/* right screen side (permissions) */}
          <Column
            id={`${id}_permissions`}
            colMD={6}
            offset_md={0}>
            {/* permission title */}
            <label id={`${id}_permissions`}>
              {translate('usermanagement.userprofile_basic_tab.permissions', lang)}
            </label>
            {/* rerun autoprint */}
            <Checkbox
              id={`${id}_basic_tab_rerun_autoprint`}
              label={translate('usermanagement.userprofile_basic_tab.rerun_autoprint', lang)}
              value={basicTab.rerunAutoprint}
              onCheck={(isChecked) => { this.onBasicTabCheckboxChange('rerunAutoprint', isChecked) }}
              disabled={!basicTab.subadmin}
              tooltip={!basicTab.subadmin && translate('usermanagement.user_subadmin_required')}
            />
            {/* rerun bundle request */}
            {UserUtils.isDOCX() &&
              <Checkbox
                id={`${id}_basic_tab_rerun_bundle`}
                label={translate('usermanagement.userprofile_basic_tab.rerun_bundle_request', lang)}
                value={basicTab.rerunBundleRequest}
                onCheck={(isChecked) => { this.onBasicTabCheckboxChange('rerunBundleRequest', isChecked) }}
                disabled={!basicTab.subadmin}
                tooltip={!basicTab.subadmin && translate('usermanagement.user_subadmin_required')}
              />
            }
            {/* modify reload request */}
            <Checkbox
              id={`${id}_basic_tab_modify_reload`}
              label={translate('usermanagement.userprofile_basic_tab.modify_reload_request', lang)}
              value={basicTab.modifyReloadRequest}
              onCheck={(isChecked) => { this.onBasicTabCheckboxChange('modifyReloadRequest', isChecked) }}
              disabled={!basicTab.subadmin}
              tooltip={!basicTab.subadmin && translate('usermanagement.user_subadmin_required')}
            />
            {/* modify archive request */}
            <Checkbox
              id={`${id}_basic_tab_modify_archive_retetion`}
              label={translate('usermanagement.userprofile_basic_tab.modify_archive_request', lang)}
              value={basicTab.modifyArchiveRequest}
              onCheck={(isChecked) => { this.onBasicTabCheckboxChange('modifyArchiveRequest', isChecked) }}
              disabled={!basicTab.subadmin}
              tooltip={!basicTab.subadmin && translate('usermanagement.user_subadmin_required')}
            />
            {/* modify delete request */}
            <Checkbox
              id={`${id}_basic_tab_modify_delete_request`}
              label={translate('usermanagement.userprofile_basic_tab.modify_delete_request', lang)}
              value={basicTab.modifyDeleteRequest}
              onCheck={(isChecked) => { this.onBasicTabCheckboxChange('modifyDeleteRequest', isChecked) }}
              disabled={!basicTab.subadmin}
              tooltip={!basicTab.subadmin && translate('usermanagement.user_subadmin_required')}
            />
            {/* modify retention period */}
            <Checkbox
              id={`${id}_basic_tab_modify_retention`}
              label={translate('usermanagement.userprofile_basic_tab.modify_retention_period', lang)}
              value={basicTab.modifyRetentionPeriod}
              onCheck={(isChecked) => { this.onBasicTabCheckboxChange('modifyRetentionPeriod', isChecked) }}
              disabled={!basicTab.subadmin}
              tooltip={!basicTab.subadmin && translate('usermanagement.user_subadmin_required')}
            />
            {/* modify status */}
            <Checkbox
              id={`${id}_basic_tab_modify_status`}
              label={translate('usermanagement.userprofile_basic_tab.modify_status', lang)}
              value={basicTab.modifyStatus}
              onCheck={(isChecked) => { this.onBasicTabCheckboxChange('modifyStatus', isChecked) }}
              disabled={!basicTab.subadmin}
              tooltip={!basicTab.subadmin && translate('usermanagement.user_subadmin_required')}
            />
          </Column>
        </Row>
      </Fragment>
    )
  }
  /* ************************************************************************************ */
  // View tab

  /**
   * @description Changes the value and errorkey of an input field in view tab.
   * @param {String} id The id of the inputfield. This string must be the same as the input field in state.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleViewTabInputChange = (id, value, errorkey) => {
    this.setState({
      viewTab: {
        ...this.state.viewTab,
        [id]: {
          ...this.state.viewTab[id],
          value: value,
          errorkey: errorkey,
        }
      }
    })
  }

  /**
   * @description Changes the value of a 'required' checkbox in view tab.
   * @param {String} id The id of the checkbox. The string must be the same as the checkbox in state.
   * @param {String } value The new value.
   */
  onViewTabCheckboxRequiredChange = (id, value) => {
    this.setState({
      viewTab: {
        ...this.state.viewTab,
        [id]: {
          ...this.state.viewTab[id],
          required: value,
        }
      }
    })
  }

  /**
   * @description Changes the value of a 'disabled' checkbox in view tab.
   * @param {String} id The id of the checkbox. The string must be the same as the checkbox in state.
   * @param {String} value The new value.
   */
  onViewTabCheckboxDisabledChange = (id, value) => {
    this.setState({
      viewTab: {
        ...this.state.viewTab,
        [id]: {
          ...this.state.viewTab[id],
          disabled: value,
        }
      }
    })
  }

  /**
   * @description Renders the view tab.
   */
  renderViewTab = () => {
    const { id, lang } = this.props
    const { viewTab } = this.state
    return (
      <div className={'bux_UserProfileModal_flex_column'}>
        <div className={'bux_flex_row_container_special_small'} style={{alignSelf: 'flex-end'}}>
          <Column
            colMD={6}
            className={'centered'}>
            <label
              id={`${id}_view_tab_column_required`}>
              {translate('usermanagement.userprofile_view_tab.required', lang)}
            </label>
          </Column>
          <Column
            colMD={6}
            className={'centered'}>
            <label
              id={`${id}_view_tab_column_disabled`}>
              {translate('usermanagement.userprofile_view_tab.disabled', lang)}
            </label>
          </Column>
        </div>
        <div className={'bux_UserProfileModal_flex_column bux_UserProfileModal_gap'}>
          <Row className={'bux_flex_row_container'}>
            <Column className={'bux_flex_column_container_special_large'}>
              <Input
                id={`${id}_view_tab_folder`}
                title={translate('usermanagement.userprofile_view_tab.folder', lang)}
                value={viewTab.folder.value}
                error={viewTab.folder.errorkey ? translate(viewTab.folder.errorkey, lang) : null}
                onInputChanged={(val, err) => { this.handleViewTabInputChange('folder', val, err) }}
                maxLength={32}
              />
            </Column>
            <Row className={'bux_flex_row_container_special_small'}>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_folder_required`}
                  value={viewTab.folder.required}
                  onCheck={(isChecked) => { this.onViewTabCheckboxRequiredChange('folder', isChecked) }}
                />
              </Column>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_folder_disabled`}
                  value={viewTab.folder.disabled}
                  onCheck={(isChecked) => { this.onViewTabCheckboxDisabledChange('folder', isChecked) }}
                />
              </Column>
            </Row>
          </Row>
          <Row className={'bux_flex_row_container'}>
            <Column className={'bux_flex_column_container_special_large'}>
              <Input
                id={`${id}_view_tab_recipient`}
                title={translate('usermanagement.userprofile_view_tab.recipient', lang)}
                value={viewTab.recipient.value}
                error={viewTab.recipient.errorkey ? translate(viewTab.recipient.errorkey, lang) : null}
                onInputChanged={(val, err) => { this.handleViewTabInputChange('recipient', val, err) }}
                maxLength={16}
              />
            </Column>
            <Row className={'bux_flex_row_container_special_small'}>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_recipient_required`}
                  value={viewTab.recipient.required}
                  onCheck={(isChecked) => { this.onViewTabCheckboxRequiredChange('recipient', isChecked) }}
                />
              </Column>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_recipient_disabled`}
                  value={viewTab.recipient.disabled}
                  onCheck={(isChecked) => { this.onViewTabCheckboxDisabledChange('recipient', isChecked) }}
                />
              </Column>
            </Row>
          </Row>
          <Row className={'bux_flex_row_container'}>
            <Column className={'bux_flex_column_container_special_large'}>
              <Input
                id={`${id}_view_tab_form`}
                title={translate('general.form', lang)}
                value={viewTab.form.value}
                error={viewTab.form.errorkey ? translate(viewTab.form.errorkey, lang) : null}
                onInputChanged={(val, err) => { this.handleViewTabInputChange('form', val, err) }}
                maxLength={8}
              />
            </Column>
            <Row className={'bux_flex_row_container_special_small'}>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_form_required`}
                  value={viewTab.form.required}
                  onCheck={(isChecked) => { this.onViewTabCheckboxRequiredChange('form', isChecked) }}
                />
              </Column>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_form_disabled`}
                  value={viewTab.form.disabled}
                  onCheck={(isChecked) => { this.onViewTabCheckboxDisabledChange('form', isChecked) }}
                />
              </Column>
            </Row>
          </Row>
          <Row className={'bux_flex_row_container'}>
            <Column className={'bux_flex_column_container_special_large'}>
              <Input
                id={`${id}_view_tab_extension`}
                title={translate('general.extension', lang)}
                value={viewTab.extension.value}
                error={viewTab.extension.errorkey ? translate(viewTab.extension.errorkey, lang) : null}
                onInputChanged={(val, err) => { this.handleViewTabInputChange('extension', val, err) }}
                maxLength={16}
              />
            </Column>
            <Row className={'bux_flex_row_container_special_small'}>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_extension_required`}
                  value={viewTab.extension.required}
                  onCheck={(isChecked) => { this.onViewTabCheckboxRequiredChange('extension', isChecked) }}
                />
              </Column>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_extension_disabled`}
                  value={viewTab.extension.disabled}
                  onCheck={(isChecked) => { this.onViewTabCheckboxDisabledChange('extension', isChecked) }}
                />
              </Column>
            </Row>
          </Row>
          <Row className={'bux_flex_row_container'}>
            <Column className={'bux_flex_column_container_special_large'}>
              <Input
                id={`${id}_view_tab_report`}
                title={translate('general.report', lang)}
                value={viewTab.report.value}
                error={viewTab.report.errorkey ? translate(viewTab.report.errorkey, lang) : null}
                onInputChanged={(val, err) => { this.handleViewTabInputChange('report', val, err) }}
                maxLength={16}
              />
            </Column>
            <Row className={'bux_flex_row_container_special_small'}>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_report_required`}
                  value={viewTab.report.required}
                  onCheck={(isChecked) => { this.onViewTabCheckboxRequiredChange('report', isChecked) }}
                />
              </Column>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_report_disabled`}
                  value={viewTab.report.disabled}
                  onCheck={(isChecked) => { this.onViewTabCheckboxDisabledChange('report', isChecked) }}
                />
              </Column>
            </Row>
          </Row>
          <Row className={'bux_flex_row_container'}>
            <Column className={'bux_flex_column_container_special_large'}>
              <Input
                id={`${id}_view_tab_jobname`}
                title={translate('usermanagement.userprofile_view_tab.jobname', lang)}
                value={viewTab.jobname.value}
                error={viewTab.jobname.errorkey ? translate(viewTab.jobname.errorkey, lang) : null}
                onInputChanged={(val, err) => { this.handleViewTabInputChange('jobname', val, err) }}
                maxLength={8}
              />
            </Column>
            <Row className={'bux_flex_row_container_special_small'}>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_jobname_required`}
                  value={viewTab.jobname.required}
                  onCheck={(isChecked) => { this.onViewTabCheckboxRequiredChange('jobname', isChecked) }}
                />
              </Column>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_view_tab_jobname_disabled`}
                  value={viewTab.jobname.disabled}
                  onCheck={(isChecked) => { this.onViewTabCheckboxDisabledChange('jobname', isChecked) }}
                />
              </Column>
            </Row>
          </Row>
        </div>
      </div>
    )
  }
  /* ************************************************************************************ */
  // Print tab

  /**
  * @description Changes the value of a checkbox in print tab.
  * @param {String} id The id of the checkbox which will changed. This string must be the same as the field in state.
  * @param {Boolean} value The new value of the checkbox.
  */
  onPrintTabCheckboxChange(id, value) {
    this.setState({
      printTab: {
        ...this.state.printTab,
        [id]: value
      }
    })
  }

  /**
   * @description Changes the value and errorkey of an input field in print tab.
   * @param {String} id The id of the inputfield. This string must be the same as the input field in state.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handlePrintTabInputChange = (id, value, errorkey) => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        [id]: {
          ...this.state.printTab[id],
          value: value,
          errorkey: errorkey,
        }
      }
    })
  }

  /**
   * @description Changes the value of a 'required' checkbox in print tab.
   * @param {String} id The id of the checkbox. The string must be the same as the checkbox in state.
   * @param {String } value The new value.
   */
  onPrintTabCheckboxRequiredChange = (id, value) => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        [id]: {
          ...this.state.printTab[id],
          required: value,
        }
      }
    })
  }

  /**
   * @description Changes the value of a 'disabled' checkbox in print tab.
   * @param {String} id The id of the checkbox. The string must be the same as the checkbox in state.
   * @param {String } value The new value.
   */
  onPrintTabCheckboxDisabledChange = (id, value) => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        [id]: {
          ...this.state.printTab[id],
          disabled: value,
        }
      }
    })
  }

  /**
   * @description Changes the print limit.
   * @param value The new value.
   */
  handlePrintLimitChange = (value) => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        printLimitPage: value
      }
    })
  }

  /**
   * @description Gets the translated print limit parameters as array.
   */
  getTranslatedPrintParameters = () => {
    const { lang } = this.props

    return [
      translate('usermanagement.userprofile_print_tab.print_parameter_default', lang),
      translate('usermanagement.userprofile_print_tab.print_parameter_user', lang)
    ]
  }

  /**
   * @description Changes the print parameter index position.
   * @param index The new index position.
   */
  handlePrintTabPrintParameterIndexChanged = (index) => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        printParameterIndex: index,
      }
    })
  }

  /**
   * @description Renders the Print tab.
   */
  renderPrintTab() {
    const { id, lang } = this.props
    const { printTab } = this.state
    return (
      <div className={'bux_UserProfileModal_flex_column'}>
        <div className={'bux_flex_row_container_special_small'} style={{alignSelf: 'flex-end'}}>
          <Column
            colMD={6}
            className={'centered'}>
            <label id={`${id}_print_tab_column_required`}>
              {translate('usermanagement.userprofile_print_tab.required')}
            </label>
          </Column>
          <Column
            colMD={6}
            className={'centered'}>
            <label id={`${id}_print_tab_column_disabled`}>
              {translate('usermanagement.userprofile_print_tab.disabled')}
            </label>
          </Column>
        </div>
        <div className={'bux_UserProfileModal_flex_column  bux_UserProfileModal_gap'}>
          <Row className={'bux_flex_row_container'}>
            <Column className={'bux_flex_column_container_special_large'}>
              <Input
                id={`${id}_print_tab_output_channel`}
                title={translate('usermanagement.userprofile_print_tab.output_channel', lang)}
                value={printTab.outputChannel.value}
                error={printTab.outputChannel.errorkey && translate(printTab.outputChannel.errorkey, lang)}
                onInputChanged={(val, err) => {
                  this.handlePrintTabInputChange('outputChannel', val, err)
                }}
                maxLength={16}
              />
            </Column>
            <Row className={'bux_flex_row_container_special_small'}>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_print_tab_output_channel_required`}
                  value={printTab.outputChannel.required}
                  onCheck={isChecked => { this.onPrintTabCheckboxRequiredChange('outputChannel', isChecked) }}
                  disabled
                />
              </Column>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_print_tab_output_channel_disabled`}
                  value={printTab.outputChannel.disabled}
                  onCheck={isChecked => { this.onPrintTabCheckboxDisabledChange('outputChannel', isChecked) }}
                />
              </Column>
            </Row>
          </Row>
          <Row className={'bux_flex_row_container'}>
            <Column className={'bux_flex_column_container_special_large'}>
              <Input
                id={`${id}_print_tab_outputformat`}
                title={translate('usermanagement.userprofile_print_tab.outputformat', lang)}
                value={printTab.outputFormat.value}
                error={printTab.outputFormat.errorkey && translate(printTab.outputFormat.errorkey, lang)}
                onInputChanged={(val, err) => {
                  this.handlePrintTabInputChange('outputFormat', val, err)
                }}
                maxLength={16}
              />
            </Column>
            <Row className={'bux_flex_row_container_special_small'}>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_print_tab_outputformat_required`}
                  value={printTab.outputFormat.required}
                  onCheck={isChecked => { this.onPrintTabCheckboxRequiredChange('outputFormat', isChecked) }}
                />
              </Column>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_print_tab_outputformat_disabled`}
                  value={printTab.outputFormat.disabled}
                  onCheck={isChecked => { this.onPrintTabCheckboxDisabledChange('outputFormat', isChecked) }}
                />
              </Column>
            </Row>
          </Row>
          <Row className={'bux_flex_row_container'}>
            <Column className={'bux_flex_column_container_special_large'}>
              <Input
                id={`${id}_print_tab_postprocessingnote`}
                title={translate('usermanagement.userprofile_print_tab.postprocessingnote', lang)}
                value={printTab.postProcessingNote.value}
                error={printTab.postProcessingNote.errorkey && translate(printTab.postProcessingNote.errorkey, lang)}
                onInputChanged={(val, err) => {
                  this.handlePrintTabInputChange('postProcessingNote', val, err)
                }}
                maxLength={16}
              />
            </Column>
            <Row className={'bux_flex_row_container_special_small'}>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_print_tab_postprocessingnote_required`}
                  value={printTab.postProcessingNote.required}
                  onCheck={(isChecked) => { this.onPrintTabCheckboxRequiredChange('postProcessingNote', isChecked) }}
                />
              </Column>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_print_tab_postprocessingnote_disabled`}
                  value={printTab.postProcessingNote.disabled}
                  onCheck={isChecked => { this.onPrintTabCheckboxDisabledChange('postProcessingNote', isChecked) }}
                />
              </Column>
            </Row>
          </Row>
          <Row className={'bux_flex_row_container'}>
            <Column className={'bux_flex_column_container_special_large'}>
              <Input
                id={`${id}_print_tab_recipient`}
                title={translate('usermanagement.userprofile_print_tab.recipient', lang)}
                value={printTab.recipient.value}
                error={printTab.recipient.errorkey && translate(printTab.recipient.errorkey, lang)}
                onInputChanged={(val, err) => {
                  this.handlePrintTabInputChange('recipient', val, err)
                }}
                maxLength={16}
              />
            </Column>
            <Row className={'bux_flex_row_container_special_small'}>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_print_tab_recipient_required`}
                  value={printTab.recipient.required}
                  onCheck={isChecked => { this.onPrintTabCheckboxRequiredChange('recipient', isChecked) }}
                />
              </Column>
              <Column
                colMD={6}
                className='bux_center_checkbox'>
                <Checkbox
                  id={`${id}_print_tab_recipient_disabled`}
                  value={printTab.recipient.disabled}
                  onCheck={isChecked => { this.onPrintTabCheckboxDisabledChange('recipient', isChecked) }}
                />
              </Column>
            </Row>
          </Row>
          <Row className={'bux_flex_row_container_special_large'}>
            <Column colMD={6}>
              <NumericSpinner
                id={`${id}_print_tab_print_limit`}
                title={translate('usermanagement.userprofile_print_tab.print_limit_page', lang)}
                value={printTab.printLimitPage}
                max={999999}
                steps={1}
                min={0}
                onChange={newVal => {
                  this.handlePrintLimitChange(newVal)
                }}
              />
            </Column>
            <Column colMD={6}>
              <Dropdown
                id={`${id}_print_tab_print_parameter`}
                items={this.getTranslatedPrintParameters()}
                activeIndex={printTab.printParameterIndex}
                onChange={this.handlePrintTabPrintParameterIndexChanged}
                title={translate('usermanagement.userprofile_print_tab.print_parameter', lang)}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Checkbox
                id={`${id}_row_6_column_0`}
                label={translate('usermanagement.userprofile_print_tab.high_prio_print', lang)}
                value={printTab.allowHighPrioPrint}
                onCheck={(isChecked) => { this.onPrintTabCheckboxChange('allowHighPrioPrint', isChecked) }}
              />
            </Column>
          </Row>
        </div>
      </div>
    )
  }
  /* ************************************************************************************ */
  // Import tab

  /**
   * @description Changes the value of a checkbox in import tab.
   * @param {String} id The id of the checkbox which will changed. This string must be the same as the field in state.
   * @param {Boolean} value The new value of the checkbox.
   */
  onImportTabCheckboxChange(id, value) {
    this.setState({
      importTab: {
        ...this.state.importTab,
        [id]: value
      }
    })
  }

  /**
   * @description Changes the value and errorkey of an input field in import tab.
   * @param {String} id The id of the inputfield. This string must be the same as the input field in state.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleImportTabInputChange = (id, value, errorkey) => {
    this.setState({
      importTab: {
        ...this.state.importTab,
        [id]: {
          ...this.state.importTab[id],
          value: value,
          errorkey: errorkey,
        }
      }
    })
  }

  /**
   * @description Changes the value of a 'required' checkbox in import tab.
   * @param {String} id The id of the checkbox. The string must be the same as the checkbox in state.
   * @param {String } value The new value.
   */
  onImportTabCheckboxRequiredChange = (id, value) => {
    this.setState({
      importTab: {
        ...this.state.importTab,
        [id]: {
          ...this.state.importTab[id],
          required: value,
        }
      }
    })
  }

  /**
   * @description Changes the value of a 'disabled' checkbox in import tab.
   * @param {String} id The id of the checkbox. The string must be the same as the checkbox in state.
   * @param {String } value The new value.
   */
  onImportTabCheckboxDisabledChange = (id, value) => {
    this.setState({
      importTab: {
        ...this.state.importTab,
        [id]: {
          ...this.state.importTab[id],
          disabled: value,
        }
      }
    })
  }

  /**
   * @description Renders the Import tab.
   */
  renderImportTab() {
    const { id, lang } = this.props
    const { importTab } = this.state
    return (
      <div className={'bux_UserProfileModal_flex_column bux_UserProfileModal_gap'}>
        <Row className={'bux_flex_row_container'}>
          <Column className={'bux_flex_column_container_special_large'}>
            <Checkbox
              id={`${id}_import_tab_enable_import`}
              label={translate('usermanagement.userprofile_import_tab.enabled_standard_import', lang)}
              value={importTab.enableStandardImport}
              onCheck={(isChecked) => this.handleCheckboxForStartpanels('importTab', 'enableStandardImport', isChecked)}
            />
          </Column>
          <Row className={'bux_flex_row_container_special_small'}>
            <Column
              colMD={6}
              className={'centered'}>
              <label
                id={`${id}_import_tab_column_required`}>
                {translate('usermanagement.userprofile_import_tab.required', lang)}
              </label>
            </Column>
            <Column
              colMD={6}
              className={'centered'}>
              <label id={`${id}_import_tab_column_disabled`}>
                {translate('usermanagement.userprofile_import_tab.disabled', lang)}
              </label>
            </Column>
          </Row>
        </Row>
        <Row className={'bux_flex_row_container'}>
          <Column className={'bux_flex_column_container_special_large'}>
            <Input
              id={`${id}_import_tab_form`}
              title={translate('general.form', lang)}
              disabled={!importTab.enableStandardImport}
              value={importTab.form.value}
              error={importTab.form.errorkey && translate(importTab.form.errorkey, lang)}
              onInputChanged={(val, err) => {
                this.handleImportTabInputChange('form', val, err)
              }}
              maxLength={8}
            />
          </Column>
          <Row className={'bux_flex_row_container_special_small'}>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_import_tab_form_required`}
                value={importTab.form.required}
                disabled
                onCheck={() => { }} // onCheck not possible, but required for component
              />
            </Column>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_import_tab_form_disabled`}
                value={importTab.form.disabled}
                disabled={!importTab.enableStandardImport}
                onCheck={(isChecked) => { this.onImportTabCheckboxDisabledChange('form', isChecked) }}
              />
            </Column>
          </Row>
        </Row>
        <Row className={'bux_flex_row_container'}>
          <Column className={'bux_flex_column_container_special_large'}>
            <Input
              id={`${id}_import_tab_extension`}
              title={translate('general.extension', lang)}
              disabled={!importTab.enableStandardImport}
              value={importTab.extension.value}
              error={importTab.extension.errorkey && translate(importTab.extension.errorkey, lang)}
              onInputChanged={(val, err) => {
                this.handleImportTabInputChange('extension', val, err)
              }}
              maxLength={16}
            />
          </Column>
          <Row className={'bux_flex_row_container_special_small'}>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_import_tab_extension_required`}
                value={importTab.extension.required}
                disabled={!importTab.enableStandardImport}
                onCheck={(isChecked) => { this.onImportTabCheckboxRequiredChange('extension', isChecked) }}
              />
            </Column>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_import_tab_extension_disabled`}
                value={importTab.extension.disabled}
                disabled={!importTab.enableStandardImport}
                onCheck={(isChecked) => { this.onImportTabCheckboxDisabledChange('extension', isChecked) }}
              />
            </Column>
          </Row>
        </Row>
        <Row className={'bux_flex_row_container'}>
          <Column className={'bux_flex_column_container_special_large'}>
            <Input
              id={`${id}_import_tab_jobname`}
              title={translate('usermanagement.userprofile_import_tab.jobname', lang)}
              disabled={!importTab.enableStandardImport}
              value={importTab.jobname.value}
              error={importTab.jobname.errorkey && translate(importTab.jobname.errorkey, lang)}
              onInputChanged={(val, err) => {
                this.handleImportTabInputChange('jobname', val, err)
              }}
              maxLength={8}
            />
          </Column>
          <Row className={'bux_flex_row_container_special_small'}>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_import_tab_jobname_required`}
                value={importTab.jobname.required}
                disabled
                onCheck={() => { }} // onCheck not possible, but required for component
              />
            </Column>
            {/* jobname disabled */}
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_import_tab_jobname_disabled`}
                value={importTab.jobname.disabled}
                disabled={!importTab.enableStandardImport}
                onCheck={(isChecked) => { this.onImportTabCheckboxDisabledChange('jobname', isChecked) }}
              />
            </Column>
          </Row>
        </Row>
      </div>
    )
  }
  /* ************************************************************************************ */
  // Output queue tab

  /**
   * @description Changes the select output channel input in outputqueue tab.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleOutputQueueTabSelectOutputChannelChange = (value, errorKey) => {
    this.setState({
      outputQueueTab: {
        ...this.state.outputQueueTab,
        selectOutputChannel: {
          ...this.state.outputQueueTab.selectOutputChannel,
          value: value,
          errorKey: errorKey
        }
      }
    })
  }

  /**
  * @description Changes the requeue outpu channel input in outputqueue tab.
  * @param {String} value The new value.
  * @param {String} errorkey The new error key.
  */
  handleOutputQueueTabRequeueOutputChannelChange = (value, errorKey) => {
    this.setState({
      outputQueueTab: {
        ...this.state.outputQueueTab,
        requeueOutputChannel: {
          value: value,
          errorKey: errorKey
        }
      }
    })
  }

  /**
   * @description Changes the value of a checkbox in other tab.
   * @param {String} id The id of the checkbox which will changed. This string must be the same as the field in state.
   * @param {Boolean} value The new value of the checkbox.
   */
  onOutputQueueTabCheckboxChange(id, value) {
    this.setState({
      outputQueueTab: {
        ...this.state.outputQueueTab,
        [id]: value
      }
    })
  }


  /**
 * @description Changes the value of a 'required' checkbox in output-queue tab.
 * @param {String} id The id of the checkbox. The string must be the same as the checkbox in state.
 * @param {String } value The new value.
 */
  onOutputQueueTabCheckboxRequiredChange = (id, value) => {
    this.setState({
      outputQueueTab: {
        ...this.state.outputQueueTab,
        [id]: {
          ...this.state.outputQueueTab[id],
          required: value,
        }
      }
    })
  }

  /**
   * @description Changes the value of a 'disabled' checkbox in output-queue tab.
   * @param {String} id The id of the checkbox. The string must be the same as the checkbox in state.
   * @param {String } value The new value.
   */
  onOutputQueueTabCheckboxDisabledChange = (id, value) => {
    this.setState({
      outputQueueTab: {
        ...this.state.outputQueueTab,
        [id]: {
          ...this.state.outputQueueTab[id],
          disabled: value,
        }
      }
    })
  }

  /**
   * @description Renders the Output-Queue tab.
   */
  renderOutputQueueTab() {
    const { id, lang } = this.props
    const { outputQueueTab } = this.state
    return (
      <div className={'bux_UserProfileModal_flex_column bux_UserProfileModal_gap'}>
        <Row className={'bux_flex_row_container'}>
          <Column className={'bux_flex_column_container_special_large'}>
            <Checkbox
              id={`${id}_outqueue_tab_enable_outputqueue`}
              label={translate('usermanagement.userprofile_outputqueue_tab.enable_output_queue', lang)}
              value={outputQueueTab.enableOutputQueue}
              onCheck={(isChecked) => this.handleCheckboxForStartpanels('outputQueueTab', 'enableOutputQueue', isChecked)}
            />
          </Column>
          <Row className={'bux_flex_row_container_special_small'}>
            <Column
              colMD={6}
              className={'centered'}>
              <label id={`${id}_outqueue_tab_column_required`}>
                {translate('usermanagement.userprofile_outputqueue_tab.required', lang)}
              </label>
            </Column>
            <Column
              className={'centered'}
              colMD={6}>
              <label id={`${id}_outqueue_tab_column_disabled`}>
                {translate('usermanagement.userprofile_outputqueue_tab.disabled', lang)}
              </label>
            </Column>
          </Row>
        </Row>
        <Row className={'bux_flex_row_container'}>
          <Column className={'bux_flex_column_container_special_large'}>
            <Input
              id={`${id}_outqueue_tab_outputchannel`}
              title={translate('usermanagement.userprofile_outputqueue_tab.select_output_channel', lang)}
              disabled={!outputQueueTab.enableOutputQueue}
              value={outputQueueTab.selectOutputChannel.value}
              error={outputQueueTab.selectOutputChannel.errorkey && translate(outputQueueTab.selectOutputChannel.errorkey, lang)}
              onInputChanged={(val, err) => {
                this.handleOutputQueueTabSelectOutputChannelChange(val, err)
              }}
              maxLength={16}
            />
          </Column>
          <Row className={'bux_flex_row_container_special_small'}>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_outqueue_tab_outputchannel_required`}
                value={outputQueueTab.selectOutputChannel.required}
                disabled={!outputQueueTab.enableOutputQueue}
                onCheck={(isChecked) => { this.onOutputQueueTabCheckboxRequiredChange('selectOutputChannel', isChecked) }}
              />
            </Column>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_outqueue_tab_outputchannel_disabled`}
                value={outputQueueTab.selectOutputChannel.disabled}
                disabled={!outputQueueTab.enableOutputQueue}
                onCheck={(isChecked) => { this.onOutputQueueTabCheckboxDisabledChange('selectOutputChannel', isChecked) }}
              />
            </Column>
          </Row>
        </Row>
        <Row className={'bux_flex_row_container'}>
          <Column className={'bux_flex_column_container_special_large'}>
            <Input
              id={`${id}_outqueue_tab_requeue_outputchan`}
              title={translate('usermanagement.userprofile_outputqueue_tab.requeue_output_channel', lang)}
              value={outputQueueTab.requeueOutputChannel.value}
              disabled={!outputQueueTab.enableOutputQueue}
              error={outputQueueTab.requeueOutputChannel.errorkey && translate(outputQueueTab.requeueOutputChannel.errorkey, lang)}
              onInputChanged={(val, err) => {
                this.handleOutputQueueTabRequeueOutputChannelChange(val, err)
              }}
              maxLength={16}
            />
          </Column>
          <Row className={'bux_flex_row_container_special_small'}>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_outqueue_tab_requeue_outputchan_required`}
                value={outputQueueTab.requeueOutputChannel.required}
                disabled={!outputQueueTab.enableOutputQueue}
                onCheck={(isChecked) => { this.onOutputQueueTabCheckboxRequiredChange('requeueOutputChannel', isChecked) }}
              />
            </Column>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_outqueue_tab_requeue_outputchan_disabled`}
                value={outputQueueTab.requeueOutputChannel.disabled}
                disabled={!outputQueueTab.enableOutputQueue}
                onCheck={(isChecked) => { this.onOutputQueueTabCheckboxDisabledChange('requeueOutputChannel', isChecked) }}
              />
            </Column>
          </Row>
        </Row>
      </div >
    )
  }
  /* ************************************************************************************ */
  // Others tab

  /**
   * @description Changes the output channel input in other tab.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleOtherTabOutputChannelChange = (value, errorKey) => {
    this.setState({
      otherTab: {
        ...this.state.otherTab,
        outputChannel: {
          value: value,
          errorKey: errorKey
        }
      }
    })
  }

  /**
   * @description Changes the value of a checkbox in other tab.
   * @param {String} id The id of the checkbox which will changed. This string must be the same as the field in state.
   * @param {Boolean} value The new value of the checkbox.
   */
  onOtherTabCheckboxChange(id, value) {
    this.setState({
      otherTab: {
        ...this.state.otherTab,
        [id]: value
      }
    })
  }

  /**
   * @description Changes the value of a 'required' checkbox in other tab.
   * @param {String} id The id of the checkbox. The string must be the same as the checkbox in state.
   * @param {String } value The new value.
   */
  onOtherTabCheckboxRequiredChange = (id, value) => {
    this.setState({
      otherTab: {
        ...this.state.otherTab,
        [id]: {
          ...this.state.otherTab[id],
          required: value,
        }
      }
    })
  }

  /**
   * @description Changes the value of a 'disabled' checkbox in other tab.
   * @param {String} id The id of the checkbox. The string must be the same as the checkbox in state.
   * @param {String} value The new value.
   */
  onOtherTabCheckboxDisabledChange = (id, value) => {
    this.setState({
      otherTab: {
        ...this.state.otherTab,
        [id]: {
          ...this.state.otherTab[id],
          disabled: value,
        }
      }
    })
  }

  /**
   * @description Renders the other tab.
   */
  renderOtherTab() {
    const { id } = this.props
    const { otherTab } = this.state
    return (
      <div className={'bux_UserProfileModal_flex_column bux_UserProfileModal_gap'}>
        <Row className={'bux_flex_row_container'}>
          <Column className={'bux_flex_column_container_special_large'}>
            <Checkbox
              id={`${id}_other_tab_enable_monitor`}
              label={translate('usermanagement.userprofile_other_tab.enable_monitor')}
              value={otherTab.enableMonitor}
              onCheck={(isChecked) => { this.onOtherTabCheckboxChange('enableMonitor', isChecked) }}
            />
          </Column>
          <Row className={'bux_flex_row_container_special_small'}>
            <Column
              colMD={6}
              className={'centered'}>
              <label id={`${id}_other_tab_column_required`}>
                {translate('usermanagement.userprofile_other_tab.required')}
              </label>
            </Column>
            <Column
              colMD={6}
              className={'centered'}>
              <label id={`${id}_other_tab_column_disabled`}>
                {translate('usermanagement.userprofile_other_tab.disabled')}
              </label>
            </Column>
          </Row>
        </Row>
        <Row className={'bux_flex_row_container'}>
          <Column className={'bux_flex_column_container_special_large'}>
            <Input
              id={`${id}_other_tab_outputchannel`}
              title={translate('usermanagement.userprofile_other_tab.select_output_channel')}
              disabled={!otherTab.enableMonitor}
              value={otherTab.outputChannel.value}
              error={otherTab.outputChannel.errorkey && translate(otherTab.outputChannel.errorkey)}
              onInputChanged={(val, err) => {
                this.handleOtherTabOutputChannelChange(val, err)
              }}
              maxLength={16}
            />
          </Column>
          <Row className={'bux_flex_row_container_special_small'}>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_other_tab_outputchannel_required`}
                value={otherTab.outputChannel.required}
                disabled={!otherTab.enableMonitor}
                onCheck={(isChecked) => { this.onOtherTabCheckboxRequiredChange('outputChannel', isChecked) }}
              />
            </Column>
            <Column
              colMD={6}
              className='bux_center_checkbox'>
              <Checkbox
                id={`${id}_othertab_outputchannel_disabled`}
                value={otherTab.outputChannel.disabled}
                disabled={!otherTab.enableMonitor}
                onCheck={(isChecked) => { this.onOtherTabCheckboxDisabledChange('outputChannel', isChecked) }}
              />
            </Column>
          </Row>
        </Row>
        <Row className={'bux_flex_row_container'}>
          <Checkbox
            id={`${id}_other_tab_enable_msglog`}
            label={translate('usermanagement.userprofile_other_tab.enable_message_log')}
            value={otherTab.enableMessageLog}
            onCheck={(isChecked) => this.handleCheckboxForStartpanels('otherTab', 'enableMessageLog', isChecked)}
          />
        </Row>
        <Row className={'bux_flex_row_container'}>
          <Checkbox
            id={`${id}_other_tab_enable_external_cmd`}
            label={translate('usermanagement.userprofile_other_tab.enable_external_command')}
            value={otherTab.enableExternalCommands}
            onCheck={(isChecked) => { this.onOtherTabCheckboxChange('enableExternalCommands', isChecked) }}
          />
        </Row>
      </div >
    )
  }

  renderMain = () => {
    const { id, lang } = this.props
    return (
      <Tabs
        id={id}
        disabledTabs={!this.state.basicTab.subadmin ? [3, 4, 5] : []}
        lang={lang}>
        <Tab title={translate('usermanagement.userprofile_basic_tab')}>
          {this.renderBasicTab()}
        </Tab>
        <Tab
          id={'userprofileView'}
          title={translate('usermanagement.userprofile_view_tab')}
          tooltip={translate('usermanagement.user_subadmin_required')}>
          {this.renderViewTab()}
        </Tab>
        <Tab
          id={'userprofilePrint'}
          title={translate('usermanagement.userprofile_print_tab')}
          tooltip={translate('usermanagement.user_subadmin_required')}>
          {this.renderPrintTab()}
        </Tab>
        <Tab
          id={'userprofileImport'}
          title={translate('usermanagement.userprofile_import_tab')}
          tooltip={translate('usermanagement.user_subadmin_required')}>
          {this.renderImportTab()}
        </Tab>
        <Tab
          id={'userprofileOutputqueue'}
          title={translate('usermanagement.userprofile_outputqueue_tab')}
          tooltip={translate('usermanagement.user_subadmin_required')}>
          {this.renderOutputQueueTab()}
        </Tab>
        <Tab
          id={'userprofileOther'}
          title={translate('usermanagement.userprofile_other_tab')}
          tooltip={translate('usermanagement.user_subadmin_required')}>
          {this.renderOtherTab()}
        </Tab>
      </Tabs>
    )
  }

  /* ************************************************************************************ */

  render = () => {
    const { id, datemask, userProfileToModify, userToCreateUserprofileFor, onClose } = this.props
    return (
      <Modal onClose={onClose}
        id='user_profile_dialog'
        className='bux_UserProfileModal'>
        <Header
          id={`${id}_modalHeader`}
          title={translate(userProfileToModify ? 'usermanagement.userprofile_modify' : 'usermanagement.userprofile_create')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('user.user_id'), value: userToCreateUserprofileFor?.BETAUSER },
              { label: translate('user.username'), value: userToCreateUserprofileFor?.USERNAME },
              userProfileToModify && { label: translate('general.last_changed'), value: `${DateUtils.getDate(datemask, userProfileToModify.CDATE, userProfileToModify.CTIME.substring(0, 8))} ${translate('general.by')} ${userProfileToModify.CUSER}` },
            ]}
            columns={4}
          />
        </Header>
        <Main>
          {this.renderMain()}
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={this.props.onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }

  /**
   * @description Sets the values of a userprofile to the current state.
   * @param userprofile The userprofile which includes the values to set.
   */
  userProfileToState = (userprofile) => {
    this.setState({
      ...this.state,
      // View Tab //
      viewTab: {
        recipient: {
          value: userprofile.VCIBR009,
          required: userprofile.VCIBRR09,
          disabled: userprofile.VCIBRD09,
        },
        folder: {
          value: userprofile.VCIBR020,
          required: userprofile.VCIBRR20,
          disabled: userprofile.VCIBRD20,
        },
        form: {
          value: userprofile.VCIBR001,
          required: userprofile.VCIBRR01,
          disabled: userprofile.VCIBRD01,
        },
        extension: {
          value: userprofile.VCIBR002,
          required: userprofile.VCIBRR02,
          disabled: userprofile.VCIBRD02,
        },
        report: {
          value: userprofile.VCIBR003,
          required: userprofile.VCIBRR03,
          disabled: userprofile.VCIBRD03,
        },
        jobname: {
          value: userprofile.VCIBR008,
          required: userprofile.VCIBRR08,
          disabled: userprofile.VCIBRD08,
        },
      },
      // Print Tab //
      printTab: {
        allowHighPrioPrint: userprofile.VCIPRPIO,
        printLimitPage: userprofile.VCIPRLMT,
        printParameterIndex: userprofile.VCIPRFRO
          ? Math.max(['DEFAULT', 'PROFILE'].findIndex(el => el === userprofile.VCIPRFRO.toUpperCase().trim()), 0)
          : 0,
        outputFormat: {
          value: userprofile.VCIPRPCR,
          required: userprofile.VCIPCRRE,
          disabled: userprofile.VCIPCRDI,
        },
        outputChannel: {
          value: userprofile.VCIPRDCR,
          required: true,
          disabled: userprofile.VCIDCRDI,
        },
        postProcessingNote: {
          value: userprofile.VCIPRPPN,
          required: userprofile.VCIPPNRE,
          disabled: userprofile.VCIPPNDI,
        },
        recipient: {
          value: userprofile.VCIPRREC,
          required: userprofile.VCIRECRE,
          disabled: userprofile.VCIRECDI,
        },
      },
      // Import Tab //
      importTab: {
        enableStandardImport: userprofile.VCIENIMP,
        form: {
          value: userprofile.VCIIM01,
          required: true,
          disabled: userprofile.VCIIM01D,
        },
        extension: {
          value: userprofile.VCIIM02,
          required: userprofile.VCIIM02R,
          disabled: userprofile.VCIIM02D,
        },
        jobname: {
          value: userprofile.VCIIM03,
          required: true,
          disabled: userprofile.VCIIM03D,
        },
      },
      // Output-Queue Tab //
      outputQueueTab: {
        enableOutputQueue: userprofile.VCIENPRQ,
        selectOutputChannel: {
          value: userprofile.VCIPQ01,
          required: userprofile.VCIPQ01R,
          disabled: userprofile.VCIPQ01D,
        },
        requeueOutputChannel: {
          value: userprofile.VCIPQ02,
          required: userprofile.VCIPQ02R,
          disabled: userprofile.VCIPQ02D,
        }
      },
      // Other Tab //
      otherTab: {
        enableMonitor: userprofile.VCIENPRM,
        enableExternalCommands: userprofile.VCIENUTL,
        enableMessageLog: userprofile.VCIENMSG,
        outputChannel: {
          value: userprofile.VCIPM01,
          required: userprofile.VCIPM01R,
          disabled: userprofile.VCIPM01D,
        },
      },
      // Basic Tab //
      basicTab: {
        betaViewRole: { value: userprofile.BWEROLE },
        subadmin: userprofile.BRWSONLY,
        startPanelIndex: 0,
        modifyDeleteRequest: userprofile.VCIDEL,
        modifyArchiveRequest: userprofile.VCIARC,
        modifyStatus: userprofile.VCISTA,
        modifyRetentionPeriod: userprofile.VCIRET,
        modifyReloadRequest: userprofile.VCIREL,
        rerunAutoprint: userprofile.VCIRAUP,
        rerunBundleRequest: userprofile.VCIRBUN,
      }
    }, () => {
      const panelIndex = userprofile.VCISTART &&
        this.userprofileStartKeys().findIndex(el => el === userprofile.VCISTART) !== -1
        ? this.userprofileStartKeys().findIndex(el => el === userprofile.VCISTART)
        : 0
      this.setState({
        basicTab: {
          ...this.state.basicTab,
          startPanelIndex: panelIndex
        }
      })
    })
  }

  /**
   * @description Sets the values from the state to a userprofile and return it.
   */
  stateToUserProfile = () => {

    const { viewTab, printTab, importTab, outputQueueTab, basicTab, otherTab } = this.state
    let userprofileObj = {
      // View Tab //
      VCIBR009: viewTab.recipient.value,
      VCIBRR09: viewTab.recipient.required,
      VCIBRD09: viewTab.recipient.disabled,
      VCIBR020: viewTab.folder.value,
      VCIBRR20: viewTab.folder.required,
      VCIBRD20: viewTab.folder.disabled,
      VCIBR001: viewTab.form.value,
      VCIBRR01: viewTab.form.required,
      VCIBRD01: viewTab.form.disabled,
      VCIBR002: viewTab.extension.value,
      VCIBRR02: viewTab.extension.required,
      VCIBRD02: viewTab.extension.disabled,
      VCIBR003: viewTab.report.value,
      VCIBRR03: viewTab.report.required,
      VCIBRD03: viewTab.report.disabled,
      VCIBR008: viewTab.jobname.value,
      VCIBRR08: viewTab.jobname.required,
      VCIBRD08: viewTab.jobname.disabled,
      // Print Tab //
      VCIPRPCR: printTab.outputFormat.value,
      VCIPCRRE: printTab.outputFormat.required,
      VCIPCRDI: printTab.outputFormat.disabled,
      VCIPRDCR: printTab.outputChannel.value,
      VCIDCRRE: printTab.outputChannel.required,
      VCIDCRDI: printTab.outputChannel.disabled,
      VCIPRPPN: printTab.postProcessingNote.value,
      VCIPPNRE: printTab.postProcessingNote.required,
      VCIPPNDI: printTab.postProcessingNote.disabled,
      VCIPRREC: printTab.recipient.value,
      VCIRECRE: printTab.recipient.required,
      VCIRECDI: printTab.recipient.disabled,
      VCIPRFRO: ['DEFAULT', 'PROFILE'][this.state.printTab.printParameterIndex],
      VCIPRPIO: printTab.allowHighPrioPrint,
      VCIPRLMT: printTab.printLimitPage,
      // VCIBR021     /* Not visible in old UI */
      // VCIBFLMT     /* Not visible in old UI */
      // Import Tab //
      VCIENIMP: importTab.enableStandardImport,
      VCIIM01: importTab.form.value,
      VCIIM01R: importTab.form.required,
      VCIIM01D: importTab.form.disabled,
      VCIIM02: importTab.extension.value,
      VCIIM02R: importTab.extension.required,
      VCIIM02D: importTab.extension.disabled,
      VCIIM03: importTab.jobname.value,
      VCIIM03R: importTab.jobname.required,
      VCIIM03D: importTab.jobname.disabled,
      // Other Tab //
      VCIENPRM: otherTab.enableMonitor,
      VCIPM01: otherTab.outputChannel.value,
      VCIPM01R: otherTab.outputChannel.required,
      VCIPM01D: otherTab.outputChannel.disabled,
      VCIENUTL: otherTab.enableExternalCommands,
      VCIENMSG: otherTab.enableMessageLog,
      // Basic Tab //
      BWEROLE: basicTab.betaViewRole.value,
      BRWSONLY: basicTab.subadmin,
      VCISTART: this.userprofileStartKeys()[basicTab.startPanelIndex],
      VCIDEL: basicTab.modifyDeleteRequest,
      VCIARC: basicTab.modifyArchiveRequest,
      VCISTA: basicTab.modifyStatus,
      VCIRET: basicTab.modifyRetentionPeriod,
      VCIREL: basicTab.modifyReloadRequest,
      VCIRAUP: basicTab.rerunAutoprint,
      // Output-Queue Tab //
      VCIENPRQ: outputQueueTab.enableOutputQueue,
      VCIENBUN: outputQueueTab.enableOutputQueue,
      VCIPQ01: outputQueueTab.selectOutputChannel.value,
      VCIPQ01R: outputQueueTab.selectOutputChannel.required,
      VCIPQ01D: outputQueueTab.selectOutputChannel.disabled,
      VCIPQ02: outputQueueTab.requeueOutputChannel.value,
      VCIPQ02R: outputQueueTab.requeueOutputChannel.required,
      VCIPQ02D: outputQueueTab.requeueOutputChannel.disabled
    }
    if (UserUtils.isDOCX()) {
      userprofileObj = {
        ...userprofileObj,
        VCIRBUN: basicTab.rerunBundleRequest,
      }
    }

    return userprofileObj
  }

  /**
   * @description Handles the save action and updates / creates a userprofile.
   */
  handleSave = () => {
    // If userprofileAlready existed update otherwise create
    if (this.props.userProfileToModify) {
      this.handleUpdate()
    } else {
      this.handleCreate()
    }
  }

  /**
   * @description Creates a new userprofile.
   */
  handleCreate = () => {
    const { userToCreateUserprofileFor, userid } = this.props

    this.props.createUserProfile(
      userToCreateUserprofileFor.BETAUSER,
      userid,
      this.stateToUserProfile(),
      this.closeCallback
    )
  }

  /**
   * @description Updates the current userprofile.
   */
  handleUpdate = () => {
    const { userToCreateUserprofileFor, userid } = this.props

    this.props.modifyUserProfile(
      userToCreateUserprofileFor.BETAUSER,
      userid,
      this.stateToUserProfile(),
      this.closeCallback
    )
  }

  /**
   * @description Closes the dialog.
   */
  closeCallback = () => {
    this.props.onClose()
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    userToCreateUserprofileFor: state.user.user,
    userProfileToModify: state.userprofile.userProfile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUserProfile: (userid, cuser, userprofile, callback) => {
      UserProfileActions.createUserProfile(userid, cuser, userprofile, callback)(dispatch)
    },
    modifyUserProfile: (userid, cuser, userprofile, callback) => {
      UserProfileActions.updateUserProfile(userid, cuser, userprofile, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileDialog)