import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DatabaseStatus from './database_status/DatabaseStatus'
import DatabaseTable from './database_table/DatabaseTable'
import DatabaseQuery from './database_query/DatabaseQuery'

class DatabaseBody extends Component {

  getBody() {
    switch (this.props.activeIndex) {
      // database status
      case 0: return <DatabaseStatus id={this.props.id} />
      // database table
      case 1: return <DatabaseTable id={this.props.id} />
      // database query
      case 2: return <DatabaseQuery id={this.props.id} />
      // should not happen
      default: return null
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

DatabaseBody.propTypes = {
  id: PropTypes.string.isRequired,
  activeIndex: PropTypes.number.isRequired,
}

export default DatabaseBody