import { LOGOUT_SUCCESS } from '../actions/AuthenticationActions'
import * as IndexQueueActions from '../actions/IndexQueueActions'

const defaultState = {
  indices: undefined,
  indexDetails: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Get index queues actions
    case IndexQueueActions.GET_INDEX_QUEUES_START:
      return { ...state }
    case IndexQueueActions.GET_INDEX_QUEUES_SUCCESS:
      return { ...state, indices: action.payload }
    case IndexQueueActions.GET_INDEX_QUEUES_FAILED:
      return { ...state }
    case IndexQueueActions.NO_INDEX_QUEUES_FOUND:
      return { ...state, indices: {} }
    /* ********************************************************************************** */
    // Get index queue details actions
    case IndexQueueActions.GET_INDEX_QUEUE_DETAILS_START:
      return { ...state }
    case IndexQueueActions.GET_INDEX_QUEUE_DETAILS_SUCCESS:
      return { ...state, indexDetails: action.payload, keepPagination: action.keepPagination }
    case IndexQueueActions.GET_INDEX_QUEUE_DETAILS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Delete index queues actions
    case IndexQueueActions.DELETE_INDEX_QUEUE_START:
      return { ...state }
    case IndexQueueActions.DELETE_INDEX_QUEUE_SUCCESS:
      return { ...state }
    case IndexQueueActions.DELETE_INDEX_QUEUE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Request index queue actions
    case IndexQueueActions.REQUEST_INDEX_QUEUE_START:
      return { ...state }
    case IndexQueueActions.REQUEST_INDEX_QUEUE_SUCCESS:
      return { ...state }
    case IndexQueueActions.REQUEST_INDEX_QUEUE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Rerun index queue actions
    case IndexQueueActions.RERUN_INDEX_QUEUE_START:
      return { ...state }
    case IndexQueueActions.RERUN_INDEX_QUEUE_SUCCESS:
      return { ...state }
    case IndexQueueActions.RERUN_INDEX_QUEUE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case LOGOUT_SUCCESS:
      return { ...state, indices: undefined, indexDetails: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer