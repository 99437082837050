import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button, Card, Column, Dropdown,
  Input,
  Row, Switch
} from 'BetaUX2Web-Components/src/'
import TimeCard from 'components/time_card/TimeCard'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'


import { translate } from 'language/Language'
import { getJobgroups } from 'redux/actions/ModalSelectorActions'
import { changePrefs } from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as Utils from 'utils/Utils'
import { JobStatus, JobType } from './SearchStonebranchSearch.types'
import { getStonebranchJobs } from 'redux/actions/SearchStonebranchActions'
import { validateTimecardErrors } from '../SearchUtils'
import { SEARCH_HEADERS } from '../Search.types'

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.search.date_field.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.search.date_field.hours' },
  { key: UNIT_DAYS, translationKey: 'general.search.date_field.days' },
]

const LAUNCH = 'SUBMIT'
const START = 'START'
const END = 'END'

const DISPLAY_JOBS_BY_ITEMS = [
  { key: LAUNCH, translationKey: 'general.search.date_field.launch' },
  { key: START, translationKey: 'general.search.date_field.start' },
  { key: END, translationKey: 'general.search.date_field.end' }
]

const FILTER_BY_JOB_ERRORS_ALL = ''
const FILTER_BY_JOB_ERRORS_SUCCESS = 'NO'
const FILTER_BY_JOB_ERRORS_ERROR = 'YES'

const ONLY_JOB_ERRORS_ITEMS = [
  { key: FILTER_BY_JOB_ERRORS_ALL, translationKey: 'general.all' },
  { key: FILTER_BY_JOB_ERRORS_SUCCESS, translationKey: 'general.successful' },
  { key: FILTER_BY_JOB_ERRORS_ERROR, translationKey: 'general.error' }
]

const JOB_TYPE_ITEMS = [{ key: '', text: 'general.any' }, ...Object.values(JobType).map(jobType => ({ key: jobType, text: `jobType.${jobType}` }))];
const JOB_STATUS_ITEMS = [{ key: '', text: 'general.any' }, ...Object.values(JobStatus).map(jobType => ({ key: jobType, text: `jobStatus.${jobType}` }))];
const COMPARISON_OPTIONS_BACKEND = ['<', '>', '=', '#'];
const COMPARISON_OPTIONS_FRONTEND = ['<', '>', '=', '!='];

const SearchStonebranchSearch = ({ id }) => {
  const selector = useSelector(store => store.selector)
  const datemask = useSelector(store => store.auth.serverdata.preferences[Preferences.DATEMASK])
  const preferences = useSelector(store => store.auth.serverdata.preferences)
  const dispatch = useDispatch()

  const defaultState = {
    time: {
      activeTimeTabIndex: 0,
      type: 0,
      period: 1,
      unit: 2,
      startDate: {
        value: '',
        error: ''
      },
      startTime: {
        value: '',
        error: ''
      },
      endDate: {
        value: '',
        error: ''
      },
      endTime: {
        value: '',
        error: ''
      }
    },
    displayJobsBy: '',
    jobgroup: '',
    jobname: '',
    agent: '',
    system: '',
    service: '',
    jobTypeIndex: '',
    statusIndex: '',
    workflow: '',
    runUser: '',
    filterByJobErrorSwitch: 0,
    exitCodeSwitch: 0,
    exitCodeInput: '',
    runtimeSwitch: 0,
    runtimeInput: '',
    sourceVersion: '',
    showJobgroupsDialog: false
  }

  const getComparisonOption = (preferenceName) => {
    return Math.max(COMPARISON_OPTIONS_FRONTEND.findIndex(option => option === preferences[preferenceName]), 0);
  }

  const initializeState = () => {
    if (preferences) {
      let activeTimeTabIndex = Utils.convertStringToInt(preferences[Preferences.SEARCH_STONEBRANCH_ACTIVE_TAB]) || 0
      let type = Utils.convertStringToInt(preferences[Preferences.SEARCH_STONEBRANCH_LASTTIME_MODE]) || 0
      let period = Utils.convertStringToInt(preferences[Preferences.SEARCH_STONEBRANCH_TIME_CUSTOM_LAST]) || 1
      let startDate = activeTimeTabIndex === 1 && preferences[Preferences.SEARCH_STONEBRANCH_START_DATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SEARCH_STONEBRANCH_START_DATE])
        : ''
      let startTime = preferences[Preferences.SEARCH_STONEBRANCH_START_TIME] || ''
      startTime = Utils.convertStringToInt(startTime)
      let endDate = preferences[Preferences.SEARCH_STONEBRANCH_END_DATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SEARCH_STONEBRANCH_END_DATE])
        : ''
      let endTime = preferences[Preferences.SEARCH_STONEBRANCH_END_TIME] || ''
      endTime = Utils.convertStringToInt(endTime)
      const resultIndexUnit = Math.max(UNITS.findIndex(d => d.key === preferences[Preferences.SEARCH_STONEBRANCH_TIME_CUSTOM_UNIT]), 0)
      const unit = resultIndexUnit !== -1 ? resultIndexUnit : 2
      const displayJobsBy = Math.max(DISPLAY_JOBS_BY_ITEMS.findIndex(d => d.key === preferences[Preferences.SEARCH_STONEBRANCH_USAGE]), 0)
      const jobname = preferences[Preferences.SEARCH_STONEBRANCH_JOBNAME] || ''
      const jobgroup = preferences[Preferences.SEARCH_STONEBRANCH_JOBGROUP] || ''
      const agent = preferences[Preferences.SEARCH_STONEBRANCH_AGENT] || ''
      const filterByJobErrorSwitch = Math.max(ONLY_JOB_ERRORS_ITEMS.findIndex(d => d.key === preferences[Preferences.SEARCH_STONEBRANCH_ONLY_JOB_ERRORS]), 0)
      const exitCodeSwitch = getComparisonOption(Preferences.SEARCH_STONEBRANCH_RETURN_CODE_SWITCH)
      const exitCodeInput = preferences[Preferences.SEARCH_STONEBRANCH_RETURN_CODE_INPUT] || ''
      const runtimeSwitch = getComparisonOption(Preferences.SEARCH_STONEBRANCH_RUNTIME_SWITCH)
      const runtimeInput = preferences[Preferences.SEARCH_STONEBRANCH_RUNTIME_INPUT] || ''
      const system = preferences[Preferences.SEARCH_STONEBRANCH_SYSTEM] || ''
      const service = preferences[Preferences.SEARCH_STONEBRANCH_SERVICE] || ''
      const jobTypeIndex = Math.max(JOB_TYPE_ITEMS.findIndex(jt => jt.key === preferences[Preferences.SEARCH_STONEBRANCH_JOBTYPE]), 0)
      const statusIndex = Math.max(JOB_STATUS_ITEMS.findIndex(js => js.key === preferences[Preferences.SEARCH_STONEBRANCH_STATUS]), 0)
      const workflow = preferences[Preferences.SEARCH_STONEBRANCH_WORKFLOW] || ''
      const runUser = preferences[Preferences.SEARCH_STONEBRANCH_RUN_USER] || ''
      const sourceVersion = preferences[Preferences.SEARCH_STONEBRANCH_SOURCE_VERSION] || ''

      return {
        time: {
          activeTimeTabIndex,
          type,
          period,
          unit,
          startDate: {
            value: startDate,
            error: ''
          },
          startTime: {
            value: startTime,
            error: ''
          },
          endDate: {
            value: endDate,
            error: ''
          },
          endTime: {
            value: endTime,
            error: ''
          }
        },
        displayJobsBy,
        jobgroup,
        jobname,
        agent,
        system,
        service,
        jobTypeIndex,
        statusIndex,
        workflow,
        runUser,
        filterByJobErrorSwitch,
        exitCodeSwitch,
        exitCodeInput,
        runtimeSwitch,
        runtimeInput,
        sourceVersion
      }
    }
    return defaultState
  }

  const [state, setState] = useState({
    ...initializeState()
  })

  const submitSearch = event => {
    event.preventDefault()
    const errors = validateTimecardErrors(state.time, datemask)
    setState({ ...state, time: { ...state.time, ...errors } })
    if (Object.keys(errors).length === 0) {
      const prefsToChange = {
        [Preferences.SEARCH_STONEBRANCH_ACTIVE_TAB]: state.time.activeTimeTabIndex,
        [Preferences.SEARCH_STONEBRANCH_LASTTIME_MODE]: state.time.type,
        [Preferences.SEARCH_STONEBRANCH_TIME_CUSTOM_LAST]: state.time.period,
        [Preferences.SEARCH_STONEBRANCH_TIME_CUSTOM_UNIT]: UNITS[state.time.unit].key,
        [Preferences.SEARCH_STONEBRANCH_START_DATE]: DateUtils.getRequestFormat(state.time.startDate.value, datemask),
        [Preferences.SEARCH_STONEBRANCH_START_TIME]: DateUtils.formatTimeToDefault(state.time.startTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_STONEBRANCH_END_DATE]: DateUtils.getRequestFormat(state.time.endDate.value, datemask),
        [Preferences.SEARCH_STONEBRANCH_END_TIME]: DateUtils.formatTimeToDefault(state.time.endTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_STONEBRANCH_USAGE]: DISPLAY_JOBS_BY_ITEMS[state.displayJobsBy].key,
        [Preferences.SEARCH_STONEBRANCH_ONLY_JOB_ERRORS]: ONLY_JOB_ERRORS_ITEMS[state.filterByJobErrorSwitch].key,
        [Preferences.SEARCH_STONEBRANCH_JOBGROUP]: state.jobgroup,
        [Preferences.SEARCH_STONEBRANCH_JOBNAME]: state.jobname,
        [Preferences.SEARCH_STONEBRANCH_AGENT]: state.agent,
        [Preferences.SEARCH_STONEBRANCH_SYSTEM]: state.system,
        [Preferences.SEARCH_STONEBRANCH_SERVICE]: state.service,
        [Preferences.SEARCH_STONEBRANCH_JOBTYPE]: JOB_TYPE_ITEMS[state.jobTypeIndex].key,
        [Preferences.SEARCH_STONEBRANCH_STATUS]: JOB_STATUS_ITEMS[state.statusIndex].key,
        [Preferences.SEARCH_STONEBRANCH_WORKFLOW]: state.workflow,
        [Preferences.SEARCH_STONEBRANCH_RUN_USER]: state.runUser,
        [Preferences.SEARCH_STONEBRANCH_RETURN_CODE_SWITCH]: COMPARISON_OPTIONS_FRONTEND[state.exitCodeSwitch],
        [Preferences.SEARCH_STONEBRANCH_RETURN_CODE_INPUT]: state.exitCodeInput,
        [Preferences.SEARCH_STONEBRANCH_RUNTIME_SWITCH]: COMPARISON_OPTIONS_FRONTEND[state.runtimeSwitch],
        [Preferences.SEARCH_STONEBRANCH_RUNTIME_INPUT]: state.runtimeInput,
        [Preferences.SEARCH_STONEBRANCH_SOURCE_VERSION]: state.sourceVersion,
        [Preferences.SEARCH_STONEBRANCH_LOGSOURCE]: 'STB'
      }
      const searchParams = {}
      searchParams[SEARCH_HEADERS.LOGSOURCE] = 'STB'
      searchParams[SEARCH_HEADERS.TUSAGE] = DISPLAY_JOBS_BY_ITEMS[state.displayJobsBy].key
      searchParams[SEARCH_HEADERS.JOBERR] = ONLY_JOB_ERRORS_ITEMS[state.filterByJobErrorSwitch].key
      searchParams[SEARCH_HEADERS.JOBGROUP] = state.jobgroup
      searchParams[SEARCH_HEADERS.LJOBNAME] = state.jobname
      searchParams[SEARCH_HEADERS.AGENTNAME] = state.agent
      searchParams[SEARCH_HEADERS.SYSTEMNAME] = state.system
      searchParams[SEARCH_HEADERS.JOBTYPE] = JOB_TYPE_ITEMS[state.jobTypeIndex].key
      searchParams[SEARCH_HEADERS.JOBSTATUS] = JOB_STATUS_ITEMS[state.statusIndex].key
      searchParams[SEARCH_HEADERS.SERVICE] = state.service
      searchParams[SEARCH_HEADERS.SRCVERSION] = state.sourceVersion
      searchParams[SEARCH_HEADERS.WORKFLOW] = state.workflow
      searchParams[SEARCH_HEADERS.RUNUSER] = state.runUser

      if (state.time.activeTimeTabIndex === 0) {
        if (state.time.type === 0) {
          searchParams[SEARCH_HEADERS.SDATE] = 'TODAY'
          prefsToChange[Preferences.SEARCH_STONEBRANCH_START_DATE] = 'TODAY'
        }
        else if (state.time.type === 1) {
          searchParams[SEARCH_HEADERS.SDATE] = 'YESTERDAY'
          prefsToChange[Preferences.SEARCH_STONEBRANCH_START_DATE] = 'YESTERDAY'
        }
        else if (state.time.type === 2) {
          searchParams[SEARCH_HEADERS.FROMLAST] = state.time.period
          searchParams[SEARCH_HEADERS.TUNITS] = DateUtils.UNITS[state.time.unit].key
        }
      }
      else if (state.time.activeTimeTabIndex === 1) {
        searchParams[SEARCH_HEADERS.SDATE] = DateUtils.getTimeshiftDate(state.time.startDate.value, state.time.startTime.value, DateUtils.DDMMYYYY_DOT)
        if (state.time.startTime.value !== '') {
          searchParams[SEARCH_HEADERS.STIME] = DateUtils.getTimeshiftDate(state.time.startDate.value, state.time.startTime.value, DateUtils.TIME_DATEMASK)
        }
        searchParams[SEARCH_HEADERS.EDATE] = DateUtils.getTimeshiftDate(state.time.endDate.value, state.time.endTime.value, DateUtils.DDMMYYYY_DOT)
        if (state.time.endTime.value !== '') {
          searchParams[SEARCH_HEADERS.ETIME] = DateUtils.getTimeshiftDate(state.time.endDate.value, state.time.endTime.value, DateUtils.TIME_DATEMASK)
        }
      }

      if (state.exitCodeInput !== '') {
        searchParams[SEARCH_HEADERS.JOBRC] = `${COMPARISON_OPTIONS_BACKEND[state.exitCodeSwitch]}${state.exitCodeInput}`
      }
      if (state.runtimeInput !== '') {
        searchParams[SEARCH_HEADERS.RUNTIME] = `${COMPARISON_OPTIONS_BACKEND[state.runtimeSwitch]}${state.runtimeInput}`
      }
      changePrefs(prefsToChange)(dispatch)
      getStonebranchJobs(undefined, searchParams)(dispatch)
    }
  }

  return (
    <>
      {
        state.showJobgroupsDialog &&
        <SelectorDialog
          id={`${id}_jobgroups_selector_dialog`}
          onClose={() => setState({ ...state, showJobgroupsDialog: false })}
          title={translate('definition.jobgroups')}
          header={[translate('general.jobgroup_name'), translate('general.title')]}
          items={selector.jobgroups.data}
          onSelect={selectedRows => {
            if (selectedRows.length > 0) {
              const newJobgroup = selector.jobgroups.data[selectedRows][selector.jobgroups.header.indexOf('JGIGNAME')]
              setState({ ...state, jobgroup: newJobgroup, showJobgroupsDialog: false })
            }
          }}
        />
      }
      <form
        id={id}
        className={'bux_drawer_form'}
        onSubmit={submitSearch}>
        <Main id={id} state={state} setState={setState} />
        <Footer id={id} search={submitSearch} reset={() => setState(defaultState)} />
      </form>
    </>
  )
}

const Main = ({ id, state, setState }) => {
  const lang = useSelector(store => store.auth.serverdata.preferences[Preferences.LANGUAGE])
  const datemask = useSelector(store => store.auth.serverdata.preferences[Preferences.DATEMASK])
  /**
   * @description Handles the changes in timecard.
   * @param {String} key The key of the input in state.
   * @param {String} val The new value of the input.
   * @param {String} err The new error of the input.
   */
  const handleTimeCardChange = (key, val, err) => {
    setState(state => ({
      ...state,
      time: {
        ...state.time,
        [key]: typeof state.time[key] === 'object'
          ? {
            value: val,
            error: err || ''
          }
          : val
      }
    }))
  }

  /**
   * @description Returns the values for the timecard.
   * @returns {Object} The timecard values.
   */
  const timeCardValues = () => {
    return {
      tabIndex: state.time.activeTimeTabIndex,
      lastTimeModeIndex: state.time.type,
      customLast: state.time.period,
      customUnitIndex: state.time.unit,
      fromDate: {
        value: state.time.startDate.value,
        error: state.time.startDate.error
      },
      fromTime: {
        value: state.time.startTime.value,
        error: state.time.startTime.error
      },
      toDate: {
        value: state.time.endDate.value,
        error: state.time.endDate.error
      },
      toTime: {
        value: state.time.endTime.value,
        error: state.time.endTime.error
      }
    }
  }

  /**
   * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
   * Needed for update function inside the TimeCard.
   * @returns {Object} The object with the state keys.
   */
  const timeCardStateKeys = () => {
    return {
      tabIndex: 'activeTimeTabIndex',
      lastTimeModeIndex: 'type',
      customLast: 'period',
      customUnitIndex: 'unit',
      fromDate: 'startDate',
      fromTime: 'startTime',
      toDate: 'endDate',
      toTime: 'endTime',
    }
  }

  return (
    <div
      id={`${id}_main`}
      className={'bux_drawer_main'}>
      <TimeCard
        id={id}
        lang={lang}
        datemask={datemask}
        values={timeCardValues()}
        stateKeys={timeCardStateKeys()}
        onValuesChange={handleTimeCardChange}
        parentContainer={'drawer_content_search_body_main'}
        translate={key => translate(key)}
      />
      <SelectBy id={id} state={state} setState={setState} />
      <Jobdata id={id} state={state} setState={setState} />
      <Additional id={id} state={state} setState={setState} />
    </div>
  )
}

const SelectBy = ({ id, state, setState }) => {
  return (
    <Card id={`${id}_select_by`} title={translate('search.select_by')}>
      <Row>
        <Column colMD={12}>
          <Switch
            id={`${id}_display_jobs_by`}
            title={translate('general.search.header.date_field')}
            items={DISPLAY_JOBS_BY_ITEMS.map(d => translate(d.translationKey))}
            activeIndex={state.displayJobsBy}
            onClick={index => setState({ ...state, displayJobsBy: index })}
            maxPerRow={3}
          />
        </Column>
      </Row>
    </Card>
  )
}

const Jobdata = ({ id, state, setState }) => {

  const dispatch = useDispatch()
  const handleJobgroupSelector = () => {
    getJobgroups(
      [SEARCH_HEADERS.JGIGNAME, SEARCH_HEADERS.JGITITLE],
      { [SEARCH_HEADERS.JGIGNAME]: state.jobgroup },
      () => setState({ ...state, showJobgroupsDialog: true })
    )(dispatch)
  }

  return (
    <Card id={`${id}_job_data`} title={translate('general.search.label.job_data')}>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_jobgroup`}
            onInputChanged={value => setState({ ...state, jobgroup: value })}
            value={state.jobgroup}
            title={translate('general.search.header.job_group')}
            maxLength={80}
            addon={{
              iconName: 'list',
              onClick: () => handleJobgroupSelector()
            }}
          />

          <Input
            id={`${id}_jobname`}
            onInputChanged={val => setState({ ...state, jobname: val })}
            value={state.jobname}
            title={translate('general.search.header.job_name')}
            maxLength={255}
          />

          <Input
            id={`${id}_agent`}
            onInputChanged={val => setState({ ...state, agent: val })}
            value={state.agent}
            title={translate('general.search.header.agent')}
            maxLength={255}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Input
            id={`${id}_service`}
            onInputChanged={val => setState({ ...state, service: val })}
            value={state.service}
            title={translate('general.search.header.service')}
            maxLength={40}
          />

        </Column>
      </Row>
      <Row>
        <Column>
          <Dropdown
            id={`${id}_jobtype`}
            items={JOB_TYPE_ITEMS.map((job) => ({ key: job.key, text: translate(job.text) }))}
            activeIndex={state.jobTypeIndex}
            onChange={(activeIndex) => { setState({ ...state, jobTypeIndex: activeIndex }) }}
            title={translate('general.search.header.job_type')}
          />
        </Column>
        <Column>
          <Dropdown
            id={`${id}_status`}
            items={JOB_STATUS_ITEMS.map((status) => ({ key: status.key, text: translate(status.text) }))}
            activeIndex={state.statusIndex}
            onChange={(activeIndex) => { setState({ ...state, statusIndex: activeIndex }) }}
            title={translate('general.search.header.job_status')}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Input
            id={`${id}_workflow`}
            onInputChanged={val => setState({ ...state, workflow: val })}
            value={state.workflow}
            title={translate('general.search.header.workflow')}
            maxLength={255}
          />

          <Input
            id={`${id}_executionUser`}
            onInputChanged={val => setState({ ...state, runUser: val })}
            value={state.runUser}
            title={translate('general.search.header.execution_user')}
            maxLength={100}
          />

          <Switch
            id={`${id}_only_job_errors`}
            title={translate('general.search.header.filter_by_job_errors')}
            maxPerRow={3}
            items={ONLY_JOB_ERRORS_ITEMS.map(el => translate(el.translationKey))}
            onClick={index => setState({ ...state, filterByJobErrorSwitch: index })}
            activeIndex={state.filterByJobErrorSwitch}
          />
        </Column>
      </Row>
    </Card>
  )
}

const Additional = ({ id, state, setState }) => {

  const changeNumeric = (key, val) => {
    if (val === '' || val.match(/^[0-9]*$/)) {
      setState({ ...state, [key]: val })
    }
  }

  return (
    <Card id={`${id}_additional`} title={translate('general.additional')}>
      <Row>
        <Column>
          <Switch
            id={`${id}_exit_code`}
            title={translate('general.search.header.exit_code')}
            activeIndex={state.exitCodeSwitch}
            onClick={index => setState({ ...state, exitCodeSwitch: index })}
            items={COMPARISON_OPTIONS_FRONTEND}
            maxPerRow={4}
          />
        </Column>
        <Column>
          <Input
            id={`${id}_exit_code`}
            title={' '}
            value={state.exitCodeInput}
            onInputChanged={value => changeNumeric('exitCodeInput', value)}
            maxLength={40}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Switch
            id={`${id}_runtime`}
            title={translate('general.search.header.runtime')}
            activeIndex={state.runtimeSwitch}
            onClick={index => setState({ ...state, runtimeSwitch: index })}
            items={COMPARISON_OPTIONS_FRONTEND}
            maxPerRow={4}
          />
        </Column>
        <Column>
          <Input
            id={`${id}_runtime`}
            title={' '}
            value={state.runtimeInput}
            onInputChanged={value => changeNumeric('runtimeInput', value)}
            maxLength={11}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Input
            id={`${id}_sourceversion`}
            title={translate('general.search.header.source_version')}
            value={state.sourceVersion}
            onInputChanged={value => setState({ ...state, sourceVersion: value })}
            maxLength={6}
          />
        </Column>
      </Row>
    </Card>
  )
}

const Footer = ({ id, search, reset }) => {
  return (
    <div id={`${id}_footer`} className='bux_drawer_footer'>
      <Button
        id={`${id}_search`}
        text={translate('general.search')}
        onClick={search}
        submit
        primary
      />
      <Button
        id={`${id}_resetBtn`}
        icon='undo'
        iconType='material'
        onClick={reset}
      />
    </div>
  )
}

export default SearchStonebranchSearch