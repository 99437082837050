import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as Utils from 'utils/Utils'

// components
import {
  Button,
  Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent,
  Row,
  Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import { updateDocumentNode } from '../../../redux/actions/DocumentNodesDefinitionActions'
import { getDocumentNodeHierarchy } from '../../../redux/actions/DocumentNodesHierarchyActions'

class ModifyDocumentNodeDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    showParentNodeDialog: false,
    generalTab: {
      parentNodeID: '',
      owner: '',
      identifier: {
        value: '',
        error: ''
      },
      description: '',
      childIdentifier: ''
    }
  }

  identifierInput = React.createRef()

  componentDidMount = () => {
    const { documentNode } = this.props
    this.setState({
      generalTab: {
        parentNodeID: documentNode.DNDPNAME,
        owner: documentNode.OWNER,
        identifier: {
          value: documentNode.DNDENAME,
          error: ''
        },
        description: documentNode.DNDDESC,
        childIdentifier: documentNode.DNDCNAME
      }
    })
  }

  /**
   * @description Handles the input changes of the input fields.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeGeneralTab = (key, value, error) => {
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        [key]: typeof this.state.generalTab[key] === 'object'
          ? { value, error }
          : value
      }
    })
  }

  /**
   * @description Handles the input changes of the parentnodeid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeGeneralTabWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleChangeGeneralTab(key, value, error)
  }

  /**
   * @description Handles the node id selector button
   */
  handleParentNodeSelector = () => {
    this.props.getDocumentNodesDefinition(
      ['DNDNAME', 'DNDENAME'],
      this.state.generalTab.parentNodeID,
      () => this.setState({ showParentNodeDialog: true })
    )
  }

  /**
  * @description Renders the selector dialogs.
  */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    return (
      <>
        {this.state.showParentNodeDialog && (
          <SelectorDialog
            id={`${id}_nodeselector_dialog`}
            onClose={() => this.setState({ showParentNodeDialog: false })}
            title={translate('definition.document_node_definitions')}
            header={[
              translate('definition.node_id'),
              translate('general.identifier')
            ]}
            items={selector.documentnodes.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newParentNodeID = selector.documentnodes.data[selectedRows][selector.documentnodes.header.indexOf('DNDNAME')]
                this.setState({
                  generalTab: {
                    ...this.state.generalTab,
                    parentNodeID: newParentNodeID
                  }
                })
              }
              this.setState({ showParentNodeDialog: false })
            }}
          />
        )}
      </>
    )
  }

  /**
  * @description Validates the identifier
  */
  validateIdentifier = () => {
    const { generalTab } = this.state
    if (generalTab.identifier.value !== '') {
      return {}
    }
    return {
      identifier: {
        ...this.state.generalTab.identifier,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the general tab
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateIdentifier()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState(state => ({ generalTab: { ...state.generalTab, ...validatorResult } }), () => {
        this.handleGeneralTabFocus()
      })
    }

    return errors === 0
  }

  /**
   * @description Sets the focus on the inputs with error
   */
  handleGeneralTabFocus = () => {
    const { generalTab } = this.state
    const requiredInputs = [
      { inputRef: this.identifierInput, error: generalTab.identifier.error }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Renders the modal header
   */
  renderHeader = () => {
    const { id, documentNode } = this.props
    const type = documentNode.DNDTYPE === 'DND' ? translate('general.node') : translate('group.group')
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.node_id'), value: documentNode.DNDNAME },
          { label: translate('general.type'), value: type},
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description Renders the general tab
   */
  renderGeneralTab = () => {
    const { id } = this.props
    const { generalTab } = this.state

    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_parentnodeid`}
              onInputChanged={value => this.handleChangeGeneralTabWithoutSpaces('parentNodeID', value)}
              value={generalTab.parentNodeID}
              title={translate('general.parent_node_id')}
              maxLength={25}
              addon={{
                iconName: 'list',
                onClick: () => this.handleParentNodeSelector()
              }}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_owner`}
              value={generalTab.owner}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={value => this.handleChangeGeneralTab('owner', value)}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_identifier`}
              value={generalTab.identifier.value}
              title={translate('general.identifier')}
              ref={this.identifierInput}
              maxLength={64}
              onInputChanged={(value, error) => this.handleChangeGeneralTab('identifier', value, error)}
              error={generalTab.identifier.error}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateIdentifier() } }))}
              required={`${translate('general.required_field')}`}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_description`}
              value={generalTab.description}
              title={translate('general.description')}
              maxLength={64}
              onInputChanged={value => this.handleChangeGeneralTab('description', value)}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_childidentifier`}
              value={generalTab.childIdentifier}
              title={translate('general.child_identifier')}
              maxLength={64}
              onInputChanged={value => this.handleChangeGeneralTab('childIdentifier', value)}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the error tabs and displays icon
   */
  handleErrorTabs = () => {
    const { generalTab } = this.state
    const buffer = []
    if (generalTab.identifier.error !== '') {
      buffer.push(0)
    }
    return buffer
  }

  /**
   * @description Handles the save button
   */
  handleSave = () => {
    const { generalTab } = this.state
    const { updateDocumentNode, documentNode, onClose, hierarchyView, updateHierarchy, getDocumentNodeHierarchy } = this.props
    const errorTabs = [
      this.validateGeneralTab()
    ]
    if (errorTabs.every(d => d)) {
      const documentNodeObj = {
        DNDNAME: documentNode.DNDNAME,
        DNDPNAME: generalTab.parentNodeID,
        DNDENAME: generalTab.identifier.value,
        DNDTYPE: documentNode.DNDTYPE,
        DNDCNAME: generalTab.childIdentifier,
        DNDDESC: generalTab.description,
        OWNER: generalTab.owner
      }
      updateDocumentNode(documentNodeObj, () => {
        hierarchyView
          ? getDocumentNodeHierarchy(
            {
              DNDNAME: documentNode.DNDNAME,
              DNDPNAME: generalTab.parentNodeID
            },
            createdDocumentNode => {
              updateHierarchy(createdDocumentNode, generalTab.parentNodeID !== '' ? generalTab.parentNodeID : undefined)
            }
          )
          : onClose()
      })
    }
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <>
        {this.renderSelectorDialogs()}
        <Modal
          id={'modify_document_node'}
          onClose={onClose}>
          <Header
            id={id}
            title={translate('definition.modify_document_node')}
            onClose={onClose}>
            {this.renderHeader()}
          </Header>
          <Main>
            <Tabs
              disabledTabs={[1]}
              errorTabs={this.handleErrorTabs()}>
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab title={translate('definition.document')}>

              </Tab>
            </Tabs>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    selector: state.selector,
    documentNode: state.definitions.documentNodes.documentNode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDocumentNodesDefinition: (fields, nodeName, callback) => {
      ModalSelectorActions.getDocumentNodesDefinition(fields, nodeName, callback)(dispatch)
    },
    updateDocumentNode: (documentNode, callback) => {
      updateDocumentNode(documentNode, callback)(dispatch)
    },
    getDocumentNodeHierarchy: (documentNodeObj, callback) => {
      getDocumentNodeHierarchy(documentNodeObj, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyDocumentNodeDialog)