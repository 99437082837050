
import * as Lang from 'language/Language'

import {
  Button, Column, Dropdown, Input, NumericSpinner, Row, Switch
} from 'BetaUX2Web-Components/src/'
import Datepicker from 'components/datepicker/Datepicker'
import Timepicker from 'components/timepicker/Timepicker'
import { LetterCase } from 'BetaUX2Web-Components/src/types'

export const uppercaseElements = [
  'RECI',
  'FOLDER',
  'NODE',
  'FORM',
  'FORMEXT',
  'EXTENSION',
  'REPORT',
  'JOBNAME',
  'JOBGROUP',
  'OBJECTTYPE',
  'PARENTNAME',
  'AGENTNAME',
]


/**
 * @description Renders the identifier config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Function} changeConfigValue Function to change the config value.
 */
export const Identifier = ({ id, configKey, currentConfig, changeConfigValue }) => {
  const identifierOperators = [
    ['LB', Lang.translate('general.label')],
    ['LF', Lang.translate('general.labeled_frame')],
    ['FL', Lang.translate('general.left')],
    ['FR', Lang.translate('general.right')]
  ]

  return (
    <>
      <Row>
        <Column colMD={12}>
          <Switch
            id={`${id}_type`}
            title={Lang.translate('general.type')}
            items={identifierOperators.map(operator => operator[1])}
            onClick={index => changeConfigValue(configKey, 'operator', identifierOperators[index][0])}
            activeIndex={identifierOperators.findIndex(operator => currentConfig.operator === operator[0])}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_identifier`}
            title={Lang.translate('general.identifier')}
            value={currentConfig.identifier}
            maxLength={50}
            onInputChanged={value => changeConfigValue(configKey, 'identifier', value)}
          />
        </Column>
      </Row>
    </>
  )
}

const indexString = (id, currentConfig, configKey, stateKey, changeConfigValue, validateValueInput) => {
  return (
    <Input
      id={`${id}_${stateKey}`}
      key={`${id}_${configKey}_equal_value`}
      ref={currentConfig[stateKey].ref}
      title={Lang.translate('general.value')}
      value={currentConfig[stateKey].value}
      error={currentConfig[stateKey].error}
      maxLength={currentConfig.width}
      onInputChanged={value => changeConfigValue(configKey, stateKey, value)}
      required={currentConfig.required && currentConfig.disabled}
      onBlur={() => validateValueInput(configKey)}
      letterCase={currentConfig.uppercase? LetterCase.uppercase: undefined}
    />
  )
}

const indexDate = (id, currentConfig, configKey, stateKey, changeConfigValue, validateValueInput, lang, datemask) => {
  return (
    <Datepicker
      id={`${id}_${stateKey}`}
      key={currentConfig.key}
      title={Lang.translate('general.value')}
      focusRef={currentConfig[stateKey].ref}
      value={currentConfig[stateKey].value}
      error={currentConfig[stateKey].error}
      onChange={val => changeConfigValue(configKey, stateKey, val, '')}
      language={lang}
      dateFormat={datemask}
      onInvalidDate={val => changeConfigValue(configKey, stateKey, val, Lang.translate('general.please_enter_a_valid_date'))}
      removeStateError={val => changeConfigValue(configKey, stateKey, val, '')}
      required={currentConfig.required && currentConfig.disabled}
      onBlur={() => validateValueInput(configKey)}
    />
  )
}

/**
 * @description Renders the index config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 */
export const IndexDefault = ({ id, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType, validateValueInput, changeConfigValueNumeric, lang, datemask }) => {
  const getType = type => {
    switch (type) {
      case 'INDEXDEFAULT': return 'STRING'
      case 'INDEXLCHOICE': return 'LCHOICE'
      case 'INDEXCCHOICE': return 'CCHOICE'
      case 'INDEXNUMERIC': return 'NUMERIC'
      case 'INDEXDATE': return 'DATE'
      case 'INDEXRNUM': return 'RNUM'
      default: return type
    }
  }
  return (
    <>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_indexname`}
            title={Lang.translate('general.indexname')}
            ref={indexNameInput}
            value={currentConfig.indexName}
            maxLength={8}
            disabled
            // ! To avoid prop types error
            onInputChanged={() => {}}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={6}>
          <Dropdown
            id={`${id}_data_format`}
            title={Lang.translate('general.data_format')}
            items={types}
            activeIndex={types.findIndex(el => el === getType(currentConfig.type))}
            onChange={value => changeType(configKey, types[value])}
            containerID={`${id}_config_tilecontainer`}
            disabled={currentConfig.operator === 'RA'}
          />
        </Column>
        {
          currentConfig.type !== 'INDEXDATE' &&
          <Column colMD={6}>
            <NumericSpinner
              id={`${id}_length`}
              title={Lang.translate('definition.length_input_field')}
              value={currentConfig.width}
              max={199}
              steps={1}
              min={1}
              onChange={value => changeConfigValueNumeric(configKey, 'width', value)}
            />
          </Column>
        }
      </Row>
      <Row>
        <Column colMD={12}>
          <Switch
            id={`${id}_operator`}
            title={Lang.translate('general.operator')}
            onClick={index => changeConfigValue(configKey, 'operator', { 0: 'EQ', 1: 'RA' }[index])}
            activeIndex={{ 'EQ': 0, 'RA': 1 }[currentConfig.operator]}
            items={[Lang.translate('general.equal'), Lang.translate('general.range')]}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_identifier`}
            title={Lang.translate('general.identifier')}
            value={currentConfig.identifier}
            maxLength={50}
            onInputChanged={value => changeConfigValue(configKey, 'identifier', value)}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          {
            currentConfig.type === 'INDEXDATE'
              ? indexDate(id, currentConfig, configKey, 'value', changeConfigValue, validateValueInput, lang, datemask)
              : indexString(id, currentConfig, configKey, 'value', changeConfigValue, validateValueInput)
          }
        </Column>
      </Row>
      <Row>
        <Column colMD={6}>
          <Switch
            id={`${id}_required`}
            title={Lang.translate('general.required')}
            onClick={index => changeConfigValue(configKey, 'required', index === 0)}
            activeIndex={currentConfig.required ? 0 : 1}
            items={[Lang.translate('general.yes'), Lang.translate('general.no')]}
          />
        </Column>
        <Column colMD={6}>
          <Switch
            id={`${id}_disabled`}
            title={Lang.translate('general.disabled')}
            onClick={index => changeConfigValue(configKey, 'disabled', index === 0)}
            activeIndex={currentConfig.disabled ? 0 : 1}
            items={[Lang.translate('general.yes'), Lang.translate('general.no')]}
          />
        </Column>
      </Row>
      {
        currentConfig.operator === 'RA' &&
        <>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_identifier`}
                ref={currentConfig.identifier2.ref}
                title={Lang.translate('general.identifier')}
                value={currentConfig.identifier2.value}
                error={currentConfig.identifier2.error}
                maxLength={50}
                onInputChanged={value => changeConfigValue(configKey, 'identifier2', value)}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              {
                currentConfig.type === 'INDEXDATE'
                  ? indexDate(id, currentConfig, configKey, 'value2', changeConfigValue, validateValueInput, lang, datemask)
                  : indexString(id, currentConfig, configKey, 'value2', changeConfigValue, validateValueInput)
              }
            </Column>
          </Row>
          <Row>
            <Column colMD={6}>
              <Switch
                id={`${id}_required`}
                title={Lang.translate('general.required')}
                onClick={index => changeConfigValue(configKey, 'required2', index === 0)}
                activeIndex={currentConfig.required2 ? 0 : 1}
                items={[Lang.translate('general.yes'), Lang.translate('general.no')]}
              />
            </Column>
            <Column colMD={6}>
              <Switch
                id={`${id}_disabled`}
                title={Lang.translate('general.disabled')}
                onClick={index => changeConfigValue(configKey, 'disabled2', index === 0)}
                activeIndex={currentConfig.disabled2 ? 0 : 1}
                items={[Lang.translate('general.yes'), Lang.translate('general.no')]}
              />
            </Column>
          </Row>
        </>
      }
    </>
  )
}

/**
 * @description Renders the cchoice hchoice config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 * @param {Function} addAdditionalField Function to add an additional field.
 * @param {Function} changeAdditionalField Function to change an additional field.
 * @param {Function} deleteAdditionalField Function to delete an additional field.
 * @param {Function} onBlurAdditionalField Function to onBlur an additional field.
 */
export const IndexCChoice = ({ id, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType, addAdditionalField, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, validateValueInput }) => (
  <>
    <IndexDefault
      id={id}
      configKey={configKey}
      currentConfig={currentConfig}
      indexNameInput={indexNameInput}
      types={types}
      changeConfigValue={changeConfigValue}
      changeType={changeType}
      validateValueInput={validateValueInput}
    />
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_db_select`}
          title={Lang.translate('definition.dbselect')}
          value={currentConfig.dbSelect.value}
          error={currentConfig.dbSelect.error}
          ref={currentConfig.dbSelect.ref}
          maxLength={128}
          onInputChanged={value => changeConfigValue(configKey, 'dbSelect', value)}
          onBlur={() => validateValueInput(configKey)}
          required
        />
      </Column>
    </Row>
    {renderAdditionalFields(id, configKey, currentConfig, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField)}
    <Row>
      <Column colMD={12}>
        <Button
          id={`${id}_add_additional_field`}
          icon={'add'}
          tooltip={Lang.translate('definition.add_additional_field')}
          onClick={() => addAdditionalField(configKey)}
          disabled={currentConfig.additionalFields.length >= 10}
        />
      </Column>
    </Row>
  </>
)

/**
 * @description Renders the lchoice config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 * @param {Function} addAdditionalField Function to add an additional field.
 * @param {Function} changeAdditionalField Function to change an additional field.
 * @param {Function} deleteAdditionalField Function to delete an additional field.
 * @param {Function} onBlurAdditionalField Function to onBlur an additional field.
 */
export const IndexLChoice = ({ id, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType, addAdditionalField, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, validateValueInput }) => (
  <>
    <IndexDefault
      id={id}
      configKey={configKey}
      currentConfig={currentConfig}
      indexNameInput={indexNameInput}
      types={types}
      changeConfigValue={changeConfigValue}
      changeType={changeType}
      validateValueInput={validateValueInput}
    />
    {renderAdditionalFields(id, configKey, currentConfig, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, false)}
    <Row>
      <Column colMD={12}>
        <Button
          id={`${id}_add_additional_field`}
          icon={'add'}
          tooltip={Lang.translate('definition.add_additional_field')}
          onClick={() => addAdditionalField(configKey)}
          disabled={currentConfig.additionalFields.length >= 10}
        />
      </Column>
    </Row>
  </>
)

/**
 * @description Renders the default choice config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 */
export const DefaultChoice = ({ id, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeConfigValueNumeric, changeType, validateValueInput }) => {
  return (
    <>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_indexname`}
            title={Lang.translate('general.indexname')}
            ref={indexNameInput}
            value={currentConfig.indexName}
            maxLength={8}
            disabled
            // To avoid prop type errors
            onInputChanged={() => {}}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={6}>
          <Dropdown
            id={`${id}_data_format`}
            title={Lang.translate('general.data_format')}
            items={types}
            activeIndex={types.findIndex(el => el === currentConfig.type)}
            onChange={value => changeType(configKey, types[value])}
            containerID={`${id}_config_tilecontainer`}
          />
        </Column>
        <Column colMD={6}>
          <NumericSpinner
            id={`${id}_length`}
            title={Lang.translate('definition.length_input_field')}
            value={currentConfig.width}
            max={199}
            steps={1}
            min={1}
            onChange={value => changeConfigValueNumeric(configKey, 'width', value)}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_identifier`}
            title={Lang.translate('general.identifier')}
            value={currentConfig.identifier}
            maxLength={50}
            onInputChanged={value => changeConfigValue(configKey, 'identifier', value)}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_value`}
            key={`${id}_value_${configKey}`}
            title={Lang.translate('general.value')}
            value={currentConfig.value.value}
            error={currentConfig.value.error}
            ref={currentConfig.value.ref}
            maxLength={currentConfig.width}
            onInputChanged={value => currentConfig.type === 'NUMERIC' ? changeConfigValueNumeric(configKey, 'value', value) : changeConfigValue(configKey, 'value', value)}
            required={currentConfig.required && currentConfig.disabled}
            onBlur={() => validateValueInput(configKey)}
            letterCase={!!currentConfig.uppercase ? LetterCase.uppercase : undefined} // Value is '*' (asterisk) for uppercase and '' (empty string) for lowercase
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={6}>
          <Switch
            id={`${id}_required`}
            title={Lang.translate('general.required')}
            onClick={index => changeConfigValue(configKey, 'required', index === 0)}
            activeIndex={currentConfig.required ? 0 : 1}
            items={[
              Lang.translate('general.yes'),
              Lang.translate('general.no')
            ]}
          />
        </Column>
        <Column colMD={6}>
          <Switch
            id={`${id}_disabled`}
            title={Lang.translate('general.disabled')}
            onClick={index => changeConfigValue(configKey, 'disabled', index === 0)}
            activeIndex={currentConfig.disabled ? 0 : 1}
            items={[
              Lang.translate('general.yes'),
              Lang.translate('general.no')
            ]}
          />
        </Column>
      </Row>
    </>
  )
}

/**
 * @description Renders the fileopen choice config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 */
export const FileOpen = ({ id, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType }) => (
  <>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_indexname`}
          title={Lang.translate('general.indexname')}
          ref={indexNameInput}
          value={currentConfig.indexName}
          maxLength={8}
          disabled
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Dropdown
          id={`${id}_data_format`}
          title={Lang.translate('general.data_format')}
          items={types}
          activeIndex={types.findIndex(el => el === currentConfig.type)}
          onChange={value => changeType(configKey, types[value])}
          containerID={`${id}_config_tilecontainer`}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_identifier`}
          title={Lang.translate('general.identifier')}
          value={currentConfig.identifier}
          maxLength={50}
          onInputChanged={value => changeConfigValue(configKey, 'identifier', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Switch
          id={`${id}_required`}
          title={Lang.translate('general.required')}
          onClick={index => changeConfigValue(configKey, 'required', index === 0)}
          activeIndex={0}
          items={[
            Lang.translate('general.yes'),
            Lang.translate('general.no')
          ]}
          disabled
        />
      </Column>
    </Row>
  </>
)


/**
 * @description Renders the dtframe config.
 * @param {String} id The id of the component.
 * @param {String} lang The current language.
 * @param {String} datemask The current datemask.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 */
export const DTFrame = ({ id, lang, datemask, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType }) => (
  <>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_indexname`}
          title={Lang.translate('general.indexname')}
          ref={indexNameInput}
          value={currentConfig.indexName}
          maxLength={8}
          disabled
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={6}>
        <Dropdown
          id={`${id}_data_format`}
          title={Lang.translate('general.data_format')}
          items={types}
          activeIndex={types.findIndex(el => el === currentConfig.type)}
          onChange={value => changeType(configKey, types[value])}
          containerID={`${id}_config_tilecontainer`}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_identifier`}
          title={Lang.translate('general.identifier')}
          value={currentConfig.identifier}
          maxLength={50}
          onInputChanged={value => changeConfigValue(configKey, 'identifier', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Datepicker
          id={`${id}_from`}
          title={Lang.translate('definition.start_date')}
          focusRef={currentConfig.value.ref}
          value={currentConfig.value.value}
          error={currentConfig.value.error}
          onChange={val => changeConfigValue(configKey, 'value', val, '')}
          language={lang}
          dateFormat={datemask}
          onInvalidDate={value => changeConfigValue(configKey, 'value', value, Lang.translate('general.please_enter_a_valid_date'))}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Datepicker
          id={`${id}_to`}
          title={Lang.translate('definition.end_date')}
          focusRef={currentConfig.value2.ref}
          value={currentConfig.value2.value}
          error={currentConfig.value2.error}
          onChange={val => changeConfigValue(configKey, 'value2', val, '')}
          language={lang}
          dateFormat={datemask}
          onInvalidDate={value => changeConfigValue(configKey, 'value2', value, Lang.translate('general.please_enter_a_valid_date'))}
        />
      </Column>
    </Row>
  </>
)

/**
 * @description Renders the datedate config.
 * @param {String} id The id of the component.
 * @param {String} lang The current language.
 * @param {String} datemask The current datemask.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 */
export const DateDate = ({ id, lang, datemask, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType, validateValueInput }) => (
  <>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_indexname`}
          title={Lang.translate('general.indexname')}
          ref={indexNameInput}
          value={currentConfig.indexName}
          maxLength={8}
          disabled
          // prop types error avoiding
          onInputChanged={() => {}}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={6}>
        <Dropdown
          id={`${id}_data_format`}
          title={Lang.translate('general.data_format')}
          items={types}
          activeIndex={types.findIndex(el => el === currentConfig.type)}
          onChange={value => changeType(configKey, types[value])}
          containerID={`${id}_config_tilecontainer`}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_identifier`}
          title={Lang.translate('general.identifier')}
          value={currentConfig.identifier}
          maxLength={50}
          onInputChanged={value => changeConfigValue(configKey, 'identifier', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Datepicker
          id={`${id}_value`}
          key={currentConfig.key}
          title={Lang.translate('general.value')}
          focusRef={currentConfig.value.ref}
          value={currentConfig.value.value}
          error={currentConfig.value.error}
          onChange={val => changeConfigValue(configKey, 'value', val, '')}
          language={lang}
          dateFormat={datemask}
          onInvalidDate={val => changeConfigValue(configKey, 'value', val, Lang.translate('general.please_enter_a_valid_date'))}
          removeStateError={val => changeConfigValue(configKey, 'value', val, '')}
          required={currentConfig.required && currentConfig.disabled}
          onBlur={() => validateValueInput(configKey)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={6}>
        <Switch
          id={`${id}_required`}
          title={Lang.translate('general.required')}
          onClick={index => changeConfigValue(configKey, 'required', index === 0)}
          activeIndex={currentConfig.required ? 0 : 1}
          items={[
            Lang.translate('general.yes'),
            Lang.translate('general.no')
          ]}
        />
      </Column>
      <Column colMD={6}>
        <Switch
          id={`${id}_disabled`}
          title={Lang.translate('general.disabled')}
          onClick={index => changeConfigValue(configKey, 'disabled', index === 0)}
          activeIndex={currentConfig.disabled ? 0 : 1}
          items={[
            Lang.translate('general.yes'),
            Lang.translate('general.no')
          ]}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_identifier2`}
          ref={currentConfig.identifier2.ref}
          title={Lang.translate('general.identifier')}
          value={currentConfig.identifier2.value}
          error={currentConfig.identifier2.error}
          maxLength={50}
          onInputChanged={value => changeConfigValue(configKey, 'identifier2', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Datepicker
          id={`${id}_value2`}
          key={currentConfig.key}
          title={Lang.translate('general.value')}
          focusRef={currentConfig.value2.ref}
          value={currentConfig.value2.value}
          error={currentConfig.value2.error}
          onChange={val => changeConfigValue(configKey, 'value2', val, '')}
          language={lang}
          dateFormat={datemask}
          onInvalidDate={val => changeConfigValue(configKey, 'value2', val, Lang.translate('general.please_enter_a_valid_date'))}
          removeStateError={val => changeConfigValue(configKey, 'value2', val, '')}
          required={currentConfig.required2 && currentConfig.disabled2}
          onBlur={() => validateValueInput(configKey)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={6}>
        <Switch
          id={`${id}_required2`}
          title={Lang.translate('general.required')}
          onClick={index => changeConfigValue(configKey, 'required2', index === 0)}
          activeIndex={currentConfig.required2 ? 0 : 1}
          items={[
            Lang.translate('general.yes'),
            Lang.translate('general.no')
          ]}
        />
      </Column>
      <Column colMD={6}>
        <Switch
          id={`${id}_disabled2`}
          title={Lang.translate('general.disabled')}
          onClick={index => changeConfigValue(configKey, 'disabled2', index === 0)}
          activeIndex={currentConfig.disabled2 ? 0 : 1}
          items={[
            Lang.translate('general.yes'),
            Lang.translate('general.no')
          ]}
        />
      </Column>
    </Row>
  </>
)

/**
 * @description Renders the datetime config.
 * @param {String} id The id of the component.
 * @param {String} lang The current language.
 * @param {String} datemask The current datemask.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 */
export const DateTime = ({ id, lang, datemask, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType, validateValueInput }) => (
  <>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_indexname`}
          title={Lang.translate('general.indexname')}
          ref={indexNameInput}
          value={currentConfig.indexName}
          maxLength={8}
          disabled
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={6}>
        <Dropdown
          id={`${id}_data_format`}
          title={Lang.translate('general.data_format')}
          items={types}
          activeIndex={types.findIndex(el => el === currentConfig.type)}
          onChange={value => changeType(configKey, types[value])}
          containerID={`${id}_config_tilecontainer`}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_identifier`}
          title={Lang.translate('general.identifier')}
          value={currentConfig.identifier}
          maxLength={50}
          onInputChanged={value => changeConfigValue(configKey, 'identifier', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Datepicker
          id={`${id}_value`}
          key={currentConfig.key}
          title={Lang.translate('general.value')}
          focusRef={currentConfig.value.ref}
          value={currentConfig.value.value}
          error={currentConfig.value.error}
          onChange={val => changeConfigValue(configKey, 'value', val, '')}
          language={lang}
          dateFormat={datemask}
          onInvalidDate={value => changeConfigValue(configKey, 'value', value, Lang.translate('general.please_enter_a_valid_date'))}
          removeStateError={val => changeConfigValue(configKey, 'value', val, '')}
          required={currentConfig.required && currentConfig.disabled}
          onBlur={() => validateValueInput(configKey)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={6}>
        <Switch
          id={`${id}_required`}
          title={Lang.translate('general.required')}
          onClick={index => changeConfigValue(configKey, 'required', index === 0)}
          activeIndex={currentConfig.required ? 0 : 1}
          items={[
            Lang.translate('general.yes'),
            Lang.translate('general.no')
          ]}
        />
      </Column>
      <Column colMD={6}>
        <Switch
          id={`${id}_disabled`}
          title={Lang.translate('general.disabled')}
          onClick={index => changeConfigValue(configKey, 'disabled', index === 0)}
          activeIndex={currentConfig.disabled ? 0 : 1}
          items={[
            Lang.translate('general.yes'),
            Lang.translate('general.no')
          ]}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_identifier2`}
          ref={currentConfig.identifier2.ref}
          title={Lang.translate('general.identifier')}
          value={currentConfig.identifier2.value}
          error={currentConfig.identifier2.error}
          maxLength={50}
          onInputChanged={value => changeConfigValue(configKey, 'identifier2', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Timepicker
          id={`${id}_value2`}
          key={currentConfig.key}
          title={Lang.translate('general.value')}
          focusRef={currentConfig.value2.ref}
          value={currentConfig.value2.value}
          error={currentConfig.value2.error}
          onChange={val => changeConfigValue(configKey, 'value2', val, '')}
          language={lang}
          onInvalidTime={() => changeConfigValue(configKey, 'value2', currentConfig.value2.value, Lang.translate('general.please_enter_a_valid_time'))}
          removeStateError={val => changeConfigValue(configKey, 'value2', val, '')}
          required={currentConfig.required2 && currentConfig.disabled2}
          onBlur={() => validateValueInput(configKey)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={6}>
        <Switch
          id={`${id}_required2`}
          title={Lang.translate('general.required')}
          onClick={index => changeConfigValue(configKey, 'required2', index === 0)}
          activeIndex={currentConfig.required2 ? 0 : 1}
          items={[Lang.translate('general.yes'), Lang.translate('general.no')]}
        />
      </Column>
      <Column colMD={6}>
        <Switch
          id={`${id}_disabled2`}
          title={Lang.translate('general.disabled')}
          onClick={index => changeConfigValue(configKey, 'disabled2', index === 0)}
          activeIndex={currentConfig.disabled2 ? 0 : 1}
          items={[Lang.translate('general.yes'), Lang.translate('general.no')]}
        />
      </Column>
    </Row>
  </>
)

/**
 * @description Renders the schoice mchoice config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 * @param {Function} changeAdditionalField Function to change an additional field.
 * @param {Function} deleteAdditionalField Function to delete an additional field.
 * @param {Function} onBlurAdditionalField Function to onBlur an additional field.
 */
export const SChoiceMChoice = ({ id, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, validateValueInput, changeConfigValueNumeric }) => (
  <>
    <DefaultChoice
      id={id}
      configKey={configKey}
      currentConfig={currentConfig}
      indexNameInput={indexNameInput}
      types={types}
      changeConfigValue={changeConfigValue}
      changeType={changeType}
      validateValueInput={validateValueInput}
      changeConfigValueNumeric={changeConfigValueNumeric}
    />
    {renderAdditionalFields(id, configKey, currentConfig, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, true)}
  </>
)

/**
 * @description Renders the cchoice hchoice config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 * @param {Function} addAdditionalField Function to add an additional field.
 * @param {Function} changeAdditionalField Function to change an additional field.
 * @param {Function} deleteAdditionalField Function to delete an additional field.
 * @param {Function} onBlurAdditionalField Function to onBlur an additional field.
 */
export const CChoiceHChoice = ({ id, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType, addAdditionalField, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, validateValueInput, changeConfigValueNumeric }) => (
  <>
    <DefaultChoice
      id={id}
      configKey={configKey}
      currentConfig={currentConfig}
      indexNameInput={indexNameInput}
      types={types}
      changeConfigValue={changeConfigValue}
      changeType={changeType}
      validateValueInput={validateValueInput}
      changeConfigValueNumeric={changeConfigValueNumeric}
    />
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_db_select`}
          key={`${id}_db_select_${configKey}`}
          title={Lang.translate('definition.dbselect')}
          value={currentConfig.dbSelect.value}
          error={currentConfig.dbSelect.error}
          ref={currentConfig.dbSelect.ref}
          maxLength={128}
          onInputChanged={value => changeConfigValue(configKey, 'dbSelect', value)}
          onBlur={() => currentConfig.dbSelect.value === '' && changeConfigValue(configKey, 'dbSelect', '', Lang.translate('general.input_required'))}
          required
        />
      </Column>
    </Row>
    {renderAdditionalFields(id, configKey, currentConfig, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, false)}
    <Row>
      <Column colMD={12}>
        <Button
          id={`${id}_add_additional_field`}
          icon={'add'}
          tooltip={Lang.translate('definition.add_additional_field')}
          onClick={() => addAdditionalField(configKey)}
          disabled={currentConfig.additionalFields.length >= 10}
        />
      </Column>
    </Row>
  </>
)

/**
 * @description Renders the lchoice config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 * @param {Function} addAdditionalField Function to add an additional field.
 * @param {Function} changeAdditionalField Function to change an additional field.
 * @param {Function} deleteAdditionalField Function to delete an additional field.
 * @param {Function} onBlurAdditionalField Function to onBlur an additional field.
 */
export const LChoice = ({ id, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType, addAdditionalField, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, validateValueInput, changeConfigValueNumeric }) => (
  <>
    <DefaultChoice
      id={id}
      configKey={configKey}
      currentConfig={currentConfig}
      indexNameInput={indexNameInput}
      types={types}
      changeConfigValue={changeConfigValue}
      changeType={changeType}
      validateValueInput={validateValueInput}
      changeConfigValueNumeric={changeConfigValueNumeric}
    />
    {renderAdditionalFields(id, configKey, currentConfig, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, false)}
    <Row>
      <Column colMD={12}>
        <Button
          id={`${id}_add_additional_field`}
          icon={'add'}
          tooltip={Lang.translate('definition.add_additional_field')}
          onClick={() => addAdditionalField(configKey)}
          disabled={currentConfig.additionalFields.length >= 10}
        />
      </Column>
    </Row>
  </>
)

/**
 * @description Renders the cchoice2 config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 * @param {Function} addAdditionalField Function to add an additional field.
 * @param {Function} changeAdditionalField Function to change an additional field.
 * @param {Function} deleteAdditionalField Function to delete an additional field.
 * @param {Function} onBlurAdditionalField Function to onBlur an additional field.
 */
export const CChoice2 = ({ id, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType, addAdditionalField, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, validateValueInput, changeConfigValueNumeric }) => (
  <>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_indexname`}
          title={Lang.translate('general.indexname')}
          ref={indexNameInput}
          value={currentConfig.indexName}
          maxLength={8}
          disabled
          // !TO AVOID PROP TYPES ERROR
          onInputChanged={() => {}}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={6}>
        <Dropdown
          id={`${id}_data_format`}
          title={Lang.translate('general.data_format')}
          items={types}
          activeIndex={types.findIndex(el => el === currentConfig.type)}
          onChange={value => changeType(configKey, types[value])}
          containerID={`${id}_config_tilecontainer`}
        />
      </Column>
      <Column colMD={6}>
        <NumericSpinner
          id={`${id}_length`}
          title={Lang.translate('definition.length_input_field')}
          value={currentConfig.width}
          max={199}
          steps={1}
          min={1}
          onChange={value => changeConfigValueNumeric(configKey, 'width', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_identifier`}
          title={Lang.translate('general.identifier')}
          value={currentConfig.identifier}
          maxLength={50}
          onInputChanged={value => changeConfigValue(configKey, 'identifier', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_value`}
          key={`${id}_value_1_${configKey}`}
          ref={currentConfig.value.ref}
          title={Lang.translate('general.value')}
          value={currentConfig.value.value}
          error={currentConfig.value.error}
          maxLength={currentConfig.width}
          onInputChanged={value => changeConfigValue(configKey, 'value', value)}
          onBlur={() => validateValueInput(configKey)}
          letterCase={(['CCHOICE', 'CCHOICE2'].includes(currentConfig.type) && uppercaseElements.includes(configKey))? LetterCase.uppercase : undefined}
          required={currentConfig.required && currentConfig.disabled}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_value2`}
          key={`${id}_value_2_${configKey}`}
          ref={currentConfig.value2.ref}
          title={Lang.translate('general.value')}
          value={currentConfig.value2.value}
          error={currentConfig.value2.error}
          maxLength={currentConfig.width}
          onInputChanged={value => changeConfigValue(configKey, 'value2', value)}
          onBlur={() => validateValueInput(configKey)}
          uppercase={['CCHOICE', 'CCHOICE2'].includes(currentConfig.type) && uppercaseElements.includes(configKey)}
          required={currentConfig.required && currentConfig.disabled}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={6}>
        <Switch
          id={`${id}_required`}
          title={Lang.translate('general.required')}
          onClick={index => changeConfigValue(configKey, 'required', index === 0)}
          activeIndex={currentConfig.required ? 0 : 1}
          items={[
            Lang.translate('general.yes'),
            Lang.translate('general.no')
          ]}
        />
      </Column>
      <Column colMD={6}>
        <Switch
          id={`${id}_disabled`}
          title={Lang.translate('general.disabled')}
          onClick={index => changeConfigValue(configKey, 'disabled', index === 0)}
          activeIndex={currentConfig.disabled ? 0 : 1}
          items={[
            Lang.translate('general.yes'),
            Lang.translate('general.no')
          ]}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_db_select`}
          title={Lang.translate('definition.dbselect')}
          value={currentConfig.dbSelect.value}
          error={currentConfig.dbSelect.error}
          ref={currentConfig.dbSelect.ref}
          maxLength={128}
          onInputChanged={value => changeConfigValue(configKey, 'dbSelect', value)}
          onBlur={() => currentConfig.dbSelect.value === '' && changeConfigValue(configKey, 'dbSelect', '', Lang.translate('general.input_required'))}
          required
        />
      </Column>
    </Row>
    {renderAdditionalFields(id, configKey, currentConfig, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, false)}
    <Row>
      <Column colMD={12}>
        <Button
          id={`${id}_add_additional_field`}
          icon={'add'}
          tooltip={Lang.translate('definition.add_additional_field')}
          onClick={() => addAdditionalField(configKey)}
          disabled={currentConfig.additionalFields.length >= 10}
        />
      </Column>
    </Row>
  </>
)

/**
 * @description Renders the amount config.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Object} indexNameInput The index name reference.
 * @param {Array} types The data format types.
 * @param {Function} changeConfigValue Function to change the config value.
 * @param {Function} changeType Function to change the type.
 */
export const Amount = ({ id, configKey, currentConfig, indexNameInput, types, changeConfigValue, changeType, onBlur, validateValueInput, changeConfigValueNumeric }) => (
  <>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_indexname`}
          title={Lang.translate('general.indexname')}
          ref={indexNameInput}
          value={currentConfig.indexName}
          maxLength={8}
          disabled
          // to avoid propTypes error
          onInputChanged={() => {}}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={6}>
        <Dropdown
          id={`${id}_data_format`}
          title={Lang.translate('general.data_format')}
          items={types}
          activeIndex={types.findIndex(el => el === currentConfig.type)}
          onChange={value => changeType(configKey, types[value])}
          containerID={`${id}_config_tilecontainer`}
        />
      </Column>
      <Column colMD={6}>
        <NumericSpinner
          id={`${id}_length`}
          title={Lang.translate('definition.length_input_field')}
          value={currentConfig.width}
          max={3}
          steps={1}
          min={1}
          onChange={value => changeConfigValueNumeric(configKey, 'width', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_identifier`}
          title={Lang.translate('general.identifier')}
          value={currentConfig.identifier}
          maxLength={50}
          onInputChanged={value => changeConfigValue(configKey, 'identifier', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <NumericSpinner
          id={`${id}_value`}
          title={Lang.translate('general.value')}
          value={currentConfig.value.value}
          max={parseInt(''.padStart(currentConfig.width, '9'))}
          steps={1}
          min={1}
          onChange={value => changeConfigValue(configKey, 'value', value)}
          onBlur={() => validateValueInput(configKey)}
          letterCase={(['CCHOICE', 'CCHOICE2'].includes(currentConfig.type) && uppercaseElements.includes(configKey))? LetterCase.uppercase : undefined}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_preselected_value`}
          title={Lang.translate('general.value')}
          value={currentConfig.value2.value}
          maxLength={50}
          onInputChanged={value => changeConfigValue(configKey, 'value2', value)}
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_identifier2`}
          ref={currentConfig.identifier2.ref}
          title={`${Lang.translate('general.identifier')}2`}
          value={currentConfig.identifier2.value}
          error={currentConfig.identifier2.error}
          onBlur={() => onBlur(currentConfig.identifier2.value, configKey, 'identifier2')}
          maxLength={128}
          onInputChanged={value => changeConfigValue(configKey, 'identifier2', value)}
          required
        />
      </Column>
    </Row>
    <Row>
      <Column colMD={12}>
        <Input
          id={`${id}_dbvalue`}
          ref={currentConfig.value3.ref}
          title={Lang.translate('definition.dbvalue')}
          value={currentConfig.value3.value}
          error={currentConfig.value3.error}
          onBlur={() => onBlur(currentConfig.value3.value, configKey, 'value3')}
          maxLength={128}
          onInputChanged={value => changeConfigValue(configKey, 'value3', value)}
          required
        />
      </Column>
    </Row>
  </>
)

/**
 * @description Renders the additional fields.
 * @param {String} id The id of the component.
 * @param {String} configKey The current config key.
 * @param {Object} currentConfig The current config.
 * @param {Function} changeAdditionalField Function to change an additional field.
 * @param {Function} deleteAdditionalField Function to delete an additional field.
 * @param {Function} onBlurAdditionalField Function to onBlur an additional field.
 * @param {Boolean} valuesDisabled Flag if values should be disabled.
 */
const renderAdditionalFields = (id, configKey, currentConfig, changeAdditionalField, deleteAdditionalField, onBlurAdditionalField, valuesDisabled) => {
  const additionalFields = []
  currentConfig.additionalFields.forEach((el, index) => {
    additionalFields.push(
      <Row key={index}>
        <Column colMD={valuesDisabled ? 6 : 5}>
          <Input
            id={`${id}_additional_identifier_${index}`}
            key={`${id}_${configKey}_additional_identifier_${index}`}
            title={Lang.translate('general.identifier')}
            ref={el.identifier.focusRef}
            value={el.identifier.value}
            error={el.identifier.error}
            maxLength={64}
            onInputChanged={value => changeAdditionalField(configKey, index, 'identifier', value)}
            ignoreMinWidth
            required
            onBlur={() => onBlurAdditionalField(configKey, index, 'identifier')}
          />
        </Column>
        <Column colMD={valuesDisabled ? 6 : 5}>
          <Input
            id={`${id}_additional_value_${index}`}
            key={`${id}_${configKey}_additional_value_${index}`}
            title={Lang.translate('definition.dbvalue')}
            ref={el.value.focusRef}
            disabled={valuesDisabled}
            value={el.value.value}
            error={!valuesDisabled && el.value.error}
            maxLength={currentConfig.width || 8}
            required
            onInputChanged={value => changeAdditionalField(configKey, index, 'value', value)}
            onBlur={() => onBlurAdditionalField(configKey, index, 'value')}
            ignoreMinWidth
          />
        </Column>
        {!valuesDisabled &&
          <Column colMD={2}>
            <Button
              id={`${id}_delete_additional_field_${index}`}
              icon='delete'
              onClick={() => deleteAdditionalField(configKey, index)}
              className={'bux_mt18 bux_align_right'}
            />
          </Column>
        }
      </Row>
    )
  })

  return additionalFields
}