export const TRANSLATIONS = {
  'general.form': 'Host',
  'general.extension': 'Application',
  'general.report': 'Sub-Log',
  'search.list': 'Log',
  'definition.document': 'Log'
}

export const TRANSLATIONS_DE = {
  '00010007': 'Nicht möglich. Log ist nicht online.',
  '00010011': 'Nicht möglich. Log ist zum Löschen markiert.',
  '00010018': 'Nicht möglich. Log ist online.',
  '00010019': 'Nicht möglich. Log ist nicht archiviert.',
  '00010025': 'Nicht möglich. Archiv-Verweilzeit für Log ist abgelaufen.',
  '00060005': 'Fehler beim Öffnen des Log, rc(%d-%d), spoolptr(%32.32s)',
  '00110002': 'Zu viele Logs - bitte die Auswahl präzisieren',
  '00203025': 'Nicht möglich, es sind noch "Empfänger <-> Log" Definitionen vorhanden.',
  '00203027': 'Nicht möglich. Diese Nachbearbeitungsnotiz wird in einem oder mehreren Log Definitionen verwendet.',
  '00203029': 'Nicht möglich. Nachbereitungsnotiz wird in einer/mehreren "Empfänger <-> Log" Definitionen verwendet.',
  '00203031': 'Nicht möglich. Ausgabekanal wird in einer/mehreren Log Definitionen verwendet.',
  '00203033': 'Nicht möglich. Ausgabekanal wird in einer/mehreren "Empfänger <-> Log" Definitionen verwendet.',
  '00203051': 'Nicht möglich. Ausgabeformat wird in einer/mehreren Logdefinitionen verwendet.',
  '00203053': 'Nicht möglich. Ausgabeformat wird in einer/mehreren "Empfänger <-> Log" Definitionen verwendet.',
  '00203062': 'Nicht möglich. Es sind noch "Index <-> Log" Definitionen vorhanden.',
  '00203073': 'Nicht möglich. Es sind noch "Gruppe <-> Log" Definitionen vorhanden.',
  '00203084': 'Nicht möglich, es sind noch "Empfänger <-> Log" Definitionen vorhanden.',
  '00203190': 'Nicht möglich. Log ist nicht archiviert',
  '00203193': 'Nicht möglich. Log ist online.',
  '00203488': 'Nicht möglich, Log ist nicht online und nicht archiviert.',
  '00203490': 'Nicht möglich. Log ist zum Löschen markiert.',
  '00203498': 'Nicht möglich. Log Archiv ist abgelaufen.',
  'assignment.folder_document.copy_folder_document': 'Ordner <-> Log Zuordnung kopieren',
  'assignment.folder_document.create_error': 'Fehler beim Erstellen Zuordnung ($0)',
  'assignment.folder_document.create_folder_document': 'Ordner <-> Log Zuordnung anlegen',
  'assignment.folder_document.create_success': 'Zuordnung erstellt ($0)',
  'assignment.folder_document.delete_assignment': 'Ordner <-> Log Zuordnung löschen',
  'assignment.folder_document.delete_error': 'Fehler beim Löschen der Zuordnung ($0)',
  'assignment.folder_document.delete_question': 'Wollen Sie die Ordner <-> Log Zuordnung wirklich löschen?',
  'assignment.folder_document.delete_success': 'Zuordnung gelöscht ($0$1$2$3$4)',
  'assignment.folder_document.dynamic_report': 'Dynamischer Report',
  'assignment.folder_document.folderid': 'Ordner-ID',
  'assignment.folder_document.get_folder_document_assignment_error': 'Fehler beim Laden der Ordner Log Zuordnung "$0"',
  'assignment.folder_document.loading_error': 'Fehler beim Laden der Ordner-Log Zuordngungen ($0)',
  'assignment.folder_document.modify_error': 'Fehler beim Bearbeiten Zuordnung ($0)',
  'assignment.folder_document.modify_success': 'Zuordnung bearbeitet ($0)',
  'assignment.folder_document.please_enter_form': 'Bitte geben Sie eine Form ein.',
  'assignment.folder_document.please_enter_id': 'Bitte geben Sie eine Ordner-ID ein.',
  'assignment.folder_document_assignment': 'Ordner <-> Log',
  'assignment.folder_document_delete_question_1': 'Wollen Sie die Ordner ',
  'assignment.folder_document_delete_question_2': ' Log Zuordnung wirklich löschen?',
  'assignment.index_document.delete_error': 'Fehler beim Löschen der Zurodnung ($0)',
  'assignment.index_document.delete_index': 'Index <-> Log Zuordnung löschen',
  'assignment.index_document.delete_question': 'Wollen Sie die Index <-> Log Zuordnung wirklich löschen?',
  'assignment.index_document.delete_success': 'Zuordnung gelöscht ($0)',
  'assignment.index_document.get_assignment_error': 'Fehler beim Laden der Index Log Zuordnung "$0"',
  'assignment.index_document.loading_error': 'Fehler beim Laden der Index-Log Zuordngungen ($0)',
  'assignment.index_document.modify_error': 'Fehler beim Modifizieren der Beziehung ($0)',
  'assignment.index_document.modify_success': 'Zuordnung bearbeitet ($0)',
  'assignment.index_document_acif_index': 'ACIF-Index',
  'assignment.index_document_afpds_nop_record': 'AFPDS NOP Eintrag',
  'assignment.index_document_assignment': 'Index <-> Log',
  'assignment.index_document_create_title': 'Index <-> Log Zuordnung anlegen',
  'assignment.index_document_debug_mode': 'Testmodus',
  'assignment.index_document_delete_question_1': 'Wollen Sie die Index ',
  'assignment.index_document_delete_question_2': ' Log Zuordnung wirklich löschen?',
  'assignment.index_document_determine_index_values_from': 'Indexwerte ermitteln aus',
  'assignment.index_document_document_text_browser': 'Log-Textbrowser',
  'assignment.index_document_document_variable': 'Logvariable',
  'assignment.index_document_extract_index_value': 'Indexwert extrahieren',
  'assignment.index_document_extract_index_values_only_from_pages': 'Indexwerte nur aus Seiten extrahieren',
  'assignment.index_document_format_index_value': 'Indexwert formatieren',
  'assignment.index_document_ignore_index_value_with_wrong_data_format': 'Indexwerte mit falschem Datenformat ignorieren',
  'assignment.index_document_ignore_index_values_from_pages': 'Indexwerte aus Seiten ignorieren',
  'assignment.index_document_index_values_are_required': 'Indexwerte sind erforderlich',
  'assignment.index_document_index_values_from': 'Indexwerte aus',
  'assignment.index_document_maximally_up_to_end_tag': 'Maximal bis Endekennzeichen',
  'assignment.index_document_pjl_statement': '@PJL-Anweisung',
  'assignment.index_document_rule_example_label': 'Beispielregel:',
  'assignment.index_document_rule_example_substitutes1': 'Ersetzt "I1234567890ABC" durch "567890-1234-I"',
  'assignment.index_document_rule_example_substitutes2': 'Ersetzt "K 23456789 ABC" durch "567890-#234-K"',
  'assignment.index_document_rule_example_value': '&VAL(5,6,0)-&VAL(1,4,#)-&VAL(0,1)',
  'assignment.index_document_sample_of_a_search_pattern_label': 'Beispiel eines Suchmusters:',
  'assignment.index_document_sample_of_a_search_pattern_value': '\'ABC\'P\'99999\'',
  'assignment.index_document_sample_of_a_time_string_label': 'Beispiel für einen Zeit-String:',
  'assignment.index_document_sample_of_a_time_string_value': '%a %b %d %H:%M:%s GMT %Y',
  'assignment.index_document_sample_of_lcs_time1': 'LC_TIME: en_US - Datum: Fri Aug 13 10:22:56 GMT',
  'assignment.index_document_sample_of_lcs_time2': 'LC_TIME: de_DE - Datum: Fr Aug 13 10:22:56 GMT',
  'assignment.index_document_sample_of_lcs_time3': 'LC_TIME: fr_FR - Datum: ven aoû 13 10:22:56 GMT',
  'assignment.index_document_samples_of_lc_time': 'Beispiele für LC_TIME:',
  'assignment.index_document_search_pattern_*': '* - Beliebiges Zeichen',
  'assignment.index_document_search_pattern_a': 'A - Klein-/Großbuchstaben',
  'assignment.index_document_search_pattern_b': 'B - Leerzeichen',
  'assignment.index_document_search_pattern_l': 'L - Kleinbuchstaben',
  'assignment.index_document_search_pattern_q': '9 - Ziffer',
  'assignment.index_document_search_pattern_s': 'S - Sonderzeichen',
  'assignment.index_document_search_pattern_u': 'U - Großbuchstaben',
  'assignment.index_document_search_pattern_x': 'X - Hexadezimal',
  'assignment.index_document_search_pattern_z': 'Z - Ziffer oder Buchstabe',
  'assignment.index_document_security_check': 'Sicherheitsüberprüfung',
  'assignment.index_document_side_file': 'Zusatzdatei',
  'assignment.index_document_strings_beginning_with_abc': 'Zeichenketten beginnend mit "ABC" gefolgt von 5 Ziffern.',
  'assignment.index_document_substitude_values': 'Auszutauschende Werte',
  'assignment.index_document_substitute_characters_before_saving_hits': 'Zeichen vor dem Speichern austauschen',
  'assignment.index_document_variables': 'Variablen:',
  'assignment.index_document_variables1': '%a - Kurzer Wochentagsname (z.B. So)',
  'assignment.index_document_variables2': '%A - Voller Wochentagsname (z.B. Sonntag)',
  'assignment.index_document_variables3': '%b - Kurzer Monatsname (z.B. Jan)',
  'assignment.index_document_variables4': '%B - Voller Monatsname (z.B. Januar)',
  'assignment.index_document_variables5': '%d - Tag (z.B. 01)',
  'assignment.index_document_variables6': '%m - Monat (01..12)',
  'assignment.index_document_variables7': '%y - Zweistellige Jahresangabe (00..99)',
  'assignment.index_document_variables8': '%Y - Jahr',
  'assignment.index_document_with_values': 'Ersetzungswert',
  'assignment.node_document.create_error': 'Fehler beim Erstellen der Zuordnung "$0" ($1)',
  'assignment.node_document.create_success': 'Zuordnung erstellt ($0)',
  'assignment.node_document.delete_assignment_failed': 'Fehler beim löschen der Knoten-Log Zuordnung "$0" ($1)',
  'assignment.node_document.delete_assignment_success': 'Zuordnung gelöscht ($0)',
  'assignment.node_document.delete_error': 'Fehler beim Löschen der Zuordnung "$0" ($1)',
  'assignment.node_document.loading_error': 'Fehler beim Laden der Knoten-Log Zuordngungen ($0)',
  'assignment.node_document.modify_error': 'Fehler beim Bearbeiten Zuordnung "$0" ($1)',
  'assignment.node_document.modify_success': 'Zuordnung bearbeitet ($0)',
  'assignment.node_document_assignment.loading_error': 'Fehler beim Laden der Knoten-Log Zuordnung',
  'assignment.node_document_delete_question_1': 'Wollen Sie die Knoten ',
  'assignment.node_document_delete_question_2': ' Log Zuordnung wirklich löschen?',
  'assignment.recipient_document.delete_assignment_error': 'Fehler beim Löschen der Zuordnung "$0" ($1)',
  'assignment.recipient_document.delete_assignment_success': 'Zuordnung gelöscht ($0)',
  'assignment.recipient_document.delete_question': 'Wollen Sie die Empfänger <-> Log Zuordnung wirklich löschen?',
  'assignment.recipient_document.delete_title': 'Empfänger <-> Log Zuordnung löschen',
  'assignment.recipient_document_assignment': 'Empfänger <-> Log',
  'assignment.recipient_document_assignment.loading_error': 'Fehler beim Laden der Empfänger-Log Zuordnung "$0" ($1)',
  'assignment.recipient_document_assignments.loading_error': 'Fehler beim Laden der Empfänger-Log Zuordnungen ($0)',
  'assignment.recipient_document_convert': 'Umwandeln',
  'assignment.recipient_document_copy': 'Empfänger <-> Log Zuordnung kopieren',
  'assignment.recipient_document_create': 'Empfänger <-> Log Zuordnung anlegen',
  'assignment.recipient_document_default': 'Standard',
  'assignment.recipient_document_delete_question_1': 'Wollen Sie die Empfänger ',
  'assignment.recipient_document_delete_question_2': ' Log Zuordnung wirklich löschen?',
  'assignment.recipient_document_static': 'Statisch',
  'definition.allow_banner_between_document_copies': 'Deckblatt zwischen Logsnkopien zulassen',
  'definition.copy_document_node': 'Logknoten kopieren',
  'definition.create_document_error': 'Fehler beim Erstellen der Logsndefinition "$0" ($1)',
  'definition.create_document_node': 'Logknoten erstellen',
  'definition.create_document_node_error': 'Fehler beim Erstellen des Logknoten "$0" ($1)',
  'definition.create_document_node_success': 'Logknoten "$0" wurde erstellt',
  'definition.create_document_success': 'Logdefinition "$0" erstellt',
  'definition.delete_document': 'Log löschen',
  'definition.delete_document_error': 'Fehler beim Löschen der Logdefinition "$0" ($1)',
  'definition.delete_document_node': 'Logknoten löschen',
  'definition.delete_document_node_error': 'Fehler beim Löschen der Logknotendefinition "$0"',
  'definition.delete_document_node_success': 'Logknotendefinition "$0" gelöscht',
  'definition.delete_document_success': 'Logdefinition "$0" wurde gelöscht',
  'definition.document': 'Log',
  'definition.document_CP1250': 'CP1250 - Windows, Zentral- and Osteuropa',
  'definition.document_CP1251': 'CP1251 - Windows, Russland, Bulgarien, Serbien',
  'definition.document_CP1252': 'CP1252 - Windows, Westeuropa',
  'definition.document_CP1253': 'CP1253 - Windows, Griechenland',
  'definition.document_CP1256': 'CP1256 - Windows, Arabisch',
  'definition.document_CP850': 'CP850 - DOS-Latein-1',
  'definition.document_IBM0037': 'IBM0037 - USA, Kanada - CECP',
  'definition.document_IBM0273': 'IBM0273 - Österreich, Germany CECP',
  'definition.document_IBM0297': 'IBM0297 - Frankreich - CECP',
  'definition.document_IBM0420': 'IBM0420 - IBM, Arabisch Zweisprachig (Ebcdic)',
  'definition.document_IBM0500': 'IBM0500 - International #5',
  'definition.document_IBM1140': 'IBM1140 - IBM, USA, Kanada (Ebcdic)',
  'definition.document_IBM1141': 'IBM1141 - IBM, Österreich, Germany (Ebcdic)',
  'definition.document_IBM1147': 'IBM1147 - IBM, Frankreich (Ebcdic)',
  'definition.document_IBM1148': 'IBM1148 - IBM, International (Ebcdic)',
  'definition.document_ISO8859_1': 'ISO8859-1 - ISO, Westeuropa',
  'definition.document_ISO8859_15': 'ISO8859-15 - ISO, Westeuropa mit Euro',
  'definition.document_ISO8859_2': 'ISO8859-2 - ISO, Zentral- und Osteuropa',
  'definition.document_UCS_2BE': 'UCS-2BE - Unicode',
  'definition.document_UTF_8': 'UTF-8 - Unicode',
  'definition.document_absolute': 'Absolute',
  'definition.document_according_to_rules_1_8': 'Nach Regel 1 bis Regel 8',
  'definition.document_afp_scan_only_nops': '*.afp: Suche nur NOPs',
  'definition.document_afp_scan_only_pjl': '*.pcl: Suche nur @PJL',
  'definition.document_afp_scan_only_tles': '*.afp: Suche nur TLEs',
  'definition.document_archive_notes': 'Notizen archivieren',
  'definition.document_archive_text_version': 'Textversion archivieren',
  'definition.document_assign_in_reverse_order': 'In umgekehrter Reihenfolge zuweisen',
  'definition.document_assign_values_to_docusr_variables': 'Werte den DOCUSR-Variablen zuordnen',
  'definition.document_auto_print': 'Auto print',
  'definition.document_autoburst': 'Autoburst',
  'definition.document_automatic_archive_document': 'Log automatisch archivieren',
  'definition.document_banner': 'Log Deckblatt',
  'definition.document_banner_trailer_file_name': 'Deckblatt/Endeblatt Dateiname',
  'definition.document_batch_bundle': 'Stapelbündelung',
  'definition.document_batch_print': 'Stapeldruck',
  'definition.document_batch_print_bundle': 'Stapeldruck/Stapelbündelung',
  'definition.document_burst_mode': 'Burstmodus',
  'definition.document_calculate_retention_by_start_date': 'Berechne Verweilzeit ab Startdatum',
  'definition.document_clone_modal_title': 'Logdefinition klonen',
  'definition.document_cond_burst1': 'Conditional Burst 1',
  'definition.document_cond_burst2': 'Conditional Burst 2',
  'definition.document_cond_burst3': 'Conditional Burst 3',
  'definition.document_content_extraction_from_column': 'Extrahiere Inhalt ab Spalte',
  'definition.document_content_extraction_length': 'Länge des zu extrahierenden Inhalts',
  'definition.document_content_extraction_relative_to': 'Extrahiere Inhalt relativ zum',
  'definition.document_copy_banner': 'Log Kopiedeckblatt',
  'definition.document_copy_modal_title': 'Logdefinition kopieren',
  'definition.document_create_modal_title': 'Logdefinition erstellen',
  'definition.document_display_search_argument_and_values': 'Suchargumente und -werte',
  'definition.document_document_print_parameter': 'Logdruckparameter',
  'definition.document_exclude': 'Exclude',
  'definition.document_exclude_formula': 'Ausschlussformel',
  'definition.document_extract_date_from_document': 'Datum aus Log extrahieren',
  'definition.document_extract_variable_values': 'Variablenwerte extrahieren',
  'definition.document_filter': 'Filter',
  'definition.document_from_position_of_rule_1': 'Ab Position der 1. Regel',
  'definition.document_header_lines': 'Kopfzeilen',
  'definition.document_header_lines_on_page_one': 'Kopfzeilen von Seite 1',
  'definition.document_hold_bundle_request': 'Bündelaufträge halten',
  'definition.document_limit_burst': 'Limit/Burst',
  'definition.document_lines_after_matching_lines': 'Zeilen nach Trefferzeilen',
  'definition.document_lines_before_matching_lines': 'Zeilen vor Trefferzeilen',
  'definition.document_mark_matches': 'Treffer kennzeichnen (=>)',
  'definition.document_maximum_burst_windows_is_reached': 'Das Maximum von 8 Burst-Fenster ist erreicht',
  'definition.document_maximum_colors_is_reached': 'Das Maximum von 4 Farben ist erreicht',
  'definition.document_maximum_columns_is_reached': 'Das Maximum von 8 Spalten ist erreicht',
  'definition.document_maximum_lines_pages': 'Maximale Zeilen pro Seite',
  'definition.document_media_cannot_be_none': 'Medium muss Band, Platte oder Optische Platte sein.',
  'definition.document_modify_modal_title': 'Logdefinition bearbeiten',
  'definition.document_no_data_found_report': '"Keine Daten gefunden"-Report',
  'definition.document_node': 'Logknoten',
  'definition.document_node_copy_same_id': 'Knoten-ID muss geändert werden',
  'definition.document_node_definitions': 'Logknotendefinitionen',
  'definition.document_node_error': 'Fehler beim Laden der Logknotendefinition ($0)',
  'definition.document_node_no_childs_possible_for_group': 'Keine Kinder möglich bei Typ "Gruppe"',
  'definition.document_nodes_error': 'Fehler beim Laden der Logknotendefinitionen ($0)',
  'definition.document_nops_encoded_in_ebcdic': 'NOPs kodiert in EBCDIC',
  'definition.document_number_of_lines': 'Zeilenanzahl',
  'definition.document_number_of_values': 'Anzahl der Werte',
  'definition.document_one_burst_window_required': 'Es ist mindestens 1 Burst-Fenster erforderlich',
  'definition.document_online_print': 'Online print',
  'definition.document_other_textversion': 'Andere Textversionen',
  'definition.document_page_offset_at_end_qualifying_pages': 'Seitenverschiebung für das Ende der Seitenextraktion',
  'definition.document_page_offset_at_start_qualifying_pages': 'Seitenverschiebung für den Beginn der Seitenextraktion',
  'definition.document_pcl_textversion_in_ebcdic': 'PCL Textversion in EBCDIC',
  'definition.document_pdf_textversion_in_utf8': 'PDF Textversion in UTF-8',
  'definition.document_prefix_lines_with_betaux_page_line_number': 'Beta UX Seiten- und Zeilennummer voranstellen',
  'definition.document_prefix_lines_with_input_line_number': 'Input-Zeilenummer voranstellen',
  'definition.document_print_control_file_name': 'Datei für Druckersteuerung',
  'definition.document_ptx_encoding_utf8': 'PTX Kodierung (UTF-8)',
  'definition.document_replace_txt_by': '*.txt ersetzen durch',
  'definition.document_report_generation_and_variable_extraction': 'Reporterstellung und Variablenextraktion',
  'definition.document_retention_days': 'Verweilzeit (Tage)',
  'definition.document_search_argument_formula': 'Suchargumentformel',
  'definition.document_search_mode': 'Suchmodus',
  'definition.document_search_mode_error': 'Bitte einen Suchmodus auswählen.',
  'definition.document_separator_between_noncontiguous_lines': 'Trennzeile (--) zwischen nicht aufeinander folgenden Zeilen',
  'definition.document_skip_all_blank_values': 'Alle leeren Werte überspringen',
  'definition.document_skip_leading_blank_values_only': 'Leere Werte nur am Anfang überspringen',
  'definition.document_start_new_page_when_value_begins_at_column': 'Seitenumbruch wenn Wert in Spalte beginnt',
  'definition.document_start_search_argument_formula': 'Start-Suchargumentformel',
  'definition.document_start_stop': 'Start/Stop',
  'definition.document_stop_after_number_of_matches': 'Stop nach Anzahl von Treffern',
  'definition.document_stop_argument_formula_on_same_page': 'Stop-Suchargumentformel auf der gleichen Seite',
  'definition.document_stop_search_argument_formula': 'Stop-Suchargumentformel',
  'definition.document_supress_leading_blanks_and_repeated_blanks_between_words': 'Leerzeichen am Anfang und bis auf eins zwischen den Wörtern entfernen',
  'definition.document_tles_encoded_in_ebcdic': 'TLEs kodiert in EBCDIC',
  'definition.document_trailer': 'Log Endeblatt',
  'definition.document_use_copies_value_from_list_generation_record': 'Anzahl Kopien aus Listengenerationsatz verwenden',
  'definition.document_value_relative_position': 'Relative Werteposition',
  'definition.document_variable_extraction': 'Variablenextraktion',
  'definition.documentdefinitions': 'Logdefinitionen',
  'definition.documents_error': 'Fehler beim Laden der Logdefinitionen ($0)',
  'definition.folder_create_assigned_documents': 'Zugeordnete Logs',
  'definition.folder_create_documents_tab': 'Logs',
  'definition.ignore_documents_print_control_parameters': 'Druckersteuerparameter der Logs ignorieren',
  'definition.index_document_assignment.security_check': 'Sicherheitsprüfung',
  'definition.index_document_assignment.values_required': 'Werte benötigt',
  'definition.index_document_assignment.variant': 'Variante',
  'definition.modify_document_definition_error': 'Fehler beim Bearbeiten der Logsndefinition "$0" ($1)',
  'definition.modify_document_definition_success': 'Logdefinition "$0" bearbeitet',
  'definition.modify_document_node': 'Logknoten bearbeiten',
  'definition.question_delete_document': 'Möchten Sie wirklich dieses Log löschen?',
  'definition.question_delete_document_node': 'Wollen Sie diesen Logknoten wirklich löschen?',
  'definition.transfer_documents_only': 'Nur Logs übertragen',
  'definition.update_document_node_error': 'Fehler beim Bearbeiten der Logknotendefinition "$0" ($1)',
  'definition.update_document_node_success': 'Logknotendefinition "$0" bearbeitet',
  'definition.verify_document_error': 'Fehler beim Verifizieren der Logdefinition "$0" ($1)',
  'documentinformation.generaltab': 'Allgemein',
  'documentinformation.generaltab_docuser1': 'DocUser 1',
  'documentinformation.generaltab_docuser2': 'DocUser 2',
  'documentinformation.generaltab_docuser3': 'DocUser 3',
  'documentinformation.generaltab_docuser4': 'DocUser 4',
  'documentinformation.generaltab_docuser5': 'DocUser 5',
  'documentinformation.generaltab_docuser6': 'DocUser 6',
  'documentinformation.generaltab_docuser7': 'DocUser 7',
  'documentinformation.generaltab_docuser8': 'DocUser 8',
  'documentinformation.generaltab_owner': 'Besitzer',
  'documentinformation.generaltab_saveutf': 'Werte in UTF-8 speichern',
  'documentinformation.generaltab_title': 'Titel',
  'documentinformation.header_afp': 'AFP',
  'documentinformation.header_blocks': 'Blöcke',
  'documentinformation.header_controlcharacters': 'Steuerzeichen',
  'documentinformation.header_doctype': 'DokTyp',
  'documentinformation.header_documentid': 'Log-ID',
  'documentinformation.header_dynamicreport': 'Dyn. Report',
  'documentinformation.header_ebcdic': 'EBCDIC',
  'documentinformation.header_encoding': 'Kodierung',
  'documentinformation.header_filename': 'Dateiname',
  'documentinformation.header_jobname': 'Jobname',
  'documentinformation.header_maxreordlength': 'Maximale Datensatzlänge',
  'documentinformation.header_obtained': 'Quelle',
  'documentinformation.header_orginaldatetime': 'Org. Datum/Zeit',
  'documentinformation.header_owner': 'Besitzer',
  'documentinformation.header_pages': 'Seiten',
  'documentinformation.header_searchmode': 'Suchmodus',
  'documentinformation.header_tablereferencecharacter': 'Tabellenreferenzzeichen',
  'documentinformation.modaltitle': 'Loginformationen',
  'documentinformation.ogrtab': 'OGR',
  'documentinformation.ogrtab_title': 'OGR Informationen',
  'documentinformation.onlineretention': 'Online-Verweilzeit',
  'documentinformation.pointerstab': 'Zeiger',
  'documentinformation.pointerstab_archivepointer': 'Archivzeiger',
  'documentinformation.pointerstab_archivetextpointer': 'Archivtextzeiger',
  'documentinformation.pointerstab_externalindexdataspoolpointer': 'Externer Index-Datenspool-Zeiger',
  'documentinformation.pointerstab_indexpointer': 'Indexzeiger',
  'documentinformation.pointerstab_listtimestamp': 'Listenzeitstempel (LTOKEN)',
  'documentinformation.pointerstab_reporttimestamp': 'Reportzeitstempel (RTOKEN)',
  'documentinformation.pointerstab_ressourcearchivepointer': 'Ressourcen Archivzeiger',
  'documentinformation.pointerstab_ressourcespoolpointer': 'Ressourcen Spoolzeiger',
  'documentinformation.pointerstab_spoolpointer': 'Spoolzeiger',
  'documentinformation.pointerstab_textpointer': 'Textzeiger',
  'documentinformation.pointerstab_title': 'Zeiger und Zeitstempel',
  'documentinformation.retentions': 'Verweilzeiten',
  'documentinformation.retentiontab': 'Verweilzeit',
  'documentinformation.retentiontab_additionalinformation': 'Zusätzliche Informationen',
  'documentinformation.retentiontab_archived': 'Archiviert',
  'documentinformation.retentiontab_archiveexperation': 'Archiv-Verfall',
  'documentinformation.retentiontab_archiveexperationdate': 'Verfallsdatum archivieren',
  'documentinformation.retentiontab_archivenotes': 'Notizen archivieren',
  'documentinformation.retentiontab_archiveretention': 'Archivaufbewahrung',
  'documentinformation.retentiontab_disk': 'Platte',
  'documentinformation.retentiontab_generations': 'Generationen',
  'documentinformation.retentiontab_indexarchived': 'Index archiviert',
  'documentinformation.retentiontab_indexonline': 'Online-Index',
  'documentinformation.retentiontab_lastdocumentaccess': 'Letzter Logsnzugriff',
  'documentinformation.retentiontab_lastnoteaccess': 'Letzter Zugriff auf die Notiz',
  'documentinformation.retentiontab_notesonline': 'Notizen online',
  'documentinformation.retentiontab_online': 'Online',
  'documentinformation.retentiontab_onlineexpiration': 'Online-Verfall',
  'documentinformation.retentiontab_opticaldisk': 'Optische Platte',
  'documentinformation.retentiontab_references': 'Verweise',
  'documentinformation.retentiontab_tape': 'Band',
  'documentinformation.statustab': 'Status',
  'documentinformation.statustab_annotation': 'Anmerkung',
  'documentinformation.statustab_controlstatus': 'Kontrollstatus',
  'documentinformation.statustab_controluser': 'Kontrollbenutzer',
  'documentinformation.statustab_editingicon': 'Bearbeitungssymbol',
  'documentprint.copies': 'Kopien',
  'documentprint.default': 'Standard',
  'documentprint.from_page': 'Von Seite',
  'documentprint.header_copies': 'Kopien',
  'documentprint.header_datetime': 'Datum / Zeit',
  'documentprint.header_doctype': 'DokTyp',
  'documentprint.header_dynamicreport': 'Dynamischer Report',
  'documentprint.header_encoding': 'Encoding',
  'documentprint.header_pages': 'Seiten',
  'documentprint.header_title': 'Titel',
  'documentprint.modaltitle': 'Log drucken',
  'documentprint.outputchannel': 'Ausgabekanal',
  'documentprint.outputformat': 'Ausgabeformat',
  'documentprint.postprocessingnote': 'Nachbearbeitungsnotiz-ID',
  'documentprint.print': 'Drucken',
  'documentprint.print_param': 'Druckparameter',
  'documentprint.priority': 'Priorität',
  'documentprint.recipient': 'Empfänger',
  'documentprint.to_page': 'Bis Seite',
  'documentprint.user': 'Benutzer',
  'documents.document_download_error': 'Fehler beim Herunterladen des Logs ($0)',
  'documents.documents_error': 'Fehler beim Suchen der Logs ($0)',
  'documents.documents_print_error': 'Fehler beim Drucken des Logs "$0" ($1)',
  'documents.documents_print_multiple_success': 'Logs gedruckt auf "$0"',
  'documents.documents_print_success': 'Log "$0" gedruckt auf "$1"',
  'documents.get_document_error': 'Fehler beim Laden des Logs "$0" ($1)',
  'documents.modify_document_success': 'Loginformationen "$0" $1 Tab bearbeitet',
  'documents.modify_document_success_log': 'Loginformationen "$0" $1 Tab bearbeitet',
  'documents.print_info_error': 'Fehler beim Abrufen von Druckinformationen',
  'documents.reload_document': 'Log neu laden',
  'documents.reload_document_retention': 'Online Beibehaltung nach Neuladen (Tage)',
  'documents.reprintbundle_error': 'Fehler beim Wiederholen des Druck- oder Bündelauftrags "$0"',
  'documents.rescan_error': 'Fehler bei der Wiederholung des Scans "$0"',
  'documents.update_document_error': 'Fehler beim Bearbeiten des Logs "$0" ($1)',
  'documents.update_document_error_log': 'Fehler beim Bearbeiten des Logs "$0" ($1)',
  'documents.update_document_success_mark': 'Log "$0" für "$1" markiert', // TODO: use msg for un/mark
  'documents.update_document_success_unmark': 'Log "$0" für "$1" entmarkiert', // TODO: use msg for un/mark
  'documents.update_documentinformation_success': 'Document "$0" modified',
  'emptyresult.create_document_link': 'Sie können ein neues Log anlegen.',
  'emptyresult.create_document_node_link': 'Sie können einen neuen Logknoten anlegen.',
  'emptyresult.create_folder_document_assignment': 'Sie können eine neue Ordner <-> Log Zuordnung erstellen.',
  'emptyresult.create_index_document_assignment': 'Sie können eine neue Index <-> Log Zuordnung erstellen.',
  'emptyresult.create_recipient_document_assignment': 'Sie können eine neue Empfänger <-> Log Zuordnung erstellen.',
  'emptyresult.no_document_node_result': 'Kein Logknoten entspricht Ihren Suchkriterien.',
  'emptyresult.no_document_result': 'Kein Log entspricht Ihren Suchkriterien.',
  'emptyresult.no_folder_document_assignment_result': 'Keine Ordner <-> Log Zuordnung entsprechen Ihren Suchkriterien.',
  'emptyresult.no_index_document_assignment_result': 'Keine Index <-> Log Zuordnung entspricht Ihren Suchkriterien.',
  'emptyresult.no_recipient_document_assignment_result': 'Keine Empfänger <-> Log Zuordnung entsprechen Ihren Suchkriterien.',
  'emptysearchresult.description': 'Kein Log gefunden',
  'general.betaview_not_configured': 'Das Log kann nicht angezeigt werden, da kein _beta view konfiguriert ist.',
  'general.display_document': 'Log anzeigen',
  'general.document': 'Log',
  'general.document_modal_title': 'Log',
  'general.documents': 'Logs',
  'general.select_document': 'Log auswählen',
  'general.selected_documents': 'Ausgewählte Logs',
  'import.fileformat_ascii_utf_8': 'Log kodiert in ASCII/UTF-8 mit MVS CC',
  'import.fileformat_ebcdic_nl': 'Log kodiert in EBCDIC mit NL = 0x25',
  'import.fileformat_ebcdic_nl_mvscc': 'Log kodiert in EBCDIC mit NL = 0x25 und MVS CC',
  'import.fileformat_ebcdic_nl_mvscc_trc': 'Log kodiert in EBCDIC with NL = 0x25 und MVS CC + TRC',
  'import.fileformat_fileextension': 'Logtyp entspricht Dateinamenerweiterung',
  'queue.documentimported': 'Log importiert',
  'queue.outputchannel_id_document': 'Ausgabekanal-ID/Log',
  'recipient.print_tab_suppress_document_banner_trailer_for_bundling': 'Logdeckblatt/-endeblatt beim Bündeln unterdrücken',
  'search.documentstatus': 'Logstatus',
  'search.multiple_documents_were_marked_for_archive': '$0 Logs wurden zum Archivieren markiert.',
  'search.multiple_documents_were_marked_for_delete': '$0 Logs wurden zum Löschen markiert.',
  'search.multiple_documents_were_marked_for_reload': '$0 Logs wurden zum Rückladen markiert.',
  'search.question_delete_from_archive': 'Sind Sie sicher, dass Sie das Log entgültig from Archiv löschen wollen?',
  'security.profile_entry_bra': 'BRA - Logs aus Archiv anzeigen',
  'security.profile_entry_brw': 'BRW - Logs aus Spool anzeigen',
  'security.profile_entry_dfh': 'DFH - Loghiearchie definieren',
  'security.profile_entry_dfl': 'DFL - Logs Ordnern zuordnen',
  'security.profile_entry_dfr': 'DFR - Logs definieren',
  'security.profile_entry_dfx': 'DFX - Log-Indizies definieren',
  'security.profile_entry_egr': 'EGR - Log-Generation bearbeiten',
  'security.profile_entry_hex': 'HEX - Hexadezimale Logsnansicht',
  'security.profile_entry_pcr': 'PCR - Logs drucken',
  'security.profile_entry_pra': 'PRA - Logs aus Archiv drucken',
  'security.profile_entry_prf': 'PRF - Logs aus Ordner drucken',
  'security.profile_entry_prt': 'PRT - Logs aus Spool drucken',
  'security.profile_entry_rsi': 'RSI - Logs indizieren',
  'security.profile_entry_sfh': 'SFH - Logshierarchie auswählen',
  'server.accounting.archiving_document': 'Archivieren eines Logs',
  'server.accounting.deleting_document_archive': 'Löschen eines Logs aus dem Archiv',
  'server.accounting.deleting_document_spool': 'Löschen eines Logs aus der Spool',
  'server.accounting.document_deletion_date': 'Einlesedatum des Logs, Dateianhang (Notiz)',
  'server.accounting.document_deletion_time': 'Einlesezeit des Logs, Dateianhang (Notiz)',
  'server.accounting.document_id': 'Log-ID',
  'server.accounting.indexing_document': 'Indexierung eines Logs',
  'server.accounting.input_date': 'Einlesedatum des Logs',
  'server.accounting.input_time': 'Einlesezeit des Logs',
  'server.accounting.outputting_document': 'Ausgabe eines Logs (drucken und anzeigen)',
  'server.accounting.print/bundle_document': 'Drucken/Bündeln eines Logs, Ausgabekanal IP/T, Protokoll Typ PJL',
  'server.accounting.read_document': 'Einlesen eines Logs (einschließlich Reload)',
  'server.accounting.reload_date': 'Rückladedatum des Logs',
  'server.accounting.reload_time': 'Rückladezeit des Logs',
  'server.accounting.type100.title': 'Einlesen eines Logs (einschließlich Reload)',
  'server.accounting.type101.title': 'Ausgabe eines Logs (drucken und anzeigen)',
  'server.accounting.type102.title': 'Indexierung eines Logs',
  'server.accounting.type103.title': 'Drucken/Bündeln eines Logs,Ausgabekanal IP/T, Protokoll Typ PJL',
  'server.accounting.type105.title': 'Löschen eines Logs aus der Spool',
  'server.accounting.type110.title': 'Archivieren eines Logs',
  'server.accounting.type120.title': 'Löschen eines Logs aus dem Archiv',
  'standardselection.delete_from_archive_error': 'Fehler beim Markieren zum Löschen des Logss "$0" ($1) aus dem Archiv',
  'standardselection.delete_from_archive_success': 'Log ($0) zum Löschen aus dem Archiv markiert',
  'standardselection.mark_archive_error': 'Fehler beim Markieren zum Archivieren des Logss "$0" ($1)',
  'standardselection.mark_archive_success': 'Log ($0) zum Archivieren markiert',
  'standardselection.mark_delete_error': 'Fehler beim Markieren zum Löschen des Logss "$0" ($1)',
  'standardselection.mark_delete_success': 'Log ($0) zum Löschen markiert',
  'standardselection.mark_reload_error': 'Fehler beim Markieren zum Nachladen des Logss "$0" ($1)',
  'standardselection.mark_reload_success': 'Log ($0) zum Nachladen markiert',
  'standardselection.unmark_archive_error': 'Fehler beim Entmarkieren zum Archivieren des Logss "$0" ($1)',
  'standardselection.unmark_archive_success': 'Log ($0) zum Archivieren entmarkiert',
  'standardselection.unmark_delete_error': 'Fehler beim Entmarkieren zum Löschen des Logss "$0" ($1)',
  'standardselection.unmark_delete_success': 'Log ($0) zum Löschen entmarkiert',
  'standardselection.unmark_reload_error': 'Fehler beim Entmarkieren zum Nachladen des Logss "$0" ($1)',
  'standardselection.unmark_reload_success': 'Log ($0) zum Nachladen entmarkiert',
  'statistic.bsastats_documents_indexed': 'Logs indexiert',
  'statistic.bsastats_documents_total': 'Logs Gesamt',
  'statistic.documents_indexed': 'Logs Indiziert',
  'statistic.documents_total': 'Logs Gesamt',
  'statistic.pages_and_docs': 'Seiten & Logs',
}

export const TRANSLATIONS_EN = {
  '00010007': 'Not possible. Log is not online.',
  '00010011': 'Not possible. Log is marked for delete.',
  '00010018': 'Not possible. Log is online.',
  '00010019': 'Not possible. Log is not archived.',
  '00010025': 'Not possible. Log is "archive expired".',
  '00060005': 'Error open Log, rc($d-$d), spoolptr($32.32s) ',
  '00110002': 'Too many logs, please narrow your selection',
  '00203025': 'Not possible. "Recipient <-> Log" entries exist.',
  '00203027': 'Not possible. Postprocessing note is used in one or more log definitions.',
  '00203029': 'Not possible. Postprocessing note is used in one or more "Recipient <-> Log" definitions.',
  '00203031': 'Not possible. Output Channel is used in one or more Log definitions.',
  '00203033': 'Not possible. Output Channel is used in one or more "Recipient <-> Log" definitions.',
  '00203051': 'Not possible. Output Format is used in one or more Log definitions.',
  '00203053': 'Not possible. Output Format is used in one or more "Recipient <-> Log" definitions.',
  '00203062': 'List/Report is assigned to one or more Index <-> Log definitions.',
  '00203073': 'Group is assigned to one or more "Group <-> Log" definitions.',
  '00203084': 'Not possible. "Recipient <-> Log" entries still exist.',
  '00203190': 'Not possible. Log not archived.',
  '00203193': 'Not possible. Log is online.',
  '00203488': 'Not possible. Log is neither online nor archived.',
  '00203490': 'Not possible. Log is marked for deletion.',
  '00203498': 'Not possible, Log is archive expired.',
  'assignment.folder_document.copy_folder_document': 'Copy folder <-> log assignment',
  'assignment.folder_document.create_error': 'Error on creating assignment ($0)',
  'assignment.folder_document.create_folder_document': 'Create folder <-> log assignment',
  'assignment.folder_document.create_success': 'Assignment created ($0)',
  'assignment.folder_document.delete_assignment': 'Delete folder <-> log assignment',
  'assignment.folder_document.delete_error': 'Error on deleting assignment ($0)',
  'assignment.folder_document.delete_question': 'Do you really want to delete this folder <-> log assignment?',
  'assignment.folder_document.delete_success': 'Assignment deleted ($0)',
  'assignment.folder_document.dynamic_report': 'Dynamic report',
  'assignment.folder_document.folderid': 'Folder ID',
  'assignment.folder_document.get_folder_document_assignment_error': 'Error on getting folder log assignment "$0"',
  'assignment.folder_document.loading_error': 'Error on loading folder-log assignments ($0)',
  'assignment.folder_document.modify_error': 'Error on modifying assignment ($0)',
  'assignment.folder_document.modify_success': 'Assignment modified ($0)',
  'assignment.folder_document.please_enter_form': 'Please enter a form.',
  'assignment.folder_document.please_enter_id': 'Pleaser enter a folder ID.',
  'assignment.folder_document_assignment': 'Folder <-> log',
  'assignment.folder_document_delete_question_1': 'Do you really want to delete this folder',
  'assignment.folder_document_delete_question_2': ' log assignment?',
  'assignment.index_document.delete_error': 'Error on deleting assignment ($0)',
  'assignment.index_document.delete_index': 'Delete index <-> log assignment',
  'assignment.index_document.delete_question': 'Do you really want to delete this index <-> log assignment?',
  'assignment.index_document.delete_success': 'Assignment deleted ($0)',
  'assignment.index_document.get_assignment_error': 'Error on getting index log assignment "$0"',
  'assignment.index_document.loading_error': 'Error on loading index-log assignments ($0)',
  'assignment.index_document.modify_error': 'Error on modifying assignment ($0)',
  'assignment.index_document.modify_success': 'Assignment modified ($0)',
  'assignment.index_document_acif_index': 'ACIF index',
  'assignment.index_document_afpds_nop_record': 'AFPDS NOP record',
  'assignment.index_document_assignment': 'Index <-> log',
  'assignment.index_document_create_title': 'Create index <-> log assignment',
  'assignment.index_document_debug_mode': 'Debug mode',
  'assignment.index_document_delete_question_1': 'Do you really want to delete this index',
  'assignment.index_document_delete_question_2': ' log assignment?',
  'assignment.index_document_determine_index_values_from': 'Determine index values from',
  'assignment.index_document_document_text_browser': 'Log text browser',
  'assignment.index_document_document_variable': 'Log variable',
  'assignment.index_document_extract_index_value': 'Extract index value',
  'assignment.index_document_extract_index_values_only_from_pages': 'Extract index values only from pages',
  'assignment.index_document_format_index_value': 'Format index value',
  'assignment.index_document_ignore_index_value_with_wrong_data_format': 'Ignore index value with wrong data format',
  'assignment.index_document_ignore_index_values_from_pages': 'Ignore index values from pages',
  'assignment.index_document_index_values_are_required': 'Index values are required',
  'assignment.index_document_index_values_from': 'Index values from',
  'assignment.index_document_maximally_up_to_end_tag': 'Maximally up to end tag',
  'assignment.index_document_pjl_statement': '@PJL statement',
  'assignment.index_document_rule_example_label': 'Example rule:',
  'assignment.index_document_rule_example_substitutes1': 'Substitutes "I1234567890ABC" with "567890-1234-I"',
  'assignment.index_document_rule_example_substitutes2': 'Substitutes "K 23456789 ABC" with "567890-#234-K"',
  'assignment.index_document_rule_example_value': '&VAL(5,6,0)-&VAL(1,4,#)-&VAL(0,1)',
  'assignment.index_document_sample_of_a_search_pattern_label': 'Sample of a search pattern:',
  'assignment.index_document_sample_of_a_search_pattern_value': '\'ABC\'P\'99999\'',
  'assignment.index_document_sample_of_a_time_string_label': 'Sample of a time string:',
  'assignment.index_document_sample_of_a_time_string_value': '%a %b %d %H:%M:%s GMT %Y',
  'assignment.index_document_sample_of_lcs_time1': 'LC_TIME: en_US - Date: Fri Aug 13 10:22:56 GMT',
  'assignment.index_document_sample_of_lcs_time2': 'LC_TIME: de_DE - Date: Fr Aug 13 10:22:56 GMT',
  'assignment.index_document_sample_of_lcs_time3': 'LC_TIME: fr_FR - Date: ven aoû 13 10:22:56 GMT',
  'assignment.index_document_samples_of_lc_time': 'Samples of LC_TIME:',
  'assignment.index_document_search_pattern_*': '* - Any single character',
  'assignment.index_document_search_pattern_a': 'A - Upper or lower case chars',
  'assignment.index_document_search_pattern_b': 'B - Blank',
  'assignment.index_document_search_pattern_l': 'L - Lower case chars',
  'assignment.index_document_search_pattern_q': '9 - Digit',
  'assignment.index_document_search_pattern_s': 'S - Special chars',
  'assignment.index_document_search_pattern_u': 'U - Upper case chars',
  'assignment.index_document_search_pattern_x': 'X - Hexadecimal',
  'assignment.index_document_search_pattern_z': 'Z - Digit or character',
  'assignment.index_document_security_check': 'Security check',
  'assignment.index_document_side_file': 'Side file',
  'assignment.index_document_strings_beginning_with_abc': 'Strings beginning with "ABC" and followed by 5 digits.',
  'assignment.index_document_substitude_values': 'Substitute values',
  'assignment.index_document_substitute_characters_before_saving_hits': 'Substitute characters before saving hits',
  'assignment.index_document_variables': 'Variables:',
  'assignment.index_document_variables1': '%a - Abbreviated weekday name (e.g., Sun)',
  'assignment.index_document_variables2': '%A - Full weekday name (e.g., Sunday)',
  'assignment.index_document_variables3': '%b - Abbreviated month name (e.g., Jan)',
  'assignment.index_document_variables4': '%B - Full month name (e.g., January)',
  'assignment.index_document_variables5': '%d - Day of month (e.g, 01)',
  'assignment.index_document_variables6': '%m - Month (01..12)',
  'assignment.index_document_variables7': '%y - Last two digits of year (00..99)',
  'assignment.index_document_variables8': '%Y - Year',
  'assignment.index_document_with_values': 'With values',
  'assignment.node_document.create_error': 'Error on creating assignment "$0" ($1)',
  'assignment.node_document.create_success': 'Assignment created ($0)',
  'assignment.node_document.delete_assignment_failed': 'Error on deleting node <-> log assignment "$0" ($1)',
  'assignment.node_document.delete_assignment_success': 'Assignment deleted ($0)',
  'assignment.node_document.delete_error': 'Error of deleting node-log assignment "$0" ($1)',
  'assignment.node_document.loading_error': 'Error on loading node-log assignments ($0)',
  'assignment.node_document.modify_error': 'Error on modifying assignment "$0" ($1)',
  'assignment.node_document.modify_success': 'Assignment modified ($0)',
  'assignment.node_document_assignment.loading_error': 'Error on getting node-log assignment',
  'assignment.node_document_delete_question_1': 'Do you really want to delete this node',
  'assignment.node_document_delete_question_2': ' log assignment?',
  'assignment.recipient_document.delete_assignment_error': 'Error on deleting assignment "$0" ($1)',
  'assignment.recipient_document.delete_assignment_success': 'Assignment deleted ($0)',
  'assignment.recipient_document.delete_question': 'Do you really want to delete this recipient <-> log assignment?',
  'assignment.recipient_document.delete_title': 'Delete recipient <-> log assignment',
  'assignment.recipient_document_assignment': 'Recipient <-> log',
  'assignment.recipient_document_assignment.loading_error': 'Error on getting recipient-log assignment "$0" ($1)',
  'assignment.recipient_document_assignments.loading_error': 'Error on loading recipient-log assignments ($0)',
  'assignment.recipient_document_convert': 'Convert',
  'assignment.recipient_document_copy': 'Copy recipient <-> log assignment',
  'assignment.recipient_document_create': 'Create recipient <-> log assignment',
  'assignment.recipient_document_default': 'Default',
  'assignment.recipient_document_delete_question_1': 'Do you really want to delete this recipient',
  'assignment.recipient_document_delete_question_2': ' log assignment?',
  'assignment.recipient_document_static': 'Static',
  'definition.allow_banner_between_document_copies': 'Allow banner between log copies',
  'definition.copy_document_node': 'Copy log node',
  'definition.create_document_error': 'Error on creating log definition "$0" ($1)',
  'definition.create_document_node': 'Create log node',
  'definition.create_document_node_error': 'Error on creating log node "$0"',
  'definition.create_document_node_success': 'Log node "$0" created',
  'definition.create_document_success': 'Log definition "$0" created',
  'definition.delete_document': 'Delete log',
  'definition.delete_document_error': 'Error on deleting log definition "$0" ($1)',
  'definition.delete_document_node': 'Delete log node',
  'definition.delete_document_node_error': 'Error on deleting log node definition "$0"',
  'definition.delete_document_node_success': 'Log node definition "$0" deleted',
  'definition.delete_document_success': 'Log definition "$0" deleted',
  'definition.document': 'Log',
  'definition.document_CP1250': 'CP1250 - Windows, Central and Eastern European',
  'definition.document_CP1251': 'CP1251 - Windows, Russian, Bulgarian, Serbian',
  'definition.document_CP1252': 'CP1252 - Windows, Western European',
  'definition.document_CP1253': 'CP1253 - Windows, Greek',
  'definition.document_CP1256': 'CP1256 - Windows, Arabic',
  'definition.document_CP850': 'CP850 - DOS-Latin-1',
  'definition.document_IBM0037': 'IBM0037 - USA, Canada - CECP',
  'definition.document_IBM0273': 'IBM0273 - Austria, Germany CECP',
  'definition.document_IBM0297': 'IBM0297 - France - CECP',
  'definition.document_IBM0420': 'IBM0420 - IBM, Arabic Bilingual (Ebcdic)',
  'definition.document_IBM0500': 'IBM0500 - International #5',
  'definition.document_IBM1140': 'IBM1140 - IBM, USA, Canada (Ebcdic)',
  'definition.document_IBM1141': 'IBM1141 - IBM, Austria, Germany (Ebcdic)',
  'definition.document_IBM1147': 'IBM1147 - IBM, France (Ebcdic)',
  'definition.document_IBM1148': 'IBM1148 - IBM, International (Ebcdic)',
  'definition.document_ISO8859_1': 'ISO8859-1 - ISO,Western European',
  'definition.document_ISO8859_15': 'ISO8859-15 - ISO, Western European with Euro',
  'definition.document_ISO8859_2': 'ISO8859-2 - ISO, Central and Eastern European',
  'definition.document_UCS_2BE': 'UCS-2BE - Unicode',
  'definition.document_UTF_8': 'UTF-8 - Unicode',
  'definition.document_absolute': 'Absolute',
  'definition.document_according_to_rules_1_8': 'According to rules 1 to 8',
  'definition.document_afp_scan_only_nops': '*.afp: Scan only NOPs',
  'definition.document_afp_scan_only_pjl': '*.pcl: Scan only @PJL',
  'definition.document_afp_scan_only_tles': '*.afp: Scan only TLEs',
  'definition.document_archive_notes': 'Archive notes',
  'definition.document_archive_text_version': 'Archive text version',
  'definition.document_assign_in_reverse_order': 'Assign in reverse order',
  'definition.document_assign_values_to_docusr_variables': 'Assign values to DOCUSR variables',
  'definition.document_auto_print': 'Auto print',
  'definition.document_autoburst': 'Autoburst',
  'definition.document_automatic_archive_document': 'Automatically archive log',
  'definition.document_banner': 'Log banner',
  'definition.document_banner_trailer_file_name': 'Banner/trailer file name',
  'definition.document_batch_bundle': 'Batch bundle',
  'definition.document_batch_print': 'Batch print',
  'definition.document_batch_print_bundle': 'Batch print/batch bundle',
  'definition.document_burst_mode': 'Burst mode',
  'definition.document_calculate_retention_by_start_date': 'Calculate retention by start date',
  'definition.document_clone_modal_title': 'Clone log definition',
  'definition.document_cond_burst1': 'Conditional Burst 1',
  'definition.document_cond_burst2': 'Conditional Burst 2',
  'definition.document_cond_burst3': 'Conditional Burst 3',
  'definition.document_content_extraction_from_column': 'Content extraction from column ',
  'definition.document_content_extraction_length': 'Content extraction length',
  'definition.document_content_extraction_relative_to': 'Content extraction relative to',
  'definition.document_copy_banner': 'Log copy banner',
  'definition.document_copy_modal_title': 'Copy log definition',
  'definition.document_create_modal_title': 'Create log definition',
  'definition.document_display_search_argument_and_values': 'Display search arguments and values',
  'definition.document_document_print_parameter': 'Log print parameter',
  'definition.document_exclude': 'Exclude',
  'definition.document_exclude_formula': 'Exclude formula',
  'definition.document_extract_date_from_document': 'Extract date from log',
  'definition.document_extract_variable_values': 'Extract variable values',
  'definition.document_filter': 'Filter',
  'definition.document_from_position_of_rule_1': 'From position of rule 1',
  'definition.document_header_lines': 'Header lines',
  'definition.document_header_lines_on_page_one': 'Header lines on page one',
  'definition.document_hold_bundle_request': 'Hold bundle request',
  'definition.document_limit_burst': 'Limit/Burst',
  'definition.document_lines_after_matching_lines': 'Lines after matching lines',
  'definition.document_lines_before_matching_lines': 'Lines before matching lines',
  'definition.document_mark_matches': 'Mark matches (=>)',
  'definition.document_maximum_burst_windows_is_reached': 'The maximum of 8 burst windows is reached',
  'definition.document_maximum_colors_is_reached': 'The maximum of 4 colors is reached',
  'definition.document_maximum_columns_is_reached': 'The maximum of 8 columns is reached',
  'definition.document_maximum_lines_pages': 'Maximum lines per page',
  'definition.document_media_cannot_be_none': 'Media have to be Tape, Disk or Optical Disk.',
  'definition.document_modify_modal_title': 'Modify log definition',
  'definition.document_no_data_found_report': '"No data found." report',
  'definition.document_node': 'Log node',
  'definition.document_node_copy_same_id': 'Node ID has to be changed',
  'definition.document_node_definitions': 'Log node definitions',
  'definition.document_node_error': 'Error on loading log node definition ($0)',
  'definition.document_node_no_childs_possible_for_group': 'No children possible for type "Group"',
  'definition.document_nodes_error': 'Error on loading log node definitions ($0)',
  'definition.document_nops_encoded_in_ebcdic': 'NOPs encoding EBCDIC',
  'definition.document_number_of_lines': 'Number of lines',
  'definition.document_number_of_values': 'Number of values',
  'definition.document_one_burst_window_required': 'At least one burst-window is required',
  'definition.document_online_print': 'Online print',
  'definition.document_other_textversion': 'Other text Version',
  'definition.document_page_offset_at_end_qualifying_pages': 'Page offset at end qualifying pages',
  'definition.document_page_offset_at_start_qualifying_pages': 'Page offset at start qualifying pages',
  'definition.document_pcl_textversion_in_ebcdic': 'PCL text version in EBCDIC',
  'definition.document_pdf_textversion_in_utf8': 'PDF text version in UTF-8',
  'definition.document_prefix_lines_with_betaux_page_line_number': 'Prefix lines with the Beta UX page and line number',
  'definition.document_prefix_lines_with_input_line_number': 'Prefix lines with the input line number',
  'definition.document_print_control_file_name': 'Print control file name',
  'definition.document_ptx_encoding_utf8': 'PTX encoding (UTF-8)',
  'definition.document_replace_txt_by': 'Replace *.txt by',
  'definition.document_report_generation_and_variable_extraction': 'Report generation & variable extraction',
  'definition.document_retention_days': 'Retention (Days)',
  'definition.document_search_argument_formula': 'Search argument formula',
  'definition.document_search_mode': 'Search mode',
  'definition.document_search_mode_error': 'Please select a search mode.',
  'definition.document_separator_between_noncontiguous_lines': 'Separator (--) between non-contiguous lines',
  'definition.document_skip_all_blank_values': 'Skip all blank values',
  'definition.document_skip_leading_blank_values_only': 'Skip leading blank values only',
  'definition.document_start_new_page_when_value_begins_at_column': 'Start new page when value begins at column',
  'definition.document_start_search_argument_formula': 'Start search argument formula',
  'definition.document_start_stop': 'Start/Stop',
  'definition.document_stop_after_number_of_matches': 'Stop after number of matches',
  'definition.document_stop_argument_formula_on_same_page': 'Stop argument formula on same page',
  'definition.document_stop_search_argument_formula': 'Stop search argument formula',
  'definition.document_supress_leading_blanks_and_repeated_blanks_between_words': 'Suppress leading blanks and repeated blanks between words',
  'definition.document_tles_encoded_in_ebcdic': 'TLEs encoding EBCDIC',
  'definition.document_trailer': 'Log trailer',
  'definition.document_use_copies_value_from_list_generation_record': 'Use copies value from list generation record',
  'definition.document_value_relative_position': 'Value relative position',
  'definition.document_variable_extraction': 'Variable extraction',
  'definition.documentdefinitions': 'Log definitions',
  'definition.documents_error': 'Error on loading log definitions ($0)',
  'definition.folder_create_assigned_documents': 'Assigned logs',
  'definition.folder_create_documents_tab': 'Logs',
  'definition.ignore_documents_print_control_parameters': 'Ignore logs print control parameters',
  'definition.index_document_assignment.security_check': 'Security check',
  'definition.index_document_assignment.values_required': 'Values required',
  'definition.index_document_assignment.variant': 'Variant',
  'definition.modify_document_definition_error': 'Error on modifying log definition "$0" ($1)',
  'definition.modify_document_definition_success': 'Log definition "$0" modified',
  'definition.modify_document_node': 'Modify log node',
  'definition.question_delete_document': 'Do you really want to delete this log?',
  'definition.question_delete_document_node': 'Do you really want to delete the log node?',
  'definition.transfer_documents_only': 'Transfer logs only',
  'definition.update_document_node_error': 'Error on modifying log node definition "$0" ($1)',
  'definition.update_document_node_success': 'Log node definition "$0" modified',
  'definition.verify_document_error': 'Error on verifying log definition "$0" ($1)',
  'documentinformation.generaltab': 'General',
  'documentinformation.generaltab_docuser1': 'DocUser 1',
  'documentinformation.generaltab_docuser2': 'DocUser 2',
  'documentinformation.generaltab_docuser3': 'DocUser 3',
  'documentinformation.generaltab_docuser4': 'DocUser 4',
  'documentinformation.generaltab_docuser5': 'DocUser 5',
  'documentinformation.generaltab_docuser6': 'DocUser 6',
  'documentinformation.generaltab_docuser7': 'DocUser 7',
  'documentinformation.generaltab_docuser8': 'DocUser 8',
  'documentinformation.generaltab_owner': 'Owner',
  'documentinformation.generaltab_saveutf': 'Save UTF-8 encoded',
  'documentinformation.generaltab_title': 'Title',
  'documentinformation.header_afp': 'AFP',
  'documentinformation.header_blocks': 'Blocks',
  'documentinformation.header_controlcharacters': 'Control characters',
  'documentinformation.header_doctype': 'DocType',
  'documentinformation.header_documentid': 'Log ID',
  'documentinformation.header_dynamicreport': 'Dyn. Report',
  'documentinformation.header_ebcdic': 'EBCDIC',
  'documentinformation.header_encoding': 'Encoding',
  'documentinformation.header_filename': 'Filename',
  'documentinformation.header_jobname': 'Jobname',
  'documentinformation.header_maxreordlength': 'Max record length',
  'documentinformation.header_obtained': 'Obtained',
  'documentinformation.header_orginaldatetime': 'Org. date/time',
  'documentinformation.header_owner': 'Owner',
  'documentinformation.header_pages': 'Pages',
  'documentinformation.header_searchmode': 'Search mode',
  'documentinformation.header_tablereferencecharacter': 'Table reference character',
  'documentinformation.modaltitle': 'Log information',
  'documentinformation.ogrtab': 'OGR',
  'documentinformation.ogrtab_title': 'OGR information',
  'documentinformation.onlineretention': 'Online retention',
  'documentinformation.pointerstab': 'Pointers',
  'documentinformation.pointerstab_archivepointer': 'Archive pointer',
  'documentinformation.pointerstab_archivetextpointer': 'Archive text pointer',
  'documentinformation.pointerstab_externalindexdataspoolpointer': 'External index data spool pointer',
  'documentinformation.pointerstab_indexpointer': 'Index pointer',
  'documentinformation.pointerstab_listtimestamp': 'List timestamp (LTOKEN)',
  'documentinformation.pointerstab_reporttimestamp': 'Report timestamp (RTOKEN)',
  'documentinformation.pointerstab_ressourcearchivepointer': 'Resource archive pointer',
  'documentinformation.pointerstab_ressourcespoolpointer': 'Resource spool pointer',
  'documentinformation.pointerstab_spoolpointer': 'Spool pointer',
  'documentinformation.pointerstab_textpointer': 'Text pointer',
  'documentinformation.pointerstab_title': 'Pointers and timestamps',
  'documentinformation.retentions': 'Retentions',
  'documentinformation.retentiontab': 'Retention',
  'documentinformation.retentiontab_additionalinformation': 'Additional information',
  'documentinformation.retentiontab_archived': 'Archived',
  'documentinformation.retentiontab_archiveexperation': 'Archive expiration',
  'documentinformation.retentiontab_archiveexperationdate': 'Archive expiration date',
  'documentinformation.retentiontab_archivenotes': 'Archive notes',
  'documentinformation.retentiontab_archiveretention': 'Archive retention',
  'documentinformation.retentiontab_disk': 'Disk',
  'documentinformation.retentiontab_generations': 'Generations',
  'documentinformation.retentiontab_indexarchived': 'Index archived',
  'documentinformation.retentiontab_indexonline': 'Index online',
  'documentinformation.retentiontab_lastdocumentaccess': 'Last log access',
  'documentinformation.retentiontab_lastnoteaccess': 'Last note access',
  'documentinformation.retentiontab_notesonline': 'Notes online',
  'documentinformation.retentiontab_online': 'Online',
  'documentinformation.retentiontab_onlineexpiration': 'Online expiration',
  'documentinformation.retentiontab_opticaldisk': 'Optical Disk',
  'documentinformation.retentiontab_references': 'References',
  'documentinformation.retentiontab_tape': 'Tape',
  'documentinformation.statustab': 'Status',
  'documentinformation.statustab_annotation': 'Annotation',
  'documentinformation.statustab_controlstatus': 'Control status',
  'documentinformation.statustab_controluser': 'Control user',
  'documentinformation.statustab_editingicon': 'Editing icon',
  'documentprint.copies': 'Copies',
  'documentprint.default': 'Default',
  'documentprint.from_page': 'From page',
  'documentprint.header_copies': 'Copies',
  'documentprint.header_datetime': 'Date / Time',
  'documentprint.header_doctype': 'DocType',
  'documentprint.header_dynamicreport': 'Dynamic report',
  'documentprint.header_encoding': 'Encoding',
  'documentprint.header_pages': 'Pages',
  'documentprint.header_title': 'Title',
  'documentprint.modaltitle': 'Print log',
  'documentprint.outputchannel': 'Output channel',
  'documentprint.outputformat': 'Output format',
  'documentprint.postprocessingnote': 'Postprocessing note ID',
  'documentprint.print': 'Print',
  'documentprint.print_param': 'Print parameter',
  'documentprint.priority': 'Priority',
  'documentprint.recipient': 'Recipient',
  'documentprint.to_page': 'To page',
  'documentprint.user': 'User',
  'documents.document_download_error': 'Error on download log ($0)',
  'documents.documents_error': 'Error on searching logs ($0)',
  'documents.documents_print_error': 'Error on printing log "$0" ($1)',
  'documents.documents_print_multiple_success': 'Logs printed on "$0"',
  'documents.documents_print_success': 'Log "$0" printed on "$1"',
  'documents.get_document_error': 'Error on getting log "$0" ($1)',
  'documents.modify_document_success': 'Log "$0" $1 tab modified',
  'documents.modify_document_success_log': 'Log "$0" $1 tab modified',
  'documents.print_info_error': 'Error on getting print information',
  'documents.reload_document': 'Reload log',
  'documents.reload_document_retention': 'Online retention after reload (days)',
  'documents.reprintbundle_error': 'Error on reprint or rebundle "$0"',
  'documents.rescan_error': 'Error on rerun rescan "$0"',
  'documents.update_document_error': 'Error on modifying log "$0" ($1)',
  'documents.update_document_error_log': 'Error on modifying log "$0" ($1)',
  'documents.update_document_success_mark': 'Log "$0" marked for "$1"', // TODO: use msg for un/mark
  'documents.update_document_success_unmark': 'Log "$0" unmarked for "$1"', // TODO: use msg for un/mark
  'documents.update_documentinformation_success': 'Log "$0" modified',
  'emptyresult.create_document_link': 'You can create a new log.',
  'emptyresult.create_document_node_link': 'You can create a new log node.',
  'emptyresult.create_folder_document_assignment': 'You can create a new folder <-> log assignment.',
  'emptyresult.create_index_document_assignment': 'You can create a new index <-> log assignment.',
  'emptyresult.create_recipient_document_assignment': 'You can create a new recipient <-> log assignment.',
  'emptyresult.no_document_node_result': 'No log node matches your search criteria.',
  'emptyresult.no_document_result': 'No log matches your seach criteria.',
  'emptyresult.no_folder_document_assignment_result': 'No folder <-> log assignment matches your search criteria.',
  'emptyresult.no_index_document_assignment_result': 'No index <-> log assignment matches your search criteria.',
  'emptyresult.no_recipient_document_assignment_result': 'No recipient <-> log assignment matches your search criteria.',
  'emptysearchresult.description': 'No logs found',
  'general.betaview_not_configured': 'Log can not be shown, because there is no _beta view configured.',
  'general.display_document': 'Display log',
  'general.display_documents': 'Display logs',
  'general.document': 'Log',
  'general.document_modal_title': 'document',
  'general.documents': 'Logs',
  'general.select_document': 'Select log',
  'general.selected_documents': 'Selected logs',
  'general.text_documents': 'Text logs',
  'import.fileformat_ascii_utf_8': 'Log encoding is ASCII/UTF-8 with MVS CC',
  'import.fileformat_ebcdic_nl': 'Log encoding is EBCDIC with NL = 0x25',
  'import.fileformat_ebcdic_nl_mvscc': 'Log encoding is EBCDIC with NL = 0x25 and MVS CC',
  'import.fileformat_ebcdic_nl_mvscc_trc': 'Log encoding is EBCDIC with NL = 0x25 and MVS CC + TRC',
  'queue.documentimported': 'Log imported',
  'queue.outputchannel_id_document': 'Output channel ID/Document',
  'recipient.print_tab_suppress_document_banner_trailer_for_bundling': 'Suppress log banner/trailer for bundling',
  'search.documentstatus': 'Log status',
  'search.multiple_documents_were_marked_for_archive': '$0 logs were marked for archive.',
  'search.multiple_documents_were_marked_for_delete': '$0 logs were marked for delete.',
  'search.multiple_documents_were_marked_for_reload': '$0 logs were marked for reload.',
  'search.question_delete_from_archive': 'Do you really want to permanently delete the log from archive?',
  'security.profile_entry_dfh': 'DFH - Define log hierarchy',
  'security.profile_entry_hex': 'HEX - Display log hexadecimal',
  'security.profile_entry_sfh': 'SFH - Select log hierarchy',
  'server.accounting.archiving_document': 'Archiving a log',
  'server.accounting.deleting_document_archive': 'Deleting a log from the archive',
  'server.accounting.deleting_document_spool': 'Deleting a log from the spool',
  'server.accounting.document_deletion_date': 'Date log input, Attachment (Note)',
  'server.accounting.document_deletion_time': 'Time log input, Attachment (Note)',
  'server.accounting.document_id': 'Log-ID',
  'server.accounting.indexing_document': 'Indexing a log',
  'server.accounting.input_date': 'Date log input',
  'server.accounting.input_time': 'Time log input',
  'server.accounting.outputting_document': 'Outputting a log (printing and online-display)',
  'server.accounting.print/bundle_document': 'Print/Bundle a log, output channel IP/T, protocol type PJL',
  'server.accounting.read_document': 'Read in a log (including reload)',
  'server.accounting.reload_date': 'Date log reload',
  'server.accounting.reload_time': 'Time log reload',
  'server.accounting.type100.title': 'Read in a log (including reload)',
  'server.accounting.type101.title': 'Outputting a log (printing and online-display)',
  'server.accounting.type102.title': 'Indexing a log',
  'server.accounting.type103.title': 'Print/Bundle a log,output channel IP/T, protocol type PJL',
  'server.accounting.type105.title': 'Deleting a log from the spool',
  'server.accounting.type110.title': 'Archiving a log',
  'server.accounting.type120.title': 'Deleting a log from the archive',
  'standardselection.delete_from_archive_error': 'Error on delete from archive log ($0)',
  'standardselection.delete_from_archive_success': 'Log ($0) marked for delete from archive',
  'standardselection.mark_archive_error': 'Error on mark for archive log ($0) ($1)',
  'standardselection.mark_archive_success': 'Log ($0) marked for archive',
  'standardselection.mark_delete_error': 'Error on mark for delete log ($0) ($1)',
  'standardselection.mark_delete_success': 'Log ($0) marked for delete',
  'standardselection.mark_reload_error': 'Error on mark for reload log "$0" ($1)',
  'standardselection.mark_reload_success': 'Log ($0) marked for reload',
  'standardselection.unmark_archive_error': 'Error on unmark for archive log "$0" ($1)',
  'standardselection.unmark_archive_success': 'Log ($0) unmarked for archive',
  'standardselection.unmark_delete_error': 'Error on unmark for delete log ($0) ($1)',
  'standardselection.unmark_delete_success': 'Log ($0) unmarked for delete',
  'standardselection.unmark_reload_error': 'Error on unmark for reload log ($0) ($1)',
  'standardselection.unmark_reload_success': 'Log ($0) unmarked for reload',
  'statistic.bsastats_documents_indexed': 'logs indexed',
  'statistic.bsastats_documents_total': 'logs total',
  'statistic.documents_indexed': 'Logs indexed',
  'statistic.documents_total': 'Logs total',
  'statistic.pages_and_docs': 'Pages & logs',
}