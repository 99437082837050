import React, { useEffect, useState, useRef } from 'react';
import './SnackbarTile.scss';

// Other components
import Icon from '../../icon/Icon';
import { SnackbarContent, SnackbarContentType, SnackbarTitle, TYPE_ERROR, TYPE_INFO, TYPE_SUCCESS } from '../Snackbar.types';
import useTimeout from '../../../hooks/timeout';

export interface SnackbarTileProps {
  /**
   * Callback of snackbar to call when snackbar-tile should be destroyed
   * @param SnackbarContent content Content to be destroyed
   */
  onDestroy: (content: SnackbarContent[]) => void;
  /** Structure of content to render */
  content: SnackbarContent[];
  /** List of string to be display as title on snackbarTile hover */
  customTitle?: SnackbarTitle[];
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** Number of the element */
  index: number;
}

const SnackbarTile = ({ ...props }: SnackbarTileProps): JSX.Element => {
  const [fadeout, setFadeout] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  // eslint-disable-next-line
  const { clear, reset } = useTimeout(() => timeoutCallback(), 10000);
  const pauseButton = useRef<HTMLDivElement>(null);
  const closeButton = useRef<HTMLDivElement>(null);

  /**
   * @description Handles the close of the snackbar.
   */
  const handleClose = (): void => {
    // Trigger fadeout-animation
    setFadeout(true);

    // Wait for the fade-out animation before call destroy-callback
    setTimeout(() => {
      props.onDestroy(props.content);
    }, 300);
  };

  /**
   * @description Handles the close of the snackbar.
   */
  const handlePause = (): void => {
    clear();
    setDisabled(true);
  };

  /**
   * @description Return if the messages of the snackbar-tile contains an error.
   * @returns {boolean} Snackbar tile contains error
   */
  const containsError = (): boolean => {
    let contains = false;

    props.content.forEach(c => {
      if (c.type === TYPE_ERROR) {
        contains = true;
      }
    });
    return contains;
  };

  /**
   * @description Returns the icon-name of the beta-icon-font which will be displayed in the snackbar.
   * The icon is used as a type-visualization
   * @param {string} type One of the following types [success|info|error]
   * @returns {boolean} Name of the icon from beta-icons-font
   */
  const getIcon = (type: SnackbarContentType): string => {
    return {
      [TYPE_SUCCESS]: 'done_filled',
      [TYPE_INFO]: 'info_filled',
      [TYPE_ERROR]: 'error_filled'
    }[type];
  };

  const isDisabled = (): boolean => {
    return containsError() || disabled;
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    switch (e.key) {
      case 'Escape':
        handleClose();
        e.preventDefault();
        break;
      case 'ArrowLeft':
        if (!isDisabled()) {
          pauseButton.current?.focus();
          e.preventDefault();
        }
        break;
      case 'ArrowRight':
        closeButton.current?.focus();
        e.preventDefault();
        break;
    }

  };

  const closeOnKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleClose();
      e.preventDefault();
    }

  };

  const pauseOnKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      handlePause();
      e.preventDefault();
    }

  };

  const timeoutCallback = (): void => {
    if (!containsError()) {
      handleClose();
    }
  };

  useEffect(() => {
    reset();
  }, [reset]);

  // Choose css-fadeout-class for animation based on the amount of messages in the snackbar (1-3)
  // max(0, min(content.length, 3)) ensures that a value between 1-3 is choosed
  const fadeoutClass = `bux_move_out_${Math.max(1, Math.min(props.content.length, 3))}`;
  const fadeinClass = 'bux_move_in';
  /**
   * @description Renders a snackbar-tile with 1-3 messages
   * @returns {object} JSX-Object to render
   */

  let customTitle = '';
  if (props.customTitle && props.customTitle.length > 0) {
    props.customTitle.forEach(ct => {
      customTitle += ct.text + '\n';
    });
  }

  return (
    <div id={`${props.id}_tiles_wrapper_${props.index}`} className={`bux_snackbar_tile_wrapper ${fadeout ? fadeoutClass : fadeinClass}`}>
      <div id={`${props.id}_tiles_container_${props.index}`} className={'bux_snackbar_tile'}>
        {
          /* Render each message for the snackbar-tile */
          props.content.map((contentPart, i) => {
            return (
              <div key={`${props.index}_${i}`} id={`${props.id}_tile_${props.index}_${i}`} className={'bux_snackbar_tile_msg'}>
                <Icon
                  id={`${props.id}_tile_${props.index}_${i}`}
                  className={'bux_snackbar_tile_msg_icon'}
                  name={getIcon(contentPart.type)}
                />
                <p
                  id={`${props.id}_tile_${props.index}_${i}_text`}
                  className='bux_snackbar_tile_msg_text'
                  // Replace in title is only used to make lists of entries separated by ', ' better readable like in the case of multifileupload
                  title={customTitle && customTitle.length > 0 ? customTitle : contentPart?.text?.replace(', ', ',\n')}>
                  {contentPart.text}
                </p>
              </div>
            );
          })
        }
        {/* Render action-buttons */}
        <div className={'snackbar_actions_container'} onKeyDown={handleKeyDown} >
          <div
            ref={pauseButton}
            className={`snackbar_action ${isDisabled() ? 'snackbar_action_disabled' : ''}`}
            onClick={() => handlePause()}
            onMouseUp={e => e.currentTarget.blur()}
            onKeyDown={pauseOnKeyDown}
            tabIndex={isDisabled() ? -1 : 0}>
            <Icon
              id={`snackbar_tile_pause_${props.index}`}
              className={'bux_snackbar_tile_icon'}
              name={'pause'}
              disabled={isDisabled()}
            />
          </div>
          <div
            ref={closeButton}
            className={'snackbar_action'}
            onClick={() => handleClose()}
            onMouseUp={e => e.currentTarget.blur()}
            onKeyDown={closeOnKeyDown}
            tabIndex={0}>
            <Icon
              id={`snackbar_tile_close_${props.index}`}
              className={'bux_snackbar_tile_icon'}
              name={'close'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnackbarTile;
