import { Component } from 'react'
import { connect } from 'react-redux'

import './MainScreen.scss'

import { LoadingSpinner, NavbarButton } from 'BetaUX2Web-Components/src/'
import { EditableWidthDrawer } from 'components/editable-width_drawer/EditableWidthDrawer'
import Header from 'components/header/Header'
import SearchResultDatabase from 'components/search_result/database/SearchResultDatabase'
import SearchResultDefinition from 'components/search_result/definition/SearchResultDefinition'
import SearchResultImport from 'components/search_result/import/SearchResultImport'
import SearchResultSearch from 'components/search_result/search/SearchResultSearch'
import SearchResultSecurity from 'components/search_result/security/SearchResultSecurity'
import SearchResultServer from 'components/search_result/server/SearchResultServer'
import SearchResultStatistic from 'components/search_result/statistic/SearchResultStatistic'
import SearchResultUserManagement from 'components/search_result/usermanagement/SearchResultUserManagement'

import Assignment from 'components/drawer/content/assignment/Assignment'
import Database from 'components/drawer/content/database/Database'
import Definition from 'components/drawer/content/definition/Definition'
import Import from 'components/drawer/content/import/Import'
import Queue from 'components/drawer/content/queue/Queue'
import Search from 'components/drawer/content/search/Search'
import Security from 'components/drawer/content/security/Security'
import Server from 'components/drawer/content/server/Server'
import Statistic from 'components/drawer/content/statistic/Statistic'
import UserManagement from 'components/drawer/content/usermanagement/UserManagement'

import SearchResultAssignment from 'components/search_result/assignment/SearchResultAssignment'
import SearchResultQueue from 'components/search_result/queue/SearchResultQueue'

import * as ContentTypes from 'components/drawer/content/Content'
import { translate } from 'language/Language'
import * as AuthActions from 'redux/actions/AuthenticationActions'
import * as DrawerUtils from 'utils/DrawerUtils'

class MainScreen extends Component {
  state = {
    drawerExpanded: 'initialized'
  }

  componentDidMount = () => {
    const { auth } = this.props
    const el = document.querySelector('#badm_tab_title')
    if (el && !el.innerHTML.startsWith('_beta admin |')) {
      el.innerHTML = `_beta admin | ${auth.serverdata.systemname}`
    }

    this.validateSession()
  }

  componentWillUnmount = () => {
    const el = document.querySelector('#badm_tab_title')
    if (el) {
      el.innerHTML = '_beta admin'
    }
  }

  onDrawerVisibilityChanged = expanded => {
    this.setState({
      drawerExpanded: expanded
    })
  }

  /**
   * @description Let the child components control the drawer expand/collapse behavior.
   */
  onAutoDrawer = value => {
    const { drawerExpanded } = this.state
    const changed = value
      ? ![true, 'initialized'].includes(drawerExpanded)
      : value !== drawerExpanded
    if (changed) {
      this.setState({
        drawerExpanded: value
      })
    }
  }

  getSearchContent = () => {
    let url = this.props.match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(0, url.indexOf('/'))
    }
    switch (url) {
      case 'usermanagement': return (
        <SearchResultUserManagement
          id='userManagement'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
      case 'import': return (
        <SearchResultImport
          id='import'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
      case 'statistic': return (
        <SearchResultStatistic
          id='statistic'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
      case 'security': return (
        <SearchResultSecurity
          id='security'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
      case 'definition': return (
        <SearchResultDefinition
          id='definition'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
      case 'search': return (
        <SearchResultSearch
          id='search'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
      case 'server': return (
        <SearchResultServer
          id='server'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
      case 'database': return (
        <SearchResultDatabase
          id='database'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
      case 'queue': return (
        <SearchResultQueue
          id='queue'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
      case 'assignment': return (
        <SearchResultAssignment
          id='assignment'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
      default: return (
        <SearchResultSearch
          id='search'
          match={this.props.match}
          drawerExpanded={this.state.drawerExpanded}
          autoDrawer={this.onAutoDrawer}
        />
      )
    }
  }

  /**
   * @description Gets the current content type.
   */
  getContentType = () => {

    let url = this.props.match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better compariation
    // url can be abc or abc/def: if no slash is given then get abc otherwsie def
    if (url.indexOf('/') !== -1) {
      url = url.substring(0, url.indexOf('/'))
    }

    switch (url) {
      case 'usermanagement': return ContentTypes.CONTENT_TYPE_USER_MANAGEMENT
      case 'definition': return ContentTypes.CONTENT_TYPE_DEFINITION
      case 'search': return ContentTypes.CONTENT_TYPE_SEARCH
      case 'import': return ContentTypes.CONTENT_TYPE_IMPORT
      case 'statistic': return ContentTypes.CONTENT_TYPE_STATISTIC
      case 'server': return ContentTypes.CONTENT_TYPE_SERVER
      case 'database': return ContentTypes.CONTENT_TYPE_DATABASE
      case 'assignment': return ContentTypes.CONTENT_TYPE_ASSIGNMENT
      case 'queue': return ContentTypes.CONTENT_TYPE_QUEUE
      case 'security': return ContentTypes.CONTENT_TYPE_SECURITY
      default: return ContentTypes.CONTENT_TYPE_SEARCH
    }
  }

  /**
   * @description Gets the navbar buttons for an admin.
   */
  getNavbarButtons = () => {
    let navbarButtonsLinks = {
      search: DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_SEARCH),
      import: DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_IMPORT),
      queue: DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_QUEUE),
      definition: DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_DEFINITION),
      assignment: DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_ASSIGNMENT),
      statistic: DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_STATISTIC),
      usermanagement: DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_USER_MANAGEMENT),
      security: DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_SECURITY),
      server: DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_SERVER),
      database: DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_DATABASE)
    }

    const contentType = this.getContentType()
    const id = 'drawer'

    const navBarButtons = []

    // I've choosed this unelegant and simple solution for readablity.
    if (navbarButtonsLinks.search.length > 0) {
      navBarButtons.push(
        <NavbarButton
          id={`${id}_nav_search`}
          key={`${id}_nav_search`}
          icon={'search'}
          title={translate('general.search')}
          link={'/search'}
          links={DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_SEARCH)}
          selected={contentType === ContentTypes.CONTENT_TYPE_SEARCH}
          onAutoDrawer={() => this.onAutoDrawer(true)}
        />
      )
    }
    if (navbarButtonsLinks.import && navbarButtonsLinks.import.length > 0) {
      navBarButtons.push(
        <NavbarButton
          id={`${id}_nav_import`}
          key={`${id}_nav_import`}
          icon={'import_server'}
          title={translate('general.import')}
          link={'/import'}
          links={DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_IMPORT)}
          selected={contentType === ContentTypes.CONTENT_TYPE_IMPORT}
          onAutoDrawer={() => this.onAutoDrawer(true)}
        />
      )
    }
    if (navbarButtonsLinks.queue.length > 0) {
      navBarButtons.push(
        <NavbarButton
          id={`${id}_nav_queue`}
          key={`${id}_nav_queue`}
          icon={'queue'}
          title={translate('general.queue')}
          link={'/queue'}
          links={DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_QUEUE)}
          selected={contentType === ContentTypes.CONTENT_TYPE_QUEUE}
          onAutoDrawer={() => this.onAutoDrawer(true)}
        />
      )
    }
    if (navbarButtonsLinks.definition.length > 0) {
      navBarButtons.push(
        <NavbarButton
          id={`${id}_nav_definition`}
          key={`${id}_nav_definition`}
          icon={'list'}
          title={translate('general.definition')}
          link={'/definition'}
          links={DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_DEFINITION)}
          selected={contentType === ContentTypes.CONTENT_TYPE_DEFINITION}
          onAutoDrawer={() => this.onAutoDrawer(true)}
        />
      )
    }
    if (navbarButtonsLinks.assignment.length > 0) {
      navBarButtons.push(
        <NavbarButton
          id={`${id}_nav_assignment`}
          key={`${id}_nav_assignment`}
          icon={'assignment'}
          title={translate('general.assignment')}
          link={'/assignment'}
          links={DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_ASSIGNMENT)}
          selected={contentType === ContentTypes.CONTENT_TYPE_ASSIGNMENT}
          onAutoDrawer={() => this.onAutoDrawer(true)}
        />
      )
    }
    if (navbarButtonsLinks.statistic.length > 0) {
      navBarButtons.push(
        <NavbarButton
          id={`${id}_nav_statistic`}
          key={`${id}_nav_statistic`}
          icon={'statistic'}
          title={translate('general.statistic')}
          link={'/statistic'}
          links={DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_STATISTIC)}
          selected={contentType === ContentTypes.CONTENT_TYPE_STATISTIC}
          onAutoDrawer={() => this.onAutoDrawer(true)}
        />
      )
    }
    if (navbarButtonsLinks.usermanagement.length > 0) {
      navBarButtons.push(
        <NavbarButton
          id={`${id}_nav_usermanagement`}
          key={`${id}_nav_usermanagement`}
          icon={'user'}
          title={translate('general.usermanagement')}
          link={'/usermanagement'}
          links={DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_USER_MANAGEMENT)}
          selected={contentType === ContentTypes.CONTENT_TYPE_USER_MANAGEMENT}
          onAutoDrawer={() => this.onAutoDrawer(true)}
        />
      )
    }
    if (navbarButtonsLinks.security.length > 0) {
      navBarButtons.push(
        <NavbarButton
          id={`${id}_nav_security`}
          key={`${id}_nav_security`}
          icon={'security'}
          title={translate('general.security')}
          link={'/security'}
          links={DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_SECURITY)}
          selected={contentType === ContentTypes.CONTENT_TYPE_SECURITY}
        />
      )
    }
    if (navbarButtonsLinks.server.length > 0) {
      navBarButtons.push(
        <NavbarButton
          id={`${id}_nav_server`}
          key={`${id}_nav_server`}
          icon={'server'}
          title={translate('general.server')}
          link={'/server'}
          links={DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_SERVER)}
          selected={contentType === ContentTypes.CONTENT_TYPE_SERVER}
          onAutoDrawer={() => this.onAutoDrawer(true)}
        />
      )
    }
    if (navbarButtonsLinks.database.length > 0) {
      navBarButtons.push(
        <NavbarButton
          id={`${id}_nav_database`}
          key={`${id}_nav_database`}
          icon={'database'}
          title={translate('general.database')}
          link={'/database'}
          links={DrawerUtils.getContentItems(ContentTypes.CONTENT_TYPE_DATABASE)}
          selected={contentType === ContentTypes.CONTENT_TYPE_DATABASE}
          onAutoDrawer={() => this.onAutoDrawer(true)}
        />
      )
    }
    return navBarButtons
  }

  /**
   * @description Renders the content.
   */
  getDrawerContent = () => {
    const { match } = this.props
    const contentType = this.getContentType()
    const items = DrawerUtils.getContentItems(contentType)
    const id = 'drawer_content'

    switch (contentType) {
      case ContentTypes.CONTENT_TYPE_SEARCH:
        return (
          <Search
            id={`${id}_search`}
            match={match}
            items={items}
          />)
      case ContentTypes.CONTENT_TYPE_USER_MANAGEMENT:
        return (
          <UserManagement
            id={`${id}_usermanagement`}
            match={match}
            items={items}
          />)
      case ContentTypes.CONTENT_TYPE_IMPORT:
        return (
          <Import
            id={`${id}_import`}
            match={match}
            items={items}
          />)
      case ContentTypes.CONTENT_TYPE_STATISTIC:
        return (
          <Statistic
            id={`${id}_statistic`}
            match={match}
            items={items}
          />)
      case ContentTypes.CONTENT_TYPE_SERVER:
        return (
          <Server
            id={`${id}_server`}
            match={match}
            items={items}
          />
        )
      case ContentTypes.CONTENT_TYPE_DATABASE:
        return (
          <Database
            id={`${id}_database`}
            match={match}
            items={items}
          />)
      case ContentTypes.CONTENT_TYPE_SECURITY:
        return (
          <Security
            id={`${id}_security`}
            match={match}
            items={items}
          />)
      case ContentTypes.CONTENT_TYPE_DEFINITION:
        return (
          <Definition
            id={`${id}_definition`}
            match={match}
            items={items}
          />)
      case ContentTypes.CONTENT_TYPE_ASSIGNMENT:
        return (
          <Assignment
            id={`${id}_assignment`}
            match={match}
            items={items}
          />)
      case ContentTypes.CONTENT_TYPE_QUEUE:
        return (
          <Queue
            id={`${id}_queue`}
            match={match}
            items={items}
          />)
      default:
        return null
    }
  }

  validateSession = () => {
    const userId = this.props.auth?.userid
    const serverType = this.props.auth?.serverdata?.server?.type
    const serverVersion = this.props.auth?.serverdata?.server?.version

    if (userId && serverType && serverVersion) {
      this.props.isValidUserSession(userId, serverType, serverVersion, {});
    }
  }

  render = () => {
    const { auth, showLoader, history } = this.props
    return (
      <div className={'bux_mainScreen_container'}>
        <LoadingSpinner showLoader={showLoader} />
        <div id='Mainscreen' className='bux_MainScreen'>
          <Header
            id='header'
            loggedIn={auth.loggedIn ?? false}
            history={history}
          />
          <EditableWidthDrawer
            id={'main_drawerContent'}
            contentLeft={this.getDrawerContent()}
            contentRight={this.getSearchContent()}
            navbarButtons={this.getNavbarButtons()}
            disabled
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    showLoader: state.loadingspinner.showLoader
  }
}

const mapDispatchToProps = dispatch => {
  return {
    isValidUserSession: (userId, serverType, serverVersion, preferences) => AuthActions.isValidUserSession(userId, serverType, serverVersion, preferences)(dispatch),
    logout: (userId, serverType, serverVersion, preferences) => AuthActions.logout(userId, serverType, serverVersion, preferences)(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen)