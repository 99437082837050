import React, { useEffect, useState } from 'react'
import { translate } from 'language/Language';
import { SelectionDocuments } from 'redux/store.types';
import { columnTranslation, EResultTableType, getTranslationsFromApi, TranslationObjFromApi } from 'utils/ColumnUtils';
import { DataTableWrapper } from './DataTableWrapper';
import { useDataTableMapperFunc } from '../search-documents-utils';
import { EmptySearchResult, NoSearch, ResultContainer } from '../../../../BetaUX2Web-Components/src';
import { RootStateOrAny, useSelector } from 'react-redux';

import * as Preferences from 'redux/general/Preferences'

interface SelectionTableProps {
  id: string,
  documents?: SelectionDocuments,
  keepPagination?: boolean,
  headers: string[],
  checkedRows?: string[],
  fillPage: boolean,
  createActionButtons: (rowIndex: number) => JSX.Element[],
  createTableRowAction: (rowIndex: number) => void,
  checkedDropdownItems?: string[],
  onCheckedDropdownAction?: (rows: number[][], index: number) => void,
  additionalInteraction: JSX.Element[],
  drawerExpanded: boolean,
  autoDrawer: () => void,
  openDocViewerCallback: (indexes: number[]) => void
  updateCheckedRowsToParent: (checkedRows: string[]) => void;
  resultTableType?: EResultTableType;
}

export const SelectionTable = (props: SelectionTableProps) => {
  const lang = useSelector((state: RootStateOrAny) => state.auth.serverdata.preferences[Preferences.LANGUAGE]);
  const [apiTranslation, setApiTranslation] = useState<TranslationObjFromApi[]>([])
  const [translatedHeader, setTranslatedHeader] = useState(props.headers)

  const headerIndex = (key: string): number => {
    return props.documents?.header?.indexOf(key) || -1;
  }
  const data = useDataTableMapperFunc()(props.documents?.data ?? [], props.documents?.header ?? [], props.headers ?? [])
  let dataIds;
  if (headerIndex('LTOKEN') !== -1) {
    dataIds = (props.documents?.data ?? []).map((d) => (d[headerIndex('LTOKEN')] + (d[headerIndex('RTOKEN')] || '0000000000000000')))
  }

  useEffect(() => {
    setTranslatedHeader(props.headers.map((h: string) => {
      const translated: TranslationObjFromApi | undefined = apiTranslation.find((apiT: TranslationObjFromApi) => apiT.key === h) ?? undefined
      const dynamicTranslatedHeaderKey = translated?.value || undefined
      const staticTranslatedHeaderKeyOrRawHeaderKey = (h in columnTranslation ? translate(columnTranslation[(h as keyof typeof columnTranslation)]) : h)
      return dynamicTranslatedHeaderKey ?? staticTranslatedHeaderKeyOrRawHeaderKey
    }))
    // eslint-disable-next-line
  }, [apiTranslation, JSON.stringify(props.headers)])

  useEffect(() => {
    getTranslationsFromApi(props.resultTableType).then((apiTranslation) => {
      setApiTranslation(apiTranslation)
    })
    // eslint-disable-next-line
  }, [lang])

  return (
    <ResultContainer
      drawerExpanded={props.drawerExpanded}
      autoDrawer={props.autoDrawer}
    >
      {
        translatedHeader.length !== 0
          ? (
            data.length !== 0
              ? <DataTableWrapper
                id={props.id}
                header={translatedHeader}
                data={data}
                dataIds={dataIds}
                checkedRows={props.checkedRows}
                fillPage={props.fillPage}
                keepPagination={props.keepPagination}
                rowActions={props.createActionButtons}
                onRowClick={index => props.createTableRowAction(index)}
                checkedDropdownItems={props.checkedDropdownItems}
                onCheckedDropdownAction={props.onCheckedDropdownAction}
                additionalTableActions={props.additionalInteraction}
                openDocViewerCallback={props.openDocViewerCallback}
                updateCheckedRowsToParent={props.updateCheckedRowsToParent}
              />
              : <EmptySearchResult
                id={`${props.id}_emptysearchresult`}
                description={translate('emptysearchresult.description')}
              />
          )
          : <NoSearch
            id={`${props.id}_nosearch`}
            message={translate('nosearch.description')}
          />
      }
    </ResultContainer>
  )
}