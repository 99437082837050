import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'

import SearchResultSecurityProfiles from './profiles/SearchResultSecurityProfiles'
import SearchResultEntities from './entity/SearchResultEntities'

class SearchResultSecurity extends Component {

  /**
   * @description Gets the body which should be rendered.
   */
  getBody() {
    const { match, id, drawerExpanded, autoDrawer } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    switch (url) {
      // status search result
      case 'security':
        return (
          <SearchResultSecurityProfiles
            id={`${id}_security`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'entity':
        return (
          <SearchResultEntities
            id={`${id}_entity`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      // fallback is database status
      default:
        return (
          // take care of the route because if you enter a wrong route you can create an infinite loop!
          <Redirect to='/security' />
        )
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

SearchResultSecurity.propTypes = {
  id: PropTypes.string.isRequired,
  match: PropTypes.any.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

export default SearchResultSecurity