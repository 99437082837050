import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const FOLDER_DOCUMENT_GET_ASSIGNMENTS_START = 'FOLDER_DOCUMENT_GET_ASSIGNMENTS_START'
export const FOLDER_DOCUMENT_GET_ASSIGNMENTS_SUCCESS = 'FOLDER_DOCUMENT_GET_ASSIGNMENTS_SUCCESS'
export const FOLDER_DOCUMENT_GET_ASSIGNMENTS_FAILED = 'FOLDER_DOCUMENT_GET_ASSIGNMENTS_FAILED'

export const FOLDER_DOCUMENT_GET_ASSIGNMENT_START = 'FOLDER_DOCUMENT_GET_ASSIGNMENT_START'
export const FOLDER_DOCUMENT_GET_ASSIGNMENT_SUCCESS = 'FOLDER_DOCUMENT_GET_ASSIGNMENT_SUCCESS'
export const FOLDER_DOCUMENT_GET_ASSIGNMENT_FAILED = 'FOLDER_DOCUMENT_GET_ASSIGNMENT_FAILED'

export const FOLDER_DOCUMENT_CREATE_ASSIGNMENT_START = 'FOLDER_DOCUMENT_CREATE_ASSIGNMENT_START'
export const FOLDER_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS = 'FOLDER_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS'
export const FOLDER_DOCUMENT_CREATE_ASSIGNMENT_FAILED = 'FOLDER_DOCUMENT_CREATE_ASSIGNMENT_FAILED'

export const FOLDER_DOCUMENT_MODIFY_ASSIGNMENT_START = 'FOLDER_DOCUMENT_MODIFY_ASSIGNMENT_START'
export const FOLDER_DOCUMENT_MODIFY_ASSIGNMENT_SUCCESS = 'FOLDER_DOCUMENT_MODIFY_ASSIGNMENT_SUCCESS'
export const FOLDER_DOCUMENT_MODIFY_ASSIGNMENT_FAILED = 'FOLDER_DOCUMENT_MODIFY_ASSIGNMENT_FAILED'

export const FOLDER_DOCUMENT_DELETE_ASSIGNMENT_START = 'FOLDER_DOCUMENT_DELETE_ASSIGNMENT_START'
export const FOLDER_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS = 'FOLDER_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS'
export const FOLDER_DOCUMENT_DELETE_ASSIGNMENT_FAILED = 'FOLDER_DOCUMENT_DELETE_ASSIGNMENT_FAILED'

export const NO_FOLDER_DOCUMENT_ASSIGNMENTS_FOUND = 'NO_FOLDER_DOCUMENT_ASSIGNMENTS_FOUND'

export const FOLDER_DOCUMENT_GET_DOCUMENTS_START = 'FOLDER_DOCUMENT_GET_DOCUMENTS_START'
export const FOLDER_DOCUMENT_GET_DOCUMENTS_SUCCESS = 'FOLDER_DOCUMENT_GET_DOCUMENTS_SUCCESS'
export const FOLDER_DOCUMENT_GET_DOCUMENTS_FAILED = 'FOLDER_DOCUMENT_GET_DOCUMENTS_FAILED'

export const NO_FOLDER_DOCUMENT_DOCUMENTS_FOUND = 'NO_DEFINITION_DOCUMENTS_FOUND'

/**
 * @description Calling the rest api and do getFolders request.
 * @param {Object} fields The fields to select (If undefined => Return all fields).
 * @param {String} folderid The folderid to search for. Blank if not a searchcriteria.
 * @param {String} form The form to search for. Blank if not a searchcriteria.
 * @param {String} extension The extension to search for. Blank if not a searchcriteria.
 * @param {String} report The report to search for. Blank if not a searchcriteria.
 * @param {String} dynamicReport The dynamicReport to search for. Blank if not a searchcriteria.
 * @param {Function} callback The callback which will be called of the request was successful.
 */
export function getFolderDocumentAssignments(fields, folderid, form,
  extension, report, dynamicReport, callback, keepPagination = false) {

  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: FOLDER_DOCUMENT_GET_ASSIGNMENTS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (folderid) { queryParams.push(`GLRNAME=${encodeURIComponent(folderid)}`) }
    if (form) { queryParams.push(`FORM=${encodeURIComponent(form)}`) }
    if (extension) { queryParams.push(`EXT=${encodeURIComponent(extension)}`) }
    if (report) { queryParams.push(`REPORT=${encodeURIComponent(report)}`) }
    if (dynamicReport) { queryParams.push(`WREPORT=${encodeURIComponent(dynamicReport)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folderdocumentassignments?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_FOLDER_DOCUMENT_ASSIGNMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: FOLDER_DOCUMENT_GET_ASSIGNMENTS_FAILED })
          }
        }
        else {
          dispatch({ type: FOLDER_DOCUMENT_GET_ASSIGNMENTS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.folder_document.loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DOCUMENT_GET_ASSIGNMENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getFolderDocumentAssignment(fields, folder, form, ext, report, dynamicReport, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]

    dispatch({ type: FOLDER_DOCUMENT_GET_ASSIGNMENT_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    queryParams.push(`GLRNAME=${encodeURIComponent(folder)}`)
    queryParams.push(`FORM=${encodeURIComponent(form)}`)
    queryParams.push(`EXT=${encodeURIComponent(ext)}`)
    queryParams.push(`REPORT=${encodeURIComponent(report)}`)
    queryParams.push(`WREPORT=${encodeURIComponent(dynamicReport)}`)

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folderdocumentassignment?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FOLDER_DOCUMENT_GET_ASSIGNMENT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: FOLDER_DOCUMENT_GET_ASSIGNMENT_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('assignment.folder_document.get_folder_document_assignment_error', lang, [folder, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DOCUMENT_GET_ASSIGNMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do create folder documents request.
 * @param {Object} folderDocument The folder definition to create:
 * GLRNAME: String
 * FORM: String
 * EXT: String
 * REPORT: String
 * WREPORT: String
 * @param {Function} callback The callback which will be called of the request was successful.
 */
export function createFolderDocumentAssignment(folderDocument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let assignmentMessage = `${Lang.translate('definition.folder_id')}: ${folderDocument['GLRNAME']}, ${Lang.translate('general.form')}: ${folderDocument['FORM']}`
    if (folderDocument['EXT']) {
      assignmentMessage += `, ${Lang.translate('general.extension')}: ${folderDocument['EXT']}`
    }
    if (folderDocument['REPORT']) {
      assignmentMessage += `, ${Lang.translate('general.report')}: ${folderDocument['REPORT']}`
    }
    if (folderDocument['WREPORT']) {
      assignmentMessage += `, ${Lang.translate('assignment.folder_document.dynamic_report')}: ${folderDocument['WREPORT']}`
    }

    dispatch({ type: FOLDER_DOCUMENT_CREATE_ASSIGNMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folderdocumentassignment`, {
        method: 'post',
        body: ObjectUtils.removeByValue(folderDocument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FOLDER_DOCUMENT_CREATE_ASSIGNMENT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: FOLDER_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('assignment.folder_document.create_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.folder_document.create_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DOCUMENT_CREATE_ASSIGNMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function deleteFolderDocumentAssignment(folderDocument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let assignmentMessage = `${Lang.translate('definition.folder_id')}: ${folderDocument['GLRNAME']}, ${Lang.translate('general.form')}: ${folderDocument['FORM']}`
    if (folderDocument['EXT'] !== '') {
      assignmentMessage += `, ${Lang.translate('general.extension')}: ${folderDocument['EXT']}`
    }
    if (folderDocument['REPORT'] !== '') {
      assignmentMessage += `, ${Lang.translate('general.report')}: ${folderDocument['REPORT']}`
    }
    if (folderDocument['WREPORT'] !== '') {
      assignmentMessage += `, ${Lang.translate('assignment.folder_document.dynamic_report')}: ${folderDocument['WREPORT']}`
    }

    dispatch({ type: FOLDER_DOCUMENT_DELETE_ASSIGNMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folderdocumentassignment${createQueryParamsForFetch(folderDocument)}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FOLDER_DOCUMENT_DELETE_ASSIGNMENT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: FOLDER_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS })

          SnackbarActions.show(
            Lang.translate('assignment.folder_document.delete_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(
          Lang.translate('assignment.folder_document.delete_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)

        dispatch({ type: FOLDER_DOCUMENT_DELETE_ASSIGNMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getDocuments(fields, form, extension, report, smode, outputChannelID, outputFormatID, process, owner, title, ppn, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: FOLDER_DOCUMENT_GET_DOCUMENTS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (form) { queryParams.push(`FORM=${encodeURIComponent(form)}`) }
    if (extension) { queryParams.push(`EXT=${encodeURIComponent(extension)}`) }
    if (report) { queryParams.push(`REPORT=${encodeURIComponent(report)}`) }
    if (smode) { queryParams.push(`SMODE=${encodeURIComponent(smode)}`) }
    if (outputChannelID) { queryParams.push(`DCR=${encodeURIComponent(outputChannelID)}`) }
    if (outputFormatID) { queryParams.push(`PCR=${encodeURIComponent(outputFormatID)}`) }
    if (process) { queryParams.push(`PROCESS=${encodeURIComponent(process)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`LTITLE=${encodeURIComponent(title)}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documents?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_FOLDER_DOCUMENT_DOCUMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: FOLDER_DOCUMENT_GET_DOCUMENTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: FOLDER_DOCUMENT_GET_DOCUMENTS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback(jsondata.data)
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.documents_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DOCUMENT_GET_DOCUMENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function modifyFolderDocumentAssignment(folderDocument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let assignmentMessage = `${Lang.translate('definition.folder_id')}: ${folderDocument['GLRNAME']}, ${Lang.translate('general.form')}: ${folderDocument['FORM']}`
    if (folderDocument['EXT'] !== '') {
      assignmentMessage += `, ${Lang.translate('general.extension')}: ${folderDocument['EXT']}`
    }
    if (folderDocument['REPORT'] !== '') {
      assignmentMessage += `, ${Lang.translate('general.report')}: ${folderDocument['REPORT']}`
    }
    if (folderDocument['WREPORT'] !== '') {
      assignmentMessage += `, ${Lang.translate('assignment.folder_document.dynamic_report')}: ${folderDocument['WREPORT']}`
    }

    dispatch({ type: FOLDER_DOCUMENT_MODIFY_ASSIGNMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folderdocumentassignment`, {
        method: 'put',
        body: ObjectUtils.removeByValue(folderDocument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FOLDER_DOCUMENT_MODIFY_ASSIGNMENT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: FOLDER_DOCUMENT_MODIFY_ASSIGNMENT_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('assignment.folder_document.modify_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.folder_document.modify_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DOCUMENT_MODIFY_ASSIGNMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    getFolderDocumentAssignments(
      undefined,
      prefs[Preferences.ASSIGNMENT_FOLDER_DOC_FOLDERID],
      prefs[Preferences.ASSIGNMENT_FOLDER_DOC_FORM],
      prefs[Preferences.ASSIGNMENT_FOLDER_DOC_EXTENSION],
      prefs[Preferences.ASSIGNMENT_FOLDER_DOC_REPORT],
      prefs[Preferences.ASSIGNMENT_FOLDER_DOC_DYNAMICREPORT],
      undefined,
      true
    )(dispatch)
  }
}