import hash from 'object-hash'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Components
import {
  Column, DataHierarchy, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup,
  TableButtonGroupItem, TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CopyDocumentNodeDialog from 'components/dialogs/copy_document_node_dialog/CopyDocumentNodeDialog'
import CreateDocumentNodeDialog from 'components/dialogs/create_document_node_dialog/CreateDocumentNodeDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyDocumentNodeDialog from 'components/dialogs/modify_document_node_dialog/ModifyDocumentNodeDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as DocumentNodesActions from 'redux/actions/DocumentNodesDefinitionActions'
import * as DocumentNodesHierarchyActions from 'redux/actions/DocumentNodesHierarchyActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as SortUtils from 'utils/SortUtils'
import * as UrlUtils from 'utils/UrlUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

// no childs const to reduce duplicate strings and avoid spellings bugs
const NO_CHILDS = 'NO_CHILDS'

class SearchResultDocumentNode extends Component {
  state = {
    dialogs: {
      showCreate: false,
      createData: undefined,
      showModify: false,
      showCopy: false,
      showDelete: false,
      showTableSettings: false
    },
    parentNodeID: undefined,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: NODE_ID, translation: 'definition.node_id', default: true },
      { rest: PARENT_NODE_ID, translation: 'general.parent_node_id', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: IDENTIFIER, translation: 'definition.index_identifier', default: true },
      { rest: TYPE, translation: 'general.type', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CUSER },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description Modifies the new data, which is loaded through rest api request when open a hierarchy row.
   * The data is modified like the other data in the data hierarchy to display just the specific data information we want.
   * After the modification, the data is updated into the redux state.
   */
  // eslint-disable-next-line camelcase
  componentDidUpdate = nextProps => {
    const { hierarchyEntriesData, updateHierarchyEntriesData, datemask } = this.props
    const hierarchyHasExpanded = (hierarchyEntriesData?.length > 0 && !nextProps?.hierarchyEntriesData) || hierarchyEntriesData?.length > nextProps?.hierarchyEntriesData?.length // Note: Check for first expansion after render OR any other expansion -> 'hierarchyEntriesData' contains the new state in render cycle (not 'nextProps')

    if (hierarchyHasExpanded) {
      let buffer = []
      const usedHeaders = this.getDefaultHeader()
      const newHierarchyEntries = [...hierarchyEntriesData]
      const lastEntry = newHierarchyEntries[newHierarchyEntries.length - 1]
      const data = Array.isArray(lastEntry.data) ? lastEntry.data : [lastEntry.data]
      data.forEach(element => {
        let dataBuffer = []
        if (element !== NO_CHILDS) {
          usedHeaders.forEach(h => {
            if (h === TYPE) {
              dataBuffer.push(element[this.headerDataHierarchy(TYPE)] === 'DND' ? translate('general.node') : translate('group.group'))
            }
            else if (h === LASTCHANGED) {
              dataBuffer.push(DateUtils.getDate(datemask, element[this.headerDataHierarchy('CDATE')], element[this.headerDataHierarchy('CTIME')].substring(0, 8)))
            }
            else {
              dataBuffer.push(element[this.headerDataHierarchy(h)])
            }
          })
          buffer.push(dataBuffer)
        }
        else {
          buffer = NO_CHILDS
        }
      })
      if (buffer.length > 0) {
        lastEntry.data = buffer
        lastEntry.key = Array.isArray(buffer) ? buffer.map(d => hash(d)) : [hash(buffer)]
        newHierarchyEntries[newHierarchyEntries.length - 1] = lastEntry
        updateHierarchyEntriesData(newHierarchyEntries)
      }
    }
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  handleDelete = () => {
    const { deleteDocumentNode, documentNode, preferences } = this.props
    const documentNodeObj = {
      DNDNAME: documentNode.DNDNAME,
      DNDPNAME: documentNode.DNDPNAME
    }
    // const callback = () => this.closeDialog('showDelete')
    let displayAs = preferences[Preferences.DEFINITION_DOCUMENT_NODE_DISPLAY_AS]
      ? Math.max(DefinitionUtils.RECIPIENT_DISPLAY_AS.findIndex(el => el.key === preferences[Preferences.DEFINITION_DOCUMENT_NODE_DISPLAY_AS]), 0)
      : 0
    const callback = () => this.setState({ dialogs: { ...this.state.dialogs, showDelete: false } }, () => {
      if (displayAs === 1) {
        this.updateDocumentNodeAfterDelete()
      }
    })
    deleteDocumentNode(documentNodeObj, callback)
  }

  openInsertChild = index => {
    const { documentNodes } = this.props
    this.setState({ parentNodeID: documentNodes.data[index][this.headerDataTable('DNDNAME')] })
  }

  handleInsertChildHierarchy = data => {
    this.setState({
      parentNodeID: data[0]
    })
  }

  /**
   * @description Shows the copy document node dialog, if the getDocumentNode request was successful.
   * @param {Number} data The data of the document node which should be copied.
   */
  openCopyDialogHierarchy = (data) => {
    const documentNodeObj = {
      DNDNAME: data[0],
      DNDPNAME: data[1]
    }
    this.props.getDocumentNode(documentNodeObj, () => this.setState({ dialogs: { ...this.state.dialogs, showCopy: true } }))
  }

  openDeleteDialogHierarchy = data => {
    const documentNodeObj = {
      DNDNAME: data[0],
      DNDPNAME: data[1]
    }
    this.props.checkDocumentNodeisDeletable(data[0], () => {
      this.props.getDocumentNode(documentNodeObj, () => this.setState({ dialogs: { ...this.state.dialogs, showDelete: true } }))
    })
  }

  openModifyDialogHierarchy = data => {
    const documentNodeObj = {
      DNDNAME: data[0],
      DNDPNAME: data[1]
    }
    this.props.getDocumentNode(documentNodeObj, () => this.setState({ dialogs: { ...this.state.dialogs, showModify: true } }))
  }

  showDocuments = index => {
    const { documentNodes } = this.props
    const header = documentNodes.header
    const data = documentNodes.data[index]
    const keys = [
      { rest: 'DNDNAME', ui: 'nodeID' }
    ]

    const state = {
      nodeID: data[header.indexOf('DNDNAME')]
    }

    const urlToPush = `/assignment/node_document${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { documentNodes, id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType={'material'}
        iconName={'edit'}
        title={translate('general.edit')}
        onClick={() => this.openDialog('showModify', rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType={'material'}
        iconName={'delete'}
        title={translate('general.delete')}
        onClick={() => this.openDialog('showDelete', rowIndex)}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.openDialog('showModify', rowIndex)}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => this.openDialog('showCopy', rowIndex)}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        {
          // DND === type node
          rowIndex !== undefined && documentNodes.data[rowIndex][this.headerDataTable('DNDTYPE')] === 'DND' &&
          <TableButtonGroupItem
            onClick={() => this.openInsertChild(rowIndex)}
            id={`${id}_insertChildBtn`}
            icon={<Icon name={'add'} className={'actionIcon'} />}
            text={translate('general.insert_child')}
            title={translate('general.insert_child')}
          />
        }
        <TableButtonGroupItem
          onClick={() => this.openDialog('showDelete', rowIndex)}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
        {
          // DGI == type group
          rowIndex !== undefined && documentNodes.data[rowIndex][this.headerDataTable('DNDTYPE')] === 'DGI' &&
          <>
            <TableButtonGroupSeparator />
            <TableButtonGroupItem
              onClick={() => this.showDocuments(rowIndex)}
              id={`${id}_show_documents_btn`}
              icon={<Icon name='document' className='actionIcon' />}
              text={translate('general.display_documents')}
              title={translate('general.display_documents')}
            />
          </>
        }
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Array} data The data of the current row.
   */
  createActionButtonsHierarchy = (data, index) => {
    const { id } = this.props

    return [
      <TableButton
        iconName='edit'
        id={`${id}_tableButtonEdit_${index}`}
        onClick={() => this.openModifyDialogHierarchy(data)}
        title={translate('general.edit')}
      />,
      <TableButton
        disabled={data[4] === 'Group'}
        iconName='add'
        id={`${id}_tableButtonInsertChild_${index}`}
        onClick={() => data[4] !== 'Group' && this.handleInsertChildHierarchy(data)}
        title={data[4] === 'Group' ? translate('definition.document_node_no_childs_possible_for_group') : translate('general.insert_child')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${index}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          icon={<Icon name='edit' className='actionIcon' />}
          id={`${id}_editBtn`}
          onClick={() => this.openModifyDialogHierarchy(data)}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          disabled={data[4] === 'Group'}
          icon={<Icon name='add' className='actionIcon' />}
          iconName='add'
          id={`${id}_tableButtonInsertChild_${index}`}
          onClick={() => data[4] !== 'Group' && this.handleInsertChildHierarchy(data)}
          text={data[4] === 'Group' ? translate('definition.document_node_no_childs_possible_for_group') : translate('general.insert_child')}
          title={data[4] === 'Group' ? translate('definition.document_node_no_childs_possible_for_group') : translate('general.insert_child')}
        />
        <TableButtonGroupItem
          icon={<Icon name='copy' className='actionIcon' />}
          id={`${id}_copyBtn`}
          onClick={() => this.openCopyDialogHierarchy(data)}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        <TableButtonGroupItem
          icon={<Icon name='delete' className='actionIcon' />}
          iconName='delete'
          iconType='material'
          id={`${id}_tableButtonDelete_${index}`}
          onClick={() => this.openDeleteDialogHierarchy(data)}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtonsTable = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.openPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={() => this.props.refreshData()}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ dialogs: { ...this.state.dialogs, showTableSettings: true } })}
        />,
      ]
    )
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtonsHierarchy = (data, header) => {
    return (
      <DownloadWrapper
        id='download_wrapper'
        header={header}
        data={[...data]}
        csvSplitter=';'
        filename='data.csv'
        tooltip={translate('table.download_as_csv')}>
        <Link
          id={'download'}
          iconName={'download'}
          onCLick={() => { }}
        />
      </DownloadWrapper>
    )
  }

  /**
   * @description Gets the index of the header in redux state definitions.documentNodes.header
   * @param {String} header Header name of the header in redux state definitions.documentNodes.header
   */
  headerDataTable = header => this.props.documentNodes.header.indexOf(header)

  /**
   * @description Gets the index of the header in redux state hierarchy.documentNode.hierarchyData.header
   * @param {String} header Header name of the header in redux state definitions.documentNodes.header
   */
  headerDataHierarchy = header => this.props.documentNodesHierarchy.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getData = () => {
    const { datemask, documentNodes } = this.props
    let data = []
    let headers = this.getUsedHeader()
    documentNodes.data.forEach(el => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerDataTable('CDATE')], el[this.headerDataTable('CTIME')].substring(0, 8)))
        }
        else if (header === TYPE) {
          dataBuffer.push(el[this.headerDataTable(TYPE)] === 'DND' ? translate('general.node') : translate('group.group'))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerDataTable('CDATE')], el[this.headerDataTable('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerDataTable('CDATE')], el[this.headerDataTable('CTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerDataTable(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  getHierarchyData = () => {
    const { datemask, documentNodesHierarchy } = this.props
    const data = []
    documentNodesHierarchy && documentNodesHierarchy.data && documentNodesHierarchy.data.forEach(el => {
      const nodeID = el[this.headerDataHierarchy('DNDNAME')]
      const parentNodeID = el[this.headerDataHierarchy('DNDPNAME')]
      const owner = el[this.headerDataHierarchy('OWNER')]
      const identifier = el[this.headerDataHierarchy('DNDENAME')]
      const type = el[this.headerDataHierarchy(TYPE)] === 'DND' ? translate('general.node') : translate('group.group')
      const lastChanged = DateUtils.getDate(datemask, el[this.headerDataHierarchy('CDATE')], el[this.headerDataHierarchy('CTIME')].substring(0, 8))
      const dataToPush = [nodeID, parentNodeID, owner, identifier, type, lastChanged]
      data.push(dataToPush)
    })
    return data
  }

  openDialog = (key, index) => {
    const { documentNodes, getDocumentNode } = this.props
    const callback = () => this.setState(prevState => ({
      dialogs: {
        ...prevState.dialogs,
        [key]: true
      }
    }))
    const documentNodeObj = {
      DNDNAME: documentNodes.data[index][this.headerDataTable('DNDNAME')],
      DNDPNAME: documentNodes.data[index][this.headerDataTable('DNDPNAME')]
    }
    getDocumentNode(documentNodeObj, callback)
  }

  closeDialog = key => {
    this.setState(prevState => ({
      dialogs: {
        ...prevState.dialogs,
        [key]: false
      }
    }))
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_DOCUMENT_NODE]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_DOCUMENT_NODE].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_DOCUMENT_NODE]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_DOCUMENT_NODE].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  handleHierarchyClick = (parentKey, rootKey, hash, level, data) => {
    const { getHierarchyEntry } = this.props
    const documentNodeObj = {
      DNDPNAME: data[this.headerDataHierarchy('DNDNAME')]
    }
    getHierarchyEntry(documentNodeObj, parentKey, rootKey, hash, level)
  }

  updateDocumentNodeAfterCreate = (documentNode, parentNode) => {
    const { datemask, preferences, documentNodesHierarchy, updateHierarchyEntriesData, hierarchyEntriesData, getDocumentNodesHierarchy } = this.props
    const documentNodeObj = {
      DNDNAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_NODE_ID],
      DNDPNAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_PARENT_NODE_ID],
      OWNER: preferences[Preferences.DEFINITION_DOCUMENT_NODE_OWNER],
      DNDENAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_IDENTIFIER]
    }

    if (documentNode && !parentNode) {
      getDocumentNodesHierarchy(documentNodesHierarchy)
    } else if (parentNode) {
      getDocumentNodesHierarchy(documentNodeObj, () => {
        const nodeID = documentNode.DNDNAME
        const parentNodeID = documentNode.DNDPNAME
        const owner = documentNode.OWNER
        const identifier = documentNode.DNDENAME
        const type = documentNode.DNDTYPE === 'DND' ? translate('general.node') : translate('group.group')
        const lastChanged = DateUtils.getDate(datemask, documentNode.CDATE, documentNode.CTIME.substring(0, 8))
        const createdEntry = [nodeID, parentNodeID, owner, identifier, type, lastChanged]
        const dataToUpdate = []
        hierarchyEntriesData && hierarchyEntriesData.forEach(entry => {
          const dataToSearch = entry.data !== NO_CHILDS ? [...entry.data] : entry.data
          if (dataToSearch !== NO_CHILDS) {
            for (let i = 0; i < dataToSearch.length; i++) {
              const currParentNode = dataToSearch[i][1]
              if (currParentNode === parentNode) {
                dataToSearch.push(createdEntry)
                break
              }
            }
          }
          dataToUpdate.push({ ...entry, data: dataToSearch })
        })
        dataToUpdate.length > 0 && updateHierarchyEntriesData(dataToUpdate)
      })
    }
  }

  updateDocumentNodeAfterDelete = () => {
    const { preferences, getDocumentNodesHierarchy, hierarchyEntriesData, updateHierarchyEntriesData } = this.props
    const documentNodeObj = {
      DNDNAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_NODE_ID],
      DNDPNAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_PARENT_NODE_ID],
      OWNER: preferences[Preferences.DEFINITION_DOCUMENT_NODE_OWNER],
      DNDENAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_IDENTIFIER]
    }
    getDocumentNodesHierarchy(documentNodeObj, () => {
      const dataToUpdate = []
      let deletedEntryKey
      if (hierarchyEntriesData && hierarchyEntriesData.length > 0) {
        for (let i = 0; i < hierarchyEntriesData.length - 1; i++) {
          if (Array.isArray(hierarchyEntriesData[i].data)) {
            let entryFoundInOpenRows = hierarchyEntriesData[i].data.findIndex(child => child[0] === this.props.documentNode.DNDNAME)
            if (entryFoundInOpenRows !== -1) {
              deletedEntryKey = hierarchyEntriesData[i].key[entryFoundInOpenRows]
              break
            }
          }
        }
        hierarchyEntriesData.forEach(entry => {
          if (entry.parentKey !== deletedEntryKey) {
            if (Array.isArray(entry.data)) {
              if (entry?.data?.length > 0) {
                dataToUpdate.push({
                  ...entry,
                  data: Array.isArray(entry.data) ? [...entry.data.filter(child => child[0] !== this.props.documentNode.DNDNAME)] : entry.data,
                  key: [...entry.key.filter(k => k !== deletedEntryKey)]
                })
              }
            } else {
              dataToUpdate.push({ ...entry })
            }
          }
        })
        updateHierarchyEntriesData(dataToUpdate)
      }
    }, true)
  }

  updateDocumentNodeAfterModify = node => {
    const { preferences, getDocumentNodesHierarchy, updateHierarchyEntriesData, hierarchyEntriesData, datemask } = this.props
    const documentNodeObj = {
      DNDNAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_NODE_ID],
      DNDPNAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_PARENT_NODE_ID],
      OWNER: preferences[Preferences.DEFINITION_DOCUMENT_NODE_OWNER],
      DNDENAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_IDENTIFIER]
    }
    getDocumentNodesHierarchy(documentNodeObj, () => {
      // parse data from modified document node to fit into the hierarchy structure
      const nodeID = node.DNDNAME
      const parentID = node.DNDPNAME
      const owner = node.OWNER
      const identifier = node.DNDENAME
      const foundType = DefinitionUtils.DOCUMENT_NODE_TYPES.find(d => d.key === node.DNDTYPE)
      const type = foundType ? translate(foundType.translationKey) : node.DNDTYPE
      const lastChanged = DateUtils.getDate(datemask, node.CDATE, node.CTIME.substring(0, 8))
      const modifiedEntry = [nodeID, parentID, owner, identifier, type, lastChanged]
      // new data which is passed to the redux store when updated with the created entry
      let dataToUpdate = []
      // is used to check, if the predecessor has changed so the entry needs to be moved when open
      let moveEntry = false
      // loops through all open hierarchy entries
      if (hierarchyEntriesData && hierarchyEntriesData.length > 0) {
        hierarchyEntriesData.forEach(entry => {
          let dataToSearch = entry.data !== NO_CHILDS ? [...entry.data] : entry.data
          if (dataToSearch !== NO_CHILDS) {
            for (let i = 0; i < dataToSearch.length; i++) {
              const currNodeID = dataToSearch[i][0]
              const currParentNode = dataToSearch[i][1]
              // check if open row should be updated with new entry
              if (currNodeID === nodeID) {
                if (currParentNode === parentID) {
                  dataToSearch[i] = modifiedEntry
                }
                else {
                  moveEntry = true
                  // if the row has more than 1 child and the predecessor has changed, just remove the chlild from it to move it later on another position
                  if (dataToSearch.length > 1) {
                    dataToSearch.splice(i, 1)
                  }
                  else {
                    dataToSearch = NO_CHILDS
                  }
                }
                break
              }
            }
          }
          dataToUpdate.push({ ...entry, data: dataToSearch })
        })
        // readds the entry which was removed before when the predessor has changed and the other row is open
        if (moveEntry) {
          for (let i = 0; i < dataToUpdate.length; i++) {
            if (dataToUpdate[i].data !== NO_CHILDS) {
              for (let j = 0; j < dataToUpdate[i].data.length; j++) {
                const currParentNode = dataToUpdate[i].data[j][1]
                if (currParentNode === parentID) {
                  dataToUpdate[i].data.push(modifiedEntry)
                  break
                }
              }
            }
          }
        }
        updateHierarchyEntriesData(dataToUpdate)
      }
    })
  }

  openPrefilledCreateDialog = () => {
    const { preferences } = this.props
    const documentNodeObj = {
      DNDNAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_NODE_ID],
      DNDPNAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_PARENT_NODE_ID],
      OWNER: preferences[Preferences.DEFINITION_DOCUMENT_NODE_OWNER],
      DNDENAME: preferences[Preferences.DEFINITION_DOCUMENT_NODE_IDENTIFIER],
      DNDTYPE: preferences[Preferences.DEFINITION_DOCUMENT_NODE_TYPE],
    }
    this.setState({ dialogs: { ...this.state.dialogs, showCreate: true, createData: documentNodeObj } })
  }

  render = () => {
    const { documentNodes, documentNode, id, loading, drawerExpanded, lang, autoDrawer, datemask, keepPagination,
      preferences, documentNodesHierarchy, hierarchyEntriesData, updateHierarchyEntriesData } = this.props
    const { dialogs, parentNodeID } = this.state
    const tableData = documentNodes && documentNodes.data ? this.getData() : null
    const tableHeader = this.getUsedHeader()
    const hierarchyData = documentNodesHierarchy ? this.getHierarchyData() : null
    const hierarchyHeader = getTranslatedHeaders(this.state.header, this.getDefaultHeader())
    const translatedHeaders = getTranslatedHeaders(this.state.header, tableHeader)
    const fillPage = this.getFillPageInfo()
    const displayAs = Math.max(DefinitionUtils.RECIPIENT_DISPLAY_AS.findIndex(el => el.key === preferences[Preferences.DEFINITION_DOCUMENT_NODE_DISPLAY_AS]), 0)
    return (
      <>
        {dialogs.showCreate &&
          <CreateDocumentNodeDialog
            id={`${id}_createdocumentnode`}
            prefilledData={this.state.dialogs.createData}
            onClose={() => this.setState({ dialogs: { ...this.state.dialogs, showCreate: false, createData: undefined } })}
          />}
        {parentNodeID &&
          <CreateDocumentNodeDialog
            id={`${id}_createdocumentnode`}
            parentNodeID={parentNodeID}
            onClose={() => this.setState({ dialogs: { ...this.state.dialogs, showCreate: false }, parentNodeID: undefined })}
            hierarchyView={displayAs === 1}
            updateHierarchy={(documentNode, parentNode) => {
              this.setState({ parentNodeID: undefined, dialogs: { ...this.state.dialogs, showCreate: false } }, () => {
                this.updateDocumentNodeAfterCreate(documentNode, parentNode)
              })
            }}
          />}
        {dialogs.showModify &&
          <ModifyDocumentNodeDialog
            id={`${id}_modifydocumentnode`}
            onClose={() => this.setState({ dialogs: { ...this.state.dialogs, showModify: false } })}
            hierarchyView={displayAs === 1}
            updateHierarchy={(documentNode, parentNode) => {
              this.setState({ dialogs: { ...this.state.dialogs, showModify: false } }, () => {
                this.updateDocumentNodeAfterModify(documentNode, parentNode)
              })
            }}
          />}
        {dialogs.showCopy &&
          <CopyDocumentNodeDialog
            id={`${id}_copydocumentnode`}
            parentNodeID={parentNodeID}
            onClose={() => this.setState({ dialogs: { ...this.state.dialogs, showCopy: false }, parentNodeID: undefined })}
            hierarchyView={displayAs === 1}
            updateHierarchy={(documentNode, parentNode) => {
              this.setState({ parentNodeID: undefined, dialogs: { ...this.state.dialogs, showCopy: false } }, () => {
                this.updateDocumentNodeAfterCreate(documentNode, parentNode)
              })
            }}
          />}
        {
          dialogs.showDelete &&
          <DeleteDialog
            id={`${id}_documentnode`}
            title={translate('definition.delete_document_node')}
            question={translate('definition.question_delete_document_node')}
            onClose={() => this.closeDialog('showDelete')}
            onDelete={() => this.handleDelete()}>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_node_id_label`}>
                  {translate('definition.node_id')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_node_id_value`}>
                  {documentNode.DNDNAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_parent_node_id_label`}>
                  {translate('general.parent_node_id')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_parent_node_id_value`}>
                  {documentNode.DNDPNAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_owner_label`}>
                  {translate('general.owner')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_owner_value`}>
                  {documentNode.OWNER}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_identifier_label`}>
                  {translate('definition.index_identifier')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_identifier_value`}>
                  {documentNode.DNDENAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_type_label`}>
                  {translate('general.type')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_type_value`}>
                  {documentNode.DNDTYPE === 'DND' ? translate('general.node') : translate('group.group')}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        }
        {
          dialogs.showTableSettings &&
          <TableSettings
            id={id}
            onClose={() => this.closeDialog('showTableSettings')}
            headers={this.state.header}
            prefs={{ headers: tableHeader, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_DOCUMENT_NODE }}
          />

        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {
            displayAs === 0
              ? documentNodes
                ? tableData
                  ? <DataTable
                    loading={loading}
                    id={id}
                    header={translatedHeaders}
                    data={tableData}
                    cleanData={tableData}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={rowIndex => this.openDialog('showModify', rowIndex)}
                    columnSortDefs={this.getColumnSortDefs(tableData, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtonsTable(tableData, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    langugae={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                  : <EmptyResult
                    description={translate('emptyresult.no_document_node_result')}
                    id={`${id}_emptyresult`}
                    link={translate('emptyresult.create_document_node_link')}
                    onClick={() => this.openPrefilledCreateDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                : <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              : documentNodesHierarchy
                ? documentNodesHierarchy.data
                  ? <DataHierarchy
                    id={id}
                    selectable
                    header={hierarchyHeader.map(h => translate(h, undefined, undefined, false) ? translate(h) : h)}
                    data={hierarchyData}
                    hierarchyEntries={hierarchyEntriesData}
                    onOpenChilds={(parentKey, rootKey, hash, level, data) => this.handleHierarchyClick(parentKey, rootKey, hash, level, data)}
                    onCloseChilds={newHierarchyEntries => updateHierarchyEntriesData(newHierarchyEntries)}
                    columnSortDefs={['string', 'string', 'string', 'string', 'date-time']}
                    isHierarchyOpen={hierarchyEntriesData ? hierarchyEntriesData.length > 0 : false}
                    closeHierarchy={() => updateHierarchyEntriesData([])}
                    additionalInteraction={() => this.createInteractionButtonsHierarchy(hierarchyData, hierarchyHeader)}
                    createActionButtons={this.createActionButtonsHierarchy}
                    actionButtonsLength={3}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                  />
                  : <NoSearch
                    id={`${id}_nosearch`}
                    message={translate('general.no_data_found')}
                  />
                : <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
          }
        </ResultContainer>
      </>
    )
  }
}

const NODE_ID = 'DNDNAME'
const PARENT_NODE_ID = 'DNDPNAME'
const OWNER = 'OWNER'
const IDENTIFIER = 'DNDENAME'
const TYPE = 'DNDTYPE'
const CUSER = 'CUSER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const LASTCHANGED = 'LASTCHANGED'

const mapStateToProps = state => {
  return {
    documentNodes: state.definitions.documentNodes.documentNodes,
    documentNode: state.definitions.documentNodes.documentNode,
    documentNodesHierarchy: state.hierarchy.documentNodes.hierarchyData,
    hierarchyEntriesData: state.hierarchy.documentNodes.hierarchyEntriesData,
    keepPagination: state.definitions.documentNodes.keepPagination,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getDocumentNodes: (documentNodeObj, callback) => {
      DocumentNodesActions.getDocumentNodes(undefined, documentNodeObj, callback)(dispatch)
    },
    checkDocumentNodeisDeletable: (parentNodeID, callback) => {
      DocumentNodesActions.checkDocumentNodeisDeletable(parentNodeID, callback)(dispatch)
    },
    getDocumentNode: (documentNodeObj, callback) => {
      DocumentNodesActions.getDocumentNode(documentNodeObj, callback)(dispatch)
    },
    deleteDocumentNode: (documentNodeObj, callback) => {
      DocumentNodesActions.deleteDocumentNode(documentNodeObj, callback)(dispatch)
    },
    updateHierarchyEntriesData: newHierarchyEntriesData => {
      DocumentNodesHierarchyActions.updateHierarchyEntriesData(newHierarchyEntriesData)(dispatch)
    },
    getHierarchyEntry: (documentNodeObj, parentKey, rootKey, hash, level, callback) => {
      DocumentNodesHierarchyActions.getHierarchyEntry(documentNodeObj, parentKey, rootKey, hash, level, callback)(dispatch)
    },
    getDocumentNodesHierarchy: (documentNodeObj, callback, ignoreSnackbar) => {
      DocumentNodesHierarchyActions.getDocumentNodesHierarchy(documentNodeObj, callback, ignoreSnackbar)(dispatch)
    },
    refreshData: () => DocumentNodesActions.refreshData()(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultDocumentNode))