import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DefinitionJobGroups from './definition_job_groups/DefinitionJobGroups.jsx'
import DefinitionRecipient from './definition_recipient/DefinitionRecipient'
import DefinitionDocument from './definition_document/DefinitionDocument'
import DefinitionDocumentNode from './definition_document_node/DefinitionDocumentNode'
import DefinitionExternalCmd from './definition_external_cmd/DefinitionExternalCmd'
import DefinitionFolder from './definition_folder/DefinitionFolder'
import DefinitionIndex from './definition_index/DefinitionIndex'
import DefinitionLPDQueue from './definition_lpd_queue/DefinitionLPDQueue'
import DefinitionOutputChannel from './definition_output_channel/DefinitionOutputChannel'
import DefinitionPostprocessingNote from './definition_postprocssing_note/DefinitionPostprocessingNote'
import DefinitionOutputFormat from './definition_output_format/DefinitionOutputFormat'
import DefinitionSearchArgument from './definition_search_argument/DefinitionSearchArgument'
import DefinitionFilterArgument from './definition_filter_argument/DefinitionFilterArgument'
import DefinitionArchivePool from './definition_archive_pool/DefinitionArchivePool'
import DefinitionResultTable from './definition_result_table/DefinitionResultTable'
import DefinitionCustomDialog from './definition_custom_dialog/DefinitionCustomDialog'
import DefinitionViewProfile from './definition_view_profile/DefinitionViewProfile'
import DefinitionLog from './definition_log/DefinitionLog'

class DefinitionBody extends Component {

  getBody() {
    const { id, definitionMode, history } = this.props
    switch (definitionMode) {
      // standard import
      case 0: return
      case 'jobgroups': return <DefinitionJobGroups id={id} history={history} />
      case 'recipient': return <DefinitionRecipient id={id} history={history} />
      case 'externalcmd': return <DefinitionExternalCmd id={id} />
      case 'lpdqueue': return <DefinitionLPDQueue id={id} />
      case 'folder': return <DefinitionFolder id={id} />
      case 'index': return <DefinitionIndex id={id} />
      case 'document': return <DefinitionDocument id={id} />
      case 'log': return <DefinitionLog id={id} />
      case 'documentnode': return <DefinitionDocumentNode id={`${id}_document_node`} />
      case 'outputchannel': return <DefinitionOutputChannel id={id} />
      case 'postprocessingnote': return <DefinitionPostprocessingNote id={id}/>
      case 'outputformat': return <DefinitionOutputFormat id={id} />
      case 'searchargument': return <DefinitionSearchArgument id={id} />
      case 'filterargument': return <DefinitionFilterArgument id={id} />
      case 'archivepool': return <DefinitionArchivePool id={id} />
      case 'resulttable': return <DefinitionResultTable id={id} />
      case 'customdialog': return <DefinitionCustomDialog id={id} />
      case 'viewprofile': return <DefinitionViewProfile id={id} />
      // should not happen
      default: return <DefinitionRecipient id={id}/>
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

DefinitionBody.propTypes = {
  id: PropTypes.string.isRequired,
  definitionMode: PropTypes.string,
}

export default DefinitionBody