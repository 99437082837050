import React, {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import Icon from '../icon/Icon';
import './Ruler.scss';

export enum RulerPosition {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

interface RulerProps {
  id: string;
  /** Sets position of the ruler */
  position: RulerPosition;
  /** Set max number on the ruler */
  max?: number;
  /** Callback when griped selection changed */
  onHeldColumnsRowsChanged?: (columnNumber: number) => void;
  /** A class name which can be passed to the component */
  className?: string;
  /** Hides grip and his function */
  noGrip?: boolean;
}

const SMALL_DOT = '·';
const BIG_DOT = '•';
const PADDING_SIZE = 18;
const ICON_SIZE = 24;

export const Ruler = ({...props}: RulerProps): JSX.Element => {
  const [gripPosition, setGripPosition] = useState(0);
  const [MAX, setMAX] = useState(props.max || 0);
  const gripOn = !props.noGrip;

  const rulerRef = useRef<HTMLDivElement>(null);

  const getCharSize = useCallback((): number => {
    const char = document.querySelector(`#${props.id}_char_tester`);
    return (props.position === RulerPosition.Horizontal ? char?.getBoundingClientRect().width : char?.getBoundingClientRect().height) || 0;
  }, [props.id, props.position]);

  const getMaxColumns = useCallback((): number => {
    const container = document.querySelector(`#${props.id}_ruler`);
    const containerWidth = (props.position === RulerPosition.Horizontal ? container?.getBoundingClientRect().width : container?.getBoundingClientRect().height) || 0;
    const charSize = getCharSize();
    return Math.floor((containerWidth - 24) / charSize);
  }, [props.id, props.position, getCharSize]);

  useEffect(() => {
    if (!props.max) return;
    setMAX(props.max);
  }, [props.max]);

  useEffect(() => {
    const rulerRefElement = rulerRef.current;
    if (!rulerRefElement || props.max) return;

    // TODO: avoid re-renders!
    const observer = new ResizeObserver(() => {
      setMAX(getMaxColumns());
    });
    observer.observe(rulerRefElement);

    return () => {
      observer.unobserve(rulerRefElement);
    };
  }, [rulerRef, getMaxColumns, props.max]);

  const calculateColumnToHeld = (e: MouseEvent): number => {
    const charSize = getCharSize();
    const container = document.querySelector(`#${props.id}_ruler`);
    const containerBegin = ((container?.getBoundingClientRect().left) || 0) + PADDING_SIZE;
    let column = Math.round((e.clientX - containerBegin) / charSize);

    if (column < 0) column = 0;

    return column;
  };

  const calculateRowToHeld = (e: MouseEvent): number => {
    const charSize = getCharSize();
    const container = document.querySelector(`#${props.id}_ruler`);
    const containerBegin = ((container?.getBoundingClientRect().y) || 0) + PADDING_SIZE;
    let row = Math.round((e.clientY - containerBegin) / charSize);

    if (row < 0) row = 0;

    return row;
  };

  const getTensFromNumber = (number: number): number => {
    return Math.floor(number / 10) % 10;
  };

  const generateHorizontalRuler = (): ReactNode => {
    const arrayOfCharacters = [];

    for (let i = 1; i <= MAX; i++) {
      if (i % 10 === 0) arrayOfCharacters.push(getTensFromNumber(i));
      else if (i % 5 === 0) arrayOfCharacters.push(BIG_DOT);
      else arrayOfCharacters.push(SMALL_DOT);
    }
    return arrayOfCharacters.map((char, index) => <span key={index}>{char}</span>);
  };

  const generateVerticalRuler = (): ReactNode => {
    const arrayOfCharacters = [];
    for (let i = 1; i <= MAX; i++) {
      if (i % 10 === 0 || i % 5 === 0) {
        arrayOfCharacters.push(i);
      } else {
        arrayOfCharacters.push(SMALL_DOT);
      }
    }
    return arrayOfCharacters.map((char, index) => <span key={index}>{char}</span>);
  };

  const onMouseMove = (e: MouseEvent): void => {
    setGripPosition(props.position === RulerPosition.Horizontal ? calculateColumnToHeld(e) : calculateRowToHeld(e));
  };

  const onMouseDown = (): void => {
    document.onmousemove = onMouseMove;
    document.onmouseup = (e: MouseEvent): void => {
      const toHold = props.position === RulerPosition.Horizontal ? calculateColumnToHeld(e) : calculateRowToHeld(e);
      props.onHeldColumnsRowsChanged?.(toHold);
      document.onmousemove = document.onmouseup = null;
    };
  };

  const calculateGripPosition = (): { left?: number; top?: number } => {
    const ruler = document.querySelector(`#${props.id}_ruler`);
    const rulerBegin = (props.position === RulerPosition.Horizontal ? ruler?.getBoundingClientRect().left : ruler?.getBoundingClientRect().top) || 0;
    let position = {};
    if (gripPosition !== 0) {
      const gripedSpan = ruler?.children[gripPosition + 1];
      if (gripedSpan) {
        if (props.position === RulerPosition.Horizontal) {
          position = {left: gripedSpan?.getBoundingClientRect().right - rulerBegin - ICON_SIZE / 2};
        } else {
          position = {top: gripedSpan?.getBoundingClientRect().bottom - rulerBegin - ICON_SIZE / 2};
        }

      }
    } else {
      if (props.position === RulerPosition.Horizontal) {
        position = {left: 0};
      } else {
        position = {top: 0};
      }
    }
    return position;
  };

  const calculateGripBackground = (): { right?: number; bottom?: number } => {
    let position = {};
    if (gripPosition === 0 || gripPosition === -1) {
      position = {inset: 'unset'};
      return position;
    }

    const ruler = document.querySelector(`#${props.id}_ruler`);
    const rulerBegin = (props.position === RulerPosition.Horizontal ? ruler?.getBoundingClientRect().left : ruler?.getBoundingClientRect().top) || 0;
    const gripedSpan = ruler?.children[gripPosition + 1];
    const gripedSpanRight = gripedSpan?.getBoundingClientRect().right || 0;
    const gripedSpanBottom = gripedSpan?.getBoundingClientRect().bottom || 0;

    if (props.position === RulerPosition.Horizontal) {
      position = {top: 0, width: gripedSpanRight - rulerBegin - PADDING_SIZE, bottom: 0, left: PADDING_SIZE};
    } else {
      position = {top: PADDING_SIZE, right: 0, height: gripedSpanBottom - rulerBegin - PADDING_SIZE, left: 0};
    }
    return position;
  };

  return (
    <div id={props.id} className={`cl_ruler__container_${props.position} ${props.className}`}>
      <div
        id={`${props.id}_ruler`}
        ref={rulerRef}
        className={`cl_ruler__${props.position} ${props.max ? '' : 'cl_ruler__maxSpace'}`}
      >
        <div className={'cl_ruler__grip_background'} style={calculateGripBackground()}/>
        <span id={`${props.id}_char_tester`}
              style={{visibility: 'hidden', position: 'absolute', top: '-1200px'}}>.</span>
        {props.position === RulerPosition.Horizontal && generateHorizontalRuler()}
        {props.position === RulerPosition.Vertical && generateVerticalRuler()}

      </div>
      {gripOn &&
        <div onMouseDown={onMouseDown} className={'cl_ruler__grip'} style={calculateGripPosition()}>
          <Icon
            id={`${props.id}_icon`}
            name={`grip_${props.position}`}
          />
        </div>
      }
    </div>
  );
};
