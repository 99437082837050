import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Drawer } from 'BetaUX2Web-Components/src';

import {
  CLOSE_DRAWER_ANIMATION_TIME_MS,
  OPEN_DRAWER_ANIMATION_TIME_MS
} from '../../BetaUX2Web-Components/src/components/drawer/Drawer';

import '../../BetaUX2Web-Components/src/components/drawer/Drawer.scss';
import './EditableWidthDrawer.scss';

interface EditableWidthDrawerTypes {
  id: string;
  contentLeft: JSX.Element;
  contentRight: JSX.Element;
  navbarButtons: JSX.Element | JSX.Element[];
  disabled?: boolean;
}

const SEPARATOR_WIDTH_PX_NUM = 8;
const DRAWER_WIDTH = '32rem';
const DRAWER_MIN_WIDTH = 32;

const setDrawerWidthCSSVariable = (value: string): void => {
  document.documentElement.style.setProperty('--drawer-width', value);
};

type ExpandContentClassNameSuffixType = 'open' | 'close' | 'open_animation' | 'close_animation';
type DrawerStateType = boolean | 'initialized'

export const EditableWidthDrawer = (props: EditableWidthDrawerTypes): JSX.Element => {
  const { id, contentLeft, contentRight, navbarButtons } = props;
  const [expandDrawer, setExpandDrawer] = useState<DrawerStateType>('initialized');
  const [expandContentClassNameSuffix, setExpandContentClassNameSuffix] = useState<ExpandContentClassNameSuffixType>('open');
  const separatorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDrawerWidthCSSVariable(DRAWER_WIDTH);
  }, []);

  useEffect(() => {
    const refCurrent = separatorRef.current;
    if (!refCurrent || props.disabled) return;

    let documentOnMouseMove: ((ev: MouseEvent) => void) | null = null;

    const createDocumentOnMouseMove: (offset: number) => (ev: MouseEvent) => void = (offset: number) => {
      return (ev: MouseEvent): void => {
        if (!refCurrent) return;
        let newWidth = ev.x + offset;
        if (newWidth < DRAWER_MIN_WIDTH * 16) {
          newWidth = DRAWER_MIN_WIDTH * 16;
        }
        setDrawerWidthCSSVariable(`${newWidth + 'px'}`);
      };
    };

    const documentOnMouseUp = (): void => {
      if (documentOnMouseMove) {
        document.removeEventListener('mousemove', documentOnMouseMove);
      }
    };

    const separatorOnMouseDown = (ev: MouseEvent): void => {
      if (!refCurrent) return;

      const separatorPositionX = refCurrent.getBoundingClientRect().x;
      const separatorClickEventPositionX = ev.clientX - separatorPositionX;
      documentOnMouseMove = createDocumentOnMouseMove(SEPARATOR_WIDTH_PX_NUM - separatorClickEventPositionX);
      document.addEventListener('mousemove', documentOnMouseMove);
      document.addEventListener('mouseup', documentOnMouseUp);
    };

    refCurrent.addEventListener('mousedown', separatorOnMouseDown);

    return () => {
      if (!refCurrent) return;

      refCurrent.removeEventListener('mousedown', separatorOnMouseDown);
      document.removeEventListener('mouseup', documentOnMouseUp);
    };
  }, [separatorRef, props.disabled]);

  useLayoutEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (expandDrawer === true) {
      setExpandContentClassNameSuffix('open_animation');
      timeoutId = setTimeout(() => setExpandContentClassNameSuffix('open'), OPEN_DRAWER_ANIMATION_TIME_MS);
    } else if (!expandDrawer) {
      setExpandContentClassNameSuffix('close_animation');
      timeoutId = setTimeout(() => setExpandContentClassNameSuffix('close'), CLOSE_DRAWER_ANIMATION_TIME_MS);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [expandDrawer, setExpandContentClassNameSuffix]);

  return (
    <div
      id={id}
      className={'editable_drawer'}
    >
      <div className={'editable_drawer_container'}>
        <div
          ref={separatorRef}
          className={'separator'}
          style={{
            display: !expandDrawer || props.disabled ? 'none' : 'inherit'
          }}
        />
        <Drawer
          id={`${id}_drawer`}
          drawerExpanded={expandDrawer}
          onDrawerVisibilityChanged={setExpandDrawer}
          navbarButtons={navbarButtons}
          content={contentLeft}
        />
      </div>
      <main
        id={`${id}_main`}
        className={`editable_drawer_content editable_drawer_content_${expandContentClassNameSuffix}`}
      >
        {contentRight}
      </main>
    </div>
  );
};