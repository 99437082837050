import * as ModalSelectorActions from '../actions/ModalSelectorActions.js'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  recipientDocumentAssignments: undefined,
  recipients: undefined,
  documents: undefined,
  logs: undefined,
  outputformats: undefined,
  outputchannels: undefined,
  folders: undefined,
  indexes: undefined,
  ppns: undefined,
  filterarguments: undefined,
  resulttables: undefined,
  customdialogs: undefined,
  documentnodes: undefined,
  bqlQueryResult: undefined,
  hierarchyEntriesData: undefined,
  jobgroups: undefined,
  lpdQueues: undefined
}

const NO_CHILDS = 'NO_CHILDS'

const reducer = (state = defaultState, action) => {
  let newArray = []
  switch (action.type) {
    /* ********************************************************************************** */
    // Selector definition get recipients actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_RECIPIENTS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_RECIPIENTS_SUCCESS:
      return { ...state, recipients: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_RECIPIENTS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_RECIPIENTS_FOUND:
      return { ...state, recipients: {} }
    /* ********************************************************************************** */
    // Selector definition get recipient document assignments actions
    case ModalSelectorActions.SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_SUCCESS:
      return { ...state, recipientDocumentAssignments: action.payload.data }
    case ModalSelectorActions.SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_NO_RECIPIENT_DOCUMENT_ASSIGNMENTS_FOUND:
      return { ...state, recipientDocumentAssignments: {} }
    /* ********************************************************************************** */
    // Selector definition get documents actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_DOCUMENTS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_DOCUMENTS_SUCCESS:
      return { ...state, documents: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_DOCUMENTS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_DOCUMENTS_FOUND:
      return { ...state, documents: {} }
    /* ********************************************************************************** */
    // Selector definition get logs actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_LOGS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_LOGS_SUCCESS:
      return { ...state, logs: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_LOGS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_LOGS_FOUND:
      return { ...state, logs: {} }
    /* ********************************************************************************** */
    // Selector definition get outputformats actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_OUTPUTFORMATS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_OUTPUTFORMATS_SUCCESS:
      return { ...state, outputformats: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_OUTPUTFORMATS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_OUTPUTFORMATS_FOUND:
      return { ...state, outputformats: {} }
    /* ********************************************************************************** */
    // Selector definition get outputchannels actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_SUCCESS:
      return { ...state, outputchannels: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_OUTPUTCHANNELS_FOUND:
      return { ...state, outputchannels: {} }
    /* ********************************************************************************** */
    // Selector definition get folders actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_FOLDERS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_FOLDERS_SUCCESS:
      return { ...state, folders: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_FOLDERS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_FOLDERS_FOUND:
      return { ...state, folders: {} }
    /* ********************************************************************************** */
    // Selector definition get indexes actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_INDEXES_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_INDEXES_SUCCESS:
      return { ...state, indexes: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_INDEXES_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_INDEXES_FOUND:
      return { ...state, indexes: {} }
    /* ********************************************************************************** */
    // Selector definition get post processing notes actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_PPNS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_PPNS_SUCCESS:
      return { ...state, ppns: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_PPNS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_PPNS_FOUND:
      return { ...state, ppns: {} }
    /* ********************************************************************************** */
    // Selector definition get filter arguments actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_FILTERARGUMENTS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_FILTERARGUMENTS_SUCCESS:
      return { ...state, filterarguments: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_FILTERARGUMENTS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_FILTERARGUMENTS_FOUND:
      return { ...state, filterarguments: {} }
    /* ********************************************************************************** */
    // Selector definition get result table actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_RESULT_TABLES_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_RESULT_TABLES_SUCCESS:
      return { ...state, resulttables: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_RESULT_TABLES_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_RESULT_TABLES_FOUND:
      return { ...state, resulttables: {} }
    /* ********************************************************************************** */
    // Selector definition get custom dialog actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_SUCCESS:
      return { ...state, customdialogs: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_CUSTOM_DIALOGS_FOUND:
      return { ...state, customdialogs: {} }
    /* ********************************************************************************** */
    // Selector definition get document node actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_DOCUMENTNODES_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_DOCUMENTNODES_SUCCESS:
      return { ...state, documentnodes: action.payload.data }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_DOCUMENTNODES_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_DOCUMENTNODES_FOUND:
      return { ...state, documentnodes: {} }
    /* ********************************************************************************** */
    case ModalSelectorActions.GET_DATA_FROM_BQL_QUERY_START:
      return { ...state, bqlQueryResult: undefined }
    case ModalSelectorActions.GET_DATA_FROM_BQL_QUERY_SUCCESS:
      return { ...state, bqlQueryResult: action.payload }
    case ModalSelectorActions.GET_DATA_FROM_BQL_QUERY_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Selector definition get jobgroups actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_JOBGROUPS_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_JOBGROUPS_SUCCESS:
      return { ...state, jobgroups: action.payload }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_JOBGROUPS_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_JOBGROUPS_FOUND:
      return { ...state, jobgroups: {} }
    /* ********************************************************************************** */
    // Selector definition get lpd queues  actions
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_LPD_QUEUES_START:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_LPD_QUEUES_SUCCESS:
      return { ...state, lpdQueues: action.payload }
    case ModalSelectorActions.SELECTOR_DEFINITION_GET_LPD_QUEUES_FAILED:
      return { ...state }
    case ModalSelectorActions.SELECTOR_DEFINITION_NO_LPD_QUEUES_FOUND:
      return { ...state, lpdQueues: {} }
    /* ********************************************************************************** */
    // get hierarchy recipients actions
    case ModalSelectorActions.MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_START:
      return { ...state }
    case ModalSelectorActions.MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_SUCCESS:
      // creates an array which stores each data entry with a unique key
      const dataArr = action.payload.data.map(d => {
        return { data: d, key: action.payload.hash(d) }
      })
      // extracts the keys
      const keys = dataArr.map(d => d.key)
      // extracts the data
      const data = dataArr.map(d => d.data)
      newArray = state.hierarchyEntriesData !== undefined && state.hierarchyEntriesData.length > 0
        ? [
          ...state.hierarchyEntriesData,
          { parentKey: action.payload.parentKey, rootKey: action.payload.rootKey, key: keys, level: action.payload.level, data: data }
        ]
        : [{ parentKey: action.payload.parentKey, rootKey: action.payload.rootKey, key: keys, level: action.payload.level, data: data }]
      return {
        ...state,
        hierarchyEntriesData: newArray
      }
    case ModalSelectorActions.MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_FAILED:
      return { ...state }
    case ModalSelectorActions.MODALSELECTOR_NO_HIERARCHY_ENTRY_FOUND:
      newArray = state.hierarchyEntriesData !== undefined && state.hierarchyEntriesData.length > 0
        ? [
          ...state.hierarchyEntriesData,
          { parentKey: action.payload.parentKey, rootKey: action.payload.rootKey, key: [action.payload.hash(NO_CHILDS)], level: action.payload.level, data: NO_CHILDS }
        ]
        : [{ parentKey: action.payload.parentKey, rootKey: action.payload.rootKey, key: [action.payload.hash(NO_CHILDS)], level: action.payload.level, data: NO_CHILDS }]
      return {
        ...state,
        hierarchyEntriesData: newArray
      }
    /* ********************************************************************************** */
    // update recipients hierarchy entries actions
    case ModalSelectorActions.MODALSELECTOR_HIERARCHY_ENTRIES_UPDATED:
      return { ...state, hierarchyEntriesData: action.payload }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      return defaultState
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer