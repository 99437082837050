import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'
import AssignmentBody from './assignment_body/AssignmentBody'

import { translate } from 'language/Language'

import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class Assignment extends Component {
  handleChangeAssignment(activeIndex) {
    const link = this.getLink(activeIndex)

    if (link !== undefined)
      this.props.history.push(link)
  }

  getItems() {
    const items = []

    this.props.items.map((item) =>
      items.push(item.title)
    )

    return items
  }

  getLink(activeIndex) {
    // get the object with the current active index and return the link
    return this.props.items.find((item, currentIndex) => currentIndex === activeIndex).link
  }

  getActiveIndex(assignment) {
    // get the index of the match with the link of the item and the assignment
    let index = this.props.items.findIndex((item) => item.link.substring(item.link.lastIndexOf('/') + 1) === assignment)

    // select first index if there is no item selected
    if (index === -1)
      index = 0

    return index
  }

  handleOnSearch() {
    this.props.showLoadingSpinner()
  }

  render = () => {
    const { id, match } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    return (
      <div id={id} className='drawer_field'>
        <div id={`${id}_assignment_container1`} className='drawer_header_big bux_drawer_header'>
          <div id={`${id}_assignment_container2`} className='row search_row'>
            <div id={`${id}_assignment_container3`} className='dropdown el_dropdown'>
              <Dropdown
                id={`${id}_assignment`}
                title={translate('general.assignment')}
                items={this.getItems()}
                activeIndex={this.getActiveIndex(url)}
                onChange={(activeIndex) => { this.handleChangeAssignment(activeIndex) }}
              />
            </div>
          </div>
        </div>

        <AssignmentBody id={`${id}_body`} assignmentMode={url} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    loadingspinner: state.loadingspinner
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoadingSpinner: () => { LoadingSpinnerActions.show()(dispatch) },
    hideLoadingSpinner: () => { LoadingSpinnerActions.hide()(dispatch) },
  }
}

Assignment.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Assignment))