import React, { Component } from 'react'
import moment from 'moment'

export default class DateTimePickerDays extends Component {
	/**
	 * Get a list of the days of the week
	 * depending on the current locale
	 * @return {array} A list with the shortname of the days
	 */
	getDaysOfWeek = locale => {
		let days = locale._weekdaysMin
		let	first = locale.firstDayOfWeek()
		let	dow = []
		let	i = 0

		days.forEach( function( day ) {
			dow[ (7 + ( i++ ) - first) % 7 ] = day
		})

		return dow
	}

	renderDays = () => {
		let  date = this.props.viewDate
		let	selected = this.props.selectedDate && this.props.selectedDate.clone()
		let	prevMonth = date.clone().subtract(1, 'months')
		let	currentYear = date.year()
		let	currentMonth = date.month()
		let	weeks = []
		let	days = []
		let	renderer = this.props.renderDay || this.renderDay
		let	isValid = this.props.isValidDate || this.alwaysValidDate
		let	classes
		let isDisabled
		let dayProps
		let currentDate

		// Go to the last week of the previous month
		prevMonth.date( prevMonth.daysInMonth() ).startOf('week')
		var lastDay = prevMonth.clone().add(42, 'd')

		while (prevMonth.isBefore(lastDay)) {
			classes = 'rdtDay'
			currentDate = prevMonth.clone()

			if ((prevMonth.year() === currentYear && prevMonth.month() < currentMonth ) || (prevMonth.year() < currentYear))
				classes += ' rdtOld'
			else if ((prevMonth.year() === currentYear && prevMonth.month() > currentMonth) || (prevMonth.year() > currentYear))
				classes += ' rdtNew'

			if (selected && prevMonth.isSame(selected, 'day'))
				classes += ' rdtActive'

			if (prevMonth.isSame(moment(), 'day'))
				classes += ' rdtToday'

			isDisabled = !isValid(currentDate, selected)
			if (isDisabled) {
				classes += ' rdtDisabled'
			}

			dayProps = {
				key: prevMonth.format( 'M_D' ),
				'data-value': prevMonth.date(),
				className: classes
			}

			if ( !isDisabled )
				dayProps.onMouseDown = this.updateSelectedDate;

			days.push( renderer( dayProps, currentDate, selected ) )

			if ( days.length === 7 ) {
				weeks.push( React.createElement('tr', { key: prevMonth.format( 'M_D' )}, days ) )
				days = []
			}

			prevMonth.add( 1, 'd' )
		}

		return weeks
	}

	updateSelectedDate = event => {
		this.props.updateSelectedDate( event, true )
	}

	renderDay = (props, currentDate) => {
		return React.createElement('td',  props, currentDate.date())
	}

	renderFooter = () => {
		if (!this.props.timeFormat) {
			return ''
		}

		var date = this.props.selectedDate || this.props.viewDate

		return React.createElement('tfoot', { key: 'tf'},
			React.createElement('tr', {},
				React.createElement('td', { onClick: this.props.showView( 'time' ), colSpan: 7, className: 'rdtTimeToggle' }, date.format( this.props.timeFormat ))
			)
		)
	}

	alwaysValidDate = () => {
		return 1
	}

	render = () => {
		let footer = this.renderFooter()
		let date = this.props.viewDate
		let locale = date.localeData()
		let tableChildren

		tableChildren = [
			React.createElement('thead', { key: 'th' }, [
				React.createElement('tr', { key: 'h' }, [
					React.createElement('th', { key: 'prev', className: 'rdtPrev', onClick: this.props.subtractTime( 1, 'months' ) }, React.createElement('span', { className: 'beta_icon beta_chevron_left' })),
					React.createElement('th', { key: 'month', className: 'rdtSwitch', onClick: this.props.showView( 'months' ), colSpan: 5, 'data-value': this.props.viewDate.month() }, locale.months( date ) + ' ' + date.year() ),
					React.createElement('th', { key: 'next', className: 'rdtNext', onClick: this.props.addTime( 1, 'months' )}, React.createElement('span', { className: 'beta_icon beta_chevron_right' }))
				]),
				React.createElement('tr', { key: 'd'}, this.getDaysOfWeek( locale ).map( function( day, index ) { return React.createElement('th', { key: day + index, className: 'dow'}, day )}) )
			]),
			React.createElement('tbody', { key: 'tb' }, this.renderDays())
		]

		if ( footer )
			tableChildren.push( footer );

		return React.createElement('div', { className: 'rdtDays' },
			React.createElement('table', {}, tableChildren )
		)
	}
}
