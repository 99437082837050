import * as TYPES_DOC_VIEWER from '../../types/DocumentViewer.types';

export const DocumentBrowserTextSegment = ({ indexToHighlight, line, lineIndex, pageNumber }: { indexToHighlight: TYPES_DOC_VIEWER.TIndexToHighlight, line: string, lineIndex: number, pageNumber: number }) => {
  const shouldRenderHighlightedLine = 'position' in indexToHighlight && pageNumber === indexToHighlight.position.page && lineIndex === indexToHighlight.position.row - 1

  if (!shouldRenderHighlightedLine) {
    // Note: Lines which include only white-spaces will be replace with empty node to enable selection tooltip and conform to vertical ruler measurements
    return line.replace(/\s/g, '').length === 0 ? (<span className='doc_browser_area_interaction_content_line_empty'></span>) : (<>{line}</>)
  }

  return (
    <>
      <div>
        {line.slice(0, indexToHighlight.position.column - 1)}
      </div>
      <div className={'doc_browser_line_highlight'}>
        {line.slice(indexToHighlight.position.column - 1, indexToHighlight.position.column - 1 + indexToHighlight.maxLength)}
      </div>
      <div>
        {line.slice(indexToHighlight.position.column - 1 + indexToHighlight.maxLength)}
      </div>
    </>
  )
};