import * as UploadActions from '../actions/UploadActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  additionalInfo: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Upload actions
    case UploadActions.UPLOAD_START:
      return { ...state, additionalInfo: undefined }
    case UploadActions.UPLOAD_SUCCESS:
      return { ...state, additionalInfo: action.payload.additionalInfo }
    case UploadActions.UPLOAD_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, additionalInfo: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer