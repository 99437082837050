import * as ExternalCommandDefinitionActions from '../actions/ExternalCommandDefinitionActions.js'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  externalCommands: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get external commands actions
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_START:
      return { ...state }
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_SUCCESS:
      return { ...state, externalCommands: action.payload.data, keepPagination: action.keepPagination }
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_FAILED:
      return { ...state }
    case ExternalCommandDefinitionActions.NO_EXT_COMMAND_DEFINITION_EXT_COMMANDS_FOUND:
      return { ...state, externalCommands: {} }
    /* ********************************************************************************** */
    // Definition create external command actions
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_START:
      return { ...state }
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_SUCCESS:
      return { ...state }
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete external command actions
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_START:
      return { ...state }
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_SUCCESS:
      return { ...state }
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update external command actions
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_START:
      return { ...state }
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_SUCCESS:
      return { ...state }
    case ExternalCommandDefinitionActions.EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, externalCommands: undefined, }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer