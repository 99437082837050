import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'

// components
import {
  Button,
  Column, Dropdown, Input,
  Modal as ModalComponent, NumericSpinner, Row,
  Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'

import * as SearchArgumentActions from 'redux/actions/SearchArgumentDefinitionActions'
import _ from 'lodash'
import { hasNoValues } from 'utils/ObjectUtils'

class CreateSearchArgumentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    prefilledData: PropTypes.object,
    onClose: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props);
    this.defaultState = {
      searchArgumentID: {
        value: '',
        errorkey: ''
      },
      activeTypeIndex: 0,
      form: {
        value: '',
        errorkey: ''
      },
      extension: {
        value: '',
        errorkey: ''
      },
      report: {
        value: '',
        errorkey: ''
      },
      owner: {
        value: '',
        errorkey: ''
      },
      title: {
        value: '',
        errorkey: ''
      },
      onPage: 1,
      fromColumn: 0,
      toColumn: 0,
      fromRow: 0,
      toRow: 0
    }
    this.state = _.cloneDeep(this.defaultState)
  }

  resetState = () => {
    this.setState({...this.defaultState})
  }


  searchArgumentIdInput = React.createRef()
  searchArgumentReportInput = React.createRef()

  /**
   * @description Sets the initial focus.
   */
  componentDidMount() {
    this.searchArgumentIdInput.current.focus()
    if (this.props.prefilledData) {
      this.setState({
        searchArgumentID: {
          value: this.props.prefilledData.SASID,
          errorkey: ''
        },
        form: {
          value: this.props.prefilledData.FORM,
          errorkey: ''
        },
        extension: {
          value: this.props.prefilledData.EXTENSION,
          errorkey: ''
        },
        report: {
          value: this.props.prefilledData.REPORT,
          errorkey: ''
        },
      })
    }
  }

  /**
   * @description Handles the input changes of the input fields.
   * @param {String} tab The tab which is changed.
   * @param {String} id The id the input field.
   * @param {String} value The new value.
   * @param {String} errorkey The new errorkey.
   */
  handleInputChanged = (tab, id, value, errorkey) => {
    if (tab === 'generalTab') {
      this.setState({
        [id]: {
          value: value,
          errorkey: errorkey,
        }
      })
    } else if (tab === 'generalOptionsTab') {
      this.setState({
        [id]: value
      })
    }
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} tab The tab which is changed.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (tab, key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInputChanged(tab, key, value, error)
  }

  /**
   * @description Validates the argument id.
   * @returns {Object} If validation failed new object with error message for state otherwise empty object.
   */
  validateID = () => {
    if (this.state.searchArgumentID.value === '') {
      return {
        searchArgumentID: {
          ...this.state.searchArgumentID,
          errorkey: 'general.input_required'
        }
      }
    }
    return {}
  }

  /**
   * @description Validates the report.
   * @returns {Object} If validation failed new object with error message for state otherwise empty object.
   */
  validateReport = () => {
    if (this.state.report.value !== '') {
      return {}
    }
    return {
      report: {
        ...this.state.report,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Gets the translated type items.
   * @returns {Array} The translated type items.
   */
  getTranslatedItems = () => {
    const items = []
    DefinitionUtils.SEARCH_ARGUMENT_TYPES.forEach(type => {
      items.push(translate(type.translationKey))
    })
    return items
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { id } = this.props
    const { searchArgumentID, activeTypeIndex, form, extension, report, owner, title, onPage, fromColumn, toColumn, fromRow, toRow } = this.state
    return (
      <>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_search_argument_id`}
              value={searchArgumentID.value}
              title={translate('definition.searchargument_id')}
              ref={this.searchArgumentIdInput}
              required={`${translate('general.required_field')}`}
              maxLength={16}
              onInputChanged={(value, errorkey) => this.handleChangeWithoutSpaces('generalTab', 'searchArgumentID', value, errorkey)}
              error={searchArgumentID.errorkey && translate(searchArgumentID.errorkey)}
              onBlur={() => this.setState({ ...this.validateID() })}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Dropdown
              id={`${id}_search_argument_type`}
              title={translate('general.type')}
              items={this.getTranslatedItems()}
              activeIndex={activeTypeIndex}
              onChange={index => this.setState({ activeTypeIndex: index })}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_search_argument_form`}
              value={form.value}
              title={translate('general.form')}
              maxLength={8}
              onInputChanged={(value, errorkey) => this.handleChangeWithoutSpaces('generalTab', 'form', value, errorkey)}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_search_argument_extension`}
              value={extension.value}
              title={translate('general.extension')}
              maxLength={16}
              onInputChanged={(value, errorkey) => this.handleChangeWithoutSpaces('generalTab', 'extension', value, errorkey)}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_search_argument_report`}
              value={report.value}
              title={translate('general.report')}
              ref={this.searchArgumentReportInput}
              required={`${translate('general.required_field')}`}
              maxLength={16}
              onInputChanged={(value, errorkey) => this.handleChangeWithoutSpaces('generalTab', 'report', value, errorkey)}
              error={report.errorkey && translate(report.errorkey)}
              onBlur={() => this.setState({ ...this.validateReport() })}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_search_argument_owner`}
              value={owner.value}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={(value, errorkey) => this.handleInputChanged('generalTab', 'owner', value, errorkey)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_search_argument_title`}
              value={title.value}
              title={translate('general.title')}
              maxLength={68}
              onInputChanged={(value, errorkey) => this.handleInputChanged('generalTab', 'title', value, errorkey)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_options`}>{translate('general.options')}</label>
          </Column>
        </Row>
        {activeTypeIndex === 0
          ? <Row>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_search_argument_fromcolumn`}
                title={translate('general.from_column')}
                onChange={val => this.handleInputChanged('generalOptionsTab', 'fromColumn', val)}
                value={fromColumn}
                steps={1}
                min={0}
                max={32767}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_search_argument_tocolumn`}
                title={translate('general.to_column')}
                onChange={val => this.handleInputChanged('generalOptionsTab', 'toColumn', val)}
                value={toColumn}
                steps={1}
                min={0}
                max={32767}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_search_argument_fromrow`}
                title={translate('general.from_line')}
                onChange={val => this.handleInputChanged('generalOptionsTab', 'fromRow', val)}
                value={fromRow}
                steps={1}
                min={0}
                max={32767}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_search_argument_torow`}
                title={translate('general.to_line')}
                onChange={val => this.handleInputChanged('generalOptionsTab', 'toRow', val)}
                value={toRow}
                steps={1}
                min={0}
                max={32767}
              />
            </Column>
          </Row>
          : <Row>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_search_argument_onpage`}
                title={translate('definition.search_argument_on_page')}
                onChange={val => this.handleInputChanged('generalOptionsTab', 'onPage', val)}
                value={onPage}
                steps={1}
                min={1}
                max={9999999}
              />
            </Column>
          </Row>
        }
      </>
    )
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateID(),
      ...this.validateReport()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input of the general tab which has an error.
   */
  handleFocusGeneralTab = () => {
    const requiredInputs = [
      { inputRef: this.searchArgumentIdInput, error: this.state.searchArgumentID.errorkey },
      { inputRef: this.searchArgumentReportInput, error: this.state.report.errorkey },
    ]
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].error !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  /**
   * @description Handles the save button
   */
  handleSave = () => {
    const { createSearchArgument, onClose } = this.props
    const {
      searchArgumentID, title, owner, form, extension, report, activeTypeIndex,
      onPage, fromColumn, toColumn, fromRow, toRow
    } = this.state

    const errorTabs = [
      this.validateGeneralTab(),
    ]

    if (errorTabs.every(d => d)) {
      let type = activeTypeIndex === 0 ? 'VALUE' : 'PAGE'
      const searchArgument = {
        'SASID': searchArgumentID.value,
        'SASTITLE': title.value,
        'OWNER': owner.value,
        'FORM': form.value,
        'EXTENSION': extension.value,
        'REPORT': report.value,
        'SASTYPE': type,
        'SASFCOL': type === 'VALUE' ? fromColumn : undefined,
        'SASTCOL': type === 'VALUE' ? toColumn : undefined,
        'SASFROW': type === 'VALUE' ? fromRow : undefined,
        'SASTROW': type === 'VALUE' ? toRow : undefined,
        'SASPNUM': type === 'PAGE' ? onPage : undefined
      }
      createSearchArgument(searchArgument, onClose)
    }
  }

  /**
   * @description Gets the tab indices as array which has errors.
   * @returns {Array} The tab indices which has errors.
   */
  handleErrorTabs = () => {
    const { searchArgumentID, report } = this.state
    let buffer = []
    // possible general tab errors
    const isIdError = searchArgumentID.errorkey !== ''
    const isReportError = report.errorkey !== ''

    if (isIdError || isReportError) {
      buffer.push(0)
    }

    return buffer
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <>
        <Modal
          id='create_search_argument_dialog'
          className='bux_UserProfileModal'
          onClose={onClose}>
          <Header
            id={`${id}_modalHeader`}
            title={translate('definition.create_search_argument_title')}
            onClose={onClose}
          />
          <Main id={id}>
            <Tabs
              id={id}
              disabledTabs={[1]}
              errorTabs={this.handleErrorTabs()}>
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab
                tooltip={translate('definition.search_argument_values_disabled_info')}
                title={translate('general.values')}
              />
            </Tabs>
          </Main>
          <Footer>
            {this.props.prefilledData && !hasNoValues(this.props.prefilledData) &&
              <Button
                id={`${id}_resetbtn`}
                tooltip={translate('general.reset')}
                icon={'undo'}
                onClick={() => this.resetState()}
              />
            }
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {
    createSearchArgument: (searchArgument, callback) => {
      SearchArgumentActions.createSearchArgument(searchArgument, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSearchArgumentDialog)