import { translate } from 'language/Language'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as OutputQueueActions from 'redux/actions/OutputQueueActions'

import {
  Button, Card, Column, Dropdown, MetaDataGrid, Modal as ModalComponent,
  Row, Switch
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import './IPPServerStatus.scss'

const statusActionItems = [
  { rest: 'GPA', translation: 'queues.output_queue_server_attributes' },
  { rest: 'GPAS', translation: 'queues.output_queue_status' },
  { rest: 'GJA', translation: 'queues.output_queue_server_job_information' },
  { rest: 'GJAS', translation: 'queues.output_queue_server_status' },
  { rest: 'VJ', translation: 'queues.output_queue_validate_job' },
]

class IPPServerStatus extends Component {

  state = {
    ippStatusAction: 0,
    verbose: 1,
    requestResult: undefined
  }

  checkStatus = () => {
    const { ippStatusAction, verbose } = this.state
    const { checkIPPStatus, token } = this.props
    const requestObj = {
      IPP: statusActionItems[ippStatusAction].rest,
      VERBOSE: { 0: true, 1: false }[verbose],
      TOKEN: token
    }
    const callback = () => this.setState({ requestResult: this.props.serverStatusCheck })
    checkIPPStatus(requestObj, callback)
  }

  render = () => {
    const { ippStatusAction, verbose, requestResult } = this.state
    const { id, onClose, outputChannel } = this.props
    return (
      <Modal
        id={'ipp_server_status'}
        className={'bux_UserProfileModal'}
        onClose={onClose}>
        <Header
          id={id}
          title={translate('queues.output_queue_ipp_server_status')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('documentprint.outputchannel'), value: outputChannel },
            ]}
            columns={4}
          />
        </Header>
        <Main id={id}>
          <Card className={'bux_full_height'}>
            <Row>
              <Column colMD={3}>
                <Dropdown
                  id={`${id}_ipp_status_action`}
                  items={statusActionItems.map(d => translate(d.translation))}
                  onChange={index => this.setState({ ippStatusAction: index })}
                  activeIndex={ippStatusAction}
                  title={translate('queues.output_queue_ipp_status_action')}
                />
              </Column>
              <Column colMD={3}>
                <Switch
                  id={`${id}_verbose`}
                  title={translate('queues.output_queue_ipp_verbose_output')}
                  items={[translate('general.yes'), translate('general.no')]}
                  onClick={index => this.setState({ verbose: index })}
                  activeIndex={verbose}
                />
              </Column>
              <Column colMD={3} offsetMD={3}>
                <Button
                  id={`${id}_check_status`}
                  title={' '}
                  text={translate('queues.output_queue_ipp_check_status')}
                  onClick={this.checkStatus}
                  containerClass={'bux_flex'}
                  className={'bux_align_right'}
                  primary
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <hr />
              </Column>
            </Row>
            <Row className={'bux_full_height bux_flex'}>
              <Column colMD={12} className={'bux_flex'}>
                <div className={'bux_ipp_server_status_result'}>
                  <span id={`${id}_request_result_title`}>{translate('queues.output_queue_ipp_request_result')}</span>
                  <div id={`${id}_request_result`}>
                    {requestResult ? requestResult.join('\n') : ''}
                  </div>
                </div>
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    serverStatusCheck: state.queue.output.serverStatusCheck
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkIPPStatus: (requestObj, callback) => OutputQueueActions.checkIPPStatus(requestObj, callback)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IPPServerStatus)