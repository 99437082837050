import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import * as ObjectUtils from 'utils/ObjectUtils'
import * as DateUtils from 'utils/DateUtils'
import {restapiRequest} from 'utils/RequestUtils'

export const GET_OUTPUT_QUEUES_START = 'GET_OUTPUT_QUEUES_START'
export const GET_OUTPUT_QUEUES_SUCCESS = 'GET_OUTPUT_QUEUES_SUCCESS'
export const GET_OUTPUT_QUEUES_FAILED = 'GET_OUTPUT_QUEUES_FAILED'

export const GET_OUTPUT_QUEUE_DETAILS_START = 'GET_OUTPUT_QUEUE_DETAILS_START'
export const GET_OUTPUT_QUEUE_DETAILS_SUCCESS = 'GET_OUTPUT_QUEUE_DETAILS_SUCCESS'
export const GET_OUTPUT_QUEUE_DETAILS_FAILED = 'GET_OUTPUT_QUEUE_DETAILS_FAILED'

export const GET_OUTPUT_QUEUE_REQUEST_DETAILS_START = 'GET_OUTPUT_QUEUE_REQUEST_DETAILS_START'
export const GET_OUTPUT_QUEUE_REQUEST_DETAILS_SUCCESS = 'GET_OUTPUT_QUEUE_REQUEST_DETAILS_SUCCESS'
export const GET_OUTPUT_QUEUE_REQUEST_DETAILS_FAILED = 'GET_OUTPUT_QUEUE_REQUEST_DETAILS_FAILED'

export const GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_START = 'GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_START'
export const GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_SUCCESS = 'GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_SUCCESS'
export const GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_FAILED = 'GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_FAILED'

export const CHECK_IPP_STATUS_START = 'CHECK_IPP_STATUS_START'
export const CHECK_IPP_STATUS_SUCCESS = 'CHECK_IPP_STATUS_SUCCESS'
export const CHECK_IPP_STATUS_FAILED = 'CHECK_IPP_STATUS_FAILED'

export const NO_OUTPUT_QUEUES_FOUND = 'NO_OUTPUT_QUEUES_FOUND'

/**
 *
 * @param {Object} searchParams The search params to filter the search.
 * @param {Boolean} keepPagination Flag, if a new search is done by user.
 * @param {Function} callback The callback which will be called when the request was successful. Can be undefined.
 */
export function getOutputQueues(searchParams, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({type: GET_OUTPUT_QUEUES_START})

    // clear invalid values of object and encode
    searchParams = ObjectUtils.removeByValue(searchParams, [undefined, null, ''])

    const queryParams = []
    Object.keys(searchParams).forEach(key => queryParams.push(`${key}=${encodeURIComponent(searchParams[key])}`))

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/output?${queryParams.join('&')}`, {method: 'get'})
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            // if (jsondata.data.length === 0) {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({type: NO_OUTPUT_QUEUES_FOUND})
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({type: GET_OUTPUT_QUEUES_FAILED})
          }
        } else {
          dispatch({type: GET_OUTPUT_QUEUES_SUCCESS, payload: jsondata.data, keepPagination})

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.output_queue_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({type: GET_OUTPUT_QUEUES_FAILED})
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getOutputQueueRequestDetails(outputQueue, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({type: GET_OUTPUT_QUEUE_REQUEST_DETAILS_START})

    // clear invalid values of object and encode
    outputQueue = ObjectUtils.removeByValue(outputQueue, [undefined, null, ''])

    const queryParams = []
    Object.keys(outputQueue).forEach(key => queryParams.push(`${key}=${encodeURIComponent(outputQueue[key])}`))

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/output/requestdetails?${queryParams.join('&')}`, {method: 'get'})
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({type: GET_OUTPUT_QUEUE_REQUEST_DETAILS_FAILED})
          }
        } else {
          dispatch({type: GET_OUTPUT_QUEUE_REQUEST_DETAILS_SUCCESS, payload: jsondata.data})

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.output_queue_details_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({type: GET_OUTPUT_QUEUE_REQUEST_DETAILS_FAILED})
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getOutputQueueInformation(outputQueue, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({type: GET_OUTPUT_QUEUE_DETAILS_START})

    // clear invalid values of object and encode
    outputQueue = ObjectUtils.removeByValue(outputQueue, [undefined, null, ''])

    const queryParams = []
    Object.keys(outputQueue).forEach(key => queryParams.push(`${key}=${encodeURIComponent(outputQueue[key])}`))

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/output/details?${queryParams.join('&')}`, {method: 'get'})
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({type: GET_OUTPUT_QUEUE_DETAILS_FAILED})
          }
        } else {
          dispatch({type: GET_OUTPUT_QUEUE_DETAILS_SUCCESS, payload: jsondata.data})

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.output_queue_details_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({type: GET_OUTPUT_QUEUE_DETAILS_FAILED})
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function updateOutputQueue(outputQueueID, outputChannelID, priority, rerunObj, command, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const message = result => {
      switch (command) {
        case 'STOP':
          return `queue.output_queue_cancel_${result}`
        case 'DELETE':
          return `queue.output_queue_mark_as_delete_${result}`
        case 'HOLD':
          return `queue.output_queue_hold_${result}`
        case 'RELEASE':
          return `queue.output_queue_release_${result}`
        case 'REPRIO':
          return `queue.output_queue_modify_priority_${result}`
        case 'RERUN':
          return `queue.output_queue_rerun_${result}`
        default:
          return ''
      }
    }

    const bodyObj = {BTOKENS: [outputQueueID], REQUEST: command}
    if (priority !== undefined) {
      bodyObj['PRTPRIO'] = priority
    }
    if (rerunObj) {
      bodyObj['STARTSEQ'] = rerunObj.startSequenz
      bodyObj['ENDSEQ'] = rerunObj.endSequenz
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/output`, {
        method: 'POST',
        body: bodyObj
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        // ! additionalInfo is a array for each btoken so we need to check each entry
        jsondata.additionalInfo.forEach(entry => {
          let rc = entry.data.RC.toString()
          let irc = entry.data.IRC.toString()
          if (rc !== '0' || irc !== '0') {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, entry.data.MSG, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
          } else {
            // call the callback on success
            if (callback) {
              callback()
            }
            SnackbarActions.show(Lang.translate(message('success'), lang, [outputChannelID, priority !== undefined ? priority.toString() : '']), SnackbarActions.TYPE_SUCCESS)(dispatch)
          }
        })

        refreshSearch()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate(message('error'), lang, [outputChannelID, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Request for requeue output queue
 * @param {String} outputQueueID
 * @param {String} outputChannelID
 * @param {Number} priority
 * @param {String} whichOutputChannel
 * @param {Function} callback
 */
export function requeueOutputQueue(outputQueueID, outputChannelID, priority, whichOutputChannel, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const message = result => `queue.output_queue_requeue_${result}`

    const bodyObj = {BTOKENS: [outputQueueID], REQUEST: 'REQUEUE'}
    if (priority !== undefined) {
      bodyObj['PRTPRIO'] = priority
    }
    if (whichOutputChannel !== undefined) {
      bodyObj['RQMODE'] = whichOutputChannel
      bodyObj['RQDCR'] = outputChannelID
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/output`, {
        method: 'POST',
        body: bodyObj
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        // ! additionalInfo is a array for each btoken so we need to check each entry
        jsondata.additionalInfo.forEach(entry => {
          let rc = entry?.error?.rc?.toString()
          let irc = entry?.error?.irc?.toString()
          if (!entry.success && (rc !== '0' || irc !== '0')) {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, entry.error.message, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
          } else {
            // call the callback on success
            if (callback) {
              callback()
            }
            SnackbarActions.show(Lang.translate(message('success'), lang, [outputChannelID, priority !== undefined ? priority.toString() : '']), SnackbarActions.TYPE_SUCCESS)(dispatch)
          }
        })

        refreshSearch()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate(message('error'), lang, [outputChannelID, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Requests the restapi and gets the step details for the sections of request details
 * @param {*} token
 * @param {*} process
 * @param {*} sequenceNumber BSEQNR
 * @param {*} callback
 */
export function getStepDetails(token, process, sequenceNumber, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({type: GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_START})

    const queryParams = [
      `TOKEN=${encodeURIComponent(token)}`,
      `PROCESS=${encodeURIComponent(process)}`,
      `BSEQNR=${encodeURIComponent(sequenceNumber)}`
    ]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/output/stepdetails?${queryParams.join('&')}`, {method: 'get'})
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({type: GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_FAILED})
          }
        } else {
          dispatch({type: GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_SUCCESS, payload: jsondata.data})

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.output_queue_request_step_details_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({type: GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_FAILED})
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description
 * @param {*} requestObj
 * @param {*} callback
 */
export function checkIPPStatus(requestObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({type: CHECK_IPP_STATUS_START})

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/output/command`, {
        method: 'POST',
        body: requestObj
      }),
      Config.CONNECT_TIMEOUT_LONG,
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT_LONG)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({type: CHECK_IPP_STATUS_FAILED})
          }
        } else {
          dispatch({type: CHECK_IPP_STATUS_SUCCESS, payload: jsondata.data?.MESSAGE})
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queues.output_queue_error_check_status', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({type: CHECK_IPP_STATUS_FAILED})
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const searchParams = {
      'DCR': prefs[Preferences.QUEUE_OUTPUT_OUTPUTCHANNEL_ID],
      'DCRTYPE': prefs[Preferences.QUEUE_OUTPUT_TYPE],
      'BTYPE': prefs[Preferences.QUEUE_OUTPUT_PRINT_BUNDLE],
      'BNAME': prefs[Preferences.QUEUE_OUTPUT_BUNDLE_NAME],
      'BREQUEST': prefs[Preferences.QUEUE_OUTPUT_REQUESTOR],
      'PRTSTAT': prefs[Preferences.QUEUE_OUTPUT_STATUS],
      'FORM': prefs[Preferences.QUEUE_OUTPUT_FORM],
      'EXT': prefs[Preferences.QUEUE_OUTPUT_EXTENSION],
      'REPORT': prefs[Preferences.QUEUE_OUTPUT_REPORT],
      'JOBNAME': prefs[Preferences.QUEUE_OUTPUT_JOBNAME],
      'RECI': prefs[Preferences.QUEUE_OUTPUT_RECIPIENT_ID],
      'PROCESS': prefs[Preferences.QUEUE_OUTPUT_DISPLAY],
      'PRTPRIO': prefs[Preferences.QUEUE_OUTPUT_SPECIFIC_PRIORITY],
      'PRTRQFLG': prefs[Preferences.QUEUE_OUTPUT_REQUEUED]
    }
    if (prefs[Preferences.QUEUE_OUTPUT_ACTIVE_TAB] === 0) {
      if (prefs[Preferences.QUEUE_OUTPUT_LASTTIME_MODE] === 0) {
        searchParams['SDATE'] = 'TODAY'
      } else if (prefs[Preferences.QUEUE_OUTPUT_LASTTIME_MODE] === 1) {
        searchParams['SDATE'] = 'YESTERDAY'
      } else if (prefs[Preferences.QUEUE_OUTPUT_LASTTIME_MODE] === 2) {
        searchParams['FROMLAST'] = prefs[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_LAST]
        searchParams['TUNITS'] = prefs[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_UNIT]
      }
    } else if (prefs[Preferences.QUEUE_OUTPUT_ACTIVE_TAB] === 1) {
      const sdate = DateUtils.getDate(prefs[Preferences.DATEMASK], prefs[Preferences.QUEUE_OUTPUT_START_DATE])
      const stime = prefs[Preferences.QUEUE_OUTPUT_START_TIME]
      const edate = DateUtils.getDate(prefs[Preferences.DATEMASK], prefs[Preferences.QUEUE_OUTPUT_END_DATE])
      const etime = prefs[Preferences.QUEUE_OUTPUT_END_TIME]

      searchParams['SDATE'] = DateUtils.getTimeshiftDate(sdate, stime, DateUtils.DDMMYYYY_DOT)
      searchParams['STIME'] = DateUtils.getTimeshiftDate(sdate, stime, DateUtils.TIME_DATEMASK)
      searchParams['EDATE'] = DateUtils.getTimeshiftDate(edate, etime, DateUtils.DDMMYYYY_DOT)
      searchParams['ETIME'] = DateUtils.getTimeshiftDate(edate, etime, DateUtils.TIME_DATEMASK)
    }
    getOutputQueues(
      searchParams,
      undefined,
      true
    )(dispatch)
  }
}