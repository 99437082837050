import { Component } from 'react'
import { connect } from 'react-redux'

import './LogoutDialog.scss'

import {
  Button, Checkbox, ModalDialog
} from 'BetaUX2Web-Components/src/'


import { translate } from 'language/Language'

import * as AuthActions from 'redux/actions/AuthenticationActions'
import * as LastSessionActions from 'redux/actions/LastSessionActions'
import * as Preferences from 'redux/general/Preferences'
import * as LocalStorage from 'redux/middleware/LocalStorage'
import store from 'redux/Store'

import { sendDocumentViewerBroadcastChannelMessage as sendMessage, MESSAGE_CLOSE_ORPHAN_DOCVIEWER } from 'utils/BroadcastChannelUtils'

class LogoutDialog extends Component {

  state = {
    saveSessionValues: false
  }

  /**
   * @description Changes the flag to save the session values.
   * @param {Boolean} val The new value.
   */
  handleOnCheckSessionValues(val) {
    this.setState({ saveSessionValues: val })
  }

  /**
   * @description Initializes the save session flag.
   */
  componentDidMount() {
    const saveSessionActive = LocalStorage.loadIndividualValue('saveSessionPreselected')

    /* Localstorage saves as string. Yes..Really */
    if (saveSessionActive === 'true') {
      this.setState({ saveSessionValues: saveSessionActive === 'true' })
    }
  }

  /**
   * @description Handles the logout action. Also saves the save session flag into local storage.
   */
  handleOnLogout() {
    // remove last session because we only need the last session on usertoken expired
    this.props.removeLastSession()

    const serverdata = store.getState().auth.serverdata
    const userid = store.getState().auth.userid
    const serverType = serverdata.server.type
    const serverVersion = serverdata.server.version

    // only save preferences if user checked this option
    const preferences = this.state.saveSessionValues ? serverdata.preferences : {}

    LocalStorage.saveIndividualValue('saveSessionPreselected', this.state.saveSessionValues)

    // Case: After a logout any former Document Viewer tab will render the main Beta Admin view (same for a follow-up login).
    // This will prevent any duplicate Beta Admins and close the orphan Doc Viewer tab:
    sendMessage(MESSAGE_CLOSE_ORPHAN_DOCVIEWER)

    // call logout request
    this.props.logout(userid, serverType, serverVersion, preferences)
  }

  render = () => {
    return (
      <ModalDialog
        id={this.props.id}
        small
        title={translate('header.logout', this.props.lang)}
        onClose={() => this.props.onClose()}
        visible={this.props.visible}>
        <div
          id={`${this.props.id}_body`}
          className='modalDialogBody'>
          <label
            id={`${this.props.id}_body_label`}
            className='logoutDialogQuestion'>
            {translate('logoutdialog.question_save_session_values', this.props.lang)}
          </label>
          <Checkbox
            id={`${this.props.id}_body_checkbox`}
            tabindex={0} // ! not html therefore this is an unused property
            onCheck={(val) => this.handleOnCheckSessionValues(val)}
            value={this.state.saveSessionValues}
            label={translate('logoutdialog.save_session_values', this.props.lang)}
          />
        </div>
        <div
          id={`${this.props.id}_footer`}
          className='modalDialogFooter'>
          <Button
            id={`${this.props.id}_footer_cancel`}
            text={translate('general.cancel', this.props.lang)}
            onClick={() => this.props.onClose()}
            tabindex={1} />
          <Button
            id={`${this.props.id}_footer_logout`}
            text={translate('header.logout', this.props.lang)}
            onClick={() => this.handleOnLogout()}
            tabindex={2}
            primary
            submit />
        </div>
      </ModalDialog>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: (userid, serverType, serverVersion, preferences) => AuthActions.logout(userid, serverType, serverVersion, preferences)(dispatch),
    removeLastSession: () => LastSessionActions.removeLastSession()(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutDialog)