import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const DATABASE_BQLQUERY_START = 'DATABASE_BQLQUERY_START'
export const DATABASE_BQLQUERY_SUCCESS = 'DATABASE_BQLQUERY_SUCCESS'
export const DATABASE_BQLQUERY_FAILED = 'DATABASE_BQLQUERY_FAILED'
export const DATABASE_BQLQUERY_NO_RESULT = 'DATABASE_BQLQUERY_NO_RESULT'

export const DATABASE_BQLQUERY_COPIED = 'DATABASE_BQLQUERY_COPIED'

export const DATABASE_GET_FIELDS_START = 'DATABASE_GET_FIELDS_START'
export const DATABASE_GET_FIELDS_SUCCESS = 'DATABASE_GET_FIELDS_SUCCESS'
export const DATABASE_GET_FIELDS_FAILED = 'DATABASE_GET_FIELDS_FAILED'

export const DATABASE_GET_TABLEKEYS_START = 'DATABASE_GET_TABLEKEYS_START'
export const DATABASE_GET_TABLEKEYS_SUCCESS = 'DATABASE_GET_TABLEKEYS_SUCCESS'
export const DATABASE_GET_TABLEKEYS_FAILED = 'DATABASE_GET_TABLEKEYS_FAILED'

export const DATABASE_GET_KEY_FIELDS_START = 'DATABASE_GET_KEY_FIELDS_START'
export const DATABASE_GET_KEY_FIELDS_SUCCESS = 'DATABASE_GET_KEY_FIELDS_SUCCESS'
export const DATABASE_GET_KEY_FIELDS_FAILED = 'DATABASE_GET_KEY_FIELDS_FAILED'

export const DATABASE_GET_TABLEFIELDS_START = 'DATABASE_GET_TABLEFIELDS_START'
export const DATABASE_GET_TABLEFIELDS_SUCCESS = 'DATABASE_GET_TABLEFIELDS_SUCCESS'
export const DATABASE_GET_TABLEFIELDS_FAILED = 'DATABASE_GET_TABLEFIELDS_FAILED'

export const DATABASE_GET_TABLES_START = 'DATABASE_GET_TABLES_START'
export const DATABASE_GET_TABLES_SUCCESS = 'DATABASE_GET_TABLES_SUCCESS'
export const DATABASE_GET_TABLES_FAILED = 'DATABASE_GET_TABLES_FAILED'

export const DATABASE_DELETE_FILE_START = 'DATABASE_DELETE_FILE_START'
export const DATABASE_DELETE_FILE_SUCCESS = 'DATABASE_DELETE_FILE_SUCCESS'
export const DATABASE_DELETE_FILE_FAILED = 'DATABASE_DELETE_FILE_FAILED'

export const DATABASE_CREATE_FILE_START = 'DATABASE_CREATE_FILE_START'
export const DATABASE_CREATE_FILE_SUCCESS = 'DATABASE_CREATE_FILE_SUCCESS'
export const DATABASE_CREATE_FILE_FAILED = 'DATABASE_CREATE_FILE_FAILED'

export const DATABASE_UPDATE_FILE_START = 'DATABASE_UPDATE_FILE_START'
export const DATABASE_UPDATE_FILE_SUCCESS = 'DATABASE_UPDATE_FILE_SUCCESS'
export const DATABASE_UPDATE_FILE_FAILED = 'DATABASE_UPDATE_FILE_FAILED'

export const DATABASE_GET_FILE_START = 'DATABASE_GET_FILE_START'
export const DATABASE_GET_FILE_SUCCESS = 'DATABASE_GET_FILE_SUCCESS'
export const DATABASE_GET_FILE_FAILED = 'DATABASE_GET_FILE_FAILED'

export const DATABASE_GET_FILES_START = 'DATABASE_GET_FILES_START'
export const DATABASE_GET_FILES_SUCCESS = 'DATABASE_GET_FILES_SUCCESS'
export const DATABASE_GET_FILES_FAILED = 'DATABASE_GET_FILES_FAILED'

export const NO_DATABASE_FILES_FOUND = 'NO_DATABASE_FILES_FOUND'

// * 'XAPROD': product name
// * 'XASNAME': short name
// * 'XALNAME': long name
// * 'XDCSI': CI size
// * 'XDTYPE': type of the database file. Possible Values:
// *    'MI': mirror
// *    'DE': data file
// *    'KE': key file
// *    'SY': sync file
// *    'SP': spool file
// *    'FM': file is beeing formatted
// *    'CL': file is closed
// * 'XDSTAT': status of the database file. Possible values:
// *    'ER': error
// *    'US': in use
// *    'FU': full
// *    'MO': model
// *    'RO': read only
// *    'FM': file is beeing formatted
// *    'CL': file is closed
// * 'XDFID': file id
// * 'XDDDNAME': dd name
// * 'XDDSN': dataset name
// * 'XDVOL': volser
// * 'XDUNIT': unit
// * 'XDSPACE': space in cylinder
// * 'XDKEYNAM': short name of key file
// * 'XDMIRNAM': short name of the mirror file
// * 'XDBUFFA': buffer used for speedmaster
// * 'XDALCRBA': allocated rba
// * 'XDUSERBA': used rba
// * 'XDPUSE': filling level in percent
// * 'XDREADO': read only
// * 'XDBLKA': total number of blocks
// * 'XDBLKF': number of free blocks
// * 'XDBLKU': number of used blocks
// * 'XDMGMTCL': management class
// * 'XDSTORCL': storage class
// * 'XDDATACL': data class
// * 'XDGETS': blocks read or retrieved from file (I/O read)
// * 'XDPUTS': blocks issued or put to file (I/O write)
// * 'XDGPSPD': speedmaster calls (I/O req)
// * 'XDPSPD': saving due to speedmaster in percent

/**
 * @description Calls the rest api and obtain database fields.
 * @param {String} field The name of a database field.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getFields(field, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: DATABASE_GET_FIELDS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/fields?FIELD=${field}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DATABASE_GET_FIELDS_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: DATABASE_GET_FIELDS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.fields_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_GET_FIELDS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and obtain database table keys.
 * @param {String} table The name of a database table.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getTableKeys(table, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: DATABASE_GET_TABLEKEYS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/tablekeys?TABLE=${table}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DATABASE_GET_TABLEKEYS_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: DATABASE_GET_TABLEKEYS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.tablekeys_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_GET_TABLEKEYS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and obtain database key fields
 * @param {String} key The name of a database table key.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getKeyFields(key, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)

    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: DATABASE_GET_KEY_FIELDS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/keyfields?KEY=${key}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DATABASE_GET_KEY_FIELDS_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: DATABASE_GET_KEY_FIELDS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.keyfields_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_GET_KEY_FIELDS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and obtain database table fields.
 * @param {String} table The name of a database table.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getTableFields(table, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: DATABASE_GET_TABLEFIELDS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/tablefields?TABLE=${table}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DATABASE_GET_TABLEFIELDS_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: DATABASE_GET_TABLEFIELDS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.tablefields_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_GET_TABLEFIELDS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and obtain database tables.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getTables(callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: DATABASE_GET_TABLES_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/tables`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DATABASE_GET_TABLES_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: DATABASE_GET_TABLES_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.tables_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_GET_TABLES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes a database file.
 * @param {String} shortname The shortname of database file (XASNAME).
 * @param {String} filename The name of database file (XDDSN).
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function deleteFile(shortname, filename, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: DATABASE_DELETE_FILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/file${createQueryParamsForFetch({ XASNAME: shortname })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DATABASE_DELETE_FILE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DATABASE_DELETE_FILE_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('database.delete_file_success', lang, filename), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getFiles again to get the current result
          getFiles()(dispatch)

        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.delete_file_error', lang, [filename, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_DELETE_FILE_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a database file.
 * @param {Object} file The database file to create. Possible values:
* 'XAPROD': product name (required)
* 'XDCSI': CI size
* 'XDTYPE': type of the database file. (required) Possible Values:
*    'MI': mirror
*    'DE': data file
*    'KE': key file
*    'SY': sync file
*    'SP': spool file
*    'FM': file is beeing formatted
*    'CL': file is closed
* 'XDDSN': dataset name (required)
* 'XDSPACE': space in cylinder (required)
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function createFile(file, callback) {

  return dispatch => {
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: DATABASE_CREATE_FILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/file`, {
        method: 'post',
        body: ObjectUtils.removeByValue(file, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DATABASE_CREATE_FILE_FAILED, payload: { error } })
        }
        else {
          // call getFiles again to get the current result
          getFiles(() => {
            const filename = file['XDDSN']
            dispatch({ type: DATABASE_CREATE_FILE_SUCCESS, payload: jsondata.data })
            SnackbarActions.show(Lang.translate('database.create_file_success', lang, filename), SnackbarActions.TYPE_SUCCESS)(dispatch)
          })(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.create_file_error', lang, [file['XASPROD'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_CREATE_FILE_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a database file.
 * @param {String} filename The name of the database file (XASNAME) (required)
 * @param {String} readonly The readonly flag (XDREADO)
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function updateFile(filename, readonly, callback) {
  return dispatch => {

    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: DATABASE_UPDATE_FILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/file`, {
        method: 'put',
        body: {
          ...filename && { XASNAME: filename },
          ...readonly && { XDREADO: readonly },
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DATABASE_UPDATE_FILE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DATABASE_UPDATE_FILE_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('database.update_file_success', lang, filename), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getFiles again to get the current result
          getFiles()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.update_file_error', lang, [filename, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_UPDATE_FILE_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and optain information for a database file.
 * @param {String} filename The name of the database file
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getFile(filename, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: DATABASE_GET_FILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/file?FILE=${filename}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DATABASE_GET_FILE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DATABASE_GET_FILE_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback(jsondata.data)
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.get_file_error', lang, [filename, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_UPDATE_FILE_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and optain database files.
 * @param {Function} callback the callback which will be called when the request was successful. Can be null.
 */
export function getFiles(callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: DATABASE_GET_FILES_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/files`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_DATABASE_FILES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: DATABASE_GET_FILES_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: DATABASE_GET_FILES_SUCCESS, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.files_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_GET_FILES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and executes a bql command.
 * @param {String} bqlCommand The bql command.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function executeBqlQuery(bqlCommand, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: DATABASE_BQLQUERY_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/bqlquery`, {
        method: 'post',
        body: { COMMAND: bqlCommand.trim() }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: DATABASE_BQLQUERY_NO_RESULT })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: DATABASE_BQLQUERY_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: DATABASE_BQLQUERY_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.bqlquery_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DATABASE_BQLQUERY_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getCopiedBqlQueryData(databaseData) {
  return dispatch => {
    const currentDatabaseData = Object.assign({}, store.getState().auth.database)

    Object.keys(databaseData).forEach(key => {
      currentDatabaseData[key] = databaseData[key]
    })

    dispatch({
      type: DATABASE_BQLQUERY_COPIED, payload: currentDatabaseData
    })
  }
}