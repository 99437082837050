import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import { Component } from 'react'
import * as SystemDefaultUtils from 'utils/SystemDefaultUtils'

// Components
import {
  Button, Card, Column, MetaDataGrid, Modal as ModalComponent,
  Row, Switch
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

class SystemDefaultsSwitchableDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    parameter: PropTypes.string.isRequired,
    selected: PropTypes.number.isRequired,
    values: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  }

  state = {
    index: this.props.selected
  }

  /**
   * @description Gets the translated switchable values.
   * @param {Array} values The values which includes the translation key.
   * @returns {Array} The translated switchable values.
   */
  getTranslatedSwitchableValues = (values) => {
    const items = []

    values.forEach(value => {
      items.push(translate(value.translationKey))
    })

    return items
  }

  /**
   * @description Renders the header.
   */
  renderHeader() {
    const { id, parameter } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('general.parameter'), value: SystemDefaultUtils.getParameterTranslation(parameter) },
        ]}
        columns={4}
      />
    )
  }

  render = () => {
    const { id, onClose, onSave, values, parameter } = this.props
    const { index } = this.state

    const translatedValues = this.getTranslatedSwitchableValues(values)
    return (
      <Modal onClose={onClose} id={id} size={'s'}>
        <Header
          id={`${id}_modify_systemdefaults`}
          title={translate('systemdefaults.modify_systemdefaults')}
          onClose={() => onClose()}>
          {this.renderHeader()}
        </Header>
        <Main id={id}>
          <Card>
            <Row>
              <Column
                colMD={12}>
                <Switch
                  id={`${id}_value`}
                  title={translate('general.value')}
                  onClick={index => this.setState({ index: index })}
                  activeIndex={index}
                  items={translatedValues}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={id}>
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_footer_save`}
            text={translate('general.save')}
            onClick={() => { onSave(parameter, values[index].key) }}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

export default SystemDefaultsSwitchableDialog