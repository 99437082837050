import * as SearchControlMActions from 'redux/actions/SearchControlMActions'
import { LOGOUT_SUCCESS } from '../actions/AuthenticationActions'

const defaultState = {
  controlmJobs: undefined,
  controlmJobDetails: undefined,
  controlmJobLogs: undefined,
  controlmJobLog: undefined,
  controlmJobLogPrintInfo: undefined,
  controlmJobLogInformation: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    case SearchControlMActions.GET_CONTROLM_JOBS_START:
      return { ...state }
    case SearchControlMActions.GET_CONTROLM_JOBS_SUCCESS:
      return { ...state, controlmJobs: action.payload, keepPagination: action.keepPagination }
    case SearchControlMActions.GET_CONTROLM_JOBS_FAILED:
      return { ...state }
    case SearchControlMActions.NO_CONTROLM_JOBS_FOUND:
      return { ...state, controlmJobs: {} }
    /* ********************************************************************************** */
    // Get specific uc4 job
    case SearchControlMActions.GET_CONTROLM_JOB_START:
      return { ...state }
    case SearchControlMActions.GET_CONTROLM_JOB_SUCCESS:
      return { ...state, controlmJobDetails: { ...state.controlmJobDetails, ...action.payload} }
    case SearchControlMActions.GET_CONTROLM_JOB_FAILED:
      return { ...state }
    case LOGOUT_SUCCESS:
      return defaultState
    /* ********************************************************************************** */
    // Get logs of a job
    case SearchControlMActions.GET_CONTROLM_JOB_LOGS_START:
      return { ...state }
    case SearchControlMActions.GET_CONTROLM_JOB_LOGS_SUCCESS:
      return { ...state, controlmJobLogs: action.payload }
    case SearchControlMActions.GET_CONTROLM_JOB_LOGS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get zos job log information
    case SearchControlMActions.CONTROLM_GET_JOB_LOG_INFORMATION_START:
      return { ...state }
    case SearchControlMActions.CONTROLM_GET_JOB_LOG_INFORMATION_SUCCESS:
      return { ...state, controlmJobLogInformation: action.payload }
    case SearchControlMActions.CONTROLM_GET_JOB_LOG_INFORMATION_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get zos job log
    case SearchControlMActions.CONTROLM_JOBS_GET_LOG_START:
      return { ...state }
    case SearchControlMActions.CONTROLM_JOBS_GET_LOG_SUCCESS:
      return { ...state, controlmJobLog: action.payload }
    case SearchControlMActions.CONTROLM_JOBS_GET_LOG_FAILED:
      return { ...state, controlmJobLog: undefined }
    /* ********************************************************************************** */
    // Get zos job log print info
    case SearchControlMActions.CONTROLM_JOBS_GET_LOG_PRINT_INFO_START:
      return { ...state }
    case SearchControlMActions.CONTROLM_JOBS_GET_LOG_PRINT_INFO_SUCCESS:
      return { ...state, controlmJobLogPrintInfo: action.payload }
    case SearchControlMActions.CONTROLM_JOBS_GET_LOG_PRINT_INFO_FAILED:
      return { ...state }
    case SearchControlMActions.CONTROLM_JOBS_DOWNLOAD_JOB_START:
      return { ...state }
    case SearchControlMActions.CONTROLM_JOBS_DOWNLOAD_JOB_SUCCESS:
      return { ...state }
    case SearchControlMActions.CONTROLM_JOBS_DOWNLOAD_JOB_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer