import PropTypes from 'prop-types'
import { Component } from 'react'

// Utils
import * as moment from 'moment'

// Components
import {
  Card, Column, Dropdown, Input, NumericSpinner, Row, Switch, Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'

import Datepicker from '../datepicker/Datepicker'
import Timepicker from '../timepicker/Timepicker'


import { validDateStrings } from '../../utils/DateUtils'

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.hours' },
  { key: UNIT_DAYS, translationKey: 'general.days' },
]

export const LASTTIME_MODE_TODAY = 'today'
export const LASTTIME_MODE_YESTERDAY = 'yesterday'
export const LASTTIME_MODE_CUSTOM = 'custom'

const LASTTIME_MODES = [
  { key: LASTTIME_MODE_TODAY, translationKey: 'general.today' },
  { key: LASTTIME_MODE_YESTERDAY, translationKey: 'general.yesterday' },
  { key: LASTTIME_MODE_CUSTOM, translationKey: 'general.custom' },
]

export default class TimeCard extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Used to display the date and time in the right language format.*/
    lang: PropTypes.string.isRequired,
    /** Used to display the date entries in the correct format. */
    datemask: PropTypes.string.isRequired,
    /** Initial values of the timecard. */
    values: PropTypes.shape({
      tabIndex: PropTypes.number.isRequired,
      lastTimeModeIndex: PropTypes.number,
      customLast: PropTypes.number.isRequired,
      customUnitIndex: PropTypes.number.isRequired,
      fromDate: PropTypes.shape({
        value: PropTypes.string.isRequired,
        error: PropTypes.string.isRequired
      }).isRequired,
      fromTime: PropTypes.shape({
        value: PropTypes.string.isRequired,
        error: PropTypes.string.isRequired
      }).isRequired,
      toDate: PropTypes.shape({
        value: PropTypes.string.isRequired,
        error: PropTypes.string.isRequired
      }).isRequired,
      toTime: PropTypes.shape({
        value: PropTypes.string.isRequired,
        error: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    /** Object maps keys returned by component to custom one */
    stateKeys: PropTypes.shape({
      tabIndex: PropTypes.string.isRequired,
      lastTimeModeIndex: PropTypes.string.isRequired,
      customLast: PropTypes.string.isRequired,
      customUnitIndex: PropTypes.string.isRequired,
      fromDate: PropTypes.string.isRequired,
      fromTime: PropTypes.string.isRequired,
      toDate: PropTypes.string.isRequired,
      toTime: PropTypes.string.isRequired
    }).isRequired,
    /**
     * Function to be called on change data.
     * @param {string} key Name of the key for which data changed
     * @param {string|object} value New value of the field
     * @param {string} error Error description if occurs, otherwise undefined
     */
    onValuesChange: PropTypes.func.isRequired,
    /** Enables third timestamp tab */
    enableTimestamp: PropTypes.bool,
    /** Title for the third tab with timestamp */
    timestampTitle: PropTypes.string,
    /**
     * Function to translate following keys by our own.
     *
     * - `general.time`
     * - `general.last`
     * - `general.unit`
     * - `general.date`
     * - `general.from`
     * - `general.to`
     * - `general.invalid_date_format`
     * - `general.invalid_time_format`
     * - `general.list_timestamp`
     * - `statistic.negative_date_difference_error`
     * - `standard_selection.list_time_stamp_error`
     *
     * @param {string} key
     */
    translate: PropTypes.func.isRequired,
    /** Reference to access DOM nodes */
    focusRefs: PropTypes.any,
    /** Parent container id */
    parentContainer: PropTypes.string
  }

  isDateAllowedDateString = value => validDateStrings.includes(value.toUpperCase())

  /**
   * @description Removes the errors when the tab index is changed to the first tab. Readds the erros when the tab index is changed to the second tab.
   */
  componentDidUpdate = prevProps => {
    const { values, stateKeys, onValuesChange, datemask } = this.props
    if (values.tabIndex === 0 && prevProps.values.tabIndex === 1) {
      onValuesChange(stateKeys.fromDate, values.fromDate.value, '')
      onValuesChange(stateKeys.toDate, values.toDate.value, '')
      onValuesChange(stateKeys.fromTime, values.fromTime.value, '')
      onValuesChange(stateKeys.toTime, values.toTime.value, '')
    }
    else if (values.tabIndex === 0 && prevProps.values.tabIndex === 2) {
      onValuesChange(stateKeys.listTimestamp, values.listTimestamp.value, '')
    }
    else if (values.tabIndex === 1 && prevProps.values.tabIndex !== 1) {
      if (values.fromDate.value !== '' && !this.isDateAllowedDateString(values.fromDate.value) && !this.isDate(values.fromDate.value, datemask)) {
        onValuesChange(stateKeys.fromDate, values.fromDate.value, 'general.invalid_date_format')
      }
      if (values.toDate.value !== '' && !this.isDateAllowedDateString(values.toDate.value) && !this.isDate(values.toDate.value, datemask)) {
        onValuesChange(stateKeys.toDate, values.toDate.value, 'general.invalid_date_format')
      }
      if (values.fromTime.value !== '' && !values.fromTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        onValuesChange(stateKeys.fromTime, values.fromTime.value, 'general.invalid_time_format')
      }
      if (values.toTime.value !== '' && !values.toTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        onValuesChange(stateKeys.toTime, values.toTime.value, 'general.invalid_time_format')
      }
    }
    else if (values.tabIndex === 2 && prevProps.values.tabIndex !== 2) {
      if (![0, 16].includes(values.listTimestamp.value.length) || !values.listTimestamp.value.match(/^$|^[0-9a-fA-F]+$/gm)) {
        onValuesChange(stateKeys.listTimestamp, values.listTimestamp.value, 'standard_selection.list_time_stamp_error')
      }
    }
    if (prevProps.datemask !== datemask && values.tabIndex === 1) {
      if (values.fromDate.value !== '' && !this.isDateAllowedDateString(values.fromDate.value) && this.isDate(values.fromDate.value, prevProps.datemask) && !this.isDate(values.fromDate.value, datemask)) {
        onValuesChange(stateKeys.fromDate, moment(values.fromDate.value, prevProps.datemask).format(datemask), '')
      }
      if (values.toDate.value !== '' && !this.isDateAllowedDateString(values.toDate.value) && this.isDate(values.toDate.value, prevProps.datemask) && !this.isDate(values.toDate.value, datemask)) {
        onValuesChange(stateKeys.toDate, moment(values.toDate.value, prevProps.datemask).format(datemask), '')
      }
    }
  }

  /**
   * @description Returns, if the given date matches the pattern.
   * @param {String} date The date which will be checked.
   * @param {String} pattern The pattern which is used to check the date.
   * @returns {Boolean}
   */
  isDate = (date, pattern) => moment(date, pattern, true).isValid()

  /**
   * @description Gets the translated dropdown items.
   * @param {Array} items The array which includes all items.
   */
  getTranslatedDropdownItems = (items) => {
    const translatedItems = []
    items.forEach(entry => {
      translatedItems.push(this.props.translate(entry.translationKey))
    })
    return translatedItems
  }

  validateListTimeStamp = listTimestamp => {
    if ([0, 16].includes(listTimestamp.value.length) && listTimestamp.value.match(/^$|^[0-9a-fA-F]+$/gm)) {
      return {}
    }
    return {
      value: listTimestamp.value,
      error: 'standard_selection.list_time_stamp_error'
    }
  }

  onBlurListTimestamp = () => {
    const { onValuesChange, values, stateKeys } = this.props
    const validation = this.validateListTimeStamp(values.listTimestamp)
    if (Object.keys(validation).length > 0) {
      onValuesChange(stateKeys.listTimestamp, validation.value, validation.error)
    }
  }

  isDateDiffNegative = () => {
    const { stateKeys, values, onValuesChange, datemask } = this.props
    if (values.fromDate.value !== '' && values.toDate.value !== '') {
      if (this.isDate(values.fromDate.value, datemask) && this.isDate(values.toDate.value, datemask)) {
        const fromDate = moment(values.fromDate.value, datemask)
        const toDate = moment(values.toDate.value, datemask)
        if (toDate.isBefore(fromDate)) {
          onValuesChange(stateKeys.toDate, values.toDate.value, 'statistic.negative_date_difference_error')
        }
        else {
          onValuesChange(stateKeys.toDate, values.toDate.value, '')
        }
      }
    }
    else if (values.fromDate.value === '' && values.toDate.error === 'statistic.negative_date_difference_error') {
      onValuesChange(stateKeys.toDate, values.toDate.value, '')
    }
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, lang, title, datemask, onValuesChange, stateKeys, values, enableTimestamp, timestampTitle, focusRefs, parentContainer } = this.props
    const lastTimeModeItems = this.getTranslatedDropdownItems(LASTTIME_MODES)
    const unitItems = this.getTranslatedDropdownItems(UNITS)
    return (
      <Card id={`${id}_time_card`} title={title || this.props.translate('general.time')} noPadding>
        <Tabs
          id={`${id}_timecard_tabs`}
          onTabChanged={index => onValuesChange(stateKeys.tabIndex, index)}
          activeTab={values.tabIndex}
        >
          <Tab
            title={this.props.translate('general.last')}>
            <Row>
              <Column colMD={12}>
                <Switch
                  id={`${id}_lastmode`}
                  items={lastTimeModeItems}
                  maxPerRow={3}
                  activeIndex={values.lastTimeModeIndex}
                  onClick={index => onValuesChange(stateKeys.lastTimeModeIndex, index)}
                />
              </Column>
            </Row>
            {
              values.lastTimeModeIndex === 2 && (
                <Row>
                  <Column
                    colMD={6}
                    offsetMD={0}>
                    <NumericSpinner
                      id={`${id}_last`}
                      onChange={val => onValuesChange(stateKeys.customLast, val)}
                      max={99}
                      value={values.customLast}
                      steps={1}
                      min={1}
                      title={this.props.translate('general.last')}
                    />
                  </Column>
                  <Column
                    colMD={6}
                    offsetMD={0}>
                    <Dropdown
                      id={`${id}_customunit`}
                      items={unitItems}
                      activeIndex={values.customUnitIndex}
                      onChange={index => onValuesChange(stateKeys.customUnitIndex, index)}
                      title={this.props.translate('general.unit')}
                    />
                  </Column>
                </Row>
              )
            }
          </Tab>
          <Tab
            title={this.props.translate('general.date')}>
            <Row>
              <Column colMD={6}>
                <Datepicker
                  id={`${id}_fromdate`}
                  title={this.props.translate('general.from')}
                  value={values.fromDate.value}
                  error={values.fromDate.error && this.props.translate(values.fromDate.error)}
                  onChange={val => onValuesChange(stateKeys.fromDate, val, '')}
                  language={lang}
                  dateFormat={datemask}
                  onInvalidDate={val => onValuesChange(stateKeys.fromDate, val, 'general.invalid_date_format')}
                  focusRef={focusRefs?.fromDate}
                  onBlur={date => this.isDateDiffNegative(date)}
                  parentContainer={parentContainer}
                />
              </Column>
              <Column colMD={6}>
                <Timepicker
                  id={`${id}_fromtime`}
                  title={' '}
                  value={values.fromTime.value}
                  error={values.fromTime.error && this.props.translate(values.fromTime.error)}
                  onChange={(val, err) => onValuesChange(stateKeys.fromTime, val, err)}
                  onInvalidTime={val => onValuesChange(stateKeys.fromTime, val, 'general.invalid_time_format')}
                  language={lang}
                  focusRef={focusRefs?.fromTime}
                  parentContainer={parentContainer}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={6}>
                <Datepicker
                  id={`${id}_todate`}
                  title={this.props.translate('general.to')}
                  value={values.toDate.value}
                  error={values.toDate.error && this.props.translate(values.toDate.error)}
                  onChange={val => onValuesChange(stateKeys.toDate, val, '')}
                  language={lang}
                  dateFormat={datemask}
                  onInvalidDate={val => onValuesChange(stateKeys.toDate, val, 'general.invalid_date_format')}
                  oneAdditionalDate={true}
                  onBlur={date => this.isDateDiffNegative(date)}
                  focusRef={focusRefs?.toDate}
                  parentContainer={parentContainer}
                />
              </Column>
              <Column colMD={6}>
                <Timepicker
                  id={`${id}_totime`}
                  title={' '}
                  value={values.toTime.value}
                  error={values.toTime.error && this.props.translate(values.toTime.error)}
                  onChange={(val, err) => onValuesChange(stateKeys.toTime, val, err)}
                  onInvalidTime={val => onValuesChange(stateKeys.toTime, val, 'general.invalid_time_format')}
                  language={lang}
                  focusRef={focusRefs?.toTime}
                  parentContainer={parentContainer}
                />
              </Column>
            </Row>
          </Tab>
          {
            enableTimestamp &&
            <Tab
              title={timestampTitle ? timestampTitle : this.props.translate('general.list_timestamp')}>
              <Row>
                <Column colMD={12}>
                  <Input
                    id={`${id}_listtimestamp`}
                    title={timestampTitle ? timestampTitle : this.props.translate('general.list_timestamp')}
                    onInputChanged={val => onValuesChange(stateKeys.listTimestamp, val)}
                    onBlur={() => this.onBlurListTimestamp()}
                    value={values.listTimestamp.value}
                    error={values.listTimestamp.error && this.props.translate(values.listTimestamp.error)}
                    maxLength={16}
                    ref={focusRefs?.listTimestamp}
                  />
                </Column>
              </Row>
            </Tab>
          }
        </Tabs>
      </Card>
    )
  }
}