import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Label from '../label/Label'

import './MultilineInput.scss'
import ErrorMessage from '../error_message/ErrorMessage'

/**
 * MultilineInput component created according to
 * _Textarea_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
export default class MultilineInput extends Component {

  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /**
     * Function to be called on change of input
     * @param {string} value New selected value
     */
    onInputChanged: PropTypes.func.isRequired,
    /** Initial value of the input */
    value: PropTypes.string.isRequired,
    /** Label to be displayed above the input.*/
    title: PropTypes.string,
    /** Number of characters per line */
    cols: PropTypes.number,
    /** Number of text lines in input */
    rows: PropTypes.number,
    /** Defines resizable behavior */
    resizable: PropTypes.shape({
      direction: PropTypes.oneOf([
        'vertical', 'horizontal'
      ]),
      defaultHeight: PropTypes.number
    }),
    /**
    * Sets dot and required word, next to the _title_, which indicates that fields is required.
    * If true - english version is used "required"
    * If string, user provide the word which appears after dot.
    * If _title_ is not set, indicator will not be shown.
    */
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Disables components visually and functionally. */
    onBlur: PropTypes.func,
    /** Function to be called on an focus event.*/
    onFocus: PropTypes.func,
    /** Tab index */
    tabIndex: PropTypes.number,
    /** If true, entered text will be always uppercase  */
    uppercase: PropTypes.bool,
    /** Sets max length of entered value into input.*/
    maxLength: PropTypes.number,
    /** Use mono spaced font */
    mono: PropTypes.bool,
    /** Disables components visually and functionally. */
    disabled: PropTypes.bool,
    /** Sets autofocus */
    autoFocus: PropTypes.bool,
    /** Error description to be displayed below the input.*/
    error: PropTypes.string,
    /** Sets max length of entered value into input includes line breaks.*/
    maxLengthIncludesLinebreaks: PropTypes.bool
  }

  state = {
    padding: 6,
    fontSize: 14,
    minHeight: 40,
    caretPosition: undefined,
    maxLength: this.props.maxLength
  }

  textAreaRef = React.createRef()

  componentWillUnmount = () => {
    const { onBlur } = this.props
    onBlur && onBlur()
  }

  componentWillMount = () => {
    const { maxLength, cols, rows, maxLengthIncludesLinebreaks } = this.props
    this.setState({ maxLength: this.getMaxLength(maxLength, cols, rows, maxLengthIncludesLinebreaks) })
  }

  componentDidMount = () => {
    const {resizable, value, id, cols} = this.props;
    const {padding, fontSize, minHeight} = this.state;
    const el = document.querySelector(`#${id}_multiline_input`);
    const buffer = Array.from(value);
    if (value.length > cols) {
      const lines = value.split('\n') || value;
      let changed = false;
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].length > cols) {
          changed = true;
          lines[i] = lines[i].slice(0, cols-1) + '\n' + lines[i].slice(cols-1, lines[i].length);
        }
      }
      if(changed) {
        this.props.onInputChanged && this.props.onInputChanged(lines.join('\n'));
      }
    }
    if (resizable && value !== '') {
      const lineHeight = fontSize + 2 * padding;
      let actualLinebreaks = 0;
      const height = el.getBoundingClientRect().height;
      const val = buffer.join('');
      if (val.includes('\n')) {
        actualLinebreaks = val.split('\n').length - 1;
      }
      if (actualLinebreaks >= (height - minHeight) / lineHeight) {
        el.style.height = `${minHeight + actualLinebreaks * lineHeight}px`;
      }
    }
  };

  componentDidUpdate = (_, prevState) => {
    const { caretPosition } = this.state
    const { value, id } = this.props
    let newCaretPosition = caretPosition
    if (prevState.caretPosition === newCaretPosition) {
      // Just set cursor position when a value was inserted between.
      if (newCaretPosition < value.length) {
        const el = document.querySelector(`#${id}_multiline_input`)
        el.selectionStart = newCaretPosition
        el.selectionEnd = newCaretPosition
      }
    }
  }

  /**
   * @description limits the input to specific cols and rows and changes the value of the textarea when typing
   * @param {String} val value of the type event
   * @param event
   * @param {Boolean} initial Used when loading value into this component initially. Otherwise will not update for the initial value, which fills out the whole space because the update function detects no available space.
   */
  inputChanged = (val, event, initial = false) => {
    const { cols, rows, uppercase, onInputChanged } = this.props
    event && event.preventDefault()
    const el = event && event.target
    if (uppercase) {
      val = val.toUpperCase()
    }
    const isSpaceAvailable = (index, lines, rest) => {
      if (index < rows) {
        if (lines[index] === undefined) {
          return true
        }
        else if (lines[index].length < cols) {
          if (lines[index].length + rest.length <= cols) {
            return true
          }
          else {
            const avialableSpace = cols - lines[index].length
            const newRest = rest.substring(0, avialableSpace)
            return isSpaceAvailable(index + 1, lines, newRest)
          }
        }
        return isSpaceAvailable(index + 1, lines, rest)
      }
      return false
    }
    // Recursively check where the add the rest of the value which didn't fit into that line.
    const recursiveUpdate = (index, lines, rest) => {
      if (rest !== '' && lines.length <= rows) {
        if (index < lines.length) {
          if (lines[index + 1] !== undefined) {
            lines[index + 1] = `${rest}${lines[index + 1]}`
            if (lines[index + 1].length > cols) {
              let innerBuffer = lines[index]
              lines[index] = lines[index].substring(0, cols)
              rest = innerBuffer.substring(cols, innerBuffer.length)
              return recursiveUpdate(index + 1, lines, rest)
            }
            else {
              return lines
            }
          }
          else {
            lines.push(rest)
            return lines
          }
        }
      }
      return lines
    }
    let lines = val.split('\n')
    if (cols && rows) {
      if (lines.length <= rows) {
        if (this.props.value.length < this.state.maxLength || val.length < this.state.maxLength) {
          let availableSpace = true
          for (let i = 0; i < lines.length; i++) {
            let rest = ''
            if (lines[i].length > cols) {
              // copy lines array
              let innerBuffer = lines.slice()[i]
              rest = innerBuffer.substring(cols, innerBuffer.length)
              availableSpace = isSpaceAvailable(i, lines, rest)
              if (availableSpace) {
                lines[i] = lines[i].substring(0, cols)
                lines = recursiveUpdate(i, lines, rest)
              }
            }
          }
          if (availableSpace) {
            // Readds line breaks for each array entry.
            let result = lines.join('\n')

            let caretPosition = el?.selectionStart
            // Get cursor position.
            if (caretPosition) {
              // Gets the line from the previous caret position
              let buffer = this.props.value.substring(0, this.state.caretPosition)
              const previousCaretLine = buffer.split('\n').length
              // Gets the line from the current caret position
              buffer = result.substring(0, caretPosition)
              const currentCaretLine = buffer.split('\n').length

              // Checks if the caret position moved to the next line.
              const isCaretPositionInNextLine = currentCaretLine > previousCaretLine
              // Checks if one character was inserted at line break position so the cursor position needs to be increased.
              const oneCharWasInsertedAtLinebreak = result[caretPosition - 1] === '\n'

              const oldValueLength = this.props.value.replace(/[\n]/g, '').length
              const newValueLength = result.replace(/[\n]/g, '').length
              if ((oneCharWasInsertedAtLinebreak || isCaretPositionInNextLine) && oldValueLength < newValueLength) {
                // Increase cursor postion with 1 when a value was inserted at line break position.
                caretPosition += 1
              }
            }
            this.setState({ caretPosition: caretPosition }, () => onInputChanged(result))
          }
        }
      }
    }
    else if (rows === undefined || cols === undefined) {
      onInputChanged(val)
    }
    if (initial) {
      onInputChanged(val)
    }
  }

  onKeyDown = event => {
    const { id, resizable, rows } = this.props
    const { padding, fontSize, minHeight } = this.state
    if (event.key === 'Enter' && resizable) {
      const lineHeight = fontSize + 2 * padding
      const el = document.querySelector(`#${id}_multiline_input`)
      const height = el.getBoundingClientRect().height
      const actualLinebreaks = event.target.value.split('\n').length
      if (actualLinebreaks >= (height - minHeight) / lineHeight && actualLinebreaks < rows) {
        event.target.style.height = 'auto'
        event.target.style.height = `${minHeight + actualLinebreaks * lineHeight}px`
      }
    }
  }

  getMaxLength = (maxLength, cols, rows, maxLengthIncludesLinebreaks) => {
    let newMaxLength = rows !== undefined && cols !== undefined
      ? maxLength <= cols * rows
        ? maxLength
        : cols * rows
      : maxLength
        ? maxLength
        : undefined
    if (newMaxLength !== undefined && !maxLengthIncludesLinebreaks) {
      newMaxLength += rows - 1
    }
    return newMaxLength
  }

  /**
   * @description renders the textarea input
   */
  renderTextArea = () => {
    const { id, rows, value, tabIndex, onBlur, onFocus, disabled, autoFocus, mono, resizable, error } = this.props
    const { padding, fontSize, minHeight, maxLength } = this.state
    let css = {}
    if (resizable) {
      const lineHeight = fontSize + 2 * padding
      const direction = resizable.direction === true ? 'both' : resizable.direction === 'vertical' ? 'vertical' : resizable.direction === 'horizontal' ? 'horizontal' : 'none'
      const height = resizable.direction === 'vertical'
        ? `${resizable.defaultHeight !== undefined
          ? (minHeight + (resizable.defaultHeight - 1) * lineHeight)
          : minHeight}px`
        : 'auto'
      const maxHeight = `${minHeight + (rows - 1) * lineHeight}px`

      css = { resize: direction, height, maxHeight }
    } else {
      css = { resize: 'none' }
    }

    return (
      <textarea
        style={css}
        id={`${id}_multiline_input`}
        key={`${id}_multiline_input`}
        ref={this.textAreaRef}
        className={`${mono && 'textarea_mono'} ${error && 'textarea_error'}`}
        tabIndex={tabIndex}
        value={value}
        onChange={event => this.inputChanged(event.target.value, event)}
        rows={rows}
        invalid={error ? 'true' : 'false'}
        disabled={disabled}
        autoFocus={autoFocus}
        onBlur={onBlur ? () => onBlur() : null}
        onFocus={onFocus ? () => onFocus() : null}
        maxLength={maxLength}
        onKeyPress={event => this.onKeyDown(event)}
        wrap={'hard'}
      />
    )
  }

  render() {
    const { id, title, error, required, disabled } = this.props
    return (
      <div
        id={`${id}_container`}
        className={`bux_multiline_input ${disabled && ' disabled'}`}>
        <Label
          id={`${id}_label`}
          title={title}
          required={required}
          disabled={disabled}
          isError={!!error}
        />
        {this.renderTextArea()}
        <ErrorMessage id={`${id}_error`} text={error}/>
      </div>
    )
  }
}