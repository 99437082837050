import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ServerMaintenance from './server_maintenance/ServerMaintenance'
import ServerExternalCommand from './server_external_command/ServerExternalCommand'
import ServerAccountingRecords from './server_accounting_records/ServerAccountingRecords'
import ServerMessageLog from './server_message_log/ServerMessageLog'
import ServerLogFile from './server_log_file/ServerLogFile'
import ServerPrinterDeviceInfo from './server_printer_device_info/ServerPrinterDeviceInfo'
import ServerSystemDefaults from './server_system_defaults/ServerSystemDefaults'
import ServerArchiveFile from './server_archive_file/ServerArchiveFile'
import ServerLpdStatus from './server_lpd_status/ServerLpdStatus'
import ServerOutputProcesses from './server_output_processes/ServerOutputProcesses'

class ServerBody extends Component {

  /**
   * @description Gets the body which should be rendered.
   */
  getBody() {
    const { id, serverMode } = this.props
    switch (serverMode) {
      case 'accounting': return <ServerAccountingRecords id={id} />
      case 'externalcmd': return <ServerExternalCommand id={id} />
      case 'maintenance': return <ServerMaintenance id={id} />
      case 'messagelog': return <ServerMessageLog id={id} />
      case 'serverlogfile': return <ServerLogFile id={id} />
      case 'printerdeviceinfo': return <ServerPrinterDeviceInfo id={id} />
      case 'systemdefaults': return <ServerSystemDefaults id={id} />
      case 'archivefile': return <ServerArchiveFile id={id} />
      case 'lpdstatus': return <ServerLpdStatus id={id} />
      case 'outputprocesses': return <ServerOutputProcesses id={id} />
      // should not happen
      default: return null
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

ServerBody.propTypes = {
  id: PropTypes.string.isRequired,
  serverMode: PropTypes.string
}

export default ServerBody