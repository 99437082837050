
import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'

export const RECIPIENTS_GET_HIERARCHY_DATA_START = 'RECIPIENTS_GET_HIERARCHY_DATA_START'
export const RECIPIENTS_GET_HIERARCHY_DATA_SUCCESS = 'RECIPIENTS_GET_HIERARCHY_DATA_SUCCESS'
export const RECIPIENTS_GET_HIERARCHY_DATA_FAILED = 'RECIPIENTS_GET_HIERARCHY_DATA_FAILED'
export const NO_RECIPIENTS_HIERARCHY_FOUND = 'NO_RECIPIENTS_HIERARCHY_FOUND'

export const RECIPIENT_HIERARCHY_GET_SINGLE_DATA_START = 'RECIPIENT_HIERARCHY_GET_SINGLE_DATA_START'
export const RECIPIENT_HIERARCHY_GET_SINGLE_DATA_SUCCESS = 'RECIPIENT_HIERARCHY_GET_SINGLE_DATA_SUCCESS'
export const RECIPIENT_HIERARCHY_GET_SINGLE_DATA_FAILED = 'RECIPIENT_HIERARCHY_GET_SINGLE_DATA_FAILED'

export const RECIPIENT_GET_HIERARCHY_ENTRY_DATA_START = 'RECIPIENT_GET_HIERARCHY_ENTRY_DATA_START'
export const RECIPIENT_GET_HIERARCHY_ENTRY_DATA_SUCCESS = 'RECIPIENT_GET_HIERARCHY_ENTRY_DATA_SUCCESS'
export const RECIPIENT_GET_HIERARCHY_ENTRY_DATA_FAILED = 'RECIPIENT_GET_HIERARCHY_ENTRY_DATA_FAILED'
export const NO_RECIPIENT_HIERARCHY_ENTRY_FOUND = 'NO_RECIPIENT_HIERARCHY_ENTRY_FOUND'

export const RECIPIENTS_HIERARCHY_ENTRIES_UPDATED = 'RECIPIENTS_HIERARCHY_ENTRIES_UPDATED'
export const NO_CHILDS = 'NO_CHILDS'

/**
 * @description Calling the rest api and do getRecipients request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'RECI', 'PREDRECI', 'RTYPE', 'OWNER', 'TITLE', 'BRWS', 'DCR', 'PCR', 'PPN', 'CDATE', 'CTIME'
 * @param {String} rtype The recipient type. Possible values:
 * 'ADDR', 'RECI', 'ALIAS' or ''
 * @param {String} distributionType The distribution type. Possible values:
 * 'ONLINE', 'AUTO-P', 'BUNDLE', 'ALL'
 * @param {String} recipient The recipient pattern.
 * @param {String} preRecipient The pre recipient pattern.
 * @param {String} owner The owner pattern.
 * @param {String} title The title pattern.
 * @param {String} outputchannel The outputchannel pattern.
 * @param {String} outputformat The outputformat pattern.
 * @param {String} ppn The post precess note pattern.
 * @param {Function} callback the callback which will be called when the request was successful.
 */
export function getRecipientsHierarchy(fields, rtype, distributionType, recipient, preRecipient, owner, title, outputchannel, outputformat, ppn, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: RECIPIENTS_GET_HIERARCHY_DATA_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (rtype) { queryParams.push(`RTYPE=${encodeURIComponent(rtype)}`) }
    if (distributionType) { queryParams.push(`BRWS=${encodeURIComponent(distributionType)}`) }
    if (recipient) { queryParams.push(`RECI=${encodeURIComponent(recipient)}`) }
    if (preRecipient) { queryParams.push(`PREDRECI=${encodeURIComponent(preRecipient)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`TITLE=${encodeURIComponent(title)}`) }
    if (outputchannel) { queryParams.push(`DCR=${encodeURIComponent(outputchannel)}`) }
    if (outputformat) { queryParams.push(`PDC=${encodeURIComponent(outputformat)}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipients?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_RECIPIENTS_HIERARCHY_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: RECIPIENTS_GET_HIERARCHY_DATA_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: RECIPIENTS_GET_HIERARCHY_DATA_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.recipients_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENTS_GET_HIERARCHY_DATA_FAILED, payload: { error } })
        setTimeout(() => LoadingSpinnerActions.hide()(dispatch), 3000)

      })
  }
}

/**
 * @description Calls the rest api and gets a recipient from hierarchy view.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'OWNER', 'TITLE', 'BRWS', 'PCR', 'DCR', 'PPN', 'MAILADR', 'OVPCR', 'OVDCR', 'OVPPN',
 * 'OVBUN', 'PRTBAN', 'PRTBAN2', 'PRTBANB', 'PRTTOC', 'OVBAN', 'OVLBB', 'BHPRG', 'THPRG',
 * 'DJDE', 'DJDET', 'BANNER1', 'BANNER2', 'BANNER3', 'BANNER4', 'BANNER5', 'BANNER6',
 * 'BANNER7', 'BANNER8', 'ATITLE1', 'ATITLE2', 'ATITLE3', 'ATITLE4', 'ATITLE5', 'ATITLE6',
 * 'ATITLE7', 'ATITLE8', 'CDATE', 'CTIME', 'CUSER'
 * @param {String} recipient The recipient.
 * @param {String} preRecipient The pre recipient.
 * @param {String} rtype The recipient type. Possible values:
 * 'ADDR', 'RECI', 'ALIAS'
 * @param {Function} callback The callback which will be called when the get recipient request was successful. Can be null.
 */
export function getRecipientToModify(fields, recipient, preRecipient, rtype, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: RECIPIENT_HIERARCHY_GET_SINGLE_DATA_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (recipient) { queryParams.push(`RECI=${encodeURIComponent(recipient)}`) }
    queryParams.push(`PREDRECI=${encodeURIComponent(preRecipient)}`)
    if (rtype) { queryParams.push(`RTYPE=${encodeURIComponent(rtype)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipient?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: RECIPIENT_HIERARCHY_GET_SINGLE_DATA_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: RECIPIENT_HIERARCHY_GET_SINGLE_DATA_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.get_recipient_error', lang, [recipient, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_HIERARCHY_GET_SINGLE_DATA_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}


/**
 * @description Calling the rest api and do getRecipients request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'RECI', 'PREDRECI', 'RTYPE', 'OWNER', 'TITLE', 'BRWS', 'DCR', 'PCR', 'PPN', 'CDATE', 'CTIME'
 * @param {String} rtype The recipient type. Possible values:
 * 'ADDR', 'RECI', 'ALIAS' or ''
 * @param {String} distributionType The distribution type. Possible values:
 * 'ONLINE', 'AUTO-P', 'BUNDLE', 'ALL'
 * @param {String} recipient The recipient pattern.
 * @param {String} preRecipient The pre recipient pattern.
 * @param {String} owner The owner pattern.
 * @param {String} title The title pattern.
 * @param {String} outputchannel The outputchannel pattern.
 * @param {String} outputformat The outputformat pattern.
 * @param {String} ppn The post precess note pattern.
 * @param {Function} callback the callback which will be called when the request was successful.
 */
export function getHierarchyEntry(fields, rtype, distributionType, recipient, preRecipient, owner, title, outputchannel, outputformat, ppn,
  parentKey, rootKey, hash, level, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const hierarchyState = store.getState().hierarchy.recipient
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: RECIPIENT_GET_HIERARCHY_ENTRY_DATA_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (rtype) { queryParams.push(`RTYPE=${encodeURIComponent(rtype)}`) }
    if (distributionType) { queryParams.push(`BRWS=${encodeURIComponent(distributionType)}`) }
    if (recipient) { queryParams.push(`RECI=${encodeURIComponent(recipient)}`) }
    if (preRecipient) { queryParams.push(`PREDRECI=${encodeURIComponent(preRecipient)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`TITLE=${encodeURIComponent(title)}`) }
    if (outputchannel) { queryParams.push(`DCR=${encodeURIComponent(outputchannel)}`) }
    if (outputformat) { queryParams.push(`PDC=${encodeURIComponent(outputformat)}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipients?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            const newHierarchyEntriesData = hierarchyState.hierarchyEntriesData !== undefined && hierarchyState.hierarchyEntriesData.length > 0
              ? [ ...hierarchyState.hierarchyEntriesData, { parentKey: parentKey, rootKey: rootKey, key: [hash(NO_CHILDS)], level: level, data: NO_CHILDS }]
              : [{ parentKey: parentKey, rootKey: rootKey, key: [hash(NO_CHILDS)], level: level, data: NO_CHILDS }]

            dispatch({ type: NO_RECIPIENT_HIERARCHY_ENTRY_FOUND, payload: { newHierarchyEntriesData: newHierarchyEntriesData }})
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: RECIPIENT_GET_HIERARCHY_ENTRY_DATA_FAILED, payload: { error } })
          }
        }
        else {
          const dataArr = jsondata.data.data.map(d => ({ data: d, key: hash(d) }))
          const keys = dataArr.map(d => d.key)
          const data = dataArr.map(d => d.data)

          const newHierarchyEntriesData = hierarchyState.hierarchyEntriesData !== undefined && hierarchyState.hierarchyEntriesData.length > 0
            ? [ ...hierarchyState.hierarchyEntriesData, { parentKey: parentKey, rootKey: rootKey, key: keys, level: level, data: data }]
            : [{ parentKey: parentKey, rootKey: rootKey, key: keys, level: level, data: data }]

          dispatch({ type: RECIPIENT_GET_HIERARCHY_ENTRY_DATA_SUCCESS, payload: { newHierarchyEntriesData: newHierarchyEntriesData }})
          // call the callback on success
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.recipients_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_GET_HIERARCHY_ENTRY_DATA_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export const updateHierarchyEntriesData = (newHierarchyEntriesData, reload = true) => {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    dispatch({
      type: RECIPIENTS_HIERARCHY_ENTRIES_UPDATED, payload: newHierarchyEntriesData
    })
    if (reload) {
      const prefs = store.getState().auth.serverdata.preferences
      const fields = undefined
      const rtype = prefs[Preferences.DEFINITION_RECI_TYPE]
      const distributionType = prefs[Preferences.DEFINITION_RECI_DISTRIBUTVIA]
      const recipient = prefs[Preferences.DEFINITION_RECI_ID]
      const preRecipient = prefs[Preferences.DEFINITION_RECI_PREDECESSORID]
      const owner = prefs[Preferences.DEFINITION_RECI_OWNER]
      const title = prefs[Preferences.DEFINITION_RECI_TITLE]
      const outputchannel = prefs[Preferences.DEFINITION_RECI_OUTPUTCHANNEL]
      const outputformat = prefs[Preferences.DEFINITION_RECI_OUTPUTFORMAT]
      const ppn = prefs[Preferences.DEFINITION_RECI_POSTPROCESSNOTE]
      getRecipientsHierarchy(fields, rtype, distributionType, recipient, preRecipient, owner, title, outputchannel, outputformat, ppn)(dispatch)
    }
    LoadingSpinnerActions.hide()(dispatch)
  }
}

export const getRecipientForHierarchy = (recipient, rtype, predecessor, callback) => {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    /* Build request-parameter for URL */
    const queryParams = [
      `RECI=${encodeURIComponent(recipient)}`,
      `RTYPE=${encodeURIComponent(rtype)}`,
      `PREDRECI=${encodeURIComponent(predecessor)}`
    ]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipient?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          // call callback on success
          if (callback) {
            callback(jsondata.data)
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.get_recipient_error', lang, [recipient, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}