import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as SortUtils from 'utils/SortUtils'

// Style
import './SearchResultUsers.scss'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CreateUserDialog, { CLONE_USER, COPY_USER, CREATE_USER } from 'components/dialogs/create_user_dialog/CreateUserDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyUserDialog from 'components/dialogs/modify_user_dialog/ModifyUserDialog'
import UserProfileDialog from 'components/dialogs/user_profile_dialog/UserProfileDialog'
import UserSecurityProfileDialog from 'components/dialogs/user_securityprofile_dialog/UserSecurityProfileDialog'
import TableSettings from 'components/table_settings/TableSettings'

// Utils
import * as UserUtils from 'utils/UserUtils'

// redux
import { translate } from 'language/Language'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as UserActions from 'redux/actions/UserActions'
import * as UserGroupAssignmentActions from 'redux/actions/UserGroupAssignmentActions'
import * as UserProfileActions from 'redux/actions/UserProfileActions'
import * as UserSecurityAssignmentActions from 'redux/actions/UserSecurityAssignmentActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultUsers extends Component {
  state = {
    showCreateUserDialog: false,
    showModifyUserDialog: false,
    showCopyUserDialog: false,
    showDeleteUserDialog: false,
    showCloneUserDialog: false,
    showDeleteUserProfileDialog: false,
    showSecurityProfileUserDialog: false,
    showUserProfileDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation(),
    assignmentsBlocked: false,
    userProfileLoadedForRemoval: false
  }

  componentDidUpdate(_, prevState) {
    if (this.state.userProfileLoadedForRemoval && prevState.userProfileLoadedForRemoval !== this.state.userProfileLoadedForRemoval && this.props.userprofile.userProfile) {
      this.setState({ showDeleteUserProfileDialog: true })
    }
    if (this.state.userProfileLoadedForRemoval && prevState.userProfileLoadedForRemoval !== this.state.userProfileLoadedForRemoval && !this.props.userprofile?.userProfile) {
      this.props.showSnackbar(translate('usermanagement.delete_userprofile_no_profile_available', this.props.lang, this.props.user?.BETAUSER), SnackbarActions.TYPE_INFO)
      this.setState({ userProfileLoadedForRemoval: false })
    }
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    return [
      { rest: BETAUSER, translation: 'user.user_id', default: true },
      { rest: USERNAME, translation: 'user.username', default: true },
      { rest: ADMIN, translation: 'user.privileges', default: true },
      { rest: USRREVOK, translation: 'general.revoked', default: true },
      // rest api do not send last login parameter, its just for save a rest parameter when using tablesettings
      { rest: LASTLOGIN, translation: 'general.last_login', default: true },
      { rest: LLOGDATE },
      { rest: LLOGTIME },
      { rest: CUSER },
      { rest: CDATE },
      { rest: CTIME },
      { rest: FORCEPWD },
      { rest: CKPWDEXT },
      { rest: PWDCINTV },
      { rest: PWDCDATE },
      { rest: PWDCTIME }
    ]
  }

  /**
   * @description Shows the create user dialog.
   */
  handleOnAddEntry = () => {
    this.setState({ showCreateUserDialog: true })
  }

  /**
   * @description Refreshs the current table.
   */
  handleOnRefresh = () => {
    const { preferences } = this.props
    const userid = preferences[Preferences.USERMANAGEMENT_USERS_USERID]
    const username = preferences[Preferences.USERMANAGEMENT_USERS_USERNAME]
    const adminOnly = preferences[Preferences.USERMANAGEMENT_USERS_PRIVILEGES]
    // "!== false" is required to ensure parameters are not sent when they are search, because preference saves as string
    const externalAuthOnly = preferences[Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY] !== 'false'
    const revokedOnly = preferences[Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY] !== 'false'

    this.props.getUsers(userid, username, adminOnly, externalAuthOnly, revokedOnly)
  }

  /**
   * @description Shows the delete user dialog, if the getuser request was successful.
   * @param {Number} index The index of the user which should be deleted.
   */
  handleOnDeleteUser = index => {
    // get the userid from redux
    const userid = this.props.users.data[index][0]

    // declare success callback
    const callback = () => { this.setState({ showDeleteUserDialog: true }) }

    // get the specific user
    this.props.getUser(userid, callback)
  }

  /**
   * @description Shows the delete user profile dialog, if the following requests were successful:
   * getuser, getuserprofile
   * @param {Number} index The index of the user which userprofile should be deleted.
   */
  handleOnDeleteUserProfile = index => {
    // get the userid from redux
    const userid = this.props.users.data[index][0]

    // declace get user callback
    const getUserCallback = () => {
      this.props.getUserProfile(
        userid,
        () => this.setState({ userProfileLoadedForRemoval: true })
      )
    }

    // get the specific user
    this.props.getUser(userid, getUserCallback)
  }

  /**
   * @description Shows the modify user dialog, if the following request were successful:
   * getuser, getgroups, getassignments
   * @param {Number} index The index of the user which should be modified.
   */
  handleOnModifyUser = index => {
    // get the userid from redux
    const userid = this.props.users.data[index][0]

    // declare success callback
    const showDialogCallback = () => {
      this.setState({ showModifyUserDialog: true })
    }
    // declare error callback
    const notAuthorizedCallback = () => {
      this.setState({ showModifyUserDialog: true, assignmentsBlocked: true })
    }
    const getAssignmentsCallback = (user) => {
      this.props.getGroupAssignments(
        user.BETAUSER,
        showDialogCallback,
        notAuthorizedCallback
      )
    }

    // get the specific user
    this.props.getUser(userid, getAssignmentsCallback)
  }

  /**
   * @description Shows the copy user dialog, if the getuser request was successful.
   * @param {Number} index The index of the user which should be copied.
   */
  handleCopyUserDialog = index => {
    // get the userid from redux
    const userid = this.props.users.data[index][0]

    // declare success callback
    const callback = () => { this.setState({ showCopyUserDialog: true }) }

    // get the specific user
    this.props.getUser(userid, callback)
  }

  /**
   * @description Shows the clone user dialog, if the getuser request was successful.
   * @param {Number} index The index of the user which should be cloned.
   */
  handleOnCloneUser = index => {
    // get the userid from redux
    const userid = this.props.users.data[index][0]

    // declare success callback
    const callback = () => { this.setState({ showCloneUserDialog: true }) }

    // get the specific user
    this.props.getUser(userid, callback)
  }

  /**
   * @description Shows the user security profiles dialog if the following requests were successful:
   * getuser, getsecurityprofiles
   * @param {Number} index The index of the user which security profiles should be shown.
   */
  handleOnShowUserSecurityProfilesDialog = index => {
    // get the userid from redux
    const userid = this.props.users.data[index][0]

    const getSecurityProfilesCallback = () => {
      this.setState({ showSecurityProfileUserDialog: true })
    }

    const getUserCallBack = () => {
      // get the specific user
      this.props.getSecurityprofileAssignments(
        userid,
        getSecurityProfilesCallback
      )
    }

    this.props.getUser(userid, getUserCallBack)
  }

  /**
   * @description Shows the user profile dialog if the getUserProfile request was successful.
   * @param {Number} index The index of the user which userprofile should be shown.
   */
  handleOnShowUserprofileDialog = index => {
    // get the userid from redux
    const userid = this.props.users.data[index][0]

    const getUserCallBack = () => {
      // get the specific user
      this.props.getUserProfile(
        userid,
        () => this.setState({ showUserProfileDialog: true })
      )
    }

    this.props.getUser(userid, getUserCallBack)
  }


  /**
   * @description Deletes the current user.
   */
  deleteUser = () => {
    const { user, deleteUser } = this.props

    // declare success callback
    const callback = () => this.setState({ showDeleteUserDialog: false })

    deleteUser(user.BETAUSER, user.USERNAME, callback)
  }

  /**
   * Deletes the current user profile.
   */
  deleteUserProfile = () => {
    const userid = this.props.user.BETAUSER

    // declare success callback
    const callback = () => { this.setState({ showDeleteUserProfileDialog: false, userProfileLoadedForRemoval: false }) }

    this.props.deleteUserProfile(userid, callback)
  }

  /**
   * @description Gets the index of a specific header.
   * @param {String} header specific header of the users
   */
  headerData = header => this.props.users.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData = () => {
    const { datemask, users } = this.props
    let data = []
    let headers = this.getUsedHeader()
    users.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === ADMIN) {
          const admin = element[this.headerData(ADMIN)]
          const index = UserUtils.PRIVILEGES_ITEMS.findIndex(d => d.key === admin)
          index !== -1
            ? dataBuffer.push(translate(UserUtils.PRIVILEGES_ITEMS[index].translationKey))
            // Fallback if a user has an invalid value
            : dataBuffer.push(translate('general.invalid_value'))
        }
        else if (header === USRREVOK) {
          dataBuffer.push(element[this.headerData(USRREVOK)] === 'OK' ? translate('general.no') : translate('general.yes'))
        }
        else if (header === LASTLOGIN) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('LLOGDATE')], element[this.headerData('LLOGTIME')].substring(0, 8)))
        }
        else if (header === LLOGTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('LLOGDATE')], element[this.headerData('LLOGTIME')].substring(0, 8)))
        }
        else if (header === LLOGDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('LLOGDATE')], element[this.headerData('LLOGTIME')].substring(0, 8), false))
        }
        else if (header === PWDCTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('PWDCDATE')], element[this.headerData('PWDCTIME')].substring(0, 8)))
        }
        else if (header === PWDCDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('PWDCDATE')], element[this.headerData('PWDCTIME')].substring(0, 8), false))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Data with jsx elements for icon (don't use this for download as csv)
   */
  getViewData = () => {
    const { datemask, users } = this.props
    let data = []
    let headers = this.getUsedHeader()
    users.data.forEach((element, i) => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === ADMIN) {
          const admin = element[this.headerData(ADMIN)]
          const index = UserUtils.PRIVILEGES_ITEMS.findIndex(d => d.key === admin)
          index !== -1
            ? dataBuffer.push(translate(UserUtils.PRIVILEGES_ITEMS[index].translationKey))
            // Fallback if a user has an invalid value
            : dataBuffer.push(translate('general.invalid_value'))
        }
        else if (header === USRREVOK) {
          const revoked = element[this.headerData(USRREVOK)]
          dataBuffer.push(
            <Icon
              key={i}
              id={`revoked_row${data.length}`}
              name={`${revoked === 'OK' ? 'unlock' : 'lock'}`}
              className={`${revoked === 'OK' ? 'ok' : 'revoked'}`}
            />
          )
        }
        else if (header === LASTLOGIN) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('LLOGDATE')], element[this.headerData('LLOGTIME')].substring(0, 8)))
        }
        else if (header === LLOGTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('LLOGDATE')], element[this.headerData('LLOGTIME')].substring(0, 8)))
        }
        else if (header === LLOGDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('LLOGDATE')], element[this.headerData('LLOGTIME')].substring(0, 8), false))
        }
        else if (header === PWDCTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('PWDCDATE')], element[this.headerData('PWDCTIME')].substring(0, 8)))
        }
        else if (header === PWDCDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('PWDCDATE')], element[this.headerData('PWDCTIME')].substring(0, 8), false))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data which should be shown.
   * @param {Array} header The header of the columns.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleOnModifyUser(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleOnDeleteUser(rowIndex)}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleOnModifyUser(rowIndex)}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => { this.handleCopyUserDialog(rowIndex) }}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleOnCloneUser(rowIndex)}
          id={`${id}_cloneBtn`}
          icon={<Icon name='clone' className='actionIcon' />}
          text={translate('general.clone')}
          title={translate('general.clone')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleOnDeleteUser(rowIndex)}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => { this.handleOnShowUserprofileDialog(rowIndex) }}
          id={`${id}_profileBtn`}
          icon={<Icon name='user_account' className='actionIcon' />}
          text={translate('userpreferences.profile')}
          title={translate('userpreferences.profile')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleOnDeleteUserProfile(rowIndex)}
          id={`${id}_deleteProfileBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('usermanagement.delete_profile')}
          title={translate('usermanagement.delete_profile')}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => { this.handleOnShowUserSecurityProfilesDialog(rowIndex) }}
          id={`${id}_securityProfilesBtn`}
          icon={<Icon name='security' className='actionIcon' />}
          text={translate('security.profiles')}
          title={translate('security.profiles')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={this.handleOnAddEntry}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleOnRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_USERS]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_USERS].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_USERS]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_USERS].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  render = () => {
    const { users, id, loading, drawerExpanded, lang, user, datemask, autoDrawer, removeAllGroups, keepPagination } = this.props
    const { showCreateUserDialog, showModifyUserDialog, showCopyUserDialog, showCloneUserDialog, showSecurityProfileUserDialog, showUserProfileDialog, showDeleteUserDialog, showDeleteUserProfileDialog, showTableSettingsDialog, assignmentsBlocked } = this.state
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    const data = users && users.data ? this.getCleanData() : null

    return (
      <>
        {/* create user dialog */}
        {showCreateUserDialog && (
          <CreateUserDialog
            id={`${id}_createuserdialog`}
            aimUser={CREATE_USER}
            visible={showCreateUserDialog}
            onClose={() => { this.setState({ showCreateUserDialog: false }) }}
          />
        )}

        {/* modify user dialog */}
        {showModifyUserDialog && user && (
          <ModifyUserDialog
            id='modifyuserdialog'
            visible={showModifyUserDialog}
            assignmentsBlocked={assignmentsBlocked}
            onClose={() => {
              removeAllGroups()
              this.setState({ showModifyUserDialog: false, assignmentsBlocked: false })
            }}
          />
        )}

        {/* copy user dialog */}
        {showCopyUserDialog && user && (
          <CreateUserDialog
            id='copyuserdialog'
            aimUser={COPY_USER}
            visible={showCopyUserDialog}
            onClose={() => {
              removeAllGroups()
              this.setState({ showCopyUserDialog: false })
            }}
          />
        )}

        {/* clone user dialog */}
        {showCloneUserDialog && user && (
          <CreateUserDialog
            id={`${id}_cloneuserdialog`}
            visible={showCloneUserDialog}
            aimUser={CLONE_USER}
            onClose={() => {
              removeAllGroups()
              this.setState({ showCloneUserDialog: false })
            }}
          />
        )}

        {/* users securityprofile dialog */}
        {showSecurityProfileUserDialog && (
          <UserSecurityProfileDialog
            id='users_securityprofiles'
            visible={showSecurityProfileUserDialog}
            onClose={() => { this.setState({ showSecurityProfileUserDialog: false }) }}
          />
        )}

        {/* users securityprofile dialog */}
        {showUserProfileDialog && (
          <UserProfileDialog
            id='users_userprofiledialog'
            onClose={() => { this.setState({ showUserProfileDialog: false }) }}
          />
        )}

        {/* delete user dialog */}
        {showDeleteUserDialog && user && (
          <DeleteDialog
            id={`${id}_deleteuserdialog`}
            title={translate('usermanagement.delete_user')}
            question={translate('usermanagement.question_delete_user')}
            visible={showDeleteUserDialog}
            onClose={() => { this.setState({ showDeleteUserDialog: false }) }}
            onDelete={() => this.deleteUser()}
          >
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_userid_row_titlescol_userid`}>
                  {translate('user.user_id')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_userid_row_valuescol_userid`}>
                  {user.BETAUSER}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_username_row_titlescol_username`}>
                  {translate('user.username')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_username_row_valuescol_username`}>
                  {user.USERNAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_lastlogin_row_titlescol_lastlogin`}>
                  {translate('general.last_login')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_lastlogin_row_valuescol_lastlogin`}>
                  {DateUtils.getDate(datemask, user.LLOGDATE, user.LLOGTIME.substring(0, 8))}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}

        {/* delete user profile dialog */}
        {showDeleteUserProfileDialog && (
          <DeleteDialog
            id={`${id}_deleteuserprofiledialog`}
            title={translate('usermanagement.delete_userprofile')}
            question={translate('usermanagement.question_delete_userprofile')}
            visible={showDeleteUserProfileDialog}
            onClose={() => { this.setState({ showDeleteUserProfileDialog: false, userProfileLoadedForRemoval: false }) }}
            onDelete={() => this.deleteUserProfile()}
          >
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_userid_row_titlescol_userid`}>
                  {translate('user.user_id')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_userid_row_valuescol_userid`}>
                  {user.BETAUSER}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_username_row_titlescol_username`}>
                  {translate('user.username')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_username_row_valuescol_username`}>
                  {user.USERNAME}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_USERMANAGEMENT_USERS }}
          />
        )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if users not exist in redux
            users ? (
              // show empty result if there are no users after searching
              data ? (
                <DataTable
                  loading={loading}
                  id={id}
                  header={translatedHeaders}
                  data={this.getViewData()}
                  cleanData={data}
                  selectable={true}
                  createActionButtons={this.createActionButtons}
                  createTableRowAction={this.handleOnModifyUser}
                  columnSortDefs={this.getColumnSortDefs(this.getViewData(), translatedHeaders)}
                  additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                  fillPage={fillPage}
                  translate={key => translate(key)}
                  language={lang}
                  datemask={datemask}
                  keepPagination={keepPagination}
                />
              )
                : (
                  <EmptyResult
                    description={translate('emptyresult.no_users_result')}
                    id={`${id}_emptyresult`}
                    link={translate('emptyresult.create_user_link')}
                    onClick={this.handleOnAddEntry}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )}
        </ResultContainer>
      </>
    )
  }
}

const BETAUSER = 'BETAUSER'
const USERNAME = 'USERNAME'
const LLOGDATE = 'LLOGDATE'
const LLOGTIME = 'LLOGTIME'
const ADMIN = 'ADMIN'
const CUSER = 'CUSER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const USRREVOK = 'USRREVOK'
const FORCEPWD = 'FORCEPWD'
const CKPWDEXT = 'CKPWDEXT'
const PWDCINTV = 'PWDCINTV'
const PWDCDATE = 'PWDCDATE'
const PWDCTIME = 'PWDCTIME'
const LASTLOGIN = 'LASTLOGIN'

SearchResultUsers.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    users: state.user.users,
    user: state.user.user,
    keepPagination: state.user.keepPagination,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    userprofile: state.userprofile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUser: (userid, callback) => {
      UserActions.getUser(userid, callback)(dispatch)
    },
    getUsers: (userid, username, adminOnly, externalAuthOnly, revokedOnly) => {
      UserActions.getUsers(userid, username, adminOnly, externalAuthOnly, revokedOnly)(dispatch)
    },
    deleteUser: (userid, username, callback) => {
      UserActions.deleteUser(userid, username, callback)(dispatch)
    },
    getGroupAssignments: (userid, successCallback, errorCallback) => {
      UserGroupAssignmentActions.getUserAssignments(userid, successCallback, errorCallback)(dispatch)
    },
    removeAllGroups: () => UserGroupAssignmentActions.removeAllGroups()(dispatch),
    getSecurityprofileAssignments: (userid, callback) => {
      UserSecurityAssignmentActions.getUsersSecurityProfiles(userid, callback)(dispatch)
    },
    getUserProfile: (userid, callback) => {
      UserProfileActions.getUserProfile(userid, callback)(dispatch)
    },
    deleteUserProfile: (userid, callback) => {
      UserProfileActions.deleteUserProfile(userid, callback)(dispatch)
    },
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultUsers)