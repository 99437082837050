import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as AssignmentUtils from 'utils/AssignmentUtils'
import * as DateUtils from 'utils/DateUtils'
import * as UserUtils from 'utils/UserUtils'

// components
import {
  Button, Column, Dropdown, Icon, Input, Modal as ModalComponent, NumericSpinner, Row, Switch, Tab, Tabs, Toggle, Tooltip
} from 'BetaUX2Web-Components/src/'
import Datepicker from '../../datepicker/Datepicker'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import * as IndexDocumentAssignmentActions from 'redux/actions/IndexDocumentAssignmentActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import './CreateIndexDocumentAssignmentDialog.scss'

// Redux
import moment from 'moment'
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'

const {Modal, Main, Header, Footer} = ModalComponent

class CreateIndexDocumentAssignment extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    generalTab: {
      showFormExtensionReportSelectorDialog: false,
      showIndexIdSeletorDialog: false,
      form: {
        value: '',
        error: ''
      },
      extension: '',
      report: '',
      dateFormat: 0,
      dateMask: 0,
      timeString: '',
      lcTime: '',
      decimalMark: 0,
      indexID: {
        value: '',
        error: ''
      },
      dataFormat: '',
      identifier: '',
      variant: 0,
      validFrom: {
        value: moment().format(this.props.datemask),
        error: ''
      },
      validTo: {
        value: '',
        error: ''
      },
      available: 0,
      ignoreIndexValueWithWrongDataFormat: false,
      indexValuesAreRequired: false,
      debugMode: false,
      securityCheck: false
    },
    sourceTab: {
      determineIndexValuesFrom: 0,
      documentVariable: 0,
      tleName: {
        value: '',
        error: ''
      },
      indexValuesFrom: 0,
      searchPatternDocumentTextBrowser: {
        value: '',
        error: ''
      },
      searchPatternPJL: {
        value: '',
        error: ''
      },
      fromLine: 0,
      toLine: 0,
      fromColumn: 0,
      toColumn: 0,
      detectMatches: 2,
      hitRange: 0
    },
    indexValuesTab: {
      fromColumn: 0,
      relativeTo: 0,
      inLengthOf: 1,
      maximallyUpToEndTag: '',
      alignment: 1, // Align to right = default
      rule: '',
      substituteValues1: '',
      withValues1: '',
      substituteValues2: '',
      withValues2: '',
      substituteValues3: '',
      withValues3: '',
      substituteValues4: '',
      withValues4: '',
    },
    conditionsTab: {
      extractSearchPattern: '',
      extractFromLine: 0,
      extractToLine: 0,
      extractFromColumn: 0,
      extractToColumn: 0,
      ignoreSearchPattern: '',
      ignoreFromLine: 0,
      ignoreToLine: 0,
      ignoreFromColumn: 0,
      ignoreToColumn: 0,
    }
  }

  formInput = React.createRef()
  indexInput = React.createRef()
  validFromInput = React.createRef()
  validToInput = React.createRef()
  searchPatternDocumentTextBrowserInput = React.createRef()
  searchPatternPJLInput = React.createRef()
  tleNameInput = React.createRef()

  /**
   * @description Sets the initial focus.
   */
  componentDidMount() {
    this.formInput.current.focus()
  }

  /**
   * @description Validates the form.
   */
  validateForm = () => {
    const { generalTab } = this.state
    if (generalTab.form.value !== '') {
      return {}
    }
    return {
      form: {
        ...this.state.generalTab.form,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the index id.
   */
  validateIndexID = () => {
    const { generalTab } = this.state
    if (generalTab.indexID.value !== '') {
      return {}
    }
    return {
      indexID: {
        ...this.state.generalTab.indexID,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the valid from.
   */
  validateValidFromDate = () => {
    const { generalTab } = this.state
    const { datemask } = this.props
    if (generalTab.validFrom.value !== '') {
      if (DateUtils.isDate(generalTab.validFrom.value, datemask)) {
        return {}
      }
    }
    return {
      validFrom: {
        ...this.state.generalTab.validFrom,
        error: 'general.please_enter_a_valid_date'
      }
    }
  }

  /**
   * @description Validates the valid from.
   */
  validateValidToDate = () => {
    const { generalTab } = this.state
    const { datemask } = this.props
    if (generalTab.validTo.value !== '') {
      if (DateUtils.isDate(generalTab.validTo.value, datemask)) {
        return {}
      }
    }
    return {
      validTo: {
        ...this.state.generalTab.validTo,
        error: 'general.please_enter_a_valid_date'
      }
    }
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateForm(),
      ...this.validateIndexID(),
      ...this.validateValidFromDate(),
      ...this.validateValidToDate()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ generalTab: { ...this.state.generalTab, ...validatorResult } }, () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input of the general tab which has an error.
   */
  handleFocusGeneralTab = () => {
    const { generalTab } = this.state
    const requiredInputs = [
      { inputRef: this.formInput, error: generalTab.form.error },
      { inputRef: this.indexInput, error: generalTab.indexID.error },
      { inputRef: this.validFromInput, error: generalTab.validFrom.error },
      { inputRef: this.validToInput, error: generalTab.validTo.error }
    ]
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].error !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  /**
   * @description Validates the document text browser search pattern.
   */
  validateSearchPatternDocumentTextBrowser = () => {
    const { sourceTab } = this.state
    if (sourceTab.searchPatternDocumentTextBrowser.value !== '') {
      return {}
    }
    return {
      searchPatternDocumentTextBrowser: {
        ...this.state.sourceTab.searchPatternDocumentTextBrowser,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the tle name.
   */
  validateTleName = () => {
    const { sourceTab } = this.state
    if (sourceTab.tleName.value !== '') {
      return {}
    }
    return {
      tleName: {
        ...this.state.sourceTab.tleName,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the pjl statement search pattern.
   */
  validateSearchPatternPJL = () => {
    const { sourceTab } = this.state
    if (sourceTab.searchPatternPJL.value !== '') {
      return {}
    }
    return {
      searchPatternPJL: {
        ...this.state.sourceTab.searchPatternPJL,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the source tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateSourceTab = () => {
    const { sourceTab } = this.state

    let validatorResult = {}
    let focusRef
    if (sourceTab.determineIndexValuesFrom === 0) {
      validatorResult = this.validateSearchPatternDocumentTextBrowser()
      focusRef = this.searchPatternDocumentTextBrowserInput
    } else if (sourceTab.determineIndexValuesFrom === 2) {
      validatorResult = this.validateTleName()
      focusRef = this.tleNameInput
    } else if (sourceTab.determineIndexValuesFrom === 3) {
      validatorResult = this.validateSearchPatternPJL()
      focusRef = this.searchPatternPJLInput
    }

    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ sourceTab: { ...this.state.sourceTab, ...validatorResult } }, () => {
        if (focusRef.current) {
          focusRef.current.focus()
        }
      })
    }
    return errors === 0
  }

  /**
   * @description Gets the tab indices as array which has errors.
   * @returns {Array} The tab indices which has errors.
   */
  handleErrorTabs = () => {
    const { generalTab, sourceTab } = this.state
    let buffer = []
    // possible general tab errors
    const isFormError = generalTab.form.error !== ''
    const isIndexIDError = generalTab.indexID.error !== ''
    const isValidFromError = generalTab.validFrom.error !== ''
    const isValidToError = generalTab.validTo.error !== ''
    // possible source tab errors
    const isSearchPatternDocumentTextBrowserError = sourceTab.searchPatternDocumentTextBrowser.error !== ''
    const isSearchPatternPJLError = sourceTab.searchPatternPJL.error !== ''
    const isTleNameError = sourceTab.tleName.error !== ''

    if (isFormError || isIndexIDError || isValidFromError || isValidToError) {
      buffer.push(0)
    }
    if (isSearchPatternDocumentTextBrowserError || isSearchPatternPJLError || isTleNameError) {
      buffer.push(1)
    }
    return buffer
  }

  /**
   * @description Handles input changes on general tab.
   * @param {String} key The key of the input in state.
   * @param {String} val The new value.
   * @param {Boolean} isRequired Flag if the input is required.
   */
  handleGeneralTabInputChanged = (key, val, isRequired) => {
    if (isRequired) {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          [key]: {
            value: val,
            error: ''
          }
        }
      })
    } else {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          [key]: val
        }
      })
    }
  }

  /**
   * @description Requests the document definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  onOpenFormExtReportSelector = () => {
    const { generalTab } = this.state
    const callback = () => {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          showFormExtensionReportSelectorDialog: true
        }
      })
    }

    this.props.getDocumentDefinitions(
      ['FORM', 'EXT', 'REPORT'],
      generalTab.form.value,
      generalTab.extension,
      generalTab.report,
      callback
    )
  }

  /**
   * @description Requests the index definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  onOpenIndexIdDialog = () => {
    const { generalTab } = this.state
    const callback = () => {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          showIndexIdSeletorDialog: true
        }
      })
    }

    this.props.getIndexDefinitions(
      ['IXINAME', 'IXDTYPE', 'IXENAME'],
      generalTab.indexID.value,
      callback
    )
  }

  /**
   * @description Gets the datemask items.
   * @returns {Array} The datemask items.
   */
  getDatemaskItems = () => {
    return [
      'DD/MM/YYYY', 'DD/MM/YY', 'MM/DD/YYYY', 'MM/DD/YY', 'DD.MM.YYYY',
      'DD.MM.YY', 'YYYY-MM-DD', 'YY-MM-DD', 'YYYY.DDD', 'YY.DDD'
    ]
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { id, lang, datemask } = this.props
    const { generalTab } = this.state
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_general_tab_select_document`}>{translate('general.select_document')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_general_tab_form`}
              value={generalTab.form.value}
              title={translate('general.form')}
              maxLength={8}
              onInputChanged={value => this.handleGeneralTabInputChanged('form', value, true)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFormExtReportSelector(),
              }
              }
              required={`${translate('general.required_field')}`}
              error={generalTab.form.error && translate(generalTab.form.error)}
              ref={this.formInput}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateForm() } }))}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_general_tab_extension`}
              value={generalTab.extension}
              title={translate('general.extension')}
              maxLength={16}
              onInputChanged={value => this.handleGeneralTabInputChanged('extension', value)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFormExtReportSelector(),
              }
              }
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_general_tab_report`}
              value={generalTab.report}
              title={translate('general.report')}
              maxLength={16}
              onInputChanged={value => this.handleGeneralTabInputChanged('report', value)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFormExtReportSelector(),
              }
              }
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_general_tab_date_format`}
              title={translate('general.dateformat')}
              items={[
                translate('general.datemask'),
                translate('general.manually')
              ]}
              onClick={index => this.handleGeneralTabInputChanged('dateFormat', index)}
              activeIndex={generalTab.dateFormat}
            />
          </Column>
          {
            generalTab.dateFormat === 1
              ? <>
                <Column colMD={3}>
                  <Input
                    id={`${id}_general_tab_time_string`}
                    value={generalTab.timeString}
                    maxLength={32}
                    onInputChanged={value => this.handleGeneralTabInputChanged('timeString', value)}
                    title={translate('general.time_string')}
                  />
                </Column>
                <Column colMD={3} className={'bux_flex_row'}>
                  <Input
                    id={`${id}_general_tab_lc_time`}
                    value={generalTab.lcTime}
                    maxLength={32}
                    onInputChanged={value => this.handleGeneralTabInputChanged('lcTime', value)}
                    title={translate('general.lc_time')}
                  />
                  <Tooltip
                    event={'click'}
                    className={'bux_place_help_icon'}
                    id={`${id}_general_tab_lc_time`}
                    tooltipContent={[
                      <label>{translate('assignment.index_document_sample_of_a_time_string_label')}</label>,
                      <p>{translate('assignment.index_document_sample_of_a_time_string_value')}</p>,
                      <br></br>,
                      <label>{translate('assignment.index_document_samples_of_lc_time')}</label>,
                      <p>{translate('assignment.index_document_sample_of_lcs_time1')}</p>,
                      <p>{translate('assignment.index_document_sample_of_lcs_time2')}</p>,
                      <p>{translate('assignment.index_document_sample_of_lcs_time3')}</p>,
                      <br></br>,
                      <label>{translate('assignment.index_document_variables')}</label>,
                      <p>{translate('assignment.index_document_variables1')}</p>,
                      <p>{translate('assignment.index_document_variables2')}</p>,
                      <p>{translate('assignment.index_document_variables3')}</p>,
                      <p>{translate('assignment.index_document_variables4')}</p>,
                      <p>{translate('assignment.index_document_variables5')}</p>,
                      <p>{translate('assignment.index_document_variables6')}</p>,
                      <p>{translate('assignment.index_document_variables7')}</p>,
                      <p>{translate('assignment.index_document_variables8')}</p>,
                    ]}>
                    <Icon
                      id={`${id}_general_tab_lc_time_tooltip`}
                      name={'info_filled'}
                    />
                  </Tooltip>
                </Column>
              </>
              : <Column colMD={3}>
                <Dropdown
                  id={`${id}_general_tab_datemask`}
                  title={translate('general.datemask')}
                  items={this.getDatemaskItems()}
                  activeIndex={generalTab.dateMask}
                  onChange={value => this.handleGeneralTabInputChanged('dateMask', value)}
                />
              </Column>
          }
        </Row>
        {
          generalTab.dateFormat === 1 &&
          <Row>
            <Column colMD={6}>
              <Switch
                id={`${id}_general_tab_decimal_mark`}
                title={translate('general.decimal_mark')}
                items={[
                  translate('general.comma'),
                  translate('general.point')
                ]}
                onClick={index => this.handleGeneralTabInputChanged('decimalMark', index)}
                activeIndex={generalTab.decimalMark}
              />
            </Column>
          </Row>
        }
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <label id={`${id}_general_tab_select_index`}>{translate('general.select_index')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_general_tab_index_id`}
              value={generalTab.indexID.value}
              title={translate('definition.index_id')}
              maxLength={16}
              onInputChanged={value => this.handleGeneralTabInputChanged('indexID', value, true)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenIndexIdDialog(),
              }
              }
              error={generalTab.indexID.error && translate(generalTab.indexID.error)}
              required={`${translate('general.required_field')}`}
              ref={this.indexInput}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateIndexID() } }))}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_general_tab_data_format`}
              value={generalTab.dataFormat}
              title={translate('general.data_format')}
              disabled
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_general_tab_identifier`}
              value={generalTab.identifier}
              title={translate('general.identifier')}
              disabled
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_general_tab_variant`}
              title={translate('general.variant')}
              onChange={val => this.handleGeneralTabInputChanged('variant', val)}
              value={generalTab.variant}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <Datepicker
              id={`${id}_general_tab_valid_from`}
              title={translate('general.valid_from')}
              value={generalTab.validFrom.value}
              onChange={date => this.handleGeneralTabInputChanged('validFrom', date, true)}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateValidFromDate() } }))}
              dateFormat={datemask}
              required={`${translate('general.required_field')}`}
              error={generalTab.validFrom.error && translate(generalTab.validFrom.error)}
              focusRef={this.validFromInput}
              bothDates
              language={lang}
              parentContainer={`${id}_tilecontainer`}
            />
          </Column>
          <Column colMD={3}>
            <Datepicker
              id={`${id}_general_tab_valid_to`}
              title={translate('general.valid_to')}
              value={generalTab.validTo.value}
              onChange={date => this.handleGeneralTabInputChanged('validTo', date, true)}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateValidToDate() } }))}
              dateFormat={datemask}
              required={`${translate('general.required_field')}`}
              error={generalTab.validTo.error && translate(generalTab.validTo.error)}
              focusRef={this.validToInput}
              bothDates
              language={lang}
              parentContainer={`${id}_tilecontainer`}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_general_tab_available`}
              title={translate('general.available')}
              items={[
                translate('general.local'),
                translate('general.global')
              ]}
              onClick={index => this.handleGeneralTabInputChanged('available', index)}
              activeIndex={generalTab.available}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Toggle
              id={`${id}_general_tab_ignore_index_value_with_wrong_data_format`}
              onCheck={val => this.handleGeneralTabInputChanged('ignoreIndexValueWithWrongDataFormat', val)}
              value={generalTab.ignoreIndexValueWithWrongDataFormat}
              title={translate('assignment.index_document_ignore_index_value_with_wrong_data_format')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
          <Column colMD={6}>
            <Toggle
              id={`${id}_general_tab_index_values_are_required`}
              onCheck={val => this.handleGeneralTabInputChanged('indexValuesAreRequired', val)}
              value={generalTab.indexValuesAreRequired}
              title={translate('assignment.index_document_index_values_are_required')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Toggle
              id={`${id}_general_tab_debug_mode`}
              onCheck={val => this.handleGeneralTabInputChanged('debugMode', val)}
              value={generalTab.debugMode}
              title={translate('assignment.index_document_debug_mode')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
          <Column colMD={6}>
            <Toggle
              id={`${id}_general_tab_security_check`}
              onCheck={val => this.handleGeneralTabInputChanged('securityCheck', val)}
              value={generalTab.securityCheck}
              title={translate('assignment.index_document_security_check')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Resets the source tab errors.
   */
  resetSourceTabErrors = () => {
    this.setState({
      sourceTab: {
        ...this.state.sourceTab,
        tleName: {
          ...this.state.sourceTab.tleName,
          error: ''
        },
        searchPatternDocumentTextBrowser: {
          ...this.state.sourceTab.searchPatternDocumentTextBrowser,
          error: ''
        },
        searchPatternPJL: {
          ...this.state.sourceTab.searchPatternPJL,
          error: ''
        },
      }
    })
  }

  /**
   * @description Handles the input changes on source tab.
   * @param {String} key The key of the input in state.
   * @param {String} val The new value.
   * @param {Boolean} isRequired Flag if the input is required.
   */
  handleSourceTabInputChanged = (key, val, isRequired) => {
    if (isRequired) {
      this.setState({
        sourceTab: {
          ...this.state.sourceTab,
          [key]: {
            value: val,
            error: ''
          }
        }
      })
    } else {
      this.setState({
        sourceTab: {
          ...this.state.sourceTab,
          [key]: val
        }
      }, () => {
        if (key === 'determineIndexValuesFrom') {
          this.resetSourceTabErrors()
        }
      })
    }
  }

  /**
   * @description Renders the additional document text browser content.
   */
  renderAdditionalDocumentTextBrowserContent = () => {
    const { id } = this.props
    const { sourceTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={12} className={'bux_flex_row'}>
            <Input
              id={`${id}_source_tab_search_pattern`}
              value={sourceTab.searchPatternDocumentTextBrowser.value}
              title={translate('definition.filter_argument_search_pattern')}
              onInputChanged={value => this.handleSourceTabInputChanged('searchPatternDocumentTextBrowser', value, true)}
              maxLength={240}
              required={`${translate('general.required_field')}`}
              error={sourceTab.searchPatternDocumentTextBrowser.error && translate(sourceTab.searchPatternDocumentTextBrowser.error)}
              ref={this.searchPatternDocumentTextBrowserInput}
              onBlur={() => this.setState({ sourceTab: { ...this.state.sourceTab, ...this.validateSearchPatternDocumentTextBrowser() } })}
            />
            <Tooltip
              id={`${id}_assignment_tooltip`}
              event={'click'}
              className={'bux_place_help_icon'}
              tooltipContent={[
                <label>{translate('assignment.index_document_sample_of_a_search_pattern_label')}</label>,
                <p>{translate('assignment.index_document_sample_of_a_search_pattern_value')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_strings_beginning_with_abc')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_search_pattern_q')}</p>,
                <p>{translate('assignment.index_document_search_pattern_x')}</p>,
                <p>{translate('assignment.index_document_search_pattern_z')}</p>,
                <p>{translate('assignment.index_document_search_pattern_l')}</p>,
                <p>{translate('assignment.index_document_search_pattern_b')}</p>,
                <p>{translate('assignment.index_document_search_pattern_a')}</p>,
                <p>{translate('assignment.index_document_search_pattern_u')}</p>,
                <p>{translate('assignment.index_document_search_pattern_s')}</p>,
                <p>{translate('assignment.index_document_search_pattern_*')}</p>,
              ]}>
              <Icon
                id={`${id}_source_tab_search_pattern_tooltip`}
                name={'info_filled'}
              />
            </Tooltip>
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_general_tab_search_window`}>{translate('general.search_window')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_source_tab_from_line`}
              title={translate('general.from_line')}
              onChange={val => this.handleSourceTabInputChanged('fromLine', val)}
              value={sourceTab.fromLine}
              min={0}
              max={32767}
              steps={1}
              disabled={sourceTab.indexValuesFrom === 2}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_source_tab_to_line`}
              title={translate('general.to_line')}
              onChange={val => this.handleSourceTabInputChanged('toLine', val)}
              value={sourceTab.toLine}
              min={0}
              max={32767}
              steps={1}
              disabled={sourceTab.indexValuesFrom === 2}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_source_tab_from_column`}
              title={translate('general.from_column')}
              onChange={val => this.handleSourceTabInputChanged('fromColumn', val)}
              value={sourceTab.fromColumn}
              min={0}
              max={32767}
              steps={1}
              disabled={sourceTab.indexValuesFrom === 2}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_source_tab_to_column`}
              title={translate('general.to_column')}
              onChange={val => this.handleSourceTabInputChanged('toColumn', val)}
              value={sourceTab.toColumn}
              min={0}
              max={32767}
              steps={1}
              disabled={sourceTab.indexValuesFrom === 2}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_source_tab_detect_matches`}
              title={translate('general.detect_matches')}
              items={AssignmentUtils.DETECT_MATCHES.map(d => translate(d.translationKey))}
              onClick={index => this.handleSourceTabInputChanged('detectMatches', index)}
              activeIndex={sourceTab.detectMatches}
              maxPerRow={3}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Dropdown
              id={`${id}_source_tab_hit_range`}
              title={translate('general.hit_range')}
              items={AssignmentUtils.HIT_RANGE.map(d => translate(d.translationKey))}
              activeIndex={sourceTab.hitRange}
              onChange={value => this.handleSourceTabInputChanged('hitRange', value)}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the additional acif index content.
   */
  renderAdditionalAcifIndexContent = () => {
    const { sourceTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_source_tab_tle_name`}
              value={sourceTab.tleName.value}
              title={translate('definition.searchargument_tle_name')}
              onInputChanged={value => this.handleSourceTabInputChanged('tleName', value, true)}
              maxLength={64}
              required={`${translate('general.required_field')}`}
              error={sourceTab.tleName.error && translate(sourceTab.tleName.error)}
              ref={this.tleNameInput}
              onBlur={() => this.setState({ sourceTab: { ...this.state.sourceTab, ...this.validateTleName() } })}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_source_tab_hit_Range`}
              title={translate('general.hit_range')}
              items={[
                translate('general.hit_page'),
                translate('general.include_pages_after')
              ]}
              onClick={index => this.handleSourceTabInputChanged('hitRange', index)}
              activeIndex={sourceTab.hitRange}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the additional pjl statement content.
   */
  renderAdditionalPjlStatementContent = () => {
    const { sourceTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={12} className={'bux_flex_row'}>
            <Input
              id={`${id}_source_tab_search_pattern`}
              value={sourceTab.searchPatternPJL.value}
              title={translate('definition.filter_argument_search_pattern')}
              onInputChanged={value => this.handleSourceTabInputChanged('searchPatternPJL', value, true)}
              maxLength={240}
              required={`${translate('general.required_field')}`}
              error={sourceTab.searchPatternPJL.error && translate(sourceTab.searchPatternPJL.error)}
              ref={this.searchPatternPJLInput}
              onBlur={() => this.setState({ sourceTab: { ...this.state.sourceTab, ...this.validateSearchPatternPJL() } })}
            />
            <Tooltip
              event={'click'}
              className={'bux_place_help_icon'}
              id={`${id}_source_tab_search_pattern_tooltip`}
              tooltipContent={[
                <label>{translate('assignment.index_document_sample_of_a_time_string_label')}</label>,
                <p>{translate('assignment.index_document_sample_of_a_time_string_value')}</p>,
                <br></br>,
                <label>{translate('assignment.index_document_samples_of_lc_time')}</label>,
                <p>{translate('assignment.index_document_sample_of_lcs_time1')}</p>,
                <p>{translate('assignment.index_document_sample_of_lcs_time2')}</p>,
                <p>{translate('assignment.index_document_sample_of_lcs_time3')}</p>,
                <br></br>,
                <label>{translate('assignment.index_document_variables')}</label>,
                <p>{translate('assignment.index_document_variables1')}</p>,
                <p>{translate('assignment.index_document_variables2')}</p>,
                <p>{translate('assignment.index_document_variables3')}</p>,
                <p>{translate('assignment.index_document_variables4')}</p>,
                <p>{translate('assignment.index_document_variables5')}</p>,
                <p>{translate('assignment.index_document_variables6')}</p>,
                <p>{translate('assignment.index_document_variables7')}</p>,
                <p>{translate('assignment.index_document_variables8')}</p>,
              ]}>
              <Icon
                id={`${id}_source_tab_search_pattern_tooltip`}
                name={'info_filled'}
              />
            </Tooltip>
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_source_tab_hit_Range`}
              title={translate('general.hit_range')}
              items={[
                translate('general.hit_page'),
                translate('general.include_pages_after')
              ]}
              onClick={index => this.handleSourceTabInputChanged('hitRange', index)}
              activeIndex={sourceTab.hitRange}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Gets the document variable items.
   * @returns {Array} The document variable items.
   */
  getDocumentVariableItems = () => {
    return [
      '&DOCUSR1', '&DOCUSR2', '&DOCUSR3', '&DOCUSR4', '&DOCUSR5',
      '&DOCUSR6', '&DOCUSR7', '&DOCUSR8', '&EXTENSION', '&FILENAME',
      '&FORM', '&JOBNAME', '&ODATE', '&WREPORT'
    ]
  }

  /**
   * @description Renders the source tab.
   */
  renderSourceTab = () => {
    const { id } = this.props
    const { sourceTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_source_tab_determine_index_values_from`}
              title={translate('assignment.index_document_determine_index_values_from')}
              items={AssignmentUtils.DETERMINE_INDEX_VALUES_FROM.map(d => translate(d.translationKey))}
              activeIndex={sourceTab.determineIndexValuesFrom}
              onChange={value => this.handleSourceTabInputChanged('determineIndexValuesFrom', value)}
            />
          </Column>
          {
            sourceTab.determineIndexValuesFrom === 0 &&
            <Column colMD={6} className={'bux_pr8'}>
              <Switch
                id={`${id}_source_tab_index_values_from`}
                title={translate('assignment.index_document_index_values_from')}
                items={[
                  translate('general.document'),
                  translate('assignment.index_document_side_file'),
                  translate('assignment.index_document_afpds_nop_record')
                ]}
                onClick={index => this.handleSourceTabInputChanged('indexValuesFrom', index)}
                activeIndex={sourceTab.indexValuesFrom}
                maxPerRow={3}
              />
            </Column>
          }
          {
            sourceTab.determineIndexValuesFrom === 1 &&
            <Column colMD={3}>
              <Dropdown
                id={`${id}_source_tab_document_variable`}
                title={translate('assignment.index_document_document_variable')}
                items={this.getDocumentVariableItems()}
                activeIndex={sourceTab.documentVariable}
                onChange={value => this.handleSourceTabInputChanged('documentVariable', value)}
              />
            </Column>
          }
        </Row>
        {
          {
            0: this.renderAdditionalDocumentTextBrowserContent(),
            2: this.renderAdditionalAcifIndexContent(),
            3: this.renderAdditionalPjlStatementContent()
          }[sourceTab.determineIndexValuesFrom]
        }
      </>
    )
  }

  /**
   * @description Handles the input changes on index values tab.
   * @param {String} key The key of the input in state.
   * @param {*} val The new value.
   */
  handleIndexValuesTabInputChanged = (key, val) => {
    this.setState({
      indexValuesTab: {
        ...this.state.indexValuesTab,
        [key]: val
      }
    })
  }

  /**
   * @description Renders the index values tab.
   */
  renderIndexValuesTab = () => {
    const { id } = this.props
    const { indexValuesTab } = this.state
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_index_values_tab_extract_index_value`}>{translate('assignment.index_document_extract_index_value')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_index_values_tab_from_column`}
              title={translate('general.from_column')}
              onChange={val => this.handleIndexValuesTabInputChanged('fromColumn', val)}
              value={indexValuesTab.fromColumn}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={9}>
            <Switch
              id={`${id}_index_values_tab_relative_to`}
              title={translate('general.relative_to')}
              items={AssignmentUtils.RELATIVE_TO.map(d => translate(d.translationKey))}
              onClick={index => this.handleIndexValuesTabInputChanged('relativeTo', index)}
              activeIndex={indexValuesTab.relativeTo}
              maxPerRow={3}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_index_values_tab_in_length_of`}
              title={translate('general.in_length_of')}
              onChange={val => this.handleIndexValuesTabInputChanged('inLengthOf', val)}
              value={indexValuesTab.inLengthOf}
              min={1}
              max={64}
              steps={1}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_source_tab_search_pattern`}
              value={indexValuesTab.maximallyUpToEndTag}
              title={translate('assignment.index_document_maximally_up_to_end_tag')}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('maximallyUpToEndTag', value)}
              maxLength={32}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_index_values_tab_format_index_value`}>{translate('assignment.index_document_format_index_value')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Switch
              id={`${id}_index_values_tab_alignment`}
              title={translate('general.alignment')}
              items={[
                translate('general.align_to_left'),
                translate('general.align_to_right')
              ]}
              onClick={index => this.handleIndexValuesTabInputChanged('alignment', index)}
              activeIndex={indexValuesTab.alignment}
            />
          </Column>
          <Column colMD={9} className={'bux_flex_row'}>
            <Input
              id={`${id}_index_values_tab_rule`}
              value={indexValuesTab.rule}
              title={translate('general.rule')}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('rule', value)}
              maxLength={64}
            />
            <Tooltip
              id={`${id}_rule_tooltip`}
              event={'click'}
              className={'bux_place_help_icon'}
              tooltipContent={[
                <label>{translate('assignment.index_document_rule_example_label')}</label>,
                <p>{translate('assignment.index_document_rule_example_value')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_rule_example_substitutes1')}</p>,
                <p>{translate('assignment.index_document_rule_example_substitutes2')}</p>,
              ]}>
              <Icon
                id={`${id}_source_tab_search_pattern_tooltip`}
                name={'info_filled'}
              />
            </Tooltip>
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_index_values_tab_substitute_characters_before_saving_hits`}>{translate('assignment.index_document_substitute_characters_before_saving_hits')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_substitute_values1`}
              value={indexValuesTab.substituteValues1}
              title={translate('assignment.index_document_substitude_values')}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('substituteValues1', value)}
              maxLength={32}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_with_values1`}
              value={indexValuesTab.withValues1}
              title={translate('assignment.index_document_with_values')}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('withValues1', value)}
              maxLength={32}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_substitute_values2`}
              value={indexValuesTab.substituteValues2}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('substituteValues2', value)}
              maxLength={32}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_with_values2`}
              value={indexValuesTab.withValues2}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('withValues2', value)}
              maxLength={32}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_substitute_values3`}
              value={indexValuesTab.substituteValues3}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('substituteValues3', value)}
              maxLength={32}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_with_values3`}
              value={indexValuesTab.withValues3}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('withValues3', value)}
              maxLength={32}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_substitute_values4`}
              value={indexValuesTab.substituteValues4}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('substituteValues4', value)}
              maxLength={32}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_with_values4`}
              value={indexValuesTab.withValues4}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('withValues4', value)}
              maxLength={32}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the input changes on conditions tab.
   * @param {String} key The key of the input in state.
   * @param {*} val The new value.
   */
  handleConditionsTabInputChanged = (key, val) => {
    this.setState({
      conditionsTab: {
        ...this.state.conditionsTab,
        [key]: val
      }
    })
  }

  /**
   * @description Renders the conditions tab.
   */
  renderConditionsTab = () => {
    const { id } = this.props
    const { conditionsTab } = this.state
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_conditions_tab_extract_index_values_only_from_pages`}>{translate('assignment.index_document_extract_index_values_only_from_pages')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={12} className={'bux_flex_row'}>
            <Input
              id={`${id}_conditions_tab_extract_search_pattern`}
              value={conditionsTab.extractSearchPattern}
              title={translate('definition.filter_argument_search_pattern')}
              onInputChanged={value => this.handleConditionsTabInputChanged('extractSearchPattern', value)}
              maxLength={64}
            />
            <Tooltip
              event={'click'}
              className={'bux_place_help_icon'}
              id={`${id}_conditions_tab_extract_search_pattern`}
              tooltipContent={[
                <label>{translate('assignment.index_document_sample_of_a_search_pattern_label')}</label>,
                <p>{translate('assignment.index_document_sample_of_a_search_pattern_value')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_strings_beginning_with_abc')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_search_pattern_q')}</p>,
                <p>{translate('assignment.index_document_search_pattern_x')}</p>,
                <p>{translate('assignment.index_document_search_pattern_z')}</p>,
                <p>{translate('assignment.index_document_search_pattern_l')}</p>,
                <p>{translate('assignment.index_document_search_pattern_b')}</p>,
                <p>{translate('assignment.index_document_search_pattern_a')}</p>,
                <p>{translate('assignment.index_document_search_pattern_u')}</p>,
                <p>{translate('assignment.index_document_search_pattern_s')}</p>,
                <p>{translate('assignment.index_document_search_pattern_*')}</p>,
              ]}>
              <Icon
                id={`${id}_conditions_tab_extract_search_pattern_tooltip`}
                name={'info_filled'}
              />
            </Tooltip>
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_conditions_tab_extract_search_window`}>{translate('general.search_window')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_extract_from_line`}
              title={translate('general.from_line')}
              onChange={val => this.handleConditionsTabInputChanged('extractFromLine', val)}
              value={conditionsTab.extractFromLine}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_extract_to_line`}
              title={translate('general.to_line')}
              onChange={val => this.handleConditionsTabInputChanged('extractToLine', val)}
              value={conditionsTab.extractToLine}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_extract_from_column`}
              title={translate('general.from_column')}
              onChange={val => this.handleConditionsTabInputChanged('extractFromColumn', val)}
              value={conditionsTab.extractFromColumn}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_extract_to_column`}
              title={translate('general.to_column')}
              onChange={val => this.handleConditionsTabInputChanged('extractToColumn', val)}
              value={conditionsTab.extractToColumn}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_conditions_tab_ignore_index_values_from_pages`}>{translate('assignment.index_document_ignore_index_values_from_pages')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={12} className={'bux_flex_row'}>
            <Input
              id={`${id}_conditions_tab_ignore_search_pattern`}
              value={conditionsTab.ignoreSearchPattern}
              title={translate('definition.filter_argument_search_pattern')}
              onInputChanged={value => this.handleConditionsTabInputChanged('ignoreSearchPattern', value)}
              maxLength={64}
            />
            <Tooltip
              event={'click'}
              className={'bux_place_help_icon'}
              id={`${id}_conditions_tab_ignore_search_pattern`}
              tooltipContent={[
                <label>{translate('assignment.index_document_sample_of_a_search_pattern_label')}</label>,
                <p>{translate('assignment.index_document_sample_of_a_search_pattern_value')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_strings_beginning_with_abc')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_search_pattern_q')}</p>,
                <p>{translate('assignment.index_document_search_pattern_x')}</p>,
                <p>{translate('assignment.index_document_search_pattern_z')}</p>,
                <p>{translate('assignment.index_document_search_pattern_l')}</p>,
                <p>{translate('assignment.index_document_search_pattern_b')}</p>,
                <p>{translate('assignment.index_document_search_pattern_a')}</p>,
                <p>{translate('assignment.index_document_search_pattern_u')}</p>,
                <p>{translate('assignment.index_document_search_pattern_s')}</p>,
                <p>{translate('assignment.index_document_search_pattern_*')}</p>,
              ]}>
              <Icon
                id={`${id}_conditions_tab_ignore_search_pattern_tooltip`}
                name={'info_filled'}
              />
            </Tooltip>
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_conditions_tab_ignore_search_window`}>{translate('general.search_window')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_ignore_from_line`}
              title={translate('general.from_line')}
              onChange={val => this.handleConditionsTabInputChanged('ignoreFromLine', val)}
              value={conditionsTab.ignoreFromLine}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_ignore_to_line`}
              title={translate('general.to_line')}
              onChange={val => this.handleConditionsTabInputChanged('ignoreToLine', val)}
              value={conditionsTab.ignoreToLine}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_ignore_from_column`}
              title={translate('general.from_column')}
              onChange={val => this.handleConditionsTabInputChanged('ignoreFromColumn', val)}
              value={conditionsTab.ignoreFromColumn}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_ignore_to_column`}
              title={translate('general.to_column')}
              onChange={val => this.handleConditionsTabInputChanged('ignoreToColumn', val)}
              value={conditionsTab.ignoreToColumn}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the save action.
   * @param {Boolean} saveAsUtf8 Flag if the definition should be saved as utf-8. If false it will be saved as iso-8859-1.
   */
  handleSave = (saveAsUtf8) => {
    const { generalTab, sourceTab, indexValuesTab, conditionsTab } = this.state
    const { datemask } = this.props
    const errorTabs = [
      this.validateGeneralTab(),
      this.validateSourceTab()
    ]
    if (errorTabs.every(d => d)) {
      const indexDocumentAssignmentObj = {
        'FORM': generalTab.form.value,
        'EXT': generalTab.extension,
        'REPORT': generalTab.report,
        'IXINAME': generalTab.indexID.value,
        'IXINSTNR': generalTab.variant,
        'VDATEB': DateUtils.formatDate(moment(generalTab.validFrom.value, datemask), DateUtils.DDMMYYYY_DOT, DateUtils.TIMESHIFT_ADD),
        'VDATEE': DateUtils.formatDate(moment(generalTab.validTo.value, datemask), DateUtils.DDMMYYYY_DOT, DateUtils.TIMESHIFT_ADD),
        'IXSCOPE': generalTab.available === 0 ? 'LOCAL' : 'GLOBAL',
        'IXSKIPF': generalTab.ignoreIndexValueWithWrongDataFormat,
        'IXDEBUG': generalTab.debugMode,
        'IXENODAT': generalTab.indexValuesAreRequired,
        'IXSCHECK': generalTab.securityCheck,
        'IXEXTPOS': indexValuesTab.fromColumn,
        'IXEXTREL': AssignmentUtils.RELATIVE_TO[indexValuesTab.relativeTo].key,
        'IXEXTLEN': indexValuesTab.inLengthOf,
        'IXEMASK': indexValuesTab.maximallyUpToEndTag,
        'IALIGNTR': indexValuesTab.alignment === 1,
        'IXFOMRUL': indexValuesTab.rule,
        'IXRUL1I': indexValuesTab.substituteValues1,
        'IXRUL1O': indexValuesTab.withValues1,
        'IXRUL2I': indexValuesTab.substituteValues2,
        'IXRUL2O': indexValuesTab.withValues2,
        'IXRUL3I': indexValuesTab.substituteValues3,
        'IXRUL3O': indexValuesTab.withValues3,
        'IXRUL4I': indexValuesTab.substituteValues4,
        'IXRUL4O': indexValuesTab.withValues4,
        'IXPMASK': conditionsTab.extractSearchPattern,
        'IXPSLINE': conditionsTab.extractFromLine,
        'IXPELINE': conditionsTab.extractToLine,
        'IXPSCOL': conditionsTab.extractFromColumn,
        'IXPECOL': conditionsTab.extractToColumn,
        'IXXMASK': conditionsTab.ignoreSearchPattern,
        'IXXSLINE': conditionsTab.ignoreFromLine,
        'IXXELINE': conditionsTab.ignoreToLine,
        'IXXSCOL': conditionsTab.ignoreFromColumn,
        'IXXECOL': conditionsTab.ignoreToColumn,
        'SAVEUTF8': saveAsUtf8
      }
      if (generalTab.dateFormat === 1) {
        indexDocumentAssignmentObj.IXUTISTR = true
        indexDocumentAssignmentObj.IXUDTSTR = generalTab.timeString
        indexDocumentAssignmentObj.IXUDTIME = generalTab.lcTime
        indexDocumentAssignmentObj.IDSPOINT = generalTab.decimalMark === 1
      } else {
        indexDocumentAssignmentObj.IXUTISTR = false
        indexDocumentAssignmentObj.IXIDMASK = this.getDatemaskItems()[generalTab.dateMask]
      }
      if (sourceTab.determineIndexValuesFrom === 0) {
        indexDocumentAssignmentObj.IXBASED = AssignmentUtils.DETERMINE_INDEX_VALUES_FROM[sourceTab.determineIndexValuesFrom].key[sourceTab.indexValuesFrom]
        indexDocumentAssignmentObj.IXSMASK = sourceTab.searchPatternDocumentTextBrowser.value
        indexDocumentAssignmentObj.IXSSLINE = sourceTab.fromLine
        indexDocumentAssignmentObj.IXSELINE = sourceTab.toLine
        indexDocumentAssignmentObj.IXSSCOL = sourceTab.fromColumn
        indexDocumentAssignmentObj.IXSECOL = sourceTab.toColumn
        indexDocumentAssignmentObj.IXMATCH = AssignmentUtils.DETECT_MATCHES[sourceTab.detectMatches].key
        indexDocumentAssignmentObj.IXDATASC = AssignmentUtils.HIT_RANGE[sourceTab.hitRange].key
      } else if (sourceTab.determineIndexValuesFrom === 1) {
        indexDocumentAssignmentObj.IXBASED = AssignmentUtils.DETERMINE_INDEX_VALUES_FROM[sourceTab.determineIndexValuesFrom].key
        indexDocumentAssignmentObj.IXSMASK = this.getDocumentVariableItems()[sourceTab.documentVariable]
      } else if (sourceTab.determineIndexValuesFrom === 2) {
        indexDocumentAssignmentObj.IXBASED = AssignmentUtils.DETERMINE_INDEX_VALUES_FROM[sourceTab.determineIndexValuesFrom].key
        indexDocumentAssignmentObj.IXSMASK = sourceTab.tleName.value
        indexDocumentAssignmentObj.IXDATASC = sourceTab.hitRange === 0 ? 'PAGE' : 'START'
      } else {
        indexDocumentAssignmentObj.IXBASED = AssignmentUtils.DETERMINE_INDEX_VALUES_FROM[sourceTab.determineIndexValuesFrom].key
        indexDocumentAssignmentObj.IXSMASK = sourceTab.searchPatternPJL.value
        indexDocumentAssignmentObj.IXDATASC = sourceTab.hitRange === 0 ? 'PAGE' : 'START'
      }
      this.props.createIndexDocumentAssignment(indexDocumentAssignmentObj, () => this.props.onClose())
    }
  }

  /**
   * @description Gets the title of the modal dialog.
   * @returns {Array} The title of the modal dialog.
   */
  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.create_assignment')} ${translate('general.index_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'index_document_relation_modal_title'} name={'relation'} tabIndex={-1}/>,
      <div
        key={'title_3'}>
        {`${UserUtils.isDOCX()
          ? translate('general.document_modal_title')
          : translate('general.log_modal_title')}  ${translate('general.assignment_modal_title')}`}
      </div>,
    ]
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id, selector: { documents, indexes } } = this.props
    const { generalTab } = this.state

    return (
      <>
        {
          generalTab.showFormExtensionReportSelectorDialog && (
            <SelectorDialog
              id={`${id}_index_document_selector_dialog_form_extension_report`}
              onClose={() => {
                this.setState({
                  generalTab: {
                    ...this.state.generalTab,
                    showFormExtensionReportSelectorDialog: false
                  }
                })
              }}
              title={translate('definition.documentdefinitions')}
              header={[
                translate('general.form'),
                translate('general.extension'),
                translate('general.report'),
              ]}
              items={documents.data}
              onSelect={selectedRows => {
                if (selectedRows.length > 0) {
                  const newForm = documents.data[selectedRows][documents.header.indexOf('FORM')]
                  const newExt = documents.data[selectedRows][documents.header.indexOf('EXT')]
                  const newReport = documents.data[selectedRows][documents.header.indexOf('REPORT')]
                  this.setState({
                    generalTab: {
                      ...this.state.generalTab,
                      form: { value: newForm, error: '' },
                      extension: newExt,
                      report: newReport,
                      showFormExtensionReportSelectorDialog: false
                    }
                  })
                } else {
                  this.setState({
                    generalTab: {
                      ...this.state.generalTab,
                      showFormExtensionReportSelectorDialog: false
                    }
                  })
                }
              }}
            />
          )
        }
        {
          generalTab.showIndexIdSeletorDialog && (
            <SelectorDialog
              id={`${id}_index_document_selector_dialog_index`}
              onClose={() => {
                this.setState({
                  generalTab: {
                    ...this.state.generalTab,
                    showIndexIdSeletorDialog: false
                  }
                })
              }}
              title={translate('definition.index_definitions')}
              header={[
                translate('definition.index_id'),
                translate('definition.index_data_format'),
                translate('definition.index_identifier'),
              ]}
              items={indexes.data}
              onSelect={selectedRows => {
                if (selectedRows.length > 0) {
                  const newIndexID = indexes.data[selectedRows][indexes.header.indexOf('IXINAME')]
                  const newDataFormat = indexes.data[selectedRows][indexes.header.indexOf('IXDTYPE')]
                  const newIdentifier = indexes.data[selectedRows][indexes.header.indexOf('IXENAME')]
                  this.setState({
                    generalTab: {
                      ...this.state.generalTab,
                      indexID: { value: newIndexID, error: '' },
                      dataFormat: newDataFormat,
                      identifier: newIdentifier,
                      showIndexIdSeletorDialog: false
                    }
                  })
                } else {
                  this.setState({
                    generalTab: {
                      ...this.state.generalTab,
                      showIndexIdSeletorDialog: false
                    }
                  })
                }
              }}
            />
          )
        }
      </>
    )
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <>
        <Modal
          id={id}
          className='bux_UserProfileModal'
          onClose={onClose}>
          <Header
            id={`${id}_modalHeader`}
            title={this.getModalTitle()}
            onClose={onClose}
          />
          <Main id={id}>
            {this.renderSelectorDialogs()}
            <Tabs
              id={id}
              errorTabs={this.handleErrorTabs()}
            >
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab title={translate('general.source')}>
                {this.renderSourceTab()}
              </Tab>
              <Tab title={translate('general.index_values')}>
                {this.renderIndexValuesTab()}
              </Tab>
              <Tab title={translate('general.conditions')}>
                {this.renderConditionsTab()}
              </Tab>
            </Tabs>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn_iso88591`}
              text={translate('general.save_as_iso88591')}
              onClick={() => this.handleSave(false)}
              primary
              submit
            />
            <Button
              id={`${id}_savebtn_utf8`}
              text={translate('general.save_as_utf8')}
              onClick={() => this.handleSave(true)}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    selector: state.selector,
    datemask: state.auth.serverdata.preferences.DATEMASK,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    getIndexDefinitions: (fields, index, callback) => {
      ModalSelectorActions.getIndexes(
        fields,
        index,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    createIndexDocumentAssignment: (indexDocumentAssignment, callback) => {
      IndexDocumentAssignmentActions.createIndexDocumentAssignment(indexDocumentAssignment, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateIndexDocumentAssignment)