import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'

import * as UserUtils from 'utils/UserUtils'

import SearchResultPagesDocuments from './pages_documents/SearchResultPagesDocuments'
import SearchResultStatisticLogx from './logx/SearchResultStatisticLogx'

class SearchResultStatistic extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    match: PropTypes.any.isRequired,
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }

  /**
   * @description Gets the body which should be rendered.
   */
  getBody() {
    const { match, id, drawerExpanded, autoDrawer } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    switch (url) {
      case 'pagesdocs':
        return (
          <SearchResultPagesDocuments
            id={`${id}_pages_documents`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'logx':
        return (
          <SearchResultStatisticLogx
            id={`${id}_logx`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      default:
        return (
          <Redirect to={UserUtils.isDOCX() ? '/statistic/pagesdocs' : '/statistic/logx'} />
        )
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }

}
export default SearchResultStatistic