import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'
import * as Utils from 'utils/Utils'

// components

import {
  Button,
  Card,
  Column,
  Input,
  Modal as ModalComponent,
  Row,
  Switch
} from 'BetaUX2Web-Components/src/'


import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

// Redux
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import { cloneDocument } from 'redux/actions/DocumentDefinitionActions'

const { Modal, Main, Header, Footer } = ModalComponent
class CloneDocumentDefinitionDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    formSource: '',
    extensionSource: '',
    reportSource: '',
    formTarget: {
      value: '',
      error: ''
    },
    extensionTarget: {
      value: '',
      error: ''
    },
    reportTarget: {
      value: '',
      error: '',
    },
    documentRecipient: 0,
    documentIndex: 0,
    documentFolder: 0,
    documentNode: 0,
    allReports: 0,
    showDocumentSelectorDialog: false
  }

  formTargetInput = React.createRef()
  extensionTargetInput = React.createRef()
  reportTargetInput = React.createRef()

  componentDidMount = () => {
    const { document } = this.props
    this.formTargetInput.current.focus()
    this.setState({
      formSource: document.FORM,
      extensionSource: document.EXT,
      reportSource: document.REPORT
    })
  }

  /**
   * @description gets the index of the header in redux state
   * @param {Object} data specific redux object
   * @param {String} header header name of the header in redux
   */
  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param value New value of inputfield
   */
  handleInputChanged = (key, value, error) => {
    this.setState(state => ({
      [key]: typeof state[key] === 'object'
        ? { value, error: error || '' }
        : value
    }))
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInputChanged(key, value, error)
  }

  /**
   * @description Requests the document definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handleDocumentSelector = () => {
    const { getDocumentDefinitions } = this.props
    const { formTarget, extensionTarget } = this.state
    const callback = () => this.setState({ showDocumentSelectorDialog: true })

    getDocumentDefinitions(['FORM', 'EXT', 'REPORT'], formTarget.value, extensionTarget.value, callback)
  }

  /**
   * @description Renders the selector dialogs
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    const { showDocumentSelectorDialog, reportSource } = this.state
    return (
      <>
        {showDocumentSelectorDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report')
            ]}
            items={selector.documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const form = selector.documents.data[selectedRows][this.headerData(selector.documents, 'FORM')]
                const extension = selector.documents.data[selectedRows][this.headerData(selector.documents, 'EXT')]
                const report = reportSource ? selector.documents.data[selectedRows][this.headerData(selector.documents, 'REPORT')] : ''
                this.setState({ formTarget: { value: form }, extensionTarget: { value: extension }, reportTarget: { value: report } })
              }
              this.setState({ showDocumentSelectorDialog: false })
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Validates the form.
   */
  validateForm = () => {
    const { formTarget } = this.state
    if (formTarget.value !== '') {
      if (formTarget.value.includes(' ')) {
        return {
          formTarget: {
            ...formTarget,
            error: 'general.input_no_blanks'
          }
        }
      } else {
        return {}
      }
    }
    return {
      formTarget: {
        ...formTarget,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the extension.
   */
  validateExtension = () => {
    const { extensionTarget, extensionSource } = this.state
    if (extensionSource === '') {
      return {}
    }
    if (extensionTarget.value !== '') {
      return {}
    }
    return {
      extensionTarget: {
        ...this.state.extensionTarget,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the report.
   */
  validateReport = () => {
    const { reportTarget, reportSource } = this.state
    if (reportSource === '') {
      return {}
    }
    if (reportTarget.value !== '') {
      return {}
    }
    return {
      reportTarget: {
        ...this.state.reportTarget,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = {
      ...this.validateForm(),
      ...this.validateExtension(),
      ...this.validateReport()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input which has an error.
   */
  handleFocus = () => {
    const { formTarget, extensionTarget, reportTarget } = this.state
    const requiredInputs = [
      { inputRef: this.formTargetInput, error: formTarget.error },
      { inputRef: this.extensionTargetInput, error: extensionTarget.error },
      { inputRef: this.reportTargetInput, error: reportTarget.error }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Clones the entry
   */
  handleSave = saveAsUtf8 => {
    const { cloneDocument, onClose } = this.props
    const {
      formSource,
      extensionSource,
      reportSource,
      formTarget,
      extensionTarget,
      reportTarget,
      documentRecipient,
      documentIndex,
      documentFolder,
      documentNode,
      allReports
    } = this.state
    if (this.validateInputs()) {
      const documentDef = {
        FORM: formSource,
        NFORM: formTarget.value,
        EXTENSION: extensionSource,
        NEXTENSION: extensionTarget.value,
        REPORT: reportSource,
        NREPORT: reportTarget.value,
        cloneDocumentRecipientAssignments: documentRecipient === 0,
        cloneDocumentFolderAssignments: documentFolder === 0,
        cloneDocumentIndexAssignments: documentIndex === 0,
        cloneDocumentNodeAssignments: documentNode === 0,
        SAVEUTF8: saveAsUtf8,
        cloneReports: allReports === 0
      }

      cloneDocument(documentDef, () => onClose())
    }
  }

  render = () => {
    const { id, onClose } = this.props
    const {
      formSource,
      extensionSource,
      reportSource,
      formTarget,
      extensionTarget,
      reportTarget,
      documentRecipient,
      documentIndex,
      documentFolder,
      documentNode,
      allReports
    } = this.state
    return (
      <>
        {this.renderSelectorDialogs()}
        <Modal
          id='clone_document_dialog'
          className='bux_UserProfileModal'
          onClose={onClose}>
          <Header
            id={`${id}_modalHeader`}
            title={translate('definition.document_clone_modal_title')}
            onClose={onClose} />
          <Main id={id}>
            <Card>
              <Row>
                <Column colMD={3}>
                  <label>{translate('general.source')}</label>
                </Column>
                <Column colMD={3}>
                  <label>{translate('general.target')}</label>
                </Column>
                <Column colMD={3}>
                  <label>{translate('general.copy_assignments')}</label>
                </Column>
              </Row>
              <Row>
                <Column colMD={3}>
                  <Input
                    id={`${id}_formsource`}
                    onInputChanged={val => this.handleInputChanged('formSource', val)}
                    value={formSource}
                    title={translate('general.form')}
                    maxLength={8}
                    charCount
                    ref={this.formSourceInput}
                    disabled
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_formtarget`}
                    onInputChanged={(val, err) => this.handleChangeWithoutSpaces('formTarget', val, err)}
                    value={formTarget.value}
                    title={translate('general.form')}
                    ref={this.formTargetInput}
                    error={formTarget.error && translate(formTarget.error)}
                    maxLength={8}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }}
                    onBlur={() => this.setState({ ...this.validateForm() })}
                    required={`${translate('general.required_field')}`}
                  />
                </Column>
                <Column colMD={3}>
                  <Switch
                    id={`${id}_document_recipient`}
                    title={`${translate('general.document')} <--> ${translate('general.recipient')}`}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleInputChanged('documentRecipient', index)}
                    activeIndex={documentRecipient}
                  />
                </Column>
                <Column colMD={3}>
                  <Switch
                    id={`${id}_document_index`}
                    title={`${translate('general.document')} <--> ${translate('definition.index')}`}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleInputChanged('documentIndex', index)}
                    activeIndex={documentIndex}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={3}>
                  <Input
                    id={`${id}_extensionsource`}
                    onInputChanged={val => this.handleInputChanged('extensionSource', val)}
                    value={extensionSource}
                    title={translate('general.extension')}
                    maxLength={16}
                    ref={this.extensionSourceInput}
                    disabled
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_extensiontarget`}
                    onInputChanged={(val, err) => this.handleChangeWithoutSpaces('extensionTarget', val, err)}
                    value={extensionTarget.value}
                    title={translate('general.extension')}
                    ref={this.extensionTargetInput}
                    error={extensionTarget.error && translate(extensionTarget.error)}
                    maxLength={16}
                    addon={{
                      iconName: 'list',
                      tooltip: translate('loginscreen.change_password'),
                      onClick: () => this.handleDocumentSelector(),
                    }}
                    onBlur={() => this.setState({ ...this.validateExtension() })}
                    required={extensionSource !== '' ? `${translate('general.required_field')}` : false}
                  />
                </Column>
                <Column colMD={3}>
                  <Switch
                    id={`${id}_document_folder`}
                    title={`${translate('general.document')} <--> ${translate('general.folder')}`}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleInputChanged('documentFolder', index)}
                    activeIndex={documentFolder}
                  />
                </Column>
                <Column colMD={3}>
                  <Switch
                    id={`${id}_document_node`}
                    title={`${translate('general.document')} <--> ${translate('definition.node')}`}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleInputChanged('documentNode', index)}
                    activeIndex={documentNode}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={3}>
                  <Input
                    id={`${id}_reportsource`}
                    onInputChanged={val => this.handleInputChanged('reportSource', val)}
                    value={reportSource}
                    title={translate('general.report')}
                    maxLength={16}
                    ref={this.reportSourceInput}
                    disabled
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_reporttarget`}
                    onInputChanged={(val, err) => this.handleInputChanged('reportTarget', val, err)}
                    value={reportTarget.value}
                    title={translate('general.report')}
                    maxLength={16}
                    error={reportTarget.error && translate(reportTarget.error)}
                    onBlur={() => this.setState({ ...this.validateReport() })}
                    required={reportSource !== '' ? `${translate('general.required_field')}` : false}
                    disabled={reportSource === ''}
                    ref={this.reportTargetInput}
                  />
                </Column>
                <Column colMD={3}>
                  <Switch
                    id={`${id}_allreports`}
                    title={translate('general.all_reports')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleInputChanged('allReports', index)}
                    activeIndex={allReports}
                    disabled={reportSource !== ''}
                  />
                </Column>
                <Column colMD={3}/>
              </Row>
            </Card>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn_iso88591`}
              text={translate('general.save_as_iso88591')}
              onClick={() => this.handleSave(false)}
              primary
              submit
            />
            <Button
              id={`${id}_savebtn_utf8`}
              text={translate('general.save_as_utf8')}
              onClick={() => this.handleSave(true)}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }

}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    document: state.definitions.documents.document,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDocumentDefinitions: (fields, form, extension, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        undefined, // report
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    cloneDocument: (documentDefinition, callback) => {
      cloneDocument(documentDefinition, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CloneDocumentDefinitionDialog)