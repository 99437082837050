import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// components
import {
  Button,
  Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent, MultilineInput, Row,
  Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent


// Redux
import { connect } from 'react-redux'
import * as PostProcessingNoteDefinitionActions from 'redux/actions/PostProcessingNoteDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'

class ModifyPPNDialog extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    generalTab: {
      owner: {
        value: this.props.ppn2Modify.OWNER,
        errorkey: '',
      },
      title: {
        value: this.props.ppn2Modify.PPNTITLE,
        errorkey: '',
      },
      printControlFilename: {
        value: this.props.ppn2Modify.DJDE,
        errorkey: '',
      }
    },
    textTab: {
      text: ''
    }
  }

  ownerInput = React.createRef()

  /**
   * @description Sets the initial values.
   */
  componentDidMount = () => {
    const { ppn2Modify } = this.props
    let multilineText = ''
    for (let i = 1; i <= 16; i++) {
      multilineText += ppn2Modify[`PPNTEX${i}`]
      if (i < 16 && ppn2Modify[`PPNTEX${i}`] !== '') {
        multilineText += '\n'
      }
    }

    this.setState({
      textTab: {
        ...this.state.textTab,
        text: multilineText
      }
    })
    this.ownerInput.current.focus()
  }

  /**
   * @description Handles the save action.
   * @param {Boolean} saveAsUtf8 Flag if the definition should be saved as utf-8. If false it will be saved as iso-8859-1.
   */
  handleSave = (saveAsUtf8) => {
    const { generalTab, textTab } = this.state

    // assign each row to its param
    const [PPNTEX1, PPNTEX2, PPNTEX3, PPNTEX4, PPNTEX5, PPNTEX6, PPNTEX7, PPNTEX8, PPNTEX9,
      PPNTEX10, PPNTEX11, PPNTEX12, PPNTEX13, PPNTEX14, PPNTEX15, PPNTEX16
    ] = textTab.text.split('\n')

    const ppnDefinition = {
      PPN: this.props.ppn2Modify.PPN,
      OWNER: generalTab.owner.value,
      DJDE: generalTab.printControlFilename.value,
      PPNTITLE: generalTab.title.value,
      CUSER: this.props.userid,
      PPNTEX1: PPNTEX1,
      PPNTEX2: PPNTEX2,
      PPNTEX3: PPNTEX3,
      PPNTEX4: PPNTEX4,
      PPNTEX5: PPNTEX5,
      PPNTEX6: PPNTEX6,
      PPNTEX7: PPNTEX7,
      PPNTEX8: PPNTEX8,
      PPNTEX9: PPNTEX9,
      PPNTEX10: PPNTEX10,
      PPNTEX11: PPNTEX11,
      PPNTEX12: PPNTEX12,
      PPNTEX13: PPNTEX13,
      PPNTEX14: PPNTEX14,
      PPNTEX15: PPNTEX15,
      PPNTEX16: PPNTEX16,
      SAVEUTF8: saveAsUtf8
    }

    const callback = () => this.props.onClose()
    this.props.updatePPN(ppnDefinition, callback)
  }

  /**
   * @description Handles the changes on input fields in general tab.
   * @param {String} id The id of the field.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleGeneralTabInputChanged = (id, value, errorkey) => {
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        [id]: {
          value: value,
          errorkey: errorkey
        }
      }
    })
  }

  /**
   * @description Handles the changes on input fields in text tab.
   * @param {String} id The id of the field.
   * @param {String} value The new value.
   */
  handleTextTabInputChanged = (id, value) => {
    this.setState({
      textTab: {
        ...this.state.textTab,
        [id]: value
      }
    })
  }

  /**
   * @description Renders the header which includes the non editable information.
   */
  renderHeader() {
    const { id, ppn2Modify, datemask } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.postprocessing_note_id'), value: ppn2Modify.PPN },
          { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, ppn2Modify.CDATE, ppn2Modify.CTIME.substring(0, 8)) + ' ' + translate('general.by') + ' ' + ppn2Modify.CUSER },
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { id } = this.props
    const { generalTab } = this.state
    return (
      <>
        {/* owner + print control filename + title row */}
        <Row>
          <Column
            colMD={3}>
            {/* owner */}
            <Input
              id={`${id}_owner`}
              ref={this.ownerInput}
              title={translate('general.owner')}
              value={generalTab.owner.value}
              error={generalTab.owner.errorkey && translate(generalTab.owner.errorkey)}
              maxLength={8}
              onInputChanged={value => this.handleGeneralTabInputChanged('owner', value)}>
            </Input>
          </Column>
          <Column
            colMD={3}>
            {/* print control filename */}
            <Input
              id={`${id}_print_control_filename`}
              title={translate('definition.ppn_print_control_filename')}
              value={generalTab.printControlFilename.value}
              error={generalTab.printControlFilename.errorkey && translate(generalTab.printControlFilename.errorkey)}
              maxLength={8}
              onInputChanged={value => this.handleGeneralTabInputChanged('printControlFilename', value)}>
            </Input>
          </Column>
          <Column
            colMD={6}>
            {/* title */}
            <Input
              id={`${id}_title`}
              title={translate('general.title')}
              value={generalTab.title.value}
              error={generalTab.title.errorkey && translate(generalTab.title.errorkey)}
              maxLength={40}
              onInputChanged={value => this.handleGeneralTabInputChanged('title', value)}>
            </Input>
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the text tab.
   */
  renderTextTab = () => {
    const { id } = this.props
    const { textTab } = this.state
    return (
      <>
        <Row>
          <Column
            colMD={12}>
            <MultilineInput
              tabIndex={0}
              title={translate('general.text')}
              value={textTab.text}
              cols={80}
              rows={16}
              id={`${id}_text`}
              onInputChanged={value => this.handleTextTabInputChanged('text', value)}
              mono
            />
          </Column>
        </Row>
      </>
    )
  }

  render = () => {
    const { id, lang, onClose } = this.props
    return (
      <Modal onClose={onClose}
        id={id}
        className='bux_UserProfileModal'>
        <Header
          id={id}
          title={translate('definition.modify_ppn')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main
          id={id}>
          <Tabs
            id={id}
            lang={lang}>
            <Tab title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('general.text')}>
              {this.renderTextTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn_iso88591`}
            text={translate('general.save_as_iso88591')}
            onClick={() => this.handleSave(false)}
            primary
            submit
          />
          <Button
            id={`${id}_savebtn_utf8`}
            text={translate('general.save_as_utf8')}
            onClick={() => this.handleSave(true)}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    ppn2Modify: state.definitions.ppns.ppn,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updatePPN: (ppnDefinition, callback) => {
      PostProcessingNoteDefinitionActions.updatePPN(ppnDefinition, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyPPNDialog)