import React, { ReactNode } from 'react';
import { prepareClassNames } from '../../../utils/ComponentUtils';
import Icon from '../../icon/Icon';
import { DropdownData, DropdownDataset, isANode, isDropdownDatasetType } from '../Dropdown.types';


interface DropdownButtonProps {
  id: string;
  dataTestId?: string | undefined;
  onClick: () => void;
  active: DropdownData;
  autoFocus?: boolean;
  open?: boolean;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  focusRef?: React.RefObject<HTMLButtonElement>;
  tabIndex?: number;
  placeholder?: string;
}

export const DropdownButton = (props: DropdownButtonProps): JSX.Element => {
  const { id, onClick, active, autoFocus, open, disabled, onFocus, onBlur, focusRef, tabIndex, placeholder } = props;
  const text = isDropdownDatasetType(active) ? (active as DropdownDataset).text : active as (ReactNode | string);
  const icon = isDropdownDatasetType(active) ? (active as DropdownDataset).icon : undefined;
  const stringText = typeof active === 'string' ? active : undefined;

  const activeClassNames = prepareClassNames([
    'dropdown_button_active_value',
    isANode(active) && 'menu_item_node'
  ]);

  return (
    <div
      id={`${id}_div`}
      className={'dropdown_button_container'}>
      <button
        id={`${id}_dropdown_button`}
        data-testid={props.dataTestId}
        className={`button_secondary ${open ? 'dropdown_menu_open' : ''}`}
        type={'button'}
        ref={focusRef}
        autoFocus={autoFocus}
        onClick={onClick}
        onMouseOut={e => e.currentTarget.blur()}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={tabIndex}
        title={stringText || placeholder || ''}>
        {icon && <Icon id="dropdown_button_user_icon" name={icon}/>}
        <div className={'dropdown_button_active_value_container'}>
          {<span id={`${id}_active_value`} className={activeClassNames}>{text}</span>}
        </div>
        <Icon id={`${id}_dropdown_button_chevron_icon`} name={open ? 'chevron_up' : 'chevron_down'}/>
      </button>
    </div>
  );
};