import PropTypes from 'prop-types'
import { Component } from 'react'
import * as DateUtils from 'utils/DateUtils'
import * as SortUtils from 'utils/SortUtils'

// components
import { DataTable, DownloadWrapper, Link, ResultContainer, TableButton } from 'BetaUX2Web-Components/src/'

import MaintenanceResultDialog from 'components/dialogs/maintenance_result_dialog/MaintenanceResultDialog'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as MaintenanceActions from 'redux/actions/MaintenanceActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'

import './SearchResultServerMaintenance.scss'

class SearchResultServerMaintenance extends Component {

  state = {
    pids: {},
    currentJobstate: {
      function: '',
      rc: '',
      lastExecuted: '',
      pid: ''
    },
    showResultDialog: false
  }

  /**
   * @description Sends the getJobs request.
   */
  componentDidMount = () => {
    this.props.getJobs()
  }

  componentDidUpdate = () => {
    requestAnimationFrame(() => {
      var allTableButtons = Array.from(document.querySelectorAll('.bux_datatable_actionbutton'))
      allTableButtons.forEach(button => {
        button.classList.remove('bux_disabled_actionbutton')
        let iconContainer = button.querySelector('div')
        if (iconContainer.classList.contains('disabled')) {
          button.classList.add('bux_disabled_actionbutton')
        }
      })
    })
  }

  /**
   * @description handles the result action when the show result button is clicked
   */
  handleResult = rowIndex => {
    const func = this.getJobsData()[rowIndex][0]
    const rc = this.getJobsData()[rowIndex][2]
    const lastExecuted = this.getJobsData()[rowIndex][3]
    const pid = this.getJobsData()[rowIndex][4]
    const callback = () => {
      this.setState({
        showResultDialog: true,
        currentJobstate: {
          function: func,
          rc,
          lastExecuted,
          pid
        }
      })
    }
    this.props.getJobOutput(pid, callback)
  }

  /**
   * @description handles the execution when execute button is clicked
   * @param rowIndex The index of the current row
   */
  handleExecute = rowIndex => {
    const jobs = this.getJobsData()
    const job = jobs[rowIndex][1]

    const callback = (jobExecutionData) => {
      this.setState(prevState => ({
        ...prevState,
        pids: {
          ...prevState.pids,
          [job]: jobExecutionData.PID
        }
      }))
    }
    this.props.executeJob(job, callback)
  }

  multiExecution = rows => {
    const { executeJob, showMultiple, lang, getJobs } = this.props
    const jobNames = rows.map(row => row[1])
    const newPids = { ...this.state.pids }
    const results = []
    const jobCount = jobNames.length
    const singleExecution = iteration => {
      const job = jobNames[iteration]
      executeJob(job, result => {
        // if one execute request fails, there will be no result
        if (result?.PID) {
          newPids[job] = result.PID
          results.push({ job, success: true })
        }
        else {
          results.push({ job, success: false, message: result?.message })
        }
        if (iteration < jobCount - 1) {
          singleExecution(iteration + 1)
        }
        // after last iteration update all pids for successfully executed ones
        else if (iteration === jobCount - 1) {
          this.setState({ pids: newPids })
          const snackbarMessages = []
          const successExecution = results.filter(result => result.success)
          const failedExecution = results.filter(result => !result.success)
          if (successExecution.length > 0) {
            if (successExecution.length <= 2) {
              successExecution.forEach(exec => {
                snackbarMessages.push({ text: translate('server.external_command_executed', lang, [this.translateJobname(exec.job)]), type: SnackbarActions.TYPE_SUCCESS })
              })
            }
            else {
              snackbarMessages.push({ text: translate('server.jobs_executed_counted', lang, [successExecution.length.toString()]), type: SnackbarActions.TYPE_SUCCESS })
            }
          }
          if (failedExecution.length > 0) {
            failedExecution.forEach(exec => {
              snackbarMessages.push({ text: translate('server.job_executed_fail', lang, [exec.job, exec.message]), type: SnackbarActions.TYPE_ERROR })
            })
          }
          showMultiple(snackbarMessages)
          getJobs()
        }
      }, true)
    }
    singleExecution(0)
  }

  handleRefresh = () => {
    this.props.getJobs()
    this.setState({
      pids: {}
    })
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    const jobs = this.getJobsData()
    let pid = ''
    if (rowIndex !== undefined) {
      pid = jobs[rowIndex][4]
    }

    return [
      <TableButton
        id={`${id}_tableButtonExecute_${rowIndex}`}
        iconType='material'
        iconName='execute'
        title={translate('database.execute')}
        onClick={() => { this.handleExecute(rowIndex) }}
      />,
      <TableButton
        id={`${id}_tableButtonResult_${rowIndex}`}
        iconType='material'
        iconName='result'
        title={translate('database.result')}
        disabled={pid === ''}
        onClick={() => pid !== '' && this.handleResult(rowIndex)}
      />
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
      ]
    )
  }

  /**
   * @description gets the index of the header in redux state maintenance.jobs.header
   * @param {String} header header name of the header in redux state maintenance.jobs.header
   */
  headerData = header => this.props.maintenance.jobs.header.indexOf(header)

  translateJobname = jobname => {
    switch (jobname) {
      case 'DEONL':
        return translate('database.online_cleanup')
      case 'DELOG':
        return translate('database.message_log_cleanup')
      case 'DEPRT':
        return translate('database.output_queue_cleanup')
      case 'DENTE':
        return translate('database.notes_cleanup')
      case 'BKARC':
        return translate('database.archive')
      case 'BKARCN':
        return translate('database.archive_notes')
      case 'BKCLN':
        return translate('database.archive_cleanup')
      case 'BKRLD':
        return translate('database.reload')
      case 'DAILY':
        return translate('database.daily')
      default: return jobname
    }
  }

  /**
   * @description builds the data to a an array which is used later
   */
  getJobsData = () => {
    const { datemask, maintenance } = this.props
    const { pids } = this.state
    const data = []
    maintenance.jobs.data.forEach(element => {
      let dataBuffer = []
      dataBuffer.push(this.translateJobname(element[this.headerData('JOBNAME')]))
      dataBuffer.push(element[this.headerData('JOBNAME')])
      const parsedRC = parseInt(element[this.headerData('RC')])
      // Fallback because REST-API returns some times empty strings for a RC whoch would be parsed into NaN.
      dataBuffer.push(!isNaN(parsedRC) ? parsedRC : '')
      dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('DATE')]))
      dataBuffer.push(pids[element[this.headerData('JOBNAME')]]?.toString() || '')
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the header for the table. Includes empty header strings for the actions.
   */
  getDefaultHeader = () => {
    return [
      'database.function',
      'general.jobname',
      'database.return_code',
      'database.last_executed',
      'database.process_id'
    ]
  }

  /**
     * @description Gets specific column sort definitions.
     */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)


  render = () => {
    const { id, maintenance, drawerExpanded, autoDrawer, lang, datemask } = this.props
    const { showResultDialog, currentJobstate } = this.state
    const data = maintenance.jobs ? this.getJobsData() : null
    const header = this.getDefaultHeader().map(h => translate(h))
    return (
      <>
        {showResultDialog && (
          <MaintenanceResultDialog
            jobState={currentJobstate}
            id={`${id}_maintenanceresult`}
            onClose={() => this.setState({ showResultDialog: false })}
          />
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {data && (
            <DataTable
              id={id}
              header={header}
              data={data}
              cleanData={data}
              selectable={true}
              createActionButtons={this.createActionButtons}
              columnSortDefs={this.getColumnSortDefs(data, header)}
              additionalInteraction={this.createInteractionButtons(data, header)}
              fillPage
              translate={key => translate(key)}
              language={lang}
              datemask={datemask}
              noAction
              // downloadItems={[translate('general.execute')]}
              // showModal={(checkedRows, index) => this.multiExecution(checkedRows, index)}
            />
          )}
        </ResultContainer>
      </>
    )
  }
}

SearchResultServerMaintenance.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences.DATEMASK,
    maintenance: state.maintenance
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getJobs: callback => {
      MaintenanceActions.getJobs(callback)(dispatch)
    },
    executeJob: (jobname, callback, executeCallbackAlways) => {
      MaintenanceActions.executeJob(jobname, callback, executeCallbackAlways)(dispatch)
    },
    getJobOutput: (pid, callback) => {
      MaintenanceActions.getJobOutput(pid, callback)(dispatch)
    },
    showMultiple: snackbarMessages => SnackbarActions.showMultiple(snackbarMessages)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultServerMaintenance)