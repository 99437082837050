import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { withRouter } from 'react-router'

// Components
import { Button, Card, Column, Dropdown, Row } from 'BetaUX2Web-Components/src/'
import Datepicker from 'components/datepicker/Datepicker'

// Redux
import { connect } from 'react-redux'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as StatisticActions from 'redux/actions/StatisticActions'
import * as Preferences from 'redux/general/Preferences'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import { CHART_LINECHART, LOGX_CHARTS, UNITS, UNIT_DAY } from 'utils/StatisticUtils'

class StatisticLogx extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    fromDate: {
      value: '',
      errorkey: ''
    },
    toDate: {
      value: '',
      errorkey: ''
    },
    activeUnitIndex: UNITS.findIndex(temp => temp.key === UNIT_DAY),
    activePagesChartIndex: LOGX_CHARTS.findIndex(temp => temp.key === CHART_LINECHART),
    activeLogsChartIndex: LOGX_CHARTS.findIndex(temp => temp.key === CHART_LINECHART),
    activeJobsChartIndex: LOGX_CHARTS.findIndex(temp => temp.key === CHART_LINECHART),
  }

  state = {
    ...this.defaultState
  }

  fromDateInput = React.createRef()

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
   * @description Updates the date when the datemask was changed.
   */
  componentDidUpdate = prevProps => {
    const { datemask } = this.props
    const { fromDate, toDate } = this.state
    if (datemask !== prevProps.datemask) {
      let newFromDate = fromDate
      let newToDate = toDate
      if (DateUtils.isDate(fromDate.value, prevProps.datemask)) {
        newFromDate = {
          value: moment(fromDate.value, prevProps.datemask).format(datemask),
          errorkey: ''
        }
      }
      else {
        // remove error when date fits the new datemask
        if (DateUtils.isDate(fromDate.value, datemask)) {
          newFromDate = {
            ...fromDate,
            errorkey: ''
          }
        }
      }
      if (DateUtils.isDate(toDate.value, prevProps.datemask)) {
        newToDate = {
          value: moment(toDate.value, prevProps.datemask).format(datemask),
          errorkey: ''
        }
      }
      else {
        // remove error when date fits the new datemask
        if (DateUtils.isDate(toDate.value, datemask)) {
          newToDate = {
            ...toDate,
            errorkey: ''
          }
        }
      }
      this.setState({ fromDate: newFromDate, toDate: newToDate })
    }
  }
  testNegativeDateDifference = () => {
    const { toDate, fromDate } = this.state
    // sets errorkey if toDate is in the past but not if toDate is empty
    let errorkey = ''
    if (this.calcDifferenceOfDates() < 0 && fromDate.value !== '') {
      errorkey = 'statistic.negative_date_difference_error'
    }
    this.setState({
      toDate: {
        value: toDate.value,
        errorkey
      }
    })
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  initFieldsFromPreferences = () => {
    const { preferences, datemask } = this.props

    if (preferences) {
      // from date
      let fromDate = preferences[Preferences.STATISTIC_LOGX_FROMDATE] || ''
      // fallback if old preferences data is stored as date and not as unix timestamp
      if (fromDate !== '') {
        if (DateUtils.isUnixTimestamp(fromDate)) {
          fromDate = DateUtils.getDateFromUnixTimestamp(fromDate, datemask)
        }
        else {
          fromDate = moment(fromDate, DateUtils.DDMMYYYY_DOT).format(datemask)
        }
      }
      // to date
      let toDate = preferences[Preferences.STATISTIC_LOGX_TODATE] || ''
      if (toDate !== '') {
        if (DateUtils.isUnixTimestamp(toDate)) {
          toDate = DateUtils.getDateFromUnixTimestamp(toDate, datemask)
        }
        else {
          toDate = moment(toDate, DateUtils.DDMMYYYY_DOT).format(datemask)
        }
      }
      else {
        toDate = DateUtils.today().format(datemask)
      }
      // unit
      let unit = preferences[Preferences.STATISTIC_LOGX_UNIT] || UNIT_DAY
      const activeUnitIndex = Math.max(UNITS.findIndex(temp => temp.key === unit), 0)

      // pages chart
      let pagesChart = preferences[Preferences.STATISTIC_LOGX_PAGES_CHART] || CHART_LINECHART
      const activePagesChartIndex = Math.max(LOGX_CHARTS.findIndex(temp => temp.key === pagesChart), 0)

      // pages chart
      let logsChart = preferences[Preferences.STATISTIC_LOGX_LOGS_CHART] || CHART_LINECHART
      const activeLogsChartIndex = Math.max(LOGX_CHARTS.findIndex(temp => temp.key === logsChart), 0)

      let jobsChart = preferences[Preferences.STATISTIC_LOGX_JOBS_CHART] || CHART_LINECHART
      const activeJobsChartIndex = Math.max(LOGX_CHARTS.findIndex(temp => temp.key === jobsChart), 0)

      this.setState({
        fromDate: {
          value: fromDate,
          errorkey: ''
        },
        toDate: {
          value: toDate,
          errorkey: ''
        },
        activeUnitIndex: activeUnitIndex,
        activePagesChartIndex: activePagesChartIndex,
        activeLogsChartIndex: activeLogsChartIndex,
        activeJobsChartIndex: activeJobsChartIndex
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInputChanged = (id, value, errorkey) => {
    this.setState({
      [id]: {
        value: value,
        errorkey: errorkey
      }
    }, () => {
      if (id === 'toDate' || id === 'fromDate') {
        this.testNegativeDateDifference()
      }
    })
  }

  /**
   * @description Handles the search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = event => {
    const { datemask } = this.props
    event.preventDefault()

    const {
      fromDate,
      toDate,
      activeUnitIndex,
      activePagesChartIndex,
      activeLogsChartIndex,
      activeJobsChartIndex
    } = this.state

    if (fromDate.value > toDate.value || !toDate.value) {
      this.setState({
        toDate: {
          value: DateUtils.today(),
          errorkey: ''
        }
      })
    }

    // check if values are correct
    if (!this.verifyFields()) {
      return
    }

    // save search values in preferences
    const prefsToChange = {
      [Preferences.STATISTIC_LOGX_FROMDATE]: DateUtils.getRequestFormat(fromDate.value, datemask),
      [Preferences.STATISTIC_LOGX_TODATE]: DateUtils.getRequestFormat(toDate.value, datemask),
      [Preferences.STATISTIC_LOGX_UNIT]: UNITS[activeUnitIndex].key,
      [Preferences.STATISTIC_LOGX_PAGES_CHART]: LOGX_CHARTS[activePagesChartIndex].key,
      [Preferences.STATISTIC_LOGX_LOGS_CHART]: LOGX_CHARTS[activeLogsChartIndex].key,
      [Preferences.STATISTIC_LOGX_JOBS_CHART]: LOGX_CHARTS[activeJobsChartIndex].key,
    }

    this.props.changePrefs(prefsToChange)

    const searchFromDate = DateUtils.getTimeshiftDate(fromDate.value, '', DateUtils.DDMMYYYY_DOT)
    const searchToDate = DateUtils.getTimeshiftDate(toDate.value, '', DateUtils.DDMMYYYY_DOT)
    const activeUnit = UNITS[activeUnitIndex].key
    this.props.getLogxBsaStats(searchFromDate, searchToDate, activeUnit)
  }

  /**
   * @description Verifies the search fields and adds error under required fields if they're not filled.
   */
  verifyFields = () => {
    return this.verfiyField('fromDate', true, this.fromDateInput)
  }

  /**
   * @description Verifies a field and adds error under required fields if they're not filled.
   * @param fieldKey The key of the field in state
   * @param required The flag if the field is required (Important for adding error below when value is empty)
   * @param focus The reference to the field to focus when verification fails (pass nothing if you don't want to focus the field)
   */
  verfiyField = (fieldKey, required, focus) => {
    let verifyOk = true
    const field = this.state[fieldKey]

    if (required) {
      // add error when field is empty and required
      if (field.value === '') {
        this.setState({ [fieldKey]: { value: '', errorkey: 'general.input_required' } })
        verifyOk = false
      }
    }

    // switch verify flag if there's an errorkey
    if (verifyOk && field.errorkey && field.errorkey !== '') {
      verifyOk = false
    }

    // focus field on error
    if (!verifyOk && focus) {
      focus.current.focus()
    }

    return verifyOk
  }

  /**
   * @description Gets the translated units.
   */
  getTranslatedUnits = () => {
    const units = []
    UNITS.forEach((entry, index) => {
      units.push(`${translate(entry.translationKey)} ${this.recommendation() === index ? translate('statistic.unit_recommendation') : ''}`)
    })

    return units
  }

  /**
   * @description Returns a recommended unit list index.
   */
  recommendation = () => {
    const difference = this.calcDifferenceOfDates()
    if (difference < 0 || !difference) {
      return null
    } else if (difference < 90) {
      return 0
    } else if (difference >= 90 && difference <= 1095) {
      return 1
    } else {
      return 2
    }
  }

  /**
   * @description Calculates the difference between two dates.
   */
  calcDifferenceOfDates = () => {
    const startDate = moment(this.state.fromDate.value, this.props.datemask)
    const endDate = moment(this.state.toDate.value, this.props.datemask)
    const differenceInDays = moment.duration(endDate.diff(startDate))._milliseconds / 1000 / 60 / 60 / 24
    return differenceInDays
  }

  /**
   * @description Gets the translated charts.
   */
  getTranslatedCharts = () => {
    const { lang } = this.props

    const charts = []
    LOGX_CHARTS.forEach(entry => {
      charts.push(translate(entry.translationKey, lang))
    })

    return charts
  }

  /**
   * @description Resets the statisticpanel to default values.
   */
  handleOnResetStatistic = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id, lang, datemask } = this.props
    const unitItems = this.getTranslatedUnits()
    return (
      <Card
        id={`${id}_body_generalcard`}
        title={translate('general.general')}>
        {/* fromDate + toDate row */}
        <Row id={`${id}_body_generalcard_row_0`} >
          <Column
            id={`${id}_body_generalcard_row_0_fromcolumn`}
            colMD={6}
            offsetMD={0}>
            {/* fromDate */}
            <Datepicker
              id={`${id}_fromdate`}
              focusRef={this.fromDateInput}
              title={translate('general.from')}
              value={this.state.fromDate.value}
              error={this.state.fromDate.errorkey && translate(this.state.fromDate.errorkey)}
              onChange={(val, err) => { this.handleInputChanged('fromDate', val, err) }}
              dateFormat={datemask}
              required={`${translate('general.required_field')}`}
              language={lang}
              onInvalidDate={() => this.setState({ fromDate: { ...this.state.fromDate, errorkey: 'general.invalid_date_format' } })}
            />
          </Column>
          <Column
            id={`${id}_body_generalcard_row_0_tocolumn`}
            colMD={6}
            offsetMD={0}>
            {/* toDate */}
            <Datepicker
              id={`${id}_todate`}
              title={translate('general.to')}
              value={this.state.toDate.value}
              error={this.state.toDate.errorkey && translate(this.state.toDate.errorkey)}
              onChange={(val, err) => { this.handleInputChanged('toDate', val, err) }}
              dateFormat={datemask}
              language={lang}
              onInvalidDate={() => this.setState({ toDate: { ...this.state.toDate, errorkey: 'general.invalid_date_format' } })}
              onBlur={this.testNegativeDateDifference}
            />
          </Column>
        </Row>
        {/* unit */}
        <Row>
          <Column colMD={12}>
            <Dropdown
              id={`${id}_unit`}
              items={unitItems}
              activeIndex={this.state.activeUnitIndex}
              onChange={activeIndex => { this.setState({ activeUnitIndex: activeIndex }) }}
              title={translate('general.unit')}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the chart types card.
   */
  renderChartTypes = () => {
    const { id } = this.props
    const { activePagesChartIndex, activeLogsChartIndex, activeJobsChartIndex } = this.state
    const chartItems = this.getTranslatedCharts()

    return (
      <Card
        id={`${id}_body_charttypecard`}
        title={translate('statistic.charttypes')}>
        <Row>
          <Column colMD={12}>
            <Dropdown
              id={`${id}_pages_chart`}
              items={chartItems}
              activeIndex={activePagesChartIndex}
              onChange={activeIndex => { this.setState({ activePagesChartIndex: activeIndex }) }}
              title={translate('general.pages')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Dropdown
              id={`${id}_logs_chart`}
              items={chartItems}
              activeIndex={activeLogsChartIndex}
              onChange={activeIndex => { this.setState({ activeLogsChartIndex: activeIndex }) }}
              title={translate('general.logs')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Dropdown
              id={`${id}_jobs_chart`}
              items={chartItems}
              activeIndex={activeJobsChartIndex}
              onChange={activeIndex => { this.setState({ activeJobsChartIndex: activeIndex }) }}
              title={translate('general.jobs')}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {/* general card */}
        {this.renderGeneralCard()}
        {/* chart types card */}
        {this.renderChartTypes()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          className='bux_iconResteBtn'
          onClick={this.handleOnResetStatistic}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={`${id}_body`}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    toDate: state.auth.serverdata.preferences[Preferences.STATISTIC_LOGX_TODATE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch),
    getLogxBsaStats: (fromDate, toDate, unit) => StatisticActions.getLogxBsaStats(fromDate, toDate, unit)(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StatisticLogx))