import * as OutputFormatDefinitionActions from '../actions/OutputFormatDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  outputFormats: undefined,
  outputFormat: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get output formats actions
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_START:
      return { ...state }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_SUCCESS:
      return { ...state, outputFormats: action.payload.data, keepPagination: action.keepPagination }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_FAILED:
      return { ...state }
    case OutputFormatDefinitionActions.NO_OUTPUTFORMAT_DEFINITION_OUTPUTFORMATS_FOUND:
      return { ...state, outputFormats: {} }
    /* ********************************************************************************** */
    // Definition create output format actions
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_START:
      return { ...state }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_SUCCESS:
      return { ...state }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition get single output format actions
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_START:
      return { ...state, outputFormat: undefined }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_SUCCESS:
      return { ...state, outputFormat: action.payload }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update output format
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_START:
      return { ...state }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_SUCCESS:
      return { ...state }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete output format
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_START:
      return { ...state }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_SUCCESS:
      return { ...state }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition verify output format
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_START:
      return { ...state }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_SUCCESS:
      return { ...state, verify: action.payload }
    case OutputFormatDefinitionActions.OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, outputFormats: undefined, outputFormat: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer