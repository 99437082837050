import React from 'react';
import PropTypes from 'prop-types';

/**
 * Tab and Tabs component created according to
 * _TabBar_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
export const Tab = ({id, dataTestId, children, disabled, padding, className, tooltip= undefined}) => {
  return (
    <div
      disabled={disabled}
      id={`${id}_tab`}
      data-testid={dataTestId}
      title={tooltip}
      style={padding && {padding: '1rem'}}
      className={className || ''}>
      {children}
    </div>
  );
};

Tab.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string,
  /** This ID will be used for integration tests to access the HTML element */
  dataTestId: PropTypes.string,
  /** Disables tab. Default: false. */
  disabled: PropTypes.bool,
  /** Padding value */
  padding: PropTypes.string,
  /** Style class from CSS for styling for Tab.*/
  className: PropTypes.string,
  /** Children of the component.*/
  children: PropTypes.node,
  /** Title of Tab */
  title: PropTypes.string,
};