import * as Language from 'language/Language'

// ! "fields" haben jeweils verschiedene Reihenfolgen
// TODO: eine einheitliche Reihenfolge für "fields" festlegen
// TODO: eine einheitliche Bennenung für die einzelnen "field" festlegen
export const getAccountingRecords = () => {
  return {
    headers: [
      Language.translate('server.accounting.type'),
      Language.translate('server.accounting.description')
    ],
    types: {
      type050: {
        name: `${Language.translate('server.accounting.type')} 050`,
        title: Language.translate('server.accounting.type050.title'),
        description: Language.translate('server.accounting.log_in/out_user'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          }, {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          }, {
            id: 'FLAG',
            description: `${Language.translate('server.accounting.flag')}`
          }, {
            id: 'USER',
            description: `${Language.translate('server.accounting.user')}`
          }, {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          }, {
            id: 'IP_CLIENT',
            description: `${Language.translate('server.accounting.ip_client')}`
          }, {
            id: 'IP_SERVER',
            description: `${Language.translate('server.accounting.ip_server')}`
          }, {
            id: 'DATE',
            description: `${Language.translate('server.accounting.user_log_in/out_date')}`
          }, {
            id: 'TIME',
            description: `${Language.translate('server.accounting.user_log_in/out_time')}`
          }
        ]
      },
      type100: {
        name: `${Language.translate('server.accounting.type')} 100`,
        title: Language.translate('server.accounting.type100.title'),
        description: Language.translate('server.accounting.read_document'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          }, {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          }, {
            id: 'FORM',
            description: `${Language.translate('general.form')}`
          }, {
            id: 'EXTENSION',
            description: `${Language.translate('general.extension')}`
          }, {
            id: 'REPORT',
            description: `${Language.translate('general.report')}`
          }, {
            id: 'OWNER',
            description: `${Language.translate('server.accounting.owner')}`
          }, {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          }, {
            id: 'FILETYPE',
            description: `${Language.translate('server.accounting.filetype')}`
          }, {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          }, {
            id: 'SPOOL_BLOCKS',
            description: `${Language.translate('server.accounting.spool_blocks')}`
          }, {
            id: 'INPUT_DATE',
            description: `${Language.translate('server.accounting.input_date')}`
          }, {
            id: 'INPUT_TIME',
            description: `${Language.translate('server.accounting.input_time')}`
          }, {
            id: 'RELOAD_DATE',
            description: `${Language.translate('server.accounting.reload_date')}`
          }, {
            id: 'RELOAD_TIME',
            description: `${Language.translate('server.accounting.reload_time')}`
          }, {
            id: 'DOCUMENT_ID',
            description: `${Language.translate('server.accounting.document_id')}`
          }
        ]
      },
      type101: {
        name: `${Language.translate('server.accounting.type')} 101`,
        title: Language.translate('server.accounting.type101.title'),
        description: Language.translate('server.accounting.outputting_document'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          }, {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          }, {
            id: 'BUNDLE_PRINT_NAME',
            description: `${Language.translate('server.accounting.bundle_print_name_only_print')}`
          }, {
            id: 'FORM',
            description: `${Language.translate('general.form')}`
          }, {
            id: 'EXTENSION',
            description: `${Language.translate('general.extension')}`
          }, {
            id: 'REPORT',
            description: `${Language.translate('general.report')}`
          }, {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          }, {
            id: 'FILETYPE',
            description: `${Language.translate('server.accounting.filetype')}`
          }, {
            id: 'RECIPIENT',
            description: `${Language.translate('server.accounting.recipient')}`
          }, {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          }, {
            id: 'BANNERPAGES',
            description: `${Language.translate('server.accounting.bannerpages')}`
          }, {
            id: 'COPIES',
            description: `${Language.translate('server.accounting.copies')}`
          }, {
            id: 'DCR_NAME',
            description: `${Language.translate('server.accounting.dcr_name')}`
          }, {
            id: 'DCR_TYPE',
            description: `${Language.translate('server.accounting.dcr_type')}`
          }, {
            id: 'INPUT_DATE',
            description: `${Language.translate('server.accounting.input_date')}`
          }, {
            id: 'INPUT_TIME',
            description: `${Language.translate('server.accounting.input_time')}`
          }, {
            id: 'OWNER',
            description: `${Language.translate('server.accounting.owner')}`
          }, {
            id: 'BJOBNAME',
            description: `${Language.translate('server.accounting.bjobname')}`
          }, {
            id: 'BJOBID',
            description: `${Language.translate('server.accounting.bjobid')}`
          }, {
            id: 'DOCUMENT_ID',
            description: `${Language.translate('server.accounting.document_id')}`
          }
        ]
      },
      type102: {
        name: `${Language.translate('server.accounting.type')} 102`,
        title: Language.translate('server.accounting.type102.title'),
        description: Language.translate('server.accounting.indexing_document'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          }, {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          }, {
            id: 'FORM',
            description: `${Language.translate('general.form')}`
          }, {
            id: 'EXTENSION',
            description: `${Language.translate('general.extension')}`
          }, {
            id: 'REPORT',
            description: `${Language.translate('general.report')}`
          }, {
            id: 'OWNER',
            description: `${Language.translate('server.accounting.owner')}`
          }, {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          }, {
            id: 'FILETYPE',
            description: `${Language.translate('server.accounting.filetype')}`
          }, {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          }, {
            id: 'INDEXIES',
            description: `${Language.translate('server.accounting.indexies')}`
          }, {
            id: 'INDEXHITS',
            description: `${Language.translate('server.accounting.indexhits')}`
          }, {
            id: 'INPUT_DATE',
            description: `${Language.translate('server.accounting.input_date')}`
          }, {
            id: 'INPUT_TIME',
            description: `${Language.translate('server.accounting.input_time')}`
          }, {
            id: 'DOCUMENT_ID',
            description: `${Language.translate('server.accounting.document_id')}`
          }
        ]
      },
      type103: {
        name: `${Language.translate('server.accounting.type')} 103`,
        title: Language.translate('server.accounting.type103.title'),
        description: Language.translate('server.accounting.print/bundle_document'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          }, {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          }, {
            id: 'BUNDLE_PRINT_NAME',
            description: `${Language.translate('server.accounting.bundle_print_name_print/view')}`
          }, {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          }, {
            id: 'PAGES_ESTIMATED',
            description: `${Language.translate('server.accounting.pages_estimated')}`
          }, {
            id: 'BANNERPAGES',
            description: `${Language.translate('server.accounting.bannerpages')}`
          }, {
            id: 'BUNDLE_COPIES',
            description: `${Language.translate('server.accounting.bundle_copies')}`
          }, {
            id: 'DCR_NAME',
            description: `${Language.translate('server.accounting.dcr_name')}`
          }, {
            id: 'DCR_TYPE',
            description: `${Language.translate('server.accounting.dcr_type')}`
          }, {
            id: 'DCR_PROTOCOL_TYP',
            description: `${Language.translate('server.accounting.dcr_protocol_typ')}`
          }, {
            id: 'DCR_OWNER',
            description: `${Language.translate('server.accounting.dcr_owner')}`
          }, {
            id: 'BJOBNAME',
            description: `${Language.translate('server.accounting.bjobname')}`
          }, {
            id: 'BJOBID',
            description: `${Language.translate('server.accounting.bjobid')}`
          }, {
            id: 'JOB_SUBMIT_DATE',
            description: `${Language.translate('server.accounting.job_submit_date')}`
          }, {
            id: 'JOB_SUBMIT_TIME',
            description: `${Language.translate('server.accounting.job_submit_time')}`
          }, {
            id: 'DELIVERY_INIT_DATE',
            description: `${Language.translate('server.accounting.delivery_init_date')}`
          }, {
            id: 'DELIVERY_INIT_TIME',
            description: `${Language.translate('server.accounting.delivery_init_time')}`
          }, {
            id: 'JOB_END_DATE',
            description: `${Language.translate('server.accounting.job_end_date')}`
          }, {
            id: 'JOB_END_TIME',
            description: `${Language.translate('server.accounting.job_end_time')}`
          }, {
            id: 'OUTPUT_ID',
            description: `${Language.translate('server.accounting.output_id')}`
          }
        ]
      },
      type104: {
        name: `${Language.translate('server.accounting.type')} 104`,
        title: Language.translate('server.accounting.type104.title'),
        description: Language.translate('server.accounting.select_date/index'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          }, {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          }, {
            id: 'USER',
            description: `${Language.translate('server.accounting.user')}`
          }, {
            id: 'FOLDER',
            description: `${Language.translate('server.accounting.folder')}`
          }, {
            id: 'RECIPIENT',
            description: `${Language.translate('server.accounting.recipient')}`
          }, {
            id: 'FORM',
            description: `${Language.translate('general.form')}`
          }, {
            id: 'EXTENSION',
            description: `${Language.translate('general.extension')}`
          }, {
            id: 'REPORT',
            description: `${Language.translate('general.report')}`
          }, {
            id: 'OWNER',
            description: `${Language.translate('server.accounting.owner')}`
          }, {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          }, {
            id: 'FILETYPE',
            description: `${Language.translate('server.accounting.filetype')}`
          }, {
            id: 'START_DATE',
            description: `${Language.translate('server.accounting.start_date')}`
          }, {
            id: 'START_TIME',
            description: `${Language.translate('server.accounting.start_time')}`
          }, {
            id: 'END_DATE',
            description: `${Language.translate('server.accounting.end_date')}`
          }, {
            id: 'END_TIME',
            description: `${Language.translate('server.accounting.end_time')}`
          }, {
            id: 'SELECTED',
            description: `${Language.translate('server.accounting.selected')}`
          }, {
            id: 'RETURNED',
            description: `${Language.translate('server.accounting.returned')}`
          }, {
            id: 'IDX_CMD',
            description: `${Language.translate('server.accounting.idx_cmd')}`
          }
        ]
      },
      type105: {
        name: `${Language.translate('server.accounting.type')} 105`,
        title: Language.translate('server.accounting.type105.title'),
        description: Language.translate('server.accounting.deleting_document_spool'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          }, {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          }, {
            id: 'FORM',
            description: `${Language.translate('general.form')}`
          }, {
            id: 'EXTENSION',
            description: `${Language.translate('general.extension')}`
          }, {
            id: 'REPORT',
            description: `${Language.translate('general.report')}`
          }, {
            id: 'OWNER',
            description: `${Language.translate('server.accounting.owner')}`
          }, {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          }, {
            id: 'FILETYPE',
            description: `${Language.translate('server.accounting.filetype')}`
          }, {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          }, {
            id: 'SPOOL_BLOCKS',
            description: `${Language.translate('server.accounting.spool_blocks')}`
          }, {
            id: 'DATE',
            description: `${Language.translate('server.accounting.document_deletion_date')}`
          }, {
            id: 'TIME',
            description: `${Language.translate('server.accounting.document_deletion_time')}`
          }, {
            id: 'DOCUMENT_ID',
            description: `${Language.translate('server.accounting.document_id')}`
          }
        ]
      },
      type110: {
        name: `${Language.translate('server.accounting.type')} 110`,
        title: Language.translate('server.accounting.type110.title'),
        description: Language.translate('server.accounting.archiving_document'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          }, {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          }, {
            id: 'FORM',
            description: `${Language.translate('general.form')}`
          }, {
            id: 'EXTENSION',
            description: `${Language.translate('general.extension')}`
          }, {
            id: 'REPORT',
            description: `${Language.translate('general.report')}`
          }, {
            id: 'OWNER',
            description: `${Language.translate('server.accounting.owner')}`
          }, {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          }, {
            id: 'FILETYPE',
            description: `${Language.translate('server.accounting.filetype')}`
          }, {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          }, {
            id: 'SPOOL_BLOCKS',
            description: `${Language.translate('server.accounting.spool_blocks')}`
          }, {
            id: 'INPUT_DATE',
            description: `${Language.translate('server.accounting.input_date')}`
          }, {
            id: 'INPUT_TIME',
            description: `${Language.translate('server.accounting.input_time')}`
          }, {
            id: 'RETENTION_PERIOD',
            description: `${Language.translate('server.accounting.retention_period')}`
          }, {
            id: 'EXPIRATION_DATE',
            description: `${Language.translate('server.accounting.expiration_date')}`
          }, {
            id: 'ARCHIVENAME',
            description: `${Language.translate('server.accounting.archivename')}`
          }, {
            id: 'DOCUMENT_ID',
            description: `${Language.translate('server.accounting.document_id')}`
          }
        ]
      },
      type120: {
        name: `${Language.translate('server.accounting.type')} 120`,
        title: Language.translate('server.accounting.type120.title'),
        description: Language.translate('server.accounting.deleting_document_archive'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          }, {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          }, {
            id: 'FORM',
            description: `${Language.translate('general.form')}`
          }, {
            id: 'EXTENSION',
            description: `${Language.translate('general.extension')}`
          }, {
            id: 'REPORT',
            description: `${Language.translate('general.report')}`
          }, {
            id: 'OWNER',
            description: `${Language.translate('server.accounting.owner')}`
          }, {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          }, {
            id: 'FILETYPE',
            description: `${Language.translate('server.accounting.filetype')}`
          }, {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          }, {
            id: 'INPUT_DATE',
            description: `${Language.translate('server.accounting.input_date')}`
          }, {
            id: 'INPUT_TIME',
            description: `${Language.translate('server.accounting.input_time')}`
          }, {
            id: 'RETENTION_PERIOD',
            description: `${Language.translate('server.accounting.retention_period')}`
          }, {
            id: 'EXPIRATION_DATE',
            description: `${Language.translate('server.accounting.expiration_date')}`
          }, {
            id: 'DOCUMENT_ID',
            description: `${Language.translate('server.accounting.document_id')}`
          }
        ]
      },
      type130: {
        name: `${Language.translate('server.accounting.type')} 130`,
        title: Language.translate('server.accounting.type130.title'),
        description: Language.translate('server.accounting.archiving_reloading_deleting'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          }, {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          }, {
            id: 'ACTION',
            description: `${Language.translate('server.accounting.action')}`
          }, {
            id: 'FORM',
            description: `${Language.translate('general.form')}`
          }, {
            id: 'EXTENSION',
            description: `${Language.translate('general.extension')}`
          }, {
            id: 'REPORT',
            description: `${Language.translate('general.report')}`
          }, {
            id: 'OWNER',
            description: `${Language.translate('server.accounting.owner')}`
          }, {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          }, {
            id: 'FILETYPE',
            description: `${Language.translate('server.accounting.filetype')}`
          }, {
            id: 'NUMBER_OF_NOTES',
            description: `${Language.translate('server.accounting.number_of_notes')}`
          }, {
            id: 'INPUT_DATE',
            description: `${Language.translate('server.accounting.input_date')}`
          }, {
            id: 'INPUT_TIME',
            description: `${Language.translate('server.accounting.input_time')}`
          }, {
            id: 'DOCUMENT_ID',
            description: `${Language.translate('server.accounting.document_id')}`
          }
        ]
      },
      type200: {
        name: `${Language.translate('server.accounting.type')} 200`,
        title: Language.translate('server.accounting.type200.title'),
        description: Language.translate('server.accounting.type200.title'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          },
          {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          },
          {
            id: 'JOBGROUP',
            description: `${Language.translate('server.accounting.jobgroup')}`
          },
          {
            id: 'NETID',
            description: `${Language.translate('server.accounting.net_id')}`
          },
          {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          },
          {
            id: 'JOBID',
            description: `${Language.translate('server.accounting.job_id')}`
          },
          {
            id: 'OBTAINED',
            description: `${Language.translate('server.accounting.obtained_zos')}`
          },
          {
            id: 'SUBMIT_USER',
            description: `${Language.translate('server.accounting.submit_user')}`
          },
          {
            id: 'CLASS',
            description: `${Language.translate('server.accounting.class')}`
          },
          {
            id: 'DDNAME',
            description: `${Language.translate('server.accounting.ddname')}`
          },
          {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          },
          {
            id: 'SPOOL_BLOCKS',
            description: `${Language.translate('server.accounting.spool_blocks')}`
          },
          {
            id: 'BYTES',
            description: `${Language.translate('server.accounting.bytes')}`
          },
          {
            id: 'SUBMIT_DATE',
            description: `${Language.translate('server.accounting.submit_date')}`
          },
          {
            id: 'SUBMIT_TIME',
            description: `${Language.translate('server.accounting.submit_time')}`
          }
        ]
      },
      type210: {
        name: `${Language.translate('server.accounting.type')} 210`,
        title: Language.translate('server.accounting.type210.title'),
        description: Language.translate('server.accounting.type210.title'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          },
          {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          },
          {
            id: 'JOBGROUP',
            description: `${Language.translate('server.accounting.jobgroup')}`
          },
          {
            id: 'SYSTEM_NAME',
            description: `${Language.translate('server.accounting.systemname')}`
          },
          {
            id: 'CLIENT_NAME',
            description: `${Language.translate('server.accounting.clientname')}`
          },
          {
            id: 'RUNNUMBER',
            description: `${Language.translate('server.accounting.runnumber')}`
          },
          {
            id: 'AGENT_NAME',
            description: `${Language.translate('server.accounting.agentname')}`
          },
          {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          },
          {
            id: 'OBTAINED',
            description: `${Language.translate('server.accounting.obtained_uc4')}`
          },
          {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          },
          {
            id: 'SPOOL_BLOCKS',
            description: `${Language.translate('server.accounting.spool_blocks')}`
          },
          {
            id: 'BYTES',
            description: `${Language.translate('server.accounting.bytes')}`
          },
          {
            id: 'SUBMIT_DATE',
            description: `${Language.translate('server.accounting.submit_date')}`
          },
          {
            id: 'SUBMIT_TIME',
            description: `${Language.translate('server.accounting.submit_time')}`
          }
        ]
      },
      type220: {
        name: `${Language.translate('server.accounting.type')} 220`,
        title: Language.translate('server.accounting.type220.title'),
        description: Language.translate('server.accounting.type220.title'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'ACC_DATE',
            description: `${Language.translate('server.accounting.acc_date')}`
          },
          {
            id: 'ACC_TIME',
            description: `${Language.translate('server.accounting.acc_time')}`
          },
          {
            id: 'JOBGROUP',
            description: `${Language.translate('server.accounting.jobgroup')}`
          },
          {
            id: 'DATACENTER',
            description: `${Language.translate('server.accounting.datacenter')}`
          },
          {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          },
          {
            id: 'ORDERID',
            description: `${Language.translate('server.accounting.orderid')}`
          },
          {
            id: 'RUNCOUNT',
            description: `${Language.translate('server.accounting.runcount')}`
          },
          {
            id: 'AGENT_NAME',
            description: `${Language.translate('server.accounting.agentname')}`
          },
          {
            id: 'APPLICATION',
            description: `${Language.translate('server.accounting.application')}`
          },
          {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          },
          {
            id: 'SPOOL_BLOCKS',
            description: `${Language.translate('server.accounting.spool_blocks')}`
          },
          {
            id: 'BYTES',
            description: `${Language.translate('server.accounting.bytes')}`
          },
          {
            id: 'ORDER_DATE',
            description: `${Language.translate('server.accounting.order_date')}`
          },
          {
            id: 'START_DATE',
            description: `${Language.translate('server.accounting.job_start_date')}`
          },
          {
            id: 'START_TIME',
            description: `${Language.translate('server.accounting.job_start_time')}`
          }
        ]
      },
      type230: {
        name: `${Language.translate('server.accounting.type')} 230`,
        title: Language.translate('server.accounting.type230.title'),
        description: Language.translate('server.accounting.type230.title'),
        headers: [
          Language.translate('server.accounting.field'),
          Language.translate('server.accounting.description')
        ],
        fields: [
          {
            id: 'SUBLEVEL',
            description: `${Language.translate('server.accounting.sublevel')}`
          },
          {
            id: 'JOBGROUP',
            description: `${Language.translate('server.accounting.jobgroup')}`
          },
          {
            id: 'SYSID',
            description: `${Language.translate('server.accounting.sysid')}`
          },
          {
            id: 'JOBNAME',
            description: `${Language.translate('server.accounting.jobname')}`
          },
          {
            id: 'AGENT_NAME',
            description: `${Language.translate('server.accounting.agentname')}`
          },
          {
            id: 'DDNAME',
            description: `${Language.translate('server.accounting.ddname')}`
          },
          {
            id: 'ATT_COUNT',
            description: `${Language.translate('server.accounting.att_count')}`
          },
          {
            id: 'PAGES',
            description: `${Language.translate('server.accounting.pages')}`
          },
          {
            id: 'SPOOL_BLOCKS',
            description: `${Language.translate('server.accounting.spool_blocks')}`
          },
          {
            id: 'BYTES',
            description: `${Language.translate('server.accounting.bytes')}`
          },
          {
            id: 'START_DATE',
            description: `${Language.translate('server.accounting.job_start_date')}`
          },
          {
            id: 'START_TIME',
            description: `${Language.translate('server.accounting.job_start_time')}`
          }
        ]
      }
    }
  }
}