import * as CustomSelectionActions from '../actions/CustomSelectionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  documents: undefined,
  document: undefined,
  zos: undefined,
  uc4: undefined,
  controlm: undefined,
  syslog: undefined,
  documentPrintInfo: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    case CustomSelectionActions.CUSTOM_SELECTION_CACHE_SEARCH_DATA:
      return { ...state, cache: { ...state.cache, [action.cacheKey]: { searchResults: action.searchResults, customDialog: action.customDialog, customDialogConfig: action.customDialogConfig, resultTable: action.resultTable, searchParameters: action.searchParameters } } }
    case CustomSelectionActions.CUSTOM_SELECTION_CACHE_UPDATED_SEARCH_DATA:
      return { ...state, cache: { ...state.cache, [action.cacheKey]: { ...state.cache[action.cacheKey], searchResults: action.searchResults } } }
    case CustomSelectionActions.CUSTOM_SELECTION_REMOVE_CACHED_SEARCH_DATA: {
      let nextState = { ...state }
      delete nextState.cache[action.cacheKey]
      return nextState
    }
    case CustomSelectionActions.CUSTOM_SELECTION_RESET_CACHE: {
      let nextState = { ...state }
      delete nextState.cache
      return nextState
    }
    // Document get documents actions
    case CustomSelectionActions.CUSTOM_SELECTION_GET_DOCUMENTS_START:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_DOCUMENTS_SUCCESS:
      return { ...state, documents: action.payload, keepPagination: action.keepPagination }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_DOCUMENTS_FAILED:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_NO_DOCUMENTS_FOUND:
      return { ...state, documents: {} }
    /* ********************************************************************************** */
    // Document get document actions
    case CustomSelectionActions.CUSTOM_SELECTION_GET_DOCUMENT_START:
      return { ...state, document: undefined }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_DOCUMENT_SUCCESS:
      return { ...state, document: action.payload }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_DOCUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Document update documents action
    case CustomSelectionActions.CUSTOM_SELECTION_UPDATE_DOCUMENT_START:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_UPDATE_DOCUMENT_SUCCESS:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Document get print info
    case CustomSelectionActions.CUSTOM_SELECTION_GET_PRINT_INFO_START:
      return { ...state, documentPrintInfo: undefined }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_PRINT_INFO_SUCCESS:
      return { ...state, documentPrintInfo: action.payload }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_PRINT_INFO_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get uc4 jobs
    case CustomSelectionActions.CUSTOM_SELECTION_GET_UC4_JOBS_START:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_UC4_JOBS_SUCCESS:
      return { ...state, uc4: { ...state.uc4, uc4Jobs: action.payload, keepPagination: action.keepPagination }}
    case CustomSelectionActions.CUSTOM_SELECTION_GET_UC4_JOBS_FAILED:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_NO_UC4_JOBS_FOUND:
      return { ...state, uc4: { ...state.uc4, uc4Jobs: {} }}
    /* ********************************************************************************** */
    // Get specific uc4 job
    case CustomSelectionActions.CUSTOM_SELECTION_GET_UC4_JOB_START:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_UC4_JOB_SUCCESS:
      return { ...state, uc4: { ...state.uc4, uc4JobDetails: action.payload }}
    case CustomSelectionActions.CUSTOM_SELECTION_GET_UC4_JOB_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get zos jobs
    case CustomSelectionActions.CUSTOM_SELECTION_GET_ZOS_JOBS_START:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_ZOS_JOBS_SUCCESS:
      return { ...state, zos: { ...state.zos, zosJobs: action.payload, keepPagination: action.keepPagination }}
    case CustomSelectionActions.CUSTOM_SELECTION_GET_ZOS_JOBS_FAILED:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_NO_ZOS_JOBS_FOUND:
      return { ...state, zos: { ...state.zos, zosJobs: {} } }
    /* ********************************************************************************** */
    // Get specific zos job
    case CustomSelectionActions.CUSTOM_SELECTION_GET_ZOS_JOB_START:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_ZOS_JOB_SUCCESS:
      return { ...state, zos: { ...state.zos, zosJobDetails: action.payload } }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_ZOS_JOB_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get logs of a job
    case CustomSelectionActions.CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_START:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_SUCCESS:
      return { ...state, zos: { ...state.zos, zosJobLogs: action.payload } }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get controlm jobs
    case CustomSelectionActions.CUSTOM_SELECTION_GET_CONTROLM_JOBS_START:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_CONTROLM_JOBS_SUCCESS:
      return { ...state, controlm: { ...state.controlm, controlmJobs: action.payload, keepPagination: action.keepPagination }}
    case CustomSelectionActions.CUSTOM_SELECTION_GET_CONTROLM_JOBS_FAILED:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_NO_CONTROLM_JOBS_FOUND:
      return { ...state, controlm: { ...state.controlm, controlmJobs: {} }}
    /* ********************************************************************************** */
    // Get syslog jobs
    case CustomSelectionActions.CUSTOM_SELECTION_GET_SYSLOG_JOBS_START:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_SYSLOG_JOBS_SUCCESS:
      return { ...state, syslog: { ...state.syslog, syslogJobs: action.payload, keepPagination: action.keepPagination }}
    case CustomSelectionActions.CUSTOM_SELECTION_GET_SYSLOG_JOBS_FAILED:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_NO_SYSLOG_JOBS_FOUND:
      return { ...state, syslog: { ...state.syslog, syslogJobs: {} }}
    case CustomSelectionActions.CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_START:
      return { ...state }
    case CustomSelectionActions.CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_SUCCESS:
      return {...state, stonebranchJobs: action.payload, keepPagination: action.keepPagination }
    case CustomSelectionActions.CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED:
      return {...state }
    case CustomSelectionActions.CUSTOM_SELECTION_NO_STONEBRANCH_JOBS_FOUND:
      return {...state, stonebranchJobs: { } }
    /* ********************************************************************************** */
    case CustomSelectionActions.CUSTOM_SELECTION_RESET:
      return { ...state, documents: undefined, document: undefined, zos: undefined, uc4: undefined, controlm: undefined, syslog: undefined, stonebranchJobs: undefined }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return defaultState
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer