import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as SortUtils from 'utils/SortUtils'

// Components
import { DataTable, DownloadWrapper, EmptySearchResult, Link, NoSearch, ResultContainer, TableButton } from 'BetaUX2Web-Components/src/'
import TableSettings from 'components/table_settings/TableSettings'

import ArchiveFileInformationDialog from 'components/dialogs/archive_file_information_dialog/ArchiveFileInformationDialog'

// redux
import { translate } from 'language/Language'
import * as ArchiveFileActions from 'redux/actions/ArchiveFileServerActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultArchiveFile extends Component {
  state = {
    header: this.fillHeaderInformation(),
    showTableSettingsDialog: false,
    showInfoDialog: false
  }

  fillHeaderInformation() {
    return [
      { rest: FILE, translation: 'definition.archive_file', default: true },
      { rest: ARCHIVEPOOL_ID, translation: 'definition.archive_pool_id', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: EXPIRES, translation: 'general.expires', default: true },
      { rest: ATOKEN },
      { rest: AGRORDER },
      { rest: ALFNDNR },
    ]
  }

  /**
   * @description Handles the modal dialogs for modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { archiveFiles, getArchiveFile, getArchiveObjects } = this.props
    const token = archiveFiles.data[index][this.headerData('ATOKEN')]
    // order is used for both AGRORDER and AORORDER for the requests
    const order = archiveFiles.data[index][this.headerData('AGRORDER')]
    const number = archiveFiles.data[index][this.headerData('ALFNDNR')]

    getArchiveFile(token, order, number, () => {
      getArchiveObjects(token, order, number, () => this.setState({ [dialog]: true }))
    })
  }

  /**
   * @description Refreshes the table
   */
  handleRefresh = () => {
    const { preferences, getArchiveFiles } = this.props

    getArchiveFiles(
      undefined,
      preferences[Preferences.SERVER_ARCHIVEFILE_ARCHIVEPOOL_ID],
      preferences[Preferences.SERVER_ARCHIVEFILE_FILE],
      DateUtils.getDateFromUnixTimestamp(preferences[Preferences.SERVER_ARCHIVEFILE_ARCHIVEDATE], DateUtils.DDMMYYYY_DOT),
      preferences[Preferences.SERVER_ARCHIVEFILE_LEVEL3ARCHIVE],
      preferences[Preferences.SERVER_ARCHIVEFILE_OWNER],
    )
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description gets the index of the header in redux state folders.header
   * @param {String} header header name of the header in redux state folders.header
   */
  headerData = header => this.props.archiveFiles.header.indexOf(header)


  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType='material'
        iconName='info'
        title={translate('general.information')}
        onClick={() => this.handleDialog(rowIndex, 'showInfoDialog')}
      />
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, archiveFiles } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    archiveFiles.data.forEach(element => {
      let dataBuffer = []
      usedHeader.forEach(h => {
        if (h === EXPIRES) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('AGREXPDT')]))
        }
        else {
          const val = element[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_ARCHIVEFILE]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_ARCHIVEFILE].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_ARCHIVEFILE]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_ARCHIVEFILE].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  render = () => {
    const { archiveFiles, id, drawerExpanded, autoDrawer, lang, datemask } = this.props
    const { showTableSettingsDialog, showInfoDialog } = this.state
    let data = archiveFiles && archiveFiles.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_SERVER_ARCHIVEFILE }}
          />)}
        {showInfoDialog && (
          <ArchiveFileInformationDialog
            id={id}
            onClose={() => this.setState({ showInfoDialog: false })}
          />)}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {archiveFiles
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={index => this.handleDialog(index, 'showInfoDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                  />
                )
                : (
                  <EmptySearchResult
                    id={`${id}_emptysearchresult`}
                    description={translate('general.no_data_found')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const FILE = 'AGRFILE'
const ARCHIVEPOOL_ID = 'ARCPOOL'
const OWNER = 'OWNER'
const EXPIRES = 'AGREXPDT'
const ATOKEN = 'ATOKEN'
const AGRORDER = 'AGRORDER'
const ALFNDNR = 'ALFNDNR'

SearchResultArchiveFile.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    archiveFiles: state.archivefiles.archiveFiles,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getArchiveFiles: (fields, pool, file, archiveDate, level3arc, owner, callback) => {
      ArchiveFileActions.getArchiveFiles(fields, pool, file, archiveDate, level3arc, owner, callback)(dispatch)
    },
    getArchiveFile: (archiveToken, reloadOrder, archiveNumber, callback) => {
      ArchiveFileActions.getArchiveFile(archiveToken, reloadOrder, archiveNumber, callback)(dispatch)
    },
    getArchiveObjects: (archiveToken, reloadOrder, archiveNumber, callback) => {
      ArchiveFileActions.getArchiveObjects(archiveToken, reloadOrder, archiveNumber, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultArchiveFile)