import React, { Component } from 'react'
// import ReactDOMServer from 'react-dom/server'
import PropTypes from 'prop-types'

import './DataTable.scss'

export default class AdditionalRow extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string,
    /** Array of opened rows*/
    openRows: PropTypes.array.isRequired,
    /**
     * Data which is displayed in DataTable component
     *
     * Type: `array<array<any>>`
     */
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    /** Index of the row */
    index: PropTypes.number.isRequired,
    /** Array of previously opened rows*/
    prevOpenRows: PropTypes.array.isRequired,
    /**
     * Function which returns JSX element, filled with data for specified index
     *
     * @param {object} data Data for specified _index_ passed to _expandableRowData_
     * @param {number} index Index of the row
     */
    expandableRowElements: PropTypes.func.isRequired,
    /** Number of columns in the data table which should be merged */
    tableCols: PropTypes.number.isRequired
  }

  /**
   * @description Handles the button behavior when expand a row.
   */
  componentDidUpdate = () => {
    const { index } = this.props
    const el = document.querySelector(`#datatable_additionalrow_${index} .expandableRowContainer`)
    const button = el && el.querySelector('button')
    if (el && el.contains(button)) {
      setTimeout(() => {
        button.classList.remove('bux_hide_btn')
        button.classList.add('bux_show_btn')
      }, 500)
    }
  }

  /**
   * @description Prevent unnecessary rerendering.
   */
  // TODO: As discussed by KeHu and PaDi the check if the row is open should be given as a prop
  // from the parent component and then the check if the data in the openedRow has been changed is easier to
  // until this change has been done, the check if not really performance-relevant. Will be done by @PaDi
  // shouldComponentUpdate = nextProps => {
  //   return (
  //     (this.props.openRows.length !== nextProps.openRows.length) ||
  //     (this.props.expandableRowElements && !nextProps.expandableRowElements) || // expandableRowElements-function Not longer given
  //     (!this.props.expandableRowElements && nextProps.expandableRowElements) || // expandableRowElements-function Now given
  //     (this.props.expandableRowElements && // Result which is returned when expandableRowElements would be called (row is expanded) has been changed
  //       this.props.openRows.includes(this.props.index) && nextProps.openRows.includes(nextProps.index) &&
  //       ReactDOMServer.renderToString(this.props.expandableRowElements(this.props.data.filter(d => d[0] === this.props.index)[0], this.props.index)) !==
  //       ReactDOMServer.renderToString(nextProps.expandableRowElements(nextProps.data.filter(d => d[0] === nextProps.index)[0], nextProps.index))
  //     )
  //   )
  // }

  /**
   * @description Renders the component.
   */
  render() {
    const { id, openRows, data, index, prevOpenRows, expandableRowElements, tableCols, } = this.props
    return (
      <tr
        className={'bux_datatable_additionalrow'}
        id={`${id}_additionalrow_${index}`}
        key={`maintable_tr_opentr_${index}`}
      >
        <td colSpan={tableCols}>
          <div className={`bux_expandable_content ${openRows.includes(index) ? (!prevOpenRows.includes(index) ? 'bux_expand_row' : 'bux_open_row') : ''}`}>
            {openRows.includes(index) && expandableRowElements(data.filter(d => d[0] === index)[0], index)}
          </div>
        </td>
      </tr>
    )
  }
}