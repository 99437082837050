import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'

// Components
import {
  Button, Card, Column,
  Input,
  Modal as ModalComponent, MultilineInput, Row
} from 'BetaUX2Web-Components/src/'
import { LetterCase } from 'BetaUX2Web-Components/src/types'
const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as OutputFormatActions from 'redux/actions/OutputFormatDefinitionActions'
import * as Preferences from 'redux/general/Preferences'

// Utils
import { validateInputID } from 'utils/DefinitionUtils'
import { hasNoValues } from 'utils/ObjectUtils'


class CreateOutputFormatDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    copyOutputFormat: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    prefilledData: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.defaultState = {
      outputFormatID: {
        value: this.props.copyOutputFormat ? this.props.outputFormat.PCR : '',
        errorkey: ''
      },
      owner: {
        value: this.props.copyOutputFormat ? this.props.outputFormat.OWNER : '',
        errorkey: ''
      },
      title: {
        value: this.props.copyOutputFormat ? this.props.outputFormat.PCRTITLE : '',
        errorkey: ''
      },
      values: {
        value: this.props.copyOutputFormat ? this.props.outputFormat.PCROUTP : '',
        errorkey: ''
      }
    }
    this.state = _.cloneDeep(this.defaultState)
  }

  resetState = () => {
    this.setState(_.cloneDeep(this.defaultState))
  }

  outputFormatIDInput = React.createRef()

  componentDidMount() {
    this.outputFormatIDInput.current.focus()

    if (!this.props.copyOutputFormat && this.props.prefilledData) {
      this.setState({
        outputFormatID: {
          value: this.props.prefilledData?.PCR ?? '',
          errorkey: ''
        },
        owner: {
          value: this.props.prefilledData?.OWNER ?? '',
          errorkey: ''
        },
        title: {
          value: this.props.prefilledData?.PCRTITLE ?? '',
          errorkey: ''
        }
      })
    }
  }

  /**
   * @description Handles changes in input fields.
   * @param id The id of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInput = (id, value, errorkey) => this.setState({ [id]: { value, errorkey } })

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInput(key, value, error)
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = { ...validateInputID('outputFormatID', this.state.outputFormatID, 'output_format', this.props.copyOutputFormat ? this.props.outputFormat.PCR : undefined) }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.outputFormatIDInput.current && this.outputFormatIDInput.current.focus()
      })
    }
    return errors === 0
  }

  /**
   * @description Handles the save action
   */
  handleSave = () => {
    const { outputFormatID, owner, title, values } = this.state
    if (this.validateInputs()) {
      const outputFormat = {
        PCR: outputFormatID.value,
        OWNER: owner.value,
        PCRTITLE: title.value,
        PCROUTP: values.value.split('\n').join(' ')
      }
      const callback = () => {
        this.props.onClose()
      }
      this.props.createOutputFormat(outputFormat, callback)
    }
  }

  render = () => {
    const { id, onClose, copyOutputFormat } = this.props
    const { outputFormatID, owner, title, values } = this.state
    return (
      <Modal onClose={onClose}
        id={`${id}`}
        className='bux_UserProfileModal'>
        <Header
          id={`${id}`}
          title={copyOutputFormat ? translate('definition.copy_output_format') : translate('definition.create_output_format')}
          onClose={onClose}>
        </Header>
        <Main id={`${id}`}>
          <Card>
            <Row>
              <Column colMD={6}>
                <Input
                  id={`${id}_outputformat_id`}
                  title={translate('definition.output_format_id')}
                  ref={this.outputFormatIDInput}
                  maxLength={16}
                  value={outputFormatID.value}
                  error={outputFormatID.errorkey ? translate(outputFormatID.errorkey) : null}
                  onInputChanged={(val, err) => this.handleChangeWithoutSpaces('outputFormatID', val, err)}
                  onBlur={() => this.setState({ ...validateInputID('outputFormatID', outputFormatID, 'output_format', copyOutputFormat ? this.props.outputFormat.PCR : undefined) })}
                  lettercase={LetterCase.uppercase}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
              <Column colMD={3}>
                <Input
                  id={`${id}_outputformat_owner`}
                  title={translate('general.owner')}
                  maxLength={8}
                  value={owner.value}
                  onInputChanged={(val, err) => this.handleInput('owner', val, err)}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <Input
                  id={`${id}_outputformat_title`}
                  title={translate('general.title')}
                  maxLength={80}
                  value={title.value}
                  onInputChanged={(val, err) => this.handleInput('title', val, err)}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <MultilineInput
                  id={`${id}_outputformat_values`}
                  title={translate('definition.output_format_values')}
                  onInputChanged={val => this.handleInput('values', val)}
                  value={values.value}
                  rows={6}
                  maxLength={640}
                  maxLengthIncludesLinebreaks
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          {this.props.prefilledData && !hasNoValues(this.props.prefilledData) &&
            <Button
              id={`${id}_resetbtn`}
              tooltip={translate('general.reset')}
              icon={'undo'}
              onClick={this.resetState}
            />
          }
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    outputFormat: state.definitions.outputformats.outputFormat
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createOutputFormat: (outputFormat, callback) => {
      OutputFormatActions.createOutputFormat(outputFormat, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOutputFormatDialog)