import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SearchStandardSearch from './search_standard_search/SearchStandardSearch'
import SearchCustomSearch from './search_custom_search/SearchCustomSearch'
import SearchZosSearch from './search_zos_search/SearchZosSearch'
import SearchUc4Search from './search_uc4_search/SearchUc4Search'
import SearchControlMSearch from './search_controlm/SearchControlMSearch'
import SearchSyslogSearch from './search_syslog_search/SearchSyslogSearch'
import SearchStonebranchSearch from './search_stonebranch/SearchStonebranchSearch'

class SearchBody extends Component {

  /**
   * @description Gets the body.
   * @returns {Element} The element of the body.
   */
  getBody() {
    switch (this.props.searchMode) {
      case 'standardselection': return <SearchStandardSearch id={this.props.id} />
      case 'customsearch': return <SearchCustomSearch id={this.props.id} />
      case 'zos': return <SearchZosSearch id={this.props.id} />
      case 'uc4': return <SearchUc4Search id={this.props.id} />
      case 'controlm': return <SearchControlMSearch id={this.props.id} />
      case 'stonebranch': return <SearchStonebranchSearch id={this.props.id} />
      case 'syslog': return <SearchSyslogSearch id={this.props.id} />
      default: return null
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

SearchBody.propTypes = {
  id: PropTypes.string.isRequired,
}

export default SearchBody