import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

export const SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_START = 'SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_START'
export const SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_SUCCESS = 'SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_SUCCESS'
export const SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_FAILED = 'SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_FAILED'
export const SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_NO_FOUND = 'SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_NO_FOUND'

export const SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_START = 'SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_START'
export const SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_SUCCESS = 'SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_SUCCESS'
export const SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_FAILED = 'SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_FAILED'

export const SEARCHARGUMENTS_DEFINITION_REFRESH_SEARCHARGUMENT_SUCCESS = 'SEARCHARGUMENTS_DEFINITION_REFRESH_SEARCHARGUMENT_SUCCESS'
export const SEARCHARGUMENTS_DEFINITION_REFRESH_SEARCHARGUMENT_FAILED = 'SEARCHARGUMENTS_DEFINITION_REFRESH_SEARCHARGUMENT_FAILED'

export const SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_START = 'SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_START'
export const SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_SUCCESS = 'SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_SUCCESS'
export const SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_FAILED = 'SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_FAILED'

export const SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_VALUE_START = 'SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_VALUE_START'
export const SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_VALUE_SUCCESS = 'SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_VALUE_SUCCESS'
export const SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_VALUE_FAILED = 'SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_VALUE_FAILED'

export const SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_START = 'SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_START'
export const SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_SUCCESS = 'SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_SUCCESS'
export const SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_FAILED = 'SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_FAILED'

export const SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_VALUE_START = 'SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_VALUE_START'
export const SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_VALUE_SUCCESS = 'SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_VALUE_SUCCESS'
export const SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_VALUE_FAILED = 'SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_VALUE_FAILED'

export const SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_START = 'SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_START'
export const SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_SUCCESS = 'SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_SUCCESS'
export const SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_FAILED = 'SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_FAILED'

export const SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_START = 'SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_START'
export const SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_SUCCESS = 'SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_SUCCESS'
export const SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_FAILED = 'SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_FAILED'

export const SEARCHARGUMENTS_DEFINITION_MODIFY_SEARCHARGUMENT_START = 'SEARCHARGUMENTS_DEFINITION_MODIFY_SEARCHARGUMENT_START'
export const SEARCHARGUMENTS_DEFINITION_MODIFY_SEARCHARGUMENT_SUCCESS = 'SEARCHARGUMENTS_DEFINITION_MODIFY_SEARCHARGUMENT_SUCCESS'
export const SEARCHARGUMENTS_DEFINITION_MODIFY_SEARCHARGUMENT_FAILED = 'SEARCHARGUMENTS_DEFINITION_MODIFY_SEARCHARGUMENT_FAILED'

/**
 * @description Calling the rest api and do getSearchArguments request.
 * @param {Object} fields The fields to select (If undefined => Return all fields).
 * @param {String} sasid The search argument id.
 * @param {String} form The search argument form.
 * @param {String} extension The search argument extension.
 * @param {String} report The search argument report.
 * @param {Function} callback the callback which will be called when the request was successful.
 */
export function getSearchArguments(fields, sasid, form, extension, report, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (sasid) { queryParams.push(`SASID=${encodeURIComponent(sasid)}`) }
    if (form) { queryParams.push(`FORM=${encodeURIComponent(form)}`) }
    if (extension) { queryParams.push(`EXT=${encodeURIComponent(extension)}`) }
    if (report) { queryParams.push(`REPORT=${encodeURIComponent(report)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searcharguments?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_NO_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.recipients_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 *
 * @param {Object} searchArgument The search argument definition, possible fields:
 * SASID: "string",
 * SASTITLE: "string",
 * OWNER: "string",
 * FORM: "string",
 * EXTENSION: "string",
 * REPORT: "string",
 * SASTYPE: "VALUE",
 * SASFCOL: 0,
 * SASTCOL: 0,
 * SASFROW: 0,
 * SASTROW: 0,
 * SASPNUM: 0
 * @param {*} callback The callback which will be called when the request was successful. Can be null.
 */
export function createSearchArgument(searchArgument, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    let documentInfo = ''
    documentInfo += `${Lang.translate('general.form')}: ${searchArgument.FORM}`
    if (searchArgument.EXTENSION !== '') {
      documentInfo += `, ${Lang.translate('general.extension')}: ${searchArgument.EXTENSION}`
    }
    if (searchArgument.REPORT !== '') {
      documentInfo += `, ${Lang.translate('general.report')}: ${searchArgument.REPORT}`
    }

    dispatch({ type: SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchargument`, {
        method: 'post',
        body: ObjectUtils.removeByValue(searchArgument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.create_search_argument_success', lang, [searchArgument.SASID, documentInfo]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          const sasid = prefs[Preferences.DEFINITION_SEARCHARGUMENT_ID]
          const form = prefs[Preferences.DEFINITION_SEARCHARGUMENT_FORM]
          const extension = prefs[Preferences.DEFINITION_SEARCHARGUMENT_EXTENSION]
          const report = prefs[Preferences.DEFINITION_SEARCHARGUMENT_REPORT]
          getSearchArguments(undefined, sasid, form, extension, report, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.create_search_argument_error', lang, [searchArgument.SASID, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets a search argument.
 * @param {String} sasid The id of the search argument.
 * @param {String} form The form of the search argument.
 * @param {String} ext The extension of the search argument.
 * @param {String} report The report of the search argument.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getSearchArgument(sasid, form, ext, report, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_START })

    const queryParams = []
    queryParams.push(`SASID=${encodeURIComponent(sasid)}`)
    queryParams.push(`FORM=${encodeURIComponent(form)}`)
    queryParams.push(`EXTENSION=${encodeURIComponent(ext)}`)
    queryParams.push(`REPORT=${encodeURIComponent(report)}`)

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchargument?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.get_search_argument_error', lang, [sasid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Refreshs the search argument after search argument values has changed.
 * @param {String} sasid The id of the search argument.
 * @param {String} form The form of the search argument.
 * @param {String} ext The extension of the search argument.
 * @param {String} report The report of the search argument.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function refreshSearchArgument(sasid, form, ext, report, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]

    const queryParams = []
    queryParams.push(`SASID=${encodeURIComponent(sasid)}`)
    queryParams.push(`FORM=${encodeURIComponent(form)}`)
    queryParams.push(`EXTENSION=${encodeURIComponent(ext)}`)
    queryParams.push(`REPORT=${encodeURIComponent(report)}`)

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchargument?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_REFRESH_SEARCHARGUMENT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_REFRESH_SEARCHARGUMENT_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.get_search_argument_error', lang, [sasid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCHARGUMENTS_DEFINITION_REFRESH_SEARCHARGUMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes the selected search argument entry
 * @param {Object} The search argument definition, possible fields:
 * SASID: "string",
 * OWNER: "string",
 * FORM: "string",
 * EXTENSION: "string",
 * PROCESS: 'DELETE', 'FORCE'
 * @param {*} callback The callback which will be called when the request was successful. Can be null.
 */
export function deleteSearchArgument(searchArgument, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let documentInfo = ''
    documentInfo += `${Lang.translate('general.form')}: ${searchArgument.FORM}`
    if (searchArgument.EXTENSION !== '') {
      documentInfo += `, ${Lang.translate('general.extension')}: ${searchArgument.EXTENSION}`
    }
    if (searchArgument.REPORT !== '') {
      documentInfo += `, ${Lang.translate('general.report')}: ${searchArgument.REPORT}`
    }

    dispatch({ type: SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchargument${createQueryParamsForFetch(ObjectUtils.removeByValue(searchArgument, [undefined, null]))}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.delete_search_argument_success', lang, [searchArgument.SASID, documentInfo]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          const sasid = prefs[Preferences.DEFINITION_SEARCHARGUMENT_ID]
          const form = prefs[Preferences.DEFINITION_SEARCHARGUMENT_FORM]
          const extension = prefs[Preferences.DEFINITION_SEARCHARGUMENT_EXTENSION]
          const report = prefs[Preferences.DEFINITION_SEARCHARGUMENT_REPORT]
          getSearchArguments(undefined, sasid, form, extension, report, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_search_argument_error', lang, [searchArgument.SASID, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets a list of search argument definitions they are referenced in other context.
 * @param {String} searchArgument The search argument .
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function verifySearchArgument(searchArgument, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchargument/verify`, {
        method: 'post',
        body: ObjectUtils.removeByValue(searchArgument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          if (rc.toString() === '0016' && irc.toString() === '0000') {
            SnackbarActions.show(Lang.translate('general.verify_error'), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.verify_search_argument_error', lang, [searchArgument, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_FAILED, payload: { error } })
      })
  }
}

export function copySearchArgument(searchArgument, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let documentInfo = ''
    documentInfo += `${Lang.translate('general.form')}: ${searchArgument[0].FORM}`
    if (searchArgument[0].EXTENSION !== '') {
      documentInfo += `, ${Lang.translate('general.extension')}: ${searchArgument[0].EXTENSION}`
    }
    if (searchArgument[0].REPORT !== '') {
      documentInfo += `, ${Lang.translate('general.report')}: ${searchArgument[0].REPORT}`
    }

    dispatch({ type: SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchargument/values`, {
        method: 'post',
        body: searchArgument.map(el => ObjectUtils.removeByValue(el, [undefined, null]))
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          if (rc.toString() === '0016' && irc.toString() === '0000') {
            SnackbarActions.show(Lang.translate('general.copy_error'), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.create_search_argument_success', lang, [searchArgument[0].SASID, documentInfo]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          const sasid = prefs[Preferences.DEFINITION_SEARCHARGUMENT_ID]
          const form = prefs[Preferences.DEFINITION_SEARCHARGUMENT_FORM]
          const extension = prefs[Preferences.DEFINITION_SEARCHARGUMENT_EXTENSION]
          const report = prefs[Preferences.DEFINITION_SEARCHARGUMENT_REPORT]
          getSearchArguments(undefined, sasid, form, extension, report, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.create_search_argument_error', lang, [searchArgument, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_FAILED, payload: { error } })
      })
  }
}

export function createSearchArgumentValue(searchArgumentValue, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_VALUE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchargumentvalue`, {
        method: 'post',
        body: ObjectUtils.removeByValue(searchArgumentValue, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_VALUE_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_VALUE_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.create_searchargument_value_success', lang, searchArgumentValue.SAASARG), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshSearchArgument(
            searchArgumentValue.SASID,
            searchArgumentValue.FORM,
            searchArgumentValue.EXTENSION,
            searchArgumentValue.REPORT,
            undefined
          )(dispatch)

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.create_searchargument_value_error', lang, [searchArgumentValue.SAASARG, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_VALUE_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function deleteSearchArgumentValue(searchArgumentValue, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_VALUE_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchargumentvalue${createQueryParamsForFetch(ObjectUtils.removeByValue(searchArgumentValue, [undefined, null]))}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_VALUE_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_VALUE_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.delete_searchargument_value_success', lang, searchArgumentValue.SAASARG), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          refreshSearchArgument(
            searchArgumentValue.SASID,
            searchArgumentValue.FORM,
            searchArgumentValue.EXT,
            searchArgumentValue.REPORT,
            undefined
          )(dispatch)

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_searchargument_value_error', lang, [searchArgumentValue.SAASARG, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_VALUE_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function modifySearchArgument(searchArgument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    let documentInfo = ''
    documentInfo += `${Lang.translate('general.form')}: ${searchArgument.FORM}`
    if (searchArgument.EXTENSION !== '') {
      documentInfo += `, ${Lang.translate('general.extension')}: ${searchArgument.EXTENSION}`
    }
    if (searchArgument.REPORT !== '') {
      documentInfo += `, ${Lang.translate('general.report')}: ${searchArgument.REPORT}`
    }

    dispatch({ type: SEARCHARGUMENTS_DEFINITION_MODIFY_SEARCHARGUMENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchargument`, {
        method: 'put',
        body: ObjectUtils.removeByValue(searchArgument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_MODIFY_SEARCHARGUMENT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: SEARCHARGUMENTS_DEFINITION_MODIFY_SEARCHARGUMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.modify_searchargument_success', lang, [searchArgument.SASID, documentInfo]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          const sasid = prefs[Preferences.DEFINITION_SEARCHARGUMENT_ID]
          const form = prefs[Preferences.DEFINITION_SEARCHARGUMENT_FORM]
          const extension = prefs[Preferences.DEFINITION_SEARCHARGUMENT_EXTENSION]
          const report = prefs[Preferences.DEFINITION_SEARCHARGUMENT_REPORT]
          getSearchArguments(undefined, sasid, form, extension, report, undefined, true)(dispatch)

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.modify_searchargument_error', lang, [searchArgument.SASID, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCHARGUMENTS_DEFINITION_MODIFY_SEARCHARGUMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}