import React, { Component } from 'react'

export default class DateTimePickerMonths extends Component {

	renderMonths = () => {
		let date = this.props.selectedDate
		let month = this.props.viewDate.month()
		let year = this.props.viewDate.year()
		let rows = []
		let i = 0
		let months = []
		let renderer = this.props.renderMonth || this.renderMonth
		let isValid = this.props.isValidDate || this.alwaysValidDate
		let classes
		let props
		let currentMonth
		let isDisabled
		let noOfDaysInMonth
		let daysInMonth
		let validDay
		// Date is irrelevant because we're only interested in month
		let irrelevantDate = 1

		while (i < 12) {
			classes = 'rdtMonth'
			currentMonth = this.props.viewDate.clone().set({ year: year, month: i, date: irrelevantDate })

			noOfDaysInMonth = currentMonth.endOf('month').format('D')
			daysInMonth = Array.from({ length: noOfDaysInMonth }, (e, i) =>  {
				return i + 1
			})

			// eslint-disable-next-line
			validDay = daysInMonth.find(d => {
				let day = currentMonth.clone().set( 'date', d )
				return isValid(day)
			})

			isDisabled = validDay === undefined

			if (isDisabled)
				classes += ' rdtDisabled'

			if (date && i === date.month() && year === date.year())
				classes += ' rdtActive'

			props = {
				key: i,
				'data-value': i,
				className: classes
			}

			if (!isDisabled)
				props.onClick = this.props.updateOn === 'months'
					? this.updateSelectedMonth
					: this.props.setDate('month')

			months.push(renderer(props, i, year, date && date.clone()))

			if (months.length === 4) {
				rows.push(React.createElement('tr', { key: month + '_' + rows.length }, months))
				months = []
			}
			i++
		}
		return rows
	}

	updateSelectedMonth = event => {
		this.props.updateSelectedDate(event)
	}

	renderMonth = (props, month) => {
		var localMoment = this.props.viewDate
		var monthStr = localMoment.localeData().monthsShort(localMoment.month(month))
		var strLength = 3
		// Because some months are up to 5 characters long, we want to
		// use a fixed string length for consistency
		var monthStrFixedLength = monthStr.substring(0, strLength)
		return React.createElement('td', props, this.capitalize(monthStrFixedLength))
	}

	alwaysValidDate = () => {
		return 1
	}

  capitalize = str => {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	render = () => {
		return React.createElement('div', { className: 'rdtMonths' }, [
			React.createElement('table', { key: 'a' }, React.createElement('thead', {}, React.createElement('tr', {}, [
				React.createElement('th', { key: 'prev', className: 'rdtPrev', onClick: this.props.subtractTime( 1, 'years' ) }, React.createElement('span', { className: 'beta_icon beta_chevron_left' })),
				React.createElement('th', { key: 'year', className: 'rdtSwitch', onClick: this.props.showView( 'years' ), colSpan: 2, 'data-value': this.props.viewDate.year() }, this.props.viewDate.year() ),
				React.createElement('th', { key: 'next', className: 'rdtNext', onClick: this.props.addTime( 1, 'years' )}, React.createElement('span', { className: 'beta_icon beta_chevron_right' }))
			]))),
			React.createElement('table', { key: 'months' }, React.createElement('tbody', { key: 'b' }, this.renderMonths()))
		])
	}
}