import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'

import SearchResultBundleQueue from './bundle/SearchResultBundleQueue'
import SearchResultIndexQueue from './index/SearchResultIndexQueue'
import SearchResultLPDQueue from './lpd/SearchResultLPDQueue'
import SearchResultOutputQueue from './output/SearchResultOutputQueue'
import SearchResultReloadQueue from './reload/SearchResultReloadQueue'

// Redux
import { connect } from 'react-redux'
// Utils
import * as UserUtils from 'utils/UserUtils'

class SearchResultQueue extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    match: PropTypes.any.isRequired,
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }

  getDefaultRoute = () => {
    // || {} is required to get undefined for the destructed variables when userprofile is undefined
    const { VCIENPRQ } = this.props.userprofile || {}
    if (UserUtils.isDOCX()) {
      // take care of the route because if you enter a wrong route you can create an infinite loop!
      return UserUtils.isAdmin()
        ? <Redirect to='/queue/output' /> // For admin Output-Queue is default
        : VCIENPRQ
          ? <Redirect to='/queue/output' /> // Not admin, but allowed to see output-queue
          : <Redirect to='/search/standardselection' /> // Fallbak when no access to /queue-features
    }
    return UserUtils.isAdmin()
      ? <Redirect to='/queue/output' />
      : VCIENPRQ
        ? <Redirect to='/queue/output' /> // Not admin, but allowed to see output-queue
        : <Redirect to='/search/zos' /> // Fallbak when no access to /queue-features
  }

  /**
   * @description Gets the body which should be rendered.
   */
  getBody = () => {
    const { match, id, drawerExpanded, autoDrawer } = this.props
    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }
    switch (url) {
      case 'lpd':
        return (
          <SearchResultLPDQueue
            id={`${id}_lpd`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'output':
        return (
          <SearchResultOutputQueue
            id={`${id}_output`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'bundle':
        return (
          <SearchResultBundleQueue
            id={`${id}_bundle`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'reload':
        return (
          <SearchResultReloadQueue
            id={`${id}_reload`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'index':
        return (
          <SearchResultIndexQueue
            id={`${id}_index`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      // fallback is output queue
      default: return this.getDefaultRoute()
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

const mapStateToProps = state => {
  return {
    userprofile: state.auth.serverdata && state.auth.serverdata.userprofile
  }
}

export default connect(mapStateToProps)(SearchResultQueue)