import { translate } from 'language/Language'
import { Component } from 'react'

import {
  Button, Card, Column, MetaDataGrid, Modal as ModalComponent, Row,
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getStepDetails } from 'redux/actions/OutputQueueActions'
import * as Preferences from 'redux/general/Preferences'

import { getDate } from 'utils/DateUtils'
import { OUTPUT_QUEUE_STATUS_VALUES } from 'utils/QueueUtils'

import './OutputQueueRequestInformation.scss'

class OutputQueueRequestInformation extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  state = {
    showMoreSections: [],
    showMoreData: []
  }

  isShowMoreAvailable = 'MoreInfoPRTPAB'

  /**
   * @description Initalizes the data.
   */
  componentDidMount = () => {
    this.setState({
      showMoreSections: this.fillState(false),
      showMoreData: this.fillState('')
    })
  }

  fillState = param => Array(this.props.outputRequestDetails.length).fill(param)

  /**
   * @description gets the index of the header in redux state
   * @param {String} header header name of the header in redux
   */
  headerData = header => this.props.outputDetails.header.indexOf(header)

  /**
   * @description Renders the header.
   */
  renderHeader = () => {
    const { id, datemask, outputDetails } = this.props
    const status = OUTPUT_QUEUE_STATUS_VALUES.find(el => el.key === outputDetails.data[0][this.headerData('BSTATUS')])?.translationKey
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.output_channel_id'), value: outputDetails.data[0][this.headerData('DCR')] },
          { label: translate('queue.requestqueued'), value: getDate(datemask, outputDetails.data[0][this.headerData('BDATE')], outputDetails.data[0][this.headerData('BTIME')].substring(0, 8)) },
          { label: translate('general.status'), value: translate(status) },
          { label: translate('queue.btoken'), value: outputDetails.data[0][this.headerData('BTOKEN')] },
          { label: translate('database.return_code'), value: outputDetails.data[0][this.headerData('PRTRC')] },
          { label: translate('general.form'), value: outputDetails.data[0][this.headerData('FORM')] },
          { label: translate('general.extension'), value: outputDetails.data[0][this.headerData('EXT')] },
          { label: translate('general.report'), value: outputDetails.data[0][this.headerData('REPORT')] },
          { label: translate('recipient.reci_id'), value: outputDetails.data[0][this.headerData('RECI')] },
          { label: translate('queue.priority'), value: outputDetails.data[0][this.headerData('PRTPRIO')] },
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description Renders the parameter sections
   * @param {Number} index the index of the specific section
   */
  renderParameterSections = index => {
    const { id, outputRequestDetails } = this.props
    let keys = Object.keys(outputRequestDetails[index])
    let values = Object.values(outputRequestDetails[index])
    const showMoreIndex = keys.indexOf(this.isShowMoreAvailable)
    // Removes information if an entry contains more information.
    if (showMoreIndex !== -1) {
      keys = keys.filter((_, i) => i !== showMoreIndex)
      values = values.filter((_, i) => i !== showMoreIndex)
    }
    const items = []
    for (let i = 2; i < values.length; i += 2) {
      items.push(
        <Row key={`parametersection${items.length}`} noMargin>
          <Column colMD={3}>
            <span id={`${id}_${keys[i]}${i}_span`}>{keys[i]}:</span>
          </Column>
          <Column colMD={3}>
            <span id={`${id}_${keys[i]}${i}_value`}>{values[i]}</span>
          </Column>
          {
            keys[i + 1] &&
            <>
              <Column colMD={3}>
                <span id={`${id}_${keys[i + 1]}${i}_span`}>{keys[i + 1]}:</span>
              </Column>
              <Column colMD={3}>
                <span id={`${id}_${keys[i + 1]}${i}_value`}>{values[i + 1]}</span>
              </Column>
            </>
          }
        </Row>
      )
    }
    return items
  }

  /**
   * @description Handles the click more button action
   * @param {Number} index index of the specific section
   */
  handleMoreButton = index => {
    const { outputDetails } = this.props
    const showMoreSections = this.state.showMoreSections
    this.state.showMoreSections.forEach((_, i) => {
      if (i === index) { showMoreSections[i] = true }
    })

    const callback = () => {
      const { outputRequestStepDetails } = this.props
      const showMoreData = this.state.showMoreData
      this.state.showMoreData.forEach((_, i) => {
        if (i === index) { showMoreData[i] = outputRequestStepDetails.PRTPAB }
      })
      this.setState({ showMoreSections, showMoreData })
    }

    this.props.getStepDetails(
      outputDetails.data[0][this.headerData('BTOKEN')],
      'PRTPAB',
      index,
      callback
    )
  }

  /**
   * @description Handles the click less button action
   * @param {Number} index index of the specific section
   */
  handleLessButton = index => {
    const showMoreSections = this.state.showMoreSections
    this.state.showMoreSections.forEach((_, i) => {
      if (i === index) { showMoreSections[i] = false }
    })
    const showMoreData = this.state.showMoreData
    this.state.showMoreData.forEach((_, i) => {
      if (i === index) { showMoreData[i] = '' }
    })
    this.setState({ showMoreSections, showMoreData })
  }

  /**
   * @description Renders the more or less buttons, depending on the current state
   * @param {Number} index index of the specific section
   */
  renderMoreLessButton = index => {
    const { showMoreSections } = this.state
    const { outputRequestDetails } = this.props
    const keys = Object.keys(outputRequestDetails[index])
    return (
      keys.includes(this.isShowMoreAvailable)
        ? !showMoreSections[index]
          ? <Row noMargin>
            <Column colMD={1}>
              <div onClick={() => this.handleMoreButton(index)} className={'moreLessContainer'}>
                <label>
                  {translate('general.more')}
                </label>
                <i className='beta_icon_chevron_down' />
              </div>
            </Column>
          </Row>
          : <Row noMargin>
            <Column colMD={1}>
              <div onClick={() => this.handleLessButton(index)} className={'moreLessContainer'}>
                <label>
                  {translate('general.less')}
                </label>
                <i className='beta_icon_chevron_up' />
              </div>
            </Column>
          </Row>
        : <></>
    )
  }

  /**
   * @description Renders the more section when the more button is clicked
   * @param {Number} index index of the specific section
   */
  renderMoreSections = index => {
    const { showMoreData } = this.state
    const items = []
    let dataString = ''
    if (showMoreData[index]) {
      for (let i = 0; i < showMoreData[index].length; i++) {
        // the charcodes represents the unicode block �, which should be replaced by a readable string
        if (showMoreData[index][i].charCodeAt(0) === 21) {
          dataString += '\n'
        } else if (showMoreData[index][i].charCodeAt(0) === 65533) {
          dataString += 'ö'
        } else {
          dataString += showMoreData[index][i]
        }
      }
      dataString.split('\n').forEach((d, i) => {
        items.push(
          <Row key={`more_section_${index}_row${i}`} noMargin>
            <Column colMD={6}>
              <span>{d}</span>
            </Column>
          </Row>
        )
      })
    }
    return items
  }

  /**
   * @description Renders the main section
   */
  renderMain = () => {
    const { outputRequestDetails } = this.props
    const cards = []
    for (let i = 0; i < outputRequestDetails.length; i++) {
      cards.push(
        <Card key={`card_${cards.length}`} title={outputRequestDetails[i].Section} className={'badm_flex_col'}>
          {this.renderParameterSections(i)}
          {this.renderMoreLessButton(i)}
          {this.renderMoreSections(i)}
        </Card>
      )
    }
    return cards
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={id}
          title={translate('queue.output_information')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main
          id={id}
          flexColumn>
          <Card className={'bux_multi_card_container'}>
            {this.renderMain()}
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    outputDetails: state.queue.output.outputDetails,
    outputRequestDetails: state.queue.output.outputRequestDetails,
    outputRequestStepDetails: state.queue.output.outputRequestStepDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getStepDetails: (token, process, sequenceNumber, callback) => {
      getStepDetails(token, process, sequenceNumber, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutputQueueRequestInformation)