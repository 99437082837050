import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as Utils from 'utils/Utils'

// components
import { Button, Card, Column, Icon, Input, Modal as ModalComponent, Row } from 'BetaUX2Web-Components/src/'

import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import { createFolderDocumentAssignment } from 'redux/actions/FolderDocumentAssignmentAction'
// Redux
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'

const {Modal, Main, Header, Footer} = ModalComponent

class CopyFolderDocumentAssignmentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    form: {
      value: this.props.folderDocumentAssignment.FORM,
      errorkey: ''
    },
    extension: {
      value: this.props.folderDocumentAssignment.EXT,
      errorkey: ''
    },
    report: {
      value: this.props.folderDocumentAssignment.REPORT,
      errorkey: ''
    },
    dynamicReport: {
      value: this.props.folderDocumentAssignment.WREPORT,
      errorkey: ''
    },
    folderID: {
      value: this.props.folderDocumentAssignment.GLRNAME,
      errorkey: ''
    },
    showFolderSelectorDialog: false,
    showDocumentSelectorDialog: false
  }

  formInput = React.createRef()
  folderIdInput = React.createRef()

  componentDidMount = () => {
    this.formInput.current.focus()
  }

  /**
  * @description gets the index of the header in redux state
  * @param {Object} data specific redux object
  * @param {String} header header name of the header in redux
  */
  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param val New value of inputfield
   */
  handleInputChanged = (key, val, err) => {
    this.setState({
      [key]: {
        value: val,
        errorkey: err
      }
    })
  }

  /**
   * @description Requests the index definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handleFolderSelector = () => {
    const { getFolderDefinitions } = this.props
    const { folderID } = this.state
    const callback = () => this.setState({ showFolderSelectorDialog: true })

    getFolderDefinitions(['GLRNAME', 'GLRTITLE'], folderID.value, callback)
  }

  /**
   * @description Requests the document definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handleDocumentSelector = () => {
    const { getDocumentDefinitions } = this.props
    const { form, extension, report } = this.state
    const callback = () => this.setState({ showDocumentSelectorDialog: true })

    getDocumentDefinitions(['FORM', 'EXT', 'REPORT'], form.value, extension.value, report.value, callback)
  }

  /**
   * @description Renders the selector dialogs
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    const { showFolderSelectorDialog, showDocumentSelectorDialog } = this.state
    return (
      <>
        {showFolderSelectorDialog && (
          <SelectorDialog
            id={`${id}_folderdefinition_selector_dialog`}
            onClose={() => this.setState({ showFolderSelectorDialog: false })}
            title={translate('definition.folderdefinitions')}
            header={[
              translate('definition.folder_id'),
              translate('general.title'),
            ]}
            items={selector.folders.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const folderID = selector.folders.data[selectedRows][this.headerData(selector.folders, 'GLRNAME')]
                this.setState({ folderID: { value: folderID } })
              }
              this.setState({ showFolderSelectorDialog: false })
            }}
          />
        )}
        {showDocumentSelectorDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report')
            ]}
            items={selector.documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const form = selector.documents.data[selectedRows][this.headerData(selector.documents, 'FORM')]
                const extension = selector.documents.data[selectedRows][this.headerData(selector.documents, 'EXT')]
                const report = selector.documents.data[selectedRows][this.headerData(selector.documents, 'REPORT')]
                this.setState({ form: { value: form }, extension: { value: extension }, report: { value: report } })
              }
              this.setState({ showDocumentSelectorDialog: false })
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Validates the form.
   */
  validateForm = () => {
    const { form } = this.state
    if (form.value !== '') {
      return {}
    }
    return {
      form: {
        ...this.state.form,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the folder id.
   */
  validateFolder = () => {
    const { folderID } = this.state
    if (folderID.value !== '') {
      return {}
    }
    return {
      folderID: {
        ...this.state.folderID,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = {
      ...this.validateForm(),
      ...this.validateFolder()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input which has an error.
   */
  handleFocus = () => {
    const { form, folderID } = this.state
    const requiredInputs = [
      { inputRef: this.formInput, errorkey: form.error },
      { inputRef: this.folderIdInput, errorkey: folderID.error },
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Creates the entry
   */
  handleSave = () => {
    const { createFolderDocumentAssignment, onClose } = this.props
    const { form, extension, report, dynamicReport, folderID } = this.state
    if (this.validateInputs()) {
      const folderDef = {
        GLRNAME: folderID.value,
        FORM: form.value,
        EXT: extension.value,
        REPORT: report.value,
        WREPORT: dynamicReport.value
      }

      createFolderDocumentAssignment(folderDef, () => onClose())
    }
  }

  getModalTitle = () => {
    return [
      <div key={'folder_document_relation_modal_title_1'}> {`${translate('general.copy')} ${translate('general.folder_modal_title')}`}</div>,
      <Icon key={'folder_document_relation_modal_title_2'} id={'folder_document_relation_modal_title'} name={'relation'}/>,
      <div
        key={'folder_document_relation_modal_title_3'}>
        {`${translate('general.document_modal_title')} ${translate('general.assignment_modal_title')}`}
      </div>,
    ]
  }

  render = () => {
    const { id, onClose } = this.props
    const { form, extension, report, dynamicReport, folderID } = this.state
    return (
      <>
        {this.renderSelectorDialogs()}
        <Modal
          id='copy_folder_document_assignment_dialog'
          className='bux_UserProfileModal'
          onClose={onClose}>
          <Header
            id={id}
            title={this.getModalTitle()}
            onClose={onClose}
          />
          <Main id={id}>
            <Card>
              <Row>
                <Column colMD={3}>
                  <Input
                    id={`${id}_form`}
                    onInputChanged={(val, err) => { this.handleInputChanged('form', val, err) }}
                    value={form.value}
                    title={translate('general.form')}
                    ref={this.formInput}
                    error={form.error && translate(form.error)}
                    maxLength={8}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }}
                    onBlur={() => this.setState({ ...this.validateForm() })}
                    required={`${translate('general.required_field')}`}
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_extension`}
                    onInputChanged={(val, err) => { this.handleInputChanged('extension', val, err) }}
                    value={extension.value}
                    title={translate('general.extension')}
                    maxLength={16}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }}
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_report`}
                    onInputChanged={(val, err) => { this.handleInputChanged('report', val, err) }}
                    value={report.value}
                    title={translate('general.report')}
                    maxLength={16}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }}
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_dynamicreport`}
                    onInputChanged={(val, err) => { this.handleInputChanged('dynamicReport', val, err) }}
                    value={dynamicReport.value}
                    title={translate('assignment.folder_document.dynamic_report')}
                    maxLength={8}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={6}>
                  <Input
                    id={`${id}_folderid`}
                    onInputChanged={(val, err) => {
                      this.handleInputChanged('folderID', val, err)
                    }}
                    value={folderID.value}
                    title={translate('assignment.folder_document.folderid')}
                    ref={this.folderIdInput}
                    error={folderID.error && translate(folderID.error)}
                    maxLength={32}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleFolderSelector(),
                    }}
                    onBlur={() => this.setState({ ...this.validateFolder() })}
                    required={`${translate('general.required_field')}`}
                  />
                </Column>
              </Row>
            </Card>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }

}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    folderDocumentAssignment: state.assignments.folderdocumentassignment.folderDocumentAssignment,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getFolderDefinitions: (fields, foldername, callback) => {
      ModalSelectorActions.getFolderDefinitions(
        fields,
        foldername,
        undefined, // folder title
        undefined, // owner
        undefined, // brw
        callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    createFolderDocumentAssignment: (folderDef, callback) => {
      createFolderDocumentAssignment(folderDef, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyFolderDocumentAssignmentDialog)