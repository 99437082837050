/**
* @description Get object with the values from url according to keys definitions, which allows to map names in url into object properties.
* You can provide keys to map names of params in query into object properties.
* @param url - string to convert
* @param keys - list of object {rest: string, ui: string} which allows to map params in query to object properties
* (rest is name from the query, ui from the object property)
* @return object
*/
export const getDataFromUrlParams = (url, keys) => {
  const params = new URLSearchParams(url)
  const paramsAsObject = Object.fromEntries(params.entries());
  let data = paramsAsObject;
  if (keys) {
    data = {}
    for (let key of keys) {
      data[key.ui] = paramsAsObject[key.rest];
    }
  }
  return data;
}

/**
 * @description Creates the query params which are added to the url dorm data from passed object.
 * You can provide keys to map object properties to different names of params in query.
 * @param object - object to convert to url query params
 * @param keys - list of object {rest: string, ui: string} which allows to map object properties to params in query
 * (rest is name from the query, ui from the object property)
 * @param execute - if true search will be automatically executed after redirect
 * @param omitZeroValues - omit zero values as a number, default true
 * @return string
 */
export const createUrlParamsFromObject = (object, keys, execute = false, omitZeroValues = true) => {
  let data = object;
  if (keys) {
    data = {}
    for (let key of keys) {
      const uiValue = typeof object[key.ui] === 'object' ? object[key.ui].value : object[key.ui];
      if (uiValue !== undefined && uiValue !== '' && (omitZeroValues && uiValue !== 0)) {
        data[key.rest] = uiValue;
      }
    }
  }
  let url = new URLSearchParams(data).toString();
  if (execute) {
    url += '&EXECUTE=true'
  }
  url = '?' + url;
  return url;
}

/**
 * @description Checks if the url includes the key for execute a search.
 */
export const urlContainsExecute = () => {
  const urlParamsString = window.location.href.substring(window.location.href.indexOf('?') + 1)
  let urlParamsSplit = urlParamsString.split('&')
  const executeIndex = urlParamsSplit.findIndex(d => d === 'EXECUTE=true')
  return executeIndex !== -1;

}

/**
 * @description Creates the query string for a fetch request
 * @param {Object} obj
 * @returns {String}
 */
export const createQueryParamsForFetch = obj => {
  let params = '?'
  if (Object.values(obj).length > 0) {
    Object.entries(obj).forEach(([key, value], index) => {
      // Just adds the "&" if it is not the first parameter
      let prefix = ''
      if (index > 0) {
        prefix = '&'
      }
      params += `${prefix}${key}=${encodeURIComponent(value)}`
    })
  }
  else {
    return ''
  }
  return params
}