import React from 'react';

import { Card, Column, Row } from 'BetaUX2Web-Components/src';

import { useTabContentContext } from '../../contexts/TabContentContext';
import { useDrawerContext } from '../../contexts/DrawerContext';

import { translate } from 'language/Language';

export const DrawerMain = () => {
  const { id } = useTabContentContext();
  const { documentInfo } = useDrawerContext();

  const translateTableHeader = (header: string): string => {
    if (header === 'jobid') return translate(`job.${header}`) + ':';
    if (header === 'system' || header === 'source' || header === 'date' || header === 'time' || header === 'jobname')
      return translate(`general.${header}`) + ':';
    return translate(`definition.${header}`) + ':';
  }

  return (
    <div className='bux_drawer_form drawer_content_main'>
      <Card
        id={`${id}_body_documentinfocard`}
        className='bux_pl8 bux_pr8'
        title={translate('documentinformation.modaltitle')}>

        {Object.entries(documentInfo).map((element, key) => {
          if (documentInfo.system === 'LOGX' && (element[0] === 'form' || element[0] === 'extension' || element[0] === 'report')) return null;
          if (documentInfo.system === 'DOCX' && (element[0] === 'host' || element[0] === 'application' || element[0] === 'sublog')) return null;

          return (
            <Row
              key={key}
              id={`${id}_body_documentinfocard_row_${key}`}>
              <Column
                id={`${id}_body_documentinfocard_row_${key}_column_0`}
                colMD={3}
                offsetMD={0}>
                {translateTableHeader(element[0])}
              </Column>
              <Column
                id={`${id}_body_documentinfocard_row_${key}_column_1`}
                colMD={9}
                offsetMD={0}>
                {element[1] === '' || element[1] === undefined ? '-' : element[1]}
              </Column>
            </Row>
          )
        })}
      </Card>
    </div>
  )
};