import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_START = 'OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_START'
export const OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_SUCCESS = 'OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_SUCCESS'
export const OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_FAILED = 'OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_FAILED'
export const NO_OUTPUTFORMAT_DEFINITION_OUTPUTFORMATS_FOUND = 'OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_FAILED'

export const OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_START = 'OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_START'
export const OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_SUCCESS = 'OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_SUCCESS'
export const OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_FAILED = 'OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_FAILED'

export const OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_START = 'OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_START'
export const OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_SUCCESS = 'OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_SUCCESS'
export const OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_FAILED = 'OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_FAILED'

export const OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_START = 'OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_START'
export const OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_SUCCESS = 'OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_SUCCESS'
export const OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_FAILED = 'OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_FAILED'

export const OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_START = 'OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_START'
export const OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_SUCCESS = 'OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_SUCCESS'
export const OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_FAILED = 'OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_FAILED'

export const OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_START = 'OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_START'
export const OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_SUCCESS = 'OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_SUCCESS'
export const OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_FAILED = 'OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_FAILED'

/**
 * @description Calls the rest api and gets an output format.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'PCR', 'OWNER', 'PCRTITLE', 'PCROUTP', 'CDATE', 'CTIME', 'CUSER'
 * @param {String} outputFormat The id of the output format.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getOutputFormat(fields, outputFormat, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (outputFormat) { queryParams.push(`PCR=${encodeURIComponent(outputFormat)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputFormat?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.get_output_format_error', lang, [outputFormat, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMAT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates an output format definition.
 * @param {Object} outputFormat The output format to create
 * "PCR": "string",
 * "OWNER": "string",
 * "PCRTITLE": "string",
 * "PCROUTP": "string",
 * "CUSER": "string",
 * "CDATE": "string",
 * "CTIME": "string"
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function createOutputFormat(outputFormat, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputFormat`, {
        method: 'post',
        body: ObjectUtils.removeByValue(outputFormat, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.create_output_format_success', lang, outputFormat['PCR']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getExternalCommands again to get the current result
          const name = prefs[Preferences.DEFINITION_OUTPUTFORMAT_ID]
          const owner = prefs[Preferences.DEFINITION_OUTPUTFORMAT_OWNER]
          const title = prefs[Preferences.DEFINITION_OUTPUTFORMAT_TITLE]
          getOutputFormats(undefined, name, owner, title, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.create_output_format_error', lang, [outputFormat['PCR'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTFORMAT_DEFINITION_CREATE_OUTPUTFORMAT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and deletes a output format definition.
 * @param {String} outputFormat
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function deleteOutputFormat(outputFormat, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputFormat${createQueryParamsForFetch({ PCR: outputFormat })}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_FAILED, payload: { error } })
        } else {
          dispatch({ type: OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.delete_output_format_success', lang, outputFormat), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          const fields = undefined
          const id = prefs[Preferences.DEFINITION_OUTPUTFORMAT_ID]
          const owner = prefs[Preferences.DEFINITION_OUTPUTFORMAT_OWNER]
          const title = prefs[Preferences.DEFINITION_OUTPUTFORMAT_TITLE]

          getOutputFormats(fields, id, owner, title, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_output_format_error', lang, [outputFormat, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTFORMAT_DEFINITION_DELETE_OUTPUTFORMAT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calling the rest api and do getOutoutFormats request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'PCR', 'PCRTITLE', 'OWNER', 'CDATE', 'CTIME', 'CUSER'
 * @param {String} outputFormat The output format pattern.
 * @param {String} owner The owner pattern.
 * @param {String} title The title of the output format.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getOutputFormats(fields, outputFormat, owner, title, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (outputFormat) { queryParams.push(`PCR=${encodeURIComponent(outputFormat)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`PCRTITLE=${encodeURIComponent(title)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputFormats?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_OUTPUTFORMAT_DEFINITION_OUTPUTFORMATS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.outputformats_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTFORMAT_DEFINITION_GET_OUTPUTFORMATS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function updateOutputFormat(outputFormat, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputFormat`, {
        method: 'put',
        body: outputFormat
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.update_output_format_success', lang, outputFormat['PCR']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getIndexes again to get the current result
          const name = prefs[Preferences.DEFINITION_OUTPUTFORMAT_ID]
          const owner = prefs[Preferences.DEFINITION_OUTPUTFORMAT_OWNER]
          const title = prefs[Preferences.DEFINITION_OUTPUTFORMAT_TITLE]
          getOutputFormats(undefined, name, owner, title, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.update_output_format_error', lang, [outputFormat['PCR'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTFORMAT_DEFINITION_UPDATE_OUTPUTFORMAT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets a list of output format definitions they are referenced in other context.
 * @param {String} outputFormat The output format (PCR).
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function verifyOutputFormat(outputFormat, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputFormat/verify`, {
        method: 'post',
        body: { PCR: outputFormat }
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          if (rc.toString() === '0016' && irc.toString() === '0000') {
            SnackbarActions.show(Lang.translate('general.verify_error'), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
          dispatch({ type: OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_FAILED, payload: { error } })
        } else {
          dispatch({ type: OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.verify_output_format_error', lang, [outputFormat, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTFORMAT_DEFINITION_VERIFY_OUTPUTFORMAT_FAILED, payload: { error } })
      })
  }
}