import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { DownloadWrapper, Link, TableButton } from 'BetaUX2Web-Components/src'
import CtmJobInformationDialog from 'components/dialogs/ctm_job_information_dialog/CtmJobInformationDialog'
import * as SEARCH_CONSTANTS from 'components/drawer/content/search/search_body/search_standard_search/SearchStandardSearch'
import { SelectionTable } from '../common/SelectionTable';
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'

import { addTabToDocViewer } from 'redux/actions/DocViewerActions'
import {
  getControlMJobs,
  getCtmJobDetails,
  getControlMJobLogs,
  downloadJob
} from 'redux/actions/SearchControlMActions'
import * as Preferences from 'redux/general/Preferences'

import { EResultTableType, translateHeaderColumns } from 'utils/ColumnUtils'
import * as DateUtils from 'utils/DateUtils'
import { SEARCH_TYPE_CONTROLM_ALL } from 'utils/DocViewerUtils'
import { fillHeaderInformation, getCleanData, getSavedOrDefaultHeader } from '../search-documents-utils'

const JOBNAME = 'JOBNAME'
const ORDDATE = 'ORDDATE'
const STRTDATE = 'STRTDATE'
const ENDDATE = 'ENDDATE'
const JOBERR = 'JOBERR'
const JOBRC = 'JOBRC'
const DATACENT = 'DATACENT'
const JOBGROUP = 'JOBGROUP'
const ORDERID = 'ORDERID'
const RUNCOUNT = 'RUNCOUNT'
const APPL = 'APPL'
const SUBAPPL = 'SUBAPPL'
const AGNTNAME = 'AGNTNAME'
const RUNTIME = 'RUNTIME'
const DEFAULT_ORDERED_HEADERS = [JOBNAME, ORDDATE, STRTDATE, ENDDATE, JOBERR, JOBRC, DATACENT, JOBGROUP, ORDERID, RUNCOUNT, APPL, SUBAPPL, AGNTNAME, RUNTIME]

const SearchResultControlM = ({ id, drawerExpanded, autoDrawer }) => {
  const preferences = useSelector(store => store.auth.serverdata.preferences)
  const lang = useSelector(store => store.auth.serverdata.preferences[Preferences.LANGUAGE])
  const datemask = useSelector(store => store.auth.serverdata.preferences[Preferences.DATEMASK])
  const keepPagination = useSelector(store => store.search.standardselection.keepPagination)
  const controlmJobs = useSelector(store => store.search.controlm.controlmJobs)
  const dispatch = useDispatch()
  const [showTableSettings, setShowTableSettings] = useState(false)
  const [showJobInformationDialog, setShowJobInformationDialog] = useState(false)
  const [allHeader, setAllHeader] = useState([])
  const [translatedHeader, setTranslatedHeader] = useState([])
  const [settingsTranslatedHeader, setSettingsTranslatedHeader] = useState([])
  const [usedHeader, setUsedHeader] = useState([])
  const [data, setData] = useState(null)

  useEffect(() => {
    const newHeader = fillHeaderInformation(controlmJobs, DEFAULT_ORDERED_HEADERS)
    setAllHeader(newHeader)
  }, [controlmJobs])

  useEffect(() => {
    if (allHeader.length > 0 && usedHeader.length > 0) {
      updateHeadersTranslation()
    }
  }, [allHeader, usedHeader, lang, updateHeadersTranslation])

  useEffect(() => {
    if (allHeader.length > 0) {
      setUsedHeader(getSavedOrDefaultHeader(allHeader, preferences, Preferences.TABLE_SETTINGS_SEARCH_CONTROLM))
    }
  }, [preferences, allHeader])

  useEffect(() => {
    if (allHeader.length > 0 && controlmJobs && controlmJobs.data) {
      setData(getCleanData(controlmJobs, usedHeader, datemask))
    }
    else {
      setData(null)
    }
  }, [allHeader, usedHeader, controlmJobs, datemask])

  /**
   * @description gets the index of the header in redux state controlmJobs.header
   * @param {String} header header name of the header in redux state controlmJobs.header
   */
  const headerData = useCallback(header => controlmJobs.header.indexOf(header), [controlmJobs])

  const updateHeadersTranslation = useCallback(() => {
    const header = getSavedOrDefaultHeader(allHeader, preferences, Preferences.TABLE_SETTINGS_SEARCH_CONTROLM)

    translateHeaderColumns(header, EResultTableType.BRWCTM).then(translatedHeaders => {
      setTranslatedHeader(translatedHeaders)
    })

    translateHeaderColumns(allHeader.map(v => v.rest), EResultTableType.BRWCTM).then(translatedHeaders => {
      const settingsHeader = translatedHeaders.map((v, index) => {
        return {
          rest: allHeader[index].rest,
          translated: v
        }
      })
      setSettingsTranslatedHeader(settingsHeader)
    })

  }, [allHeader, preferences])

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  const getFillPageInfo = () => {
    if (preferences[Preferences.TABLE_SETTINGS_SEARCH_CONTROLM]) {
      return preferences[Preferences.TABLE_SETTINGS_SEARCH_CONTROLM].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  //* Commented out until adding Beta View route additionally:
  /*   const handleDisplayJob = rowIndex => {
    const systemName = controlmJobs.data[rowIndex][headerData('SYSNAME')]
    const runNumber = controlmJobs.data[rowIndex][headerData('RUNNUMB')]
    const startDate = controlmJobs.data[rowIndex][headerData('STRTDATE')]
    const startTime = controlmJobs.data[rowIndex][headerData('STRTTIME')]
    const ukey = controlmJobs.data[rowIndex][headerData('UKEY')]
    displayJob(bwebaseurl, bwesystemname, startDate, startTime, systemName, runNumber, ukey)(dispatch)
  } */

  /**
   * @description This function will open all selected rows as tabs in the DocViewer.
   * @param {Array<number>} selectedRowsIndices This array contains the indices of the selected rows.
   */
  const openSelectedRowsInDocViewer = (selectedRowsIndices) => {
    selectedRowsIndices.forEach(rowIndex => openDocumentViewer(rowIndex))
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  const openDocumentViewer = useCallback((rowIndex) => {
    addTabToDocViewer({
      id: controlmJobs.data[rowIndex][headerData('UKEY')],
      type: SEARCH_TYPE_CONTROLM_ALL,
      header: controlmJobs.header,
      data: controlmJobs.data[rowIndex],
      pageCount: 0 // Will be populated in document viewer to avoid callback problems with multi-display
    })(dispatch)
  }, [controlmJobs, dispatch, headerData])

  /**
    * @description Handles the download functionality of logs in binary format.
    * @param {Number} rowIndex The current row.
    */
  const handleJobDownload = rowIndex => {
    let orderDate = controlmJobs.data[rowIndex][headerData('ORDDATE')]
    const orderID = controlmJobs.data[rowIndex][headerData('ORDERID')]
    const runCount = controlmJobs.data[rowIndex][headerData('RUNCOUNT')]
    const datacenter = controlmJobs.data[rowIndex][headerData('DATACENT')]
    const logSource = controlmJobs.data[rowIndex][headerData('LOGSRC')]

    const queryParams = {
      LOGSOURCE: logSource,
      ORDERDATE: orderDate,
      ORDERID: orderID,
      RUNCOUNT: runCount,
      DATACENTER: datacenter,
    }

    // Replace all unwanted characters.
    orderDate = orderDate.replace(/[/.-]/g, '')

    // Generate name which will be suggested as download-filename
    const filename = [orderDate, orderID, runCount, datacenter].filter(entry => entry !== undefined).join('.') + '.log'
    downloadJob(queryParams, filename)(dispatch)
  }

  const handleDisplayJobInformation = rowIndex => {
    const jobObj = {
      LOGSOURCE: controlmJobs.data[rowIndex][headerData('LOGSRC')],
      ORDERDATE: controlmJobs.data[rowIndex][headerData('ORDDATE')],
      ORDERID: controlmJobs.data[rowIndex][headerData('ORDERID')],
      RUNCOUNT: controlmJobs.data[rowIndex][headerData('RUNCOUNT')],
      DATACENTER: controlmJobs.data[rowIndex][headerData('DATACENT')]
    }

    getCtmJobDetails(undefined, jobObj, () => {
      if (jobObj.LOGSOURCE === 'CTMZOS') {
        const jobObj2 = {
          UKEY: controlmJobs.data[rowIndex][headerData('LINKUKEY')],
          ORDERDATE: controlmJobs.data[rowIndex][headerData('ORDDATE')],
          ORDERID: controlmJobs.data[rowIndex][headerData('ORDERID')],
          RUNCOUNT: controlmJobs.data[rowIndex][headerData('RUNCOUNT')],
          DATACENTER: controlmJobs.data[rowIndex][headerData('DATACENT')]
        }
        getCtmJobDetails(undefined, jobObj2, (data) => {
          const jobObjForLogs = {
            LOGSOURCE: 'ZOS',
            SRCSUBD: data['SRCSUBD'],
            SRCSUBT: data['SRCSUBT'],
            JOBNAME: data['JOBNAME'],
            JOBID: data['JOBID']
          }
          getControlMJobLogs(jobObjForLogs, () => {
            setShowJobInformationDialog(rowIndex)
          })(dispatch)
        })(dispatch)
      } else {
        setShowJobInformationDialog(rowIndex)
      }
    })(dispatch)

  }

  const handleRefresh = () => {
    const activeTabIndex = preferences[Preferences.SEARCH_CONTROLM_ACTIVE_TAB]
    let lastTimeMode = preferences[Preferences.SEARCH_CONTROLM_LASTTIME_MODE]
    let sdate = ''
    let stime = ''
    let edate = ''
    let etime = ''
    let customLast = ''
    let customUnit = ''

    if (activeTabIndex === 0) {
      if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_TODAY || lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_YESTERDAY) {
        sdate = preferences[Preferences.SEARCH_CONTROLM_LASTTIME_MODE].toUpperCase()
      } else if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_CUSTOM) {
        customLast = preferences[Preferences.SEARCH_CONTROLM_TIME_CUSTOM_LAST]
        customUnit = preferences[Preferences.SEARCH_CONTROLM_TIME_CUSTOM_UNIT]
      }
    } else if (activeTabIndex === 1) {
      sdate = preferences[Preferences.SEARCH_CONTROLM_START_DATE] !== '' ? moment(preferences[Preferences.SEARCH_CONTROLM_START_DATE], DateUtils.DDMMYYYY_DOT).format(DateUtils.DDMMYYYY_DOT) : ''
      stime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_CONTROLM_START_TIME])
      edate = preferences[Preferences.SEARCH_CONTROLM_END_DATE] !== '' ? moment(preferences[Preferences.SEARCH_CONTROLM_END_DATE], DateUtils.DDMMYYYY_DOT).format(DateUtils.DDMMYYYY_DOT) : ''
      etime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_CONTROLM_END_TIME])
    }

    const searchParams = {
      'FROMLAST': customLast,
      'TUNITS': customUnit,
      'SDATE': sdate,
      'STIME': stime,
      'EDATE': edate,
      'ETIME': etime,
      'LOGSOURCE': preferences[Preferences.SEARCH_CONTROLM_LOGSOURCE],
      'TUSAGE': preferences[Preferences.SEARCH_CONTROLM_USAGE],
      'JOBERR': preferences[Preferences.SEARCH_CONTROLM_ONLY_JOB_ERRORS],
      'JOBGROUP': preferences[Preferences.SEARCH_CONTROLM_JOBGROUP],
      'LJOBNAME': preferences[Preferences.SEARCH_CONTROLM_JOBNAME],
      'DATACENTER': preferences[Preferences.SEARCH_CONTROLM_DATACENTER],
      'APPLICATION': preferences[Preferences.SEARCH_CONTROLM_APPLICATION],
      'SUBAPPLICATION': preferences[Preferences.SEARCH_CONTROLM_SUBAPPLICATION],
      'AGENTNAME': preferences[Preferences.SEARCH_CONTROLM_AGENT],
      'RUNUSER': preferences[Preferences.SEARCH_CONTROLM_RUNAS],
      'FOLDER': preferences[Preferences.SEARCH_CONTROLM_FOLDER],
      'JOBTYPE': preferences[Preferences.SEARCH_CONTROLM_JOBTYPE],
      'ORDERID': preferences[Preferences.SEARCH_CONTROLM_ORDERID],
      'RUNCOUNT': preferences[Preferences.SEARCH_CONTROLM_RUNCOUNT]
    }

    if (preferences[Preferences.SEARCH_CONTROLM_RETURNCODE_INPUT] && preferences[Preferences.SEARCH_CONTROLM_RETURNCODE_INPUT] !== '') {
      searchParams['JOBRC'] = `${preferences[Preferences.SEARCH_CONTROLM_RETURNCODE_SWITCH]}${preferences[Preferences.SEARCH_CONTROLM_RETURNCODE_INPUT]}`
    }

    if (preferences[Preferences.SEARCH_CONTROLM_RUNTIME_INPUT] && preferences[Preferences.SEARCH_CONTROLM_RUNTIME_INPUT] !== '') {
      searchParams['RUNTIME'] = `${preferences[Preferences.SEARCH_CONTROLM_RUNTIME_SWITCH]}${preferences[Preferences.SEARCH_CONTROLM_RUNTIME_INPUT]}`
    }

    getControlMJobs(undefined, searchParams)(dispatch)
  }

  return (
    <>
      {
        showTableSettings &&
        <TableSettings
          id={id}
          onClose={() => setShowTableSettings(false)}
          headers={settingsTranslatedHeader}
          prefs={{ headers: usedHeader, fillPage: getFillPageInfo(), key: Preferences.TABLE_SETTINGS_SEARCH_CONTROLM }}
        />
      }
      {
        showJobInformationDialog !== false && controlmJobs &&
        <CtmJobInformationDialog
          id={id}
          jobIndex={showJobInformationDialog}
          onClose={() => setShowJobInformationDialog(false)}
          controlmJobs={controlmJobs}
        />
      }
      <SelectionTable
        id={id}
        headers={usedHeader}
        documents={controlmJobs}
        keepPagination={keepPagination}
        fillPage={getFillPageInfo()}
        createActionButtons={index => actionButtons(index, id, handleDisplayJobInformation, openDocumentViewer, handleJobDownload)}
        createTableRowAction={index => handleDisplayJobInformation(index)}
        additionalInteraction={menuButtons(data, translatedHeader, () => setShowTableSettings(true), handleRefresh)}
        drawerExpanded={drawerExpanded}
        autoDrawer={autoDrawer}
        openDocViewerCallback={openSelectedRowsInDocViewer}
        resultTableType={EResultTableType.BRWCTM}
      />
    </>
  )
}

const actionButtons = (index, id, handleDisplayJobInformation, openDocumentViewer, handleJobDownload) => {
  return [
    <TableButton
      id={`${id}_tableButtonInfo_${index}`}
      iconType={'material'}
      iconName={'info'}
      title={translate('general.information')}
      onClick={() => handleDisplayJobInformation(index)}
    />,
    <TableButton
      id={`${id}_tableButtonDisplay_${index}`}
      iconType={'material'}
      iconName={'document'}
      title={translate('general.display')}
      onClick={() => openDocumentViewer(index)}
    />,
    <TableButton
      id={`${id}_tableButton_download_${index}`}
      iconType={'material'}
      iconName={'download'}
      title={translate('general.download')}
      onClick={() => handleJobDownload(index)}
    />
  ]
}

const menuButtons = (data, header, showTableSettings, handleRefresh) => {
  return (
    [
      <Link
        id={'cached'}
        iconName={'refresh'}
        tooltip={translate('table.refresh')}
        onClick={() => handleRefresh()}
      />,
      <DownloadWrapper
        id='download_wrapper'
        header={header}
        data={data}
        csvSplitter=';'
        filename='data.csv'
        tooltip={translate('table.download_as_csv')}>
        <Link
          id={'download'}
          iconName={'download'}
          onCLick={() => { }}
          tooltip={translate('table.download_as_csv')}
        />
      </DownloadWrapper>,
      <Link
        id={'settings'}
        iconName={'settings'}
        tooltip={translate('table.settings')}
        onClick={() => showTableSettings()}
      />
    ]
  )
}

export default SearchResultControlM