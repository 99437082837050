import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../../checkbox/Checkbox'

export default class TableCheckbox extends Component {

  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string,
    /** Style class from CSS for styling for component.*/
    className: PropTypes.string,
    /** Indicates if the checkbox is checked or not */
    isChecked: PropTypes.bool,
    /** Action index will be passed in __handleOnCheck__. Should be a unique id of the checkbox */
    actionIndex: PropTypes.number,
    /** Function to be called on click, when checkbox change state.*/
    handleOnCheck: PropTypes.func
  }

  shouldComponentUpdate(nextProps) {
    const oldValues = Object.values(this.props)
    const newValues = Object.values(nextProps)
    if (oldValues.length !== newValues.length) {
      return true
    }
    for (let i = 0; i < oldValues.length; i++) {
      if (['string', 'number', 'boolean'].includes(typeof newValues[i])) {
        if (oldValues[i] !== newValues[i]) {
          return true
        }
      }
    }
    return false
  }

  render() {
    const {
      id, className, isChecked, index, handleOnCheck
    } = this.props

    return (
      <td
        className={className}>
        <Checkbox
          id={id}
          className={'bux_tableCheckbox'}
          value={isChecked}
          onCheck={() => handleOnCheck(index)}
        />
      </td>
    )
  }

}