import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Style
import './MultiFloatingInput.scss'

// Components
import ErrorMessage from '../error_message/ErrorMessage';
import Input from '../input/Input'
import Label from '../label/Label'

/**
 * MultiFloatingInput component created according to
 * _Input_ from style guide
 * [DCI UI-Styleguide 2_20191101](https://xd.adobe.com/view/9a3fd546-d268-4499-b13b-ab013f6dd10d-cf6c/screen/9db8673a-f819-4632-8f91-3506800d8b40)
 */
export default class MultiFloatingInput extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Label to be displayed above the input.*/
    title: PropTypes.string.isRequired,
    /**
     * Function to be called on input change.
     * @param {string} value
     */
    onInputChanged: PropTypes.func.isRequired,
    /**
     * Array of string which will be displayed on the button of the input.
     * Array items will be separated with '.' to specified format of the user input.
     */
    items: PropTypes.arrayOf(
      PropTypes.string
    ),
    /** Regex pattern for validation */
    validation: PropTypes.any,
    /** Error description to be displayed below the input.*/
    error: PropTypes.string,
    /** Disables components visually and functionally. */
    disabled: PropTypes.bool,
    /** Sets max length of entered value into input.*/
    maxLength: PropTypes.number,
    /**
     * Sets asterisk, next to the _title_, which indicates that fields is required.
     * If _title_ is not set, asterisk will not be shown.
     */
    required: PropTypes.bool,
    /**
     * Function to translate following key by our own.
     *
     * - `security.profile_mask_invalid_input` => prefix of the error msg
     * - `general.required` => required word in label
     *
     * @param {string} key
     */
    translate: PropTypes.func.isRequired,
    /** Initial value of the multi floating input */
    input: PropTypes.string.isRequired,
    /** Enables character counting. Default is true. */
    charCount: PropTypes.bool
  }

  state = {
    output: [],
    hasFocus: false
  }

  /**
   * @description Updates the state value.
   */
  componentDidUpdate = (prevProps) => {
    // Reset output value if input gets resetted
    if (prevProps.input !== '' && this.props.input === '')
      this.setState({ output: '' })
  }

  /**
   * @description Handles input changes
   * @param {Object} event
   */
  handleChange = (event) => {
    this.setState({
      output: event.target.value.split('.')
    })
    this.props.onInputChanged(event.target.value)
  }

  /**
   * @description Handles simple input changes
   * @param {String} val new value of the input
   */
  handleSimpleInput = (val) => {
    this.setState({ input: val })
    this.props.onInputChanged(val)
  }

  /**
   * @description Renders the component
   */
  render = () => {
    const { id, maxLength, title, input, items, validation, error, disabled, required, charCount = true, translate } = this.props
    const { output, hasFocus } = this.state
    return (
      <Fragment>
        { items !== undefined && items.length > 0
          ? <div className='bux_fancy_input'>
            <div className={'bux_input_header_container'}>
              <Label
                id={`${id}_title`}
                title={title}
                required={required ? translate?.('general.required') : false}
                disabled={disabled}
                isError={!!error}
              />
              {
                charCount && maxLength && input !== undefined &&
                <label
                  className={`bux_char_counter ${hasFocus ? 'bux_show' : 'bux_hide'}`}>
                  {`${input.length}/${maxLength}`}
                </label>
              }
            </div>
            <input
              id={`${id}_input`}
              className={`form-control ${((items !== undefined && output.length > items.length) || error) && 'we_decoratedErrorField'}`}
              name='profilemask'
              maxLength={maxLength}
              onChange={this.handleChange}
              disabled={disabled || items === undefined}
              value={input}
              onFocus={() => this.setState({ hasFocus: true })}
              onBlur={() => this.setState({ hasFocus: false })}
              invalid={(!!error).toString()}
            />
            {items !== undefined && output.length <= items.length &&
              <Placeholder
                id={`${id}_placeholder`}
                items={items}
                input={input}
                validation={validation}
              />
            }
            {items !== undefined && output.length > items.length &&
              <ErrorReport
                id={`${id}_errorReport`}
                prefix={this.props.translate('security.profile_mask_invalid_input')}
                msg={`".${output.slice(items.length, Infinity).join('.')}"`}
              />
            }
            <ErrorMessage id={`${id}_error`} text={error} className={'bux_fancy_input_error'}/>
          </div>
          : <Input
            id={id}
            title={title}
            value={input}
            onInputChanged={(val) => this.handleSimpleInput(val)}
            error={error}
            disabled={disabled || items === undefined}
            maxLength={maxLength}
            required={required}
            invalid={(!!error).toString()}
          />
        }
      </Fragment>
    )
  }
}


const Placeholder = ({ id, items, input, validation }) => {
  let count = (input.match(validation || /\./g) || []).length
  return (
    <span
      className='placeholder'
      id={`${id}_list`}>
      {items.map((item, index) => (
        <span
          id={`${id}_item${index}`}
          className={`color${index + 1}_bux ${count === index ? 'true_' : 'false_'}highlight_bux`}
          key={index} >
          { item}
        </span>
      ))}
    </span>
  )
}

const ErrorReport = ({ id, msg, prefix }) => {
  return (
    <div
      id={`${id}_prefix`}
      title={`${prefix} ${msg}`}
      className='invalid-input'>
      { prefix}
      <span id={`${id}_msg`}>
        {msg}
      </span>
    </div>
  )
}