import {
  Button,
  MetaDataGrid, Modal
} from 'BetaUX2Web-Components/src/';

import { translate } from 'language/Language';
import { useDispatch } from 'react-redux';
import { getLPDQueueCommand } from 'redux/actions/LPDQueueActions';

interface RequeueLpdQueueDialogProps {
  id: string;
  onClose: (succeed: boolean) => void;
  lpdData: LpdQueue;
  mode: RequeueLpdQueueDialogMode;
}

interface LpdQueue {
  QUEUE: string,
  STATUS: string,
  IPCLIENT: string,
  SPATH: string,
  STATUSR: string,
  JOB: string,
  DATE: string,
  TIME: string,
  RC: string,
  IRC: string,
  DFA: string,
  SIZE: string,
  CFA: string,
  CONFN: string,
  CONFP: string,
}

export enum RequeueLpdQueueDialogMode {
  PRINT = 'LPD_PRINT',
  REMOVE = 'LPD_REMOVE'
}

export const RequeueLpdQueueDialog = ({ ...props }: RequeueLpdQueueDialogProps) => {
  const dispatch = useDispatch()
  const title = (props.mode === RequeueLpdQueueDialogMode.PRINT) ? translate('queue.lpd_queue_rerun_request') : translate('queue.lpd_queue_remove_request')
  const renderData = () => {
    return (
      <MetaDataGrid
        id={`${props.id}_header`}
        metaData={[
          { label: translate('general.queue'), value: props.lpdData.QUEUE },
          { label: translate('general.spath'), value: props.lpdData.SPATH },
          { label: translate('general.job'), value: props.lpdData.JOB },
          { label: translate('general.file'), value: props.lpdData.CONFN },
        ]}
        columns={4}
      />);
  }
  const onExecuteClick = () => {
    getLPDQueueCommand({ COMMAND: props.mode, QUEUE: props.lpdData.QUEUE, SPATH: props.lpdData.SPATH}, () => {})(dispatch)
    props.onClose?.(true);
  };

  const onClose = () => {
    props?.onClose(false)
  }

  return (
    <Modal.Modal
      id={props.id}
      onClose={onClose}
      size='s'>
      <Modal.Header
        id={props.id}
        title={title}
        onClose={onClose}>
      </Modal.Header>
      <Modal.Main id={`${props.id}_requeue_lpd_queue`} flexColumn>
        {renderData()}
      </Modal.Main>
      <Modal.Footer id={''}>
        <Button
          id={`${props.id}_cancelbtn`}
          text={translate('general.cancel')}
          onClick={onClose}
        />
        <Button
          id={`${props.id}_confirm`}
          text={translate('general.execute')}
          onClick={onExecuteClick}
          primary
        />
      </Modal.Footer>
    </Modal.Modal>
  )
}