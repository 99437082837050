import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CopyRemoteUserDialog from 'components/dialogs/copy_remote_user_dialog/CopyRemoteUserDialog.jsx'
import CreateRemoteUserDialog from 'components/dialogs/create_remote_user_dialog/CreateRemoteUserDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyRemoteUserDialog from 'components/dialogs/modify_remote_user/ModifyRemoteUser'
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'
import * as RemoteUserActions from 'redux/actions/RemoteUserActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as SortUtils from 'utils/SortUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultRemoteUsers extends Component {
  state = {
    showCreateRemoteUserDialog: false,
    showDeleteRemoteUserDialog: false,
    showModifyRemoteUserDialog: false,
    showCopyRemoteUserDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation()
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    return [
      { rest: UNIXUSER, translation: 'remoteuser.unixuser', default: true },
      { rest: BETAUSER, translation: 'remoteuser.betaux_user_id', default: true },
      { rest: IPMASK, translation: 'remoteuser.ip4mask', default: true },
      { rest: IP6MASK, translation: 'remoteuser.ip6mask', default: true },
      // rest api do not send last changed parameter, its just for save a rest parameter when using tablesettings
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER }
    ]
  }

  /**
   * @description Opens the create remote user dialog.
   */
  handleOnAddEntry = () => {
    this.setState({ showCreateRemoteUserDialog: true })
  }

  /**
   * @description Refreshs the table.
   */
  handleOnRefresh = () => {
    const { preferences } = this.props
    const unixuser = preferences[Preferences.USERMANAGEMENT_REMOTEUSERS_UNIXUSER]
    const buxuser = preferences[Preferences.USERMANAGEMENT_REMOTEUSERS_BUXUSER]

    this.props.getRemoteUsers(unixuser, buxuser)
  }

  /**
   * @description Opens the modify remote user dialog.
   * @param {Number} index The index on which the user clicked.
   */
  handleOnModifyRemoteUser = index => {
    // get the unixuser from redux
    const unixuser = this.props.remoteUsers.data[index][this.props.remoteUsers.header.indexOf('UNIXUSER')]

    // declare success callback
    const callback = () => { this.setState({ showModifyRemoteUserDialog: true }) }

    // get the specific remote user
    this.props.getRemoteUser(unixuser, callback)
  }

  /**
   * @description Opens the copy remote user dialog.
   * @param {Number} index The index on which the user clicked.
   */
  handleCopyRemoteUserDialog = index => {
    // get the unixuser from redux
    const unixuser = this.props.remoteUsers.data[index][this.props.remoteUsers.header.indexOf('UNIXUSER')]

    // declare success callback
    const callback = () => { this.setState({ showCopyRemoteUserDialog: true }) }

    // get the specific remote user
    this.props.getRemoteUser(unixuser, callback)
  }

  /**
   * @description Opens the delete remote user dialog.
   * @param {Number} index The index on which the user clicked.
   */
  handleOnDeleteRemoteUser = (index) => {
    // get the unixuser from redux
    const unixuser = this.props.remoteUsers.data[index][this.props.remoteUsers.header.indexOf('UNIXUSER')]

    // declare success callback
    const callback = () => { this.setState({ showDeleteRemoteUserDialog: true }) }

    // get the specific remote user
    this.props.getRemoteUser(unixuser, callback)
  }

  /**
   * @description Deletes the current user.
   */
  deleteRemoteUser = () => {
    const unixuser = this.props.remoteUser.UNIXUSER
    const userid = this.props.remoteUser.BETAUSER

    // declare success callback
    const callback = () => { this.setState({ showDeleteRemoteUserDialog: false }) }

    this.props.deleteRemoteUser(unixuser, userid, callback)
  }

  headerData = header => this.props.remoteUsers.header.indexOf(header)

  /**
   * Gets the viewdata with jsx elements for icon (don't use this for download as csv)
   */
  getViewData() {
    const { datemask, remoteUsers } = this.props
    let data = []
    let headers = this.getUsedHeader()
    remoteUsers.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to show.
   * @param {Array} header the headers of the columns.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Creates the actionbuttons the a row.
   * @param {Number} rowIndex The row index.
   */
  createActionButtons = rowIndex => {
    const { id, lang } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconName='edit'
        title={translate('general.edit', lang)}
        onClick={() => this.handleOnModifyRemoteUser(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconName='delete'
        title={translate('general.delete', lang)}
        onClick={() => this.handleOnDeleteRemoteUser(rowIndex)}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options', lang)}>
        <TableButtonGroupItem
          onClick={() => this.handleOnModifyRemoteUser(rowIndex)}
          id={`${id}_editBtn`}
          icon={<Icon id={`edit_icon_row${rowIndex}`} name='edit' className='actionIcon' />}
          text={translate('general.edit', lang)}
          title={translate('general.edit', lang)}
        />
        <TableButtonGroupItem
          onClick={() => { this.handleCopyRemoteUserDialog(rowIndex) }}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy', lang)}
          title={translate('general.copy', lang)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleOnDeleteRemoteUser(rowIndex)}
          id={`${id}_deleteBtn`}
          icon={<Icon id={`delete_icon_row${rowIndex}`} name='delete' className='actionIcon' />}
          text={translate('general.delete', lang)}
          title={translate('general.delete', lang)}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create', lang)}
          onClick={this.handleOnAddEntry}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleOnRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings', lang)}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_REMOTEUSER]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_REMOTEUSER].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_REMOTEUSER]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_REMOTEUSER].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  render = () => {
    const { id, remoteUsers, loading, drawerExpanded, lang, remoteUser, autoDrawer, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateRemoteUserDialog, showModifyRemoteUserDialog, showCopyRemoteUserDialog, showDeleteRemoteUserDialog } = this.state
    const data = remoteUsers && remoteUsers.data ? this.getViewData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {/* create group dialog */}
        {showCreateRemoteUserDialog &&
          <CreateRemoteUserDialog
            id={`${id}_createRemoteUserDialog`}
            visible={showCreateRemoteUserDialog}
            onClose={() => { this.setState({ showCreateRemoteUserDialog: false }) }}
          />
        }
        {/* modify remote user dialog */}
        {showModifyRemoteUserDialog && remoteUser && (
          <ModifyRemoteUserDialog
            id='modifyRemoteUserDialog'
            visible={showModifyRemoteUserDialog}
            onClose={() => { this.setState({ showModifyRemoteUserDialog: false }) }}
          />
        )}
        {/* Copy remote user dialog */}
        {showCopyRemoteUserDialog && remoteUser && (
          <CopyRemoteUserDialog
            id='copyRemoteUserDialog'
            onClose={() => { this.setState({ showCopyRemoteUserDialog: false }) }}
          />
        )}
        {/* delete remote user dialog */}
        {showDeleteRemoteUserDialog && remoteUser && (
          <DeleteDialog
            id={`${id}_deleteRemoteUserdialog`}
            title={translate('usermanagement.delete_remote_user')}
            question={translate('usermanagement.question_delete_remote_user')}
            visible={showDeleteRemoteUserDialog}
            onClose={() => this.setState({ showDeleteRemoteUserDialog: false }) }
            onDelete={() => this.deleteRemoteUser()}
          >
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_unixuser_row_titlescol_unixuser`}>
                  {translate('remoteuser.unixuser')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_unixuser_row_valuescol_unixuser`}>
                  {remoteUser.UNIXUSER}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_buxuserid_row_titlescol_betauser`}>
                  {translate('remoteuser.betaux_user_id')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_betauser_row_valuescol_betauser`}>
                  {remoteUser.BETAUSER}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_USERMANAGEMENT_REMOTEUSER }}
          />
        )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if remote users not exist in redux
            remoteUsers
              ? (
                // show empty result if there are no remote users after searching
                data
                  ? (
                    <DataTable
                      loading={loading}
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={data}
                      selectable={true}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={this.handleOnModifyRemoteUser}
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptyResult
                      description={translate('emptyresult.no_remote_users_result')}
                      id={`${id}_emptyresult`}
                      link={translate('emptyresult.create_remote_user_link')}
                      onClick={this.handleOnAddEntry}
                      headline={translate('emptyresult.no_result_headline')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )
          }
        </ResultContainer>
      </>
    )
  }
}

const UNIXUSER = 'UNIXUSER'
const BETAUSER = 'BETAUSER'
const IPMASK = 'IPMASK'
const IP6MASK = 'IP6MASK'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

SearchResultRemoteUsers.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = (state) => {
  return {
    usertoken: state.auth.serverdata.token,
    remoteUsers: state.remoteuser.remoteUsers,
    remoteUser: state.remoteuser.remoteUser,
    keepPagination: state.remoteuser.keepPagination,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRemoteUser: (unixuser, callback) => {
      RemoteUserActions.getRemoteUser(unixuser, callback)(dispatch)
    },
    getRemoteUsers: (unixuser, betauser) => {
      RemoteUserActions.getRemoteUsers(unixuser, betauser)(dispatch)
    },
    deleteRemoteUser: (unixuser, userid, callback) => {
      RemoteUserActions.deleteRemoteUser(unixuser, userid, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultRemoteUsers)