import * as moment from 'moment'
import store from 'redux/Store'
import * as Preferences from 'redux/general/Preferences'

// set invalidDate string to empty string
moment.updateLocale(moment.locale(), { invalidDate: '' })

/**
 * @description Formats a date and time to the given pattern. For pattern use constants of DateUtils.js.
 * The time will be concated to the formatted date, if the formatting was successful. Otherwise this function will return an empty string.
 * @param {String} pattern The pattern to format the date. Use ' ' for only using time datepattern.
 * @param {String} date The date to format.
 * @param {String} time The time to concat to formatted date.
 * @param {Boolean} appendTime Flag whether the time has to be added to the pattern datemask.
 */
export function getDate(pattern, date, time = undefined, appendTime = true) {
  if (date === '') {
    return ''
  }

  let timeToUse = time || '00:00:00'

  // parse the date and time to an object
  let currentDate = moment(`${date} ${timeToUse}`, { 8: PARSING_DATEMASK_SHORT, 11: PARSING_DATEMASK }[timeToUse.length], true)
  // create formatting datemask
  let formattingDatemask = `${pattern ? pattern : DATEMASKS[0]} ${(time && appendTime) ? TIME_DATEMASK_FULL_MILLISECONDS : ''}`.trim()
  // add / subtract timeshift
  if (store.getState().auth.loggedIn && time) {
    currentDate.subtract(store.getState().auth.serverdata.timeshift, 'm')
  }
  // format the date

  return currentDate.format(formattingDatemask)
}

export const formatDateTime = (pattern, date, time) => moment(`${date} ${time}`, PARSING_DATEMASK, true).format(`${pattern} ${TIME_DATEMASK_FULL_MILLISECONDS}`)

export const formatTimeToDefault = (time, timemask = TIME_DATEMASK_FULL) => {
  if (time === '') {
    return time
  }
  if (!moment.isMoment(time)) {
    // convert the time string into moment object
    // check moment doc for more information about special formats

    time = moment(time, [moment.ISO_8601, 'HH:mm:ss'])
  }
  return time.format(timemask)
}

export const toMoment = (date, pattern) => moment(date, pattern, true)

export function isDate(date, pattern) {
  let currentDate = moment(date, pattern, true)
  return currentDate.isValid()
}

/**
 * @description Formats a timestamp to a date string. If the timestamp is not a number it returns the date param.
 * @param {Number} date The date as long.
 * @param {String} toPattern The to pattern.
 * @param {Number} timeshiftMode The timeshift mode to use. (Use constants of this class)
 */
export function formatDate(date, toPattern, timeshiftMode) {
  // return the inputted date if its not a number

  // ! temporarily removed to fix all requests with date params
  // if (isNaN(date)) {
  //   return date
  // }

  // parse the date and time to an object
  let currentDate = moment(new Date(date))

  // create formatting datemask
  let formattingDatemask = `${toPattern}`

  // add / timeshift
  if (store.getState().auth.loggedIn && timeshiftMode) {
    if (timeshiftMode === TIMESHIFT_ADD) {
      currentDate.add(store.getState().auth.serverdata.timeshift, 'm')
    }
    else if (timeshiftMode === TIMESHIFT_SUBTRACT) {
      currentDate.subtract(store.getState().auth.serverdata.timeshift, 'm')
    }
  }

  // format the date
  return currentDate.format(formattingDatemask)
}

/**
 * @description Gets a date object based on the given datetime string.
 * @param {String} datetime The date time string. The format of the datetime is the datemask from the redux store + " HH:mm".
 */
export function getDateObject(datetime) {
  // get current datemask
  let currentDatemask = store.getState().auth.serverdata.preferences[Preferences.DATEMASK]

  // init with default datemask if current datemask is not set
  if (currentDatemask === undefined) {
    currentDatemask = DATEMASKS[0]
  }
  // Fallback to use the short TIMEMASK (HH:mm) when searching because the timepicker supports only minutes and seconds at the moment.
  // This function is used to check if a value is a valid date too (e.g for datatable sorting). So we need to validate the input before we can check which timemask will be used.
  const timeLength = typeof datetime === 'string' && datetime.includes(' ') && datetime.split(' ').length > 1 ? datetime.split(' ')[1].length : 0
  const TIMEMASK_TO_USE = { 5: TIME_DATEMASK, 8: TIME_DATEMASK_FULL, 11: TIME_DATEMASK_FULL_MILLISECONDS, 0: '' }[timeLength]
  // format date time string to a date object
  const formattedDate = moment(datetime, `${currentDatemask} ${TIMEMASK_TO_USE}`, true)
  return formattedDate
}

/**
 * @description Gets the formatted date which includes the timeshift. If date and time is empty it returns ''.
 * @param {String} date The date string or empty. If empty the current date will be used.
 * @param {String} time The time string in format 'HH:ss' or empty. If empty '00:00' will be used.
 * @param {String} toPattern The pattern which will be used to format the timeshifted date.
 */
export const getTimeshiftDate = (date, time, toPattern) => {
  // get current datemask
  let currentDatemask = store.getState().auth.serverdata.preferences[Preferences.DATEMASK]

  // don't do any formatting if date and time is not set!
  if (date === '' && (!time || time === '')) {
    return ''
  }

  if (date === '') {

    // init with default datemask if current datemask is not set
    if (currentDatemask === undefined) {
      currentDatemask = DATEMASKS[0]
    }

    date = moment().format(currentDatemask)
  }

  // add 0 to time that it looks like HH:SS
  if (time.length === 4) {
    time = `0${time}`
  }

  // don't format the rest of the valid strings
  if (typeof date === 'string') {
    if (validDateStrings.includes(date.toUpperCase())) {
      return date
    }
  }
  const dateObj = getDateObject(`${date} ${time}`)
  dateObj.add(store.getState().auth.serverdata.timeshift, 'm')
  return dateObj.format(toPattern)
}

/**
 * @description This function will check if the input value is a deictic expression or not.
 * @param {String} dateFromInput Input value representing either an actual date (e.g. 01.01.2000) or a deictic expression (e.g. TODAY)
 * @returns {Boolean}
 */
export const isDeictic = (dateFromInput) => validDateStrings.includes(dateFromInput.toUpperCase());

/**
 * @description This function will format the date in case 'dateFromInput' is a date. If not, it will return the current input value.
 * @param {String} dateFromInput Input value representing either an actual date (e.g. 01.01.2000) or a deictic expression (e.g. TODAY)
 * @param {String} fromPattern Datemask pattern in which dateFromInput is formatted
 * @param {String} toPattern Datemask pattern to use in formatting
 * @returns {String} Formatted date in case of a date, otherwise it returns the current input value
 */
export const getFormattedDate = (dateFromInput, toPattern) => !isDeictic(dateFromInput) ? moment(dateFromInput).format(toPattern).toString() : dateFromInput;

/**
 * @description This function will format the date in case 'dateFromInput' is a date. If not, it will return the current input value.
 * @param {String} dateFromInput Input value representing either an actual date (e.g. 01.01.2000) or a deictic expression (e.g. TODAY)
 * @param {String} toPattern Datemask pattern to use in formatting
 * @returns {String} Formatted date in case of a date, otherwise it returns the current input value
 */
export const getFormattedDateTime = (dateFromInput, fromPattern, toPattern) => moment(dateFromInput, fromPattern).format(toPattern).toString();

/**
 * @description Gets the date sorter json string for datatables.
 */
export function getDateSorter() {
  return {
    'date-time-asc': function (a, b) {
      // convert date strings to objects
      a = getDateObject(a)
      b = getDateObject(b)

      // compare dates
      if (a.isValid() && !b.isValid()) {
        return -1
      }
      else if (!a.isValid() && b.isValid()) {
        return 1
      } else {
        return (moment(a).isAfter(b) ? -1 : moment(a).isBefore(b) ? 1 : 0)
      }
    },

    'date-time-desc': function (a, b) {
      // convert date strings to objects
      a = getDateObject(a)
      b = getDateObject(b)

      // compare dates
      if (a.isValid() && !b.isValid()) {
        return 1
      }
      else if (!a.isValid() && b.isValid()) {
        return -1
      } else {
        return (moment(a).isAfter(b) ? 1 : moment(a).isBefore(b) ? -1 : 0)
      }
    }
  }
}

/**
 * @description Checks if a given value is a timespamp.
 * @param {String} val The value which should be checked.
 */
export const isUnixTimestamp = val => {
  return typeof val === 'string' && val !== '' && (validDateStrings.includes(val.toUpperCase()) || val.match(/^\d+$/))
}

/**
 * @description Formats a given date into the unix timestamp.
 * @param {String} date
 * @param {String} datemask
 * @returns {String}
 */
export const getUnixTimestamp = (date, datemask) => {
  if (date === '') {
    return ''
  }

  // don't format the valid date strings
  if (validDateStrings.includes(date.toUpperCase())) {
    return date
  }

  return moment(date, datemask).unix().toString()
}

export const getDateFromUnixTimestamp = (timestamp, datemask) => {
  // don't format the valid date strings
  if (typeof(timestamp) === 'string' && validDateStrings.includes(timestamp.toUpperCase())) {
    return timestamp
  }
  if (timestamp && timestamp !== '') {
    return moment.unix(timestamp).format(datemask)
  }
  return ''
}

/**
 * Parse a given date with the given datemask and format it to DD.MM.YYYY
 * @param {*} date Date to format
 * @param {*} datemask Datemask the date to format currently have
 * @returns
 */
export const getRequestFormat = (date, datemask) => {
  if (date === '') {
    return date
  }
  return moment(date, datemask).format(DDMMYYYY_DOT)
}

export const today = param => moment(param)
export const timeDiff = (date1, date2, pattern) => date1.diff(moment(date2, pattern))

const PARSING_DATEMASK_SHORT = 'DD.MM.YYYY HH:mm:ss'
export const PARSING_DATEMASK = 'DD.MM.YYYY HH:mm:ss:SS'
export const TIME_DATEMASK = 'HH:mm'
export const TIME_DATEMASK_FULL = 'HH:mm:ss'
export const TIME_DATEMASK_FULL_MILLISECONDS = 'HH:mm:ss:SS'

export const DDMMYYYY_DOT = 'DD.MM.YYYY'
export const MMDDYYYY_SLASH = 'MM/DD/YYYY'
export const DDMMYYYY_SLASH = 'DD/MM/YYYY'
export const YYYYMMDD_MINUS = 'YYYY-MM-DD'

export const TIMESHIFT_ADD = 0
export const TIMESHIFT_SUBTRACT = 1

export const TIMEMASKS = [
  'HH:MM:SS',
  'HH:MM'
]

export const DATEMASKS = [
  MMDDYYYY_SLASH,
  DDMMYYYY_DOT,
  DDMMYYYY_SLASH,
  YYYYMMDD_MINUS
]

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

export const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.hours' },
  { key: UNIT_DAYS, translationKey: 'general.days' }
]

export const validDateStrings = [
  'HEUTE', 'GESTERN', 'MONTAG', 'DIENSTAG', 'MITTWOCH', 'DONNERSTAG', 'FREITAG', 'SAMSTAG', 'SONNTAG',
  'TODAY', 'YESTERDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY',
  'HIER', 'AUJOURDHUI', 'LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE'
]