import * as DocumentDefinitionActions from '../actions/DocumentDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  documents: undefined,
  document: undefined,
  verify: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get documents actions
    case DocumentDefinitionActions.DOCUMENT_DEFINITION_GET_DOCUMENTS_START:
      return { ...state }
    case DocumentDefinitionActions.DOCUMENT_DEFINITION_GET_DOCUMENTS_SUCCESS:
      return { ...state, documents: action.payload.data, keepPagination: action.keepPagination }
    case DocumentDefinitionActions.DOCUMENT_DEFINITION_GET_DOCUMENTS_FAILED:
      return { ...state }
    case DocumentDefinitionActions.DOCUMENT_DEFINITION_GET_DOCUMENT_START:
      return { ...state }
    case DocumentDefinitionActions.DOCUMENT_DEFINITION_GET_DOCUMENT_SUCCESS:
      return { ...state, document: action.payload.data }
    case DocumentDefinitionActions.DOCUMENT_DEFINITION_GET_DOCUMENT_FAILED:
      return { ...state }
    case DocumentDefinitionActions.NO_DOCUMENT_DEFINITION_DOCUMENTS_FOUND:
      return { ...state, documents: {} }
    case DocumentDefinitionActions.DOCUMENT_DEFINITION_VERIFY_START:
      return { ...state }
    case DocumentDefinitionActions.DOCUMENT_DEFINITION_VERIFY_SUCCESS:
      return { ...state, verify: action.payload }
    case DocumentDefinitionActions.DOCUMENT_DEFINITION_VERIFY_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return defaultState
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer