import store from '../Store'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as Lang from 'language/Language'
import { TYPE_CHANGE_LANGUAGE } from './AuthenticationActions'

export const PREFERENCES_CHANGE_PREFS = 'PREFERENCES_CHANGE_PREFS'

/**
 * @description Changes the preferences in redux. For the current session the preferences will be changed. To saves the preferences to the user, pass the preferences on logout.
 * @param {Object} prefs The preferences to change.
 */
export function changePrefs(prefs, showSnackbar = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    // get the current prefs
    const currentPrefs = Object.assign({}, store.getState().auth.serverdata.preferences)

    // write new prefs into current prefs
    Object.keys(prefs).forEach(key => {
      currentPrefs[key] = prefs[key]
    })

    dispatch({
      type: PREFERENCES_CHANGE_PREFS, payload: { newPrefs: currentPrefs }
    })
    LoadingSpinnerActions.hide()(dispatch)
    if (showSnackbar) {
      SnackbarActions.show(Lang.translate('general.preferences_saved_session'), SnackbarActions.TYPE_SUCCESS)(dispatch)
    }
  }
}

export const getCachedSearchParameters = (cacheKey, callback) => {
  return dispatch => {
    const currentPrefs = store.getState().auth.serverdata.preferences
    const customSelection = store.getState().search.customselection
    if (!(customSelection.cache && cacheKey in customSelection.cache)) return

    dispatch({ type: PREFERENCES_CHANGE_PREFS, payload: { newPrefs: { ...currentPrefs, [Preferences.SEARCH_LAST_CUSTOM_SEARCH]: customSelection.cache[cacheKey].searchParameters } } })

    if (callback) {
      callback()
    }
  }
}

/**
 * @description The initialization call is intended to set the base configuration for the app, regardless of the login state.
 * This function adds a fallback to the language preference in case a deprecated local storage state exists where the language preference was not stored at this location.
 */
export const init = () => {
  return dispatch => {
    const lang = store.getState().auth?.serverdata?.preferences?.LANGUAGE ?? 'en'
    dispatch({
      type: TYPE_CHANGE_LANGUAGE,
      payload: {
        lang: lang,
      }
    })
  }
}