import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_START = 'LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_START'
export const LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_SUCCESS = 'LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_SUCCESS'
export const LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_FAILED = 'LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_FAILED'

export const LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_START = 'LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_START'
export const LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_SUCCESS = 'LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_SUCCESS'
export const LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_FAILED = 'LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_FAILED'

export const LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_START = 'LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_START'
export const LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_SUCCESS = 'LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_SUCCESS'
export const LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_FAILED = 'LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_FAILED'

export const LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_START = 'LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_START'
export const LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_SUCCESS = 'LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_SUCCESS'
export const LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_FAILED = 'LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_FAILED'

export const LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_START = 'LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_START'
export const LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_SUCCESS = 'LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_SUCCESS'
export const LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_FAILED = 'LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_FAILED'

export const NO_LPD_QUEUE_DEFINITION_LPD_QUEUES_FOUND = 'NO_LPD_QUEUE_DEFINITION_LPD_QUEUES_FOUND'

/**
 * @description Calls the rest api and gets a lpd queue.
 * @param {String} name The name of the lpd queue.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getLpdQueue(name, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/lpdqueue?LPD=${encodeURIComponent(name)}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.get_lpd_queue_error', lang, [name, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a lpd queue definition.
 * @param {Object} lpdQueueDefinition The lpdQueueDefinition. Possible fields:
 * 'LPD', 'LPDINFO', 'LPDPROG', 'LPDARGS', 'CUSER', 'OWNER', 'QSSPOOL', 'QSPRINT', 'QSTATUS'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function createLpdQueue(lpdQueueDefinition, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/lpdqueue`, {
        method: 'post',
        body: ObjectUtils.removeByValue(lpdQueueDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.create_lpd_queue_success', lang, lpdQueueDefinition['LPD']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getLpdQueues again to get the current result
          const name = prefs[Preferences.DEFINITION_LPD_QUEUES_NAME]
          const description = prefs[Preferences.DEFINITION_LPD_QUEUES_DESCRIPTION]
          const owner = prefs[Preferences.DEFINITION_LPD_QUEUES_OWNER]
          getLpdQueues(name, description, owner, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.create_lpd_queue_error', lang, [lpdQueueDefinition['LPD'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and deletes a lpd queue definition.
 * @param {String} name The name of the lpd queue.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function deleteLpdQueue(name, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/lpdqueue${createQueryParamsForFetch({ LPD: name })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.delete_lpd_queue_success', lang, name), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getLpdQueues again to get the current result
          const searchName = prefs[Preferences.DEFINITION_LPD_QUEUES_NAME]
          const description = prefs[Preferences.DEFINITION_LPD_QUEUES_DESCRIPTION]
          const owner = prefs[Preferences.DEFINITION_LPD_QUEUES_OWNER]
          getLpdQueues(searchName, description, owner, undefined, true)(dispatch)
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.delete_lpd_queue_error', lang, [name, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a lpd queue definition.
 * @param {Object} lpdQueueDefinition The lpdQueueDefinition. Possible fields:
 * 'LPD', 'LPDINFO', 'LPDPROG', 'LPDARGS', 'CUSER', 'OWNER', 'QSSPOOL', 'QSPRINT', 'QSTATUS'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function updateLpdQueue(lpdQueueDefinition, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/lpdqueue`, {
        method: 'put',
        body: {
          LPD: lpdQueueDefinition['LPD'],
          LPDINFO: lpdQueueDefinition['LPDINFO'],
          LPDPROG: lpdQueueDefinition['LPDPROG'],
          LPDARGS: lpdQueueDefinition['LPDARGS'],
          CUSER: lpdQueueDefinition['CUSER'],
          OWNER: lpdQueueDefinition['OWNER'],
          QSSPOOL: lpdQueueDefinition['QSSPOOL'],
          QSPRINT: lpdQueueDefinition['QSPRINT'],
          QSTATUS: lpdQueueDefinition['QSTATUS'],
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.update_lpd_queue_success', lang, lpdQueueDefinition['LPD']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getLpdQueues again to get the current result
          const name = prefs[Preferences.DEFINITION_LPD_QUEUES_NAME]
          const description = prefs[Preferences.DEFINITION_LPD_QUEUES_DESCRIPTION]
          const owner = prefs[Preferences.DEFINITION_LPD_QUEUES_OWNER]
          getLpdQueues(name, description, owner, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.update_lpd_queue_error', lang, [lpdQueueDefinition['LPD'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calling the rest api and do getLpdQueues request.
 * @param {String} name The name pattern of the lpd queue.
 * @param {String} description The description pattern of the lpd queue.
 * @param {String} owner The owner pattern of the lpd queue.
 * @param {Function} callback the callback which will be called when the request was successful. Can be null.
 */
export function getLpdQueues(name, description, owner, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (name) { queryParams.push(`LPD=${encodeURIComponent(name)}`) }
    if (description) { queryParams.push(`LPDINFO=${encodeURIComponent(description)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/lpdqueues?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_LPD_QUEUE_DEFINITION_LPD_QUEUES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_FAILED })
          }
        }
        else {
          dispatch({ type: LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.lpd_queues_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}