import * as OutputChannelDefinitionActions from '../actions/OutputChannelDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  outputChannels: undefined,
  outputChannel: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get output channels actions
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_START:
      return { ...state }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_SUCCESS:
      return { ...state, outputChannels: action.payload.data, keepPagination: action.keepPagination }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_FAILED:
      return { ...state }
    case OutputChannelDefinitionActions.NO_OUTPUTCHANNEL_DEFINITION_OUTPUTCHANNELS_FOUND:
      return { ...state, outputChannels: {} }
    /* ********************************************************************************** */
    // Definition get single output channel actions
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_START:
      return { ...state, outputChannel: undefined, requestResult: undefined }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_SUCCESS:
      return { ...state, outputChannel: action.payload }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // execute monitor request actions
    case OutputChannelDefinitionActions.EXECUTE_MONITOR_REQUEST_START:
      return { ...state }
    case OutputChannelDefinitionActions.EXECUTE_MONITOR_REQUEST_SUCCESS:
      return { ...state, requestResult: action.payload }
    case OutputChannelDefinitionActions.EXECUTE_MONITOR_REQUEST_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition create output channel
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_START:
      return { ...state }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_SUCCESS:
      return { ...state }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition modify output channel
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_START:
      return { ...state }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_SUCCESS:
      return { ...state }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete output channel
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_START:
      return { ...state }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_SUCCESS:
      return { ...state }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition verify output channel
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_START:
      return { ...state }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_SUCCESS:
      return { ...state, verify: action.payload }
    case OutputChannelDefinitionActions.OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_FAILED:
      return { ...state }
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, outputChannels: undefined, outputChannel: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer