import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const DEFINITION_GET_ARCHIVEPOOLS_START = 'DEFINITION_GET_ARCHIVEPOOLS_START'
export const DEFINITION_GET_ARCHIVEPOOLS_SUCCESS = 'DEFINITION_GET_ARCHIVEPOOLS_SUCCESS'
export const DEFINITION_GET_ARCHIVEPOOLS_FAILED = 'DEFINITION_GET_ARCHIVEPOOLS_FAILED'

export const DEFINITION_REFRESH_ARCHIVEPOOLS_SUCCESS = 'DEFINITION_REFRESH_ARCHIVEPOOLS_SUCCESS'
export const DEFINITION_REFRESH_ARCHIVEPOOLS_FAILED = 'DEFINITION_REFRESH_ARCHIVEPOOLS_FAILED'

export const DEFINITION_GET_ARCHIVEPOOL_START = 'DEFINITION_GET_ARCHIVEPOOL_START'
export const DEFINITION_GET_ARCHIVEPOOL_SUCCESS = 'DEFINITION_GET_ARCHIVEPOOL_SUCCESS'
export const DEFINITION_GET_ARCHIVEPOOL_FAILED = 'DEFINITION_GET_ARCHIVEPOOL_FAILED'

export const NO_DEFINITION_ARCHIVEPOOLS_FOUND = 'NO_DEFINITION_ARCHIVEPOOLS_FOUND'

export const DEFINITION_CREATE_ARCHIVEPOOL_START = 'DEFINITION_CREATE_ARCHIVEPOOL_START'
export const DEFINITION_CREATE_ARCHIVEPOOL_SUCCESS = 'DEFINITION_CREATE_ARCHIVEPOOL_SUCCESS'
export const DEFINITION_CREATE_ARCHIVEPOOL_FAILED = 'DEFINITION_CREATE_ARCHIVEPOOL_FAILED'

export const DEFINITION_MODIFY_ARCHIVEPOOL_START = 'DEFINITION_MODIFY_ARCHIVEPOOL_START'
export const DEFINITION_MODIFY_ARCHIVEPOOL_SUCCESS = 'DEFINITION_MODIFY_ARCHIVEPOOL_SUCCESS'
export const DEFINITION_MODIFY_ARCHIVEPOOL_FAILED = 'DEFINITION_MODIFY_ARCHIVEPOOL_FAILED'

export const DEFINITION_DELETE_ARCHIVEPOOL_START = 'DEFINITION_DELETE_ARCHIVEPOOL_START'
export const DEFINITION_DELETE_ARCHIVEPOOL_SUCCESS = 'DEFINITION_DELETE_ARCHIVEPOOL_SUCCESS'
export const DEFINITION_DELETE_ARCHIVEPOOL_FAILED = 'DEFINITION_DELETE_ARCHIVEPOOL_FAILED'

export const DEFINITION_CREATE_ARCHIVESUBPOOL_START = 'DEFINITION_CREATE_ARCHIVESUBPOOL_START'
export const DEFINITION_CREATE_ARCHIVESUBPOOL_SUCCESS = 'DEFINITION_CREATE_ARCHIVESUBPOOL_SUCCESS'
export const DEFINITION_CREATE_ARCHIVESUBPOOL_FAILED = 'DEFINITION_CREATE_ARCHIVESUBPOOL_FAILED'

export const DEFINITION_DELETE_ARCHIVESUBPOOL_START = 'DEFINITION_DELETE_ARCHIVESUBPOOL_START'
export const DEFINITION_DELETE_ARCHIVESUBPOOL_SUCCESS = 'DEFINITION_DELETE_ARCHIVESUBPOOL_SUCCESS'
export const DEFINITION_DELETE_ARCHIVESUBPOOL_FAILED = 'DEFINITION_DELETE_ARCHIVESUBPOOL_FAILED'

export function getArchivePools(fields, id, media, retentionPeriod, title, owner, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: DEFINITION_GET_ARCHIVEPOOLS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (id) { queryParams.push(`ARCPOOL=${encodeURIComponent(id)}`) }
    if (media) { queryParams.push(`ADPMED=${encodeURIComponent(media)}`) }
    if (retentionPeriod) { queryParams.push(`ADPRETPD=${encodeURIComponent(retentionPeriod)}`) }
    if (title) { queryParams.push(`ADPTITLE=${encodeURIComponent(title)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }


    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/archivepools?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_DEFINITION_ARCHIVEPOOLS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: DEFINITION_GET_ARCHIVEPOOLS_FAILED })
          }
        }
        else {
          dispatch({ type: DEFINITION_GET_ARCHIVEPOOLS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.archive_pools_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DEFINITION_GET_ARCHIVEPOOLS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getArchivePool(fields, id, owner, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: DEFINITION_GET_ARCHIVEPOOL_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    queryParams.push(`ARCPOOL=${encodeURIComponent(id)}`)
    queryParams.push(`OWNER=${encodeURIComponent(owner)}`)


    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/archivepool?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DEFINITION_GET_ARCHIVEPOOL_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DEFINITION_GET_ARCHIVEPOOL_SUCCESS, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.archive_pool_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DEFINITION_GET_ARCHIVEPOOL_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function createArchivePool(archivePool, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    LoadingSpinnerActions.show()(dispatch)
    dispatch({ type: DEFINITION_CREATE_ARCHIVEPOOL_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/archivepool`, {
        method: 'post',
        body: ObjectUtils.removeByValue(archivePool, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          dispatch({ type: DEFINITION_CREATE_ARCHIVEPOOL_FAILED, payload: { error } })
        } else {
          dispatch({ type: DEFINITION_CREATE_ARCHIVEPOOL_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.create_archive_pool_success', lang, archivePool.ARCPOOL), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          const id = prefs[Preferences.DEFINITION_ARCHIVEPOOL_ID]
          const media = prefs[Preferences.DEFINITION_ARCHIVEPOOL_MEDIA]
          const retentionPeriod = prefs[Preferences.DEFINITION_ARCHIVEPOOL_RETENTIONPERIOD]
          const title = prefs[Preferences.DEFINITION_ARCHIVEPOOL_TITLE]
          const owner = prefs[Preferences.DEFINITION_ARCHIVEPOOL_OWNER]
          getArchivePools(undefined, id, media, retentionPeriod, title, owner, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.create_archive_pool_error', lang, [archivePool.ARCPOOL, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
        dispatch({ type: DEFINITION_CREATE_ARCHIVEPOOL_FAILED, payload: { error } })
      })
  }
}

export function createArchiveSubpool(archiveSubpool, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: DEFINITION_CREATE_ARCHIVESUBPOOL_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/archivesubpool`, {
        method: 'post',
        body: ObjectUtils.removeByValue(archiveSubpool, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DEFINITION_CREATE_ARCHIVESUBPOOL_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: DEFINITION_CREATE_ARCHIVESUBPOOL_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.create_archive_subpool_success', lang, archiveSubpool.ARCPOOL), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshArchivePool(
            archiveSubpool.ARCPOOL,
            archiveSubpool.ADPMED,
            archiveSubpool.ADPRETPD,
            archiveSubpool.ADPTITLE,
            archiveSubpool.OWNER
          )(dispatch)

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.create_archive_subpool_error', lang, [archiveSubpool.ARCPOOL, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DEFINITION_CREATE_ARCHIVESUBPOOL_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function deleteArchivePool(archivePool, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    LoadingSpinnerActions.show()(dispatch)
    dispatch({ type: DEFINITION_DELETE_ARCHIVEPOOL_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/archivepool${createQueryParamsForFetch(archivePool)}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          dispatch({ type: DEFINITION_DELETE_ARCHIVEPOOL_FAILED, payload: { error } })
        } else {
          dispatch({ type: DEFINITION_DELETE_ARCHIVEPOOL_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.delete_archive_pool_success', lang, archivePool.ARCPOOL), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }
          const id = prefs[Preferences.DEFINITION_ARCHIVEPOOL_ID]
          const media = prefs[Preferences.DEFINITION_ARCHIVEPOOL_MEDIA]
          const retentionPeriod = prefs[Preferences.DEFINITION_ARCHIVEPOOL_RETENTIONPERIOD]
          const title = prefs[Preferences.DEFINITION_ARCHIVEPOOL_TITLE]
          const owner = prefs[Preferences.DEFINITION_ARCHIVEPOOL_OWNER]
          getArchivePools(undefined, id, media, retentionPeriod, title, owner, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_archive_pool_error', lang, [archivePool.ARCPOOL, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
        dispatch({ type: DEFINITION_DELETE_ARCHIVEPOOL_FAILED, payload: { error } })
      })
  }
}

export function modifyArchivePool(archivePool, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: DEFINITION_MODIFY_ARCHIVEPOOL_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/archivepool`, {
        method: 'put',
        body: ObjectUtils.removeByValue(archivePool, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DEFINITION_MODIFY_ARCHIVEPOOL_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: DEFINITION_MODIFY_ARCHIVEPOOL_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.modify_archive_pool_success', lang, archivePool.ARCPOOL), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          const id = prefs[Preferences.DEFINITION_ARCHIVEPOOL_ID]
          const media = prefs[Preferences.DEFINITION_ARCHIVEPOOL_MEDIA]
          const retentionPeriod = prefs[Preferences.DEFINITION_ARCHIVEPOOL_RETENTIONPERIOD]
          const title = prefs[Preferences.DEFINITION_ARCHIVEPOOL_TITLE]
          const owner = prefs[Preferences.DEFINITION_ARCHIVEPOOL_OWNER]
          getArchivePools(undefined, id, media, retentionPeriod, title, owner, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.modify_archive_pool_error', lang, [archivePool.ARCPOOL, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DEFINITION_MODIFY_ARCHIVEPOOL_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function deleteArchiveSubpool(archiveSubpool, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: DEFINITION_CREATE_ARCHIVESUBPOOL_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/archivesubpool${createQueryParamsForFetch(archiveSubpool)}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DEFINITION_DELETE_ARCHIVESUBPOOL_FAILED, payload: { error } })
        } else {
          dispatch({ type: DEFINITION_DELETE_ARCHIVESUBPOOL_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.delete_archive_subpool_success', lang, archiveSubpool.ARCPOOL), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshArchivePool(
            archiveSubpool.ARCPOOL,
            archiveSubpool.ADPMED,
            archiveSubpool.ADPRETPD,
            archiveSubpool.ADPTITLE,
            archiveSubpool.OWNER
          )(dispatch)

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_archive_subpool_error', lang, [archiveSubpool.ARCPOOL, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DEFINITION_DELETE_ARCHIVESUBPOOL_FAILED, payload: { error } })
      })
  }
}

export function refreshArchivePool(id, media, retentionPeriod, title, owner, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    /* Build request-parameter for URL */
    const queryParams = []
    queryParams.push(`ARCPOOL=${encodeURIComponent(id)}`)
    queryParams.push(`ADPMED=${encodeURIComponent(media)}`)
    queryParams.push(`ADPRETPD=${encodeURIComponent(retentionPeriod)}`)
    queryParams.push(`ADPTITLE=${encodeURIComponent(title)}`)
    queryParams.push(`OWNER=${encodeURIComponent(owner)}`)

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/archivepool?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc


          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DEFINITION_REFRESH_ARCHIVEPOOLS_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: DEFINITION_REFRESH_ARCHIVEPOOLS_SUCCESS, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.archive_pools_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DEFINITION_REFRESH_ARCHIVEPOOLS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}