import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

export const DOCUMENT_NODES_DEFINITION_GET_DOCUMENT_NODES_SUCCESS = 'DOCUMENT_NODES_DEFINITION_GET_DOCUMENT_NODES_SUCCESS'
export const DOCUMENT_NODES_DEFINITION_GET_DOCUMENT_NODE_SUCCESS = 'DOCUMENT_NODES_DEFINITION_GET_DOCUMENT_NODE_SUCCESS'

export const DOCUMENT_NODES_DEFINITION_DELETE_SUCCESS = 'DOCUMENT_NODES_DEFINITION_DELETE_SUCCESS'

export const DOCUMENT_NODES_DEFINITION_UPDATE_SUCCESS = 'DOCUMENT_NODES_DEFINITION_UPDATE_SUCCESS'

export const NO_DOCUMENT_NODES_DEFINITION_FOUND = 'NO_DOCUMENT_NODES_DEFINITION_FOUND'

/**
 * @description Calling the rest api and do get docuemnt nodes request.
 * @param {Object} fields The fields to select (If undefined -> Return all fields).
 * @param {Object} documentNodeObj The object which stored the specific search parameters.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getDocumentNodes(fields, documentNodeObj, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (documentNodeObj.DNDNAME) { queryParams.push(`DNDNAME=${encodeURIComponent(documentNodeObj.DNDNAME)}`) }
    if (documentNodeObj.DNDPNAME) { queryParams.push(`DNDPNAME=${encodeURIComponent(documentNodeObj.DNDPNAME)}`) }
    if (documentNodeObj.OWNER) { queryParams.push(`OWNER=${encodeURIComponent(documentNodeObj.OWNER)}`) }
    if (documentNodeObj.DNDENAME) { queryParams.push(`DNDENAME=${encodeURIComponent(documentNodeObj.DNDENAME)}`) }
    if (documentNodeObj.DNDTYPE) { queryParams.push(`DNDTYPE=${encodeURIComponent(documentNodeObj.DNDTYPE)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodes?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_DOCUMENT_NODES_DEFINITION_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
        }
        else {
          dispatch({ type: DOCUMENT_NODES_DEFINITION_GET_DOCUMENT_NODES_SUCCESS, payload: jsondata, keepPagination })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.document_nodes_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do get document node request.
 * @param {Object} documentNodeObj The object which stored the specific search parameters.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getDocumentNode(documentNodeObj, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    /* Build request-parameter for URL */
    const queryParams = [
      `DNDNAME=${encodeURIComponent(documentNodeObj.DNDNAME)}`,
      `DNDPNAME=${encodeURIComponent(documentNodeObj.DNDPNAME)}`
    ]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnode?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        }
        else {
          dispatch({ type: DOCUMENT_NODES_DEFINITION_GET_DOCUMENT_NODE_SUCCESS, payload: jsondata, keepPagination })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.document_node_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function createDocumentNode(documentObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnode`, {
        method: 'post',
        body: ObjectUtils.removeByValue(documentObj, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.create_document_node_success', lang, documentObj['DNDNAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }
          refreshData()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.create_document_node_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes a document node definition.
 * @param {Object} documentNodeObj The document node definition.
 * @param {Function} callback The callback which will be called when the delete document node definition request was successful.
 */
export function deleteDocumentNode(documentNodeObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnode${createQueryParamsForFetch(ObjectUtils.removeByValue(documentNodeObj, [undefined, null]))}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: DOCUMENT_NODES_DEFINITION_DELETE_SUCCESS })
          SnackbarActions.show(Lang.translate('definition.delete_document_node_success', lang, documentNodeObj['DNDNAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          refreshData()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_document_node_error', lang, [documentNodeObj['DNDNAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and updates a document node
 * @param {Object} documentNode
 * @param {Function} callback
 */
export function updateDocumentNode(documentNode, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnode`, {
        method: 'put',
        body: documentNode
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: DOCUMENT_NODES_DEFINITION_UPDATE_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.update_document_node_success', lang, documentNode['DNDNAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshData()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.update_document_node_error', lang, [documentNode['DNDNAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getDocumentNodes request
 * @param {*} parentID
 * @param {*} callback
 * @returns
 */
export function checkDocumentNodeisDeletable(parentID, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    /* Build request-parameter for URL */
    const queryParams = []
    if (parentID) { queryParams.push(`DNDPNAME=${encodeURIComponent(parentID)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodes?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          // call the callback on success
          if (callback) {
            callback()
          }
        }
        else {
          SnackbarActions.show(Lang.translate('definition.node_has_child_items'), SnackbarActions.TYPE_ERROR)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.document_node_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export const refreshData = () => {
  const prefs = store.getState().auth.serverdata.preferences
  const documentNodeObj = {
    DNDNAME: prefs[Preferences.DEFINITION_DOCUMENT_NODE_NODE_ID],
    DNDPNAME: prefs[Preferences.DEFINITION_DOCUMENT_NODE_PARENT_NODE_ID],
    OWNER: prefs[Preferences.DEFINITION_DOCUMENT_NODE_OWNER],
    DNDENAME: prefs[Preferences.DEFINITION_DOCUMENT_NODE_IDENTIFIER],
    DNDTYPE: prefs[Preferences.DEFINITION_DOCUMENT_NODE_TYPE],
  }
  return dispatch => {
    getDocumentNodes(undefined, documentNodeObj, undefined, true)(dispatch)
  }
}