import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Icon from '../../icon/Icon';
import './Navbar.scss';

class Navbar extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    navbarClassName: PropTypes.string,
    onVisibilityChange: PropTypes.func.isRequired,
    visibility: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.string]).isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string
  };
  handleSlide = () => {
    this.props.onVisibilityChange(!this.props.visibility);
  };

  render = () => {
    const {id, children, visibility, className} = this.props;
    return (
      <nav id={id} className={`sidebar_nav ${className}`}>
        <div id={`${id}_btn_group_top`}>
          {children}
        </div>
        <div className="nav_btn_group_top">
          <button
            className="nav_button"
            id="we_id_navToggleClose"
            onClick={this.handleSlide}>
            <Icon
              id={`${id}_show_navbar_icon`}
              name={visibility ? 'close_drawer' : 'open_drawer'}/>
          </button>
        </div>
      </nav>
    );
  };
}

export default Navbar;