import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { LetterCase } from 'BetaUX2Web-Components/src/types'

// Components
import {
  Button, Card, Column,
  Input,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as GroupActions from 'redux/actions/GroupActions'
import * as Preferences from 'redux/general/Preferences'

class CopyGroup extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    groupid: {
      value: this.props.group.BETAGRP,
      errorkey: ''
    },
    groupname: {
      value: this.props.group.GRPNAME,
      errorkey: ''
    }
  }

  groupidInput = React.createRef()

  /**
   * @description handles the input changes.
   * @param {String} key The id of the textfield.
   * @param {String} value The new value.
   */
  handleChange(key, value) {
    this.setState({ [key]: value })
  }

  /**
   * @description Sets the initial focus.
   */
  componentDidMount() {
    // focus group id input initially
    this.groupidInput.current.focus()
  }

  /**
   * @description Validates the group id and adds an error if validation failed.
   * @param {Boolean} allowFocus Flag to focus the textfield if validation failed.
   */
  validateGroupID(allowFocus) {
    if (this.state.groupid.value !== '') {
      if (!this.checkCopyGroupID()) {

        // set the focus if it's allowed
        if (allowFocus) {
          this.groupidInput.current.focus()
        }

        return false
      }

      return true
    } else {
      this.setState({ groupid: { ...this.state.groupid, errorkey: 'usermanagement.please_enter_a_groupid' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.groupidInput.current.focus()
      }

      return false
    }
  }

  /**
   * @description Checks the copy group id. Adds an error under the textfield if the groupid is not different to the copied one.
   */
  checkCopyGroupID() {
    if (this.state.groupid.value === this.props.group.BETAGRP) {
      this.setState({ groupid: { ...this.state.groupid, errorkey: 'usermanagement.copy_group_same_groupid_error' } })

      return false
    }

    return true
  }

  /**
   * @description Closes the dialog.
   */
  handleOnClose = () => {
    this.props.onClose()
  }

  /**
   * @description Calls the rest api and copies the group.
   */
  handleOnSave = () => {
    // check data before sending request
    if (this.validateGroupID(true)) {

      // call create request
      const cuser = this.props.userid
      const groupid = this.state.groupid.value
      const groupname = this.state.groupname.value

      // declare success callback
      const callback = () => { this.props.onClose() }

      this.props.createGroup(cuser, groupid, groupname, callback)
    }
  }

  render = () => {
    const { id, lang } = this.props
    const { groupid, groupname } = this.state
    return (
      <Modal onClose={this.handleOnClose}
        id={`${id}_copyGroupDialog`}
        size={'s'}>
        <Header
          id={`${id}_copyGroupDialog`}
          title={translate('usermanagement.copy_group', lang)}
          onClose={this.handleOnClose}
        />
        <Main id={`${id}_copyGroupDialog`}>
          <Card fullHeight>
            <Row>
              <Column
                colMD={12}
                offsetMD={0}>
                <Input
                  id={`${id}_groupIdInput`}
                  ref={this.groupidInput}
                  onInputChanged={(val, err) => {
                    this.handleChange('groupid', { value: val, errorkey: err })
                  }}
                  value={groupid.value}
                  title={translate('group.group_id', lang)}
                  error={groupid.errorkey && translate(groupid.errorkey, lang)}
                  maxLength={8}
                  required={`${translate('general.required_field')}`}
                  onBlur={() => this.checkCopyGroupID()}
                  lettercase={LetterCase.uppercase}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}>
                <Input
                  id={`${id}_groupNameInput`}
                  onInputChanged={(val, err) => {
                    this.handleChange('groupname', { value: val, errorkey: err })
                  }}
                  value={groupname.value}
                  title={translate('group.groupname', lang)}
                  error={groupname.errorkey && translate(groupname.errorkey, lang)}
                  maxLength={32}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_copyGroupDialog`}>
          <Button
            id={`${id}_cancelBtn`}
            text={translate('general.cancel', lang)}
            onClick={this.handleOnClose}
          />
          <Button
            id={`${id}_saveBtn`}
            text={translate('general.save', lang)}
            onClick={this.handleOnSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    group: state.group.group
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createGroup: (
      cuser,
      groupid,
      groupname,
      callback
    ) => {
      GroupActions.createGroup(
        cuser,
        groupid,
        groupname,
        callback
      )(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyGroup)