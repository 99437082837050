import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as UserUtils from 'utils/UserUtils'

import AssignmentFolderDocument from './assignment_folder_document/AssignmentFolderDocument'
import AssignmentIndexDocument from './assignment_index_document/AssignmentIndexDocument'
import AssignmentRecipientDocument from './assignment_recipient_document/AssignmentRecipientDocument'
import AssignmentNodeDocument from './assignment_node_document/AssignmentNodeDocument'

class AssignmentBody extends Component {

  getBody = () => {
    const { id, assignmentMode } = this.props
    if (UserUtils.isDOCX()) {
      switch (assignmentMode) {
        case 'folder_document': return <AssignmentFolderDocument id={id} />
        case 'index_document': return <AssignmentIndexDocument id={id} />
        case 'recipient_document': return <AssignmentRecipientDocument id={id} />
        case 'node_document': return <AssignmentNodeDocument id={id} />
        default: return null
      }
    }
    else if (UserUtils.isLOGX()) {
      switch (assignmentMode) {
        case 'index_log': return <AssignmentIndexDocument id={id} />
        case 'recipient_log': return <AssignmentRecipientDocument id={id} />
        default: return null
      }
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

AssignmentBody.propTypes = {
  id: PropTypes.string.isRequired,
}

export default AssignmentBody