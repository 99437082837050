/* ************************************************************************************** */
// Recipient constants
export const RECIPIENT_TYPE_ANY = ''
export const RECIPIENT_TYPE_RECI = 'RECI'
export const RECIPIENT_TYPE_ADDR = 'ADDR'
export const RECIPIENT_TYPE_ALIAS = 'ALIAS'

export const RECIPIENT_TYPES = [
  { key: RECIPIENT_TYPE_ANY, translationKey: 'recipient.type_any' },
  { key: RECIPIENT_TYPE_RECI, translationKey: 'recipient.type_reci' },
  { key: RECIPIENT_TYPE_ADDR, translationKey: 'recipient.type_addr' },
  { key: RECIPIENT_TYPE_ALIAS, translationKey: 'recipient.type_alias' }
]

const RECIPIENT_DISPLAY_AS_TABLE = 'RECIPIENT_DISPLAY_AS_TABLE'
const RECIPIENT_DISPLAY_AS_HIERARCHY = 'RECIPIENT_DISPLAY_AS_HIERARCHY'

export const RECIPIENT_DISPLAY_AS = [
  { key: RECIPIENT_DISPLAY_AS_TABLE, translationKey: 'general.table' },
  { key: RECIPIENT_DISPLAY_AS_HIERARCHY, translationKey: 'general.hierarchy' }
]

/**
 * @description Returns the recipient types.
 * @param {Boolean} inluceAll The value that decides if any should be included.
 */
export const getRecipientTypes = includeAll => {
  return includeAll ? RECIPIENT_TYPES : RECIPIENT_TYPES.filter(element => element.key !== RECIPIENT_TYPE_ANY)
}

export const RECIPIENT_DISTRIBUTION_ANY = ''
export const RECIPIENT_DISTRIBUTION_ALL = 'ALL'
export const RECIPIENT_DISTRIBUTION_AUTOPRINT = 'AUTO-P'
export const RECIPIENT_DISTRIBUTION_BUNDLE = 'BUNDLE'
export const RECIPIENT_DISTRIBUTION_ONLINE = 'ONLINE'

export const RECIPIENT_DISTRIBUTIONS = [
  // any is used to get all recipients
  { key: RECIPIENT_DISTRIBUTION_ANY, translationKey: 'recipient.distribute_any' },
  // all is used to get the recipients, which have distribution all (that means, they use one of autoprint, bundle or online (no matter what distribution))
  { key: RECIPIENT_DISTRIBUTION_ALL, translationKey: 'recipient.distribute_all' },
  { key: RECIPIENT_DISTRIBUTION_AUTOPRINT, translationKey: 'recipient.distribute_autop' },
  { key: RECIPIENT_DISTRIBUTION_BUNDLE, translationKey: 'recipient.distribute_bundle' },
  { key: RECIPIENT_DISTRIBUTION_ONLINE, translationKey: 'recipient.distribute_online' }
]

/**
 * @description Returns the recipient distributions.
 * @param {Boolean} inluceAll The value that decides if any should be included.
 */
export const getRecipientDistributions = includeAll => {
  return includeAll ? RECIPIENT_DISTRIBUTIONS : RECIPIENT_DISTRIBUTIONS.filter(element => element.key !== RECIPIENT_DISTRIBUTION_ANY)
}

export const RECIPIENT_DOC_ASSIGNMENT_ANY = ''
export const RECIPIENT_DOC_ASSIGNMENT_STATIC = 'STATIC'
export const RECIPIENT_DOC_ASSIGNMENT_CONVERT = 'CONVERT'
export const RECIPIENT_DOC_ASSIGNMENT_DEFAULT = 'DEFAULT'

export const RECIPIENT_DOC_ASSIGNMENTS = [
  { key: RECIPIENT_DOC_ASSIGNMENT_ANY, translationKey: 'recipient.type_any' },
  { key: RECIPIENT_DOC_ASSIGNMENT_STATIC, translationKey: 'assignment.recipient_document_static' },
  { key: RECIPIENT_DOC_ASSIGNMENT_CONVERT, translationKey: 'assignment.recipient_document_convert' },
  { key: RECIPIENT_DOC_ASSIGNMENT_DEFAULT, translationKey: 'assignment.recipient_document_default' }
]

/**
 * @description Returns the recipient distributions.
 * @param {Boolean} inluceAll The value that decides if any should be included.
 */
export const getRecDocAssignments = includeAll => includeAll ? RECIPIENT_DOC_ASSIGNMENTS : RECIPIENT_DOC_ASSIGNMENTS.filter(element => element.key !== RECIPIENT_DOC_ASSIGNMENT_ANY)

/* ************************************************************************************** */
// lpdqueue constants
export const LPDQUEUE_TRANSFER_PROGRAM_BUXIMPORT = './buximport.exe'
export const LPDQUEUE_TRANSFER_PROGRAM_BETAEND = './BETAend'
export const LPDQUEUE_TRANSFER_PROGRAM_OTHER = ''

export const LPDQUEUE_TRANSFER_PROGRAMS = [
  { key: LPDQUEUE_TRANSFER_PROGRAM_BUXIMPORT, translationKey: 'lpdqueue.transfer_buximport' },
  { key: LPDQUEUE_TRANSFER_PROGRAM_BETAEND, translationKey: 'lpdqueue.transfer_betaend' },
  { key: LPDQUEUE_TRANSFER_PROGRAM_OTHER, translationKey: 'lpdqueue.transfer_other' }
]

/* ************************************************************************************** */
// ouput channel constants
export const OUTPUTCHANNEL_TYPE_ANY = ''
export const OUTPUTCHANNEL_TYPE_IPT = 'IP/T'
export const OUTPUTCHANNEL_TYPE_CMD = 'CMD'
export const OUTPUTCHANNEL_TYPE_APPLICATION = 'APPL'

export const OUTPUTCHANNEL_TYPES = [
  { key: OUTPUTCHANNEL_TYPE_ANY, translationKey: 'definition.outputchannel.type_any' },
  { key: OUTPUTCHANNEL_TYPE_IPT, translationKey: 'definition.outputchannel.type_ipt' },
  { key: OUTPUTCHANNEL_TYPE_CMD, translationKey: 'definition.outputchannel.type_cmd' },
  { key: OUTPUTCHANNEL_TYPE_APPLICATION, translationKey: 'definition.outputchannel.type_application' }
]

/**
 * @description Returns the output channel types.
 * @param {Boolean} includeAll the value that decides if the any should be included.
 */
export const getOutputChannelTypes = includeAll => {
  return includeAll ? OUTPUTCHANNEL_TYPES : OUTPUTCHANNEL_TYPES.filter(element => element.key !== OUTPUTCHANNEL_TYPE_ANY)
}

export const OUTPUTCHANNEL_PROTOCOL_LLP = 'LPP'
export const OUTPUTCHANNEL_PROTOCOL_IPP = 'IPP'
export const OUTPUTCHANNEL_PROTOCOL_PJL = 'PJL'
export const OUTPUTCHANNEL_PROTOCOL_DSP = 'DSP'

export const OUTPUTCHANNEL_PROTOCOLS = [
  OUTPUTCHANNEL_PROTOCOL_LLP,
  OUTPUTCHANNEL_PROTOCOL_IPP,
  OUTPUTCHANNEL_PROTOCOL_PJL,
  OUTPUTCHANNEL_PROTOCOL_DSP
]

export const OUTPUTCHANNEL_DEVICE_VERBOSE = 'VERBOSE'
export const OUTPUTCHANNEL_DEVICE_ON = 'ON'
export const OUTPUTCHANNEL_DEVICE_OFF = 'OFF'

export const OUTPUTCHANNEL_DEVICES = [
  OUTPUTCHANNEL_DEVICE_VERBOSE,
  OUTPUTCHANNEL_DEVICE_ON,
  OUTPUTCHANNEL_DEVICE_OFF
]

export const OUTPUTCHANNEL_APPLICATION_B93MVS = 'B93MVS'
export const OUTPUTCHANNEL_APPLICATION_B93EMF = 'B93EMF'
export const OUTPUTCHANNEL_APPLICATION_B93UX = 'B93UX'

export const OUTPUTCHANNEL_APPLICATIONS = [
  OUTPUTCHANNEL_APPLICATION_B93MVS,
  OUTPUTCHANNEL_APPLICATION_B93EMF,
  OUTPUTCHANNEL_APPLICATION_B93UX
]

/* ************************************************************************************** */
// index constants
export const INDEX_DATA_FORMAT_ANY = ''
export const INDEX_DATA_FORMAT_STRING = 'STRING'
export const INDEX_DATA_FORMAT_DATE = 'DATE'
export const INDEX_DATA_FORMAT_NUMERIC_RIGHT_ALIGNED = 'RNUM'
export const INDEX_DATA_FORMAT_NUMERIC_LEFT_ALIGNED = 'NUMERIC'
export const INDEX_DATA_FORMAT_DECIMAL = 'DECIMAL'

export const INDEX_DATA_FORMAT_TYPES = [
  { key: INDEX_DATA_FORMAT_ANY, translationKey: 'general.any' },
  { key: INDEX_DATA_FORMAT_STRING, translationKey: 'general.string' },
  { key: INDEX_DATA_FORMAT_DATE, translationKey: 'general.date' },
  { key: INDEX_DATA_FORMAT_NUMERIC_RIGHT_ALIGNED, translationKey: 'definition.index_numeric_right' },
  { key: INDEX_DATA_FORMAT_NUMERIC_LEFT_ALIGNED, translationKey: 'definition.index_numeric_left' },
  { key: INDEX_DATA_FORMAT_DECIMAL, translationKey: 'general.decimal' }
]

/**
 * @description Returns the index data format types.
 * @param {Boolean} includeAll the value that decides if the any should be included.
 */
export const getIndexDataFormatTypes = includeAll => {
  return includeAll ? INDEX_DATA_FORMAT_TYPES : INDEX_DATA_FORMAT_TYPES.filter(element => element.key !== INDEX_DATA_FORMAT_ANY)
}

export const INDEX_BLANKS_BETWEEN_WORDS_KEEP_ALL = 'ASIS'
export const INDEX_BLANKS_BETWEEN_WORDS_KEEP_ONE = 'ONE'
export const INDEX_BLANKS_BETWEEN_WORDS_REMOVE_ALL = 'NONE'

export const INDEX_BLANKS_BETWEEN_WORDS = [
  { key: INDEX_BLANKS_BETWEEN_WORDS_KEEP_ALL, translationKey: 'definition.index_blanks_between_words_keep_all' },
  { key: INDEX_BLANKS_BETWEEN_WORDS_KEEP_ONE, translationKey: 'definition.index_blanks_between_words_keep_one' },
  { key: INDEX_BLANKS_BETWEEN_WORDS_REMOVE_ALL, translationKey: 'definition.index_blanks_between_words_remove_all' }
]

/* ************************************************************************************** */
// document constants
// search mode
export const DOCUMENT_SEARCH_MODE_ANY = ''
export const DOCUMENT_SEARCH_MODE_ABSOLUTE = 'ABSOLUTE'
export const DOCUMENT_SEARCH_MODE_START_STOP = 'START/STOP'
export const DOCUMENT_SEARCH_MODE_AUTOBURST = 'AUTOBURST'
export const DOCUMENT_SEARCH_MODE_CONTROL = 'CONTROL'
export const DOCUMENT_SEARCH_MODE_LIMIT_BURST = 'LIMIT/BURST'
export const DOCUMENT_SEARCH_MODE_COND_BURST1 = 'COND/BURST1'
export const DOCUMENT_SEARCH_MODE_COND_BURST2 = 'COND/BURST2'
export const DOCUMENT_SEARCH_MODE_COND_BURST3 = 'COND/BURST3'
export const DOCUMENT_SEARCH_MODE_EXCLUDE = 'EXCLUDE'
export const DOCUMENT_SEARCH_MODE_FILTER = 'FILTER'

export const DOCUMENT_SEARCH_MODE = [
  { key: DOCUMENT_SEARCH_MODE_ANY, translationKey: 'general.any' },
  { key: DOCUMENT_SEARCH_MODE_ABSOLUTE, translationKey: 'definition.document_absolute' },
  { key: DOCUMENT_SEARCH_MODE_START_STOP, translationKey: 'definition.document_start_stop' },
  { key: DOCUMENT_SEARCH_MODE_AUTOBURST, translationKey: 'definition.document_autoburst' },
  { key: DOCUMENT_SEARCH_MODE_CONTROL, translationKey: 'search.control' },
  { key: DOCUMENT_SEARCH_MODE_LIMIT_BURST, translationKey: 'definition.document_limit_burst' },
  { key: DOCUMENT_SEARCH_MODE_COND_BURST1, translationKey: 'definition.document_cond_burst1' },
  { key: DOCUMENT_SEARCH_MODE_COND_BURST2, translationKey: 'definition.document_cond_burst2' },
  { key: DOCUMENT_SEARCH_MODE_COND_BURST3, translationKey: 'definition.document_cond_burst3' },
  { key: DOCUMENT_SEARCH_MODE_EXCLUDE, translationKey: 'definition.document_exclude' },
  { key: DOCUMENT_SEARCH_MODE_FILTER, translationKey: 'definition.document_filter' }
]

// type switch
export const DOCUMENT_TYPE_ANY = ''
export const DOCUMENT_TYPE_LIST = 'LIST'
export const DOCUMENT_TYPE_REPORT = 'REPORT'

export const DOCUMENT_TYPES = [
  { key: DOCUMENT_TYPE_ANY, translationKey: 'general.any' },
  { key: DOCUMENT_TYPE_LIST, translationKey: 'general.list' },
  { key: DOCUMENT_TYPE_REPORT, translationKey: 'general.report' }
]


/* ************************************************************************************** */
// search argument constants
export const SEARCH_ARGUMENT_TYPE_VALUE = 'VALUE'
export const SEARCH_ARGUMENT_TYPE_PAGE = 'PAGE'

export const SEARCH_ARGUMENT_TYPES = [
  { key: SEARCH_ARGUMENT_TYPE_VALUE, translationKey: 'general.value' },
  { key: SEARCH_ARGUMENT_TYPE_PAGE, translationKey: 'definition.page' }
]

export const SEARCH_ARGUMENT_VERIFY_SA = 'RDRSASA'
export const SEARCH_ARGUMENT_VERIFY_SE = 'RDRSASE'
export const SEARCH_ARGUMENT_VERIFY_SS = 'RDRSASS'
export const SEARCH_ARGUMENT_VERIFY_BURST_1 = 'RDRSASW1'
export const SEARCH_ARGUMENT_VERIFY_BURST_2 = 'RDRSASW2'
export const SEARCH_ARGUMENT_VERIFY_BURST_3 = 'RDRSASW3'
export const SEARCH_ARGUMENT_VERIFY_BURST_4 = 'RDRSASW4'
export const SEARCH_ARGUMENT_VERIFY_BURST_5 = 'RDRSASW5'
export const SEARCH_ARGUMENT_VERIFY_BURST_6 = 'RDRSASW6'
export const SEARCH_ARGUMENT_VERIFY_BURST_7 = 'RDRSASW7'
export const SEARCH_ARGUMENT_VERIFY_BURST_8 = 'RDRSASW8'

export const SEARCH_ARGUMENT_VERIFY_BURST_VALUES = [
  { key: SEARCH_ARGUMENT_VERIFY_SA, translationKey: 'definition.search_argument' },
  { key: SEARCH_ARGUMENT_VERIFY_SE, translationKey: 'definition.search_argument_exclude_formula' },
  { key: SEARCH_ARGUMENT_VERIFY_SS, translationKey: 'definition.search_argument_stop' },
  { key: SEARCH_ARGUMENT_VERIFY_BURST_1, translationKey: 'definition.search_argument_burst_1' },
  { key: SEARCH_ARGUMENT_VERIFY_BURST_2, translationKey: 'definition.search_argument_burst_2' },
  { key: SEARCH_ARGUMENT_VERIFY_BURST_3, translationKey: 'definition.search_argument_burst_3' },
  { key: SEARCH_ARGUMENT_VERIFY_BURST_4, translationKey: 'definition.search_argument_burst_4' },
  { key: SEARCH_ARGUMENT_VERIFY_BURST_5, translationKey: 'definition.search_argument_burst_5' },
  { key: SEARCH_ARGUMENT_VERIFY_BURST_6, translationKey: 'definition.search_argument_burst_6' },
  { key: SEARCH_ARGUMENT_VERIFY_BURST_7, translationKey: 'definition.search_argument_burst_7' },
  { key: SEARCH_ARGUMENT_VERIFY_BURST_8, translationKey: 'definition.search_argument_burst_8' }
]

export const SEARCHARGUMENT_COMPARISON_OPERATOR = [
  { key: 'EQ', translationKey: 'general.equal' },
  { key: 'NE', translationKey: 'general.not_equal' },
  { key: 'GT', translationKey: 'general.greater_than' },
  { key: 'GE', translationKey: 'general.greater_equal' },
  { key: 'LT', translationKey: 'general.less_than' },
  { key: 'LE', translationKey: 'general.less_equal' }
]


export const ARCHIVEPOOL_MEDIA_NONE = ' '
export const ARCHIVEPOOL_MEDIA_ANY = ''
export const ARCHIVEPOOL_MEDIA_TAPE = 'TAPE'
export const ARCHIVEPOOL_MEDIA_DISK = 'DISK'
export const ARCHIVEPOOL_MEDIA_ODISK = 'ODISK'

export const ARCHIVEPOOL_MEDIA = [
  { key: ARCHIVEPOOL_MEDIA_ANY, translationKey: 'general.any' },
  { key: ARCHIVEPOOL_MEDIA_TAPE, translationKey: 'general.tape' },
  { key: ARCHIVEPOOL_MEDIA_DISK, translationKey: 'general.disk' },
  { key: ARCHIVEPOOL_MEDIA_ODISK, translationKey: 'general.optical_disk' }
]

/**
 * @description Returns the archive media items.
 * @param {Boolean} includeAll the value that decides if the any should be included.
 */
export const archiveMediaItems = includeAll => {
  return includeAll ? ARCHIVEPOOL_MEDIA : ARCHIVEPOOL_MEDIA.filter(element => element.key !== ARCHIVEPOOL_MEDIA_ANY)
}

export const DOCUMENT_DEFINITION_TYPE_DAYS = 'DAY'
export const DOCUMENT_DEFINITION_TYPE_GENERATIONS = 'GEN'
export const DOCUMENT_DEFINITION_TYPE_REFERENCES = 'REF'

export const DOCUMENT_DEFINITION_TYPES = [
  { key: DOCUMENT_DEFINITION_TYPE_DAYS, translationKey: 'general.days', index: 0 },
  { key: DOCUMENT_DEFINITION_TYPE_GENERATIONS, translationKey: 'documentinformation.retentiontab_generations', index: 1 },
  { key: DOCUMENT_DEFINITION_TYPE_REFERENCES, translationKey: 'documentinformation.retentiontab_references', index: 2 }
]

export const JOBGROUP_DEFINITION_TYPE_DAYS = 'DAY'
export const JOBGROUP_DEFINITION_TYPE_REFERENCES = 'REF'

export const JOBGROUP_DEFINITION_TYPES = [
  { key: JOBGROUP_DEFINITION_TYPE_DAYS, translationKey: 'general.days', index: 0 },
  { key: JOBGROUP_DEFINITION_TYPE_REFERENCES, translationKey: 'documentinformation.retentiontab_references', index: 1 }
]

export const DOCUMENT_SEARCH_MODE_NONE = ''

export const DOCUMENT_DEFINITION_SEARCH_MODES = [
  { key: DOCUMENT_SEARCH_MODE_ABSOLUTE, translationKey: 'definition.document_absolute' },
  { key: DOCUMENT_SEARCH_MODE_START_STOP, translationKey: 'definition.document_start_stop' },
  { key: DOCUMENT_SEARCH_MODE_AUTOBURST, translationKey: 'definition.document_autoburst' },
  { key: DOCUMENT_SEARCH_MODE_CONTROL, translationKey: 'search.control' },
  { key: DOCUMENT_SEARCH_MODE_LIMIT_BURST, translationKey: 'definition.document_limit_burst' },
  { key: DOCUMENT_SEARCH_MODE_COND_BURST1, translationKey: 'definition.document_cond_burst1' },
  { key: DOCUMENT_SEARCH_MODE_COND_BURST2, translationKey: 'definition.document_cond_burst2' },
  { key: DOCUMENT_SEARCH_MODE_COND_BURST3, translationKey: 'definition.document_cond_burst3' },
  { key: DOCUMENT_SEARCH_MODE_EXCLUDE, translationKey: 'definition.document_exclude' },
  { key: DOCUMENT_SEARCH_MODE_FILTER, translationKey: 'definition.document_filter' }
]

export const hasBurstMode = mode => {
  const burstModes = [
    DOCUMENT_SEARCH_MODE_AUTOBURST,
    DOCUMENT_SEARCH_MODE_LIMIT_BURST,
    DOCUMENT_SEARCH_MODE_COND_BURST1,
    DOCUMENT_SEARCH_MODE_COND_BURST2,
    DOCUMENT_SEARCH_MODE_COND_BURST3,
  ]
  return burstModes.includes(mode)
}

export const DOCUMENT_DEFINITION_BURSTMODE_NONE = ''
export const DOCUMENT_DEFINITION_BURSTMODE_FIX = 'POSITION'
export const DOCUMENT_DEFINITION_BURSTMODE_RELATIVE = 'RELATION'

export const DOCUMENT_DEFINITION_BURSTMODES = [
  { key: DOCUMENT_DEFINITION_BURSTMODE_FIX, translationKey: 'general.position' },
  { key: DOCUMENT_DEFINITION_BURSTMODE_RELATIVE, translationKey: 'general.relation' }
]

export const DOCUMENT_DEFINITION_STATUS_NONE = ''
export const DOCUMENT_DEFINITION_STATUS_HOLD = 'H'
export const DOCUMENT_DEFINITION_STATUS_CONTROLLED = 'C'

export const DOCUMENT_DEFINITION_STATI = [
  { key: DOCUMENT_DEFINITION_STATUS_NONE, translationKey: 'general.none_keiner', index: 0 },
  { key: DOCUMENT_DEFINITION_STATUS_HOLD, translationKey: 'search.hold', index: 1 },
  { key: DOCUMENT_DEFINITION_STATUS_CONTROLLED, translationKey: 'general.controlled', index: 2 }
]

export const DOCUMENT_DEFINITION_MEDIA_TAPE = 'T'
export const DOCUMENT_DEFINITION_MEDIA_DISK = 'D'
export const DOCUMENT_DEFINITION_MEDIA_ODISK = 'O'

export const DOCUMENT_DEFINITION_MEDIA = [
  { key: DOCUMENT_DEFINITION_MEDIA_TAPE, translationKey: 'general.tape' },
  { key: DOCUMENT_DEFINITION_MEDIA_DISK, translationKey: 'general.disk' },
  { key: DOCUMENT_DEFINITION_MEDIA_ODISK, translationKey: 'general.optical_disk' }
]


export const DOCUMENT_DEFINITION_ENCODING_NONE = ''
export const DOCUMENT_DEFINITION_ENCODING_ISO8859_1 = 'ISO8859-1'
export const DOCUMENT_DEFINITION_ENCODING_ISO8859_2 = 'ISO8859-2'
export const DOCUMENT_DEFINITION_ENCODING_ISO8859_15 = 'ISO8859-15'
export const DOCUMENT_DEFINITION_ENCODING_UCS_2BE = 'UCS-2BE'
export const DOCUMENT_DEFINITION_ENCODING_UTF_8 = 'UTF-8'
export const DOCUMENT_DEFINITION_ENCODING_CP850 = 'CP850'
export const DOCUMENT_DEFINITION_ENCODING_CP1250 = 'CP1250'
export const DOCUMENT_DEFINITION_ENCODING_CP1251 = 'CP1251'
export const DOCUMENT_DEFINITION_ENCODING_CP1252 = 'CP1252'
export const DOCUMENT_DEFINITION_ENCODING_CP1253 = 'CP1253'
export const DOCUMENT_DEFINITION_ENCODING_CP1256 = 'CP1256'
export const DOCUMENT_DEFINITION_ENCODING_IBM0037 = 'IBM0037'
export const DOCUMENT_DEFINITION_ENCODING_IBM0273 = 'IBM0273'
export const DOCUMENT_DEFINITION_ENCODING_IBM0297 = 'IBM0297'
export const DOCUMENT_DEFINITION_ENCODING_IBM0500 = 'IBM0500'
export const DOCUMENT_DEFINITION_ENCODING_IBM0420 = 'IBM0420'
export const DOCUMENT_DEFINITION_ENCODING_IBM1140 = 'IBM1140'
export const DOCUMENT_DEFINITION_ENCODING_IBM1141 = 'IBM1141'
export const DOCUMENT_DEFINITION_ENCODING_IBM1147 = 'IBM1147'
export const DOCUMENT_DEFINITION_ENCODING_IBM1148 = 'IBM1148'

export const DOCUMENT_DEFINITION_ENCODINGS = [
  { key: DOCUMENT_DEFINITION_ENCODING_NONE, translationKey: 'general.none', index: 0 },
  { key: DOCUMENT_DEFINITION_ENCODING_ISO8859_1, translationKey: 'definition.document_ISO8859_1', index: 1 },
  { key: DOCUMENT_DEFINITION_ENCODING_ISO8859_2, translationKey: 'definition.document_ISO8859_2', index: 2 },
  { key: DOCUMENT_DEFINITION_ENCODING_ISO8859_15, translationKey: 'definition.document_ISO8859_15', index: 3 },
  { key: DOCUMENT_DEFINITION_ENCODING_UCS_2BE, translationKey: 'definition.document_UCS_2BE', index: 4 },
  { key: DOCUMENT_DEFINITION_ENCODING_UTF_8, translationKey: 'definition.document_UTF_8', index: 5 },
  { key: DOCUMENT_DEFINITION_ENCODING_CP850, translationKey: 'definition.document_CP850', index: 6 },
  { key: DOCUMENT_DEFINITION_ENCODING_CP1250, translationKey: 'definition.document_CP1250', index: 7 },
  { key: DOCUMENT_DEFINITION_ENCODING_CP1251, translationKey: 'definition.document_CP1251', index: 8 },
  { key: DOCUMENT_DEFINITION_ENCODING_CP1252, translationKey: 'definition.document_CP1252', index: 9 },
  { key: DOCUMENT_DEFINITION_ENCODING_CP1253, translationKey: 'definition.document_CP1253', index: 10 },
  { key: DOCUMENT_DEFINITION_ENCODING_CP1256, translationKey: 'definition.document_CP1256', index: 11 },
  { key: DOCUMENT_DEFINITION_ENCODING_IBM0037, translationKey: 'definition.document_IBM0037', index: 12 },
  { key: DOCUMENT_DEFINITION_ENCODING_IBM0273, translationKey: 'definition.document_IBM0273', index: 13 },
  { key: DOCUMENT_DEFINITION_ENCODING_IBM0297, translationKey: 'definition.document_IBM0297', index: 14 },
  { key: DOCUMENT_DEFINITION_ENCODING_IBM0500, translationKey: 'definition.document_IBM0500', index: 15 },
  { key: DOCUMENT_DEFINITION_ENCODING_IBM0420, translationKey: 'definition.document_IBM0420', index: 16 },
  { key: DOCUMENT_DEFINITION_ENCODING_IBM1140, translationKey: 'definition.document_IBM1140', index: 17 },
  { key: DOCUMENT_DEFINITION_ENCODING_IBM1141, translationKey: 'definition.document_IBM1141', index: 18 },
  { key: DOCUMENT_DEFINITION_ENCODING_IBM1147, translationKey: 'definition.document_IBM1147', index: 19 },
  { key: DOCUMENT_DEFINITION_ENCODING_IBM1148, translationKey: 'definition.document_IBM1148', index: 20 }
]

export const DOCUMENT_DEFINITION_CONTENT_EXTRACTION_LINE_START = '0'
export const DOCUMENT_DEFINITION_CONTENT_EXTRACTION_SEARCH_PATTERN_START = 'S'
export const DOCUMENT_DEFINITION_CONTENT_EXTRACTION_SEARCH_PATTERN_END = 'E'

export const DOCUMENT_DEFINITION_CONTENT_EXTRACTION_RELATIVE_TO = [
  { key: DOCUMENT_DEFINITION_CONTENT_EXTRACTION_LINE_START, translationKey: 'general.line_start', index: 0 },
  { key: DOCUMENT_DEFINITION_CONTENT_EXTRACTION_SEARCH_PATTERN_START, translationKey: 'general.search_pattern_start', index: 1 },
  { key: DOCUMENT_DEFINITION_CONTENT_EXTRACTION_SEARCH_PATTERN_END, translationKey: 'general.search_pattern_end', index: 2 }
]

export const PTX_ENCODINGS = [
  ['', 'Default from LST.TXT', 'EBCDIC', 'No'],
  ['', 'No Translation', 'ASIS', 'No'],
  ['IBM0037', 'USA, Canada - CECP', 'EBCDIC', 'No'],
  ['IBM0273', 'Austria, Germany CECP', 'EBCDIC', 'No'],
  ['IBM0297', 'France - CECP', 'EBCDIC', 'No'],
  ['IBM0300', 'Japan (Kanji) DBCS-Host', 'EBCDIC', 'Yes'],
  ['IBM0500', 'International #5', 'EBCDIC', 'No'],
  ['IBM0423', 'Greece - 183', 'EBCDIC', 'Yes'],
  ['IBM0424', 'Israel (Hebrew', 'EBCDIC', 'Yes'],
  ['IBM0437', 'Personal Computer', 'ASCII', 'Yes'],
  ['IBM0803', 'Hebrew Character Set A', 'EBCDIC', 'Yes'],
  ['IBM0819', 'Latin1 ISO/ANSI 8-BIT', 'ASCII', 'Yes'],
  ['IBM0858', 'PC - Multilingual with euro', 'ASCII', 'Yes'],
  ['IBM0850', 'PC Multilingual', 'ASCII', 'Yes'],
  ['IBM0860', 'Portugal - Personal Computer', 'ASCII', 'Yes'],
  ['IBM0861', 'Iceland - Personal Computer', 'ASCII', 'Yes'],
  ['IBM0863', 'Canadian French - PC', 'ASCII', 'Yes'],
  ['IBM0865', 'Nordic - Personal Computer', 'ASCII', 'Yes'],
  ['IBM0875', 'Greece', 'EBCDIC', 'Yes'],
  ['IBM0880', 'Cyrillic Multilingual', 'EBCDIC', 'Yes'],
  ['IBM0923', 'Latin 9', 'ASCII', 'Yes'],
  ['IBM1004', 'IBM PC Desktop Publishing', 'ASCII', 'Yes'],
  ['IBM1025', 'Cyrillic Multilingual', 'EBCDIC', 'Yes'],
  ['IBM1123', 'Cyrillic, Ukraine EBCDIC', 'EBCDIC', 'Yes'],
  ['IBM1124', 'Cyrillic, Ukraine ISO-8', 'ASCII', 'Yes'],
  ['IBM1140', 'USA, Canada ECECP', 'EBCDIC', 'Yes'],
  ['IBM1141', 'Austria, Germany ECECP', 'EBCDIC', 'Yes'],
  ['IBM1142', 'Denmark, Norway ECECP', 'EBCDIC', 'Yes'],
  ['IBM1143', 'Finland, Sweden ECECP', 'EBCDIC', 'Yes'],
  ['IBM1144', 'Italy ECECP', 'EBCDIC', 'Yes'],
  ['IBM1145', 'Spain, Latin America ECECP', 'EBCDIC', 'Yes'],
  ['IBM1146', 'UK ECECP', 'EBCDIC', 'Yes'],
  ['IBM1147', 'France ECECP', 'EBCDIC', 'Yes'],
  ['IBM1148', 'International ECECP', 'EBCDIC', 'Yes'],
  ['IBM1149', 'Iceland ECECP', 'EBCDIC', 'Yes'],
  ['IBM1252', 'Windows, Latin 1', 'ASCII', 'Yes'],
  ['IBMBASE', 'DCF REL 2 COMPATIBILITY', 'EBCDIC', 'Yes']
]

const CUSTOM_DIALOG_COMMAND_DOCX_ANY = ''
const CUSTOM_DIALOG_COMMAND_DOCX_DOCUMENT = 'SELDOC'
const CUSTOM_DIALOG_COMMAND_DOCX_IMPORT = 'IMPORT'
const CUSTOM_DIALOG_COMMAND_DOCX_GLOBAL = 'SELGBL'

export const CUSTOM_DIALOG_COMMANDS_DOCX = [
  { key: CUSTOM_DIALOG_COMMAND_DOCX_ANY, translationKey: 'general.any' },
  { key: CUSTOM_DIALOG_COMMAND_DOCX_DOCUMENT, translationKey: 'definition.document' },
  { key: CUSTOM_DIALOG_COMMAND_DOCX_IMPORT, translationKey: 'general.import' },
  { key: CUSTOM_DIALOG_COMMAND_DOCX_GLOBAL, translationKey: 'general.global' }
]

export const getCustomDialogDocxCommands = includeAll => includeAll
  ? CUSTOM_DIALOG_COMMANDS_DOCX
  : CUSTOM_DIALOG_COMMANDS_DOCX.filter(el => el.key !== CUSTOM_DIALOG_COMMAND_DOCX_ANY)

const CUSTOM_DIALOG_COMMAND_LOGX_ANY = ''
const CUSTOM_DIALOG_COMMAND_LOGX_LOGS = 'SELDOC'
export const CUSTOM_DIALOG_COMMAND_LOGX_JOBS = 'SELJOB'
const CUSTOM_DIALOG_COMMAND_LOGX_GLOBAL = 'SELGBL'

export const CUSTOM_DIALOG_COMMANDS_LOGX = [
  { key: CUSTOM_DIALOG_COMMAND_LOGX_ANY, translationKey: 'general.any' },
  { key: CUSTOM_DIALOG_COMMAND_LOGX_LOGS, translationKey: 'general.logs' },
  { key: CUSTOM_DIALOG_COMMAND_LOGX_JOBS, translationKey: 'general.jobs' },
  { key: CUSTOM_DIALOG_COMMAND_LOGX_GLOBAL, translationKey: 'general.global' }
]

export const getCustomDialogLogxCommands = includeAll => includeAll
  ? CUSTOM_DIALOG_COMMANDS_LOGX
  : CUSTOM_DIALOG_COMMANDS_LOGX.filter(el => el.key !== CUSTOM_DIALOG_COMMAND_LOGX_ANY)

const CUSTOM_DIALOG_JOBTYPE_ANY = ''
const CUSTOM_DIALOG_JOBTYPE_UC4 = 'BRWUC4'
const CUSTOM_DIALOG_JOBTYPE_ZOS = 'BRWZOS'
const CUSTOM_DIALOG_JOBTYPE_CTM = 'BRWCTM'

export const CUSTOM_DIALOG_JOBTYPE = [
  { key: CUSTOM_DIALOG_JOBTYPE_ANY, translationKey: 'general.any' },
  { key: CUSTOM_DIALOG_JOBTYPE_UC4, translationKey: 'search.uc4' },
  { key: CUSTOM_DIALOG_JOBTYPE_ZOS, translationKey: 'search.zos' },
  { key: CUSTOM_DIALOG_JOBTYPE_CTM, translationKey: 'search.controlm' },
]

export const getCustomDialogJobtypes = includeAll => includeAll
  ? CUSTOM_DIALOG_JOBTYPE
  : CUSTOM_DIALOG_JOBTYPE.filter(el => el.key !== CUSTOM_DIALOG_JOBTYPE_ANY)

const DOCUMENT_NODE_ANY = ''
const DOCUMENT_NODE_NODE = 'DND'
const DOCUMENT_NODE_GROUP = 'DGI'

export const DOCUMENT_NODE_TYPES = [
  { key: DOCUMENT_NODE_ANY, translationKey: 'general.any' },
  { key: DOCUMENT_NODE_NODE, translationKey: 'general.node' },
  { key: DOCUMENT_NODE_GROUP, translationKey: 'group.group' }
]

export const viewProfileSearchCriteria = [
  { key: 'NOCASE', translationKey: 'general.nothing' },
  { key: 'MASK', translationKey: 'definition.view_profile_use_text_as_search_pattern' },
  { key: 'CASE', translationKey: 'general.case_sensitive' }
]

/* ************************************************************************************** */
// result table constants

export const RESULT_TABLE_JOBTYPE_ALL = ''
export const RESULT_TABLE_JOBTYPE_STANDARD = 'BRWTAB'
export const RESULT_TABLE_JOBTYPE_UC4 = 'BRWUC4'
export const RESULT_TABLE_JOBTYPE_ZOS = 'BRWZOS'
export const RESULT_TABLE_JOBTYPE_CTM = 'BRWCTM'
export const RESULT_TABLE_JOBTYPE_SYS = 'BRWSYSL'
export const RESULT_TABLE_JOBTYPE_STB = 'BRWSTB'

export const RESULT_TABLE_JOB_TYPES = [
  { key: RESULT_TABLE_JOBTYPE_ALL, translationKey: 'general.all' },
  { key: RESULT_TABLE_JOBTYPE_STANDARD, translationKey: 'general.standard' },
  { key: RESULT_TABLE_JOBTYPE_UC4, translationKey: 'search.uc4' },
  { key: RESULT_TABLE_JOBTYPE_ZOS, translationKey: 'search.zos' },
  { key: RESULT_TABLE_JOBTYPE_CTM, translationKey: 'search.controlm' },
  { key: RESULT_TABLE_JOBTYPE_SYS, translationKey: 'search.syslog' },
  { key: RESULT_TABLE_JOBTYPE_STB, translationKey: 'search.stonebranch' },
]

/**
 * @description Gets the result table job types.
 * @param {Boolean} includeAll Flag to includes the jobtype 'All'
 */
export const getResultTableJobTypes = includeAll => includeAll
  ? RESULT_TABLE_JOB_TYPES
  : RESULT_TABLE_JOB_TYPES.filter(el => el.key !== RESULT_TABLE_JOBTYPE_ALL)

/* ************************************************************************************** */
// log constants

export const LOG_SEARCH_MODE_ANY = ''
export const LOG_SEARCH_MODE_FILTER = 'FILTER'

export const LOG_DEFINITION_SEARCH_MODES = [
  { key: LOG_SEARCH_MODE_ANY, translationKey: 'general.any' },
  { key: LOG_SEARCH_MODE_FILTER, translationKey: 'definition.document_filter' }
]

export const getLogSearchModes = includeAll => includeAll
  ? LOG_DEFINITION_SEARCH_MODES
  : LOG_DEFINITION_SEARCH_MODES.filter(el => el.key !== LOG_SEARCH_MODE_ANY)

export const LOG_TYPE_ANY = ''
export const LOG_TYPE_LIST = 'LIST'
export const LOG_TYPE_REPORT = 'REPORT'

export const LOG_TYPES = [
  { key: LOG_TYPE_ANY, translationKey: 'general.any' },
  { key: LOG_TYPE_LIST, translationKey: 'definition.log' },
  { key: LOG_TYPE_REPORT, translationKey: 'definition.sublog' }
]

/**
 * @description Validates the input ID if empty and with another comparison string.
 * TODO: change the translation key in english.js and german.js if you add it, so they all look like the same (should look like this: definition.{name}_copy_same_id_error).
 * @param inputName String - The name of the input object.
 * @param input Object - Input string and error key object.
 * @param name String - Name of the definition for translation. (e.g. search_argument)
 * @param copyInput String - The string to compare to.
 * @returns Returns an empty object on succes. On failure the object with a errorkey.
 */
export const validateInputID = (inputName, input, name, copyInput) => {
  if (input.value === '') {
    return {
      [inputName]: {
        ...input,
        errorkey: 'general.input_required'
      }
    }
  }
  if (copyInput !== undefined && copyInput !== '' && input.value === copyInput) {
    return {
      [inputName]: {
        ...input,
        errorkey: `definition.${name}_copy_same_id_error`
      }
    }
  }
  return {}
}