import { ReactNode } from 'react';
import './BrowserTooltip.scss';

const TOOLTIP_OFFSET = 10;

interface BrowserTooltipProps {
    /** The distance of the tooltip from the top of the screen */
    top: number;
    /** The distance of the tooltip from the left of the screen */
    left: number;
    /** Content of the tooltip */
    children: ReactNode;
}

export const BrowserTooltip = ({top, left, children}: BrowserTooltipProps) => {
  return children ? <div className='browser_tooltip' style={{ top: top + TOOLTIP_OFFSET, left: left + TOOLTIP_OFFSET }}> 
    {/* // @ts-ignore  */}
    {children}
  </div> : <></>
}