import * as UserProfileActions from 'redux/actions/UserProfileActions'
import * as AuthActions from 'redux/actions/AuthenticationActions'

const defaultState = {
  userProfile: undefined,
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Create userprofile actions
    case UserProfileActions.CREATE_USER_PROFILE_START:
      return { ...state }
    case UserProfileActions.CREATE_USER_PROFILE_SUCCESS:
      return { ...state }
    case UserProfileActions.CREATE_USER_PROFILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get userprofile actions
    case UserProfileActions.GET_USER_PROFILE_START:
      return { ...state, userProfile: undefined }
    case UserProfileActions.GET_USER_PROFILE_SUCCESS:
      return { ...state, userProfile: action.payload }
    case UserProfileActions.GET_USER_PROFILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Delete userprofile actions
    case UserProfileActions.DELETE_USER_PROFILE_START:
      return { ...state }
    case UserProfileActions.DELETE_USER_PROFILE_SUCCESS:
      return { ...state }
    case UserProfileActions.DELETE_USER_PROFILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Update userprofile actions
    case UserProfileActions.UPDATE_USER_PROFILE_START:
      return { ...state }
    case UserProfileActions.UPDATE_USER_PROFILE_SUCCESS:
      return { ...state }
    case UserProfileActions.UPDATE_USER_PROFILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, userProfile: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer