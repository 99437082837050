import * as ArchiveFileActions from '../actions/ArchiveFileServerActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  archiveFiles: undefined,
  archiveFile: undefined,
  archiveObjects: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get archive files actions
    case ArchiveFileActions.ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_START:
      return { ...state }
    case ArchiveFileActions.ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_SUCCESS:
      return { ...state, archiveFiles: action.payload.data }
    case ArchiveFileActions.ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_FAILED:
      return { ...state }
    case ArchiveFileActions.NO_ARCHIVEFILE_SERVER_ARCHIVEFILES_FOUND:
      return { ...state, archiveFiles: {} }
    /* ********************************************************************************** */
    // Definition get archive file actions
    case ArchiveFileActions.ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_START:
      return { ...state, archiveFile: undefined }
    case ArchiveFileActions.ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_SUCCESS:
      return { ...state, archiveFile: action.payload }
    case ArchiveFileActions.ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition get archiveobjects actions
    case ArchiveFileActions.ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_START:
      return { ...state, archiveObjects: undefined }
    case ArchiveFileActions.ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_SUCCESS:
      return { ...state, archiveObjects: action.payload }
    case ArchiveFileActions.ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, archiveFiles: undefined, archiveFile: undefined, archiveObjects: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer