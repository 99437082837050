import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import * as ObjectUtils from 'utils/ObjectUtils'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_START = 'RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_START'
export const RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_SUCCESS = 'RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_SUCCESS'
export const RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_FAILED = 'RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_FAILED'

export const RECIPIENT_DOCUMENT_GET_ASSIGNMENT_START = 'RECIPIENT_DOCUMENT_GET_ASSIGNMENT_START'
export const RECIPIENT_DOCUMENT_GET_ASSIGNMENT_SUCCESS = 'RECIPIENT_DOCUMENT_GET_ASSIGNMENT_SUCCESS'
export const RECIPIENT_DOCUMENT_GET_ASSIGNMENT_FAILED = 'RECIPIENT_DOCUMENT_GET_ASSIGNMENT_FAILED'

export const RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_START = 'RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_START'
export const RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS = 'RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS'
export const RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_FAILED = 'RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_FAILED'

export const RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_START = 'RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_START'
export const RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS = 'RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS'
export const RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_FAILED = 'RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_FAILED'

export const RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_START = 'RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_START'
export const RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS = 'RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS'
export const RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_FAILED = 'RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_FAILED'

export const NO_RECIPIENT_DOCUMENT_ASSIGNMENTS_FOUND = 'NO_RECIPIENT_DOCUMENT_ASSIGNMENTS_FOUND'

/**
 * @description Calling the rest api and do getRecipientDocumentAssignment request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'RECI', 'FORM', 'EXT', 'REPORT', 'WREPORT', 'LTYPE', 'BRWS', 'PCR', 'DCR', 'PPN',
 * 'LRICOPY', 'LRIFGEN', 'LRITGEN', 'OWNER', 'CUSER', 'CDATE', 'CTIME'
 * @param {String} recipient The recipient to search for.
 * @param {String} form The form to search for.
 * @param {String} extension The extension to search for.
 * @param {String} report The report to search for.
 * @param {String} dynamicReport The dynamicReport to search for.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getRecipientDocumentAssignment(fields, recipient, form, extension, report, dynamicReport, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: RECIPIENT_DOCUMENT_GET_ASSIGNMENT_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    queryParams.push(`RECI=${encodeURIComponent(recipient)}`)
    queryParams.push(`FORM=${encodeURIComponent(form)}`)
    queryParams.push(`EXT=${encodeURIComponent(extension)}`)
    queryParams.push(`REPORT=${encodeURIComponent(report)}`)
    queryParams.push(`WREPORT=${encodeURIComponent(dynamicReport)}`)

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipientdocumentassignment?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: RECIPIENT_DOCUMENT_GET_ASSIGNMENT_FAILED })
        } else {
          dispatch({ type: RECIPIENT_DOCUMENT_GET_ASSIGNMENT_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.recipient_document_assignment.loading_error', lang, [recipient, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_DOCUMENT_GET_ASSIGNMENT_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes a recipient document assignment.
 * @param {Object} recipientDocumentAssignment The recipientDocumentAssignment. Possible fields:
 * 'RECI', 'FORM', 'EXT', 'REPORT', 'WREPORT'
 * @param {Function} callback The callback which will be called when the delete recipient document assignment request was successful. Can be null.
 */
export function deleteRecipientDocumentAssignment(recipientDocumentAssignment, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    const assignmentMessage = []
    assignmentMessage.push(`${Lang.translate('recipient.reci_id')}: ${recipientDocumentAssignment.RECI}`)
    assignmentMessage.push(`${Lang.translate('general.form')}: ${recipientDocumentAssignment.FORM}`)
    if (recipientDocumentAssignment.EXT !== '') {
      assignmentMessage.push(`${Lang.translate('general.extension')}: ${recipientDocumentAssignment.EXT}`)
    }
    if (recipientDocumentAssignment.REPORT !== '') {
      assignmentMessage.push(`${Lang.translate('general.report')}: ${recipientDocumentAssignment.REPORT}`)
    }
    if (recipientDocumentAssignment.WREPORT !== '') {
      assignmentMessage.push(`${Lang.translate('general.dynamic_report')}: ${recipientDocumentAssignment.WREPORT}`)
    }
    const assignmentParam = assignmentMessage.join(', ')

    dispatch({ type: RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipientdocumentassignment${createQueryParamsForFetch(ObjectUtils.removeByValue(recipientDocumentAssignment, [undefined, null]))}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_FAILED })
        } else {
          dispatch({ type: RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('assignment.recipient_document.delete_assignment_success', lang, assignmentParam), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getRecipientDocumentAssignments again to get the current result
          const fields = undefined
          const recipientId = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_RECIPIENTID]
          const form = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_FORM]
          const extension = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_EXTENSION]
          const report = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_REPORT]
          const dynamicReport = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_DYNAMICREPORT]
          const assignment = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_ASSIGNMENT]
          const distributionVia = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_DISTRIBUTEVIA]
          const outputChannel = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTCHANNEL]
          const outputFormat = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTFORMAT]
          const ppn = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_PPN]

          getRecipientDocumentAssignments(fields, recipientId, form, extension,
            report, dynamicReport, assignment, distributionVia, outputChannel, outputFormat, ppn, undefined, true)(dispatch)
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.recipient_document.delete_assignment_failed', lang, [assignmentParam, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_FAILED })
      })
  }
}

/**
 * @description Calling the rest api and do getRecipientDocumentAssignments request.
 * @param {Object} fields The fields to select (If undefined => Return all fields).
 * Possible fields:
 * 'RECI', 'FORM', 'EXT', 'REPORT', 'WREPORT', 'LTYPE', 'BRWS', 'OWNER', 'CUSER', 'CDATE', 'CTIME'
 * @param {String} recipientId The recipientid to search for. Blank if not a searchcriteria.
 * @param {String} form The form to search for. Blank if not a searchcriteria.
 * @param {String} extension The extension to search for. Blank if not a searchcriteria.
 * @param {String} report The report to search for. Blank if not a searchcriteria.
 * @param {String} dynamicReport The dynamicReport to search for. Blank if not a searchcriteria.
 * @param {String} assignment The assignment to search for.
 * @param {String} distributionVia The distribution via mode to search for.
 * @param {String} outputChannel The output channel to search for. Blank if not a searchcriteria.
 * @param {String} outputFormat The output format to search for. Blank if not a searchcriteria.
 * @param {String} ppn The post processing note to search for. Blank if not a searchcriteria.
 * @param {Function} callback The callback which will be called of the request was successful.
 */
export function getRecipientDocumentAssignments(fields, recipientId, form,
  extension, report, dynamicReport, assignment, distributionVia, outputChannel, outputFormat, ppn, callback, keepPagination = false) {

  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (recipientId) { queryParams.push(`RECI=${encodeURIComponent(recipientId)}`) }
    if (form) { queryParams.push(`FORM=${encodeURIComponent(form)}`) }
    if (extension) { queryParams.push(`EXT=${encodeURIComponent(extension)}`) }
    if (report) { queryParams.push(`REPORT=${encodeURIComponent(report)}`) }
    if (dynamicReport) { queryParams.push(`WREPORT=${encodeURIComponent(dynamicReport)}`) }
    if (assignment) { queryParams.push(`LTYPE=${encodeURIComponent(assignment)}`) }
    if (distributionVia) { queryParams.push(`BRWS=${encodeURIComponent(distributionVia)}`) }
    if (outputChannel) { queryParams.push(`DCR=${encodeURIComponent(outputChannel)}`) }
    if (outputFormat) { queryParams.push(`PCR=${encodeURIComponent(outputFormat)}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipientdocumentassignments?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_RECIPIENT_DOCUMENT_ASSIGNMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_FAILED })
          }
        }
        else {
          dispatch({ type: RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.recipient_document_assignments.loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a assignment
 * @param {Object} reciDocument
 * RECI: "string",
 * FORM: "string",
 * EXT: "string",
 * REPORT: "string",
 * WREPORT: "string",
 * LTYPE: "STATIC | CONVERT | DEFAULT",
 * LRIFGEN: 0,
 * LRITGEN: 0,
 * LRICOPY: 0,
 * BRWS: "ALL | ONLINE | AUTO-P | BUNDLE",
 * PCR: "string",
 * DCR: "string",
 * PPN: "string",
 * OWNER: "string"
 * @param {*} callback The callback which will be called if the request was successful.
 */
export function createRecipientDocumentAssignment(reciDocument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let assignmentMessage = `${Lang.translate('recipient.reci_id')}: ${reciDocument['RECI']}, ${Lang.translate('general.form')}: ${reciDocument['FORM']}`
    if (reciDocument['EXT']) {
      assignmentMessage += `, ${Lang.translate('general.extension')}: ${reciDocument['EXT']}`
    }
    if (reciDocument['REPORT']) {
      assignmentMessage += `, ${Lang.translate('general.report')}: ${reciDocument['REPORT']}`
    }
    if (reciDocument['WREPORT']) {
      assignmentMessage += `, ${Lang.translate('assignment.folder_document.dynamic_report')}: ${reciDocument['WREPORT']}`
    }
    dispatch({ type: RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipientdocumentassignment`, {
        method: 'post',
        body: ObjectUtils.removeByValue(reciDocument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('assignment.folder_document.create_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          const fields = undefined
          const recipientId = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_RECIPIENTID]
          const form = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_FORM]
          const extension = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_EXTENSION]
          const report = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_REPORT]
          const dynamicReport = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_DYNAMICREPORT]
          const assignment = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_ASSIGNMENT]
          const distributionVia = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_DISTRIBUTEVIA]
          const outputChannel = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTCHANNEL]
          const outputFormat = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTFORMAT]
          const ppn = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_PPN]

          getRecipientDocumentAssignments(fields, recipientId, form, extension,
            report, dynamicReport, assignment, distributionVia, outputChannel, outputFormat, ppn, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('assignment.folder_document.create_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and creates a assignment
 * @param {Object} reciDocument
 * RECI: "string",
 * FORM: "string",
 * EXT: "string",
 * REPORT: "string",
 * WREPORT: "string",
 * LTYPE: "STATIC | CONVERT | DEFAULT",
 * LRIFGEN: 0,
 * LRITGEN: 0,
 * LRICOPY: 0,
 * BRWS: "ALL | ONLINE | AUTO-P | BUNDLE",
 * PCR: "string",
 * DCR: "string",
 * PPN: "string",
 * OWNER: "string"
 * @param {*} callback The callback which will be called if the request was successful.
 */
export function updateRecipientDocumentAssignment(reciDocument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let assignmentMessage = `${Lang.translate('recipient.reci_id')}: ${reciDocument['RECI']}, ${Lang.translate('general.form')}: ${reciDocument['FORM']}`
    if (reciDocument['EXT']) {
      assignmentMessage += `, ${Lang.translate('general.extension')}: ${reciDocument['EXT']}`
    }
    if (reciDocument['REPORT']) {
      assignmentMessage += `, ${Lang.translate('general.report')}: ${reciDocument['REPORT']}`
    }
    if (reciDocument['WREPORT']) {
      assignmentMessage += `, ${Lang.translate('assignment.folder_document.dynamic_report')}: ${reciDocument['WREPORT']}`
    }

    dispatch({ type: RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipientdocumentassignment`, {
        method: 'put',
        body: ObjectUtils.removeByValue(reciDocument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('assignment.folder_document.modify_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          const fields = undefined
          const recipientId = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_RECIPIENTID]
          const form = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_FORM]
          const extension = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_EXTENSION]
          const report = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_REPORT]
          const dynamicReport = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_DYNAMICREPORT]
          const assignment = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_ASSIGNMENT]
          const distributionVia = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_DISTRIBUTEVIA]
          const outputChannel = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTCHANNEL]
          const outputFormat = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTFORMAT]
          const ppn = prefs[Preferences.ASSIGNMENT_RECIPIENT_DOC_PPN]

          getRecipientDocumentAssignments(fields, recipientId, form, extension,
            report, dynamicReport, assignment, distributionVia, outputChannel, outputFormat, ppn, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('assignment.folder_document.modify_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_FAILED, payload: { error } })
      })
  }
}