import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
// Utils
import * as UserUtils from 'utils/UserUtils'

class SearchResultImport extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    match: PropTypes.any.isRequired,
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }

  getBody() {
    // || {} is required to get undefined for the destructed variables when userprofile is undefined
    const { VCIENIMP } = this.props.userprofile || {}

    let url = this.props.match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    switch (url) {
      // standard import result
      case 'standardimport':
        return <div/>
      // import dialogs result
      case 'customimport':
        return <div/>
      // fallback is standard import
      default:
        return (
          // take care of the route because if you enter a wrong route you can create an infinite loop!
          UserUtils.isAdmin()
            ? <Redirect to='/import/standardimport' /> // For admin std-import is default
            : VCIENIMP
              ? <Redirect to='/import/standardimport' /> // Not admin, but allowed to see std-import
              : <Redirect to='/import/customimport' /> // Fallback when no access to std-import then custom-imports

        )
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

const mapStateToProps = state => {
  return {
    userprofile: state.auth.serverdata && state.auth.serverdata.userprofile
  }
}


export default connect(mapStateToProps)(SearchResultImport)