import * as DateUtils from 'utils/DateUtils'
import store from 'redux/Store'

// length param is needed because data is modified so the length growths
export const getSortTypes = (data, length) => {
  let formats = []
  for (let i = 0; i < length; i++) {
    formats.push('')
  }
  data.forEach(d => {
    for (let i = 0; i < length; i++) {
      if (formats[i] !== 'string' && d[i]) {
        if (d[i] === '') {
          formats[i] = 'string'
        }
        // check hexadecimal and numbers with length of 32 (whats the name for it?)
        else if ((d[i].length === 16 || d[i].length === 32) && /^(([0-9a-fA-F]{16}){1,2})$/.test(d[i])) {
          formats[i] = 'string'
        }
        // check date-time
        else if (DateUtils.getDateObject(d[i]).isValid()) {
          formats[i] = 'date-time'
        }
        // check date
        else if (DateUtils.isDate(d[i], store.getState().auth.serverdata.preferences.DATEMASK)) {
          formats[i] = 'date'
        }
        // check time
        else if (/^([0-1]?[0-9]|2[0-4]):([0-5][0-9]):([0-5][0-9]):([0][0])?$/g.test(d[i])) {
          formats[i] = 'string'
        }
        // check number
        else if (!isNaN(d[i])) {
          formats[i] = 'number'
        }
        // check icon
        else if (d[i].props && d[i].props.name) {
          formats[i] = 'icon'
        }
        else {
          formats[i] = 'string'
        }
      }

    }
  })
  formats = formats.map(f => f === '' ? 'string' : f);
  return formats
}