import React, { Component } from 'react'
import Button from 'BetaUX2Web-Components/src/components/button/Button'

import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'
import Input from 'BetaUX2Web-Components/src/components/input/Input'

import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as JobgroupsDefinitionActions from 'redux/actions/JobgroupsDefinitionActions'


import { connect } from 'react-redux'

class DefinitionJobGroup extends Component {

  defaultState = {
    jobgroupName: '',
    title: '',
    owner: '',
    archiveMedia: 0
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount = () => {
    // initialize search fields
    const { preferences } = this.props

    let archiveMediaIndex = Math.max(DefinitionUtils.ARCHIVEPOOL_MEDIA.findIndex(d => d.key === preferences[Preferences.JOBGROUPS_DEFINITION_ARCHIVE_MEDIA]), 0)

    if (preferences) {
      this.setState({
        jobgroupName: preferences[Preferences.JOBGROUPS_DEFINITION_JOBGROUP_NAME] || this.defaultState.jobgroupName,
        title: preferences[Preferences.JOBGROUPS_DEFINITION_TITLE] || this.defaultState.title,
        owner: preferences[Preferences.JOBGROUPS_DEFINITION_OWNER] || this.defaultState.owner,
        archiveMedia: archiveMediaIndex !== -1 ? archiveMediaIndex : 0
      })
    }
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = event => {
    const { jobgroupName, title, owner, archiveMedia } = this.state
    const { changePrefs, getJobgroups } = this.props
    event.preventDefault()

    // update preferences
    const prefsObj = {
      [Preferences.JOBGROUPS_DEFINITION_JOBGROUP_NAME]: jobgroupName,
      [Preferences.JOBGROUPS_DEFINITION_TITLE]: owner,
      [Preferences.JOBGROUPS_DEFINITION_OWNER]: title,
      [Preferences.JOBGROUPS_DEFINITION_ARCHIVE_MEDIA]: DefinitionUtils.ARCHIVEPOOL_MEDIA[archiveMedia].key
    }
    changePrefs(prefsObj)
    // search jobgroups
    const searchObj = {
      JGIGNAME: jobgroupName,
      OWNER: owner,
      JGITITLE: title,
      ARCHMED: DefinitionUtils.ARCHIVEPOOL_MEDIA[archiveMedia].key
    }
    getJobgroups(searchObj)
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { jobgroupName, title, owner, archiveMedia } = this.state
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        <Card title={translate('general.general')} id={`${id}_general`}>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_jobgroup_name`}
                title={translate('general.jobgroup_name')}
                value={jobgroupName}
                onInputChanged={val => this.setState({ jobgroupName: val })}
                maxLength={80}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_title`}
                title={translate('general.title')}
                value={title}
                onInputChanged={val => this.setState({ title: val })}
                maxLength={80}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={6}>
              <Input
                id={`${id}_owner`}
                title={translate('general.owner')}
                value={owner}
                onInputChanged={val => this.setState({ owner: val })}
                maxLength={8}
              />
            </Column>
            <Column colMD={6}>
              <Dropdown
                id={`${id}_archive_media`}
                title={translate('general.archive_media')}
                items={DefinitionUtils.ARCHIVEPOOL_MEDIA.map(d => translate(d.translationKey))}
                activeIndex={archiveMedia}
                onChange={index => this.setState({ archiveMedia: index })}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props
    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id } = this.props
    return (
      <form
        id={id}
        className={'bux_drawer_form'}
        onSubmit={this.handleSubmitSearch}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch),
    getJobgroups: searchObj => JobgroupsDefinitionActions.getJobgroups(undefined, searchObj)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionJobGroup)