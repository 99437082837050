import moment from 'moment'
import { Component } from 'react'

// Utils
import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import {
  DATEMASK, LANGUAGE, SERVER_SERVERLOGFILE_DATE,
  SERVER_SERVERLOGFILE_SERVER_LOG_FILE
} from 'redux/general/Preferences'
import { DDMMYYYY_DOT, formatDate, getDateFromUnixTimestamp, getFormattedDate, getUnixTimestamp, isDate, isUnixTimestamp, today } from 'utils/DateUtils'

// components
import { Button, Card, Column, Dropdown, Row } from 'BetaUX2Web-Components/src/'
import Datepicker from 'components/datepicker/Datepicker'

// Redux
import { connect } from 'react-redux'
import { changePrefs } from 'redux/actions/PreferencesActions'
import { getServerLogFile } from 'redux/actions/ServerActions'

const SERVER_LOG_FILES = [
  'ARCHIVE_CLEANUP_ERR',
  'ARCHIVE_CLEANUP_LOG',
  'ARCHIVE_CLEANUP_OUT',
  'ARCHIVE_ERR',
  'ARCHIVE_LOG',
  'ARCHIVE_OUT',
  'BARC_ARC_ERR',
  'BARC_ARC_LOG',
  'BARC_ARC_OUT',
  'BARC_CHK_ERR',
  'BARC_CHK_LOG',
  'BARC_CHK_OUT',
  'BARC_CLN_ERR',
  'BARC_CLN_LOG',
  'BARC_CLN_OUT',
  'BARC_RLD_ERR',
  'BARC_RLD_LOG',
  'BARC_RLD_OUT',
  'BIDXQ_LOG',
  'BIDXQ_OUT',
  'BPRNT_LOG',
  'BPRNT_OUT',
  'BRLDQ_LOG',
  'BRLDQ_OUT',
  'BUGEN_LOG',
  'BUGEN_OUT',
  'BUNDL_LOG',
  'BUNDL_OUT',
  'BUTLT_LOG',
  'BUTLT_OUT',
  'DELOG_LOG',
  'DELOG_OUT',
  'DENTE_LOG',
  'DENTE_OUT',
  'DEONL_ERR',
  'DEONL_LOG',
  'DEONL_OUT',
  'DEPRT_LOG',
  'DEPRT_OUT',
  'DLOAD_LOG',
  'DLOAD_OUT',
  'ERRORLOG',
  'LOGFILE',
  'QUERY_LOG',
  'QUERY_OUT',
  'RELOAD_LOG',
  'RELOAD_OUT'
]

class ServerLogFile extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  state = {
    serverLogFile: this.props.preferences[SERVER_SERVERLOGFILE_SERVER_LOG_FILE] || SERVER_LOG_FILES[0],
    date: {
      value: '',
      error: ''
    }
  }

  componentDidMount = () => {
    const { preferences, datemask } = this.props
    const date = preferences[SERVER_SERVERLOGFILE_DATE]
      // fallback if old preferences data is stored as date and not as unix timestamp
      ? isUnixTimestamp(preferences[SERVER_SERVERLOGFILE_DATE])
        ? getDateFromUnixTimestamp(preferences[SERVER_SERVERLOGFILE_DATE], datemask)
        : ''
      : formatDate(today(), this.props.preferences[DATEMASK])
    this.setState({ date: { value: date, error: '' } })
  }

  /**
   * @description Updates the date when the datemask was changed.
   */
  componentDidUpdate = prevProps => {
    const { datemask } = this.props
    if (datemask !== prevProps.datemask) {
      // just updated the date when the date fits the previous datemask
      if (isDate(this.state.date.value, prevProps.datemask)) {
        const date = {
          value: moment(this.state.date.value, prevProps.datemask).format(datemask),
          errorkey: ''
        }
        this.setState({ date })
      }
      else {
        // remove error when date fits the new datemask
        if (isDate(this.state.date.value, datemask)) {
          this.flushError('date')
        }
      }
    }
  }

  handleChange = ({ key, value }) => {
    this.setState({
      [key]: typeof this.state[key] === 'object'
        ? { value, error: '' }
        : value
    })
  }

  setErrorIfEmpty = ({ key, message }) => {
    if (this.state[key].value === '') {
      this.setState({
        [key]: {
          ...this.state[key],
          error: message
        }
      })
    }
  }

  flushError = key => {
    this.setState({
      [key]: {
        ...this.state[key],
        error: ''
      }
    })
  }

  resetValues = () => {
    this.setState({
      serverLogFile: SERVER_LOG_FILES[0],
      date: {
        ...this.state.date,
        value: formatDate(today(), this.props.preferences[DATEMASK]),
      }
    })
  }

  search = event => {
    const { setNewPreferences, fireSearchRequest, datemask } = this.props
    const { serverLogFile, date } = this.state
    event.preventDefault()
    if (date.value) {
      setNewPreferences({
        [SERVER_SERVERLOGFILE_SERVER_LOG_FILE]: serverLogFile,
        [SERVER_SERVERLOGFILE_DATE]: getUnixTimestamp(date.value, datemask)
      })
      fireSearchRequest({
        LSTKEY: serverLogFile,
        LOGDATE: getFormattedDate(date.value, DDMMYYYY_DOT)
      })
    }
  }

  render = () => {
    const { id = 'serverlogfile', lang, datemask } = this.props
    const { serverLogFile, date } = this.state
    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.search}>
        <main id={id} className={'bux_drawer_main'} style={{ display: 'block', minHeight: 'auto' }}>
          <Card title={translate('general.general')}>
            <Row>
              <Column colMD={12}>
                <Dropdown
                  id={`${id}_dropdown`}
                  items={SERVER_LOG_FILES}
                  activeIndex={SERVER_LOG_FILES.indexOf(serverLogFile)}
                  onChange={index => this.handleChange({ key: 'serverLogFile', value: SERVER_LOG_FILES[index] })}
                  title={translate('server.log_file')}
                  autoFocus
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <Datepicker
                  id={`${id}_date`}
                  title={translate('general.date')}
                  value={date.value}
                  error={date.error}
                  onChange={date => this.handleChange({ key: 'date', value: date })}
                  onBlur={() => this.setErrorIfEmpty({ key: 'date', message: translate('general.input_required') })}
                  required={`${translate('general.required_field')}`}
                  language={lang}
                  dateFormat={datemask}
                  onInvalidDate={() => this.setState({ date: { ...this.state.date, error: translate('general.invalid_date_format') } })}
                />
              </Column>
            </Row>
          </Card>
        </main>
        <footer id={`${id}_footer`} className='bux_drawer_footer'>
          <Button
            id={`${id}_search`}
            text={translate('general.search')}
            onClick={this.search}
            submit
            primary
          />
          <Button
            id={`${id}_resetBtn`}
            icon='undo'
            iconType='material'
            onClick={this.resetValues}
          />
        </footer>
      </form>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    token: auth.serverdata.token,
    preferences: auth.serverdata.preferences,
    lang: auth.serverdata.preferences[LANGUAGE],
    datemask: auth.serverdata.preferences[DATEMASK]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNewPreferences: state => {
      changePrefs(state)(dispatch)
    },
    fireSearchRequest: (searchParams, callback) => {
      getServerLogFile(searchParams, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerLogFile)