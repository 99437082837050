import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as SortUtils from 'utils/SortUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import TableSettings from 'components/table_settings/TableSettings'

/* Dialogs */
import CopyExternalCmdDialog from 'components/dialogs/copy_external_command_dialog/CopyExternalCommandDialog'
import CreateExternalCmdDialog from 'components/dialogs/create_external_command_dialog/CreateExternalCommandDialog'
import ModifyExternalCmdDialog from 'components/dialogs/modify_external_command_dialog/ModifyExternalCommandDialog'

// redux
import { translate } from 'language/Language'
import * as Actions from 'redux/actions/ExternalCommandDefinitionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultExternalCmd extends Component {
  state = {
    dialogs: {
      showCreate: false,
      createDialogData: undefined,
      showModify: false,
      showCopy: false,
      showDelete: false,
      showTableSettings: false
    },
    selectedEntry: {
      id: null,
      desc: null,
      cmd: null,
      argument: null,
      date: null,
      time: null,
      user: null
    },
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: EXTERNALCMD_ID, translation: 'external_command.id', default: true },
      { rest: EXTERNALCMD_DESCRIPTION, translation: 'external_command.description', default: true },
      { rest: EXTERNALCMD_COMMAND, translation: 'external_command.command', default: true },
      { rest: EXTERNALCMD_ARGUMENTS, translation: 'external_command.arguments', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: UTLDATE },
      { rest: UTLTIME },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER }
    ]
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  handleRefresh = () => {
    const { preferences } = this.props
    const name = preferences[Preferences.DEFINITION_EXT_COMMANDS_ID]
    const description = preferences[Preferences.DEFINITION_EXT_COMMANDS_DESCRIPTION]
    const command = preferences[Preferences.DEFINITION_EXT_COMMANDS_COMMAND]

    this.props.getExternalCommands(name, description, command)
  }

  handleDelete = dialog => { // deletes selected externalcmd
    this.props.deleteExternalCommand(
      this.state.selectedEntry.id,
      this.closeDialog(dialog)
    )
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id, lang } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit', lang)}
        onClick={() => { this.openDialog('showModify', rowIndex) }}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete', lang)}
        onClick={() => { this.openDialog('showDelete', rowIndex) }}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options', lang)}>
        <TableButtonGroupItem
          onClick={() => { this.openDialog('showModify', rowIndex) }}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit', lang)}
          title={translate('general.edit', lang)}
        />
        <TableButtonGroupItem
          onClick={() => { this.openDialog('showCopy', rowIndex) }}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy', lang)}
          title={translate('general.copy', lang)}
        />
        <TableButtonGroupItem
          onClick={() => { this.openDialog('showDelete', rowIndex) }}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete', lang)}
          title={translate('general.delete', lang)}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create', lang)}
          onClick={() => this.showPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.openDialog('showTableSettings')}
        />,
      ]
    )
  }

  /**
   * @description gets the index of the header in redux state definitions.externalcmds.header
   * @param {String} header header name of the header in redux state definitions.externalcmds.header
   */
  headerData = header => this.props.externalcmds.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, externalcmds } = this.props
    let data = []
    let headers = this.getUsedHeader()
    /* Get Indexs base on Headers-Array */
    externalcmds.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else if (header === UTLTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('UTLDATE')], element[this.headerData('UTLTIME')].substring(0, 8)))
        }
        else if (header === UTLDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('UTLDATE')], element[this.headerData('UTLTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })

    return data
  }

  openDialog = (key, selectedEntry) => {
    this.setState(prevState => ({
      dialogs: {
        ...prevState.dialogs,
        [key]: true
      }
    }))

    selectedEntry !== undefined &&
      this.saveSelectionInState(this.props.externalcmds.data[selectedEntry])

  }

  saveSelectionInState = entry => {
    const [id, desc, cmd, argument, , , date, time, user] = entry
    this.setState({
      selectedEntry: { id, desc, cmd, argument, date, time, user }
    })
  }

  closeDialog(key) {
    this.setState(prevState => ({
      dialogs: {
        ...prevState.dialogs,
        [key]: false
      }
    }))
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_EXTERNALCMD]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_EXTERNALCMD].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_EXTERNALCMD]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_EXTERNALCMD].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  showPrefilledCreateDialog = () => {
    const {preferences } = this.props
    this.setState({
      dialogs: {
        showCreate: true,
        createDialogData: {
          UTL: preferences[Preferences.DEFINITION_EXT_COMMANDS_ID],
          UTLINFO: preferences[Preferences.DEFINITION_EXT_COMMANDS_DESCRIPTION],
          UTLPROG: preferences[Preferences.DEFINITION_EXT_COMMANDS_COMMAND],
          UTLARGS: preferences[Preferences.DEFINITION_EXT_COMMANDS_ARGUMENTS]
        }
      }
    })
  }

  render = () => {
    const { externalcmds, id, loading, drawerExpanded, lang, autoDrawer, datemask, keepPagination } = this.props
    const { dialogs, selectedEntry } = this.state
    let data = externalcmds && externalcmds.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        { /* Create external command dialog */}
        {dialogs.showCreate && (
          <CreateExternalCmdDialog
            id={id}
            visible={dialogs.showCreate}
            prefilledData={this.state.dialogs.createDialogData}
            onClose={() => { this.setState({dialogs: {showCreate: false, createDialogData: undefined}}) }}
          />
        )}
        {/* Modify external command dialog */}
        {dialogs.showModify && (
          <ModifyExternalCmdDialog
            id={id}
            onClose={() => { this.closeDialog('showModify') }}
            selectedDialog={selectedEntry}
          />
        )}
        {dialogs.showCopy && (
          <CopyExternalCmdDialog
            id={id}
            onClose={() => { this.closeDialog('showCopy') }}
            selectedDialog={selectedEntry}
          />
        )}

        {dialogs.showDelete && (// delete external command dialog
          <DeleteDialog
            id={`${id}_externalcmd`}
            title={translate('definition.externalcmd.del_ext_cmd')}
            question={translate('definition.externalcmd.question_del_ext_cmd')}
            onClose={() => { this.closeDialog('showDelete') }}
            onDelete={() => { this.handleDelete('showDelete') }}>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_ext_cmd_id_key_text`}>
                  {translate('definition.externalcmd.ext_cmd_id')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_ext_cmd_id_value_text`}>
                  {selectedEntry.id}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_description_key_text`}>
                  {`${translate('general.description')}:`}
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_betaux_user_id_value_text`}>
                  {selectedEntry.desc}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {dialogs.showTableSettings && (
          <TableSettings
            id={id}
            onClose={() => this.closeDialog('showTableSettings')}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_EXTERNALCMD }}
          />
        )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          { // show nosearch if externalcmds not exist in redux
            externalcmds
              ? (
                // show empty result if there are no externalcmds after searching
                data
                  ? (
                    <DataTable
                      loading={loading}
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={data}
                      selectable={true}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={rowIndex => this.openDialog('showModify', rowIndex)}
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      translate={key => translate(key)}
                      langugae={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptyResult
                      description={translate('emptyresult.no_external_cmd_result', lang)}
                      id={`${id}_emptyresult`}
                      link={translate('emptyresult.create_externaldcmd_link', lang)}
                      onClick={() => { this.showPrefilledCreateDialog() }}
                      headline={translate('emptyresult.no_result_headline')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )
          }
        </ResultContainer>
      </>
    )
  }
}

const EXTERNALCMD_ID = 'UTL'
const EXTERNALCMD_DESCRIPTION = 'UTLINFO'
const EXTERNALCMD_COMMAND = 'UTLPROG'
const EXTERNALCMD_ARGUMENTS = 'UTLARGS'
const UTLDATE = 'UTLDATE'
const UTLTIME = 'UTLTIME'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

SearchResultExternalCmd.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    externalcmds: state.definitions.externalCommands.externalCommands,
    keepPagination: state.definitions.externalCommands.keepPagination,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getExternalCommands: (name, description, programName, callback) => {
      Actions.getExternalCommands(name, description, programName, callback)(dispatch)
    },
    deleteExternalCommand: (name, callback) => {
      Actions.deleteExternalCommand(name, callback)(dispatch)
    },
    refreshSearch: () => { Actions.refreshSearch()(dispatch) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultExternalCmd)