import PropTypes from 'prop-types'
import { Component } from 'react'
import * as DateUtils from 'utils/DateUtils'
import { OUTPUT_QUEUE_STATUS_VALUES } from 'utils/QueueUtils'
import * as SortUtils from 'utils/SortUtils'

// components
import {
  DataTable, DownloadWrapper, EmptySearchResult, Icon, Link, NoSearch, ResultContainer, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import IPPServerStatus from 'components/dialogs/ipp_server_status/IPPServerStatus'
import ModifyPriorityDialog from 'components/dialogs/modify_priority_dialog/ModifyPriorityDialog'
import OutputQueueRequestInformation from 'components/dialogs/output_queue_request_information/OutputQueueRequestInformation'
import RequeueOutputQueueDialog from 'components/dialogs/requeue_output_queue_dialog/RequeueOutputQueueDialog'
import RerunOutputQueue from 'components/dialogs/rerun_output_queue/RerunOutputQueue'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import { getOutputQueueInformation, getOutputQueueRequestDetails, getOutputQueues, updateOutputQueue } from 'redux/actions/OutputQueueActions'
import * as Preferences from 'redux/general/Preferences'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultOutputQueue extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }

  state = {
    showTableSettingsDialog: false,
    showRerunDialog: false,
    showRequeueDialog: false,
    showModifyReleasePriorityDialog: false,
    showRequestInformationDialog: false,
    showModifyPriorityDialog: false,
    showIPPServerStatusDialog: false,
    ippServerStatusToken: undefined,
    ippServerStatusOutputChannel: undefined,
    header: this.fillHeaderInformation(),
    applyGroupedSorting: true
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data.
   * @param {Array} header The headers.
   * @returns {Array} The column sort defs.
   */
  getColumnSortDefs = (data, header) => {
    return SortUtils.getSortTypes(data, header.length)
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    return [
      { rest: REQUESTQUEUED, translation: 'queue.requestqueued', default: true },
      { rest: DCRDOC, translation: 'queue.outputchannel_id_document', default: true },
      { rest: BSTATUS, translation: 'general.status', default: true },
      { rest: PRTPRIO, translation: 'queue.priority', default: true },
      { rest: PAGES, translation: 'general.pages', default: true },
      { rest: COPIES, translation: 'import.copies', default: true },
      { rest: BTYPE, translation: 'general.type', default: true },
      { rest: PRTRQFLG, translation: 'queue.requeued', default: true },
      { rest: BREQUEST, translation: 'general.requestor', default: true },
      { rest: RECI, translation: 'recipient.reci_id', default: true },
      { rest: SRCJOBN, translation: 'general.jobname', default: true },
      { rest: BJOBNAME, translation: 'queue.bundlejobname', default: true },
      { rest: PRINTBEGIN, translation: 'queue.print_begin', default: true },
      { rest: PRINTEND, translation: 'queue.print_end', default: true },
      { rest: PRTINFO_COMBINED, translation: 'queue.print_information', default: true },
      { rest: PRTSNMP, translation: 'queue.snmp', default: true },
      { rest: BTOKEN },
      { rest: BDATE },
      { rest: BTIME },
      { rest: DCRPTYPE },
      { rest: ORDER },
      { rest: BJOBID },
      { rest: PRTRC },
      { rest: PRTINFO },
      { rest: PRTSENS },
      { rest: PRTDATEB },
      { rest: PRTTIMEB },
      { rest: PRTDATEE },
      { rest: PRTTIMEE },
      { rest: PRTDATA },
    ]
  }

  /**
   * @description Gets the index of the header in redux state.
   * @param {String} header The header name of the header in redux state.
   * @returns {Number} The index of the header.
   */
  headerData = header => this.props.outputQueues.header.indexOf(header)

  /**
   * @description Refreshes the table.
   */
  handleRefresh = () => {
    const { preferences, getOutputQueues, datemask } = this.props
    const searchParams = {
      'DCR': preferences[Preferences.QUEUE_OUTPUT_OUTPUTCHANNEL_ID],
      'DCRTYPE': preferences[Preferences.QUEUE_OUTPUT_TYPE],
      'BTYPE': preferences[Preferences.QUEUE_OUTPUT_PRINT_BUNDLE],
      'BNAME': preferences[Preferences.QUEUE_OUTPUT_BUNDLE_NAME],
      'BREQUEST': preferences[Preferences.QUEUE_OUTPUT_REQUESTOR],
      'PRTSTAT': preferences[Preferences.QUEUE_OUTPUT_STATUS],
      'FORM': preferences[Preferences.QUEUE_OUTPUT_FORM],
      'EXT': preferences[Preferences.QUEUE_OUTPUT_EXTENSION],
      'REPORT': preferences[Preferences.QUEUE_OUTPUT_REPORT],
      'JOBNAME': preferences[Preferences.QUEUE_OUTPUT_JOBNAME],
      'DCRIPADR': preferences[Preferences.QUEUE_OUTPUT_ADDRESS],
      'RECI': preferences[Preferences.QUEUE_OUTPUT_RECIPIENT_ID],
      'PROCESS': preferences[Preferences.QUEUE_OUTPUT_DISPLAY],
      // ! needs to be checked if we need to clear this value if its not a number
      'PRTPRIO': preferences[Preferences.QUEUE_OUTPUT_SPECIFIC_PRIORITY],
      'PRTRQFLG': preferences[Preferences.QUEUE_OUTPUT_REQUEUED]
    }

    if (preferences[Preferences.QUEUE_OUTPUT_ACTIVE_TAB] === 0) {
      if (preferences[Preferences.QUEUE_OUTPUT_LASTTIME_MODE] === 0) {
        searchParams['SDATE'] = 'TODAY'
      }
      else if (preferences[Preferences.QUEUE_OUTPUT_LASTTIME_MODE] === 1) {
        searchParams['SDATE'] = 'YESTERDAY'
      }
      else if (preferences[Preferences.QUEUE_OUTPUT_LASTTIME_MODE] === 2) {
        searchParams['FROMLAST'] = preferences[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_LAST]
        searchParams['TUNITS'] = preferences[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_UNIT]
      }
    }
    else if (preferences[Preferences.QUEUE_OUTPUT_ACTIVE_TAB] === 1) {
      const sdate = DateUtils.getDate(datemask, preferences[Preferences.QUEUE_OUTPUT_START_DATE])
      const stime = preferences[Preferences.QUEUE_OUTPUT_START_TIME]
      const edate = DateUtils.getDate(datemask, preferences[Preferences.QUEUE_OUTPUT_END_DATE])
      const etime = preferences[Preferences.QUEUE_OUTPUT_END_TIME]

      searchParams['SDATE'] = DateUtils.getTimeshiftDate(sdate, stime, DateUtils.DDMMYYYY_DOT)
      searchParams['STIME'] = stime !== '' ? DateUtils.getTimeshiftDate(sdate, stime, DateUtils.TIME_DATEMASK) : ''
      searchParams['EDATE'] = DateUtils.getTimeshiftDate(edate, etime, DateUtils.DDMMYYYY_DOT)
      searchParams['ETIME'] = etime !== '' ? DateUtils.getTimeshiftDate(edate, etime, DateUtils.TIME_DATEMASK) : ''
    }

    getOutputQueues(searchParams)
  }

  /**
   * @description Shows the request information dialog.
   * @param {Number} index The index of the row.
   */
  handleShowInformation = index => {
    const { outputQueues, getOutputQueueInformation, getOutputQueueRequestDetails } = this.props
    const outputQueue = { BTOKEN: outputQueues.data[index][this.headerData('BTOKEN')] }
    const disabled = outputQueues.data[index] ? outputQueues.data[index][this.headerData('PRTDATA')] !== 'INF' : false
    if (!disabled) {
      getOutputQueueInformation(outputQueue, () => {
        const outputQueue = {
          TOKEN: outputQueues.data[index][this.headerData('BTOKEN')]
        }
        getOutputQueueRequestDetails(outputQueue, () => this.setState({ showRequestInformationDialog: true }))
      })
    }
  }

  /**
   * @description Updates an output queue entry.
   * @param {Number} index The index of the row.
   * @param {String} command The command to use for the update.
   */
  updateOutputQueue = (index, command) => {
    const { outputQueues, updateOutputQueue } = this.props
    const outputQueue = outputQueues.data[index][this.headerData('BTOKEN')]
    const outputChannel = outputQueues.data[index][this.headerData('DCRDOC')]
    updateOutputQueue(outputQueue, outputChannel, undefined, command)
  }

  /**
   * @description Shows the modify release priority dialog.
   * @param {Number} index The index of the row.
   */
  showModifyReleasePriorityDialog = index => {
    const { outputQueues, getOutputQueueInformation } = this.props
    const callback = () => this.setState({ showModifyReleasePriorityDialog: true })
    const outputQueue = { BTOKEN: outputQueues.data[index][this.headerData('BTOKEN')] }
    getOutputQueueInformation(outputQueue, callback)
  }

  /**
   * @description Executes the modify release priority command and updates the priority.
   * @param {Number} priority The new priority.
   */
  executeModifyReleasePriority = priority => {
    const { outputDetails, updateOutputQueue } = this.props
    const callback = () => this.setState({ showModifyReleasePriorityDialog: false })
    const bToken = outputDetails.data[0][outputDetails.header.indexOf('BTOKEN')]
    const outputChannel = outputDetails.data[0][outputDetails.header.indexOf('DCR')]
    updateOutputQueue(bToken, outputChannel, priority, 'RELEASE', callback)
  }

  /**
   * @description Shows the modify priority dialog.
   * @param {Number} index The index of the row.
   */
  showModifyPriorityDialog = index => {
    const { outputQueues, getOutputQueueInformation } = this.props
    const callback = () => this.setState({ showModifyPriorityDialog: true })
    const outputQueue = { BTOKEN: outputQueues.data[index][this.headerData('BTOKEN')] }
    getOutputQueueInformation(outputQueue, callback)
  }

  /**
   * @description Executes the modify priority command and updates the priority.
   * @param {Number} priority The new priority.
   */
  executeModifyPriority = priority => {
    const { outputDetails, updateOutputQueue } = this.props
    const callback = () => this.setState({ showModifyPriorityDialog: false })
    const bToken = outputDetails.data[0][outputDetails.header.indexOf('BTOKEN')]
    const outputChannelID = outputDetails.data[0][outputDetails.header.indexOf('DCR')]
    updateOutputQueue(bToken, outputChannelID, priority, 'REPRIO', callback)
  }

  /**
   * @description Opens the rerun dialog.
   * @param {Number} index The index of the row.
   */
  openRerunDialog = index => {
    const { outputQueues, getOutputQueueInformation } = this.props
    const callback = () => this.setState({ showRerunDialog: true })
    const outputQueue = { BTOKEN: outputQueues.data[index][this.headerData('BTOKEN')] }
    getOutputQueueInformation(outputQueue, callback)
  }

  /**
   * @description Opens the requeue dialog.
   * @param {Number} index The index of the row.
   */
  openRequeueDialog = index => {
    const { outputQueues, getOutputQueueInformation } = this.props
    const callback = () => this.setState({ showRequeueDialog: true })
    const outputQueue = { BTOKEN: outputQueues.data[index][this.headerData('BTOKEN')] }
    getOutputQueueInformation(outputQueue, callback)
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   * @returns {Array} The actionbuttons for a row in the table.
   */
  createActionButtons = rowIndex => {
    const { id, outputQueues } = this.props
    const status = outputQueues.data[rowIndex] ? outputQueues.data[rowIndex][this.headerData('BSTATUS')] : undefined
    const printerType = outputQueues.data[rowIndex] ? outputQueues.data[rowIndex][this.headerData('DCRPTYPE')] : undefined
    const disabled = outputQueues.data[rowIndex] ? outputQueues.data[rowIndex][this.headerData('PRTDATA')] !== 'INF' : false
    const moreVertOptions = [
      <TableButtonGroupItem
        key={`${id}_infoBtn`}
        id={`${id}_infoBtn`}
        title={translate('queue.show_information')}
        onClick={() => this.handleShowInformation(rowIndex)}
        icon={<Icon name='info' className='actionIcon' />}
        text={translate('queue.show_information')}
      />,
      <TableButtonGroupSeparator key={`${id}_separator1`} id={id} />,
      <TableButtonGroupItem
        key={`${id}_reruncompletelyBtn`}
        id={`${id}_reruncompletelyBtn`}
        title={translate('queue.rerun')}
        onClick={() => this.openRerunDialog(rowIndex)}
        icon={<Icon name='rerun' className='actionIcon' />}
        text={translate('queue.rerun')}
      />,
      <TableButtonGroupItem
        key={`${id}_requeueBtn`}
        id={`${id}_requeueBtn`}
        title={translate('queue.requeue')}
        onClick={() => this.openRequeueDialog(rowIndex)}
        icon={<Icon name='requeue' className='actionIcon' />}
        text={translate('queue.requeue')}
      />
    ]

    if (status === 'WAITING') {
      moreVertOptions.push(
        <TableButtonGroupItem
          key={`${id}_modifyBtn`}
          id={`${id}_modifyBtn`}
          title={translate('general.modify_priority')}
          onClick={() => this.showModifyPriorityDialog(rowIndex)}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.modify_priority')}
        />
      )
    }

    if (status === 'ACTIVE') {
      moreVertOptions.push(
        <TableButtonGroupItem
          key={`${id}_cancelBtn`}
          id={`${id}_cancelBtn`}
          title={translate('general.cancel')}
          onClick={() => this.updateOutputQueue(rowIndex, 'STOP')}
          icon={<Icon name='queue_stop' className='actionIcon' />}
          text={translate('general.cancel')}
        />
      )
    }

    if (status !== 'DELETED') {
      moreVertOptions.push(
        <TableButtonGroupItem
          key={`${id}_${status === 'HOLD' ? 'releaseBtn' : 'holdBtn'}`}
          id={`${id}_${status === 'HOLD' ? 'releaseBtn' : 'holdBtn'}`}
          title={translate(status === 'HOLD' ? 'queue.release' : 'search.hold')}
          onClick={status === 'HOLD' ? () => this.showModifyReleasePriorityDialog(rowIndex) : () => this.updateOutputQueue(rowIndex, 'HOLD')}
          icon={
            <Icon
              name={status === 'HOLD' ? 'queue_unhold' : 'queue_hold'}
              className={'actionIcon'}
            />
          }
          text={translate(status === 'HOLD' ? 'queue.release' : 'search.hold')}
        />,
        <TableButtonGroupSeparator key={`${id}_separator3`} id={id} />,
        <TableButtonGroupItem
          key={`${id}_markdeleteBtn`}
          id={`${id}_markdeleteBtn`}
          title={translate('general.mark_delete')}
          onClick={() => this.updateOutputQueue(rowIndex, 'DELETE')}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.mark_delete')}
        />
      )
    }

    if (printerType === 'IPP') {
      moreVertOptions.push(
        <TableButtonGroupSeparator key={`${id}_separator3`} id={id} />,
        <TableButtonGroupItem
          key={`${id}_show_ipp_server_status_btn`}
          id={`${id}_show_ipp_server_status_btn`}
          title={translate('queues.output_queue_ipp_server_status')}
          text={translate('queues.output_queue_ipp_server_status')}
          onClick={() => this.setState({
            showIPPServerStatusDialog: true,
            ippServerStatusToken: outputQueues.data[rowIndex][this.headerData('BTOKEN')],
            ippServerStatusOutputChannel: outputQueues.data[rowIndex][this.headerData('DCRDOC')]
          })}
          icon={<Icon name={'monitor'} className={'actionIcon'} />}
        />
      )
    }

    const result = [
      <TableButton
        id={`${id}_table_button_info_${rowIndex}`}
        iconName='info'
        title={translate('queue.show_information')}
        onClick={() => this.handleShowInformation(rowIndex)}
        disabled={disabled}
      />,
      <TableButton
        id={`${id}_table_button_rerun_completly_${rowIndex}`}
        iconName='rerun'
        title={translate('queue.rerun')}
        onClick={() => this.openRerunDialog(rowIndex)}
        disabled={disabled}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}
        disabled={disabled}>
        {moreVertOptions}
      </TableButtonGroup>
    ]
    return result
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />
      ]
    )
  }

  /**
   * @description Gets the status as a jsx icon.
   * @param {Object} el The element of the row with all data.
   * @param {Number} index The index of the row.
   * @returns {Object} The status as a jsx icon.
   */
  getStatus = (el, index) => {
    const status = el[this.headerData('BSTATUS')]
    let result = ''
    OUTPUT_QUEUE_STATUS_VALUES.forEach(d => {
      if (d.key === status) {
        result = <Icon key={`outputqueue_status_icon_${index}`} id={this.props.id} name={d.icon} color={d.color} title={translate(d.translationKey)} />
      }
    })
    return result
  }

  /**
   * @description Gets the translated string for a type.
   * @param {String} type The type to translate.
   * @returns {String} The translated type.
   */
  translateType = type => {
    switch (type) {
      case 'O':
        return translate('definition.document_online_print')
      case 'A':
        return translate('definition.document_auto_print')
      // ! Will be changed in future bux version. At the moment 'Batch print' and 'Batch bundle' has the same value so we cannot differentiate.
      case 'B':
        return translate('definition.document_batch_print_bundle')
      default:
        return type
    }
  }

  /**
   * @description Gets the translated string for the requeued value.
   * @param {String} val The value to translate.
   * @returns {String} The translated requeued value.
   */
  translateRequeued = val => {
    switch (val) {
      case 'MRQ': return translate('queue.manually_requeued')
      case 'ARD': return translate('queue.automatically_requeued')
      case 'SRQ': return translate('queue.srq')
      case 'NO': return translate('general.no')
      default: return val
    }
  }

  /**
   * @description builds the data to a an array which is used later
   * @returns {Array} The data.
   */
  getData = () => {
    const { outputQueues, datemask } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    outputQueues.data.forEach((el, index) => {
      const dataBuffer = []
      usedHeader.forEach(h => {
        if (h === REQUESTQUEUED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('BDATE')], el[this.headerData('BTIME')].substring(0, 8)))
        }
        else if (h === PRINTBEGIN) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('PRTDATEB')], el[this.headerData('PRTTIMEB')].substring(0, 8)))
        }
        else if (h === PRINTEND) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('PRTDATEE')], el[this.headerData('PRTTIMEE')].substring(0, 8)))
        }
        else if (h === BTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('BDATE')], el[this.headerData('BTIME')].substring(0, 8)))
        }
        else if (h === BDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('BDATE')], el[this.headerData('BTIME')].substring(0, 8), false))
        }
        else if (h === PRTTIMEB) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('PRTDATEB')], el[this.headerData('PRTTIMEB')].substring(0, 8)))
        }
        else if (h === PRTDATEB) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('PRTDATEB')], el[this.headerData('PRTTIMEB')].substring(0, 8), false))
        }
        else if (h === PRTTIMEE) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('PRTDATEE')], el[this.headerData('PRTTIMEE')].substring(0, 8)))
        }
        else if (h === PRTDATEE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('PRTDATEE')], el[this.headerData('PRTTIMEE')].substring(0, 8), false))
        }
        else if (h === BSTATUS) {
          dataBuffer.push(this.getStatus(el, index))
        }
        else if (h === PRTRQFLG) {
          dataBuffer.push(this.translateRequeued(el[this.headerData(PRTRQFLG)]))
        }
        else if (h === BTYPE) {
          dataBuffer.push(this.translateType(el[this.headerData(BTYPE)]))
        }
        else if (h === PRTINFO_COMBINED) {
          let result = [el[this.headerData(PRTRC)], el[this.headerData(PRTINFO)], el[this.headerData(PRTSENS)]].join(' ')
          dataBuffer.push(result)
        }
        else {
          const val = el[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })

      //* WIP: This component displays data which is grouped. In order to apply the sorting logic the 'BTOKEN' will be used for grouping. All items of a group have the same 'BTOKEN' value *//
      if (this.state.applyGroupedSorting) {
        dataBuffer.push(el[this.headerData('BTOKEN')])
      }

      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description builds the data to a an array which is used later
   * @returns {Array} The data.
   */
  getCleanData = () => {
    const { outputQueues, datemask } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    outputQueues.data.forEach(el => {
      const dataBuffer = []
      usedHeader.forEach(h => {
        if (h === REQUESTQUEUED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('BDATE')], el[this.headerData('BTIME')].substring(0, 8)))
        }
        else if (h === PRINTBEGIN) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('PRTDATEB')], el[this.headerData('PRTTIMEB')].substring(0, 8)))
        }
        else if (h === PRINTEND) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('PRTDATEE')], el[this.headerData('PRTTIMEE')].substring(0, 8)))
        }
        else if (h === BTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('BDATE')], el[this.headerData('BTIME')].substring(0, 8)))
        }
        else if (h === BDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('BDATE')], el[this.headerData('BTIME')].substring(0, 8), false))
        }
        else if (h === PRTTIMEB) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('PRTDATEB')], el[this.headerData('PRTTIMEB')].substring(0, 8)))
        }
        else if (h === PRTDATEB) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('PRTDATEB')], el[this.headerData('PRTTIMEB')].substring(0, 8), false))
        }
        else if (h === PRTTIMEE) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('PRTDATEE')], el[this.headerData('PRTTIMEE')].substring(0, 8)))
        }
        else if (h === PRTDATEE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('PRTDATEE')], el[this.headerData('PRTTIMEE')].substring(0, 8), false))
        }
        else if (h === BSTATUS) {
          const status = el[this.headerData('BSTATUS')]
          const found = OUTPUT_QUEUE_STATUS_VALUES.find(d => d.key === status)
          dataBuffer.push(found ? translate(found.translationKey) : status)
        }
        else if (h === PRTRQFLG) {
          dataBuffer.push(this.translateRequeued(el[this.headerData(PRTRQFLG)]))
        }
        else if (h === BTYPE) {
          dataBuffer.push(this.translateType(el[this.headerData(BTYPE)]))
        }
        else if (h === PRTINFO_COMBINED) {
          let result = [el[this.headerData(PRTRC)], el[this.headerData(PRTINFO)], el[this.headerData(PRTSENS)]].join(' ')
          dataBuffer.push(result)
        }
        else {
          const val = el[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })

      //* WIP: This component displays data which is grouped. In order to apply the sorting logic the 'BTOKEN' will be used for grouping. All items of a group have the same 'BTOKEN' value *//
      if (this.state.applyGroupedSorting) {
        dataBuffer.push(el[this.headerData('BTOKEN')])
      }

      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_OUTPUT]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_OUTPUT].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_OUTPUT]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_OUTPUT].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    if (!header) return []

    return header.filter(h => h.default).map(h => h.rest)
  }

  /**
   * @description Gets the available headers.
   * @returns {Array} The available headers.
   */
  getAvailableHeaders = () => {
    const { header } = this.state
    let buffer = []
    header.forEach(header => {
      if (header.translation) {
        buffer.push(header.translation)
      } else {
        buffer.push(header.rest)
      }
    })
    return buffer
  }

  render = () => {
    const { id, drawerExpanded, autoDrawer, outputQueues, datemask, lang, keepPagination } = this.props
    const {
      showTableSettingsDialog,
      showModifyReleasePriorityDialog,
      showRequestInformationDialog,
      showModifyPriorityDialog,
      showRerunDialog,
      showIPPServerStatusDialog,
      ippServerStatusToken,
      ippServerStatusOutputChannel,
      showRequeueDialog
    } = this.state
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    const data = outputQueues && outputQueues.data
      ? this.getData(outputQueues.data)
      : null
    const cleanData = outputQueues && outputQueues.data
      ? this.getCleanData(outputQueues.data)
      : null

    return (
      <>
        {
          showTableSettingsDialog &&
          <TableSettings
            id={id}
            headers={this.state.header}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_QUEUE_OUTPUT }}
          />
        }
        {
          showModifyReleasePriorityDialog &&
          <ModifyPriorityDialog
            id={`${id}_modify_release_priority`}
            onClose={() => this.setState({ showModifyReleasePriorityDialog: false })}
            title={translate('queue.output_queue_release_request')}
            executeFunc={this.executeModifyReleasePriority}
          />
        }
        {
          showRequestInformationDialog &&
          <OutputQueueRequestInformation
            id={`${id}_outputrequestinfo`}
            onClose={() => this.setState({ showRequestInformationDialog: false })}
          />
        }
        {
          showModifyPriorityDialog &&
          <ModifyPriorityDialog
            id={`${id}_modify_priority`}
            onClose={() => this.setState({ showModifyPriorityDialog: false })}
            title={translate('general.modify_priority')}
            executeFunc={this.executeModifyPriority}
          />
        }
        {
          showRerunDialog &&
          <RerunOutputQueue
            id={`${id}_rerun_output_queue`}
            onClose={() => this.setState({ showRerunDialog: false })}
          />
        }
        {
          showRequeueDialog &&
          <RequeueOutputQueueDialog
            id={`${id}_requeue_output_queue`}
            onClose={() => this.setState({ showRequeueDialog: false })}
          />
        }
        {
          showIPPServerStatusDialog &&
          <IPPServerStatus
            id={`${id}_show_ipp_server_status_dialog`}
            onClose={() => this.setState({ showIPPServerStatusDialog: false, ippServerStatusToken: undefined, ippServerStatusOutputChannel: undefined })}
            token={ippServerStatusToken}
            outputChannel={ippServerStatusOutputChannel}
          />
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if users not exist in redux
            outputQueues
              ? (
                // show empty result if there are no users after searching
                outputQueues.data
                  ? (
                    <DataTable
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={cleanData}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={index => this.handleShowInformation(index)}
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(cleanData, translatedHeaders)}
                      fillPage={fillPage}
                      selectable
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                      //* Group sorting functionality: *//
                      applyGroupedSorting={this.state.applyGroupedSorting}
                      indexGroupingKey={header.length} // The last entry of the array for 'data' and 'cleanData' for this component is the 'BTOKEN'. It has no corresponding header entry, therefore the length of the header can be used for indexing.
                    />
                  )
                  : (
                    <EmptySearchResult
                      id={`${id}_emptysearchresult`}
                      description={translate('general.no_data_found')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )}
        </ResultContainer>
      </>
    )
  }
}

const BTOKEN = 'BTOKEN'
const BDATE = 'BDATE'
const BTIME = 'BTIME'
const DCRDOC = 'DCRDOC'
const BSTATUS = 'BSTATUS'
const PAGES = 'PAGES'
const RECI = 'RECI'
const BTYPE = 'BTYPE'
const COPIES = 'COPIES'
const DCRPTYPE = 'DCRPTYPE'
const ORDER = 'ORDER'
const SRCJOBN = 'SRCJOBN'
const PRTRQFLG = 'PRTRQFLG'
const BJOBNAME = 'BJOBNAME'
const BJOBID = 'BJOBID'
const PRTPRIO = 'PRTPRIO'
const PRTDATEB = 'PRTDATEB'
const PRTTIMEB = 'PRTTIMEB'
const PRTDATEE = 'PRTDATEE'
const PRTTIMEE = 'PRTTIMEE'
const PRTRC = 'PRTRC'
const PRTINFO = 'PRTINFO'
const PRTINFO_COMBINED = 'PRTINFO_COMBINED'
const PRTSENS = 'PRTSENS'
const PRTDATA = 'PRTDATA'
const PRTSNMP = 'PRTSNMP'
const BREQUEST = 'BREQUEST'
const REQUESTQUEUED = 'REQUESTQUEUED'
const PRINTBEGIN = 'PRINTBEGIN'
const PRINTEND = 'PRINTEND'

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    outputQueues: state.queue.output.outputs,
    outputDetails: state.queue.output.outputDetails,
    keepPagination: state.queue.output.keepPagination,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOutputQueues: (searchParams, callback) => {
      getOutputQueues(searchParams, callback)(dispatch)
    },
    getOutputQueueInformation: (outputQueue, callback) => {
      getOutputQueueInformation(outputQueue, callback)(dispatch)
    },
    updateOutputQueue: (outputQueueID, outputChannelID, priority, command, callback) => {
      updateOutputQueue(outputQueueID, outputChannelID, priority, undefined, command, callback)(dispatch)
    },
    getOutputQueueRequestDetails: (outputQueue, callback) => {
      getOutputQueueRequestDetails(outputQueue, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultOutputQueue)