import React, {Component} from 'react'

import moment from 'moment'
import {connect} from 'react-redux'

import {
  Card,
  Checkbox, Column, Dropdown, Filepicker, Input, NumericSpinner, Row, Switch
} from 'BetaUX2Web-Components/src'
import {LetterCase} from 'BetaUX2Web-Components/src/types'
import Datepicker from '../../datepicker/Datepicker'
import Timepicker from '../../timepicker/Timepicker'
import TimeCard from '../../time_card/TimeCard'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import SelectorDialogHierarchy from '../selector_dialog_hierarchy/SelectorDialogHierarchy'


import {translate} from 'language/Language'
import * as CustomDialogActions from 'redux/actions/CustomDialogActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as Preferences from 'redux/general/Preferences'
import * as CustomDialogConfigRenderer from 'utils/CustomDialogConfigRenderer'
import * as DateUtils from 'utils/DateUtils'

import './CustomDialog.scss'

import * as CustomDialogBuilder from './customDialogBuilder'


class CustomDialog extends Component {

  state = {
    documentNodeSelection: {
      value: this.props.customDialogInfo[2] === 'IMPORT' ? this.props.customDialogInfo[7] : this.props.customDialogInfo[6],
      selected: false,
      error: ''
    },
    showSelectorDialog: false,
    showSelectorDialogHierarchy: false,
    showDocumentNodeSelectorHierarchy: false,
    // Stores all information the modal selector needs to show information and update the data.
    currentModalSelectorInformation: {},
    string: {},
    schoice: {},
    amount: {},
    date: {},
    datetime: {},
    datedate: {},
    numeric: {},
    mchoice: {},
    cchoice: {},
    cchoice2: {},
    lchoice: {},
    dtframe: {},
    fileopen: {}
  }

  /**
     * @description Calls the prop callbackoverwrite and pass a function as parameter to access the state from the parent component.
     */
  initialalizeStateGetter = () => {
    this.props.getState(() => {
      return this.state
    })
  }

  /**
     * @description Calls the prop stateoverwrite and pass a function as parameter to overwrite the state from the parent component.
     */
  initialalizeStateOverwrite = () => {
    this.props.overwriteState((newState, callback) => {
      this.setState({...newState}, () => callback && callback())
    })
  }

  componentDidMount = () => {
    if (!!this.props.getCachedCustomDialogConfig()) {
      // Case: Init with cached data:
      const currentCachedCustomDialogConfig = this.props.getCachedCustomDialogConfig()
      const customDialogConfigState = this.parseCachedCustomDialogConfigState(currentCachedCustomDialogConfig)

      this.setState(customDialogConfigState, () => {
        this.initialalizeStateGetter()
        this.initialalizeStateOverwrite()
      })
    } else {
      // Case: Init with non-cached data:
      this.getNeededInformation()
    }
  }

  isDateAllowedDateString = value => DateUtils.validDateStrings.includes(value.toUpperCase())

  componentDidUpdate = prevProps => {
    const {datemask} = this.props
    const {datetime, datedate} = this.state
    if (prevProps.datemask !== datemask) {
      const newDateTime = {...datetime}
      const newDateDate = {...datedate}
      for (const key of Object.keys(newDateTime)) {
        if (newDateTime[key]?.date?.value !== '' && newDateTime[key]?.date?.error === '' && !this.isDateAllowedDateString(newDateTime[key]?.date?.value)) {
          newDateTime[key].date.value = moment(newDateTime[key]?.date?.value, prevProps.datemask).format(datemask)
        }
      }
      for (const key of Object.keys(newDateDate)) {
        if (newDateDate[key]?.from?.value !== '' && newDateDate[key]?.from?.error === '' && !this.isDateAllowedDateString(newDateDate[key]?.from?.value)) {
          newDateDate[key].from.value = moment(newDateDate[key]?.from?.value, prevProps.datemask).format(datemask)
        }
        if (newDateDate[key]?.to?.value !== '' && newDateDate[key]?.to?.error === '' && !this.isDateAllowedDateString(newDateDate[key]?.to?.value)) {
          newDateDate[key].to.value = moment(newDateDate[key]?.to?.value, prevProps.datemask).format(datemask)
        }
      }
      this.setState({datetime: newDateTime, datedate: newDateDate})
    }

    if (prevProps.customDialogInfo[0] !== this.props.customDialogInfo[0] && !!this.props.getCachedCustomDialogConfig()) {
      // Case: Cache hit on update, e.g. dropdown change -> this will load the values into the custom dialog of the previous successful custom dialog search
      const currentCachedCustomDialogConfig = this.props.getCachedCustomDialogConfig()
      const customDialogConfigState = this.parseCachedCustomDialogConfigState(currentCachedCustomDialogConfig)

      this.setState(customDialogConfigState)
    }
  }

  getDate = input => {
    const {datemask} = this.props
    if (DateUtils.validDateStrings.includes(input.toUpperCase())) {
      return input
    }
    const date = moment(input, DateUtils.YYYYMMDD_MINUS)
    return date.isValid() ? date.format(datemask) : ''
  }

  /**
     * @description Stores the position information and pre-fill the state with the needed default data.
     */
  getNeededInformation = callback => {
    const {customDialog, headerIndex} = this.props
    // Create categorized objects to summerize data for rendered elements.
    const string = {}
    const schoice = {}
    const amount = {}
    const date = {}
    const datetime = {}
    const datedate = {}
    const numeric = {}
    const mchoice = {}
    const cchoice = {}
    const cchoice2 = {}
    const lchoice = {}
    const hchoice = {}
    const dtframe = {}
    const fileopen = {}
    customDialog.forEach((d, i) => {
      if (!['LF', 'LB', 'FL', 'FR'].includes(d[headerIndex('SPIOP')])) {
        if (d[headerIndex('SPIDTYPE')] === 'STRING') {
          string[i] = {
            value: d[headerIndex('SPIVALUE')],
            error: '',
            ref: React.createRef(),
            required: d[headerIndex('SPIVALR')] === '*',
            value2: d[headerIndex('SPIVALU2')],
            error2: '',
            ref2: React.createRef(),
            required2: d[headerIndex('SPIVALR2')] === '*',
            restKey: d[headerIndex('IXINAME')]
          }
        } else if (['NUMERIC', 'RNUM'].includes(d[headerIndex('SPIDTYPE')])) {
          numeric[i] = {
            // For empty numeric default 0.
            value: d[headerIndex('SPIVALUE')] !== '' ? parseInt(d[headerIndex('SPIVALUE')]) : '',
            error: '',
            ref: React.createRef(),
            required: d[headerIndex('SPIVALR')] === '*',
            value2: d[headerIndex('SPIVALU2')] !== '' ? parseInt(d[headerIndex('SPIVALU2')]) : '',
            error2: '',
            ref2: React.createRef(),
            required2: d[headerIndex('SPIVALR2')] === '*',
            restKey: d[headerIndex('IXINAME')]
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'SCHOICE') {
          const items = d[headerIndex('SPIENAM2')].split(';').filter(d => d !== '') // 'A;B;C;' -> ['A', 'B', 'C', ''].filter() -> ['A', 'B', 'C']
          // Used trim to remove all spaces. Sometimes, the default value from REST-API is a single blank which leads into errors when using it for the request.
          const dbValues = d[headerIndex('SPIVALU3')].split(';').filter(d => d !== '').map(d => d.trim())
          // If default value is not empty.
          const dropdownIndex = d[headerIndex('SPIVALUE')] !== ''
          // If default value is included in items.
            ? items.findIndex(item => item === d[headerIndex('SPIVALUE')]) !== -1
              ? items.findIndex(item => item === d[headerIndex('SPIVALUE')])
              : 0
            : 0
          schoice[i] = {
            value: dropdownIndex,
            dbValues,
            restKey: d[headerIndex('IXINAME')]
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'AMOUNT') {
          const items = d[headerIndex('SPIENAM2')].split(';').filter(d => d !== '')
          const dbValues = d[headerIndex('SPIVALU3')].split(';').filter(d => d !== '')
          // If default value is not empty.
          const dropdownIndex = d[headerIndex('SPIVALU2')] !== ''
          // If default value is included in items.
            ? items.findIndex(item => item === d[headerIndex('SPIVALU2')]) !== -1
              ? items.findIndex(item => item === d[headerIndex('SPIVALU2')])
              : 0
            : 0
          amount[i] = {
            input: {
              value: d[headerIndex('SPIVALUE')] === '' ? 0 : parseInt(d[headerIndex('SPIVALUE')]),
              error: '',
              restKey: d[headerIndex('IXINAME')]
            },
            dropdown: {value: dropdownIndex, restKey: 'TUNITS', dbValues},
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'DATE') {
          date[i] = {
            value: this.getDate(d[headerIndex('SPIVALUE')]),
            error: '',
            ref: React.createRef(),
            required: d[headerIndex('SPIVALR')] === '*',
            value2: this.getDate(d[headerIndex('SPIVALU2')]),
            error2: '',
            ref2: React.createRef(),
            required2: d[headerIndex('SPIVALR2')] === '*',
            restKey: d[headerIndex('IXINAME')],
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'DATETIME') {
          datetime[i] = {
            date: {
              value: this.getDate(d[headerIndex('SPIVALUE')]),
              error: '',
              restKey: d[headerIndex('IXINAME')],
              required: d[headerIndex('SPIVALR')] === '*',
              ref: React.createRef()
            },
            time: {
              value: d[headerIndex('SPIVALU2')] === ''
              // Default value if the rest api value is empty.
                ? ''
              // Remove milliseconds.
                : d[headerIndex('SPIVALU2')].substring(0, 8),
              error: '',
              restKey: {'SDATE': 'STIME', 'EDATE': 'ETIME'}[d[headerIndex('IXINAME')]],
              required: d[headerIndex('SPIVALR2')] === '*',
              ref: React.createRef()
            }
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'DATEDATE') {
          datedate[i] = {
            from: {
              value: this.getDate(d[headerIndex('SPIVALUE')]),
              error: '',
              restKey: d[headerIndex('IXINAME')],
              required: d[headerIndex('SPIVALR')] === '*',
              ref: React.createRef()
            },
            to: {
              value: this.getDate(d[headerIndex('SPIVALU2')]),
              error: '',
              restKey: d[headerIndex('SPIINAM2')],
              required: d[headerIndex('SPIVALR2')] === '*',
              ref: React.createRef()
            }
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'MCHOICE') {
          const dbValues = d[headerIndex('SPIVALU3')].split(';').filter(d => d !== '') // 'A;B;C;' -> ['A', 'B', 'C', ''].filter() -> ['A', 'B', 'C']
          mchoice[i] = {restKey: d[headerIndex('IXINAME')]}
          dbValues.forEach(d => {
            mchoice[i][d] = false
          })
        } else if (d[headerIndex('SPIDTYPE')] === 'CCHOICE') {
          cchoice[i] = {
            value: d[headerIndex('SPIVALUE')],
            error: '',
            restKey: d[headerIndex('IXINAME')],
            required: d[headerIndex('SPIVALR')] === '*',
            ref: React.createRef()
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'HCHOICE') {
          hchoice[i] = {
            value: d[headerIndex('SPIVALUE')],
            error: '',
            restKey: d[headerIndex('IXINAME')],
            required: d[headerIndex('SPIVALR')] === '*',
            ref: React.createRef()
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'CCHOICE2') {
          cchoice2[i] = {
            form: {
              value: d[headerIndex('SPIVALUE')],
              error: '',
              restKey: d[headerIndex('IXINAME')],
              required: d[headerIndex('SPIVALR')] === '*',
              ref: React.createRef()
            },
            ext: {
              value: d[headerIndex('SPIVALU2')],
              error: '',
              restKey: d[headerIndex('SPIINAM2')],
              required: d[headerIndex('SPIVALR2')] === '*',
              ref: React.createRef()
            }
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'LCHOICE') {
          const items = d[headerIndex('SPIENAM2')].split(';').filter(d => d !== '')
          const dbValues = d[headerIndex('SPIVALU3')].split(';').filter(d => d !== '')
          // If default value is not empty.
          const dropdownIndex = d[headerIndex('SPIVALUE')] !== ''
          // If default value is included in items.
            ? items.findIndex(item => item === d[headerIndex('SPIVALUE')]) !== -1
              ? items.findIndex(item => item === d[headerIndex('SPIVALUE')])
              : 0
            : 0
          lchoice[i] = {
            value: dropdownIndex,
            dbValues,
            restKey: d[headerIndex('IXINAME')]
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'DTFRAME') {
          dtframe[i] = {
            tabIndex: 0,
            lastTimeModeIndex: 0,
            customLast: 0,
            customUnitIndex: 0,
            fromDate: {value: this.getDate(d[headerIndex('SPIVALUE')]), error: ''},
            fromTime: {value: '', error: ''},
            toDate: {value: this.getDate(d[headerIndex('SPIVALU2')]), error: ''},
            toTime: {value: '', error: ''},
            restKey: d[headerIndex('IXINAME')]
          }
        } else if (d[headerIndex('SPIDTYPE')] === 'FILEOPEN') {
          fileopen[i] = {
            value: [],
            error: '',
            restKey: d[headerIndex('IXINAME')],
            // Always required.
            required: true,
          }
        }
      }
    })
    this.setState({
      string,
      schoice,
      amount,
      date,
      datetime,
      datedate,
      numeric,
      mchoice,
      cchoice,
      cchoice2,
      lchoice,
      hchoice,
      dtframe,
      fileopen
    }, () => {
      this.initialalizeStateGetter()
      this.initialalizeStateOverwrite()
      callback && callback()
    })
  }

  /**
   * This function will add refs to the relevant custom dialog parameter objects. This is necessary, because refs cannot be persisted in the Redux state.
   * @param {Object} cachedCustomDialogConfigWithoutRefs This object contains the custom dialog parameter objects (e.g. for 'string', 'cchoice', ..) to populate the custom dialog component state
   * @returns {Object} e.g. { 'string': {value: String, error: String, ref: Ref, required: Boolean, ..}, 'cchoice': {..}, ..}
   */
  parseCachedCustomDialogConfigState = (cachedCustomDialogConfigWithoutRefs) => {
    return Object.entries(cachedCustomDialogConfigWithoutRefs).reduce((acc1, [key, dialogs]) => {
      const dialogsWithRefs = Object.entries(dialogs).reduce((acc2, [k, valueWithoutRefs]) => {
        let valueWithRefs

        if (key === 'string' || key === 'numeric' || key === 'date') {
          valueWithRefs = { ...valueWithoutRefs, ref: React.createRef(), ref2: React.createRef() }
        } else if (key === 'datetime') {
          valueWithRefs = { ...valueWithoutRefs, date: { ...valueWithoutRefs.date, ref: React.createRef() }, time: { ...valueWithoutRefs.time, ref: React.createRef() } }
        } else if (key === 'datedate') {
          valueWithRefs = { ...valueWithoutRefs, from: { ...valueWithoutRefs.from, ref: React.createRef() }, to: { ...valueWithoutRefs.to, ref: React.createRef() } }
        } else if (key === 'cchoice' || key === 'hchoice') {
          valueWithRefs = { ...valueWithoutRefs, ref: React.createRef() }
        } else if (key === 'cchoice2') {
          valueWithRefs = { ...valueWithoutRefs, form: { ...valueWithoutRefs.form, ref: React.createRef() }, ext: { ...valueWithoutRefs.ext, ref: React.createRef() } }
        } else {
          valueWithRefs = { ...valueWithoutRefs }
        }

        acc2[k] = valueWithRefs
        return acc2
      }, {})

      acc1[key] = dialogsWithRefs
      return acc1
    }, {})
  }

  onChangeString = (stringKey, valueKey, val) => {
    const {string} = this.state
    this.setState({
      string: {
        ...string,
        [stringKey]: {
          ...string[stringKey],
          [valueKey]: val,
          ...(valueKey === 'value' && {error: ''}),
          ...(valueKey === 'value2' && {error2: ''})
        }
      }
    })
  }

  onBlurString = (required, stringKey, valueKey) => {
    const {string} = this.state
    if (required) {
      if (string[stringKey][valueKey] === '') {
        this.setState(state => ({
          string: {
            ...state.string,
            [stringKey]: {
              ...state.string[stringKey],
              ...this.validateString(stringKey, valueKey)
            }
          }
        }))
      }
    }
  }

  /**
     * @description Validates the input.
     */
  validateString = (stringKey, valueKey) => {
    const {string} = this.state
    if (string[stringKey][valueKey] !== '') {
      return {}
    }
    return {
      ...string[stringKey],
      ...(valueKey === 'value' && {error: translate('general.input_required')}),
      ...(valueKey === 'value2' && {error2: translate('general.input_required')})
    }
  }

  renderStringInput = (object, index) => {
    const {id, headerIndex} = this.props
    const {string} = this.state
    if (object[headerIndex('SPIOP')] === 'RA') {
      return (
        <>
          <Column colMD={6}>
            <Input
              id={`${id}_${index}_0`}
              title={object[headerIndex('SPIENAME')]}
              value={string[index].value}
              error={string[index].error ? string[index].error : ''}
              onInputChanged={val => this.onChangeString(index, 'value', val)}
              onBlur={() => this.onBlurString(object[headerIndex('SPIVALR')] === '*', index, 'value')}
              maxLength={!isNaN(parseInt(object[headerIndex('SPIWEFLD')])) ? parseInt(object[headerIndex('SPIWEFLD')]) : undefined}
              required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
              disabled={object[headerIndex('SPIVALD')] === '*'}
              ref={string[index].ref}
              letterCase={object[headerIndex('SPIUCASE')] === '*' ? LetterCase.uppercase : undefined}
              ignoreMinWidth
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_${index}_1`}
              title={' '}
              value={string[index].value2}
              error={string[index].error2 ? string[index].error2 : ''}
              onInputChanged={val => this.onChangeString(index, 'value2', val)}
              onBlur={() => this.onBlurString(object[headerIndex('SPIVALR2')] === '*', index, 'value2')}
              maxLength={!isNaN(parseInt(object[headerIndex('SPIWEFLD')])) ? parseInt(object[headerIndex('SPIWEFLD')]) : undefined}
              required={object[headerIndex('SPIVALR2')] === '*' ? `${translate('general.required_field')}` : false}
              disabled={object[headerIndex('SPIVALD2')] === '*'}
              ref={string[index].ref}
              letterCase={object[headerIndex('SPIUCASE')] === '*' ? LetterCase.uppercase : undefined}
              ignoreMinWidth
            />
          </Column>
        </>
      )
    } else {
      return (
        <Column colMD={12}>
          <Input
            id={`${id}_${index}`}
            title={object[headerIndex('SPIENAME')]}
            value={string[index].value}
            error={string[index].error ? string[index].error : ''}
            onInputChanged={val => this.onChangeString(index, 'value', val)}
            onBlur={() => this.onBlurString(object[headerIndex('SPIVALR')] === '*', index, 'value')}
            maxLength={!isNaN(parseInt(object[headerIndex('SPIWEFLD')])) ? parseInt(object[headerIndex('SPIWEFLD')]) : undefined}
            required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
            ref={string[index].ref}
            letterCase={object[headerIndex('SPIUCASE')] === '*' ? LetterCase.uppercase : undefined}
            ignoreMinWidth
          />
        </Column>
      )
    }
  }

  renderStringLabel = (object, index) => {
    const {id, headerIndex} = this.props
    return (
      <Column colMD={12}>
        <label id={`${id}_${index}`}>
          {object[headerIndex('SPIENAME')]}
        </label>
      </Column>
    )
  }

  onChangeNumeric = (key, valueKey, val) => {
    const {numeric} = this.state
    if (val.match(/[0-9*]|(^$)/)) {
      this.setState({
        numeric: {
          ...this.state.numeric,
          [key]: {
            ...numeric[key],
            [valueKey]: val,
            ...(valueKey === 'value' && {error: ''}),
            ...(valueKey === 'value2' && {error2: ''}),
          }
        }
      })
    }
  }

  onBlurNumeric = (required, numericKey, valueKey) => {
    const {numeric} = this.state
    if (required) {
      if (numeric[numericKey][valueKey] === '') {
        this.setState(state => ({
          numeric: {
            ...state.numeric,
            [numericKey]: {
              ...state.numeric[numericKey],
              ...this.validateNumeric(numericKey, valueKey)
            }
          }
        }))
      }
    }
  }

  /**
     * @description Validates the input.
     */
  validateNumeric = (numericKey, valueKey) => {
    const {numeric} = this.state
    if (numeric[numericKey][valueKey] !== '') {
      return {}
    }
    return {
      ...numeric[numericKey],
      ...(valueKey === 'value' && {error: translate('general.input_required')}),
      ...(valueKey === 'value2' && {error2: translate('general.input_required')})
    }
  }


  renderNumeric = (object, index) => {
    const {numeric} = this.state
    const {id, headerIndex} = this.props
    if (object[headerIndex('SPIOP')] === 'RA') {
      return (
        <>
          <Column colMD={6}>
            <Input
              id={`${id}_${index}_0`}
              title={object[headerIndex('SPIENAME')]}
              value={numeric[index].value}
              error={numeric[index].error ? numeric[index].error : ''}
              onInputChanged={val => this.onChangeNumeric(index, 'value', val)}
              required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
              disabled={object[headerIndex('SPIVALD')] === '*'}
              ref={numeric[index].ref}
              maxLength={!isNaN(parseInt(object[headerIndex('SPIWEFLD')])) ? parseInt(object[headerIndex('SPIWEFLD')]) : undefined}
              onBlur={() => this.onBlurNumeric(object[headerIndex('SPIVALR')] === '*', index, 'value')}
              ignoreMinWidth
            />
          </Column>
          <Column colMD={6}>
            <Input
              title={object[headerIndex('SPIENAM2')]}
              id={`${id}_${index}_1`}
              value={numeric[index].value2}
              error={numeric[index].error2 ? numeric[index].error2 : ''}
              onInputChanged={val => this.onChangeNumeric(index, 'value2', val)}
              required={object[headerIndex('SPIVALR2')] === '*' ? `${translate('general.required_field')}` : false}
              disabled={object[headerIndex('SPIVALD2')] === '*'}
              ref={numeric[index].ref2}
              maxLength={!isNaN(parseInt(object[headerIndex('SPIWEFLD')])) ? parseInt(object[headerIndex('SPIWEFLD')]) : undefined}
              onBlur={() => this.onBlurNumeric(object[headerIndex('SPIVALR2')] === '*', index, 'value2')}
              ignoreMinWidth
            />
          </Column>
        </>
      )
    } else {
      return (
        <Column colMD={12}>
          <Input
            id={`${id}_${index}`}
            title={object[headerIndex('SPIENAME')]}
            value={numeric[index].value}
            error={numeric[index].error ? numeric[index].error : ''}
            onInputChanged={val => this.onChangeNumeric(index, 'value', val)}
            required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
            ref={numeric[index].ref}
            maxLength={!isNaN(parseInt(object[headerIndex('SPIWEFLD')])) ? parseInt(object[headerIndex('SPIWEFLD')]) : undefined}
            onBlur={() => this.onBlurNumeric(object[headerIndex('SPIVALR')] === '*', index, 'value')}
            ignoreMinWidth
          />
        </Column>
      )
    }
  }

  onChangeSchoice = (key, val) => {
    const {schoice} = this.state
    this.setState({
      schoice: {
        ...schoice,
        [key]: {...schoice[key], value: val}
      }
    })
  }

  renderSchoice = (object, index) => {
    const {id, headerIndex, containerID} = this.props
    let items = object[headerIndex('SPIENAM2')].split(';').filter(d => d !== '')
    if (items.length > 0 && items.length <= 4 && !object.displayAsDropdown) {
      return (
        <Switch
          id={`${id}_${index}`}
          title={object[headerIndex('SPIENAME')]}
          items={items}
          onClick={val => this.onChangeSchoice(index, val)}
          activeIndex={this.state.schoice[index].value}
          disabled={object[headerIndex('SPIVALD')] === '*'}
          maxPerRow={items.length === 4 ? 2 : items.length}
        />
      )
    } else {
      if (items.length === 0) {
        // TODO: discuss with kevin what would happend here.
        items = ['']
      }
      return (
        <Column colMD={12}>
          <Dropdown
            id={`${id}_${index}`}
            title={object[headerIndex('SPIENAME')]}
            items={items}
            onChange={val => this.onChangeSchoice(index, val)}
            activeIndex={this.state.schoice[index].value}
            required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
            containerID={containerID}
          />
        </Column>
      )
    }
  }

  onChangeAmount = (key, type, val) => {
    const {amount} = this.state
    this.setState({
      amount: {
        ...amount,
        [key]: {
          ...amount[key],
          [type]: {...amount[key][type], value: val, error: ''}
        }
      }
    })
  }

  renderAmount = (object, index) => {
    const {amount} = this.state
    const {id, headerIndex, containerID} = this.props
    const items = object[headerIndex('SPIENAM2')] !== '' ? object[headerIndex('SPIENAM2')].split(';').filter(d => d !== '') : ['']
    // e.g. "3" -> 999
    const numericMax = parseInt(''.padStart(object[headerIndex('SPIWEFLD')], '9'))
    return (
      <>
        <Column colMD={6}>
          <NumericSpinner
            id={`${id}_${index}`}
            title={object[headerIndex('SPIENAME')]}
            value={amount[index].input.value}
            error={amount[index].input.error ? amount[index].input.error : ''}
            onChange={val => this.onChangeAmount(index, 'input', val)}
            min={0}
            max={numericMax}
            steps={1}
            required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
          />
        </Column>
        <Column colMD={6}>
          <Dropdown
            id={`${id}_${index}`}
            title={' '}
            items={items}
            onChange={val => this.onChangeAmount(index, 'dropdown', val)}
            activeIndex={amount[index].dropdown.value}
            required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
            containerID={containerID}
          />
        </Column>
      </>
    )
  }

  onChangeDate = (key, valueKey, val) => {
    const {date} = this.state
    this.setState({
      date: {
        ...date,
        [key]: {
          ...date[key],
          [valueKey]: val,
          ...(valueKey === 'value' && {error: ''}),
          ...(valueKey === 'value2' && {error2: ''}),
        }
      }
    })
  }

  onBlurDate = (required, dateKey, valueKey) => {
    const {date} = this.state
    if (required) {
      if (date[dateKey][valueKey] === '') {
        this.setState(state => ({
          date: {
            ...state.date,
            [dateKey]: {
              ...state.date[dateKey],
              ...this.validateDate(dateKey, valueKey)
            }
          }
        }))
      }
    }
  }

  /**
     * @description Validates the input.
     */
  validateDate = (dateKey, valueKey) => {
    const {date} = this.state
    if (date[dateKey][valueKey] !== '') {
      return {}
    }
    return {
      ...date[dateKey],
      ...(valueKey === 'value' && {error: translate('general.input_required')}),
      ...(valueKey === 'value2' && {error2: translate('general.input_required')}),
    }
  }

  renderDate = (object, index) => {
    const {date} = this.state
    const {id, lang, datemask, headerIndex} = this.props
    if (object[headerIndex('SPIOP')] === 'RA') {
      return (
        <>
          <Column colMD={6}>
            <Datepicker
              id={`${id}_${index}_0`}
              title={object[headerIndex('SPIENAME')]}
              value={date[index].value}
              error={date[index].error ? date[index].error : ''}
              onChange={val => this.onChangeDate(index, 'value', val)}
              onBlur={() => this.onBlurDate(object[headerIndex('SPIVALR')] === '*', index, 'value')}
              language={lang}
              dateFormat={datemask}
              onInvalidDate={val => this.setState({
                date: {
                  ...this.state.date,
                  [index]: {
                    ...this.state.date[index],
                    value: val,
                    error: translate('general.please_enter_a_valid_date')
                  }
                }
              })}
              required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
              disabled={object[headerIndex('SPIVALD')] === '*'}
              focusRef={date[index].ref}
              parentContainer={this.props.scrollContainer}
            />
          </Column>
          <Column colMD={6}>
            <Datepicker
              id={`${id}_${index}_1`}
              title={' '}
              value={date[index].value2}
              error={date[index].error2 ? date[index].error2 : ''}
              onChange={val => this.onChangeDate(index, 'value2', val)}
              onBlur={() => this.onBlurDate(object[headerIndex('SPIVALR2')] === '*', index, 'value2')}
              language={lang}
              dateFormat={datemask}
              onInvalidDate={val => this.setState({
                date: {
                  ...this.state.date,
                  [index]: {
                    ...this.state.date[index],
                    value2: val,
                    error: translate('general.please_enter_a_valid_date')
                  }
                }
              })}
              required={object[headerIndex('SPIVALR2')] === '*' ? `${translate('general.required_field')}` : false}
              disabled={object[headerIndex('SPIVALD2')] === '*'}
              focusRef={date[index].ref2}
              parentContainer={this.props.scrollContainer}
            />
          </Column>
        </>
      )
    } else {
      return (
        <Column colMD={12}>
          <Datepicker
            id={`${id}_${index}`}
            title={object[headerIndex('SPIENAME')]}
            value={date[index].value}
            error={date[index].error ? date[index].error : ''}
            onChange={val => this.onChangeDate(index, 'value', val)}
            onBlur={() => this.onBlurDate(object[headerIndex('SPIVALR')] === '*', index, 'value')}
            language={lang}
            dateFormat={datemask}
            onInvalidDate={val => this.setState({
              date: {
                ...this.state.date,
                [index]: {
                  ...this.state.date[index],
                  value: val,
                  error: translate('general.please_enter_a_valid_date')
                }
              }
            })}
            required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
            focusRef={date[index].ref}
            parentContainer={this.props.scrollContainer}
          />
        </Column>
      )
    }
  }

  onChangeDateTime = (key, type, val) => {
    const {datetime} = this.state
    this.setState({
      datetime: {
        ...datetime,
        [key]: {
          ...datetime[key],
          [type]: {...datetime[key][type], value: val, error: ''}
        }
      }
    })
  }

  onBlurDateTime = (required, key, type) => {
    const {datetime} = this.state
    if (required) {
      if (datetime[key][type].value === '') {
        this.setState(state => ({
          datetime: {
            ...state.datetime,
            [key]: {
              ...state.datetime[key],
              [type]: {
                ...state.datetime[key][type],
                ...this.validateDateTime(key, type)
              }
            }
          }
        }))
      }
    }
  }

  /**
     * @description Validates the datepicker.
     */
  validateDateTime = (key, type) => {
    const {datetime} = this.state
    if (datetime[key][type].value !== '') {
      return {}
    }
    return {
      ...datetime[key][type],
      error: translate('general.input_required')
    }
  }

  renderDateTime = (object, index) => {
    const {datetime} = this.state
    const {id, lang, datemask, headerIndex} = this.props
    return (
      <>
        <Column colMD={6}>
          <Datepicker
            id={`${id}_${index}_date`}
            title={object[headerIndex('SPIENAME')]}
            value={datetime[index].date.value}
            error={datetime[index].date.error ? datetime[index].date.error : ''}
            onChange={val => this.onChangeDateTime(index, 'date', val)}
            onBlur={() => this.onBlurDateTime(object[headerIndex('SPIVALR')] === '*', index, 'date')}
            language={lang}
            dateFormat={datemask}
            onInvalidDate={val => this.setState({
              datetime: {
                ...this.state.datetime,
                [index]: {
                  ...this.state.datetime[index],
                  date: {value: val, error: translate('general.please_enter_a_valid_date')}
                }
              }
            })}
            required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
            focusRef={datetime[index].date.ref}
            parentContainer={this.props.scrollContainer}
          />
        </Column>
        <Column colMD={6}>
          <Timepicker
            id={`${id}_${index}_time`}
            title={object[headerIndex('SPIENAM2')] === '' ? ' ' : object[headerIndex('SPIENAM2')]}
            value={datetime[index].time.value}
            error={datetime[index].time.error ? datetime[index].time.error : ''}
            onChange={val => this.onChangeDateTime(index, 'time', val)}
            onBlur={() => this.onBlurDateTime(object[headerIndex('SPIVALR2')] === '*', index, 'time')}
            onInvalidTime={val => this.setState({
              datetime: {
                ...this.state.datetime,
                [index]: {
                  ...this.state.datetime[index],
                  time: {value: val, error: translate('general.please_enter_a_valid_time')}
                }
              }
            })}
            language={lang}
            required={object[headerIndex('SPIVALR2')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD2')] === '*'}
            focusRef={datetime[index].time.ref}
            parentContainer={this.props.scrollContainer}
          />
        </Column>
      </>
    )
  }

  onChangeDateDate = (key, type, val) => {
    const {datedate} = this.state
    this.setState({
      datedate: {
        ...datedate,
        [key]: {
          ...datedate[key],
          [type]: {...datedate[key][type], value: val, error: ''}
        }
      }
    })
  }

  onBlurDateDate = (required, key, type) => {
    const {datedate} = this.state
    if (required) {
      if (datedate[key][type].value === '') {
        this.setState(state => ({
          datedate: {
            ...state.datedate,
            [key]: {
              ...state.datedate[key],
              [type]: {
                ...state.datedate[key][type],
                ...this.validateDateDate(key, type)
              }
            }
          }
        }))
      }
    }
  }

  /**
     * @description Validates the datepicker.
     */
  validateDateDate = (key, type) => {
    const {datedate} = this.state
    if (datedate[key][type].value !== '') {
      return {}
    }
    return {
      ...datedate[key][type],
      error: translate('general.input_required')
    }
  }

  renderDateDate = (object, index) => {
    const {datedate} = this.state
    const {id, lang, datemask, headerIndex} = this.props
    return (
      <>
        <Column colMD={6}>
          <Datepicker
            id={`${id}_${index}_from`}
            title={object[headerIndex('SPIENAME')]}
            value={datedate[index].from.value}
            error={datedate[index].from.error ? datedate[index].from.error : ''}
            onChange={val => this.onChangeDateDate(index, 'from', val)}
            onBlur={() => this.onBlurDateDate(object[headerIndex('SPIVALR')] === '*', index, 'from')}
            language={lang}
            dateFormat={datemask}
            onInvalidDate={val => this.setState({
              datedate: {
                ...this.state.datedate,
                [index]: {
                  ...this.state.datedate[index],
                  from: {value: val, error: translate('general.please_enter_a_valid_date')}
                }
              }
            })}
            required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
            focusRef={datedate[index].from.ref}
            parentContainer={this.props.scrollContainer}
          />
        </Column>
        <Column colMD={6}>
          <Datepicker
            id={`${id}_${index}_to`}
            title={object[headerIndex('SPIENAM2')] === '' ? ' ' : object[headerIndex('SPIENAM2')]}
            value={datedate[index].to.value}
            error={datedate[index].to.error ? datedate[index].to.error : ''}
            onChange={val => this.onChangeDateDate(index, 'to', val)}
            onBlur={() => this.onBlurDateDate(object[headerIndex('SPIVALR2')] === '*', index, 'to')}
            language={lang}
            dateFormat={datemask}
            onInvalidDate={val => this.setState({
              datedate: {
                ...this.state.datedate,
                [index]: {
                  ...this.state.datedate[index],
                  to: {value: val, error: translate('general.please_enter_a_valid_date')}
                }
              }
            })}
            required={object[headerIndex('SPIVALR2')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD2')] === '*'}
            focusRef={datedate[index].to.ref}
            parentContainer={this.props.scrollContainer}
          />
        </Column>
      </>
    )
  }

  onChangeMchoice = (type, key, val) => {
    const {mchoice} = this.state
    this.setState({
      mchoice: {
        ...mchoice,
        [key]: {
          ...mchoice[key],
          [type]: val
        }
      }
    })
  }

  renderMchoice = (object, index) => {
    const {id, headerIndex} = this.props
    const items = object[headerIndex('SPIENAM2')].split(';').filter(d => d !== '')
    const dbValues = object[headerIndex('SPIVALU3')].split(';').filter(d => d !== '')
    return (
      <div className={'bux_mchoice_container'}>
        {
          items.map((item, i) => {
            return <Checkbox
              id={`${id}_${index}_${i}`}
              key={`${id}_${index}_${i}`}
              title={i === 0 ? object[headerIndex('SPIENAME')] : undefined}
              label={item}
              value={this.state.mchoice[index][dbValues[i]]}
              onCheck={val => this.onChangeMchoice(dbValues[i], index, val)}
            />
          })
        }
      </div>
    )
  }

  onChangeCchoice = (key, val) => {
    const {cchoice} = this.state
    this.setState({
      cchoice: {
        ...cchoice,
        [key]: {...cchoice[key], value: val, error: ''}
      }
    })
  }

  onBlurCchoice = (required, key) => {
    const {cchoice} = this.state
    if (required) {
      if (cchoice[key].value === '') {
        this.setState(state => ({
          cchoice: {
            ...state.cchoice,
            [key]: {
              ...state.cchoice[key],
              ...this.validateCchoice(key)
            }
          }
        }))
      }
    }
  }

  /**
     * @description Validates the input.
     */
  validateCchoice = key => {
    const {cchoice} = this.state
    if (cchoice[key].value !== '') {
      return {}
    }
    return {
      ...cchoice[key],
      error: translate('general.input_required')
    }
  }

  getModalData = (object, type, index, valueKey) => {
    const {executeBqlQuery, headerIndex} = this.props
    // Build field information string.
    let fields = `${object[headerIndex('SPIVALU3')].replace(/[;]/g, ',')}`
    if (fields[fields.length - 1] === ',') {
      fields = fields.substring(0, fields.length - 1)
    }
    let bqlQuery = `${object[headerIndex('SPIENAM3')]} FIELDS(${fields})`
    if (['hchoice', 'cchoice'].includes(type)) {
      if (bqlQuery.includes('%s')) {
        bqlQuery = bqlQuery.replace('%s', this.state[type][index].value === '' ? '*' : this.state[type][index].value)
      }
    } else if (type === 'cchoice2') {
      valueKey.forEach(key => {
        if (bqlQuery.includes('%s')) {
          bqlQuery = bqlQuery.replace('%s', this.state[type][index][key].value === '' ? '*' : this.state[type][index][key].value)
        }
      })
    }
    // These information are used to know which data should be updated when select one entry in the modal selector.
    const currentModalSelectorInformation = {
      header: object[headerIndex('SPIENAM2')].split(';').filter(d => d !== ''),
      type, // e.g cchoice2
      index, // e.g 8
      valueKey, // e.g ['form', 'ext'] or undefined
      indexName: object[headerIndex('IXINAME')],
      required: {
        'hchoice': [object[headerIndex('SPIVALR')] === '*'],
        'cchoice': [object[headerIndex('SPIVALR')] === '*'],
        'cchoice2': [object[headerIndex('SPIVALR')] === '*', object[headerIndex('SPIVALR')] === '*']
      }[type]
    }
    let showSelectorDialog = {}
    if (type === 'hchoice') {
      showSelectorDialog = {showSelectorDialogHierarchy: true}
    } else {
      showSelectorDialog = {showSelectorDialog: true}
    }
    executeBqlQuery(bqlQuery, success => {
      if (success) {
        this.setState({currentModalSelectorInformation, ...showSelectorDialog})
      }
    })
  }

  renderCchoice = (object, index) => {
    const {cchoice} = this.state
    const {id, headerIndex} = this.props
    return (
      <Column colMD={12}>
        <Input
          id={`${id}_${index}`}
          title={object[headerIndex('SPIENAME')]}
          value={cchoice[index].value}
          error={cchoice[index].error ? cchoice[index].error : ''}
          onInputChanged={val => this.onChangeCchoice(index, val)}
          onBlur={() => this.onBlurCchoice(object[headerIndex('SPIVALR')] === '*', index)}
          maxLength={!isNaN(parseInt(object[headerIndex('SPIWEFLD')])) ? parseInt(object[headerIndex('SPIWEFLD')]) : undefined}
          addon={{
            iconName: 'list',
            onClick: () => this.getModalData(object, 'cchoice', index),
          }}
          required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
          disabled={object[headerIndex('SPIVALD')] === '*'}
          ref={cchoice[index].ref}
          letterCase={CustomDialogConfigRenderer.uppercaseElements.includes(object[headerIndex('IXINAME')]) ? LetterCase.uppercase : undefined}
          ignoreMinWidth
        />
      </Column>
    )
  }

  onChangeCchoice2 = (type, key, val) => {
    const {cchoice2} = this.state
    this.setState({
      cchoice2: {
        ...cchoice2,
        [key]: {
          ...cchoice2[key],
          [type]: {...cchoice2[key][type], value: val, error: ''}
        }
      }
    })
  }

  /**
     * @description Validates the datepicker.
     */
  validateCchoice2 = (key, type) => {
    const {cchoice2} = this.state
    if (cchoice2[key][type].value !== '') {
      return {}
    }
    return {
      ...cchoice2[key][type],
      error: translate('general.input_required')
    }
  }

  onBlurCchoice2 = (required, key, type) => {
    const {cchoice2} = this.state
    if (required) {
      if (cchoice2[key][type].value === '') {
        this.setState(state => ({
          cchoice2: {
            ...state.cchoice2,
            [key]: {
              ...state.cchoice2[key],
              [type]: {
                ...state.cchoice2[key][type],
                ...this.validateCchoice2(key, type)
              }
            }
          }
        }))
      }
    }
  }

  renderCchoice2 = (object, index) => {
    const {cchoice2} = this.state
    const {id, headerIndex} = this.props
    return (
      <>
        <Column colMD={6}>
          <Input
            id={`${id}_${index}_form`}
            title={object[headerIndex('SPIENAME')]}
            value={cchoice2[index].form.value}
            error={cchoice2[index].form.error ? cchoice2[index].form.error : ''}
            onInputChanged={val => this.onChangeCchoice2('form', index, val)}
            onBlur={() => this.onBlurCchoice2(object[headerIndex('SPIVALR')] === '*', index, 'form')}
            maxLength={!isNaN(parseInt(object[headerIndex('SPIWEFLD')])) ? parseInt(object[headerIndex('SPIWEFLD')]) : undefined}
            addon={{
              iconName: 'list',
              onClick: () => this.getModalData(object, 'cchoice2', index, ['form', 'ext']),
            }}
            required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
            ref={cchoice2[index].form.ref}
            lettercase={LetterCase.uppercase}
            ignoreMinWidth
          />
        </Column>
        <Column colMD={6}>
          <Input
            id={`${id}_${index}_extension`}
            title={''}
            value={cchoice2[index].ext.value}
            error={cchoice2[index].ext.error ? cchoice2[index].ext.error : ''}
            onInputChanged={val => this.onChangeCchoice2('ext', index, val)}
            onBlur={() => this.onBlurCchoice2(object[headerIndex('SPIVALR')] === '*', index, 'ext')}
            maxLength={!isNaN(parseInt(object[headerIndex('SPIWEFLD')])) ? parseInt(object[headerIndex('SPIWEFLD')]) : undefined}
            addon={{
              iconName: 'list',
              onClick: () => this.getModalData(object, 'cchoice2', index, ['form', 'ext']),
            }}
            // ! Commented out because we just have one label for both elements.
            // required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
            ref={cchoice2[index].ext.ref}
            lettercase={LetterCase.uppercase}
            ignoreMinWidth
          />
        </Column>
      </>
    )
  }

  onChangeLchoice = (key, val) => {
    const {lchoice} = this.state
    this.setState({
      lchoice: {
        ...lchoice,
        [key]: {...lchoice[key], value: val}
      }
    })
  }

  renderLchoice = (object, index) => {
    const {id, headerIndex, containerID} = this.props
    let items = object[headerIndex('SPIENAM2')].split(';').filter(d => d !== '')
    if (items.length > 0 && items.length < 5) {
      return (
        <Switch
          id={`${id}_${index}`}
          title={object[headerIndex('SPIENAME')]}
          items={items}
          onClick={val => this.onChangeLchoice(index, val)}
          activeIndex={this.state.lchoice[index].value}
          disabled={object[headerIndex('SPIVALD')] === '*'}
          maxPerRow={items.length === 4 ? 2 : items.length}
        />
      )
    } else {
      if (items.length === 0) {
        // TODO: discuss with kevin what would happend here.
        items = ['']
      }
      return (
        <Column colMD={12}>
          <Dropdown
            id={`${id}_${index}`}
            title={object[headerIndex('SPIENAME')]}
            items={items}
            onChange={val => this.onChangeLchoice(index, val)}
            activeIndex={this.state.lchoice[index].value}
            required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
            disabled={object[headerIndex('SPIVALD')] === '*'}
            containerID={containerID}
          />
        </Column>
      )
    }
  }

  onChangeHchoice = (key, val) => {
    const {hchoice} = this.state
    this.setState({
      hchoice: {
        ...hchoice,
        [key]: {...hchoice[key], value: val, error: ''}
      }
    })
  }

  onBlurHchoice = (required, key) => {
    const {cchoice} = this.state
    if (required) {
      if (cchoice[key].value === '') {
        this.setState(state => ({
          cchoice: {
            ...state.cchoice,
            [key]: {
              ...state.cchoice[key],
              ...this.validateCchoice(key)
            }
          }
        }))
      }
    }
  }

  renderHchoice = (object, index) => {
    const {hchoice} = this.state
    const {id, headerIndex} = this.props
    return (
      <Column colMD={12}>
        <Input
          id={`${id}_${index}`}
          title={object[headerIndex('SPIENAME')]}
          value={hchoice[index].value}
          error={hchoice[index].error ? hchoice[index].error : ''}
          onInputChanged={val => this.onChangeHchoice(index, val)}
          onBlur={() => this.onBlurHchoice(object[headerIndex('SPIVALR')] === '*', index)}
          maxLength={!isNaN(parseInt(object[headerIndex('SPIWEFLD')])) ? parseInt(object[headerIndex('SPIWEFLD')]) : undefined}
          addon={{
            iconName: 'list',
            onClick: () => this.getModalData(object, 'hchoice', index),
          }}
          required={object[headerIndex('SPIVALR')] === '*' ? `${translate('general.required_field')}` : false}
          disabled={object[headerIndex('SPIVALD')] === '*'}
          ref={hchoice[index].ref}
          ignoreMinWidth
        />
      </Column>
    )
  }

  getDtframeValues = key => {
    const {dtframe} = this.state
    const dtframeToUse = dtframe[key]
    return {
      tabIndex: dtframeToUse.tabIndex,
      lastTimeModeIndex: dtframeToUse.lastTimeModeIndex,
      customLast: dtframeToUse.customLast,
      customUnitIndex: dtframeToUse.customUnitIndex,
      fromDate: {
        value: dtframeToUse.fromDate.value,
        error: dtframeToUse.fromDate.error
      },
      fromTime: {
        value: dtframeToUse.fromTime.value,
        error: dtframeToUse.fromTime.error
      },
      toDate: {
        value: dtframeToUse.toDate.value,
        error: dtframeToUse.toDate.error
      },
      toTime: {
        value: dtframeToUse.toTime.value,
        error: dtframeToUse.toTime.error
      }
    }
  }

  /**
     * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
     *              Needed for update function inside the TimeCard.
     * @returns {Object}
     */
  getDtframeStateKeys = () => {
    return {
      tabIndex: 'tabIndex',
      lastTimeModeIndex: 'lastTimeModeIndex',
      customLast: 'customLast',
      customUnitIndex: 'customUnitIndex',
      fromDate: 'fromDate',
      fromTime: 'fromTime',
      toDate: 'toDate',
      toTime: 'toTime'
    }
  }

  onDtframeChange = (key, type, val, err) => {
    this.setState(state => ({
      dtframe: {
        ...state.dtframe,
        [key]: {
          ...state.dtframe[key],
          [type]: typeof state.dtframe[key][type] === 'object'
            ? {value: val, error: err || ''}
            : val
        }
      }
    }))
  }

  renderDtframe = (object, index) => {
    const {id, lang, datemask, headerIndex} = this.props

    return (
      <TimeCard
        id={id}
        title={object[headerIndex('SPIENAME')]}
        lang={lang}
        datemask={datemask}
        values={this.getDtframeValues(index)}
        stateKeys={this.getDtframeStateKeys()}
        onValuesChange={(type, val, err) => this.onDtframeChange(index, type, val, err)}
        parentContainer={this.props.scrollContainer}
        translate={key => translate(key)}
      />
    )
  }

  onChangeFileOpen = (key, val) => {
    const {fileopen} = this.state
    this.setState({
      fileopen: {
        ...fileopen,
        [key]: {...fileopen[key], value: val, error: ''}
      }
    })
  }

  renderFileOpen = (object, index) => {
    const {fileopen} = this.state
    const {id, headerIndex} = this.props
    return (
      <Column colMD={12}>
        <Filepicker
          id={`${id}_${index}`}
          title={object[headerIndex('SPIENAME')]}
          files={fileopen[index].value}
          error={fileopen[index].error}
          onChange={files => this.onChangeFileOpen(index, files)}
          noSelectedString={translate('general.no_selected_files')}
          selectedString={translate('general.selected_files')}
          multiple={false}
          required={`${translate('general.required_field')}`}
        />
      </Column>
    )
  }

  renderCard = (title, objects) => {
    return (
      <Card title={title} id={`card_${title}`}>
        {objects.map((d, i) => {
          if (d.type === CustomDialogBuilder.FLFR) {
            return this.renderFlfr(d.left, d.right)
          }
          return (
            <Row key={i}>
              {this.renderElement(d.object, d.index)}
            </Row>
          )
        })}
      </Card>
    )
  }

  renderFlfr = (left, right) => {
    const {id} = this.props
    const maxLength = Math.max(left.length, right.length)

    return Array.from(Array(maxLength)).map((_, index) => (
      <Row id={`${id}-LeftRightContainer-${index}`} key={`customDialog-FLFR-${index}`}>
        <Column id={`${id}-LeftContainer-${index}`}
          colMD={6}>{left[index] && this.renderElement(left[index].object, left[index].index)}</Column>
        <Column id={`${id}-RightContainer-${index}`}
          colMD={6}>{right[index] && this.renderElement(right[index].object, right[index].index)}</Column>
      </Row>
    ))
  }

  renderElement = (object, index) => {
    const {headerIndex} = this.props
    if (!['EQ', 'DF', 'LB', 'RA'].includes(object[headerIndex('SPIOP')])) return

    if (object[headerIndex('SPIDTYPE')] === 'STRING' && ['EQ', 'RA'].includes(object[headerIndex('SPIOP')])) {
      return this.renderStringInput(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'STRING' && object[headerIndex('SPIOP')] === 'LB') {
      return this.renderStringLabel(object, index)
    } else if (['NUMERIC', 'RNUM'].includes(object[headerIndex('SPIDTYPE')])) {
      return this.renderNumeric(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'SCHOICE') {
      return this.renderSchoice(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'AMOUNT') {
      return this.renderAmount(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'DATE') {
      return this.renderDate(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'DATETIME') {
      return this.renderDateTime(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'DATEDATE') {
      return this.renderDateDate(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'MCHOICE') {
      return this.renderMchoice(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'CCHOICE') {
      return this.renderCchoice(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'CCHOICE2') {
      return this.renderCchoice2(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'LCHOICE') {
      return this.renderLchoice(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'HCHOICE') {
      return this.renderHchoice(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'DTFRAME') {
      return this.renderDtframe(object, index)
    } else if (object[headerIndex('SPIDTYPE')] === 'FILEOPEN') {
      return this.renderFileOpen(object, index)
    }
  }

  renderElements = () => {
    const {customDialog, headerIndex} = this.props
    const buffer = []
    if (customDialog) {
      let el
      const cardInformation = CustomDialogBuilder.build(customDialog, headerIndex)
      cardInformation.forEach((d, i) => {
        if (d.type === CustomDialogBuilder.SINGLE) {
          // Render DTFRAME without a row so the stylings work corretly.
          if (d.object[headerIndex('SPIDTYPE')] === 'DTFRAME') {
            el = this.renderDtframe(d.object, d.index)
          } else {
            el = <Row>{this.renderElement(d.object, d.index)}</Row>
          }
        } else if (d.type === CustomDialogBuilder.CARD) {
          el = this.renderCard(d.title, d.objects)
        } else if (d.type === CustomDialogBuilder.FLFR) {
          el = this.renderFlfr(d.left, d.right)
        }
        buffer.push(<div key={`${i}`}>{el}</div>)
      })
    }
    return buffer
  }

  updateModalSelectorSelection = updateData => {
    const {currentModalSelectorInformation} = this.state
    this.setState({
      showSelectorDialog: false,
      showSelectorDialogHierarchy: false,
      currentModalSelectorInformation: {},
      [currentModalSelectorInformation.type]: {
        ...this.state[currentModalSelectorInformation.type],
        [currentModalSelectorInformation.index]: {...updateData}
      }
    })
  }

  onSelectModalSelector = selectedRows => {
    const {currentModalSelectorInformation} = this.state
    const {selector} = this.props
    if (selectedRows.length > 0) {
      // vlauesKey is used when update more then one data with modal selector.
      if (currentModalSelectorInformation.valueKey) {
        const valuesKey = Array.isArray(currentModalSelectorInformation.valueKey) ? currentModalSelectorInformation.valueKey : [currentModalSelectorInformation.valueKey]
        const updateObj = {}
        valuesKey.forEach((key, index) => {
          if (index <= selector.bqlQueryResult.header.length) {
            updateObj[key] = currentModalSelectorInformation.required[index]
              ? {
                ...this.state[currentModalSelectorInformation.type][currentModalSelectorInformation.index][key],
                value: selector.bqlQueryResult.data[selectedRows][index],
                error: ''
              }
              : {
                ...this.state[currentModalSelectorInformation.type][currentModalSelectorInformation.index][key],
                value: selector.bqlQueryResult.data[selectedRows][index]
              }
          } else {
            updateObj[key] = ''
          }
        })
        this.updateModalSelectorSelection(updateObj)
      } else {
        const updateData = currentModalSelectorInformation.required[0]
          ? {
            ...this.state[currentModalSelectorInformation.type][currentModalSelectorInformation.index],
            value: selector.bqlQueryResult.data[selectedRows][0],
            error: ''
          }
          : {
            ...this.state[currentModalSelectorInformation.type][currentModalSelectorInformation.index],
            value: selector.bqlQueryResult.data[selectedRows][0]
          }
        this.updateModalSelectorSelection(updateData)
      }
    }
  }

  onSelectModalSelectorHierarchy = selectedRows => {
    const {currentModalSelectorInformation} = this.state
    if (selectedRows.length > 0) {
      const updateData = currentModalSelectorInformation.required[0]
        ? {
          ...this.state[currentModalSelectorInformation.type][currentModalSelectorInformation.index],
          value: selectedRows[0].data[0],
          error: ''
        }
        : {
          ...this.state[currentModalSelectorInformation.type][currentModalSelectorInformation.index],
          value: selectedRows[0].data[0]
        }
      this.updateModalSelectorSelection(updateData)
    }
  }

  onSelectDocumentNodeInHierarchy = selectedRow => {
    this.getNeededInformation(() => {
      // TODO: fix deep copy of object to make it possible to work with copy of it instead of reference
      const string = {...this.state.string}
      const cchoice = {...this.state.cchoice}
      const cchoice2 = {...this.state.cchoice2}
      const form = selectedRow[0].data[3]
      const extension = selectedRow[0].data[4]
      const placeholderMatrix = [
        {placeholder: '&FORM', value: form},
        {placeholder: '&EXTENSION', value: extension},
      ]
      for (const elem of [...Object.values(string), ...Object.values(cchoice), ...Object.values(cchoice2)]) {
        for (let i = 0; i < placeholderMatrix.length; i++) {
          const isCchoice2 = elem.form && elem.ext
          if (isCchoice2) {
            if (elem.form.value.includes(placeholderMatrix[i].placeholder)) {
              elem.form.value = elem.form.value.replace(placeholderMatrix[i].placeholder, placeholderMatrix[i].value)
            } else if (elem.ext.value.includes(placeholderMatrix[i].placeholder)) {
              elem.ext.value = elem.ext.value.replace(placeholderMatrix[i].placeholder, placeholderMatrix[i].value)
            }
          } else if (elem.value.includes(placeholderMatrix[i].placeholder)) {
            elem.value = elem.value.replace(placeholderMatrix[i].placeholder, placeholderMatrix[i].value)
          }
        }
      }
      // I know that the elem variable is not passed inside the set state function. Its not possible at the moment to deep copy the string object
      // (because the object contains refs etc.), so when the new value is given, the object inside the state updated automatically because of its reference.
      this.setState({
        documentNodeSelection: {...this.state.documentNodeSelection, selected: true, error: ''},
        showDocumentNodeSelectorHierarchy: false
      }, () => this.initialalizeStateGetter())
    })
  }

  loadDocumentNodes = () => {
    this.props.getDocumentNodesDefinition(['DNDNAME', 'DNDPNAME', 'DNDENAME', 'DNDTYPE'], this.state.documentNodeSelection.value, () => this.setState({showDocumentNodeSelectorHierarchy: true}))
  }

  hasDocumentNodeSelection = () => {
    const {customDialogInfo} = this.props
    const type = customDialogInfo[2]
    if (type === 'IMPORT') {
      return customDialogInfo[6] === 'YES'
    } else {
      return customDialogInfo[5] === 'YES'
    }
  }

  render = () => {
    const {
      showSelectorDialog,
      showSelectorDialogHierarchy,
      showDocumentNodeSelectorHierarchy,
      currentModalSelectorInformation,
      documentNodeSelection
    } = this.state
    const {id, selector, customDialog} = this.props
    let toRender
    try {
      toRender = <div id={`${id}_custom_dialog_container`} className={'bux_custom_dialog_container'}>
        {showSelectorDialog &&
                    <SelectorDialog
                      id={`${id}_selector_dialog`}
                      onClose={() => this.setState({currentModalSelectorInformation: {}, showSelectorDialog: false})}
                      title={translate('general.select_list')}
                      header={currentModalSelectorInformation.header}
                      items={selector.bqlQueryResult.data}
                      onSelect={selectedRows => this.onSelectModalSelector(selectedRows)}
                    />
        }
        {showSelectorDialogHierarchy &&
                    <SelectorDialogHierarchy
                      id={`${id}_selector_hierarchy_dialog`}
                      onClose={() => this.setState({
                        currentModalSelectorInformation: {},
                        showSelectorDialogHierarchy: false
                      })}
                      title={translate('general.select_list')}
                      header={currentModalSelectorInformation.header}
                      items={selector.bqlQueryResult.data}
                      onSelect={selectedRows => this.onSelectModalSelectorHierarchy(selectedRows)}
                      type={currentModalSelectorInformation.indexName}
                    />
        }
        {showDocumentNodeSelectorHierarchy &&
                    <SelectorDialogHierarchy
                      id={`${id}_document_node_selection_dialog`}
                      onClose={() => this.setState({showDocumentNodeSelectorHierarchy: false})}
                      title={translate('general.select_list')}
                      header={[translate('definition.node_id'), translate('general.parent_node_id'), translate('general.identifier'), translate('general.form'), translate('general.extension'), translate('general.type')]}
                      items={selector.documentnodes.data}
                      onSelect={selectedRows => this.onSelectDocumentNodeInHierarchy(selectedRows)}
                      type={'NODE'}
                      withAssignment
                    />
        }
        {
          this.hasDocumentNodeSelection() &&
                    <Card title={translate('definition.node_selection')}>
                      <Row>
                        <Column colMD={12}>
                          <Input
                            id={`${id}_document_node_selection`}
                            title={translate('definition.document_node')}
                            value={documentNodeSelection.value}
                            onInputChanged={() => {
                            }}
                            maxLength={25}
                            addon={{
                              iconName: 'list',
                              onClick: () => this.loadDocumentNodes(),
                            }}
                            inputDisabled
                            ignoreMinWidth
                          />
                        </Column>
                      </Row>
                    </Card>
        }
        {customDialog.length > 0 && (this.hasDocumentNodeSelection() ? documentNodeSelection.selected : true) && this.renderElements()}
      </div>
    } catch (err) {
      toRender = <div>{`Fehler beim Rendern des dynamischen Suchdialoges: ${err}.`}</div>
    }
    return toRender
  }
}

const mapStateToProps = state => {
  return {
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences.DATEMASK,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    executeBqlQuery: (bqlQuery, callback) => ModalSelectorActions.executeBqlQuery(bqlQuery, callback)(dispatch),
    getCachedCustomDialogConfig: () => CustomDialogActions.getCachedCustomDialogConfig(),
    getDocumentNodesDefinition: (fields, nodeName, callback) => ModalSelectorActions.getDocumentNodesDefinition(fields, nodeName, callback, true)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialog)