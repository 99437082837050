import * as Lang from 'language/Language'

export const TYPE_SWITCHABLE = 0
export const TYPE_NUMERIC = 1
export const TYPE_FREE_TEXT = 2

const SWITCHABLE_YES = 'YES'
const SWITCHABLE_NO = 'NO'
const SWITCHABLE_ALL = 'ALL'
const SWITCHABLE_ONLINE = 'ONLINE'
const SWITCHABLE_FORM = 'FORM'
const SWITCHABLE_JOBNAME = 'JOBN'

const TCP_TRACE_RECV = 'TCP_TRACE_RECV'
const TCP_TRACE_SEND = 'TCP_TRACE_SEND'
const SECURITY_TRACE = 'SECURITY_TRACE'
const SECURITY_LEVEL = 'SECURITY_LEVEL'
const SECURITY_LOG = 'SECURITY_LOG'
const BQL_TRACE = 'BQL_TRACE'
const BQL_TRACE_ERROR = 'BQL_TRACE_ERROR'
const BUX_COMMAND_TRACE = 'BUX_COMMAND_TRACE'
const INDEX_PRIO_DEFAULT = 'INDEX_PRIO_DEFAULT'
const INDEX_PRIO_RDRMVS = 'INDEX_PRIO_RDRMVS'
const INDEX_PRIO_RDRBUX = 'INDEX_PRIO_RDRBUX'
const INDEX_PRIO_RDREDF = 'INDEX_PRIO_RDREDF'
const INDEX_PRIO_IMPORT = 'INDEX_PRIO_IMPORT'
const INDEX_PRIO_ONLINE = 'INDEX_PRIO_ONLINE'
const RELOAD_AUTO_THRESHOLD = 'RELOAD_AUTO_THRESHOLD'
const RELOAD_USE_THRESHOLD = 'RELOAD_USE_THRESHOLD'
const BUX_DOC_SELECTION_BY_LRI_GLR = 'BUX_DOC_SELECTION_BY_LRI_GLR'
const RDR_GENERATE_NEW_LDR = 'RDR_GENERATE_NEW_LDR'
const RDR_LDR_REQUIRED = 'RDR_LDR_REQUIRED'
const TCP_TRACE_INTERN = 'TCP_TRACE_INTERN'
const JOB_GROUP_TRACE_EXEC = 'JOB_GROUP_TRACE_EXEC'
const JOB_GROUP_TRACE_INIT = 'JOB_GROUP_TRACE_INIT'

const SYSTEM_DEFAULTS = {
  // TYPE_SWITCHABLE
  [BQL_TRACE]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.bql_trace',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  [SECURITY_LOG]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.security_log',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  [TCP_TRACE_RECV]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.tcp_trace_recv',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  [TCP_TRACE_SEND]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.tcp_trace_send',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  [SECURITY_TRACE]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.security_trace',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  [BQL_TRACE_ERROR]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.bql_trace_error',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  [RDR_LDR_REQUIRED]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.rdr_ldr_required',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }, { key: SWITCHABLE_FORM, translationKey: 'general.form' }, { key: SWITCHABLE_JOBNAME, translationKey: 'general.jobname' }]
  },
  [BUX_COMMAND_TRACE]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.bux_command_trace',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  [RELOAD_USE_THRESHOLD]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.reload_use_threshold',
    default: { key: SWITCHABLE_ALL, translationKey: 'general.all' },
    values: [{ key: SWITCHABLE_ALL, translationKey: 'general.all' }, { key: SWITCHABLE_ONLINE, translationKey: 'search.online' }]
  },
  [RDR_GENERATE_NEW_LDR]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.rdr_generate_new_ldr',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  [TCP_TRACE_INTERN]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.tcp_trace_intern',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  [JOB_GROUP_TRACE_EXEC]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.job_group_trace_exec',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  [JOB_GROUP_TRACE_INIT]: {
    type: TYPE_SWITCHABLE,
    translationKey: 'systemdefaults.job_group_trace_init',
    default: { key: SWITCHABLE_NO, translationKey: 'general.no' },
    values: [{ key: SWITCHABLE_YES, translationKey: 'general.yes' }, { key: SWITCHABLE_NO, translationKey: 'general.no' }]
  },
  // TYPE_NUMERIC
  [SECURITY_LEVEL]: {
    type: TYPE_NUMERIC,
    translationKey: 'systemdefaults.security_level',
    min: 1,
    max: 2
  },
  [INDEX_PRIO_DEFAULT]: {
    type: TYPE_NUMERIC,
    translationKey: 'systemdefaults.index_prio_default',
    min: 1,
    max: 9
  },
  [INDEX_PRIO_RDRMVS]: {
    type: TYPE_NUMERIC,
    translationKey: 'systemdefaults.index_prio_rdrmvs',
    min: 1,
    max: 9
  },
  [INDEX_PRIO_RDRBUX]: {
    type: TYPE_NUMERIC,
    translationKey: 'systemdefaults.index_prio_rdrbux',
    min: 1,
    max: 9
  },
  [INDEX_PRIO_RDREDF]: {
    type: TYPE_NUMERIC,
    translationKey: 'systemdefaults.index_prio_rdredf',
    min: 1,
    max: 9
  },
  [INDEX_PRIO_IMPORT]: {
    type: TYPE_NUMERIC,
    translationKey: 'systemdefaults.index_prio_import',
    min: 1,
    max: 9
  },
  [INDEX_PRIO_ONLINE]: {
    type: TYPE_NUMERIC,
    translationKey: 'systemdefaults.index_prio_online',
    min: 1,
    max: 9
  },
  [RELOAD_AUTO_THRESHOLD]: {
    type: TYPE_NUMERIC,
    translationKey: 'systemdefaults.reload_auto_threshold',
    min: 0,
    max: 99
  },
  [BUX_DOC_SELECTION_BY_LRI_GLR]: {
    type: TYPE_NUMERIC,
    translationKey: 'systemdefaults.bux_doc_selection_by_lri_glr',
    min: 0,
    max: 99
  }
}

/**
 * @description Gets the type of the modify dialog.
 * @param {String} keyword The system default keyword.
 * @returns {Number} The type of the modify dialog. Uses the constants of this file.
 */
export function getModifyDialogType(keyword) {
  const entry = SYSTEM_DEFAULTS[keyword]

  if (entry !== undefined) {
    return entry.type
  } else {
    // fallback is a dialog with a free text input
    return TYPE_FREE_TEXT
  }
}

/**
 * @description Gets the min value for a numeric system default.
 * @param {String} keyword The system default keyword.
 * @returns {Number} The min value for a numeric system default.
 */
export function getNumericMinValue(keyword) {
  return SYSTEM_DEFAULTS[keyword].min
}

/**
 * @description Gets the min value for a numeric system default.
 * @param {String} keyword The system default keyword.
 * @returns {Number} The min value for a numeric system default.
 */
export function getNumericMaxValue(keyword) {
  return SYSTEM_DEFAULTS[keyword].max
}

/**
 * @description Gets the switchable values for a switchable system default.
 * @param {String} keyword The system default keyword.
 * @returns {Array} The switchable values for a switchable system default.
 */
export function getSwitchableValues(keyword) {
  return SYSTEM_DEFAULTS[keyword].values
}

/**
 * @description Gets the default value for a switchable system default.
 * @param {String} keyword The system default keyword.
 * @returns {Array} The switchable values for a switchable system default.
 */
export function getSwitchableDefault(keyword) {
  return SYSTEM_DEFAULTS[keyword].default
}

/**
 * Gets the translationKey for a system default keyword.
 * @param {String} keyword The system default keyword.
 */
export function getParameterTranslation(keyword) {
  if (SYSTEM_DEFAULTS[keyword] === undefined) {
    // if system default is not defined, use the keyword as translation
    return keyword
  } else {
    return Lang.translate(SYSTEM_DEFAULTS[keyword].translationKey)
  }
}