import PropTypes from 'prop-types'
import React, { Component } from 'react'
import hash from 'object-hash'

import { translate, translateNoError } from 'language/Language'

// components
import {
  Button,
  Column, DataTable, Dropdown, Input,
  Modal as ModalComponent,
  Row,
  Tab, TableButton, Tabs
} from 'BetaUX2Web-Components/src/'
import ModifyResultTableObjectDialog from 'components/dialogs/copy_result_table_dialog/ModifyResultTableObjectDialog'
import { PreviewTab } from 'components/tabs/preview/PreviewTab'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as ResultTableDefinitionActions from 'redux/actions/ResultTableDefinitionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'

import { getAvailableJobTypes } from 'utils/CustomDialogSystemUtils'
import * as SortUtils from 'utils/SortUtils'
import * as UserUtils from 'utils/UserUtils'
import * as Utils from 'utils/Utils'

import './CreateResultTableDialog.scss'
import _ from 'lodash'
import { hasNoValues } from 'utils/ObjectUtils'

class CreateResultTableDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    prefilledData: PropTypes.object,
    onClose: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props)
    this.resultTableIdInput = React.createRef()
    this.descriptionInput = React.createRef()
    this.defaultState = {
      generalTab: {
        resultTableID: {
          value: '',
          error: ''
        },
        owner: '',
        description: {
          value: '',
          error: ''
        },
        jobType: 0
      },
      formTab: {
        header: this.fillHeaderInformation(),
        filteredObjects: [],
        nonEditableObjects: [],
        data: [],
        checkedRows: [],
        showModifyDialog: false,
        sortedCol: undefined,
        currentObjectIndex: -1
      },
      previewTab: {
        lastUsedPreviewQuery: ''
      }
    }
    this.state = _.cloneDeep(this.defaultState)
  }

  resetState = () => {
    this.setState(_.cloneDeep(this.defaultState), () => {
      this.initFormTab()
    })
  }

  componentDidMount = () => {
    if (this.props.prefilledData) {
      this.setState({
        ...this.state,
        generalTab: {
          ...this.state.generalTab,
          resultTableID: {
            value: this.props.prefilledData.SLTINAME,
            error: ''
          },
          owner: this.props.prefilledData.OWNER,
          description: {
            value: this.props.prefilledData.SLTENAME,
            error: ''
          },
          jobType: 0
        },
      })
    }

    this.initFormTab()
  }

  componentWillUnmount = () => {
    this.props.removePreviewResults()
  }

  componentDidUpdate = (prevProps) => {
    const shouldInitializeFormTab = Object.keys(this.props.resultTableDefaultObjects).length !== 0 && Object.keys(this.props.resultTableDefaultObjects).length > Object.keys(prevProps.resultTableDefaultObjects).length

    if (shouldInitializeFormTab) {
      this.initFormTab()
    }
  }

  handleFocusOnTabChange = (activeTabIndex) => {
    if (activeTabIndex === 0) {
      //* Focus is set after DOM adjustment for "General" tab (otherwise ref is null):
      this.setState(this.state, () => {
        this.focusResultTableIDInput()
      })
    }

    if (activeTabIndex === 1) {
      //* Focus is not needed for "Form" tab
    }

    if (activeTabIndex === 2) {
      //* Focus is handled by <PreviewTab ../> component
    }
  }

  focusResultTableIDInput = () => {
    if (!!this.resultTableIdInput.current && !!document.getElementById(`${this.props.id}_resulttableid`)) {
      this.resultTableIdInput.current.focus()
    }
  }

  initFormTab = () => {
    this.setState({
      formTab: {
        ...this.state.formTab,
        filteredObjects: this.initFilteredData(),
        nonEditableObjects: this.initNonEditableData(),
        data: this.getData(),
      }
    }, () => {
      this.setState({
        formTab: {
          ...this.state.formTab,
          checkedRows: this.initCheckedRows(), // Dependent in 'data'
        }
      })
    })

    this.focusResultTableIDInput()
  }

  getDefaultObjects() {
    const {generalTab} = this.state
    const {resultTableDefaultObjects} = this.props
    if (UserUtils.isDOCX()) {
      return resultTableDefaultObjects.BRWTAB
    } else if (UserUtils.isLOGX()) {
      const {key: jobType} = getAvailableJobTypes()[generalTab.jobType]
      return resultTableDefaultObjects[jobType]
    }
  }

  /**
     * @description Initializes the result table objects and filter all non editable entries.
     * @returns {Array} The filtered result table objects.
     */
  initFilteredData() {
    let defaultObjects = this.getDefaultObjects()
    const filteredObject = []
    let buffer = {}
    for (let i = 0; i < defaultObjects?.data.length; i++) {
      for (let j = 0; j < defaultObjects.data[i].length; j++) {
        if (defaultObjects.data[i][defaultObjects.header.indexOf('SLISTAT')] === '*') {
          buffer[defaultObjects.header[j]] = defaultObjects.data[i][j]
        }
      }
      Object.entries(buffer).length !== 0 && filteredObject.push(buffer)
      buffer = {}
    }
    return filteredObject
  }

  /**
     * @description Initializes the result table objects and filter all editable entries.
     * @returns {Array} The filtered result table objects.
     */
  initNonEditableData() {
    let defaultObjects = this.getDefaultObjects()
    const filteredObject = []
    let buffer = {}
    for (let i = 0; i < defaultObjects?.data.length; i++) {
      for (let j = 0; j < defaultObjects.data[i].length; j++) {
        if (defaultObjects.data[i][defaultObjects.header.indexOf('SLISTAT')] !== '*') {
          buffer[defaultObjects.header[j]] = defaultObjects.data[i][j]
        }
      }
      Object.entries(buffer).length !== 0 && filteredObject.push(buffer)
      buffer = {}
    }
    return filteredObject
  }

  /**
     * @description Initializes all checked rows.
     * @returns {Array} The checked rows.
     */
  initCheckedRows() {
    const defaultFieldNames = []

    const filteredObjects = this.initFilteredData()

    filteredObjects.forEach((el) => {
      if (el.SLITSEL === '*') {
        defaultFieldNames.push(el[FIELD_NAME])
      }
    })

    // Note: The <DataTable ../> component works with the hash values of a row since BADM-2318, this changes the logic of how to work with data table rows.
    // The default checked rows are determined in the 'resultTable.OBJECTS' array via the the 'SLITSEL === "*"' condition.
    // In line 191 a helper array is created to list all default field names, e.g. 'Buxinpdt' or 'Form'. These default field names are
    // used to find the default rows, which then are used to determine the hash values of each rows.
    const { data: rows } = this.state.formTab
    const indexFieldName = this.state.formTab.header.findIndex((headerEntry) => headerEntry.rest === FIELD_NAME)
    const defaultRows = rows.filter((row) => defaultFieldNames.includes(row[indexFieldName]))
    const defaultCheckedRows = defaultRows.map((row) => hash(row.toString()))

    return defaultCheckedRows
  }

  /**
     * @description Gets the header information of the formtab table.
     * @returns {Array} The header information.
     */
  fillHeaderInformation() {
    return [
      {rest: FIELD_NAME, translation: 'definition.result_table_field_name'},
      {rest: DESCRIPTION, translation: 'general.description'},
      {rest: FIELD_FORMAT, translation: 'definition.result_table_field_format'},
      {rest: FIELD_LENGTH, translation: 'definition.result_table_field_length'},
      {rest: FIELD_VALUE, translation: 'definition.result_table_field_value'},
      {rest: COLUMN_WIDTH, translation: 'definition.result_table_column_width'},
      {rest: COLUMN_IDENTIFIER, translation: 'definition.result_table_column_identifier'},
    ]
  }

  /**
     * @description Gets specific column sort definitions.
     */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)
  sleepy = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  /**
     * @description Handles the input changes of the input fields.
     * @param {String} key The id the input field.
     * @param {String} value The new value.
     * @param {String} error The new error.
     */
  handleChangeGeneralTab = (key, value, error) => {
    const {getResultTableDefaultObjects, removePreviewResults, resultTableDefaultObjects} = this.props
    let newState = {
      generalTab: {
        ...this.state.generalTab,
        [key]: typeof this.state.generalTab[key] === 'object'
          ? {value, error}
          : value
      }
    }
    const valueChanged = value !== this.state.generalTab.jobType

    if (key === 'jobType' && valueChanged) {
      newState = {
        ...newState,
        formTab: {
          header: this.fillHeaderInformation(),
          filteredObjects: [],
          nonEditableObjects: [],
          data: [],
          checkedRows: [],
          showModifyDialog: false,
          sortedCol: undefined,
          currentObjectIndex: -1
        },
        previewTab: {
          lastUsedPreviewQuery: ''
        }
      }
      removePreviewResults()
    }

    this.setState({...newState}, () => {
      if (key === 'jobType' && valueChanged) {
        const jobtype = getAvailableJobTypes()[value].key

        // check if data is already in redux
        if (Object.keys(resultTableDefaultObjects).includes(jobtype)) {
          this.initFormTab()
        } else {
          getResultTableDefaultObjects(jobtype) // Note: Fetching new result table default objects will trigger a re-initialization of the Form Tab via the 'componentDidUpdate' lifecycle
        }
      }
    })
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleChangeGeneralTab(key, value, error)
  }

  /**
     * @description Validates the result table id
     */
  validateResultTableID = () => {
    const {generalTab} = this.state
    if (generalTab.resultTableID.value !== '') {
      return {}
    }
    return {
      resultTableID: {
        ...this.state.generalTab.resultTableID,
        error: translate('general.input_required')
      }
    }
  }

  /**
     * @description Validates the description
     */
  validateDescription = () => {
    const {generalTab} = this.state
    if (generalTab.description.value !== '') {
      return {}
    }
    return {
      description: {
        ...this.state.generalTab.description,
        error: translate('general.input_required')
      }
    }
  }

  /**
     * @description Validates the general tab
     */
  validateGeneralTab = () => {
    const validatorResult = {...this.validateResultTableID(), ...this.validateDescription()}
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState(state => ({generalTab: {...state.generalTab, ...validatorResult}}), () => {
        this.handleGeneralTabFocus()
      })
    }
    return errors === 0
  }

  /**
     * @description Focuses the next input field in general tab with an error.
     */
  handleGeneralTabFocus = () => {
    const {generalTab} = this.state
    const requiredInputs = [
      {inputRef: this.resultTableIdInput, error: generalTab.resultTableID.error},
      {inputRef: this.descriptionInput, error: generalTab.description.error}
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
     * @description Gets the translated headers.
     * @returns {Array} Translated headers.
     */
  getTranslatedHeaders = () => {
    return this.state.formTab.header.map(entry => {
      return translateNoError(entry.translation)
    })
  }

  /**
     * @description Creates the action buttons for the table.
     * @param {Number} rowIndex The index of the current row.
     */
  createActionButtons = rowIndex => {
    const {id} = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => {
          this.showModifyDialog(rowIndex)
        }}/>
    ]
  }

  /**
     * @description Shows the modify dialog for a result table object.
     * @param {Number} index The index of the clicked row.
     */
  showModifyDialog = index => {
    this.setState({
      formTab: {
        ...this.state.formTab,
        currentObjectIndex: index,
        showModifyDialog: true,
      }
    })
  }

  /**
     * @description Gets the data of the filtered objects table.
     * @returns {Array} The data of the filtered objects table.
     */
  getData() {
    const { header: headers } = this.state.formTab

    const data = this.initFilteredData().map((filteredObject) => headers.map((header) => filteredObject[header.rest]))
    return data
  }

  /**
     * @description Modifies the current object.
     * @param {Number} width The new column width of the current object.
     * @param {String} identifier The new column identifier of the current object.
     * @param {String} value The new column value of the current object.
     * @param {Function} callback The callback which will be called when the new data is set to state.
     */
  modifyObject = (width, identifier, value, callback) => {
    const newFilteredObjects = {...this.state.formTab.filteredObjects}

    // modify in data array -> we only show width and identifier in table
    const newData = [...this.state.formTab.data.map((arr) => [...arr])]
    let row = newData[this.state.formTab.currentObjectIndex]
    // column width
    row[5] = width
    // column identifier
    row[6] = identifier

    // get the key which we need to find the right object in filteredObjects
    const key = this.state.formTab.data[this.state.formTab.currentObjectIndex][0]
    let objectIndex = 0

    // get the right index
    const newObject = Object.values(newFilteredObjects).find((el, index) => {
      objectIndex = index
      return el.SLINAME === key
    })

    // set the new values to the object
    newObject.SLITLEN = width
    newObject.SLITNAME = identifier
    newObject.SPLITVAl = value

    // assign the modified object to the list
    newFilteredObjects[objectIndex] = newObject

    this.setState({
      formTab: {
        ...this.state.formTab,
        filteredObjects: newFilteredObjects,
        data: newData
      }
    }, () => callback())
  }

  /**
     * @description The drag end action from dnd.
     * @param {Object} result The result from dnd.
     */
  onDragEnd = result => {
    const {source, destination} = result
    // dropped outside the table
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      // sort items
      const currentItems = this.state.formTab.data
      const items = this.reorder(currentItems, source.index, destination.index)

      this.setState({
        formTab: {
          ...this.state.formTab,
          data: items,
          sortedCol: undefined
        }
      })
    }
  }

  /**
     * @description Reorders an item inside a list.
     * @param {Array} items The items.
     * @param {Number} startIndex The source index of the item to reorder.
     * @param {Number} endIndex The destination index.
     * @return {Array} The reordered list.
     */
  reorder = (items, startIndex, endIndex) => {
    const newItems = [...items]
    const [removed] = newItems.splice(startIndex, 1)
    newItems.splice(endIndex, 0, removed)

    return newItems
  }

  /**
     * @description Will be called when the sort ends.
     * @param {Array} sortedData The new sorted data.
     * @param {String} sortedCol The new sorted col.
     * @param {Array} newCheckedRows The new sorted checked rows
     */
  onSortEnd = (sortedData, sortedCol, newCheckedRows) => {
    this.setState({
      formTab: {
        ...this.state.formTab,
        data: sortedData,
        sortedCol: sortedCol,
        checkedRows: newCheckedRows
      }
    })
  }

  /**
     * @description Validates the form tab and shows a snackbar if no object is selected.
     * @returns {Boolean} True if validation was successful without errors.
     */
  validateFormTab = () => {
    if (this.state.formTab.checkedRows.length > 0) {
      return true
    } else {
      this.props.showSnackbar(translate('definition.empty_result_table_objects'), SnackbarActions.TYPE_ERROR)
      return false
    }
  }

  /**
     * @description Saves the data and creates a result table.
     */
  handleSave = () => {
    const {generalTab} = this.state
    const {createResultTable, onClose} = this.props
    const errorTabs = [
      this.validateGeneralTab(),
      this.validateFormTab()
    ]
    if (errorTabs.every(d => d)) {
      let jobType = ''
      if (generalTab.jobType > -1) {
        jobType = getAvailableJobTypes()[generalTab.jobType].key
      }

      const resultTable = {
        SLTINAME: generalTab.resultTableID.value,
        SLTENAME: generalTab.description.value,
        OWNER: generalTab.owner,
        SLTITYPE: jobType,
        OBJECTS: this.buildObjects()
      }
      createResultTable(resultTable, () => onClose())
    }
  }

  /**
     * @description Builds the object for the request.
     * @returns {Array} The objects for the request.
     */
  buildObjects = () => {
    const {formTab} = this.state

    const objects = []

    formTab.data.forEach((el, index) => {
      // get the key
      const key = el[0]

      // Get hash of row, because 'checkedRows' is an array of row hashes
      const rowHash = hash(el.toString())

      // find the object which matches the key
      const object2Add = Object.values(formTab.filteredObjects).find(filteredObject => {
        return key === filteredObject.SLINAME
      })

      // set new position
      object2Add.SLITPOS = index

      // set checked flag
      object2Add.SLITSEL = this.state.formTab.checkedRows.includes(rowHash) ? '*' : ''

      objects.push(object2Add)
    })

    // add non editable objects
    formTab.nonEditableObjects.forEach((el) => {
      objects.push(el)
    })

    return objects
  }

  /**
     * @description Gets the tabs with errors.
     * @returns {Array} The array with error tabs.
     */
  handleErrorTabs = () => {
    const {generalTab} = this.state
    const buffer = []
    if (generalTab.resultTableID.error !== '' || generalTab.description.error !== '') {
      buffer.push(0)
    }
    if (this.state.formTab.checkedRows.length === 0) {
      buffer.push(1)
    }
    return buffer
  }

  /**
     * @description Renders the modify dialog for an result table object.
     */
  renderModifyDialog = () => {
    const {formTab} = this.state

    const key = formTab.data[formTab.currentObjectIndex] && formTab.data[formTab.currentObjectIndex][0]
    const currentObject = Object.values(formTab.filteredObjects).find((el) => {
      return el.SLINAME === key
    })

    return (
      <>
        {
          this.state.formTab.showModifyDialog && (
            <ModifyResultTableObjectDialog
              id={`${this.props.id}_modify_object_dialog`}
              onClose={() => this.setState(({formTab: {...this.state.formTab, showModifyDialog: false}}))}
              modifyObject={this.modifyObject}
              width={parseInt(currentObject.SLITLEN)}
              identifier={currentObject.SLITNAME}
              value={currentObject.SLITVAL}
              showValue={currentObject.SLIDTYPE === 'CHOICE'}
            />
          )
        }
      </>
    )
  }

  /**
     * @description Renders the general tab.
     */
  renderGeneralTab = () => {
    const {id} = this.props
    const {generalTab} = this.state
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_resulttableid`}
              onInputChanged={(val, error) => this.handleChangeWithoutSpaces('resultTableID', val, error)}
              value={generalTab.resultTableID.value}
              title={translate('definition.result_table_id')}
              ref={this.resultTableIdInput}
              maxLength={16}
              required={`${translate('general.required_field')}`}
              error={generalTab.resultTableID.error}
              onBlur={() => this.setState(state => ({generalTab: {...state.generalTab, ...this.validateResultTableID()}}))}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_owner`}
              onInputChanged={val => this.handleChangeGeneralTab('owner', val)}
              value={generalTab.owner}
              title={translate('general.owner')}
              maxLength={8}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_description`}
              onInputChanged={(val, error) => this.handleChangeGeneralTab('description', val, error)}
              value={generalTab.description.value}
              title={translate('general.description')}
              ref={this.descriptionInput}
              maxLength={64}
              required={`${translate('general.required_field')}`}
              error={generalTab.description.error}
              onBlur={() => this.setState(state => ({generalTab: {...state.generalTab, ...this.validateDescription()}}))}
            />
          </Column>
        </Row>
        {UserUtils.isLOGX() && (
          <Row>
            <Column colMD={6}>
              <Dropdown
                id={`${id}_jobtype`}
                title={translate('job.jobtype')}
                items={getAvailableJobTypes().map(({translation}) => translate(translation))}
                onChange={(index) => this.handleChangeGeneralTab('jobType', index)}
                activeIndex={generalTab.jobType}
              />
            </Column>
          </Row>
        )}
      </>
    )
  }

  /**
     * @description Renders the form tab.
     */
  renderFormTab = () => {
    const {id, lang, datemask} = this.props

    const translatedHeaders = this.getTranslatedHeaders()

    return (
      <>
        <Row>
          <Column
            colMD={12}>
            <DataTable
              id={id}
              header={translatedHeaders}
              data={this.state.formTab.data}
              cleanData={this.state.formTab.data}
              checkedRows={this.state.formTab.checkedRows}
              selectable
              menu={false}
              onDragEnd={this.onDragEnd}
              createActionButtons={this.createActionButtons}
              createTableRowAction={index => {
                this.showModifyDialog(index)
              }}
              columnSortDefs={this.getColumnSortDefs(this.state.formTab.data, translatedHeaders)}
              translate={key => translate(key)}
              language={lang}
              datemask={datemask}
              updateCheckedRowsToParent={(newCheckedRows) => {
                this.setState({formTab: {...this.state.formTab, checkedRows: newCheckedRows}})
              }}
              onSortEnd={this.onSortEnd}
              sortedCol={this.state.formTab.sortedCol}
              checkAllIcons={{check: 'show', unCheck: 'hide'}}
            />
          </Column>
        </Row>
      </>
    )
  }

  getHeaderKeysToRender = () => {
    const indexFieldName = this.state.formTab.header.findIndex((headerEntry) => headerEntry.rest === FIELD_NAME)
    const indexColumnIdentifier = this.state.formTab.header.findIndex((headerEntry) => headerEntry.rest === COLUMN_IDENTIFIER)

    const rowHashes = this.state.formTab.checkedRows.map((rowHash) => rowHash.toUpperCase())

    const headerKeysToRender = rowHashes.reduce((acc, rowHash) => {
      const rowMatch = this.state.formTab.data.find((row) => hash(row.toString()).toUpperCase() === rowHash)
      if (!rowMatch) return acc

      acc.fieldNames.push(rowMatch[indexFieldName].toUpperCase())
      acc.columnIdentifiers.push(rowMatch[indexColumnIdentifier])

      return acc
    }, { fieldNames: [], columnIdentifiers: [] })

    return headerKeysToRender
  }

  render = () => {
    const { id, onClose } = this.props
    const { lastUsedPreviewQuery } = this.state.previewTab

    const headerKeysToRender = this.getHeaderKeysToRender()

    return (
      <>
        <Modal
          id={'create_resulttable_dialog'}
          onClose={onClose}>
          <Header
            id={`${id}_header`}
            title={translate('definition.create_result_table')}
            onClose={onClose}>
          </Header>
          <Main>
            {this.renderModifyDialog()}
            <Tabs
              id={id}
              onTabChanged={this.handleFocusOnTabChange}
              errorTabs={this.handleErrorTabs()}>
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab title={translate('general.form_tab')} className={'bux_table_container'}>
                {this.renderFormTab()}
              </Tab>
              <Tab title={translate('general.preview_tab')}>
                <PreviewTab
                  id={id}
                  headerKeysToRender={headerKeysToRender}
                  lastUsedPreviewQuery={lastUsedPreviewQuery} // Persist when switching tabs
                  setLastUsedPreviewQuery={(lastUsedPreviewQuery) => this.setState({ previewTab: { ...this.state.previewTab, lastUsedPreviewQuery: lastUsedPreviewQuery } })} // Persist when switching tabs
                  selectedResultTableType={(getAvailableJobTypes()[this.state.generalTab.jobType]).key}
                />
              </Tab>
            </Tabs>
          </Main>
          <Footer>
            {this.props.prefilledData && !hasNoValues(this.props.prefilledData) &&
              <Button
                id={`${id}_resetbtn`}
                tooltip={translate('general.reset')}
                icon={'undo'}
                onClick={this.resetState}
              />
            }
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const FIELD_NAME = 'SLINAME'
const DESCRIPTION = 'SLICOM'
const FIELD_FORMAT = 'SLIDTYPE'
const FIELD_LENGTH = 'SLILEN'
const FIELD_VALUE = 'SLIVAL'
const COLUMN_WIDTH = 'SLITLEN'
const COLUMN_IDENTIFIER = 'SLITNAME'

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    resultTableDefaultObjects: state.definitions.resulttables.resultTableDefaultObjects,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createResultTable: (resultTable, callback) => ResultTableDefinitionActions.createResultTable(resultTable, callback)(dispatch),
    getResultTableDefaultObjects: (buxCommand, callback) => ResultTableDefinitionActions.getResultTableDefaultObjects(buxCommand, callback)(dispatch),
    removePreviewResults: () => ResultTableDefinitionActions.removePreviewResults()(dispatch),
    showSnackbar: (message, type) => SnackbarActions.show(message, type)(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateResultTableDialog)