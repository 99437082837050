import { useCallback, useEffect, useState } from 'react'

// components
import { Button, Card, Column, Link, List, MultilineInput, Row, Switch, Window } from 'BetaUX2Web-Components/src/'

// style
import './DatabaseQuery.scss'

// redux
import { translate } from 'language/Language'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import * as DatabaseActions from 'redux/actions/DatabaseActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'

interface DatabaseQueryProps {
  id: string;
}

const DatabaseQuery = ({ ...props }: DatabaseQueryProps) => {
  const preferences = useSelector((store: RootStateOrAny) => store.auth.serverdata.preferences);
  const lang = useSelector((store: RootStateOrAny) => store.auth.serverdata.preferences[Preferences.LANGUAGE]);
  const bqlQuery = useSelector((store: RootStateOrAny) => store.database.bqlQuery);
  const dispatch = useDispatch()

  const [query, setQuery] = useState('');
  const [toUpper, setToUpper] = useState(true);
  const [unlocked, setUnlocked] = useState<boolean | undefined>(undefined);
  const [querySucceed, setQuerySucceed] = useState<boolean | null>(null);
  const [windowComponentHeight, setWindowComponentHeight] = useState(0);

  const favorites = preferences.FAVORITES;

  /**
   * @description Initializes the values.
   */
  useEffect(() => {
    initFieldsFromPreferences()
    if (favorites && favorites.length) {
      setUnlocked(false)
    }
    calculateWindowHeight()
    window.addEventListener('resize', calculateWindowHeight)
    return () => {
      window.removeEventListener('resize', calculateWindowHeight)
    }
  // eslint-disable-next-line
  }, [])

  /**
  * @description Initializes the values.
  */
  useEffect(() => {
    initFieldsFromPreferences()
    if (!favorites || !favorites.length) {
      setUnlocked(undefined)
    }
    if (favorites && favorites.length && !unlocked) {
      setUnlocked(false)
    }
    // eslint-disable-next-line
  }, [preferences, favorites])

  useEffect(() => {
    // show snackbar for no data queries
    if (!bqlQuery && querySucceed) {
      SnackbarActions.show(translate('database.query_success'), SnackbarActions.TYPE_SUCCESS)(dispatch)
    }
    setQuerySucceed(false)
    DatabaseActions.getCopiedBqlQueryData({ copiedBqlQuery: bqlQuery || '' })(dispatch)
  // eslint-disable-next-line
  }, [bqlQuery, dispatch])

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  const initFieldsFromPreferences = useCallback(() => {
    if (preferences) {
      const savedQuery = preferences[Preferences.DATABASE_QUERY_QUERY] || ''
      const toUpperSaved = preferences[Preferences.DATABASE_QUERY_TOUPPER] !== undefined ? preferences[Preferences.DATABASE_QUERY_TOUPPER] : true

      setQuery(savedQuery);
      setToUpper(toUpperSaved)
    }
  }, [preferences])

  /**
   * @description Handles changes on query fields.
   * @param {String} value The new value
   */
  const handleQueryChange = (value: string) => {
    setQuery(value)
  }

  /**
   * @description Handles the switch button action.
   * @param {Number} index The event which is thrown by the switch
   */
  const handleToUpper = (index: number) => setToUpper(index === 0)

  /**
   * @description Handles the submit search action.
   * @param {Object} event The event which is thrown by the button
   */
  const handleSubmit = (event: any) => {
    event.preventDefault()
    // Remove all line breaks from the string before search
    const queryValue = query.replace(/(\r\n|\n|\r)/gm, '')

    // save search values in preferences
    const prefsToChange = {
      [Preferences.DATABASE_QUERY_QUERY]: queryValue,
      [Preferences.DATABASE_QUERY_TOUPPER]: toUpper
    }

    PreferenceActions.changePrefs(prefsToChange)(dispatch)
    DatabaseActions.executeBqlQuery(toUpper ? queryValue.toUpperCase() : queryValue, () => { setQuerySucceed(true) })(dispatch)
  }

  /**
   * @description Resets the current values to the default values.
   */
  const handleReset = () => {
    // setBqlData(null)
    setQuery('')
    setToUpper(true)
    setUnlocked(false)
  }

  /**
   * @description Adds the current query to favorites.
   */
  const handleAddToFavorites = () => {

    let buffer = preferences.FAVORITES || []
    // Remove all line breaks from the string before search
    const queryValue = query.replace(/(\r\n|\n|\r)/gm, '')

    if (query !== '') {
      const favoriteIndex = buffer.findIndex((f: { query: string; toUpper: boolean }) => f.query === queryValue && f.toUpper === toUpper);
      if (favoriteIndex === -1) {
        PreferenceActions.changePrefs({
          FAVORITES: [
            ...buffer, {
              'query': queryValue,
              'toUpper': toUpper
            }
          ]
        })(dispatch)
      }
      else {
        SnackbarActions.show(translate('database.query_already_saved'), SnackbarActions.TYPE_INFO)(dispatch);
      }
    }
  }

  /**
   * @description Renders the Query card.
   */
  const queryUI = (
    <Card id={''} title={translate('general.general')}>
      <Row>
        <Column colMD={12}>
          <MultilineInput
            id={props.id}
            title={translate('database.query')}
            value={query}
            onInputChanged={(val: string) => handleQueryChange(val)}
            required={`${translate('general.required_field')}`}
            mono
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Switch
            id={`${props.id}_touppercase`}
            items={[translate('general.yes'), translate('general.no')]}
            title={translate('database.query_to_uppercase')}
            onClick={(index: number) => handleToUpper(index)}
            activeIndex={toUpper ? 0 : 1}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Button
            id={`${props.id}_addfavorite`}
            text={translate('database.add_to_favorite')}
            onClick={() => handleAddToFavorites()}
            fill
          />
        </Column>
      </Row>
    </Card>
  )


  /**
   * @description Loads a specific query into the textfield.
   * @param {String} query The string to load.
   * @param {Boolean} toUpper The to uppercase flag.
   */
  const setFromFavorite = (query: string, toUpper: boolean) => {
    setQuery(query)
    setToUpper(toUpper)
  }

  /**
   * @description Deletes a specific favorite.
   * @param {Number} index The index of the favorite to delete.
   */
  const handleDeleteFavorite = (index: number) => {

    if (unlocked) {
      PreferenceActions.changePrefs({ FAVORITES: favorites.filter((_: any, i: number) => i !== favorites.length - 1 - index) })(dispatch)
    }
  }

  const calculateWindowHeight = () => {
    const el = document.querySelector('#drawer_content_database_body_main')
    if (el) {
      const drawerHeight = el.getBoundingClientRect().height
      const drawerPaddingTop = 16
      const drawerPaddingBottom = 16
      const upperElementHeight = document.querySelector('#drawer_content_database_body_main div:first-child')?.getBoundingClientRect().height || 0
      const upperElementMarginBottom = 16
      const titleHeight = 24
      setWindowComponentHeight(drawerHeight - drawerPaddingTop - drawerPaddingBottom - upperElementHeight - upperElementMarginBottom - titleHeight);
    }
  }

  /**
   * @description Renders the Favorite card.
   */
  const favoritesUI = (
    <Window
      id={`${props.id}_main`} title={translate('database.favorites')}
      maxHeight={windowComponentHeight}
      icon={
        unlocked !== undefined
          ? <Link
            id={`${props.id}_lock_unlock_delete_function`}
            iconName={unlocked ? 'lock' : 'unlock'}
            onClick={() => setUnlocked(!unlocked)} />
          : null
      }
    >
      <List id={`${props.id}_favorites`}>
        {
          favorites && favorites.length > 0
            ? favorites.map((_: any, index: number) => {
              return (
                <div key={index} className={`bux_favorites ${index > 0 ? 'bux_light_border' : ''}`}>
                  { /* calculation for index is used, to display the newest entries first */}
                  {favorites[favorites.length - 1 - index].query}
                  <div className={'bux_favorites_icons_container'}>
                    <Link
                      id={`${props.id}_favorite_${index}_reuse`}
                      tooltip={translate('general.reuse')}
                      iconName={'reuse_text'}
                      onClick={() => setFromFavorite(favorites[favorites.length - 1 - index].query, favorites[favorites.length - 1 - index].toUpper)}
                    />
                    <Link
                      id={`${props.id}_favorite_${index}_delete`}
                      tooltip={unlocked ? translate('general.delete') : translate('definition.database_query_enable_delete_function')}
                      iconName={'delete'}
                      disabled={!unlocked}
                      onClick={() => handleDeleteFavorite(index)}
                    />
                  </div>
                </div>
              )
            })
            : <div className={'bux_no_favorites'}>{translate('database.query_no_favorites')}</div>
        }
      </List>
    </Window>
  )

  /**
   * @description Renders the main components.
   */
  const renderMain = () => {
    return (
      <div
        id={`${props.id}_main`}
        className={'bux_drawer_main bux_drawer_database_query_main'}>
        {queryUI}
        {favoritesUI}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  const renderFooter = () => {
    return (
      <div
        id={`${props.id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${props.id}_execute`}
          text={translate('database.execute', lang)}
          onClick={handleSubmit}
          submit
          primary
        />
        <Button
          id={`${props.id}_resetBtn`}
          icon='undo'
          onClick={handleReset}
        />
      </div>
    )
  }


  return (
    <form
      id={props.id}
      className='bux_drawer_form'
    // onSubmit={(e) => { handleSubmit(e) }}
    >
      {renderMain()}
      {renderFooter()}
    </form>
  )
}

export default DatabaseQuery;