import PropTypes from 'prop-types'
import { Component } from 'react'
import * as SortUtils from 'utils/SortUtils'

// components
import { DataTable, ResultContainer } from 'BetaUX2Web-Components/src/'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as ServerActions from 'redux/actions/ServerActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'

class SearchResultServerOutputProcesses extends Component {

  /**
   * @description gets the index of the header in redux state externalcmds.header
   * @param {String} header header name of the header in redux state externalcmds.header
   */
  headerData = header => this.props.outputProcesses.header.indexOf(header)

  /**
   * @description builds the data to a an array which is used later
   */
  getData = () => {
    const { outputProcesses } = this.props
    const data = []
    outputProcesses.data.forEach(d => {
      let buffer = []
      const headers = outputProcesses.header
      for (const header of headers) {
        buffer.push(d[this.headerData(header)])
      }
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  render = () => {
    const { id, drawerExpanded, autoDrawer, lang, datemask, outputProcesses } = this.props
    const data = outputProcesses?.data ? this.getData() : null
    const header = outputProcesses?.header || []
    const fillPage = false
    return (
      <ResultContainer
        drawerExpanded={drawerExpanded}
        autoDrawer={autoDrawer}>
        {
          data &&
          <DataTable
            id={id}
            header={header}
            data={data}
            cleanData={data}
            selectable={true}
            columnSortDefs={this.getColumnSortDefs(data, header)}
            fillPage={fillPage}
            translate={key => translate(key)}
            language={lang}
            datemask={datemask}
            noAction
            menu={false}
          />
        }
      </ResultContainer>
    )
  }
}

SearchResultServerOutputProcesses.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    outputProcesses: state.server.outputProcesses,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getLpdQueuesStatus: () => ServerActions.getLPDQueuesStatus()(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultServerOutputProcesses)