import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class TableCell extends Component {

  static propTypes = {
    /** Indicates is dragging in the table occurs */
    isdragoccuring: PropTypes.oneOf(['true', 'false']),
    /** Children of the components */
    children: PropTypes.node
  }


  selfRef = React.createRef()

  getSnapshotBeforeUpdate(prevProps) {
    if (!this.selfRef) {
      return null
    }

    const isDragStarting = this.props.isdragoccuring === 'true' && prevProps.isdragoccuring === 'false'

    if (!isDragStarting) {
      return null
    }

    const { width, height } = this.selfRef.current.getBoundingClientRect()

    const snapshot = {
      width,
      height,
    }

    return snapshot
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.selfRef) {
      return
    }

    if (snapshot) {
      if (this.selfRef.current.style.width === snapshot.width) {
        return
      }
      this.selfRef.current.style.width = `${snapshot.width}px`
      this.selfRef.current.style.height = `${snapshot.height}px`
      return
    }

    if (this.props.isdragoccuring === 'true') {
      return
    }

    // inline styles not applied
    if (this.selfRef.current.style.width === null) {
      return
    }

    // no snapshot and drag is finished - clear the inline styles
    this.selfRef.current.style.removeProperty('height')
    this.selfRef.current.style.removeProperty('width')
  }

  render() {
    return (
      <td ref={this.selfRef}
        // take all props (given as object) | split them | pass them into the sub-component
        {...this.props}>
        {this.props.children}
      </td>
    )
  }
}