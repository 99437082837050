import { LOGOUT_SUCCESS } from '../actions/AuthenticationActions'
import * as BundleQueueActions from '../actions/BundleQueueActions'

const defaultState = {
  bundles: undefined,
  bundleDetails: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Get bundle queues actions
    case BundleQueueActions.GET_BUNDLE_QUEUES_START:
      return { ...state }
    case BundleQueueActions.GET_BUNDLE_QUEUES_SUCCESS:
      return { ...state, bundles: action.payload }
    case BundleQueueActions.GET_BUNDLE_QUEUES_FAILED:
      return { ...state }
    case BundleQueueActions.NO_BUNDLE_QUEUES_FOUND:
      return { ...state, bundles: {} }
    /* ********************************************************************************** */
    // Get bundle queue details actions
    case BundleQueueActions.GET_BUNDLE_QUEUE_DETAILS_START:
      return { ...state, bundleDetails: undefined }
    case BundleQueueActions.GET_BUNDLE_QUEUE_DETAILS_SUCCESS:
      return { ...state, bundleDetails: action.payload, keepPagination: action.keepPagination }
    case BundleQueueActions.GET_BUNDLE_QUEUE_DETAILS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // delete bundle queue actions
    case BundleQueueActions.DELETE_BUNDLE_QUEUE_START:
      return { ...state }
    case BundleQueueActions.DELETE_BUNDLE_QUEUE_SUCCESS:
      return { ...state }
    case BundleQueueActions.DELETE_BUNDLE_QUEUE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // update bundle queue actions (includes HOLD, RELEASE and RESET)
    case BundleQueueActions.UPDATE_BUNDLE_QUEUE_START:
      return { ...state }
    case BundleQueueActions.UPDATE_BUNDLE_QUEUE_SUCCESS:
      return { ...state }
    case BundleQueueActions.UPDATE_BUNDLE_QUEUE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case LOGOUT_SUCCESS:
      return { ...state, bundles: undefined, bundleDetails: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer