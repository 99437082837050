import {Dropdown} from 'BetaUX2Web-Components/src/'
import PropTypes from 'prop-types'
import { Component } from 'react'
import QueueBody from './queue_body/QueueBody'

import { translate } from '../../../../language/Language'

import * as Preferences from 'redux/general/Preferences'
import * as LoadingSpinnerActions from '../../../../redux/actions/LoadingSpinnerActions'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class Queue extends Component {
  handleChangeQueue(activeIndex) {
    const link = this.getLink(activeIndex)

    if (link !== undefined)
      this.props.history.push(link)
  }

  getItems() {
    const items = []

    this.props.items.map((item) =>
      items.push(item.title)
    )

    return items
  }

  getLink(activeIndex) {
    // get the object with the current active index and return the link
    return this.props.items.find((item, currentIndex) => currentIndex === activeIndex).link
  }

  getActiveIndex(queue) {
    // get the index of the match with the link of the item and the queue
    let index = this.props.items.findIndex((item) => item.link.substring(item.link.lastIndexOf('/') + 1) === queue)

    // select first index if there is no item selected
    if (index === -1)
      index = 0

    return index
  }

  handleOnSearch() {
    this.props.showLoadingSpinner()
  }

  render = () => {
    const { id, lang, match } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    return (
      <div id={id} className='drawer_field'>
        <div id={`${id}_queue_container1`} className='drawer_header_big bux_drawer_header'>
          <div id={`${id}_queue_container2`} className='row search_row'>
            <div id={`${id}_queue_container3`} className='dropdown el_dropdown'>
              <Dropdown
                id={`${id}_queue`}
                title={translate('general.queue', lang)}
                items={this.getItems()}
                activeIndex={this.getActiveIndex(url)}
                onChange={(activeIndex) => { this.handleChangeQueue(activeIndex) }}
              />
            </div>
          </div>
        </div>

        <QueueBody id={`${id}_body`} queueMode={url} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    loadingspinner: state.loadingspinner
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoadingSpinner: () => { LoadingSpinnerActions.show()(dispatch) },
    hideLoadingSpinner: () => { LoadingSpinnerActions.hide()(dispatch) },
  }
}

Queue.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Queue))