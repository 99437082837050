import PropTypes from 'prop-types'
import { Component } from 'react'

import { Button, Card, Column, Input, Row } from 'BetaUX2Web-Components/src/'
import { LetterCase } from 'BetaUX2Web-Components/src/types'

import { translate } from 'language/Language'
import * as UrlUtils from 'utils/UrlUtils'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as GroupActions from 'redux/actions/GroupActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'

class UserManagementGroups extends Component {

  defaultState = {
    groupid: '',
    groupname: '',
  }

  keys = [
    { rest: 'BETAGRP', ui: 'groupid' },
    { rest: 'GRPNAME', ui: 'groupname' },
  ]

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount = () => {
    const { preferences } = this.props
    if (window.location.href.indexOf('?') !== -1) {
      this.initFieldsFromUrl()
    }
    else {
      // initialize search fields
      if (preferences) {
        let groupid = preferences[Preferences.USERMANAGEMENT_GROUPS_GROUPID] || ''
        let groupname = preferences[Preferences.USERMANAGEMENT_GROUPS_GROUPNAME] || ''
        this.setState({ groupid, groupname })
      }
    }
  }


  initFieldsFromUrl = () => {
    this.setState({
      ...this.state,
      ...UrlUtils.getDataFromUrlParams(this.props.location.search, this.keys)
    }, () => {
      if (UrlUtils.urlContainsExecute()) {
        this.search()
      }
    })
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Handles the changes on group id.
   * @param val The new value
   */
  handleGroupIdChanged = val => {
    this.setState({ groupid: val })
  }

  /**
   * @description Handles the changes on groupname.
   * @param val The new value
   */
  handleGroupnameChanged = val => {
    this.setState({ groupname: val })
  }

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = event => {
    event.preventDefault()
    this.search()
  }

  search = () => {
    const { groupid, groupname } = this.state
    const prefsToChange = {
      [Preferences.USERMANAGEMENT_GROUPS_GROUPID]: groupid,
      [Preferences.USERMANAGEMENT_GROUPS_GROUPNAME]: groupname
    }
    // save search values in preferences
    this.props.changePrefs(prefsToChange)
    this.props.searchGroups(groupid, groupname)

    // builds the parameter object which is used for url
    const urlToPush = `/usermanagement/groups${UrlUtils.createUrlParamsFromObject(this.state, this.keys)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id, lang } = this.props
    const { groupid, groupname } = this.state
    return (
      <Card
        title={translate('general.general', lang)}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_generalcard_groupid`}
              title={translate('group.group_id', lang)}
              maxLength={8}
              value={groupid}
              onInputChanged={this.handleGroupIdChanged}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_generalcard_groupname`}
              title={translate('group.groupname', lang)}
              maxLength={32}
              value={groupname}
              onInputChanged={this.handleGroupnameChanged}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props
    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSubmitSearch}
          submit
          primary />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          className='bux_iconResteBtn'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id } = this.props
    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchGroups: (groupid, groupname) => {
      GroupActions.getGroups(groupid, groupname)(dispatch)
    },
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) }
  }
}

UserManagementGroups.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserManagementGroups))