import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import {
  Button, DataTable, MetaDataGrid, Modal as ModalComponent, ProgressTabContent, ProgressTabs, TableButton
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as DatabaseActions from 'redux/actions/DatabaseActions'
import * as Preferences from 'redux/general/Preferences'

class DatabaseFieldsDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    activeTab: 0,
    clickedField: ''
  }

  /**
   * @description Handles the field button of the specific field
   * @param {Number} rowIndex the index of the specific table row
   * @param {Number} index the index of the next tab
   */
  handleFields = (rowIndex, index) => {
    const { tableFields, getFields } = this.props
    const id = tableFields.data[rowIndex][this.headerData(tableFields, 'XFSNAME')]
    const callback = () => {
      this.setState({
        activeTab: index,
        clickedField: tableFields.data[rowIndex][this.headerData(tableFields, 'XFSNAME')] || ''
      })
    }
    getFields(id, callback)
  }

  /**
   * @description Handles the tab of the progresstab.
   * @param index The index of the new tab.
   */
  handleTabs = index => {
    this.setState({ activeTab: index })
  }

  /**
   * @description Renders the header which includes the non editable information.
   */
  renderHeader = () => {
    const { id, tableHeader } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('database.table_id'), value: tableHeader.id },
          { label: translate('database.table_name'), value: tableHeader.name },
          { label: translate('general.length'), value: tableHeader.length },
          { label: translate('database.table_located_in'), value: tableHeader.location },
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description gets the index of the header in redux state header
   * @param {Object} data specific redux object
   * @param {String} header header name of the header in redux state header
   */
  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description builds the data for the table fields to an array which is used later
   */
  getTableFieldsTableData = () => {
    const { tableFields } = this.props
    const data = []
    tableFields.data.forEach(el => {
      const id = el[this.headerData(tableFields, 'XFSNAME')]
      const name = el[this.headerData(tableFields, 'XFLNAME')]
      const conv = el[this.headerData(tableFields, 'XFCONVYN')]
      const pos = parseInt(el[this.headerData(tableFields, 'XFPOS')])
      const internal = el[this.headerData(tableFields, 'XFFMTI')]
      const internalLength = parseInt(el[this.headerData(tableFields, 'XFLENI')])
      const external = el[this.headerData(tableFields, 'XFFMTE')]
      const externalLength = parseInt(el[this.headerData(tableFields, 'XFLENE')])
      data.push([id, name, conv, pos, internal, internalLength, external, externalLength])
    })
    return data
  }

  /**
   * @description builds the data for the fields to an array which is used later
   */
  getFieldsTableData = () => {
    const { fields } = this.props
    const data = []
    fields.data.forEach(el => {
      const internalType = el[this.headerData(fields, 'XFFMTI')]
      const internalLength = parseInt(el[this.headerData(fields, 'XFLENI')])
      const externalType = el[this.headerData(fields, 'XFFMTE')]
      const externalLength = parseInt(el[this.headerData(fields, 'XFLENE')])
      const subField = el[this.headerData(fields, 'XFCONVSN')]
      const longName = el[this.headerData(fields, 'XFCONVLN')]
      const lang = el[this.headerData(fields, 'XFCONVLA')]
      const internal = el[this.headerData(fields, 'XFCONVVA')]
      const external = el[this.headerData(fields, 'XFCONVTE')]
      data.push([internalType, internalLength, externalType, externalLength, subField, longName, lang, internal, external])
    })
    return data
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonTableFields_${rowIndex}`}
        iconType='material'
        iconName='field'
        title={translate('database.tables_field')}
        onClick={() => this.handleFields(rowIndex, 1)}
      />
    ]
  }

  /**
   * @description renders the Datatable for the table fields
   */
  renderTableFieldsDataList = () => {
    const { id, tableFields, lang, datemask } = this.props
    const tableFieldsData = tableFields && tableFields.data ? this.getTableFieldsTableData() : []
    return (
      <div className={'result_container'}>
        <DataTable
          id={id}
          header={[
            translate('database.tables_field_id'),
            translate('database.tables_field_name'),
            translate('database.tables_fields_conv'),
            translate('database.tables_fields_position'),
            translate('database.tables_fields_internal'),
            translate('database.tables_fields_internal_length'),
            translate('database.tables_fields_external'),
            translate('database.tables_fields_external_length'),
          ]}
          columnSortDefs={['string', 'string', 'string', 'number', 'string', 'number', 'string', 'number']}
          data={tableFieldsData}
          createTableRowAction={index => this.handleFields(index, 1)}
          createActionButtons={this.createActionButtons}
          language={lang}
          datemask={datemask}
          translate={key => translate(key)}
          menu={false}
        />
      </div>
    )
  }

  /**
   * @description renders the Datatable for the fields
   */
  renderFieldsDataList = () => {
    const { id, fields, lang, datemask } = this.props
    const fieldsData = fields && fields.data ? this.getFieldsTableData() : []
    return (
      <div className={'result_container'}>
        <DataTable
          id={`${id}_fields`}
          header={[
            translate('database.tables_fields_internal_type'),
            translate('database.tables_fields_internal_length'),
            translate('database.tables_fields_external_type'),
            translate('database.tables_fields_external_length'),
            translate('database.tables_sub_field'),
            translate('database.tables_long_name'),
            translate('database.tables_lang'),
            translate('database.tables_fields_internal_value'),
            translate('database.tables_fields_external_value')
          ]}
          columnSortDefs={['string', 'number', 'string', 'number', 'string', 'string', 'string', 'string', 'string']}
          data={fieldsData}
          noAction
          language={lang}
          datemask={datemask}
          translate={key => translate(key)}
          menu={false}
        />
      </div>
    )
  }

  render = () => {
    const { id, onClose } = this.props
    const { activeTab, clickedField } = this.state
    return (
      <Modal onClose={onClose}
        id={id}
        className='bux_databaseTablefieldsDialog'>
        <Header
          id={id}
          title={translate('database.table_information')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main id={id}>
          <ProgressTabs
            id={id}
            activeTab={activeTab}
            onChange={index => this.handleTabs(index)}
            additionalLabels={[clickedField]}
            disabledTabs={[1]}>
            <ProgressTabContent
              id={id}
              title={translate('database.tables_fields')}>
              {this.renderTableFieldsDataList()}
            </ProgressTabContent>
            <ProgressTabContent
              id={id}
              title={translate('database.tables_field')}>
              {this.renderFieldsDataList()}
            </ProgressTabContent>
          </ProgressTabs>
        </Main>
        <Footer id={id}>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    preferences: state.auth.serverdata.preferences,
    tables: state.database.tables,
    tablekeys: state.database.tablekeys,
    tableFields: state.database.tablefields,
    fields: state.database.fields,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTableFields: (table, callback) => {
      DatabaseActions.getTableFields(table, callback)(dispatch)
    },
    getFields: (field, callback) => {
      DatabaseActions.getFields(field, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseFieldsDialog)