import * as ArchivePoolDefinitionActions from '../actions/ArchivePoolDefinitionActions.js'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  archivePool: undefined,
  archivePools: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get recipients actions
    case ArchivePoolDefinitionActions.DEFINITION_GET_ARCHIVEPOOLS_START:
      return { ...state }
    case ArchivePoolDefinitionActions.DEFINITION_GET_ARCHIVEPOOLS_SUCCESS:
      return { ...state, archivePools: action.payload, keepPagination: action.keepPagination }
    case ArchivePoolDefinitionActions.DEFINITION_GET_ARCHIVEPOOLS_FAILED:
      return { ...state }
    case ArchivePoolDefinitionActions.NO_DEFINITION_ARCHIVEPOOLS_FOUND:
      return { ...state, archivePools: {} }
    /* ********************************************************************************** */
    // Definition get recipients actions
    case ArchivePoolDefinitionActions.DEFINITION_GET_ARCHIVEPOOL_START:
      return { ...state }
    case ArchivePoolDefinitionActions.DEFINITION_GET_ARCHIVEPOOL_SUCCESS:
      return { ...state, archivePool: action.payload }
    case ArchivePoolDefinitionActions.DEFINITION_GET_ARCHIVEPOOL_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete archive subpool actions
    case ArchivePoolDefinitionActions.DEFINITION_DELETE_ARCHIVESUBPOOL_START:
      return { ...state }
    case ArchivePoolDefinitionActions.DEFINITION_DELETE_ARCHIVESUBPOOL_SUCCESS:
      return { ...state }
    case ArchivePoolDefinitionActions.DEFINITION_DELETE_ARCHIVESUBPOOL_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete archive subpool actions
    case ArchivePoolDefinitionActions.DEFINITION_MODIFY_ARCHIVEPOOL_START:
      return { ...state }
    case ArchivePoolDefinitionActions.DEFINITION_MODIFY_ARCHIVEPOOL_SUCCESS:
      return { ...state }
    case ArchivePoolDefinitionActions.DEFINITION_MODIFY_ARCHIVEPOOL_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition refresh archive pools actions
    case ArchivePoolDefinitionActions.DEFINITION_REFRESH_ARCHIVEPOOLS_SUCCESS:
      return { ...state, archivePool: action.payload }
    case ArchivePoolDefinitionActions.DEFINITION_REFRESH_ARCHIVEPOOLS_FAILED:
      return { ...state }
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, archivePools: undefined, archivePool: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer