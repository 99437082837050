export interface FlyoutDataObject {
  text: string;
  icon?: string;
}

export enum FlyoutAlignment {
  center,
  left,
  right
}

export type FlyoutData = FlyoutDataObject | string;

export const isFlyoutDataObjectType = (item: FlyoutData): boolean => {
  return (item as FlyoutDataObject).text !== undefined;
};