import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SearchResultRecipient from 'components/search_result/definition/recipient/SearchResultRecipient.jsx'
import SearchResultJobGroups from 'components/search_result/definition/job_groups/SearchResultJobGroups.jsx'
import SearchResultDocument from 'components/search_result/definition/document/SearchResultDocument'
import SearchResultLog from 'components/search_result/definition/log/SearchResultLog'
import SearchResultDocumentNode from 'components/search_result/definition/document_node/SearchResultDocumentNode'
import SearchResultExternalCmd from 'components/search_result/definition/external_command/SearchResultExternalCmd'
import SearchResultFolder from 'components/search_result/definition/folder/SearchResultFolder'
import SearchResultIndex from 'components/search_result/definition/index/SearchResultIndex'
import SearchResultLPDQueue from 'components/search_result/definition/lpd_queue/SearchResultLPDQueue'
import SearchResultOutputChannel from 'components/search_result/definition/output_channel/SearchResultOutputChannel'
import SearchResultPostprocessingNote from 'components/search_result/definition/postprocessing_note/SearchResultPostprocessingNote'
import SearchResultOutputFormat from 'components/search_result/definition/output_format/SearchResultOutputFormat'
import SearchResultSearchArgument from 'components/search_result/definition/search_argument/SearchResultSearchArgument'
import SearchResultFilterArgument from 'components/search_result/definition/filter_argument/SearchResultFilterArgument'
import SearchResultArchivePool from 'components/search_result/definition/archive_pool/SearchResultArchivePool'
import SearchResultResultTable from 'components/search_result/definition/result_table/SearchResultResultTable'
import SearchResultCustomDialog from 'components/search_result/definition/custom_dialog/SearchResultCustomDialog'
import SearchResultViewProfile from 'components/search_result/definition/view_profile/SearchResultViewProfile'

import { Redirect } from 'react-router-dom'

class SearchResultDefinition extends Component {
  getBody() {
    const { id, drawerExpanded, match, autoDrawer } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    switch (url) {
      // standard selection result
      case 'recipient':
        return (
          <SearchResultRecipient
            id={`${id}_recipientresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'log':
        return (
          <SearchResultLog
            id={`${id}_logresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'externalcmd':
        return (
          <SearchResultExternalCmd
            id={`${id}_externalcmdresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'lpdqueue':
        return (
          <SearchResultLPDQueue
            id={`${id}_lpdqueueresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'folder':
        return (
          <SearchResultFolder
            id={`${id}_folderresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'index':
        return (
          <SearchResultIndex
            id={`${id}_indexresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'document':
        return (
          <SearchResultDocument
            id={`${id}_documentresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'documentnode':
        return (
          <SearchResultDocumentNode
            id={`${id}_document_node_result`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'outputchannel':
        return (
          <SearchResultOutputChannel
            id={`${id}_outputchannelresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'postprocessingnote':
        return (
          <SearchResultPostprocessingNote
            id={`${id}_postprocessingnoteresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'outputformat':
        return (
          <SearchResultOutputFormat
            id={`${id}_outputformatresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'searchargument':
        return (
          <SearchResultSearchArgument
            id={`${id}_searchargumentresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'filterargument':
        return (
          <SearchResultFilterArgument
            id={`${id}_filterargumentresult`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'archivepool':
        return (
          <SearchResultArchivePool
            id={`${id}_archivepool`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'resulttable':
        return (
          <SearchResultResultTable
            id={`${id}_resulttable`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'customdialog':
        return (
          <SearchResultCustomDialog
            id={`${id}_customdialog`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'viewprofile':
        return (
          <SearchResultViewProfile
            id={`${id}_viewProfile`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'jobgroups':
        return (
          <SearchResultJobGroups
            id={`${id}_job_groups`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )

      default: // fallback is standard selection
        return (
          // take care of the route because if you enter a wrong route you can create an infinite loop!
          <Redirect to={'/definition/recipient'} />
        )
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

SearchResultDefinition.propTypes = {
  id: PropTypes.string.isRequired,
  match: PropTypes.any.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

export default SearchResultDefinition