import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import {
  Button, Card, Column,
  Input,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'
import { LetterCase } from 'BetaUX2Web-Components/src/types'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as RemoteUserActions from 'redux/actions/RemoteUserActions'
import * as IPUtils from 'utils/IPUtils'

class CreateRemoteUserDialog extends Component {
  state = {
    unixuser: {
      value: '',
      errorkey: '',
    },
    buxuser: {
      value: '',
      errorkey: '',
    },
    ipmask: {
      value: '',
      errorkey: '',
    },
    ip6mask: {
      value: '',
      errorkey: ''
    }
  }

  unixuserInput = React.createRef()
  buxuserInput = React.createRef()
  ipmaskInput = React.createRef()
  ip6maskInput = React.createRef()

  /**
   * @description Sets the initial focus.
   */
  componentDidMount = () => {
    // focus unixuser input initially
    this.unixuserInput.current.focus()
  }

  /**
   * @description Handles the unixuser changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleOnUnixuserChanged = (value, error) => {
    this.setState({
      unixuser: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Handles the buxuser changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleOnBuxuserChanged = (value, error) => {
    this.setState({
      buxuser: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Handles the ipmask changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleOnIpmaskChanged = (value, error) => {
    this.setState({
      ipmask: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Handles the ip6mask changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleIp6mask = (value, error) => {
    this.setState({
      ip6mask: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Validates the unixuser. Adds an error under textfield if validation failed.
   * @param {Boolean} allowFocus Flag to focus the textfield if validation failed.
   */
  validateUnixuser = (allowFocus) => {
    if (this.state.unixuser.value !== '') {
      return true
    } else {
      this.setState({ unixuser: { ...this.state.unixuser, errorkey: 'usermanagement.please_enter_a_unixuser' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.unixuserInput.current.focus()
      }

      return false
    }
  }

  /**
   * @description Validates the buxuser. Adds an error under textfield if validation failed.
   * @param {Boolean} allowFocus Flag to focus the textfield if validation failed.
   */
  validateBuxuser = (allowFocus) => {
    if (this.state.buxuser.value !== '') {
      return true
    } else {
      this.setState({ buxuser: { ...this.state.buxuser, errorkey: 'usermanagement.please_enter_a_buxuser' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.buxuserInput.current.focus()
      }

      return false
    }
  }

  /**
   * @description Validates the ipmask. Adds an error under textfield if validation failed.
   * @param {Boolean} allowFocus Flag to focus the textfield if validation failed.
   */
  validateIPMask = (allowFocus) => {
    if (this.state.ipmask.value !== '') {
      if (!IPUtils.isIPv4Mask(this.state.ipmask.value)) {
        this.setState({ ipmask: { ...this.state.ipmask, errorkey: 'usermanagement.ipmask_not_valid' } })
        // set the focus if it's allowed
        if (allowFocus) {
          this.ipmaskInput.current.focus()
        }

        return false
      }
      return true
    } else {
      this.setState({ ipmask: { ...this.state.ipmask, errorkey: 'usermanagement.please_enter_a_ipmask' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.ipmaskInput.current.focus()
      }

      return false
    }
  }

  /**
   * @description Validates the ipv6mask. Adds an error under textfield if validation failed.
   * @param {Boolean} allowFocus Flag to focus the textfield if validation failed.
   */
  validateIP6Mask = (allowFocus) => {
    if (this.state.ip6mask.value === '') {
      return true
    } else if (!IPUtils.isIPv6Mask(this.state.ip6mask.value)) {
      this.setState({ ip6mask: { ...this.state.ip6mask, errorkey: 'usermanagement.ipmask_not_valid' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.ip6maskInput.current.focus()
      }

      return false
    } else {
      return true
    }
  }

  /**
   * @description Closes the dialog.
   */
  handleOnClose = () => {
    this.props.onClose()
  }

  /**
   * @description Calls the rest api and creates the remote user.
   */
  handleOnSave = () => {
    // check data before sending request
    if (this.validateUnixuser(true)) {
      if (this.validateBuxuser(true)) {
        if (this.validateIPMask(true)) {
          if (this.validateIP6Mask(true)) {
            // call create request
            const cuser = this.props.userid
            const unixuser = this.state.unixuser.value
            const buxuser = this.state.buxuser.value
            const ipmask = this.state.ipmask.value
            const ip6mask = this.state.ip6mask.value
            // declare success callback
            const callback = () => { this.props.onClose() }

            this.props.createRemoteUser(cuser, unixuser, buxuser, ipmask, ip6mask, callback)
          }
        } else {
          this.validateIP6Mask(false)
        }
      } else {
        this.validateIPMask(false)
        this.validateIP6Mask(false)
      }
    } else {
      // we also want to check and show the error message under the other fields in case of unixuser is invalid
      // don't allow focus here because unixuser field has an error -> false parameter
      // we don't have to interlace the validate function because we don't have to set the focus
      this.validateBuxuser(false)
      this.validateIPMask(false)
      this.validateIP6Mask(false)
    }
  }

  render = () => {
    const { id } = this.props

    return (
      <Modal onClose={this.handleOnClose} id={`${id}_createRemoteUserModal`} size={'s'}>
        <Header
          id={`${id}_createRemoteUserModal`}
          title={translate('usermanagement.create_remote_user')}
          onClose={this.handleOnClose}
        />
        <Main id={`${id}_createRemoteUserModal`}>
          <Card>
            <Row>
              <Column
                colMD={12}>
                <Input
                  id={`${id}_body_card_row_1_col_unixuser`}
                  ref={this.unixuserInput}
                  onInputChanged={(val, err) => { this.handleOnUnixuserChanged(val, err) }}
                  value={this.state.unixuser.value}
                  title={translate('remoteuser.unixuser')}
                  error={this.state.unixuser.errorkey && translate(this.state.unixuser.errorkey)}
                  maxLength={8}
                  onBlur={() => this.validateUnixuser()}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}>
                <Input
                  id={`${id}_body_card_row_col_buxuser`}
                  ref={this.buxuserInput}
                  onInputChanged={(val, err) => { this.handleOnBuxuserChanged(val, err) }}
                  value={this.state.buxuser.value}
                  title={translate('remoteuser.betaux_user_id')}
                  error={this.state.buxuser.errorkey && translate(this.state.buxuser.errorkey)}
                  maxLength={8}
                  onBlur={() => this.validateBuxuser()}
                  required={`${translate('general.required_field')}`}
                  lettercase={LetterCase.uppercase}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}>
                <Input
                  id={`${id}_body_card_row_col_ipmask`}
                  ref={this.ipmaskInput}
                  onInputChanged={(val, err) => { this.handleOnIpmaskChanged(val, err) }}
                  value={this.state.ipmask.value}
                  title={translate('remoteuser.ip4mask')}
                  error={this.state.ipmask.errorkey && translate(this.state.ipmask.errorkey)}
                  maxLength={45}
                  onBlur={() => this.validateIPMask()}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}>
                <Input
                  id={`${id}_ip6mask`}
                  ref={this.ip6maskInput}
                  onInputChanged={(val, err) => this.handleIp6mask(val, err)}
                  value={this.state.ip6mask.value}
                  error={this.state.ip6mask.errorkey && translate(this.state.ip6mask.errorkey)}
                  title={translate('remoteuser.ip6mask')}
                  maxLength={45}
                  onBlur={() => this.validateIP6Mask()}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_createRemoteUserModal`}>
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel')}
            onClick={this.handleOnClose}
          />
          <Button
            id={`${id}_footer_save`}
            text={translate('general.save')}
            onClick={this.handleOnSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

CreateRemoteUserDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createRemoteUser: (
      cuser,
      unixuser,
      buxuser,
      ipmask,
      ip6mask,
      callback
    ) => {
      RemoteUserActions.createRemoteUser(
        cuser,
        unixuser,
        buxuser,
        ipmask,
        ip6mask,
        callback
      )(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRemoteUserDialog)