import React from 'react';
import { TogglePosition, TOGGLE_LEFT_SIDE, TOGGLE_RIGHT_SIDE } from './Toggle.types';

import './Toggle.scss';


/**
 * Toggle component created according to
 * _Toggle_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */

interface ToggleProps{
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** Function to be called on an Toggle change.*/
  onCheck: (value: boolean) => void;
  /** The value of the Toggle */
  value: boolean;
  /** Label to be displayed above the button.*/
  title?: string;
  /** Used for disable this component. Greyed out label and control. */
  disabled?: boolean;
  /** Used to animate Toggle on change */
  animated?: boolean;
  /** Label for 'on/yes' position of the Toggle */
  yes?: string;
  /** Label for 'off/no' position of the Toggle */
  no?: string;
  /** Used for change background color on hover. Default is set to true and visible if toggle is not disabled. */
  noBackgroundHover?: boolean;
  /** Specify position of the toggle. Default is right.*/
  position?: TogglePosition;
  /** Style class from CSS for styling for Toggle.*/
  className?: string;
}


export const Toggle = (props: ToggleProps): JSX.Element => {

  /**
   * @description Negates the current given value (props) and call the onCheck-prop-function within the new value.
   */
  const handleToggleChange = (): void => {
    props.onCheck(!props.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    const { disabled } = props;
    let permission = true;
    if (event) {
      permission = false;
      if (event.key === 'Enter') {
        permission = true;
      }
    }
    if (permission) {
      !disabled && handleToggleChange();
    }
  };

  const onClick = (): void => {
    !props.disabled && handleToggleChange();
  };

  /**
   * @description Renders the Toggle component which contains multiple divs to visualize the toggle-component.
   */

  const { value, animated, title, disabled, id, noBackgroundHover, yes, no, position = TOGGLE_RIGHT_SIDE, className } = props;
  const activeClass = value ? 'active' : '';
  const animatedClass = animated ? 'cl_toggle_animated' : '';
  const disabledClass = disabled ? 'disabled' : '';
  const moveToggleClass = value ? 'cl_move_toggle_right' : 'cl_move_toggle_left';
  const classProp = className || '';
  const backgroundHover = !noBackgroundHover && !disabled ? 'cl_toggle_background_hover' : '';
  const useRemainingSpace = title ? 'cl_use_remaining_space' : '';
  return (
    <div
      id={`${id}_toggle`}
      className={`cl_toggle_outer_container
        ${activeClass}
        ${disabledClass}
        ${backgroundHover}
        ${useRemainingSpace}
        ${classProp}
      `}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      onMouseOut={e => e.currentTarget.blur()}
      tabIndex={disabled? -1 : 0}
    >
      {position === TOGGLE_RIGHT_SIDE && title && <label id={`${id}_toggle_title`}>{title}</label>}
      <div className={'cl_toggle_inner_container'}>
        <div
          id={`${id}_toggle_switch`}
          className={`cl_toggle_switch
              ${animatedClass}
              ${moveToggleClass}
            `}
        />
        <div
          className={`${value ? '' : 'cl_toggle_lable_show_right'}
              ${animatedClass} cl_toggle_text_container`}>
          <span id={`${id}_toggle_text`}>{value ? yes : no}</span>
        </div>
      </div>
      {position === TOGGLE_LEFT_SIDE && title && <label id={`${id}_toggle_title`}>{title}</label>}
    </div>
  );
};