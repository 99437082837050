import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

// Actions
import { deleteReloadQueue, getReloadQueueDetails, getReloadQueues } from 'redux/actions/ReloadQueueActions'

// Components
import { Column, DataTable, DownloadWrapper, EmptySearchResult, Link, NoSearch, ResultContainer, Row, TableButton } from 'BetaUX2Web-Components/src/'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ReloadQueueRequestInformation from 'components/dialogs/reload_queue_request_information/ReloadQueueRequestInformation'
import TableSettings from 'components/table_settings/TableSettings'
import { translate } from 'language/Language'
import {
  DATEMASK, LANGUAGE, QUEUE_RELOAD_ACTIVE_TAB, QUEUE_RELOAD_END_DATE, QUEUE_RELOAD_END_TIME, QUEUE_RELOAD_EXTENSION, QUEUE_RELOAD_FORM,
  QUEUE_RELOAD_LASTTIME_MODE, QUEUE_RELOAD_REPORT, QUEUE_RELOAD_START_DATE, QUEUE_RELOAD_START_TIME, QUEUE_RELOAD_STATUS, QUEUE_RELOAD_TIME_CUSTOM_LAST,
  QUEUE_RELOAD_TIME_CUSTOM_UNIT, TABLE_SETTINGS_QUEUE_RELOAD
} from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as QueueUtils from 'utils/QueueUtils'
import { getSortTypes } from 'utils/SortUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

const { isDate, getDate } = DateUtils

class SearchResultReloadQueue extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }

  state = {
    showTableSettingsDialog: false,
    tableHeader: {
      id: '',
      name: '',
      length: '',
      location: ''
    },
    header: this.fillHeaderInformation(),
    currentReport: '',
    showDeleteDialog: false,
    showInfoDialog: false
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    return [
      { rest: REQUEST_QUEUED, translation: 'queue.requestqueued', default: true },
      { rest: DOCUMENT_IMPORT, translation: 'queue.documentimported', default: true },
      { rest: FORM, translation: 'general.form', default: true },
      { rest: EXTENSION, translation: 'general.extension', default: true },
      { rest: REPORT, translation: 'general.report', default: true },
      { rest: PAGES, translation: 'queue.pages', default: true },
      { rest: STATUS, translation: 'queue.status', default: true },
      { rest: USER, translation: 'queue.queuedby', default: true },
      { rest: TOKEN },
      { rest: QUEUE_DATE },
      { rest: QUEUE_TIME },
      { rest: IMPORT_DATE },
      { rest: IMPORT_TIME },
      { rest: DOCUMENT_ID },
      { rest: PRIORITY },
      { rest: ARCHIVE_POINTER }
    ]
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[TABLE_SETTINGS_QUEUE_RELOAD]) {
      let buffer = []
      this.props.preferences[TABLE_SETTINGS_QUEUE_RELOAD].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[TABLE_SETTINGS_QUEUE_RELOAD]) {
      return this.props.preferences[TABLE_SETTINGS_QUEUE_RELOAD].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  /**
   * @description Refreshes the table.
   */
  refreshTable = () => {
    const { getReloadQueues, preferences, datemask } = this.props
    const searchParams = {
      'FORM': preferences[QUEUE_RELOAD_FORM],
      'EXT': preferences[QUEUE_RELOAD_EXTENSION],
      'REPORT': preferences[QUEUE_RELOAD_REPORT],
      'STATUS': preferences[QUEUE_RELOAD_STATUS]
    }

    if (preferences[QUEUE_RELOAD_ACTIVE_TAB] === 0) {
      if (preferences[QUEUE_RELOAD_LASTTIME_MODE] === 0) {
        searchParams['SDATE'] = 'TODAY'
      } else if (preferences[QUEUE_RELOAD_LASTTIME_MODE] === 1) {
        searchParams['SDATE'] = 'YESTERDAY'
      } else if (preferences[QUEUE_RELOAD_LASTTIME_MODE] === 2) {
        searchParams['FROMLAST'] = preferences[QUEUE_RELOAD_TIME_CUSTOM_LAST]
        searchParams['TUNITS'] = preferences[QUEUE_RELOAD_TIME_CUSTOM_UNIT]
      }
    } else if (preferences[QUEUE_RELOAD_ACTIVE_TAB] === 1) {
      const sdate = DateUtils.getDate(datemask, preferences[QUEUE_RELOAD_START_DATE])
      const stime = preferences[QUEUE_RELOAD_START_TIME]
      const edate = DateUtils.getDate(datemask, preferences[QUEUE_RELOAD_END_DATE])
      const etime = preferences[QUEUE_RELOAD_END_TIME]

      searchParams['SDATE'] = DateUtils.getTimeshiftDate(sdate, stime, DateUtils.DDMMYYYY_DOT)
      searchParams['STIME'] = stime !== '' ? DateUtils.getTimeshiftDate(sdate, stime, DateUtils.TIME_DATEMASK) : ''
      searchParams['EDATE'] = DateUtils.getTimeshiftDate(edate, etime, DateUtils.DDMMYYYY_DOT)
      searchParams['ETIME'] = etime !== '' ? DateUtils.getTimeshiftDate(edate, etime, DateUtils.TIME_DATEMASK) : ''
    }

    getReloadQueues(searchParams)
  }

  /**
   * @description Handles the modal dialogs
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { reloadQueues, getReloadQueueDetails } = this.props
    getReloadQueueDetails(
      reloadQueues.data[index][reloadQueues.header.indexOf('TTOKEN')],
      () => this.setState({
        [dialog]: true,
        currentReport: reloadQueues.data[index][reloadQueues.header.indexOf('REPORT')]
      })
    )
  }

  /**
   * @description Deletes a reload queue.
   */
  deleteEntry = () => {
    const { reloadDetails, deleteReloadQueue } = this.props
    const { currentReport } = this.state
    const reloadQueue = {
      FORM: reloadDetails.FORM,
      EXT: reloadDetails.EXT,
      REPORT: currentReport
    }
    deleteReloadQueue(
      reloadDetails.DOCID,
      reloadQueue,
      () => this.setState({ showDeleteDialog: false })
    )
  }

  /**
  * @description gets the index of the header in redux state
  * @param {String} header header name of the header in redux
  */
  headerData = header => this.props.reloadQueues.header.indexOf(header)

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.refreshTable}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType='material'
        iconName='info'
        title={translate('general.information')}
        onClick={() => this.handleDialog(rowIndex, 'showInfoDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />
    ]
  }

  getCleanData = data => {
    const { preferences } = this.props
    const result = []
    const usedHeader = this.getUsedHeader()
    data.forEach(element => {
      let dataBuffer = []
      usedHeader.forEach(h => {
        if (h === QUEUE_DATE) {
          dataBuffer.push(getDate(preferences.DATEMASK, element[this.headerData(QUEUE_DATE)], element[this.headerData(QUEUE_TIME)], false))
        }
        else if (h === QUEUE_TIME) {
          dataBuffer.push(getDate(' ', element[this.headerData(QUEUE_DATE)], element[this.headerData(QUEUE_TIME)]))
        }
        else if (h === IMPORT_DATE) {
          dataBuffer.push(getDate(preferences.DATEMASK, element[this.headerData(IMPORT_DATE)], element[this.headerData(IMPORT_TIME)], false))
        }
        else if (h === IMPORT_TIME) {
          dataBuffer.push(getDate(' ', element[this.headerData(IMPORT_DATE)], element[this.headerData(IMPORT_TIME)]))
        }
        else if (h === STATUS) {
          const found = QueueUtils.getStatus(false).find(e => e.key === element[this.headerData(STATUS)])
          dataBuffer.push(found ? translate(found.translationKey) : element[this.headerData(STATUS)])
        }
        else if (h === REQUEST_QUEUED) {
          dataBuffer.push(getDate(preferences.DATEMASK, element[this.headerData(QUEUE_DATE)], element[this.headerData(QUEUE_TIME)]))
        }
        else if (h === DOCUMENT_IMPORT) {
          dataBuffer.push(getDate(preferences.DATEMASK, element[this.headerData(IMPORT_DATE)], element[this.headerData(IMPORT_TIME)]))
        }
        else {
          const val = element[this.headerData(h)].toString()
          if (val.length === 16 && isDate(val, 'DD.MM.YYYY HH:mm')) {
            return dataBuffer.push(`${val}:00`)
          }
          return dataBuffer.push(val)
        }
      })
      result.push(dataBuffer)
    })
    return result
  }

  render = () => {
    const { showTableSettingsDialog, showDeleteDialog, currentReport, showInfoDialog } = this.state
    const { id, drawerExpanded, autoDrawer, reloadQueues, reloadDetails, datemask, lang, keepPagination } = this.props
    const data = reloadQueues && reloadQueues.data ? this.getCleanData(reloadQueues.data) : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: TABLE_SETTINGS_QUEUE_RELOAD }}
          />
        )}
        {showInfoDialog && (
          <ReloadQueueRequestInformation
            id={`${id}_reloadrequestinfo`}
            onClose={() => this.setState({ showInfoDialog: false })}
          />
        )}
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_deletereloadqueuedialog`}
            title={translate('queue.delete_reload_request')}
            question={translate('queue.question_delete_reload_request')}
            onClose={() => this.setState({ showDeleteDialog: false })}
            onDelete={() => this.deleteEntry()}>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_request_queued_key_text`}>
                  {translate('queue.requestqueued')}
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_request_queued_value_text`}>
                  {getDate(datemask, reloadDetails.RLDDATE, reloadDetails.RLDTIME.substring(0, 8))}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_queued_by_key_text`}>
                  {translate('queue.queuedby')}
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_queued_by_value_text`}>
                  {reloadDetails.RLDUSER}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_form_key_text`}>
                  {translate('general.form')}
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_form_value_text`}>
                  {reloadDetails.FORM}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_extension_key_text`}>
                  {translate('general.extension')}
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_extension_value_text`}>
                  {reloadDetails.EXT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_report_key_text`}>
                  {translate('general.report')}
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_report_value_text`}>
                  {currentReport}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if users not exist in redux
            reloadQueues
              ? (
                // show empty result if there are no users after searching
                reloadQueues.data
                  ? (
                    <DataTable
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={data}
                      columnSortDefs={getSortTypes(data, translatedHeaders)}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={rowIndex => this.handleDialog(rowIndex, 'showInfoDialog')}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      selectable
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptySearchResult
                      id={`${id}_emptysearchresult`}
                      description={translate('general.no_data_found')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )}
        </ResultContainer>
      </>
    )
  }
}

const TOKEN = 'TTOKEN'
const USER = 'RLDUSER'
const PAGES = 'PAGES'
const QUEUE_DATE = 'RLDDATE'
const QUEUE_TIME = 'RLDTIME'
const IMPORT_DATE = 'B93DATE'
const IMPORT_TIME = 'B93TIME'
const DOCUMENT_ID = 'DOCID'
const PRIORITY = 'RLDPRTY'
const ARCHIVE_POINTER = 'ARCPTR'
const FORM = 'FORM'
const EXTENSION = 'EXT'
const REPORT = 'REPORT'
const STATUS = 'STATUS'
const REQUEST_QUEUED = 'REQUEST_QUEUED'
const DOCUMENT_IMPORT = 'DOCUMENT_IMPORT'

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    reloadQueues: state.queue.reload.reloads,
    lang: state.auth.serverdata.preferences[LANGUAGE],
    datemask: state.auth.serverdata.preferences[DATEMASK],
    reloadDetails: state.queue.reload.reloadDetails,
    keepPagination: state.queue.reload.keepPagination
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getReloadQueues: (searchParams, callback) => getReloadQueues(searchParams, callback)(dispatch),
    getReloadQueueDetails: (ttoken, callback) => getReloadQueueDetails(ttoken, callback)(dispatch),
    deleteReloadQueue: (docid, reloadQueue, callback) => deleteReloadQueue(docid, reloadQueue, callback)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultReloadQueue)