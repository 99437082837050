import * as UserActions from '../actions/UserActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  users: undefined,
  user: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* **********************************************************************************  */
    // Create user actions
    case UserActions.CREATE_USER_START:
      return { ...state }
    case UserActions.CREATE_USER_SUCCESS:
      return { ...state }
    case UserActions.CREATE_USER_FAILED:
      return { ...state }
    /* **********************************************************************************  */
    // Get user actions
    case UserActions.GET_USER_FOR_PREFERENCES_START:
      // used for clearing when preferences are tried to load
      return { ...state, user: undefined }
    case UserActions.GET_USER_START:
      return { ...state, user: undefined }
    case UserActions.GET_USER_SUCCESS:
      return { ...state, user: action.payload }
    case UserActions.GET_USER_FAILED:
      return { ...state }
    /* **********************************************************************************  */
    // Get users actions
    case UserActions.GET_USERS_START:
      return { ...state }
    case UserActions.GET_USERS_SUCCESS:
      return { ...state, users: action.payload, keepPagination: action.keepPagination }
    case UserActions.GET_USERS_FAILED:
      return { ...state }
    case UserActions.NO_USERS_FOUND:
      return { ...state, users: {} }
    /* **********************************************************************************  */
    // Delete user actions
    case UserActions.DELETE_USER_START:
      return { ...state }
    case UserActions.DELETE_USER_SUCCESS:
      return { ...state }
    case UserActions.DELETE_USER_FAILED:
      return { ...state }
    /* **********************************************************************************  */
    // Update user actions
    case UserActions.UPDATE_USER_START:
      return { ...state }
    case UserActions.UPDATE_USER_SUCCESS:
      return { ...state }
    case UserActions.UPDATE_USER_FAILED:
      return { ...state }
    /* **********************************************************************************  */
    // Clone user actions
    case UserActions.CLONE_USER_START:
      return { ...state }
    case UserActions.CLONE_USER_SUCCESS:
      return { ...state }
    case UserActions.CLONE_USER_FAILED:
      return { ...state }
    /* **********************************************************************************  */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, users: undefined, user: undefined }
    /* **********************************************************************************  */
    default: return state
  }
}

export default reducer