import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as SortUtils from 'utils/SortUtils'

// Components
import { Column, DataTable, DownloadWrapper, EmptyResult, Link, NoSearch, ResultContainer, Row, TableButton } from 'BetaUX2Web-Components/src/'
import CreateJobgroupDialog from 'components/dialogs/create_jobgroup_dialog/CreateJobgroupDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyJobgroupDialog from 'components/dialogs/modify_jobgroup_dialog/ModifyJobgroupDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as JobgroupsDefinitionActions from 'redux/actions/JobgroupsDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

const JOBGROUP_NAME = 'JGIGNAME'
const TITLE = 'JGITITLE'
const ARCHIVE_RETENTION = 'ARCRETPD'
const ONLINE_RETENTION = 'ONLRETPD'
const MEDIA = 'ARCHMED'
const OWNER = 'OWNER'
const TYPE = 'CTYPE'
const USER = 'CUSER'
const LAST_CHANGED = 'LAST_CHANGED'
const CDATE = 'CDATE'
const CTIME = 'CTIME'

class SearchResultJobgroups extends Component {
  state = {
    showTableSettingsDialog: false,
    showCreateDialog: false,
    showModifyDialog: false,
    showDeleteDialog: false,
    header: this.fillHeaderInformation()
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    return [
      { rest: JOBGROUP_NAME, translation: 'general.jobgroup_name', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: ARCHIVE_RETENTION, translation: 'documentinformation.retentiontab_archiveretention', default: true },
      { rest: ONLINE_RETENTION, translation: 'documentinformation.onlineretention', default: true },
      { rest: MEDIA, translation: 'general.media', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: TYPE, translation: 'general.type', default: true },
      { rest: USER, translation: 'server_activity.dependencies_user', default: true },
      { rest: LAST_CHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description refreshes the table
   */
  handleRefresh = () => {
    const { preferences, getJobgroups } = this.props
    const searchObj = {
      JGIGNAME: preferences[Preferences.JOBGROUPS_DEFINITION_JOBGROUP_NAME],
      OWNER: preferences[Preferences.JOBGROUPS_DEFINITION_TITLE],
      JGITITLE: preferences[Preferences.JOBGROUPS_DEFINITION_OWNER],
      ARCHMED: preferences[Preferences.JOBGROUPS_DEFINITION_ARCHIVE_MEDIA]
    }
    getJobgroups(searchObj)
  }

  /**
   * @description gets the index of the header in redux state documents.header
   * @param {String} header header name of the header in redux state documents.header
   */
  headerData = header => this.props.jobgroups.header.indexOf(header)

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_JOBGROUPS]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_JOBGROUPS].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_JOBGROUPS]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_JOBGROUPS].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to show.
   * @param {Array} header The headers of the table.
   * @returns {Array} The column sort definitions.
   */
  getColumnSortDefs = (data, header) => {
    return SortUtils.getSortTypes(data, header.length)
  }

  /**
   * @description Handles the opening of the create/modify dialog.
   */
  handleDialog = (index, dialog) => {
    const { getJobgroup, jobgroups } = this.props
    const searchObj = { JGIGNAME: jobgroups.data[index][this.headerData('JGIGNAME')] }
    getJobgroup(searchObj, () => this.setState({ [dialog]: true }))
  }

  /**
   * @description Deletes a jobgroup.
   */
  deleteJobgroup = () => {
    const { deleteJobgroup, jobgroup } = this.props
    deleteJobgroup(jobgroup.JGIGNAME, () => this.setState({ showDeleteDialog: false }))
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType={'material'}
        iconName={'delete'}
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />
    ]
  }

  /**
   * @description Translates the given media from backend.
   * @param {String} media
   */
  translateMedia = media => {
    const el = DefinitionUtils.archiveMediaItems(false).find(d => d.key === media)
    if (el) {
      return translate(el.translationKey)
    }
    return media
  }

  /**
   * @description Translates the given type from backend.
   * @param {String} type
   */
  translateType = type => {
    const el = DefinitionUtils.DOCUMENT_DEFINITION_TYPES.find(d => d.key === type)
    if (el) {
      return translate(el.translationKey)
    }
    return type
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   * @returns {Array} The clean data.
   */
  getCleanData = () => {
    const { datemask, jobgroups } = this.props
    let data = []
    let headers = this.getUsedHeader()
    jobgroups.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === LAST_CHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData(CDATE)], element[this.headerData(CTIME)].substring(0, 8)))
        }
        else if (header === MEDIA) {
          dataBuffer.push(this.translateMedia(element[this.headerData(MEDIA)]) === ''
            ? translate('general.none')
            : this.translateMedia(element[this.headerData(MEDIA)]))
        }
        else if (header === TYPE) {
          dataBuffer.push(this.translateType(element[this.headerData(TYPE)]))
        }
        else {
          const val = element[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader.
   * @returns {Array} The interaction buttons of the table above the headers.
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.showPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={() => this.handleRefresh()}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  showPrefilledCreateDialog = () => {
    const { preferences } = this.props
    const obj = {
      JGIGNAME: preferences[Preferences.JOBGROUPS_DEFINITION_JOBGROUP_NAME],
      OWNER: preferences[Preferences.JOBGROUPS_DEFINITION_TITLE],
      JGITITLE: preferences[Preferences.JOBGROUPS_DEFINITION_OWNER],
    }
    this.setState({ showCreateDialog: true, createDialogData: obj })
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, drawerExpanded, autoDrawer, jobgroups, jobgroup, datemask, lang, keepPagination } = this.props
    const { showTableSettingsDialog, showDeleteDialog, showCreateDialog, showModifyDialog } = this.state
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    const data = jobgroups && jobgroups.data ? this.getCleanData() : null
    return (
      <>
        {
          showCreateDialog &&
          <CreateJobgroupDialog
            id={`${id}_create_jobgroup`}
            prefilledData={this.state.createDialogData}
            onClose={() => this.setState({ showCreateDialog: false, createDialogData: undefined })} />
        }
        {
          showModifyDialog &&
          <ModifyJobgroupDialog id={`${id}_modify_jobgroup`} onClose={() => this.setState({ showModifyDialog: false })} />
        }
        {
          showDeleteDialog &&
          <DeleteDialog
            id={`${id}_delete_jobgroup_dialog`}
            title={translate('definition.delete_jobgroup')}
            question={translate('definition.question_delete_jobgroup')}
            visible={showDeleteDialog}
            onClose={() => this.setState({ showDeleteDialog: false })}
            onDelete={() => this.deleteJobgroup()}
          >
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p id={`${id}_jobgroup_name_title`}>{translate('general.jobgroup_name')}:</p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p id={`${id}_jobgroup_name_value`}>{jobgroup.JGIGNAME}</p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p id={`${id}_title_title`}>{translate('general.title')}:</p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p id={`${id}_title_value`}>{jobgroup.JGITITLE}</p>
              </Column>
            </Row>
          </DeleteDialog>
        }
        {
          showTableSettingsDialog &&
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_JOBGROUPS }}
          />
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {
            jobgroups
              ? data
                ? <DataTable
                  id={id}
                  header={translatedHeaders}
                  data={data}
                  cleanData={data}
                  selectable={true}
                  createActionButtons={this.createActionButtons}
                  createTableRowAction={index => this.handleDialog(index, 'showModifyDialog')}
                  columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                  additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                  fillPage={fillPage}
                  translate={key => translate(key)}
                  language={lang}
                  datemask={datemask}
                  keepPagination={keepPagination}
                />
                : <EmptyResult
                  id={`${id}_emptysearchresult`}
                  description={translate('emptyresult.no_jobgroup_result')}
                  link={translate('emptyresult.create_jobgroup_link')}
                  onClick={() => this.showPrefilledCreateDialog()}
                  headline={translate('emptyresult.no_result_headline')}
                />
              : <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
          }
        </ResultContainer>
      </>
    )
  }
}

SearchResultJobgroups.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    keepPagination: state.definitions.jobgroups.keepPagination,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    jobgroups: state.definitions.jobgroups.jobgroups,
    jobgroup: state.definitions.jobgroups.jobgroup
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getJobgroups: (searchObj, callback) => JobgroupsDefinitionActions.getJobgroups(undefined, searchObj, callback)(dispatch),
    getJobgroup: (searchObj, callback) => JobgroupsDefinitionActions.getJobgroup(undefined, searchObj, callback)(dispatch),
    deleteJobgroup: (jobgroupName, callback) => JobgroupsDefinitionActions.deleteJobgroup(jobgroupName, callback)(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultJobgroups)