import React, {Fragment, useEffect} from 'react'
import * as AuthenticationActions from 'redux/actions/AuthenticationActions'
import {useDispatch, useSelector} from 'react-redux'

const MILLIS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60

export const PrivateRouteTimeoutGuard = () => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    const {loggedIn, userid, serverdata} = auth
    if (!(serverdata?.server) || !loggedIn) return

    let timeoutId

    const serverType = serverdata.server.type
    const serverVersion = serverdata.server.version
    const serverPrefs = serverdata.preferences

    const sessionTimeout = serverdata.restconfig?.sessionTimeout

    if (sessionTimeout && loggedIn) {
      timeoutId = setTimeout(() => {
        AuthenticationActions.logout(userid, serverType, serverVersion, serverPrefs)(dispatch)
        clearTimeout(timeoutId)
      }, sessionTimeout * SECONDS_IN_MINUTE * MILLIS_IN_SECOND)
    }

    return () => {
      if (!!timeoutId) clearTimeout(timeoutId)
    }
  }, [auth, dispatch])

  return <Fragment/>
}