import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import NumericSpinner from 'BetaUX2Web-Components/src/components/numeric_spinner/NumericSpinner'
import Switch from 'BetaUX2Web-Components/src/components/switch/Switch'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as ArchivePoolActions from 'redux/actions/ArchivePoolDefinitionActions'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as Utils from 'utils/Utils'

class DefinitionArchivePool extends Component {

  defaultState = {
    archivePoolID: '',
    owner: '',
    title: '',
    retentionPeriod: 0,
    mediaIndex: 0,
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
 * @description Initializes the import fields with the values saved in preferences.
 */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props

    if (preferences) {
      const archivePoolID = preferences[Preferences.DEFINITION_ARCHIVEPOOL_ID] || ''
      const owner = preferences[Preferences.DEFINITION_ARCHIVEPOOL_OWNER] || ''
      const title = preferences[Preferences.DEFINITION_ARCHIVEPOOL_TITLE] || ''
      let retentionPeriod = preferences[Preferences.DEFINITION_ARCHIVEPOOL_RETENTIONPERIOD] || 0
      if (Utils.isString(retentionPeriod)) {
        retentionPeriod = parseInt(retentionPeriod)
      }

      const media = preferences[Preferences.DEFINITION_ARCHIVEPOOL_MEDIA] || DefinitionUtils.ARCHIVEPOOL_MEDIA_ANY
      const mediaIndex = Math.max(DefinitionUtils.ARCHIVEPOOL_MEDIA.findIndex(temp => temp.key === media))

      this.setState({
        archivePoolID,
        owner,
        title,
        retentionPeriod,
        mediaIndex
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   */
  handleInputChanged = (id, value) => {
    this.setState({ [id]: value })
  }

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = event => {
    event.preventDefault()
    const { changePrefs, getArchivePools } = this.props
    const { archivePoolID, owner, title, retentionPeriod, mediaIndex } = this.state
    const media = DefinitionUtils.ARCHIVEPOOL_MEDIA[mediaIndex].key

    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_ARCHIVEPOOL_ID]: archivePoolID,
      [Preferences.DEFINITION_ARCHIVEPOOL_OWNER]: owner,
      [Preferences.DEFINITION_ARCHIVEPOOL_TITLE]: title,
      [Preferences.DEFINITION_ARCHIVEPOOL_RETENTIONPERIOD]: retentionPeriod,
      [Preferences.DEFINITION_ARCHIVEPOOL_MEDIA]: media
    }
    changePrefs(prefsToChange)
    getArchivePools(undefined, archivePoolID, media, retentionPeriod, title, owner)
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleOnReset = () => {
    this.setState(this.defaultState)
  }


  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { archivePoolID, owner, title, retentionPeriod, mediaIndex } = this.state
    const { id } = this.props

    return (
      <div
        id={`${id}_main_archive_pool`}
        className={'bux_drawer_main'}>
        <Card
          title={translate('general.general')}>
          <Row>
            <Column
              colMD={6}>
              <Input
                id={`${id}_archive_pool_id`}
                onInputChanged={val => this.handleInputChanged('archivePoolID', val)}
                value={archivePoolID}
                title={translate('definition.archive_pool_id')}
                maxLength={8}
              />
            </Column>
            <Column
              colMD={6}>
              <Input
                id={`${id}_archive_pool_owner`}
                onInputChanged={val => this.handleInputChanged('owner', val)}
                value={owner}
                title={translate('general.owner')}
                maxLength={8}
              />
            </Column>
          </Row>
          <Row>
            <Column
              colMD={12}>
              <Input
                id={`${id}_archive_pool_title`}
                onInputChanged={val => this.handleInputChanged('title', val)}
                value={title}
                title={translate('general.title')}
                maxLength={80}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={6}>
              <NumericSpinner
                id={`${id}_archive_pool_retention_period`}
                title={translate('general.retention_period')}
                value={retentionPeriod}
                onChange={val => this.handleInputChanged('retentionPeriod', val)}
                min={0}
                max={30000}
                steps={1}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Switch
                id={`${id}_archive_pool_media`}
                title={translate('general.media')}
                items={[
                  translate('general.any'),
                  translate('general.tape'),
                  translate('general.disk'),
                  translate('general.optical_disk'),
                ]}
                onClick={index => this.handleInputChanged('mediaIndex', index)}
                activeIndex={mediaIndex}
                maxPerRow={2}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleOnReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={() => { }}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getArchivePools: (fields, id, media, retentionPeriod, title, owner, callback) => {
      ArchivePoolActions.getArchivePools(fields, id, media, retentionPeriod, title, owner, callback)(dispatch)
    },
    changePrefs: prefs => {
      PreferenceActions.changePrefs(prefs)(dispatch)
    }
  }
}

DefinitionArchivePool.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionArchivePool)