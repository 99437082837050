import { translate } from 'language/Language'
import { Component } from 'react'

import {
  Button,
  Column, Dropdown, Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row,
  Tab,
  Tabs, TitledLabel
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'

import * as DateUtils from 'utils/DateUtils'
const { getDate } = DateUtils
class UC4jobInformationDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  state = {
    generalTab: {
      title: '',
      archiveKey1: '',
      archiveKey2: '',
      owner: ''
    },
    retentionTab: {
      onlineRetention: {
        value: 0,
        dropdown: 0
      },
      archiveRetention: {
        value: 0,
        dropdown: 0
      }
    }
  }

  componentDidMount = () => {
    const { uc4JobDetails } = this.props
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        title: uc4JobDetails.TITLE,
        archiveKey1: uc4JobDetails.ARCHKEY1,
        archiveKey2: uc4JobDetails.ARCHKEY2,
        owner: uc4JobDetails.OWNER
      },
      retentionTab: {
        ...this.state.retentionTab,
        onlineRetention: {
          value: uc4JobDetails.ONLRETPD,
          dropdown: { 'DAYS': 0, 'REF': 1, 'GEN': 2 }[uc4JobDetails.CTYPE] || 0
        },
        archiveRetention: {
          value: uc4JobDetails.ARCRETPD,
          dropdown: { '': 0, 'TAPE': 0, 'DISK': 1, 'ODISK': 2 }[uc4JobDetails.ARCHMED] || 0
        }
      }
    })
  }

  /**
   * @description Handles the general tab inputs
   * @param {String} key
   * @param {String} value
   */
  handleGeneralTab = (key, value) => this.setState({ generalTab: { ...this.state.generalTab, [key]: value } })

  /**
   * @description Handles the retention tab inputs
   * @param {String} key
   * @param {String} value
   */
  handleRetentionTab = (key, key2, value) => {
    this.setState({
      retentionTab: {
        ...this.state.retentionTab,
        [key]: {
          ...this.state[key],
          [key2]: value
        }
      }
    })
  }

  renderHeader = () => {
    const { id, uc4JobDetails, datemask } = this.props

    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('general.jobname'), value: uc4JobDetails.JOBNAME },
          { label: translate('general.client'), value: uc4JobDetails.CLNTNAME?.toString() },
          { label: translate('general.run_number'), value: uc4JobDetails.RUNNUMB?.toString() },
          { label: translate('general.jobgroup'), value: uc4JobDetails.JOBGROUP },
          { label: translate('general.system'), value: uc4JobDetails.SYSNAME },
          { label: translate('general.agent'), value: uc4JobDetails.AGNTNAME },
          { label: translate('general.parent_name'), value: uc4JobDetails.PARNAME },
          { label: translate('general.activation_date'), value: `${getDate(datemask, uc4JobDetails.SRCSUBD)} ${uc4JobDetails.SRCSUBT}` },
          { label: translate('general.start_date'), value: `${getDate(datemask, uc4JobDetails.STRTDATE)} ${uc4JobDetails.STRTTIME}` },
          { label: translate('general.end_date'), value: `${getDate(datemask, uc4JobDetails.ENDDATE)} ${uc4JobDetails.ENDTIME}` },
          { label: translate('general.bytes'), value: uc4JobDetails.SRCBYTES },
        ]}
        columns={4}
      />
    )
  }

  renderGeneralTab = () => {
    const { id } = this.props
    const { generalTab } = this.state

    return (
      <>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_generaltab_title`}
              value={generalTab.title}
              title={translate('general.title')}
              maxLength={255}
              onInputChanged={value => this.handleGeneralTab('title', value)}
              disabled
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_generaltab_archivekey1`}
              value={generalTab.archiveKey1}
              title={translate('search.uc4_archive_key_1')}
              maxLength={60}
              onInputChanged={value => this.handleGeneralTab('archiveKey1', value)}
              disabled
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_generaltab_archivekey2`}
              value={generalTab.archiveKey2}
              title={translate('search.uc4_archive_key_2')}
              maxLength={20}
              onInputChanged={value => this.handleGeneralTab('archiveKey2', value)}
              disabled
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_generaltab_owner`}
              value={generalTab.owner}
              title={translate('general.owner')}
              maxLength={16}
              onInputChanged={value => this.handleGeneralTab('owner', value)}
              disabled
            />
          </Column>
        </Row>
      </>
    )
  }

  renderRetentionTab = () => {
    const { id, uc4JobDetails, datemask } = this.props
    const { retentionTab } = this.state

    return (
      <>
        <Row>
          <Column colMD={6}>
            <label id={`${id}_retentiontab_retentions`}>{translate('documentinformation.retentions')}</label>
          </Column>
          <Column colMD={6}>
            <label id={`${id}_retentiontab_additional_information`}>{translate('documentinformation.retentiontab_additionalinformation')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <NumericSpinner
              id={`${id}_retentiontab_online_retention`}
              title={translate('documentinformation.onlineretention')}
              onChange={value => this.handleRetentionTab('onlineRetention', 'value', value)}
              value={retentionTab.onlineRetention.value}
              disabled
              // !JUST FOR PROP TYPES BECAUSE MAX IS REQUIRED. CHANGE WHEN IMPLEMENT.
              max={0}
            />
          </Column>
          <Column colMD={2}>
            <Dropdown
              id={`${id}_retentiontab_online_retention_dropdown`}
              title={' '}
              items={[
                translate('general.days'),
                translate('documentinformation.retentiontab_references'),
                translate('documentinformation.retentiontab_generations')
              ]}
              activeIndex={retentionTab.onlineRetention.dropdown}
              onChange={value => this.handleRetentionTab('onlineRetention', 'dropdown', value)}
              disabled
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${id}_retentiontab_online`}
              title={translate('search.online')}
              text={uc4JobDetails.LGRONLNE ? translate('general.yes') : translate('general.no')}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${id}_retentiontab_online_expiration`}
              title={translate('documentinformation.retentiontab_onlineexpiration')}
              text={document.ONLEXPDT ? DateUtils.getDate(document.datemask, document.ONLEXPDT) : ' '}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${id}_retentiontab_last_access`}
              title={translate('general.last_access')}
              text={getDate(datemask, uc4JobDetails.LREFD)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <NumericSpinner
              id={`${id}_retentiontab_archive_retention`}
              title={translate('documentinformation.retentiontab_archiveretention')}
              onChange={value => this.handleRetentionTab('archiveRetention', 'value', value)}
              value={retentionTab.archiveRetention.value}
              disabled
              // !JUST FOR PROP TYPES BECAUSE MAX IS REQUIRED. CHANGE WHEN IMPLEMENT.
              max={0}
            />
          </Column>
          <Column colMD={2}>
            <Dropdown
              id={`${id}_retentiontab_archive_retention_dropdown`}
              title={' '}
              items={
                retentionTab.archiveRetention.value !== 0
                  ? [translate('general.tape'), translate('general.disk'), translate('general.optical_disk')]
                  : [translate('general.none')]
              }
              activeIndex={retentionTab.archiveRetention.dropdown}
              onChange={value => this.handleRetentionTab('archiveRetention', 'dropdown', value)}
              disabled
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${id}_retentiontab_archived`}
              title={translate('documentinformation.retentiontab_archived')}
              text={uc4JobDetails.LGRARCH ? translate('general.yes') : translate('general.no')}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${id}_retentiontab_archive_expiration`}
              title={translate('documentinformation.retentiontab_archiveexperation')}
              text={getDate(datemask, uc4JobDetails.AEXPDT)}
            />
          </Column>
        </Row>
      </>
    )
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={'uc4job_information_dialog'}
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={translate('general.job_details')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main>
          <Tabs id={id}>
            <Tab title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('documentinformation.retentiontab')}>
              {this.renderRetentionTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}


const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    uc4JobDetails: state.search.uc4.uc4JobDetails
  }
}

export default connect(mapStateToProps)(UC4jobInformationDialog)