import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import { Button, ButtonGroup } from 'BetaUX2Web-Components/src';

import { useDocumentBrowserContext } from '../../contexts/DocumentBrowserContext';
import { useTabContentContext } from '../../contexts/TabContentContext';

import { usePrevious } from 'components/documentViewer/helper/hooks';

import * as Language from 'language/Language';

import * as DocViewerActions from 'redux/actions/DocViewerActions';

import * as TYPES_DOC_BROWSER from '../../types/DocumentBrowser.types';
import * as TYPES_DOC_VIEWER from '../../types/DocumentViewer.types';
import { parseIndexToHighlightMarker } from 'components/documentViewer/helper/utils';

export const DocumentBrowserNavigation = () => {
  const dispatch = useDispatch();

  const { handleInputChange, id, inputValue } = useDocumentBrowserContext() as TYPES_DOC_BROWSER.IDocumentBrowserContext;
  const { handlePageChange, pageNumber } = useTabContentContext() as TYPES_DOC_VIEWER.ITabContentContext;

  const tab: TYPES_DOC_VIEWER.ITabData = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id));
  const pageCount: TYPES_DOC_VIEWER.TPageCount = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id).pageCount) || 0;

  const prevIndexToHighlightKey = usePrevious(parseIndexToHighlightMarker(tab.indexToHighlight));
  const hasIndexHighlight = 'position' in tab.indexToHighlight;

  useEffect(() => {
    if (prevIndexToHighlightKey === parseIndexToHighlightMarker(tab.indexToHighlight)) return; // This will prevent switching the page when the page navigation is used without a change of the index to highlight
    if (!('position' in tab.indexToHighlight) || pageNumber === tab.indexToHighlight.position.page) return; // This will prevent switching the page when the page already is open

    handlePageChange(tab.indexToHighlight.position.page, pageCount);
  }, [pageCount, pageNumber, handlePageChange, prevIndexToHighlightKey, tab.indexToHighlight])

  useEffect(() => {
    if (pageCount === 0) {
      DocViewerActions.getPageCount(tab)(dispatch);
    }
    // Note: useEffect with empty array to trigger on mount/unmount -> gets misinterpreted by ESLint due to function ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //* Todo (CL): Add page navigation for DocViewer to component library
  return (
    <div className={'interactionContainer'} style={{ minHeight: 50 }}>
      <span id={'doc_browser_nav_pages'}>
        {`${Language.translate('general.page')} `}
        <input
          autoComplete="off"
          id={'doc_browser_nav_input'}
          style={{ height: '40px', width: '40px', textAlign: 'center' }}
          name={'doc_browser_nav_input'}
          onBlur={() => handleInputChange(pageNumber.toString())}
          onChange={(event) => handleInputChange(event.target.value)}
          onKeyDown={(event) => event.key === 'Enter' && handlePageChange(parseInt(inputValue), pageCount)}
          placeholder={'0'}
          value={inputValue}
        />
        {` ${Language.translate('general.of')} ${pageCount}`}
      </span>
      <div className={'doc_browser_nav_buttons interactionButtons'}>
        <Button
          id={'doc_browser_nav_interaction_button_begin'}
          onClick={() => handlePageChange(1, pageCount)}
          icon={'chevron_begin'}
          tooltip={`${Language.translate('general.page_first')}`}
          disabled={pageNumber === 1}
        />
        <ButtonGroup>
          <Button
            id={'doc_browser_nav_interaction_button_prev'}
            onClick={() => handlePageChange(pageNumber - 1, pageCount)}
            icon={'chevron_left'}
            tooltip={`${Language.translate('table.prev_page')}`}
            disabled={pageNumber === 1}
          />
          <Button
            id={'doc_browser_nav_interaction_button_next'}
            onClick={() => handlePageChange(pageNumber + 1, pageCount)}
            icon={'chevron_right'}
            tooltip={`${Language.translate('table.next_page')}`}
            disabled={pageNumber === pageCount}
          />
        </ButtonGroup>
        <Button
          id={'doc_browser_nav_interaction_button_end'}
          onClick={() => handlePageChange(pageCount, pageCount)}
          icon={'chevron_end'}
          tooltip={`${Language.translate('general.page_last')}`}
          disabled={pageNumber === pageCount}
        />
      </div>
      {hasIndexHighlight && (
        <Button
          id={'doc_browser_nav_interaction_remove_index_highlight'}
          onClick={() => DocViewerActions.removeIndexHighlight(tab.id)(dispatch)}
          icon={'delete'}
          tooltip={`${Language.translate('documentviewer.remove_index_highlight')}${'position' in tab.indexToHighlight ? tab.indexToHighlight.position.page : ''}`}
        />
      )}
    </div>
  )
};