import moment from 'moment'
import { Component } from 'react'
import { connect } from 'react-redux'

import { Button, Card, Row } from 'BetaUX2Web-Components/src/'
import CustomDialog from 'components/dialogs/custom_dialog/CustomDialog'

import './SearchCustomSearch.scss'


import { translate } from 'language/Language'
import * as CustomDialogActions from 'redux/actions/CustomDialogActions'
import * as CustomSelectionActions from 'redux/actions/CustomSelectionActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
// Utils
import * as DateUtils from 'utils/DateUtils'
import * as UserUtils from 'utils/UserUtils'

const keys = {
  STRING: 'string',
  NUMERIC: 'numeric',
  RNUM: 'numeric',
  AMOUNT: 'amount',
  DATE: 'date',
  DATETIME: 'datetime',
  DATEDATE: 'datedate',
  SCHOICE: 'schoice',
  MCHOICE: 'mchoice',
  CCHOICE: 'cchoice',
  CCHOICE2: 'cchoice2',
  LCHOICE: 'lchoice',
  HCHOICE: 'hchoice',
  DTFRAME: 'dtframe'
}

class SearchCustomSearch extends Component {

  state = {
    defaultState: undefined,
    searchButtonIsDisabled: false
  }

  // This variable will be overwritten when the getState prop was called in the child component to make it possible to access the child components state.
  getCustomDialogState = undefined

  // This variable will be overwritten when the overwriteState prop was called in the child component to make it possible to overwrite the child components state.
  overwriteCustomDialogState = undefined

  componentDidUpdate = () => {
    if (this.hasDocumentNodeSelection() !== this.state.searchButtonIsDisabled) {
      this.setState({ searchButtonIsDisabled: this.hasDocumentNodeSelection() })
    }
  }

  componentWillUnmount = () => {
    // *WIP: See BADM-2238, Cache invalidation requires a major refactoring of the state and request management
    // *At the moment the simplest solution with least amount of side effects is to allow caching within the context of Custom Selection search and resetting the cache when the user switches to a different search mode
    this.props.resetCache()
  }

  renderMain = () => {
    const { id, customDialog, lang } = this.props
    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {
          customDialog?.OBJECTS?.data && customDialog.OBJECTS.data.length > 0
            // Props cannot be overwritten in the child component so we overwrite the variable here with the callback of the callbackoverwrite function.
            // In case of not understanding, contact KEHU (kevin.hubert@betasystems.com).
            ? <CustomDialog
              id={id}
              getState={newFunction => {
                // Always get the new default state when the custom dialog is changing.
                this.setState({ defaultState: newFunction() })
                return this.getCustomDialogState = newFunction
              }}
              overwriteState={newFunction => this.overwriteCustomDialogState = newFunction}
              customDialog={customDialog.OBJECTS.data}
              customDialogInfo={customDialog}
              headerIndex={name => customDialog.OBJECTS.header.indexOf(name)}
              lang={lang}
              scrollContainer={'drawer_content_search_body_main'}
              updateParent={() => this.forceUpdate()}
            />
            : <Card>
              <Row>
                <div className='bux_no_customsearch_drawer_container'>
                  <p className='bux_no_customsearch_drawer_description'>
                    {translate('general.no_customdialog_defined')}
                  </p>
                  {
                    UserUtils.isAdmin() &&
                    <a className='bux_no_customsearch_drawer_description_link'
                      href="/definition/customdialog">
                      {translate('general.no_customdialog_create')}
                    </a>
                  }
                </div>
              </Row>
            </Card>
        }
      </div>
    )
  }

  buildSingleEntry = (key, value) => {
    let result = {}
    if ([keys.STRING, keys.NUMERIC, keys.CCHOICE, keys.HCHOICE, keys.DATE].includes(key)) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        result = { ...result, [el.restKey]: el.value }
      }
    }
    else if (key === keys.AMOUNT) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        result = { ...result, [el.input.restKey]: el.input.value, [el.dropdown.restKey]: el.dropdown.dbValues[el.dropdown.value] }
      }
    }
    else if ([keys.LCHOICE, keys.SCHOICE].includes(key)) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        // result = { ...result, [el.restKey]: ['-', 'A'].includes(el.dbValues[el.value]) ? '' : el.dbValues[el.value] }
        result = { ...result, [el.restKey]: el.dbValues[el.value] }
      }
    }
    else if (key === keys.DATETIME) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        result = {
          ...result,
          [el.date.restKey]: el.date.value !== ''
            ? DateUtils.getTimeshiftDate(el.date.value, el.time.value, DateUtils.DDMMYYYY_DOT)
            : '',
          [el.time.restKey]: el.date.value !== ''
            ? DateUtils.getTimeshiftDate(el.date.value, el.time.value, DateUtils.TIME_DATEMASK)
            : ''
        }
      }
    }
    else if (key === keys.DATEDATE) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        result = {
          ...result,
          [el.from.restKey]: el.from.value !== ''
            ? DateUtils.getTimeshiftDate(el.from.value, '', DateUtils.DDMMYYYY_DOT)
            : '',
          [el.to.restKey]: el.to.value !== ''
            ? DateUtils.getTimeshiftDate(el.to.value, '', DateUtils.DDMMYYYY_DOT)
            : ''
        }
      }
    }
    else if (key === keys.CCHOICE2) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        result = { ...result, 'FORM': el.form.value, 'EXTENSION': el.ext.value }
      }
    }
    else if (key === keys.MCHOICE) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        let valueBuilder = ''
        for (const [type, checked] of Object.entries(el)) {
          if (typeof checked === 'boolean' && checked) {
            valueBuilder += `${type}`
          }
        }
        result = { ...result, [el.restKey]: valueBuilder }
      }
    }
    else if (key === keys.DTFRAME) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        if (el.tabIndex === 0) {
          if (el.lastTimeModeIndex === 0) {
            result = { ...result, 'SDATE': 'TODAY' }
          }
          else if (el.lastTimeModeIndex === 1) {
            result = { ...result, 'SDATE': 'YESTERDAY' }
          }
          else {
            result = { ...result, 'FROMLAST': el.customLast, 'TUNITS': { 0: 'M', 1: 'H', 2: 'D' }[el.customUnitIndex] }
          }
        }
        else {
          result = {
            ...result,
            'SDATE': DateUtils.getTimeshiftDate(el.fromDate.value, el.fromTime.value, DateUtils.DDMMYYYY_DOT),
            'STIME': el.fromTime.value !== '' ? DateUtils.getTimeshiftDate(el.fromDate.value, el.fromTime.value, DateUtils.TIME_DATEMASK) : '',
            'EDATE': DateUtils.getTimeshiftDate(el.toDate.value, el.toTime.value, DateUtils.DDMMYYYY_DOT),
            'ETIME': el.toTime.value !== '' ? DateUtils.getTimeshiftDate(el.toDate.value, el.toTime.value, DateUtils.TIME_DATEMASK) : ''
          }
        }
      }
    }
    return result
  }

  buildSingleEntrySELGBL = (type, value) => {
    let result = {}

    if (!value) {
      // TODO: Add some exception haandling for undefined value.
    }

    if ([keys.STRING, keys.NUMERIC, keys.CCHOICE, keys.HCHOICE, keys.DATE].includes(type)) {
      result = { ...result, [value.restKey]: value.value }
    }
    else if (type === keys.AMOUNT) {
      result = { ...result, [value.input.restKey]: value.input.value, [value.dropdown.restKey]: value.dropdown.dbValues[value.dropdown.value] }
    }
    else if ([keys.LCHOICE, keys.SCHOICE].includes(type)) {
      result = { ...result, [value.restKey]: value.dbValues[value.value] }
    }
    else if (type === keys.DATETIME) {
      result = {
        ...result,
        [value.date.restKey]: value.date.value !== ''
          ? DateUtils.getTimeshiftDate(value.date.value, value.time.value, DateUtils.DDMMYYYY_DOT)
          : '',
        [value.time.restKey]: value.date.value !== ''
          ? DateUtils.getTimeshiftDate(value.date.value, value.time.value, DateUtils.TIME_DATEMASK)
          : ''
      }
    }
    else if (type === keys.DATEDATE) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        result = {
          ...result,
          [el.from.restKey]: el.from.value !== ''
            ? DateUtils.getTimeshiftDate(el.from.value, '', DateUtils.DDMMYYYY_DOT)
            : '',
          [el.to.restKey]: el.to.value !== ''
            ? DateUtils.getTimeshiftDate(el.to.value, '', DateUtils.DDMMYYYY_DOT)
            : ''
        }
      }
    }
    else if (type === keys.CCHOICE2) {
      result = { ...result, [value.form.restKey]: value.form.value, [value.ext.restKey]: value.ext.value }
    }
    else if (type === keys.MCHOICE) {
      let valueBuilder = ''
      for (const [type, checked] of Object.entries(value)) {
        if (typeof checked === 'boolean' && checked) {
          valueBuilder += `${type}`
        }
      }
      result = { ...result, [value.restKey]: valueBuilder }

    }
    else if (type === keys.DTFRAME) {
      if (value.tabIndex === 0) {
        if (value.lastTimeModeIndex === 0) {
          result = { ...result, 'SDATE': 'TODAY' }
        }
        else if (value.lastTimeModeIndex === 1) {
          result = { ...result, 'SDATE': 'YESTERDAY' }
        }
        else {
          result = { ...result, 'FROMLAST': value.customLast, 'TUNITS': { 0: 'M', 1: 'H', 2: 'D' }[value.customUnitIndex] }
        }
      }
      else {
        result = {
          ...result,
          'SDATE': DateUtils.getTimeshiftDate(value.fromDate.value, value.fromTime.value, DateUtils.DDMMYYYY_DOT),
          'STIME': value.fromTime.value !== '' ? DateUtils.getTimeshiftDate(value.fromDate.value, value.fromTime.value, DateUtils.TIME_DATEMASK) : '',
          'EDATE': DateUtils.getTimeshiftDate(value.toDate.value, value.toTime.value, DateUtils.DDMMYYYY_DOT),
          'ETIME': value.toTime.value !== '' ? DateUtils.getTimeshiftDate(value.toDate.value, value.toTime.value, DateUtils.TIME_DATEMASK) : ''
        }
      }
    }
    return result
  }

  translateSelGBLKey = key => {
    // Fallback to find potential error on test system.
    try {
      if (key === 'B93EXT') {
        return 'EXTENSION'
      }
      else if (key && key.startsWith('B93')) {
        return key.replace('B93', '')
      }
      return key
    }
    catch (e) {
      console.error(e, key)
      return key
    }
  }

  buildRequestObj = state => {
    const { customDialog, datemask } = this.props
    let result = {}
    if (customDialog[4] && customDialog[4] > 0) {
      result['MAXENTRY'] = customDialog[4]
    }

    const IDXARGS = []
    if (customDialog[2] === 'SELGBL') {
      for (const [key, value] of Object.entries(state)) { // Iterate through state of CustomDialogs
        if (!['currentModalSelectorInformation'].includes(key) && Object.keys(value).length > 0) {
          // eslint do not like modifying outside declared variable
          // eslint-disable-next-line
          Object.values(value).forEach(selGBLValue => { // Iterate through all STRINGS, NUMBERS step by step
            if (selGBLValue.restKey && !selGBLValue.restKey?.startsWith('B93')) {
              if (selGBLValue.value?.length !== 0) {
                const foundIndex = customDialog.OBJECTS.data.find(d => d[1] === selGBLValue.restKey)
                const operator = foundIndex && foundIndex[3]
                const type = foundIndex && foundIndex[13]
                const val1 = type === 'DATE' ? DateUtils.getRequestFormat(selGBLValue.value, datemask) : selGBLValue.value
                if (operator === 'EQ') {
                  IDXARGS.push([selGBLValue.restKey, 'EQ', val1])
                }
                else if (operator === 'RA') {
                  IDXARGS.push([selGBLValue.restKey, 'GE', val1])
                  const val2 = type === 'DATE' ? DateUtils.getRequestFormat(selGBLValue.value2, datemask) : selGBLValue.value2
                  if (selGBLValue.value2?.length !== 0) {
                    IDXARGS.push([selGBLValue.restKey, 'LT', val2])
                  }
                }
              }
            }
            else {
              // In case of SELGBL we process each key-value pair step by step and so buildSingleEntrySELGBL() won't loop thorugh an array
              const valueToUse = { ...selGBLValue }
              if (selGBLValue.form && selGBLValue.ext) {
                valueToUse.form.restKey = 'FORM'
                valueToUse.ext.restKey = 'EXTENSION'
              }
              else {
                // e.g. B93EXT = EXTENSION
                valueToUse.restKey = this.translateSelGBLKey(valueToUse.restKey)
              }
              result = { ...result, ...this.buildSingleEntrySELGBL(key, valueToUse) }
            }
          })
        }
      }
    }
    else {
      for (const [key, value] of Object.entries(state)) {
        if (!['currentModalSelectorInformation'].includes(key) && Object.keys(value).length > 0) {
          result = { ...result, ...this.buildSingleEntry(key, value) }
        }
      }
    }
    const idxArgsParm = {
      IDXARG: IDXARGS.map(d => {
        return d.join(' ')
      }).join(' AND ')
    }
    result = { ...result, ...idxArgsParm }

    if ((result.SDATE || result.EDATE) && (result.FROMLAST && result.TUNITS)) {
      delete result.FROMLAST
      delete result.TUNITS
    }

    return this.removeEmptyValues(result)
  }

  removeEmptyValues = data => {
    let result = data
    for (const [key, value] of Object.entries(data)) {
      if (['', ' '].includes(value) && key !== 'IDXARG') {
        delete data[key]
      }
    }
    return result
  }

  handleFocus = state => {
    const { customDialog } = this.props
    const headerIndex = name => customDialog.OBJECTS.header.indexOf(name)
    let focused = false
    for (let i = 0; i < customDialog.OBJECTS.data.length; i++) {
      if (!focused) {
        const key = customDialog.OBJECTS.data[i][headerIndex('SPIDTYPE')]
        const currentTypElements = Object.values(state[keys[key]])
        let currentElement
        for (let j = 0; j < currentTypElements.length; j++) {
          if ([keys.STRING, keys.NUMERIC, keys.DATE, keys.CCHOICE, keys.DTFRAME].includes(keys[key])) {
            if (currentTypElements[j].restKey === customDialog.OBJECTS.data[i][headerIndex('IXINAME')]) {
              currentElement = currentTypElements[j]
              if (currentElement.error !== '' && currentElement.ref) {
                focused = true
                currentElement.ref.current && currentElement.ref.current.focus()
                break
              }
              if (currentElement.error2 !== '' && currentElement.ref2) {
                focused = true
                currentElement.ref2.current && currentElement.ref2.current.focus()
                break
              }
            }
          }
          else if (keys[key] === keys.DATETIME) {
            if ([currentTypElements[j].date.restKey, currentTypElements[j].time.restKey].includes(customDialog.OBJECTS.data[i][headerIndex('IXINAME')])) {
              currentElement = currentTypElements[j]
              if (currentElement.date.error !== '' && currentElement.date.ref) {
                focused = true
                currentElement.date.ref.current && currentElement.date.ref.current.focus()
              }
              else if (currentElement.time.error !== '' && currentElement.time.ref) {
                focused = true
                currentElement.time.ref.current && currentElement.time.ref.current.focus()
              }
              break
            }
          }
          else if (keys[key] === keys.DATEDATE) {
            if ([currentTypElements[j].from.restKey, currentTypElements[j].from.restKey].includes(customDialog.OBJECTS.data[i][headerIndex('IXINAME')])) {
              currentElement = currentTypElements[j]
              if (currentElement.from.error !== '' && currentElement.from.ref) {
                focused = true
                currentElement.from.ref.current && currentElement.from.ref.current.focus()
              }
              else if (currentElement.to.error !== '' && currentElement.to.ref) {
                focused = true
                currentElement.to.ref.current && currentElement.to.ref.current.focus()
              }
              break
            }
          }
          else if (keys[key] === keys.CCHOICE2) {
            if ([currentTypElements[j].form.restKey, currentTypElements[j].ext.restKey].includes(customDialog.OBJECTS.data[i][headerIndex('IXINAME')])) {
              currentElement = currentTypElements[j]
              if (currentElement.form.error !== '' && currentElement.form.ref) {
                focused = true
                currentElement.form.ref.current && currentElement.form.ref.current.focus()
              }
              else if (currentElement.ext.error !== '' && currentElement.ext.ref) {
                focused = true
                currentElement.ext.ref.current && currentElement.ext.ref.current.focus()
              }
              break
            }
          }
        }
      }
      else {
        break
      }
    }
  }

  errorFound = state => {
    let result = false
    for (const [key, value] of Object.entries(state)) {
      if ([keys.STRING, keys.NUMERIC, keys.DATE, keys.CCHOICE].includes(key)) {
        result = this.hasElementError(value, result, [undefined])
      }
      else if (key === keys.AMOUNT) {
        result = this.hasElementError(value, result, ['input'])
      }
      else if (key === keys.DATETIME) {
        result = this.hasElementError(value, result, ['date', 'time'])
      }
      else if (key === keys.DATEDATE) {
        result = this.hasElementError(value, result, ['from', 'to'])
      }
      else if (key === keys.CCHOICE2) {
        result = this.hasElementError(value, result, ['form', 'ext'])
      }
      else if (key === keys.DTFRAME) {
        result = this.hasElementError(value, result, ['fromDate', 'fromTime', 'toDate', 'toTime'])
      }
    }
    this.handleFocus(state)
    return result
  }

  hasElementError = (entry, result, keys) => {
    // eslint-disable-next-line
    for (const [index, el] of Object.entries(entry)) {
      // eslint-disable-next-line
      keys.forEach(key => {
        if (key !== undefined) {
          if (el[key].error !== '') {
            result = true
          }
        }
        else {
          if (el.error !== '' || (el.error2 && el.error2 !== '')) {
            result = true
          }
        }
      })
    }
    return result
  }


  validateSingleEntry = (state, key, value) => {
    const { datemask } = this.props
    let newEl = { ...state[key] }
    // eslint-disable-next-line
    for (const [index, el] of Object.entries(value)) {
      if ([keys.STRING, keys.NUMERIC, keys.CCHOICE, keys.DATE].includes(key)) {
        if (el.error !== undefined && el.error === '' && el.required) {
          if (el.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], error: translate('general.input_required') } }
          }
        }
        if (el.error2 !== undefined && el.error2 === '' && el.required2) {
          if (el.value2 === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], error2: translate('general.input_required') } }
          }
        }
      }
      else if ([keys.DATEDATE].includes(key)) {
        if (el.from.error !== undefined && el.from.error === '' && el.from.required) {
          if (el.from.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], from: { ...newEl[index].from, error: translate('general.input_required') } } }
          }
        }
        if (el.to.error !== undefined && el.to.error === '' && el.to.required) {
          if (el.to.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], to: { ...newEl[index].to, error: translate('general.input_required') } } }
          }
        }
      }
      else if ([keys.DATETIME].includes(key)) {
        if (el.date.error !== undefined && el.date.error === '' && el.date.required) {
          if (el.date.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], date: { ...newEl[index].date, error: translate('general.input_required') } } }
          }
        }
        if (el.time.error !== undefined && el.time.error === '' && el.time.required) {
          if (el.time.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], time: { ...newEl[index].time, error: translate('general.input_required') } } }
          }
        }
      }
      else if ([keys.CCHOICE2].includes(key)) {
        if (el.form.error !== undefined && el.form.error === '' && el.form.required) {
          // The FORMEXT object is validated together.
          if (el.form.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], form: { ...newEl[index].form, error: translate('general.input_required') } } }
          }
          if (el.ext.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], ext: { ...newEl[index].ext, error: translate('general.input_required') } } }
          }
        }
      }
      else if ([keys.DTFRAME].includes(key)) {
        if (el.fromDate.value !== '' && el.toDate.value !== '') {
          if (DateUtils.isDate(el.fromDate.value, datemask) && DateUtils.isDate(el.toDate.value, datemask)) {
            const fromDate = moment(el.fromDate.value, datemask)
            const toDate = moment(el.toDate.value, datemask)
            if (toDate.isBefore(fromDate)) {
              // Time card translates the error itself.
              newEl = { ...newEl, [index]: { ...newEl[index], toDate: { ...newEl[index].toDate, error: 'statistic.negative_date_difference_error' } } }
            }
          }
        }
      }
    }
    return newEl
  }

  validateElements = (state, callback) => {
    let result = state
    for (const [key, value] of Object.entries(state)) {
      if (!['currentModalSelectorInformation'].includes(key) && Object.keys(value).length > 0) {
        result = { ...result, [key]: { ...this.validateSingleEntry(state, key, value) } }
      }
    }
    this.overwriteCustomDialogState(result, callback)
  }

  handleSubmit = event => {
    event.preventDefault()

    // Check if variable has a function, if not -> no custom dialog was loaded
    if (!this.getCustomDialogState) return

    const customDialogState = this.getCustomDialogState()

    this.validateElements(customDialogState, this.loadResultTable)
  }

  loadResultTable = () => {
    const { customDialog, getResultTable, resetResultTable } = this.props
    const resultTableId = customDialog[3] // Code: SLTINAME

    // Load result table if its defined in the specific custom dialog:
    if (resultTableId !== '') {
      getResultTable(resultTableId, this.loadCustomSearchData)
    } else {
      // WIP: Find use case for this condition
      resetResultTable()
    }
  }

  loadCustomSearchData = () => {
    const { cacheSearchData, changePrefs, customDialog, getControlMJobs, getDocuments, getDocumentsByGlobalIndex, getSyslogJobs, getStonebranchJobs, getUC4Jobs, getZosJobs } = this.props
    const resultTableType = customDialog[7] // Code: SLTITYPE
    const isDocument = UserUtils.isDOCX() || (UserUtils.isLOGX() && resultTableType === 'BRWTAB')
    const isGlobalIndexSearch = customDialog[2] === 'SELGBL'

    const customDialogState = this.getCustomDialogState()
    if (this.errorFound(customDialogState)) return

    const requestObj = this.buildRequestObj(customDialogState)

    changePrefs({ [Preferences.SEARCH_LAST_CUSTOM_SEARCH]: requestObj })

    if (isDocument && isGlobalIndexSearch) {
      getDocumentsByGlobalIndex(requestObj, () => cacheSearchData(customDialogState))
    }

    if (isDocument && !isGlobalIndexSearch) {
      getDocuments(requestObj, () => cacheSearchData(customDialogState))
    }

    if (!isDocument && resultTableType === 'BRWUC4') {
      getUC4Jobs(requestObj, () => cacheSearchData(customDialogState, resultTableType))
    }

    if (!isDocument && resultTableType === 'BRWZOS') {
      getZosJobs(requestObj, () => cacheSearchData(customDialogState, resultTableType))
    }

    if (!isDocument && resultTableType === 'BRWCTM') {
      getControlMJobs(requestObj, () => cacheSearchData(customDialogState, resultTableType))
    }

    if (!isDocument && resultTableType === 'BRWSYSL') {
      getSyslogJobs(requestObj, () => cacheSearchData(customDialogState, resultTableType))
    }

    if (!isDocument && resultTableType === 'BRWSTB') {
      getStonebranchJobs(requestObj, () => cacheSearchData(customDialogState, resultTableType))
    }
  }

  hasDocumentNodeSelection = () => {
    const { customDialog } = this.props
    if (customDialog) {
      const type = customDialog[2]
      if (type === 'IMPORT') {
        return customDialog[6] === 'YES'
      }
      else {
        return customDialog[5] === 'YES'
      }
    }
    return
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props
    let customDialogState
    if (this.getCustomDialogState) {
      customDialogState = this.getCustomDialogState()
    }
    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={event => this.handleSubmit(event)}
          submit
          primary
          disabled={customDialogState && this.state.searchButtonIsDisabled && !customDialogState.documentNodeSelection.selected}
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={() => this.overwriteCustomDialogState(this.state.defaultState)}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className={'bux_drawer_form'}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    customDialog: state.customDialogs.customDialog,
    customDialogs: state.customDialogs.customDialogs,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    resultTable: state.customDialogs.resultTable,
    token: state.auth.serverdata.token,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    cacheSearchData: (customDialogState, resultTableType) => CustomSelectionActions.cacheSearchData(customDialogState, resultTableType)(dispatch),
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch),
    getControlMJobs: (searchObj, callback) => CustomSelectionActions.getControlMJobs(undefined, searchObj, callback)(dispatch),
    getDocuments: (searchParams, callback) => CustomSelectionActions.getDocuments({ searchParams: searchParams, callback: callback })(dispatch),
    getDocumentsByGlobalIndex: (searchParams, callback) => CustomSelectionActions.getDocumentsByGlobalIndex({ searchParams: searchParams, callback: callback })(dispatch),
    getResultTable: (resultTableID, callback) => CustomDialogActions.getResultTable(resultTableID, callback)(dispatch),
    getStonebranchJobs: (searchObj, callback) => CustomSelectionActions.getStonebranchJobs(undefined, searchObj, callback)(dispatch),
    getSyslogJobs: (searchObj, callback) => CustomSelectionActions.getSyslogJobs(undefined, searchObj, callback)(dispatch),
    getUC4Jobs: (searchObj, callback) => CustomSelectionActions.getUC4Jobs({ searchObj: searchObj, callback: callback })(dispatch),
    getZosJobs: (searchObj, callback) => CustomSelectionActions.getZosJobs(undefined, searchObj, callback)(dispatch),
    resetCache: () => CustomSelectionActions.resetCache()(dispatch),
    resetResultTable: () => CustomDialogActions.resetResultTable()(dispatch),
    showSnackbar: (message, type) => SnackbarActions.show(message, type)(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchCustomSearch)