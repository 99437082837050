import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import * as UserUtils from 'utils/UserUtils'

import StatisticPagesDocuments from './statistic_pages_documents/StatisticPagesDocuments'
import StatisticLogx from './statistic_logx/StatisticLogx'

class StatisticBody extends Component {

  getBody() {
    const { id, statisticMode, history } = this.props
    switch (statisticMode) {
      case 0: return
      case 'pagesdocuments': return <StatisticPagesDocuments id={id} history={history} />
      case 'logx': return <StatisticLogx id={id} history={history} />
      default: return <StatisticPagesDocuments id={id} history={history} />
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

StatisticBody.propTypes = {
  id: PropTypes.string.isRequired,
  statisticMode: PropTypes.string,
}

export default StatisticBody