import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './CreateSecurityProfileDialog.scss'


// Components
import {
  Button, Card, Column, Dropdown, Input,
  Modal as ModalComponent, MultiFloatingInput, Row, Switch
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent


// Redux
import { connect } from 'react-redux'
import * as SecurityProfileActions from 'redux/actions/SecurityProfileActions'
import * as Preferences from 'redux/general/Preferences'
import * as SecurityFunctions from 'redux/general/SecurityFunctions'

class CreateSecurityProfileDialog extends Component {

  state = {
    betauser: {
      value: '',
      errorkey: ''
    },
    profileDesc: {
      value: '',
      errorkey: ''
    },
    accessTranslated: [
      translate('security.access_none'),
      translate('security.access_read'),
      translate('security.access_update'),
      translate('security.access_alter')
    ],
    accessOrig: [
      'NONE',
      'READ',
      'UPDATE',
      'ALTER'
    ],
    profilemask: '',
    accessIndex: 0,
    profileCategory: SecurityFunctions.ALL_FUNCTIONS,
    profileEntry: SecurityFunctions.STAR,
    convertToUpper: 0,
  }

  accessInput = React.createRef()

  /**
   * @description Sets the initial focus.
   */
  componentDidMount() {
    this.accessInput.current.focus()
  }

  /**
   * @description Changes the betauser value and it's errorkey.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleOnBetauserChanged(value, error) {
    this.setState({
      betauser: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Changes the profile description value and it's error key.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleOnProfileDescChanged(value, error) {
    this.setState({
      profileDesc: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Changes the profile mask.
   * @param {String} value The new value.
   */
  handleOnProfilemaskChanged(value) {
    this.setState({ profilemask: value })
  }

  /**
   * @description Changes the current access selection.
   * @param {Number} activeIndex The index of the new selection.
   */
  handleOnAccessChanged(activeIndex) {
    this.setState({ accessIndex: activeIndex })
  }

  /**
   * @description Changes the current category selection.
   * @param {Number} activeIndex The index of the new selection.
   */
  handleProfileCategoryChanged(activeIndex) {
    const profileCategory = SecurityFunctions.getCategoryKey(activeIndex)

    this.setState({
      profileCategory: profileCategory,
      profileEntry: SecurityFunctions.getFunctionKey(profileCategory, 0)
    })
  }

  /**
   * @description Changes the current entry selection.
   * @param {Number} activeIndex The index of the new selection.
   */
  handleProfileEntryChanged(activeIndex) {
    const category = this.state.profileCategory
    this.setState({ profileEntry: SecurityFunctions.getFunctionKey(category, activeIndex) })
  }

  /**
   * @description Changes the value of convert to upper.
   * @param {String} value The new value.
   */
  handleOnCheckConvertToUpper = index => {
    this.setState({ convertToUpper: index })
  }

  /**
   * @description Closes this dialog.
   */
  handleOnClose = () => {
    this.props.onClose()
  }

  /**
   * @description Handles the save action.
   */
  handleOnSave = () => {
    // call create request
    const cuser = this.props.userid
    const betauser = this.state.betauser.value
    const profileDesc = this.state.profileDesc.value
    const access = this.state.accessOrig[this.state.accessIndex]

    const profileEntry = this.state.profileEntry
    let concatedProfilemask = SecurityFunctions.getConcatedProfileMask(profileEntry, this.state.profilemask)

    if (this.state.convertToUpper === 0) {
      concatedProfilemask = concatedProfilemask.toUpperCase()
    }

    // declare success callback
    const callback = () => this.props.onClose()

    this.props.createSecurityProfile(cuser, concatedProfilemask, betauser, profileDesc, access, callback)
  }

  render = () => {
    const { id } = this.props
    const {
      profileCategory, profileEntry, accessTranslated, accessIndex, betauser,
      profileDesc, profilemask, convertToUpper
    } = this.state

    return (
      <Modal onClose={this.handleOnClose}
        id={'security_createsecurityprofiledialog_dialog'}
        className='bux_createSecurityProfileModal'>
        <Header
          id={`${id}_header`}
          title={translate('security.create_security_profile')}
          onClose={this.handleOnClose}
        />
        <Main id={`${id}_main`}>
          <Card>
            <Row>
              <Column colMD={6}>
                <Dropdown
                  id={`${id}_main_accessDropdown`}
                  items={accessTranslated}
                  focusRef={this.accessInput}
                  activeIndex={accessIndex}
                  onChange={(activeIndex) => { this.handleOnAccessChanged(activeIndex) }}
                  title={translate('security.access')}
                />
              </Column>
              <Column colMD={6}>
                <Input
                  id={`${id}_main_userOrGroupId`}
                  onInputChanged={(val, error) => { this.handleOnBetauserChanged(val, error) }}
                  value={betauser.value}
                  title={translate('security.user_group_id')}
                  maxLength={8}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <Input
                  id={`${id}_main_profileDescription`}
                  onInputChanged={(val, error) => { this.handleOnProfileDescChanged(val, error) }}
                  value={profileDesc.value}
                  title={translate('general.description')}
                  maxLength={64}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Dropdown
                  id={`${id}_main_profileType`}
                  title={translate('security.profile_type')}
                  generalItems={SecurityFunctions.getTranslatedGeneralCategories()}
                  items={SecurityFunctions.getTranslatedCategories()}
                  activeIndex={SecurityFunctions.indexOfCategory(profileCategory)}
                  onChange={(activeIndex) => this.handleProfileCategoryChanged(activeIndex)} />
              </Column>
              <Column colMD={3}>
                <Dropdown
                  id={`${id}_main_profileEntry`}
                  title={translate('security.profile_entry')}
                  generalItems={SecurityFunctions.getTranslatedGeneralFunctions(profileCategory)}
                  items={SecurityFunctions.getTranslatedFunctions(profileCategory)}
                  activeIndex={SecurityFunctions.indexOfFunction(profileCategory, profileEntry)}
                  onChange={(activeIndex) => this.handleProfileEntryChanged(activeIndex)} />
              </Column>
              <Column colMD={6}>
                <Switch
                  id={`${id}_main_convert`}
                  title={translate('security.convert_to_upper')}
                  items={[translate('general.yes'), translate('general.no')]}
                  onClick={index => this.handleOnCheckConvertToUpper(index)}
                  activeIndex={convertToUpper}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <MultiFloatingInput
                  id={`${id}_main_profileMask`}
                  title={translate('security.profile_mask')}
                  onInputChanged={(val) => this.handleOnProfilemaskChanged(val)}
                  items={SecurityFunctions.getProfilemaskPrefixAndParts(profileEntry).parts}
                  maxLength={64}
                  input={profilemask}
                  translate={key => translate(key)}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_footer`}>
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel')}
            onClick={this.handleOnClose}
          />
          <Button
            id={`${id}_footer_save`}
            text={translate('general.save')}
            onClick={this.handleOnSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

CreateSecurityProfileDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createSecurityProfile: (
      cuser,
      profile,
      betauser,
      profileDesc,
      access,
      callback
    ) => {
      SecurityProfileActions.createSecurityProfile(
        cuser,
        profile,
        betauser,
        profileDesc,
        access,
        callback
      )(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSecurityProfileDialog)