import React from 'react';

// Style
import './ErrorMessage.scss';


interface ErrorMessageProps {
  id: string;
  dataTestId?: string | undefined;
  text: string | undefined;
  className?: string;
}
/**
 * Error component created according to style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
const ErrorMessage = ({ id, dataTestId, className, text }: ErrorMessageProps): JSX.Element => {
  return (
    <>
      {text !== undefined &&
          <div id={id} data-testid={dataTestId} className={'error_message___container'}>
            <div className={`error_message ${className || ''}`}>
              {text}
            </div>
          </div>
      }
    </>
  );
};

export default ErrorMessage;