import { createContext, useContext } from 'react';

import * as TYPES_DRAWER from '../types/Drawer.types';

export const DrawerContext = createContext<Record<string, never> | TYPES_DRAWER.IDrawerContext>({});

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('useDrawerContext must be used within DrawerProvider');
  }

  return context;
};