import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { DownloadWrapper, Link, TableButton } from 'BetaUX2Web-Components/src/'
import UC4jobInformationDialog from 'components/dialogs/uc4job_information_dialog/UC4jobInformationDialog'
import * as SEARCH_CONSTANTS from 'components/drawer/content/search/search_body/search_standard_search/SearchStandardSearch'
import { SelectionTable } from '../common/SelectionTable';
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'

import * as SearchUC4Actions from 'redux/actions/SearchUC4Actions'
import * as DocViewerActions from 'redux/actions/DocViewerActions'
import * as Preferences from 'redux/general/Preferences'

import { EResultTableType, getColumnsObjects, translateHeaderColumns } from 'utils/ColumnUtils'
import * as CommonUtils from 'utils/CommonUtils'
import * as DateUtils from 'utils/DateUtils'
import { SEARCH_TYPE_UC4 } from 'utils/DocViewerUtils'
import * as SortUtils from 'utils/SortUtils'

const JOBNAME = 'JOBNAME'
const ACTIVATION_DATE = 'SRCSUBD'
const ACTIVATION_TIME = 'SRCSUBT'
const JOBERROR = 'JOBERR'
const STATUS = 'UC4STAT'
const LOGSOURCE = 'LOGSRC'
const CLIENT = 'CLNTNAME'
const RUNNUMBER = 'RUNNUMB'
const SYSTEM = 'SYSNAME'
const JOBGROUP = 'JOBGROUP'
const UC4RC = 'UC4RC'
const UC4CPU = 'UC4CPU'
const UC4RRT = 'UC4RRT'
const SRCSUBDT = 'SRCSUBDT'
const STRTDT = 'STRTDT'
const ENDDT = 'ENDDT'

class SearchResultUc4Search extends Component {
  state = {
    showDocumentInformationDialog: false,
    showTableSettingsDialog: false,
    showReloadDialog: false,
    showjobInformationDialog: false,
    header: this.fillHeaderInformation(),
    translatedHeaders: [],
    settingsTranslatedHeaders: [],
  }

  updateHeadersTranslation() {
    const header = this.getUsedHeader()

    translateHeaderColumns(header, EResultTableType.BRWUC4).then(translatedHeaders => {
      this.setState({translatedHeaders})
    })

    translateHeaderColumns(this.state.header.map(v => v.rest), EResultTableType.BRWUC4).then(translatedHeaders => {
      const settingsTranslatedHeaders = translatedHeaders.map((v, index) => {
        return {
          rest: this.state.header[index].rest,
          translated: v
        }
      })
      this.setState({settingsTranslatedHeaders})
    })

  }

  componentDidMount() {
    this.updateHeadersTranslation()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.uc4jobs !== prevProps.uc4jobs) {
      this.setState({ ...this.state, header: this.fillHeaderInformation() })
    }

    const prevHeaders = prevProps.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION]?.displayedHeaders
    const currHeaders = this.props.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION]?.displayedHeaders

    const headersChanged = prevHeaders && currHeaders && prevHeaders !== currHeaders
    const langChanged = prevProps.lang !== this.props.lang

    if (headersChanged || langChanged) this.updateHeadersTranslation()

    if (prevState.header.length === 0 && this.state.header.length !== 0) {
      this.updateHeadersTranslation();
    }
  }
  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    const { uc4jobs } = this.props
    const defaultOrderedHeaders = [SRCSUBDT, STRTDT, ENDDT, JOBNAME, JOBERROR, STATUS, UC4RC, LOGSOURCE,
      CLIENT, RUNNUMBER, SYSTEM, JOBGROUP, UC4CPU, UC4RRT]

    return CommonUtils.hasProperties(uc4jobs) ? getColumnsObjects(uc4jobs.header, defaultOrderedHeaders) : []
  }

  /**
   * @description refreshes the table
   */
  handleRefresh = () => {
    const { preferences, getUC4Jobs } = this.props
    const activeTabIndex = preferences[Preferences.SEARCH_UC4_ACTIVE_TAB]
    let lastTimeMode = preferences[Preferences.SEARCH_UC4_LASTTIME_MODE]
    let sdate = ''
    let stime = ''
    let edate = ''
    let etime = ''
    let customLast = ''
    let customUnit = ''

    if (activeTabIndex === 0) {
      if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_TODAY || lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_YESTERDAY) {
        sdate = preferences[Preferences.SEARCH_UC4_LASTTIME_MODE].toUpperCase()
      } else if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_CUSTOM) {
        customLast = preferences[Preferences.SEARCH_UC4_TIME_CUSTOM_LAST]
        customUnit = preferences[Preferences.SEARCH_UC4_TIME_CUSTOM_UNIT]
      }
    } else if (activeTabIndex === 1) {
      sdate = DateUtils.getRequestFormat(preferences[Preferences.SEARCH_UC4_START_DATE], DateUtils.DDMMYYYY_DOT)
      stime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_UC4_START_TIME])
      edate = DateUtils.getRequestFormat(preferences[Preferences.SEARCH_UC4_END_DATE], DateUtils.DDMMYYYY_DOT)
      etime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_UC4_END_TIME])
    }

    const searchParams = {
      FROMLAST: customLast,
      TUNITS: customUnit,
      SDATE: sdate,
      STIME: stime,
      EDATE: edate,
      ETIME: etime,
      LOGSOURCE: preferences[Preferences.SEARCH_UC4_LOGSOURCE],
      TUSAGE: preferences[Preferences.SEARCH_UC4_USAGE],
      LJOBNAME: preferences[Preferences.SEARCH_UC4_JOBNAME],
      SYSTEMNAME: preferences[Preferences.SEARCH_UC4_SYSTEMNAME],
      RUNNUMBER: preferences[Preferences.SEARCH_UC4_RUN_NUMBER],
      CLIENTNAME: preferences[Preferences.SEARCH_UC4_CLIENT],
      OBJECTTYPE: preferences[Preferences.SEARCH_UC4_OBJECT_TYPE],
      PARENTNAME: preferences[Preferences.SEARCH_UC4_PARENT_NAME],
      UC4RC: preferences[Preferences.SEARCH_UC4_AGENT_NAME],
      ARCHKEY1: preferences[Preferences.SEARCH_UC4_ARCHIVE_KEY_1],
      ARCHKEY2: preferences[Preferences.SEARCH_UC4_ARCHIVE_KEY_2],
      JOBERR: preferences[Preferences.SEARCH_UC4_ONLY_JOB_ERRORS],
    }
    if (preferences[Preferences.SEARCH_UC4_RETURNCODE_INPUT] && preferences[Preferences.SEARCH_UC4_RETURNCODE_INPUT] !== '') {
      searchParams['UC4RC'] = `${preferences[Preferences.SEARCH_UC4_RETURNCODE_SWITCH]}${preferences[Preferences.SEARCH_UC4_RETURNCODE_INPUT]}`
    }
    if (preferences[Preferences.SEARCH_UC4_STATUS_INPUT] && preferences[Preferences.SEARCH_UC4_STATUS_INPUT] !== '') {
      searchParams['UC4STAT'] = `${preferences[Preferences.SEARCH_UC4_STATUS_SWITCH]}${preferences[Preferences.SEARCH_UC4_STATUS_INPUT]}`
    }
    if (preferences[Preferences.SEARCH_UC4_CPU_USAGE_INPUT] && preferences[Preferences.SEARCH_UC4_CPU_USAGE_INPUT] !== '') {
      searchParams['UC4CPU'] = `${preferences[Preferences.SEARCH_UC4_CPU_USAGE_SWITCH]}${preferences[Preferences.SEARCH_UC4_CPU_USAGE_INPUT]}`
    }
    if (preferences[Preferences.SEARCH_UC4_RUNTIME_INPUT] && preferences[Preferences.SEARCH_UC4_RUNTIME_INPUT] !== '') {
      searchParams['UC4RRT'] = `${preferences[Preferences.SEARCH_UC4_RUNTIME_SWITCH]}${preferences[Preferences.SEARCH_UC4_RUNTIME_INPUT]}`
    }
    getUC4Jobs(searchParams)
  }

  /**
   * @description gets the index of the header in redux state documents.header
   * @param {String} header header name of the header in redux state documents.header
   */
  headerData = header => this.props.uc4jobs.header.indexOf(header)

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_UC4]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_UC4].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_UC4]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_UC4].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to show.
   * @param {Array} header The headers of the table.
   * @returns {Array} The column sort definitions.
   */
  getColumnSortDefs = (data, header) => {
    return SortUtils.getSortTypes(data, header.length)
  }

  /**
   * @description Gets specific details of a job
   * @param {Number} rowIndex
   */
  getUC4Details = rowIndex => {
    const { getUC4JobDetails, uc4jobs } = this.props
    const jobname = uc4jobs.data[rowIndex][this.headerData('JOBNAME')]
    const jobObj = {
      LOGSOURCE: 'UC4',
      STARTDATE: uc4jobs.data[rowIndex][this.headerData('STRTDATE')],
      STARTTIME: uc4jobs.data[rowIndex][this.headerData('STRTTIME')],
      RUNNUMBER: uc4jobs.data[rowIndex][this.headerData('RUNNUMB')],
      SYSTEMNAME: uc4jobs.data[rowIndex][this.headerData('SYSNAME')],
    }
    getUC4JobDetails(jobObj, jobname, () => this.setState({ showjobInformationDialog: true }))
  }

  /**
   * @description Handles the download functionality of logs in binary format.
   * @param {Number} rowIndex The current row.
   */
  handleLogDownload = rowIndex => {
    const { uc4jobs, downloadLog } = this.props
    const systemName = uc4jobs.data[rowIndex][this.headerData('SYSNAME')]
    const runNumber = uc4jobs.data[rowIndex][this.headerData('RUNNUMB')]
    let startDate = uc4jobs.data[rowIndex][this.headerData('STRTDATE')]
    let startTime = uc4jobs.data[rowIndex][this.headerData('STRTTIME')]
    const queryParams = {
      LOGSOURCE: 'UC4',
      STARTDATE: startDate,
      STARTTIME: startTime,
      RUNNUMBER: runNumber,
      SYSTEMNAME: systemName
    }

    // Replace all unwanted characters.
    startDate = startDate.replace(/[/.-]/g, '')
    startTime = startTime.replace(/[:]/g, '')

    // Generate name which will be suggested as download-filename
    const filename = [systemName, runNumber, startDate, startTime].filter(entry => entry !== undefined).join('.')
    downloadLog(queryParams, filename)
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  handleDisplayDocument = rowIndex => {
    const { uc4jobs, displayDocument, bwesystemname, bwebaseurl } = this.props
    const systemName = uc4jobs.data[rowIndex][this.headerData('SYSNAME')]
    const runNumber = uc4jobs.data[rowIndex][this.headerData('RUNNUMB')]
    const startDate = uc4jobs.data[rowIndex][this.headerData('STRTDATE')]
    const startTime = uc4jobs.data[rowIndex][this.headerData('STRTTIME')]
    const ukey = uc4jobs.data[rowIndex][this.headerData('UKEY')]
    displayDocument(bwebaseurl, bwesystemname, startDate, startTime, systemName, runNumber, ukey)
  }

  /**
   * @description This function will open all selected rows as tabs in the DocViewer.
   * @param {Array<number>} selectedRowsIndices This array contains the indices of the selected rows.
   */
  openSelectedRowsInDocViewer = (selectedRowsIndices) => {
    selectedRowsIndices.forEach(rowIndex => this.openDocumentViewer(rowIndex))
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewer = (rowIndex) => {
    const { uc4jobs, addTabToDocViewer } = this.props
    const docId = uc4jobs.data[rowIndex][this.headerData('DOCID')]
    const pageCount = parseInt(uc4jobs.data[rowIndex][this.headerData('SRCPAGES')])

    addTabToDocViewer({
      id: docId,
      type: SEARCH_TYPE_UC4,
      header: uc4jobs.header,
      data: uc4jobs.data[rowIndex],
      pageCount: pageCount
    })
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButton_info_${rowIndex}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => this.getUC4Details(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButton_display_${rowIndex}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => this.openDocumentViewer(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButton_download_${rowIndex}`}
        iconType={'material'}
        iconName={'download'}
        title={translate('general.download')}
        onClick={() => this.handleLogDownload(rowIndex)}
      />,
    ]
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   * @returns {Array} The clean data.
   */
  getCleanData = () => {
    const { datemask, uc4jobs } = this.props
    const data = []
    const headers = this.getUsedHeader()
    uc4jobs.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === ACTIVATION_DATE) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData(ACTIVATION_DATE)], el[this.headerData(ACTIVATION_TIME)], false))
        }
        else if (header === ACTIVATION_TIME) {
          buffer.push(DateUtils.getDate(' ', el[this.headerData(ACTIVATION_DATE)], el[this.headerData(ACTIVATION_TIME)].substring(0, 8)))
        }
        else if (header === SRCSUBDT) {
          if (el[this.headerData(SRCSUBDT)] !== '') {
            const [date, time] = el[this.headerData(SRCSUBDT)].split(' ')
            buffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            buffer.push(el[this.headerData(header)])
          }
        }
        else if (header === STRTDT) {
          if (el[this.headerData(STRTDT)] !== '') {
            const [date, time] = el[this.headerData(STRTDT)].split(' ')
            buffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            buffer.push(el[this.headerData(header)])
          }
        }
        else if (header === ENDDT) {
          if (el[this.headerData(ENDDT)] !== '') {
            const [date, time] = el[this.headerData(ENDDT)].split(' ')
            buffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            buffer.push(el[this.headerData(header)])
          }
        }
        else if (header === JOBERROR) {
          buffer.push(el[this.headerData(JOBERROR)] === 'NO' ? translate('general.no') : translate('general.yes'))
        }
        else {
          buffer.push(el[this.headerData(header)])
        }
      })
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader.
   * @returns {Array} The interaction buttons of the table above the headers.
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={() => { this.handleRefresh() }}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={data}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  render = () => {
    const { showTableSettingsDialog, showjobInformationDialog } = this.state
    const { id, drawerExpanded, autoDrawer, keepPagination, uc4jobs } = this.props
    const header = this.getUsedHeader()
    const fillPage = this.getFillPageInfo()
    const data = uc4jobs && uc4jobs.data ? this.getCleanData() : null

    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.settingsTranslatedHeaders.length ? this.state.settingsTranslatedHeaders : this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_SEARCH_UC4 }}
          />
        )}
        {showjobInformationDialog && (
          <UC4jobInformationDialog
            id={`${id}_uc4jobinfo`}
            onClose={() => this.setState({ showjobInformationDialog: false })}
          />
        )}
        <SelectionTable
          id={this.props.id}
          headers={header}
          documents={uc4jobs}
          keepPagination={keepPagination}
          fillPage={fillPage}
          createActionButtons={this.createActionButtons}
          createTableRowAction={this.getUC4Details}
          additionalInteraction={this.createInteractionButtons(data, this.state.translatedHeaders)}
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}
          openDocViewerCallback={this.openSelectedRowsInDocViewer}
          resultTableType={EResultTableType.BRWUC4}
        />
      </>
    )
  }
}

SearchResultUc4Search.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    keepPagination: state.search.uc4.keepPagination,
    uc4jobs: state.search.uc4.uc4Jobs,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    bwesystemname: state.auth.serverdata.bwesystemname,
    bwebaseurl: state.auth.serverdata.bwebaseurl,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUC4Jobs: (searchObj, callback) => SearchUC4Actions.getUC4Jobs(undefined, searchObj, callback)(dispatch),
    getUC4JobDetails: (jobObj, jobname, callback) => SearchUC4Actions.getUC4JobDetails(undefined, jobObj, jobname, callback)(dispatch),
    downloadLog: (queryParams, downloadname, callback) => SearchUC4Actions.downloadLog(queryParams, downloadname, callback)(dispatch),
    displayDocument: (betaviewBaseurl, betaviewFavname, sdate, stime, sysname, runnumber, ukey, callback) => {
      SearchUC4Actions.displayDocument(betaviewBaseurl, betaviewFavname, sdate, stime, sysname, runnumber, ukey, callback)(dispatch)
    },
    addTabToDocViewer: (tabData, callback) => {
      DocViewerActions.addTabToDocViewer(tabData, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultUc4Search)