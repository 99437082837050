import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../icon/Icon'
import Link from '../../link/Link'
import Checkbox from '../../checkbox/Checkbox'

export default class TableHeader extends Component {

  /**
   * @description Compares two arrays and returns, if they are equal.
   * @param array1 First array which should compared.
   * @param array2 Second array which should compared.
   */
  compareArrays = (array1, array2) => {
    let counter = 0
    if (array1.length === array2.length) {
      for (let i = 0; i < array1.length; i++) {
        if (array1[i] === array2[i]) {
          counter++
        }
      }
    }
    else {
      return false
    }
    return counter === array1.length
  }

  renderPlaceholder = () => {
    const { createActionButtons, expandable, data } = this.props
    const buffer = []
    const length = data.length > 0 ? createActionButtons().length + (expandable ? 1 : 0) : 0
    for (let i = 0; i < length; i++) {
      buffer.push(<th key={`placeholder_${i}`} style={{ right: (length - 1 - i) * 40 }} className={'bux_th_placeholder'}><div></div></th>)
    }
    return buffer
  }

  isDescending = (index) => {
    const { sortedCol } = this.props
    if (sortedCol !== undefined) {
      return sortedCol[index]
    }
    else {
      return false
    }
  }

  /**
   * @description Handles the whole table header.
   */
  render = () => {
    // sticky header only works, when all columns fits in the table layout without overflow
    const { header, id, selectable, data, checkAllCheckboxesSelected, handleOnCheckAll,
      createActionButtons, showDragHandle, sortedCol, sorting, checkAllIcons } = this.props
    return (
      <thead>
        <tr id={`${id}_thead`}>
          {
            showDragHandle && data.length > 0 &&
            <th style={{ cursor: 'auto' }} />
          }
          {
            selectable && data.length > 0 &&
            (
              checkAllIcons
                ? (
                  <th className={'bux_check_all_icons'} >
                    <Link
                      id={`${id}_check_all`}
                      iconName={checkAllCheckboxesSelected ? checkAllIcons.unCheck : checkAllIcons.check}
                      onClick={() => handleOnCheckAll(!checkAllCheckboxesSelected)}
                    />
                  </th>
                )
                : (
                  <th className={'bux_check_all'}>
                    <Checkbox
                      id={`${id}_check_all`}
                      value={checkAllCheckboxesSelected}
                      onCheck={isChecked => handleOnCheckAll(isChecked)}
                    />
                  </th>
                )
            )
          }
          {header.map((header, index) => {
            return (
              <th key={`${header}_${index}`} onClick={() => sorting(index)} id={`th_${index}`}>
                <div key={`${header}_${index}_bux_header_container`} className={`bux_header_container ${sortedCol && sortedCol[index] !== undefined ? 'bux_sort_icon' : ''}`}>
                  {sortedCol && sortedCol[index] !== undefined
                    // arrow upwards stands for ascending sorting
                    ? <>
                      <span key={`${header}_${index}_bux_header_label_sorted`} className={'bux_underline bux_header_label_sorted'}>{header}</span>
                      <Icon name={this.isDescending(index) ? 'downwards' : 'upwards'} className={'bux_vertical_align_sort_icon'} />
                    </>
                    : <span key={`${header}_${index}_bux_header_label`} className={'bux_header_label'}>{header}</span>
                  }
                </div>
              </th>
            )
          })}
          {
            createActionButtons &&
            this.renderPlaceholder()
          }
        </tr>
      </thead >
    )
  }
}

TableHeader.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string.isRequired,
  /** Array of string headers which represents names of the columns*/
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Indicates if the header should have sticky behave.
   * Sticky header only works, when all columns fits in the table layout without overflow
   */
  stickyHeader: PropTypes.bool,
  /**
   * Function used to render action buttons.
   * @returns {Element[]} array of action buttons
  */
  createActionButtons: PropTypes.func,
  /**
     * Sorted column as a object
     *
     * Object type:
     *
     * ```js
     * {
     *    number: bool, (true- descending, false: ascending)
     *    ...
     * }
     * ```
     */
  sortedCol: PropTypes.object,
  /** Used to display main checkbox when each row of the DataTable is selectable for e.g. download option in order to select/unselect all.*/
  selectable: PropTypes.bool,
  /** Used when table should have expandable rows. */
  expandable: PropTypes.bool,
  /**
   * Data of the table
   * TODO: it could be just length or indicator if is empty
   */
  data: PropTypes.array,
  /** Indicates if checkbox for selecting/unselecting all is checked*/
  checkAllCheckboxesSelected: PropTypes.bool,
  /**
   * Function to be called on selection or unselection of the checkbox
   * @param {bool} selectedAll
  */
  handleOnCheckAll: PropTypes.func,
  /** Enables visualization of drag handle */
  showDragHandle: PropTypes.bool,
  /**
   * Function to be called on header sorting.
   * @param {number} index Index of the sorted header
   */
  sorting: PropTypes.func,
  /** Object which provide name of the icon for 'checks all' */
  checkAllIcons: PropTypes.shape({ check: PropTypes.string, unCheck: PropTypes.string }),
}