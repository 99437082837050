/**
 * @param {String} value The value to check.
 * @return {Boolean} true if the String is a valid IPv4 mask; false otherwise
 */
export function isIPv4Mask(value) {
  const regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|(\*))(\.|$)){4}$/
  return regex.test(value)
}

/**
 * @param {String} value The value to check.
 * @return {Boolean} true if the String is a valid IPv6 mask; false otherwise
 */
export function isIPv6Mask(value) {
  const regex = /^[\da-f*]{1,4}$/i

  // See https://blogs.msdn.microsoft.com/oldnewthing/20060522-08/?p=31113 and
  // https://4sysops.com/archives/ipv6-tutorial-part-4-ipv6-address-syntax/
  const components = value.split(':')
  if (components.length < 2 || components.length > 8)
    return false
  if (components[0] !== '' || components[1] !== '') {
    // Address does not begin with a zero compression ('::')
    if (!components[0].match(regex)) {
      // Component must contain 1-4 hex characters or *
      return false
    }
  }

  let zeroCompressionOccured = false
  for (let i = 1; i < components.length; ++i) {
    if (components[i] === '') {
      // We're inside a zero compression ('::')
      if (zeroCompressionOccured) {
        // Zero compression can only occur once in an address
        return false
      }
      zeroCompressionOccured = true
    } else if (!components[i].match(regex)) {
      // Component must contain 1-4 hex characters or *
      return false
    }
  }
  return true
}