import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'

// components
import {
  Button,
  Column, Dropdown, Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row, Switch, Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import * as JobgroupsDefinitionActions from 'redux/actions/JobgroupsDefinitionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'

import './ModifyJobgroupDialog.scss'

class ModifyJobgroupDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    generalTab: {
      owner: '',
      title: { value: '', error: '' },
      archiveRetention: 0,
      onlineRetention: 0,
      archiveMedia: 0,
      type: 0
    },
    jobgroupMasksTab: {
      groupMasks: []
    }
  }

  titleInput = React.createRef()

  /**
   * @description Find id in array by the given attribute.
   * @param {Array} array to look for the key.
   * @param {String} attr inside the obejct that we want to retrive the id from.
   * @param {String} value value of the attribute we want to target.
   */
  findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i
      }
    }
    return -1
  }

  /**
   * @description Loads the values from the selected jobgroup.
   */
  componentDidMount = () => {
    const { jobgroup } = this.props
    this.setState({
      generalTab: {
        owner: jobgroup.OWNER,
        title: { value: jobgroup.JGITITLE, error: '' },
        archiveRetention: jobgroup.ARCRETPD,
        archiveMedia: jobgroup.ARCHMED !== ''
          ? this.findWithAttr(DefinitionUtils.archiveMediaItems(false), 'key', jobgroup.ARCHMED)
          : 0,
        onlineRetention: jobgroup.ONLRETPD,
        // If findIndex method returns -1 because the entry was not found, take 0 as default value.
        type: Math.max(DefinitionUtils.JOBGROUP_DEFINITION_TYPES.findIndex(d => d.key === jobgroup.CTYPE), 0)
      },
      jobgroupMasksTab: {
        groupMasks: jobgroup.OBJECTS
          ? jobgroup.OBJECTS.map(d => {
            return {
              filenameOrMask: { value: d.JGMMASK, error: '' },
              exclusive: d.JGMEXCL ? 0 : 1,
              ref: React.createRef()
            }
          })
          : []
      }
    })
    this.titleInput.current && this.titleInput.current.focus()
  }

  /**
   * @description Validates the filter argument id.
   */
  validateInputIfEmpty = key => {
    const { generalTab } = this.state
    if (generalTab[key].value !== '') {
      return {}
    }
    return {
      [key]: {
        ...generalTab[key],
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Handles the input changes of the general tab.
   * @param {String} key The key of the input field.
   * @param {String} value The new value.
   */
  handleGeneralTabInputChanged = (key, value) => {
    const { generalTab } = this.state
    this.setState({
      generalTab: {
        ...generalTab,
        [key]: typeof generalTab[key] === 'object'
          ? {
            value: value,
            error: '',
          }
          : value
      }
    })
  }

  /**
   * @description Handles the input changes of the archive retention.
   * @param {String} value The new value.
   */
  handleGeneralTabArchiveRetentionChange = (value) => {
    const { generalTab } = this.state
    return this.setState({
      generalTab: {
        ...generalTab,
        archiveRetention: value,
        archiveMedia: value === 0 ? 0 : generalTab.archiveMedia,

      }
    })
  }

  /**
   * @description Renders the general tab
   */
  renderGeneralTab = () => {
    const { id } = this.props
    const { generalTab } = this.state

    return (
      <>
        <Row>
          <Column colMD={9}>
            <Input
              id={`${id}_title`}
              ref={this.titleInput}
              value={generalTab.title.value}
              error={generalTab.title.error}
              title={translate('general.title')}
              onInputChanged={val => this.handleGeneralTabInputChanged('title', val)}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateInputIfEmpty('title') } }))}
              maxLength={64}
              required={`${translate('general.required_field')}`}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_owner`}
              value={generalTab.owner}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={val => this.handleGeneralTabInputChanged('owner', val)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_archive_retention`}
              title={translate('documentinformation.retentiontab_archiveretention')}
              value={generalTab.archiveRetention}
              onChange={val => this.handleGeneralTabArchiveRetentionChange(val)}
              min={0}
              max={30000}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_online_retention`}
              title={translate('documentinformation.onlineretention')}
              value={generalTab.onlineRetention}
              onChange={val => this.handleGeneralTabInputChanged('onlineRetention', val)}
              min={0}
              max={9999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_archive_media`}
              title={translate('general.archive_media')}
              items={DefinitionUtils.archiveMediaItems(false).map(d => generalTab.archiveRetention === 0
                ? translate('general.none')
                : translate(d.translationKey))}
              activeIndex={generalTab.archiveMedia}
              onChange={index => this.handleGeneralTabInputChanged('archiveMedia', index)}
              disabled={generalTab.archiveRetention === 0}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_type`}
              title={translate('general.type')}
              items={DefinitionUtils.JOBGROUP_DEFINITION_TYPES.map(d => translate(d.translationKey))}
              activeIndex={generalTab.type}
              onChange={index => this.handleGeneralTabInputChanged('type', index)}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Adds a new group mask entry at the bottom with default values.
   */
  addGroupMask = () => {
    const { jobgroupMasksTab } = this.state
    const el = { filenameOrMask: { value: '', error: '' }, exclusive: 1, ref: React.createRef() }
    this.setState({ jobgroupMasksTab: { ...jobgroupMasksTab, groupMasks: [...jobgroupMasksTab.groupMasks, el] } })
  }

  /**
   * @description Copies a group mask and add it at the end.
   * @param {String} filenameOrMask
   * @param {Number} exclusive
   */
  copyGroupMask = (filenameOrMask, exclusive) => {
    const { jobgroupMasksTab } = this.state
    const el = { filenameOrMask: { value: filenameOrMask.value, error: '' }, exclusive, ref: React.createRef() }
    this.setState({ jobgroupMasksTab: { ...jobgroupMasksTab, groupMasks: [...jobgroupMasksTab.groupMasks, el] } })
  }

  /**
   * @description Deletes a group mask entry.
   * @param {Number} index
   */
  deleteGroupMask = index => {
    const { jobgroupMasksTab } = this.state
    this.setState({ jobgroupMasksTab: { ...jobgroupMasksTab, groupMasks: jobgroupMasksTab.groupMasks.filter((_, i) => i !== index) } })
  }

  /**
   * @description Handles the change of a value inside the jobgroup masks tab.
   * @param {String} key
   * @param {Number} index
   * @param {String} value
   */
  changeGroupMaskValue = (key, index, value) => {
    const { jobgroupMasksTab } = this.state
    let buffer = [...jobgroupMasksTab.groupMasks]
    buffer[index][key] = typeof buffer[index][key] === 'object' ? { value, error: '' } : value
    this.setState({
      jobgroupMasksTab: {
        ...jobgroupMasksTab,
        groupMasks: buffer
      }
    })
  }

  /**
   * @description Validates the filename or mask input field.
   * @param {Number} index
   */
  validateFilenameOrMask = index => {
    const { jobgroupMasksTab } = this.state
    const buffer = jobgroupMasksTab.groupMasks
    if (buffer[index] && buffer[index].filenameOrMask.value === '') {
      buffer[index].filenameOrMask = { value: '', error: translate('general.input_required') }
    }
    return buffer
  }

  /**
   * @description Renders the UI for the group masks tab.
   */
  renderGroupMasks = () => {
    const { id } = this.props
    return this.state.jobgroupMasksTab.groupMasks.map((d, i) => {
      return (
        <Row key={i}>
          <Column colMD={6}>
            <Input
              id={`${id}_file_name_or_mask_${i}`}
              ref={d.ref}
              title={i === 0 && translate('general.file_name_or_mask')}
              value={d.filenameOrMask.value}
              error={d.filenameOrMask.error}
              onInputChanged={value => this.changeGroupMaskValue('filenameOrMask', i, value)}
              onBlur={() => this.setState(state => ({ jobgroupMasksTab: { ...state.jobgroupMasksTab, groupMasks: [...this.validateFilenameOrMask(i)] } }))}
              maxLength={80}
              required={i === 0 ? `${translate('general.required_field')}` : false}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_exclusive_${i}`}
              title={i === 0 ? translate('general.exclusive') : ' '}
              activeIndex={d.exclusive}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.changeGroupMaskValue('exclusive', i, index)}
            />
          </Column>
          <Column colMD={1}>
            <Button
              id={`${id}_copy_group_mask_${i}`}
              className={'bux_move_group_mask_button'}
              icon={'copy'}
              tooltip={translate('general.copy_group_mask')}
              onClick={() => this.copyGroupMask(d.filenameOrMask, d.exclusive)}
            />
          </Column>
          <Column colMD={1}>
            <Button
              id={`${id}_delete_group_mask_${i}`}
              className={'bux_move_group_mask_button'}
              icon={'delete'}
              tooltip={translate('general.delete_group_mask')}
              onClick={() => this.deleteGroupMask(i)}
            />
          </Column>
        </Row>
      )
    })
  }

  /**
   * @description Renders the the formula tab
   */
  renderJobgroupMasksTab = () => {
    const { id } = this.props
    const { jobgroupMasksTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={3}>
            <label id={`${id}_group_masks_label`} className={'bux_center_headline'}>{translate('general.group_masks')}</label>
          </Column>
          <Column colMD={3} offsetMD={6}>
            <div className={'bux_place_add_group_mask_btn'}>
              {
                <Button
                  id={`${id}_add_group_mask`}
                  icon={'add'}
                  tooltip={translate('general.add_group_mask')}
                  onClick={() => this.addGroupMask()}
                />
              }
            </div>
          </Column>
        </Row>
        {jobgroupMasksTab.groupMasks.length > 0 && this.renderGroupMasks()}
      </>
    )
  }

  /**
   * @description Validates the general tab.
   * @returns {Boolean} False if there is an error in that tab.
   */
  validateGeneralTab = () => {
    const validatorResult = { ...this.validateInputIfEmpty('title') }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ generalTab: { ...this.state.generalTab, ...validatorResult } }, () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input with an error in general tab.
   */
  handleFocusGeneralTab = () => {
    const { generalTab } = this.state
    const requiredInputs = [{ inputRef: this.titleInput, errorkey: generalTab.title.error }]
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].errorkey !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  /**
   * @description Validates the group masks inside the jobgroup masks tab.
   */
  validateGroupMasks = () => {
    const { jobgroupMasksTab } = this.state
    let buffer = [...jobgroupMasksTab.groupMasks]
    let error = false
    buffer.forEach(d => {
      if (d.filenameOrMask.value === '') {
        d.filenameOrMask = { value: '', error: translate('general.input_required') }
        error = true
      }
    })
    if (error) {
      return buffer
    }
    return []
  }

  /**
   * @description Validates the required fields in the jobgroup masks tab.
   */
  validateJobgroupMasksTab = () => {
    const validatorResult = this.validateGroupMasks()
    const errors = validatorResult.length
    if (errors > 0) {
      this.setState({ jobgroupMasksTab: { ...this.state.jobgroupMasksTab, groupMasks: validatorResult } }, () => {
        this.handleFocusJobgroupMasksTab()
      })
    }
    return errors === 0
  }

  /**
 * @description Tries to focus the next input with an error in jobgroup masks tab.
 */
  handleFocusJobgroupMasksTab = () => {
    const { jobgroupMasksTab } = this.state
    const requiredInputs = jobgroupMasksTab.groupMasks.map(d => {
      return { inputRef: d.ref, errorkey: d.filenameOrMask.error }
    })
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].errorkey !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }


  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { updateJobgroup, onClose, jobgroup } = this.props
    const { generalTab, jobgroupMasksTab } = this.state

    const errorTabs = [
      this.validateGeneralTab(),
      this.validateJobgroupMasksTab()
    ]
    if (errorTabs.every(d => d)) {
      const updateObj = {
        JGIGNAME: jobgroup.JGIGNAME,
        OWNER: generalTab.owner,
        ARCHMED: generalTab.archiveRetention === 0
          ? DefinitionUtils.ARCHIVEPOOL_MEDIA_NONE
          : DefinitionUtils.archiveMediaItems(false)[generalTab.archiveMedia].key,
        JGITITLE: generalTab.title.value,
        ARCRETPD: generalTab.archiveRetention,
        ONLRETPD: generalTab.onlineRetention,
        CTYPE: DefinitionUtils.JOBGROUP_DEFINITION_TYPES[generalTab.type].key,
        OBJECTS: jobgroupMasksTab.groupMasks.map(d => { return { JGIGNAME: jobgroup.JGIGNAME, JGMMASK: d.filenameOrMask.value, JGMEXCL: d.exclusive === 0 } })
      }
      updateJobgroup(updateObj, onClose)
    }
  }

  /**
   * @description Gets the error tabs as an array of numbers.
   * @returns {Array} The error tabs as an array of numbers.
   */
  handleErrorTabs = () => {
    const {
      generalTab: { title },
      jobgroupMasksTab: { groupMasks }
    } = this.state
    let buffer = []
    if (title.error !== '') {
      buffer.push(0)
    }
    groupMasks.forEach(d => {
      if (d.filenameOrMask.error !== '') {
        buffer.push(1)
      }
    })
    return buffer
  }

  /**
   * @description Translates the given media from backend.
   * @param {String} media
   */
  translateMedia = media => {
    const el = DefinitionUtils.archiveMediaItems(false).find(d => d.key === media)
    if (el) {
      return translate(el.translationKey)
    }
    return media
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, onClose, jobgroup, datemask } = this.props
    return (
      <Modal
        id={'modify_jobgroup_dialog'}
        className={'bux_UserProfileModal'}
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={translate('definition.modify_jobgroup')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('general.jobgroup_name'), value: jobgroup?.JGIGNAME },
              { label: translate('general.last_changed'), value: `${DateUtils.getDate(datemask, jobgroup?.CDATE, jobgroup?.CTIME.substring(0, 8))} ${translate('general.by')} ${jobgroup?.CUSER}` },
            ]}
            columns={4}
          />
        </Header>
        <Main id={id}>
          <Tabs
            id={id}
            errorTabs={this.handleErrorTabs()}>
            <Tab title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('general.jobgroup_masks')}>
              {this.renderJobgroupMasksTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    jobgroup: state.definitions.jobgroups.jobgroup
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateJobgroup: (updateObj, callback) => {
      JobgroupsDefinitionActions.updateJobgroup(updateObj, callback)(dispatch)
    },
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyJobgroupDialog)