import * as DateUtils from '../../../utils/DateUtils';
import {translate} from '../../../language/Language';
import moment from 'moment/moment';
import {DATEMASK} from '../../../redux/general/Preferences';
import {useAppSelector} from '../../../redux/store.types';
import { getColumnsObjects, HeaderColumnObj } from 'utils/ColumnUtils'
import * as CommonUtils from 'utils/CommonUtils'

const B93DATE = 'B93DATE'
const B93TIME = 'B93TIME'
const RTOKEN = 'RTOKEN'
const LGRONLNE = 'LGRONLNE'
const LGRARCH = 'LGRARCH'
const LGRFFARC = 'LGRFFARC'
const LGRFFDEL = 'LGRFFDEL'
const LGRFFREL = 'LGRFFREL'
const B93SDATE = 'B93SDATE'
const B93STIME = 'B93STIME'


type UseDataTableMapperFunc = () => (data: string[][], headers: string[], usedHeaders: string[]) => string[][]
export const useDataTableMapperFunc: UseDataTableMapperFunc = () => {
  const datemask: string = useAppSelector(state => state.auth.serverdata.preferences[DATEMASK])
  return (data, headers, usedHeaders) => {
    const headerData = (key: string): number => {
      return headers.indexOf(key)
    }

    return data.map((element) => {
      return usedHeaders.map((header) => {
        if (header === LGRFFDEL) {
          return (element[headerData('LGRFFDEL')] === 'PEND' ? translate('general.yes') : translate('general.no'))
        } else if (header === LGRFFARC) {
          return (element[headerData('LGRFFARC')] === 'PEND' ? translate('general.yes') : translate('general.no'))
        } else if (header === LGRFFREL) {
          return (element[headerData('LGRFFREL')] === 'PEND' ? translate('general.yes') : translate('general.no'))
        } else if (header === LGRARCH) {
          return (element[headerData('LGRARCH')] === 'YES' ? translate('general.yes') : translate('general.no'))
        } else if (header === LGRONLNE) {
          return (element[headerData('LGRONLNE')] === 'YES' ? translate('general.yes') : translate('general.no'))
        } else if (header === RTOKEN) {
          return (element[headerData('RTOKEN')] || '0000000000000000')
        } else if (header === B93TIME) {
          return (DateUtils.getDate(' ', element[headerData('B93DATE')], element[headerData('B93TIME')]?.substring(0, 8)))
        } else if (header === B93DATE) {
          return (DateUtils.getDate(datemask, element[headerData('B93DATE')], element[headerData('B93TIME')]?.substring(0, 8), false))
        } else if (header === B93STIME) {
          return (DateUtils.getDate(' ', element[headerData('B93SDATE')], element[headerData('B93STIME')]?.substring(0, 8)))
        } else if (header === B93SDATE) {
          return (DateUtils.getDate(datemask, element[headerData('B93SDATE')], element[headerData('B93STIME')]?.substring(0, 8), false))
        } else {
          const val = element[headerData(header)]?.toString() ?? ''
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            return (moment(`${val}:00:00`, DateUtils.PARSING_DATEMASK).format(`${datemask} ${DateUtils.TIME_DATEMASK_FULL_MILLISECONDS}`))
          } else if (val.length === 19 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm:ss')) {
            return (moment(`${val}:00`, DateUtils.PARSING_DATEMASK).format(`${datemask} ${DateUtils.TIME_DATEMASK_FULL_MILLISECONDS}`))
          } else if (val.length === 22 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm:ss:SS')) {
            return (moment(val, DateUtils.PARSING_DATEMASK).format(`${datemask} ${DateUtils.TIME_DATEMASK_FULL_MILLISECONDS}`))
          } else {
            return (val)
          }
        }
      })
    })
  }
}

/**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
export const fillHeaderInformation = (controlmJobs: any, defaultOrderedHeaders: string[]) => {
  return CommonUtils.hasProperties(controlmJobs) ? getColumnsObjects(controlmJobs.header, defaultOrderedHeaders) : []
}

/**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
export const getSavedOrDefaultHeader = (allHeaderObjs: HeaderColumnObj[], preferences: any, preferenceKey: string) => {
  if (preferences[preferenceKey]) {
    let buffer: string[] = []
    preferences[preferenceKey].displayedHeaders.forEach((d: any) => {
      // fallback if old preferences saved the columns as language keys and rest keys
      for (let i = 0; i < allHeaderObjs.length; i++) {
        if (allHeaderObjs[i].rest === d || allHeaderObjs[i].translation === d) {
          buffer.push(allHeaderObjs[i].rest)
          break
        }
      }
    })
    return buffer
  }
  else {
    return getDefaultHeader(allHeaderObjs)
  }
}

/**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
const getDefaultHeader = (allHeaderObjs: HeaderColumnObj[]) => {
  const buffer: any = []
  if (allHeaderObjs) {
    allHeaderObjs.filter(h => h.default).forEach(h => buffer.push(h.rest))
  }
  return buffer
}


const ORDDATE = 'ORDDATE'
const STRTDATE = 'STRTDATE'
const ENDDATE = 'ENDDATE'

export const getCleanData = (jobs: {header: string[]; data: string[][]}, usedHeader: string[], datemask: string) => {
  if (!jobs.data && !jobs.header) return null;

  const result: string[][] = []
  const headerIndex = (header: string) => jobs.header.indexOf(header)
  jobs.data.forEach((d: string[]) => {
    let buffer: string[] = []
    usedHeader.forEach(h => {
      if ([ORDDATE, STRTDATE, ENDDATE].includes(h)) {
        buffer.push(moment(d[headerIndex(h)], DateUtils.DDMMYYYY_DOT).format(datemask))
      }
      else {
        buffer.push(d[headerIndex(h)])
      }
    })
    result.push(buffer)
  })
  return result
}