export const DOCUMENT_ONLINERETENTION_DAYS = 'DAY'
export const DOCUMENT_ONLINERETENTION_REFERENCES = 'REF'
export const DOCUMENT_ONLINERETENTION_GENERATIONS = 'GEN'

export const DOCUMENT_ARCHIVERETENTION_NONE = ''
export const DOCUMENT_ARCHIVERETENTION_DISK = 'DISK'
export const DOCUMENT_ARCHIVERETENTION_TAPE = 'TAPE'
export const DOCUMENT_ARCHIVERETENTION_OPTICALDISK = 'ODISK'

export const DOCUMENT_EDITINGICON_NONE = ''
export const DOCUMENT_EDITINGICON_ERROR = 'X'
export const DOCUMENT_EDITINGICON_PIN = 'P'
export const DOCUMENT_EDITINGICON_STAMP = 'S'
export const DOCUMENT_EDITINGICON_CLIP = 'C'
export const DOCUMENT_EDITINGICON_HOOK = 'H'

export const DOCUMENT_CONTROLSTATUS_NONE = ''
export const DOCUMENT_CONTROLSTATUS_HOLD = 'H'
export const DOCUMENT_CONTROLSTATUS_CONTROL = 'C'

export const DOCUMENT_TEXTENCO_ISO8859_1 = 'ISO8859-1'
export const DOCUMENT_TEXTENCO_ISO8859_2 = 'ISO8859-2'
export const DOCUMENT_TEXTENCO_ISO8859_15 = 'ISO8859-15'
export const DOCUMENT_TEXTENCO_UTF_8 = 'UTF-8'
export const DOCUMENT_TEXTENCO_CP850 = 'CP850'
export const DOCUMENT_TEXTENCO_CP1250 = 'CP1250'
export const DOCUMENT_TEXTENCO_CP1251 = 'CP1251'
export const DOCUMENT_TEXTENCO_CP1252 = 'CP1252'
export const DOCUMENT_TEXTENCO_CP1253 = 'CP1253'
export const DOCUMENT_TEXTENCO_CP1254 = 'CP1254'
export const DOCUMENT_TEXTENCO_CP1256 = 'CP1256'

export const DOCUMENT_TEXTENCO_IBM0037 = 'IBM0037'
export const DOCUMENT_TEXTENCO_IBM0273 = 'IBM0273'
export const DOCUMENT_TEXTENCO_IBM0297 = 'IBM0297'
export const DOCUMENT_TEXTENCO_IBM0420 = 'IBM0420'
export const DOCUMENT_TEXTENCO_IBM0500 = 'IBM0500'
export const DOCUMENT_TEXTENCO_IBM1140 = 'IBM1140'
export const DOCUMENT_TEXTENCO_IBM1141 = 'IBM1141'
export const DOCUMENT_TEXTENCO_IBM1147 = 'IBM1147'
export const DOCUMENT_TEXTENCO_IBM1148 = 'IBM1148'

export const ONLINERETENTIONS = [
  { key: DOCUMENT_ONLINERETENTION_DAYS, translationKey: 'general.days' },
  { key: DOCUMENT_ONLINERETENTION_REFERENCES, translationKey: 'documentinformation.retentiontab_references' },
  { key: DOCUMENT_ONLINERETENTION_GENERATIONS, translationKey: 'documentinformation.retentiontab_generations' }
]

export const ARCHIVERETENTIONS = [
  { key: DOCUMENT_ARCHIVERETENTION_NONE, translationKey: 'general.none' },
  { key: DOCUMENT_ARCHIVERETENTION_DISK, translationKey: 'documentinformation.retentiontab_disk' },
  { key: DOCUMENT_ARCHIVERETENTION_TAPE, translationKey: 'documentinformation.retentiontab_tape' },
  { key: DOCUMENT_ARCHIVERETENTION_OPTICALDISK, translationKey: 'documentinformation.retentiontab_opticaldisk' }
]

export const EDITINGICONS = [
  { key: DOCUMENT_EDITINGICON_NONE, translationKey: 'general.none' },
  { key: DOCUMENT_EDITINGICON_ERROR, translationKey: 'import.editicon_error'},
  { key: DOCUMENT_EDITINGICON_HOOK, translationKey: 'import.editicon_hook' },
  { key: DOCUMENT_EDITINGICON_CLIP, translationKey: 'import.editicon_clip' },
  { key: DOCUMENT_EDITINGICON_PIN, translationKey: 'import.editicon_pin' },
  { key: DOCUMENT_EDITINGICON_STAMP, translationKey: 'import.editicon_stamp' }
]

export const CONTROLSTATI = [
  { key: DOCUMENT_CONTROLSTATUS_NONE, translationKey: 'general.none' },
  { key: DOCUMENT_CONTROLSTATUS_HOLD, translationKey: 'search.hold' },
  { key: DOCUMENT_CONTROLSTATUS_CONTROL, translationKey: 'search.control' }
]

// used when the list has the field LGRASCII = YES
export const TEXTENCO = [
  { key: DOCUMENT_TEXTENCO_ISO8859_1 },
  { key: DOCUMENT_TEXTENCO_ISO8859_2 },
  { key: DOCUMENT_TEXTENCO_ISO8859_15 },
  { key: DOCUMENT_TEXTENCO_UTF_8 },
  { key: DOCUMENT_TEXTENCO_CP850 },
  { key: DOCUMENT_TEXTENCO_CP1250 },
  { key: DOCUMENT_TEXTENCO_CP1251 },
  { key: DOCUMENT_TEXTENCO_CP1252 },
  { key: DOCUMENT_TEXTENCO_CP1253 },
  { key: DOCUMENT_TEXTENCO_CP1254 },
  { key: DOCUMENT_TEXTENCO_CP1256 },
]

// used when the list has the field LGRASCII = NO
export const TEXTENCO_EBCIDIC = [
  { key: DOCUMENT_TEXTENCO_ISO8859_1 },
  { key: DOCUMENT_TEXTENCO_IBM0037 },
  { key: DOCUMENT_TEXTENCO_IBM0273 },
  { key: DOCUMENT_TEXTENCO_IBM0297 },
  { key: DOCUMENT_TEXTENCO_IBM0420 },
  { key: DOCUMENT_TEXTENCO_IBM0500 },
  { key: DOCUMENT_TEXTENCO_IBM1140 },
  { key: DOCUMENT_TEXTENCO_IBM1141 },
  { key: DOCUMENT_TEXTENCO_IBM1147 },
  { key: DOCUMENT_TEXTENCO_IBM1148 },
]