import { Icon } from 'BetaUX2Web-Components/src/'
import * as ContentTypes from 'BetaUX2Web-Components/src/components/drawer/content/Content'
import { translate } from 'language/Language'
import store from 'redux/Store'


// Utils
import * as UserUtils from 'utils/UserUtils'

/**
 * @description Gets the security definition.
 */
const getSearchDefinitions = () => {
  if (UserUtils.isDOCX()) {
    return [
      {
        title: translate('search.standard_search'),
        link: '/search/standardselection',
        available: true
      },
      {
        title: translate('search.custom_search'),
        link: '/search/customsearch',
        available: true
      }
    ].filter(entry => entry.available)
  }
  else if (UserUtils.isLOGX()) {
    return [
      {
        title: translate('search.standard_search'),
        link: '/search/standardselection',
        available: true
      },
      {
        title: translate('search.zos'),
        link: '/search/zos',
        available: true
      },
      {
        title: translate('search.uc4'),
        link: '/search/uc4',
        available: true
      },
      {
        title: translate('search.controlm'),
        link: '/search/controlm',
        available: true
      },
      {
        title: translate('search.stonebranch'),
        link: '/search/stonebranch',
        available: true
      },
      {
        title: translate('search.syslog'),
        link: '/search/syslog',
        available: true
      },
      {
        title: translate('search.custom_search'),
        link: '/search/customsearch',
        available: true
      },
    ].filter(entry => entry.available)
  }

}

/**
 * @description Gets the import definitions.
 */
const getImportDefinitions = () => {
  // || {} is required to catch exception when userprofile is undefined
  const { VCIENIMP } = store.getState().auth?.serverdata.userprofile || {}
  if (UserUtils.isDOCX()) {
    return [
      {
        title: translate('import.standard_import'),
        link: '/import/standardimport',
        available: UserUtils.isAdmin() || VCIENIMP
      },
      {
        title: translate('import.custom_import'),
        link: '/import/customimport',
        available: true
      }
    ].filter(entry => entry.available)
  } else if (UserUtils.isLOGX()) {
    return [{
      title: translate('import.standard_import'),
      link: '/import/standardimport',
      available: UserUtils.isAdmin() || VCIENIMP
    }]
  }
}

/**
 * @description Gets the definition definitions.
 */
const getDefinitionDefinitions = () => {
  if (UserUtils.isDOCX()) {
    return [
      {
        title: translate('definition.recipient'),
        link: '/definition/recipient',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.document'),
        link: '/definition/document',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.document_node'),
        link: '/definition/documentnode',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.search_arguments'),
        link: '/definition/searchargument',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.filter_argument'),
        link: '/definition/filterargument',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.folder'),
        link: '/definition/folder',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.index'),
        link: '/definition/index',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.outputchannel'),
        link: '/definition/outputchannel',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.outputformat'),
        link: '/definition/outputformat',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.post_processing_note'),
        link: '/definition/postprocessingnote',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.external_commands'),
        link: '/definition/externalcmd',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.lpd_queue'),
        link: '/definition/lpdqueue',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.archive_pool'),
        link: '/definition/archivepool',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.result_table'),
        link: '/definition/resulttable',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.custom_dialog'),
        link: '/definition/customdialog',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.view_profile'),
        link: '/definition/viewprofile',
        available: UserUtils.isAdmin()
      }
    ].filter(entry => entry.available)
  }
  else if (UserUtils.isLOGX()) {
    return [
      {
        title: translate('definition.recipient'),
        link: '/definition/recipient',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.log'),
        link: '/definition/log',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.jobgroups'),
        link: '/definition/jobgroups',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.filter_argument'),
        link: '/definition/filterargument',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.index'),
        link: '/definition/index',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.outputchannel'),
        link: '/definition/outputchannel',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.outputformat'),
        link: '/definition/outputformat',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.post_processing_note'),
        link: '/definition/postprocessingnote',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.external_commands'),
        link: '/definition/externalcmd',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.lpd_queue'),
        link: '/definition/lpdqueue',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.archive_pool'),
        link: '/definition/archivepool',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.result_table'),
        link: '/definition/resulttable',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.custom_dialog'),
        link: '/definition/customdialog',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.view_profile'),
        link: '/definition/viewprofile',
        available: UserUtils.isAdmin()
      }
    ].filter(entry => entry.available)
  }
}

/**
 * @description Gets the user management definitions.
 */
const getUserManagementDefinitions = () => {
  return [
    {
      title: translate('group.groups'),
      link: '/usermanagement/groups',
      available: UserUtils.isAdmin()
    }, {
      title: translate('user.users'),
      link: '/usermanagement/users',
      available: UserUtils.isAdmin()
    }, {
      title: translate('user.remoteusers'),
      link: '/usermanagement/remoteusers',
      available: UserUtils.isAdmin()
    }
  ].filter(entry => entry.available)
}

/**
 * @description Gets the security definition.
 */
const getAssignmentDefinitions = () => {
  if (UserUtils.isDOCX()) {
    return (
      [
        {
          title: [
            translate('definition.recipient'),
            <Icon key={'0'} id={'recipient_document_relation'} name={'relation'} />,
            translate('definition.document')
          ],
          link: '/assignment/recipient_document',
          available: UserUtils.isAdmin()
        },
        {
          title: [
            translate('definition.folder'),
            <Icon key={'1'} id={'folder_document_relation'} name={'relation'} />,
            translate('definition.document')
          ],
          link: '/assignment/folder_document',
          available: UserUtils.isAdmin()
        },
        {
          title: [
            translate('definition.index'),
            <Icon key={'2'} id={'index_document_relation'} name={'relation'} />,
            translate('definition.document')
          ],
          link: '/assignment/index_document',
          available: UserUtils.isAdmin()
        },
        {
          title: [
            translate('definition.node'),
            <Icon key={'3'} id={'node_document_relation'} name={'relation'} />,
            translate('definition.document')
          ],
          link: '/assignment/node_document',
          available: UserUtils.isAdmin()
        }
      ] // Remove all entries which are for admins only if not a admin
    ).filter(entry => entry.available)
  }
  else if (UserUtils.isLOGX()) {
    return (
      [
        {
          title: [
            translate('definition.recipient'),
            <Icon key={'0'} id={'recipient_log_relation'} name={'relation'} />,
            translate('general.log')
          ],
          link: '/assignment/recipient_log',
          available: UserUtils.isAdmin()
        },
        {
          title: [
            translate('definition.index'),
            <Icon key={'1'} id={'index_log_relation'} name={'relation'} />,
            translate('general.log')
          ],
          link: '/assignment/index_log',
          available: UserUtils.isAdmin()
        }
      ] // Remove all entries which are for admins only if not a admin
    ).filter(entry => entry.available)
  }
}

/**
 * @description Gets the security definition.
 */
const getQueueDefinitions = () => {
  // || {} is required to catch exception when userprofile is undefined
  const { VCIENPRQ } = store.getState().auth.serverdata.userprofile || {}
  if (UserUtils.isDOCX()) {
    return [
      {
        title: translate('queue.output'),
        link: '/queue/output',
        available: UserUtils.isAdmin() || VCIENPRQ
      },
      {
        title: translate('queue.bundle'),
        link: '/queue/bundle',
        available: UserUtils.isAdmin() || VCIENPRQ
      },
      {
        title: translate('queue.reload'),
        link: '/queue/reload',
        available: UserUtils.isAdmin()
      },
      {
        title: translate('queue.index'),
        link: '/queue/index',
        available: UserUtils.isAdmin()
      },
      {
        title: translate('queue.lpd'),
        link: '/queue/lpd',
        available: UserUtils.isAdmin()
      }
    ].filter(entry => entry.available)
  }
  else if (UserUtils.isLOGX()) {
    return [
      {
        title: translate('queue.output'),
        link: '/queue/output',
        available: UserUtils.isAdmin() || VCIENPRQ
      },
      {
        title: translate('queue.reload'),
        link: '/queue/reload',
        available: UserUtils.isAdmin()
      },
      {
        title: translate('queue.index'),
        link: '/queue/index',
        available: UserUtils.isAdmin()
      },
      {
        title: translate('queue.lpd'),
        link: '/queue/lpd',
        available: UserUtils.isAdmin()
      }
    ].filter(entry => entry.available)
  }
}

/**
 * @description Gets the statistic definitions.
 */
const getStatisticDefinitions = () => {
  if (UserUtils.isDOCX()) {
    return [
      {
        title: translate('statistic.pages_and_docs'),
        link: '/statistic/pagesdocs',
        available: UserUtils.isAdmin()
      }
    ].filter(entry => entry.available)
  }
  else if (UserUtils.isLOGX()) {
    return [
      {
        title: translate('statistic.logx'),
        link: '/statistic/logx',
        available: UserUtils.isAdmin()
      }
    ].filter(entry => entry.available)
  }
}

/**
 * @description Gets the server definitions.
 */
const getServerDefinitions = () => {
  // || {} is required to catch exception when userprofile is undefined
  const { VCIENMSG, VCIENUTL, VCIENPRM } = store.getState().auth.serverdata.userprofile || {}
  if (UserUtils.isDOCX()) {
    return [
      {
        title: translate('database.messagelog'),
        link: '/server/messagelog',
        available: UserUtils.isAdmin() || VCIENMSG
      }, {
        title: translate('server.log_file'),
        link: '/server/serverlogfile',
        available: UserUtils.isAdmin()
      }, {
        title: translate('server.system_defaults'),
        link: '/server/systemdefaults',
        available: UserUtils.isAdmin()
      }, {
        title: translate('database.maintenance'),
        link: '/server/maintenance',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.external_commands'),
        link: '/server/externalcmd',
        available: UserUtils.isAdmin() || VCIENUTL
      }, {
        title: translate('server.accounting'),
        link: '/server/accounting',
        available: UserUtils.isAdmin()
      }, {
        title: translate('server.printer_device_information'),
        link: '/server/printerdeviceinfo',
        available: UserUtils.isAdmin() || VCIENMSG
      }, {
        title: translate('server.archive_files'),
        link: '/server/archivefile',
        available: UserUtils.isAdmin()
      }, {
        title: translate('server.lpd_status'),
        link: '/server/lpdstatus',
        available: UserUtils.isAdmin()
      }, {
        title: translate('server.output_processes'),
        link: '/server/outputprocesses',
        available: UserUtils.isAdmin() || VCIENPRM
      }
    ].filter(entry => entry.available)
  }
  else if (UserUtils.isLOGX()) {
    return [
      {
        title: translate('database.messagelog'),
        link: '/server/messagelog',
        available: UserUtils.isAdmin() || VCIENMSG
      }, {
        title: translate('server.log_file'),
        link: '/server/serverlogfile',
        available: UserUtils.isAdmin()
      }, {
        title: translate('server.system_defaults'),
        link: '/server/systemdefaults',
        available: UserUtils.isAdmin()
      }, {
        title: translate('database.maintenance'),
        link: '/server/maintenance',
        available: UserUtils.isAdmin()
      }, {
        title: translate('definition.external_commands'),
        link: '/server/externalcmd',
        available: UserUtils.isAdmin() || VCIENUTL
      }, {
        title: translate('server.accounting'),
        link: '/server/accounting',
        available: UserUtils.isAdmin()
      }, {
        title: translate('server.archive_files'),
        link: '/server/archivefile',
        available: UserUtils.isAdmin()
      }, {
        title: translate('server.lpd_status'),
        link: '/server/lpdstatus',
        available: UserUtils.isAdmin()
      }, {
        title: translate('server.output_processes'),
        link: '/server/outputprocesses',
        available: UserUtils.isAdmin() || VCIENPRM
      }
    ].filter(entry => entry.available)
  }
}

/**
 * @description Gets the database definitions.
 */
const getDatabaseDefinitions = () => {
  // || {} is required to catch exception when userprofile is undefined
  const { VCIENMSG } = store.getState().auth.serverdata.userprofile || {}
  return [
    {
      title: translate('general.status'),
      link: '/database/status',
      available: UserUtils.isAdmin() || VCIENMSG
    }, {
      title: translate('database.tables'),
      link: '/database/table',
      available: UserUtils.isAdmin()
    }, {
      title: translate('database.query'),
      link: '/database/query',
      available: UserUtils.isAdmin()
    }
  ].filter(entry => entry.available)
}

/**
 * @description Gets the security definition.
 */
const getSecurityDefinitions = () => {
  return [
    {
      title: translate('security.profiles'),
      link: '/security',
      available: UserUtils.isAdmin()
    },
    {
      title: translate('security.entities'),
      link: '/security/entity',
      available: UserUtils.isAdmin()
    }
  ].filter(entry => entry.available)
}

/**
 * @description Gets the content items for a content type.
 */
export const getContentItems = (contentType) => {
  switch (contentType) {
    case ContentTypes.CONTENT_TYPE_SEARCH: return getSearchDefinitions()
    case ContentTypes.CONTENT_TYPE_IMPORT: return getImportDefinitions()
    case ContentTypes.CONTENT_TYPE_DEFINITION: return getDefinitionDefinitions()
    case ContentTypes.CONTENT_TYPE_USER_MANAGEMENT: return getUserManagementDefinitions()
    case ContentTypes.CONTENT_TYPE_ASSIGNMENT: return getAssignmentDefinitions()
    case ContentTypes.CONTENT_TYPE_QUEUE: return getQueueDefinitions()
    case ContentTypes.CONTENT_TYPE_STATISTIC: return getStatisticDefinitions()
    case ContentTypes.CONTENT_TYPE_SERVER: return getServerDefinitions()
    case ContentTypes.CONTENT_TYPE_DATABASE: return getDatabaseDefinitions()
    case ContentTypes.CONTENT_TYPE_SECURITY: return getSecurityDefinitions()
    default: return getUserManagementDefinitions()
  }
}