import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DownloadWrapper extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM. */
    id: PropTypes.string.isRequired,
    /** The array of data */
    data: PropTypes.arrayOf(PropTypes.array),
    /** The array of header names of data */
    header: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** The name of the downloaded file */
    filename: PropTypes.string,
    /** Delimiter (e.g. ':', ';', ' ' or anything). Default is ';'.*/
    delimiter: PropTypes.string,
    /** The tooltip which will be shown on hover. */
    tooltip: PropTypes.string
  };

  /**
   * @description Converts an array-of-arrays to csv and then to a uri which can be used as a link to download the csv-data.
   * @param {Array} header
   * @param {Array} data
   * @param {String} delimiter The string that splits the elements
   * @returns {String}
   */
  getCSVFromArrayOfArrays = (header, data, delimiter) => {
    let csv = '';

    // header
    // note: header may include the headers for the actionbuttons which are empty ("")
    // -> filter them out
    csv += `${header.filter(val => val).join(delimiter || ';')}\n`;

    // data
    // note: the date format is correctly here. Excel reformats the date!
    for(let element of data) {
      csv += `${element.join(delimiter || ';')}\n`
    };

    /* Blob is required, otherwise the url would be cut after ~2000character */
    /* https://stackoverflow.com/questions/24610694/export-html-table-to-csv-in-google-chrome-browser/24611096#24611096 */
    /* "\ufeff is required for UTF-8 encoding: */
    /* https://stackoverflow.com/questions/31959487/utf-8-encoidng-issue-when-exporting-csv-file-javascript/32002335#32002335 */
    const csvData = new Blob(['\ufeff' + csv], { encoding: 'UTF-8', type: 'text/csv;charset=UTF-8' });
    return URL.createObjectURL(csvData);
  };

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, filename, header, data, delimiter, children, tooltip } = this.props;
    return (
      <a
        id={id}
        href={this.getCSVFromArrayOfArrays(header, data, delimiter)}
        download={filename || 'data.csv'}
        title={tooltip ? tooltip : null} >
        {children}
      </a>
    );
  };
}