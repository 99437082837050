import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import * as UserUtils from 'utils/UserUtils'
import * as Utils from 'utils/Utils'

// components
import {
  Button, Card,
  Checkbox, Column, Dropdown, Icon, Input,
  NumericSpinner, Row, Switch
} from 'BetaUX2Web-Components/src/'
import TimeCard from 'components/time_card/TimeCard'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

// redux
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as StandardSelectionActions from 'redux/actions/StandardSelectionActions'
import * as Preferences from 'redux/general/Preferences'

import './SearchStandardSearch.scss'

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.hours' },
  { key: UNIT_DAYS, translationKey: 'general.days' },
]

export const LASTTIME_MODE_TODAY = 'today'
export const LASTTIME_MODE_YESTERDAY = 'yesterday'
export const LASTTIME_MODE_CUSTOM = 'custom'

const LASTTIME_MODES = [
  { key: LASTTIME_MODE_TODAY, translationKey: 'general.today' },
  { key: LASTTIME_MODE_YESTERDAY, translationKey: 'general.yesterday' },
  { key: LASTTIME_MODE_CUSTOM, translationKey: 'general.custom' },
]

const CONTROL_STATUS_ANY = ''
const CONTROL_STATUS_HOLD = 'H'
const CONTROL_STATUS_CONTROL = 'C'

const CONTROL_STATUS_ITEMS = [
  { key: CONTROL_STATUS_ANY, translationKey: 'general.any' },
  { key: CONTROL_STATUS_HOLD, translationKey: 'search.hold' },
  { key: CONTROL_STATUS_CONTROL, translationKey: 'search.control' },
]

const ONLINE_ANY = ''
const ONLINE_YES = 'YES'
const ONLINE_NO = 'NO'

const ONLINE_ITEMS = [
  { key: ONLINE_ANY, translationKey: 'general.any' },
  { key: ONLINE_YES, translationKey: 'general.yes' },
  { key: ONLINE_NO, translationKey: 'general.no' },
]

const ARCHIVED_ANY = ''
const ARCHIVED_YES = 'YES'
const ARCHVIED_NO = 'NO'
const ARCHVIED_PENDING = 'PENDING'

const ARCHIVED_ITEMS = [
  { key: ARCHIVED_ANY, translationKey: 'general.any' },
  { key: ARCHIVED_YES, translationKey: 'general.yes' },
  { key: ARCHVIED_NO, translationKey: 'general.no' },
  { key: ARCHVIED_PENDING, translationKey: 'general.pending' },
]

const MARKED_FOR_RELOAD_ANY = ''
const MARKED_FOR_RELOAD_YES = 'YES'
const MARKED_FOR_RELOAD_NO = 'NO'

const MARKED_FOR_RELOAD_ITEMS = [
  { key: MARKED_FOR_RELOAD_ANY, translationKey: 'general.any' },
  { key: MARKED_FOR_RELOAD_YES, translationKey: 'general.yes' },
  { key: MARKED_FOR_RELOAD_NO, translationKey: 'general.no' },
]

const MARKED_FOR_DELETE_ANY = ''
const MARKED_FOR_DELETE_YES = 'YES'
const MARKED_FOR_DELETE_NO = 'NO'

const MARKED_FOR_DELETE_ITEMS = [
  { key: MARKED_FOR_DELETE_ANY, translationKey: 'general.any' },
  { key: MARKED_FOR_DELETE_YES, translationKey: 'general.yes' },
  { key: MARKED_FOR_DELETE_NO, translationKey: 'general.no' },
]

const AUTOPRINT_ANY = ''
const AUTOPRINT_YES = 'YES'
const AUTOPRINT_NO = 'NO'

const AUTOPRINT_ITEMS = [
  { key: AUTOPRINT_ANY, translationKey: 'general.any' },
  { key: AUTOPRINT_YES, translationKey: 'general.yes' },
  { key: AUTOPRINT_NO, translationKey: 'general.no' },
]

const EDITING_ICON_ANY = ''
const EDITING_ICON_ERROR = 'X'
const EDITING_ICON_HOOK = 'H'
const EDITING_ICON_CLIP = 'C'
const EDITING_ICON_PIN = 'P'
const EDITING_ICON_STAMP = 'S'

const EDITING_ICON_ITEMS = [
  { key: EDITING_ICON_ANY, translationKey: 'general.any', icon: 'none' },
  { key: EDITING_ICON_ERROR, translationKey: 'import.editicon_error', icon: 'error' },
  { key: EDITING_ICON_HOOK, translationKey: 'import.editicon_hook', icon: 'check' },
  { key: EDITING_ICON_CLIP, translationKey: 'import.editicon_clip', icon: 'clip' },
  { key: EDITING_ICON_PIN, translationKey: 'import.editicon_pin', icon: 'pin' },
  { key: EDITING_ICON_STAMP, translationKey: 'import.editicon_stamp', icon: 'stamp' },
]

const OBTAINED_ANY = ''
const OBTAINED_IMPORT = 'IMPORT'
const OBTAINED_B93UX = 'B93UX'
const OBTAINED_MVS = 'MVS'
const OBTAINED_EDF = 'EDF'
const OBTAINED_RELOAD = 'RELOAD'
const OBTAINED_RESCAN = 'RESCAN'
const OBTAINED_UC4ARC = 'UC4ARC'
const OBTAINED_UC4ONL = 'UC4ONL'

const OBTAINED_ITEMS = [
  { key: OBTAINED_ANY, translationKey: 'general.any' },
  { key: OBTAINED_IMPORT, translationKey: 'general.import' },
  { key: OBTAINED_B93UX, translationKey: 'search.b93ux' },
  { key: OBTAINED_MVS, translationKey: 'search.mvs' },
  { key: OBTAINED_EDF, translationKey: 'search.edf' },
  { key: OBTAINED_RELOAD, translationKey: 'search.reload' },
  { key: OBTAINED_RESCAN, translationKey: 'search.rescan' },
]

const OBTAINED_ITEMS_LOGX = [
  ...OBTAINED_ITEMS.filter(d => d.key !== OBTAINED_RESCAN),
  { key: OBTAINED_UC4ARC, translationKey: 'search.uc4_archive' },
  { key: OBTAINED_UC4ONL, translationKey: 'search.uc4_online' },
]

const TYPE_ANY = ''
const TYPE_LIST = 'LIST'
const TYPE_REPORT = 'REPORT'

const INDEX_ALL = ''
const INDEX_YES = 'YES'
const INDEX_NO = 'NO'

const NOTES_ALL = ''
const NOTES_YES = 'YES'
const NOTES_NO = 'NO'

const TYPE_ITEMS = [
  { key: TYPE_ANY, translationKey: 'general.any' },
  { key: TYPE_LIST, translationKey: 'search.list' },
  { key: TYPE_REPORT, translationKey: 'general.report' }
]

const INDEX_ITEMS = [
  { key: INDEX_ALL, translationKey: 'general.any' },
  { key: INDEX_YES, translationKey: 'general.yes' },
  { key: INDEX_NO, translationKey: 'general.no' }
]

const NOTES_ITEMS = [
  { key: NOTES_ALL, translationKey: 'general.any' },
  { key: NOTES_YES, translationKey: 'general.yes' },
  { key: NOTES_NO, translationKey: 'general.no' }
]

class SearchStandardSearch extends Component {

  formRef = React.createRef()
  extensionRef = React.createRef()
  reportRef = React.createRef()
  jobnameRef = React.createRef()
  recipientRef = React.createRef()
  folderRef = React.createRef()
  fromDateRef = React.createRef()
  fromTimeRef = React.createRef()
  toDateRef = React.createRef()
  toTimeRef = React.createRef()
  listTimestampRef = React.createRef()
  returnCodeInputRef = React.createRef()

  defaultState = {
    time: {
      activeTimeTabIndex: 0,
      activeLastTimeModeIndex: 0,
      customLast: 1,
      activeUnitIndex: 2,
      fromDate: {
        value: '',
        error: ''
      },
      fromTime: {
        value: '',
        error: ''
      },
      toDate: {
        value: '',
        error: ''
      },
      toTime: {
        value: '',
        error: ''
      },
      listTimestamp: {
        value: '',
        error: ''
      }
    },
    form: {
      value: '',
      errorkey: ''
    },
    extension: {
      value: '',
      errorkey: ''
    },
    report: {
      value: '',
      errorkey: ''
    },
    jobname: {
      value: '',
      errorkey: ''
    },
    title: {
      value: '',
      errorkey: ''
    },
    titleFromColumn: 0,
    recipient: {
      value: '',
      errorkey: ''
    },
    folder: {
      value: '',
      errorkey: ''
    },
    outputchannel: {
      value: '',
      errorkey: ''
    },
    requestor: {
      value: '',
      errorkey: ''
    },
    user: {
      value: '',
      errorkey: ''
    },
    owner: {
      value: '',
      errorkey: ''
    },
    doctype: {
      value: '',
      errorkey: ''
    },
    filteredByRC: false,
    returnCodeSwitch: 0,
    returnCodeInput: {
      value: '',
      error: ''
    },
    docuser1: {
      value: '',
      errorkey: ''
    },
    docuser2: {
      value: '',
      errorkey: ''
    },
    docuser3: {
      value: '',
      errorkey: ''
    },
    docuser4: {
      value: '',
      errorkey: ''
    },
    docuser5: {
      value: '',
      errorkey: ''
    },
    docuser6: {
      value: '',
      errorkey: ''
    },
    docuser7: {
      value: '',
      errorkey: ''
    },
    docuser8: {
      value: '',
      errorkey: ''
    },
    activeControlStatusIndex: Math.max(CONTROL_STATUS_ITEMS.findIndex(entry => entry.key === CONTROL_STATUS_ANY), 0),
    activeOnlineIndex: ONLINE_ITEMS.findIndex(entry => entry.key === ONLINE_ANY),
    controlUser: {
      value: '',
      errorkey: ''
    },
    activeArchivedIndex: Math.max(ARCHIVED_ITEMS.findIndex(entry => entry.key === ARCHIVED_ANY), 0),
    activeMarkedForReloadIndex: Math.max(MARKED_FOR_RELOAD_ITEMS.findIndex(entry => entry.key === MARKED_FOR_RELOAD_ANY), 0),
    activeMarkedForDeleteIndex: Math.max(MARKED_FOR_DELETE_ITEMS.findIndex(entry => entry.key === MARKED_FOR_DELETE_ANY), 0),
    activeAutoprintIndex: Math.max(AUTOPRINT_ITEMS.findIndex(entry => entry.key === AUTOPRINT_ANY), 0),
    activeEditingIconIndex: Math.max(EDITING_ICON_ITEMS.findIndex(entry => entry.key === EDITING_ICON_ANY), 0),
    activeObtainedIndex: Math.max(
      UserUtils.isDOCX()
        ? OBTAINED_ITEMS.findIndex(entry => entry.key === OBTAINED_ANY)
        : OBTAINED_ITEMS_LOGX.findIndex(entry => entry.key === OBTAINED_ANY)
      , 0
    ),
    activeType: Math.max(TYPE_ITEMS.findIndex(entry => entry.key === TYPE_ANY), 0),
    activeIndex: Math.max(INDEX_ITEMS.findIndex(entry => entry.key === INDEX_ALL), 0),
    activeNotes: Math.max(NOTES_ITEMS.findIndex(entry => entry.key === NOTES_ALL), 0),
    outputStatusPrinted: false,
    outputStatusError: false,
    outputStatusHold: false,
    outputStatusWaiting: false,
    outputStatusDelete: false,
    outputStatusNotFound: false,
    outputStatusActive: false,
    outputStatusRetry: false,
    outputStatusCancelled: false,
    outputStatusRequeued: false,
  }

  state = {
    ...this.defaultState,
    showRecipientDefinitionSelectorDialog: false,
    showFolderDefinitionSelectorDialog: false,
    showOutputChanelDefinitionsSelectorDialog: false,
    showFormDefinitionSelectorDialog: false,
    showExtDefinitionSelectorDialog: false,
    showReportDefinitionSelectorDialog: false,
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
  * @description Initializes the import fields with the values saved in preferences.
  */
  initFieldsFromPreferences = () => {
    const { preferences, datemask, userprofile } = this.props

    if (preferences) {
      // last time mode
      let activeTimeTabIndex = Utils.convertStringToInt(preferences[Preferences.SEARCH_STD_ACTIVE_TAB]) || 0
      let lastTimeMode = preferences[Preferences.SEARCH_STD_LASTTIME_MODE]
      if (!lastTimeMode) {
        lastTimeMode = LASTTIME_MODE_TODAY
      }
      const activeLastTimeModeIndex = Math.max(LASTTIME_MODES.findIndex(temp => temp.key === lastTimeMode), 0)

      // custom last
      let customLast = preferences[Preferences.SEARCH_STD_CUSTOMLAST]
      if (!customLast) {
        customLast = 1
      }
      else {
        if (Utils.isString(customLast)) {
          customLast = parseInt(customLast)
        }
      }

      // custom unit
      let customUnit = preferences[Preferences.SEARCH_STD_CUSTOM_UNIT]
      if (!customUnit) {
        customUnit = UNIT_DAYS
      }
      const activeUnitIndex = Math.max(UNITS.findIndex(temp => temp.key === customUnit), 0)

      // formdate (default: '')
      let fromDate = activeTimeTabIndex === 1 ? preferences[Preferences.SEARCH_STD_FROMDATE] : ''
      if (DateUtils.isUnixTimestamp(fromDate)) {
        fromDate = DateUtils.getDateFromUnixTimestamp(fromDate, datemask)
      }
      else {
        fromDate = ''
      }

      // fromtime (default: '')
      let fromTime = preferences[Preferences.SEARCH_STD_FROMTIME] || ''
      fromTime = Utils.convertStringToInt(fromTime)

      // todate (default: '')
      let toDate = preferences[Preferences.SEARCH_STD_TODATE] || ''
      if (DateUtils.isUnixTimestamp(toDate)) {
        toDate = DateUtils.getDateFromUnixTimestamp(toDate, datemask)
      }
      else {
        toDate = ''
      }

      // toTime (default: '')
      let toTime = preferences[Preferences.SEARCH_STD_TOTIME] || ''
      toTime = Utils.convertStringToInt(toTime)

      // list timestamp (default: '')
      const listTimestamp = preferences[Preferences.SEARCH_STD_LISTTIMESTAMP] || ''

      // form (default: '')
      const form = userprofile
        ? userprofile.VCIBR001 !== ''
          ? userprofile.VCIBR001
          : preferences[Preferences.SEARCH_STD_FORM] || ''
        : preferences[Preferences.SEARCH_STD_FORM] || ''

      // extension (default: '')
      const extension = userprofile
        ? userprofile.VCIBR002 !== ''
          ? userprofile.VCIBR002
          : preferences[Preferences.SEARCH_STD_EXTENSION] || ''
        : preferences[Preferences.SEARCH_STD_EXTENSION] || ''

      // report (default: '')
      const report = userprofile
        ? userprofile.VCIBR003 !== ''
          ? userprofile.VCIBR003
          : preferences[Preferences.SEARCH_STD_REPORT] || ''
        : preferences[Preferences.SEARCH_STD_REPORT] || ''

      // jobname (default: '')
      const jobname = userprofile
        ? userprofile.VCIBR008 !== ''
          ? userprofile.VCIBR008
          : preferences[Preferences.SEARCH_STD_JOBNAME] || ''
        : preferences[Preferences.SEARCH_STD_JOBNAME] || ''

      // title (default: '')
      const title = preferences[Preferences.SEARCH_STD_TITLE] || ''

      // title from column
      let titleFromColumn = preferences[Preferences.SEARCH_STD_TITLEFROMCOLUMN]
      if (!titleFromColumn) {
        titleFromColumn = 0
      }
      else {
        if (Utils.isString(titleFromColumn)) {
          titleFromColumn = parseInt(titleFromColumn)
        }
      }

      // recipient (default: '')
      const recipient = userprofile
        ? userprofile.VCIBR009 !== ''
          ? userprofile.VCIBR009
          : preferences[Preferences.SEARCH_STD_RECIPIENT] || ''
        : preferences[Preferences.SEARCH_STD_RECIPIENT] || ''

      // folder (default: '')
      const folder = userprofile
        ? userprofile.VCIBR020 !== ''
          ? userprofile.VCIBR020
          : preferences[Preferences.SEARCH_STD_FOLDER] || ''
        : preferences[Preferences.SEARCH_STD_FOLDER] || ''

      // outputchannel (default: '')
      const outputchannel = preferences[Preferences.SEARCH_STD_OUTPUTCHANNEL] || ''

      // requestor (default: '')
      const requestor = preferences[Preferences.SEARCH_STD_REQUESTOR] || ''

      // user (default: '')
      const user = preferences[Preferences.SEARCH_STD_USER] || ''

      // owner (default: '')
      const owner = preferences[Preferences.SEARCH_STD_OWNER] || ''

      // doctype (default: '')
      const doctype = preferences[Preferences.SEARCH_STD_DOCTYPE] || ''

      let filteredByRC = preferences[Preferences.SEACH_STD_FILTERED_BY_RC]
      const returnCodeSwitch = { '<': 0, '>': 1, '=': 2, '#': 3 }[preferences[Preferences.SEARCH_STD_RETURNCODE_SWITCH]] || 0
      const returnCodeInput = preferences[Preferences.SEARCH_STD_RETURNCODE_INPUT] || ''

      if (Utils.isString(filteredByRC)) {
        filteredByRC = (filteredByRC === 'true')
      }

      // docuser1 (default: '')
      const docuser1 = preferences[Preferences.SEARCH_STD_DOCUSER1] || ''

      // docuser2 (default: '')
      const docuser2 = preferences[Preferences.SEARCH_STD_DOCUSER2] || ''

      // docuser3 (default: '')
      const docuser3 = preferences[Preferences.SEARCH_STD_DOCUSER3] || ''

      // docuser4 (default: '')
      const docuser4 = preferences[Preferences.SEARCH_STD_DOCUSER4] || ''

      // docuser5 (default: '')
      const docuser5 = preferences[Preferences.SEARCH_STD_DOCUSER5] || ''

      // docuser6 (default: '')
      const docuser6 = preferences[Preferences.SEARCH_STD_DOCUSER6] || ''

      // docuser7 (default: '')
      const docuser7 = preferences[Preferences.SEARCH_STD_DOCUSER7] || ''

      // docuser8 (default: '')
      const docuser8 = preferences[Preferences.SEARCH_STD_DOCUSER8] || ''

      // control status
      let controlStatus = preferences[Preferences.SEARCH_STD_CONTROL_STATUS]
      if (!controlStatus) {
        controlStatus = CONTROL_STATUS_ANY
      }
      const activeControlStatusIndex = Math.max(CONTROL_STATUS_ITEMS.findIndex(temp => temp.key === controlStatus), 0)

      // online
      let online = preferences[Preferences.SEARCH_STD_ONLINE]
      if (!online) {
        online = ONLINE_ANY
      }
      const activeOnlineIndex = ONLINE_ITEMS.findIndex(temp => temp.key === online)

      // control user
      const controlUser = preferences[Preferences.SEARCH_STD_CONTROL_USER] || ''

      // archived
      let archived = preferences[Preferences.SEARCH_STD_ARCHIVED]
      if (!archived) {
        archived = ARCHIVED_ANY
      }
      const activeArchivedIndex = Math.max(ARCHIVED_ITEMS.findIndex(temp => temp.key === archived), 0)

      // marked for reload
      let markedForReload = preferences[Preferences.SEARCH_STD_MARKED_FOR_RELOAD]
      if (!markedForReload) {
        markedForReload = MARKED_FOR_RELOAD_ANY
      }
      const activeMarkedForReloadIndex = Math.max(MARKED_FOR_RELOAD_ITEMS.findIndex(temp => temp.key === markedForReload), 0)

      // marked for delete
      let markedForDelete = preferences[Preferences.SEARCH_STD_MARKED_FOR_DELETE]
      if (!markedForDelete) {
        markedForDelete = MARKED_FOR_DELETE_ANY
      }
      const activeMarkedForDeleteIndex = Math.max(MARKED_FOR_DELETE_ITEMS.findIndex(temp => temp.key === markedForDelete), 0)

      // autoprint
      let autoprint = preferences[Preferences.SEARCH_STD_AUTOPRINT]
      if (!autoprint) {
        autoprint = AUTOPRINT_ANY
      }
      const activeAutoprintIndex = Math.max(AUTOPRINT_ITEMS.findIndex(temp => temp.key === autoprint), 0)

      // editing icon
      let editingIcon = preferences[Preferences.SEARCH_STD_EDITING_ICON]
      if (!editingIcon) {
        editingIcon = EDITING_ICON_ANY
      }
      const activeEditingIconIndex = Math.max(EDITING_ICON_ITEMS.findIndex(temp => temp.key === editingIcon), 0)

      // obtained
      let obtained = preferences[Preferences.SEARCH_STD_OBTAINED]
      if (!obtained) {
        obtained = OBTAINED_ANY
      }
      const activeObtainedIndex = Math.max(
        UserUtils.isDOCX()
          ? OBTAINED_ITEMS.findIndex(temp => temp.key === obtained)
          : OBTAINED_ITEMS_LOGX.findIndex(temp => temp.key === obtained)
        , 0
      )

      // select by
      let type = preferences[Preferences.SEARCH_STD_SELECT_BY_TYPE]
      if (!type) {
        type = TYPE_ANY
      }
      const activeType = Math.max(TYPE_ITEMS.findIndex(temp => temp.key === type), 0)

      let index = preferences[Preferences.SEARCH_STD_SELECT_BY_INDEX]
      if (!index) {
        index = INDEX_ALL
      }
      const activeIndex = Math.max(INDEX_ITEMS.findIndex(temp => temp.key === index), 0)

      let notes = preferences[Preferences.SEARCH_STD_SELECT_BY_NOTES]
      if (!notes) {
        notes = NOTES_ALL
      }
      const activeNotes = Math.max(NOTES_ITEMS.findIndex(temp => temp.key === notes), 0)

      let outputStatusPrinted = preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_PRINTED] || false
      if (Utils.isString(outputStatusPrinted)) {
        outputStatusPrinted = (outputStatusPrinted === 'true')
      }
      let outputStatusError = preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_ERROR] || false
      if (Utils.isString(outputStatusError)) {
        outputStatusError = (outputStatusError === 'true')
      }
      let outputStatusHold = preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_HOLD] || false
      if (Utils.isString(outputStatusHold)) {
        outputStatusHold = (outputStatusHold === 'true')
      }
      let outputStatusWaiting = preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_WAITING] || false
      if (Utils.isString(outputStatusWaiting)) {
        outputStatusWaiting = (outputStatusWaiting === 'true')
      }
      let outputStatusDelete = preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_DELETE] || false
      if (Utils.isString(outputStatusDelete)) {
        outputStatusDelete = (outputStatusDelete === 'true')
      }
      let outputStatusNotFound = preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_NOTFOUND] || false
      if (Utils.isString(outputStatusNotFound)) {
        outputStatusNotFound = (outputStatusNotFound === 'true')
      }
      let outputStatusActive = preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_ACTIVE] || false
      if (Utils.isString(outputStatusActive)) {
        outputStatusActive = (outputStatusActive === 'true')
      }
      let outputStatusRetry = preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_RETRY] || false
      if (Utils.isString(outputStatusRetry)) {
        outputStatusRetry = (outputStatusRetry === 'true')
      }
      let outputStatusCancelled = preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_CANCELLED] || false
      if (Utils.isString(outputStatusCancelled)) {
        outputStatusCancelled = (outputStatusCancelled === 'true')
      }
      let outputStatusRequeued = preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_REQUEUED] || false
      if (Utils.isString(outputStatusRequeued)) {
        outputStatusRequeued = (outputStatusRequeued === 'true')
      }

      this.setState({
        time: {
          activeTimeTabIndex,
          activeLastTimeModeIndex,
          customLast,
          activeUnitIndex,
          fromDate: {
            value: fromDate,
            error: ''
          },
          fromTime: {
            value: fromTime,
            error: ''
          },
          toDate: {
            value: toDate,
            error: ''
          },
          toTime: {
            value: toTime,
            error: ''
          },
          listTimestamp: {
            value: listTimestamp,
            error: ''
          }
        },
        activeIndex: activeIndex,
        activeNotes: activeNotes,
        form: {
          value: form,
          errorkey: ''
        },
        extension: {
          value: extension,
          errorkey: ''
        },
        report: {
          value: report,
          errorkey: ''
        },
        jobname: {
          value: jobname,
          errorkey: ''
        },
        title: {
          value: title,
          errorkey: ''
        },
        titleFromColumn: titleFromColumn,
        recipient: {
          value: recipient,
          errorkey: ''
        },
        folder: {
          value: folder,
          errorkey: ''
        },
        outputchannel: {
          value: outputchannel,
          errorkey: ''
        },
        requestor: {
          value: requestor,
          errorkey: ''
        },
        user: {
          value: user,
          errorkey: ''
        },
        owner: {
          value: owner,
          errorkey: ''
        },
        doctype: {
          value: doctype,
          errorkey: ''
        },
        filteredByRC,
        returnCodeSwitch,
        returnCodeInput: {
          value: returnCodeInput,
          error: ''
        },
        docuser1: {
          value: docuser1,
          errorkey: ''
        },
        docuser2: {
          value: docuser2,
          errorkey: ''
        },
        docuser3: {
          value: docuser3,
          errorkey: ''
        },
        docuser4: {
          value: docuser4,
          errorkey: ''
        },
        docuser5: {
          value: docuser5,
          errorkey: ''
        },
        docuser6: {
          value: docuser6,
          errorkey: ''
        },
        docuser7: {
          value: docuser7,
          errorkey: ''
        },
        docuser8: {
          value: docuser8,
          errorkey: ''
        },
        activeControlStatusIndex: activeControlStatusIndex,
        activeOnlineIndex: activeOnlineIndex,
        controlUser: {
          value: controlUser,
          errorkey: ''
        },
        activeArchivedIndex: activeArchivedIndex,
        activeMarkedForReloadIndex: activeMarkedForReloadIndex,
        activeMarkedForDeleteIndex: activeMarkedForDeleteIndex,
        activeAutoprintIndex: activeAutoprintIndex,
        activeEditingIconIndex: activeEditingIconIndex,
        activeObtainedIndex: activeObtainedIndex,
        activeType: activeType,
        outputStatusPrinted: outputStatusPrinted,
        outputStatusError: outputStatusError,
        outputStatusHold: outputStatusHold,
        outputStatusWaiting: outputStatusWaiting,
        outputStatusDelete: outputStatusDelete,
        outputStatusNotFound: outputStatusNotFound,
        outputStatusActive: outputStatusActive,
        outputStatusRetry: outputStatusRetry,
        outputStatusCancelled: outputStatusCancelled,
        outputStatusRequeued: outputStatusRequeued,
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInputChanged = (id, value, errorkey) => {
    this.setState({
      [id]: {
        value: value,
        errorkey: errorkey
      }
    })
  }

  /**
 * @description Handles changes on checkboxes.
 * @param id The id of the field to change
 * @param value The new value
 */
  handleCheckboxChanged = (id, value) => {
    this.setState({
      [id]: value
    })
  }

  /**
   * @description Gets the output status value for the request.
   */
  getPrintOutputStatusValue = () => {
    const {
      outputStatusPrinted,
      outputStatusError,
      outputStatusHold,
      outputStatusWaiting,
      outputStatusDelete,
      outputStatusNotFound,
      outputStatusActive,
      outputStatusRetry,
      outputStatusCancelled,
      outputStatusRequeued,
    } = this.state

    let value = ''
    if (outputStatusPrinted) {
      value = value.concat('P')
    }
    if (outputStatusError) {
      value = value.concat('E')
    }
    if (outputStatusHold) {
      value = value.concat('H')
    }
    if (outputStatusWaiting) {
      value = value.concat('W')
    }
    if (outputStatusDelete) {
      value = value.concat('D')
    }
    if (outputStatusNotFound) {
      value = value.concat('N')
    }
    if (outputStatusActive) {
      value = value.concat('A')
    }
    if (outputStatusRetry) {
      value = value.concat('R')
    }
    if (outputStatusCancelled) {
      value = value.concat('C')
    }
    if (outputStatusRequeued) {
      value = value.concat('Q')
    }

    return value
  }

  handleFocus = () => {
    const { time, form, extension, report, jobname, recipient, folder, returnCodeInput } = this.state
    const focusArr = [
      { inputRef: this.fromDateRef, error: time.fromDate.error },
      { inputRef: this.fromTimeRef, error: time.fromTime.error },
      { inputRef: this.toDateRef, error: time.toDate.error },
      { inputRef: this.toTimeRef, error: time.toTime.error },
      { inputRef: this.listTimestampRef, error: time.listTimestamp.error },
      { inputRef: this.formRef, error: form.errorkey },
      { inputRef: this.extensionRef, error: extension.errorkey },
      { inputRef: this.reportRef, error: report.errorkey },
      { inputRef: this.jobnameRef, error: jobname.errorkey },
      { inputRef: this.recipientRef, error: recipient.errorkey },
      { inputRef: this.folderRef, error: folder.errorkey },
      { inputRef: this.returnCodeInputRef, error: returnCodeInput.error },
    ]
    Utils.setFocus(focusArr)
  }

  isDateAllowedDateString = value => DateUtils.validDateStrings.includes(value.toUpperCase())

  /**
   * @description Validates all elements which could be required or contains a wrong value. Checks if date inputs are valid dates and if given, the end date is not before the start date.
   */
  validateErrors = () => {
    const { time, form, extension, report, jobname, recipient, folder, filteredByRC, returnCodeInput } = this.state
    const { datemask, userprofile } = this.props
    let errorObj = { ...this.state }
    let errorsFound = 0
    if (time.activeTimeTabIndex === 1) {
      if (!this.isDateAllowedDateString(time.fromDate.value) && time.fromDate.value !== '' && !DateUtils.isDate(time.fromDate.value, datemask)) {
        errorObj.time.fromDate = {
          value: time.fromDate.value,
          error: 'general.invalid_date_format'
        }
        errorsFound += 1
      }
      if (!this.isDateAllowedDateString(time.toDate.value) && time.toDate.value !== '' && !DateUtils.isDate(time.toDate.value, datemask)) {
        errorObj.time.toDate = {
          value: time.toDate.value,
          error: 'general.invalid_date_format'
        }
        errorsFound += 1
      }
      if (time.fromTime.value !== '' && !time.fromTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errorObj.time.fromTime = {
          value: time.fromTime.value,
          error: 'general.invalid_time_format'
        }
        errorsFound += 1
      }
      if (time.toTime.value !== '' && !time.toTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errorObj.time.toTime = {
          value: time.toTime.value,
          error: 'general.invalid_time_format'
        }
        errorsFound += 1
      }
    }
    else if (time.activeTimeTabIndex === 2) {
      if (![0, 16].includes(time.listTimestamp.value.length) || !time.listTimestamp.value.match(/^$|^[0-9a-fA-F]+$/gm)) {
        errorObj.time.listTimestamp = {
          value: time.listTimestamp.value,
          error: 'standard_selection.list_time_stamp_error'
        }
        errorsFound += 1
      }
    }
    // Set error if the to date is before the from date and both inputs are filled with a date.
    if (time.fromDate.value !== '' && time.toDate.value !== '') {
      if (DateUtils.isDate(time.fromDate.value, datemask) && DateUtils.isDate(time.toDate.value, datemask)) {
        const fromDate = moment(time.fromDate.value, datemask)
        const toDate = moment(time.toDate.value, datemask)
        if (toDate.isBefore(fromDate)) {
          errorObj.time.toDate = {
            value: time.toDate.value,
            error: 'statistic.negative_date_difference_error'
          }
          errorsFound += 1
        }
      }
    }
    if (userprofile && userprofile.VCIBRR01 && form.value === '') {
      errorObj.form = {
        value: form.value,
        errorkey: 'general.input_required'
      }
      errorsFound += 1
    }
    if (userprofile && userprofile.VCIBRR02 && extension.value === '') {
      errorObj.extension = {
        value: extension.value,
        errorkey: 'general.input_required'
      }
      errorsFound += 1
    }
    if (userprofile && userprofile.VCIBRR03 && report.value === '') {
      errorObj.report = {
        value: report.value,
        errorkey: 'general.input_required'
      }
      errorsFound += 1
    }
    if (userprofile && userprofile.VCIBRR08 && jobname.value === '') {
      errorObj.jobname = {
        value: jobname.value,
        errorkey: 'general.input_required'
      }
      errorsFound += 1
    }
    if (userprofile && userprofile.VCIBRR09 && recipient.value === '') {
      errorObj.recipient = {
        value: recipient.value,
        errorkey: 'general.input_required'
      }
      errorsFound += 1
    }
    if (userprofile && userprofile.VCIBRR20 && folder.value === '') {
      errorObj.folder = {
        value: folder.value,
        errorkey: 'general.input_required'
      }
      errorsFound += 1
    }
    if (UserUtils.isLOGX() && filteredByRC && returnCodeInput.value === '') {
      errorObj.returnCodeInput = {
        value: returnCodeInput.value,
        error: 'general.input_required'
      }
      errorsFound += 1
    }
    this.setState({ ...errorObj }, () => {
      errorsFound > 0 && this.handleFocus()
    })
    return errorsFound
  }

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = event => {
    const { datemask } = this.props
    event.preventDefault()

    const {
      time,
      activeIndex,
      activeNotes,
      form,
      extension,
      report,
      jobname,
      title,
      titleFromColumn,
      recipient,
      folder,
      outputchannel,
      requestor,
      user,
      owner,
      doctype,
      filteredByRC,
      returnCodeSwitch,
      returnCodeInput,
      docuser1,
      docuser2,
      docuser3,
      docuser4,
      docuser5,
      docuser6,
      docuser7,
      docuser8,
      activeControlStatusIndex,
      activeOnlineIndex,
      controlUser,
      activeArchivedIndex,
      activeMarkedForReloadIndex,
      activeMarkedForDeleteIndex,
      activeAutoprintIndex,
      activeEditingIconIndex,
      activeObtainedIndex,
      activeType,
      outputStatusPrinted,
      outputStatusError,
      outputStatusHold,
      outputStatusWaiting,
      outputStatusDelete,
      outputStatusNotFound,
      outputStatusActive,
      outputStatusRetry,
      outputStatusCancelled,
      outputStatusRequeued,
    } = this.state
    const errors = this.validateErrors()
    if (errors === 0) {
      const obtainedItemsToUse = UserUtils.isDOCX() ? OBTAINED_ITEMS : OBTAINED_ITEMS_LOGX

      const searchParams = {
        'FORM': form.value,
        'EXTENSION': extension.value,
        'REPORT': report.value,
        'JOBNAME': jobname.value,
        'TITLE': title.value,
        'FTITLE': titleFromColumn,
        'RECI': recipient.value,
        'FOLDER': folder.value,
        'DCR': outputchannel.value,
        'BREQUEST': requestor.value,
        'SRCSUBU': user.value,
        'OWNER': owner.value,
        'DOCTYPE': doctype.value,
        'DOCUSR1': docuser1.value,
        'DOCUSR2': docuser2.value,
        'DOCUSR3': docuser3.value,
        'DOCUSR4': docuser4.value,
        'DOCUSR5': docuser5.value,
        'DOCUSR6': docuser6.value,
        'DOCUSR7': docuser7.value,
        'DOCUSR8': docuser8.value,
        'LGRSTAT': CONTROL_STATUS_ITEMS[activeControlStatusIndex].key,
        ...ONLINE_ITEMS[activeOnlineIndex].key !== ONLINE_ANY && {
          'ONLINE': ONLINE_ITEMS[activeOnlineIndex].key
        },
        'CUSER': controlUser.value,
        ...ARCHIVED_ITEMS[activeArchivedIndex].key !== ARCHIVED_ANY && {
          'ARCHIVE': ARCHIVED_ITEMS[activeArchivedIndex].key
        },
        ...MARKED_FOR_RELOAD_ITEMS[activeMarkedForReloadIndex].key !== MARKED_FOR_RELOAD_ANY && {
          'RELOAD': MARKED_FOR_RELOAD_ITEMS[activeMarkedForReloadIndex].key
        },
        ...MARKED_FOR_DELETE_ITEMS[activeMarkedForDeleteIndex].key !== MARKED_FOR_DELETE_ANY && {
          'DELETE': MARKED_FOR_DELETE_ITEMS[activeMarkedForDeleteIndex].key
        },
        ...AUTOPRINT_ITEMS[activeAutoprintIndex].key !== AUTOPRINT_ANY && {
          'LGRAUTO': AUTOPRINT_ITEMS[activeAutoprintIndex].key
        },
        ...EDITING_ICON_ITEMS[activeEditingIconIndex].key !== EDITING_ICON_ANY && {
          'EDTSTAT': EDITING_ICON_ITEMS[activeEditingIconIndex].key
        },
        ...obtainedItemsToUse[activeObtainedIndex].key !== OBTAINED_ANY && {
          'SRCOBT': obtainedItemsToUse[activeObtainedIndex].key
        },
        ...TYPE_ITEMS[activeType].key !== TYPE_ANY && {
          'PROCESS': TYPE_ITEMS[activeType].key
        },
        ...INDEX_ITEMS[activeIndex].key !== INDEX_ALL && {
          'INDEXED': INDEX_ITEMS[activeIndex].key
        },
        ...NOTES_ITEMS[activeNotes].key !== NOTES_ALL && {
          'LGRNOTE': NOTES_ITEMS[activeNotes].key
        },
        ...this.getPrintOutputStatusValue() !== '' && {
          'PRTSTAT': this.getPrintOutputStatusValue()
        }
      }

      // save search values in preferences
      const prefsToChange = {
        [Preferences.SEARCH_STD_ACTIVE_TAB]: time.activeTimeTabIndex,
        [Preferences.SEARCH_STD_LASTTIME_MODE]: LASTTIME_MODES[time.activeLastTimeModeIndex].key,
        [Preferences.SEARCH_STD_CUSTOMLAST]: time.customLast,
        [Preferences.SEARCH_STD_CUSTOM_UNIT]: UNITS[time.activeUnitIndex].key,
        [Preferences.SEARCH_STD_FROMDATE]: DateUtils.getUnixTimestamp(time.fromDate.value, datemask),
        [Preferences.SEARCH_STD_FROMTIME]: DateUtils.formatTimeToDefault(time.fromTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_STD_TODATE]: DateUtils.getUnixTimestamp(time.toDate.value, datemask),
        [Preferences.SEARCH_STD_TOTIME]: DateUtils.formatTimeToDefault(time.toTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_STD_LISTTIMESTAMP]: time.listTimestamp.value,
        [Preferences.SEARCH_STD_FORM]: form.value,
        [Preferences.SEARCH_STD_EXTENSION]: extension.value,
        [Preferences.SEARCH_STD_REPORT]: report.value,
        [Preferences.SEARCH_STD_JOBNAME]: jobname.value,
        [Preferences.SEARCH_STD_TITLE]: title.value,
        [Preferences.SEARCH_STD_TITLEFROMCOLUMN]: titleFromColumn,
        [Preferences.SEARCH_STD_RECIPIENT]: recipient.value,
        [Preferences.SEARCH_STD_FOLDER]: folder.value,
        [Preferences.SEARCH_STD_OUTPUTCHANNEL]: outputchannel.value,
        [Preferences.SEARCH_STD_REQUESTOR]: requestor.value,
        [Preferences.SEARCH_STD_USER]: user.value,
        [Preferences.SEARCH_STD_OWNER]: owner.value,
        [Preferences.SEARCH_STD_DOCTYPE]: doctype.value,
        [Preferences.SEACH_STD_FILTERED_BY_RC]: filteredByRC,
        [Preferences.SEARCH_STD_RETURNCODE_SWITCH]: { 0: '<', 1: '>', 2: '=', 3: '#' }[returnCodeSwitch],
        [Preferences.SEARCH_STD_RETURNCODE_INPUT]: returnCodeInput.value,
        [Preferences.SEARCH_STD_DOCUSER1]: docuser1.value,
        [Preferences.SEARCH_STD_DOCUSER2]: docuser2.value,
        [Preferences.SEARCH_STD_DOCUSER3]: docuser3.value,
        [Preferences.SEARCH_STD_DOCUSER4]: docuser4.value,
        [Preferences.SEARCH_STD_DOCUSER5]: docuser5.value,
        [Preferences.SEARCH_STD_DOCUSER6]: docuser6.value,
        [Preferences.SEARCH_STD_DOCUSER7]: docuser7.value,
        [Preferences.SEARCH_STD_DOCUSER8]: docuser8.value,
        [Preferences.SEARCH_STD_CONTROL_STATUS]: CONTROL_STATUS_ITEMS[activeControlStatusIndex].key,
        [Preferences.SEARCH_STD_ONLINE]: ONLINE_ITEMS[activeOnlineIndex].key,
        [Preferences.SEARCH_STD_CONTROL_USER]: controlUser.value,
        [Preferences.SEARCH_STD_ARCHIVED]: ARCHIVED_ITEMS[activeArchivedIndex].key,
        [Preferences.SEARCH_STD_MARKED_FOR_RELOAD]: MARKED_FOR_RELOAD_ITEMS[activeMarkedForReloadIndex].key,
        [Preferences.SEARCH_STD_MARKED_FOR_DELETE]: MARKED_FOR_DELETE_ITEMS[activeMarkedForDeleteIndex].key,
        [Preferences.SEARCH_STD_AUTOPRINT]: AUTOPRINT_ITEMS[activeAutoprintIndex].key,
        [Preferences.SEARCH_STD_EDITING_ICON]: EDITING_ICON_ITEMS[activeEditingIconIndex].key,
        [Preferences.SEARCH_STD_OBTAINED]: UserUtils.isDOCX() ? OBTAINED_ITEMS[activeObtainedIndex].key : OBTAINED_ITEMS_LOGX[activeObtainedIndex].key,
        [Preferences.SEARCH_STD_SELECT_BY_TYPE]: TYPE_ITEMS[activeType].key,
        [Preferences.SEARCH_STD_SELECT_BY_INDEX]: INDEX_ITEMS[activeIndex].key,
        [Preferences.SEARCH_STD_SELECT_BY_NOTES]: NOTES_ITEMS[activeNotes].key,
        [Preferences.SEARCH_STD_OUTPUT_STATUS_PRINTED]: outputStatusPrinted,
        [Preferences.SEARCH_STD_OUTPUT_STATUS_ERROR]: outputStatusError,
        [Preferences.SEARCH_STD_OUTPUT_STATUS_HOLD]: outputStatusHold,
        [Preferences.SEARCH_STD_OUTPUT_STATUS_WAITING]: outputStatusWaiting,
        [Preferences.SEARCH_STD_OUTPUT_STATUS_DELETE]: outputStatusDelete,
        [Preferences.SEARCH_STD_OUTPUT_STATUS_NOTFOUND]: outputStatusNotFound,
        [Preferences.SEARCH_STD_OUTPUT_STATUS_ACTIVE]: outputStatusActive,
        [Preferences.SEARCH_STD_OUTPUT_STATUS_RETRY]: outputStatusRetry,
        [Preferences.SEARCH_STD_OUTPUT_STATUS_CANCELLED]: outputStatusCancelled,
        [Preferences.SEARCH_STD_OUTPUT_STATUS_REQUEUED]: outputStatusRequeued,
        [Preferences.SEARCH_LAST_STANDARD_SEARCH]: searchParams,
      }

      this.props.changePrefs(prefsToChange)

      if (UserUtils.isLOGX() && filteredByRC) {
        searchParams['JOBRC'] = `${{ 0: '<', 1: '>', 2: '=', 3: '#' }[returnCodeSwitch]}${returnCodeInput.value}`
      }
      // last tab is active
      if (time.activeTimeTabIndex === 0) {
        if (LASTTIME_MODES[time.activeLastTimeModeIndex].key === LASTTIME_MODE_TODAY) {
          searchParams['SDATE'] = 'TODAY'
        }
        else if (LASTTIME_MODES[time.activeLastTimeModeIndex].key === LASTTIME_MODE_YESTERDAY) {
          searchParams['SDATE'] = 'YESTERDAY'
        }
        else if (LASTTIME_MODES[time.activeLastTimeModeIndex].key === LASTTIME_MODE_CUSTOM) {
          searchParams['FROMLAST'] = time.customLast
          searchParams['TUNITS'] = UNITS[time.activeUnitIndex].key
        }
      }
      else if (time.activeTimeTabIndex === 1) {
        searchParams['SDATE'] = DateUtils.getTimeshiftDate(time.fromDate.value, time.fromTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.fromTime.value !== '') {
          searchParams['STIME'] = DateUtils.getTimeshiftDate(time.fromDate.value, time.fromTime.value, DateUtils.TIME_DATEMASK)
        }
        searchParams['EDATE'] = DateUtils.getTimeshiftDate(time.toDate.value, time.toTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.fromTime.value !== '') {
          searchParams['ETIME'] = DateUtils.getTimeshiftDate(time.toDate.value, time.toTime.value, DateUtils.TIME_DATEMASK)
        }
      }
      else if (time.activeTimeTabIndex === 2) {
        searchParams['TOKEN'] = time.listTimestamp.value
      }
      this.props.getDocuments(undefined, searchParams)
    }
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleOnReset = () => {
    const { userprofile } = this.props
    const form = {
      value: userprofile &&
        userprofile.VCIBRD01
        ? userprofile.VCIBR001 !== ''
          ? userprofile.VCIBR001
          : this.state.form.value
        : '',
      error: ''
    }
    const extension = {
      value: userprofile &&
        userprofile.VCIBRD02
        ? userprofile.VCIBR002 !== ''
          ? userprofile.VCIBR002
          : this.state.extension.value
        : '',
      error: ''
    }
    const report = {
      value: userprofile &&
        userprofile.VCIBRD03
        ? userprofile.VCIBR003 !== ''
          ? userprofile.VCIBR003
          : this.state.report.value
        : '',
      error: ''
    }
    const jobname = {
      value: userprofile &&
        userprofile.VCIBRD08
        ? userprofile.VCIBR008 !== ''
          ? userprofile.VCIBR008
          : this.state.jobname.value
        : '',
      error: ''
    }
    const recipient = {
      value: userprofile &&
        userprofile.VCIBRD09
        ? userprofile.VCIBR009 !== ''
          ? userprofile.VCIBR009
          : this.state.recipient.value
        : '',
      error: ''
    }
    const folder = {
      value: userprofile &&
        userprofile.VCIBRD20
        ? userprofile.VCIBR020 !== ''
          ? userprofile.VCIBR020
          : this.state.folder.value
        : '',
      error: ''
    }
    const activeTimeTabIndex = this.state.activeTimeTabIndex
    const activeLastTimeModeIndex = this.state.activeLastTimeModeIndex
    this.setState({
      ...this.defaultState,
      form,
      extension,
      report,
      jobname,
      recipient,
      folder,
      activeTimeTabIndex,
      activeLastTimeModeIndex
    })
  }

  /**
   * @description Requests the recipient definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenRecipientDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showRecipientDefinitionSelectorDialog: true })
    }

    this.props.getRecipientDefinitions(
      ['RECI', 'TITLE'],
      this.state.recipient.value,
      callback)
  }

  /**
   * @description Requests the folder definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenFolderDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showFolderDefinitionSelectorDialog: true })
    }

    this.props.getFolderDefinitions(
      ['GLRNAME', 'GLRTITLE'],
      this.state.folder.value,
      callback)
  }

  onOpenOutputChanelDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showOutputChanelDefinitionsSelectorDialog: true })
    }

    this.props.getOutputChanelDefinitions(
      ['DCR', 'DCRTITLE'],
      this.state.outputchannel.value,
      callback)
  }

  onOpenFormDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showFormDefinitionSelectorDialog: true })
    }

    this.props.getDocumentDefinitions(
      ['FORM'],
      this.state.form.value,
      this.state.extension.value,
      this.state.report.value,
      callback)
  }

  onOpenExtDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showExtDefinitionSelectorDialog: true })
    }

    this.props.getDocumentDefinitions(
      ['EXT'],
      this.state.form.value,
      this.state.extension.value,
      this.state.report.value,
      callback)
  }

  onOpenReportDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showReportDefinitionSelectorDialog: true })
    }

    this.props.getDocumentDefinitions(
      ['REPORT'],
      this.state.form.value,
      this.state.extension.value,
      this.state.report.value,
      callback)
  }

  /**
   * @description Gets the translated dropdown items.
   * @param {Array} items The array which includes all items.
   */
  getTranslatedDropdownItems = (items) => {
    const translatedItems = []
    items.forEach(entry => {
      translatedItems.push(translate(entry.translationKey))
    })

    return translatedItems
  }

  getSelectByItems = items => {
    const translatedItems = []
    items.forEach(entry => {
      translatedItems.push(translate(entry.translationKey))
    })
    return translatedItems
  }

  /**
   * @description Gets the translated editing icons.
   * It also includes the icon as jsx.
   */
  getTranslatedEditingIconItems = () => {
    const translatedItems = []
    EDITING_ICON_ITEMS.forEach((entry, index) => {
      const item = [<Icon name={entry.icon} key={`${index}_0`} />, <span key={`${index}_1`}>{translate(entry.translationKey)}</span>]

      translatedItems.push(item)
    })

    return translatedItems
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id } = this.props

    // recipient definitions
    let recipientDefinitions
    if (this.state.showRecipientDefinitionSelectorDialog) {
      recipientDefinitions = this.props.selector.recipients
    }

    // folder definitions
    let folderDefinitions
    if (this.state.showFolderDefinitionSelectorDialog) {
      folderDefinitions = this.props.selector.folders
    }

    let outputChanelDefinitions
    if (this.state.showOutputChanelDefinitionsSelectorDialog) {
      outputChanelDefinitions = this.props.selector.outputchannels
    }

    let formDefinitions
    if (this.state.showFormDefinitionSelectorDialog) {
      formDefinitions = this.props.selector.documents
      formDefinitions.data = formDefinitions.data
        .filter((value, index, self) => self.findIndex(v => value[0] === v[0]) === index)
    }

    let extDefinitions
    if (this.state.showExtDefinitionSelectorDialog) {
      extDefinitions = this.props.selector.documents
      extDefinitions.data = extDefinitions.data
        .filter((value, index, self) => self.findIndex(v => value[0] === v[0]) === index)
    }

    let reportDefinitions
    if (this.state.showReportDefinitionSelectorDialog) {
      reportDefinitions = this.props.selector.documents
      reportDefinitions.data = reportDefinitions.data
        .filter((value, index, self) => self.findIndex(v => value[0] === v[0]) === index)
    }

    return (
      <>
        {this.state.showRecipientDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_recipientselector_dialog`}
            onClose={() => { this.setState({ showRecipientDefinitionSelectorDialog: false }) }}
            title={translate('definition.recipientdefinitions')}
            header={[
              translate('general.recipient'),
              translate('general.title'),
            ]}
            items={recipientDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newRecipient = recipientDefinitions.data[selectedRows][recipientDefinitions.header.indexOf('RECI')]
                this.setState({
                  recipient: { value: newRecipient, errorkey: '' },
                  showRecipientDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showRecipientDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showFolderDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_folderselector_dialog`}
            onClose={() => { this.setState({ showFolderDefinitionSelectorDialog: false }) }}
            title={translate('definition.folderdefinitions')}
            header={[
              translate('general.folder'),
              translate('general.title'),
            ]}
            items={folderDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newFolder = folderDefinitions.data[selectedRows][folderDefinitions.header.indexOf('GLRNAME')]
                this.setState({
                  folder: { value: newFolder, errorkey: '' },
                  showFolderDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showFolderDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showOutputChanelDefinitionsSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputChanel_definitions`}
            onClose={() => { this.setState({ showOutputChanelDefinitionsSelectorDialog: false }) }}
            title={translate('definition.output_channel.title')}
            header={[
              translate('general.output_channel'),
              translate('general.title'),
            ]}
            items={outputChanelDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newFolder = outputChanelDefinitions.data[selectedRows][outputChanelDefinitions.header.indexOf('DCR')]
                this.setState({
                  outputchannel: { value: newFolder, errorkey: '' },
                  showOutputChanelDefinitionsSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputChanelDefinitionsSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showFormDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_form_definitions`}
            onClose={() => { this.setState({ showFormDefinitionSelectorDialog: false }) }}
            // todo:
            title={translate('definition.form.title')}
            header={[
              // todo:
              translate('definition.form'),
            ]}
            items={formDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newValue = formDefinitions.data[selectedRows][formDefinitions.header.indexOf('FORM')]
                this.setState({
                  form: { value: newValue, errorkey: '' },
                  showFormDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showFormDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showExtDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_ext_definitions`}
            onClose={() => { this.setState({ showExtDefinitionSelectorDialog: false }) }}
            // todo:
            title={translate('definition.extension.title')}
            header={[
              translate('definition.extension')
            ]}
            items={extDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newValue = extDefinitions.data[selectedRows][extDefinitions.header.indexOf('EXT')]
                this.setState({
                  extension: { value: newValue, errorkey: '' },
                  showExtDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showExtDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showReportDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_report_definitions`}
            onClose={() => { this.setState({ showReportDefinitionSelectorDialog: false }) }}
            title={translate('definition.report.title')}
            header={[
              translate('definition.report'),
            ]}
            items={reportDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newValue = reportDefinitions.data[selectedRows][reportDefinitions.header.indexOf('REPORT')]
                this.setState({
                  report: { value: newValue, errorkey: '' },
                  showReportDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showReportDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}


      </>
    )
  }

  /**
   * @description Renders the time card.
   */
  renderTimeCard = () => {
    const { id, lang, datemask } = this.props
    return (
      <TimeCard
        id={id}
        lang={lang}
        datemask={datemask}
        values={this.timeCardValues()}
        stateKeys={this.timeCardStateKeys()}
        focusRefs={this.timeCardFocusRefs()}
        onValuesChange={this.handleTimeCardChange}
        enableTimestamp
        parentContainer={'drawer_content_search_body_main'}
        translate={key => translate(key)}
      />
    )
  }

  handleTimeCardChange = (key, val, err) => {
    this.setState(state => ({
      time: {
        ...state.time,
        [key]: typeof state.time[key] === 'object'
          ? {
            value: val,
            error: err || ''
          }
          : val
      }
    }))
  }

  timeCardValues = () => {
    const { time } = this.state
    return {
      tabIndex: time.activeTimeTabIndex,
      lastTimeModeIndex: time.activeLastTimeModeIndex,
      customLast: time.customLast,
      customUnitIndex: time.activeUnitIndex,
      fromDate: {
        value: time.fromDate.value,
        error: time.fromDate.error
      },
      fromTime: {
        value: time.fromTime.value,
        error: time.fromTime.error
      },
      toDate: {
        value: time.toDate.value,
        error: time.toDate.error
      },
      toTime: {
        value: time.toTime.value,
        error: time.toTime.error
      },
      listTimestamp: {
        value: time.listTimestamp.value,
        error: time.listTimestamp.error
      }
    }
  }

  /**
   * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
   *              Needed for update function inside the TimeCard.
   * @returns {Object}
   */
  timeCardStateKeys = () => {
    return {
      tabIndex: 'activeTimeTabIndex',
      lastTimeModeIndex: 'activeLastTimeModeIndex',
      customLast: 'customLast',
      customUnitIndex: 'activeUnitIndex',
      fromDate: 'fromDate',
      fromTime: 'fromTime',
      toDate: 'toDate',
      toTime: 'toTime',
      listTimestamp: 'listTimestamp'
    }
  }

  timeCardFocusRefs = () => {
    return {
      fromDate: this.fromDateRef,
      toDate: this.toDateRef,
      fromTime: this.fromTimeRef,
      toTime: this.toTimeRef,
      listTimestamp: this.listTimestampRef
    }
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id, userprofile } = this.props

    return (
      <Card
        id={`${id}_general_card`}
        title={translate('general.general')}>
        {/* form + extension row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* form */}
            <Input
              id={`${id}_form`}
              onInputChanged={(val, err) => { this.handleInputChanged('form', val, err) }}
              value={this.state.form.value}
              title={translate('general.form')}
              error={this.state.form.errorkey && translate(this.state.form.errorkey)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFormDefinitionDialog()
              }}
              maxLength={8}
              required={userprofile && userprofile.VCIBRR01 ? `${translate('general.required_field')}` : false}
              disabled={userprofile && userprofile.VCIBRD01}
              ref={this.formRef}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* extension */}
            <Input
              id={`${id}_extension`}
              onInputChanged={(val, err) => { this.handleInputChanged('extension', val, err) }}
              value={this.state.extension.value}
              title={translate('general.extension')}
              error={this.state.extension.errorkey && translate(this.state.extension.errorkey)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenExtDefinitionDialog()
              }}
              maxLength={16}
              required={userprofile && userprofile.VCIBRR02 ? `${translate('general.required_field')}` : false}
              disabled={userprofile && userprofile.VCIBRD02}
              ref={this.extensionRef}
            />
          </Column>
        </Row>
        {/* report + jobname row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* report */}
            <Input
              id={`${id}_report`}
              onInputChanged={(val, err) => { this.handleInputChanged('report', val, err) }}
              value={this.state.report.value}
              title={translate('general.report')}
              error={this.state.report.errorkey && translate(this.state.report.errorkey)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenReportDefinitionDialog()
              }}
              maxLength={16}
              required={userprofile && userprofile.VCIBRR03 ? `${translate('general.required_field')}` : false}
              disabled={userprofile && userprofile.VCIBRD03}
              ref={this.reportRef}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* jobname */}
            <Input
              id={`${id}_jobname`}
              onInputChanged={(val, err) => { this.handleInputChanged('jobname', val, err) }}
              value={this.state.jobname.value}
              title={translate('general.jobname')}
              error={this.state.jobname.errorkey && translate(this.state.jobname.errorkey)}
              maxLength={8}
              required={userprofile && userprofile.VCIBRR08 ? `${translate('general.required_field')}` : false}
              disabled={userprofile && userprofile.VCIBRD08}
              ref={this.jobnameRef}
            />
          </Column>
        </Row>
        {/* title */}
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_title`}
              onInputChanged={(val, err) => { this.handleInputChanged('title', val, err) }}
              value={this.state.title.value}
              title={translate('general.title')}
              error={this.state.title.errorkey && translate(this.state.title.errorkey)}
              maxLength={48}
            />
          </Column>
        </Row>
        {/* title from column row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* title from column */}
            <NumericSpinner
              id={`${id}_titlefromcol`}
              onChange={(newVal) => { this.setState({ titleFromColumn: newVal }) }}
              max={80}
              value={this.state.titleFromColumn}
              steps={1}
              min={0}
              title={translate('general.titlefromcol')}
              emptyable
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the assignment card.
   */
  renderAssignmentCard = () => {
    const { id, userprofile } = this.props

    return (
      <Card
        id={`${id}_assignment_card`}
        title={translate('general.assignment')}>
        {/* recipient + folder row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* recipient */}
            <Input
              id={`${id}_recipient`}
              onInputChanged={(val, err) => this.handleInputChanged('recipient', val, err)}
              value={this.state.recipient.value}
              title={translate('general.recipient')}
              error={this.state.recipient.errorkey && translate(this.state.recipient.errorkey)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenRecipientDefinitionDialog()
              }}
              required={userprofile && userprofile.VCIBRR09 ? `${translate('general.required_field')}` : false}
              disabled={userprofile && userprofile.VCIBRD09}
              ref={this.recipientRef}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* folder */}
            <Input
              id={`${id}_folder`}
              onInputChanged={(val, err) => this.handleInputChanged('folder', val, err)}
              value={this.state.folder.value}
              title={translate('general.folder')}
              error={this.state.folder.errorkey && translate(this.state.folder.errorkey)}
              maxLength={32}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFolderDefinitionDialog()
              }}
              required={userprofile && userprofile.VCIBRR20 ? `${translate('general.required_field')}` : false}
              disabled={userprofile && userprofile.VCIBRD20}
              ref={this.folderRef}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the output card.
   */
  renderOutputCard = () => {
    const { id } = this.props

    return (
      <Card
        id={`${id}_output_card`}
        title={translate('general.output')}>
        {/* output channel + requestor row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* output channel */}
            <Input
              id={`${id}_outputchannel`}
              onInputChanged={(val, err) => this.handleInputChanged('outputchannel', val, err)}
              value={this.state.outputchannel.value}
              title={translate('import.outputchannel')}
              error={this.state.outputchannel.errorkey && translate(this.state.outputchannel.errorkey)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputChanelDefinitionDialog()
              }}
              maxLength={16}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* requestor */}
            <Input
              id={`${id}_requestor`}
              onInputChanged={(val, err) => this.handleInputChanged('requestor', val, err)}
              value={this.state.requestor.value}
              title={translate('general.requestor')}
              error={this.state.requestor.errorkey && translate(this.state.requestor.errorkey)}
              maxLength={8}
            />
          </Column>
        </Row>
      </Card>
    )
  }


  onChangeNumericInput = (key, value) => {
    if (value === '' || value.match(/^[0-9]*$/)) {
      this.setState({
        [key]: {
          value,
          error: ''
        }
      })
    }
  }

  /**
   * @description Renders the additional card.
   */
  renderAdditionalCard = () => {
    const { id } = this.props

    return (
      <Card
        id={`${id}_additionalcard`}
        title={translate('general.additional')}>
        {/* user + owner row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* user */}
            <Input
              id={`${id}_user`}
              onInputChanged={(val, err) => this.handleInputChanged('user', val, err)}
              value={this.state.user.value}
              title={translate('user.user')}
              error={this.state.user.errorkey && translate(this.state.user.errorkey)}
              maxLength={8}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* owner */}
            <Input
              id={`${id}_owner`}
              onInputChanged={(val, err) => this.handleInputChanged('owner', val, err)}
              value={this.state.owner.value}
              title={translate('general.owner')}
              error={this.state.owner.errorkey && translate(this.state.owner.errorkey)}
              maxLength={8}
            />
          </Column>
        </Row>
        {/* doctype row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* doctype */}
            <Input
              id={`${id}_doctype`}
              onInputChanged={(val, err) => this.handleInputChanged('doctype', val, err)}
              value={this.state.doctype.value}
              title={translate('general.doctype')}
              error={this.state.doctype.errorkey && translate(this.state.doctype.errorkey)}
              maxLength={8}
            />
          </Column>
        </Row>
        {UserUtils.isLOGX() &&
          <Row>
            <Column colMD={6}>
              <Switch
                id={`${id}_filtered_by_rc`}
                title={translate('search.filtered_by_rc')}
                activeIndex={this.state.filteredByRC ? 0 : 1}
                onClick={index => this.setState({ filteredByRC: index === 0 })}
                items={[translate('general.yes'), translate('general.no')]}
                maxPerRow={2}
              />
            </Column>
          </Row>
        }
        {UserUtils.isLOGX() && this.state.filteredByRC &&
          <Row>
            <Column colMD={6}>
              <Switch
                id={`${id}_return_code`}
                title={translate('general.return_code')}
                activeIndex={this.state.returnCodeSwitch}
                onClick={index => this.setState({ returnCodeSwitch: index })}
                items={['<', '>', '=', '!=']}
                maxPerRow={4}
              />
            </Column>
            <Column
              colMD={6}
              offsetMD={0}>
              <Input
                id={`${id}_return_code`}
                onInputChanged={val => this.onChangeNumericInput('returnCodeInput', val)}
                value={this.state.returnCodeInput.value}
                error={this.state.returnCodeInput.error && translate(this.state.returnCodeInput.error)}
                ref={this.returnCodeInputRef}
                title={''}
                maxLength={5}
                required={`${translate('general.required_field')}`}
              />
            </Column>
          </Row>
        }
      </Card>
    )
  }

  /**
   * @description Renders the docuser variables card.
   */
  renderDocUserVariablesCard = () => {
    const { id } = this.props

    return (
      <Card
        id={`${id}_user_variables_card`}
        title={translate('general.docuservars')}>
        {/* docuser1 + docuser2 row */}
        <Row>
          <Column
            id={`${id}_docusercolumn_docuser1`}
            colMD={6}
            offsetMD={0}>
            {/* docuser1 */}
            <Input
              id={`${id}_docuser1`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser1', val, err) }}
              value={this.state.docuser1.value}
              title={translate('general.docuser1')}
              error={this.state.docuser1.errorkey && translate(this.state.docuser1.errorkey)}
              maxLength={32}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser2 */}
            <Input
              id={`${id}_docuser2`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser2', val, err) }}
              value={this.state.docuser2.value}
              title={translate('general.docuser2')}
              error={this.state.docuser2.errorkey && translate(this.state.docuser2.errorkey)}
              maxLength={32}
            />
          </Column>
        </Row>
        {/* docuser3 + docuser4 row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser3 */}
            <Input
              id={`${id}_docuser3`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser3', val, err) }}
              value={this.state.docuser3.value}
              title={translate('general.docuser3')}
              error={this.state.docuser3.errorkey && translate(this.state.docuser3.errorkey)}
              maxLength={32}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser4 */}
            <Input
              id={`${id}_docuser4`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser4', val, err) }}
              value={this.state.docuser4.value}
              title={translate('general.docuser4')}
              error={this.state.docuser4.errorkey && translate(this.state.docuser4.errorkey)}
              maxLength={32}
            />
          </Column>
        </Row>
        {/* docuser5 + docuser6 row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser5 */}
            <Input
              id={`${id}_docuser5`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser5', val, err) }}
              value={this.state.docuser5.value}
              title={translate('general.docuser5')}
              error={this.state.docuser5.errorkey && translate(this.state.docuser5.errorkey)}
              maxLength={32}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser6 */}
            <Input
              id={`${id}_docuser6`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser6', val, err) }}
              value={this.state.docuser6.value}
              title={translate('general.docuser6')}
              error={this.state.docuser6.errorkey && translate(this.state.docuser6.errorkey)}
              maxLength={32}
            />
          </Column>
        </Row>
        {/* docuser7 + docuser8 row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser7 */}
            <Input
              id={`${id}_docuser7`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser7', val, err) }}
              value={this.state.docuser7.value}
              title={translate('general.docuser7')}
              error={this.state.docuser7.errorkey && translate(this.state.docuser7.errorkey)}
              maxLength={32}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser8 */}
            <Input
              id={`${id}_docuser8`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser8', val, err) }}
              value={this.state.docuser8.value}
              title={translate('general.docuser8')}
              error={this.state.docuser8.errorkey && translate(this.state.docuser8.errorkey)}
              maxLength={32}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the document status.
   */
  renderDocStatus = () => {
    const { id } = this.props
    const controlStatusItems = this.getTranslatedDropdownItems(CONTROL_STATUS_ITEMS)
    const onlineItems = this.getTranslatedDropdownItems(ONLINE_ITEMS)
    const archivedItems = this.getTranslatedDropdownItems(ARCHIVED_ITEMS)
    const markedForReloadItems = this.getTranslatedDropdownItems(MARKED_FOR_RELOAD_ITEMS)
    const markedForDeleteItems = this.getTranslatedDropdownItems(MARKED_FOR_DELETE_ITEMS)
    const autoprintItems = this.getTranslatedDropdownItems(AUTOPRINT_ITEMS)
    const editingIconItems = this.getTranslatedEditingIconItems()
    const obtainedItems = this.getTranslatedDropdownItems(OBTAINED_ITEMS)

    return (
      <Card
        id={`${id}_doc_status_card`}
        title={translate('search.documentstatus')}>
        {/* control status + online row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* control status */}
            <Dropdown
              id={`${id}_controlstatus`}
              items={controlStatusItems}
              activeIndex={this.state.activeControlStatusIndex}
              onChange={(activeIndex) => { this.setState({ activeControlStatusIndex: activeIndex }) }}
              title={translate('search.controlstatus')}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* online */}
            <Dropdown
              id={`${id}_online`}
              items={onlineItems}
              activeIndex={this.state.activeOnlineIndex}
              onChange={(activeIndex) => { this.setState({ activeOnlineIndex: activeIndex }) }}
              title={translate('search.online')}
            />
          </Column>
        </Row>
        {/* control user + archived row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* control user */}
            <Input
              id={`${id}_controluser`}
              onInputChanged={(val, err) => { this.handleInputChanged('controlUser', val, err) }}
              value={this.state.controlUser.value}
              title={translate('search.controluser')}
              error={this.state.controlUser.errorkey && translate(this.state.controlUser.errorkey)}
              maxLength={8}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* archived */}
            <Dropdown
              id={`${id}_archived`}
              items={archivedItems}
              activeIndex={this.state.activeArchivedIndex}
              onChange={(activeIndex) => { this.setState({ activeArchivedIndex: activeIndex }) }}
              title={translate('search.archived')}
            />
          </Column>
        </Row>
        {/* marked for reload + marked for delete row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* marked for reload */}
            <Dropdown
              id={`${id}_markedforreload`}
              items={markedForReloadItems}
              activeIndex={this.state.activeMarkedForReloadIndex}
              onChange={(activeIndex) => { this.setState({ activeMarkedForReloadIndex: activeIndex }) }}
              title={translate('search.marked_for_reload')}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* marked for delete */}
            <Dropdown
              id={`${id}_markedfordelete`}
              items={markedForDeleteItems}
              activeIndex={this.state.activeMarkedForDeleteIndex}
              onChange={(activeIndex) => { this.setState({ activeMarkedForDeleteIndex: activeIndex }) }}
              title={translate('search.marked_for_delete')}
            />
          </Column>
        </Row>
        {/* autoprint + editing icon row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* autoprint */}
            <Dropdown
              id={`${id}_autoprint`}
              items={autoprintItems}
              activeIndex={this.state.activeAutoprintIndex}
              onChange={(activeIndex) => { this.setState({ activeAutoprintIndex: activeIndex }) }}
              title={translate('search.autoprint')}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* editing icon */}
            <Dropdown
              id={`${id}_editingicon`}
              items={editingIconItems}
              activeIndex={this.state.activeEditingIconIndex}
              onChange={(activeIndex) => { this.setState({ activeEditingIconIndex: activeIndex }) }}
              title={translate('import.editicon')}
            />
          </Column>
        </Row>
        {/* obtained row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* obtained */}
            <Dropdown
              id={`${id}_obtained`}
              items={obtainedItems}
              activeIndex={this.state.activeObtainedIndex}
              onChange={(activeIndex) => { this.setState({ activeObtainedIndex: activeIndex }) }}
              title={translate('search.obtained')}
            />
          </Column>
        </Row>
      </Card>
    )
  }
  /**
   * @description Renders the log status.
   */
  renderLogStatus = () => {
    const { id } = this.props
    const onlineItems = this.getTranslatedDropdownItems(ONLINE_ITEMS)
    const archivedItems = this.getTranslatedDropdownItems(ARCHIVED_ITEMS)
    const markedForReloadItems = this.getTranslatedDropdownItems(MARKED_FOR_RELOAD_ITEMS)
    const markedForDeleteItems = this.getTranslatedDropdownItems(MARKED_FOR_DELETE_ITEMS)
    const obtainedItems = this.getTranslatedDropdownItems(OBTAINED_ITEMS_LOGX)

    return (
      <Card
        id={`${id}_log_status_card`}
        title={translate('search.documentstatus')}>
        <Row>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_archived`}
              items={archivedItems}
              activeIndex={this.state.activeArchivedIndex}
              onChange={(activeIndex) => { this.setState({ activeArchivedIndex: activeIndex }) }}
              title={translate('search.archived')}
            />
          </Column>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_online`}
              items={onlineItems}
              activeIndex={this.state.activeOnlineIndex}
              onChange={(activeIndex) => { this.setState({ activeOnlineIndex: activeIndex }) }}
              title={translate('search.online')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_markedforreload`}
              items={markedForReloadItems}
              activeIndex={this.state.activeMarkedForReloadIndex}
              onChange={(activeIndex) => { this.setState({ activeMarkedForReloadIndex: activeIndex }) }}
              title={translate('search.marked_for_reload')}
            />
          </Column>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_markedfordelete`}
              items={markedForDeleteItems}
              activeIndex={this.state.activeMarkedForDeleteIndex}
              onChange={(activeIndex) => { this.setState({ activeMarkedForDeleteIndex: activeIndex }) }}
              title={translate('search.marked_for_delete')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_obtained`}
              items={obtainedItems}
              activeIndex={this.state.activeObtainedIndex}
              onChange={(activeIndex) => { this.setState({ activeObtainedIndex: activeIndex }) }}
              title={translate('search.obtained')}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the output status.
   */
  renderSelectBy = () => {
    const { id } = this.props
    return (
      <Card
        id={`${id}_select_by`}
        title={translate('search.select_by')}>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_select_by_type`}
              title={translate('general.type')}
              items={this.getSelectByItems(TYPE_ITEMS)}
              maxPerRow={3}
              activeIndex={this.state.activeType}
              onClick={index => this.setState({ activeType: index })}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_select_by_index`}
              title={translate('definition.index')}
              items={this.getSelectByItems(INDEX_ITEMS)}
              maxPerRow={3}
              activeIndex={this.state.activeIndex}
              onClick={index => this.setState({ activeIndex: index })}
            />
          </Column>
        </Row>
        {UserUtils.isDOCX() &&
          <Row>
            <Column colMD={12}>
              <Switch
                id={`${id}_select_by_notes`}
                title={translate('search.notes')}
                items={this.getSelectByItems(NOTES_ITEMS)}
                maxPerRow={3}
                activeIndex={this.state.activeNotes}
                onClick={index => this.setState({ activeNotes: index })}
              />
            </Column>
          </Row>
        }
      </Card>
    )
  }

  /**
   * @description Renders the output status.
   */
  renderOutputStatus = () => {
    const { id } = this.props
    return (
      <Card
        id={`${id}_output_status_card`}
        title={translate('search.outputstatus')}>
        {/* printed + error row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* printed */}
            <Checkbox
              id={`${id}_outputstatus_printed`}
              label={translate('search.outputstatus_printed')}
              onCheck={(val) => { this.handleCheckboxChanged('outputStatusPrinted', val) }}
              value={this.state.outputStatusPrinted}>
            </Checkbox>
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* error */}
            <Checkbox
              id={`${id}_outputstatus_error`}
              label={translate('search.outputstatus_error')}
              onCheck={(val) => { this.handleCheckboxChanged('outputStatusError', val) }}
              value={this.state.outputStatusError}>
            </Checkbox>
          </Column>
        </Row>
        {/* hold + waiting row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* hold */}
            <Checkbox
              id={`${id}_outputstatus_hold`}
              label={translate('search.outputstatus_hold')}
              onCheck={(val) => { this.handleCheckboxChanged('outputStatusHold', val) }}
              value={this.state.outputStatusHold}>
            </Checkbox>
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* waiting */}
            <Checkbox
              id={`${id}_outputstatus_waiting`}
              label={translate('search.outputstatus_waiting')}
              onCheck={(val) => { this.handleCheckboxChanged('outputStatusWaiting', val) }}
              value={this.state.outputStatusWaiting}>
            </Checkbox>
          </Column>
        </Row>
        {/* delete + notfound row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* delete */}
            <Checkbox
              id={`${id}_outputstatus_delete`}
              label={translate('search.outputstatus_delete')}
              onCheck={(val) => { this.handleCheckboxChanged('outputStatusDelete', val) }}
              value={this.state.outputStatusDelete}>
            </Checkbox>
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* notfound */}
            <Checkbox
              id={`${id}_outputstatus_notfound`}
              label={translate('search.outputstatus_notfound')}
              onCheck={(val) => { this.handleCheckboxChanged('outputStatusNotFound', val) }}
              value={this.state.outputStatusNotFound}>
            </Checkbox>
          </Column>
        </Row>
        {/* active + retry row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* active */}
            <Checkbox
              id={`${id}_outputstatus_active`}
              label={translate('search.outputstatus_active')}
              onCheck={(val) => { this.handleCheckboxChanged('outputStatusActive', val) }}
              value={this.state.outputStatusActive}>
            </Checkbox>
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* retry */}
            <Checkbox
              id={`${id}_outputstatus_retry`}
              label={translate('search.outputstatus_retry')}
              onCheck={(val) => { this.handleCheckboxChanged('outputStatusRetry', val) }}
              value={this.state.outputStatusRetry}>
            </Checkbox>
          </Column>
        </Row>
        {/* cancelled + requeued row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* cancelled */}
            <Checkbox
              id={`${id}_outputstatus_cancelled`}
              label={translate('search.outputstatus_canceled')}
              onCheck={(val) => { this.handleCheckboxChanged('outputStatusCancelled', val) }}
              value={this.state.outputStatusCancelled}>
            </Checkbox>
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* requeued */}
            <Checkbox
              id={`${id}_outputstatus_requeued`}
              label={translate('search.outputstatus_requeued')}
              onCheck={(val) => { this.handleCheckboxChanged('outputStatusRequeued', val) }}
              value={this.state.outputStatusRequeued}>
            </Checkbox>
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}
        tabIndex={-1}>
        {this.renderSelectorDialogs()}
        {this.renderTimeCard()}
        {this.renderGeneralCard()}
        {this.renderSelectBy()}
        {UserUtils.isDOCX() && this.renderAssignmentCard()}
        {UserUtils.isDOCX() && this.renderOutputCard()}
        {this.renderAdditionalCard()}
        {
          UserUtils.isDOCX()
            ? this.renderDocStatus()
            : this.renderLogStatus()
        }
        {this.renderDocUserVariablesCard()}
        {UserUtils.isDOCX() && this.renderOutputStatus()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleOnReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    selector: state.selector,
    datemask: state.auth.serverdata.preferences.DATEMASK,
    userprofile: state.auth.serverdata.userprofile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRecipientDefinitions: (fields, recipient, callback) => {
      ModalSelectorActions.getRecipientDefinitions(
        fields,
        undefined, // rtype
        undefined, // distribution type
        recipient,
        undefined, // preRecipient
        undefined, // owner
        undefined, // title
        undefined, // outputchannel
        undefined, // outputformat
        undefined, // ppn
        callback)(dispatch)
    },
    getFolderDefinitions: (fields, foldername, callback) => {
      ModalSelectorActions.getFolderDefinitions(
        fields,
        foldername,
        undefined, // folder title
        undefined, // owner
        undefined, // brw
        callback)(dispatch)
    },
    getOutputChanelDefinitions: (fields, outputChanel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChanel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback
      )(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        callback,
        false
      )(dispatch)
    },
    getDocuments: (fields, searchParams) => {
      StandardSelectionActions.getDocuments(
        fields,
        searchParams,
        undefined, // callback
      )(dispatch)
    },
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) },
  }
}

SearchStandardSearch.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchStandardSearch)