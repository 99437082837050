import React, { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { prepareClassNames } from '../../utils/ComponentUtils';
import useTimeout from '../../hooks/timeout';
import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
  /** Integer 0-100 represents progress of loading or filling up the progress ring */
  progress?: number;
  /** Boolean specifying whether there should be a background under the Spinner */
  bgArea?: boolean;
  /** Determines whether there is 'Progress Spinner' - includes 0-100 progress without filling up progress ring, but progress ring just spinning around  */
  progressSpinner?: boolean;
  /** Boolean to handle if loader should be visible */
  showLoader: boolean;
  /** Short info text under the spinner */
  text?: string;
  /** Indicates if loading spinner should be pinned to whole view or placed in container */
  isFixed?: boolean;
}

interface PortalSpinnerProps {
  children: ReactNode;
  showLoader: boolean;
}

/**
 * LoadingSpinner component created according to
 * _LoadingSpinner_ from style guide
 * [DCI UI-Styleguide 3-20210921](https://xd.adobe.com/view/90190a08-bc88-49ba-95b8-fc9ffa61f0fd-c54a/screen/85133093-ecfa-4721-ba71-b5ef4656ee87/).
 *
 * There exists 3 variants of loading spinner ('Loading Spinner', 'Progress Spinner', 'Progress Indicator'). Also default feature for 60% opacity Extralight Anthrazite background.
 * @param progress representing 0-100% of loading status
 * @param showLoader handle if loader should be visible
 */

const PortalSpinner = ({ children, showLoader }: PortalSpinnerProps): JSX.Element => {
  const portalSelector = document.querySelector('#loadingSpinner_Portal');
  const portalClassNames = prepareClassNames(['cl_background', showLoader && 'cl_show']);
  const [show, setShow] = useState<boolean | undefined>(undefined);

  const { clear, reset } = useTimeout(() => setShow(false), 400);

  useEffect(() => {
    if (showLoader) setShow(true);
    else reset();

    return () => {
      clear();
    };

  }, [showLoader, clear, reset]);

  if (portalSelector && show) {
    return (ReactDOM.createPortal(
        <div id={'LoadingSpinner'}>
          <div className={portalClassNames}>
            <div className="cl_spinner-container">
              {children}
            </div>
          </div>
        </div>, portalSelector)
    );
  } else {
    // console.error('PortalSpinner', 'Wrong portal id');
    return <></>;
  }
};

const Spinner = ({ text, progress, bgArea = true, progressSpinner, showLoader }: LoadingSpinnerProps): JSX.Element => {
  const radius = 45.5;
  const stroke = 9;
  const circumference = radius * 2 * Math.PI;
  let strokeDashoffset = circumference - (progress || 0) / 100 * circumference;

  if (!progressSpinner && !Number.isInteger(progress)) strokeDashoffset = circumference - 66 / 100 * circumference;
  if (progressSpinner && Number.isInteger(progress)) strokeDashoffset = circumference - 66 / 100 * circumference;

  const backgroundClassNames = prepareClassNames(['cl_spinner', bgArea && 'cl_spinner-bg', showLoader && 'cl_show']);
  const spinnerClassNames = prepareClassNames(['cl_progress-ring', (!Number.isInteger(progress) || progressSpinner) && 'cl_spinning']);
  return (<>
      <div className={backgroundClassNames}>
        <div className={spinnerClassNames}>
          {progress && (progress >= 0) && <span className="cl_progress-ring__counter">{progress}</span>}
          <svg
            height={radius * 2 + stroke}
            width={radius * 2 + stroke}
          >
            <circle
              strokeWidth={stroke}
              strokeDasharray={circumference + ' ' + circumference}
              r={radius}
              className={'cl_ring-bg'}
            />
            <circle
              strokeWidth={stroke}
              strokeDasharray={circumference + ' ' + circumference}
              style={{ strokeDashoffset }}
              r={radius}
              className={'cl_bar'}
            />

          </svg>
        </div>
      </div>
      {text && <div className={'cl_info'}>
        <h4>{text}</h4>
      </div>
      }
  </>
  );
};

const LoadingSpinner = ({ showLoader, isFixed = true, ...props }: LoadingSpinnerProps): JSX.Element => {
  const { text, progressSpinner, progress, bgArea } = props;

  if (isFixed) {
    return (
      <PortalSpinner showLoader={showLoader}>
        <Spinner text={text} progress={progress} bgArea={bgArea} progressSpinner={progressSpinner}
                 showLoader={showLoader}/>
      </PortalSpinner>
    );
  } else {
    return (
      <div className={'cl_relative-spinner'}>
        {showLoader &&
          <Spinner text={text} progress={progress} bgArea={bgArea} progressSpinner={progressSpinner}
                   showLoader={showLoader}/>}
      </div>);
  }
};

export default LoadingSpinner;
