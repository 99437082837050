import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as SortUtils from 'utils/SortUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CreatePPNDialog from 'components/dialogs/create_ppn_dialog/CreatePPNDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyPPNDialog from 'components/dialogs/modify_ppn_dialog/ModifyPPNDialog'
import VerifyPostprocessingNote from 'components/dialogs/verify_postprocessing_note/VerifyPostprocessingNote'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as PPNActions from 'redux/actions/PostProcessingNoteDefinitionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultPostProcessingNote extends Component {
  state = {
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    showVerifyDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: PPN_ID, translation: 'definition.postprocessing_note_id', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER }
    ]
  }

  handleAddEntry = () => {
    this.setState({ showCreateDialog: true })
  }

  handleRefresh = () => {
    const { preferences } = this.props
    const ppnid = preferences[Preferences.DEFINITION_PPNS_ID]
    const title = preferences[Preferences.DEFINITION_PPNS_TITLE]
    const owner = preferences[Preferences.DEFINITION_PPNS_OWNER]

    this.props.getPPNs(undefined, ppnid, title, owner)
  }

  handleModifyPPN = index => {
    const ppnid = this.props.ppns.data[index][this.props.ppns.header.indexOf('PPN')]
    const callback = () => {
      this.setState({
        showModifyDialog: true
      })
    }
    this.props.getPPN(undefined, ppnid, callback)
  }

  handleCopyPPN = index => {
    const ppnid = this.props.ppns.data[index][this.props.ppns.header.indexOf('PPN')]
    const callback = () => {
      this.setState({
        showCopyDialog: true
      })
    }
    this.props.getPPN(undefined, ppnid, callback)
  }

  handleDeletePPN = index => {
    const ppnid = this.props.ppns.data[index][this.props.ppns.header.indexOf('PPN')]
    const callback = () => {
      this.setState({ showDeleteDialog: true })
    }

    this.props.getPPN(undefined, ppnid, callback)
  }

  deletePPN = () => {
    const callback = () => {
      this.setState({ showDeleteDialog: false })
    }
    this.props.deletePPN(this.props.ppn.PPN, callback)
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Gets the verify information and shows the dialog
   */
  showVerify = index => {
    const { ppns, getPPN, verifyPPN } = this.props
    const ppnid = ppns.data[index][ppns.header.indexOf('PPN')]
    const callback = () => this.setState({ showVerifyDialog: true })
    getPPN(undefined, ppnid, () => {
      verifyPPN(ppnid, callback)
    })
  }


  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id, lang } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit', lang)}
        onClick={() => this.handleModifyPPN(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete', lang)}
        onClick={() => { this.handleDeletePPN(rowIndex) }}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options', lang)}>
        <TableButtonGroupItem
          onClick={() => this.handleModifyPPN(rowIndex)}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit', lang)}
          title={translate('general.edit', lang)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleCopyPPN(rowIndex)}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy', lang)}
          title={translate('general.copy', lang)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDeletePPN(rowIndex)}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete', lang)}
          title={translate('general.delete', lang)}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          id={`${id}_verifyBtn`}
          icon={<Icon name='verify' className='actionIcon' />}
          text={translate('general.verify', lang)}
          title={translate('general.verify', lang)}
          onClick={() => { this.showVerify(rowIndex, 'showVerify') }}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create', lang)}
          onClick={this.showPrefilledCreateDialog}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings', lang)}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description gets the index of the header in redux state definitions.ppns.header
   * @param {String} header header name of the header in redux definitions.state ppns.header
   */
  headerData = header => this.props.ppns.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, ppns } = this.props
    let data = []
    let headers = this.getUsedHeader()
    ppns.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_PPN]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_PPN].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_PPN]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_PPN].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  showPrefilledCreateDialog = () => {
    const { preferences } = this.props
    this.setState({
      showCreateDialog: true,
      createDialogData:
      { PPN: preferences[Preferences.DEFINITION_PPNS_ID],
        PPNTITLE: preferences[Preferences.DEFINITION_PPNS_TITLE],
        OWNER: preferences[Preferences.DEFINITION_PPNS_OWNER] } })
  }

  render = () => {
    const { ppns, ppn, id, loading, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showCopyDialog, showModifyDialog, showDeleteDialog, showVerifyDialog } = this.state
    let data = ppns && ppns.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {/* create postprocessing note dialog */}
        {showCreateDialog && (
          <CreatePPNDialog
            id={`${id}_createdialog`}
            copyPPN={false}
            prefilledData={this.state.createDialogData}
            onClose={() => this.setState({ showCreateDialog: false, createDialogData: undefined })}
          />
        )}
        {/* copy postprocessing note dialog */}
        {showCopyDialog && ppn && (
          <CreatePPNDialog
            id={`${id}_copydialog`}
            copyPPN={true}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        )}
        {/* modify postprocessing note dialog */}
        {showModifyDialog && ppn && (
          <ModifyPPNDialog
            id={`${id}_modifydialog`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        )}
        {/* delete postprocessing note dialog */}
        {showDeleteDialog && ppn && (
          <DeleteDialog
            id={`${id}_deletedialog`}
            title={translate('definition.ppn.del_ppn')}
            question={translate('definition.ppn.question_del_ppn')}
            onClose={() => { this.setState({ showDeleteDialog: false }) }}
            onDelete={() => { this.deletePPN() }}>
            <Row>
              <Column
                colMD={4}>
                <p id={`${id}_ppn_id_key_text`}>
                  {translate('definition.postprocessing_note_id')}:
                </p>
              </Column>
              <Column
                colMD={8}>
                <p id={`${id}_ppn_id_value_text`}>
                  {ppn.PPN}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_PPN }}
          />
        )
        }
        {
          showVerifyDialog &&
          <VerifyPostprocessingNote
            id={`${id}_verifydialog`}
            onClose={() => this.setState({ showVerifyDialog: false })}
          />
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if postprocessing note not exist in redux
            ppns
              ? (
                // show empty result if there are no postprocessing notes after searching
                data
                  ? (
                    <DataTable
                      loading={loading}
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={data}
                      selectable={true}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={this.handleModifyPPN}
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptyResult
                      description={translate('emptyresult.no_ppn_result')}
                      id={`${id}_emptysearchresult`}
                      link={translate('emptyresult.create_ppn_link')}
                      onClick={this.showPrefilledCreateDialog}
                      headline={translate('emptyresult.no_result_headline')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )}
        </ResultContainer>
      </>
    )
  }
}

const PPN_ID = 'PPN'
const TITLE = 'PPNTITLE'
const OWNER = 'OWNER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

SearchResultPostProcessingNote.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    ppns: state.definitions.ppns.ppns,
    ppn: state.definitions.ppns.ppn,
    keepPagination: state.definitions.ppns.keepPagination,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getPPNs: (fields, ppn, owner, title, callback) => {
      PPNActions.getPPNs(fields, ppn, owner, title, callback)(dispatch)
    },
    getPPN: (fields, ppn, callback) => {
      PPNActions.getPPN(fields, ppn, callback)(dispatch)
    },
    deletePPN: (ppn, callback) => {
      PPNActions.deletePPN(ppn, callback)(dispatch)
    },
    verifyPPN: (ppn, callback) => {
      PPNActions.verifyPPN(ppn, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultPostProcessingNote)