import { createContext, useContext } from 'react';

import * as TYPES_DOC_BROWSER from '../types/DocumentBrowser.types';

export const DocumentBrowserContext = createContext<Record<string, never> | TYPES_DOC_BROWSER.IDocumentBrowserContext>({});

export const useDocumentBrowserContext = () => {
  const context = useContext(DocumentBrowserContext);

  if (!context) {
    throw new Error('useDocumentBrowserContext must be used within DocumentBrowserProvider');
  }

  return context;
};