import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as SortUtils from 'utils/SortUtils'

import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CopyGroupDialog from 'components/dialogs/copy_group_dialog/CopyGroupDialog'
import CreateGroupDialog from 'components/dialogs/create_group_dialog/CreateGroupDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyGroupDialog from 'components/dialogs/modify_group_dialog/ModifyGroupDialog'
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'
import * as GroupActions from 'redux/actions/GroupActions'
import * as UserGroupAssignmentActions from 'redux/actions/UserGroupAssignmentActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultGroups extends Component {

  state = {
    showCreateGroupDialog: false,
    showDeleteGroupDialog: false,
    showModifyGroupDialog: false,
    showCopyGroupDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation(),
    assignmentsBlocked: false
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    return [
      { rest: BETAGRP, translation: 'group.group_id', default: true },
      { rest: GRPNAME, translation: 'group.groupname', default: true },
      // rest api do not send last changed parameter, its just for save a rest parameter when using tablesettings
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER }
    ]
  }

  /**
   * @description Shows the create group dialog.
   */
  handleOnAddEntry = () => {
    this.setState({ showCreateGroupDialog: true })
  }

  /**
   * @description Refreshs the table and call the search again.
   */
  handleOnRefresh = () => {
    const { preferences } = this.props
    const groupid = preferences[Preferences.USERMANAGEMENT_GROUPS_GROUPID]
    const groupname = preferences[Preferences.USERMANAGEMENT_GROUPS_GROUPNAME]

    this.props.getGroups(groupid, groupname)
  }

  /**
   * @description Shows the modify group dialog after getting the assignments and the clicked group.
   * @param {Number} index The index on which the user clicked.
   */
  handleOnModifyGroup = index => {
    // get the groupid from redux
    const groupid = this.props.groups.data[index][this.props.groups.header.indexOf('BETAGRP')]

    // declare success callback
    const showDialogCallback = () => { this.setState({ showModifyGroupDialog: true }) }

    // declare error callback
    const notAuthorizedCallback = () => {
      this.setState({ showModifyGroupDialog: true, assignmentsBlocked: true })
    }

    const getAssignmentsCallback = () => {
      this.props.getAssignments(
        groupid,
        showDialogCallback,
        notAuthorizedCallback
      )
    }

    // get the specific group
    this.props.getGroup(groupid, getAssignmentsCallback)
  }

  /**
   * @description Shows the copy dialog after getting the current group.
   */
  handleOnCopyGroup = index => {
    // get the groupid from redux
    const groupid = this.props.groups.data[index][this.props.groups.header.indexOf('BETAGRP')]

    // declare success callback
    const callback = () => { this.setState({ showCopyGroupDialog: true }) }

    // get the specific group
    this.props.getGroup(groupid, callback)
  }

  /**
   * @description Shows the delete dialog after gettings the current group.
   */
  handleOnDeleteGroup = (index) => {
    // get the groupid from redux
    const groupid = this.props.groups.data[index][this.props.groups.header.indexOf('BETAGRP')]

    // declare success callback
    const callback = () => { this.setState({ showDeleteGroupDialog: true }) }

    // get the specific group
    this.props.getGroup(groupid, callback)
  }

  /**
   * @description Delete the current group.
   */
  deleteGroup = () => {
    const { group, userid, deleteGroup } = this.props

    // declare success callback
    const callback = () => { this.setState({ showDeleteGroupDialog: false }) }

    deleteGroup(group.BETAGRP, userid, group.GRPNAME, callback)
  }

  /**
   * Gets the viewdata with jsx elements for icon (don't use this for download as csv)
   */
  getViewData() {
    const { datemask, groups } = this.props
    let data = []
    let headers = this.getUsedHeader()
    groups.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[groups.header.indexOf('CDATE')], element[groups.header.indexOf('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[groups.header.indexOf('CDATE')], element[groups.header.indexOf('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[groups.header.indexOf('CDATE')], element[groups.header.indexOf('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[groups.header.indexOf(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }


  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to show.
   * @param {Array} header the headers of the columns.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Creates the actionbuttons the a row.
   * @param {Number} rowIndex The row index.
   */
  createActionButtons = (rowIndex) => {
    const { id, lang } = this.props

    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconName='edit'
        title={translate('general.edit', lang)}
        onClick={() => this.handleOnModifyGroup(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconName='delete'
        title={translate('general.delete', lang)}
        onClick={() => { this.handleOnDeleteGroup(rowIndex) }}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options', lang)}>
        <TableButtonGroupItem
          onClick={() => this.handleOnModifyGroup(rowIndex)}
          id={`${id}_editBtn`}
          icon={<Icon id={`edit_icon_row${rowIndex}`} name='edit' className='actionIcon' />}
          text={translate('general.edit', lang)}
          title={translate('general.edit', lang)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleOnCopyGroup(rowIndex)}
          id={`${id}_copyBtn`}
          icon={<Icon id={`copy_icon_row${rowIndex}`} name='copy' className='actionIcon' />}
          text={translate('general.copy', lang)}
          title={translate('general.copy', lang)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleOnDeleteGroup(rowIndex)}
          id={`${id}_deleteBtn`}
          icon={<Icon id={`delete_icon_row${rowIndex}`} name='delete' className='actionIcon' />}
          text={translate('general.delete', lang)}
          title={translate('general.delete', lang)}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create', lang)}
          onClick={this.handleOnAddEntry}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleOnRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings', lang)}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_GROUPS]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_GROUPS].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_GROUPS]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_USERMANAGEMENT_GROUPS].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  render = () => {
    const { id, groups, loading, drawerExpanded, lang, group, autoDrawer, datemask, removeAllUsers, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateGroupDialog, showModifyGroupDialog, showCopyGroupDialog, showDeleteGroupDialog, assignmentsBlocked } = this.state
    const data = groups && groups.data ? this.getViewData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {/* create group dialog */}
        {showCreateGroupDialog && (
          <CreateGroupDialog
            id={`${id}_creategroupdialog`}
            visible={showCreateGroupDialog}
            onClose={() => { this.setState({ showCreateGroupDialog: false }) }}
          />
        )}

        {/* modify group dialog */}
        {showModifyGroupDialog && group && (
          <ModifyGroupDialog
            id='modifygroupdialog'
            visible={showModifyGroupDialog}
            assignmentsBlocked={assignmentsBlocked}
            onClose={() => {
              removeAllUsers()
              this.setState({ showModifyGroupDialog: false })
            }}
          />
        )}

        {/* copy group dialog */}
        {showCopyGroupDialog && group && (
          <CopyGroupDialog
            id='copygroupdialog'
            visible={showCopyGroupDialog}
            onClose={() => {
              removeAllUsers()
              this.setState({ showCopyGroupDialog: false })
            }}
          />
        )}

        {/* delete group dialog */}
        {showDeleteGroupDialog && group && (
          <DeleteDialog
            id={`${id}_deletegroupdialog`}
            title={translate('usermanagement.delete_group')}
            question={translate('usermanagement.question_delete_group')}
            visible={showDeleteGroupDialog}
            onClose={() => this.setState({ showDeleteGroupDialog: false })}
            onDelete={() => this.deleteGroup()}
          >
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_groupid_row_titlescol_groupid`}>
                  {translate('group.group_id')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_groupid_row_valuescol_groupid`}>
                  {this.props.group.BETAGRP}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_groupname_row_titlescol_groupname`}>
                  {translate('group.groupname')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_groupname_row_valuescol_groupname`}>
                  {this.props.group.GRPNAME}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {
          showTableSettingsDialog && (
            <TableSettings
              id={id}
              onClose={() => this.setState({ showTableSettingsDialog: false })}
              headers={this.state.header}
              prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_USERMANAGEMENT_GROUPS }}
            />
          )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if groups not exist in redux
            groups
              ? (
                // show empty result if there are no groups after searching
                data
                  ? (
                    <DataTable
                      loading={loading}
                      id={id}
                      header={translatedHeaders}
                      data={this.getViewData()}
                      cleanData={data}
                      selectable={true}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={this.handleOnModifyGroup}
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptyResult
                      description={translate('emptyresult.no_groups_result')}
                      id={`${id}_emptyresult`}
                      link={translate('emptyresult.create_group_link')}
                      onClick={this.handleOnAddEntry}
                      headline={translate('emptyresult.no_result_headline')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )}
        </ResultContainer>
      </>
    )
  }
}

const BETAGRP = 'BETAGRP'
const GRPNAME = 'GRPNAME'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

SearchResultGroups.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    groups: state.group.groups,
    group: state.group.group,
    keepPagination: state.group.keepPagination,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getGroup: (groupid, callback) => {
      GroupActions.getGroup(groupid, callback)(dispatch)
    },
    getGroups: (groupid, groupname) => {
      GroupActions.getGroups(groupid, groupname)(dispatch)
    },
    deleteGroup: (groupid, userid, groupname, callback) => {
      GroupActions.deleteGroup(groupid, userid, groupname, callback)(dispatch)
    },
    removeAllUsers: () => UserGroupAssignmentActions.removeAllUsers()(dispatch),
    getAssignments: (groupid, successCallback, errorCallback) => {
      UserGroupAssignmentActions.getGroupAssignments(groupid, successCallback, errorCallback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultGroups)