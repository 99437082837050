import React, { Component } from 'react'
import { connect } from 'react-redux'

import './ImportCustomImport.scss'

import Button from 'BetaUX2Web-Components/src/components/button/Button'
import CustomDialog from 'components/dialogs/custom_dialog/CustomDialog'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Row from 'BetaUX2Web-Components/src/components/row/Row'

import * as SnackbarActions from 'redux/actions/SnackbarActions'
import { translate } from 'language/Language'
import * as Preferences from 'redux/general/Preferences'
import * as UploadActions from 'redux/actions/UploadActions'

// Utils
import { isAdmin } from 'utils/UserUtils'

const keys = {
  STRING: 'string',
  NUMERIC: 'numeric',
  AMOUNT: 'amount',
  DATE: 'date',
  DATETIME: 'datetime',
  DATEDATE: 'datedate',
  SCHOICE: 'schoice',
  MCHOICE: 'mchoice',
  CCHOICE: 'cchoice',
  CCHOICE2: 'cchoice2',
  LCHOICE: 'lchoice',
  DTFRAME: 'dtframe',
  FILEOPEN: 'fileopen'
}

class ImportCustomImport extends Component {

  state = {
    defaultState: undefined,
    searchButtonIsDisabled: false
  }

  componentDidUpdate = () => {
    if (this.hasDocumentNodeSelection() !== this.state.searchButtonIsDisabled) {
      this.setState({ searchButtonIsDisabled: this.hasDocumentNodeSelection() })
    }
  }

  // This variable will be overwritten when the getState prop was called in the child component to make it possible to access the child components state.
  getCustomDialogState = undefined

  // This variable will be overwritten when the overwriteState prop was called in the child component to make it possible to overwrite the child components state.
  overwriteCustomDialogState = undefined

  handleFocus = state => {
    const { customDialog } = this.props
    const headerIndex = name => customDialog.OBJECTS.header.indexOf(name)
    let focused = false

    for (let i = 0; i < customDialog.OBJECTS.data.length; i++) {
      if (!focused) {
        const key = customDialog.OBJECTS.data[i][headerIndex('SPIDTYPE')]
        const currentTypElements = Object.values(state[keys[key]])
        let currentElement
        for (let j = 0; j < currentTypElements.length; j++) {
          if ([keys.STRING, keys.NUMERIC, keys.DATE, keys.CCHOICE, keys.DTFRAME].includes(keys[key])) {
            if (currentTypElements[j].restKey === customDialog.OBJECTS.data[i][headerIndex('IXINAME')]) {
              currentElement = currentTypElements[j]
              if (currentElement.error !== '' && currentElement.ref) {
                focused = true
                currentElement.ref.current && currentElement.ref.current.focus()
              }
              break
            }
          }
          else if (keys[key] === keys.DATETIME) {
            if ([currentTypElements[j].date.restKey, currentTypElements[j].time.restKey].includes(customDialog.OBJECTS.data[i][headerIndex('IXINAME')])) {
              currentElement = currentTypElements[j]
              if (currentElement.date.error !== '' && currentElement.date.ref) {
                focused = true
                currentElement.date.ref.current && currentElement.date.ref.current.focus()
              }
              else if (currentElement.time.error !== '' && currentElement.time.ref) {
                focused = true
                currentElement.time.ref.current && currentElement.time.ref.current.focus()
              }
              break
            }
          }
          else if (keys[key] === keys.DATEDATE) {
            if ([currentTypElements[j].from.restKey, currentTypElements[j].from.restKey].includes(customDialog.OBJECTS.data[i][headerIndex('IXINAME')])) {
              currentElement = currentTypElements[j]
              if (currentElement.from.error !== '' && currentElement.from.ref) {
                focused = true
                currentElement.from.ref.current && currentElement.from.ref.current.focus()
              }
              else if (currentElement.to.error !== '' && currentElement.to.ref) {
                focused = true
                currentElement.to.ref.current && currentElement.to.ref.current.focus()
              }
              break
            }
          }
          else if (keys[key] === keys.CCHOICE2) {
            if ([currentTypElements[j].form.restKey, currentTypElements[j].ext.restKey].includes(customDialog.OBJECTS.data[i][headerIndex('IXINAME')])) {
              currentElement = currentTypElements[j]
              if (currentElement.form.error !== '' && currentElement.form.ref) {
                focused = true
                currentElement.form.ref.current && currentElement.form.ref.current.focus()
              }
              else if (currentElement.ext.error !== '' && currentElement.ext.ref) {
                focused = true
                currentElement.ext.ref.current && currentElement.ext.ref.current.focus()
              }
              break
            }
          }
        }
      }
      else {
        break
      }
    }
  }

  hasDocumentNodeSelection = () => {
    const { customDialog } = this.props
    if (customDialog) {
      const type = customDialog[2]
      if (type === 'IMPORT') {
        return customDialog[6] === 'YES'
      }
      else {
        return customDialog[5] === 'YES'
      }
    }
    return
  }

  hasElementError = (entry, result, keys) => {
    // eslint-disable-next-line
    for (const [index, el] of Object.entries(entry)) {
      // eslint-disable-next-line
      keys.forEach(key => {
        if (key !== undefined) {
          if (el[key].error !== '') {
            result = true
          }
        }
        else {
          if (el.error !== '') {
            result = true
          }
        }
      })
    }
    return result
  }

  errorFound = state => {
    let result = false
    for (const [key, value] of Object.entries(state)) {
      if ([keys.STRING, keys.NUMERIC, keys.DATE, keys.CCHOICE, keys.FILEOPEN].includes(key)) {
        result = this.hasElementError(value, result, [undefined])
      }
      else if (key === keys.AMOUNT) {
        result = this.hasElementError(value, result, ['input'])
      }
      else if (key === keys.DATETIME) {
        result = this.hasElementError(value, result, ['date', 'time'])
      }
      else if (key === keys.DATEDATE) {
        result = this.hasElementError(value, result, ['from', 'to'])
      }
      else if (key === keys.CCHOICE2) {
        result = this.hasElementError(value, result, ['form', 'ext'])
      }
      else if (key === keys.DTFRAME) {
        result = this.hasElementError(value, result, ['fromDate', 'fromTime', 'toDate', 'toTime'])
      }
    }
    this.handleFocus(state)
    return result
  }

  validateSingleEntry = (state, key, value) => {
    let newEl = { ...state[key] }
    // eslint-disable-next-line
    for (const [index, el] of Object.entries(value)) {
      if ([keys.STRING, keys.NUMERIC, keys.CCHOICE, keys.DATE].includes(key)) {
        if (el.error !== undefined && el.error === '' && el.required) {
          if (el.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], error: translate('general.input_required') } }
          }
        }
      }
      else if ([keys.DATEDATE].includes(key)) {
        if (el.from.error !== undefined && el.from.error === '' && el.from.required) {
          if (el.from.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], from: { ...newEl[index].from, error: translate('general.input_required') } } }
          }
        }
        if (el.to.error !== undefined && el.to.error === '' && el.to.required) {
          if (el.to.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], to: { ...newEl[index].to, error: translate('general.input_required') } } }
          }
        }
      }
      else if ([keys.DATETIME].includes(key)) {
        if (el.date.error !== undefined && el.date.error === '' && el.date.required) {
          if (el.date.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], date: { ...newEl[index].date, error: translate('general.input_required') } } }
          }
        }
        if (el.time.error !== undefined && el.time.error === '' && el.time.required) {
          if (el.time.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], time: { ...newEl[index].time, error: translate('general.input_required') } } }
          }
        }
      }
      else if ([keys.CCHOICE2].includes(key)) {
        if (el.form.error !== undefined && el.form.error === '' && el.form.required) {
          // The FORMEXT object is validated together.
          if (el.form.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], form: { ...newEl[index].form, error: translate('general.input_required') } } }
          }
          if (el.ext.value === '') {
            newEl = { ...newEl, [index]: { ...newEl[index], ext: { ...newEl[index].ext, error: translate('general.input_required') } } }
          }
        }
      }
      else if ([keys.FILEOPEN].includes(key)) {
        if (el.error !== undefined && el.error === '' && el.required) {
          if (el.value.length === 0) {
            newEl = { ...newEl, [index]: { ...newEl[index], error: translate('general.input_required') } }
          }
        }
      }
    }
    return newEl
  }

  validateElements = (state, callback) => {
    let result = state
    for (const [key, value] of Object.entries(state)) {
      if (!['currentModalSelectorInformation', 'documentNodeSelection'].includes(key) && Object.keys(value).length > 0) {
        result = { ...result, [key]: { ...this.validateSingleEntry(state, key, value) } }
      }
    }
    this.overwriteCustomDialogState(result, callback)
  }

  buildSingleEntry = (key, value) => {
    let result = {}
    if ([keys.STRING, keys.NUMERIC, keys.CCHOICE, keys.DATE].includes(key)) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        result = { ...result, [el.restKey]: el.value }
      }
    }
    else if ([keys.LCHOICE, keys.SCHOICE].includes(key)) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        let requestValue = el.dbValues[el.value]
        let requestKey = el.restKey
        // 'SPFORMAT' will be translated to request key 'mvs' if the value of this field is not 'FEXT'.
        if (el.restKey === 'SPFORMAT') {
          // 'mvs' needs to have a empty value when the key is changed to 'useExt'. Because of 'mvs' is empty by default, we don't need to change the 'mvs' key value pair here.
          // For better understanding why we need to do this, check the 'handleSubmitImport' function inside ImportStandardImport.jsx.
          if (requestValue === 'FEXT') {
            requestKey = 'useExt'
            requestValue = true
          }
          // default value 'NO' need to be translated to empty string. Default values com from REST-API. Some values need to be changed for the import route.
          else if (requestValue === 'NO') {
            requestValue = ''
          }
        }
        result = { ...result, [requestKey]: requestValue }
      }
    }
    else if (key === keys.CCHOICE2) {
      // eslint-disable-next-line
      for (const [index, el] of Object.entries(value)) {
        result = { ...result, 'form': el.form.value, 'extension': el.ext.value }
      }
    }
    return result
  }

  buildRequestObj = state => {
    let result = {}
    for (const [key, value] of Object.entries(state)) {
      if (!['currentModalSelectorInformation', 'documentNodeSelection'].includes(key) && Object.keys(value).length > 0) {
        result = { ...result, ...this.buildSingleEntry(key, value) }
      }
    }
    result = this.formatDocuser(result)
    return result
  }

  /**
   * @description Formats docuser variables into request format.
   */
  formatDocuser = obj => {
    let buffer = []
    for (let i = 1; i <= 8; i++) {
      if (obj[`DOCUSR${i}`] !== undefined) {
        buffer.push(obj[`DOCUSR${i}`])
        delete obj[`DOCUSR${i}`]
      }
    }
    if (buffer.length > 0) {
      obj['DOCUSR'] = buffer
    }
    return obj
  }

  /**
   * @description Handles the submit import action.
   * @param {Object} event The event which is thrown by the button
   */
  handleSubmitImport = event => {
    event.preventDefault()
    // Check if variable has a function, when not no custom dialog was loaded
    if (this.getCustomDialogState) {
      const customDialogState = this.getCustomDialogState()
      const validateCallback = () => {
        const customDialogState = this.getCustomDialogState()
        if (!this.errorFound(customDialogState)) {
          const importObj = this.buildRequestObj(customDialogState)
          const importCallback = () => this.overwriteCustomDialogState(this.state.defaultState)
          // Take the value of the first entry, because its just possible to have one of this object.
          // Fallback if no INFILE element is used in custom dialog so we have no file to uplaod.
          const file = Object.keys(customDialogState.fileopen).length > 0 ? customDialogState.fileopen[Object.keys(customDialogState.fileopen)[0]].value : []
          this.props.upload(importObj, file, importCallback)
        }
      }
      this.validateElements(customDialogState, validateCallback)
    }
  }


  renderMain = () => {
    const { id, customDialog, lang } = this.props
    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {
          customDialog?.OBJECTS?.data && customDialog.OBJECTS.data.length > 0
            // Props cannot be overwritten in the child component so we overwrite the variable here with the callback of the callbackoverwrite function.
            // In case of not understanding, contact KEHU (kevin.hubert@betasystems.com).
            ? <CustomDialog
              id={id}
              getState={newFunction => {
                // Always get the new default state when the custom dialog is changing.
                this.setState({ defaultState: newFunction() })
                return this.getCustomDialogState = newFunction
              }}
              overwriteState={newFunction => this.overwriteCustomDialogState = newFunction}
              customDialog={customDialog.OBJECTS.data}
              customDialogInfo={customDialog}
              headerIndex={name => customDialog.OBJECTS.header.indexOf(name)}
              lang={lang}
              updateParent={() => this.forceUpdate()}
            />
            : (
              <Card>
                <Row>
                  <div className='bux_no_customimport_drawer_container'>
                    <p className='bux_no_customimport_drawer_description'>
                      {translate('general.no_customdialog_defined')}
                    </p>
                    {
                      isAdmin() &&
                      <a className='bux_no_customimport_drawer_description_link'
                        href="/definition/customdialog">
                        {translate('general.no_customdialog_create')}
                      </a>
                    }
                  </div>
                </Row>
              </Card>
            )
        }
      </div>
    )
  }

  /**
 * @description Renders the footer.
 */
  renderFooter = () => {
    const { id } = this.props
    let customDialogState
    if (this.getCustomDialogState) {
      customDialogState = this.getCustomDialogState()
    }
    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_import`}
          text={translate('general.import')}
          onClick={event => this.handleSubmitImport(event)}
          submit
          primary
          disabled={customDialogState && this.state.searchButtonIsDisabled && !customDialogState.documentNodeSelection.selected}
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={() => this.overwriteCustomDialogState(this.state.defaultState)}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className={'bux_drawer_form'}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    customDialog: state.customDialogs.customDialog,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => SnackbarActions.show(message, type)(dispatch),
    upload: (importParams, files, callback) => UploadActions.upload(importParams, files, callback)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportCustomImport)