import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as UserUtils from 'utils/UserUtils'
import * as Utils from 'utils/Utils'

// components
import {
  Button, Card, Column, Dropdown, Icon, Input,
  Modal as ModalComponent, NumericSpinner, Row
} from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import { getRecDocAssignments, getRecipientDistributions } from 'utils/DefinitionUtils'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import { createRecipientDocumentAssignment } from 'redux/actions/RecipientDocumentAssignmentActions'

class CreateRecipientDocumentAssignmentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    recipientID: {
      value: '',
      error: ''
    },
    assignmentIndex: 0,
    distributionViaIndex: 0,
    form: {
      value: '',
      error: ''
    },
    extension: '',
    report: '',
    dynamicReport: '',
    outputChannel: '',
    outputFormat: '',
    ppn: '',
    copies: 1,
    fromGen: 0,
    toGen: 0,
    showRecipientSelectorDialog: false,
    showDocumentSelectorDialog: false,
    showOutputChannelSelectorDialog: false,
    showOutputFormatSelectorDialog: false,
    showPpnSelectorDialog: false
  }

  recipientIdInput = React.createRef()
  formInput = React.createRef()

  componentDidMount = () => {
    this.recipientIdInput.current.focus()
  }

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param val New value of inputfield
   * @param isRequired Wether the input is required or not
   */
  handleInput = (key, value, isRequired) => {
    if (isRequired) {
      this.setState({
        [key]: {
          value,
          error: ''
        }
      })
    } else {
      this.setState({ [key]: value })
    }
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  handleRecipientSelector = () => {
    const { getRecipientDefinitions } = this.props
    const { recipientID } = this.state
    const callback = () => this.setState({ showRecipientSelectorDialog: true })
    getRecipientDefinitions(['RECI', 'TITLE'], recipientID.value, callback)
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  handleDocumentSelector = () => {
    const { getDocumentDefinitions } = this.props
    const { form, extension, report } = this.state
    const callback = () => this.setState({ showDocumentSelectorDialog: true })
    getDocumentDefinitions(['FORM', 'EXT', 'REPORT'], form.value, extension, report, callback)
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  handleOutputChannelSelector = () => {
    const { getOutputChannelDefinitions } = this.props
    const { outputChannel } = this.state
    const callback = () => this.setState({ showOutputChannelSelectorDialog: true })
    getOutputChannelDefinitions(['DCR', 'DCRTITLE'], outputChannel, callback)
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  handleOutputFormatSelector = () => {
    const { getOutputFormatDefinitions } = this.props
    const { outputFormat } = this.state
    const callback = () => this.setState({ showOutputFormatSelectorDialog: true })
    getOutputFormatDefinitions(['PCR', 'PCRTITLE'], outputFormat, callback)
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  handlePpnSelector = () => {
    const { getPPNDefinitions } = this.props
    const { ppn } = this.state
    const callback = () => this.setState({ showPpnSelectorDialog: true })
    getPPNDefinitions(['PPN', 'PPNTITLE'], ppn, callback)
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id, selector: { recipients, documents, outputchannels, outputformats, ppns } } = this.props
    const { showRecipientSelectorDialog, showDocumentSelectorDialog, showOutputChannelSelectorDialog, showOutputFormatSelectorDialog, showPpnSelectorDialog } = this.state

    return (
      <>
        {showRecipientSelectorDialog && (
          <SelectorDialog
            id={`${id}_recipientdefinition_selector_dialog`}
            onClose={() => this.setState({ showRecipientSelectorDialog: false })}
            title={translate('definition.recipientdefinitions')}
            header={[
              translate('general.recipient'),
              translate('general.title'),
            ]}
            items={recipients.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const recipientID = recipients.data[selectedRows][recipients.header.indexOf('RECI')]
                this.setState({
                  recipientID: { value: recipientID, error: '' },
                  showRecipientSelectorDialog: false
                })
              } else {
                this.setState({ showRecipientSelectorDialog: false })
              }
            }}
          />
        )}
        {showDocumentSelectorDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report')
            ]}
            items={documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const form = documents.data[selectedRows][documents.header.indexOf('FORM')]
                const extension = documents.data[selectedRows][documents.header.indexOf('EXT')]
                const report = documents.data[selectedRows][documents.header.indexOf('REPORT')]
                this.setState({
                  form: { value: form, error: '' },
                  extension,
                  report,
                  showDocumentSelectorDialog: false
                })
              } else {
                this.setState({ showDocumentSelectorDialog: false })
              }
            }}
          />
        )}
        {showOutputChannelSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputchanneldefinition_selector_dialog`}
            onClose={() => this.setState({ showOutputChannelSelectorDialog: false })}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('import.outputchannel'),
              translate('general.title')
            ]}
            items={outputchannels.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const outputChannel = outputchannels.data[selectedRows][outputchannels.header.indexOf('DCR')]
                this.setState({
                  outputChannel,
                  showOutputChannelSelectorDialog: false
                })
              } else {
                this.setState({ showOutputChannelSelectorDialog: false })
              }
            }}
          />
        )}
        {showOutputFormatSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputformatdefinition_selector_dialog`}
            onClose={() => this.setState({ showOutputFormatSelectorDialog: false })}
            title={translate('definition.outputformatdefinitions')}
            header={[
              translate('import.outputformat'),
              translate('general.title')
            ]}
            items={outputformats.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const outputFormat = outputformats.data[selectedRows][outputformats.header.indexOf('PCR')]
                this.setState({
                  outputFormat,
                  showOutputFormatSelectorDialog: false
                })
              } else {
                this.setState({ showOutputFormatSelectorDialog: false })
              }
            }}
          />
        )}
        {showPpnSelectorDialog && (
          <SelectorDialog
            id={`${id}_ppndefinition_selector_dialog`}
            onClose={() => this.setState({ showPpnSelectorDialog: false })}
            title={translate('definition.ppndefinitions')}
            header={[
              translate('definition.postprocessing_note'),
              translate('general.title')
            ]}
            items={ppns.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const ppn = ppns.data[selectedRows][ppns.header.indexOf('PPN')]
                this.setState({
                  ppn,
                  showPpnSelectorDialog: false
                })
              } else {
                this.setState({ showPpnSelectorDialog: false })
              }
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Validates the recipient id.
   */
  validateRecipient = () => {
    const { recipientID } = this.state
    if (recipientID.value !== '') {
      return {}
    }
    return {
      recipientID: {
        ...this.state.recipientID,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the form.
   */
  validateForm = () => {
    const { form } = this.state
    if (form.value !== '') {
      return {}
    }
    return {
      form: {
        ...this.state.form,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = {
      ...this.validateRecipient(),
      ...this.validateForm()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input which has an error.
   */
  handleFocus = () => {
    const { form, recipientID } = this.state
    const requiredInputs = [
      { inputRef: this.recipientIdInput, error: recipientID.error },
      { inputRef: this.formInput, error: form.error },
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { createRecipientDocumentAssignment, onClose } = this.props
    const {
      recipientID,
      assignmentIndex,
      distributionViaIndex,
      form,
      extension,
      report,
      dynamicReport,
      outputChannel,
      outputFormat,
      ppn,
      copies,
      fromGen,
      toGen
    } = this.state
    if (this.validateInputs()) {
      const assignment = getRecDocAssignments()[assignmentIndex].key
      const distributionVia = getRecipientDistributions()[distributionViaIndex].key
      const reciDocument = {
        RECI: recipientID.value,
        FORM: form.value,
        EXT: extension,
        REPORT: report,
        WREPORT: dynamicReport,
        LTYPE: assignment,
        LRIFGEN: fromGen,
        LRITGEN: toGen,
        LRICOPY: copies,
        BRWS: distributionVia,
        PCR: outputFormat,
        DCR: outputChannel,
        PPN: ppn
      }
      createRecipientDocumentAssignment(reciDocument, () => onClose())
    }
  }

  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.create_assignment')} ${translate('general.recipient_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'folder_document_relation_modal_title'} name={'relation'} tabIndex={-1}/>,
      <div
        key={'title_3'}>
        {`${UserUtils.isDOCX()
          ? translate('general.document_modal_title')
          : translate('general.log_modal_title')}  ${translate('general.assignment_modal_title')}`}
      </div>,
    ]
  }

  render = () => {
    const { id, onClose } = this.props
    const {
      recipientID,
      assignmentIndex,
      distributionViaIndex,
      form,
      extension,
      report,
      dynamicReport,
      outputChannel,
      outputFormat,
      ppn,
      copies,
      fromGen,
      toGen
    } = this.state

    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={this.getModalTitle()}
          onClose={onClose}
        />
        <Main id={id}>
          {this.renderSelectorDialogs()}
          <Card>
            <Row>
              <Column colMD={3}>
                <Input
                  id={`${id}_recipient_id`}
                  onInputChanged={value => this.handleInput('recipientID', value, true)}
                  value={recipientID.value}
                  error={recipientID.error && translate(recipientID.error)}
                  title={translate('recipient.reci_id')}
                  ref={this.recipientIdInput}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.handleRecipientSelector(),
                  }}
                  onBlur={() => this.setState({ ...this.validateRecipient() })}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
              <Column colMD={3}>
                <Dropdown
                  id={`${id}_assignment`}
                  title={translate('general.assignment')}
                  items={getRecDocAssignments().map(el => translate(el.translationKey))}
                  activeIndex={assignmentIndex}
                  onChange={value => this.handleInput('assignmentIndex', value)}
                  value={assignmentIndex}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
              <Column colMD={3} className={'bux_pr8'}>
                <Dropdown
                  id={`${id}_distribution`}
                  title={translate('recipient.distribution_via')}
                  items={getRecipientDistributions().map(el => translate(el.translationKey))}
                  activeIndex={distributionViaIndex}
                  onChange={value => this.handleInput('distributionViaIndex', value)}
                  value={distributionViaIndex}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Input
                  id={`${id}_form`}
                  onInputChanged={value => this.handleInput('form', value, true)}
                  value={form.value}
                  error={form.error && translate(form.error)}
                  title={translate('general.form')}
                  ref={this.formInput}
                  maxLength={8}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.handleDocumentSelector(),
                  }}
                  onBlur={() => this.setState({ ...this.validateForm() })}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
              <Column colMD={3}>
                <Input
                  id={`${id}_extension`}
                  onInputChanged={value => this.handleInput('extension', value)}
                  value={extension}
                  title={translate('general.extension')}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.handleDocumentSelector(),
                  }}
                />
              </Column>
              <Column colMD={3}>
                <Input
                  id={`${id}_report`}
                  onInputChanged={value => this.handleInput('report', value)}
                  value={report}
                  title={translate('general.report')}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.handleDocumentSelector(),
                  }}
                />
              </Column>
              <Column colMD={3}>
                <Input
                  id={`${id}_dynamicReport`}
                  onInputChanged={value => this.handleInput('dynamicReport', value)}
                  value={dynamicReport}
                  title={translate('general.dynamic_report')}
                  maxLength={16}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <hr />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Input
                  id={`${id}_outputchannel`}
                  onInputChanged={value => this.handleInput('outputChannel', value)}
                  title={translate('import.outputchannel')}
                  value={outputChannel}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.handleOutputChannelSelector(),
                  }}
                />
              </Column>
              <Column colMD={3}>
                <Input
                  id={`${id}_outputformat`}
                  onInputChanged={value => this.handleInput('outputFormat', value)}
                  title={translate('recipient.output_format')}
                  value={outputFormat}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.handleOutputFormatSelector(),
                  }}
                />
              </Column>
              <Column colMD={3} className={'bux_pr8'}>
                <Input
                  id={`${id}_ppn`}
                  onInputChanged={value => this.handleInput('ppn', value)}
                  title={translate('recipient.postprocessing_note')}
                  value={ppn}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.handlePpnSelector(),
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_copies`}
                  title={translate('import.copies')}
                  onChange={value => this.handleInput('copies', value)}
                  value={copies}
                  min={1}
                  max={32767}
                  steps={1}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_fromgen`}
                  title={translate('general.from_gen')}
                  onChange={value => this.handleInput('fromGen', value)}
                  value={fromGen}
                  min={0}
                  max={32767}
                  steps={1}
                />
              </Column>
              <Column colMD={3} className={'bux_pr8'}>
                <NumericSpinner
                  id={`${id}_togen`}
                  title={translate('general.to_gen')}
                  onChange={value => this.handleInput('toGen', value)}
                  value={toGen}
                  min={0}
                  max={32767}
                  steps={1}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={id}>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}
const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRecipientDefinitions: (fields, recipient, callback) => {
      ModalSelectorActions.getRecipientDefinitions(
        fields,
        undefined, // rtype
        undefined, // distribution type
        recipient,
        undefined, // preRecipient
        undefined, // owner
        undefined, // title
        undefined, // outputchannel
        undefined, // outputformat
        undefined, // ppn
        callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getOutputFormatDefinitions: (fields, outputFormat, callback) => {
      ModalSelectorActions.getOutputFormatDefinitions(
        fields,
        outputFormat,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getPPNDefinitions: (fields, ppn, callback) => {
      ModalSelectorActions.getPPNDefinitions(
        fields,
        ppn,
        undefined,
        undefined,
        callback)(dispatch)
    },
    createRecipientDocumentAssignment: (reciDocument, callback) => {
      createRecipientDocumentAssignment(reciDocument, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRecipientDocumentAssignmentDialog)