import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'
import * as Utils from 'utils/Utils'
import * as DefinitionUtils from 'utils/DefinitionUtils'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Switch from 'BetaUX2Web-Components/src/components/switch/Switch'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'
import Card from 'BetaUX2Web-Components/src/components/card/Card'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as OutputChannelDefinitionActions from 'redux/actions/OutputChannelDefinitionActions'

class DefinitionOutputChannel extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    activeTypeIndex: Math.max(DefinitionUtils.getOutputChannelTypes(true).findIndex(temp => temp.key === DefinitionUtils.OUTPUTCHANNEL_TYPE_ANY), 0),
    outputChannelID: {
      value: '',
      errorkey: ''
    },
    owner: {
      value: '',
      errorkey: ''
    },
    title: {
      value: '',
      errorkey: ''
    },
    alternativeID: {
      value: '',
      errorkey: ''
    },
    onlyActiveAlternativeID: false
  }

  state = {
    ...this.defaultState
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props

    if (preferences) {
      const id = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_ID] || ''
      const type = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_TYPE] || DefinitionUtils.OUTPUTCHANNEL_TYPE_ANY
      const owner = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_OWNER] || ''
      const title = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_TITLE] || ''
      const altID = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_ALTERNATIVE_ID] || ''
      const activeTypeIndex = Math.max(DefinitionUtils.getOutputChannelTypes(true).findIndex(temp => temp.key === type), 0)

      let onlyActiveAlt = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_ONLY_ACTIVE_ALTERNATIVES]
      if (Utils.isString(onlyActiveAlt)) {
        onlyActiveAlt = (onlyActiveAlt === 'true')
      }

      this.setState({
        activeTypeIndex,
        outputChannelID: {
          value: id,
          errorkey: ''
        },
        owner: {
          value: owner,
          errorkey: ''
        },
        title: {
          value: title,
          errorkey: ''
        },
        alternativeID: {
          value: altID,
          errorkey: ''
        },
        onlyActiveAlternativeID: onlyActiveAlt
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change.
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInput = (id, value, errorkey) => {
    this.setState({ [id]: { value, errorkey } })
  }

  /**
   * @description Handles the switch buttons
   * @index The new active index
   */
  handleSwich = index => this.setState({ onlyActiveAlternativeID: index === 0 })

  /**
   * @description Handles the dropdown input field.
   * @index The new active type index.
   */
  handleDropdown = index => this.setState({ activeTypeIndex: index })

  /**
   * @description Gets the type items from DefinitionUtils and creates new array with translation keys
   */
  getTypeItems = () => {
    const items = []
    DefinitionUtils.getOutputChannelTypes(true).forEach(type => {
      items.push(translate(type.translationKey))
    })
    return items
  }

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = event => {
    event.preventDefault()
    const { changePrefs, getOutputChannels } = this.props

    const id = this.state.outputChannelID.value
    const type = DefinitionUtils.getOutputChannelTypes(true)[this.state.activeTypeIndex].key
    const owner = this.state.owner.value
    const title = this.state.title.value
    const altID = this.state.alternativeID.value
    const onlyActiveAlt = this.state.onlyActiveAlternativeID

    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_OUTPUTCHANNEL_ID]: id,
      [Preferences.DEFINITION_OUTPUTCHANNEL_TYPE]: type,
      [Preferences.DEFINITION_OUTPUTCHANNEL_OWNER]: owner,
      [Preferences.DEFINITION_OUTPUTCHANNEL_TITLE]: title,
      [Preferences.DEFINITION_OUTPUTCHANNEL_ALTERNATIVE_ID]: altID,
      [Preferences.DEFINITION_OUTPUTCHANNEL_ONLY_ACTIVE_ALTERNATIVES]: onlyActiveAlt,
    }

    changePrefs(prefsToChange)
    getOutputChannels(undefined, id, type, owner, title, altID, onlyActiveAlt)
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleReset = () => {
    this.setState(this.defaultState)
  }


  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    const { outputChannelID, activeTypeIndex, owner, title, alternativeID, onlyActiveAlternativeID } = this.state
    const typeItems = this.getTypeItems()

    return (
      <div
        id={`${id}_main_outputchannel`}
        className={'bux_drawer_main'}>
        <Card title={translate('general.general')}>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_outputchannel_id`}
                title={translate('definition.outputchannel.id')}
                maxLength={16}
                onInputChanged={(val, err) => this.handleInput('outputChannelID', val, err)}
                value={outputChannelID.value}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={6}>
              <Dropdown
                id={`${id}_outputchannel_type`}
                title={translate('general.type')}
                items={typeItems}
                activeIndex={activeTypeIndex}
                onChange={index => this.handleDropdown(index)}
              />
            </Column>
            <Column colMD={6}>
              <Input
                id={`${id}_outputchannel_owner`}
                title={translate('general.owner')}
                maxLength={8}
                onInputChanged={(val, err) => this.handleInput('owner', val, err)}
                value={owner.value}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_outputchannel_title`}
                title={translate('general.title')}
                maxLength={40}
                onInputChanged={(val, err) => this.handleInput('title', val, err)}
                value={title.value}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_outputchannel_alternative_id`}
                title={translate('definition.outputchannel.alternative_id')}
                maxLength={16}
                onInputChanged={(val, err) => this.handleInput('alternativeID', val, err)}
                value={alternativeID.value}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Switch
                id={`${id}_outputchannel_only_active_alternatives`}
                title={translate('definition.outputchannel.only_active_alternatives')}
                items={[
                  translate('general.yes'),
                  translate('general.no')
                ]}
                onClick={index => this.handleSwich(index)}
                activeIndex={onlyActiveAlternativeID ? 0 : 1}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch),
    getOutputChannels: (fields, outputChannel, type, title, requeue, enableRequeue, callback) => {
      OutputChannelDefinitionActions.getOutputChannels(fields, outputChannel, type, title, requeue, enableRequeue, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionOutputChannel)