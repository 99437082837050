import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './TableButton.scss'

import Icon from '../../../icon/Icon'

export default class TableButton extends Component {

  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Function to be called on an click event.*/
    onClick: PropTypes.func,
    /** Name of the icon to be displayed in TableButton.*/
    iconName: PropTypes.string.isRequired,
    /** Tooltip for the button. */
    title: PropTypes.string.isRequired,
    /** Disables this component. Default: false. */
    disabled: PropTypes.bool
  }

  handleOnClick = event => {
    event.preventDefault()
    if (!this.props.disabled)
      this.props.onClick()
  }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.handleOnClick(event)
    }
  }

  render() {
    const { id, iconName, title, disabled } = this.props
    return (
      <div
        className={'table_no_spaceing bux_actionbutton'}
        title={title}
        onClick={this.handleOnClick}
        onKeyDown={this.handleKeyDown}
        tabIndex={disabled ? -1 : 0}
        id={id}>
        <div
          id={`${id}_link`}
          className={`${disabled ? ' disabled' : ''}`}>
          <Icon
            id={`${id}_link_icon`}
            title={title}
            name={iconName}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
}