import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'

import * as UserUtils from 'utils/UserUtils'

import SearchResultFolderDocumentAssingment from './folderdocument/SearchResultFolderDocumentAssingment'
import SearchResultIndexDocumentAssingment from './indexdocument/SearchResultIndexDocumentAssingment'
import SearchResultRecipientDocumentAssingment from './recipientdocument/SearchResultRecipientDocumentAssingment'
import SearchResultNodeDocumentAssignment from './nodedocument/SearchResultNodeDocumentAssignment'

class SearchResultAssignment extends Component {

  /**
   * @description Gets the body which should be rendered.
   */
  getBody() {
    const { match, id, drawerExpanded, autoDrawer } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    switch (url) {
      case 'recipient_document':
        return (
          <SearchResultRecipientDocumentAssingment
            id={`${id}_recipient_document`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      // for logx system
      case 'recipient_log':
        return (
          <SearchResultRecipientDocumentAssingment
            id={`${id}_recipient_log`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'folder_document':
        return (
          <SearchResultFolderDocumentAssingment
            id={`${id}_folder_document`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'index_document':
        return (
          <SearchResultIndexDocumentAssingment
            id={`${id}_index_document`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      // for logx system
      case 'index_log':
        return (
          <SearchResultIndexDocumentAssingment
            id={`${id}_index_log`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'node_document':
        return (
          <SearchResultNodeDocumentAssignment
            id={`${id}_node_document`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      // fallback is folder_document assignment
      default:
        return (
          // take care of the route because if you enter a wrong route you can create an infinite loop!
          <Redirect to={UserUtils.isDOCX() ? '/assignment/recipient_document' : 'assignment/recipient_log'} />
        )
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

SearchResultAssignment.propTypes = {
  id: PropTypes.string.isRequired,
  match: PropTypes.any.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

export default SearchResultAssignment