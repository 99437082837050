import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CopyViewProfileDialog from 'components/dialogs/copy_view_profile_dialog/CopyViewProfileDialog'
import CreateViewProfileDialog from 'components/dialogs/create_view_profile_dialog/CreateViewProfileDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyViewProfileDialog from 'components/dialogs/modify_view_profile_dialog/ModifyViewProfileDialog'
import TableSettings from 'components/table_settings/TableSettings'

import * as ViewProfileActions from 'redux/actions/ViewProfileDefinitionActions'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import * as SortUtils from 'utils/SortUtils'

import * as Preferences from 'redux/general/Preferences'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultViewProfile extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired
  }

  state = {
    showTableSettingsDialog: false,
    showCreateDialog: false,
    showDeleteDialog: false,
    showModifyDialog: false,
    header: this.fillHeaderInformation(),
    createDialogData: undefined
  }

  fillHeaderInformation() {
    return [
      { rest: VIEWPROFILEID, translation: 'definition.view_profile_id', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: DOCTYPE, translation: 'documentinformation.header_doctype', default: true },
      { rest: STATUS, translation: 'general.status', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER }
    ]
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Handles the action buttons to open dialogs
   * @param {Number} rowIndex
   * @param {String} dialog
   */
  handleDialog = (rowIndex, dialog) => {
    const { viewProfiles, getViewProfile } = this.props
    const viewProfileID = viewProfiles.data[rowIndex][this.headerData('PBDNAME')]
    const docType = viewProfiles.data[rowIndex][this.headerData('SRCJOBI')]
    getViewProfile(viewProfileID, docType, () => this.setState({ [dialog]: true }))
  }

  /**
   * @description Deletes the view profile
   */
  handleDelete = () => {
    const { viewProfile, deleteViewProfile } = this.props
    const viewProfileObj = {
      PBDNAME: viewProfile.PBDNAME,
      SRCJOBI: viewProfile.SRCJOBI,
      PBDMODE: viewProfile.PBDMODE,
      PBDTITLE: viewProfile.PBDTITLE,
      OWNER: viewProfile.OWNER,
      CDATE: viewProfile.CDATE,
      CTIME: viewProfile.CTIME,
      CUSER: viewProfile.CUSER,
      VDATEB: viewProfile.VDATEB,
      VDATEE: viewProfile.VDATEE,
      PBDVUSER: viewProfile.PBDVUSER,
      FORM: viewProfile.FORM,
      EXT: viewProfile.EXT,
      REPORT: viewProfile.REPORT,
      PBDT1H: viewProfile.PBDT1H,
      PBDT1C: viewProfile.PBDT1C,
      PBDT1FP: viewProfile.PBDT1FP,
      PBDT1TP: viewProfile.PBDT1TP,
      PBDT1FL: viewProfile.PBDT1FL,
      PBDT1TL: viewProfile.PBDT1TL,
      PBDT1FC: viewProfile.PBDT1FC,
      PBDT1TC: viewProfile.PBDT1TC,
      PBDT1SM: viewProfile.PBDT1SM,
      PBDT2H: viewProfile.PBDT2H,
      PBDT2C: viewProfile.PBDT2C,
      PBDT2FP: viewProfile.PBDT2FP,
      PBDT2TP: viewProfile.PBDT2TP,
      PBDT2FL: viewProfile.PBDT2FL,
      PBDT2TL: viewProfile.PBDT2TL,
      PBDT2FC: viewProfile.PBDT2FC,
      PBDT2TC: viewProfile.PBDT2TC,
      PBDT2SM: viewProfile.PBDT2SM,
      PBDT101H: viewProfile.PBDT101H,
      PBDT101W: viewProfile.PBDT101W,
      PBDT101S: viewProfile.PBDT101S,
      PBDT101E: viewProfile.PBDT101E,
      PBDT102H: viewProfile.PBDT102H,
      PBDT102W: viewProfile.PBDT102W,
      PBDT102S: viewProfile.PBDT102S,
      PBDT102E: viewProfile.PBDT102E,
      PBDT103H: viewProfile.PBDT103H,
      PBDT103W: viewProfile.PBDT103W,
      PBDT103S: viewProfile.PBDT103S,
      PBDT103E: viewProfile.PBDT103E,
      PBDT104H: viewProfile.PBDT104H,
      PBDT104W: viewProfile.PBDT104W,
      PBDT104S: viewProfile.PBDT104S,
      PBDT104E: viewProfile.PBDT104E,
      PBDT105H: viewProfile.PBDT105H,
      PBDT105W: viewProfile.PBDT105W,
      PBDT105S: viewProfile.PBDT105S,
      PBDT105E: viewProfile.PBDT105E,
      PBDT106H: viewProfile.PBDT106H,
      PBDT106W: viewProfile.PBDT106W,
      PBDT106S: viewProfile.PBDT106S,
      PBDT106E: viewProfile.PBDT106E,
      PBDT107H: viewProfile.PBDT107H,
      PBDT107W: viewProfile.PBDT107W,
      PBDT107S: viewProfile.PBDT107S,
      PBDT107E: viewProfile.PBDT107E,
      PBDT108H: viewProfile.PBDT108H,
      PBDT108W: viewProfile.PBDT108W,
      PBDT108S: viewProfile.PBDT108S,
      PBDT108E: viewProfile.PBDT108E,
      PBDT1M1T: viewProfile.PBDT1M1T,
      PBDT1M1F: viewProfile.PBDT1M1F,
      PBDT1M1B: viewProfile.PBDT1M1B,
      PBDT1M2T: viewProfile.PBDT1M2T,
      PBDT1M2F: viewProfile.PBDT1M2F,
      PBDT1M2B: viewProfile.PBDT1M2B,
      PBDT1M3T: viewProfile.PBDT1M3T,
      PBDT1M3F: viewProfile.PBDT1M3F,
      PBDT1M3B: viewProfile.PBDT1M3B,
      PBDT1M4T: viewProfile.PBDT1M4T,
      PBDT1M4F: viewProfile.PBDT1M4F,
      PBDT1M4B: viewProfile.PBDT1M4B,
      PBDT201H: viewProfile.PBDT201H,
      PBDT201W: viewProfile.PBDT201W,
      PBDT201S: viewProfile.PBDT201S,
      PBDT201E: viewProfile.PBDT201E,
      PBDT202H: viewProfile.PBDT202H,
      PBDT202W: viewProfile.PBDT202W,
      PBDT202S: viewProfile.PBDT202S,
      PBDT202E: viewProfile.PBDT202E,
      PBDT203H: viewProfile.PBDT203H,
      PBDT203W: viewProfile.PBDT203W,
      PBDT203S: viewProfile.PBDT203S,
      PBDT203E: viewProfile.PBDT203E,
      PBDT204H: viewProfile.PBDT204H,
      PBDT204W: viewProfile.PBDT204W,
      PBDT204S: viewProfile.PBDT204S,
      PBDT204E: viewProfile.PBDT204E,
      PBDT205H: viewProfile.PBDT205H,
      PBDT205W: viewProfile.PBDT205W,
      PBDT205S: viewProfile.PBDT205S,
      PBDT205E: viewProfile.PBDT205E,
      PBDT206H: viewProfile.PBDT206H,
      PBDT206W: viewProfile.PBDT206W,
      PBDT206S: viewProfile.PBDT206S,
      PBDT206E: viewProfile.PBDT206E,
      PBDT207H: viewProfile.PBDT207H,
      PBDT207W: viewProfile.PBDT207W,
      PBDT207S: viewProfile.PBDT207S,
      PBDT207E: viewProfile.PBDT207E,
      PBDT208H: viewProfile.PBDT208H,
      PBDT208W: viewProfile.PBDT208W,
      PBDT208S: viewProfile.PBDT208S,
      PBDT208E: viewProfile.PBDT208E,
      PBDT2M1T: viewProfile.PBDT2M1T,
      PBDT2M1F: viewProfile.PBDT2M1F,
      PBDT2M1B: viewProfile.PBDT2M1B,
      PBDT2M2T: viewProfile.PBDT2M2T,
      PBDT2M2F: viewProfile.PBDT2M2F,
      PBDT2M2B: viewProfile.PBDT2M2B,
      PBDT2M3T: viewProfile.PBDT2M3T,
      PBDT2M3F: viewProfile.PBDT2M3F,
      PBDT2M3B: viewProfile.PBDT2M3B,
      PBDT2M4T: viewProfile.PBDT2M4T,
      PBDT2M4F: viewProfile.PBDT2M4F,
      PBDT2M4B: viewProfile.PBDT2M4B
    }
    deleteViewProfile(viewProfileObj, () => this.setState({ showDeleteDialog: false }))
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
        />
        <TableButtonGroupItem
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
        />
        <TableButtonGroupItem
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.showPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.props.refreshSearch}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description gets the index of the header in redux state definitions.viewProfiles.header
   * @param {String} header header name of the header in redux definitions.state viewProfiles.header
   */
  headerData = header => this.props.viewProfiles.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, viewProfiles } = this.props
    const data = []
    const headers = this.getUsedHeader()

    viewProfiles.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          buffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else if (header === STATUS) {
          buffer.push(
            el[this.headerData('PBDMODE')] === 'PROD'
              ? 'Active (A)'
              : 'Test Mode'
          )
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            buffer.push(`${val}:00`)
          }
          else {
            buffer.push(val)
          }
        }
      })
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_VIEW_PROFILE]) {
      const buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_VIEW_PROFILE].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_VIEW_PROFILE]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_VIEW_PROFILE].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  showPrefilledCreateDialog = () => {
    const { preferences } = this.props
    this.setState({
      showCreateDialog: true,
      createDialogData: {
        PBDNAME: preferences[Preferences.DEFINITION_VIEW_PROFILE_ID],
        OWNER: preferences[Preferences.DEFINITION_VIEW_PROFILE_OWNER],
        PBDTITLE: preferences[Preferences.DEFINITION_VIEW_PROFILE_TITLE],
        SRCJOBI: preferences[Preferences.DEFINITION_VIEW_PROFILE_DOCTYPE]
      }
    })
  }


  render = () => {
    const { id, viewProfiles, viewProfile, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showDeleteDialog, showCreateDialog, showCopyDialog, showModifyDialog } = this.state
    const data = viewProfiles && viewProfiles.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_VIEW_PROFILE }}
          />
        )}
        {showCreateDialog && (
          <CreateViewProfileDialog
            id={`${id}_createviewprofile`}
            prefilledData={this.state.createDialogData}
            onClose={() => { this.setState({ showCreateDialog: false, createDialogData: undefined }) }}
          />
        )}
        {showCopyDialog && (
          <CopyViewProfileDialog
            id={`${id}_copyviewprofile`}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        )}
        {showModifyDialog && (
          <ModifyViewProfileDialog
            id={`${id}_modifyviewprofile`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        )}
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_viewprofile`}
            title={translate('definition.delete_view_profile')}
            question={translate('definition.question_delete_view_profile')}
            onClose={() => this.setState({ showDeleteDialog: false })}
            onDelete={() => this.handleDelete()}>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_viewprofileid_label`}>
                  {translate('definition.view_profile_id')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_viewprofileid_value`}>
                  {viewProfile.PBDNAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_doctype_label`}>
                  {translate('documentinformation.header_doctype')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_doctype_value`}>
                  {viewProfile.SRCJOBI}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_owner_label`}>
                  {translate('general.owner')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_owner_value`}>
                  {viewProfile.OWNER}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {viewProfiles
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={index => this.handleDialog(index, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_view_profile_result')}
                    link={translate('emptyresult.create_view_profile_link')}
                    onClick={() => this.showPrefilledCreateDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const VIEWPROFILEID = 'PBDNAME'
const TITLE = 'PBDTITLE'
const DOCTYPE = 'SRCJOBI'
const OWNER = 'OWNER'
const STATUS = 'PBDMODE'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    viewProfiles: state.definitions.viewprofiles.viewProfiles,
    viewProfile: state.definitions.viewprofiles.viewProfile,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    keepPagination: state.definitions.resulttables.keepPagination
  }
}

const mapDispatchToProps = dispatch => {
  return {
    refreshSearch: () => ViewProfileActions.refreshSearch()(dispatch),
    getViewProfile: (viewProfileID, docType, callback) => {
      ViewProfileActions.getViewProfile(viewProfileID, docType, callback)(dispatch)
    },
    deleteViewProfile: (viewProfile, callback) => {
      ViewProfileActions.deleteViewProfile(viewProfile, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultViewProfile)