import { Button, Card, Column, Input, Row, Switch } from 'BetaUX2Web-Components/src/'
import { LetterCase } from 'BetaUX2Web-Components/src/types'
import PropTypes from 'prop-types'
import { Component } from 'react'

import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as UserActions from 'redux/actions/UserActions'

import { translate } from 'language/Language'
import * as Preferences from 'redux/general/Preferences'
import * as UrlUtils from 'utils/UrlUtils'
import * as Utils from 'utils/Utils'

// Utils
import * as UserUtils from 'utils/UserUtils'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class UserManagementUsers extends Component {

  defaultState = {
    userid: '',
    username: '',
    privileges: 0,
    externalAuthOnly: 1,
    revokedOnly: 1,
  }

  keys = [
    { rest: 'BETAUSER', ui: 'userid' },
    { rest: 'USERNAME', ui: 'username' },
    { rest: 'ADMIN', ui: 'privileges' },
    { rest: 'CKPWDEXT', ui: 'externalAuthOnly' },
    { rest: 'USRREVOK', ui: 'revokedOnly' },
  ]

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount = () => {
    const { preferences } = this.props

    if (window.location.href.indexOf('?') !== -1) {
      this.initFieldsFromUrl()
    }
    else {
      // initialize search fields
      if (preferences) {
        let userid = preferences[Preferences.USERMANAGEMENT_USERS_USERID] || ''
        let username = preferences[Preferences.USERMANAGEMENT_USERS_USERNAME] || ''
        let privileges = preferences[Preferences.USERMANAGEMENT_USERS_PRIVILEGES] || 0
        if (Utils.isString(privileges)) {
          privileges = Math.max(UserUtils.PRIVILEGES_ITEMS_SEARCH.findIndex(d => d.key === privileges), 0)
        }
        let externalAuthOnly = preferences[Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY] || 1
        if (Utils.isString(externalAuthOnly)) {
          externalAuthOnly = externalAuthOnly === 'true' ? 0 : 1
        }
        let revokedOnly = preferences[Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY] || 1
        if (Utils.isString(revokedOnly)) {
          revokedOnly = revokedOnly === 'true' ? 0 : 1
        }
        this.setState({
          userid: userid,
          username: username,
          privileges: privileges,
          externalAuthOnly: externalAuthOnly,
          revokedOnly: revokedOnly,
        })
      }
    }
  }

  initFieldsFromUrl = () => {
    const newValues = UrlUtils.getDataFromUrlParams(this.props.location.search, this.keys)
    this.setState({
      userid: newValues.userid || '',
      username: newValues.username || '',
      privileges: !isNaN(newValues.privileges) ? parseInt(newValues.privileges) : 0,
      externalAuthOnly: !isNaN(newValues.externalAuthOnly) ? parseInt(newValues.externalAuthOnly) : 1,
      revokedOnly: !isNaN(newValues.revokedOnly) ? parseInt(newValues.revokedOnly) : 1,
    }, () => {
      if (UrlUtils.urlContainsExecute()) {
        this.search()
      }
    })
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Handles the changes on userid.
   * @param val The new value
   */
  handleUserIdChanged = val => {
    this.setState({ userid: val })
  }

  /**
   * @description Handles the changes on username.
   * @param val The new value
   */
  handleUsernameChanged = val => {
    this.setState({ username: val })
  }

  /**
   * @description Handles the changes on authenticated only.
   * @param index The new active index
   */
  handleOnClickExternalAuthenticatedOnly = (index) => {
    /* Index == '0' button, means true (External Authenticated only) for request */
    this.setState({ externalAuthOnly: index })
  }

  /**
   * @description Handles the changes on revoked user only.
   * @param index The new active index
   */
  handleOnClickRevokedUsersOnly = index => {
    /* Index == '0' button, means true (Revoked only) for request */
    this.setState({ revokedOnly: index })
  }

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = event => {
    event.preventDefault()
    this.search()
  }

  search = () => {
    const {
      userid,
      username,
      privileges,
      externalAuthOnly,
      revokedOnly
    } = this.state

    // save search values in preferences
    const prefsToChange = {
      [Preferences.USERMANAGEMENT_USERS_USERID]: userid,
      [Preferences.USERMANAGEMENT_USERS_USERNAME]: username,
      [Preferences.USERMANAGEMENT_USERS_PRIVILEGES]: UserUtils.PRIVILEGES_ITEMS_SEARCH[privileges].key,
      [Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY]: { 0: 'true', 1: 'false' }[externalAuthOnly],
      [Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY]: { 0: 'true', 1: 'false' }[revokedOnly]
    }
    this.props.changePrefs(prefsToChange)
    // Math.max(UserUtils.PRIVILEGES_ITEMS.findIndex(d => d.key === adminOnly), 0)
    const adminOnlyForRequest = UserUtils.PRIVILEGES_ITEMS_SEARCH[privileges].key
    const externalAuthOnlyForRequest = externalAuthOnly === 0
    const revokedOnlyForRequest = revokedOnly === 0
    this.props.searchUsers(
      userid,
      username,
      adminOnlyForRequest,
      externalAuthOnlyForRequest,
      revokedOnlyForRequest
    )

    // builds the parameter object which is used for url
    const urlToPush = `/usermanagement/users${UrlUtils.createUrlParamsFromObject(this.state, this.keys)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { userid, username } = this.state
    return (
      <Card
        title={translate('general.general')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_generalcard_userid`}
              title={translate('user.user_id')}
              maxLength={8}
              value={userid}
              onInputChanged={this.handleUserIdChanged}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_generalcard_username`}
              title={translate('user.username')}
              maxLength={32}
              value={username}
              onInputChanged={this.handleUsernameChanged}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the addiditonal card.
   */
  renderAdditionalCard = () => {
    const { id } = this.props
    const { privileges, externalAuthOnly, revokedOnly } = this.state
    return (
      <Card
        title={translate('general.additional')}>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_additionalcard_adminitistrator_only`}
              title={translate('user.privileges')}
              items={[...UserUtils.PRIVILEGES_ITEMS_SEARCH.map(item => translate(item.translationKey))]}
              activeIndex={privileges}
              onClick={index => this.setState({ privileges: index })}
              maxPerRow={2}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_additionalcard_external_authenticated_only`}
              title={translate('usermanagement.external_authenticated_only')}
              items={[
                translate('general.yes'),
                translate('general.no')
              ]}
              activeIndex={externalAuthOnly}
              onClick={this.handleOnClickExternalAuthenticatedOnly}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_additionalcard_revoked_users_only`}
              title={translate('usermanagement.revoked_users_only')}
              items={[
                translate('general.yes'),
                translate('general.no')
              ]}
              activeIndex={revokedOnly}
              onClick={this.handleOnClickRevokedUsersOnly}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderGeneralCard()}
        {this.renderAdditionalCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props
    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id } = this.props
    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {/* main */}
        {this.renderMain()}
        {/* footer */}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchUsers: (userid, username, adminOnly, externalAuthOnly, revokedOnly) => {
      UserActions.getUsers(userid, username, adminOnly, externalAuthOnly, revokedOnly)(dispatch)
    },
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) }
  }
}

UserManagementUsers.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserManagementUsers))