import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'

// actions
export const ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_START = 'ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_START'
export const ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_SUCCESS = 'ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_SUCCESS'
export const ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_FAILED = 'ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_FAILED'
export const NO_ARCHIVEFILE_SERVER_ARCHIVEFILES_FOUND = 'NO_ARCHIVEFILE_SERVER_ARCHIVEFILES_FOUND'

export const ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_START = 'ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_START'
export const ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_SUCCESS = 'ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_SUCCESS'
export const ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_FAILED = 'ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_FAILED'

export const ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_START = 'ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_START'
export const ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_SUCCESS = 'ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_SUCCESS'
export const ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_FAILED = 'ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_FAILED'

/**
 * @description Calls the rest api and requests endpoint to get all archive files
 * @param {Array} fields
 * @param {String} arcpool
 * @param {String} arcfile
 * @param {String} arcdate
 * @param {String} level3arc
 * @param {String} owner
 * @param {Function} callback
 */
export function getArchiveFiles(fields, arcpool, arcfile, arcdate, level3arc, owner, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${encodeURIComponent(fields)}`) }
    if (arcpool) { queryParams.push(`ARCPOOL=${encodeURIComponent(arcpool)}`) }
    if (arcfile) { queryParams.push(`AGRFILE=${encodeURIComponent(arcfile)}`) }
    if (arcdate) { queryParams.push(`AGRDATE=${encodeURIComponent(arcdate)}`) }
    if (level3arc) { queryParams.push(`LEVEL3ARCHIVE=${encodeURIComponent(level3arc)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/archive/archivefiles?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_ARCHIVEFILE_SERVER_ARCHIVEFILES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_FAILED, payload: { error } })
          }
        } else {
          dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.archive_files_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEFILES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and requests endpoint to get a specific archive file
 * @param {String} archiveToken
 * @param {String} reloadOrder
 * @param {String} archiveNumber
 * @param {Function} callback
 */
export function getArchiveFile(archiveToken, reloadOrder, archiveNumber, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_START })

    const queryParams = []
    queryParams.push(`ATOKEN=${encodeURIComponent(archiveToken)}`)
    queryParams.push(`AGRORDER=${encodeURIComponent(reloadOrder)}`)
    queryParams.push(`ALFNDNR=${encodeURIComponent(archiveNumber)}`)

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/archive/archivefile?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('server.get_archive_file_error', lang, [archiveToken, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEFILE_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and requests endpoint to get the archive objects of a archive file
 * @param {String} archiveToken
 * @param {String} reloadOrder
 * @param {String} archiveNumber
 * @param {Function} callback
 */
export function getArchiveObjects(archiveToken, reloadOrder, archiveNumber, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_START })

    const queryParams = []
    queryParams.push(`ATOKEN=${encodeURIComponent(archiveToken)}`)
    queryParams.push(`AORORDER=${encodeURIComponent(reloadOrder)}`)
    queryParams.push(`ALFNDNR=${encodeURIComponent(archiveNumber)}`)

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/archive/archiveobjects?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('server.get_archive_objects_error', lang, [archiveToken, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: ARCHIVEFILE_SERVER_GET_ARCHIVEOBJECTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}