/* eslint-disable indent */
/* eslint-disable quotes */

import PropTypes from 'prop-types'
import React, { Component } from 'react'

// React Router

// Redux
import { connect } from 'react-redux'
import * as StatisticActions from 'redux/actions/StatisticActions'
import * as Preferences from 'redux/general/Preferences'

import './StatisticMenu.scss'

import { Button, ButtonGroup, Dropdown, Link } from 'BetaUX2Web-Components/src/'
import 'BetaUX2Web-Components/src/components/datatable/table_menu/TableMenu.scss'
import { translate } from 'language/Language'

class StatisticMenu extends Component {
  state = {
    index: 0,
    dropdown: false,
  }

  csvDownload = React.createRef()
  pdfDownload = React.createRef()


  static propTypes = {
    id: PropTypes.string.isRequired
  }

  setIndex = (index) => {
    this.setState({ index })
  }

  renderDownloadAction = () => {
    const { dateOutput, id, bsaStats, header } = this.props
    const csvSplitter = ';'
    const tooltip = this.state.index === 0 ? translate(`table.download_as_csv`) : translate(`table.download_as_pdf`)
    const filenameCSV = `statistics_from_${dateOutput.from}_to_${dateOutput.to}.csv`
    return (
      <a
        id={id}
        ref={this.csvDownload}
        href={this.getCSVFromArrayOfArrays(header(), bsaStats, csvSplitter)}
        download={filenameCSV || 'data.csv'}
        title={tooltip ? tooltip : null}
      >
        <Button
          id={`${id}_download_button`}
          icon='open_drawer'
          onClick={() => this.handleCSVDownload()}
        />
      </a>
    )
  }

  handleCSVDownload = () => {
    this.csvDownload.current.click()
  }

  handlePDFDownload = () => {
    const { dateOutput, preferences, getDownloadPdf } = this.props

    const searchFromDate = preferences.STATISTIC_FROMDATE ? preferences.STATISTIC_FROMDATE : preferences.STATISTIC_LOGX_FROMDATE
    const searchToDate = preferences.STATISTIC_TODATE ? preferences.STATISTIC_TODATE : preferences.STATISTIC_LOGX_TODATE
    const filenamePDF = `statistics_from_${dateOutput.from}_to_${dateOutput.to}.pdf`
    getDownloadPdf(searchToDate, searchFromDate, filenamePDF)
  }

  getCSVFromArrayOfArrays = (header, data, delimiter) => {
    let csv = ''

    // header
    // note: header may include the headers for the actionbuttons which are empty ("")
    // -> filter them out
    csv += `${header.filter(val => val).join(delimiter || ';')}\n`

    // data
    // note: the date format is correctly here. Excel reformats the date!
    data.forEach(element => {
      csv += `${element.join(delimiter || ';')}\n`
    })

    /* Blob is required, otherwise the url would be cut after ~2000character */
    /* https://stackoverflow.com/questions/24610694/export-html-table-to-csv-in-google-chrome-browser/24611096#24611096 */
    /* "\ufeff is required for UTF-8 encoding: */
    /* https://stackoverflow.com/questions/31959487/utf-8-encoidng-issue-when-exporting-csv-file-javascript/32002335#32002335 */
    const csvData = new Blob(['\ufeff' + csv], { encoding: 'UTF-8', type: 'text/csv;charset=UTF-8' })
    return URL.createObjectURL(csvData)
  }

  render = () => {
    const { onRefreshClick, dateOutput, id} = this.props
    const downloadItems = [translate('table.download_as_pdf')]
    return (
      <div className='table_action_row' id={`${id}_statistics_menu`}>
        <div className='table_action_row_left'>
          <span id='we_id_dates' className='el_table_head_info'>
            {`${dateOutput.from} - ${dateOutput.to}`}
          </span>
        </div>
        <div className='table_action_row_center static_action_row_center'>
          <Link
            id='refresh_statmenu-btn'
            onClick={onRefreshClick}
            iconName='refresh'
            tooltip={translate('table.refresh')}
          />
          <div className='statistics-menu-group'>
            <ButtonGroup>
              <Dropdown
                id={`statistics_menu_dropdown`}
                activeIndex={this.state.index}
                items={
                  downloadItems
                    ? [translate('table.download_as_csv'), ...downloadItems]
                    : [translate('table.download_as_csv')]
                }
                onChange={(index) => { this.setIndex(index) }}
              />
              {/* component to execute the action of the dropdown */}
              {
                this.state.index === 0 && this.renderDownloadAction()
              }
              {
                this.state.index !== 0 &&
                // eslint-disable-next-line
                <a
                  id={id}
                  ref={this.csvDownload}
                  >
                <Button
                  icon='open_drawer'
                  onClick={() => this.handlePDFDownload()}
                />
                </a>
              }
            </ButtonGroup>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    toDate: state.auth.serverdata.preferences[Preferences.STATISTIC_TODATE],
    preferences: state.auth.serverdata.preferences,
    downloadBsastatsPdf: state.statistic.downloadBsastatsPdf
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDownloadPdf: (searchToDate, searchFromDate, filenamePDF) => StatisticActions.getDownloadPdf(searchToDate, searchFromDate, filenamePDF)(dispatch),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(StatisticMenu)