import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Header } from './Header';
import { Main } from './Main';
import { Footer } from './Footer';

import './Modal.scss';
import { ModalCommonProps, ModalSize } from './Modal.types';
import { prepareClassNames } from '../../utils/ComponentUtils';


interface ModalProps extends ModalCommonProps {
  size?: ModalSize;
  onClose?: () => void;
  parentDialogId?: string;
  noBackdrop?: boolean;
  glassEffect?: boolean;
}

const Modal = (props: ModalProps): JSX.Element => {
  const portalElement: Element | null = document.querySelector(props.parentDialogId ? `#${props.parentDialogId}` : '#modalDialog_Portal');

  if (!portalElement) throw new Error('Cannot find a element for modal with id');

  const handleFocus = (event: FocusEvent): void => {
    // on default, we loop through all elements inside a modal.
    // but if we have a submodal dialog, we want to pass through the focus to that submodal.
    // so the parent should not consume the focus anymore!

    const lastModal = document.querySelector('#modalDialog_Portal > div:last-of-type');
    if (lastModal && (lastModal.id === props.id)) {
      // Note: the modal dialog itself will also get the focus

      // consume the event
      event.preventDefault();

      // get the modal dialog as container element
      const containerElement = document.querySelector(`#${props.id}_modal`);
      if (containerElement === null || document.activeElement === null) {
        return;
      }

      // check if the current focused element is inside the container element
      const isFocusOutsideModal = !containerElement.contains(document.activeElement);

      // check if active element is not snackbar element. We cannot steel focus from snackbar component.
      if (document.activeElement.className.indexOf('snackbar') !== -1) {
        return;
      }
      // check if active element is not dropdown element. We cannot steel focus from these component.
      if (document.activeElement.className.indexOf('dropdown-menu') !== -1 || document.activeElement.className.indexOf('table_button') !== -1 || document.activeElement.className.indexOf('menu_item') !== -1) {
        return;
      }

      // if the focus is outside set it to the next component inside -> modal dialog
      if (isFocusOutsideModal) {
        const autofocusElement = containerElement.querySelector('[autofocus]') as HTMLElement;
        const wrapperElements = containerElement.querySelectorAll('[tabindex]') as NodeListOf<HTMLElement>;

        // set the focus
        if (autofocusElement !== null) {
          autofocusElement.focus();
        } else if (wrapperElements !== null) {
          for (const element of wrapperElements) {
            const tabIndex = element.getAttribute('tabindex');
            if (!tabIndex || Number(tabIndex) >= 0) {
              element.focus();
              break;
            }
          }
        }
      }
    }
  };

  /**
   * @description Handles the ESC key press
   * @param {Object} event
   */
  const handleEscape = (event: KeyboardEvent): void => {
    const lastModal = document.querySelector('#modalDialog_Portal > div:last-of-type');
    if (lastModal && lastModal.id === props.id) {
      if (event.key === 'Escape') {
        if ((event.target as Element).className.indexOf('snackbar') === -1) {
          props.onClose?.();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('focus', handleFocus, true);
    document.addEventListener('keydown', handleEscape);
    // clean up like componentWillUnmount
    return function cleanup() {
      document.removeEventListener('focus', handleFocus, true);
      document.removeEventListener('keydown', handleEscape);
    };
  });

  const backdropClassNames = prepareClassNames([
    'modal_backdrop',
    props.noBackdrop && 'no_backdrop',
    props.glassEffect && 'backdrop_glass_background']);

  const classNames = prepareClassNames([
    'modal_container',
    props.className && props.className,
    `modal_size_${props.size || 'm'}`,
    props.glassEffect && 'modal_glass_background'
  ]);

  return (
    ReactDOM.createPortal(
      <div
        id={props.id}
        className={backdropClassNames}
      >
        <div
          id={`${props.id}_modal`}
          className={classNames}
        >
          <div className={'modal_content'}>
            {props.children}
          </div>
        </div>
      </div>,
      portalElement
    )
  );

};

const ModalComponent = {
  Modal,
  Header,
  Main,
  Footer
};
export default ModalComponent;