import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './CopySecurityProfileDialog.scss'


// Components
import {
  Button, Card,
  Checkbox, Column, Dropdown, Input,
  Modal as ModalComponent, MultiFloatingInput, Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent


// Redux
import { connect } from 'react-redux'
import * as SecurityProfileActions from 'redux/actions/SecurityProfileActions'
import * as Preferences from 'redux/general/Preferences'
import * as SecurityFunctions from 'redux/general/SecurityFunctions'

class CopySecurityProfileDialog extends Component {
  state = {
    profilemask: this.props.securityProfile2Copy.PROFILE,
    betauser: {
      value: this.props.securityProfile2Copy.BETAUSER,
      errorkey: ''
    },
    profileDesc: {
      value: this.props.securityProfile2Copy.PRODESC,
      errorkey: ''
    },
    accessTranslated: [
      translate('security.access_none'),
      translate('security.access_read'),
      translate('security.access_update'),
      translate('security.access_alter')
    ],
    accessOrig: [
      'NONE',
      'READ',
      'UPDATE',
      'ALTER'
    ],
    accessIndex: 0,
    profileCategory: '',
    profileEntry: '',
    convertToUpper: false,
  }

  accessInput = React.createRef()

  /**
   * @description Initilizes the state values.
   */
  componentDidMount() {
    const profilemaskPrefix = this.props.securityProfile2Copy.PROFILE.split('.')[0]
    let profilemask = this.props.securityProfile2Copy.PROFILE.split('.').slice(1).join('.')
    let profileEntry = SecurityFunctions.getProfilentryAndParts(profilemaskPrefix).translationKey

    // in case we didn't find the entry, set it to free text and use the complete input as profilemask
    if (profileEntry === undefined || profileEntry === SecurityFunctions.SEC) {
      profileEntry = SecurityFunctions.FREE
      profilemask = this.props.securityProfile2Copy.PROFILE
    }
    // init values from securityProfile2Copy
    this.setState({
      profilemask: profilemask,
      accessIndex: this.state.accessOrig.indexOf(this.props.securityProfile2Copy.ACCESS),
      profileCategory: SecurityFunctions.ALL_FUNCTIONS,
      profileEntry: profileEntry
    })

    this.accessInput.current.focus()
  }

  /**
   * @description Changes the current access selection.
   * @param {Number} activeIndex The index of the new selection.
   */
  handleOnAccessChanged(activeIndex) {
    this.setState({ accessIndex: activeIndex })
  }

  /**
   * @description Changes the betauser value and it's errorkey.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleOnBetauserChanged(value, errorkey) {
    this.setState({
      betauser: {
        value: value,
        errorkey: errorkey
      }
    })
  }

  /**
   * @description Changes the profile description value and it's error key.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleOnProfileDescChanged(value, errorkey) {
    this.setState({
      profileDesc: {
        value: value,
        errorkey: errorkey
      }
    })
  }

  /**
   * @description Changes the current category selection.
   * @param {Number} activeIndex The index of the new selection.
   */
  handleProfileCategoryChanged(activeIndex) {
    const profileCategory = SecurityFunctions.getCategoryKey(activeIndex)

    this.setState({
      profileCategory: profileCategory,
      profileEntry: SecurityFunctions.getFunctionKey(profileCategory, 0)
    })
  }

  /**
   * @description Changes the current entry selection.
   * @param {Number} activeIndex The index of the new selection.
   */
  handleProfileEntryChanged(activeIndex) {
    const category = this.state.profileCategory
    this.setState({
      profileEntry: SecurityFunctions.getFunctionKey(category, activeIndex)
    })
  }

  /**
   * @description Changes the profile mask.
   * @param {String} value The new value.
   */
  handleOnProfilemaskChanged(value) {
    this.setState({ profilemask: value })
  }

  /**
   * @description Changes the value of convert to upper.
   * @param {String} value The new value.
   */
  handleOnConvertToUpperChanged(value) {
    this.setState({ convertToUpper: value })
  }

  /**
   * @description Handles the save action.
   */
  handleOnSave() {
    // call create request
    const cuser = this.props.userid
    const betauser = this.state.betauser.value
    const profileDesc = this.state.profileDesc.value
    const access = this.state.accessOrig[this.state.accessIndex]

    const profileEntry = this.state.profileEntry
    let concatedProfilemask = SecurityFunctions.getConcatedProfileMask(profileEntry, this.state.profilemask)

    if (this.state.convertToUpper) {
      concatedProfilemask = concatedProfilemask.toUpperCase()
    }

    // declare success callback
    const callback = () => this.props.onClose()

    this.props.createSecurityProfile(cuser, concatedProfilemask, betauser, profileDesc, access, callback)
  }

  render = () => {
    const { id, onClose } = this.props
    const { profileCategory, profileEntry, accessTranslated, accessIndex, betauser, profileDesc, profilemask, convertToUpper } = this.state

    return (
      <Modal onClose={onClose} id={'security_copysecurityprofiledialog_dialog'} className={'bux_copySecurityProfileModal'}>
        <Header
          id={`${id}_copySecurityProfileModal`}
          title={translate('security.copy_security_profile')}
          onClose={() => onClose()}
        />
        <Main id={`${id}_copySecurityProfileModal`}>
          <Card id={`${id}_card`}>
            <Row
              id={`${id}_body_card_row`}>
              <Column
                id={`${id}_body_card_row_col_access`}
                colMD={6}
                offsetMD={0}>
                <Dropdown
                  id={`${id}_body_card_row_col_user_access_field`}
                  focusRef={this.accessInput}
                  items={accessTranslated}
                  activeIndex={accessIndex}
                  onChange={(activeIndex) => { this.handleOnAccessChanged(activeIndex) }}
                  title={translate('security.access')}
                />
              </Column>
              <Column
                id={`${id}_body_card_row_col_additional`}
                colMD={6}
                offsetMD={0}>
                <Input
                  id={`${id}_body_card_row_col_user_user_group_id`}
                  onInputChanged={(val, error) => { this.handleOnBetauserChanged(val, error) }}
                  value={betauser.value}
                  title={translate('security.user_group_id')}
                  error={betauser.errorkey ? translate(betauser.errorkey) : null}
                  maxLength={8}
                />
              </Column>
            </Row>
            <Row
              id={`${id}_body_card_row`}>
              <Column
                id={`${id}_body_card_row_col_profileDesc`}
                colMD={12}
                offsetMD={0}>
                <Input
                  id={`${id}_body_card_row_col_profileDesc`}
                  onInputChanged={(val, error) => { this.handleOnProfileDescChanged(val, error) }}
                  value={profileDesc.value}
                  title={translate('general.description')}
                  maxLength={64}
                />
              </Column>
            </Row>
            <Row
              id={`${id}_body_card_row`}>
              <Column
                id={`${id}_body_card_row_col_profile_category`}
                colMD={3}
                offsetMD={0}>
                <Dropdown
                  id={`${id}_body_card_row_col_profile_category_field`}
                  title={translate('security.profile_type')}
                  generalItems={SecurityFunctions.getTranslatedGeneralCategories()}
                  items={SecurityFunctions.getTranslatedCategories()}
                  activeIndex={SecurityFunctions.indexOfCategory(profileCategory)}
                  onChange={(activeIndex) => this.handleProfileCategoryChanged(activeIndex)} />
              </Column>
              <Column
                id={`${id}_body_card_row_col_profile_entry`}
                colMD={3}
                offsetMD={0}>
                <Dropdown
                  id={`${id}_body_card_row_col_profile_entry_field`}
                  title={translate('security.profile_entry')}
                  generalItems={SecurityFunctions.getTranslatedGeneralFunctions(profileCategory)}
                  items={SecurityFunctions.getTranslatedFunctions(profileCategory)}
                  activeIndex={SecurityFunctions.indexOfFunction(profileCategory, profileEntry)}
                  onChange={(activeIndex) => this.handleProfileEntryChanged(activeIndex)} />
              </Column>
              <Column
                id={`${id}_body_card_row_col_profile_mask`}
                colMD={6}
                offsetMD={0}>
                <MultiFloatingInput
                  id={`${id}_body_card_row_col_profilemask_field`}
                  onInputChanged={(val) => { this.handleOnProfilemaskChanged(val) }}
                  title={translate('security.profile_mask')}
                  items={SecurityFunctions.getProfilemaskPrefixAndParts(profileEntry).parts}
                  input={profilemask}
                  maxLength={64}
                  translate={key =>translate(key)}
                />
              </Column>
            </Row>
            <Row id={`${id}_body_card_row`} style={{ height: 35, marginTop: 0 }}>
              <Column
                id={`${id}_body_card_row_col_profile_mask`}
                colMD={3}
                offsetMD={9}>
                <Checkbox
                  id={`${id}_body_card_row_convert`}
                  onCheck={(val) => this.handleOnConvertToUpperChanged(val)}
                  label={translate('security.convert_to_upper')}
                  value={convertToUpper}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_copySecurityProfileModal`}>
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel')}
            onClick={() => onClose()}
          />
          <Button
            id={`${id}_footer_save`}
            text={translate('general.save')}
            onClick={() => this.handleOnSave()}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

CopySecurityProfileDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    securityProfile2Copy: state.security.securityProfile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createSecurityProfile: (
      cuser,
      profile,
      betauser,
      profileDesc,
      access,
      callback
    ) => {
      SecurityProfileActions.createSecurityProfile(
        cuser,
        profile,
        betauser,
        profileDesc,
        access,
        callback
      )(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopySecurityProfileDialog)