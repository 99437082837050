import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as Utils from 'utils/Utils'

// components
import {
  Button,
  Column, Dropdown, Input,
  Modal as ModalComponent,
  Row,
  Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'

import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

// Redux
import { connect } from 'react-redux'
import { createDocumentNode } from 'redux/actions/DocumentNodesDefinitionActions'
import * as DocumentNodesHierarchyActions from 'redux/actions/DocumentNodesHierarchyActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'

const { Modal, Main, Header, Footer } = ModalComponent
class CopyDocumentNodeDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    showParentNodeDialog: false,
    generalTab: {
      nodeID: {
        value: this.props.documentNode.DNDNAME,
        error: ''
      },
      parentNodeID: this.props.documentNode.DNDPNAME,
      typeIndex: this.props.documentNode.DNDTYPE === 'DND' ? 0 : 1,
      owner: this.props.documentNode.OWNER,
      identifier: {
        value: this.props.documentNode.DNDENAME,
        error: ''
      },
      description: this.props.documentNode.DNDDESC,
      childIdentifier: this.props.documentNode.DNDCNAME
    }
  }

  nodeIdInput = React.createRef()
  identifierInput = React.createRef()

  componentDidMount = () => {
    this.nodeIdInput.current.focus()
  }

  /**
   * @description Handles the input changes of the input fields.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeGeneralTab = (key, value, error) => {
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        [key]: typeof this.state.generalTab[key] === 'object'
          ? { value, error }
          : value
      }
    })
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleChangeGeneralTab(key, value, error)
  }

  /**
   * @description Handles the node id selector button
   */
  handleParentNodeSelector = () => {
    this.props.getDocumentNodesDefinitionTypeNode(
      ['DNDNAME', 'DNDENAME'],
      this.state.generalTab.parentNodeID,
      () => this.setState({ showParentNodeDialog: true })
    )
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    return (
      <>
        {this.state.showParentNodeDialog && (
          <SelectorDialog
            id={`${id}_nodeselector_dialog`}
            onClose={() => this.setState({ showParentNodeDialog: false })}
            title={translate('definition.document_node_definitions')}
            header={[
              translate('definition.node_id'),
              translate('general.identifier')
            ]}
            items={selector.documentnodes.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newParentNodeID = selector.documentnodes.data[selectedRows][selector.documentnodes.header.indexOf('DNDNAME')]
                this.setState({
                  generalTab: {
                    ...this.state.generalTab,
                    parentNodeID: newParentNodeID
                  }
                })
              }
              this.setState({ showParentNodeDialog: false })
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Validates the node id
   */
  validateNodeID = () => {
    const { generalTab } = this.state
    if (generalTab.nodeID.value !== '') {
      if (generalTab.nodeID.value === this.props.documentNode.DNDNAME) {
        return {
          nodeID: {
            ...this.state.generalTab.nodeID,
            error: translate('definition.document_node_copy_same_id')
          }
        }
      } else {
        return {}
      }
    }
    return {
      nodeID: {
        ...this.state.generalTab.nodeID,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the identifier
   */
  validateIdentifier = () => {
    const { generalTab } = this.state
    if (generalTab.identifier.value !== '') {
      return {}
    }
    return {
      identifier: {
        ...this.state.generalTab.identifier,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the general tab
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateNodeID(),
      ...this.validateIdentifier()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState(state => ({ generalTab: { ...state.generalTab, ...validatorResult } }), () => {
        this.handleGeneralTabFocus()
      })
    }

    return errors === 0
  }

  /**
   * @description Sets the focus on the inputs with error
   */
  handleGeneralTabFocus = () => {
    const { generalTab } = this.state
    const requiredInputs = [
      { inputRef: this.nodeIdInput, error: generalTab.nodeID.error },
      { inputRef: this.identifierInput, error: generalTab.identifier.error }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Renders the general tab
   */
  renderGeneralTab = () => {
    const { id, parentNodeID } = this.props
    const { generalTab } = this.state

    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_id`}
              value={generalTab.nodeID.value}
              title={translate('definition.node_id')}
              ref={this.nodeIdInput}
              maxLength={25}
              onInputChanged={(value, error) => this.handleChangeWithoutSpaces('nodeID', value, error)}
              error={generalTab.nodeID.error}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateNodeID() } }))}
              required={`${translate('general.required_field')}`}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_parentnodeid`}
              // Just allow update of parentNodeID when its not given through props.
              onInputChanged={value => !parentNodeID && this.handleChangeWithoutSpaces('parentNodeID', value)}
              value={generalTab.parentNodeID}
              title={translate('general.parent_node_id')}
              maxLength={25}
              addon={{
                iconName: 'list',
                onClick: () => this.handleParentNodeSelector(),
              }}
              // Disable element if parentNodeID is given through props, so this dialog is used as insert child dialog.
              disabled={parentNodeID}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_type`}
              onChange={index => this.handleChangeGeneralTab('typeIndex', index)}
              items={[translate('general.node'), translate('group.group')]}
              activeIndex={generalTab.typeIndex}
              title={translate('general.type')}
              disabled={generalTab.parentNodeID === ''}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_owner`}
              value={generalTab.owner}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={value => this.handleChangeGeneralTab('owner', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_identifier`}
              value={generalTab.identifier.value}
              title={translate('general.identifier')}
              ref={this.identifierInput}
              maxLength={64}
              onInputChanged={(value, error) => this.handleChangeGeneralTab('identifier', value, error)}
              error={generalTab.identifier.error}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateIdentifier() } }))}
              required={`${translate('general.required_field')}`}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_description`}
              value={generalTab.description}
              title={translate('general.description')}
              maxLength={64}
              onInputChanged={value => this.handleChangeGeneralTab('description', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_childidentifier`}
              value={generalTab.childIdentifier}
              title={translate('general.child_identifier')}
              maxLength={64}
              onInputChanged={value => this.handleChangeGeneralTab('childIdentifier', value)}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the error tabs and displays icon
   */
  handleErrorTabs = () => {
    const { generalTab } = this.state
    const buffer = []
    if (generalTab.nodeID.error !== '' || generalTab.identifier.error !== '') {
      buffer.push(0)
    }
    return buffer
  }

  /**
   * @description Handles the save button
   */
  handleSave = () => {
    const { generalTab } = this.state
    const { createDocumentNode, onClose } = this.props
    const errorTabs = [
      this.validateGeneralTab()
    ]
    if (errorTabs.every(d => d)) {
      const documentNode = {
        DNDNAME: generalTab.nodeID.value,
        DNDPNAME: generalTab.parentNodeID,
        DNDENAME: generalTab.identifier.value,
        DNDTYPE: generalTab.typeIndex === 0 ? 'DND' : 'DGI',
        DNDCNAME: generalTab.childIdentifier,
        DNDDESC: generalTab.description,
        OWNER: generalTab.owner
      }
      createDocumentNode(documentNode, () => {
        this.props.hierarchyView
          ? this.props.getDocumentNodeHierarchy(
            {
              DNDNAME: generalTab.nodeID.value,
              DNDPNAME: generalTab.parentNodeID
            },
            createdDocumentNode => {
              this.props.updateHierarchy(createdDocumentNode, generalTab.parentNodeID !== '' ? generalTab.parentNodeID : undefined)
            }
          ) : onClose()
      })
    }
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <>
        {this.renderSelectorDialogs()}
        <Modal
          id={'copy_document_node'}
          onClose={onClose}>
          <Header
            id={id}
            title={translate('definition.copy_document_node')}
            onClose={onClose}>
          </Header>
          <Main>
            <Tabs
              id={`${id}_copy_document_node`}
              errorTabs={this.handleErrorTabs()}
              disabledTabs={[1]}>
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab title={translate('definition.document')}>

              </Tab>
            </Tabs>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    selector: state.selector,
    documentNode: state.definitions.documentNodes.documentNode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDocumentNodesDefinitionTypeNode: (fields, nodeName, callback) => {
      ModalSelectorActions.getDocumentNodesDefinitionTypeNode(fields, nodeName, callback)(dispatch)
    },
    createDocumentNode: (documentObj, callback) => {
      createDocumentNode(documentObj, callback)(dispatch)
    },
    getDocumentNodeHierarchy: (documentNodeObj, callback) => {
      DocumentNodesHierarchyActions.getDocumentNodeHierarchy(documentNodeObj, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyDocumentNodeDialog)