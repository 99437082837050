import { translate } from 'language/Language'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'

// components
import {
  Button, Checkbox, Column, Dropdown, Input,
  Modal as ModalComponent, MultilineInput, NumericSpinner, Row, Switch, Tab,
  Tabs, Toggle
} from 'BetaUX2Web-Components/src/'
import Datepicker from '../../datepicker/Datepicker'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import { LetterCase } from 'BetaUX2Web-Components/src/types'
const { Modal, Main, Header, Footer } = ModalComponent

// redux
import { connect } from 'react-redux'
import * as DocumentDefinitionActions from 'redux/actions/DocumentDefinitionActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as Preferences from 'redux/general/Preferences'
import { hasNoValues } from 'utils/ObjectUtils'
import _ from 'lodash'

class CreateDocumentDefinitionDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props);
    this.defaultState = {
      showPTXSelectorDialog: false,
      showOutputFormatDefinitionSelectorDialog: false,
      showOutputChannelDefinitionSelectorDialog: false,
      showPostProcessingNoteDefinitionSelectorDialog: false,
      showFilterArgumentDefinitionSelectorDialog: false,
      generalTab: {
        form: {
          value: '',
          error: ''
        },
        extension: '',
        owner: '',
        report: '',
        searchMode: 0,
        burstMode: 0,
        validFrom: {
          value: moment().format(this.props.datemask),
          error: ''
        },
        validTo: {
          value: moment('31.12.9999', DateUtils.DDMMYYYY_DOT).format(this.props.datemask),
          error: ''
        },
        controlStatus: 0,
        controlUserID: '',
        scanOnlyNOPs: 1,
        scanOnlyTLEs: 1,
        scanOnlyPJL: 1,
        titleType: 0,
        title: '',
        page: 0,
        line: 0,
        column: 0,
        length: 0
      },
      retentionTab: {
        onlineRetention: 0,
        type: 0,
        archiveRetention: 0,
        media: 0,
        calculateRetentionByStartDate: {
          value: 1,
          error: ''
        },
        day: 0,
        month: 0,
        automaticArchiveDocument: 1,
        archiveNotes: 1,
        archiveTextVersion: 1
      },
      /**
       * @description State of the tab for 'Attributes'
       * @param {number} dataCompression Switch state of 'Data compression', 0 = 'Yes' (default), 1 = 'No'
       * @param {number} checkSum Switch state of 'Checksum', 0 = 'Yes', 1 = 'No' (default)
       * @param {number} encoding Dropdown state of 'Encoding', 0 = 'None', Rest see 'DOCUMENT_DEFINITION_ENCODINGS'
       * @param {string} replaceTxtBy Text input field state of 'Replace *.txt by'
       * @param {number} maximumLinesPages Number input field state of 'Maximum lines per page'
       * @param {number} headerLines  Number input field state of 'Header lines'
       * @param {string} startNewPageWhenValueBeginsAtColumnText1 Text input field state of 'Start new page when value beings at column'
       * @param {number} startNewPageWhenValueBeginsAtColumnNumber1 Number input field state of 'Column' (tied to startNewPageWhenValueBeginsAtColumnText1)
       * @param {string} startNewPageWhenValueBeginsAtColumnText2 Text input field state of 2nd 'Start new page when value beings at column'
       * @param {number} startNewPageWhenValueBeginsAtColumnNumber2 Number input field state of 'Column' (tied to startNewPageWhenValueBeginsAtColumnText2)
       * @param {object} ptxEncoding List selection input field state of 'PTX encoding (UTF-8)'
       * @param {number} nopsEncodedInEBCDIC Switch state of 'NOPs encoding EBCDIC', 0 = 'Yes', 1 = 'No' (default)
       * @param {number} tlesEncodedInEBCDIC Switch state of 'TLEs encoding EBCDIC', 0 = 'Yes', 1 = 'No' (default)
       * @param {number} pdfTextVersionInUTF8 Switch state of 'PDF text version in UTF-8', 0 = 'Yes', 1 = 'No' (default)
       * @param {number} pclTextVersionInEBCDIC Switch state of 'PCL text version in EBCDIC', 0 = 'Yes', 1 = 'No' (default)
       * @param {boolean} extractDateFromDocument Checkbox state of 'Extract date from log' (default 'false')
       * @param {object} searchPattern Contains parsed text input field value information for 'Search pattern' within 'value' and 'error'
       * @param {number} fromLine Number input field state of 'From line' for 'Search pattern'
       * @param {number} toLine Number input field state of 'To line' for 'Search pattern'
       * @param {number} fromColumn  Number input field state of 'From column' for 'Search pattern'
       * @param {number} toColumn  Number input field state of 'To column' for 'Search pattern'
       * @param {number} contentExtractionFromColumn Number input field state of 'Content extraction from column'
       * @param {number} contentExtractionLength Number input field state of 'Content extraction length'
       * @param {number} contentExtractionRelativeTo Switch state of 'Content extraction relative to', 0 = 'Line start' (default), 1 = 'Search pattern start', 2 = 'Search pattern end'
       * @param {object} timeStringFormat Contains parsed text input field value information for 'Time string format' within 'value' and 'error'
       * @param {object} lctimeFormat Contains parsed text input field value information for 'LC_TIME format' within 'value' and 'error'
       */
      attributesTab: {
        dataCompression: 0,
        checkSum: 1,
        encoding: 0,
        replaceTxtBy: '',
        maximumLinesPages: 0,
        headerLines: 0,
        startNewPageWhenValueBeginsAtColumnText1: '',
        startNewPageWhenValueBeginsAtColumnNumber1: 0,
        startNewPageWhenValueBeginsAtColumnText2: '',
        startNewPageWhenValueBeginsAtColumnNumber2: 0,
        ptxEncoding: {
          key: '',
          display: '',
          encoding: ''
        },
        nopsEncodedInEBCDIC: 1,
        tlesEncodedInEBCDIC: 1,
        pdfTextVersionInUTF8: 1,
        pclTextVersionInEBCDIC: 1,
        extractDateFromDocument: false,
        searchPattern: {
          value: '',
          error: ''
        },
        fromLine: 0,
        toLine: 0,
        fromColumn: 0,
        toColumn: 0,
        contentExtractionFromColumn: 0,
        contentExtractionLength: 0,
        contentExtractionRelativeTo: 0,
        timeStringFormat: {
          value: '',
          error: ''
        },
        lctimeFormat: {
          value: '',
          error: ''
        }
      },
      printTab: {
        autoprint: 1,
        holdBundleRequest: 1,
        useCopiesValueFromListGeneratedRecord: 1,
        outputChannelID: '',
        outputFormatID: '',
        postprocessingNote: '',
        printControlFileName: '',
        printPriority: 0,
        bannerTrailerFileNameBanner: '',
        bannerTrailerFileNameTrailer: '',
        printControlFileNameBanner: '',
        printControlFileNameTrailer: ''
      },
      formulaTab: {
        searchArgumentFormula: {
          value: '',
          error: ''
        },
        startSearchArgumentFormula: {
          value: '',
          error: ''
        },
        stopSearchArgumentFormula: {
          value: '',
          error: ''
        },
        stopSearchArgumentOnSamePage: 1,
        // As we discussed, we just need one variable for each field
        // because we can store negative/positive values in one.
        pageOffsetAtStartQualifyingPages: 0,
        pageOffsetAtEndQualifyingPages: 0,
        excludeFormula: ''
      },
      burstWindowTab: {
        reportPrefix: '',
        burstWindows: []
      },
      variablesTab: {
        variableExtraction: 0,
        numberOfLines: 0,
        numberOfValues: 0,
        suppressLeadingBlanksAndRepeatedBlanksBetweenWords: 1,
        valueRelativePosition: [
          { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
          { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
          { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
          { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
          { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
          { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
          { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
          { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 }
        ],
        skipAllBlankValues: false,
        assignInReverseOrder: false,
        skipLeadingBlankValuesOnly: false
      },
      filterTab: {
        filterArgumentID: {
          value: '',
          error: ''
        },
        stopAfterNumberOfMatches: 0,
        headerLinesOnPageOne: 0,
        linesBeforeMatchingLines: 0,
        linesAfterMatchingLines: 0,
        separatorBetweenNonContiguousLines: false,
        markMatches: false,
        prefixLinesWithInputLineNumber: false,
        noDataFoundReport: false,
        prefixLinesWithBetaUxPageAndLineNumber: false
      }
    }
    const dataInitializedState = _.cloneDeep(this.defaultState)
    dataInitializedState.generalTab.form.value = this.props.data?.form || '';
    dataInitializedState.generalTab.extension = this.props.data?.ext || '';
    dataInitializedState.generalTab.owner = this.props.data?.owner || '';
    dataInitializedState.generalTab.report = this.props.data?.report || '';
    dataInitializedState.generalTab.searchMode = this.props.data?.searchMode ? Math.max(DefinitionUtils.DOCUMENT_DEFINITION_SEARCH_MODES.findIndex(d => d.key === this.props.data.searchMode), 0) : 0;
    dataInitializedState.generalTab.title = this.props.data?.title || '';
    dataInitializedState.printTab.outputChannelID = this.props.data?.outputChannelId || '';
    dataInitializedState.printTab.outputFormatID = this.props.data?.outputFormatId || '';
    dataInitializedState.printTab.postprocessingNote = this.props.data?.postprocessingNote || '';

    this.state = _.cloneDeep(dataInitializedState)
  }

  formInput = React.createRef()
  searchPatternInput = React.createRef()
  timeStringFormatInput = React.createRef()
  lctimeFormatInput = React.createRef()
  searchArgumentRefs = []
  filterArgumentIdInput = React.createRef()

  componentDidMount = () => {
    this.formInput.current.focus()
  }

  componentDidUpdate = (_, prevState) => {
    const { generalTab, burstWindowTab } = this.state
    const automaticallyAddBurstWindow = [2, 4, 5, 6, 7].includes(generalTab.searchMode) && burstWindowTab.burstWindows.length === 0
    const resetBurstWindowError = prevState.generalTab.searchMode !== generalTab.searchMode &&
      [0, 1, 3, 8, 9].includes(generalTab.searchMode) &&
      burstWindowTab.burstWindows.length > 0
    const resetFormulaError = (prevState.generalTab.searchMode !== generalTab.searchMode && [2, 3, 8, 9].includes(generalTab.searchMode)) ||
      (generalTab.report === '' && prevState.generalTab.report !== '')
    // automatically added the first row because its required
    if (automaticallyAddBurstWindow) {
      const newBurstWindow = {
        searchArgument: { value: '', error: '' },
        line: { value: 0, error: '' },
        column: { value: 0, error: '' },
        length: { value: 0, error: '' },
        suffix: ''
      }
      this.setState({
        burstWindowTab: {
          ...burstWindowTab,
          burstWindows: [...burstWindowTab.burstWindows, newBurstWindow]
        }
      })
    }
    // resets the errors when the search mode is changing so this fields are no longer required but keep the values
    else if (resetBurstWindowError) {
      const buffer = burstWindowTab.burstWindows
      buffer.forEach(d => {
        d.searchArgument.error = ''
        d.line.error = ''
        d.column.error = ''
        d.length.error = ''
      })
      this.setState({ burstWindowTab: { ...burstWindowTab, burstWindows: buffer } })
    }
    if (resetFormulaError) {
      const formulaTab = {
        searchArgumentFormula: {
          ...this.state.formulaTab.searchArgumentFormula,
          error: ''
        },
        startSearchArgumentFormula: {
          ...this.state.formulaTab.startSearchArgumentFormula,
          error: ''
        },
        stopSearchArgumentFormula: {
          ...this.state.formulaTab.stopSearchArgumentFormula,
          error: ''
        }
      }
      this.setState({ formulaTab: { ...this.state.formulaTab, ...formulaTab } })
    }
    if (this.searchArgumentRefs.length !== burstWindowTab.burstWindows.length) {
      this.updateSearchArgumentRefs()
    }
  }

  updateSearchArgumentRefs = () => {
    const { burstWindowTab } = this.state
    this.searchArgumentRefs = []
    burstWindowTab.burstWindows.forEach(() => {
      this.searchArgumentRefs.push(React.createRef())
    })
  }

  resetPrefilledData = () => {
    console.log(this.state, this.defaultState)
    this.setState({...this.defaultState})
  }

  /**
   * @description Handles the input changes of the input fields.
   * @param {String} tab The tab in that the value has changed.
   * @param {String} key The elements state key.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChange = (tab, key, value, error) => {
    const { burstWindowTab } = this.state
    this.setState(state => ({
      [tab]: {
        ...state[tab],
        [key]: typeof state[tab][key] === 'object'
          ? { value, error: error || '' }
          : value
      }
    }), () => {
      // if burst mode is changed, reset errors in burstWindowTab
      if (key === 'burstMode') {
        const result = burstWindowTab.burstWindows
        for (let i = 0; i < result.length; i++) {
          result[i].searchArgument.error = ''
          result[i].line.error = ''
          result[i].column.error = ''
          result[i].length.error = ''
        }
        this.setState(state => ({ burstWindowTab: { ...state.burstWindowTab, burstWindows: [...result] } }))
      }
    })
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} tab The tab in that the value has changed.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (tab, key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleChange(tab, key, value, error)
  }

  /**
   * @description Handles the input changes of the spinner fields inside the retentionTab.
   * @param {String} key The elements state key.
   * @param {String} value The new value.
   */

  handleSpinnerChange = (key, value) => {
    const temp = { ...this.state.retentionTab }
    temp[key] = value
    if (value > 0 || temp.calculateRetentionByStartDate.error !== '') {
      temp.calculateRetentionByStartDate.error = ''
    }
    this.setState({
      retentionTab: temp
    })
  }

  /**
   * @description Handles the save action.
   * @param {Boolean} saveAsUtf8 Flag if the definition should be saved as utf-8. If false it will be saved as iso-8859-1.
   */
  handleSave = (saveAsUtf8) => {
    const { createDocument, onClose } = this.props
    const { generalTab, retentionTab, attributesTab, printTab, formulaTab, burstWindowTab, variablesTab, filterTab } = this.state

    const errorTabs = [
      this.validateGeneralTab(),
      this.validateRetentionTab(),
      this.validateAttributesTab(),
      this.validateFormulaTab(),
      this.validateBurstWindowTab(),
      this.validateFilterTab()
    ]

    if (errorTabs.every(d => d)) {
      const generalTabObj = {
        FORM: generalTab.form.value,
        EXT: generalTab.extension,
        OWNER: generalTab.owner,
        REPORT: generalTab.report,
        SMODE: generalTab.report === ''
          ? DefinitionUtils.DOCUMENT_SEARCH_MODE_NONE
          : DefinitionUtils.DOCUMENT_DEFINITION_SEARCH_MODES[generalTab.searchMode].key,
        BMODE: generalTab.report === '' || !DefinitionUtils.hasBurstMode(DefinitionUtils.DOCUMENT_DEFINITION_SEARCH_MODES[generalTab.searchMode].key)
          ? DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODES[0].key
          : DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODES[generalTab.burstMode].key,
        VDATEB: DateUtils.getTimeshiftDate(generalTab.validFrom.value, '', DateUtils.DDMMYYYY_DOT),
        VDATEE: DateUtils.getTimeshiftDate(generalTab.validTo.value, '', DateUtils.DDMMYYYY_DOT),
        STATUS: DefinitionUtils.DOCUMENT_DEFINITION_STATI[generalTab.controlStatus].key,
        LDRCUSR: generalTab.controlUserID,
        RDRNDATA: generalTab.scanOnlyNOPs === 0,
        RDRTDATA: generalTab.scanOnlyTLEs === 0,
        RDRPDATA: generalTab.scanOnlyPJL === 0,
        LTITLE: generalTab.title,
      }
      // just send to server when title type is 'dynamic generated'
      if (generalTab.titleType === 1) {
        generalTabObj.DYNTIPAG = generalTab.page
        generalTabObj.DYNTIROW = generalTab.line
        generalTabObj.DYNTICOL = generalTab.column
        generalTabObj.DYNTILEN = generalTab.length
      }

      const retentionTabObj = {
        ONLRETPD: retentionTab.onlineRetention,
        CTYPE: DefinitionUtils.DOCUMENT_DEFINITION_TYPES[retentionTab.type].key,
        ARCRETPD: retentionTab.archiveRetention,
        AUTOARCH: retentionTab.automaticArchiveDocument === 0,
        ARCHNOTE: retentionTab.archiveNotes === 0,
        ARCHDOCT: retentionTab.archiveTextVersion === 0
      }

      if (retentionTab.archiveRetention > 0) {
        retentionTabObj.ARCHMED = DefinitionUtils.DOCUMENT_DEFINITION_MEDIA[retentionTab.media].key
      }

      if (retentionTab.calculateRetentionByStartDate.value === 1) {
        retentionTabObj.ARCRETDD = 0
        retentionTabObj.ARCRETMM = 0
      }
      else {
        retentionTabObj.ARCRETDD = retentionTab.day
        retentionTabObj.ARCRETMM = retentionTab.month
      }

      const attributesTabObj = {
        LDRCOMP: attributesTab.dataCompression === 0,
        LDRCHKSM: attributesTab.checkSum === 0,
        TEXTENCO: DefinitionUtils.DOCUMENT_DEFINITION_ENCODINGS[attributesTab.encoding].key,
        LDRNMIME: attributesTab.replaceTxtBy,
        LDRLNECT: attributesTab.maximumLinesPages,
        DOCHLINE: attributesTab.headerLines,
        LDRNPAGE: attributesTab.startNewPageWhenValueBeginsAtColumnText1,
        LDRNPAGC: attributesTab.startNewPageWhenValueBeginsAtColumnNumber1,
        LDRNDOC: attributesTab.startNewPageWhenValueBeginsAtColumnText2,
        LDRNDOCC: attributesTab.startNewPageWhenValueBeginsAtColumnNumber2,
        AFPCODEP: attributesTab.ptxEncoding.key,
        LDRAFPAS: attributesTab.ptxEncoding.encoding !== 'EBCDIC',
        RDRNEBCD: attributesTab.nopsEncodedInEBCDIC === 0,
        RDRTEBCD: attributesTab.tlesEncodedInEBCDIC === 0,
        LDRPDFU8: attributesTab.pdfTextVersionInUTF8 === 0,
        LDRPCLEB: attributesTab.pclTextVersionInEBCDIC === 0
      }

      if (attributesTab.extractDateFromDocument) {
        attributesTabObj.LDRDSMSK = attributesTab.searchPattern.value
        attributesTabObj.LDRDMKSL = attributesTab.fromLine
        attributesTabObj.LDRDMKEL = attributesTab.toLine
        attributesTabObj.LDRDMKSC = attributesTab.fromColumn
        attributesTabObj.LDRDMKEC = attributesTab.toColumn
        attributesTabObj.LDRDEPOS = attributesTab.contentExtractionFromColumn
        attributesTabObj.LDRDELEN = attributesTab.contentExtractionLength
        attributesTabObj.LDRDEREL = DefinitionUtils.DOCUMENT_DEFINITION_CONTENT_EXTRACTION_RELATIVE_TO[attributesTab.contentExtractionRelativeTo].key
        attributesTabObj.LDRDTSTR = attributesTab.timeStringFormat.value
        attributesTabObj.LDRDTIME = attributesTab.lctimeFormat.value
      }

      const printTabObj = {
        AUTO: printTab.autoprint === 0,
        BUNDLSTA: printTab.holdBundleRequest === 0 ? 'H' : '',
        LDRSCOPY: printTab.useCopiesValueFromListGeneratedRecord === 0,
        DCR: printTab.outputChannelID,
        PCR: printTab.outputFormatID,
        PPN: printTab.postprocessingNote,
        LISTPIC: printTab.printControlFileName,
        PRTPRIO: printTab.printPriority,
        BHPRG: printTab.bannerTrailerFileNameBanner,
        THPRG: printTab.bannerTrailerFileNameTrailer,
        DJDE: printTab.printControlFileNameBanner,
        DJDET: printTab.printControlFileNameTrailer
      }

      const formulaTabObj = {
        RDRSASEC: formulaTab.excludeFormula
      }

      if (generalTab.searchMode === 0) {
        formulaTabObj.RDRSASA = formulaTab.searchArgumentFormula.value
        formulaTabObj.RDRPOSS = formulaTab.pageOffsetAtStartQualifyingPages > 0 ? '+' : '-'
        formulaTabObj.RDRPOS = formulaTab.pageOffsetAtStartQualifyingPages < 0
          ? parseInt(formulaTab.pageOffsetAtStartQualifyingPages) * -1
          : formulaTab.pageOffsetAtStartQualifyingPages
        formulaTabObj.RDRPOES = formulaTab.pageOffsetAtEndQualifyingPages > 0 ? '+' : '-'
        formulaTabObj.RDRPOE = formulaTab.pageOffsetAtEndQualifyingPages < 0
          ? parseInt(formulaTab.pageOffsetAtEndQualifyingPages) * -1
          : formulaTab.pageOffsetAtEndQualifyingPages
      }
      else if (generalTab.searchMode === 1) {
        formulaTabObj.RDRSASS = formulaTab.startSearchArgumentFormula.value
        formulaTabObj.RDRSASE = formulaTab.stopSearchArgumentFormula.value
        formulaTabObj.RDRSPAGE = formulaTab.stopSearchArgumentOnSamePage === 0
        formulaTabObj.RDRPOSS = formulaTab.pageOffsetAtStartQualifyingPages > 0 ? '+' : '-'
        formulaTabObj.RDRPOS = formulaTab.pageOffsetAtStartQualifyingPages < 0
          ? parseInt(formulaTab.pageOffsetAtStartQualifyingPages) * -1
          : formulaTab.pageOffsetAtStartQualifyingPages
        formulaTabObj.RDRPOES = formulaTab.pageOffsetAtEndQualifyingPages > 0 ? '+' : '-'
        formulaTabObj.RDRPOE = formulaTab.pageOffsetAtEndQualifyingPages < 0
          ? parseInt(formulaTab.pageOffsetAtEndQualifyingPages) * -1
          : formulaTab.pageOffsetAtEndQualifyingPages
      }
      else if (generalTab.searchMode === 4) {
        formulaTabObj.RDRSASS = formulaTab.startSearchArgumentFormula.value
        formulaTabObj.RDRSASE = formulaTab.stopSearchArgumentFormula.value
        formulaTabObj.RDRSPAGE = formulaTab.stopSearchArgumentOnSamePage === 0
      }
      // search mode conditional bust 1 - 3
      else if ([5, 6, 7].includes(generalTab.searchMode)) {
        formulaTabObj.RDRSASA = formulaTab.searchArgumentFormula.value
      }

      const burstWindowTabObj = {
        RDRGPRFX: burstWindowTab.reportPrefix
      }

      // ! make sure the burstWindows array is build like its used here
      if (burstWindowTab.burstWindows.length > 0) {
        if (generalTab.burstMode === 0) {
          burstWindowTab.burstWindows.forEach((burstWindow, index) => {
            burstWindowTabObj[`RDRGKR0${index + 1}`] = burstWindow.line.value
            burstWindowTabObj[`RDRGKC0${index + 1}`] = burstWindow.column.value
            burstWindowTabObj[`RDRGKWL${index + 1}`] = burstWindow.length.value
            burstWindowTabObj[`RDRGKSU${index + 1}`] = burstWindow.suffix
          })
        }
        else if (generalTab.burstMode === 1) {
          burstWindowTab.burstWindows.forEach((burstWindow, index) => {
            burstWindowTabObj[`RDRSASW${index + 1}`] = burstWindow.searchArgument.value
            burstWindowTabObj[`RDRGKR0${index + 1}`] = burstWindow.line.value
            burstWindowTabObj[`RDRGKC0${index + 1}`] = burstWindow.column.value
            burstWindowTabObj[`RDRGKWL${index + 1}`] = burstWindow.length.value
            burstWindowTabObj[`RDRGKSU${index + 1}`] = burstWindow.suffix
          })
        }
      }

      const variablesTabObj = {
        LDRDMODE: variablesTab.variableExtraction === 1,
        LDRDCOMP: variablesTab.suppressLeadingBlanksAndRepeatedBlanksBetweenWords === 0,
        LDRDSAER: variablesTab.skipAllBlankValues,
        LDRDINV: variablesTab.assignInReverseOrder,
        LDRDSSER: variablesTab.skipLeadingBlankValuesOnly
      }

      if (variablesTab.variableExtraction === 0) {
        // ! check if needed for rest api
        // variablesTabObj.LDRVWROW = 0
        // variablesTabObj.LDRDMNUM = 0
        variablesTab.valueRelativePosition.forEach((value, index) => {
          variablesTabObj[`LDRV0${index + 1}R`] = value.line
          variablesTabObj[`LDRV0${index + 1}C`] = value.column
          variablesTabObj[`LDRV0${index + 1}L`] = value.length
          variablesTabObj[`LDRV0${index + 1}S`] = value.searchPattern
          variablesTabObj[`LDRV0${index + 1}SR`] = value.fromLine
          variablesTabObj[`LDRV0${index + 1}ER`] = value.toLine
          variablesTabObj[`LDRV0${index + 1}SC`] = value.fromColumn
          variablesTabObj[`LDRV0${index + 1}EC`] = value.toColumn
        })
      }
      else if (variablesTab.variableExtraction === 1) {
        variablesTabObj.LDRVWROW = variablesTab.numberOfLines
        variablesTabObj.LDRDMNUM = variablesTab.numberOfValues
        variablesTabObj.LDRV01R = variablesTab.valueRelativePosition[0].line
        variablesTabObj.LDRV01C = variablesTab.valueRelativePosition[0].column
        variablesTabObj.LDRV01L = variablesTab.valueRelativePosition[0].length
        variablesTabObj.LDRV01S = variablesTab.valueRelativePosition[0].searchPattern
        variablesTabObj.LDRV01SR = variablesTab.valueRelativePosition[0].fromLine
        variablesTabObj.LDRV01SC = variablesTab.valueRelativePosition[0].toLine
        variablesTabObj.LDRV01ER = variablesTab.valueRelativePosition[0].fromColumn
        variablesTabObj.LDRV01EC = variablesTab.valueRelativePosition[0].toColumn
      }

      const filterTabObj = {
        SLF: filterTab.filterArgumentID.value,
        SLFMLINE: filterTab.stopAfterNumberOfMatches,
        SLFHLINE: filterTab.headerLinesOnPageOne,
        SLFBLINE: filterTab.linesBeforeMatchingLines,
        SLFALINE: filterTab.linesAfterMatchingLines,
        SLFSLINE: filterTab.separatorBetweenNonContiguousLines,
        SLFMMARK: filterTab.markMatches,
        SLFNLINE: filterTab.prefixLinesWithInputLineNumber,
        SLFNODAT: filterTab.noDataFoundReport,
        SLFULINE: filterTab.prefixLinesWithBetaUxPageAndLineNumber
      }

      let documentObj = {
        ...generalTabObj,
        ...retentionTabObj,
        ...printTabObj,
        ...variablesTabObj,
        SAVEUTF8: saveAsUtf8
      }

      // search mode is none
      if (generalTab.searchMode === 0 && generalTab.report === '') {
        documentObj = { ...documentObj, ...attributesTabObj }
      }
      // search mode is absolute or start/stop
      else if (generalTab.searchMode === 0 || generalTab.searchMode === 1) {
        documentObj = { ...documentObj, ...formulaTabObj }
      }
      // search mode is autoburst, limit burst, conditional burst 1-3
      else if ([2, 4, 5, 6, 7].includes(generalTab.searchMode)) {
        documentObj = { ...documentObj, ...formulaTabObj, ...burstWindowTabObj }
      }
      else if (generalTab.searchMode === 9) {
        documentObj = { ...documentObj, ...filterTabObj }
      }

      createDocument(documentObj, () => onClose())
    }
  }

  /**
   * @description Validates the valid from.
   */
  validateValidFromDate = () => {
    const { generalTab } = this.state
    const { datemask } = this.props
    if (![0, 1, 2, 3, 4, 8, 9].includes(generalTab.searchMode)) {
      return {}
    }
    if (generalTab.validFrom.value !== '') {
      if (DateUtils.isDate(generalTab.validFrom.value, datemask)) {
        return {}
      }
    }
    return {
      validFrom: {
        ...this.state.generalTab.validFrom,
        error: translate('general.please_enter_a_valid_date')
      }
    }
  }

  /**
   * @description Validates the valid from.
   */
  validateValidToDate = () => {
    const { generalTab } = this.state
    const { datemask } = this.props
    if (![0, 1, 2, 3, 4, 8, 9].includes(generalTab.searchMode)) {
      return {}
    }
    if (generalTab.validTo.value !== '') {
      if (DateUtils.isDate(generalTab.validTo.value, datemask)) {
        return {}
      }
    }
    return {
      validTo: {
        ...this.state.generalTab.validTo,
        error: translate('general.please_enter_a_valid_date')
      }
    }
  }

  /**
   * @description Validates the form.
   */
  validateForm = () => {
    const { generalTab } = this.state
    if (generalTab.form.value !== '') {
      return {}
    }
    return {
      form: {
        ...generalTab.form,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the general tab.
   */
  validateGeneralTab = () => {
    const { generalTab } = this.state
    const validatorResult = {
      ...this.validateForm(),
      ...this.validateValidFromDate(),
      ...this.validateValidToDate()
    }
    const error = Object.keys(validatorResult).length
    if (error > 0) {
      this.setState(state => ({ generalTab: { ...state.generalTab, ...validatorResult } }), () => {
        generalTab.form.error !== '' && this.formInput.current && this.formInput.current.focus()
      })
    }
    return error === 0
  }

  /**
   * @description Validates the retention start date.
   */
  validateRetentionDate = () => {
    const { retentionTab } = this.state
    if (retentionTab.calculateRetentionByStartDate.value === 0 && (retentionTab.day === 0 && retentionTab.month === 0)) {
      return {
        calculateRetentionByStartDate: {
          ...this.state.retentionTab.calculateRetentionByStartDate,
          error: translate('general.retention_day_or_month_has_to_be_greater_than_zero')
        }
      }
    }
    return {}
  }


  /**
   * @description Validates the retention tab.
   */
  validateRetentionTab = () => {
    const validatorResult = {
      ...this.validateRetentionDate()
    }

    const error = Object.keys(validatorResult).length
    if (error > 0) {
      this.setState(state => ({ retentionTab: { ...state.retentionTab, ...validatorResult } }))
    }
    return error === 0
  }

  /**
   * @description Handles the picker validation. Used for onBlur because this validation is different from validation when save definition.
   * @param {string} key The state key.
   */
  handlePickerValidation = key => {
    const { generalTab } = this.state
    const { datemask } = this.props
    const result = generalTab[key]
    // if the field is required, the date picker must have a value
    if ([2, 3, 8, 9].includes(generalTab.searchMode)) {
      if (result.value !== '') {
        result.error = this.handlePickerError(result.value, datemask)
      }
      else {
        result.error = translate('general.input_required')
      }
    }
    else {
      if (result.value !== '') {
        result.error = this.handlePickerError(result.value, datemask)
      }
    }
    this.setState({ generalTab: { ...generalTab, [key]: result } })
  }

  handlePickerError = (val, datemask) => {
    if (!DateUtils.isDate(val, datemask)) {
      return translate('general.please_enter_a_valid_date')
    }
    return ''
  }

  /**
   * @description Renders the general tab in create document definition dialog.
   */
  renderGeneralTab = () => {
    const { generalTab } = this.state
    const { datemask, lang, id } = this.props
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_form`}
              required={`${translate('general.required_field')}`}
              title={translate('general.form')}
              onInputChanged={(val, error) => this.handleChangeWithoutSpaces('generalTab', 'form', val, error)}
              maxLength={8}
              value={generalTab.form.value}
              error={generalTab.form.error}
              ref={this.formInput}
              onBlur={() => this.setState({ generalTab: { ...this.state.generalTab, ...this.validateForm() } })}
              lettercase={LetterCase.uppercase}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_extension`}
              title={translate('general.extension')}
              onInputChanged={val => this.handleChangeWithoutSpaces('generalTab', 'extension', val)}
              maxLength={16}
              value={generalTab.extension}
              lettercase={LetterCase.uppercase}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Input
              id={`${id}_owner`}
              title={translate('general.owner')}
              onInputChanged={val => this.handleChange('generalTab', 'owner', val)}
              maxLength={8}
              value={generalTab.owner}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_report`}
              title={translate('general.report')}
              onInputChanged={val => this.handleChange('generalTab', 'report', val)}
              maxLength={16}
              value={generalTab.report}
              lettercase={LetterCase.uppercase}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_searchmode`}
              title={translate('definition.document_search_mode')}
              // display none when dropdown is disabled
              items={generalTab.report === ''
                ? [translate('general.none')]
                : DefinitionUtils.DOCUMENT_DEFINITION_SEARCH_MODES.map(d => translate(d.translationKey))
              }
              activeIndex={generalTab.searchMode}
              onChange={index => this.handleChange('generalTab', 'searchMode', index, '')}
              disabled={generalTab.report === ''}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Dropdown
              id={`${id}_burstmode`}
              title={translate('definition.document_burst_mode')}
              items={generalTab.report === '' || ![2, 4, 5, 6, 7].includes(generalTab.searchMode)
                ? [translate('general.none')]
                : DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODES.map(d => translate(d.translationKey))
              }
              activeIndex={generalTab.burstMode}
              onChange={index => this.handleChange('generalTab', 'burstMode', index)}
              disabled={generalTab.report === '' || ![2, 4, 5, 6, 7].includes(generalTab.searchMode)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Datepicker
              id={`${id}_valid_from`}
              title={translate('general.valid_from')}
              value={generalTab.validFrom.value}
              onChange={date => this.handleChange('generalTab', 'validFrom', date, '')}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateValidFromDate() } }))}
              // use different function here, because the default validation of toDate do not allowed empty input
              onInvalidDate={val => this.setState(state => ({
                generalTab: { ...state.generalTab, validFrom: { value: val, error: translate('general.please_enter_a_valid_date') } }
              }))}
              dateFormat={datemask}
              error={generalTab.validFrom.error}
              focusRef={this.validFromInput}
              bothDates
              required={[0, 1, 2, 3, 4, 8, 9].includes(generalTab.searchMode) ? `${translate('general.required_field')}` : false}
              language={lang}
              parentContainer={`${id}_tilecontainer`}
            />
          </Column>
          <Column colMD={3}>
            <Datepicker
              id={`${id}_valid_to`}
              title={translate('general.valid_to')}
              value={generalTab.validTo.value}
              onChange={date => this.handleChange('generalTab', 'validTo', date, '')}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateValidToDate() } }))}
              // use different function here, because thedefault validation of toDate do not allowed empty input
              onInvalidDate={val => this.setState(state => ({
                generalTab: { ...state.generalTab, validTo: { value: val, error: translate('general.please_enter_a_valid_date') } }
              }))}
              dateFormat={datemask}
              error={generalTab.validTo.error}
              focusRef={this.validToInput}
              bothDates
              required={[0, 1, 2, 3, 4, 8, 9].includes(generalTab.searchMode) ? `${translate('general.required_field')}` : false}
              language={lang}
              parentContainer={`${id}_tilecontainer`}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_control_status`}
              title={translate('documentinformation.statustab_controlstatus')}
              items={DefinitionUtils.DOCUMENT_DEFINITION_STATI.map(d => translate(d.translationKey))}
              activeIndex={generalTab.controlStatus}
              onChange={index => this.handleChange('generalTab', 'controlStatus', index)}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_control_user_id`}
              title={translate('general.control_user_id')}
              onInputChanged={val => this.handleChange('generalTab', 'controlUserID', val)}
              maxLength={8}
              value={generalTab.controlUserID}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label
              id={`${id}_report_generation_and_variable_extraction`}>
              {translate('definition.document_report_generation_and_variable_extraction')}
            </label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_afp_scan_only_nops`}
              title={translate('definition.document_afp_scan_only_nops')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('generalTab', 'scanOnlyNOPs', index)}
              activeIndex={generalTab.scanOnlyNOPs}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_afp_scan_only_tles`}
              title={translate('definition.document_afp_scan_only_tles')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('generalTab', 'scanOnlyTLEs', index)}
              activeIndex={generalTab.scanOnlyTLEs}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_afp_scan_only_pjl`}
              title={translate('definition.document_afp_scan_only_pjl')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('generalTab', 'scanOnlyPJL', index)}
              activeIndex={generalTab.scanOnlyPJL}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_title`}>{translate('general.title')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_title_type`}
              title={translate('general.title_type')}
              items={[translate('general.fix'), translate('general.dynamic_generated')]}
              onClick={index => this.handleChange('generalTab', 'titleType', index)}
              activeIndex={generalTab.titleType}
            />
          </Column>
          <Column colMD={8}>
            <Input
              id={`${id}_title`}
              title={translate('general.title')}
              onInputChanged={val => this.handleChange('generalTab', 'title', val)}
              maxLength={80}
              value={generalTab.title}
            />
          </Column>
        </Row>
        {
          generalTab.titleType === 1 &&
          <Row>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_page`}
                title={translate('general.page')}
                onChange={val => this.handleChange('generalTab', 'page', val)}
                value={generalTab.page}
                min={0}
                max={32767}
                steps={1}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_line`}
                title={translate('general.line')}
                onChange={val => this.handleChange('generalTab', 'line', val)}
                value={generalTab.line}
                min={0}
                max={9999}
                steps={1}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_column`}
                title={translate('general.column')}
                onChange={val => this.handleChange('generalTab', 'column', val)}
                value={generalTab.column}
                min={0}
                max={999}
                steps={1}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_length`}
                title={translate('general.length')}
                onChange={val => this.handleChange('generalTab', 'length', val)}
                value={generalTab.length}
                min={0}
                max={80}
                steps={1}
              />
            </Column>
          </Row>
        }
      </>
    )
  }

  /**
   * @description Renders the retention tab in create document definition dialog.
   */
  renderRetentionTab = () => {
    const { id } = this.props
    const { retentionTab } = this.state
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_online_retention`}>{translate('documentinformation.onlineretention')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <NumericSpinner
              id={`${id}_online_retention`}
              title={translate('definition.document_retention_days')}
              onChange={val => this.handleChange('retentionTab', 'onlineRetention', val)}
              value={retentionTab.onlineRetention}
              min={0}
              max={9999}
              steps={1}
            />
          </Column>
          <Column colMD={4} className={'bux_pr8'}>
            <Dropdown
              id={`${id}_type`}
              title={translate('general.type')}
              items={DefinitionUtils.DOCUMENT_DEFINITION_TYPES.map(d => translate(d.translationKey))}
              activeIndex={retentionTab.type}
              onChange={index => this.handleChange('retentionTab', 'type', index)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_archive_retention`}>{translate('documentinformation.retentiontab_archiveretention')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <NumericSpinner
              id={`${id}_archive_retention`}
              title={translate('definition.document_retention_days')}
              onChange={val => this.handleChange('retentionTab', 'archiveRetention', val)}
              value={retentionTab.archiveRetention}
              min={0}
              max={30000}
              steps={1}
            />
          </Column>
          <Column colMD={4} className={'bux_pr8'}>
            <Dropdown
              id={`${id}_media`}
              title={translate('general.media')}
              items={retentionTab.archiveRetention === 0
                ? [translate('general.none')]
                : DefinitionUtils.DOCUMENT_DEFINITION_MEDIA.map(d => translate(d.translationKey))}
              activeIndex={retentionTab.media}
              onChange={index => this.handleChange('retentionTab', 'media', index, '')}
              disabled={retentionTab.archiveRetention === 0}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_calculate_retention_by_start_date`}
              title={translate('definition.document_calculate_retention_by_start_date')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('retentionTab', 'calculateRetentionByStartDate', index)}
              activeIndex={retentionTab.calculateRetentionByStartDate.value}
              error={retentionTab.calculateRetentionByStartDate.error}
            />
          </Column>
          <Column colMD={2}>
            <NumericSpinner
              id={`${id}_day`}
              title={translate('statistic.unit_day')}
              onChange={val => this.handleSpinnerChange('day', val)}
              value={retentionTab.day}
              min={0}
              max={31}
              steps={1}
              disabled={retentionTab.calculateRetentionByStartDate.value === 1}
            />
          </Column>
          <Column colMD={2} className={'bux_pr8'}>
            <NumericSpinner
              id={`${id}_month`}
              title={translate('statistic.unit_month')}
              onChange={val => this.handleSpinnerChange('month', val)}
              value={retentionTab.month}
              min={0}
              max={12}
              steps={1}
              disabled={retentionTab.calculateRetentionByStartDate.value === 1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_automatic_archive_document`}
              title={translate('definition.document_automatic_archive_document')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('retentionTab', 'automaticArchiveDocument', index)}
              activeIndex={retentionTab.automaticArchiveDocument}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_archive_notes`}
              title={translate('definition.document_archive_notes')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('retentionTab', 'archiveNotes', index)}
              activeIndex={retentionTab.archiveNotes}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_archive_text_version`}
              title={translate('definition.document_archive_text_version')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('retentionTab', 'archiveTextVersion', index)}
              activeIndex={retentionTab.archiveTextVersion}
            />
          </Column>
        </Row>
      </>
    )
  }

  validateAttributesTabInput = key => {
    const { generalTab, attributesTab } = this.state
    if (generalTab.report !== '' || !attributesTab.extractDateFromDocument) {
      return {}
    }

    if (attributesTab.extractDateFromDocument && attributesTab[key].value !== '') {
      const regex = '\\s*\\S.*'
      if (!new RegExp(`^${regex}$`).test(attributesTab[key].value)) {
        return {
          [key]: {
            ...this.state.attributesTab[key],
            error: translate('general.input_no_start_space')
          }
        }
      }
      return {}
    }

    return {
      [key]: {
        ...this.state.attributesTab[key],
        error: translate('general.input_required')
      }
    }
  }

  handleFocusAttributeTab = () => {
    const { attributesTab } = this.state
    const focusArr = [
      { error: attributesTab.searchPattern.error, ref: this.searchPatternInput },
      { error: attributesTab.timeStringFormat.error, ref: this.timeStringFormatInput },
      { error: attributesTab.lctimeFormat.error, ref: this.lctimeFormatInput },
    ]
    for (let i = 0; i < focusArr.length; i++) {
      if (focusArr[i].error !== '') {
        focusArr[i].ref.current && focusArr[i].ref.current.focus()
        break
      }
    }
  }

  validateAttributesTab = () => {
    const validatorResult = {
      ...this.validateAttributesTabInput('searchPattern'),
      ...this.validateAttributesTabInput('timeStringFormat'),
      ...this.validateAttributesTabInput('lctimeFormat'),
    }
    const error = Object.keys(validatorResult).length
    if (error > 0) {
      this.setState({ attributesTab: { ...this.state.attributesTab, ...validatorResult } }, () => {
        this.handleFocusAttributeTab()
      })
    }
    return error === 0
  }

  /**
   * @description Renders the attributes tab in create document definition dialog.
   */
  renderAttributesTab = () => {
    const { attributesTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_data_compression`}
              title={translate('general.data_compression')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('attributesTab', 'dataCompression', index)}
              activeIndex={attributesTab.dataCompression}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_checksum`}
              title={translate('general.checksum')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('attributesTab', 'checkSum', index)}
              activeIndex={attributesTab.checkSum}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_text_documents`}>{translate('general.text_documents')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_encoding`}
              title={translate('documentinformation.header_encoding')}
              items={DefinitionUtils.DOCUMENT_DEFINITION_ENCODINGS.map(d => translate(d.translationKey))}
              activeIndex={attributesTab.encoding}
              onChange={index => this.handleChange('attributesTab', 'encoding', index)}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_replace_txt_by`}
              title={translate('definition.document_replace_txt_by')}
              onInputChanged={val => this.handleChange('attributesTab', 'replaceTxtBy', val)}
              maxLength={8}
              value={attributesTab.replaceTxtBy}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_maximum_lines_pages`}
              title={translate('definition.document_maximum_lines_pages')}
              onChange={val => this.handleChange('attributesTab', 'maximumLinesPages', val)}
              value={attributesTab.maximumLinesPages}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_header_lines`}
              title={translate('definition.document_header_lines')}
              onChange={val => this.handleChange('attributesTab', 'headerLines', val)}
              value={attributesTab.headerLines}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_start_new_page_when_value_begins_at_column_text_1`}
              title={translate('definition.document_start_new_page_when_value_begins_at_column')}
              onInputChanged={val => this.handleChange('attributesTab', 'startNewPageWhenValueBeginsAtColumnText1', val)}
              maxLength={64}
              value={attributesTab.startNewPageWhenValueBeginsAtColumnText1}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <NumericSpinner
              id={`${id}_start_new_page_when_value_begins_at_column_number_1`}
              title={translate('general.column')}
              onChange={val => this.handleChange('attributesTab', 'startNewPageWhenValueBeginsAtColumnNumber1', val)}
              value={attributesTab.startNewPageWhenValueBeginsAtColumnNumber1}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_start_new_page_when_value_begins_at_column_text_2`}
              onInputChanged={val => this.handleChange('attributesTab', 'startNewPageWhenValueBeginsAtColumnText2', val)}
              maxLength={64}
              value={attributesTab.startNewPageWhenValueBeginsAtColumnText2}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <NumericSpinner
              id={`${id}_start_new_page_when_value_begins_at_column_number_2`}
              title={' '}
              onChange={val => this.handleChange('attributesTab', 'startNewPageWhenValueBeginsAtColumnNumber2', val)}
              value={attributesTab.startNewPageWhenValueBeginsAtColumnNumber2}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_afp_text_version`}>{translate('general.afp_text_version')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Input
              id={`${id}_ptx_encoding`}
              title={translate('definition.document_ptx_encoding_utf8')}
              onInputChanged={val => this.handleChange('attributesTab', 'ptxEncoding', val)}
              maxLength={64}
              value={attributesTab.ptxEncoding.display}
              addon={{
                iconName: 'list',
                onClick: () => this.setState({ showPTXSelectorDialog: true }),
              }}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_nops_encoded_in_ebcdic`}
              title={translate('definition.document_nops_encoded_in_ebcdic')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('attributesTab', 'nopsEncodedInEBCDIC', index)}
              activeIndex={attributesTab.nopsEncodedInEBCDIC}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_tles_encoded_in_ebcdic`}
              title={translate('definition.document_tles_encoded_in_ebcdic')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('attributesTab', 'tlesEncodedInEBCDIC', index)}
              activeIndex={attributesTab.tlesEncodedInEBCDIC}
            />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_other_text_version`}>{translate('definition.document_other_textversion')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_pdf_text_version_in_utf8`}
              title={translate('definition.document_pdf_textversion_in_utf8')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('attributesTab', 'pdfTextVersionInUTF8', index)}
              activeIndex={attributesTab.pdfTextVersionInUTF8}
            />
          </Column>
          <Column colMD={4} className={'bux_pr8'}>
            <Switch
              id={`${id}_pcl_text_version_in_ebcdic`}
              title={translate('definition.document_pcl_textversion_in_ebcdic')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('attributesTab', 'pclTextVersionInEBCDIC', index)}
              activeIndex={attributesTab.pclTextVersionInEBCDIC}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Checkbox
              id={`${id}_extract_date_from_document`}
              label={translate('definition.document_extract_date_from_document')}
              value={attributesTab.extractDateFromDocument}
              onCheck={isChecked => this.handleChange('attributesTab', 'extractDateFromDocument', isChecked)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_search_pattern`}
              ref={this.searchPatternInput}
              title={translate('definition.filter_argument_search_pattern')}
              onInputChanged={(val, error) => this.handleChange('attributesTab', 'searchPattern', val, error)}
              onBlur={() => this.setState(state => ({ attributesTab: { ...state.attributesTab, ...this.validateAttributesTabInput('searchPattern') } }))}
              maxLength={32}
              value={attributesTab.searchPattern.value}
              error={attributesTab.extractDateFromDocument && attributesTab.searchPattern.error}
              disabled={!attributesTab.extractDateFromDocument}
              required={`${translate('general.required_field')}`}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_from_line`}
              title={translate('general.from_line')}
              onChange={val => this.handleChange('attributesTab', 'fromLine', val)}
              value={attributesTab.fromLine}
              min={0}
              max={32767}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_to_line`}
              title={translate('general.to_line')}
              onChange={val => this.handleChange('attributesTab', 'toLine', val)}
              value={attributesTab.toLine}
              min={0}
              max={32767}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
        </Row>
        <Row>
          <Column offsetMD={6} colMD={3} className={'bux_pl8'}>
            <NumericSpinner
              id={`${id}_from_column`}
              title={translate('general.from_column')}
              onChange={val => this.handleChange('attributesTab', 'fromColumn', val)}
              value={attributesTab.fromColumn}
              min={0}
              max={32767}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_to_column`}
              title={translate('general.to_column')}
              onChange={val => this.handleChange('attributesTab', 'toColumn', val)}
              value={attributesTab.toColumn}
              min={0}
              max={32767}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_content_extraction_from_column`}
              title={translate('definition.document_content_extraction_from_column')}
              onChange={val => this.handleChange('attributesTab', 'contentExtractionFromColumn', val)}
              value={attributesTab.contentExtractionFromColumn}
              min={0}
              max={32767}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_content_extraction_length`}
              title={translate('definition.document_content_extraction_length')}
              onChange={val => this.handleChange('attributesTab', 'contentExtractionLength', val)}
              value={attributesTab.contentExtractionLength}
              min={0}
              max={50}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
          <Column colMD={6}>
            <Switch
              id={`${id}_content_extraction_relative_to`}
              title={translate('definition.document_content_extraction_relative_to')}
              items={[
                translate('general.line_start'),
                translate('general.search_pattern_start'),
                translate('general.search_pattern_end')
              ]}
              onClick={index => this.handleChange('attributesTab', 'contentExtractionRelativeTo', index)}
              activeIndex={attributesTab.contentExtractionRelativeTo}
              disabled={!attributesTab.extractDateFromDocument}
              maxPerRow={3}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_time_string_format`}
              title={translate('general.time_string_format')}
              onInputChanged={(val, error) => this.handleChange('attributesTab', 'timeStringFormat', val, error)}
              onBlur={() => this.setState(state => ({ attributesTab: { ...state.attributesTab, ...this.validateAttributesTabInput('timeStringFormat') } }))}
              maxLength={32}
              value={attributesTab.timeStringFormat.value}
              error={attributesTab.extractDateFromDocument && attributesTab.timeStringFormat.error}
              disabled={!attributesTab.extractDateFromDocument}
              ref={this.timeStringFormatInput}
              required={`${translate('general.required_field')}`}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Input
              id={`${id}_lctime_format`}
              title={translate('general.lc_time')}
              onInputChanged={(val, error) => this.handleChange('attributesTab', 'lctimeFormat', val, error)}
              onBlur={() => this.setState(state => ({ attributesTab: { ...state.attributesTab, ...this.validateAttributesTabInput('lctimeFormat') } }))}
              maxLength={32}
              value={attributesTab.lctimeFormat.value}
              error={attributesTab.extractDateFromDocument && attributesTab.lctimeFormat.error}
              disabled={!attributesTab.extractDateFromDocument}
              ref={this.lctimeFormatInput}
              required={`${translate('general.required_field')}`}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the print tab in create document definition dialog.
   */
  renderPrintTab = () => {
    const { printTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label
              id={`${id}_document_print_parameter`}>
              {translate('definition.document_document_print_parameter')}
            </label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_autoprint`}
              title={translate('import.autoprint')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('printTab', 'autoprint', index)}
              activeIndex={printTab.autoprint}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_hold_bundle_request`}
              title={translate('definition.document_hold_bundle_request')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('printTab', 'holdBundleRequest', index)}
              activeIndex={printTab.holdBundleRequest}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_use_copies_value_from_list_generation_record`}
              title={translate('definition.document_use_copies_value_from_list_generation_record')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('printTab', 'useCopiesValueFromListGeneratedRecord', index)}
              activeIndex={printTab.useCopiesValueFromListGeneratedRecord}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Input
              id={`${id}_output_channel`}
              value={printTab.outputChannelID}
              title={translate('definition.output_channel_id')}
              maxLength={16}
              onInputChanged={val => this.handleChange('printTab', 'outputChannelID', val)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputChannelDefinitionDialog(),
              }
              }
            />
          </Column>
          <Column colMD={4}>
            <Input
              id={`${id}_output_format`}
              value={printTab.outputFormatID}
              title={translate('definition.output_format_id')}
              maxLength={16}
              onInputChanged={val => this.handleChange('printTab', 'outputFormatID', val)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputFormatDefinitionDialog(),
              }}
            />
          </Column>
          <Column colMD={4}>
            <Input
              id={`${id}_postprocessing_note`}
              value={printTab.postprocessingNote}
              title={translate('definition.postprocessing_note_id')}
              maxLength={16}
              onInputChanged={val => this.handleChange('printTab', 'postprocessingNote', val)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputFormatDefinitionDialog(),
              }
              }
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Input
              id={`${id}_print_control_file_name`}
              title={translate('definition.ppn_print_control_filename')}
              onInputChanged={val => this.handleChange('printTab', 'printControlFileName', val)}
              maxLength={8}
              value={printTab.printControlFileName}
            />
          </Column>
          <Column colMD={2}>
            <NumericSpinner
              id={`${id}_print_priority`}
              title={translate('general.print_priority')}
              onChange={val => this.handleChange('printTab', 'printPriority', val)}
              value={printTab.printPriority}
              min={0}
              max={15}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_banner_trailer_file_name`}>{translate('definition.document_banner_trailer_file_name')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_banner_trailer_file_name_banner`}
              title={translate('recipient.banner')}
              onInputChanged={val => this.handleChange('printTab', 'bannerTrailerFileNameBanner', val)}
              maxLength={8}
              value={printTab.bannerTrailerFileNameBanner}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_banner_trailer_file_name_trailer`}
              title={translate('recipient.banner_tab_banner_file_name_trailer')}
              onInputChanged={val => this.handleChange('printTab', 'bannerTrailerFileNameTrailer', val)}
              maxLength={8}
              value={printTab.bannerTrailerFileNameTrailer}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_print_control_file_name`}>{translate('definition.document_print_control_file_name')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_print_control_file_name_banner`}
              title={translate('recipient.banner')}
              onInputChanged={val => this.handleChange('printTab', 'printControlFileNameBanner', val)}
              maxLength={8}
              value={printTab.printControlFileNameBanner}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_print_control_file_name_trailer`}
              title={translate('recipient.banner_tab_banner_file_name_trailer')}
              onInputChanged={val => this.handleChange('printTab', 'printControlFileNameTrailer', val)}
              maxLength={8}
              value={printTab.printControlFileNameTrailer}
            />
          </Column>
        </Row>
      </>
    )
  }

  formatText = (tab, key, validation) => {
    let value = typeof this.state[tab][key] === 'object' ? this.state[tab][key].value : this.state[tab][key]
    this.setState(state => ({
      [tab]: {
        ...state[tab],
        [key]: typeof state[tab][key] === 'object'
          ? { value: value.replace(/(\r\n|\n|\r)/g, ' ').toUpperCase(), error: '' }
          : value.replace(/(\r\n|\n|\r)/g, ' ').toUpperCase()
      }
    }), () => {
      if (validation) {
        this.setState(state => ({
          [tab]: {
            ...state[tab], ...validation()
          }
        }))
      }
    })
  }

  renderFormulaTabAbsolute = () => {
    const { formulaTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              id={`${id}_search_argument_formula`}
              title={translate('definition.document_search_argument_formula')}
              value={formulaTab.searchArgumentFormula.value}
              error={formulaTab.searchArgumentFormula.error}
              onInputChanged={val => this.handleChange('formulaTab', 'searchArgumentFormula', val, '')}
              onBlur={() => this.formatText('formulaTab', 'searchArgumentFormula', this.validateFormulaSearch)}
              rows={6}
              mono
              required={`${translate('general.required_field')}`}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_page_offset_at_start_qualifying_pages`}
              title={translate('definition.document_page_offset_at_start_qualifying_pages')}
              onChange={val => this.handleChange('formulaTab', 'pageOffsetAtStartQualifyingPages', val)}
              value={formulaTab.pageOffsetAtStartQualifyingPages}
              min={-32767}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_page_offset_at_end_qualifying_pages`}
              title={translate('definition.document_page_offset_at_end_qualifying_pages')}
              onChange={val => this.handleChange('formulaTab', 'pageOffsetAtEndQualifyingPages', val)}
              value={formulaTab.pageOffsetAtEndQualifyingPages}
              min={-32767}
              max={32767}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              id={`${id}_exclude_formula`}
              title={translate('definition.search_argument_exclude_formula')}
              value={formulaTab.excludeFormula}
              onInputChanged={val => this.handleChange('formulaTab', 'excludeFormula', val)}
              onBlur={() => this.formatText('formulaTab', 'excludeFormula')}
              rows={6}
              mono
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Validates the search argument formula textarea
   */
  validateFormulaSearch = () => {
    const { generalTab, formulaTab } = this.state
    if ([0, 5, 6, 7].includes(generalTab.searchMode) && generalTab.report !== '') {
      if (formulaTab.searchArgumentFormula.value !== '') {
        return {}
      }
      return {
        searchArgumentFormula: {
          ...this.state.formulaTab.searchArgumentFormula,
          error: translate('general.input_required')
        }
      }
    } else {
      return {}
    }
  }

  /**
   * @description Validates the start search argument formula textarea
   */
  validateFormulaStartSearch = () => {
    const { generalTab, formulaTab } = this.state
    if (generalTab.searchMode === 1 || generalTab.searchMode === 4) {
      if (formulaTab.startSearchArgumentFormula.value !== '') {
        return {}
      }
      return {
        startSearchArgumentFormula: {
          ...this.state.formulaTab.startSearchArgumentFormula,
          error: translate('general.input_required')
        }
      }
    } else {
      return {}
    }
  }

  /**
   * @description Validates the stop search argument formula textarea
   */
  validateFormulaStopSearch = () => {
    const { generalTab, formulaTab } = this.state
    if (generalTab.searchMode === 1 || generalTab.searchMode === 4) {
      if (formulaTab.stopSearchArgumentFormula.value !== '') {
        return {}
      }
      return {
        stopSearchArgumentFormula: {
          ...this.state.formulaTab.stopSearchArgumentFormula,
          error: translate('general.input_required')
        }
      }
    } else {
      return {}
    }
  }

  validateFormulaTab = () => {
    const validatorResult = {
      ...this.validateFormulaSearch(),
      ...this.validateFormulaStartSearch(),
      ...this.validateFormulaStopSearch()
    }
    const error = Object.keys(validatorResult).length
    if (error > 0) {
      this.setState(state => ({ formulaTab: { ...state.formulaTab, ...validatorResult } }))
    }
    return error === 0
  }

  renderFormulaTabStartStop = () => {
    const { formulaTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              id={`${id}_start_search_argument_formula`}
              title={translate('definition.document_start_search_argument_formula')}
              value={formulaTab.startSearchArgumentFormula.value}
              onInputChanged={val => this.handleChange('formulaTab', 'startSearchArgumentFormula', val, '')}
              onBlur={() => this.formatText('formulaTab', 'startSearchArgumentFormula', this.validateFormulaStartSearch)}
              error={formulaTab.startSearchArgumentFormula.error}
              rows={6}
              required={`${translate('general.required_field')}`}
              mono
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              id={`${id}_stop_search_argument_formula`}
              title={translate('definition.document_stop_search_argument_formula')}
              value={formulaTab.stopSearchArgumentFormula.value}
              onInputChanged={val => this.handleChange('formulaTab', 'stopSearchArgumentFormula', val, '')}
              onBlur={() => this.formatText('formulaTab', 'stopSearchArgumentFormula', this.validateFormulaStopSearch)}
              error={formulaTab.stopSearchArgumentFormula.error}
              rows={6}
              required={`${translate('general.required_field')}`}
              mono
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_stop_argument_formula_on_same_page`}
              title={translate('definition.document_stop_argument_formula_on_same_page')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('formulaTab', 'stopSearchArgumentOnSamePage', index)}
              activeIndex={formulaTab.stopSearchArgumentOnSamePage}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_page_offset_at_start_qualifying_pages`}
              title={translate('definition.document_page_offset_at_start_qualifying_pages')}
              onChange={val => this.handleChange('formulaTab', 'pageOffsetAtStartQualifyingPages', val)}
              value={formulaTab.pageOffsetAtStartQualifyingPages}
              min={-32767}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_page_offset_at_end_qualifying_pages`}
              title={translate('definition.document_page_offset_at_end_qualifying_pages')}
              onChange={val => this.handleChange('formulaTab', 'pageOffsetAtEndQualifyingPages', val)}
              value={formulaTab.pageOffsetAtEndQualifyingPages}
              min={-32767}
              max={32767}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              id={`${id}_exclude_formula`}
              title={translate('definition.search_argument_exclude_formula')}
              value={formulaTab.excludeFormula}
              onInputChanged={val => this.handleChange('formulaTab', 'excludeFormula', val)}
              onBlur={() => this.formatText('formulaTab', 'excludeFormula')}
              rows={6}
              mono
            />
          </Column>
        </Row>
      </>
    )
  }

  renderFormulaTabAutoburst = () => {
    const { formulaTab } = this.state
    const { id } = this.props
    return (
      <Row>
        <Column colMD={12}>
          <MultilineInput
            id={`${id}_exclude_formula`}
            title={translate('definition.search_argument_exclude_formula')}
            value={formulaTab.excludeFormula}
            onInputChanged={val => this.handleChange('formulaTab', 'excludeFormula', val)}
            onBlur={() => this.formatText('formulaTab', 'excludeFormula')}
            rows={6}
            mono
          />
        </Column>
      </Row>
    )
  }

  renderFormulaTabLimitBurst = () => {
    const { formulaTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              id={`${id}_start_search_argument_formula`}
              title={translate('definition.document_start_search_argument_formula')}
              value={formulaTab.startSearchArgumentFormula.value}
              onInputChanged={val => this.handleChange('formulaTab', 'startSearchArgumentFormula', val, '')}
              onBlur={() => this.formatText('formulaTab', 'startSearchArgumentFormula', this.validateFormulaStartSearch)}
              error={formulaTab.startSearchArgumentFormula.error}
              rows={6}
              required={`${translate('general.required_field')}`}
              mono
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              id={`${id}_stop_search_argument_formula`}
              title={translate('definition.document_stop_search_argument_formula')}
              value={formulaTab.stopSearchArgumentFormula.value}
              onInputChanged={val => this.handleChange('formulaTab', 'stopSearchArgumentFormula', val, '')}
              onBlur={() => this.formatText('formulaTab', 'stopSearchArgumentFormula', this.validateFormulaStopSearch)}
              error={formulaTab.stopSearchArgumentFormula.error}
              rows={6}
              required={`${translate('general.required_field')}`}
              mono
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_stop_argument_formula_on_same_page`}
              title={translate('definition.document_stop_argument_formula_on_same_page')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('formulaTab', 'stopSearchArgumentOnSamePage', index)}
              activeIndex={formulaTab.stopSearchArgumentOnSamePage}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              id={`${id}_exclude_formula`}
              title={translate('definition.search_argument_exclude_formula')}
              value={formulaTab.excludeFormula}
              onInputChanged={val => this.handleChange('formulaTab', 'excludeFormula', val)}
              onBlur={() => this.formatText('formulaTab', 'excludeFormula')}
              rows={6}
              mono
            />
          </Column>
        </Row>
      </>
    )
  }

  renderFormulaTabConditionalBurst = () => {
    const { formulaTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              id={`${id}_search_argument_formula`}
              title={translate('definition.document_search_argument_formula')}
              value={formulaTab.searchArgumentFormula.value}
              error={formulaTab.searchArgumentFormula.error}
              onInputChanged={val => this.handleChange('formulaTab', 'searchArgumentFormula', val, '')}
              onBlur={() => this.formatText('formulaTab', 'searchArgumentFormula', this.validateFormulaSearch)}
              rows={6}
              mono
              required={`${translate('general.required_field')}`}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              id={`${id}_exclude_formula`}
              title={translate('definition.search_argument_exclude_formula')}
              value={formulaTab.excludeFormula}
              onInputChanged={val => this.handleChange('formulaTab', 'excludeFormula', val)}
              onBlur={() => this.formatText('formulaTab', 'excludeFormula')}
              rows={6}
              mono
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the formula tab in create document definition dialog.
   */
  renderFormulaTab = () => {
    const { generalTab } = this.state
    return (
      <>
        {
          {
            0: this.renderFormulaTabAbsolute(),
            1: this.renderFormulaTabStartStop(),
            2: this.renderFormulaTabAutoburst(),
            4: this.renderFormulaTabLimitBurst(),
            5: this.renderFormulaTabConditionalBurst(),
            6: this.renderFormulaTabConditionalBurst(),
            7: this.renderFormulaTabConditionalBurst()
          }[generalTab.searchMode]
        }
      </>
    )
  }

  handleAddBurstWindowRow = () => {
    const { burstWindowTab } = this.state
    if (burstWindowTab.burstWindows.length < 8) {
      const newBurstWindow = {
        searchArgument: { value: '', error: '' },
        line: { value: 0, error: '' },
        column: { value: 0, error: '' },
        length: { value: 0, error: '' },
        suffix: ''
      }
      this.setState({
        burstWindowTab: {
          ...burstWindowTab,
          burstWindows: [...burstWindowTab.burstWindows, newBurstWindow]
        }
      })
    }
  }

  handleBurstWindowsChange = (index, key, val) => {
    const { burstWindowTab } = this.state
    let newBurstWindows = burstWindowTab.burstWindows
    newBurstWindows[index][key] = typeof newBurstWindows[index][key] === 'object' ? { value: val, error: '' } : val
    this.setState({
      burstWindowTab: {
        ...burstWindowTab,
        burstWindows: newBurstWindows
      }
    })
  }

  handleDeleteBurstWindow = index => {
    const { burstWindowTab } = this.state
    this.setState({
      burstWindowTab: {
        ...burstWindowTab,
        burstWindows: burstWindowTab.burstWindows.filter((_, i) => i !== index)
      }
    })
  }

  /**
   * @description Validates one search argument field.
   */
  validateSearchArgument = index => {
    const { burstWindowTab } = this.state
    // Checks if the component stil exists. This is necessary, because the component could be deleted through UI.
    if (burstWindowTab.burstWindows[index]) {
      if (burstWindowTab.burstWindows[index].searchArgument.value !== '') {
        return {}
      }
      const newBurstWindows = burstWindowTab.burstWindows
      newBurstWindows[index].searchArgument = { value: '', error: translate('general.input_required') }
      return {
        burstWindows: [...newBurstWindows]
      }
    }
  }

  /**
   * @description Validates all existing search argument fields.
   */
  validateSearchArguments = () => {
    const { generalTab, burstWindowTab } = this.state
    let errorFound = false
    const result = burstWindowTab.burstWindows
    // if burst mode is FIX, we do not have to validate this field
    if (generalTab.burstMode === 0) {
      return {}
    }
    for (let i = 0; i < burstWindowTab.burstWindows.length; i++) {
      if (result[i].searchArgument.value === '') {
        errorFound = true
        result[i].searchArgument.error = translate('general.input_required')
      }
    }
    if (errorFound) {
      return {
        burstWindows: [...result]
      }
    }
    return {}
  }


  validateLine = () => {
    const { generalTab, burstWindowTab } = this.state
    const result = burstWindowTab.burstWindows
    // if burst mode is RELATIVE or burst window tab disabled, we do not have to validate this field
    if (generalTab.burstMode === 1 || ![2, 4, 5, 6, 7].includes(generalTab.searchMode)) {
      return {}
    }
    if (result.length > 0 && result[0].line.value === 0) {
      result[0].line.error = translate('general.spinner_error')
      return { burstWindows: [...result] }
    }
    return {}
  }

  validateColumn = () => {
    const { generalTab, burstWindowTab } = this.state
    const result = burstWindowTab.burstWindows
    // if burst mode is RELATIVE or burst window tab disabled, we do not have to validate this field
    if (generalTab.burstMode === 1 || ![2, 4, 5, 6, 7].includes(generalTab.searchMode)) {
      return {}
    }
    if (result.length > 0 && result[0].column.value === 0) {
      result[0].column.error = translate('general.spinner_error')
      return { burstWindows: [...result] }
    }
    return {}
  }

  validateLength = () => {
    const { generalTab, burstWindowTab } = this.state
    const result = burstWindowTab.burstWindows
    // if burst window tab is disabled
    if (![2, 4, 5, 6, 7].includes(generalTab.searchMode)) {
      return {}
    }
    if (result.length > 0 && result[0].length.value === 0) {
      result[0].length.error = translate('general.spinner_error')
      return { burstWindows: [...result] }
    }
    return {}
  }

  /**
   * @description Handles the focus for all search argument fields.
   */
  handleFocusOnSearchArguments = () => {
    const { burstWindowTab } = this.state
    for (let i = 0; i < burstWindowTab.burstWindows.length; i++) {
      if (burstWindowTab.burstWindows[i].searchArgument.error !== '') {
        this.searchArgumentRefs[i].current && this.searchArgumentRefs[i].current.focus()
        break
      }
    }
  }

  validateBurstWindowTab = () => {
    const validatorResult = {
      ...this.validateSearchArguments(),
      ...this.validateLine(),
      ...this.validateColumn(),
      ...this.validateLength()
    }
    const error = Object.keys(validatorResult).length
    if (error > 0) {
      this.setState({ burstWindowTab: { ...this.state.burstWindowTab, ...validatorResult } }, () => {
        this.handleFocusOnSearchArguments()
      })
    }
    return error === 0
  }

  renderBurstWindows = () => {
    const { generalTab, burstWindowTab } = this.state
    const { id } = this.props
    const buffer = []
    for (let i = 0; i < burstWindowTab.burstWindows.length; i++) {
      // search mode === 1 (FIX) | search mode === 2 (RELATIVE)
      buffer.push(
        <>
          <Row>
            <Column colMD={4}>
              <Input
                id={`${id}_${i}_${generalTab.burstMode === 0 ? 'burst_window' : 'search_argument_id'}`}
                ref={generalTab.burstMode === 0 ? false : this.searchArgumentRefs[i]}
                // just set title for the first row
                title={i === 0 ? translate(generalTab.burstMode === 0 ? 'general.burst_windows' : 'definition.searchargument_id') : false}
                onInputChanged={generalTab.burstMode === 0 ? null : val => this.handleBurstWindowsChange(i, 'searchArgument', val)}
                maxLength={generalTab.burstMode === 0 ? false : 16}
                value={generalTab.burstMode === 0 ? `${i + 1}.` : burstWindowTab.burstWindows[i].searchArgument.value}
                error={generalTab.burstMode === 0 ? '' : burstWindowTab.burstWindows[i].searchArgument.error}
                onBlur={generalTab.burstMode === 0 ? false : () => this.setState(state => ({ burstWindowTab: { ...state.burstWindowTab, ...this.validateSearchArgument(i) } }))}
                disabled={generalTab.burstMode === 0}
                // just first row should display the required indikator
                required={i === 0 && generalTab.burstMode === 1 ? `${translate('general.required_field')}` : false}
              />
            </Column>
            <Column colMD={2}>
              <NumericSpinner
                id={`${id}_${i}_line`}
                // just set title for the first row
                title={i === 0 ? translate('general.line') : ' '}
                onChange={val => this.handleBurstWindowsChange(i, 'line', val)}
                onBlur={generalTab.burstMode === 0 && i === 0 && (() => this.setState(state => ({ burstWindowTab: { ...state.burstWindowTab, ...this.validateLine() } })))}
                value={burstWindowTab.burstWindows[i].line.value}
                error={burstWindowTab.burstWindows[i].line.error}
                min={0}
                max={32767}
                steps={1}
                required={i === 0 && generalTab.burstMode === 0 ? `${translate('general.required_field')}` : false}
              />
            </Column>
            <Column colMD={2}>
              <NumericSpinner
                id={`${id}_${i}_column`}
                // just set title for the first row
                title={i === 0 ? translate('general.column') : ' '}
                onChange={val => this.handleBurstWindowsChange(i, 'column', val)}
                onBlur={generalTab.burstMode === 0 && i === 0 && (() => this.setState(state => ({ burstWindowTab: { ...state.burstWindowTab, ...this.validateColumn() } })))}
                value={burstWindowTab.burstWindows[i].column.value}
                error={burstWindowTab.burstWindows[i].column.error}
                min={0}
                max={32767}
                steps={1}
                required={i === 0 && generalTab.burstMode === 0 ? `${translate('general.required_field')}` : false}
              />
            </Column>
            <Column colMD={2}>
              <NumericSpinner
                id={`${id}_${i}_length`}
                // just set title for the first row
                title={i === 0 ? translate('general.length') : ' '}
                onChange={val => this.handleBurstWindowsChange(i, 'length', val)}
                onBlur={i === 0 && (() => this.setState(state => ({ burstWindowTab: { ...state.burstWindowTab, ...this.validateLength() } })))}
                value={burstWindowTab.burstWindows[i].length.value}
                error={burstWindowTab.burstWindows[i].length.error}
                min={0}
                max={255}
                steps={1}
                required={i === 0 ? `${translate('general.required_field')}` : false}
              />
            </Column>
            <Column colMD={1}>
              <Input
                id={`${id}_${i}_suffix`}
                // just set title for the first row
                title={i === 0 ? translate('general.suffix') : false}
                onInputChanged={val => this.handleBurstWindowsChange(i, 'suffix', val)}
                maxLength={1}
                value={burstWindowTab.burstWindows[i].suffix}
                ignoreMinWidth
              />
            </Column>
            <Column className={'bux_flex'} colMD={1}>
              <Button
                id={`${id}_delete_burst_window_${i}`}
                icon={'delete'}
                onClick={() => this.handleDeleteBurstWindow(i)}
                className={'bux_mt18'}
                containerClass={'bux_align_right'}
                disabled={burstWindowTab.burstWindows.length === 1}
                tooltip={burstWindowTab.burstWindows.length === 1 && translate('definition.document_one_burst_window_required')}
              />
            </Column>
          </Row>
        </>
      )
    }
    return buffer
  }

  /**
   * @description Renders the burst-window tab in create document definition dialog.
   */
  renderBurstWindowTab = () => {
    const { burstWindowTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_report_prefix`}
              title={translate('general.report_prefix')}
              onInputChanged={val => this.handleChange('burstWindowTab', 'reportPrefix', val)}
              maxLength={8}
              value={burstWindowTab.reportPrefix}
            />
          </Column>
          <Column className={'bux_flex_column'} offsetMD={6} colMD={3}>
            <Button
              id={`${id}_add_burst_window`}
              title={' '}
              text={translate('general.add_burst_window')}
              onClick={() => this.handleAddBurstWindowRow()}
              containerClass={'bux_align_right'}
              disabled={burstWindowTab.burstWindows.length === 8}
              tooltip={burstWindowTab.burstWindows.length === 8 && translate('definition.document_maximum_burst_windows_is_reached')}
            />
          </Column>
        </Row>
        {this.renderBurstWindows()}
      </>
    )
  }

  /**
   * @description Renders the variables tab in create document definition dialog.
   */
  renderVariablesTab = () => {
    const { id } = this.props
    const { variablesTab } = this.state
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_extract_variable_values`}>{translate('definition.document_extract_variable_values')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_variable_extraction`}
              title={translate('definition.document_variable_extraction')}
              items={[
                translate('definition.document_according_to_rules_1_8'),
                translate('definition.document_from_position_of_rule_1')
              ]}
              onClick={index => this.handleChange('variablesTab', 'variableExtraction', index)}
              activeIndex={variablesTab.variableExtraction}
            />
          </Column>
          {variablesTab.variableExtraction === 1 && (
            <>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_number_of_lines`}
                  title={translate('definition.document_number_of_lines')}
                  onChange={val => this.handleChange('variablesTab', 'numberOfLines', val)}
                  value={variablesTab.numberOfLines}
                  min={0}
                  max={32}
                  steps={1}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_number_of_values`}
                  title={translate('definition.document_number_of_values')}
                  onChange={val => this.handleChange('variablesTab', 'numberOfValues', val)}
                  value={variablesTab.numberOfValues}
                  min={0}
                  max={8}
                  steps={1}
                />
              </Column>
            </>
          )}
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_supress_leading_blanks_and_repeated_blanks_between_words`}
              title={translate('definition.document_supress_leading_blanks_and_repeated_blanks_between_words')}
              items={[
                translate('general.yes'),
                translate('general.no')
              ]}
              onClick={index => this.handleChange('variablesTab', 'suppressLeadingBlanksAndRepeatedBlanksBetweenWords', index)}
              activeIndex={variablesTab.suppressLeadingBlanksAndRepeatedBlanksBetweenWords}
            />
          </Column>
        </Row>
        {this.renderValueRelativePosition()}
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_assign_values_to_docusr_variables`}>{translate('definition.document_assign_values_to_docusr_variables')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Toggle
              id={`${id}_skip_all_blank_values`}
              onCheck={val => this.handleChange('variablesTab', 'skipAllBlankValues', val)}
              value={variablesTab.skipAllBlankValues}
              title={translate('definition.document_skip_all_blank_values')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
          <Column colMD={4}>
            <Toggle
              id={`${id}_assign_in_reverse_order`}
              onCheck={val => this.handleChange('variablesTab', 'assignInReverseOrder', val)}
              value={variablesTab.assignInReverseOrder}
              title={translate('definition.document_assign_in_reverse_order')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Toggle
              id={`${id}_skip_leading_blank_values_only`}
              onCheck={val => this.handleChange('variablesTab', 'skipLeadingBlankValuesOnly', val)}
              value={variablesTab.skipLeadingBlankValuesOnly}
              title={translate('definition.document_skip_leading_blank_values_only')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the changes of the numeric spinners.
   * @param {String} key The key of the numeric spinner in state.
   * @param {Number} index The index of the numeric spinner (row).
   * @param {Number} val The new value.
   */
  handleRelativePosition = (key, index, val) => {
    const newValue = this.state.variablesTab.valueRelativePosition
    newValue[index][key] = val
    this.setState({
      variablesTab: {
        ...this.state.variablesTab,
        valueRelativePosition: newValue
      }
    })
  }

  /**
   * @description Renders the value relative position section
   */
  renderValueRelativePosition = () => {
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={7}>
            <label id={`${id}_value_relative_position`}>{translate('definition.document_value_relative_position')}</label>
          </Column>
          <Column colMD={5}>
            <label id={`${id}_search`}>{translate('general.search')}</label>
          </Column>
        </Row>
        {this.renderValueRelativePositionRows()}
      </>
    )
  }

  renderValueRelativePositionRows = () => {
    const { id } = this.props
    const { variablesTab } = this.state
    return (
      variablesTab.valueRelativePosition.map((el, i) => (
        <>
          <Row>
            <Column colMD={4}>
              <Row removePadding>
                <Column colMD={5}>
                  <Row removePadding>
                    <Column colMD={2}>
                      <label className={'bux_mb0 bux_mt28'}>{i + 1}.</label>
                    </Column>
                    <Column colMD={10}>
                      <NumericSpinner
                        id={`${id}_line_${i}`}
                        onChange={val => this.handleRelativePosition('line', i, val)}
                        value={el.line}
                        min={0}
                        max={32767}
                        steps={1}
                        disableButtons
                        title={i === 0 ? translate('general.line') : ' '}
                        disabled={variablesTab.variableExtraction === 1 && i > 0}
                      />
                    </Column>
                  </Row>
                </Column>
                <Column colMD={4}>
                  <NumericSpinner
                    id={`${id}_column_${i}`}
                    onChange={val => this.handleRelativePosition('column', i, val)}
                    value={el.column}
                    min={0}
                    max={32767}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.column') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_length_${i}`}
                    onChange={val => this.handleRelativePosition('length', i, val)}
                    value={el.length}
                    min={0}
                    max={32}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.length') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
              </Row>
            </Column>
            <Column colMD={3}>
              <Input
                id={`${id}_searchpattern_${i}`}
                onInputChanged={val => this.handleRelativePosition('searchPattern', i, val)}
                maxLength={32}
                value={el.searchPattern}
                disabled={variablesTab.variableExtraction === 1 && i > 0}
                title={i === 0 ? translate('general.searchpattern') : ' '}
              />
            </Column>
            <Column colMD={5}>
              <Row removePadding>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_from_line_${i}`}
                    onChange={val => this.handleRelativePosition('fromLine', i, val)}
                    value={el.fromLine}
                    min={0}
                    max={32767}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.from_line') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_to_line_${i}`}
                    onChange={val => this.handleRelativePosition('toLine', i, val)}
                    value={el.toLine}
                    min={0}
                    max={32767}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.to_line') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_from_column_${i}`}
                    onChange={val => this.handleRelativePosition('fromColumn', i, val)}
                    value={el.fromColumn}
                    min={0}
                    max={32767}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.from_column') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_to_column_${i}`}
                    onChange={val => this.handleRelativePosition('toColumn', i, val)}
                    value={el.toColumn}
                    min={0}
                    max={32767}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.to_column') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </>
      ))
    )
  }

  /**
   * @description Validates the filter argument ID
   */
  validateFilterArgumentID = () => {
    const { generalTab, filterTab } = this.state
    if (generalTab.searchMode === 9) {
      if (filterTab.filterArgumentID.value !== '') {
        return {}
      }
      return {
        filterArgumentID: {
          ...this.state.filterTab.filterArgumentID,
          error: 'general.input_required'
        }
      }
    } else {
      return {}
    }
  }

  /**
   * @description Validates the filter tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateFilterTab = () => {
    const validatorResult = { ...this.validateFilterArgumentID() }
    const errors = Object.keys(this.validateFilterArgumentID()).length
    if (errors > 0) {
      this.setState({ filterTab: { ...this.state.filterTab, ...validatorResult } }, () => {
        this.filterArgumentIdInput.current && this.filterArgumentIdInput.current.focus()
      })
    }
    return errors === 0
  }

  /**
   * @description Renders the filter tab in create document definition dialog.
   */
  renderFilterTab = () => {
    const { filterTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_filterargumentid`}
              value={filterTab.filterArgumentID.value}
              title={translate('definition.filter_argument_id')}
              ref={this.filterArgumentIdInput}
              maxLength={16}
              error={filterTab.filterArgumentID.error && translate(filterTab.filterArgumentID.error)}
              onInputChanged={val => this.handleChange('filterTab', 'filterArgumentID', val, '')}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputFormatDefinitionDialog(),
              }
              }
              onBlur={() => this.setState({ filterTab: { ...this.state.filterTab, ...this.validateFilterArgumentID() } })}
              required={`${translate('general.required_field')}`}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_stop_after_number_of_matches`}
              title={translate('definition.document_stop_after_number_of_matches')}
              onChange={val => this.handleChange('filterTab', 'stopAfterNumberOfMatches', val)}
              value={filterTab.stopAfterNumberOfMatches}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_header_lines_on_page_one`}
              title={translate('definition.document_header_lines_on_page_one')}
              onChange={val => this.handleChange('filterTab', 'headerLinesOnPageOne', val)}
              value={filterTab.headerLinesOnPageOne}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_lines_before_matching_lines`}
              title={translate('definition.document_lines_before_matching_lines')}
              onChange={val => this.handleChange('filterTab', 'linesBeforeMatchingLines', val)}
              value={filterTab.linesBeforeMatchingLines}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_lines_after_matching_lines`}
              title={translate('definition.document_lines_after_matching_lines')}
              onChange={val => this.handleChange('filterTab', 'linesAfterMatchingLines', val)}
              value={filterTab.linesAfterMatchingLines}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Toggle
              id={`${id}_separator_between_noncontiguous_lines`}
              onCheck={val => this.handleChange('filterTab', 'separatorBetweenNonContiguousLines', val)}
              value={filterTab.separatorBetweenNonContiguousLines}
              title={translate('definition.document_separator_between_noncontiguous_lines')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
          <Column colMD={6}>
            <Toggle
              id={`${id}_mark_matches`}
              onCheck={val => this.handleChange('filterTab', 'markMatches', val)}
              value={filterTab.markMatches}
              title={translate('definition.document_mark_matches')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Toggle
              id={`${id}_prefix_lines_with_input_line_number`}
              onCheck={val => this.handleChange('filterTab', 'prefixLinesWithInputLineNumber', val)}
              value={filterTab.prefixLinesWithInputLineNumber}
              title={translate('definition.document_prefix_lines_with_input_line_number')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
          <Column colMD={6}>
            <Toggle
              id={`${id}_no_data_found_report`}
              onCheck={val => this.handleChange('filterTab', 'noDataFoundReport', val)}
              value={filterTab.noDataFoundReport}
              title={translate('definition.document_no_data_found_report')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Toggle
              id={`${id}_prefix_lines_with_betaux_page_line_number`}
              onCheck={val => this.handleChange('filterTab', 'prefixLinesWithBetaUxPageAndLineNumber', val)}
              value={filterTab.prefixLinesWithBetaUxPageAndLineNumber}
              title={translate('definition.document_prefix_lines_with_betaux_page_line_number')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
      </>
    )
  }

  handleErrorTabs = () => {
    const { generalTab, retentionTab, attributesTab, formulaTab, burstWindowTab, filterTab } = this.state
    const buffer = []
    // possible general tab errors
    const isFormError = generalTab.form.error !== ''
    const isValidFromError = generalTab.validFrom.error !== ''
    const isValidToError = generalTab.validTo.error !== ''
    // possible retention tab errors
    const isValidRetentionError = retentionTab.calculateRetentionByStartDate.error !== ''
    // possible attributes tab errors
    const isSearchPatternError = generalTab.report === '' && attributesTab.searchPattern.error !== ''
    const isLctTimeFormatError = attributesTab.lctimeFormat.error !== ''
    const isTimeStringFormatError = attributesTab.timeStringFormat.error !== ''
    // possible formula tab errors
    const isSearchArgumentFormulaError = formulaTab.searchArgumentFormula.error !== ''
    const isStartSearchArgumentFormulaError = formulaTab.startSearchArgumentFormula.error !== ''
    const isStopSearchArgumentFormulaError = formulaTab.stopSearchArgumentFormula.error !== ''
    // possible burst window tab errors
    // possible filter tab errors
    const isFilterArgumentError = filterTab.filterArgumentID.error !== ''
    const isSearchArgumentError = () => {
      let result = false
      if (generalTab.burstMode === 0 || burstWindowTab.burstWindows.length === 0) {
        return result
      }
      for (let i = 0; i < burstWindowTab.burstWindows.length; i++) {
        if (burstWindowTab.burstWindows[i].searchArgument.error !== '') {
          result = true
          break
        }
      }
      return result
    }
    const isLineError = burstWindowTab.burstWindows.length > 0 && burstWindowTab.burstWindows[0].line.error !== ''
    const isColumnError = burstWindowTab.burstWindows.length > 0 && burstWindowTab.burstWindows[0].column.error !== ''
    const isLengthError = burstWindowTab.burstWindows.length > 0 && burstWindowTab.burstWindows[0].length.error !== ''
    if (isFormError || isValidFromError || isValidToError) {
      buffer.push(0)
    }
    if (isValidRetentionError) {
      buffer.push(1)
    }
    if (isSearchPatternError || isTimeStringFormatError || isLctTimeFormatError) {
      buffer.push(2)
    }
    if (isSearchArgumentFormulaError || isStartSearchArgumentFormulaError || isStopSearchArgumentFormulaError) {
      buffer.push(4)
    }
    if (isSearchArgumentError() || isLineError || isColumnError || isLengthError) {
      buffer.push(5)
    }
    if (isFilterArgumentError && generalTab.searchMode === 9) {
      buffer.push(7)
    }
    return buffer
  }

  /**
   * @description Returns the disabled tab indices.
   * @returns {Array.<Number>}
   */
  getDisabledTabs = () => {
    const { generalTab } = this.state
    let buffer = []
    switch (generalTab.searchMode) {
      case 0:
        if (generalTab.report === '') {
          // if search mode value is none
          buffer.push(4, 5, 7)
        }
        else {
          // if search mode value is absolute
          buffer.push(2, 5, 7)
        }
        break
      // if search mode value is start/stop
      case 1:
        buffer.push(2, 5, 7)
        break
      // if search mode value is autoburst
      case 2:
        buffer.push(2, 7)
        break
      // if search mode value is control
      case 3:
        buffer.push(2, 4, 5, 7)
        break
      case 4:
        // if search mdoe value is limit burst
        buffer.push(2, 7)
        break
      // if search mode value is cond/burst 1
      case 5:
        buffer.push(2, 7)
        break
      // if search mode value is cond/burst 2
      case 6:
        buffer.push(2, 7)
        break
      // if search mode value is cond/burst 3
      case 7:
        buffer.push(2, 7)
        break
      // if search mode value is exclude
      case 8:
        buffer.push(2, 4, 5, 7)
        break
      // if search mode value is filter
      case 9:
        buffer.push(2, 4, 5)
        break
      default:
        break
    }
    return buffer
  }

  /**
 * @description Requests the outputformat definition with the current selection. On successful request it opens the seletor dialog.
 */
  onOpenOutputFormatDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showOutputFormatDefinitionSelectorDialog: true })
    }

    this.props.getOutputFormatDefinitions(
      ['PCR', 'PCRTITLE'],
      this.state.printTab.outputFormat,
      callback)
  }

  /**
   * @description Requests the outputchannel definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenOutputChannelDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showOutputChannelDefinitionSelectorDialog: true })
    }
    this.props.getOutputChannelDefinitions(
      ['DCR', 'DCRTYPE', 'DCRTITLE'],
      this.state.printTab.outputChannelID,
      callback)
  }

  /**
   * @description Requests the postprocessingnote definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenPostProcessingNoteDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showPostProcessingNoteDefinitionSelectorDialog: true })
    }

    this.props.getPostProcessingNotesDefinitions(
      ['PPN', 'PPNTITLE'],
      this.state.printTab.postprocessingNote,
      callback)
  }

  /**
   * @description Requests the filter argument definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenFilterArgumentDefinitionDialog = () => {
    const callback = () => this.setState({ showFilterArgumentDefinitionSelectorDialog: true })
    this.props.getFilterArgumentsDefinitions(
      ['SLF', 'SLFTITLE'],
      this.state.filterTab.filterArgumentID.value,
      callback
    )
  }

  /**
 * @description Renders the selector dialogs.
 */
  renderSelectorDialogs = () => {
    const {
      showPTXSelectorDialog,
      showOutputFormatDefinitionSelectorDialog,
      showOutputChannelDefinitionSelectorDialog,
      showPostProcessingNoteDefinitionSelectorDialog,
      showFilterArgumentDefinitionSelectorDialog
    } = this.state
    const { id } = this.props
    // outputformat definitions
    let outputFormatDefinitions
    if (showOutputFormatDefinitionSelectorDialog) {
      outputFormatDefinitions = this.props.selector.outputformats
    }

    // outputchannel definitions
    let outputChannelDefinitions
    if (showOutputChannelDefinitionSelectorDialog) {
      outputChannelDefinitions = this.props.selector.outputchannels
    }

    // postprocessingnote definitions
    let postProcessingNoteDefinitions
    if (showPostProcessingNoteDefinitionSelectorDialog) {
      postProcessingNoteDefinitions = this.props.selector.ppns
    }

    // filter argument definitions
    let filterArgumentDefinitions
    if (showFilterArgumentDefinitionSelectorDialog) {
      filterArgumentDefinitions = this.props.selector.filterarguments
    }

    return (
      <>
        {showOutputFormatDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputformatselector_dialog`}
            onClose={() => {
              this.setState({ showOutputFormatDefinitionSelectorDialog: false })
            }}
            title={translate('definition.outputformatdefinitions')}
            header={[
              translate('definition.output_format_id'),
              translate('general.title'),
            ]}
            items={outputFormatDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputformat = outputFormatDefinitions.data[selectedRows][outputFormatDefinitions.header.indexOf('PCR')]
                this.setState({
                  showOutputFormatDefinitionSelectorDialog: false,
                  printTab: {
                    ...this.state.printTab,
                    outputFormatID: newOutputformat
                  }
                })
              }
            }}
          />
        )}
        {showOutputChannelDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_outputchannelselector_dialog`}
            onClose={() => {
              this.setState({ showOutputChannelDefinitionSelectorDialog: false })
            }}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('definition.output_channel_id'),
              translate('general.type'),
              translate('general.title')
            ]}
            items={outputChannelDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputchannel = outputChannelDefinitions.data[selectedRows][outputChannelDefinitions.header.indexOf('DCR')]
                this.setState({
                  showOutputChannelDefinitionSelectorDialog: false,
                  printTab: {
                    ...this.state.printTab,
                    outputChannelID: newOutputchannel
                  }
                })
              }
            }}
          />
        )}
        {showPostProcessingNoteDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_postprocessingnoteselector_dialog`}
            onClose={() => this.setState({ showPostProcessingNoteDefinitionSelectorDialog: false })}
            title={translate('definition.post_processing_note_definition')}
            header={[
              translate('definition.postprocessing_note_id'),
              translate('general.title')
            ]}
            items={postProcessingNoteDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newPostprocessingNode = postProcessingNoteDefinitions.data[selectedRows][postProcessingNoteDefinitions.header.indexOf('PPN')]
                this.setState({
                  showPostProcessingNoteDefinitionSelectorDialog: false,
                  printTab: {
                    ...this.state.printTab,
                    postprocessingNote: newPostprocessingNode
                  }
                })
              }
            }}
          />
        )}
        {showPTXSelectorDialog && (
          <SelectorDialog
            id={`${id}_ptx_encoding_selector_dialog`}
            onClose={() => this.setState({ showPTXSelectorDialog: false })}
            title={translate('definition.document_ptx_encoding_utf8')}
            header={[
              translate('general.code_page'),
              translate('general.title'),
              translate('general.ascii_ebcdic'),
              translate('general.utf8'),
            ]}
            items={DefinitionUtils.PTX_ENCODINGS}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const ptxEncoding = DefinitionUtils.PTX_ENCODINGS[selectedRows][0] !== ''
                  ? {
                    key: DefinitionUtils.PTX_ENCODINGS[selectedRows][0],
                    display: DefinitionUtils.PTX_ENCODINGS[selectedRows][0],
                    encoding: DefinitionUtils.PTX_ENCODINGS[selectedRows][2]
                  }
                  : {
                    key: '',
                    display: DefinitionUtils.PTX_ENCODINGS[selectedRows][1],
                    encoding: DefinitionUtils.PTX_ENCODINGS[selectedRows][2]
                  }
                this.setState({ showPTXSelectorDialog: false, attributesTab: { ...this.state.attributesTab, ptxEncoding } })
              }
            }}
          />
        )}
        {showFilterArgumentDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_filterargumentselector_dialog`}
            onClose={() => this.setState({ showFilterArgumentDefinitionSelectorDialog: false })}
            title={translate('definition.filter_argument_definition')}
            header={[
              translate('definition.filter_argument_id'),
              translate('general.title')
            ]}
            items={filterArgumentDefinitions.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newFilterArgumentID = filterArgumentDefinitions.data[selectedRows][filterArgumentDefinitions.header.indexOf('SLF')]
                this.setState({
                  showFilterArgumentDefinitionSelectorDialog: false,
                  filterTab: {
                    ...this.state.filterTab,
                    filterArgumentID: { value: newFilterArgumentID, error: '' }
                  }
                })
              }
            }}
          />
        )}
      </>
    )
  }

  render = () => {
    const { id, onClose, lang } = this.props
    return (
      <>
        {this.renderSelectorDialogs()}
        <Modal onClose={onClose}
          id={id}
          className={'bux_UserProfileModal'}
          size={'l'}>
          <Header
            id={`${id}_modalHeader`}
            title={translate('definition.document_create_modal_title')}
            onClose={onClose} />
          <Main id={id}>
            <Tabs
              id={id}
              lang={lang}
              disabledTabs={this.getDisabledTabs()}
              errorTabs={this.handleErrorTabs()}>
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab title={translate('documentinformation.retentiontab')}>
                {this.renderRetentionTab()}
              </Tab>
              <Tab title={translate('definition.attributes')}>
                {this.renderAttributesTab()}
              </Tab>
              <Tab title={translate('recipient.print')}>
                {this.renderPrintTab()}
              </Tab>
              <Tab title={translate('definition.filter_argument_formula')}>
                {this.renderFormulaTab()}
              </Tab>
              <Tab title={translate('general.burst_window')}>
                {this.renderBurstWindowTab()}
              </Tab>
              <Tab title={translate('general.variables')}>
                {this.renderVariablesTab()}
              </Tab>
              <Tab title={translate('definition.document_filter')}>
                {this.renderFilterTab()}
              </Tab>
            </Tabs>
          </Main>
          <Footer>
            {this.props.data && !hasNoValues(this.props.data) &&
              <Button
                id={`${id}_resetbtn`}
                tooltip={translate('general.reset')}
                icon={'undo'}
                onClick={this.resetPrefilledData}
              />
            }
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn_iso88591`}
              text={translate('general.save_as_iso88591')}
              onClick={() => this.handleSave(false)}
              primary
              submit
            />
            <Button
              id={`${id}_savebtn_utf8`}
              text={translate('general.save_as_utf8')}
              onClick={() => this.handleSave(true)}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences.DATEMASK,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createDocument: (documentDefinition, callback) => {
      DocumentDefinitionActions.createDocument(documentDefinition, callback)(dispatch)
    },
    getOutputFormatDefinitions: (fields, outputFormat, callback) => {
      ModalSelectorActions.getOutputFormatDefinitions(
        fields,
        outputFormat,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getPostProcessingNotesDefinitions: (fields, ppn, callback) => {
      ModalSelectorActions.getPPNDefinitions(
        fields,
        ppn,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getFilterArgumentsDefinitions: (fields, filterArgument, callback) => {
      ModalSelectorActions.getFilterArgumentsDefinitions(
        fields,
        undefined,
        filterArgument,
        undefined,
        callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocumentDefinitionDialog)