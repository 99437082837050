import { Component } from 'react'

import { Button, Card, Column, Input, Row } from 'BetaUX2Web-Components/src/'
import TimeCard from 'components/time_card/TimeCard'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as SearchSyslogActions from 'redux/actions/SearchSyslogActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as Utils from 'utils/Utils'

import { connect } from 'react-redux'
import { validateTimecardErrors } from '../SearchUtils'

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.hours' },
  { key: UNIT_DAYS, translationKey: 'general.days' },
]

class SearchSyslogSearch extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    showJobgroupsDialog: false,
    time: {
      activeTimeTabIndex: 0,
      activeLastTimeModeIndex: 0,
      customLast: 1,
      activeUnitIndex: 2,
      type: 0,
      period: 1,
      unit: 0,
      startDate: {
        value: '',
        error: ''
      },
      startTime: {
        value: '',
        error: ''
      },
      endDate: {
        value: '',
        error: ''
      },
      endTime: {
        value: '',
        error: ''
      }
    },
    jobgroup: '',
    jobID: '',
    jobname: '',
    system: '',
    submitUser: ''
  }

  state = {
    ...this.defaultState
  }

  componentDidMount = () => {
    const { preferences, datemask } = this.props

    if (preferences) {
      let activeTimeTabIndex = Utils.convertStringToInt(preferences[Preferences.SEARCH_SYSLOG_ACTIVE_TAB]) || 0
      let startDate = activeTimeTabIndex === 1 && preferences[Preferences.SEARCH_SYSLOG_START_DATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SEARCH_SYSLOG_START_DATE])
        : ''

      let startTime = preferences[Preferences.SEARCH_SYSLOG_START_TIME] || ''
      startTime = Utils.convertStringToInt(startTime)

      let endDate = preferences[Preferences.SEARCH_SYSLOG_END_DATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SEARCH_SYSLOG_END_DATE])
        : ''

      let endTime = preferences[Preferences.SEARCH_SYSLOG_END_TIME] || ''
      endTime = Utils.convertStringToInt(endTime)

      const resultIndexUnit = UNITS.findIndex(d => d.key === preferences[Preferences.SEARCH_SYSLOG_TIME_CUSTOM_UNIT])
      const unitIndex = resultIndexUnit !== -1 ? resultIndexUnit : 2

      const jobgroup = preferences[Preferences.SEARCH_SYSLOG_JOBGROUP] || ''
      const jobID = preferences[Preferences.SEARCH_SYSLOG_JOBID] || ''
      const jobname = preferences[Preferences.SEARCH_SYSLOG_JOBNAME] || ''
      const system = preferences[Preferences.SEARCH_SYSLOG_SYSTEM] || ''
      const submitUser = preferences[Preferences.SEARCH_SYSLOG_SUBMIT_USER] || ''

      this.setState({
        time: {
          activeTimeTabIndex,
          type: Utils.convertStringToInt(preferences[Preferences.SEARCH_SYSLOG_LASTTIME_MODE]) || 0,
          period: Utils.convertStringToInt(preferences[Preferences.SEARCH_SYSLOG_TIME_CUSTOM_LAST]) || 1,
          unit: unitIndex,
          startDate: {
            value: startDate,
            error: ''
          },
          startTime: {
            value: startTime,
            error: ''
          },
          endDate: {
            value: endDate,
            error: ''
          },
          endTime: {
            value: endTime,
            error: ''
          }
        },
        jobgroup,
        jobID,
        jobname,
        system,
        submitUser
      })
    }
  }

  /**
 * @description Handles the jobgroup selector button
 */
  handleJobgroupSelector = () => {
    this.props.getJobgroups(
      ['JGIGNAME', 'JGITITLE'],
      { JGIGNAME: this.state.jobgroup },
      () => this.setState({ showJobgroupsDialog: true })
    )
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => this.setState(this.defaultState)


  /**
   * @description Handles changes on input fields.
   * @param {String} id The id of the field to change
   * @param {String} value The new value
   */
  handleInputChanged = (id, value) => this.setState({ [id]: value })

  /**
   * @description Handles the changes in timecard.
   * @param {String} key The key of the input in state.
   * @param {String} val The new value of the input.
   * @param {String} err The new error of the input.
   */
  handleTimeCardChange = (key, val, err) => {
    this.setState(state => ({
      time: {
        ...state.time,
        [key]: typeof state.time[key] === 'object'
          ? {
            value: val,
            error: err || ''
          }
          : val
      }
    }))
  }

  handleSubmitSearch = event => {
    const { time, jobgroup, jobID, jobname, system, submitUser } = this.state
    const { datemask, getSyslogJobs, changePrefs } = this.props

    event.preventDefault()
    const errors = validateTimecardErrors(time, datemask);
    this.setState({ ...this.state, time: { ...time, ...errors } })
    if (Object.keys(errors).length === 0) {
      const prefsToChange = {
        [Preferences.SEARCH_SYSLOG_ACTIVE_TAB]: time.activeTimeTabIndex,
        [Preferences.SEARCH_SYSLOG_LASTTIME_MODE]: time.type,
        [Preferences.SEARCH_SYSLOG_TIME_CUSTOM_LAST]: time.period,
        [Preferences.SEARCH_SYSLOG_TIME_CUSTOM_UNIT]: UNITS[time.unit].key,
        [Preferences.SEARCH_SYSLOG_START_DATE]: DateUtils.getRequestFormat(time.startDate.value, datemask),
        [Preferences.SEARCH_SYSLOG_START_TIME]: DateUtils.formatTimeToDefault(time.startTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_SYSLOG_END_DATE]: DateUtils.getRequestFormat(time.endDate.value, datemask),
        [Preferences.SEARCH_SYSLOG_END_TIME]: DateUtils.formatTimeToDefault(time.endTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_SYSLOG_JOBGROUP]: jobgroup,
        [Preferences.SEARCH_SYSLOG_JOBID]: jobID,
        [Preferences.SEARCH_SYSLOG_JOBNAME]: jobname,
        [Preferences.SEARCH_SYSLOG_SYSTEM]: system,
        [Preferences.SEARCH_SYSLOG_SUBMIT_USER]: submitUser,
      }
      changePrefs(prefsToChange)

      const searchParams = {
        LOGSOURCE: 'SYSL',
        JOBGROUP: jobgroup,
        JOBID: jobID,
        SYSTEMNAME: system,
        RUNUSER: submitUser,
        JOBNAME: jobname
      }

      if (time.activeTimeTabIndex === 0) {
        prefsToChange[Preferences.QUEUE_OUTPUT_START_DATE] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_START_TIME] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_END_DATE] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_END_TIME] = ''
        if (time.type === 0) {
          searchParams['SDATE'] = 'TODAY'
          prefsToChange[Preferences.QUEUE_OUTPUT_START_DATE] = 'TODAY'
        }
        else if (time.type === 1) {
          searchParams['SDATE'] = 'YESTERDAY'
          prefsToChange[Preferences.QUEUE_OUTPUT_START_DATE] = 'YESTERDAY'
        }
        else if (time.type === 2) {
          searchParams['FROMLAST'] = time.period
          searchParams['TUNITS'] = DateUtils.UNITS[time.unit].key
        }
      }
      else if (time.activeTimeTabIndex === 1) {
        searchParams['SDATE'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.startTime.value !== '') {
          searchParams['STIME'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.TIME_DATEMASK)
        }
        searchParams['EDATE'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.endTime.value !== '') {
          searchParams['ETIME'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.TIME_DATEMASK)
        }

        prefsToChange[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_LAST] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_UNIT] = ''
      }

      getSyslogJobs(searchParams)
    }
  }

  /**
   * @description Returns the values for the timecard.
   * @returns {Object} The timecard values.
   */
  timeCardValues = () => {
    const { time } = this.state
    return {
      tabIndex: time.activeTimeTabIndex,
      lastTimeModeIndex: time.type,
      customLast: time.period,
      customUnitIndex: time.unit,
      fromDate: {
        value: time.startDate.value,
        error: time.startDate.error
      },
      fromTime: {
        value: time.startTime.value,
        error: time.startTime.error
      },
      toDate: {
        value: time.endDate.value,
        error: time.endDate.error
      },
      toTime: {
        value: time.endTime.value,
        error: time.endTime.error
      }
    }
  }

  /**
   * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
   * Needed for update function inside the TimeCard.
   * @returns {Object} The object with the state keys.
   */
  timeCardStateKeys = () => {
    return {
      tabIndex: 'activeTimeTabIndex',
      lastTimeModeIndex: 'type',
      customLast: 'period',
      customUnitIndex: 'unit',
      fromDate: 'startDate',
      fromTime: 'startTime',
      toDate: 'endDate',
      toTime: 'endTime',
    }
  }

  /**
   * @description Renders the time card.
   */
  renderTimeCard = () => {
    const { id, lang, datemask } = this.props
    return (
      <TimeCard
        id={id}
        lang={lang}
        datemask={datemask}
        values={this.timeCardValues()}
        stateKeys={this.timeCardStateKeys()}
        onValuesChange={this.handleTimeCardChange}
        parentContainer={'drawer_content_search_body_main'}
        translate={key => translate(key)}
      />
    )
  }

  /**
   * @description Renders the job data card.
   */
  renderJobData = () => {
    const { jobgroup, jobID, jobname, system, submitUser } = this.state
    const { id } = this.props

    return (
      <Card
        id={`${id}_syslog_job_data`}
        title={translate('job.job_data')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_syslog_jobgroup`}
              onInputChanged={value => this.setState({ jobgroup: value })}
              value={jobgroup}
              title={translate('general.jobgroup')}
              maxLength={80}
              addon={{
                iconName: 'list',
                onClick: () => this.handleJobgroupSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_syslog_jobid`}
              onInputChanged={(val) => this.handleInputChanged('jobID', val)}
              value={jobID}
              title={translate('job.jobid')}
              maxLength={8}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_syslog_jobname`}
              onInputChanged={(val) => this.handleInputChanged('jobname', val)}
              value={jobname}
              title={translate('general.jobname')}
              maxLength={8}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_syslog_system`}
              onInputChanged={(val) => this.handleInputChanged('system', val)}
              value={system}
              title={translate('general.system')}
              maxLength={8}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_syslog_submituser`}
              onInputChanged={(val) => this.handleInputChanged('submitUser', val)}
              value={submitUser}
              title={translate('job.submit_user')}
              maxLength={8}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the component which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderTimeCard()}
        {this.renderJobData()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props
    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { showJobgroupsDialog } = this.state
    const { id, selector } = this.props
    return (
      <>
        {showJobgroupsDialog && (
          <SelectorDialog
            id={`${id}_jobgroups_selector_dialog`}
            onClose={() => this.setState({ showJobgroupsDialog: false })}
            title={translate('definition.jobgroups')}
            header={[translate('general.jobgroup_name'), translate('general.title')]}
            items={selector.jobgroups.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newJobgroup = selector.jobgroups.data[selectedRows][selector.jobgroups.header.indexOf('JGIGNAME')]
                this.setState({ jobgroup: newJobgroup })
              }
              this.setState({ showJobgroupsDialog: false })
            }}
          />
        )}
        <form
          id={id}
          className={'bux_drawer_form'}
          onSubmit={this.handleSubmitSearch}>
          {this.renderMain()}
          {this.renderFooter()}
        </form>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences.DATEMASK,
    selector: state.selector
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch),
    getSyslogJobs: (searchObj, callback) => SearchSyslogActions.getSyslogJobs(undefined, searchObj, callback)(dispatch),
    getJobgroups: (fields, searchObj, callback) => ModalSelectorActions.getJobgroups(fields, searchObj, callback)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchSyslogSearch)