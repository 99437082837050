import React, { Component } from 'react'
import PropTypes from 'prop-types'

import UserManagementUsers from './usermanagement_users/UserManagementUsers'
import UserManagementGroups from './usermanagement_groups/UserManagementGroups'
import UserManagementRemoteUsers from './usermanagement_remoteusers/UserManagementRemoteUsers'

class UserManagementBody extends Component {

  /**
   * Returns the body for the usermanagement-drawer based on the current url
   */
  getBody() {

    const { id, definitionMode } = this.props

    // Get drawer content by url-param
    switch (definitionMode) {
      case 'groups': return <UserManagementGroups id={id} />
      case 'users': return <UserManagementUsers id={id} />
      case 'remoteusers': return <UserManagementRemoteUsers id={id} />
      // should not happen
      default: return null
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

UserManagementBody.propTypes = {
  id: PropTypes.string.isRequired,
  definitionMode: PropTypes.string.isRequired,
}

export default UserManagementBody