import * as IndexDocumentAssignmentActions from '../actions/IndexDocumentAssignmentActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  indexDocumentAssignments: undefined,
  indexDocumentAssignment: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get index-documents actions
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_GET_ASSIGNMENTS_START:
      return { ...state }
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_GET_ASSIGNMENTS_SUCCESS:
      return { ...state, indexDocumentAssignments: action.payload, keepPagination: action.keepPagination }
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_GET_ASSIGNMENTS_FAILED:
      return { ...state }
    case IndexDocumentAssignmentActions.NO_INDEX_DOCUMENT_ASSIGNMENTS_FOUND:
      return { ...state, indexDocumentAssignments: {} }
    /* ********************************************************************************** */
    // Definition get index-document actions
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_GET_ASSIGNMENT_START:
      return { ...state, indexDocumentAssignment: undefined }
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_GET_ASSIGNMENT_SUCCESS:
      return { ...state, indexDocumentAssignment: action.payload }
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_GET_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition create index-document actions
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_CREATE_ASSIGNMENT_START:
      return { ...state }
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_CREATE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete index-document actions
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_DELETE_ASSIGNMENT_START:
      return { ...state }
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_DELETE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update index-document actions
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_UPDATE_ASSIGNMENT_START:
      return { ...state }
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case IndexDocumentAssignmentActions.INDEX_DOCUMENT_UPDATE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, indexDocumentAssignments: undefined, indexDocumentAssignment: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer