import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as SortUtils from 'utils/SortUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CreateFilterArgumentDialog from 'components/dialogs/create_filter_argument_dialog/CreateFilterArgumentDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyFilterArgumentDialog from 'components/dialogs/modify_filter_argument_dialog/ModifyFilterArgumentDialog'
import VerifyFilterArgumentDialog from 'components/dialogs/verify_filter_argument_dialog/VerifyFilterArgumentDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as FilterArgumentActions from 'redux/actions/FilterArgumentActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultFilterArgument extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired
  }

  state = {
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showVerifyDialog: false,
    showDeleteDialog: false,
    showTableSettingsDialog: false,
    header: this.getHeaderInformation()
  }

  getHeaderInformation() {
    return [
      { rest: FILTERARGUMENT_ID, translation: 'definition.filter_argument_id', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CUSER },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description Handles the modal dialogs for create, modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { getFilterArgument, filterArguments } = this.props
    const name = filterArguments.data[index][this.headerData('SLF')]
    getFilterArgument(name, () => this.setState({ [dialog]: true }))
  }

  /**
   * @description Gets the verify information and shows the dialog
   * @param {Number} index Index of the data array
   */
  handleVerify = index => {
    const { filterArguments, getFilterArgument, verifyFilterArgument } = this.props
    const name = filterArguments.data[index][this.headerData('SLF')]
    getFilterArgument(name, () => {
      verifyFilterArgument(name, () => this.setState({ showVerifyDialog: true }))
    })
  }

  /**
   * @description Deletes the selected filter argument.
   */
  deleteFilterArgument = () => {
    const { deleteFilterArgument, filterArgument } = this.props
    deleteFilterArgument(filterArgument.SLF, () => this.setState({ showDeleteDialog: false }))
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  handleRefresh = () => {
    const { getFilterArguments, preferences } = this.props
    const filterArgumentID = preferences[Preferences.DEFINITION_FILTERARGUMENT_ID]
    const owner = preferences[Preferences.DEFINITION_FILTERARGUMENT_OWNER]
    const title = preferences[Preferences.DEFINITION_FILTERARGUMENT_TITLE]
    getFilterArguments(owner, filterArgumentID, title)
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.showPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id, lang } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit', lang)}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete', lang)}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options', lang)}>
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit', lang)}
          title={translate('general.edit', lang)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy', lang)}
          title={translate('general.copy', lang)}
        />
        <TableButtonGroupItem
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete', lang)}
          title={translate('general.delete', lang)}
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          id={`${id}_verifyBtn`}
          icon={<Icon name='verify' className='actionIcon' />}
          text={translate('general.verify', lang)}
          title={translate('general.verify', lang)}
          onClick={() => this.handleVerify(rowIndex)}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description gets the index of the header in redux state filterArguments.header
   * @param {String} header header name of the header in redux state filterArguments.header
   */
  headerData = header => this.props.filterArguments.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, filterArguments } = this.props
    const data = []
    const headers = this.getUsedHeader()
    filterArguments.data.forEach(el => {
      const dataBuffer = []
      headers.forEach(header => {
        if (header === FILTERARGUMENT_ID) {
          dataBuffer.push(el[this.headerData('SLF')])
        }
        else if (header === OWNER) {
          dataBuffer.push(el[this.headerData('OWNER')])
        }
        else if (header === TITLE) {
          dataBuffer.push(el[this.headerData('SLFTITLE')])
        }
        else if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })

    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_FILTERARGUMENT]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_FILTERARGUMENT].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_FILTERARGUMENT]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_FILTERARGUMENT].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  showPrefilledCreateDialog = () => {
    const { preferences } = this.props

    this.setState({
      showCreateDialog: true,
      createDialogData: {
        SLF: preferences[Preferences.DEFINITION_FILTERARGUMENT_ID],
        OWNER: preferences[Preferences.DEFINITION_FILTERARGUMENT_OWNER],
        SLFTITLE: preferences[Preferences.DEFINITION_FILTERARGUMENT_TITLE]} })
  }

  render = () => {
    const { filterArguments, filterArgument, id, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showDeleteDialog, showCopyDialog, showModifyDialog, showVerifyDialog } = this.state
    const data = filterArguments && filterArguments.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {showCreateDialog && (
          <CreateFilterArgumentDialog
            id={`${id}_createdialog`}
            copyFilterArgument={false}
            prefilledData={this.state.createDialogData}
            onClose={() => this.setState({ showCreateDialog: false, createDialogData: undefined })}
          />
        )}
        {showModifyDialog && (
          <ModifyFilterArgumentDialog
            id={`${id}_modifydialog`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        )}
        {showCopyDialog && (
          <CreateFilterArgumentDialog
            id={`${id}_copydialog`}
            copyFilterArgument={true}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        )}
        {showVerifyDialog && (
          <VerifyFilterArgumentDialog
            id={`${id}_verifydialog`}
            onClose={() => this.setState({ showVerifyDialog: false })}
          />
        )}
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_deletedialog`}
            title={translate('definition.delete_filter_argument')}
            question={translate('definition.question_delete_filter_argument')}
            onClose={() => { this.setState({ showDeleteDialog: false }) }}
            onDelete={() => { this.deleteFilterArgument() }}>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_filter_argument_id_key_text`}>
                  {translate('definition.filter_argument_id')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_filter_argument_id_value_text`}>
                  {filterArgument.SLF}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_filter_argument_title_key_text`}>
                  {translate('general.title')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_filter_argument_title_value_text`}>
                  {filterArgument.SLFTITLE}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_FILTERARGUMENT }}
          />
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {filterArguments
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={rowIndex => this.handleDialog(rowIndex, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_filter_argument_result')}
                    link={translate('emptyresult.create_filter_argument_link')}
                    onClick={() => this.showPrefilledCreateDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const FILTERARGUMENT_ID = 'SLF'
const TITLE = 'SLFTITLE'
const OWNER = 'OWNER'
const CUSER = 'CUSER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const LASTCHANGED = 'LASTCHANGED'

const mapStateToProps = state => {
  return {
    filterArguments: state.definitions.filterarguments.filterArguments,
    filterArgument: state.definitions.filterarguments.filterArgument,
    keepPagination: state.definitions.filterarguments.keepPagination,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getFilterArgument: (filterArgument, callback) => {
      FilterArgumentActions.getFilterArgument(filterArgument, callback)(dispatch)
    },
    deleteFilterArgument: (filterArgument, callback) => {
      FilterArgumentActions.deleteFilterArgument(filterArgument, callback)(dispatch)
    },
    verifyFilterArgument: (filterArgument, callback) => {
      FilterArgumentActions.verifyFilterArgument(filterArgument, callback)(dispatch)
    },
    getFilterArguments: (owner, filterArgument, title, callback) => {
      FilterArgumentActions.getFilterArguments(undefined, owner, filterArgument, title, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultFilterArgument)