import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button, Card, Column,
  Input,
  Row, Switch
} from 'BetaUX2Web-Components/src/'
import TimeCard from 'components/time_card/TimeCard'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'


import { translate } from 'language/Language'
import { getJobgroups } from 'redux/actions/ModalSelectorActions'
import { changePrefs } from 'redux/actions/PreferencesActions'
import { getControlMJobs } from 'redux/actions/SearchControlMActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as Utils from 'utils/Utils'
import { validateTimecardErrors } from '../SearchUtils'

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.hours' },
  { key: UNIT_DAYS, translationKey: 'general.days' },
]

const ORDER = 'ORDER'
const START = 'START'
const END = 'END'

const DISPLAY_JOBS_BY_ITEMS = [
  { key: ORDER, translationKey: 'general.order' },
  { key: START, translationKey: 'general.start' },
  { key: END, translationKey: 'general.end' }
]

const FILTER_BY_JOB_ERRORS_ALL = ''
const FILTER_BY_JOB_ERRORS_SUCCESS = 'NO'
const FILTER_BY_JOB_ERRORS_ERROR = 'YES'

const ONLY_JOB_ERRORS_ITEMS = [
  { key: FILTER_BY_JOB_ERRORS_ALL, translationKey: 'general.all' },
  { key: FILTER_BY_JOB_ERRORS_SUCCESS, translationKey: 'general.successful' },
  { key: FILTER_BY_JOB_ERRORS_ERROR, translationKey: 'general.error' }
]

const LOG_SOURCE_READER_ALL = 'CTM'
const LOG_SOURCE_READER_MIGRATION = 'CTMMIG'

const LOG_SOURCE_READER_ITEMS = [
  { key: LOG_SOURCE_READER_ALL, translationKey: 'general.all' },
  { key: LOG_SOURCE_READER_MIGRATION, translationKey: 'general.migration' }
]

const SearchControlMSearch = ({ id }) => {
  const selector = useSelector(store => store.selector)
  const datemask = useSelector(store => store.auth.serverdata.preferences[Preferences.DATEMASK])
  const preferences = useSelector(store => store.auth.serverdata.preferences)
  const dispatch = useDispatch()

  const defaultState = {
    time: {
      activeTimeTabIndex: 0,
      type: 0,
      period: 1,
      unit: 2,
      startDate: {
        value: '',
        error: ''
      },
      startTime: {
        value: '',
        error: ''
      },
      endDate: {
        value: '',
        error: ''
      },
      endTime: {
        value: '',
        error: ''
      }
    },
    displayJobsBy: 0,
    jobgroup: '',
    jobname: '',
    datacenter: '',
    application: '',
    subapplication: '',
    agent: '',
    runAs: '',
    orderID: '',
    runCount: '',
    onlyJobErrors: 0,
    logSourceReader: 0,
    returncodeSwitch: 0,
    returncodeInput: '',
    runtimeSwitch: 0,
    runtimeInput: '',
    showJobgroupsDialog: false
  }

  const initializeState = () => {
    if (preferences) {
      let activeTimeTabIndex = Utils.convertStringToInt(preferences[Preferences.SEARCH_CONTROLM_ACTIVE_TAB]) || 0
      let type = Utils.convertStringToInt(preferences[Preferences.SEARCH_CONTROLM_LASTTIME_MODE]) || 0
      let period = Utils.convertStringToInt(preferences[Preferences.SEARCH_CONTROLM_TIME_CUSTOM_LAST]) || 1
      let startDate = activeTimeTabIndex === 1 && preferences[Preferences.SEARCH_CONTROLM_START_DATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SEARCH_CONTROLM_START_DATE])
        : ''
      let startTime = preferences[Preferences.SEARCH_CONTROLM_START_TIME] || ''
      startTime = Utils.convertStringToInt(startTime)
      let endDate = preferences[Preferences.SEARCH_CONTROLM_END_DATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SEARCH_CONTROLM_END_DATE])
        : ''
      let endTime = preferences[Preferences.SEARCH_CONTROLM_END_TIME] || ''
      endTime = Utils.convertStringToInt(endTime)
      const resultIndexUnit = Math.max(UNITS.findIndex(d => d.key === preferences[Preferences.SEARCH_CONTROLM_TIME_CUSTOM_UNIT]), 0)
      const unit = resultIndexUnit !== -1 ? resultIndexUnit : 2
      const displayJobsBy = Math.max(DISPLAY_JOBS_BY_ITEMS.findIndex(d => d.key === preferences[Preferences.SEARCH_CONTROLM_USAGE]), 0)
      const jobname = preferences[Preferences.SEARCH_CONTROLM_JOBNAME] || ''
      const jobgroup = preferences[Preferences.SEARCH_CONTROLM_JOBGROUP] || ''
      const datacenter = preferences[Preferences.SEARCH_CONTROLM_DATACENTER] || ''
      const application = preferences[Preferences.SEARCH_CONTROLM_APPLICATION] || ''
      const subapplication = preferences[Preferences.SEARCH_CONTROLM_SUBAPPLICATION] || ''
      const agent = preferences[Preferences.SEARCH_CONTROLM_AGENT] || ''
      const runAs = preferences[Preferences.SEARCH_CONTROLM_RUNAS] || ''
      const folder = preferences[Preferences.SEARCH_CONTROLM_FOLDER] || ''
      const jobType = preferences[Preferences.SEARCH_CONTROLM_JOBTYPE] || ''
      const orderID = preferences[Preferences.SEARCH_CONTROLM_ORDERID] || ''
      const runCount = preferences[Preferences.SEARCH_CONTROLM_RUNCOUNT] || ''
      const onlyJobErrors = Math.max(ONLY_JOB_ERRORS_ITEMS.findIndex(d => d.key === preferences[Preferences.SEARCH_CONTROLM_ONLY_JOB_ERRORS]), 0)
      const logSourceReader = Math.max(LOG_SOURCE_READER_ITEMS.findIndex(d => d.key === preferences[Preferences.SEARCH_CONTROLM_LOGSOURCE]), 0)
      const returncodeSwitch = { '<': 0, '>': 1, '=': 2, '#': 3 }[preferences[Preferences.SEARCH_CONTROLM_RETURNCODE_SWITCH]] || 0
      const returncodeInput = preferences[Preferences.SEARCH_CONTROLM_RETURNCODE_INPUT] || ''
      const runtimeSwitch = { '<': 0, '>': 1, '=': 2, '#': 3 }[preferences[Preferences.SEARCH_CONTROLM_RUNTIME_SWITCH]] || 0
      const runtimeInput = preferences[Preferences.SEARCH_CONTROLM_RUNTIME_INPUT] || ''

      return {
        time: {
          activeTimeTabIndex,
          type,
          period,
          unit,
          startDate: {
            value: startDate,
            error: ''
          },
          startTime: {
            value: startTime,
            error: ''
          },
          endDate: {
            value: endDate,
            error: ''
          },
          endTime: {
            value: endTime,
            error: ''
          }
        },
        displayJobsBy,
        jobgroup,
        jobname,
        datacenter,
        application,
        subapplication,
        agent,
        runAs,
        folder,
        jobType,
        orderID,
        runCount,
        onlyJobErrors,
        logSourceReader,
        returncodeSwitch,
        returncodeInput,
        runtimeSwitch,
        runtimeInput
      }
    }
    return defaultState
  }

  const [state, setState] = useState({
    ...initializeState()
  })

  const submitSearch = event => {
    const { time, logSourceReader, displayJobsBy, onlyJobErrors, jobgroup, jobname, datacenter, application, subapplication, agent, runAs, folder, jobType, orderID, runCount, returncodeSwitch, returncodeInput, runtimeInput, runtimeSwitch } = state
    event.preventDefault()
    const errors = validateTimecardErrors(time, datemask);
    setState({ ...state, time: { ...state.time, ...errors } })
    if (Object.keys(errors).length === 0) {
      const prefsToChange = {
        [Preferences.SEARCH_CONTROLM_ACTIVE_TAB]: time.activeTimeTabIndex,
        [Preferences.SEARCH_CONTROLM_LASTTIME_MODE]: time.type,
        [Preferences.SEARCH_CONTROLM_TIME_CUSTOM_LAST]: time.period,
        [Preferences.SEARCH_CONTROLM_TIME_CUSTOM_UNIT]: UNITS[time.unit].key,
        [Preferences.SEARCH_CONTROLM_START_DATE]: DateUtils.getRequestFormat(time.startDate.value, datemask),
        [Preferences.SEARCH_CONTROLM_START_TIME]: DateUtils.formatTimeToDefault(time.startTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_CONTROLM_END_DATE]: DateUtils.getRequestFormat(time.endDate.value, datemask),
        [Preferences.SEARCH_CONTROLM_END_TIME]: DateUtils.formatTimeToDefault(time.endTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_CONTROLM_LOGSOURCE]: LOG_SOURCE_READER_ITEMS[logSourceReader].key,
        [Preferences.SEARCH_CONTROLM_USAGE]: DISPLAY_JOBS_BY_ITEMS[displayJobsBy].key,
        [Preferences.SEARCH_CONTROLM_ONLY_JOB_ERRORS]: ONLY_JOB_ERRORS_ITEMS[onlyJobErrors].key,
        [Preferences.SEARCH_CONTROLM_JOBGROUP]: jobgroup,
        [Preferences.SEARCH_CONTROLM_JOBNAME]: jobname,
        [Preferences.SEARCH_CONTROLM_DATACENTER]: datacenter,
        [Preferences.SEARCH_CONTROLM_APPLICATION]: application,
        [Preferences.SEARCH_CONTROLM_SUBAPPLICATION]: subapplication,
        [Preferences.SEARCH_CONTROLM_AGENT]: agent,
        [Preferences.SEARCH_CONTROLM_RUNAS]: runAs,
        [Preferences.SEARCH_CONTROLM_FOLDER]: folder,
        [Preferences.SEARCH_CONTROLM_JOBTYPE]: jobType,
        [Preferences.SEARCH_CONTROLM_ORDERID]: orderID,
        [Preferences.SEARCH_CONTROLM_RUNCOUNT]: runCount,
        [Preferences.SEARCH_CONTROLM_RETURNCODE_SWITCH]: { 0: '<', 1: '>', 2: '=', 3: '#' }[returncodeSwitch],
        [Preferences.SEARCH_CONTROLM_RETURNCODE_INPUT]: returncodeInput,
        [Preferences.SEARCH_CONTROLM_RUNTIME_SWITCH]: { 0: '<', 1: '>', 2: '=', 3: '#' }[runtimeSwitch],
        [Preferences.SEARCH_CONTROLM_RUNTIME_INPUT]: runtimeInput
      }

      const searchParams = {
        'LOGSOURCE': LOG_SOURCE_READER_ITEMS[logSourceReader].key,
        'TUSAGE': DISPLAY_JOBS_BY_ITEMS[displayJobsBy].key,
        'JOBERR': ONLY_JOB_ERRORS_ITEMS[onlyJobErrors].key,
        'JOBGROUP': jobgroup,
        'LJOBNAME': jobname,
        'DATACENTER': datacenter,
        'APPLICATION': application,
        'SUBAPPLICATION': subapplication,
        'AGENTNAME': agent,
        'RUNUSER': runAs,
        'FOLDER': folder,
        'JOBTYPE': jobType,
        'ORDERID': orderID,
        'RUNCOUNT': runCount
      }

      if (time.activeTimeTabIndex === 0) {
        if (time.type === 0) {
          searchParams['SDATE'] = 'TODAY'
          prefsToChange[Preferences.SEARCH_CONTROLM_START_DATE] = 'TODAY'
        }
        else if (time.type === 1) {
          searchParams['SDATE'] = 'YESTERDAY'
          prefsToChange[Preferences.SEARCH_CONTROLM_START_DATE] = 'YESTERDAY'
        }
        else if (time.type === 2) {
          searchParams['FROMLAST'] = time.period
          searchParams['TUNITS'] = DateUtils.UNITS[time.unit].key
        }
      }
      else if (time.activeTimeTabIndex === 1) {
        searchParams['SDATE'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.startTime.value !== '') {
          searchParams['STIME'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.TIME_DATEMASK)
        }
        searchParams['EDATE'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.endTime.value !== '') {
          searchParams['ETIME'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.TIME_DATEMASK)
        }
      }

      if (returncodeInput !== '') {
        searchParams['JOBRC'] = `${{ 0: '<', 1: '>', 2: '=', 3: '#' }[returncodeSwitch]}${returncodeInput}`
      }
      if (runtimeInput !== '') {
        searchParams['RUNTIME'] = `${{ 0: '<', 1: '>', 2: '=', 3: '#' }[runtimeSwitch]}${runtimeInput}`
      }
      changePrefs(prefsToChange)(dispatch)
      getControlMJobs(undefined, searchParams)(dispatch)
    }
  }

  return (
    <>
      {
        state.showJobgroupsDialog &&
        <SelectorDialog
          id={`${id}_jobgroups_selector_dialog`}
          onClose={() => setState({ ...state, showJobgroupsDialog: false })}
          title={translate('definition.jobgroups')}
          header={[translate('general.jobgroup_name'), translate('general.title')]}
          items={selector.jobgroups.data}
          onSelect={selectedRows => {
            if (selectedRows.length > 0) {
              const newJobgroup = selector.jobgroups.data[selectedRows][selector.jobgroups.header.indexOf('JGIGNAME')]
              setState({ ...state, jobgroup: newJobgroup, showJobgroupsDialog: false })
            }
          }}
        />
      }
      <form
        id={id}
        className={'bux_drawer_form'}
        onSubmit={submitSearch}>
        <Main id={id} state={state} setState={setState} />
        <Footer id={id} search={submitSearch} reset={() => setState(defaultState)} />
      </form>
    </>
  )
}

const Main = ({ id, state, setState }) => {
  const lang = useSelector(store => store.auth.serverdata.preferences[Preferences.LANGUAGE])
  const datemask = useSelector(store => store.auth.serverdata.preferences[Preferences.DATEMASK])

  /**
   * @description Handles the changes in timecard.
   * @param {String} key The key of the input in state.
   * @param {String} val The new value of the input.
   * @param {String} err The new error of the input.
   */
  const handleTimeCardChange = (key, val, err) => {
    setState(state => ({
      ...state,
      time: {
        ...state.time,
        [key]: typeof state.time[key] === 'object'
          ? {
            value: val,
            error: err || ''
          }
          : val
      }
    }))
  }

  /**
   * @description Returns the values for the timecard.
   * @returns {Object} The timecard values.
   */
  const timeCardValues = () => {
    const { time } = state
    return {
      tabIndex: time.activeTimeTabIndex,
      lastTimeModeIndex: time.type,
      customLast: time.period,
      customUnitIndex: time.unit,
      fromDate: {
        value: time.startDate.value,
        error: time.startDate.error
      },
      fromTime: {
        value: time.startTime.value,
        error: time.startTime.error
      },
      toDate: {
        value: time.endDate.value,
        error: time.endDate.error
      },
      toTime: {
        value: time.endTime.value,
        error: time.endTime.error
      }
    }
  }

  /**
   * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
   * Needed for update function inside the TimeCard.
   * @returns {Object} The object with the state keys.
   */
  const timeCardStateKeys = () => {
    return {
      tabIndex: 'activeTimeTabIndex',
      lastTimeModeIndex: 'type',
      customLast: 'period',
      customUnitIndex: 'unit',
      fromDate: 'startDate',
      fromTime: 'startTime',
      toDate: 'endDate',
      toTime: 'endTime',
    }
  }

  return (
    <div
      id={`${id}_main`}
      className={'bux_drawer_main'}>
      <TimeCard
        id={id}
        lang={lang}
        datemask={datemask}
        values={timeCardValues()}
        stateKeys={timeCardStateKeys()}
        onValuesChange={handleTimeCardChange}
        parentContainer={'drawer_content_search_body_main'}
        translate={key => translate(key)}
      />
      <SelectBy id={id} state={state} setState={setState} />
      <Jobdata id={id} state={state} setState={setState} />
      <Additional id={id} state={state} setState={setState} />
    </div>
  )
}

const SelectBy = ({ id, state, setState }) => {
  return (
    <Card id={`${id}_select_by`} title={translate('search.select_by')}>
      <Row>
        <Column colMD={12}>
          <Switch
            id={`${id}_display_jobs_by`}
            title={translate('general.date_field')}
            items={DISPLAY_JOBS_BY_ITEMS.map(d => translate(d.translationKey))}
            activeIndex={state.displayJobsBy}
            onClick={index => setState({ ...state, displayJobsBy: index })}
            maxPerRow={3}
          />
        </Column>
      </Row>
    </Card>
  )
}

const Jobdata = ({ id, state, setState }) => {

  const dispatch = useDispatch()
  const handleJobgroupSelector = () => {
    getJobgroups(
      ['JGIGNAME', 'JGITITLE'],
      { JGIGNAME: state.jobgroup },
      () => setState({ ...state, showJobgroupsDialog: true })
    )(dispatch)
  }

  return (
    <Card id={`${id}_job_data`} title={translate('general.job_data')}>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_jobgroup`}
            onInputChanged={value => setState({ ...state, jobgroup: value })}
            value={state.jobgroup}
            title={translate('general.jobgroup')}
            maxLength={80}
            addon={{
              iconName: 'list',
              onClick: () => handleJobgroupSelector()
            }}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_jobname`}
            onInputChanged={val => setState({ ...state, jobname: val })}
            value={state.jobname}
            title={translate('general.jobname')}
            maxLength={64}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_datacenter`}
            onInputChanged={val => setState({ ...state, datacenter: val })}
            value={state.datacenter}
            title={translate('general.datacenter')}
            maxLength={20}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_application`}
            onInputChanged={val => setState({ ...state, application: val })}
            value={state.application}
            title={translate('definition.application')}
            maxLength={200}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}sub_application`}
            onInputChanged={val => setState({ ...state, subapplication: val })}
            value={state.subapplication}
            title={translate('general.sub_application')}
            maxLength={64}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_agent`}
            onInputChanged={val => setState({ ...state, agent: val })}
            value={state.agent}
            title={translate('general.agent')}
            maxLength={85}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_runas`}
            onInputChanged={val => setState({ ...state, runAs: val })}
            value={state.runAs}
            title={translate('general.run_as')}
            maxLength={30}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_folder`}
            onInputChanged={val => setState({ ...state, folder: val })}
            value={state.folder}
            title={translate('general.folder')}
            maxLength={64}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Input
            id={`${id}_job_type`}
            onInputChanged={val => setState({ ...state, jobType: val })}
            value={state.jobType}
            title={translate('general.job_type')}
            maxLength={16}
          />
        </Column>
      </Row>

      <Row>
        <Column colMD={6}>
          <Input
            id={`${id}_orderid`}
            onInputChanged={val => setState({ ...state, orderID: val })}
            value={state.orderID}
            title={translate('general.order_id')}
            maxLength={8}
          />
        </Column>
        <Column colMD={6}>
          <Input
            id={`${id}_runcount`}
            onInputChanged={val => setState({ ...state, runCount: val })}
            value={state.runCount}
            title={translate('general.run_count')}
            maxLength={5}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={12}>
          <Switch
            id={`${id}_only_job_errors`}
            title={translate('job.filter_by_job_errors')}
            maxPerRow={3}
            items={ONLY_JOB_ERRORS_ITEMS.map(el => translate(el.translationKey))}
            onClick={index => setState({ ...state, onlyJobErrors: index })}
            activeIndex={state.onlyJobErrors}
          />
        </Column>
      </Row>
    </Card>
  )
}

const Additional = ({ id, state, setState }) => {

  const changeNumeric = (key, val) => {
    if (val === '' || val.match(/^[0-9]*$/)) {
      setState({ ...state, [key]: val })
    }
  }

  return (
    <Card id={`${id}_additional`} title={translate('general.additional')}>
      <Row>
        <Column colMD={12}>
          <Switch
            id={`${id}_log_source_reader`}
            title={translate('general.log_source_reader')}
            maxPerRow={3}
            items={LOG_SOURCE_READER_ITEMS.map(el => translate(el.translationKey))}
            onClick={index => setState({ ...state, logSourceReader: index })}
            activeIndex={state.logSourceReader}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={6}>
          <Switch
            id={`${id}_return_code`}
            title={translate('general.return_code')}
            activeIndex={state.returncodeSwitch}
            onClick={index => setState({ ...state, returncodeSwitch: index })}
            items={['<', '>', '=', '!=']}
            maxPerRow={4}
          />
        </Column>
        <Column colMD={6}>
          <Input
            id={`${id}_return_code`}
            title={' '}
            value={state.returncodeInput}
            onInputChanged={value => changeNumeric('returncodeInput', value)}
            maxLength={5}
          />
        </Column>
      </Row>
      <Row>
        <Column colMD={6}>
          <Switch
            id={`${id}_runtime`}
            title={translate('general.runtime')}
            activeIndex={state.runtimeSwitch}
            onClick={index => setState({ ...state, runtimeSwitch: index })}
            items={['<', '>', '=', '!=']}
            maxPerRow={4}
          />
        </Column>
        <Column colMD={6}>
          <Input
            id={`${id}_runtime`}
            title={' '}
            value={state.runtimeInput}
            onInputChanged={value => changeNumeric('runtimeInput', value)}
            maxLength={5}
          />
        </Column>
      </Row>
    </Card>
  )
}

const Footer = ({ id, search, reset }) => {
  return (
    <div id={`${id}_footer`} className='bux_drawer_footer'>
      <Button
        id={`${id}_search`}
        text={translate('general.search')}
        onClick={search}
        submit
        primary
      />
      <Button
        id={`${id}_resetBtn`}
        icon='undo'
        iconType='material'
        onClick={reset}
      />
    </div>
  )
}

export default SearchControlMSearch