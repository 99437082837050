import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import { Component } from 'react'

import {
  Button,
  Column, Dropdown, Icon, Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row, Switch, Tab,
  Tabs, TitledLabel
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import { connect } from 'react-redux'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as StandardSelectionActions from 'redux/actions/StandardSelectionActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as SelectionUtils from 'utils/SelectionUtils'
const { GENERAL, STATUS, RETENTION } = StandardSelectionActions

class DocumentInformation extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  state = {
    activeTabIndex: 0,
    requests: [],
    generalTab: {
      title: '',
      docUser1: '',
      docUser2: '',
      docUser3: '',
      docUser4: '',
      docUser5: '',
      docUser6: '',
      docUser7: '',
      docUser8: '',
      saveUtf: false,
      textenco: 0,
      encoding: 0,
      owner: ''
    },
    statusTab: {
      editingIcon: 0,
      annotation: '',
      controlStatus: 0,
      controlUser: '',
    },
    retentionTab: {
      onlineRetention: 0,
      onlineRetentionSelection: 0,
      archiveRetention: 0,
      archiveRetentionSelection: 0,
      archiveNotes: false
    }
  }

  /**
   * @description Initializes the values.
   */
  componentDidMount = () => {
    this.setDefaultValues()
  }

  /**
   * @description Updates the values.
   */
  componentDidUpdate = (_, prevState) => {
    const { statusTab } = this.state
    if (prevState.statusTab.controlStatus !== statusTab.controlStatus && statusTab.controlStatus !== 2) {
      this.resetValue('statusTab', 'controlUser', '')
    }
  }

  isEBCIDIC = () => {
    return !this.props.document.LGRASCII
  }

  /**
   * @description Sets the default values.
   */
  setDefaultValues = () => {
    const { document } = this.props
    const textencoKey = (document.TEXTENCO && document.TEXTENCO !== 'NONE')
      ? SelectionUtils.TEXTENCO.find(d => d.key === document.TEXTENCO)?.key
      : undefined
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        title: document.LTITLE,
        docUser1: document.DOCUSR1,
        docUser2: document.DOCUSR2,
        docUser3: document.DOCUSR3,
        docUser4: document.DOCUSR4,
        docUser5: document.DOCUSR5,
        docUser6: document.DOCUSR6,
        docUser7: document.DOCUSR7,
        docUser8: document.DOCUSR8,
        owner: document.OWNER,
        textenco: textencoKey ? this.getTextencoItems().indexOf(textencoKey) : 0,
        // Just used for saving, not possible to load.
        saveUtf: false
      },
      statusTab: {
        ...this.state.statusTab,
        editingIcon: Math.max(SelectionUtils.EDITINGICONS.findIndex(d => d.key === document.EDTSTAT), 0),
        annotation: document.EDTTEXT,
        controlStatus: Math.max(SelectionUtils.CONTROLSTATI.findIndex(d => d.key === document.STATUS), 0),
        controlUser: document.LGRCUSR
      },
      retentionTab: {
        ...this.state.retentionTab,
        onlineRetention: document.ONLRETPD,
        onlineRetentionSelection: Math.max(SelectionUtils.ONLINERETENTIONS.findIndex(d => d.key === document.CTYPE), 0),
        archiveRetention: document.ARCRETPD,
        archiveRetentionSelection: Math.max(SelectionUtils.ARCHIVERETENTIONS.findIndex(d => d.key === document.ARCHMED), 0),
        archiveNotes: document.ARCHNOTE
      }
    })
  }

  /**
   * @description Handles changes of a field.
   * @param {String} tab The current tab.
   * @param {String} key The key of the field.
   * @param {*} value The new value.
   */
  handleChange = (tab, key, value) => {
    this.setState({
      [tab]: {
        ...this.state[tab],
        [key]: value
      }
    })
  }

  /**
   * @description Resets the value of a field.
   * @param {String} tab The current tab.
   * @param {String} key The key of the field.
   */
  resetValue = (tab, key, val = null) => {
    this.setState({
      [tab]: {
        ...this.state[tab],
        [key]: val
      }
    })
  }

  /**
   * @description Handles the changes of tabindex.
   * @param {Number} index The new tabindex.
   */
  handleTabIndex = index => {
    if (index !== this.state.activeTabIndex)
      this.setState({ activeTabIndex: index })
  }

  /**
   * @description Saves the values in multiple requests. One request for each tab.
   */
  handleSave = () => {
    const { generalTab, retentionTab, statusTab } = this.state
    const { document, refreshFunction } = this.props
    const documentDefinitionGeneralTab = {
      DOCID: document.DOCID,
      LTITLE: generalTab.title,
      DOCUSR1: generalTab.docUser1,
      DOCUSR2: generalTab.docUser2,
      DOCUSR3: generalTab.docUser3,
      DOCUSR4: generalTab.docUser4,
      DOCUSR5: generalTab.docUser5,
      DOCUSR6: generalTab.docUser6,
      DOCUSR7: generalTab.docUser7,
      DOCUSR8: generalTab.docUser8,
      TEXTENCO: this.isEBCIDIC() ? SelectionUtils.TEXTENCO_EBCIDIC[generalTab.textenco].key : SelectionUtils.TEXTENCO[generalTab.textenco].key,
      ENCODING: generalTab.saveUtf,
      OWNER: generalTab.owner,
      FORM: document.FORM,
      EXT: document.EXT,
      REPORT: document.WREPORT
    }

    const documentDefinitionStatusTab = {
      DOCID: document.DOCID,
      EDTSTAT: SelectionUtils.EDITINGICONS[statusTab.editingIcon].key,
      EDTTEXT: statusTab.annotation,
      STATUS: SelectionUtils.CONTROLSTATI[statusTab.controlStatus].key,
      LGRCUSR: statusTab.controlUser,
      FORM: document.FORM,
      EXT: document.EXT,
      REPORT: document.WREPORT
    }

    const documentDefinitionRetentionTab = {
      DOCID: document.DOCID,
      ONLRETPD: retentionTab.onlineRetention,
      ONLCTYPE: SelectionUtils.ONLINERETENTIONS[retentionTab.onlineRetentionSelection].key,
      ARCRETPD: retentionTab.archiveRetention,
      ARCHMED: SelectionUtils.ARCHIVERETENTIONS[retentionTab.archiveRetentionSelection].key,
      ARCHNOTE: retentionTab.archiveNotes,
      FORM: document.FORM,
      EXT: document.EXT,
      REPORT: document.WREPORT
    }

    const callback = tab => {
      this.setState({
        requests: this.state.requests.filter(d => d !== tab && d !== false)
      }, () => {
        if (this.state.requests.length === 0) {
          this.props.onClose()
        }
      }
      )
    }
    // just sets state when data has changed
    this.setState({
      requests: [
        (!this.compareStateWithRedux(documentDefinitionGeneralTab, this.getGeneralTabRedux())) && GENERAL,
        (!this.compareStateWithRedux(documentDefinitionRetentionTab, this.getRetentionTabRedux())) && RETENTION,
        (!this.compareStateWithRedux(documentDefinitionStatusTab, this.getStatusTabRedux())) && STATUS,
      ]
    }, () => {
      if (this.state.requests.includes(GENERAL)) {
        this.props.updateDocument(documentDefinitionGeneralTab, GENERAL, () => callback(GENERAL), refreshFunction)
      }
      if (this.state.requests.includes(RETENTION)) {
        this.props.updateDocument(documentDefinitionRetentionTab, RETENTION, () => callback(RETENTION), refreshFunction)
      }
      if (this.state.requests.includes(STATUS)) {
        this.props.updateDocument(documentDefinitionStatusTab, STATUS, () => callback(STATUS), refreshFunction)
      }
      if (this.state.requests.filter(d => d !== false).length === 0) {
        this.props.onClose()
        // this.props.showSnackbar(translate('documents.update_documentinformation_success', lang, ]), SnackbarActions.TYPE_SUCCESS)
      }
    })
  }

  /**
   * @description Gets the general tab redux values.
   * @returns {Array} The values.
   */
  getGeneralTabRedux = () => {
    return {
      DOCID: this.props.opendocument['DOCID'],
      LTITLE: this.props.opendocument['LTITLE'],
      DOCUSR1: this.props.opendocument['DOCUSR1'],
      DOCUSR2: this.props.opendocument['DOCUSR2'],
      DOCUSR3: this.props.opendocument['DOCUSR3'],
      DOCUSR4: this.props.opendocument['DOCUSR4'],
      DOCUSR5: this.props.opendocument['DOCUSR5'],
      DOCUSR6: this.props.opendocument['DOCUSR6'],
      DOCUSR7: this.props.opendocument['DOCUSR7'],
      DOCUSR8: this.props.opendocument['DOCUSR8'],
      OWNER: this.props.opendocument['OWNER'],
      TEXTENCO: this.props.opendocument['TEXTENCO'],
      ENCODING: false, // Default, value is not loaded (see also setDefaultValues)
      FORM: this.props.opendocument['FORM'],
      EXT: this.props.opendocument['EXT'],
      REPORT: this.props.opendocument['WREPORT'],
    }
  }

  /**
   * @description Gets the status tab redux values.
   * @returns {Array} The values.
   */
  getStatusTabRedux = () => {
    return {
      DOCID: this.props.opendocument['DOCID'],
      EDTSTAT: this.props.opendocument['EDTSTAT'],
      EDTTEXT: this.props.opendocument['EDTTEXT'],
      STATUS: this.props.opendocument['STATUS'],
      LGRCUSR: this.props.opendocument['LGRCUSR'] ?? '',
      FORM: this.props.opendocument['FORM'],
      EXT: this.props.opendocument['EXT'],
      REPORT: this.props.opendocument['WREPORT'],
    }
  }

  /**
   * @description Gets the retention tab redux values.
   * @returns {Array} The values.
   */
  getRetentionTabRedux = () => {
    return {
      DOCID: this.props.opendocument['DOCID'],
      ONLRETPD: this.props.opendocument['ONLRETPD'],
      ONLCTYPE: this.props.opendocument['CTYPE'],
      ARCRETPD: this.props.opendocument['ARCRETPD'],
      ARCHMED: this.props.opendocument['ARCHMED'],
      ARCHNOTE: this.props.opendocument['ARCHNOTE'],
      FORM: this.props.opendocument['FORM'],
      EXT: this.props.opendocument['EXT'],
      REPORT: this.props.opendocument['WREPORT'],
    }
  }

  /**
   * @description Compares the state values with the redux values.
   * @param {Object} state The local state of the component
   * @param {Array} redux The redux state of the opened document
   */
  compareStateWithRedux = (localState, reduxState) => {
    for (const [localStateKey, localStateValue] of Object.entries(localState)) {
      if (reduxState[localStateKey] !== localStateValue) {
        return false
      }
    }

    return true
  }

  /**
   * @description Gets the text encoding items.
   * @returns {Array} The text encoding items.
   */
  getTextencoItems = () => {
    const items = []
    const encodingsToUse = this.isEBCIDIC() ? SelectionUtils.TEXTENCO_EBCIDIC : SelectionUtils.TEXTENCO
    encodingsToUse.forEach(type => {
      items.push(type.key)
    })

    return items
  }

  /**
   * @description Gets the translated online retention items.
   * @returns {Array} The translated online retention items.
   */
  getOnlineRetentionItems = () => {
    const items = []

    SelectionUtils.ONLINERETENTIONS.forEach(type => {
      items.push(translate(type.translationKey))
    })

    return items
  }

  /**
   * @description Gets the translated archive retention items.
   * @returns {Array} The translated archive retention items.
   */
  getArchiveRetentionItems = () => {
    const items = []

    SelectionUtils.ARCHIVERETENTIONS.forEach(type => {
      items.push(translate(type.translationKey))
    })

    return items
  }

  /**
   * @description Renders the header.
   */
  renderHeader = () => {
    const { id, document, datemask } = this.props
    const baseid = `${id}_documentinformation_modal`

    return (
      <MetaDataGrid
        id={`${baseid}_header`}
        metaData={[
          { label: translate('general.form'), value: document.FORM },
          { label: translate('general.extension'), value: document.EXT },
          { label: translate('general.report'), value: document.WREPORT },
          { label: translate('documentinformation.header_dynamicreport'), value: document.REPORT },
          { label: translate('documentinformation.header_jobname'), value: document.SRCJOBN },
          { label: translate('documentinformation.header_doctype'), value: document.SRCJOBI },
          { label: translate('documentinformation.header_pages'), value: document.SRCPAGES },
          (document?.CHECKSUM) ? { label: translate('documentinformation.header_checksum'), value: document.CHECKSUM } : {},
          { label: translate('documentinformation.header_searchmode'), value: document.SMODE },
          { label: translate('documentinformation.header_documentid'), value: document.DOCID },
          { label: translate('documentinformation.header_encoding'), value: document.TEXTENCO },
          { label: translate('documentinformation.header_obtained'), value: document.SRCOBT },
          { label: translate('documentinformation.header_ebcdic'), value: !document.LGRASCII, yesNo: true},
          { label: translate('documentinformation.header_afp'), value: document.LGRAFP, yesNo: true},
          { label: translate('documentinformation.header_controlcharacters'), value: document.LGRPCC, yesNo: true},
          { label: translate('documentinformation.header_orginaldatetime'), value: DateUtils.getDate(datemask, document.B93DATE, document.B93TIME.substring(0, 8))},
          { label: translate('documentinformation.header_maxreordlength'), value: document.LGRMAXRL},
          { label: translate('documentinformation.header_blocks'), value: document.SPOOLBLK },
          { label: translate('documentinformation.header_tablereferencecharacter'), value: document.LGRTRC, yesNo: true },
          { label: translate('documentinformation.header_filename'), value: document.SRCDSN },
          { label: translate('general.bytes'), value: document.SRCBYTES },
        ]}
        columns={4}
        translate={translate}
      />
    )
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { id } = this.props
    const { generalTab } = this.state
    const baseid = `${id}_documentinformation_modal_main`

    return (
      <Tab
        onShow={() => this.handleTabIndex(0)}
        title={translate('documentinformation.generaltab')}>
        <Row>
          <Column
            colMD={12}>
            <Input
              id={`${baseid}_generaltab_title`}
              value={generalTab.title}
              title={translate('documentinformation.generaltab_title')}
              maxLength={80}
              onInputChanged={value => this.handleChange('generalTab', 'title', value)}
              autoFocus
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={6}>
            <Input
              id={`${baseid}_generaltab_docuser1_input`}
              value={generalTab.docUser1}
              title={translate('documentinformation.generaltab_docuser1')}
              maxLength={32}
              onInputChanged={value => this.handleChange('generalTab', 'docUser1', value)}
            />
          </Column>
          <Column
            colMD={6}>
            <Input
              id={`${baseid}_generaltab_docuser2_input`}
              value={generalTab.docUser2}
              title={translate('documentinformation.generaltab_docuser2')}
              maxLength={32}
              onInputChanged={value => this.handleChange('generalTab', 'docUser2', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={6}>
            <Input
              id={`${baseid}_generaltab_docuser3_input`}
              value={generalTab.docUser3}
              title={translate('documentinformation.generaltab_docuser3')}
              maxLength={32}
              onInputChanged={value => this.handleChange('generalTab', 'docUser3', value)}
            />
          </Column>
          <Column
            colMD={6}>
            <Input
              id={`${baseid}_generaltab_docuser4_input`}
              value={generalTab.docUser4}
              title={translate('documentinformation.generaltab_docuser4')}
              maxLength={32}
              onInputChanged={value => this.handleChange('generalTab', 'docUser4', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={6}>
            <Input
              id={`${baseid}_generaltab_docuser5_input`}
              value={generalTab.docUser5}
              title={translate('documentinformation.generaltab_docuser5')}
              maxLength={32}
              onInputChanged={value => this.handleChange('generalTab', 'docUser5', value)}
            />
          </Column>
          <Column
            colMD={6}>
            <Input
              id={`${baseid}_generaltab_docuser6_input`}
              value={generalTab.docUser6}
              title={translate('documentinformation.generaltab_docuser6')}
              maxLength={32}
              onInputChanged={value => this.handleChange('generalTab', 'docUser6', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={6}>
            <Input
              id={`${baseid}_generaltab_docuser7_input`}
              value={generalTab.docUser7}
              title={translate('documentinformation.generaltab_docuser7')}
              maxLength={32}
              onInputChanged={value => this.handleChange('generalTab', 'docUser7', value)}
            />
          </Column>
          <Column
            colMD={6}>
            <Input
              id={`${baseid}_generaltab_docuser8_input`}
              value={generalTab.docUser8}
              title={translate('documentinformation.generaltab_docuser8')}
              maxLength={32}
              onInputChanged={value => this.handleChange('generalTab', 'docUser8', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={6}>
            <Switch
              id={`${baseid}_generaltab_saveutf8`}
              title={translate('documentinformation.generaltab_saveutf')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('generalTab', 'saveUtf', index === 0)}
              activeIndex={generalTab.saveUtf ? 0 : 1}
            />
          </Column>
          <Column
            colMD={3}>
            <Dropdown
              id={`${baseid}_generaltab_encoding`}
              title={translate('documentinformation.header_encoding')}
              activeIndex={generalTab.textenco}
              onChange={index => this.handleChange('generalTab', 'textenco', index)}
              items={this.getTextencoItems()}
            />
          </Column>
          <Column
            colMD={3}>
            <Input
              id={`${baseid}_generaltab_owner_input`}
              value={generalTab.owner}
              title={translate('documentinformation.generaltab_owner')}
              maxLength={8}
              onInputChanged={value => this.handleChange('generalTab', 'owner', value)}
            />
          </Column>
        </Row>
      </Tab>
    )
  }

  /**
   * @description Renders the status tab.
   */
  renderStatusTab = () => {
    const { id } = this.props
    const { statusTab } = this.state
    const baseid = `${id}_documentinformation_modal_main`

    return (
      <Tab
        onShow={() => this.handleTabIndex(1)}
        title={translate('documentinformation.statustab')}>
        <Row>
          <Column colMD={3}>
            <Dropdown
              id={`${baseid}_statustab_editingicon`}
              title={translate('documentinformation.statustab_editingicon')}
              activeIndex={statusTab.editingIcon}
              onChange={index => this.handleChange('statusTab', 'editingIcon', index)}
              items={[
                [<Icon name={'none'} key={'documentinformation.statustab_editingicon_none'} />, <span key={'documentinformation.statustab_editingicon_none_text'}>{translate('general.none')}</span>],
                [<Icon name={'error'} key={'documentinformation.statustab_editingicon_error'} />, <span key={'documentinformation.statustab_editingicon_error_text'}>{translate('import.editicon_error')}</span>],
                [<Icon name={'check'} key={'documentinformation.statustab_editingicon_check'} />, <span key={'documentinformation.statustab_editingicon_check_text'}>{translate('import.editicon_hook')}</span>],
                [<Icon name={'clip'} key={'documentinformation.statustab_editingicon_clip'} />, <span key={'documentinformation.statustab_editingicon_clip_text'}>{translate('import.editicon_clip')}</span>],
                [<Icon name={'pin'} key={'documentinformation.statustab_editingicon_pin'} />, <span key={'documentinformation.statustab_editingicon_pin_text'}>{translate('import.editicon_pin')}</span>],
                [<Icon name={'stamp'} key={'documentinformation.statustab_editingicon_stamp'} />, <span key={'documentinformation.statustab_editingicon_stamp_text'}>{translate('import.editicon_stamp')}</span>],
              ]}
              autoFocus
            />
          </Column>
          <Column colMD={9}>
            <Input
              id={`${baseid}_statustab_annotation`}
              value={statusTab.annotation}
              title={translate('documentinformation.statustab_annotation')}
              maxLength={32}
              onInputChanged={value => this.handleChange('statusTab', 'annotation', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Dropdown
              id={`${baseid}_statustab_controlstatus`}
              title={translate('documentinformation.statustab_controlstatus')}
              activeIndex={statusTab.controlStatus}
              onChange={index => this.handleChange('statusTab', 'controlStatus', index)}
              items={[
                translate('general.none'),
                translate('search.hold'),
                translate('search.control'),
              ]}
            />
          </Column>
          <Column colMD={5}>
            <Input
              id={`${baseid}_statustab_controluser`}
              disabled={statusTab.controlStatus !== 2}
              value={statusTab.controlUser}
              title={translate('documentinformation.statustab_controluser')}
              maxLength={8}
              onInputChanged={value => this.handleChange('statusTab', 'controlUser', value)}
            />
          </Column>
        </Row>
      </Tab>
    )
  }

  /**
   * @description Renders the retention tab.
   */
  renderRetentionTab = () => {
    const { id, document } = this.props
    const { retentionTab } = this.state
    const baseid = `${id}_documentinformation_modal_main`

    return (
      <Tab
        onShow={() => this.handleTabIndex(2)}
        title={translate('documentinformation.retentiontab')}>
        <Row>
          <Column colMD={6}>
            <label
              id={`${baseid}_retentiontab_title_retentions`}>
              {translate('documentinformation.retentions')}
            </label>
          </Column>
          <Column colMD={6}>
            <label
              id={`${baseid}_retentiontab_title_additional`}>
              {translate('documentinformation.retentiontab_additionalinformation')}
            </label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${baseid}_retentiontab_onlineretention`}
              value={retentionTab.onlineRetention}
              steps={1}
              min={0}
              max={9999}
              title={translate('documentinformation.onlineretention')}
              maxLength={80}
              onChange={num => this.handleChange('retentionTab', 'onlineRetention', num)}
              autoFocus
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${baseid}_retentiontab_onlineretentionselection`}
              items={this.getOnlineRetentionItems()}
              activeIndex={retentionTab.onlineRetentionSelection}
              onChange={index => this.handleChange('retentionTab', 'onlineRetentionSelection', index)}
              title={' '}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${baseid}_retentiontab_online`}
              title={translate('documentinformation.retentiontab_online')}
              text={document.LGRONLNE ? translate('general.yes') : translate('general.no')}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${baseid}_retentiontab_onlineexpiration`}
              title={translate('documentinformation.retentiontab_onlineexpiration')}
              text={document.ONLEXPDT ? DateUtils.getDate(document.datemask, document.ONLEXPDT) : '-'}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${baseid}_retentiontab_lastdocumentaccess`}
              title={translate('documentinformation.retentiontab_lastdocumentaccess')}
              text={document.LREFD ? DateUtils.getDate(document.datemask, document.LREFD) : '-'}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${baseid}_retentiontab_archiveretention`}
              value={retentionTab.archiveRetention}
              steps={1}
              min={0}
              max={9999}
              title={translate('documentinformation.retentiontab_archiveretention')}
              onChange={num => this.handleChange('retentionTab', 'archiveRetention', num)}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${baseid}_retentiontab_archiveretentionselection`}
              items={this.getArchiveRetentionItems()}
              activeIndex={retentionTab.archiveRetentionSelection}
              onChange={index => this.handleChange('retentionTab', 'archiveRetentionSelection', index)}
              title={' '}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${baseid}_retentiontab_archived`}
              title={translate('documentinformation.retentiontab_archived')}
              text={document.LGRARCH ? translate('general.yes') : translate('general.no')}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${baseid}_retentiontab_archiveexperationdate`}
              title={translate('documentinformation.retentiontab_archiveexperationdate')}
              text={document.AEXPDT ? DateUtils.getDate(document.datemask, document.AEXPDT) : '-'}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${baseid}_retentiontab_archivenotes`}
              title={translate('documentinformation.retentiontab_archivenotes')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('retentionTab', 'archiveNotes', index === 0)}
              activeIndex={retentionTab.archiveNotes ? 0 : 1}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${baseid}_retentiontab_notesonline`}
              title={translate('documentinformation.retentiontab_notesonline')}
              text={document.LGRNOTES ? translate('general.yes') : translate('general.no')}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${baseid}_retentiontab_lastnoteaccess`}
              title={translate('documentinformation.retentiontab_lastnoteaccess')}
              text={document.NLREFD ? DateUtils.getDate(document.datemask, document.NLREFD) : '-'}
            />
          </Column>
        </Row>
        <Row>
          <Column offsetMD={6} colMD={2} className={'bux_pl8_pr8'}>
            <TitledLabel
              id={`${baseid}_retentiontab_indexonline`}
              title={translate('documentinformation.retentiontab_indexonline')}
              text={document.LGRONDEX ? translate('general.yes') : translate('general.no')}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${baseid}_retentiontab_indexarchived`}
              title={translate('documentinformation.retentiontab_indexarchived')}
              text={document.LGRIXARC ? translate('general.yes') : translate('general.no')}
            />
          </Column>
        </Row>
      </Tab>
    )
  }

  /**
   * @description Renders the pointers tab.
   */
  renderPointersTab = () => {
    const { id, document } = this.props
    const baseid = `${id}_documentinformation_modal_main`

    return (
      <Tab
        onShow={() => this.handleTabIndex(3)}
        title={translate('documentinformation.pointerstab')}>
        <Row>
          <Column colMD={12}>
            <label
              id={`${baseid}_pointerstab_title`}
              className={'cl_label'}>
              {translate('documentinformation.pointerstab_title')}
            </label>
          </Column>
        </Row>
        <Row>
          <Column colMD={5}>
            <TitledLabel
              id={`${baseid}_pointerstab_listtimestamp`}
              title={translate('documentinformation.pointerstab_listtimestamp')}
              text={document.LTOKEN}
            />
          </Column>
          <Column colMD={5}>
            <TitledLabel
              id={`${baseid}_pointerstab_reporttimestamp`}
              title={translate('documentinformation.pointerstab_reporttimestamp')}
              text={document.RTOKEN !== '' ? document.RTOKEN : translate('general.none')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={5}>
            <TitledLabel
              id={`${baseid}_pointerstab_ressourcespoolpointer`}
              title={translate('documentinformation.pointerstab_ressourcespoolpointer')}
              text={document.RSRCEPTR !== '' ? document.RSRCEPTR : translate('general.none')}
            />
          </Column>
          <Column colMD={5}>
            <TitledLabel
              id={`${baseid}_pointerstab_spoolpointer`}
              title={translate('documentinformation.pointerstab_spoolpointer')}
              text={document.SPOOLPTR !== '' ? document.SPOOLPTR : translate('general.none')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={5}>
            <TitledLabel
              id={`${baseid}_pointerstab_ressourcearchivepointer`}
              title={translate('documentinformation.pointerstab_ressourcearchivepointer')}
              text={document.ARCPTRR !== '' ? document.ARCPTRR : translate('general.none')}
            />
          </Column>
          <Column colMD={5}>
            <TitledLabel
              id={`${baseid}_pointerstab_archivepointer`}
              title={translate('documentinformation.pointerstab_archivepointer')}
              text={document.ARCPTR !== '' ? document.ARCPTR : translate('general.none')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={5}>
            <TitledLabel
              id={`${baseid}_pointerstab_archivetextpointer`}
              title={translate('documentinformation.pointerstab_archivetextpointer')}
              text={document.ARCPTRT !== '' ? document.ARCPTRT : translate('general.none')}
            />
          </Column>
          <Column colMD={5}>
            <TitledLabel
              id={`${baseid}_pointerstab_textpointer`}
              title={translate('documentinformation.pointerstab_textpointer')}
              text={document.TXTPTR !== '' ? document.TXTPTR : translate('general.none')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={5}>
            <TitledLabel
              id={`${baseid}_pointerstab_externalindexdataspoolpointer`}
              title={translate('documentinformation.pointerstab_externalindexdataspoolpointer')}
              text={document.EXTIXPTR !== '' ? document.EXTIXPTR : translate('general.none')}
            />
          </Column>
          <Column colMD={5}>
            <TitledLabel
              id={`${baseid}_pointerstab_indexpointer`}
              title={translate('documentinformation.pointerstab_indexpointer')}
              text={document.INDEXPTR !== '' ? document.INDEXPTR : translate('general.none')}
            />
          </Column>
        </Row>
      </Tab>
    )
  }

  /**
   * @description Renders the OGR tab
   */
  renderOGRTab = () => {
    const { id, document } = this.props
    const baseid = `${id}_documentinformation_modal_main`
    return (
      <Tab
        onShow={() => this.handleTabIndex(4)}
        title={translate('documentinformation.ogrtab')}>
        <Row>
          <Column colMD={12}>
            <div className={'bux_ipp_server_status_result'}>
              <span id={`${baseid}_ogrtab_title`}>
                {translate('documentinformation.ogrtab_title')}
              </span>
              <div>
                {document?.OGR}
              </div>
            </div>
          </Column>
        </Row>
      </Tab>
    )
  }

  /**
   * @description Handles logic to disable tabs
   */
  handleDisabledTabs = () => {
    const { userprofile } = this.props
    const buffer = []
    userprofile && !userprofile.VCIRET && buffer.push(2)
    userprofile && !userprofile.VCISTA && buffer.push(1)
    return buffer
  }

  render = () => {
    const { id, onClose, document } = this.props

    return (
      <Modal
        onClose={onClose}
        id={`${id}_documentinformation`}>
        <Header
          id={`${id}_documentinformation`}
          title={translate('documentinformation.modaltitle')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main
          id={`${id}_documentinformation_modal_main`}>
          <Tabs
            id={`${id}_documentinformation_modal_main`}
            disabledTabs={this.handleDisabledTabs()}>
            {this.renderGeneralTab()}
            {this.renderStatusTab()}
            {this.renderRetentionTab()}
            {this.renderPointersTab()}
            {document.OGR && this.renderOGRTab()}
          </Tabs>
        </Main>
        <Footer>
          <Button
            id={`${id}_documentinformation_modal_footer_cancel_button`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_documentinformation_modal_footer_save_button`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth, search }) => {
  return {
    // Auth state:
    datemask: auth.serverdata.preferences[Preferences.DATEMASK],
    lang: auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: auth.serverdata.preferences,
    userprofile: auth.serverdata.userprofile,
    usertoken: auth.serverdata.token,
    // Search state:
    opendocument: search.standardselection.document
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    updateDocument: (documentDefinition, useCase, callback, refreshFunction) => {
      StandardSelectionActions.updateDocument(documentDefinition, useCase, callback, refreshFunction)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentInformation)