import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Components
import TableHeader from './table_header/TableHeader'
import TableLoadingGif from '../../thirdparty/internal/beta_view/gifs/spinner.gif'
import TableMenu from './table_menu/TableMenu'
import TableRow from './table_row/TableRow'
import AdditionalRow from './AdditionalRow'
import { DragDropContext, Droppable } from '@hello-pangea/dnd'
import hash from 'object-hash'

// Style
import './DataTable.scss'

// import moment from 'moment'
import * as UTILS from './DataTable.utils'

export default class DataTable extends Component {

  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Header which are displayed in DataTable component */
    header: PropTypes.arrayOf(PropTypes.string).isRequired,
    /**
     * Data which is displayed in DataTable component
     *
     * Type: `array<array<any>>`
     */
    data: PropTypes.array.isRequired,
    /**
    * Optional array og unique identifiers to distingue row
    *
    * Type: `array<string>`
    */
    dataIds: PropTypes.array,
    /**
     * Data which is used for CSV download. In most cases data and cleanData are not different.
     * But if you display other JSX elements inside the table, you need to remove the JSX elements
     * to make a download of the data possible.
     *
     * Type: `array<array<object>>`
     */
    cleanData: PropTypes.array,
    /**
     * Used when each row of the DataTable should be selectable for e.g. download option.
     * The column with checkboxes will appear as the first column.
     */
    selectable: PropTypes.bool,
    /**
     * Function that returns array of JSX elements which are displayed at end of each row.
     *
     * @param {number} rowIndex index of the row
     * @return {array<element>} elements
     */
    createActionButtons: PropTypes.func,
    /**
     * Executed when click at row.
     * @param {number} index Index of clicked row
     * @param {SyntheticBaseEvent} event
     */
    createTableRowAction: PropTypes.func,
    /**
     * Types of each column to sort correctly.
     *
     * Enum: "date-time","date", "number", "string", "progressbar", "icon"
     */
    columnSortDefs: PropTypes.arrayOf(PropTypes.oneOf(['date-time', 'date', 'number', 'string', 'progressbar', 'icon'])).isRequired,
    /** Array of JSX elements which are displayed in the menu.*/
    additionalInteraction: PropTypes.arrayOf(PropTypes.element),
    /** Used to display the numbers in the right language format (e.g. 130.000 or 130,000 or 130 000).*/
    language: PropTypes.oneOf(['en', 'de', 'fr']),
    /** Used to display the date entries in the correct format. */
    datemask: PropTypes.string,
    /**
     * Translate function key => key
     * you will need to translate following keys by our own:
     *
     * - `general.no_matching_records` => displayed when filter and no entry matches
     * - `general.selected` => displayed when select one entry
     * - `table.download_as_csv` => displayed when select one entry and gives option to download selected entry
     * - `table.filter_table` => placeholder for filter input
     * - `general.close_row` => tooltip for close row button - chevron down
     * - `general.open_row`  => tooltip for open row button - chevron up
     *
     * @param {string} key
     */
    translate: PropTypes.func,
    /**
     * Used when DataTable content should take the whole available place inside a container.
     * If not used, the DataTable will be rendered with maxEntriesPerPage property or DEFAULT_MAX_ENTRIES_PER_PAGE entries per page.
     */
    fillPage: PropTypes.bool,
    /* Flag if the pagination should be reset or not.*/
    keepPagination: PropTypes.bool,
    /** Disables sorting possibility. */
    disableSorting: PropTypes.bool,
    /* Used when DataList should have expandable rows. */
    expandable: PropTypes.bool,
    /**
     * Array of additional data passed as array for expanded rows of DataTable.
     * First element of ech additional array of dta should be an index of the row.
     *
     * The array should contains only additional data for rows which are currently expanded.
     * The state of _expandableRowData_ should be changed in _onOpenRow_ and  _onCloseRow_ in order to add and delete expanded data form the array.
     *
     * Type: array<array<object>
     */
    expandableRowData: PropTypes.array,
    /**
     * Function which returns JSX element, filled with data for specified index
     *
     * @param {object} data Data for specified _index_ passed to _expandableRowData_
     * @param {number} index Index of the row
     */
    expandableRowElements: PropTypes.func,
    /**
     * Will be called when on row open.
     * The function should add data, which should be display in expanded row.
     * The data should be added to the _expandableRowData_ for row with specified _index_.
     * After adding new value, _callback_ function shall be called.
     *
     * @param {number} index
     * @param {func} callback
     */
    onOpenRow: PropTypes.func,
    /**
     * Will be called when on row close.
     * The function should delete data for specified _index_ from _expandableRowData_.
     * After deleting value, _callback_ function shall be called.
     *
     * @param {number} index
     * @param {func} callback
     */
    onCloseRow: PropTypes.func,
    /**
     * Initial value for filtering
     */
    filter: PropTypes.string,
    /**
     * Sets row state of checked if _selectable_ option is set to _true_
     * Array of string consists of hashes of whole row or data ids if _dataIds_ is provided.
     * */
    checkedRows: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function to be called on the end of drag event.
     * Function should implement reaction of drag&drop, by changing the data.
     *
     * @param {DragResult} result
     *
     * ```
     * {
     *    source: {
     *      droppableId: number,
     *      index: number
     *    },
     *    destination: {
     *      droppableId: number,
     *      index: number
     *    },
     * }
     * ```
     */
    onDragEnd: PropTypes.func,
    /** Used in context of drag&drop. Context of drag&drop is set to parent in order to drag&drop between tables.  */
    useParentContext: PropTypes.bool,
    /**
     * Sets sorted column
     *
     * Object type:
     *
     * ```js
     * {
     *    number: bool, (true- descending, false: ascending)
     *    ...
     * }
     * ```
     */
    sortedCol: PropTypes.object,
    /**
     * Will be called when the sort ends.
     * @param {array<array<object>>} sortedData The new sorted data
     * @param {object} sortedCol The new sorted column
     * @param {array<string>} newCheckedRows The new sorted checked rows
     */
    onSortEnd: PropTypes.func,
    /**
     * Functionality connected with the _Drawer_ component.
     *  When the drawer is expanded and a row is checked, the DataTable component checks, if there is enough place to display the filter input at the top right corner.
     */
    drawerExpanded: PropTypes.bool,
    /**
     * Update checked rows to parent
     * @param {array<string>} checkedRows as array of hashed rows or array of data ids if _dataIds_ provided.
     */
    updateCheckedRowsToParent: PropTypes.func,
    /** Used with drag&drop features. Used for styling. */
    draggingId: PropTypes.string,
    /** Enables visualization of drag handle */
    showDragHandle: PropTypes.bool,
    /** Used to highlight some rows in the DataTable */
    selectedRows: PropTypes.arrayOf(PropTypes.number),
    /** Object which provide name of the icon for 'checks all' */
    checkAllIcons: PropTypes.shape({ check: PropTypes.string, unCheck: PropTypes.string }),
    /** No action for actions button */
    noAction: PropTypes.bool,
    /** Used with drag&drop features. Id of component outside  datatable. */
    droppableID: PropTypes.string,
    /** Used with drag&drop features. Array of selected items. */
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    /** Items which should be display in dropdown in the table menu, after selecting rows. */
    downloadItems: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function to be called on custom action from _downloadItems_ proceed on the table
     * @param {array<array<any>>} checkedRows Array of whole rows of data which are checked
     */
    showModal: PropTypes.func,
    /** Style class from CSS for styling for DataTable.*/
    className: PropTypes.string,
    /** Shows table menu above the table with data. Default true. */
    menu: PropTypes.bool,
    /** Style class from CSS for styling for spinner container.*/
    class: PropTypes.string,
    /** Sets max rows number per page, default is 500.*/
    maxEntriesPerPage: PropTypes.number,
    /** Indicates whether to use the group sorting logic or not */
    applyGroupedSorting: PropTypes.bool,
    /** Index of the grouping key in the 'data' and 'cleanData' array */
    indexGroupingKey: PropTypes.number,

  }

  state = {
    itemsPerPage: 0,
    displayedItems: 0,
    currentPage: 1,
    filter: '',
    showFilter: true,
    checkedRows: [],
    sortedCol: undefined,
    openRows: [],
    prevOpenRows: [],
    loading: true,
    downloadActionIndex: 0,
    modData: [],
    cleanData: [],
  }

  MAX_ENTRIES_PER_PAGE = this.props.maxEntriesPerPage || UTILS.DEFAULT_MAX_ENTRIES_PER_PAGE


  /**
   * @description Initializes the values and event listener.
   */
  componentWillMount = () => {
    const { cleanData, sortedCol, filter, checkedRows } = this.props

    let currentSortedCol = this.state.sortedCol
    if (sortedCol || sortedCol === undefined) {
      currentSortedCol = sortedCol
    }

    let filteredData = this.filter(this.props.data, this.props.dataIds, filter === undefined ? '' : filter)
    let stateCleanData = cleanData ? [...cleanData.map((arr, i) => [...arr, i])] : []
    const newCheckedRows = []
    if (checkedRows) {
      filteredData.forEach(d => {
        if (checkedRows.includes(UTILS.getHashFromRow(d))) {
          newCheckedRows.push(UTILS.getHashFromRow(d))
        }
      })
    }
    this.setState({
      loading: false,
      modData: filteredData,
      sortedCol: currentSortedCol,
      cleanData: stateCleanData,
      checkedRows: newCheckedRows
    }, () => {
      sortedCol !== undefined && this.onSortColumn(Object.keys(sortedCol)[0])
    })
  }

  /**
   * @description Adds event listener and init the items per page.
   */
  componentDidMount = () => {
    const { fillPage, data } = this.props
    if (fillPage) {
      this.calcRows()
      window.addEventListener('resize', this.calcRows)
    } else {
      this.setState({ itemsPerPage: data.length < this.MAX_ENTRIES_PER_PAGE ? data.length : this.MAX_ENTRIES_PER_PAGE })
    }
    window.addEventListener('resize', this.handleFilterVisibility)
  }

  /**
   * @description Removes the event listener.
   */
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.calcRows)
    window.removeEventListener('resize', this.handleFilterVisibility)
  }

  /**
   * @description Updates the internal data.
   * @param {Object} nextProps The next properties.
   */
  componentWillReceiveProps = (nextProps) => {
    const { itemsPerPage, modData } = this.state
    let prevData = [...this.props.data.map((arr, i) => [...arr, i])]
    let nextData = [...nextProps.data.map((arr, i) => [...arr, i])]
    let newCurrentPage
    let newModData
    let newCleanData
    let newSortedCol = null
    let newCheckedRows
    let sortCallback
    let checkCallback

    if ((this.props.sortedCol !== nextProps.sortedCol) && (nextProps.sortedCol || nextProps.sortedCol === undefined)) {
      newSortedCol = nextProps.sortedCol
    }

    // Just reset the pagination when a new search was done by a user through drawer.
    let tempCurrentPage = nextProps.keepPagination ? this.state.currentPage : 1
    // If the user deletes a entry, which is the only entry on the last page, the pagination should decrease.
    if (tempCurrentPage > 1 && (tempCurrentPage - 1) * itemsPerPage >= nextData.length) {
      tempCurrentPage -= 1
    }
    if (!this.areArraysEqual(nextData, prevData, nextProps.columnSortDefs)) {
      let nextCleanData = nextProps.cleanData ? [...nextProps.cleanData.map((arr, i) => [...arr, i])] : []
      nextData = this.filter(nextProps.data, nextProps.dataIds, this.state.filter)
      newModData = nextData
      newCleanData = nextCleanData

      if (this.props.currentPage !== tempCurrentPage) {
        newCurrentPage = tempCurrentPage
      }

      sortCallback = () => {
        if (!this.props.onSortEnd && this.props.sortedCol) {
          this.onSortColumn(Object.keys(this.props.sortedCol)[0])
        }
        else if (this.state.sortedCol !== undefined) {
          this.onSortColumn(Object.keys(this.state.sortedCol)[0], false)
        }
      }
    }
    if (nextProps.checkedRows) {
      newCheckedRows = []
      let data = newModData ? newModData : modData
      data.forEach(d => {
        if (nextProps.checkedRows.includes(UTILS.getHashFromRow(d))) {
          newCheckedRows.push(UTILS.getHashFromRow(d))
        }
      })
    }
    else if (this.state.checkedRows.length > 0) {
      newCheckedRows = []
      let data = newModData ? newModData : modData
      data.forEach(d => {
        if (this.state.checkedRows.includes(UTILS.getHashFromRow(d))) {
          newCheckedRows.push(UTILS.getHashFromRow(d))
        }
      })
    }

    if (newCheckedRows && nextProps.checkedRows && (newCheckedRows.length !== nextProps.checkedRows.length)) {
      checkCallback = () => {
        this.props.updateCheckedRowsToParent(newCheckedRows)
      }
    }

    let newState = {}
    if (newCurrentPage !== undefined) {
      newState.currentPage = newCurrentPage
    }
    if (newModData !== undefined) {
      newState.modData = newModData
    }
    if (newCleanData !== undefined) {
      newState.cleanData = newCleanData
    }
    if (newSortedCol !== null) {
      newState.sortedCol = newSortedCol
    }
    if (newCheckedRows !== undefined) {
      newState.checkedRows = newCheckedRows
    }

    // only set new state if there are new values
    if (Object.keys(newState).length !== 0) {
      this.setState(state => ({ ...state, ...newState }), () => { sortCallback && sortCallback(); checkCallback && checkCallback(); })
    }
  }

  /**
   * @description Updates the internal data.
   * @param {Object} prevProps The previous properties.
   * @param {Object} prevState The previous state.
   */
  componentDidUpdate = (prevProps, prevState) => {
    const { openRows, itemsPerPage, displayedItems, currentPage, showFilter, prevOpenRows, checkedRows } = this.state
    const { drawerExpanded, data, fillPage, id } = this.props
    let window = document.body.clientWidth

    let newItemsPerPage
    let newDisplayedItems
    let newCurrentPage
    let newShowFilter
    let newOpenRows
    let newPrevOpenRows


    if (prevProps.fillPage !== fillPage || prevProps.data.length !== data.length) {
      if (fillPage) {
        this.calcRows()
      }
      else {
        const tempItemsPerPage = data.length < this.MAX_ENTRIES_PER_PAGE ? data.length : this.MAX_ENTRIES_PER_PAGE
        if (tempItemsPerPage !== itemsPerPage) {
          newItemsPerPage = tempItemsPerPage
          newCurrentPage = 1
        }
      }
    }
    if (prevState.displayedItems !== Array.from(document.querySelector(`#${id}_tbody`).children).length - openRows.length) {
      const tempDisplayedItems = Array.from(document.querySelector(`#${id}_tbody`).children).length - openRows.length
      if (tempDisplayedItems !== displayedItems) {
        newDisplayedItems = tempDisplayedItems
      }
    }
    if (displayedItems <= 0 && currentPage > 1 && Math.ceil(data.length / itemsPerPage) < currentPage) {
      newCurrentPage = currentPage - 1
    }
    if (drawerExpanded) {
      if (window <= 1440 && checkedRows.length > 0 && showFilter) {
        newShowFilter = false
      }
      else if (!showFilter && checkedRows.length <= 0) {
        newShowFilter = true
      }
    }
    if (prevProps.expandableRowData !== this.props.expandableRowData && this.props.expandableRowData.length === 0) {
      newOpenRows = []
    }
    if (prevState.prevOpenRows !== prevState.openRows) {
      if (prevState.openRows !== prevOpenRows) {
        newPrevOpenRows = prevState.openRows
      }
    }

    this.colorizeRows()
    this.setBorderIfScrollable()
    if (prevProps.drawerExpanded !== this.props.drawerExpanded) {
      const interval = setInterval(this.setBorderIfScrollable, 5)
      setTimeout(() => clearInterval(interval), 500)
    }

    let newState = {}
    if (newItemsPerPage !== undefined) {
      newState.itemsPerPage = newItemsPerPage
    }
    if (newDisplayedItems !== undefined) {
      newState.displayedItems = newDisplayedItems
    }
    if (newCurrentPage !== undefined) {
      newState.currentPage = newCurrentPage
    }
    if (newShowFilter !== undefined) {
      newState.showFilter = newShowFilter
    }
    if (newOpenRows !== undefined) {
      newState.openRows = newOpenRows
    }
    if (newPrevOpenRows !== undefined) {
      newState.prevOpenRows = newPrevOpenRows
    }

    // only set new state if there are new values
    if (Object.keys(newState).length !== 0) {
      this.setState({ ...this.state, ...newState })
    }
  }

  /**
   * @description Colorize each row background. Needed because each open row should have the same background as the opened row.
   */
  colorizeRows = () => {
    const { id } = this.props
    let counter = 0
    const allRows = Array.from(document.querySelectorAll(`#${id}_datatable tr`))
    allRows.forEach(row => {
      row.classList.remove('bux_even_row', 'bux_odd_row')
    })
    for (let i = 1; i < allRows.length; i++) {
      if (allRows[i].classList.contains('bux_datatable_additionalrow')) {
        if ((counter - 1) % 2 === 0) {
          allRows[i].classList.add('bux_even_row')
        }
        else {
          allRows[i].classList.add('bux_odd_row')
        }
      }
      else {
        if (counter % 2 === 0) {
          allRows[i].classList.add('bux_even_row')
          counter++
        }
        else {
          allRows[i].classList.add('bux_odd_row')
          counter++
        }
      }
    }
  }

  /**
   * @description Checks if the arrays are equal.
   * @param {Array} arr1 The first array to check.
   * @param {Array} arr2 The second array to check.
   * @param {Array} types The types of the values in the arrays. (columnsortdef)
   * @returns {Boolean} True if the arrays are equal.
   */
  areArraysEqual = (arr1, arr2, types) => {
    if (arr1.length !== arr2.length) {
      return false
    }
    let resultRows = 0
    for (let i = 0; i < arr1.length; i++) {
      let resultCols = 0
      for (let j = 0; j < arr1[i].length; j++) {
        if (arr1[i].length !== arr2[i].length) {
          return false
        }
        if (types[j] === 'progressbar') {
          if (arr1[i][j].props.value === arr2[i][j].props.value) {
            resultCols++
          }
        }
        // ! If we use more different react components into the datatable we need to add them here to make the update of the data successfull
        else if (types[j] === 'icon') {
          // its possible, that some rows has icons and other not, so we need to check if both have icons
          if (arr1[i][j].props && arr2[i][j].props) {
            // compare icon props to make sure the content is the same or not
            if (arr1[i][j].props.name === arr2[i][j].props.name && arr1[i][j].props.title === arr2[i][j].props.title && arr1[i][j].props.color === arr2[i][j].props.color) {
              resultCols++
            }
          }
          // Fallback for output queue search result. Some rows have an empty value in "icon" column.
          else if (typeof arr1[i][j] === 'string' && typeof arr2[i][j] === 'string' && arr1[i][j] === arr2[i][j]) {
            resultCols++
          }
        }
        // ? Alternative implementation to compare react components inside a table row. Could result in negative performance.
        // if (React.isValidElement(arr1[i][j]) && React.isValidElement(arr2[i][j])) {
        //   const oldSample = JSON.stringify(arr1[i][j])
        //   const newSample = JSON.stringify(arr2[i][j])
        //   if (oldSample === newSample) {
        //     resultCols++
        //   }
        // }
        else {
          if (arr1[i][j] === arr2[i][j]) {
            resultCols++
          }
        }
      }
      if (resultCols === arr1[i].length) {
        resultRows++
      }
    }
    if (resultRows === arr1.length) {
      return true
    }
    return false
  }

  /**
   * @description Handles the next page click.
   */
  onClickNext = () => {
    const { itemsPerPage, currentPage, modData } = this.state
    if (currentPage < Math.ceil(modData.length / itemsPerPage)) {
      this.setState({ currentPage: currentPage + 1, openRows: [] })
    }
  }

  /**
   * @description Handles the previous page click.
   */
  onClickPrevious = () => {
    const { currentPage } = this.state
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1, openRows: [] })
    }
  }

  /**
   * @description Updates the filter variable in state.
   * @param {String} value Value of the filter text.
   */
  onUpdateFilter = value => {
    const { sortedCol } = this.props
    const filteredData = this.filter(this.props.data, this.props.dataIds, value)
    this.setState({ filter: value, currentPage: 1, modData: filteredData, openRows: [] }, () => {
      !this.props.onSortEnd && sortedCol && this.onSortColumn(Object.keys(sortedCol)[0])
    })
  }

  getFilteredChecked = () => {
    const { checkedRows, modData } = this.state
    let filteredCheckedRows = [];

    if (checkedRows && modData) {
      const dataRows = [...modData].filter((dataRow) => checkedRows.includes(UTILS.getHashFromRow(dataRow)));
      filteredCheckedRows = (dataRows || []).map((dataRow) => UTILS.getHashFromRow(dataRow))
    }
    return filteredCheckedRows;
  }

  /**
   * @description Gets the filtered data with the given value.
   * @param {Array} propData The data of the props.
   * @param {String} value The filter string.
   */
  filter = (propData, dataIds, value) => {
    let data = [...propData.map((arr, i) => [...arr, dataIds ? dataIds[i] : hash(arr.toString()), i])]
    let filteredData = []
    data.forEach(rowData => {
      let counter = 0
      // removes the last data entry which stores the original index
      const dataToCheck = rowData.filter((_, i) => i < rowData.length - 1)
      dataToCheck.forEach(cellData => {
        if (!React.isValidElement(cellData) && typeof cellData !== 'object') {
          if (cellData.toString().toLowerCase().includes(value.toLowerCase())) {
            counter++
          }
        }
      })
      if (counter > 0) {
        filteredData.push(rowData)
      }
    })
    return filteredData
  }

  groupedSort = (col, data, isClean = false) => {
    const { columnSortDefs, datemask, indexGroupingKey } = this.props
    const currentDatemask = datemask === undefined ? UTILS.DEFAULT_MASK : datemask

    const groupedData = UTILS.getGroupedTableData(data, indexGroupingKey)

    return Object.values(groupedData).sort((a, b) => {
      return UTILS.compareColumn({
        columnType: `${columnSortDefs[col]}${isClean ? '_clean': ''}`,
        cellA: a[0][col],
        cellB: b[0][col],
        datemask: currentDatemask,
        sortAscending: this.state.sortedCol[col]
      })
    }).flat()
  }

  singleSort = (col, data, isClean = false) => {
    const { columnSortDefs, datemask } = this.props
    const currentDatemask = datemask === undefined ? UTILS.DEFAULT_MASK : datemask

    return data.sort((a, b) => {
      return UTILS.compareColumn({
        columnType: `${columnSortDefs[col]}${isClean ? '_clean': ''}`,
        cellA: a[col],
        cellB: b[col],
        datemask: currentDatemask,
        sortAscending: this.state.sortedCol[col]
      })
    })
  }

  /**
   * @description Sorts the data in a given column.
   * @param {Number} col Column which should be sorted.
   */
  onSortColumn = (col, resetPagination = true) => {
    const { applyGroupedSorting, header, onSortEnd, updateCheckedRowsToParent } = this.props
    const { modData, cleanData, currentPage } = this.state

    const sortedItemsModData = !applyGroupedSorting ? [...this.singleSort(col, modData)] : [...this.groupedSort(col, modData)]
    const sortedItemsCleanData = !applyGroupedSorting ? [...this.singleSort(col, cleanData, true)] : [...this.groupedSort(col, cleanData, true)]

    // only call the parent when onSortEnd is set
    if (onSortEnd) {
      const newData = [...sortedItemsModData.map(arr => [...arr].splice(0, header.length))]
      const updatedCheckedRows = []
      this.state.modData.forEach(d => this.state.checkedRows.includes(UTILS.getHashFromRow(d)) && updatedCheckedRows.push(UTILS.getHashFromRow(d)))
      onSortEnd(newData, this.state.sortedCol, updatedCheckedRows)
    }
    else {
      this.setState({ modData: sortedItemsModData, cleanData: sortedItemsCleanData, currentPage: resetPagination ? 1 : currentPage, openRows: [] }, () => {
        const buffer = []
        this.state.modData.forEach(d => this.state.checkedRows.includes(UTILS.getHashFromRow(d)) && buffer.push(UTILS.getHashFromRow(d)))
        if (updateCheckedRowsToParent) {
          updateCheckedRowsToParent(buffer)
        }
      })
    }
  }

  /**
   * @description Calculates how much rows are shown on the screen.
   */
  calcRows = () => {
    const { data, id, fillPage } = this.props
    const { modData, currentPage, itemsPerPage } = this.state
    const MIN_ROWS = 1
    const tableMenuHeight = 50
    const maxFiveHundred = this.MAX_ENTRIES_PER_PAGE
    const el = document.querySelector(`#${id}_datatable_container`)
    if (el) {
      const elHeight = el.getBoundingClientRect().height - tableMenuHeight
      const rows = Math.floor(elHeight / 40 - 1)
      if (!fillPage) {
        if (data.length > maxFiveHundred) {
          if (itemsPerPage !== maxFiveHundred) {
            this.setState({ itemsPerPage: maxFiveHundred })
          }
        }
        else {
          if (itemsPerPage !== data.length) {
            this.setState({ itemsPerPage: data.length })
          }
        }
      }
      else {
        if (rows > data.length) {
          this.setState({ itemsPerPage: data.length })
        }
        else if (rows > MIN_ROWS) {
          // Decrease pagination when the page size is changing and the current page would not display entries anymore.
          let newCurrentPage = currentPage > 1 && rows * (currentPage - 1) >= modData.length ? currentPage - 1 : currentPage
          this.setState({ itemsPerPage: rows, currentPage: newCurrentPage })
        }
        else {
          this.setState({ itemsPerPage: MIN_ROWS })
        }
      }
    }
  }

  /**
   * @description Hides the filter input when space is not enough to display.
   */
  handleFilterVisibility = () => {
    const { checkedRows } = this.state
    if (document.body.clientWidth <= 1400 && checkedRows.length > 0) {
      this.setState({ showFilter: false })
    }
    else {
      this.setState({ showFilter: true })
    }
  }

  /**
   * @description Saves the row into the state, which is expanded.
   * @param {Number} index Index which row is clicked.
   */
  onExpandRow = index => {
    const { openRows } = this.state
    if (openRows && openRows.includes(index)) {
      this.setState({ openRows: openRows.filter(d => d !== index) })
    }
    else {
      this.setState({ openRows: [...openRows, index] })
    }
  }

  /**
   * @description Checks/unchecks the row.
   * @param {Number} currentIndex Index of the row.
   */
  handleOnCheck = currentIndex => {
    const { checkedRows, modData, currentPage, itemsPerPage } = this.state
    const { updateCheckedRowsToParent } = this.props
    const data = modData[(itemsPerPage * (currentPage - 1)) + currentIndex]
    const dataHash = UTILS.getHashFromRow(data)
    if (!checkedRows.includes(dataHash)) {
      this.setState({
        checkedRows: [...checkedRows, dataHash]
      }, () => {
        this.handleFilterVisibility()
        if (updateCheckedRowsToParent) {
          const buffer = []
          this.state.modData.forEach(d => this.state.checkedRows.includes(UTILS.getHashFromRow(d)) && buffer.push(UTILS.getHashFromRow(d)))
          updateCheckedRowsToParent(buffer)
        }
      })
    } else {
      this.setState({
        checkedRows: checkedRows.filter(checkedElement => checkedElement !== dataHash)
      }, () => {
        this.handleFilterVisibility()
        if (updateCheckedRowsToParent) {
          const buffer = []
          this.state.modData.forEach(d => this.state.checkedRows.includes(UTILS.getHashFromRow(d)) && buffer.push(UTILS.getHashFromRow(d)))
          updateCheckedRowsToParent(buffer)
        }
      })
    }
  }

  /**
   * @description Checks/unchecks all displayed rows.
   * @param {Boolean} isChecked Flag if the rows shoud be checked/unchecked.
   */
  handleOnCheckAll = isChecked => {
    const { id, updateCheckedRowsToParent } = this.props
    const { currentPage, itemsPerPage, checkedRows, modData } = this.state
    let checkedRowsBuffer = [...checkedRows]
    // get the rows of the current page
    let rows = [...document.querySelector(`#${id}_tbody`).children]
    const pageOffset = (currentPage - 1) * itemsPerPage
    rows.forEach((_, index) => {
      const data = modData[index + pageOffset];
      const dataHash = UTILS.getHashFromRow(data);
      if (isChecked) {
        if (!checkedRowsBuffer.includes(dataHash)) {
          checkedRowsBuffer.push(dataHash)
        }
      } else {
        checkedRowsBuffer = checkedRowsBuffer.filter(row => row !== (dataHash))
      }
    })
    this.setState({ checkedRows: checkedRowsBuffer }, () => {
      this.handleFilterVisibility()
      if (updateCheckedRowsToParent) {
        const checkedRowsBuffer = []
        this.state.modData.forEach(d => this.state.checkedRows.includes(UTILS.getHashFromRow(d)) && checkedRowsBuffer.push(UTILS.getHashFromRow(d)))
        updateCheckedRowsToParent(checkedRowsBuffer)
      }
    })
  }

  /**
   * @description Checks if all rows are checked.
   * @returns {Boolean} true if all rows of the current page are checked.
   */
  checkAllCheckboxesSelected = () => {
    const { itemsPerPage, currentPage, modData, checkedRows } = this.state
    // calcs how much rows are shown
    let max = itemsPerPage * currentPage <= modData.length ? itemsPerPage : modData.length % itemsPerPage
    const pageOffset = (currentPage - 1) * itemsPerPage
    // check if all rows on the current page are checked
    let found = 0
    for (let i = 0; i < max; i++) {
      const data = modData[i + pageOffset]
      const dataHash = UTILS.getHashFromRow(data);
      if (checkedRows.includes(dataHash)) {
        found++
      }
    }
    return found === max
  }

  /**
   * @description Handles the change of the download dropdown.
   * @param {Number} index Index of the changed dropdown item.
   */
  handleChangeDownloadAction = index => {
    this.setState({ downloadActionIndex: index })
  }


  /**
   * @description Opens / Closes a row at a specific index.
   * @param {Number} index The index of the row which should be opened / closed.
   */
  onOpenCloseRow = index => {
    const { id } = this.props
    const { openRows } = this.state
    if (openRows.includes(index)) {
      const el = document.querySelector(`#${id}_additionalrow_${index} .bux_expandable_content`)
      el.classList.remove('bux_expand_row')
      el.classList.add('bux_collapse_row')
      setTimeout(() => this.removeData(index), 500)
    } else {
      this.addData(index)
    }
  }

  /**
   * @description Adds the expandable row data at the index to the table.
   * @param {Number} index The index of the expanded row.
   */
  addData = index => {
    this.props.onOpenRow(index, () => {
      this.setState({
        openRows: [...this.state.openRows, index],
      })
    })
  }

  /**
   * @description Removes the epandable row data at the index from the table.
   * @param {Number} index The index of the expanded row.
   */
  removeData = index => {
    this.props.onCloseRow(index, () => {
      this.setState({
        openRows: this.state.openRows.filter(d => d !== index),
      })
    })
  }

  handleClick = (index, event) => {
    const { expandable, createTableRowAction } = this.props
    if (expandable) {
      this.onOpenCloseRow(index)
    }
    else {
      createTableRowAction && createTableRowAction(index, event)
    }
  }

  /**
   * @description Checks if the the passed array exists in the passed arrays param.
   * @param {Array} arrays An array of arrays.
   * @param {Array} array The array which should be checked if it exists in the arrays param.
   * @returns {Boolean} True if the passed array exists in the passed arrays.
   */
  includesArray = (arrays, array) => {
    for (let i = 0; i < arrays.length; i++) {
      const element = arrays[i]
      if (this.equalsArrays(element, array, true)) {
        return true
      }
    }
    return false
  }

  /**
   * @description Checks if the arrays are equal.
   * @param {Array} array1 The first array to check.
   * @param {Array} array2 The second array to check.
   * @param {Boolean} strict Flag for strict check.
   * @returns {Boolean} True if the arrays are equal.
   */
  equalsArrays = (array1, array2, strict) => {
    if (!array1 || !array2)
      return false

    if (strict === undefined)
      strict = true

    if (array1.length !== array2.length)
      return false

    for (var i = 0; i < array1.length; i++) {
      if (array1[i] instanceof Array && array2[i] instanceof Array) {
        if (!this.equalsArrays(array1[i], array2[i], strict))
          return false
      }
      else if (strict && array1[i] !== array2[i]) {
        return false
      }
      else if (!strict) {
        return array1.sort().equals(array2.sort(), true)
      }
    }
    return true
  }

  /**
   * @description Renders the drag and drop context.
   * @returns {Object} JSX elements
   */
  renderContext = () => {
    const { onSortEnd, onDragEnd } = this.props
    return (
      // enables the dnd functionality
      <DragDropContext onDragEnd={(result) => {
        onDragEnd(result)
        // reset sorting arrow
        if (onSortEnd) {
          // onSortEnd(undefined, undefined)
        }
        else {
          this.setState({ sortedCol: undefined })
        }
      }
      }>
        {this.renderMainTable()}
      </DragDropContext>
    )
  }

  onSort = col => {
    if (!this.props.disableSorting) {
      this.setState({ sortedCol: { [col]: (!this.state.sortedCol || !this.state.sortedCol[col]) } },
        () => this.onSortColumn(col)
      )
    }
  }

  /**
   * @description Renders the main table with all data.
   * @returns {Object} JSX elements
   */
  renderMainTable = () => {
    const { id, header, expandableRowData, expandableRowElements, columnSortDefs,
      createActionButtons, translate, language, selectable, expandable,
      draggingId, onDragEnd, showDragHandle, useParentContext, selectedRows, checkAllIcons } = this.props
    const { modData, currentPage, itemsPerPage, openRows, prevOpenRows,
      checkedRows, filter, sortedCol } = this.state
    let noAction = this.props.noAction
    // if noAction is not passed or false check if actionbutton in row exists
    if (!noAction) {
      noAction = !createActionButtons
    }
    const dataToShow = modData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    return (
      <table id={`${id}_datatable`} className={'bux_datatable'}>
        <TableHeader
          header={header}
          sorting={col => this.onSort(col)}
          id={id}
          sortedCol={sortedCol}
          selectable={selectable}
          data={modData}
          checkAllIcons={checkAllIcons}
          checkAllCheckboxesSelected={this.checkAllCheckboxesSelected()}
          handleOnCheckAll={this.handleOnCheckAll}
          createActionButtons={createActionButtons}
          expandable={expandable}
          showDragHandle={(onDragEnd || useParentContext) && openRows.length === 0 && showDragHandle}
        />
        <Droppable droppableId={this.props.droppableID ? this.props.droppableID : 'droppable'} >
          {(provided) => (
            <tbody id={`${id}_tbody`}
              ref={provided.innerRef}>
              {
                dataToShow.length > 0
                  // render filtered items for the current page
                  ? dataToShow.map((rowData, index) => {
                    let filteredRowData = [...rowData].filter((_, i) => i < header.length)
                    const isSelected = this.props.selectedIds && this.includesArray(this.props.selectedIds, filteredRowData)
                    // Following lines seems senseless but you should now
                    // null && true && false = null
                    // null && true && true  = false
                    // If there is a true and false and a null in array js-condition, it's getting weird.

                    // The variable "isGhosting" is for the elements which are dragged and should be a white space on their position.
                    const isGhosting = [isSelected && draggingId && !this.equalsArrays(draggingId, filteredRowData)].every(d => d)
                    return (
                      <Fragment key={`maintable_tr_${UTILS.getIndexFromRow(rowData)}`}>
                        <TableRow
                          enableDnd={(this.props.onDragEnd || this.props.useParentContext) && openRows.length === 0}
                          id={id}
                          noAction={noAction}
                          data={filteredRowData}
                          index={index}
                          actionIndex={UTILS.getIndexFromRow(rowData)}
                          handleClick={this.handleClick}
                          columnSortDefs={columnSortDefs}
                          language={language}
                          selectable={selectable}
                          isChecked={checkedRows.includes(UTILS.getHashFromRow(rowData))}
                          handleOnCheck={this.handleOnCheck}
                          createActionButtons={createActionButtons}
                          filter={filter}
                          expandable={expandable}
                          isOpen={openRows.includes(UTILS.getIndexFromRow(rowData))}
                          onOpenCloseRow={this.onOpenCloseRow}
                          showDragHandle={(this.props.onDragEnd || this.props.useParentContext) && this.state.openRows.length === 0 && this.props.showDragHandle}
                          draggingId={draggingId}
                          isSelected={isSelected}
                          isGhosting={isGhosting}
                          selectedIds={this.props.selectedIds}
                          translate={translate}
                          selectedRows={selectedRows}
                        />
                        {
                          expandable && expandableRowData &&
                          <AdditionalRow
                            id={id}
                            data={expandableRowData}
                            tableCols={header.length + createActionButtons().length + 1}
                            index={UTILS.getIndexFromRow(rowData)}
                            openRows={openRows}
                            prevOpenRows={prevOpenRows}
                            expandableRowElements={expandableRowElements}
                            expandable={expandable}
                          />
                        }
                      </Fragment>
                    )
                  })
                  : <tr>
                    <td className={'bux_datatable_no_filter_result'} colSpan={header.length}>
                      <div>{translate('general.no_matching_records')}</div>
                    </td>
                  </tr>
              }
              {/* render placeholder important for dnd */}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </table>
    )
  }

  /**
   * @description Sets the border for the container to display correctly.
   */
  setBorderIfScrollable = () => {
    const { id } = this.props
    const el = document.querySelector(`#datatable_bottom_border_${id}`)
    if (this.isContainerVerticalScrollable()) {
      el.classList.add('bux_datatable_move_left_bottom_border')
    }
    else {
      el.classList.remove('bux_datatable_move_left_bottom_border')
    }
    if (this.isContainerHorizontalScrollable()) {
      el.classList.add('bux_datatable_move_top_bottom_border')
    }
    else {
      el.classList.remove('bux_datatable_move_top_bottom_border')
    }
  }

  /**
   * @description Checks if the container is vertical scrollable.
   * @returns {Boolean}
   */
  isContainerVerticalScrollable = () => {
    const { id } = this.props
    const el = document.querySelector(`#${id}_datatable_scroll_container`)
    return el && el.scrollHeight > el.clientHeight
  }

  /**
   * @description Checks if the container is horizontal scrollable.
   * @returns {Boolean}
   */
  isContainerHorizontalScrollable = () => {
    const { id } = this.props
    const el = document.querySelector(`#${id}_datatable_scroll_container`)
    return el && el.scrollWidth > el.clientWidth
  }

  getSelectedRows = () => {
    const result = []
    this.state.modData.forEach(d => this.state.checkedRows.includes(UTILS.getHashFromRow(d)) && result.push(d))
    return result
  }

  /**
   * @description Renders the whole data table.
   */
  render = () => {
    const { header, id, additionalInteraction, downloadItems, showModal, translate,
      useParentContext, className, menu = true, openDocViewerCallback } = this.props
    const { currentPage, itemsPerPage, filter, modData, loading,
      showFilter, downloadActionIndex, cleanData } = this.state

    return (
      <div id={`${id}_datatable_container`}
        className={`bux_datatable_container${className ? ` ${className}` : ''}`}>
        <div id='we_table_spinner' style={{ display: loading ? '' : 'none' }} className={`${this.props.class} we_id_main_body_container`}>
          <img src={TableLoadingGif} alt='loading' />
        </div>
        {
          menu &&
          <TableMenu
            id={id}
            data={modData}
            cleanData={cleanData}
            header={header}
            clickNext={this.onClickNext}
            clickPrevious={this.onClickPrevious}
            changeDownloadAction={this.handleChangeDownloadAction}
            additionalInteraction={additionalInteraction}
            updateFilter={this.onUpdateFilter}
            filter={filter}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            checkedRows={this.getFilteredChecked()}
            showFilter={showFilter}
            downloadActionIndex={downloadActionIndex}
            downloadItems={downloadItems}
            showModal={() => showModal(this.getSelectedRows(), downloadActionIndex)}
            translate={translate}
            openDocViewerCallback={openDocViewerCallback}
          />
        }
        <div className={`bux_datatable_scroll_container ${menu ? 'bux_datatable_with_menu' : ''}`} id={`${id}_datatable_scroll_container`}>
          {
            useParentContext
              ? this.renderMainTable()
              : this.renderContext()
          }
        </div>
        <div id={`datatable_bottom_border_${id}`} className={'bux_datatable_bottom_border'} />
      </div>
    )
  }
}