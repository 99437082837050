import React, { Component } from 'react'
import propTypes from 'prop-types'
import * as d3 from 'd3'

import './DonutChart.scss'

class DonutChart extends Component {

  stillUpdate = false

  state = {
    width: 0,
    height: 0,
    orgValue: []
  }

  componentDidMount() {
    let el = document.querySelector(`#donutChart${this.props.id}`).getBoundingClientRect()
    this.setState({ width: this.props.width || el.width, height: this.props.height || el.height }, this.drawChart)
    window.addEventListener('resize', this.updateChart)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateChart)
  }

  componentWillReceiveProps = () => {
    requestAnimationFrame(() => {
      // updates chart when datemask or language has changed
      d3.select(`#donutChart${this.props.id}`).select('svg').remove()
      this.drawChart()
      if (!this.stillUpdate) {
        this.stillUpdate = true
        let chartUpdater = setInterval(this.updateChart, 1)
        setTimeout(() => this.stopUpdater(chartUpdater), 300)
      }
    })
  }

  // stops the interval for updating the chart when expand/collapse drawer
  stopUpdater = timer => {
    clearInterval(timer)
    this.updateChart()
    this.stillUpdate = false
  }

  updateChart = () => {
    const { width, height } = this.state
    let el = document.querySelector(`#donutChart${this.props.id}`)
    if (el) {
      el = el.getBoundingClientRect()
      if (el.width !== width || el.height !== height) {
        this.setState({ width: el.width, height: el.height, orgValue: [] }, () => {
          d3.select(`#donutChart${this.props.id}`).select('svg').remove()
          this.drawChart()
        })
      }
    }
  }

  drawChart = () => {
    const propsId = this.props.id
    const id = `#donutChart${propsId}`
    const { data, thickness, pieOrDonut, formatFunc } = this.props
    const { width, height } = this.state
    const radius = Math.min(width, height) / 2
    const keys = Object.keys(data[0])
    const legendKeys = Object.values(data)
    let totalValue = 0
    data.forEach(d => totalValue += d.value)
    const svg = d3.select(id)
      .append('svg')
      .attr('width', width)
      .attr('height', height)

    if (totalValue > 0) {
      let colors = d3.scaleOrdinal()
        .range(this.props.colors)

      const arc = d3.arc()
      if (pieOrDonut === 'pie') {
        arc.innerRadius(0)
          .outerRadius(radius)
      } else {
        arc.innerRadius(radius - thickness)
          .outerRadius(radius)
      }

      let el = document.querySelector(id).getBoundingClientRect()
      // TODO: just display the 3 biggest value legend keys and one 'other' key
      const legendHeight = el.height / 2 - ((legendKeys.length) % 2 !== 0 ? (Math.floor(legendKeys.length / 2) * 50) : (legendKeys.length / 2 - 1) * 50 + 30)
      svg.append('svg')
        .append('g')
        .attr('class', 'legend')
        .attr('transform', `translate(0, ${legendHeight})`)

      for (let i = 0; i < legendKeys.length; i++) {
        svg.select('.legend')
          .append('rect')
          .attr('fill', (i < this.props.colors.length) ? this.props.colors[i] : this.props.colors[this.props.colors.length - 1])
          .attr('height', 10)
          .attr('width', 40)
          .attr('y', () => i * 50)

        svg.selectAll('.legend').append('text')
          .text(legendKeys[i][keys[0]])
          .attr('font-weight', 'bold')
          .attr('fill', (i < this.props.colors.length) ? this.props.colors[i] : this.props.colors[this.props.colors.length - 1])
          .attr('x', 50)
          .attr('y', () => i * 50 + 9)
      }

      // remove legend when space is to small to display it in good way
      let legendWidth = document.querySelector(`${id} .legend`).getBoundingClientRect().width
      if (width - legendWidth <= 325) {
        d3.select(`${id} .legend`).remove()
      }

      // select tooltip
      let tooltip = d3.select(`${id}`).selectAll(pieOrDonut === 'pie' ? '.tooltip_pie' : '.tooltip_donut')

      // create tooltip when no tooltip exists
      if (tooltip.empty()) {
        tooltip = d3
          .select(id)
          .append('div')
          .style('opacity', 0)
        pieOrDonut === 'pie' ? tooltip.attr('class', 'tooltip_pie') : tooltip.attr('class', 'tooltip_donut')
      }

      const g = svg.append('svg').append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`)
        .on('mouseleave', () => {
          g.selectAll('path')
            .transition()
            .style('opacity', 1)
          tooltip
            .transition()
            .style('opacity', 0)
        })


      const pie = d3.pie()
        .value(d => {
          if (d.value !== Math.max(d.value, totalValue * 0.015)) {
            this.setState({ orgValue: [...this.state.orgValue, d.value] })
          }
          return Math.max(d.value, totalValue * 0.015)
        })
        .sort(null)

      g.selectAll(`dpath${propsId}`)
        .data(pie(data))
        .enter()
        .append('g')
        .attr('class', 'pathGroup')
        .append('path')
        .attr('class', `dpath${propsId}`)
        .attr('d', arc)
        .attr('fill', d => colors(d.data[keys[0]]))
        .attr('cursor', 'pointer')
        .attr('stroke', '#fff')
        .attr('stroke-width', 3)
        .on('mouseover', function (event, d) {
          const e = g.nodes();
          const i = e.indexOf(event.currentTarget);
          const text = `${d.data[keys[0]]}`
          const infoText = `${formatFunc ? formatFunc(d.data[keys[1]]) : d.data[keys[1]]} | ${calculatePercentage(d, i)}%`
          d3.selectAll(`.dpath${propsId}`)
            .transition()
            .duration(100)
            .style('opacity', 0.3)

          d3.select(this)
            .transition()
            .style('opacity', 1)

          if (pieOrDonut === 'pie') {
            tooltip
              .transition()
              .duration(200)
              .style('opacity', 1)
            tooltip
              .html(`<h3>${text}</h3><p>${infoText}</p>`)
              .style('left', `${event.layerX}px`)
              .style('top', `${event.layerY - 70}px`)
              .style('font-family', 'Roboto')
          } else {
            g.append('text')
              .attr('font-weight', 'bold')
              .attr('text-anchor', 'middle')
              .attr('font-size', '2rem')
              .attr('dy', '-0.5em')
              .text(text)
            g.append('text')
              .attr('transorm', `translate(${width / 2}, ${height / 2})`)
              .attr('text-anchor', 'middle')
              .attr('font-size', '1.5rem')
              .attr('dy', '2em')
              .attr('dx', '0.25em')
              .text(infoText)
              .attr('font-size', '14px')
          }
        })
        .on('mousemove', (event) => {
          tooltip
            .style('left', `${event.layerX}px`)
            .style('top', `${event.layerY - 70}px`)
        })
        .on('mouseleave', () => {
          g.selectAll('text')
            .remove()
        })

      const calculatePercentage = (d, i) => {
        let totalValue = 0
        data.forEach(object => {
          totalValue += Number(object.value)
        })
        return ((this.state.orgValue && this.state.orgValue[i] ? this.state.orgValue[i] : d.value) / totalValue * 100).toFixed(2)
      }

      // animation when the chart renders
      // TODO: FIX BUG WHEN THE CHART IS HOVERED WHILE RENDERING
      // function pieTween(b) {
      //   b.innerRadius = 0
      //   const i = d3.interpolate({ startAngle: 0, endAngle: 0 }, b)
      //   return t => {
      //     return arc(i(t))
      //   }
      // }
    }
    else {
      const textLength = 118
      const textHeight = 14
      svg.append('text')
        .text('No data available.')
        .attr('font-weight', 'bold')
        .attr('fill', '#333')
        .attr('x', width / 2 - textLength / 2)
        .attr('y', height / 2 - textHeight / 2)
    }
  }

  render() {
    return <div className={'donutChart'} id={`donutChart${this.props.id}`}></div>
  }
}

DonutChart.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  /** Array of data contains name and value of donut/pie chart */
  data: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      value: propTypes.number
    })).isRequired,
  /** The width defines the width of the svg element which displays the bar chart. */
  width: propTypes.number,
  /** The height defines the height of the svg element which displays the bar chart. */
  height: propTypes.number,
  /**
   * The colors are used to colorize the bars in the bar chart.
   * Each color in this array will be used for one bar.
   * If you define less colors then bars you have, the last color will be used for each additional bar.
   */
  colors: propTypes.arrayOf(propTypes.string).isRequired,
  /**
   * The thickness determines the thickness of the donut chart.
   * If the thickness is 0, the chart would be invisible.
   */
  thickness: propTypes.number.isRequired,
  /** It will display either a pie or donut chart. Default is 'donut'. */
  pieOrDonut: propTypes.oneOf(['pie', 'donut']),
  /** The function allows to format values of the charts, which are visible on hover */
  formatFunc: propTypes.func
}

export default DonutChart