import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'
import Card from 'BetaUX2Web-Components/src/components/card/Card'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as IndexDefinitionActions from 'redux/actions/IndexDefinitionActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'

class DefinitionIndex extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    indexID: {
      value: '',
      errorkey: ''
    },
    owner: {
      value: '',
      errorkey: ''
    },
    identifier: {
      value: '',
      errorkey: ''
    },
    activeDataFormatIndex: Math.max(DefinitionUtils.getIndexDataFormatTypes(true).findIndex(temp => temp.key === DefinitionUtils.INDEX_DATA_FORMAT_ANY), 0)
  }

  state = { ...this.defaultState }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props

    if (preferences) {
      const activeDataFormat = preferences[Preferences.DEFINITION_INDEX_DATA_FORMAT] || DefinitionUtils.INDEX_DATA_FORMAT_ANY
      const activeDataFormatIndex = Math.max(DefinitionUtils.getIndexDataFormatTypes(true).findIndex(temp => temp.key === activeDataFormat), 0)

      this.setState({
        indexID: {
          value: preferences[Preferences.DEFINITION_INDEX_ID] || '',
          errorkey: ''
        },
        owner: {
          value: preferences[Preferences.DEFINITION_INDEX_OWNER] || '',
          errorkey: ''
        },
        identifier: {
          value: preferences[Preferences.DEFINITION_INDEX_IDENTIFIER] || '',
          errorkey: ''
        },
        activeDataFormatIndex
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInput = (id, value, errorkey) => this.setState({ [id]: { value, errorkey } })

  /**
   * @description Handles the dropdown input field.
   * @index The new active type index.
   */
  handleDropdown = index => this.setState({ activeDataFormatIndex: index })

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmit = event => {
    event.preventDefault()
    const { getIndexes, changePrefs } = this.props
    const { indexID, owner, identifier, activeDataFormatIndex } = this.state

    const activeDataFormat = DefinitionUtils.getIndexDataFormatTypes(true)[activeDataFormatIndex].key

    indexID.value = indexID.value.trim()
    owner.value = owner.value.trim()
    identifier.value = identifier.value.trim()

    const prefsToChange = {
      [Preferences.DEFINITION_INDEX_ID]: indexID.value,
      [Preferences.DEFINITION_INDEX_OWNER]: owner.value,
      [Preferences.DEFINITION_INDEX_IDENTIFIER]: identifier.value,
      [Preferences.DEFINITION_INDEX_DATA_FORMAT]: activeDataFormat
    }

    changePrefs(prefsToChange)
    getIndexes(undefined, indexID.value, identifier.value, activeDataFormat, owner.value)

  }

  /**
   * @description Resets the current values to the default values.
   */
  handleReset = () => this.setState(this.defaultState)

  /**
   * @description Gets the type items from DefinitionUtils and creates new array with translation keys
   */
  getTypeItems = () => {
    const items = []
    DefinitionUtils.getIndexDataFormatTypes(true).forEach(type => {
      items.push(translate(type.translationKey))
    })
    return items
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    const { indexID, owner, identifier, activeDataFormatIndex } = this.state
    const typeItems = this.getTypeItems()

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        <Card title={translate('general.general')}>
          <Row>
            <Column colMD={6}>
              <Input
                id={`${id}_index_id`}
                title={translate('definition.index_id')}
                onInputChanged={(val, err) => this.handleInput('indexID', val, err)}
                maxLength={16}
                value={indexID.value}
              />
            </Column>
            <Column colMD={6}>
              <Input
                id={`${id}_index_owner`}
                title={translate('general.owner')}
                onInputChanged={(val, err) => this.handleInput('owner', val, err)}
                maxLength={8}
                value={owner.value}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_index_identifier`}
                title={translate('definition.index_identifier')}
                onInputChanged={(val, err) => this.handleInput('identifier', val, err)}
                maxLength={64}
                value={identifier.value}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Dropdown
                id={`${id}_index_dataformat`}
                title={translate('definition.index_data_format')}
                items={typeItems}
                activeIndex={activeDataFormatIndex}
                onChange={index => this.handleDropdown(index)}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSubmit}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={() => { }}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getIndexes: (fields, index, identifier, dataFormat, owner, callback) => {
      IndexDefinitionActions.getIndexes(fields, index, identifier, dataFormat, owner, callback)(dispatch)
    },
    changePrefs: prefs => {
      PreferenceActions.changePrefs(prefs)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionIndex)