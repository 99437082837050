import PropTypes from 'prop-types'
import { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import * as SortUtils from 'utils/SortUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CopySecurityProfileDialog from 'components/dialogs/copy_security_profile_dialog/CopySecurityProfileDialog'
import CreateSecurityProfileDialog from 'components/dialogs/create_security_profile_dialog/CreateSecurityProfileDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifySecurityProfileDialog from 'components/dialogs/modify_security_profile_dialog/ModifySecurityProfileDialog'
import TableSettings from 'components/table_settings/TableSettings'

import * as SecurityProfileActions from 'redux/actions/SecurityProfileActions'
import * as Preferences from 'redux/general/Preferences'
import * as SecurityFunctions from 'redux/general/SecurityFunctions'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultSecurityProfiles extends Component {
  state = {
    showCreateSecurityProfileDialog: false,
    showModifySecurityProfileDialog: false,
    showCopySecurityProfileDialog: false,
    showDeleteSecurityProfileDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: PROFILEMASK, translation: 'security.profile_mask', default: true },
      { rest: USER_GROUP_ID, translation: 'security.user_group_id', default: true },
      { rest: ACCESS, translation: 'security.access', default: true },
      { rest: DESCRIPTION, translation: 'general.description', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER }
    ]
  }

  /**
   * @description Shows the create security profile dialog.
   */
  handleOnAddEntry = () => {
    this.setState({ showCreateSecurityProfileDialog: true })
  }

  /**
   * @description Calls the last search again.
   */
  handleOnRefresh = () => {
    const { preferences } = this.props
    const profilemask = preferences[Preferences.SECURITY_PROFILEMASK]
    const betauser = preferences[Preferences.SECURITY_BETAUSER]

    this.props.getSecurityProfiles(profilemask, betauser)
  }

  /**
   * @description Opens the delete security profile dialog.
   * @param index The index position of the item which should be deleted.
   */
  handleOnDeleteSecurityProfile = (index) => {
    // get the profilemask from redux
    const profilemask = this.props.securityProfiles.data[index][0]
    const betauser = this.props.securityProfiles.data[index][1]

    // declare success callback
    const callback = () => { this.setState({ showDeleteSecurityProfileDialog: true }) }

    // get the specific security profile
    this.props.getSecurityProfile(profilemask, betauser, callback)
  }

  /**
   * @description Opens the modify security profile dialog.
   * @param index The index position of the item which should be modified.
   */
  handleOnModifySecurityProfile = (index) => {
    // get the profilemask from redux
    const profilemask = this.props.securityProfiles.data[index][0]
    const betauser = this.props.securityProfiles.data[index][1]

    // declare success callback
    const callback = () => { this.setState({ showModifySecurityProfileDialog: true }) }

    // get the specific security profile
    this.props.getSecurityProfile(profilemask, betauser, callback)
  }

  /**
   * @description Opens the copy security profile dialog.
   * @param index The index position of the item which should be copied.
   */
  handleOnCopySecurityProfile = (index) => {
    // get the profilemask from redux
    const profilemask = this.props.securityProfiles.data[index][0]
    const betauser = this.props.securityProfiles.data[index][1]

    // declare success callback
    const callback = () => { this.setState({ showCopySecurityProfileDialog: true }) }

    // get the specific security profile
    this.props.getSecurityProfile(profilemask, betauser, callback)
  }

  /**
   * @description Deletes the current security profile.
   */
  deleteSecurityProfile = () => {
    const profilemask = this.props.securityProfile.PROFILE
    const betauser = this.props.securityProfile.BETAUSER

    // declare success callback
    const callback = () => this.setState({ showDeleteSecurityProfileDialog: false })

    this.props.deleteSecurityProfile(profilemask, betauser, callback)
  }

  /**
   * @description gets the index of the header in redux state securityProfiles.header
   * @param {String} header header name of the header in redux state securityProfiles.header
   */
  headerData = header => this.props.securityProfiles.header.indexOf(header)

  /**
   * @description Gets the data for the table.
   */
  getData = () => {
    const { datemask, securityProfiles } = this.props
    let data = []
    let headers = this.getUsedHeader()
    securityProfiles.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === ACCESS) {
          let access
          switch (element[this.headerData(ACCESS)]) {
            case 'READ':
              access = translate('security.access_read')
              break
            case 'ALTER':
              access = translate('security.access_alter')
              break
            case 'UPDATE':
              access = translate('security.access_update')
              break
            case 'NONE':
              access = translate('security.access_none')
              break
            default:
              // Should not happen
              access = translate('security.access_none')
          }
          dataBuffer.push(access)
        }
        else if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData(CDATE)], element[this.headerData(CTIME)].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }


  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = (rowIndex) => {
    const { id, lang } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconName='edit'
        title={translate('general.edit', lang)}
        onClick={() => this.handleOnModifySecurityProfile(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconName='delete'
        title={translate('general.delete', lang)}
        onClick={() => this.handleOnDeleteSecurityProfile(rowIndex)}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options', lang)}>
        <TableButtonGroupItem
          onClick={() => this.handleOnModifySecurityProfile(rowIndex)}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit', lang)}
          title={translate('general.edit', lang)}
        />
        <TableButtonGroupItem
          onClick={() => { this.handleOnCopySecurityProfile(rowIndex) }}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy', lang)}
          title={translate('general.copy', lang)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleOnDeleteSecurityProfile(rowIndex)}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete', lang)}
          title={translate('general.delete', lang)}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create', lang)}
          onClick={this.handleOnAddEntry}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleOnRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings', lang)}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SECURITYPROFILES]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_SECURITYPROFILES].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SECURITYPROFILES]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_SECURITYPROFILES].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  /**
   * @description Renderes the delete dialog.
   */
  renderDeleteDialog = () => {
    const { id, securityProfile } = this.props
    let profilemask = ''
    let profileentry = ''

    if (securityProfile.PROFILE) {
      const entryAndParts = SecurityFunctions.getProfilentryAndParts(securityProfile.PROFILE.split('.')[0])

      // if there is no translationkey for the prefix, set the entry to free text and use the complete text as profilemask
      if (entryAndParts.translationKey === undefined) {
        profileentry = SecurityFunctions.FREE
        profilemask = securityProfile.PROFILE.split('.')[0]
      } else {
        profileentry = entryAndParts.translationKey
        profilemask = securityProfile.PROFILE.split('.').slice(1).join('.')
      }
    }

    return (
      <DeleteDialog
        id={`${id}_deletesecuritydialog`}
        title={translate('security.delete_security')}
        question={translate('security.question_delete_security')}
        visible={this.state.showDeleteSecurityProfileDialog}
        onClose={() => { this.setState({ showDeleteSecurityProfileDialog: false }) }}
        onDelete={() => this.deleteSecurityProfile()}
      >
        <Row
          id={`${id}_body_profileentry_row`}>
          <Column
            id={`${id}_body_profileentry_row_titlescol`}
            colMD={3}
            offsetMD={0}>
            <p
              id={`${id}_body_profileentry_row_titlescol_profileentry`}>
              {`${translate('security.profile_entry')}:`}
            </p>
          </Column>
          <Column
            id={`${id}_body_profileentry_row_valuescol`}
            colMD={9}
            offsetMD={0}>
            <p
              id={`${id}_body_profileentry_row_valuescol_profileentry`}>
              {
                translate(profileentry)
              }
            </p>
          </Column>
        </Row>
        <Row
          id={`${id}_body_profilemask_row`}>
          <Column
            id={`${id}_body_profilemask_row_titlescol`}
            colMD={3}
            offsetMD={0}>
            <p
              id={`${id}_body_profilemask_row_titlescol_profilemask`}>
              {translate('security.profile_mask')}:
            </p>
          </Column>
          <Column
            id={`${id}_body_profilemask_row_valuescol`}
            colMD={9}
            offsetMD={0}>
            <p
              id={`${id}_body_profilemask_row_valuescol_profilemask`}>
              {profilemask}
            </p>
          </Column>
        </Row>
        <Row
          id={`${id}_body_user_group_row`}>
          <Column
            id={`${id}_body_user_group_row_titlescol`}
            colMD={3}
            offsetMD={0}>
            <p
              id={`${id}_body_user_group_row_titlescol_user_group`}>
              {`${translate('security.user_group')}:`}
            </p>
          </Column>
          <Column
            id={`${id}_body_user_group_row_valuescol`}
            colMD={9}
            offsetMD={0}>
            <p
              id={`${id}_body_user_group_row_valuescol_user_group`}>
              {
                securityProfile.BETAUSER
              }
            </p>
          </Column>
        </Row>
      </DeleteDialog>
    )
  }

  render = () => {
    const { securityProfiles, id, loading, drawerExpanded, securityProfile, lang, autoDrawer, datemask, keepPagination } = this.props
    const { showCreateSecurityProfileDialog, showModifySecurityProfileDialog, showCopySecurityProfileDialog, showDeleteSecurityProfileDialog, showTableSettingsDialog } = this.state
    let data = securityProfiles && securityProfiles.data ? this.getData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <Fragment>
        {/* create security profile dialog */}
        {showCreateSecurityProfileDialog && (
          <CreateSecurityProfileDialog
            id={`${id}_createsecurityprofiledialog`}
            visible={showCreateSecurityProfileDialog}
            onClose={() => { this.setState({ showCreateSecurityProfileDialog: false }) }}
          />
        )}
        {/* modify security profile dialog */}
        {showModifySecurityProfileDialog && securityProfile && (
          <ModifySecurityProfileDialog
            id={id}
            visible={showModifySecurityProfileDialog}
            onClose={() => { this.setState({ showModifySecurityProfileDialog: false }) }}
          />
        )}
        {/* copy security profile dialog */}
        {showCopySecurityProfileDialog && securityProfile && (
          <CopySecurityProfileDialog
            id={`${id}_copysecurityprofiledialog`}
            visible={showCopySecurityProfileDialog}
            onClose={() => { this.setState({ showCopySecurityProfileDialog: false }) }}
          />
        )}
        {/* delete security profile dialog */}
        {showDeleteSecurityProfileDialog && securityProfile && (
          this.renderDeleteDialog()
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_SECURITYPROFILES }}
          />
        )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          { // show nosearch if security profile not exist in redux
            securityProfiles
              ? (
                // show empty result if there are no security profiles after searching
                data
                  ? (
                    <DataTable
                      loading={loading}
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={data}
                      selectable={true}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={this.handleOnModifySecurityProfile}
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptyResult
                      description={translate('emptyresult.no_security_profiles_result')}
                      id={`${id}_emptyresult`}
                      link={translate('emptyresult.create_security_profile_link')}
                      onClick={this.handleOnAddEntry}
                      headline={translate('emptyresult.no_result_headline')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )
          }
        </ResultContainer>
      </Fragment>
    )
  }
}

const PROFILEMASK = 'PROFILE'
const USER_GROUP_ID = 'BETAUSER'
const ACCESS = 'ACCESS'
const DESCRIPTION = 'PRODESC'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

SearchResultSecurityProfiles.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    securityProfiles: state.security.securityProfiles,
    securityProfile: state.security.securityProfile,
    keepPagination: state.security.keepPagination,
    usertoken: state.auth.serverdata.token,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getSecurityProfiles: (profilemask, betauser) => {
      SecurityProfileActions.getSecurityProfiles(profilemask, betauser)(dispatch)
    },
    getSecurityProfile: (profilemask, betauser, callback) => {
      SecurityProfileActions.getSecurityProfile(profilemask, betauser, callback)(dispatch)
    },
    deleteSecurityProfile: (profilemask, betauser, callback) => {
      SecurityProfileActions.deleteSecurityProfile(profilemask, betauser, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultSecurityProfiles)