import { translate } from 'language/Language'
import { Component } from 'react'

import { Button, MetaDataGrid, Modal as ModalComponent } from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'

import { getDate } from 'utils/DateUtils'

import './ZosJobLogInformation.scss'

class ZosJobLogInformation extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    zosJobLogInformation: PropTypes.any,
    onClose: PropTypes.func
  }

  /**
   * @description Renders the header
   */
  renderHeader = () => {
    const { id, zosJobLogInformation, datemask } = this.props

    return (
      <div id='zosJobLogInformation'>
        <MetaDataGrid
          id={`${id}_header`}
          metaData={[
            { label: translate('general.jobname'), value: zosJobLogInformation.JOBNAME },
            zosJobLogInformation.JOBID && { label: translate('job.jobid'), value: zosJobLogInformation.JOBID },
            { label: translate('general.jobgroup'), value: zosJobLogInformation.JOBGROUP },
            zosJobLogInformation.SRCCLAS && { label: translate('job.messageclass'), value: zosJobLogInformation.SRCCLAS },
            { label: translate('job.submit_date'), value: `${getDate(datemask, zosJobLogInformation.SRCSUBD)} ${zosJobLogInformation.SRCSUBT}` },
            { label: translate('job.start_date'), value: `${getDate(datemask, zosJobLogInformation.STRTDATE)} ${zosJobLogInformation.STRTTIME}` },
            { label: translate('job.end_date'), value: `${getDate(datemask, zosJobLogInformation.ENDDATE)} ${zosJobLogInformation.ENDTIME}` },
            { label: translate('job.return_code'), value: zosJobLogInformation.JOBRC },
            zosJobLogInformation.DSID && { label: translate('job.dsid'), value: zosJobLogInformation.DSID?.toString() },
            { label: translate('job.dd_name'), value: zosJobLogInformation.SRCDDND },
            zosJobLogInformation.SRCSTPD && { label: translate('job.step_name'), value: zosJobLogInformation.SRCSTPD },
            zosJobLogInformation.SRCPRCD && { label: translate('job.proc_step'), value: zosJobLogInformation.SRCPRCD },
            { label: translate('documentinformation.header_documentid'), value: zosJobLogInformation.DOCID },
            { label: translate('documentinformation.retentiontab_online'), value: zosJobLogInformation.LGRONLNE, yesNo: true },
            { label: translate('documentinformation.onlineretention'), value: zosJobLogInformation.ONLRETPD?.toString() },
            { label: translate('general.archive'), value: zosJobLogInformation.LGRARCH, yesNo: true },
            { label: translate('general.marked_archive'), value: zosJobLogInformation.LGRFFARC, yesNo: true },
            { label: translate('general.marked_delete'), value: zosJobLogInformation.LGRFFDEL, yesNo: true },
            { label: translate('general.marked_reload'), value: zosJobLogInformation.LGRFFREL, yesNo: true },
            { label: translate('general.archive_media'), value: zosJobLogInformation.ARCHMED},
            { label: (zosJobLogInformation.CTYPE === 'DAY' ? translate('general.retention_period_days') : translate('general.retention_period')), value: zosJobLogInformation.ARCRETPD?.toString() },
            { label: translate('documentinformation.header_encoding'), value: zosJobLogInformation.TEXTENCO },
            { label: translate('general.ascii_ebcdic'), value: zosJobLogInformation.LGRASCII ? (zosJobLogInformation.LGRASCII === true || zosJobLogInformation.LGRASCII === 'YES') && zosJobLogInformation.TEXTENCO === 'UTF-8' ? 'ASCII' : 'EBCDIC' : '' },
          ]}
          columns={4}
        />
      </div>
    )
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={'zos_job_log_information_dialog'}
        onClose={onClose}
        className='dynamic_height'
        size={'s'}>
        <Header
          id={`${id}_modalHeader`}
          title={translate('search.zos_job_information')}
          onClose={onClose}>
        </Header>
        <Main
          flexColumn>
          {this.renderHeader()}
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}


const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK]
  }
}

export default connect(mapStateToProps)(ZosJobLogInformation)