import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'

export const GET_LPD_QUEUES_START = 'GET_LPD_QUEUES_START'
export const GET_LPD_QUEUES_SUCCESS = 'GET_LPD_QUEUES_SUCCESS'
export const GET_LPD_QUEUES_FAILED = 'GET_LPD_QUEUES_FAILED'
export const NO_LPD_QUEUES_FOUND = 'NO_LPD_QUEUES_FOUND'

export const LPD_QUEUE_COMMAND_START = 'LPD_QUEUE_COMMAND_START'
export const LPD_QUEUE_COMMAND_SUCCESS = 'LPD_QUEUE_COMMAND_SUCCESS'
export const LPD_QUEUE_COMMAND_FAILED = 'LPD_QUEUE_COMMAND_FAILED'


/**
 * @description Calls the rest api and gets a lpd queue.
 * @param {Array} fields
 * @param {Object} searchObj
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getLPDQueues(fields, searchObj, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_LPD_QUEUES_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/lpdqueue?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_LPD_QUEUES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_LPD_QUEUES_FAILED })
          }
        }
        else {
          dispatch({ type: GET_LPD_QUEUES_SUCCESS, payload: jsondata.data })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.get_lpd_queue_error', lang, [searchObj['LPD'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_LPD_QUEUES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api to run lpd queue command.
 * @param {Array} fields
 * @param {Object} searchObj
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getLPDQueueCommand(bodyParams, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: LPD_QUEUE_COMMAND_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/lpdqueue/command`, { method: 'post', body: bodyParams })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: LPD_QUEUE_COMMAND_FAILED })

        }
        else {
          dispatch({ type: LPD_QUEUE_COMMAND_SUCCESS, payload: jsondata.data })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.lpd_queue_command_error', lang, [reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LPD_QUEUE_COMMAND_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}