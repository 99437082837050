import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as UrlUtils from 'utils/UrlUtils'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Switch from 'BetaUX2Web-Components/src/components/switch/Switch'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as RecipientDefinitionActions from 'redux/actions/RecipientDefinitionActions'
import * as RecipientHierarchyActions from 'redux/actions/RecipientHierarchyActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import SelectorDialog from '../../../../../dialogs/selector_dialog/SelectorDialog';
import * as ModalSelectorActions from '../../../../../../redux/actions/ModalSelectorActions';

class DefinitionRecipient extends Component {

  defaultState = {
    activeTypeIndex: Math.max(DefinitionUtils.getRecipientTypes(true).findIndex(temp => temp.key === DefinitionUtils.RECIPIENT_TYPE_ANY), 0),
    activeDistributionViaIndex: Math.max(DefinitionUtils.getRecipientDistributions(true).findIndex(temp => temp.key === DefinitionUtils.RECIPIENT_DISTRIBUTION_ANY), 0),
    recipientid: '',
    owner: '',
    predecessorid: '',
    title: '',
    outputchannel: '',
    outputformat: '',
    postprocessingnote: '',
    displayAs: 0
  }

  keys = [
    { rest: 'RECI', ui: 'recipientid' },
    { rest: 'PREDRECI', ui: 'predecessorid' },
    { rest: 'RTYPE', ui: 'activeTypeIndex' },
    { rest: 'OWNER', ui: 'owner' },
    { rest: 'BRWS', ui: 'activeDistributionViaIndex' },
    { rest: 'TITLE', ui: 'title' },
    { rest: 'DCR', ui: 'outputformat' },
    { rest: 'PDC', ui: 'outputchannel' },
    { rest: 'PPN', ui: 'postprocessingnote' },
    { rest: 'DISPLAYAS', ui: 'displayAs' }
  ]

  state = {
    ...this.defaultState,
    showCreateRecipientDialog: false,
    showModifyRecipientDialog: false,
    showCopyRecipientDialog: false,
    showDeleteRecipientDialog: false,
    showOutputChanelDefinitionsSelectorDialog: false,
    showOutputFormatDefinitionsSelectorDialog: false,
    showPostProcessingNoteDefinitionsSelectorDialog: false
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    if (window.location.href.indexOf('?') !== -1) {
      this.initFieldsFromUrl()
    } else {
      this.initFieldsFromPreferences()
    }
  }


  initFieldsFromUrl = () => {
    const newValues = UrlUtils.getDataFromUrlParams(this.props.location.search, this.keys)
    this.setState({
      ...this.state,
      activeTypeIndex: newValues.activeTypeIndex ? parseInt(newValues.activeTypeIndex) : 0,
      activeDistributionViaIndex: newValues.activeDistributionViaIndex ? parseInt(newValues.activeDistributionViaIndex) : 0,
      recipientid: newValues.recipientid || '',
      owner: newValues.owner || '',
      predecessorid: newValues.predecessorid || '',
      title: newValues.title || '',
      outputchannel: newValues.outputchannel || '',
      outputformat: newValues.outputformat || '',
      postprocessingnote: newValues.postprocessingnote || '',
      displayAs: newValues.displayAs ? parseInt(newValues.displayAs) : 0
    }, () => {
      if (UrlUtils.urlContainsExecute()) {
        this.search()
      }
    })
  }

  /**
 * @description Initializes the import fields with the values saved in preferences.
 */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props

    if (preferences) {
      const id = preferences[Preferences.DEFINITION_RECI_ID] || ''
      const type = preferences[Preferences.DEFINITION_RECI_TYPE] || DefinitionUtils.RECIPIENT_TYPE_ANY
      const owner = preferences[Preferences.DEFINITION_RECI_OWNER] || ''
      const title = preferences[Preferences.DEFINITION_RECI_TITLE] || ''
      const distributionvia = preferences[Preferences.DEFINITION_RECI_DISTRIBUTVIA] || DefinitionUtils.RECIPIENT_DISTRIBUTION_ANY
      const outputformat = preferences[Preferences.DEFINITION_RECI_OUTPUTFORMAT] || ''
      const predecessorid = preferences[Preferences.DEFINITION_RECI_PREDECESSORID] || ''
      const outputchannel = preferences[Preferences.DEFINITION_RECI_OUTPUTCHANNEL] || ''
      const postprocessingnote = preferences[Preferences.DEFINITION_RECI_POSTPROCESSNOTE] || ''

      const displayAsIndex = Math.max(DefinitionUtils.RECIPIENT_DISPLAY_AS.findIndex(el => el.key === preferences[Preferences.DEFINITION_RECI_DISPLAY_AS]), 0)
      const displayAs = displayAsIndex !== -1 ? displayAsIndex : 0

      const activeTypeIndex = Math.max(DefinitionUtils.getRecipientTypes(true).findIndex(temp => temp.key === type), 0)
      const activeDistributionViaIndex = Math.max(DefinitionUtils.getRecipientDistributions(true).findIndex(temp => temp.key === distributionvia), 0)

      this.setState({
        activeTypeIndex: activeTypeIndex,
        activeDistributionViaIndex: activeDistributionViaIndex,
        recipientid: id,
        owner,
        predecessorid,
        title,
        outputchannel,
        outputformat,
        postprocessingnote,
        displayAs
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   */
  handleInputChanged = (id, value,) => {
    this.setState({ [id]: value })
  }

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = (event) => {
    event.preventDefault()
    this.search()
  }

  search = () => {
    const {
      activeTypeIndex,
      activeDistributionViaIndex,
      recipientid,
      owner,
      title,
      outputformat,
      predecessorid,
      outputchannel,
      postprocessingnote,
      displayAs
    } = this.state

    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_RECI_ID]: recipientid,
      [Preferences.DEFINITION_RECI_TYPE]: DefinitionUtils.getRecipientTypes(true)[activeTypeIndex].key,
      [Preferences.DEFINITION_RECI_OWNER]: owner,
      [Preferences.DEFINITION_RECI_TITLE]: title,
      [Preferences.DEFINITION_RECI_DISTRIBUTVIA]: DefinitionUtils.getRecipientDistributions(true)[activeDistributionViaIndex].key,
      [Preferences.DEFINITION_RECI_OUTPUTFORMAT]: outputformat,
      [Preferences.DEFINITION_RECI_PREDECESSORID]: predecessorid,
      [Preferences.DEFINITION_RECI_OUTPUTCHANNEL]: outputchannel,
      [Preferences.DEFINITION_RECI_POSTPROCESSNOTE]: postprocessingnote,
      [Preferences.DEFINITION_RECI_DISPLAY_AS]: DefinitionUtils.RECIPIENT_DISPLAY_AS[displayAs].key
    }

    this.props.changePrefs(prefsToChange)
    if (displayAs === 0) {
      this.props.getRecipients(
        undefined,
        DefinitionUtils.getRecipientTypes(true)[activeTypeIndex].key,
        DefinitionUtils.getRecipientDistributions(true)[activeDistributionViaIndex].key,
        recipientid,
        predecessorid,
        owner,
        title,
        outputchannel,
        outputformat,
        postprocessingnote,
        () => this.props.updateHierarchyEntriesData(undefined, false)
      )
    } else {
      this.props.getRecipientsHierarchy(
        undefined,
        DefinitionUtils.getRecipientTypes(true)[activeTypeIndex].key,
        DefinitionUtils.getRecipientDistributions(true)[activeDistributionViaIndex].key,
        recipientid,
        predecessorid,
        owner,
        title,
        outputchannel,
        outputformat,
        postprocessingnote,
        () => this.props.updateHierarchyEntriesData(undefined)
      )
    }

    // builds the parameter object which is used for url
    const urlToPush = `/definition/recipient${UrlUtils.createUrlParamsFromObject(this.state, this.keys)}`
    this.props.history.push(urlToPush)
  }


  /**
   * @description Resets the current values to the default values.
   */
  handleOnReset = () => {
    this.setState(this.defaultState)
  }


  /**
   * @description Gets the translated recipient types.
   */
  getTranslatedTypeItems = () => {
    const items = []
    DefinitionUtils.getRecipientTypes(true).forEach(typeEntry => {
      items.push(translate(typeEntry.translationKey))
    })
    return items
  }


  /**
   * @description Gets the translated distribution via options.
   */
  getTranslatedDistributionItems = () => {
    const items = []
    DefinitionUtils.getRecipientDistributions(true).forEach(distributionItem => {
      items.push(translate(distributionItem.translationKey))
    })
    return items
  }

  onOpenOutputChanelDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showOutputChanelDefinitionsSelectorDialog: true })
    }

    this.props.getOutputChanelDefinitions(
      ['DCR', 'DCRTITLE'],
      this.state.outputchannel.value,
      callback)
  }

  onOpenOutputFormatDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showOutputFormatDefinitionsSelectorDialog: true })
    }

    this.props.getOutputFormatDefinitions(
      ['PCR', 'PCRTITLE'],
      this.state.outputchannel.value,
      callback)
  }

  /**
   * @description Requests the postprocessingnote definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenPostProcessingNoteDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showPostProcessingNoteDefinitionsSelectorDialog: true })
    }

    this.props.getPostProcessingNotesDefinitions(
      ['PPN', 'PPNTITLE'],
      this.state.postprocessingnote,
      callback)
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const {
      recipientid,
      predecessorid,
      owner,
      title,
      activeTypeIndex,
      activeDistributionViaIndex
    } = this.state

    const typeItems = this.getTranslatedTypeItems()
    const distributionItems = this.getTranslatedDistributionItems()

    return (
      <Card
        title={translate('general.general')}>
        {/* id */}
        <Row>
          <Column
            colMD={12}
            offsetMD={0}>
            <Input
              id={`${id}_recipientid`}
              onInputChanged={val => { this.handleInputChanged('recipientid', val) }}
              value={recipientid}
              title={translate('recipient.reci_id')}
              maxLength={16}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            <Dropdown
              id={`${id}_type`}
              items={typeItems}
              activeIndex={activeTypeIndex}
              onChange={(activeTypeIndex) => { this.setState({ activeTypeIndex }) }}
              title={translate('recipient.type')}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            <Input
              id={`${id}_owner`}
              onInputChanged={val => { this.handleInputChanged('owner', val) }}
              value={owner}
              title={translate('recipient.owner')}
              maxLength={8}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={12}>
            <Input
              id={`${id}_predecessorid`}
              onInputChanged={val => { this.handleInputChanged('predecessorid', val) }}
              value={predecessorid}
              title={translate('recipient.predecessor_id')}
              maxLength={16}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={12}>
            <Dropdown
              id={`${id}_distributionvia`}
              items={distributionItems}
              activeIndex={activeDistributionViaIndex}
              onChange={activeDistributionViaIndex => { this.setState({ activeDistributionViaIndex }) }}
              title={translate('recipient.distribution_via')}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={12}>
            <Input
              id={`${id}_title`}
              onInputChanged={val => { this.handleInputChanged('title', val) }}
              value={title}
              title={translate('general.title')}
              maxLength={80}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the assignment card.
   */
  renderAdditionalCard = () => {
    const { id } = this.props
    const { outputchannel, outputformat, postprocessingnote } = this.state
    return (
      <Card
        title={translate('recipient.additional')}>
        {/* Output channel */}
        <Row>
          <Column
            colMD={12}>
            <Input
              id={`${id}_outputchannel`}
              onInputChanged={val => { this.handleInputChanged('outputchannel', val) }}
              value={outputchannel}
              title={translate('recipient.output_channel')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputChanelDefinitionDialog()
              }}
            />
          </Column>
        </Row>
        {/* Output format */}
        <Row>
          <Column
            colMD={12}>
            <Input
              id={`${id}_outputformat`}
              onInputChanged={val => { this.handleInputChanged('outputformat', val) }}
              value={outputformat}
              title={translate('recipient.output_format')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputFormatDefinitionDialog()
              }}
            />
          </Column>
        </Row>
        {/* Postprocessing note */}
        <Row>
          <Column
            colMD={12}>
            <Input
              id={`${id}_postprocessingnote`}
              onInputChanged={val => { this.handleInputChanged('postprocessingnote', val) }}
              value={postprocessingnote}
              title={translate('recipient.postprocessing_note')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenPostProcessingNoteDefinitionDialog()
              }}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  renderVisualizationCard = () => {
    const { id } = this.props
    return (
      <Card
        title={translate('general.visualization')}
      >
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_visualization`}
              title={translate('general.display_as')}
              items={[
                translate('general.table'),
                translate('general.hierarchy')
              ]}
              onClick={index => this.handleInputChanged('displayAs', index)}
              activeIndex={this.state.displayAs}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderGeneralCard()}
        {this.renderAdditionalCard()}
        {this.renderVisualizationCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleOnReset}
        />
      </div>
    )
  }

  renderSelectorDialogs = () => {
    const { id } = this.props

    let outputChanelDefinitions
    if (this.state.showOutputChanelDefinitionsSelectorDialog) {
      outputChanelDefinitions = this.props.selector.outputchannels
    }

    let outputFormatDefinitions
    if (this.state.showOutputFormatDefinitionsSelectorDialog) {
      outputFormatDefinitions = this.props.selector.outputformats
    }

    // postprocessingnote definitions
    let postProcessingNoteDefinitions
    if (this.state.showPostProcessingNoteDefinitionsSelectorDialog) {
      postProcessingNoteDefinitions = this.props.selector.ppns
    }

    return (
      <>
        {this.state.showOutputChanelDefinitionsSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputChanel_definitions`}
            onClose={() => { this.setState({ showOutputChanelDefinitionsSelectorDialog: false }) }}
            title={translate('definition.output_channel')}
            header={[
              translate('general.output_channel'),
              translate('general.title'),
            ]}
            items={outputChanelDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newFolder = outputChanelDefinitions.data[selectedRows][outputChanelDefinitions.header.indexOf('DCR')]
                this.setState({
                  outputchannel: newFolder,
                  showOutputChanelDefinitionsSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputChanelDefinitionsSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showOutputFormatDefinitionsSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputFormat_definitions`}
            onClose={() => { this.setState({ showOutputFormatDefinitionsSelectorDialog: false }) }}
            title={translate('definition.output_format.title')}
            header={[
              translate('definition.output_format'),
              translate('general.title'),
            ]}
            items={outputFormatDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newFolder = outputFormatDefinitions.data[selectedRows][outputFormatDefinitions.header.indexOf('PCR')]
                this.setState({
                  outputformat: newFolder,
                  showOutputFormatDefinitionsSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputFormatDefinitionsSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showPostProcessingNoteDefinitionsSelectorDialog && (
          <SelectorDialog
            id={`${id}_postprocessingnoteselector_dialog`}
            onClose={() => {
              this.setState({ showPostProcessingNoteDefinitionsSelectorDialog: false })
            }}
            title={translate('definition.post_processing_note_definition')}
            header={[
              translate('definition.postprocessing_note'),
              translate('general.title')
            ]}
            items={postProcessingNoteDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newPostprocessingNode = postProcessingNoteDefinitions.data[selectedRows][postProcessingNoteDefinitions.header.indexOf('PPN')]
                this.setState({
                  postprocessingnote: newPostprocessingNode,
                  showPostProcessingNoteDefinitionsSelectorDialog: false
                })
              } else {
                this.setState({ showPostProcessingNoteDefinitionsSelectorDialog: false })
              }
            }}
          />
        )}

      </>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {this.renderMain()}
        {this.renderFooter()}
        {this.renderSelectorDialogs()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    selector: state.selector,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRecipients: (fields, type, distributionType, recipientid, predecessor, owner, title, outputchannel, outputformat, postprocessingnote, callback) => {
      RecipientDefinitionActions.getRecipients(
        fields,
        type,
        distributionType,
        recipientid,
        predecessor,
        owner,
        title,
        outputchannel,
        outputformat,
        postprocessingnote,
        callback
      )(dispatch)
    },
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getRecipientsHierarchy: (fields, rtype, distributionType, recipient, preRecipient, owner, title, outputchannel, outputformat, ppn, callback) => {
      RecipientHierarchyActions.getRecipientsHierarchy(
        fields,
        rtype,
        distributionType,
        recipient,
        preRecipient,
        owner,
        title,
        outputchannel,
        outputformat,
        ppn,
        callback
      )(dispatch)
    },
    updateHierarchyEntriesData: (newHierarchyData, reload) => {
      RecipientHierarchyActions.updateHierarchyEntriesData(newHierarchyData, reload)(dispatch)
    },
    getOutputChanelDefinitions: (fields, outputChanel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChanel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback
      )(dispatch)
    },
    getOutputFormatDefinitions: (fields, outputFormat, callback) => {
      ModalSelectorActions.getOutputFormatDefinitions(
        fields,
        outputFormat,
        undefined,
        undefined,
        callback
      )(dispatch)
    },
    getPostProcessingNotesDefinitions: (fields, ppn, callback) => {
      ModalSelectorActions.getPPNDefinitions(
        fields,
        ppn,
        undefined,
        undefined,
        callback)(dispatch)
    },
  }
}

DefinitionRecipient.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionRecipient)