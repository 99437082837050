import React, { Component } from 'react'

// Utils
import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import * as Utils from 'utils/Utils'

// components
import {
  Button, Checkbox, Column, Dropdown, Icon, Input,
  MetaDataGrid,
  Modal as ModalComponent, MultilineInput, NumericSpinner, Row, Switch, Tab,
  Tabs,
  Tooltip
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import {
  getOutputChannelTypes, OUTPUTCHANNEL_APPLICATIONS, OUTPUTCHANNEL_DEVICES, OUTPUTCHANNEL_PROTOCOLS
} from 'utils/DefinitionUtils'

import * as DateUtils from 'utils/DateUtils'

// Redux
import { connect } from 'react-redux'
import { updateOutputChannel } from 'redux/actions/OutputChannelDefinitionActions'

class ModifyOutputChannelDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = this.setDefaultState()

  setDefaultState() {
    const { outputChannel } = this.props
    return {
      general: {
        outputChannelID: outputChannel['DCR'],
        alternativeID: outputChannel['DCRRQ'],
        type: getOutputChannelTypes().indexOf(getOutputChannelTypes().filter(d => d.key === outputChannel['DCRTYPE'])[0]),
        owner: outputChannel['OWNER'],
        alternativeActiv: outputChannel['DCRRQFLG'],
        title: outputChannel['DCRTITLE'],
        parametersAndValues: outputChannel['DCROUTP']
      },
      attributes: {
        createOutputRequestWithStatusHold: outputChannel['DCRHOLD'],
        allowBannerBetweenDocumentCopies: outputChannel['IPFORCEO'],
        userOutputGenerationRecordOgr: outputChannel['DCRUSOGR'],
        transferDocumentsOnly: outputChannel['DCRVODOC'],
        debugMode: outputChannel['DCRDEBUG'],
        postprocessingCommand: outputChannel['DCRECMD'],
        printControlAndBannerFilePrefix: outputChannel['PICPREFX'],
        initializationPrintControl: outputChannel['DJDE'],
        terminationPrintControl: outputChannel['DJDET'],
        ignoreDocumentsPrintControlParameters: outputChannel['DCRVSUP']
      },
      formatOptions: {
        insertCarriageReturnBeforeLineFeed: !outputChannel['DCRVICBF'],
        insertCarriageReturnBeforeEachLine: outputChannel['DCRVICBD'],
        outputInUtf8: outputChannel['DCRVFIX'],
        insertPageBreaksAfterFormatting: !outputChannel['DCRVICR'],
        tableOfContents: outputChannel['TOCFORM'],
        recipientBanner: outputChannel['LRNFORMB'],
        recipientTrailer: outputChannel['LRNFORMT'],
        documentBanner: outputChannel['LSTFORMB'],
        documentCopyBanner: outputChannel['LSTFORMC'],
        documentTrailer: outputChannel['LSTFORMT'],
        postprocessingNote: outputChannel['PPNFORM'],
        document: outputChannel['LISTFORM']
      },
      extras: {
        optionalParameters: outputChannel['DCRBLIST']
      },
      iptAttributes: {
        address: {
          value: outputChannel['DCRTYPE'] === 'IP/T' ? outputChannel['DCRIPADR'] : '',
          error: ''
        },
        port: {
          value: outputChannel['DCRTYPE'] === 'IP/T' ? outputChannel['DCRPORT'].toString() : '',
          error: ''
        },
        protocol: outputChannel['DCRTYPE'] === 'IP/T' ? OUTPUTCHANNEL_PROTOCOLS.indexOf(outputChannel['DCRPTYPE']) : 0,
        queueName: outputChannel['DCRTYPE'] === 'IP/T' ? outputChannel['DCRPLIB'] : '',
        activateSnmpSetting: outputChannel['DCRTYPE'] === 'IP/T'
          ? global.isSet(outputChannel['IPPRINT'])
          : false,
        activateAlternativeAfterRetryPeriod: outputChannel['DCRTYPE'] === 'IP/T'
          ? global.isSet(outputChannel['IPFORCEA'])
          : false,
        retryPeriodMinutes: outputChannel['DCRTYPE'] === 'IP/T' ? outputChannel['DCRVPRTY'] : 0,
        community: outputChannel['DCRTYPE'] === 'IP/T' ? outputChannel['PDICOMMU'] : '',
        activatePjlStatusReadbackUstatus: outputChannel['DCRTYPE'] === 'IP/T' && outputChannel['DCRPTYPE'] === 'PJL'
          ? outputChannel['PJLUSTJ']
          : false,
        page: outputChannel['DCRTYPE'] === 'IP/T' ? outputChannel['PJLUSTP'] : false,
        timedSeconds: outputChannel['DCRTYPE'] === 'IP/T' ? outputChannel['PJLUSTIM'] : 0,
        device: outputChannel['DCRTYPE'] === 'IP/T'
          ? { VERBOSE: 0, ON: 1, OFF: 2 }[outputChannel['PJLUSTD']]
          : 2,
        waitMinutes: outputChannel['DCRTYPE'] === 'IP/T' ? outputChannel['PJLMWAIT'] : 0,
        optionalParameters: outputChannel['DCRTYPE'] === 'IP/T' ? outputChannel['DCRCLIST'] : ''
      },
      commandAttributes: {
        provideOutputAsSeparateFilesInWorkingDirectory: outputChannel['DCRTYPE'] === 'CMD' ? outputChannel['IPFOLDER'] : false,
        skeleton: outputChannel['DCRTYPE'] === 'CMD' ? outputChannel['DCRCSKL'] : '',
        preparationCommand: outputChannel['DCRTYPE'] === 'CMD' ? outputChannel['DCRFCMD'] : '',
        outputCommand: {
          value: outputChannel['DCRTYPE'] === 'CMD' ? outputChannel['DCRCLIST'] : '',
          error: ''
        }
      },
      applicationAttributes: {
        address: {
          value: outputChannel['DCRTYPE'] === 'APPL' ? outputChannel['DCRIPADR'] : '',
          error: ''
        },
        port: {
          value: outputChannel['DCRTYPE'] === 'APPL' ? outputChannel['DCRPORT'] : '',
          error: ''
        },
        application: {
          value: outputChannel['DCRTYPE'] === 'APPL'
            ? OUTPUTCHANNEL_APPLICATIONS.indexOf(outputChannel['DCRAPPL'])
            : 0,
          error: OUTPUTCHANNEL_APPLICATIONS.indexOf(outputChannel['DCRAPPL']) === -1 ? 'queue.deprecated_value_ouputqueue_application' : ''
        },
        queueName: outputChannel['DCRTYPE'] === 'APPL' ? outputChannel['DCRPLIB'] : '',
        optionalParameters: outputChannel['DCRTYPE'] === 'APPL' ? outputChannel['DCRCLIST'] : ''
      }
    }
  }

  addressIptInput = React.createRef()
  portIptInput = React.createRef()
  addressApplicationInput = React.createRef()
  portApplicationInput = React.createRef()
  outputCommandInput = React.createRef()

  // componentDidMount = () => {
  //   // Just needed to validate the dropdown "Application" in "Application"-Tab because due to migration-data
  //   // a might deprecated value could be loaded and a different ones needs to be selected manually
  //   this.validateApplicationTab()
  // }

  handleChange = ({ tab, key, value }) => {
    this.setState(state => ({
      [tab]: {
        ...state[tab],
        [key]: typeof state[tab][key] === 'object'
          ? { value: value, error: '' }
          : value
      }
    }), () => {
      // make sure the values is resetted when the dropdown value is changed and the checkbox was checked before.
      if (key === 'protocol' && this.state.iptAttributes.protocol !== 2) {
        this.setState({
          iptAttributes: {
            ...this.state.iptAttributes,
            activatePjlStatusReadbackUstatus: false
          }
        })
      }
    })
  }

  /**
   * @description Validates the address in ip/t
   */
  validateAddressIPT = () => {
    const { iptAttributes } = this.state
    if (iptAttributes.address.value !== '') {
      return {}
    }
    return {
      address: {
        ...this.state.iptAttributes.address,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the port in ip/t
   */
  validatePortIPT = () => {
    const { iptAttributes } = this.state
    if (iptAttributes.port.value !== '') {
      return {}
    }
    return {
      port: {
        ...this.state.iptAttributes.port,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateIptTab = () => {
    const validatorResult = {
      ...this.validateAddressIPT(),
      ...this.validatePortIPT()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ iptAttributes: { ...this.state.iptAttributes, ...validatorResult } }, () => {
        this.handleIptFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input of the ipt tab which has an error.
   */
  handleIptFocus = () => {
    const { iptAttributes } = this.state
    const requiredInputs = [
      { inputRef: this.addressIptInput, error: iptAttributes.address.error },
      { inputRef: this.portIptInput, error: iptAttributes.port.error }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Validates the address in application tab
   */
  validateAddressApplication = () => {
    const { applicationAttributes } = this.state
    if (applicationAttributes.address.value !== '') {
      return {}
    }
    return {
      address: {
        ...this.state.applicationAttributes.address,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the application dropdown in application tab
   * A error might occure here, when a unexpected value form a older version,
   * migration or invalid database update comes in
   */
  validateApplicationTabApplication = () => {
    const { applicationAttributes } = this.state
    if (applicationAttributes.application.value !== -1) {
      return {}
    }
    return {
      application: {
        ...this.state.applicationAttributes.application,
        error: 'queue.deprecated_value_ouputqueue_application'
      }
    }
  }

  /**
   * @description Validates the port in application tab
   */
  validatePortApplication = () => {
    const { applicationAttributes } = this.state
    if (applicationAttributes.port.value !== '') {
      return {}
    }
    return {
      port: {
        ...this.state.applicationAttributes.port,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateApplicationTab = () => {
    const validatorResult = {
      ...this.validateAddressApplication(),
      ...this.validatePortApplication(),
      ...this.validateApplicationTabApplication()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ applicationAttributes: { ...this.state.applicationAttributes, ...validatorResult } }, () => {
        this.handleApplicationFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input of the application tab which has an error.
   */
  handleApplicationFocus = () => {
    const { applicationAttributes } = this.state
    const requiredInputs = [
      { inputRef: this.addressApplicationInput, error: applicationAttributes.address.error },
      { inputRef: this.portApplicationInput, error: applicationAttributes.port.error }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Validates the output command in command tab
   */
  validateOutputCommand = () => {
    const { commandAttributes } = this.state
    if (commandAttributes.outputCommand.value !== '') {
      return {}
    }
    return {
      outputCommand: {
        ...this.state.commandAttributes.outputCommand,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the command tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateCommandTab = () => {
    const validatorResult = {
      ...this.validateOutputCommand()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ commandAttributes: { ...this.state.commandAttributes, ...validatorResult } }, () => {
        this.outputCommandInput.current && this.outputCommandInput.current.focus()
      })
    }
    return errors === 0
  }

  /**
   * @description Gets the tabs that are active based on type index
   * @returns {Array}
   */
  errorTabs = () => {
    const { general: { type } } = this.state
    const tabs = []
    if (type === 0) {
      tabs.push(this.validateIptTab())
    } else if (type === 1) {
      tabs.push(this.validateCommandTab())
    } else if (type === 2) {
      tabs.push(this.validateApplicationTab())
    }
    return tabs
  }

  handleSave = () => {
    const { updateOutputChannel, onClose } = this.props
    const { general, attributes, formatOptions, extras, iptAttributes, commandAttributes, applicationAttributes } = this.state

    if (this.errorTabs().every(d => d)) {
      const data = {
        DCR: general.outputChannelID,
        DCRRQ: general.alternativeID,
        DCRTYPE: getOutputChannelTypes()[general.type].key,
        OWNER: general.owner,
        DCRTITLE: general.title,
        DCRRQFLG: general.alternativeActiv,
        DCROUTP: Utils.replaceAll(general.parametersAndValues, '\n', ''),
        DCRHOLD: attributes.createOutputRequestWithStatusHold,
        IPFORCEO: attributes.allowBannerBetweenDocumentCopies,
        DCRUSOGR: attributes.userOutputGenerationRecordOgr,
        DCRVODOC: attributes.transferDocumentsOnly,
        DCRDEBUG: attributes.debugMode,
        DCRECMD: Utils.replaceAll(attributes.postprocessingCommand, '\n', ''),
        PICPREFX: attributes.printControlAndBannerFilePrefix,
        DJDE: attributes.initializationPrintControl,
        DJDET: attributes.terminationPrintControl,
        DCRVSUP: attributes.ignoreDocumentsPrintControlParameters,
        DCRVICBF: !formatOptions.insertCarriageReturnBeforeLineFeed,
        DCRVICBD: formatOptions.insertCarriageReturnBeforeEachLine,
        DCRVFIX: formatOptions.outputInUtf8,
        DCRVICR: !formatOptions.insertPageBreaksAfterFormatting,
        TOCFORM: Utils.replaceAll(formatOptions.tableOfContents, '\n', ''),
        LRNFORMB: Utils.replaceAll(formatOptions.recipientBanner, '\n', ''),
        LRNFORMT: Utils.replaceAll(formatOptions.recipientTrailer, '\n', ''),
        LSTFORMB: Utils.replaceAll(formatOptions.documentBanner, '\n', ''),
        LSTFORMC: Utils.replaceAll(formatOptions.documentCopyBanner, '\n', ''),
        LSTFORMT: Utils.replaceAll(formatOptions.documentTrailer, '\n', ''),
        PPNFORM: Utils.replaceAll(formatOptions.postprocessingNote, '\n', ''),
        LISTFORM: Utils.replaceAll(formatOptions.document, '\n', ''),
        DCRBLIST: Utils.replaceAll(extras.optionalParameters, '\n', ''),
        DCRIPADR: { 1: '', 0: iptAttributes.address.value, 2: applicationAttributes.address.value }[general.type],
        DCRPORT: { 0: iptAttributes.port.value, 2: applicationAttributes.port.value }[general.type],
        DCRPTYPE: OUTPUTCHANNEL_PROTOCOLS[iptAttributes.protocol],
        IPPRINT: iptAttributes.activateSnmpSetting,
        IPFORCEA: iptAttributes.activateAlternativeAfterRetryPeriod,
        DCRVPRTY: iptAttributes.retryPeriodMinutes,
        PDICOMMU: iptAttributes.community,
        PJLUSTJ: iptAttributes.activatePjlStatusReadbackUstatus,
        PJLUSTP: iptAttributes.page,
        PJLUSTIM: iptAttributes.timedSeconds,
        PJLUSTD: OUTPUTCHANNEL_DEVICES[iptAttributes.device],
        PJLMWAIT: iptAttributes.waitMinutes,
        IPFOLDER: commandAttributes.provideOutputAsSeparateFilesInWorkingDirectory,
        DCRCSKL: commandAttributes.skeleton,
        DCRFCMD: Utils.replaceAll(commandAttributes.preparationCommand, '\n', ''),
        DCRCLIST: {
          0: Utils.replaceAll(iptAttributes.optionalParameters, '\n', ''),
          1: Utils.replaceAll(commandAttributes.outputCommand.value, '\n', ''),
          2: Utils.replaceAll(applicationAttributes.optionalParameters, '\n', '')
        }[general.type],
        DCRAPPL: OUTPUTCHANNEL_APPLICATIONS[applicationAttributes.application.value],
        DCRPLIB: { 1: '', 0: iptAttributes.queueName, 2: applicationAttributes.queueName }[general.type]
      }

      updateOutputChannel(data, onClose)
    }
  }

  /**
   * @description Gets the tab indices as array which has errors.
   * @returns {Array} The tab indices which has errors.
   */
  handleErrorTabs = () => {
    const { general, iptAttributes, commandAttributes, applicationAttributes } = this.state
    const result = []
    const lastTabHasError = () => {
      return {
        0: iptAttributes.address.error !== '' || iptAttributes.port.error !== '',
        1: commandAttributes.outputCommand.error !== '',
        2: applicationAttributes.address.error !== '' || applicationAttributes.port.error !== '' || applicationAttributes.application.error !== ''
      }[general.type]
    }
    if (lastTabHasError()) {
      result.push(4)
    }
    return result
  }

  render = () => {
    const { id = 'createouputchanneldialog', onClose, datemask, outputChannel } = this.props
    const { general, attributes, formatOptions, extras, iptAttributes, commandAttributes, applicationAttributes } = this.state
    return (
      <Modal
        id={`${id}`}
        className='bux_UserProfileModal'
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={translate('definition.modify_output_channel')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('definition.output_channel_id'), value: general.outputChannelID },
              { label: translate('definition.type'), value: translate(getOutputChannelTypes()[general.type].translationKey) },
              { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, outputChannel.CDATE, outputChannel.CTIME.substring(0, 8)) + ' ' + translate('general.by') + ' ' + outputChannel.CUSER },
            ]}
            columns={4}
          />
        </Header>
        <Main id={id}>
          <Tabs
            id={id}
            errorTabs={this.handleErrorTabs()}>
            <Tab title={translate('general.general')}>
              <Row>
                <Column colMD={6}>
                  <Input
                    id={`${id}_modifydialoggeneralalternativeid`}
                    title={translate('definition.alternative_id')}
                    value={general.alternativeID}
                    maxLength={16}
                    onInputChanged={value => this.handleChange({ tab: 'general', key: 'alternativeID', value: value })}
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_modifydialoggeneralowner`}
                    title={translate('definition.owner')}
                    value={general.owner}
                    maxLength={8}
                    onInputChanged={value => this.handleChange({ tab: 'general', key: 'owner', value: value })}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialoggeneralalternativeactiv`}
                    title={translate('definition.alternative_activ')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'general', key: 'alternativeActiv', value: index === 0 })}
                    activeIndex={general.alternativeActiv ? 0 : 1}
                  />
                </Column>
                <Column colMD={6}>
                  <Input
                    id={`${id}_modifydialoggeneraltitle`}
                    title={translate('definition.title')}
                    value={general.title}
                    maxLength={40}
                    onInputChanged={value => this.handleChange({ tab: 'general', key: 'title', value: value })}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <hr />
                </Column>
              </Row>
              <Row isTitle>
                <Column colMD={12}>
                  <label>{translate('definition.output_format_defaults')}</label>
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialoggeneralparametersandvalues`}
                    title={translate('definition.parameters_and_values')}
                    value={general.parametersAndValues}
                    cols={64}
                    rows={64}
                    onInputChanged={value => this.handleChange({ tab: 'general', key: 'parametersAndValues', value: value })}
                    mono
                    resizable={{ direction: 'vertical', defaultHeight: 8 }}
                  />
                </Column>
              </Row>
            </Tab>
            <Tab title={translate('definition.attributes')}>
              <Row>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialogattributescreateoutputrequestwithstatushold`}
                    title={translate('definition.create_output_request_with_status_hold')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'attributes', key: 'createOutputRequestWithStatusHold', value: index === 0 })}
                    activeIndex={attributes.createOutputRequestWithStatusHold ? 0 : 1}
                  />
                </Column>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialogattributesallowbannerbetweendocumentcopies`}
                    title={translate('definition.allow_banner_between_document_copies')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'attributes', key: 'allowBannerBetweenDocumentCopies', value: index === 0 })}
                    activeIndex={attributes.allowBannerBetweenDocumentCopies ? 0 : 1}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialogattributesuseroutputgenerationrecordogr`}
                    title={translate('definition.user_output_generation_record_ogr')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'attributes', key: 'userOutputGenerationRecordOgr', value: index === 0 })}
                    activeIndex={attributes.userOutputGenerationRecordOgr ? 0 : 1}
                  />
                </Column>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialogattributestransferdocumentsonly`}
                    title={translate('definition.transfer_documents_only')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'attributes', key: 'transferDocumentsOnly', value: index === 0 })}
                    activeIndex={attributes.transferDocumentsOnly ? 0 : 1}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialogattributesdebugmode`}
                    title={translate('definition.debug_mode')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'attributes', key: 'debugMode', value: index === 0 })}
                    activeIndex={attributes.debugMode ? 0 : 1}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <hr />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialogattributespostprocessingcommand`}
                    title={translate('definition.postprocessing_command')}
                    value={attributes.postprocessingCommand}
                    cols={64}
                    rows={8}
                    onInputChanged={value => this.handleChange({ tab: 'attributes', key: 'postprocessingCommand', value: value })}
                    mono
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <hr />
                </Column>
              </Row>
              <Row isTitle>
                <Column colMD={12}>
                  <label>{translate('definition.print_control_parameters_pic')}</label>
                </Column>
              </Row>
              <Row>
                <Column colMD={6}>
                  <Input
                    id={`${id}_modifydialogattributesprintcontrolandbannerfileprefix`}
                    title={translate('definition.print_control_and_banner_file_prefix')}
                    value={attributes.printControlAndBannerFilePrefix}
                    maxLength={8}
                    onInputChanged={value => this.handleChange({ tab: 'attributes', key: 'printControlAndBannerFilePrefix', value: value })}
                  />
                </Column>
                <Column colMD={6}>
                  <Input
                    id={`${id}_modifydialogattributesinitializationprintcontrol`}
                    title={translate('definition.initialization_print_control')}
                    value={attributes.initializationPrintControl}
                    maxLength={8}
                    onInputChanged={value => this.handleChange({ tab: 'attributes', key: 'initializationPrintControl', value: value })}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={6}>
                  <Input
                    id={`${id}_modifydialogattributesterminationprintcontrol`}
                    title={translate('definition.termination_print_control')}
                    value={attributes.terminationPrintControl}
                    maxLength={8}
                    onInputChanged={value => this.handleChange({ tab: 'attributes', key: 'terminationPrintControl', value: value })}
                  />
                </Column>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialogattributesignoredocumentsprintcontrolparameters`}
                    title={translate('definition.ignore_documents_print_control_parameters')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'attributes', key: 'ignoreDocumentsPrintControlParameters', value: index === 0 })}
                    activeIndex={attributes.ignoreDocumentsPrintControlParameters ? 0 : 1}
                  />
                </Column>
              </Row>
            </Tab>
            <Tab title={translate('definition.format_options')}>
              <Row>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialogformatoptionsinsertcarriagereturnbeforelinefeed`}
                    title={translate('definition.insert_carriage_return_before_line_feed')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'formatOptions', key: 'insertCarriageReturnBeforeLineFeed', value: index === 0 })}
                    activeIndex={formatOptions.insertCarriageReturnBeforeLineFeed ? 0 : 1}
                  />
                </Column>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialogformatoptionsinsertcarriagereturnbeforeeachline`}
                    title={translate('definition.insert_carriage_return_before_each_line')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'formatOptions', key: 'insertCarriageReturnBeforeEachLine', value: index === 0 })}
                    activeIndex={formatOptions.insertCarriageReturnBeforeEachLine ? 0 : 1}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialogformatoptionsoutputinutf8`}
                    title={translate('definition.output_in_utf8')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'formatOptions', key: 'outputInUtf8', value: index === 0 })}
                    activeIndex={formatOptions.outputInUtf8 ? 0 : 1}
                  />
                </Column>
                <Column colMD={6}>
                  <Switch
                    id={`${id}_modifydialogformatoptionsinsertpagebreaksafterformatting`}
                    title={translate('definition.insert_page_breaks_after_formatting')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleChange({ tab: 'formatOptions', key: 'insertPageBreaksAfterFormatting', value: index === 0 })}
                    activeIndex={formatOptions.insertPageBreaksAfterFormatting ? 0 : 1}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialogformatoptionstableofcontents`}
                    title={translate('definition.table_of_contents')}
                    value={formatOptions.tableOfContents}
                    cols={64}
                    rows={8}
                    onInputChanged={value => this.handleChange({ tab: 'formatOptions', key: 'tableOfContents', value: value })}
                    mono
                    resizable={{ direction: 'vertical', defaultHeight: 1 }}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialogformatoptionsrecipientbanner`}
                    title={translate('definition.recipient_banner')}
                    value={formatOptions.recipientBanner}
                    cols={64}
                    rows={8}
                    onInputChanged={value => this.handleChange({ tab: 'formatOptions', key: 'recipientBanner', value: value })}
                    mono
                    resizable={{ direction: 'vertical', defaultHeight: 1 }}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialogformatoptionsrecipienttrailer`}
                    title={translate('definition.recipient_trailer')}
                    value={formatOptions.recipientTrailer}
                    cols={64}
                    rows={8}
                    onInputChanged={value => this.handleChange({ tab: 'formatOptions', key: 'recipientTrailer', value: value })}
                    mono
                    resizable={{ direction: 'vertical', defaultHeight: 1 }}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialogformatoptionsdocumentbanner`}
                    title={translate('definition.document_banner')}
                    value={formatOptions.documentBanner}
                    cols={64}
                    rows={8}
                    onInputChanged={value => this.handleChange({ tab: 'formatOptions', key: 'documentBanner', value: value })}
                    mono
                    resizable={{ direction: 'vertical', defaultHeight: 1 }}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialogformatoptionsdocumentcopybanner`}
                    title={translate('definition.document_copy_banner')}
                    value={formatOptions.documentCopyBanner}
                    cols={64}
                    rows={8}
                    onInputChanged={value => this.handleChange({ tab: 'formatOptions', key: 'documentCopyBanner', value: value })}
                    mono
                    resizable={{ direction: 'vertical', defaultHeight: 1 }}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialogformatoptionsdocumenttrailer`}
                    title={translate('definition.document_trailer')}
                    value={formatOptions.documentTrailer}
                    cols={64}
                    rows={8}
                    onInputChanged={value => this.handleChange({ tab: 'formatOptions', key: 'documentTrailer', value: value })}
                    mono
                    resizable={{ direction: 'vertical', defaultHeight: 1 }}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialogformatoptionspostprocessingnote`}
                    title={translate('definition.postprocessing_note')}
                    value={formatOptions.postprocessingNote}
                    cols={64}
                    rows={8}
                    onInputChanged={value => this.handleChange({ tab: 'formatOptions', key: 'postprocessingNote', value: value })}
                    mono
                    resizable={{ direction: 'vertical', defaultHeight: 1 }}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialogformatoptionsdocument`}
                    title={translate('definition.document')}
                    value={formatOptions.document}
                    cols={64}
                    rows={8}
                    onInputChanged={value => this.handleChange({ tab: 'formatOptions', key: 'document', value: value })}
                    mono
                    resizable={{ direction: 'vertical', defaultHeight: 1 }}
                  />
                </Column>
              </Row>
            </Tab>
            <Tab title={translate('definition.extras')}>
              <Row>
                <Column colMD={12}>
                  <MultilineInput
                    id={`${id}_modifydialogoptionalparameters`}
                    title={translate('definition.optional_parameters')}
                    value={extras.optionalParameters}
                    cols={64}
                    rows={8}
                    onInputChanged={value => this.handleChange({ tab: 'extras', key: 'optionalParameters', value: value })}
                    mono
                  />
                </Column>
              </Row>
            </Tab>
            {{
              0: (// render "IP/T - Attributes"-Tab
                <Tab title={translate('definition.ipt_attributes')}>
                  <Row>
                    <Column colMD={6}>
                      <Input
                        id={`${id}_modifydialogiptattributesaddress`}
                        title={translate('definition.address')}
                        value={iptAttributes.address.value}
                        error={iptAttributes.address.error && translate(iptAttributes.address.error)}
                        maxLength={50}
                        onInputChanged={value => this.handleChange({ tab: 'iptAttributes', key: 'address', value: value })}
                        onBlur={() => this.setState(state => ({ iptAttributes: { ...state.iptAttributes, ...this.validateAddressIPT() } }))}
                        ref={this.addressIptInput}
                        required={`${translate('general.required_field')}`}
                      />
                    </Column>
                    <Column colMD={3}>
                      <NumericSpinner
                        id={`${id}_modifydialogiptattributesport`}
                        title={translate('definition.port')}
                        value={iptAttributes.port.value}
                        error={iptAttributes.port.error && translate(iptAttributes.port.error)}
                        onChange={value => this.handleChange({ tab: 'iptAttributes', key: 'port', value: value })}
                        onBlur={() => this.setState(state => ({ iptAttributes: { ...state.iptAttributes, ...this.validatePortIPT() } }))}
                        focusRef={this.portIptInput}
                        required={`${translate('general.required_field')}`}
                        min={1}
                        steps={1}
                        max={65535}
                        emptyable
                        default={''}
                      />
                    </Column>
                    <Column colMD={3}>
                      <Dropdown
                        id={`${id}_modifydialogiptattributesprotocol`}
                        title={translate('definition.protocol')}
                        items={[
                          'LPP',
                          'IPP',
                          'PJL',
                          'DSP'
                        ]}
                        activeIndex={iptAttributes.protocol}
                        onChange={index => this.handleChange({ tab: 'iptAttributes', key: 'protocol', value: index })}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={6}>
                      <Input
                        id={`${id}_modifydialogiptattributesqueuename`}
                        title={translate('definition.queue_name')}
                        value={iptAttributes.queueName}
                        maxLength={44}
                        onInputChanged={value => this.handleChange({ tab: 'iptAttributes', key: 'queueName', value: value })}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={12}>
                      <hr />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={12}>
                      <Checkbox
                        id={`${id}_modifydialogiptattributesactivatesnmpsetting`}
                        label={translate('definition.activate_snmp_setting')}
                        value={iptAttributes.activateSnmpSetting}
                        onCheck={value => this.handleChange({ tab: 'iptAttributes', key: 'activateSnmpSetting', value: value })}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={6}>
                      <Switch
                        id={`${id}_modifydialogiptattributesactivatealternativeafterretryperiod`}
                        title={translate('definition.activate_alternative_after_retry_period')}
                        items={[
                          translate('general.yes'),
                          translate('general.no')
                        ]}
                        activeIndex={iptAttributes.activateAlternativeAfterRetryPeriod ? 0 : 1}
                        onClick={index => this.handleChange({ tab: 'iptAttributes', key: 'activateAlternativeAfterRetryPeriod', value: index === 0 })}
                        disabled={!iptAttributes.activateSnmpSetting}
                      />
                    </Column>
                    <Column colMD={3}>
                      <NumericSpinner
                        id={`${id}_modifydialogiptattributesretryperiodminutes`}
                        title={translate('definition.retry_period_minutes')}
                        value={iptAttributes.retryPeriodMinutes}
                        steps={1}
                        max={9999}
                        min={0}
                        onChange={value => this.handleChange({ tab: 'iptAttributes', key: 'retryPeriodMinutes', value: value })}
                        disabled={!iptAttributes.activateSnmpSetting}
                      />
                    </Column>
                    <Column colMD={3}>
                      <Input
                        id={`${id}_modifydialogiptattributescommunity`}
                        title={translate('definition.community')}
                        value={iptAttributes.community}
                        maxLength={32}
                        onInputChanged={value => this.handleChange({ tab: 'iptAttributes', key: 'community', value: value })}
                        disabled={!iptAttributes.activateSnmpSetting}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={12}>
                      <hr />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={12}>
                      <Checkbox
                        id={`${id}_modifydialogiptattributesactivatepjlstatusreadbackustatus`}
                        tooltip={translate('definition.output_channel_pjl_info')}
                        label={translate('definition.activate_pjl_status_readback_ustatus')}
                        value={iptAttributes.activatePjlStatusReadbackUstatus}
                        onCheck={value => this.handleChange({ tab: 'iptAttributes', key: 'activatePjlStatusReadbackUstatus', value: value })}
                        disabled={iptAttributes.protocol !== 2}// 2 === PGL
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={6}>
                      <Switch
                        id={`${id}_modifydialogiptattributespage`}
                        title={translate('definition.page')}
                        items={[
                          translate('general.yes'),
                          translate('general.no')
                        ]}
                        activeIndex={iptAttributes.page ? 0 : 1}
                        onClick={index => this.handleChange({ tab: 'iptAttributes', key: 'page', value: index === 0 })}
                        disabled={!iptAttributes.activatePjlStatusReadbackUstatus}
                      />
                    </Column>
                    <Column colMD={3} className={'bux_pr8'}>
                      <NumericSpinner
                        id={`${id}_modifydialogiptattributestimedseconds`}
                        title={translate('definition.timed_seconds')}
                        value={iptAttributes.timedSeconds}
                        steps={1}
                        max={300}
                        min={0}
                        onChange={value => this.handleChange({ tab: 'iptAttributes', key: 'timedSeconds', value: value })}
                        disabled={!iptAttributes.activatePjlStatusReadbackUstatus}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={6}>
                      <Switch
                        id={`${id}_modifydialogiptattributesdevice`}
                        title={translate('definition.device')}
                        items={[
                          translate('definition.verbose'),
                          translate('definition.on'),
                          translate('definition.off')
                        ]}
                        maxPerRow={3}
                        activeIndex={iptAttributes.device}
                        onClick={index => this.handleChange({ tab: 'iptAttributes', key: 'device', value: index })}
                        disabled={!iptAttributes.activatePjlStatusReadbackUstatus}
                      />
                    </Column>
                    <Column colMD={3} className={'bux_pr8'}>
                      <NumericSpinner
                        id={`${id}_modifydialogiptattributeswaitminutes`}
                        title={translate('definition.wait_minutes')}
                        value={iptAttributes.waitMinutes}
                        steps={1}
                        max={1440}
                        min={0}
                        onChange={value => this.handleChange({ tab: 'iptAttributes', key: 'waitMinutes', value: value })}
                        disabled={!iptAttributes.activatePjlStatusReadbackUstatus}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={12}>
                      <hr />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={12}>
                      <MultilineInput
                        id={`${id}_modifydialogiptattributesoptionalparameters`}
                        title={translate('definition.optional_parameters')}
                        value={iptAttributes.optionalParameters}
                        cols={64}
                        rows={8}
                        onInputChanged={value => this.handleChange({ tab: 'iptAttributes', key: 'optionalParameters', value: value })}
                        mono
                      />
                    </Column>
                  </Row>
                </Tab>
              ),
              1: (// render "Command - Attributes"-Tab
                <Tab title={translate('definition.command_attributes')}>
                  <Row>
                    <Column colMD={6}>
                      <Switch
                        id={`${id}_modifydialogprovideoutputasseparatefilesinworkingdirectory`}
                        title={translate('definition.provide_output_as_separate_files_in_working_directory')}
                        items={[translate('general.yes'), translate('general.no')]}
                        onClick={index => this.handleChange({ tab: 'commandAttributes', key: 'provideOutputAsSeparateFilesInWorkingDirectory', value: index === 0 })}
                        activeIndex={commandAttributes.provideOutputAsSeparateFilesInWorkingDirectory ? 0 : 1}
                      />
                    </Column>
                    <Column colMD={3}>
                      <Input
                        id={`${id}_modifydialogcommandattributesskeleton`}
                        title={translate('definition.skeleton_file')}
                        value={commandAttributes.skeleton}
                        maxLength={8}
                        disabled={!commandAttributes.provideOutputAsSeparateFilesInWorkingDirectory}
                        onInputChanged={value => this.handleChange({ tab: 'commandAttributes', key: 'skeleton', value: value })}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={12}>
                      <MultilineInput
                        id={`${id}_modifydialogcommandattributespreparationcommand`}
                        title={translate('definition.preparation_command')}
                        value={commandAttributes.preparationCommand}
                        cols={64}
                        rows={8}
                        disabled={!commandAttributes.provideOutputAsSeparateFilesInWorkingDirectory}
                        onInputChanged={value => this.handleChange({ tab: 'commandAttributes', key: 'preparationCommand', value: value })}
                        mono
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={12}>
                      <hr />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={11}>
                      <MultilineInput
                        id={`${id}_modifydialogcommandattributesoutputcommand`}
                        title={translate('definition.output_command')}
                        value={commandAttributes.outputCommand.value}
                        error={commandAttributes.outputCommand.error && translate(commandAttributes.outputCommand.error)}
                        cols={64}
                        rows={16}
                        onInputChanged={value => this.handleChange({ tab: 'commandAttributes', key: 'outputCommand', value: value })}
                        onBlur={() => this.setState({ commandAttributes: { ...this.state.commandAttributes, ...this.validateOutputCommand() } })}
                        focusRef={this.outputCommandInput}
                        mono
                        required={`${translate('general.required_field')}`}
                      />
                    </Column>
                    <Column colMD={1}>
                      <Tooltip
                        event={'click'}
                        className='bux_tooltip'
                        parentContainer={'definition_outputchannelresult_tilecontainer'}
                        id={`${id}_modify_dialog_command_attributes_output_command`}
                        tooltipContent={[
                          <label>{translate('definition.example_for_single_file_processing')}</label>,
                          <ul>
                            <li>{translate('definition.save_as_cat')}</li>
                            <li>{translate('definition.print_lpr_print')}</li>
                            <li>{translate('definition.print_lp')}</li>
                          </ul>,
                          <label>{translate('definition.example_for_multi_file_processing')}</label>,
                          <ul>
                            <li>{translate('definition.save_as_cp')}</li>
                            <li>{translate('definition.print_lpr_folder')}</li>
                          </ul>
                        ]}>
                        <Icon
                          id={`${id}_modifydialogcommandattributesoutputcommandtooltip`}
                          name={'info_filled'}
                        />
                      </Tooltip>
                    </Column>
                  </Row>
                </Tab>
              ),
              2: (// render "Application - Attributes"-Tab
                <Tab title={translate('definition.application_attributes')}>
                  <Row>
                    <Column colMD={6}>
                      <Input
                        id={`${id}_modifydialogapplicationattributesaddress`}
                        title={translate('definition.address')}
                        value={applicationAttributes.address.value}
                        error={applicationAttributes.address.error && translate(applicationAttributes.address.error)}
                        maxLength={50}
                        onInputChanged={value => this.handleChange({ tab: 'applicationAttributes', key: 'address', value: value })}
                        onBlur={() => this.setState(state => ({ applicationAttributes: { ...state.applicationAttributes, ...this.validateAddressApplication() } }))}
                        ref={this.addressApplicationInput}
                        required={`${translate('general.required_field')}`}
                      />
                    </Column>
                    <Column colMD={3}>
                      <NumericSpinner
                        id={`${id}_modifydialogapplicationattributesport`}
                        title={translate('definition.port')}
                        value={applicationAttributes.port.value}
                        error={applicationAttributes.port.error && translate(applicationAttributes.port.error)}
                        onChange={value => this.handleChange({ tab: 'applicationAttributes', key: 'port', value: value })}
                        onBlur={() => this.setState(state => ({ applicationAttributes: { ...state.applicationAttributes, ...this.validatePortApplication() } }))}
                        focusRef={this.portApplicationInput}
                        required={`${translate('general.required_field')}`}
                        min={1}
                        steps={1}
                        max={65535}
                        emptyable
                        default={''}
                      />
                    </Column>
                    <Column colMD={3}>
                      <Dropdown
                        id={`${id}_modifydialogapplicationattributesapplication`}
                        title={translate('definition.application')}
                        items={['_beta doc|z', 'B93EMF', '_beta doc|x']}
                        activeIndex={applicationAttributes.application.value}
                        stringValue={outputChannel['DCRAPPL']}
                        error={applicationAttributes.application.error && translate(applicationAttributes.application.error)}
                        onChange={index => this.handleChange({ tab: 'applicationAttributes', key: 'application', value: index })}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={6}>
                      <Input
                        id={`${id}_modifydialogapplicationattributesqueuename`}
                        title={translate('definition.queue_name')}
                        value={applicationAttributes.queueName}
                        maxLength={44}
                        onInputChanged={value => this.handleChange({ tab: 'applicationAttributes', key: 'queueName', value: value })}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={12}>
                      <hr />
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={12}>
                      <MultilineInput
                        id={`${id}_modifydialogapplicationattributesoptionalparameters`}
                        title={translate('definition.optional_parameters')}
                        value={applicationAttributes.optionalParameters}
                        cols={64}
                        rows={16}
                        onInputChanged={value => this.handleChange({ tab: 'applicationAttributes', key: 'optionalParameters', value: value })}
                        mono
                      />
                    </Column>
                  </Row>
                </Tab>
              )
            }[general.type]}
          </Tabs>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth, definitions }) => {
  return {
    token: auth.serverdata.token,
    outputChannel: definitions.outputchannels.outputChannel,
    datemask: auth.serverdata.preferences.DATEMASK
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateOutputChannel: (outputChannel, callback) => {
      updateOutputChannel(outputChannel, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyOutputChannelDialog)