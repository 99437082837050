import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const CREATE_REMOTE_USER_START = 'CREATE_REMOTE_USER_START'
export const CREATE_REMOTE_USER_SUCCESS = 'CREATE_REMOTE_USER_SUCCESS'
export const CREATE_REMOTE_USER_FAILED = 'CREATE_REMOTE_USER_FAILED'

export const GET_REMOTE_USER_START = 'GET_REMOTE_USER_START'
export const GET_REMOTE_USER_SUCCESS = 'GET_REMOTE_USER_SUCCESS'
export const GET_REMOTE_USER_FAILED = 'GET_REMOTE_USER_FAILED'

export const DELETE_REMOTE_USER_START = 'DELETE_REMOTE_USER_START'
export const DELETE_REMOTE_USER_SUCCESS = 'DELETE_REMOTE_USER_SUCCESS'
export const DELETE_REMOTE_USER_FAILED = 'DELETE_REMOTE_USER_FAILED'

export const UPDATE_REMOTE_USER_START = 'UPDATE_REMOTE_USER_START'
export const UPDATE_REMOTE_USER_SUCCESS = 'UPDATE_REMOTE_USER_SUCCESS'
export const UPDATE_REMOTE_USER_FAILED = 'UPDATE_REMOTE_USER_FAILED'

export const GET_REMOTE_USERS_START = 'GET_REMOTE_USERS_START'
export const GET_REMOTE_USERS_SUCCESS = 'GET_REMOTE_USERS_SUCCESS'
export const GET_REMOTE_USERS_FAILED = 'GET_REMOTE_USERS_FAILED'

export const NO_REMOTE_USERS_FOUND = 'NO_REMOTE_USERS_FOUND'

/**
 * @description Calls the rest api and creates a remote user.
 * @param {Strign} cuser The userid which creates the remote user.
 * @param {String} unixuser The unixuser for the creation.
 * @param {String} betauser The betauser for the creation.
 * @param {String} ipmask The ip mask for the creation.
 * @param {Function} callback The callback which will be called when the create remote user request was successful. Can be null.
 */
export function createRemoteUser(cuser, unixuser, betauser, ipmask, ip6mask, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CREATE_REMOTE_USER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/remoteuser`, {
        method: 'post',
        body: {
          UNIXUSER: unixuser,
          BETAUSER: betauser,
          IPMASK: ipmask,
          IP6MASK: ip6mask,
          CUSER: cuser,
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CREATE_REMOTE_USER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: CREATE_REMOTE_USER_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.create_remote_user_success', lang, unixuser), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getRemoteUsers again to get the current result
          const searchUnixuser = prefs[Preferences.USERMANAGEMENT_REMOTEUSERS_UNIXUSER]
          const searchBuxuser = prefs[Preferences.USERMANAGEMENT_REMOTEUSERS_BUXUSER]

          getRemoteUsers(searchUnixuser, searchBuxuser, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.create_remote_user_error', lang, [unixuser, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CREATE_REMOTE_USER_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets a remote user.
 * @param {String} unixuser The unixuser to get the remote user.
 * @param {Function} callback The callback which will we be call when the get remote user request was successful. Can be null.
 */
export function getRemoteUser(unixuser, callback) {
  return dispatch => {
    /* Show loading-animation and block user-input */
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: GET_REMOTE_USER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/remoteuser?UNIXUSER=${encodeURIComponent(unixuser)}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_REMOTE_USER_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_REMOTE_USER_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_remote_user_error', lang, [unixuser, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_REMOTE_USER_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes a remote user.
 * @param {String} unixuser The unixuser to delete.
 * @param {Function} callback The callback which will be called when the delete remote user request was successful. Can be null.
 */
export function deleteRemoteUser(unixuser, userid, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: DELETE_REMOTE_USER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/remoteuser${createQueryParamsForFetch({ UNIXUSER: unixuser })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DELETE_REMOTE_USER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DELETE_REMOTE_USER_SUCCESS })
          SnackbarActions.show(Lang.translate('usermanagement.delete_remote_user_success', lang, [unixuser, userid]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getRemoteUsers again to get the current result
          const searchUnixuser = prefs[Preferences.USERMANAGEMENT_REMOTEUSERS_UNIXUSER]
          const searchBuxuser = prefs[Preferences.USERMANAGEMENT_REMOTEUSERS_BUXUSER]

          getRemoteUsers(searchUnixuser, searchBuxuser, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.delete_remote_user_error', lang, [unixuser, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DELETE_REMOTE_USER_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates the remote user.
 * @param {String} cuser The userid which updates the remote user.
 * @param {String} unixuser The unixuser to update the correct remote user.
 * @param {String} betauser The new betauser.
 * @param {String} ipmask The new ipmask.
 * @param {Function} callback The callback which will be called whem the update remote user request was successful. Can be null.
 */
export function updateRemoteUser(cuser, unixuser, betauser, ipmask, ip6mask, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: UPDATE_REMOTE_USER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/remoteuser`, {
        method: 'put',
        body: {
          UNIXUSER: unixuser,
          BETAUSER: betauser,
          CUSER: cuser,
          IPMASK: ipmask,
          IP6MASK: ip6mask
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: UPDATE_REMOTE_USER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: UPDATE_REMOTE_USER_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.update_remote_user_success', lang, unixuser), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getRemoteUsers again to get the current result
          const searchUnixuser = prefs[Preferences.USERMANAGEMENT_REMOTEUSERS_UNIXUSER]
          const searchBuxuser = prefs[Preferences.USERMANAGEMENT_REMOTEUSERS_BUXUSER]
          getRemoteUsers(searchUnixuser, searchBuxuser, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.update_remote_user_error', lang, [unixuser, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPDATE_REMOTE_USER_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets remote users.
 * @param {String} unixuser The unixuser to search.
 * @param {String} betauser The betauser to search.
 */
export function getRemoteUsers(unixuser, betauser, keepPagination = false) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: GET_REMOTE_USERS_START })
    LoadingSpinnerActions.show()(dispatch)

    const startTime = Date.now()

    /* Build request-parameter for URL */
    const queryParams = []
    if (unixuser) { queryParams.push(`UNIXUSER=${encodeURIComponent(unixuser)}`) }
    if (betauser) { queryParams.push(`BETAUSER=${encodeURIComponent(betauser)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/remoteusers?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // handle no data found here to dispatch NO_REMOTE_USERS_FOUND action
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_REMOTE_USERS_FOUND })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_REMOTE_USERS_FAILED })
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_REMOTE_USERS_SUCCESS, payload: jsondata.data, keepPagination })

          // wait a bit to show the user the loading animation
          const usedTime = Date.now() - startTime
          if (usedTime < 300) {
            setTimeout(() => {
              LoadingSpinnerActions.hide()(dispatch)
            }, 300 - usedTime)
          }
          else {
            LoadingSpinnerActions.hide()(dispatch)
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_remote_users_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_REMOTE_USERS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}