import * as NodeDocumentAssignmentActions from '../actions/NodeDocumentAssignmentActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  nodeDocumentAssignments: undefined,
  nodeDocumentAssignment: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get node document assignments actions
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_GET_ASSIGNMENTS_START:
      return { ...state }
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_GET_ASSIGNMENTS_SUCCESS:
      return { ...state, nodeDocumentAssignments: action.payload, keepPagination: action.keepPagination }
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_GET_ASSIGNMENTS_FAILED:
      return { ...state }
    case NodeDocumentAssignmentActions.NO_NODE_DOCUMENT_ASSIGNMENTS_FOUND:
      return { ...state, nodeDocumentAssignments: {} }
    /* ********************************************************************************** */
    // Definition get node document assignment actions
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_GET_ASSIGNMENT_START:
      return { ...state, nodeDocumentAssignment: undefined }
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_GET_ASSIGNMENT_SUCCESS:
      return { ...state, nodeDocumentAssignment: action.payload}
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_GET_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete node document assignment actions
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_DELETE_ASSIGNMENT_START:
      return { ...state }
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_DELETE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update node document assignment actions
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_UPDATE_ASSIGNMENT_START:
      return { ...state }
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_UPDATE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update node document assignment actions
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_CREATE_ASSIGNMENT_START:
      return { ...state }
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case NodeDocumentAssignmentActions.NODE_DOCUMENT_CREATE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, nodeDocumentAssignments: undefined, nodeDocumentAssignment: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer