import store from 'redux/Store'
import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LastSessionActions from 'redux/actions/LastSessionActions'
import * as SecurityProfileActions from 'redux/actions/SecurityProfileActions'
import * as CustomDialogDefinitionActions from 'redux/actions/CustomDialogDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import { userprofilePanelRoutes } from 'utils/UserUtils'
import { restapiRequest } from 'utils/RequestUtils'
import { DOCUMENT_VIEWER_URL_BASE } from 'components/documentViewer/constants/routes'
// actions
export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT_START = 'LOGOUT_START'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'
export const GET_BACKENDS_START = 'GET_BACKENDS_START'
export const GET_BACKENDS_SUCCESS = 'GET_BACKENDS_SUCCESS'
export const GET_BACKENDS_FAILED = 'GET_BACKENDS_FAILED'
export const GET_RESTAPIVERSION_START = 'GET_RESTAPIVERSION_START'
export const GET_RESTAPIVERSION_SUCCESS = 'GET_RESTAPIVERSION_SUCCESS'
export const GET_RESTAPIVERSION_FAILED = 'GET_RESTAPIVERSION_FAILED'
export const TYPE_CHANGE_LANGUAGE = 'LANGUAGE_CHANGE'

/**
 * @description Changes the current global language.
 * @param {String} lang The new language. See possible language values in Language.js.
 */
export function changeLanguage(lang) {
  return dispatch => {
    dispatch({
      type: TYPE_CHANGE_LANGUAGE,
      payload: {
        lang: lang,
      }
    })
  }
}

/**
 * Clears all data in redux. We could outsource this function into another reducer but currently we do this here.
 * If we have more general functions like this then outsource it.
 */
export function clearData() {
  return dispatch => {
    dispatch({ type: LOGOUT_SUCCESS, payload: { } })
  }
}

/**
 * @description Get version information from REST-API
 */
export function getAPIVersionInfo() {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    dispatch({ type: GET_RESTAPIVERSION_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/version`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          const { message, type } = GeneralErrorHandler.getError(jsondata.error, dispatch)
          SnackbarActions.show(message, type)(dispatch)
          dispatch({ type: GET_RESTAPIVERSION_FAILED })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: GET_RESTAPIVERSION_SUCCESS, payload: { data: jsondata.data } })
          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('loginscreen.apiversion_loading_error', undefined, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_RESTAPIVERSION_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}


/**
 * @description Get all available systemnames which can connected to
 */
export function getBackends() {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    dispatch({ type: GET_BACKENDS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/system/connections`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          const { message, type } = GeneralErrorHandler.getError(jsondata.error, dispatch)
          SnackbarActions.show(message, type)(dispatch)
          dispatch({ type: GET_BACKENDS_FAILED })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          if (!jsondata.data || jsondata.data.length === 0) {
            SnackbarActions.show(Lang.translate('loginscreen.no_backend_systems'), SnackbarActions.TYPE_ERROR)(dispatch)
            dispatch({ type: GET_BACKENDS_FAILED })
          } else {
            dispatch({ type: GET_BACKENDS_SUCCESS, payload: { data: jsondata.data } })
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('loginscreen.backend_loading_error', undefined, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_BACKENDS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do a login request.
 * @param {String} systemname Name of the available backends to connect to.
 * @param {String} userid The current user id known as BETAUSER from bql.
 * @param {String} password The current password.
 * @param {String} newPassword The new password. Needed when user want to change the password.
 * @param {String} history The history to jump to a page when reconnecting.
 */
export function login(systemname, userid, password, newPassword, history, openNewPassword) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const lang = store.getState().auth.serverdata.preferences.LANGUAGE;
    dispatch({ type: LOGIN_START })

    const headers = {}

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/public/login`, {
        method: 'post',
        headers,
        body: {
          username: userid,
          password: password,
          newpassword: newPassword,
          timeoffset: new Date().getTimezoneOffset(),
          systemname
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          const { message, type } = GeneralErrorHandler.getError(jsondata.error, dispatch)
          const ircsForExpire = ['1201', '1202', '1203']
          const rc = jsondata.error.rc
          const irc = jsondata.error.irc
          if (rc === '0008' && ircsForExpire.includes(irc)) {
            openNewPassword()
          }
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(message, type)(dispatch)
          dispatch({ type: LOGIN_FAILED })
        } else {
          dispatch({ type: LOGIN_SUCCESS, payload: { userid, data: jsondata } })
          if (jsondata.warning) {
            const { message, type } = GeneralErrorHandler.getError(jsondata.warning, dispatch)
            SnackbarActions.show(message, type)(dispatch)
          }
          // load security profile entity parts just for admins
          if (!jsondata?.userprofile?.BRWSONLY) {
            SecurityProfileActions.getEntityParts()(dispatch)
            CustomDialogDefinitionActions.getAvailableTypes()(dispatch)
          }
          else {
            LoadingSpinnerActions.hide()(dispatch)
          }

          const lastSession = store.getState().lastsession
          if (lastSession && lastSession.page) {
            // redirect if there's a reconnect
            if (lastSession.userid === userid) {
              history.push(store.getState().lastsession.page)
            } else {
              // userid is different -> don't redirect. Also remove the values from the last session because there is a new user
              LastSessionActions.removeLastSession()(dispatch)

              // try open last route from preferences
              openLastRouteFromPreferences(history)
            }
          } else {
            // try open last route from preferences
            openLastRouteFromPreferences(history)
          }

          // if user changed his password show info message
          if (newPassword) {
            SnackbarActions.show(Lang.translate('loginscreen.password_successfully_changed', lang), SnackbarActions.TYPE_INFO)(dispatch)
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('loginscreen.login_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LOGIN_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Opens the last route of the preferences if exist.
 * @param {String} history The history to jump to a page.
 */
function openLastRouteFromPreferences(history) {
  const preferences = store.getState().auth.serverdata.preferences
  const userprofile = store.getState().auth.serverdata.userprofile

  if (userprofile && userprofile.VCISTART) {
    history.push(userprofilePanelRoutes.find(d => d.key === userprofile.VCISTART).route)
  } else {
    preferences && preferences[Preferences.LAST_ROUTE] && history.push(preferences[Preferences.LAST_ROUTE])
  }
}

/**
 * @description Calling the rest api and do a logout request.
 * @param {String} userid The user id of the user to logout. Needed for save the preferences.
 * @param {String} serverType The server type which was given from the login request.
 * @param {String} serverVersion The server version which was given from the login request.
 * @param {Object} preferences The list of preferences to be saved. Can be null if no preferences should be saved.
 */
export function logout(userid, serverType, serverVersion, preferences, callback) {
  return dispatch => {
    const lang = store.getState().auth.serverdata.preferences.LANGUAGE
    dispatch({ type: LOGOUT_START })

    if (preferences) {
      if (Array.isArray(preferences.FAVORITES)) {
        // converts the objects inside the favorites array in strings, after that the array is converted in a string too
        const parsedFavorites = JSON.stringify(preferences.FAVORITES)
        preferences = {
          ...preferences,
          FAVORITES: parsedFavorites
        }
        // save current route
        preferences = {
          ...preferences,
          // Fallback seems to be needed in case of a user where the tab closing is blocked:
          [Preferences.LAST_ROUTE]: !window.location.pathname.includes(DOCUMENT_VIEWER_URL_BASE) ? window.location.pathname : '/search/standardselection'
        }
      }
    }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/logoff`, {
        method: 'post',
        body: {
          username: userid,
          type: serverType,
          version: serverVersion,
          preferences: preferences
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: LOGOUT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: LOGOUT_SUCCESS, payload: {persistPreferences: !!preferences}})

          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('header.logout_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)

        dispatch({ type: LOGOUT_FAILED, payload: { error } })
      })
  }
}

export function isValidUserSession(userid, serverType, serverVersion, preferences) {
  return async dispatch => {
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/defaultsearchresultobjects`, { method: 'get' })
    )

    try {
      setTimeout(() => {
        cancelablePromise.cancel()
      }, Config.FETCH_DATA_TIMEOUT)

      const res = await cancelablePromise.promise
      const resJson = await res.json()

      if (!resJson.success) {
        logout(userid, serverType, serverVersion, preferences, () => {
          const error = GeneralErrorHandler.handleResponse(resJson.error.rc, resJson.error.irc, resJson.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        })(dispatch)

        return false
      } else {
        return true
      }
    } catch (e) {
      logout(userid, serverType, serverVersion, preferences, () => {
        SnackbarActions.show(e.message, SnackbarActions.TYPE_ERROR)(dispatch)
      })(dispatch)

      return false
    }
  }
}