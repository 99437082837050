import { Component } from 'react'
import { connect } from 'react-redux'

import {
  Button, Card, Column, MetaDataGrid, Modal as ModalComponent, NumericSpinner, Row
} from 'BetaUX2Web-Components/src/'
import { translate } from 'language/Language'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as QueueUtils from 'utils/QueueUtils'

const { Modal, Main, Header, Footer } = ModalComponent

class ModifyPriorityDialog extends Component {

  state = {
    newPriority: 0,
    outputChannelID: '',
    requestQueued: '',
    status: '',
    bToken: '',
    returnCode: '',
    form: '',
    extension: '',
    report: '',
    recipientID: '',
    priority: '',
  }

  /**
   * @description Load the data from redux state into this state. Response data from REST-API is really difficult to understand,
   * so we map the data into an understandable way.
   */
  componentDidMount = () => {
    const { outputDetails, datemask } = this.props
    // We get 3 array entries from REST-API. The information are stored in different array entries.
    let prtIndex
    let infIndex
    outputDetails.data.forEach((entry, index) => {
      if (entry[this.headerData('PRTDATA')] === 'LST') {
        prtIndex = index
      }
      if (entry[this.headerData('PRTDATA')] === 'INF') {
        infIndex = index
      }
    })
    const dataPrt = outputDetails.data[prtIndex]
    const dataInf = outputDetails.data[infIndex]
    const outputChannelID = dataPrt[this.headerData('DCR')]
    const requestQueued = DateUtils.getDate(datemask, dataPrt[this.headerData('BDATE')], dataPrt[this.headerData('BTIME')].substring(0, 8))
    const status = dataInf[this.headerData('BSTATUS')]
    const bToken = dataPrt[this.headerData('BTOKEN')]
    const returnCode = dataPrt[this.headerData('PRTRC')]
    const form = dataPrt[this.headerData('FORM')]
    const extension = dataPrt[this.headerData('EXT')]
    const report = dataPrt[this.headerData('REPORT')]
    const recipientID = dataPrt[this.headerData('RECI')]
    const priority = dataInf[this.headerData('PRTPRIO')]
    this.setState({ outputChannelID, requestQueued, status, bToken, returnCode, form, extension, report, recipientID, priority })
  }

  headerData = header => this.props.outputDetails.header.indexOf(header)

  render = () => {
    const { newPriority, outputChannelID, requestQueued, status, bToken, returnCode, form, extension, report, recipientID, priority } = this.state
    const { id, onClose, title, executeFunc } = this.props
    const statusFound = QueueUtils.OUTPUT_QUEUE_STATUS_VALUES.find(el => el.key === status)
    return (
      <Modal onClose={onClose} id={id}>
        <Header
          id={id}
          title={title}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('definition.output_channel_id'), value: outputChannelID },
              { label: translate('queue.requestqueued'), value: requestQueued },
              { label: translate('database.status'), value: statusFound ? translate(statusFound.translationKey) : status },
              { label: translate('general.btoken'), value: bToken },
              { label: translate('database.return_code'), value: returnCode },
              { label: translate('general.form'), value: form },
              { label: translate('general.extension'), value: extension },
              { label: translate('general.report'), value: report },
              { label: translate('recipient.reci_id'), value: recipientID },
              { label: translate('queue.priority'), value: priority },
            ]}
            columns={4}
          />
        </Header>
        <Main>
          <Card>
            <Row>
              <Column colMD={4}>
                <NumericSpinner
                  id={`${id}_new_priority`}
                  title={translate('general.new_priority')}
                  onChange={val => this.setState({ newPriority: val})}
                  value={newPriority}
                  min={0}
                  max={15}
                  steps={1}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_executebtn`}
            text={translate('database.execute')}
            onClick={() => executeFunc(newPriority)}
            primary
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    outputDetails: state.queue.output.outputDetails,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK]
  }
}

export default connect(mapStateToProps)(ModifyPriorityDialog)