import { translate } from 'language/Language'
import { Component } from 'react'

import {
  Button, Card, Column, DataTable, MetaDataGrid, Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as Preferences from 'redux/general/Preferences'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as Utils from 'utils/Utils'

class VerifyIndex extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  /**
   * @description Gets the index of a specific header.
   * @param {String} header specific header of the verified output channel
   */
  headerData = header => this.props.verify.header.indexOf(header)

  getData = () => {
    const { verify } = this.props
    const data = []
    verify.data.forEach(d => {
      data.push([
        d[this.headerData('IXSCOPE')],
        d[this.headerData('FORM')],
        d[this.headerData('EXT')],
        d[this.headerData('REPORT')],
        d[this.headerData('IXSCHECK')],
        d[this.headerData('VDATEB')],
        d[this.headerData('VDATEE')]
      ])
    })
    return data
  }

  render = () => {
    const { id, onClose, index, lang, datemask } = this.props
    const dataFormatTranslation = DefinitionUtils.getIndexDataFormatTypes()
    return (
      <Modal onClose={onClose} id={id}>
        <Header
          id={id}
          title={translate('definition.index_verify')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('definition.index_id'), value: index.IXINAME },
              { label: translate('definition.index_data_format'), value: translate(dataFormatTranslation.find(element => element.key === index.IXDTYPE)?.translationKey) },
              { label: translate('definition.index_identifier'), value: index.IXENAME },
            ]}
            columns={4}
          />
        </Header>
        <Main>
          <Card className={Utils.getSpecificContainerStyle()}>
            <Row className={'bux_modal_container'}>
              <Column colMD={12} className={'bux_full_height'}>
                <DataTable
                  id={`${id}_verify`}
                  header={[
                    translate('general.available'),
                    translate('general.form'),
                    translate('general.extension'),
                    translate('general.report'),
                    translate('general.sec'),
                    translate('general.valid_from'),
                    translate('general.valid_to')
                  ]}
                  columnSortDefs={['string', 'string', 'string', 'string', 'string', 'string', 'string']}
                  data={this.getData()}
                  noAction
                  language={lang}
                  datemask={datemask}
                  translate={key => translate(key)}
                  menu={false}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}


const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    index: state.definitions.indexes.index,
    verify: state.definitions.indexes.verify
  }
}

export default connect(mapStateToProps)(VerifyIndex)