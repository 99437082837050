import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  Button, Card,
  Checkbox, Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row, Switch
} from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import { translate } from 'language/Language'

const { Modal, Main, Header, Footer } = ModalComponent

import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as StandardSelectionActions from 'redux/actions/StandardSelectionActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as Utils from 'utils/Utils'

import './DocumentPrint.scss'

class DocumentPrint extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    document: PropTypes.object.isRequired,
    printInfo: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  state = {
    activeTabIndex: 0,
    requests: [],
    printConfig: {
      printParameterIndex: 0,
      outputChannelID: {
        value: '',
        errorkey: '',
        backup: ''
      },
      outputFormatID: {
        value: '',
        errorkey: '',
        backup: ''
      },
      ppnID: {
        value: '',
        errorkey: '',
        backup: ''
      },
      recipientID: {
        value: '',
        errorkey: ''
      },
      fromPage: {
        value: 1,
        error: ''
      },
      toPage: {
        value: 1,
        error: ''
      },
      copies: 1,
      priority: 0,
    },
    showOutputChannelSelectorDialog: false,
    showOutputFormatSelectorDialog: false,
    showPostprocessingNoteSelectorDialog: false,
    showRecipientSelectorDialog: false,
    currentPrint: 1,
    printAll: false
  }

  outputChannelInput = React.createRef()
  outputFormatInput = React.createRef()
  ppnInput = React.createRef()
  recipientInput = React.createRef()
  fromPageInput = React.createRef()
  toPageInput = React.createRef()

  /**
   * @description Initializes the values.
   */
  componentDidMount = () => {
    this.setDefaultValues()
  }

  /**
   * @description Sets the default values.
   */
  setDefaultValues = () => {
    const { userprofile, printInfo, documentsToPrint, document } = this.props
    const { currentPrint } = this.state
    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentPrint - 1].docInfo : document
    const outputChannel = userprofile
      ? userprofile.VCIPRDCR !== ''
        ? userprofile.VCIPRDCR : ''
      : ''
    const outputFormat = userprofile
      ? userprofile.VCIPRPCR !== ''
        ? userprofile.VCIPRPCR : ''
      : ''
    const ppn = userprofile
      ? userprofile.VCIPRPPN !== ''
        ? userprofile.VCIPRPPN : ''
      : ''
    const reci = userprofile
      ? userprofile.VCIPRREC !== ''
        ? userprofile.VCIPRREC : ''
      : ''
    const printParameterIndex = userprofile
      ? userprofile.VCIPRFRO === 'PROFILE' ? 0 : 1
      : 0
    this.setState({
      printConfig: {
        ...this.state.printConfig,
        printParameterIndex,
        outputChannelID: {
          value: printParameterIndex === 0
            ? outputChannel
            : printInfo[0].data?.DOCDCR || 'STD', // Note: Customer needs to make sure that output channel for value of DOCDCR or 'STD' was created
          errorkey: '',
          backup: printParameterIndex === 1 ? outputChannel : ''
        },
        outputFormatID: {
          value: printParameterIndex === 0
            ? outputFormat
            : printInfo[0].data?.DOCPCR || '',
          errorkey: '',
          backup: printParameterIndex === 1 ? outputFormat : ''
        },
        ppnID: {
          value: printParameterIndex === 0
            ? ppn
            : printInfo[0].data?.DOCPPN || '',
          errorkey: '',
          backup: printParameterIndex === 1 ? ppn : ''
        },
        recipientID: { value: reci, errorkey: '' },
        toPage: { value: documentToUse.SRCPAGES, error: '' }
      },
    })
  }

  /**
   * @description Handles changes of a field.
   * @param {String} tab The current tab.
   * @param {String} key The key of the field.
   * @param {*} value The new value.
   */
  handleChange = (key, value) => {
    this.setState({
      printConfig: {
        ...this.state.printConfig,
        [key]: typeof this.state.printConfig[key] === 'object'
          ? { value, error: '' }
          : value
      }
    })
  }

  /**
   * @description Handles changes of a from page input.
   * @param {*} value The new value.
   */
  handleChangeFromPage = value => {
    this.setState({
      printConfig: {
        ...this.state.printConfig,
        fromPage: { value, error: '' },
        toPage: { ...this.state.printConfig.toPage, error: '' }
      }
    })
  }

  /**
   * @description Handles changes of a to page input.
   * @param {*} value The new value.
   */
  handleChangeToPage = value => {
    this.setState({
      printConfig: {
        ...this.state.printConfig,
        fromPage: { ...this.state.printConfig.fromPage, error: '' },
        toPage: { value, error: '' }
      }
    })
  }

  /**
   * @description Handles changes of a field.
   * @param {String} tab The current tab.
   * @param {String} key The key of the field.
   * @param {*} value The new value.
   */
  handleInputChange = (key, value, errorkey) => {
    this.setState({
      printConfig: {
        ...this.state.printConfig,
        [key]: typeof this.state.printConfig[key] === 'object'
          ? { ...this.state.printConfig[key], value, errorkey }
          : value
      }
    })
  }

  handlePrintParameter = value => {
    const { printConfig } = this.state
    const { printInfo } = this.props
    const outputChannelID = value === 0
      ? { value: printConfig.outputChannelID.backup || '', errorkey: '', backup: '' }
      : { value: printInfo[0].data.DOCDCR || '', errorkey: '', backup: printConfig.outputChannelID.value }
    const outputFormatID = value === 0
      ? { value: printConfig.outputFormatID.backup || '', errorkey: '', backup: '' }
      : { value: printInfo[0].data.DOCPCR || '', errorkey: '', backup: printConfig.outputFormatID.value }
    const ppnID = value === 0
      ? { value: printConfig.ppnID.backup || '', errorkey: '', backup: '' }
      : { value: printInfo[0].data.DOCPPN || '', errorkey: '', backup: printConfig.ppnID.value }
    this.setState({
      printConfig: {
        ...this.state.printConfig,
        outputChannelID,
        outputFormatID,
        ppnID,
        printParameterIndex: value
      }
    })
  }

  validateOutputChannelID = () => {
    if (this.state.printConfig.outputChannelID.value !== '') {
      return {}
    }
    return {
      outputChannelID: {
        ...this.state.printConfig.outputChannelID,
        errorkey: translate('general.input_required')
      }
    }
  }

  validateOutputFormatID = () => {
    if (this.props.userprofile?.VCIPCRRE) {
      if (this.state.printConfig.outputFormatID.value !== '') {
        return {}
      }
      return {
        outputFormatID: {
          ...this.state.printConfig.outputFormatID,
          errorkey: translate('general.input_required')
        }
      }
    }
    return {}
  }

  validateRecipientID = () => {
    if (this.props.userprofile?.VCIRECRE) {
      if (this.state.printConfig.recipientID.value !== '') {
        return {}
      }
      return {
        recipientID: {
          ...this.state.printConfig.recipientID,
          errorkey: translate('general.input_required')
        }
      }
    }
    return {}
  }

  validatePPN = () => {
    if (this.props.userprofile?.VCIPPNRE) {
      if (this.state.printConfig.ppnID.value !== '') {
        return {}
      }
      return {
        ppnID: {
          ...this.state.printConfig.ppnID,
          errorkey: translate('general.input_required')
        }
      }
    }
    return {}
  }

  validateFromPageToPage = () => {
    if (this.state.printConfig.fromPage.value <= this.state.printConfig.toPage.value) {
      return {}
    }
    return {
      fromPage: {
        ...this.state.printConfig.fromPage,
        error: translate('error.from_page')
      },
      toPage: {
        ...this.state.printConfig.toPage,
        error: translate('error.to_page')
      }
    }
  }

  validateInputs = () => {
    const { printConfig } = this.state
    let validatorResult = {
      ...this.validateRecipientID(),
      ...this.validateFromPageToPage()
    }
    if (printConfig.printParameterIndex === 0) {
      validatorResult = {
        ...validatorResult,
        ...this.validateOutputChannelID(),
        ...this.validateOutputFormatID(),
        ...this.validatePPN(),
      }
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ printConfig: { ...this.state.printConfig, ...validatorResult } }, () => {
        this.handleFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input which has an error.
   */
  handleFocus = () => {
    const requiredInputs = [
      { inputRef: this.outputChannelInput, error: this.state.printConfig.outputChannelID.errorkey },
      { inputRef: this.outputFormatInput, error: this.state.printConfig.outputFormatID.errorkey },
      { inputRef: this.ppnInput, error: this.state.printConfig.ppnID.errorkey },
      { inputRef: this.recipientInput, error: this.state.printConfig.recipientID.errorkey },
      { inputRef: this.fromPageInput, error: this.state.printConfig.recipientID.error },
      { inputRef: this.toPageInput, error: this.state.printConfig.recipientID.error }
    ]
    Utils.setFocus(requiredInputs)
  }

  nextDocument = () => {
    const { currentPrint } = this.state
    const { onClose, documentsToPrint } = this.props
    if (documentsToPrint && currentPrint < Object.values(documentsToPrint).length) {
      this.setState({ currentPrint: currentPrint + 1 }, () => this.setDefaultValues())
    }
    else {
      onClose()
    }
  }

  /**
   * @description Saves the values in multiple requests. One request for each tab.
   */
  handleSubmit = () => {
    const { document, printDocument, refreshFunction, documentsToPrint } = this.props
    const { printConfig, currentPrint } = this.state

    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentPrint - 1].docInfo : document

    const closeCallback = successObjects => {
      if (successObjects !== undefined && successObjects.length > 0) {
        this.nextDocument()
      }
    }

    if (this.validateInputs()) {
      const printObj = {
        'DOCIDS': [documentToUse.DOCID],
        'FROMTO': `${printConfig.fromPage.value}-${printConfig.toPage.value}`,
        'COPIES': printConfig.copies,
        'PRTPRIO': printConfig.priority,
        'RECI': printConfig.recipientID.value,
        'PPN': printConfig.ppnID.value,
        'PCR': printConfig.outputFormatID.value,
        'DCR': printConfig.outputChannelID.value,
        'USEFROM': printConfig.printParameterIndex ? 'DEFINITION' : 'PROFILE'
      }
      printDocument(printObj, closeCallback, refreshFunction)
    }
  }

  handleSubmitAll = () => {
    const { onClose, printDocument, refreshFunction, documentsToPrint } = this.props
    const { printConfig, currentPrint } = this.state

    const documents = Object.values(documentsToPrint)
    const docids = []
    for (let i = currentPrint - 1; i < documents.length; i++) {
      docids.push(documents[i]?.docInfo?.DOCID)
    }
    if (this.validateInputs()) {
      const printObj = {
        'DOCIDS': docids,
        'COPIES': printConfig.copies,
        'PRTPRIO': printConfig.priority,
        'RECI': printConfig.recipientID.value,
        'PPN': printConfig.ppnID.value,
        'PCR': printConfig.outputFormatID.value,
        'DCR': printConfig.outputChannelID.value,
        'USEFROM': printConfig.printParameterIndex ? 'DEFINITION' : 'PROFILE'
      }
      printDocument(printObj, onClose, refreshFunction)
    }
  }

  /**
   * @description: Loads the outputchannel-definitions and show them in a modal-selector
   */
  openOutputChannelSelector = () => {
    const { getOutputChannelDefinitions } = this.props

    getOutputChannelDefinitions(
      ['DCR', 'DCRTITLE'],
      this.state.printConfig.outputChannelID.value,
      () => this.setState({ showOutputChannelSelectorDialog: true }))
  }

  /**
   * @description: Loads the outputformat-definitions and show them in a modal-selector
   */
  openOutputFormatSelector = () => {
    const { getOutputFormatDefinitions } = this.props

    getOutputFormatDefinitions(
      ['PCR', 'PCRTITLE'],
      this.state.printConfig.outputFormatID.value,
      () => this.setState({ showOutputFormatSelectorDialog: true }))
  }

  /**
   * @description: Loads the postprocessingnote-definitions and show them in a modal-selector
   */
  openPostprocessingNoteSelector = () => {
    const { getPostprocessingNoteDefinitions } = this.props

    getPostprocessingNoteDefinitions(
      ['PPN', 'PPNTITLE'],
      this.state.printConfig.ppnID.value,
      () => this.setState({ showPostprocessingNoteSelectorDialog: true }))
  }

  /**
   * @description: Loads the recipient-definitions and show them in a modal-selector
   */
  openRecipientSelector = () => {
    const { getRecipientDefinitions } = this.props

    getRecipientDefinitions(
      ['RECI', 'TITLE'],
      this.state.printConfig.recipientID.value,
      () => this.setState({ showRecipientSelectorDialog: true }))
  }


  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id } = this.props
    const { outputchannels, outputformats, ppns, recipients } = this.props.selector
    const {
      showOutputChannelSelectorDialog,
      showOutputFormatSelectorDialog,
      showPostprocessingNoteSelectorDialog,
      showRecipientSelectorDialog
    } = this.state

    return (
      <>
        {showOutputChannelSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputchannelselector_dialog`}
            onClose={() => {
              this.setState({
                showOutputChannelSelectorDialog: false
              })
            }}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('definition.output_channel_id'),
              translate('general.title'),
            ]}
            items={outputchannels.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputChannel = outputchannels.data[selectedRows][outputchannels.header.indexOf('DCR')]
                this.setState({
                  printConfig: {
                    ...this.state.printConfig,
                    outputChannelID: { value: newOutputChannel, errorkey: '' },
                  },
                  showOutputChannelSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputChannelSelectorDialog: false
                })
              }
            }}
          />
        )}
        {showOutputFormatSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_outputformatselector_dialog`}
            onClose={() => {
              this.setState({
                showOutputFormatSelectorDialog: false
              })
            }}
            title={translate('definition.outputformatdefinitions')}
            header={[
              translate('definition.output_format_id'),
              translate('general.title')
            ]}
            items={outputformats.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputFormat = outputformats.data[selectedRows][outputformats.header.indexOf('PCR')]
                this.setState({
                  printConfig: {
                    ...this.state.printConfig,
                    outputFormatID: { value: newOutputFormat, errorkey: '' },
                  },
                  showOutputFormatSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputFormatSelectorDialog: false
                })
              }
            }}
          />
        )}
        {showPostprocessingNoteSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_postprocessingnoteselector_dialog`}
            onClose={() => {
              this.setState({
                showPostprocessingNoteSelectorDialog: false
              })
            }}
            title={translate('definition.post_processing_note_definition')}
            header={[
              translate('definition.ppn_id'),
              translate('general.title')
            ]}
            items={ppns.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newPostprocessingNode = ppns.data[selectedRows][ppns.header.indexOf('PPN')]
                this.setState({
                  printConfig: {
                    ...this.state.printConfig,
                    ppnID: { value: newPostprocessingNode, errorkey: '' },
                  },
                  showPostprocessingNoteSelectorDialog: false
                })
              } else {
                this.setState({
                  showPostprocessingNoteSelectorDialog: false
                })
              }
            }}
          />
        )}
        {showRecipientSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_recipientselector_dialog`}
            onClose={() => {
              this.setState({
                showRecipientSelectorDialog: false
              })
            }}
            title={translate('definition.recipientdefinitions')}
            header={[
              translate('recipient.reci_id'),
              translate('general.title')
            ]}
            items={recipients.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newRecipient = recipients.data[selectedRows][recipients.header.indexOf('RECI')]

                this.setState({
                  printConfig: {
                    ...this.state.printConfig,
                    recipientID: { value: newRecipient, errorkey: '' },
                  },
                  showRecipientSelectorDialog: false
                })
              } else {
                this.setState({
                  showRecipientSelectorDialog: false
                })
              }
            }}
          />
        )}
      </>
    )
  }

  disableInput = userprofileParam => userprofileParam || this.state.printConfig.printParameterIndex === 1

  handleFromPageOnBlur = () => {
    const { printConfig } = this.state
    if (printConfig.fromPage.value > printConfig.toPage.value) {
      this.setState({
        printConfig: {
          ...this.state.printConfig,
          fromPage: { ...this.state.printConfig.fromPage, error: translate('error.from_page') },
          toPage: { ...this.state.printConfig.toPage, error: translate('error.to_page') }
        }
      })
    }
  }

  handleToPageOnBlur = () => {
    const { printConfig } = this.state
    if (printConfig.fromPage.value > printConfig.toPage.value) {
      this.setState({
        printConfig: {
          ...this.state.printConfig,
          fromPage: { ...this.state.printConfig.fromPage, error: translate('error.from_page') },
          toPage: { ...this.state.printConfig.toPage, error: translate('error.to_page') }
        }
      })
    }
  }

  render = () => {
    const { id, onClose, userprofile, document, documentsToPrint } = this.props
    const { printConfig, currentPrint, printAll } = this.state
    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentPrint - 1].docInfo : document

    return (
      <Modal onClose={onClose} id={`${id}_documentprint`}>
        <Header
          id={`${id}_documentprint`}
          title={translate('documentprint.modaltitle')}
          onClose={onClose}>
          <HeaderContent id={`${id}_documentprint_modal`} props={this.props} currentPrint={currentPrint} />
        </Header>
        <Main
          id={`${id}_documentprint_modal_main`}>
          {this.renderSelectorDialogs()}
          <Card>
            <Row>
              <Column colMD={4}>
                <Switch
                  id={`${id}_printparameter`}
                  title={translate('documentprint.print_param')}
                  onClick={value => this.handlePrintParameter(value)}
                  activeIndex={printConfig.printParameterIndex}
                  items={[
                    translate('documentprint.user'),
                    translate('documentprint.default')
                  ]}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Input
                  id={`${id}_outputchannel`}
                  onInputChanged={(val, err) => { this.handleInputChange('outputChannelID', val, err) }}
                  title={translate('definition.output_channel_id')}
                  value={printConfig.outputChannelID.value}
                  error={printConfig.outputChannelID.errorkey}
                  ref={this.outputChannelInput}
                  required={`${translate('general.required_field')}`}
                  disabled={this.disableInput(userprofile && userprofile.VCIPRDI)}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.openOutputChannelSelector(),
                  }}
                  onBlur={() => this.setState(state => ({ printConfig: { ...state.printConfig, ...this.validateOutputChannelID() } }))}
                />
              </Column>
              <Column colMD={3}>
                <Input
                  id={`${id}_outputformat`}
                  onInputChanged={(val, err) => { this.handleInputChange('outputFormatID', val, err) }}
                  title={translate('definition.output_format_id')}
                  value={printConfig.outputFormatID.value}
                  error={printConfig.printParameterIndex === 0 && printConfig.outputFormatID.errorkey}
                  ref={this.outputFormatInput}
                  maxLength={16}
                  required={userprofile ? userprofile.VCIPCRRE : false}
                  disabled={this.disableInput(userprofile && userprofile.VCIPCRDI)}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.openOutputFormatSelector(),
                  }}
                  onBlur={() => userprofile?.VCIPCRRE && this.setState(state => ({ printConfig: { ...state.printConfig, ...this.validateOutputFormatID() } }))}
                />
              </Column>
              <Column colMD={3}>
                <Input
                  id={`${id}_postprocessingnote`}
                  onInputChanged={(val, err) => { this.handleInputChange('ppnID', val, err) }}
                  title={translate('documentprint.postprocessingnote')}
                  value={printConfig.ppnID.value}
                  error={printConfig.printParameterIndex === 0 && printConfig.ppnID.errorkey}
                  ref={this.ppnInput}
                  maxLength={16}
                  required={userprofile ? userprofile.VCIPPNRE : false}
                  disabled={this.disableInput(userprofile && userprofile.VCIPPNDI)}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.openPostprocessingNoteSelector(),
                  }}
                  onBlur={() => userprofile?.VCIPPNRE && this.setState(state => ({ printConfig: { ...state.printConfig, ...this.validatePPN() } }))}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <hr />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_frompage`}
                  title={translate('documentprint.from_page')}
                  value={printConfig.fromPage.value}
                  error={printConfig.fromPage.error}
                  steps={1}
                  min={1}
                  max={documentToUse.SRCPAGES}
                  onChange={newVal => this.handleChangeFromPage(newVal)}
                  onBlur={() => this.handleFromPageOnBlur()}
                  focusRef={this.fromPageInput}
                  disabled={this.state.printAll}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_topage`}
                  title={translate('documentprint.to_page')}
                  value={printConfig.toPage.value}
                  error={printConfig.toPage.error}
                  max={documentToUse.SRCPAGES}
                  default={documentToUse.SRCPAGES}
                  steps={1}
                  min={1}
                  onChange={newVal => this.handleChangeToPage(newVal)}
                  onBlur={() => this.handleToPageOnBlur()}
                  focusRef={this.toPageInput}
                  disabled={this.state.printAll}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_copies`}
                  title={translate('documentprint.copies')}
                  value={printConfig.copies}
                  max={256}
                  steps={1}
                  min={1}
                  onChange={newVal => this.handleChange('copies', newVal)}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_priority`}
                  title={translate('documentprint.priority')}
                  value={userprofile && userprofile.VCIPRPIO === false ? 0 : printConfig.priority}
                  max={15}
                  steps={1}
                  min={0}
                  disabled={userprofile && !userprofile.VCIPRPIO}
                  onChange={newVal => { this.handleChange('priority', newVal) }}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Input
                  id={`${id}_recipient`}
                  onInputChanged={(val, err) => { this.handleInputChange('recipientID', val, err) }}
                  value={printConfig.recipientID.value}
                  title={translate('recipient.reci_id')}
                  error={printConfig.recipientID.errorkey}
                  ref={this.recipientInput}
                  required={userprofile ? userprofile.VCIRECRE : false}
                  disabled={userprofile ? userprofile.VCIRECDI : false}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.openRecipientSelector(),
                  }}
                  onBlur={() => userprofile?.VCIRECRE && this.setState(state => ({ printConfig: { ...state.printConfig, ...this.validateRecipientID() } }))}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          {
            documentsToPrint &&
            <>
              <span className={'badm_print_progress'}>{`${currentPrint} of ${Object.values(documentsToPrint).length} ${translate('general.items_lowercase')}`}</span>
              <Checkbox
                id={`${id}_print_all`}
                onCheck={isChecked => this.setState({ printAll: isChecked })}
                value={printAll}
                label={translate('general.print_all')}
              />
            </>
          }
          <Button
            id={`${id}_documentprint_modal_footer_cancel_button`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          {
            documentsToPrint &&
            <Button
              id={`${id}_skip_button`}
              text={translate('general.skip')}
              onClick={() => this.nextDocument()}
              disabled={printAll}
              tooltip={printAll && translate('print.skip_disabled')}
            />
          }
          <Button
            id={`${id}_documentprint_modal_footer_print_button`}
            text={printAll ? `${translate('documentprint.print')} (${Object.values(documentsToPrint).length - currentPrint + 1})` : translate('documentprint.print')}
            onClick={() => printAll ? this.handleSubmitAll() : this.handleSubmit()}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const HeaderContent = ({ id, props, currentPrint }) => {
  const document = props.documentsToPrint ? Object.values(props.documentsToPrint)[currentPrint - 1].docInfo : props.document
  const printInfo = props.documentsToPrint ? Object.values(props.documentsToPrint)[currentPrint - 1].printInfo : props.printInfo
  return (
    <MetaDataGrid
      id={`${id}_header`}
      metaData={[
        { label: translate('general.form'), value: document.FORM },
        { label: translate('general.extension'), value: document.EXT },
        { label: translate('general.report'), value: document.WREPORT },
        { label: translate('documentprint.header_dynamicreport'), value: document.REPORT },
        { label: translate('documentprint.header_datetime'), value: DateUtils.getDate(props.datemask, document.B93DATE, document.B93TIME.substring(0, 8)) },
        { label: translate('documentprint.header_doctype'), value: document.SRCJOBI },
        { label: translate('documentprint.header_encoding'), value: document.TEXTENCO },
        { label: translate('documentprint.header_title'), value: document.LTITLE },
        { label: translate('documentprint.header_pages'), value: document.SRCPAGES?.toString() },
        { label: translate('documentprint.header_copies'), value: (printInfo && printInfo[0]?.data?.SRCCOPY?.replace(/^0+/, '')) || '' },
      ]}
      columns={4}
    />
  )
}


const mapStateToProps = ({ auth, selector }) => {
  return {
    usertoken: auth.serverdata.token,
    lang: auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: auth.serverdata.preferences,
    selector: selector,
    userprofile: auth.serverdata.userprofile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    printDocument: (printObj, callback, refreshFunction) => {
      StandardSelectionActions.printDocument(printObj, callback, refreshFunction)(dispatch)
    },
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getOutputFormatDefinitions: (fields, outputFormat, callback) => {
      ModalSelectorActions.getOutputFormatDefinitions(
        fields,
        outputFormat,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getPostprocessingNoteDefinitions: (fields, ppn, callback) => {
      ModalSelectorActions.getPPNDefinitions(
        fields,
        ppn,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getRecipientDefinitions: (fields, recipient, callback) => {
      ModalSelectorActions.getRecipientDefinitions(
        fields,
        undefined, // rtype
        undefined, // distribution type
        recipient,
        undefined, // preRecipient
        undefined, // owner
        undefined, // title
        undefined, // outputchannel
        undefined, // outputformat
        undefined, // ppn
        callback)(dispatch)
    },

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPrint)