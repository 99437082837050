import * as ResultTableDefinitionActions from '../actions/ResultTableDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  keepPagination: undefined,
  previewResults: undefined,
  resultTable: undefined,
  resultTables: undefined,
  resultTableDefaultObjects: {
    BRWTAB: undefined,
    BRWUC4: undefined,
    BRWZOS: undefined,
    BRWCTM: undefined
  },
  resultTableObjects: undefined,
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get result tables actions
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_START:
      return { ...state }
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_SUCCESS:
      return { ...state, resultTables: action.payload.data, keepPagination: action.keepPagination }
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_FAILED:
      return { ...state }
    case ResultTableDefinitionActions.NO_RESULT_TABLE_DEFINITION_RESULT_TABLES_FOUND:
      return { ...state, resultTables: {} }
    /* ********************************************************************************** */
    // Definition get result table actions
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_SUCCESS:
      return { ...state, resultTable: action.payload.data }
    /* ********************************************************************************** */
    // Definition get result table default objects actions
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_START:
      return { ...state }
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_SUCCESS:
      return { ...state, resultTableDefaultObjects: { ...state.resultTableDefaultObjects, [action.key]: action.payload.data } }
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update result table actions
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_START:
      return { ...state }
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_SUCCESS:
      return { ...state }
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update result table actions
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_START:
      return { ...state }
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_SUCCESS:
      return { ...state, previewResults: action.payload }
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_FAILED:
      return { ...state }
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_NOT_FOUND:
      return { ...state }
    case ResultTableDefinitionActions.RESULT_TABLE_DEFINITION_REMOVE_PREVIEW_RESULTS:
      return { ...state, previewResults: undefined }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, resultTables: undefined, resultTable: undefined, resultTableDefaultObjects: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer