import * as SearchActions from './SearchActions'

export const GET_ZOS_JOBS_START = 'GET_ZOS_JOBS_START'
export const GET_ZOS_JOBS_SUCCESS = 'GET_ZOS_JOBS_SUCCESS'
export const GET_ZOS_JOBS_FAILED = 'GET_ZOS_JOBS_FAILED'

export const NO_ZOS_JOBS_FOUND = 'NO_ZOS_JOBS_FOUND'

export const GET_ZOS_JOB_START = 'GET_ZOS_JOB_START'
export const GET_ZOS_JOB_SUCCESS = 'GET_ZOS_JOB_SUCCESS'
export const GET_ZOS_JOB_FAILED = 'GET_ZOS_JOB_FAILED'

export const GET_ZOS_JOB_LOGS_START = 'GET_ZOS_JOB_LOGS_START'
export const GET_ZOS_JOB_LOGS_SUCCESS = 'GET_ZOS_JOB_LOGS_SUCCESS'
export const GET_ZOS_JOB_LOGS_FAILED = 'GET_ZOS_JOB_LOGS_FAILED'

export const NO_ZOS_JOB_LOGS_FOUND = 'NO_ZOS_JOB_LOGS_FOUND'

export const ZOS_JOBS_GET_LOG_START = 'ZOS_JOBS_GET_LOG_START'
export const ZOS_JOBS_GET_LOG_SUCCESS = 'ZOS_JOBS_GET_LOG_SUCCESS'
export const ZOS_JOBS_GET_LOG_FAILED = 'ZOS_JOBS_GET_LOG_FAILED'
export const ZOS_JOBS_NO_LOG_FOUND = 'ZOS_JOBS_NO_LOG_FOUND'

export const ZOS_JOBS_DOWNLOAD_LOG_START = 'ZOS_JOBS_DOWNLOAD_LOG_START'
export const ZOS_JOBS_DOWNLOAD_LOG_SUCCESS = 'ZOS_JOBS_DOWNLOAD_LOG_SUCCESS'
export const ZOS_JOBS_DOWNLOAD_LOG_FAILED = 'ZOS_JOBS_DOWNLOAD_LOG_FAILED'

export const ZOS_JOBS_DOWNLOAD_JOB_START = 'ZOS_JOBS_DOWNLOAD_JOB_START'
export const ZOS_JOBS_DOWNLOAD_JOB_SUCCESS = 'ZOS_JOBS_DOWNLOAD_JOB_SUCCESS'
export const ZOS_JOBS_DOWNLOAD_JOB_FAILED = 'ZOS_JOBS_DOWNLOAD_JOB_FAILED'

export const ZOS_JOBS_GET_LOG_PRINT_INFO_START = 'ZOS_JOBS_GET_LOG_PRINT_INFO_START'
export const ZOS_JOBS_GET_LOG_PRINT_INFO_SUCCESS = 'ZOS_JOBS_GET_LOG_PRINT_INFO_SUCCESS'
export const ZOS_JOBS_GET_LOG_PRINT_INFO_FAILED = 'ZOS_JOBS_GET_LOG_PRINT_INFO_FAILED'

export const ZOS_LOG_UPDATE_LOG_START = 'ZOS_LOG_UPDATE_LOG_START'
export const ZOS_LOG_UPDATE_LOG_SUCCESS = 'ZOS_LOG_UPDATE_LOG_SUCCESS'
export const ZOS_LOG_UPDATE_LOG_FAILED = 'ZOS_LOG_UPDATE_LOG_FAILED'

export const ZOS_GET_JOB_LOG_INFORMATION_START = 'ZOS_GET_JOB_LOG_INFORMATION_START'
export const ZOS_GET_JOB_LOG_INFORMATION_SUCCESS = 'ZOS_GET_JOB_LOG_INFORMATION_SUCCESS'
export const ZOS_GET_JOB_LOG_INFORMATION_FAILED = 'ZOS_GET_JOB_LOG_INFORMATION_FAILED'


export function getZosJobs(fields, searchObj, callback, keepPagination = false) {
  return dispatch => {
    SearchActions.getJobs(fields, searchObj, callback, keepPagination, {
      START: GET_ZOS_JOBS_START,
      SUCCESS: GET_ZOS_JOBS_SUCCESS,
      FAILED: GET_ZOS_JOBS_FAILED,
      NOT_FOUND: NO_ZOS_JOBS_FOUND,
      error: 'search.zos_loading_error'
    })(dispatch)
  }
}

export function getZosJobDetails(fields, jobObj, callback) {
  return dispatch => {
    SearchActions.getJobDetails(fields, jobObj, callback, {
      START: GET_ZOS_JOB_START,
      SUCCESS: GET_ZOS_JOB_SUCCESS,
      FAILED: GET_ZOS_JOB_FAILED,
      error: 'search.zos_details_error'
    })(dispatch)
  }
}

export function getZosJobLogs(jobObj, callback) {
  return dispatch => {
    SearchActions.getJobLogs(jobObj, callback, {
      START: GET_ZOS_JOB_LOGS_START,
      SUCCESS: GET_ZOS_JOB_LOGS_SUCCESS,
      FAILED: GET_ZOS_JOB_LOGS_FAILED,
      NOT_FOUND: NO_ZOS_JOB_LOGS_FOUND,
      error: 'search.zos_logs_loading_error'
    })(dispatch)
  }
}

export function getLog(fields, docid, process, callback) {
  return dispatch => {
    SearchActions.getLog(fields, docid, process, callback, {
      START: ZOS_JOBS_GET_LOG_START,
      SUCCESS: ZOS_JOBS_GET_LOG_SUCCESS,
      FAILED: ZOS_JOBS_GET_LOG_FAILED,
    })(dispatch)
  }
}

export function downloadLog(docid, downloadname, callback) {
  return dispatch => {
    SearchActions.downloadLog(docid, downloadname, callback, {
      START: ZOS_JOBS_DOWNLOAD_LOG_START,
      SUCCESS: ZOS_JOBS_DOWNLOAD_LOG_SUCCESS,
      FAILED: ZOS_JOBS_DOWNLOAD_LOG_FAILED,
    })(dispatch)
  }
}

export function downloadLogText(docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback) {
  return dispatch => {
    SearchActions.downloadLogText(docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback, {
      START: ZOS_JOBS_DOWNLOAD_LOG_START,
      SUCCESS: ZOS_JOBS_DOWNLOAD_LOG_SUCCESS,
      FAILED: ZOS_JOBS_DOWNLOAD_LOG_FAILED,
    })(dispatch)
  }
}

export function downloadLogHex(docid, downloadname, displayMode, showPageHeader, showResources, callback) {
  return dispatch => {
    SearchActions.downloadLogHex(docid, downloadname, displayMode, showPageHeader, showResources, callback, {
      START: ZOS_JOBS_DOWNLOAD_LOG_START,
      SUCCESS: ZOS_JOBS_DOWNLOAD_LOG_SUCCESS,
      FAILED: ZOS_JOBS_DOWNLOAD_LOG_FAILED,
    })(dispatch)
  }
}

export function downloadJob(fields, searchObj, callback) {
  return dispatch => {
    SearchActions.downloadJob(fields, searchObj, callback, {
      START: ZOS_JOBS_DOWNLOAD_JOB_START,
      SUCCESS: ZOS_JOBS_DOWNLOAD_JOB_SUCCESS,
      FAILED: ZOS_JOBS_DOWNLOAD_JOB_FAILED,
    })(dispatch)
  }
}

export function getPrintInfo(docids, callback) {
  return dispatch => {
    SearchActions.getPrintInfo(docids, callback, {
      START: ZOS_JOBS_GET_LOG_PRINT_INFO_START,
      SUCCESS: ZOS_JOBS_GET_LOG_PRINT_INFO_SUCCESS,
      FAILED: ZOS_JOBS_GET_LOG_PRINT_INFO_FAILED,
    })(dispatch)
  }
}

export function markArchive(logDefinition, searchObj, callback) {
  return dispatch => {
    SearchActions.markArchive(logDefinition, searchObj, callback, {
      START: ZOS_LOG_UPDATE_LOG_START,
      SUCCESS: ZOS_LOG_UPDATE_LOG_SUCCESS,
      FAILED: ZOS_LOG_UPDATE_LOG_FAILED,
    })(dispatch)
  }
}

export function markDelete(logDefinition, searchObj, callback) {
  return dispatch => {
    SearchActions.markDelete(logDefinition, searchObj, callback, {
      START: ZOS_LOG_UPDATE_LOG_START,
      SUCCESS: ZOS_LOG_UPDATE_LOG_SUCCESS,
      FAILED: ZOS_LOG_UPDATE_LOG_FAILED,
    })(dispatch)
  }
}

export function markReload(logDefinition, searchObj, callback) {
  return dispatch => {
    SearchActions.markReload(logDefinition, searchObj, callback, {
      START: ZOS_LOG_UPDATE_LOG_START,
      SUCCESS: ZOS_LOG_UPDATE_LOG_SUCCESS,
      FAILED: ZOS_LOG_UPDATE_LOG_FAILED,
    })(dispatch)
  }
}


export function getJobLogInformation(fields, joblogObj, callback) {
  return dispatch => {
    SearchActions.getJobLogInformation(fields, joblogObj, callback, {
      START: ZOS_GET_JOB_LOG_INFORMATION_START,
      SUCCESS: ZOS_GET_JOB_LOG_INFORMATION_SUCCESS,
      FAILED: ZOS_GET_JOB_LOG_INFORMATION_FAILED,
      error: 'search.zos_details_information_error'
    })(dispatch)
  }
}


export function displayJob(betaviewBaseurl, betaviewFavname, sdate, stime, jobname, jobid, ukey, callback) {
  return dispatch => {
    SearchActions.displayJob(betaviewBaseurl, betaviewFavname, sdate, stime, jobname, jobid, ukey, callback)(dispatch)
  }
}

export function displayLog(docid, betaviewBaseurl, betaviewFavname, ukey, callback) {
  return dispatch => {
    SearchActions.displayLog(docid, betaviewBaseurl, betaviewFavname, ukey, callback)(dispatch)
  }
}