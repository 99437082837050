import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import * as ObjectUtils from 'utils/ObjectUtils'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

export const GET_RELOAD_QUEUES_START = 'GET_RELOAD_QUEUES_START'
export const GET_RELOAD_QUEUES_SUCCESS = 'GET_RELOAD_QUEUES_SUCCESS'
export const GET_RELOAD_QUEUES_FAILED = 'GET_RELOAD_QUEUES_FAILED'
export const NO_RELOAD_QUEUES_FOUND = 'NO_RELOAD_QUEUES_FOUND'

export const GET_RELOAD_QUEUE_DETAILS_START = 'GET_RELOAD_QUEUE_DETAILS_START'
export const GET_RELOAD_QUEUE_DETAILS_SUCCESS = 'GET_RELOAD_QUEUE_DETAILS_SUCCESS'
export const GET_RELOAD_QUEUE_DETAILS_FAILED = 'GET_RELOAD_QUEUE_DETAILS_FAILED'

export const DELETE_RELOAD_QUEUE_START = 'DELETE_RELOAD_QUEUE_START'
export const DELETE_RELOAD_QUEUE_SUCCESS = 'DELETE_RELOAD_QUEUE_SUCCESS'
export const DELETE_RELOAD_QUEUE_FAILED = 'DELETE_RELOAD_QUEUE_FAILED'

/**
 * @description Calling the rest api and list reload queues.
 * @param {Object} searchParams The keywords for get reload queues.
 * @param {Boolean} keepPagination Flag, if a new search is done by user.
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function getReloadQueues(searchParams, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_RELOAD_QUEUES_START })

    // clear invalid values of object and encode
    searchParams = ObjectUtils.removeByValue(searchParams, [undefined, null, ''])

    const queryParams = []
    Object.keys(searchParams).forEach(key => queryParams.push(`${key}=${encodeURIComponent(searchParams[key])}`))

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/reload?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            // if (jsondata.data.length === 0) {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_RELOAD_QUEUES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_RELOAD_QUEUES_FAILED })
          }
        } else {
          dispatch({ type: GET_RELOAD_QUEUES_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.reload_queue_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_RELOAD_QUEUES_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and get details of a specific reload queue.
 * @param {String} ttoken The ttoken of the specific reload queue.
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function getReloadQueueDetails(ttoken, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_RELOAD_QUEUE_DETAILS_START })

    const queryParams = []
    if (ttoken) { queryParams.push(`TTOKEN=${encodeURIComponent(ttoken)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/reload/details?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_RELOAD_QUEUE_DETAILS_FAILED })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: GET_RELOAD_QUEUE_DETAILS_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.get_reload_queue_details_error', lang, [ttoken, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_RELOAD_QUEUE_DETAILS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and get details of a specific reload queue.
 * @param {String} docid The docid of the entry to be deleted
 * @param {Object} reloadQueue The object with keys
 * This param is only used to display information in snackbar, not for request
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function deleteReloadQueue(docid, reloadQueue, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let message = [`${Lang.translate('general.form')}: ${reloadQueue['FORM']}`]
    if (reloadQueue['EXT']) {
      message.push(`${Lang.translate('general.extension')}: ${reloadQueue['EXT']}`)
    }
    if (reloadQueue['REPORT']) {
      message.push(`${Lang.translate('general.report')}: ${reloadQueue['REPORT']}`)
    }
    const assignmentMessage = message.join(', ')

    dispatch({ type: DELETE_RELOAD_QUEUE_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/reload${createQueryParamsForFetch({ DOCIDS: docid })}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.additionalInfo[0].error.rc
          let irc = jsondata.additionalInfo[0].error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.additionalInfo[0].error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(Lang.translate('queue.delete_reload_queue_error', lang, [assignmentMessage, error.message]), error.type)(dispatch)
          dispatch({ type: DELETE_RELOAD_QUEUE_FAILED })
        } else {
          dispatch({ type: DELETE_RELOAD_QUEUE_SUCCESS })

          SnackbarActions.show(Lang.translate('queue.delete_reload_queue_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }
          const searchParams = {
            SDATE: prefs[Preferences.QUEUE_RELOAD_START_DATE],
            STIME: prefs[Preferences.QUEUE_RELOAD_START_TIME],
            EDATE: prefs[Preferences.QUEUE_RELOAD_END_DATE],
            ETIME: prefs[Preferences.QUEUE_RELOAD_END_TIME],
            TUNITS: prefs[Preferences.QUEUE_RELOAD_TIME_CUSTOM_UNIT],
            FROMLAST: prefs[Preferences.QUEUE_RELOAD_TIME_CUSTOM_LAST],
            FORM: prefs[Preferences.QUEUE_RELOAD_FORM],
            EXT: prefs[Preferences.QUEUE_RELOAD_EXTENSION],
            REPORT: prefs[Preferences.QUEUE_RELOAD_REPORT],
            STATUS: prefs[Preferences.QUEUE_RELOAD_STATUS]
          }
          getReloadQueues(
            searchParams,
            undefined,
            true
          )(dispatch)
        }
      })
      .catch(error => {
        LoadingSpinnerActions.hide()(dispatch)
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.delete_reload_queue_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DELETE_RELOAD_QUEUE_FAILED })
      })
  }
}