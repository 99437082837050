import { Component } from 'react'


import './ServerActivity.scss'

// Utils
import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import * as Preferences from 'redux/general/Preferences'

// components
import {
  Button,
  Column, DataTable, Icon, Modal as ModalComponent,
  Row,
  Tab,
  Tabs, TitledLabel
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import * as UserUtils from 'utils/UserUtils'

// Redux
import { connect } from 'react-redux'
import {
  getActiveUsers, getComponents, getConnects, getEnqueues, getServerstatus, getTransactions
} from 'redux/actions/StatisticActions'
import { DDMMYYYY_DOT, TIME_DATEMASK_FULL, getFormattedDateTime } from 'utils/DateUtils'

class ServerActivity extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  interval = undefined

  componentDidMount = () => {
    const { getActiveUsers } = this.props
    const delay = 10000
    this.interval = setInterval(getActiveUsers, delay)
  }

  componentWillUnmount = () => {
    this.clearRunningInterval()
  }

  clearRunningInterval = () => {
    clearInterval(this.interval)
  }

  getDependenciesData = () => {
    const { dependencies, datemask } = this.props
    const PROCESS_ID = 'PID'
    const FUNCTION = 'FUNCTION'
    const APPLICATION = 'APPL'
    const LAST_REQUEST = 'LAST REQUEST'
    const USER = 'USER'
    const REST_PROCESS_ID = 'CLN-PID'
    const CLIENT_IP = 'CLIENT IP'
    const buffer = []
    dependencies.forEach(d => {
      const innerBuffer = [
        d[PROCESS_ID] || '',
        d[FUNCTION] || '',
        d[APPLICATION] || '',
        getFormattedDateTime(d[LAST_REQUEST], `${DDMMYYYY_DOT} ${TIME_DATEMASK_FULL}`, `${datemask} ${TIME_DATEMASK_FULL}`) || '',
        d[USER] || '',
        d[REST_PROCESS_ID] || '',
        d[CLIENT_IP] || '',
      ]
      buffer.push(innerBuffer)
    })
    return buffer
  }

  handleTabChanged = index => {
    const { getComponents, getServerstatus, getEnqueues, getConnects, getTransactions } = this.props
    const delay = 10000
    this.clearRunningInterval()
    switch (index) {
      case 0:
        getActiveUsers()
        this.interval = setInterval(getActiveUsers, delay)
        break
      case 1:
        getComponents()
        this.interval = setInterval(getComponents, delay)
        break
      case 2:
        getConnects()
        this.interval = setInterval(getConnects, delay)
        break
      case 3:
        getEnqueues()
        this.interval = setInterval(getEnqueues, delay)
        break
      case 4:
        getServerstatus()
        this.interval = setInterval(getServerstatus, delay)
        break
      case 5:
        getTransactions()
        this.interval = setInterval(getTransactions, delay)
        break
      default:
        return
    }
  }

  buildActiveUsersData = () => {
    const { activeUsers, datemask } = this.props
    return activeUsers.map(entry => {
      return [
        entry.USERID,
        entry.IP,
        entry.APPL,
        entry.STATUS,
        getFormattedDateTime(entry['LOGON TIME'], `${DDMMYYYY_DOT} ${TIME_DATEMASK_FULL}`, `${datemask} ${TIME_DATEMASK_FULL}`),
        getFormattedDateTime(entry['LAST REQUEST'], `${DDMMYYYY_DOT} ${TIME_DATEMASK_FULL}`, `${datemask} ${TIME_DATEMASK_FULL}`),
        entry.PAGES,
      ]
    })
  }

  buildComponentsData = () => {
    const { components, datemask } = this.props
    return components.map(entry => {
      return [
        entry.COMPONENT,
        entry.IP,
        entry.PORT,
        entry.PID,
        getFormattedDateTime(entry['CONNECT TIME'], `${DDMMYYYY_DOT} ${TIME_DATEMASK_FULL}`, `${datemask} ${TIME_DATEMASK_FULL}`),
        entry.PROGRAMM,
        entry['WORK DIRECTORY'],
      ]
    })
  }

  buildEnqueuesData = () => {
    const { enqueues } = this.props
    return enqueues.map(entry => {
      return [
        entry.NAME,
        entry.ENQ,
        entry.DEQ,
        entry.PID,
        entry.STACK,
        entry['SEM-PID'],
        entry['SEM-NCNT'],
        entry['SEM-VAL'],
      ]
    })
  }

  buildTransactionsData = () => {
    const { transactions } = this.props
    return transactions.map(entry => {
      return [
        entry.STATE,
        entry.PID,
        entry.FUNCTION,
        entry.MEMORY,
        entry.SHARED,
        entry.GETS,
        entry.PUTS,
        entry.TCPRECEIVEPKTS,
        entry.TCPRECEIVEBYTES,
        entry.TCPSENDPKTS,
        entry.TCPSENDBYTES,
        entry.LOCK,
        entry.UNLOCK,
        entry.ENQUEUES,
        entry.DEQUEUES,
      ]
    })
  }

  render = () => {
    const { id = 'serveractivity', activeUsers, components, dependencies, enqueues, status, transactions, onClose, lang, datemask, serverdata, userprofile, restApiVersion } = this.props
    const activeUsersData = activeUsers && activeUsers.length > 0 ? this.buildActiveUsersData() : undefined
    const componentsData = components && components.length > 0 ? this.buildComponentsData() : undefined
    const dependenciesData = dependencies && dependencies.length > 0 ? this.getDependenciesData() : undefined
    const enqueuesData = enqueues && enqueues.length > 0 ? this.buildEnqueuesData() : undefined
    const transactionsData = transactions && transactions.length > 0 ? this.buildTransactionsData() : undefined

    return (
      <Modal
        onClose={onClose}
        id={id}
        className='bux_UserProfileModal'>
        <Header
          id={id}
          title={translate('server_activity')}
          onClose={onClose}>
          {
            status &&
            <>
              <Row>
                <Column colMD={4}>
                  <TitledLabel
                    id={`${id}_bsarelease`}
                    title={translate('server_activity.status_bsa_release')}
                    text={status && [status.BSARELEASE, ` (${status.BSARELEASEDATE})`]}
                  />
                </Column>
                <Column colMD={2}>
                  <TitledLabel
                    id={`${id}_type`}
                    title={translate('general.type')}
                    text={serverdata.server.type}
                  />
                </Column>
                <Column colMD={3}>
                  <TitledLabel
                    id={`${id}_version`}
                    title={translate('general.version')}
                    text={`${serverdata.server.version} / ${restApiVersion ? `${restApiVersion.version} (${restApiVersion.buildhash})` : ''}`}
                  />
                </Column>
                <Column colMD={3}>
                  <TitledLabel
                    id={`${id}_startpid`}
                    title={translate('server_activity.status_start_pid')}
                    text={status && status.PID}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={4}>
                  <TitledLabel
                    id={`${id}_servername`}
                    title={translate('server_activity.servername')}
                    text={
                      <div className='bux_icon_text_row'>
                        <Icon
                          id={`${id}_server_encrypted`}
                          className={`bux_small_icon beta_icon_ ${serverdata.secure ? 'bux_server_encrypted ' : 'bux_server_not_encrypted'}`}
                          name={serverdata.secure ? 'lock_control' : 'unlock_control'}
                          title={`${serverdata.secure ? translate('server_activity.backend_encrypted') : translate('server_activity.backend_unencrypted')}`}
                        />
                        {serverdata.systemname}
                      </div>
                    }
                  />
                </Column>
                <Column colMD={2}>
                  <TitledLabel
                    id={`${id}_ip`}
                    title={translate('server_activity.active_users_ip')}
                    text={`${serverdata.ip}:${serverdata.port}`}
                  />
                </Column>
                <Column colMD={3}>
                  <TitledLabel
                    id={`${id}_serverstart`}
                    title={translate('server_activity.status_server_start')}
                    text={status && getFormattedDateTime(status.SERVERSTART, `${DDMMYYYY_DOT} ${TIME_DATEMASK_FULL}`, `${datemask} ${TIME_DATEMASK_FULL}`)}
                  />
                </Column>
                <Column colMD={3}>
                  <TitledLabel
                    id={`${id}_timeshift_to_server`}
                    title={translate('server_activity.timeshift_to_server')}
                    text={parseInt(serverdata.timeshift) >= 0 ? `+${serverdata.timeshift} min` : `${serverdata.timeshift} min`}
                  />
                </Column>
              </Row>
            </>
          }
        </Header>
        <Main id={id}>
          {
            // VCIENPRM is the flag field, if the monitioring is enabled for subadmin.
            (UserUtils.isAdmin() || userprofile.VCIENPRM) &&
            <Tabs id={id} onTabChanged={index => this.handleTabChanged(index)} className={'bux_modal_datatable_container'}>
              <Tab title={translate('server_activity.active_users')}>
                <Row className={'bux_full_height'}>
                  <Column colMD={12}>
                    {
                      activeUsersData &&
                      <div style={{ marginBottom: 0, height: '100%' }}>
                        <DataTable
                          id={`${id}_active_users`}
                          header={[
                            translate('server_activity.active_users_user_id'),
                            translate('server_activity.active_users_ip'),
                            translate('server_activity.active_users_application'),
                            translate('general.status'),
                            translate('server_activity.active_users_logon_time'),
                            translate('server_activity.dependencies_last_request'),
                            translate('server_activity.active_users_pages'),
                          ]}
                          data={activeUsersData}
                          columnSortDefs={['string', 'string', 'string', 'string', 'date-time', 'date-time', 'number']}
                          noAction
                          language={lang}
                          datemask={datemask}
                          translate={key => translate(key)}
                          menu={false}
                        />
                      </div>
                    }
                  </Column>
                </Row>
              </Tab>
              <Tab title={translate('server_activity.components')}>
                <Row>
                  <Column colMD={12}>
                    {
                      componentsData &&
                      <div style={{ marginBottom: 0 }}>
                        <DataTable
                          id={`${id}_components`}
                          header={[
                            translate('server_activity.components_component'),
                            translate('server_activity.components_ip'),
                            translate('server_activity.components_port'),
                            translate('server_activity.components_process_id'),
                            translate('server_activity.connection_time'),
                            translate('server_activity.components_programm'),
                            translate('server_activity.components_work_directory')
                          ]}
                          data={componentsData}
                          columnSortDefs={['string', 'string', 'string', 'string', 'date-time', 'string', 'string']}
                          noAction
                          language={lang}
                          datemask={datemask}
                          translate={key => translate(key)}
                          menu={false}
                        />
                      </div>
                    }
                  </Column>
                </Row>
              </Tab>
              <Tab title={translate('server_activity.dependencies')}>
                <Row>
                  <Column colMD={12}>
                    {
                      dependenciesData &&
                      <div style={{ marginBottom: 0 }}>
                        <DataTable
                          id={`${id}_dependencies`}
                          header={[
                            translate('server_activity.dependencies_process_id'),
                            translate('server_activity.dependencies_function'),
                            translate('server_activity.dependencies_application'),
                            translate('server_activity.dependencies_last_request'),
                            translate('server_activity.dependencies_user'),
                            translate('server_activity.dependencies_client_process_id'),
                            translate('server_activity.dependencies_client_ip')
                          ]}
                          data={dependenciesData}
                          columnSortDefs={['string', 'string', 'string', 'date-time', 'string', 'string']}
                          noAction
                          language={lang}
                          datemask={datemask}
                          translate={key => translate(key)}
                          menu={false}
                        />
                      </div>
                    }
                  </Column>
                </Row>
              </Tab>
              <Tab title={translate('server_activity.enqueues')}>
                <Row>
                  <Column colMD={12}>
                    {
                      enqueuesData &&
                      <DataTable
                        id={`${id}_enqueues`}
                        header={[
                          translate('server_activity.enqueues_name'),
                          translate('server_activity.enqueues_enqueues'),
                          translate('server_activity.enqueues_dequeues'),
                          translate('server_activity.enqueues_process_id'),
                          translate('server_activity.enqueues_stack'),
                          translate('server_activity.enqueues_semaphore_process_id'),
                          translate('server_activity.enqueues_semaphore_ncnt'),
                          translate('server_activity.enqueues_semaphore_value'),
                        ]}
                        data={enqueuesData}
                        columnSortDefs={['string', 'number', 'number', 'string', 'number', 'string', 'string']}
                        noAction
                        language={lang}
                        datemask={datemask}
                        translate={key => translate(key)}
                        menu={false}
                      />
                    }
                  </Column>
                </Row>
              </Tab>
              <Tab title={translate('server_activity.status')}>
                <Row>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_bqlgets`}
                      title={translate('server_activity.status_bql_gets')}
                      text={status && status['BQL GETS']}
                    />
                  </Column>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_ipsend`}
                      title={translate('server_activity.status_ip_send')}
                      // text={status && status.ip && status.ip.send}
                      text={status && status['IP /SEND']}
                    />
                  </Column>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_iprecv`}
                      title={translate('server_activity.status_ip_recv')}
                      text={status && status['IP /RECV']}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_bqlputs`}
                      title={translate('server_activity.status_bql_puts')}
                      text={status && status['BQL PUTS']}
                    />
                  </Column>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_sendinbyte`}
                      title={translate('server_activity.status_send_in_byte')}
                      text={status && status['SEND IN BYTE']}
                    />
                  </Column>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_recvinbyte`}
                      title={translate('server_activity.status_recv_in_byte')}
                      text={status && status['RECV IN BYTE']}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_locks`}
                      title={translate('server_activity.status_locks')}
                      text={status && status.LOCKS}
                    />
                  </Column>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_enq`}
                      title={translate('server_activity.status_enq')}
                      text={status && status.ENQ}
                    />
                  </Column>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_proc`}
                      title={translate('server_activity.status_proc')}
                      text={status && `${status['CURRENT PROC']} / ${status['MAX PROC']}`}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_unlocks`}
                      title={translate('server_activity.status_unlocks')}
                      text={status && status.UNLOCKS}
                    />
                  </Column>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_deq`}
                      title={translate('server_activity.status_deq')}
                      text={status && status.DEQ}
                    />
                  </Column>
                  <Column colMD={4}>
                    <TitledLabel
                      id={`${id}_users`}
                      title={translate('server_activity.status_users')}
                      text={status && `${status.USERS} | ${status['REMOTE USERS']}`}
                    />
                  </Column>
                </Row>
              </Tab>
              <Tab title={translate('server_activity.transactions')}>
                <Row>
                  <Column colMD={12}>
                    {
                      transactionsData &&
                      <div style={{ marginBottom: 0 }}>
                        <DataTable
                          id={`${id}_transactions`}
                          header={[
                            translate('server_activity.transactions_state'),
                            translate('server_activity.transactions_process_id'),
                            translate('server_activity.transactions_function'),
                            translate('server_activity.transactions_memory'),
                            translate('server_activity.transactions_shared'),
                            translate('server_activity.transactions_gets'),
                            translate('server_activity.transactions_puts'),
                            translate('server_activity.transactions_tcp_received_packages'),
                            translate('server_activity.transactions_tcp_received_bytes'),
                            translate('server_activity.transactions_tcp_send_packages'),
                            translate('server_activity.transactions_tcp_send_bytes'),
                            translate('server_activity.transactions_lock'),
                            translate('server_activity.transactions_unlock'),
                            translate('server_activity.transactions_enqueues'),
                            translate('server_activity.transactions_dequeues'),
                          ]}
                          data={transactionsData}
                          columnSortDefs={['string', 'string', 'string', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'number']}
                          noAction
                          language={lang}
                          datemask={datemask}
                          translate={key => translate(key)}
                          menu={false}
                        />
                      </div>
                    }
                  </Column>
                </Row>
              </Tab>
            </Tabs>
          }
        </Main>
        <Footer>
          <Button
            id={`${id}_closebutton`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth, statistic }) => {
  return {
    activeUsers: statistic.activeUsers,
    components: statistic.components,
    dependencies: statistic.connects,
    enqueues: statistic.enqueues,
    status: statistic.serverstatus,
    transactions: statistic.transactions,
    lang: auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: auth.serverdata.preferences[Preferences.DATEMASK],
    serverdata: auth.serverdata,
    userprofile: auth.serverdata && auth.serverdata.userprofile,
    restApiVersion: auth.restApiVersion
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getActiveUsers: () => getActiveUsers()(dispatch),
    getComponents: () => getComponents()(dispatch),
    getServerstatus: () => getServerstatus()(dispatch),
    getEnqueues: () => getEnqueues()(dispatch),
    getConnects: () => getConnects()(dispatch),
    getTransactions: () => getTransactions()(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerActivity)