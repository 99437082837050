// actions
export const SNACKBAR_SHOW = 'SNACKBAR_SHOW'
export const SNACKBAR_MULTIPLE_SHOW = 'SNACKBAR_MULTIPLE_SHOW'
export const SNACKBAR_HIDE = 'SNACKBAR_HIDE'
export const SNACKBAR_REMOVE = 'SNACKBAR_REMOVE'

// types
export const TYPE_SUCCESS = 'success'
export const TYPE_INFO = 'info'
export const TYPE_ERROR = 'error'

export function showMultiple(messageTypeObjs, customTitle) {
  return dispatch => {
    dispatch({
      type: SNACKBAR_MULTIPLE_SHOW,
      payload: {
        messageTypeObjs,
        customTitle
      }
    })
  }
}

export function show(message, type) {
  return dispatch => {
    dispatch({
      type: SNACKBAR_SHOW,
      payload: {
        message: message,
        type: type
      }
    })
  }
}

export function deleteMessage(idToRemove) {
  return dispatch => {
    dispatch({
      type: SNACKBAR_REMOVE,
      payload: {
        idToRemove
      }
    })
  }
}