import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import { Component } from 'react'
import * as SystemDefaultUtils from 'utils/SystemDefaultUtils'

// Components
import {
  Button, Card, Column, MetaDataGrid, Modal as ModalComponent, NumericSpinner, Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

class SystemDefaultsNumericSpinnerDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    parameter: PropTypes.string.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    min: PropTypes.number,
  }

  state = {
    value: this.props.value
  }

  /**
   * @description Renders the header.
   */
  renderHeader() {
    const { id, parameter } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('general.parameter'), value: SystemDefaultUtils.getParameterTranslation(parameter) },
        ]}
        columns={4}
      />
    )
  }

  render = () => {
    const { id, onClose, onSave, min, max, parameter } = this.props
    const { value } = this.state
    return (
      <Modal onClose={onClose} id={id} size={'s'}>
        <Header
          id={`${id}_modify_systemdefaults`}
          title={translate('systemdefaults.modify_systemdefaults')}
          onClose={() => onClose()}>
          {this.renderHeader()}
        </Header>
        <Main id={id}>
          <Card>
            <Row>
              <Column
                colMD={12}>
                <NumericSpinner
                  id={`${id}_value`}
                  title={translate('general.value')}
                  value={value}
                  max={max}
                  steps={1}
                  min={min ? min : 0}
                  onChange={newVal => { this.setState({ value: newVal}) }}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={id}>
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_footer_save`}
            text={translate('general.save')}
            onClick={() => { onSave(parameter, value.toString()) }}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

export default SystemDefaultsNumericSpinnerDialog