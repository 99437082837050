import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAILED = 'GET_USER_PROFILE_FAILED'

export const CREATE_USER_PROFILE_START = 'CREATE_USER_PROFILE_START'
export const CREATE_USER_PROFILE_SUCCESS = 'CREATE_USER_PROFILE_SUCCESS'
export const CREATE_USER_PROFILE_FAILED = 'CREATE_USER_PROFILE_FAILED'

export const DELETE_USER_PROFILE_START = 'DELETE_USER_PROFILE_START'
export const DELETE_USER_PROFILE_SUCCESS = 'DELETE_USER_PROFILE_SUCCESS'
export const DELETE_USER_PROFILE_FAILED = 'DELETE_USER_PROFILE_FAILED'

export const UPDATE_USER_PROFILE_START = 'UPDATE_USER_PROFILE_START'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED'

/**
 * @description Calls the rest api and gets the userprofile of an user.
 * @param {String} userid The userid to get the userprofile.
 * @param {Function} callback The callback which will be called when the get user profile request was successful.
 */
export function getUserProfile(userid, callback) {
  /* Escape URL-parameters */
  userid = encodeURIComponent(userid)

  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_USER_PROFILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/userprofile?B93USER=${userid}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          /* No data found in case of getUserProfile should open the "Create" dialog.
             This ist the only exception where this behaviour is wanted.
             So we ignore the GeneralErrorHandler in this case and run the callback */
          if (rc === '0016' && irc === '0000') {
            dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: jsondata.data })

            // call callback on success
            if (callback) {
              callback()
            }
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_USER_PROFILE_FAILED, payload: { error } })
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_userprofile_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_USER_PROFILE_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a userprofile of an user.
 * @param {String} userid The userid for which the userprofile should be created.
 * @param {String} cuser The userid which created the userprofile.
 * @param {Object} userprofile The userprofile to create which includes all information.
 * @param {Function} callback The callback which will be called when the create user profile request was successful.
 */
export function createUserProfile(userid, cuser, userprofile, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CREATE_USER_PROFILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/userprofile`, {
        method: 'post',
        body: {
          ...userprofile,
          B93USER: userid,
          CUSER: cuser,
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CREATE_USER_PROFILE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: CREATE_USER_PROFILE_START })

          SnackbarActions.show(Lang.translate('usermanagement.create_userprofile_success', lang, userid), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.create_userprofile_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CREATE_USER_PROFILE_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and deletes the userprofile of an user.
 * @param {String} userid The userid for which user the userprofile should be deleted.
 * @param {Functions} callback The callback which will be called when the delete user profile request was successful.
 */
export function deleteUserProfile(userid, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: DELETE_USER_PROFILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/userprofile${createQueryParamsForFetch({ B93USER: userid })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DELETE_USER_PROFILE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DELETE_USER_PROFILE_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.delete_userprofile_success', lang, userid), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.delete_userprofile_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DELETE_USER_PROFILE_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates the userprofile of an user.
 * @param {String} userid The userid for which user the userprofile should be updated.
 * @param {Object} userprofile The userprofile which includes the updated information.
 * @param {Function} callback The callback which will be called when the update user profile request was successful.
 */
export function updateUserProfile(userid, cuser, userprofile, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: UPDATE_USER_PROFILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/userprofile`, {
        method: 'put',
        body: {
          ...userprofile,
          B93USER: userid,
          CUSER: cuser,
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: UPDATE_USER_PROFILE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: UPDATE_USER_PROFILE_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.update_userprofile_success', lang, userid), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.update_userprofile_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPDATE_USER_PROFILE_FAILED, payload: { error } })
      })
  }
}