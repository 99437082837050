import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import { archiveMediaItems } from 'utils/DefinitionUtils'
import * as Utils from 'utils/Utils'

// Components
import {
  Button,
  Column, DataTable, Dropdown, Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row, Switch, Tab, TableButton, Tabs
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import { createArchiveSubpool, deleteArchiveSubpool, modifyArchivePool } from 'redux/actions/ArchivePoolDefinitionActions'
import * as Preferences from 'redux/general/Preferences'

import './ModifyArchivePoolDialog.scss'

class ModifyArchivePoolDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    generalTab: {
      title: '',
      orderForReload: 0,
      reloadOrderValue: 1
    },
    subpoolTab: {
      retentionPeriod: 1,
      retentionPeriod2: 1,
      orderForReload: 1,
      archiveMediaIndex: 0,
      fileSize: 1,
      fileName: {
        value: '',
        errorkey: ''
      },
      managedBy: 0,
      useStorageApi: 1,
      reloadScript: {
        dropdown: 0,
        input: {
          value: '',
          errorkey: ''
        }
      },
      deleteScript: {
        dropdown: 0,
        input: {
          value: '',
          errorkey: ''
        }
      }
    }
  }

  fileNameInput = React.createRef()
  reloadScriptInput = React.createRef()
  deleteScriptInput = React.createRef()

  componentDidMount = () => {
    const { archivePool } = this.props
    this.setState({
      generalTab: {
        title: archivePool[0].ADPTITLE,
        orderForReload: archivePool[0].ADPORDER <= 99 ? 1 : 0,
        reloadOrderValue: archivePool[0].ADPORDER <= 99 ? archivePool[0].ADPORDER : 1
      }
    })
  }

  handleGeneralTab = (key, val) => {
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        [key]: val
      }
    })
  }

  handleSubpoolTab = (key, val) => {
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        [key]: val
      }
    })
  }

  handleFileNameMask = value => {
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        fileName: {
          value,
          errorkey: ''
        }
      }
    })
  }

  handleReloadScriptDropdown = val => {
    const { subpoolTab } = this.state
    let reloadScriptInputValueToUse = subpoolTab.reloadScript.input.value
    // cuts the input valu if the length is bigger then 239 and the dropdown items is not 'other'
    if (val !== 2 && reloadScriptInputValueToUse.length > 239) {
      reloadScriptInputValueToUse = reloadScriptInputValueToUse.substring(239, 0)
    }
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        reloadScript: {
          ...this.state.subpoolTab.reloadScript,
          dropdown: val,
          input: {
            value: reloadScriptInputValueToUse,
            errorkey: ''
          }
        }
      }
    })
  }

  handleReloadScriptInput = val => {
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        reloadScript: {
          ...this.state.subpoolTab.reloadScript,
          input: {
            value: val,
            errorkey: ''
          }
        }
      }
    })
  }

  handleDeleteScriptDropdown = val => {
    const { subpoolTab } = this.state
    let deleteScriptInputValueToUse = subpoolTab.deleteScript.input.value
    // cuts the input valu if the length is bigger then 239 and the dropdown items is not 'other'
    if (val !== 2 && deleteScriptInputValueToUse.length > 239) {
      deleteScriptInputValueToUse = deleteScriptInputValueToUse.substring(239, 0)
    }
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        deleteScript: {
          ...this.state.subpoolTab.deleteScript,
          dropdown: val,
          input: {
            value: deleteScriptInputValueToUse,
            errorkey: ''
          }
        }
      }
    })
  }

  handleDeleteScriptInput = val => {
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        deleteScript: {
          ...this.state.subpoolTab.deleteScript,
          input: {
            value: val,
            errorkey: ''
          }
        }
      }
    })
  }

  validateFileNameMask = () => {
    const { subpoolTab } = this.state
    if (subpoolTab.fileName.value !== '') {
      return {}
    }
    return {
      fileName: {
        ...this.state.subpoolTab.fileName,
        errorkey: 'general.input_required'
      }
    }
  }

  validateReloadScriptCommand = () => {
    const { subpoolTab } = this.state
    if (subpoolTab.managedBy === 1) {
      if (subpoolTab.reloadScript.input.value !== '') {
        return {}
      }
      return {
        reloadScript: {
          ...this.state.subpoolTab.reloadScript,
          input: {
            ...this.state.subpoolTab.reloadScript.input,
            errorkey: 'general.input_required'
          }
        }
      }
    } else {
      return {}
    }
  }

  validateDeleteScriptCommand = () => {
    const { subpoolTab } = this.state
    if (subpoolTab.managedBy === 1) {
      if (subpoolTab.deleteScript.input.value !== '') {
        return {}
      }
      return {
        deleteScript: {
          ...this.state.subpoolTab.deleteScript,
          input: {
            ...this.state.subpoolTab.deleteScript.input,
            errorkey: 'general.input_required'
          }
        }
      }
    } else {
      return {}
    }
  }

  validateSubpoolTab = () => {
    const validatorResult = {
      ...this.validateFileNameMask(),
      ...this.validateReloadScriptCommand(),
      ...this.validateDeleteScriptCommand()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ subpoolTab: { ...this.state.subpoolTab, ...validatorResult } }, () => {
        this.handleSubpoolTabFocus()
      })
    }
    return errors === 0
  }

  handleSubpoolTabFocus = () => {
    const requiredInputs = [
      { inputRef: this.fileNameInput, error: this.state.subpoolTab.fileName.errorkey },
      { inputRef: this.reloadScriptInput, error: this.state.subpoolTab.reloadScript.input.errorkey },
      { inputRef: this.deleteScriptInput, error: this.state.subpoolTab.deleteScript.input.errorkey }
    ]
    Utils.setFocus(requiredInputs)
  }

  scriptItems = () => ['./buxbarcemc.exe', './buxbarctms.exe', translate('lpdqueue.transfer_other')]

  handleAddSubpool = () => {
    const { archivePool, createArchiveSubpool } = this.props
    const { subpoolTab } = this.state
    const callback = () => {
      this.setState({
        subpoolTab: {
          ...this.state.subpoolTab,
          retentionPeriod: 1,
          orderForReload: 1,
          archiveMediaIndex: 0,
          fileSize: 1,
          fileName: {
            value: ''
          },
          managedBy: 0,
          reloadScript: {
            dropdown: 0,
            input: {
              value: ''
            }
          },
          deleteScript: {
            dropdown: 0,
            input: {
              value: ''
            }
          },
        }
      })
    }

    let reloadValue = ''
    let deleteValue = ''

    if (subpoolTab.managedBy === 1) {
      reloadValue = subpoolTab.reloadScript.dropdown === 2
        ? subpoolTab.reloadScript.input.value
        : `${this.scriptItems()[subpoolTab.reloadScript.dropdown]} ${subpoolTab.reloadScript.input.value}`
      deleteValue = subpoolTab.deleteScript.dropdown === 2
        ? subpoolTab.deleteScript.input.value
        : `${this.scriptItems()[subpoolTab.deleteScript.dropdown]} ${subpoolTab.deleteScript.input.value}`
    }

    if (this.validateSubpoolTab()) {
      const archiveSubpool = {
        ARCPOOL: archivePool[0].ARCPOOL,
        OWNER: archivePool[0].OWNER,
        ADSORDER: subpoolTab.orderForReload,
        ADSMED: archiveMediaItems()[subpoolTab.archiveMediaIndex].key,
        ADSDSIZE: subpoolTab.fileSize,
        ADSFMSK: subpoolTab.fileName.value,
        ADSAUTOC: subpoolTab.managedBy === 0,
        ADSDELEC: deleteValue,
        ADSRESTC: reloadValue,
        ADSRETPD: subpoolTab.retentionPeriod,
        ADSRETP2: subpoolTab.managedBy === 1 ? subpoolTab.retentionPeriod2 : subpoolTab.retentionPeriod,
        ADSAPI: subpoolTab.managedBy === 1 && subpoolTab.useStorageApi === 0
      }
      createArchiveSubpool(archiveSubpool, callback)
    }
  }

  isOther = val => {
    return !val.startsWith(this.scriptItems()[0]) && !val.startsWith(this.scriptItems()[1])
  }

  reuseSubpoolValues = index => {
    const { archivePool } = this.props
    const reloadString = archivePool[index + 1].ADSRESTC
    const deleteString = archivePool[index + 1].ADSDELEC

    const firstReloadString = reloadString.substring(0, reloadString.indexOf(' '))
    const firstDeleteString = deleteString.substring(0, deleteString.indexOf(' '))
    const reloadInputValue = this.isOther(reloadString) ? reloadString : reloadString.substring(reloadString.indexOf(' ') + 1)
    const deleteInputValue = this.isOther(deleteString) ? deleteString : deleteString.substring(deleteString.indexOf(' ') + 1)
    let reloadDropdown = 2
    let deleteDropdown = 2

    if (firstReloadString === this.scriptItems()[0]) {
      reloadDropdown = 0
    } else if (firstReloadString === this.scriptItems()[1]) {
      reloadDropdown = 1
    }

    if (firstDeleteString === this.scriptItems()[0]) {
      deleteDropdown = 0
    } else if (firstDeleteString === this.scriptItems()[1]) {
      deleteDropdown = 1
    }

    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        retentionPeriod: archivePool[index + 1].ADSRETPD,
        retentionPeriod2: archivePool[index + 1].ADSRETP2,
        orderForReload: archivePool[index + 1].ADSORDER,
        archiveMediaIndex: Math.max(archiveMediaItems().findIndex(d => d.key === archivePool[index + 1].ADSMED), 0),
        fileSize: archivePool[index + 1].ADSDSIZE,
        fileName: { value: archivePool[index + 1].ADSFMSK },
        managedBy: archivePool[index + 1].ADSAUTOC ? 0 : 1,
        useStorageApi: archivePool[index + 1].ADSAPI ? 0 : 1,
        reloadScript: {
          dropdown: reloadDropdown,
          input: { value: reloadInputValue }
        },
        deleteScript: {
          dropdown: deleteDropdown,
          input: { value: deleteInputValue }
        },
      }
    })
  }

  deleteSubpool = index => {
    const { archivePool, deleteArchiveSubpool } = this.props
    const archiveSubpool = {
      ARCPOOL: archivePool[0].ARCPOOL,
      OWNER: archivePool[0].OWNER,
      ADSORDER: archivePool[index + 1].ADSORDER,
      ADSMED: archivePool[index + 1].ADSMED,
      ADSRETPD: archivePool[index + 1].ADSRETPD,
      ADSDSIZE: archivePool[index + 1].ADSDSIZE,
      ADSFMSK: archivePool[index + 1].ADSFMSK,
      ADSAUTOC: archivePool[index + 1].ADSAUTOC,
      ADSDELEC: archivePool[index + 1].ADSDELEC,
      ADSRESTC: archivePool[index + 1].ADSRESTC,
      ADSRETP2: archivePool[index + 1].ADSRETP2,
      ADSAPI: archivePool[index + 1].ADSAPI
    }
    deleteArchiveSubpool(archiveSubpool)
  }

  handleSave = () => {
    const { archivePool, modifyArchivePool, onClose } = this.props
    const { generalTab } = this.state

    const archivePoolObj = {
      ARCPOOL: archivePool[0].ARCPOOL,
      OWNER: archivePool[0].OWNER,
      ADPRETPD: archivePool[0].ADPRETPD,
      ADPMED: archivePool[0].ADPMED,
      ADPTITLE: generalTab.title,
      ADPORDER: generalTab.orderForReload === 1 ? generalTab.reloadOrderValue : 32767
    }
    modifyArchivePool(archivePoolObj, () => onClose())
  }

  getHeaders = () => {
    return [
      translate('general.order'),
      translate('general.media'),
      translate('general.retention_period'),
      translate('definition.archive_pool_retention_period_level2'),
      translate('database.file_size'),
      translate('definition.archive_pool_file_name_mask'),
      translate('general.managed_by'),
      translate('definition.archive_pool_reload_script_command'),
      translate('definition.archive_pool_delete_script_command')
    ]
  }

  getData = () => {
    const { archivePool } = this.props
    const data = []
    const subpools = [...archivePool.filter((_, i) => i !== 0)]
    subpools.forEach(d => {
      const buffer = []
      const order = d.ADSORDER
      const media = translate(archiveMediaItems().filter(item => item.key === d.ADSMED)[0].translationKey)
      const retention = d.ADSRETPD
      const retention2 = d.ADSRETP2
      const fileSize = d.ADSDSIZE
      const fileMask = d.ADSFMSK
      const managedBy = d.ADSAPI ? 'API' : d.ADSAUTOC ? 'HSM' : 'BMS'
      const reloadScript = !d.ADSAUTOC && d.ADSRESTC
      const deleteScript = !d.ADSAUTOC && d.ADSDELEC
      buffer.push(order, media, retention, retention2, fileSize, fileMask, managedBy, reloadScript, deleteScript)
      data.push(buffer)
    })
    return data
  }

  renderHeader = () => {
    const { id, datemask, archivePool, lang } = this.props
    const foundMediaArchiveKey = archiveMediaItems().find(d => d.key === archivePool[0].ADPMED)
    const mediaArchive = foundMediaArchiveKey ? translate(foundMediaArchiveKey.translationKey) : archivePool[0].ADPMED
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.archive_pool_id'), value: archivePool[0].ARCPOOL },
          { label: translate('general.owner'), value: archivePool[0].OWNER },
          { label: translate('general.retention_period_days'), value: Utils.formatInt(archivePool[0].ADPRETPD, lang) },
          { label: translate('general.archive_media'), value: mediaArchive },
          { label: translate('general.last_changed'), value: `${DateUtils.getDate(datemask, archivePool[0].CDATE, archivePool[0].CTIME.substring(0, 8))} ${translate('general.by')} ${archivePool[0].CUSER}` },
        ]}
        columns={5}
      />
    )
  }

  renderGeneralTab = () => {
    const { id } = this.props
    const { generalTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_title`}
              title={translate('general.title')}
              maxLength={80}
              value={generalTab.title}
              onInputChanged={val => this.handleGeneralTab('title', val)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_order_for_reload`}
              title={translate('definition.archive_pool_order_for_reload')}
              items={[translate('general.as_is'), translate('general.value')]}
              onClick={index => this.handleGeneralTab('orderForReload', index)}
              activeIndex={generalTab.orderForReload}
            />
          </Column>
          {generalTab.orderForReload === 1 &&
            <Column colMD={2}>
              <NumericSpinner
                id={`${id}_reload_order_value`}
                title={translate('definition.archive_pool_reload_order_value')}
                onChange={val => this.handleGeneralTab('reloadOrderValue', val)}
                value={generalTab.reloadOrderValue}
                steps={1}
                min={1}
                max={99}
              />
            </Column>
          }
        </Row>
      </>
    )
  }

  renderSubpoolTab = () => {
    const { id, lang, datemask } = this.props
    const { subpoolTab } = this.state
    const archiveMediaTrans = archiveMediaItems().map(d => translate(d.translationKey))

    return (
      <div className='tab-content'>
        <Row>
          <Column
            colMD={12}>
            <label id={`${id}_add_subpool`}>{translate('definition.archive_pool_add_subpool')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_retention_period`}
              title={translate('definition.archive_pool_retention_period_days')}
              onChange={val => this.handleSubpoolTab('retentionPeriod', val)}
              value={subpoolTab.retentionPeriod}
              steps={1}
              min={1}
              max={30000}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_order_for_reload`}
              title={translate('definition.archive_pool_order_for_reload')}
              onChange={val => this.handleSubpoolTab('orderForReload', val)}
              value={subpoolTab.orderForReload}
              steps={1}
              min={1}
              max={99}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_archive_media`}
              title={translate('general.archive_media')}
              items={archiveMediaTrans}
              onChange={val => this.handleSubpoolTab('archiveMediaIndex', val)}
              activeIndex={subpoolTab.archiveMediaIndex}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_file_size`}
              title={translate('definition.archive_pool_file_size')}
              onChange={val => this.handleSubpoolTab('fileSize', val)}
              value={subpoolTab.fileSize}
              steps={1}
              min={1}
              max={500}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_file_name`}
              title={translate('definition.archive_pool_file_name_mask')}
              maxLength={64}
              value={subpoolTab.fileName.value}
              error={subpoolTab.fileName.errorkey && translate(subpoolTab.fileName.errorkey)}
              onInputChanged={val => this.handleFileNameMask(val)}
              ref={this.fileNameInput}
              required={`${translate('general.required_field')}`}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Switch
              id={`${id}_file_managed_by`}
              title={translate('definition.archive_pool_file_managed_by')}
              items={[translate('general.hsm'), translate('general.api')]}
              onClick={index => this.handleSubpoolTab('managedBy', index)}
              activeIndex={subpoolTab.managedBy}
            />
          </Column>
          {subpoolTab.managedBy === 1 &&
            <>
              <Column colMD={3}>
                <Switch
                  id={`${id}_archive_pool_use_storage_api`}
                  title={translate('definition.archive_pool_use_storage_api')}
                  items={[translate('general.yes'), translate('general.no')]}
                  onClick={val => this.handleSubpoolTab('useStorageApi', val)}
                  activeIndex={subpoolTab.useStorageApi}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_archive_pool_retention_period_level2`}
                  title={translate('definition.archive_pool_retention_period_level2')}
                  onChange={val => this.handleSubpoolTab('retentionPeriod2', val)}
                  value={subpoolTab.retentionPeriod2}
                  steps={1}
                  min={1}
                  max={30000}
                />
              </Column>
            </>
          }
        </Row>
        {
          subpoolTab.managedBy === 1 &&
          <>
            <Row>
              <Column colMD={3}>
                <Dropdown
                  id={`${id}_reload_script`}
                  title={translate('definition.archive_pool_reload_script_command')}
                  items={this.scriptItems()}
                  onChange={val => this.handleReloadScriptDropdown(val)}
                  activeIndex={subpoolTab.reloadScript.dropdown}
                />
              </Column>
              <Column colMD={9}>
                <Input
                  id={`${id}_reload_script`}
                  title={''}
                  maxLength={subpoolTab.reloadScript.dropdown === 2 ? 256 : 239}
                  value={subpoolTab.reloadScript.input.value}
                  error={subpoolTab.reloadScript.input.errorkey && translate(subpoolTab.reloadScript.input.errorkey)}
                  onInputChanged={val => this.handleReloadScriptInput(val)}
                  ref={this.reloadScriptInput}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Dropdown
                  id={`${id}_delete_script`}
                  title={translate('definition.archive_pool_delete_script_command')}
                  items={this.scriptItems()}
                  onChange={val => this.handleDeleteScriptDropdown(val)}
                  activeIndex={subpoolTab.deleteScript.dropdown}
                />
              </Column>
              <Column colMD={9}>
                <Input
                  id={`${id}_delete_script`}
                  title={''}
                  maxLength={subpoolTab.deleteScript.dropdown === 2 ? 256 : 239}
                  value={subpoolTab.deleteScript.input.value}
                  error={subpoolTab.deleteScript.input.errorkey && translate(subpoolTab.deleteScript.input.errorkey)}
                  onInputChanged={val => this.handleDeleteScriptInput(val)}
                  ref={this.deleteScriptInput}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
          </>
        }
        <Row>
          <Column
            colMD={3}
            offsetMD={9}
            className={'bux_flex_row_end'}>
            <div className={'bux_place_add_filter_argument_btn'}>
              <Button
                id={`${id}_add_subpool`}
                text={translate('definition.archive_pool_add_subpool')}
                className={'bux_large_button'}
                onClick={this.handleAddSubpool}
              />
            </div>
          </Column>
        </Row>
        <Row>
          <Column
            colMD={12}>
            <label id={`${id}_attached_subpools`}>{translate('definition.archive_pool_attached_subpools')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <div>
              <DataTable
                id={`${id}_1`}
                header={this.getHeaders()}
                data={this.getData()}
                columnSortDefs={['number', 'string', 'number', 'number', 'string', 'string', 'string', 'string']}
                createListRowAction={this.reuseSubpoolValues}
                createActionButtons={this.createActionButtons}
                menu={false}
                language={lang}
                datemask={datemask}
                translate={key => translate(key)}
                className={'attachedSubpools_datatable'}
              />
            </div>
          </Column>
        </Row>
        <Row>
          <Column colMD={12}><hr /></Column>
        </Row>
      </div>
    )
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButton_reusetext_${rowIndex}`}
        iconName={'reuse_text'}
        title={translate('general.reuse')}
        onClick={() => this.reuseSubpoolValues(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButton_delete_${rowIndex}`}
        iconName={'delete'}
        title={translate('general.delete')}
        onClick={() => this.deleteSubpool(rowIndex)}
      />
    ]
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <>
        <Modal
          id={'modify_archivepool_dialog'}
          className={'bux_UserProfileModal'}
          onClose={onClose}>
          <Header
            id={`${id}_modalheader`}
            title={translate('definition.modify_archive_pool')}
            onClose={onClose}>
            {this.renderHeader()}
          </Header>
          <Main id={id}>
            <Tabs
              id={id}>
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab title={translate('general.subpool')}>
                {this.renderSubpoolTab()}
              </Tab>
            </Tabs>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    archivePool: state.definitions.archivePool.archivePool,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createArchiveSubpool: (archiveSubpool, callback) => {
      createArchiveSubpool(archiveSubpool, callback)(dispatch)
    },
    deleteArchiveSubpool: (archiveSubpool, callback) => {
      deleteArchiveSubpool(archiveSubpool, callback)(dispatch)
    },
    modifyArchivePool: (archiveSubpool, callback) => {
      modifyArchivePool(archiveSubpool, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyArchivePoolDialog)