import { translate } from 'language/Language'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'


import {
  Button, Card, Column, DataTable, Dropdown, Icon, Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row,
  Tab, TableButton,
  TableButtonGroup,
  TableButtonGroupGroup,
  TableButtonGroupItem,
  TableButtonGroupSeparator, Tabs, TitledLabel
} from 'BetaUX2Web-Components/src/'
import ZosJobLogInformation from 'components/dialogs/zos_job_log_information/ZosJobLogInformation'
import DocumentPrint from '../document_print_dialog/DocumentPrint'
import { DownloadDialog } from '../download_dialog/DownloadDialog'
import RerunDialog, * as RerunDialogTypes from '../rerun_dialog/RerunDialog'

const { Modal, Main, Header, Footer } = ModalComponent

import * as ControlMActions from 'redux/actions/SearchControlMActions'
import * as Preferences from 'redux/general/Preferences'

import * as DateUtils from 'utils/DateUtils'
import * as UrlUtils from 'utils/UrlUtils'
import { addTabToDocViewer } from 'redux/actions/DocViewerActions'


const { getDate } = DateUtils

const CtmJobInformationDialog = withRouter(({ history, id, jobIndex, onClose, controlmJobs }) => {

  const lang = useSelector(store => store.auth.serverdata.preferences[Preferences.LANGUAGE])
  const datemask = useSelector(store => store.auth.serverdata.preferences[Preferences.DATEMASK])
  const controlmJobDetails = useSelector(store => store.search.controlm.controlmJobDetails)
  const controlmJobLogs = useSelector(store => store.search.controlm.controlmJobLogs)
  const controlmJobLogInformation = useSelector(store => store.search.controlm.controlmJobLogInformation)
  const controlmJobLogPrintInfo = useSelector(store => store.search.controlm.controlmJobLogPrintInfo)
  const controlmJobLog= useSelector(store => store.search.controlm.controlmJobLog)
  const bwesystemname = useSelector(store => store.auth.serverdata.bwesystemname)
  const bwebaseurl = useSelector(store => store.auth.serverdata.bwebaseurl)
  const userprofile = useSelector(store => store.auth.serverdata.userprofile)

  const dispatch = useDispatch()

  const isCTMZOS = () => {
    const controlmJobsData = controlmJobs
    const headerIndex = controlmJobsData.header.indexOf(LOGSOURCE)
    return controlmJobsData.data[jobIndex] && controlmJobsData.data[jobIndex][headerIndex] === CTMZOS
  }

  const [generalTab, setGeneralTab] = useState({ filename: '' })
  const [retentionTab, setRetentionTab] = useState({ onlineRetention: { value: 0, dropdown: 0 }, archiveRetention: { value: 0, dropdown: 0 } })
  const [showJobLogInformationDialog, setShowJobLogInformationDialog] = useState(false)
  const [showPrintDialog, setShowPrintDialog] = useState(false)
  const [showDownloadDialog, setShowDownloadDialog] = useState(false)
  const [showRerunDialog, setShowRerunDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState(undefined)
  const [selectedMimeType, setSelectedMimeType] = useState(undefined)
  const [selectedDownloadType, setSelectedDownloadType] = useState(undefined)
  const [rerunType, setRerunType] = useState(undefined)


  useEffect(() => {
    setGeneralTab({ fileName: controlmJobDetails.SRCDSN })
    setRetentionTab((r) => ({
      ...r,
      onlineRetention: {
        value: controlmJobDetails.ONLRETPD,
        dropdown: { 'DAYS': 0, 'REF': 1, 'GEN': 2 }[controlmJobDetails.CTYPE] || 0
      },
      archiveRetention: {
        value: controlmJobDetails.ARCRETPD,
        dropdown: { '': 0, 'TAPE': 0, 'DISK': 1, 'ODISK': 2 }[controlmJobDetails.ARCHMED] || 0
      }
    }))
  // eslint-disable-next-line
  }, [])

  /**
   * @description Handles the general tab inputs
   * @param {String} key
   * @param {String} value
   */
  const handleGeneralTab = (key, value) => setGeneralTab({ ...generalTab, [key]: value })

  /**
   * @description Handles the retention tab inputs
   * @param {String} key
   * @param {String} value
   */
  const handleRetentionTab = (key, key2, value) => {
    setRetentionTab({
      ...retentionTab,
      [key]: {
        ...retentionTab[key],
        [key2]: value
      }
    }
    )
  }

  const renderHeader = () => {
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('general.jobname'), value: controlmJobDetails.JOBNAME },
          { label: translate('general.order_id'), value: controlmJobDetails.ORDERID },
          { label: translate('general.run_count'), value: controlmJobDetails.RUNCOUNT },
          { label: translate('general.jobgroup'), value: controlmJobDetails.JOBGROUP },
          { label: translate('general.datacenter'), value: controlmJobDetails.DATACENT },
          { label: translate('definition.application'), value: controlmJobDetails.APPL },
          { label: translate('general.sub_application'), value: controlmJobDetails.SUBAPPL },
          { label: translate('general.agent'), value: controlmJobDetails.AGNTNAME },
          { label: translate('general.order_date'), value: `${getDate(datemask, controlmJobDetails.ORDDATE)}` },
          { label: translate('general.start_date'), value: `${getDate(datemask, controlmJobDetails.STRTDATE)} ${controlmJobDetails.STRTTIME}` },
          { label: translate('general.end_date'), value: `${getDate(datemask, controlmJobDetails.ENDDATE)} ${controlmJobDetails.ENDTIME}` },
          { label: translate('general.run_as'), value: controlmJobDetails.RUNUSER },
          { label: translate('general.bytes'), value: controlmJobDetails.SRCBYTES },
          { label: translate('general.job_type'), value: controlmJobDetails.JOBTYPE },
          { label: translate('general.folder'), value: controlmJobDetails.FOLDER },
        ]}
        columns={4}
      />
    )
  }

  const renderGeneralTab = () => {
    return (
      <>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_generaltab_filename`}
              value={generalTab.fileName}
              title={translate('general.filename')}
              maxLength={80}
              onInputChanged={value => handleGeneralTab('fileName', value)}
              disabled
            />
          </Column>
        </Row>
      </>
    )
  }

  const renderRetentionTab = () => {
    return (
      <>
        <Row>
          <Column colMD={6}>
            <label id={`${id}_retentiontab_retentions`}>{translate('documentinformation.retentions')}</label>
          </Column>
          <Column colMD={6}>
            <label id={`${id}_retentiontab_additional_information`}>{translate('documentinformation.retentiontab_additionalinformation')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <NumericSpinner
              id={`${id}_retentiontab_online_retention`}
              title={translate('documentinformation.onlineretention')}
              onChange={value => handleRetentionTab('onlineRetention', 'value', value)}
              value={retentionTab.onlineRetention.value}
              // DISABLED FOR NOW UNTIL ROUTES ARE IMPLEMENTED
              disabled
              max={0}
            />
          </Column>
          <Column colMD={2}>
            <Dropdown
              id={`${id}_retentiontab_online_retention_dropdown`}
              title={' '}
              items={[
                translate('general.days'),
                translate('documentinformation.retentiontab_references'),
                translate('documentinformation.retentiontab_generations')
              ]}
              activeIndex={retentionTab.onlineRetention.dropdown}
              onChange={value => handleRetentionTab('onlineRetention', 'dropdown', value)}
              // DISABLED FOR NOW UNTIL ROUTES ARE IMPLEMENTED
              disabled
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${id}_retentiontab_online`}
              title={translate('search.online')}
              text={controlmJobDetails.LGRONLNE ? translate('general.yes') : translate('general.no')}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${id}_retentiontab_online_expiration`}
              title={translate('documentinformation.retentiontab_onlineexpiration')}
              text={document.ONLEXPDT ? DateUtils.getDate(document.datemask, document.ONLEXPDT) : ' '}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${id}_retentiontab_last_access`}
              title={translate('general.last_access')}
              text={getDate(datemask, controlmJobDetails.LREFD)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <NumericSpinner
              id={`${id}_retentiontab_archive_retention`}
              title={translate('documentinformation.retentiontab_archiveretention')}
              onChange={value => handleRetentionTab('archiveRetention', 'value', value)}
              value={retentionTab.archiveRetention.value}
              // DISABLED FOR NOW UNTIL ROUTES ARE IMPLEMENTED
              disabled
              max={0}
            />
          </Column>
          <Column colMD={2}>
            <Dropdown
              id={`${id}_retentiontab_archive_retention_dropdown`}
              title={' '}
              items={
                retentionTab.archiveRetention.value !== 0
                  ? [translate('general.tape'), translate('general.disk'), translate('general.optical_disk')]
                  : [translate('general.none')]
              }
              activeIndex={retentionTab.archiveRetention.dropdown}
              onChange={value => handleRetentionTab('archiveRetention', 'dropdown', value)}
              // DISABLED FOR NOW UNTIL ROUTES ARE IMPLEMENTED
              disabled
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${id}_retentiontab_archived`}
              title={translate('documentinformation.retentiontab_archived')}
              text={controlmJobDetails.LGRARCH ? translate('general.yes') : translate('general.no')}
            />
          </Column>
          <Column colMD={2}>
            <TitledLabel
              id={`${id}_retentiontab_archive_expiration`}
              title={translate('documentinformation.retentiontab_archiveexperation')}
              text={getDate(datemask, controlmJobDetails.AEXPDT)}
            />
          </Column>
        </Row>
      </>
    )
  }

  const handleLogInformation = rowIndex => {
    const joblogObj = {
      LOGSOURCE: 'ZOS',
      SRCSUBD: controlmJobDetails.SRCSUBD,
      SRCSUBT: controlmJobDetails.SRCSUBT,
      JOBNAME: controlmJobDetails.JOBNAME,
      JOBID: controlmJobDetails.JOBID,
      DSID: controlmJobLogs.data[rowIndex][controlmJobLogs.header.indexOf('DSID')]
    }
    ControlMActions.getJobLogInformation(undefined, joblogObj, () => setShowJobLogInformationDialog(true))(dispatch)
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  const handleDisplayLog = rowIndex => {
    const docid = controlmJobLogs.data[rowIndex][headerData('DOCID')]
    const ukey = controlmJobLogs.data[rowIndex][headerData('UKEY')]

    ControlMActions.displayLog(docid, bwebaseurl, bwesystemname, ukey)(dispatch)
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  const openDocumentViewer = (rowIndex) => {
    const docId = controlmJobLogs.data[rowIndex][headerData('DOCID')]
    const pageCount = controlmJobLogs.data[rowIndex][headerData('SRCPAGES')]

    addTabToDocViewer({
      id: docId,
      type: 'controlm',
      header: controlmJobLogs.header,
      data: controlmJobLogs.data[rowIndex],
      pageCount: parseInt(pageCount)
    })(dispatch)
  }

  /**
  * @description Handles the open print document dialog action.
  * @param {Number} rowIndex The current row.
  */
  const handleShowLogPrint = rowIndex => {
    let docid = controlmJobLogs.data[rowIndex][headerData('DOCID')]
    ControlMActions.getLog(undefined, docid, undefined, () => {
      ControlMActions.getPrintInfo(docid, () => setShowPrintDialog(true))(dispatch)
    })(dispatch)
  }


  /**
 * @description Handles the download functionality of logs in binary format.
 * @param {Number} rowIndex The current row.
 */
  const handleLogDownloadBinary = rowIndex => {
    const docid = controlmJobLogs.data[rowIndex][headerData('DOCID')]
    const form = controlmJobLogs.data[rowIndex][headerData('FORM')]
    const ext = controlmJobLogs.data[rowIndex][headerData('EXT')]
    const report = controlmJobLogs.data[rowIndex][headerData('REPORT')]

    let doctype = controlmJobLogs.data[rowIndex][headerData('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => entry !== undefined).join('.')
    ControlMActions.downloadLog(docid, filename)(dispatch)
  }

  /**
   * @description Handles the download functionality. Opens an additional modal, if download settings  are available for a specific mimetype.
   * @param {Number} rowIndex The current row.
   * @param {String} mimetype The mimetype of the specific doucment.
   */
  const handleDocumentDownloadText = (rowIndex, mimetype) => {
    if (!showDownloadDialog && ['*.afp', '*.pcl', '*.pjl'].includes(mimetype)) {
      return openDocumentDownloadDialog(rowIndex, 'TEXT')
    }
    return executeDownloadTextDirectly(rowIndex, mimetype)
  }

  /**
   * @description Executes the text download directly without an additional modal.
   * @param {Number} rowIndex The current row.
   * @param {String} mimetype The mimetype of the specific doucment.
   */
  const executeDownloadTextDirectly = (rowIndex, mimetype) => {
    const docid = controlmJobLogs.data[rowIndex][headerData('DOCID')]
    const form = controlmJobLogs.data[rowIndex][headerData('FORM')]
    const ext = controlmJobLogs.data[rowIndex][headerData('EXT')]
    const report = controlmJobLogs.data[rowIndex][headerData('REPORT')]
    let doctype = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[mimetype]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    ControlMActions.downloadLogText(docid, filename, mimetypeTranslation)(dispatch)
  }

  const openDocumentDownloadDialog = (rowIndex, type) => {
    setSelectedRow(rowIndex)
    setSelectedMimeType(controlmJobLogs.data[rowIndex][headerData('SRCJOBI')])
    setSelectedDownloadType(type)
    setShowDownloadDialog(true)
  }

  /**
   * @description Handles the download functionality of zos job logs in hex format.
   * @param {Number} rowIndex The current row.
   */
  const handleLogDownloadHex = (displayMode, showPageHeader, showResources) => {
    const docid = controlmJobLogs.data[selectedRow][headerData('DOCID')]

    const form = controlmJobLogs.data[selectedRow][headerData('FORM')]
    const ext = controlmJobLogs.data[selectedRow][headerData('EXT')]
    const report = controlmJobLogs.data[selectedRow][headerData('REPORT')]
    let doctype = 'hex'

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    ControlMActions.downloadLogHex(docid, filename, displayMode, showPageHeader, showResources, () => resetDownloadDialog())(dispatch)
  }

  /**
   * @description Handles the download functionality of zos job logs in text format from download dialog.
   * @param {String} showNOP YES/NO value for show NOP
   * @param {String} showTLE YES/NO value for show TLE
   * @param {String} showPJL YES/NO value for show PJL
   */
  const executeLogTextFromDialog = (showNOP, showTLE, showPJL) => {
    const docid = controlmJobLogs.data[selectedRow][headerData('DOCID')]

    const form = controlmJobLogs.data[selectedRow][headerData('FORM')]
    const ext = controlmJobLogs.data[selectedRow][headerData('EXT')]
    const report = controlmJobLogs.data[selectedRow][headerData('REPORT')]
    let doctype = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[selectedMimeType]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    ControlMActions.downloadLogText(docid, filename, mimetypeTranslation, showNOP, showTLE, showPJL, () => resetDownloadDialog())(dispatch)
  }

  /**
 * @description Handles the rerun action.
 * @param {Number} rowIndex The current row.
 * @param {String} type The type of rerun. Use constants of RerunDialog!
 */
  const handleRerun = (rowIndex, type) => {
    const docid = controlmJobLogs.data[rowIndex][headerData('DOCID')]
    const callback = () => { setRerunType(type); setShowRerunDialog(true) }
    ControlMActions.getLog(undefined, docid, undefined, callback)(dispatch)
  }

  /**
   * @description Opens the recipient log assignment menu and prefills drawer values with values from log entry.
   * @param {*} index The rowindex
   */
  const showRecipients = index => {
    const header = controlmJobLogs.header
    const data = controlmJobLogs.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
      { rest: 'REPORT', ui: 'report' },
      { rest: 'WREPORT', ui: 'dynamicReport' },
    ]
    // fake the state like its used in assignment recipient log to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
      report: data[header.indexOf('WREPORT')] || '',
      dynamicReport: ''
      //  Not possible to get the value at the moment.
      // dynamicReport: data[header.indexOf('DREPORT')],
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/recipient_log${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    history.push(urlToPush)
  }

  /**
 * @description un-/marks the archive or delete status, unmarks the reload status
 * @param {Number} index the index of the current row
 * @param {String} markedParam the header which should be used to update the ARCHIVE, DELETE or RELOAD
 * possible: 'LGRFFARC', 'LGRFFDEL', 'LGRFFREL'
 * LGRFFREL is only used to unmark reload
 */
  const updateMark = (index, markedParam) => {
    const docid = controlmJobLogs.data[index][headerData('DOCID')]

    const callback = (controlmJobLog) => {
      const archiveStatus = controlmJobLog[markedParam] === false || controlmJobLog[markedParam] === '' ? 'PENDING' : ''
      let logDefinition = {
        // [param]: archiveStatus,
        DOCID: docid,
        FORM: controlmJobLog.FORM,
        EXT: controlmJobLog.EXT,
        REPORT: controlmJobLog.REPORT
      }
      const job = controlmJobs.data.find(d => controlmJobLogs.data[index][headerData('SRCJOBN')] === d[controlmJobs.header.indexOf('JOBNAME')])
      const searchObj = {
        LOGSOURCE: 'ZOS',
        SRCSUBD: job[controlmJobs.header.indexOf('SRCSUBD')],
        SRCSUBT: job[controlmJobs.header.indexOf('SRCSUBT')],
        JOBNAME: job[controlmJobs.header.indexOf('JOBNAME')],
        JOBID: job[controlmJobs.header.indexOf('JOBID')],
      }
      if (markedParam === 'LGRFFARC') {
        logDefinition = {
          ...logDefinition,
          ARCHIVE: archiveStatus
        }
        ControlMActions.markArchive(logDefinition, searchObj)(dispatch)
      }
      else if (markedParam === 'LGRFFDEL') {
        logDefinition = {
          ...logDefinition,
          DELETE: archiveStatus
        }
        ControlMActions.markDelete(logDefinition, searchObj)(dispatch)
      }
    }

    ControlMActions.getLog(undefined, docid, undefined, callback)(dispatch)
  }


  /**
     * @description Creates the action buttons for the table.
     * @param {Number} rowIndex The index of the current row.
     */
  const createActionButtons = rowIndex => {
    const mimetype = rowIndex !== undefined ? controlmJobLogs?.data[rowIndex][headerData('SRCJOBI')] : undefined
    const modifyArchiveAllowed = userprofile === undefined || userprofile?.VCIARC
    const modifyDeleteAllowed = userprofile === undefined || userprofile?.VCIDEL

    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => handleLogInformation(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${rowIndex}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => openDocumentViewer(rowIndex)}
      />,
      <TableButtonGroup
        id={`${id}_tableButtonMore_${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          id={`${id}_info_btn`}
          icon={<Icon name='info' className='actionIcon' />}
          text={translate('general.information')}
          title={translate('general.information')}
          onClick={() => handleLogInformation(rowIndex)}
        />
        <TableButtonGroupItem
          id={`${id}_display_btn`}
          icon={<Icon name='document' className='actionIcon' />}
          text={translate('general.display_in_beta_view')}
          title={translate('general.display_in_beta_view')}
          onClick={() => handleDisplayLog(rowIndex)}
        />
        <TableButtonGroupItem
          id={`${id}_print_btn`}
          icon={<Icon name='print' className='actionIcon' />}
          text={translate('documentprint.print')}
          title={translate('documentprint.print')}
          onClick={() => handleShowLogPrint(rowIndex)}
        />

        <TableButtonGroupGroup id={`${id}_download_group_btn`} text={translate('general.download')}>
          <TableButtonGroupItem
            onClick={() => handleLogDownloadBinary(rowIndex)}
            id={`${id}_download_btn`}
            icon={<Icon name={'download'} className={'actionIcon'} />}
            text={translate('general.download')}
            title={translate('general.download')}
          />
          {
            ['*.ps', '*.pdf', '*.afp', '*.pcl', '*.pjl'].includes(mimetype) &&
            <TableButtonGroupItem
              onClick={() => handleDocumentDownloadText(rowIndex, mimetype)}
              id={`${id}_download_text_btn`}
              icon={<Icon name={'download'} className={'actionIcon'} />}
              text={translate('general.download_as_text')}
              title={translate('general.download_as_text')}
            />
          }

          <TableButtonGroupItem
            onClick={() => openDocumentDownloadDialog(rowIndex, 'HEX')}
            id={`${id}_download_hex_btn`}
            icon={<Icon name={'download'} className={'actionIcon'} />}
            text={translate('standard_selection.download_as_hex')}
            title={translate('standard_selection.download_as_hex')}
          />
        </TableButtonGroupGroup>
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => handleRerun(rowIndex, RerunDialogTypes.ITEM_FORCE_AUTO_PRINT)}
          id={`${id}_rerun_autoprint_btn`}
          icon={<Icon name='rerun' className='actionIcon' />}
          text={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
          title={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => showRecipients(rowIndex)}
          id={`${id}_show_recipients_btn`}
          icon={<Icon name='group' className='actionIcon' />}
          text={translate('general.display_recipients')}
          title={translate('general.display_recipients')}
        />

        {(modifyArchiveAllowed || modifyDeleteAllowed) &&
          <TableButtonGroupSeparator id={id} />
        }
        { /* check if controlmJobLogs.data[rowIndex] is not undefined is necessary, because redux updates the data faster than datatable, so datatable useses this function with old index and we get an error, when less rows are in the new data (no proof, just a theory)*/}
        {modifyDeleteAllowed && (controlmJobLogs.data[rowIndex] &&
          (controlmJobLogs.data[rowIndex] && controlmJobLogs.data[rowIndex][headerData('LGRFFDEL')] === ''
            ? <TableButtonGroupItem
              onClick={() => updateMark(rowIndex, 'LGRFFDEL')}
              id={`${id}_markDeleteBtn`}
              icon={<Icon name='delete' className='actionIcon' />}
              text={translate('general.mark_delete')}
              title={translate('general.mark_delete')}
            />
            : <TableButtonGroupItem
              onClick={() => updateMark(rowIndex, 'LGRFFDEL')}
              id={`${id}_unmarkDeleteBtn`}
              icon={<Icon name='close' className='actionIcon' />}
              text={translate('general.unmark_delete')}
              title={translate('general.unmark_delete')}
            />))
        }

        {modifyArchiveAllowed && controlmJobLogs.data[rowIndex] && (
          controlmJobLogs.data[rowIndex][headerData('LGRFFARC')] === ''
            ? <TableButtonGroupItem
              onClick={() => updateMark(rowIndex, 'LGRFFARC')}
              id={`${id}_markArchiveBtn`}
              icon={<Icon name='archive' className='actionIcon' />}
              text={translate('general.mark_archive')}
              title={translate('general.mark_archive')}
            />
            : <TableButtonGroupItem
              onClick={() => updateMark(rowIndex, 'LGRFFARC')}
              id={`${id}_unmarkArchiveBtn`}
              icon={<Icon name='unarchive' className='actionIcon' />}
              text={translate('general.unmark_archive')}
              title={translate('general.unmark_archive')}
            />)}
      </TableButtonGroup>
    ]
  }

  const headerData = header => controlmJobLogs.header.indexOf(header)
  /**
  * @description gets the corrected/formatted data from the table
  */
  const getTableData = () => {
    const data = []
    controlmJobLogs.data.forEach(el => {
      const ddName = el[headerData('SRCDDND')]
      const stepName = el[headerData('SRCSTPD')]
      const dsID = el[headerData('DSID')]
      const pages = el[headerData('SRCPAGES')]
      const lines = el[headerData('SRCLNCT')]
      const form = el[headerData('FORM')]
      const extension = el[headerData('EXT')]
      const jobClass = el[headerData('SRCCLAS')]
      const markedDelete = el[headerData('LGRFFDEL')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      const markedArchive = el[headerData('LGRFFARC')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      data.push([ddName, stepName, dsID, pages, lines, form, extension, jobClass, markedDelete, markedArchive])
    })
    return data
  }
  const renderLogs = () => (
    <Card className={'bux_card_flex'}>
      <Row>
        <Column colMD={12}>
          <label id={`${id}_logfiles`}>{translate('job.log_files')}</label>
        </Column>
      </Row>
      <Row colMD={12} className='bux_full_height'>
        <Column colMD={12} className={'bux_full_height'}>
          <DataTable
            id={id}
            header={[
              translate('job.dd_name'),
              translate('job.step_name'),
              translate('job.ds_id'),
              translate('general.pages'),
              translate('general.lines'),
              translate('general.form'),
              translate('general.extension'),
              translate('job.class'),
              translate('general.marked_delete'),
              translate('general.marked_archive'),
            ]}
            columnSortDefs={['string', 'string', 'number', 'number', 'number', 'string', 'string', 'string', 'string']}
            createActionButtons={createActionButtons}
            createTableRowAction={(rowIndex) => handleLogInformation(rowIndex)}
            data={data}
            language={lang}
            datemask={datemask}
            translate={key => translate(key)}
            menu={false}
          />
        </Column>
      </Row>
    </Card>
  )
  const renderTabs = () => {
    return (<Tabs id={id}>
      <Tab title={translate('general.general')}>
        {renderGeneralTab()}
      </Tab>
      <Tab title={translate('documentinformation.retentiontab')}>
        {renderRetentionTab()}
      </Tab>
    </Tabs>
    )
  }

  const resetDownloadDialog = () => {
    setShowDownloadDialog(false)
    setSelectedRow(undefined)
    setSelectedMimeType(undefined)
    setSelectedDownloadType(undefined)
  }

  const data = controlmJobLogs && controlmJobLogs.data ? getTableData() : null
  return (
    <>
      {
        showJobLogInformationDialog &&
        <ZosJobLogInformation
          id={`${id}_info_dialog`}
          zosJobLogInformation={controlmJobLogInformation}
          onClose={() => setShowJobLogInformationDialog(false)}
        />
      }

      {
        showPrintDialog &&
        <DocumentPrint
          id={`${id}_print_dialog`}
          onClose={() => setShowPrintDialog(false)}
          document={controlmJobLog}
          printInfo={controlmJobLogPrintInfo}
        />
      }
      {
        showDownloadDialog &&
        <DownloadDialog
          id={selectedDownloadType === 'TEXT' ? `${id}_download_text_dialog` : `${id}_download_hex_dialog`}
          onClose={() => resetDownloadDialog()}
          mimetype={selectedMimeType}
          download={selectedDownloadType === 'TEXT' ? executeLogTextFromDialog : handleLogDownloadHex}
          downloadType={selectedDownloadType}
          title={selectedDownloadType === 'TEXT' ? translate('general.download_as_text') : translate('standard_selection.download_as_hex')}
        />
      }
      {
        showRerunDialog &&
        <RerunDialog
          id={`${id}_rerun_dialog`}
          onClose={() => setShowRerunDialog(false)}
          type={rerunType}
          document={controlmJobLog}
        />
      }
      <Modal
        id={'ctm_job_information_dialog'}
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={translate('general.job_details')}
          onClose={onClose}>
          {renderHeader()}
        </Header>
        <Main>
          {isCTMZOS() ? renderLogs() : renderTabs()}
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    </>
  )
})

export default CtmJobInformationDialog

const LOGSOURCE = 'LOGSRC'
const CTMZOS = 'CTMZOS'