import React, {forwardRef} from 'react';
import './Button.scss';

import Icon from '../icon/Icon';
import Label from '../label/Label';
import {prepareClassNames} from '../../utils/ComponentUtils';

interface ButtonProps {
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** This ID will be used for integration tests to access the HTML element */
  dataTestId?: string;
  /** Style class from CSS for styling for button.*/
  className?: string;
  /** Style class from CSS for styling button container.*/
  containerClass?: string;
  /** Function to be called on an click event.*/
  onClick?: (e: React.MouseEvent) => void;
  /** Text to be displayed in Button.*/
  text?: string;
  /** Name of the icon to be displayed in Button.*/
  icon?: string;
  /** Set general style for type primary button.*/
  primary?: boolean;
  /** Enables button type submit button.*/
  submit?: boolean;
  /** Enables button to take full width of parent.*/
  fill?: boolean;
  /** Label to be displayed above the button.*/
  title?: string;
  /** Enable autofocus on render completion.*/
  autoFocus?: boolean;
  /** Text to be displayed in tooltip when you hover component.*/
  tooltip?: string;
  /** Disables this component.*/
  disabled?: boolean;
  /** Tab index of this component */
  tabIndex?: number;
}

/**
 * Button component created according to
 * _Primary Button_, _Secondary Button_, _Icon Button_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref): JSX.Element => {
  const classNames = prepareClassNames([
    props.primary ? 'button_primary' : 'button_secondary',
    props.icon ? 'button_with_icon' : '',
    props.text ? 'button_with_text' : '',
    props.fill ? 'button_full_width' : '',
    props.className
  ]);

  return (
    <>
      <Label
        id={`${props.id}_title`}
        title={props.title}
        required={false}
        disabled={props.disabled}
      />
      <div className={`${'button_container'} ${props.containerClass || ''}`} title={props.tooltip || ''}>
        <button
          id={props.id}
          data-testid={props?.dataTestId}
          ref={ref}
          type={props.submit ? 'submit' : 'button'}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          tabIndex={props.tabIndex}
          className={classNames}
          onClick={props.onClick}
          onMouseUp={e => e.currentTarget.blur()}
        >
          {// Render Icon if props.icon is given
            props.icon && <Icon id={props.id} name={props.icon}/>
          }
          {
            props.text && <span id={`${props.id}_text`} title={props.tooltip ?? props.text}>{props.text}</span>
          }
        </button>
      </div>
    </>
  );
});

Button.displayName = 'ButtonComponent';

export default Button;