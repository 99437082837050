import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { LoadingSpinner } from 'BetaUX2Web-Components/src';
import { HexSegmentBlock, HexSegmentLine } from './DocumentBrowserHexSegments';
import { DocumentBrowserRulerArea } from './DocumentBrowserRulerArea';

import * as ATTRIBUTES from '../../constants/attributes';

import { useTabContentContext } from '../../contexts/TabContentContext';

import * as DOC_VIEWER_UTILS from '../../helper/utils';

import { translate } from 'language/Language';

import * as TYPES_DOC_BROWSER_HEX_AREA from '../../types/DocumentBrowserHexArea.types';
import * as TYPES_DOC_VIEWER from '../../types/DocumentViewer.types';

export const DocumentBrowserPageAreaHexLine = () => {
  const { cacheKey, id, pageNumber } = useTabContentContext() as TYPES_DOC_VIEWER.ITabContentContext;

  const isFetching: TYPES_DOC_VIEWER.ITabData['isFetching'] = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id).isFetching);
  const hexLineData: TYPES_DOC_VIEWER.TPageContent = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id).pagesHexLine[cacheKey]);
  const { maxBlockLength, groupedHeaderData: header, groupedPageData: pagedata } = DOC_VIEWER_UTILS.parseHexLineData(hexLineData);
  const totalLineCount = header.lineCount + pagedata.lineCount;

  if (isFetching) {
    return (
      <div className='doc_browser_area_interaction_spinner'>
        <LoadingSpinner showLoader={isFetching} isFixed={false} bgArea={false} />
      </div>
    )
  }

  return (
    <div id={`${ATTRIBUTES.ID_HEX_CONTAINER}_${cacheKey}`}>
      {totalLineCount !== 0 ? (
        <div className='doc_browser_area_interaction'>
          <DocumentBrowserRulerArea
            id={id}
            maxBlockLength={maxBlockLength}
            pageNumber={pageNumber}
            totalLineCount={totalLineCount}
            isHexView
          >
            <>
              {header.data.map((line: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexBlockDataContent, lineIndex: number) => (
                <HexSegmentBlock
                  key={`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}`}
                  line={line}
                  lineIndex={lineIndex}
                  maxBlockLength={maxBlockLength}
                />
              ))}
              {pagedata.data.map((segment: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent[], segmentIndex: number) => (
                <HexSegmentLine
                  key={`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${segmentIndex + header.lineCount}`}
                  segment={segment}
                  segmentIndex={segmentIndex + header.lineCount} />
              ))}
            </>
          </DocumentBrowserRulerArea>
        </div>
      ) : (
        <div className='doc_browser_area_interaction'>
          {translate('documentviewer.no_data')}
        </div>
      )}
    </div>
  )
};