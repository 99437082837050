/* ************************************************************************************** */
// usermanagement users
export const USERMANAGEMENT_USERS_USERID = 'USERMANAGEMENT_USERS_USERID'
export const USERMANAGEMENT_USERS_USERNAME = 'USERMANAGEMENT_USERS_USERNAME'
export const USERMANAGEMENT_USERS_PRIVILEGES = 'USERMANAGEMENT_USERS_PRIVILEGES'
export const USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY = 'USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY'
export const USERMANAGEMENT_USERS_REVOKED_ONLY = 'USERMANAGEMENT_USERS_REVOKED_ONLY'

// usermanagement groups
export const USERMANAGEMENT_GROUPS_GROUPID = 'USERMANAGEMENT_GROUPS_GROUPID'
export const USERMANAGEMENT_GROUPS_GROUPNAME = 'USERMANAGEMENT_GROUPS_GROUPNAME'

// usermanagement remoteusers
export const USERMANAGEMENT_REMOTEUSERS_UNIXUSER = 'USERMANAGEMENT_REMOTEUSERS_UNIXUSER'
export const USERMANAGEMENT_REMOTEUSERS_BUXUSER = 'USERMANAGEMENT_REMOTEUSERS_BUXUSER'
/* ************************************************************************************** */
// standard import
export const IMPORT_STD_ENCODING = 'IMPORT_STD_ENCODING'
export const IMPORT_STD_SPECIAL_FILE_FORMAT = 'IMPORT_STD_SPECIAL_FILE_FORMAT'
export const IMPORT_STD_FORM = 'IMPORT_STD_FORM'
export const IMPORT_STD_EXTENSION = 'IMPORT_STD_EXTENSION'
export const IMPORT_STD_JOBNAME = 'IMPORT_STD_JOBNAME'
export const IMPORT_STD_TITLE = 'IMPORT_STD_TITLE'
export const IMPORT_STD_RECIPIENT = 'IMPORT_STD_RECIPIENT'
export const IMPORT_STD_EDIT_ICON = 'IMPORT_STD_EDIT_ICON'
export const IMPORT_STD_OUTPUT_FORMAT = 'IMPORT_STD_OUTPUT_FORMAT'
export const IMPORT_STD_OUTPUT_CHANNEL = 'IMPORT_STD_OUTPUT_CHANNEL'
export const IMPORT_STD_COPIES = 'IMPORT_STD_COPIES'
export const IMPORT_STD_AUTOPRINT = 'IMPORT_STD_AUTOPRINT'
export const IMPORT_STD_ANNOTATION = 'IMPORT_STD_ANNOTATION'
export const IMPORT_STD_DOCUSER1 = 'IMPORT_STD_DOCUSER1'
export const IMPORT_STD_DOCUSER2 = 'IMPORT_STD_DOCUSER2'
export const IMPORT_STD_DOCUSER3 = 'IMPORT_STD_DOCUSER3'
export const IMPORT_STD_DOCUSER4 = 'IMPORT_STD_DOCUSER4'
export const IMPORT_STD_DOCUSER5 = 'IMPORT_STD_DOCUSER5'
export const IMPORT_STD_DOCUSER6 = 'IMPORT_STD_DOCUSER6'
export const IMPORT_STD_DOCUSER7 = 'IMPORT_STD_DOCUSER7'
export const IMPORT_STD_DOCUSER8 = 'IMPORT_STD_DOCUSER8'
/* ************************************************************************************** */
// statistic pages and documents
export const STATISTIC_FROMDATE = 'STATISTIC_FROMDATE'
export const STATISTIC_TODATE = 'STATISTIC_TODATE'
export const STATISTIC_UNIT = 'STATISTIC_UNIT'
export const STATISTIC_PAGES_CHART = 'STATISTIC_PAGES_CHART'
export const STATISTIC_DOCUMENTS_CHART = 'STATISTIC_DOCUMENTS_CHART'
/* ************************************************************************************** */
// statistic logx
export const STATISTIC_LOGX_FROMDATE = 'STATISTIC_LOGX_FROMDATE'
export const STATISTIC_LOGX_TODATE = 'STATISTIC_LOGX_TODATE'
export const STATISTIC_LOGX_UNIT = 'STATISTIC_LOGX_UNIT'
export const STATISTIC_LOGX_PAGES_CHART = 'STATISTIC_LOGX_PAGES_CHART'
export const STATISTIC_LOGX_LOGS_CHART = 'STATISTIC_LOGX_LOGS_CHART'
export const STATISTIC_LOGX_JOBS_CHART = 'STATISTIC_LOGX_JOBS_CHART'
/* ************************************************************************************** */
// security
export const SECURITY_PROFILEMASK = 'SECURITY_PROFILEMASK'
export const SECURITY_BETAUSER = 'SECURITY_BETAUSER'
export const SECURITY_PROFILE_TYPE = 'SECURITY_PROFILE_TYPE'
export const SECURITY_PROFILE_ENTRY = 'SECURITY_PROFILE_ENTRY'
/* ************************************************************************************** */
// general
export const LANGUAGE = 'LANGUAGE'
export const DATEMASK = 'DATEMASK'
/* ************************************************************************************** */
export const SEARCH_CURRENT_CUSTOM_DIALOG_DOCX = 'SEARCH_CURRENT_CUSTOM_DIALOG_DOCX'
export const SEARCH_CURRENT_CUSTOM_DIALOG_LOGX = 'SEARCH_CURRENT_CUSTOM_DIALOG_LOGX'
export const SEARCH_LAST_CUSTOM_SEARCH = 'SEARCH_LAST_CUSTOM_SEARCH'
// search
export const SEARCH_STD_LASTTIME_MODE = 'SEARCH_STD_LASTTIME_MODE'
export const SEARCH_STD_CUSTOMLAST = 'SEARCH_STD_CUSTOMLAST'
export const SEARCH_STD_CUSTOM_UNIT = 'SEARCH_STD_CUSTOM_UNIT'
export const SEARCH_STD_FROMDATE = 'SEARCH_STD_FROMDATE'
export const SEARCH_STD_FROMTIME = 'SEARCH_STD_FROMTIME'
export const SEARCH_STD_TODATE = 'SEARCH_STD_TODATE'
export const SEARCH_STD_TOTIME = 'SEARCH_STD_TOTIME'
export const SEARCH_STD_LISTTIMESTAMP = 'SEARCH_STD_LISTTIMESTAMP'
export const SEARCH_STD_FORM = 'SEARCH_STD_FORM'
export const SEARCH_STD_EXTENSION = 'SEARCH_STD_EXTENSION'
export const SEARCH_STD_REPORT = 'SEARCH_STD_REPORT'
export const SEARCH_STD_JOBNAME = 'SEARCH_STD_JOBNAME'
export const SEARCH_STD_TITLE = 'SEARCH_STD_TITLE'
export const SEARCH_STD_TITLEFROMCOLUMN = 'SEARCH_STD_TITLEFROMCOLUMN'
export const SEARCH_STD_RECIPIENT = 'SEARCH_STD_RECIPIENT'
export const SEARCH_STD_FOLDER = 'SEARCH_STD_FOLDER'
export const SEARCH_STD_OUTPUTCHANNEL = 'SEARCH_STD_OUTPUTCHANNEL'
export const SEARCH_STD_REQUESTOR = 'SEARCH_STD_REQUESTOR'
export const SEARCH_STD_USER = 'SEARCH_STD_USER'
export const SEARCH_STD_OWNER = 'SEARCH_STD_OWNER'
export const SEARCH_STD_DOCTYPE = 'SEARCH_STD_DOCTYPE'
export const SEACH_STD_FILTERED_BY_RC = 'SEACH_STD_FILTERED_BY_RC'
export const SEARCH_STD_RETURNCODE_SWITCH = 'SEARCH_STD_RETURNCODE_SWITCH'
export const SEARCH_STD_RETURNCODE_INPUT = 'SEARCH_STD_RETURNCODE_INPUT'
export const SEARCH_STD_DOCUSER1 = 'SEARCH_STD_DOCUSER1'
export const SEARCH_STD_DOCUSER2 = 'SEARCH_STD_DOCUSER2'
export const SEARCH_STD_DOCUSER3 = 'SEARCH_STD_DOCUSER3'
export const SEARCH_STD_DOCUSER4 = 'SEARCH_STD_DOCUSER4'
export const SEARCH_STD_DOCUSER5 = 'SEARCH_STD_DOCUSER5'
export const SEARCH_STD_DOCUSER6 = 'SEARCH_STD_DOCUSER6'
export const SEARCH_STD_DOCUSER7 = 'SEARCH_STD_DOCUSER7'
export const SEARCH_STD_DOCUSER8 = 'SEARCH_STD_DOCUSER8'
export const SEARCH_STD_CONTROL_STATUS = 'SEARCH_STD_CONTROL_STATUS'
export const SEARCH_STD_ONLINE = 'SEARCH_STD_ONLINE'
export const SEARCH_STD_CONTROL_USER = 'SEARCH_STD_CONTROL_USER'
export const SEARCH_STD_ARCHIVED = 'SEARCH_STD_ARCHIVED'
export const SEARCH_STD_MARKED_FOR_RELOAD = 'SEARCH_STD_MARKED_FOR_RELOAD'
export const SEARCH_STD_MARKED_FOR_DELETE = 'SEARCH_STD_MARKED_FOR_DELETE'
export const SEARCH_STD_AUTOPRINT = 'SEARCH_STD_AUTOPRINT'
export const SEARCH_STD_EDITING_ICON = 'SEARCH_STD_EDITING_ICON'
export const SEARCH_STD_OBTAINED = 'SEARCH_STD_OBTAINED'
export const SEARCH_STD_SELECT_BY_TYPE = 'SEARCH_STD_SELECT_BY_TYPE'
export const SEARCH_STD_SELECT_BY_INDEX = 'SEARCH_STD_SELECT_BY_INDEX'
export const SEARCH_STD_SELECT_BY_NOTES = 'SEARCH_STD_SELECT_BY_NOTES'
export const SEARCH_STD_OUTPUT_STATUS_PRINTED = 'SEARCH_STD_OUTPUT_STATUS_PRINTED'
export const SEARCH_STD_OUTPUT_STATUS_ERROR = 'SEARCH_STD_OUTPUT_STATUS_ERROR'
export const SEARCH_STD_OUTPUT_STATUS_HOLD = 'SEARCH_STD_OUTPUT_STATUS_HOLD'
export const SEARCH_STD_OUTPUT_STATUS_WAITING = 'SEARCH_STD_OUTPUT_STATUS_WAITING'
export const SEARCH_STD_OUTPUT_STATUS_DELETE = 'SEARCH_STD_OUTPUT_STATUS_DELETE'
export const SEARCH_STD_OUTPUT_STATUS_NOTFOUND = 'SEARCH_STD_OUTPUT_STATUS_NOTFOUND'
export const SEARCH_STD_OUTPUT_STATUS_ACTIVE = 'SEARCH_STD_OUTPUT_STATUS_ACTIVE'
export const SEARCH_STD_OUTPUT_STATUS_RETRY = 'SEARCH_STD_OUTPUT_STATUS_RETRY'
export const SEARCH_STD_OUTPUT_STATUS_CANCELLED = 'SEARCH_STD_OUTPUT_STATUS_CANCELLED'
export const SEARCH_STD_OUTPUT_STATUS_REQUEUED = 'SEARCH_STD_OUTPUT_STATUS_REQUEUED'
export const SEARCH_STD_ACTIVE_TAB = 'SEARCH_STD_ACTIVE_TAB'
export const SEARCH_LAST_STANDARD_SEARCH = 'SEARCH_LAST_STANDARD_SEARCH'
/* ************************************************************************************** */
// search uc4
export const SEARCH_UC4_START_DATE = 'SEARCH_UC4_START_DATE'
export const SEARCH_UC4_START_TIME = 'SEARCH_UC4_START_TIME'
export const SEARCH_UC4_END_DATE = 'SEARCH_UC4_END_DATE'
export const SEARCH_UC4_END_TIME = 'SEARCH_UC4_END_TIME'
export const SEARCH_UC4_ACTIVE_TAB = 'SEARCH_UC4_ACTIVE_TAB'
export const SEARCH_UC4_LASTTIME_MODE = 'SEARCH_UC4_LASTTIME_MODE'
export const SEARCH_UC4_TIME_CUSTOM_UNIT = 'SEARCH_UC4_TIME_CUSTOM_UNIT'
export const SEARCH_UC4_TIME_CUSTOM_LAST = 'SEARCH_UC4_TIME_CUSTOM_LAST'
export const SEARCH_UC4_USAGE = 'SEARCH_UC4_USAGE'
export const SEARCH_UC4_JOBGROUP = 'SEARCH_UC4_JOBGROUP'
export const SEARCH_UC4_JOBNAME = 'SEARCH_UC4_JOBNAME'
export const SEARCH_UC4_SYSTEMNAME = 'SEARCH_UC4_SYSTEMNAME'
export const SEARCH_UC4_RUN_NUMBER = 'SEARCH_UC4_RUN_NUMBER'
export const SEARCH_UC4_CLIENT = 'SEARCH_UC4_CLIENT'
export const SEARCH_UC4_OBJECT_TYPE = 'SEARCH_UC4_OBJECT_TYPE'
export const SEARCH_UC4_PARENT_NAME = 'SEARCH_UC4_PARENT_NAME'
export const SEARCH_UC4_AGENT_NAME = 'SEARCH_UC4_AGENT_NAME'
export const SEARCH_UC4_ARCHIVE_KEY_1 = 'SEARCH_UC4_ARCHIVE_KEY_1'
export const SEARCH_UC4_ARCHIVE_KEY_2 = 'SEARCH_UC4_ARCHIVE_KEY_2'
export const SEARCH_UC4_ONLY_JOB_ERRORS = 'SEARCH_UC4_ONLY_JOB_ERRORS'
export const SEARCH_UC4_LOGSOURCE = 'SEARCH_UC4_LOGSOURCE'
export const SEARCH_UC4_RETURNCODE_SWITCH = 'SEARCH_UC4_RETURNCODE_SWITCH'
export const SEARCH_UC4_RETURNCODE_INPUT = 'SEARCH_UC4_RETURNCODE_INPUT'
export const SEARCH_UC4_STATUS_SWITCH = 'SEARCH_UC4_STATUS_SWITCH'
export const SEARCH_UC4_STATUS_INPUT = 'SEARCH_UC4_STATUS_INPUT'
export const SEARCH_UC4_CPU_USAGE_SWITCH = 'SEARCH_UC4_CPU_USAGE_SWITCH'
export const SEARCH_UC4_CPU_USAGE_INPUT = 'SEARCH_UC4_CPU_USAGE_INPUT'
export const SEARCH_UC4_RUNTIME_SWITCH = 'SEARCH_UC4_RUNTIME_SWITCH'
export const SEARCH_UC4_RUNTIME_INPUT = 'SEARCH_UC4_RUNTIME_INPUT'
/* ************************************************************************************** */
// search zos
export const SEARCH_ZOS_START_DATE = 'SEARCH_ZOS_START_DATE'
export const SEARCH_ZOS_START_TIME = 'SEARCH_ZOS_START_TIME'
export const SEARCH_ZOS_END_DATE = 'SEARCH_ZOS_END_DATE'
export const SEARCH_ZOS_END_TIME = 'SEARCH_ZOS_END_TIME'
export const SEARCH_ZOS_ACTIVE_TAB = 'SEARCH_ZOS_ACTIVE_TAB'
export const SEARCH_ZOS_LASTTIME_MODE = 'SEARCH_ZOS_LASTTIME_MODE'
export const SEARCH_ZOS_TIME_CUSTOM_UNIT = 'SEARCH_ZOS_TIME_CUSTOM_UNIT'
export const SEARCH_ZOS_TIME_CUSTOM_LAST = 'SEARCH_ZOS_TIME_CUSTOM_LAST'
export const SEARCH_ZOS_USAGE = 'SEARCH_ZOS_USAGE'
export const SEARCH_ZOS_ONLY_JOB_ERRORS = 'SEARCH_ZOS_ONLY_JOB_ERRORS'
export const SEARCH_ZOS_LOGSOURCE = 'SEARCH_ZOS_LOGSOURCE'
export const SEARCH_ZOS_JOBGROUP = 'SEARCH_ZOS_JOBGROUP'
export const SEARCH_ZOS_JOBNAME1 = 'SEARCH_ZOS_JOBNAME1'
export const SEARCH_ZOS_JOBNAME2 = 'SEARCH_ZOS_JOBNAME2'
export const SEARCH_ZOS_JOBNAME3 = 'SEARCH_ZOS_JOBNAME3'
export const SEARCH_ZOS_JOBNAME4 = 'SEARCH_ZOS_JOBNAME4'
export const SEARCH_ZOS_JOBID = 'SEARCH_ZOS_JOBID'
export const SEARCH_ZOS_RETURNCODE = 'SEARCH_ZOS_RETURNCODE'
export const SEARCH_ZOS_NETID1 = 'SEARCH_ZOS_NETID1'
export const SEARCH_ZOS_NETID2 = 'SEARCH_ZOS_NETID2'
export const SEARCH_ZOS_CLASS = 'SEARCH_ZOS_CLASS'
/* ************************************************************************************** */
export const SEARCH_CONTROLM_ACTIVE_TAB = 'SEARCH_CONTROLM_ACTIVE_TAB'
export const SEARCH_CONTROLM_LASTTIME_MODE = 'SEARCH_CONTROLM_LASTTIME_MODE'
export const SEARCH_CONTROLM_TIME_CUSTOM_LAST = 'SEARCH_CONTROLM_TIME_CUSTOM_LAST'
export const SEARCH_CONTROLM_TIME_CUSTOM_UNIT = 'SEARCH_CONTROLM_TIME_CUSTOM_UNIT'
export const SEARCH_CONTROLM_START_DATE = 'SEARCH_CONTROLM_START_DATE'
export const SEARCH_CONTROLM_START_TIME = 'SEARCH_CONTROLM_START_TIME'
export const SEARCH_CONTROLM_END_DATE = 'SEARCH_CONTROLM_END_DATE'
export const SEARCH_CONTROLM_END_TIME = 'SEARCH_CONTROLM_END_TIME'
export const SEARCH_CONTROLM_LOGSOURCE = 'SEARCH_CONTROLM_LOGSOURCE'
export const SEARCH_CONTROLM_USAGE = 'SEARCH_CONTROLM_USAGE'
export const SEARCH_CONTROLM_ONLY_JOB_ERRORS = 'SEARCH_CONTROLM_ONLY_JOB_ERRORS'
export const SEARCH_CONTROLM_JOBGROUP = 'SEARCH_CONTROLM_JOBGROUP'
export const SEARCH_CONTROLM_JOBNAME = 'SEARCH_CONTROLM_JOBNAME'
export const SEARCH_CONTROLM_DATACENTER = 'SEARCH_CONTROLM_DATACENTER'
export const SEARCH_CONTROLM_APPLICATION = 'SEARCH_CONTROLM_APPLICATION'
export const SEARCH_CONTROLM_SUBAPPLICATION = 'SEARCH_CONTROLM_SUBAPPLICATION'
export const SEARCH_CONTROLM_AGENT = 'SEARCH_CONTROLM_AGENT'
export const SEARCH_CONTROLM_RUNAS = 'SEARCH_CONTROLM_RUNAS'
export const SEARCH_CONTROLM_FOLDER = 'SEARCH_CONTROLM_FOLDER'
export const SEARCH_CONTROLM_JOBTYPE = 'SEARCH_CONTROLM_JOBTYPE'
export const SEARCH_CONTROLM_ORDERID = 'SEARCH_CONTROLM_ORDERID'
export const SEARCH_CONTROLM_RUNCOUNT = 'SEARCH_CONTROLM_RUNCOUNT'
export const SEARCH_CONTROLM_RETURNCODE_SWITCH = 'SEARCH_CONTROLM_RETURNCODE_SWITCH'
export const SEARCH_CONTROLM_RETURNCODE_INPUT = 'SEARCH_CONTROLM_RETURNCODE_INPUT'
export const SEARCH_CONTROLM_RUNTIME_SWITCH = 'SEARCH_CONTROLM_RUNTIME_SWITCH'
export const SEARCH_CONTROLM_RUNTIME_INPUT = 'SEARCH_CONTROLM_RUNTIME_INPUT'
/* ************************************************************************************** */
export const SEARCH_SYSLOG_ACTIVE_TAB = 'SEARCH_SYSLOG_ACTIVE_TAB'
export const SEARCH_SYSLOG_LASTTIME_MODE = 'SEARCH_SYSLOG_LASTTIME_MODE'
export const SEARCH_SYSLOG_TIME_CUSTOM_LAST = 'SEARCH_SYSLOG_TIME_CUSTOM_LAST'
export const SEARCH_SYSLOG_TIME_CUSTOM_UNIT = 'SEARCH_SYSLOG_TIME_CUSTOM_UNIT'
export const SEARCH_SYSLOG_START_DATE = 'SEARCH_SYSLOG_START_DATE'
export const SEARCH_SYSLOG_START_TIME = 'SEARCH_SYSLOG_START_TIME'
export const SEARCH_SYSLOG_END_DATE = 'SEARCH_SYSLOG_END_DATE'
export const SEARCH_SYSLOG_END_TIME = 'SEARCH_SYSLOG_END_TIME'
export const SEARCH_SYSLOG_JOBGROUP = 'SEARCH_SYSLOG_JOBGROUP'
export const SEARCH_SYSLOG_JOBID = 'SEARCH_SYSLOG_JOBID'
export const SEARCH_SYSLOG_JOBNAME = 'SEARCH_SYSLOG_JOBNAME'
export const SEARCH_SYSLOG_SYSTEM = 'SEARCH_SYSLOG_SYSTEM'
export const SEARCH_SYSLOG_SUBMIT_USER = 'SEARCH_SYSLOG_SUBMIT_USER'
/* ************************************************************************************** */
export const SEARCH_STONEBRANCH_ACTIVE_TAB = 'SEARCH_STONEBRANCH_ACTIVE_TAB'
export const SEARCH_STONEBRANCH_LASTTIME_MODE = 'SEARCH_STONEBRANCH_LASTTIME_MODE'
export const SEARCH_STONEBRANCH_TIME_CUSTOM_LAST = 'SEARCH_STONEBRANCH_TIME_CUSTOM_LAST'
export const SEARCH_STONEBRANCH_TIME_CUSTOM_UNIT = 'SEARCH_STONEBRANCH_TIME_CUSTOM_UNIT'
export const SEARCH_STONEBRANCH_START_DATE = 'SEARCH_STONEBRANCH_START_DATE'
export const SEARCH_STONEBRANCH_START_TIME = 'SEARCH_STONEBRANCH_START_TIME'
export const SEARCH_STONEBRANCH_END_DATE = 'SEARCH_STONEBRANCH_END_DATE'
export const SEARCH_STONEBRANCH_END_TIME = 'SEARCH_STONEBRANCH_END_TIME'
export const SEARCH_STONEBRANCH_LOGSOURCE = 'SEARCH_STONEBRANCH_LOGSOURCE'
export const SEARCH_STONEBRANCH_USAGE = 'SEARCH_STONEBRANCH_USAGE'
export const SEARCH_STONEBRANCH_ONLY_JOB_ERRORS = 'SEARCH_STONEBRANCH_ONLY_JOB_ERRORS'
export const SEARCH_STONEBRANCH_JOBGROUP = 'SEARCH_STONEBRANCH_JOBGROUP'
export const SEARCH_STONEBRANCH_JOBNAME = 'SEARCH_STONEBRANCH_JOBNAME'
export const SEARCH_STONEBRANCH_AGENT = 'SEARCH_STONEBRANCH_AGENT'
export const SEARCH_STONEBRANCH_ORDERID = 'SEARCH_STONEBRANCH_ORDERID'
export const SEARCH_STONEBRANCH_RUNCOUNT = 'SEARCH_STONEBRANCH_RUNCOUNT'
export const SEARCH_STONEBRANCH_RETURN_CODE_SWITCH = 'SEARCH_STONEBRANCH_RETURN_CODE_SWITCH'
export const SEARCH_STONEBRANCH_RETURN_CODE_INPUT = 'SEARCH_STONEBRANCH_RETURN_CODE_INPUT'
export const SEARCH_STONEBRANCH_RUNTIME_SWITCH = 'SEARCH_STONEBRANCH_RUNTIME_SWITCH'
export const SEARCH_STONEBRANCH_RUNTIME_INPUT = 'SEARCH_STONEBRANCH_RUNTIME_INPUT'

export const SEARCH_STONEBRANCH_SYSTEM = 'SEARCH_STONEBRANCH_SYSTEM'
export const SEARCH_STONEBRANCH_SERVICE = 'SEARCH_STONEBRANCH_SERVICE'
export const SEARCH_STONEBRANCH_JOBTYPE = 'SEARCH_STONEBRANCH_JOBTYPE'
export const SEARCH_STONEBRANCH_STATUS = 'SEARCH_STONEBRANCH_STATUS'
export const SEARCH_STONEBRANCH_WORKFLOW = 'SEARCH_STONEBRANCH_WORKFLOW'
export const SEARCH_STONEBRANCH_RUN_USER = 'SEARCH_STONEBRANCH_RUN_USER'
export const SEARCH_STONEBRANCH_SOURCE_VERSION = 'SEARCH_STONEBRANCH_SOURCE_VERSION'

/* ************************************************************************************** */
// definition recipient
export const DEFINITION_RECI_ID = 'DEFINITION_RECI_ID'
export const DEFINITION_RECI_TYPE = 'DEFINITION_RECI_TYPE'
export const DEFINITION_RECI_OWNER = 'DEFINITION_RECI_OWNER'
export const DEFINITION_RECI_TITLE = 'DEFINITION_RECI_TITLE'
export const DEFINITION_RECI_DISTRIBUTVIA = 'DEFINITION_RECI_DISTRIBUTVIA'
export const DEFINITION_RECI_OUTPUTFORMAT = 'DEFINITION_RECI_OUTPUTFORMAT'
export const DEFINITION_RECI_PREDECESSORID = 'DEFINITION_RECI_PREDECESSORID'
export const DEFINITION_RECI_OUTPUTCHANNEL = 'DEFINITION_RECI_OUTPUTCHANNEL'
export const DEFINITION_RECI_POSTPROCESSNOTE = 'DEFINITION_RECI_POSTPROCESSNOTE'
export const DEFINITION_RECI_DISPLAY_AS = 'DEFINITION_RECI_DISPLAY_AS'
/* ************************************************************************************** */
// definition recipient
export const JOBGROUPS_DEFINITION_JOBGROUP_NAME = 'JOBGROUPS_DEFINITION_JOBGROUP_NAME'
export const JOBGROUPS_DEFINITION_TITLE = 'JOBGROUPS_DEFINITION_TITLE'
export const JOBGROUPS_DEFINITION_OWNER = 'JOBGROUPS_DEFINITION_OWNER'
export const JOBGROUPS_DEFINITION_ARCHIVE_MEDIA = 'JOBGROUPS_DEFINITION_ARCHIVE_MEDIA'
/* ************************************************************************************** */
// definition folder
export const DEFINITION_FOLDER_ID = 'DEFINITION_FOLDER_ID'
export const DEFINITION_FOLDER_TITLE = 'DEFINITION_FOLDER_TITLE'
export const DEFINITION_FOLDER_OWNER = 'DEFINITION_FOLDER_OWNER'
export const DEFINITION_FOLDER_BRW = 'DEFINITION_FOLDER_BRW'
/* ************************************************************************************** */
// definition external commands
export const DEFINITION_EXT_COMMANDS_ID = 'DEFINITION_EXT_COMMANDS_ID'
export const DEFINITION_EXT_COMMANDS_DESCRIPTION = 'DEFINITION_EXT_COMMANDS_DESCRIPTION'
export const DEFINITION_EXT_COMMANDS_PROGRAM_NAME = 'DEFINITION_EXT_COMMANDS_PROGRAM_NAME'
export const DEFINITION_EXT_COMMANDS_COMMAND = 'DEFINITION_EXT_COMMANDS_COMMAND'
export const DEFINITION_EXT_COMMANDS_ARGUMENTS = 'DEFINITION_EXT_COMMANDS_ARGUMENTS'
/* ************************************************************************************** */
// definition index
export const DEFINITION_INDEX_ID = 'DEFINITION_INDEX_ID'
export const DEFINITION_INDEX_OWNER = 'DEFINITION_INDEX_OWNER'
export const DEFINITION_INDEX_IDENTIFIER = 'DEFINITION_INDEX_IDENTIFIER'
export const DEFINITION_INDEX_DATA_FORMAT = 'DEFINITION_INDEX_DATA_FORMAT'
/* ************************************************************************************** */
// definition documents
export const DEFINITION_DOCUMENT_FORM = 'DEFINITION_DOCUMENT_FORM'
export const DEFINITION_DOCUMENT_EXT = 'DEFINITION_DOCUMENT_EXT'
export const DEFINITION_DOCUMENT_REPORT = 'DEFINITION_DOCUMENT_REPORT'
export const DEFINITION_DOCUMENT_SEARCH_MODE = 'DEFINITION_DOCUMENT_SEARCH_MODE'
export const DEFINITION_DOCUMENT_TYPE = 'DEFINITION_DOCUMENT_TYPE'
export const DEFINITION_DOCUMENT_OWNER = 'DEFINITION_DOCUMENT_OWNER'
export const DEFINITION_DOCUMENT_TITLE = 'DEFINITION_DOCUMENT_TITLE'
export const DEFINITION_DOCUMENT_OUTPUTCHANNEL_ID = 'DEFINITION_DOCUMENT_OUTPUTCHANNEL_ID'
export const DEFINITION_DOCUMENT_OUTPUTFORMAT_ID = 'DEFINITION_DOCUMENT_OUTPUTFORMAT_ID'
export const DEFINITION_DOCUMENT_PPN_ID = 'DEFINITION_DOCUMENT_PPN_ID'
/* ************************************************************************************** */
// definition document node
export const DEFINITION_DOCUMENT_NODE_NODE_ID = 'DEFINITION_DOCUMENT_NODE_NODE_ID'
export const DEFINITION_DOCUMENT_NODE_PARENT_NODE_ID = 'DEFINITION_DOCUMENT_NODE_PARENT_NODE_ID'
export const DEFINITION_DOCUMENT_NODE_OWNER = 'DEFINITION_DOCUMENT_NODE_OWNER'
export const DEFINITION_DOCUMENT_NODE_IDENTIFIER = 'DEFINITION_DOCUMENT_NODE_IDENTIFIER'
export const DEFINITION_DOCUMENT_NODE_TYPE = 'DEFINITION_DOCUMENT_NODE_TYPE'
export const DEFINITION_DOCUMENT_NODE_DISPLAY_AS = 'DEFINITION_DOCUMENT_DISPLAY_AS'
/* ************************************************************************************** */
// definition lpd queues
export const DEFINITION_LPD_QUEUES_NAME = 'DEFINITION_LPD_QUEUES_NAME'
export const DEFINITION_LPD_QUEUES_DESCRIPTION = 'DEFINITION_LPD_QUEUES_DESCRIPTION'
export const DEFINITION_LPD_QUEUES_OWNER = 'DEFINITION_LPD_QUEUES_OWNER'
/* ************************************************************************************** */
// definition post processing notes
export const DEFINITION_PPNS_ID = 'DEFINITION_PPNS_ID'
export const DEFINITION_PPNS_OWNER = 'DEFINITION_PPNS_OWNER'
export const DEFINITION_PPNS_TITLE = 'DEFINITION_PPNS_TITLE'
/* ************************************************************************************** */
// definition output channels
export const DEFINITION_OUTPUTCHANNEL_ID = 'DEFINITION_OUTPUTCHANNEL_ID'
export const DEFINITION_OUTPUTCHANNEL_TYPE = 'DEFINITION_OUTPUTCHANNEL_TYPE'
export const DEFINITION_OUTPUTCHANNEL_OWNER = 'DEFINITION_OUTPUTCHANNEL_OWNER'
export const DEFINITION_OUTPUTCHANNEL_TITLE = 'DEFINITION_OUTPUTCHANNEL_TITLE'
export const DEFINITION_OUTPUTCHANNEL_ALTERNATIVE_ID = 'DEFINITION_OUTPUTCHANNEL_ALTERNATIVE_ID'
export const DEFINITION_OUTPUTCHANNEL_ONLY_ACTIVE_ALTERNATIVES = 'DEFINITION_OUTPUTCHANNEL_ONLY_ACTIVE_ALTERNATIVES'
/* ************************************************************************************** */
// definition output formats
export const DEFINITION_OUTPUTFORMAT_ID = 'DEFINITION_OUTPUTFORMAT_ID'
export const DEFINITION_OUTPUTFORMAT_OWNER = 'DEFINITION_OUTPUTFORMAT_OWNER'
export const DEFINITION_OUTPUTFORMAT_TITLE = 'DEFINITION_OUTPUTFORMAT_TITLE'
/* ************************************************************************************** */
// definition search arguments
export const DEFINITION_SEARCHARGUMENT_ID = 'DEFINITION_SEARCHARGUMENT_ID'
export const DEFINITION_SEARCHARGUMENT_FORM = 'DEFINITION_SEARCHARGUMENT_FORM'
export const DEFINITION_SEARCHARGUMENT_EXTENSION = 'DEFINITION_SEARCHARGUMENT_EXTENSION'
export const DEFINITION_SEARCHARGUMENT_REPORT = 'DEFINITION_SEARCHARGUMENT_REPORT'
/* ************************************************************************************** */
// definition filter arguments
export const DEFINITION_FILTERARGUMENT_ID = 'DEFINITION_FILTERARGUMENT_ID'
export const DEFINITION_FILTERARGUMENT_OWNER = 'DEFINITION_FILTERARGUMENT_OWNER'
export const DEFINITION_FILTERARGUMENT_TITLE = 'DEFINITION_FILTERARGUMENT_TITLE'
/* ************************************************************************************** */
// definition archive pool
export const DEFINITION_ARCHIVEPOOL_ID = 'DEFINITION_ARCHIVEPOOL_ID'
export const DEFINITION_ARCHIVEPOOL_OWNER = 'DEFINITION_ARCHIVEPOOL_OWNER'
export const DEFINITION_ARCHIVEPOOL_TITLE = 'DEFINITION_ARCHIVEPOOL_TITLE'
export const DEFINITION_ARCHIVEPOOL_RETENTIONPERIOD = 'DEFINITION_ARCHIVEPOOL_RETENTIONPERIOD'
export const DEFINITION_ARCHIVEPOOL_MEDIA = 'DEFINITION_ARCHIVEPOOL_MEDIA'
/* ************************************************************************************** */
// definition result table
export const DEFINITION_RESULT_TABLE_ID = 'DEFINITION_RESULT_TABLE_ID'
export const DEFINITION_RESULT_TABLE_DESCRIPTION = 'DEFINITION_RESULT_TABLE_DESCRIPTION'
export const DEFINITION_RESULT_TABLE_OWNER = 'DEFINITION_RESULT_TABLE_OWNER'
export const DEFINITION_RESULT_TABLE_JOBTYPE = 'DEFINITION_RESULT_TABLE_JOBTYPE'
/* ************************************************************************************** */
// definition custom dialog
export const DEFINITION_CUSTOM_DIALOG_ID = 'DEFINITION_CUSTOM_DIALOG_ID'
export const DEFINITION_CUSTOM_DIALOG_DESCRIPTION = 'DEFINITION_CUSTOM_DIALOG_DESCRIPTION'
export const DEFINITION_CUSTOM_DIALOG_OWNER = 'DEFINITION_CUSTOM_DIALOG_OWNER'
export const DEFINITION_CUSTOM_DIALOG_RESULT_TABLE_ID = 'DEFINITION_CUSTOM_DIALOG_RESULT_TABLE_ID'
export const DEFINITION_CUSTOM_DIALOG_COMMAND = 'DEFINITION_CUSTOM_DIALOG_COMMAND'
export const DEFINITION_CUSTOM_DIALOG_JOBTYPE = 'DEFINITION_CUSTOM_DIALOG_JOBTYPE'
/* ************************************************************************************** */
// definition view profile
export const DEFINITION_VIEW_PROFILE_ID = 'DEFINITION_VIEW_PROFILE_ID'
export const DEFINITION_VIEW_PROFILE_TITLE = 'DEFINITION_VIEW_PROFILE_TITLE'
export const DEFINITION_VIEW_PROFILE_DOCTYPE = 'DEFINITION_VIEW_PROFILE_DOCTYPE'
export const DEFINITION_VIEW_PROFILE_OWNER = 'DEFINITION_VIEW_PROFILE_OWNER'
/* ************************************************************************************** */
// definition log
export const DEFINITION_LOG_HOST = 'DEFINITION_LOG_HOST'
export const DEFINITION_LOG_APPLICATION = 'DEFINITION_LOG_APPLICATION'
export const DEFINITION_LOG_SUBLOG = 'DEFINITION_LOG_SUBLOG'
export const DEFINITION_LOG_TYPE = 'DEFINITION_LOG_TYPE'
export const DEFINITION_LOG_OWNER = 'DEFINITION_LOG_OWNER'
export const DEFINITION_LOG_TITLE = 'DEFINITION_LOG_TITLE'
/* ************************************************************************************** */
// database query
export const DATABASE_QUERY_QUERY = 'DATABASE_QUERY_QUERY'
export const DATABASE_QUERY_TOUPPER = 'DATABASE_QUERY_TOUPPER'
/* ************************************************************************************** */
// table settings
export const TABLE_SETTINGS_DATABASE_ACCOUNTING = 'TABLE_SETTINGS_DATABASE_ACCOUNTING'
export const TABLE_SETTINGS_DATABASE_EXTERNALCMD = 'TABLE_SETTINGS_DATABASE_EXTERNALCMD'
export const TABLE_SETTINGS_DATABASE_MAINTENANCE = 'TABLE_SETTINGS_DATABASE_MAINTENANCE'
export const TABLE_SETTINGS_DATABASE_QUERY = 'TABLE_SETTINGS_DATABASE_QUERY'
export const TABLE_SETTINGS_DATABASE_TABLES = 'TABLE_SETTINGS_DATABASE_TABLES'
export const TABLE_SETTINGS_DEFINITION_ARCHIVEPOOL = 'TABLE_SETTINGS_DEFINITION_ARCHIVEPOOL'
export const TABLE_SETTINGS_DEFINITION_DOCUMENT = 'TABLE_SETTINGS_DEFINITION_DOCUMENT'
export const TABLE_SETTINGS_DEFINITION_LOG = 'TABLE_SETTINGS_DEFINITION_LOG'
export const TABLE_SETTINGS_DEFINITION_DOCUMENT_NODE = 'TABLE_SETTINGS_DEFINITION_DOCUMENT_NODE'
export const TABLE_SETTINGS_DEFINITION_EXTERNALCMD = 'TABLE_SETTINGS_DEFINITION_EXTERNALCMD'
export const TABLE_SETTINGS_DEFINITION_FILTERARGUMENT = 'TABLE_SETTINGS_DEFINITION_FILTERARGUMENT'
export const TABLE_SETTINGS_DEFINITION_FOLDER = 'TABLE_SETTINGS_DEFINITION_FOLDER'
export const TABLE_SETTINGS_DEFINITION_INDEX = 'TABLE_SETTINGS_DEFINITION_INDEX'
export const TABLE_SETTINGS_DEFINITION_LPDQUEUE = 'TABLE_SETTINGS_DEFINITION_LPDQUEUE'
export const TABLE_SETTINGS_DEFINITION_OUTPUTCHANNEL = 'TABLE_SETTINGS_DEFINITION_OUTPUTCHANNEL'
export const TABLE_SETTINGS_DEFINITION_OUTPUTFORMAT = 'TABLE_SETTINGS_DEFINITION_OUTPUTFORMAT'
export const TABLE_SETTINGS_DEFINITION_PPN = 'TABLE_SETTINGS_DEFINITION_PPN'
export const TABLE_SETTINGS_DEFINITION_RECIPIENT = 'TABLE_SETTINGS_DEFINITION_RECIPIENT'
export const TABLE_SETTINGS_DEFINITION_SEARCHARGUMENT = 'TABLE_SETTINGS_DEFINITION_SEARCHARGUMENT'
export const TABLE_SETTINGS_DEFINITION_RESULT_TABLE = 'TABLE_SETTINGS_DEFINITION_RESULT_TABLE'
export const TABLE_SETTINGS_DEFINITION_CUSTOM_DIALOG = 'TABLE_SETTINGS_DEFINITION_CUSTOM_DIALOG'
export const TABLE_SETTINGS_DEFINITION_VIEW_PROFILE = 'TABLE_SETTINGS_DEFINITION_VIEW_PROFILE'
export const TABLE_SETTINGS_DEFINITION_JOBGROUPS = 'TABLE_SETTINGS_DEFINITION_JOBGROUPS'
export const TABLE_SETTINGS_FOLDER_DOCUMENT_ASSIGNMENT = 'TABLE_SETTINGS_FOLDER_DOCUMENT_ASSIGNMENT'
export const TABLE_SETTINGS_INDEX_DOCUMENT_ASSIGNMENT = 'TABLE_SETTINGS_INDEX_DOCUMENT_ASSIGNMENT'
export const TABLE_SETTINGS_PRINTER_DEVICE_INFO = 'TABLE_SETTINGS_PRINTER_DEVICE_INFO'
export const TABLE_SETTINGS_QUEUE_RELOAD = 'TABLE_SETTINGS_QUEUE_RELOAD'
export const TABLE_SETTINGS_RECIPIENT_DOCUMENT_ASSIGNMENT = 'TABLE_SETTINGS_RECIPIENT_DOCUMENT_ASSIGNMENT'
export const TABLE_SETTINGS_SECURITYPROFILES = 'TABLE_SETTINGS_SECURITYPROFILES'
export const TABLE_SETTINGS_SERVER_ARCHIVEFILE = 'TABLE_SETTINGS_SERVER_ARCHIVEFILE'
export const TABLE_SETTINGS_SERVER_LPD_STATUS = 'TABLE_SETTINGS_SERVER_LPD_STATUS'
export const TABLE_SETTINGS_SERVER_MESSAGE_LOG = 'TABLE_SETTINGS_SERVER_MESSAGE_LOG'
export const TABLE_SETTINGS_SEARCH_UC4 = 'TABLE_SETTINGS_SEARCH_UC4'
export const TABLE_SETTINGS_SEARCH_ZOS = 'TABLE_SETTINGS_SEARCH_ZOS'
export const TABLE_SETTINGS_SEARCH_CONTROLM = 'TABLE_SETTINGS_SEARCH_CONTROLM'
export const TABLE_SETTINGS_SEARCH_STONEBRANCH = 'TABLE_SETTINGS_SEARCH_STONEBRANCH'
export const TABLE_SETTINGS_SEARCH_SYSLOG = 'TABLE_SETTINGS_SEARCH_SYSLOG'
export const TABLE_SETTINGS_STANDARDSELECTION = 'TABLE_SETTINGS_STANDARDSELECTION'
export const TABLE_SETTINGS_CUSTOMSELECTION_DOCUMENTS = 'TABLE_SETTINGS_CUSTOMSELECTION_DOCUMENTS'
export const TABLE_SETTINGS_CUSTOMSELECTION_LOGS = 'TABLE_SETTINGS_CUSTOMSELECTION_LOGS'
export const TABLE_SETTINGS_CUSTOMSELECTION_UC4 = 'TABLE_SETTINGS_CUSTOMSELECTION_UC4'
export const TABLE_SETTINGS_CUSTOMSELECTION_ZOS = 'TABLE_SETTINGS_CUSTOMSELECTION_ZOS'
export const TABLE_SETTINGS_CUSTOMSELECTION_CONTROLM = 'TABLE_SETTINGS_CUSTOMSELECTION_CONTROLM'
export const TABLE_SETTINGS_CUSTOMSELECTION_SYSLOG = 'TABLE_SETTINGS_CUSTOMSELECTION_SYSLOG'

export const TABLE_SETTINGS_CUSTOMSELECTION_STONEBRANCH = 'TABLE_SETTINGS_CUSTOMSELECTION_STONEBRANCH'
export const TABLE_SETTINGS_USERMANAGEMENT_GROUPS = 'TABLE_SETTINGS_GROUPS'
export const TABLE_SETTINGS_USERMANAGEMENT_REMOTEUSER = 'TABLE_SETTINGS_REMOTEUSER'
export const TABLE_SETTINGS_USERMANAGEMENT_USERS = 'TABLE_SETTINGS_USERS'
export const TABLE_SETTINGS_QUEUE_BUNDLE = 'TABLE_SETTINGS_QUEUE_BUNDLE'
export const TABLE_SETTINGS_QUEUE_INDEX = 'TABLE_SETTINGS_QUEUE_INDEX'
export const TABLE_SETTINGS_QUEUE_OUTPUT = 'TABLE_SETTINGS_QUEUE_OUTPUT'
export const TABLE_SETTINGS_QUEUE_LPD = 'TABLE_SETTINGS_QUEUE_LPD'
export const TABLE_SETTINGS_NODE_DOCUMENT_ASSIGNMENT = 'TABLE_SETTINGS_NODE_DOCUMENT_ASSIGNMENT'
export const TABLE_SETTINGS_CUSTOMSELECTION_RESULTTABLES = 'TABLE_SETTINGS_CUSTOMSELECTION_RESULTTABLES'

export const ALL_TABLE_SETTINGS = [
  TABLE_SETTINGS_DATABASE_ACCOUNTING,
  TABLE_SETTINGS_DATABASE_EXTERNALCMD,
  TABLE_SETTINGS_DATABASE_MAINTENANCE,
  TABLE_SETTINGS_DATABASE_QUERY,
  TABLE_SETTINGS_DATABASE_TABLES,
  TABLE_SETTINGS_DEFINITION_ARCHIVEPOOL,
  TABLE_SETTINGS_DEFINITION_DOCUMENT,
  TABLE_SETTINGS_DEFINITION_LOG,
  TABLE_SETTINGS_DEFINITION_DOCUMENT_NODE,
  TABLE_SETTINGS_DEFINITION_EXTERNALCMD,
  TABLE_SETTINGS_DEFINITION_FILTERARGUMENT,
  TABLE_SETTINGS_DEFINITION_FOLDER,
  TABLE_SETTINGS_DEFINITION_INDEX,
  TABLE_SETTINGS_DEFINITION_LPDQUEUE,
  TABLE_SETTINGS_DEFINITION_OUTPUTCHANNEL,
  TABLE_SETTINGS_DEFINITION_OUTPUTFORMAT,
  TABLE_SETTINGS_DEFINITION_PPN,
  TABLE_SETTINGS_DEFINITION_RECIPIENT,
  TABLE_SETTINGS_DEFINITION_SEARCHARGUMENT,
  TABLE_SETTINGS_DEFINITION_RESULT_TABLE,
  TABLE_SETTINGS_DEFINITION_CUSTOM_DIALOG,
  TABLE_SETTINGS_DEFINITION_VIEW_PROFILE,
  TABLE_SETTINGS_DEFINITION_JOBGROUPS,
  TABLE_SETTINGS_FOLDER_DOCUMENT_ASSIGNMENT,
  TABLE_SETTINGS_INDEX_DOCUMENT_ASSIGNMENT,
  TABLE_SETTINGS_PRINTER_DEVICE_INFO,
  TABLE_SETTINGS_RECIPIENT_DOCUMENT_ASSIGNMENT,
  TABLE_SETTINGS_SECURITYPROFILES,
  TABLE_SETTINGS_SERVER_ARCHIVEFILE,
  TABLE_SETTINGS_SERVER_LPD_STATUS,
  TABLE_SETTINGS_SERVER_MESSAGE_LOG,
  TABLE_SETTINGS_STANDARDSELECTION,
  TABLE_SETTINGS_USERMANAGEMENT_GROUPS,
  TABLE_SETTINGS_USERMANAGEMENT_REMOTEUSER,
  TABLE_SETTINGS_USERMANAGEMENT_USERS,
  TABLE_SETTINGS_QUEUE_RELOAD,
  TABLE_SETTINGS_QUEUE_BUNDLE,
  TABLE_SETTINGS_QUEUE_INDEX,
  TABLE_SETTINGS_QUEUE_OUTPUT,
  TABLE_SETTINGS_QUEUE_LPD,
  TABLE_SETTINGS_NODE_DOCUMENT_ASSIGNMENT,
  TABLE_SETTINGS_SEARCH_UC4,
  TABLE_SETTINGS_SEARCH_ZOS,
  TABLE_SETTINGS_SEARCH_CONTROLM,
  TABLE_SETTINGS_SEARCH_SYSLOG,
  TABLE_SETTINGS_CUSTOMSELECTION_DOCUMENTS,
  TABLE_SETTINGS_CUSTOMSELECTION_LOGS,
  TABLE_SETTINGS_CUSTOMSELECTION_UC4,
  TABLE_SETTINGS_CUSTOMSELECTION_ZOS,
  TABLE_SETTINGS_CUSTOMSELECTION_RESULTTABLES
]

/* ************************************************************************************** */
// general
export const LAST_ROUTE = 'LAST_ROUTE'
/* ************************************************************************************** */
// server message log
export const MESSAGE_LOG_LASTTIME_MODE = 'MESSAGE_LOG_LASTTIME_MODE'
export const MESSAGE_LOG_CUSTOMLAST = 'MESSAGE_LOG_CUSTOMLAST'
export const MESSAGE_LOG_CUSTOM_UNIT = 'MESSAGE_LOG_CUSTOM_UNIT'
export const MESSAGE_LOG_FROMDATE = 'MESSAGE_LOG_FROMDATE'
export const MESSAGE_LOG_FROMTIME = 'MESSAGE_LOG_FROMTIME'
export const MESSAGE_LOG_TODATE = 'MESSAGE_LOG_TODATE'
export const MESSAGE_LOG_TOTIME = 'MESSAGE_LOG_TOTIME'
export const MESSAGE_LOG_MESSAGE_ID = 'MESSAGE_LOG_MESSAGE_ID'
export const MESSAGE_LOG_TEXT = 'MESSAGE_LOG_TEXT'
export const MESSAGE_LOG_ACTIVE_TAB = 'MESSAGE_LOG_ACTIVE_TAB'
/* ************************************************************************************** */
/* ************************************************************************************** */
// server log file
export const SERVER_SERVERLOGFILE_SERVER_LOG_FILE = 'SERVER_SERVERLOGFILE_SERVER_LOG_FILE'
export const SERVER_SERVERLOGFILE_DATE = 'SERVER_SERVERLOGFILE_DATE'
/* ************************************************************************************** */
// server printer device information
export const SERVER_PDI_ACTIVE_TAB = 'SERVER_PDI_ACTIVE_TAB'
export const SERVER_PDI_LASTTIME_MODE = 'SERVER_PDI_LASTTIME_MODE'
export const SERVER_PDI_CUSTOM_LAST = 'SERVER_PDI_CUSTOM_LAST'
export const SERVER_PDI_CUSTOM_UNIT = 'SERVER_PDI_CUSTOM_UNIT'
export const SERVER_PDI_FROMDATE = 'SERVER_PDI_FROMDATE'
export const SERVER_PDI_FROMTIME = 'SERVER_PDI_FROMTIME'
export const SERVER_PDI_TODATE = 'SERVER_PDI_TODATE'
export const SERVER_PDI_TOTIME = 'SERVER_PDI_TOTIME'
export const SERVER_PDI_OUTPUTCHANNELID = 'SERVER_PDI_OUTPUTCHANNELID'
export const SERVER_PDI_MESSAGEID = 'SERVER_PDI_MESSAGEID'
export const SERVER_PDI_TEXT = 'SERVER_PDI_TEXT'
// server archive file
export const SERVER_ARCHIVEFILE_ARCHIVEPOOL_ID = 'SERVER_ARCHIVEFILE_ARCHIVEPOOL_ID'
export const SERVER_ARCHIVEFILE_OWNER = 'SERVER_ARCHIVEFILE_OWNER'
export const SERVER_ARCHIVEFILE_FILE = 'SERVER_ARCHIVEFILE_FILE'
export const SERVER_ARCHIVEFILE_ARCHIVEDATE = 'SERVER_ARCHIVEFILE_ARCHIVEDATE'
export const SERVER_ARCHIVEFILE_LEVEL3ARCHIVE = 'SERVER_ARCHIVEFILE_LEVEL3ARCHIVE'
// server output processes
export const SERVER_OUTPUT_PROCESSES_OUTPUT_CHANNEL_ID = 'SERVER_OUTPUT_PROCESSES_OUTPUT_CHANNEL_ID'
export const SERVER_OUTPUT_PROCESSES_OUTPUT_SELECT_BY = 'SERVER_OUTPUT_PROCESSES_OUTPUT_SELECT_BY'
export const SERVER_OUTPUT_PROCESSES_OUTPUT_STATUS = 'SERVER_OUTPUT_PROCESSES_OUTPUT_STATUS'
export const SERVER_OUTPUT_PROCESSES_OUTPUT_PRIORITY = 'SERVER_OUTPUT_PROCESSES_OUTPUT_PRIORITY'
/* ************************************************************************************** */
// Assignments - Folder - Document
export const ASSIGNMENT_FOLDER_DOC_FOLDERID = 'ASSIGNMENT_FOLDER_DOC_FOLDERID'
export const ASSIGNMENT_FOLDER_DOC_FORM = 'ASSIGNMENT_FOLDER_DOC_FORM'
export const ASSIGNMENT_FOLDER_DOC_EXTENSION = 'ASSIGNMENT_FOLDER_DOC_EXTENSION'
export const ASSIGNMENT_FOLDER_DOC_REPORT = 'ASSIGNMENT_FOLDER_DOC_REPORT'
export const ASSIGNMENT_FOLDER_DOC_DYNAMICREPORT = 'ASSIGNMENT_FOLDER_DOC_DYNAMICREPORT'
/* ************************************************************************************** */
// Assignments - Recipient - Document
export const ASSIGNMENT_RECIPIENT_DOC_RECIPIENTID = 'ASSIGNMENT_RECIPIENT_DOC_RECIPIENTID'
export const ASSIGNMENT_RECIPIENT_DOC_FORM = 'ASSIGNMENT_RECIPIENT_DOC_FORM'
export const ASSIGNMENT_RECIPIENT_DOC_EXTENSION = 'ASSIGNMENT_RECIPIENT_DOC_EXTENSION'
export const ASSIGNMENT_RECIPIENT_DOC_REPORT = 'ASSIGNMENT_RECIPIENT_DOC_REPORT'
export const ASSIGNMENT_RECIPIENT_DOC_DYNAMICREPORT = 'ASSIGNMENT_RECIPIENT_DOC_DYNAMICREPORT'
export const ASSIGNMENT_RECIPIENT_DOC_ASSIGNMENT = 'ASSIGNMENT_RECIPIENT_DOC_ASSIGNMENT'
export const ASSIGNMENT_RECIPIENT_DOC_DISTRIBUTEVIA = 'ASSIGNMENT_RECIPIENT_DOC_DISTRIBUTEVIA'
export const ASSIGNMENT_RECIPIENT_DOC_OUTPUTCHANNEL = 'ASSIGNMENT_RECIPIENT_DOC_OUTPUTCHANNEL'
export const ASSIGNMENT_RECIPIENT_DOC_OUTPUTFORMAT = 'ASSIGNMENT_RECIPIENT_DOC_OUTPUTFORMAT'
export const ASSIGNMENT_RECIPIENT_DOC_PPN = 'ASSIGNMENT_RECIPIENT_DOC_PPN'
/* ************************************************************************************** */
// Assignments - Index - Document
export const ASSIGNMENT_INDEX_DOC_INDEXID = 'ASSIGNMENT_INDEX_DOC_INDEXID'
export const ASSIGNMENT_INDEX_DOC_FORM = 'ASSIGNMENT_INDEX_DOC_FORM'
export const ASSIGNMENT_INDEX_DOC_EXTENSION = 'ASSIGNMENT_INDEX_DOC_EXTENSION'
export const ASSIGNMENT_INDEX_DOC_REPORT = 'ASSIGNMENT_INDEX_DOC_REPORT'
export const ASSIGNMENT_INDEX_DOC_AVAILABLE = 'ASSIGNMENT_INDEX_DOC_AVAILABLE'
/* ************************************************************************************** */
// Assignments - Node - Document
export const ASSIGNMENT_NODE_DOC_NODEID = 'ASSIGNMENT_NODE_DOC_NODEID'
export const ASSIGNMENT_NODE_DOC_FORM = 'ASSIGNMENT_NODE_DOC_FORM'
export const ASSIGNMENT_NODE_DOC_EXTENSION = 'ASSIGNMENT_NODE_DOC_EXTENSION'
/* ************************************************************************************** */
// Time - Keys
/* ************************************************************************************** */
// Queue - Reload
export const QUEUE_RELOAD_ACTIVE_TAB = 'QUEUE_RELOAD_ACTIVE_TAB'
export const QUEUE_RELOAD_LASTTIME_MODE = 'QUEUE_RELOAD_LASTTIME_MODE'
export const QUEUE_RELOAD_TIME_CUSTOM_LAST = 'QUEUE_RELOAD_TIME_CUSTOM_LAST'
export const QUEUE_RELOAD_TIME_CUSTOM_UNIT = 'QUEUE_RELOAD_TIME_CUSTOM_UNIT'
export const QUEUE_RELOAD_START_DATE = 'QUEUE_RELOAD_START_DATE'
export const QUEUE_RELOAD_START_TIME = 'QUEUE_RELOAD_START_TIME'
export const QUEUE_RELOAD_END_DATE = 'QUEUE_RELOAD_END_DATE'
export const QUEUE_RELOAD_END_TIME = 'QUEUE_RELOAD_END_TIME'
export const QUEUE_RELOAD_FORM = 'QUEUE_RELOAD_FORM'
export const QUEUE_RELOAD_EXTENSION = 'QUEUE_RELOAD_EXTENSION'
export const QUEUE_RELOAD_REPORT = 'QUEUE_RELOAD_REPORT'
export const QUEUE_RELOAD_STATUS = 'QUEUE_RELOAD_STATUS'
/* ************************************************************************************** */
// Queue - Bundle
export const QUEUE_BUNDLE_OUTPUT_CHANNEL = 'QUEUE_BUNDLE_OUTPUT_CHANNEL'
export const QUEUE_BUNDLE_OUTPUT_FORMAT = 'QUEUE_BUNDLE_OUTPUT_FORMAT'
export const QUEUE_BUNDLE_ADDRESS = 'QUEUE_BUNDLE_ADDRESS'
export const QUEUE_BUNDLE_RECIPIENT = 'QUEUE_BUNDLE_RECIPIENT'
export const QUEUE_BUNDLE_FORM = 'QUEUE_BUNDLE_FORM'
export const QUEUE_BUNDLE_EXTENSION = 'QUEUE_BUNDLE_EXTENSION'
export const QUEUE_BUNDLE_REPORT = 'QUEUE_BUNDLE_REPORT'
export const QUEUE_BUNDLE_WREPORT = 'QUEUE_BUNDLE_WREPORT'
export const QUEUE_BUNDLE_JOBNAME = 'QUEUE_BUNDLE_JOBNAME'
export const QUEUE_BUNDLE_ONLY_STATUS_HOLD = 'QUEUE_BUNDLE_ONLY_STATUS_HOLD'
export const QUEUE_BUNDLE_DOCUSER1 = 'QUEUE_BUNDLE_DOCUSER1'
export const QUEUE_BUNDLE_DOCUSER2 = 'QUEUE_BUNDLE_DOCUSER2'
export const QUEUE_BUNDLE_DOCUSER3 = 'QUEUE_BUNDLE_DOCUSER3'
export const QUEUE_BUNDLE_DOCUSER4 = 'QUEUE_BUNDLE_DOCUSER4'
export const QUEUE_BUNDLE_DOCUSER5 = 'QUEUE_BUNDLE_DOCUSER5'
export const QUEUE_BUNDLE_DOCUSER6 = 'QUEUE_BUNDLE_DOCUSER6'
export const QUEUE_BUNDLE_DOCUSER7 = 'QUEUE_BUNDLE_DOCUSER7'
export const QUEUE_BUNDLE_DOCUSER8 = 'QUEUE_BUNDLE_DOCUSER8'
/* ************************************************************************************** */
// Queue - Index
export const QUEUE_INDEX_ACTIVE_TAB = 'QUEUE_INDEX_ACTIVE_TAB'
export const QUEUE_INDEX_LASTTIME_MODE = 'QUEUE_INDEX_LASTTIME_MODE'
export const QUEUE_INDEX_TIME_CUSTOM_LAST = 'QUEUE_INDEX_TIME_CUSTOM_LAST'
export const QUEUE_INDEX_TIME_CUSTOM_UNIT = 'QUEUE_INDEX_TIME_CUSTOM_UNIT'
export const QUEUE_INDEX_START_DATE = 'QUEUE_INDEX_START_DATE'
export const QUEUE_INDEX_START_TIME = 'QUEUE_INDEX_START_TIME'
export const QUEUE_INDEX_END_DATE = 'QUEUE_INDEX_END_DATE'
export const QUEUE_INDEX_END_TIME = 'QUEUE_INDEX_END_TIME'
export const QUEUE_INDEX_FORM = 'QUEUE_INDEX_FORM'
export const QUEUE_INDEX_EXTENSION = 'QUEUE_INDEX_EXTENSION'
export const QUEUE_INDEX_REPORT = 'QUEUE_INDEX_REPORT'
export const QUEUE_INDEX_STATUS = 'QUEUE_INDEX_STATUS'
/* ************************************************************************************** */
// Queue - Output
export const QUEUE_OUTPUT_ACTIVE_TAB = 'QUEUE_OUTPUT_ACTIVE_TAB'
export const QUEUE_OUTPUT_LASTTIME_MODE = 'QUEUE_OUTPUT_LASTTIME_MODE'
export const QUEUE_OUTPUT_TIME_CUSTOM_LAST = 'QUEUE_OUTPUT_TIME_CUSTOM_LAST'
export const QUEUE_OUTPUT_TIME_CUSTOM_UNIT = 'QUEUE_OUTPUT_TIME_CUSTOM_UNIT'
export const QUEUE_OUTPUT_START_DATE = 'QUEUE_OUTPUT_START_DATE'
export const QUEUE_OUTPUT_START_TIME = 'QUEUE_OUTPUT_START_TIME'
export const QUEUE_OUTPUT_END_DATE = 'QUEUE_OUTPUT_END_DATE'
export const QUEUE_OUTPUT_END_TIME = 'QUEUE_OUTPUT_END_TIME'
export const QUEUE_OUTPUT_OUTPUTCHANNEL_ID = 'QUEUE_OUTPUT_OUTPUTCHANNEL_ID'
export const QUEUE_OUTPUT_TYPE = 'QUEUE_OUTPUT_TYPE'
export const QUEUE_OUTPUT_PRIORITY = 'QUEUE_OUTPUT_PRIORITY'
export const QUEUE_OUTPUT_SPECIFIC_PRIORITY = 'QUEUE_OUTPUT_SPECIFIC_PRIORITY'
export const QUEUE_OUTPUT_REQUEUED = 'QUEUE_OUTPUT_REQUEUED'
export const QUEUE_OUTPUT_PRINT_BUNDLE = 'QUEUE_OUTPUT_PRINT_BUNDLE'
export const QUEUE_OUTPUT_BUNDLE_NAME = 'QUEUE_OUTPUT_BUNDLE_NAME'
export const QUEUE_OUTPUT_REQUESTOR = 'QUEUE_OUTPUT_REQUESTOR'
export const QUEUE_OUTPUT_RECIPIENT_ID = 'QUEUE_OUTPUT_RECIPIENT_ID'
export const QUEUE_OUTPUT_FORM = 'QUEUE_OUTPUT_FORM'
export const QUEUE_OUTPUT_EXTENSION = 'QUEUE_OUTPUT_EXTENSION'
export const QUEUE_OUTPUT_REPORT = 'QUEUE_OUTPUT_REPORT'
export const QUEUE_OUTPUT_JOBNAME = 'QUEUE_OUTPUT_JOBNAME'
export const QUEUE_OUTPUT_ADDRESS = 'QUEUE_OUTPUT_ADDRESS'
export const QUEUE_OUTPUT_DISPLAY = 'QUEUE_OUTPUT_DISPLAY'
export const QUEUE_OUTPUT_STATUS_PRINTED = 'QUEUE_OUTPUT_STATUS_PRINTED'
export const QUEUE_OUTPUT_STATUS_ERROR = 'QUEUE_OUTPUT_STATUS_ERROR'
export const QUEUE_OUTPUT_STATUS_ACTIVE = 'QUEUE_OUTPUT_STATUS_ACTIVE'
export const QUEUE_OUTPUT_STATUS_WAITING = 'QUEUE_OUTPUT_STATUS_WAITING'
export const QUEUE_OUTPUT_STATUS_HOLD = 'QUEUE_OUTPUT_STATUS_HOLD'
export const QUEUE_OUTPUT_STATUS_DELETE = 'QUEUE_OUTPUT_STATUS_DELETE'
export const QUEUE_OUTPUT_STATUS_RETRY = 'QUEUE_OUTPUT_STATUS_RETRY'
export const QUEUE_OUTPUT_STATUS_CANCELLED = 'QUEUE_OUTPUT_STATUS_CANCELLED'
export const QUEUE_OUTPUT_STATUS_REQUEUED = 'QUEUE_OUTPUT_STATUS_REQUEUED'
export const QUEUE_OUTPUT_STATUS = 'QUEUE_OUTPUT_STATUS'
/* ************************************************************************************** */
// Queue - LPD
export const QUEUE_LPD_LPD_QUEUE_ID = 'QUEUE_LPD_LPD_QUEUE_ID'
export const QUEUE_LPD_SPOOLING_BEGIN = 'QUEUE_LPD_SPOOLING_BEGIN'
export const QUEUE_LPD_SPOOLING_END = 'QUEUE_LPD_SPOOLING_END'
export const QUEUE_LPD_SPOOLING_FAILED = 'QUEUE_LPD_SPOOLING_FAILED'
export const QUEUE_LPD_TRANSFER_BEGIN = 'QUEUE_LPD_TRANSFER_BEGIN'
export const QUEUE_LPD_TRANSFER_END = 'QUEUE_LPD_TRANSFER_END'
export const QUEUE_LPD_TRANSFER_FAILED = 'QUEUE_LPD_TRANSFER_FAILED'
export const QUEUE_LPD_WAITING_FOR_AUTOPRINT = 'QUEUE_LPD_WAITING_FOR_AUTOPRINT'