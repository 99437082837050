import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActionButtons from '../action_buttons/ActionButtons';
import TableCell from '../table_cell/TableCell';
import TableCheckbox from '../table_checkbox/TableCheckbox';
import TableButton from '../table_buttons/table_button/TableButton';
import { Draggable } from '@hello-pangea/dnd';
import Icon from '../../icon/Icon';
import { equalsArrays } from '../../../utils/Utils';

// Styles
import './TableRow.scss';

export default class TableRow extends Component {

  state = {
    hover: false,
    _WIP_renderHelperValue: false
  };

  shouldComponentUpdate(nextProps, nextState) {
    const oldValues = Object.values(this.props);
    const newValues = Object.values(nextProps);
    if (oldValues.length !== newValues.length) {
      return true;
    }
    if (this.state.hover !== nextState.hover) {
      return true;
    }
    if (this.state._WIP_renderHelperValue !== nextState._WIP_renderHelperValue) {
      return true;
    }
    for (let i = 0; i < oldValues.length; i++) {
      if (Array.isArray(newValues[i])) {
        if (!equalsArrays(newValues[i], oldValues[i])) {
          return true;
        }
      }
      else if (newValues[i] === null || newValues[i] === undefined || ['string', 'number', 'boolean'].includes(typeof newValues[i])) {
        if (oldValues[i] !== newValues[i]) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * @description Formats a given number.
   * @param {number} number The number.
   */
  // just works when we use numbers without decimal places
  // TODO: find a workaround when we start to work with numbers with decimal places
  formatNumber = number => {
    const { language } = this.props;
    const EN = 'en';
    const DE = 'de';
    const FR = 'fr';
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    if (language === EN) {
      return number.toString().replace(regex, ',');
    }
    else if (language === DE) {
      return number.toString().replace(regex, '.');
    }
    else if (language === FR) {
      return number.toString().replace(regex, ' ');
    }
    else {
      return number;
    }

    // TODO: general and better solution for formatting numbers.
    // If we use this we have to store the numberformat object in state and update it when language changes.
    // Don't just use the line below because it's very slow because the numberformat object is created for every row everytime it changes.
    // return new Intl.NumberFormat(this.props.language).format(number)
  };

  /**
   * @description Formats the celldata.
   * @param {*} celldata The celldata to format.
   * @param {Number} index The index of the cell.
   * @returns {String} The formatted celldata.
   */
  formatCellData = (cellData, index) => {
    const { columnSortDefs } = this.props;
    if (columnSortDefs[index] === 'number' && typeof cellData !== 'string') {
      return this.formatNumber(cellData);
    }
    return cellData;
  };

  /**
   * @description Adds or removes the hover of the first button.
   * @param {Boolean} hover Flag for adding or removing the hover of the first action button.
   */
  handleHover = hover => {
    const { id, index, createActionButtons, noAction } = this.props;
    if (!noAction) {
      if (createActionButtons) {
        const el = document.querySelector(`#${id}_row_${index}_actionbutton_0`);
        if (el) {
          if (hover) {
            el.classList.add('bux_fake_hover');
          }
          else {
            el.classList.remove('bux_fake_hover');
          }
        }
      }
    }
  };

  /**
   * @description Changes the visualization for the data inside a table cell to highlight the text, which is filtered.
   * @param {(string|object)} data The data inside a table cell.
   */
  displayData = (data, index) => {
    const { filter } = this.props;
    const result = [];
    data = this.formatCellData(data, index);
    if (!['object', 'number'].includes(typeof data)) {
      const lowerCaseFilter = filter.toLowerCase();
      if (lowerCaseFilter !== '') {
        while (data.toLowerCase().indexOf(lowerCaseFilter) !== -1) {
          // pushs the text before the founded text
          result.push(data.substring(0, data.toLowerCase().indexOf(lowerCaseFilter)));
          // pushs the founded text inside a span to style its background
          result.push(
            <span key={`hightlight_${result.length}`} className={'bux_highlight'}>
              {data.substring(data.toLowerCase().indexOf(lowerCaseFilter), data.toLowerCase().indexOf(lowerCaseFilter) + lowerCaseFilter.length)}
            </span>
          );
          // cuts off the pushed text
          data = data.substring(data.toLowerCase().indexOf(lowerCaseFilter) + lowerCaseFilter.length, data.length);
        }
      }
    }
    // pushs the remaining text which do not include the filter text
    result.push(data);
    return result;
  };

  /**
   * @description Renders the checkbox of the row.
   */
  renderCheckBox = () => {
    const {
      id, index, noAction, selectable, handleOnCheck, enableDnd, showDragHandle,
      isChecked, isSelected, isGhosting
    } = this.props;
    const { hover } = this.state;

    const className = `bux_datatable_checkbox
      ${enableDnd && hover ? 'bux_fake_hover' : ''}
      ${enableDnd && noAction ? 'bux_grab' : ''}
      ${noAction && isSelected ? 'bux_row_selected' : ''}
      ${isGhosting ? 'bux_row_ghosting' : ''}
      ${showDragHandle && selectable ? 'bux_row_remove_checkbox_border' : ''}
    `;

    return (
      selectable &&
      <TableCheckbox
        id={`${id}_${index}`}
        index={index}
        handleOnCheck={handleOnCheck}
        isChecked={isChecked}
        className={className}
      />
    );
  };

  /**
   * @description Renders the data.
   * @param {Array} dataToRender The data to render.
   * @param {Boolean} isDragOccuring Flag if drag is occuring.
   */
  renderData = (dataToRender, isDragOccuring) => {
    const { id, data, handleClick, index, actionIndex, noAction, enableDnd, createActionButtons, selectedRows } = this.props;
    const { hover } = this.state;

    return (
      dataToRender.map((cellData, i) => {
        return (
          <TableCell
            isdragoccuring={isDragOccuring.toString()} // write isdragoccuring lowercase and convert it to string because otherwise there are console errors!
            className={`
              ${React.isValidElement(cellData) ? 'center' : ''}
              ${this.props.isSelected ? 'bux_row_selected' : ''}
              ${this.props.isGhosting ? 'bux_row_ghosting' : ''}
              ${enableDnd && noAction ? 'bux_grab' : ''}
              ${hover ? 'bux_fake_hover' : ''}
              ${selectedRows && selectedRows.includes(actionIndex) ? 'bux_fake_select' : ''}
              `}
            key={`cellData_${actionIndex}_${i}`}
            id={`${id}_row_${index}_col_${i}`}
            onMouseEnter={() => this.setState({ hover: true }, () => this.handleHover(true))}
            onMouseLeave={() => this.setState({ hover: false }, () => this.handleHover(false))}
            onClick={handleClick && ((event) => handleClick(actionIndex, event))}>
            <div className={`bux_celldata ${(!createActionButtons && i === data.length - 1) ? 'bux_tablerow_add_border_right' : ''}`}>{this.displayData(cellData, i)}</div>
          </TableCell>
        );
      })
    );
  };

  /**
   * @description Renders the expandable button.
   */
  renderExpandable = () => {
    const {
      id, index, actionIndex, createActionButtons, expandable, isOpen, onOpenCloseRow, translate
    } = this.props;
    return (
      expandable &&
      <td id={`${id}_row_${index}_actionbutton_0`} className={'bux_datatable_actionbutton'} style={{ right: createActionButtons(index).length * 40 }}>
        <TableButton
          id={`${id}_tableButton_${index}`}
          iconName={isOpen ? 'chevron_up' : 'chevron_down'}
          title={translate(isOpen ? 'general.close_row' : 'general.open_row')}
          onClick={() => onOpenCloseRow(actionIndex)}
        />
      </td>
    );
  };

  /**
   * @description Renders the actionbuttons.
   */
  renderActionButtons = () => {
    const {
      id, index, actionIndex, createActionButtons, expandable,
    } = this.props;

    return (
      createActionButtons &&
      <ActionButtons
        id={`${id}_row_${index}`}
        index={actionIndex}
        createActionButtons={createActionButtons}
        expandable={expandable}
        // WIP: Due to the nesting of the action button(s) within the data table row and the usage of 'createPortal' in <TableButtonGroup...> the data of the context menu needs to be updated before the DOM elements for the context menu are injected via "createPortal"
        _WIP_renderHelperValue={this.state._WIP_renderHelperValue}
        _WIP_updateRenderHelperValue={(boolean) => this.setState({ _WIP_renderHelperValue: boolean })}
      />
    );
  };

  /**
   * @description Renders the row wrapped in a draggable.
   */
  renderDraggable = () => {
    const { id, data, index, noAction, enableDnd, showDragHandle, selectedIds, actionIndex } = this.props;
    const { hover } = this.state;
    let visibleRowData = data;
    return (
      <Draggable
        key={`${id}_${actionIndex}`}
        draggableId={`${id}_row_${index}`}
        index={index}
        isDragDisabled={!enableDnd}>
        {(provided, snapshot) => {
          const shouldShowSelection = snapshot.isDragging && selectedIds && selectedIds.length > 1;
          // change visible item to the first selected item when group dragging
          if (shouldShowSelection) {
            visibleRowData = selectedIds[0];
          }
          return (
            <tr
              id={`${id}_row_${index}`}
              key={`${id}_${actionIndex}_tr`}
              className={`
            ${noAction && !enableDnd ? 'bux_no_action' : ''}
            ${shouldShowSelection ? 'bux_row_grouping' : ''}
            ${snapshot.isDragging ? 'bux_tablerow_dragging' : ''}
          `}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}>
              {/* drag handle icon */}
              {
                showDragHandle &&
                <td
                  className={`bux_tablerow_draghandle
                ${this.props.isSelected ? 'bux_row_selected' : ''}
                ${this.props.isGhosting ? 'bux_row_ghosting' : ''}
                ${(noAction && hover) ? 'bux_fake_hover' : ''}
                `}
                  onMouseEnter={() => this.setState({ hover: noAction !== undefined }, () => this.handleHover(true))}
                  onMouseLeave={() => this.setState({ hover: false }, () => this.handleHover(false))}
                >
                  <Icon id={`${id}_draggableicon`} name={'drag_horizontal'} />
                </td>
              }
              {/* checkbox */}
              {this.renderCheckBox()}
              {/* data */}
              {this.renderData(visibleRowData, snapshot.isDragging)}
              {/* expandable button */}
              {this.renderExpandable()}
              {/* action buttons */}
              {this.renderActionButtons()}
              {
                shouldShowSelection && (
                  <div className='bux_row_selectionCount'>
                    {this.props.selectedIds.length}
                  </div>
                )
              }
            </tr>
          );
        }}
      </Draggable>
    );
  };

  /**
   * @description Renders the row without the draggable wrapper.
   */
  renderRow = () => {
    const { id, data, index, noAction, enableDnd, actionIndex } = this.props;
    const visibleRowData = data;
    return (
      <tr
        id={`${id}_row_${index}`}
        key={`${id}_${actionIndex}_tr`}
        className={`${noAction && !enableDnd ? 'bux_no_action' : ''}`}>

        {/* checkbox */}
        {this.renderCheckBox()}
        {/* data */}
        {this.renderData(visibleRowData, false)}
        {/* expandable button */}
        {this.renderExpandable()}
        {/* action buttons */}
        {this.renderActionButtons()}
      </tr>
    );
  };

  render = () => {
    const { enableDnd } = this.props;
    return (
      <>
        {/* render with draggable if dnd is enabled */}
        {enableDnd && this.renderDraggable()}
        {/* render without draggable if dnd is disabled to improve performance */}
        {!enableDnd && this.renderRow()}
      </>
    );
  };
}

TableRow.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string.isRequired,
  /**
   * Data which is displayed in DataTable component
   *
   * Type: `array<any>`
   */
  data: PropTypes.array.isRequired,
  /** Row index */
  index: PropTypes.number.isRequired,
  /**
   * Function to be called on action button click
   * @param {number} index Index of clicked action button
   */
  handleClick: PropTypes.func,
  /** Used to display the numbers in the right language format (e.g. 130.000 or 130,000 or 130 000).*/
  language: PropTypes.oneOf(['en', 'de', 'fr']),
  /**
     * Types of each column to sort correctly.
     *
     * Enum: "date-time","date", "number", "string", "progressbar", "icon"
     */
  columnSortDefs: PropTypes.arrayOf(PropTypes.oneOf(['date-time', 'date', 'number', 'string', 'progressbar', 'icon'])).isRequired,
  /**
     * Function that returns array of JSX elements which are displayed at end of each row.
     *
     * @param {number} rowIndex index of the row
     * @return {array<element>} elements
     */
  createActionButtons: PropTypes.func,
  /** No action for actions button */
  noAction: PropTypes.bool,
  /**
   * Initial value for filtering
   */
  filter: PropTypes.string,
  /** Action index */
  actionIndex: PropTypes.number,
  /**
   * Used when row of the DataTable should be selectable for e.g. download option.
   * The checkbox will appear in the first column of the row.
   */
  selectable: PropTypes.bool,
  /**
   * Function to be called on checkbox selected/unselected
   * @param {bool} isChecked
  */
  handleOnCheck: PropTypes.func,
  /** Enables drag and drop features for the row */
  enableDnd: PropTypes.bool,
  /** Enables visualization of drag handle */
  showDragHandle: PropTypes.bool,
  /** Indicates whether the checkbox is checked or not */
  isChecked: PropTypes.bool,
  /** Indicates whether the row is selected or not */
  isSelected: PropTypes.bool,
  /** Indicates whether the row is ghosting or not */
  isGhosting: PropTypes.bool,
  /** Used with drag&drop features. Array of selected items. */
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  /** Used to highlight some rows in the DataTable */
  selectedRows: PropTypes.arrayOf(PropTypes.number),
  /** Used when DataList should have expandable rows. */
  expandable: PropTypes.bool,
  /** Indicates whether the row is open or not */
  isOpen: PropTypes.bool,
  /** Function to be called on row close */
  onOpenCloseRow: PropTypes.func,
  /**
     * Translate function key => key
     * you will need to translate following keys by our own:
     *
     * - `general.close_row`
     * - `general.open_row`
     *
     * @param {string} key
     */
  translate: PropTypes.func,
};