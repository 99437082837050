import PropTypes from 'prop-types'
import { Component } from 'react'
import * as SortUtils from 'utils/SortUtils'

// components
import {
  Column, DataTable, DownloadWrapper, EmptySearchResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup,
  TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import DocumentInformation from 'components/dialogs/document_information_dialog/DocumentInformation'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as BundleQueueActions from 'redux/actions/BundleQueueActions'
import { getDocument } from 'redux/actions/StandardSelectionActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultBundleQueue extends Component {
  state = {
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation(),
    showDeleteDialog: false,
    showDocumentInformationDialog: false
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    return [
      { rest: DCR, translation: 'definition.output_channel_id', default: true },
      { rest: PCR, translation: 'definition.output_format_id', default: true },
      { rest: ADDRESS, translation: 'definition.address', default: true },
      { rest: RECI, translation: 'recipient.reci_id', default: true },
      { rest: FORM, translation: 'general.form', default: true },
      { rest: EXT, translation: 'general.extension', default: true },
      { rest: WREPORT, translation: 'general.report', default: true },
      { rest: SRCPAGES, translation: 'general.pages', default: true },
      { rest: LRICOPY, translation: 'import.copies', default: true },
      { rest: DOCUMENT_IMPORTED, translation: 'queue.documentimported', default: true },
      { rest: SRCJOBN, translation: 'general.jobname', default: true },
      { rest: STATUS, translation: 'general.status', default: true },
      { rest: SRCJOBI, translation: 'general.doctype', default: true },
      { rest: B93DATE },
      { rest: B93TIME },
      { rest: LTOKEN },
      { rest: RTOKEN },
      { rest: DOCUSR1 },
      { rest: DOCUSR2 },
      { rest: DOCUSR3 },
      { rest: DOCUSR4 },
      { rest: DOCUSR5 },
      { rest: DOCUSR6 },
      { rest: DOCUSR7 },
      { rest: DOCUSR8 }
    ]
  }

  /**
   * @description Refreshes the table.
   */
  handleRefresh = () => {
    const { preferences, getBundleQueues } = this.props

    const searchParams = {
      'DCR': preferences[Preferences.QUEUE_BUNDLE_OUTPUT_CHANNEL],
      'PCR': preferences[Preferences.QUEUE_BUNDLE_OUTPUT_FORMAT],
      'PREDRECI': preferences[Preferences.QUEUE_BUNDLE_ADDRESS],
      'RECI': preferences[Preferences.QUEUE_BUNDLE_RECIPIENT],
      'FORM': preferences[Preferences.QUEUE_BUNDLE_FORM],
      'EXT': preferences[Preferences.QUEUE_BUNDLE_EXTENSION],
      'REPORT': preferences[Preferences.QUEUE_BUNDLE_REPORT],
      'WREPORT': preferences[Preferences.QUEUE_BUNDLE_WREPORT],
      'SRCJOBN': preferences[Preferences.QUEUE_BUNDLE_JOBNAME],
      'BUNDLSTA': preferences[Preferences.QUEUE_BUNDLE_ONLY_STATUS_HOLD] ? 'H' : '',
      'DOCUSR1': preferences[Preferences.QUEUE_BUNDLE_DOCUSER1],
      'DOCUSR2': preferences[Preferences.QUEUE_BUNDLE_DOCUSER2],
      'DOCUSR3': preferences[Preferences.QUEUE_BUNDLE_DOCUSER3],
      'DOCUSR4': preferences[Preferences.QUEUE_BUNDLE_DOCUSER4],
      'DOCUSR5': preferences[Preferences.QUEUE_BUNDLE_DOCUSER5],
      'DOCUSR6': preferences[Preferences.QUEUE_BUNDLE_DOCUSER6],
      'DOCUSR7': preferences[Preferences.QUEUE_BUNDLE_DOCUSER7],
      'DOCUSR8': preferences[Preferences.QUEUE_BUNDLE_DOCUSER8],
    }
    getBundleQueues(searchParams)
  }

  /**
   * @description Handles the modal dialogs
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { bundleQueues, getBundleQueueDetails } = this.props
    const docid = bundleQueues.data[index][bundleQueues.header.indexOf('LTOKEN')] + bundleQueues.data[index][bundleQueues.header.indexOf('RTOKEN')]
    getBundleQueueDetails(
      docid,
      bundleQueues.data[index][bundleQueues.header.indexOf('RECI')],
      () => this.setState({ [dialog]: true })
    )
  }

  /**
   * @description Handles the show document information action.
   * @param {Number} rowIndex The current row.
   */
  handleDocumentInformation = rowIndex => {
    const { bundleQueues } = this.props
    const docid = bundleQueues.data[rowIndex][this.headerData('LTOKEN')] + bundleQueues.data[rowIndex][this.headerData('RTOKEN')]
    const callback = () => { this.setState({ showDocumentInformationDialog: true }) }
    this.props.getDocument(undefined, docid, '', callback)
  }

  /**
   * @description Deletes the entry
   */
  handleDelete = () => {
    const { deleteBundleQueue, bundleDetails } = this.props
    deleteBundleQueue(
      bundleDetails.DOCID,
      bundleDetails.RECI,
      () => this.setState({ showDeleteDialog: false })
    )
  }

  /**
   * @description Refreshes the bundle queue at an index and release it.
   * @param {Number} index The current rowindex.
   */
  handleOnRelease = (index) => {
    const { bundleQueues, getBundleQueueDetails, releaseBundleQueue } = this.props
    const docid = bundleQueues.data[index][bundleQueues.header.indexOf('LTOKEN')] + bundleQueues.data[index][bundleQueues.header.indexOf('RTOKEN')]
    getBundleQueueDetails(
      docid,
      bundleQueues.data[index][bundleQueues.header.indexOf('RECI')],
      (bundleDetails) => {
        releaseBundleQueue(bundleDetails.DOCID, bundleDetails.RECI, bundleDetails.FORM, bundleDetails.EXT, bundleDetails.REPORT)
      }
    )
  }

  /**
   * @description Refreshes the bundle queue at an index and hold it.
   * @param {Number} index The current rowindex.
   */
  handleOnHold = (index) => {
    const { bundleQueues, getBundleQueueDetails, holdBundleQueue } = this.props
    const docid = bundleQueues.data[index][bundleQueues.header.indexOf('LTOKEN')] + bundleQueues.data[index][bundleQueues.header.indexOf('RTOKEN')]
    getBundleQueueDetails(
      docid,
      bundleQueues.data[index][bundleQueues.header.indexOf('RECI')],
      (bundleDetails) => {
        holdBundleQueue(bundleDetails.DOCID, bundleDetails.RECI, bundleDetails.FORM, bundleDetails.EXT, bundleDetails.REPORT)
      }
    )
  }

  /**
   * @description resets a bundle queue
   * @param {Number} index The current rowindex.
   */
  handleReset = index => {
    const { bundleQueues, getBundleQueueDetails, resetBundleQueue } = this.props
    const docid = bundleQueues.data[index][bundleQueues.header.indexOf('LTOKEN')] + bundleQueues.data[index][bundleQueues.header.indexOf('RTOKEN')]
    getBundleQueueDetails(
      docid,
      bundleQueues.data[index][bundleQueues.header.indexOf('RECI')],
      (bundleDetails) => {
        resetBundleQueue(bundleDetails.DOCID, bundleDetails.RECI, bundleDetails.FORM, bundleDetails.EXT, bundleDetails.REPORT)
      }
    )
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data.
   * @param {Array} header The headers.
   * @returns {Array} The column sort defs.
   */
  getColumnSortDefs = (data, header) => {
    return SortUtils.getSortTypes(data, header.length)
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   * @returns {Array} The actionbuttons for a row in the table.
   */
  createActionButtons = rowIndex => {
    const { id, bundleQueues } = this.props

    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType='material'
        iconName='info'
        title={translate('documentinformation.modaltitle')}
        onClick={() => this.handleDocumentInformation(rowIndex)}
      />,
      // hold or release icon dependent from the status
      rowIndex !== undefined && bundleQueues.data[rowIndex][bundleQueues.header.indexOf('BUNDLSTA')] === 'H'
        ? (
          <TableButton
            id={`${id}_tableButtonRelease_${rowIndex}`}
            iconType='material'
            iconName='queue_unhold'
            title={translate('queue.release')}
            onClick={() => { this.handleOnRelease(rowIndex) }}
          />
        )
        : (
          <TableButton
            id={`${id}_tableButtonHold_${rowIndex}`}
            iconType='material'
            iconName='queue_hold'
            title={translate('search.hold')}
            onClick={() => { this.handleOnHold(rowIndex) }}
          />
        ),
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          id={`${id}_docInfoBtn`}
          title={translate('documentinformation.modaltitle')}
          onClick={() => this.handleDocumentInformation(rowIndex)}
          icon={<Icon name='info' className='actionIcon' />}
          text={translate('documentinformation.modaltitle')}
        />
        {/* hold or release icon dependent from the status */}
        {rowIndex !== undefined && bundleQueues.data[rowIndex][bundleQueues.header.indexOf('BUNDLSTA')] === 'H'
          ? (
            <TableButtonGroupItem
              id={`${id}_releaseBtn_${rowIndex}`}
              title={translate('queue.release')}
              onClick={() => { this.handleOnRelease(rowIndex) }}
              icon={<Icon name='queue_unhold' className='actionIcon' />}
              text={translate('queue.release')}
            />
          )
          : (
            <TableButtonGroupItem
              id={`${id}_holdBtn_${rowIndex}`}
              title={translate('search.hold')}
              onClick={() => { this.handleOnHold(rowIndex) }}
              icon={<Icon name='queue_hold' className='actionIcon' />}
              text={translate('search.hold')}
            />
          )
        }
        <TableButtonGroupItem
          id={`${id}_resetBtn`}
          title={translate('general.reset')}
          onClick={() => this.handleReset(rowIndex)}
          icon={<Icon name='undo' className='actionIcon' />}
          text={translate('general.reset')}
        />
        <TableButtonGroupItem
          id={`${id}_deleteBtn`}
          title={translate('general.delete')}
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings', lang)}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Gets the index of the header in redux state.
   * @param {String} header The header name of the header in redux state.
   * @returns {Number} The index of the header.
   */
  headerData = header => this.props.bundleQueues.header.indexOf(header)

  /**
   * @description builds the data to a an array which is used later
   */
  getCleanData = () => {
    const { bundleQueues, datemask } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    bundleQueues.data.forEach(el => {
      let dataBuffer = []
      usedHeader.forEach(h => {
        if (h === DOCUMENT_IMPORTED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('B93DATE')], el[this.headerData('B93TIME')].substring(0, 8)))
        }
        else if (h === B93TIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('B93DATE')], el[this.headerData('B93TIME')].substring(0, 8)))
        }
        else if (h === B93DATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('B93DATE')], el[this.headerData('B93TIME')].substring(0, 8), false))
        }
        else if (h === STATUS) {
          dataBuffer.push(el[this.headerData('BUNDLSTA')] === 'H' ? translate('search.hold') : '')
        }
        else {
          const val = el[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_BUNDLE]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_BUNDLE].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the available headers.
   * @returns {Array} The available headers.
   */
  getAvailableHeaders = () => {
    const { header } = this.state
    let buffer = []
    header.forEach(header => {
      if (header.translation) {
        buffer.push(header.translation)
      } else {
        buffer.push(header.rest)
      }
    })
    return buffer
  }

  renderDeleteDialog = () => {
    const { id, bundleDetails } = this.props
    return (
      <DeleteDialog
        id={`${id}_deletebundlequeuedialog`}
        title={translate('queue.delete_bundle_request')}
        question={translate('queue.question_delete_bundle_request')}
        onClose={() => this.setState({ showDeleteDialog: false })}
        onDelete={() => this.handleDelete()}>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_request_outputchannelid_key_text`}>
              {translate('definition.output_channel_id')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_request_outputchannelid_value_text`}>
              {bundleDetails.DCR}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_outputformatid_key_text`}>
              {translate('definition.output_format_id')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_outputformatid_value_text`}>
              {bundleDetails.PCR}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_recipientid_key_text`}>
              {translate('recipient.reci_id')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_recipientid_value_text`}>
              {bundleDetails.RECI}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_form_key_text`}>
              {translate('general.form')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_form_value_text`}>
              {bundleDetails.FORM}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_extension_key_text`}>
              {translate('general.extension')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_extension_value_text`}>
              {bundleDetails.EXT}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_report_key_text`}>
              {translate('general.report')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_report_value_text`}>
              {bundleDetails.REPORT}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_status_key_text`}>
              {translate('general.status')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_status_value_text`}>
              {bundleDetails.BUNDLSTA === 'H' ? translate('search.hold') : ''}
            </p>
          </Column>
        </Row>
      </DeleteDialog>
    )
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_BUNDLE]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_BUNDLE].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  render = () => {
    const { showTableSettingsDialog, showDeleteDialog, showDocumentInformationDialog } = this.state
    const { id, drawerExpanded, autoDrawer, bundleQueues, datemask, lang, keepPagination, document } = this.props
    const data = bundleQueues && bundleQueues.data ? this.getCleanData(bundleQueues.data) : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_QUEUE_BUNDLE }}
          />
        )}
        {showDeleteDialog && this.renderDeleteDialog()}
        {showDocumentInformationDialog && (
          <DocumentInformation
            id={id}
            onClose={() => this.setState({ showDocumentInformationDialog: false })}
            document={document}
          />
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if users not exist in redux
            bundleQueues
              ? (
                // show empty result if there are no users after searching
                bundleQueues.data
                  ? (
                    <DataTable
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={data}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={this.handleDocumentInformation}
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      selectable
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptySearchResult
                      id={`${id}_emptysearchresult`}
                      description={translate('general.no_data_found')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )}
        </ResultContainer>
      </>
    )
  }
}

const ADDRESS = 'PREDRECI'
const RECI = 'RECI'
const DCR = 'DCR'
const PCR = 'PCR'
const B93DATE = 'B93DATE'
const B93TIME = 'B93TIME'
const SRCJOBI = 'SRCJOBI'
const FORM = 'FORM'
const EXT = 'EXT'
const WREPORT = 'WREPORT'
const SRCPAGES = 'SRCPAGES'
const LRICOPY = 'LRICOPY'
const LTOKEN = 'LTOKEN'
const RTOKEN = 'RTOKEN'
const DOCUSR1 = 'DOCUSR1'
const DOCUSR2 = 'DOCUSR2'
const DOCUSR3 = 'DOCUSR3'
const DOCUSR4 = 'DOCUSR4'
const DOCUSR5 = 'DOCUSR5'
const DOCUSR6 = 'DOCUSR6'
const DOCUSR7 = 'DOCUSR7'
const DOCUSR8 = 'DOCUSR8'
const SRCJOBN = 'SRCJOBN'
const STATUS = 'BUNDLSTA'
const DOCUMENT_IMPORTED = 'DOCUMENT_IMPORTED'

SearchResultBundleQueue.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    database: state.database,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    bundleQueues: state.queue.bundle.bundles,
    keepPagination: state.queue.bundle.keepPagination,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    bundleDetails: state.queue.bundle.bundleDetails,
    document: state.search.standardselection.document
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getBundleQueues: (searchParams, callback) => {
      BundleQueueActions.getBundleQueues(searchParams, callback)(dispatch)
    },
    getBundleQueueDetails: (docid, reci, callback) => {
      BundleQueueActions.getBundleQueueDetails(docid, reci, callback)(dispatch)
    },
    deleteBundleQueue: (docid, reci, callback) => {
      BundleQueueActions.deleteBundleQueue(docid, reci, callback)(dispatch)
    },
    holdBundleQueue: (docid, reci, form, extension, report, callback) => {
      BundleQueueActions.holdBundleQueue(docid, reci, form, extension, report, callback)(dispatch)
    },
    releaseBundleQueue: (docid, reci, form, extension, report, callback) => {
      BundleQueueActions.releaseBundleQueue(docid, reci, form, extension, report, callback)(dispatch)
    },
    resetBundleQueue: (docid, reci, form, extension, report, callback) => {
      BundleQueueActions.resetBundleQueue(docid, reci, form, extension, report, callback)(dispatch)
    },
    getDocument: (fields, docid, process, callback) => {
      getDocument(fields, docid, process, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultBundleQueue)