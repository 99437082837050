/**
 * Removes attributes by values from an object.
 * @param {Object} object The object which includes values which should be removed.
 * @param {*} valuesToRemove The values which should be removed from the object.
 * Can be an array or a single value.
 */
export function removeByValue(object, valuesToRemove) {
  const newObject = {...object}

  // throw error if no values to removed passed
  if (arguments.length < 2) {
    throw new Error('No values to remove!')
  }

  if (!(valuesToRemove instanceof Array)) {
    valuesToRemove = [valuesToRemove]
  }

  // loop through all object keys
  Object.keys(newObject).forEach(key => {
    const value = newObject[key]

    // NOTE: Also tested array.includes. ForEach is faster!
    // delete matching values from object
    valuesToRemove.forEach(valueToRemove => {
      if (valueToRemove === value) {
        delete newObject[key]
        return
      }
    })
  })

  return newObject
}

/**
 * @description Checks if an object includes all properties.
 * @param {Object} object The object which should be checked.
 * @param {*} properties The properties which should be checked if they exists in the object. Can be an array or string.
 */
export function hasProperties(object, properties) {
  if (!(properties instanceof Array)) {
    properties = [properties]
  }

  properties.forEach(el => {
    if (!object.hasOwnProperty(el)) {
      return false
    }
  })

  return true
}

/**
 * @description Checks if an object has any values.
 * @param {Object} object The object which should be checked.
 */
export function hasNoValues(object) {
  for (const key in object) {
    if (object[key])
      return false;
  }
  return true;
}