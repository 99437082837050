import { Button, Card, Column, DataTable, MetaDataGrid, Modal, Row, Switch, Tab, TableButton, Tabs } from 'BetaUX2Web-Components/src';
import { translate } from 'language/Language';
import { useEffect, useMemo, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { addTabToDocViewer } from 'redux/actions/DocViewerActions';
import { getLocalIndexesInfo, searchByLocalIndexes } from 'redux/actions/SearchByIndexActions';
import * as Preferences from 'redux/general/Preferences';
import * as DocViewerUtils from 'utils/DocViewerUtils';
import { convertIntoObject } from 'utils/Utils';
import { IndexSearchCriteria } from './IndexSearchCriteria';
import { CriteriaOperator, IndexCriteria, IndexInfo, SearchByIndexResult, convertResponseObjToIndexInfo, convertResponseObjToSearchByIndexResult } from './SearchByIndex.types';

import { DDMMYYYY_DOT, getFormattedDateTime } from 'utils/DateUtils';
import './SearchByIndexDialog.scss';

interface SearchByIndexDialogProps {
  id: string;
  document: any;
  onClose: () => void;
}

const OPERATORS = ['AND', 'OR'];

export const SearchByIndexDialog = ({ ...props }: SearchByIndexDialogProps) => {
  const datemask = useSelector((store: RootStateOrAny) => store.auth.serverdata.preferences[Preferences.DATEMASK])
  const [activeTab, setActiveTab] = useState<number>(0);
  const [operatorIndex, setOperatorIndex] = useState<number>(0);
  const [indexes, setIndexes] = useState<IndexInfo[]>([]);
  const [criteria, setCriteria] = useState<Record<number, IndexCriteria>>({});
  const [results, setResult] = useState<SearchByIndexResult[]>([]);
  const [displayResultTab, setDisplayResultTab] = useState(false);
  const dispatch = useDispatch()

  const docId = useMemo(() => {
    let docId = '' + props.document.LTOKEN + props.document.RTOKEN
    docId = docId.length === 32 ? docId : docId + '0000000000000000'
    return docId;
  }, [props.document])


  const resultsArray = useMemo(() => {
    return results.map((result) => (
      [
        result.position.page, result.position.row, result.position.column,
        result.positionFrom.page, result.positionFrom.row, result.positionFrom.column,
        result.positionTo.page, result.positionTo.row, result.positionTo.column,
        result.value, result.internalIndexName
      ]));
  }, [results])

  const isAnyResult = useMemo(() => Object.keys(results).length !== 0, [results])

  useEffect(() => {
    getConvertedIndexes()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const newCriteria: Record<number, IndexCriteria> = {};
    indexes.forEach((index, i) => {
      newCriteria[i] = {
        internalIndexName: index.internalIndexName,
        from: '',
        to: ''
      }
      setCriteria((newCriteria));
    })
    // eslint-disable-next-line
  }, [indexes])

  const isAnyEntered = useMemo(() => {
    let changed = false;
    for (let criterion of Object.values(criteria)) {
      if (criterion.from !== '' || criterion.to !== '') {
        changed = true;
      }
    }
    return changed;
  }, [criteria])

  const getConvertedIndexes = async () => {
    getLocalIndexesInfo(docId, (resp: any) => {
      if (resp.header && resp.data) {
        const convertedIndexes = convertResponseObjToIndexInfo(convertIntoObject(resp.header, resp.data) as any);
        setIndexes(convertedIndexes);
      }
    })(dispatch)

  }

  const header = (
    <MetaDataGrid
      id={`${props.id}_header`}
      metaData={[
        { label: translate('general.form'), value: props.document.FORM },
        { label: translate('general.extension'), value: props.document.EXT },
        { label: translate('general.date'), value: getFormattedDateTime(props.document.B93DATE, `${DDMMYYYY_DOT}`, `${datemask}`)},
        { label: translate('general.jobname'), value: props.document.SRCJOBN },
        { label: translate('general.doctype'), value: props.document.SRCJOBI },
        { label: translate('general.title'), value: props.document.LTITLE },
      ]}
      columns={4}
    />
  );

  const onCriteriaValueChanged = (criteria: Record<number, IndexCriteria>) => {
    setCriteria(criteria);
    setDisplayResultTab(false);
  }

  const onSearchClicked = () => {
    let criterionWithOperators: string[] = [];
    for (let criterion of Object.values(criteria)) {
      if (criterion.from === '' && criterion.to === '') {
        continue;
      }

      if (criterion.from !== '' && criterion.to !== '') {
        criterionWithOperators.push(`${criterion.internalIndexName} ${CriteriaOperator.GREATER_EQUAL} '${criterion.from}'`);
        criterionWithOperators.push(`${criterion.internalIndexName} ${CriteriaOperator.LESS_EQUAL} '${criterion.to}'`);
      } else if (criterion.from !== '' || criterion.to !== '') {
        criterionWithOperators.push(`${criterion.internalIndexName} ${CriteriaOperator.EQUAL} '${criterion.from}'`);
      }
    }
    const criterionWithOperatorsString = criterionWithOperators.join(` ${OPERATORS[operatorIndex]} `);
    searchByLocalIndexes(docId, criterionWithOperatorsString, (response: any) => {
      const dataConverted = convertIntoObject(response.header, response.data);
      const data = Array.isArray(dataConverted) ? dataConverted : [dataConverted];
      setResult(convertResponseObjToSearchByIndexResult(data))
      setDisplayResultTab(true)
      setActiveTab(1)

    })(dispatch)
  }

  /**
  * @description This function will handle opening a new tab with the parsed document data.
  */
  const openDocumentViewer = (rowIndex: number) => {
    const pageCount = props.document ? parseInt(props.document.SRCPAGES) : 1

    addTabToDocViewer({
      id: docId,
      type: DocViewerUtils.SEARCH_TYPE_STANDARDSELECTION,
      header: Object.keys(props.document),
      data: Object.values(props.document),
      pageCount: pageCount,
      indexData: results[rowIndex]
    })(dispatch)
  }

  const createActionButtons = (rowIndex: number) => (
    [
      <TableButton
        id={`${props.id}_tableButtonDisplay_${rowIndex}`}
        iconType='material'
        iconName='document'
        title={translate('general.display')}
        onClick={() => openDocumentViewer(rowIndex)}
      />]
  );

  return <>
    <Modal.Modal
      id={props.id}
      onClose={props.onClose}
      size='m'>
      <Modal.Header
        id={props.id}
        title={translate('general.search_by_index')}
        onClose={props.onClose}>
        {header}
      </Modal.Header>
      <Modal.Main id={`${props.id}_body`} className={'modal_no_scroll'} flexColumn>
        <Card id={`${props.id}_card`} fullHeight>
          <Tabs
            id={`${props.id}_searchCriteria`}
            activeTab={activeTab}
            disabledTabs={displayResultTab ? [] : [1]}
            onTabChanged={setActiveTab}>
            <Tab title={translate('general.search_by_index.search_criteria')} className={'search_criteria_tab'}>
              <Row>
                <Column col={6}>
                  <Switch id={`${props.id}operator`}
                    items={OPERATORS.map(op => translate(`general.search_by_index.operator_${op.toLowerCase()}`))}
                    activeIndex={operatorIndex}
                    title={'Operator'}
                    onClick={(index: number) => setOperatorIndex(index)} />
                </Column>
              </Row>

              {Object.keys(criteria).length > 0 && <IndexSearchCriteria indexes={indexes} criteria={criteria} onCriteriaValueChanged={onCriteriaValueChanged} />}
              <Button
                id={`${props.id}_search`}
                text={translate('general.search')}
                onClick={() => onSearchClicked()}
                disabled={!isAnyEntered}
                primary
                containerClass={'align-right'}
              />
            </Tab>
            <Tab title={translate('general.search_by_index.search_results')}>
              {
                isAnyResult &&
                <DataTable
                  id={'search_result_datatable'}
                  header={[
                    translate('general.page'), translate('general.line'), translate('general.column'),
                    translate('general.s_page'), translate('general.s_line'), translate('general.s_column'),
                    translate('general.e_page'), translate('general.e_line'), translate('general.e_column'),
                    translate('general.value'), translate('general.name')]}
                  columnSortDefs={['number', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'string', 'string']}
                  data={resultsArray}
                  menu={false}
                  createActionButtons={createActionButtons}
                  translate={() => { }}
                />
              }
            </Tab>
          </Tabs>
        </Card>
      </Modal.Main>
      <Modal.Footer id={''}>
        <Button
          id={`${props.id}_cancelbtn`}
          text={translate('general.cancel')}
          onClick={props.onClose}
        />
      </Modal.Footer>
    </Modal.Modal>
  </>
}