import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as SortUtils from 'utils/SortUtils'

// Components
import { DataTable, DownloadWrapper, EmptySearchResult, Link, NoSearch, ResultContainer } from 'BetaUX2Web-Components/src/'

import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as ServerActions from 'redux/actions/ServerActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'

import * as SEARCH_CONSTANTS from 'components/drawer/content/server/server_body/server_message_log/ServerMessageLog'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultServerMessageLog extends Component {
  state = {
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: DATETIME, translation: 'general.date_time', default: true },
      { rest: MESSAGE_ID, translation: 'database.result.message_id', default: true },
      { rest: TEXT, translation: 'general.text', default: true },
      { rest: DATE, translation: 'general.date' },
      { rest: TIME, translation: 'general.time' }
    ]
  }

  /**
   * @description Refreshs the current table.
   */
  handleRefresh = () => {
    const { preferences } = this.props

    const activeTabIndex = preferences[Preferences.MESSAGE_LOG_ACTIVE_TAB]
    let lastTimeMode = preferences[Preferences.MESSAGE_LOG_LASTTIME_MODE]
    let sdate = ''
    let stime = ''
    let edate = ''
    let etime = ''
    let customLast = ''
    let customUnit = ''

    if (activeTabIndex === 0) {
      if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_TODAY || lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_YESTERDAY) {
        sdate = preferences[Preferences.MESSAGE_LOG_LASTTIME_MODE].toUpperCase()
      } else if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_CUSTOM) {
        customLast = preferences[Preferences.MESSAGE_LOG_CUSTOMLAST]
        customUnit = preferences[Preferences.MESSAGE_LOG_CUSTOM_UNIT]
      }
    } else if (activeTabIndex === 1) {
      sdate = DateUtils.getDateFromUnixTimestamp(preferences[Preferences.MESSAGE_LOG_FROMDATE], DateUtils.DDMMYYYY_DOT)
      stime = DateUtils.formatTimeToDefault(preferences[Preferences.MESSAGE_LOG_FROMTIME])
      edate = DateUtils.getDateFromUnixTimestamp(preferences[Preferences.MESSAGE_LOG_TODATE], DateUtils.DDMMYYYY_DOT)
      etime = DateUtils.formatTimeToDefault(preferences[Preferences.MESSAGE_LOG_TOTIME])
    }

    const searchParams = {
      FROMLAST: customLast,
      TUNITS: customUnit,
      SDATE: sdate,
      STIME: stime,
      EDATE: edate,
      ETIME: etime,
      MSGNR: preferences[Preferences.MESSAGE_LOG_MESSAGE_ID],
      MSGTXT: preferences[Preferences.MESSAGE_LOG_TEXT]
    }

    this.props.getMessageLog(searchParams)
  }

  /**
   * @description gets the index of the header in redux state messagelog.header
   * @param {String} header header name of the header in redux state messagelog.header
   */
  headerData = header => this.props.messagelog.header.indexOf(header)

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, messagelog } = this.props
    let data = []
    let headers = this.getUsedHeader()
    messagelog.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === DATETIME) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('MSGDATE')], element[this.headerData('MSGTIME')].substring(0, 8)))
        }
        else if (header === TIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('MSGDATE')], element[this.headerData('MSGTIME')].substring(0, 8)))
        }
        else if (header === DATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('MSGDATE')], element[this.headerData('MSGTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Creates the interaction menu of the table.
   * @param {Array} data The data to show.
   * @param {Array} header The header to show.
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={() => { this.handleRefresh() }}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />
      ]
    )
  }

  /*
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_MESSAGE_LOG]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_MESSAGE_LOG].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_MESSAGE_LOG]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_MESSAGE_LOG].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  render = () => {
    const { messagelog, id, loading, drawerExpanded, autoDrawer, lang, datemask } = this.props
    const { showTableSettingsDialog } = this.state
    let data = messagelog && messagelog.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_SERVER_MESSAGE_LOG }}
          />
        )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if documents not exist in redux
            messagelog
              ? (
                // show empty result if there are no documents after searching
                data
                  ? (
                    <DataTable
                      loading={loading}
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={data}
                      selectable
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                    />
                  )
                  : (
                    <EmptySearchResult
                      id={`${id}_emptysearchresult`}
                      description={translate('general.no_data_found')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )}
        </ResultContainer>
      </>
    )
  }
}

const DATE = 'MSGDATE'
const TIME = 'MSGTIME'
const MESSAGE_ID = 'MSGNR'
const TEXT = 'MSGTEXT'
const DATETIME = 'DATETIME'

SearchResultServerMessageLog.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    messagelog: state.server.messagelog,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getMessageLog: (searchParams, callback) => {
      ServerActions.getMessageLog(searchParams, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultServerMessageLog)