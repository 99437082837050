import PropTypes from 'prop-types'
import React, { Component } from 'react'

// components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CopyFolderDocumentAssignmentDialog from 'components/dialogs/copy_folder_document_assignment_dialog/CopyFolderDocumentAssignmentDialog'
import CreateFolderDocumentAssignmentDialog from 'components/dialogs/create_folder_document_assignment_dialog/CreateFolderDocumentAssignmentDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyFolderDocumentAssignmentDialog from 'components/dialogs/modify_folder_document_assignment_dialog/ModifyFolderDocumentAssignmentDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as FolderDocumentAssignmentAction from 'redux/actions/FolderDocumentAssignmentAction'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as SortUtils from 'utils/SortUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultFolderDocumentAssignment extends Component {
  state = {
    showTableSettingsDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    showModifyDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: FOLDERID, translation: 'assignment.folder_document.folderid', default: true },
      { rest: FORM, translation: 'general.form', default: true },
      { rest: EXTENSION, translation: 'general.extension', default: true },
      { rest: REPORT, translation: 'general.report', default: true },
      { rest: DYNAMIC_REPORT, translation: 'assignment.folder_document.dynamic_report', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: OWNER },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
    * @description Gets the index of the header in redux state.
    * @param {String} header The header name of the header in redux.
    */
  headerData = header => this.props.folderDocumentAssignments.header.indexOf(header)

  /**
   * @description Handles the modal dialogs for create, modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { folderDocumentAssignments, getFolderDocumentAssignment } = this.props

    getFolderDocumentAssignment(
      undefined,
      folderDocumentAssignments.data[index][this.headerData('GLRNAME')],
      folderDocumentAssignments.data[index][this.headerData('FORM')],
      folderDocumentAssignments.data[index][this.headerData('EXT')],
      folderDocumentAssignments.data[index][this.headerData('REPORT')],
      folderDocumentAssignments.data[index][this.headerData('WREPORT')],
      () => this.setState({ [dialog]: true })
    )
  }

  /**
   * @description Deletes the selected assignment
   */
  deleteFolderdocumentAssignment = () => {
    const { deleteFolderDocumentAssignment, folderDocumentAssignment } = this.props
    const folderDocument = {
      GLRNAME: folderDocumentAssignment.GLRNAME,
      FORM: folderDocumentAssignment.FORM,
      EXT: folderDocumentAssignment.EXT,
      REPORT: folderDocumentAssignment.REPORT,
      WREPORT: folderDocumentAssignment.WREPORT,
      OWNER: folderDocumentAssignment.OWNER
    }
    deleteFolderDocumentAssignment(folderDocument, () => this.setState({ showDeleteDialog: false }))
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData = () => {
    const { datemask, folderDocumentAssignments } = this.props
    const data = []
    const headers = this.getUsedHeader()
    folderDocumentAssignments.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          buffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            buffer.push(`${val}:00`)
          }
          else {
            buffer.push(val)
          }
        }
      })
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
        />
        <TableButtonGroupItem
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
        />
        <TableButtonGroupItem
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.setState({ showCreateDialog: true })}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.props.refreshSearch}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data array.
   * @param {Array} header The header array.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.delete')} ${translate('general.folder_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'folder_document_relation_modal_title'} name={'relation'} tabIndex={-1}/>,
      <div key={'title_3'}>{`${translate('general.document_modal_title')}  ${translate('general.assignment_modal_title')}`}</div>,
    ]
  }

  getModalQuestion = () => {
    return [
      <div key={'title_1'}>{translate('assignment.folder_document_delete_question_1')}</div>,
      <Icon key={'title_2'} id={'folder_document_relation_modal_question'} name={'relation'} />,
      <div key={'title_3'}>{translate('assignment.folder_document_delete_question_2')}</div>
    ]
  }

  getNoResultTitle = () => {
    return [
      `${translate('general.none')} `,
      `${translate('general.folder_modal_title')}`,
      <Icon id={'folder_document_relation_modal_title'} name={'relation'} />,
      `${translate('general.document_modal_title')} `,
      `${translate('general.assignment_modal_title')} `,
      translate('assignment.no_match_single')
    ]
  }

  getNoResultLink = () => {
    return [
      `${translate('assignment.you_can')} `,
      `${translate('general.folder_modal_title')}`,
      <Icon id={'folder_document_relation_modal_title'} name={'relation'} />,
      `${translate('general.document_modal_title')} `,
      `${translate('general.assignment_modal_title')} `,
      translate('assignment.create')
    ]
  }

  /**
 * @description Gets the used headers.
 * @returns {Array} The used headers.
 */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_FOLDER_DOCUMENT_ASSIGNMENT]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_FOLDER_DOCUMENT_ASSIGNMENT].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_FOLDER_DOCUMENT_ASSIGNMENT]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_FOLDER_DOCUMENT_ASSIGNMENT].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  render = () => {
    const { id, drawerExpanded, autoDrawer, folderDocumentAssignments, folderDocumentAssignment, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showDeleteDialog, showCopyDialog, showModifyDialog } = this.state
    const data = folderDocumentAssignments && folderDocumentAssignments.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {showCreateDialog && (
          <CreateFolderDocumentAssignmentDialog
            id={`${id}_createfolderdocumentassignment`}
            onClose={() => this.setState({ showCreateDialog: false })}
          />
        )}
        {showCopyDialog && (
          <CopyFolderDocumentAssignmentDialog
            id={`${id}_copyfolderdocumentassignment`}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        )}
        {showModifyDialog && (
          <ModifyFolderDocumentAssignmentDialog
            id={`${id}_modifyfolderdocumentassignment`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        )}
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_deletefolderdocumentassignmentdialog`}
            title={this.getModalTitle()}
            question={this.getModalQuestion()}
            onClose={() => { this.setState({ showDeleteDialog: false }) }}
            onDelete={() => { this.deleteFolderdocumentAssignment() }}>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_folder_id_key_text`}>
                  {translate('definition.folder_id')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_folder_id_value_text`}>
                  {folderDocumentAssignment.GLRNAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_form_key_text`}>
                  {translate('general.form')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_form_value_text`}>
                  {folderDocumentAssignment.FORM}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_extension_key_text`}>
                  {translate('general.extension')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_extension_value_text`}>
                  {folderDocumentAssignment.EXT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_report_key_text`}>
                  {translate('general.report')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_report_value_text`}>
                  {folderDocumentAssignment.REPORT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_dynamicreport_key_text`}>
                  {translate('assignment.folder_document.dynamic_report')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_dynamicreport_value_text`}>
                  {folderDocumentAssignment.WREPORT}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_FOLDER_DOCUMENT_ASSIGNMENT }}
          />
        )}
        {/* Render search result or empty-result-component */}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {folderDocumentAssignments
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={rowIndex => this.handleDialog(rowIndex, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={this.getNoResultTitle()}
                    link={this.getNoResultLink()}
                    onClick={() => this.setState({ showCreateDialog: true })}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const FOLDERID = 'GLRNAME'
const FORM = 'FORM'
const EXTENSION = 'EXT'
const REPORT = 'REPORT'
const DYNAMIC_REPORT = 'WREPORT'
const LASTCHANGED = 'LASTCHANGED'
const OWNER = 'OWNER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'

SearchResultFolderDocumentAssignment.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    preferences: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    folderDocumentAssignments: state.assignments.folderdocumentassignment.folderDocumentAssignments,
    folderDocumentAssignment: state.assignments.folderdocumentassignment.folderDocumentAssignment,
    keepPagination: state.assignments.folderdocumentassignment.keepPagination,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getFolderDocumentAssignment: (fields, folder, form, ext, report, dynamicReport, callback) => {
      FolderDocumentAssignmentAction.getFolderDocumentAssignment(fields, folder, form, ext, report, dynamicReport, callback)(dispatch)
    },
    deleteFolderDocumentAssignment: (folderDocumentAssignment, callback) => {
      FolderDocumentAssignmentAction.deleteFolderDocumentAssignment(folderDocumentAssignment, callback)(dispatch)
    },
    refreshSearch: () => FolderDocumentAssignmentAction.refreshSearch()(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultFolderDocumentAssignment)