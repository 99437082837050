import * as FolderDefinitionActions from '../actions/FolderDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  folders: undefined,
  folder: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get recipient actions
    case FolderDefinitionActions.FOLDER_DEFINITION_GET_FOLDER_START:
      return { ...state, folder: undefined }
    case FolderDefinitionActions.FOLDER_DEFINITION_GET_FOLDER_SUCCESS:
      return { ...state, folder: action.payload }
    case FolderDefinitionActions.FOLDER_DEFINITION_GET_FOLDER_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition get folders actions
    case FolderDefinitionActions.FOLDER_DEFINITION_GET_FOLDERS_START:
      return { ...state }
    case FolderDefinitionActions.FOLDER_DEFINITION_GET_FOLDERS_SUCCESS:
      return { ...state, folders: action.payload.data, keepPagination: action.keepPagination }
    case FolderDefinitionActions.FOLDER_DEFINITION_GET_FOLDERS_FAILED:
      return { ...state }
    case FolderDefinitionActions.NO_FOLDER_DEFINITION_FOLDERS_FOUND:
      return { ...state, folders: {} }
    /* ********************************************************************************** */
    // Definition create folders actions
    case FolderDefinitionActions.FOLDER_DEFINITION_CREATE_FOLDER_START:
      return { ...state }
    case FolderDefinitionActions.FOLDER_DEFINITION_CREATE_FOLDER_SUCCESS:
      return { ...state }
    case FolderDefinitionActions.FOLDER_DEFINITION_CREATE_FOLDER_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete folders actions
    case FolderDefinitionActions.FOLDER_DEFINITION_DELETE_FOLDER_START:
      return { ...state }
    case FolderDefinitionActions.FOLDER_DEFINITION_DELETE_FOLDER_SUCCESS:
      return { ...state }
    case FolderDefinitionActions.FOLDER_DEFINITION_DELETE_FOLDER_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition clone folders actions
    case FolderDefinitionActions.FOLDER_DEFINITION_CLONE_FOLDER_START:
      return { ...state }
    case FolderDefinitionActions.FOLDER_DEFINITION_CLONE_FOLDER_SUCCESS:
      return { ...state }
    case FolderDefinitionActions.FOLDER_DEFINITION_CLONE_FOLDER_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update folders actions
    case FolderDefinitionActions.FOLDER_DEFINITION_UPDATE_FOLDER_START:
      return { ...state }
    case FolderDefinitionActions.FOLDER_DEFINITION_UPDATE_FOLDER_SUCCESS:
      return { ...state }
    case FolderDefinitionActions.FOLDER_DEFINITION_UPDATE_FOLDER_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, folders: undefined, folder: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer