import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../icon/Icon'
import Checkbox from '../checkbox/Checkbox'
import './HierarchyHeader.scss'

export default class HierarchyHeader extends Component {

  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Header which are displayed in header of DataHierarchy component.*/
    header: PropTypes.arrayOf(PropTypes.string).isRequired,
    /**
     * Sets sorted column
     *
     * Object type:
     *
     * ```js
     * {
     *    number: bool, (true- descending, false: ascending)
     *    ...
     * }
     * ```
     */
    sortedCol: PropTypes.object,
    /**
     * Function to be called on header checkbox clicked. Should be used to check/uncheck all of the visible rows at once.
     * @param {boolean} isChecked
     */
    handleCheckAll: PropTypes.func.isRequired,
    /** Used when click on 'close all open entries'-icon. */
    closeHierarchy: PropTypes.func.isRequired,
    /** Indicates, if the 'close all open entries'-icon is available. */
    isHierarchyOpen: PropTypes.bool.isRequired,
    /** Used header should support selectable feature. Checkbox will appear before all headers. */
    selectable: PropTypes.bool,
    /** Number of action buttons for row. Action buttons have no headers, so placeholders are needed, the HierarchyHeader will render placeholder for them.*/
    actionButtonsLength: PropTypes.number.isRequired,
    /** Indicates if the header checkbox is checked or not */
    allCheckboxesSelected: PropTypes.bool.isRequired,
    /**
     * Function to be called on sort one of the header
     * @param {number} index Index of the sorted header
    */
    sorting: PropTypes.func.isRequired
  }

  state = {
    asc: true
  }

  /**
   * @description Compares two arrays and returns, if they are equal.
   * @param array1 First array which should compared.
   * @param array2 Second array which should compared.
   */
  compareArrays = (array1, array2) => {
    let counter = 0
    if (array1.length === array2.length) {
      for (let i = 0; i < array1.length; i++) {
        if (array1[i] === array2[i]) {
          counter++
        }
      }
    }
    else {
      return false
    }
    return counter === array1.length
  }

  /**
   * @description Handles the sort of the given column.
   * @param index The column to sort.
   */
  onSorting = index => {
    const { sortedCol } = this.props
    sortedCol && sortedCol[index] ? this.setState({ asc: false }) : this.setState({ asc: true })
    this.props.sorting(index)

  }

  /**
   * @description Renders the placeholders for the action buttons. Action buttons have no headers, so placeholders are needed.
   */
  renderActionButtonsPlaceholder = () => {
    const { actionButtonsLength } = this.props
    const buffer = []
    for (let i = 0; i < actionButtonsLength; i++) {
      const multiplicator = (actionButtonsLength - i - 1) >= 0 ? (actionButtonsLength - i - 1) : 0
      buffer.push(<th key={`actionButtonPlaceholder_${i}`} className={'bux_actionbuttons_placeholder_container'} style={{ right: multiplicator * 40 + 'px' }}><div className={'bux_actionbuttons_placeholder'} /></th>)
    }
    return buffer
  }

  /**
   * @description Removes the hover on the hierarchy header when hovering the 'close all open rows'-icon to display a different action.
   * @param {string} id The id of the element which shouldn't have the hover class anymore.
   * @param {bool} removeClass Flag if the class should be removed or added.
   */
  handleHover = (id, removeClass) => {
    let el = document.querySelector(`#${id}`)
    if (removeClass) {
      el.querySelector('.bux_header_container').classList.remove('bux_header_hover')
    }
    else {
      el.querySelector('.bux_header_container').classList.add('bux_header_hover')
    }
  }

  /**
   * @description Closes all open hierarchy rows.
   * @param event The event from the clicked element.
   */
  handleCloseHierarchy = event => {
    event.stopPropagation()
    if (this.props.isHierarchyOpen) {
      this.props.closeHierarchy()
    }
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { header, id, allCheckboxesSelected, handleCheckAll, selectable, sortedCol, isHierarchyOpen } = this.props
    const { asc } = this.state
    return (
      <thead>
        <tr id={`${id}_thead`}>
          {
            selectable &&
            <th id={'th_0'} className={'bux_mark_all'}>
              <Checkbox
                id={`${id}_markAll`}
                className={'markAll'}
                value={allCheckboxesSelected}
                onCheck={isChecked => handleCheckAll(isChecked)}
              />
            </th>
          }
          {
            header.map((header, index) => {
              return (
                <th key={`${header}_${index}`} onClick={() => this.onSorting(index)} id={`${id}_th_${index}`}>
                  <div
                    className={`bux_header_container bux_header_hover ${sortedCol && sortedCol[index] !== undefined ? 'bux_sort_icon' : ''}`}>
                    {
                      index === 0 &&
                      <div
                        onMouseOver={() => this.handleHover(`${id}_th_${index}`, true)}
                        onMouseOut={() => this.handleHover(`${id}_th_${index}`, false)}
                        onClick={event => this.handleCloseHierarchy(event)}
                        tabIndex={isHierarchyOpen ? 0 : -1}
                        onKeyDown={(e) => e.key === 'Enter' && this.handleCloseHierarchy(e) && e.preventDefault() && false}>
                        <Icon
                          name={'hierarchy_collapse'}
                          disabled={!isHierarchyOpen}
                          className={'bux_close_hierarchy_icon'}
                        />
                      </div>
                    }
                    <div className={'bux-header-label_container'} tabIndex={0} onKeyDown={e => e.key === 'Enter' && this.onSorting(index) && false}>
                      {sortedCol && sortedCol[index] !== undefined
                        // arrow upwards stands for ascending sorting
                        ? <>
                          <Icon
                            name={asc ? 'upwards' : 'downwards'}
                            className={'bux_vertical_align_sort_icon'}
                          />
                          <span className={`${(sortedCol && sortedCol[index] !== undefined) ? 'bux_underline' : ''} bux_header_label_sorted`}>
                            {header}
                          </span>
                        </>
                        : <span className={'bux_header_label'}>{header}</span>
                      }
                    </div>
                  </div>
                </th>
              )
            })
          }
          {this.renderActionButtonsPlaceholder()}
        </tr>
      </thead>
    )
  }
}