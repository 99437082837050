import React, { createRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TabProps } from './Tab';

import './Tabs.scss';
import { TabLabel } from './TabLabel';
import { translate } from 'language/Language'
import { Button } from 'BetaUX2Web-Components/src';
import { ConfirmationDialog } from 'components/dialogs/confirmation_dialog/ConfirmationDialog';
import { CSS_CLASS_TAB_CONTENT_CONTAINER, ID_TAB_CONTENT_WRAPPER } from '../constants/attributes';

const SCROLL_BY_VALUE = 80;


interface TabsProps {
  id: string;
  activeTabIndex: number |null;
  children: JSX.Element | JSX.Element[];
  onClose: (indexes: number[]) => void;
  enableConfirmationDialog?: boolean;
}
// TODO: remove after CL upgrade
/* eslint-disable @typescript-eslint/no-explicit-any, react-hooks/exhaustive-deps */
export const Tabs = (props: TabsProps): JSX.Element => {
  const { id, children, onClose } = props;

  const [focusable, setFocusable] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentFocusedIndex, setCurrentFocusedIndex] = useState(0);
  const [scrollButtonsDisabled, setScrollButtonsDisabled] = useState(true);
  const [tabsRefs, setTabsRefs] = useState<RefObject<HTMLLIElement>[]>([]);

  const [confirmationDialogShow, setConfirmationDialogShow] = useState(false);

  const closeAllTabsRef = useRef<HTMLLIElement>(null);
  const tabLabelsContainerRef = useRef<HTMLUListElement>(null);
  const scrollLeftButtonRef = useRef<HTMLButtonElement>(null);
  const scrollRightButtonRef = useRef<HTMLButtonElement>(null);

  const tabsLabels: JSX.Element[] = useMemo((): JSX.Element[] => {
    const newTabsRefs: RefObject<HTMLLIElement>[] = [];

    const childrenCount = React.Children.count(children);

    const result: JSX.Element[] = React.Children.map(children, (element, index) => {
      const { title, translateTitle, date, disableClose } = element.props as TabProps;

      const selected = selectedIndex === index;

      const ref = createRef<HTMLLIElement>();
      newTabsRefs.push(ref);

      return (
        <TabLabel
          id={`${id}_${index}_id`}
          key={`${id}_tab-label_${index}_key`}
          ref={ref}
          title={translateTitle ? translate(title) : title}
          date={date}
          selected={selected}
          focusable={focusable}
          onClick={() => {
            setSelectedIndex(index);
            setCurrentFocusedIndex(index);
          }}
          onClose={() => {
            if (selected) {
              // Case: Current focused tab will be closed (= selected)
              const newIndex = (index === childrenCount - 1) ? index - 1 : index;
              setSelectedIndex(newIndex);
              setCurrentFocusedIndex(newIndex);
            } else {
              // Case: Tab left from current focused tab will be closed (index < currentFocusedIndex) or tab right from current focused tab will be close (index >= currentFocusedIndex)
              const newIndex = index < currentFocusedIndex ? currentFocusedIndex - 1 : currentFocusedIndex;
              setSelectedIndex(newIndex);
              setCurrentFocusedIndex(newIndex);
            }
            onClose([index]);
          }}
          onBlur={() => {
            setCurrentFocusedIndex(-1);
            setFocusable(true);
          }}
          onFocus={() => {
            setCurrentFocusedIndex(currentFocusedIndex === -1 ? selectedIndex : index);
          }}
          disableClose={disableClose}
        />
      );
    });
    if (result.length !== 0)
      newTabsRefs.push(closeAllTabsRef);
    if (tabsRefs.length !== 0 && newTabsRefs.length > tabsRefs.length) {
      tabsRefs.forEach((tabRef, index) => {
        if (newTabsRefs[index] !== tabRef) {
          setCurrentFocusedIndex(index);
          setSelectedIndex(index);
        }
      });
    }
    setTabsRefs(newTabsRefs);
    return result;
  }, [id, children, focusable, selectedIndex, setSelectedIndex, currentFocusedIndex, setCurrentFocusedIndex, setTabsRefs, setFocusable, onClose, translate]);

  const focusPrevElement = useCallback((): void => {
    const newIndex = currentFocusedIndex === 0 ? 0 : currentFocusedIndex - 1;
    setCurrentFocusedIndex(newIndex);
  }, [setCurrentFocusedIndex, currentFocusedIndex]);

  const focusNextElement = useCallback((): void => {
    const lastIndex = tabsRefs.length - 1;
    const newIndex = currentFocusedIndex === lastIndex ? lastIndex : currentFocusedIndex + 1;
    setCurrentFocusedIndex(newIndex);
  }, [setCurrentFocusedIndex, currentFocusedIndex, tabsRefs]);

  const focusFirstElement = useCallback((): void => {
    setCurrentFocusedIndex(0);
  }, [setCurrentFocusedIndex]);

  const focusLastElement = useCallback((): void => {
    setCurrentFocusedIndex(tabsRefs.length - 1);
  }, [tabsRefs.length, setCurrentFocusedIndex]);

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLUListElement>): void => {
    let preventDefault = true;
    switch (event.key) {
      case 'ArrowLeft':
        focusPrevElement();
        break;
      case 'ArrowRight':
        focusNextElement();
        break;
      case 'Home':
        focusFirstElement();
        break;
      case 'End':
        focusLastElement();
        break;
      case 'Tab':
        preventDefault = false;
        setCurrentFocusedIndex(-1);
        setFocusable(false);
        break;
      default:
        preventDefault = false;
        break;
    }
    if (preventDefault) event.preventDefault();
  }, [focusPrevElement, focusNextElement, focusFirstElement, focusLastElement, setFocusable]);

  const onRightButtonClick = useCallback(() => {
    const currentScrollValue = tabLabelsContainerRef.current?.scrollLeft || 0;
    tabLabelsContainerRef.current?.scroll({ left: currentScrollValue + SCROLL_BY_VALUE, behavior: 'smooth' });
  }, [tabLabelsContainerRef]);

  const onLeftButtonClick = useCallback(() => {
    const currentScrollValue = tabLabelsContainerRef.current?.scrollLeft || 0;
    tabLabelsContainerRef.current?.scroll({ left: currentScrollValue - SCROLL_BY_VALUE, behavior: 'smooth' });
  }, [tabLabelsContainerRef]);

  const toggleScrollButtonsDisable = useCallback(() => {
    if (!tabLabelsContainerRef.current) return;
    const { scrollWidth, clientWidth } = tabLabelsContainerRef.current;
    setScrollButtonsDisabled(scrollWidth <= clientWidth);
  }, [tabLabelsContainerRef, setScrollButtonsDisabled]);

  const onCloseAllTab = useCallback((): void => {
    const indexesToClose: number[] = [];
    React.Children.forEach(children, async (tab, index) => {
      const { disableClose } = tab.props as TabProps;
      if (!disableClose) indexesToClose.push(index);
    });
    onClose(indexesToClose);
    setSelectedIndex(0);
    setCurrentFocusedIndex(0);
  }, [children, onClose]);

  const onCloseAllTabBlur = useCallback(() => {
    setCurrentFocusedIndex(-1);
    setFocusable(true);
  }, [setFocusable]);

  const onCloseAllTabFocus = useCallback(() => setCurrentFocusedIndex(currentFocusedIndex === -1 ? selectedIndex : tabsRefs.length - 1),
    [selectedIndex, currentFocusedIndex, setCurrentFocusedIndex, tabsRefs]);

  useEffect(() => {
    const ref = tabsRefs[currentFocusedIndex];
    if (!ref?.current) return;
    ref.current.focus({ preventScroll: false });
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }, [currentFocusedIndex, tabsRefs]);

  useEffect(() => {
    toggleScrollButtonsDisable();
  }, [toggleScrollButtonsDisable, tabsLabels]);

  useEffect(() => {
    window.addEventListener('resize', toggleScrollButtonsDisable);
    return () => {
      window.removeEventListener('resize', toggleScrollButtonsDisable);
    };
  }, [toggleScrollButtonsDisable]);

  useEffect(() => {
    if (props.activeTabIndex === null) return;
    if (props.activeTabIndex === selectedIndex) return;

    setSelectedIndex(props.activeTabIndex);
    setCurrentFocusedIndex(props.activeTabIndex);
  }, [props.activeTabIndex])

  const onPositiveAction = () => {
    onCloseAllTab();
    setConfirmationDialogShow(false);
  };

  const onCloseAllTabsClicked = () => {
    if (props.enableConfirmationDialog) {
      setConfirmationDialogShow(true);
    } else {
      onCloseAllTab();
    }
  };

  return (
    <>
      {
        props.enableConfirmationDialog && confirmationDialogShow &&
        <ConfirmationDialog
          id={`${id}_confirmationDialog`}
          title={translate('tabs.confirmation_dialog.title')}
          message={translate('tabs.confirmation_dialog.message')}
          onPositiveAction={onPositiveAction}
          onNegativeAction={() => setConfirmationDialogShow(false)} />
      }
      <div id={id} className={'tab-container'}>
        <div id={`${id}_tab-labels-container`} className={'tab-labels-container'}>
          <Button
            id={'tab-labels-left-arrow-button'}
            ref={scrollLeftButtonRef}
            icon={'chevron_left'}
            className={'arrow-button arrow-button-left'}
            onClick={onLeftButtonClick}
            disabled={scrollButtonsDisabled}
          />
          <ul
            id={`${id}_tab-labels-container_ul`}
            ref={tabLabelsContainerRef}
            tabIndex={-1}
            className={'tab-labels'}
            onKeyDown={onKeyDown}
          >
            {tabsLabels}
            {
              tabsLabels.length !== 0 && <TabLabel
                ref={closeAllTabsRef}
                id={'close_all_tabs'}
                title={translate('tabs.title.close_all')}
                focusable={focusable}
                onClick={onCloseAllTabsClicked}
                onBlur={onCloseAllTabBlur}
                onFocus={onCloseAllTabFocus}
                onClose={() => null} // Note from BADM-2060 - This should not happen
                disableClose
              />
            }
          </ul>
          <Button
            id={'tab-labels-right-arrow-button'}
            ref={scrollRightButtonRef}
            icon={'chevron_right'}
            className={'arrow-button arrow-button-right'}
            onClick={onRightButtonClick}
            disabled={scrollButtonsDisabled}
          />
        </div>
        <div id={ID_TAB_CONTENT_WRAPPER} key={ID_TAB_CONTENT_WRAPPER} className={'tab-container'}>
          {
            React.Children.map(children, (element, index) => {
              const { content } = element.props as TabProps;
              return (
                <div
                  id={`${index}_tab-content-container`}
                  className={CSS_CLASS_TAB_CONTENT_CONTAINER}
                  style={{
                    display: selectedIndex === index ? 'inherit' : 'none',
                  }}
                >
                  {content}
                </div>
              );
            })
          }
        </div>
      </div>
    </>
  );
};
/* eslint-enable @typescript-eslint/no-explicit-any, react-hooks/exhaustive-deps */