import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Card from 'BetaUX2Web-Components/src/components/card/Card'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as ExternalCmdActions from 'redux/actions/ExternalCommandDefinitionActions'

class DefinitionExternalCommand extends Component {

  defaultState = {
    externalcmdid: {
      value: '',
      errorkey: ''
    },
    command: {
      value: '',
      errorkey: ''
    },
    description: {
      value: '',
      errorkey: ''
    },
    args: {
      value: '',
      errorkey: ''
    }
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
 * @description Initializes the import fields with the values saved in preferences.
 */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props

    if (preferences) {
      const id = preferences[Preferences.DEFINITION_EXT_COMMANDS_ID] || ''
      const command = preferences[Preferences.DEFINITION_EXT_COMMANDS_COMMAND] || ''
      const description = preferences[Preferences.DEFINITION_EXT_COMMANDS_DESCRIPTION] || ''
      const args = preferences[Preferences.DEFINITION_EXT_COMMANDS_ARGUMENTS] || ''

      this.setState({
        externalcmdid: {
          value: id,
          errorkey: ''
        },
        command: {
          value: command,
          errorkey: ''
        },
        description: {
          value: description,
          errorkey: ''
        },
        args: {
          value: args,
          errorkey: ''
        }
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInputChanged = (id, value, errorkey) => {
    this.setState({ [id]: { value, errorkey } })
  }

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = (event) => {
    event.preventDefault()

    const { externalcmdid, command, description, args } = this.state

    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_EXT_COMMANDS_ID]: externalcmdid.value,
      [Preferences.DEFINITION_EXT_COMMANDS_COMMAND]: command.value,
      [Preferences.DEFINITION_EXT_COMMANDS_DESCRIPTION]: description.value,
      [Preferences.DEFINITION_EXT_COMMANDS_ARGUMENTS]: args.value
    }

    this.props.changePrefs(prefsToChange)
    this.props.searchExternalCommand(
      externalcmdid.value,
      description.value,
      command.value,
      args.value
    )
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleOnReset = () => {
    this.setState(this.defaultState)
  }


  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id, lang } = this.props
    const { externalcmdid, command, description, args } = this.state

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        <Card
          title={translate('general.general', lang)}>
          {/* id */}
          <Row>
            <Column
              colMD={12}
              offsetMD={0}>
              {/* externalcommand id */}
              <Input
                id={`${id}_externalcmdid`}
                onInputChanged={(val, err) => { this.handleInputChanged('externalcmdid', val, err) }}
                value={externalcmdid.value}
                title={translate('external_command.id', lang)}
                error={externalcmdid.errorkey && translate(externalcmdid.errorkey, lang)}
                maxLength={16}
              />
            </Column>
          </Row>
          <Row>
            <Column
              colMD={12}
              offsetMD={0}>
              {/* description */}
              <Input
                id={`${id}_description`}
                onInputChanged={(val, err) => { this.handleInputChanged('description', val, err) }}
                value={description.value}
                title={translate('external_command.description', lang)}
                error={description.errorkey && translate(description.errorkey, lang)}
                maxLength={64}
              />
            </Column>
          </Row>
          <Row>
            <Column
              colMD={12}
              offsetMD={0}>
              {/* command */}
              <Input
                id={`${id}_command`}
                onInputChanged={(val, err) => { this.handleInputChanged('command', val, err) }}
                value={command.value}
                title={translate('external_command.command', lang)}
                error={command.errorkey && translate(command.errorkey, lang)}
                maxLength={256}
              />
            </Column>
          </Row>
          <Row>
            <Column
              colMD={12}
              offsetMD={0}>
              <Input
                id={`${id}_args`}
                onInputChanged={(val, err) => { this.handleInputChanged('args', val, err) }}
                value={args.value}
                title={translate('external_command.arguments', lang)}
                maxLength={256}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleOnReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={() => { }}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchExternalCommand: (externalcmdid, description, command, args) => {
      ExternalCmdActions.getExternalCommands(
        externalcmdid, description, command, args
      )(dispatch)
    },
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) },
  }
}

DefinitionExternalCommand.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionExternalCommand)