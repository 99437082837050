import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { DrawerContext } from '../contexts/DrawerContext';
import { useTabContentContext } from '../contexts/TabContentContext';

import * as TYPES_DRAWER from '../types/Drawer.types';
import * as TYPES_DOC_VIEWER from '../types/DocumentViewer.types';

export const DocumentDrawerProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { id } = useTabContentContext();

  const data: TYPES_DOC_VIEWER.TTabDataData = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id).data);
  const header: TYPES_DOC_VIEWER.TTabDataHeader = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id).header);
  const systemName: string = useSelector((state: RootStateOrAny) => state.auth.serverdata.systemname);

  const documentInfo: TYPES_DRAWER.IDrawerDocumentInformation = {
    system: systemName,
    source: data[header.indexOf('LOGSRC')],
    title: data[header.indexOf('LTITLE')],
    date: data[header.indexOf('B93DATE')] || data[header.indexOf('SRCSUBD')] || data[header.indexOf('STRTDATE')],
    time: data[header.indexOf('B93TIME')] || data[header.indexOf('SRCSUBT')] || data[header.indexOf('STRTTIME')],
    host: data[header.indexOf('FORM')],
    application: data[header.indexOf('EXT')],
    sublog: data[header.indexOf('WREPORT')],
    form: data[header.indexOf('FORM')],
    extension: data[header.indexOf('EXT')],
    report: data[header.indexOf('WREPORT')],
    jobname: data[header.indexOf('JOBNAME')] || data[header.indexOf('SRCJOBN')],
    jobid: data[header.indexOf('JOBID')] || data[header.indexOf('LTOKEN')]
  }

  return (
    <DrawerContext.Provider value={{ documentInfo }}>
      {children}
    </DrawerContext.Provider>
  )
};