export enum JobType {
    WORKFLOW = 'workflow',
    TIMER = 'timer',
    WINDOWS = 'windows',
    LINUX_UNIX = 'linuxWindows',
    ZOS = 'zOS',
    AGENT_FILE_MONITOR = 'agentFileMonitor',
    MANUAL = 'manual',
    EMAIL = 'email',
    FILE_TRANSFER = 'fileTransfer',
    SQL = 'sql',
    REMOTE_FILE_MONITOR = 'remoteFileMonitor',
    TASK_MONITOR = 'taskMonitor',
    STORED_PROCEDURE = 'storedProcedure',
    UNIVERSAL_COMMAND = 'universalCommand',
    SYSTEM_MONITOR = 'systemMonitor',
    APPLICATION_CONTROL = 'applicationControl',
    SAP = 'sap',
    VARIABLE_MONITOR = 'variableMonitor',
    WEB_SERVICE = 'webService',
    EMAIL_MONITOR = 'emailMonitor',
    PEOPLESOFT = 'peopleSoft',
    RECURRING = 'recurring',
    UNIVERSAL = 'universal'
}

export enum JobStatus {
    FAILED = 'failed',
    FINISHED = 'finished',
    SUCCESS = 'success'
}