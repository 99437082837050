import { translate } from 'language/Language'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'

import {
  Button, Card, Column, DataTable, Icon, MetaDataGrid, Modal as ModalComponent,
  Row, TableButton,
  TableButtonGroup,
  TableButtonGroupGroup,
  TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import DocumentPrint from 'components/dialogs/document_print_dialog/DocumentPrint'
import { DownloadDialog } from 'components/dialogs/download_dialog/DownloadDialog'
import MarkReloadDocumentDialog from 'components/dialogs/mark_reload_document_dialog/MarkReloadDocumentDialog'
import RerunDialog, * as RerunDialogTypes from 'components/dialogs/rerun_dialog/RerunDialog'
import ZosJobLogInformation from 'components/dialogs/zos_job_log_information/ZosJobLogInformation'

const { Modal, Main, Header, Footer } = ModalComponent

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as DocViewerActions from 'redux/actions/DocViewerActions'
import * as ZosActions from 'redux/actions/SearchZOSActions'
import * as Preferences from 'redux/general/Preferences'
import { getDate } from 'utils/DateUtils'
import * as UrlUtils from 'utils/UrlUtils'

class ZosJobInformationDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  state = {
    showRerunDialog: false,
    showPrintDialog: false,
    showJobLogInformationDialog: false,
    rerunType: undefined,
    showDownloadDialog: false,
    showReloadDialog: false,
    showDeletefromArchiveDialog: false,
    selectedRow: undefined,
    selectedMimeType: undefined,
    selectedDownloadType: undefined
  }

  /**
   * @description Renders the header which includes the non editable information
   */
  renderHeader = () => {
    const { id, zosJobDetails, datemask } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('general.jobname'), value: zosJobDetails.JOBNAME },
          { label: translate('job.jobid'), value: zosJobDetails.JOBID },
          { label: translate('general.jobgroup'), value: zosJobDetails.JOBGROUP },
          { label: translate('job.class'), value: zosJobDetails.SRCCLAS },
          { label: translate('job.netid'), value: zosJobDetails.JNETID },
          { label: translate('job.submit_user'), value: zosJobDetails.SRCSUBU },
          { label: translate('general.encoding'), value: zosJobDetails.TEXTENCO },
          { label: translate('documentinformation.header_doctype'), value: zosJobDetails.SRCJOBI },
          { label: translate('general.submit_date'), value: `${getDate(datemask, zosJobDetails.SRCSUBD)} ${zosJobDetails.SRCSUBT}` },
          { label: translate('general.start_date'), value: `${getDate(datemask, zosJobDetails.STRTDATE)} ${zosJobDetails.STRTTIME}` },
          { label: translate('general.end_date'), value: `${getDate(datemask, zosJobDetails.ENDDATE)} ${zosJobDetails.ENDTIME}` },
          { label: translate('general.return_code'), value: zosJobDetails.JOBRC },
          { label: translate('general.pages'), value: zosJobDetails.SRCPAGES?.toString() },
          { label: translate('general.bytes'), value: zosJobDetails.SRCBYTES?.toString() },
        ]}
        columns={6}
      />
    )
  }

  /**
 * @description Handles the rerun action.
 * @param {Number} rowIndex The current row.
 * @param {String} type The type of rerun. Use constants of RerunDialog!
 */
  handleRerun = (rowIndex, type) => {
    const { zosJobLogs } = this.props
    const docid = zosJobLogs.data[rowIndex][this.headerData('DOCID')]
    const callback = () => { this.setState({ showRerunDialog: true, rerunType: type }) }
    this.props.getLog(docid, callback)
  }

  /**
   * @description Opens the recipient log assignment menu and prefills drawer values with values from log entry.
   * @param {*} index The rowindex
   */
  showRecipients = index => {
    const { zosJobLogs } = this.props
    const header = zosJobLogs.header
    const data = zosJobLogs.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
      { rest: 'REPORT', ui: 'report' },
      { rest: 'WREPORT', ui: 'dynamicReport' },
    ]

    // fake the state like its used in assignment recipient log to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
      report: data[header.indexOf('WREPORT')] || '',
      dynamicReport: ''
      //  Not possible to get the value at the moment.
      // dynamicReport: data[header.indexOf('DREPORT')],
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/recipient_log${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  /**
 * @description Handles the download functionality of logs in binary format.
 * @param {Number} rowIndex The current row.
 */
  handleLogDownloadBinary = rowIndex => {
    const { zosJobLogs, downloadLog } = this.props
    const docid = zosJobLogs.data[rowIndex][this.headerData('DOCID')]

    const form = zosJobLogs.data[rowIndex][this.headerData('FORM')]
    const ext = zosJobLogs.data[rowIndex][this.headerData('EXT')]
    const report = zosJobLogs.data[rowIndex][this.headerData('REPORT')]
    let doctype = zosJobLogs.data[rowIndex][this.headerData('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => entry !== undefined).join('.')
    downloadLog(docid, filename)
  }

  /**
   * @description Handles the download functionality. Opens an additional modal, if download settings  are available for a specific mimetype.
   * @param {Number} rowIndex The current row.
   * @param {String} mimetype The mimetype of the specific doucment.
   */
  handleDocumentDownloadText = (rowIndex, mimetype) => {
    const { showDownloadDialog } = this.state
    if (!showDownloadDialog && ['*.afp', '*.pcl', '*.pjl'].includes(mimetype)) {
      return this.openDownloadTextDialog(rowIndex)
    }
    return this.executeDownloadTextDirectly(rowIndex, mimetype)
  }

  /**
   * @description Executes the text download directly without an additional modal.
   * @param {Number} rowIndex The current row.
   * @param {String} mimetype The mimetype of the specific doucment.
   */
  executeDownloadTextDirectly = (rowIndex, mimetype) => {
    const { zosJobLogs, downloadLogText } = this.props
    const docid = zosJobLogs.data[rowIndex][this.headerData('DOCID')]
    const form = zosJobLogs.data[rowIndex][this.headerData('FORM')]
    const ext = zosJobLogs.data[rowIndex][this.headerData('EXT')]
    const report = zosJobLogs.data[rowIndex][this.headerData('REPORT')]
    let doctype = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[mimetype]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadLogText(docid, filename, mimetypeTranslation)
  }

  /**
   * @description Handles the download functionality of zos job logs in text format from download dialog.
   * @param {String} showNOP YES/NO value for show NOP
   * @param {String} showTLE YES/NO value for show TLE
   * @param {String} showPJL YES/NO value for show PJL
   */
  executeLogTextFromDialog = (showNOP, showTLE, showPJL) => {
    const { zosJobLogs, downloadLogText } = this.props
    const { selectedRow, selectedMimeType } = this.state
    const docid = zosJobLogs.data[selectedRow][this.headerData('DOCID')]

    const form = zosJobLogs.data[selectedRow][this.headerData('FORM')]
    const ext = zosJobLogs.data[selectedRow][this.headerData('EXT')]
    const report = zosJobLogs.data[selectedRow][this.headerData('REPORT')]
    let doctype = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[selectedMimeType]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadLogText(docid, filename, mimetypeTranslation, showNOP, showTLE, showPJL, () => this.setState({
      showDownloadDialog: false,
      selectedRow: undefined,
      selectedMimeType: undefined,
      selectedDownloadType: undefined
    }))
  }

  openDownloadTextDialog = rowIndex => {
    const { zosJobLogs } = this.props
    this.setState({ showDownloadDialog: true, selectedRow: rowIndex, selectedMimeType: zosJobLogs.data[rowIndex][this.headerData('SRCJOBI')], selectedDownloadType: 'TEXT' })
  }

  /**
   * @description Handles the download functionality of zos job logs in hex format.
   * @param {Number} rowIndex The current row.
   */
  handleLogDownloadHex = (displayMode, showPageHeader, showResources) => {
    const { selectedRow } = this.state
    const { zosJobLogs, downloadLogHex } = this.props
    const docid = zosJobLogs.data[selectedRow][this.headerData('DOCID')]

    const form = zosJobLogs.data[selectedRow][this.headerData('FORM')]
    const ext = zosJobLogs.data[selectedRow][this.headerData('EXT')]
    const report = zosJobLogs.data[selectedRow][this.headerData('REPORT')]
    let doctype = 'hex'

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadLogHex(docid, filename, displayMode, showPageHeader, showResources, () => this.setState({
      showDownloadDialog: false,
      selectedRow: undefined,
      selectedMimeType: undefined,
      selectedDownloadType: undefined
    }))
  }

  handleShowDocumentDownloadHexDialog = rowIndex => {
    const { zosJobLogs } = this.props
    this.setState({ showDownloadDialog: true, selectedRow: rowIndex, selectedMimeType: zosJobLogs.data[rowIndex][this.headerData('SRCJOBI')], selectedDownloadType: 'HEX' })
  }

  /**
 * @description Handles the open print document dialog action.
 * @param {Number} rowIndex The current row.
 */
  handleShowLogPrint = rowIndex => {
    const { zosJobLogs, getLog, getPrintInfo } = this.props
    let docid = zosJobLogs.data[rowIndex][this.headerData('DOCID')]
    getLog(docid, () => {
      getPrintInfo(docid, () => this.setState({ showPrintDialog: true }))
    })
  }

  /**
 * @description un-/marks the archive or delete status, unmarks the reload status
 * @param {Number} index the index of the current row
 * @param {String} markedParam the header which should be used to update the ARCHIVE, DELETE or RELOAD
 * possible: 'LGRFFARC', 'LGRFFDEL', 'LGRFFREL'
 * LGRFFREL is only used to unmark reload
 */
  updateMark = (index, markedParam) => {
    const { zosJobs, zosJobLogs, getLog, markArchive, markDelete, markReload } = this.props
    const docid = zosJobLogs.data[index][this.headerData('DOCID')]

    const callback = () => {
      const { zosJobLog } = this.props
      const archiveStatus = zosJobLog[markedParam] === false || zosJobLog[markedParam] === '' ? 'PENDING' : ''
      let logDefinition = {
        // [param]: archiveStatus,
        DOCID: docid,
        FORM: zosJobLog.FORM,
        EXT: zosJobLog.EXT,
        REPORT: zosJobLog.REPORT
      }
      const job = zosJobs.data.find(d => zosJobLogs.data[index][this.headerData('SRCJOBN')] === d[zosJobs.header.indexOf('JOBNAME')])
      const searchObj = {
        LOGSOURCE: 'ZOS',
        SRCSUBD: job[zosJobs.header.indexOf('SRCSUBD')],
        SRCSUBT: job[zosJobs.header.indexOf('SRCSUBT')],
        JOBNAME: job[zosJobs.header.indexOf('JOBNAME')],
        JOBID: job[zosJobs.header.indexOf('JOBID')],
      }
      if (markedParam === 'LGRFFARC') {
        logDefinition = {
          ...logDefinition,
          ARCHIVE: archiveStatus
        }
        markArchive(logDefinition, searchObj)
      }
      else if (markedParam === 'LGRFFDEL') {
        logDefinition = {
          ...logDefinition,
          DELETE: archiveStatus
        }
        markDelete(logDefinition, searchObj)
      }
      else if (markedParam === 'LGRFFREL') {
        logDefinition = {
          ...logDefinition,
          RELOAD: archiveStatus
        }
        markReload(logDefinition, searchObj)
      }
    }

    getLog(docid, callback)
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  handleDisplayLog = rowIndex => {
    const { zosJobLogs, displayLog, bwesystemname, bwebaseurl } = this.props
    const docid = zosJobLogs.data[rowIndex][this.headerData('DOCID')]
    const ukey = zosJobLogs.data[rowIndex][this.headerData('UKEY')]

    displayLog(docid, bwebaseurl, bwesystemname, ukey)
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewer = (rowIndex) => {
    const { zosJobLogs, addTabToDocViewer } = this.props
    const docId = zosJobLogs.data[rowIndex][this.headerData('DOCID')]
    const pageCount = zosJobLogs.data[rowIndex][this.headerData('SRCPAGES')]

    addTabToDocViewer({
      id: docId,
      type: 'zos',
      header: zosJobLogs.header,
      data: zosJobLogs.data[rowIndex],
      pageCount: parseInt(pageCount)
    })
  }

  /**
   * @description Handles the reload action.
   * @param {Number} index The row index of document.
   */
  handleReload = index => {
    const { zosJobLogs } = this.props
    const docid = zosJobLogs.data[index][this.headerData('DOCID')]
    const callback = () => this.setState({ showReloadDialog: true, clickedRow: index })
    this.props.getLog(docid, callback)
  }

  reloadCallback = () => {
    const { clickedRow } = this.state
    const { zosJobs, zosJobLogs, getZosJobLogs } = this.props
    const job = zosJobs.data.find(d => zosJobLogs.data[clickedRow][this.headerData('SRCJOBN')] === d[zosJobs.header.indexOf('JOBNAME')])
    const searchObj = {
      LOGSOURCE: 'ZOS',
      SRCSUBD: job[zosJobs.header.indexOf('SRCSUBD')],
      SRCSUBT: job[zosJobs.header.indexOf('SRCSUBT')],
      JOBNAME: job[zosJobs.header.indexOf('JOBNAME')],
      JOBID: job[zosJobs.header.indexOf('JOBID')],
    }
    getZosJobLogs(searchObj, () => this.setState({ showReloadDialog: false, clickedRow: undefined }))
  }

  /**
   * @description Handles the delete from archive action.
   * @param {Number} index The row index of document.
   */
  handleDeleteFromArchive = index => {
    const { zosJobLogs } = this.props
    const docid = zosJobLogs.data[index][this.headerData('DOCID')]
    const callback = () => this.setState({ showDeletefromArchiveDialog: true })

    this.props.getLog(docid, callback)
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id, zosJobLogs, userprofile } = this.props
    const modifyReloadAllowed = userprofile === undefined || userprofile?.VCIREL
    const modifyArchiveAllowed = userprofile === undefined || userprofile?.VCIARC
    const modifyDeleteAllowed = userprofile === undefined || userprofile?.VCIDEL
    const mimetype = rowIndex !== undefined ? zosJobLogs?.data[rowIndex][this.headerData('SRCJOBI')] : undefined
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => this.handleLogInformation(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${rowIndex}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => this.openDocumentViewer(rowIndex)}
      />,
      <TableButtonGroup
        id={`${id}_tableButtonMore_${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleLogInformation(rowIndex)}
          id={`${id}_info_btn`}
          icon={<Icon name='info' className='actionIcon' />}
          text={translate('general.information')}
          title={translate('general.information')}
        />
        <TableButtonGroupItem
          id={`${id}_display_btn`}
          icon={<Icon name='document' className='actionIcon' />}
          text={translate('general.display_in_beta_view')}
          title={translate('general.display_in_beta_view')}
          onClick={() => this.handleDisplayLog(rowIndex)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleShowLogPrint(rowIndex)}
          id={`${id}_print_btn`}
          icon={<Icon name='print' className='actionIcon' />}
          text={translate('documentprint.print')}
          title={translate('documentprint.print')}
        />
        <TableButtonGroupGroup id={`${id}_download_group_btn`} text={translate('general.download')}>
          <TableButtonGroupItem
            onClick={() => this.handleLogDownloadBinary(rowIndex)}
            id={`${id}_download_btn`}
            icon={<Icon name={'download'} className={'actionIcon'} />}
            text={translate('general.download')}
            title={translate('general.download')}
          />
          {
            ['*.ps', '*.pdf', '*.afp', '*.pcl', '*.pjl'].includes(mimetype) &&
            <TableButtonGroupItem
              onClick={() => this.handleDocumentDownloadText(rowIndex, mimetype)}
              id={`${id}_download_text_btn`}
              icon={<Icon name={'download'} className={'actionIcon'} />}
              text={translate('general.download_as_text')}
              title={translate('general.download_as_text')}
            />
          }
          <TableButtonGroupItem
            onClick={() => this.handleShowDocumentDownloadHexDialog(rowIndex)}
            id={`${id}_download_hex_btn`}
            icon={<Icon name={'download'} className={'actionIcon'} />}
            text={translate('standard_selection.download_as_hex')}
            title={translate('standard_selection.download_as_hex')}
          />
        </TableButtonGroupGroup>
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => this.handleRerun(rowIndex, RerunDialogTypes.ITEM_FORCE_AUTO_PRINT)}
          id={`${id}_rerun_autoprint_btn`}
          icon={<Icon name='rerun' className='actionIcon' />}
          text={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
          title={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => this.showRecipients(rowIndex)}
          id={`${id}_show_recipients_btn`}
          icon={<Icon name='group' className='actionIcon' />}
          text={translate('general.display_recipients')}
          title={translate('general.display_recipients')}
        />
        {(modifyArchiveAllowed || modifyDeleteAllowed || modifyReloadAllowed) &&
          <TableButtonGroupSeparator id={id} />
        }
        { /* check if zosJobLogs.data[rowIndex] is not undefined is necessary, because redux updates the data faster than datatable, so datatable useses this function with old index and we get an error, when less rows are in the new data (no proof, just a theory)*/}
        {modifyDeleteAllowed && (zosJobLogs.data[rowIndex] && zosJobLogs.data[rowIndex][this.headerData('LGRONLNE')] === 'YES' &&
          (
            zosJobLogs.data[rowIndex] && zosJobLogs.data[rowIndex][this.headerData('LGRFFDEL')] === ''
              ? <TableButtonGroupItem
                onClick={() => this.updateMark(rowIndex, 'LGRFFDEL')}
                id={`${id}_markDeleteBtn`}
                icon={<Icon name='delete' className='actionIcon' />}
                text={translate('general.mark_delete')}
                title={translate('general.mark_delete')}
              />
              : <TableButtonGroupItem
                onClick={() => { this.updateMark(rowIndex, 'LGRFFDEL') }}
                id={`${id}_unmarkDeleteBtn`}
                icon={<Icon name='close' className='actionIcon' />}
                text={translate('general.unmark_delete')}
                title={translate('general.unmark_delete')}
              />))
        }
        {modifyArchiveAllowed && (zosJobLogs.data[rowIndex] && (
          zosJobLogs.data[rowIndex][this.headerData('LGRONLNE')] === 'YES' && (
            zosJobLogs.data[rowIndex][this.headerData('LGRFFARC')] === ''
              ? <TableButtonGroupItem
                onClick={() => this.updateMark(rowIndex, 'LGRFFARC')}
                id={`${id}_markArchiveBtn`}
                icon={<Icon name='archive' className='actionIcon' />}
                text={translate('general.mark_archive')}
                title={translate('general.mark_archive')}
              />
              : <TableButtonGroupItem
                onClick={() => this.updateMark(rowIndex, 'LGRFFARC')}
                id={`${id}_unmarkArchiveBtn`}
                icon={<Icon name='unarchive' className='actionIcon' />}
                text={translate('general.unmark_archive')}
                title={translate('general.unmark_archive')}
              />)))}
        {modifyReloadAllowed && (zosJobLogs.data[rowIndex] && (
          zosJobLogs.data[rowIndex][this.headerData('LGRONLNE')] === 'NO' &&
          zosJobLogs.data[rowIndex][this.headerData('LGRARCH')] === 'YES' && (
            zosJobLogs.data[rowIndex][this.headerData('LGRFFREL')] === ''
              ? <TableButtonGroupItem
                onClick={() => this.handleReload(rowIndex)}
                id={`${id}_markReloadBtn`}
                icon={<Icon name='reload' className='actionIcon' />}
                text={translate('general.mark_reload')}
                title={translate('general.mark_reload')}
              />
              : <TableButtonGroupItem
                onClick={() => this.updateMark(rowIndex, 'LGRFFREL')}
                id={`${id}_unmarkReloadBtn`}
                icon={<Icon name='cancel_reload' className='actionIcon' />}
                text={translate('general.unmark_reload')}
                title={translate('general.unmark_reload')}
              />)))}
        {zosJobLogs.data[rowIndex] && (
          zosJobLogs.data[rowIndex][this.headerData('LGRARCH')] === 'YES' &&
          <TableButtonGroupItem
            onClick={() => this.handleDeleteFromArchive(rowIndex)}
            id={`${id}_delteFromArchiveBtn`}
            icon={<Icon name='close' className='actionIcon' />}
            text={translate('general.delete_from_archive')}
            title={translate('general.delete_from_archive')}
          />)}
      </TableButtonGroup>
    ]
  }

  /**
   * @description gets the index of the header in redux state zosJobLogs.header
   * @param {String} header header name of the header in redux state zosJobLogs.header
   */
  headerData = header => this.props.zosJobLogs.header.indexOf(header)

  handleLogInformation = rowIndex => {
    const { zosJobLogs, getJobLogInformation, zosJobDetails } = this.props
    const joblogObj = {
      LOGSOURCE: 'ZOS',
      SRCSUBD: zosJobDetails.SRCSUBD,
      SRCSUBT: zosJobDetails.SRCSUBT,
      JOBNAME: zosJobDetails.JOBNAME,
      JOBID: zosJobDetails.JOBID,
      DSID: zosJobLogs.data[rowIndex][zosJobLogs.header.indexOf('DSID')]
    }
    getJobLogInformation(joblogObj, () => this.setState({ showJobLogInformationDialog: true }))
  }

  /**
   * @description gets the corrected/formatted data from the table
   */
  getTableData = () => {
    const data = []
    this.props.zosJobLogs.data.forEach(el => {
      const ddName = el[this.headerData('SRCDDND')]
      const stepName = el[this.headerData('SRCSTPD')]
      const procStepName = el[this.headerData('SRCPRCD')]
      const dsID = el[this.headerData('DSID')]
      const pages = el[this.headerData('SRCPAGES')]
      const lines = el[this.headerData('SRCLNCT')]
      const form = el[this.headerData('FORM')]
      const extension = el[this.headerData('EXT')]
      const jobClass = el[this.headerData('SRCCLAS')]
      const markedDelete = el[this.headerData('LGRFFDEL')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      const markedArchive = el[this.headerData('LGRFFARC')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      const markedReload = el[this.headerData('LGRFFREL')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      const archived = el[this.headerData('LGRARCH')] === 'YES' ? translate('general.yes') : translate('general.no')
      const online = el[this.headerData('LGRONLNE')] === 'YES' ? translate('general.yes') : translate('general.no')
      data.push([ddName, stepName, procStepName, dsID, pages, lines, form, extension, jobClass, markedDelete, markedArchive, markedReload, archived, online])
    })
    return data
  }

  /**
   * @description Deletes the current document from archive.
   */
  deleteFromArchive = () => {
    const { zosJobs, zosJobLog, markArchive } = this.props
    const docid = zosJobLog['DOCID']

    const updateObj = {
      ARCHIVE: 'DELETE',
      DOCID: docid,
      FORM: zosJobLog.FORM,
      EXT: zosJobLog.EXT,
      REPORT: zosJobLog.REPORT
    }

    const job = zosJobs.data.find(d => zosJobLog.SRCJOBN === d[zosJobs.header.indexOf('JOBNAME')])
    const searchObj = {
      LOGSOURCE: 'ZOS',
      SRCSUBD: job[zosJobs.header.indexOf('SRCSUBD')],
      SRCSUBT: job[zosJobs.header.indexOf('SRCSUBT')],
      JOBNAME: job[zosJobs.header.indexOf('JOBNAME')],
      JOBID: job[zosJobs.header.indexOf('JOBID')],
    }

    const callback = () => this.setState({ showDeletefromArchiveDialog: false })
    markArchive(updateObj, searchObj, callback)
  }

  markReload = (updateObj, callback) => {
    const { zosJobs, zosJobLog, markReload } = this.props
    const job = zosJobs.data.find(d => zosJobLog.SRCJOBN === d[zosJobs.header.indexOf('JOBNAME')])
    const searchObj = {
      LOGSOURCE: 'ZOS',
      SRCSUBD: job[zosJobs.header.indexOf('SRCSUBD')],
      SRCSUBT: job[zosJobs.header.indexOf('SRCSUBT')],
      JOBNAME: job[zosJobs.header.indexOf('JOBNAME')],
      JOBID: job[zosJobs.header.indexOf('JOBID')],
    }
    markReload(updateObj, searchObj, callback)
  }

  render = () => {
    const { showRerunDialog, rerunType, showPrintDialog, showJobLogInformationDialog, showDownloadDialog, selectedMimeType, selectedDownloadType, showReloadDialog, showDeletefromArchiveDialog } = this.state
    const { id, onClose, lang, datemask, zosJobLogs, zosJobLog, zosJobLogPrintInfo, zosJobLogInformation } = this.props
    const data = zosJobLogs && zosJobLogs.data ? this.getTableData() : null
    return (
      <>
        {
          showRerunDialog &&
          <RerunDialog
            id={id}
            onClose={() => this.setState({ showRerunDialog: false })}
            type={rerunType}
            document={zosJobLog}
          />
        }
        {
          showPrintDialog &&
          <DocumentPrint
            id={id}
            onClose={() => this.setState({ showPrintDialog: false })}
            document={zosJobLog}
            printInfo={zosJobLogPrintInfo}
          />
        }
        {
          showJobLogInformationDialog &&
          <ZosJobLogInformation
            id={`${id}_loginfodialog`}
            zosJobLogInformation={zosJobLogInformation}
            onClose={() => this.setState({ showJobLogInformationDialog: false })}
          />
        }
        {
          showReloadDialog &&
          <MarkReloadDocumentDialog
            id={id}
            onClose={this.reloadCallback}
            document={zosJobLog}
            markReload={(obj, callback) => this.markReload(obj, callback)}
          />
        }
        {
          showDownloadDialog &&
          <DownloadDialog
            id={selectedDownloadType === 'TEXT' ? 'download_text_dialog' : 'download_hex_dialog'}
            onClose={() => this.setState({ showDownloadDialog: false, selectedRow: undefined, selectedMimeType: undefined, selectedDownloadType: undefined })}
            mimetype={selectedMimeType}
            download={selectedDownloadType === 'TEXT' ? this.executeLogTextFromDialog : this.handleLogDownloadHex}
            downloadType={selectedDownloadType}
            title={selectedDownloadType === 'TEXT' ? translate('general.download_as_text') : translate('standard_selection.download_as_hex')}
          />
        }
        {showDeletefromArchiveDialog &&
          <DeleteDialog
            id={`${id}_deletedocumentfromarchivedialog`}
            title={translate('general.delete_from_archive')}
            question={translate('search.question_delete_from_archive_log')}
            onClose={() => this.setState({ showDeletefromArchiveDialog: false })}
            onDelete={() => this.deleteFromArchive()}
            visible>
            <Row>
              <Column colMD={3} offsetMD={0}>
                <p id={`${id}_form`}>
                  {translate('general.form')}:
                </p>
              </Column>
              <Column colMD={9} offsetMD={0}>
                <p id={`${id}_form_value`}>
                  {zosJobLog.FORM}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3} offsetMD={0}>
                <p id={`${id}_extension`}>
                  {translate('general.extension')}:
                </p>
              </Column>
              <Column colMD={9} offsetMD={0}>
                <p id={`${id}_extension_value`}>
                  {zosJobLog.EXT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3} offsetMD={0}>
                <p id={`${id}_report`}>
                  {translate('general.report')}:
                </p>
              </Column>
              <Column colMD={9} offsetMD={0}>
                <p id={`${id}_report_value`}>
                  {zosJobLog.WREPORT}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        }
        <Modal
          id={'zos_job_information_dialog'}
          className={'buxUserProfileModal'}
          onClose={onClose}
          size={'l'}>
          <Header
            id={`${id}_modalHeader`}
            title={translate('general.job_details')}
            onClose={onClose}>
            {this.renderHeader()}
          </Header>
          <Main id={id}>
            <Card className={'bux_card_flex'}>
              <Row>
                <Column colMD={12}>
                  <label id={`${id}_logfiles`}>{translate('job.log_files')}</label>
                </Column>
              </Row>
              <Row className={'bux_full_height'}>
                <Column colMD={12} className={'bux_full_height'}>
                  <DataTable
                    id={id}
                    header={[
                      translate('job.dd_name'),
                      translate('job.step_name'),
                      translate('job.proc_step'),
                      translate('job.ds_id'),
                      translate('general.pages'),
                      translate('general.lines'),
                      translate('general.form'),
                      translate('general.extension'),
                      translate('job.class'),
                      translate('general.marked_delete'),
                      translate('general.marked_archive'),
                      translate('general.marked_reload'),
                      translate('general.archive'),
                      translate('recipient.distribute_online'),
                    ]}
                    columnSortDefs={['string', 'string', 'string', 'number', 'number', 'number', 'string', 'string', 'string', 'string']}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={rowIndex => this.handleLogInformation(rowIndex)}
                    data={data}
                    language={lang}
                    datemask={datemask}
                    translate={key => translate(key)}
                    menu={false}
                  />
                </Column>
              </Row>
            </Card>
          </Main>
          <Footer>
            <Button
              id={`${id}_closebtn`}
              text={translate('general.close')}
              onClick={onClose}
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    zosJobDetails: state.search.zos.zosJobDetails,
    zosJobLogs: state.search.zos.zosJobLogs,
    zosJobLog: state.search.zos.zosJobLog,
    zosJobLogInformation: state.search.zos.zosJobLogInformation,
    zosJobLogPrintInfo: state.search.zos.zosJobLogPrintInfo,
    zosJobs: state.search.zos.zosJobs,
    bwesystemname: state.auth.serverdata.bwesystemname,
    bwebaseurl: state.auth.serverdata.bwebaseurl,
    userprofile: state.auth.serverdata.userprofile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLog: (docid, callback) => ZosActions.getLog(undefined, docid, undefined, callback)(dispatch),
    downloadLog: (docid, downloadname, callback) => ZosActions.downloadLog(docid, downloadname, callback)(dispatch),
    downloadLogText: (docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback) => {
      ZosActions.downloadLogText(docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback)(dispatch)
    },
    downloadLogHex: (docid, downloadname, displayMode, showPageHeader, showResources, callback) => {
      ZosActions.downloadLogHex(docid, downloadname, displayMode, showPageHeader, showResources, callback)(dispatch)
    },
    getPrintInfo: (docid, callback) => ZosActions.getPrintInfo(docid, callback)(dispatch),
    markArchive: (logDefinition, searchObj, callback) => ZosActions.markArchive(logDefinition, searchObj, callback)(dispatch),
    markDelete: (logDefinition, searchObj, callback) => ZosActions.markDelete(logDefinition, searchObj, callback)(dispatch),
    markReload: (logDefinition, searchObj, callback) => ZosActions.markReload(logDefinition, searchObj, callback)(dispatch),
    getJobLogInformation: (joblogObj, callback) => ZosActions.getJobLogInformation(undefined, joblogObj, callback)(dispatch),
    displayLog: (docid, betaviewBaseurl, betaviewFavname, callback) => {
      ZosActions.displayLog(docid, betaviewBaseurl, betaviewFavname, callback)(dispatch)
    },
    getZosJobLogs: (obj, callback) => ZosActions.getZosJobLogs(obj, callback)(dispatch),
    addTabToDocViewer: (tabData, callback) => {
      DocViewerActions.addTabToDocViewer(tabData, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ZosJobInformationDialog))