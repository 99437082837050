import React, {useCallback, useState} from 'react';
import {Flyout} from 'BetaUX2Web-Components/src';
import {FlyoutAlignment, FlyoutData} from 'BetaUX2Web-Components/src/types';
import {translate} from 'language/Language';
import {useDispatch, useSelector} from 'react-redux';
import {changeLanguage} from 'redux/actions/AuthenticationActions';

const LOCALES = ['en', 'de']

const mapToDropdownItem = (locale: string): FlyoutData => ({
  icon: `lang_${locale}`,
  text: translate(`language.${locale}`)
})

export const SwitchLanguageButton = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const lang = useSelector(state => state.auth?.serverdata?.preferences?.LANGUAGE) ?? LOCALES[0] //* WIP: Check rendering cycle, this hook is called too early
  const dispatch = useDispatch()

  const [langIndex, setLangIndex] = useState(LOCALES.findIndex(l => l === lang));

  const switchLanguage = useCallback((index: number): void => {
    setLangIndex(index)
    changeLanguage(LOCALES[index])(dispatch)
  }, [setLangIndex, dispatch])

  return (
    <Flyout
      id={'SwitchLanguageButton'}
      alignment={FlyoutAlignment.right}
      iconName={`lang_${lang}`}
      items={LOCALES.map(mapToDropdownItem)}
      activeIndex={langIndex}
      onChange={switchLanguage}
      tooltip={translate('language.language_selection')}
    />
  )
}