import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as SortUtils from 'utils/SortUtils'
import * as UrlUtils from 'utils/UrlUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CopyFolderDefinitionDialog from 'components/dialogs/copy_folder_definition_dialog/CopyFolderDefinitionDialog'
import CreateFolderDefinitionDialog from 'components/dialogs/create_folder_definition_dialog/CreateFolderDefinitionDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyFolderDefinitionDialog from 'components/dialogs/modify_folder_definition_dialog/ModifyFolderDefinitionDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as FolderActions from 'redux/actions/FolderDefinitionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultFolder extends Component {
  state = {
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: FOLDER_ID, translation: 'definition.folder_id', default: true },
      { rest: OWNER, translation: 'definition.folder_owner', default: true },
      { rest: TITLE, translation: 'definition.folder_title', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description Handles the refresh button
   */
  handleRefresh = () => {
    const { getFolders, preferences } = this.props
    const name = preferences[Preferences.DEFINITION_FOLDER_ID]
    const title = preferences[Preferences.DEFINITION_FOLDER_TITLE]
    const owner = preferences[Preferences.DEFINITION_FOLDER_OWNER]
    const brw = preferences[Preferences.DEFINITION_FOLDER_BRW]

    getFolders(undefined, name, title, owner, brw)
  }

  /**
   * @description Handles the modal dialogs for modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { getFolder, folders } = this.props
    const name = folders.data[index][this.headerData('GLRNAME')]
    getFolder(name, () => this.setState({ [dialog]: true }))
  }

  /**
   * @description shows the create folder dialog
   */
  showPrefilledCreateDialog = () => {
    const { preferences } = this.props
    this.setState({
      showCreateDialog: true,
      createDialogData: {
        GLRNAME: preferences[Preferences.DEFINITION_FOLDER_ID],
        GLRTITLE: preferences[Preferences.DEFINITION_FOLDER_TITLE],
        OWNER: preferences[Preferences.DEFINITION_FOLDER_OWNER]
      }

    })
  }

  /**
   * @description deletes the specific entry
   */
  handleDelete = () => {
    const { deleteFolder, folder } = this.props
    deleteFolder(folder.GLRNAME, () => this.setState({ showDeleteDialog: false }))
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  showDocuments = index => {
    const { folders } = this.props
    const header = folders.header
    const data = folders.data[index]
    const keys = [
      { rest: 'GLRNAME', ui: 'folder' }
    ]

    const state = {
      folder: data[header.indexOf('GLRNAME')]
    }

    const urlToPush = `/assignment/folder_document${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id, lang } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit', lang)}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete', lang)}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options', lang)}>
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit', lang)}
          title={translate('general.edit', lang)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy', lang)}
          title={translate('general.copy', lang)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete', lang)}
          title={translate('general.delete', lang)}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => this.showDocuments(rowIndex)}
          id={`${id}_show_documents_btn`}
          icon={<Icon name='document' className='actionIcon' />}
          text={translate('general.display_documents')}
          title={translate('general.display_documents')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description gets the index of the header in redux state folders.header
   * @param {String} header header name of the header in redux state folders.header
   */
  headerData = header => this.props.folders.header.indexOf(header)

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create', lang)}
          onClick={this.showPrefilledCreateDialog}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings', lang)}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, folders } = this.props
    let data = []
    let headers = this.getUsedHeader()
    folders.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_FOLDER]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_FOLDER].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_FOLDER]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_FOLDER].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  render = () => {
    const { folders, folder, id, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showDeleteDialog, showCopyDialog, showModifyDialog } = this.state
    let data = folders && folders.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showCreateDialog &&
          <CreateFolderDefinitionDialog
            id={`${id}_createfolder`}
            prefilledData={this.state.createDialogData}
            onClose={() => this.setState({ showCreateDialog: false, createDialogData: undefined })}
          />
        }
        {showCopyDialog &&
          <CopyFolderDefinitionDialog
            id={`${id}_copyfolder`}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        }
        {showModifyDialog &&
          <ModifyFolderDefinitionDialog
            id={`${id}_modifyfolder`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        }
        {showDeleteDialog &&
          <DeleteDialog
            id={`${id}_deletefolder`}
            title={translate('definition.delete_folder')}
            question={translate('definition.delete_folder_question')}
            onClose={() => this.setState({ showDeleteDialog: false })}
            onDelete={() => { this.handleDelete() }}>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_folder_id_key_text`}>
                  {translate('definition.folder_id')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_folder_id_value_text`}>
                  {folder.GLRNAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_folder_title_key_text`}>
                  {translate('definition.folder_title')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_folder_title_value_text`}>
                  {folder.GLRTITLE}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        }
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_FOLDER }}
          />
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {folders
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={rowIndex => this.handleDialog(rowIndex, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_folder_result')}
                    link={translate('emptyresult.create_folder_link')}
                    onClick={() => this.showPrefilledCreateDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const FOLDER_ID = 'GLRNAME'
const OWNER = 'OWNER'
const TITLE = 'GLRTITLE'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const LASTCHANGED = 'LASTCHANGED'

SearchResultFolder.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    folders: state.definitions.folders.folders,
    folder: state.definitions.folders.folder,
    keepPagination: state.definitions.folders.keepPagination,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getFolders: (fields, foldername, foldertitle, owner, brw, callback) => {
      FolderActions.getFolders(fields, foldername, foldertitle, owner, brw, callback)(dispatch)
    },
    getFolder: (folder, callback) => {
      FolderActions.getFolder(folder, callback)(dispatch)
    },
    deleteFolder: (foldername, callback) => {
      FolderActions.deleteFolder(foldername, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultFolder))