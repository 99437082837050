import React, { Component } from 'react'
import DaysView from './DaysView.jsx'
import MonthsView from './MonthsView.jsx'
import YearsView from './YearsView.jsx'
import TimeView from './TimeView'

export default class CalendarContainer extends Component {

	render = () => {
		return {
			'days': <DaysView {...this.props.viewProps} />,
			'months': <MonthsView {...this.props.viewProps} />,
			'years': <YearsView {...this.props.viewProps} />,
			'time': <TimeView {...this.props.viewProps} />

		}[this.props.view]
		// if (this.props.view === 'days') {
		// 	return <DaysView {...this.props} />
		// }
		// else if (this.props.view === 'months') {
		// 	return <MonthsView {...this.props} />
		// }
		// else if (this.props.view === 'years') {
		// 	return <YearsView {...this.props} />
		// }
		// else if (this.props.view === 'time') {
		// 	return <TimeView {...this.props} />
		// }
		// return React.createElement( viewComponents[ this.props.view ], this.props.viewProps )
	}
}
