import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import ErrorMessage from '../error_message/ErrorMessage';
import Icon from '../icon/Icon'
import Label from '../label/Label'

// Style
import './NumericSpinner.scss'

/**
 * NumericSpinner component created according to
 * _Input_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
export default class NumericSpinner extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /**
     * Function to be called on an input change event.
     * @param {string} value
     */
    onChange: PropTypes.func.isRequired,
    /** Maximum value allowed.*/
    max: PropTypes.number.isRequired,
    /** Components value as a string or number. */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /** Default number which is used when the input loses the focus and there is no number or just a '-' or '+'.*/
    default: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Range of steps.*/
    steps: PropTypes.number,
    /** Minimum value allowed. Default is 0.*/
    min: PropTypes.number,
    /** Disables components visually and functionally.*/
    disabled: PropTypes.bool,
    /** Allows the numeric spinner to become "-" (visual '-' real value '') (no value) */
    emptyable: PropTypes.bool,
    /** Index of tab order.*/
    tabindex: PropTypes.string,
    /**
    * Sets dot and required word, next to the _title_, which indicates that fields is required.
    * If true - english version is used "required"
    * If string, user provide the word which appears after dot.
    * If _title_ is not set, indicator will not be shown.
    */
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** Enable autofocus on render completion.*/
    autoFocus: PropTypes.bool,
    /** Error description */
    error: PropTypes.string,
    /** Function to be called on blur. */
    onBlur: PropTypes.func,
    /** Label to be displayed above the numeric spinner.*/
    title: PropTypes.string,
    /** Disables possibility of interaction with NumericSpinner with buttons */
    disableButtons: PropTypes.bool,
    /** React ref */
    focusRef: PropTypes.any
  }

  state = {
    changeValueOnWheel: false,
    default: 0 || this.props.min,
    min: 0,
    max: 0,
    mousedownInc: false,
    mousedownDec: false
  }

  /**
   * @description Initializes the state values.
   */
  componentDidMount = () => {
    const min = this.props.min || 0
    const max = this.props.max
    let defaultVal

    if (this.props.default === undefined) {
      if (min <= 0 && max >= 0) {
        defaultVal = this.props.emptyable ? '' : 0
      } else {
        // if default is not passed use the nearest value to 0 which is in range
        defaultVal = [min, max].reduce((prev, curr) => {
          return (Math.abs(curr) < Math.abs(prev) ? curr : prev)
        })
      }
    } else {
      defaultVal = this.props.default
    }

    this.setState({
      changeValueOnWheel: false,
      default: defaultVal,
      min: min,
      max
    })
  }


  /**
   * @description Ensure min-/max-props be updated in the state when changed in props
   * @param {Object} nextProps The next props.
   */
  componentWillReceiveProps = (nextProps) => {
    let { min, max } = nextProps
    this.setState({
      min: min || 0,
      max,
      default: nextProps.default !== undefined ? nextProps.default : this.state.default
    })
  }

  componentWillUnmount = () => {
    const { onBlur } = this.props
    onBlur && onBlur()
  }

  /**
   * @description Calculates the next value. It takes care of the minus and plus and the valid range.
   * @param {String} val The value of add or subtract to the current value.
   */
  calculateNextVal = (val) => {
    const { value } = this.props
    const { min, max } = this.state
    

    let newVal
    if (value === '-') {
      newVal = max >= 0 ? 0 : max
    } else if (value === '+') {
      newVal = min >= 0 ? min : 0
    } else if (value === '') {
      newVal = min
    } else {
      if (value === undefined) {
        newVal = min + val
      } else {
      newVal = value + val
      }
    }
    this.handleOnChange(newVal)
  }

  /**
   * @description Handles the value change.
   * @param {String} val The new value.
   */
  handleOnChange = (val) => {
    const { onChange, id } = this.props
    const { min, max } = this.state

    // empty string is a valid value
    if (val === '') {
      onChange(val)
    } else if (min < 0 && val === '-') {
      // minus is a valid value
      onChange(val)
    } else if (max >= 0 && val === '+') {
      // plus is a valid value
      onChange(val)
      /* +val is used to convert to number, otherwise a string-int-comparsion is done */
    } else if (+val >= min && +val <= max) {
      // only allow values which are inside the min max range
      let newVal = parseInt(val, 10)
      if (isNaN(newVal)) {
        newVal = this.state.default
      }
      onChange(newVal)
    } else {
      console.warn(`NumericSpinner id: ${id} - value ${val} is out of range!`)
    }
  }

  /**
   * @description Handles the key down event.
   * @param {Object} event The key event.
   */
  onKeyDown = (event) => {
    const { value, steps, onChange } = this.props

    // arrow up
    if (event.keyCode === 38) {
      this.calculateNextVal(steps)
    } else if (event.keyCode === 40) {
      // arrow down
      this.calculateNextVal(-steps)
    } else if (event.keyCode === 13) {
      // enter
      // set default value if value is empty or minus or plus
      if (value === '' || value === '-' || value === '+') {
        onChange(this.state.default)
      }
    }
  }

  /**
   * @description Handles the key down event of the controls (arrow up and arrow down buttons).
   * @param {Object} event The key event.
   * @param {Number} val The number to add.
   */
  onControlKey = (event, val) => {
    // enter
    if (event.keyCode === 13) {
      this.calculateNextVal(val)
    }
  }

  /**
   * @description Handles the on wheel event.
   * @param {Object} event The mouse wheel event.
   */
  onWheel = event => {
    const { steps } = this.props
    const delta = event.deltaY
    // only change value if on wheel is enabled
    if (this.state.changeValueOnWheel) {
      event.preventDefault()
      // scroll up (increase)
      if (delta < 0) {
        this.calculateNextVal(steps)
      } else if (delta > 0) {
        // scroll down (decrease)
        this.calculateNextVal(-steps)
      }
    }
  }

  /**
   * @description Handles the focus changes.
   * @param {Boolean} isFocused Value if this component is focused.
   */
  onChangeFocus = isFocused => {
    const { value, onChange } = this.props

    this.setState({
      changeValueOnWheel: isFocused,
    })

    // set min value if value is empty or min or plus
    if (value === '' || value === '-' || value === '+') {
      onChange(!isFocused ? this.state.default : value)
    }

    if (!isFocused) {
      this.handleOnBlur()
    }
  }

  /**
   * @description Executes on blur function, if no spinner element has the focus.
   */
  handleOnBlur = () => {
    const { onBlur, id } = this.props
    if (onBlur) {
      // timeout is needed because the body receives the focus instant before the focus is set to the next element
      setTimeout(() => {
        const idsFromFocusableElements = [`${id}_div_input`, `${id}_div_decrease_arrow`, `${id}_div_increase_arrow`]
        if (!idsFromFocusableElements.includes(document.activeElement.id)) {
          onBlur()
        }
      }, 1)
    }
  }

  /**
   * @description Handles the keydown event on the addon.
   * @param {object} event The event of the specific action on an element.
   * @param {number} steps The steps the value should be changed.
   * @param {string} id The id from that element.
   */
  handleOnKeyDownAddon = (event, steps, id) => {
    this.onControlKey(event, steps)
    this.addActiveStyle(event, id)
  }

  /**
   * @description Adds the focus style to a specific element.
   * @param {string} mousedown The mousedown variable stored in state.
   * @param {string} id The id from that element.
   */
  addFocusStyle = (mousedown, id) => {
    if (!this.state[mousedown]) {
      const el = document.querySelector(`#${id}`)
      if (document.activeElement.id === id) {
        el.classList.add('bux_focus_style')
      }
    }
  }

  /**
   * @description Removes the focus style from a specific element.
   * @param {id} id The id from that element.
   */
  removeFocusStyle = id => {
    const el = document.querySelector(`#${id}`)
    el.classList.remove('bux_focus_style')
  }

  /**
   * @description Removes the focus style on all elements expect a specific element.
   * @param {string} id The id from that specific element.
   */
  removeFocusStyleExceptSpecific = id => {
    const elements = document.querySelectorAll(`${this.props.id}_div addon`)
    elements.forEach(el => {
      if (document.activeElement.id !== id) {
        el.classList.remove('bux_focus_style')
      }
    })
  }

  /**
   * @description Adds the active style on a specific element.
   * @param {object} event The event of the specific action on an element.
   * @param {string} id The id from that element.
   */
  addActiveStyle = (event, id) => {
    let permission = true
    if (event) {
      permission = false
      if (event.keyCode === 13) {
        permission = true
      }
    }
    if (permission) {
      const el = document.querySelector(`#${id}`)
      el.classList.remove('bux_focus_style')
      el.classList.add('bux_numericSpinner_active_addon')
    }
  }

  /**
   * @description Adds the active style on a specific element.
   * @param {object} event The event of the specific action on an element.
   * @param {string} id The id from that element.
   */
  removeActiveStyle = (event, id) => {
    let permission = true
    if (event) {
      permission = false
      if (event.keyCode === 13) {
        permission = true
      }
    }
    if (permission) {
      const el = document.querySelector(`#${id}`)
      el.classList.remove('bux_numericSpinner_active_addon')
      const hoveredAddon = document.querySelector(`#${this.props.id}_div :hover`)
      if (document.activeElement.id === id && (!hoveredAddon || hoveredAddon.id !== id)) {
        el.classList.add('bux_focus_style')
      }
    }
  }

  /**
   * @description Sets the mousedown flag to true.
   * @param {string} mousedown The mousedown variable stored in state.
   * @param {id} id The id from that element.
   */
  handleMouseDown = (mousedown, id) => {
    this.setState({ [mousedown]: true }, () => {
      this.removeFocusStyleExceptSpecific(id)
    })
  }

  /**
   * @description Sets the mousedown flag to false and removes specific styles.
   * @param {string} mousedown The mousedown variable stored in state.
   * @param {string} id The id from that element.
   */
  handleMouseUp = mousedown => {
    this.setState({ [mousedown]: false })
  }

  /**
   * @description Sets the mousedown flag to false and add/removes specific styles.
   * @param {string} mousedown The mousedown variable stored in state.
   * @param {string} id The id from that element.
   */
  handleMouseLeave = (mousedown, id) => {
    if (document.activeElement.id === id) {
      this.setState({ [mousedown]: false }, () => {
        this.addFocusStyle(mousedown, id)
      })
    }
  }

  /**
   * @description Handles the on blur function for the arrow buttons.
   */
  handleArrowOnBlur = id => {
    this.removeFocusStyle(id)
    this.handleOnBlur()
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, title, value, tabindex, steps, required, autoFocus, disabled, error, disableButtons, focusRef, className } = this.props
    return (
      <div id={id} className={`bux_numericSpinner ${className || ''}`}>
        {/* render title if available */}
        <Label
          id={`${id}_title`}
          title={title}
          required={required}
          disabled={disabled}
          isError={!!error}
        />
        <div id={`${id}_div`}>
          <input
            id={`${id}_div_input`}
            className={`${disableButtons ? '' : 'withAddon'}`}
            tabIndex={tabindex}
            value={value}
            type={'text'}
            onFocus={() => this.onChangeFocus(true)}
            onBlur={() => this.onChangeFocus(false)}
            onChange={event => this.handleOnChange(event.target.value)}
            onKeyDown={event => this.onKeyDown(event)}
            onWheel={event => this.onWheel(event)}
            autoFocus={autoFocus}
            disabled={disabled}
            invalid={error ? 'true' : 'false'}
            style={disableButtons && { width: '100%' }}
            ref={focusRef}
            placeholder={'0'} // Conform to accessibility check
          />
          {!disableButtons && (
            <>
              <div
                id={`${id}_div_decrease_arrow`}
                tabIndex={disabled ? -1 : 0}
                disabled={disabled}
                className={'addon'}
                onKeyDown={event => this.handleOnKeyDownAddon(event, -steps, `${id}_div_decrease_arrow`)}
                onKeyUp={event => this.removeActiveStyle(event, `${id}_div_decrease_arrow`)}
                onClick={() => this.calculateNextVal(-steps)}
                onFocus={() => this.addFocusStyle('mousedownDec', `${id}_div_decrease_arrow`)}
                onBlur={() => this.handleArrowOnBlur(`${id}_div_decrease_arrow`)}
                onMouseMove={() => this.removeFocusStyle(`${id}_div_decrease_arrow`)}
                onMouseLeave={(e) => { this.handleMouseLeave('mousedownDec', `${id}_div_decrease_arrow`); e.currentTarget.blur() }}
                onMouseDown={() => this.handleMouseDown('mousedownDec', `${id}_div_decrease_arrow`)}
                onMouseUp={() => this.handleMouseUp('mousedownDec')}>
                <Icon
                  id={`${id}_div_decrease_arrow`}
                  name={'chevron_down'}
                />
              </div>
              <div
                id={`${id}_div_increase_arrow`}
                tabIndex={disabled ? -1 : 0}
                disabled={disabled}
                className={'addon'}
                onKeyDown={event => this.handleOnKeyDownAddon(event, steps, `${id}_div_increase_arrow`)}
                onKeyUp={event => this.removeActiveStyle(event, `${id}_div_increase_arrow`)}
                onClick={() => this.calculateNextVal(steps)}
                onFocus={() => this.addFocusStyle('mousedownInc', `${id}_div_increase_arrow`)}
                onBlur={() => this.handleArrowOnBlur(`${id}_div_increase_arrow`)}
                onMouseMove={() => this.removeFocusStyle(`${id}_div_increase_arrow`)}
                onMouseLeave={(e) => { this.handleMouseLeave('mousedownInc', `${id}_div_increase_arrow`); e.currentTarget.blur() }}
                onMouseDown={() => this.handleMouseDown('mousedownInc', `${id}_div_increase_arrow`)}
                onMouseUp={() => this.handleMouseUp('mousedownInc')}>
                <Icon
                  id={`${id}_div_increase_arrow`}
                  name={'chevron_up'}
                />
              </div>
            </>
          )}
        </div>
        <ErrorMessage id={`${id}_error`} text={error} />
      </div>
    )
  }
}