import React, { Component } from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import LoginScreen from 'screens/login_screen/LoginScreen'
import ErrorBoundary from '../../errorhandler/ErrorBoundary'

class PrivateRoute extends Component {

  /**
   * @description Wraps a component into an errorboundary.
   * @param {Object} component The jsx object to wrap.
   */
  wrapIntoErrorBoundary = (component) => {
    return (
      <ErrorBoundary history={this.props.history}>
        {component}
      </ErrorBoundary>
    )
  }

  /**
   * @description Redirect to default page (admin / subadmin have different default pages) if authorized user tries to go to the LoginPage.
   */
  redirectLogin() {
    return (
      this.props.loggedIn
        ? this.getLoggedInRoute()
        : this.wrapIntoErrorBoundary(<Route exact path='/' render={props => <LoginScreen {...props} />} />)
    )
  }

  /**
   * @description Redirects to logged in page.
   */
  getLoggedInRoute() {
    // BUX-5121
    return <Redirect to='/search' />
  }

  /**
   * @description Redirects to the given page. If user is not authorized it redirects to login page.
   */
  redirectPrivate() {
    const { loggedIn, path, component: Component } = this.props
    /* Redirect to LoginPage if user is unauthorized */

    return (
      this.wrapIntoErrorBoundary(
        <Route path={path} render={(props) => (
          loggedIn
            ? <Component {...props} />
            : <Redirect to='/' />
        )} />
      )
    )
  }

  render = () => {
    return (
      this.props.path === '/'
        ? this.redirectLogin()
        : this.redirectPrivate()
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    auth: state.auth
  }
}

export default connect(mapStateToProps)(withRouter(PrivateRoute))