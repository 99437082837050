import * as RecipientDefinitionActions from '../actions/RecipientDefinitionActions.js'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  recipients: undefined,
  recipient: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get recipient actions
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_GET_RECIPIENT_START:
      return { ...state, recipient: undefined }
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_GET_RECIPIENT_SUCCESS:
      return { ...state, recipient: action.payload }
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_GET_RECIPIENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition get recipients actions
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_GET_RECIPIENTS_START:
      return { ...state }
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_GET_RECIPIENTS_SUCCESS:
      return { ...state, recipients: action.payload.data, keepPagination: action.keepPagination }
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_GET_RECIPIENTS_FAILED:
      return { ...state }
    case RecipientDefinitionActions.NO_RECIPIENT_DEFINITION_RECIPIENTS_FOUND:
      return { ...state, recipients: {} }
    /* ********************************************************************************** */
    // Definition create recipients actions
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_CREATE_RECIPIENT_START:
      return { ...state }
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_CREATE_RECIPIENT_SUCCESS:
      return { ...state }
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_CREATE_RECIPIENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete recipients actions
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_DELETE_RECIPIENT_START:
      return { ...state }
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_DELETE_RECIPIENT_SUCCESS:
      return { ...state }
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_DELETE_RECIPIENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update recipients actions
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_UPDATE_RECIPIENT_START:
      return { ...state }
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_UPDATE_RECIPIENT_SUCCESS:
      return { ...state }
    case RecipientDefinitionActions.RECIPIENT_DEFINITION_UPDATE_RECIPIENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return {
        ...state, recipients: undefined, recipient: undefined
      }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer