/* eslint-disable */
import React, { ReactNode, useEffect, useState } from 'react';

// Components
import Icon from '../icon/Icon';
import Label from '../label/Label';

// Style
import './Input.scss';
import ErrorMessage from '../error_message/ErrorMessage';
import { prepareClassNames } from '../../utils/ComponentUtils';
import { LetterCase } from '../_common/types';

export const TYPE_PASSWORD = 'password';
export const TYPE_TEXT = 'text';

export type InputType = typeof TYPE_PASSWORD | typeof TYPE_TEXT;

export interface Validation {
  /** Regex for validation */
  regex: string;
  /** Error description on validation fail */
  error: string;
}

export interface InputAddon {
  /** Name of the icon which should be displayed at the end of input field */
  iconName: string;
  /** Function to be called on _addon_ icon clicked */
  onClick?: (event: React.MouseEvent) => void;
  /** Text which should be displayed on hover _addon_ icon */
  tooltip?: string;
  /** Object which represents properties of inner addon */
  isInner?: boolean;
}

interface InputProps {
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** This ID will be used for integration tests to access the HTML element */
  dataTestId?: string;
  /** Reference to access DOM nodes */
  focusRef?: React.RefObject<HTMLInputElement>;
  /** Sets autofocus */
  autoFocus?: boolean;
  /**
   * Function to be called on change of input
   * @param {string} value New selected value
   * @param {string} error Error msg from _regexError_ if _reqexValidation_ fails, otherwise empty string.
   */
  onInputChanged?: (value: string, error: string, event: any) => void;
  /** Initial value of the input */
  value: string;
  /** Label to be displayed above the input.*/
  title?: string;
  /**
   * Type of the input.
   * Use constants of this module: TYPE_PASSWORD, TYPE_TEXT.
   */
  type?: InputType;
  /** Error description to be displayed below the input.*/
  error?: string;
  /** Disables components visually and functionally. */
  disabled?: boolean;
  /** Disables only input. */
  inputDisabled?: boolean;
  /** Text to be displayed when no _value_ passed */
  placeholder?: string;
  /**
   * Sets dot and required word, next to the _title_, which indicates that fields is required.
   * If true - english version is used "required"
   * If string, user provide the word which appears after dot.
   * If _title_ is not set, indicator will not be shown.
   */
  required?: boolean | string;
  /** Defines addon which should be placed at the end of the input inside or outside */
  addon?: InputAddon;
  /** Validation object where regex patter and error message when validation fails.*/
  validation?: Validation;
  /**
   * Shows an identifier in the inside the input, how much chars are left until max length is reached.
   * Will not be displayed, when no max length is set.
   */
  charCount?: boolean;
  /** Sets max length of entered value into input. Enables char counter.*/
  maxLength?: number;
  /** Tab index */
  tabindex?: number;
  /** Ignores the min width property of inputs */
  ignoreMinWidth?: boolean;
  /** Allow autocomplete */
  autocomplete?: string;
  /** JSX element to be displayed next to input */
  additionalButton?: ReactNode;
  /** Function to be called on an blur event.*/
  onBlur?: (event: React.FocusEvent) => void;
  /** Function to be called on an focus event.*/
  onFocus?: (event: React.FocusEvent) => void;
  /** Style class from CSS for styling for input container.*/
  containerClassName?: string;
  /** Style class from CSS for styling for component container.*/
  inputContainerClassName?: string;
  /** Tooltip for require input compatible with the language version of the application instead of the browser language.*/
  tooltip?: string;
  /** Sets entered text with letter case (uppercase or lowercase). Default is as user enter.*/
  letterCase?: LetterCase;
  /** If true input cannot be edited.*/
  readonly?: boolean;
}

/**
 * Input component created according to
 * _Input_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
const Input = React.forwardRef(({
  id,
  dataTestId,
  autoFocus,
  onInputChanged,
  value,
  title,
  tooltip,
  type,
  error,
  disabled,
  inputDisabled,
  placeholder,
  required,
  addon,
  validation,
  charCount,
  maxLength,
  tabindex,
  letterCase = LetterCase.default,
  ignoreMinWidth,
  autocomplete,
  additionalButton,
  onBlur,
  onFocus,
  containerClassName,
  inputContainerClassName,
  readonly
}: InputProps, ref: any): React.ReactElement => {

  /**
   * @description Checks if the input accept the new value and execute the onInputChanged function.
   * @param {String} val new value of the input
   * @param {Object} event
   */

  const [isShown, setIsShown] = useState(false);
  const [isCursorAbove, setIsCursorAbove] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    if (required) {
      // For required field tooltip is visible only when input field is empty
      if (value?.length < 1 || value === undefined) setIsEmpty(true);
      if (value?.length >= 1 && value !== undefined) setIsEmpty(false);
      if (isEmpty) {
        setIsShown(true);
      }
      if (!isEmpty) {
        setIsShown(false);
      }
    } else {
      setIsShown(true);
    }
  }, [isCursorAbove]);

  useEffect(() => {
    let val = value

    if (letterCase === LetterCase.uppercase) {
      val = val.toUpperCase()
    } else if (letterCase === LetterCase.lowercase) {
      val = val.toLowerCase()
    }
    if (val !== value) onInputChanged?.(val, '', null)
  }, [])

  const inputChanged = (val: string, event: React.ChangeEvent<HTMLInputElement>): void => {
    let error = '';
    if (letterCase !== LetterCase.default) {
      // Reposition the cursor to the original position, instead of the end of it's field
      if (event.target) {
        const el = event.target;
        const caret = event.target.selectionStart;
        window.requestAnimationFrame(() => {
          el.selectionStart = caret;
          el.selectionEnd = caret;
        });
      }
      switch (letterCase) {
        case LetterCase.uppercase:
          val = val.toUpperCase();
          break;
        case LetterCase.lowercase:
          val = val.toLowerCase();
          break;
      }

    }
    if (validation) {
      if (!new RegExp(`^${validation.regex}$`).test(val)) {
        error = validation.error;
      }
    }
    onInputChanged?.(val, error, event);
  };

  const onClickAddon = (event: React.MouseEvent): void => {
    addon?.onClick?.(event);
  };

  const renderCharacterLimitIndicator = (): ReactNode => {
    return (
      charCount!== false && maxLength &&
      <label
        className={'cl_input_char_counter'}>
        {`${value?.length ?? 0}/${maxLength}`}
      </label>);
  };

  /**
   * @description Renders addon as a separate button to be placed outside the input.
   * @returns {Object} JSX elements
   */
  const renderAddonOutside = (): ReactNode => {
    const titleProp = { ...addon?.tooltip && { title: addon?.tooltip } } // Conform to accessbility check

    return (
      addon &&
      <button
        id={`${id}_btn`}
        className={`cl_addon ${addon?.isInner ? 'cl_addon_inner' : ''}`}
        tabIndex={tabindex || 0}
        { ...titleProp }
        type={'button'}
        disabled={disabled}
        onClick={onClickAddon}
        onMouseLeave={event => event.currentTarget.blur()}>
        <Icon id={`${id}_addon`} name={addon?.iconName || ''} />
      </button>
    );
  };

  /**
   * @description Renders the HTML input with addon, character limit indicator and button if it is needed.
   * @returns {Object} JSX elements
   */
  const renderInput = (): ReactNode => {
    const classNames = prepareClassNames([
      charCount !== false && maxLength && 'cl_input_with_char_count',
      addon && 'cl_input_with_addon',
      addon && addon?.isInner && 'cl_input_with_inner_addon',
      additionalButton && 'cl_input_with_additional_button',
      disabled && 'cl_input_disabled',
      inputContainerClassName]);

    const borderClassNames = prepareClassNames([
      'cl_input_border',
      inputDisabled && 'cl_input_only_disabled',
      readonly && 'cl_input_readonly']);

    return (
      <div className={`cl_input_inner_container ${classNames}`}>
        <div className={borderClassNames}></div>
        <div className={'cl_input_group'}>
          <input
            id={`${id}_input`}
            data-testid={dataTestId}
            ref={ref}
            value={value}
            tabIndex={tabindex ?? disabled ? -1 : 0}
            autoFocus={autoFocus}
            maxLength={maxLength}
            type={type || TYPE_TEXT}
            disabled={(disabled || inputDisabled)}
            placeholder={placeholder}
            onChange={event => inputChanged(event.target.value, event)}
            required={!!required}
            autoComplete={autocomplete ? autocomplete : 'new-password'} // 'new-password' is a workaround because 'off' not working for FF
            onBlur={onBlur}
            onFocus={onFocus}
            title={isShown ? tooltip : ''}
            onMouseEnter={() => setIsCursorAbove(true)}
            onMouseLeave={() => setIsCursorAbove(false)}
            readOnly={readonly}

            // custom property
            {...{ invalid: (!!error).toString() }}
          />
          <div className={'inner_elements'}>
            {renderCharacterLimitIndicator()}
          </div>
          {renderAddonOutside()}
          {additionalButton}
        </div>
      </div>

    );
  };

  /**
   * @description Renders the component.
   */
  return (
    <div
      id={id}
      className={`cl_input_container ${containerClassName !== undefined ? containerClassName : ''} ${ignoreMinWidth ? 'cl_ignore_min_width' : ''}`}>
      {title !== undefined &&
        <div className={'cl_title'}>
          <Label
            id={`${id}_label`}
            title={title}
            required={required}
            disabled={disabled}
            isError={!!error}
          />
        </div>}
      {renderInput()}
      <ErrorMessage id={`${id}_input_error`} dataTestId={dataTestId ? `${dataTestId}_error` : undefined} text={error} />
    </div>
  );

});

export default Input;