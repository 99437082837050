import store from 'redux/Store'

import * as Config from 'config/Configs'
import { DOC_VIEWER_HEX_MODE_BLOCK } from 'components/documentViewer/constants/data';

export const SEARCH_TYPE_CONTROLM = 'controlm';
export const SEARCH_TYPE_CUSTOMSELECTION = 'customselection';
export const SEARCH_TYPE_STANDARDSELECTION = 'standardselection';
export const SEARCH_TYPE_SYSLOG = 'syslog';
export const SEARCH_TYPE_STONEBRANCH = 'stonebranch';
export const SEARCH_TYPE_UC4 = 'uc4';
export const SEARCH_TYPE_ZOS = 'zos';

export const SEARCH_TYPE_CONTROLM_ALL = 'controlm_all';
export const SEARCH_TYPE_STONEBRANCH_ALL = 'stonebranch_all';
export const SEARCH_TYPE_SYSLOG_ALL = 'syslog_all';
export const SEARCH_TYPE_ZOS_ALL = 'zos_all';

export const SEARCH_TYPE_STANDARDSELECTION_SIDEFILE = 'standardselection_sidefile'

export const IDX_MARKER = 'IDX_'

export const blockLengthMap = {
  controlm: '',
  'controlm_all': '',
  customselection: '',
  standardselection: 'LGRMAXRL',
  'standardselection_sidefile': '',
  stonebranch: '',
  'stonebranch_all': '',
  syslog: '',
  'syslog_all': '',
  uc4: '',
  zos: '',
  'zos_all': '',
}

export const extensionKeyMap = {
  controlm: '',
  'controlm_all': '',
  customselection: '',
  standardselection: 'SRCJOBI',
  'standardselection_sidefile': '',
  stonebranch: '',
  'stonebranch_all': '',
  syslog: '',
  'syslog_all': '',
  uc4: '',
  zos: '',
  'zos_all': '',
}

export const mimeTypeTranslation = {
  '*.txt': 'txt',
  '*.ps': 'pstext',
  '*.afp': 'afptext',
  '*.pcl': 'pcltext',
  '*.pjl': 'pcltext',
  '*.pdf': 'pdftext',
}

export const isDocument = (type) => {
  return [
    SEARCH_TYPE_CONTROLM,
    SEARCH_TYPE_STANDARDSELECTION,
    SEARCH_TYPE_STONEBRANCH,
    SEARCH_TYPE_SYSLOG,
    SEARCH_TYPE_UC4,
    SEARCH_TYPE_ZOS,
  ].some((searchType) => searchType === type)
}

export const isJob = (type) => {
  return [
    SEARCH_TYPE_CONTROLM_ALL,
    SEARCH_TYPE_STONEBRANCH_ALL,
    SEARCH_TYPE_SYSLOG_ALL,
    SEARCH_TYPE_ZOS_ALL
  ].some((searchType) => searchType === type)
}

export const isSideFile = (type) => {
  return [
    SEARCH_TYPE_STANDARDSELECTION_SIDEFILE
  ].some((searchType) => searchType === type)
}

/**
 * @description This function parses the request URL for fetching the docment/job content.
 * @param {Object} tabData { id: String, type: String, header: String[], data: String[], pageCount: Number }
 * @param {Number} pageNumberToLoad Number of the page which content should be loaded (only for documents)
 * @returns {String} URL
 */
export const parseUrlFetchPageContentText = (tabData, pageNumberToLoad) => {
  const usertoken = store.getState().auth.serverdata.token

  if (isDocument(tabData.type)) {
    const indexExt = tabData.header.indexOf(extensionKeyMap[tabData.type])
    const mimeTypeTxt = mimeTypeTranslation[tabData.data[indexExt]] ?? 'save'

    return `${Config.REST_API_URL}/api/documents/document/download?USERTOKEN=${usertoken}&DOCID=${tabData.id}&MIMETYPE=${mimeTypeTxt}&PAGE=${pageNumberToLoad}`
  }

  if (isJob(tabData.type)) {
    const indexUkey = tabData.header.indexOf('UKEY')
    const ukey = tabData.data[indexUkey]
    const mimeTypeTxt = 'save'

    return `${Config.REST_API_URL}/api/jobs/job/download?USERTOKEN=${usertoken}&UKEY=${ukey}&MIMETYPE=${mimeTypeTxt}&PAGE=${pageNumberToLoad}`
  }

  if (isSideFile(tabData.type)) {
    const docId = tabData.id.replace(IDX_MARKER, '')
    const mimeTypeSideFile = 'save'
    return `${Config.REST_API_URL}/api/documents/document/download?USERTOKEN=${usertoken}&DOCID=${docId}&MIMETYPE=${mimeTypeSideFile}&PAGE=${pageNumberToLoad}&DISPIDXDATA=YES`
  }
}

/**
 * @description This function parses the request URL for fetching the document/job hex content.
 * @param {Object} fetchData { tabId: String, hexMode: String, pageNumberToLoad: Number, showPageHeader: String, showResources: String }
 * @param {Number} pageNumberToLoad Number of the page which content should be loaded (only for hex)
 * @returns {String} URL
 */
export const parseUrlFetchPageContentHex = ({ tabId, hexMode, pageNumberToLoad, showPageHeader, showResources }) => {
  const usertoken = store.getState().auth.serverdata.token
  const tab = store.getState().docviewer.tabs.find((tab) => tab.id === tabId)

  if (isDocument(tab.type)) {
    return `${Config.REST_API_URL}/api/documents/document/download?USERTOKEN=${usertoken}&DOCID=${tabId}&PAGE=${pageNumberToLoad}&MIMETYPE=hex&HEX=YES&HEXBLOCK=${hexMode === DOC_VIEWER_HEX_MODE_BLOCK ? 'YES' : 'NO'}&HEXPAB=${showPageHeader}&HEXRES=${showResources}`
  }

  if (isJob(tab.type)) {
    // const indexUkey = tab.header.indexOf('UKEY')
    // const ukey = encodeURIComponent(tab.data[indexUkey])

    // WIP: Endpoint not available yet
    // return `${Config.REST_API_URL}/api/jobs/job/download?USERTOKEN=${usertoken}&UKEY=${ukey}&PAGE=${pageNumberToLoad}&MIMETYPE=hex&HEX=YES&HEXBLOCK=${hexMode === DOC_VIEWER_HEX_MODE_BLOCK ? 'YES' : 'NO'}&HEXPAB=${showPageHeader}&HEXRES=${showResources}`

    return null
  }

  if (isSideFile(tab.type)) {
    const docId = tabId.replace(IDX_MARKER, '')
    return `${Config.REST_API_URL}/api/documents/document/download?USERTOKEN=${usertoken}&DOCID=${docId}&PAGE=${pageNumberToLoad}&MIMETYPE=hex&HEX=YES&HEXBLOCK=${hexMode === DOC_VIEWER_HEX_MODE_BLOCK ? 'YES' : 'NO'}&HEXPAB=${showPageHeader}&HEXRES=${showResources}&DISPIDXDATA=YES`
  }
}