import React, { Component } from 'react'

// Utils
import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as Utils from 'utils/Utils'
import { LetterCase } from 'BetaUX2Web-Components/src/types'
// components
import {
  Button, Checkbox, Column, Combobox, Input,
  Modal as ModalComponent, NumericSpinner, Row, Switch, Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'
import Datepicker from '../../datepicker/Datepicker'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import { getDocumentDefinitions } from 'redux/actions/ModalSelectorActions'
import { createViewProfile } from 'redux/actions/ViewProfileDefinitionActions'
import * as Preferences from 'redux/general/Preferences'

class CopyViewProfileDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    showDocumentSelectorDialog: false,
    generalTab: {
      viewProfileID: {
        value: '',
        error: ''
      },
      owner: '',
      title: '',
      testMode: false,
      testUser: '',
      docType: {
        value: '',
        error: ''
      },
      form: '',
      extension: '',
      report: '',
      startDate: {
        value: '',
        error: '',
      },
      endDate: {
        value: '',
        error: ''
      }
    },
    table1Tab: {
      searchText: {
        value: '',
        error: ''
      },
      searchCriteriaIndex: 0,
      fromPage: 0,
      fromLine: 0,
      fromColumn: 0,
      toPage: 0,
      toLine: 0,
      toColumn: 0,
      tableHeader: '',
      settingIndex: 0,
      settingColumns: [],
      settingColors: []
    },
    table2Tab: {
      searchText: {
        value: '',
        error: ''
      },
      searchCriteriaIndex: 0,
      useTextAsSearchPattern: false,
      caseSensitive: false,
      fromPage: 0,
      fromLine: 0,
      fromColumn: 0,
      toPage: 0,
      toLine: 0,
      toColumn: 0,
      tableHeader: '',
      settingIndex: 0,
      settingColumns: [],
      settingColors: []
    }
  }

  viewProfileIdInput = React.createRef()
  docTypeInput = React.createRef()
  startDateInput = React.createRef()
  endDateInput = React.createRef()
  searchText1Input = React.createRef()
  searchText2Input = React.createRef()

  componentDidMount = () => {
    this.viewProfileIdInput.current.focus()
    this.initializeState()
  }

  /**
   * @description Initializes the state
   */
  initializeState = () => {
    const { viewProfile, datemask } = this.props
    const columns1 = []
    const colors1 = []
    const columns2 = []
    const colors2 = []
    for (let i = 1; i < 9; i++) {
      if (viewProfile[`PBDT10${i}H`] !== '') {
        columns1.push({
          identifier: {
            value: viewProfile[`PBDT10${i}H`],
            error: ''
          },
          width: viewProfile[`PBDT10${i}W`],
          sColumn: viewProfile[`PBDT10${i}S`],
          eColumn: viewProfile[`PBDT10${i}E`],
          ref: React.createRef()
        })
      }
    }
    for (let i = 1; i < 5; i++) {
      if (viewProfile[`PBDT1M${i}T`] !== '') {
        colors1.push({
          text: {
            value: viewProfile[`PBDT1M${i}T`],
            error: ''
          },
          foreground: {
            value: viewProfile[`PBDT1M${i}F`],
            error: ''
          },
          background: {
            value: viewProfile[`PBDT1M${i}B`],
            error: ''
          },
          ref: React.createRef(),
          refForeground: React.createRef(),
          refBackground: React.createRef(),
        })
      }
    }
    for (let i = 1; i < 9; i++) {
      if (viewProfile[`PBDT20${i}H`] !== '') {
        columns2.push({
          identifier: {
            value: viewProfile[`PBDT20${i}H`],
            error: ''
          },
          width: viewProfile[`PBDT20${i}W`],
          sColumn: viewProfile[`PBDT20${i}S`],
          eColumn: viewProfile[`PBDT20${i}E`],
          ref: React.createRef()
        })
      }
    }
    for (let i = 1; i < 5; i++) {
      if (viewProfile[`PBDT2M${i}T`] !== '') {
        colors2.push({
          text: {
            value: viewProfile[`PBDT2M${i}T`],
            error: ''
          },
          foreground: {
            value: viewProfile[`PBDT2M${i}F`],
            error: ''
          },
          background: {
            value: viewProfile[`PBDT2M${i}B`],
            error: ''
          },
          ref: React.createRef(),
          refForeground: React.createRef(),
          refBackground: React.createRef(),
        })
      }
    }

    this.setState({
      showDocumentSelectorDialog: false,
      generalTab: {
        viewProfileID: {
          value: viewProfile.PBDNAME,
          error: ''
        },
        owner: viewProfile.OWNER,
        title: viewProfile.PBDTITLE,
        testMode: viewProfile.PBDMODE === 'TEST',
        testUser: viewProfile.PBDVUSER,
        docType: {
          value: viewProfile.SRCJOBI,
          error: ''
        },
        form: viewProfile.FORM,
        extension: viewProfile.EXT,
        report: viewProfile.REPORT,
        startDate: {
          value: DateUtils.getDate(datemask, viewProfile.VDATEB),
          error: '',
        },
        endDate: {
          value: DateUtils.getDate(datemask, viewProfile.VDATEE),
          error: ''
        }
      },
      table1Tab: {
        searchText: {
          value: viewProfile.PBDT1C,
          error: ''
        },
        searchCriteriaIndex: Math.max(DefinitionUtils.viewProfileSearchCriteria.findIndex(d => d.key === viewProfile.PBDT1SM), 0),
        fromPage: viewProfile.PBDT1FP,
        fromLine: viewProfile.PBDT1FL,
        fromColumn: viewProfile.PBDT1FC,
        toPage: viewProfile.PBDT1TP,
        toLine: viewProfile.PBDT1TL,
        toColumn: viewProfile.PBDT1TC,
        tableHeader: viewProfile.PBDT1H,
        settingIndex: 0,
        settingColumns: columns1,
        settingColors: colors1
      },
      table2Tab: {
        searchText: {
          value: viewProfile.PBDT2C,
          error: ''
        },
        searchCriteriaIndex: Math.max(DefinitionUtils.viewProfileSearchCriteria.findIndex(d => d.key === viewProfile.PBDT2SM), 0),
        fromPage: viewProfile.PBDT2FP,
        fromLine: viewProfile.PBDT2FL,
        fromColumn: viewProfile.PBDT2FC,
        toPage: viewProfile.PBDT2TP,
        toLine: viewProfile.PBDT2TL,
        toColumn: viewProfile.PBDT2TC,
        tableHeader: viewProfile.PBDT2H,
        settingIndex: 0,
        settingColumns: columns2,
        settingColors: colors2
      }
    })
  }

  /**
   * @description Handles the input changes.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleGeneralTab = (key, value, error) => {
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        [key]: typeof this.state.generalTab[key] === 'object'
          ? { value, error }
          : value
      }
    })
  }

  /**
  * @description Validates the id.
  * @returns {Object} Empty object if there is no error. Otherwise returns the new object with an error for the state.
  */
  validateID = () => {
    const { generalTab } = this.state
    if (generalTab.viewProfileID.value !== '') {
      if (generalTab.viewProfileID.value === this.props.viewProfile.PBDNAME) {
        return {
          viewProfileID: {
            ...this.state.generalTab.viewProfileID,
            error: translate('definition.view_profile_copy_same_id')
          }
        }
      } else {
        return {}
      }
    }
    return {
      viewProfileID: {
        ...this.state.generalTab.viewProfileID,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the id.
   * @returns {Object} Empty object if there is no error. Otherwise returns the new object with an error for the state.
   */
  validateDocType = () => {
    if (this.state.generalTab.docType.value !== '') {
      return {}
    }
    return {
      docType: {
        ...this.state.generalTab.docType,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the start date
   */
  validateStartDate = () => {
    const { datemask } = this.props
    const { generalTab } = this.state

    if (DateUtils.isDate(generalTab.startDate.value, datemask)) {
      return {}
    }
    return {
      ...this.state.generalTab.startDate,
      error: translate('general.please_enter_a_valid_date')
    }
  }

  /**
   * @description Validates the end date
   */
  validateEndDate = () => {
    const { datemask } = this.props
    const { generalTab } = this.state

    if (DateUtils.isDate(generalTab.endDate.value, datemask)) {
      return {}
    }
    return {
      ...this.state.generalTab.endDate,
      error: translate('general.please_enter_a_valid_date')
    }
  }

  /**
   * @description Validates the general tab
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateID(),
      ...this.validateDocType()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState(state => ({ generalTab: { ...state.generalTab, ...validatorResult } }), () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }

  /**
   * @description Handles the focus of the general tab
   */
  handleFocusGeneralTab = () => {
    const { generalTab } = this.state
    const requiredInputs = [
      { inputRef: this.viewProfileIdInput, error: generalTab.viewProfileID.error },
      { inputRef: this.docTypeInput, error: generalTab.docType.error }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  handleDocumentSelector = () => {
    const { getDocumentDefinitions } = this.props
    const { generalTab: { form, extension, report } } = this.state
    const callback = () => this.setState({ showDocumentSelectorDialog: true })
    getDocumentDefinitions(['FORM', 'EXT', 'REPORT'], form, extension, report, callback)
  }

  renderSelectorDialogs = () => {
    const { id, selector: { documents } } = this.props
    const { showDocumentSelectorDialog } = this.state

    return (
      <>
        {showDocumentSelectorDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report')
            ]}
            items={documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const form = documents.data[selectedRows][documents.header.indexOf('FORM')]
                const extension = documents.data[selectedRows][documents.header.indexOf('EXT')]
                const report = documents.data[selectedRows][documents.header.indexOf('REPORT')]
                this.setState({
                  showDocumentSelectorDialog: false,
                  generalTab: {
                    ...this.state.generalTab,
                    form,
                    extension,
                    report
                  }
                })
              } else {
                this.setState({ showDocumentSelectorDialog: false })
              }
            }}
          />
        )}
      </>
    )
  }

  renderGeneralTab = () => {
    const { id, lang, datemask } = this.props
    const { generalTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_generaltab_viewprofileid`}
              value={generalTab.viewProfileID.value}
              title={translate('definition.view_profile_id')}
              ref={this.viewProfileIdInput}
              required={`${translate('general.required_field')}`}
              maxLength={16}
              onInputChanged={(value, error) => this.handleGeneralTab('viewProfileID', value, error)}
              error={generalTab.viewProfileID.error}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateID() } }))}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_generaltab_owner`}
              value={generalTab.owner}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={value => this.handleGeneralTab('owner', value)}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_generaltab_title`}
              value={generalTab.title}
              title={translate('general.title')}
              maxLength={40}
              onInputChanged={value => this.handleGeneralTab('title', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Checkbox
              id={`${id}_generaltab_testmode`}
              value={generalTab.testMode}
              onCheck={value => this.handleGeneralTab('testMode', value)}
              label={translate('definition.view_profile_test_mode')}
            />
          </Column>
        </Row>
        {generalTab.testMode &&
          <Row>
            <Column colMD={3}>
              <Input
                id={`${id}_generaltab_testuser`}
                value={generalTab.testUser}
                title={translate('definition.view_profile_test_user')}
                maxLength={8}
                onInputChanged={value => this.handleGeneralTab('testUser', value)}
              />
            </Column>
          </Row>}
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_generaltab_doctype`}
              value={generalTab.docType.value}
              title={translate('documentinformation.header_doctype')}
              required={`${translate('general.required_field')}`}
              maxLength={8}
              error={generalTab.docType.error}
              onInputChanged={(value, error) => this.handleGeneralTab('docType', value, error)}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateDocType() } }))}
              ref={this.docTypeInput}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_generaltab_form`}
              onInputChanged={value => this.handleGeneralTab('form', value)}
              value={generalTab.form}
              title={translate('general.form')}
              maxLength={8}
              addon={{
                iconName: 'list',
                onClick: () => this.handleDocumentSelector(),
              }}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_generaltab_extension`}
              onInputChanged={value => this.handleGeneralTab('extension', value)}
              value={generalTab.extension}
              title={translate('general.extension')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.handleDocumentSelector(),
              }}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_generaltab_report`}
              onInputChanged={value => this.handleGeneralTab('report', value)}
              value={generalTab.report}
              title={translate('general.report')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.handleDocumentSelector(),
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Datepicker
              id={`${id}_generaltab_startdate`}
              title={translate('general.start_date')}
              value={generalTab.startDate.value}
              onChange={date => this.handleGeneralTab('startDate', date, '')}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateStartDate() } }))}
              // use different function here, because the default validation of toDate do not allowed empty input
              onInvalidDate={val => this.setState(state => ({
                generalTab: { ...state.generalTab, startDate: { value: val, error: translate('general.please_enter_a_valid_date') } }
              }))}
              dateFormat={datemask}
              error={generalTab.startDate.error}
              focusRef={this.startDateInput}
              bothDates
              language={lang}
              parentContainer={`${id}_tilecontainer`}
            />
          </Column>
          <Column colMD={3}>
            <Datepicker
              id={`${id}_generaltab_enddate`}
              title={translate('general.end_date')}
              value={generalTab.endDate.value}
              onChange={date => this.handleGeneralTab('endDate', date, '')}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateEndDate() } }))}
              // use different function here, because the default validation of toDate do not allowed empty input
              onInvalidDate={val => this.setState(state => ({
                generalTab: { ...state.generalTab, endDate: { value: val, error: translate('general.please_enter_a_valid_date') } }
              }))}
              dateFormat={datemask}
              error={generalTab.endDate.error}
              focusRef={this.endDateInput}
              bothDates
              language={lang}
              parentContainer={`${id}_tilecontainer`}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the input changes.
   * @param {String} key The id the input.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleTable1Tab = (key, value, error) => {
    this.setState({
      table1Tab: {
        ...this.state.table1Tab,
        [key]: typeof this.state.table1Tab[key] === 'object'
          ? { value, error }
          : value
      }
    })
  }

  /**
   * @description Validates the search text in table 1 tab.
   * @returns {Object} Empty object if there is no error. Otherwise returns the new object with an error for the state.
   */
  validateSearchText1 = () => {
    if (this.state.table1Tab.searchText.value !== '') {
      return {}
    }
    return {
      searchText: {
        ...this.state.table1Tab.searchText,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description handles the add setting button and adds additional row for setting columns
   */
  handleAddSettingColumnTable1 = () => {
    if (this.state.table1Tab.settingColumns.length < 8) {
      this.setState({
        table1Tab: {
          ...this.state.table1Tab,
          settingColumns: [
            ...this.state.table1Tab.settingColumns,
            {
              identifier: {
                value: '',
                error: ''
              },
              width: 0,
              sColumn: 0,
              eColumn: 0,
              ref: React.createRef()
            },
          ]
        }
      })
    }
  }

  /**
   * @description handles the add setting button and adds additional row for setting colors
   */
  handleAddSettingColorTable1 = () => {
    if (this.state.table1Tab.settingColors.length < 4) {
      this.setState({
        table1Tab: {
          ...this.state.table1Tab,
          settingColors: [
            ...this.state.table1Tab.settingColors,
            {
              text: {
                value: '',
                error: ''
              },
              foreground: {
                value: '',
                error: ''
              },
              background: {
                value: '',
                error: ''
              },
              ref: React.createRef(),
              refForeground: React.createRef(),
              refBackground: React.createRef()
            }
          ]
        }
      })
    }
  }

  /**
   * @description Validates the I. Table tab.
   * @returns {Boolean} False if there is an error in that tab.
   */
  validateTable1Tab = () => {
    const validatorResult = {
      ...this.validateSearchText1(),
      ...this.validateSettingColumnIdentifierTable1(),
      ...this.validateSettingColorTextTable1(),
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ table1Tab: { ...this.state.table1Tab, ...validatorResult } }, () => {
        this.handleFocusTable1()
      })
    }
    return errors === 0
  }

  handleFocusTable1 = () => {
    const { table1Tab: { searchText, settingColumns, settingColors } } = this.state
    const requiredInputs = [
      { inputRef: this.searchText1Input, error: searchText.error }
    ]

    if (settingColumns) {
      for (let i = 0; i < settingColumns.length; i++) {
        requiredInputs.push(
          { inputRef: settingColumns[i].ref, error: settingColumns[i].identifier.error }
        )
      }
    }

    if (settingColors) {
      for (let i = 0; i < settingColors.length; i++) {
        requiredInputs.push(
          { inputRef: settingColors[i].ref, error: settingColors[i].text.error }
        )
        requiredInputs.push(
          { inputRef: settingColors[i].refForeground, error: settingColors[i].foreground.error }
        )
        requiredInputs.push(
          { inputRef: settingColors[i].refBackground, error: settingColors[i].background.error }
        )
      }
    }

    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].error !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  /**
   * @description Handles the inputs in the rendered setting columns rows
   * @param {Number} index
   * @param {String} key
   * @param {String} value
   * @param {String} error
   */
  handleTable1SettingColumns = (index, key, value, error) => {
    const newValue = this.state.table1Tab.settingColumns
    newValue[index][key] = typeof newValue[index][key] === 'object'
      ? { value, error }
      : value
    this.setState({
      table1Tab: {
        ...this.state.table1Tab,
        settingColumns: newValue
      }
    })
  }

  /**
   * @description Validates a single setting column
   * @param {Number} index
   */
  validateSettingColumnIdentifierTable1OnBlur = index => {
    let clone = this.state.table1Tab.settingColumns
    if (clone[index] && clone[index].identifier.value === '') {
      clone[index].identifier = {
        value: clone[index].identifier.value,
        error: translate('general.input_required')
      }
      return { settingColumns: clone }
    }
    return {}
  }

  /**
   * @description Validates all setting columns identifier
   * @param {Number} index
   */
  validateSettingColumnIdentifierTable1 = () => {
    let clone = this.state.table1Tab.settingColumns
    let error = false
    for (let i = 0; i < clone.length; i++) {
      if (clone[i].identifier.value === '') {
        clone[i].identifier = {
          value: clone[i].identifier.value,
          error: translate('general.input_required')
        }
        error = true
      }
    }
    if (error) {
      return { settingColumn: clone }
    }
    return {}
  }

  /**
   * @description Handles the inputs in the rendered setting color rows
   * @param {Number} index
   * @param {String} key
   * @param {String} value
   * @param {String} error
   */
  handleTable1SettingColors = (index, key, value, error) => {
    const newValue = this.state.table1Tab.settingColors
    newValue[index][key] = typeof newValue[index][key] === 'object'
      ? { value, error }
      : value
    this.setState({
      table1Tab: {
        ...this.state.table1Tab,
        settingColors: newValue
      }
    })
  }

  /**
   * @description Validates a single setting color
   * @param {Number} index
   */
  validateSettingColorTextTable1OnBlur = index => {
    let clone = this.state.table1Tab.settingColors
    if (clone[index] && clone[index].text.value === '') {
      clone[index].text = {
        value: clone[index].text.value,
        error: translate('general.input_required')
      }
      return { settingColor: clone }
    }
    return {}
  }

  /**
   * @description Validates the setting colors text
   * @param {Number} index
   */
  validateSettingColorTextTable1 = () => {
    let clone = this.state.table1Tab.settingColors
    let error = false
    for (let i = 0; i < clone.length; i++) {
      if (clone[i].text.value === '') {
        clone[i].text = {
          value: clone[i].text.value,
          error: translate('general.input_required')
        }
        error = true
      }
      if (!clone[i].foreground.value.match(/^$|#[0-9A-F]{6}$/)) {
        clone[i].foreground = {
          value: clone[i].foreground.value,
          error: translate('general.invalid_hex_value')
        }
        error = true
      }
      if (!clone[i].background.value.match(/^$|#[0-9A-F]{6}$/)) {
        clone[i].background = {
          value: clone[i].background.value,
          error: translate('general.invalid_hex_value')
        }
        error = true
      }
    }
    if (error) {
      return { settingColor: clone }
    }
    return {}
  }

  /**
   * @description Deletes the current setting row of tab table 1
   * @param {String} setting
   * @param {Number} index
   */
  handleDeleteSettingTable1 = (setting, index) => {
    this.setState({
      table1Tab: {
        ...this.state.table1Tab,
        [setting]: this.state.table1Tab[setting].filter((_, i) => i !== index)
      }
    })
  }

  /**
   * @description Returns the default color items for combobox.
   */
  colorItems = () => {
    return [
      { display: translate('general.none'), key: '' },
      { display: `${translate('general.black')} (#000000)`, key: '#000000' },
      { display: `${translate('general.red')} (#FF0000)`, key: '#FF0000' },
      { display: `${translate('general.blue')} (#0000FF)`, key: '#0000FF' },
      { display: `${translate('general.green')} (#00FF00)`, key: '#00FF00' },
      { display: `${translate('general.yellow')} (#FFFF00)`, key: '#FFFF00' },
      { display: `${translate('general.pink')} (#FF4444)`, key: '#FF4444' },
      { display: `${translate('general.torquise')} (#00FFFF)`, key: '#00FFFF' }
    ]
  }


  /**
   * @description Gets the translated search criteria
   */
  translatedSearchCriteria = () => DefinitionUtils.viewProfileSearchCriteria.map(d => translate(d.translationKey))

  handleCheckboxTableTabs = (tab, key1, key2, value) => {
    this.setState({
      [tab]: {
        ...this.state[tab],
        [key1]: value,
        [key2]: false
      }
    })
  }

  /**
   * @description Renders the columns for setting columns
   */
  renderSettingColumnsTable1 = () => {
    const { id } = this.props
    const { table1Tab } = this.state
    return (
      table1Tab.settingColumns.map((el, i) => {
        return (
          <Row>
            <Column colMD={5}>
              <Input
                id={`${id}_table1tab_column_identifier_${i}`}
                title={i === 0 ? translate('general.identifier') : ' '}
                onInputChanged={(value, error) => this.handleTable1SettingColumns(i, 'identifier', value, error)}
                value={el.identifier.value}
                maxLength={32}
                required={i === 0 ? `${translate('general.required_field')}` : false}
                error={el.identifier.error}
                ref={el.ref}
                onBlur={() => this.setState(state => ({ table1Tab: { ...state.table1Tab, ...this.validateSettingColumnIdentifierTable1OnBlur(i) } }))}
              />
            </Column>
            <Column colMD={2}>
              <NumericSpinner
                id={`${id}_table1tab_column_width_${i}`}
                title={i === 0 ? translate('general.width') : ' '}
                onChange={value => this.handleTable1SettingColumns(i, 'width', value)}
                value={el.width}
                min={0}
                max={999}
                steps={1}
              />
            </Column>
            <Column colMD={2}>
              <NumericSpinner
                id={`${id}_table1tab_column_scolumn_${i}`}
                title={i === 0 ? translate('general.s_column') : ' '}
                onChange={value => this.handleTable1SettingColumns(i, 'sColumn', value)}
                value={el.sColumn}
                min={0}
                max={999}
                steps={1}
              />
            </Column>
            <Column colMD={2}>
              <NumericSpinner
                id={`${id}_table1tab_column_ecolumn_${i}`}
                title={i === 0 ? translate('general.e_column') : ' '}
                onChange={value => this.handleTable1SettingColumns(i, 'eColumn', value)}
                value={el.eColumn}
                min={0}
                max={999}
                steps={1}
              />
            </Column>
            <Column colMD={1}>
              <Button
                id={`${id}_table1button_deletecolumn_${i}`}
                icon={'delete'}
                className={'bux_mt18 bux_align_right'}
                onClick={() => this.handleDeleteSettingTable1('settingColumns', i)}
              />
            </Column>
          </Row>
        )
      })
    )
  }

  /**
   * @description Renders the columns for setting colors
   */
  renderSettingColorsTable1 = () => {
    const { id } = this.props
    const { table1Tab } = this.state
    return (
      table1Tab.settingColors.map((el, i) => {
        return (
          <Row>
            <Column colMD={5}>
              <Input
                id={`${id}_table1tab_color_text_${i}`}
                title={i === 0 ? translate('general.text') : ' '}
                onInputChanged={(value, error) => this.handleTable1SettingColors(i, 'text', value, error)}
                value={el.text.value}
                maxLength={32}
                required={i === 0 ? `${translate('general.required_field')}` : false}
                error={el.text.error}
                ref={el.ref}
                onBlur={() => this.setState(state => ({ table1Tab: { ...state.table1Tab, ...this.validateSettingColorTextTable1OnBlur(i) } }))}
              />
            </Column>
            <Column colMD={3}>
              <Combobox
                id={`${id}_table1tab_color_foreground_${i}`}
                title={i === 0 ? translate('general.foreground_hex') : ' '}
                value={el.foreground.value}
                error={el.foreground.error}
                items={this.colorItems()}
                onChange={(value, error) => this.handleTable1SettingColors(i, 'foreground', value, error)}
                lettercase={LetterCase.uppercase}
                regexValidation={/^$|#[0-9A-F]{6}$/}
                regexError={translate('general.invalid_hex_value')}
                maxLength={7}
                focusRef={el.refForeground}
              />
            </Column>
            <Column colMD={3}>
              <Combobox
                id={`${id}_table1tab_color_background_${i}`}
                title={i === 0 ? translate('general.background_hex') : ' '}
                value={el.background.value}
                error={el.background.error}
                items={this.colorItems()}
                onChange={(value, error) => this.handleTable1SettingColors(i, 'background', value, error)}
                lettercase={LetterCase.uppercase}
                regexValidation={/^$|#[0-9A-F]{6}$/}
                regexError={translate('general.invalid_hex_value')}
                maxLength={7}
                focusRef={el.refBackground}
              />
            </Column>
            <Column colMD={1}>
              <Button
                id={`${id}_table1button_deletecolor_${i}`}
                icon={'delete'}
                className={'bux_mt18 bux_align_right'}
                onClick={() => this.handleDeleteSettingTable1('settingColors', i)}
              />
            </Column>
          </Row>
        )
      })
    )
  }

  /**
   * @description Renders the table 1 tab
   */
  renderTable1Tab = () => {
    const { id } = this.props
    const { table1Tab } = this.state
    const addEntryDisabled = table1Tab.settingIndex === 0 ? table1Tab.settingColumns.length >= 8 : table1Tab.settingColors.length >= 4
    return (
      <>
        <Row>
          <Column colMD={3}>{translate('general.search')}</Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_table1tab_searchtext`}
              value={table1Tab.searchText.value}
              title={translate('general.search_text')}
              ref={this.searchText1Input}
              required={`${translate('general.required_field')}`}
              maxLength={64}
              onInputChanged={(value, error) => this.handleTable1Tab('searchText', value, error)}
              error={table1Tab.searchText.error}
              onBlur={() => this.setState(state => ({ table1Tab: { ...state.table1Tab, ...this.validateSearchText1() } }))}
            />
          </Column>
          <Column colMD={6}>
            <Switch
              id={`${id}_table1tab_searchcriteria`}
              title={translate('definition.view_profile_search_criteria')}
              items={this.translatedSearchCriteria()}
              maxPerRow={3}
              activeIndex={table1Tab.searchCriteriaIndex}
              onClick={index => this.handleTable1Tab('searchCriteriaIndex', index)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table1tab_frompage`}
              title={translate('general.from_page')}
              onChange={value => this.handleTable1Tab('fromPage', value)}
              value={table1Tab.fromPage}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table1tab_fromline`}
              title={translate('general.from_line')}
              onChange={value => this.handleTable1Tab('fromLine', value)}
              value={table1Tab.fromLine}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table1tab_fromcolumn`}
              title={translate('general.from_column')}
              onChange={value => this.handleTable1Tab('fromColumn', value)}
              value={table1Tab.fromColumn}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table1tab_topage`}
              title={translate('general.to_page')}
              onChange={value => this.handleTable1Tab('toPage', value)}
              value={table1Tab.toPage}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table1tab_toline`}
              title={translate('general.to_line')}
              onChange={value => this.handleTable1Tab('toLine', value)}
              value={table1Tab.toLine}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table1tab_tocolumn`}
              title={translate('general.to_column')}
              onChange={value => this.handleTable1Tab('toColumn', value)}
              value={table1Tab.toColumn}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
        </Row>
        <Row><Column colMD={12}><hr /></Column></Row>
        <Row>
          <Column colMD={3}>{translate('general.format')}</Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_table1tab_tableheader`}
              value={table1Tab.tableHeader}
              title={translate('general.table_header')}
              maxLength={80}
              onInputChanged={value => this.handleTable1Tab('tableHeader', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_table1tab_setting`}
              title={translate('general.settings')}
              items={[translate('general.columns'), translate('general.colors')]}
              activeIndex={table1Tab.settingIndex}
              onClick={index => this.handleTable1Tab('settingIndex', index)}
              errorIcons={this.handleErrorIconsTable1()}
            />
          </Column>
          <Column colMD={7}></Column>
          <Column colMD={1}>
            <Button
              id={`${id}_addsetting`}
              className={'bux_align_right'}
              title={' '}
              icon={'add'}
              tooltip={addEntryDisabled && (table1Tab.settingIndex === 0 ? translate('definition.document_maximum_columns_is_reached') : translate('definition.document_maximum_colors_is_reached'))}
              disabled={addEntryDisabled}
              onClick={table1Tab.settingIndex === 0 ? this.handleAddSettingColumnTable1 : this.handleAddSettingColorTable1}
            />
          </Column>
        </Row>
        {table1Tab.settingIndex === 0
          ? this.renderSettingColumnsTable1()
          : this.renderSettingColorsTable1()}
      </>
    )
  }

  /**
   * @description Handles the input changes.
   * @param {String} key The id the input.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleTable2Tab = (key, value, error) => {
    this.setState({
      table2Tab: {
        ...this.state.table2Tab,
        [key]: typeof this.state.table2Tab[key] === 'object'
          ? { value, error }
          : value
      }
    })
  }

  /**
   * @description Validates the search text in table 1 tab.
   * @returns {Object} Empty object if there is no error. Otherwise returns the new object with an error for the state.
   */
  validateSearchText2 = () => {
    if (this.state.table2Tab.searchText.value !== '') {
      return {}
    }
    return {
      searchText: {
        ...this.state.table2Tab.searchText,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description handles the add setting button and adds additional row for setting columns
   */
  handleAddSettingColumnTable2 = () => {
    if (this.state.table2Tab.settingColumns.length < 8) {
      this.setState({
        table2Tab: {
          ...this.state.table2Tab,
          settingColumns: [
            ...this.state.table2Tab.settingColumns,
            {
              identifier: {
                value: '',
                error: ''
              },
              width: 0,
              sColumn: 0,
              eColumn: 0,
              ref: React.createRef()
            },
          ]
        }
      })
    }
  }

  /**
   * @description handles the add setting button and adds additional row for setting colors
   */
  handleAddSettingColorTable2 = () => {
    if (this.state.table2Tab.settingColors.length < 4) {
      this.setState({
        table2Tab: {
          ...this.state.table2Tab,
          settingColors: [
            ...this.state.table2Tab.settingColors,
            {
              text: {
                value: '',
                error: ''
              },
              foreground: {
                value: '',
                error: ''
              },
              background: {
                value: '',
                error: ''
              },
              ref: React.createRef(),
              refForeground: React.createRef(),
              refBackground: React.createRef()
            }
          ]
        }
      })
    }
  }

  /**
   * @description Handles the inputs in the rendered setting columns rows
   * @param {Number} index
   * @param {String} key
   * @param {String} value
   * @param {String} error
   */
  handleTable2SettingColumns = (index, key, value, error) => {
    const newValue = this.state.table2Tab.settingColumns
    newValue[index][key] = typeof newValue[index][key] === 'object'
      ? { value, error }
      : value
    this.setState({
      table2Tab: {
        ...this.state.table2Tab,
        settingColumns: newValue
      }
    })
  }

  /**
   * @description Validates a single setting column
   * @param {Number} index
   */
  validateSettingColumnIdentifierTable2OnBlur = index => {
    let clone = this.state.table2Tab.settingColumns
    if (clone[index] && clone[index].identifier.value === '') {
      clone[index].identifier = {
        value: clone[index].identifier.value,
        error: translate('general.input_required')
      }
      return { settingColumns: clone }
    }
    return {}
  }

  /**
   * @description Validates the setting columns identifier
   * @param {Number} index
   */
  validateSettingColumnIdentifierTable2 = () => {
    let clone = this.state.table2Tab.settingColumns
    let error = false
    for (let i = 0; i < clone.length; i++) {
      if (clone[i].identifier.value === '') {
        clone[i].identifier = {
          value: clone[i].identifier.value,
          error: translate('general.input_required')
        }
        error = true
      }
    }
    if (error) {
      return { settingColumn: clone }
    }
    return {}
  }

  /**
   * @description Handles the inputs in the rendered setting color rows
   * @param {Number} index
   * @param {String} key
   * @param {String} value
   * @param {String} error
   */
  handleTable2SettingColors = (index, key, value, error) => {
    const newValue = this.state.table2Tab.settingColors
    newValue[index][key] = typeof newValue[index][key] === 'object'
      ? { value, error }
      : value
    this.setState({
      table2Tab: {
        ...this.state.table2Tab,
        settingColors: newValue
      }
    })
  }

  /**
   * @description Validates a single setting column
   * @param {Number} index
   */
  validateSettingColorTextTable2OnBlur = index => {
    let clone = this.state.table2Tab.settingColors
    if (clone[index] && clone[index].text.value === '') {
      clone[index].text = {
        value: clone[index].text.value,
        error: translate('general.input_required')
      }
      return { settingColor: clone }
    }
    return {}
  }

  /**
   * @description Validates the setting colors text
   * @param {Number} index
   */
  validateSettingColorTextTable2 = () => {
    let clone = this.state.table2Tab.settingColors
    let error = false
    for (let i = 0; i < clone.length; i++) {
      if (clone[i].text.value === '') {
        clone[i].text = {
          value: clone[i].text.value,
          error: translate('general.input_required')
        }
        error = true
      }
      if (!clone[i].foreground.value.match(/^$|#[0-9A-F]{6}$/)) {
        clone[i].foreground = {
          value: clone[i].foreground.value,
          error: translate('general.invalid_hex_value')
        }
        error = true
      }
      if (!clone[i].background.value.match(/^$|#[0-9A-F]{6}$/)) {
        clone[i].background = {
          value: clone[i].background.value,
          error: translate('general.invalid_hex_value')
        }
        error = true
      }
    }
    if (error) {
      return { settingColor: clone }
    }
    return {}
  }

  /**
   * @description Deletes the current setting row of tab table 1
   * @param {String} setting
   * @param {Number} index
   */
  handleDeleteSettingTable2 = (setting, index) => {
    this.setState({
      table2Tab: {
        ...this.state.table2Tab,
        [setting]: this.state.table2Tab[setting].filter((_, i) => i !== index)
      }
    })
  }

  /**
   * @description Validates the I. Table tab.
   * @returns {Boolean} False if there is an error in that tab.
   */
  validateTable2Tab = () => {
    const validatorResult = {
      ...this.validateSearchText2(),
      ...this.validateSettingColumnIdentifierTable2(),
      ...this.validateSettingColorTextTable2(),
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ table2Tab: { ...this.state.table2Tab, ...validatorResult } }, () => {
        this.handleFocusTable2()
      })
    }
    return errors === 0
  }

  handleFocusTable2 = () => {
    const { table2Tab: { searchText, settingColumns, settingColors } } = this.state
    const requiredInputs = [
      { inputRef: this.searchText2Input, error: searchText.error }
    ]

    if (settingColumns) {
      for (let i = 0; i < settingColumns.length; i++) {
        requiredInputs.push(
          { inputRef: settingColumns[i].ref, error: settingColumns[i].identifier.error }
        )
      }
    }

    if (settingColors) {
      for (let i = 0; i < settingColors.length; i++) {
        requiredInputs.push(
          { inputRef: settingColors[i].ref, error: settingColors[i].text.error }
        )
        requiredInputs.push(
          { inputRef: settingColors[i].refForeground, error: settingColors[i].foreground.error }
        )
        requiredInputs.push(
          { inputRef: settingColors[i].refBackground, error: settingColors[i].background.error }
        )
      }
    }

    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].error !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  /**
   * @description Renders the columns for setting columns
   */
  renderSettingColumnsTable2 = () => {
    const { id } = this.props
    const { table2Tab } = this.state
    return (
      table2Tab.settingColumns.map((el, i) => {
        return (
          <Row>
            <Column colMD={5}>
              <Input
                id={`${id}_table2tab_column_identifier_${i}`}
                title={i === 0 ? translate('general.identifier') : ' '}
                onInputChanged={(value, error) => this.handleTable2SettingColumns(i, 'identifier', value, error)}
                value={el.identifier.value}
                maxLength={32}
                required={i === 0 ? `${translate('general.required_field')}` : false}
                error={el.identifier.error}
                ref={el.ref}
                onBlur={() => this.setState(state => ({ table2Tab: { ...state.table2Tab, ...this.validateSettingColumnIdentifierTable2OnBlur(i) } }))}
              />
            </Column>
            <Column colMD={2}>
              <NumericSpinner
                id={`${id}_table2tab_column_width_${i}`}
                title={i === 0 ? translate('general.width') : ' '}
                onChange={value => this.handleTable2SettingColumns(i, 'width', value)}
                value={el.width}
                min={0}
                max={999}
                steps={1}
              />
            </Column>
            <Column colMD={2}>
              <NumericSpinner
                id={`${id}_table2tab_column_scolumn_${i}`}
                title={i === 0 ? translate('general.s_column') : ' '}
                onChange={value => this.handleTable2SettingColumns(i, 'sColumn', value)}
                value={el.sColumn}
                min={0}
                max={999}
                steps={1}
              />
            </Column>
            <Column colMD={2}>
              <NumericSpinner
                id={`${id}_table2tab_column_ecolumn_${i}`}
                title={i === 0 ? translate('general.e_column') : ' '}
                onChange={value => this.handleTable2SettingColumns(i, 'eColumn', value)}
                value={el.eColumn}
                min={0}
                max={999}
                steps={1}
              />
            </Column>
            <Column colMD={1}>
              <Button
                id={`${id}_table2button_deletecolumn_${i}`}
                icon={'delete'}
                className={'bux_mt18 bux_align_right'}
                onClick={() => this.handleDeleteSettingTable2('settingColumns', i)}
              />
            </Column>
          </Row>
        )
      })
    )
  }

  /**
   * @description Renders the columns for setting colors
   */
  renderSettingColorsTable2 = () => {
    const { id } = this.props
    const { table2Tab } = this.state
    return (
      table2Tab.settingColors.map((el, i) => {
        return (
          <Row>
            <Column colMD={5}>
              <Input
                id={`${id}_table2tab_color_text_${i}`}
                title={i === 0 ? translate('general.text') : ' '}
                onInputChanged={(value, error) => this.handleTable2SettingColors(i, 'text', value, error)}
                value={el.text.value}
                maxLength={32}
                required={i === 0 ? `${translate('general.required_field')}` : false}
                error={el.text.error}
                ref={el.ref}
                onBlur={() => this.setState(state => ({ table2Tab: { ...state.table2Tab, ...this.validateSettingColorTextTable2OnBlur(i) } }))}
              />
            </Column>
            <Column colMD={3}>
              <Combobox
                id={`${id}_table2tab_color_foreground_${i}`}
                title={i === 0 ? translate('general.foreground_hex') : ' '}
                focusRef={el.refForeground}
                value={el.foreground.value}
                error={el.foreground.error}
                items={this.colorItems()}
                onChange={(value, error) => this.handleTable2SettingColors(i, 'foreground', value, error)}
                lettercase={LetterCase.uppercase}
                regexValidation={/^$|#[0-9A-F]{6}$/}
                regexError={translate('general.invalid_hex_value')}
                maxLength={7}
              />
            </Column>
            <Column colMD={3}>
              <Combobox
                id={`${id}_table2tab_color_background_${i}`}
                title={i === 0 ? translate('general.background_hex') : ' '}
                focusRef={el.refBackground}
                value={el.background.value}
                error={el.background.error}
                items={this.colorItems()}
                onChange={(value, error) => this.handleTable2SettingColors(i, 'background', value, error)}
                lettercase={LetterCase.uppercase}
                regexValidation={/^$|#[0-9A-F]{6}$/}
                regexError={translate('general.invalid_hex_value')}
                maxLength={7}
              />
            </Column>
            <Column colMD={1}>
              <Button
                id={`${id}_table2button_deletecolor_${i}`}
                icon={'delete'}
                className={'bux_mt18 bux_align_right'}
                onClick={() => this.handleDeleteSettingTable2('settingColors', i)}
              />
            </Column>
          </Row>
        )
      })
    )
  }

  /**
   * @description Renders the II. Table tab
   */
  renderTable2Tab = () => {
    const { id } = this.props
    const { table2Tab } = this.state
    const addEntryDisabled = table2Tab.settingIndex === 0 ? table2Tab.settingColumns.length >= 8 : table2Tab.settingColors.length >= 4
    return (
      <>
        <Row>
          <Column colMD={3}>{translate('general.search')}</Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_table2tab_searchtext`}
              value={table2Tab.searchText.value}
              title={translate('general.search_text')}
              ref={this.searchText2Input}
              required={`${translate('general.required_field')}`}
              maxLength={64}
              onInputChanged={(value, error) => this.handleTable2Tab('searchText', value, error)}
              error={table2Tab.searchText.error}
              onBlur={() => this.setState({ table2Tab: { ...this.state.table2Tab, ...this.validateSearchText2() } })}
            />
          </Column>
          <Column colMD={6}>
            <Switch
              id={`${id}_table2tab_searchcriteria`}
              title={translate('definition.view_profile_search_criteria')}
              items={[
                translate('general.nothing'),
                translate('definition.view_profile_use_text_as_search_pattern'),
                translate('general.case_sensitive')
              ]}
              maxPerRow={3}
              activeIndex={table2Tab.searchCriteriaIndex}
              onClick={index => this.handleTable2Tab('searchCriteriaIndex', index)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table2tab_frompage`}
              title={translate('general.from_page')}
              onChange={value => this.handleTable2Tab('fromPage', value)}
              value={table2Tab.fromPage}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table2tab_fromline`}
              title={translate('general.from_line')}
              onChange={value => this.handleTable2Tab('fromLine', value)}
              value={table2Tab.fromLine}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table2tab_fromcolumn`}
              title={translate('general.from_column')}
              onChange={value => this.handleTable2Tab('fromColumn', value)}
              value={table2Tab.fromColumn}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table2tab_topage`}
              title={translate('general.to_page')}
              onChange={value => this.handleTable2Tab('toPage', value)}
              value={table2Tab.toPage}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table2tab_toline`}
              title={translate('general.to_line')}
              onChange={value => this.handleTable2Tab('toLine', value)}
              value={table2Tab.toLine}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_table2tab_tocolumn`}
              title={translate('general.to_column')}
              onChange={value => this.handleTable2Tab('toColumn', value)}
              value={table2Tab.toColumn}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
        </Row>
        <Row><Column colMD={12}><hr /></Column></Row>
        <Row>
          <Column colMD={3}>{translate('general.format')}</Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_table2tab_tableheader`}
              value={table2Tab.tableHeader}
              title={translate('general.table_header')}
              maxLength={80}
              onInputChanged={value => this.handleTable2Tab('tableHeader', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_table2tab_setting`}
              title={translate('general.settings')}
              items={[translate('general.columns'), translate('general.colors')]}
              activeIndex={table2Tab.settingIndex}
              onClick={index => this.handleTable2Tab('settingIndex', index)}
              errorIcons={this.handleErrorIconsTable2()}
            />
          </Column>
          <Column colMD={7}></Column>
          <Column colMD={1}>
            <Button
              id={`${id}_addsetting`}
              className={'bux_align_right'}
              title={' '}
              icon={'add'}
              tooltip={addEntryDisabled && (table2Tab.settingIndex === 0 ? translate('definition.document_maximum_columns_is_reached') : translate('definition.document_maximum_colors_is_reached'))}
              disabled={addEntryDisabled}
              onClick={table2Tab.settingIndex === 0 ? this.handleAddSettingColumnTable2 : this.handleAddSettingColorTable2}
            />
          </Column>
        </Row>
        {table2Tab.settingIndex === 0
          ? this.renderSettingColumnsTable2()
          : this.renderSettingColorsTable2()}
      </>
    )
  }

  /**
   * @description Handles the save button
   */
  handleSave = () => {
    const { generalTab, table1Tab, table2Tab } = this.state
    const { createViewProfile, onClose } = this.props
    const errorTabs = [
      this.validateGeneralTab(),
      this.validateTable1Tab(),
      this.validateTable2Tab()
    ]
    if (errorTabs.every(d => d)) {
      const viewProfileObj = {
        'PBDNAME': generalTab.viewProfileID.value,
        'SRCJOBI': generalTab.docType.value,
        'PBDTITLE': generalTab.title,
        'OWNER': generalTab.owner,
        'PBDMODE': generalTab.testMode ? 'TEST' : 'PROD',
        'PBDVUSER': generalTab.testMode ? generalTab.testUser : '',
        'VDATEB': DateUtils.getTimeshiftDate(generalTab.startDate.value, '', DateUtils.DDMMYYYY_DOT),
        'VDATEE': DateUtils.getTimeshiftDate(generalTab.endDate.value, '', DateUtils.DDMMYYYY_DOT),
        'FORM': generalTab.form,
        'EXT': generalTab.extension,
        'REPORT': generalTab.report,
        'PBDT1H': table1Tab.tableHeader,
        'PBDT1C': table1Tab.searchText.value,
        'PBDT1SM': DefinitionUtils.viewProfileSearchCriteria[table1Tab.searchCriteriaIndex].key,
        'PBDT1FP': table1Tab.fromPage,
        'PBDT1FL': table1Tab.fromLine,
        'PBDT1FC': table1Tab.fromColumn,
        'PBDT1TP': table1Tab.toPage,
        'PBDT1TL': table1Tab.toLine,
        'PBDT1TC': table1Tab.toColumn,
        'PBDT2H': table2Tab.tableHeader,
        'PBDT2C': table2Tab.searchText.value,
        'PBDT2SM': DefinitionUtils.viewProfileSearchCriteria[table2Tab.searchCriteriaIndex].key,
        'PBDT2FP': table2Tab.fromPage,
        'PBDT2FL': table2Tab.fromLine,
        'PBDT2FC': table2Tab.fromColumn,
        'PBDT2TP': table2Tab.toPage,
        'PBDT2TL': table2Tab.toLine,
        'PBDT2TC': table2Tab.toColumn
      }
      for (let i = 0; i < table1Tab.settingColumns.length; i++) {
        viewProfileObj[`PBDT10${i + 1}H`] = table1Tab.settingColumns[i].identifier.value
        viewProfileObj[`PBDT10${i + 1}W`] = table1Tab.settingColumns[i].width
        viewProfileObj[`PBDT10${i + 1}S`] = table1Tab.settingColumns[i].sColumn
        viewProfileObj[`PBDT10${i + 1}E`] = table1Tab.settingColumns[i].eColumn
      }
      for (let i = 0; i < table1Tab.settingColors.length; i++) {
        viewProfileObj[`PBDT1M${i + 1}T`] = table1Tab.settingColors[i].text.value
        viewProfileObj[`PBDT1M${i + 1}F`] = table1Tab.settingColors[i].foreground.value
        viewProfileObj[`PBDT1M${i + 1}B`] = table1Tab.settingColors[i].background.value
      }
      for (let i = 0; i < table2Tab.settingColumns.length; i++) {
        viewProfileObj[`PBDT20${i + 1}H`] = table2Tab.settingColumns[i].identifier.value
        viewProfileObj[`PBDT20${i + 1}W`] = table2Tab.settingColumns[i].width
        viewProfileObj[`PBDT20${i + 1}S`] = table2Tab.settingColumns[i].sColumn
        viewProfileObj[`PBDT20${i + 1}E`] = table2Tab.settingColumns[i].eColumn
      }
      for (let i = 0; i < table2Tab.settingColors.length; i++) {
        viewProfileObj[`PBDT2M${i + 1}T`] = table2Tab.settingColors[i].text.value
        viewProfileObj[`PBDT2M${i + 1}F`] = table2Tab.settingColors[i].foreground.value
        viewProfileObj[`PBDT2M${i + 1}B`] = table2Tab.settingColors[i].background.value
      }
      createViewProfile(viewProfileObj, () => onClose())
    }
  }

  /**
   * @description Checks for errors in the dynamic setting fields
   * @param {String} setting
   * @param {String} field
   */
  isSettingError = (setting, field) => {
    if (setting.length === 0) {
      return false
    }
    for (let i = 0; i < setting.length; i++) {
      if (setting[i][field].error !== '') {
        return true
      }
    }
  }

  /**
   * @description Gets the error tabs as an array of numbers.
   * @returns {Array} The error tabs as an array of numbers.
   */
  handleErrorTabs = () => {
    const { generalTab, table1Tab, table2Tab } = this.state
    const buffer = []
    const IDError = generalTab.viewProfileID.error !== ''
    const isDocTypeError = generalTab.docType.error !== ''
    const startDateError = generalTab.startDate.error !== ''
    const endDateError = generalTab.endDate.error !== ''
    const searchText1 = table1Tab.searchText.error !== ''
    const searchText2 = table2Tab.searchText.error !== ''

    if (IDError || isDocTypeError || startDateError || endDateError) {
      buffer.push(0)
    }

    if (searchText1 || this.isSettingError(table1Tab.settingColumns, 'identifier') || this.isSettingError(table1Tab.settingColors, 'text') ||
      this.isSettingError(table1Tab.settingColors, 'foreground') || this.isSettingError(table1Tab.settingColors, 'background')) {
      buffer.push(1)
    }

    if (searchText2 || this.isSettingError(table2Tab.settingColumns, 'identifier') || this.isSettingError(table2Tab.settingColors, 'text') ||
      this.isSettingError(table2Tab.settingColors, 'foreground') || this.isSettingError(table2Tab.settingColors, 'background')) {
      buffer.push(2)
    }

    return buffer
  }

  /**
   * @description Handles error icons in switchable button
   */
  handleErrorIconsTable1 = () => {
    const { table1Tab } = this.state
    const buffer = []

    if (this.isSettingError(table1Tab.settingColumns, 'identifier')) {
      buffer.push(0)
    }
    if (this.isSettingError(table1Tab.settingColors, 'text') || this.isSettingError(table1Tab.settingColors, 'foreground') ||
      this.isSettingError(table1Tab.settingColors, 'background')) {
      buffer.push(1)
    }
    return buffer
  }

  /**
   * @description Handles error icons in switchable button
   */
  handleErrorIconsTable2 = () => {
    const { table2Tab } = this.state
    const buffer = []

    if (this.isSettingError(table2Tab.settingColumns, 'identifier')) {
      buffer.push(0)
    }
    if (this.isSettingError(table2Tab.settingColors, 'text') || this.isSettingError(table2Tab.settingColors, 'foreground') ||
      this.isSettingError(table2Tab.settingColors, 'background')) {
      buffer.push(1)
    }
    return buffer
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={'copy_view_profile'}
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={translate('definition.copy_view_profile')}
          onClose={onClose}
        />
        <Main id={id}>
          {this.renderSelectorDialogs()}
          <Tabs
            id={id}
            errorTabs={this.handleErrorTabs()}>
            <Tab title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('definition.view_profile_table_1')}>
              {this.renderTable1Tab()}
            </Tab>
            <Tab title={translate('definition.view_profile_table_2')}>
              {this.renderTable2Tab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences.DATEMASK,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    selector: state.selector,
    viewProfile: state.definitions.viewprofiles.viewProfile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    createViewProfile: (viewProfileObj, callback) => {
      createViewProfile(viewProfileObj, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyViewProfileDialog)