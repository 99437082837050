import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import hash from 'object-hash';

// Components
import HierarchyHeader from './HierarchyHeader';
import HierarchyMenu from './HierarchyMenu';
import HierarchyRow from './HierarchyRow';

// Style
import './DataHierarchy.scss';

// no childs const to reduce duplicate strings and avoid spellings bugs
const NO_CHILDS = 'NO_CHILDS';

export default class DataHierarchy extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,

    /** Header which are displayed in header of DataHierarchy component.*/
    header: PropTypes.arrayOf(PropTypes.string).isRequired,

    /**
     * Data which is displayed in DataHierarchy component.
     * This data array just stores the root level of the hierarchy.
     * */
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    /**
     * Stores the child entries. Each array entry stores all child information for a specific level.
     *
     * It needs to have following structure:
     *
     * ```
     * hierarchyEntries = [
     *   {
     *     parentKey: string,
     *     rootKey: string,
     *     level: number,
     *     data: [
     *        [ your data ],
     *        [ your data ]
     *      ]
     *   },
     *   {
     *     parentKey: string,
     *     rootKey: string,
     *     level: number,
     *     data: [ [ your data ] ]
     *    },
     *   .
     *   .
     *   .
     * ]
     * ```
     */
    hierarchyEntries: PropTypes.arrayOf(PropTypes.object).isRequired,

    /**
     * Will be executed when the click on a row is detected and no childs are still visible.
     * Allows to generate new entries based on the one clicked one.
     * @param {string} parentKey Parent data hash key
     * @param {string} rootKey Root data hash key
     * @param {function} hash Provided hash function
     * @param {number} level Level of the hierarchy depth
     * @param {array<array<string>} data Array of rows
     */
    onOpenChilds: PropTypes.func.isRequired,

    /**
     * Will be executed when the click on a row is detected and childs are still visible.
     * @param {array} newHierarchyEntries Provide updated structure of hierarchyEntries as a array of visible entries after closing one of them.
     */
    onCloseChilds: PropTypes.func.isRequired,

    /** Types of each column to sort correctly. */
    columnSortDefs: PropTypes.arrayOf(PropTypes.string).isRequired,

    /** Used to display the numbers in the right language format (e.g. 130.000 or 130,000 or 130 000). */
    language: PropTypes.oneOf(['en', 'de', 'fr']),

    /**
     * Function to translate following keys by our own.
     *
     * - `general.entries` => will be displayed in hierarchy menu to display how much entries are rendered
     * - `general.selected` => will be displayed when select one entry
     * - `general.no_data_found` => will be displayed when a hierarchy entry is opened but has no childs
     * - `general.no_matching_records` => will be displayed when filter and no entry matches
     * - `table.download_as_csv` => will be displayed when entries are selected to download
     *
     * @param {string} key
     */
    translate: PropTypes.func.isRequired,

    /** Used to display the date entries in the correct format. */
    datemask: PropTypes.string,

    /** Checks, if the 'close all open entries'-icon is available. */
    isHierarchyOpen: PropTypes.bool,

    /** Used when click on 'close all open entries'-icon. */
    closeHierarchy: PropTypes.func,

    /** Used when each row of the DataHierarchy component should be selectable for e.g. download option. */
    selectable: PropTypes.bool,

    /** Function which returns array of JSX elements which are displayed in the menu. */
    additionalInteraction: PropTypes.func,

    /**
     * Function that returns array of JSX elements which are displayed at end of each row.
     *
     * @param {number} rowIndex index of the row
     * @return {array<element>} elements
     */
    createActionButtons: PropTypes.func,

    /** Length of the created action buttons.*/
    actionButtonsLength: PropTypes.number,

    /** Array of selected rows */
    selectedRows: PropTypes.any,

    /** Items which should be display in dropdown in the hierarchy menu, after selecting rows. */
    downloadItems: PropTypes.arrayOf(PropTypes.string),

    /**
     * It works only when is connected with relationToIdColumnHeaderName
     * It gives possiblity to check if the parent has any child
     * It works only when we fetch whole data stored in table,
     * so it won't work for fetches in parts
     */
    idColumnHeaderName: PropTypes.string,

    /**
     * It works only when is connected with idColumnHeaderName
     * It gives possiblity to check if the parent has any child
     * It works only when we fetch whole data stored in table,
     * so it won't work for fetches in parts
     */
    relationToIdColumnHeaderName: PropTypes.string,

    /**
     * Set this option to true if in the first view (without expanded children)
     * should be only parents. The default option renders children next to parents too.
     */
    skipChildrenInFirstView: PropTypes.bool,

    /**
     * Pass here headers which are not visible right now. It works with
     * idColumnHeaderName and relationToIdColumnHeaderName
     */
    invisibleHeaders: PropTypes.arrayOf(PropTypes.string),

    /**
     * Set this option to true if onOpenChild should be passed whole
     * row without deleted headers. It works with invisibleHeaders
     */
    passWholeRowOnOpenChild: PropTypes.bool,
  };

  state = {
    modData: [],
    modDataWithAllHeaders: [],
    checkedRows: [],
    headersToDisplay: [],
    rowHeight: 40,
    headerHeight: 41,
    sortedCol: undefined,
    downloadActionIndex: 0,
    showStart: undefined,
    showEnd: undefined,
    hierarchyHeight: 0,
    isScrolling: false,
    lastWasClicked: false,
  };

  /**
   * @description Combines the data and child information when the component will be renderd the first time.
   */
  componentWillMount = () => {
    this.updateData(this.props.data, this.props.hierarchyEntries);
  };

  /**
   * @description Added the resize and scroll event listener.
   */
  componentDidMount = () => {
    const { id } = this.props;
    const { rowHeight } = this.state;
    window.addEventListener('resize', this.onResize);
    const hierarchyContainer = document.querySelector(
      `#${id}_datahierarchy_container`
    );
    if (hierarchyContainer) {
      requestAnimationFrame(() => {
        hierarchyContainer.addEventListener('scroll', this.onScroll);
        const padiHierarchy = document.querySelector(`#${id}_padihierarchy`);
        const height =
          Math.floor(padiHierarchy.getBoundingClientRect().height / rowHeight) *
            rowHeight +
          2;
        hierarchyContainer.style.height = height + 'px';
        this.setState({
          showStart: hierarchyContainer.scrollTop,
          showEnd: height + hierarchyContainer.scrollTop,
        });
      });
    }
  };

  /**
   * @description Removes the resize and scroll event listener.
   */
  componentWillUnmount = () => {
    const { id } = this.props;
    const el = document.querySelector(`#${id}_datahierarchy_container`);
    if (el) {
      el.removeEventListener('scroll', this.onScroll);
    }
    window.removeEventListener('resize', this.onResize);
  };

  /**
   * @description Combines the data and child information when the component will receive new properties.
   * @param nextProps The next properties which the component will receive.
   */
  componentWillReceiveProps = (nextProps) => {
    this.updateData(nextProps.data, nextProps.hierarchyEntries);
  };

  /**
   * @description Sets the background colors for the different rows, is needed because simple css solution do not exist for our special requirements.
   * @param prevProps The old properties from the component before updated.
   */
  componentDidUpdate = (prevProps) => {
    if(prevProps.invisibleHeaders.length !== this.props.invisibleHeaders.length) {
      this.updateData(prevProps.data, prevProps.hierarchyEntries);
    }
    const { id, hierarchyEntries } = this.props;
    const { modData, showStart, rowHeight, lastWasClicked } = this.state;
    // Get the value of the row which is started to show, this value is changed when scroll the hierarchy.
    let startRow = Math.floor(showStart / rowHeight);
    const allRows = Array.from(
      document.querySelectorAll(`#${id}_datahierarchy tbody tr`)
    );
    const rowColors = [];
    let innerCounter = 0;
    modData.forEach((d) => {
      if (d instanceof Object && !Array.isArray(d) && d.data === NO_CHILDS) {
        if ((innerCounter - 1) % 2 === 0) {
          rowColors.push('bux_even_row');
        } else {
          rowColors.push('bux_odd_row');
        }
      } else {
        if (innerCounter % 2 === 0) {
          rowColors.push('bux_even_row');
          innerCounter++;
        } else {
          rowColors.push('bux_odd_row');
          innerCounter++;
        }
      }
    });
    allRows.forEach((row, i) => {
      row.classList.remove('bux_even_row', 'bux_odd_row');
      row.classList.add(rowColors[i + startRow]);
    });
    // Detects if the last row was clicked and new childs are rendered so the container needs to scroll down, to display the new child.
    const hierarchyEntriesExist =
      hierarchyEntries && prevProps.hierarchyEntries;
    if (
      lastWasClicked &&
      hierarchyEntriesExist &&
      hierarchyEntries.length > prevProps.hierarchyEntries.length
    ) {
      this.autoScroll();
      this.setState({ lastWasClicked: false });
    }
  };

  /**
   * @description Returns arry where are only parents
   */
  getDataWithoutChildren = () => {
    // It requires the relation column to works, to check if this is the parent or child
    if (!this.props.relationToIdColumnHeaderName) {
      return [...this.props.data];
    }

    const relationToIdHeaderIndex = this.props.header.findIndex(
      (header) => header === this.props.relationToIdColumnHeaderName
    );

    if (relationToIdHeaderIndex === -1) {
      return [...this.props.data];
    }

    const parents = this.props.data.filter(
      (row) => !row[relationToIdHeaderIndex]
    );
    return parents;
  };

  /**
   * @description Loops through hierarchyEntries, searchs for the parent element in hierarchyData and append the child data to it.
   * @param {Array.<Array>} data The root level data.
   * @param {Array.<Object>} hierarchyEntries All child information.
   * @param {bool} sortData Flag if the data should be sorted after combined it.
   */
  updateData = (data, hierarchyEntries, sortData = true) => {
    const { sortedCol } = this.state;
    let dataToUpdate = this.props.skipChildrenInFirstView
      ? this.getDataWithoutChildren()
      : [...data.map((d) => [...d])];
    const dataToUpdateWithAllHeaders = [...dataToUpdate.map((d) => [...d])];
    if (this.props.invisibleHeaders) {
      dataToUpdate = this.getDataToDisplay(dataToUpdate);
    }
    hierarchyEntries &&
      hierarchyEntries.forEach((el) => {
        let rootKey = hash(dataToUpdate[0]);
        for (let i = 0; i < dataToUpdate.length; i++) {
          let dataToSearchThrough = [dataToUpdate[i]];
          // if data is still child, extract childinformation from child object
          if (
            dataToUpdate[i] instanceof Object &&
            !Array.isArray(dataToUpdate[i])
          ) {
            dataToSearchThrough = [dataToUpdate[i].data];
          } else {
            // Update current rootkey which is used to check where the child needs to be appended
            rootKey = hash(dataToUpdate[i]);
          }

          let found = false;
          // eslint-disable-next-line
          Array.isArray(dataToSearchThrough) &&
            dataToSearchThrough.forEach((d) => {
              const parentKey = hash(d);
              if (parentKey === el.parentKey && rootKey === el.rootKey) {
                found = true;
                // Make sure the childData is always an array.
                const childData = Array.isArray(el.data)
                  ? [...el.data].map((d) => [...d])
                  : [el.data];
                childData.forEach((d, childIndex) => {
                  let childToAdd = { level: el.level, data: d };

                  dataToUpdateWithAllHeaders.splice(i + childIndex + 1, 0, {
                    level: el.level,
                    data: [...d],
                  });
                  if (this.props.invisibleHeaders) {
                    childToAdd = this.getDataToDisplay(childToAdd, true);
                  }
                  // Insert the child data at a specific position.
                  dataToUpdate.splice(i + childIndex + 1, 0, childToAdd);
                });
              }
            });
          if (found) {
            break;
          }
        }
      });
    this.setState(
      {
        modData: dataToUpdate,
        modDataWithAllHeaders: dataToUpdateWithAllHeaders,
      },
      () => {
        this.updateCheckedRows();
        if (sortData && sortedCol !== undefined) {
          this.setState(
            {
              sortedCol: {
                [Object.keys(sortedCol)[0]]:
                  !sortedCol[Object.keys(sortedCol)[0]],
              },
            },
            () => {
              this.onSort(dataToUpdate, Object.keys(this.state.sortedCol)[0]);
            }
          );
        }
      }
    );
  };

  /**
   * @description Proofs if all checked rows are still available. Is needed when checked rows are closed, so the checked rows are updated.
   */
  updateCheckedRows = () => {
    const { checkedRows, modData } = this.state;
    const elementsToDelete = [];
    checkedRows.forEach((row) => {
      let found = false;
      for (let i = 0; i < modData.length; i++) {
        let dataToHash = modData[i];
        if (this.isChild(modData[i])) {
          if (modData[i].data !== NO_CHILDS) {
            dataToHash = modData[i].data;
            if (hash(dataToHash) === row) {
              found = true;
              break;
            }
          }
        } else {
          if (hash(modData[i]) === row) {
            found = true;
            break;
          }
        }
      }
      if (!found) {
        elementsToDelete.push(row);
      }
    });
    let buffer = checkedRows;
    elementsToDelete.forEach((el) => {
      buffer.splice(checkedRows.indexOf(el), 1);
    });
    this.setState({ checkedRows: buffer });
  };

  /**
   * @description Makes new calculation for the hierarchy height and all displayed rows.
   */
  onResize = () => {
    const { id } = this.props;
    const { rowHeight } = this.state;
    const el = document.querySelector(`#${id}_datahierarchy_container`);
    const el2 = document.querySelector(`#${id}_padihierarchy`);
    el.style.height =
      Math.floor(el2.getBoundingClientRect().height / rowHeight) * rowHeight +
      1 +
      'px';
    this.setState({
      showStart: el.scrollTop,
      showEnd: el.clientHeight + el.scrollTop,
    });
  };

  /**
   * @description Makes the calculation which data entries are shown.
   */
  onScroll = () => {
    const { id } = this.props;
    const el = document.querySelector(`#${id}_datahierarchy_container`);
    this.setState(
      { showStart: el.scrollTop, showEnd: el.clientHeight + el.scrollTop },
      () => {
        const el2 = document.querySelector(`#${id}_datahierarchy`);
        el2.classList.add('remove_pointer_events');
        const allCells = Array.from(
          document.querySelectorAll(`#${id}_datahierarchy tbody tr td`)
        );
        allCells.forEach((row) => {
          row.classList.remove('bux_fake_hover');
        });
        clearTimeout(this.isScrolling);
        this.isScrolling = setTimeout(
          () => el2.classList.remove('remove_pointer_events'),
          150
        );
      }
    );
  };

  /**
   * @description Scrolls automatically one entry down when the last entry was clicked so the new entry is visible.
   */
  autoScroll = () => {
    const el = document.querySelector(
      `#${this.props.id}_datahierarchy_container`
    );
    el.scrollTo({ top: el.scrollTop + 40, left: 0, behavior: 'smooth' });
  };

  /**
   * @description Returns if a specific row is checked.
   * @returns {bool}
   */
  isRowChecked = (rowHash) => {
    return this.state.checkedRows.includes(rowHash);
  };

  /**
   * @description Checks/unchecks a specific row.
   * @param {string} rowHash The unique key of the clicked row.
   */
  handleCheck = (rowHash) => {
    const { checkedRows } = this.state;
    if (checkedRows.includes(rowHash)) {
      this.setState({ checkedRows: checkedRows.filter((d) => d !== rowHash) });
    } else {
      this.setState({ checkedRows: [...checkedRows, rowHash] });
    }
  };

  /**
   * @description Returns if all checkboxes are selected.
   * @returns {bool}
   */
  checkAllCheckboxesSelected = () => {
    const { modData, checkedRows } = this.state;
    let rowsToCheck = 0;
    modData.forEach((d) => {
      if (this.isChild(d)) {
        if (d.data !== NO_CHILDS) {
          rowsToCheck++;
        }
      } else {
        rowsToCheck++;
      }
    });
    return checkedRows.length === rowsToCheck;
  };

  /**
   * @description Checks/undchecks all rows.
   */
  handleCheckAll = () => {
    const { modData } = this.state;
    const buffer = [];
    if (!this.checkAllCheckboxesSelected()) {
      modData.forEach((d) => {
        let dataToHash = d;
        if (this.isChild(d)) {
          if (d.data !== NO_CHILDS) {
            dataToHash = d.data;
            buffer.push(hash(dataToHash));
          }
        } else {
          buffer.push(hash(dataToHash));
        }
      });
    }
    this.setState({ checkedRows: buffer });
  };

  /**
   * @description Checks if the arrays are equal.
   * @param {Array} arr1 The first array to check.
   * @param {Array} arr2 The second array to check.
   * @param {Array} types The types of the values in the arrays. (columnsortdef)
   * @returns {Boolean} True if the arrays are equal.
   */
  areArraysEqual = (arr1, arr2, types) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    let resultRows = 0;
    for (let i = 0; i < arr1.length; i++) {
      let resultCols = 0;
      for (let j = 0; j < arr1[i].length; j++) {
        if (types[j] === 'progressbar') {
          if (arr1[i][j].props.value === arr2[i][j].props.value) {
            resultCols++;
          }
        } else if (types[j] === 'icon') {
          if (arr1[i][j].props.name === arr2[i][j].props.name) {
            resultCols++;
          }
        } else {
          if (arr1[i][j] === arr2[i][j]) {
            resultCols++;
          }
        }
      }
      if (resultCols === arr1[i].length) {
        resultRows++;
      }
    }
    if (resultRows === arr1.length) {
      return true;
    }
    return false;
  };

  /**
   * @description Gets a date object based on the given datetime string.
   * @param {String} datetime The date time string. The format of the datetime is the datemask from the redux store + " HH:mm".
   */
  getDateObject = (datetime) => {
    const { datemask } = this.props;
    const TIME_DATEMASK = 'H:mm';
    const DEFAULT_MASK = 'DD.MM.YYYY';
    // init with default datemask if datemask is not set
    const currentDatemask = datemask === undefined ? DEFAULT_MASK : datemask;
    // format date time string to a date object
    return moment(datetime, `${currentDatemask} ${TIME_DATEMASK}`, true);
  };

  /**
   * @description Sorts the data for a specific row.
   * @param {Array.<Array>} data The data which should be sorted.
   * @param {number} col The column which is used to sort.
   */
  onSort = (data, col) => {
    let buffer = [...data.filter((d) => Array.isArray(d))];
    this.onSortColumn(buffer, col);
    this.setState(
      {
        sortedCol: {
          [col]: !this.state.sortedCol || !this.state.sortedCol[col],
        },
      },
      () => this.updateData(buffer, this.props.hierarchyEntries, false)
    );
  };

  /**
   * @description Sorts the data in a given column.
   * @param {Array.<Array>} data The data which should be sorted.
   * @param {Number} col Column which should be sorted.
   */
  onSortColumn = (data, col) => {
    const { columnSortDefs, datemask } = this.props;
    const { sortedCol } = this.state;
    data.sort((a, b) => {
      if (
        columnSortDefs[col] !== 'date-time' &&
        columnSortDefs[col] !== 'date'
      ) {
        if (columnSortDefs[col] === 'icon') {
          a = a[col].props.name;
          b = b[col].props.name;
        } else if (columnSortDefs[col] === 'number') {
          a = parseInt(a[col]);
          b = parseInt(b[col]);
        } else {
          a = a[col].toLowerCase();
          b = b[col].toLowerCase();
        }
        if (sortedCol && sortedCol[col]) {
          return a > b ? -1 : b > a ? 1 : 0;
        } else {
          return a > b ? 1 : b > a ? -1 : 0;
        }
      } else {
        // convert date strings to objects
        if (columnSortDefs[col] === 'date') {
          a = moment(a[col], datemask);
          b = moment(b[col], datemask);
        } else {
          a = this.getDateObject(a[col]);
          b = this.getDateObject(b[col]);
          if (a.isValid() && !b.isValid()) {
            return 1;
          } else if (!a.isValid() && b.isValid()) {
            return -1;
          }
        }
        // compare dates
        if (sortedCol && sortedCol[col]) {
          return a.isAfter(b) ? -1 : a.isBefore(b) ? 1 : 0;
        } else {
          return a.isAfter(b) ? 1 : a.isBefore(b) ? -1 : 0;
        }
      }
    });
  };

  /**
   * @description Handles the change of the download dropdown.
   * @param {Number} index Index of the changed dropdown item.
   */
  handleChangeDownloadAction = (index) => {
    this.setState({ downloadActionIndex: index });
  };

  /**
   * @description Returns if the hierarchy is scrollable.
   * @returns {bool}
   */
  isVerticalScrollable = () => {
    const { id } = this.props;
    const el = document.querySelector(`#${id}_datahierarchy_container`);
    return el && el.scrollHeight > el.clientHeight;
  };

  /**
   * @description Returns if the hierarchy is scrollable.
   * @returns {bool}
   */
  isHorizontalScrollable = () => {
    const { id } = this.props;
    const el = document.querySelector(`#${id}_datahierarchy_container`);
    return el && el.scrolWidth > el.clientWidth;
  };

  /**
   * @description Returns if an element is an child.
   * @return {bool}
   */
  isChild = (el) => {
    return el instanceof Object && !Array.isArray(el);
  };

  /**
   * @description Will be executed, when the last displayed row was clicked.
   */
  detectLastRowClick = () => {
    this.setState({ lastWasClicked: true });
  };

  /**
   * @description Check if the row should be expandable
   * @returns {bool}
   */
  shouldHandleActionClick = (rowData, indexInModData) => {
    const { modDataWithAllHeaders } = this.state;
    const { data, header, idColumnHeaderName, relationToIdColumnHeaderName } =
      this.props;

    if (!idColumnHeaderName || !relationToIdColumnHeaderName) {
      return true;
    }

    const idHeaderIndex = header.findIndex(
      (header) => header === idColumnHeaderName
    );
    const relationToIdHeaderIndex = header.findIndex(
      (header) => header === relationToIdColumnHeaderName
    );

    if (idHeaderIndex === -1 || relationToIdHeaderIndex === -1) {
      return true;
    }

    if (this.isChild(rowData)) {
      const childExist = data.some(
        (row) =>
          row[relationToIdHeaderIndex] ===
          modDataWithAllHeaders[indexInModData].data[idHeaderIndex]
      );
      return childExist;
    }

    const childExist = data.some(
      (row) =>
        row[relationToIdHeaderIndex] ===
        modDataWithAllHeaders[indexInModData][idHeaderIndex]
    );
    return childExist;
  };

  /**
   *
   * @description Returns all the headers which are not inside invisible headers
   */
  getHeadersToDisplay = () => {
    if (!this.props.invisibleHeaders) {
      this.setState({ headersToDisplay: this.props.header });
      return;
    }
    const headersToDisplay = this.props.header.filter(
      (header) => !this.props.invisibleHeaders.some((h) => h === header)
    );
    return headersToDisplay;
  };

  /**
   *
   * @description Pass data to this function and it will return
   * the data without the fields related with invisible headers
   */
  getDataToDisplay = (data, isChild) => {
    const indexesToRemove = this.props.header
      .map((header, index) => ({ header, index }))
      .filter(({ header }) => this.props.invisibleHeaders.includes(header))
      .map(({ index }) => index);

    indexesToRemove.sort((a, b) => b - a); // Sort from max to min to avoid errors in the next step

    if (isChild) {
      const dataToUpdate = data;

      if (
        !dataToUpdate.data ||
        !dataToUpdate.data.length ||
        dataToUpdate.data === NO_CHILDS
      ) {
        return dataToUpdate;
      }

      for (const index of indexesToRemove) {
        if (index >= 0 && index < dataToUpdate.data.length) {
          dataToUpdate.data.splice(index, 1);
        }
      }

      return dataToUpdate;
    }

    const dataToUpdate = [...data.map((d) => [...d])];

    for (const row of dataToUpdate) {
      for (const index of indexesToRemove) {
        if (index >= 0 && index < row.length) {
          row.splice(index, 1);
        }
      }
    }

    return dataToUpdate;
  };

  /**
   * @description Renders the main table with all data.
   */
  renderMainTable = () => {
    const {
      data,
      hierarchyEntries,
      id,
      onOpenChilds,
      onCloseChilds,
      columnSortDefs,
      createActionButtons,
      selectable,
      isHierarchyOpen,
      closeHierarchy,
      actionButtonsLength,
      language,
      translate,
      selectedRows,
      passWholeRowOnOpenChild,
    } = this.props;
    const {
      modData,
      modDataWithAllHeaders,
      headersToDisplay,
      showStart,
      showEnd,
      rowHeight,
      headerHeight,
      sortedCol,
    } = this.state;
    const height = { height: (modData.length + 1) * rowHeight + 1 + 'px' };
    const startDataIndex = showStart / rowHeight;
    const endDataIndex = Math.ceil((showEnd - headerHeight) / rowHeight);
    const dataToShow = modData.slice(startDataIndex, endDataIndex);
    return (
      <div
        className={'bux_datahierarchy_container'}
        id={`${id}_datahierarchy_container`}
      >
        <div id={'hierarchy_scroll_container'} style={height}>
          <table id={`${id}_datahierarchy`} className={'bux_datahierarchy'}>
            <HierarchyHeader
              header={this.getHeadersToDisplay()}
              sorting={(col) => this.onSort(modData, col)}
              id={id}
              sortedCol={sortedCol}
              resetSorting={sortedCol === undefined}
              allCheckboxesSelected={this.checkAllCheckboxesSelected()}
              handleCheckAll={(isChecked) => this.handleCheckAll(isChecked)}
              actionButtonsLength={actionButtonsLength || 0}
              selectable={selectable}
              isHierarchyOpen={isHierarchyOpen}
              closeHierarchy={closeHierarchy}
            />
            <tbody id={`${id}_tbody`}>
              {modData.length > 0 ? (
                // render filtered items for the current page
                dataToShow.map((rowData, index) => {
                  let cleanedRowData = rowData;
                  let rootKey;
                  // its necessary to take the data in modData array, because in dataToShow are just the data which are displayed,
                  // so the last row has no information about following row information
                  const indexInModData =
                    Math.floor(showStart / rowHeight) + index;
                  let counter = indexInModData;
                  let level = this.isChild(modData[indexInModData])
                    ? modData[indexInModData].level
                    : 0;
                  let isOpen =
                    this.isChild(modData[indexInModData + 1]) &&
                    level < modData[indexInModData + 1].level;
                  while (counter >= 0) {
                    if (this.isChild(modData[counter])) {
                      counter--;
                    } else {
                      rootKey = hash(modData[counter]);
                      break;
                    }
                  }
                  if (this.isChild(rowData)) {
                    cleanedRowData = rowData.data;
                  }
                  return (
                    <Fragment key={`datahierarchy_tr_${index}`}>
                      <HierarchyRow
                        id={id}
                        data={cleanedRowData}
                        hierarchyEntries={hierarchyEntries}
                        rowLength={data[0].length}
                        index={index}
                        parentKey={hash(cleanedRowData)}
                        rootKey={rootKey}
                        level={level}
                        isOpen={isOpen}
                        onCloseChilds={onCloseChilds}
                        onOpenChilds={
                          passWholeRowOnOpenChild
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            ? (parentKey, rootKey, hash, level, data) => {
                              onOpenChilds(
                                parentKey,
                                rootKey,
                                hash,
                                level,
                                this.isChild(rowData)
                                  ? modDataWithAllHeaders[indexInModData].data
                                  : modDataWithAllHeaders[indexInModData]
                              );
                            }
                            : onOpenChilds
                        }
                        columnSortDefs={columnSortDefs}
                        createActionButtons={createActionButtons}
                        selectable={selectable}
                        isRowChecked={this.isRowChecked}
                        handleCheck={this.handleCheck}
                        actionButtonsLength={actionButtonsLength || 0}
                        language={language}
                        translate={translate}
                        isScrolling={this.isScrolling}
                        detectLastRowClick={
                          index === dataToShow.length - 1
                            ? this.detectLastRowClick
                            : undefined
                        }
                        selectedRows={selectedRows}
                        shouldHandleActionClick={this.shouldHandleActionClick(
                          rowData,
                          indexInModData
                        )}
                      />
                    </Fragment>
                  );
                })
              ) : (
                <tr>
                  <td
                    className={'bux_no_result'}
                    colSpan={`${modData[0].length}`}
                  >
                    <div>{translate('general.no_matching_records')}</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  /**
   * @description Renders the whole data table.
   */
  render = () => {
    const { id, additionalInteraction, downloadItems, translate } = this.props;
    const { modData, headersToDisplay, downloadActionIndex, checkedRows } =
      this.state;
    return (
      <div id={'datahierarchy'} className={'bux_maincontainer'}>
        {/* draws the interaction line */}
        <HierarchyMenu
          id={id}
          data={modData}
          header={this.getHeadersToDisplay()}
          changeDownloadAction={this.handleChangeDownloadAction}
          additionalInteraction={additionalInteraction}
          checkedRows={checkedRows}
          downloadActionIndex={downloadActionIndex}
          downloadItems={downloadItems}
          translate={translate}
        />
        <div className={'padi_hierarchy'} id={`${id}_padihierarchy`}>
          {this.renderMainTable()}
        </div>
      </div>
    );
  };
}