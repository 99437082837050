import * as DateUtils from 'utils/DateUtils'

const isDateAllowedDateString = value => DateUtils.validDateStrings.includes(value.toUpperCase())

/**
 * @description Builds the object for a config by type.
 * @param {Object} currentConfigElement The config element.
 * @param {String} datemask The current datemask.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
export function buildObjectByType(currentConfigElement, datemask, dialogID, counter) {
  let buffer = {}
  if (currentConfigElement.type === 'IDENTIFIER') {
    buffer = buildIdentifierObject(currentConfigElement, dialogID, counter)
  }
  else if (['STRING', 'NUMERIC'].includes(currentConfigElement.type)) {
    buffer = buildDefaultObject(currentConfigElement, dialogID, counter)
  }
  else if (currentConfigElement.type === 'DATE') {
    buffer = buildDateObject(currentConfigElement, datemask, dialogID, counter)
  }
  else if (currentConfigElement.type === 'DATEDATE') {
    buffer = buildDateDateObject(currentConfigElement, datemask, dialogID, counter)
  }
  else if (currentConfigElement.type === 'DATETIME') {
    buffer = buildDateTimeObject(currentConfigElement, datemask, dialogID, counter)
  }
  else if (currentConfigElement.type === 'DTFRAME') {
    buffer = buildDTFrameObject(currentConfigElement, datemask, dialogID, counter)
  }
  else if (currentConfigElement.type === 'LCHOICE') {
    buffer = buildLChoiceObject(currentConfigElement, dialogID, counter)
  }
  else if (currentConfigElement.type === 'CCHOICE2') {
    buffer = buildCChoice2Object(currentConfigElement, dialogID, counter)
  }
  else if (currentConfigElement.type === 'AMOUNT') {
    buffer = buildAmountObject(currentConfigElement, dialogID, counter)
  }
  else if (currentConfigElement.type === 'FILEOPEN') {
    buffer = buildFileOpenObject(currentConfigElement, dialogID, counter)
  }
  else if (['CCHOICE', 'HCHOICE', 'SCHOICE', 'MCHOICE'].includes(currentConfigElement.type)) {
    buffer = buildGeneralChoiceObject(currentConfigElement, dialogID, counter)
  }
  else if (['INDEXDEFAULT', 'INDEXNUMERIC', 'INDEXDATE', 'INDEXRNUM'].includes(currentConfigElement.type)) {
    buffer = buildIndexDefaultObject(currentConfigElement, dialogID, counter)
  }
  else if (currentConfigElement.type === 'INDEXLCHOICE') {
    buffer = buildIndexLChoiceObject(currentConfigElement, dialogID, counter)
  }
  else if (currentConfigElement.type === 'INDEXCCHOICE') {
    buffer = buildIndexCChoiceObject(currentConfigElement, dialogID, counter)
  }
  return buffer
}

/**
 * @description Builds the identifier object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildIdentifierObject(currentConfigElement, dialogID, counter) {
  return {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME,': currentConfigElement.indexName,
    'SPIOP': currentConfigElement.operator,
    'SPIENAME': currentConfigElement.identifier,
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type)
  }
}

/**
 * @description Builds the default object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildDefaultObject(currentConfigElement, dialogID, counter) {
  return {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIOP': 'EQ',
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': currentConfigElement.value.value,
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width,
    'SPIUCASE': currentConfigElement.uppercase ? '*' : ''
  }
}

/**
 * @description Builds the date object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} datemask The current datemask.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildDateObject(currentConfigElement, datemask, dialogID, counter) {
  return {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIOP': 'EQ',
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': isDateAllowedDateString(currentConfigElement.value.value)
      ? currentConfigElement.value.value
      : DateUtils.toMoment(currentConfigElement.value.value, datemask).format(DateUtils.YYYYMMDD_MINUS),
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width
  }
}

/**
 * @description Builds the date date object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} datemask The current datemask.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildDateDateObject(currentConfigElement, datemask, dialogID, counter) {
  return {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIINAM2': currentConfigElement.secondIndexName,
    'SPIOP': 'EQ',
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': isDateAllowedDateString(currentConfigElement.value.value)
      ? currentConfigElement.value.value
      : currentConfigElement.value.value === ''
        ? currentConfigElement.value.value
        : DateUtils.toMoment(currentConfigElement.value.value, datemask).format(DateUtils.YYYYMMDD_MINUS),
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIENAM2': currentConfigElement.identifier2.value,
    'SPIVALU2': isDateAllowedDateString(currentConfigElement.value2.value)
      ? currentConfigElement.value2.value
      : currentConfigElement.value2.value === ''
        ? currentConfigElement.value2.value
        : DateUtils.toMoment(currentConfigElement.value2.value, datemask).format(DateUtils.YYYYMMDD_MINUS),
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width,
    'SPIVALR2': currentConfigElement.required2 ? '*' : '',
    'SPIVALD2': currentConfigElement.disabled2 ? '*' : ''
  }
}

/**
 * @description Builds the date time object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} datemask The current datemask.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildDateTimeObject(currentConfigElement, datemask, dialogID, counter) {
  return {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIINAM2': currentConfigElement.secondIndexName,
    'SPIOP': 'EQ',
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': isDateAllowedDateString(currentConfigElement.value.value)
      ? currentConfigElement.value.value
      : currentConfigElement.value.value === ''
        ? currentConfigElement.value.value
        : DateUtils.toMoment(currentConfigElement.value.value, datemask).format(DateUtils.YYYYMMDD_MINUS),
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIENAM2': currentConfigElement.identifier2.value,
    'SPIVALU2': DateUtils.formatTimeToDefault(currentConfigElement.value2.value),
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width,
    'SPIVALR2': currentConfigElement.required2 ? '*' : '',
    'SPIVALD2': currentConfigElement.disabled2 ? '*' : ''
  }
}

/**
 * @description Builds the dtframe object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} datemask The current datemask.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildDTFrameObject(currentConfigElement, datemask, dialogID, counter) {
  return {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    // specific operator for date time frame element
    'SPIOP': 'DF',
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': isDateAllowedDateString(currentConfigElement.value.value)
      ? currentConfigElement.value.value
      : currentConfigElement.value.value !== ''
        ? DateUtils.toMoment(currentConfigElement.value.value, datemask).format(DateUtils.YYYYMMDD_MINUS)
        : '',
    'SPIVALU2': isDateAllowedDateString(currentConfigElement.value2.value)
      ? currentConfigElement.value2.value
      : currentConfigElement.value2.value !== ''
        ? DateUtils.toMoment(currentConfigElement.value2.value, datemask).format(DateUtils.YYYYMMDD_MINUS)
        : '',
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width
  }
}

/**
 * @description Builds the lchoice object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildLChoiceObject(currentConfigElement, dialogID, counter) {
  return {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIOP': 'EQ',
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': currentConfigElement.value.value,
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width,
    'SPIENAM2': currentConfigElement.additionalFields.map(d => d.identifier.value).join(';'),
    'SPIVALU3': currentConfigElement.additionalFields.map(d => d.value.value).join(';'),
    'SPIUCASE': currentConfigElement.uppercase ? '*' : ''
  }
}

/**
 * @description Builds the cchoice2 object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildCChoice2Object(currentConfigElement, dialogID, counter) {
  return {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIOP': 'EQ',
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': currentConfigElement.value.value,
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIVALU2': currentConfigElement.value2.value,
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width,
    'SPIENAM3': currentConfigElement.dbSelect.value,
    'SPIENAM2': currentConfigElement.additionalFields.map(d => d.identifier.value).join(';'),
    'SPIVALU3': currentConfigElement.additionalFields.map(d => d.value.value).join(';'),
    'SPIUCASE': currentConfigElement.uppercase ? '*' : ''
  }
}

/**
 * @description Builds the amount object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildAmountObject(currentConfigElement, dialogID, counter) {
  return {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIINAM2': currentConfigElement.secondIndexName,
    'SPIOP': 'EQ',
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': currentConfigElement.value.value,
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width,
    'SPIVALU2': currentConfigElement.value2.value,
    'SPIENAM2': currentConfigElement.identifier2.value,
    'SPIVALU3': currentConfigElement.value3.value
  }
}

/**
 * @description Builds the file open object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildFileOpenObject(currentConfigElement, dialogID, counter) {
  return {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIOP': 'EQ',
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': '',
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width
  }
}

/**
 * @description Builds the general choice object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildGeneralChoiceObject(currentConfigElement, dialogID, counter) {
  const obj = {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIOP': 'EQ',
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': currentConfigElement.value.value,
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width,
    'SPIENAM3': currentConfigElement.dbSelect.value,
    'SPIENAM2': currentConfigElement.additionalFields.map(d => d.identifier.value).join(';'),
    'SPIVALU3': currentConfigElement.additionalFields.map(d => d.value.value).join(';'),
    'SPIUCASE': currentConfigElement.uppercase ? '*' : ''
  }

  // special values for 'PROCESS'
  if (currentConfigElement.indexName === 'PROCESS') {
    obj['SPIVALU3'] = currentConfigElement.additionalFields.map(d => {
      const value = d.value.value
      switch (value) {
        case 'ALL': return 'A'
        case 'LIST': return 'L'
        case 'REPORT': return 'R'
        default: return value
      }
    }).join(';')
  }
  return obj
}

/**
 * @description Builds the index default choice object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildIndexDefaultObject(currentConfigElement, dialogID, counter) {
  let buffer = {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIOP': currentConfigElement.operator,
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': currentConfigElement.value.value,
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width,
    'SPIUCASE': currentConfigElement.uppercase ? '*' : ''
  }
  if (currentConfigElement.operator === 'RA') {
    buffer['SPIENAM2'] = currentConfigElement.identifier2.value
    buffer['SPIVALU2'] = currentConfigElement.value2.value
    buffer['SPIVALR2'] = currentConfigElement.required2 ? '*' : ''
    buffer['SPIVALD2'] = currentConfigElement.disabled2 ? '*' : ''
  }
  return buffer
}

/**
 * @description Builds the index lchoice object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildIndexLChoiceObject(currentConfigElement, dialogID, counter) {
  let buffer = {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIOP': currentConfigElement.operator,
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': currentConfigElement.value.value,
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width,
    'SPIENAM2': currentConfigElement.additionalFields.map(d => d.identifier.value).join(';'),
    'SPIVALU3': currentConfigElement.additionalFields.map(d => d.value.value).join(';'),
    'SPIUCASE': currentConfigElement.uppercase ? '*' : ''
  }
  if (currentConfigElement.operator === 'RA') {
    buffer['SPIVALU2'] = currentConfigElement.value2.value
    buffer['SPIVALR2'] = currentConfigElement.required2 ? '*' : ''
    buffer['SPIVALD2'] = currentConfigElement.disabled2 ? '*' : ''
  }
  return buffer
}

/**
 * @description Builds the index cchoice object for a config.
 * @param {Object} currentConfigElement The config element.
 * @param {String} dialogID The id of the custom dialog.
 * @param {Number} counter The counter for the sequential number.
 */
function buildIndexCChoiceObject(currentConfigElement, dialogID, counter) {
  let buffer = {
    'SPTINAME': dialogID,
    'SPISEQNR': counter,
    'IXINAME': currentConfigElement.indexName,
    'SPIOP': currentConfigElement.operator,
    'SPIENAME': currentConfigElement.identifier,
    'SPIVALUE': currentConfigElement.value.value,
    'SPIVALR': currentConfigElement.required ? '*' : '',
    'SPIVALD': currentConfigElement.disabled ? '*' : '',
    'SPIDTYPE': getTypeForRequest(currentConfigElement.type),
    'SPIWEFLD': currentConfigElement.width,
    'SPIENAM3': currentConfigElement.dbSelect.value,
    'SPIENAM2': currentConfigElement.additionalFields.map(d => d.identifier.value).join(';'),
    'SPIVALU3': currentConfigElement.additionalFields.map(d => d.value.value).join(';'),
    'SPIUCASE': currentConfigElement.uppercase ? '*' : ''
  }
  if (currentConfigElement.operator === 'RA') {
    buffer['SPIVALU2'] = currentConfigElement.value2.value
    buffer['SPIVALR2'] = currentConfigElement.required2 ? '*' : ''
    buffer['SPIVALD2'] = currentConfigElement.disabled2 ? '*' : ''
  }
  return buffer
}

/**
 * @returns List of identifiers for mapping the dropdown values in the 'Form' tab for 'Elements' in 'Create/Copy/Modify Custom Dialog'
 */
export function getGeneralIndexIdentifier() {
  return [
    'B93FROMLAST',
    'B93SDATE',
    'B93EDATE',
    'B93DTFRAME',
    'B93RECI',
    'B93FOLDER',
    'B93NODE',
    'B93FORM',
    'B93FORMEXT',
    'B93EXT',
    'B93REPORT',
    'B93TITLE',
    'B93JOBNAME',
    'B93PROCESS',
    'B93OWNER',
    'B93DOCUSR1',
    'B93DOCUSR2',
    'B93DOCUSR3',
    'B93DOCUSR4',
    'B93DOCUSR5',
    'B93DOCUSR6',
    'B93DOCUSR7',
    'B93DOCUSR8',
    'B93SRCDSN',
  ]
}

/**
 * @description Changes the type which is used for the request in case of the type is one of artificial index types.
 */
function getTypeForRequest(type) {
  switch (type) {
    case 'INDEXDEFAULT': return 'STRING'
    case 'INDEXLCHOICE': return 'LCHOICE'
    case 'INDEXCCHOICE': return 'CCHOICE'
    case 'INDEXNUMERIC': return 'NUMERIC'
    case 'INDEXDATE': return 'DATE'
    case 'INDEXRNUM': return 'RNUM'
    case 'IDENTIFIER': return 'STRING'
    default: return type
  }
}