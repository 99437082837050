import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// components
import {
  Button, Card, Column,
  Input,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// redux
import { connect } from 'react-redux'
import { createFolder } from 'redux/actions/FolderDefinitionActions'

class CopyFolderDefinitionDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    folderID: {
      value: this.props.folder.GLRNAME,
      errorkey: ''
    },
    owner: {
      value: this.props.folder.OWNER,
      errorkey: ''
    },
    title: {
      value: this.props.folder.GLRTITLE,
      errorkey: ''
    }
  }

  folderIdInput = React.createRef()

  componentDidMount = () => {
    this.folderIdInput.current.focus()
  }

  /**
   * @description Handles the input changes of the input fields.
   * @param {String} id The id the input field.
   * @param {String} value The new value.
   * @param {String} errorkey The new errorkey.
   */
  handleInput = (id, value, errorkey) => this.setState({ [id]: { value, errorkey } })

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInput(key, value, error)
  }

  /**
   * @description Validates the output format id.
   * @param {boolean} allowFocus Flag to allow the focus on error.
   */
  validateID = () => {
    const { folderID } = this.state
    const { folder } = this.props
    if (folderID.value !== '') {
      if (folderID.value === folder.GLRNAME) {
        return {
          folderID: {
            ...this.state.folderID,
            errorkey: 'definition.folder_copy_same_id_error'
          }
        }
      } else {
        return {}
      }
    }
    return {
      folderID: {
        ...this.state.folderID,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = { ...this.validateID() }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.folderIdInput.current && this.folderIdInput.current.focus()
      })
    }
    return errors === 0
  }

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { createFolder, onClose } = this.props
    const { folderID, owner, title } = this.state
    if (this.validateInputs()) {
      const folderDefinition = {
        GLRNAME: folderID.value,
        GLRTITLE: title.value,
        OWNER: owner.value
      }
      createFolder(folderDefinition, () => onClose())
    }
  }

  render = () => {
    const { id, onClose } = this.props
    const { folderID, owner, title } = this.state
    return (
      <Modal onClose={onClose}
        id='copy_folderdefinition_dialog'
        className='bux_UserProfileModal'>
        <Header
          id={`${id}_modalHeader`}
          title={translate('definition.folder_copy_modal_title')}
          onClose={onClose} />
        <Main id={id}>
          <Card>
            <Row>
              <Column colMD={8}>
                <Input
                  id={`${id}_id`}
                  value={folderID.value}
                  title={translate('definition.folder_id')}
                  ref={this.folderIdInput}
                  required={`${translate('general.required_field')}`}
                  maxLength={32}
                  onInputChanged={(val, err) => this.handleChangeWithoutSpaces('folderID', val, err)}
                  error={folderID.errorkey && translate(folderID.errorkey)}
                  onBlur={() => this.setState({ ...this.validateID() })}
                />
              </Column>
              <Column colMD={4}>
                <Input
                  id={`${id}_owner`}
                  value={owner.value}
                  title={translate('general.owner')}
                  maxLength={8}
                  onInputChanged={(val, err) => this.handleInput('owner', val, err)}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <Input
                  id={`${id}_title`}
                  value={title.value}
                  title={translate('general.title')}
                  maxLength={40}
                  onInputChanged={(val, err) => this.handleInput('title', val, err)}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    folder: state.definitions.folders.folder
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createFolder: (folderDefinition, callback) => createFolder(folderDefinition, callback)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyFolderDefinitionDialog)