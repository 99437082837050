import moment from 'moment'
import PropTypes from 'prop-types'
import { Component } from 'react'

// components
import { Button, Card, Column, Input, Row } from 'BetaUX2Web-Components/src/'
import TimeCard from 'components/time_card/TimeCard'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as ServerActions from 'redux/actions/ServerActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as Utils from 'utils/Utils'

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.hours' },
  { key: UNIT_DAYS, translationKey: 'general.days' },
]

export const LASTTIME_MODE_TODAY = 'today'
export const LASTTIME_MODE_YESTERDAY = 'yesterday'
export const LASTTIME_MODE_CUSTOM = 'custom'

const LASTTIME_MODES = [
  { key: LASTTIME_MODE_TODAY, translationKey: 'general.today' },
  { key: LASTTIME_MODE_YESTERDAY, translationKey: 'general.yesterday' },
  { key: LASTTIME_MODE_CUSTOM, translationKey: 'general.custom' },
]

class ServerMessageLog extends Component {

  defaultState = {
    time: {
      activeTimeTabIndex: 0,
      activeLastTimeModeIndex: 0,
      customLast: 1,
      activeUnitIndex: 2,
      fromDate: {
        value: '',
        error: ''
      },
      fromTime: {
        value: '',
        error: ''
      },
      toDate: {
        value: '',
        error: ''
      },
      toTime: {
        value: '',
        error: ''
      }
    },
    messageID: {
      value: '',
      errorkey: ''
    },
    text: {
      value: '',
      errorkey: ''
    }
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount = () => {
    // initialize search fields
    const { preferences, datemask } = this.props

    if (preferences) {
      let activeTimeTabIndex = Utils.convertStringToInt(preferences[Preferences.MESSAGE_LOG_ACTIVE_TAB]) || 0
      // last time mode
      let lastTimeMode = preferences[Preferences.MESSAGE_LOG_LASTTIME_MODE]
      if (!lastTimeMode) {
        lastTimeMode = LASTTIME_MODE_TODAY
      }
      const activeLastTimeModeIndex = Math.max(LASTTIME_MODES.findIndex(temp => temp.key === lastTimeMode), 0)

      // custom last
      let customLast = preferences[Preferences.MESSAGE_LOG_CUSTOMLAST]
      if (!customLast) {
        customLast = 1
      }
      else {
        if (Utils.isString(customLast)) {
          customLast = parseInt(customLast)
        }
      }

      // custom unit
      let customUnit = preferences[Preferences.MESSAGE_LOG_CUSTOM_UNIT]
      if (!customUnit) {
        customUnit = UNIT_DAYS
      }
      const activeUnitIndex = Math.max(UNITS.findIndex(temp => temp.key === customUnit), 0)

      let fromDate = activeTimeTabIndex === 1 && preferences[Preferences.MESSAGE_LOG_FROMDATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.MESSAGE_LOG_FROMDATE])
        : ''

      let fromTime = preferences[Preferences.MESSAGE_LOG_FROMTIME] || ''
      fromTime = Utils.convertStringToInt(fromTime)

      let toDate = preferences[Preferences.MESSAGE_LOG_TODATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.MESSAGE_LOG_TODATE])
        : ''

      let toTime = preferences[Preferences.MESSAGE_LOG_TOTIME] || ''
      toTime = Utils.convertStringToInt(toTime)

      // message id
      const messageID = preferences[Preferences.MESSAGE_LOG_MESSAGE_ID] || ''

      // text
      const text = preferences[Preferences.MESSAGE_LOG_TEXT] || ''

      this.setState({
        time: {
          activeTimeTabIndex,
          type: activeLastTimeModeIndex,
          period: customLast,
          unit: activeUnitIndex,
          fromDate: {
            value: fromDate,
            errorkey: ''
          },
          fromTime: {
            value: fromTime,
            errorkey: ''
          },
          toDate: {
            value: toDate,
            errorkey: ''
          },
          toTime: {
            value: toTime,
            errorkey: ''
          }
        },
        messageID: {
          value: messageID,
          errorkey: ''
        },
        text: {
          value: text,
          errorkey: ''
        }
      })
    }
  }


  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Handles the input changes.
   * @param {String} key The key of the input field.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleInputChanged = (key, value, error) => {
    this.setState({
      [key]: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Validates all elements from the timecard component. Checks if date inputs are valid dates and if given, the end date is not before the start date.
   */
  validateTimecardErrors = () => {
    const { time } = this.state
    const { datemask } = this.props
    let errorObj = {}
    if (time.activeTimeTabIndex === 1) {
      if (time.fromDate.value !== '' && !DateUtils.isDate(time.fromDate.value, datemask) && !DateUtils.validDateStrings.includes(time.fromDate.value.toUpperCase())) {
        errorObj.fromDate = {
          value: time.fromDate.value,
          error: 'general.invalid_date_format'
        }
      }
      if (time.toDate.value !== '' && !DateUtils.isDate(time.toDate.value, datemask) && !DateUtils.validDateStrings.includes(time.toDate.value.toUpperCase())) {
        errorObj.toDate = {
          value: time.toDate.value,
          error: 'general.invalid_date_format'
        }
      }
      if (time.fromTime.value !== '' && !time.fromTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errorObj.fromTime = {
          value: time.fromTime.value,
          error: 'general.invalid_time_format'
        }
      }
      if (time.toTime.value !== '' && !time.toTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errorObj.toTime = {
          value: time.toTime.value,
          error: 'general.invalid_time_format'
        }
      }
    }
    // Set error if the to date is before the from date and both inputs are filled with a date.
    if (!errorObj.toDate) {
      if (time.fromDate.value !== '' && time.toDate.value !== '') {
        if (DateUtils.isDate(time.fromDate.value, datemask) && DateUtils.isDate(time.toDate.value, datemask)) {
          const fromDate = moment(time.fromDate.value, datemask)
          const toDate = moment(time.toDate.value, datemask)
          if (toDate.isBefore(fromDate)) {
            errorObj.toDate = {
              value: time.toDate.value,
              error: 'statistic.negative_date_difference_error'
            }
          }
        }
      }
    }
    this.setState({ time: { ...time, ...errorObj } })
    return Object.keys(errorObj).length
  }

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = event => {
    const { datemask } = this.props
    event.preventDefault()

    const { time, messageID, text } = this.state

    const errors = this.validateTimecardErrors()
    if (errors === 0) {
      // save search values in preferences
      const prefsToChange = {
        [Preferences.MESSAGE_LOG_ACTIVE_TAB]: time.activeTimeTabIndex,
        [Preferences.MESSAGE_LOG_LASTTIME_MODE]: time.type,
        [Preferences.MESSAGE_LOG_CUSTOMLAST]: time.period,
        [Preferences.MESSAGE_LOG_CUSTOM_UNIT]: UNITS[time.unit].key,
        [Preferences.MESSAGE_LOG_FROMDATE]: DateUtils.getRequestFormat(time.fromDate.value, datemask),
        [Preferences.MESSAGE_LOG_FROMTIME]: DateUtils.formatTimeToDefault(time.fromTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.MESSAGE_LOG_TODATE]: DateUtils.getRequestFormat(time.toDate.value, datemask),
        [Preferences.MESSAGE_LOG_TOTIME]: DateUtils.formatTimeToDefault(time.toTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.MESSAGE_LOG_MESSAGE_ID]: messageID.value,
        [Preferences.MESSAGE_LOG_TEXT]: text.value
      }

      this.props.changePrefs(prefsToChange)
      const searchParams = {
        // last tab is active
        ...time.activeTimeTabIndex === 0 && {
          // today
          ...LASTTIME_MODES[time.type].key === LASTTIME_MODE_TODAY && {
            'SDATE': 'TODAY',
          },
          // yesterday
          ...LASTTIME_MODES[time.type].key === LASTTIME_MODE_YESTERDAY && {
            'SDATE': 'YESTERDAY',
          },
          // custom
          ...LASTTIME_MODES[time.type].key === LASTTIME_MODE_CUSTOM && {
            'FROMLAST': time.period,
            'TUNITS': UNITS[time.unit].key,
          },
        },
        // date tab is active
        ...time.activeTimeTabIndex === 1 && {
          'SDATE': DateUtils.getTimeshiftDate(time.fromDate.value, time.fromTime.value, DateUtils.DDMMYYYY_DOT),
          ...time.fromTime.value !== '' && {
            'STIME': DateUtils.getTimeshiftDate(time.fromDate.value, time.fromTime.value, DateUtils.TIME_DATEMASK),
          },
          'EDATE': DateUtils.getTimeshiftDate(time.toDate.value, time.toTime.value, DateUtils.DDMMYYYY_DOT),
          ...time.toTime.value !== '' && {
            'ETIME': DateUtils.getTimeshiftDate(time.toDate.value, time.toTime.value, DateUtils.TIME_DATEMASK)
          }
        },
        'MSGNR': messageID.value,
        'MSGTXT': text.value,
      }

      this.props.getMessageLog(searchParams)
    }
  }

  /**
   * @description Gets the translated dropdown items.
   * @param {Array} items The array which includes all items.
   */
  getTranslatedDropdownItems = (items) => {
    const translatedItems = []
    items.forEach(entry => {
      translatedItems.push(translate(entry.translationKey))
    })

    return translatedItems
  }

  /**
   * @description Handles the changes in timecard.
   * @param {String} key The key of the input in state.
   * @param {String} val The new value of the input.
   * @param {String} err The new error of the input.
   */
  handleTimeCardChange = (key, val, err) => {
    this.setState(state => ({
      time: {
        ...state.time,
        [key]: typeof state.time[key] === 'object'
          ? {
            value: val,
            error: err || ''
          }
          : val
      }
    }))
  }

  /**
   * @description Returns the values for the timecard.
   * @returns {Object} The timecard values.
   */
  timeCardValues = () => {
    const { time } = this.state
    return {
      tabIndex: time.activeTimeTabIndex,
      lastTimeModeIndex: time.type,
      customLast: time.period,
      customUnitIndex: time.unit,
      fromDate: {
        value: time.fromDate.value,
        error: time.fromDate.error
      },
      fromTime: {
        value: time.fromTime.value,
        error: time.fromTime.error
      },
      toDate: {
        value: time.toDate.value,
        error: time.toDate.error
      },
      toTime: {
        value: time.toTime.value,
        error: time.toTime.error
      }
    }
  }

  /**
   * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
   * Needed for update function inside the TimeCard.
   * @returns {Object} The object with the state keys.
   */
  timeCardStateKeys = () => {
    return {
      tabIndex: 'activeTimeTabIndex',
      lastTimeModeIndex: 'type',
      customLast: 'period',
      customUnitIndex: 'unit',
      fromDate: 'fromDate',
      fromTime: 'fromTime',
      toDate: 'toDate',
      toTime: 'toTime',
    }
  }

  /**
   * @description Renders the time card.
   */
  renderTimeCard = () => {
    const { id, lang, datemask } = this.props
    return (
      <TimeCard
        id={id}
        lang={lang}
        datemask={datemask}
        values={this.timeCardValues()}
        stateKeys={this.timeCardStateKeys()}
        onValuesChange={this.handleTimeCardChange}
        parentContainer={'drawer_content_server_body_main'}
        translate={key => translate(key)}
      />
    )
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props

    return (
      <Card title={translate('general.general')}>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_generalcard_messageid`}
              title={translate('database.result.message_id')}
              maxLength={8}
              value={this.state.messageID.value}
              onInputChanged={(val, err) => this.handleInputChanged('messageID', val, err)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_generalcard_text`}
              title={translate('general.text')}
              maxLength={40}
              value={this.state.text.value}
              onInputChanged={(val, err) => this.handleInputChanged('text', val, err)}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the main components.
   */
  renderMain = () => {
    return (
      <div
        id={`${this.props.id}_main`}
        className={'bux_drawer_main'}>
        {this.renderTimeCard()}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {/* main */}
        {this.renderMain()}
        {/* footer */}
        {this.renderFooter()}
      </form>
    )
  }
}

ServerMessageLog.propTypes = {
  id: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences.DATEMASK
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMessageLog: searchParams => {
      ServerActions.getMessageLog(searchParams)(dispatch)
    },
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerMessageLog)