import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SecurityEntities from './security_entities/Entities'
import Security from './security_profiles/Security'
// import DefinitionLog from './definition_log/DefinitionLog'

class SecurityDefinitionBody extends Component {

  getBody() {
    const { id, definitionMode, history } = this.props
    switch (definitionMode) {
      // standard import
      case 0: return
      case 'security': return <Security id={id} history={history} />
      case 'entity': return <SecurityEntities id={id} history={history} />

      // should not happen
      default: return <Security id={id}/>
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

SecurityDefinitionBody.propTypes = {
  id: PropTypes.string.isRequired,
  definitionMode: PropTypes.string,
}

export default SecurityDefinitionBody