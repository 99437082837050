import {Component} from 'react'
import {connect} from 'react-redux'

import './Header.scss'

import {translate} from 'language/Language'
import Logo from 'ressource/logo/logo-beta_admin-blue.svg'

import {Icon, } from 'BetaUX2Web-Components/src'
import LogoutDialog from 'components/dialogs/logout_dialog/LogoutDialog'
import ServerActivity from 'components/dialogs/server_activity/ServerActivity'
import SubAdminUserPreferencesDialog
  from 'components/dialogs/subadmin_user_preferences_dialog/SubAdminUserPreferencesDialog'
import UserPreferencesDialog from 'components/dialogs/user_preferences_dialog/UserPreferencesDialog'

import {
  getActiveUsers, getServerstatus
} from 'redux/actions/StatisticActions'
import * as UserActions from 'redux/actions/UserActions'
import store from 'redux/Store'
import * as UserUtils from 'utils/UserUtils'


class Header extends Component {
  state = {
    logoutDialogVisible: false,
    langItems: [translate('language.english'), translate('language.german'),],
    showUserPreferencesDialog: false,
    showMonitoringDialog: false,
    minimal: this.props.minimal
  }

  handleOnLogout() {
    this.setState({
      logoutDialogVisible: true
    })
  }

  handleOnUserPreferences = () => {
    const callback = () => {
      this.setState({showUserPreferencesDialog: true})
    }
    const userid = store.getState().auth.serverdata.betauser

    if (this.props.auth.serverdata.userprofile) {
      if (this.props.auth.serverdata.userprofile.BRWSONLY) {

        // show dialog directly
        callback()

        // skip getUserForPreferences request because subadmin has no permission
        return
      }
    }

    this.props.getUserForPreferences(userid, callback, true)
  }

  renderUserPreferenceDialog = () => {
    const {serverdata} = this.props.auth
    // subadmin or admins without the securityprofile SEC.USR should see the different dialog
    if ((serverdata.userprofile && serverdata.userprofile.BRWSONLY) || !this.props.user.user) {
      return (<SubAdminUserPreferencesDialog
        id={`${this.props.id}_userpreferencesdialog`}
        visible={this.state.showUserPreferencesDialog}
        onClose={() => {
          this.setState({showUserPreferencesDialog: false})
        }}
      />)
    }

    // normal admin preferences dialog
    return (<UserPreferencesDialog
      id={`${this.props.id}_userpreferencesdialog`}
      visible={this.state.showUserPreferencesDialog}
      onClose={() => {
        this.setState({showUserPreferencesDialog: false})
      }}
    />)
  }

  toggleMonitoringDialog = () => {
    const {getServerstatus, getActiveUsers} = this.props
    getServerstatus(() => {
      getActiveUsers(() => {
        this.setState(prevState => ({
          showMonitoringDialog: !prevState.showMonitoringDialog
        }))
      })
    })
  }

  renderLoggedInInfo() {
    const {id, auth} = this.props
    const userid = auth.userid

    return (<div>

      {/* logout dialog */}
      {this.state.logoutDialogVisible ? <LogoutDialog
        id='logout_dialog'
        history={this.props.history}
        visible={this.state.logoutDialogVisible}
        onClose={() => {
          this.setState({logoutDialogVisible: false})
        }}
      /> : null}

      {/* user preferences dialog */}
      {this.state.showUserPreferencesDialog && (this.renderUserPreferenceDialog())}

      <div className='header_info'>
        {this.props.history && (<div
          id='we_id_logout_href'
          className='we_header_user_info user_info'
          onClick={() => this.handleOnLogout()}
          title={translate('header.logout')}>
          <Icon id={`${id}_logOffBtn`} name='logout'/>
        </div>)}

        <div
          id='we_id_header_username'
          className='we_header_user_info user_info'>
          {`${userid} @ ${auth.serverdata.systemname}`}
        </div>

        {!this.props.minimal && (<>
          <div
            id='we_id_user_preferences_href'
            className='we_header_user_info user_info'
            onClick={this.handleOnUserPreferences}
            title={translate('userpreferences.user_preferences')}>
            <Icon id={`${id}_settingsBtn`} name='settings'/>
          </div>
          {UserUtils.isAdmin() && <div
            id='server_activity'
            className='we_header_user_info user_info'
            onClick={this.toggleMonitoringDialog}
            title={translate('server_activity')}>
            <Icon id={'server_activity_button'} name='monitor'/>
          </div>}
        </>)}
      </div>
      {this.state.showMonitoringDialog && (<ServerActivity
        id={'server_activity'}
        onClose={() => this.setState({showMonitoringDialog: false})}
      />)}
    </div>)
  }

  render = () => {
    return (<header className='Header'>
      <div className='bux_header'>
        <div className='header_logo'>
          <img src={Logo} alt='Logo'/>
        </div>
        {this.props.loggedIn ? this.renderLoggedInInfo() : null}
      </div>
    </header>)
  }
}

const mapStateToProps = state => {
  return {
    language: state.language,
    user: state.user,
    auth: state.auth,
    userprofile: state.auth.serverdata && state.auth.serverdata.userprofile,
    isAdmin: state.auth.serverdata && state.auth.serverdata.userprofile && state.auth.serverdata.userprofile.BRWSONLY
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserForPreferences: (userid, callback) => UserActions.getUserForPreferences(userid, callback)(dispatch),
    getServerstatus: callback => getServerstatus(callback)(dispatch),
    getActiveUsers: callback => getActiveUsers(callback)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)