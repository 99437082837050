import PropTypes from 'prop-types'
import { Component } from 'react'

// Translations
import * as Language from 'language/Language'

// Components
import {
  Button, Card, Checkbox, Column,
  DataTable, Dropdown, Input,
  MetaDataGrid,
  Modal as ModalComponent,
  Row,
  Tab, Tabs
} from 'BetaUX2Web-Components/src/'

// Redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'

// Utils
import { getAccountingRecords } from 'utils/AccountingRecordsUtils.js'
import { DATEMASKS, TIMEMASKS, today } from 'utils/DateUtils'
import * as UserUtils from 'utils/UserUtils'

// Style
import './AccountingRecords.scss'

const { Modal, Main, Header, Footer } = ModalComponent

class AccountingRecords extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  state = {
    activeTab: 0,
    date: {
      from: {
        value: '',
        errorkey: ''
      },
      to: {
        value: today(),
        errorkey: ''
      }
    },
    mask: {
      date: this.props.headerInfo
        ? DATEMASKS.indexOf(this.props.headerInfo['DATEMASK']) !== -1
          ? DATEMASKS.indexOf(this.props.headerInfo['DATEMASK'])
          : DATEMASKS.indexOf(this.props.prefDatemask)
        : DATEMASKS.indexOf(this.props.prefDatemask),
      time: this.props.headerInfo
        ? TIMEMASKS.indexOf(this.props.headerInfo['TIMEMASK']) !== -1
          ? TIMEMASKS.indexOf(this.props.headerInfo['TIMEMASK'])
          : 0
        : 0
    },
    fileExtension: this.props.headerInfo ? this.props.headerInfo['OUTPUT_FILE_EXTENSION'] : 'csv',
    download: this.props.download || {},
    data: {},
    sortedCol: {}
  }

  // dateFromInput = React.createRef() // TODO: implement when needed

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount = () => {
    const { selected } = this.props

    const data = {}
    for (const [key, type] of Object.entries(selected)) {
      data[key] = Object.values(type.fields).map((field) => [field.id, field.description])
    }

    this.setState({ data: data })
  }

  /**
   * @description Initializes The download data.
   */
  componentDidMount = () => {
    const { selected, download } = this.props
    const { data } = this.state

    let sortedCol = {}
    for (const key of Object.keys(selected)) {
      // init sort for tab
      sortedCol[key] = undefined
    }

    // when loading accounting file order the data in datatable like its defined in the config file
    if (download) {
      const keys = Object.keys(data)
      for (let i = 0; i < keys.length; i++) {
        if (download[keys[i]].checkedRows.length > 0) {
          let buffer = {}
          data[keys[i]].forEach((d, index) => {
            if (download[keys[i]].checkedRows.includes(d[0])) {
              buffer[d[0]] = index
            }
          })
          const bufferKeys = Object.keys(buffer)
          for (let x = 0; x < bufferKeys.length; x++) {
            for (let y = 0; y < bufferKeys.length - 1; y++) {
              const downloadIndex = (key1, key2) => download[key1].checkedRows.indexOf(key2)
              if ((buffer[bufferKeys[x]] < buffer[bufferKeys[y + 1]]) && (downloadIndex(keys[i], bufferKeys[x]) > downloadIndex(keys[i], bufferKeys[y + 1]))) {
                const indexBuffer = buffer[bufferKeys[x]]
                buffer[bufferKeys[x]] = buffer[bufferKeys[y + 1]]
                // semicolon is needed because the last line in if case uses new array position change syntax
                buffer[bufferKeys[y + 1]] = indexBuffer;
                // swap two items inside the array
                [data[keys[i]][buffer[bufferKeys[x]]], data[keys[i]][buffer[bufferKeys[y + 1]]]] = [data[keys[i]][buffer[bufferKeys[y + 1]]], data[keys[i]][buffer[bufferKeys[x]]]]
              }
            }
          }
        }
      }
    }
    this.setState({ sortedCol: sortedCol })
    // this.dateFromInput.current.focus() // TODO: implement when needed
    if (!this.props.download) {
      let downloadBuffer = this.state.download
      const types = getAccountingRecords().types
      if (UserUtils.isDOCX()) {
        delete types['type200']
        delete types['type210']
        delete types['type220']
      }
      Object.keys(types).forEach(key => downloadBuffer[key] = this.props.selected[key] ? { defaultOrder: true, checkedRows: [] } : undefined)
      this.setState({ download: downloadBuffer })
    }
  }

  /**
   * @description Handles the value changes except the table changes.
   * @param {String} group The type of the tab.
   * @param {String} key The property name.
   * @param {*} value The new value.
   */
  handleChange = ({ group, key, value }) => {
    if (group) {
      this.setState({
        [group]: {
          ...this.state[group],
          [key]: value
        }
      })
    } else {
      this.setState({
        [key]: value
      })
    }
  }

  /**
   * @description Handles the value changes except the table changes.
   * @param {String} group The type of the tab.
   * @param {String} key The property name.
   * @param {*} value The new value.
   */
  handleChangeWithError = ({ group, key, value }) => {
    this.setState({
      [group]: {
        ...this.state[group],
        [key]: {
          ...this.state[group][key],
          value: value,
          errorkey: value !== '' ? '' : Language.translate('general.input_required')
        }
      }
    })
  }

  setError = (group, key, error) => {
    this.setState({
      [group]: {
        ...this.state[group],
        [key]: {
          ...this.state[group][key],
          errorkey: error,
        }
      }
    })
  }

  /**
   * @description Handles the download changes.
   * @param {String} group The type of the tab.
   * @param {String} key The property name.
   * @param {*} value The new value.
   */
  handleDownloadChanged = ({ group, key, value }) => {
    this.setState({
      download: {
        ...this.state.download,
        [group]: {
          ...this.state.download[group],
          [key]: value
        }
      }
    })
  }

  /**
   * @description Updates the checked rows.
   * @param {String} key The type of the tab.
   * @param {Array} checkedRows The new checked rows.
   */
  updateCheckedRows = (key, checkedRows) => {
    this.setState({
      download: {
        ...this.state.download,
        [key]: {
          ...this.state.download[key],
          checkedRows: checkedRows
        }
      }
    })
  }

  validRequiredFields = () => {
    // TODO: implement when needed
    // const { date } = this.state
    // const { prefDatemask } = this.props
    // if (date.from.value === '') {
    //   this.setError('date', 'from', Language.translate('general.input_required'))
    //   return false
    // }
    // if (date.to.value === '') {
    //   this.setError('date', 'to', Language.translate('general.input_required'))
    //   return false
    // }
    // if (!isDate(date.from.value, prefDatemask.DATEMASK)) {
    //   this.setError('date', 'from', Language.translate('general.incorrect_date'))
    //   return false
    // }
    // if (!isDate(date.to.value, prefDatemask.DATEMASK)) {
    //   this.setError('date', 'to', Language.translate('general.incorrect_date'))
    //   return false
    // }
    return true
  }

  /**
   * @description Downloads the selected items with the users sort order.
   */
  handleDownload = () => {
    const { download, mask, fileExtension } = this.state
    let buffer = ''
    if (this.validRequiredFields()) {
      for (const [key, val] of Object.entries(download)) {
        if (val) {
          if (val.defaultOrder) {
            buffer += `RECORD_TYPE_${key.replace('type', '')}=1;\r\n`
          } else {
            if (val.checkedRows.length > 0) {
              let order = ''
              download[key].checkedRows.forEach((d, i) => i < download[key].checkedRows.length - 1 ? order += `${d},` : order += `${d};`)
              buffer += `RECORD_TYPE_${key.replace('type', '')}=1,${order}\r\n`
            } else {
              buffer += `RECORD_TYPE_${key.replace('type', '')}=0;\r\n`
            }
          }
        } else {
          buffer += `RECORD_TYPE_${key.replace('type', '')}=0;\r\n`
        }
      }
      buffer += '* ----------------------------------------------------\r\n'
      buffer += `DATEMASK=${DATEMASKS[mask.date]}\r\n`
      buffer += `TIMEMASK=${TIMEMASKS[mask.time]}\r\n`
      buffer += `OUTPUT_FILE_EXTENSION=${fileExtension}`

      const extMap = {
        doc: 'text/plain',
        csv: 'text/csv',
        txt: 'text/plain'
      }

      const config = new Blob(['\ufeff' + buffer], { encoding: 'UTF-8', type: `${extMap.fileExtension ?? 'text/plain'};charset=UTF-8` })
      const url = URL.createObjectURL(config)
      const a = document.querySelector('#downloadLink')
      a.href = url
      a.download = `Config.${fileExtension in extMap ? fileExtension : 'txt'}`
      this.props.onClose()
    }
  }

  /**
   * @description The drag end action from dnd.
   * @param {Object} result The result from dnd.
   */
  onDragEnd = result => {
    const { source, destination } = result
    // dropped outside the table
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      // get the current items with help of the active tab
      const currentItems = this.state.data[Object.keys(this.state.data)[this.state.activeTab]]
      const items = this.reorder(currentItems, source.index, destination.index)

      // clone data object
      let newData = { ...this.state.data }

      // only modify the current tab data
      newData[Object.keys(this.state.data)[this.state.activeTab]] = items

      this.setState({ data: newData, sortedCol: {} }, () => {
        this.updateCheckedRowsAfterDrag()
      })
    }
  }

  /**
   * @description Will be called when the sort ends.
   * @param {Array} sortedData The new sorted data.
   * @param {String} sortedCol The new sorted col.
   */
  onSortEnd = (sortedData, sortedCol) => {
    // clone data object
    let newData = { ...this.state.data }

    const newState = {}
    if (sortedData) {
      // only modify the current tab data
      newData[Object.keys(this.state.data)[this.state.activeTab]] = sortedData
      newState['data'] = newData
    }

    // clone sortedCol object
    let newSortedCol = { ...this.state.sortedCol }
    // only modify the current tab data
    newSortedCol[Object.keys(this.state.data)[this.state.activeTab]] = sortedCol
    newState['sortedCol'] = newSortedCol

    this.setState(newState)
  }

  /**
   * @description Reorders the checkedRows after dragged the rows.
   */
  updateCheckedRowsAfterDrag = () => {
    const { data, download, activeTab } = this.state
    const buffer = {}
    const dataKeys = Object.keys(data)
    // gets the index on the checkedRows and safes it with key in buffer variable
    dataKeys.forEach((key, indexOut) => {
      if (indexOut === activeTab) {
        data[key].forEach((entry, indexIn) => {
          if (download[key].checkedRows.includes(entry[0])) {
            buffer[entry[0]] = indexIn
          }
        })
      }
    })
    const bufferKeys = Object.keys(buffer)
    // compares the index of the key in buffer variable with the checked rows order in checkedRows variable and changes the position, when necessary
    for (let i = 0; i < bufferKeys.length - 1; i++) {
      if (buffer[bufferKeys[i]] > buffer[bufferKeys[i + 1]]) {
        const indexBuffer = buffer[bufferKeys[i]]
        buffer[bufferKeys[i]] = buffer[bufferKeys[i + 1]]
        buffer[bufferKeys[i + 1]] = indexBuffer
      }
    }
    this.setState({
      download: {
        ...this.state.download,
        [dataKeys[activeTab]]: {
          ...this.state.download[dataKeys[activeTab]],
          checkedRows: Object.keys(buffer)
        }
      }
    })
  }

  /**
   * @description Reorders an item inside a list.
   * @param {Array} list The list.
   * @param {Number} startIndex The source index of the item to reorder.
   * @param {Number} endIndex The destination index.
   * @return {Array} The reordered list.
   */
  reorder = (items, startIndex, endIndex) => {
    const newItems = [...items]
    const [removed] = newItems.splice(startIndex, 1)
    newItems.splice(endIndex, 0, removed)

    return newItems
  }

  render = () => {
    const { id, onClose, selected, headerInfo, lang, prefDatemask } = this.props
    const { mask, fileExtension, download, data } = this.state
    const tabs = []

    for (const [key, type] of Object.entries(selected)) {
      tabs.push(
        <Tab
          id={`${id}_tab${key}`}
          title={type.name}
          onChange>
          <Row>
            <Column colMD={12}>
              <label id={`${id}_tab${key}title`}>
                {type.title}
              </label>
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Checkbox
                id={`${id}_tab${key}_checkAll`}
                onCheck={isChecked => this.handleDownloadChanged({ group: key, key: 'defaultOrder', value: isChecked })}
                value={download[key] ? download[key].defaultOrder : false}
                label={Language.translate('server.accounting.checkAll')}
              />
            </Column>
          </Row>
          <Row className={'bux_min_height0'}>
            <Column colMD={12}>
              <div className={'bux_full_height'}
                disabled={download[key] && download[key].defaultOrder}>
                <DataTable
                  id={`${id}_tab${key}`}
                  onDragEnd={this.onDragEnd}
                  header={type.headers}
                  data={data[key]}
                  selectable
                  menu={false}
                  columnSortDefs={['string', 'string']}
                  checkedRows={download[key] && download[key].checkedRows}
                  updateCheckedRowsToParent={checkedRows => this.updateCheckedRows(key, checkedRows)}
                  onSortEnd={this.onSortEnd}
                  sortedCol={this.state.sortedCol[Object.keys(this.state.data)[this.state.activeTab]]}
                  language={lang}
                  datemask={prefDatemask}
                  translate={key => Language.translate(key)}
                />
              </div>
            </Column>
          </Row>
        </Tab>
      )
    }

    return (
      <Modal onClose={onClose} id={id} big={UserUtils.isLOGX()}>
        <Header
          id={id}
          title={Language.translate('server.accounting.select_records')}
          onClose={onClose}>
          {
            headerInfo &&
              <MetaDataGrid
                id={`${id}_header`}
                metaData={[
                  { label: Language.translate('server.accounting_opened_Accounting_file'), value: headerInfo['FILENAME'] },
                ]}
                columns={4}
              />
          }
        </Header>
        <Main id={id}>
          <div className={'accounting_main'}>
            <Card id={id}>
              <Row>
                {/* TODO: implement when needed */}
                {/* <Column colMD={2}>
                  <Datepicker
                    id={`${id}_fromdate`}
                    focusRef={this.dateFromInput}
                    title={Language.translate('general.from')}
                    value={date.from.value}
                    onChange={date => this.handleChangeWithError({ group: 'date', key: 'from', value: date })}
                    error={date.from.errorkey}
                    dateFormat
                    required
                  />
                </Column>
                <Column colMD={2}>
                  <Datepicker
                    id={`${id}_dateto`}
                    title={Language.translate('general.to')}
                    value={date.to.value}
                    onChange={date => this.handleChangeWithError({ group: 'date', key: 'to', value: date })}
                    error={date.to.errorkey}
                    dateFormat
                    required
                  />
                </Column> */}
                <Column colMD={3}>
                  <Dropdown
                    id={`${id}_datemask`}
                    title={Language.translate('general.datemask2')}
                    activeIndex={mask.date}
                    onChange={index => this.handleChange({ group: 'mask', key: 'date', value: index })}
                    items={DATEMASKS}
                  />
                </Column>
                <Column colMD={3}>
                  <Dropdown
                    id={`${id}_timemask`}
                    title={Language.translate('general.timemask')}
                    activeIndex={mask.time}
                    onChange={index => this.handleChange({ group: 'mask', key: 'time', value: index })}
                    items={TIMEMASKS}
                  />
                </Column>
                <Column colMD={2}>
                  <Input
                    id={`${id}_fileextension`}
                    value={fileExtension}
                    title={Language.translate('general.file_extension')}
                    maxLength={32}
                    onInputChanged={value => this.handleChange({ key: 'fileExtension', value: value })}
                  />
                </Column>
              </Row>
            </Card>
            <Tabs
              id={id}
              onTabChanged={(index) => this.setState({ activeTab: index })}>
              {tabs}
            </Tabs>
          </div>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancel`}
            text={Language.translate('general.cancel')}
            onClick={onClose}
          />
          <a id={'downloadLink'} href={'...'}>
            <Button
              id={`${id}_download`}
              text={Language.translate('general.download')}
              onClick={() => this.handleDownload()}
              primary
              submit
            />
          </a>
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    prefDatemask: auth.serverdata.preferences.DATEMASK,
    lang: auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

export default connect(mapStateToProps)(AccountingRecords)