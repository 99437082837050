import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'

// actions
export const MAINTENANCE_EXECUTE_JOB_START = 'MAINTENANCE_EXECUTE_JOB_START'
export const MAINTENANCE_EXECUTE_JOB_SUCCESS = 'MAINTENANCE_EXECUTE_JOB_SUCCESS'
export const MAINTENANCE_EXECUTE_JOB_FAILED = 'MAINTENANCE_EXECUTE_JOB_FAILED'

export const MAINTENANCE_GET_JOBS_START = 'MAINTENANCE_GET_JOBS_START'
export const MAINTENANCE_GET_JOBS_SUCCESS = 'MAINTENANCE_GET_JOBS_SUCCESS'
export const MAINTENANCE_GET_JOBS_FAILED = 'MAINTENANCE_GET_JOBS_FAILED'

export const MAINTENANCE_EXECUTE_UTILITY_START = 'MAINTENANCE_EXECUTE_UTILITY_START'
export const MAINTENANCE_EXECUTE_UTILITY_SUCCESS = 'MAINTENANCE_EXECUTE_UTILITY_SUCCESS'
export const MAINTENANCE_EXECUTE_UTILITY_FAILED = 'MAINTENANCE_EXECUTE_UTILITY_FAILED'

export const MAINTENANCE_GET_JOBOUTPUT_START = 'MAINTENANCE_GET_JOBOUTPUT_START'
export const MAINTENANCE_GET_JOBOUTPUT_SUCCESS = 'MAINTENANCE_GET_JOBOUTPUT_SUCCESS'
export const MAINTENANCE_GET_JOBOUTPUT_FAILED = 'MAINTENANCE_GET_JOBOUTPUT_FAILED'

export const NO_MAINTENANCE_JOBS_FOUND = 'NO_MAINTENANCE_JOBS_FOUND'

/**
 * @description Calls the rest api and executes a predefined job.
 * @param {String} jobname The name of the predefined job. Possible values:
 * 'DEONL', 'DELOG', 'DEPRT', 'DENTE', 'BKARC', 'BKARCN', 'BKCLN', 'BKRLD'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function executeJob(jobname, callback, executeCallbackAlways = false) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: MAINTENANCE_EXECUTE_JOB_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/maintenance/execjob`, {
        method: 'post',
        body: { JOBNAME: jobname }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          dispatch({ type: MAINTENANCE_EXECUTE_JOB_FAILED, payload: { error } })
          if (executeCallbackAlways && callback) {
            callback(error)
          }
          else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
        }
        else {
          dispatch({ type: MAINTENANCE_EXECUTE_JOB_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback(jsondata.data)
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('maintencance.execute_job_error', lang, [jobname, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: MAINTENANCE_EXECUTE_JOB_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and list all predefined jobs.
 * @param {Function} callback the callback which will be called when the request was successful. Can be null.
 */
export function getJobs(callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: MAINTENANCE_GET_JOBS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/maintenance/listjobs`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_MAINTENANCE_JOBS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: MAINTENANCE_GET_JOBS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: MAINTENANCE_GET_JOBS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('maintenance.jobs_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: MAINTENANCE_GET_JOBS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and executes an utility.
 * @param {String} utility The name utility. (UTL)
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function executeUtility(utility, callback, executeCallbackAlways = false) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: MAINTENANCE_EXECUTE_UTILITY_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/maintenance/executil`, {
        method: 'post',
        body: { UTL: utility }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          dispatch({ type: MAINTENANCE_EXECUTE_UTILITY_FAILED, payload: { error } })
          // If the callback should be executed always, then we handle the snackbar outside for multiple execution of this action.
          if (executeCallbackAlways && callback) {
            callback(error)
          }
          else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
        }
        else {
          dispatch({ type: MAINTENANCE_EXECUTE_UTILITY_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback(jsondata.data)
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('maintencance.execute_utility_error', lang, [utility, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: MAINTENANCE_EXECUTE_UTILITY_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets the jobOutput of a previously executed predefined job or utility.
 * @param {String} pid The PID of the previously executed job.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getJobOutput(pid, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: MAINTENANCE_GET_JOBOUTPUT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/maintenance/joboutput?PID=${encodeURIComponent(pid)}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: MAINTENANCE_GET_JOBOUTPUT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: MAINTENANCE_GET_JOBOUTPUT_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('maintencance.get_joboutput_error', lang, [pid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: MAINTENANCE_GET_JOBOUTPUT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}