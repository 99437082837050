import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'

import {
  Button, Card, Column,
  Input,
  Modal as ModalComponent, NumericSpinner, Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Header, Footer } = ModalComponent

class ModifyResultTableObjectDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    modifyObject: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    identifier: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    showValue: PropTypes.bool.isRequired
  }

  state = {
    width: this.props.width,
    identifier: this.props.identifier,
    value: this.props.value
  }

  /**
   * @description Saves the data and creates a result table.
   */
  handleSave = () => {
    const { width, identifier, value } = this.state
    const { modifyObject, onClose } = this.props

    modifyObject(width, identifier, value, onClose)
  }

  render = () => {
    const { id, onClose } = this.props

    return (
      <>
        <Modal
          id={'modify_resulttableobject_dialog'}
          onClose={onClose}
          size={'s'}>
          <Header
            id={id}
            title={translate('definition.result_table_modify_object')}
            onClose={onClose}>
          </Header>
          <Row>
            <Column colMD={12}>
              <Card>
                <Row>
                  <Column colMD={12}>
                    <NumericSpinner
                      id={`${id}_width`}
                      onChange={newVal => { this.setState({ width: newVal }) }}
                      max={80}
                      value={this.state.width ?? ''}
                      steps={1}
                      min={1}
                      title={translate('definition.result_table_column_width')}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column colMD={12}>
                    <Input
                      id={`${id}_identifier`}
                      onInputChanged={(val) => { this.setState({ identifier: val }) }}
                      maxLength={64}
                      value={this.state.identifier}
                      title={translate('definition.result_table_column_identifier')}
                    />
                  </Column>
                </Row>
                {
                  this.props.showValue && (
                    <Row>
                      <Column colMD={12}>
                        <Input
                          id={`${id}_value`}
                          onInputChanged={(val) => { this.setState({ value: val }) }}
                          value={this.state.value}
                          title={translate('definition.result_table_column_value')}
                        />
                      </Column>
                    </Row>
                  )
                }
              </Card>
            </Column>
          </Row>
          <Footer id={`${id}_footer`}>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

export default ModifyResultTableObjectDialog