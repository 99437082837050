import * as SecurityProfileActions from '../actions/SecurityProfileActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  securityProfile: undefined,
  securityProfiles: undefined,
  entityParts: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Create security profile actions
    case SecurityProfileActions.CREATE_SECURITY_PROFILE_START:
      return { ...state }
    case SecurityProfileActions.CREATE_SECURITY_PROFILE_SUCCESS:
      return { ...state }
    case SecurityProfileActions.CREATE_SECURITY_PROFILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get security profile actions
    case SecurityProfileActions.GET_SECURITY_PROFILE_START:
      return { ...state, securityProfile: undefined }
    case SecurityProfileActions.GET_SECURITY_PROFILE_SUCCESS:
      return { ...state, securityProfile: action.payload }
    case SecurityProfileActions.GET_SECURITY_PROFILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Delete security profile actions
    case SecurityProfileActions.DELETE_SECURITY_PROFILE_START:
      return { ...state }
    case SecurityProfileActions.DELETE_SECURITY_PROFILE_SUCCESS:
      return { ...state }
    case SecurityProfileActions.DELETE_SECURITY_PROFILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Update security profile actions
    case SecurityProfileActions.UPDATE_SECURITY_PROFILE_START:
      return { ...state }
    case SecurityProfileActions.UPDATE_SECURITY_PROFILE_SUCCESS:
      return { ...state }
    case SecurityProfileActions.UPDATE_SECURITY_PROFILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Refresh security profiles actions
    case SecurityProfileActions.REFRESH_SECURITY_PROFILES_START:
      return { ...state }
    case SecurityProfileActions.REFRESH_SECURITY_PROFILES_SUCCESS:
      return { ...state }
    case SecurityProfileActions.REFRESH_SECURITY_PROFILES_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get security profiles actions
    case SecurityProfileActions.GET_SECURITY_PROFILES_START:
      return { ...state }
    case SecurityProfileActions.GET_SECURITY_PROFILES_SUCCESS:
      return { ...state, securityProfiles: action.payload, keepPagination: action.keepPagination }
    case SecurityProfileActions.GET_SECURITY_PROFILES_FAILED:
      return { ...state }
    case SecurityProfileActions.NO_SECURITY_PROFILES_FOUND:
      return { ...state, securityProfiles: {} }
    /* ********************************************************************************** */
    // Get security profiles entity parts
    case SecurityProfileActions.GET_SECURITY_PROFILES_ENTITY_PARTS_START:
      return { ...state }
    case SecurityProfileActions.GET_SECURITY_PROFILES_ENTITY_PARTS_SUCCESS:
      return { ...state, entityParts: action.payload }
    case SecurityProfileActions.GET_SECURITY_PROFILES_ENTITY_PARTS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return {
        ...state,
        securityProfiles: undefined,
        securityProfile: undefined,
        entityParts: undefined
      }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer