import PropTypes from 'prop-types'
import { Component } from 'react'

import {
  Button, Card,
  Checkbox, Column,
  Input,
  Row
} from 'BetaUX2Web-Components/src/'
import { LetterCase } from 'BetaUX2Web-Components/src/types'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

import { translate } from 'language/Language'

import { connect } from 'react-redux'
import * as LPDQueueActions from 'redux/actions/LPDQueueActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'

class QueueLPD extends Component {

  defaultState = {
    showLPDQueueDefinitions: false,
    lpdQueueID: '',
    spoolingBegin: false,
    spoolingEnd: false,
    spoolingFailed: false,
    transferBegin: false,
    transferEnd: false,
    transferFailed: false,
    waitingForAutoprint: false
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount = () => {
    // initialize search fields
    const { preferences } = this.props

    if (preferences) {
      this.setState({
        lpdQueueID: preferences[Preferences.QUEUE_LPD_LPD_QUEUE_ID] || '',
        spoolingBegin: preferences[Preferences.QUEUE_LPD_SPOOLING_BEGIN] || false,
        spoolingEnd: preferences[Preferences.QUEUE_LPD_SPOOLING_END] || false,
        spoolingFailed: preferences[Preferences.QUEUE_LPD_SPOOLING_FAILED] || false,
        transferBegin: preferences[Preferences.QUEUE_LPD_TRANSFER_BEGIN] || false,
        transferEnd: preferences[Preferences.QUEUE_LPD_TRANSFER_END] || false,
        transferFailed: preferences[Preferences.QUEUE_LPD_TRANSFER_FAILED] || false,
        waitingForAutoprint: preferences[Preferences.QUEUE_LPD_WAITING_FOR_AUTOPRINT] || false
      })
    }
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }


  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = (event) => {
    const {
      lpdQueueID,
      spoolingBegin,
      spoolingEnd,
      spoolingFailed,
      transferBegin,
      transferEnd,
      transferFailed,
      waitingForAutoprint
    } = this.state
    const { changePrefs, getLPDQueues } = this.props
    event.preventDefault()

    // save search values in preferences
    const prefsToChange = {
      [Preferences.QUEUE_LPD_LPD_QUEUE_ID]: lpdQueueID,
      [Preferences.QUEUE_LPD_SPOOLING_BEGIN]: spoolingBegin,
      [Preferences.QUEUE_LPD_SPOOLING_END]: spoolingEnd,
      [Preferences.QUEUE_LPD_SPOOLING_FAILED]: spoolingFailed,
      [Preferences.QUEUE_LPD_TRANSFER_BEGIN]: transferBegin,
      [Preferences.QUEUE_LPD_TRANSFER_END]: transferEnd,
      [Preferences.QUEUE_LPD_TRANSFER_FAILED]: transferFailed,
      [Preferences.QUEUE_LPD_WAITING_FOR_AUTOPRINT]: waitingForAutoprint
    }
    changePrefs(prefsToChange)

    const searchObj = {
      QUEUE: lpdQueueID
    }
    getLPDQueues(searchObj)
  }

  /**
   * @description Handles the node id selector button
   */
  handleLPDQueueIDSelector = () => {
    this.props.getLPDQueueDefinitions(
      ['LPD', 'LPDINFO'],
      { LPD: this.state.lpdQueueID },
      () => this.setState({ showLPDQueueDefinitions: true })
    )
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { lpdQueueID } = this.state
    return (
      <Card title={translate('general.general')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_lpd_queue_id`}
              title={translate('definition.lpd_queue.id')}
              maxLength={16}
              value={lpdQueueID}
              onInputChanged={value => this.setState({ lpdQueueID: value })}
              addon={{
                iconName: 'list',
                onClick: () => this.handleLPDQueueIDSelector()
              }}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  renderStatusCard = () => {
    const { spoolingBegin, spoolingEnd, spoolingFailed, transferBegin, transferEnd, transferFailed, waitingForAutoprint } = this.state
    const { id } = this.props
    return (
      <Card title={translate('general.status')}>
        <Row>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_spooling_begin`}
              label={translate('queue.spooling_begin')}
              value={spoolingBegin}
              onCheck={value => this.setState({ spoolingBegin: value })}
            />
          </Column>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_transfer_begin`}
              label={translate('queue.transfer_begin')}
              value={transferBegin}
              onCheck={value => this.setState({ transferBegin: value })}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_spooling_end`}
              label={translate('queue.spooling_end')}
              value={spoolingEnd}
              onCheck={value => this.setState({ spoolingEnd: value })}
            />
          </Column>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_transfer_end`}
              label={translate('queue.transfer_end')}
              value={transferEnd}
              onCheck={value => this.setState({ transferEnd: value })}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_spooling_failed`}
              label={translate('queue.spooling_failed')}
              value={spoolingFailed}
              onCheck={value => this.setState({ spoolingFailed: value })}
            />
          </Column>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_transfer_failed`}
              label={translate('queue.transfer_failed')}
              value={transferFailed}
              onCheck={value => this.setState({ transferFailed: value })}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Checkbox
              id={`${id}_waiting_for_autoprint`}
              label={translate('queue.waiting_for_autoprint')}
              value={waitingForAutoprint}
              onCheck={value => this.setState({ waitingForAutoprint: value })}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderGeneralCard()}
        {/* //! Readd this when the REST-API provides it in GET-request */}
        {/* {this.renderStatusCard()} */}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={'drawer_content_queue_body_search'}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary />
        <Button
          id={'drawer_content_queue_body_resetBtn'}
          icon='undo'
          iconType='material'
          className='bux_iconResteBtn'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { id, selector } = this.props
    const { showLPDQueueDefinitions } = this.state
    return (
      <>
        {
          showLPDQueueDefinitions &&
          <SelectorDialog
            id={`${id}_lpd_queue_definitions_dialog`}
            onClose={() => this.setState({ showLPDQueueDefinitions: false })}
            title={translate('definition.lpd_queue_definitions')}
            header={[translate('definition.lpd_queue.id'), translate('general.title')]}
            items={selector.lpdQueues?.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const lpdQueueID = selector.lpdQueues.data[selectedRows][selector.lpdQueues.header.indexOf('LPD')]
                this.setState({ lpdQueueID })
              }
              this.setState({ showLPDQueueDefinitions: false })
            }}
          />
        }
        <form
          id={id}
          className='bux_drawer_form'
          onSubmit={this.handleSubmitSearch}>
          {this.renderMain()}
          {this.renderFooter()}
        </form>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch),
    getLPDQueueDefinitions: (fields, searchObj, callback) => ModalSelectorActions.getLpdQueueDefinitions(fields, searchObj, callback)(dispatch),
    getLPDQueues: (searchObj, callback) => LPDQueueActions.getLPDQueues(undefined, searchObj, callback)(dispatch)
  }
}

QueueLPD.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueLPD)