import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

export const CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_START = 'CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_START'
export const CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_SUCCESS = 'CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_SUCCESS'
export const CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_FAILED = 'CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_FAILED'
export const NO_CUSTOM_DIALOG_DEFINITION_CUSTOM_DIALOGS_FOUND = 'NO_CUSTOM_DIALOG_DEFINITION_CUSTOM_DIALOGS_FOUND'

export const CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOG_START = 'CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOG_START'
export const CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOG_SUCCESS = 'CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOG_SUCCESS'

export const CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_START = 'CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_START'
export const CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_SUCCESS = 'CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_SUCCESS'
export const CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_FAILED = 'CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_FAILED'
export const NO_CUSTOM_DIALOG_DEFINITION_DEFAULT_OBJECTS_FOUND = 'NO_CUSTOM_DIALOG_DEFINITION_DEFAULT_OBJECTS_FOUND'

export const CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_START = 'CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_START'
export const CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_SUCCESS = 'CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_SUCCESS'
export const CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_FAILED = 'CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_FAILED'
export const NO_CUSTOM_DIALOG_DEFINITION_INDEX_DEFAULT_OBJECTS_FOUND = 'NO_CUSTOM_DIALOG_DEFINITION_INDEX_DEFAULT_OBJECTS_FOUND'

export const CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_START = 'CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_START'
export const CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_SUCCESS = 'CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_SUCCESS'
export const CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_FAILED = 'CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_FAILED'
export const NO_CUSTOM_DIALOG_DEFINITION_ASSIGNED_OBJECTS_FOUND = 'NO_CUSTOM_DIALOG_DEFINITION_ASSIGNED_OBJECTS_FOUND'

export const CUSTOM_DIALOG_DEFINITION_DELETE_SUCCESS = 'CUSTOM_DIALOG_DEFINITION_DELETE_SUCCESS'

export const GET_CUSTOM_DIALOG_OBJECTS_START = 'GET_CUSTOM_DIALOG_OBJECTS_START'
export const GET_CUSTOM_DIALOG_OBJECTS_SUCCESS = 'GET_CUSTOM_DIALOG_OBJECTS_SUCCESS'
export const GET_CUSTOM_DIALOG_OBJECTS_FAILED = 'GET_CUSTOM_DIALOG_OBJECTS_FAILED'

export const GET_CUSTOM_DIALOG_AVAILABLE_JOBTYPES_SUCCESS = 'GET_CUSTOM_DIALOG_AVAILABLE_JOBTYPES_SUCCESS'
export const GET_CUSTOM_DIALOG_AVAILABLE_JOBTYPES_FAILED = 'GET_CUSTOM_DIALOG_AVAILABLE_JOBTYPES_FAILED'

/**
 * @description Calling the rest api and requests get custom dialogs.
 * @param {String} dialogID
 * @param {String} description
 * @param {String} owner
 * @param {String} resultTableID
 * @param {String} command
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getCustomDialogs(dialogID, description, owner, resultTableID, command, jobtype, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_START })
    const queryParams = []
    if (dialogID) { queryParams.push(`SPTINAME=${encodeURIComponent(dialogID)}`) }
    if (description) { queryParams.push(`SPTENAME=${encodeURIComponent(description)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (resultTableID) { queryParams.push(`SLTINAME=${encodeURIComponent(resultTableID)}`) }
    if (command) { queryParams.push(`SPTUXCMD=${encodeURIComponent(command)}`) }
    if (jobtype) { queryParams.push(`SLTITYPE=${encodeURIComponent(jobtype)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchprofiles?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_CUSTOM_DIALOG_DEFINITION_CUSTOM_DIALOGS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_FAILED })
          }
        } else {
          dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_SUCCESS, payload: jsondata, keepPagination })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.custom_dialogs_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and get a specific custom dialog
 * @param {String} customDialogID
 * @param {Function} callback
 * @param {Boolean} keepPagination
 */
export function getCustomDialog(customDialogID, callback, keepPagination = false) {
  return dispatch => {
    dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOG_START })
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchprofile?SPTINAME=${encodeURIComponent(customDialogID)}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    return cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        }
        else {
          dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOG_SUCCESS, payload: jsondata, keepPagination })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.custom_dialog_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a custom dialog definition.
 * @param {Object} customDialog "SPTINAME", "SPTENAME", "SPTUXCMD", "SLTINAME", "MAXENTRY", "SPTDND",
 * "DNDNAME", "OWNER", "OBJECTS"
 * @param {Function} callback The callback which will be called when the create custom dialog request was successful.
 */
export function createCustomDialog(customDialog, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchprofile`, {
        method: 'post',
        body: ObjectUtils.removeByValue(customDialog, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.create_custom_dialog_success', lang, customDialog['SPTINAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.create_custom_dialog_error', lang, [customDialog['SPTINAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and modifies a custom dialog definition.
 * @param {Object} customDialog "SPTINAME", "SPTENAME", "SPTUXCMD", "SLTINAME", "MAXENTRY", "SPTDND",
 * "DNDNAME", "OWNER", "OBJECTS"
 * @param {Function} callback The callback which will be called when request was successful.
 */
export function modifyCustomDialog(customDialog, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchprofile`, {
        method: 'put',
        body: ObjectUtils.removeByValue(customDialog, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.modify_custom_dialog_success', lang, customDialog['SPTINAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.modify_custom_dialog_error', lang, [customDialog['SPTINAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes an entry
 * @param {Object} resultTable
 * @param {Function} callback
 */
export function deleteCustomDialog(customDialog, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchprofile${createQueryParamsForFetch(ObjectUtils.removeByValue(customDialog, [undefined, null]))}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        }
        else {
          dispatch({ type: CUSTOM_DIALOG_DEFINITION_DELETE_SUCCESS })
          SnackbarActions.show(Lang.translate('definition.delete_custom_dialog_success', lang, customDialog['SPTINAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_custom_dialog_error', lang, [customDialog['SPTINAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Refreshes the search with redux prefs
 */
export const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    getCustomDialogs(
      prefs[Preferences.DEFINITION_CUSTOM_DIALOG_ID],
      prefs[Preferences.DEFINITION_CUSTOM_DIALOG_DESCRIPTION],
      prefs[Preferences.DEFINITION_CUSTOM_DIALOG_OWNER],
      prefs[Preferences.DEFINITION_CUSTOM_DIALOG_RESULT_TABLE_ID],
      prefs[Preferences.DEFINITION_CUSTOM_DIALOG_COMMAND],
      prefs[Preferences.DEFINITION_CUSTOM_DIALOG_JOBTYPE],
      undefined,
      true
    )(dispatch)
  }
}

/**
 * @description Calling the rest api and requests the default objects.
 * @param {String} buxCmd The search bux command. Possible fields: 'SELDOC', 'IMPORT', 'SELJOB'
 * @param {String} args The mandatory args. Only needed when buxCmd is 'SELJOB'. Possible values: '', 'LOGSOURCE(B92)', 'LOGSOURCE(UC4)'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getIndexDefaultObjects(callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/defaultsearchprofileindexes`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    return cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            dispatch({ type: NO_CUSTOM_DIALOG_DEFINITION_INDEX_DEFAULT_OBJECTS_FOUND, buxCmd: 'INDEX' })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_FAILED })
          }
        } else {
          dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_SUCCESS, payload: jsondata, buxCmd: 'INDEX' })
        }

        // Execute callback is not effected if the request was successfull or failed.
        if (callback) {
          callback()
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.custom_dialog_default_objects_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and requests the default objects.
 * @param {String} buxCmd The search bux command. Possible fields: 'SELDOC', 'IMPORT', 'SELJOB'
 * @param {String} args The mandatory args. Only needed when buxCmd is 'SELJOB'. Possible values: '', 'LOGSOURCE(B92)', 'LOGSOURCE(UC4)'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getDefaultObjects(buxCmd, args, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_START })
    const queryParams = []
    if (buxCmd) { queryParams.push(`SPTUXCMD=${encodeURIComponent(buxCmd)}`) }
    if (args) { queryParams.push(`SLTITYPE=${encodeURIComponent(args)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/defaultsearchprofileobjects?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    return cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_CUSTOM_DIALOG_DEFINITION_DEFAULT_OBJECTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_FAILED })
          }
        } else {
          dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_SUCCESS, payload: jsondata, buxCmd, args })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.custom_dialog_default_objects_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and requests the assigned objects.
 * @param {String} searchProfile The internal name of the search profile.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getAssignedObjects(searchProfile, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_START })
    const queryParams = []
    if (searchProfile) { queryParams.push(`SPTINAME=${encodeURIComponent(searchProfile)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchprofileobjects?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_CUSTOM_DIALOG_DEFINITION_ASSIGNED_OBJECTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_FAILED })
          }
        } else {
          dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_SUCCESS, payload: jsondata })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.custom_dialog_assigned_objects_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getCustomDialogObjects(sptiname, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_CUSTOM_DIALOG_OBJECTS_START })

    /* Build request-parameter for URL */
    const queryParams = [`SPTINAME=${encodeURIComponent(sptiname)}`]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/searchprofileobjects?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_CUSTOM_DIALOG_OBJECTS_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_CUSTOM_DIALOG_OBJECTS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.custom_dialog_error', lang, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_CUSTOM_DIALOG_OBJECTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and returning the available job types
 */
export const getAvailableTypes = () => {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchprofiletypes`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          dispatch({ type: GET_CUSTOM_DIALOG_AVAILABLE_JOBTYPES_FAILED })
        }
        else {
          LoadingSpinnerActions.hide()(dispatch)
          dispatch({ type: GET_CUSTOM_DIALOG_AVAILABLE_JOBTYPES_SUCCESS, payload: jsondata.data })
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.available_jobtypes_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}