import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'

// actions
export const SELECTOR_DEFINITION_GET_RECIPIENTS_START = 'SELECTOR_DEFINITION_GET_RECIPIENTS_START'
export const SELECTOR_DEFINITION_GET_RECIPIENTS_SUCCESS = 'SELECTOR_DEFINITION_GET_RECIPIENTS_SUCCESS'
export const SELECTOR_DEFINITION_GET_RECIPIENTS_FAILED = 'SELECTOR_DEFINITION_GET_RECIPIENTS_FAILED'

export const SELECTOR_DEFINITION_GET_DOCUMENTS_START = 'SELECTOR_DEFINITION_GET_DOCUMENTS_START'
export const SELECTOR_DEFINITION_GET_DOCUMENTS_SUCCESS = 'SELECTOR_DEFINITION_GET_DOCUMENTS_SUCCESS'
export const SELECTOR_DEFINITION_GET_DOCUMENTS_FAILED = 'SELECTOR_DEFINITION_GET_DOCUMENTS_FAILED'

export const SELECTOR_DEFINITION_GET_LOGS_START = 'SELECTOR_DEFINITION_GET_LOGS_START'
export const SELECTOR_DEFINITION_GET_LOGS_SUCCESS = 'SELECTOR_DEFINITION_GET_LOGS_SUCCESS'
export const SELECTOR_DEFINITION_GET_LOGS_FAILED = 'SELECTOR_DEFINITION_GET_LOGS_FAILED'

export const SELECTOR_DEFINITION_GET_OUTPUTFORMATS_START = 'SELECTOR_DEFINITION_GET_OUTPUTFORMATS_START'
export const SELECTOR_DEFINITION_GET_OUTPUTFORMATS_SUCCESS = 'SELECTOR_DEFINITION_GET_OUTPUTFORMATS_SUCCESS'
export const SELECTOR_DEFINITION_GET_OUTPUTFORMATS_FAILED = 'SELECTOR_DEFINITION_GET_OUTPUTFORMATS_FAILED'

export const SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_START = 'SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_START'
export const SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_SUCCESS = 'SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_SUCCESS'
export const SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_FAILED = 'SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_FAILED'

export const SELECTOR_DEFINITION_GET_FOLDERS_START = 'SELECTOR_DEFINITION_GET_FOLDERS_START'
export const SELECTOR_DEFINITION_GET_FOLDERS_SUCCESS = 'SELECTOR_DEFINITION_GET_FOLDERS_SUCCESS'
export const SELECTOR_DEFINITION_GET_FOLDERS_FAILED = 'SELECTOR_DEFINITION_GET_FOLDERS_FAILED'

export const SELECTOR_DEFINITION_GET_INDEXES_START = 'SELECTOR_DEFINITION_GET_INDEXES_START'
export const SELECTOR_DEFINITION_GET_INDEXES_SUCCESS = 'SELECTOR_DEFINITION_GET_INDEXES_SUCCESS'
export const SELECTOR_DEFINITION_GET_INDEXES_FAILED = 'SELECTOR_DEFINITION_GET_INDEXES_FAILED'

export const SELECTOR_DEFINITION_GET_PPNS_START = 'SELECTOR_DEFINITION_GET_PPNS_START'
export const SELECTOR_DEFINITION_GET_PPNS_SUCCESS = 'SELECTOR_DEFINITION_GET_PPNS_SUCCESS'
export const SELECTOR_DEFINITION_GET_PPNS_FAILED = 'SELECTOR_DEFINITION_GET_PPNS_FAILED'

export const SELECTOR_DEFINITION_GET_FILTERARGUMENTS_START = 'SELECTOR_DEFINITION_GET_FILTERARGUMENTS_START'
export const SELECTOR_DEFINITION_GET_FILTERARGUMENTS_SUCCESS = 'SELECTOR_DEFINITION_GET_FILTERARGUMENTS_SUCCESS'
export const SELECTOR_DEFINITION_GET_FILTERARGUMENTS_FAILED = 'SELECTOR_DEFINITION_GET_FILTERARGUMENTS_FAILED'

export const SELECTOR_DEFINITION_GET_RESULT_TABLES_START = 'SELECTOR_DEFINITION_GET_RESULT_TABLES_START'
export const SELECTOR_DEFINITION_GET_RESULT_TABLES_SUCCESS = 'SELECTOR_DEFINITION_GET_RESULT_TABLES_SUCCESS'
export const SELECTOR_DEFINITION_GET_RESULT_TABLES_FAILED = 'SELECTOR_DEFINITION_GET_RESULT_TABLES_FAILED'

export const SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_START = 'SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_START'
export const SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_SUCCESS = 'SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_SUCCESS'
export const SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_FAILED = 'SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_FAILED'

export const SELECTOR_DEFINITION_GET_DOCUMENTNODES_START = 'SELECTOR_DEFINITION_GET_DOCUMENTNODES_START'
export const SELECTOR_DEFINITION_GET_DOCUMENTNODES_SUCCESS = 'SELECTOR_DEFINITION_GET_DOCUMENTNODES_SUCCESS'
export const SELECTOR_DEFINITION_GET_DOCUMENTNODES_FAILED = 'SELECTOR_DEFINITION_GET_DOCUMENTNODES_FAILED'

export const SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_START = 'SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_START'
export const SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_SUCCESS = 'SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_SUCCESS'
export const SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_FAILED = 'SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_FAILED'

export const SELECTOR_DEFINITION_GET_JOBGROUPS_START = 'SELECTOR_DEFINITION_GET_JOBGROUPS_START'
export const SELECTOR_DEFINITION_GET_JOBGROUPS_SUCCESS = 'SELECTOR_DEFINITION_GET_JOBGROUPS_SUCCESS'
export const SELECTOR_DEFINITION_GET_JOBGROUPS_FAILED = 'SELECTOR_DEFINITION_GET_JOBGROUPS_FAILED'

export const SELECTOR_DEFINITION_GET_LPD_QUEUES_START = 'SELECTOR_DEFINITION_GET_LPD_QUEUES_START'
export const SELECTOR_DEFINITION_GET_LPD_QUEUES_SUCCESS = 'SELECTOR_DEFINITION_GET_LPD_QUEUES_SUCCESS'
export const SELECTOR_DEFINITION_GET_LPD_QUEUES_FAILED = 'SELECTOR_DEFINITION_GET_LPD_QUEUES_FAILED'

export const SELECTOR_DEFINITION_NO_RECIPIENTS_FOUND = 'SELECTOR_DEFINITION_NO_RECIPIENTS_FOUND'
export const SELECTOR_DEFINITION_NO_DOCUMENTS_FOUND = 'SELECTOR_DEFINITION_NO_DOCUMENTS_FOUND'
export const SELECTOR_DEFINITION_NO_LOGS_FOUND = 'SELECTOR_DEFINITION_NO_LOGS_FOUND'
export const SELECTOR_DEFINITION_NO_OUTPUTFORMATS_FOUND = 'SELECTOR_DEFINITION_NO_OUTPUTFORMATS_FOUND'
export const SELECTOR_DEFINITION_NO_OUTPUTCHANNELS_FOUND = 'SELECTOR_DEFINITION_NO_OUTPUTCHANNELS_FOUND'
export const SELECTOR_DEFINITION_NO_FOLDERS_FOUND = 'SELECTOR_DEFINITION_NO_FOLDERS_FOUND'
export const SELECTOR_DEFINITION_NO_INDEXES_FOUND = 'SELECTOR_DEFINITION_NO_INDEXES_FOUND'
export const SELECTOR_DEFINITION_NO_PPNS_FOUND = 'SELECTOR_DEFINITION_NO_PPNS_FOUND'
export const SELECTOR_DEFINITION_NO_FILTERARGUMENTS_FOUND = 'SELECTOR_DEFINITION_NO_PPNS_FOUND'
export const SELECTOR_DEFINITION_NO_RESULT_TABLES_FOUND = 'SELECTOR_DEFINITION_NO_RESULT_TABLES_FOUND'
export const SELECTOR_DEFINITION_NO_CUSTOM_DIALOGS_FOUND = 'SELECTOR_DEFINITION_NO_CUSTOM_DIALOGS_FOUND'
export const SELECTOR_DEFINITION_NO_DOCUMENTNODES_FOUND = 'SELECTOR_DEFINITION_NO_DOCUMENTNODES_FOUND'
export const SELECTOR_DEFINITION_NO_JOBGROUPS_FOUND = 'SELECTOR_NO_JOBGROUPS_DEFINITION_FOUND'
export const SELECTOR_DEFINITION_NO_LPD_QUEUES_FOUND = 'SELECTOR_DEFINITION_NO_LPD_QUEUES_FOUND'
export const SELECTOR_NO_RECIPIENT_DOCUMENT_ASSIGNMENTS_FOUND = 'SELECTOR_NO_RECIPIENT_DOCUMENT_ASSIGNMENTS_FOUND'

export const GET_DATA_FROM_BQL_QUERY_START = 'GET_DATA_FROM_BQL_QUERY_START'
export const GET_DATA_FROM_BQL_QUERY_SUCCESS = 'GET_DATA_FROM_BQL_QUERY_SUCCESS'
export const GET_DATA_FROM_BQL_QUERY_FAILED = 'GET_DATA_FROM_BQL_QUERY_FAILED'
export const GET_DATA_FROM_BQL_QUERY_NO_RESULT = 'GET_DATA_FROM_BQL_QUERY_NO_RESULT'

export const MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_START = 'MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_START'
export const MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_SUCCESS = 'MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_SUCCESS'
export const MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_FAILED = 'MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_FAILED'
export const MODALSELECTOR_NO_HIERARCHY_ENTRY_FOUND = 'MODALSELECTOR_NO_HIERARCHY_ENTRY_FOUND'

export const MODALSELECTOR_HIERARCHY_ENTRIES_UPDATED = 'MODALSELECTOR_RECIPIENTS_HIERARCHY_ENTRIES_UPDATED'

/**
 * @description Calling the rest api and do getRecipientDocumentAssignments request.
 * @param {Object} fields The fields to select (If undefined => Return all fields).
 * Possible fields:
 * 'RECI', 'FORM', 'EXT', 'REPORT', 'WREPORT', 'LTYPE', 'BRWS', 'OWNER', 'CUSER', 'CDATE', 'CTIME'
 * @param {String} recipientId The recipientid to search for. Blank if not a searchcriteria.
 * @param {String} form The form to search for. Blank if not a searchcriteria.
 * @param {String} extension The extension to search for. Blank if not a searchcriteria.
 * @param {String} report The report to search for. Blank if not a searchcriteria.
 * @param {String} dynamicReport The dynamicReport to search for. Blank if not a searchcriteria.
 * @param {String} assignment The assignment to search for.
 * @param {String} distributionVia The distribution via mode to search for.
 * @param {String} outputChannel The output channel to search for. Blank if not a searchcriteria.
 * @param {String} outputFormat The output format to search for. Blank if not a searchcriteria.
 * @param {String} ppn The post processing note to search for. Blank if not a searchcriteria.
 * @param {Function} callback The callback which will be called of the request was successful.
 */
export function getRecipientDocumentAssignments(fields, recipientId, form,
  extension, report, dynamicReport, assignment, distributionVia, outputChannel, outputFormat, ppn, callback) {

  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (recipientId) { queryParams.push(`RECI=${encodeURIComponent(recipientId)}`) }
    queryParams.push(`FORM=${encodeURIComponent(form)}`)
    queryParams.push(`EXT=${encodeURIComponent(extension)}`)
    queryParams.push(`REPORT=${encodeURIComponent(report)}`)
    queryParams.push(`WREPORT=${encodeURIComponent(dynamicReport)}`)
    if (assignment) { queryParams.push(`LTYPE=${encodeURIComponent(assignment)}`) }
    if (distributionVia) { queryParams.push(`BRWS=${encodeURIComponent(distributionVia)}`) }
    if (outputChannel) { queryParams.push(`DCR=${encodeURIComponent(outputChannel)}`) }
    if (outputFormat) { queryParams.push(`PCR=${encodeURIComponent(outputFormat)}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipientdocumentassignments?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_NO_RECIPIENT_DOCUMENT_ASSIGNMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_FAILED })
          }
        }
        else {
          dispatch({ type: SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.recipient_document_assignments.loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getRecipients request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'RECI', 'PREDRECI', 'RTYPE', 'OWNER', 'TITLE', 'BRWS', 'DCR', 'PCR', 'PPN', 'CDATE', 'CTIME'
 * @param {String} rtype The recipient type. Possible values:
 * 'ADDR', 'RECI', 'ALIAS' or ''
 * @param {String} distributionType The distribution type. Possible values:
 * 'ONLINE', 'AUTO-P', 'BUNDLE', 'ALL'
 * @param {String} recipient The recipient pattern.
 * @param {String} preRecipient The pre recipient pattern.
 * @param {String} owner The owner pattern.
 * @param {String} title The title pattern.
 * @param {String} outputchannel The outputchannel pattern.
 * @param {String} outputformat The outputformat pattern.
 * @param {String} ppn The post precess note pattern.
 * @param {Function} callback the callback which will be called when the request was successful.
 */
export function getRecipientDefinitions(fields, rtype, distributionType, recipient, preRecipient, owner, title, outputchannel, outputformat, ppn, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_RECIPIENTS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (rtype) { queryParams.push(`RTYPE=${encodeURIComponent(rtype)}`) }
    if (distributionType) { queryParams.push(`BRWS=${encodeURIComponent(distributionType)}`) }
    if (recipient) { queryParams.push(`RECI=${encodeURIComponent(recipient)}`) }
    if (preRecipient) { queryParams.push(`PREDRECI=${encodeURIComponent(preRecipient)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`TITLE=${encodeURIComponent(title)}`) }
    if (outputchannel) { queryParams.push(`DCR=${encodeURIComponent(outputchannel)}`) }
    if (outputformat) { queryParams.push(`PDC=${encodeURIComponent(outputformat)}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipients?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_RECIPIENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_RECIPIENTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SELECTOR_DEFINITION_GET_RECIPIENTS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.recipients_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_RECIPIENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getDocuments request.
 * @param {Object} fields The fields to select (If undefined -> Return all fields). Possible fields:
 * 'FORM', 'EXT', 'REPORT', 'SMODE', 'BMODE', 'LTITLE', 'OWNER', 'CDATE', 'CTIME'
 * @param {String} form The form pattern.
 * @param {String} extension The extention pattern.
 * @param {String} report The report pattern.
 * @param {String} smode The smode. Possible values:
 * 'START/STOP', 'ABSOLUTE', 'CONTROL', 'AUTOBURST', 'LIMIT/BURST', 'COND/BURST1',
 * 'COND/BURST2', 'COND/BURST3', 'FILTER', 'EXCLUDE', or ''
 * @param {String} process The process mode. Possible Values:
 * 'LIST', 'REPORT', 'ALL' or ''
 * @param {String} owner The owner pattern.
 * @param {String} title The report pattern.
 * @param {String} ppn The post process note pattern.
 * @param {Function} callback The callback which will be called when the request was successful.
 * @param {Boolean} strict Enables empty parameters. Currently only used for extension.
 */
export function getDocumentDefinitions(fields, form, extension, report, smode, process, owner, title, ppn, callback, strict) {
  // default serach to get search results when input didn't have value
  if (form === '') {
    form = '*'
  }
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    queryParams.push(`FORM=${encodeURIComponent(form)}`)
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (extension || strict) { queryParams.push(`EXT=${encodeURIComponent(extension)}`) }
    if (report) { queryParams.push(`REPORT=${encodeURIComponent(report)}`) }
    if (smode) { queryParams.push(`SMODE=${encodeURIComponent(smode)}`) }
    if (process) { queryParams.push(`PROCESS=${encodeURIComponent(process)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`TITLE=${encodeURIComponent(title)}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documents?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_DOCUMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.documents_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getDocuments request.
 * @param {Object} fields The fields to select (If undefined -> Return all fields). Possible fields:
 * 'FORM', 'EXT', 'REPORT', 'SMODE', 'BMODE', 'LTITLE', 'OWNER', 'CDATE', 'CTIME'
 * @param {String} form The form pattern.
 * @param {String} extension The extention pattern.
 * @param {String} report The report pattern.
 * @param {String} smode The smode. Possible values:
 * 'START/STOP', 'ABSOLUTE', 'CONTROL', 'AUTOBURST', 'LIMIT/BURST', 'COND/BURST1',
 * 'COND/BURST2', 'COND/BURST3', 'FILTER', 'EXCLUDE', or ''
 * @param {String} process The process mode. Possible Values:
 * 'LIST', 'REPORT', 'ALL' or ''
 * @param {String} owner The owner pattern.
 * @param {String} title The report pattern.
 * @param {String} ppn The post process note pattern.
 * @param {Function} callback The callback which will be called when the request was successful.
 * @param {Boolean} strict Enables empty parameters. Currently only used for extension.
 */
export function getLogDefinitions(fields, form, extension, report, smode, process, owner, title, ppn, callback, strict) {
  // default serach to get search results when input didn't have value
  if (form === '') {
    form = '*'
  }
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_LOGS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    queryParams.push(`FORM=${encodeURIComponent(form)}`)
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (extension || strict) { queryParams.push(`EXT=${encodeURIComponent(extension)}`) }
    if (report) { queryParams.push(`REPORT=${encodeURIComponent(report)}`) }
    if (smode) { queryParams.push(`SMODE=${encodeURIComponent(smode)}`) }
    if (process) { queryParams.push(`PROCESS=${encodeURIComponent(process)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`TITLE=${encodeURIComponent(title)}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documents?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_LOGS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_LOGS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SELECTOR_DEFINITION_GET_LOGS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.documents_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_LOGS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getOutoutFormats request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'PCR', 'PCRTITLE', 'OWNER', 'CDATE', 'CTIME', 'CUSER'
 * @param {String} outputFormat The output format pattern.
 * @param {String} owner The owner pattern.
 * @param {String} title The title of the output format.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getOutputFormatDefinitions(fields, outputFormat, owner, title, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_OUTPUTFORMATS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (outputFormat) { queryParams.push(`PCR=${encodeURIComponent(outputFormat)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`PCRTITLE=${encodeURIComponent(title)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputFormats?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_OUTPUTFORMATS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SELECTOR_DEFINITION_GET_OUTPUTFORMATS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.outputformats_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getOutoutChannels request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'DCR', 'DCRTYPE', 'DCRTITLE', 'DCRRQ', 'DCRRQFLG', 'CDATE', 'CTIME'
 * @param {String} outputChannel The output channel pattern.
 * @param {String} type The type of the output channel. Possible values:
 * 'CMD', 'IP/T', 'APPL' or ''
 * @param {String} title The title of the output channel.
 * @param {String} requeue The requeue name pattern.
 * @param {Boolean} enableRequeue The flag to enable requeue.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getOutputChannelDefinitions(fields, outputChannel, type, title, requeue, enableRequeue, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (outputChannel) { queryParams.push(`DCR=${encodeURIComponent(outputChannel)}`) }
    if (type) { queryParams.push(`DCRTYPE=${encodeURIComponent(type)}`) }
    if (title) { queryParams.push(`DCRTITLE=${encodeURIComponent(title)}`) }
    if (requeue) { queryParams.push(`DCRRQ=${encodeURIComponent(requeue)}`) }
    if (enableRequeue) { queryParams.push(`DCRRQFLG=${encodeURIComponent(enableRequeue)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputChannels?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_OUTPUTCHANNELS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.outputchannels_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_OUTPUTCHANNELS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getFolders request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'GLRNAME', 'OWNER', 'GLRTITLE', 'CDATE', 'CTIME'
 * @param {String} folderid The folderid pattern.
 * @param {String} foldertitle The foldertitle pattern.
 * @param {String} owner The owner pattern.
 * @param {String} brw Used for browsing. allowed values: 'Y' or ''. If BRW=Y is specified a parameter for the security checks is setup.
 * @param {Function} callback The callback which will be called of the request was successful.
 */
export function getFolderDefinitions(fields, folderid, foldertitle, owner, brw, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_FOLDERS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (folderid) { queryParams.push(`GLRNAME=${encodeURIComponent(folderid)}`) }
    if (foldertitle) { queryParams.push(`GLRTITLE=${encodeURIComponent(foldertitle)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (brw) { queryParams.push(`BRW=${encodeURIComponent(brw)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folders?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_FOLDERS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_FOLDERS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SELECTOR_DEFINITION_GET_FOLDERS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.folders_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_FOLDERS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getPostProcessingNotes request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'PPN': name of the post processing note (required),
 * 'PPNTITLE': long description of the post processing note,
 * 'OWNER': owner of the post processing note,
 * 'CDATE': last modified date,
 * 'CTIME': last modified time,
 * 'CUSER': control user
 * @param {String} ppn The post processing notes name pattern. (required)
 * @param {String} owner The post processing owner pattern.
 * @param {String} ppnTitle The post processing title pattern.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getPPNDefinitions(fields, ppn, owner, ppnTitle, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_PPNS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (ppnTitle) { queryParams.push(`PPNTITLE=${encodeURIComponent(ppnTitle)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/postProcessingNotes?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_PPNS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_PPNS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SELECTOR_DEFINITION_GET_PPNS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.ppns_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_PPNS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getIndexes request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'IXINAME': The index name,
 * 'IXENAME': The index external name,
 * 'IXDTYPE': The index type,
 * 'OWNER': The owner of the index definition,
 * 'CDATE': The creation date,
 * 'CTIME': The creation time,
 * 'CUSER': The creator of the index definition
 * @param {String} index The index name.
 * @param {String} identifier The index external name.
 * @param {String} dataFormat The index type.
 * @param {String} owner The owner of the index definition.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getIndexes(fields, index, identifier, dataFormat, owner, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_INDEXES_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (index) { queryParams.push(`IXINAME=${encodeURIComponent(index)}`) }
    if (identifier) { queryParams.push(`IXENAME=${encodeURIComponent(identifier)}`) }
    if (dataFormat) { queryParams.push(`IXDTYPE=${encodeURIComponent(dataFormat)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/indexes?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_INDEXES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_INDEXES_FAILED, payload: { error } })
          }
        } else {
          dispatch({ type: SELECTOR_DEFINITION_GET_INDEXES_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.get_indexes_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_INDEXES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets filter arguments.
 * @param {*} fields The fields to select (If undefined => Return all fields). Possible fields:
 *
 * @param {*} owner The owner of the filter argument.
 * @param {*} filterArgument The name/id of the filter argument.
 * @param {*} title The title of the filter argument.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getFilterArgumentsDefinitions(fields, owner, filterArgument, title, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_FILTERARGUMENTS_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (filterArgument) { queryParams.push(`SLF=${encodeURIComponent(filterArgument)}`) }
    if (title) { queryParams.push(`SLFTITLE=${encodeURIComponent(title)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/filterarguments?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_FILTERARGUMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_FILTERARGUMENTS_FAILED, payload: { error } })
          }
        } else {
          dispatch({ type: SELECTOR_DEFINITION_GET_FILTERARGUMENTS_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.filter_argument_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_FILTERARGUMENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and requests get result tables.
 * @param {Array} fields which fields we get back from request
 * @param {String} resultTableID
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getResultTableDefinitions(fields, resultTableID, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_RESULT_TABLES_START })
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (resultTableID) { queryParams.push(`SLTINAME=${encodeURIComponent(resultTableID)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchresults?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_RESULT_TABLES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_RESULT_TABLES_FAILED })
          }
        } else {
          dispatch({ type: SELECTOR_DEFINITION_GET_RESULT_TABLES_SUCCESS, payload: jsondata })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.result_table_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_RESULT_TABLES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and requests get custom dialogs.
 * @param {Array} fields which fields we get back from request
 * @param {String} dialogID
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getCustomDialogDefinitions(fields, dialogID, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_START })
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (dialogID) { queryParams.push(`SPTINAME=${encodeURIComponent(dialogID)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchprofiles?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_CUSTOM_DIALOGS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_FAILED })
          }
        } else {
          dispatch({ type: SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_SUCCESS, payload: jsondata })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.custom_dialog_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and requests get documentnodes.
 * @param {Array} fields which fields we get back from request
 * @param {String} nodeName
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getDocumentNodesDefinition(fields, nodeName, callback, withAssignment) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_START })
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (nodeName) { queryParams.push(`DNDNAME=${encodeURIComponent(nodeName)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodes?${queryParams.join('&')}`, { method: 'get' })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_DOCUMENTNODES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTNODES_FAILED })
          }
        } else {
          const dataToUse = withAssignment
            ? {
              ...jsondata,
              data: {
                ...jsondata.data,
                data: [...jsondata.data.data].map(d => {
                  let result = [...d]
                  result.splice(3, 0, '')
                  result.splice(4, 0, '')
                  return result
                })
              }
            }
            : jsondata
          dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTNODES_SUCCESS, payload: dataToUse })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.document_nodes_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTNODES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and requests get documentnodes by type group.
 * @param {Array} fields which fields we get back from request
 * @param {String} nodeName
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getDocumentNodesDefinitionTypeGroup(fields, nodeName, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_START })
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (nodeName) { queryParams.push(`DNDNAME=${encodeURIComponent(nodeName)}`) }
    queryParams.push('DNDTYPE=DGI')

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodes?${queryParams.join('&')}`, { method: 'get' })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_DOCUMENTNODES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTNODES_FAILED })
          }
        } else {
          dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTNODES_SUCCESS, payload: jsondata })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.document_nodes_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTNODES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and requests get documentnodes by type node.
 * @param {Array} fields which fields we get back from request
 * @param {String} nodeName
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getDocumentNodesDefinitionTypeNode(fields, nodeName, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: SELECTOR_DEFINITION_GET_CUSTOM_DIALOGS_START })
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (nodeName) { queryParams.push(`DNDNAME=${encodeURIComponent(nodeName)}`) }
    queryParams.push('DNDTYPE=DND')

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodes?${queryParams.join('&')}`, { method: 'get' })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_DOCUMENTNODES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTNODES_FAILED })
          }
        } else {
          dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTNODES_SUCCESS, payload: jsondata })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.document_nodes_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_DOCUMENTNODES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and executes a bql command.
 * @param {String} bqlQuery The bql query.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function executeBqlQuery(bqlQuery, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_DATA_FROM_BQL_QUERY_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/database/bqlquery`, {
        method: 'post',
        body: { COMMAND: bqlQuery.trim() }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: GET_DATA_FROM_BQL_QUERY_NO_RESULT })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_DATA_FROM_BQL_QUERY_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: GET_DATA_FROM_BQL_QUERY_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback(jsondata.data !== undefined)
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('database.bqlquery_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_DATA_FROM_BQL_QUERY_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getRecipients request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'RECI', 'PREDRECI', 'RTYPE', 'OWNER', 'TITLE', 'BRWS', 'DCR', 'PCR', 'PPN', 'CDATE', 'CTIME'
 * @param {String} preRecipient The pre recipient pattern.
 * @param {Function} callback the callback which will be called when the request was successful.
 */
export function getRecipientHierarchyEntry(preRecipient, parentKey, rootKey, hash, level, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_START })

    /* Build request-parameter for URL */
    const queryParams = ['FIELDS=RECI,TITLE,PREDRECI,RTYPE']
    if (preRecipient) { queryParams.push(`PREDRECI=${encodeURIComponent(preRecipient)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipients?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            dispatch({ type: MODALSELECTOR_NO_HIERARCHY_ENTRY_FOUND, payload: { parentKey, rootKey, hash, level } })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_SUCCESS, payload: { parentKey, rootKey, hash, level, data: jsondata.data.data } })
          // call the callback on success
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.recipients_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do get document node request.
 * @param {String} parentNode The parentnode which is used for the request to get the childs.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getNodeHierarchyEntry(parentNode, parentKey, rootKey, hash, level, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    dispatch({ type: MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_START })

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    /* Build request-parameter for URL */
    const queryParams = [
      'FIELDS=DNDNAME,DNDENAME,DNDPNAME,DNDTYPE',
      `DNDPNAME=${encodeURIComponent(parentNode)}`
    ]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodes?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            dispatch({ type: MODALSELECTOR_NO_HIERARCHY_ENTRY_FOUND, payload: { parentKey, rootKey, hash, level } })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
        }
        else {
          const dataToUse = jsondata.data.data.map(d => {
            let result = [...d]
            result.splice(3, 0, '')
            result.splice(4, 0, '')
            return result
          })
          dispatch({ type: MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_SUCCESS, payload: { parentKey, rootKey, hash, level, data: dataToUse } })
          // call the callback on success
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.document_node_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do get document node request.
 * @param {String} nodeID The nodeID which is used for the request to get the assignments.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getNodeDocumentAssginmentsHierarchyEntry(nodeID, parentKey, rootKey, hash, level, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    dispatch({ type: MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_START })

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    /* Build request-parameter for URL */
    const queryParams = [
      'FIELDS=DNDNAME, DGIENAME, FORM, EXT',
      `DNDNAME=${encodeURIComponent(nodeID)}`
    ]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodedocumentassignments?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            dispatch({ type: MODALSELECTOR_NO_HIERARCHY_ENTRY_FOUND, payload: { parentKey, rootKey, hash, level } })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
        }
        else {
          const dataToUse = [...jsondata.data.data.map(d => {
            let result = [...d, 'DOC']
            // insert empry value for "Parent node ID" value. Documents have no "Parent node ID".
            result.splice(1, 0, '')
            return result
          })]
          dispatch({ type: MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_SUCCESS, payload: { parentKey, rootKey, hash, level, data: dataToUse } })
          // call the callback on success
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.document_node_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: MODALSELECTOR_GET_HIERARCHY_ENTRY_DATA_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getJobgroups(fields, searchObj, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_JOBGROUPS_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definitionlogx/jobgroups?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_JOBGROUPS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_JOBGROUPS_FAILED })
          }
        }
        else {
          dispatch({ type: SELECTOR_DEFINITION_GET_JOBGROUPS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.jobgroups_loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_JOBGROUPS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getLpdQueues request.
 * @param {Object} searchObj The object which contains the query params.
 * @param {Function} callback the callback which will be called when the request was successful. Can be null.
 */
export function getLpdQueueDefinitions(fields, searchObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SELECTOR_DEFINITION_GET_LPD_QUEUES_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/lpdqueues?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_NO_LPD_QUEUES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SELECTOR_DEFINITION_GET_LPD_QUEUES_FAILED })
          }
        }
        else {
          dispatch({ type: SELECTOR_DEFINITION_GET_LPD_QUEUES_SUCCESS, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.lpd_queues_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SELECTOR_DEFINITION_GET_LPD_QUEUES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}


export const updateHierarchyEntriesData = (newHierarchyEntriesData, query) => {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    dispatch({
      type: MODALSELECTOR_HIERARCHY_ENTRIES_UPDATED, payload: newHierarchyEntriesData
    })
    if (query) {
      executeBqlQuery(query)(dispatch)
    }
    LoadingSpinnerActions.hide()(dispatch)
  }
}