import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import * as ResponseUtils from 'utils/ResponseUtils'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import { restapiRequest } from 'utils/RequestUtils'

// actions
export const UPLOAD_START = 'UPLOAD_START'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const UPLOAD_FAILED = 'UPLOAD_FAILED'

/**
 * @description Calling the rest api and do an upload of multiple files.
 * @param {Object} importParams The params for the upload.
 * Possible params:
 * form: document form (required),
 * extension: document extension,
 * jobname: current jobname,
 * recipient: recipient,
 * dcr: output channel name,
 * pcr: output format definition name,
 * title: title of the document,
 * docusr: array of docuser variables,
 * comment: comment,
 * copies: number of copies,
 * autoPrint: flag whether the document shall be automatically printed,
 * mvs:
 *  special mvs file format. one of the following values:
 *  'MVS' for document encoding is EBCDIC with newline 0x25
 *  'MVS-CC' for document encoding is EBCDIC with newline 0x25 and ASA control characters
 *  'MVS-CC-T' for document encoding is EBCDIC with newline 0x25, ASA control characters and table reference characters
 *  'TXT-CC' for document encoding is ASCII/UTF-8 with ASA control characters,
 * useExt: flag whether the file extension shall be used as filetype,
 * userid: name of the BUX user who triggers the upload,
 * documentStatus:
 *  document status, maps to edit status (BBC icons) in the document.
 *  One of the following values:
*  'X' for Error, 'P' for Pin, 'S' for Stamp, 'C' for Clip, 'H' for Hook, or empty
 * encoding:
 *  document encoding. One of the following values:
 *  'ISO8859-1', 'ISO8859-2', 'ISO8859-15', 'UTF-8',
 *  'CP850', 'CP1250', 'CP1251', 'CP1252', 'CP1253', 'CP1254', 'CP1256',
 *  'IBM0420', 'IBM1140', 'IBM1141', 'IBM1147', 'IBM1148' or empty
 * @param {Array} files Array of files to upload. Can also be a single file, which will be converted internally to an array.
 * @param {Function} callback The callback which will be called when the upload was successful.
 */
export function upload(importParams, files, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: UPLOAD_START })

    // convert to array of files
    if (files !== undefined && files instanceof File) {
      files = [files]
    }

    // build formdata
    const formData = new FormData()
    for (const [key, value] of Object.entries(importParams)) {
      if (key === 'DOCUSR') {
        if (Array.isArray(value)) {
          if (value.filter(d => d !== '').length > 0) {
            formData.append(key, value)
          }
        }
      }
      else if (value !== '') {
        formData.append(key, value)
      }
    }
    files.forEach(file => formData.append('files', file))

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/documents/upload`, {
        method: 'post',
        body: formData
      }),
      Config.UPLOAD_DATA_TIMEOUT
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.UPLOAD_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (jsondata.additionalInfo) {
          const rsp = ResponseUtils.processResponse(jsondata.additionalInfo, Lang.translate('import.import_success'), dispatch)

          SnackbarActions.showMultiple(rsp.snackbarContent)(dispatch)
          if (callback) {
            callback(rsp.successObjects)
          }
        }
        else {
          let rc = jsondata.error?.rc
          let irc = jsondata.error?.irc
          // check general errors
          const params = ResponseUtils.parseErrorParams(jsondata.error?.param)
          let error = GeneralErrorHandler.handleResponse(rc, irc, params, dispatch)
          // TODO: Add specific error handler which may appear for each document separatly and even the general error e.g. invalid usertoken
          SnackbarActions.show(error.message, error.type)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('upload.error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPLOAD_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}