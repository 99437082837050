import {TypedUseSelectorHook, useSelector} from 'react-redux';
import {DATEMASK} from './general/Preferences';

export interface SelectionDocuments {
    data?: string[][],
    header?: string[]
}

export interface GlobalState {
    auth: {
        serverdata: {
            token: string;
            preferences: Record<string, string | number> & {
                [DATEMASK]: string
            }
        }
    }
}

export const useAppSelector: TypedUseSelectorHook<GlobalState> = useSelector