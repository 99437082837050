import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

export const GET_JOBGROUPS_DEFINITION_START = 'GET_JOBGROUPS_DEFINITION_START'
export const GET_JOBGROUPS_DEFINITION_SUCCESS = 'GET_JOBGROUPS_DEFINITION_SUCCESS'
export const GET_JOBGROUPS_DEFINITION_FAILED = 'GET_JOBGROUPS_DEFINITION_FAILED'
export const NO_JOBGROUPS_DEFINITION_FOUND = 'NO_JOBGROUPS_DEFINITION_FOUND'

export const GET_JOBGROUP_DEFINITION_START = 'GET_JOBGROUP_DEFINITION_START'
export const GET_JOBGROUP_DEFINITION_SUCCESS = 'GET_JOBGROUP_DEFINITION_SUCCESS'
export const GET_JOBGROUP_DEFINITION_FAILED = 'GET_JOBGROUP_DEFINITION_FAILED'
export const NO_JOBGROUP_DEFINITION_FOUND = 'NO_JOBGROUP_DEFINITION_FOUND'

export const DELETE_JOBGROUP_DEFINITION_START = 'DELETE_JOBGROUP_DEFINITION_START'
export const DELETE_JOBGROUP_DEFINITION_SUCCESS = 'DELETE_JOBGROUP_DEFINITION_SUCCESS'
export const DELETE_JOBGROUP_DEFINITION_FAILED = 'DELETE_JOBGROUP_DEFINITION_FAILED'

export const CREATE_JOBGROUP_DEFINITION_START = 'CREATE_JOBGROUP_DEFINITION_START'
export const CREATE_JOBGROUP_DEFINITION_SUCCESS = 'CREATE_JOBGROUP_DEFINITION_SUCCESS'
export const CREATE_JOBGROUP_DEFINITION_FAILED = 'CREATE_JOBGROUP_DEFINITION_FAILED'

export const UPDATE_JOBGROUP_DEFINITION_START = 'UPDATE_JOBGROUP_DEFINITION_START'
export const UPDATE_JOBGROUP_DEFINITION_SUCCESS = 'UPDATE_JOBGROUP_DEFINITION_SUCCESS'
export const UPDATE_JOBGROUP_DEFINITION_FAILED = 'UPDATE_JOBGROUP_DEFINITION_FAILED'

export function getJobgroups(fields, searchObj, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_JOBGROUPS_DEFINITION_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definitionlogx/jobgroups?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_JOBGROUPS_DEFINITION_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_JOBGROUPS_DEFINITION_FAILED })
          }
        }
        else {
          dispatch({ type: GET_JOBGROUPS_DEFINITION_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.jobgroups_loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_JOBGROUPS_DEFINITION_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getJobgroup(fields, searchObj, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_JOBGROUP_DEFINITION_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definitionlogx/jobgroup?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_JOBGROUP_DEFINITION_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_JOBGROUP_DEFINITION_FAILED })
          }
        }
        else {
          dispatch({ type: GET_JOBGROUP_DEFINITION_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.jobgroup_loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_JOBGROUP_DEFINITION_SUCCESS })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function createJobgroup(updateObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CREATE_JOBGROUP_DEFINITION_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definitionlogx/jobgroup`, {
        method: 'post',
        body: updateObj
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CREATE_JOBGROUP_DEFINITION_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: CREATE_JOBGROUP_DEFINITION_SUCCESS })
          SnackbarActions.show(Lang.translate('definition.create_jobgroup_success', lang, updateObj.JGIGNAME), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }
          // ! DO NOT HIDE LOADING SPINNER WHEN SUCCESS BECAUSE THE TEST NEEDS TO WAIT UNTIL THE REFRESH IS DONE
          // ! REFRESH WILL HIDE LOADING SPINNER WHEN FINISH
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.create_jobgroup_error', lang, [updateObj.JGIGNAME, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
        dispatch({ type: CREATE_JOBGROUP_DEFINITION_FAILED, payload: { error } })
      })
  }
}

export function updateJobgroup(updateObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: UPDATE_JOBGROUP_DEFINITION_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definitionlogx/jobgroup`, {
        method: 'put',
        body: updateObj
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: UPDATE_JOBGROUP_DEFINITION_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: UPDATE_JOBGROUP_DEFINITION_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.update_jobgroup_success', lang, updateObj.JGIGNAME), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
          // ! DO NOT HIDE LOADING SPINNER WHEN SUCCESS BECAUSE THE TEST NEEDS TO WAIT UNTIL THE REFRESH IS DONE
          // ! REFRESH WILL HIDE LOADING SPINNER WHEN FINISH
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.update_jobgroup_error', lang, [updateObj.JGIGNAME, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPDATE_JOBGROUP_DEFINITION_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and deletes a jobgroup.
 * @param {String} jobgroupName The current jobgroupName to delete.
 * @param {Function} callback The callback which will be called when the delete user request was successful. Can be null.
 */
export function deleteJobgroup(jobgroupName, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: DELETE_JOBGROUP_DEFINITION_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definitionlogx/jobgroup${createQueryParamsForFetch({ JGIGNAME: jobgroupName })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DELETE_JOBGROUP_DEFINITION_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DELETE_JOBGROUP_DEFINITION_SUCCESS })
          SnackbarActions.show(Lang.translate('definition.delete_jobgroup_success', lang, jobgroupName), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
          // ! DO NOT HIDE LOADING SPINNER WHEN SUCCESS BECAUSE THE TEST NEEDS TO WAIT UNTIL THE REFRESH IS DONE
          // ! REFRESH WILL HIDE LOADING SPINNER WHEN FINISH
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.delete_jobgroup_error', lang, [jobgroupName, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DELETE_JOBGROUP_DEFINITION_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Refreshes the search with redux prefs
 */
export const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const searchObj = {
      JGIGNAME: prefs[Preferences.JOBGROUPS_DEFINITION_JOBGROUP_NAME],
      OWNER: prefs[Preferences.JOBGROUPS_DEFINITION_TITLE],
      JGITITLE: prefs[Preferences.JOBGROUPS_DEFINITION_OWNER],
      ARCHMED: prefs[Preferences.JOBGROUPS_DEFINITION_ARCHIVE_MEDIA],
    }
    getJobgroups(
      undefined,
      searchObj,
      undefined,
      true
    )(dispatch)
  }
}