import React from 'react';
import { prepareClassNames } from '../../utils/ComponentUtils';

// Style
import './Icon.scss';

interface IconProps {
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** name of the icon to display. */
  name: string;
  /** Style class from CSS for styling for i element.*/
  className?: string;
  /** Style class from CSS for styling for container.*/
  containerClassName?: string;
  /** Disables this component.*/
  disabled?: boolean;
  /** Label to be displayed above the button.*/
  title?: string;
  /** Color of the icon */
  color?: string;
  /** Tab index */
  tabIndex?: number;
  /** Add around icon padding 0.5rem to have size 2.5rem x 2.5rem */
  padded?: boolean;
}

const Icon = ({ id, name, className, disabled, title, color, tabIndex = -1, padded, containerClassName}: IconProps): JSX.Element => {
  const containerClassNames = prepareClassNames([
    'icon_container',
    padded && 'padded',
    containerClassName
  ]);

  const iconClassNames = prepareClassNames([
    `beta_${name}`,
    disabled && 'disabled',
    className
  ]);

  return (
    <div
      id={`${id}_container`}
      title={title}
      className={containerClassNames}
      tabIndex={tabIndex}>
      <i
        id={`${id}_icon`}
        className={iconClassNames}
        title={title}
        style={{ color }}
        tabIndex={-1}
      />
    </div>
  );
};

export default Icon;