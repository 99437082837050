import { DEFAULT_TABLE_HEADER_KEYS, PREFERENCE_HEADERS_MAP } from './TableUtils'

export const BUX_COMMAND_LIST = [
  // 'IMPORT',
  // 'INDEX',
  'BUX_SELDOC',
  'BUX_SELGBL',
  'BUX_SELJOB',
]

const LOGSOURCE_MAP = {
  'BRWCTM': 'CTM',
  'BRWSTB': 'STB',
  'BRWSYSL': 'SYSL',
  'BRWUC4': 'UC4',
  'BRWZOS': 'ZOS'
}

/**
 * This function will return an array of header keys which the user configured via the table settings. In case there is no configuration, 'null' will be returned.
 * @param {Object} preferences The current preference state in Redux
 * @param {String} resultTableType Either 'BRWTAB', 'BRWCTM', 'BRWSTB', 'BRWSYSL', 'BRWUC4' or 'BRWZOS'
 * @returns {Array | null} Array of cached header keys based on the user settings
 */
export const getCachedHeaderKeys = (preferences, resultTableType) => {
  return resultTableType in PREFERENCE_HEADERS_MAP && preferences[PREFERENCE_HEADERS_MAP[resultTableType]] ? preferences[PREFERENCE_HEADERS_MAP[resultTableType]].displayedHeaders : null
}

/**
 * This function will return an array of the default header keys based on the result table type (SLTITYPE). If the result table type is unknown, then an empty array will be returned.
 * @param {String} resultTableType Either 'BRWTAB', 'BRWCTM', 'BRWSTB', 'BRWSYSL', 'BRWUC4' or 'BRWZOS'
 * @returns {Array}  Array of default header keys
 */
export const getDefaultHeaderKeys = (resultTableType) => {
  return resultTableType in DEFAULT_TABLE_HEADER_KEYS ? DEFAULT_TABLE_HEADER_KEYS[resultTableType] : []
}

/**
 * This function will return the LOGSOURCE value based on the result table type
 * @param {String} resultTableType Either 'BRWTAB', 'BRWCTM', 'BRWSTB', 'BRWSYSL', 'BRWUC4' or 'BRWZOS'
 * @returns {String} LOGSOURCE value, e.g. 'ZOS'
 */
export const getLogSource = (resultTableType) => {
  return resultTableType in LOGSOURCE_MAP ? LOGSOURCE_MAP[resultTableType] : ''
}

/**
 * This function will check if the input BUX query has correct values to avoid a mismatch between result table type and actual results.
 * @param {Object} params command: BUX command, e.g. BUX_SELDOC; logSourceResultTable: LOGSOURCE value based on open result table; logSourceInput: LOGSOURCE value based on suer input
 * @returns {String} Error string (empty string in case of correct BUX query)
 */
export const isCorrectBuxQuery = ({ command, logSourceResultTable, logSourceInput }) => {
  const isCorrectBuxCommand = !!logSourceResultTable ? (command === 'BUX_SELJOB') : (command === 'BUX_SELDOC' || command === 'BUX_SELGBL')
  if (!isCorrectBuxCommand) return `Incorrect BUX command, please use ${command}`

  if (command !== 'BUX_SELJOB') return '' // Avoid next check, which is only for BUX_SELJOB commands

  const isCorrectLogsource = logSourceInput === logSourceResultTable
  if (!isCorrectLogsource) return `Incorrect value for LOGSOURCE parameter, please use ${logSourceResultTable}`

  return ''
}

/**
 * This function will check if the BUX query is valid, e.g. by checking if the input is empty or not.
 * @param {String} buxQuery BUX query string which the user has typed into the input field
 * @returns {Boolean}
 */
export const isValidBuxQuery = (buxQuery) => {
  const hasNoParameters = buxQuery.split(' ').length <= 1
  const isEmpty = buxQuery.length === 0
  const isNotStartingWithBuxCommand = buxQuery.substring(0, 4) !== 'BUX_'
  const isUnknownBuxCommand = buxQuery.split(' ').length !== 0 && !BUX_COMMAND_LIST.some(BUX_COMMAND => buxQuery.split(' ')[0] === BUX_COMMAND)

  const isInvalid = hasNoParameters || isEmpty || isNotStartingWithBuxCommand || isUnknownBuxCommand

  return !isInvalid
}

/**
 * This function will parse the BUX query, which the user typed into the input field into an object.
 * @param {String} buxQuery BUX query string which the user has typed into the input field
 * @returns {Object} Obejct with the used BUX command and the parsed search parameter based on the user input
 */
export const parseSearchParamsFromBuxQuery = (buxQuery) => {
  const buxQueryParts = buxQuery.split(' ')
  const command = buxQueryParts[0] // e.g., 'BUX_SELDOC'
  const paramsAsString = buxQueryParts.slice(1) // e.g., ['MAXENTRY(10)', ..]

  const regexGetValueBetweenParentheses = /\((.*)\)/

  const searchParams = paramsAsString.reduce((acc, paramAsString) => {
    const regexMatchArray = paramAsString.match(regexGetValueBetweenParentheses) // e.g., [ '(10)', '10', index: 8, input: 'MAXENTRY(10)', groups: undefined ]
    const key = paramAsString.replace(regexMatchArray[0], '')
    const valueBetweenParentheses = regexMatchArray[1] // e.g., '10'

    acc[key] = valueBetweenParentheses
    return acc
  }, {})

  return { command: command, searchParams: searchParams } // TBD: 'command' ununsed currently, because of missing testing capabilities for global index search result table (BUX_SELGBL)
}