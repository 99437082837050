import * as Lang from 'language/Language'
import store from 'redux/Store'

// all functions
export const FREE = 'security.profile_entry_free'
export const STAR_DOT_STAR_STAR = 'security.profile_entry_*.**'
export const STAR = 'security.profile_entry_*'
export const EBM = 'security.profile_entry_ebm' // mainframe only -> don't show it
export const IMM = 'security.profile_entry_imm'

// user functions
export const ARC = 'security.profile_entry_arc'
export const ARD = 'security.profile_entry_ard'
export const BRA = 'security.profile_entry_bra'
export const BRW = 'security.profile_entry_brw'
export const BNA = 'security.profile_entry_bna'
export const BNU = 'security.profile_entry_bnu'
export const DEL = 'security.profile_entry_del'
export const DST = 'security.profile_entry_dst'
export const EGR = 'security.profile_entry_egr'
export const GLN = 'security.profile_entry_gln'
export const HEX = 'security.profile_entry_hex'
export const IMP = 'security.profile_entry_imp'
export const NTE = 'security.profile_entry_nte'
export const PCR = 'security.profile_entry_pcr'
export const PRA = 'security.profile_entry_pra'
export const PRF = 'security.profile_entry_prf'
export const PRQ = 'security.profile_entry_prq'
export const PRT = 'security.profile_entry_prt'
export const RLD = 'security.profile_entry_rld'
export const RLI = 'security.profile_entry_rli'
export const SFD = 'security.profile_entry_sfd'
export const SFH = 'security.profile_entry_sfh'
export const SFP = 'security.profile_entry_sfp'
export const SFR = 'security.profile_entry_sfr'
export const SHW = 'security.profile_entry_shw'
export const SPT = 'security.profile_entry_spt'
export const SSI = 'security.profile_entry_ssi'
export const UTL = 'security.profile_entry_utl'

// sub administrator functions
export const DFA = 'security.profile_entry_dfa'
export const DFD = 'security.profile_entry_dfd'
export const DFF = 'security.profile_entry_dff'
export const DFH = 'security.profile_entry_dfh'
export const DFI = 'security.profile_entry_dfi'
export const DFL = 'security.profile_entry_dfl'
export const DFN = 'security.profile_entry_dfn'
export const DFP = 'security.profile_entry_dfp'
export const DFR = 'security.profile_entry_dfr'
export const DFS = 'security.profile_entry_dfs'
export const DFT = 'security.profile_entry_dft'
export const DFU = 'security.profile_entry_dfu'
export const DFX = 'security.profile_entry_dfx'

// administrator functions
export const ADM = 'security.profile_entry_adm'
export const RSC = 'security.profile_entry_rsc'
export const RSI = 'security.profile_entry_rsi'

// system functions
export const SYS = 'security.profile_entry_sys'

// security functions
export const SEC = 'security.profile_entry_sec'

// logx functions
export const SJU = 'security.profile_entry_sju'
export const SJZ = 'security.profile_entry_sjz'
export const BJU = 'security.profile_entry_bju'
export const BJZ = 'security.profile_entry_bjz'

// job functions
export const BSL = 'security.profile_entry_bsl'
export const BCM = 'security.profile_entry_bcm'
export const BSB = 'security.profile_entry_bsb'
export const SSL = 'security.profile_entry_ssl'
export const SCM = 'security.profile_entry_scm'
export const SSB = 'security.profile_entry_ssb'

// profile categories
export const ALL_FUNCTIONS = 'security.profile_type_all_functions'
export const USER_FUNCTIONS = 'security.profile_type_user_functions'
export const SUBADMIN_FUNCTIONS = 'security.profile_type_subadmin_functions'
export const ADMIN_FUNCTIONS = 'security.profile_type_admin_functions'
export const SYSTEM_FUNCTIONS = 'security.profile_type_system_functions'
export const SECURITY_FUNCTIONS = 'security.profile_type_security_functions'
export const JOB_FUNCTIONS = 'security.profile_type_job_functions'


// ****************************************************************************************/
// Translated category functions

/**
 * @description Gets the translated general categories in sorted order.
 */
export function getTranslatedGeneralCategories() {
  const categories = []

  // translate the categories
  getGeneralProfileCategories().forEach(element => {
    categories.push(Lang.translate(element))
  })

  // sort the translated categories
  categories.sort()

  return categories
}

/**
 * @description Gets the translated categories in sorted order.
 */
export function getTranslatedCategories() {
  const categories = []

  // translate the categories
  getProfileCategories().forEach(element => {
    categories.push(Lang.translate(element))
  })

  // sort the translated categories
  categories.sort()

  return categories
}

/**
 * @description Gets the index of a category key.
 * @param {String} categoryKey The category key to search.
 */
export function indexOfCategory(categoryKey) {
  if (categoryKey === '') {
    return 0
  }

  const categories = []

  getTranslatedGeneralCategories().forEach(element => {
    categories.push(element)
  })
  getTranslatedCategories().forEach(element => {
    categories.push(element)
  })

  // get the index of the selected category
  let index = categories.indexOf(Lang.translate(categoryKey))

  // if the key is not found, default is the first position
  if (index === -1) {
    index = 0
  }

  return index
}

/**
 * @description Gets the key of the category at the index position.
 * @param {Number} index The index where to get the category key.
 */
export function getCategoryKey(index) {
  const categoryMap = new Map()
  const categories = []

  // translate the categories
  // also save the keys and translated categories in a map that we can find with the translation the key
  getGeneralProfileCategories().forEach(element => {
    const translatedCategory = Lang.translate(element)
    categories.push(translatedCategory)
    categoryMap.set(translatedCategory, element)
  })

  const tempCategories = []
  getProfileCategories().forEach(element => {
    const translatedCategory = Lang.translate(element)
    tempCategories.push(translatedCategory)
    categoryMap.set(translatedCategory, element)
  })

  // sort the translated categories
  tempCategories.sort()

  tempCategories.forEach(element => {
    categories.push(element)
  })

  return categoryMap.get(categories[index])
}
// ****************************************************************************************
// Translated entry functions

/**
 * @description Gets the translated functions for a category in sorted order.
 * @param {String} category The category key.
 */
export function getTranslatedFunctions(category) {
  const functions = []
  // get the functions for the category and translate it
  getFunctionsOf(category).forEach(element => {
    let translation = translateFunction(element)
    functions.push(translation)
  })

  // sort the translated functions
  functions.sort()
  return functions
}

/**
 * @description Gets the general function keys.
 * @param {String} category The category key.
 * @param {Boolean} includeAll Flag for including all functions. Needed for search.
 */
export function getGeneralFunctions(category, includeAll) {
  const functions = []
  if (category === ALL_FUNCTIONS) {
    functions.push(FREE)
    functions.push(STAR)

    if (includeAll) {
      functions.push(STAR_DOT_STAR_STAR)
    }
  }

  return functions
}

/**
 * @description Gets the translated general functions for a category.
 * @param {String} category The category key.
 * @param {Boolean} includeAll Flag for including all functions. Needed for search.
 */
export function getTranslatedGeneralFunctions(category, includeAll) {
  const translatedfunctions = []
  getGeneralFunctions(category, includeAll).forEach(element => {
    translatedfunctions.push(Lang.translate(element))
  })

  // sort the translated functions
  translatedfunctions.sort()

  return translatedfunctions
}

/**
 * @description Gets the index position for a function key of a category key.
 * @param {String} category The category key.
 * @param {String} profileFunction The selected function key.
 * @param {Boolean} includeAll Flag for including all functions. Needed for search.
 */
export function indexOfFunction(category, profileFunction, includeAll) {
  if (profileFunction === '') {
    return 0
  }

  // add the general translated functions
  let functions = getTranslatedGeneralFunctions(category, includeAll)

  // add the translated functions
  getTranslatedFunctions(category).forEach(element => {
    functions.push(element)
  })

  const translation = translateFunction(profileFunction)

  // get the index of the selected category
  let index = functions.indexOf(translation)

  // if the key is not found, default is the first position
  if (index === -1) {
    index = 0
  }

  return index
}

/**
 * @description Gets the key of the function for a category key and the index position.
 * @param {String} category The category key.
 * @param {Number} index The index where to get the function key.
 * @param {Boolean} includeAll Flag for including all functions. Needed for search.
 */
export function getFunctionKey(category, index, includeAll) {
  const functionsMap = new Map()
  const generalFunctions = []
  const functions = []

  // translate the general functions
  // also save the keys and translated functions in a map that we can find with the translation the key
  getGeneralFunctions(category, includeAll).forEach(element => {
    const translatedFunction = Lang.translate(element)
    generalFunctions.push(translatedFunction)
    functionsMap.set(translatedFunction, element)
  })

  // translate the functions
  // also save the keys and translated functions in a map that we can find with the translation the key
  getFunctionsOf(category).forEach(element => {
    const translatedFunction = translateFunction(element)

    functions.push(translatedFunction)
    functionsMap.set(translatedFunction, element)
  })

  // concat two arrays
  const allFunctions = [...generalFunctions.sort(), ...functions.sort()]

  return functionsMap.get(allFunctions[index])
}
/* ************************************************************************************** */

/**
 * @description Gets the profile category keys.
 */
export function getProfileCategories() {
  const servertype = store.getState().auth.serverdata.server.type

  if (servertype === 'B93') {
    return [
      USER_FUNCTIONS,
      SUBADMIN_FUNCTIONS,
      ADMIN_FUNCTIONS,
      SYSTEM_FUNCTIONS,
      SECURITY_FUNCTIONS
    ]
  } else if (servertype === 'B92') {
    return [
      USER_FUNCTIONS,
      JOB_FUNCTIONS,
      SUBADMIN_FUNCTIONS,
      ADMIN_FUNCTIONS,
      SYSTEM_FUNCTIONS,
      SECURITY_FUNCTIONS
    ]
  } else {
    return [] // should not happen!
  }
}

/**
 * @description Gets the general profile category keys.
 */
export function getGeneralProfileCategories() {
  return [
    ALL_FUNCTIONS
  ]
}

/**
 * @description Gets function keys of a category.
 * @param {String} category The category key.
 */
export function getFunctionsOf(category) {
  switch (category) {
    case ALL_FUNCTIONS: return getAllFunctions()
    case USER_FUNCTIONS: return getUserFunctions()
    case JOB_FUNCTIONS: return getJobFunctions()
    case SUBADMIN_FUNCTIONS: return getSubAdminFunctions()
    case ADMIN_FUNCTIONS: return getAdminFunctions()
    case SYSTEM_FUNCTIONS: return getSystemFunctions()
    case SECURITY_FUNCTIONS: return getSecurityFunctions()
    default: return getAllFunctions()
  }
}

/**
 * @description Gets all function keys.
 */
export function getAllFunctions() {
  const entityParts = store.getState().security.entityParts

  const functions = []

  if (entityParts) {
    entityParts.data.forEach(entry => {
      const prefix = entry[entityParts.header.indexOf('ENTPRFX')]
      // EBM is mainframe only! Don't show it
      if (prefix !== 'EBM') {
        functions.push(`security.profile_entry_${prefix.toLowerCase()}`)
      }
    })
  }

  return functions
}

/**
 * Gets the user function keys.
 */
export function getUserFunctions() {
  return [
    ARC, BRA, BRW, BNA, BNU, DEL, DST, EGR, GLN, HEX, IMP, IMM, NTE, PCR, PRA,
    PRF, PRQ, PRT, RLD, RLI, SFD, SFH, SFP, SFR, ARD, SHW, SPT, SSI, UTL
  ]
}

/**
 * @description Gets the subadmin function keys.
 */
export function getSubAdminFunctions() {
  return [DFA, DFD, DFF, DFH, DFI, DFL, DFN, DFP, DFR, DFS, DFT, DFU, DFX]
}

/**
 * @description Gets the admin function keys.
 */
export function getAdminFunctions() {
  return [ADM, RSC, RSI]
}

/**
 * @description Gets the system function keys.
 */
export function getSystemFunctions() {
  return [SYS]
}

/**
 * @description Gets the security function keys.
 */
export function getSecurityFunctions() {
  return [SEC]
}

/**
 * @description Gets the job function keys.
 */
export function getJobFunctions() {
  // TODO: ask Marcus when he is back
  return [SJU, SJZ, BJU, BJZ, BSL, BCM, SSL, SCM, BSB, SSB]
}

/**
 * Possible cases:
 * - Free input or * (parts is empty array)
 * - Parts is undefined -> input is disabled
 * - Parts is an array of strings
 */
const profileMasksPrefixAndParts = {
  [FREE]: { prefix: '', parts: [] },
  [STAR_DOT_STAR_STAR]: { prefix: '*.**', parts: undefined },
  [STAR]: { prefix: '**', parts: [] },
  [SEC]: { prefix: 'SEC', parts: [] } /* SEC is Special - SEC.VCI, SEC.USR, SEC.GRP are valid but we don't offer a auto-completion here. Just allow input for field "profilemask" */
}

/**
 * @description Gets the parts for a prefix.
 * @param {String} prefix The prefix for which the parts will be searched.
 */
export function getParts(prefix) {
  const entityParts = store.getState().security.entityParts

  const indexPrefix = entityParts.header.indexOf('ENTPRFX')
  const indexParts = entityParts.header.indexOf('ENTRULE')

  // get the specific data object from redux which match the prefix
  const data = entityParts.data.filter(entry => entry[indexPrefix] === prefix)

  // return undefined in case of no parts
  if (!data || !data[0] || !data[0][indexParts]) {
    return undefined
  }

  // get the parts as string
  let partsString = data[0][indexParts]

  // cut & char at the start
  if (partsString.startsWith('&')) {
    partsString = partsString.substring(1)
  }

  // return parts as array without .& between the parts
  return partsString.split('.&')
}

/**
 * @description Gets the profilemask with prefix and parts.
 * @param {String} profileentry The profile entry key.
 */
export function getProfilemaskPrefixAndParts(profileentry) {
  let result = { prefix: '', parts: undefined }

  if (!profileentry) {
    return result
  }

  // general entries
  Object.keys(profileMasksPrefixAndParts).forEach(translationKey => {
    if (translationKey.toLowerCase() === profileentry.toLowerCase()) {
      result = profileMasksPrefixAndParts[translationKey]
      return
    }
  })

  const entityParts = store.getState().security.entityParts
  if (entityParts) {
    entityParts.data.forEach(entry => {
      const prefix = entry[entityParts.header.indexOf('ENTPRFX')]
      if (`security.profile_entry_${prefix.toLowerCase()}` === profileentry.toLowerCase()) {
        result = {
          prefix: prefix,
          parts: getParts(prefix)
        }
        return
      }
    })
  }

  return result
}

/**
 * @description Gets an object which contain the Profileentry-translationkey and parts based on the prefix
 * @param {String} prefix The Profileentry of the profilemask without a dot (.) at the end
 */
export function getProfilentryAndParts(prefix) {
  let result = {
    prefix,
    parts: undefined,
    translationKey: undefined
  }

  Object.keys(profileMasksPrefixAndParts).forEach(translationKey => {
    if (profileMasksPrefixAndParts[translationKey].prefix.replace('.', '') === prefix) {
      result = {
        prefix: prefix,
        parts: profileMasksPrefixAndParts[translationKey].parts,
        translationKey: translationKey
      }
      return
    }
  })

  const entityParts = store.getState().security.entityParts
  if (entityParts) {
    entityParts.data.forEach(entry => {
      const prefix2Check = entry[entityParts.header.indexOf('ENTPRFX')]
      if (prefix2Check.replace('.', '') === prefix) {
        result = {
          prefix: prefix,
          parts: getParts(prefix),
          translationKey: `security.profile_entry_${prefix.toLowerCase()}`
        }
        return
      }
    })
  }

  return result
}

/**
 * @description Gets the concated profile mask for a profile entry and the profilemask.
 * @param {String} entry The profile entry.
 * @param {String} profilemask The profilemask.
 */
export function getConcatedProfileMask(entry, profilemask) {
  const profileMaskPrefix = getProfilemaskPrefixAndParts(entry).prefix

  // free input
  if (profileMaskPrefix === '') {
    return profilemask
  }
  // no profilmask input possible -> return **
  else if (profileMaskPrefix === '**') {
    return profileMaskPrefix
  }
  // an entry has been selected
  else {
    // if profilmask is empty -> return prefix without .
    if (profilemask === '') {
      return profileMaskPrefix
    }
    // else concatinate prefix with . and user input
    else {
      return `${profileMaskPrefix}.${profilemask}`
    }
  }
}

/**
 * @description Translates an element. If the language key won't be found we use the translation of the rest api.
 * @param {String} element The element to translate.
 */
function translateFunction(element) {
  let translation
  try {
    translation = Lang.translate(element)
  }
  catch (error) {
    // write error in console that language key does not exist. We catch the error here because otherwise the ui will crash!
    console.error(`Security profile translation for key <${element}> not found! Using translation of rest api!`)

    const entityParts = store.getState().security.entityParts

    // get prefix from translation key (last 3 chars)
    const prefix = element.substring(element.lastIndexOf('_') + 1).toUpperCase()

    // if key of security profile does not exist, use english translation of rest api
    const entryData = entityParts.data.find(entry => {
      return prefix === entry[entityParts.header.indexOf('ENTPRFX')]
    })

    // concat prefix with translation of rest api
    // for example: SJU - Select UC4 Jobs/Job Logs
    translation = `${prefix} - ${entryData[entityParts.header.indexOf('ENTDESC')]}`
  }

  return translation
}