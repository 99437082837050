import React, { Component } from 'react'

// Components
import {
  Button, Card, Column, Dropdown, Input,
  Modal as ModalComponent, NumericSpinner, Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as DatabaseActions from 'redux/actions/DatabaseActions'

class CreateDatabaseFileDialog extends Component {
  state = {
    typeIndex: 0,
    prefix: `${this.props.servertype}.Spool`,
    index: 1,
    fileSize: 250
  }

  /**
   * @description Handles the changes of the type dropdown.
   * @param {String} index The index of the dropdown values.
   */
  handleTypeChange = index => {
    const type = index === 0 ? 'Spool' : 'Index'
    this.setState({
      typeIndex: index,
      prefix: `${this.props.servertype}.${type}`
    })
  }

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { fileSize, prefix, index, typeIndex } = this.state
    const callback = () => { this.props.onClose() }
    const file = {
      XAPROD: this.props.servertype,
      XDDSN: `${prefix}${index}`,
      XDSPACE: `${fileSize}`,
      XDTYPE: typeIndex === 0 ? 'SP' : 'IX'
    }
    this.props.createFile(file, callback)
  }

  render = () => {
    const { id, onClose } = this.props
    const { prefix, typeIndex, index, fileSize } = this.state
    return (
      <>
        <Modal onClose={onClose}
          id='create_database_file_dialog'
          className='bux_UserProfileModal'>
          <Header
            id={`${id}_modalHeader`}
            title={translate('database.create_database_file')}
            onClose={onClose} />
          <Main id={id}>
            <Card>
              <Row>
                <Column colMD={3}>
                  <Dropdown
                    id={`${id}_type`}
                    items={[
                      translate('database.spool_model'),
                      translate('database.index_model')
                    ]}
                    onChange={this.handleTypeChange}
                    activeIndex={typeIndex}
                    title={translate('general.type')}
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_prefix`}
                    title={translate('database.prefix')}
                    value={prefix}
                    onInputChanged={() => { }}
                    disabled
                  />
                </Column>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_index`}
                    title={translate('definition.index')}
                    steps={1}
                    min={1}
                    max={9999}
                    value={index}
                    onChange={val => this.setState({ index: val })}
                  />
                </Column>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_size`}
                    title={translate('database.size_mb')}
                    steps={1}
                    min={0}
                    max={2000}
                    value={fileSize}
                    onChange={val => this.setState({ fileSize: val })}
                  />
                </Column>
              </Row>
            </Card>
          </Main>
          <Footer id={id}>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    usertoken: state.auth.serverdata.token,
    servertype: state.auth.serverdata.server.type
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createFile: (file, callback) => {
      DatabaseActions.createFile(file, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateDatabaseFileDialog)