import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const PPN_DEFINITION_CREATE_PPN_START = 'PPN_DEFINITION_CREATE_PPN_START'
export const PPN_DEFINITION_CREATE_PPN_SUCCESS = 'PPN_DEFINITION_CREATE_PPN_SUCCESS'
export const PPN_DEFINITION_CREATE_PPN_FAILED = 'PPN_DEFINITION_CREATE_PPN_FAILED'

export const PPN_DEFINITION_UPDATE_PPN_START = 'PPN_DEFINITION_UPDATE_PPN_START'
export const PPN_DEFINITION_UPDATE_PPN_SUCCESS = 'PPN_DEFINITION_UPDATE_PPN_SUCCESS'
export const PPN_DEFINITION_UPDATE_PPN_FAILED = 'PPN_DEFINITION_UPDATE_PPN_FAILED'

export const PPN_DEFINITION_GET_PPN_START = 'PPN_DEFINITION_GET_PPN_START'
export const PPN_DEFINITION_GET_PPN_SUCCESS = 'PPN_DEFINITION_GET_PPN_SUCCESS'
export const PPN_DEFINITION_GET_PPN_FAILED = 'PPN_DEFINITION_GET_PPN_FAILED'

export const PPN_DEFINITION_DELETE_PPN_START = 'PPN_DEFINITION_DELETE_PPN_START'
export const PPN_DEFINITION_DELETE_PPN_SUCCESS = 'PPN_DEFINITION_DELETE_PPN_SUCCESS'
export const PPN_DEFINITION_DELETE_PPN_FAILED = 'PPN_DEFINITION_DELETE_PPN_FAILED'

export const PPN_DEFINITION_GET_PPNS_START = 'PPN_DEFINITION_GET_PPNS_START'
export const PPN_DEFINITION_GET_PPNS_SUCCESS = 'PPN_DEFINITION_GET_PPNS_SUCCESS'
export const PPN_DEFINITION_GET_PPNS_FAILED = 'PPN_DEFINITION_GET_PPNS_FAILED'

export const PPN_DEFINITION_VERIFY_PPN_START = 'PPN_DEFINITION_VERIFY_PPN_START'
export const PPN_DEFINITION_VERIFY_PPN_SUCCESS = 'PPN_DEFINITION_VERIFY_PPN_SUCCESS'
export const PPN_DEFINITION_VERIFY_PPN_FAILED = 'PPN_DEFINITION_VERIFY_PPN_FAILED'

export const NO_PPN_DEFINITION_PPNS_FOUND = 'NO_PPN_DEFINITION_PPNS_FOUND'

/**
 * @description Calls the rest api and gets a post processing note.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'OWNER', 'PPNTITLE', 'DJDE', 'CDATE', 'CTIME', 'CUSER', 'PPNTEX1', 'PPNTEX2', 'PPNTEX3', 'PPNTEX4', 'PPNTEX5',
 * 'PPNTEX6', 'PPNTEX7', 'PPNTEX8', 'PPNTEX9', 'PPNTEX10', 'PPNTEX11', 'PPNTEX12', 'PPNTEX13', 'PPNTEX14',
 * 'PPNTEX15', 'PPNTEX16'
 * @param {String} ppn The post processing note id.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getPPN(fields, ppn, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: PPN_DEFINITION_GET_PPN_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/postProcessingNote?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: PPN_DEFINITION_GET_PPN_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: PPN_DEFINITION_GET_PPN_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.get_ppn_error', lang, [ppn, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: PPN_DEFINITION_GET_PPN_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a post processing definition.
 * @param {Object} ppnDefinition The post processing note definition. Possible fields:
 * 'PPN': Name of the post processing note,
 * 'PPNTITLE': Long description of the post processing note,
 * 'DJDE': Print control file name,
 * 'OWNER': Owner of the post processing note,
 * 'CUSER' The current user,
 * 'PPNTEX1': Row 1 of the text,
 * 'PPNTEX2': Row 2 of the text,
 * 'PPNTEX3': Row 3 of the text,
 * 'PPNTEX4': Row 4 of the text,
 * 'PPNTEX5': Row 5 of the text,
 * 'PPNTEX6': Row 6 of the text,
 * 'PPNTEX7': Row 7 of the text,
 * 'PPNTEX8': Row 8 of the text,
 * 'PPNTEX9': Row 9 of the text,
 * 'PPNTEX10': Row 10 of the text,
 * 'PPNTEX11': Row 11 of the text,
 * 'PPNTEX12': Row 12 of the text,
 * 'PPNTEX13': Row 13 of the text,
 * 'PPNTEX14': Row 14 of the text,
 * 'PPNTEX15': Row 15 of the text,
 * 'PPNTEX16': Row 16 of the text,
 * @param {Function} callback The callback which will be called when the create post processing note request was successful. Can be null.
 */
export function createPPN(ppnDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: PPN_DEFINITION_CREATE_PPN_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/postProcessingNote`, {
        method: 'post',
        body: ObjectUtils.removeByValue(ppnDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: PPN_DEFINITION_CREATE_PPN_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: PPN_DEFINITION_CREATE_PPN_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.create_ppn_success', lang, ppnDefinition['PPN']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getPPNs again to get the current result
          const ppn = prefs[Preferences.DEFINITION_PPNS_ID]
          const fields = undefined
          const owner = prefs[Preferences.DEFINITION_PPNS_OWNER]
          const title = prefs[Preferences.DEFINITION_PPNS_TITLE]
          getPPNs(fields, ppn, owner, title, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.create_ppn_error', lang, [ppnDefinition['PPN'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
        dispatch({ type: PPN_DEFINITION_CREATE_PPN_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and deletes a post processing note definition.
 * @param {String} ppn The post processing note id.
 * @param {Function} callback The callback which will be called when the delete post processing note definition request was successful. Can be null.
 */
export function deletePPN(ppn, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: PPN_DEFINITION_DELETE_PPN_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/postProcessingNote${createQueryParamsForFetch({ PPN: ppn })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: PPN_DEFINITION_DELETE_PPN_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: PPN_DEFINITION_DELETE_PPN_SUCCESS })
          SnackbarActions.show(Lang.translate('definition.delete_ppn_success', lang, ppn), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getPPNs again to get the current result
          const ppnid = prefs[Preferences.DEFINITION_PPNS_ID]
          const fields = undefined
          const owner = prefs[Preferences.DEFINITION_PPNS_OWNER]
          const title = prefs[Preferences.DEFINITION_PPNS_TITLE]
          getPPNs(fields, ppnid, owner, title, undefined, true)(dispatch)
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.delete_ppn_error', lang, [ppn, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: PPN_DEFINITION_DELETE_PPN_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a post processing note definition.
 * @param {Object} ppnDefinition The ppnDefinition. Possible fields:
 * 'PPN': Name of the post processing note,
 * 'OWNER': Owner of the post processing note,
 * 'PPNTITLE': Long description of the post processing note,
 * 'CUSER' The current user,
 * 'DJDE': Print control file name,
 * 'PPNTEX1': Row 1 of the text,
 * 'PPNTEX2': Row 2 of the text,
 * 'PPNTEX3': Row 3 of the text,
 * 'PPNTEX4': Row 4 of the text,
 * 'PPNTEX5': Row 5 of the text,
 * 'PPNTEX6': Row 6 of the text,
 * 'PPNTEX7': Row 7 of the text,
 * 'PPNTEX8': Row 8 of the text,
 * 'PPNTEX9': Row 9 of the text,
 * 'PPNTEX10': Row 10 of the text,
 * 'PPNTEX11': Row 11 of the text,
 * 'PPNTEX12': Row 12 of the text,
 * 'PPNTEX13': Row 13 of the text,
 * 'PPNTEX14': Row 14 of the text,
 * 'PPNTEX15': Row 15 of the text,
 * 'PPNTEX16': Row 16 of the text,
 * 'SAVEUTF8': Flag for save as UTF8
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function updatePPN(ppnDefinition, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: PPN_DEFINITION_UPDATE_PPN_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/postProcessingNote`, {
        method: 'put',
        body: {
          PPN: ppnDefinition['PPN'],
          PPNTITLE: ppnDefinition['PPNTITLE'],
          OWNER: ppnDefinition['OWNER'],
          CUSER: ppnDefinition['CUSER'],
          DJDE: ppnDefinition['DJDE'],
          PPNTEX1: ppnDefinition['PPNTEX1'],
          PPNTEX2: ppnDefinition['PPNTEX2'],
          PPNTEX3: ppnDefinition['PPNTEX3'],
          PPNTEX4: ppnDefinition['PPNTEX4'],
          PPNTEX5: ppnDefinition['PPNTEX5'],
          PPNTEX6: ppnDefinition['PPNTEX6'],
          PPNTEX7: ppnDefinition['PPNTEX7'],
          PPNTEX8: ppnDefinition['PPNTEX8'],
          PPNTEX9: ppnDefinition['PPNTEX9'],
          PPNTEX10: ppnDefinition['PPNTEX10'],
          PPNTEX11: ppnDefinition['PPNTEX11'],
          PPNTEX12: ppnDefinition['PPNTEX12'],
          PPNTEX13: ppnDefinition['PPNTEX13'],
          PPNTEX14: ppnDefinition['PPNTEX14'],
          PPNTEX15: ppnDefinition['PPNTEX15'],
          PPNTEX16: ppnDefinition['PPNTEX16'],
          SAVEUTF8: ppnDefinition['SAVEUTF8']
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: PPN_DEFINITION_UPDATE_PPN_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: PPN_DEFINITION_UPDATE_PPN_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.update_ppn_success', lang, ppnDefinition['PPN']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getPPNs again to get the current result
          const ppn = prefs[Preferences.DEFINITION_PPNS_ID]
          const fields = undefined
          const owner = prefs[Preferences.DEFINITION_PPNS_OWNER]
          const title = prefs[Preferences.DEFINITION_PPNS_TITLE]
          getPPNs(fields, ppn, owner, title, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.update_ppn_error', lang, [ppnDefinition['PPN'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: PPN_DEFINITION_UPDATE_PPN_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calling the rest api and do getRecipients request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'PPN', 'PPNTITLE', 'OWNER', 'CDATE', 'CTIME', 'CUSER'
 * @param {String} ppn The post processing note id pattern.
 * @param {String} owner The owner pattern of the post processing notes.
 * @param {String} title The title pattern of the post processing notes.
 * @param {Function} callback the callback which will be called when the request was successful.
 */
export function getPPNs(fields, ppn, owner, title, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: PPN_DEFINITION_GET_PPNS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`PPNTITLE=${encodeURIComponent(title)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/postProcessingNotes?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_PPN_DEFINITION_PPNS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: PPN_DEFINITION_GET_PPNS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: PPN_DEFINITION_GET_PPNS_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.ppns_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: PPN_DEFINITION_GET_PPNS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets a list of postprocessing note definitions they are referenced in other context.
 * @param {String} ppn The postprocessing note (PPN).
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function verifyPPN(ppn, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: PPN_DEFINITION_VERIFY_PPN_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/postprocessingnote/verify`, {
        method: 'post',
        body: { PPN: ppn }
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          if (rc.toString() === '0016' && irc.toString() === '0000') {
            SnackbarActions.show(Lang.translate('general.verify_error'), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
          dispatch({ type: PPN_DEFINITION_VERIFY_PPN_FAILED, payload: { error } })
        } else {
          dispatch({ type: PPN_DEFINITION_VERIFY_PPN_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.verify_ppn_error', lang, [ppn, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: PPN_DEFINITION_VERIFY_PPN_FAILED, payload: { error } })
      })
  }
}