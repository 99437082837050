import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './Content.scss'

class Content extends Component {

  render = () => {
    const { id, content } = this.props
    return (
      <div id={id} className='drawer_field bux_drawer'>
        { content }
      </div>
    )
  }
}

Content.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.element
}

export default Content

export const CONTENT_TYPE_SEARCH = 0
export const CONTENT_TYPE_IMPORT = 1
export const CONTENT_TYPE_USER_MANAGEMENT = 2
export const CONTENT_TYPE_SECURITY = 3
export const CONTENT_TYPE_STATISTIC = 4
export const CONTENT_TYPE_DEFINITION = 5
export const CONTENT_TYPE_SERVER = 6
export const CONTENT_TYPE_DATABASE = 7
export const CONTENT_TYPE_ASSIGNMENT = 8
export const CONTENT_TYPE_QUEUE = 9