import PropTypes from 'prop-types'
import { Component } from 'react'
import * as SortUtils from 'utils/SortUtils'

// components
import {
  Column, DataTable,
  DownloadWrapper, EmptySearchResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton,
  TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import IndexQueueRequestInformation from 'components/dialogs/index_queue_request_information/IndexQueueRequestInformation'
import RerunIndexQueueDialog from 'components/dialogs/rerun_index_queue_dialog/RerunIndexQueueDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as IndexQueueActions from 'redux/actions/IndexQueueActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as QueueUtils from 'utils/QueueUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultIndexQueue extends Component {
  state = {
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation(),
    showInfoDialog: false,
    showRerunDialog: false
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    return [
      { rest: REQUESTQUEUED, translation: 'queue.requestqueued', default: true },
      { rest: DOCIMPORTED, translation: 'queue.documentimported', default: true },
      { rest: FORM, translation: 'general.form', default: true },
      { rest: EXTENSION, translation: 'general.extension', default: true },
      { rest: REPORT, translation: 'general.report', default: true },
      { rest: CIQPRIO, translation: 'queue.priority', default: true },
      { rest: PAGES, translation: 'general.pages', default: true },
      { rest: STATUS, translation: 'general.status', default: true },
      { rest: CIQTODO, translation: 'general.type' },
      { rest: TTOKEN },
      { rest: CIQDATE },
      { rest: CIQTIME },
      { rest: CIQUSER, translation: 'queue.queuedby' },
      { rest: DOCID },
      { rest: B93DATE },
      { rest: B93TIME },
      { rest: FILTER, translation: 'definition.document_filter' },
    ]
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to show.
   * @param {Array} header The headers of the table.
   * @returns {Array} The column sort definitions.
   */
  getColumnSortDefs = (data, header) => {
    return SortUtils.getSortTypes(data, header.length)
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   * @returns {Array} The actionbuttons of the table.
   */
  createActionButtons = rowIndex => {
    const { id, indexQueues } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType='material'
        iconName='info'
        title={translate('queue.show_information')}
        onClick={() => this.handleDialog(rowIndex, 'showInfoDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          id={`${id}_tableButtonInfo`}
          icon={<Icon name='info' className='actionIcon' />}
          text={translate('queue.show_information')}
          title={translate('queue.show_information')}
          onClick={() => this.handleDialog(rowIndex, 'showInfoDialog')}
        />
        {rowIndex !== undefined && indexQueues.data[rowIndex][this.headerData('STATUS')] === 'ERROR' &&
          <TableButtonGroupItem
            id={`${id}_tableButtonRerun`}
            icon={<Icon name='rerun' className='actionIcon' />}
            text={translate('queue.rerun')}
            title={translate('queue.rerun')}
            onClick={() => this.handleDialog(rowIndex, 'showRerunDialog')}
          />
        }
        <TableButtonGroupItem
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader.
   * @returns {Array} The interaction buttons of the table above the headers.
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.props.refreshSearch}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Gets the index of the header in redux state.
   * @param {String} header The header name of the header in redux state.
   * @returns {Number} The index of the header.
   */
  headerData = header => this.props.indexQueues.header.indexOf(header)

  /**
   * @description Builds the data to an array which is used later.
   * @returns {Array} The clean data.
   */
  getCleanData = () => {
    const { indexQueues, datemask } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    indexQueues.data.forEach(el => {
      let dataBuffer = []
      usedHeader.forEach(h => {
        if (h === DOCIMPORTED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('B93DATE')], el[this.headerData('B93TIME')].substring(0, 8)))
        }
        else if (h === REQUESTQUEUED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CIQDATE')], el[this.headerData('CIQTIME')].substring(0, 8)))
        }
        else if (h === CIQTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('CIQDATE')], el[this.headerData('CIQTIME')].substring(0, 8)))
        }
        else if (h === CIQDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CIQDATE')], el[this.headerData('CIQTIME')].substring(0, 8), false))
        }
        else if (h === B93TIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('B93DATE')], el[this.headerData('B93TIME')].substring(0, 8)))
        }
        else if (h === B93DATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('B93DATE')], el[this.headerData('B93TIME')].substring(0, 8), false))
        }
        else if (h === STATUS) {
          const status = el[this.headerData('STATUS')]
          const found = QueueUtils.STATUS_VALUES.find(entry => entry.key === status)
          dataBuffer.push(found ? translate(found.translationKey) : status)
        }
        else if (h === CIQTODO) {
          const type = el[this.headerData('CIQTODO')]
          if (type === 'IX') {
            dataBuffer.push(translate('definition.index'))
          } else {
            dataBuffer.push(type)
          }
        }
        else {
          const val = el[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_INDEX]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_INDEX].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_INDEX]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_QUEUE_INDEX].displayedHeaders.forEach(d => {
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  /**
  * @description Handles the modal dialogs
  * @param {Number} index Index of the data array
  * @param {String} dialog Which dialog to open
  */
  handleDialog = (index, dialog) => {
    const { indexQueues, getIndexQueueDetails } = this.props
    getIndexQueueDetails(
      indexQueues.data[index][this.headerData('TTOKEN')],
      () => this.setState({ [dialog]: true })
    )
  }

  deleteIndexQueue = () => {
    const { deleteIndexQueue, indexQueueDetails } = this.props
    const indexQueue = {
      TTOKEN: indexQueueDetails.TTOKEN,
      FORM: indexQueueDetails.FORM,
      EXT: indexQueueDetails.EXT,
      WREPORT: indexQueueDetails.WREPORT,
    }

    deleteIndexQueue(indexQueue, () => this.setState({ showDeleteDialog: false }))
  }

  renderDeleteDialog = () => {
    const { id, indexQueueDetails, datemask } = this.props

    return (
      <DeleteDialog
        id={`${id}_deleteindexqueue`}
        title={translate('queue.delete_index_request')}
        question={translate('queue.question_delete_index_request')}
        onClose={() => this.setState({ showDeleteDialog: false })}
        onDelete={() => this.deleteIndexQueue()}>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_request_requeued_key_text`}>
              {translate('queue.requeued')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_request_requeued_value_text`}>
              {DateUtils.getDate(datemask, indexQueueDetails.CIQDATE, indexQueueDetails.CIQTIME.substring(0, 8))}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_request_queuedby_key_text`}>
              {translate('queue.queuedby')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_request_queuedby_value_text`}>
              {indexQueueDetails.CIQUSER}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_request_form_key_text`}>
              {translate('general.form')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_request_form_value_text`}>
              {indexQueueDetails.FORM}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_request_extension_key_text`}>
              {translate('general.extension')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_request_extension_value_text`}>
              {indexQueueDetails.EXT}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_request_report_key_text`}>
              {translate('general.report')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_request_report_value_text`}>
              {indexQueueDetails.REPORT}
            </p>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <p id={`${id}_request_filter_key_text`}>
              {translate('definition.document_filter')}
            </p>
          </Column>
          <Column colMD={9}>
            <p id={`${id}_request_filter_value_text`}>
              {indexQueueDetails.FILTER}
            </p>
          </Column>
        </Row>
      </DeleteDialog>
    )
  }

  render = () => {
    const { showTableSettingsDialog, showInfoDialog, showDeleteDialog, showRerunDialog } = this.state
    const { id, drawerExpanded, autoDrawer, indexQueues, datemask, lang, keepPagination } = this.props
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    const data = indexQueues && indexQueues.data
      ? this.getCleanData(indexQueues.data)
      : null

    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            headers={this.state.header}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_QUEUE_INDEX }}
          />
        )}
        {showInfoDialog && (
          <IndexQueueRequestInformation
            id={`${id}_indexrequestinfo`}
            onClose={() => this.setState({ showInfoDialog: false })}
          />
        )}
        {showRerunDialog && (
          <RerunIndexQueueDialog
            id={`${id}_indexrerun`}
            onClose={() => this.setState({ showRerunDialog: false })}
          />
        )}
        {showDeleteDialog && this.renderDeleteDialog()}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if users not exist in redux
            indexQueues
              ? (
                // show empty result if there are no users after searching
                indexQueues.data
                  ? (
                    <DataTable
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={data}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={index => this.handleDialog(index, 'showInfoDialog')}
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      selectable
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptySearchResult
                      id={`${id}_emptysearchresult`}
                      description={translate('general.no_data_found')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )}
        </ResultContainer>
      </>
    )
  }
}

const TTOKEN = 'TTOKEN'
const CIQDATE = 'CIQDATE'
const CIQTIME = 'CIQTIME'
const FORM = 'FORM'
const EXTENSION = 'EXT'
const REPORT = 'REPORT'
const STATUS = 'STATUS'
const PAGES = 'PAGES'
const CIQUSER = 'CIQUSER'
const DOCID = 'DOCID'
const B93DATE = 'B93DATE'
const B93TIME = 'B93TIME'
const CIQTODO = 'CIQTODO'
const FILTER = 'FILTER'
const CIQPRIO = 'CIQPRIO'
const REQUESTQUEUED = 'REQUESTQUEUED'
const DOCIMPORTED = 'DOCIMPORTED'

SearchResultIndexQueue.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    indexQueues: state.queue.index.indices,
    keepPagination: state.queue.index.keepPagination,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    indexQueueDetails: state.queue.index.indexDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getIndexQueueDetails: (ttoken, callback) => {
      IndexQueueActions.getIndexQueueDetails(ttoken, callback)(dispatch)
    },
    deleteIndexQueue: (indexQueue, callback) => {
      IndexQueueActions.deleteIndexQueue(indexQueue, callback)(dispatch)
    },
    refreshSearch: () => { IndexQueueActions.refreshSearch()(dispatch) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultIndexQueue)