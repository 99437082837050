import * as DocumentNodeHierarchyActions from '../actions/DocumentNodesHierarchyActions.js'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  hierarchyData: undefined,
  hierarchyEntriesData: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case DocumentNodeHierarchyActions.DOCUMENT_NODE_GET_HIERARCHY_DATA:
      return { ...state, hierarchyData: action.payload.data }
    case DocumentNodeHierarchyActions.NO_DOCUMENT_NODE_HIERARCHY_FOUND:
      return { ...state, hierarchyData: {} }
    /* ---------------------------------------------------------------------- */
    case DocumentNodeHierarchyActions.DOCUMENT_NODE_GET_HIERARCHY_ENTRY_SUCCESS:
      return { ...state, hierarchyEntriesData: action.payload.newHierarchyEntriesData }
    case DocumentNodeHierarchyActions.NO_DOCUMENT_NODE_HIERARCHY_ENTRY_FOUND:
      return { ...state, hierarchyEntriesData: action.payload.newHierarchyEntriesData }
    /* ---------------------------------------------------------------------- */
    case DocumentNodeHierarchyActions.DOCUMENT_NODE_HIERARCHY_ENTRIES_UPDATED:
      return { ...state, hierarchyEntriesData: action.payload }
    /* ---------------------------------------------------------------------- */
    case AuthActions.LOGOUT_SUCCESS:
      return defaultState
    default: return state
  }
}

export default reducer