import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './NoSearch.scss'

export default class NoSearch extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Text to be displayed */
    message: PropTypes.string,
    /** Style class from CSS for styling for component.*/
    class: PropTypes.string
  }

  /**
   * @description Renders the component
   */
  render = () => {
    const { id, message } = this.props
    return (
      <div
        id='NoSearch'
        className={`${this.props.class} body_container_overflow bux_no_search_container`}>
        <p
          id={`${id}_description`}
          className='bux_no_search_description'>
          {message}
        </p>
      </div>
    )
  }
}