
// INDEX_DOCUMENT constants
export const AVAILABLE_ANY = ''
export const AVAILABLE_GLOBAL = 'GLOBAL'
export const AVAILABLE_LOCAL = 'LOCAL'

export const AVAILABLE_ITEMS = [
  { key: AVAILABLE_ANY, translationKey: 'general.any' },
  { key: AVAILABLE_GLOBAL, translationKey: 'general.global' },
  { key: AVAILABLE_LOCAL, translationKey: 'general.local' }
]

export const ANY = 'ANY'
export const PFIRST = 'PFIRST'
export const LFIRST = 'LFIRST'

export const DETECT_MATCHES = [
  { key: ANY, translationKey: 'general.all' },
  { key: PFIRST, translationKey: 'general.first_in_window_only' },
  { key: LFIRST, translationKey: 'general.first_in_line_only' }
]

export const PAGE = 'PAGE'
export const START = 'START'
export const END = 'END'
export const LINE = 'LINE'
export const HLINE = 'HLINE'

export const HIT_RANGE = [
  { key: PAGE, translationKey: 'general.hit_page' },
  { key: START, translationKey: 'general.include_pages_after' },
  { key: END, translationKey: 'general.include_pages_before' },
  { key: HLINE, translationKey: 'general.include_hit_line' },
  { key: LINE, translationKey: 'general.include_lines_after' }
]

export const MASK = 'MASK'
export const ETXT = 'ETXT'
export const NOPS = 'NOPS'
export const DOCV = 'DOCV'
export const ACIF = 'ACIF'
export const PJL = 'PJL'

export const DETERMINE_INDEX_VALUES_FROM = [
  { key: [ MASK, ETXT, NOPS ], translationKey: 'assignment.index_document_document_text_browser' },
  { key: DOCV, translationKey: 'assignment.index_document_document_variable' },
  { key: ACIF, translationKey: 'assignment.index_document_acif_index' },
  { key: PJL, translationKey: 'assignment.index_document_pjl_statement' },
]

const LS = 'LS'
const SS = 'SS'
const ES = 'ES'

export const RELATIVE_TO = [
  { key: LS, translationKey: 'general.line_start' },
  { key: SS, translationKey: 'general.search_pattern_start' },
  { key: ES, translationKey: 'general.search_pattern_end' }
]