import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_START = 'EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_START'
export const EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_SUCCESS = 'EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_SUCCESS'
export const EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_FAILED = 'EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_FAILED'

export const EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_START = 'EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_START'
export const EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_SUCCESS = 'EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_SUCCESS'
export const EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_FAILED = 'EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_FAILED'

export const EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_START = 'EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_START'
export const EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_SUCCESS = 'EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_SUCCESS'
export const EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_FAILED = 'EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_FAILED'

export const EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_START = 'EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_START'
export const EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_SUCCESS = 'EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_SUCCESS'
export const EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_FAILED = 'EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_FAILED'

export const NO_EXT_COMMAND_DEFINITION_EXT_COMMANDS_FOUND = 'NO_EXT_COMMAND_DEFINITION_EXT_COMMANDS_FOUND'

/**
 * @description Calls the rest api and creates a external command definition.
 * @param {Object} extCommandDefinition The extCommandDefinition. Possible fields:
 * 'UTL', 'UTLINFO', 'UTLPROG', 'UTLARGS', 'CUSER'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function createExternalCmd(extCommandDefinition, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/utility`, {
        method: 'post',
        body: ObjectUtils.removeByValue(extCommandDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.create_ext_command_success', lang, extCommandDefinition['UTL']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.create_ext_command_error', lang, [extCommandDefinition['UTL'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: EXT_COMMAND_DEFINITION_CREATE_EXT_COMMAND_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and deletes a external command definition.
 * @param {String} name The name of the external command.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function deleteExternalCommand(name, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/utility${createQueryParamsForFetch({ UTL: name })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.delete_ext_command_success', lang, name), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }

      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.delete_ext_command_error', lang, [name, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: EXT_COMMAND_DEFINITION_DELETE_EXT_COMMAND_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates an external command definition.
 * @param {Object} extCommandDefinition The extCommandDefinition. Possible fields:
 * 'UTL', 'UTLINFO', 'UTLPROG', 'UTLARGS', 'CUSER'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function updateExternalCommand(extCommandDefinition, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/utility`, {
        method: 'put',
        body: {
          UTL: extCommandDefinition['UTL'],
          UTLINFO: extCommandDefinition['UTLINFO'],
          UTLPROG: extCommandDefinition['UTLPROG'],
          UTLARGS: extCommandDefinition['UTLARGS'],
          CUSER: extCommandDefinition['CUSER'],
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.update_ext_command_success', lang, extCommandDefinition['UTL']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.update_ext_command_error', lang, [extCommandDefinition['UTL'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: EXT_COMMAND_DEFINITION_UPDATE_EXT_COMMAND_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calling the rest api and do getExternalCommands request.
 * @param {String} name The name pattern of the external command.
 * @param {String} description The description pattern of the external command.
 * @param {String} programName The program name pattern of the external command.
 * @param {Function} callback the callback which will be called when the request was successful. Can be null.
 */
export function getExternalCommands(name, description, programName, args, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (name) { queryParams.push(`UTL=${encodeURIComponent(name)}`) }
    if (description) { queryParams.push(`UTLINFO=${encodeURIComponent(description)}`) }
    if (programName) { queryParams.push(`UTLPROG=${encodeURIComponent(programName)}`) }
    if (args) { queryParams.push(`UTLARGS=${encodeURIComponent(args)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/utilities?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_EXT_COMMAND_DEFINITION_EXT_COMMANDS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_FAILED })
          }
        }
        else {
          dispatch({ type: EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.ext_commands_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: EXT_COMMAND_DEFINITION_GET_EXT_COMMANDS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Refreshes the search with redux prefs
 */
export const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const name = prefs[Preferences.DEFINITION_EXT_COMMANDS_ID]
    const description = prefs[Preferences.DEFINITION_EXT_COMMANDS_DESCRIPTION]
    const programName = prefs[Preferences.DEFINITION_EXT_COMMANDS_COMMAND]
    const args = prefs[Preferences.DEFINITION_EXT_COMMANDS_ARGUMENTS]
    getExternalCommands(name, description, programName, args, undefined, true)(dispatch)
  }
}