import { ReactNode } from 'react';

export interface DropdownDataset {
  key?: string;
  text: string;
  icon?: string;
}

export type DropdownData = DropdownDataset | string | ReactNode;

export const isDropdownDatasetType = (item: DropdownData): boolean => {
  return (item as DropdownDataset)?.text !== undefined;
};

export const isANode = (item: DropdownData): boolean => !isDropdownDatasetType(item) && typeof item !== 'string';