import * as AuthActions from '../actions/AuthenticationActions'
import * as PreferenceActions from '../actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'
import { ENGLISH } from 'language/Language'
import { MMDDYYYY_SLASH } from 'utils/DateUtils'

const defaultState = {
  loggedIn: false,
  userid: undefined,
  serverdata: {preferences: {'LANGUAGE': ENGLISH, 'DATEMASK': MMDDYYYY_SLASH}}
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Login actions
    case AuthActions.LOGIN_START:
      return { ...state, loggedIn: false, serverdata: {preferences: {}}}
    case AuthActions.LOGIN_SUCCESS:
      // remove useless success boolean from serverdata
      const serverdata = action.payload.data
      delete serverdata.success

      // parses the table settings config into objects
      Preferences.ALL_TABLE_SETTINGS.forEach(tableSetting => {
        if (serverdata.preferences[tableSetting]) {
          serverdata.preferences[tableSetting] = JSON.parse(serverdata.preferences[tableSetting])
        }
      })

      // converts the favorites back into the array of objects format
      if (serverdata.preferences.FAVORITES) {
        try {
          serverdata.preferences.FAVORITES = JSON.parse(serverdata.preferences.FAVORITES)
        }
        catch {
          console.error('Problem parsing BQL favorites.', serverdata.preferences.FAVORITES)
          serverdata.preferences.FAVORITES = []
        }
      }

      // set default values for language and datemask
      const modifiedServerdata = {
        ...serverdata,
        preferences: {
          ...serverdata.preferences,
          ...!serverdata.preferences.LANGUAGE && { LANGUAGE: ENGLISH },
          ...!serverdata.preferences.DATEMASK && { DATEMASK: MMDDYYYY_SLASH },
        }
      }
      // The userid should be displayed as its stored in the database. If the login is done with the ldap user, the ldap user should be displayed instead of the betauser.
      // The ldap user is not returned from login route. So we check the userid, which was used for the login. If it's not equal to the betauser the ldap user was used for login.
      const useridFrontend = action.payload.userid.toUpperCase()
      let useridToUse = action.payload.data.betauser ? action.payload.data.betauser.toUpperCase() : useridFrontend
      if (useridToUse !== useridFrontend) {
        useridToUse = useridFrontend
      }
      return { ...state, loggedIn: true, userid: useridToUse, serverdata: {...modifiedServerdata, ...{preferences: {...modifiedServerdata.preferences, ...state.serverdata.preferences}}} }
    case AuthActions.LOGIN_FAILED:
      return { ...state, loggedIn: false }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_START:
      return { ...state, loggedIn: true }
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      if (action.payload.persistPreferences)
        return { ...state, loggedIn: false, userid: undefined}
      else
        return { ...state, loggedIn: false, serverdata: {preferences: {LANGUAGE: state.serverdata.preferences.LANGUAGE, DATEMASK: state.serverdata.preferences.DATEMASK}}, userid: undefined}
    case AuthActions.LOGOUT_FAILED:
      return { ...state, loggedIn: false, userid: undefined }
    /* ********************************************************************************** */
    // Preference actions
    case PreferenceActions.PREFERENCES_CHANGE_PREFS:
      const newServerdata = state.serverdata

      // set the new preferences
      if (newServerdata)
        newServerdata.preferences = action.payload.newPrefs

      return { ...state, loggedIn: true, serverdata: {...newServerdata, ...{preferences: {...state.serverdata.preferences}}}}

    case AuthActions.GET_BACKENDS_START:
      return { ...state, availableBackends: undefined, serverdata: 'serverdata' in state ? state.serverdata : defaultState.serverdata }
    case AuthActions.GET_BACKENDS_SUCCESS:
      return { ...state, availableBackends: action.payload.data }
    case AuthActions.GET_BACKENDS_FAILED:
      return { ...state, availableBackends: undefined }
    case AuthActions.GET_RESTAPIVERSION_START:
      return { ...state, restApiVersion: undefined, serverdata: 'serverdata' in state ? state.serverdata : defaultState.serverdata }
    case AuthActions.GET_RESTAPIVERSION_SUCCESS:
      return { ...state, restApiVersion: action.payload.data }
    case AuthActions.GET_RESTAPIVERSION_FAILED:
      return { ...state, restApiVersion: undefined }
    /* ********************************************************************************** */
    /* ********************************************************************************** */
    // Language actions
    case AuthActions.TYPE_CHANGE_LANGUAGE:
      return { ...state, serverdata: {...state.serverdata, preferences: 'serverdata' in state ? { ...state.serverdata.preferences, LANGUAGE: action.payload.lang } : defaultState.serverdata.preferences } }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer