import * as ServerActions from '../actions/ServerActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  messagelog: undefined,
  serverLogFile: undefined,
  systemdefaults: undefined,
  printerdeviceinfo: undefined,
  keepPagination: undefined,
  lpdstatus: undefined,
  outputProcesses: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Server getMessageLog actions
    case ServerActions.SERVER_GET_MESSAGE_LOG_START:
      return { ...state }
    case ServerActions.SERVER_GET_MESSAGE_LOG_SUCCESS:
      return { ...state, messagelog: action.payload }
    case ServerActions.SERVER_GET_MESSAGE_LOG_FAILED:
      return { ...state }
    case ServerActions.NO_SERVER_MESSAGE_LOG_FOUND:
      return { ...state, messagelog: {} }
    /* ********************************************************************************** */
    // Server serverLogFile actions
    case ServerActions.SERVER_GET_SERVERLOGFILE_SERVER_LOG_FILE_START:
      return { ...state, serverLogFile: undefined }
    case ServerActions.SERVER_GET_SERVERLOGFILE_SERVER_LOG_FILE_SUCCESS:
      return { ...state, serverLogFile: action.payload }
    case ServerActions.SERVER_GET_SERVERLOGFILE_SERVER_LOG_FILE_FAILED:
      return { ...state }
    case ServerActions.NO_SERVER_SERVERLOGFILE_SERVER_LOG_FILE_FOUND:
      return { ...state, serverLogFile: {} }
    /* ********************************************************************************** */
    // Server getSystemDefaults actions
    case ServerActions.SERVER_GET_SYSTEM_DEFAULTS_START:
      return { ...state }
    case ServerActions.SERVER_GET_SYSTEM_DEFAULTS_SUCCESS:
      return { ...state, systemdefaults: action.payload, keepPagination: action.keepPagination }
    case ServerActions.SERVER_GET_SYSTEM_DEFAULTS_FAILED:
      return { ...state }
    case ServerActions.NO_SERVER_SYSTEM_DEFAULTS_FOUND:
      return { ...state, systemdefaults: {} }
    /* ********************************************************************************** */
    // Server printer device information
    case ServerActions.GET_PRINTER_DEVICE_INFORMATION_START:
      return { ...state }
    case ServerActions.GET_PRINTER_DEVICE_INFORMATION_SUCCESS:
      return { ...state, printerdeviceinfo: action.payload }
    case ServerActions.GET_PRINTER_DEVICE_INFORMATION_FAILED:
      return { ...state }
    case ServerActions.NO_PRINTER_DEVICE_INFORMATION_FOUND:
      return { ...state, printerdeviceinfo: {} }
    // Server lpd status
    case ServerActions.GET_LPD_QUEUES_STATUS_START:
      return { ...state }
    case ServerActions.GET_LPD_QUEUES_STATUS_SUCCESS:
      return { ...state, lpdstatus: action.payload }
    case ServerActions.GET_LPD_QUEUES_STATUS_FAILED:
      return { ...state }
    case ServerActions.NO_LPD_QUEUES_STATUS_FOUND:
      return { ...state, lpdstatus: {} }
    // Server output processes
    case ServerActions.GET_OUTPUT_PROCESSES_START:
      return { ...state }
    case ServerActions.GET_OUTPUT_PROCESSES_SUCCESS:
      return { ...state, outputProcesses: action.payload }
    case ServerActions.GET_OUTPUT_PROCESSES_FAILED:
      return { ...state }
    case ServerActions.NO_OUTPUT_PROCESSES_FOUND:
      return { ...state, outputProcesses: {} }
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return {
        ...state,
        messagelog: undefined,
        serverLogFile: undefined,
        systemdefaults: undefined,
        printerdeviceinfo: undefined,
        keepPagination: undefined,
        lpdstatus: undefined,
        outputProcesses: undefined
      }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer