import { translate } from 'language/Language'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  Button, Card, Column, DataTable, Icon, MetaDataGrid, Modal as ModalComponent,
  Row, TableButton, TableButtonGroup, TableButtonGroupGroup, TableButtonGroupItem, TableButtonGroupSeparator,
} from 'BetaUX2Web-Components/src/'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import DocumentPrint from 'components/dialogs/document_print_dialog/DocumentPrint'
import { DownloadDialog } from 'components/dialogs/download_dialog/DownloadDialog'
import RerunDialog, { ITEM_FORCE_AUTO_PRINT } from 'components/dialogs/rerun_dialog/RerunDialog'

import { addTabToDocViewer } from 'redux/actions/DocViewerActions'

const { Modal, Main, Header, Footer } = ModalComponent

import {
  displayLog, downloadLog, downloadLogHex, downloadLogText, getLog,
  getPrintInfo, markArchive,
  markDelete
} from 'redux/actions/SearchSyslogActions'
import * as Preferences from 'redux/general/Preferences'

import { getDate } from 'utils/DateUtils'
import * as UrlUtils from 'utils/UrlUtils'
import ZosJobLogInformation from '../zos_job_log_information/ZosJobLogInformation'

const SyslogJobInformationDialog = withRouter(({ history, id, onClose }) => {
  const lang = useSelector(store => store.auth.serverdata.preferences[Preferences.LANGUAGE])
  const datemask = useSelector(store => store.auth.serverdata.preferences[Preferences.DATEMASK])
  const syslogJobs = useSelector(store => store.search.syslog.syslogJobs)
  const syslogJobDetails = useSelector(store => store.search.syslog.syslogJobDetails)
  const syslogJobLogs = useSelector(store => store.search.syslog.syslogJobLogs)
  const syslogJobLog = useSelector(store => store.search.syslog.syslogJobLog)
  const syslogJobLogPrintInfo = useSelector(store => store.search.syslog.zosJobLogPrintInfo)
  const bwesystemname = useSelector(store => store.auth.serverdata.bwesystemname)
  const bwebaseurl = useSelector(store => store.auth.serverdata.bwebaseurl)
  const userprofile = useSelector(store => store.auth.serverdata.userprofile)

  const dispatch = useDispatch()

  const [showJobLogInformationDialog, setShowJobLogInformationDialog] = useState(false)
  const [showPrintDialog, setShowPrintDialog] = useState(false)
  const [selectedForDownload, setSelectedForDownload] = useState({
    showDownloadDialog: false,
    row: undefined,
    mimeType: undefined,
    downloadType: undefined
  })
  const [rerunState, setRerunState] = useState({
    showRerunDialog: false,
    rerunType: undefined
  })
  const [showDeleteFromArchiveDialog, setShowDeleteFromArchiveDialog] = useState(false)
  const [temp, setTemp] = useState(null)

  // Ref is used to adjust callback cycle for 'Display recipients' event in App.
  // Recipient data (syslogJobLog) for redirection is not available on opening the context menu
  // and needs to be fetched first (see SearchSyslogActions -> getLog)
  const redirectToRecipientLog = useRef(false)

  useEffect(() => {
    // Handling callback for cases: 'Archive' and 'Delete'
    if (syslogJobLog && temp && !redirectToRecipientLog.current) {
      updateMarkCallback()
      setTemp(null)
    }

    // Handling callback for case: 'Show recipient'
    if (syslogJobLog && !temp && redirectToRecipientLog.current) {
      redirectToRecipientLog.current = false
      handleRedirectToRecipientLog(syslogJobLog, history)
    }
    // eslint-disable-next-line
  }, [syslogJobLog, temp])

  const renderHeader = () => {
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('general.jobname'), value: syslogJobDetails.JOBNAME },
          { label: translate('job.jobid'), value: syslogJobDetails.JOBID },
          { label: translate('general.jobgroup'), value: syslogJobDetails.JOBGROUP },
          { label: translate('job.class'), value: syslogJobDetails.SRCCLAS },
          { label: translate('general.system'), value: syslogJobDetails.SYSNAME },
          { label: translate('job.submit_user'), value: syslogJobDetails.SRCSUBU },
          { label: translate('general.encoding'), value: syslogJobDetails.TEXTENCO },
          { label: translate('general.doctype'), value: syslogJobDetails.SRCJOBI },
          { label: translate('search.reader_date'), value: `${getDate(datemask, syslogJobDetails.SRCSUBD)} ${syslogJobDetails.SRCSUBT}` },
          { label: translate('general.pages'), value: syslogJobDetails.SRCPAGES },
          { label: translate('general.bytes'), value: syslogJobDetails.SRCBYTES },
        ]}
        columns={4}
      />
    )
  }

  const headerData = header => syslogJobLogs.header.indexOf(header)

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  const handleDisplayLog = rowIndex => {
    const docid = syslogJobLogs.data[rowIndex][headerData('DOCID')]
    displayLog(docid, bwebaseurl, bwesystemname)(dispatch)
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  const openDocumentViewer = (rowIndex) => {
    const docId = syslogJobLogs.data[rowIndex][headerData('DOCID')]
    const pageCount = syslogJobLogs.data[rowIndex][headerData('SRCPAGES')]

    addTabToDocViewer({
      id: docId,
      type: 'syslog',
      header: syslogJobLogs.header,
      data: syslogJobLogs.data[rowIndex],
      pageCount: parseInt(pageCount)
    })(dispatch)
  }

  const handleLogInformation = rowIndex => {
    const docid = syslogJobLogs.data[rowIndex][headerData('DOCID')]
    getLog(undefined, docid, undefined, () => setShowJobLogInformationDialog(true))(dispatch)
  }

  /**
   * @description Handles the open print document dialog action.
   * @param {Number} rowIndex The current row
   */
  const handleShowLogPrint = rowIndex => {
    const docid = syslogJobLogs.data[rowIndex][headerData('DOCID')]
    getLog(undefined, docid, undefined, () => {
      getPrintInfo(docid, () => setShowPrintDialog(true))(dispatch)
    })(dispatch)
  }

  /**
 * @description Handles the download functionality of logs in binary format.
 * @param {Number} rowIndex The current row.
 */
  const handleLogDownloadBinary = rowIndex => {
    const docid = syslogJobLogs.data[rowIndex][headerData('DOCID')]

    const form = syslogJobLogs.data[rowIndex][headerData('FORM')]
    const ext = syslogJobLogs.data[rowIndex][headerData('EXT')]
    const report = syslogJobLogs.data[rowIndex][headerData('REPORT')]
    let doctype = syslogJobLogs.data[rowIndex][headerData('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => entry !== undefined).join('.')
    downloadLog(docid, filename)(dispatch)
  }

  /**
   * @description Handles the download functionality. Opens an additional modal, if download settings  are available for a specific mimetype.
   * @param {Number} rowIndex The current row.
   * @param {String} mimetype The mimetype of the specific doucment.
   */
  const handleDocumentDownloadText = (rowIndex, mimetype) => {
    if (!selectedForDownload.showDownloadDialog && ['*.afp', '*.pcl', '*.pjl'].includes(mimetype)) {
      return openDownloadTextDialog(rowIndex)
    }
    return executeDownloadTextDirectly(rowIndex, mimetype)
  }

  const openDownloadTextDialog = rowIndex => {
    setSelectedForDownload({
      showDownloadDialog: true,
      row: rowIndex,
      mimeType: syslogJobLogs.data[rowIndex][headerData('SRCJOBI')],
      downloadType: 'TEXT'
    })
  }

  /**
   * @description Executes the text download directly without an additional modal.
   * @param {Number} rowIndex The current row.
   * @param {String} mimetype The mimetype of the specific doucment.
   */
  const executeDownloadTextDirectly = (rowIndex, mimetype) => {
    const docid = syslogJobLogs.data[rowIndex][headerData('DOCID')]
    const form = syslogJobLogs.data[rowIndex][headerData('FORM')]
    const ext = syslogJobLogs.data[rowIndex][headerData('EXT')]
    const report = syslogJobLogs.data[rowIndex][headerData('REPORT')]
    let doctype = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[mimetype]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadLogText(docid, filename, mimetypeTranslation)(dispatch)
  }

  /**
   * @description Handles the download functionality of zos job logs in text format from download dialog.
   * @param {String} showNOP YES/NO value for show NOP
   * @param {String} showTLE YES/NO value for show TLE
   * @param {String} showPJL YES/NO value for show PJL
   */
  const executeLogTextFromDialog = (showNOP, showTLE, showPJL) => {
    const { row, mimeType } = selectedForDownload
    const docid = syslogJobLogs.data[row][headerData('DOCID')]

    const form = syslogJobLogs.data[row][headerData('FORM')]
    const ext = syslogJobLogs.data[row][headerData('EXT')]
    const report = syslogJobLogs.data[row][headerData('REPORT')]
    let doctype = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[mimeType]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadLogText(docid, filename, mimetypeTranslation, showNOP, showTLE, showPJL, () => setSelectedForDownload({
      showDownloadDialog: false,
      row: undefined,
      mimeType: undefined,
      downloadType: undefined
    }))
  }

  /**
   * @description Handles the download functionality of zos job logs in hex format.
   * @param {Number} rowIndex The current row.
   */
  const handleLogDownloadHex = (displayMode, showPageHeader, showResources) => {
    const { row } = selectedForDownload
    const docid = syslogJobLogs.data[row][headerData('DOCID')]

    const form = syslogJobLogs.data[row][headerData('FORM')]
    const ext = syslogJobLogs.data[row][headerData('EXT')]
    const report = syslogJobLogs.data[row][headerData('REPORT')]
    let doctype = 'hex'

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadLogHex(docid, filename, displayMode, showPageHeader, showResources, () => setSelectedForDownload({
      showDownloadDialog: false,
      row: undefined,
      mimeType: undefined,
      downloadType: undefined
    }))(dispatch)
  }

  const handleShowDocumentDownloadHexDialog = rowIndex => {
    setSelectedForDownload({
      showDownloadDialog: true,
      row: rowIndex,
      mimeType: syslogJobLogs.data[rowIndex][headerData('SRCJOBI')],
      downloadType: 'HEX'
    })
  }

  /**
 * @description Handles the rerun action.
 * @param {Number} rowIndex The current row.
 * @param {String} type The type of rerun. Use constants of RerunDialog!
 */
  const handleRerun = (rowIndex, type) => {
    const docid = syslogJobLogs.data[rowIndex][headerData('DOCID')]
    getLog(undefined, docid, undefined, () => setRerunState({ showRerunDialog: true, rerunType: type }))(dispatch)
  }

  /**
   * @description Opens the recipient log assignment menu and prefills drawer values with values from log entry.
   * @param {*} index The rowindex
   */
  const showRecipients = (rowIndex) => {
    const docidIndex = syslogJobLogs.header.findIndex(h => h === 'DOCID')
    const docid = syslogJobLogs.data[rowIndex][docidIndex]

    getLog(undefined, docid, undefined, () => { redirectToRecipientLog.current = true })(dispatch)
  }

  /**
* @description un-/marks the archive or delete status, unmarks the reload status
* @param {Number} index the index of the current row
* @param {String} markedParam the header which should be used to update the ARCHIVE, DELETE or RELOAD
* possible: 'LGRFFARC', 'LGRFFDEL'
*/
  const updateMark = (index, markedParam) => {
    const docid = syslogJobLogs.data[index][headerData('DOCID')]
    getLog(undefined, docid, undefined, () => setTemp({ markedParam, index }))(dispatch)
  }

  const updateMarkCallback = () => {
    const { markedParam, index } = temp
    const docid = syslogJobLogs.data[index][headerData('DOCID')]

    const archiveStatus = (syslogJobLog[markedParam] === false || syslogJobLog[markedParam] === '') ? 'PENDING' : ''
    let logDefinition = {
      DOCID: docid,
      FORM: syslogJobLog.FORM,
      EXT: syslogJobLog.EXT,
      REPORT: syslogJobLog.REPORT
    }
    const job = syslogJobs.data.find(d => syslogJobLogs.data[index][headerData('SRCJOBN')] === d[syslogJobs.header.indexOf('JOBNAME')])

    const searchObj = {
      LOGSOURCE: 'SYSL',
      SRCSUBD: job[syslogJobs.header.indexOf('SRCSUBD')],
      SRCSUBT: job[syslogJobs.header.indexOf('SRCSUBT')],
      JOBNAME: job[syslogJobs.header.indexOf('JOBNAME')],
      JOBID: job[syslogJobs.header.indexOf('JOBID')],
    }
    if (markedParam === 'LGRFFARC') {
      logDefinition = {
        ...logDefinition,
        ARCHIVE: archiveStatus
      }
      markArchive(logDefinition, searchObj)(dispatch)
    } else if (markedParam === 'LGRFFDEL') {
      logDefinition = {
        ...logDefinition,
        DELETE: archiveStatus
      }
      markDelete(logDefinition, searchObj)(dispatch)
    }
  }

  /**
 * @description Handles the delete from archive action.
 * @param {Number} index The row index of document.
 */
  const handleDeleteFromArchive = index => {
    const docid = syslogJobLogs.data[index][headerData('DOCID')]
    getLog(undefined, docid, undefined, () => setShowDeleteFromArchiveDialog(true))(dispatch)
  }

  /**
   * @description Deletes the current document from archive.
   */
  const deleteFromArchive = () => {
    const docid = syslogJobLog['DOCID']

    const updateObj = {
      ARCHIVE: 'DELETE',
      DOCID: docid,
      FORM: syslogJobLog.FORM,
      EXT: syslogJobLog.EXT,
      REPORT: syslogJobLog.REPORT
    }

    const job = syslogJobs.data.find(d => syslogJobLog.SRCJOBN === d[syslogJobs.header.indexOf('JOBNAME')])
    const searchObj = {
      LOGSOURCE: 'SYSL',
      SRCSUBD: job[syslogJobs.header.indexOf('SRCSUBD')],
      SRCSUBT: job[syslogJobs.header.indexOf('SRCSUBT')],
      JOBNAME: job[syslogJobs.header.indexOf('JOBNAME')],
      JOBID: job[syslogJobs.header.indexOf('JOBID')],
    }

    markArchive(updateObj, searchObj, () => setShowDeleteFromArchiveDialog(false))
  }

  const getTableData = () => {
    const data = []
    syslogJobLogs.data.forEach(el => {
      const ddName = el[headerData('SRCDDND')]
      const stepName = el[headerData('SRCSTPD')]
      const procStep = el[headerData('SRCPRCD')]
      const filename = el[headerData('SRCDSN')]
      const pages = el[headerData('SRCPAGES')]
      const lines = el[headerData('SRCLNCT')]
      const jobClass = el[headerData('SRCCLAS')]
      const markedDelete = el[headerData('LGRFFDEL')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      const markedArchive = el[headerData('LGRFFARC')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      const markedReload = el[headerData('LGRFFREL')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      const archived = el[headerData('LGRARCH')] === 'YES' ? translate('general.yes') : translate('general.no')
      const online = el[headerData('LGRONLNE')] === 'YES' ? translate('general.yes') : translate('general.no')
      data.push([ddName, stepName, procStep, filename, pages, lines, jobClass, markedDelete, markedArchive, markedReload, archived, online])
    })
    return data
  }

  /**
  * @description Creates the action buttons for the table.
  * @param {Number} rowIndex The index of the current row.
  */
  const createActionButtons = rowIndex => {
    const modifyArchiveAllowed = userprofile === undefined || userprofile?.VCIARC
    const modifyDeleteAllowed = userprofile === undefined || userprofile?.VCIDEL
    const mimetype = rowIndex !== undefined ? syslogJobLogs?.data[rowIndex][headerData('SRCJOBI')] : undefined
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => handleLogInformation(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${rowIndex}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => openDocumentViewer(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonPrint_${rowIndex}`}
        iconType={'material'}
        iconName={'print'}
        title={translate('documentprint.print')}
        onClick={() => handleShowLogPrint(rowIndex)}
      />,
      <TableButtonGroup
        id={`${id}_tableButtonMore_${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          id={`${id}_display_btn`}
          icon={<Icon name='document' className='actionIcon' />}
          text={translate('general.display_in_beta_view')}
          title={translate('general.display_in_beta_view')}
          onClick={() => handleDisplayLog(rowIndex)}
        />
        <TableButtonGroupItem
          onClick={() => handleShowLogPrint(rowIndex)}
          id={`${id}_print_btn`}
          icon={<Icon name='print' className='actionIcon' />}
          text={translate('documentprint.print')}
          title={translate('documentprint.print')}
        />
        <TableButtonGroupGroup id={`${id}_download_group_btn`} text={translate('general.download')}>
          <TableButtonGroupItem
            onClick={() => handleLogDownloadBinary(rowIndex)}
            id={`${id}_download_btn`}
            icon={<Icon name={'download'} className={'actionIcon'} />}
            text={translate('general.download')}
            title={translate('general.download')}
          />
          {
            ['*.ps', '*.pdf', '*.afp', '*.pcl', '*.pjl'].includes(mimetype) &&
            <TableButtonGroupItem
              onClick={() => handleDocumentDownloadText(rowIndex, mimetype)}
              id={`${id}_download_text_btn`}
              icon={<Icon name={'download'} className={'actionIcon'} />}
              text={translate('general.download_as_text')}
              title={translate('general.download_as_text')}
            />
          }
          <TableButtonGroupItem
            onClick={() => handleShowDocumentDownloadHexDialog(rowIndex)}
            id={`${id}_download_hex_btn`}
            icon={<Icon name={'download'} className={'actionIcon'} />}
            text={translate('standard_selection.download_as_hex')}
            title={translate('standard_selection.download_as_hex')}
          />
        </TableButtonGroupGroup>
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => handleRerun(rowIndex, ITEM_FORCE_AUTO_PRINT)}
          id={`${id}_rerun_autoprint_btn`}
          icon={<Icon name='rerun' className='actionIcon' />}
          text={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
          title={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => showRecipients(rowIndex)}
          id={`${id}_show_recipients_btn`}
          icon={<Icon name='group' className='actionIcon' />}
          text={translate('general.display_recipients')}
          title={translate('general.display_recipients')}
        />
        {(modifyArchiveAllowed || modifyDeleteAllowed) &&
          <TableButtonGroupSeparator id={id} />
        }
        { /* check if syslogJobLogs.data[rowIndex] is not undefined is necessary, because redux updates the data faster than datatable, so datatable useses function with old index and we get an error, when less rows are in the new data (no proof, just a theory)*/}
        {modifyDeleteAllowed && (syslogJobLogs.data[rowIndex] && syslogJobLogs.data[rowIndex][headerData('LGRONLNE')] === 'YES' &&
          (
            syslogJobLogs.data[rowIndex] && syslogJobLogs.data[rowIndex][headerData('LGRFFDEL')] === ''
              ? <TableButtonGroupItem
                onClick={() => updateMark(rowIndex, 'LGRFFDEL')}
                id={`${id}_markDeleteBtn`}
                icon={<Icon name='delete' className='actionIcon' />}
                text={translate('general.mark_delete')}
                title={translate('general.mark_delete')}
              />
              : <TableButtonGroupItem
                onClick={() => updateMark(rowIndex, 'LGRFFDEL')}
                id={`${id}_unmarkDeleteBtn`}
                icon={<Icon name='close' className='actionIcon' />}
                text={translate('general.unmark_delete')}
                title={translate('general.unmark_delete')}
              />))
        }
        {modifyArchiveAllowed && (syslogJobLogs.data[rowIndex] && (
          syslogJobLogs.data[rowIndex][headerData('LGRONLNE')] === 'YES' && (
            syslogJobLogs.data[rowIndex][headerData('LGRFFARC')] === ''
              ? <TableButtonGroupItem
                onClick={() => updateMark(rowIndex, 'LGRFFARC')}
                id={`${id}_markArchiveBtn`}
                icon={<Icon name='archive' className='actionIcon' />}
                text={translate('general.mark_archive')}
                title={translate('general.mark_archive')}
              />
              : <TableButtonGroupItem
                onClick={() => updateMark(rowIndex, 'LGRFFARC')}
                id={`${id}_unmarkArchiveBtn`}
                icon={<Icon name='unarchive' className='actionIcon' />}
                text={translate('general.unmark_archive')}
                title={translate('general.unmark_archive')}
              />)))}
        {syslogJobLogs.data[rowIndex] && (
          syslogJobLogs.data[rowIndex][headerData('LGRARCH')] === 'YES' &&
          <TableButtonGroupItem
            onClick={() => handleDeleteFromArchive(rowIndex)}
            id={`${id}_delteFromArchiveBtn`}
            icon={<Icon name='close' className='actionIcon' />}
            text={translate('general.delete_from_archive')}
            title={translate('general.delete_from_archive')}
          />)}
      </TableButtonGroup>
    ]
  }

  const renderMain = () => {
    const data = syslogJobLogs?.data ? getTableData() : null
    return (
      <Card className={'bux_card_flex'}>
        <Row>
          <Column colMD={12}>
            <label id={`${id}_logfiles`}>{translate('job.log_files')}</label>
          </Column>
        </Row>
        <Row colMD={12} className='bux_full_height'>
          <DataTable
            id={id}
            header={[
              translate('job.dd_name'),
              translate('job.step_name'),
              translate('job.proc_step'),
              translate('general.file_name'),
              translate('general.pages'),
              translate('general.lines'),
              translate('job.class'),
              translate('general.marked_delete'),
              translate('general.marked_archive'),
              translate('general.marked_reload'),
              translate('general.archive'),
              translate('recipient.distribute_online')
            ]}
            columnSortDefs={[
              'string',
              'string',
              'string',
              'string',
              'number',
              'number',
              'number',
              'string',
              'string',
              'string',
              'string',
              'string'
            ]}
            createActionButtons={createActionButtons}
            createTableRowAction={rowIndex => handleLogInformation(rowIndex)}
            data={data}
            language={lang}
            datemask={datemask}
            translate={key => translate(key)}
            menu={false}
          />
        </Row>
      </Card>
    )
  }

  return (
    <>
      {
        showJobLogInformationDialog &&
        <ZosJobLogInformation
          id={`${id}_info_dialog`}
          zosJobLogInformation={syslogJobLog}
          onClose={() => setShowJobLogInformationDialog(false)}
        />
      }
      {
        showPrintDialog &&
        <DocumentPrint
          id={id}
          onClose={() => setShowPrintDialog(false)}
          document={syslogJobLog}
          printInfo={syslogJobLogPrintInfo}
        />
      }
      {
        selectedForDownload.showDownloadDialog &&
        <DownloadDialog
          id={selectedForDownload.downloadType === 'TEXT' ? 'download_text_dialog' : 'download_hex_dialog'}
          onClose={() => setSelectedForDownload({ showDownloadDialog: false, row: undefined, mimeType: undefined, downloadType: undefined })}
          mimetype={selectedForDownload.mimeType}
          download={selectedForDownload.downloadType === 'TEXT' ? executeLogTextFromDialog : handleLogDownloadHex}
          downloadType={selectedForDownload.downloadType}
          title={selectedForDownload.downloadType === 'TEXT' ? translate('general.download_as_text') : translate('standard_selection.download_as_hex')}
        />
      }
      {
        showDeleteFromArchiveDialog &&
        <DeleteDialog
          id={`${id}_deletedocumentfromarchivedialog`}
          title={translate('general.delete_from_archive')}
          question={translate('search.question_delete_from_archive_log')}
          onClose={() => setShowDeleteFromArchiveDialog(false)}
          onDelete={() => deleteFromArchive()}
          visible>
          <Row>
            <Column colMD={3} offsetMD={0}>
              <p id={`${id}_form`}>
                {translate('general.form')}:
              </p>
            </Column>
            <Column colMD={9} offsetMD={0}>
              <p id={`${id}_form_value`}>
                {syslogJobLog.FORM}
              </p>
            </Column>
          </Row>
          <Row>
            <Column colMD={3} offsetMD={0}>
              <p id={`${id}_extension`}>
                {translate('general.extension')}:
              </p>
            </Column>
            <Column colMD={9} offsetMD={0}>
              <p id={`${id}_extension_value`}>
                {syslogJobLog.EXT}
              </p>
            </Column>
          </Row>
          <Row>
            <Column colMD={3} offsetMD={0}>
              <p id={`${id}_report`}>
                {translate('general.report')}:
              </p>
            </Column>
            <Column colMD={9} offsetMD={0}>
              <p id={`${id}_report_value`}>
                {syslogJobLog.WREPORT}
              </p>
            </Column>
          </Row>
        </DeleteDialog>
      }
      {
        rerunState.showRerunDialog &&
        <RerunDialog
          id={id}
          onClose={() => setRerunState({ showRerunDialog: false })}
          type={rerunState.rerunType}
          document={syslogJobLog}
        />
      }
      <Modal
        id={'syslog_job_information_dialog'}
        className='buxUserProfileModal'
        size='l'
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={translate('general.job_details')}
          onClose={onClose}>
          {renderHeader()}
        </Header>
        <Main>
          {renderMain()}
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    </>
  )
})

/**
   * @description This function will parse the URL to access the recipient log based on the syslogJobLog data and will trigger a redirection.
   * @param {object} syslogJobLog Data of the Syslog job
   */
const handleRedirectToRecipientLog = (syslogJobLog, history) => {
  const keys = [
    { rest: 'FORM', ui: 'form' },
    { rest: 'EXT', ui: 'extension' },
    { rest: 'REPORT', ui: 'report' },
    { rest: 'WREPORT', ui: 'dynamicReport' },
  ]

  // Parsing 1st parameter for URL utility function using fetched recipient log data
  const state = {
    form: syslogJobLog.FORM,
    extension: syslogJobLog.EXT,
    report: syslogJobLog.WREPORT || '',
    dynamicReport: ''
    // tudi: Not possible to get the value at the moment.
    // dynamicReport: data[header.indexOf('DREPORT')],
  }

  // Parsing URL to access selected recipient log
  const urlToPush = `/assignment/recipient_log${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
  history.push(urlToPush)
}

export default SyslogJobInformationDialog