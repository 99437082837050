import * as DocumentNodesDefinitionActions from '../actions/DocumentNodesDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  documentNodes: undefined,
  documentNode: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case DocumentNodesDefinitionActions.DOCUMENT_NODES_DEFINITION_GET_DOCUMENT_NODES_SUCCESS:
      return { ...state, documentNodes: action.payload.data, keepPagination: action.keepPagination }
    case DocumentNodesDefinitionActions.DOCUMENT_NODES_DEFINITION_GET_DOCUMENT_NODE_SUCCESS:
      return { ...state, documentNode: action.payload.data }
    case DocumentNodesDefinitionActions.NO_DOCUMENT_NODES_DEFINITION_FOUND:
      return { ...state, documentNodes: {} }
    case AuthActions.LOGOUT_SUCCESS:
      return defaultState
    default: return state
  }
}

export default reducer