import React, { ReactNode, ReactNodeArray } from 'react';
import './Row.scss';
import { prepareClassNames } from '../../utils/ComponentUtils';

interface RowProps {
  /** Optional id of container div */
  id?: string,
  /** Sets full height of the row */
  fullHeight?: boolean;
  /** Sets padding on the end of the row */
  padded?: boolean;
  /** Sets no gap */
  noGap?: boolean;
  /** A class name which can be passed through the row */
  className?: string;
  /** Children of the component.*/
  children?: ReactNode | ReactNodeArray;
}

const Row = ({ ...props }: RowProps): JSX.Element => {
  const classNames = prepareClassNames([
    'cl_row row',
    props.className,
    props.fullHeight && 'cl_full_height',
    props.padded && 'cl_padded',
    props.noGap && 'cl_no_gap'
  ]);
  return (
    <div id={props.id} className={classNames}>
      {props.children}
    </div>
  );
};

export default Row;