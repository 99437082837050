import React, { Component } from 'react'
import './NavbarButton.scss'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Icon from '../../../icon/Icon'

class NavbarButton extends Component {
  state = {
    showHoverMenu: false
  }

  /**
   * @description Gets the link list to render.
   */
  getLinkList() {
    const { onAutoDrawer } = this.props
    const links = this.props.links
    const listOfLinks = links.map((link, index) =>
      <Link
        id={`${this.props.id}_links_${index}`}
        key={index}
        to={link.link}
        onMouseLeave={e => e.currentTarget.blur()}>
        <div className={'bux_title_link_container'} onClick={onAutoDrawer && (() => onAutoDrawer())}>
          {link.title}
        </div>
      </Link>
    )
    return (
      <div
        id={`${this.props.id}_nav_menu`}
        className='drawer_nav_menu'>
        <Link
          id={`${this.props.id}_nav_menu_titlelink`}
          to={this.props.link}
          className='drawer_nav_menu_headline'>
          {this.props.title}
        </Link>
        {listOfLinks}
      </div>
    )
  }

  /**
   * @description Shows the menu.
   */
  onHover = () => {
    this.setState({
      showHoverMenu: true
    })
  }

  /**
   * @description Hides the menu.
   */
  onLeave = () => {
    this.setState({
      showHoverMenu: false
    })
  }

  render = () => {
    const selected = this.props.selected ? 'selected' : ''
    return (
      <div
        id={this.props.id}
        className='drawer_nav_item'
        onMouseEnter={() => this.onHover()}
        onMouseLeave={() => this.onLeave()}>
        <Link
          id={`${this.props.id}_link`}
          to={this.props.link}>
          <button
            id={`${this.props.id}_link_button`}
            className={`nav_button drawer_nav_button ${selected} ${this.state.showHoverMenu
              ? 'nav_selected'
              : ''}`
            }>
            <Icon id={this.props.id} name={this.props.icon} />
          </button>
        </Link>
        {
          this.state.showHoverMenu &&
          this.getLinkList()
        }
      </div>
    )
  }
}

NavbarButton.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string.isRequired,
  /** Set NavbarButton as a selected one */
  selected: PropTypes.bool,
  /** Link to redirect on click */
  link: PropTypes.string.isRequired,
  /** Link to redirect on click */
  links: PropTypes.arrayOf(PropTypes.shape({
    /** Text to be displayed in submenu of NavbarButton.*/
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    /** Link to redirect on click */
    link: PropTypes.string,
    /** Sets is NavbarButton is available */
    available: PropTypes.bool
  })),
  /** Name of the icon to be displayed in component.*/
  icon: PropTypes.string.isRequired,
  /** Text to be displayed as tooltip when you hover component.*/
  title: PropTypes.string,
  /** Callback function to be called on click on NavbarButton */
  onAutoDrawer: PropTypes.func
}

export default NavbarButton