// Class names
export const CSS_CLASS_BROWSER_CONTENT = 'doc_browser_area_interaction_content';
export const CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT = 'doc_browser_line_highlight';
export const CSS_CLASS_TAB_CONTENT_CONTAINER = 'tab-content-container';
// IDs
export const COMPONENT_ID = 'documentViewer';
export const DRAWER_ID = 'drawer';
export const ID_HEX_CONTAINER = 'hex_container'; // Used for handling tab visibility check
export const ID_HEX_BLOCK_LINE = 'hexblock_line'; // Used for setting up the mutation observer
export const ID_HEX_BLOCK_SEGMENT = 'hexblock_segment'; // Used for handling selection logic in block-mode hex segments
export const ID_HEX_BLOCK_INACTIVE_SEGMENT = 'hexblock_inactive_segment';
export const ID_HEX_LINE_BLOCK = 'hexline_block'; // Used for setting up the mutation observer
export const ID_HEX_LINE_SEGMENT = 'hexline_segment'; // Used for handling selection logic in line-mode hex segments
export const ID_HEX_LINE_INACTIVE_SEGMENT = 'hexline_inactive_segment';
export const ID_TAB_CONTENT_WRAPPER = 'tab-content-wrapper'; // Used for setting up the hex selection event listener for 'click'