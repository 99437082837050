import React, { ReactNode, ReactNodeArray } from 'react';
import './Card.scss';
import { prepareClassNames } from '../../utils/ComponentUtils';

interface CardProps {
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** Label to be display.*/
  title?: string;
  /** Style class from CSS for styling card. */
  className?: string;
  /** Style class from CSS for styling card container. */
  containerClassName?: string;
  /** Enables card to take full hight of parent.*/
  fullHeight?: boolean;
  /** Removes the padding */
  noPadding?: boolean;
  /** Children of the component.*/
  children?: ReactNode | ReactNodeArray;
  /** Set background to transparent instead of white color */
  transparent?: boolean;
  /** Set disabled property on container */
  disabled?: boolean
}

/**
 * Card component created according to
 * _Card_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */

const Card = ({ ...props }: CardProps): JSX.Element => {
  const containerClassNames = prepareClassNames([
    'cl_cardContainer',
    props.containerClassName,
    props.fullHeight ? 'cl_fullHeight' : '',
  ]);
  const cardClassNames = prepareClassNames([
    'cl_cardContent',
    props.noPadding && 'cl_no_padding',
    props.transparent && 'cl_transparent',
    props.className
  ]);
  return (
    <div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      disabled={!!props.disabled}
      id={props.id}
      className={containerClassNames}
    >
      {
        props.title &&
        <label
          id={`${props.id}_title`}
          className={'cl_label'}>
          {props.title}
        </label>
      }
      <div
        id={`${props.id}_children`}
        className={cardClassNames}>
        {props.children}
      </div>
    </div>
  );
};

export default Card;