import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './Tooltip.scss';

const css = (el, props) => el.setAttribute('style', props);

export default class Tooltip extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Color style of the tooltip */
    colors: PropTypes.shape({
      font: PropTypes.string,
      background: PropTypes.string,
      foreground: PropTypes.string,
      border: PropTypes.string
    }),
    /** Type of the event which should trigger the tooltip. Default 'hover' */
    event: PropTypes.oneOf(['click', 'hover']),
    /** JSX element or elements in array */
    tooltipContent: PropTypes.node,
    /** Style class from CSS for styling for Tooltip.*/
    className: PropTypes.string,
    /** Children of the component.*/
    children: PropTypes.node,
    /** Id of scrollable parent of the component in order to close tooltip on scroll */
    parentContainer: PropTypes.string

  };

  state = {
    showContent: null
  };


  componentWillMount = () => {
    window.addEventListener('resize', this.handleResize);
  };

  /**
  * @description Handles the remove of 'resize' and 'scroll' listeners.
  */
  componentWillUnmount = () => {
    if (this.props.parentContainer) {
      const parentContainer = document.getElementById(`${this.props.parentContainer}`);
      parentContainer && parentContainer.removeEventListener('scroll', this.onScroll);
    }
    window.removeEventListener('resize', this.handleResize);
  };

  handleResize = () => {
    if (this.state.showContent) {
      this.setState({ showContent: false });
    }
  };

  /**
   * @description adds the color attributes to the css variables to change the colors base on color props
   * add 'scroll' listeners to parent component CreateOutputChannelDialog.
   */
  componentDidMount = () => {
    const { colors } = this.props;
    if (this.props.parentContainer) {
      const parentContainer = document.getElementById(`${this.props.parentContainer}`);
      parentContainer && parentContainer.addEventListener('scroll', this.onScroll);
    }

    if (colors) {
      if (colors.font) {
        document.querySelector(':root').style.setProperty('--font-color', colors.font);
      }
      if (colors.background) {
        document.querySelector(':root').style.setProperty('--background-color', colors.background);
      }
      if (colors.foreground) {
        document.querySelector(':root').style.setProperty('--foreground-color', colors.foreground);
      }
      if (colors.border) {
        document.querySelector(':root').style.setProperty('--border-color', colors.border);
      }
    }
  };

  /**
   * @description positions the tooltip content based on the position of the tooltip icon
   */
  componentDidUpdate = () => {
    const { id, children } = this.props;
    if (children.props.id) {
      const el = document.querySelector(`#${children.props.id}_icon`);
      const elPosX = el.getBoundingClientRect().x;
      const elPosY = el.getBoundingClientRect().y;
      const elWidth = el.getBoundingClientRect().width;
      const elHeight = el.getBoundingClientRect().height;
      const el2 = document.querySelector(`#${id}_tooltip_wrapper`);
      const el2Height = el2 && el2.getBoundingClientRect().height;
      el2 && css(
        el2, `
        left: ${elPosX + 35}px;
        min-width: ${elWidth}px;
        top: ${elPosY + (elHeight / 2) - (el2Height/2)}px;
        `
      );
    }
  };

  /**
   * @description Sets the wrapper reference to compare the click event component
   * and decide if it's the same component.
   * @param {Object} node The new reference.
   */
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  /**
   * @description Closes file-list if click is outside of file-list.
   * @param e The event object
   */
  handleClickOutside = e => {
    e.stopPropagation();
    if (this.wrapperRef && this.wrapperRef.contains(e.target)) {
      return false;
    }
    this.toggleTooltip();
  };

  /**
   * @description Toggles the component tooltip component and adds/remove the click event
   */
  toggleTooltip = () => {
    if (!this.state.showContent) {
      document.addEventListener('click', this.handleClickOutside);
    } else {
      document.removeEventListener('click', this.handleClickOutside);
    }
    this.setState(prevState => { return { showContent: !prevState.showContent } });
  };

  /**
  * @description Handles close the modal dialog.
  */

  closeModal = e => {
    e.stopPropagation();
    document.removeEventListener('click', this.closeModal);
    this.setState({ showContent: false });
  };

  /**
  * @description Handles the scroll on parent Component CreateOutputChannelDialog.
  */

  onScroll = () => {
    if (this.state.showContent) {
      this.setState({ showContent: false });
      document.removeEventListener('click', this.closeModal);
    }
  };

  /**
   * @description Handles the click.
   */
  handleClick = e => {
    e.stopPropagation();
    if (this.state.showContent) {
      this.closeModal(e);
      return;
    }
    this.setState({ showContent: true });
    e.stopPropagation();
    document.addEventListener('click', this.closeModal);
  };

  /**
   * @description Handles the mouse over.
   */
  handleMouseOver = () => (this.props.event !== 'click' || this.props.event === 'hover') &&
    this.setState({ showContent: true });

  /**
   * @description Handles the mosue leave.
   */
  handleMouseLeave = () => (this.props.event !== 'click' || this.props.event === 'hover') &&
    this.setState({ showContent: false });

  handleKeyDown = (e) => {
    return e.key === 'Enter' && this.handleClick(e) && false;
  };

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, tooltipContent, className, children } = this.props;
    const { showContent } = this.state;
    return (
      <div
        id={id}
        onMouseLeave={this.handleMouseLeave}
        onBlur={this.handleMouseLeave}
        className={className ? className : ''}>
        <div
          id={`${id}_wrapper`}
          tabIndex={0}
          onFocus={this.handleMouseOver}
          onKeyDown={this.handleKeyDown}
          onMouseOver={this.handleMouseOver}
          onClick={(e) => this.handleClick(e)}
          ref={this.setWrapperRef}
          className='tooltip_icon_wrapper'>
          {children}
        </div>
        {ReactDOM.createPortal(
          showContent !== false &&
          <TooltipList
            id={`${id}_tooltip`}
            items={tooltipContent}
            visibility={showContent}
            wrapperRef={this.setWrapperRef}
          />
          , document.querySelector('#moreVert_Portal')
        )}
      </div>
    );
  };
}

const TooltipList = ({ id, items, visibility, wrapperRef }) => {
  return (
    <ul
      id={`${id}_wrapper`}
      ref={wrapperRef}
      className={`tooltip_wrapper ${visibility ? 'open' : 'close'}`}>
      {items}
    </ul>
  );
};