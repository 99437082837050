import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '../../button/Button'
import Dropdown from '../../dropdown/Dropdown'
import DownloadWrapper from '../../download_wrapper/DownloadWrapper'
import Icon from '../../icon/Icon'
import Input from '../../input/Input'
import './TableMenu.scss'
import { ButtonGroup } from '../../button/ButtonGroup'
import { getHashFromRow, getIndexFromRow } from '../DataTable.utils'


export default class TableMenu extends Component {

  /**
   * @description This function will conditionally return the base dropdown item(s).
   * @returns {Array}
   */
  getBaseDropdownItems = () => {
    const { openDocViewerCallback, translate } = this.props

    return openDocViewerCallback
      ? [
        { text: translate('documentviewer.document_display')},
        { text: translate('table.download_as_csv') }
      ]
      : [{ text: translate('table.download_as_csv') }]
  }

  /**
   * @description  Removes the last entry in the array which displays the original index position in request repsonse.
   *               This is needed because we want to compare hashes when request response changed after deleting one entry.
   */
  removeIndexInfo = arr => {
    return [...arr.filter((_, i) => i !== arr.length - 1)]
  }

  /**
   * @description Renders the display action button when a row is selected and the callback to open the DocViewer is defined.
   */
  renderDisplayAction = () => {
    const { id, openDocViewerCallback, checkedRows, data } = this.props;

    if (checkedRows.length > 0 && data) {
      const selectedRows = [];
      data.forEach(d => {
        if (checkedRows.includes(getHashFromRow(d))) {
          const position = getIndexFromRow(d);
          selectedRows.push(position);
        }
      });

      return (
        <button
          id='we_id_table_action_btn'
          type='button'
          className={'input-group-addon el_input_button el_table_head_action_input_addon bux_table_menu_open'}
          onClick={() => openDocViewerCallback(selectedRows)}>
          <Icon
            id={`${id}_actionBtn_icon`}
            name='open_drawer'
          />
        </button>
      );
    }
  };

  /**
   * @description Renders the download action when checked a row.
   */
  renderDownloadAction = () => {
    const { id, cleanData, header, checkedRows, data } = this.props

    if (checkedRows.length > 0 && data) {
      const selectedRows = []
      data.forEach(d => {
        if (checkedRows.includes(getHashFromRow(d))) {
          const position = getIndexFromRow(d)
          selectedRows.push(this.removeIndexInfo(cleanData.find(entry => getIndexFromRow(entry) === position)))
        }
      })
      return (
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...selectedRows]}
          csvSplitter=';'
          filename='data.csv'>
          <button
            id='we_id_table_action_btn'
            type='button'
            className={'input-group-addon el_input_button el_table_head_action_input_addon bux_table_menu_open'}>
            <Icon
              id={`${id}_actionBtn_icon`}
              name='open_drawer'
            />
          </button>
        </DownloadWrapper>
      )
    }
  }

  /**
   * @description Renders the modal for dropdown items other than 'Display' and 'Download as CSV'
   */
  renderModalAction = () => {
    const { id, showModal } = this.props

    return (
      <button
        id='we_id_table_action_btn'
        type='button'
        className={'input-group-addon el_input_button el_table_head_action_input_addon bux_table_menu_open'}
        onClick={() => showModal()}
      >
        <Icon
          id={`${id}_actionBtn_icon`}
          name='open_drawer'
        />
      </button>
    )
  }

  /**
   * @description Renders the whole table menu.
   */
  render() {
    const {
      data,
      additionalInteraction,
      id,
      clickNext,
      clickPrevious,
      changeDownloadAction,
      updateFilter,
      filter,
      currentPage,
      itemsPerPage,
      checkedRows,
      showFilter,
      downloadActionIndex,
      downloadItems,
      translate,
      openDocViewerCallback
    } = this.props
    let from = `${data.length > 0 ? ((currentPage - 1) * itemsPerPage) + 1 : 0}`
    let to = `${itemsPerPage * currentPage <= data.length ? itemsPerPage * currentPage : data.length}`
    return (
      <div className={'interactionContainer'}>
        <span id={`${id}_pages`}>
          {`${from} - ${to} ${translate('general.of')} ${data.length}`}
        </span>
        
          <ButtonGroup>
            <Button
              id={`${id}_interaction_button_prev`}
              onClick={clickPrevious}
              icon={'chevron_left'}
              tooltip={'Previous Page'}
              disabled={currentPage === 1}
            />
            <Button
              id={`${id}_interaction_button_next`}
              onClick={clickNext}
              icon={'chevron_right'}
              tooltip={'Next Page'}
              disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
            />
          </ButtonGroup>
        <div className={'interactionButtons'}>
          {
            Array.isArray(additionalInteraction)
              ? additionalInteraction.map((d, index) => React.cloneElement(d, { key: `${index}_interaction` }))
              : additionalInteraction
          }
        </div>
        {
          checkedRows && checkedRows.length > 0 &&
          <div className='bux_actionDropdown'>
            {/* selected rows information */}
            <label className='el_table_head_action_input_label nowrap'>
              {`${checkedRows.length} ${translate('general.selected')}`}
            </label>
            {/* dropdown of user actions */}
            <Dropdown
              id={`${id}_dropdown`}
              activeIndex={downloadActionIndex}
              items={
                downloadItems
                  ? [...this.getBaseDropdownItems(), ...downloadItems.map(t => ( { text: t } )) ]
                  : [...this.getBaseDropdownItems()]
              }
              onChange={index => changeDownloadAction(index)}
            />
            {/* component to execute the action of the dropdown */}
            {
              downloadActionIndex === 0 && (openDocViewerCallback ? this.renderDisplayAction() : this.renderDownloadAction())
            }
            {
              downloadActionIndex === 1 && (openDocViewerCallback ? this.renderDownloadAction() : this.renderModalAction())
            }
            {
              downloadActionIndex > 1 && this.renderModalAction()
            }
          </div>
        }
        {
          showFilter &&
          <Input
            id={`${id}_filter`}
            containerClassName={'bux_filter'}
            name={'table_filter'}
            onInputChanged={val => updateFilter(val)}
            placeholder={translate('table.filter_table')}
            value={filter}
          />
        }
      </div>
    )
  }
}

TableMenu.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string.isRequired,
  /** Header which are displayed in DataTable component */
  header: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Data which is displayed in DataTable component
   *
   * Type: `array<array<any>>`
  */
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  /**
   * Data which is used for CSV download. In most cases data and cleanData are not different.
   * But if you display other JSX elements inside the table, you need to remove the JSX elements
   * to make a download of the data possible.
   *
   * Type: `array<array<object>>`
   */
  cleanData: PropTypes.arrayOf(PropTypes.array).isRequired,
  /** Function to be called on next page clicked */
  clickNext: PropTypes.func.isRequired,
  /** Function to be called on previous page clicked */
  clickPrevious: PropTypes.func.isRequired,
  /**
   * Function to be called on change of selected user action in dropdown
   * @param {number} index Index of selected item
  */
  changeDownloadAction: PropTypes.func.isRequired,
  /** Array of JSX elements which are displayed in the menu.*/
  additionalInteraction: PropTypes.array,
  /**
   * Function to be called on input change of the filter
   * @param {string} val Current input value
   */
  updateFilter: PropTypes.func.isRequired,
  /** Filtered phrase */
  filter: PropTypes.string.isRequired,
  /** Number of current page */
  currentPage: PropTypes.number.isRequired,
  /** Number of items per page */
  itemsPerPage: PropTypes.number.isRequired,
  /** Sets row state of checked if _selectable_ option is set to _true_*/
  checkedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Displays filter in Table menu */
  showFilter: PropTypes.bool.isRequired,
  /** Index of action which allows to download the data */
  downloadActionIndex: PropTypes.number.isRequired,
  /** Array of user actions displayed in dropdown in table menu*/
  downloadItems: PropTypes.arrayOf(PropTypes.string),
  /**
     * Function to translate following key by our own.
     *
     * - `table.filter_table`
     * - `general.selected`
     * - `table.download_as_csv`
     *
     * @param {string} key
     */
  translate: PropTypes.func,
  /**
     * Function to be called on custom action from _downloadItems_ proceed on the table
     * @param {array<array<any>>} checkedRows Array of whole rows of data which are checked
     */
  showModal: PropTypes.func,
}