import React, { ReactNode } from 'react';
import { ModalCommonProps } from './Modal.types';
import Link from '../link/Link';
import { prepareClassNames } from '../../utils/ComponentUtils';

export interface HeaderProps extends ModalCommonProps {
  title?: ReactNode;
  onClose?: () => void;
}

export const Header = ({ id, title, children, className='', onClose }: HeaderProps): JSX.Element => {
  const titleClassNames = prepareClassNames([
    'modal_header_title',
    className]);

  return (
    <header id={`${id}`}>
      <div className="modal_header_content">
        <div className={titleClassNames}>
          {title}
        </div>
        {onClose && <Link id={`${id}_modal_close`} className={'modal_close_button'} iconName={'close'} onClick={onClose} />}
      </div>
      {children &&
        <div className={'modal_header_items'}>
          {children}
        </div>
      }
    </header>
  );
};