import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AVAILABLE_ANY, AVAILABLE_ITEMS } from 'utils/AssignmentUtils'

import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Switch from 'BetaUX2Web-Components/src/components/switch/Switch'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

import { translate } from 'language/Language'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as UrlUtils from 'utils/UrlUtils'
import * as UserUtils from 'utils/UserUtils'
import * as Preferences from 'redux/general/Preferences'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as IndexDocumentAssignmentActions from 'redux/actions/IndexDocumentAssignmentActions'


class AssignmentIndexDocument extends Component {

  defaultState = {
    indexID: '',
    form: '',
    extension: '',
    report: '',
    availableIndex: 0,
    showIndexSelectorDialog: false,
    showDocumentSelectorDialog: false
  }

  keys = [
    { rest: 'IXINAME', ui: 'indexID' },
    { rest: 'FORM', ui: 'form' },
    { rest: 'EXT', ui: 'extension' },
    { rest: 'REPORT', ui: 'report' },
    { rest: 'IXSCOPE', ui: 'availableIndex' },
  ]

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount = () => {
    const { prefs } = this.props
    if (window.location.href.indexOf('?') !== -1) {
      this.initFieldsFromUrl()
    }
    else {
      if (prefs) {
        const indexID = prefs[Preferences.ASSIGNMENT_INDEX_DOC_INDEXID] || ''
        const form = prefs[Preferences.ASSIGNMENT_INDEX_DOC_FORM] || ''
        const extension = prefs[Preferences.ASSIGNMENT_INDEX_DOC_EXTENSION] || ''
        const report = prefs[Preferences.ASSIGNMENT_INDEX_DOC_REPORT] || ''
        const available = prefs[Preferences.ASSIGNMENT_INDEX_DOC_AVAILABLE] || AVAILABLE_ANY
        const availableIndex = Math.max(AVAILABLE_ITEMS.findIndex(temp => temp.key === available), 0)
        this.setState({ indexID, form, extension, report, availableIndex })
      }
    }
  }

  /**
   * @description Fills the state with the values from the url and executes the search when the parameter is given.
   */
  initFieldsFromUrl = () => {
    const newValues = UrlUtils.getDataFromUrlParams(this.props.location.search, this.keys)
    this.setState({
      indexID: newValues.indexID || '',
      form: newValues.form || '',
      extension: newValues.extension || '',
      report: newValues.report || '',
      availableIndex: !isNaN(newValues.availableIndex) ? parseInt(newValues.availableIndex) : 0,
    }, () => {
      if (UrlUtils.urlContainsExecute()) {
        this.search()
      }
    })
  }

  /**
   * @description gets the index of the header in redux state
   * @param {Object} data specific redux object
   * @param {String} header header name of the header in redux
   */
  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description Requests the index definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handleIndexSelector = () => {
    const { getIndexes } = this.props
    const { indexID } = this.state
    const callback = () => this.setState({ showIndexSelectorDialog: true })

    getIndexes(['IXINAME', 'IXENAME'], indexID, callback)
  }

  /**
   * @description Requests the document definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handleDocumentSelector = () => {
    const { getDocumentDefinitions } = this.props
    const { form, extension, report } = this.state
    const callback = () => this.setState({ showDocumentSelectorDialog: true })

    getDocumentDefinitions(['FORM', 'EXT', 'REPORT'], form, extension, report, callback)
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => this.setState(this.defaultState)


  /**
   * @description Handles the changes on input.
   * @param val The new value
   */
  handleInput = (id, val) => this.setState({ [id]: val })

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = event => {
    event.preventDefault()
    this.search()
  }

  /**
   * @description Executes the search.
   */
  search = () => {
    const { changePrefs, getIndexDocumentAssignments } = this.props
    const { indexID, form, extension, report, availableIndex } = this.state
    const available = AVAILABLE_ITEMS[availableIndex].key
    const prefsToChange = {
      [Preferences.ASSIGNMENT_INDEX_DOC_INDEXID]: indexID,
      [Preferences.ASSIGNMENT_INDEX_DOC_FORM]: form,
      [Preferences.ASSIGNMENT_INDEX_DOC_EXTENSION]: extension,
      [Preferences.ASSIGNMENT_INDEX_DOC_REPORT]: report,
      [Preferences.ASSIGNMENT_INDEX_DOC_AVAILABLE]: available,
    }
    changePrefs(prefsToChange)

    // sort by default 'I' for now, could be changed later
    getIndexDocumentAssignments(undefined, indexID, form, extension, report, available, 'I')

    // builds the parameter object which is used for url
    let specificUrl = 'index_document'
    if (UserUtils.isLOGX()) {
      specificUrl = 'index_log'
    }
    const urlToPush = `/assignment/${specificUrl}${UrlUtils.createUrlParamsFromObject(this.state, this.keys)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    const { showIndexSelectorDialog, showDocumentSelectorDialog } = this.state
    return (
      <>
        {showIndexSelectorDialog && (
          <SelectorDialog
            id={`${id}_indexdocument_indexdefinition_selector_dialog`}
            onClose={() => this.setState({ showIndexSelectorDialog: false })}
            title={translate('definition.index_definitions')}
            header={[
              translate('definition.index_id'),
              translate('general.title')
            ]}
            items={selector.indexes.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const indexID = selector.indexes.data[selectedRows][this.headerData(selector.indexes, 'IXINAME')]
                this.setState({ indexID })
              }
              this.setState({ showIndexSelectorDialog: false })
            }}
          />
        )}
        {showDocumentSelectorDialog && (
          <SelectorDialog
            id={`${id}_indexdocument_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report')
            ]}
            items={selector.documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const form = selector.documents.data[selectedRows][this.headerData(selector.documents, 'FORM')]
                const extension = selector.documents.data[selectedRows][this.headerData(selector.documents, 'EXT')]
                const report = selector.documents.data[selectedRows][this.headerData(selector.documents, 'REPORT')]
                this.setState({ form, extension, report })
              }
              this.setState({ showDocumentSelectorDialog: false })
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { indexID, form, extension, report, availableIndex } = this.state
    return (
      <Card
        title={translate('general.general')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_indexdocument_indexid`}
              onInputChanged={val => this.handleInput('indexID', val)}
              value={indexID}
              title={translate('definition.index_id')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.handleIndexSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_indexdocument_form`}
              onInputChanged={val => this.handleInput('form', val)}
              value={form}
              title={translate('general.form')}
              maxLength={8}
              addon={{
                iconName: 'list',
                onClick: () => this.handleDocumentSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_indexdocument_extension`}
              onInputChanged={val => this.handleInput('extension', val)}
              value={extension}
              title={translate('general.extension')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.handleDocumentSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_indexdocument_report`}
              onInputChanged={val => this.handleInput('report', val)}
              value={report}
              title={translate('general.report')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.handleDocumentSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_indexdocument_available`}
              title={translate('general.available')}
              items={[
                translate('general.any'),
                translate('general.global'),
                translate('general.local'),
              ]}
              onClick={index => this.handleInput('availableIndex', index)}
              activeIndex={availableIndex}
              maxPerRow={3}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_indexdocument_main`}
        className={'bux_drawer_main'}>
        {this.renderSelectorDialogs()}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSubmitSearch}
          submit
          primary />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getIndexes: (fields, index, callback) => {
      ModalSelectorActions.getIndexes(
        fields,
        index,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    getIndexDocumentAssignments: (fields, indexID, form, ext, report, scope, sortBy, callback) => {
      IndexDocumentAssignmentActions.getIndexDocumentAssignments(
        fields,
        indexID,
        form,
        ext,
        report,
        scope,
        sortBy,
        callback)(dispatch)
    }
  }
}

AssignmentIndexDocument.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssignmentIndexDocument))