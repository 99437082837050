import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'

// Components
import {
  Button, Card, Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent, MultilineInput, Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as OutputFormatActions from 'redux/actions/OutputFormatDefinitionActions'
import * as Preferences from 'redux/general/Preferences'

class ModifyOutputFormatDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func,
  }

  state = {
    owner: {
      value: this.props.outputFormat.OWNER,
      errorkey: ''
    },
    title: {
      value: this.props.outputFormat.PCRTITLE,
      errorkey: ''
    },
    values: {
      value: this.props.outputFormat.PCROUTP,
      errorkey: ''
    }
  }

  ownerInput = React.createRef()

  componentDidMount = () => {
    this.ownerInput.current.focus()
  }

  /**
   * @description Handles changes in input fields.
   * @param id The id of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInput = (id, value, errorkey) => this.setState({ [id]: { value, errorkey } })

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { outputFormat, updateOutputFormat, onClose } = this.props
    const { owner, title, values } = this.state

    const outputFormatDefinition = {
      PCR: outputFormat.PCR,
      OWNER: owner.value,
      PCRTITLE: title.value,
      PCROUTP: values.value.split('\n').join(' ')
    }
    updateOutputFormat(outputFormatDefinition, () => onClose())
  }

  render = () => {
    const { id, onClose, datemask, outputFormat } = this.props
    const { owner, title, values } = this.state
    return (
      <Modal onClose={onClose} id={id}>
        <Header
          id={id}
          title={translate('definition.modify_output_format')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('definition.output_format_id'), value: outputFormat.PCR },
              { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, outputFormat.CDATE, outputFormat.CTIME.substring(0, 8)) },
            ]}
            columns={4}
          />
        </Header>
        <Main id={id}>
          <Card>
            <Row>
              <Column colMD={3}>
                <Input
                  id={`${id}_owner`}
                  value={owner.value}
                  ref={this.ownerInput}
                  title={translate('general.owner')}
                  maxLength={8}
                  onInputChanged={(value, errorkey) => this.handleInput('owner', value, errorkey)}
                  error={owner.errorkey && translate(owner.errorkey)}
                />
              </Column>
              <Column colMD={9}>
                <Input
                  id={`${id}_title`}
                  value={title.value}
                  title={translate('general.title')}
                  maxLength={80}
                  onInputChanged={(value, errorkey) => this.handleInput('title', value, errorkey)}
                  error={title.errorkey && translate(title.errorkey)}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <MultilineInput
                  id={`${id}_values`}
                  title={translate('definition.output_format_values')}
                  onInputChanged={val => this.handleInput('values', val)}
                  value={values.value}
                  rows={6}
                  maxLength={640}
                  maxLengthIncludesLinebreaks
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    outputFormat: state.definitions.outputformats.outputFormat,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateOutputFormat: (outputFormat, callback) => {
      OutputFormatActions.updateOutputFormat(outputFormat, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyOutputFormatDialog)