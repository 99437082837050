import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './TableButtonGroup.scss'
import ReactDOM from 'react-dom'
import TableButtonGroupItem from '../table_button_group_item/TableButtonGroupItem'
import TableButtonGroupGroup from '../table_button_group_group/TableButtonGroupGroup'
import TableButtonGroupSeparator from '../table_button_group_separator/TableButtonGroupSeparator'
import Icon from '../../../icon/Icon'


export default class TableButtonGroup extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Text to be displayed in tooltip when you hover component.*/
    tooltip: PropTypes.string,
    /** Disables this component. Default: false. */
    disabled: PropTypes.bool,
    // /** Children of the component.*/
    // children: PropTypes.node

  }

  constructor(props) {
    super(props)
    this.state = {
      showDropdown: false,
      restoreFocus: false
    }
    this.moreVertItemsRef = React.createRef()
    this.buttonRef = React.createRef()
  }

  setListRef = node => {
    this.moreVertItemsRef.current = node
  }

  /**
   * @description Toggle dropdown via setState
   */
  handleDropdown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }))
  }

  /**
   * @description Close dropdown via setState
   */
  closeDropdown = () => {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false })
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.showDropdown !== prevState.showDropdown) {
      if (this.state.showDropdown) {
        document.addEventListener('mousedown', this.handleClick)
        window.addEventListener('resize', this.closeDropdown)
        const el = document.querySelector('.bux_datatable_scroll_container')
        if (el) {
          el.addEventListener('scroll', this.closeDropdown)
        }
      }
      else {
        document.removeEventListener('mousedown', this.handleClick)
        window.removeEventListener('resize', this.closeDropdown)
        const el = document.querySelector('.bux_datatable_scroll_container')
        if (el) {
          el.removeEventListener('scroll', this.closeDropdown)
        }
      }
    }
    if (this.state.restoreFocus) {
      this.buttonRef && this.buttonRef.focus()
    }
    if (this.state.showDropdown) {
      this.moreVertItemsRef && this.moreVertItemsRef.current.focus()
    }
    else if (this.state.restoreFocus) {
      this.buttonRef && this.buttonRef.focus()
    }
  }

  /**
   * @description Check wether the user clicks inside the dropdown. Close it otherwise
   */
  handleClick = event => {
    // Checks if the click is inside the more vert menu or not.
    // First ref has a current kay because its directly created from React.createRef().
    if (((this.buttonRef.current && this.buttonRef.current.contains(event.target))) || (this.buttonRef.current && this.moreVertItemsRef.current.contains(event.target)) || (this.moreVertItemsRef.current && this.moreVertItemsRef.current.contains(event.target))) {
      return
    }
    // Close the dropdown if the click is outside it's node
    this.setState({ showDropdown: false })
  }

  calcSingleElementHeight = child => {
    const tableButtonGroupItemObjType = TableButtonGroupItem.name
    const tableButtonGroupGroupObjType = TableButtonGroupGroup.name
    const tableButtonSeperator = TableButtonGroupSeparator.name
    if ([tableButtonGroupItemObjType, tableButtonGroupGroupObjType].includes(child.type.name)) {
      return 40 // Items will have a height of 40px
    }
    else if (child.type.name === tableButtonSeperator) {
      return 9 // Seperators will have a height of 9px
    }
    return 0
  }

  /**
   * @description Calculate the expectedDropdownHeight by looping through all children &
   * Adding the expected heights
   */
  calcExpectedDropdownHeight = () => {
    const { children } = this.props
    let expectedHeight = 2 + 10 // Add border(2px) and padding(10px)

    // Loop through each child and add the expected height to expectedHeight
    const fragment = (<></>).type
    React.Children.forEach(children.filter(d => d?.type !== TableButtonGroupItem.type), child => {
      if (child.type === fragment) {
        const innerChildren = child.props.children
        innerChildren.forEach(innerChild => {
          expectedHeight += this.calcSingleElementHeight(innerChild)
        })
      }
      else {
        expectedHeight += this.calcSingleElementHeight(child)
      }
    })
    return expectedHeight
  }

  /**
   * @description Calculate the position of the parent and the distance to top and bottom
   * Compare the bottom offset with the calculated expected dropdown height
   * Decide wether to render it above or below the parent
   */
  getTopPosition = () => {
    const { id } = this.props

    let expectedDropwdownHeight = this.calcExpectedDropdownHeight()
    // Relative parent position & absolute offset to top and bottom of page (not viewport)
    let parentPos = document.getElementById(`${id}_dropdownBtn`).getBoundingClientRect()
    let parentOffsetTop = parentPos.top + window.pageYOffset
    let parentOffsetBottom = document.body.clientHeight - parentOffsetTop - parentPos.height

    // Alternatives for document.body.clientHeight (in case it isn't reliable):
    // $(document.height() (JQuery)
    // document.documentElement.clientHeight

    // Check wether the dropdown has enough space to render below it's parent
    if (parentOffsetBottom > expectedDropwdownHeight) {
      return { top: (parentOffsetTop + 40), left: parentPos.left }
    }
    else {
      // Position it 6px inside, but above the parent
      return { top: (parentOffsetTop - expectedDropwdownHeight + 10), left: parentPos.left }
    }
  }

  render = () => {
    const { id, tooltip, disabled } = this.props
    const { showDropdown } = this.state

    return (
      <div
        className='table_no_spaceing bux_tableButtonGroup'
        title={tooltip}>
        <div className={`dropdown ${disabled ? 'disabled' : ''}`}>
          <button
            id={`${id}_dropdownBtn`}
            className={'cl_more_button el_table_btn dropdown-toggle'}
            onClick={this.handleDropdown}
            ref={this.buttonRef}>
            <Icon name='more_vertical' />
          </button>
          {
            showDropdown &&
            ReactDOM.createPortal(
              <>
                <ul
                  tabIndex={0}
                  id={`${id}_dropdownMenu`}
                  className={'dropdown-menu el_wall_btn_dropdown morevert_dropdown'}
                  onClick={this.closeDropdown}
                  ref={node => {
                    this.setListRef(node)
                    // Calculates the stylings for the more vert items container. Need to be implemented this way, because its not possible to set stylings with "!important" through styling prop.
                    if (node) {
                      node.style.setProperty('top', `${this.getTopPosition().top}px`, 'important')
                      node.style.setProperty('right', `calc(100% - ${this.getTopPosition().left}px - 39px)`, 'important')
                    }
                  }}>
                  {this.props.children}
                </ul>
                <div tabIndex={0} onFocus={() => this.setState({ showDropdown: false, restoreFocus: true })}></div>
              </>,
              document.querySelector('#moreVert_Portal')
            )
          }
        </div>
      </div>
    )
  }
}