import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  Button, Card,
  Checkbox, Column,
  Input, NumericSpinner, Row, Switch
} from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as ServerActions from 'redux/actions/ServerActions'
import * as Preferences from 'redux/general/Preferences'
import * as ServerUtils from 'utils/ServerUtils'

class ServerOutputProcesses extends Component {

  state = {
    showOutputChannelDefinitionSelectorDialog: false,
    outputChannelID: { value: '', error: '' },
    selectBy: 0,
    refreshtime: 10,
    status: 0,
    selectPriority: 0,
    priority: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
      13: false,
      14: false,
      15: false,
    },
    refreshing: false
  }

  outputChannelIDRef = React.createRef()
  refreshInterval = undefined

  componentDidMount = () => {
    const { preferences, userprofile } = this.props
    const { VCIPM01 } = userprofile || {}
    this.setState({
      outputChannelID: {
        value: preferences[Preferences.SERVER_OUTPUT_PROCESSES_OUTPUT_CHANNEL_ID]
          ? preferences[Preferences.SERVER_OUTPUT_PROCESSES_OUTPUT_CHANNEL_ID]
          : (VCIPM01 && VCIPM01 !== '')
            ? VCIPM01
            : '',
        error: ''
      },
      selectBy: Math.max(ServerUtils.SELECT_BY.findIndex(d => d.key === preferences[Preferences.SERVER_OUTPUT_PROCESSES_OUTPUT_SELECT_BY]), 0),
      status: Math.max(ServerUtils.STATUS.findIndex(d => d.key === preferences[Preferences.SERVER_OUTPUT_PROCESSES_OUTPUT_STATUS]), 0),
      ...this.convertPrioIntoStateInfo()
    })
  }

  componentWillUnmount = () => {
    clearInterval(this.refreshInterval)
    this.setState({ refreshing: false })
  }

  buildPriorityStateObj = arr => {
    const result = {}
    arr.forEach(d => {
      result[d] = true
    })
    return result
  }

  convertPrioIntoStateInfo = () => {
    const { preferences } = this.props
    const priority = preferences[Preferences.SERVER_OUTPUT_PROCESSES_OUTPUT_PRIORITY]
    let result = {}
    if (priority && priority !== '') {
      if (priority === '123456789ABCDEF') {
        result.selectPriority = 0
      }
      else {
        result.selectPriority = 1
        result.priority = { ...result.priority, ...this.buildPriorityStateObj(priority.split('').map(d => parseInt(d, 16))) }
      }
    }
    return result
  }

  handleReset = () => {
    const { VCIPM01 } = this.props.userprofile || {}
    this.setState({
      outputChannelID: { value: (VCIPM01 && VCIPM01 !== '') ? VCIPM01 : '', error: '' },
      selectBy: 0,
      refreshtime: 10,
      status: 0,
      selectPriority: 0,
      priority: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
      }
    }, () => this.handleStop())
  }

  convertToHex = val => {
    return Number(val).toString(16)
  }

  validateOutputChannel = () => {
    const { outputChannelID } = this.state
    const { userprofile } = this.props
    const { VCIPM01R } = userprofile || {}
    if (VCIPM01R) {
      if (outputChannelID.value === '') {
        this.setState({ outputChannelID: { value: '', error: 'general.input_required' } }, () => {
          this.outputChannelIDRef.current && this.outputChannelIDRef.current.focus()
        })
        return false
      }
    }
    return true
  }

  handleRefresh = event => {
    const { outputChannelID, selectBy, refreshtime, status, selectPriority, priority } = this.state
    const { getOutputProcesses } = this.props
    event.preventDefault()
    if (this.validateOutputChannel()) {
      const priorityToUse = selectPriority === 0
        ? Object.keys(priority).map(d => this.convertToHex(d)).join('').toUpperCase()
        : Object.entries(priority).map(([key, val]) => val ? key : undefined).filter(d => d !== undefined).map(d => this.convertToHex(d)).join('').toUpperCase()

      const outputChannelValue = outputChannelID.value.trim() ? outputChannelID.value : '*'
      const prefsToChange = {
        [Preferences.SERVER_OUTPUT_PROCESSES_OUTPUT_CHANNEL_ID]: outputChannelValue,
        [Preferences.SERVER_OUTPUT_PROCESSES_OUTPUT_SELECT_BY]: ServerUtils.SELECT_BY[selectBy].key,
        [Preferences.SERVER_OUTPUT_PROCESSES_OUTPUT_STATUS]: ServerUtils.STATUS[status].key,
        [Preferences.SERVER_OUTPUT_PROCESSES_OUTPUT_PRIORITY]: priorityToUse,
      }
      this.props.changePrefs(prefsToChange)

      const requestObj = {
        DCR: outputChannelValue,
        MOD: ServerUtils.SELECT_BY[selectBy].key,
        STATE: ServerUtils.STATUS[status].key,
        PRIO: priorityToUse
      }
      getOutputProcesses(requestObj, () => {
        this.setState({ refreshing: true })
        this.refreshInterval = setInterval(() => {
          getOutputProcesses(requestObj)
        }, refreshtime * 1000)
      })
    }
  }

  handleStop = event => {
    event && event.preventDefault()
    clearInterval(this.refreshInterval)
    this.setState({ refreshing: false })
  }

  /**
   * @description Requests the outputchannel definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenOutputChannelDefinitionDialog = () => {
    const callback = () => this.setState({ showOutputChannelDefinitionSelectorDialog: true })
    this.props.getOutputChannelDefinitions(
      ['DCR', 'DCRTITLE'],
      this.state.outputChannelID.value,
      callback
    )
  }

  renderSelectorDialog = () => {
    const { showOutputChannelDefinitionSelectorDialog } = this.state
    const { selector, id } = this.props
    return (
      showOutputChannelDefinitionSelectorDialog && (
        <SelectorDialog
          id={`${id}_document_outputchannel_selector_dialog`}
          onClose={() => this.setState({ showOutputChannelDefinitionSelectorDialog: false })}
          title={translate('definition.outputchanneldefinitions')}
          header={[translate('definition.output_channel_id'), translate('general.title')]}
          items={selector.outputchannels.data}
          onSelect={selectedRows => {
            if (selectedRows.length > 0) {
              const outputChannelID = selector.outputchannels.data[selectedRows][selector.outputchannels.header.indexOf('DCR')]
              this.setState({ outputChannelID: { value: outputChannelID, error: '' } })
            }
            this.setState({ showOutputChannelDefinitionSelectorDialog: false })
          }}
        />
      )
    )
  }

  renderGeneral = () => {
    const { outputChannelID, selectBy, refreshtime, status } = this.state
    const { id, userprofile } = this.props
    const { VCIPM01R, VCIPM01D } = userprofile || {}
    return (
      <Card title={translate('general.general')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_outputchannelid`}
              title={translate('definition.outputchannel.id')}
              value={outputChannelID.value}
              error={outputChannelID.error && translate(outputChannelID.error)}
              onInputChanged={val => this.setState({ outputChannelID: { value: val, error: '' } })}
              onBlur={() => VCIPM01R && outputChannelID.value === '' && this.setState({ outputChannelID: { value: '', error: 'general.input_required' } })}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputChannelDefinitionDialog()
              }}
              required={VCIPM01R ? `${translate('general.required_field')}` : false}
              disabled={VCIPM01D}
              ref={this.outputChannelIDRef}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_select_by`}
              title={translate('search.select_by')}
              items={ServerUtils.SELECT_BY.map(d => translate(d.translationKey))}
              activeIndex={selectBy}
              onClick={index => this.setState({ selectBy: index })}
              maxPerRow={3}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <NumericSpinner
              id={`${id}_refreshtime`}
              title={translate('general.refreshtime_in_seconds')}
              onChange={val => this.setState({ refreshtime: val })}
              value={refreshtime}
              min={10}
              max={999}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_status`}
              title={translate('general.status')}
              items={ServerUtils.STATUS.map(d => translate(d.translationKey))}
              activeIndex={status}
              onClick={index => this.setState({ status: index })}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  renderPriority = () => {
    const { selectPriority, priority } = this.state
    const { id } = this.props
    return (
      <Card title={translate('documentprint.priority')}>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_select_priority`}
              title={translate('general.select_priority')}
              items={[translate('general.any'), translate('general.specific')]}
              activeIndex={selectPriority}
              onClick={index => this.setState({ selectPriority: index })}
            />
          </Column>
        </Row>
        {
          selectPriority === 1 &&
          <>
            <Row>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_0`}
                  value={priority[0]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 0: value } })}
                  label={'0'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_1`}
                  value={priority[1]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 1: value } })}
                  label={'1'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_2`}
                  value={priority[2]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 2: value } })}
                  label={'2'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_3`}
                  value={priority[3]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 3: value } })}
                  label={'3'}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_4`}
                  value={priority[4]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 4: value } })}
                  label={'4'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_5`}
                  value={priority[5]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 5: value } })}
                  label={'5'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_6`}
                  value={priority[6]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 6: value } })}
                  label={'6'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_7`}
                  value={priority[7]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 7: value } })}
                  label={'7'}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_8`}
                  value={priority[8]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 8: value } })}
                  label={'8'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_9`}
                  value={priority[9]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 9: value } })}
                  label={'9'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_10`}
                  value={priority[10]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 10: value } })}
                  label={'10'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_11`}
                  value={priority[11]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 11: value } })}
                  label={'11'}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_12`}
                  value={priority[12]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 12: value } })}
                  label={'12'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_13`}
                  value={priority[13]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 13: value } })}
                  label={'13'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_14`}
                  value={priority[14]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 14: value } })}
                  label={'14'}
                />
              </Column>
              <Column colMD={3}>
                <Checkbox
                  id={`${id}_15`}
                  value={priority[15]}
                  onCheck={value => this.setState({ priority: { ...this.state.priority, 15: value } })}
                  label={'15'}
                />
              </Column>
            </Row>
          </>
        }
      </Card>
    )
  }

  /**
   * @description Renders the main components.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div id={`${id}_main`} className={'bux_drawer_main'} tabIndex={-1}>
        {this.renderSelectorDialog()}
        {this.renderGeneral()}
        {this.renderPriority()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { refreshing } = this.state
    const { id } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate(refreshing ? 'general.stop' : 'general.start')}
          onClick={refreshing ? this.handleStop : this.handleRefresh}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleReset}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    selector: state.selector,
    userprofile: state.auth.serverdata && state.auth.serverdata.userprofile,
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(fields, outputChannel, undefined, undefined, undefined, undefined, callback)(dispatch)
    },
    getOutputProcesses: (params, callback) => ServerActions.getOutputProcesses(params, callback)(dispatch),
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch)
  }
}

ServerOutputProcesses.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerOutputProcesses)