import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './ModifySecurityProfileDialog.scss';


// Components
import {
  Button, Card, Column, Dropdown, Input,
  MetaDataGrid,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/';

const { Modal, Main, Header, Footer } = ModalComponent


// Utils
import * as DateUtils from 'utils/DateUtils';


//  Redux
import { connect } from 'react-redux';
import * as SecurityProfileActions from 'redux/actions/SecurityProfileActions';
import * as Preferences from 'redux/general/Preferences';
import * as SecurityFunctions from 'redux/general/SecurityFunctions';

class ModifySecurityProfileDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    profileDesc: {
      value: this.props.securityProfileToModify.PRODESC,
      error: ''
    },
    accessIndex: 0,
    accessTranslated: [
      translate('security.access_none'),
      translate('security.access_read'),
      translate('security.access_update'),
      translate('security.access_alter')
    ],
    accessOrig: [
      'NONE',
      'READ',
      'UPDATE',
      'ALTER'
    ],
  }

  accessInput = React.createRef()

  /**
   * @description Initializes the state values.
   */
  componentDidMount() {
    // init index of access dropdown
    this.setState({
      accessIndex: this.state.accessOrig.indexOf(this.props.securityProfileToModify.ACCESS)
    })

    this.accessInput.current.focus()
  }

  /**
   * @description Changes the access selection.
   * @param {Number} activeIndex The new index position.
   */
  handleOnAccessChanged(activeIndex) {
    this.setState({ accessIndex: activeIndex })
  }

  /**
   * @description Changes the profile description value and it's error key.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleOnProfileDescChanged(value) {
    this.setState({
      profileDesc: {
        value: value
      }
    })
  }

  /**
   * @description Closes this dialog.
   */
  handleOnClose = () => {
    this.props.onClose()
  }

  /**
   * @description Handles the save action.
   */
  handleOnSave = () => {
    // call create request
    const cuser = this.props.userId
    const profile = this.props.securityProfileToModify.PROFILE
    const betauser = this.props.securityProfileToModify.BETAUSER
    const profileDesc = this.state.profileDesc.value
    const access = this.state.accessOrig[this.state.accessIndex]

    // declare success callback
    const callback = () => { this.props.onClose() }

    this.props.updateSecurityProfile(cuser, profile, betauser, profileDesc, access, callback)
  }

  render = () => {
    const { id, lang, dateMask, securityProfileToModify } = this.props
    const { accessIndex, profileDesc, accessTranslated } = this.state

    let profilemask = ''
    let profileentry = ''

    const entryAndParts = SecurityFunctions.getProfilentryAndParts(securityProfileToModify.PROFILE.split('.')[0])

    // if there is no translationkey for the prefix, set the entry to free text and use the complete text as profilemask
    if (entryAndParts.translationKey === undefined) {
      profileentry = SecurityFunctions.FREE
      profilemask = securityProfileToModify.PROFILE.split('.')[0]
    } else {
      profileentry = entryAndParts.translationKey
      profilemask = securityProfileToModify.PROFILE.split('.').slice(1).join('.')
    }

    return (
      <Modal onClose={this.handleOnClose}
        id={'security_modifySecurityProfileModal_dialog'}
        className={'bux_modifySecurityProfileModal'}>
        <Header
          id={`${id}_modifySecurityProfileModal`}
          title={translate('security.modify_security_profile', lang)}
          onClose={this.handleOnClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('security.profile_entry', lang), value: translate(profileentry, lang) },
              { label: translate('general.profile_mask', lang), value: profilemask },
              { label: translate('security.user_group_id', lang), value: securityProfileToModify.BETAUSER },
              { label: translate('general.last_changed', lang), value: `${DateUtils.getDate(dateMask, securityProfileToModify.CDATE, securityProfileToModify.CTIME.substring(0, 8))} ${translate('general.by', lang)} ${securityProfileToModify.CUSER}`},
            ]}
            columns={4}
          />
        </Header>
        <Main id={`${id}_modifySecurityProfileModal`}>
          <Card id={`${id}_card`}>
            <Row>
              <Column colMD={4}>
                <Dropdown
                  id={`${id}_accessSelection`}
                  focusRef={this.accessInput}
                  items={accessTranslated}
                  activeIndex={accessIndex}
                  onChange={activeIndex => {
                    this.handleOnAccessChanged(activeIndex)
                  }}
                  title={translate('security.access', lang)}
                />
              </Column>
              <Column colMD={8}>
                <Input
                  id={`${id}_profileDesc`}
                  onInputChanged={(val) => { this.handleOnProfileDescChanged(val) }}
                  value={profileDesc.value}
                  title={translate('general.description', lang)}
                  maxLength={64}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_modifySecurityProfileModal`}>
          <Button
            id={`${id}_cancelBtn`}
            text={translate('general.cancel', lang)}
            onClick={this.handleOnClose}
          />
          <Button
            id={`${id}_saveBtn`}
            text={translate('general.save', lang)}
            onClick={this.handleOnSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    dateMask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    securityProfileToModify: state.security.securityProfile,
    userToken: state.auth.serverdata.token,
    userId: state.auth.userid
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSecurityProfile: (
      cuser,
      profile,
      betauser,
      profileDesc,
      access,
      callback
    ) => {
      SecurityProfileActions.updateSecurityProfile(
        cuser,
        profile,
        betauser,
        profileDesc,
        access,
        callback
      )(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifySecurityProfileDialog)