
import * as RecipientHierarchyActions from '../actions/RecipientHierarchyActions.js'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  hierarchyData: undefined,
  hierarchyEntriesData: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    case RecipientHierarchyActions.RECIPIENTS_GET_HIERARCHY_DATA_START:
      return { ...state }
    case RecipientHierarchyActions.RECIPIENTS_GET_HIERARCHY_DATA_SUCCESS:
      return { ...state, hierarchyData: action.payload.data }
    case RecipientHierarchyActions.RECIPIENTS_GET_HIERARCHY_DATA_FAILED:
      return { ...state }
    case RecipientHierarchyActions.NO_RECIPIENTS_HIERARCHY_FOUND:
      return { ...state, hierarchyData: {} }
    /* ********************************************************************************** */
    // get hierarchy recipients actions
    case RecipientHierarchyActions.RECIPIENT_GET_HIERARCHY_ENTRY_DATA_START:
      return { ...state }
    case RecipientHierarchyActions.RECIPIENT_GET_HIERARCHY_ENTRY_DATA_SUCCESS:
      return { ...state, hierarchyEntriesData: action.payload.newHierarchyEntriesData }
    case RecipientHierarchyActions.RECIPIENT_GET_HIERARCHY_ENTRY_DATA_FAILED:
      return { ...state }
    case RecipientHierarchyActions.NO_RECIPIENT_HIERARCHY_ENTRY_FOUND:
      return { ...state, hierarchyEntriesData: action.payload.newHierarchyEntriesData }
    /* ********************************************************************************** */
    // get single recipient to modify
    case RecipientHierarchyActions.RECIPIENT_HIERARCHY_GET_SINGLE_DATA_START:
      return { ...state, hierarchyDataToModify: undefined }
    case RecipientHierarchyActions.RECIPIENT_HIERARCHY_GET_SINGLE_DATA_SUCCESS:
      return { ...state, hierarchyDataToModify: action.payload }
    case RecipientHierarchyActions.RECIPIENT_HIERARCHY_GET_SINGLE_DATA_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // update recipients hierarchy entries actions
    case RecipientHierarchyActions.RECIPIENTS_HIERARCHY_ENTRIES_UPDATED:
      return { ...state, hierarchyEntriesData: action.payload }
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, hierarchyData: undefined, hierarchyEntriesData: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer