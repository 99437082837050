import * as PostProcessingNoteDefinitionActions from '../actions/PostProcessingNoteDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  ppns: undefined,
  ppn: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get post processing note actions
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_GET_PPN_START:
      return { ...state, ppn: undefined }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_GET_PPN_SUCCESS:
      return { ...state, ppn: action.payload }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_GET_PPN_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition create post processing note actions
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_CREATE_PPN_START:
      return { ...state }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_CREATE_PPN_SUCCESS:
      return { ...state }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_CREATE_PPN_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition get post processing notes actions
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_GET_PPNS_START:
      return { ...state }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_GET_PPNS_SUCCESS:
      return { ...state, ppns: action.payload.data, keepPagination: action.keepPagination }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_GET_PPNS_FAILED:
      return { ...state }
    case PostProcessingNoteDefinitionActions.NO_PPN_DEFINITION_PPNS_FOUND:
      return { ...state, ppns: {} }
    /* ********************************************************************************** */
    // Definition update post processing note actions
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_UPDATE_PPN_START:
      return { ...state }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_UPDATE_PPN_SUCCESS:
      return { ...state }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_UPDATE_PPN_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete post processing note actions
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_DELETE_PPN_START:
      return { ...state }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_DELETE_PPN_SUCCESS:
      return { ...state }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_DELETE_PPN_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition verify output channel
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_VERIFY_PPN_START:
      return { ...state }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_VERIFY_PPN_SUCCESS:
      return { ...state, verify: action.payload }
    case PostProcessingNoteDefinitionActions.PPN_DEFINITION_VERIFY_PPN_FAILED:
      return { ...state }
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, ppns: undefined, ppn: undefined, }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer