import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

// Components
import {
  Button, Card, Column, DataTable, Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Utils
import { translate } from 'language/Language'
import * as Preferences from 'redux/general/Preferences'

import './SelectorDialog.scss'

class SelectorDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    header: PropTypes.arrayOf(PropTypes.string).isRequired,
    multiSelect: PropTypes.bool,
    items: PropTypes.array
  }

  state = {
    selectedRows: []
  }

  /**
   * @description Handles the selection change event.
   * @param index The row on which the user clicked
   */
  handleOnSelectionChange = index => {
    if (!this.state.selectedRows.includes(index)) {
      // append index to selectedRows on multiselect
      if (this.props.multiSelect) {
        this.setState(currState => ({
          selectedRows: currState.selectedRows.concat([index])
        }))
      } else {
        // clean selectedRows on single select and append index
        this.setState(({
          selectedRows: [index]
        }))
      }
    } else {
      this.setState(currState => ({
        selectedRows: currState.selectedRows.filter(item => item !== index)
      }))
    }
  }

  getSortDefs = () => {
    let sortDefs = []
    for (let i = 0; i < this.props.header.length; i++) {
      sortDefs.push('string')
    }
    return sortDefs
  }

  render = () => {
    const { id, onSelect, title, header, items, onClose, datemask, lang } = this.props
    const { selectedRows } = this.state

    return (
      <Modal
        id={`${id}`}
        onClose={onClose}>
        <Header
          id={`${id}`}
          title={title}
          onClose={() => onClose()}
        />
        <Main id={'child'}>
          <Card>
            <Row className={'bux_full_height'}>
              <Column colMD={12} className={'bux_full_height'}>
                <DataTable
                  id={`${id}_1`}
                  className={'bux_add_pointer'}
                  header={header}
                  data={items}
                  columnSortDefs={this.getSortDefs()}
                  createTableRowAction={index => this.handleOnSelectionChange(index)}
                  language={lang}
                  datemask={datemask}
                  translate={key => translate(key)}
                  menu={false}
                  selectedRows={selectedRows}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_footer`}>
          <Button
            id={`${id}_cancelBtn`}
            text={translate('general.cancel')}
            onClick={() => onClose()}
          />
          <Button
            id={`${id}_selectBtn`}
            text={translate('general.select')}
            onClick={() => onSelect(selectedRows)}
            disabled={!selectedRows.length }
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
  }
}

export default connect(mapStateToProps)(SelectorDialog)