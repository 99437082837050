import { translate } from 'language/Language'
import { Component } from 'react'

import {
  Button,
  MetaDataGrid, Modal as ModalComponent
} from 'BetaUX2Web-Components/src/'
import PropTypes from 'prop-types'

const { Modal, Main, Header, Footer } = ModalComponent

import { connect } from 'react-redux'
import * as IndexQueueActions from 'redux/actions/IndexQueueActions'
import * as Preferences from 'redux/general/Preferences'
import { getDate } from 'utils/DateUtils'

import './RerunIndexQueueDialog.scss'

class RerunIndexQueueDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  handleExecute = () => {
    const { indexDetails, rerunIndexQueue, onClose } = this.props
    const rerunObj = {
      TTOKEN: indexDetails.TTOKEN,
      CIQDATE: indexDetails.CIQDATE,
      CIQTIME: indexDetails.CIQTIME,
      CIQSTAT: indexDetails.CIQSTAT,
      CIQUSER: indexDetails.CIQUSER,
      FORM: indexDetails.FORM,
      EXT: indexDetails.EXT,
      WREPORT: indexDetails.WREPORT,
      B93DATE: indexDetails.B93DATE,
      B93TIME: indexDetails.B93TIME,
      LTITLE: indexDetails.LTITLE,
      SRCJOBN: indexDetails.SRCJOBN,
      SRCJOBI: indexDetails.SRCJOBI,
      SRCPAGES: indexDetails.SRCPAGES,
      CIQRC: indexDetails.CIQRC,
      CIQINFO: indexDetails.CIQINFO,
      MSG: indexDetails.MSG,
      FILTER: indexDetails.FILTER,
      CIQPRIO: indexDetails.CIQPRIO,
      CIQTODO: indexDetails.CIQTODO,
      DOCID: indexDetails.DOCID
    }
    rerunIndexQueue(rerunObj, () => onClose())
  }

  renderHeader = () => {
    const { id, datemask, indexDetails } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('queue.requestqueued'), value: getDate(datemask, indexDetails.CIQDATE, indexDetails.CIQTIME.substring(0, 8)) },
          { label: translate('queue.requestqueued'), value: indexDetails.CIQUSER },
          { label: translate('general.form'), value: indexDetails.FORM },
          { label: translate('general.extension'), value: indexDetails.EXT },
          { label: translate('general.report'), value: indexDetails.WREPORT },
          { label: translate('definition.document_filter'), value: indexDetails.FILTER },
        ]}
        columns={4}
      />
    )
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={id}
        className='noMain'
        onClose={onClose}>
        <Header
          id={id}
          title={translate('queue.rerun_index_queue_request')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main />
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_executebtn`}
            text={translate('database.execute')}
            onClick={() => this.handleExecute()}
            primary
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    userid: state.auth.userid,
    indexDetails: state.queue.index.indexDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    rerunIndexQueue: (rerunObj, callback) => {
      IndexQueueActions.rerunIndexQueue(rerunObj, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RerunIndexQueueDialog)