import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  Button, Card,
  Checkbox, Column, Dropdown, Input, NumericSpinner, Row, Switch
} from 'BetaUX2Web-Components/src/'
import TimeCard from '../../../../../time_card/TimeCard'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import { getOutputChannelTypes } from 'utils/DefinitionUtils'
import {
  DISPLAY_ALL,
  DISPLAY_ITEMS, PRINT_BUNDLE_ANY,
  PRINT_BUNDLE_ITEMS, REQUEUED_ITEMS
} from 'utils/QueueUtils'
import * as Utils from 'utils/Utils'

import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import { getOutputQueues } from 'redux/actions/OutputQueueActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'


class QueueOutput extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    time: {
      activeTimeTabIndex: 0,
      type: 0,
      period: 1,
      unit: 2,
      startDate: {
        value: '',
        error: ''
      },
      startTime: {
        value: '',
        error: ''
      },
      endDate: {
        value: '',
        error: ''
      },
      endTime: {
        value: '',
        error: ''
      },
    },
    outputChannelID: {
      value: '',
      error: ''
    },
    showOutputChannelSelectorDialog: false,
    typeIndex: 0,
    priorityIndex: 0,
    specificPriority: 0,
    requeuedIndex: 0,
    printBundleIndex: 0,
    bundleName: '',
    requestor: '',
    recipientID: '',
    showRecipientSelectorDialog: false,
    form: {
      value: '',
      error: ''
    },
    extension: {
      value: '',
      error: ''
    },
    report: {
      value: '',
      error: ''
    },
    showDocumentSelectorDialog: false,
    jobname: '',
    address: '',
    displayIndex: 0,
    statusPrinted: false,
    statusError: false,
    statusActive: false,
    statusWaiting: false,
    statusHold: false,
    statusDelete: false,
    statusRetry: false,
    statusCancelled: false,
    statusRequeued: false
  }

  state = {
    ...this.defaultState
  }

  fromDateRef = React.createRef()
  fromTimeRef = React.createRef()
  toDateRef = React.createRef()
  toTimeRef = React.createRef()
  outputChannelIDRef = React.createRef()

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount = () => {
    const { preferences, datemask, userprofile } = this.props
    let activeTimeTabIndex = Utils.convertStringToInt(preferences[Preferences.QUEUE_OUTPUT_ACTIVE_TAB]) || 0
    let startDate = activeTimeTabIndex === 1 && preferences[Preferences.QUEUE_OUTPUT_START_DATE]
      ? DateUtils.getDate(datemask, preferences[Preferences.QUEUE_OUTPUT_START_DATE])
      : ''
    let startTime = preferences[Preferences.QUEUE_OUTPUT_START_TIME] || ''
    startTime = Utils.convertStringToInt(startTime)

    let endDate = preferences[Preferences.QUEUE_OUTPUT_END_DATE]
      ? DateUtils.getDate(datemask, preferences[Preferences.QUEUE_OUTPUT_END_DATE])
      : ''

    let endTime = preferences[Preferences.QUEUE_OUTPUT_END_TIME] || ''
    endTime = Utils.convertStringToInt(endTime)

    const getUnitIndex = () => {
      const prefsKey = preferences[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_UNIT]
      let result = 2
      for (let i = 0; i < DateUtils.UNITS.length; i++) {
        if (DateUtils.UNITS[i].key === prefsKey) {
          result = i
        }
      }
      return result
    }

    const type = preferences[Preferences.QUEUE_OUTPUT_TYPE] || ''
    const requeued = preferences[Preferences.QUEUE_OUTPUT_REQUEUED] || ''
    const printBundle = preferences[Preferences.QUEUE_OUTPUT_PRINT_BUNDLE] || PRINT_BUNDLE_ANY
    const display = preferences[Preferences.QUEUE_OUTPUT_DISPLAY] || DISPLAY_ALL
    const statusPrinted = preferences[Preferences.QUEUE_OUTPUT_STATUS_PRINTED] || false
    const statusError = preferences[Preferences.QUEUE_OUTPUT_STATUS_ERROR] || false
    const statusActive = preferences[Preferences.QUEUE_OUTPUT_STATUS_ACTIVE] || false
    const statusWaiting = preferences[Preferences.QUEUE_OUTPUT_STATUS_WAITING] || false
    const statusHold = preferences[Preferences.QUEUE_OUTPUT_STATUS_HOLD] || false
    const statusDelete = preferences[Preferences.QUEUE_OUTPUT_STATUS_DELETE] || false
    const statusRetry = preferences[Preferences.QUEUE_OUTPUT_STATUS_RETRY] || false
    const statusCancelled = preferences[Preferences.QUEUE_OUTPUT_STATUS_CANCELLED] || false
    const statusRequeued = preferences[Preferences.QUEUE_OUTPUT_STATUS_REQUEUED] || false

    const outputchannelID = userprofile
      ? userprofile.VCIPQ01 !== ''
        ? userprofile.VCIPQ01
        : preferences[Preferences.QUEUE_OUTPUT_OUTPUTCHANNEL_ID] || ''
      : preferences[Preferences.QUEUE_OUTPUT_OUTPUTCHANNEL_ID] || ''

    // set preference values or fallback default values
    this.setState({
      time: {
        activeTimeTabIndex,
        type: Utils.convertStringToInt(preferences[Preferences.QUEUE_OUTPUT_LASTTIME_MODE]) || 0,
        period: Utils.convertStringToInt(preferences[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_LAST]) || 1,
        unit: getUnitIndex(),
        startDate: {
          value: startDate,
          error: ''
        },
        startTime: {
          value: startTime,
          error: ''
        },
        endDate: {
          value: endDate,
          error: ''
        },
        endTime: {
          value: endTime,
          error: ''
        },
      },
      outputChannelID: { value: outputchannelID, error: '' },
      typeIndex: Math.max(getOutputChannelTypes(true).findIndex(d => d.key === type), 0),
      priorityIndex: preferences[Preferences.QUEUE_OUTPUT_PRIORITY] === 'Specific' ? 1 : 0,
      specificPriority: preferences[Preferences.QUEUE_OUTPUT_SPECIFIC_PRIORITY] || 0,
      requeuedIndex: Math.max(REQUEUED_ITEMS.findIndex(d => d.key === requeued), 0),
      printBundleIndex: Math.max(PRINT_BUNDLE_ITEMS.findIndex(d => d.key === printBundle), 0),
      bundleName: preferences[Preferences.QUEUE_OUTPUT_BUNDLE_NAME] || '',
      requestor: preferences[Preferences.QUEUE_OUTPUT_REQUESTOR] || '',
      recipientID: preferences[Preferences.QUEUE_OUTPUT_RECIPIENT_ID] || '',
      form: {
        value: preferences[Preferences.QUEUE_OUTPUT_FORM] || '',
        error: ''
      },
      extension: {
        value: preferences[Preferences.QUEUE_OUTPUT_EXTENSION] || '',
        error: ''
      },
      report: {
        value: preferences[Preferences.QUEUE_OUTPUT_REPORT] || '',
        error: ''
      },
      jobname: preferences[Preferences.QUEUE_OUTPUT_JOBNAME] || '',
      address: preferences[Preferences.QUEUE_OUTPUT_ADDRESS] || '',
      displayIndex: Math.max(DISPLAY_ITEMS.findIndex(d => d.key === display), 0),
      statusPrinted: statusPrinted === 'true',
      statusError: statusError === 'true',
      statusActive: statusActive === 'true',
      statusWaiting: statusWaiting === 'true',
      statusHold: statusHold === 'true',
      statusDelete: statusDelete === 'true',
      statusRetry: statusRetry === 'true',
      statusCancelled: statusCancelled === 'true',
      statusRequeued: statusRequeued === 'true'
    })
  }

  handleTimeCardChange = (key, val, err) => {
    this.setState(state => ({
      time: {
        ...state.time,
        [key]: typeof state.time[key] === 'object'
          ? {
            value: val,
            error: err || ''
          }
          : val
      }
    }))
  }

  timeCardValues = () => {
    const { time } = this.state
    return {
      tabIndex: time.activeTimeTabIndex,
      lastTimeModeIndex: time.type,
      customLast: time.period,
      customUnitIndex: time.unit,
      fromDate: {
        value: time.startDate.value,
        error: time.startDate.error
      },
      fromTime: {
        value: time.startTime.value,
        error: time.startTime.error
      },
      toDate: {
        value: time.endDate.value,
        error: time.endDate.error
      },
      toTime: {
        value: time.endTime.value,
        error: time.endTime.error
      }
    }
  }

  /**
   * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
   *              Needed for update function inside the TimeCard.
   * @returns {Object}
   */
  timeCardStateKeys = () => {
    return {
      tabIndex: 'activeTimeTabIndex',
      lastTimeModeIndex: 'type',
      customLast: 'period',
      customUnitIndex: 'unit',
      fromDate: 'startDate',
      fromTime: 'startTime',
      toDate: 'endDate',
      toTime: 'endTime'
    }
  }

  timeCardFocusRefs = () => {
    return {
      fromDate: this.fromDateRef,
      toDate: this.toDateRef,
      fromTime: this.fromTimeRef,
      toTime: this.toTimeRef
    }
  }

  /**
   * @description Renders the time card.
   */
  renderTimeCard = () => {
    const { id, language, datemask } = this.props

    return (
      <TimeCard
        id={id}
        lang={language}
        datemask={datemask}
        values={this.timeCardValues()}
        stateKeys={this.timeCardStateKeys()}
        focusRefs={this.timeCardFocusRefs()}
        onValuesChange={this.handleTimeCardChange}
        parentContainer={'drawer_content_queue_body_output_queue_main'}
        translate={key => translate(key)}
      />
    )
  }

  /**
   * @description Sets the changes to the state.
   * @param {String} key The key in state.
   * @param {String} value The new value.
   * @param {String} error The new error key.
   */
  handleChange = (key, value, error) => {
    this.setState({
      [key]: typeof (this.state[key]) === 'object'
        ? { value, error }
        : value
    })
  }

  /**
   * @description Concats the status values
   * @returns {String} concatinated value
   */
  getStatusValues = () => {
    const {
      statusPrinted,
      statusError,
      statusActive,
      statusWaiting,
      statusHold,
      statusDelete,
      statusRetry,
      statusCancelled,
      statusRequeued
    } = this.state

    let value = ''
    if (statusPrinted) { value += 'P' }
    if (statusError) { value += 'E' }
    if (statusActive) { value += 'A' }
    if (statusWaiting) { value += 'W' }
    if (statusHold) { value += 'H' }
    if (statusDelete) { value += 'D' }
    if (statusRetry) { value += 'R' }
    if (statusCancelled) { value += 'C' }
    if (statusRequeued) { value += 'Q' }
    return value
  }

  handleFocus = () => {
    const { time, outputChannelID } = this.state
    const focusArr = [
      { inputRef: this.fromDateRef, error: time.startDate.error },
      { inputRef: this.fromTimeRef, error: time.startTime.error },
      { inputRef: this.toDateRef, error: time.endDate.error },
      { inputRef: this.toTimeRef, error: time.endTime.error },
      { inputRef: this.outputChannelIDRef, error: outputChannelID.error }
    ]
    Utils.setFocus(focusArr)
  }

  /**
   * @description Validates all elements from the timecard component. Checks if date inputs are valid dates and if given, the end date is not before the start date.
   */
  validateErrors = () => {
    const { time } = this.state
    const { datemask, userprofile } = this.props
    let errorObj = { ...this.state }
    let errorsFound = 0
    if (time.activeTimeTabIndex === 1) {
      if (time.startDate.value !== '' && !DateUtils.isDate(time.startDate.value, datemask) && !DateUtils.validDateStrings.includes(time.startDate.value.toUpperCase())) {
        errorObj.time.startDate = {
          value: time.startDate.value,
          error: 'general.invalid_date_format'
        }
        errorsFound++
      }
      if (time.endDate.value !== '' && !DateUtils.isDate(time.endDate.value, datemask) && !DateUtils.validDateStrings.includes(time.endDate.value.toUpperCase())) {
        errorObj.time.endDate = {
          value: time.endDate.value,
          error: 'general.invalid_date_format'
        }
        errorsFound++
      }
      if (time.startTime.value !== '' && !time.startTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errorObj.time.startTime = {
          value: time.startTime.value,
          error: 'general.invalid_time_format'
        }
        errorsFound++
      }
      if (time.endTime.value !== '' && !time.endTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errorObj.time.endTime = {
          value: time.endTime.value,
          error: 'general.invalid_time_format'
        }
        errorsFound++
      }
    }
    // Set error if the to date is before the from date and both inputs are filled with a date.
    if (!errorObj.time.endDate) {
      if (time.startDate.value !== '' && time.endDate.value !== '') {
        if (DateUtils.isDate(time.startDate.value, datemask) && DateUtils.isDate(time.endDate.value, datemask)) {
          const startDate = moment(time.startDate.value, datemask)
          const endDate = moment(time.endDate.value, datemask)
          if (endDate.isBefore(startDate)) {
            errorObj.time.endDate = {
              value: time.endDate.value,
              error: 'statistic.negative_date_difference_error'
            }
            errorsFound++
          }
        }
      }
    }

    if (userprofile && userprofile.VCIPQ01R && this.state.outputChannelID.value === '') {
      errorObj.outputChannelID = {
        value: '',
        error: 'general.input_required'
      }
      errorsFound++
    }

    this.setState({ ...errorObj }, () => this.handleFocus())
    return errorsFound
  }

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param {Object} event The event
   */
  handleSearch = e => {
    e.preventDefault()

    const { changePrefs, getOutputQueues, datemask } = this.props
    const {
      time,
      outputChannelID,
      typeIndex,
      priorityIndex,
      specificPriority,
      requeuedIndex,
      printBundleIndex,
      bundleName,
      requestor,
      recipientID,
      form,
      extension,
      report,
      jobname,
      address,
      displayIndex,
      statusPrinted,
      statusError,
      statusActive,
      statusWaiting,
      statusHold,
      statusDelete,
      statusRetry,
      statusCancelled,
      statusRequeued
    } = this.state

    const errors = this.validateErrors()
    if (errors === 0) {
      const type = getOutputChannelTypes(true)[typeIndex].key
      const requeued = REQUEUED_ITEMS[requeuedIndex].key
      const printBundle = PRINT_BUNDLE_ITEMS[printBundleIndex].key
      const specificPrio = priorityIndex === 1 ? specificPriority : ''
      const display = DISPLAY_ITEMS[displayIndex].key

      const prefsToChange = {
        [Preferences.QUEUE_OUTPUT_ACTIVE_TAB]: time.activeTimeTabIndex,
        [Preferences.QUEUE_OUTPUT_LASTTIME_MODE]: time.type,
        [Preferences.QUEUE_OUTPUT_TIME_CUSTOM_LAST]: time.period,
        [Preferences.QUEUE_OUTPUT_TIME_CUSTOM_UNIT]: DateUtils.UNITS[time.unit].key,
        [Preferences.QUEUE_OUTPUT_START_DATE]: DateUtils.getRequestFormat(time.startDate.value, datemask),
        [Preferences.QUEUE_OUTPUT_START_TIME]: DateUtils.formatTimeToDefault(time.startTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.QUEUE_OUTPUT_END_DATE]: DateUtils.getRequestFormat(time.endDate.value, datemask),
        [Preferences.QUEUE_OUTPUT_END_TIME]: DateUtils.formatTimeToDefault(time.endTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.QUEUE_OUTPUT_OUTPUTCHANNEL_ID]: outputChannelID.value,
        [Preferences.QUEUE_OUTPUT_TYPE]: type,
        [Preferences.QUEUE_OUTPUT_PRIORITY]: priorityIndex === 0 ? 'Any' : 'Specific',
        [Preferences.QUEUE_OUTPUT_SPECIFIC_PRIORITY]: specificPrio,
        [Preferences.QUEUE_OUTPUT_REQUEUED]: requeued,
        [Preferences.QUEUE_OUTPUT_PRINT_BUNDLE]: printBundle,
        [Preferences.QUEUE_OUTPUT_BUNDLE_NAME]: bundleName,
        [Preferences.QUEUE_OUTPUT_REQUESTOR]: requestor,
        [Preferences.QUEUE_OUTPUT_RECIPIENT_ID]: recipientID,
        [Preferences.QUEUE_OUTPUT_FORM]: form.value,
        [Preferences.QUEUE_OUTPUT_EXTENSION]: extension.value,
        [Preferences.QUEUE_OUTPUT_REPORT]: report.value,
        [Preferences.QUEUE_OUTPUT_JOBNAME]: jobname,
        [Preferences.QUEUE_OUTPUT_ADDRESS]: address,
        [Preferences.QUEUE_OUTPUT_DISPLAY]: display,
        [Preferences.QUEUE_OUTPUT_STATUS_PRINTED]: statusPrinted,
        [Preferences.QUEUE_OUTPUT_STATUS_ERROR]: statusError,
        [Preferences.QUEUE_OUTPUT_STATUS_ACTIVE]: statusActive,
        [Preferences.QUEUE_OUTPUT_STATUS_WAITING]: statusWaiting,
        [Preferences.QUEUE_OUTPUT_STATUS_HOLD]: statusHold,
        [Preferences.QUEUE_OUTPUT_STATUS_DELETE]: statusDelete,
        [Preferences.QUEUE_OUTPUT_STATUS_RETRY]: statusRetry,
        [Preferences.QUEUE_OUTPUT_STATUS_CANCELLED]: statusCancelled,
        [Preferences.QUEUE_OUTPUT_STATUS_REQUEUED]: statusRequeued,
        [Preferences.QUEUE_OUTPUT_STATUS]: this.getStatusValues()
      }

      const searchParams = {
        DCR: outputChannelID.value,
        DCRTYPE: type,
        BTYPE: printBundle,
        BNAME: bundleName,
        BREQUEST: requestor,
        PRTSTAT: this.getStatusValues(),
        FORM: form.value,
        EXTENSION: extension.value,
        REPORT: report.value,
        JOBNAME: jobname,
        DCRIPADR: address,
        RECI: recipientID,
        PROCESS: display,
        PRTPRIO: specificPrio,
        PRTRQFLG: requeued
      }

      if (time.activeTimeTabIndex === 0) {
        prefsToChange[Preferences.QUEUE_OUTPUT_START_DATE] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_START_TIME] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_END_DATE] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_END_TIME] = ''
        if (time.type === 0) {
          searchParams['SDATE'] = 'TODAY'
          prefsToChange[Preferences.QUEUE_OUTPUT_START_DATE] = 'TODAY'
        }
        else if (time.type === 1) {
          searchParams['SDATE'] = 'YESTERDAY'
          prefsToChange[Preferences.QUEUE_OUTPUT_START_DATE] = 'YESTERDAY'
        }
        else if (time.type === 2) {
          searchParams['FROMLAST'] = time.period
          searchParams['TUNITS'] = DateUtils.UNITS[time.unit].key
        }
      }
      else if (time.activeTimeTabIndex === 1) {
        searchParams['SDATE'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.startTime.value !== '') {
          searchParams['STIME'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.TIME_DATEMASK)
        }
        searchParams['EDATE'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.endTime.value !== '') {
          searchParams['ETIME'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.TIME_DATEMASK)
        }

        prefsToChange[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_LAST] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_UNIT] = ''
      }

      changePrefs(prefsToChange)
      getOutputQueues(searchParams)
    }
  }
  /**
   * @description Requests the outputchannel definition with the current selection. On successful request it opens the seletor dialog.
   */
  openOutputChannelSelector = () => {
    const { getOutputChannelDefinitions } = this.props
    const { outputChannelID } = this.state

    getOutputChannelDefinitions(
      ['DCR', 'DCRTITLE'],
      outputChannelID.value,
      () => this.setState({ showOutputChannelSelectorDialog: true }))
  }

  /**
   * @description Requests the recipient definition with the current selection. On successful request it opens the seletor dialog.
   */
  openRecipientSelector = () => {
    const { getRecipientDefinitions } = this.props
    const { recipientID } = this.state

    getRecipientDefinitions(
      ['RECI', 'TITLE'],
      recipientID,
      () => this.setState({ showRecipientSelectorDialog: true }))
  }

  /**
   * @description Requests the document definition with the current selection. On successful request it opens the selector dialog.
   */
  openDocumentDefinitionsSelector = () => {
    const { getDocumentDefinitions } = this.props
    const { form, extension, report } = this.state
    getDocumentDefinitions(
      ['FORM', 'EXT', 'REPORT'],
      form.value,
      extension.value,
      report.value,
      () => this.setState({ showDocumentSelectorDialog: true })
    )
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    const { showOutputChannelSelectorDialog, showRecipientSelectorDialog, showDocumentSelectorDialog } = this.state
    return (
      <>
        {showOutputChannelSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputchanneldefinition_selector_dialog`}
            onClose={() => this.setState({ showOutputChannelSelectorDialog: false })}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('definition.output_channel_id'),
              translate('general.title')
            ]}
            items={selector.outputchannels.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newOutputchannel = selector.outputchannels.data[selectedRows][selector.outputchannels.header.indexOf('DCR')]
                this.setState({
                  outputChannelID: { value: newOutputchannel, error: '' },
                  showOutputChannelSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputChannelSelectorDialog: false
                })
              }
            }}
          />
        )}
        {showRecipientSelectorDialog && (
          <SelectorDialog
            id={`${id}_recipientdefinition_selector_dialog`}
            onClose={() => this.setState({ showRecipientSelectorDialog: false })}
            title={translate('definition.recipientdefinitions')}
            header={[
              translate('recipient.reci_id'),
              translate('general.title'),
            ]}
            items={selector.recipients.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newRecipient = selector.recipients.data[selectedRows][selector.recipients.header.indexOf('RECI')]
                this.setState({
                  recipientID: newRecipient,
                  showRecipientSelectorDialog: false
                })
              } else {
                this.setState({
                  showRecipientSelectorDialog: false
                })
              }
            }}
          />
        )}
        {showDocumentSelectorDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report')
            ]}
            items={selector.documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const form = selector.documents.data[selectedRows][selector.documents.header.indexOf('FORM')]
                const extension = selector.documents.data[selectedRows][selector.documents.header.indexOf('EXT')]
                const report = selector.documents.data[selectedRows][selector.documents.header.indexOf('REPORT')]
                this.setState({
                  form: { value: form, error: '' },
                  extension: { value: extension, error: '' },
                  report: { value: report, error: '' },
                  showDocumentSelectorDialog: false
                })
              }
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description gets translated output channel types for dropdown
   * @returns {Array}
   */
  getTranslatedTypes = () => getOutputChannelTypes(true).map(d => translate(d.translationKey))

  /**
   * @description gets translated requeued items for dropdown
   * @returns {Array}
   */
  getTranslatedRequeued = () => REQUEUED_ITEMS.map(d => translate(d.translationKey))

  /**
   * @description gets translated print/bundle items for dropdown
   * @returns {Array}
   */
  getTranslatedPrintBundle = () => PRINT_BUNDLE_ITEMS.map(d => translate(d.translationKey))

  /**
 * @description gets translated display items for switchable buttons
 * @returns {Array}
 */
  getTranslatedDisplay = () => DISPLAY_ITEMS.map(d => translate(d.translationKey))

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id, userprofile } = this.props
    const {
      outputChannelID,
      typeIndex,
      priorityIndex,
      specificPriority,
      requeuedIndex,
      printBundleIndex,
      bundleName,
      requestor,
      recipientID,
      form,
      extension,
      report,
      jobname,
      address
    } = this.state

    return (
      <Card title={translate('general.general')}>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_outputchannelid`}
              title={translate('definition.output_channel_id')}
              value={outputChannelID.value}
              error={outputChannelID.error && translate(outputChannelID.error)}
              ref={this.outputChannelIDRef}
              onInputChanged={(value) => this.handleChange('outputChannelID', value)}
              maxLength={16}
              disabled={userprofile && userprofile.VCIPQ01D}
              required={userprofile && userprofile.VCIPQ01R ? `${translate('general.required_field')}` : false}
              addon={{
                iconName: 'list',
                onClick: () => this.openOutputChannelSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_type`}
              title={translate('general.type')}
              items={this.getTranslatedTypes()}
              activeIndex={typeIndex}
              onChange={index => this.handleChange('typeIndex', index)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_priority`}
              title={translate('queue.priority')}
              items={[
                translate('general.any'),
                translate('general.specific')
              ]}
              maxPerRow={2}
              activeIndex={priorityIndex}
              onClick={index => this.handleChange('priorityIndex', index)}
            />
          </Column>
          <Column colMD={6}>
            {priorityIndex === 1 &&
              <NumericSpinner
                id={`${id}_specific_priority`}
                title={translate('queue.specific_priority')}
                value={specificPriority}
                onChange={val => this.handleChange('specificPriority', val)}
                min={0}
                max={15}
                steps={1}
              />}
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_requeued`}
              title={translate('queue.requeued')}
              items={this.getTranslatedRequeued()}
              activeIndex={requeuedIndex}
              onChange={index => this.handleChange('requeuedIndex', index)}
            />
          </Column>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_printbundle`}
              title={translate('queue.print_bundle')}
              items={this.getTranslatedPrintBundle()}
              activeIndex={printBundleIndex}
              onChange={index => this.handleChange('printBundleIndex', index)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_bundlename`}
              title={translate('queue.bundle_name')}
              value={bundleName}
              onInputChanged={value => this.handleChange('bundleName', value)}
              maxLength={16}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_requestor`}
              title={translate('general.requestor')}
              value={requestor}
              onInputChanged={value => this.handleChange('requestor', value)}
              maxLength={8}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_recipientid`}
              title={translate('recipient.reci_id')}
              value={recipientID}
              onInputChanged={value => this.handleChange('recipientID', value)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.openRecipientSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_form`}
              onInputChanged={(value, error) => this.handleChange('form', value, error)}
              value={form.value}
              title={translate('general.form')}
              error={form.error && translate(form.error)}
              maxLength={8}
              addon={{
                iconName: 'list',
                onClick: () => this.openDocumentDefinitionsSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_extension`}
              onInputChanged={(value, error) => this.handleChange('extension', value, error)}
              value={extension.value}
              title={translate('general.extension')}
              error={extension.error && translate(extension.error)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.openDocumentDefinitionsSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_report`}
              onInputChanged={(value, error) => this.handleChange('report', value, error)}
              value={report.value}
              title={translate('general.report')}
              error={report.error && translate(report.error)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.openDocumentDefinitionsSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_jobname`}
              title={translate('general.jobname')}
              value={jobname}
              onInputChanged={value => this.handleChange('jobname', value)}
              maxLength={8}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_address`}
              title={translate('definition.address')}
              value={address}
              onInputChanged={value => this.handleChange('address', value)}
              maxLength={40}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the additional card.
   */
  renderAdditionalCard = () => {
    const { id } = this.props
    const { displayIndex } = this.state
    return (
      <Card title={translate('general.additional')}>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_display`}
              title={translate('general.display')}
              items={this.getTranslatedDisplay()}
              activeIndex={displayIndex}
              onClick={index => this.handleChange('displayIndex', index)}
              maxPerRow={2}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the status card.
   */
  renderStatusCard = () => {
    const { id } = this.props
    const {
      statusPrinted,
      statusError,
      statusActive,
      statusWaiting,
      statusHold,
      statusDelete,
      statusRetry,
      statusCancelled,
      statusRequeued
    } = this.state

    return (
      <Card title={translate('general.status')}>
        <Row>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_status_printed`}
              label={translate('general.printed')}
              onCheck={value => this.handleChange('statusPrinted', value)}
              value={statusPrinted}
            />
          </Column>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_status_error`}
              label={translate('general.error')}
              onCheck={value => this.handleChange('statusError', value)}
              value={statusError}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_status_active`}
              label={translate('general.active')}
              onCheck={value => this.handleChange('statusActive', value)}
              value={statusActive}
            />
          </Column>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_status_waiting`}
              label={translate('general.waiting')}
              onCheck={value => this.handleChange('statusWaiting', value)}
              value={statusWaiting}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_status_hold`}
              label={translate('search.hold')}
              onCheck={value => this.handleChange('statusHold', value)}
              value={statusHold}
            />
          </Column>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_status_deleted`}
              label={translate('general.deleted')}
              onCheck={value => this.handleChange('statusDelete', value)}
              value={statusDelete}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_status_retry`}
              label={translate('general.retry')}
              onCheck={value => this.handleChange('statusRetry', value)}
              value={statusRetry}
            />
          </Column>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_status_cancelled`}
              label={translate('general.canceled')}
              onCheck={value => this.handleChange('statusCancelled', value)}
              value={statusCancelled}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_status_requeued`}
              label={translate('queue.requeued')}
              onCheck={value => this.handleChange('statusRequeued', value)}
              value={statusRequeued}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderSelectorDialogs()}
        {this.renderTimeCard()}
        {this.renderGeneralCard()}
        {this.renderAdditionalCard()}
        {this.renderStatusCard()}
      </div>
    )
  }

  /**
   * @description Handles the reset button
   */
  handleReset = () => {
    const { userprofile } = this.props
    const outputChannelID = {
      value: userprofile &&
        userprofile.VCIPQ01D
        ? userprofile.VCIPQ01 !== ''
          ? userprofile.VCIPQ01
          : this.state.outputChannelID.value
        : '',
      error: ''
    }
    this.setState({
      ...this.defaultState,
      outputChannelID
    })
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={'drawer_content_queue_body_search'}
          text={translate('general.search')}
          onClick={this.handleSearch}
          submit
          primary />
        <Button
          id={'drawer_content_queue_body_resetBtn'}
          icon='undo'
          iconType='material'
          className='bux_iconResteBtn'
          onClick={this.handleReset}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSearch}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    language: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences.DATEMASK,
    selector: state.selector,
    userprofile: state.auth.serverdata.userprofile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch),
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    getRecipientDefinitions: (fields, recipient, callback) => {
      ModalSelectorActions.getRecipientDefinitions(
        fields,
        undefined, // rtype
        undefined, // distribution type
        recipient,
        undefined, // preRecipient
        undefined, // owner
        undefined, // title
        undefined, // outputchannel
        undefined, // outputformat
        undefined, // ppn
        callback)(dispatch)
    },
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getOutputQueues: (searchParams, callback) => {
      getOutputQueues(searchParams, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueOutput)