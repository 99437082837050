import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

// actions
export const RECIPIENT_DEFINITION_GET_RECIPIENTS_START = 'RECIPIENT_DEFINITION_GET_RECIPIENTS_START'
export const RECIPIENT_DEFINITION_GET_RECIPIENTS_SUCCESS = 'RECIPIENT_DEFINITION_GET_RECIPIENTS_SUCCESS'
export const RECIPIENT_DEFINITION_GET_RECIPIENTS_FAILED = 'RECIPIENT_DEFINITION_GET_RECIPIENTS_FAILED'

export const RECIPIENT_DEFINITION_GET_RECIPIENT_START = 'RECIPIENT_DEFINITION_GET_RECIPIENT_START'
export const RECIPIENT_DEFINITION_GET_RECIPIENT_SUCCESS = 'RECIPIENT_DEFINITION_GET_RECIPIENT_SUCCESS'
export const RECIPIENT_DEFINITION_GET_RECIPIENT_FAILED = 'RECIPIENT_DEFINITION_GET_RECIPIENT_FAILED'

export const RECIPIENT_DEFINITION_CREATE_RECIPIENT_START = 'RECIPIENT_DEFINITION_CREATE_RECIPIENT_START'
export const RECIPIENT_DEFINITION_CREATE_RECIPIENT_SUCCESS = 'RECIPIENT_DEFINITION_CREATE_RECIPIENT_SUCCESS'
export const RECIPIENT_DEFINITION_CREATE_RECIPIENT_FAILED = 'RECIPIENT_DEFINITION_CREATE_RECIPIENT_FAILED'

export const RECIPIENT_DEFINITION_DELETE_RECIPIENT_START = 'RECIPIENT_DEFINITION_DELETE_RECIPIENT_START'
export const RECIPIENT_DEFINITION_DELETE_RECIPIENT_SUCCESS = 'RECIPIENT_DEFINITION_DELETE_RECIPIENT_SUCCESS'
export const RECIPIENT_DEFINITION_DELETE_RECIPIENT_FAILED = 'RECIPIENT_DEFINITION_DELETE_RECIPIENT_FAILED'

export const RECIPIENT_DEFINITION_UPDATE_RECIPIENT_START = 'RECIPIENT_DEFINITION_UPDATE_RECIPIENT_START'
export const RECIPIENT_DEFINITION_UPDATE_RECIPIENT_SUCCESS = 'RECIPIENT_DEFINITION_UPDATE_RECIPIENT_SUCCESS'
export const RECIPIENT_DEFINITION_UPDATE_RECIPIENT_FAILED = 'RECIPIENT_DEFINITION_UPDATE_RECIPIENT_FAILED'

export const NO_RECIPIENT_DEFINITION_RECIPIENTS_FOUND = 'NO_RECIPIENT_DEFINITION_RECIPIENTS_FOUND'

/**
 * @description Calls the rest api and gets a recipient definition.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'OWNER', 'TITLE', 'BRWS', 'PCR', 'DCR', 'PPN', 'MAILADR', 'OVPCR', 'OVDCR', 'OVPPN',
 * 'OVBUN', 'PRTBAN', 'PRTBAN2', 'PRTBANB', 'PRTTOC', 'OVBAN', 'OVLBB', 'BHPRG', 'THPRG',
 * 'DJDE', 'DJDET', 'BANNER1', 'BANNER2', 'BANNER3', 'BANNER4', 'BANNER5', 'BANNER6',
 * 'BANNER7', 'BANNER8', 'ATITLE1', 'ATITLE2', 'ATITLE3', 'ATITLE4', 'ATITLE5', 'ATITLE6',
 * 'ATITLE7', 'ATITLE8', 'CDATE', 'CTIME', 'CUSER'
 * @param {String} recipient The recipient.
 * @param {String} preRecipient The pre recipient.
 * @param {String} rtype The recipient type. Possible values:
 * 'ADDR', 'RECI', 'ALIAS'
 * @param {Function} callback The callback which will be called when the get recipient request was successful. Can be null.
 */
export function getRecipient(fields, recipient, preRecipient, rtype, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: RECIPIENT_DEFINITION_GET_RECIPIENT_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (recipient) { queryParams.push(`RECI=${encodeURIComponent(recipient)}`) }
    queryParams.push(`PREDRECI=${encodeURIComponent(preRecipient)}`)
    if (rtype) { queryParams.push(`RTYPE=${encodeURIComponent(rtype)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipient?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: RECIPIENT_DEFINITION_GET_RECIPIENT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: RECIPIENT_DEFINITION_GET_RECIPIENT_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.get_recipient_error', lang, [recipient, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_DEFINITION_GET_RECIPIENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a recipient definition.
 * @param {Object} recipientDefinition The recipientdefinition. Possible fields:
 * 'RECI', 'PREDRECI', 'RTYPE', 'OWNER', 'TITLE', 'BRWS', 'PCR', 'DCR', 'PPN', 'MAILADR',
 * 'OVPCR', 'OVDCR', 'OVPPN', 'OVBUN', 'PRTBAN', 'PRTBAN2', 'PRTBANB', 'PRTTOC', 'OVBAN',
 * 'OVLBB', 'BHPRG', 'THPRG', 'DJDE', 'DJDET', 'BANNER1', 'BANNER2', 'BANNER3', 'BANNER4',
 * 'BANNER5', 'BANNER6', 'BANNER7', 'BANNER8', 'ATITLE1', 'ATITLE2', 'ATITLE3', 'ATITLE4',
 * 'ATITLE5', 'ATITLE6', 'ATITLE7', 'ATITLE8', 'CUSER'
 * @param {Function} callback The callback which will be called when the create recipient request was successful. Can be null.
 */
export function createRecipient(recipientDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const recipientType = Lang.translate(DefinitionUtils.getRecipientTypes().find(element => element.key === recipientDefinition['RTYPE']).translationKey)
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: RECIPIENT_DEFINITION_CREATE_RECIPIENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipient`, {
        method: 'post',
        body: ObjectUtils.removeByValue(recipientDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: RECIPIENT_DEFINITION_CREATE_RECIPIENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: RECIPIENT_DEFINITION_CREATE_RECIPIENT_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.create_recipient_success', lang, [recipientDefinition['RECI'], recipientType]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getRecipients again to get the current result
          const fields = undefined
          const rtype = prefs[Preferences.DEFINITION_RECI_TYPE]
          const distributionType = prefs[Preferences.DEFINITION_RECI_DISTRIBUTVIA]
          const recipient = prefs[Preferences.DEFINITION_RECI_ID]
          const preRecipient = prefs[Preferences.DEFINITION_RECI_PREDECESSORID]
          const owner = prefs[Preferences.DEFINITION_RECI_OWNER]
          const title = prefs[Preferences.DEFINITION_RECI_TITLE]
          const outputchannel = prefs[Preferences.DEFINITION_RECI_OUTPUTCHANNEL]
          const outputformat = prefs[Preferences.DEFINITION_RECI_OUTPUTFORMAT]
          const ppn = prefs[Preferences.DEFINITION_RECI_POSTPROCESSNOTE]
          getRecipients(fields, rtype, distributionType, recipient, preRecipient, owner, title, outputchannel, outputformat, ppn, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.create_recipient_error', lang, [recipientDefinition['RECI'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_DEFINITION_CREATE_RECIPIENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and deletes a recipient definition.
 * @param {Object} recipientDefinition The recipientdefinition. Possible fields:
 * 'RECI', 'PREDRECI', 'RTYPE'
 * @param {Function} callback The callback which will be called when the delete recipient definition request was successful. Can be null.
 */
export function deleteRecipient(recipientDefinition, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)

    const assignmentMessage = []
    assignmentMessage.push(`${Lang.translate('recipient.reci_id')}: ${recipientDefinition.RECI}`)
    assignmentMessage.push(`${Lang.translate('recipient.type')}: ${recipientDefinition.RTYPE}`)
    const messageParams = assignmentMessage.join(', ')

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: RECIPIENT_DEFINITION_DELETE_RECIPIENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipient${createQueryParamsForFetch(ObjectUtils.removeByValue(recipientDefinition, [undefined, null]))}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          dispatch({ type: RECIPIENT_DEFINITION_DELETE_RECIPIENT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: RECIPIENT_DEFINITION_DELETE_RECIPIENT_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.delete_recipient_success', lang, messageParams), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getRecipients again to get the current result
          const fields = undefined
          const rtype = prefs[Preferences.DEFINITION_RECI_TYPE]
          const distributionType = prefs[Preferences.DEFINITION_RECI_DISTRIBUTVIA]
          const recipient = prefs[Preferences.DEFINITION_RECI_ID]
          const preRecipient = prefs[Preferences.DEFINITION_RECI_PREDECESSORID]
          const owner = prefs[Preferences.DEFINITION_RECI_OWNER]
          const title = prefs[Preferences.DEFINITION_RECI_TITLE]
          const outputchannel = prefs[Preferences.DEFINITION_RECI_OUTPUTCHANNEL]
          const outputformat = prefs[Preferences.DEFINITION_RECI_OUTPUTFORMAT]
          const ppn = prefs[Preferences.DEFINITION_RECI_POSTPROCESSNOTE]
          getRecipients(fields, rtype, distributionType, recipient, preRecipient, owner, title, outputchannel, outputformat, ppn, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.delete_recipient_error', lang, [messageParams, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_DEFINITION_DELETE_RECIPIENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a recipient definition.
 * @param {Object} recipientDefinition The recipientdefinition. Possible fields:
 * 'RECI', 'PREDRECI', 'NEWPREDRECI', 'RTYPE', 'OWNER', 'TITLE', 'BRWS', 'PCR', 'DCR', 'PPN',
 * 'MAILADR', 'OVPCR', 'OVDCR', 'OVPPN', 'OVBUN', 'PRTBAN', 'PRTBAN2', 'PRTBANB', 'PRTTOC',
 * 'OVBAN', 'OVLBB', 'BHPRG', 'THPRG', 'DJDE', 'DJDET', 'BANNER1', 'BANNER2', 'BANNER3',
 * 'BANNER4', 'BANNER5', 'BANNER6', 'BANNER7', 'BANNER8', 'ATITLE1', 'ATITLE2', 'ATITLE3',
 * 'ATITLE4', 'ATITLE5', 'ATITLE6', 'ATITLE7', 'ATITLE8', 'CUSER'
 * @param {Function} callback The callback which will be called when the update recipient request was successful. Can be null.
 */
export function updateRecipient(recipientDefinition, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: RECIPIENT_DEFINITION_UPDATE_RECIPIENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipient`, {
        method: 'put',
        body: recipientDefinition
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: RECIPIENT_DEFINITION_UPDATE_RECIPIENT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: RECIPIENT_DEFINITION_UPDATE_RECIPIENT_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.update_recipient_success', lang, recipientDefinition['RECI']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getRecipients again to get the current result
          const fields = undefined
          const rtype = prefs[Preferences.DEFINITION_RECI_TYPE]
          const distributionType = prefs[Preferences.DEFINITION_RECI_DISTRIBUTVIA]
          const recipient = prefs[Preferences.DEFINITION_RECI_ID]
          const preRecipient = prefs[Preferences.DEFINITION_RECI_PREDECESSORID]
          const owner = prefs[Preferences.DEFINITION_RECI_OWNER]
          const title = prefs[Preferences.DEFINITION_RECI_TITLE]
          const outputchannel = prefs[Preferences.DEFINITION_RECI_OUTPUTCHANNEL]
          const outputformat = prefs[Preferences.DEFINITION_RECI_OUTPUTFORMAT]
          const ppn = prefs[Preferences.DEFINITION_RECI_POSTPROCESSNOTE]
          getRecipients(fields, rtype, distributionType, recipient, preRecipient, owner, title, outputchannel, outputformat, ppn, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.update_recipient_error', lang, [recipientDefinition['RECI'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_DEFINITION_UPDATE_RECIPIENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calling the rest api and do getRecipients request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'RECI', 'PREDRECI', 'RTYPE', 'OWNER', 'TITLE', 'BRWS', 'DCR', 'PCR', 'PPN', 'CDATE', 'CTIME'
 * @param {String} rtype The recipient type. Possible values:
 * 'ADDR', 'RECI', 'ALIAS' or ''
 * @param {String} distributionType The distribution type. Possible values:
 * 'ONLINE', 'AUTO-P', 'BUNDLE', 'ALL'
 * @param {String} recipient The recipient pattern.
 * @param {String} preRecipient The pre recipient pattern.
 * @param {String} owner The owner pattern.
 * @param {String} title The title pattern.
 * @param {String} outputchannel The outputchannel pattern.
 * @param {String} outputformat The outputformat pattern.
 * @param {String} ppn The post precess note pattern.
 * @param {Function} callback the callback which will be called when the request was successfull.
 */
export function getRecipients(fields, rtype, distributionType, recipient, preRecipient, owner, title, outputchannel, outputformat, ppn, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: RECIPIENT_DEFINITION_GET_RECIPIENTS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (rtype) { queryParams.push(`RTYPE=${encodeURIComponent(rtype)}`) }
    if (distributionType) { queryParams.push(`BRWS=${encodeURIComponent(distributionType)}`) }
    if (recipient) { queryParams.push(`RECI=${encodeURIComponent(recipient)}`) }
    if (preRecipient) { queryParams.push(`PREDRECI=${encodeURIComponent(preRecipient)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`TITLE=${encodeURIComponent(title)}`) }
    if (outputchannel) { queryParams.push(`DCR=${encodeURIComponent(outputchannel)}`) }
    if (outputformat) { queryParams.push(`PDC=${encodeURIComponent(outputformat)}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipients?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_RECIPIENT_DEFINITION_RECIPIENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: RECIPIENT_DEFINITION_GET_RECIPIENTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: RECIPIENT_DEFINITION_GET_RECIPIENTS_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.recipients_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RECIPIENT_DEFINITION_GET_RECIPIENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getRecipients request.
 * @param {String} preRecipient The pre recipient pattern.
 * @param {Function} callback the callback which will be called when the request was successful.
 */
export function checkRecipientIsDeletable(preRecipient, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    /* Build request-parameter for URL */
    const queryParams = []
    if (preRecipient) { queryParams.push(`PREDRECI=${encodeURIComponent(preRecipient)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/recipients?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          // call the callback on success
          if (callback) {
            callback()
          }
        }
        else {
          SnackbarActions.show(Lang.translate('definition.node_has_child_items'), SnackbarActions.TYPE_ERROR)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.recipients_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}