import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

// Style
import './SubAdminUserPreferencesDialog.scss'

import { ENGLISH, FRENCH, GERMAN, translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'

// Components
import {
  Button, Card, Column, Dropdown, MetaDataGrid, Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import * as PreferencesActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'

class SubAdminUserPreferencesDialog extends Component {

  state = {
    languages: [
      translate('language.english'),
      translate('language.german'),
      translate('language.french'),
    ],
    activeLanguageIndex: 0,
    datemasks: DateUtils.DATEMASKS,
    activeDatemaskIndex: 0
  }

  componentDidMount() {
    this.setState({
      activeDatemaskIndex: this.getIniDatemaskIndex(),
      activeLanguageIndex: this.getInitLanguageIndex()
    })
  }

  getIniDatemaskIndex() {
    let index = this.state.datemasks.indexOf(this.props.preferences[Preferences.DATEMASK])
    if (index < 0)
      index = 0
    return index
  }

  getInitLanguageIndex() {
    const lang = this.props.preferences[Preferences.LANGUAGE]
    switch (lang) {
      case ENGLISH: return 0
      case GERMAN: return 1
      case FRENCH: return 2
      default: return 0
    }
  }

  handleOnClose = () => {
    this.props.onClose()
  }

  handleOnSave = () => {
    // build prefs to update
    const prefs = {
      [Preferences.DATEMASK]: this.state.datemasks[this.state.activeDatemaskIndex],
      [Preferences.LANGUAGE]: this.getSaveLanguage(this.state.activeLanguageIndex)
    }

    // change the prefs
    this.props.changePrefs(prefs)

    // close the dialog
    this.props.onClose()
  }

  handleDatemaskChanged = (activeIndex) => {
    this.setState({
      activeDatemaskIndex: activeIndex
    })
  }

  handleLanguageChanged = (activeIndex) => {
    this.setState({
      activeLanguageIndex: activeIndex
    })
  }

  getSaveLanguage() {
    switch (this.state.activeLanguageIndex) {
      case 0: return ENGLISH
      case 1: return GERMAN
      case 2: return FRENCH
      default: return ENGLISH
    }
  }

  render = () => {
    const { id, userid, datemask, serverdata } = this.props
    return (
      <Modal
        id={id}
        title={translate('userpreferences.user_preferences')}>
        <Header
          id={id}
          className={'bux_SubAdminUserPreferencesHeader'}
          title={translate('userpreferences.user_preferences')}
          onClose={this.handleOnClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('user.user_id'), value: userid },
              { label: translate('general.last_login'), value: serverdata?.lastlogindate && serverdata.lastlogintime ? DateUtils.getDate(datemask, serverdata.lastlogindate, serverdata.lastlogintime.substring(0, 8)) : ''},
              { label: translate('general.last_password_change'), value: serverdata?.lastpwdchangedate && serverdata.lastpwdchangetime ? DateUtils.getDate(datemask, serverdata.lastpwdchangedate, serverdata.lastpwdchangetime.substring(0, 8)) : ''},
            ]}
            columns={4}
          />
        </Header>
        <Main>
          <Card>
            <Row id={`${id}_tabcontainer_profile_langdate_row`}>
              <Column
                id={`${id}_tabcontainer_profile_langdate_row_lang_col`}
                colMD={6}
                offsetMD={0}>
                <Dropdown
                  id={`${id}_tabcontainer_profile_langdate_row_col_lang`}
                  items={this.state.languages}
                  onChange={this.handleLanguageChanged}
                  activeIndex={this.state.activeLanguageIndex}
                  title={translate('general.language')}
                />
              </Column>
              <Column
                id={`${id}_tabcontainer_profile_langdate_row_date_col`}
                colMD={6}
                offsetMD={0}>
                <Dropdown
                  id={`${id}_tabcontainer_profile_langdate_row_date_lang`}
                  items={this.state.datemasks}
                  onChange={this.handleDatemaskChanged}
                  activeIndex={this.state.activeDatemaskIndex}
                  title={translate('general.datemask')}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer
          id={`${id}_footer`}
          className='modalDialogFooter'>
          <Button
            id={`${id}_cancelBtn`}
            text={translate('general.cancel')}
            onClick={this.handleOnClose}
          />
          <Button
            id={`${id}_saveBtn`}
            text={translate('general.save')}
            onClick={() => this.handleOnSave()}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

SubAdminUserPreferencesDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    userprofile: state.auth.serverdata.userprofile,
    preferences: state.auth.serverdata.preferences,
    serverdata: state.auth.serverdata
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: (prefs) => { PreferencesActions.changePrefs(prefs)(dispatch) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubAdminUserPreferencesDialog)