import * as DatabaseActions from '../actions/DatabaseActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  bqlQuery: undefined,
  fields: undefined,
  tablekeys: undefined,
  tablefields: undefined,
  files: undefined,
  file: undefined,
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Database bqlQuery actions
    case DatabaseActions.DATABASE_BQLQUERY_START:
      return { ...state, bqlQuery: undefined }
    case DatabaseActions.DATABASE_BQLQUERY_SUCCESS:
      return { ...state, bqlQuery: action.payload }
    case DatabaseActions.DATABASE_BQLQUERY_FAILED:
      return { ...state }
    case DatabaseActions.DATABASE_BQLQUERY_COPIED:
      return { ...state, ...action.payload }
    /* ********************************************************************************** */
    // Database getFields actions
    case DatabaseActions.DATABASE_GET_FIELDS_START:
      return { ...state }
    case DatabaseActions.DATABASE_GET_FIELDS_SUCCESS:
      return { ...state, fields: action.payload }
    case DatabaseActions.DATABASE_GET_FIELDS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Database getTableKeys actions
    case DatabaseActions.DATABASE_GET_TABLEKEYS_START:
      return { ...state }
    case DatabaseActions.DATABASE_GET_TABLEKEYS_SUCCESS:
      return { ...state, tablekeys: action.payload }
    case DatabaseActions.DATABASE_GET_TABLEKEYS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Database getKeyFields actions
    case DatabaseActions.DATABASE_GET_KEY_FIELDS_START:
      return { ...state }
    case DatabaseActions.DATABASE_GET_KEY_FIELDS_SUCCESS:
      return { ...state, keyfields: action.payload }
    case DatabaseActions.DATABASE_GET_KEY_FIELDS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Database getTableFields actions
    case DatabaseActions.DATABASE_GET_TABLEFIELDS_START:
      return { ...state }
    case DatabaseActions.DATABASE_GET_TABLEFIELDS_SUCCESS:
      return { ...state, tablefields: action.payload }
    case DatabaseActions.DATABASE_GET_TABLEFIELDS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Database getTables actions
    case DatabaseActions.DATABASE_GET_TABLES_START:
      return { ...state }
    case DatabaseActions.DATABASE_GET_TABLES_SUCCESS:
      return { ...state, tables: action.payload }
    case DatabaseActions.DATABASE_GET_TABLES_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Database deleteFile actions
    case DatabaseActions.DATABASE_DELETE_FILE_START:
      return { ...state }
    case DatabaseActions.DATABASE_DELETE_FILE_SUCCESS:
      return { ...state }
    case DatabaseActions.DATABASE_DELETE_FILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Database createFile actions
    case DatabaseActions.DATABASE_CREATE_FILE_START:
      return { ...state }
    case DatabaseActions.DATABASE_CREATE_FILE_SUCCESS:
      return { ...state }
    case DatabaseActions.DATABASE_CREATE_FILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Database updateFile actions
    case DatabaseActions.DATABASE_UPDATE_FILE_START:
      return { ...state }
    case DatabaseActions.DATABASE_UPDATE_FILE_SUCCESS:
      return { ...state }
    case DatabaseActions.DATABASE_UPDATE_FILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Database getFile actions
    case DatabaseActions.DATABASE_GET_FILE_START:
      return { ...state, file: undefined }
    case DatabaseActions.DATABASE_GET_FILE_SUCCESS:
      return { ...state, file: action.payload }
    case DatabaseActions.DATABASE_GET_FILE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Database getFiles actions
    case DatabaseActions.DATABASE_GET_FILES_START:
      return { ...state }
    case DatabaseActions.DATABASE_GET_FILES_SUCCESS:
      return { ...state, files: action.payload }
    case DatabaseActions.DATABASE_GET_FILES_FAILED:
      return { ...state }
    case DatabaseActions.NO_DATABASE_FILES_FOUND:
      return { ...state, files: {} }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return {
        ...state,
        bqlQuery: undefined,
        copiedBqlQuery: undefined,
        fields: undefined,
        tablekeys: undefined,
        tablefields: undefined,
        tables: undefined,
        files: undefined,
        file: undefined,
      }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer