import React from 'react';

// Style
import './Label.scss';
import { prepareClassNames } from '../../utils/ComponentUtils';

interface LabelProps {
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** Style class from CSS for styling for label.*/
  className?: string;
  /** Text to be displayed */
  title?: string;
  /** Indicates if the label should be marked as a required */
  required?: boolean | string;
  /** Indicates if the label should be greyed out */
  disabled?: boolean;
  /**
   * Indicates is an error occurs.
   * If _isError_  and _required_ are set to true, required mark will change color to red
   */
  isError?: boolean;
  /** Sets bold font for label */
  bold?: boolean;

}

/**
 * Label component created according to style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
const Label = ({ id, className, title, required, disabled, isError, bold }: LabelProps): JSX.Element => {
  const concatenatedClassNames = prepareClassNames([
    'label_title',
    isError && 'label_title_error',
    bold && 'cl_label_bold',
    className
  ]);

  return (
    <>
      {
        title !== undefined &&
        <div id={id} className={'cl_label_container'}>
          <label
            id={`${id}_label`}
            className={concatenatedClassNames}
            // custom properties
            {...{ required: !!required }}
            {...{ disabled: disabled }}
            title={title}>
            {/* white space (fromCharCode(160)) is needed when empty span should be rendered to horizontal align the cols */}
            {title === ' ' ? String.fromCharCode(160) : title}
            {required && <label>{typeof required == 'string' ? required : 'required'} </label>}
          </label>
        </div>
      }
    </>
  );
};


export default Label;