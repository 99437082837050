import * as UserSecurityAssignmentActions from '../actions/UserSecurityAssignmentActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  userSecurityAssignments: undefined,
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Get users security profiles actions
    case UserSecurityAssignmentActions.GET_USERS_SECURITY_PROFILES_START:
      return { ...state, userSecurityAssignments: undefined }
    case UserSecurityAssignmentActions.GET_USERS_SECURITY_PROFILES_SUCCESS:
      return { ...state, userSecurityAssignments: action.payload }
    case UserSecurityAssignmentActions.GET_USERS_SECURITY_PROFILES_FAILED:
      return { ...state, userSecurityAssignments: undefined }
    case UserSecurityAssignmentActions.NO_USERS_SECURITY_PROFILES_FOUND:
      return { ...state, userSecurityAssignments: {} }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, userSecurityAssignments: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer