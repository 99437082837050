import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import { Component } from 'react'

// Components
import {
  Button, Card,
  Checkbox, Column, MetaDataGrid, Modal as ModalComponent, NumericSpinner, Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as StandardSelectionActions from 'redux/actions/StandardSelectionActions'
import * as Preferences from 'redux/general/Preferences'

class MarkReloadDocumentDialog extends Component {
  state = {
    days: 0,
    currentProgress: 1,
    processAll: false
  }

  /**
   * @description changes state days
   * @param {Number} value the new value for days
   */
  handleDays = value => {
    this.setState({ days: value })
  }

  nextDocument = () => {
    const { currentProgress } = this.state
    const { onClose, documentsToReload } = this.props
    if (documentsToReload && currentProgress < Object.values(documentsToReload).length) {
      this.setState({ currentProgress: currentProgress + 1 })
    } else {
      onClose()
    }
  }

  /**
   * @description saves the new reload status
   */
  handleReload = () => {
    const { document, markReload, documentsToReload, refreshFunction } = this.props
    const { currentProgress } = this.state
    const documentToUse = documentsToReload ? Object.values(documentsToReload)[currentProgress - 1] : document

    const closeCallback = () => {
      this.nextDocument()
    }
    const documentDefinition = {
      RELOAD: 'PENDING',
      DOCID: documentToUse.DOCID,
      FORM: documentToUse.FORM,
      EXT: documentToUse.EXT,
      REPORT: documentToUse.REPORT,
      ONLRETPD: this.state.days.toString()
    }
    markReload(documentDefinition, closeCallback, refreshFunction)
  }

  /**
   * @description saves the new reload status
   */
  handleReloadAll = () => {
    const { document, updateMarkMulti, documentsToReload, onClose, resetCheckedRows, refreshFunction } = this.props
    const { currentProgress } = this.state
    const documentToUse = Object.values(documentsToReload)
    const docids = []
    const docInfo = []
    for (let i = currentProgress - 1; i < documentToUse.length; i++) {
      docids.push(documentToUse[i]?.DOCID)
      docInfo.push({
        form: documentToUse[i].FORM,
        extension: documentToUse[i].EXT,
        report: documentToUse[i].REPORT
      })
    }
    const documentDefinition = {
      RELOAD: 'PENDING',
      DOCIDS: docids,
      FORM: document.FORM,
      EXT: document.EXT,
      REPORT: document.REPORT,
      ONLRETPD: this.state.days.toString()
    }
    updateMarkMulti(documentDefinition, docInfo, () => {
      onClose()
      resetCheckedRows && resetCheckedRows()
    }, refreshFunction)
  }

  render = () => {
    const { id, document, onClose, documentsToReload } = this.props
    const { processAll, currentProgress } = this.state
    const documentToUse = documentsToReload ? Object.values(documentsToReload)[currentProgress - 1] : document

    return (
      <Modal
        id={`${id}_markreload`}
        onClose={onClose}
        size={'m'}>
        <Header
          id={`${id}_markreload`}
          title={translate('documents.reload_document')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('general.form'), value: documentToUse.FORM },
              { label: translate('general.extension'), value: documentToUse.EXT },
              { label: translate('general.report'), value: documentToUse.REPORT },
            ]}
            columns={4}
          />
        </Header>
        <Main id={`${id}_modal_main`}>
          <Card>
            <Row>
              <Column colMD={12}>
                <NumericSpinner
                  id={`${id}_input`}
                  onChange={this.handleDays}
                  max={9999}
                  value={this.state.days}
                  steps={1}
                  min={0}
                  title={translate('documents.reload_document_retention')}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_modal_header`}>
          {
            documentsToReload &&
            <>
              <span className={'badm_print_progress'}>{`${currentProgress} of ${Object.values(documentsToReload).length} ${translate('general.items_lowercase')}`}</span>
              <Checkbox
                id={`${id}_request_all`}
                onCheck={isChecked => this.setState({ processAll: isChecked })}
                value={processAll}
                label={translate('general.request_all_current_settings')}
              />
            </>
          }
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          {
            documentsToReload &&
            <Button
              id={`${id}_skip_button`}
              text={translate('general.skip')}
              onClick={() => this.nextDocument()}
              disabled={processAll}
              tooltip={processAll && translate('general.request_all_current_settings')}
            />
          }
          <Button
            id={`${id}_footer_reload`}
            text={processAll ? `${translate('general.reload')} (${Object.values(documentsToReload).length - currentProgress + 1})` : translate('general.reload')}
            onClick={processAll ? this.handleReloadAll : this.handleReload}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

MarkReloadDocumentDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateMarkMulti: (updateObj, docInformation, callback, refreshFunction) => {
      StandardSelectionActions.updateMarkMulti(updateObj, docInformation, callback, refreshFunction)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkReloadDocumentDialog)