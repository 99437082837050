import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class TableButtonGroupSeparator extends PureComponent {
  render = () => {
    return (
      <li
        id={`${this.props.id}_separator`}
        role="separator"
        className="dropdown-divider"
      />
    )
  }
}

TableButtonGroupSeparator.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string,
}

export default TableButtonGroupSeparator