import React, {useEffect, useState, useCallback} from 'react';

interface DropdownMenuProps {
  id: string;
  dataTestId?: string | undefined;
  items: React.ReactNode[];
  wrapperRef: React.RefObject<HTMLUListElement>;
  generalItems: React.ReactNode[];
  containerID?: string;
}
export const DropdownMenu = ({ id, dataTestId, items, wrapperRef, generalItems }: DropdownMenuProps): JSX.Element => {
  const getSizeAndPosition = useCallback((): {} => {
    const el = document.querySelector(`#${id}`);
    const elTop = el?.getBoundingClientRect().top || 0;
    const elY = el?.getBoundingClientRect().y || 0;
    const elYLeft= el?.getBoundingClientRect().left || 0;
    const elWidth = el?.getBoundingClientRect().width || 0;

    const elTitle = document.querySelector(`#${id}_dropdown_title`);
    const elTitleHeight = elTitle?.getBoundingClientRect().height || 0;

    const elButton = document.querySelector(`#${id}_dropdown_button`);
    const elButtonHeight = elButton?.getBoundingClientRect().height || 0;

    const height = elTitleHeight + elButtonHeight;

    const maxHeightToUse = window.innerHeight - elTop - height;
    const possibleItems = Math.floor(maxHeightToUse / 40);
    const itemToUse = (items.length < possibleItems ? items.length : possibleItems);
    return {
      top: elY + height - 1,
      left: elYLeft,
      width: elWidth,
      height: 42 + (itemToUse - 1) * 40
    };
  }, [id, items.length]);
  const dropdownSizesAndPosition = getSizeAndPosition();
  const [size, setSize] = useState(dropdownSizesAndPosition);

  useEffect(() => {
    window.addEventListener('resize', () => setSize(getSizeAndPosition));
    return () => {
      window.removeEventListener('resize', () => setSize(getSizeAndPosition));
    };
  }, [getSizeAndPosition]);

  return (
    <ul
      ref={wrapperRef}
      id={`${id}_menu_list`}
      data-testid={dataTestId}
      className={'dropdown_menu cl_dropdown_menu'}
      style={{ ...size }}
    >
      {generalItems}
      {generalItems[0] !== undefined && <li className='generalDivider'></li>}
      {items}
    </ul>
  );
};