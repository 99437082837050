export const DOC_VIEWER_HEX_MODE_BLOCK = 'BLOCK';
export const DOC_VIEWER_HEX_MODE_LINE = 'LINE';
export const DOC_VIEWER_HEX_SHOW_HEADER_NO = 'NO';
export const DOC_VIEWER_HEX_SHOW_RESOURCES_NO = 'NO';
export const DOC_VIEWER_HEX_SHOW_HEADER_YES = 'YES';
export const DOC_VIEWER_HEX_SHOW_RESOURCES_YES = 'YES';

export const DOC_VIEWER_HEX_MARKER_SEPARATOR = '  ';
export const DOC_VIEWER_HEX_SEPARATOR_START = ' * ';
export const DOC_VIEWER_HEX_SEPARATOR_END = ' *';

export const TAB_CONFIG_VIEW_MODE_HEX_BLOCK = 'view_mode_hex_block';
export const TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER = 'view_mode_hex_block_header';
export const TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER_RESOURCES = 'view_mode_hex_block_header_resources';
export const TAB_CONFIG_VIEW_MODE_HEX_BLOCK_RESOURCES = 'view_mode_hex_block_resources';
export const TAB_CONFIG_VIEW_MODE_HEX_LINE = 'view_mode_hex_line';
export const TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER = 'view_mode_hex_line_header';
export const TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER_RESOURCES = 'view_mode_hex_line_header_resources';
export const TAB_CONFIG_VIEW_MODE_HEX_LINE_RESOURCES = 'view_mode_hex_line_resources';
export const TAB_CONFIG_VIEW_MODE_TEXT = 'view_mode_text';

export const TAB_CONFIG_VIEW_WITH_RESOURCES = [
  TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER_RESOURCES,
  TAB_CONFIG_VIEW_MODE_HEX_BLOCK_RESOURCES,
  TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER_RESOURCES,
  TAB_CONFIG_VIEW_MODE_HEX_LINE_RESOURCES
];