import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as UrlUtils from 'utils/UrlUtils'
import * as UserUtils from 'utils/UserUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CloneLogDefinitionDialog from 'components/dialogs/clone_log_definition_dialog/CloneLogDefinitionDialog'
import CreateLogDialog from 'components/dialogs/create_log_dialog/CreateLogDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyLogDialog from 'components/dialogs/modify_log_dialog/ModifyLogDialog'
import VerifyDocumentDefinitionDialog from 'components/dialogs/verify_document_definition_dialog/VerifyDocumentDefinitionDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as LogDefinitionActions from 'redux/actions/LogDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultLog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired
  }

  state = {
    header: this.fillHeaderInformation(),
    showTableSettingsDialog: false,
    showCreateDialog: false,
    showModifyDialog: false,
    showDeleteDialog: false,
    showCloneDialog: false
  }

  fillHeaderInformation() {
    return [
      { rest: HOST, translation: 'definition.host', default: true },
      { rest: APPLICATION, translation: 'definition.application', default: true },
      { rest: SUBLOG, translation: 'definition.sublog', default: true },
      { rest: SMODE, translation: 'definition.document_search_mode', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: BURSTMODE },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description gets the index of the header in redux state header
   * @param {String} header header name of the header in redux state header
   */
  headerData = header => this.props.logs.header.indexOf(header)

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = () => [{ type: 'date-time', col: 5 }]

  /**
   * @description Handles the refresh button
   */
  handleRefresh = () => {
    const { prefs, getLogs } = this.props
    getLogs(
      undefined,
      prefs[Preferences.DEFINITION_LOG_HOST],
      prefs[Preferences.DEFINITION_LOG_APPLICATION],
      prefs[Preferences.DEFINITION_LOG_SUBLOG],
      prefs[Preferences.DEFINITION_LOG_TYPE],
      prefs[Preferences.DEFINITION_LOG_OWNER],
      prefs[Preferences.DEFINITION_LOG_TITLE],
    )
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, logs } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    logs.data.forEach(element => {
      const dataBuffer = []
      usedHeader.forEach(h => {
        if (h === SMODE && element[this.headerData(SMODE)] !== '') {
          const found = DefinitionUtils.DOCUMENT_DEFINITION_SEARCH_MODES.find(d => d.key === element[this.headerData('SMODE')])
          dataBuffer.push(found ? translate(found.translationKey) : element[this.headerData('SMODE')])
        }
        else if (h === BURSTMODE && element[this.headerData(BURSTMODE)] !== '') {
          const found = DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODES.find(d => d.key === element[this.headerData('BMODE')])
          dataBuffer.push(found ? translate(found.translationKey) : element[this.headerData('BMODE')])
        }
        else if (h === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.showPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  handleDialog = (index, dialog) => {
    const { getLog, logs } = this.props
    const callback = () => this.setState({ [dialog]: true })
    const host = logs.data[index][this.headerData('FORM')]
    const application = logs.data[index][this.headerData('EXT')]
    const subLog = logs.data[index][this.headerData('REPORT')]
    getLog(host, application, subLog, callback)
  }

  showIndices = index => {
    const { logs } = this.props
    const header = logs.header
    const data = logs.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
      { rest: 'REPORT', ui: 'report' },
    ]
    // fake the state like its used in assignment index log to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
      report: data[header.indexOf('REPORT')],
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/index_log${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  showRecipients = index => {
    const { logs } = this.props
    const header = logs.header
    const data = logs.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
      { rest: 'REPORT', ui: 'report' },
      { rest: 'WREPORT', ui: 'dynamicReport' },
    ]
    // fake the state like its used in assignment index log to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
      report: data[header.indexOf('REPORT')],
      dynamicReport: ''
      // ! Not possible to get the value at the moment.
      // dynamicReport: data[header.indexOf('WREPORT')],
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/recipient_log${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  showNodes = index => {
    const { logs } = this.props
    const header = logs.header
    const data = logs.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
    ]
    // fake the state like its used in assignment index log to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/node_log${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  // showVerify = index => {
  // const { logs, verifyDocument } = this.props
  // const logObj = {
  //   FORM: logs.data[index][this.headerData('FORM')],
  //   EXTENSION: logs.data[index][this.headerData('EXT')],
  //   REPORT: logs.data[index][this.headerData('REPORT')]
  // }

  // verifyDocument(logObj, () => this.setState({ showVerifyDialog: true }))
  // }

  /**
   * @description Checks for the status of the row entry to decide if the verify button should be rendered
   * @param {Number} index
   */
  isSearchModeActive = index => {
    const { logs } = this.props
    const sMode = logs.data[index]?.[this.headerData('SMODE')]
    const bMode = logs.data[index]?.[this.headerData('BMODE')]
    if (sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_ABSOLUTE || sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_START_STOP) {
      return true
    }
    if (sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_AUTOBURST) {
      if (bMode === DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODE_RELATIVE) {
        return true
      }
    }

    if (sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_LIMIT_BURST || sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_COND_BURST1 || sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_COND_BURST2 || sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_COND_BURST3) {
      if (bMode === DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODE_RELATIVE || bMode === DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODE_FIX) {
        return true
      }
    }
    return false
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showCloneDialog')}
          id={`${id}_cloneBtn`}
          icon={<Icon name='clone' className='actionIcon' />}
          text={translate('general.clone')}
          title={translate('general.clone')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => this.showRecipients(rowIndex)}
          id={`${id}_show_recipients_btn`}
          icon={<Icon name='group' className='actionIcon' />}
          text={translate('general.display_recipients')}
          title={translate('general.display_recipients')}
        />
        <TableButtonGroupItem
          onClick={() => this.showIndices(rowIndex)}
          id={`${id}_show_indices_btn`}
          icon={<Icon name='reference' className='actionIcon' />}
          text={translate('general.display_indices')}
          title={translate('general.display_indices')}
        />
        {
          UserUtils.isDOCX() &&
          <TableButtonGroupItem
            onClick={() => this.showNodes(rowIndex)}
            id={`${id}_show_nodes_btn`}
            icon={<Icon name='node' className='actionIcon' />}
            text={translate('general.display_nodes')}
            title={translate('general.display_nodes')}
          />
        }
        {
          this.isSearchModeActive(rowIndex) &&
          <>
            <TableButtonGroupSeparator id={id} />
            <TableButtonGroupItem
              onClick={() => this.showVerify(rowIndex)}
              id={`${id}_verify_arguments`}
              icon={<Icon name='verify' className='actionIcon' />}
              text={translate('general.verify')}
              title={translate('general.verify')}
            />
          </>
        }
      </TableButtonGroup>
    ]
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.prefs[Preferences.TABLE_SETTINGS_DEFINITION_LOG]) {
      let buffer = []
      this.props.prefs[Preferences.TABLE_SETTINGS_DEFINITION_LOG].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.prefs[Preferences.TABLE_SETTINGS_DEFINITION_LOG]) {
      return this.props.prefs[Preferences.TABLE_SETTINGS_DEFINITION_LOG].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  deleteLog = () => {
    const { log, deleteLog } = this.props
    const callback = () => this.setState({ showDeleteDialog: false })
    const logObj = {
      FORM: log.FORM,
      EXT: log.EXT,
      REPORT: log.REPORT
    }
    deleteLog(logObj, callback)
  }
  showPrefilledCreateDialog = () => {
    const { prefs } = this.props

    const host = prefs[Preferences.DEFINITION_LOG_HOST];
    const application = prefs[Preferences.DEFINITION_LOG_APPLICATION];
    const subLog = prefs[Preferences.DEFINITION_LOG_SUBLOG];
    const owner = prefs[Preferences.DEFINITION_LOG_OWNER];
    const title = prefs[Preferences.DEFINITION_LOG_TITLE];

    this.setState({
      showCreateDialog: true,
      createDialogData: {
        FORM: host,
        EXT: application,
        REPORT: subLog,
        OWNER: owner,
        LTITLE: title} })
  }

  render = () => {
    const { logs, id, drawerExpanded, autoDrawer, lang, datemask, log, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showDeleteDialog, showCopyDialog, showModifyDialog, showVerifyDialog, showCloneDialog } = this.state
    const data = logs && logs.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {
          showTableSettingsDialog &&
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_LOG }}
          />
        }
        {
          showCreateDialog &&
          <CreateLogDialog
            id={`${id}_create_dialog`}
            copyLog={false}
            onClose={() => this.setState({ showCreateDialog: false, createDialogData: undefined})}
            prefilledData={this.state.createDialogData}
          />
        }
        {
          showCopyDialog &&
          <CreateLogDialog
            id={`${id}_copy_dialog`}
            copyLog={true}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        }
        {
          showModifyDialog &&
          <ModifyLogDialog
            id={`${id}_modify_dialog`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        }
        {
          showVerifyDialog &&
          <VerifyDocumentDefinitionDialog
            id={`${id}_verify_dialog`}
            onClose={() => this.setState({ showVerifyDialog: false })}
          />
        }
        {
          showCloneDialog &&
          <CloneLogDefinitionDialog
            id={`${id}_clone_dialog`}
            onClose={() => this.setState({ showCloneDialog: false })}
          />
        }
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_deletelogdialog`}
            title={translate('definition.delete_log')}
            question={translate('definition.question_delete_log')}
            onClose={() => this.setState({ showDeleteDialog: false })}
            onDelete={() => this.deleteLog()}>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}`}>
                  {translate('definition.host')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}`}>
                  {log.FORM}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}`}>
                  {translate('definition.application')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}`}>
                  {log.EXT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}`}>
                  {translate('definition.sublog')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}`}>
                  {log.REPORT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}`}>
                  {translate('general.title')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}`}>
                  {log.LTITLE}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {logs
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={index => this.handleDialog(index, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    translate={key => translate(key)}
                    fillPage={fillPage}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_log_result')}
                    link={translate('emptyresult.create_log_link')}
                    onClick={() => this.showPrefilledCreateDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const HOST = 'FORM'
const APPLICATION = 'EXT'
const SUBLOG = 'REPORT'
const SMODE = 'SMODE'
const BURSTMODE = 'BMODE'
const TITLE = 'LTITLE'
const OWNER = 'OWNER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const LASTCHANGED = 'LASTCHANGED'

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    prefs: state.auth.serverdata.preferences,
    logs: state.definitions.logs.logs,
    log: state.definitions.logs.log,
    keepPagination: state.definitions.logs.keepPagination,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLogs: (fields, host, application, subLog, process, owner, title, callback) => {
      LogDefinitionActions.getLogs(fields, host, application, subLog, process, owner, title, callback)(dispatch)
    },
    getLog: (form, extension, report, callback) => {
      LogDefinitionActions.getLog(undefined, form, extension, report, callback)(dispatch)
    },
    deleteLog: (logObj, callback) => {
      LogDefinitionActions.deleteLog(logObj, callback)(dispatch)
    },
    verifyLog: (logObj, callback) => {
      LogDefinitionActions.verifyLog(logObj, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultLog))