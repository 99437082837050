import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Card from 'BetaUX2Web-Components/src/components/card/Card'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as SearchArgumentActions from 'redux/actions/SearchArgumentDefinitionActions'
import * as ModalSelectorActions from '../../../../../../redux/actions/ModalSelectorActions';
import SelectorDialog from '../../../../../dialogs/selector_dialog/SelectorDialog';

class DefinitionSearchArgument extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  state = {
    searchargumentID: '',
    form: '',
    extension: '',
    report: '',
    showFormDefinitionSelectorDialog: false,
    showExtDefinitionSelectorDialog: false,
    showReportDefinitionSelectorDialog: false,
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props
    if (preferences) {
      this.setState({
        searchargumentID: preferences[Preferences.DEFINITION_SEARCHARGUMENT_ID] ?? this.state.searchargumentID,
        form: preferences[Preferences.DEFINITION_SEARCHARGUMENT_FORM] ?? this.state.form,
        extension: preferences[Preferences.DEFINITION_SEARCHARGUMENT_EXTENSION] ?? this.state.extension,
        report: preferences[Preferences.DEFINITION_SEARCHARGUMENT_REPORT] ?? this.state.report
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   */
  handleInputChanged = (id, value) => this.setState({ [id]: value })

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = (event) => {
    const { searchargumentID, form, extension, report } = this.state
    const { changePrefs, getSearchArguments } = this.props
    event.preventDefault()
    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_SEARCHARGUMENT_ID]: searchargumentID,
      [Preferences.DEFINITION_SEARCHARGUMENT_FORM]: form,
      [Preferences.DEFINITION_SEARCHARGUMENT_EXTENSION]: extension,
      [Preferences.DEFINITION_SEARCHARGUMENT_REPORT]: report
    }
    changePrefs(prefsToChange)
    getSearchArguments(undefined, searchargumentID, form, extension, report)
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleOnReset = () => {
    this.setState({
      searchargumentID: '',
      form: '',
      extension: '',
      report: ''
    })
  }


  onOpenFormDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showFormDefinitionSelectorDialog: true })
    }

    this.props.getDocumentDefinitions(
      ['FORM'],
      this.state.form,
      this.state.extension,
      this.state.report,
      callback)
  }

  onOpenExtDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showExtDefinitionSelectorDialog: true })
    }

    this.props.getDocumentDefinitions(
      ['EXT'],
      this.state.form,
      this.state.extension,
      this.state.report,
      callback)
  }

  onOpenReportDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showReportDefinitionSelectorDialog: true })
    }

    this.props.getDocumentDefinitions(
      ['REPORT'],
      this.state.form,
      this.state.extension,
      this.state.report,
      callback)
  }

  renderSelectorDialogs = () => {
    const { id } = this.props

    let formDefinitions
    if (this.state.showFormDefinitionSelectorDialog) {
      formDefinitions = this.props.selector.documents
      formDefinitions.data = formDefinitions.data
        .filter((value, index, self) => self.findIndex(v => value[0] === v[0]) === index)
    }

    let extDefinitions
    if (this.state.showExtDefinitionSelectorDialog) {
      extDefinitions = this.props.selector.documents
      extDefinitions.data = extDefinitions.data
        .filter((value, index, self) => self.findIndex(v => value[0] === v[0]) === index)
    }

    let reportDefinitions
    if (this.state.showReportDefinitionSelectorDialog) {
      reportDefinitions = this.props.selector.documents
      reportDefinitions.data = reportDefinitions.data
        .filter((value, index, self) => self.findIndex(v => value[0] === v[0]) === index)
    }

    return (
      <>

        {this.state.showFormDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_form_definitions`}
            onClose={() => { this.setState({ showFormDefinitionSelectorDialog: false }) }}
            title={translate('definition.form.title')}
            header={[
              translate('definition.form')
            ]}
            items={formDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newValue = formDefinitions.data[selectedRows][formDefinitions.header.indexOf('FORM')]
                this.setState({
                  form: newValue,
                  showFormDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showFormDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showExtDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_ext_definitions`}
            onClose={() => { this.setState({ showExtDefinitionSelectorDialog: false }) }}
            title={translate('definition.extension.title')}
            header={[
              translate('definition.extension')
            ]}
            items={extDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newValue = extDefinitions.data[selectedRows][extDefinitions.header.indexOf('EXT')]
                this.setState({
                  extension: newValue,
                  showExtDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showExtDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showReportDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_report_definitions`}
            onClose={() => { this.setState({ showReportDefinitionSelectorDialog: false }) }}
            title={translate('definition.report.title')}
            header={[
              translate('definition.report'),
            ]}
            items={reportDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newValue = reportDefinitions.data[selectedRows][reportDefinitions.header.indexOf('REPORT')]
                this.setState({
                  report: newValue,
                  showReportDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showReportDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

      </>
    )
  }


  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    const { searchargumentID, form, extension, report } = this.state
    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        <Card title={translate('general.general')}>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_searchargument_id`}
                title={translate('definition.searchargument_id')}
                onInputChanged={val => this.handleInputChanged('searchargumentID', val)}
                maxLength={16}
                value={searchargumentID}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={6}>
              <Input
                id={`${id}_searchargument_form`}
                title={translate('general.form')}
                onInputChanged={val => this.handleInputChanged('form', val)}
                maxLength={8}
                value={form}
                addon={{
                  iconName: 'list',
                  onClick: () => this.onOpenFormDefinitionDialog()
                }}
              />
            </Column>
            <Column colMD={6}>
              <Input
                id={`${id}_searchargument_extension`}
                title={translate('general.extension')}
                onInputChanged={val => this.handleInputChanged('extension', val)}
                maxLength={16}
                value={extension}
                addon={{
                  iconName: 'list',
                  onClick: () => this.onOpenExtDefinitionDialog()
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_searchargument_report`}
                title={translate('general.report')}
                onInputChanged={val => this.handleInputChanged('report', val)}
                maxLength={16}
                value={report}
                addon={{
                  iconName: 'list',
                  onClick: () => this.onOpenReportDefinitionDialog()
                }}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleOnReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={() => { }}>
        {this.renderMain()}
        {this.renderFooter()}
        {this.renderSelectorDialogs()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => {
      PreferenceActions.changePrefs(prefs)(dispatch)
    },
    getSearchArguments: (fields, sasid, form, extension, report, callback) => {
      SearchArgumentActions.getSearchArguments(fields, sasid, form, extension, report, callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        callback,
        false
      )(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionSearchArgument)