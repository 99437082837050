import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CopyCustomDialog from 'components/dialogs/copy_custom_dialog/CopyCustomDialog'
import CreateCustomDialog from 'components/dialogs/create_custom_dialog/CreateCustomDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyCustomDialog from 'components/dialogs/modify_custom_dialog/ModifyCustomDialog'
import TableSettings from 'components/table_settings/TableSettings'


import { translate } from 'language/Language'
import * as CustomDialogSystem from 'utils/CustomDialogSystemUtils'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import { getCustomDialogDocxCommands, getCustomDialogLogxCommands } from 'utils/DefinitionUtils'
import * as SortUtils from 'utils/SortUtils'
import * as UserUtils from 'utils/UserUtils'

import * as CustomDialogActions from 'redux/actions/CustomDialogDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultCustomDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired
  }

  state = {
    showTableSettingsDialog: false,
    showCreateDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    showModifyDialog: false,
    header: this.fillHeaderInformation(),
    checkedRows: []
  }

  fillHeaderInformation() {
    return [
      { rest: DIALOGID, translation: 'definition.custom_dialog_id', default: true },
      { rest: DESCRIPTION, translation: 'general.description', default: true },
      { rest: RESULTTABLEID, translation: 'definition.result_table_id', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: COMMAND, translation: 'definition.command', default: true },
      { rest: JOBTYPE, translation: 'job.jobtype', default: true },
      { rest: HIERARCHY, translation: 'general.hierarchy', default: true },
      { rest: NODE, translation: 'general.node', default: true },
      { rest: HITS, translation: 'general.hits', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER },
    ]
  }

  /**
   * @description Handles the action buttons to open dialogs.
   * @param {Number} rowIndex The row index of the item which was clicked.
   * @param {String} dialog The dialog of the state.
   */
  handleDialog = (rowIndex, dialog) => {
    const { getCustomDialog, customDialogs } = this.props
    const id = customDialogs.data[rowIndex][this.headerData('SPTINAME')]
    getCustomDialog(id, () => this.setState({ [dialog]: true }))
  }

  /**
   * @description Handles the modify dialog action.
   * @param {Number} rowIndex The row index of the item which was clicked.
   */
  handleModifyDialog = rowIndex => {
    const { getCustomDialog, customDialogs, getDefaultObjects, getIndexDefaultObjects } = this.props
    const id = customDialogs.data[rowIndex][this.headerData('SPTINAME')]
    const type = customDialogs.data[rowIndex][this.headerData('SPTUXCMD')]
    const jobtype = customDialogs.data[rowIndex][this.headerData('SLTITYPE')]

    // add jobtype as second argument
    const params = [type, '']
    if (type === 'SELJOB') {
      params[1] = jobtype
    }

    const promisses = [getDefaultObjects(params[0], params[1])]

    // get the available index elementes when SELGBL is used
    if (type === 'SELGBL') {
      promisses.push(getIndexDefaultObjects())
    }

    Promise.all([
      ...promisses,
      getCustomDialog(id)
    ]).then(() => this.setState({ showModifyDialog: true }))
  }

  /**
   * @description Handles the create dialog action.
   */
  handleCreateDialog = () => {
    const { getDefaultObjects, preferences } = this.props
    Promise.all([
      getDefaultObjects('SELDOC', '')
    ]).then(() => this.setState({
      showCreateDialog: true,
      createData: {
        SPTINAME: preferences[Preferences.DEFINITION_CUSTOM_DIALOG_ID],
        SPTENAME: preferences[Preferences.DEFINITION_CUSTOM_DIALOG_DESCRIPTION],
        OWNER: preferences[Preferences.DEFINITION_CUSTOM_DIALOG_OWNER],
        SLTINAME: preferences[Preferences.DEFINITION_CUSTOM_DIALOG_RESULT_TABLE_ID],
        SPTUXCMD: preferences[Preferences.DEFINITION_CUSTOM_DIALOG_COMMAND],
        SLTITYPE: preferences[Preferences.DEFINITION_CUSTOM_DIALOG_JOBTYPE],
      }
    }))
  }

  /**
   * @description Handles the create dialog action.
   */
  handleCopyDialog = rowIndex => {
    const { getCustomDialog, customDialogs, getDefaultObjects, getIndexDefaultObjects } = this.props
    const id = customDialogs.data[rowIndex][this.headerData('SPTINAME')]
    const type = customDialogs.data[rowIndex][this.headerData('SPTUXCMD')]
    const jobtype = customDialogs.data[rowIndex][this.headerData('SLTITYPE')]

    // add jobtype as second argument
    const params = [type, '']
    if (type === 'SELJOB') {
      params[1] = jobtype
    }

    const promisses = [getDefaultObjects(params[0], params[1])]

    // get the available index elementes when SELGBL is used
    if (type === 'SELGBL') {
      promisses.push(getIndexDefaultObjects())
    }

    Promise.all([
      ...promisses,
      getCustomDialog(id)
    ]).then(() => this.setState({ showCopyDialog: true }))
  }

  /**
   * @description Deletes the custom dialog
   */
  handleDelete = () => {
    const { deleteCustomDialog, customDialog } = this.props
    const customDialogObj = {
      SPTINAME: customDialog.SPTINAME,
      SPTENAME: customDialog.SPTENAME,
      SPTUXCMD: customDialog.SPTUXCMD,
      SLTINAME: customDialog.SLTINAME,
      MAXENTRY: customDialog.MAXENTRY,
      SPTDND: customDialog.SPTDND,
      DNDNAME: customDialog.DNDNAME,
      OWNER: customDialog.OWNER
    }
    deleteCustomDialog(customDialogObj, () => this.setState({ showDeleteDialog: false }))
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleModifyDialog(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
          onClick={() => this.handleModifyDialog(rowIndex)}
        />
        <TableButtonGroupItem
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
          onClick={() => this.handleCopyDialog(rowIndex)}
        />
        <TableButtonGroupItem
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.handleCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.props.refreshSearch}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
  * @description gets the index of the header in redux state definitions.customDialogs.header
  * @param {String} header header name of the header in redux definitions.state customDialogs.header
  */
  headerData = header => this.props.customDialogs.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, customDialogs } = this.props
    const data = []
    const headers = this.getUsedHeader()

    customDialogs.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        } else if (header === COMMAND) {
          if (UserUtils.isDOCX()) {
            const found = getCustomDialogDocxCommands(false).find(e => e.key === el[this.headerData(COMMAND)])
            buffer.push(found ? translate(found.translationKey) : el[this.headerData(COMMAND)])
          } else {
            // Fallback because I had an IMPORT custom dialog on my logx system. Maybe some other systems have this data too.
            const commandToUse = getCustomDialogLogxCommands(false).find(e => e.key === el[this.headerData(COMMAND)])
            buffer.push(commandToUse ? translate(commandToUse.translationKey) : el[this.headerData(COMMAND)])
          }
        } else if (header === JOBTYPE) {
          const result = DefinitionUtils.getResultTableJobTypes().find(jobtype => jobtype.key === el[this.headerData(header)])
          buffer.push(result ? translate(result.translationKey) : el[this.headerData(header)])
        } else if (header === HIERARCHY) {
          el[this.headerData(HIERARCHY)] === 'YES'
            ? buffer.push(translate('general.yes'))
            : buffer.push(translate('general.no'))
        } else if (header === CTIME) {
          buffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        } else if (header === CDATE) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            buffer.push(`${val}:00`)
          }
          else {
            buffer.push(val)
          }
        }
      })
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_CUSTOM_DIALOG]) {
      const buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_CUSTOM_DIALOG].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_CUSTOM_DIALOG]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_CUSTOM_DIALOG].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  render = () => {
    const { id, customDialogs, customDialog, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showDeleteDialog, showCopyDialog, showModifyDialog } = this.state
    const data = customDialogs && customDialogs.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_CUSTOM_DIALOG }}
          />
        )}
        {showCreateDialog && (
          <CreateCustomDialog
            id={`${id}_createcustomdialog`}
            prefilledData={this.state.createData}
            onClose={() => this.setState({ showCreateDialog: false, createData: undefined })}
          />
        )}
        {showCopyDialog && (
          <CopyCustomDialog
            id={`${id}_copycustomdialog`}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        )}
        {showModifyDialog && (
          <ModifyCustomDialog
            id={`${id}_modifycustomdialog`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        )}
        {showDeleteDialog &&
          <DeleteDialog
            id={`${id}_deletecustomdialog`}
            title={translate('definition.delete_custom_dialog')}
            question={translate('definition.question_delete_custom_dialog')}
            onClose={() => this.setState({ showDeleteDialog: false })}
            onDelete={() => this.handleDelete()}>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_customdialogid_label`}>
                  {translate('definition.custom_dialog_id')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_customdialogid_value`}>
                  {customDialog.SPTINAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_description_label`}>
                  {translate('general.description')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_description_value`}>
                  {customDialog.SPTENAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_command_label`}>
                  {translate('definition.command')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_command_value`}>
                  {translate(CustomDialogSystem.getCustomDialogCommands().find(d => d.key === customDialog.SPTUXCMD)?.translationKey)}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_owner_label`}>
                  {translate('general.owner')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_owner_value`}>
                  {customDialog.OWNER}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {customDialogs
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={index => { this.handleModifyDialog(index) }}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                    updateCheckedRowsToParent={(newCheckedRows) => {
                      this.setState({ ...this.state.checkedRows, checkedRows: newCheckedRows })
                    }}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_custom_dialog_result')}
                    link={translate('emptyresult.create_custom_dialog_link')}
                    onClick={() => this.handleCreateDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const DIALOGID = 'SPTINAME'
const DESCRIPTION = 'SPTENAME'
const OWNER = 'OWNER'
const RESULTTABLEID = 'SLTINAME'
const COMMAND = 'SPTUXCMD'
const JOBTYPE = 'SLTITYPE'
const HIERARCHY = 'SPTDND'
const NODE = 'DNDNAME'
const HITS = 'MAXENTRY'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    customDialogs: state.definitions.customdialogs.customDialogs,
    customDialog: state.definitions.customdialogs.customDialog,
    keepPagination: state.definitions.customdialogs.keepPagination
  }
}

const mapDispatchToProps = dispatch => {
  return {
    refreshSearch: () => { CustomDialogActions.refreshSearch()(dispatch) },
    getCustomDialog: (customDialogID, callback) => {
      return CustomDialogActions.getCustomDialog(customDialogID, callback)(dispatch)
    },
    deleteCustomDialog: (customDialog, callback) => {
      CustomDialogActions.deleteCustomDialog(customDialog, callback)(dispatch)
    },
    getDefaultObjects: (buxCmd, args, callback) => {
      return CustomDialogActions.getDefaultObjects(buxCmd, args, callback)(dispatch)
    },
    getAssignedObjects: (searchProfile, callback) => {
      CustomDialogActions.getAssignedObjects(searchProfile, callback)(dispatch)
    },
    getIndexDefaultObjects: callback => {
      return CustomDialogActions.getIndexDefaultObjects(callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultCustomDialog)