import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const CREATE_GROUP_START = 'CREATE_GROUP_START'
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED'

export const GET_GROUP_START = 'GET_GROUP_START'
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS'
export const GET_GROUP_FAILED = 'GET_GROUP_FAILED'

export const DELETE_GROUP_START = 'DELETE_GROUP_START'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED'

export const UPDATE_GROUP_START = 'UPDATE_GROUP_START'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED'

export const GET_GROUPS_START = 'GET_GROUPS_START'
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS'
export const GET_GROUPS_FAILED = 'GET_GROUPS_FAILED'

export const NO_GROUPS_FOUND = 'NO_GROUPS_FOUND'

/**
 * @description Calls the rest api and creates a new group.
 * @param {String} cuser The userid which creates the new group.
 * @param {String} groupid The groupid of the new group.
 * @param {String} groupname The groupname of the new group.
 * @param {Function} callback The callback which is called when create group request was successful. Can be null.
 */
export function createGroup(cuser, groupid, groupname, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CREATE_GROUP_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/group`, {
        method: 'post',
        body: {
          BETAGRP: groupid,
          GRPNAME: groupname,
          CUSER: cuser,
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CREATE_GROUP_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: CREATE_GROUP_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.create_group_success', lang, groupid), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getGroups again to get the current result
          const searchGroupID = prefs[Preferences.USERMANAGEMENT_GROUPS_GROUPID]
          const searchGroupname = prefs[Preferences.USERMANAGEMENT_GROUPS_GROUPNAME]
          getGroups(searchGroupID, searchGroupname, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.create_group_error', lang, [groupid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CREATE_GROUP_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Call the rest api and get a group.
 * @param {String} groupid The group id search.
 * @param {Function} callback The callback which will be called when the group request was successfully. Can be null.
 */
export function getGroup(groupid, callback) {
  return dispatch => {
    /* Show loading-animation to block user-input */
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: GET_GROUP_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/group?BETAGRP=${encodeURIComponent(groupid)}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_GROUP_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_GROUP_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_group_error', lang, [groupid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_GROUP_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes a group.
 * @param {String} groupid The groupid used to delete the group.
 * @param {String} userid The current userid.
 * @param {Function} callback The callback which will be called when the delete group request was successful. Can be null.
 */
export function deleteGroup(groupid, userid, groupname, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: DELETE_GROUP_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/group${createQueryParamsForFetch({ BETAGRP: groupid, BETAUSER: userid })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DELETE_GROUP_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DELETE_GROUP_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.delete_group_success', lang, [groupid, groupname]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getGroups again to get the current result
          const searchGroupID = prefs[Preferences.USERMANAGEMENT_GROUPS_GROUPID]
          const searchGroupname = prefs[Preferences.USERMANAGEMENT_GROUPS_GROUPNAME]

          getGroups(searchGroupID, searchGroupname, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.delete_group_error', lang, [groupid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DELETE_GROUP_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a group.
 * @param {String} cuser The userid which updates the group.
 * @param {String} groupid The groupid to update the correct group.
 * @param {String} groupname The new group name.
 * @param {Function} callback The callback which will be called when the update group request was successful. Can be null.
 */
export function updateGroup(cuser, groupid, groupname, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: UPDATE_GROUP_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/group`, {
        method: 'put',
        body: {
          BETAGRP: groupid,
          GRPNAME: groupname,
          CUSER: cuser,
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: UPDATE_GROUP_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: UPDATE_GROUP_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.update_group_success', lang, groupid), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getGroups again to get the current result
          const searchGroupID = prefs[Preferences.USERMANAGEMENT_GROUPS_GROUPID]
          const searchGroupname = prefs[Preferences.USERMANAGEMENT_GROUPS_GROUPNAME]

          getGroups(searchGroupID, searchGroupname, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.update_group_error', lang, [groupid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPDATE_GROUP_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets groups.
 * @param {String} groupid The group id to search.
 * @param {String} groupname The groupname to search.
 * @param {Function} callback The callback which will be called when the get users request was successful. Can be null.
 */
export function getGroups(groupid, groupname, callback, keepPagination = false) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: GET_GROUPS_START })
    LoadingSpinnerActions.show()(dispatch)

    const startTime = Date.now()

    const queryParams = []
    if (groupid) { queryParams.push(`BETAGRP=${encodeURIComponent(groupid)}`) }
    if (groupname) { queryParams.push(`GRPNAME=${encodeURIComponent(groupname)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/groups?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // handle no data found here to dispatch NO_GROUPS_FOUND action
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_GROUPS_FOUND })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_GROUPS_FAILED })
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_GROUPS_SUCCESS, payload: jsondata.data, keepPagination })

          // call callback on success
          if (callback) {
            callback()
          }

          // wait a bit to show the user the loading animation
          const usedTime = Date.now() - startTime
          if (usedTime < 300) {
            setTimeout(() => {
              LoadingSpinnerActions.hide()(dispatch)
            }, 300 - usedTime)
          }
          else {
            LoadingSpinnerActions.hide()(dispatch)
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_groups_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_GROUPS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}