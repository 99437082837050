import * as Preferences from 'redux/general/Preferences'

// REST header keys for all search types
export const REST_HEADER_KEYS = {
  'ACTIVATION_DATE': 'SRCSUBD',
  'ACTIVATION_TIME': 'SRCSUBT',
  'AGNTNAME': 'AGNTNAME',
  'APPL': 'APPL',
  'CLASS': 'SRCCLAS',
  'CLIENT': 'CLNTNAME',
  'CREATIONDATE': 'CREATIONDATE',
  'DATACENT': 'DATACENT',
  'ENDDATE': 'ENDDATE',
  'ENDDT': 'ENDDT',
  'EXT': 'EXT',
  'FORM': 'FORM',
  'HIGHCC': 'JHIGHCC',
  'JOBERR': 'JOBERR',
  'JOBERROR': 'JOBERR',
  'JOBGROUP': 'JOBGROUP',
  'JOBID': 'JOBID',
  'JOBNAME': 'JOBNAME',
  'JOBRC': 'JOBRC',
  'JOBSTAT': 'JOBSTAT',
  'LGRARCH': 'LGRARCH',
  'LGRFFARC': 'LGRFFARC',
  'LGRFFDEL': 'LGRFFDEL',
  'LGRFFREL': 'LGRFFREL',
  'LGRONLNE': 'LGRONLNE',
  'LOGSOURCE': 'LOGSRC',
  'LTITLE': 'LTITLE',
  'LTOKEN': 'LTOKEN',
  'NETID': 'JNETID',
  'ORDDATE': 'ORDDATE',
  'ORDERID': 'ORDERID',
  'OWNER': 'OWNER',
  'READERDATE': 'SRCSUBDT',
  'RETURNCODE': 'JOBRC',
  'RTOKEN': 'RTOKEN',
  'RUNCOUNT': 'RUNCOUNT',
  'RUNNUMBER': 'RUNNUMB',
  'RUNTIME': 'RUNTIME',
  'RUNUSER': 'RUNUSER',
  'SERVICES': 'SERVICES',
  'SOURCE_VERSION': 'SRCVERS',
  'SRCJOBN': 'SRCJOBN',
  'SRCJOBI': 'SRCJOBI',
  'SRCPAGES': 'SRCPAGES',
  'SRCSUBU': 'SRCSUBU',
  'SRCSUBDT': 'SRCSUBDT',
  'STATUS': 'UC4STAT',
  'STRTDATE': 'STRTDATE',
  'STRTDT': 'STRTDT',
  'SUBAPPL': 'SUBAPPL',
  'SYSTEM': 'SYSNAME',
  'SYSTEMNAME': 'SYSTEMNAME',
  'UC4CPU': 'UC4CPU',
  'UC4RC': 'UC4RC',
  'UC4RRT': 'UC4RRT',
  'USER': 'SRCSUBU',
  'USR1NAM': 'USR1NAM',
  'USR1VAL': 'USR1VAL',
  'USR2NAM': 'USR2NAM',
  'USR2VAL': 'USR2VAL',
  'WORKFLOW': 'WORKFLOW',
  'WREPORT': 'WREPORT',
}

export const DEFAULT_TABLE_HEADER_KEYS = {
  'BRWCTM': [
    REST_HEADER_KEYS.JOBNAME,
    REST_HEADER_KEYS.ORDDATE,
    REST_HEADER_KEYS.STRTDATE,
    REST_HEADER_KEYS.ENDDATE,
    REST_HEADER_KEYS.JOBERR,
    REST_HEADER_KEYS.JOBRC,
    REST_HEADER_KEYS.DATACENT,
    REST_HEADER_KEYS.JOBGROUP,
    REST_HEADER_KEYS.ORDERID,
    REST_HEADER_KEYS.RUNCOUNT,
    REST_HEADER_KEYS.APPL,
    REST_HEADER_KEYS.SUBAPPL,
    REST_HEADER_KEYS.AGNTNAME,
    REST_HEADER_KEYS.RUNTIME
  ],
  'BRWSTB': [
    REST_HEADER_KEYS.JOBNAME,
    REST_HEADER_KEYS.SRCSUBDT,
    REST_HEADER_KEYS.STRTDATE,
    REST_HEADER_KEYS.ENDDATE,
    REST_HEADER_KEYS.JOBSTAT,
    REST_HEADER_KEYS.JOBERR,
    REST_HEADER_KEYS.JOBRC,
    REST_HEADER_KEYS.RUNUSER,
    REST_HEADER_KEYS.AGNTNAME,
    REST_HEADER_KEYS.SYSTEMNAME,
    REST_HEADER_KEYS.JOBGROUP,
    REST_HEADER_KEYS.SERVICES,
    REST_HEADER_KEYS.SOURCE_VERSION,
    REST_HEADER_KEYS.WORKFLOW,
    REST_HEADER_KEYS.USR1NAM,
    REST_HEADER_KEYS.USR1VAL,
    REST_HEADER_KEYS.USR2NAM,
    REST_HEADER_KEYS.USR2VAL
  ],
  'BRWSYSL': [
    REST_HEADER_KEYS.JOBNAME,
    REST_HEADER_KEYS.SYSTEM,
    REST_HEADER_KEYS.READERDATE,
    REST_HEADER_KEYS.JOBGROUP,
    REST_HEADER_KEYS.JOBID
  ],
  'BRWTAB': [
    REST_HEADER_KEYS.FORM,
    REST_HEADER_KEYS.EXT,
    REST_HEADER_KEYS.WREPORT,
    REST_HEADER_KEYS.SRCJOBN,
    REST_HEADER_KEYS.SRCPAGES,
    REST_HEADER_KEYS.OWNER,
    REST_HEADER_KEYS.SRCJOBI,
    REST_HEADER_KEYS.LTITLE,
    REST_HEADER_KEYS.SRCSUBU,
    REST_HEADER_KEYS.CREATIONDATE,
    REST_HEADER_KEYS.LGRFFDEL,
    REST_HEADER_KEYS.LGRFFARC,
    REST_HEADER_KEYS.LGRFFREL,
    REST_HEADER_KEYS.LGRARCH,
    REST_HEADER_KEYS.LGRONLNE,
    REST_HEADER_KEYS.LTOKEN,
    REST_HEADER_KEYS.RTOKEN
  ],
  'BRWUC4': [
    REST_HEADER_KEYS.SRCSUBDT,
    REST_HEADER_KEYS.STRTDT,
    REST_HEADER_KEYS.ENDDT,
    REST_HEADER_KEYS.JOBNAME,
    REST_HEADER_KEYS.JOBERROR,
    REST_HEADER_KEYS.STATUS,
    REST_HEADER_KEYS.UC4RC,
    REST_HEADER_KEYS.LOGSOURCE,
    REST_HEADER_KEYS.CLIENT,
    REST_HEADER_KEYS.RUNNUMBER,
    REST_HEADER_KEYS.SYSTEM,
    REST_HEADER_KEYS.JOBGROUP,
    REST_HEADER_KEYS.UC4CPU,
    REST_HEADER_KEYS.UC4RRT
  ],
  'BRWZOS': [
    REST_HEADER_KEYS.JOBID,
    REST_HEADER_KEYS.JOBNAME,
    REST_HEADER_KEYS.SRCSUBDT,
    REST_HEADER_KEYS.STRTDT,
    REST_HEADER_KEYS.ENDDT,
    REST_HEADER_KEYS.JOBERROR,
    REST_HEADER_KEYS.RETURNCODE,
    REST_HEADER_KEYS.HIGHCC,
    REST_HEADER_KEYS.NETID,
    REST_HEADER_KEYS.USER,
    REST_HEADER_KEYS.CLASS,
    REST_HEADER_KEYS.JOBGROUP,
    REST_HEADER_KEYS.ACTIVATION_DATE,
    REST_HEADER_KEYS.ACTIVATION_TIME
  ],
}

export const PREFERENCE_HEADERS_MAP = {
  'BRWCTM': Preferences.TABLE_SETTINGS_SEARCH_CONTROLM,
  'BRWSTB': Preferences.TABLE_SETTINGS_SEARCH_STONEBRANCH,
  'BRWSYSL': Preferences.TABLE_SETTINGS_SEARCH_SYSLOG,
  'BRWTAB': Preferences.TABLE_SETTINGS_STANDARDSELECTION,
  'BRWUC4': Preferences.TABLE_SETTINGS_SEARCH_UC4,
  'BRWZOS': Preferences.TABLE_SETTINGS_SEARCH_ZOS,
}