import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as AuthActions from 'redux/actions/AuthenticationActions'

const defaultState = []

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    // Snackbar actions
    case SnackbarActions.SNACKBAR_MULTIPLE_SHOW:
      return [...state, { id: Date.now(), content: action.payload.messageTypeObjs, customTitle: action.payload.customTitle }]
    case SnackbarActions.SNACKBAR_SHOW:
      return [...state, { id: Date.now(), content: [{ text: action.payload.message, type: action.payload.type }] }]
    case SnackbarActions.SNACKBAR_REMOVE:
      const newState = state.filter((obj) => obj.id !== action.payload.idToRemove)
      return newState
    case AuthActions.LOGOUT_SUCCESS: // Delete all snackbar notifications after logout
      return defaultState
    case AuthActions.LOGIN_SUCCESS: // Delete all snackbar notifications after login
      return defaultState
    default: return state
  }
}

export default reducer