import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as UrlUtils from 'utils/UrlUtils'
import * as UserUtils from 'utils/UserUtils'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as RecipientDocumentAssignmentActions from 'redux/actions/RecipientDocumentAssignmentActions'

class AssignmentRecipientDocument extends Component {

  defaultState = {
    recipientid: {
      value: '',
      errorkey: ''
    },
    form: {
      value: '',
      errorkey: ''
    },
    extension: {
      value: '',
      errorkey: ''
    },
    report: {
      value: '',
      errorkey: ''
    },
    dynamicReport: {
      value: '',
      errorkey: ''
    },
    activeAssignmentIndex: 0,
    activeDistributionViaIndex: 0,
    outputChannelId: {
      value: '',
      errorkey: ''
    },
    outputFormatId: {
      value: '',
      errorkey: ''
    },
    ppnId: {
      value: '',
      errorkey: ''
    },
    showRecipientSelectorDialog: false,
    showFormExtensionReportSelectorDialog: false,
    showOutputChannelSelectorDialog: false,
    showOutputFormatSelectorDialog: false,
    showPostProcessingNoteSelectorDialog: false,
  }

  keys = [
    { rest: 'RECI', ui: 'recipientid' },
    { rest: 'FORM', ui: 'form' },
    { rest: 'EXT', ui: 'extension' },
    { rest: 'REPORT', ui: 'report' },
    { rest: 'LTYPE', ui: 'activeAssignmentIndex' },
    { rest: 'BRWS', ui: 'activeDistributionViaIndex' },
    { rest: 'WREPORT', ui: 'dynamicReport' },
    { rest: 'DCR', ui: 'outputChannelId' },
    { rest: 'PCR', ui: 'outputFormatId' },
    { rest: 'PPN', ui: 'ppnId' },
  ]

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount() {
    if (window.location.href.indexOf('?') !== -1) {
      this.initFieldsFromUrl()
    }
    else {
      // initialize search fields
      const { preferences } = this.props

      if (preferences) {
        // folder id
        const recipientid = preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_RECIPIENTID] || ''
        // form
        const form = preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_FORM] || ''
        // extension
        const extension = preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_EXTENSION] || ''
        // report
        const report = preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_REPORT] || ''
        // dynamic reprot
        const dynamicReport = preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_DYNAMICREPORT] || ''

        // assigment index
        const assignment = preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_ASSIGNMENT]
        let activeAssignmentIndex = DefinitionUtils.RECIPIENT_DOC_ASSIGNMENTS.findIndex(element => element.key === DefinitionUtils.RECIPIENT_DOC_ASSIGNMENT_ANY)
        if (assignment) {
          activeAssignmentIndex = Math.max(DefinitionUtils.RECIPIENT_DOC_ASSIGNMENTS.findIndex(element => element.key === assignment), 0)
        }
        // distribute via index
        const distributeVia = preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_DISTRIBUTEVIA]
        let activeDistributionViaIndex = DefinitionUtils.getRecipientDistributions(true).findIndex(element => element.key === DefinitionUtils.RECIPIENT_DISTRIBUTION_ANY)
        if (distributeVia) {
          activeDistributionViaIndex = Math.max(DefinitionUtils.getRecipientDistributions(true).findIndex(element => element.key === distributeVia), 0)
        }
        // output channel
        const outputChannel = preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTCHANNEL] || ''
        // output format
        const outputFormat = preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTFORMAT] || ''
        // post processing note
        const ppn = preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_PPN] || ''

        this.setState({
          recipientid: { value: recipientid, errorkey: '' },
          form: { value: form, errorkey: '' },
          extension: { value: extension, errorkey: '' },
          report: { value: report, errorkey: '' },
          dynamicReport: { value: dynamicReport, errorkey: '' },
          activeAssignmentIndex: activeAssignmentIndex,
          activeDistributionViaIndex: activeDistributionViaIndex,
          outputChannelId: { value: outputChannel, errorkey: '' },
          outputFormatId: { value: outputFormat, errorkey: '' },
          ppnId: { value: ppn, errorkey: '' },
        })
      }
    }
  }

  /**
   * @description Fills the state with the values from the url and executes the search when the parameter is given.
   */
  initFieldsFromUrl = () => {
    const newValues = UrlUtils.getDataFromUrlParams(this.props.location.search, this.keys)
    this.setState({
      recipientid: {
        value: newValues.recipientid || '',
        errorkey: ''
      },
      form: {
        value: newValues.form || '',
        errorkey: ''
      },
      extension: {
        value: newValues.extension || '',
        errorkey: ''
      },
      report: {
        value: newValues.report || '',
        errorkey: ''
      },
      dynamicReport: {
        value: newValues.dynamicReport || '',
        errorkey: ''
      },
      activeAssignmentIndex: newValues.activeAssignmentIndex || 0,
      activeDistributionViaIndex: newValues.activeDistributionViaIndex || 0,
      outputChannelId: {
        value: newValues.outputChannelId || '',
        errorkey: ''
      },
      outputFormatId: {
        value: newValues.outputFormatId || '',
        errorkey: ''
      },
      ppnId: {
        value: newValues.ppnId || '',
        errorkey: ''
      },
    }, () => {
      if (UrlUtils.urlContainsExecute()) {
        this.search()
      }
    })
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  onOpenRecipientDefinitionSelector = () => {
    const callback = () => {
      this.setState({ showRecipientSelectorDialog: true })
    }
    this.props.getRecipientDefinitions(
      ['RECI', 'TITLE'],
      this.state.recipientid.value,
      callback
    )
  }

  /**
   * @description Requests the document definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  onOpenFormExtReportSelector = () => {
    const callback = () => {
      this.setState({ showFormExtensionReportSelectorDialog: true })
    }

    this.props.getDocumentDefinitions(
      ['FORM', 'EXT', 'REPORT'],
      this.state.form.value,
      this.state.extension.value,
      this.state.report.value,
      callback
    )
  }

  /**
   * @description Requests the outputchannel definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenOutputChannelSelector = () => {
    const callback = () => {
      this.setState({
        showOutputChannelSelectorDialog: true
      })
    }

    this.props.getOutputChannelDefinitions(
      ['DCR', 'DCRTITLE'],
      this.state.outputChannelId.value,
      callback)
  }

  /**
   * @description Requests the outputformat definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenOutputFormatSelector = () => {
    const callback = () => {
      this.setState({
        showOutputFormatSelectorDialog: true
      })
    }

    this.props.getOutputFormatDefinitions(
      ['PCR', 'PCRTITLE'],
      this.state.outputFormatId.value,
      callback)
  }

  /**
   * @description Requests the postprocessingnote definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenPostProcessingNoteSelector = () => {
    const callback = () => {
      this.setState({
        showPostProcessingNoteSelectorDialog: true
      })
    }

    this.props.getPostProcessingNotesDefinitions(
      ['PPN', 'PPNTITLE'],
      this.state.ppnId.value,
      callback)
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Handles the changes on inputfield.
   * @param {String} key Key for inputfield in state
   * @param {String} val New value of inputfield
   */
  handleInputChanged = (key, val, err) => {
    this.setState({
      [key]: {
        value: val,
        errorkey: err
      }
    })
  }

  /**
   * @description Handles the search event. Saves the search values in preferences
   * and call the rest api to search.
   * @param {Object} event The event
   */
  handleSubmitSearch = (event) => {
    event.preventDefault()
    this.search()
  }

  /**
   * @description Executes the search.
   */
  search = () => {
    const {
      recipientid, form, extension, report, dynamicReport, activeAssignmentIndex,
      activeDistributionViaIndex, outputChannelId, outputFormatId, ppnId,
    } = this.state
    // save search values in preferences
    const prefsToChange = {
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_RECIPIENTID]: recipientid.value,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_FORM]: form.value,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_EXTENSION]: extension.value,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_REPORT]: report.value,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_DYNAMICREPORT]: dynamicReport.value,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_ASSIGNMENT]: DefinitionUtils.RECIPIENT_DOC_ASSIGNMENTS[activeAssignmentIndex].key,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_DISTRIBUTEVIA]: DefinitionUtils.getRecipientDistributions(true)[activeDistributionViaIndex].key,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTCHANNEL]: outputChannelId.value,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTFORMAT]: outputFormatId.value,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_PPN]: ppnId.value,
    }

    this.props.changePrefs(prefsToChange)
    this.props.getRecipientDocumentAssignments(
      undefined,
      recipientid.value,
      form.value,
      extension.value,
      report.value,
      dynamicReport.value,
      DefinitionUtils.RECIPIENT_DOC_ASSIGNMENTS[activeAssignmentIndex].key,
      DefinitionUtils.getRecipientDistributions(true)[activeDistributionViaIndex].key,
      outputChannelId.value,
      outputFormatId.value,
      ppnId.value,
    )
    let specificUrl = 'recipient_document'
    if (UserUtils.isLOGX()) {
      specificUrl = 'recipient_log'
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/${specificUrl}${UrlUtils.createUrlParamsFromObject(this.state, this.keys)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Gets the translated assignment options.
   */
  getTranslatedAssignmentItems = () => {
    const items = []
    DefinitionUtils.RECIPIENT_DOC_ASSIGNMENTS.forEach(assigmentItem => {
      items.push(translate(assigmentItem.translationKey))
    })

    return items
  }

  /**
   * @description Gets the translated distribution via options.
   */
  getTranslatedDistributionItems = () => {
    const items = []
    DefinitionUtils.getRecipientDistributions(true).forEach(distributionItem => {
      items.push(translate(distributionItem.translationKey))
    })

    return items
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id } = this.props

    // recipient definitions
    let recipientDefinitions
    if (this.state.showRecipientSelectorDialog) {
      recipientDefinitions = this.props.selector.recipients
    }

    // document definitions
    let documentDefinitions
    if (this.state.showFormExtensionReportSelectorDialog) {
      documentDefinitions = this.props.selector.documents
    }

    // outputformat definitions
    let outputFormatDefinitions
    if (this.state.showOutputFormatSelectorDialog) {
      outputFormatDefinitions = this.props.selector.outputformats
    }

    // outputchannel definitions
    let outputChannelDefinitions
    if (this.state.showOutputChannelSelectorDialog) {
      outputChannelDefinitions = this.props.selector.outputchannels
    }

    // postprocessingnote definitions
    let postProcessingNoteDefinitions
    if (this.state.showPostProcessingNoteSelectorDialog) {
      postProcessingNoteDefinitions = this.props.selector.ppns
    }

    return (
      <>
        {this.state.showRecipientSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_recipientdefinition_selector_dialog`}
            onClose={() => { this.setState({ showRecipientSelectorDialog: false }) }}
            title={translate('definition.recipientdefinitions')}
            header={[
              translate('general.recipient'),
              translate('general.title'),
            ]}
            items={recipientDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newRecipient = recipientDefinitions.data[selectedRows][recipientDefinitions.header.indexOf('RECI')]
                this.setState({
                  recipientid: { value: newRecipient, errorkey: '' },
                  showRecipientSelectorDialog: false
                })
              } else {
                this.setState({
                  showRecipientDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showFormExtensionReportSelectorDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => {
              this.setState({
                showFormExtensionReportSelectorDialog: false
              })
            }}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report'),
            ]}
            items={documentDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newForm = documentDefinitions
                  .data[selectedRows][documentDefinitions.header.indexOf('FORM')]
                const newExt = documentDefinitions
                  .data[selectedRows][documentDefinitions.header.indexOf('EXT')]
                const newReport = documentDefinitions
                  .data[selectedRows][documentDefinitions.header.indexOf('REPORT')]
                this.setState({
                  form: { value: newForm, errorkey: '' },
                  extension: { value: newExt, errorkey: '' },
                  report: { value: newReport, errorkey: '' },
                })
              }
              /* Close dialog */
              this.setState({
                showFormExtensionReportSelectorDialog: false
              })
            }}
          />
        )}
        {this.state.showOutputChannelSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_outputchanneldefinition_selector_dialog`}
            onClose={() => {
              this.setState({
                showOutputChannelSelectorDialog: false
              })
            }}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('import.outputchannel'),
              translate('general.title')
            ]}
            items={outputChannelDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputchannel = outputChannelDefinitions.data[selectedRows][outputChannelDefinitions.header.indexOf('DCR')]
                this.setState({
                  outputChannelId: {
                    value: newOutputchannel,
                    errorkey: ''
                  },
                  showOutputChannelSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputChannelSelectorDialog: false
                })
              }
            }}
          />
        )}
        {this.state.showOutputFormatSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputformatdefinition_selector_dialog`}
            onClose={() => {
              this.setState({
                showOutputFormatSelectorDialog: false
              })
            }}
            title={translate('definition.outputformatdefinitions')}
            header={[
              translate('import.outputformat'),
              translate('general.title'),
            ]}
            items={outputFormatDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputformat = outputFormatDefinitions.data[selectedRows][outputFormatDefinitions.header.indexOf('PCR')]
                this.setState({
                  outputFormatId: {
                    value: newOutputformat,
                    errorkey: '',
                  },
                  showOutputFormatSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputFormatSelectorDialog: false
                })
              }
            }}
          />
        )}
        {this.state.showPostProcessingNoteSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_postprocessingnotedefinition_selector_dialog`}
            onClose={() => {
              this.setState({
                showPostProcessingNoteSelectorDialog: false
              })
            }}
            title={translate('definition.post_processing_note_definition')}
            header={[
              translate('definition.post_processing_note'),
              translate('general.title')
            ]}
            items={postProcessingNoteDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newPostprocessingNode = postProcessingNoteDefinitions.data[selectedRows][postProcessingNoteDefinitions.header.indexOf('PPN')]
                this.setState({
                  ppnId: {
                    value: newPostprocessingNode,
                    errorkey: '',
                  },
                  showPostProcessingNoteSelectorDialog: false
                })
              } else {
                this.setState({
                  showPostProcessingNoteSelectorDialog: false
                })
              }
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { recipientid, form, extension, report, dynamicReport } = this.state

    return (
      <Card
        title={translate('general.general')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_recipientid`}
              onInputChanged={(val, err) => {
                this.handleInputChanged('recipientid', val, err)
              }}
              value={recipientid.value}
              title={translate('recipient.reci_id')}
              error={recipientid.errorkey && translate(recipientid.errorkey)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenRecipientDefinitionSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_form`}
              onInputChanged={(val, err) => { this.handleInputChanged('form', val, err) }}
              value={form.value}
              title={translate('general.form')}
              error={form.errorkey && translate(form.errorkey)}
              maxLength={8}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFormExtReportSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_extension`}
              onInputChanged={(val, err) => {
                this.handleInputChanged('extension', val, err)
              }}
              value={extension.value}
              title={translate('general.extension')}
              error={extension.errorkey && translate(extension.errorkey)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFormExtReportSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_report`}
              onInputChanged={(val, err) => {
                this.handleInputChanged('report', val, err)
              }}
              value={report.value}
              title={translate('general.report')}
              error={report.errorkey && translate(report.errorkey)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFormExtReportSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_dynamicreport`}
              title={translate('assignment.folder_document.dynamic_report')}
              maxLength={16}
              value={dynamicReport.value}
              onInputChanged={(val, err) => {
                this.handleInputChanged('dynamicReport', val, err)
              }}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the additional card.
   */
  renderAdditionalCard = () => {
    const { id } = this.props
    const { activeAssignmentIndex, activeDistributionViaIndex, outputChannelId, outputFormatId, ppnId } = this.state

    const distributionItems = this.getTranslatedDistributionItems()
    const assigmentItems = this.getTranslatedAssignmentItems()
    return (
      <Card
        title={translate('general.additional')}>
        <Row>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_assignment`}
              items={assigmentItems}
              activeIndex={activeAssignmentIndex}
              onChange={activeAssignmentIndex => { this.setState({ activeAssignmentIndex }) }}
              title={translate('general.assignment')}
            />
          </Column>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_distributionvia`}
              items={distributionItems}
              activeIndex={activeDistributionViaIndex}
              onChange={activeDistributionViaIndex => { this.setState({ activeDistributionViaIndex }) }}
              title={translate('recipient.distribution_via')}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={6}>
            <Input
              id={`${id}_outputchannel`}
              value={outputChannelId.value}
              title={translate('recipient.output_channel')}
              maxLength={16}
              onInputChanged={(val, err) => {
                this.handleInputChanged('outputChannelId', val, err)
              }}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputChannelSelector()
              }}
            />
          </Column>
          <Column
            colMD={6}>
            <Input
              id={`${id}_outputformat`}
              value={outputFormatId.value}
              title={translate('recipient.output_format')}
              maxLength={16}
              onInputChanged={(val, err) => {
                this.handleInputChanged('outputFormatId', val, err)
              }}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputFormatSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={6}>
            <Input
              id={`${id}_postprocessing_note`}
              value={ppnId.value}
              title={translate('recipient.postprocessing_note')}
              maxLength={16}
              onInputChanged={value => this.handleInputChanged('ppnId', value)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenPostProcessingNoteSelector()
              }}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {/* Render open selector dialogs */}
        {this.renderSelectorDialogs()}
        {/* general card */}
        {this.renderGeneralCard()}
        {/* additional card */}
        {this.renderAdditionalCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          className='bux_iconResteBtn'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {/* main */}
        {this.renderMain()}
        {/* footer */}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    selector: state.selector,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getRecipientDefinitions: (fields, recipient, callback) => {
      ModalSelectorActions.getRecipientDefinitions(
        fields,
        undefined, // rtype
        undefined, // distribution type
        recipient,
        undefined, // preRecipient
        undefined, // owner
        undefined, // title
        undefined, // outputchannel
        undefined, // outputformat
        undefined, // ppn
        callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getOutputFormatDefinitions: (fields, outputFormat, callback) => {
      ModalSelectorActions.getOutputFormatDefinitions(
        fields,
        outputFormat,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getPostProcessingNotesDefinitions: (fields, ppn, callback) => {
      ModalSelectorActions.getPPNDefinitions(
        fields,
        ppn,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getRecipientDocumentAssignments: (fields, recipientId, form, extension, report, dynamicReport, assignment, distributionVia, outputChannel, outputFormat, ppn, callback) => {
      RecipientDocumentAssignmentActions.getRecipientDocumentAssignments(fields, recipientId, form,
        extension, report, dynamicReport, assignment, distributionVia, outputChannel, outputFormat, ppn, callback)(dispatch)
    }
  }
}

AssignmentRecipientDocument.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssignmentRecipientDocument))