import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'

// components
import {
  Button,
  Column, DataTable, Dropdown, Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row, Switch, Tab, TableButton, Tabs
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'

import * as SearchArgumentActions from 'redux/actions/SearchArgumentDefinitionActions'

import './ModifySearchArgumentDialog.scss'

class ModifySearchArgumentDialog extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    generalTab: {
      owner: '',
      title: '',
      onPage: 1,
      fromColumn: 0,
      toColumn: 0,
      fromRow: 0,
      toRow: 0
    },
    valuesTab: {
      searchArgumentValue: {
        value: '',
        errorkey: ''
      },
      saveSearchArgumentValueasUTF8: 1,
      searchArgumentValueType: 0,
      columnPositionForSearchPattern: 0,
      caseSensitive: 1,
      comparisonOperator: 0,
    }
  }

  ownerInput = React.createRef()
  inputRef = React.createRef()

  /**
   * @description Sets the intial focus.
   */
  componentDidMount = () => {
    const { searchArgument } = this.props

    this.setState({
      generalTab: {
        owner: searchArgument[0].OWNER,
        title: searchArgument[0].SASTITLE,
        onPage: searchArgument[0].SASPNUM,
        fromColumn: searchArgument[0].SASFCOL,
        toColumn: searchArgument[0].SASTCOL,
        fromRow: searchArgument[0].SASFROW,
        toRow: searchArgument[0].SASTROW
      }
    })
    this.ownerInput.current.focus()
  }

  /**
   * @description Handles the changes in general tab.
   * @param {String} key The key of the input in state.
   * @param {*} val The new value.
   */
  handleChangeGeneralTab = (key, val) => {
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        [key]: val
      }
    })
  }

  /**
   * @description Handles the changes in values tab.
   * @param {String} key The key of the input in state.
   * @param {*} val The new value.
   */
  handleChangeValuesTab = (key, val) => {
    this.setState({
      valuesTab: {
        ...this.state.valuesTab,
        [key]: val
      }
    })
  }

  /**
   * @description Handles the changes of search argument in values tab.
   * @param {String} val The new value.
   */
  handleChangeSearchArgumentValue = (val, errorkey) => {
    this.setState({
      valuesTab: {
        ...this.state.valuesTab,
        searchArgumentValue: {
          value: val,
          errorkey: errorkey
        }
      }
    })
  }

  /**
   * @description Validates the search argument value.
   * @returns {Object} If validation failed new object with error message for state otherwise empty object.
   */
  validateSearchArgumentValue = () => {
    const { valuesTab } = this.state
    if (valuesTab.searchArgumentValue.value !== '') {
      return {}
    }
    return {
      searchArgumentValue: {
        ...this.state.valuesTab.searchArgumentValue,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Adds a new search argument with the current input.
   */
  onAddSearchArgumentValue = () => {
    const { searchArgument, createSearchArgumentValue } = this.props
    const { valuesTab } = this.state
    // resets the input fields
    const callback = () => {
      this.setState({
        valuesTab: {
          searchArgumentValue: {
            value: '',
            errorkey: ''
          },
          saveSearchArgumentValueasUTF8: 1,
          searchArgumentValueType: 0,
          columnPositionForSearchPattern: 0,
          caseSensitive: 1,
          comparisonOperator: 0,
        }
      }, () => this.inputRef.current.focus())
    }
    if (valuesTab.searchArgumentValue.value === '') {
      this.setState({
        valuesTab: {
          ...this.state.valuesTab,
          ...this.validateSearchArgumentValue()
        }
      }, () => this.inputRef.current.focus())
    } else {
      const searchArgumentValue = {
        SASID: searchArgument[0].SASID,
        FORM: searchArgument[0].FORM,
        EXTENSION: searchArgument[0].EXTENSION,
        REPORT: searchArgument[0].REPORT,
        SAASARG: valuesTab.searchArgumentValue.value,
        SAAUTF8: valuesTab.saveSearchArgumentValueasUTF8 === 0,
        PROCESS: 'ADDSAS'
      }
      if (valuesTab.searchArgumentValueType === 0) {
        searchArgumentValue.SAANOCS = valuesTab.caseSensitive === 0 ? 'YES' : 'NO'
        searchArgumentValue.SAATYPE = DefinitionUtils.SEARCHARGUMENT_COMPARISON_OPERATOR.filter((_, i) => i === valuesTab.comparisonOperator)[0].key
      } else if (valuesTab.searchArgumentValueType === 1) {
        searchArgumentValue.SAANOCS = 'MASK'
        searchArgumentValue.SAACPAMM = valuesTab.columnPositionForSearchPattern === 0 ? 'SS' : 'ES'
      } else if (valuesTab.searchArgumentValueType === 2) {
        searchArgumentValue.SAANOCS = 'TLE'
      }
      createSearchArgumentValue(searchArgumentValue, callback)
    }
  }

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { searchArgument, modifySearchArgument, onClose } = this.props
    const { generalTab } = this.state
    const searchArgumentToModify = {
      SASID: searchArgument[0].SASID,
      FORM: searchArgument[0].FORM,
      EXTENSION: searchArgument[0].EXTENSION,
      REPORT: searchArgument[0].REPORT,
      SASTYPE: searchArgument[0].SASTYPE,
      OWNER: generalTab.owner,
      SASTITLE: generalTab.title,
    }
    if (searchArgument[0].SASTYPE === 'VALUE') {
      searchArgumentToModify.SASFCOL = generalTab.fromColumn
      searchArgumentToModify.SASTCOL = generalTab.toColumn
      searchArgumentToModify.SASFROW = generalTab.fromRow
      searchArgumentToModify.SASTROW = generalTab.toRow
    }
    else if (searchArgument[0].SASTYPE === 'PAGE') {
      searchArgumentToModify.SASPNUM = generalTab.onPage
    }
    modifySearchArgument(searchArgumentToModify, onClose)
  }

  /**
   * @description Renderes the header.
   */
  renderHeader = () => {
    const { id, searchArgument, datemask } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.searchargument_id'), value: searchArgument[0].SASID },
          { label: translate('general.form'), value: searchArgument[0].FORM },
          { label: translate('general.extension'), value: searchArgument[0].EXTENSION },
          { label: translate('general.report'), value: searchArgument[0].REPORT },
          { label: translate('general.type'), value: translate(DefinitionUtils.SEARCH_ARGUMENT_TYPES.filter(d => d.key === searchArgument[0].SASTYPE)[0].translationKey) },
          { label: translate('general.last_changed'), value: `${DateUtils.getDate(datemask, searchArgument[0].CDATE, searchArgument[0].CTIME.substring(0, 8))} ${translate('general.by')} ${searchArgument[0].CUSER}` },
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { id, searchArgument } = this.props
    const { generalTab: { owner, title, fromColumn, toColumn, fromRow, toRow, onPage } } = this.state
    return (
      <>
        <Row>
          <Column colMD={4}>
            <Input
              id={`${id}_searchargument_owner`}
              value={owner}
              ref={this.ownerInput}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={value => this.handleChangeGeneralTab('owner', value)}
            />
          </Column>
          <Column colMD={8}>
            <Input
              id={`${id}_searchargument_title`}
              value={title}
              title={translate('general.title')}
              maxLength={68}
              onInputChanged={value => this.handleChangeGeneralTab('title', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_options`}>{translate('general.options')}</label>
          </Column>
        </Row>
        {
          searchArgument[0].SASTYPE === 'VALUE'
            ? <Row>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_searchargument_fromcolumn`}
                  title={translate('general.from_column')}
                  onChange={val => this.handleChangeGeneralTab('fromColumn', val)}
                  value={fromColumn}
                  steps={1}
                  min={0}
                  max={32767}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_searchargument_tocolumn`}
                  title={translate('general.to_column')}
                  onChange={val => this.handleChangeGeneralTab('toColumn', val)}
                  value={toColumn}
                  steps={1}
                  min={0}
                  max={32767}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_searchargument_fromrow`}
                  title={translate('general.from_line')}
                  onChange={val => this.handleChangeGeneralTab('fromRow', val)}
                  value={fromRow}
                  steps={1}
                  min={0}
                  max={32767}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_searchargument_torow`}
                  title={translate('general.to_line')}
                  onChange={val => this.handleChangeGeneralTab('toRow', val)}
                  value={toRow}
                  steps={1}
                  min={0}
                  max={32767}
                />
              </Column>
            </Row>
            : <Row>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_searchargument_onpage`}
                  title={translate('definition.search_argument_on_page')}
                  onChange={val => this.handleChangeGeneralTab('onPage', val)}
                  value={onPage}
                  steps={1}
                  min={1}
                  max={9999999}
                />
              </Column>
            </Row>
        }
      </>
    )
  }

  /**
   * @description Get the header of the table in values tab.
   * @returns {Array} The header of the table.
   */
  getHeaders = () => {
    return [
      translate('general.operator'),
      translate('general.type'),
      translate('general.case_sensitive'),
      translate('general.utf8'),
      translate('definition.searchargument_searchargument_value'),
      translate('general.column_position'),
      translate('general.last_changed')
    ]
  }

  /**
   * @description Returns "Yes" or "No" if the type is "Text" (values "YES" and "NO" stands for text).
   * @param {String} input The type.
   * @returns {String} The translated case.
   */
  getTranslatedType = input => {
    switch (input) {
      case 'YES':
        return translate('general.text')
      case 'NO':
        return translate('general.text')
      case 'MASK':
        return translate('general.pattern')
      case 'TLE':
        return translate('general.tle')
      default:
        return ''
    }
  }

  /**
   * @description Returns "Yes" or "No" if the type is "Text" (values "YES" and "NO" stands for text).
   * @param {String} input The type.
   * @returns {String} The translated case.
   */
  getTranslatedCase = input => {
    switch (input) {
      case 'YES':
        return translate('general.yes')
      case 'NO':
        return translate('general.no')
      default:
        return ''
    }
  }

  /**
   * @description Gets the translated column position.
   * @param {String} input The untranslated column position.
   * @returns {String} The translated column position.
   */
  getTranslatedColumnPosition = input => {
    switch (input) {
      case 'SS':
        return translate('general.searchpattern_start')
      case 'ES':
        return translate('general.searchpattern_end')
      default:
        return ''
    }
  }

  /**
   * @description Gets the data for the table.
   * @returns {Array} The data for the table.
   */
  getData = () => {
    const { searchArgument, datemask } = this.props
    let buffer = []
    // filters first entry, because we want to get the search argument values, the first entry is the search argument
    let values = [...searchArgument.filter((_, i) => i !== 0)]
    values.forEach(d => {
      let bufferLine = []
      const comparisonOperator = d.SAATYPE !== ''
        ? translate(DefinitionUtils.SEARCHARGUMENT_COMPARISON_OPERATOR.filter(compOperator => compOperator.key === d.SAATYPE)[0].translationKey)
        : ''
      const type = this.getTranslatedType(d.SAANOCS)
      const caseSensitive = this.getTranslatedCase(d.SAANOCS)
      const utf8 = d.SAAUTF8 ? translate('general.yes') : translate('general.no')
      const searchArgValue = d.SAASARG
      const columnPosition = this.getTranslatedColumnPosition(d.SAACPAMM)
      const lastChanged = DateUtils.getDate(datemask, d.CDATE, d.CTIME.substring(0, 8))
      bufferLine.push(comparisonOperator, type, caseSensitive, utf8, searchArgValue, columnPosition, lastChanged)
      buffer.push(bufferLine)
    })
    return buffer
  }

  /**
   * @description Deletes the search argument of a specific position.
   * @param {Number} index The index of the search argument to delete.
   */
  onDeleteSearchArgumentValue = index => {
    const { searchArgument, deleteSearchArgumentValue } = this.props
    const searchArgumentValue = {
      SASID: searchArgument[index + 1].SASID,
      FORM: searchArgument[index + 1].FORM,
      EXT: searchArgument[index + 1].EXTENSION,
      REPORT: searchArgument[index + 1].REPORT,
      SAASARG: searchArgument[index + 1].SAASARG,
    }
    deleteSearchArgumentValue(searchArgumentValue)
  }

  /**
   * @description Reuses a search argument of a specific position.
   * @param {Number} index The index of the search argument to reuse.
   */
  onReuseSearchArgumentValue = index => {
    const { searchArgument } = this.props
    this.setState({
      valuesTab: {
        searchArgumentValue: {
          value: searchArgument[index + 1].SAASARG,
          errorkey: ''
        },
        saveSearchArgumentValueasUTF8: searchArgument[index + 1].SAAUTF8 ? 0 : 1,
        searchArgumentValueType: { YES: 0, NO: 0, MASK: 1, TLE: 2 }[searchArgument[index + 1].SAANOCS],
        // SAANOCS could have one of this following values: 'YES', 'NO', 'TLE', 'MASK'
        caseSensitive: searchArgument[index + 1].SAANOCS === 'YES' ? 0 : 1,
        comparisonOperator: ['YES', 'NO'].includes(searchArgument[index + 1].SAANOCS)
          ? Math.max(DefinitionUtils.SEARCHARGUMENT_COMPARISON_OPERATOR.findIndex(el => el.key === searchArgument[index + 1].SAATYPE), 0)
          : 0,
        columnPositionForSearchPattern: searchArgument[index + 1].SAANOCS === 'MASK'
          ? searchArgument[index + 1].SAACPAMM === 'SS'
            ? 0
            : 1
          : 0
      }
    })
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButton_reusetext_${rowIndex}`}
        iconName={'reuse_text'}
        title={translate('general.reuse')}
        onClick={() => this.onReuseSearchArgumentValue(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButton_delete_${rowIndex}`}
        iconName={'delete'}
        title={translate('general.delete')}
        onClick={() => this.onDeleteSearchArgumentValue(rowIndex)}
      />
    ]
  }

  /**
   * @description Renders the values tab.
   */
  renderValuesTab = () => {
    const { id, lang, datemask } = this.props
    const { valuesTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={12}>
            <label id={`${id}_add_searchargument_value`}>{translate('definition.searchargument_add_searchargument_value')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={8}>
            <Input
              id={`${id}_value`}
              value={valuesTab.searchArgumentValue.value}
              error={valuesTab.searchArgumentValue.errorkey && translate(valuesTab.searchArgumentValue.errorkey)}
              title={translate('definition.searchargument_searchargument_value')}
              maxLength={128}
              onInputChanged={(value, error) => this.handleChangeSearchArgumentValue(value, error)}
              ref={this.inputRef}
              required={`${translate('general.required_field')}`}
              onBlur={() => this.setState({ valuesTab: { ...this.state.valuesTab, ...this.validateSearchArgumentValue() } })}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_save_searchargument_value_as_utf8`}
              title={translate('definition.searchargument_save_searchargument_value_as_utf8')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChangeValuesTab('saveSearchArgumentValueasUTF8', index)}
              activeIndex={valuesTab.saveSearchArgumentValueasUTF8}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_value_type`}
              title={translate('definition.searchargument_value_type')}
              items={[
                translate('general.text'),
                translate('general.searchpattern'),
                translate('definition.searchargument_tle_name')
              ]}
              onClick={index => this.handleChangeValuesTab('searchArgumentValueType', index)}
              activeIndex={valuesTab.searchArgumentValueType}
              maxPerRow={3}
            />
          </Column>
          {this.renderSearchArgumentValueTypeContent(valuesTab.searchArgumentValueType)}
        </Row>
        <Row>
          <Column colMD={3} offsetMD={9} className={'bux_flex_row_end'}>
            <Button
              id={`${id}_add_searchargument_value`}
              text={translate('definition.searchargument_add_searchargument_value')}
              onClick={() => this.onAddSearchArgumentValue()}
              className={'bux_large_button'}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <label id={`${id}_attached_search_argument_values`}>{translate('definition.searchargument_attached_searchargument_values')}</label>
          </Column>
        </Row>
        <Row className={'bux_datalist_searchargument_values_container_row'}>
          <Column colMD={12}>
            <div className={'bux_datalist_searchargument_values_container'}>
              <DataTable
                id={`${id}_1`}
                header={this.getHeaders()}
                data={this.getData()}
                columnSortDefs={['string', 'string', 'string', 'string', 'string', 'string', 'date-time']}
                createTableRowAction={index => this.onReuseSearchArgumentValue(index)}
                createActionButtons={this.createActionButtons}
                menu={false}
                language={lang}
                datemask={datemask}
                translate={key => translate(key)}
              />
            </div>
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Gets the translated comparison operator items.
   * @returns {Array} The translated comparison operator items.
   */
  getComparionOperatorItems = () => {
    return DefinitionUtils.SEARCHARGUMENT_COMPARISON_OPERATOR.map(d => translate(d.translationKey))
  }

  /**
   * @description Renders the value type content.
   * @param {Number} index The index of the value type.
   */
  renderSearchArgumentValueTypeContent = index => {
    const { id } = this.props
    const { valuesTab } = this.state
    switch (index) {
      case 0:
        return (
          <>
            <Column colMD={3}>
              <Switch
                id={`${id}_searchargument_case_sensitive`}
                title={translate('definition.searchargument_case_sensitive')}
                items={[translate('general.yes'), translate('general.no')]}
                onClick={index => this.handleChangeValuesTab('caseSensitive', index)}
                activeIndex={valuesTab.caseSensitive}
              />
            </Column>
            <Column colMD={3}>
              <Dropdown
                id={`${id}_comparison_operator`}
                items={this.getComparionOperatorItems()}
                onChange={value => this.handleChangeValuesTab('comparisonOperator', value)}
                activeIndex={valuesTab.comparisonOperator}
                title={translate('definition.searchargument_comparison_operator')}
              />
            </Column>
          </>
        )
      case 1:
        return (
          <Column colMD={6}>
            <Switch
              id={`${id}_columnposition_for_searchpattern`}
              title={translate('definition.searchargument_columnposition_for_searchpattern')}
              items={[translate('general.searchpattern_start'), translate('general.searchpattern_end')]}
              onClick={index => this.handleChangeValuesTab('columnPositionForSearchPattern', index)}
              activeIndex={valuesTab.columnPositionForSearchPattern}
            />
          </Column>
        )
      case 2:
        return <></>
      default: return <></>
    }
  }

  render = () => {
    const { id, onClose, searchArgument } = this.props
    return (
      <Modal onClose={onClose} id={'modify_searchargument_dialog'} className={'bux_UserProfileModal'}>
        <Header
          id={`${id}_modalheader`}
          title={translate('definition.modify_searchargument_title')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main
          id={id}>
          <Tabs id={id} disabledTabs={searchArgument[0].SASTYPE === 'VALUE' ? [] : [1]}>
            <Tab title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('general.values')}>
              {
                searchArgument[0].SASTYPE === 'VALUE'
                  ? this.renderValuesTab()
                  : <></>
              }
            </Tab>
          </Tabs>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchArgument: state.definitions.searcharguments.searchArgument,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createSearchArgumentValue: (searchArgumentValue, callback) => {
      SearchArgumentActions.createSearchArgumentValue(searchArgumentValue, callback)(dispatch)
    },
    deleteSearchArgumentValue: (searchArgumentValue, callback) => {
      SearchArgumentActions.deleteSearchArgumentValue(searchArgumentValue, callback)(dispatch)
    },
    modifySearchArgument: (searchArgument, callback) => {
      SearchArgumentActions.modifySearchArgument(searchArgument, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifySearchArgumentDialog)