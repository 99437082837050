import { Component } from 'react'
import { connect } from 'react-redux'

import {
  Button, Card, Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row, Switch
} from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

const { Modal, Main, Header, Footer } = ModalComponent

import { translate } from 'language/Language'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import { requeueOutputQueue } from 'redux/actions/OutputQueueActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'

class RequeueOutputQueueDialog extends Component {

  state = {
    newPriority: 0,
    whichOutputChannel: 0,
    showOutputChannelSelectorDialog: false,
    outputChannel: {
      value: '',
      error: ''
    }
  }

  outputChannelTypes = () => ['B', 'A', 'C']

  headerData = header => this.props.outputQueue.header.indexOf(header)

  handleOutputChannelInput = (value, error) => {
    this.setState({
      outputChannel: {
        ...this.state.outputChannel,
        value,
        error
      }
    })
  }

  validateOutputChannel = () => {
    if (this.state.whichOutputChannel === 2) {
      if (this.state.outputChannel.value !== '') {
        return {}
      }
      return {
        outputChannel: {
          ...this.state.outputChannel,
          error: translate('general.input_required')
        }
      }
    }
    return {}
  }

  /**
  * @description: Loads the outputchannel-definitions and show them in a modal-selector
  */
  openOutputChannelSelector = () => {
    const { getOutputChannelDefinitions } = this.props

    getOutputChannelDefinitions(
      ['DCR', 'DCRTITLE'],
      this.state.outputChannel.value,
      () => this.setState({ showOutputChannelSelectorDialog: true }))
  }

  renderOutputChannelSelector = () => {
    const { id, selector } = this.props
    const { showOutputChannelSelectorDialog } = this.state

    return (
      <>
        {showOutputChannelSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputchannelselector_dialog`}
            onClose={() => {
              this.setState({
                showOutputChannelSelectorDialog: false
              })
            }}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('definition.output_channel_id'),
              translate('general.title'),
            ]}
            items={selector.outputchannels.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputChannel = selector.outputchannels.data[selectedRows][selector.outputchannels.header.indexOf('DCR')]
                this.setState({
                  outputChannel: { value: newOutputChannel, error: '' },
                  showOutputChannelSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputChannelSelectorDialog: false
                })
              }
            }}
          />
        )}
      </>
    )
  }

  handleSave = () => {
    const { requeueOutputQueue, outputQueue, onClose } = this.props
    const { newPriority, whichOutputChannel } = this.state
    const btoken = outputQueue.data[0][this.headerData('BTOKEN')]
    let outputChannel = ''
    if (whichOutputChannel === 0) {
      outputChannel = outputQueue.data[0][this.headerData('DCR')]
    } else if (whichOutputChannel === 1) {
      outputChannel = this.state.outputChannel.value !== ''
        ? this.state.outputChannel.value
        : outputQueue.data[0][this.headerData('DCR')]
    } else {
      outputChannel = this.state.outputChannel.value
    }

    requeueOutputQueue(btoken, outputChannel, newPriority, this.outputChannelTypes()[whichOutputChannel], onClose)
  }

  getOutputChannelTitle = () => this.state.whichOutputChannel === 1
    ? translate('queue.output_channel_if_not_specified_or_inactive')
    : translate('recipient.output_channel')

  /**
   * @description Renders the header
   */
  renderHeader = () => {
    const { id, datemask, outputQueue } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.output_channel_id'), value: outputQueue.data[0][this.headerData('DCR')] },
          { label: translate('general.request_queued'), value: DateUtils.getDate(datemask, outputQueue.data[0][this.headerData('BDATE')], outputQueue.data[0][this.headerData('BTIME')].substring(0, 8)) },
          { label: translate('general.status'), value: outputQueue.data[0][this.headerData('BSTATUS')] },
          { label: translate('general.btoken'), value: outputQueue.data[0][this.headerData('BTOKEN')] },
          { label: translate('database.return_code'), value: outputQueue.data[0][this.headerData('PRTRC')] },
          { label: translate('general.form'), value: outputQueue.data[0][this.headerData('FORM')] },
          { label: translate('general.extension'), value: outputQueue.data[0][this.headerData('EXT')] },
          { label: translate('general.report'), value: outputQueue.data[0][this.headerData('REPORT')] },
          { label: translate('recipient.reci_id'), value: outputQueue.data[0][this.headerData('RECI')] },
          { label: translate('queue.priority'), value: outputQueue.data[0][this.headerData('PRTPRIO')] },
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description Render the main section
   */
  renderMain = () => {
    const { newPriority, whichOutputChannel, outputChannel } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_new_priority`}
              title={translate('general.new_priority')}
              onChange={val => this.setState({ newPriority: val })}
              value={newPriority}
              steps={1}
              min={0}
              max={15}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_which_output_channel_to_use`}
              title={translate('queue.which_output_channel_to_use')}
              items={[
                translate('general.current'),
                translate('general.alternate'),
                translate('general.new')
              ]}
              onClick={(index) => this.setState({ whichOutputChannel: index }, () => {
                if (this.state.whichOutputChannel !== 2) {
                  this.setState({ outputChannel: { value: this.state.outputChannel.value, error: '' } })
                }
              })}
              activeIndex={whichOutputChannel}
              maxPerRow={3}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_output_channel`}
              value={outputChannel.value}
              error={outputChannel.error}
              title={this.getOutputChannelTitle()}
              maxLength={16}
              onInputChanged={(value, error) => this.handleOutputChannelInput(value, error)}
              addon={{
                iconName: 'list',
                onClick: () => this.openOutputChannelSelector()
              }}
              disabled={whichOutputChannel === 0}
              required={whichOutputChannel === 2 ? `${translate('general.required_field')}` : false}
              onBlur={() => whichOutputChannel === 2 && this.setState({ ...this.validateOutputChannel() })}
            />
          </Column>
        </Row>
      </>
    )
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={id}
          title={translate('queue.requeue_output_queue_request')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main id={`${id}_requeue_output_queue`}>
          {this.renderOutputChannelSelector()}
          <Card>
            {this.renderMain()}
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_confirm`}
            text={translate('general.confirm')}
            onClick={this.handleSave}
            primary
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    outputQueue: state.queue.output.outputDetails,
    outputQueues: state.queue.output.outputs,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requeueOutputQueue: (outputQueueID, outputChannelID, priority, whichOutputChannel, callback) => {
      requeueOutputQueue(outputQueueID, outputChannelID, priority, whichOutputChannel, callback)(dispatch)
    },
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequeueOutputQueueDialog)