import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as Utils from 'utils/Utils'

// components
import {
  Button,
  Column, Dropdown, Input,
  Modal as ModalComponent, NumericSpinner, RelationAssignments, Row,
  Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as SearchArgumentActions from 'redux/actions/SearchArgumentDefinitionActions'

import './CopySearchArgumentDialog.scss'

class CopySearchArgumentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    searchArgumentID: {
      value: this.props.searchArgument[0].SASID,
      errorkey: ''
    },
    activeTypeIndex: this.props.searchArgument[0].SASTYPE === 'VALUE' ? 0 : 1,
    form: {
      value: this.props.searchArgument[0].FORM,
      errorkey: ''
    },
    extension: {
      value: this.props.searchArgument[0].EXTENSION,
      errorkey: ''
    },
    report: {
      value: this.props.searchArgument[0].REPORT,
      errorkey: ''
    },
    owner: {
      value: this.props.searchArgument[0].OWNER,
      errorkey: ''
    },
    title: {
      value: this.props.searchArgument[0].SASTITLE,
      errorkey: ''
    },
    onPage: this.props.searchArgument[0].SASPNUM === 0 ? 1 : this.props.searchArgument[0].SASPNUM,
    fromColumn: this.props.searchArgument[0].SASFCOL,
    toColumn: this.props.searchArgument[0].SASTCOL,
    fromRow: this.props.searchArgument[0].SASFROW,
    toRow: this.props.searchArgument[0].SASTROW,
    available: {
      sortedCol: 0,
      data: []
    },
    toCopy: {
      sortedCol: 0,
      data: []
    },
    selectedIds: [],
    draggingId: null
  }

  searchArgumentIdInput = React.createRef()
  searchArgumentReportInput = React.createRef()

  /**
   * @description Sets the initial focus and intializes the data.
   */
  componentDidMount() {
    this.searchArgumentIdInput.current.focus()
    window.addEventListener('click', this.onWindowClick)
    this.setState({
      available: {
        sortedCol: 0,
        data: this.getAvailableData()
      }
    })
  }

  /**
   * @description Unselects all items.
   * @param {Function} callback The callback which is called after unselect all items.
   */
  unselectAll = callback => {
    const internalCallback = () => {
      if (callback) {
        callback()
      }
    }

    this.setState({
      selectedIds: []
    }, () => internalCallback())
  }

  /**
   * @description Unselects all items if the user clicked on the screen.
   * @param {Object} event The mouse event.
   */
  onWindowClick = (event) => {
    if (event.defaultPrevented) {
      return
    }

    this.unselectAll()
  }

  /**
   * @description Handles the input changes of the input fields.
   * @param {String} tab The tab which is changed.
   * @param {String} id The id the input field.
   * @param {String} value The new value.
   * @param {String} errorkey The new errorkey.
   */
  handleInputChanged = (tab, id, value, errorkey) => {
    if (tab === 'generalTab') {
      this.setState({
        [id]: {
          value: value,
          errorkey: errorkey,
        }
      })
    } else if (tab === 'generalOptionsTab') {
      this.setState({
        [id]: value
      })
    }
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} tab The tab which is changed.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (tab, key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInputChanged(tab, key, value, error)
  }

  /**
   * @description Validates the id.
   * @returns {Object} If validation failed new object with error message for state otherwise empty object.
   */
  validateID = () => {
    const { searchArgumentID } = this.state
    if (searchArgumentID.value === '') {
      return {
        searchArgumentID: {
          ...searchArgumentID,
          errorkey: 'general.input_required'
        }
      }
    } else if (searchArgumentID.value === this.props.searchArgument[0].SASID) {
      return {
        searchArgumentID: {
          ...searchArgumentID,
          errorkey: 'definition.search_argument_copy_same_id'
        }
      }
    }
    return {}
  }

  /**
   * @description Validates the report.
   * @returns {Object} If validation failed new object with error message for state otherwise empty object.
   */
  validateReport = () => {
    if (this.state.report.value !== '') {
      return {}
    }
    return {
      report: {
        ...this.state.report,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Gets the translated type items.
   * @returns {Array} The translated type items.
   */
  getTranslatedItems = () => {
    const items = []
    DefinitionUtils.SEARCH_ARGUMENT_TYPES.forEach(type => {
      items.push(translate(type.translationKey))
    })
    return items
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { id } = this.props
    const { searchArgumentID, activeTypeIndex, form, extension, report, owner, title, onPage, fromColumn, toColumn, fromRow, toRow } = this.state
    return (
      <>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_search_argument_id`}
              value={searchArgumentID.value}
              title={translate('definition.searchargument_id')}
              ref={this.searchArgumentIdInput}
              required={`${translate('general.required_field')}`}
              maxLength={16}
              onInputChanged={(value, errorkey) => this.handleChangeWithoutSpaces('generalTab', 'searchArgumentID', value, errorkey)}
              error={searchArgumentID.errorkey && translate(searchArgumentID.errorkey)}
              onBlur={() => this.setState({ ...this.validateID() })}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Dropdown
              id={`${id}_search_argument_type`}
              title={translate('general.type')}
              items={this.getTranslatedItems()}
              activeIndex={activeTypeIndex}
              onChange={index => this.setState({ activeTypeIndex: index })}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_search_argument_form`}
              value={form.value}
              title={translate('general.form')}
              maxLength={8}
              onInputChanged={(value, errorkey) => this.handleChangeWithoutSpaces('generalTab', 'form', value, errorkey)}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_search_argument_extension`}
              value={extension.value}
              title={translate('general.extension')}
              maxLength={16}
              onInputChanged={(value, errorkey) => this.handleChangeWithoutSpaces('generalTab', 'extension', value, errorkey)}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_search_argument_report`}
              value={report.value}
              title={translate('general.report')}
              ref={this.searchArgumentReportInput}
              required={`${translate('general.required_field')}`}
              maxLength={16}
              onInputChanged={(value, errorkey) => this.handleChangeWithoutSpaces('generalTab', 'report', value, errorkey)}
              error={report.errorkey && translate(report.errorkey)}
              onBlur={() => this.setState({ ...this.validateReport() })}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_search_argument_owner`}
              value={owner.value}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={(value, errorkey) => this.handleInputChanged('generalTab', 'owner', value, errorkey)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_search_argument_title`}
              value={title.value}
              title={translate('general.title')}
              maxLength={68}
              onInputChanged={(value, errorkey) => this.handleInputChanged('generalTab', 'title', value, errorkey)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_options`}>{translate('general.options')}</label>
          </Column>
        </Row>
        {activeTypeIndex === 0
          ? <Row>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_search_argument_fromcolumn`}
                title={translate('general.from_column')}
                onChange={val => this.handleInputChanged('generalOptionsTab', 'fromColumn', val)}
                value={fromColumn}
                steps={1}
                min={0}
                max={32767}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_search_argument_tocolumn`}
                title={translate('general.to_column')}
                onChange={val => this.handleInputChanged('generalOptionsTab', 'toColumn', val)}
                value={toColumn}
                steps={1}
                min={0}
                max={32767}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_search_argument_fromrow`}
                title={translate('general.from_line')}
                onChange={val => this.handleInputChanged('generalOptionsTab', 'fromRow', val)}
                value={fromRow}
                steps={1}
                min={0}
                max={32767}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_search_argument_torow`}
                title={translate('general.to_line')}
                onChange={val => this.handleInputChanged('generalOptionsTab', 'toRow', val)}
                value={toRow}
                steps={1}
                min={0}
                max={32767}
              />
            </Column>
          </Row>
          : <Row>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_search_argument_onpage`}
                title={translate('definition.search_argument_on_page')}
                onChange={val => this.handleInputChanged('generalOptionsTab', 'onPage', val)}
                value={onPage}
                steps={1}
                min={1}
                max={9999999}
              />
            </Column>
          </Row>
        }
      </>
    )
  }

  /**
   * @description Moves an item from a list to another.
   * @param {Array} source The source list.
   * @param {Array} destination The destination list.
   * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
   * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
   * @returns {Object} An object with the new source and destination lists.
   */
  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = [...source]
    const destClone = [...destination]
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  /**
   * @description Moves items from a list to another.
   * @param {Array} source The source list.
   * @param {Array} destination The destination list.
   * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
   * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
   * @returns {Object} An object with the new source and destination lists.
   */
  moveMulti = (source, destination, droppableSource, droppableDestination) => {
    let sourceClone = [...source]
    const destClone = [...destination]
    sourceClone = sourceClone.filter((id) => {
      return !this.state.selectedIds.includes(id)
    })

    const orderedSelectedHeaders = [...this.state.selectedIds]
    orderedSelectedHeaders.sort((a, b) => {
      const indexOfA = source.indexOf(a)
      const indexOfB = source.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      // sorting by their order in the selected headers
      return -1
    })

    destClone.splice(droppableDestination.index, 0, ...orderedSelectedHeaders)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    result['orderedSelectedIds'] = orderedSelectedHeaders

    return result
  }

  /**
   * @description Performes the single drag action.
   * @param {Object} result Includes the new lists.
   */
  singleDrag = result => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      this.setState({ draggingId: null })
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorder(
        this.state[source.droppableId].data,
        source.index,
        destination.index
      )

      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: items
        },
        draggingId: null
      })
      // move if the dnd was between different lists
    } else {
      const result = this.move(
        this.state[source.droppableId].data,
        this.state[destination.droppableId].data,
        source,
        destination
      )

      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: result[source.droppableId]
        },
        [destination.droppableId]: {
          ...this.state[destination.droppableId],
          data: result[destination.droppableId]
        },
        draggingId: null
      })
    }
  }

  /**
  * @description Performes the multi drag action.
  * @param {Object} result Includes the new lists.
  */
  multiDrag = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      this.setState({ draggingId: null })
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorderMulti(
        this.state[source.droppableId].data,
        source.index,
        destination.index
      )

      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: items
        },
        draggingId: null
      })
      // move if the dnd was between different lists
    } else {
      const result = this.moveMulti(
        this.state[source.droppableId].data,
        this.state[destination.droppableId].data,
        source,
        destination
      )

      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: result[source.droppableId]
        },
        [destination.droppableId]: {
          ...this.state[destination.droppableId],
          data: result[destination.droppableId]
        },
        draggingId: null,
        selectedIds: result['orderedSelectedIds']
      })
    }
  }

  /**
   * @description The drag end action from dnd.
   * @param {Object} result The result from dnd.
   */
  onDragEnd = result => {
    if (this.state.selectedIds.length > 1) {
      this.multiDrag(result)
    } else {
      // single drag
      this.singleDrag(result)
    }
  }

  /**
  * @description Reorders multiple items.
  * @param {Array} list The list.
  * @param {Number} startIndex The source start index.
  * @param {Number} endIndex The destination index.
  */
  reorderMulti = (list, startIndex, endIndex) => {
    // do nothing when startindex and endindex are equal
    if (startIndex === endIndex) {
      return list
    }

    let result = [...list]

    const insertAtIndex = (() => {
      // gets the offset of the index where to insert the items
      const destinationIndexOffset = this.state.selectedIds.reduce((previous, current) => {
        const index = list.indexOf(current)

        if (current === this.state.draggingId) {
          if (index > endIndex && previous > 0) {
            return previous - 1
          } else {
            return previous
          }
        }

        if (index > endIndex) {
          return previous
        }

        return previous + 1
      }, 0)

      return endIndex - destinationIndexOffset
    })()

    const orderedSelectedHeaders = [...this.state.selectedIds]
    orderedSelectedHeaders.sort((a, b) => {

      const indexOfA = list.indexOf(a)
      const indexOfB = list.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      // sorting by their order in the selected headers
      return -1
    })

    result = result.filter((id) => {
      return !this.state.selectedIds.includes(id)
    })

    result.splice(insertAtIndex, 0, ...orderedSelectedHeaders)

    return result
  }

  /**
   * @description Reorders an item inside a list.
   * @param {Array} list The list.
   * @param {Number} startIndex The source index of the item to reorder.
   * @param {Number} endIndex The destination index.
   * @return {Array} The reordered list.
   */
  reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  /**
   * @description Toggles a selection of an item.
   * @param {String} id The id of the data to select or unselect.
   */
  toggleSelection = (id) => {
    let newSelectedIds = []
    if (!this.state.selectedIds.includes(id)) {
      newSelectedIds = [id]
    } else if (this.state.selectedIds.length > 1) {
      // was part of a seleted group -> will now become the only selected item
      newSelectedIds = [id]
    } else {
      // was previously selected but not in a group -> will now clear the selection
      newSelectedIds = []
    }

    this.setState({ selectedIds: newSelectedIds })
  }

  /**
   * @description Toggles the selection in group (with ctrl).
   * @param {String} id The id of the data which was clicked.
   */
  toggleSelectionInGroup = (id) => {
    const index = this.state.selectedIds.indexOf(id)

    // if not selected -> add it to the selected items
    if (index === -1) {
      this.setState({ selectedIds: [...this.state.selectedIds, id] })
    } else {
      // item was previously selected -> remove it from the group
      const shallow = [...this.state.selectedIds]
      shallow.splice(index, 1)
      this.setState({ selectedIds: shallow })
    }
  }

  /**
   * @description Selects all elements between the last selected item and the item to select.
   * @param {Array} data The data.
   * @param {String} id The id of the data to select.
   */
  multiSelectTo = (data, id) => {
    const updated = this.multiSelect(data, id)

    if (updated === null) {
      return
    }

    this.setState({ selectedIds: updated })
  }

  /**
 * @description Multi selects items with shift.
 * @param {Array} data The data.
 * @param {String} id The id of the data which was clicked.
 * @returns {Array} The new selected headers or null.
 */
  multiSelect = (data, newId) => {
    if (!this.state.selectedIds.length) {
      return [newId]
    }

    const indexOfNew = data.indexOf(newId)
    const indexOfLast = data.indexOf(this.state.selectedIds[this.state.selectedIds.length - 1])

    if (indexOfNew === indexOfLast) {
      return null
    }

    const isSelectingForwards = indexOfNew > indexOfLast
    const start = isSelectingForwards ? indexOfLast : indexOfNew
    const end = isSelectingForwards ? indexOfNew : indexOfLast

    const inBetween = data.slice(start, end + 1)

    // add headers which are between the last selected item and the clicked item
    // if items between are already selected do nothing
    const toAdd = inBetween.filter((id) => {
      if (Utils.includesArray(this.state.selectedIds, id)) {
        return false
      }
      return true
    })

    const combined = [...this.state.selectedIds, ...toAdd]

    return combined
  }

  /**
   * @description Sets the width and visibility properties for group dragging.
   * @param {Object} dragStart The dragStart object.
   */
  onBeforeDragStart = (dragStart) => {
    // get the id which is dragged
    let id = this.state[dragStart.source.droppableId].data[dragStart.source.index]
    const selected = this.state.selectedIds.find((headerId) => headerId === id)

    let newHeaderWidth = '0px'
    let visibility = 'hidden'

    if (selected) {
      // set the width of the grouping items based on the item which will be dragged
      if (document.querySelector(`#${dragStart.draggableId}`)) {
        newHeaderWidth = `${document.querySelector(`#${dragStart.draggableId}`).getBoundingClientRect().width}px`
        visibility = 'visible'
      }
      this.setState({ draggingId: id })
    } else {
      // if draggin an item that is not selected - unselect all items
      this.setState({ draggingId: id, selectedIds: [] })
    }

    document.querySelector(':root').style.setProperty('--row-width', newHeaderWidth)
    document.querySelector(':root').style.setProperty('--row-visibility', visibility)
  }

  /**
   * @description Renders the values tab.
   */
  renderValuesTab = () => {
    const { id } = this.props
    const available = {
      title: translate('definition.search_argument_values_available'),
      data: this.state.available.data,
      headers: [
        translate('definition.search_argument_operator'),
        translate('general.type'),
        translate('general.case_sensitive'),
        translate('import.enc_utf_8'),
        translate('definition.search_argument_value'),
        translate('definition.search_argument_column_position'),
      ],
      columnSortDefs: ['string', 'string', 'string', 'string', 'string', 'string'],
      onSortEnd: (newData, sortedCol) => { this.setState({ available: { sortedCol: sortedCol, data: newData } }) },
      sortedCol: this.state.available.sortedCol,
      droppableID: 'available'
    }

    const chosen = {
      title: translate('definition.search_argument_values_to_copy'),
      data: this.state.toCopy.data,
      headers: [
        translate('definition.search_argument_operator'),
        translate('general.type'),
        translate('general.case_sensitive'),
        translate('import.enc_utf_8'),
        translate('definition.search_argument_value'),
        translate('definition.search_argument_column_position'),
      ],
      columnSortDefs: ['string', 'string', 'string', 'string', 'string', 'string'],
      onSortEnd: (newData, sortedCol) => { this.setState({ toCopy: { sortedCol: sortedCol, data: newData } }) },
      sortedCol: this.state.available.sortedCol,
      droppableID: 'toCopy'
    }

    return (
      <div className={'bux_copy_search_argument_values_container'}>
        <RelationAssignments
          id={`${id}_relation_assignments`}
          available={available}
          chosen={chosen}
          onBeforeDragStart={this.onBeforeDragStart}
          onDragEnd={this.onDragEnd}
          selectedIds={this.state.selectedIds}
          draggingId={this.state.draggingId}
          toggleSelection={this.toggleSelection}
          toggleSelectionInGroup={this.toggleSelectionInGroup}
          multiSelectTo={this.multiSelectTo}
          unselectAll={this.unselectAll}
          translate={key => translate(key)}
        />
      </div>
    )
  }

  /**
   * @description Returns "Yes" or "No" if the type is "Text" (values "YES" and "NO" stands for text).
   * @param {String} input The type.
   * @returns {String} The translated case.
   */
  getTranslatedType = input => {
    switch (input) {
      case 'YES':
        return translate('general.text')
      case 'NO':
        return translate('general.text')
      case 'MASK':
        return translate('general.pattern')
      case 'TLE':
        return translate('general.tle')
      default:
        return ''
    }
  }

  /**
   * @description Returns "Yes" or "No" if the type is "Text" (values "YES" and "NO" stands for text).
   * @param {String} input The type.
   * @returns {String} The translated case.
   */
  getTranslatedCase = input => {
    switch (input) {
      case 'YES':
        return translate('general.yes')
      case 'NO':
        return translate('general.no')
      default:
        return ''
    }
  }

  /**
   * @description Gets the available data.
   * @returns {Array} The available data.
   */
  getAvailableData = () => {
    const { searchArgument } = this.props
    const data = []

    for (let i = 1; i < searchArgument.length; i++) {
      const type = this.getTranslatedType(searchArgument[i].SAANOCS)
      const caseSensitive = this.getTranslatedCase(searchArgument[i].SAANOCS)
      const utf8Value = searchArgument[i].SAAUTF8 ? translate('general.yes') : translate('general.no')
      data.push([searchArgument[i].SAATYPE, type, caseSensitive, utf8Value, searchArgument[i].SAASARG, searchArgument[i].SAACPAMM])
    }
    return data
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateID(),
      ...this.validateReport()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }

  translateTypeBack = (type, caseSensitive) => {
    switch (type) {
      case translate('general.text'): {
        if (caseSensitive === translate('general.yes')) {
          return 'YES'
        }
        else if (caseSensitive === translate('general.no')) {
          return 'NO'
        }
        break
      }
      case translate('general.pattern'): return 'MASK'
      case translate('general.tle'): return 'TLE'
      default: return type
    }
  }

  /**
   * @description Handles the save button
   */
  handleSave = () => {
    const { onClose, copySearchArgument } = this.props
    const {
      searchArgumentID, title, owner, form, extension, report, activeTypeIndex,
      onPage, fromColumn, toColumn, fromRow, toRow, toCopy
    } = this.state

    const errorTabs = [
      this.validateGeneralTab(),
    ]

    if (errorTabs.every(d => d)) {
      const searchArgumentArr = []
      for (let i = 0; i <= toCopy.data.length; i++) {
        if (i === 0) {
          searchArgumentArr.push({
            'ID': 'SAS',
            'SASID': searchArgumentID.value,
            'FORM': form.value,
            'EXTENSION': extension.value,
            'REPORT': report.value,
            'OWNER': owner.value,
            'SASTITLE': title.value,
            'SASTYPE': activeTypeIndex === 0 ? 'VALUE' : 'PAGE',
            'SASFCOL': fromColumn,
            'SASTCOL': toColumn,
            'SASFROW': fromRow,
            'SASTROW': toRow,
            'SASPNUM': onPage
          })
        } else {
          searchArgumentArr.push({
            'ID': 'SAA',
            'SASID': searchArgumentID.value,
            'FORM': form.value,
            'EXTENSION': extension.value,
            'REPORT': report.value,
            'SAATYPE': toCopy.data[i - 1][0],
            'SAANOCS': this.translateTypeBack(toCopy.data[i - 1][1], toCopy.data[i - 1][2]),
            'SAACPAMM': toCopy.data[i - 1][5],
            'SAASARG': toCopy.data[i - 1][4],
            'SAAUTF8': toCopy.data[i - 1][3],
          })
        }
      }
      copySearchArgument(searchArgumentArr, () => onClose())
    }
  }

  /**
   * @description Gets the tab indices as array which has errors.
   * @returns {Array} The tab indices which has errors.
   */
  handleErrorTabs = () => {
    const { searchArgumentID, report } = this.state
    let buffer = []
    // possible general tab errors
    const isIdError = searchArgumentID.errorkey !== ''
    const isReportError = report.errorkey !== ''

    if (isIdError || isReportError) {
      buffer.push(0)
    }

    return buffer
  }

  /**
   * @description Tries to focus the next input of the general tab which has an error.
   */
  handleFocusGeneralTab = () => {
    const requiredInputs = [
      { inputRef: this.searchArgumentIdInput, error: this.state.searchArgumentID.errorkey },
      { inputRef: this.searchArgumentReportInput, error: this.state.report.errorkey },
    ]
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].error !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <>
        <Modal
          id='copy_search_argument_dialog'
          className='bux_UserProfileModal'
          onClose={onClose}>
          <Header
            id={`${id}_modalHeader`}
            title={translate('definition.copy_search_argument_title')}
            onClose={onClose}
          />
          <Main id={id}>
            <Tabs
              id={id}
              errorTabs={this.handleErrorTabs()}
              disabledTabs={this.state.activeTypeIndex === 1 ? [1] : []}
            >
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab title={translate('general.values')}>
                {this.renderValuesTab()}
              </Tab>
            </Tabs>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchArgument: state.definitions.searcharguments.searchArgument
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createSearchArgument: (searchArgument, callback) => {
      SearchArgumentActions.createSearchArgument(searchArgument, callback)(dispatch)
    },
    copySearchArgument: (searchArgument, callback) => {
      SearchArgumentActions.copySearchArgument(searchArgument, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopySearchArgumentDialog)