import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'

// Components
import {
  Button, Card, Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent,
  Row, Switch
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as IndexDefinitionActions from 'redux/actions/IndexDefinitionActions'
import * as Preferences from 'redux/general/Preferences'

class ModifyIndexDialog extends Component {

  state = {
    owner: {
      value: this.props.indexToModify.OWNER,
      errorkey: ''
    },
    identifier: {
      value: this.props.indexToModify.IXENAME,
      errorkey: ''
    },
    activeBlankBetweenWordsIndex: 0
  }

  ownerInput = React.createRef()
  identifierInput = React.createRef()

  /**
   * @description Initializes the values.
   */
  componentDidMount() {
    const { indexToModify } = this.props

    // only init active blank between words when data format is string
    if (indexToModify.IXDTYPE === DefinitionUtils.INDEX_DATA_FORMAT_STRING) {
      this.setState({
        activeBlankBetweenWordsIndex: Math.max(DefinitionUtils.INDEX_BLANKS_BETWEEN_WORDS.findIndex(element => element.key === indexToModify.IXBLANK), 0)
      })
    }

    this.ownerInput.current.focus()
  }

  /**
   * @description Handles the input changes of the input fields.
   * @param {String} id The id the input field.
   * @param {String} value The new value.
   * @param {String} errorkey The new errorkey.
   */
  handleInputChanged = (id, value, errorkey) => {
    this.setState({
      [id]: {
        value: value,
        errorkey: errorkey,
      }
    })
  }

  /**
   * @description Validates the identifier.
   */
  validateIdentifier = () => {
    if (this.state.identifier.value !== '') {
      return {}
    }
    return {
      identifier: {
        ...this.state.identifier,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = { ...this.validateIdentifier() }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.identifierInput.current && this.identifierInput.current.focus()
      })
    }
    return errors === 0
  }

  /**
 * @description Gets the translated blank between words items.
 * @returns {Array} The translated blank between words items.
 */
  getTranslatedBlankBetweenWordItems = () => {
    const items = []
    DefinitionUtils.INDEX_BLANKS_BETWEEN_WORDS.forEach(type => {
      items.push(translate(type.translationKey))
    })
    return items
  }

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { indexToModify } = this.props
    const { owner, identifier, activeBlankBetweenWordsIndex } = this.state

    if (this.validateInputs()) {
      const indexDefinition = {
        IXINAME: indexToModify.IXINAME,
        OWNER: owner.value,
        IXENAME: identifier.value,
        IXMAXLEN: indexToModify.IXMAXLEN,
        IXDTYPE: indexToModify.IXDTYPE,
      }

      // add number of decimal places when dataformat is decimal
      if (indexToModify.IXDTYPE === DefinitionUtils.INDEX_DATA_FORMAT_DECIMAL) {
        indexDefinition['IXNPLACE'] = indexToModify.IXNPLACE
      } else if (indexToModify.IXDTYPE === DefinitionUtils.INDEX_DATA_FORMAT_STRING) {
        // add additional values when dataformat is string
        indexDefinition['IXCASE'] = indexToModify.IXCASE
        indexDefinition['IXBLANK'] = DefinitionUtils.INDEX_BLANKS_BETWEEN_WORDS[activeBlankBetweenWordsIndex].key
      }

      const callback = () => { this.props.onClose() }
      this.props.updateIndex(indexDefinition, callback)
    }
  }

  /**
   * @description Renders the header for data format string.
   */
  renderHeaderDataFormatString = () => {
    const { id, indexToModify, datemask } = this.props
    const idxType = DefinitionUtils.getIndexDataFormatTypes().find(element => element.key === indexToModify.IXDTYPE)
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.index_id'), value: indexToModify.IXINAME },
          { label: translate('definition.index_maxlength'), value: indexToModify.IXMAXLEN },
          { label: translate('definition.index_data_format'), value: idxType ? translate(idxType.translationKey) : indexToModify.IXDTYPE },
          { label: translate('definition.index_convert_values_to_upper'), value: indexToModify.IXCASE, yesNo: true },
          { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, indexToModify.CDATE, indexToModify.CTIME.substring(0, 8)) + ' ' + translate('general.by') + ' ' + indexToModify.CUSER },
        ]}
        columns={4}
        translate={translate}
      />
    )
  }

  /**
   * @description Renders the header for data format decimal.
   */
  renderHeaderDataFormatDecimal = () => {
    const { id, indexToModify, datemask } = this.props
    const idxType = DefinitionUtils.getIndexDataFormatTypes().find(element => element.key === indexToModify.IXDTYPE)
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.index_id'), value: indexToModify.IXINAME },
          { label: translate('definition.index_maxlength'), value: indexToModify.IXMAXLEN },
          { label: translate('definition.index_data_format'), value: idxType ? translate(idxType.translationKey) : indexToModify.IXDTYPE },
          { label: translate('definition.index_number_of_decimal_places'), value: indexToModify.IXNPLACE },
          { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, indexToModify.CDATE, indexToModify.CTIME.substring(0, 8)) + ' ' + translate('general.by') + ' ' + indexToModify.CUSER },
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description Renders the header for other dataformats than string and decimal.
   */
  renderHeaderNormal = () => {
    const { id, indexToModify, datemask } = this.props
    const idxType = DefinitionUtils.getIndexDataFormatTypes().find(element => element.key === indexToModify.IXDTYPE)
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.index_id'), value: indexToModify.IXINAME },
          { label: translate('definition.index_maxlength'), value: indexToModify.IXMAXLEN },
          { label: translate('definition.index_data_format'), value: idxType ? translate(idxType.translationKey) : indexToModify.IXDTYPE },
          { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, indexToModify.CDATE, indexToModify.CTIME.substring(0, 8)) + ' ' + translate('general.by') + ' ' + indexToModify.CUSER },
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description Renders the header which includes the non editable information.
   */
  renderHeader() {
    const { indexToModify } = this.props
    return (
      <>
        {
          indexToModify.IXDTYPE === DefinitionUtils.INDEX_DATA_FORMAT_DECIMAL && (
            this.renderHeaderDataFormatDecimal()
          )
        }
        {
          indexToModify.IXDTYPE === DefinitionUtils.INDEX_DATA_FORMAT_STRING && (
            this.renderHeaderDataFormatString()
          )
        }
        {
          indexToModify.IXDTYPE !== DefinitionUtils.INDEX_DATA_FORMAT_STRING &&
          indexToModify.IXDTYPE !== DefinitionUtils.INDEX_DATA_FORMAT_DECIMAL && (
            this.renderHeaderNormal()
          )
        }
      </>
    )
  }

  render = () => {
    const { id, onClose, indexToModify } = this.props
    const { owner, identifier, activeBlankBetweenWordsIndex } = this.state
    return (
      <Modal onClose={onClose}
        id={id}
        className='bux_UserProfileModal'>
        <Header
          id={`${id}_modalHeader`}
          title={translate('definition.update_index')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main
          id={id}>
          <Card>
            <Row>
              <Column
                colMD={3}>
                {/* owner */}
                <Input
                  id={`${id}_owner`}
                  value={owner.value}
                  ref={this.ownerInput}
                  title={translate('general.owner')}
                  maxLength={8}
                  onInputChanged={(value, errorkey) => this.handleInputChanged('owner', value, errorkey)}
                  error={owner.errorkey && translate(owner.errorkey)}
                />
              </Column>
              <Column
                colMD={9}>
                {/* identifier */}
                <Input
                  id={`${id}_identifier`}
                  value={identifier.value}
                  title={translate('definition.index_identifier')}
                  ref={this.identifierInput}
                  maxLength={64}
                  required={`${translate('general.required_field')}`}
                  onInputChanged={(value, errorkey) => this.handleInputChanged('identifier', value, errorkey)}
                  error={identifier.errorkey && translate(identifier.errorkey)}
                  onBlur={() => this.setState({ ...this.validateIdentifier() })}
                />
              </Column>
            </Row>
            {
              indexToModify.IXDTYPE === DefinitionUtils.INDEX_DATA_FORMAT_STRING && (
                <>
                  <Row>
                    <Column colMD={12}>
                      {/* hairline */}
                      <hr />
                    </Column>
                  </Row>
                  <Row isTitle>
                    <Column colMD={12}>
                      {/* attributes label */}
                      <label id={`${id}_attributes`}>{translate('definition.attributes')}</label>
                    </Column>
                  </Row>
                  <Row>
                    <Column colMD={6}>
                      <Switch
                        id={`${id}_blanks_between_words`}
                        title={translate('definition.index_blanks_between_words')}
                        items={this.getTranslatedBlankBetweenWordItems()}
                        onClick={(index) => this.setState({ activeBlankBetweenWordsIndex: index })}
                        activeIndex={activeBlankBetweenWordsIndex}
                        maxPerRow={3}
                      />
                    </Column>
                  </Row>
                </>
              )
            }
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

ModifyIndexDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    userid: state.auth.userid,
    indexToModify: state.definitions.indexes.index,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateIndex: (indexDefinition, callback) => {
      IndexDefinitionActions.updateIndex(indexDefinition, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyIndexDialog)