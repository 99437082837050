import PropTypes from 'prop-types'
import { Component } from 'react'

import { Button, Card, Column, Input, Row } from 'BetaUX2Web-Components/src/'
import { LetterCase } from 'BetaUX2Web-Components/src/types'

import { translate } from 'language/Language'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as RemoteUserActions from 'redux/actions/RemoteUserActions'
import * as Preferences from 'redux/general/Preferences'
import * as UrlUtils from 'utils/UrlUtils'


class UserManagementRemoteUsers extends Component {

  defaultState = {
    unixuser: '',
    buxuser: '',
  }

  keys = [
    { rest: 'UNIXUSER', ui: 'unixuser' },
    { rest: 'BETAUSER', ui: 'buxuser' }
  ]

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount() {
    const { preferences } = this.props
    if (window.location.href.indexOf('?') !== -1) {
      this.initFieldsFromUrl()
    }
    else {
      // initialize search fields
      if (preferences) {
        let unixuser = preferences[Preferences.USERMANAGEMENT_REMOTEUSERS_UNIXUSER] || ''
        let buxuser = preferences[Preferences.USERMANAGEMENT_REMOTEUSERS_BUXUSER] || ''
        this.setState({ unixuser, buxuser })
      }
    }
  }


  initFieldsFromUrl = () => {
    this.setState({
      ...this.state,
      ...UrlUtils.getDataFromUrlParams(this.props.location.search, this.keys)
    }, () => {
      if (UrlUtils.urlContainsExecute()) {
        this.search()
      }
    })
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Handles the changes on unixuser.
   * @param val The new value
   */
  handleUnixuserChanged = val => {
    this.setState({
      unixuser: val
    })
  }

  /**
   * @description Handles the changes on buxuser.
   * @param val The new value
   */
  handleBuxuserChanged = val => {
    this.setState({
      buxuser: val
    })
  }

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = event => {
    event.preventDefault()
    this.search()
  }

  search = () => {
    const { unixuser, buxuser } = this.state
    const prefsToChange = {
      [Preferences.USERMANAGEMENT_REMOTEUSERS_UNIXUSER]: unixuser,
      [Preferences.USERMANAGEMENT_REMOTEUSERS_BUXUSER]: buxuser
    }
    // save search values in preferences
    this.props.changePrefs(prefsToChange)
    this.props.searchRemoteUsers(unixuser, buxuser)

    // builds the parameter object which is used for url
    const urlToPush = `/usermanagement/remoteusers${UrlUtils.createUrlParamsFromObject(this.state, this.keys)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id, lang } = this.props
    return (
      <Card
        title={translate('general.general', lang)}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_generalcard_unixuser`}
              title={translate('remoteuser.unixuser', lang)}
              maxLength={8}
              value={this.state.unixuser}
              onInputChanged={this.handleUnixuserChanged}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_generalcard_buxuserid`}
              title={translate('remoteuser.betaux_user_id', lang)}
              maxLength={8}
              value={this.state.buxuser}
              onInputChanged={this.handleBuxuserChanged}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {/* general card */}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props
    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSubmitSearch}
          submit
          primary />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id } = this.props
    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchRemoteUsers: (unixuser, userid) => {
      RemoteUserActions.getRemoteUsers(unixuser, userid)(dispatch)
    },
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch)
  }
}

UserManagementRemoteUsers.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserManagementRemoteUsers))