import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as Utils from 'utils/Utils'

// components
import {
  Button, Card, Column, Icon, Input,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import { createNodeDocument } from 'redux/actions/NodeDocumentAssignmentActions'

class CopyNodeDocumentAssignmentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    form: {
      value: this.props.documentNode.FORM,
      error: ''
    },
    extension: this.props.documentNode.EXT,
    nodeID: {
      value: this.props.documentNode.DNDNAME,
      error: ''
    },
    owner: this.props.documentNode.OWNER,
    identifier: {
      value: this.props.documentNode.DGIENAME,
      error: ''
    },
    description: this.props.documentNode.DGIDESC,
    showNodeDialog: false,
    showDocumentDialog: false
  }

  formInput = React.createRef()
  nodeIdInput = React.createRef()
  identifierInput = React.createRef()

  componentDidMount = () => {
    this.formInput.current.focus()
  }

  /**
  * @description gets the index of the header in redux state
  * @param {Object} data specific redux object
  * @param {String} header header name of the header in redux
  */
  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param val New value of inputfield
   */
  handleInputChanged = (key, value, error) => {
    this.setState({
      [key]: typeof this.state[key] === 'object'
        ? { value, error }
        : value
    })
  }

  /**
   * @description Handles the input changes of the nodeid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleInputChangedWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInputChanged(key, value, error)
  }

  /**
   * @description Handles the node id selector button
   */
  handleNodeSelector = () => {
    this.props.getDocumentNodesDefinition(
      ['DNDNAME', 'DNDENAME'],
      this.state.nodeID.value,
      () => this.setState({ showNodeDialog: true })
    )
  }

  /**
   * @description Handles the form and extension selector button
   */
  handleDocumentSelector = () => {
    this.props.getDocumentDefinitions(
      ['FORM', 'EXT'],
      this.state.form.value,
      this.state.extension,
      () => this.setState({ showDocumentDialog: true })
    )
  }

  /**
   * @description Renders the selector dialogs
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props

    return (
      <>
        {this.state.showNodeDialog && (
          <SelectorDialog
            id={`${id}_nodedefinition_selector_dialog`}
            onClose={() => this.setState({ showNodeDialog: false })}
            title={translate('definition.document_node_definitions')}
            header={[
              translate('definition.node_id'),
              translate('general.identifier')
            ]}
            items={selector.documentnodes.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newNodeID = selector.documentnodes.data[selectedRows][selector.documentnodes.header.indexOf('DNDNAME')]
                this.setState({ nodeID: { value: newNodeID, error: '' } })
              }
              this.setState({ showNodeDialog: false })
            }}
          />
        )}

        {this.state.showDocumentDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension')
            ]}
            items={selector.documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newForm = selector.documents
                  .data[selectedRows][selector.documents.header.indexOf('FORM')]
                const newExt = selector.documents
                  .data[selectedRows][selector.documents.header.indexOf('EXT')]
                this.setState({
                  form: { value: newForm, error: '' },
                  extension: newExt
                })
              }
              this.setState({ showDocumentDialog: false })
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Validates the form.
   */
  validateForm = () => {
    const { form } = this.state
    if (form.value !== '') {
      return {}
    }
    return {
      form: {
        ...this.state.form,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the node id.
   */
  validateNodeID = () => {
    const { nodeID } = this.state
    if (nodeID.value !== '') {
      return {}
    }
    return {
      nodeID: {
        ...this.state.nodeID,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the identifier id.
   */
  validateIdentifier = () => {
    const { identifier } = this.state
    if (identifier.value !== '') {
      return {}
    }
    return {
      identifier: {
        ...this.state.identifier,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = {
      ...this.validateForm(),
      ...this.validateNodeID(),
      ...this.validateIdentifier()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input which has an error.
   */
  handleFocus = () => {
    const { form, nodeID, identifier } = this.state
    const requiredInputs = [
      { inputRef: this.formInput, error: form.error },
      { inputRef: this.nodeIdInput, error: nodeID.error },
      { inputRef: this.identifierInput, error: identifier.error }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Creates the entry
   */
  handleSave = () => {
    const { createNodeDocument, onClose } = this.props
    const { form, extension, nodeID, owner, identifier, description } = this.state
    const errors = [
      this.validateInputs()
    ]
    if (errors.every(d => d)) {
      const nodeDocumentObj = {
        DNDNAME: nodeID.value,
        FORM: form.value,
        EXT: extension,
        DGIENAME: identifier.value,
        DGIDESC: description,
        OWNER: owner
      }

      createNodeDocument(nodeDocumentObj, () => onClose())
    }
  }

  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.copy')} ${translate('general.node_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'node_document_relation_modal_title'} name={'relation'} />,
      <div key={'title_3'}>{`${translate('general.document_modal_title')} ${translate('general.assignment_modal_title')}`}</div>,
    ]
  }

  render = () => {
    const { id, onClose } = this.props
    const { form, extension, nodeID, owner, identifier, description } = this.state
    return (
      <>
        {this.renderSelectorDialogs()}
        <Modal
          id='copy_node_document_assignment_dialog'
          className='bux_UserProfileModal'
          onClose={onClose}>
          <Header
            id={id}
            title={this.getModalTitle()}
            onClose={onClose}
          />
          <Main id={id}>
            <Card>
              <Row>
                <Column colMD={3}>
                  <Input
                    id={`${id}_form`}
                    onInputChanged={(val, error) => this.handleInputChanged('form', val, error)}
                    value={form.value}
                    title={translate('general.form')}
                    ref={this.formInput}
                    error={form.error}
                    maxLength={8}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }}
                    onBlur={() => this.setState({ ...this.validateForm() })}
                    required={`${translate('general.required_field')}`}
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_extension`}
                    onInputChanged={(val) => this.handleInputChanged('extension', val)}
                    value={extension}
                    title={translate('general.extension')}
                    maxLength={16}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }}
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_nodeid`}
                    onInputChanged={(val, error) => this.handleInputChangedWithoutSpaces('nodeID', val, error)}
                    value={nodeID.value}
                    error={nodeID.error}
                    title={translate('definition.node_id')}
                    maxLength={25}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }}
                    onBlur={() => this.setState({ ...this.validateNodeID() })}
                    ref={this.nodeIdInput}
                    required={`${translate('general.required_field')}`}
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_owner`}
                    value={owner}
                    title={translate('general.owner')}
                    maxLength={8}
                    onInputChanged={value => this.handleInputChanged('owner', value)}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={6}>
                  <Input
                    id={`${id}_identifier`}
                    onInputChanged={(val, error) => this.handleInputChanged('identifier', val, error)}
                    value={identifier.value}
                    title={translate('general.identifier')}
                    ref={this.identifierInput}
                    error={identifier.error}
                    maxLength={80}
                    onBlur={() => this.setState({ ...this.validateIdentifier() })}
                    required={`${translate('general.required_field')}`}
                  />
                </Column>
                <Column colMD={6}>
                  <Input
                    id={`${id}_description`}
                    value={description}
                    title={translate('general.description')}
                    maxLength={80}
                    onInputChanged={value => this.handleInputChanged('description', value)}
                  />
                </Column>
              </Row>
            </Card>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }

}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    selector: state.selector,
    documentNode: state.assignments.nodedocumentassignment.nodeDocumentAssignment
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDocumentNodesDefinition: (fields, nodeName, callback) => {
      ModalSelectorActions.getDocumentNodesDefinition(fields, nodeName, callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        undefined, // report
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    createNodeDocument: (nodeDocument, callback) => [
      createNodeDocument(nodeDocument, callback)(dispatch)
    ]
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyNodeDocumentAssignmentDialog)