import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as SortUtils from 'utils/SortUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer,
  Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CreateArchivePoolDialog from 'components/dialogs/create_archive_pool_dialog/CreateArchivePoolDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyArchivePoolDialog from 'components/dialogs/modify_archive_pool_dialog/ModifyArchivePoolDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as ArchivePoolDefinitionActions from 'redux/actions/ArchivePoolDefinitionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultArchivePool extends Component {
  state = {
    header: this.fillHeaderInformation(),
    showCreateDialog: false,
    showModifyDialog: false,
    showDeleteDialog: false,
    showTableSettingsDialog: false,
    createDialogData: undefined
  }

  fillHeaderInformation() {
    return [
      { rest: ARCHIVEPOOL_ID, translation: 'definition.archive_pool_id', default: true },
      { rest: MEDIUM, translation: 'general.media', default: true },
      { rest: RETENTION_PERIOD, translation: 'general.retention_period', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description Handles the refresh button
   */
  handleRefresh = () => {
    const { preferences } = this.props
    const archivePoolID = preferences[Preferences.DEFINITION_ARCHIVEPOOL_ID]
    const owner = preferences[Preferences.DEFINITION_ARCHIVEPOOL_OWNER]
    const title = preferences[Preferences.DEFINITION_ARCHIVEPOOL_TITLE]
    const retentionPeriod = preferences[Preferences.DEFINITION_ARCHIVEPOOL_RETENTIONPERIOD]
    const media = preferences[Preferences.DEFINITION_ARCHIVEPOOL_MEDIA]
    this.props.getArchivePools(undefined, archivePoolID, media, retentionPeriod, title, owner)
  }

  /**
   * @description Handles the modal dialogs for modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { getArchivePool, archivePools } = this.props
    const id = archivePools.data[index][this.headerData('ARCPOOL')]
    const owner = archivePools.data[index][this.headerData('OWNER')]
    getArchivePool(undefined, id, owner, () => this.setState({ [dialog]: true }))
  }

  /**
   * @description deletes the specific entry
   */
  handleDelete = () => {
    const { archivePool, deleteArchivePool } = this.props
    const archivePoolObj = {
      ARCPOOL: archivePool[0].ARCPOOL,
      OWNER: archivePool[0].OWNER,
      ADPRETPD: archivePool[0].ADPRETPD,
      ADPMED: archivePool[0].ADPMED,
      ADPTITLE: archivePool[0].ADPTITLE,
      ADPORDER: archivePool[0].ADPORDER
    }
    deleteArchivePool(archivePoolObj, () => this.setState({ showDeleteDialog: false }))
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description gets the index of the header in redux state folders.header
   * @param {String} header header name of the header in redux state folders.header
   */
  headerData = header => this.props.archivePools.header.indexOf(header)


  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.showPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  translateMedia = media => {
    if (media === 'TAPE') {
      return translate('documentinformation.retentiontab_tape')
    }
    else if (media === 'ODISK') {
      return translate('documentinformation.retentiontab_opticaldisk')
    }
    else if (media === 'DISK') {
      return translate('documentinformation.retentiontab_disk')
    }
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData = () => {
    const { datemask, archivePools } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    archivePools.data.forEach(element => {
      let dataBuffer = []
      usedHeader.forEach(h => {
        if (h === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === MEDIUM) {
          dataBuffer.push(this.translateMedia(element[this.headerData(MEDIUM)]))
        }
        else if (h === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
 * @description Gets the used headers.
 * @returns {Array} The used headers.
 */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_ARCHIVEPOOL]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_ARCHIVEPOOL].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_ARCHIVEPOOL]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_ARCHIVEPOOL].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  showPrefilledCreateDialog = () => {
    const { preferences } = this.props
    this.setState({
      showCreateDialog: true,
      createDialogData: {
        ARCPOOL: preferences[Preferences.DEFINITION_ARCHIVEPOOL_ID],
        ADPMED: preferences[Preferences.DEFINITION_ARCHIVEPOOL_MEDIA],
        OWNER: preferences[Preferences.DEFINITION_ARCHIVEPOOL_OWNER],
        ADPRETPD: preferences[Preferences.DEFINITION_ARCHIVEPOOL_RETENTIONPERIOD],
        ADPTITLE: preferences[Preferences.DEFINITION_ARCHIVEPOOL_TITLE],
      }
    })
  }

  render = () => {
    const { archivePool, archivePools, id, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showDeleteDialog, showModifyDialog } = this.state
    let data = archivePools && archivePools.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showCreateDialog &&
          <CreateArchivePoolDialog
            id={id}
            prefilledData={this.state.createDialogData}
            onClose={() => { this.setState({ showCreateDialog: false, createDialogData: undefined }) }}
          />
        }
        {showModifyDialog &&
          <ModifyArchivePoolDialog
            id={`${id}_modify`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        }
        {showDeleteDialog &&
          <DeleteDialog
            id={`${id}`}
            title={translate('definition.archive_pool_delete')}
            question={translate('definition.archive_pool_delete_question')}
            onClose={() => this.setState({ showDeleteDialog: false })}
            onDelete={() => this.handleDelete()}>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_archive_pool_id_label`}>
                  {translate('definition.archive_pool_id')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_archive_pool_id_value`}>
                  {archivePool[0].ARCPOOL}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_archive_pool_media_label`}>
                  {translate('general.media')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_archive_pool_media_value`}>
                  {archivePool[0].ADPMED}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_archive_pool_retention_period_label`}>
                  {translate('general.retention_period')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_archive_pool_retention_period_value`}>
                  {archivePool[0].ADPRETPD}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_archive_pool_owner_label`}>
                  {translate('general.owner')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_archive_pool_owner_value`}>
                  {archivePool[0].OWNER}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_archive_pool_title_label`}>
                  {translate('general.title')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_archive_pool_title_value`}>
                  {archivePool[0].ADPTITLE}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        }
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_ARCHIVEPOOL }}
          />
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {archivePools
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={rowIndex => this.handleDialog(rowIndex, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_archive_pool_result')}
                    link={translate('emptyresult.create_archive_pool_link')}
                    onClick={() => this.showPrefilledCreateDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const ARCHIVEPOOL_ID = 'ARCPOOL'
const MEDIUM = 'ADPMED'
const RETENTION_PERIOD = 'ADPRETPD'
const OWNER = 'OWNER'
const TITLE = 'ADPTITLE'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const LASTCHANGED = 'LASTCHANGED'

SearchResultArchivePool.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    archivePools: state.definitions.archivePool.archivePools,
    archivePool: state.definitions.archivePool.archivePool,
    keepPagination: state.definitions.archivePool.keepPagination,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getArchivePools: (fields, id, media, retentionPeriod, title, owner, callback) => {
      ArchivePoolDefinitionActions.getArchivePools(fields, id, media, retentionPeriod, title, owner, callback)(dispatch)
    },
    getArchivePool: (fields, id, owner, callback) => {
      ArchivePoolDefinitionActions.getArchivePool(fields, id, owner, callback)(dispatch)
    },
    deleteArchivePool: (archivePool, callback) => {
      ArchivePoolDefinitionActions.deleteArchivePool(archivePool, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultArchivePool)