import moment from 'moment'
import PropTypes from 'prop-types'
import { Component } from 'react'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'

// components
import {
  Button, Card, Column,
  Input,
  Row
} from 'BetaUX2Web-Components/src/'
import TimeCard from 'components/time_card/TimeCard'

// redux
import { connect } from 'react-redux'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as PrinterDeviceInfoActions from 'redux/actions/ServerActions'
import * as Preferences from 'redux/general/Preferences'
import * as Utils from 'utils/Utils'
import SelectorDialog from '../../../../../dialogs/selector_dialog/SelectorDialog';
import * as ModalSelectorActions from '../../../../../../redux/actions/ModalSelectorActions';

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.hours' },
  { key: UNIT_DAYS, translationKey: 'general.days' },
]

export const LASTTIME_MODE_TODAY = 'today'
export const LASTTIME_MODE_YESTERDAY = 'yesterday'
export const LASTTIME_MODE_CUSTOM = 'custom'

const LASTTIME_MODES = [
  { key: LASTTIME_MODE_TODAY, translationKey: 'general.today' },
  { key: LASTTIME_MODE_YESTERDAY, translationKey: 'general.yesterday' },
  { key: LASTTIME_MODE_CUSTOM, translationKey: 'general.custom' },
]

class ServerPrinterDeviceInfo extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    time: {
      activeTimeTabIndex: 0,
      type: 0,
      period: 1,
      unit: 2,
      fromDate: {
        value: '',
        error: ''
      },
      fromTime: {
        value: '',
        error: ''
      },
      toDate: {
        value: '',
        error: ''
      },
      toTime: {
        value: '',
        error: ''
      }
    },
    outputChannelID: {
      value: '',
      errorkey: ''
    },
    messageID: {
      value: '',
      errorkey: ''
    },
    text: {
      value: '',
      errorkey: ''
    }
  }

  state = {
    ...this.defaultState,
    showOutputChanelDefinitionsSelectorDialog: false
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  handleOnReset = () => {
    this.setState(this.defaultState)
  }

  initFieldsFromPreferences = () => {
    const { preferences, datemask } = this.props

    if (preferences) {
      let activeTimeTabIndex = Utils.convertStringToInt(preferences[Preferences.SERVER_PDI_ACTIVE_TAB]) || 0
      let lastTimeMode = Utils.convertStringToInt(preferences[Preferences.SERVER_PDI_LASTTIME_MODE])
      if (!lastTimeMode) {
        lastTimeMode = LASTTIME_MODE_TODAY
      }
      const activeLastTimeModeIndex = Math.max(LASTTIME_MODES.findIndex(temp => temp.key === lastTimeMode), 0)
      // custom last
      let customLast = Utils.convertStringToInt(preferences[Preferences.SERVER_PDI_CUSTOM_LAST])
      if (!customLast) {
        customLast = 1
      }
      else {
        if (Utils.isString(customLast)) {
          customLast = parseInt(customLast)
        }
      }

      // custom unit
      let customUnit = preferences[Preferences.SERVER_PDI_CUSTOM_UNIT]
      if (!customUnit) {
        customUnit = UNIT_DAYS
      }
      const activeUnitIndex = UNITS.findIndex(temp => temp.key === customUnit)
      let fromDate = activeTimeTabIndex === 1 && preferences[Preferences.SERVER_PDI_FROMDATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SERVER_PDI_FROMDATE])
        : ''
      let fromTime = preferences[Preferences.SERVER_PDI_FROMTIME] || ''
      fromTime = Utils.convertStringToInt(fromTime)

      let toDate = preferences[Preferences.SERVER_PDI_TODATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SERVER_PDI_TODATE])
        : ''
      let toTime = preferences[Preferences.SERVER_PDI_TOTIME] || ''
      toTime = Utils.convertStringToInt(toTime)
      const outputChannelID = preferences[Preferences.SERVER_PDI_OUTPUTCHANNELID] || ''
      const messageID = preferences[Preferences.SERVER_PDI_MESSAGEID] || ''
      const text = preferences[Preferences.SERVER_PDI_TEXT] || ''

      this.setState(currState => {
        return {
          time: {
            ...currState.time,
            activeTimeTabIndex: activeTimeTabIndex,
            type: activeLastTimeModeIndex,
            period: customLast,
            unit: activeUnitIndex,
            fromDate: {
              value: fromDate,
              errorkey: ''
            },
            fromTime: {
              value: fromTime,
              errorkey: ''
            },
            toDate: {
              value: toDate,
              errorkey: ''
            },
            toTime: {
              value: toTime,
              errorkey: ''
            }
          },
          outputChannelID: {
            value: outputChannelID,
            errorkey: ''
          },
          messageID: {
            value: messageID,
            errorkey: ''
          },
          text: {
            value: text,
            errorkey: ''
          }
        }
      })
    }
  }

  /**
   * @description Handles changes in input fields.
   * @param id The id of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInputChange = (id, value, errorkey) => {
    this.setState({
      [id]: {
        value, errorkey
      }
    })
  }

  /**
   * @description Gets the translated dropdown items.
   * @param {Array} items The array which includes all items.
   */
  getTranslatedDropdownItems = (items) => {
    const translatedItems = []
    items.forEach(entry => {
      translatedItems.push(translate(entry.translationKey))
    })

    return translatedItems
  }

  /**
   * @description Validates all elements from the timecard component. Checks if date inputs are valid dates and if given, the end date is not before the start date.
   */
  validateTimecardErrors = () => {
    const { time } = this.state
    const { datemask } = this.props
    let errorObj = {}
    if (time.activeTimeTabIndex === 1) {
      if (time.fromDate.value !== '' && !DateUtils.isDate(time.fromDate.value, datemask) && !DateUtils.validDateStrings.includes(time.fromDate.value.toUpperCase())) {
        errorObj.fromDate = {
          value: time.fromDate.value,
          error: 'general.invalid_date_format'
        }
      }
      if (time.toDate.value !== '' && !DateUtils.isDate(time.toDate.value, datemask) && !DateUtils.validDateStrings.includes(time.toDate.value.toUpperCase())) {
        errorObj.toDate = {
          value: time.toDate.value,
          error: 'general.invalid_date_format'
        }
      }
      if (time.fromTime.value !== '' && !time.fromTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errorObj.fromTime = {
          value: time.fromTime.value,
          error: 'general.invalid_time_format'
        }
      }
      if (time.toTime.value !== '' && !time.toTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errorObj.toTime = {
          value: time.toTime.value,
          error: 'general.invalid_time_format'
        }
      }
    }
    // Set error if the to date is before the from date and both inputs are filled with a date.
    if (!errorObj.toDate) {
      if (time.fromDate.value !== '' && time.toDate.value !== '') {
        if (DateUtils.isDate(time.fromDate.value, datemask) && DateUtils.isDate(time.toDate.value, datemask)) {
          const fromDate = moment(time.fromDate.value, datemask)
          const toDate = moment(time.toDate.value, datemask)
          if (toDate.isBefore(fromDate)) {
            errorObj.toDate = {
              value: time.toDate.value,
              error: 'statistic.negative_date_difference_error'
            }
          }
        }
      }
    }
    this.setState({ time: { ...time, ...errorObj } })
    return Object.keys(errorObj).length
  }

  handleSearch = event => {
    const { datemask } = this.props
    event.preventDefault()

    const {
      time,
      outputChannelID,
      messageID,
      text
    } = this.state

    const errors = this.validateTimecardErrors()
    if (errors === 0) {
      const prefsToChange = {
        [Preferences.SERVER_PDI_ACTIVE_TAB]: time.activeTimeTabIndex,
        [Preferences.SERVER_PDI_LASTTIME_MODE]: time.type,
        [Preferences.SERVER_PDI_CUSTOM_LAST]: time.period,
        [Preferences.SERVER_PDI_CUSTOM_UNIT]: UNITS[time.unit].key,
        [Preferences.SERVER_PDI_FROMDATE]: DateUtils.getRequestFormat(time.fromDate.value, datemask),
        [Preferences.SERVER_PDI_FROMTIME]: DateUtils.formatTimeToDefault(time.fromTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SERVER_PDI_TODATE]: DateUtils.getRequestFormat(time.toDate.value, datemask),
        [Preferences.SERVER_PDI_TOTIME]: DateUtils.formatTimeToDefault(time.toTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SERVER_PDI_OUTPUTCHANNELID]: outputChannelID.value,
        [Preferences.SERVER_PDI_MESSAGEID]: messageID.value,
        [Preferences.SERVER_PDI_TEXT]: text.value
      }

      this.props.changePrefs(prefsToChange)

      const searchParams = {
        ...time.activeTimeTabIndex === 0 && {
          ...LASTTIME_MODES[time.type].key === LASTTIME_MODE_TODAY && {
            'SDATE': 'TODAY'
          },
          ...LASTTIME_MODES[time.type].key === LASTTIME_MODE_YESTERDAY && {
            'SDATE': 'YESTERDAY'
          },
          ...LASTTIME_MODES[time.type].key === LASTTIME_MODE_CUSTOM && {
            'FROMLAST': time.period,
            'TUNITS': UNITS[time.unit].key
          },
        },
        ...time.activeTimeTabIndex === 1 && {
          'SDATE': DateUtils.getTimeshiftDate(time.fromDate.value, time.fromTime.value, DateUtils.DDMMYYYY_DOT),
          ...time.fromTime.value !== '' && {
            'STIME': DateUtils.getTimeshiftDate(time.fromDate.value, time.fromTime.value, DateUtils.TIME_DATEMASK),
          },
          'EDATE': DateUtils.getTimeshiftDate(time.toDate.value, time.toTime.value, DateUtils.DDMMYYYY_DOT),
          ...time.toTime.value !== '' && {
            'ETIME': DateUtils.getTimeshiftDate(time.toDate.value, time.toTime.value, DateUtils.TIME_DATEMASK)
          }
        },
        'DCR': outputChannelID.value,
        'MSGNR': messageID.value,
        'MSGTXT': text.value
      }

      this.props.getPrinterDeviceInfo(searchParams)
    }
  }

  onOpenOutputChanelDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showOutputChanelDefinitionsSelectorDialog: true })
    }

    this.props.getOutputChanelDefinitions(
      ['DCR', 'DCRTITLE'],
      this.state.outputChannelID.value,
      callback)
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props

    return (
      <Card title={translate('general.general')}>
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            <Input
              id={`${id}_outputchannelid`}
              onInputChanged={(val, err) => { this.handleInputChange('outputChannelID', val, err) }}
              value={this.state.outputChannelID.value}
              title={translate('definition.output_channel')}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputChanelDefinitionDialog()
              }}
              error={this.state.outputChannelID.errorkey && translate(this.state.form.errorkey)}
              maxLength={16}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            <Input
              id={`${id}_messageid`}
              onInputChanged={(val, err) => { this.handleInputChange('messageID', val, err) }}
              value={this.state.messageID.value}
              title={translate('server.message_id')}
              error={this.state.messageID.errorkey && translate(this.state.messageID.errorkey)}
              maxLength={8}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={12}
            offsetMD={0}>
            <Input
              id={`${id}_text`}
              onInputChanged={(val, err) => { this.handleInputChange('text', val, err) }}
              value={this.state.text.value}
              title={translate('general.text')}
              error={this.state.text.errorkey && translate(this.state.text.errorkey)}
              maxLength={40}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Handles the changes in timecard.
   * @param {String} key The key of the input in state.
   * @param {String} val The new value of the input.
   * @param {String} err The new error of the input.
   */
  handleTimeCardChange = (key, val, err) => {
    this.setState(state => ({
      time: {
        ...state.time,
        [key]: typeof state.time[key] === 'object'
          ? {
            value: val,
            error: err || ''
          }
          : val
      }
    }))
  }

  /**
   * @description Returns the values for the timecard.
   * @returns {Object} The timecard values.
   */
  timeCardValues = () => {
    const { time } = this.state
    return {
      tabIndex: time.activeTimeTabIndex,
      lastTimeModeIndex: time.type,
      customLast: time.period,
      customUnitIndex: time.unit,
      fromDate: {
        value: time.fromDate.value,
        error: time.fromDate.error
      },
      fromTime: {
        value: time.fromTime.value,
        error: time.fromTime.error
      },
      toDate: {
        value: time.toDate.value,
        error: time.toDate.error
      },
      toTime: {
        value: time.toTime.value,
        error: time.toTime.error
      }
    }
  }

  /**
   * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
   * Needed for update function inside the TimeCard.
   * @returns {Object} The object with the state keys.
   */
  timeCardStateKeys = () => {
    return {
      tabIndex: 'activeTimeTabIndex',
      lastTimeModeIndex: 'type',
      customLast: 'period',
      customUnitIndex: 'unit',
      fromDate: 'fromDate',
      fromTime: 'fromTime',
      toDate: 'toDate',
      toTime: 'toTime',
    }
  }

  /**
   * @description Renders the time card.
   */
  renderTimeCard = () => {
    const { id, lang, datemask } = this.props
    return (
      <TimeCard
        id={id}
        lang={lang}
        datemask={datemask}
        values={this.timeCardValues()}
        stateKeys={this.timeCardStateKeys()}
        onValuesChange={this.handleTimeCardChange}
        parentContainer={'drawer_content_server_body_main'}
        translate={key => translate(key)}
      />
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}
        tabIndex={-1}>
        {this.renderTimeCard()}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleOnReset}
        />
      </div>
    )
  }

  renderSelectorDialogs = () => {
    const { id } = this.props

    let outputChanelDefinitions
    if (this.state.showOutputChanelDefinitionsSelectorDialog) {
      outputChanelDefinitions = this.props.selector.outputchannels
    }

    return (
      <>
        {this.state.showOutputChanelDefinitionsSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputChanel_definitions`}
            onClose={() => { this.setState({ showOutputChanelDefinitionsSelectorDialog: false }) }}
            title={translate('definition.output_channel.title')}
            header={[
              translate('general.output_channel'),
              translate('general.title'),
            ]}
            items={outputChanelDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newFolder = outputChanelDefinitions.data[selectedRows][outputChanelDefinitions.header.indexOf('DCR')]
                this.setState({
                  outputChannelID: { value: newFolder, errorkey: '' },
                  showOutputChanelDefinitionsSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputChanelDefinitionsSelectorDialog: false
                })
              }
            }}
          />
        )}

      </>
    )
  }

  render = () => {
    const { id } = this.props
    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={() => { }}>
        {this.renderMain()}
        {this.renderFooter()}
        {this.renderSelectorDialogs()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences.DATEMASK,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPrinterDeviceInfo: (searchParam, callback) => {
      PrinterDeviceInfoActions.getPrinterDeviceInfo(searchParam, callback)(dispatch)
    },
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getOutputChanelDefinitions: (fields, outputChanel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChanel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback
      )(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerPrinterDeviceInfo)