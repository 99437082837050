import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { AVAILABLE_ITEMS } from 'utils/AssignmentUtils'
import * as SortUtils from 'utils/SortUtils'

// components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CopyIndexDocumentAssignmentDialog from 'components/dialogs/copy_index_document_assignment_dialog/CopyIndexDocumentAssignmentDialog'
import CreateIndexDocumentAssignmentDialog from 'components/dialogs/create_index_document_assignment_dialog/CreateIndexDocumentAssignmentDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyIndexDocumentAssignmentDialog from 'components/dialogs/modify_index_document_assignment_dialog/ModifyIndexDocumentAssignmentDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as IndexDocumentAssignmentActions from 'redux/actions/IndexDocumentAssignmentActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as UserUtils from 'utils/UserUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultDatabaseStatus extends Component {

  state = {
    showTableSettingsDialog: false,
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: INDEXID, translation: 'definition.index_id', default: true },
      { rest: FORM, translation: 'general.form', default: true },
      { rest: EXT, translation: 'general.extension', default: true },
      { rest: REPORT, translation: 'general.report', default: true },
      { rest: VARIANT, translation: 'definition.index_document_assignment.variant', default: true },
      { rest: AVAILABLE, translation: 'general.available', default: true },
      { rest: VALUES_REQUIRED, translation: 'definition.index_document_assignment.values_required', default: true },
      { rest: SECURITY_CHECK, translation: 'definition.index_document_assignment.security_check', default: true },
      { rest: VALID_FROM, translation: 'general.valid_from', default: true },
      { rest: VALID_TO, translation: 'general.valid_to', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER }
    ]
  }

  /**
   * @description Handles the modal dialogs for create, modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { getIndexDocumentAssignment, indexDocumentAssignments } = this.props
    getIndexDocumentAssignment(
      undefined,
      indexDocumentAssignments.data[index][this.headerData('IXINAME')],
      indexDocumentAssignments.data[index][this.headerData('FORM')],
      indexDocumentAssignments.data[index][this.headerData('EXT')],
      indexDocumentAssignments.data[index][this.headerData('REPORT')],
      indexDocumentAssignments.data[index][this.headerData('IXINSTNR')],
      () => this.setState({ [dialog]: true })
    )
  }

  /**
   * @description Deletes the selected assignment
   */
  deleteIndexDocumentAssignment = () => {
    const { indexDocumentAssignment, deleteIndexDocumentAssignment } = this.props
    const indexDocument = {
      IXINAME: indexDocumentAssignment.IXINAME,
      FORM: indexDocumentAssignment.FORM,
      EXT: indexDocumentAssignment.EXT,
      REPORT: indexDocumentAssignment.REPORT,
      IXINSTNR: indexDocumentAssignment.IXINSTNR,
    }
    deleteIndexDocumentAssignment(indexDocument, () => this.setState({ showDeleteDialog: false }))
  }

  /**
   * @description Refreshs the current table.
   */
  handleRefresh = () => {
    const { preferences, getIndexDocumentAssignments } = this.props
    getIndexDocumentAssignments(
      undefined,
      preferences[Preferences.ASSIGNMENT_INDEX_DOC_INDEXID],
      preferences[Preferences.ASSIGNMENT_INDEX_DOC_FORM],
      preferences[Preferences.ASSIGNMENT_INDEX_DOC_EXTENSION],
      preferences[Preferences.ASSIGNMENT_INDEX_DOC_REPORT],
      preferences[Preferences.ASSIGNMENT_INDEX_DOC_AVAILABLE],
      'I' // sort by default 'I' for now, could be changed later
    )
  }


  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
  * @description gets the index of the header in redux state
  * @param {String} header header name of the header in redux
  */
  headerData = header => this.props.indexDocumentAssignments.header.indexOf(header)

  /**
   * @description Creates the action button for a row.
   * @param {Number} rowIndex The index of the row.
   * @returns {Array} The action buttons.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.setState({ showCreateDialog: true })}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, indexDocumentAssignments } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    indexDocumentAssignments.data.forEach(element => {
      const dataBuffer = []
      usedHeader.forEach(h => {
        if (h === VALID_FROM) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData(VALID_FROM)]))
        }
        else if (h === AVAILABLE) {
          const found = AVAILABLE_ITEMS.find(el => el.key === element[this.headerData('IXSCOPE')])
          dataBuffer.push(found ? translate(found.translationKey) : element[this.headerData('IXSCOPE')])
        }
        else if (h === VALUES_REQUIRED) {
          dataBuffer.push(
            translate(element[this.headerData('IXENODAT')] === 'NO' ? 'general.no' : 'general.yes')
          )
        }
        else if (h === SECURITY_CHECK) {
          dataBuffer.push(
            translate(element[this.headerData('IXSCHECK')] === 'NO' ? 'general.no' : 'general.yes')
          )
        }
        else if (h === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === VALID_TO) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData(VALID_TO)]))
        }
        else if (h === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.delete')} ${translate('general.index_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'folder_document_relation_modal_title'} name={'relation'} tabIndex={-1}/>,
      <div
        key={'title_3'}>
        {`${UserUtils.isDOCX()
          ? translate('general.document_modal_title')
          : translate('general.log_modal_title')}  ${translate('general.assignment_modal_title')}`}
      </div>,
    ]
  }

  getModalQuestion = () => {
    return [
      <div key={'title_1'}>{translate('assignment.index_document_delete_question_1')}</div>,
      <Icon key={'title_2'} id={'index_document_relation_modal_question'} name={'relation'} />,
      <div key={'title_3'}>{translate(UserUtils.isDOCX() ? 'assignment.index_document_delete_question_2' : 'assignment.index_log_delete_question_2')}</div>
    ]
  }

  getNoResultTitle = () => {
    if (UserUtils.isDOCX()) {
      return [
        `${translate('assignment.no_alt')} `,
        `${translate('general.index_modal_title')}`,
        <Icon key={'index_document_relation_modal_title'} id={'index_document_relation_modal_title'} name={'relation'} />,
        `${translate('general.document_modal_title')} `,
        `${translate('general.assignment_modal_title')}`,
        ` ${translate('assignment.no_match_single')}`
      ]
    }
    else if (UserUtils.isLOGX()) {
      return [
        `${translate('assignment.no_alt')} `,
        `${translate('general.index_modal_title')}`,
        <Icon key={'indexlog_relation_modal_title'} id={'index_document_relation_modal_title'} name={'relation'} />,
        `${translate('general.log_modal_title')} `,
        `${translate('general.assignment_modal_title')}`,
        ` ${translate('assignment.no_match_single')}`
      ]
    }
  }

  getNoResultLink = () => {
    if (UserUtils.isDOCX()) {
      return [
        `${translate('assignment.you_can')} `,
        `${translate('general.index_modal_title')}`,
        <Icon key={'index_document_relation_modal_link'} id={'index_document_relation_modal_link'} name={'relation'} />,
        `${translate('general.document_modal_title')} `,
        `${translate('general.assignment_modal_title')}`,
        ` ${translate('assignment.create')}`
      ]
    }
    else if (UserUtils.isLOGX()) {
      return [
        `${translate('assignment.you_can')} `,
        `${translate('general.index_modal_title')}`,
        <Icon key={'index_log_relation_modal_link'} id={'index_document_relation_modal_link'} name={'relation'} />,
        `${translate('general.log_modal_title')} `,
        `${translate('general.assignment_modal_title')}`,
        ` ${translate('assignment.create')}`
      ]
    }
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_INDEX_DOCUMENT_ASSIGNMENT]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_INDEX_DOCUMENT_ASSIGNMENT].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_INDEX_DOCUMENT_ASSIGNMENT]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_INDEX_DOCUMENT_ASSIGNMENT].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  render = () => {
    const { indexDocumentAssignments, indexDocumentAssignment, id, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showCopyDialog, showModifyDialog, showDeleteDialog } = this.state
    const data = indexDocumentAssignments && indexDocumentAssignments.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {showCreateDialog &&
          <CreateIndexDocumentAssignmentDialog
            id={`${id}_create_dialog`}
            onClose={() => this.setState({ showCreateDialog: false })}
          />}
        {showCopyDialog &&
          <CopyIndexDocumentAssignmentDialog
            id={`${id}_copy_dialog`}
            onClose={() => this.setState({ showCopyDialog: false })}
          />}
        {showModifyDialog &&
          <ModifyIndexDocumentAssignmentDialog
            id={`${id}_modify_dialog`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_INDEX_DOCUMENT_ASSIGNMENT }}
          />
        )}
        {showDeleteDialog &&
          <DeleteDialog
            id={`${id}_deleteindexdocumentassignmentdialog`}
            title={this.getModalTitle()}
            question={this.getModalQuestion()}
            onClose={() => { this.setState({ showDeleteDialog: false }) }}
            onDelete={() => { this.deleteIndexDocumentAssignment() }}>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_index_id_key_text`}>
                  {translate('definition.index_id')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_index_id_value_text`}>
                  {indexDocumentAssignment.IXINAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_index_variant_key_text`}>
                  {translate('definition.index_document_assignment.variant')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_index_variant_value_text`}>
                  {indexDocumentAssignment.IXINSTNR}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_index_form_key_text`}>
                  {translate('general.form')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_index_form_value_text`}>
                  {indexDocumentAssignment.FORM}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_index_extension_key_text`}>
                  {translate('general.extension')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_index_extension_value_text`}>
                  {indexDocumentAssignment.EXT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_index_report_key_text`}>
                  {translate('general.report')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_index_report_value_text`}>
                  {indexDocumentAssignment.REPORT}
                </p>
              </Column>
            </Row>
          </DeleteDialog>}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {indexDocumentAssignments
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={(index) => { this.handleDialog(index, 'showModifyDialog') }}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={this.getNoResultTitle()}
                    link={this.getNoResultLink()}
                    onClick={() => this.setState({ showCreateDialog: true })}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const INDEXID = 'IXINAME'
const FORM = 'FORM'
const EXT = 'EXT'
const REPORT = 'REPORT'
const VARIANT = 'IXINSTNR'
const AVAILABLE = 'IXSCOPE'
const VALUES_REQUIRED = 'IXENODAT'
const SECURITY_CHECK = 'IXSCHECK'
const VALID_FROM = 'VDATEB'
const VALID_TO = 'VDATEE'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

SearchResultDatabaseStatus.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    indexDocumentAssignments: state.assignments.indexdocumentassignment.indexDocumentAssignments,
    indexDocumentAssignment: state.assignments.indexdocumentassignment.indexDocumentAssignment,
    keepPagination: state.assignments.indexdocumentassignment.keepPagination,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getIndexDocumentAssignments: (fields, indexID, form, ext, report, scope, sortBy, callback) => {
      IndexDocumentAssignmentActions.getIndexDocumentAssignments(
        fields,
        indexID,
        form,
        ext,
        report,
        scope,
        sortBy,
        callback)(dispatch)
    },
    getIndexDocumentAssignment: (fields, indexID, form, ext, report, variantNumber, callback) => {
      IndexDocumentAssignmentActions.getIndexDocumentAssignment(fields, indexID, form, ext, report, variantNumber, callback)(dispatch)
    },
    deleteIndexDocumentAssignment: (indexDocument, callback) => {
      IndexDocumentAssignmentActions.deleteIndexDocumentAssignment(indexDocument, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultDatabaseStatus)