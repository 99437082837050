import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ActionButtons extends Component {

  render = () => {
    const { id, index, expandable } = this.props
    return (
      this.props.createActionButtons && this.props.createActionButtons(index).map((button, i) => {
        return (
          <td
            id={`${id}_actionbutton_${i + (expandable ? 1 : 0)}`}
            style={{ right: (this.props.createActionButtons(index).length - 1 - i) * 40 }}
            className={'bux_datatable_actionbutton'} key={`actionButton_${i}`}
            // WIP: Due to the nesting of the action button(s) within the data table row and the usage of 'createPortal' in <TableButtonGroup...> the data of the context menu needs to be updated before the DOM elements for the context menu are injected via "createPortal"
            { ...button.props.id.includes('tableButtonMore') && { onMouseEnter: () => this.props._WIP_updateRenderHelperValue(!this.props._WIP_renderHelperValue) } }
            { ...button.props.id.includes('tableButtonMore') && { onClick: () => this.props._WIP_updateRenderHelperValue(!this.props._WIP_renderHelperValue) } }
            >
            {button}
          </td>
        )
      })
    )
  }
}

ActionButtons.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string,
  /** Row index */
  index: PropTypes.number,
  /** Idicates if action is expandable or not*/
  expandable: PropTypes.bool,
  /**
   * Function called to creates buttons for given row index.
   * @param {number} index Index of the row
   * @returns {Element[]} array of jsx buttons
   * */
  createActionButtons: PropTypes.func
}