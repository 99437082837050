import PropTypes from 'prop-types'
import { Component } from 'react'

// Locals
import { translate } from 'language/Language'

// Components
import { Dropdown } from 'BetaUX2Web-Components/src/'

// React Router
import { withRouter } from 'react-router'

// Redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import StatisticBody from './statistic_body/StatisticBody'


class Statistic extends Component {

  /**
   * @description Get the items for the statistic dropdown.
   */
  getStatisticItems() {
    const items = []

    this.props.items.forEach(item =>
      items.push(item.title)
    )

    return items
  }

  /**
   * @description Changes the selected position of the statistic dropdown.
   * @param {Number} activeIndex The new selected index position of the statistic dropdown.
   */
  handleChangeStatistic(activeIndex) {
    const link = this.getLink(activeIndex)

    if (link !== undefined)
      this.props.history.push(link)
  }

  /**
   * @description Gets the index of a definition to select.
   * @param {String} definition The definition to select.
   */
  getActiveStatisticIndex(definition) {
    // get the index of the match with the link of the item and the definition
    let index = this.props.items.findIndex((item) => item.link.substring(item.link.lastIndexOf('/') + 1) === definition)

    // select first index if there is no item selected
    if (index === -1)
      index = 0

    return index
  }

  /**
   * @description Gets the link for a specific index.
   * @param {Number} activeIndex The index to find the link.
   */
  getLink(activeIndex) {
    // get the object with the current active index and return the link
    return this.props.items.find((item, currentIndex) => currentIndex === activeIndex).link
  }

  render = () => {
    const { id, match } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    return (
      <div id={id} className='drawer_field'>
        <div id={`${id}_definition_container1`} className='drawer_header_big bux_drawer_header'>
          <div id={`${id}_definition_container2`} className='row search_row'>
            <div id={`${id}_definition_container3`} className='dropdown el_dropdown'>
              <Dropdown
                id={`${id}_definition`}
                title={translate('general.statistic')}
                items={this.getStatisticItems()}
                activeIndex={this.getActiveStatisticIndex(url)}
                onChange={(activeIndex) => { this.handleChangeStatistic(activeIndex) }}
              />
            </div>
          </div>
        </div>

        <StatisticBody
          id={`${id}_body`}
          statisticMode={url}
          history={this.props.history}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

Statistic.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withRouter(Statistic))