import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../icon/Icon'
import ActionButtons from './ActionButtons'
import Checkbox from '../checkbox/Checkbox'
import hash from 'object-hash'
import './HierarchyRow.scss'

export default class HierarchyRow extends Component {

  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string,
    /** Level of the hierarchy depth */
    level: PropTypes.number,
    /** Index of the row */
    index: PropTypes.number,
    /** Array of data to be displayed in row */
    data: PropTypes.array.isRequired,
    /** Number of action buttons for row */
    actionButtonsLength: PropTypes.number,
    /** Used to display the numbers in the right language format (e.g. 130.000 or 130,000 or 130 000). */
    language: PropTypes.any,
    /**
     * Function to translate following keys by our own.
     *
     * - `general.no_data_found` => will be displayed when a hierarchy entry is opened but has no childs
     *
     * @param {string} key
     */
    translate: PropTypes.func,
    /** Length of the data array */
    rowLength: PropTypes.number,
    /** Used when each row of the DataHierarchy component should be selectable for e.g. download option. */
    selectable: PropTypes.bool,
    /**
     * Stores the child entries. Each array entry stores all child information for a specific level.
     *
     * It needs to have following structure:
     *
     * ```
     * hierarchyEntries = [
     *   {
     *     parentKey: string,
     *     rootKey: string,
     *     level: number,
     *     data: [
     *        [ your data ],
     *        [ your data ]
     *      ]
     *   },
     *   {
     *     parentKey: string,
     *     rootKey: string,
     *     level: number,
     *     data: [ [ your data ] ]
     *    },
     *   .
     *   .
     *   .
     * ]
     * ```
     */
    hierarchyEntries: PropTypes.arrayOf(PropTypes.object),
    /**
     * Will be executed when the click on a row is detected and childs are still visible.
     * @param {array} newHierarchyEntries Provide updated structure of hierarchyEntries as a array of visible entries after closing one of them.
     */
    onCloseChilds: PropTypes.func,
    /**
     * Will be executed when the click on a row is detected and no childs are still visible.
     * Allows to generate new entries based on the one clicked one.
     * @param {string} parentKey Parent data hash key
     * @param {string} rootKey Root data hash key
     * @param {function} hash Provided hash function
     * @param {number} level Level of the hierarchy depth
     * @param {array<array<string>} data Array of rows
     */
    onOpenChilds: PropTypes.func,
    /** Function to detect last row clicked */
    detectLastRowClick: PropTypes.func,
    /** Indicates if the row is open */
    isOpen: PropTypes.bool,
    /** Parent data hash key */
    parentKey: PropTypes.string,
    /** Root data hash key */
    rootKey: PropTypes.string,
    /** Types of each column to sort correctly. */
    columnSortDefs: PropTypes.array,
    /**
     * Function that returns array of JSX elements which are displayed at end of each row.
     *
     * @param {number} rowIndex index of the row
     * @return {array<element>} elements
     */
    createActionButtons: PropTypes.func,
    /**
     *  Function provided in order to check if the row is checked
     *  @param {string} rowDataHash Hashed value of the row data
     */
    isRowChecked: PropTypes.func,
    /**
     * Function to be called on check or uncheck of the checkbox
     * @param {string} rowDataHash Hashed value of the row data
     */
    handleCheck: PropTypes.func,
    /** Array of selected rows */
    selectedRows: PropTypes.any,
    /** 
     * Set to false disables onOpenChilds and onCloseChilds functions
     * also it hides 'the hierarchy_opened', 'hierarchy_closed' icons and set cursor to default on row hover.
     * @default true
     */
    shouldHandleActionClick: PropTypes.bool, 
  }

  state = {
    hover: false,
  }

  /**
   * @description Resets the hover state on scrolling.
   * @param nextProps The next properties the component will receive.
   */
  componentWillReceiveProps = nextProps => {
    if (nextProps.isScrolling) {
      this.setState({ hover: false })
    }
  }

  /**
   * @description Formats a given number
   * @param {number} number The number.
   */
  // TODO: find a workaround when we start to work with numbers with decimal places
  formatNumber = number => {
    const { language } = this.props
    const EN = 'en'
    const DE = 'de'
    const FR = 'fr'
    const regex = /\B(?=(\d{3})+(?!\d))/g
    if (language === EN) {
      return number.toString().replace(regex, ',')
    }
    else if (language === DE) {
      return number.toString().replace(regex, '.')
    }
    else if (language === FR) {
      return number.toString().replace(regex, ' ')
    }
    else {
      return number
    }
  }

  /**
   * @description Renders the amount of placeholders needed for different levels.
   * @returns {Array.<Element>}
   */
  renderPlaceholder = level => {
    let buffer = []
    for (let i = 0; i < level; i++) {
      buffer.push(<div key={`hierarchy_placeholder_${i}`} className={'bux_hierarchy_placeholder'} />)
    }
    return buffer
  }

  /**
   * @description Renders an empty row, which is displayed, when no child data is found.
   */
  renderEmptyRow = () => {
    const { actionButtonsLength, translate, rowLength, level, index, id, selectable } = this.props
    return (
      <tr className={'bux_hierarchy_no_data_found'} id={`${id}_hierarchyrow_${index}`}>
        <td colSpan={rowLength + actionButtonsLength + (selectable ? 1 : 0)}>
          <div className={'bux_hierarchy_no_data_found_container'}>
            {this.renderPlaceholder(level)}
            <div>{translate('general.no_data_found')}</div>
          </div>
        </td>
      </tr>
    )
  }

  /**
   * @description Handles the click event on a row.
   * If the row is still open and needs be closed, the function finds out, which rows need to be closed.
   */
  handleClick = (parentKey, rootKey, level, isOpen) => {
    const { data, hierarchyEntries, onCloseChilds, onOpenChilds, detectLastRowClick } = this.props
    if (onCloseChilds && onOpenChilds) {
      if (isOpen) {
        let dataToRemove = []
        let keys = [parentKey]
        for (let i = 0; i < hierarchyEntries.length; i++) {
          const keysBuffer = []
          keys.forEach(key => {
            for (let j = 0; j < hierarchyEntries.length; j++) {
              if (hierarchyEntries[j].parentKey === key && hierarchyEntries[j].rootKey === rootKey && hierarchyEntries[j].level >= level) {
                dataToRemove.push(hash(hierarchyEntries[j]))
                hierarchyEntries[j].key.forEach(d => {
                  keysBuffer.push(d)
                })
                break
              }
            }
          })
          keys = keysBuffer
        }
        let newhierarchyEntries = hierarchyEntries.filter(d => !dataToRemove.includes(hash(d)))
        onCloseChilds(newhierarchyEntries)
      }
      else {
        onOpenChilds(parentKey, rootKey, key => hash(key), level, data)
      }
    }
    detectLastRowClick && !isOpen && detectLastRowClick()
  }

  /**
   * @description Renders one hierarchy row with, when given, all action buttons.
   */
  renderRow = () => {
    const {
      data, level, index, isOpen, id, parentKey, rootKey, columnSortDefs, createActionButtons, selectable, isRowChecked,
      handleCheck, actionButtonsLength, selectedRows
    } = this.props
    const { hover } = this.state
    return (
      <>
        <tr
          className={'bux_hierarchy_row'}
          id={`${id}_hierarchyrow_${index}`}
          tabIndex={0}
          onFocus={() => this.setState({ hover: true })}
          onBlur={() => this.setState({ hover: false })}
          onKeyDown={e => e.key === 'Enter' && this.props.shouldHandleActionClick && this.handleClick(parentKey, rootKey, level + 1, isOpen) && false}>
          {
            selectable &&
            <td className={'bux_hierarchy_checkbox'}>
              <Checkbox
                id={`${id}_hierarchyrow_${index}_checkbox`}
                value={isRowChecked(hash(data))}
                onCheck={() => handleCheck(hash(data))}
              />
            </td>
          }
          {data.map((cellData, i) => {
            const hashedData = hash(data)
            const isRowSelected = selectedRows &&
              selectedRows.findIndex(d => d.hashedData === hashedData && d.rootKey === rootKey && d.parentKey === parentKey && d.level === level) !== -1
            return (
              (React.isValidElement(cellData) || typeof cellData !== 'object') &&
              <td
                className={`
                ${React.isValidElement(cellData) ? 'center' : ''}
                ${hover ? 'bux_fake_hover' : ''}
                ${isRowSelected ? 'bux_fake_select' : ''}
                ${!this.props.shouldHandleActionClick ? 'bux_disable_cursor_pointer' : ''}
                `}
                key={`cellData_${parentKey}_${i}`}
                id={`${id}_col_${i}`}
                onMouseOver={() => this.setState({ hover: true })}
                onMouseOut={() => this.setState({ hover: false })}
                onFocus={() => this.setState({ hover: true })}
                onBlur={() => this.setState({ hover: false })}
                onClick={this.props.shouldHandleActionClick ? () => this.handleClick(parentKey, rootKey, level + 1, isOpen) : () => undefined}
              >
                <div className={'bux_celldata_container'}>
                  {
                    i === 0 &&
                    <div className={'bux_hierarchy_row_icon_container'}>
                      {this.renderPlaceholder(level)}
                      {this.props.shouldHandleActionClick ? 
                        <Icon name={isOpen ? 'hierarchy_opened' : 'hierarchy_closed'} /> :
                        <div className={'bux_hierarchy_empty_icon_size'} />
                      }
                    </div>
                  }
                  <div className={'bux_celldata'}>{(columnSortDefs[i] === 'number' && typeof cellData !== 'string') ? this.formatNumber(cellData) : cellData}</div>
                </div>
              </td>
            )
          })}
          {
            createActionButtons &&
            <ActionButtons
              dataObj={data}
              createActionButtons={createActionButtons}
              index={index}
              actionButtonsLength={actionButtonsLength}
              parentKey={parentKey}
              rootKey={rootKey}
              level={level}
            />
          }
        </tr>
      </>
    )
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { data } = this.props
    return (
      <>
        {
          Array.isArray(data)
            ? this.renderRow()
            : this.renderEmptyRow()
        }
      </>
    )
  }
}

HierarchyRow.defaultProps = { shouldHandleActionClick: true }