import store from 'redux/Store'
import * as Preferences from 'redux/general/Preferences'
import * as Lang from 'language/Language'
import * as Configs from '../../config/Configs';

/**
 * @description Creates a cancelable promise with a timeout.
 * @param {Object} promise The promise which is wrapped from this promise.
 * @param {int|undefined} timeout The optional timeout for the connection. This is not the timeout for getData.
 */
export const makeCancelable = (promise, timeout = undefined) => {
  let hasCanceled = false

  const serverTimeout = store.getState()?.auth?.serverdata?.restconfig?.serverTimeout

  let connectTimeout = timeout || serverTimeout || Configs.CONNECT_TIMEOUT

  // get the language from redux
  let lang
  if (store.getState().auth.loggedIn) {
    lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
  }

  // create a wrapped promise which we can cancel
  const wrappedPromise = new Promise((resolve, reject) => {
    // if we canceled the promise -> set flag isCanceled to true
    // we can ask the error if the promise was canceled
    promise
      .then(
        val => hasCanceled ? reject({ isCanceled: true }) : resolve(val),
        error => hasCanceled ? reject({ isCanceled: true }) : reject(error)
      )
      .catch(
        // reject if there is no connection possible
        // note: we didn't cancel the request here
        // this timeout is different to the "getData" timeout. (noConnection !== requestToFetchDataTakesTooLong)
        // the timeout for getData have to declared where you call makeCancelable
        // (inside this timeout you have to call cancel on this promise)
        setTimeout(function () {
          reject(Lang.translate('general.no_connection_to_server', lang))
        }, connectTimeout)
      )
  })

  // return the promise and the possibility to cancel the promise
  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
    },
  }
}