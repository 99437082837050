import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import * as ObjectUtils from 'utils/ObjectUtils'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
export const GET_BUNDLE_QUEUES_START = 'GET_BUNDLE_QUEUES_START'
export const GET_BUNDLE_QUEUES_SUCCESS = 'GET_BUNDLE_QUEUES_SUCCESS'
export const GET_BUNDLE_QUEUES_FAILED = 'GET_BUNDLE_QUEUES_FAILED'
export const NO_BUNDLE_QUEUES_FOUND = 'NO_BUNDLE_QUEUES_FOUND'

export const GET_BUNDLE_QUEUE_DETAILS_START = 'GET_BUNDLE_QUEUE_DETAILS_START'
export const GET_BUNDLE_QUEUE_DETAILS_SUCCESS = 'GET_BUNDLE_QUEUE_DETAILS_SUCCESS'
export const GET_BUNDLE_QUEUE_DETAILS_FAILED = 'GET_BUNDLE_QUEUE_DETAILS_FAILED'

export const DELETE_BUNDLE_QUEUE_START = 'DELETE_BUNDLE_QUEUE_START'
export const DELETE_BUNDLE_QUEUE_SUCCESS = 'DELETE_BUNDLE_QUEUE_SUCCESS'
export const DELETE_BUNDLE_QUEUE_FAILED = 'DELETE_BUNDLE_QUEUE_FAILED'

export const UPDATE_BUNDLE_QUEUE_START = 'UPDATE_BUNDLE_QUEUE_START'
export const UPDATE_BUNDLE_QUEUE_SUCCESS = 'UPDATE_BUNDLE_QUEUE_SUCCESS'
export const UPDATE_BUNDLE_QUEUE_FAILED = 'UPDATE_BUNDLE_QUEUE_FAILED'

/**
 * @description Calling the rest api and list bundle queues.
 * @param {Object} searchParams The keywords for get bundle queues. Possible values:
 * DOCID, DCR, PCR, PREDRECI, RECI, FORM, EXT, REPORT, WREPORT, DOCUSR1, DOCUSR2, DOCUSR3,
 * DOCUSR4, DOCUSR5, DOCUSR6, DOCUSR7, DOCUSR8, SRCJOBN, BUNDLESTA
 * @param {Boolean} newSearch Flag, if a new search is done by user.
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function getBundleQueues(searchParams, callback, keepPagination = false,) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_BUNDLE_QUEUES_START })

    // clear invalid values of object and encode
    searchParams = ObjectUtils.removeByValue(searchParams, [undefined, null, ''])

    const queryParams = []
    Object.keys(searchParams).forEach(key => queryParams.push(`${key}=${encodeURIComponent(searchParams[key])}`))

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/bundle?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            // if (jsondata.data.length === 0) {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_BUNDLE_QUEUES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_BUNDLE_QUEUES_FAILED })
          }
        } else {
          dispatch({ type: GET_BUNDLE_QUEUES_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.bundle_queue_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_BUNDLE_QUEUES_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and get bundle queue details.
 * @param {String} docid DOCID of the document
 * @param {String} reci Recipient ID
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function getBundleQueueDetails(docid, reci, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_BUNDLE_QUEUE_DETAILS_START })

    const queryParams = []
    if (docid) { queryParams.push(`DOCID=${encodeURIComponent(docid)}`) }
    if (reci) { queryParams.push(`RECI=${encodeURIComponent(reci)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/bundle/details?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_BUNDLE_QUEUE_DETAILS_FAILED })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: GET_BUNDLE_QUEUE_DETAILS_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback(jsondata.data)
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.get_bundle_queue_details_error', lang, [docid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_BUNDLE_QUEUE_DETAILS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and delete a bundle queue.
 * @param {String} docid DOCID of the document
 * @param {String} reci Recipient ID
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function deleteBundleQueue(docid, reci, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: DELETE_BUNDLE_QUEUE_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/bundle${createQueryParamsForFetch({ DOCID: docid, RECI: reci })}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.additionalInfo[0].error.rc
          let irc = jsondata.additionalInfo[0].error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.additionalInfo[0].error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DELETE_BUNDLE_QUEUE_FAILED })
        } else {
          dispatch({ type: DELETE_BUNDLE_QUEUE_SUCCESS })

          SnackbarActions.show(Lang.translate('queue.delete_bundle_queue_success'), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }
          const searchParams = {
            DCR: prefs[Preferences.QUEUE_BUNDLE_OUTPUT_CHANNEL],
            PCR: prefs[Preferences.QUEUE_BUNDLE_OUTPUT_FORMAT],
            PREDRECI: prefs[Preferences.QUEUE_BUNDLE_ADDRESS],
            RECI: prefs[Preferences.QUEUE_BUNDLE_RECIPIENT],
            FORM: prefs[Preferences.QUEUE_BUNDLE_FORM],
            EXT: prefs[Preferences.QUEUE_BUNDLE_EXTENSION],
            REPORT: prefs[Preferences.QUEUE_BUNDLE_REPORT],
            WREPORT: prefs[Preferences.QUEUE_BUNDLE_WREPORT],
            SRCJOBN: prefs[Preferences.QUEUE_BUNDLE_JOBNAME],
            BUNDLSTA: prefs[Preferences.QUEUE_BUNDLE_ONLY_STATUS_HOLD] ? 'H' : '',
            DOCUSR1: prefs[Preferences.QUEUE_BUNDLE_DOCUSER1],
            DOCUSR2: prefs[Preferences.QUEUE_BUNDLE_DOCUSER2],
            DOCUSR3: prefs[Preferences.QUEUE_BUNDLE_DOCUSER3],
            DOCUSR4: prefs[Preferences.QUEUE_BUNDLE_DOCUSER4],
            DOCUSR5: prefs[Preferences.QUEUE_BUNDLE_DOCUSER5],
            DOCUSR6: prefs[Preferences.QUEUE_BUNDLE_DOCUSER6],
            DOCUSR7: prefs[Preferences.QUEUE_BUNDLE_DOCUSER7],
            DOCUSR8: prefs[Preferences.QUEUE_BUNDLE_DOCUSER8]
          }
          getBundleQueues(searchParams, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        LoadingSpinnerActions.hide()(dispatch)
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.delete_bundle_queue_error', lang, [reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DELETE_BUNDLE_QUEUE_FAILED })
      })
  }
}

/**
 * @description Calling the rest api and updates a bundle queue.
 * @param {String} docid DOCID of the document
 * @param {String} reci Recipient ID
 * @param {String} form The form (Only used for snackbar)
 * @param {String} extension The extension (Only used for snackbar)
 * @param {String} report The report (Only used for snackbar)
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function holdBundleQueue(docid, reci, form, extension, report, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const messageParams = []
    messageParams.push(`${Lang.translate('recipient.reci_id')}: ${reci}`)
    messageParams.push(`${Lang.translate('general.form')}: ${form}`)
    if (extension !== '') {
      messageParams.push(`${Lang.translate('general.extension')}: ${extension}`)
    }
    if (report !== '') {
      messageParams.push(`${Lang.translate('general.report')}: ${report}`)
    }
    const message = messageParams.join(', ')

    dispatch({ type: UPDATE_BUNDLE_QUEUE_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/bundle`, {
        method: 'put',
        body: {
          DOCID: docid,
          RECI: reci,
          PROCESS: 'HOLD'
        }
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: UPDATE_BUNDLE_QUEUE_FAILED })
        } else {
          dispatch({ type: UPDATE_BUNDLE_QUEUE_SUCCESS })

          SnackbarActions.show(Lang.translate('queue.hold_bundle_queue_success', lang, message), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }
          const searchParams = {
            DCR: prefs[Preferences.QUEUE_BUNDLE_OUTPUT_CHANNEL],
            PCR: prefs[Preferences.QUEUE_BUNDLE_OUTPUT_FORMAT],
            PREDRECI: prefs[Preferences.QUEUE_BUNDLE_ADDRESS],
            RECI: prefs[Preferences.QUEUE_BUNDLE_RECIPIENT],
            FORM: prefs[Preferences.QUEUE_BUNDLE_FORM],
            EXT: prefs[Preferences.QUEUE_BUNDLE_EXTENSION],
            REPORT: prefs[Preferences.QUEUE_BUNDLE_REPORT],
            WREPORT: prefs[Preferences.QUEUE_BUNDLE_WREPORT],
            SRCJOBN: prefs[Preferences.QUEUE_BUNDLE_JOBNAME],
            BUNDLSTA: prefs[Preferences.QUEUE_BUNDLE_ONLY_STATUS_HOLD] ? 'H' : '',
            DOCUSR1: prefs[Preferences.QUEUE_BUNDLE_DOCUSER1],
            DOCUSR2: prefs[Preferences.QUEUE_BUNDLE_DOCUSER2],
            DOCUSR3: prefs[Preferences.QUEUE_BUNDLE_DOCUSER3],
            DOCUSR4: prefs[Preferences.QUEUE_BUNDLE_DOCUSER4],
            DOCUSR5: prefs[Preferences.QUEUE_BUNDLE_DOCUSER5],
            DOCUSR6: prefs[Preferences.QUEUE_BUNDLE_DOCUSER6],
            DOCUSR7: prefs[Preferences.QUEUE_BUNDLE_DOCUSER7],
            DOCUSR8: prefs[Preferences.QUEUE_BUNDLE_DOCUSER8]
          }
          getBundleQueues(searchParams, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.hold_bundle_queue_error', lang, [reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPDATE_BUNDLE_QUEUE_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and release a bundle queue.
 * @param {String} docid DOCID of the document
 * @param {String} reci Recipient ID
 * @param {String} form The form (Only used for snackbar)
 * @param {String} extension The extension (Only used for snackbar)
 * @param {String} report The report (Only used for snackbar)
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function releaseBundleQueue(docid, reci, form, extension, report, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const messageParams = []
    messageParams.push(`${Lang.translate('recipient.reci_id')}: ${reci}`)
    messageParams.push(`${Lang.translate('general.form')}: ${form}`)
    if (extension !== '') {
      messageParams.push(`${Lang.translate('general.extension')}: ${extension}`)
    }
    if (report !== '') {
      messageParams.push(`${Lang.translate('general.report')}: ${report}`)
    }
    const message = messageParams.join(', ')

    dispatch({ type: UPDATE_BUNDLE_QUEUE_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/bundle`, {
        method: 'put',
        body: {
          DOCID: docid,
          RECI: reci,
          PROCESS: 'RELEASE'
        }
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: UPDATE_BUNDLE_QUEUE_FAILED })
        } else {
          dispatch({ type: UPDATE_BUNDLE_QUEUE_SUCCESS })

          SnackbarActions.show(Lang.translate('queue.release_bundle_queue_success', lang, message), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }
          const searchParams = {
            DCR: prefs[Preferences.QUEUE_BUNDLE_OUTPUT_CHANNEL],
            PCR: prefs[Preferences.QUEUE_BUNDLE_OUTPUT_FORMAT],
            PREDRECI: prefs[Preferences.QUEUE_BUNDLE_ADDRESS],
            RECI: prefs[Preferences.QUEUE_BUNDLE_RECIPIENT],
            FORM: prefs[Preferences.QUEUE_BUNDLE_FORM],
            EXT: prefs[Preferences.QUEUE_BUNDLE_EXTENSION],
            REPORT: prefs[Preferences.QUEUE_BUNDLE_REPORT],
            WREPORT: prefs[Preferences.QUEUE_BUNDLE_WREPORT],
            SRCJOBN: prefs[Preferences.QUEUE_BUNDLE_JOBNAME],
            BUNDLSTA: prefs[Preferences.QUEUE_BUNDLE_ONLY_STATUS_HOLD] ? 'H' : '',
            DOCUSR1: prefs[Preferences.QUEUE_BUNDLE_DOCUSER1],
            DOCUSR2: prefs[Preferences.QUEUE_BUNDLE_DOCUSER2],
            DOCUSR3: prefs[Preferences.QUEUE_BUNDLE_DOCUSER3],
            DOCUSR4: prefs[Preferences.QUEUE_BUNDLE_DOCUSER4],
            DOCUSR5: prefs[Preferences.QUEUE_BUNDLE_DOCUSER5],
            DOCUSR6: prefs[Preferences.QUEUE_BUNDLE_DOCUSER6],
            DOCUSR7: prefs[Preferences.QUEUE_BUNDLE_DOCUSER7],
            DOCUSR8: prefs[Preferences.QUEUE_BUNDLE_DOCUSER8]
          }
          getBundleQueues(searchParams, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.release_bundle_queue_error', lang, [reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPDATE_BUNDLE_QUEUE_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and reset a bundle queue.
 * @param {String} docid DOCID of the document
 * @param {String} reci Recipient ID
 * @param {String} form The form (Only used for snackbar)
 * @param {String} extension The extension (Only used for snackbar)
 * @param {String} report The report (Only used for snackbar)
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function resetBundleQueue(docid, reci, form, extension, report, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const messageParams = []
    messageParams.push(`${Lang.translate('recipient.reci_id')}: ${reci}`)
    messageParams.push(`${Lang.translate('general.form')}: ${form}`)
    if (extension !== '') {
      messageParams.push(`${Lang.translate('general.extension')}: ${extension}`)
    }
    if (report !== '') {
      messageParams.push(`${Lang.translate('general.report')}: ${report}`)
    }
    const message = messageParams.join(', ')

    dispatch({ type: UPDATE_BUNDLE_QUEUE_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/bundle`, {
        method: 'put',
        body: {
          DOCID: docid,
          RECI: reci,
          PROCESS: 'RESET'
        }
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: UPDATE_BUNDLE_QUEUE_FAILED })
        } else {
          dispatch({ type: UPDATE_BUNDLE_QUEUE_SUCCESS })

          SnackbarActions.show(Lang.translate('queue.reset_bundle_queue_success', lang, message), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }
          const searchParams = {
            DCR: prefs[Preferences.QUEUE_BUNDLE_OUTPUT_CHANNEL],
            PCR: prefs[Preferences.QUEUE_BUNDLE_OUTPUT_FORMAT],
            PREDRECI: prefs[Preferences.QUEUE_BUNDLE_ADDRESS],
            RECI: prefs[Preferences.QUEUE_BUNDLE_RECIPIENT],
            FORM: prefs[Preferences.QUEUE_BUNDLE_FORM],
            EXT: prefs[Preferences.QUEUE_BUNDLE_EXTENSION],
            REPORT: prefs[Preferences.QUEUE_BUNDLE_REPORT],
            WREPORT: prefs[Preferences.QUEUE_BUNDLE_WREPORT],
            SRCJOBN: prefs[Preferences.QUEUE_BUNDLE_JOBNAME],
            BUNDLSTA: prefs[Preferences.QUEUE_BUNDLE_ONLY_STATUS_HOLD] ? 'H' : '',
            DOCUSR1: prefs[Preferences.QUEUE_BUNDLE_DOCUSER1],
            DOCUSR2: prefs[Preferences.QUEUE_BUNDLE_DOCUSER2],
            DOCUSR3: prefs[Preferences.QUEUE_BUNDLE_DOCUSER3],
            DOCUSR4: prefs[Preferences.QUEUE_BUNDLE_DOCUSER4],
            DOCUSR5: prefs[Preferences.QUEUE_BUNDLE_DOCUSER5],
            DOCUSR6: prefs[Preferences.QUEUE_BUNDLE_DOCUSER6],
            DOCUSR7: prefs[Preferences.QUEUE_BUNDLE_DOCUSER7],
            DOCUSR8: prefs[Preferences.QUEUE_BUNDLE_DOCUSER8]
          }
          getBundleQueues(searchParams, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.reset_bundle_queue_error', lang, [reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPDATE_BUNDLE_QUEUE_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}