import React, { Component } from 'react'
import Header from 'components/header/Header'
import Footer from 'BetaUX2Web-Components/src/components/footer/Footer'

import Owl from 'ressource/problem-owl.svg'
import { translate } from 'language/Language'
import * as Configs from 'config/Configs'

import { connect } from 'react-redux'


import './ErrorBoundary.scss'

class ErrorBoundary extends Component {

  state = {
    error: undefined,
    info: undefined
  }

  /**
   * If a unhandled error occures, save it in state. This will trigger
   * the render-function to show the error-screen
   * @param {object} info contains the callstack in "componentStack"-prop
   * @param {string} error thrown error as text
   */
  componentDidCatch = (error, info) => {
    this.setState({ error, info })
  }

  /**
   * Copy to clipboard in Javascript is a bit weird:
   * - Create a invisible textarea
   * - Set the content
   * - Focus it
   * - Simulate copy command
   * @param {object} info contains the callstack in "componentStack"-prop
   * @param {string} error thrown error as text
   */
  copyErrorToClipboard = (info, error) => {
    let el = document.createElement('textarea')
    el.value = `Error: ${error}\nInfo: ${info.componentStack}`

    el.setAttribute('readonly', '')
    el.style = { visible: 'hidden' }
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  /**
   * @description Renders the text below the owl.
   */
  renderText = () => {
    const text = [
      // more details text
      <div
        key={'errorboundary_moredetails'}
        className={'bux_errorboundary_text_space_between'}>
        {translate('errorboundary.more_details')}
      </div>,
      // contact text
      <div
        key={'errorboundary_contact'}
        className={'bux_errorboundary_text_space_between'}>
        {translate('errorboundary.contact')}
      </div>,
    ]

    if (this.props.history) {
      text.push(
        // goto startpage text
        <span
          key={'errorboundary_goto'}
          onClick={() => {
            this.props.history.push('/search/standardselection')
          }}
          className={'bux_errorboundary_goto'}>
          {translate('errorboundary.goto_startpage')}
        </span>)
    }

    return text
  }

  /**
   * @description Renders the errorpage.
   */
  renderErrorPage = () => {
    const {
      productname,
      buildhash,
      buildtimestamp,
      productversion,
      servicepack,
      patchlevel,
      releasetype
    } = Configs.CONFIGS

    return (
      <div className={'bux_errorboundary'}>
        <Header
          id='header'
          loggedIn={this.props.auth.loggedIn}
          history={this.props.history}
          minimal
        />
        <div className={'bux_errorboundary_center'}>
          <img src={Owl} alt='Logo' className={'bux_errorboundary_owl'} />

          <div className={'bux_errorboundary_headline'}>
            {translate('errorboundary.unexpected_error_occured')}
          </div>
          <div className={'bux_errorboundary_text'}>
            {this.renderText()}
          </div>
        </div>
        <Footer
          id='errorboundaryFooter'
          text={`${productname} - Version: ${productversion}.${servicepack}${patchlevel} ${releasetype} Revision: ${buildhash} (${buildtimestamp}) `}
        />
      </div>
    )
  }

  /**
   * Render an error-screen to avoid the application to crash when unhandled error is thrown
   * The error-screen will allow the user to copy the error to clipboard or open an
   * email-program to send the error to the BetaUX2Web-Team
   */
  render = () => {
    const { info, error } = this.state
    if (info || error) {
      return this.renderErrorPage()
    }
    /* no error - render whatever is wrapper by the error-boundary-component */
    return this.props.children
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(ErrorBoundary)