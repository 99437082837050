import PropTypes from 'prop-types'
import { Component } from 'react'
import * as SortUtils from 'utils/SortUtils'

// components
import { DataTable, DownloadWrapper, Link, ResultContainer } from 'BetaUX2Web-Components/src/'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as ServerActions from 'redux/actions/ServerActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import { getTranslatedHeaders } from 'utils/ColumnUtils';


const LPD_QUEUE_ID = 'QUEUE'
const STATUS = 'STATUS'
const SPOOL_OK = 'SPOK'
const SPOOL_ERROR = 'SPERR'
const PRINT_OK = 'PROK'
const PRINT_ERROR = 'PRERR'
const TRANS_OK = 'APROK'
const TRANS_ERROR = 'APRERR'
const REQUEST_ACTIVE = 'REQACT'
const REQUEST_MAX = 'REQMAX'

class SearchResultServerLpdStatus extends Component {

  state = {
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: LPD_QUEUE_ID, translation: 'definition.lpd_queue.id', default: true },
      { rest: STATUS, translation: 'general.status', default: true },
      { rest: SPOOL_OK, translation: 'server.spool_ok', default: true },
      { rest: SPOOL_ERROR, translation: 'server.spool_err', default: true },
      { rest: PRINT_OK, translation: 'server.trans_ok', default: true },
      { rest: PRINT_ERROR, translation: 'server.trans_err', default: true },
      { rest: TRANS_OK, translation: 'server.print_ok', default: true },
      { rest: TRANS_ERROR, translation: 'server.print_err', default: true },
      { rest: REQUEST_ACTIVE, translation: 'server.request_active', default: true },
      { rest: REQUEST_MAX, translation: 'server.request_max', default: true },
    ]
  }

  /**
   * @description Refreshes the table
   */
  handleRefresh = () => {
    this.props.getLpdQueuesStatus()
  }

  /**
   * @description gets the index of the header in redux state externalcmds.header
   * @param {String} header header name of the header in redux state externalcmds.header
   */
  headerData = header => this.props.lpdstatus.header.indexOf(header)

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />
      ]
    )
  }

  /**
   * @description builds the data to a an array which is used later
   */
  getData = () => {
    const { lpdstatus } = this.props
    const data = []
    let headers = this.getUsedHeader()
    lpdstatus.data.forEach(el => {
      let dataBuffer = []
      headers.forEach(header => {
        dataBuffer.push(el[this.headerData(header)])
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_LPD_STATUS]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_LPD_STATUS].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    }
    else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_LPD_STATUS]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_SERVER_LPD_STATUS].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  render = () => {
    const { id, lpdstatus, drawerExpanded, autoDrawer, lang, datemask } = this.props
    const { showTableSettingsDialog } = this.state
    const data = lpdstatus?.data ? this.getData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_SERVER_LPD_STATUS }}
          />
        )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {
            data &&
            <DataTable
              id={id}
              header={translatedHeaders}
              data={data}
              cleanData={data}
              selectable={true}
              columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
              additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
              fillPage={fillPage}
              translate={key => translate(key)}
              language={lang}
              datemask={datemask}
              noAction
            />
          }
        </ResultContainer>
      </>
    )
  }
}

SearchResultServerLpdStatus.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    maintenance: state.maintenance,
    lpdstatus: state.server.lpdstatus,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getLpdQueuesStatus: () => ServerActions.getLPDQueuesStatus()(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultServerLpdStatus)