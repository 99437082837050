import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  Column,
  DownloadWrapper,
  Icon,
  Link,
  Row,
  TableButton,
  TableButtonGroup,
  TableButtonGroupGroup,
  TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'

import * as SEARCH_CONSTANTS from 'components/drawer/content/search/search_body/search_standard_search/SearchStandardSearch'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import DocumentInformation from 'components/dialogs/document_information_dialog/DocumentInformation.jsx'
import DocumentPrint from 'components/dialogs/document_print_dialog/DocumentPrint.jsx'
import { DownloadDialog } from 'components/dialogs/download_dialog/DownloadDialog'
import MarkReloadDocumentDialog from 'components/dialogs/mark_reload_document_dialog/MarkReloadDocumentDialog'
import RerunDialog, * as RerunDialogTypes from 'components/dialogs/rerun_dialog/RerunDialog'
import { SelectionTable } from '../common/SelectionTable';
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'

import * as DocViewerActions from 'redux/actions/DocViewerActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as StandardSelectionActions from 'redux/actions/StandardSelectionActions'
import * as Preferences from 'redux/general/Preferences'

import { EResultTableType, getColumnsObjects, translateHeaderColumns } from 'utils/ColumnUtils'
import * as CommonUtils from 'utils/CommonUtils'
import * as DateUtils from 'utils/DateUtils'
import * as DocViewerUtils from 'utils/DocViewerUtils'
import * as SortUtils from 'utils/SortUtils'
import * as UrlUtils from 'utils/UrlUtils'
import * as UserUtils from 'utils/UserUtils'

import { convertIntoObject } from 'utils/Utils'
import { SearchByIndexDialog } from 'components/dialogs/search_by_index/SearchByIndexDialog'
class SearchResultStandardSelection extends Component {
  state = {
    showDocumentInformationDialog: false,
    showTableSettingsDialog: false,
    showReloadDialog: false,
    showReloadDialogMultiple: false,
    showPrintDialogMultiple: false,
    showPrintDialog: false,
    showRerunDialog: false,
    showRerunDialogMultiple: false,
    showDownloadDialog: false,
    selectedRow: undefined,
    selectedMimeType: undefined,
    selectedDownloadType: undefined,
    header: this.fillHeaderInformation(),
    translatedHeaders: [],
    settingsTranslatedHeaders: [],
    rerunType: undefined,
    documentsToPrint: {},
    documentsToReload: {},
    checkedRows: undefined,
    parameterToBeUpdated: null,
  }

  /**
     * @description Fills the header information for the table columns.
     * @returns {Array} An array of column information and translation keys.
     */
  fillHeaderInformation() {
    const { documents } = this.props
    const defaultOrderedHeaders = [FORM, EXT, WREPORT, SRCJOBN, SRCPAGES, OWNER, SRCJOBI, LTITLE,
      SRCSUBU, CREATIONDATE, LGRFFDEL, LGRFFARC, LGRFFREL, LGRARCH, LGRONLNE, LTOKEN, RTOKEN]

    return CommonUtils.hasProperties(documents) ? getColumnsObjects(documents.header, defaultOrderedHeaders) : []
  }

  updateHeadersTranslation() {
    const header = this.getUsedHeader()

    translateHeaderColumns(header, EResultTableType.BRWTAB).then(translatedHeaders => {
      this.setState({ translatedHeaders })
    })

    translateHeaderColumns(this.state.header.map(v => v.rest), EResultTableType.BRWTAB).then(translatedHeaders => {
      const settingsTranslatedHeaders = translatedHeaders.map((v, index) => {
        return {
          rest: this.state.header[index].rest,
          translated: v
        }
      })
      this.setState({ settingsTranslatedHeaders })
    })

  }

  componentDidMount() {
    this.updateHeadersTranslation()
    this.setState({ header: this.fillHeaderInformation() })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const prevHeaders = prevProps.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION]?.displayedHeaders
    const currHeaders = this.props.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION]?.displayedHeaders

    const headersChanged = prevHeaders && currHeaders && prevHeaders !== currHeaders
    const langChanged = prevProps.lang !== this.props.lang

    if (headersChanged || langChanged) {
      this.updateHeadersTranslation()
    }

    if (!prevProps.documents?.header && this.props.documents?.header) {
      this.setState({ header: this.fillHeaderInformation() })
    }

    if ((this.state.parameterToBeUpdated !== null) && (this.state.parameterToBeUpdated !== prevState.parameterToBeUpdated)) {
      this.updateDocumentMarker(this.state.parameterToBeUpdated)
    }

    if (prevState.header.length === 0 && this.state.header.length !== 0) {
      this.updateHeadersTranslation();
    }
  }

  updateDocumentMarker = (parameterToBeUpdated) => {
    const { document, markArchive, markDelete, markReload } = this.props

    const archiveStatus = document[parameterToBeUpdated] === false || document[parameterToBeUpdated] === '' ? 'PENDING' : ''
    const singleLtoken = document.LTOKEN
    const singleRtoken = document.RTOKEN
    const singleDocid = singleLtoken + singleRtoken

    const documentDefinition = {
      DOCID: singleDocid,
      FORM: document.FORM,
      EXT: document.EXT,
      REPORT: document.REPORT
    }

    if (parameterToBeUpdated === 'LGRFFARC') {
      markArchive({ ...documentDefinition, ARCHIVE: archiveStatus }, () => this.setState({ parameterToBeUpdated: null }))
    }

    if (parameterToBeUpdated === 'LGRFFDEL') {
      markDelete({ ...documentDefinition, DELETE: archiveStatus }, () => this.setState({ parameterToBeUpdated: null }))
    }

    if (parameterToBeUpdated === 'LGRFFREL') {
      markReload({ ...documentDefinition, RELOAD: archiveStatus }, () => this.setState({ parameterToBeUpdated: null }))
    }
  }

  /**
     * @description refreshes the table
     */
  handleRefresh = () => {
    const { preferences, getDocuments } = this.props
    const activeTabIndex = preferences[Preferences.SEARCH_STD_ACTIVE_TAB]
    let lastTimeMode = preferences[Preferences.SEARCH_STD_LASTTIME_MODE]
    let sdate = ''
    let stime = ''
    let edate = ''
    let etime = ''
    let listTimestamp = ''
    let customLast = ''
    let customUnit = ''
    let outputStatusValues = ''

    if (activeTabIndex === 0) {
      if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_TODAY || lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_YESTERDAY) {
        sdate = preferences[Preferences.SEARCH_STD_LASTTIME_MODE].toUpperCase()
      } else if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_CUSTOM) {
        customLast = preferences[Preferences.SEARCH_STD_CUSTOMLAST]
        customUnit = preferences[Preferences.SEARCH_STD_CUSTOM_UNIT]
      }
    } else if (activeTabIndex === 1) {
      sdate = DateUtils.getDateFromUnixTimestamp(preferences[Preferences.SEARCH_STD_FROMDATE], DateUtils.DDMMYYYY_DOT)
      stime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_STD_FROMTIME])
      edate = DateUtils.getDateFromUnixTimestamp(preferences[Preferences.SEARCH_STD_TODATE], DateUtils.DDMMYYYY_DOT)
      etime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_STD_TOTIME])
    } else if (activeTabIndex === 2) {
      listTimestamp = preferences[Preferences.SEARCH_STD_LISTTIMESTAMP]
    }

    if (preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_PRINTED]) {
      outputStatusValues += 'P'
    }
    if (preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_ERROR]) {
      outputStatusValues += 'E'
    }
    if (preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_HOLD]) {
      outputStatusValues += 'H'
    }
    if (preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_WAITING]) {
      outputStatusValues += 'W'
    }
    if (preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_DELETE]) {
      outputStatusValues += 'D'
    }
    if (preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_NOTFOUND]) {
      outputStatusValues += 'N'
    }
    if (preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_ACTIVE]) {
      outputStatusValues += 'A'
    }
    if (preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_RETRY]) {
      outputStatusValues += 'R'
    }
    if (preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_CANCELLED]) {
      outputStatusValues += 'C'
    }
    if (preferences[Preferences.SEARCH_STD_OUTPUT_STATUS_REQUEUED]) {
      outputStatusValues += 'Q'
    }
    const searchParams = {
      FROMLAST: customLast,
      TUNITS: customUnit,
      SDATE: sdate,
      STIME: stime,
      EDATE: edate,
      ETIME: etime,
      DOCID: listTimestamp,
      PROCESS: preferences[Preferences.SEARCH_STD_SELECT_BY_TYPE],
      FORM: preferences[Preferences.SEARCH_STD_FORM],
      EXTENSION: preferences[Preferences.SEARCH_STD_EXTENSION],
      REPORT: preferences[Preferences.SEARCH_STD_REPORT],
      RECI: preferences[Preferences.SEARCH_STD_RECIPIENT],
      FOLDER: preferences[Preferences.SEARCH_STD_FOLDER],
      JOBNAME: preferences[Preferences.SEARCH_STD_JOBNAME],
      TITLE: preferences[Preferences.SEARCH_STD_TITLE],
      FTITLE: preferences[Preferences.SEARCH_STD_TITLEFROMCOLUMN],
      LGRSTAT: preferences[Preferences.SEARCH_STD_CONTROL_STATUS],
      CUSER: preferences[Preferences.SEARCH_STD_CONTROL_USER],
      ONLINE: preferences[Preferences.SEARCH_STD_ONLINE],
      ARCHIVE: preferences[Preferences.SEARCH_STD_ARCHIVED],
      INDEXED: preferences[Preferences.SEARCH_STD_SELECT_BY_INDEX],
      DELETE: preferences[Preferences.SEARCH_STD_MARKED_FOR_DELETE],
      RELOAD: preferences[Preferences.SEARCH_STD_MARKED_FOR_RELOAD],
      LGRNOTE: preferences[Preferences.SEARCH_STD_SELECT_BY_NOTES],
      DOCTYPE: preferences[Preferences.SEARCH_STD_DOCTYPE],
      SRCSUBU: preferences[Preferences.SEARCH_STD_USER],
      OWNER: preferences[Preferences.SEARCH_STD_OWNER],
      DOCUSR1: preferences[Preferences.SEARCH_STD_DOCUSER1],
      DOCUSR2: preferences[Preferences.SEARCH_STD_DOCUSER2],
      DOCUSR3: preferences[Preferences.SEARCH_STD_DOCUSER3],
      DOCUSR4: preferences[Preferences.SEARCH_STD_DOCUSER4],
      DOCUSR5: preferences[Preferences.SEARCH_STD_DOCUSER5],
      DOCUSR6: preferences[Preferences.SEARCH_STD_DOCUSER6],
      DOCUSR7: preferences[Preferences.SEARCH_STD_DOCUSER7],
      DOCUSR8: preferences[Preferences.SEARCH_STD_DOCUSER8],
      EDITSTAT: preferences[Preferences.SEARCH_STD_EDITING_ICON],
      SRCOBT: preferences[Preferences.SEARCH_STD_OBTAINED],
      DCR: preferences[Preferences.SEARCH_STD_OUTPUTCHANNEL],
      BREQUEST: preferences[Preferences.SEARCH_STD_REQUESTOR],
      LGRAUTO: preferences[Preferences.SEARCH_STD_AUTOPRINT],
      PRTSTAT: outputStatusValues
    }
    getDocuments(undefined, searchParams)
  }

  /**
     * @description Handles the reload action.
     * @param {Number} index The row index of document.
     */
  handleReload = index => {
    const { documents } = this.props
    const ltoken = documents.data[index][this.headerIndex('LTOKEN')]
    const rtoken = documents.data[index][this.headerIndex('RTOKEN')]
    const docid = ltoken + rtoken
    const callback = () => {
      this.setState({ showReloadDialog: true })
    }
    this.props.getDocument(undefined, docid, '', callback)
  }

  /**
     * @description Handles the delete from archive action.
     * @param {Number} index The row index of document.
     */
  handleDeleteFromArchive = index => {
    const { documents } = this.props
    const ltoken = documents.data[index][this.headerIndex('LTOKEN')]
    const rtoken = documents.data[index][this.headerIndex('RTOKEN')]
    const docid = ltoken + rtoken
    const callback = () => {
      this.setState({ showDeletefromArchiveDialog: true })
    }

    this.props.getDocument(undefined, docid, '', callback)
  }

  /**
     * @description Deletes the current document from archive.
     */
  deleteFromArchive = () => {
    const { document, markArchive } = this.props
    const docid = this.props.document['DOCID']

    const documentDefinition = {
      ARCHIVE: 'DELETE',
      DOCID: docid,
      FORM: document.FORM,
      EXT: document.EXT,
      REPORT: document.REPORT
    }

    const callback = () => {
      this.setState({ showDeletefromArchiveDialog: false })
    }
    markArchive(documentDefinition, callback)
  }

  /**
     * @description gets the index of the header in redux state documents.header
     * @param {String} header header name of the header in redux state documents.header
     */
  headerIndex = header => this.props.documents.header.indexOf(header)


  /**
     * @description Resets the checked rows after multi marking to have an equal behavior for all checked rows even if the entry was not changed through marking.
     */
  resetCheckedRows = () => {
    this.setState({ checkedRows: [] }, () => this.setState({ checkedRows: undefined }))
  }

  multiMarkAction = (rows, dropdownIndex) => {
    const { updateMarkMulti, documents } = this.props
    const docids = rows.map(row => {
      const ltoken = documents.data[row[row.length - 1]][this.headerIndex('LTOKEN')]
      const rtoken = documents.data[row[row.length - 1]][this.headerIndex('RTOKEN')] || '0000000000000000'
      return ltoken + rtoken
    })
    const docInformation = rows.map(row => {
      return {
        form: documents.data[row[row.length - 1]][this.headerIndex('FORM')],
        extension: documents.data[row[row.length - 1]][this.headerIndex('EXT')],
        report: documents.data[row[row.length - 1]][this.headerIndex('WREPORT')]
      }
    })
    const requestObj = {
      DOCIDS: docids
    }
    switch (dropdownIndex) {
      case 2:
        requestObj.DELETE = 'PENDING'
        updateMarkMulti(requestObj, docInformation)
        break
      case 3:
        requestObj.DELETE = ''
        updateMarkMulti(requestObj, docInformation)
        break
      case 4:
        requestObj.ARCHIVE = 'PENDING'
        updateMarkMulti(requestObj, docInformation)
        break
      case 5:
        requestObj.ARCHIVE = ''
        updateMarkMulti(requestObj, docInformation)
        break
      case 6:
        requestObj.RELOAD = 'PENDING'
        this.handleMultipleReload(docids)
        break
      case 7:
        requestObj.RELOAD = ''
        this.handleMultipleReload(docids)
        break
      case 8:
        this.handlePrintMultiple(docids)
        break
      case 9:
        this.handleMultipleRerun(docids, RerunDialogTypes.ITEM_FORCE_AUTO_PRINT)
        break
      case 10:
        this.handleMultipleRerun(docids, RerunDialogTypes.ITEM_BUNDLE)
        break
      default:
        break
    }
  }

  handlePrintMultiple = docidsArr => {
    const { getDocument, getPrintInfo } = this.props
    Promise.all([
      Promise.all(docidsArr.map(docid => {
        return getPrintInfo(docid)
          .then(res => {
            return [docid, res]
          })
      })),
      Promise.all(docidsArr.map(docid => {
        return getDocument(undefined, docid, '')
          .then(res => {
            return [docid, res]
          })
      }))
    ])
      .then(([...res]) => {
        const printinfos = res[0]
        const docinfos = res[1]
        let buffer = {}
        for (let i = 0; i < printinfos.length; i++) {
          if (!printinfos[i][1][0].error) {
            buffer = { ...buffer, [printinfos[i][0]]: { printInfo: printinfos[i][1], docInfo: docinfos[i][1] } }
          }
        }
        if (Object.values(buffer).length > 0) {
          this.setState({ documentsToPrint: buffer, showPrintDialogMultiple: true })
        }
      })
  }

  handleMultipleRerun = (docidsArr, type) => {
    const { getDocument } = this.props
    Promise.all(docidsArr.map(docid => {
      return getDocument(undefined, docid, '')
        .then(res => {
          return [docid, res]
        })
    }))
      .then(([...docs]) => {
        let buffer = {}
        docs.forEach(doc => {
          buffer = { ...buffer, [doc[0]]: doc[1] }
        })
        this.setState({ documentsToPrint: buffer, showRerunDialogMultiple: true, rerunType: type })
      })
  }

  handleMultipleReload = docidsArr => {
    const { getDocument } = this.props
    Promise.all(docidsArr.map(docid => {
      return getDocument(undefined, docid, '')
        .then(res => {
          return [docid, res]
        })
    }))
      .then(([...docs]) => {
        let buffer = {}
        docs.forEach(doc => {
          buffer = { ...buffer, [doc[0]]: doc[1] }
        })
        this.setState({ documentsToReload: buffer, showReloadDialogMultiple: true })
      })
  }

  /**
     * @description un-/marks the archive or delete status, unmarks the reload status
     * @param {Number} index the index of the current row
     * @param {String} markedParam the header which should be used to update the ARCHIVE, DELETE or RELOAD
     * possible: 'LGRFFARC', 'LGRFFDEL', 'LGRFFREL'
     * LGRFFREL is only used to unmark reload
     */
  updateMark = (index, markedParam) => {
    const { documents, getDocument } = this.props
    const currentLtoken = documents.data[index][this.headerIndex('LTOKEN')]
    const currentRtoken = documents.data[index][this.headerIndex('RTOKEN')]
    const currentDocid = currentLtoken + currentRtoken

    getDocument(undefined, currentDocid, '', () => this.setState({ parameterToBeUpdated: markedParam }))
  }

  /**
     * @description Gets the fill page info.
     * @returns {Boolean} The fill page info.
     */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION].fillPage
    } else {
      return true
    }
  }

  /**
     * @description Gets the used headers.
     * @returns {Array} The used headers.
     */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
     * @description Gets the default headers for the table.
     * @returns {Array} The default headers.
     */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  /**
     * @description Gets the translated headers.
     * @param {Array} chosenHeaders The chosen headers which are not translated.
     * @returns {Array} The translated chosen headers.
     */
  getTranslatedHeaders = chosenHeaders => {
    let filteredHeaders = []
    chosenHeaders.forEach(h => {
      const temp = this.state.header.filter(entry => entry.rest === h)
      if (temp.length > 0) {
        filteredHeaders.push(temp[0])
      }
    })

    return filteredHeaders.map(entry => {
      const key = entry.translation ? entry.translation : entry.rest
      return translate(key, undefined, undefined, false) ? translate(key) : key
    })
  }

  /**
     * @description Gets specific column sort definitions.
     * @param {Array} data The data to show.
     * @param {Array} header The headers of the table.
     * @returns {Array} The column sort definitions.
     */
  getColumnSortDefs = (data, header) => {
    return SortUtils.getSortTypes(data, header.length)
  }

  /**
     * @description Handles the show document information action.
     * @param {Number} rowIndex The current row.
     */
  handleShowDocumentInformation = rowIndex => {
    const { documents } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + documents.data[rowIndex][this.headerIndex('RTOKEN')]
    const callback = () => {
      this.setState({ showDocumentInformationDialog: true })
    }
    this.props.getDocument(undefined, docid, '', callback)
  }

  /**
     * @description Handles the open print document dialog action.
     * @param {Number} rowIndex The current row.
     */
  handleShowDocumentPrint = rowIndex => {
    const { documents, getDocument, getPrintInfo } = this.props
    let docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + documents.data[rowIndex][this.headerIndex('RTOKEN')]
    docid = docid.length === 32 ? docid : docid + '0000000000000000'
    getDocument(undefined, docid, '', () => {
      getPrintInfo(docid, () => this.setState({ showPrintDialog: true }))
    })
  }

  /**
     * @description Handles the open search by index dialog action.
     * @param {Number} rowIndex The current row.
     */
  handleShowSearchByIndex = rowIndex => {
    const { documents } = this.props
    const doc = documents.data[rowIndex]
    const docObj = convertIntoObject(documents.header, doc);
    this.setState({ searchByIndexDialogData: docObj, showSearchByIndexDialog: true })
  }


  /**
     * @description Handles the rerun action.
     * @param {Number} rowIndex The current row.
     * @param {String} type The type of rerun. Use constants of RerunDialog!
     */
  handleRerun = (rowIndex, type) => {
    const { documents } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + documents.data[rowIndex][this.headerIndex('RTOKEN')]
    const callback = () => {
      this.setState({ showRerunDialog: true, rerunType: type })
    }
    this.props.getDocument(undefined, docid, '', callback)
  }

  /**
     * @description Handles the download functionality of documents in binary format.
     * @param {Number} rowIndex The current row.
     */
  handleDocumentDownloadBinary = rowIndex => {
    const { documents, downloadDocument } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    const docidFilename = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '')
    const form = documents.data[rowIndex][this.headerIndex('FORM')]
    const ext = documents.data[rowIndex][this.headerIndex('EXT')]
    let doctype = documents.data[rowIndex][this.headerIndex('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, docidFilename, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadDocument(docid, filename)
  }

  /**
     * @description Handles the download functionality. Opens an additional modal, if download settings are available for a specific mimetype.
     * @param {Number} rowIndex The current row.
     * @param {String} mimetype The mimetype of the specific doucment.
     */
  handleDocumentDownloadText = (rowIndex, mimetype) => {
    const { showDownloadDialog } = this.state
    if (!showDownloadDialog && ['*.afp', '*.pcl', '*.pjl'].includes(mimetype)) {
      return this.openDownloadTextDialog(rowIndex)
    }
    return this.executeDownloadTextDirectly(rowIndex, mimetype)
  }

  /**
     * @description Executes the text download directly without an additional modal.
     * @param {Number} rowIndex The current row.
     * @param {String} mimetype The mimetype of the specific doucment.
     */
  executeDownloadTextDirectly = (rowIndex, mimetype) => {
    const { documents, downloadDocumentText } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    const docidFilename = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '')
    const form = documents.data[rowIndex][this.headerIndex('FORM')]
    const ext = documents.data[rowIndex][this.headerIndex('EXT')]
    let doctype = documents.data[rowIndex][this.headerIndex('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }
    const fileExtension = 'txt'
    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[mimetype]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, docidFilename, doctype, fileExtension].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadDocumentText(docid, filename, mimetypeTranslation)
  }

  executeDownloadTextFromDialog = (showNOP, showTLE, showPJL) => {
    const { documents, downloadDocumentText } = this.props
    const { selectedRow, selectedMimeType } = this.state
    const docid = documents.data[selectedRow][this.headerIndex('LTOKEN')] + (documents.data[selectedRow][this.headerIndex('RTOKEN')] || '0000000000000000')
    const docidFilename = documents.data[selectedRow][this.headerIndex('LTOKEN')] + (documents.data[selectedRow][this.headerIndex('RTOKEN')] || '')
    const form = documents.data[selectedRow][this.headerIndex('FORM')]
    const ext = documents.data[selectedRow][this.headerIndex('EXT')]
    let doctype = documents.data[selectedRow][this.headerIndex('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }
    let fileExtension = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[selectedMimeType]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, docidFilename, doctype, fileExtension].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadDocumentText(docid, filename, mimetypeTranslation, showNOP, showTLE, showPJL, () => this.setState({
      showDownloadDialog: false,
      selectedRow: undefined,
      selectedMimeType: undefined,
      selectedDownloadType: undefined
    }))
  }

  openDownloadTextDialog = rowIndex => {
    const { documents } = this.props
    this.setState({
      showDownloadDialog: true,
      selectedRow: rowIndex,
      selectedMimeType: documents.data[rowIndex][this.headerIndex('SRCJOBI')],
      selectedDownloadType: 'TEXT'
    })
  }

  /**
     * @description Handles the download functionality of documents in hex format.
     * @param {String} displayMode YES/NO value for block/line format.
     * @param {String} showPageHeader YES/NO value for show page header.
     * @param {String} showResources YES/NO value for show resources.
     */
  handleDocumentDownloadHex = (displayMode, showPageHeader, showResources) => {
    const { selectedRow } = this.state
    const { documents, downloadDocumentHex } = this.props
    const docid = documents.data[selectedRow][this.headerIndex('LTOKEN')] + (documents.data[selectedRow][this.headerIndex('RTOKEN')] || '0000000000000000')
    const docidFilename = documents.data[selectedRow][this.headerIndex('LTOKEN')] + (documents.data[selectedRow][this.headerIndex('RTOKEN')] || '')
    const form = documents.data[selectedRow][this.headerIndex('FORM')]
    const ext = documents.data[selectedRow][this.headerIndex('EXT')]
    let doctype = documents.data[selectedRow][this.headerIndex('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }
    let fileExtension = 'hex'

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, docidFilename, doctype, fileExtension].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')
    downloadDocumentHex(docid, filename, displayMode, showPageHeader, showResources, () => this.setState({
      showDownloadDialog: false,
      selectedRow: undefined,
      selectedMimeType: undefined,
      selectedDownloadType: undefined
    }))
  }

  handleShowDocumentDownloadHexDialog = rowIndex => {
    const { documents } = this.props
    this.setState({
      showDownloadDialog: true,
      selectedRow: rowIndex,
      selectedMimeType: documents.data[rowIndex][this.headerIndex('SRCJOBI')],
      selectedDownloadType: 'HEX'
    })
  }

  handleDocumentDownloadSideFile(rowIndex) {
    const { documents } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    const docidFilename = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '')
    const form = documents.data[rowIndex][this.headerIndex('FORM')]
    const ext = documents.data[rowIndex][this.headerIndex('EXT')]
    let doctype = documents.data[rowIndex][this.headerIndex('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }
    let fileExtension = 'idx'
    const filename = [form, ext, docidFilename, doctype, fileExtension].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')
    this.props.downloadDocumentSideFile(docid, filename)
  }

  /**
     * @description Handles the display functionality of documents in _beta view.
     * @param {Number} rowIndex The current row.
     */
  handleDisplayDocmentInBetaView = rowIndex => {
    const { documents, displayDocument, bwesystemname, bwebaseurl } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')

    displayDocument(docid, bwebaseurl, bwesystemname)
  }

  showRecipients = index => {
    const { documents } = this.props
    const header = documents.header
    const data = documents.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
      { rest: 'REPORT', ui: 'report' },
      { rest: 'WREPORT', ui: 'dynamicReport' },
    ]

    // fake the state like its used in assignment index document to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
      report: data[header.indexOf('DREPORT')],
      dynamicReport: data[header.indexOf('WREPORT')]
    }
    // builds the parameter object which is used for url
    const urlToPush = UserUtils.isDOCX()
      ? `/assignment/recipient_document${UrlUtils.createUrlParamsFromObject(state, keys)}`
      : `/assignment/recipient_log${UrlUtils.createUrlParamsFromObject(state, keys)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description This function will open all selected rows as tabs in the DocViewer.
   * @param {Array} selectedRows This array contains the indices of the selected rows.
   */
  openSelectedRowsInDocViewer = (selectedRowsIndices) => {
    selectedRowsIndices.forEach(rowIndex => this.openDocumentViewer(rowIndex))
  }

  /**
   * @description This function will handle opening a new tab with the parsed document data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewer = (rowIndex) => {
    const { documents, addTabToDocViewer } = this.props
    const docId = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    const pageCountHeaderIndex = documents.header.indexOf('SRCPAGES')
    const pageCount = pageCountHeaderIndex !== -1 ? parseInt(documents.data[rowIndex][pageCountHeaderIndex]) : 1

    addTabToDocViewer({
      id: docId,
      type: DocViewerUtils.SEARCH_TYPE_STANDARDSELECTION,
      header: documents.header,
      data: documents.data[rowIndex],
      pageCount: pageCount
    })
  }

  /**
   * @description This function will handle opening a new tab with the parsed document data for a sidefile.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewerSideFile = (rowIndex) => {
    const { documents, addTabToDocViewer } = this.props
    const docId = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    const pageCountHeaderIndex = documents.header.indexOf('EIXPAGES')
    const pageCount = pageCountHeaderIndex !== -1 ? parseInt(documents.data[rowIndex][pageCountHeaderIndex]) : 1

    addTabToDocViewer({
      id: `${DocViewerUtils.IDX_MARKER}${docId}`,
      type: DocViewerUtils.SEARCH_TYPE_STANDARDSELECTION_SIDEFILE,
      header: documents.header,
      data: documents.data[rowIndex],
      pageCount: pageCount
    })
  }

  /**
     * @description Creates the action buttons for the table.
     * @param {Number} rowIndex The index of the current row.
     */
  createActionButtons = rowIndex => {
    const { id, documents, userprofile } = this.props
    const rerunAutoprintAllowed = userprofile === undefined || userprofile?.VCIRAUP
    const rerunBundleRequestAllowed = userprofile === undefined || userprofile?.VCIRBUN
    const modifyReloadAllowed = userprofile === undefined || userprofile?.VCIREL
    const modifyArchiveAllowed = userprofile === undefined || userprofile?.VCIARC
    const modifyDeleteAllowed = userprofile === undefined || userprofile?.VCIDEL
    const mimetype = rowIndex !== undefined ? documents?.data[rowIndex][this.headerIndex('SRCJOBI')] : undefined
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType='material'
        iconName='info'
        title={translate('general.information')}
        onClick={() => this.handleShowDocumentInformation(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplayInBetaView_${rowIndex}`}
        iconType='material'
        iconName='document'
        title={translate('general.display')}
        onClick={() => this.openDocumentViewer(rowIndex)}
      />,
      <TableButtonGroup
        id={`${id}_tableButtonMore_${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleShowDocumentInformation(rowIndex)}
          id={`${id}_infoBtn`}
          icon={<Icon name='info' className='actionIcon' />}
          text={translate('general.information')}
          title={translate('general.information')}
        />
        <TableButtonGroupItem
          id={`${id}_tableButtonDisplayInBetaView_${rowIndex}`}
          iconType='material'
          icon={<Icon name='document' className='actionIcon' />}
          text={translate('general.display_in_beta_view')}
          title={translate('general.display_in_beta_view')}
          onClick={() => this.handleDisplayDocmentInBetaView(rowIndex)}
        />
        <TableButtonGroupItem
          onClick={() => this.handleShowDocumentPrint(rowIndex)}
          id={`${id}_printBtn`}
          icon={<Icon name='print' className='actionIcon' />}
          text={translate('documentprint.print')}
          title={translate('documentprint.print')}
        />
        {documents?.data[rowIndex]?.[this.headerIndex('LGRINDEX')] === 'YES' &&
          <TableButtonGroupItem
            onClick={() => this.handleShowSearchByIndex(rowIndex)}
            id={`${id}_serachByIndex`}
            icon={<Icon name='search' className='actionIcon' />}
            text={translate('general.search_by_index')}
            title={translate('general.search_by_index')}
          />}
        <TableButtonGroupGroup id={`${id}_download_group_btn`} text={translate('general.download')}>
          <TableButtonGroupItem
            onClick={() => this.handleDocumentDownloadBinary(rowIndex)}
            id={`${id}_downloadBtn`}
            icon={<Icon name='download' className='actionIcon' />}
            text={translate('general.download')}
            title={translate('general.download')}
          />
          {
            ['*.ps', '*.pdf', '*.afp', '*.pcl', '*.pjl'].includes(mimetype) &&
            <TableButtonGroupItem
              onClick={() => this.handleDocumentDownloadText(rowIndex, mimetype)}
              id={`${id}_downloadTextBtn`}
              icon={<Icon name='download' className={'actionIcon'} />}
              text={translate('general.download_as_text')}
              title={translate('general.download_as_text')}
            />
          }
          <TableButtonGroupItem
            onClick={() => this.handleShowDocumentDownloadHexDialog(rowIndex)}
            id={`${id}_downloadHexBtn`}
            icon={<Icon name='download' className={'actionIcon'} />}
            text={translate('standard_selection.download_as_hex')}
            title={translate('standard_selection.download_as_hex')}
          />
        </TableButtonGroupGroup>
        {rowIndex !== undefined && documents?.data[rowIndex][this.headerIndex('EIXPAGES')] > 0 && (
          <>
            <TableButtonGroupSeparator id={id} />
            <TableButtonGroupGroup id={`${id}_sidefile_group_btn`} text={translate('standard_selection.sidefile')}>
              <TableButtonGroupItem
                id={`${id}_btn_display_docviewer_${rowIndex}`}
                iconType='material'
                icon={<Icon name='document' className='actionIcon' />}
                text={translate('general.display')}
                title={translate('general.display')}
                onClick={() => this.openDocumentViewerSideFile(rowIndex)}
              />
              <TableButtonGroupItem
                onClick={() => this.handleDocumentDownloadSideFile(rowIndex)}
                id={`${id}_btn_download_side_file`}
                icon={<Icon name='download' className={'actionIcon'} />}
                text={translate('standard_selection.download_as_side_file')}
                title={translate('standard_selection.download_as_side_file')}
              />
            </TableButtonGroupGroup>
          </>
        )
        }
        {
          (rerunAutoprintAllowed || rerunBundleRequestAllowed) &&
          <TableButtonGroupSeparator id={id} />
        }
        {
          (rerunAutoprintAllowed || (rerunBundleRequestAllowed && UserUtils.isDOCX())) &&
          <TableButtonGroupGroup id={`${id}_rerun_group_btn`} text={translate('queue.rerun')}>
            {rerunAutoprintAllowed &&
              <>
                <TableButtonGroupItem
                  onClick={() => {
                    this.handleRerun(rowIndex)
                  }}
                  id={`${id}_rerunBtn`}
                  icon={<Icon name='rerun' className='actionIcon' />}
                  text={translate('queue.rerun')}
                  title={translate('queue.rerun')}
                />
                <TableButtonGroupItem
                  onClick={() => {
                    this.handleRerun(rowIndex, RerunDialogTypes.ITEM_FORCE_AUTO_PRINT)
                  }}
                  id={`${id}_rerunAutoprintBtn`}
                  icon={<Icon name='rerun' className='actionIcon' />}
                  text={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
                  title={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
                />
              </>
            }
            {
              UserUtils.isDOCX() &&
              rerunBundleRequestAllowed &&
              <TableButtonGroupItem
                onClick={() => {
                  this.handleRerun(rowIndex, RerunDialogTypes.ITEM_BUNDLE)
                }}
                id={`${id}_rerunBundleRequestBtn`}
                icon={<Icon name='rerun' className='actionIcon' />}
                text={translate('usermanagement.userprofile_basic_tab.rerun_bundle_request')}
                title={translate('usermanagement.userprofile_basic_tab.rerun_bundle_request')}
              />
            }
          </TableButtonGroupGroup>
        }
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => this.showRecipients(rowIndex)}
          id={`${id}_show_recipients_btn`}
          icon={<Icon name='group' className='actionIcon' />}
          text={translate('general.display_recipients')}
          title={translate('general.display_recipients')}
        />
        {(modifyArchiveAllowed || modifyDeleteAllowed || modifyReloadAllowed) &&
          <TableButtonGroupSeparator id={id} />
        }
        { /* check if documents.data[rowIndex] is not undefined is necessary, because redux updates the data faster than datatable, so datatable useses this function with old index and we get an error, when less rows are in the new data (no proof, just a theory)*/}
        {modifyDeleteAllowed && (documents.data[rowIndex] && documents.data[rowIndex][this.headerIndex('LGRONLNE')] === 'YES' &&
          (
            documents.data[rowIndex] && documents.data[rowIndex][this.headerIndex('LGRFFDEL')] === ''
              ? <TableButtonGroupItem
                onClick={() => {
                  this.updateMark(rowIndex, 'LGRFFDEL')
                }}
                id={`${id}_markDeleteBtn`}
                icon={<Icon name='delete' className='actionIcon' />}
                text={translate('general.mark_delete')}
                title={translate('general.mark_delete')}
              />
              : <TableButtonGroupItem
                onClick={() => {
                  this.updateMark(rowIndex, 'LGRFFDEL')
                }}
                id={`${id}_unmarkDeleteBtn`}
                icon={<Icon name='close' className='actionIcon' />}
                text={translate('general.unmark_delete')}
                title={translate('general.unmark_delete')}
              />))
        }
        {modifyArchiveAllowed && (documents.data[rowIndex] && (
          documents.data[rowIndex][this.headerIndex('LGRONLNE')] === 'YES' && documents.data[rowIndex][this.headerIndex('LGRARCH')] === 'NO' && (
            documents.data[rowIndex][this.headerIndex('LGRFFARC')] === ''
              ? <TableButtonGroupItem
                onClick={() => this.updateMark(rowIndex, 'LGRFFARC')}
                id={`${id}_markArchiveBtn`}
                icon={<Icon name='archive' className='actionIcon' />}
                text={translate('general.mark_archive')}
                title={translate('general.mark_archive')}
              />
              : <TableButtonGroupItem
                onClick={() => this.updateMark(rowIndex, 'LGRFFARC')}
                id={`${id}_unmarkArchiveBtn`}
                icon={<Icon name='unarchive' className='actionIcon' />}
                text={translate('general.unmark_archive')}
                title={translate('general.unmark_archive')}
              />)))}
        {modifyReloadAllowed && (documents.data[rowIndex] && (
          documents.data[rowIndex][this.headerIndex('LGRONLNE')] === 'NO' &&
          documents.data[rowIndex][this.headerIndex('LGRARCH')] === 'YES' && (
            documents.data[rowIndex][this.headerIndex('LGRFFREL')] === ''
              ? <TableButtonGroupItem
                onClick={() => {
                  this.handleReload(rowIndex)
                }}
                id={`${id}_markReloadBtn`}
                icon={<Icon name='reload' className='actionIcon' />}
                text={translate('general.mark_reload')}
                title={translate('general.mark_reload')}
              />
              : <TableButtonGroupItem
                onClick={() => {
                  this.updateMark(rowIndex, 'LGRFFREL')
                }}
                id={`${id}_unmarkReloadBtn`}
                icon={<Icon name='cancel_reload' className='actionIcon' />}
                text={translate('general.unmark_reload')}
                title={translate('general.unmark_reload')}
              />)))}
        {documents.data[rowIndex] && (
          documents.data[rowIndex][this.headerIndex('LGRARCH')] === 'YES' &&
          <TableButtonGroupItem
            onClick={() => {
              this.handleDeleteFromArchive(rowIndex)
            }}
            id={`${id}_delteFromArchiveBtn`}
            icon={<Icon name='close' className='actionIcon' />}
            text={translate('general.delete_from_archive')}
            title={translate('general.delete_from_archive')}
          />)}
      </TableButtonGroup>
    ]
  }

  /**
     * @description We need 'clean data' for download as csv (data in textual representation)
     * @returns {Array} The clean data.
     */
  getCleanData() {
    const { datemask, documents } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    documents.data.forEach(element => {
      let dataBuffer = []
      usedHeader.forEach(header => {
        if (header === CREATIONDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerIndex('B93DATE')], element[this.headerIndex('B93TIME')].substring(0, 8)))
        } else if (header === LGRFFDEL) {
          dataBuffer.push(element[this.headerIndex('LGRFFDEL')] === 'PEND' ? translate('general.yes') : translate('general.no'))
        } else if (header === LGRFFARC) {
          dataBuffer.push(element[this.headerIndex('LGRFFARC')] === 'PEND' ? translate('general.yes') : translate('general.no'))
        } else if (header === LGRFFREL) {
          dataBuffer.push(element[this.headerIndex('LGRFFREL')] === 'PEND' ? translate('general.yes') : translate('general.no'))
        } else if (header === LGRARCH) {
          dataBuffer.push(element[this.headerIndex('LGRARCH')] === 'YES' ? translate('general.yes') : translate('general.no'))
        } else if (header === LGRONLNE) {
          dataBuffer.push(element[this.headerIndex('LGRONLNE')] === 'YES' ? translate('general.yes') : translate('general.no'))
        } else if (header === RTOKEN) {
          dataBuffer.push(element[this.headerIndex('RTOKEN')] || '0000000000000000')
        } else if (header === B93TIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerIndex('B93DATE')], element[this.headerIndex('B93TIME')].substring(0, 8)))
        } else if (header === B93DATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerIndex('B93DATE')], element[this.headerIndex('B93TIME')].substring(0, 8), false))
        } else if (header === B93STIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerIndex('B93SDATE')], element[this.headerIndex('B93STIME')].substring(0, 8)))
        } else if (header === B93SDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerIndex('B93SDATE')], element[this.headerIndex('B93STIME')].substring(0, 8), false))
        } else {
          const val = element[this.headerIndex(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(moment(`${val}:00:00`, DateUtils.PARSING_DATEMASK).format(`${datemask} ${DateUtils.TIME_DATEMASK_FULL_MILLISECONDS}`))
          } else if (val.length === 19 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm:ss')) {
            dataBuffer.push(moment(`${val}:00`, DateUtils.PARSING_DATEMASK).format(`${datemask} ${DateUtils.TIME_DATEMASK_FULL_MILLISECONDS}`))
          } else if (val.length === 22 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm:ss:SS')) {
            dataBuffer.push(moment(val, DateUtils.PARSING_DATEMASK).format(`${datemask} ${DateUtils.TIME_DATEMASK_FULL_MILLISECONDS}`))
          } else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
     * @description Creates the buttons for the tablemenu.
     * @param {Array} data The data which is shown in the table.
     * @param {Array} header The headers which are shown in the tableheader.
     * @returns {Array} The interaction buttons of the table above the headers.
     */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={() => {
            this.handleRefresh()
          }}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={data}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {
            }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  getTableMenuDropdownItems = () => {
    const result = [
      translate('general.mark_delete'),
      translate('general.unmark_delete'),
      translate('general.mark_archive'),
      translate('general.unmark_archive'),
      translate('general.mark_reload'),
      translate('general.unmark_reload'),
      translate('documentprint.print'),
      translate('usermanagement.userprofile_basic_tab.rerun_autoprint')
    ]
    if (UserUtils.isDOCX()) {
      result.push(translate('usermanagement.userprofile_basic_tab.rerun_bundle_request'))
    }
    return result
  }

  render = () => {
    const {
      documents,
      document,
      id,
      drawerExpanded,
      autoDrawer,
      keepPagination,
      printInfo,
      markReload
    } = this.props
    const {
      showTableSettingsDialog,
      showDocumentInformationDialog,
      showReloadDialog,
      showDeletefromArchiveDialog,
      showPrintDialog,
      showRerunDialog,
      showDownloadDialog,
      selectedMimeType,
      selectedDownloadType,
      showPrintDialogMultiple,
      documentsToPrint,
      documentsToReload,
      showRerunDialogMultiple,
      showReloadDialogMultiple,
      showSearchByIndexDialog,
      searchByIndexDialogData
    } = this.state
    const header = this.getUsedHeader()
    const fillPage = this.getFillPageInfo()
    const data = documents && documents.data
      ? this.getCleanData()
      : null

    return (
      <>
        {
          showDocumentInformationDialog &&
          <DocumentInformation
            id={id}
            onClose={() => {
              this.setState({ showDocumentInformationDialog: false })
            }}
            document={document}
          />
        }
        {
          showPrintDialog &&
          <DocumentPrint
            id={id}
            onClose={() => this.setState({ showPrintDialog: false })}
            document={document}
            printInfo={printInfo}
          />
        }
        {
          showPrintDialogMultiple &&
          <DocumentPrint
            id={id}
            onClose={() => this.setState({ showPrintDialogMultiple: false, documentsToPrint: {} })}
            printInfo={printInfo}
            documentsToPrint={documentsToPrint}
          />
        }
        {
          showReloadDialog &&
          <MarkReloadDocumentDialog
            id={id}
            onClose={() => this.setState({ showReloadDialog: false })}
            document={document}
            markReload={(obj, callback) => markReload(obj, callback)}
          />
        }
        {
          showReloadDialogMultiple &&
          <MarkReloadDocumentDialog
            id={id}
            onClose={() => this.setState({ showReloadDialogMultiple: false, docoumentsToReload: {} })}
            document={document}
            markReload={(obj, callback) => markReload(obj, callback)}
            documentsToReload={documentsToReload}
          // resetCheckedRows={this.resetCheckedRows}
          />
        }
        {
          showRerunDialog &&
          <RerunDialog
            id={id}
            onClose={() => this.setState({ showRerunDialog: false })}
            type={this.state.rerunType}
            document={document}
          />
        }
        {
          showRerunDialogMultiple &&
          <RerunDialog
            id={id}
            onClose={() => this.setState({ showRerunDialogMultiple: false, documentsToPrint: {} })}
            type={this.state.rerunType}
            documentsToPrint={documentsToPrint}
          />
        }
        {
          showDownloadDialog &&
          <DownloadDialog
            id={selectedDownloadType === 'TEXT' ? 'download_text_dialog' : 'download_hex_dialog'}
            onClose={() => this.setState({
              showDownloadDialog: false,
              selectedRow: undefined,
              selectedMimeType: undefined,
              selectedDownloadType: undefined
            })}
            mimetype={selectedMimeType}
            download={selectedDownloadType === 'TEXT' ? this.executeDownloadTextFromDialog : this.handleDocumentDownloadHex}
            downloadType={selectedDownloadType}
            title={selectedDownloadType === 'TEXT' ? translate('general.download_as_text') : translate('standard_selection.download_as_hex')}
          />
        }
        {
          showSearchByIndexDialog &&
          <SearchByIndexDialog
            id={`${id}_searchBYIndexDialog`}
            document={searchByIndexDialogData}
            onClose={() => this.setState({ searchByIndexDialogData: null, showSearchByIndexDialog: false })}
          />
        }
        {showDeletefromArchiveDialog && (
          <DeleteDialog
            id={`${id}_deletedocumentfromarchivedialog`}
            title={translate('general.delete_from_archive')}
            question={translate('search.question_delete_from_archive')}
            visible={showDeletefromArchiveDialog}
            onClose={() => {
              this.setState({ showDeletefromArchiveDialog: false })
            }}
            onDelete={() => this.deleteFromArchive()}
          >
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_form`}>
                  {translate('general.form')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_form_value`}>
                  {document.FORM}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_extension`}>
                  {translate('general.extension')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_extension_value`}>
                  {document.EXT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_report`}>
                  {translate('general.report')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_report_value`}>
                  {document.WREPORT}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.settingsTranslatedHeaders.length ? this.state.settingsTranslatedHeaders : this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_STANDARDSELECTION }}
          />
        )
        }
        <SelectionTable
          id={this.props.id}
          headers={header}
          documents={documents}
          keepPagination={keepPagination}
          fillPage={fillPage}
          createActionButtons={this.createActionButtons}
          createTableRowAction={this.handleShowDocumentInformation}
          additionalInteraction={this.createInteractionButtons(data, this.state.translatedHeaders)}
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}
          openDocViewerCallback={this.openSelectedRowsInDocViewer}
          checkedRows={this.state.checkedRows}
          checkedDropdownItems={this.getTableMenuDropdownItems()}
          onCheckedDropdownAction={(checkedRows, index) => this.multiMarkAction(checkedRows, index)}
          resultTableType={EResultTableType.BRWTAB}
        />
      </>
    )
  }
}

const B93DATE = 'B93DATE'
const B93TIME = 'B93TIME'
const FORM = 'FORM'
const SRCJOBN = 'SRCJOBN'
const SRCJOBI = 'SRCJOBI'
const EXT = 'EXT'
const WREPORT = 'WREPORT'
const LTOKEN = 'LTOKEN'
const RTOKEN = 'RTOKEN'
const LTITLE = 'LTITLE'
const SRCPAGES = 'SRCPAGES'
const LGRONLNE = 'LGRONLNE'
const LGRARCH = 'LGRARCH'
const LGRFFARC = 'LGRFFARC'
const LGRFFDEL = 'LGRFFDEL'
const LGRFFREL = 'LGRFFREL'
const B93SDATE = 'B93SDATE'
const B93STIME = 'B93STIME'
const SRCSUBU = 'SRCSUBU'
const OWNER = 'OWNER'
const CREATIONDATE = 'CREATIONDATE'

SearchResultStandardSelection.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    documents: state.search.standardselection.documents,
    document: state.search.standardselection.document,
    keepPagination: state.search.standardselection.keepPagination,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    bwesystemname: state.auth.serverdata.bwesystemname,
    bwebaseurl: state.auth.serverdata.bwebaseurl,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    printInfo: state.search.standardselection.documentPrintInfo,
    userprofile: state.auth.serverdata.userprofile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getDocuments: (fields, searchParams, callback) => {
      StandardSelectionActions.getDocuments(fields, searchParams, callback)(dispatch)
    },
    getDocument: (fields, docid, process, callback) => {
      return StandardSelectionActions.getDocument(fields, docid, process, callback)(dispatch)
    },
    markArchive: (documentDefinition, callback) => {
      StandardSelectionActions.markArchive(documentDefinition, callback)(dispatch)
    },
    markDelete: (documentDefinition, callback) => {
      StandardSelectionActions.markDelete(documentDefinition, callback)(dispatch)
    },
    markReload: (documentDefinition, callback) => {
      StandardSelectionActions.markReload(documentDefinition, callback)(dispatch)
    },
    getPrintInfo: (docids, callback) => {
      return StandardSelectionActions.getPrintInfo(docids, callback)(dispatch)
    },
    downloadDocument: (docid, downloadname, callback) => {
      StandardSelectionActions.downloadDocument(docid, downloadname, callback)(dispatch)
    },
    downloadDocumentSideFile: (docid, downloadname, displayMode, callback) => {
      StandardSelectionActions.downloadDocumentSideFile(docid, downloadname, displayMode, callback)(dispatch)
    },
    downloadDocumentText: (docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback) => {
      StandardSelectionActions.downloadDocumentText(docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback)(dispatch)
    },
    downloadDocumentHex: (docid, downloadname, displayMode, showPageHeader, showResources, callback) => {
      StandardSelectionActions.downloadDocumentHex(docid, downloadname, displayMode, showPageHeader, showResources, callback)(dispatch)
    },
    displayDocument: (docid, betaviewBaseurl, betaviewFavname, callback) => {
      StandardSelectionActions.displayDocument(docid, betaviewBaseurl, betaviewFavname, callback)(dispatch)
    },
    updateMarkMulti: (updateObj, docInformation, callback) => {
      StandardSelectionActions.updateMarkMulti(updateObj, docInformation, callback)(dispatch)
    },
    addTabToDocViewer: (documentData, callback) => {
      DocViewerActions.addTabToDocViewer(documentData, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultStandardSelection))