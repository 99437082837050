import React, { useEffect, useState } from 'react';
import './DocumentBrowserHexSegment.scss';

import * as ATTRIBUTES from '../../constants/attributes';
import * as DATA from '../../constants/data';

import { useTabContentContext } from '../../contexts/TabContentContext';

import * as DOC_VIEWER_UTILS from '../../helper/utils';

import * as TYPES_DOC_BROWSER_HEX_AREA from '../../types/DocumentBrowserHexArea.types';
import * as TYPES_DOC_VIEWER from '../../types/DocumentViewer.types';
import { translate } from 'language/Language';
import { CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT } from '../../constants/attributes';
import { BrowserTooltip } from 'BetaUX2Web-Components/src';

export const HexSegmentBlock = ({ line, lineIndex, maxBlockLength }: { line: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexBlockDataContent, lineIndex: number, maxBlockLength: number }) => {
  const [renderConfiguration, setRenderConfiguration] = useState<{ x: number | null, y: number | null, showInactiveLine: boolean }>({ x: null, y: null, showInactiveLine: true });

  /**
   * @description This function will trigger the rendering of the inactive line, so that the function handleSimulateClick can be executed on this component.
   */
  const handleMouseDownOnLine = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    setRenderConfiguration({ x: event.clientX, y: event.clientY, showInactiveLine: false });
  };

  /**
   * @description This function will trigger a click on the provided coordinates after the active line component is rendered.
   * @param x X coordinate for the target click
   * @param y y coordinate for the target click
   */
  const handleSimulateClick = (x: number, y: number) => {
    const elementToClick = document.elementFromPoint(x, y)
    if (!elementToClick) return;

    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      screenX: x,
      screenY: y
    })

    elementToClick.dispatchEvent(event)
  };

  /**
   * @description This function will trigger the rendering of the inactive line component in case there are no highlights in the line.
   * @param mutationList List of mutation for all descendants for attributes defined in observer options (e.g., 'class')
   */
  const callbackOnObservedMutationsInLine = (mutationList: MutationRecord[]) => {
    const lineHasHighlight = document.getElementById(`${ATTRIBUTES.ID_HEX_BLOCK_LINE}${lineIndex}`)?.getElementsByClassName(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);

    for (const mutation of mutationList) {
      if (mutation.type !== 'attributes') continue;

      if (!(mutation.target as Element).className.includes(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT) && (!lineHasHighlight || lineHasHighlight.length === 0)) {
        // Trigger rendering of inactive line component if there are no highlights in the current line due to a line switch or a manual removal of the highlight (clicking on the highlight):
        setRenderConfiguration({ x: null, y: null, showInactiveLine: true });
      }
    }
  };

  useEffect(() => {
    // Target node to watch for mutations
    const baseElement = document.getElementById(`${ATTRIBUTES.ID_HEX_BLOCK_LINE}${lineIndex}`);
    if (!baseElement) return;

    const observer = new MutationObserver(callbackOnObservedMutationsInLine);
    observer.observe(baseElement, { attributes: true, attributeFilter: ['class'], childList: false, subtree: true });

    return () => {
      observer.disconnect();
    }
    // Note: useEffect with empty array to trigger on mount/unmount -> gets misinterpreted by ESLint due to function ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!renderConfiguration.showInactiveLine && renderConfiguration.x && renderConfiguration.y) {
      handleSimulateClick(renderConfiguration.x, renderConfiguration.y);
    }
    // Note: handleSimulateClick has no indirect state dependency, necessary state information gets passes as argument(s), ESLint is not taking this into account
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderConfiguration.showInactiveLine])

  return (
    <div id={`${ATTRIBUTES.ID_HEX_BLOCK_LINE}${lineIndex}`} key={`${ATTRIBUTES.ID_HEX_BLOCK_LINE}${lineIndex}`}>
      {renderConfiguration.showInactiveLine ? (
        <HexSegmentBlockInactive
          line={line}
          lineIndex={lineIndex}
          maxBlockLength={maxBlockLength}
          handleMouseDown={handleMouseDownOnLine}
        />
      ) : (
        <HexSegmentBlockActive
          line={line}
          lineIndex={lineIndex}
          maxBlockLength={maxBlockLength}
        />
      )}
    </div>
  )
};

export const HexSegmentBlockActive = ({ line, lineIndex, maxBlockLength }: { line: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexBlockDataContent, lineIndex: number, maxBlockLength: number }) => {
  const { cacheKey } = useTabContentContext() as TYPES_DOC_VIEWER.ITabContentContext;

  const hex8DigitPartFillerSpaces = ''.padEnd(maxBlockLength - (line.hex ? line.hex?.join(' ').length : 0) - `${line.marker}${DATA.DOC_VIEWER_HEX_MARKER_SEPARATOR}`.length - line.text.length - 5, ' '); // Add remaining whitespaces to end of hex digit part to be consistent with plain segment

  return (
    <div className='hexblock_segment_line_wrapper'>
      <div className='hexblock_segment_line_marker' key={`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}_line${lineIndex}_marker_${cacheKey}`}>
        {`${line.marker}${DATA.DOC_VIEWER_HEX_MARKER_SEPARATOR}`}
      </div>
      <div className='hexblock_segment_line_hex' key={`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}_line${lineIndex}_hex_${cacheKey}`}>
        <div className='hexblock_segment_line_hex_8digit_part_wrapper' id={`hexblock_segment_line_hex_8digit_part_wrapper_${lineIndex}`} key={`hexblock_segment_line_hex_8digit_part_wrapper_${lineIndex}_${cacheKey}`}>
          {line.hex?.map((hex8DigitPart: string, hex8DigitIndex: number) => {
            return (
              <React.Fragment key={`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}_fragment_line${lineIndex}_hex${hex8DigitIndex}_${cacheKey}`}>
                <div className='hexblock_segment_line_hex_8digit_part' key={`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}_line${lineIndex}_hex${hex8DigitIndex}_${cacheKey}`}>
                  {DOC_VIEWER_UTILS.getChunks(hex8DigitPart).map((chunk: string, chunkIndex: number) => {
                    return (
                      <p
                        className={'hexblock_segment_line_hex_chunk'}
                        id={`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}_line${lineIndex}_hex${hex8DigitIndex}_chunk${chunkIndex}_${cacheKey}`}
                        key={`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}_line${lineIndex}_hex${hex8DigitIndex}_chunk${chunkIndex}_${cacheKey}`}>
                        {chunk}
                      </p>
                    )
                  })}
                </div>
                <div>
                  {line.hex && hex8DigitIndex !== line.hex.length - 1 ? ' ' : hex8DigitPartFillerSpaces}
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
      <div className='hexblock_segment_line_separator'>
        {line.hex && line.hex.length !== 0 ? DATA.DOC_VIEWER_HEX_SEPARATOR_START : null}
      </div>
      <div className='hexblock_segment_line_text_wrapper' key={`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}_line${lineIndex}_text`}>
        {line.text.split('').map((char: string, charIndex: number) => {
          return (
            <p
              className={'hexblock_segment_line_text'}
              id={`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}_line${lineIndex}_text${charIndex}_${cacheKey}`}
              key={`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}_line${lineIndex}_text${charIndex}_${cacheKey}`}>
              {char}
            </p>
          )
        })}
      </div>
      <div className='hexblock_segment_line_separator'>
        {line.hex && line.hex.length !== 0 ? DATA.DOC_VIEWER_HEX_SEPARATOR_END : null}
      </div>
    </div>
  )
};

export const HexSegmentBlockInactive = ({ line, lineIndex, maxBlockLength, handleMouseDown }: { line: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexBlockDataContent, lineIndex: number, maxBlockLength: number, handleMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void }) => {
  const markerPart = line.marker ? `${line.marker}${DATA.DOC_VIEWER_HEX_MARKER_SEPARATOR}` : '';
  const textPart = !['page', 'head', 'res'].some((sectionMarker) => markerPart.includes(sectionMarker)) ? `${DATA.DOC_VIEWER_HEX_SEPARATOR_START}${line.text}${DATA.DOC_VIEWER_HEX_SEPARATOR_END}` : '';
  const hexPart = line.hex ? line.hex.join(' ').padEnd(maxBlockLength - markerPart.length - textPart.length, ' ') : '';

  return (
    <div
      id={`${ATTRIBUTES.ID_HEX_BLOCK_INACTIVE_SEGMENT}_line${lineIndex}`}
      key={`${ATTRIBUTES.ID_HEX_BLOCK_INACTIVE_SEGMENT}_line${lineIndex}`}
      className='hexblock_segment_inactive_line_wrapper'
      onMouseDown={handleMouseDown}
    >
      {`${markerPart}${hexPart}${textPart}`}
    </div>
  )
};

export const HexSegmentLine = ({ segment, segmentIndex }: { segment: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent[], segmentIndex: number }) => {
  const [renderConfiguration, setRenderConfiguration] = useState<{ x: number | null, y: number | null, showInActiveBlock: boolean }>({ x: null, y: null, showInActiveBlock: true });

  /**
   * @description This function will trigger the rendering of the inactive line, so that the function handleSimulateClick can be executed on this component.
   */
  const handleMouseDownOnLine = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    setRenderConfiguration({ x: event.clientX, y: event.clientY, showInActiveBlock: false });
  };

  /**
   * @description This function will trigger a click on the provided coordinates after the active line component is rendered.
   * @param x X coordinate for the target click
   * @param y y coordinate for the target click
   */
  const handleSimulateClick = (x: number, y: number) => {
    const elementToClick = document.elementFromPoint(x, y)
    if (!elementToClick) return;

    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      screenX: x,
      screenY: y
    })

    elementToClick.dispatchEvent(event)
  };

  /**
   * @description This function will trigger the rendering of the inactive line component in case there are no highlights in the line.
   * @param mutationList List of mutation for all descendants for attributes defined in observer options (e.g., 'class')
   */
  const callbackOnObservedMutationsInLine = (mutationList: MutationRecord[]) => {
    const lineHasHighlight = document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_BLOCK}${segmentIndex}`)?.getElementsByClassName(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);

    for (const mutation of mutationList) {
      if (mutation.type !== 'attributes') continue;

      if (!(mutation.target as Element).className.includes(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT) && (!lineHasHighlight || lineHasHighlight.length === 0)) {
        // Trigger rendering of inactive line component if there are no highlights in the current line due to a line switch or a manual removal of the highlight (clicking on the highlight):
        setRenderConfiguration({ x: null, y: null, showInActiveBlock: true });
      }
    }
  };

  useEffect(() => {
    // Target node to watch for mutations
    const baseElement = document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_BLOCK}${segmentIndex}`);
    if (!baseElement) return;

    const observer = new MutationObserver(callbackOnObservedMutationsInLine);
    observer.observe(baseElement, { attributes: true, attributeFilter: ['class'], childList: false, subtree: true });

    return () => {
      observer.disconnect();
    }
    // Note: useEffect with empty array to trigger on mount/unmount -> gets misinterpreted by ESLint due to function ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!renderConfiguration.showInActiveBlock && renderConfiguration.x && renderConfiguration.y) {
      handleSimulateClick(renderConfiguration.x, renderConfiguration.y);
    }
    // Note: handleSimulateClick has no indirect state dependency, necessary state information gets passes as argument(s), ESLint is not taking this into account
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderConfiguration.showInActiveBlock])

  return (
    <div id={`${ATTRIBUTES.ID_HEX_LINE_BLOCK}${segmentIndex}`} key={`${ATTRIBUTES.ID_HEX_LINE_BLOCK}${segmentIndex}`}>
      {renderConfiguration.showInActiveBlock ? (
        <HexSegmentLineInactive
          segment={segment}
          segmentIndex={segmentIndex}
          handleMouseDown={handleMouseDownOnLine}
        />
      ) : (
        <HexSegmentLineActive
          segment={segment}
          segmentIndex={segmentIndex}
        />
      )}
    </div>
  )
};

export const HexSegmentLineActive = ({ segment, segmentIndex }: { segment: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent[], segmentIndex: number }) => {
  const { cacheKey } = useTabContentContext() as TYPES_DOC_VIEWER.ITabContentContext;
  const [tooltip, setTooltip] = useState<{ posX: number; posY: number; columnPosition: number } | undefined>(undefined);

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const clientX = event.clientX;
    const clientY = event.clientY;
    const regexToGetColumn = /_text(.*?)_/;
    const column = Number(event.currentTarget.id.match(regexToGetColumn)?.[1]) + 1

    const isActiveColumn = event.currentTarget.className.includes(CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT)
    if (isActiveColumn) {
      setTooltip({ posX: clientX, posY: clientY, columnPosition: column });
    } else {
      setTooltip(undefined)
    }
  }

  return (
    <div className='hexline_segment'>
      {segment.map((line: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent, lineIndex : number) => {
        return (
          <div className='hexline_segment_line_wrapper' key={`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${segmentIndex}_line${lineIndex}_wrapper`}>
            <div className='hexline_segment_line_marker' key={`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${segmentIndex}_line${lineIndex}_marker`}>
              {`${line.marker}${DATA.DOC_VIEWER_HEX_MARKER_SEPARATOR}`}
            </div>
            <div className='hexline_segment_line' key={`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${segmentIndex}_line${lineIndex}`}>
              {line.hexAndTextLine.split('').map((char: string, charIndex: number) => {
                return (
                  <React.Fragment key={`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${segmentIndex}_line${lineIndex}_text${charIndex}_${cacheKey}`}>
                    {tooltip && <BrowserTooltip top={tooltip.posY} left={tooltip.posX}>
                      <span>{translate('general.line')}: {Number(line.marker)}</span>
                      <span>{translate('general.column')}: {tooltip.columnPosition}</span>
                    </BrowserTooltip>}
                    <div
                      className='hexline_segment_line_text'
                      id={`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${segmentIndex}_line${lineIndex}_text${charIndex}_${cacheKey}`}
                      onMouseMove={onMouseMove}
                      onMouseOut={() => setTooltip(undefined)}>
                      {char}
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
};

export const HexSegmentLineInactive = ({ segment, segmentIndex, handleMouseDown }: { segment: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent[], segmentIndex: number, handleMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void }) => {
  const segmentText = segment.reduce((block, line) => block + `${line.marker}${line.hexAndTextLine ? DATA.DOC_VIEWER_HEX_MARKER_SEPARATOR : ''}${line.hexAndTextLine}\n`, '');

  return (
    <div
      id={`${ATTRIBUTES.ID_HEX_LINE_INACTIVE_SEGMENT}_segment${segmentIndex}`}
      key={`${ATTRIBUTES.ID_HEX_LINE_INACTIVE_SEGMENT}_segment${segmentIndex}`}
      className='hexline_segment_inactive_line_wrapper'
      onMouseDown={handleMouseDown}
    >
      {segmentText}
    </div>
  )
};