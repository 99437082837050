import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import * as ObjectUtils from 'utils/ObjectUtils'
import * as ResponseUtils from 'utils/ResponseUtils'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

export const GET_INDEX_QUEUES_START = 'GET_INDEX_QUEUES_START'
export const GET_INDEX_QUEUES_SUCCESS = 'GET_INDEX_QUEUES_SUCCESS'
export const GET_INDEX_QUEUES_FAILED = 'GET_INDEX_QUEUES_FAILED'
export const NO_INDEX_QUEUES_FOUND = 'NO_INDEX_QUEUES_FOUND'

export const GET_INDEX_QUEUE_DETAILS_START = 'GET_INDEX_QUEUE_DETAILS_START'
export const GET_INDEX_QUEUE_DETAILS_SUCCESS = 'GET_INDEX_QUEUE_DETAILS_SUCCESS'
export const GET_INDEX_QUEUE_DETAILS_FAILED = 'GET_INDEX_QUEUE_DETAILS_FAILED'

export const DELETE_INDEX_QUEUE_START = 'DELETE_INDEX_QUEUE_START'
export const DELETE_INDEX_QUEUE_SUCCESS = 'DELETE_INDEX_QUEUE_SUCCESS'
export const DELETE_INDEX_QUEUE_FAILED = 'DELETE_INDEX_QUEUE_FAILED'

export const REQUEST_INDEX_QUEUE_START = 'REQUEST_INDEX_QUEUE_START'
export const REQUEST_INDEX_QUEUE_SUCCESS = 'REQUEST_INDEX_QUEUE_SUCCESS'
export const REQUEST_INDEX_QUEUE_FAILED = 'REQUEST_INDEX_QUEUE_FAILED'

export const RERUN_INDEX_QUEUE_START = 'RERUN_INDEX_QUEUE_START'
export const RERUN_INDEX_QUEUE_SUCCESS = 'RERUN_INDEX_QUEUE_SUCCESS'
export const RERUN_INDEX_QUEUE_FAILED = 'RERUN_INDEX_QUEUE_FAILED'

/**
 * @description Calling the rest api and list index queues.
 * @param {Object} searchParams The keywords for get index queues. Possible values:
 * DOCID, FROMLAST, TUNITS, SDATE, EDATE, ETIME, FORM, EXTENSION, REPORT, STATUS
 * @param {Boolean} keepPagination Flag, if a new search is done by user.
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function getIndexQueues(searchParams, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_INDEX_QUEUES_START })

    // clear invalid values of object and encode
    searchParams = ObjectUtils.removeByValue(searchParams, [undefined, null, ''])

    const queryParams = []
    Object.keys(searchParams).forEach(key => queryParams.push(`${key}=${encodeURIComponent(searchParams[key])}`))

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/index?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            // if (jsondata.data.length === 0) {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_INDEX_QUEUES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_INDEX_QUEUES_FAILED })
          }
        } else {
          dispatch({ type: GET_INDEX_QUEUES_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('queue.index_queue_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_INDEX_QUEUES_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and get details of a specific index queue.
 * @param {String} ttoken The ttoken of the specific index queue.
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function getIndexQueueDetails(ttoken, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_INDEX_QUEUES_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/index/details?TTOKEN=${encodeURIComponent(ttoken)}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_INDEX_QUEUE_DETAILS_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: GET_INDEX_QUEUE_DETAILS_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.get_index_queue_details_error', lang, [ttoken, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_INDEX_QUEUE_DETAILS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and get details of a specific index queue.
 * @param {Array} docids The docids which should be requeued.
 * @param {String} filter The filter which can be "*" for any.
 * @param {String} user The requester of the index request.
 * @param {Number} priority The priority of the index request (1-9)
 * @param {String} process The process instruction. Possible values:
 * 'ALL': All,
 * 'IX': Index,
 * 'FX': Filter
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function queueIndexRequest(docids, filter, user, priority, process, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: REQUEST_INDEX_QUEUE_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/index`, {
        method: 'post',
        body: ObjectUtils.removeByValue({
          DOCIDS: docids,
          FILTER: filter,
          CIQUSER: user,
          CIQPRIO: priority,
          PROCESS: process
        }, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (jsondata.additionalInfo) {
          const rsp = ResponseUtils.processResponse(jsondata.additionalInfo, Lang.translate('queue.insert_index_request_success'), dispatch)

          SnackbarActions.showMultiple(rsp.snackbarContent)(dispatch)
          if (jsondata?.success && callback) {
            callback(rsp.successObjects)
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.insert_index_request_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: REQUEST_INDEX_QUEUE_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and deletes an index queue.
 * @param {Object} indexQueue ttoken, form, extension, report
 * @param {Function} callback
 */
export function deleteIndexQueue(indexQueue, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    let deleteMessage = [
      `${Lang.translate('general.form')}: ${indexQueue['FORM']}`,
      indexQueue['EXT'] && `, ${Lang.translate('general.extension')}: ${indexQueue['EXT']}`,
      indexQueue['WREPORT'] && `, ${Lang.translate('general.report')}: ${indexQueue['WREPORT']}`,
    ]

    dispatch({ type: DELETE_INDEX_QUEUE_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/index${createQueryParamsForFetch({ TTOKENS: indexQueue['TTOKEN'] })}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.additionalInfo[0].error.rc
          let irc = jsondata.additionalInfo[0].error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.additionalInfo[0].error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DELETE_INDEX_QUEUE_FAILED })
        } else {
          dispatch({ type: DELETE_INDEX_QUEUE_SUCCESS })

          SnackbarActions.show(
            Lang.translate('queue.delete_index_queue_success', lang, deleteMessage), SnackbarActions.TYPE_SUCCESS
          )(dispatch)

          if (callback) {
            callback()
          }
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(
          Lang.translate('queue.delete_index_queue_error', lang, [...deleteMessage, reason]), SnackbarActions.TYPE_ERROR
        )(dispatch)
        dispatch({ type: DELETE_INDEX_QUEUE_FAILED })
      })
  }
}

/**
 * @description Calling the rest api and reruns a single index queue.
 * @param {Function} callback the callback which will be called when the request was successful. Can be undefined.
 */
export function rerunIndexQueue(rerunObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: RERUN_INDEX_QUEUE_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/index/rerun`, {
        method: 'post',
        body: rerunObj
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (jsondata && !jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: RERUN_INDEX_QUEUE_FAILED, payload: { error } })
        } else {
          dispatch({ type: RERUN_INDEX_QUEUE_SUCCESS, payload: jsondata.data })
          SnackbarActions.show(Lang.translate('queue.index_rerun_success', lang, rerunObj.DOCID), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }
          refreshSearch()(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.index_rerun_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: REQUEST_INDEX_QUEUE_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Rereshes the search
 */
export const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const searchParam = {
      'FROMLAST': prefs[Preferences.QUEUE_INDEX_TIME_CUSTOM_LAST],
      'TUNITS': prefs[Preferences.QUEUE_INDEX_TIME_CUSTOM_UNIT],
      'SDATE': prefs[Preferences.QUEUE_INDEX_START_DATE],
      'STIME': prefs[Preferences.QUEUE_INDEX_START_TIME],
      'EDATE': prefs[Preferences.QUEUE_INDEX_END_DATE],
      'ETIME': prefs[Preferences.QUEUE_INDEX_END_TIME],
      'FORM': prefs[Preferences.QUEUE_INDEX_FORM],
      'EXTENSION': prefs[Preferences.QUEUE_INDEX_EXTENSION],
      'REPORT': prefs[Preferences.QUEUE_INDEX_REPORT],
      'STATUS': prefs[Preferences.QUEUE_INDEX_STATUS]
    }
    getIndexQueues(searchParam, undefined, true)(dispatch)
  }
}