import { LOGOUT_SUCCESS } from 'redux/actions/AuthenticationActions'
import * as LPDQueueActions from '../actions/LPDQueueActions'

const defaultState = {
  lpdQueues: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case LPDQueueActions.GET_LPD_QUEUES_START:
      return { ...state }
    case LPDQueueActions.GET_LPD_QUEUES_SUCCESS:
      return { ...state, lpdQueues: action.payload }
    case LPDQueueActions.GET_LPD_QUEUES_FAILED:
      return { ...state }
    case LPDQueueActions.NO_LPD_QUEUES_FOUND:
      return { ...state, lpdQueues: {} }
    /* ********************************************************************************** */
    // Logout actions
    case LOGOUT_SUCCESS:
      return { ...state, lpdQueues: undefined }
    default: return state
  }
}

export default reducer