import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'

import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import { hasNoValues } from 'utils/ObjectUtils'
import * as Utils from 'utils/Utils'

// components
import {
  Button, Checkbox, Column, Dropdown, Input,
  Modal as ModalComponent, NumericSpinner, Row, Switch, Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as ArchivePoolDefinitionActions from 'redux/actions/ArchivePoolDefinitionActions'
import * as Preferences from 'redux/general/Preferences'

class CreateArchivePoolDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    prefilledData: PropTypes.object,
    onClose: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props)
    this.archivePoolIdInput = React.createRef()
    this.fileNameMaskInput = React.createRef()
    this.reloadScriptInput = React.createRef()
    this.deleteScriptInput = React.createRef()
    this.defaultState = {
      generalTab: {
        archivePoolID: {
          value: '',
          errorkey: ''
        },
        owner: '',
        retentionPeriod: 1,
        media: 0,
        title: '',
        orderForReload: 0,
        reloadOrderValue: 1
      },
      subpoolTab: {
        createInitialSubpool: false,
        retentionPeriod: 1,
        retentionPeriod2: 1,
        orderForReload: 1,
        media: 0,
        fileSize: 1,
        fileNameMask: {
          value: '',
          errorkey: ''
        },
        fileManagedBy: 0,
        useStorageApi: 1,
        reloadScriptCommand: {
          dropdown: 0,
          input: {
            value: '',
            errorkey: ''
          }
        },
        deleteScriptCommand: {
          dropdown: 0,
          input: {
            value: '',
            errorkey: ''
          }
        }
      }
    }
    this.state = _.cloneDeep(this.defaultState)
  }

  resetState = () => {
    this.setState(_.cloneDeep(this.defaultState))
  }

  componentDidMount() {
    this.archivePoolIdInput.current.focus()

    if (this.props.prefilledData) {
      const mediaKey = DefinitionUtils.archiveMediaItems(false).findIndex((mediaInfo) => mediaInfo.key === this.props.prefilledData?.ADPMED)

      this.setState({
        ...this.state,
        generalTab: {
          ...this.state.generalTab,
          archivePoolID: {
            value: this.props.prefilledData?.ARCPOOL ?? '',
            errorkey: ''
          },
          owner: this.props.prefilledData?.OWNER ?? '',
          media: mediaKey !== -1 ? mediaKey : 0,
          retentionPeriod: this.props.prefilledData?.ADPRETPD ?? 1,
          title: this.props.prefilledData?.ADPTITLE ?? '',
        }
      })
    }
  }

  /**
   * @description Handles the input changes of the input fields.
   * @param {String} id The id the input field.
   * @param {String} value The new value.
   * @param {String} errorkey The new errorkey.
   */
  handleChangeGeneralTab = (id, value, errorkey) => {
    if (id === 'archivePoolID') {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          [id]: {
            value: value,
            errorkey: errorkey,
          }
        }
      })
    } else {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          [id]: value
        }
      })
    }
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleChangeGeneralTab(key, value, error)
  }

  handleChangeSubpoolTab = (id, value) => {
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        [id]: value
      }
    }, () => {
      if (id === 'createInitialSubpool' && !value) {
        this.resetErrorkeys()
      }
    })
  }

  resetErrorkeys = () => {
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        fileNameMask: {
          ...this.state.subpoolTab.fileNameMask,
          errorkey: ''
        },
        reloadScriptCommand: {
          ...this.state.subpoolTab.reloadScriptCommand,
          input: {
            ...this.state.subpoolTab.reloadScriptCommand.input,
            errorkey: ''
          }
        },
        deleteScriptCommand: {
          ...this.state.subpoolTab.deleteScriptCommand,
          input: {
            ...this.state.subpoolTab.deleteScriptCommand.input,
            errorkey: ''
          }
        }
      }
    })
  }

  handleChangeSubpoolTabFileNameMask = value => {
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        fileNameMask: {
          value,
          errorkey: ''
        }
      }
    })
  }

  /**
   * @description Validates the index id.
   */
  validateID = () => {
    const { generalTab } = this.state
    if (generalTab.archivePoolID.value !== '') {
      return {}
    }
    return {
      archivePoolID: {
        ...this.state.generalTab.archivePoolID,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateGeneralTab = () => {
    const validatorResult = { ...this.validateID() }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ generalTab: { ...this.state.generalTab, ...validatorResult } }, () => {
        this.archivePoolIdInput.current && this.archivePoolIdInput.current.focus()
      })
    }
    return errors === 0
  }

  handleSubpoolTabFocus = () => {
    const requiredInputs = [
      { inputRef: this.fileNameMaskInput, error: this.state.subpoolTab.fileNameMask.errorkey },
      { inputRef: this.reloadScriptInput, error: this.state.subpoolTab.reloadScriptCommand.input.errorkey },
      { inputRef: this.deleteScriptInput, error: this.state.subpoolTab.deleteScriptCommand.input.errorkey }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Gets the translated data format items.
   * @returns {Array} The translated data format items.
   */
  getArchiveMediaTranslatedItems = () => {
    const items = []
    DefinitionUtils.ARCHIVEPOOL_MEDIA.filter((_, i) => i > 0).forEach(type => {
      items.push(translate(type.translationKey))
    })
    return items
  }

  renderGeneralTab = () => {
    const { id } = this.props
    const { generalTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_archive_pool_id`}
              value={generalTab.archivePoolID.value}
              title={translate('definition.archive_pool_id')}
              ref={this.archivePoolIdInput}
              required={`${translate('general.required_field')}`}
              maxLength={8}
              onInputChanged={(value, errorkey) => this.handleChangeGeneralTab('archivePoolID', value, errorkey)}
              error={generalTab.archivePoolID.errorkey && translate(generalTab.archivePoolID.errorkey)}
              onBlur={() => this.setState({ generalTab: { ...this.state.generalTab, ...this.validateID() } })}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_archive_pool_owner`}
              value={generalTab.owner}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={value => this.handleChangeGeneralTab('owner', value)}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_archive_pool_retention_period`}
              title={translate('general.retention_period_days')}
              onChange={val => this.handleChangeGeneralTab('retentionPeriod', val)}
              value={generalTab.retentionPeriod}
              min={1}
              max={30000}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_archive_pool_media`}
              title={translate('general.archive_media')}
              items={this.getArchiveMediaTranslatedItems()}
              activeIndex={generalTab.media}
              onChange={value => this.handleChangeGeneralTab('media', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_archive_pool_title`}
              value={generalTab.title}
              title={translate('general.title')}
              maxLength={80}
              onInputChanged={value => this.handleChangeGeneralTab('title', value)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_archive_pool_order_for_reload`}
              title={translate('definition.archive_pool_order_for_reload')}
              items={[translate('general.as_is'), translate('general.value')]}
              onClick={val => this.handleChangeGeneralTab('orderForReload', val)}
              activeIndex={generalTab.orderForReload}
            />
          </Column>
          {
            generalTab.orderForReload === 1 &&
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_archive_pool_reload_order_value`}
                title={translate('definition.archive_pool_reload_order_value')}
                onChange={val => this.handleChangeGeneralTab('reloadOrderValue', val)}
                value={generalTab.reloadOrderValue}
                min={1}
                max={99}
                steps={1}
              />
            </Column>
          }
        </Row>
      </>
    )
  }

  getScriptItems = () => {
    return [
      './buxbarcemc.exe',
      './buxbarctms.exe',
      translate('lpdqueue.transfer_other')
    ]
  }

  handleChangeReloadScriptCommandDropdown = val => {
    const { subpoolTab } = this.state
    let reloadScriptCommandInputValueToUse = subpoolTab.reloadScriptCommand.input.value
    // cuts the input valu if the length is bigger then 239 and the dropdown items is not 'other'
    if (val !== 2 && reloadScriptCommandInputValueToUse.length > 239) {
      reloadScriptCommandInputValueToUse = reloadScriptCommandInputValueToUse.substring(239, 0)
    }
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        reloadScriptCommand: {
          ...this.state.subpoolTab.reloadScriptCommand,
          dropdown: val,
          input: {
            value: reloadScriptCommandInputValueToUse,
            errorkey: ''
          }
        }
      }
    })
  }

  handleChangeReloadScriptCommandInput = val => {
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        reloadScriptCommand: {
          ...this.state.subpoolTab.reloadScriptCommand,
          input: {
            value: val,
            errorkey: ''
          }
        }
      }
    })
  }

  handleChangeDeleteScriptCommandDropdown = val => {
    const { subpoolTab } = this.state
    let deleteScriptCommandInputValueToUse = subpoolTab.deleteScriptCommand.input.value
    // cuts the input valu if the length is bigger then 239 and the dropdown items is not 'other'
    if (val !== 2 && deleteScriptCommandInputValueToUse.length > 239) {
      deleteScriptCommandInputValueToUse = deleteScriptCommandInputValueToUse.substring(239, 0)
    }
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        deleteScriptCommand: {
          ...this.state.subpoolTab.deleteScriptCommand,
          dropdown: val,
          input: {
            value: deleteScriptCommandInputValueToUse,
            errorkey: ''
          }
        }
      }
    })
  }

  handleChangeDeleteScriptCommandInput = val => {
    this.setState({
      subpoolTab: {
        ...this.state.subpoolTab,
        deleteScriptCommand: {
          ...this.state.subpoolTab.deleteScriptCommand,
          input: {
            value: val,
            errorkey: ''
          }
        }
      }
    })
  }

  validateFileNameMask = () => {
    const { subpoolTab } = this.state
    if (subpoolTab.createInitialSubpool) {
      if (subpoolTab.fileNameMask.value !== '') {
        return {}
      }
      return {
        fileNameMask: {
          ...this.state.subpoolTab.fileNameMask,
          errorkey: 'general.input_required'
        }
      }
    } else {
      return {}
    }
  }

  validateReloadScriptCommand = () => {
    const { subpoolTab } = this.state
    if (subpoolTab.createInitialSubpool && subpoolTab.fileManagedBy === 1) {
      if (subpoolTab.reloadScriptCommand.input.value !== '') {
        return {}
      }
      return {
        reloadScriptCommand: {
          ...this.state.subpoolTab.reloadScriptCommand,
          input: {
            ...this.state.subpoolTab.reloadScriptCommand.input,
            errorkey: 'general.input_required'
          }
        }
      }
    } else {
      return {}
    }
  }

  validateDeleteScriptCommand = () => {
    const { subpoolTab } = this.state
    if (subpoolTab.createInitialSubpool && subpoolTab.fileManagedBy === 1) {
      if (subpoolTab.deleteScriptCommand.input.value !== '') {
        return {}
      }
      return {
        deleteScriptCommand: {
          ...this.state.subpoolTab.deleteScriptCommand,
          input: {
            ...this.state.subpoolTab.deleteScriptCommand.input,
            errorkey: 'general.input_required'
          }
        }
      }
    } else {
      return {}
    }
  }

  validateSubpoolTab = () => {
    const validatorResult = {
      ...this.validateFileNameMask(),
      ...this.validateReloadScriptCommand(),
      ...this.validateDeleteScriptCommand()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ subpoolTab: { ...this.state.subpoolTab, ...validatorResult } }, () => {
        this.handleSubpoolTabFocus()
      })
    }
    return errors === 0
  }

  renderSubpoolTab = () => {
    const { id } = this.props
    const { subpoolTab, generalTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={6}>
            <Checkbox
              id={`${id}_archive_pool_create_initial_subpool`}
              value={subpoolTab.createInitialSubpool}
              onCheck={val => this.handleChangeSubpoolTab('createInitialSubpool', val)}
              label={translate('definition.archive_pool_create_initial_subpool')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_archive_pool_retention_period_days`}
              title={translate('definition.archive_pool_retention_period_days')}
              onChange={() => {}}
              value={generalTab.retentionPeriod}
              min={1}
              max={30000}
              steps={1}
              disabled
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_archive_pool_order_for_reload`}
              title={translate('definition.archive_pool_order_for_reload')}
              onChange={val => this.handleChangeSubpoolTab('orderForReload', val)}
              value={subpoolTab.orderForReload}
              min={1}
              max={99}
              steps={1}
              disabled={!subpoolTab.createInitialSubpool}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_archive_pool_media`}
              title={translate('general.archive_media')}
              items={this.getArchiveMediaTranslatedItems()}
              activeIndex={generalTab.media}
              onChange={() => {}}
              disabled
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_archive_pool_file_size`}
              title={translate('definition.archive_pool_file_size')}
              onChange={val => this.handleChangeSubpoolTab('fileSize', val)}
              value={subpoolTab.fileSize}
              min={1}
              max={500}
              steps={1}
              disabled={!subpoolTab.createInitialSubpool}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_archive_pool_file_name_mask`}
              value={subpoolTab.fileNameMask.value}
              title={translate('definition.archive_pool_file_name_mask')}
              ref={this.fileNameMaskInput}
              maxLength={64}
              onInputChanged={value => this.handleChangeSubpoolTabFileNameMask(value)}
              error={subpoolTab.fileNameMask.errorkey && translate(subpoolTab.fileNameMask.errorkey)}
              disabled={!subpoolTab.createInitialSubpool}
              onBlur={() => this.setState(state => ({ subpoolTab: { ...state.subpoolTab, ...this.validateFileNameMask() } }))}
              required={`${translate('general.required_field')}`}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label className={!subpoolTab.createInitialSubpool ? 'disabled' : ''}>{translate('definition.archive_pool_archive_file_management')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Switch
              id={`${id}_archive_pool_file_managed_by`}
              title={translate('definition.archive_pool_file_managed_by')}
              items={[translate('general.hsm'), translate('general.api')]}
              onClick={val => this.handleChangeSubpoolTab('fileManagedBy', val)}
              activeIndex={subpoolTab.fileManagedBy}
              disabled={!subpoolTab.createInitialSubpool}
            />
          </Column>
          {
            subpoolTab.fileManagedBy === 1 &&
            <>
              <Column colMD={3}>
                <Switch
                  id={`${id}_archive_pool_use_storage_api`}
                  title={translate('definition.archive_pool_use_storage_api')}
                  items={[translate('general.yes'), translate('general.no')]}
                  onClick={val => this.handleChangeSubpoolTab('useStorageApi', val)}
                  activeIndex={subpoolTab.useStorageApi}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_archive_pool_retention_period_level2`}
                  title={translate('definition.archive_pool_retention_period_level2')}
                  onChange={val => this.handleChangeSubpoolTab('retentionPeriod2', val)}
                  value={subpoolTab.retentionPeriod2}
                  disabled={!subpoolTab.createInitialSubpool}
                  steps={1}
                  min={1}
                  max={30000}
                />
              </Column>
            </>
          }
        </Row>
        {
          subpoolTab.fileManagedBy === 1 &&
          <>
            <Row>
              <Column colMD={3}>
                <Dropdown
                  id={`${id}_archive_pool_reload_script_command_dropdown`}
                  title={translate('definition.archive_pool_reload_script_command')}
                  items={this.getScriptItems()}
                  activeIndex={subpoolTab.reloadScriptCommand.dropdown}
                  onChange={value => this.handleChangeReloadScriptCommandDropdown(value)}
                  disabled={!subpoolTab.createInitialSubpool}
                />
              </Column>
              <Column colMD={9}>
                <Input
                  id={`${id}_archive_pool_reload_script_command_input`}
                  value={subpoolTab.reloadScriptCommand.input.value}
                  ref={this.reloadScriptInput}
                  maxLength={subpoolTab.reloadScriptCommand.dropdown === 2 ? 256 : 239}
                  onInputChanged={value => this.handleChangeReloadScriptCommandInput(value)}
                  disabled={!subpoolTab.createInitialSubpool}
                  title={''}
                  error={subpoolTab.reloadScriptCommand.input.errorkey && translate(subpoolTab.reloadScriptCommand.input.errorkey)}
                  onBlur={() => this.setState(state => ({ subpoolTab: { ...state.subpoolTab, ...this.validateReloadScriptCommand() } }))}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Dropdown
                  id={`${id}_archive_pool_delete_script_command_dropdown`}
                  title={translate('definition.archive_pool_delete_script_command')}
                  items={this.getScriptItems()}
                  activeIndex={subpoolTab.deleteScriptCommand.dropdown}
                  onChange={value => this.handleChangeDeleteScriptCommandDropdown(value)}
                  disabled={!subpoolTab.createInitialSubpool}
                />
              </Column>
              <Column colMD={9}>
                <Input
                  id={`${id}_archive_pool_delete_script_command_input`}
                  value={subpoolTab.deleteScriptCommand.input.value}
                  ref={this.deleteScriptInput}
                  maxLength={subpoolTab.deleteScriptCommand.dropdown === 2 ? 256 : 239}
                  onInputChanged={value => this.handleChangeDeleteScriptCommandInput(value)}
                  disabled={!subpoolTab.createInitialSubpool}
                  title={''}
                  error={subpoolTab.deleteScriptCommand.input.errorkey && translate(subpoolTab.deleteScriptCommand.input.errorkey)}
                  onBlur={() => this.setState(state => ({ subpoolTab: { ...state.subpoolTab, ...this.validateDeleteScriptCommand() } }))}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
          </>
        }
      </>
    )
  }

  handleErrorTabs = () => {
    const {
      generalTab: { archivePoolID },
      subpoolTab: {
        fileNameMask,
        reloadScriptCommand,
        deleteScriptCommand
      }
    } = this.state
    const buffer = []
    const fileNameError = fileNameMask.errorkey !== '' && fileNameMask.errorkey !== undefined
    const reloadError = reloadScriptCommand.input.errorkey !== '' && reloadScriptCommand.input.errorkey !== undefined
    const deleteError = deleteScriptCommand.input.errorkey !== '' && deleteScriptCommand.input.errorkey !== undefined
    if (archivePoolID.errorkey !== '' && archivePoolID.errorkey !== undefined) {
      buffer.push(0)
    }
    if (fileNameError || reloadError || deleteError) {
      buffer.push(1)
    }
    return buffer
  }

  /**
   * @description Handles the save button
   */
  handleSave = () => {
    const { generalTab, subpoolTab } = this.state
    const { createArchivePool, createArchiveSubpool, onClose } = this.props
    const errorTabs = [
      this.validateGeneralTab(),
      this.validateSubpoolTab()
    ]
    if (errorTabs.every(d => d)) {
      const archivePoolObj = {
        ARCPOOL: generalTab.archivePoolID.value,
        OWNER: generalTab.owner,
        ADPRETPD: generalTab.retentionPeriod,
        ADPMED: DefinitionUtils.ARCHIVEPOOL_MEDIA.filter((_, i) => i !== 0)[generalTab.media].key,
        ADPTITLE: generalTab.title,
        ADPORDER: generalTab.orderForReload === 1 ? generalTab.reloadOrderValue : 32767
      }

      const archiveSubpoolObj = {
        ARCPOOL: generalTab.archivePoolID.value,
        OWNER: generalTab.owner,
        ADSORDER: subpoolTab.orderForReload,
        ADSMED: DefinitionUtils.ARCHIVEPOOL_MEDIA.filter((_, i) => i !== 0)[generalTab.media].key,
        ADSRETPD: generalTab.retentionPeriod,
        ADSRETP2: subpoolTab.retentionPeriod,
        ADSDSIZE: subpoolTab.fileSize,
        ADSFMSK: subpoolTab.fileNameMask.value,
        ADSAUTOC: subpoolTab.fileManagedBy === 0,
      }

      if (subpoolTab.fileManagedBy === 1) {
        archiveSubpoolObj.ADSRESTC = subpoolTab.reloadScriptCommand.dropdown === 2
          ? subpoolTab.reloadScriptCommand.input.value
          : `${this.getScriptItems()[subpoolTab.reloadScriptCommand.dropdown]} ${subpoolTab.reloadScriptCommand.input.value}`
        archiveSubpoolObj.ADSDELEC = subpoolTab.deleteScriptCommand.dropdown === 2
          ? subpoolTab.deleteScriptCommand.input.value
          : `${this.getScriptItems()[subpoolTab.deleteScriptCommand.dropdown]} ${subpoolTab.deleteScriptCommand.input.value}`
        archiveSubpoolObj.ADSRETP2 = subpoolTab.retentionPeriod2
        archiveSubpoolObj.ADSAPI = subpoolTab.fileManagedBy === 1 && subpoolTab.useStorageApi === 0 // BMS/Storage-API + Use stroage-API "Yes"
      }

      createArchivePool(archivePoolObj, () => {
        if (subpoolTab.createInitialSubpool) {
          createArchiveSubpool(archiveSubpoolObj, () => onClose())
        }
        else {
          onClose()
        }
      })
    }
  }

  render = () => {
    const { id, lang, onClose } = this.props
    return (
      <>
        <Modal
          id='create_archive_pool_dialog'
          className='bux_UserProfileModal'
          onClose={onClose}>
          <Header
            id={`${id}_modalHeader`}
            title={translate('definition.create_archive_pool_title')}
            onClose={onClose}
          />
          <Main id={id}>
            <Tabs
              id={id}
              lang={lang}
              errorTabs={this.handleErrorTabs()}>
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab title={translate('general.subpool')}>
                {this.renderSubpoolTab()}
              </Tab>
            </Tabs>
          </Main>
          <Footer>
            {this.props.prefilledData && !hasNoValues(this.props.prefilledData) &&
              <Button
                id={`${id}_resetbtn`}
                tooltip={translate('general.reset')}
                icon={'undo'}
                onClick={this.resetState}
              />
            }
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createArchivePool: (archivePool, callback) => {
      ArchivePoolDefinitionActions.createArchivePool(archivePool, callback)(dispatch)
    },
    createArchiveSubpool: (archiveSubpool, callback) => {
      ArchivePoolDefinitionActions.createArchiveSubpool(archiveSubpool, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateArchivePoolDialog)