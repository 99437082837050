export const ARCHIVEFILE_LEVEL3ARCHIVE_ANY = ''
export const ARCHIVEFILE_LEVEL3ARCHIVE_PENDING = 'PENDING'
export const ARCHIVEFILE_LEVEL3ARCHIVE_YES = 'YES'
export const ARCHIVEFILE_LEVEL3ARCHIVE_NO = 'NO'

export const ARCHIVEFILE_LEVEL3ARCHIVE = [
  { key: ARCHIVEFILE_LEVEL3ARCHIVE_ANY, translationKey: 'general.any' },
  { key: ARCHIVEFILE_LEVEL3ARCHIVE_PENDING, translationKey: 'general.pending' },
  { key: ARCHIVEFILE_LEVEL3ARCHIVE_YES, translationKey: 'general.yes' },
  { key: ARCHIVEFILE_LEVEL3ARCHIVE_NO, translationKey: 'general.no' }
]

export const SELECT_BY_DETAIL = 'DET'
export const SELECT_BY_SUM = 'SUM'
export const SELECT_BY_TOTAL = 'TOT'

export const SELECT_BY = [
  { key: SELECT_BY_DETAIL, translationKey: 'general.detail' },
  { key: SELECT_BY_SUM, translationKey: 'general.sum' },
  { key: SELECT_BY_TOTAL, translationKey: 'general.total' },
]

export const STATUS_ANY = 'ALL'
export const STATUS_ACTIVE = 'RUN'
export const STATUS_WAIT = 'WAIT'
export const STATUS_ERROR = 'ERROR'

export const STATUS = [
  { key: STATUS_ANY, translationKey: 'general.any' },
  { key: STATUS_ACTIVE, translationKey: 'general.active' },
  { key: STATUS_WAIT, translationKey: 'general.waiting' },
  { key: STATUS_ERROR, translationKey: 'general.error' },
]