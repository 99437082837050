import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { BrowserTooltip, LoadingSpinner } from 'BetaUX2Web-Components/src';

import { DocumentBrowserRulerArea } from './DocumentBrowserRulerArea';
import { DocumentBrowserTextSegment } from './DocumentBrowserTextSegment';

import { useTabContentContext } from '../../contexts/TabContentContext';

import { CSS_CLASS_BROWSER_CONTENT } from '../../constants/attributes';

import { parsePageContent } from '../../helper/utils';

import { translate } from 'language/Language'

import * as TYPES_DOC_BROWSER_PAGE_AREA from '../../types/DocumentBrowserPageArea.types';
import * as TYPES_DOC_VIEWER from '../../types/DocumentViewer.types';

const NOT_FOUND = -1;

export const DocumentBrowserPageAreaText = () => {
  const { id, pageNumber } = useTabContentContext() as TYPES_DOC_VIEWER.ITabContentContext;
  const tab: TYPES_DOC_VIEWER.ITabData = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id));

  const { maxBlockLength, content }: { maxBlockLength: number, content: TYPES_DOC_VIEWER.TPageContent[] } = parsePageContent(tab);

  const [selectionRanges, setSelectionRanges] = useState<Map<number, TYPES_DOC_BROWSER_PAGE_AREA.ISelectionRange> | null>(null);
  const [selectionPerLines, setSelectionPerLines] = useState<Map<number, TYPES_DOC_BROWSER_PAGE_AREA.ISelectionPerLine> | null>(null);
  const [tooltip, setTooltip] = useState<(TYPES_DOC_BROWSER_PAGE_AREA.ISelectionRange & { posX: number; posY: number }) | undefined>(undefined);

  const CHAR_TESTER_ID = useMemo(() => `doc_char_tester_${id}`, [id]);
  const linesWithSelection = useMemo(() => Array.from(selectionPerLines?.keys() || []), [selectionPerLines]);

  const getCharWidth = useCallback((): number => document.querySelector(`#${CHAR_TESTER_ID}`)?.getBoundingClientRect().width || -1, [CHAR_TESTER_ID]);

  useEffect(() => {
    document.addEventListener('selectionchange', calculateSelections);
    return () => {
      document.removeEventListener('selectionchange', calculateSelections)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, id, pageNumber]);

  useEffect(() => {
    selectionRanges && updateRangeInTooltip(selectionRanges)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionRanges]);

  const calculateSelections = () => {
    const selection = document.getSelection();
    if (selection?.type === 'Range') {
      calculateSelectionByRanges(selection);
    }
    else {
      setSelectionPerLines(null);
      setSelectionRanges(null);
      setTooltip(undefined);
    }
  };

  const calculateSelectionByRanges = (selection: Selection) => {
    const rangeNumber = selection.rangeCount;
    const newSelectionRanges: Map<number, TYPES_DOC_BROWSER_PAGE_AREA.ISelectionRange> = new Map<number, TYPES_DOC_BROWSER_PAGE_AREA.ISelectionRange>();
    const newSelectionPerLine: Map<number, TYPES_DOC_BROWSER_PAGE_AREA.ISelectionPerLine> = new Map<number, TYPES_DOC_BROWSER_PAGE_AREA.ISelectionPerLine>();

    for (let i = 0; i < rangeNumber; i++) {
      const range = selection.getRangeAt(i);
      if (!isDocumentBrowserContent(range.startContainer.parentElement) ||
        !isDocumentBrowserContent(range.endContainer.parentElement)) {
        continue;
      }
      const startLine = getLine(range.startContainer);
      const endLine = getLine(range.endContainer);

      if (startLine === NOT_FOUND && endLine === NOT_FOUND) continue;

      // calculate selection in one line
      if (startLine === endLine) {
        newSelectionRanges.set(i, {
          id: i,
          start: [startLine, range.startOffset],
          end: [endLine, range.endOffset],
          length: range.endOffset - range.startOffset
        })
        newSelectionPerLine.set(startLine, {
          id: i,
          line: startLine,
          start: range.startOffset,
          end: range.endOffset,

        })
      }
      // calculate selection in multiline
      else {
        const length = content[startLine].length - range.startOffset + getLengthBetweenLines(startLine + 1, endLine) + range.endOffset;
        newSelectionRanges.set(i, { id: i, start: [startLine, range.startOffset], end: [endLine, range.endOffset], length: length });

        newSelectionPerLine.set(startLine, { id: i, line: startLine, start: range.startOffset, end: content[startLine].length })
        if (endLine - startLine > 1) {
          for (let line = startLine + 1; line < endLine; line++) {
            newSelectionPerLine.set(line, { id: i, line: line, start: 0, end: content[line].length });
          }
        }
        newSelectionPerLine.set(endLine, { id: i, line: endLine, start: 0, end: range.endOffset });

      }
    }
    setSelectionPerLines(newSelectionPerLine.size ? newSelectionPerLine : null);
    setSelectionRanges(newSelectionRanges.size ? newSelectionRanges : null);
  };

  const onMouseMove = (event: React.MouseEvent<HTMLElement>, line: number) => {
    const clientX = event.clientX;
    const clientY = event.clientY;
    const beginOfElement = (event.currentTarget)?.getBoundingClientRect().x;
    const charWidth = getCharWidth();
    const charNumber = Math.ceil((clientX - beginOfElement) / charWidth);
    const selectionPerLine = selectionPerLines?.get(line);
    if (selectionPerLine) {
      if (charNumber <= selectionPerLine.end && charNumber >= selectionPerLine.start) {
        const range = selectionRanges?.get(selectionPerLine.id);
        range && setTooltip({ ...countFrom1Not0(range) as TYPES_DOC_BROWSER_PAGE_AREA.ISelectionRange, posX: clientX, posY: clientY });
        return;
      }
    }
    setTooltip(undefined);
  };

  const updateRangeInTooltip = (selectionRanges?: Map<number, TYPES_DOC_BROWSER_PAGE_AREA.ISelectionRange>) => {
    if (tooltip && selectionRanges) {
      const range = selectionRanges.get(tooltip.id);
      range && setTooltip({ ...countFrom1Not0(range) as TYPES_DOC_BROWSER_PAGE_AREA.ISelectionRange, posX: tooltip?.posX, posY: tooltip?.posY });
    }
  };

  const isDocumentBrowserContent = (element: HTMLElement | null) => {
    return element?.className.includes(CSS_CLASS_BROWSER_CONTENT) || false;
  }

  const getLengthBetweenLines = (start: number, end: number): number => {
    let length = 0;
    for (let i = start; i < end; i++) {
      length += content[i].length;
    }
    return length;
  }
  const getLine = (rangeContainer: Node) => {
    const elementId = rangeContainer.parentElement?.id;
    const lineStr = elementId?.substring(elementId?.indexOf('_') + 1);
    const line = (lineStr) ? +lineStr : NOT_FOUND;
    return line;
  };

  if (tab.isFetching) {
    return (
      <div className='doc_browser_area_interaction_spinner'>
        <LoadingSpinner showLoader={tab.isFetching} isFixed={false} bgArea={false} />
      </div>
    )
  }

  const countFrom1Not0 = (selectionRange: TYPES_DOC_BROWSER_PAGE_AREA.ISelectionRange) => {
    const newSelectionRange = { ...selectionRange };
    newSelectionRange.start = newSelectionRange.start.map(i => i + 1);
    newSelectionRange.end = newSelectionRange.end.map(i => i + 1);
    return newSelectionRange;
  };

  return (
    <>
      {content.length !== 0 ? (
        <div className='doc_browser_area_interaction'>
          {tooltip && <BrowserTooltip top={tooltip.posY} left={tooltip.posX}><span>{translate('documentviewer.selection_tooltip.start_row_column')} {tooltip.start.join('/')}</span>
            <span>{translate('documentviewer.selection_tooltip.end_row_column')} {tooltip.end.join('/')}</span>
            <span>{translate('documentviewer.selection_tooltip.length')} {tooltip?.length}</span>
          </BrowserTooltip>
          }
          <DocumentBrowserRulerArea
            id={id}
            maxBlockLength={maxBlockLength}
            pageNumber={pageNumber}
            totalLineCount={content.length}
          >
            <>
              {content.map((line: string, lineIndex: number) => (
                <div
                  id={`${pageNumber}_${lineIndex}`}
                  className={`${CSS_CLASS_BROWSER_CONTENT}_line`}
                  key={`line_${lineIndex}`}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  onMouseMove={e => linesWithSelection?.includes(lineIndex) ? onMouseMove(e, lineIndex) : undefined}
                  onMouseOut={() => linesWithSelection?.includes(lineIndex) ? setTooltip(undefined) : undefined}
                >
                  <DocumentBrowserTextSegment
                    indexToHighlight={tab.indexToHighlight}
                    line={line}
                    lineIndex={lineIndex}
                    pageNumber={pageNumber}
                  />
                </div>
              ))}
            </>
          </DocumentBrowserRulerArea>
        </div>
      ) : (
        <div className='doc_browser_area_interaction'>
          {translate('documentviewer.no_data')}
        </div>
      )}
    </>
  )
};