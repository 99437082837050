import React, { Component } from 'react'
import PropTypes from 'prop-types'

import * as Preferences from 'redux/general/Preferences'
import { translate } from 'language/Language'

import { connect } from 'react-redux'

import './DatabaseStatus.scss'

class DatabaseStatus extends Component {

  /**
   * @description Renders the main components.
   */
  renderMain = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_main`}
        className='bux_drawer_main bux_database_status_container'>
        <p className='bux_database_status_description'>
          {translate('general.no_options_available', lang)}
        </p>
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'>
        {/* main */}
        {this.renderMain()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}


DatabaseStatus.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(DatabaseStatus)