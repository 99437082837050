import React from 'react';
import { TitledLabel } from '../titled_label/TitledLabel';

import './MetaDataGrid.scss';

export interface MetaDataObj {
  label: string;
  value: string | number | boolean;
  yesNo?: boolean;
}

export interface MetaDataGridProps {
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** Array of ordered metadata objects to display.*/
  metaData: MetaDataObj[]
  /** Number columns to display. From 1 to 12. Default: 3  */
  columns?: number;
  /**
   * Translate function key => key
   * you will need to translate following keys by our own:
   *
   * - `general.yes` => displayed when metadata has yesNo property set to true and value is undefined or 'N'
   * - `general.no` => displayed when metadata has yesNo property set to true and value is 'Y' or any
   *
   * @param {string} key
   */
  translate?: (key: string) => string
}

const MAX_COLUMN = 12;
const MIN_COLUMN = 1;
const EMPTY_VALUE = '-';

const getNumberOfColumn = (columns: number): number => {
  let colNum = columns;
  if (colNum > MAX_COLUMN) colNum = MAX_COLUMN;
  if (colNum < MIN_COLUMN) colNum = MIN_COLUMN;
  return colNum;
};

export const MetaDataGrid = ({ id, metaData, columns: columnNumber = 3, translate }: MetaDataGridProps): JSX.Element => {
  const columns = getNumberOfColumn(columnNumber);
  const metaDataCount = metaData.length;
  const rows = Math.ceil(metaDataCount / columns);
  const lastItemColumn = (notEmptyCounter: number): number => Math.abs(notEmptyCounter - ((rows - 1) * columns));
  let notEmptyCounter = 0;

  const prepareValue = (valueToPrepare: string | number | boolean | undefined, yesNo?: boolean): string => {
    let value = valueToPrepare;
    if (value === undefined) value = '';
    else {
      if (typeof value === 'string') {
        if (yesNo && translate) {
          value = (!value || value.toLowerCase() === 'n' || value.toLowerCase() === 'no' || value.toLowerCase() === 'false'
            ? translate('general.no')
            : translate('general.yes'));
        }
        if (value === '') value = EMPTY_VALUE;
      }
      if (typeof value === 'number' || typeof value === 'boolean') {
        if (yesNo && translate) {
          value = (value ? translate('general.yes') : translate('general.no'));
        }
        else if (yesNo) value = (!!value).toString();
        else value = value.toString();
      }
    }

    return value;
  };


  return <div
    className={'cl_metaDataHeader'}
    style={{ gridTemplateColumns: `repeat(${columns}, 1fr)`, gridTemplateRows: `repeat(${rows}, 1fr)` }}>
    {metaData.map((md, i) => {
      if (!md) return null;

      const value = prepareValue(md.value, md.yesNo);

      notEmptyCounter++;

      return <TitledLabel
        id={`${id}_${md.label}`}
        key={`${id}_${md.label}`}
        title={md.label}
        text={value}
        style={(i === metaDataCount - 1) ? { gridColumnStart: `${lastItemColumn(notEmptyCounter)}`, gridColumnEnd: `${columns}` } : {}}
      />;
    }
    )}
  </div>;

};