/**
 * @description Checks if the passed param is a string.
 * @param {*} s The passed param which should be checked.
 * @returns {Boolean} True if the passed param is a string.
 */
export function isString(s) {
  return typeof (s) === 'string' || s instanceof String
}

/**
 * @description Checks if the the passed array exists in the passed arrays param.
 * @param {Array} arrays An array of arrays.
 * @param {Array} array The array which should be checked if it exists in the arrays param.
 * @returns {Boolean} True if the passed array exists in the passed arrays.
 */
export function includesArray(arrays, array) {
  for (let i = 0; i < arrays.length; i++) {
    const element = arrays[i]

    if (equalsArrays(element, array, true)) {
      return true
    }
  }

  return false
}

/**
 * @description Checks if the arrays are equal.
 * @param {Array} array1 The first array to check.
 * @param {Array} array2 The second array to check.
 * @param {Boolean} strict Flag for strict check.
 * @returns {Boolean} True if the arrays are equal.
 */
export function equalsArrays(array1, array2, strict) {
  if (!array1 || !array2)
    return false

  if (arguments.length === 2)
    strict = true

  if (array1.length !== array2.length)
    return false

  for (var i = 0; i < array1.length; i++) {
    if (array1[i] instanceof Array && array2[i] instanceof Array) {
      if (!equalsArrays(array1[i], array2[i], strict))
        return false
    }
    else if (strict && array1[i] !== array2[i]) {
      return false
    }
    else if (!strict) {
      return array1.sort().equals(array2.sort(), true)
    }
  }
  return true
}

/**
 * @description Parses a string value into an int. If the int is not a number after teh conversion, return the string as fallback.
 * @param {String} value The value to convert.
 * @returns {*} The converted int or the passed param if the conversion failed or the param if it's not a string.
 */
export function convertStringToInt(value) {
  if (!isString(value)) {
    return value
  }

  if (value.length > 0) {
    // the + before the value is important because we want to force NaN if includes others chars than digits
    const parsed = parseInt(+value)
    if (!isNaN(parsed)) {
      value = parsed
    }
  }

  return value
}

/**
 * @description Replaces all matching strings inside the whole string with the replacement.
 * @param {String} string The whole string.
 * @param {String} search The search string to replace.
 * @param {String} replacement The replacement.
 * @returns {String} The new replaced string.
 */
export function replaceAll(string, search, replacement) {
  return string.replace(new RegExp(search, 'g'), replacement)
}

/**
 * @description focuses the next input which has an error
 * @param {Array of Objects} requiredInputs
 */
export const setFocus = requiredInputs => {
  for (let i = 0; i < requiredInputs.length; i++) {
    if (requiredInputs[i].error !== '') {
      if (requiredInputs[i].inputRef.current) {
        requiredInputs[i].inputRef.current.focus()
        break
      }
    }
  }
}

/**
 * @description Format the number for the specific language with additional decimal places
 * @param {number} number Number to format
 * @param {string} language - Language to format for. One of [de|en|fr]
 * @param {string} decimalPlaces - How many decimales placed should be attached atleast. e.g.
 *                            decimalPlaces 2:  2.4 becomes 2.40
 *                            decimalPlaces 0:  2.4 keep    2.4
 */
export const formatNumeric = (number, language, decimalPlaces = 0) => {
  number = parseFloat(number)
  if (isNaN(number)) {
    throw Error(`Format numeric expectes numeric values instead got ${typeof (number)} (${number})`)
  }

  const EN = 'en'
  const DE = 'de'
  const FR = 'fr'
  if (![EN, DE, FR].includes(language)) {
    throw Error(`Function formatNumeric invalid parameter for language: ${language}`)
  }

  const decs = number.countDecimals()
  // Create copy to avoid ref-manipulation. Not sure if needed
  let el = number.toString()
  let begin
  let end
  if (decs > 0) {
    begin = el.substring(0, el.length - decs - 1)
    end = el.substring(el.length - decs).padEnd(decimalPlaces, '0')
  } else {
    begin = el
    end = '00'
  }

  const regex = /\B(?=(\d{3})+(?!\d))/g
  if (language === EN) {
    begin = begin.replace(regex, ',') // e.g. 123456.00 => 123,456.00
    if (end !== '') { end = `.${end}` }
  }
  else if (language === DE) {
    begin = begin.replace(regex, '.') // e.g. 123456.00 => 123.456,00
    if (end !== '') { end = `,${end}` }
  }
  else if (language === FR) {
    begin = begin.replace(regex, ' ') // e.g. 123456.00 => 123 456,00
    if (end !== '') { end = `,${end}` }
  }
  return `${begin}${end}`
}

/**
 * @description Format the number for the specific language without decimals
 * @param {number} number Number to format
 * @param {string} language - Language to format for. One of [de|en|fr]
 */
export const formatInt = (number, language) => {
  number = parseInt(number)
  if (isNaN(number)) {
    throw Error(`Format numeric expectes numeric values instead got ${typeof (number)} (${number})`)
  }

  const EN = 'en'
  const DE = 'de'
  const FR = 'fr'
  if (![EN, DE, FR].includes(language)) {
    throw Error(`Function formatInt invalid parameter for language: ${language}`)
  }

  let sNumber = number.toString()
  if (language === EN) {
    sNumber = sNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // e.g. 123456 => 123,456
  }
  else if (language === DE) {
    sNumber = sNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.') // e.g. 123456 => 123.456
  }
  else if (language === FR) {
    sNumber = sNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // e.g. 123456 => 123 456
  }
  return sNumber
}


/**
 * @description Returns a different class, which depends on the current used browser. This class is used for the table container inside a modal to make the table scrollable and    *              not the modal itself.
 */
export const getSpecificContainerStyle = () => {
  return !!window.chrome ? 'bux_chrome_container_style' : 'bux_not_chrome_container_style'
}

/**
 * Convert header array and data array or arrays into one object or array of objects
 * @param {any[]} header
 * @param {any[] or any[][]} data
 * @returns any or any[]
 */
export const convertIntoObject = (header, data) => {
  if (!data) return null;
  if (Array.isArray(data) && Array.isArray(data[0])) {
    return arraysIntoArrayOfObject(header, data)
  }
  else {
    return arrayIntoObject(header, data)
  }
}

const arrayIntoObject = (header, data) => {
  let mapped = {};
  header.map((h, index) => mapped[h] = data[index])
  return mapped;
}

const arraysIntoArrayOfObject = (header, data) => {
  let mapped = [];
  data.map((row) => mapped.push(arrayIntoObject(header, row)))
  return mapped;
}

/**
 * If provided string is empty or undefined will be replaced by '-' sign.
 * The function should be used in modal's headers.
 * @param {string} str
 * @returns {string} the same value or '-'
 */
export const headerValue = (str) => {
  return str !== '' && str !== undefined ? str : '-'
}