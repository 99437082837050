import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { LoadingSpinner } from 'BetaUX2Web-Components/src';
import { HexSegmentBlock } from './DocumentBrowserHexSegments';
import { DocumentBrowserRulerArea } from './DocumentBrowserRulerArea';

import * as ATTRIBUTES from '../../constants/attributes';

import { useTabContentContext } from '../../contexts/TabContentContext';

import * as DOC_VIEWER_UTILS from '../../helper/utils';

import { translate } from 'language/Language';

import * as TYPES_DOC_BROWSER_HEX_AREA from '../../types/DocumentBrowserHexArea.types';
import * as TYPES_DOC_VIEWER from '../../types/DocumentViewer.types';

export const DocumentBrowserPageAreaHexBlock = () => {
  const NUM_OFFSET_DELIMITER = 5; // Helper offset to align the horizontal ruler with the perceived content length due to asterisk separators

  const { cacheKey, id, pageNumber } = useTabContentContext() as TYPES_DOC_VIEWER.ITabContentContext;

  const isFetching: TYPES_DOC_VIEWER.ITabData['isFetching'] = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id).isFetching);
  const hexBlockData: TYPES_DOC_VIEWER.TPageContent = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id).pagesHexBlock[cacheKey]);
  const { maxBlockLength, content } = DOC_VIEWER_UTILS.parseHexBlockData(hexBlockData);

  if (isFetching) {
    return (
      <div className='doc_browser_area_interaction_spinner'>
        <LoadingSpinner showLoader={isFetching} isFixed={false} bgArea={false} />
      </div>
    )
  }

  /* //! WIP: Separate styles into CSS */
  return (
    <div id={`${ATTRIBUTES.ID_HEX_CONTAINER}_${cacheKey}`}>
      {content.length !== 0 ? (
        <div className='doc_browser_area_interaction'>
          <DocumentBrowserRulerArea
            id={id}
            maxBlockLength={maxBlockLength + NUM_OFFSET_DELIMITER}
            pageNumber={pageNumber}
            totalLineCount={content.length}
            isHexView
          >
            <>
              {content.map((line: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexBlockDataContent, lineIndex: number) => (
                <HexSegmentBlock
                  key={`${id}_${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${lineIndex}`}
                  line={line}
                  lineIndex={lineIndex}
                  maxBlockLength={maxBlockLength + NUM_OFFSET_DELIMITER}
                />
              ))}
            </>
          </DocumentBrowserRulerArea>
        </div>
      ) : (
        <div className='doc_browser_area_interaction'>
          {translate('documentviewer.no_data')}
        </div>
      )}
    </div>
  )
};