import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as SortUtils from 'utils/SortUtils'

import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CopyNodeDocumentAssignmentDialog from 'components/dialogs/copy_node_document_assignment_dialog/CopyNodeDocumentAssignmentDialog'
import CreateNodeDocumentAssignmentDialog from 'components/dialogs/create_node_document_assignment_dialog/CreateNodeDocumentAssignmentDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyNodeDocumentAssignmentDialog from 'components/dialogs/modify_node_document_assignment_dialog/ModifyNodeDocumentAssignmentDialog'
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as NodeDocumentActions from 'redux/actions/NodeDocumentAssignmentActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultNodeDocumentAssignment extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired
  }

  state = {
    header: this.fillHeaderInformation(),
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    showTableSettingsDialog: false
  }

  /**
   * @description Builds the header array with rest value and translation key.
   * @returns {Array} The header array.
   */
  fillHeaderInformation() {
    return [
      { rest: NODEID, translation: 'definition.node_id', default: true },
      { rest: IDENTIFIER, translation: 'general.identifier', default: true },
      { rest: FORM, translation: 'general.form', default: true },
      { rest: EXT, translation: 'general.extension', default: true },
      { rest: DESC, translation: 'general.description', default: true },
      { rest: OWNER, translation: 'general.owner' },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data array.
   * @param {Array} header The header array.
   * @returns {Array} The sort defs for each row.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Handles the modal dialogs for modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { getNodeDocumentAssignment, nodeDocuments } = this.props

    const nodeAssignment = {
      DNDNAME: nodeDocuments.data[index][this.headerData('DNDNAME')],
      FORM: nodeDocuments.data[index][this.headerData('FORM')],
      EXT: nodeDocuments.data[index][this.headerData('EXT')],
      DGIENAME: nodeDocuments.data[index][this.headerData('DGIENAME')],
      DGIDESC: nodeDocuments.data[index][this.headerData('DGIDESC')],
      OWNER: nodeDocuments.data[index][this.headerData('OWNER')],
      CUSER: nodeDocuments.data[index][this.headerData('CUSER')],
      CDATE: nodeDocuments.data[index][this.headerData('CDATE')],
      CTIME: nodeDocuments.data[index][this.headerData('CTIME')],
    }

    const callback = () => this.setState({ [dialog]: true })
    getNodeDocumentAssignment(nodeAssignment, callback)
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => { this.handleDialog(rowIndex, 'showModifyDialog') }}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => { this.handleDialog(rowIndex, 'showDeleteDialog') }}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => { this.handleDialog(rowIndex, 'showModifyDialog') }}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        <TableButtonGroupItem
          onClick={() => { this.handleDialog(rowIndex, 'showDeleteDialog') }}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.setState({ showCreateDialog: true })}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.props.refreshSearch}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Gets the index of the header.
   * @param {String} header header name of the header.
   * @returns {Number}The index of the header.
   */
  headerData = header => this.props.nodeDocuments.header.indexOf(header)

  /**
   * @description Builds the data.
   * @returns {Array} The data.
   */
  getData = () => {
    const { nodeDocuments, datemask } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    nodeDocuments.data.forEach(el => {
      let dataBuffer = []
      usedHeader.forEach(h => {
        if (h === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { prefs } = this.props
    const { header } = this.state
    if (prefs[Preferences.TABLE_SETTINGS_NODE_DOCUMENT_ASSIGNMENT]) {
      let buffer = []
      prefs[Preferences.TABLE_SETTINGS_NODE_DOCUMENT_ASSIGNMENT].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    const { prefs } = this.props
    if (prefs[Preferences.TABLE_SETTINGS_NODE_DOCUMENT_ASSIGNMENT]) {
      return prefs[Preferences.TABLE_SETTINGS_NODE_DOCUMENT_ASSIGNMENT].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  /**
   * @description Deletes the current node document assignment.
   */
  deleteAssignment = () => {
    const { nodeDocument, deleteNodeDocumentAssignment } = this.props

    const assignment = {
      DNDNAME: nodeDocument['DNDNAME'],
      FORM: nodeDocument['FORM'],
      EXT: nodeDocument['EXT'],
    }
    // declare success callback
    const callback = () => { this.setState({ showDeleteDialog: false }) }
    // deletes the specific node document assignment
    deleteNodeDocumentAssignment(assignment, callback)
  }

  /**
   * @description Gets the modal title for delete dialog.
   * @returns {Array} The modal title as an array.
   */
  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.delete')} ${translate('general.node_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'node_document_relation_modal_title'} name={'relation'} tabIndex={-1}/>,
      <div key={'title_3'}>{`${translate('general.document_modal_title')}  ${translate('general.assignment_modal_title')}`}</div>,
    ]
  }

  /**
   * @description Gets the modal question for delete dialog.
   * @returns {Array} The modal question as an array.
   */
  getModalQuestion = () => {
    return [
      <div key={'title_1'}>{translate('assignment.node_document_delete_question_1')}</div>,
      <Icon key={'title_2'} id={'node_document_relation_modal_question'} name={'relation'} />,
      <div key={'title_3'}>{translate('assignment.node_document_delete_question_2')}</div>
    ]
  }

  getNoResultTitle = () => {
    return [
      `${translate('assignment.no_alt')} `,
      `${translate('general.node_modal_title')}`,
      <Icon id={'recipient_document_relation_modal_title'} name={'relation'} />,
      `${translate('general.document_modal_title')} `,
      `${translate('general.assignment_modal_title')} `,
      ` ${translate('assignment.no_match_single')}`
    ]
  }

  getNoResultLink = () => {
    return [
      `${translate('assignment.you_can')} `,
      `${translate('general.node_modal_title')}`,
      <Icon id={'recipient_document_relation_modal_title'} name={'relation'} />,
      `${translate('general.document_modal_title')} `,
      `${translate('general.assignment_modal_title')} `,
      ` ${translate('assignment.create')}`
    ]
  }

  render = () => {
    const { id, drawerExpanded, autoDrawer, nodeDocuments, nodeDocument, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showDeleteDialog, showCreateDialog, showCopyDialog, showModifyDialog } = this.state
    const data = nodeDocuments && nodeDocuments.data ? this.getData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_deletenodedocumentassignmentdialog`}
            title={this.getModalTitle()}
            question={this.getModalQuestion()}
            onClose={() => { this.setState({ showDeleteDialog: false }) }}
            onDelete={() => { this.deleteAssignment() }}
          >
            <Row>
              <Column
                colMD={3}>
                <p
                  id={`${id}_nodeid_text`}>
                  {translate('definition.node_id')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p
                  id={`${id}_nodeid`}>
                  {nodeDocument?.DNDNAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p
                  id={`${id}_form_text`}>
                  {translate('general.form')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p
                  id={`${id}_form`}>
                  {nodeDocument?.FORM}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p
                  id={`${id}_extension_text`}>
                  {translate('general.extension')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p
                  id={`${id}_extension`}>
                  {nodeDocument?.EXT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p
                  id={`${id}_identifier_text`}>
                  {translate('general.identifier')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p
                  id={`${id}_identifier`}>
                  {nodeDocument?.DGIENAME}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_NODE_DOCUMENT_ASSIGNMENT }}
          />
        )}
        {showCreateDialog && (
          <CreateNodeDocumentAssignmentDialog
            id={`${id}_createnodedocumentassignment`}
            onClose={() => this.setState({ showCreateDialog: false })}
          />
        )}
        {showCopyDialog && (
          <CopyNodeDocumentAssignmentDialog
            id={`${id}_copynodedocumentassignment`}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        )}
        {showModifyDialog && (
          <ModifyNodeDocumentAssignmentDialog
            id={`${id}_modifynodedocumentassignment`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {nodeDocuments
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={rowIndex => this.handleDialog(rowIndex, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={this.getNoResultTitle()}
                    link={this.getNoResultLink()}
                    onClick={() => this.setState({ showCreateDialog: true })}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const NODEID = 'DNDNAME'
const FORM = 'FORM'
const EXT = 'EXT'
const IDENTIFIER = 'DGIENAME'
const DESC = 'DGIDESC'
const OWNER = 'OWNER'
const LASTCHANGED = 'LASTCHANGED'
const CDATE = 'CDATE'
const CTIME = 'CTIME'

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    nodeDocuments: state.assignments.nodedocumentassignment.nodeDocumentAssignments,
    nodeDocument: state.assignments.nodedocumentassignment.nodeDocumentAssignment,
    keepPagination: state.assignments.recipientdocumentassignment.keepPagination,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) },
    refreshSearch: () => { NodeDocumentActions.refreshSearch()(dispatch) },
    getNodeDocumentAssignment: (nodeAssignment, callback) => {
      NodeDocumentActions.getNodeDocumentAssignment(nodeAssignment, callback)(dispatch)
    },
    deleteNodeDocumentAssignment: (nodeDocumentAssignment, callback) => {
      NodeDocumentActions.deleteNodeDocumentAssignment(nodeDocumentAssignment, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultNodeDocumentAssignment)