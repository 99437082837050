import React, { Component } from 'react'
import { connect } from 'react-redux'

import './LoginScreen.scss'

import { Button, Card, Dropdown, Footer, Form, Input, Link, LoadingSpinner, Main, Modal } from 'BetaUX2Web-Components/src'
import { TYPE_TEXT, TYPE_PASSWORD } from 'BetaUX2Web-Components/src/types'

import * as Configs from 'config/Configs'
import { translate } from 'language/Language'

import * as AuthActions from 'redux/actions/AuthenticationActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import { SwitchLanguageButton } from './SwitchLanguageButton';
import Logo from 'ressource/logo/logo-beta_admin-wht.svg';

const CURRENT_BACKEND_KEY = 'current_backend'

const ModalHeader = () => {
  return (
    <>
      {translate('loginscreen.please_enter_your_user_data')}
      <SwitchLanguageButton/>
    </>
  )
}

class LoginScreen extends Component {
  constructor(props) {
    super(props)
    // create a ref to store the textInput DOM element
    this.usernameInput = React.createRef()
    this.passwordInput = React.createRef()
    this.newPasswordInput = React.createRef()
    this.newPasswordConfirmInput = React.createRef()
  }

  state = {
    maskedPwd: true,
    renderNewPwd: undefined,

    systemnameIndex: 0,
    username: {
      value: this.props.lastsession && this.props.lastsession.userid ? this.props.lastsession.userid : '',
      errorkey: ''
    },
    password: {
      value: '',
      errorkey: ''
    },
    newPassword: {
      value: '',
      errorkey: ''
    },
    newPasswordConfirm: {
      value: '',
      errorkey: ''
    }
  }

  setSavedBackendIndex = () => {
    const currentBackend = localStorage.getItem(CURRENT_BACKEND_KEY)
    const currentBackendIndex = this.getAvailableBackends().indexOf(currentBackend)
    if (currentBackendIndex > -1) {
      this.setState({
        'systemnameIndex': currentBackendIndex
      })
    }
  }

  /**
   * @description Load the list of available backends for this UI instance
   */
  componentDidMount = () => {
    const {
      getBackends,
      getAPIVersion
    } = this.props
    getBackends()
    getAPIVersion()
    this.setSavedBackendIndex()
  }

  /**
   * @description Get all available backend-systems for the REST-API
   * @returns {Array} String array of available backend names to connect to
   */
  getAvailableBackends = () => {
    const {backends} = this.props

    /* No specific backends means the server itself is the only available backend. */
    if (!backends) {
      return []
    }

    /* Index of SYSTEM_NAME in REST-API-result */
    const systemnameIndex = backends.header.indexOf('SYSTEM_NAME')

    /* Return all names of available backends */
    return backends.data.map(backend => backend[systemnameIndex])
  }

  /**
   * @description Changes the value and error key for an input.
   * @param {String} stateKey The key of the input. Must be the same as in state.
   * @param {String} value The new string.
   * @param {String} error The new error key.
   */
  handleInput(stateKey, value, error) {
    this.setState({
      [stateKey]: {
        value: value,
        errorkey: error
      }
    })
  }

  handleDropdownChange = (newIndex) => {
    localStorage.setItem(CURRENT_BACKEND_KEY, this.getAvailableBackends()[newIndex])
    this.setState({
      'systemnameIndex': newIndex
    }, () => this.usernameInput.current && this.usernameInput.current.focus())
  }

  /**
   * @description Changes the value and error key for the new password input.
   * Also checks the equality of the newpasswordconfirm input and set the errorkey for this input.
   * @param {String} value The new value.
   * @param {String} error The new error key.
   */
  handleNewPwd(value, error) {
    // set new values for newpassword
    this.setState({
      newPassword: {
        value: value,
        errorkey: error
      }
    })

    // checking equality to confirm input
    const errorPwdConfirm = value !== this.state.newPasswordConfirm.value &&
    this.state.newPasswordConfirm.value.length !== 0 && value.length !== 0
      ? 'loginscreen.password_not_equal_to_new_password'
      : null

    // set new values of confirm input
    this.setState((currState) => ({
      newPasswordConfirm: {
        value: currState.newPasswordConfirm.value,
        errorkey: errorPwdConfirm
      }
    }))
  }

  /**
   * @description Changes the value and error key for newpasswordconfirm input.
   * @param {String} value The new value.
   * @param {String} error The new error key.
   */
  handleNewPwdConfirm(value, error) {
    // checking equality to newpassword
    const errorkey = this.state.newPassword.value !== value &&
    this.state.newPassword.value.length !== 0 && value.length !== 0
      ? 'loginscreen.password_not_equal_to_new_password'
      : error

    // set new values of confirm input
    this.setState({
      newPasswordConfirm: {
        value: value,
        errorkey: errorkey
      }
    })
  }

  /**
   * @description Changes the flag for mask pwd.
   * @param {Boolean} value The new value.
   */
  handleMaskPwd(value) {
    this.setState({
      maskedPwd: value
    })
  }

  /**
   * @description Changes the visibility of the newpwd section.
   */
  handleRenderNewPwd() {
    this.setState((currState) => ({
      renderNewPwd: !currState.renderNewPwd,
    }))
  }

  /**
   * @description Renders the new pwd section.
   */
  renderNewPwd() {
    const {
      renderNewPwd,
      maskedPwd,
      newPassword,
      newPasswordConfirm
    } = this.state
    return (
      renderNewPwd !== undefined &&
      <div id={renderNewPwd ? 'expand' : 'collapse'} className={'loginScreen_changePassword_container'}>
        {/* new password input */}
        <Input
          id="password"
          ref={this.passwordInput}
          title={translate('user.password')}
          value={this.state.password.value}
          error={this.state.password.errorkey ? translate(this.state.password.errorkey) : ''}
          charCount
          maxLength={32}
          type={this.state.maskedPwd ? TYPE_PASSWORD : TYPE_TEXT}
          addon={this.createInputAddon(this.state.maskedPwd)}
          onInputChanged={(val, err) => {
            this.handleInput('password', val, err)
          }}
          autocomplete={'password'}
        />

        <Input
          id="newpassword"
          ref={this.newPasswordInput}
          title={translate('loginscreen.new_password')}
          type={maskedPwd ? TYPE_PASSWORD : TYPE_TEXT}
          value={newPassword.value}
          error={translate(newPassword.errorkey) || ''}
          charCount
          maxLength={8}
          onInputChanged={(val, err) => {
            this.handleNewPwd(val, err)
          }}
        />
        {/* new password confirm input */}
        <Input
          id="newpassword_confirm"
          ref={this.newPasswordConfirmInput}
          title={translate('loginscreen.repeat_new_password')}
          type={maskedPwd ? TYPE_PASSWORD : TYPE_TEXT}
          value={newPasswordConfirm.value}
          error={translate(newPasswordConfirm.errorkey) || ''}
          charCount
          maxLength={8}
          onInputChanged={(val, err) => {
            this.handleNewPwdConfirm(val, err)
          }}
        />
      </div>
    )
  }

  /** This need to be a arrow-function. Because i pass the function as a callback
   to the Button-Component and otherwise 'this' would be the Button-Context */
  handleLogin = event => {
    event.preventDefault()

    const {login} = this.props
    const {
      renderNewPwd,
      username,
      password,
      newPassword,
      systemnameIndex
    } = this.state

    // Get selected systemname. Systemname selection if available is required, otherwise set undefined
    const systemname = this.getAvailableBackends() ? this.getAvailableBackends()[systemnameIndex] : undefined

    // check data before sending login request
    // login fields should not be empty
    if (this.validateUsername(true)) {
      if (this.validatePassword(true)) {
        // check if user want to change password and if the input of the change password fields are valid
        if (!this.validateNewPassword(true)) {
          return
        }
        // Call login request, if new-password inputs are visible, with new password
        // otherwise don't pass new password to function to avoid a unwanted pw-change
        if (renderNewPwd) {
          login(systemname, username.value, password.value, newPassword.value)
        } else {
          login(systemname, username.value, password.value, undefined, () => this.handleRenderNewPwd())
        }
      } else {
        // we also want to check and show the error message under change password fields in case of password is invalid
        // don't allow focus here because password field has an error -> false parameter
        this.validateNewPassword(false)
      }
    } else {
      // we also want to check and show the error message under the other fields in case of username is invalid
      // don't allow focus here because username field has an error -> false parameter
      // we don't have to interlace the validate function because we don't have to set the focus
      this.validatePassword(false)
      this.validateNewPassword(false)
    }
  }

  /**
   * @description Validates the input of username.
   * @param {Boolean} allowFocus Flag for allow to focus username input when there is an error.
   */
  validateUsername(allowFocus) {
    if (this.state.username.value !== '') {
      return true
    } else {
      this.setState({
        username: {
          ...this.state.username,
          errorkey: 'loginscreen.please_enter_your_username'
        }
      })

      // set the focus if it's allowed
      if (allowFocus)
        this.usernameInput.current.focus()

      return false
    }
  }

  /**
   * @description Validates the input of password.
   * @param {Boolean} allowFocus Flag for allow to focus password input when there is an error.
   */
  validatePassword(allowFocus) {
    if (this.state.password.value !== '') {
      return true
    } else {
      this.setState({
        password: {
          ...this.state.password,
          errorkey: 'loginscreen.please_enter_your_password'
        }
      })

      // set the focus if it's allowed
      if (allowFocus)
        this.passwordInput.current.focus()

      return false
    }
  }

  /**
   * @description Validates the newpassword and the newpasswordconfirm input.
   * @param {Boolean} allowFocus Flag for allow to focus newpassword / newpasswordconfirm input when there is an error.
   */
  validateNewPassword(allowFocus) {
    // check if the user want to change the password
    if (this.state.renderNewPwd) {
      // check if new password is valid
      if (this.state.newPassword.value !== '') {
        // check if newPassword and newPasswordConfirm are equal
        if (this.state.newPassword.value !== this.state.newPasswordConfirm.value) {
          this.setState({
            newPasswordConfirm: {
              ...this.state.newPasswordConfirm,
              errorkey: 'loginscreen.password_not_equal_to_new_password'
            }
          })
          // set the focus if it's allowed
          if (allowFocus)
            this.newPasswordConfirmInput.current.focus()
          return false
        } else {
          return true
        }
      } else {
        this.setState({
          newPassword: {
            ...this.state.newPassword,
            errorkey: 'loginscreen.please_enter_your_new_password'
          }
        })

        // set the focus if it's allowed
        if (allowFocus)
          this.newPasswordInput.current.focus()

        return false
      }
    }
    // user don't want to change password -> validation is true
    else {
      return true
    }
  }

  createInputAddon = (show) => ({
    iconName: show ? 'hide' : 'show',
    tooltip: show ? translate('loginscreen.hide_password') : translate('loginscreen.show_password'),
    onClick: () => this.handleMaskPwd(!show),
    isInner: true
  });

  render = () => {
    const {
      productname,
      buildhash,
      buildtimestamp,
      productversion,
      servicepack,
      patchlevel,
      releasetype
    } = Configs.CONFIGS

    const {showLoader} = this.props
    const {systemnameIndex} = this.state

    // If no backends are available, show a hardcoded values to indicate that.
    // This need be done in render, otherwise the value would be send to the server during the login
    let backends = this.getAvailableBackends()
    if (backends.length < 1) {
      backends = [translate('loginscreen.no_systemname_specified')]
    }

    // const inputAddon = ({
    //   iconName: 'edit',
    //   tooltip: translate('loginscreen.change_password'),
    //   onClick: () => this.handleRenderNewPwd(),
    //   isInner: false
    // });

    return (
      <>
        <LoadingSpinner showLoader={showLoader}/>

        <div id="LoginScreenBUX">
          {/* <Header id='loginHeader' /> */}
          <Main id="loginMain">
            <div className="loginScreenLogo">
              <img src={Logo} alt="beta reader logo"/>
            </div>
            <Modal.Modal
              id="LoginScreenBUXModal"
              size="xs"
              noBackdrop
              glassEffect
            >
              <Modal.Header
                id={'LoginScreenBUXModal_header'}
                className={'modalHeaderTitle'}
                title={<ModalHeader/>}
              />
              <Card id="loginCard" className={'bux_login_screen_container'} transparent>
                <Form
                  id={'loginCardForm'}
                  className={'bux_login_card_form'}
                  onSubmit={this.handleLogin}>
                  {/* username input */}
                  {
                    backends.length < 2
                      ? <Input
                        id="systemname"
                        title={translate('loginscreen.systemname')}
                        onInputChanged={() => { /* to prevent error in console */
                        }}
                        value={backends[0]}
                        disabled
                      />
                      : <Dropdown
                        id="systemname"
                        items={backends}
                        activeIndex={systemnameIndex}
                        onChange={activeIndex => this.handleDropdownChange(activeIndex)}
                        title={translate('loginscreen.systemname')}
                        disabled={backends.length < 2}
                      />
                  }

                  <Input
                    autoFocus={true}
                    id="user"
                    ref={this.usernameInput}
                    title={translate('user.user')}
                    value={this.state.username.value}
                    error={this.state.username.errorkey ? translate(this.state.username.errorkey) : ''}
                    charCount
                    maxLength={32}
                    onInputChanged={(val, err) => {
                      this.handleInput('username', val, err)
                    }}
                    autocomplete={'user'}
                  />
                  {/* password input */}
                  {!this.state.renderNewPwd && <Input
                    id="password"
                    ref={this.passwordInput}
                    title={translate('user.password')}
                    value={this.state.password.value}
                    error={this.state.password.errorkey ? translate(this.state.password.errorkey) : ''}
                    charCount
                    maxLength={32}
                    type={this.state.maskedPwd ? TYPE_PASSWORD : TYPE_TEXT}
                    addon={this.createInputAddon(this.state.maskedPwd)}
                    onInputChanged={(val, err) => {
                      this.handleInput('password', val, err)
                    }}
                    autocomplete={'password'}
                  />}
                  <div className="changePassword_container">
                    <Link
                      id={'changePassword_hide'}
                      text={this.state.renderNewPwd
                        ? translate('general.close')
                        : translate('loginscreen.change_password')
                      }
                      iconName={this.state.renderNewPwd ? 'chevron_up' : 'chevron_down'}
                      // className={'linkLike'}
                      onClick={() => {
                        this.handleRenderNewPwd();
                      }}
                      noGap
                    />
                    <h3 hidden={!this.state.renderNewPwd}>
                      {translate('loginscreen.change_password')}
                    </h3>
                  </div>
                  {/* new password section */}
                  {this.renderNewPwd()}
                  <div className="we_id_login_btn_row">
                    {/* login button */}
                    <Button
                      id="loginbtn"
                      text={translate('loginscreen.login')}
                      onClick={this.handleLogin}
                      primary
                      submit
                    />
                  </div>
                </Form>
              </Card>
            </Modal.Modal>
          </Main>
          <Footer
            id="loginFooter"
            text={`${productname} - Version: ${productversion}.${servicepack}${patchlevel} ${releasetype} Revision: ${buildhash} (${buildtimestamp}) `}
          />
        </div>

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lastsession: state.lastsession,
    backends: state.auth.availableBackends,
    showLoader: state.loadingspinner.showLoader
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getBackends: () => {
      AuthActions.getBackends()(dispatch)
    },
    getAPIVersion: () => {
      AuthActions.getAPIVersionInfo()(dispatch)
    },
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    login: (systemname, username, password, newPassword, openNewPassword) => {
      AuthActions.login(systemname, username, password, newPassword, props.history, openNewPassword)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)