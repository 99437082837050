import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

// Components
import {
  Button, Card, Column, DataHierarchy, Modal as ModalComponent,
  Row, TableButton
} from 'BetaUX2Web-Components/src/'
import hash from 'object-hash'

const { Modal, Main, Header, Footer } = ModalComponent

// Utils
import { translate } from 'language/Language'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as Preferences from 'redux/general/Preferences'

import './SelectorDialogHierarchy.scss'

class SelectorDialogHierarchy extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    header: PropTypes.arrayOf(PropTypes.string).isRequired,
    multiSelect: PropTypes.bool,
    items: PropTypes.array,
    type: PropTypes.string
  }

  state = {
    selectedRows: []
  }

  /**
   * @description Handles the click on the row.
   * @param index Index of the clicked row.
   * @param isClicked
   */
  handleRowClick = (id, index, isClicked) => {
    // gets the clicked row
    const row = document.querySelector(`#${id}_row_${index}`)
    // gets the row and adds the hover class or removes them
    isClicked
      ? row.classList.add('bux_fake_hover')
      : row.classList.remove('bux_fake_hover')
  }

  /**
   * @description Handles the selection change event.
   * @param index The row on which the user clicked
   */
  handleOnSelectionChange = index => {
    let id = `${this.props.id}_1`
    if (!this.state.selectedRows.includes(index)) {
      // append index to selectedRows on multiselect
      if (this.props.multiSelect) {
        this.setState(currState => ({
          selectedRows: currState.selectedRows.concat([index])
        }), () => this.handleRowClick(id, index, true))
      } else {
        // clean selectedRows on single select and append index
        this.state.selectedRows.length > 0 && this.handleRowClick(id, this.state.selectedRows, false)
        this.setState(({
          selectedRows: [index]
        }), () => this.handleRowClick(id, index, true))
      }
    } else {
      this.setState(currState => ({
        selectedRows: currState.selectedRows.filter(item => item !== index)
      }), () => this.handleRowClick(id, index, false))
    }
  }

  getSortDefs = () => {
    let sortDefs = []
    for (let i = 0; i < this.props.header.length; i++) {
      sortDefs.push('string')
    }
    return sortDefs
  }

  handleHierarchyClick = (parentKey, rootKey, hash, level, data) => {
    const { getRecipientHierarchyEntry, getNodeHierarchyEntry, type, getNodeDocumentAssginmentsHierarchyEntry, withAssignment } = this.props
    if (type === 'RECI') {
      const predReci = data[0]
      getRecipientHierarchyEntry(predReci, parentKey, rootKey, hash, level)
    }
    else if (type === 'NODE') {
      const parentNode = data[0]
      const nodeType = data[5]
      if (nodeType === 'DGI' && withAssignment) {
        getNodeDocumentAssginmentsHierarchyEntry(parentNode, parentKey, rootKey, hash, level)
      }
      else {
        getNodeHierarchyEntry(parentNode, parentKey, rootKey, hash, level)
      }
    }
  }

  handleHierarchySelect = (data, rootKey, parentKey, level) => {
    const { selectedRows } = this.state
    const hashedData = hash(data)
    const isRowSelectedIndex = selectedRows.findIndex(d => d.hashedData === hashedData && d.rootKey === rootKey && d.parentKey === parentKey && d.level === level)
    let newSelectedRows = selectedRows
    if (isRowSelectedIndex !== -1) {
      newSelectedRows = newSelectedRows.filter((_, i) => i !== isRowSelectedIndex)
    }
    else {
      newSelectedRows = [{ data, hashedData, rootKey, parentKey, level }]
    }
    this.setState({ selectedRows: newSelectedRows })
  }

  /**
 * @description Creates the action buttons for the table.
 * @param {Array} data The data of the current row.
 */
  createActionButtonsHierarchy = (data, index, rootKey, parentKey, level) => {
    const { selectedRows } = this.state
    const { id, withAssignment } = this.props
    const hashedData = hash(data)
    const isSelected = selectedRows.findIndex(d => d.hashedData === hashedData && d.rootKey === rootKey && d.parentKey === parentKey && d.level === level) !== -1
    return [
      <TableButton
        id={`${id}_tablebutton_check_${index}`}
        iconName={isSelected ? 'close' : 'check'}
        title={isSelected ? translate('general.unselect') : translate('general.select')}
        onClick={() => this.handleHierarchySelect(data, rootKey, parentKey, level)}
        disabled={withAssignment && data[5] !== 'DOC'}
      />
    ]
  }

  /**
   * @description Handles the close of a hierarchy entry. Checks if a selected row will be closed and resets the selected rows information.
   */
  handleCloseHierarchyEntry = newHierarchyEntriesData => {
    const { selectedRows } = this.state
    const { hierarchyEntriesData, updateHierarchyEntriesData } = this.props
    const removedEntries = []
    hierarchyEntriesData.forEach(oldEntry => {
      const isRemoved = newHierarchyEntriesData.findIndex(newEntry => {
        return newEntry.rootKey === oldEntry.rootKey && newEntry.parentKey === oldEntry.parentKey && newEntry.level === oldEntry.level
      }) === -1
      if (isRemoved) {
        removedEntries.push(oldEntry)
      }
    })
    removedEntries.forEach(removedEntry => {
      if (Array.isArray(removedEntry.data)) {
        removedEntry.data.forEach(d => {
          const hashedData = hash(d)
          const isSelected = selectedRows.findIndex(selected => {
            return selected.hashedData === hashedData && selected.rootKey === removedEntry.rootKey && selected.level === removedEntry.level
          }) !== -1
          if (isSelected) {
            this.setState({ selectedRows: [] })
          }
        })
      }
    })
    updateHierarchyEntriesData(newHierarchyEntriesData)
  }

  handleSubmitSelect = () => {
    const { onSelect, updateHierarchyEntriesData } = this.props
    const { selectedRows } = this.state
    updateHierarchyEntriesData([])
    onSelect(selectedRows)
  }

  handleCloseModal = () => {
    const { onClose, updateHierarchyEntriesData } = this.props
    updateHierarchyEntriesData([])
    onClose()
  }

  render = () => {
    const { id, title, header, items, datemask, lang, hierarchyEntriesData, updateHierarchyEntriesData } = this.props
    const { selectedRows } = this.state
    const hierarchyHeader = header.map(h => translate(h, undefined, undefined, false) ? translate(h) : h)
    return (
      <Modal
        id={`${id}`}
        onClose={this.handleCloseModal}>
        <Header
          id={`${id}`}
          title={title}
          onClose={() => this.handleCloseModal()}
        />
        <Main id={'child'}>
          <Card>
            <Row className={'bux_full_height'}>
              <Column colMD={12} className={'bux_full_height'}>
                <DataHierarchy
                  id={id}
                  header={hierarchyHeader}
                  data={items}
                  hierarchyEntries={hierarchyEntriesData}
                  createActionButtons={this.createActionButtonsHierarchy}
                  actionButtonsLength={1}
                  onOpenChilds={(parentKey, rootKey, hash, level, data) => this.handleHierarchyClick(parentKey, rootKey, hash, level, data)}
                  onCloseChilds={newHierarchyEntries => this.handleCloseHierarchyEntry(newHierarchyEntries)}
                  columnSortDefs={this.getSortDefs()}
                  isHierarchyOpen={hierarchyEntriesData && hierarchyEntriesData.length > 0}
                  closeHierarchy={() => updateHierarchyEntriesData([])}
                  translate={key => translate(key)}
                  language={lang}
                  datemask={datemask}
                  selectedRows={selectedRows}
                  updateSelectedRows={newselectedRows => this.setState({ selectedRows: newselectedRows })}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_footer`}>
          <Button
            id={`${id}_cancelBtn`}
            text={translate('general.cancel')}
            onClick={() => this.handleCloseModal()}
          />
          <Button
            id={`${id}_selectBtn`}
            text={translate('general.select')}
            onClick={() => this.handleSubmitSelect()}
            disabled={selectedRows.length === 0}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    hierarchyEntriesData: state.selector.hierarchyEntriesData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRecipientHierarchyEntry: (predReci, parentKey, rootKey, hash, level, callback) => {
      ModalSelectorActions.getRecipientHierarchyEntry(predReci, parentKey, rootKey, hash, level, callback)(dispatch)
    },
    getNodeHierarchyEntry: (parentNode, parentKey, rootKey, hash, level, callback) => {
      ModalSelectorActions.getNodeHierarchyEntry(parentNode, parentKey, rootKey, hash, level, callback)(dispatch)
    },
    getNodeDocumentAssginmentsHierarchyEntry: (nodeID, parentKey, rootKey, hash, level, callback) => {
      ModalSelectorActions.getNodeDocumentAssginmentsHierarchyEntry(nodeID, parentKey, rootKey, hash, level, callback)(dispatch)
    },
    updateHierarchyEntriesData: newHierarchyEntriesData => {
      ModalSelectorActions.updateHierarchyEntriesData(newHierarchyEntriesData)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorDialogHierarchy)