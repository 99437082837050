import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'

import { DownloadWrapper, Link, TableButton } from 'BetaUX2Web-Components/src'
import * as SEARCH_CONSTANTS from 'components/drawer/content/search/search_body/search_standard_search/SearchStandardSearch'
import ZosJobInformationDialog from 'components/dialogs/zos_job_information_dialog/ZosJobInformationDialog'
import { SelectionTable } from '../common/SelectionTable';
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'

import * as DocViewerActions from 'redux/actions/DocViewerActions'
import * as SearchZOSActions from 'redux/actions/SearchZOSActions'
import * as Preferences from 'redux/general/Preferences'

import { columnTranslation, EResultTableType, joinOrderedColumnsWithRest, translateHeaderColumns } from 'utils/ColumnUtils'
import * as CommonUtils from 'utils/CommonUtils'
import * as DateUtils from 'utils/DateUtils'
import { SEARCH_TYPE_ZOS_ALL } from 'utils/DocViewerUtils'
import * as SortUtils from 'utils/SortUtils'

const JOBID = 'JOBID'
const JOBNAME = 'JOBNAME'
const ACTIVATION_DATE = 'SRCSUBD'
const ACTIVATION_TIME = 'SRCSUBT'
const JOBERROR = 'JOBERR'
const RETURNCODE = 'JOBRC'
const HIGHCC = 'JHIGHCC'
const NETID = 'JNETID'
const USER = 'SRCSUBU'
const CLASS = 'SRCCLAS'
const JOBGROUP = 'JOBGROUP'
const SRCSUBDT = 'SRCSUBDT'
const STRTDT = 'STRTDT'
const ENDDT = 'ENDDT'


class SearchResultZosSearch extends Component {
  state = {
    showjobInformationDialog: false,
    showTableSettingsDialog: false,
    showReloadDialog: false,
    header: this.fillHeaderInformation(),
    translatedHeaders: [],
    settingsTranslatedHeaders: [],
  }

  updateHeadersTranslation() {
    const header = this.getUsedHeader()
    translateHeaderColumns(header, EResultTableType.BRWZOS).then(translatedHeaders => {
      this.setState({translatedHeaders})
    })
    translateHeaderColumns(this.state.header.map(v => v.rest), EResultTableType.BRWZOS).then(translatedHeaders => {
      const settingsTranslatedHeaders = translatedHeaders.map((v, index) => {
        return {
          rest: this.state.header[index].rest,
          translated: v
        }
      })
      this.setState({settingsTranslatedHeaders})
    })
  }

  componentDidMount() {
    this.updateHeadersTranslation()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.zosJobs !== prevProps.zosJobs) {
      this.setState({ header: this.fillHeaderInformation() })

      const prevHeaders = prevProps.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION]?.displayedHeaders
      const currHeaders = this.props.preferences[Preferences.TABLE_SETTINGS_STANDARDSELECTION]?.displayedHeaders

      const headersChanged = prevHeaders && currHeaders && prevHeaders !== currHeaders
      const langChanged = prevProps.lang !== this.props.lang

      if (headersChanged || langChanged) this.updateHeadersTranslation()

      if (prevState.header.length === 0 && this.state.header.length !== 0) {
        this.updateHeadersTranslation();
      }
    }
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    const { zosJobs } = this.props
    const ordererColumns = [
      { rest: JOBID, translation: columnTranslation[JOBID], default: true },
      { rest: JOBNAME, translation: columnTranslation[JOBNAME], default: true },
      { rest: SRCSUBDT, translation: columnTranslation[SRCSUBDT], default: true },
      { rest: STRTDT, translation: columnTranslation[STRTDT], default: true },
      { rest: ENDDT, translation: columnTranslation[ENDDT], default: true },
      { rest: JOBERROR, translation: columnTranslation[JOBERROR], default: true },
      { rest: RETURNCODE, translation: columnTranslation[RETURNCODE], default: true },
      { rest: HIGHCC, translation: columnTranslation[HIGHCC], default: true },
      { rest: NETID, translation: columnTranslation[NETID], default: true },
      { rest: USER, translation: columnTranslation[USER], default: true },
      { rest: CLASS, translation: columnTranslation[CLASS], default: true },
      { rest: JOBGROUP, translation: columnTranslation[JOBGROUP], default: true },
      { rest: ACTIVATION_DATE, translation: 'job.submit_date' },
      { rest: ACTIVATION_TIME, translation: 'job.submit_time' },
    ]
    return CommonUtils.hasProperties(zosJobs) ? joinOrderedColumnsWithRest(zosJobs.header, ordererColumns) : []
  }

  /**
   * @description Refreshes the table.
   */
  handleRefresh = () => {
    const { preferences, getZosJobs } = this.props
    const activeTabIndex = preferences[Preferences.SEARCH_ZOS_ACTIVE_TAB]
    let lastTimeMode = preferences[Preferences.SEARCH_ZOS_LASTTIME_MODE]
    let sdate = ''
    let stime = ''
    let edate = ''
    let etime = ''
    let customLast = ''
    let customUnit = ''

    if (activeTabIndex === 0) {
      if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_TODAY || lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_YESTERDAY) {
        sdate = preferences[Preferences.SEARCH_ZOS_LASTTIME_MODE].toUpperCase()
      } else if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_CUSTOM) {
        customLast = preferences[Preferences.SEARCH_ZOS_TIME_CUSTOM_LAST]
        customUnit = preferences[Preferences.SEARCH_ZOS_TIME_CUSTOM_UNIT]
      }
    } else if (activeTabIndex === 1) {
      sdate = preferences[Preferences.SEARCH_ZOS_START_DATE] !== '' ? moment(preferences[Preferences.SEARCH_ZOS_START_DATE], DateUtils.DDMMYYYY_DOT).format(DateUtils.DDMMYYYY_DOT) : ''
      stime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_ZOS_START_TIME])
      edate = preferences[Preferences.SEARCH_ZOS_END_DATE] !== '' ? moment(preferences[Preferences.SEARCH_ZOS_END_DATE], DateUtils.DDMMYYYY_DOT).format(DateUtils.DDMMYYYY_DOT) : ''
      etime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_ZOS_END_TIME])
    }

    const searchParams = {
      FROMLAST: customLast,
      TUNITS: customUnit,
      SDATE: sdate,
      STIME: stime,
      EDATE: edate,
      ETIME: etime,
      LOGSOURCE: preferences[Preferences.SEARCH_ZOS_LOGSOURCE],
      TUSAGE: preferences[Preferences.SEARCH_ZOS_USAGE],
      JOBNAME1: preferences[Preferences.SEARCH_ZOS_JOBNAME1],
      JOBNAME2: preferences[Preferences.SEARCH_ZOS_JOBNAME2],
      JOBNAME3: preferences[Preferences.SEARCH_ZOS_JOBNAME3],
      JOBNAME4: preferences[Preferences.SEARCH_ZOS_JOBNAME4],
      JOBID: preferences[Preferences.SEARCH_ZOS_JOBID],
      JOBNETID1: preferences[Preferences.SEARCH_ZOS_NETID1],
      JOBNETID2: preferences[Preferences.SEARCH_ZOS_NETID2],
      JOBCLASS: preferences[Preferences.SEARCH_ZOS_CLASS],
      JOBERR: preferences[Preferences.SEARCH_ZOS_ONLY_JOB_ERRORS],
    }
    getZosJobs(searchParams)
  }

  /**
   * @description gets the index of the header in redux state documents.header
   * @param {String} header header name of the header in redux state documents.header
   */
  headerData = header => this.props.zosJobs.header.indexOf(header)

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_ZOS]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_ZOS].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_ZOS]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_SEARCH_ZOS].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to show.
   * @param {Array} header The headers of the table.
   * @returns {Array} The column sort definitions.
   */
  getColumnSortDefs = (data, header) => {
    return SortUtils.getSortTypes(data, header.length)
  }

  /**
   * @description Gets specific details of a job
   * @param {Number} rowIndex
   */
  getZosDetails = rowIndex => {
    const { getZosJobDetails, zosJobs, getZosJobLogs } = this.props
    const jobObj = {
      LOGSOURCE: 'ZOS',
      SRCSUBD: zosJobs.data[rowIndex][this.headerData('SRCSUBD')],
      SRCSUBT: zosJobs.data[rowIndex][this.headerData('SRCSUBT')],
      JOBNAME: zosJobs.data[rowIndex][this.headerData('JOBNAME')],
      JOBID: zosJobs.data[rowIndex][this.headerData('JOBID')]
    }
    getZosJobDetails(jobObj, () => {
      getZosJobLogs(jobObj, () => this.setState({ showjobInformationDialog: true }))
    })
  }

  /**
   * @description Handles the download functionality of logs in binary format.
   * @param {Number} rowIndex The current row.
   */
  handleJobDownload = rowIndex => {
    const { zosJobs, downloadJob } = this.props
    const jobID = zosJobs.data[rowIndex][this.headerData('JOBID')]
    const jobName = zosJobs.data[rowIndex][this.headerData('JOBNAME')]
    let startDate = zosJobs.data[rowIndex][this.headerData('SRCSUBD')]
    let startTime = zosJobs.data[rowIndex][this.headerData('SRCSUBT')]
    const queryParams = {
      LOGSOURCE: 'ZOS',
      JOBID: jobID,
      JOBNAME: jobName,
      SRCSUBD: startDate,
      SRCSUBT: startTime,
    }

    // Replace all unwanted characters.
    startDate = startDate.replace(/[/.-]/g, '')
    startTime = startTime.replace(/[:]/g, '')

    // Generate name which will be suggested as download-filename
    const filename = [jobID, jobName, startDate, startTime].filter(entry => entry !== undefined).join('.')
    downloadJob(queryParams, filename)
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  handleDisplayJob = rowIndex => {
    const { zosJobs, displayJob, bwesystemname, bwebaseurl } = this.props
    const jobID = zosJobs.data[rowIndex][this.headerData('JOBID')]
    const jobName = zosJobs.data[rowIndex][this.headerData('JOBNAME')]
    const startDate = zosJobs.data[rowIndex][this.headerData('SRCSUBD')]
    const startTime = zosJobs.data[rowIndex][this.headerData('SRCSUBT')]
    const ukey = zosJobs.data[rowIndex][this.headerData('UKEY')]
    displayJob(bwebaseurl, bwesystemname, startDate, startTime, jobName, jobID, ukey)
  }

  /**
   * @description This function will open all selected rows as tabs in the DocViewer.
   * @param {Array<number>} selectedRowsIndices This array contains the indices of the selected rows.
   */
  openSelectedRowsInDocViewer = (selectedRowsIndices) => {
    selectedRowsIndices.forEach(rowIndex => this.openDocumentViewer(rowIndex))
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewer = (rowIndex) => {
    const { addTabToDocViewer, zosJobs } = this.props

    addTabToDocViewer({
      id: zosJobs.data[rowIndex][this.headerData('JOBID')],
      type: SEARCH_TYPE_ZOS_ALL,
      header: zosJobs.header,
      data: zosJobs.data[rowIndex],
      pageCount: 0 // Will be populated in document viewer to avoid callback problems with multi-display
    })
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => this.getZosDetails(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${rowIndex}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => this.openDocumentViewer(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButton_download_${rowIndex}`}
        iconType={'material'}
        iconName={'download'}
        title={translate('general.download')}
        onClick={() => this.handleJobDownload(rowIndex)}
      />
    ]
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   * @returns {Array} The clean data.
   */
  getCleanData = () => {
    const { datemask, zosJobs } = this.props
    const data = []
    const headers = this.getUsedHeader()
    zosJobs.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === ACTIVATION_DATE) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData(ACTIVATION_DATE)], el[this.headerData(ACTIVATION_TIME)], false))
        }
        else if (header === ACTIVATION_TIME) {
          buffer.push(DateUtils.getDate(' ', el[this.headerData(ACTIVATION_DATE)], el[this.headerData(ACTIVATION_TIME)].substring(0, 8)))
        }
        else if (header === SRCSUBDT) {
          if (el[this.headerData(SRCSUBDT)] !== '') {
            const [date, time] = el[this.headerData(SRCSUBDT)].split(' ')
            buffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            buffer.push(el[this.headerData(header)])
          }
        }
        else if (header === STRTDT) {
          if (el[this.headerData(STRTDT)] !== '') {
            const [date, time] = el[this.headerData(STRTDT)].split(' ')
            buffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            buffer.push(el[this.headerData(header)])
          }
        }
        else if (header === ENDDT) {
          if (el[this.headerData(ENDDT)] !== '') {
            const [date, time] = el[this.headerData(ENDDT)].split(' ')
            buffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            buffer.push(el[this.headerData(header)])
          }
        }
        else if (header === JOBERROR) {
          buffer.push(el[this.headerData(JOBERROR)] === 'NO' ? translate('general.no') : translate('general.yes'))
        }
        else {
          buffer.push(el[this.headerData(header)])
        }
      })
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader.
   * @returns {Array} The interaction buttons of the table above the headers.
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={() => { this.handleRefresh() }}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={data}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  render = () => {
    const { id, drawerExpanded, autoDrawer, keepPagination, zosJobs } = this.props
    const { showTableSettingsDialog, showjobInformationDialog } = this.state
    const header = this.getUsedHeader()
    const fillPage = this.getFillPageInfo()
    const data = zosJobs && zosJobs.data ? this.getCleanData() : null

    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.settingsTranslatedHeaders.length ? this.state.settingsTranslatedHeaders : this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_SEARCH_ZOS }}
          />
        )}
        {showjobInformationDialog && (
          <ZosJobInformationDialog
            id={`${id}_zosjobinfo`}
            onClose={() => this.setState({ showjobInformationDialog: false })}
          />
        )}
        <SelectionTable
          id={this.props.id}
          headers={header}
          documents={zosJobs}
          keepPagination={keepPagination}
          fillPage={fillPage}
          createActionButtons={this.createActionButtons}
          createTableRowAction={this.getZosDetails}
          additionalInteraction={this.createInteractionButtons(data, this.state.translatedHeaders)}
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}
          openDocViewerCallback={this.openSelectedRowsInDocViewer}
          resultTableType={EResultTableType.BRWZOS}
        />
      </>
    )
  }
}

SearchResultZosSearch.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    keepPagination: state.search.zos.keepPagination,
    usertoken: state.auth.serverdata.token,
    zosJobs: state.search.zos.zosJobs,
    zosJobDetails: state.search.zos.zosJobDetails,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    bwesystemname: state.auth.serverdata.bwesystemname,
    bwebaseurl: state.auth.serverdata.bwebaseurl,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getZosJobs: (searchObj, callback) => SearchZOSActions.getZosJobs(undefined, searchObj, callback)(dispatch),
    getZosJobDetails: (jobObj, jobid, callback) => SearchZOSActions.getZosJobDetails(undefined, jobObj, jobid, callback)(dispatch),
    getZosJobLogs: (jobObj, callback) => SearchZOSActions.getZosJobLogs(jobObj, callback)(dispatch),
    downloadJob: (queryParams, downloadname, callback) => SearchZOSActions.downloadJob(queryParams, downloadname, callback)(dispatch),
    displayJob: (betaviewBaseurl, betaviewFavname, sdate, stime, jobname, jobid, ukey, callback) => {
      SearchZOSActions.displayJob(betaviewBaseurl, betaviewFavname, sdate, stime, jobname, jobid, ukey, callback)(dispatch)
    },
    addTabToDocViewer: (tabData, callback) => {
      DocViewerActions.addTabToDocViewer(tabData, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultZosSearch)