import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './EmptyResult.scss'

export default class EmptyResult extends Component {

static propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string.isRequired,
  /** Label to be displayed above the component.*/
  headline: PropTypes.string.isRequired,
  /**
   * Description content as a JSX node.
   * It could be a string, JSX element or array of both.
   */
  description: PropTypes.node,
  /**
   * Link content as a JSX node.
   * It could be a string, JSX element or array of both.
   */
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /** Function to be called on an click at link.*/
  onClick: PropTypes.func,
  /** Style class from CSS for styling for button.*/
  className: PropTypes.string
}

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, headline, description, link, onClick, className } = this.props
    return (
      <div
        id={'EmptyResult'}
        className={`${className} body_container_overflow`}>
        <p
          id={`${id}_head`}
          className={'bux_empty_resultHeadText'}>
          {headline}
        </p>
        <div
          id={`${id}_body`}
          className={'bux_empty_resultBodyContainer'}>
          <h3
            id={`${id}_bodyInfo`}>
            {description}
          </h3>
          <div
            id={`${id}_bodyLink`}>
            <span
              tabIndex={0}
              id={`${id}_bodyLink_a`}
              onClick={() => onClick()}>
              {link}
            </span>
          </div>
        </div>
      </div>
    )
  }
}