import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as Utils from 'utils/Utils'

// Components
import {
  Button, Card, Column,
  Input,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent
// Redux
import { connect } from 'react-redux'
import * as ExternalCmdActions from 'redux/actions/ExternalCommandDefinitionActions'
import * as Preferences from 'redux/general/Preferences'


class CopyExternalCmdDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  externalcmdInput = React.createRef()
  commandInput = React.createRef()
  argumentInput = React.createRef()
  descriptionInput = React.createRef()


  state = {
    externalcmdID: {
      value: this.props.selectedDialog.id,
      errorkey: ''
    },
    description: {
      value: this.props.selectedDialog.desc,
      errorkey: ''
    },
    command: {
      value: this.props.selectedDialog.cmd,
      errorkey: ''
    },
    argument: {
      value: this.props.selectedDialog.argument,
      errorkey: ''
    },
  }

  componentDidMount() {
    this.externalcmdInput.current.focus()
  }

  /**
 * @description Handles changes on input fields.
 * @param id The id of the field to change
 * @param value The new value
 * @param errorkey The new errorkey
 */
  handleInputChanged = (id, value, errorkey) => {
    this.setState({ [id]: { value, errorkey } })
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInputChanged(key, value, error)
  }

  /**
   * @description Validates the id.
   */
  validateID = () => {
    const { externalcmdID } = this.state
    if (externalcmdID.value !== '') {
      if (externalcmdID.value === this.props.selectedDialog.id) {
        return {
          externalcmdID: {
            ...this.state.externalcmdID,
            errorkey: 'external_command.command_id_same_command_id'
          }
        }
      } else {
        return {}
      }
    }
    return {
      externalcmdID: {
        ...this.state.externalcmdID,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the command.
   */
  validateCommand = () => {
    if (this.state.command.value !== '') {
      return {}
    }
    return {
      command: {
        ...this.state.command,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = {
      ...this.validateID(),
      ...this.validateCommand()
    }

    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input which has an error.
   */
  handleFocus = () => {
    const requiredInputs = [
      { inputRef: this.externalcmdInput, error: this.state.externalcmdID.errorkey },
      { inputRef: this.commandInput, error: this.state.command.errorkey }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description copies the new command
   */
  handleOnSave = () => {
    const { userid, onClose, createExternalCmd } = this.props
    const { externalcmdID, description, command, argument } = this.state
    if (this.validateInputs()) {
      const externalCmdToCopy = {
        'UTL': externalcmdID.value,
        'UTLINFO': description.value,
        'UTLPROG': command.value,
        'UTLARGS': argument.value,
        'CUSER': userid,
      }

      createExternalCmd(externalCmdToCopy, () => onClose())
    }
  }


  render = () => {
    const { id, onClose } = this.props
    const { externalcmdID, command, description, argument } = this.state

    return (
      <Modal onClose={onClose}
        id={`${id}_copy_externalcmd`}
        className={'bux_copyExternalCmdDialog'}>
        <Header
          id={`${id}_copyExternalCmdDialog`}
          title={translate('definition.copy_externalcmd')}
          onClose={onClose}>
        </Header>
        <Main id={'modal_main'}>
          <Card
            id={`${id}_body_card`}>
            <Row id={`${id}_externalcmd_id_row`}>
              <Column id={`${id}_externalcmd_id_col`} colMD={6}>
                <Input
                  id={`${id}_externalcmd_id_input`}
                  ref={this.externalcmdInput}
                  onInputChanged={(val, err) => { this.handleChangeWithoutSpaces('externalcmdID', val, err) }}
                  value={externalcmdID.value}
                  title={translate('external_command.id')}
                  error={externalcmdID.errorkey ? translate(externalcmdID.errorkey) : null}
                  onBlur={() => this.setState({ ...this.validateID() })}
                  maxLength={16}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
              <Column id={`${id}_externalcmd_description_col`} colMD={6}>
                <Input
                  id={`${id}_externalcmd_description_input`}
                  ref={this.descriptionInput}
                  onInputChanged={(val, err) => { this.handleInputChanged('description', val, err) }}
                  value={description.value}
                  title={translate('external_command.description')}
                  error={description.errorkey ? translate(description.errorkey) : null}
                  maxLength={64}
                />
              </Column>
            </Row>
            <Row id={`${id}_externalcmd_command_row`}>
              <Column id={`${id}_externalcmd_command_col`} colMD={12}>
                <Input
                  id={`${id}_externalcmd_command_input`}
                  ref={this.commandInput}
                  onInputChanged={(val, err) => { this.handleChangeWithoutSpaces('command', val, err) }}
                  value={command.value}
                  title={translate('external_command.command')}
                  error={command.errorkey ? translate(command.errorkey) : null}
                  onBlur={() => this.setState({ ...this.validateCommand() })}
                  maxLength={256}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row id={`${id}_externalcmd_argument_row`}>
              <Column id={`${id}_externalcmd_argument_col`} colMD={12}>
                <Input
                  id={`${id}_externalcmd_argument_input`}
                  ref={this.argumentInput}
                  onInputChanged={(val, err) => { this.handleInputChanged('argument', val, err) }}
                  value={argument.value}
                  title={translate('external_command.arguments')}
                  error={argument.errorkey ? translate(argument.errorkey) : null}
                  maxLength={256}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_modal_footer`}>
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_footer_save`}
            text={translate('general.save')}
            onClick={this.handleOnSave}
            primary
            submit
          />
        </Footer>
      </Modal >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createExternalCmd: (externalcmdObj, callback) => {
      ExternalCmdActions.createExternalCmd(externalcmdObj, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyExternalCmdDialog)