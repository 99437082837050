import { translate } from 'language/Language'
import { Component } from 'react'

import {
  Button, Card, Column, MetaDataGrid, Modal as ModalComponent,
  Row, TitledLabel
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as QueueUtils from 'utils/QueueUtils'

import { getDate } from 'utils/DateUtils'

class ReloadQueueRequestInformation extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  renderHeader = () => {
    const { id, reloadDetails, datemask } = this.props
    const status = reloadDetails && translate(QueueUtils.getStatus().find(d => d.key === reloadDetails.RLDSTAT)?.translationKey)
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('queue.queuedby'), value: reloadDetails.RLDUSER },
          { label: translate('queue.status'), value: status },
          { label: translate('queue.priority'), value: reloadDetails.RLDPRTY },
          { label: translate('queue.requestqueued'), value: getDate(datemask, reloadDetails.RLDDATE, reloadDetails.RLDTIME.substring(0, 8)) },
          { label: translate('general.retention_period'), value: reloadDetails.ONLRETPD },
        ]}
        columns={4}
      />

    )
  }

  renderMain = () => {
    const { id, reloadDetails, datemask } = this.props
    return (
      <Card>
        <Row>
          <Column colMD={3}>
            <label id={`${id}_document_info`}>{translate('documentinformation.modaltitle')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_form`}
              title={translate('general.form')}
              text={reloadDetails.FORM}
            />
          </Column>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_extension`}
              title={translate('general.extension')}
              text={reloadDetails.EXT}
            />
          </Column>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_report`}
              title={translate('general.report')}
              text={reloadDetails.WREPORT}
            />
          </Column>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_jobname`}
              title={translate('general.jobname')}
              text={reloadDetails.SRCJOBN}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_pages`}
              title={translate('general.pages')}
              text={reloadDetails.SRCPAGES}
            />
          </Column>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_type`}
              title={translate('general.type')}
              text={reloadDetails.SRCJOBI}
            />
          </Column>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_documentimported`}
              title={translate('queue.documentimported')}
              text={
                getDate(
                  datemask,
                  reloadDetails.B93DATE,
                  reloadDetails.B93TIME.substring(0, 8))}
            />
          </Column>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_archivepointer`}
              title={translate('documentinformation.pointerstab_archivepointer')}
              text={reloadDetails.ARCPTR}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_title`}
              title={translate('general.title')}
              text={reloadDetails.LTITLE}
            />
          </Column>
        </Row>
      </Card>
    )
  }


  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={id}
          title={translate('queue.reload_information')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main>
          {this.renderMain()}
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    reloadDetails: state.queue.reload.reloadDetails
  }
}

export default connect(mapStateToProps)(ReloadQueueRequestInformation)