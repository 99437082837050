import { translate } from 'language/Language'
import { Component } from 'react'
import * as DateUtils from 'utils/DateUtils'
import * as SortUtils from 'utils/SortUtils'

// Components
import {
  DataTable, DownloadWrapper, EmptySearchResult, Link, NoSearch, ResultContainer
} from 'BetaUX2Web-Components/src/'


// Redux
import { connect } from 'react-redux'
import { getServerLogFile } from 'redux/actions/ServerActions'
import * as Preferences from 'redux/general/Preferences'

class SearchResultServerLogFile extends Component {
  state = {
    showTableSettingsDialog: false
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  handleRefresh = event => {
    const { preferences, fireSearchRequest } = this.props
    event.preventDefault()
    fireSearchRequest({
      LSTKEY: preferences.SERVER_SERVERLOGFILE_SERVER_LOG_FILE,
      LOGDATE: DateUtils.getDateFromUnixTimestamp(preferences.SERVER_SERVERLOGFILE_DATE, DateUtils.DDMMYYYY_DOT),
    })
  }

  render = () => {
    const { id, drawerExpanded, autoDrawer, serverLogFile, lang, datemask } = this.props
    const data = serverLogFile && serverLogFile.data ? serverLogFile.data.data : []
    const header = serverLogFile && serverLogFile.data ? ['general.text'] : []
    return (
      <>
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if documents not exist in redux
            serverLogFile
              ? (
                // show empty result if there are no documents after searching
                data.length
                  ? (
                    <DataTable
                      id={id}
                      header={header.map(h => translate(h, undefined, undefined, false) ? translate(h) : h)}
                      data={data}
                      cleanData={data}
                      selectable
                      columnSortDefs={this.getColumnSortDefs(data, header)}
                      additionalInteraction={[
                        <Link
                          id={'cached'}
                          iconName={'refresh'}
                          tooltip={translate('table.refresh')}
                          onClick={event => this.handleRefresh(event)}
                        />,
                        <DownloadWrapper
                          id='download_wrapper'
                          header={header}
                          data={[...data]}
                          csvSplitter=';'
                          filename='data.csv'>
                          <Link
                            id={'download'}
                            iconName={'download'}
                            tooltip={translate('table.download_as_csv')}
                          />
                        </DownloadWrapper>
                      ]}
                      fillPage={true}
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                    />
                  )
                  : (
                    <EmptySearchResult
                      id={`${id}_emptysearchresult`}
                      description={translate('general.no_data_found')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )}
        </ResultContainer>
      </>
    )
  }
}

const mapStateToProps = ({ auth, server }) => {
  return {
    token: auth.serverdata.token,
    preferences: auth.serverdata.preferences,
    serverLogFile: server.serverLogFile,
    lang: auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: auth.serverdata.preferences[Preferences.DATEMASK],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fireSearchRequest: (searchParams, callback) => {
      getServerLogFile(searchParams, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultServerLogFile)