import React, { useEffect, useState } from 'react'

import {
  Button, Card, Column, Modal as ModalComponent,
  Row, Switch
} from 'BetaUX2Web-Components/src/'
import { translate } from 'language/Language'

const { Modal, Main, Header, Footer } = ModalComponent

const focusRef1 = React.createRef()

const PclPjlTextOptions = ({ id, showPjl, setShowPjl }) => {
  return (
    <Row>
      <Column colMD={12}>
        <Switch
          id={`${id}_show_pjl`}
          title={translate('general.show_pjl')}
          items={[translate('general.yes'), translate('general.no')]}
          onClick={index => setShowPjl(index)}
          activeIndex={showPjl}
          refs={[focusRef1]}
        />
      </Column>
    </Row>
  )
}

const AfpTextOptions = ({ id, showNop, setShowNop, showTle, setShowTle }) => {
  return (
    <>
      <Row>
        <Column colMD={6}>
          <Switch
            id={`${id}_show_nop`}
            title={translate('general.show_nop')}
            items={[translate('general.yes'), translate('general.no')]}
            onClick={index => setShowNop(index)}
            activeIndex={showNop}
            refs={[focusRef1]}
          />
        </Column>
        <Column colMD={6}>
          <Switch
            id={`${id}_show_tle`}
            title={translate('general.show_tle')}
            items={[translate('general.yes'), translate('general.no')]}
            onClick={index => setShowTle(index)}
            activeIndex={showTle}
          />
        </Column>
      </Row>
    </>
  )
}

const SpecialOptions = ({ id, displayMode, setDisplayMode, showPageHeader, setShowPageHeader, showResources, setShowResources }) => {
  return (
    <>
      <Row>
        <Column colMD={4}>
          <Switch
            id={`${id}_display_mode`}
            title={translate('general.display_mode')}
            items={[translate('general.line_format'), translate('general.block_format')]}
            onClick={index => setDisplayMode(index)}
            activeIndex={displayMode}
            refs={[focusRef1]}
          />
        </Column>
        <Column colMD={4}>
          <Switch
            id={`${id}_show_page_header`}
            title={translate('general.show_page_header')}
            items={[translate('general.yes'), translate('general.no')]}
            onClick={index => setShowPageHeader(index)}
            activeIndex={showPageHeader}
          />
        </Column>
        <Column colMD={4}>
          <Switch
            id={`${id}_show_resources`}
            title={translate('general.show_resources')}
            items={[translate('general.yes'), translate('general.no')]}
            onClick={index => setShowResources(index)}
            activeIndex={showResources}
          />
        </Column>
      </Row>
    </>
  )
}

const DefaultHexOptions = ({ id, displayMode, setDisplayMode, showPageHeader, setShowPageHeader, }) => {
  return (
    <Row>
      <Column colMD={6}>
        <Switch
          id={`${id}_display_mode`}
          title={translate('general.display_mode')}
          items={[translate('general.line_format'), translate('general.block_format')]}
          onClick={index => setDisplayMode(index)}
          activeIndex={displayMode}
          refs={[focusRef1]}
        />
      </Column>
      <Column colMD={6}>
        <Switch
          id={`${id}_show_page_header`}
          title={translate('general.show_page_header')}
          items={[translate('general.yes'), translate('general.no')]}
          onClick={index => setShowPageHeader(index)}
          activeIndex={showPageHeader}
        />
      </Column>
    </Row>
  )
}

export const DownloadDialog = ({ id, onClose, mimetype, downloadType, download, title }) => {
  const [displayMode, setDisplayMode] = useState(0)
  const [showPageHeader, setShowPageHeader] = useState(1)
  const [showResources, setShowResources] = useState(1)
  const [showPjl, setShowPjl] = useState(1)
  const [showNop, setShowNop] = useState(1)
  const [showTle, setShowTle] = useState(1)

  // equivalent to component did mount
  useEffect(() => {
    return focusRef1?.current?.focus()
  }, [])

  const handleDownloadText = () => {
    const showNopToUse = { 0: 'YES', 1: 'NO' }[showNop]
    const showTleToUse = { 0: 'YES', 1: 'NO' }[showTle]
    const showPjlToUse = { 0: 'YES', 1: 'NO' }[showPjl]
    download(showNopToUse, showTleToUse, showPjlToUse)
  }

  const handleDownloadHex = () => {
    const displayModeToUse = { 0: 'NO', 1: 'YES' }[displayMode]
    const showPageHeaderToUse = { 0: 'YES', 1: 'NO' }[showPageHeader]
    const showResourcesToUse = { 0: 'YES', 1: 'NO' }[showResources]
    download(displayModeToUse, showPageHeaderToUse, showResourcesToUse)
  }

  const renderHexOptions = () => {
    switch (mimetype) {
      case '*.afp': return (
        <SpecialOptions
          id={id}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          showPageHeader={showPageHeader}
          setShowPageHeader={setShowPageHeader}
          showResources={showResources}
          setShowResources={setShowResources}
        />
      )
      case '*.ps': return (
        <SpecialOptions
          id={id}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          showPageHeader={showPageHeader}
          setShowPageHeader={setShowPageHeader}
          showResources={showResources}
          setShowResources={setShowResources}
        />
      )
      case '*.pcl': return (
        <SpecialOptions
          id={id}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          showPageHeader={showPageHeader}
          setShowPageHeader={setShowPageHeader}
          showResources={showResources}
          setShowResources={setShowResources}
        />
      )
      default: return (
        <DefaultHexOptions
          id={id}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          showPageHeader={showPageHeader}
          setShowPageHeader={setShowPageHeader}
        />
      )
    }
  }

  const renderTextOptions = () => {
    switch (mimetype) {
      case '*.afp': return (
        <AfpTextOptions
          id={id}
          showNop={showNop}
          setShowNop={setShowNop}
          showTle={showTle}
          setShowTle={setShowTle}
        />
      )
      case '*.pjl': return (
        <PclPjlTextOptions
          id={id}
          showPjl={showPjl}
          setShowPjl={setShowPjl}
        />
      )
      case '*.pcl': return (
        <PclPjlTextOptions
          id={id}
          showPjl={showPjl}
          setShowPjl={setShowPjl}
        />
      )
      default: return <></>
    }
  }

  return (
    <Modal onClose={onClose} id={id} small={downloadType === 'TEXT' || !['*.afp', '*.ps', '*.pcl'].includes(mimetype)}>
      <Header
        id={`${id}_modal_header`}
        title={title}
        onClose={onClose}>
      </Header>
      <Main>
        <Card>
          { downloadType === 'TEXT' ? renderTextOptions() : renderHexOptions() }
        </Card>
      </Main>
      <Footer>
        <Button
          id={`${id}_cancel_btn`}
          text={translate('general.cancel')}
          onClick={onClose}
        />
        <Button
          id={`${id}_download_btn`}
          text={translate('general.download')}
          onClick={downloadType === 'TEXT' ? () => handleDownloadText() : () => handleDownloadHex()}
          primary
        />
      </Footer>
    </Modal>
  )
}