import PropTypes from 'prop-types'
import { Component } from 'react'
import * as UserUtils from 'utils/UserUtils'
import * as Utils from 'utils/Utils'

// components
import { Button, Card, Column, DataTable, DonutChart, DownloadWrapper, Link, Progressbar, ResultContainer, Row, TableButton } from 'BetaUX2Web-Components/src/'
import CreateDatabaseFileDialog from 'components/dialogs/create_database_file/CreateDatabaseFileDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as DatabaseActions from 'redux/actions/DatabaseActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'

import './SearchResultDatabaseStatus.scss'

class SearchResultDatabaseStatus extends Component {

  state = {
    expandableRowData: [],
    showAddDialog: false,
    showDeleteDialog: false,
    status: null
  }

  /**
   * @description Sends the getFiles request.
   */
  componentDidMount = () => {
    this.props.getFiles()
  }

  /**
   * @description Gets the colors for the 'used' column as an object.
   * @param {Number} value The current value of the used space.
   */
  getUsedColors = value => {
    let foreground
    let background
    const fontColor = '#000000'
    if (value >= 0 && value < 40) {
      foreground = '#80bb3d'
      background = '#80bb3d'
    } else if (value >= 40 && value < 80) {
      foreground = '#fffa72'
      background = '#fffa72'
    } else if (value >= 80) {
      foreground = '#d31409'
      background = '#d31409'
    }
    return {
      foreground: foreground,
      background: background,
      font: fontColor
    }
  }

  /**
   * @description Translates the type.
   * @returns {String} The translated type.
   */
  translateType = type => {
    switch (type) {
      case 'DA': return 'Data'
      case 'KE': return 'Keys'
      case 'IX': return 'Index'
      case 'SP': return 'Spool'
      case 'LO': return 'Logfile'
      case 'NO': return 'Definitions'
      case 'SY': return 'Sync.'
      default: return type // Should not happen!
    }
  }

  /**
   * @description Data with jsx elements for icon (don't use this for download as csv)
   * @param {Boolean} shouldBeClean Flag if the data should be clean.
   * @returns {Array} The data.
   */
  getViewData = shouldBeClean => {
    const { database, lang } = this.props

    let data = []
    database.files.data.forEach(element => {
      const fileid = element[database.files.header.findIndex(entry => entry === 'XDFID')]
      const filename = element[database.files.header.findIndex(entry => entry === 'XDDSN')]
      const shortname = element[database.files.header.findIndex(entry => entry === 'XASNAME')]
      const type = this.translateType(element[database.files.header.findIndex(entry => entry === 'XDTYPE')])
      const totalBlocks = element[database.files.header.findIndex(entry => entry === 'XDBLKU')] !== '' ? element[database.files.header.findIndex(entry => entry === 'XDBLKU')] : 0
      const allocBlocks = element[database.files.header.findIndex(entry => entry === 'XDBLKA')] !== ''
        ? parseInt(element[database.files.header.findIndex(entry => entry === 'XDBLKA')])
        : 0
      const freeBlocks = element[database.files.header.findIndex(entry => entry === 'XDBLKF')] !== ''
        ? parseInt(element[database.files.header.findIndex(entry => entry === 'XDBLKF')])
        : 0
      let used
      if (!shouldBeClean) {
        used = <Progressbar
          id={`used_row${data.length}`}
          key={`used_row${data.length}`}
          value={parseInt(allocBlocks)}
          textFormatter={number => Utils.formatNumeric(number, lang, 2)}
          max={parseInt(totalBlocks)}
          color={this.getUsedColors((allocBlocks !== 0 && totalBlocks !== 0) ? allocBlocks / totalBlocks * 100 : 0)} />
      } else {
        used = `${(allocBlocks !== 0 && totalBlocks !== 0) ? (allocBlocks / totalBlocks * 100).toFixed(2) : 0} %`
      }

      let status = element[database.files.header.findIndex(entry => entry === 'XDSTAT')]
      if (status === 'MOD') {
        status = translate('database.file_status_mod')
      } else if (status === 'OPN') {
        status = translate('database.file_status_opn')
      } else if (status === 'FMT') {
        status = translate('database.file_status_fmt')
      } else if (status === 'FUL') {
        status = translate('database.file_status_ful')
      } else if (status === 'ONL') {
        status = translate('database.file_status_onl')
      } else if (status === 'ERR') {
        status = translate('database.file_status_err')
      } else if (status === 'CLS') {
        status = translate('database.file_status_cls')
      } else if (status === 'EMP') {
        status = translate('database.file_status_emp')
      }

      let readOnly = element[database.files.header.findIndex(entry => entry === 'XDREADO')]
      if (readOnly === 'NO') {
        readOnly = translate('general.no')
      } else {
        readOnly = translate('general.yes')
      }

      data.push([fileid, filename, shortname, type, used, allocBlocks, freeBlocks, status, readOnly])
    })
    return data
  }

  /**
   * @description Gets the header for the table. Includes empty header strings for the actions.
   * @returns {Array} The translated headers.
   */
  getHeader = () => {
    const { lang } = this.props
    return [
      translate('database.file_id', lang),
      translate('database.filename', lang),
      translate('database.shortname', lang),
      translate('database.type', lang),
      translate('database.used', lang),
      translate('database.allocated_blocks', lang),
      translate('database.free_blocks', lang),
      translate('general.status', lang),
      translate('database.read_only', lang),
    ]
  }

  /**
   * @description gets the index of the header in redux state documents.header
   * @param {String} header header name of the header in redux state documents.header
   */
  headerData = header => this.props.database.files.header.indexOf(header)

  /**
   * @description Gets the data for the spool databases diagram.
   * @returns {Array} The data for the spool databases diagram.
   */
  getSpoolDatabasesDiagramData = () => {
    const { database } = this.props
    let used = 0
    let free = 0
    const SPOOL = 'SP'
    database.files.data.forEach(d => {
      if (d[this.headerData('XDTYPE')] === SPOOL) {
        used += d[this.headerData('XDBLKA')] !== '' ? parseInt(d[this.headerData('XDBLKA')]) : 0
        free += d[this.headerData('XDBLKF')] !== '' ? parseInt(d[this.headerData('XDBLKF')]) : 0
      }
    })

    return [
      { 'name': translate('database.status_donutchart_used'), 'value': used },
      { 'name': translate('database.status_donutchart_free'), 'value': free }
    ]
  }

  /**
   * @description Gets the data for the index databases diagram.
   * @returns {Array} The data for the index databases diagram.
   */
  getIndexDatabasesDiagramData = () => {
    const { database } = this.props
    let used = 0
    let free = 0
    const INDEX = 'IX'
    database.files.data.forEach(d => {
      if (d[this.headerData('XDTYPE')] === INDEX) {
        used += d[this.headerData('XDBLKA')] !== '' ? parseInt(d[this.headerData('XDBLKA')]) : 0
        free += d[this.headerData('XDBLKF')] !== '' ? parseInt(d[this.headerData('XDBLKF')]) : 0
      }
    })

    return [
      { 'name': translate('database.status_donutchart_used'), 'value': used },
      { 'name': translate('database.status_donutchart_free'), 'value': free }
    ]
  }

  /**
   * @description Formats the value with the correct unit.
   * @param {Number} val The value to format.
   * @returns {String} The formatted value.
   */
  formatFunc = val => {
    let counter = 0
    let unit
    // calc block to MB
    val /= 256

    while (val > 1024) {
      val /= 1024
      counter++
    }
    switch (counter) {
      case 0:
        unit = 'MB'
        break
      case 1:
        unit = 'GB'
        break
      case 2:
        unit = 'TB'
        break
      default:
        unit = 'MB'
    }
    return `${Math.round(val * 100) / 100} ${unit}`
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    const result = []
    if (UserUtils.isAdmin()) {
      result.push(
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create', lang)}
          onClick={() => this.setState({ showAddDialog: true })}
        />
      )
    }
    result.push(
      <Link
        id={'cached'}
        iconName={'refresh'}
        tooltip={translate('table.refresh', lang)}
        onClick={() => {
          this.props.getFiles()
          this.setState({ expandableRowData: [] })
        }}
      />,
      <DownloadWrapper
        id='download_wrapper'
        header={header}
        data={[...data]}
        csvSplitter=';'
        filename='data.csv'
        tooltip={translate('table.download_as_csv')}>
        <Link
          id={'download'}
          iconName={'download'}
          onCLick={() => { }}
        />
      </DownloadWrapper>
    )
    return result
  }

  /**
   * @description Creates the action button for a row.
   * @param {Number} index The index of the row.
   * @returns {Array} The action buttons.
   */
  createActionButtons = index => {
    const { database, id } = this.props
    if (UserUtils.isAdmin()) {
      let enableDeleteType = true
      let enableDeleteStatus = true
      if (index !== undefined) {
        const databaseType = database.files.data[index][this.headerData('XDTYPE')]
        const databaseStatus = database.files.data[index][this.headerData('XDSTAT')]
        enableDeleteType = (databaseType === 'SP' || databaseType === 'IX')
        enableDeleteStatus = (databaseStatus === 'MOD' || databaseStatus === 'EMP')
      }

      requestAnimationFrame(() => {
        let allTableButtons = Array.from(document.querySelectorAll('.listActionButtons'))
        allTableButtons.forEach(button => {
          button.classList.remove('disabledButtonDataList')
          let iconContainer = button.querySelector('div')
          if (iconContainer && iconContainer.classList.contains('disabled')) {
            button.classList.add('disabledButtonDataList')
          }
        })
      })

      return [
        <TableButton
          id={`${id}_tableButtonDelete_${index}`}
          iconType='material'
          iconName='delete'
          title={!enableDeleteType
            ? translate('database.disable_delete_type')
            : (!enableDeleteStatus
              ? translate('database.disable_delete_status')
              : translate('general.delete')
            )
          }
          onClick={() => this.handleOpenDeleteDialog(index)}
          disabled={(!enableDeleteType || !enableDeleteStatus)}
        />
      ]
    }
    return []
  }

  /**
   * @description Loads the specific database file and opens the delete dialog.
   * @param {Number} index The current row.
   */
  handleOpenDeleteDialog = index => {
    const { database } = this.props
    let shortname = database.files.data[index][database.files.header.findIndex(header => header === 'XASNAME')]
    const callback = () => {
      this.setState({ showDeleteDialog: true })
    }
    this.props.getFile(shortname, callback)
  }

  /**
   * @description Deletes the current database file.
   */
  handleDelete = () => {
    const { database: { file } } = this.props
    const shortname = file.XASNAME
    const filename = file.XDDSN
    const callback = () => {
      this.setState({ showDeleteDialog: false })
    }
    this.props.deleteFile(shortname, filename, callback)
  }

  /**
   * @description Gets the expandable rowdata of the current database file.
   * @param {Number} index The current index.
   */
  addExpandableRowData = (index, callback) => {
    const { database: { files } } = this.props
    const { expandableRowData } = this.state
    let shortname = files.data[index][files.header.findIndex(header => header === 'XASNAME')]
    this.props.getFile(shortname, (file) => {
      return this.setState({
        expandableRowData: [...expandableRowData, [index, file.XDGETS, file.XDPUTS, file.XDSPACE, file.XDCSI]]
      }, () => callback && callback())
    })
  }

  /**
   * @description Deletes the expandable rowdata of the current database file.
   * @param {Number} index The current index.
   */
  deleteExpandableRowData = (index, callback) => {
    this.setState({ expandableRowData: this.state.expandableRowData.filter(d => d[0] !== index) }, () => callback && callback())
  }

  /**
   * @description Updates the read write status.
   * @param {Number} index The index f the current row.
   */
  handleReadWriteStatus = index => {
    const { database, updateFile, showSnackbar, lang } = this.props
    const shortname = database.files.data[index][database.files.header.findIndex(header => header === 'XASNAME')]
    const currentReadOnlyFlag =
      database.files.data[index][database.files.header.findIndex(header => header === 'XDREADO')] === 'YES'
        ? 'NO'
        : 'YES'
    const callback = () => {
      if (currentReadOnlyFlag === 'YES') {
        showSnackbar(translate('database.status_changed_to_read_write_success', lang, shortname), SnackbarActions.TYPE_SUCCESS)
      } else {
        showSnackbar(translate('database.status_changed_to_read_only_success', lang, shortname), SnackbarActions.TYPE_SUCCESS)
      }
    }
    updateFile(shortname, currentReadOnlyFlag, callback)
  }

  /**
   * @description Creates the read write button for the specific row.
   * @param {Number} index The index of the row.
   * @returns {Object} The read write button.
   */
  createReadWriteButton = index => {
    const { id, database } = this.props
    const currentReadOnlyFlag = database.files.data[index][database.files.header.findIndex(header => header === 'XDREADO')]
    const type = database.files.data[index][database.files.header.findIndex(header => header === 'XDTYPE')] === 'SP' ||
      database.files.data[index][database.files.header.findIndex(header => header === 'XDTYPE')] === 'IX'
    const status = database.files.data[index][database.files.header.findIndex(header => header === 'XDSTAT')] !== 'MOD'

    return (
      <>
        {type && status && UserUtils.isAdmin() &&
          <Button
            id={`${id}_readwritebtn_${index}`}
            text={currentReadOnlyFlag === 'NO'
              ? translate('database.status_set_read_only')
              : translate('database.status_set_read_write')}
            onClick={() => this.handleReadWriteStatus(index)}
          />
        }
      </>
    )
  }

  /**
   * @description Fills the expandable row with data.
   * @param {Array} data The data.
   * @param {Number} index The index of the row.
   */
  expandableRowElements = (data, index) => {
    if (data) {
      return (
        <div className={'expandableRowContainer'}>
          <div className={'statusInformation'}>
            <div className={'informationContainer'}>
              <div className={'information'}>
                <div>{translate('database.reads')}: </div>
                <div>{data[1]}</div>
              </div>
              <div className={'information'}>
                <div>{translate('database.writes')}: </div>
                <div>{data[2]}</div>
              </div>
            </div>
            <div className={'informationContainer'}>
              <div className={'information'}>
                <div>{translate('database.file_size')}: </div>
                <div>{data[3]} MB</div>
              </div>
              <div className={'information'}>
                <div>{translate('database.block_size')}: </div>
                <div>{data[4]} Bytes</div>
              </div>
            </div>
          </div>
          {this.createReadWriteButton(index)}
        </div>
      )
    }
  }

  getColumnSortDefs = () => {
    return [
      'string',
      'string',
      'string',
      'string',
      'progressbar',
      'number',
      'number',
      'string',
      'string',
    ]
  }

  render = () => {
    const { id, database, database: { file }, drawerExpanded, autoDrawer, lang, datemask } = this.props
    const { expandableRowData } = this.state
    const shouldBeClean = true
    let header = database.files && database.files.data ? this.getHeader() : null
    let data = database.files && database.files.data ? this.getViewData() : null
    let cleanData = database.files && database.files.data ? this.getViewData(shouldBeClean) : null
    return (
      <>
        {
          this.state.showAddDialog &&
          <CreateDatabaseFileDialog
            id={`${id}_createfile`}
            onClose={() => this.setState({ showAddDialog: false })}
          />
        }
        {
          this.state.showDeleteDialog && file && (
            <DeleteDialog
              id={`${id}_deletedatabasedialog`}
              title={translate('database.delete_database_file')}
              question={translate('database.question_delete_database_file')}
              visible={this.state.showDeleteDialog}
              onClose={() => { this.setState({ showDeleteDialog: false }) }}
              onDelete={() => this.handleDelete()}>
              <Row>
                <Column
                  colMD={3}>
                  <p id={`${id}_database_fileid_key_text`}>
                    {translate('database.fileid')}:
                  </p>
                </Column>
                <Column
                  colMD={3}>
                  <p id={`${id}_database_fileid_value_text`}>
                    {file.XDFID}
                  </p>
                </Column>
              </Row>
              <Row>
                <Column
                  colMD={3}>
                  <p id={`${id}_database_filename_key_text`}>
                    {translate('database.filename')}:
                  </p>
                </Column>
                <Column
                  colMD={3}>
                  <p id={`${id}_database_filename_value_text`}>
                    {file.XDDSN}
                  </p>
                </Column>
              </Row>
              <Row>
                <Column
                  colMD={3}>
                  <p id={`${id}_database_type_key_text`}>
                    {translate('database.type')}:
                  </p>
                </Column>
                <Column
                  colMD={3}>
                  <p id={`${id}_database_type_value_text`}>
                    {file.XDTYPE === 'IX' ? translate('database.index_model') : translate('database.spool_model')}
                  </p>
                </Column>
              </Row>
              <Row>
                <Column
                  colMD={3}>
                  <p id={`${id}_database_status_key_text`}>
                    {translate('database.status')}:
                  </p>
                </Column>
                <Column
                  colMD={3}>
                  <p id={`${id}_database_status_value_text`}>
                    {file.XDSTAT === 'MOD' ? translate('database.model') : translate('database.empty')}
                  </p>
                </Column>
              </Row>
            </DeleteDialog>
          )
        }
        <ResultContainer
          drawerColor
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          <Row>
            <Column
              colSM={6}
              colMD={6}>
              <Card
                title={translate('database.spool_databases')}
                className={'bux_card_padding'}>
                <div id={'donutchart_container'}>
                  {data &&
                    <DonutChart
                      id={1}
                      data={this.getSpoolDatabasesDiagramData()}
                      thickness={30}
                      pieOrDonut={'donut'}
                      colors={['#0068b4', '#d6d8de']}
                      formatFunc={this.formatFunc}
                    />
                  }
                </div>
              </Card>
            </Column>
            <Column
              colSM={6}
              colMD={6}>
              <Card
                title={translate('database.index_databases')}
                className={'bux_card_padding'}>
                <div id={'donutchart_container'}>
                  {data &&
                    <DonutChart
                      id={2}
                      data={this.getIndexDatabasesDiagramData()}
                      thickness={30}
                      pieOrDonut={'donut'}
                      colors={['#0068b4', '#d6d8de']}
                      formatFunc={this.formatFunc}
                    />
                  }
                </div>
              </Card>
            </Column>
          </Row>
          {data &&
            <Row className={'bux_database_status_datatable_row'}>
              <Column colMD={12}>
                {/* <div className={'bux_datalist_database_status'}> */}
                <Card className={'bux_card_padding'} fullHeight>
                  <DataTable
                    id={id}
                    header={header}
                    data={data}
                    columnSortDefs={this.getColumnSortDefs()}
                    language={lang}
                    datemask={datemask}
                    translate={key => translate(key)}
                    createActionButtons={this.createActionButtons}
                    additionalInteraction={this.createInteractionButtons(cleanData, header)}
                    expandable
                    onOpenRow={this.addExpandableRowData}
                    onCloseRow={this.deleteExpandableRowData}
                    expandableRowElements={this.expandableRowElements}
                    expandableRowData={expandableRowData}
                  />
                </Card>
                {/* </div> */}
              </Column>
            </Row>
          }
        </ResultContainer>
      </>
    )
  }
}

SearchResultDatabaseStatus.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    database: state.database,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getFiles: () => {
      DatabaseActions.getFiles()(dispatch)
    },
    getFile: (filename, callback) => {
      DatabaseActions.getFile(filename, callback)(dispatch)
    },
    deleteFile: (shortname, filename, callback) => {
      DatabaseActions.deleteFile(shortname, filename, callback)(dispatch)
    },
    updateFile: (filename, readonly, callback) => {
      DatabaseActions.updateFile(filename, readonly, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultDatabaseStatus)