import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_START = 'FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_START'
export const FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_SUCCESS = 'FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_SUCCESS'
export const FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_FAILED = 'FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_FAILED'
export const NO_FILTERARGUMENT_DEFINITION_FILTERARGUMENTS_FOUND = 'NO_FILTERARGUMENT_DEFINITION_FILTERARGUMENTS_FOUND'

export const FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_START = 'FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_START'
export const FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_SUCCESS = 'FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_SUCCESS'
export const FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_FAILED = 'FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_FAILED'

export const FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_START = 'FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_START'
export const FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_SUCCESS = 'FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_SUCCESS'
export const FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_FAILED = 'FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_FAILED'

export const FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_START = 'FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_START'
export const FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_SUCCESS = 'FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_SUCCESS'
export const FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_FAILED = 'FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_FAILED'

export const FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_START = 'FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_START'
export const FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_SUCCESS = 'FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_SUCCESS'
export const FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_FAILED = 'FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_FAILED'

export const FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_START = 'FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_START'
export const FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_SUCCESS = 'FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_SUCCESS'
export const FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_FAILED = 'FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_FAILED'

/**
 * @description Calls the rest api and gets filter arguments.
 * @param {*} fields The fields to select (If undefined => Return all fields). Possible fields:
 *
 * @param {*} owner The owner of the filter argument.
 * @param {*} filterArgument The name/id of the filter argument.
 * @param {*} title The title of the filter argument.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getFilterArguments(fields, owner, filterArgument, title, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (filterArgument) { queryParams.push(`SLF=${encodeURIComponent(filterArgument)}`) }
    if (title) { queryParams.push(`SLFTITLE=${encodeURIComponent(title)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/filterarguments?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_FILTERARGUMENT_DEFINITION_FILTERARGUMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_FAILED, payload: { error } })
          }
        } else {
          dispatch({ type: FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.filter_argument_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a filter argument definition.
 * @param {Object} filterArgument
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function createFilterArgument(filterArgument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_START })
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/filterargument`, {
        method: 'post',
        body: ObjectUtils.removeByValue(filterArgument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          dispatch({ type: FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.create_filter_argument_success', lang, filterArgument.SLF), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
          // ! DO NOT HIDE LOADING SPINNER WHEN SUCCESS BECAUSE THE TEST NEEDS TO WAIT UNTIL THE REFRESH IS DONE
          // ! REFRESH WILL HIDE LOADING SPINNER WHEN FINISH
          const owner = prefs[Preferences.DEFINITION_FILTERARGUMENT_OWNER]
          const filterID = prefs[Preferences.DEFINITION_FILTERARGUMENT_ID]
          const title = prefs[Preferences.DEFINITION_FILTERARGUMENT_TITLE]
          getFilterArguments(undefined, owner, filterID, title, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.create_filter_argument_error', lang, [filterArgument.SLF, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets a filter argument.
 * @param {String} filterArgument The id of the filter argument.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getFilterArgument(filterArgument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_START })

    const queryParams = []
    if (filterArgument) { queryParams.push(`SLF=${encodeURIComponent(filterArgument)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/filterargument?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.get_filter_argument_error', lang, [filterArgument, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes a filter argument
 * @param {String} filterArgument
 * @param {Function} callback
 */
export function deleteFilterArgument(filterArgument, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/filterargument${createQueryParamsForFetch({ SLF: filterArgument })}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.delete_filter_argument_success', lang, filterArgument), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }
          const owner = prefs[Preferences.DEFINITION_FILTERARGUMENT_OWNER]
          const filterID = prefs[Preferences.DEFINITION_FILTERARGUMENT_ID]
          const title = prefs[Preferences.DEFINITION_FILTERARGUMENT_TITLE]
          getFilterArguments(undefined, owner, filterID, title, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_filter_argument_error', lang, [filterArgument, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a filter argument
 * @param {Object} filterArgument
 * @param {Function} callback
 */
export function updateFilterArgument(filterArgument, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/filterArgument`, {
        method: 'put',
        body: filterArgument
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.update_filter_argument_success', lang, filterArgument['SLF']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          const owner = prefs[Preferences.DEFINITION_FILTERARGUMENT_OWNER]
          const filterID = prefs[Preferences.DEFINITION_FILTERARGUMENT_ID]
          const title = prefs[Preferences.DEFINITION_FILTERARGUMENT_TITLE]
          getFilterArguments(undefined, owner, filterID, title, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.update_filter_argument_error', lang, [filterArgument['SLF'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets a list of filter argument definitions they are referenced in other context.
 * @param {String} filterArgument The filter argument .
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function verifyFilterArgument(filterArgument, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/filterargument/verify`, {
        method: 'post',
        body: { SLF: filterArgument }
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          if (rc.toString() === '0016' && irc.toString() === '0000') {
            SnackbarActions.show(Lang.translate('general.verify_error'), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
          dispatch({ type: FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.verify_filter_argument_error', lang, [filterArgument, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_FAILED, payload: { error } })
      })
  }
}