import store from 'redux/Store'

import * as Config from 'config/Configs'

import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as StandardSelectionActions from 'redux/actions/StandardSelectionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'

import * as DATA from 'components/documentViewer/constants/data'
import { DOCUMENT_VIEWER_URL_BASE } from 'components/documentViewer/constants/routes'

import { translate } from 'language/Language'

import * as Preferences from 'redux/general/Preferences'

import { sendDocumentViewerBroadcastChannelMessage as sendMessage, documentViewerWindowRef, MESSAGE_CLOSE_ORPHAN_DOCVIEWER } from 'utils/BroadcastChannelUtils'
import * as DocViewerUtils from 'utils/DocViewerUtils'
import { restapiRequest } from 'utils/RequestUtils'

export const DOCVIEWER_ADD_TAB_SUCCESS = 'DOCVIEWER_ADD_TAB_SUCCESS'
export const DOCVIEWER_ADD_TAB_START = 'DOCVIEWER_ADD_TAB_START'
export const DOCVIEWER_ADD_TAB_FAIL = 'DOCVIEWER_ADD_TAB_FAIL'

export const DOCVIEWER_ADD_TAB_CONTENT_TEXT_SUCCESS = 'DOCVIEWER_ADD_TAB_CONTENT_TEXT_SUCCESS'
export const DOCVIEWER_ADD_TAB_CONTENT_TEXT_START = 'DOCVIEWER_ADD_TAB_CONTENT_TEXT_START'
export const DOCVIEWER_ADD_TAB_CONTENT_TEXT_FAIL = 'DOCVIEWER_ADD_TAB_CONTENT_TEXT_FAIL'

export const DOCVIEWER_ADD_TAB_CONTENT_HEX_BLOCK_SUCCESS = 'DOCVIEWER_ADD_TAB_CONTENT_HEX_BLOCK_SUCCESS'
export const DOCVIEWER_ADD_TAB_CONTENT_HEX_BLOCK_START = 'DOCVIEWER_ADD_TAB_CONTENT_HEX_BLOCK_START'
export const DOCVIEWER_ADD_TAB_CONTENT_HEX_BLOCK_FAIL = 'DOCVIEWER_ADD_TAB_CONTENT_HEX_BLOCK_FAIL'

export const DOCVIEWER_ADD_TAB_CONTENT_HEX_LINE_SUCCESS = 'DOCVIEWER_ADD_TAB_CONTENT_HEX_LINE_SUCCESS'
export const DOCVIEWER_ADD_TAB_CONTENT_HEX_LINE_START = 'DOCVIEWER_ADD_TAB_CONTENT_HEX_LINE_START'
export const DOCVIEWER_ADD_TAB_CONTENT_HEX_LINE_FAIL = 'DOCVIEWER_ADD_TAB_CONTENT_HEX_LINE_FAIL'

export const DOCVIEWER_GET_PAGE_COUNT_SUCCESS = 'DOCVIEWER_GET_PAGE_COUNT_SUCCESS'
export const DOCVIEWER_GET_PAGE_COUNT_START = 'DOCVIEWER_GET_PAGE_COUNT_START'
export const DOCVIEWER_GET_PAGE_COUNT_FAIL = 'DOCVIEWER_GET_PAGE_COUNT_FAIL'

export const DOCVIEWER_ADD_HIGHLIGHT_INDEX = 'DOCVIEWER_ADD_HIGHLIGHT_INDEX'
export const DOCVIEWER_REMOVE_HIGHLIGHT_INDEX = 'DOCVIEWER_REMOVE_HIGHLIGHT_INDEX'

export const DOCVIEWER_REMOVE_TABS_SUCCESS = 'DOCVIEWER_REMOVE_TABS_SUCCESS'

const USER_SESSION_EXPIRED = '00203499';
const SNACKBAR_DOCUMENT_ADDED = 'documentviewer.document_will_be_opened';
const SNACKBAR_DOCUMENT_ALREADY_OPEN = 'documentviewer.document_already_opened';
const SNACKBAR_INDEX_HIGHLIGHT_REMOVED = 'documentviewer.removed_index_highlight';

export const addTabToDocViewer = (tabData, callback) => {
  return async dispatch => {
    const tabs = store.getState().docviewer.tabs
    const tabAlreadyOpen = tabs.find((tab) => tab.id === tabData.id)

    // 1. Handle data flow:
    if (tabAlreadyOpen && !('indexData' in tabData)) {
      SnackbarActions.show(translate(SNACKBAR_DOCUMENT_ALREADY_OPEN), SnackbarActions.TYPE_INFO)(dispatch)
    }

    if (tabAlreadyOpen && 'indexData' in tabData) {
      const { indexData } = tabData

      dispatch({ type: DOCVIEWER_ADD_HIGHLIGHT_INDEX, payload: { id: tabData.id, indexData: indexData }})
    }

    if (!tabAlreadyOpen && !('indexData' in tabData)) {
      dispatch({ type: DOCVIEWER_ADD_TAB_SUCCESS, payload: { tabDataToBeAdded: tabData }})
      SnackbarActions.show(translate(SNACKBAR_DOCUMENT_ADDED), SnackbarActions.TYPE_INFO)(dispatch)
      callback?.()
    }

    if (!tabAlreadyOpen && 'indexData' in tabData) {
      const { indexData, ...tabDataToBeAdded } = tabData

      SnackbarActions.show(translate(SNACKBAR_DOCUMENT_ADDED), SnackbarActions.TYPE_INFO)(dispatch)

      dispatch({ type: DOCVIEWER_ADD_TAB_SUCCESS, payload: { tabDataToBeAdded: tabDataToBeAdded }})
      dispatch({ type: DOCVIEWER_ADD_HIGHLIGHT_INDEX, payload: { id: tabDataToBeAdded.id, indexData: indexData }})
    }

    // 2. Handle tab logic:
    if (!documentViewerWindowRef.window || (documentViewerWindowRef.window && documentViewerWindowRef.window.closed)) {
      sendMessage(MESSAGE_CLOSE_ORPHAN_DOCVIEWER)

      documentViewerWindowRef.window = window.open(window.location.origin + DOCUMENT_VIEWER_URL_BASE, '_blank')
      documentViewerWindowRef.window.focus()
    } else {
      documentViewerWindowRef.window.focus()
    }
  }
}

export const getPageContentText = (tabId, pageNumberToLoad, callback) => {
  return dispatch => {
    const tabData = store.getState().docviewer.tabs.find((tab) => tab.id === tabId)
    const url = DocViewerUtils.parseUrlFetchPageContentText(tabData, pageNumberToLoad)
    const encoding = tabData.data[tabData.header.findIndex((h) => h === 'TEXTENCO')]

    dispatch({ type: DOCVIEWER_ADD_TAB_CONTENT_TEXT_START, payload: { id: tabData.id } })

    // 1. Create cancelable request
    const fetchOptions = {
      method: 'get',
      headers: {
        token: undefined
      }
    }
    const cancelablePromise = FetchTimeout.makeCancelable(restapiRequest(url, fetchOptions), Config.CONNECT_TIMEOUT)

    // 2. Set timeout to cancel request
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    // 3. Execute request
    return cancelablePromise
      .promise
      .then((data) => {
        if (data.bodyUsed) {
          throw new Error(USER_SESSION_EXPIRED)
        }
        if (data.status !== 200) {
          return data.json().then((json) => ({ status: 400, data: json })) // Error object expected as JSON stream
        }
        const decoder = new TextDecoder(encoding)
        return data.arrayBuffer().then((data) => ({ status: 200, data: [decoder.decode(data)] }))
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: DOCVIEWER_ADD_TAB_CONTENT_TEXT_SUCCESS, payload: { id: tabData.id, pagesText: { data: res.data } } })
          if (callback) {
            callback()
          }
        } else {
          /* //! WIP: Consolidate into catch block */
          const error = GeneralErrorHandler.handleResponse(res.data.error.rc, res.data.error.irc, res.data.error.param, dispatch)
          SnackbarActions.show(error.message, SnackbarActions.TYPE_ERROR)(dispatch)

          dispatch({ type: DOCVIEWER_ADD_TAB_CONTENT_TEXT_FAIL, payload: { id: tabData.id, error: res.data } })
        }
      })
      .catch((error) => {
        if (error.message === USER_SESSION_EXPIRED) {
          SnackbarActions.show(translate('documentviewer.session_expired'), SnackbarActions.TYPE_ERROR)(dispatch)
        } else {
          const reason = error.isCanceled ? translate('general.fetch_data_timeout') : error.toString()
          SnackbarActions.show(translate('documents.documents_error', undefined, ['', reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        }

        dispatch({ type: DOCVIEWER_ADD_TAB_CONTENT_TEXT_FAIL, payload: { id: tabData.id, error: error } })
      })
  }
}

export const getPageContentHex = ({ tabId, pageNumberToLoad, viewMode }, callback) => {
  return dispatch => {
    const hexMode = viewMode.includes('hex_block') ? DATA.DOC_VIEWER_HEX_MODE_BLOCK : DATA.DOC_VIEWER_HEX_MODE_LINE;
    const showPageHeader = viewMode.includes('_header') ? DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES : DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO;
    const showResources = viewMode.includes('_resources') ? DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES : DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO;

    const url = DocViewerUtils.parseUrlFetchPageContentHex({ tabId, hexMode, pageNumberToLoad, showPageHeader, showResources })

    if (!url) {
      SnackbarActions.show('Fetching HEX data for a job is not supported yet', SnackbarActions.TYPE_INFO)(dispatch)
      return;
    }

    dispatch({ type: `DOCVIEWER_ADD_TAB_CONTENT_HEX_${hexMode}_START`, payload: { id: tabId } })

    // 1. Create cancelable request
    const fetchOptions = {
      method: 'get',
      headers: {
        token: undefined
      }
    }
    const cancelablePromise = FetchTimeout.makeCancelable(restapiRequest(url, fetchOptions), Config.CONNECT_TIMEOUT)

    // 2. Set timeout to cancel request
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    // 3. Execute request
    return cancelablePromise
      .promise
      .then((data) => {
        if (data.bodyUsed) {
          throw new Error(USER_SESSION_EXPIRED)
        }
        if (data.status !== 200) {
          return data.json().then((json) => ({ status: 400, data: json })) // Error object expected as JSON stream
        }
        const decoder = new TextDecoder('iso-8859-1')
        return data.arrayBuffer().then((data) => ({ status: 200, data: [decoder.decode(data)] }))
      })
      .then((res) => {
        if (res.status === 200) {
          const hexModeKey = hexMode === DATA.DOC_VIEWER_HEX_MODE_BLOCK ? 'pagesHexBlock' : 'pagesHexLine'
          const currentHexPages = (store.getState().docviewer.tabs.find((tab) => tab.id === tabId))[hexModeKey];

          // Note: Create a new entry based on a unique key, which holds the information about ID, hex configuration and page number:
          dispatch({ type: `DOCVIEWER_ADD_TAB_CONTENT_HEX_${hexMode}_SUCCESS`, payload: { id: tabId, [hexModeKey]: { ...currentHexPages, [`${tabId}_${viewMode}_${pageNumberToLoad}`]: res.data[0] } } })

          if (callback) {
            callback()
          }
        } else {
          /* //! WIP: Consolidate into catch block */
          const error = GeneralErrorHandler.handleResponse(res.data.error.rc, res.data.error.irc, res.data.error.param, dispatch)
          SnackbarActions.show(error.message, SnackbarActions.TYPE_ERROR)(dispatch)

          dispatch({ type: `DOCVIEWER_ADD_TAB_CONTENT_HEX_${hexMode}_FAIL`, payload: { id: tabId, error: res.data } })
        }
      })
      .catch((error) => {
        if (error.message === USER_SESSION_EXPIRED) {
          SnackbarActions.show(translate('documentviewer.session_expired'), SnackbarActions.TYPE_ERROR)(dispatch)
        } else {
          const reason = error.isCanceled ? translate('general.fetch_data_timeout') : error.toString()
          SnackbarActions.show(translate('documents.documents_error', undefined, ['', reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        }

        dispatch({ type: `DOCVIEWER_ADD_TAB_CONTENT_HEX_${hexMode}_FAIL`, payload: { id: tabId, error: error } })
      })
  }
}

export const getJobDetails = async (queryParameters, dispatch) => {
  try {
    const parameters = Object.entries(queryParameters).map(([key, value]) => `${key}=${encodeURIComponent(value)}`);

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/job?${parameters.join('&')}`, { method: 'get' })
    )

    setTimeout(() => { cancelablePromise.cancel() }, Config.FETCH_DATA_TIMEOUT)

    const response = await cancelablePromise.promise;
    const data = await response.json();

    if (!data.success) {
      const error = GeneralErrorHandler.handleResponse(data.error.rc, data.error.irc, data.error.param, dispatch)

      // Case: Expected error
      return { result: null, error: error.message }
    }

    // Case: Success
    return { result: data.data, error: null }
  } catch (error) {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const reason = error.isCanceled ? translate('general.fetch_data_timeout') : error.toString()

    // Case: Unexpected error
    return { result: null, error: translate('documentviewer.unexpected_error', lang, reason)}
  }
}

export const getJobDetailsQueryParameters = (tabData) => {
  if (tabData.type === DocViewerUtils.SEARCH_TYPE_ZOS_ALL) {
    return {
      LOGSOURCE: 'ZOS',
      SRCSUBD: tabData.data[(tabData.header.findIndex((h) => h === 'SRCSUBD'))],
      SRCSUBT: tabData.data[(tabData.header.findIndex((h) => h === 'SRCSUBT'))],
      JOBNAME: tabData.data[(tabData.header.findIndex((h) => h === 'JOBNAME'))],
      JOBID: tabData.data[(tabData.header.findIndex((h) => h === 'JOBID'))]
    }
  }

  if (tabData.type === DocViewerUtils.SEARCH_TYPE_CONTROLM_ALL) {
    return {
      LOGSOURCE: tabData.data[(tabData.header.findIndex((h) => h === 'LOGSRC'))],
      ORDERDATE: tabData.data[(tabData.header.findIndex((h) => h === 'ORDDATE'))],
      ORDERID: tabData.data[(tabData.header.findIndex((h) => h === 'ORDERID'))],
      RUNCOUNT: tabData.data[(tabData.header.findIndex((h) => h === 'RUNCOUNT'))],
      DATACENTER: tabData.data[(tabData.header.findIndex((h) => h === 'DATACENT'))]
    }
  }

  if (tabData.type === DocViewerUtils.SEARCH_TYPE_STONEBRANCH_ALL) {
    return {
      UKEY: tabData.data[(tabData.header.findIndex((h) => h === 'UKEY'))],
    }
  }

  if (tabData.type === DocViewerUtils.SEARCH_TYPE_SYSLOG_ALL) {
    return {
      LOGSOURCE: 'SYSL',
      SRCSUBD: tabData.data[(tabData.header.findIndex((h) => h === 'SRCSUBD'))],
      SRCSUBT: tabData.data[(tabData.header.findIndex((h) => h === 'SRCSUBT'))],
      JOBNAME: tabData.data[(tabData.header.findIndex((h) => h === 'JOBNAME'))],
      JOBID: tabData.data[(tabData.header.findIndex((h) => h === 'JOBID'))]
    }
  }
}

export const getPageCount = (tabData) => {
  return async dispatch => {
    const queryParameters = getJobDetailsQueryParameters(tabData)

    dispatch({ type: DOCVIEWER_GET_PAGE_COUNT_START })

    const { result, error } = await getJobDetails(queryParameters, dispatch)

    if (error) {
      dispatch({ type: DOCVIEWER_GET_PAGE_COUNT_FAIL, payload: { error } })
      SnackbarActions.show(error, SnackbarActions.TYPE_ERROR)(dispatch)
    }

    if (result) {
      dispatch({ type: DOCVIEWER_GET_PAGE_COUNT_SUCCESS, payload: { id: tabData.id, pageCount: result?.SRCPAGES ?? 0 } })
    }
  }
}

export const loadDataForPrintModal = (docId, callback) => {
  return dispatch => {
    StandardSelectionActions.getDocument(undefined, docId, '', () => {
      StandardSelectionActions.getPrintInfo(docId, callback)(dispatch)
    })(dispatch)
  }
}

export const removeDocumentsFromDocViewer = (tabIds, callback) => {
  return dispatch => {
    dispatch({ type: DOCVIEWER_REMOVE_TABS_SUCCESS, payload: { tabIdsToBeRemoved: tabIds } })

    if (callback) {
      //* TBC if needed
    }
  }
}

export const removeIndexHighlight = (tabId) => {
  return dispatch => {
    dispatch({ type: DOCVIEWER_REMOVE_HIGHLIGHT_INDEX, payload: { id: tabId }})
    SnackbarActions.show(translate(SNACKBAR_INDEX_HIGHLIGHT_REMOVED), SnackbarActions.TYPE_SUCCESS)(dispatch)
  }
}