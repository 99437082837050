import * as RemoteUserActions from '../actions/RemoteUserActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  remoteUser: undefined,
  remoteUsers: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Create remote user actions
    case RemoteUserActions.CREATE_REMOTE_USER_START:
      return { ...state }
    case RemoteUserActions.CREATE_REMOTE_USER_SUCCESS:
      return { ...state }
    case RemoteUserActions.CREATE_REMOTE_USER_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get remote user actions
    case RemoteUserActions.GET_REMOTE_USER_START:
      return { ...state, remoteUser: undefined }
    case RemoteUserActions.GET_REMOTE_USER_SUCCESS:
      return { ...state, remoteUser: action.payload }
    case RemoteUserActions.GET_REMOTE_USER_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Delete remote user actions
    case RemoteUserActions.DELETE_REMOTE_USER_START:
      return { ...state }
    case RemoteUserActions.DELETE_REMOTE_USER_SUCCESS:
      return { ...state }
    case RemoteUserActions.DELETE_REMOTE_USER_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Update remote user actions
    case RemoteUserActions.UPDATE_REMOTE_USER_START:
      return { ...state }
    case RemoteUserActions.UPDATE_REMOTE_USER_SUCCESS:
      return { ...state }
    case RemoteUserActions.UPDATE_REMOTE_USER_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get remote users actions
    case RemoteUserActions.GET_REMOTE_USERS_START:
      return { ...state }
    case RemoteUserActions.GET_REMOTE_USERS_SUCCESS:
      return { ...state, remoteUsers: action.payload, keepPagination: action.keepPagination }
    case RemoteUserActions.GET_REMOTE_USERS_FAILED:
      return { ...state }
    case RemoteUserActions.NO_REMOTE_USERS_FOUND:
      return { ...state, remoteUsers: {} }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, remoteUsers: undefined, remoteUser: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer