import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { getPageContentText, getPageContentHex } from 'redux/actions/DocViewerActions';

import * as DATA from '../constants/data';

import { DocumentBrowserContext } from '../contexts/DocumentBrowserContext';
import { useTabContentContext } from '../contexts/TabContentContext';

import * as TYPES_DOC_VIEWER from '../types/DocumentViewer.types';

const NUM_PAGE_INITIAL = 1;

export const DocumentBrowserProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const dispatch = useDispatch();

  const { id, pageNumber, viewMode } = useTabContentContext();

  const tab: TYPES_DOC_VIEWER.ITabData = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id));

  const [inputValue, setInputValue] = useState(NUM_PAGE_INITIAL.toString());

  /**
   * @description This function will update the input field of the page navigation before the user confirms this value either by pressing 'Enter' or by unfocusing (see handlePageChange).
   * @param newInputValue New value of input field
   */
  const handleInputChange = (newInputValue: string) => {
    // This conditional allows for an empty input field when the user types a page number, but will catch invalid user inputs (e.g. mix of numbers and characters)
    const isInvalidInputValue = isNaN(parseInt(newInputValue)) && newInputValue.length !== 0;

    setInputValue(isInvalidInputValue ? inputValue : newInputValue);
  };

  /**
   * @description This function will update the the input value according to the new page number.
   * @param newPageNumber New page number to use as index for rendering page contents (see DocumentBrowserPageArea).
   * @param pageCount
   */
  const syncInputValueAndPageNumber = (newPageNumber: number, pageCount: number) => {
    const isValidPageNumberAndInputUpdateNeeded = newPageNumber !== parseInt(inputValue) && newPageNumber >= 1 && newPageNumber <= pageCount;

    setInputValue(isValidPageNumberAndInputUpdateNeeded ? newPageNumber.toString() : inputValue); // Syncing of inputValue and pageNumber when user confirms either by pressing 'Enter' or by unfocusing
  }

  useEffect(() => {
    syncInputValueAndPageNumber(pageNumber, tab.pageCount);

    switch (viewMode) {
      // Mode: Text view:
      case DATA.TAB_CONFIG_VIEW_MODE_TEXT: getPageContentText(id, pageNumber)(dispatch); break;
      // Mode: HEX block view:
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK: getPageContentHex({ tabId: id, pageNumberToLoad: pageNumber, viewMode: viewMode })(dispatch); break;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER: getPageContentHex({ tabId: id, pageNumberToLoad: pageNumber, viewMode: viewMode })(dispatch); break;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER_RESOURCES: getPageContentHex({ tabId: id, pageNumberToLoad: pageNumber, viewMode: viewMode })(dispatch); break;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_RESOURCES: getPageContentHex({ tabId: id, pageNumberToLoad: pageNumber, viewMode: viewMode })(dispatch); break;
      // Mode: HEX line view:
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE: getPageContentHex({ tabId: id, pageNumberToLoad: pageNumber, viewMode: viewMode })(dispatch); break;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER: getPageContentHex({ tabId: id, pageNumberToLoad: pageNumber, viewMode: viewMode })(dispatch); break;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER_RESOURCES: getPageContentHex({ tabId: id, pageNumberToLoad: pageNumber, viewMode: viewMode })(dispatch); break;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_RESOURCES: getPageContentHex({ tabId: id, pageNumberToLoad: pageNumber, viewMode: viewMode })(dispatch); break;
    }
    // Note: id, dispatch are static values -> ESLint assumes dynamic values and shows warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, viewMode]);

  return (
    <DocumentBrowserContext.Provider value={{
      handleInputChange,
      id,
      inputValue,
    }}>
      {children}
    </DocumentBrowserContext.Provider>
  )
};