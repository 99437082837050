import React from 'react';
import { prepareClassNames } from '../../utils/ComponentUtils';
import {ModalCommonProps} from './Modal.types';

export interface MainProps extends ModalCommonProps {
  flexColumn?: boolean;
}

export const Main = ({ id, className, children, flexColumn }: MainProps): JSX.Element => {
  const classNames = prepareClassNames([
    className,
    flexColumn && 'cl_flexColumn']);
  return (
    <main
      id={`${id}`}
      tabIndex={-1}
      className={classNames}
    >
      {children}
    </main>
  );
};