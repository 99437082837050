import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import * as ObjectUtils from 'utils/ObjectUtils'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const INDEX_DEFINITION_GET_INDEXES_START = 'INDEX_DEFINITION_GET_INDEXES_START'
export const INDEX_DEFINITION_GET_INDEXES_SUCCESS = 'INDEX_DEFINITION_GET_INDEXES_SUCCESS'
export const INDEX_DEFINITION_GET_INDEXES_FAILED = 'INDEX_DEFINITION_GET_INDEXES_FAILED'
export const NO_INDEX_DEFINITION_INDEXES_FOUND = 'NO_INDEX_DEFINITION_INDEXES_FOUND'

export const INDEX_DEFINITION_GET_INDEX_START = 'INDEX_DEFINITION_GET_INDEX_START'
export const INDEX_DEFINITION_GET_INDEX_SUCCESS = 'INDEX_DEFINITION_GET_INDEX_SUCCESS'
export const INDEX_DEFINITION_GET_INDEX_FAILED = 'INDEX_DEFINITION_GET_INDEX_FAILED'

export const INDEX_DEFINITION_DELETE_INDEX_START = 'INDEX_DEFINITION_DELETE_INDEX_START'
export const INDEX_DEFINITION_DELETE_INDEX_SUCCESS = 'INDEX_DEFINITION_DELETE_INDEX_SUCCESS'
export const INDEX_DEFINITION_DELETE_INDEX_FAILED = 'INDEX_DEFINITION_DELETE_INDEX_FAILED'

export const INDEX_DEFINITION_CREATE_INDEX_START = 'INDEX_DEFINITION_CREATE_INDEX_START'
export const INDEX_DEFINITION_CREATE_INDEX_SUCCESS = 'INDEX_DEFINITION_CREATE_INDEX_SUCCESS'
export const INDEX_DEFINITION_CREATE_INDEX_FAILED = 'INDEX_DEFINITION_CREATE_INDEX_FAILED'

export const INDEX_DEFINITION_UPDATE_INDEX_START = 'INDEX_DEFINITION_UPDATE_INDEX_START'
export const INDEX_DEFINITION_UPDATE_INDEX_SUCCESS = 'INDEX_DEFINITION_UPDATE_INDEX_SUCCESS'
export const INDEX_DEFINITION_UPDATE_INDEX_FAILED = 'INDEX_DEFINITION_UPDATE_INDEX_FAILED'

export const INDEX_DEFINITION_VERIFY_START = 'INDEX_DEFINITION_VERIFY_START'
export const INDEX_DEFINITION_VERIFY_SUCCESS = 'INDEX_DEFINITION_VERIFY_SUCCESS'
export const INDEX_DEFINITION_VERIFY_FAILED = 'INDEX_DEFINITION_VERIFY_FAILED'

/**
 * @description Calls the rest api and creates an index definition.
 * @param {Object} indexDefinition The index definition. Possible fields:
 * IXINAME (required): The index name.
 * IXMAXLEN: The maximal length of the index.
 * IXENAME: The index external name.
 * IXDTYPE: The index type.
 * OWNER: The owner.
 * CUSER: The control user.
 * CDATE: The last modified date.
 * CTIME: The last modified time.
 * IXCASE: Flag to convert the index to upper case.
 * IXNPLACE: The number of decimal places.
 * IXBLANK: Blanks between words (ONE, NONE, ASIS)
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function createIndex(indexDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: INDEX_DEFINITION_CREATE_INDEX_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/index`, {
        method: 'post',
        body: ObjectUtils.removeByValue(indexDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: INDEX_DEFINITION_CREATE_INDEX_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: INDEX_DEFINITION_CREATE_INDEX_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.create_index_success', lang, indexDefinition.IXINAME), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getIndexes again to get the current result
          const index = prefs[Preferences.DEFINITION_INDEX_ID]
          const identifier = prefs[Preferences.DEFINITION_INDEX_IDENTIFIER]
          const dataFormat = prefs[Preferences.DEFINITION_INDEX_DATA_FORMAT]
          const owner = prefs[Preferences.DEFINITION_INDEX_OWNER]
          // ! DO NOT HIDE LOADING SPINNER WHEN SUCCESS BECAUSE THE TEST NEEDS TO WAIT UNTIL THE REFRESH IS DONE
          // ! REFRESH WILL HIDE LOADING SPINNER WHEN FINISH
          getIndexes(undefined, index, identifier, dataFormat, owner, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.create_index_error', lang, [indexDefinition.IXINAME, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: INDEX_DEFINITION_CREATE_INDEX_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getIndexes request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'IXINAME': The index name,
 * 'IXENAME': The index external name,
 * 'IXDTYPE': The index type,
 * 'OWNER': The owner of the index definition,
 * 'CDATE': The creation date,
 * 'CTIME': The creation time,
 * 'CUSER': The creator of the index definition
 * @param {String} index The index name.
 * @param {String} identifier The index external name.
 * @param {String} dataFormat The index type.
 * @param {String} owner The owner of the index definition.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getIndexes(fields, index, identifier, dataFormat, owner, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: INDEX_DEFINITION_GET_INDEXES_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (index) { queryParams.push(`IXINAME=${encodeURIComponent(index)}`) }
    if (identifier) { queryParams.push(`IXENAME=${encodeURIComponent(identifier)}`) }
    if (dataFormat) { queryParams.push(`IXDTYPE=${encodeURIComponent(dataFormat)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/indexes?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_INDEX_DEFINITION_INDEXES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: INDEX_DEFINITION_GET_INDEXES_FAILED, payload: { error } })
          }
        } else {
          dispatch({ type: INDEX_DEFINITION_GET_INDEXES_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.indexes_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: INDEX_DEFINITION_GET_INDEXES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets an index.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'PCR', 'OWNER', 'PCRTITLE', 'PCROUTP', 'CDATE', 'CTIME', 'CUSER'
 * @param {String} outputFormat The id of the output format.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getIndex(fields, index, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: INDEX_DEFINITION_GET_INDEX_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (index) { queryParams.push(`IXINAME=${encodeURIComponent(index)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/index?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: INDEX_DEFINITION_GET_INDEX_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: INDEX_DEFINITION_GET_INDEX_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.get_index_error', lang, [index, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: INDEX_DEFINITION_GET_INDEX_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes an index.
 * @param {String} index The index definition which should be deleted.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function deleteIndex(index, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: INDEX_DEFINITION_DELETE_INDEX_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/index${createQueryParamsForFetch({ IXINAME: index })}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          dispatch({ type: INDEX_DEFINITION_DELETE_INDEX_FAILED, payload: { error } })
        } else {
          dispatch({ type: INDEX_DEFINITION_DELETE_INDEX_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.delete_index_success', lang, index), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          // call getIndexes again to get the current result
          const indexID = prefs[Preferences.DEFINITION_INDEX_ID]
          const identifier = prefs[Preferences.DEFINITION_INDEX_IDENTIFIER]
          const dataFormat = prefs[Preferences.DEFINITION_INDEX_DATA_FORMAT]
          const owner = prefs[Preferences.DEFINITION_INDEX_OWNER]

          getIndexes(undefined, indexID, identifier, dataFormat, owner, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.delete_index_error', lang, [index, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: INDEX_DEFINITION_DELETE_INDEX_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates an index definition.
 * @param {Object} indexDefinition The index definition. Possible fields:
 * IXINAME (required): The index name.
 * IXMAXLEN: The maximal length of the index.
 * IXENAME: The index external name.
 * IXDTYPE: The index type.
 * OWNER: The owner.
 * CUSER: The control user.
 * CDATE: The last modified date.
 * CTIME: The last modified time.
 * IXCASE: Flag to convert the index to upper case.
 * IXNPLACE: The number of decimal places.
 * IXBLANK: Blanks between words (ONE, NONE, ASIS)
 */
export function updateIndex(indexDefinition, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: INDEX_DEFINITION_UPDATE_INDEX_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/index`, {
        method: 'put',
        body: indexDefinition
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: INDEX_DEFINITION_UPDATE_INDEX_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: INDEX_DEFINITION_UPDATE_INDEX_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.update_index_success', lang, indexDefinition['IXINAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getIndexes again to get the current result
          const indexID = prefs[Preferences.DEFINITION_INDEX_ID]
          const identifier = prefs[Preferences.DEFINITION_INDEX_IDENTIFIER]
          const dataFormat = prefs[Preferences.DEFINITION_INDEX_DATA_FORMAT]
          const owner = prefs[Preferences.DEFINITION_INDEX_OWNER]

          getIndexes(undefined, indexID, identifier, dataFormat, owner, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.update_index_error', lang, [indexDefinition['IXINAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: INDEX_DEFINITION_UPDATE_INDEX_FAILED, payload: { error } })
      })
  }
}

export function verifyIndex(indexID, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: INDEX_DEFINITION_VERIFY_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/index/verify`, {
        method: 'post',
        body: { IXINAME: indexID }
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          if (rc.toString() === '0016' && irc.toString() === '0000') {
            SnackbarActions.show(Lang.translate('general.verify_error'), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
          dispatch({ type: INDEX_DEFINITION_VERIFY_FAILED, payload: { error } })
        } else {
          dispatch({ type: INDEX_DEFINITION_VERIFY_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.verify_index_error', lang, [indexID, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: INDEX_DEFINITION_VERIFY_FAILED, payload: { error } })
      })

  }
}