import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'

import SearchResultDatabaseStatus from './status/SearchResultDatabaseStatus'
import SearchResultDatabaseTable from './tables/SearchResultDatabaseTable'
import SearchResultDatabaseQuery from './query/SearchResultDatabaseQuery'

class SearchResultDatabase extends Component {

  /**
   * @description Gets the body which should be rendered.
   */
  getBody() {
    const { match, id, drawerExpanded, autoDrawer } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    switch (url) {
      // status search result
      case 'status':
        return (
          <SearchResultDatabaseStatus
            id={`${id}_status`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'table':
        return (
          <SearchResultDatabaseTable
            id={`${id}_table`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'query':
        return (
          <SearchResultDatabaseQuery
            id={`${id}_query`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      // fallback is database status
      default:
        return (
          // take care of the route because if you enter a wrong route you can create an infinite loop!
          <Redirect to='/database/status' />
        )
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

SearchResultDatabase.propTypes = {
  id: PropTypes.string.isRequired,
  match: PropTypes.any.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

export default SearchResultDatabase