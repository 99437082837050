import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'

// components
import {
  Button, Card, Column, Dropdown, MetaDataGrid, Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as OutputChannelDefinitionActions from 'redux/actions/OutputChannelDefinitionActions'

import './SnmpStatusDialog.scss'

const ALL = 'ALL'
const INFO = 'INFO'
const MARK = 'MARK'
const STATE = 'STATE'

const SNMP_STATUS_ACTION_ITEMS = [
  { key: ALL, translationKey: 'general.all' },
  { key: INFO, translationKey: 'general.info' },
  { key: MARK, translationKey: 'general.mark' },
  { key: STATE, translationKey: 'general.state' }
]

class SnmpStatusDialog extends Component {
  state = {
    snpmStatusActionIndex: 0
  }

  snmpStatusActionInput = React.createRef()

  /**
   * @description Sets the initial focus.
   */
  componentDidMount = () => {
    // this.snmpStatusActionInput.current.focus()
  }

  /**
   * @description Handles the changes values without error messages.
   * @param {String} id The id the field.
   * @param {String} value The new value.
   */
  handleChange = (id, value) => {
    this.setState({
      [id]: value
    })
  }

  getData = () => {
    const { requestResult } = this.props

    if (!requestResult || !requestResult.header || !requestResult.data) {
      return <></>
    } else {
      let text = []

      // add header
      text.push(
        <Row noMargin>
          <Column colMD={12}>
            {requestResult.header[0]}
          </Column>
        </Row>
      )

      // add data
      requestResult.data.forEach(el => {
        text.push(
          <Row noMargin={el[0] !== ''}>
            <Column colMD={12}>
              {el[0]}
            </Column>
          </Row>
        )
      })

      return text
    }
  }

  /**
   * @description Renders the header.
   */
  renderHeader = () => {
    const { id, outputChannel } = this.props

    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.output_channel_id'), value: outputChannel.DCR },
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description Renders the main content.
   */
  renderMain = () => {
    const { id, executeMonitorRequest, outputChannel } = this.props
    const { snpmStatusActionIndex } = this.state

    return (
      <Card className={'bux_full_height'}>
        <Row>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_snmp_status_action`}
              title={translate('general.snmp_status_action')}
              items={SNMP_STATUS_ACTION_ITEMS.map(d => translate(d.translationKey))}
              activeIndex={snpmStatusActionIndex}
              onChange={index => this.handleChange('snpmStatusActionIndex', index)}
            />
          </Column>
          <Column offsetMD={6} colMD={3}>
            <Button
              id={`${id}_check_state_btn`}
              title={' '}
              text={translate('general.check_status')}
              primary
              containerClass={'bux_flex'}
              className={'bux_align_right'}
              onClick={() => executeMonitorRequest(outputChannel.DCR, SNMP_STATUS_ACTION_ITEMS[snpmStatusActionIndex].key) }
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr/>
          </Column>
        </Row>
        <Row className={'bux_full_height bux_flex'}>
          <Column colMD={12} className={'bux_flex'}>
            <div className={'bux_snmp_status_result'}>
              <span id={`${id}_request_result_title`}>{translate('queues.output_queue_ipp_request_result')}</span>
              <div id={`${id}_request_result`}>
                {this.getData()}
              </div>
            </div>
          </Column>
        </Row>
      </Card>
    )
  }

  render = () => {
    const { id, onClose } = this.props

    return (
      <Modal
        onClose={onClose}
        id={id}>
        <Header
          id={id}
          title={translate('general.snmp_status')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main id={id}>
          {this.renderMain()}
        </Main>
        <Footer id={id}>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

SnmpStatusDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    outputChannel: state.definitions.outputchannels.outputChannel,
    requestResult: state.definitions.outputchannels.requestResult
  }
}

const mapDispatchToProps = dispatch => {
  return {
    executeMonitorRequest: (outputChannel, state) => {
      OutputChannelDefinitionActions.executeMonitorRequest(outputChannel, 'PRT', state)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnmpStatusDialog)