import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const FOLDER_DEFINITION_GET_FOLDERS_START = 'FOLDER_DEFINITION_GET_FOLDERS_START'
export const FOLDER_DEFINITION_GET_FOLDERS_SUCCESS = 'FOLDER_DEFINITION_GET_FOLDERS_SUCCESS'
export const FOLDER_DEFINITION_GET_FOLDERS_FAILED = 'FOLDER_DEFINITION_GET_FOLDERS_FAILED'

export const FOLDER_DEFINITION_GET_FOLDER_START = 'FOLDER_DEFINITION_GET_FOLDER_START'
export const FOLDER_DEFINITION_GET_FOLDER_SUCCESS = 'FOLDER_DEFINITION_GET_FOLDER_SUCCESS'
export const FOLDER_DEFINITION_GET_FOLDER_FAILED = 'FOLDER_DEFINITION_GET_FOLDER_FAILED'

export const FOLDER_DEFINITION_CREATE_FOLDER_START = 'FOLDER_DEFINITION_CREATE_FOLDER_START'
export const FOLDER_DEFINITION_CREATE_FOLDER_SUCCESS = 'FOLDER_DEFINITION_CREATE_FOLDER_SUCCESS'
export const FOLDER_DEFINITION_CREATE_FOLDER_FAILED = 'FOLDER_DEFINITION_CREATE_FOLDER_FAILED'

export const FOLDER_DEFINITION_DELETE_FOLDER_START = 'FOLDER_DEFINITION_DELETE_FOLDER_START'
export const FOLDER_DEFINITION_DELETE_FOLDER_SUCCESS = 'FOLDER_DEFINITION_DELETE_FOLDER_SUCCESS'
export const FOLDER_DEFINITION_DELETE_FOLDER_FAILED = 'FOLDER_DEFINITION_DELETE_FOLDER_FAILED'

export const FOLDER_DEFINITION_UPDATE_FOLDER_START = 'FOLDER_DEFINITION_UPDATE_FOLDER_START'
export const FOLDER_DEFINITION_UPDATE_FOLDER_SUCCESS = 'FOLDER_DEFINITION_UPDATE_FOLDER_SUCCESS'
export const FOLDER_DEFINITION_UPDATE_FOLDER_FAILED = 'FOLDER_DEFINITION_UPDATE_FOLDER_FAILED'

export const FOLDER_DEFINITION_CLONE_FOLDER_START = 'FOLDER_DEFINITION_CLONE_FOLDER_START'
export const FOLDER_DEFINITION_CLONE_FOLDER_SUCCESS = 'FOLDER_DEFINITION_CLONE_FOLDER_SUCCESS'
export const FOLDER_DEFINITION_CLONE_FOLDER_FAILED = 'FOLDER_DEFINITION_CLONE_FOLDER_FAILED'

export const NO_FOLDER_DEFINITION_FOLDERS_FOUND = 'NO_FOLDER_DEFINITION_FOLDERS_FOUND'

/**
 * @description Calls the rest api and gets a folder definition.
 * @param {String} folder The folder name.
 * @param {Function} callback The callback which will be called when the get folder request was successful. Can be null.
 */
export function getFolder(folder, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: FOLDER_DEFINITION_GET_FOLDER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folder?GLRNAME=${encodeURIComponent(folder)}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FOLDER_DEFINITION_GET_FOLDER_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: FOLDER_DEFINITION_GET_FOLDER_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.get_folder_error', lang, [folder, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DEFINITION_GET_FOLDER_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a folder definition.
 * @param {Object} folderDefinition The folderdefinition. Possible fields:
 * 'GLRNAME', 'OWNER', 'GLRTITLE', 'CUSER'
 * @param {Function} callback The callback which will be called when the create folder request was successful. Can be null.
 */
export function createFolder(folderDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: FOLDER_DEFINITION_CREATE_FOLDER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folder`, {
        method: 'post',
        body: ObjectUtils.removeByValue(folderDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FOLDER_DEFINITION_CREATE_FOLDER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: FOLDER_DEFINITION_CREATE_FOLDER_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.create_folder_success', lang, folderDefinition['GLRNAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getFolders again to get the current result
          const fields = undefined
          const foldername = prefs[Preferences.DEFINITION_FOLDER_ID]
          const foldertitle = prefs[Preferences.DEFINITION_FOLDER_TITLE]
          const owner = prefs[Preferences.DEFINITION_FOLDER_OWNER]
          const brw = prefs[Preferences.DEFINITION_FOLDER_BRW]
          getFolders(fields, foldername, foldertitle, owner, brw, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.create_folder_error', lang, [folderDefinition['GLRNAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DEFINITION_CREATE_FOLDER_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes a folder definition.
 * @param {String} foldername The folder name.
 * @param {Function} callback The callback which will be called when the delete folder definition request was successful. Can be null.
 */
export function deleteFolder(foldername, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: FOLDER_DEFINITION_DELETE_FOLDER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folder${createQueryParamsForFetch({ GLRNAME: foldername })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FOLDER_DEFINITION_DELETE_FOLDER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: FOLDER_DEFINITION_DELETE_FOLDER_SUCCESS })

          SnackbarActions.show(Lang.translate('definition.delete_folder_success', lang, foldername), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getFolders again to get the current result
          const fields = undefined
          const searchFoldername = prefs[Preferences.DEFINITION_FOLDER_ID]
          const foldertitle = prefs[Preferences.DEFINITION_FOLDER_TITLE]
          const owner = prefs[Preferences.DEFINITION_FOLDER_OWNER]
          const brw = prefs[Preferences.DEFINITION_FOLDER_BRW]
          getFolders(fields, searchFoldername, foldertitle, owner, brw, undefined, true)(dispatch)
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.delete_folder_error', lang, [foldername, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DEFINITION_DELETE_FOLDER_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and updates a folder definition.
 * @param {Object} folderDefinition The folderdefinition. Possible fields:
 * 'GLRNAME', 'OWNER', 'GLRTITLE'
 * @param {Function} callback The callback which will be called when the create folder request was successful. Can be null.
 */
export function updateFolder(folderDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: FOLDER_DEFINITION_UPDATE_FOLDER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folder`, {
        method: 'put',
        body: {
          GLRNAME: folderDefinition['GLRNAME'],
          OWNER: folderDefinition['OWNER'],
          GLRTITLE: folderDefinition['GLRTITLE'],
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FOLDER_DEFINITION_UPDATE_FOLDER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: FOLDER_DEFINITION_UPDATE_FOLDER_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.update_folder_success', lang, folderDefinition['GLRNAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getFolders again to get the current result
          const fields = undefined
          const foldername = prefs[Preferences.DEFINITION_FOLDER_ID]
          const foldertitle = prefs[Preferences.DEFINITION_FOLDER_TITLE]
          const owner = prefs[Preferences.DEFINITION_FOLDER_OWNER]
          const brw = prefs[Preferences.DEFINITION_FOLDER_BRW]
          getFolders(fields, foldername, foldertitle, owner, brw, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.update_folder_error', lang, [folderDefinition['GLRNAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DEFINITION_UPDATE_FOLDER_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and clones a folder definition.
 * @param {Object} folderDefinition The folderdefinition. Possible fields:
 * 'GLRNAME', 'NEWGLRNAME', 'OWNER', 'GLRTITLE', 'CUSER'
 * @param {Function} callback The callback which will be called when the create folder request was successful. Can be null.
 */
export function cloneFolder(folderDefinition, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: FOLDER_DEFINITION_CLONE_FOLDER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folder/clone`, {
        method: 'post',
        body: {
          GLRNAME: folderDefinition['GLRNAME'],
          NEWGLRNAME: folderDefinition['NEWGLRNAME'],
          OWNER: folderDefinition['OWNER'],
          GLRTITLE: folderDefinition['GLRTITLE'],
          CUSER: folderDefinition['CUSER'],
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: FOLDER_DEFINITION_CLONE_FOLDER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: FOLDER_DEFINITION_CLONE_FOLDER_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.clone_folder_success', lang, [folderDefinition['NEWGLRNAME'], folderDefinition['GLRNAME']]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getFolders again to get the current result
          const fields = undefined
          const foldername = prefs[Preferences.DEFINITION_FOLDER_ID]
          const foldertitle = prefs[Preferences.DEFINITION_FOLDER_TITLE]
          const owner = prefs[Preferences.DEFINITION_FOLDER_OWNER]
          const brw = prefs[Preferences.DEFINITION_FOLDER_BRW]
          getFolders(fields, foldername, foldertitle, owner, brw, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.clone_folder_error', lang, [folderDefinition['GLRNAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DEFINITION_CLONE_FOLDER_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calling the rest api and do getFolders request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'GLRNAME', 'OWNER', 'GLRTITLE', 'CDATE', 'CTIME'
 * @param {String} foldername The foldername pattern.
 * @param {String} foldertitle The foldertitle pattern.
 * @param {String} owner The owner pattern.
 * @param {String} brw Used for browsing. allowed values: 'Y' or ''. If BRW=Y is specified a parameter for the security checks is setup.
 * @param {Function} callback The callback which will be called of the request was successful.
 */
export function getFolders(fields, foldername, foldertitle, owner, brw, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: FOLDER_DEFINITION_GET_FOLDERS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (foldername) { queryParams.push(`GLRNAME=${encodeURIComponent(foldername)}`) }
    if (foldertitle) { queryParams.push(`GLRTITLE=${encodeURIComponent(foldertitle)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (brw) { queryParams.push(`BRW=${encodeURIComponent(brw)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/folders?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_FOLDER_DEFINITION_FOLDERS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: FOLDER_DEFINITION_GET_FOLDERS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: FOLDER_DEFINITION_GET_FOLDERS_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.folders_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: FOLDER_DEFINITION_GET_FOLDERS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}