import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Card from 'BetaUX2Web-Components/src/components/card/Card'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as PostprocessingNoteAction from 'redux/actions/PostProcessingNoteDefinitionActions'

class DefinitionPostprocessingNotes extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    ppnid: '',
    title: '',
    owner: ''
  }

  state = {
    ppnid: '',
    title: '',
    owner: ''
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount = () => {
    this.initFieldsFromPreferences()
  }

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props

    if (preferences) {
      const ppnid = preferences[Preferences.DEFINITION_PPNS_ID] || ''
      const title = preferences[Preferences.DEFINITION_PPNS_TITLE] || ''
      const owner = preferences[Preferences.DEFINITION_PPNS_OWNER] || ''
      this.setState({ ppnid, title, owner })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   */
  handleInputChanged = (id, value) => {
    this.setState({ [id]: value })
  }

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = (event) => {
    event.preventDefault()

    const { ppnid, owner, title } = this.state

    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_PPNS_ID]: ppnid,
      [Preferences.DEFINITION_PPNS_TITLE]: title,
      [Preferences.DEFINITION_PPNS_OWNER]: owner,
    }

    this.props.changePrefs(prefsToChange)
    this.props.getPPNs(
      undefined,
      ppnid,
      owner,
      title
    )
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleOnReset = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id, lang } = this.props
    const { ppnid, title, owner } = this.state
    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        <Card
          title={translate('general.general', lang)}>
          <Row>
            <Column
              colMD={12}>
              <Input
                id={`${id}_ppnid`}
                onInputChanged={val => { this.handleInputChanged('ppnid', val) }}
                value={ppnid}
                title={translate('definition.ppn_id', lang)}
                maxLength={16}
              />
            </Column>
          </Row>
          <Row>
            <Column
              colMD={12}>
              <Input
                id={`${id}_title`}
                onInputChanged={val => { this.handleInputChanged('title', val) }}
                value={title}
                title={translate('general.title', lang)}
                maxLength={40}
              />
            </Column>
          </Row>
          <Row>
            <Column
              colMD={6}>
              <Input
                id={`${id}_owner`}
                onInputChanged={val => { this.handleInputChanged('owner', val) }}
                value={owner}
                title={translate('general.owner', lang)}
                maxLength={8}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleOnReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={() => { }}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPPNs: (fields, ppn, owner, title, callback) => {
      PostprocessingNoteAction.getPPNs(fields, ppn, owner, title, callback)(dispatch)
    },
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionPostprocessingNotes)