import React, { Component } from 'react'
import PropTypes from 'prop-types'

import * as Preferences from 'redux/general/Preferences'
import { translate } from 'language/Language'

import { connect } from 'react-redux'
import * as ServerActions from 'redux/actions/ServerActions'

class ServerLpdStatus extends Component {

  componentDidMount = () => {
    this.props.getLpdQueuesStatus()
  }

  /**
   * @description Renders the main components.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className='bux_drawer_main bux_database_maintenance_container'>
        <p className='bux_database_maintenance_description'>
          {translate('general.no_options_available')}
        </p>
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'>
        {this.renderMain()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLpdQueuesStatus: () => ServerActions.getLPDQueuesStatus()(dispatch)
  }
}

ServerLpdStatus.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerLpdStatus)