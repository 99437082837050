import PropTypes from 'prop-types'
import { Component } from 'react'

// Style
import './Security.scss'

// Locals
import { translate } from 'language/Language'

// Components
import {
  Button, Card, Column, Dropdown, Input, MultiFloatingInput, Row
} from 'BetaUX2Web-Components/src/'
import { LetterCase } from 'BetaUX2Web-Components/src/types'
// React Router
import { withRouter } from 'react-router'

// Redux
import { connect } from 'react-redux'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as SecurityProfileActions from 'redux/actions/SecurityProfileActions'
import * as Preferences from 'redux/general/Preferences'
import * as SecurityFunctions from 'redux/general/SecurityFunctions'

class Security extends Component {

  defaultState = {
    profilemask: '',
    betauser: '*',
    profileCategory: SecurityFunctions.ALL_FUNCTIONS,
    profileEntry: SecurityFunctions.STAR,
  }

  state = {
    ...this.defaultState
  }


  /**
   * @description Initializes the values in state.
   */
  componentDidMount() {
    // initialize search fields
    const { preferences } = this.props

    let betauser = preferences[Preferences.SECURITY_BETAUSER] || '*'
    let profilemaskPrefix = ''
    if (preferences[Preferences.SECURITY_PROFILEMASK]) {
      profilemaskPrefix = preferences[Preferences.SECURITY_PROFILEMASK].split('.')[0]
    }

    let profileCategory = ''
    let profileEntry = ''
    let profilemask = ''

    if (!preferences[Preferences.SECURITY_PROFILE_TYPE]) {
      profileCategory = SecurityFunctions.ALL_FUNCTIONS
    } else {
      profileCategory = preferences[Preferences.SECURITY_PROFILE_TYPE]
    }

    if (!preferences[Preferences.SECURITY_PROFILE_ENTRY]) {
      profileEntry = SecurityFunctions.STAR
    } else {
      profileEntry = preferences[Preferences.SECURITY_PROFILE_ENTRY]
    }

    if (profileEntry !== SecurityFunctions.FREE) {
      if (preferences[Preferences.SECURITY_PROFILEMASK]) {
        profilemask = preferences[Preferences.SECURITY_PROFILEMASK].split('.').slice(1).join('.')
      }

      let tempProfileEntry = SecurityFunctions.getProfilentryAndParts(profilemaskPrefix).translationKey

      // in case we didn't find the entry, set it to free text and use the complete input as profilemask
      if (tempProfileEntry === undefined) {
        if (preferences[Preferences.SECURITY_PROFILEMASK].indexOf('.') === -1) {
          profilemask = preferences[Preferences.SECURITY_PROFILEMASK]
        }
      }
    }
    else {
      profilemask = preferences[Preferences.SECURITY_PROFILEMASK]
    }

    this.setState({
      profilemask: profilemask,
      betauser: betauser,
      profileCategory: profileCategory,
      profileEntry: profileEntry,
    })
  }

  /**
   * @description Changes the value of the profile mask.
   * @param {String} val The new value.
   */
  handleProfileMaskChanged = (val) => {
    this.setState({ profilemask: val })
  }

  /**
   * @description Resets the security panel to default values.
   */
  handleResetSecurity = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Changes the value of the usergroupid.
   * @param {String} val The new value.
   */
  handleUserGroupIdChanged(val) {
    this.setState({ betauser: val })
  }

  /**
   * @description Changes the profile category dropdown selection.
   * @param {Number} activeIndex The new selected index position of the profile category dropdown.
   */
  handleProfileCategoryChanged(activeIndex) {
    const profileCategory = SecurityFunctions.getCategoryKey(activeIndex)

    this.setState({
      profileCategory: profileCategory,
      profileEntry: SecurityFunctions.getFunctionKey(profileCategory, 0, true)
    })
  }

  /**
   * @description Changes the profile entry dropdown selection.
   * @param {Number} activeIndex The new selected index position of the profile entry dropdown.
   */
  handleProfileEntryChanged(activeIndex) {
    const category = this.state.profileCategory

    this.setState({ profileEntry: SecurityFunctions.getFunctionKey(category, activeIndex, true) })
  }

  /**
   * @description Calls the search to the api. Also saves the search values locally in store.
   * @param {Object} event The click event.
   */
  handleSubmitSearch = (event) => {
    event.preventDefault()

    const profileEntry = this.state.profileEntry

    const profileMaskPrefix = SecurityFunctions.getProfilemaskPrefixAndParts(profileEntry).prefix
    const profileMaskParts = SecurityFunctions.getProfilemaskPrefixAndParts(profileEntry).parts
    let concatedProfilemask = ''

    /* Only concat if a profilemask is entered (this.state.profilemask) and
       profileMaskPrefix accepts a profilemask (profilemaskParts != undefined) */
    if (this.state.profilemask && profileMaskParts) {
      if (profileMaskPrefix) {
        concatedProfilemask = `${profileMaskPrefix}.${this.state.profilemask}`
      } else { /* Avoid leading '.' before profilemask when no prefix is given (free-text) */
        concatedProfilemask = this.state.profilemask
      }
    } else {
      /* Only add prefix, when no profilemask is given or allowed (avoid '.' at end)  */
      concatedProfilemask = profileMaskPrefix
    }

    // save search values in preferences
    const prefsToChange = {
      [Preferences.SECURITY_PROFILEMASK]: concatedProfilemask,
      [Preferences.SECURITY_BETAUSER]: this.state.betauser,
      [Preferences.SECURITY_PROFILE_TYPE]: this.state.profileCategory,
      [Preferences.SECURITY_PROFILE_ENTRY]: profileEntry
    }

    this.props.changePrefs(prefsToChange)
    this.props.searchSecurityProfiles(concatedProfilemask, this.state.betauser)
  }

  /**
   * @description Calls the rest api and activates the security profiles.
   * @param {Object} event The click event.
   */
  handleActivate = event => {
    event.preventDefault()
    this.props.activateSecurityProfiles(null)
  }

  /**
   * @description Changes the selected position of the definition dropdown.
   * @param {Number} activeIndex The new selected index position of the definition dropdown.
   */
  handleChangeDefinition(activeIndex) {
    const link = this.getLink(activeIndex)

    if (link !== undefined) {
      this.props.history.push(link)
    }
  }

  /**
   * @description Gets the index of a definition to select.
   * @param {String} definition The definition to select.
   * @returns {Number} The active definition index.
   */
  getActiveDefinitionIndex(definition) {
    // get the index of the match with the link of the item and the definition
    let index = this.props.items.findIndex((item) => item.link.substring(item.link.lastIndexOf('/') + 1) === definition)

    // select first index if there is no item selected
    if (index === -1) {
      index = 0
    }

    return index
  }

  /**
   * @description Gets the link for a specific index.
   * @param {Number} activeIndex The index to find the link.
   * @returns {String} The link of the active index.
   */
  getLink(activeIndex) {
    // get the object with the current active index and return the link
    return this.props.items.find((_, currentIndex) => currentIndex === activeIndex).link
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { profilemask, profileCategory, profileEntry } = this.state
    return (
      <Card
        title={translate('general.general')}>
        <Row>
          <Column
            colMD={12}
            offsetMD={0}>
            <Dropdown
              id={`${id}_generalcard_row_0_column_profile_type`}
              title={translate('security.profile_type')}
              generalItems={SecurityFunctions.getTranslatedGeneralCategories()}
              items={SecurityFunctions.getTranslatedCategories()}
              activeIndex={SecurityFunctions.indexOfCategory(profileCategory)}
              onChange={(activeIndex) => this.handleProfileCategoryChanged(activeIndex)} />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={12}
            offsetMD={0}>
            <Dropdown
              id={`${id}_generalcard_row_1_column_profile_entry`}
              title={translate('security.profile_entry')}
              generalItems={SecurityFunctions.getTranslatedGeneralFunctions(profileCategory, true)}
              items={SecurityFunctions.getTranslatedFunctions(profileCategory)}
              activeIndex={SecurityFunctions.indexOfFunction(profileCategory, profileEntry, true)}
              onChange={(activeIndex) => this.handleProfileEntryChanged(activeIndex)} />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <MultiFloatingInput
              id={`${id}_profileMask`}
              title={translate('security.profile_mask')}
              onInputChanged={(val) => this.handleProfileMaskChanged(val)}
              input={profilemask.trimStart()}
              items={SecurityFunctions.getProfilemaskPrefixAndParts(profileEntry).parts}
              maxLength={64}
              translate={key =>translate(key)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_generalcard_user_group_id`}
              title={translate('security.user_group_id')}
              value={this.state.betauser}
              onInputChanged={(val) => this.handleUserGroupIdChanged(val)}
              maxLength={8}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {/* general card */}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_activate`}
          text={translate('general.update')}
          onClick={this.handleActivate}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          className='bux_iconResteBtn'
          onClick={this.handleResetSecurity}
        />
      </div>
    )
  }

  render = () => {
    const { id, match } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    return (
      <div id={id} className='drawer_field'>
        <form
          id={`${id}_body`}
          className='bux_drawer_form'
          onSubmit={this.handleSubmitSearch}>
          {/* main */}
          {this.renderMain()}
          {/* footer */}
          {this.renderFooter()}
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    security: state.security // not used but needed for updating component
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchSecurityProfiles: (profilemask, betauser) => {
      SecurityProfileActions.getSecurityProfiles(profilemask, betauser)(dispatch)
    },
    activateSecurityProfiles: callback => {
      SecurityProfileActions.refreshSecurityProfiles(callback)(dispatch)
    },
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) }
  }
}

Security.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Security))