import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'
import * as Utils from 'utils/Utils'

// components
import {
  Button,
  Card,
  Column,
  Input,
  Modal as ModalComponent,
  Row,
  Switch
} from 'BetaUX2Web-Components/src/'

import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

// Redux
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import { cloneLog } from 'redux/actions/LogDefinitionActions'

const { Modal, Main, Header, Footer } = ModalComponent
class CloneLogDefinitionDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    hostSource: '',
    applicationSource: '',
    sublogSource: '',
    hostTarget: {
      value: '',
      error: ''
    },
    applicationTarget: {
      value: '',
      error: ''
    },
    sublogTarget: {
      value: '',
      error: '',
    },
    logRecipient: 0,
    logIndex: 0,
    logFolder: 0,
    logNode: 0,
    allSublogs: 0,
    showDocumentSelectorDialog: false
  }

  hostTargetInput = React.createRef()
  applicationTargetInput = React.createRef()

  componentDidMount = () => {
    const { log } = this.props
    this.hostTargetInput.current.focus()
    this.setState({
      hostSource: log.FORM,
      applicationSource: log.EXT,
      sublogSource: log.REPORT
    })
  }

  /**
   * @description gets the index of the header in redux state
   * @param {Object} data specific redux object
   * @param {String} header header name of the header in redux
   */
  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param value New value of inputfield
   */
  handleInputChanged = (key, value, error) => {
    this.setState(state => ({
      [key]: typeof state[key] === 'object'
        ? { value, error: error || '' }
        : value
    }))
  }

  /**
   * @description Requests the log definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handleDocumentSelector = () => {
    const { getDocumentDefinitions } = this.props
    const { hostTarget, applicationTarget } = this.state
    const callback = () => this.setState({ showDocumentSelectorDialog: true })

    getDocumentDefinitions(['FORM', 'EXT', 'REPORT'], hostTarget.value, applicationTarget.value, callback)
  }

  /**
   * @description Renders the selector dialogs
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    const { sublogSource } = this.state

    const filteredSelectorLogData = selector.logs?.data ? selector.logs.data.filter((logDataSet) => !!logDataSet[this.headerData(selector.logs, 'EXT')]) : []// Exclude entries with empty 'Application', because this field is required
    if (filteredSelectorLogData.length === 0) return null

    return (
      <>
        <SelectorDialog
          id={`${id}_logdefinition_selector_dialog`}
          onClose={() => this.setState({ showDocumentSelectorDialog: false })}
          title={translate('definition.logdefinitions')}
          header={[
            translate('general.form'),
            translate('general.extension'),
            translate('general.report')
          ]}
          items={filteredSelectorLogData}
          onSelect={selectedRows => {
            if (selectedRows.length > 0) {
              const host = filteredSelectorLogData[selectedRows][this.headerData(selector.logs, 'FORM')]
              const application = filteredSelectorLogData[selectedRows][this.headerData(selector.logs, 'EXT')]
              const sublog = sublogSource ? filteredSelectorLogData[selectedRows][this.headerData(selector.logs, 'REPORT')] : ''
              this.setState({ hostTarget: { value: host }, applicationTarget: { value: application }, sublogTarget: { value: sublog } })
            }
            this.setState({ showDocumentSelectorDialog: false })
          }}
        />
      </>
    )
  }

  /**
   * @description Validates the host.
   */
  validateHost = () => {
    const { hostTarget } = this.state
    if (hostTarget.value !== '') {
      return {}
    }
    return {
      hostTarget: {
        ...this.state.hostTarget,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the application.
   */
  validateApplication = () => {
    const { applicationTarget, applicationSource } = this.state
    if (applicationSource === '') {
      return {}
    }
    if (applicationTarget.value !== '') {
      return {}
    }
    return {
      applicationTarget: {
        ...this.state.applicationTarget,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the sublog.
   */
  validateSublog = () => {
    const { sublogTarget, sublogSource } = this.state
    if (sublogSource === '') {
      return {}
    }
    if (sublogTarget.value !== '') {
      return {}
    }
    return {
      sublogTarget: {
        ...this.state.sublogTarget,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = {
      ...this.validateHost(),
      ...this.validateApplication(),
      ...this.validateSublog()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input which has an error.
   */
  handleFocus = () => {
    const { hostTarget, applicationTarget, sublogTarget } = this.state
    const requiredInputs = [
      { inputRef: this.hostTargetInput, error: hostTarget.error },
      { inputRef: this.applicationTargetInput, error: applicationTarget.error },
      { inputRef: this.sublogTargetInput, error: sublogTarget.error }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Clones the entry
   */
  handleSave = saveAsUtf8 => {
    const { cloneLog, onClose } = this.props
    const {
      hostSource,
      applicationSource,
      sublogSource,
      hostTarget,
      applicationTarget,
      sublogTarget,
      logRecipient,
      logIndex,
      allSublogs
    } = this.state
    if (this.validateInputs()) {
      const logDef = {
        FORM: hostSource,
        NFORM: hostTarget.value,
        EXTENSION: applicationSource,
        NEXTENSION: applicationTarget.value,
        REPORT: sublogSource,
        NREPORT: sublogTarget.value,
        cloneDocumentRecipientAssignments: logRecipient === 0,
        cloneDocumentIndexAssignments: logIndex === 0,
        SAVEUTF8: saveAsUtf8,
        cloneReports: allSublogs === 0
      }

      cloneLog(logDef, () => onClose())
    }
  }

  render = () => {
    const { id, onClose } = this.props
    const {
      hostSource,
      applicationSource,
      sublogSource,
      hostTarget,
      applicationTarget,
      sublogTarget,
      logRecipient,
      logIndex,
      allSublogs,
      showDocumentSelectorDialog
    } = this.state

    return (
      <>
        {showDocumentSelectorDialog && this.renderSelectorDialogs()}
        <Modal
          id='clone_log_dialog'
          className='bux_UserProfileModal'
          onClose={onClose}>
          <Header
            id={`${id}_modalHeader`}
            title={translate('definition.log_clone_modal_title')}
            onClose={onClose} />
          <Main id={id}>
            <Card>
              <Row>
                <Column colMD={3}>
                  <label>{translate('general.source')}</label>
                </Column>
                <Column colMD={3}>
                  <label>{translate('general.target')}</label>
                </Column>
                <Column colMD={3}>
                  <label>{translate('general.copy_assignments')}</label>
                </Column>
              </Row>
              <Row>
                <Column colMD={3}>
                  <Input
                    id={`${id}_hostsource`}
                    onInputChanged={val => this.handleInputChanged('hostSource', val)}
                    value={hostSource}
                    title={translate('general.form')}
                    maxLength={8}
                    ref={this.hostSourceInput}
                    disabled
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_hosttarget`}
                    onInputChanged={(val, err) => this.handleInputChanged('hostTarget', val, err)}
                    value={hostTarget.value}
                    title={translate('general.form')}
                    ref={this.hostTargetInput}
                    error={hostTarget.error && translate(hostTarget.error)}
                    maxLength={8}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }}
                    onBlur={() => this.setState({ ...this.validateHost() })}
                    required={`${translate('general.required_field')}`}
                  />
                </Column>
                <Column colMD={3}>
                  <Switch
                    id={`${id}_log_recipient`}
                    title={`${translate('general.log')} <--> ${translate('general.recipient')}`}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleInputChanged('logRecipient', index)}
                    activeIndex={logRecipient}
                  />
                </Column>
                <Column colMD={3}>
                  <Switch
                    id={`${id}_log_index`}
                    title={`${translate('general.log')} <--> ${translate('definition.index')}`}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleInputChanged('logIndex', index)}
                    activeIndex={logIndex}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={3}>
                  <Input
                    id={`${id}_applicationsource`}
                    onInputChanged={val => this.handleInputChanged('applicationSource', val)}
                    value={applicationSource}
                    title={translate('general.extension')}
                    maxLength={16}
                    ref={this.applicationSourceInput}
                    disabled
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_applicationtarget`}
                    onInputChanged={(val, err) => this.handleInputChanged('applicationTarget', val, err)}
                    value={applicationTarget.value}
                    title={translate('general.extension')}
                    ref={this.applicationTargetInput}
                    error={applicationTarget.error && translate(applicationTarget.error)}
                    maxLength={16}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }}
                    onBlur={() => this.setState({ ...this.validateApplication() })}
                    required={applicationSource !== '' ? `${translate('general.required_field')}` : false}
                  />
                </Column>
                <Column colMD={6} />
              </Row>
              <Row>
                <Column colMD={3}>
                  <Input
                    id={`${id}_sublogsource`}
                    onInputChanged={val => this.handleInputChanged('sublogSource', val)}
                    value={sublogSource}
                    title={translate('general.report')}
                    maxLength={16}
                    ref={this.sublogSourceInput}
                    disabled
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_sublogtarget`}
                    onInputChanged={(val, err) => this.handleInputChanged('sublogTarget', val, err)}
                    value={sublogTarget.value}
                    title={translate('general.report')}
                    maxLength={16}
                    error={sublogTarget.error && translate(sublogTarget.error)}
                    onBlur={() => this.setState({ ...this.validateSublog() })}
                    required={sublogSource !== '' ? `${translate('general.required_field')}` : false}
                    disabled={sublogSource === ''}
                  />
                </Column>
                <Column colMD={3}>
                  <Switch
                    id={`${id}_allsublogs`}
                    title={translate('general.all_reports')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={index => this.handleInputChanged('allSublogs', index)}
                    activeIndex={allSublogs}
                    disabled={sublogSource !== ''}
                  />
                </Column>
              </Row>
            </Card>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn_iso88591`}
              text={translate('general.save_as_iso88591')}
              onClick={() => this.handleSave(false)}
              primary
              submit
            />
            <Button
              id={`${id}_savebtn_utf8`}
              text={translate('general.save_as_utf8')}
              onClick={() => this.handleSave(true)}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }

}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    log: state.definitions.logs.log,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDocumentDefinitions: (fields, host, application, callback) => {
      ModalSelectorActions.getLogDefinitions(
        fields,
        host,
        application,
        undefined, // sublog
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    cloneLog: (logDefinition, callback) => {
      cloneLog(logDefinition, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CloneLogDefinitionDialog)