import * as ViewProfileDefinitionActions from '../actions/ViewProfileDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  viewProfiles: undefined,
  viewProfile: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get result tables actions
    case ViewProfileDefinitionActions.VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_START:
      return { ...state }
    case ViewProfileDefinitionActions.VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_SUCCESS:
      return { ...state, viewProfiles: action.payload.data, keepPagination: action.keepPagination}
    case ViewProfileDefinitionActions.VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_FAILED:
      return { ...state }
    case ViewProfileDefinitionActions.NO_VIEW_PROFILE_DEFINITION_VIEW_PROFILES_FOUND:
      return { ...state, viewProfiles: {} }
    /* ********************************************************************************** */
    // Definition get result table actions
    case ViewProfileDefinitionActions.VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILE_SUCCESS:
      return { ...state, viewProfile: action.payload.data}
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, viewProfiles: undefined, viewProfile: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer