import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'

export const DOCUMENT_NODE_GET_HIERARCHY_DATA = 'DOCUMENT_NODE_GET_HIERARCHY_DATA'
export const NO_DOCUMENT_NODE_HIERARCHY_FOUND = 'NO_DOCUMENT_NODE_HIERARCHY_FOUND'

export const DOCUMENT_NODE_GET_HIERARCHY_ENTRY_SUCCESS = 'DOCUMENT_NODE_GET_HIERARCHY_ENTRY_SUCCESS'
export const NO_DOCUMENT_NODE_HIERARCHY_ENTRY_FOUND = 'NO_DOCUMENT_NODE_HIERARCHY_ENTRY_FOUND'

export const DOCUMENT_NODE_HIERARCHY_ENTRIES_UPDATED = 'DOCUMENT_NODE_HIERARCHY_ENTRIES_UPDATED'
export const NO_CHILDS = 'NO_CHILDS'

/**
 * @description Calling the rest api and do get docuemnt nodes request.
 * @param {Object} documentNodeObj The object which stored the specific search parameters.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getDocumentNodesHierarchy(documentNodeObj, callback, ignoreSnackbar = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    /* Build request-parameter for URL */
    const queryParams = []
    if (documentNodeObj.DNDNAME) { queryParams.push(`DNDNAME=${encodeURIComponent(documentNodeObj.DNDNAME)}`) }
    // Needs to be filled every time to display the hierarchy correctly.
    queryParams.push(`DNDPNAME=${documentNodeObj.DNDPNAME ? encodeURIComponent(documentNodeObj.DNDPNAME) : ''}`)
    if (documentNodeObj.OWNER) { queryParams.push(`OWNER=${encodeURIComponent(documentNodeObj.OWNER)}`) }
    if (documentNodeObj.DNDENAME) { queryParams.push(`DNDENAME=${encodeURIComponent(documentNodeObj.DNDENAME)}`) }
    if (documentNodeObj.DNDTYPE) { queryParams.push(`DNDTYPE=${encodeURIComponent(documentNodeObj.DNDTYPE)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodes?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          // check specific errors
          if (rc === '0016' && irc === '0000') {
            !ignoreSnackbar && SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_DOCUMENT_NODE_HIERARCHY_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
        }
        else {
          dispatch({ type: DOCUMENT_NODE_GET_HIERARCHY_DATA, payload: jsondata })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.document_nodes_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do get document node request.
 * @param {Object} documentNodeObj The object which stored the specific search parameters.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getHierarchyEntry(documentNodeObj, parentKey, rootKey, hash, level, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const hierarchyState = store.getState().hierarchy.documentNodes
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    /* Build request-parameter for URL */
    const queryParams = [`DNDPNAME=${encodeURIComponent(documentNodeObj.DNDPNAME)}`]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodes?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            const newHierarchyEntriesData = hierarchyState.hierarchyEntriesData !== undefined && hierarchyState.hierarchyEntriesData.length > 0
              ? [ ...hierarchyState.hierarchyEntriesData, { parentKey: parentKey, rootKey: rootKey, key: [hash(NO_CHILDS)], level: level, data: NO_CHILDS }]
              : [{ parentKey: parentKey, rootKey: rootKey, key: [hash(NO_CHILDS)], level: level, data: NO_CHILDS }]

            dispatch({ type: NO_DOCUMENT_NODE_HIERARCHY_ENTRY_FOUND, payload: { newHierarchyEntriesData: newHierarchyEntriesData } })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
        }
        else {
          const dataArr = jsondata.data.data.map(d => ({ data: d, key: hash(d) }))
          const keys = dataArr.map(d => d.key)
          const data = dataArr.map(d => d.data)

          const newHierarchyEntriesData = hierarchyState.hierarchyEntriesData !== undefined && hierarchyState.hierarchyEntriesData.length > 0
            ? [ ...hierarchyState.hierarchyEntriesData, { parentKey: parentKey, rootKey: rootKey, key: keys, level: level, data: data }]
            : [{ parentKey: parentKey, rootKey: rootKey, key: keys, level: level, data: data }]

          dispatch({ type: DOCUMENT_NODE_GET_HIERARCHY_ENTRY_SUCCESS, payload: { newHierarchyEntriesData: newHierarchyEntriesData } })
          // call the callback on success
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.document_node_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export const updateHierarchyEntriesData = (newHierarchyEntriesData, reload = true) => {
  return dispatch => {
    dispatch({ type: DOCUMENT_NODE_HIERARCHY_ENTRIES_UPDATED, payload: newHierarchyEntriesData })
    if (reload) {
      const prefs = store.getState().auth.serverdata.preferences
      const documentNodeObj = {
        DNDNAME: prefs[Preferences.DEFINITION_DOCUMENT_NODE_NODE_ID],
        DNDPNAME: prefs[Preferences.DEFINITION_DOCUMENT_NODE_PARENT_NODE_ID],
        OWNER: prefs[Preferences.DEFINITION_DOCUMENT_NODE_OWNER],
        DNDENAME: prefs[Preferences.DEFINITION_DOCUMENT_NODE_IDENTIFIER]
      }
      getDocumentNodesHierarchy(documentNodeObj)(dispatch)
    }
  }
}

/**
 * @description Calling the rest api and do get document node request.
 * @param {Object} documentNodeObj The object which stored the specific search parameters.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getDocumentNodeHierarchy(documentNodeObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    /* Build request-parameter for URL */
    const queryParams = [
      `DNDNAME=${encodeURIComponent(documentNodeObj.DNDNAME)}`,
      `DNDPNAME=${encodeURIComponent(documentNodeObj.DNDPNAME)}`
    ]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnode?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        }
        else {
          if (callback) {
            callback(jsondata.data)
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.document_node_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}