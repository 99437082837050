import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Card from 'BetaUX2Web-Components/src/components/card/Card'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as FilterArgumentActions from 'redux/actions/FilterArgumentActions'

class DefinitionFilterArgument extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    filterID: {
      value: '',
      errorkey: ''
    },
    owner: {
      value: '',
      errorkey: ''
    },
    title: {
      value: '',
      errorkey: ''
    }
  }

  state = { ...this.defaultState }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
 * @description Initializes the import fields with the values saved in preferences.
 */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props
    if (preferences) {
      this.setState({
        filterID: {
          value: preferences[Preferences.DEFINITION_FILTERARGUMENT_ID] || '',
          errorkey: ''
        },
        owner: {
          value: preferences[Preferences.DEFINITION_FILTERARGUMENT_OWNER] || '',
          errorkey: ''
        },
        title: {
          value: preferences[Preferences.DEFINITION_FILTERARGUMENT_TITLE] || '',
          errorkey: ''
        }
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInput = (id, value, errorkey) => this.setState({ [id]: { value, errorkey } })

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSearch = event => {
    const { changePrefs, getFilterArguments } = this.props
    const { filterID, owner, title } = this.state
    event.preventDefault()

    const prefsToChange = {
      [Preferences.DEFINITION_FILTERARGUMENT_ID]: filterID.value,
      [Preferences.DEFINITION_FILTERARGUMENT_OWNER]: owner.value,
      [Preferences.DEFINITION_FILTERARGUMENT_TITLE]: title.value
    }

    changePrefs(prefsToChange)
    getFilterArguments(undefined, owner.value, filterID.value, title.value)
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleReset = () => this.setState(this.defaultState)

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    const { filterID, owner, title } = this.state

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        <Card title={translate('general.general')}>
          <Row>
            <Column colMD={6}>
              <Input
                id={`${id}_filterargument_id`}
                onInputChanged={(val, error) => this.handleInput('filterID', val, error)}
                value={filterID.value}
                title={translate('definition.filter_argument_id')}
                maxLength={16}
              />
            </Column>
            <Column colMD={6}>
              <Input
                id={`${id}_filterargument_owner`}
                onInputChanged={(val, error) => this.handleInput('owner', val, error)}
                value={owner.value}
                title={translate('general.owner')}
                maxLength={8}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_filterargument_title`}
                onInputChanged={(val, error) => this.handleInput('title', val, error)}
                value={title.value}
                title={translate('general.title')}
                maxLength={40}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={() => { }}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getFilterArguments: (fields, owner, filterArgument, title, callback) => {
      FilterArgumentActions.getFilterArguments(fields, owner, filterArgument, title, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionFilterArgument)