import * as GroupActions from '../actions/GroupActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  group: undefined,
  groups: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Create group actions
    case GroupActions.CREATE_GROUP_START:
      return { ...state }
    case GroupActions.CREATE_GROUP_SUCCESS:
      return { ...state }
    case GroupActions.CREATE_GROUP_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get group actions
    case GroupActions.GET_GROUP_START:
      // reset current group
      return { ...state, group: undefined }
    case GroupActions.GET_GROUP_SUCCESS:
      return { ...state, group: action.payload }
    case GroupActions.GET_GROUP_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Delete group actions
    case GroupActions.DELETE_GROUP_START:
      return { ...state }
    case GroupActions.DELETE_GROUP_SUCCESS:
      return { ...state }
    case GroupActions.DELETE_GROUP_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Update group actions
    case GroupActions.UPDATE_GROUP_START:
      return { ...state }
    case GroupActions.UPDATE_GROUP_SUCCESS:
      return { ...state }
    case GroupActions.UPDATE_GROUP_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get groups actions
    case GroupActions.GET_GROUPS_START:
      return { ...state }
    case GroupActions.GET_GROUPS_SUCCESS:
      return { ...state, groups: action.payload, keepPagination: action.keepPagination }
    case GroupActions.GET_GROUPS_FAILED:
      return { ...state }
    case GroupActions.NO_GROUPS_FOUND:
      return { ...state, groups: {} }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, groups: undefined, group: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer