import * as Lang from 'language/Language'

import store from 'redux/Store'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as AuthActions from 'redux/actions/AuthenticationActions'
import * as LastSessionActions from 'redux/actions/LastSessionActions'

/**
 * @description Handles the response. It will create an error which includes the fields 'handled' (says if the error was handled from this function), 'type' (the type of the error message -> see types in SnackbarActions) and message (the translated error message).
 * @param {String} rc The return code.
 * @param {String} irc The internal return code.
 * @param {Object} params The params The params for the message translation.
 * @param {Object} dispatch The dispatch object to dispatch redux actions.
 */
export function handleResponse(rc, irc = 0, params, dispatch) {
  const error = {
    handled: false,
    type: SnackbarActions.TYPE_ERROR,
    message: undefined
  }
  // get the language from redux
  const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]


  // user token expired
  if (rc === '0020' && irc === '3499') {
    // save value into last session
    const userid = store.getState().auth.userid
    const url = window.location.pathname
    LastSessionActions.saveLastSession(userid, url)(dispatch)

    // clear data in redux
    AuthActions.clearData()(dispatch)

    error.handled = true
  }
  let parameters
  if (rc === '0042' && irc === '0006') {
    parameters = prepareParams(params)
  }
  // get the message based on the rc and irc
  error.message = Lang.translateRC(rc, irc, lang, parameters ?? params)

  if (!error.message) {
    error.message = Lang.translate('unknown_error_message', lang, [rc, irc])
  }
  return error
}

export const prepareParams = (params) => {
  let paramArray
  if (!(params instanceof Array)) {
    paramArray = JSON.parse(params.replace('\\"', '"'))
  }
  else {
    paramArray = JSON.parse(params.map(p => p.replace('\\"', '"')))
  }

  const messageArray = paramArray.flatMap((paramObj) => {
    if (paramObj.nestedErrors) {
      return paramObj.nestedErrors.map(nestedError => nestedError.msg)
    } else {
      return paramObj.msg
    }
  })

  const joinedMessages = messageArray.filter((msg, index) => messageArray.indexOf(msg) === index).join('. ')
  return joinedMessages
}

export const getError = (requestError, dispatch) => {
  let rc = requestError.rc
  let irc = requestError.irc
  let param = requestError.param
  // check general errors
  let error = handleResponse(rc, irc, param, dispatch)
  let type = error.type
  // if warning returns, that the password will expire soon, change the type to info
  if (rc === '0008' && irc === '0000') {
    type = SnackbarActions.TYPE_INFO
  }
  return {
    message: error.message,
    type
  }
}