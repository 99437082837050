import * as SearchArgumentActions from '../actions/SearchArgumentDefinitionActions.js'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  searchArguments: undefined,
  searchArgument: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get search arguments actions
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_START:
      return { ...state }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_SUCCESS:
      return { ...state, searchArguments: action.payload.data, keepPagination: action.keepPagination }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_FAILED:
      return { ...state }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENTS_NO_FOUND:
      return { ...state, searchArguments: {} }
    /* ********************************************************************************** */
    // Definition create search argument actions
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_START:
      return { ...state }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_SUCCESS:
      return { ...state }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_CREATE_SEARCHARGUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition copy search argument actions
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_START:
      return { ...state }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_SUCCESS:
      return { ...state }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_COPY_SEARCHARGUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition get search argument actions
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_START:
      return { ...state, searchArgument: undefined }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_SUCCESS:
      return { ...state, searchArgument: action.payload }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_GET_SEARCHARGUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition get search argument actions
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_REFRESH_SEARCHARGUMENT_SUCCESS:
      return { ...state, searchArgument: action.payload }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_REFRESH_SEARCHARGUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete search argument actions
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_START:
      return { ...state }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_SUCCESS:
      return { ...state }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_DELETE_SEARCHARGUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition verify search argument actions
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_START:
      return { ...state }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_SUCCESS:
      return { ...state, verify: action.payload }
    case SearchArgumentActions.SEARCHARGUMENTS_DEFINITION_VERIFY_SEARCHARGUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, searchArguments: undefined, searchArgument: undefined }
    default: return state
  }
}

export default reducer