import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// components
import {
  Button, Card, Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as FolderActions from 'redux/actions/FolderDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'

class ModifyFolderDefinitionDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    owner: {
      value: this.props.folder.OWNER,
      errorkey: ''
    },
    title: {
      value: this.props.folder.GLRTITLE,
      errorkey: ''
    }
  }

  ownerInput = React.createRef()

  componentDidMount = () => {
    this.ownerInput.current.focus()
  }

  /**
   * @description Handles changes in input fields.
   * @param id The id of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInput = (id, value, errorkey) => this.setState({ [id]: { value, errorkey } })

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { folder, updateFolder, onClose } = this.props
    const { owner, title } = this.state

    const folderDefinition = {
      GLRNAME: folder.GLRNAME,
      OWNER: owner.value,
      GLRTITLE: title.value,
    }
    updateFolder(folderDefinition, () => onClose())
  }

  render = () => {
    const { id, onClose, datemask, folder } = this.props
    const { owner, title } = this.state
    return (
      <Modal onClose={onClose} id={id}>
        <Header
          id={id}
          title={translate('definition.modify_folder')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('definition.folder_id'), value: folder.GLRNAME },
              { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, folder.CDATE, folder.CTIME.substring(0, 8)) },
            ]}
            columns={4}
          />
        </Header>
        <Main id={id}>
          <Card>
            <Row>
              <Column colMD={3}>
                <Input
                  id={`${id}_owner`}
                  value={owner.value}
                  ref={this.ownerInput}
                  title={translate('general.owner')}
                  maxLength={8}
                  onInputChanged={(value, errorkey) => this.handleInput('owner', value, errorkey)}
                  error={owner.errorkey && translate(owner.errorkey)}
                />
              </Column>
              <Column colMD={9}>
                <Input
                  id={`${id}_title`}
                  value={title.value}
                  title={translate('general.title')}
                  maxLength={80}
                  onInputChanged={(value, errorkey) => this.handleInput('title', value, errorkey)}
                  error={title.errorkey && translate(title.errorkey)}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

ModifyFolderDefinitionDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    folder: state.definitions.folders.folder
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateFolder: (folderDefinition, callback) => {
      FolderActions.updateFolder(folderDefinition, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyFolderDefinitionDialog)