import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  Button,
  Column,
  Input,
  Modal as ModalComponent, MultilineInput, Row,
  Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'
import { validateInputID } from 'utils/DefinitionUtils'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as PostProcessingNoteDefinitionActions from 'redux/actions/PostProcessingNoteDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import { hasNoValues } from 'utils/ObjectUtils'
import _ from 'lodash'

class CreatePPNDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    prefilledData: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.defaultState = {
      generalTab: {
        ppnID: {
          value: this.props.copyPPN ? this.props.ppn.PPN : '',
          errorkey: '',
        },
        owner: {
          value: this.props.copyPPN ? this.props.ppn.OWNER : '',
          errorkey: '',
        },
        title: {
          value: this.props.copyPPN ? this.props.ppn.PPNTITLE : '',
          errorkey: '',
        },
        printControlFilename: {
          value: this.props.copyPPN ? this.props.ppn.DJDE : '',
          errorkey: '',
        }
      },
      textTab: {
        text: ''
      }
    }
    this.state = _.cloneDeep(this.defaultState)
  }

  resetState = () => {
    this.setState(_.cloneDeep(this.defaultState))
  }

  ppnIDInput = React.createRef()

  /**
   * @description Sets the initial focus.
   */
  componentDidMount = () => {
    let multilineText = ''
    if (this.props.copyPPN) {
      const { ppn } = this.props
      for (let i = 1; i <= 16; i++) {
        multilineText += ppn[`PPNTEX${i}`]
        if (i < 16 && ppn[`PPNTEX${i}`] !== '') {
          multilineText += '\n'
        }
      }
    }

    if (this.props.prefilledData || this.props.copyPPN) {
      const data = this.props.prefilledData ? this.props.prefilledData : this.props.copyPPN ? this.props.ppn : undefined
      this.setState({
        generalTab: {
          ppnID: {
            value: data?.PPN ?? '',
            errorkey: '',
          },
          owner: {
            value: data?.OWNER ?? '',
            errorkey: '',
          },
          title: {
            value: data?.PPNTITLE ?? '',
            errorkey: '',
          },
          printControlFilename: {
            value: data?.DJDE ?? '',
            errorkey: '',
          }
        },
        textTab: {
          text: multilineText
        }
      })
    }

    this.ppnIDInput.current.focus()
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateGeneralTab = () => {
    const validatorResult = { ...validateInputID('ppnID', this.state.generalTab.ppnID, 'ppn', this.props.copyPPN ? this.props.ppn.PPN : undefined) }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ generalTab: { ...this.state.generalTab, ...validatorResult } }, () => {
        this.ppnIDInput.current && this.ppnIDInput.current.focus()
      })
    }
    return errors === 0
  }

  /**
   * @description Gets the tab indices as array which has errors.
   * @returns {Array} The tab indices which has errors.
   */
  handleErrorTabs = () => {
    const { generalTab } = this.state
    const buffer = []
    if (generalTab.ppnID.errorkey !== '') {
      buffer.push(0)
    }
    return buffer
  }

  /**
   * @description Handles the save action.
   * @param {Boolean} saveAsUtf8 Flag if the definition should be saved as utf-8. If false it will be saved as iso-8859-1.
   */
  handleSave = (saveAsUtf8) => {
    const { generalTab, textTab } = this.state

    // assign each row to its param
    const [PPNTEX1, PPNTEX2, PPNTEX3, PPNTEX4, PPNTEX5, PPNTEX6, PPNTEX7, PPNTEX8, PPNTEX9,
      PPNTEX10, PPNTEX11, PPNTEX12, PPNTEX13, PPNTEX14, PPNTEX15, PPNTEX16
    ] = textTab.text.split('\n')

    if (this.validateGeneralTab()) {
      const ppnDefinition = {
        PPN: generalTab.ppnID.value,
        OWNER: generalTab.owner.value,
        DJDE: generalTab.printControlFilename.value,
        PPNTITLE: generalTab.title.value,
        CUSER: this.props.userid,
        PPNTEX1: PPNTEX1,
        PPNTEX2: PPNTEX2,
        PPNTEX3: PPNTEX3,
        PPNTEX4: PPNTEX4,
        PPNTEX5: PPNTEX5,
        PPNTEX6: PPNTEX6,
        PPNTEX7: PPNTEX7,
        PPNTEX8: PPNTEX8,
        PPNTEX9: PPNTEX9,
        PPNTEX10: PPNTEX10,
        PPNTEX11: PPNTEX11,
        PPNTEX12: PPNTEX12,
        PPNTEX13: PPNTEX13,
        PPNTEX14: PPNTEX14,
        PPNTEX15: PPNTEX15,
        PPNTEX16: PPNTEX16,
        SAVEUTF8: saveAsUtf8
      }

      const callback = () => this.props.onClose()
      this.props.createPPN(ppnDefinition, callback)
    }
  }

  /**
   * @description Handles the changes on input fields in tabs.
   * @param {String} tab The tab which changed.
   * @param {String} id The id of the field.
   * @param {String} value The new value.
   * @param {String} errorkey The new error key.
   */
  handleInputChanged = (tab, id, value, errorkey) => {
    if (tab === 'generalTab') {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          [id]: {
            value: value,
            errorkey: errorkey
          }
        }
      })
    } else if (tab === 'textTab') {
      this.setState({
        textTab: {
          ...this.state.textTab,
          [id]: value
        }
      })
    }
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} tab The tab which changed.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (tab, key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInputChanged(tab, key, value, error)
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { id, copyPPN } = this.props
    const { generalTab } = this.state
    return (
      <>
        {/* post processing note row*/}
        <Row>
          <Column
            colMD={6}>
            {/* post processing note */}
            <Input
              id={`${id}_ppn_id`}
              ref={this.ppnIDInput}
              title={translate('definition.postprocessing_note_id')}
              value={generalTab.ppnID.value}
              error={generalTab.ppnID.errorkey && translate(generalTab.ppnID.errorkey)}
              maxLength={16}
              onInputChanged={(value, errorkey) => this.handleChangeWithoutSpaces('generalTab', 'ppnID', value, errorkey)}
              onBlur={() => this.setState({ generalTab: { ...this.state.generalTab, ...validateInputID('ppnID', this.state.generalTab.ppnID, 'ppn', copyPPN ? this.props.ppn.PPN : undefined) } })}
              required={`${translate('general.required_field')}`}
            />
          </Column>
        </Row>
        {/* owner + print control filename + title row */}
        <Row>
          <Column
            colMD={3}>
            {/* owner */}
            <Input
              id={`${id}_owner`}
              title={translate('general.owner')}
              value={generalTab.owner.value}
              error={generalTab.owner.errorkey && translate(generalTab.owner.errorkey)}
              maxLength={8}
              onInputChanged={value => this.handleInputChanged('generalTab', 'owner', value)}>
            </Input>
          </Column>
          <Column
            colMD={3}>
            {/* print control filename */}
            <Input
              id={`${id}_print_control_filename`}
              title={translate('definition.ppn_print_control_filename')}
              value={generalTab.printControlFilename.value}
              error={generalTab.printControlFilename.errorkey && translate(generalTab.printControlFilename.errorkey)}
              maxLength={8}
              onInputChanged={value => this.handleInputChanged('generalTab', 'printControlFilename', value)}>
            </Input>
          </Column>
          <Column
            colMD={6}>
            {/* title */}
            <Input
              id={`${id}_title`}
              title={translate('general.title')}
              value={generalTab.title.value}
              error={generalTab.title.errorkey && translate(generalTab.title.errorkey)}
              maxLength={40}
              onInputChanged={value => this.handleInputChanged('generalTab', 'title', value)}>
            </Input>
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the text tab.
   */
  renderTextTab = () => {
    const { id } = this.props
    const { textTab } = this.state
    return (
      <>
        <Row>
          <Column
            colMD={12}>
            <MultilineInput
              tabIndex={0}
              title={translate('general.text')}
              value={textTab.text}
              cols={80}
              rows={16}
              id={`${id}_text`}
              onInputChanged={value => this.handleInputChanged('textTab', 'text', value)}
              mono
            />
          </Column>
        </Row>
      </>
    )
  }

  render = () => {
    const { id, onClose, lang, copyPPN } = this.props
    return (
      <Modal onClose={onClose}
        id={id}
        className='bux_UserProfileModal'>
        <Header
          id={`${id}`}
          title={copyPPN ? translate('definition.copy_ppn') : translate('definition.create_ppn')}
          onClose={onClose}>
        </Header>
        <Main
          id={id}>
          <Tabs
            id={id}
            lang={lang}
            errorTabs={this.handleErrorTabs()}>
            <Tab title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('general.text')}>
              {this.renderTextTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer>
          {this.props.prefilledData && !hasNoValues(this.props.prefilledData) &&
            <Button
              id={`${id}_resetbtn`}
              tooltip={translate('general.reset')}
              icon={'undo'}
              onClick={this.resetState}
            />
          }
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn_iso88591`}
            text={translate('general.save_as_iso88591')}
            onClick={() => this.handleSave(false)}
            primary
            submit
          />
          <Button
            id={`${id}_savebtn_utf8`}
            text={translate('general.save_as_utf8')}
            onClick={() => this.handleSave(true)}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    ppn: state.definitions.ppns.ppn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createPPN: (ppnDefinition, callback) => {
      PostProcessingNoteDefinitionActions.createPPN(ppnDefinition, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePPNDialog)