import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './TableButtonGroupItem.scss'

export default class TableButtonGroupItem extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Function to be called on an click event.*/
    onClick: PropTypes.func.isRequired,
    /** Text to be displayed next to the icon.*/
    text: PropTypes.string.isRequired,
    /** Icon to be displayed in component.*/
    icon: PropTypes.element.isRequired,
    /** Tooltip.*/
    title: PropTypes.string,
    /** Disables the component. Default: false. */
    disabled: PropTypes.bool
  }

  handleOnClick = (event) => {
    event.preventDefault()
    this.props.onClick()
  }

  render() {
    const { id, title, icon, disabled, text } = this.props
    return (
      disabled
        ? <li id={id} tabIndex={0} title={title} className={`action_opacity table_button_group_item${disabled ? ' disabled' : ''}`}>
          {icon}{text}
        </li>
        : <li id={id} tabIndex={0} title={title} onClick={this.handleOnClick} className='table_button_group_item'>
          {icon}{text}
        </li>
    )
  }
}