import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Column, Input, Row } from 'BetaUX2Web-Components/src'
import { DataTableWrapper } from 'components/search_result/search/common/DataTableWrapper'
import { useDataTableMapperFunc } from 'components/search_result/search/search-documents-utils'

import { getPreviewDocuments, getPreviewJobs } from 'redux/actions/ResultTableDefinitionActions'
import * as TabUtils from 'utils/TabUtils'

export const PreviewTab = ({ id, headerKeysToRender, lastUsedPreviewQuery, setLastUsedPreviewQuery, selectedResultTableType }) => {
  const dispatch = useDispatch()

  // Global state:
  const previewResults = useSelector((state) => state.definitions.resulttables.previewResults)

  // Component data:
  const previewBuxQueryInputRef = useRef(null)
  const logSource = TabUtils.getLogSource(selectedResultTableType)
  const previewQuery = lastUsedPreviewQuery.length !== 0 ? lastUsedPreviewQuery : (!!logSource ? `BUX_SELJOB LOGSOURCE(${logSource}) SDATE(TODAY) MAXENTRY(10)` : 'BUX_SELDOC SDATE(TODAY) MAXENTRY(10)') // TBD: Default for global index search, e.g. 'BUX_SELGBL IDXARG(ARTNR EQ '*') MAXENTRY(10)
  const rowData = useDataTableMapperFunc()(previewResults?.data ?? [], previewResults?.header ?? [], headerKeysToRender.fieldNames)

  // Local state:
  const [error, setError] = useState('')
  const [inputValue, setInputValue] = useState(previewQuery)

  const handleInputChange = (value) => {
    setInputValue(value)

    if (!!error) setError('') // Remove error message when user changes input value
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    TabUtils.isValidBuxQuery(inputValue) ? loadPreviewData(inputValue) : setError('Not valid')
  }

  const loadPreviewData = (buxQuery) => {
    const { command, searchParams } = TabUtils.parseSearchParamsFromBuxQuery(buxQuery)

    const error = TabUtils.isCorrectBuxQuery({ command: command, logSourceResultTable: logSource, logSourceInput: searchParams['LOGSOURCE'] })
    if (error) return setError(error) // Set error message if BUX query is valid, but does not match result table type

    if (logSource) {
      getPreviewJobs({ searchParams: { ...searchParams, LOGSOURCE: logSource } })(dispatch)
    } else {
      getPreviewDocuments({ searchParams: searchParams, isGlobalIndexSearch: command === 'BUX_SELGBL' })(dispatch)
    }

    setLastUsedPreviewQuery(inputValue) // Persist when switching tabs
  }

  const setFocusToInputField = () => {
    if (!!document.getElementById(`${id}_preview_bux_query`) && !!previewBuxQueryInputRef.current) {
      previewBuxQueryInputRef.current.focus()
    }
  }

  useEffect(() => {
    setFocusToInputField()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Row>
        <Column fullHeight>
          <div
            onKeyDown={(event) => event.key === 'Enter' && handleSubmit(event)}
          >
            <Input
              id={`${id}_preview_bux_query`}
              ref={previewBuxQueryInputRef}
              onInputChanged={handleInputChange}
              value={inputValue}
              title={'BUX query'}
              maxLength={128}
              addon={{ iconName: 'search', isInner: false, onClick: handleSubmit}}
              error={error}
            />
          </div>
        </Column>
      </Row>
      {previewResults && (
        <>
          <Row>
            Results
          </Row>
          <Row>
            <div style={{ overflow: 'auto', maxHeight: '30vh' }}>
              <DataTableWrapper
                id={id}
                data={rowData}
                fillPage={false}
                keepPagination={false}
                header={headerKeysToRender.columnIdentifiers}
                menu={false}
                onRowClick={() => null}
                selectable={false}
              />
            </div>
          </Row>
        </>
      )}
    </>
  )
}