import * as RecipientDocumentAssignmentActions from '../actions/RecipientDocumentAssignmentActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  recipientDocumentAssignments: undefined,
  recipientDocumentAssignment: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get recipient document assignments actions
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_START:
      return { ...state }
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_SUCCESS:
      return { ...state, recipientDocumentAssignments: action.payload, keepPagination: action.keepPagination }
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_GET_ASSIGNMENTS_FAILED:
      return { ...state }
    case RecipientDocumentAssignmentActions.NO_RECIPIENT_DOCUMENT_ASSIGNMENTS_FOUND:
      return { ...state, recipientDocumentAssignments: {} }
    /* ********************************************************************************** */
    // Definition get recipient document assignment actions
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_GET_ASSIGNMENT_START:
      return { ...state }
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_GET_ASSIGNMENT_SUCCESS:
      return { ...state, recipientDocumentAssignment: action.payload }
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_GET_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition create recipient document assignment actions
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_START:
      return { ...state }
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_CREATE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update recipient document assignment actions
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_START:
      return { ...state }
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_UPDATE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete recipient document assignment actions
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_START:
      return { ...state }
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case RecipientDocumentAssignmentActions.RECIPIENT_DOCUMENT_DELETE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, recipientDocumentAssignments: undefined, recipientDocumentAssignment: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer