import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_START = 'OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_START'
export const OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_SUCCESS = 'OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_SUCCESS'
export const OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_FAILED = 'OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_FAILED'
export const NO_OUTPUTCHANNEL_DEFINITION_OUTPUTCHANNELS_FOUND = 'NO_OUTPUTCHANNEL_DEFINITION_OUTPUTCHANNELS_FOUND'

export const OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_START = 'OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_START'
export const OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_SUCCESS = 'OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_SUCCESS'
export const OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_FAILED = 'OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_FAILED'

export const OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_START = 'OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_START'
export const OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_SUCCESS = 'OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_SUCCESS'
export const OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_FAILED = 'OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_FAILED'

export const OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_START = 'OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_START'
export const OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_SUCCESS = 'OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_SUCCESS'
export const OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_FAILED = 'OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_FAILED'

export const OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_START = 'OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_START'
export const OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_SUCCESS = 'OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_SUCCESS'
export const OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_FAILED = 'OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_FAILED'

export const OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_START = 'OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_START'
export const OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_SUCCESS = 'OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_SUCCESS'
export const OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_FAILED = 'OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_FAILED'

export const EXECUTE_MONITOR_REQUEST_START = 'EXECUTE_MONITOR_REQUEST_START'
export const EXECUTE_MONITOR_REQUEST_SUCCESS = 'EXECUTE_MONITOR_REQUEST_SUCCESS'
export const EXECUTE_MONITOR_REQUEST_FAILED = 'EXECUTE_MONITOR_REQUEST_FAILED'

/**
 * @description Calling the rest api and do getOutoutChannels request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'DCR', 'DCRTYPE', 'DCRTITLE', 'DCRRQ', 'DCRRQFLG', 'CDATE', 'CTIME'
 * @param {String} outputChannel The output channel pattern.
 * @param {String} type The type of the output channel. Possible values:
 * 'CMD', 'IP/T', 'APPL' or ''
 * @param {String} title The title of the output channel.
 * @param {String} requeue The requeue name pattern.
 * @param {Boolean} enableRequeue The flag to enable requeue.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getOutputChannels(fields, outputChannel, type, owner, title, requeue, enableRequeue, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (outputChannel) { queryParams.push(`DCR=${encodeURIComponent(outputChannel)}`) }
    if (type) { queryParams.push(`DCRTYPE=${encodeURIComponent(type)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`DCRTITLE=${encodeURIComponent(title)}`) }
    if (requeue) { queryParams.push(`DCRRQ=${encodeURIComponent(requeue)}`) }
    if (enableRequeue) { queryParams.push(`DCRRQFLG=${encodeURIComponent(enableRequeue)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputChannels?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_OUTPUTCHANNEL_DEFINITION_OUTPUTCHANNELS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.outputchannels_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNELS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 *
 * @param {String} outputChannel The outputchannel we want to check.
 * @param {String} mod The mod. Possible values: 'SUM', 'DET', 'TOT', 'PRT'
 * @param {String} state The state. Possible values: 'RUN', 'WAIT', 'ERROR', 'INFO', 'MARK', 'STATE', 'ALL'
 * @param {String} prio The priorities.
 */
export function executeMonitorRequest(outputChannel, mod, state, prio, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: EXECUTE_MONITOR_REQUEST_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/output/monitor`, {
        method: 'POST',
        body: {
          DCR: outputChannel,
          MOD: mod,
          STATE: state,
          PRIO: prio
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: EXECUTE_MONITOR_REQUEST_FAILED })
          }
        } else {
          dispatch({ type: EXECUTE_MONITOR_REQUEST_SUCCESS, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('queue.output_queue_monitor_request_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: EXECUTE_MONITOR_REQUEST_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets a output channel.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * 'DCR', 'OWNER', 'DCRTITLE', 'DCRIPADR', 'DCRPORT', 'DCRPLIB', 'DCRCLIST', 'DCRAPPL',
   'CDATE', 'CTIME', 'CUSER', 'PICPREFX', 'DJDE', 'DJDET', 'DCRHOLD',
   'DCRVICR', 'DCRBLIST', 'DCRVSUP', 'DCRVODOC', 'DCRVICBF', 'DCRVICBD',
   'LRNFORMB', 'LRNFORMT', 'LSTFORMB', 'LSTFORMT', 'PPNFORM', 'LISTFORM', 'TOCFORM',
   'PJLUSTJ', 'PJLUSTP', 'PJLUSTD', 'PJLUSTIM', 'PJLMWAIT',
   'DCRPTYPE', 'DCRRQ', 'DCRRQFLG', 'DCRDEBUG', 'IPFOLDER', 'DCRCSKL', 'DCRFCMD',
   'DCROUTP', 'DCRUSOGR', 'DCRECMD', 'LSTFORMC', 'IPFORCEO',
   'IPPRINT', 'IPFORCEA', 'PDICOMMU', 'DCRVPRTY', 'DCRTYPE', 'DCRVFIX'
 * @param {String} outputChannel The id of the output channel.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getOutputChannel(fields, outputChannel, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (outputChannel) { queryParams.push(`DCR=${encodeURIComponent(outputChannel)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputChannel?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.get_output_channel_error', lang, [outputChannel, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTCHANNEL_DEFINITION_GET_OUTPUTCHANNEL_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a output channel definition.
 * @param {Object} outputChannel The outputChannelDefinition. Possible fields:
 * 'DCR', 'OWNER', 'DCRTITLE', 'DCRIPADR', 'DCRPORT', 'DCRPLIB', 'DCRCLIST', 'DCRAPPL',
   'PICPREFX', 'DJDE', 'DJDET', 'DCRHOLD',
   'DCRVICR', 'DCRBLIST', 'DCRVSUP', 'DCRVODOC', 'DCRVICBF', 'DCRVICBD',
   'LRNFORMB', 'LRNFORMT', 'LSTFORMB', 'LSTFORMT', 'PPNFORM', 'LISTFORM', 'TOCFORM',
   'PJLUSTJ', 'PJLUSTP', 'PJLUSTD', 'PJLUSTIM', 'PJLMWAIT',
   'DCRPTYPE', 'DCRRQ', 'DCRRQFLG', 'DCRDEBUG', 'IPFOLDER', 'DCRCSKL', 'DCRFCMD',
   'DCROUTP', 'DCRUSOGR', 'DCRECMD', 'LSTFORMC', 'IPFORCEO',
   'IPPRINT', 'IPFORCEA', 'PDICOMMU', 'DCRVPRTY', 'DCRTYPE', 'DCRVFIX'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function createOutputChannel(outputChannel, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputChannel`, {
        method: 'post',
        body: ObjectUtils.removeByValue(outputChannel, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.create_output_channel_success', lang, outputChannel['DCR']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          const fields = undefined
          const id = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_ID]
          const type = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_TYPE]
          const owner = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_OWNER]
          const title = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_TITLE]
          const altID = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_ALTERNATIVE_ID]
          const onlyActiveAlt = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_ONLY_ACTIVE_ALTERNATIVES]

          getOutputChannels(fields, id, type, owner, title, altID, onlyActiveAlt, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.create_output_channel_error', lang, [outputChannel['DCR'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
        dispatch({ type: OUTPUTCHANNEL_DEFINITION_CREATE_OUTPUTCHANNEL_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a output channel definition.
 * @param {Object} outputChannel The outputChannelDefinition. Possible fields:
 * 'DCR', 'OWNER', 'DCRTITLE', 'DCRIPADR', 'DCRPORT', 'DCRPLIB', 'DCRCLIST', 'DCRAPPL',
   'PICPREFX', 'DJDE', 'DJDET', 'DCRHOLD',
   'DCRVICR', 'DCRBLIST', 'DCRVSUP', 'DCRVODOC', 'DCRVICBF', 'DCRVICBD',
   'LRNFORMB', 'LRNFORMT', 'LSTFORMB', 'LSTFORMT', 'PPNFORM', 'LISTFORM', 'TOCFORM',
   'PJLUSTJ', 'PJLUSTP', 'PJLUSTD', 'PJLUSTIM', 'PJLMWAIT',
   'DCRPTYPE', 'DCRRQ', 'DCRRQFLG', 'DCRDEBUG', 'IPFOLDER', 'DCRCSKL', 'DCRFCMD',
   'DCROUTP', 'DCRUSOGR', 'DCRECMD', 'LSTFORMC', 'IPFORCEO',
   'IPPRINT', 'IPFORCEA', 'PDICOMMU', 'DCRVPRTY', 'DCRTYPE', 'DCRVFIX'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function updateOutputChannel(outputChannel, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputChannel`, {
        method: 'put',
        body: ObjectUtils.removeByValue(outputChannel, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_FAILED, payload: { error } })
        } else {
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.modify_output_channel_success', lang, outputChannel['DCR']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          const fields = undefined
          const id = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_ID]
          const type = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_TYPE]
          const owner = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_OWNER]
          const title = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_TITLE]
          const altID = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_ALTERNATIVE_ID]
          const onlyActiveAlt = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_ONLY_ACTIVE_ALTERNATIVES]

          getOutputChannels(fields, id, type, owner, title, altID, onlyActiveAlt, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.modify_output_channel_error', lang, [outputChannel['DCR'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTCHANNEL_DEFINITION_MODIFY_OUTPUTCHANNEL_FAILED, payload: { error } })
      })
  }
}


/**
 * @description Calls the rest api and deletes a output channel definition.
 * @param {String} outputChannel The outputChannel to delete (DCR).
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function deleteOutputChannel(outputChannel, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputChannel${createQueryParamsForFetch({ DCR: outputChannel })}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_FAILED, payload: { error } })
        } else {
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('definition.delete_output_channel_success', lang, outputChannel), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          const fields = undefined
          const id = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_ID]
          const type = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_TYPE]
          const owner = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_OWNER]
          const title = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_TITLE]
          const altID = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_ALTERNATIVE_ID]
          const onlyActiveAlt = prefs[Preferences.DEFINITION_OUTPUTCHANNEL_ONLY_ACTIVE_ALTERNATIVES]

          getOutputChannels(fields, id, type, owner, title, altID, onlyActiveAlt, undefined, true)(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_output_channel_error', lang, [outputChannel, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTCHANNEL_DEFINITION_DELETE_OUTPUTCHANNEL_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets a list of output channel definitions they are referenced in other context.
 * @param {String} outputChannel The outputChannel (DCR).
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function verifyOutputChannel(outputChannel, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/outputChannel/verify`, {
        method: 'post',
        body: { DCR: outputChannel }
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          if (rc.toString() === '0016' && irc.toString() === '0000') {
            SnackbarActions.show(Lang.translate('general.verify_error'), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_FAILED, payload: { error } })
        } else {
          dispatch({ type: OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.verify_output_channel_error', lang, [outputChannel, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: OUTPUTCHANNEL_DEFINITION_VERIFY_OUTPUTCHANNEL_FAILED, payload: { error } })
      })
  }
}