import {translate} from 'language/Language'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import * as ConfigRenderer from 'utils/CustomDialogConfigRenderer'
import * as CustomDialogSystem from 'utils/CustomDialogSystemUtils'
import * as CustomDialogUtils from 'utils/CustomDialogUtils'
import * as UserUtils from 'utils/UserUtils'
import * as Utils from 'utils/Utils'

// components
import {
  Button, Card, Checkbox, Column, Dropdown, Icon, Input,
  Modal as ModalComponent, NumericSpinner, Row, Link,
  Tab,
  Tabs
} from 'BetaUX2Web-Components/src'
import CustomDialog from 'components/dialogs/custom_dialog/CustomDialog'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd'
import { CUSTOM_DIALOG_COMMAND_LOGX_JOBS } from 'utils/DefinitionUtils'

const {Modal, Main, Header, Footer} = ModalComponent

// Redux
import {connect} from 'react-redux'
import {
  createCustomDialog,
  getDefaultObjects,
  getIndexDefaultObjects
} from 'redux/actions/CustomDialogDefinitionActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'

import './CreateCustomDialog.scss'
import _ from 'lodash'
import { hasNoValues } from 'utils/ObjectUtils'
import { getAvailableJobTypesCustomDialog } from 'utils/CustomDialogSystemUtils';

const generalIndexIdentifier = CustomDialogUtils.getGeneralIndexIdentifier()

/*
 *
 * //! To make the naming of variables easy as possible, i used the naming "identifier" for all possibile items which could be added through the dropdown on top of assigned
 * //! elements in the UI. The first item of this dropdown is just the real identifier item with the name "Identifier".
 *
 */

class CreateCustomDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    prefilledData: PropTypes.object,
    onClose: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.descriptionInput = React.createRef()
    this.dialogIdInput = React.createRef()
    this.indexNameInput = React.createRef()
    this.nodeIdInput = React.createRef()
    this.defaultState = {
      defaultState: undefined,
      showResultTableDialog: false,
      showNodeDialog: false,
      generalTab: {
        dialogID: {
          value: '',
          error: ''
        },
        owner: '',
        commandIndex: 0,
        jobtypeIndex: 0,
        description: {
          value: '',
          error: ''
        },
        resultTableID: '',
        hitLimitation: 0,
        displayHierarchy: false,
        nodeID: {
          value: '',
          error: ''
        }
      },
      formTab: {
        assigned: [],
        unassigned: [],
        groupedReduxData: {},
        indexIdentifier: [],
        selectedRow: undefined,
        draggingId: null,
        addElementDropdown: 0,
        configs: {},
        activeFormTab: 0,
        isPreviewEnabled: false
      }
    }
    this.state = _.cloneDeep(this.defaultState);
  }

  resetState = () => {
    this.setState(_.cloneDeep(this.defaultState), () => {
      this.getUnAssignedTableData()
    })
  }

  /**
     * @description Sets the initial focus.
     */
  componentDidMount = () => {
    this.dialogIdInput.current.focus()
    if (this.props.prefilledData) {
      this.setState({
        ...this.state,
        generalTab: {
          ...this.state.generalTab,
          dialogID: {
            value: this.props.prefilledData.SPTINAME,
            error: ''
          },
          owner: this.props.prefilledData.OWNER,
          commandIndex: this.getCommandIndex(this.props.prefilledData.SPTUXCMD),
          jobtypeIndex: this.getTypeIndex(this.props.prefilledData.SLTITYPE),
          description: {
            value: this.props.prefilledData.SPTENAME,
            error: ''
          },
          resultTableID: this.props.prefilledData.SLTINAME,
        },
      }, () => {
        this.props.prefilledData.SPTUXCMD ? this.handleChangeGeneralTab('commandIndex', this.getCommandIndex(this.props.prefilledData.SPTUXCMD)) : this.getUnAssignedTableData();
      })
    } else {
      this.getUnAssignedTableData()
    }

  }

  getCommandIndex = (commandName) => {
    return UserUtils.isDOCX()
      ? { 'SELDOC': 0, 'IMPORT': 1, 'SELGBL': 2 }[commandName] ?? 0
      : { 'SELDOC': 0, 'SELJOB': 1, 'SELGBL': 2 }[commandName] ?? 0;
  }

  getTypeIndex = (typeName) => {
    const types = getAvailableJobTypesCustomDialog();
    const index = types.findIndex((obj) => obj.key === typeName)
    return index !== -1 ? index : 0
  }

  /**
     * @description Will be called when the component did update.
     * @param {*} _ The prev props.
     * @param {*} prevState The prev state.
     */
  componentDidUpdate = (_, prevState) => {
    const {formTab} = this.state
    // reset the form tab if the command has been change
    if (formTab.selectedRow) {
      // Resets selection when the selected element was dragged into unassigned container.
      if (formTab.assigned.length < prevState.formTab.assigned.length && formTab.unassigned.length > prevState.formTab.unassigned.length) {
        if (formTab.assigned.findIndex(el => el[0] === formTab.selectedRow.key) === -1) {
          this.setState(state => ({formTab: {...state.formTab, selectedRow: undefined}}))
        }
        // Update the selected index when a element was dragged outside which was positioned before the selected element.
        else {
          this.setState(state => ({
            formTab: {
              ...state.formTab,
              selectedRow: {
                ...state.formTab.selectedRow,
                index: formTab.assigned.findIndex(el => el[0] === formTab.selectedRow.key)
              }
            }
          }))
        }
      }
      if (formTab.assigned.length > prevState.formTab.assigned.length && formTab.unassigned.length < prevState.formTab.unassigned.length) {
        // Update the selected index when a element from outide was dragged before the selected element.
        this.setState(state => ({
          formTab: {
            ...state.formTab,
            selectedRow: {
              ...state.formTab.selectedRow,
              index: formTab.assigned.findIndex(el => el[0] === formTab.selectedRow.key)
            }
          }
        }))
      }
    }
    // Removes the error in the config, when a data type has changed and the unmount process inside the component, which runs after that, sets the old error again.
    // Its not possible to change the unmount function inside the component, because we need it for other functions, so I choose this solution to clear the error.
    let newConfigs = {}
    for (const [key, entry] of Object.entries(formTab.configs)) {
      if (prevState.formTab.configs[key] && formTab.configs[key]) {
        if (prevState.formTab.configs[key].key !== formTab.configs[key].key) {
          // eslint-disable-next-line
                    for (const [entryKey, entryValue] of Object.entries(entry)) {
            if (typeof entryValue === 'object' && entryValue.error && entryValue.error !== '') {
              // If one element has multiple errors and the new config still has values, use the new values which was added before to this object.
              if (newConfigs[key]) {
                newConfigs[key] = {
                  ...newConfigs[key],
                  [entryKey]: {...newConfigs[key][entryKey], error: ''}
                }
              }
              // If the new configs object has no values fill the values from the state.
              else {
                newConfigs[key] = {
                  ...formTab.configs[key],
                  [entryKey]: {...formTab.configs[key][entryKey], error: ''}
                }
              }
            }
          }
        }
      }
    }
    if (Object.keys(newConfigs).length > 0) {
      this.setState(state => ({formTab: {...state.formTab, configs: {...state.formTab.configs, ...newConfigs}}}))
    }
  }

  getCustomDialogState = undefined
  overwriteCustomDialogState = undefined

  getGroupedReduxDateByCommand = (command) => {
    const {defaultObjects} = this.props
    let result = {}
    if (defaultObjects[command]) {
      const data = defaultObjects[command].data

      const headerIndex = name => defaultObjects[command].header.indexOf(name)
      let currentIndexName = data[0][headerIndex('IXINAME')]
      let buffer = []
      // INDEX data is different from all the other date where we get all the different types, so we need to add them by ourself.
      if (command === 'INDEX') {
        defaultObjects[command].data.forEach(entry => {
          currentIndexName = entry[headerIndex('IXINAME')]
          buffer.push(entry)
          if (entry[headerIndex('IDXTYPE')] === 'STRING') {
            let innerBuffer = [...entry]
            innerBuffer[headerIndex('IDXTYPE')] = 'LCHOICE'
            buffer.push(innerBuffer)
            innerBuffer = [...entry]
            innerBuffer[headerIndex('IDXTYPE')] = 'CCHOICE'
            buffer.push(innerBuffer)
          }
          result[currentIndexName] = buffer
          buffer = []
        })
      } else if (command === 'SELGBL') {
        // Adds prefix 'B93' because the implementation should fit the TCL GUI implementation and the rest api returns wrong values without the prefix.
        // I don't know why, but in the TCL GUI the one and only object which gets a special handling is the "EXTENSION".
        currentIndexName = data[0][headerIndex('IXINAME')] === 'EXTENSION' ? 'B93EXT' : `B93${data[0][headerIndex('IXINAME')]}`
        defaultObjects[command].data.forEach((entry, index) => {
          const entryIndexName = entry[headerIndex('IXINAME')] === 'EXTENSION' ? 'B93EXT' : `B93${entry[headerIndex('IXINAME')]}`
          if (entryIndexName !== currentIndexName) {
            result[currentIndexName] = buffer
            currentIndexName = entryIndexName
            buffer = []
          }
          let innerBuffer = [...entry]
          innerBuffer[headerIndex('IXINAME')] = innerBuffer[headerIndex('IXINAME')] === 'EXTENSION' ? 'B93EXT' : `B93${innerBuffer[headerIndex('IXINAME')]}`
          buffer.push(innerBuffer)
          // If its the last element in the default objects and not added to te grouped object adds it
          if (defaultObjects[command].data.length - 1 === index && result[currentIndexName] === undefined) {
            result[currentIndexName] = buffer
          }
        })
      } else {
        defaultObjects[command].data.forEach((entry, index) => {
          if (entry[defaultObjects[command].header.indexOf('IXINAME')] !== currentIndexName) {
            result[currentIndexName] = buffer
            currentIndexName = entry[defaultObjects[command].header.indexOf('IXINAME')]
            buffer = []
          }
          buffer.push(entry)
          // If its the last element in the default objects and not added to te grouped object adds it
          if (defaultObjects[command].data.length - 1 === index && result[currentIndexName] === undefined) {
            result[currentIndexName] = buffer
          }
        })
      }
    }
    return result
  }

  getGroupedReduxDateByJobtype = jobtype => {
    const {defaultObjects} = this.props
    let result = {}

    if (defaultObjects[jobtype]) {
      const data = defaultObjects[jobtype].data

      const headerIndex = name => defaultObjects[jobtype].header.indexOf(name)
      let currentIndexName = data[0][headerIndex('IXINAME')]
      let buffer = []
      defaultObjects[jobtype].data.forEach((entry, index) => {
        if (entry[defaultObjects[jobtype].header.indexOf('IXINAME')] !== currentIndexName) {
          result[currentIndexName] = buffer
          currentIndexName = entry[defaultObjects[jobtype].header.indexOf('IXINAME')]
          buffer = []
        }
        buffer.push(entry)
        // If its the last element in the default objects and not added to te grouped object adds it
        if (defaultObjects[jobtype].data.length - 1 === index && result[currentIndexName] === undefined) {
          result[currentIndexName] = buffer
        }
      })
    }
    return result
  }

  /**
     * @description Gets the unassigned table data.
     */
  getUnAssignedTableData = callback => {
    const {generalTab: {commandIndex, jobtypeIndex}, formTab: {assigned}} = this.state
    const {defaultObjects, availableJobtypes} = this.props
    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key === 'SELGBL'
      ? 'INDEX'
      : CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    let jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    jobtype = jobtype === '' ? 'SELDOC' : jobtype

    const defaultObject = defaultObjects[buxCmd]
      ? defaultObjects[buxCmd]
      : jobtype
        ? defaultObjects[jobtype]
        : undefined


    // group redux data
    const groupedReduxData = {
      'SELDOC': this.getGroupedReduxDateByCommand('SELDOC'),
      'IMPORT': this.getGroupedReduxDateByCommand('IMPORT'),
      'SELGBL': this.getGroupedReduxDateByCommand('SELGBL'),
      'INDEX': this.getGroupedReduxDateByCommand('INDEX'),
      ...Object.fromEntries(availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => [d[1], this.getGroupedReduxDateByJobtype(d[1])]))
    }

    // only use the first entry of each indexname
    let groupedObjects = {
      '': [],
      'SELDOC': [],
      'IMPORT': [],
      'SELGBL': [[Date.now(), '', 'LB']],
      'INDEX': [],
      ...Object.fromEntries(availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => [d[1], []]))
    }

    for (const [key] of Object.entries(groupedReduxData)) {
      // eslint-disable-next-line
      for (const [unused, value] of Object.entries(groupedReduxData[key])) {
        groupedObjects[key].push(value[0])
      }
    }

    const groupedObjectCmdOrJobtype = groupedObjects[buxCmd] ?? groupedObjects[jobtype]

    let tableData = groupedObjectCmdOrJobtype.map(entry => {
      // Get indexname, identifier and type
      return [
        entry[defaultObject.header.indexOf('IXINAME')],
        buxCmd === 'INDEX' ? entry[defaultObject.header.indexOf('IXENAME')] : entry[defaultObject.header.indexOf('SPIENAME')]
      ]
    })
    if (assigned.length > 0) {
      tableData = tableData.filter(d => assigned.find(assignedEl => assignedEl[0] !== d[0]))
    }

    const indexIdentifier = groupedObjects['SELGBL']
    this.setState(state => ({
      formTab: {
        ...state.formTab,
        unassigned: tableData,
        groupedReduxData,
        indexIdentifier
      }
    }), () => callback && callback())

  }

  /**
     * @description Handles the input changes of the input fields.
     * @param {String} key The id the input field.
     * @param {String} value The new value.
     * @param {String} error The new error.
     */
  handleChangeGeneralTab = (key, value, error) => {
    const {availableJobtypes, getDefaultObjects, getIndexDefaultObjects, defaultObjects} = this.props
    let newState = {
      generalTab: {
        ...this.state.generalTab,
        [key]: typeof this.state.generalTab[key] === 'object'
          ? {value, error}
          : value
      }
    }
    if ((key === 'commandIndex' || key === 'jobtypeIndex')) {
      newState = {
        ...newState,
        formTab: {
          assigned: [],
          unassigned: [],
          groupedReduxData: {},
          selectedRow: undefined,
          draggingId: null,
          addElementDropdown: 0,
          configs: {},
          indexIdentifier: []
        }
      }
    }

    this.setState({...newState}, () => {
      if ((key === 'commandIndex' || key === 'jobtypeIndex')) {
        const buxCmd = CustomDialogSystem.getCustomDialogCommands()[this.state.generalTab.commandIndex].key
        const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[this.state.generalTab.jobtypeIndex]
        const promises = []

        // check if data is already in redux
        if (!(Object.keys(defaultObjects).includes(buxCmd)) && buxCmd !== 'SELJOB') {
          promises.push(getDefaultObjects(buxCmd, ''))
          // get the available index elementes when SELGBL is used
          if (buxCmd === 'SELGBL') {
            promises.push(getIndexDefaultObjects())
          }
        } else if (!(Object.keys(defaultObjects).includes(jobtype))) {
          promises.push(getDefaultObjects(buxCmd, jobtype))
        }

        Promise.all(promises).then(() => {
          this.getUnAssignedTableData(() => {
            const {formTab, generalTab} = this.state
            let newAssigned = []
            let newUnassigned = [...formTab.unassigned]
            let newConfigs = {}
            const setJobtypeDefault = index => {
              const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[index]
              const groupedData = formTab.groupedReduxData[jobtype]
              if (groupedData['LOGSOURCE']) {
                newAssigned = [['LOGSOURCE', `${translate('search.obtained')}:`, 'EQ']]
                newUnassigned.splice(newUnassigned.findIndex(d => d[0] === 'LOGSOURCE'), 1)
                let newData = [...groupedData['LOGSOURCE'][0]]
                newConfigs = this.getNewConfigsByType(newData, undefined)
              }
            }
            if (key === 'commandIndex') {
              // automatically add INFILE element when import dialog will be created
              const buxCmd = CustomDialogSystem.getCustomDialogCommands()[value].key
              if (buxCmd === 'IMPORT') {
                const groupedData = formTab.groupedReduxData[buxCmd]
                if (groupedData['INFILE']) {
                  newAssigned = [['INFILE', 'File:', 'EQ']]
                  newUnassigned.splice(newUnassigned.findIndex(d => d[0] === 'INFILE'), 1)
                  let newData = [...groupedData['INFILE'][0]]
                  newConfigs = this.getNewConfigsByType(newData, undefined)
                }
              }
              // automatically add LOGSOURCE element when import dialog will be created
              else if (buxCmd === CUSTOM_DIALOG_COMMAND_LOGX_JOBS) {
                setJobtypeDefault(generalTab.jobtypeIndex)
              }
            } else if (key === 'jobtypeIndex') {
              setJobtypeDefault(generalTab.jobtypeIndex)
            }
            this.setState({
              formTab: {
                ...formTab,
                configs: newConfigs,
                assigned: newAssigned,
                unassigned: newUnassigned
              }
            })
          })
        })
      }
    })
  }

  /**
     * @description Handles the input changes of the id and parentid without spaces.
     * @param {String} key The id the input field.
     * @param {String} value The new value.
     * @param {String} error The new error.
     */
  handleChangeWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleChangeGeneralTab(key, value, error)
  }

  /**
     * @description Validates the dialog id.
     * @returns {Object} Empty object if there is no error. Otherwise returns the new object with an error for the state.
     */
  validateDialogID = () => {
    const {generalTab} = this.state
    if (generalTab.dialogID.value !== '') {
      return {}
    }
    return {
      dialogID: {
        ...this.state.generalTab.dialogID,
        error: translate('general.input_required')
      }
    }
  }

  /**
     * @description Validates the description.
     * @returns {Object} Empty object if there is no error. Otherwise returns the new object with an error for the state.
     */
  validateDescription = () => {
    const {generalTab} = this.state
    if (generalTab.description.value !== '') {
      return {}
    }
    return {
      description: {
        ...this.state.generalTab.description,
        error: translate('general.input_required')
      }
    }
  }

  /**
     * @description Validates the node id.
     * @returns {Object} Empty object if there is no error. Otherwise returns the new object with an error for the state.
     */
  validateNodeID = () => {
    const {generalTab} = this.state
    if (generalTab.displayHierarchy) {
      if (generalTab.nodeID.value !== '') {
        return {}
      }
      return {
        nodeID: {
          ...this.state.generalTab.nodeID,
          error: translate('general.input_required')
        }
      }
    }
    return {}
  }

  /**
     * @description Validates the formtab and focuses the next input fields with an error if exists.
     * @returns {Boolean} True if there are no errors in general tab.
     */
  validateFormTab = () => {
    const {formTab} = this.state
    let errorsFound = 0
    const newConfigs = this.state.formTab.configs
    for (const [configKey, configEntry] of Object.entries(formTab.configs)) {
      // eslint-disable-next-line
            for (const [unused, entryValue] of Object.entries(configEntry)) {
        if (typeof entryValue === 'object' && entryValue.error && entryValue.error !== '') {
          errorsFound += 1
        }
      }
      if (configEntry.required && configEntry.disabled) {
        if (configEntry.value.value === '') {
          newConfigs[configKey].value.error = translate('general.input_required')
          errorsFound += 1
        }
        if (configEntry.type === 'CCHOICE2' && configEntry.value2.value === '') {
          newConfigs[configKey].value2.error = translate('general.input_required')
          errorsFound += 1
        }
      }
      if (configEntry.required2 && configEntry.disabled2) {
        if (['INDEXDEFAULT', 'INDEXLCHOICE', 'INDEXCCHOICE', 'INDEXNUMERIC', 'INDEXDATE'].includes(configEntry.type) && configEntry.operator === 'RA' && configEntry.value2.value === '') {
          newConfigs[configKey].value2.error = translate('general.input_required')
          errorsFound += 1
        }
      }
      if (configEntry.width && configEntry.width < configEntry.value?.value?.length) {
        newConfigs[configKey].value.error = translate('general.invalid_value_length')
        errorsFound += 1
      }
      if (configEntry.type === 'CCHOICE2' && configEntry.width && configEntry.width < configEntry.value2?.value?.length) {
        newConfigs[configKey].value2.error = translate('general.invalid_value_length')
        errorsFound += 1
      }
      if (['CCHOICE', 'CCHOICE2', 'HCHOICE'].includes(configEntry.type)) {
        if (configEntry.dbSelect.value === '') {
          newConfigs[configKey].dbSelect.error = translate('general.input_required')
          errorsFound += 1
        }
      }
      if (configEntry.additionalFields) {
        // validate additional fields
        const newAdditionalFields = [...configEntry.additionalFields]
        for (let i = 0; i < configEntry.additionalFields.length; i++) {
          let newObj = this.validateAdditionalField(configKey, i, 'identifier')
          if (Object.keys(newObj).length > 0) {
            newAdditionalFields[i].identifier = newObj
            errorsFound += 1
          }
          newObj = this.validateAdditionalField(configKey, i, 'value')
          if (Object.keys(newObj).length > 0) {
            newAdditionalFields[i].value = newObj
            errorsFound += 1
          }
        }

        newConfigs[configKey] = {
          ...newConfigs[configKey],
          additionalFields: newAdditionalFields
        }

      }
    }
    if (errorsFound > 0) {
      this.setState(state => ({formTab: {...state.formTab, configs: newConfigs}}), () => {
        this.handleFormTabFocus()
      })
    }
    return errorsFound === 0
  }

  /**
     * @description Validates the general tab and focuses the next input fields with an error if exists.
     * @returns {Boolean} True if there are no errors in general tab.
     */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateDialogID(),
      ...this.validateDescription(),
      ...this.validateNodeID()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState(state => ({generalTab: {...state.generalTab, ...validatorResult}}), () => {
        this.handleGeneralTabFocus()
      })
    }
    return errors === 0
  }

  /**
     * @description Focuses the next input field in general tab with an error.
     */
  handleGeneralTabFocus = () => {
    const {generalTab} = this.state
    const requiredInputs = [
      {inputRef: this.dialogIdInput, error: generalTab.dialogID.error},
      {inputRef: this.descriptionInput, error: generalTab.description.error},
      {inputRef: this.nodeIdInput, error: generalTab.nodeID.error}
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
     * @description Focuses the next input field in format tab with an error.
     */
  handleFormTabFocus = () => {
    const {formTab} = this.state
    const requiredInputs = []

    if (formTab.selectedRow !== undefined) {
      const configKey = formTab.selectedRow.key
      const currentConfig = formTab.configs[configKey]

      for (const [key, value] of Object.entries(currentConfig)) {
        if (key !== 'additionalFields') {
          if (typeof value === 'object' && value.error && value.error !== '') {
            requiredInputs.push({inputRef: value.ref, error: value.error})
          }
        }
      }
      if (currentConfig.additionalFields) {
        currentConfig.additionalFields.forEach(el => {
          if (el.identifier.error !== '') {
            requiredInputs.push({inputRef: el.identifier.focusRef, error: el.identifier.error})
          }
          if (el.value.error !== '') {
            requiredInputs.push({inputRef: el.value.focusRef, error: el.value.error})
          }
        })
      }
    }
    Utils.setFocus(requiredInputs)
  }

  /**
     * @description Handles the result table selector button
     */
  handleResultTableSelector = () => {
    this.props.getResultTableDefinitions(
      ['SLTINAME', 'SLTENAME'],
      this.state.generalTab.resultTableID,
      () => this.setState({showResultTableDialog: true})
    )
  }

  /**
     * @description Handles the node id selector button
     */
  handleNodeSelector = () => {
    this.props.getDocumentNodesDefinition(
      ['DNDNAME', 'DNDENAME'],
      this.state.generalTab.nodeID.value,
      () => this.setState({showNodeDialog: true})
    )
  }

  /**
     * @description Gets the translated commands.
     * @returns {Array} The translated commands.
     */
  getTranslatedCommands = () => CustomDialogSystem.getCustomDialogCommands().map(d => translate(d.translationKey))

  /**
     * @description Reorders an item inside a list.
     * @param {Array} list The list.
     * @param {Number} startIndex The source index of the item to reorder.
     * @param {Number} endIndex The destination index.
     * @return {Array} The reordered list.
     */
  reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  /**
     * @description Gets the new configs object by the type for the state.
     * @param {Object} newData The new data object which includes all information.
     * @param {String} isIdentifier Flag if the data is an identifier.
     * @param {('DEFAULT'|'INDEX')} identifierType The tye of the identifier.
     * @param {number} commandIndex Index of the current command which is used. Could be undefined, then the state value is used.
     * @returns {Object} The new configs object for the state.
     */
  getNewConfigsByType = (newData, identifierType, commandIndex = this.state.generalTab.commandIndex) => {
    const {generalTab: {jobtypeIndex}, formTab} = this.state
    const {defaultObjects, availableJobtypes} = this.props
    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const configs = {...this.state.formTab.configs}
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]

    if (identifierType === 'DEFAULT') {
      // newData[0] is the artifical unique key for an identifier.
      configs[newData[0]] = {
        indexName: '',
        type: 'IDENTIFIER',
        identifier: '',
        operator: 'LB'
      }
      return configs
    }

    let newAdditionalFields = []

    // We need artificial types for index elements because they need a different render function.
    const translateIndexType = input => {
      return {
        'STRING': 'INDEXDEFAULT',
        'CCHOICE': 'INDEXCCHOICE',
        'LCHOICE': 'INDEXLCHOICE',
        'NUMERIC': 'INDEXNUMERIC',
        'DATE': 'INDEXDATE',
        'RNUM': 'INDEXRNUM'
      }[input]
    }
    let cmdToUse = buxCmd !== 'SELJOB' ? buxCmd : jobtype
    // Take index name from default position. This variable contains the key, which is used for the search request.
    let indexName = newData[defaultObjects[cmdToUse].header.indexOf('IXINAME')]
    // This is used for elements which have two elements in one row (e.g. AMOUNT, DATEDATE, DATETIME). This variable contains the second key which is used for the search request.
    let secondIndexName = newData[defaultObjects[cmdToUse].header.indexOf('SPIINAM2')]
    // Different data extraction because of index route return values in a different structure
    // (e.g. header 'IXINAME' has index 1 in index route, in all other route it has the index 0)
    if (cmdToUse === 'SELGBL' && !generalIndexIdentifier.includes(indexName)) {
      cmdToUse = 'INDEX'
      indexName = newData[defaultObjects[cmdToUse].header.indexOf('IXINAME')]
    }

    let newType = cmdToUse === 'INDEX'
      ? translateIndexType(newData[defaultObjects[cmdToUse].header.indexOf('IDXTYPE')])
      : newData[defaultObject.header.indexOf('SPIDTYPE')]
    if (newType === 'CCHOICE' || newType === 'CCHOICE2' || newType === 'HCHOICE' ||
            newType === 'LCHOICE' || newType === 'SCHOICE' || newType === 'MCHOICE') {
      let identifiers = newData[defaultObject.header.indexOf('SPIENAM2')]
      let values = newData[defaultObject.header.indexOf('SPIVALU3')]
      // Just prefill additional fields when SPIENAM2 and SPIVALU3 are not empty.
      if (identifiers !== '' && values !== '') {
        identifiers = identifiers.split(';')
        values = values.split(';')
        for (let i = 0; i < identifiers.length; i++) {
          newAdditionalFields.push({
            identifier: {value: identifiers[i], error: '', focusRef: React.createRef()},
            value: {value: values[i], error: '', focusRef: React.createRef()}
          })
        }
      }
    }

    // If SDATE is added and EDATE still exist in configs, the type of SDATE have to be DATETIME
    if (['SDATE', 'B93SDATE'].includes(indexName) && (formTab.configs['EDATE'] || formTab.configs['B93EDATE'])) {
      newType = 'DATETIME'
    }
    if (cmdToUse === 'INDEX') {
      configs[indexName] = {
        indexName,
        type: newType,
        width: newData[defaultObjects[cmdToUse].header.indexOf('IDXLNG')],
        identifier: newData[defaultObjects[cmdToUse].header.indexOf('IXENAME')],
        identifier2: {value: '', error: '', ref: React.createRef()},
        value: {value: '', error: '', ref: React.createRef()},
        value2: {value: '', error: '', ref: React.createRef()},
        value3: {value: '', error: '', ref: React.createRef()},
        operator: indexName === 'DTFRAME' ? 'DF' : 'EQ',
        required: newData[defaultObjects[cmdToUse].header.indexOf('SPIVALR')] === '*',
        required2: false,
        disabled: newData[defaultObjects[cmdToUse].header.indexOf('SPIVALD')] === '*',
        disabled2: false,
        dbSelect: {value: '', error: '', ref: React.createRef()},
        additionalFields: newAdditionalFields,
        key: parseInt(`${Date.now()}${Math.floor(Math.random() * 1000) + 1}`),
        uppercase: newData[defaultObjects[cmdToUse].header.indexOf('SPIUCASE')]
      }
    } else {
      configs[indexName] = {
        indexName,
        secondIndexName,
        type: newType,
        width: parseInt(newData[defaultObject.header.indexOf('SPIWEFLD')]),
        identifier: newData[defaultObject.header.indexOf('SPIENAME')],
        identifier2: {
          value: newData[defaultObject.header.indexOf('SPIENAM2')],
          error: '',
          ref: React.createRef()
        },
        value: {
          value: newData[defaultObject.header.indexOf('SPIVALUE')] !== undefined ? newData[defaultObject.header.indexOf('SPIVALUE')] : '',
          error: '',
          ref: React.createRef()
        },
        value2: {value: newData[defaultObject.header.indexOf('SPIVALU2')], error: '', ref: React.createRef()},
        value3: {value: newData[defaultObject.header.indexOf('SPIVALU3')], error: '', ref: React.createRef()},
        required: newData[defaultObject.header.indexOf('SPIVALR')] === '*',
        required2: false,
        disabled: newData[defaultObject.header.indexOf('SPIVALD')] === '*',
        disabled2: false,
        dbSelect: {value: newData[defaultObject.header.indexOf('SPIENAM3')], error: '', ref: React.createRef()},
        additionalFields: newAdditionalFields,
        key: parseInt(`${Date.now()}${Math.floor(Math.random() * 1000) + 1}`),
        uppercase: newData[defaultObject.header.indexOf('SPIUCASE')]
      }
    }

    return configs
  }

  isDragOutsideAllowed = indexName => {
    const {generalTab: {commandIndex}} = this.state
    const {showSnackbar} = this.props
    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    let result = true
    if (buxCmd === 'IMPORT') {
      if (indexName === 'INFILE') {
        result = false
        showSnackbar(translate('error.infile_required'), SnackbarActions.TYPE_ERROR)
      }
    }
    if (buxCmd === CUSTOM_DIALOG_COMMAND_LOGX_JOBS) {
      if (indexName === 'LOGSOURCE') {
        result = false
        showSnackbar(translate('error.logsource_required'), SnackbarActions.TYPE_ERROR)
      }
    }
    return result
  }

  addItemAllowed = indexName => {
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const {lang, showSnackbar, availableJobtypes} = this.props
    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    let result = true
    let excludeItems = []
    if (buxCmd === 'SELDOC') {
      excludeItems = [
        ['SDATE', 'FROMLAST'],
        ['EDATE', 'FROMLAST'],
        ['DTFRAME', 'FROMLAST'],
        ['DTFRAME', 'SDATE'],
        ['DTFRAME', 'EDATE'],
        ['FORM', 'FORMEXT'],
        ['EXTENSION', 'FORMEXT'],
        ['RECI', 'NODE']
      ]
      // Just allow EDATE to be added when SDATE has format DATETIME
      if (formTab.configs['SDATE'] && formTab.configs['SDATE'].type === 'DATEDATE') {
        excludeItems.push(['SDATE', 'EDATE'])
      }
    } else if (buxCmd === 'IMPORT') {
      excludeItems = [
        ['FORM', 'FORMEXT'],
        ['EXTENSION', 'FORMEXT']
      ]
    } else if (buxCmd === 'SELGBL') {
      excludeItems = [
        ['B93SDATE', 'B93FROMLAST'],
        ['B93EDATE', 'B93FROMLAST'],
        ['B93DTFRAME', 'B93FROMLAST'],
        ['B93DTFRAME', 'B93SDATE'],
        ['B93DTFRAME', 'B93EDATE'],
        ['B93FORM', 'B93FORMEXT'],
        ['B93EXT', 'B93FORMEXT'],
        ['B93RECI', 'B93NODE']
      ]
      if (formTab.configs['B93SDATE'] && formTab.configs['B93SDATE'].type === 'DATEDATE') {
        excludeItems.push(['B93SDATE', 'B93EDATE'])
      }
    } else if (['BRWUC4', 'BRWZOS', 'BRWCTM'].includes(jobtype)) {
      excludeItems = [
        ['SDATE', 'FROMLAST'],
        ['EDATE', 'FROMLAST'],
        ['DTFRAME', 'FROMLAST'],
        ['DTFRAME', 'SDATE'],
        ['DTFRAME', 'EDATE']
      ]
    }
    excludeItems.forEach(rule => {
      if (rule.includes(indexName)) {
        for (let i = 0; i < formTab.assigned.length; i++) {
          if (rule.includes(formTab.assigned[i][0])) {
            result = false
            // Builds param array which is used inside the error message.
            const params = [indexName, rule.filter(d => d !== indexName)[0]]
            let messageKey = 'definitions.custom_dialog_element_blocked'
            // If the first parameter is EDATE or B93EDATE and the second parameter is SDATE or B93SDATE, another error message is thrown for better understanding.
            if (['EDATE', 'B93EDATE'].includes(params[0]) && ['SDATE', 'B93SDATE'].includes(params[1])) {
              messageKey = 'definitions.custom_dialog_element_blocked_sdate'
            }
            showSnackbar(translate(messageKey, lang, params), SnackbarActions.TYPE_ERROR)
          }
        }
      }
    })
    return result
  }

  /**
     * @description Moves an item from a list to another.
     * @param {Array} source The source list.
     * @param {Array} destination The destination list.
     * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
     * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
     * @returns {Object} An object with the new source and destination lists.
     */
  move = (source, destination, droppableSource, droppableDestination) => {
    const {defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key === 'SELGBL'
      ? 'INDEX'
      : CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ?? defaultObjects[jobtype]
    const groupedData = formTab.groupedReduxData[buxCmd] ?? formTab.groupedReduxData[jobtype]
    const sourceClone = [...source]
    const destClone = [...destination]
    let [removed] = sourceClone.splice(droppableSource.index, 1)
    // If its not an identifier.
    const isNotIdentifier = buxCmd === 'INDEX'
      ? !generalIndexIdentifier.includes(removed[0]) && typeof removed[0] !== 'number'
      : typeof removed[0] !== 'number'
    // show indexname and identifier and operator on assigned table
    if (droppableDestination.droppableId === 'assigned') {
      const indexName = removed[0]
      if (this.addItemAllowed(indexName)) {
        let newData = groupedData[indexName][0]

        // SPIOP is the operator
        removed.splice(
          2,
          0,
          buxCmd === 'INDEX'
            ? 'EQ'
            : indexName === 'DTFRAME'
              ? 'DF'
              : newData[defaultObject.header.indexOf('SPIOP')]
        )

        const newConfigs = this.getNewConfigsByType(newData)

        // init the config
        this.setState({formTab: {...this.state.formTab, configs: newConfigs}})
      } else {
        return
      }
    } else {
      if (this.isDragOutsideAllowed(removed[0])) {
        if (isNotIdentifier) {
          // Get removed element from grouped available entries
          removed = [...groupedData[removed[0]][0]]
          // Just take information we want to display
          if (buxCmd !== 'INDEX') {
            removed = removed.splice(0, 2)
          } else {
            removed = [removed[defaultObject.header.indexOf('IXINAME')], removed[defaultObject.header.indexOf('IXENAME')]]
          }
        }

        // Remove element from configs.
        let newConfigs = formTab.configs
        delete newConfigs[removed[0]]
        this.setState(state => ({
          formTab: {
            ...state.formTab,
            configs: newConfigs,
            indexIdentifier: this.updateIndexIdentifierItems(newConfigs)
          }
        }))
      } else {
        return
      }
    }

    // Just add the element when the dragged element was not an identifier.
    if (isNotIdentifier) {
      destClone.splice(droppableDestination.index, 0, removed)
    }

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    return result
  }

  /**
     * @description Sets the width and visibility properties for group dragging.
     * @param {Object} dragStart The dragStart object.
     */
  onBeforeDragStart = dragStart => {
    // get the id which is dragged
    let id = this.state.formTab[dragStart.source.droppableId][dragStart.source.index]

    this.setState({formTab: {...this.state.formTab, draggingId: id}})
  }

  /**
     * @description Performes the single drag action.
     * @param {Object} result Includes the new lists.
     */
  singleDrag = result => {
    const {formTab} = this.state
    const {source, destination} = result

    // dropped outside the list
    if (!destination) {
      this.setState({formTab: {...this.state.formTab, draggingId: null}})
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorder(
        this.state.formTab[source.droppableId],
        source.index,
        destination.index
      )

      // Update the selected row when reorder elements.
      let selectedRow
      if (source.droppableId === 'assigned' && formTab.selectedRow) {
        selectedRow = {
          index: items.findIndex(el => el[0] === formTab.selectedRow.key),
          key: formTab.selectedRow.key
        }
      } else {
        selectedRow = formTab.selectedRow
      }

      this.setState({
        formTab: {
          ...this.state.formTab,
          [source.droppableId]: items,
          draggingId: null,
          selectedRow
        }
      })
      // move if the dnd was between different lists
    } else {
      const result = this.move(
        this.state.formTab[source.droppableId],
        this.state.formTab[destination.droppableId],
        source,
        destination
      )
      if (!result) {
        return
      }
      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState(state => ({
        formTab: {
          ...state.formTab,
          [source.droppableId]: result[source.droppableId],
          [destination.droppableId]: result[destination.droppableId],
          draggingId: null
        }
      }))
    }
  }

  /**
     * @description The drag end action from dnd.
     * @param {Object} result The result from dnd.
     */
  onDragEnd = (result) => {
    this.singleDrag(result)
  }

  /**
     * @description Creates the table row action for the datatable.
     * @param {Array} data The data of the datatable.
     * @param {Number} index The index of the row.
     * @param {Object} event The event which is thrown on clicking the row.
     */
  handleSelect = (event, index) => {
    const {formTab} = this.state
    if (event.defaultPrevented) {
      return
    }

    if (event.button !== 0) {
      return
    }

    event.preventDefault()
    if (formTab.selectedRow === index) {
      this.setState(state => ({formTab: {...state.formTab, selectedRow: undefined}}))
    } else {
      // Get this key here and not as parameter because we need the "clean" data to make sure we get the right key.
      // Rendered data are modified to display the identifier entries in right way.
      const key = formTab.assigned[index][0]
      this.setState(state => ({formTab: {...state.formTab, selectedRow: {index, key}}}))
    }
  }

  /**
     * @description Deletes an assigned element.
     * @param {Number} index The index of the element to delete.
     */
  handleDelete = index => {
    const {generalTab, formTab} = this.state
    const {defaultObjects, availableJobtypes} = this.props
    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[generalTab.commandIndex].key === 'SELGBL'
      ? 'INDEX'
      : CustomDialogSystem.getCustomDialogCommands()[generalTab.commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[generalTab.jobtypeIndex]
    // If its an identifier.
    const isIdentifier = buxCmd === 'INDEX'
      ? generalIndexIdentifier.includes(formTab.assigned[index][0]) || typeof formTab.assigned[index][0] === 'number'
      : typeof formTab.assigned[index][0] === 'number'
    if (isIdentifier) {
      // Remove element from config
      let key = formTab.assigned[index][0]
      let newConfigs = formTab.configs
      delete newConfigs[key]

      const newAssigned = formTab.assigned.filter((_, i) => i !== index)
      // Updates the selected row when one netry was deleted.
      const selectedIndex = formTab.selectedRow ? newAssigned.findIndex(el => el[0] === formTab.selectedRow.key) : -1
      const newSelectedRow = selectedIndex !== -1
        ? {index: selectedIndex, key: formTab.selectedRow.key}
        : undefined
      this.setState({
        formTab: {
          ...this.state.formTab,
          assigned: newAssigned,
          selectedRow: newSelectedRow,
          configs: newConfigs,
          indexIdentifier: this.updateIndexIdentifierItems(newConfigs),
          addElementDropdown: 0
        }
      })
    } else {
      // Remove element from config
      let key = formTab.assigned[index][0]
      let newConfigs = formTab.configs
      delete newConfigs[key]
      // Get removed element from grouped available entries
      const groupedReduxDataToPush = formTab.groupedReduxData[buxCmd]
        ? [...formTab.groupedReduxData[buxCmd][key][0]]
        : [...formTab.groupedReduxData[jobtype][key][0]]
      let toPush = groupedReduxDataToPush
      if (buxCmd === 'INDEX') {
        toPush = [toPush[defaultObjects[buxCmd].header.indexOf('IXINAME')], toPush[defaultObjects[buxCmd].header.indexOf('IXENAME')]]
      } else {
        toPush = toPush.splice(0, 2)
      }
      // Just take information we want to display
      const newUnassigned = [...formTab.unassigned, toPush]
      const newAssigned = formTab.assigned.filter((_, i) => i !== index)
      // Updates the selected row when one netry was deleted.
      const selectedIndex = formTab.selectedRow ? newAssigned.findIndex(el => el[0] === formTab.selectedRow.key) : -1
      const newSelectedRow = formTab.selectedRow && selectedIndex !== -1
        ? {index: selectedIndex, key: formTab.selectedRow.key}
        : undefined
      this.setState(state => ({
        formTab: {
          ...state.formTab,
          unassigned: newUnassigned,
          assigned: newAssigned,
          selectedRow: newSelectedRow,
          configs: newConfigs
        }
      }))
    }
  }

  updateIndexIdentifierItems = newConfig => {
    const {formTab} = this.state
    // Array which stores all used identifier keys.
    const usedIdentifierKeys = Object.keys(formTab.configs).filter(key => typeof key === 'number')
    let randomNumber = Math.floor(Math.random() * 1000) + 1
    // Generate new artificial key for identifer.
    let identifierKey = parseInt(`${Date.now()}${randomNumber}`)
    // Check if the key is in use and create a new one if the key is still used.
    while (usedIdentifierKeys.includes(identifierKey)) {
      randomNumber = Math.floor(Math.random() * 1000) + 1
      identifierKey = parseInt(`${Date.now()}${randomNumber}`)
    }
    const indexIdentifier = [[identifierKey, '', 'LB']]
    // eslint-disable-next-line
        for (const [unused, value] of Object.entries(formTab.groupedReduxData['SELGBL'])) {
      indexIdentifier.push(value[0])
    }
    const configKeys = Object.keys(newConfig)
    return indexIdentifier.filter(d => !configKeys.includes(d[0]))
  }

  /**
     * @description Adds an identifier element to the bottom of the assigned elements.
     */
  addIdentifier = () => {
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const {defaultObjects, availableJobtypes} = this.props
    const newAssigned = formTab.assigned
    let buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    // Added convertion of buxCmd because its stored differently in the defaultObjects.
    if (buxCmd === 'SELJOB') {
      buxCmd = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    }
    // Array which stores all identifier keys.
    const usedIdentifierKeys = Object.keys(formTab.configs).filter(key => typeof key === 'number')
    let randomNumber = Math.floor(Math.random() * 1000) + 1
    // Generate new artificial key for identifer.
    let identifierKey = parseInt(`${Date.now()}${randomNumber}`)
    // Check if the key is in use and create a new one if the key is still used.
    while (usedIdentifierKeys.includes(identifierKey)) {
      randomNumber = Math.floor(Math.random() * 1000) + 1
      identifierKey = parseInt(`${Date.now()}${randomNumber}`)
    }
    const indentifierForConfig = buxCmd === 'SELGBL'
      ? formTab.indexIdentifier[formTab.addElementDropdown]
      : [identifierKey, '', 'LB']

    const identifierToShow = [...indentifierForConfig]
    const indexName = indentifierForConfig[defaultObjects[buxCmd].header.indexOf('IXINAME')]
    if (this.addItemAllowed(indexName)) {
      if (buxCmd === 'SELGBL' && formTab.addElementDropdown > 0) {
        const operator = indentifierForConfig[defaultObjects[buxCmd].header.indexOf('SPIOP')]
        // Cuts of all information with index > 2 out of array.
        identifierToShow.splice(2, identifierToShow.length)
        identifierToShow.push(operator)
      }

      // When one entry is selected, adds the identifier after the selected element. If not, adds the identifier to end.
      formTab.selectedRow
        ? newAssigned.splice(formTab.selectedRow.index + 1, 0, identifierToShow)
        : newAssigned.push(identifierToShow)

      // This variable stored the inforamtion, if an element was added through the dropdown or through drag and drop.
      const identifier = buxCmd === 'SELGBL'
        ? formTab.addElementDropdown > 0
          ? 'INDEX'
          : 'DEFAULT'
        : 'DEFAULT'

      const newConfigs = this.getNewConfigsByType(indentifierForConfig, identifier)

      this.setState(state => ({
        formTab: {
          ...state.formTab,
          assigned: newAssigned,
          configs: newConfigs,
          indexIdentifier: this.updateIndexIdentifierItems(newConfigs),
          addElementDropdown: 0
        }
      }))
    }
  }

  /**
     * @description Changes the type of the selected config.
     * @param {String} configKey The current config key.
     * @param {String} value The new type value.
     */
  changeType = (configKey, value) => {
    const {defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    let buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    if (!generalIndexIdentifier.includes(configKey) && buxCmd === 'SELGBL') {
      buxCmd = 'INDEX'
    } else if (buxCmd === 'SELJOB') {
      buxCmd = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    }
    const typeIndex = buxCmd === 'INDEX' ? defaultObjects[buxCmd].header.indexOf('IDXTYPE') : defaultObjects[buxCmd].header.indexOf('SPIDTYPE')
    const newData = formTab.groupedReduxData[buxCmd][formTab.configs[configKey].indexName].find(el => el[typeIndex] === value)
    const newConfigs = {...this.getNewConfigsByType(newData)}
    let newAssigned = formTab.assigned
    const assignedToChange = newAssigned.find(el => el[0] === configKey)
    assignedToChange[1] = newConfigs[configKey].identifier
    // Reset operator when change index type.
    if (buxCmd === 'INDEX') {
      assignedToChange[2] = 'EQ'
    }

    this.setState({formTab: {...this.state.formTab, configs: newConfigs}})
  }

  /**
     * @description Changes the type of a config.
     * @param {String} configKey The config key.
     * @param {String} value The new type value.
     */
  changeConfigValue = (configKey, valueKey, value, error) => {
    const {formTab} = this.state
    const {defaultObjects} = this.props
    const newConfigs = formTab.configs
    // onBlur function useses this function, so we need to check, if the config key is still available. If the component is unmounted, the on blur is executed too, but there is no
    // value anymore in configs.
    if (newConfigs[configKey]) {
      newConfigs[configKey] = {
        ...this.state.formTab.configs[configKey],
        [valueKey]: typeof newConfigs[configKey][valueKey] === 'object'
        // ref is always part of object so we need to spread this object
          ? error && error !== ''
            ? {...newConfigs[configKey][valueKey], error}
            : {...newConfigs[configKey][valueKey], value, error: ''}
          : value
      }

      // if required an disabled switchable button is turned off, remove the error from the input field when existing
      const specialConfigKeys = ['required', 'disabled', 'required2', 'disabled2']
      if (specialConfigKeys.includes(valueKey) && (!newConfigs[configKey].required || !newConfigs[configKey].disabled) && newConfigs[configKey].value.error !== '') {
        newConfigs[configKey].value.error = ''
      }
      if (specialConfigKeys.includes(valueKey) && (!newConfigs[configKey].required2 || !newConfigs[configKey].disabled2) && newConfigs[configKey].value2.error !== '') {
        newConfigs[configKey].value2.error = ''
      }

      const newAssigned = formTab.assigned
      if (['identifier', 'operator'].includes(valueKey)) {
        const indexToUpdateValue = {'identifier': 1, 'operator': 2}[valueKey]
        const elementToUpdate = newAssigned.find(el => el[0] === formTab.selectedRow.key)
        elementToUpdate[indexToUpdateValue] = value
        const elHasStringAsType = formTab.groupedReduxData['INDEX'][configKey]
          ? formTab.groupedReduxData['INDEX'][configKey].find(el => el[defaultObjects['INDEX'].header.indexOf('IDXTYPE')] === 'STRING') !== undefined
          : false
        if (valueKey === 'operator') {
          // resets the error when the operator changes so just one value is displayed
          if (value === 'EQ') {
            if (newConfigs[configKey].value2.error !== '') {
              newConfigs[configKey].value2.error = ''
            }
          }
          if (value === 'RA') {
            // Reset type dropdown to "STRING" when an index element operator was changed to "RANGE" and the index element has "STRING" as possible type
            if (elHasStringAsType) {
              newConfigs[configKey] = {
                ...newConfigs[configKey],
                type: 'INDEXDEFAULT'
              }
            }
            if (newConfigs[configKey].dbSelect.error !== '') {
              newConfigs[configKey].dbSelect.error = ''
            }
          }
        }
      }
      this.setState(state => ({formTab: {...state.formTab, configs: newConfigs, assigned: newAssigned}}))
    }
  }

  inputHasValue = (value, configKey, inputKey,) => {
    if (value.replace(/\s/g, '') === '') {
      this.changeConfigValue(configKey, inputKey, value, translate('general.input_required'))
    }
  }

  /**
     * @description Changes the type of a config.
     * @param {String} configKey The config key.
     * @param {String} value The new type value.
     */
  changeConfigValueNumeric = (configKey, valueKey, value, error) => {
    const {formTab} = this.state
    const {defaultObjects} = this.props
    const newConfigs = formTab.configs
    const oldWidth = newConfigs[configKey].width
    if (value.toString().match(/^[0-9]*$/)) {
      newConfigs[configKey] = {
        ...newConfigs[configKey],
        [valueKey]: typeof newConfigs[configKey][valueKey] === 'object'
          ? {...newConfigs[configKey][valueKey], value, error: error || ''}
          : value
      }

      // resets error when the length changes and the value length fits into the new length
      if (valueKey === 'width') {
        if (newConfigs[configKey]?.value?.value.length > oldWidth && newConfigs[configKey]?.value?.value?.length <= value) {
          newConfigs[configKey].value.error = ''
        }
        if (newConfigs[configKey].type === 'CCHOICE2' && newConfigs[configKey]?.value2?.value.length > oldWidth && newConfigs[configKey]?.value2?.value?.length <= value) {
          newConfigs[configKey].value2.error = ''
        }
      }

      const newAssigned = formTab.assigned
      if (['identifier', 'operator'].includes(valueKey)) {
        const indexToUpdateValue = {'identifier': 1, 'operator': 2}[valueKey]
        const elementToUpdate = newAssigned.find(el => el[0] === formTab.selectedRow.key)
        elementToUpdate[indexToUpdateValue] = value
        const elHasStringAsType = formTab.groupedReduxData['INDEX'][configKey]
          ? formTab.groupedReduxData['INDEX'][configKey].find(el => el[defaultObjects['INDEX'].header.indexOf('IDXTYPE')] === 'STRING') !== undefined
          : false
        // Reset type dropdown to "STRING" when an index element operator was changed to "RANGE" and the index element has "STRING" as possible type
        if (valueKey === 'operator' && value === 'RA' && elHasStringAsType) {
          newConfigs[configKey] = {
            ...newConfigs[configKey],
            type: 'INDEXDEFAULT'
          }
        }
      }
      this.setState({formTab: {...this.state.formTab, configs: newConfigs, assigned: newAssigned}})
    }
  }

  /**
     * @description Adds an additional field to the current config.
     * @param {String} configKey The current config key.
     */
  addAdditionalField = configKey => {
    const newConfigs = this.state.formTab.configs
    if (newConfigs[configKey].additionalFields.length < 10) {
      const newAdditionalFields = newConfigs[configKey].additionalFields.length > 0 ? [...newConfigs[configKey].additionalFields] : []
      newAdditionalFields.push({
        identifier: {value: '', error: '', focusRef: React.createRef()},
        value: {value: '', error: '', focusRef: React.createRef()}
      })

      newConfigs[configKey] = {
        ...newConfigs[configKey],
        additionalFields: newAdditionalFields
      }

      this.setState({formTab: {...this.state.formTab, configs: newConfigs}})
    }
  }

  /**
     * @description Changes the additional fields for a specific index.
     * @param {String} configKey The current config key.
     * @param {String} index The index of the config to modify.
     * @param {String} key
     * @param {String} value The new value value.
     */
  changeAdditionalField = (configKey, index, key, value) => {
    const newConfigs = this.state.formTab.configs
    const newAdditionalFields = [...newConfigs[configKey].additionalFields]
    newAdditionalFields[index][key] = {...newAdditionalFields[index][key], value, error: ''}

    newConfigs[configKey] = {
      ...newConfigs[configKey],
      additionalFields: newAdditionalFields
    }

    this.setState({formTab: {...this.state.formTab, configs: newConfigs}})
  }

  /**
     * @description On blur the additional fields for a specific index.
     * @param {String} configKey The current config key.
     * @param {String} index The index of the config to modify.
     */
  onBlurAdditionalField = (configKey, index, key) => {
    const newConfigs = this.state.formTab.configs

    if (newConfigs[configKey] !== undefined && newConfigs[configKey].additionalFields[index] !== undefined) {
      const newObj = this.validateAdditionalField(configKey, index, key)

      if (Object.keys(newObj).length > 0) {
        const newAdditionalFields = [...newConfigs[configKey].additionalFields]
        newAdditionalFields[index][key] = newObj

        newConfigs[configKey] = {
          ...newConfigs[configKey],
          additionalFields: newAdditionalFields
        }

        this.setState({formTab: {...this.state.formTab, configs: newConfigs}})
      }
    }
  }

  /**
     * @description Validates a specific additional field of the current config.
     * @param {String} configKey The current config key.
     * @param {Number} index The index of the current additional field in the config.
     * @returns {Object} Empty object if there is no error. Otherwise returns the new object with an error for the state.
     */
  validateAdditionalField = (configKey, index, key) => {
    const {formTab} = this.state
    if (formTab.configs[configKey].additionalFields[index][key].value === '') {
      return {
        ...formTab.configs[configKey].additionalFields[index][key],
        error: translate('general.input_required')
      }
    }
    return {}
  }

  /**
     * @description Deletes a specific additional field.
     * @param {String} configKey The current config key.
     * @param {Number} index The index of the additional field to delete.
     */
  deleteAdditionalField = (configKey, index) => {
    const newConfigs = this.state.formTab.configs
    const newAdditionalFields = [...newConfigs[configKey].additionalFields]
    newAdditionalFields.splice(index, 1)

    newConfigs[configKey] = {
      ...newConfigs[configKey],
      additionalFields: newAdditionalFields
    }

    this.setState({formTab: {...this.state.formTab, configs: newConfigs}})
  }

  validateValueInput = configKey => {
    const newConfigs = this.state.formTab.configs
    if (newConfigs[configKey]) {
      if (newConfigs[configKey].required && newConfigs[configKey].disabled) {
        if (newConfigs[configKey].value?.value === '') {
          this.changeConfigValue(configKey, 'value', newConfigs[configKey].value.value, translate('general.input_required'))
        }
        if (newConfigs[configKey].type === 'CCHOICE2' && newConfigs[configKey].value2?.value === '') {
          this.changeConfigValue(configKey, 'value2', newConfigs[configKey].value2.value, translate('general.input_required'))
        }
      } else if (newConfigs[configKey].width < newConfigs[configKey].value?.value?.length) {
        this.changeConfigValue(configKey, 'value', newConfigs[configKey].value.value, translate('general.invalid_value_length'))
      }
      if (!newConfigs[configKey].operator) {
        if (['DATEDATE', 'DATETIME'].includes(newConfigs[configKey].type) && newConfigs[configKey].required2 && newConfigs[configKey].disabled2 && newConfigs[configKey].value2?.value === '') {
          this.changeConfigValue(configKey, 'value2', newConfigs[configKey].value2.value, translate('general.input_required'))
        }
      } else {
        if (newConfigs[configKey].operator === 'EQ') {
          if (['CCHOCIE', 'HCHOICE', 'INDEXCCHOICE'].includes(newConfigs[configKey].type) && newConfigs[configKey].dbSelect.value === '') {
            this.changeConfigValue(configKey, 'dbSelect', newConfigs[configKey].dbSelect.value, translate('general.input_required'))
          }
        } else if (newConfigs[configKey].operator === 'RA') {
          if (newConfigs[configKey].required2 && newConfigs[configKey].disabled2 && newConfigs[configKey].value2?.value === '' && newConfigs[configKey].value2?.error === '') {
            this.changeConfigValue(configKey, 'value2', newConfigs[configKey].value2.value, translate('general.input_required'))
          }
          if (newConfigs[configKey].dbSelect?.error !== '') {
            this.changeConfigValue(configKey, 'dbSelect', newConfigs[configKey].dbSelect.value, translate(''))
          }
        }
      }
    }
  }

  /**
     * @description Gets the error tabs.
     * @returns {Array} The error tabs.
     */
  handleErrorTabs = () => {
    const {generalTab} = this.state
    const buffer = []
    if (generalTab.dialogID.error !== '' || generalTab.description.error !== '' || generalTab.nodeID.error !== '') {
      buffer.push(0)
    }
    if (this.hasFormTabErrors()) {
      buffer.push(1)
    }
    return buffer
  }

  /**
     * @description Gets the error tabs.
     * @returns {Array} The error tabs.
     */
  handleErrorTabsAssigned = () => {
    const buffer = []
    if (this.hasFormTabErrors()) {
      buffer.push(0)
    }
    return buffer
  }

  /**
     * @description Checks if the formtab has errors.
     * @returns {Boolean} True if the formtab has an error.
     */
  hasFormTabErrors = () => {
    const {formTab} = this.state

    // eslint-disable-next-line
        for (const [unused, value] of Object.entries(formTab.configs)) {
      // eslint-disable-next-line
            for (const [unused, entryValue] of Object.entries(value)) {
        if (typeof entryValue === 'object' && entryValue.error && entryValue.error !== '') {
          return true
        }
      }
      if (value.additionalFields) {
        for (let i = 0; i < value.additionalFields.length; i++) {
          if (value.additionalFields[i].identifier.error !== '' || value.additionalFields[i].value.error !== '') {
            return true
          }
        }
      }
    }
    return false
  }

  /**
     * @description Builds the search profile object for the request.
     * @returns {Array} The search profile objects.
     */
  buildSearchProfileObjects = () => {
    const {generalTab, formTab} = this.state
    const {datemask} = this.props
    const result = []
    let counter = 0
    formTab.assigned.forEach(el => {
      const currentConfigElement = formTab.configs[el[0]]

      // build the object by the config type
      let buffer = CustomDialogUtils.buildObjectByType(currentConfigElement, datemask, generalTab.dialogID.value, counter)

      result.push(buffer)
      counter++
    })
    return result
  }

  /**
     * @description Renders the command section depending on DOCX or LOGX system
     */
  renderCommand = () => {
    const {id} = this.props
    const {generalTab: {commandIndex}} = this.state
    if (UserUtils.isDOCX()) {
      return (
        <CustomDialogSystem.CommandDocx
          id={id}
          commandIndex={commandIndex}
          handleInputChanged={this.handleChangeGeneralTab}
        />
      )
    }
    return (
      <CustomDialogSystem.CommandLogx
        id={id}
        commandIndex={commandIndex}
        handleInputChanged={this.handleChangeGeneralTab}
      />
    )
  }

  /**
     * @description Validates the data and saves the custom dialog.
     */
  handleSave = () => {
    const {generalTab} = this.state
    const {createCustomDialog, onClose, availableJobtypes} = this.props
    const errorTabs = [
      this.validateGeneralTab(),
      this.validateFormTab()
    ]

    const command = CustomDialogSystem.getCustomDialogCommands()[generalTab.commandIndex].key
    const jobtype = CustomDialogSystem.isJobtypeActive(generalTab.commandIndex)
      ? availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[generalTab.jobtypeIndex]
      : 'BRWTAB'

    if (errorTabs.every(d => d)) {
      const customDialog = {
        SPTINAME: generalTab.dialogID.value,
        SPTENAME: generalTab.description.value,
        SPTUXCMD: command,
        SLTITYPE: jobtype,
        SLTINAME: generalTab.resultTableID,
        MAXENTRY: generalTab.hitLimitation,
        // need to replace SPTDND value with true or false but need the rest api compatibility
        SPTDND: generalTab.displayHierarchy,
        DNDNAME: generalTab.nodeID.value,
        OWNER: generalTab.owner,
        OBJECTS: this.buildSearchProfileObjects()
      }
      createCustomDialog(customDialog, () => onClose())
    }
  }

  /**
     * @description Renders the general tab.
     */
  renderGeneralTab = () => {
    const {id} = this.props
    const {generalTab} = this.state
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_id`}
              value={generalTab.dialogID.value}
              title={translate('definition.custom_dialog_id')}
              ref={this.dialogIdInput}
              maxLength={16}
              onInputChanged={(value, error) => this.handleChangeWithoutSpaces('dialogID', value, error)}
              error={generalTab.dialogID.error}
              onBlur={() => this.setState(state => ({generalTab: {...state.generalTab, ...this.validateDialogID()}}))}
              required={`${translate('general.required_field')}`}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_owner`}
              value={generalTab.owner}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={value => this.handleChangeGeneralTab('owner', value)}
            />
          </Column>
          <Column colMD={6}>
            {this.renderCommand()}
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_description`}
              value={generalTab.description.value}
              title={translate('general.description')}
              ref={this.descriptionInput}
              maxLength={64}
              onInputChanged={(value, error) => this.handleChangeGeneralTab('description', value, error)}
              error={generalTab.description.error}
              onBlur={() => this.setState(state => ({generalTab: {...state.generalTab, ...this.validateDescription()}}))}
              required={`${translate('general.required_field')}`}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_resulttableid`}
              onInputChanged={value => this.handleChangeGeneralTab('resultTableID', value)}
              value={generalTab.resultTableID}
              title={translate('definition.result_table_id')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.handleResultTableSelector(),
              }}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_hitlimitation`}
              title={translate('definition.hit_limitation')}
              onChange={value => this.handleChangeGeneralTab('hitLimitation', value)}
              value={generalTab.hitLimitation}
              min={0}
              max={9999}
              steps={1}
            />
          </Column>
        </Row>
        {UserUtils.isDOCX() &&
                    <>
                      <Row><Column colMD={12}>
                        <hr/>
                      </Column></Row>
                      <Row>
                        <Column colMD={3}>
                          <Checkbox
                            id={`${id}_displayhierarchy`}
                            value={generalTab.displayHierarchy}
                            onCheck={value => this.handleChangeGeneralTab('displayHierarchy', value)}
                            label={translate('definition.display_hierarchy')}
                          />
                        </Column>
                      </Row>
                    </>
        }
        {generalTab.displayHierarchy &&
                    <Row>
                      <Column colMD={3}>
                        <Input
                          id={`${id}_nodeid`}
                          onInputChanged={(value, error) => this.handleChangeGeneralTab('nodeID', value, error)}
                          value={generalTab.nodeID.value}
                          title={translate('definition.node_id')}
                          error={generalTab.nodeID.error}
                          onBlur={() => this.setState(state => ({generalTab: {...state.generalTab, ...this.validateNodeID()}}))}
                          ref={this.nodeIdInput}
                          maxLength={25}
                          addon={{
                            iconName: 'list',
                            onClick: () => this.handleNodeSelector(),
                          }}
                          required={`${translate('general.required_field')}`}
                        />
                      </Column>
                    </Row>
        }
        {CustomDialogSystem.isJobtypeActive(generalTab.commandIndex) && (
          <>
            <Row><Column colMD={12}>
              <hr/>
            </Column></Row>
            <Row>
              <Column colMD={4}>
                <CustomDialogSystem.DropdownJobtypes
                  id={`${id}_jobType`}
                  jobtypeIndex={generalTab.jobtypeIndex}
                  handleInputChanged={this.handleChangeGeneralTab}
                />
              </Column>
            </Row>
          </>
        )}
      </>
    )
  }

  /**
     * @description Renders the selector dialogs.
     */
  renderSelectorDialogs = () => {
    const {id, selector} = this.props
    return (
      <>
        {this.state.showResultTableDialog && (
          <SelectorDialog
            id={`${id}_resulttableselector_dialog`}
            onClose={() => this.setState({showResultTableDialog: false})}
            title={translate('definition.result_table_definitions')}
            header={[
              translate('definition.result_table_id'),
              translate('general.description'),
            ]}
            items={selector.resulttables.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newResultTableID = selector.resulttables.data[selectedRows][selector.resulttables.header.indexOf('SLTINAME')]
                this.setState({
                  generalTab: {
                    ...this.state.generalTab,
                    resultTableID: newResultTableID
                  }
                })
              }
              this.setState({showResultTableDialog: false})
            }}
          />
        )}
        {this.state.showNodeDialog && (
          <SelectorDialog
            id={`${id}_nodeselector_dialog`}
            onClose={() => this.setState({showNodeDialog: false})}
            title={translate('definition.document_node_definitions')}
            header={[
              translate('definition.node_id'),
              translate('general.identifier'),
            ]}
            items={selector.documentnodes.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newNodeID = selector.documentnodes.data[selectedRows][selector.documentnodes.header.indexOf('DNDNAME')]
                this.setState({
                  generalTab: {
                    ...this.state.generalTab,
                    nodeID: {value: newNodeID, error: ''}
                  }
                })
              }
              this.setState({showNodeDialog: false})
            }}
          />
        )}
      </>
    )
  }

  getIdentifierDropdownItems = () => {
    const {generalTab: {commandIndex}, formTab: {indexIdentifier}} = this.state
    // First item in indexIdentifier is the identifier item itself. This item has a unique artificial key, which we cannot use to display the name.
    const indexIdentifierToUse = indexIdentifier.filter((_, i) => i > 0)
    return CustomDialogSystem.getCustomDialogCommands()[commandIndex].key === 'SELGBL'
      ? [[translate('general.identifier')], ...indexIdentifierToUse.map(d => d[1].replace(':', ''))]
      : [translate('general.identifier')]
  }

  getPreviewData = () => {
    const {formTab} = this.state
    const searchProfileObjects = this.buildSearchProfileObjects()
    const data = []
    for (let i = 0; i < formTab.assigned.length; i++) {
      const dataBuffer = []
      const indexName = searchProfileObjects[i].IXINAME
      const seqNumber = searchProfileObjects[i].SPISEQNR
      const operator = searchProfileObjects[i].SPIOP
      const identifier = searchProfileObjects[i].SPIENAME
      const value = searchProfileObjects[i].SPIVALUE
      const required = searchProfileObjects[i].SPIVALR
      const disabled = searchProfileObjects[i].SPIVALD
      const identifier2 = searchProfileObjects[i].SPIENAM2 ? searchProfileObjects[i].SPIENAM2 : ''
      const value2 = searchProfileObjects[i].SPIVALU2 ? searchProfileObjects[i].SPIVALU2 : ''
      const required2 = searchProfileObjects[i].SPIVALR2 ? searchProfileObjects[i].SPIVALR2 : ''
      const disabled2 = searchProfileObjects[i].SPIVALD2 ? searchProfileObjects[i].SPIVALD2 : ''
      const maxLength = searchProfileObjects[i].SPIWEFLD
      const type = searchProfileObjects[i].SPIDTYPE
      const uppercase = searchProfileObjects[i].SPIUCASE
      const identifier3 = searchProfileObjects[i].SPIENAM3 ? searchProfileObjects[i].SPIENAM3 : ''
      const value3 = searchProfileObjects[i].SPIVALU3 ? searchProfileObjects[i].SPIVALU3 : ''
      const name2 = searchProfileObjects[i].SPIINAM2 ? searchProfileObjects[i].SPIINAM2 : ''

      dataBuffer.push(
        'STD',
        indexName,
        seqNumber,
        operator,
        identifier,
        value,
        required,
        disabled,
        identifier2,
        value2,
        required2,
        disabled2,
        maxLength,
        type,
        uppercase,
        identifier3,
        value3,
        name2
      )
      data.push(dataBuffer)
    }
    return data
  }

  getCustomDialogInfo = () => {
    const {generalTab} = this.state
    const command = CustomDialogSystem.getCustomDialogCommands()[generalTab.commandIndex].key
    let result = [
      generalTab.dialogID.value,
      generalTab.description.value,
      command,
      generalTab.owner,
      generalTab.hitLimitation,
      generalTab.displayHierarchy ? 'YES' : '',
      generalTab.nodeID.value
    ]
    if (command === 'IMPORT') {
      result.splice(3, 0, 'BRWTAB')
    }
    return result
  }

  getPreviewHeaderIndex = () => {
    return [
      'SPTINAME',
      'IXINAME',
      'SPISEQNR',
      'SPIOP',
      'SPIENAME',
      'SPIVALUE',
      'SPIVALR',
      'SPIVALD',
      'SPIENAM2',
      'SPIVALU2',
      'SPIVALR2',
      'SPIVALD2',
      'SPIWEFLD',
      'SPIDTYPE',
      'SPIUCASE',
      'SPIENAM3',
      'SPIVALU3',
      'SPIINAM2',
    ]
  }

  isPreviewDisabled = () => {
    const {formTab} = this.state
    let result = false
    // eslint-disable-next-line
        for (const [unused, entry] of Object.entries(formTab.configs)) {
      for (const [entryKey, entryValue] of Object.entries(entry)) {
        if (entryKey !== 'additionalFields') {
          if (typeof entryValue === 'object' && entryValue.error && entryValue.error !== '') {
            result = true
            break
          }
        } else {
          for (let i = 0; i < entryValue.length; i++) {
            if (entryValue[i].identifier.error !== '' || entryValue[i].value.error !== '') {
              result = true
              break
            }
          }
        }
      }
    }
    return result
  }

  /**
     * @description Validates the configuration when clicking on "Preview".
     * @param {Number} index
     */
  validateConfiguration = index => {
    if (index === 1) {
      this.validateFormTab()
    }
  }

  configHasError = key => {
    let result = false
    const config = this.state.formTab.configs[key]
    if (key && config) {
      // eslint-disable-next-line
            for (const [unused, entryValue] of Object.entries(config)) {
        if (typeof entryValue === 'object' && entryValue.error && entryValue.error !== '') {
          result = true
          break
        }
      }
      if (config.required && config.disabled) {
        if (config.value.error !== '') {
          result = true
        }
      }
      if (['CCHOICE', 'CCHOICE2', 'HCHOICE'].includes(config.type)) {
        if (config.dbSelect.error !== '') {
          result = true
        }
      }
      if (config.additionalFields) {
        // validate additional fields
        for (let i = 0; i < config.additionalFields.length; i++) {
          if (config.additionalFields[i]?.identifier?.error !== '') {
            result = true
            break
          }
          if (config.additionalFields[i]?.value?.error !== '') {
            result = true
            break
          }
        }
      }
    }
    return result
  }

  /**
     * @description Renders the form tab.
     */
  renderFormTab = () => {
    const {formTab} = this.state
    const {id, lang} = this.props
    const available = {
      data: this.state.formTab.unassigned,
      droppableID: 'unassigned'
    }

    // Removes unique artificial key of identifier.
    const getChosenData = () => {
      let data = []
      formTab.assigned.forEach(d => {
        if (typeof d[0] === 'number') {
          let buffer = [...d]
          buffer[0] = ''
          data.push(buffer)
        } else {
          data.push([...d])
        }
      })
      return data
    }

    const chosen = {
      data: getChosenData(),
      droppableID: 'assigned'
    }

    const getIconTitle = el => {
      if (el === 'INFILE') {
        return translate('infile_required_and_cannot_be_deleted')
      } else if (el === 'LOGSOURCE') {
        return translate('logsource_required_and_cannot_be_deleted')
      }
    }

    return (
      <>
        <Row className={'use_full_height bux_custom_dialog_form_container'}>
          <Column colMD={8}>
            <DragDropContext
              onBeforeDragStart={this.onBeforeDragStart}
              onDragEnd={this.onDragEnd}>
              <Row className={'bux_custom_dialog_datatable_row'}>
                {/* available list */}
                <Column colMD={5}>
                  <Card
                    id={'available_elements_card'}
                    title={translate('definition.available_elements')}
                    className={'bux_available_elements_card'}
                    containerClassName={'bux_available_elements_card_container'}
                  >
                    <Droppable droppableId={available.droppableID}>
                      {(provided) => (
                        <ul ref={provided.innerRef} id={available.droppableID}
                          className={'bux_elements_container'}>
                          {available.data.map((row, index) => {
                            return (
                              <Draggable
                                key={`${available.droppableID}_${index}`}
                                draggableId={`${available.droppableID}_${index}`}
                                index={index}>
                                {(provided) => {
                                  return (
                                    <li
                                      className={'bux_elements_row'}
                                      id={`${available.droppableID}_${index}`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}>
                                      {row.map((col, innerIndex) =>
                                        <div key={innerIndex}
                                          className={'bux_unassigned_element_column'}
                                          title={col}>
                                          <span>{col}</span>
                                        </div>)}
                                    </li>
                                  )
                                }}
                              </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </Card>
                </Column>
                <Column colMD={7}>
                  {!this.state.formTab.isPreviewEnabled &&
                                        <Card
                                          id={`${id}_elements`}
                                          title={translate('definition.elements')}
                                          className={'bux_used_elements'}
                                          containerClassName={'bux_used_elements_container'}
                                        >
                                          <div className={'bux_element_and_preview_header'}>
                                            <Checkbox
                                              id={`${id}_enable_preview`}
                                              onCheck={() => this.setState(state => ({
                                                formTab: {
                                                  ...state.formTab,
                                                  isPreviewEnabled: true
                                                }
                                              }))}
                                              value={this.state.formTab.isPreviewEnabled}
                                              label={translate('definition.custom_dialog.preview.enable')}
                                            />
                                            <div className={'bux_add_element_to_custom_dialog'}>
                                              <Dropdown
                                                id={`${id}_add_element_dropdown`}
                                                activeIndex={formTab.addElementDropdown}
                                                items={this.getIdentifierDropdownItems()}
                                                onChange={index => this.setState({
                                                  formTab: {
                                                    ...this.state.formTab,
                                                    addElementDropdown: index
                                                  }
                                                })}
                                              />
                                              <button
                                                id={`${id}_add_element_button`}
                                                type='button'
                                                className={'input-group-addon el_input_button bux_table_menu_open'}
                                                onClick={() => this.addIdentifier()}>
                                                <Icon
                                                  id={`${id}_actionBtn_icon`}
                                                  name={'add'}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                          <Droppable droppableId={chosen.droppableID}>
                                            {(provided) => (
                                              <ul ref={provided.innerRef} id={chosen.droppableID}
                                                className={'bux_elements_container'}>
                                                {chosen.data.length > 0
                                                  ? chosen.data.map((row, index) => {
                                                    return (
                                                      <Draggable
                                                        key={`${chosen.droppableID}_${index}`}
                                                        draggableId={`${chosen.droppableID}_${index}`}
                                                        index={index}>
                                                        {(provided) => {
                                                          return (
                                                            <li
                                                              className={`bux_elements_row ${formTab.selectedRow && index === formTab.selectedRow.index ? 'bux_fake_hover' : ''}`}
                                                              id={`${chosen.droppableID}_${index}`}
                                                              ref={provided.innerRef}
                                                              {...provided.draggableProps}
                                                              {...provided.dragHandleProps}>
                                                              {
                                                                row.map((col, i) => {
                                                                  return (
                                                                    <div
                                                                      key={`${i}_${Date.now()}`}
                                                                      className={'bux_assigned_element_column'}
                                                                      onClick={event => this.handleSelect(event, index)}>
                                                                      <div
                                                                        className={'bux_content_limiter'}
                                                                        title={col}>
                                                                        {col}
                                                                      </div>
                                                                      {i === 0 && this.configHasError(col) &&
                                                                                                                <Icon
                                                                                                                  id={`${id}_error_${i}`}
                                                                                                                  name='alert_small'
                                                                                                                  color={'red'}
                                                                                                                  className={'bux_alert_icon'}
                                                                                                                  containerClassName={'bux_alert_icon_container'}
                                                                                                                />
                                                                      }
                                                                    </div>
                                                                  )
                                                                })
                                                              }
                                                              <Link
                                                                id={`${id}_${index}_delete`}
                                                                iconName={'delete'}
                                                                tooltip={getIconTitle(row[0])}
                                                                disabled={['INFILE', 'LOGSOURCE'].includes(row[0])}
                                                                onClick={() => this.handleDelete(index)}/>
                                                            </li>
                                                          )
                                                        }}
                                                      </Draggable>
                                                    )
                                                  })
                                                  : <li className={'bux_elements_row bux_no_assigned_elements'}>
                                                    {translate('definition.create_custom_dialog_no_assigned_elements')}
                                                  </li>
                                                }
                                                {provided.placeholder}
                                              </ul>
                                            )}
                                          </Droppable>
                                        </Card>
                  }
                  {this.state.formTab.isPreviewEnabled &&
                                        <Card
                                          padding
                                          title={translate('definition.preview')}
                                          className={'bux_custom_dialog_preview_tab'}
                                          id={`${id}_preview`}
                                        >
                                          <div className={'bux_element_and_preview_header'}>
                                            <Checkbox
                                              id={`${id}_disable_preview`}
                                              onCheck={() => this.setState(state => ({
                                                formTab: {
                                                  ...state.formTab,
                                                  isPreviewEnabled: false
                                                }
                                              }))}
                                              value={this.state.formTab.isPreviewEnabled}
                                              label={translate('definition.custom_dialog.preview.enable')}
                                            />
                                          </div>
                                          {
                                            this.isPreviewDisabled()
                                              ? <p className={'bux_error_preview'}>{translate('general.preview_error')}</p>
                                              : this.getPreviewData().length > 0
                                                ? <CustomDialog
                                                  id={id}
                                                  getState={newFunction => {
                                                    this.setState({defaultState: newFunction()})
                                                    return this.getCustomDialogState = newFunction
                                                  }}
                                                  overwriteState={newFunction => this.overwriteCustomDialogState = newFunction}
                                                  customDialog={this.getPreviewData()}
                                                  customDialogInfo={this.getCustomDialogInfo()}
                                                  headerIndex={name => this.getPreviewHeaderIndex().indexOf(name)}
                                                  containerID={'definition_customdialog_createcustomdialog_assinged_elements_and_preview_tilecontainer'}
                                                  lang={lang}
                                                  scrollContainer={'definition_customdialog_createcustomdialog_assinged_elements_and_preview_tilecontainer'}
                                                />
                                                : <></>
                                          }
                                        </Card>
                  }
                </Column>
              </Row>
            </DragDropContext>
          </Column>
          <Column colMD={4}>
            <Card
              id={`${id}_config`}
              title={translate('definition.configuration')}
              className={`bux_modal_datatable_container bux_custom_dialog_config_container ${formTab.selectedRow === undefined ? 'bux_center_no_selected' : ''}`}
              disabled={this.state.formTab.isPreviewEnabled}
            >
              {this.renderConfiguration()}
            </Card>
          </Column>
        </Row>
      </>
    )
  }

  /**
     * @description Renders the configuration.
     */
  renderConfiguration = () => {
    const {formTab, generalTab: {commandIndex}} = this.state
    if (formTab.selectedRow !== undefined) {
      // el[0] is the indexname or a number (timestamp) in case of identifier
      const found = formTab.assigned.find(el => el[0] === formTab.selectedRow.key)
      const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
      if (found) {
        const type = formTab.configs[formTab.selectedRow.key].type
        // render configuration for index type elements
        if (buxCmd === 'SELGBL' && !generalIndexIdentifier.includes(found[0])) {
          switch (type) {
            case 'INDEXLCHOICE':
              return this.renderIndexLChoice()
            case 'INDEXCCHOICE':
              return this.renderIndexCChoice()
            case 'IDENTIFIER':
              return this.renderIdentifier()
            default:
              return this.renderIndexDefault()
          }
        } else {
          switch (type) {
            case 'CCHOICE':
              return this.renderCChoiceHChoice()
            case 'CCHOICE2':
              return this.renderCChoice2()
            case 'HCHOICE':
              return this.renderCChoiceHChoice()
            case 'LCHOICE':
              return this.renderLChoice()
            case 'SCHOICE':
              return this.renderSChoiceMChoice()
            case 'MCHOICE':
              return this.renderSChoiceMChoice()
            case 'DTFRAME':
              return this.renderDTFrame()
            case 'DATEDATE':
              return this.renderDateDate()
            case 'DATETIME':
              return this.renderDateTime()
            case 'IDENTIFIER':
              return this.renderIdentifier()
            case 'AMOUNT':
              return this.renderAmount()
            case 'FILEOPEN':
              return this.renderFileOpen()
            default:
              return this.renderDefaultChoice()
          }
        }
      }
    } else {
      return <p>{translate('general.no_entry_selected')}</p>
    }
  }

  /**
     * @description Renders the cchoice or hchoice configuration.
     */
  renderCChoiceHChoice = () => {
    const {id, defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]

    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    // extract all available types for this indexname
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('SPIDTYPE')])

    return (
      <ConfigRenderer.CChoiceHChoice
        id={id}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
        addAdditionalField={this.addAdditionalField}
        changeAdditionalField={this.changeAdditionalField}
        deleteAdditionalField={this.deleteAdditionalField}
        onBlurAdditionalField={this.onBlurAdditionalField}
        validateValueInput={this.validateValueInput}
        changeConfigValueNumeric={this.changeConfigValueNumeric}
      />
    )
  }

  /**
     * @description Renders the cchoice or hchoice configuration.
     */
  renderCChoice2 = () => {
    const {id, defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]

    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    // extract all available types for this indexname
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('SPIDTYPE')])

    return (
      <ConfigRenderer.CChoice2
        id={id}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
        addAdditionalField={this.addAdditionalField}
        changeAdditionalField={this.changeAdditionalField}
        deleteAdditionalField={this.deleteAdditionalField}
        onBlurAdditionalField={this.onBlurAdditionalField}
        validateValueInput={this.validateValueInput}
        changeConfigValueNumeric={this.changeConfigValueNumeric}
      />
    )
  }

  /**
     * @description Renders the lchoice configuration.
     */
  renderLChoice = () => {
    const {id, defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]
    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    // extract all available types for this indexname
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('SPIDTYPE')])

    return (
      <ConfigRenderer.LChoice
        id={id}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
        addAdditionalField={this.addAdditionalField}
        changeAdditionalField={this.changeAdditionalField}
        deleteAdditionalField={this.deleteAdditionalField}
        onBlurAdditionalField={this.onBlurAdditionalField}
        validateValueInput={this.validateValueInput}
        changeConfigValueNumeric={this.changeConfigValueNumeric}
      />
    )
  }

  /**
     * @description Renders the schoice or mchoice configuration.
     */
  renderSChoiceMChoice = () => {
    const {id, defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]

    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    // extract all available types for this indexname
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('SPIDTYPE')])

    return (
      <ConfigRenderer.SChoiceMChoice
        id={id}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
        changeAdditionalField={this.changeAdditionalField}
        deleteAdditionalField={this.deleteAdditionalField}
        onBlurAdditionalField={this.onBlurAdditionalField}
        validateValueInput={this.validateValueInput}
        changeConfigValueNumeric={this.changeConfigValueNumeric}
      />
    )
  }

  /**
     * @description Renders the identifier.
     */
  renderIdentifier = () => {
    const {formTab} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]

    return (
      <ConfigRenderer.Identifier
        id={this.props.id}
        configKey={configKey}
        currentConfig={currentConfig}
        changeConfigValue={this.changeConfigValue}
      />
    )
  }

  /**
     * @description Renders the default choice configuration.
     */
  renderIndexDefault = () => {
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const {id, defaultObjects, lang, datemask, availableJobtypes} = this.props
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]
    let buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    if (!generalIndexIdentifier.includes(currentConfig.indexName) && buxCmd === 'SELGBL') {
      buxCmd = 'INDEX'
    }

    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('IDXTYPE')])
    return (
      <ConfigRenderer.IndexDefault
        id={id}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
        validateValueInput={this.validateValueInput}
        changeConfigValueNumeric={this.changeConfigValueNumeric}
        lang={lang}
        datemask={datemask}
      />
    )
  }

  /**
     * @description Renders the index lchoice configuration.
     */
  renderIndexLChoice = () => {
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const {id, defaultObjects, availableJobtypes} = this.props
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]
    let buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    if (!generalIndexIdentifier.includes(currentConfig.indexName) && buxCmd === 'SELGBL') {
      buxCmd = 'INDEX'
    }
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('IDXTYPE')])

    return (
      <ConfigRenderer.IndexLChoice
        id={id}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
        addAdditionalField={this.addAdditionalField}
        changeAdditionalField={this.changeAdditionalField}
        deleteAdditionalField={this.deleteAdditionalField}
        onBlurAdditionalField={this.onBlurAdditionalField}
        validateValueInput={this.validateValueInput}
      />
    )
  }

  /**
     * @description Renders the index cchoice configuration.
     */
  renderIndexCChoice = () => {
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const {id, defaultObjects, availableJobtypes} = this.props
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]
    let buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    if (!generalIndexIdentifier.includes(currentConfig.indexName) && buxCmd === 'SELGBL') {
      buxCmd = 'INDEX'
    }
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('IDXTYPE')])

    return (
      <ConfigRenderer.IndexCChoice
        id={id}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
        addAdditionalField={this.addAdditionalField}
        changeAdditionalField={this.changeAdditionalField}
        deleteAdditionalField={this.deleteAdditionalField}
        onBlurAdditionalField={this.onBlurAdditionalField}
        validateValueInput={this.validateValueInput}
      />
    )
  }

  /**
     * @description Renders the default choice configuration.
     */
  renderDefaultChoice = () => {
    const {id, defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]
    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    // extract all available types for this indexname
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('SPIDTYPE')])

    return (
      <ConfigRenderer.DefaultChoice
        id={id}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeConfigValueNumeric={this.changeConfigValueNumeric}
        changeType={this.changeType}
        validateValueInput={this.validateValueInput}
      />
    )
  }

  /**
     * @description Renders the default choice configuration.
     */
  renderFileOpen = () => {
    const {id, defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]

    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    // extract all available types for this indexname
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('SPIDTYPE')])

    return (
      <ConfigRenderer.FileOpen
        id={id}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
      />
    )
  }

  /**
     * @description Renders the amount configuration.
     */
  renderAmount = () => {
    const {id, defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]

    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    // extract all available types for this indexname
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('SPIDTYPE')])

    return (
      <ConfigRenderer.Amount
        id={id}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
        onBlur={this.inputHasValue}
        validateValueInput={this.validateValueInput}
        changeConfigValueNumeric={this.changeConfigValueNumeric}
      />
    )
  }

  /**
     * @description Renders the dtframe configuration.
     */
  renderDTFrame = () => {
    const {id, lang, datemask, defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]

    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    // extract all available types for this indexname
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('SPIDTYPE')])

    return (
      <ConfigRenderer.DTFrame
        id={id}
        lang={lang}
        datemask={datemask}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
      />
    )
  }

  /**
     * @description Renders the datedate configuration.
     */
  renderDateDate = () => {
    const {id, lang, datemask, defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]

    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    // extract all available types for this indexname
    const types = fittedObjects.map(el => el[defaultObject.header.indexOf('SPIDTYPE')])

    return (
      <ConfigRenderer.DateDate
        id={id}
        lang={lang}
        datemask={datemask}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
        validateValueInput={this.validateValueInput}
      />
    )
  }

  /**
     * @description Renders the datetime configuration.
     */
  renderDateTime = () => {
    const {id, lang, datemask, defaultObjects, availableJobtypes} = this.props
    const {formTab, generalTab: {commandIndex, jobtypeIndex}} = this.state
    const configKey = formTab.selectedRow.key
    const currentConfig = formTab.configs[configKey]

    const buxCmd = CustomDialogSystem.getCustomDialogCommands()[commandIndex].key
    const jobtype = availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1])[jobtypeIndex]
    const defaultObject = defaultObjects[buxCmd] ? defaultObjects[buxCmd] : defaultObjects[jobtype]
    const fittedObjects = formTab.groupedReduxData[buxCmd]
      ? formTab.groupedReduxData[buxCmd][currentConfig.indexName]
      : formTab.groupedReduxData[jobtype][currentConfig.indexName]
    // extract all available types for this indexname
    let types = fittedObjects.map(el => el[defaultObject.header.indexOf('SPIDTYPE')])

    // If SDATE is added and EDATE still exists in config, the type have to be DATETIME and could not be changed.
    if (['SDATE', 'B93SDATE'].includes(configKey) && (formTab.configs['EDATE'] || formTab.configs['B93EDATE'])) {
      types = types.filter(d => d !== 'DATEDATE')
    }

    return (
      <ConfigRenderer.DateTime
        id={id}
        lang={lang}
        datemask={datemask}
        configKey={configKey}
        currentConfig={currentConfig}
        indexNameInput={this.indexNameInput}
        types={types}
        changeConfigValue={this.changeConfigValue}
        changeType={this.changeType}
        validateValueInput={this.validateValueInput}
      />
    )
  }

  render = () => {
    const {id, onClose} = this.props
    return (
      <>
        {this.renderSelectorDialogs()}
        <Modal
          id={'create_custom_dialog'}
          onClose={onClose}
          size={'l'}>
          <Header
            id={id}
            title={translate('definition.create_custom_dialog')}
            onClose={onClose}>
          </Header>
          <Main>
            <Tabs id={id} errorTabs={this.handleErrorTabs()} className={'bux_modal_datatable_container'}>
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab title={translate('general.form_tab')}>
                {this.renderFormTab()}
              </Tab>
            </Tabs>
          </Main>
          <Footer>
            {this.props.prefilledData && !hasNoValues(this.props.prefilledData) &&
              <Button
                id={`${id}_resetbtn`}
                tooltip={translate('general.reset')}
                icon={'undo'}
                onClick={this.resetState}
              />
            }
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selector: state.selector,
    defaultObjects: state.definitions.customdialogs.defaultObjects,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    availableJobtypes: state.definitions.customdialogs.availableJobtypes
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getResultTableDefinitions: (fields, resultTableID, callback) => {
      ModalSelectorActions.getResultTableDefinitions(fields, resultTableID, callback)(dispatch)
    },
    getDocumentNodesDefinition: (fields, nodeName, callback) => {
      ModalSelectorActions.getDocumentNodesDefinition(fields, nodeName, callback)(dispatch)
    },
    createCustomDialog: (customDialog, callback) => {
      createCustomDialog(customDialog, callback)(dispatch)
    },
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getDefaultObjects: (buxCmd, args, callback) => {
      return getDefaultObjects(buxCmd, args, callback)(dispatch)
    },
    getIndexDefaultObjects: callback => {
      getIndexDefaultObjects(callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomDialog)