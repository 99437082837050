import { translate } from 'language/Language'
import { Component } from 'react'

import {
  Button, Card, Column, Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'

class VerifyDocumentDefinitionDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  /**
   * @description Renders the main section
   */
  renderMain = () => {
    const { id, verify } = this.props
    const items = []
    for (let i = 0; i < verify.data.length; i++) {
      items.push(
        <Row>
          <Column colMD={6}>
            <span id={`${id}_${i}_span`}>{verify.data[i][2]}</span>
          </Column>
        </Row>
      )
    }
    return <Card>{items}</Card>
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={id}
          title={translate('definition.document_display_search_argument_and_values')}
          onClose={onClose}
        />
        <Main
          id={id}>
          <Card className={'bux_multi_card_container'}>
            {this.renderMain()}
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    verify: state.definitions.documents.verify
  }
}


export default connect(mapStateToProps)(VerifyDocumentDefinitionDialog)