import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'

// components
import {
  Button, Card, Column, Dropdown, Icon, Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row
} from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import { RECIPIENT_DOC_ASSIGNMENTS, getRecipientDistributions } from 'utils/DefinitionUtils'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import { updateRecipientDocumentAssignment } from 'redux/actions/RecipientDocumentAssignmentActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as UserUtils from 'utils/UserUtils'

class ModifyRecipientDocumentAssignmentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    distributionViaIndex: Math.max(getRecipientDistributions().findIndex(el => el.key === this.props.recipientDocumentAssignment.BRWS), 0),
    outputChannel: this.props.recipientDocumentAssignment.DCR,
    outputFormat: this.props.recipientDocumentAssignment.PCR,
    ppn: this.props.recipientDocumentAssignment.PPN,
    copies: parseInt(this.props.recipientDocumentAssignment.LRICOPY, 10),
    fromGen: parseInt(this.props.recipientDocumentAssignment.LRIFGEN, 10),
    toGen: parseInt(this.props.recipientDocumentAssignment.LRITGEN, 10),
    showRecipientSelectorDialog: false,
    showDocumentSelectorDialog: false,
    showOutputChannelSelectorDialog: false,
    showOutputFormatSelectorDialog: false,
    showPpnSelectorDialog: false
  }

  recipientIdInput = React.createRef()
  formInput = React.createRef()

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param val New value of inputfield
   * @param isRequired Wether the input is required or not
   */
  handleInput = (key, value) => this.setState({ [key]: value })

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  handleOutputChannelSelector = () => {
    const { getOutputChannelDefinitions } = this.props
    const { outputChannel } = this.state
    const callback = () => this.setState({ showOutputChannelSelectorDialog: true })
    getOutputChannelDefinitions(['DCR', 'DCRTITLE'], outputChannel, callback)
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  handleOutputFormatSelector = () => {
    const { getOutputFormatDefinitions } = this.props
    const { outputFormat } = this.state
    const callback = () => this.setState({ showOutputFormatSelectorDialog: true })
    getOutputFormatDefinitions(['PCR', 'PCRTITLE'], outputFormat, callback)
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  handlePpnSelector = () => {
    const { getPPNDefinitions } = this.props
    const { ppn } = this.state
    const callback = () => this.setState({ showPpnSelectorDialog: true })
    getPPNDefinitions(['PPN', 'PPNTITLE'], ppn, callback)
  }

  /**
   * @description Renders the header.
   */
  renderHeader = () => {
    const { id, recipientDocumentAssignment, datemask } = this.props
    const assignment = RECIPIENT_DOC_ASSIGNMENTS.find(el => recipientDocumentAssignment.LTYPE === el.key)?.translationKey
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('recipient.reci_id'), value: recipientDocumentAssignment.RECI },
          { label: translate('general.form'), value: recipientDocumentAssignment.FORM },
          { label: translate('general.extension'), value: recipientDocumentAssignment.EXT },
          { label: translate('general.report'), value: recipientDocumentAssignment.REPORT },
          { label: translate('general.dynamic_report'), value: recipientDocumentAssignment.WREPORT },
          { label: translate('general.assignment'), value: translate(assignment) },
          { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, recipientDocumentAssignment.CDATE, recipientDocumentAssignment.CTIME.substring(0, 8)) + ' ' + translate('general.by') + ' ' + recipientDocumentAssignment.CUSER },
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id, selector: { outputchannels, outputformats, ppns } } = this.props
    const { showOutputChannelSelectorDialog, showOutputFormatSelectorDialog, showPpnSelectorDialog } = this.state

    return (
      <>
        {showOutputChannelSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputchanneldefinition_selector_dialog`}
            onClose={() => this.setState({ showOutputChannelSelectorDialog: false })}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('import.outputchannel'),
              translate('general.title')
            ]}
            items={outputchannels.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const outputChannel = outputchannels.data[selectedRows][outputchannels.header.indexOf('DCR')]
                this.setState({
                  outputChannel,
                  showOutputChannelSelectorDialog: false
                })
              } else {
                this.setState({ showOutputChannelSelectorDialog: false })
              }
            }}
          />
        )}
        {showOutputFormatSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputformatdefinition_selector_dialog`}
            onClose={() => this.setState({ showOutputFormatSelectorDialog: false })}
            title={translate('definition.outputformatdefinitions')}
            header={[
              translate('import.outputformat'),
              translate('general.title')
            ]}
            items={outputformats.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const outputFormat = outputformats.data[selectedRows][outputformats.header.indexOf('PCR')]
                this.setState({
                  outputFormat,
                  showOutputFormatSelectorDialog: false
                })
              } else {
                this.setState({ showOutputFormatSelectorDialog: false })
              }
            }}
          />
        )}
        {showPpnSelectorDialog && (
          <SelectorDialog
            id={`${id}_ppndefinition_selector_dialog`}
            onClose={() => this.setState({ showPpnSelectorDialog: false })}
            title={translate('definition.ppndefinitions')}
            header={[
              translate('definition.postprocessing_note'),
              translate('general.title')
            ]}
            items={ppns.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const ppn = ppns.data[selectedRows][ppns.header.indexOf('PPN')]
                this.setState({
                  ppn,
                  showPpnSelectorDialog: false
                })
              } else {
                this.setState({ showPpnSelectorDialog: false })
              }
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { updateRecipientDocumentAssignment, recipientDocumentAssignment, onClose } = this.props
    const {
      distributionViaIndex,
      outputChannel,
      outputFormat,
      ppn,
      copies,
      fromGen,
      toGen
    } = this.state
    const distributionVia = getRecipientDistributions()[distributionViaIndex].key
    const recipientDocument = {
      RECI: recipientDocumentAssignment.RECI,
      FORM: recipientDocumentAssignment.FORM,
      EXT: recipientDocumentAssignment.EXT,
      REPORT: recipientDocumentAssignment.REPORT,
      WREPORT: recipientDocumentAssignment.WREPORT,
      LTYPE: recipientDocumentAssignment.LTYPE,
      LRIFGEN: fromGen,
      LRITGEN: toGen,
      LRICOPY: copies,
      BRWS: distributionVia,
      PCR: outputFormat,
      DCR: outputChannel,
      PPN: ppn
    }
    updateRecipientDocumentAssignment(recipientDocument, () => onClose())
  }

  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.modify')} ${translate('general.recipient_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'folder_document_relation_modal_title'} name={'relation'} tabIndex={-1}/>,
      <div
        key={'title_3'}>
        {`${UserUtils.isDOCX()
          ? translate('general.document_modal_title')
          : translate('general.log_modal_title')}  ${translate('general.assignment_modal_title')}`}
      </div>,
    ]
  }

  render = () => {
    const { id, onClose } = this.props
    const {
      distributionViaIndex,
      outputChannel,
      outputFormat,
      ppn,
      copies,
      fromGen,
      toGen
    } = this.state

    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={this.getModalTitle()}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main id={id}>
          {this.renderSelectorDialogs()}
          <Card>
            <Row>
              <Column colMD={3}>
                <Dropdown
                  id={`${id}_distribution`}
                  title={translate('recipient.distribution_via')}
                  items={getRecipientDistributions().map(el => translate(el.translationKey))}
                  activeIndex={distributionViaIndex}
                  onChange={value => this.handleInput('distributionViaIndex', value)}
                  value={distributionViaIndex}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                <hr />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <Input
                  id={`${id}_outputchannel`}
                  onInputChanged={value => this.handleInput('outputChannel', value)}
                  title={translate('import.outputchannel')}
                  value={outputChannel}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.handleOutputChannelSelector()
                  }}
                />
              </Column>
              <Column colMD={3}>
                <Input
                  id={`${id}_outputformat`}
                  onInputChanged={value => this.handleInput('outputFormat', value)}
                  title={translate('recipient.output_format')}
                  value={outputFormat}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.handleOutputFormatSelector()
                  }}
                />
              </Column>
              <Column colMD={3}>
                <Input
                  id={`${id}_ppn`}
                  onInputChanged={value => this.handleInput('ppn', value)}
                  title={translate('recipient.postprocessing_note')}
                  value={ppn}
                  maxLength={16}
                  addon={{
                    iconName: 'list',
                    onClick: () => this.handlePpnSelector()
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_copies`}
                  title={translate('import.copies')}
                  onChange={value => this.handleInput('copies', value)}
                  value={copies}
                  min={1}
                  max={32767}
                  steps={1}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_fromgen`}
                  title={translate('general.from_gen')}
                  onChange={value => this.handleInput('fromGen', value)}
                  value={fromGen}
                  min={0}
                  max={32767}
                  steps={1}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_togen`}
                  title={translate('general.to_gen')}
                  onChange={value => this.handleInput('toGen', value)}
                  value={toGen}
                  min={0}
                  max={32767}
                  steps={1}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={id}>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}
const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    selector: state.selector,
    recipientDocumentAssignment: state.assignments.recipientdocumentassignment.recipientDocumentAssignment,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getOutputFormatDefinitions: (fields, outputFormat, callback) => {
      ModalSelectorActions.getOutputFormatDefinitions(
        fields,
        outputFormat,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getPPNDefinitions: (fields, ppn, callback) => {
      ModalSelectorActions.getPPNDefinitions(
        fields,
        ppn,
        undefined,
        undefined,
        callback)(dispatch)
    },
    updateRecipientDocumentAssignment: (reciDocument, callback) => {
      updateRecipientDocumentAssignment(reciDocument, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyRecipientDocumentAssignmentDialog)