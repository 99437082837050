import { LOGOUT_SUCCESS } from '../actions/AuthenticationActions'
import * as CustomDialogActions from '../actions/CustomDialogActions'

const defaultState = {
  customDialogs: undefined,
  customDialog: undefined,
  resultTable: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case CustomDialogActions.GET_CUSTOM_DIALOG_START:
      return { ...state, customDialog: undefined }
    case CustomDialogActions.GET_CUSTOM_DIALOG_SUCCESS:
      return { ...state, customDialog: { ...action.dialog, OBJECTS: action.payload }}
    case CustomDialogActions.GET_CUSTOM_DIALOG_FAILED:
      return { ...state, customDialog: undefined }
    case CustomDialogActions.GET_CUSTOM_DIALOGS_START:
      return { ...state, customDialogs: undefined }
    case CustomDialogActions.GET_CUSTOM_DIALOGS_SUCCESS:
      return { ...state, customDialogs: action.payload }
    case CustomDialogActions.GET_CUSTOM_DIALOGS_FAILED:
      return { ...state, customDialogs: undefined }
    case CustomDialogActions.NO_CUSTOM_DIALOGS_FOUND:
      return { ...state, customDialogs: [] }
    case CustomDialogActions.GET_CUSTOM_DIALOG_RESULT_TABLE_SUCCESS:
      return { ...state, resultTable: action.payload }
    case CustomDialogActions.GET_CUSTOM_DIALOG_RESULT_TABLE_FAILED:
      return { ...state, resultTable: undefined }
    case CustomDialogActions.RESET_RESULT_TABLE:
      return { ...state, resultTable: undefined }
    case LOGOUT_SUCCESS:
      return defaultState
    default: return state
  }
}

export default reducer