import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Style
import './List.scss'

export default class List extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Style class from CSS for styling for List.*/
    className: PropTypes.string,
    /** Label to be displayed above the List.*/
    title: PropTypes.string,
    /** Children of the component.*/
    children: PropTypes.node
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, children, className, title } = this.props
    return (
      <div className={`${className ? className : ''} bux_list_container`}>
        { // white space (fromCharCode(160)) is needed when empty span should be renderd to horizontal align the cols
          title && <label>{title === ' ' ? String.fromCharCode(160) : title}</label>
        }
        <ul className={'bux_list'} id={`${id}_list`}>
          {
            Array.isArray(children)
              ? children.map((child, index) => {
                return <li key={index}>{child}</li>
              })
              : <li key={0}>{children}</li>
          }
        </ul>
      </div>
    )
  }
}