import React, { Component } from 'react'
import * as d3 from 'd3'
import propTypes from 'prop-types'
import './LineChart.scss'
import * as moment from 'moment'
import { equalsArrays } from '../../../utils/Utils'

export default class LineChart extends Component {

  stillUpdate = false

  state = {
    margin: {
      top: 40,
      right: 40,
      bottom: 60,
      left: 80
    },
    width: 0,
    height: 0,
    animations: true,
  }

  /**
   * @description Added the event which recalculate the chart when resize the window.
   */
  componentDidMount() {
    const { id, width, height } = this.props
    const {
      margin: {
        top,
        right,
        bottom,
        left
      }
    } = this.state
    const el = document.querySelector(`#lineChart${id}`)
    window.addEventListener('resize', this.handleUpdate)
    requestAnimationFrame(() => {
      this.setState({
        animations: true,
        width: width - left - right || (el.offsetWidth - 0.01 * el.offsetWidth) - left - right,
        height: height - top - bottom || el.offsetHeight - top - bottom
      }, this.drawChart)
    })
  }

  /**
   * @description Removes the event which recalculate the chart when resize the window.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleUpdate)
  }

  /**
   * @description Updates the chart information when datemask or char data has changed.
   */
  componentDidUpdate = prevProps => {
    // updates chart when datemask or language has changed
    if (prevProps.dateFormat !== this.props.dateFormat || !equalsArrays(prevProps.keys, this.props.keys) || !equalsArrays(prevProps.data, this.props.data)) {
      d3.select(`#lineChart${this.props.id}`)
        .select('svg')
        .remove()
      this.drawChart()
    }
    // will be executed when drawer expand/collapse
    if (this.props.drawerExpanded !== prevProps.drawerExpanded) {
      this.handleUpdate()
    }
  }

  /**
   * @description Checks if the arrays are equal.
   * @param {Array} array1 The first array to check.
   * @param {Array} array2 The second array to check.
   * @param {Boolean} strict Flag for strict check.
   * @returns {Boolean} True if the arrays are equal.
   */
  equalsArrays(array1, array2, strict) {
    if (!array1 || !array2)
      return false

    if (arguments.length === 2)
      strict = true

    if (array1.length !== array2.length)
      return false

    for (var i = 0; i < array1.length; i++) {
      if (array1[i] instanceof Array && array2[i] instanceof Array) {
        if (!this.equalsArrays(array1[i], array2[i], strict))
          return false
      }
      else if (strict && array1[i] !== array2[i]) {
        return false
      }
      else if (!strict) {
        return array1.sort().equals(array2.sort(), true)
      }
    }
    return true
  }

  /**
   * @description Stops the interval for updating the chart when expand/collapse drawer
   */
  stopUpdater = timer => {
    clearInterval(timer)
    this.updateChart()
  }

  /**
   * @description Added the interval for updating chart (e.g when drawer expand/collapse)
   */
  handleUpdate = () => {
    let chartUpdater = setInterval(this.updateChart, 1)
    setTimeout(() => this.stopUpdater(chartUpdater), 300)
  }

  /**
   * @description Updates teh chart.
   */
  updateChart = () => {
    const { id } = this.props
    const {
      margin: {
        top,
        right,
        bottom,
        left
      }
    } = this.state
    const el = document.querySelector(`#lineChart${id}`)
    d3.select(`#lineChart${id}`)
      .select('svg')
      .remove()
    this.setState({
      animations: false,
      width: (el.offsetWidth - 0.01 * el.offsetWidth) - left - right,
      height: el.offsetHeight - top - bottom
    }, this.drawChart)
  }

  /**
   * @description Translates the given curve style prop into the d3 function
   */
  getCurveStyle = curveStyle => {
    switch (curveStyle) {
      case 'linear': return d3.curveLinear
      case 'natural': return d3.curveNatural
      case 'step': return d3.curveStep
      case 'stepafter': return d3.curveStepAfter
      case 'stepbefore': return d3.curveStepBefore
      case 'basis': return d3.curveBasis
      case 'cardinal': return d3.curveCardinal
      case 'monotonex': return d3.curveMonotoneX
      case 'monotoney': return d3.curveMonotoneY
      case 'catmullrome': return d3.curveCatmullRom
      default: return d3.curveLinear
    }
  }

  /**
   * @description Formats the numbers for better legibility (e.g. 154000 = 154.000).
   */
  formatValue = value => {
    const { language } = this.props
    const regex = /\B(?=(\d{3})+(?!\d))/g
    switch (language) {
      case 'de': return value.toString().replace(regex, '.')
      case 'en': return value.toString().replace(regex, ',')
      case 'fr': return value.toString().replace(regex, ' ')
      default: return value
    }
  }

  /**
   * @description Formats the input date into the given format.
   */
  formatDate = (input, timeFormat) => {
    const date = moment(input)
    return date.format(timeFormat.replace('%d', 'DD').replace('%m', 'MM').replace('%Y', 'YYYY'))
  }

  /**
   * @description Returns the datemask, which d3 needs to display it correctly in the chart.
   */
  getDatemask = () => {
    return this.props.dateFormat === undefined
      ? '%m/%d/%Y'
      : this.props.dateFormat.replace('MM', '%m').replace('DD', '%d').replace('YYYY', '%Y')
  }

  /**
   * @description Draws the chart.
   */
  drawChart = () => {
    const forms = ['circle', 'rectangle', 'rhombus', 'triangleUp', 'triangleDown', 'hexagon']
    let bufferLines = []
    let maxY = 0
    // props need to be changed when working without react
    let data = [...this.props.data.map(d => [...d])]
    let colors = this.props.colors
    let id = `#lineChart${this.props.id}`
    let yAxisScale = this.props.yAxisScale
    let dotStyle = this.props.dotStyle
    let dotHover = this.props.dotHover
    let axis = this.props.axis
    let curveStyle = this.props.curveStyle
    let keys = this.props.keys
    let timeFormat = this.getDatemask()
    let changeTimeFormat = false
    let parseTime = d3.timeParse('%d.%m.%Y')

    data.forEach((d, i) => {
      if (typeof d[0] === 'string') {
        if (d[0].length === 7) {
          if (!changeTimeFormat) {
            changeTimeFormat = true
            if (timeFormat[1] === 'd') {
              timeFormat = timeFormat.substring(3, 8)
            }
            else if (timeFormat.indexOf('.') === 2) {
              if (timeFormat[1] === 'm') {
                timeFormat = '%m.%Y'
              }
              else if (timeFormat[1] === 'Y') {
                timeFormat = '%Y.%m'
              }
            }
            else if (timeFormat.indexOf('/') === 2) {
              if (timeFormat[1] === 'm') {
                timeFormat = '%m/%Y'
              }
              else if (timeFormat[1] === 'Y') {
                timeFormat = '%Y/%m'
              }
            }
            else if (timeFormat.indexOf('-') === 2) {
              if (timeFormat[1] === 'm') {
                timeFormat = '%m-%Y'
              }
              else if (timeFormat[1] === 'Y') {
                timeFormat = '%Y-%m'
              }
            }
          }
          data[i][0] = `01.${d[0]}`
        }
        else if (d[0].length === 4) {
          data[i][0] = `01.01.${d[0]}`
          timeFormat = '%Y'
        }
        data[i][0] = parseTime(d[0])
      }
      for (let j = 1; j < data[0].length; j++) {
        data[i][j] = +d[j]
        if (d[j] > maxY) {
          maxY = d[j]
        }
      }
    })
    // select tooltip
    let tooltip = d3.select(`${id}`).selectAll(`#tooltip_lineChart_${this.props.id}`)

    // create tooltip when no tooltip exists
    if (tooltip.empty()) {
      tooltip = d3.select(`${id}`).append('div')
        .attr('class', 'tooltip_lineChart')
        .attr('id', `tooltip_lineChart_${this.props.id}`)
        .style('opacity', 0)
    }

    // manipulate svg and create group
    let svg = d3.select(`${id}`)
      .append('svg')
      .attr('class', 'svgLineChart')
      .attr('width', this.state.width + this.state.margin.left + this.state.margin.right)
      .attr('height', this.state.height + this.state.margin.top + this.state.margin.bottom)

    svg.append('g')
      .attr('class', 'legend')

    svg.append('g')
      .attr('class', 'graph')
      .attr('transform',
        `translate(${this.state.margin.left},${this.state.margin.top})`)

    // scale x-axis
    let x = d3.scaleTime()
      .domain(d3.extent(data.map(d => d[0])))
      .range([0, this.state.width])

    // scale y-axis
    let y = null
    if (yAxisScale === 'linear') {
      y = d3.scaleLinear()
        .domain([0, maxY + 0.1 * maxY])
        .range([this.state.height + 0.05 * this.state.height, 0])
    }
    else if (yAxisScale === 'logarithm') {
      y = d3.scaleLog()
        .base(2)
        .domain([1, maxY + 0.1 * this.state.height])
        .range([this.state.height + 0.05 * this.state.height, 0])
    }
    else if (yAxisScale === 'pow1') {
      y = d3.scalePow()
        .exponent(0.5)
        .domain([0, maxY + 0.1 * this.state.height])
        .range([this.state.height + 0.05 * this.state.height, 0])
    }
    else if (yAxisScale === 'pow2') {
      y = d3.scalePow()
        .exponent(1.5)
        .domain([0, maxY + 0.1 * this.state.height])
        .range([this.state.height + 0.05 * this.state.height, 0])
    }

    let xAxisPosition = this.state.height + 0.05 * this.state.height

    const DATEMASK_YEAR_LENGTH = 2 // %Y
    const DATEMASK_MONTH_YEAR_LENGTH = 5 // %m.%Y
    const DATEMASK_FULL_LENGTH = 8 // %d.%m.%Y
    let parsedDateFormat = timeFormat
    // replaces the d3 datemask with moment datemask to format the date
    if (timeFormat.length === DATEMASK_YEAR_LENGTH) {
      parsedDateFormat = parsedDateFormat.replace('%Y', 'YYYY')
    }
    else if (timeFormat.length === DATEMASK_MONTH_YEAR_LENGTH) {
      parsedDateFormat = parsedDateFormat.replace('%m', 'MM')
      parsedDateFormat = parsedDateFormat.replace('%Y', 'YYYY')
    }
    else if (timeFormat.length === DATEMASK_FULL_LENGTH) {
      parsedDateFormat = timeFormat.replace('%d', 'DD')
      parsedDateFormat = parsedDateFormat.replace('%m', 'MM')
      parsedDateFormat = parsedDateFormat.replace('%Y', 'YYYY')
    }
    // filters the ticks on the x axis when they are doubled
    let ticksToUse = x.ticks().filter((d, i) => {
      const allTicks = x.ticks().map(j => moment(j).format(parsedDateFormat))
      const index = allTicks.indexOf(moment(d).format(parsedDateFormat))
      return i === index
    })
    // maximum pixel when display full date on each tick
    const MAX_DATE_WIDTH = 75
    // reduce ticks when width shrink
    while (this.state.width / MAX_DATE_WIDTH < ticksToUse.length) {
      ticksToUse = ticksToUse.filter((_, i) => i % 2 === 0)
    }

    // add the x axis
    svg.select('.graph')
      .append('g')
      .style('font-size', '12px')
      .attr('transform', `translate(0,${xAxisPosition})`)
      .attr('class', 'xAxis')
      .call(d3.axisBottom(x).tickFormat(d3.timeFormat(timeFormat)).tickValues(ticksToUse)
      )

    // add the y axis
    svg.select('.graph')
      .append('g')
      .style('font-size', '12px')
      .attr('class', 'yAxis')
      .call(d3.axisLeft(y).tickFormat(value => this.formatValue(value)))

    // colorize the axes
    svg.selectAll('.domain')
      .attr('stroke', '#010514')

    // colorize the axes lines
    svg.selectAll('g')
      .selectAll('line')
      .attr('stroke', '#010514')

    // add grids for y axis
    svg.select('.graph')
      .append('g')
      .attr('class', 'grid')
      .call(d3.axisLeft(y)
        .tickSize(-this.state.width)
        .tickFormat('')
      )

    // removes top line
    svg.select('.grid')
      .selectAll('path')
      .remove('path')

    // remove axes if user wants to
    if (axis !== 'show') {
      let remover = svg.selectAll('.graph').select('.xAxis')
      remover.select('path').remove()
      remover.selectAll('g').selectAll('line').remove()
      remover = svg.selectAll('.graph').select('.yAxis')
      remover.select('path').remove()
      remover.selectAll('g').selectAll('line').remove()
    }
    else {
      // removes the double line on the x axis
      svg.select('.grid')
        .select('g')
        .remove()
    }

    // splits data array into arrays
    // e.g. array[date, value1, value2] = array[date, value1] & array[date, value2]
    for (let lineNr = 1; lineNr < data[0].length; lineNr++) {
      let fusionArray1 = []
      for (let j = 0; j < data.length; j++) {
        let fusionArray2 = []
        fusionArray2[0] = data[j][0]
        fusionArray2[1] = data[j][lineNr]
        fusionArray1.push(fusionArray2)
      }
      bufferLines.push(fusionArray1)

      // define the line
      let line = d3.line()
        .x(d => x(d[0]))
        .y(d => y(d[1]))
        .curve(this.getCurveStyle(curveStyle))

      // add key to legend
      svg.select('.legend')
        .append('rect')
        .attr('fill', (lineNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[lineNr - 1])
        .attr('height', 10)
        .attr('width', 40)
        .attr('x', () => {
          let addSpace = 0
          for (let i = 0; i < lineNr - 1; i++) {
            addSpace += keys[i].length * 9
          }
          return this.state.margin.left + ((lineNr - 1) * 50) + addSpace + (lineNr - 1) * 20
        })
        .attr('y', 10)

      svg.selectAll('.legend').append('text')
        .text(keys[lineNr - 1])
        .attr('font-weight', 'bold')
        .attr('fill', (lineNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[lineNr - 1])
        .attr('x', () => {
          let addSpace = 0
          for (let i = 0; i < lineNr - 1; i++) {
            addSpace += keys[i].length * 9
          }
          return this.state.margin.left + ((lineNr - 1) * 50) + 50 + addSpace + (lineNr - 1) * 20
        })
        .attr('y', 20)

      // removes legend when space is not enough to display it in right way
      if (document.querySelector(`${id} .legend`).getBoundingClientRect().width > this.state.width) {
        d3.select(`${id} .legend`).remove()
      }

      // add the line
      svg.select('.graph')
        .append('g')
        .attr('class', 'lineContainer')
        .append('path')
        .data([bufferLines[lineNr - 1]])
        .attr('id', `line${lineNr}`)
        .attr('class', this.state.animations ? 'line' : 'lineNoAnim')
        .attr('d', line)
        .attr('fill', 'none')
        .attr('stroke', (lineNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[lineNr - 1])
        .attr('stroke-width', '3')
        // show tooltip for line
        // eslint-disable-next-line
        .on('mouseover', (event) => {
          tooltip.style('display', 'flex')
          tooltip
            .transition()
            .duration(200)
            .style('opacity', 1)
          tooltip
            .html(`<span>${keys[lineNr - 1]}</span>`)
            .style('color', (lineNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[lineNr - 1])
            .style('left', `${event.clientX}px`)
            .style('top', `${event.clientY - 28}px`)
        })
        .on('mousemove', (event) => {
          let tooltipDiv = document.querySelector(`#tooltip_lineChart_${this.props.id}`)
          const tooltipDivWidth = tooltipDiv.offsetWidth
          const tooltipDivHeight = tooltipDiv.offsetHeight
          const el = document.querySelector(id)
          const xPosition = event.clientX
          const yPosition = event.clientY
          tooltip
            .style('left', `${xPosition + tooltipDivWidth <= el.offsetWidth - 5 ? xPosition : xPosition - tooltipDivWidth}px`)
            .style('top', `${yPosition + tooltipDivHeight <= el.offsetHeight - 5 ? yPosition + 20 : yPosition - tooltipDivHeight - 3}px`)
        })
        // hide tooltip
        .on('mouseout', () => {
          tooltip.transition()
            .duration(500)
            .style('opacity', 0)
            .on('end', () => {
              tooltip.style('display', 'none')
            })
        })

      // get current length of the line for animation calculation
      let lineChart = document.querySelector(`${id} .svgLineChart .graph`)
      let allLines = lineChart.querySelectorAll('.lineContainer')
      let currentLine = null
      allLines.forEach(d => {
        let buffer = d.querySelector(`#line${lineNr}`)
        if (buffer !== null) {
          currentLine = buffer
        }
      })
      const lineLength = currentLine.getTotalLength() + 20
      svg.select(`#line${lineNr}`)
        .attr('stroke-dasharray', lineLength)
        .attr('stroke-dashoffset', lineLength)

      let dotCounter = -1
      let xPos = []
      let yPos = []
      // add the dots
      let dot = svg.selectAll('.lineContainer')
      if (dotStyle === 'different') {
        if (forms[lineNr - 1] === 'circle') {
          dot = dot.filter((_, i) => i === lineNr - 1).selectAll('circle')
            .data(bufferLines[lineNr - 1])
            .enter()
            .append('circle')
            .attr('cx', d => x(d[0]))
            .attr('cy', d => y(d[1]))
            .attr('r', '4')
        }
        else if (forms[lineNr - 1] === 'rectangle') {
          dot = dot.filter((_, i) => i === lineNr - 1).selectAll('rect')
            .data(bufferLines[lineNr - 1])
            .enter()
            .append('rect')
            .attr('x', d => {
              xPos.push(x(d[0]))
              return x(d[0]) - 4
            })
            .attr('y', d => {
              yPos.push(y(d[1]))
              return y(d[1]) - 4
            })
            .attr('height', '8')
            .attr('width', '8')
        }
        else if (forms[lineNr - 1] === 'rhombus') {
          dot = dot.filter((_, i) => i === lineNr - 1).selectAll('polygon')
            .data(bufferLines[lineNr - 1])
            .enter()
            .append('polygon')
            .attr('points', d => {
              xPos.push(x(d[0]))
              yPos.push(y(d[1]))
              return `${x(d[0])},${y(d[1]) - 5} ${x(d[0]) + 5},${y(d[1])} ${x(d[0])},${y(d[1]) + 5} ${x(d[0]) - 5},${y(d[1])}`
            })
        }
        else if (forms[lineNr - 1] === 'triangleUp') {
          dot = dot.filter((_, i) => i === lineNr - 1).selectAll('path').filter((d, i) => i > 0)
            .data(bufferLines[lineNr - 1])
            .enter()
            .append('path')
            .attr('d', d => {
              xPos.push(x(d[0]))
              yPos.push(y(d[1]))
              return `M${x(d[0])},${y(d[1]) - 5} ${x(d[0]) - 5},${y(d[1]) + 4} ${x(d[0]) + 5},${y(d[1]) + 4}z`
            })
        }
        else if (forms[lineNr - 1] === 'triangleDown') {
          dot = dot.filter((_, i) => i === lineNr - 1).selectAll('path').filter((d, i) => i > 0)
            .data(bufferLines[lineNr - 1])
            .enter()
            .append('path')
            .attr('d', d => {
              xPos.push(x(d[0]))
              yPos.push(y(d[1]))
              return `M${x(d[0]) - 5},${y(d[1]) - 4} ${x(d[0]) + 5},${y(d[1]) - 4} ${x(d[0])},${y(d[1]) + 4}z`
            })
        }
        else if (forms[lineNr - 1] === 'hexagon') {
          dot = dot.filter((_, i) => i === lineNr - 1).selectAll('polygon')
            .data(bufferLines[lineNr - 1])
            .enter()
            .append('polygon')
            .attr('points', d => {
              xPos.push(x(d[0]))
              yPos.push(y(d[1]))
              return `${x(d[0]) - 3},${y(d[1]) - 5} ${x(d[0]) + 3},${y(d[1]) - 5} ${x(d[0]) + 6},${y(d[1])} ${x(d[0]) + 3},${y(d[1]) + 5} ${x(d[0]) - 3},${y(d[1]) + 5} ${x(d[0]) - 6},${y(d[1])}`
            })
        }
      }
      else if (dotStyle === 'circle') {
        dot = dot.selectAll('circle')
          .data(bufferLines[lineNr - 1])
          .enter()
          .append('circle')
          .attr('cx', d => x(d[0]))
          .attr('cy', d => y(d[1]))
          .attr('r', '4')
      }
      dot.attr('fill', (lineNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[lineNr - 1])
        .attr('stroke', 'white')
        .attr('sroke-width', '0.5')
        .attr('class', () => {
          dotCounter++
          return `${this.state.animations ? 'dot' : ''} dot_${dotCounter}`
        })
        .attr('id', () => {
          if (dotCounter >= data.length - 1) {
            dotCounter = -1
          }
          dotCounter++
          return `dot_${lineNr - 1}_${dotCounter}`
        })
      // show tooltip for dot
      // eslint-disable-next-line
      dot.on('mouseover', (event, d) => {
        const e = dot.nodes();
        const i = e.indexOf(event.currentTarget);
        if (dotHover === 'one') {
          if (dotStyle === 'different') {
            if (forms[lineNr - 1] === 'circle') {
              svg.select(`#dot_${lineNr - 1}_${i}`)
                .attr('r', '6')
            }
            else if (forms[lineNr - 1] === 'rectangle') {
              svg.select(`#dot_${lineNr - 1}_${i}`)
                .attr('x', xPos[i] - 6)
                .attr('y', yPos[i] - 6)
                .attr('height', '12')
                .attr('width', '12')
            }
            else if (forms[lineNr - 1] === 'rhombus') {
              svg.select(`#dot_${lineNr - 1}_${i}`)
                .attr('points', `${xPos[i]},${yPos[i] - 8} ${xPos[i] + 8},${yPos[i]} ${xPos[i]},${yPos[i] + 8} ${xPos[i] - 8},${yPos[i]}`)
            }
            else if (forms[lineNr - 1] === 'triangleUp') {
              svg.select(`#dot_${lineNr - 1}_${i}`)
                .attr('d', `M${xPos[i]},${yPos[i] - 8} ${xPos[i] - 8},${yPos[i] + 6} ${xPos[i] + 8},${yPos[i] + 6}z`)
            }
            else if (forms[lineNr - 1] === 'triangleDown') {
              svg.select(`#dot_${lineNr - 1}_${i}`)
                .attr('d', `M${xPos[i] - 8},${yPos[i] - 6} ${xPos[i] + 8},${yPos[i] - 6} ${xPos[i]},${yPos[i] + 6}z`)
            }
            else if (forms[lineNr - 1] === 'hexagon') {
              svg.select(`#dot_${lineNr - 1}_${i}`)
                .attr('points', `${xPos[i] - 4},${yPos[i] - 7} ${xPos[i] + 4},${yPos[i] - 7} ${xPos[i] + 8},${yPos[i]} ${xPos[i] + 4},${yPos[i] + 7} ${xPos[i] - 4},${yPos[i] + 7} ${xPos[i] - 8},${yPos[i]}`)
            }
          }
          else if (dotStyle === 'circle') {
            svg.select(`#dot_${lineNr - 1}_${i}`)
              .attr('r', '6')
          }
        }
        else if (dotHover === 'vertical') {
          if (dotStyle === 'circle') {
            svg.selectAll(`.dot_${i}`)
              .attr('r', '6')
          }
        }
        tooltip.style('display', 'flex')
        tooltip.transition()
          .duration(200)
          .style('opacity', 1)

        if (dotHover === 'vertical') {
          tooltip.selectAll('span').remove()
          tooltip.append('span')
            .text(this.formatDate(d[0], timeFormat))
            .style('color', '#010514')
            .style('font-weight', 'bold')
          bufferLines.forEach((d, j) => {
            tooltip.append('span')
              .text(`${keys[j]}: ${this.formatValue(d[i][1])}`)
              .style('color', colors[j])
          })
        }
        else {
          tooltip.selectAll('span').remove()
          tooltip.append('span')
            .text(this.formatDate(d[0], timeFormat))
            .style('color', '#010514')
            .style('font-weight', 'bold')
          tooltip.append('span')
            .text(`${keys[lineNr - 1]}: ${this.formatValue(bufferLines[lineNr - 1][i][1])}`)
            .style('color', colors[lineNr - 1])
        }

        let tooltipDiv = document.querySelector(`#tooltip_lineChart_${this.props.id}`)
        const tooltipDivWidth = tooltipDiv.offsetWidth
        const tooltipDivHeight = tooltipDiv.offsetHeight
        const el = document.querySelector(id)
        const xPosition = event.clientX
        const yPosition = event.clientY
        tooltip
          .style('left', `${xPosition + tooltipDivWidth <= el.offsetWidth - 5 ? xPosition : xPosition - tooltipDivWidth}px`)
          .style('top', `${yPosition + tooltipDivHeight <= el.offsetHeight - 5 ? yPosition + 20 : yPosition - tooltipDivHeight - 3}px`)
      })
        // hide tooltip
        // eslint-disable-next-line
        .on('mouseout', (d, i) => {
          if (dotHover === 'one') {
            if (dotStyle === 'different') {
              if (forms[lineNr - 1] === 'circle') {
                svg.select(`#dot_${lineNr - 1}_${i}`)
                  .attr('r', '4')
              }
              else if (forms[lineNr - 1] === 'rectangle') {
                svg.select(`#dot_${lineNr - 1}_${i}`)
                  .attr('x', xPos[i] - 4)
                  .attr('y', yPos[i] - 4)
                  .attr('height', '8')
                  .attr('width', '8')
              }
              else if (forms[lineNr - 1] === 'rhombus') {
                svg.select(`#dot_${lineNr - 1}_${i}`)
                  .attr('points', () => `${xPos[i]},${yPos[i] - 5} ${xPos[i] + 5},${yPos[i]} ${xPos[i]},${yPos[i] + 5} ${xPos[i] - 5},${yPos[i]}`)
              }
              else if (forms[lineNr - 1] === 'triangleUp') {
                svg.select(`#dot_${lineNr - 1}_${i}`)
                  .attr('d', () => `M${xPos[i]},${yPos[i] - 5} ${xPos[i] - 5},${yPos[i] + 4} ${xPos[i] + 5},${yPos[i] + 4}z`)
              }
              else if (forms[lineNr - 1] === 'triangleDown') {
                svg.select(`#dot_${lineNr - 1}_${i}`)
                  .attr('d', () => `M${xPos[i] - 5},${yPos[i] - 4} ${xPos[i] + 5},${yPos[i] - 4} ${xPos[i]},${yPos[i] + 4}z`)
              }
              else if (forms[lineNr - 1] === 'hexagon') {
                svg.select(`#dot_${lineNr - 1}_${i}`)
                  .attr('points', `${x(d[0]) - 3},${y(d[1]) - 5} ${x(d[0]) + 3},${y(d[1]) - 5} ${x(d[0]) + 6},${y(d[1])} ${x(d[0]) + 3},${y(d[1]) + 5} ${x(d[0]) - 3},${y(d[1]) + 5} ${x(d[0]) - 6},${y(d[1])}`)
              }
            }
            else if (dotStyle === 'circle') {
              svg.select(`#dot_${lineNr - 1}_${i}`)
                .attr('r', '4')
            }
          }
          else if (dotHover === 'vertical') {
            if (dotStyle === 'circle') {
              svg.selectAll(`.dot_${i}`)
                .attr('r', '4')
            }
          }
          tooltip.transition()
            .duration(500)
            .style('opacity', 0)
            .on('end', () => {
              tooltip.style('display', 'none')
            })

        })
    }
  }

  render() {
    return <div className={'lineChart'} id={`lineChart${this.props.id}`}></div>
  }
}

LineChart.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: propTypes.string.isRequired,
  /**
   * Array of array of data.
   * The first element of array of data should contains label, rest value which should be represents by bar.
   *
   * ```js
   * [
   *  ["label", 10, 12, ...],
   *  ["label2", 0, 2, ...],
   *  ...
   * ]
   * ```
   */
  data: propTypes.arrayOf(propTypes.array).isRequired,
  /** Name of the value passed after label to the _data_, to show it in legend.*/
  keys: propTypes.arrayOf(propTypes.string).isRequired,
  /**
   * The colors are used to colorize the bars in the bar chart.
   * Each color in this array will be used for one bar.
   * If you define less colors then bars you have, the last color will be used for each additional bar.
   */
  colors: propTypes.arrayOf(propTypes.string).isRequired,
  /** CurveStyle decides how to display the line in the chart. */
  curveStyle: propTypes.oneOf([
    'linear',
    'natural',
    'step',
    'stepafter',
    'stepbefore',
    'basis',
    'cardinal',
    'monotonex',
    'monotoney',
    'cutmullrome']).isRequired,
  /**
   * DotHover describes how the dots react when mouse hover.
   * If you want to use the 'vertical' option, you need to choose 'circle' in dotStyle option.
   */
  dotHover: propTypes.oneOf(['one', 'vertical']).isRequired,
  /**
   * DotStyle describes how the dots at each data point are styled.
   * If you choose different, each line gets dots with a different style.
   */
  dotStyle: propTypes.oneOf(['circle', 'different']).isRequired,
  /** YAxisScale describes how the y axis scales. You can choose two scale options: */
  yAxisScale: propTypes.oneOf(['linear', 'pow1', 'pow2', 'logarithm']).isRequired,
  /** Axis decides, if the axes is shown or not.*/
  axis: propTypes.oneOf(['show', 'none']).isRequired,
  /** Date format of the date passed in _data_ */
  dateFormat: propTypes.string.isRequired,
  /** Width defines the width of the svg element which displays the linechart. */
  width: propTypes.number,
  /** Height defines the height of the svg element which displays the linechart. */
  height: propTypes.number,
  /**
   * Functionality connected with the _Drawer_ component.
   * When the drawer is expanded and a row is checked, the chart re-render.
   */
  drawerExpanded: propTypes.oneOfType([propTypes.bool, propTypes.string]),
  /** Used for the locale of the barchart. */
  language: propTypes.oneOf(['de', 'en', 'fr'])
}