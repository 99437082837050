import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SearchResultStandardSelection from 'components/search_result/search/standard_selection/SearchResultStandardSelection'
import SearchResultCustomSearch from 'components/search_result/search/custom_search/SearchResultCustomSearch'
import SearchResultUc4Search from 'components/search_result/search/uc4_search/SearchResultUc4Search'
import SearchResultZosSearch from 'components/search_result/search/zos_search/SearchResultZosSearch'
import SearchResulControlM from 'components/search_result/search/controlm_search/SearchResultControlM'
import SearchResultSyslog from 'components/search_result/search/syslog_search/SearchResultSyslog'

import { Redirect } from 'react-router-dom'
// Redux
import { connect } from 'react-redux'
import SearchResultStonebranch from './stonebranch_search/SearchResultStonebranch'

class SearchResultSearch extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    match: PropTypes.any.isRequired,
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }

  getBody() {
    const { id, drawerExpanded, autoDrawer, match } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    switch (url) {
      // standard selection result
      case 'standardselection':
        return (
          <SearchResultStandardSelection
            id={`${id}_standardsearch`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer} />
        )
      case 'zos':
        return (
          <SearchResultZosSearch
            id={`${id}_zos`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer} />
        )
      case 'uc4':
        return (
          <SearchResultUc4Search
            id={`${id}_uc4`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer} />
        )
      case 'controlm':
        return (
          <SearchResulControlM
            id={`${id}_controlm`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer} />
        )
      case 'customsearch':
        return (
          <SearchResultCustomSearch
            id={`${id}_standardsearch`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer} />
        )
      case 'syslog':
        return (
          <SearchResultSyslog
            id={`${id}_syslog`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer} />
        )
      case 'stonebranch':
        return (
          <SearchResultStonebranch
            id={`${id}_stonebranch`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer} />
        )
      // fallback is standard selection
      // take care of the route because if you enter a wrong route you can create an infinite loop!
      default:
        return <Redirect to={'/search/standardselection'} />
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}


export default connect()(SearchResultSearch)