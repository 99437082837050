export const TYPE_SUCCESS = 'success';
export const TYPE_INFO = 'info';
export const TYPE_ERROR = 'error';

export type SnackbarContentType = 'success' | 'info' | 'error';

export interface SnackbarContent {
  /** Text to be displayed in snackbar*/
  text: string;
  /** type of the snackbar, indicates color and the icon which appears*/
  type: SnackbarContentType;
}
export interface SnackbarTitle {
  /** Text to be displayed in snackbar*/
  text: string;
}

export interface SnackbarGroup {
  /** Unique ID for snackbar group */
  id: number;
  /** Array of data which represents snackbar content */
  content: SnackbarContent[];
  /** List of string to be display as title on snackbarTile hover */
  customTitle?: SnackbarTitle[];
}

