import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { Button, Card, Column, Row, Switch } from 'BetaUX2Web-Components/src';

import * as DATA from '../../constants/data';

import { useTabContentContext } from '../../contexts/TabContentContext';

import { getViewMode, getViewModeConfigurationParameter, isDocumentWithResources } from '../../helper/utils';

import { translate } from 'language/Language';

import * as TYPES_DOC_BROWSER from '../../types/DocumentBrowser.types';
import * as TYPES_DOC_VIEWER from '../../types/DocumentViewer.types';

export const DrawerHexMenu = () => {
  const { id, pageNumber, viewMode, setViewMode } = useTabContentContext();

  const tab: TYPES_DOC_VIEWER.ITabData = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id));
  const hasResources = isDocumentWithResources(tab);

  const [hexMode, setHexMode] = useState<TYPES_DOC_BROWSER.TDocumentHexMode>(DATA.DOC_VIEWER_HEX_MODE_LINE);
  const [showPageHeader, setShowPageHeader] = useState<TYPES_DOC_BROWSER.TDocumentHexShowHeader>(DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO);
  const [showResources, setShowResources] = useState<TYPES_DOC_BROWSER.TDocumentHexShowResources>(DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO);

  const handleViewModeChange = () => {
    const newViewMode = getViewMode(hexMode, showPageHeader, showResources);
    if (!newViewMode) return;

    setViewMode(newViewMode);
  };

  useEffect(() => {
    const config = getViewModeConfigurationParameter(viewMode);
    if (!config) return;

    if (hexMode !== config.currentHexMode) setHexMode(config.currentHexMode);
    if (showPageHeader !== config.currentPageHeader) setShowPageHeader(config.currentPageHeader);
    if (showResources !== config.currentResources) setShowResources(config.currentResources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber])

  return (
    <div className='bux_drawer_form drawer_content_main'>
      <Card
        id={`${id}_body_documentinfocard`}
        className='bux_pl8 bux_pr8'
        title={translate('documentviewer.hex_view_configuration')}>
        <Card id='doc_viewer_hex_menu_card'>
          <Row padded>
            <Column>
              <Column colMD={12}>
                <Switch
                  id={'download_hex_dialog_display_mode'}
                  title={translate('general.display_mode')}
                  items={[translate('general.line_format'), translate('general.block_format')]}
                  onClick={(index: number) => setHexMode(index === 0 ? DATA.DOC_VIEWER_HEX_MODE_LINE : DATA.DOC_VIEWER_HEX_MODE_BLOCK)}
                  activeIndex={hexMode === DATA.DOC_VIEWER_HEX_MODE_LINE ? 0 : 1}
                />
              </Column>
              <Column colMD={12}>
                <Switch
                  id={'download_hex_dialog_show_page_header'}
                  title={translate('general.show_page_header')}
                  items={[translate('general.yes'), translate('general.no')]}
                  onClick={(index: number) => setShowPageHeader(index === 0 ? DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES : DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO)}
                  activeIndex={showPageHeader === DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES ? 0 : 1}
                />
              </Column>
              {hasResources && (
                <Column colMD={12}>
                  <Switch
                    id={'download_hex_dialog_show_resources'}
                    title={translate('general.show_resources')}
                    items={[translate('general.yes'), translate('general.no')]}
                    onClick={(index: number) => setShowResources(index === 0 ? DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES : DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO)}
                    activeIndex={showResources === DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES ? 0 : 1}
                  />
                </Column>
              )}
            </Column>
          </Row>
          <Row padded>
            <Button
              id={'download_hex_dialog_display_btn'}
              text={translate('general.display')}
              onClick={() => handleViewModeChange()}
              disabled={tab.isFetching}
              primary
            />
          </Row>
        </Card>
      </Card>
    </div>
  )
};