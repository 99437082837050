import * as LastSessionActions from 'redux/actions/LastSessionActions'

const defaultState = {
  userid: undefined,
  page: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case LastSessionActions.SAVE_LAST_SESSION:
      return { ...state, userid: action.payload.userid, page: action.payload.page }
    case LastSessionActions.REMOVE_LAST_SESSION:
      return { ...state, userid: undefined, page: undefined }
    default: return state
  }
}

export default reducer