
import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as UserUtils from 'utils/UserUtils'

import { Dropdown, Switch } from 'BetaUX2Web-Components/src/'
import store from 'redux/Store'

export const JOB_TYPE_TRANSLATIONS = {
  'BRWTAB': 'general.standard',
  'BRWCTM': 'search.controlm',
  'BRWUC4': 'search.uc4',
  'BRWZOS': 'search.zos',
  'BRWSYSL': 'search.syslog',
  'BRWSTB': 'search.stonebranch'
}

export const getAvailableJobTypes = () => store?.getState()?.definitions?.customdialogs?.availableJobtypes?.data
  ?.filter(d => d[0] === 'SELJOB' || d[0] === 'SELDOC')
  ?.map(j => j[1])
  ?.map(key => ({key, translation: JOB_TYPE_TRANSLATIONS[key] || key})) || []

export const getAvailableJobTypesCustomDialog = () => store?.getState()?.definitions?.customdialogs?.availableJobtypes?.data
  ?.filter(d => d[0] === 'SELJOB')
  ?.map(j => j[1])
  ?.map(key => ({key, translation: JOB_TYPE_TRANSLATIONS[key] || key})) || []

/**
 * @description Renders the command section
 * @param {String} id
 * @param {Number} commandIndex
 * @param {Function} handleInputChanged
 */
export const CommandDocx = ({id, commandIndex, handleInputChanged}) => (
  <>
    <Switch
      id={`${id}_command`}
      title={translate('definition.command')}
      maxPerRow={3}
      activeIndex={commandIndex}
      items={DefinitionUtils.getCustomDialogDocxCommands().map(d => translate(d.translationKey))}
      onClick={index => handleInputChanged('commandIndex', index)}
    />
  </>
)

/**
 * @description Renders the command section
 * @param {String} id
 * @param {Number} commandIndex
 * @param {Function} handleInputChanged
 */
export const CommandLogx = ({id, commandIndex, handleInputChanged}) => (
  <>
    <Switch
      id={`${id}_command`}
      title={translate('definition.command')}
      maxPerRow={3}
      activeIndex={commandIndex}
      items={DefinitionUtils.getCustomDialogLogxCommands().map(d => translate(d.translationKey))}
      onClick={index => handleInputChanged('commandIndex', index)}
    />
  </>
)

/**
 * @description Renders the Jobtype section
 * @param {String} id
 * @param {Number} jobtypeIndex
 * @param {Function} handleInputChanged
 */
export const Jobtype = ({id, jobtypeIndex, handleInputChanged}) => {
  const items = getAvailableJobTypes().map(({ translation }) => translate(translation))

  return (
    <Dropdown
      id={id}
      title={translate('job.jobtype')}
      activeIndex={jobtypeIndex}
      items={items}
      onChange={index => handleInputChanged('jobtypeIndex', index)}
    />
  )
}

/**
 * @description Renders the Jobtype dropdown section for the custom dialog modals
 * @param {String} id
 * @param {Number} jobtypeIndex
 * @param {Function} handleInputChanged
 */
export const DropdownJobtypes = ({id, jobtypeIndex, handleInputChanged}) => {
  const items = getAvailableJobTypesCustomDialog().map(({ translation }) => translate(translation))

  return (
    <Dropdown
      id={id}
      title={translate('job.jobtype')}
      activeIndex={jobtypeIndex}
      items={items}
      onChange={index => handleInputChanged('jobtypeIndex', index)}
    />
  )
}

export const isJobtypeActive = commandIndex => UserUtils.isLOGX() && DefinitionUtils.getCustomDialogLogxCommands()[commandIndex]?.key === DefinitionUtils.CUSTOM_DIALOG_COMMAND_LOGX_JOBS

export const getCustomDialogCommands = () => UserUtils.isDOCX() ? DefinitionUtils.getCustomDialogDocxCommands() : DefinitionUtils.getCustomDialogLogxCommands()