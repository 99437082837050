
import React, { ReactNode } from 'react';
import { Button, Modal } from 'BetaUX2Web-Components/src';

import { translate } from 'language/Language'


interface ConfirmationDialogProps {
  id: string;
  title: ReactNode;
  message: ReactNode;
  positive?: ReactNode;
  negative?: ReactNode;
  onPositiveAction?: () => void;
  onNegativeAction?: () => void;
  onCancel?: () => void;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  return <Modal.Modal id={props.id} size={'xs'} onClose={props.onCancel}>
    <Modal.Header id={`${props.id}_modalHeader`} title={props.title}/>
    <Modal.Main id={`${props.id}_modalMain`}>
      {props.message}
    </Modal.Main>
    <Modal.Footer id={`${props.id}_modalFooter`}>
      <Button
        id={`${props.id}_negativeButton`}
        onClick={props.onNegativeAction}
        text={props.negative ?? translate('general.no')} />
      <Button
        id={`${props.id}_positiveButton`}
        onClick={props.onPositiveAction}
        text={props.positive ?? translate('general.yes')}
        primary />
    </Modal.Footer>
  </Modal.Modal>;
};