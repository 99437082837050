const localStorageKey = 'betaux2web_localstorage'

const storesNotToLoad = ['snackbar', 'loadingspinner']

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(localStorageKey)
    if (serializedState) {
      const state = JSON.parse(serializedState)
      storesNotToLoad.forEach(notToLoadName => delete state[notToLoadName])
      return state
    } else {
      return undefined
    }
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    localStorage.setItem(localStorageKey, JSON.stringify(state))
  } catch (err) {
    console.error('Error saving state:', saveState)
  }
}


export const loadIndividualValue = (key) => {
  try {
    return localStorage.getItem(key)
  } catch (err) {
    return undefined
  }
}

export const saveIndividualValue = (key, value) => {
  try {
    localStorage.setItem(key, value)
  } catch (err) {
    console.error(`Error saving ${key} - ${value} in localstorage`)
  }
}