import store from 'redux/Store'
import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as DateUtils from 'utils/DateUtils'
import * as Utils from 'utils/Utils'
import * as ObjectUtils from 'utils/ObjectUtils'
import * as ResponseUtils from 'utils/ResponseUtils'
import * as SearchActions from 'redux/actions/SearchActions'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import * as SEARCH_CONSTANTS from 'components/drawer/content/search/search_body/search_standard_search/SearchStandardSearch'
import * as UserUtils from 'utils/UserUtils'
import { restapiRequest } from 'utils/RequestUtils'

// actions
export const CUSTOM_SELECTION_CACHE_SEARCH_DATA = 'CUSTOM_SELECTION_CACHE_SEARCH_DATA'
export const CUSTOM_SELECTION_CACHE_UPDATED_SEARCH_DATA = 'CUSTOM_SELECTION_CACHE_UPDATED_SEARCH_DATA'
export const CUSTOM_SELECTION_REMOVE_CACHED_SEARCH_DATA = 'CUSTOM_SELECTION_REMOVE_CACHED_SEARCH_DATA'
export const CUSTOM_SELECTION_RESET_CACHE = 'CUSTOM_SELECTION_RESET_CACHE'

export const CUSTOM_SELECTION_GET_DOCUMENTS_START = 'CUSTOM_SELECTION_GET_DOCUMENTS_START'
export const CUSTOM_SELECTION_GET_DOCUMENTS_SUCCESS = 'CUSTOM_SELECTION_GET_DOCUMENTS_SUCCESS'
export const CUSTOM_SELECTION_GET_DOCUMENTS_FAILED = 'CUSTOM_SELECTION_GET_DOCUMENTS_FAILED'

export const CUSTOM_SELECTION_GET_DOCUMENT_START = 'CUSTOM_SELECTION_GET_DOCUMENT_START'
export const CUSTOM_SELECTION_GET_DOCUMENT_SUCCESS = 'CUSTOM_SELECTION_GET_DOCUMENT_SUCCESS'
export const CUSTOM_SELECTION_GET_DOCUMENT_FAILED = 'CUSTOM_SELECTION_GET_DOCUMENT_FAILED'

export const CUSTOM_SELECTION_UPDATE_DOCUMENT_START = 'CUSTOM_SELECTION_UPDATE_DOCUMENT_START'
export const CUSTOM_SELECTION_UPDATE_DOCUMENT_SUCCESS = 'CUSTOM_SELECTION_UPDATE_DOCUMENT_SUCCESS'
export const CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED = 'CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED'

export const CUSTOM_SELECTION_UPDATE_DOCUMENTS_START = 'CUSTOM_SELECTION_UPDATE_DOCUMENTS_START'
export const CUSTOM_SELECTION_UPDATE_DOCUMENTS_SUCCESS = 'CUSTOM_SELECTION_UPDATE_DOCUMENTS_SUCCESS'
export const CUSTOM_SELECTION_UPDATE_DOCUMENTS_FAILED = 'CUSTOM_SELECTION_UPDATE_DOCUMENTS_FAILED'

export const CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_START = 'CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_START'
export const CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_SUCCESS = 'CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_SUCCESS'
export const CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED = 'CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED'

export const CUSTOM_SELECTION_GET_PRINT_INFO_START = 'CUSTOM_SELECTION_GET_PRINT_INFO_START'
export const CUSTOM_SELECTION_GET_PRINT_INFO_SUCCESS = 'CUSTOM_SELECTION_GET_PRINT_INFO_SUCCESS'
export const CUSTOM_SELECTION_GET_PRINT_INFO_FAILED = 'CUSTOM_SELECTION_GET_PRINT_INFO_FAILED'

export const CUSTOM_SELECTION_NO_DOCUMENTS_FOUND = 'CUSTOM_SELECTION_NO_DOCUMENTS_FOUND'

export const CUSTOM_SELECTION_GET_UC4_JOBS_START = 'CUSTOM_SELECTION_GET_UC4_JOBS_START'
export const CUSTOM_SELECTION_GET_UC4_JOBS_SUCCESS = 'CUSTOM_SELECTION_GET_UC4_JOBS_SUCCESS'
export const CUSTOM_SELECTION_GET_UC4_JOBS_FAILED = 'CUSTOM_SELECTION_GET_UC4_JOBS_FAILED'
export const CUSTOM_SELECTION_NO_UC4_JOBS_FOUND = 'CUSTOM_SELECTION_NO_UC4_JOBS_FOUND'

export const CUSTOM_SELECTION_GET_UC4_JOB_START = 'CUSTOM_SELECTION_GET_UC4_JOB_START'
export const CUSTOM_SELECTION_GET_UC4_JOB_SUCCESS = 'CUSTOM_SELECTION_GET_UC4_JOB_SUCCESS'
export const CUSTOM_SELECTION_GET_UC4_JOB_FAILED = 'CUSTOM_SELECTION_GET_UC4_JOB_FAILED'

export const CUSTOM_SELECTION_GET_ZOS_JOBS_START = 'CUSTOM_SELECTION_GET_ZOS_JOBS_START'
export const CUSTOM_SELECTION_GET_ZOS_JOBS_SUCCESS = 'CUSTOM_SELECTION_GET_ZOS_JOBS_SUCCESS'
export const CUSTOM_SELECTION_GET_ZOS_JOBS_FAILED = 'CUSTOM_SELECTION_GET_ZOS_JOBS_FAILED'
export const CUSTOM_SELECTION_NO_ZOS_JOBS_FOUND = 'CUSTOM_SELECTION_NO_ZOS_JOBS_FOUND'

export const CUSTOM_SELECTION_GET_ZOS_JOB_START = 'CUSTOM_SELECTION_GET_ZOS_JOB_START'
export const CUSTOM_SELECTION_GET_ZOS_JOB_SUCCESS = 'CUSTOM_SELECTION_GET_ZOS_JOB_SUCCESS'
export const CUSTOM_SELECTION_GET_ZOS_JOB_FAILED = 'CUSTOM_SELECTION_GET_ZOS_JOB_FAILED'

export const CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_START = 'CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_START'
export const CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_SUCCESS = 'CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_SUCCESS'
export const CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_FAILED = 'CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_FAILED'
export const CUSTOM_SELECTION_NO_ZOS_JOB_LOGS_FOUND = 'CUSTOM_SELECTION_NO_ZOS_JOB_LOGS_FOUND'

export const CUSTOM_SELECTION_GET_CONTROLM_JOBS_START = 'CUSTOM_SELECTION_GET_CONTROLM_JOBS_START'
export const CUSTOM_SELECTION_GET_CONTROLM_JOBS_SUCCESS = 'CUSTOM_SELECTION_GET_CONTROLM_JOBS_SUCCESS'
export const CUSTOM_SELECTION_GET_CONTROLM_JOBS_FAILED = 'CUSTOM_SELECTION_GET_CONTROLM_JOBS_FAILED'
export const CUSTOM_SELECTION_NO_CONTROLM_JOBS_FOUND = 'CUSTOM_SELECTION_NO_CONTROLM_JOBS_FOUND'

export const CUSTOM_SELECTION_GET_SYSLOG_JOBS_START = 'CUSTOM_SELECTION_GET_SYSLOG_JOBS_START'
export const CUSTOM_SELECTION_GET_SYSLOG_JOBS_SUCCESS = 'CUSTOM_SELECTION_GET_SYSLOG_JOBS_SUCCESS'
export const CUSTOM_SELECTION_GET_SYSLOG_JOBS_FAILED = 'CUSTOM_SELECTION_GET_SYSLOG_JOBS_FAILED'
export const CUSTOM_SELECTION_NO_SYSLOG_JOBS_FOUND = 'CUSTOM_SELECTION_NO_SYSLOG_JOBS_FOUND'
export const CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_START = 'CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_START'
export const CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_SUCCESS = 'CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_SUCCESS'
export const CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_FAILED = 'CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_FAILED'
export const CUSTOM_SELECTION_NO_STONEBRANCH_JOBS_FOUND = 'CUSTOM_SELECTION_NO_STONEBRANCH_JOBS_FOUND'


export const CUSTOM_SELECTION_RESET = 'CUSTOM_SELECTION_RESET'

/**
 * @description Calls the rest api and gets a single document.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * "OFDOCID", "B93DATE", "B93TIME", "SORTDT", "FORM", "LGRCUSR", "SRCJOBN", "SRCJOBI", "SRCPRCD",
 * "SRCSTPD", "SRCDDND", "EXT",  "DREPORT", "WREPORT", "LTOKEN", "RTOKEN", "SMODE", "LTITLE",
 * "STATUS", "SRCPAGES", "SRCLNCT", "SRCMLRL", "LGRONLNE", "LGRARCH", "LGRAUTOP", "LGRFFARC",
 * "LGRFFDEL", "LGRFFREL", "LGRINDEX", "LGRXEROX", "LGRAFP", "LGRTRC", "LGRASCII", "SRCRECFM",
 * "LGRMAXRL", "LREFD", "LGRNOTES", "PRTSTAT", "B93SDATE", "B93STIME", "EIXPAGES", "SPLPAGES",
 * "PBDNAME", "SRCSUBU", "JOBRC", "OWNER", "DOCUSR1", "DOCUSR2", "DOCUSR3", "DOCUSR4", "DOCUSR5",
 * "DOCUSR6", "DOCUSR7", "DOCUSR8", "TEXTENCO", "NLREFD", "LGRSECIX", "EDTSTAT", "EDTTEXT",
 * "DOCSTAT","OUTSTAT", "PENDING", "BUXORGDT", "BUXINPDT"
 * @param {String} docid The id of the document, corresponds to LTOKEN + RTOKEN
 * @param {String} process processing instructions, optional.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getDocument(fields, docid, process, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENT_START })

    docid = docid.length === 32 ? docid : docid + '0000000000000000'
    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (docid) { queryParams.push(`DOCID=${encodeURIComponent(docid)}`) }
    if (process) { queryParams.push(`PROCESS=${encodeURIComponent(process)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/document?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    return cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENT_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENT_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
          LoadingSpinnerActions.hide()(dispatch)
          return jsondata.data
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', lang, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}


/**
 * @description Calls the rest api and downloads a single document.
 * @param {Object} docid ID of the doucment to download
 * @param {Object} filename Name the save should be saved with
 * @param {Function} callback
 */
export function downloadDocument(docid, downloadname, callback) {
  return dispatch => {

    const usertoken = store.getState().auth.serverdata.token

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_START })
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/document/download?USERTOKEN=${usertoken}&DOCID=${docid}&MIMETYPE=save${downloadname ? `&RESFILENAME=${encodeURIComponent(downloadname)}` : ''}`, {
        method: 'get',
        headers: {
          token: undefined
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    cancelablePromise
      .promise
      .then(data => {
        // If data.status 200, document will be returned binary
        if (data.status === 200) {
          data.blob()
            .then(result => {
              return URL.createObjectURL(result)
            })
            .then(href => {
              // Open download dialog:
              // Create a-tag with href, simulate click and delete it afterward.
              let element = document.createElement('a')
              element.setAttribute('href', href)
              element.setAttribute('download', downloadname)
              element.style.display = 'none'
              document.body.appendChild(element)
              element.click()
              document.body.removeChild(element)

              dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_SUCCESS })
            })
        }
        else { // Else we expect the result (error) in a json stream
          data.json()
            .then(json => {
              let rc = json.error.rc
              let irc = json.error.irc
              // check general errors
              let error = GeneralErrorHandler.handleResponse(rc, irc, json.error.param, dispatch)
              SnackbarActions.show(error.message, error.type)(dispatch)
              dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED, payload: { error } })
            })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', undefined, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and downloads a single document.
 * @param {Object} docid ID of the doucment to download
 * @param {Object} downloadname Name the save should be saved with
 * @param {Function} callback
 */
export function downloadDocumentHex(docid, downloadname, displayMode, showPageHeader, showResources, callback) {
  return dispatch => {
    const usertoken = store.getState().auth.serverdata.token
    const paramTranslator = {
      USERTOKEN: usertoken,
      DOCID: docid,
      MIMETYPE: 'hex',
      HEX: 'YES',
      HEXBLOCK: displayMode,
      HEXPAB: showPageHeader,
      HEXRES: showResources
    }
    if (downloadname) {
      paramTranslator.RESFILENAME = encodeURIComponent(downloadname)
    }

    let url = `${Config.REST_API_URL}/api/documents/document/download?`
    // Get key value pairs from object and lopp them to build the url
    Object.entries(paramTranslator).forEach(([key, value], index) => {
      // Just adds the "&" if its not the first parameter
      let prefix = ''
      if (index > 0) {
        prefix = '&'
      }
      url += `${prefix}${key}=${value}`
    })

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_START })
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(url, {
        method: 'get',
        headers: {
          token: undefined
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    cancelablePromise
      .promise
      .then(data => {
        // If data.status 200, document will be returned binary
        if (data.status === 200) {
          data.blob()
            .then(result => {
              return URL.createObjectURL(result)
            })
            .then(href => {
              // Open download dialog:
              // Create a-tag with href, simulate click and delete it afterward.
              let element = document.createElement('a')
              element.setAttribute('href', href)
              element.setAttribute('download', downloadname)
              element.style.display = 'none'
              document.body.appendChild(element)
              element.click()
              document.body.removeChild(element)

              dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_SUCCESS })
              if (callback) {
                callback()
              }
            })
        }
        else { // Else we expect the result (error) in a json stream
          data.json()
            .then(json => {
              let rc = json.error.rc
              let irc = json.error.irc
              // check general errors
              let error = GeneralErrorHandler.handleResponse(rc, irc, json.error.param, dispatch)
              SnackbarActions.show(error.message, error.type)(dispatch)
              dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED, payload: { error } })
            })
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', undefined, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and downloads a single document.
 * @param {Object} docid ID of the doucment to download
 * @param {Object} downloadname Name the save should be saved with
 * @param {Function} callback
 */
export function downloadDocumentText(docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback) {
  return dispatch => {
    const usertoken = store.getState().auth.serverdata.token
    const paramTranslator = {
      USERTOKEN: usertoken,
      DOCID: docid,
      MIMETYPE: mimetype,
      DISPTAB: 'YES',
      DISPNOP: showNOP || 'NO',
      DISPTLE: showTLE || 'NO',
      DISPPJL: showPJL || 'NO',
      DISPLINENO: (showNOP === 'YES' || showTLE === 'YES' || showPJL === 'YES') ? 'YES' : 'NO',
    }
    if (downloadname) {
      paramTranslator.RESFILENAME = encodeURIComponent(downloadname)
    }

    let url = `${Config.REST_API_URL}/api/documents/document/download?`
    // Get key value pairs from object and lopp them to build the url
    Object.entries(paramTranslator).forEach(([key, value], index) => {
      // Just adds the "&" if its not the first parameter
      let prefix = ''
      if (index > 0) {
        prefix = '&'
      }
      url += `${prefix}${key}=${value}`
    })

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_START })
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(url, {
        method: 'get',
        headers: {
          token: undefined
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    cancelablePromise
      .promise
      .then(data => {
        // If data.status 200, document will be returned binary
        if (data.status === 200) {
          data.blob()
            .then(result => {
              return URL.createObjectURL(result)
            })
            .then(href => {
              // Open download dialog:
              // Create a-tag with href, simulate click and delete it afterward.
              let element = document.createElement('a')
              element.setAttribute('href', href)
              element.setAttribute('download', downloadname)
              element.style.display = 'none'
              document.body.appendChild(element)
              element.click()
              document.body.removeChild(element)

              dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_SUCCESS })
              if (callback) {
                callback()
              }
            })
        }
        else { // Else we expect the result (error) in a json stream
          data.json()
            .then(json => {
              let rc = json.error.rc
              let irc = json.error.irc
              // check general errors
              let error = GeneralErrorHandler.handleResponse(rc, irc, json.error.param, dispatch)
              SnackbarActions.show(error.message, error.type)(dispatch)
              dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED, payload: { error } })
            })
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', undefined, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function downloadDocumentSideFile(docid, downloadname, callback) {
  return dispatch => {
    const usertoken = store.getState().auth.serverdata.token
    const paramTranslator = {
      USERTOKEN: usertoken,
      DOCID: docid,
      MIMETYPE: 'save',
      DISPIDXDATA: 'YES'
    }
    if (downloadname) {
      paramTranslator.RESFILENAME = encodeURIComponent(downloadname)
    }

    let url = `${Config.REST_API_URL}/api/documents/document/download?`
    // Get key value pairs from object and lopp them to build the url
    Object.entries(paramTranslator).forEach(([key, value], index) => {
      // Just adds the "&" if its not the first parameter
      let prefix = ''
      if (index > 0) {
        prefix = '&'
      }
      url += `${prefix}${key}=${value}`
    })

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_START })
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      fetch(url, {
        method: 'get',
        headers: {
          datemask: DateUtils.DDMMYYYY_DOT
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    cancelablePromise
      .promise
      .then(data => {
        // If data.status 200, document will be returned binary
        if (data.status === 200) {
          data.blob()
            .then(result => {
              return URL.createObjectURL(result)
            })
            .then(href => {
              // Open download dialog:
              // Create a-tag with href, simulate click and delete it afterward.
              let element = document.createElement('a')
              element.setAttribute('href', href)
              element.setAttribute('download', downloadname)
              element.style.display = 'none'
              document.body.appendChild(element)
              element.click()
              document.body.removeChild(element)

              dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_SUCCESS })
              if (callback) {
                callback()
              }
            })
        }
        else { // Else we expect the result (error) in a json stream
          data.json()
            .then(json => {
              let rc = json.error.rc
              let irc = json.error.irc
              // check general errors
              let error = GeneralErrorHandler.handleResponse(rc, irc, json.error.param, dispatch)
              SnackbarActions.show(error.message, error.type)(dispatch)
              dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED, payload: { error } })
            })
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', undefined, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_DOWNLOAD_DOCUMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Build and opens the url to open the document in _bet aview.
 * @param {String} docid ID of the doucment to open
 * @param {String} betaviewBaseurl URL of _beta view: e.g. http://server.com/betaview
 * @param {String} betaviewFavname Favoritename of _beta view which is connected to the given bux-server
 * @param {Function} callback
 */
export function displayDocument(docid, betaviewBaseurl, betaviewFavname, callback) {
  return dispatch => {
    if (!betaviewBaseurl || !betaviewFavname) { // Baseurl missing
      SnackbarActions.show(Lang.translate('general.betaview_not_configured'), SnackbarActions.TYPE_ERROR)(dispatch)
    } else { // Config given

      const token = encodeURIComponent(store.getState().auth.serverdata.token)
      const bweurl = `${betaviewBaseurl}${betaviewBaseurl[betaviewBaseurl.length - 1] === '/' ? '' : '/'}opendocumentlink.action?docid=${docid}&favid=${betaviewFavname}&utoken=${token}`
      const windowid = 'docbrowser@bwequ4711:8080/betaview'
      window.open(bweurl, windowid)

      if (callback) {
        callback()
      }
    }
  }
}

const getDispatchType = (resultTableType) => {
  if (resultTableType === 'BRWUC4') return CUSTOM_SELECTION_GET_UC4_JOBS_SUCCESS
  if (resultTableType === 'BRWCTM') return CUSTOM_SELECTION_GET_CONTROLM_JOBS_SUCCESS
  if (resultTableType === 'BRWSTB') return CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_SUCCESS
  if (resultTableType === 'BRWSYSL') return CUSTOM_SELECTION_GET_SYSLOG_JOBS_SUCCESS
  if (resultTableType === 'BRWZOS') return CUSTOM_SELECTION_GET_ZOS_JOBS_SUCCESS

  return CUSTOM_SELECTION_GET_DOCUMENTS_SUCCESS
}

export const getCachedSearchResults = (cacheKey, resultTableType, callback) => {
  return dispatch => {
    const customSelection = store.getState().search.customselection
    if (!(customSelection.cache && cacheKey in customSelection.cache)) return

    const dispatchType = getDispatchType(resultTableType)

    dispatch({
      type: dispatchType,
      payload: customSelection.cache[cacheKey].searchResults,
      keepPagination: customSelection.keepPagination ?? false
    })

    if (callback) {
      callback()
    }
  }
}

// WIP: Add function explanation
const parseCustomDialogConfigForCache = (customDialogConfig) => {
  return dispatch => {
    const configKeysToSave = ['amount', 'cchoice', 'cchoice2', 'date', 'datetime', 'datedate', 'dtframe', 'fileopen', 'hchoice', 'lchoice', 'mchoice', 'numeric', 'schoice', 'string']
    const prefs = store.getState().auth.serverdata.preferences

    const cacheKey = store.getState().auth.serverdata.systemname === 'DOCX' ? prefs[Preferences.SEARCH_CURRENT_CUSTOM_DIALOG_DOCX] : prefs[Preferences.SEARCH_CURRENT_CUSTOM_DIALOG_LOGX]
    const currentCachedConfig = store.getState().search.customselection.cache && cacheKey in store.getState().search.customselection.cache ? store.getState().search.customselection.cache[cacheKey].customDialogConfig : null

    if (currentCachedConfig) {
      dispatch({ type: CUSTOM_SELECTION_REMOVE_CACHED_SEARCH_DATA, cacheKey: cacheKey })
    }

    const nextConfigWithoutRefs = Object.entries(customDialogConfig).reduce((acc, [key, val]) => {
      if (configKeysToSave.includes(key)) {
        Object.keys(val).forEach((k) => {
          delete val[k]['ref']
          delete val[k]['ref2']
        })
        acc[key] = val
      }
      return acc
    }, {})

    return { cacheKey: cacheKey, nextConfigWithoutRefs: nextConfigWithoutRefs }
  }
}

const getCurrentSearchData = (resultTableType) => {
  if (resultTableType === 'BRWUC4') return store.getState().search.customselection.uc4.uc4Jobs
  if (resultTableType === 'BRWCTM') return store.getState().search.customselection.controlm.controlmJobs
  if (resultTableType === 'BRWSTB') return store.getState().search.customselection.stonebranchJobs
  if (resultTableType === 'BRWSYSL') return store.getState().search.customselection.syslog.syslogJobs
  if (resultTableType === 'BRWZOS') return store.getState().search.customselection.zos.zosJobs

  return store.getState().search.customselection.documents
}

export const cacheSearchData = (customDialogConfig, resultTableType) => {
  return dispatch => {
    const { cacheKey, nextConfigWithoutRefs } = parseCustomDialogConfigForCache(customDialogConfig)(dispatch)

    dispatch({
      type: CUSTOM_SELECTION_CACHE_SEARCH_DATA,
      cacheKey: cacheKey,
      customDialog: store.getState().customDialogs.customDialog,
      customDialogConfig: nextConfigWithoutRefs,
      searchResults: getCurrentSearchData(resultTableType),
      resultTable: store.getState().customDialogs.resultTable,
      searchParameters: store.getState().auth.serverdata.preferences[Preferences.SEARCH_LAST_CUSTOM_SEARCH]
    })
  }
}

export const cacheUpdatedSearchResults = (resultTableType) => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const cacheKey = store.getState().auth.serverdata.systemname === 'DOCX' ? prefs[Preferences.SEARCH_CURRENT_CUSTOM_DIALOG_DOCX] : prefs[Preferences.SEARCH_CURRENT_CUSTOM_DIALOG_LOGX]

    dispatch({
      type: CUSTOM_SELECTION_CACHE_UPDATED_SEARCH_DATA,
      cacheKey: cacheKey,
      searchResults: getCurrentSearchData(resultTableType)
    })
  }
}

export const resetCache = () => {
  return dispatch => {
    dispatch({ type: CUSTOM_SELECTION_RESET_CACHE })
  }
}


/**
 * @description Calling the rest api and do the getDocuments request.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * "OFDOCID", "B93DATE", "B93TIME", "SORTDT", "FORM", "LGRCUSR", "SRCJOBN", "SRCJOBI", "SRCPRCD",
 * "SRCSTPD", "SRCDDND", "EXT",  "DREPORT", "WREPORT", "LTOKEN", "RTOKEN", "SMODE", "LTITLE",
 * "STATUS", "SRCPAGES", "SRCLNCT", "SRCMLRL", "LGRONLNE", "LGRARCH", "LGRAUTOP", "LGRFFARC",
 * "LGRFFDEL", "LGRFFREL", "LGRINDEX", "LGRXEROX", "LGRAFP", "LGRTRC", "LGRASCII", "SRCRECFM",
 * "LGRMAXRL", "LREFD", "LGRNOTES", "PRTSTAT", "B93SDATE", "B93STIME", "EIXPAGES", "SPLPAGES",
 * "PBDNAME", "SRCSUBU", "JOBRC", "OWNER", "DOCUSR1", "DOCUSR2", "DOCUSR3", "DOCUSR4", "DOCUSR5",
 * "DOCUSR6", "DOCUSR7", "DOCUSR8", "TEXTENCO", "NLREFD", "LGRSECIX", "EDTSTAT", "EDTTEXT",
 * "DOCSTAT","OUTSTAT", "PENDING", "BUXORGDT", "BUXINPDT"
 * @param {Object} searchParams The params for the search. Possible params:
 * MAXENTRY: maximum number of hits to be returned,
 * FROMLAST: The from last date pattern,
 * TUNITS: The units of the time (only used on last tab -> custom),
 * SDATE: The start date (only used on date tab),
 * STIME: The start time (only used on date tab),
 * EDATE: The end date (only used on date tab),
 * ETIME: The end time (only used on date tab),
 * TOKEN: The list-token (only used on list timestamp tab),
 * PROCESS: The type of the document (Possible values: LIST, REPORT, ALL),
 * FORM: The form pattern,
 * EXTENSION: The extension pattern,
 * REPORT: The report pattern,
 * RECI: The recipient pattern,
 * FOLDER: The folder pattern,
 * TAB: The tab name pattern,
 * NODE: The node name pattern,
 * JOBNAME: The jobname pattern,
 * TITLE: The title pattern,
 * FTITLE: The column position of text in title,
 * LGRSTAT: The control status (Possible values: H, C)
 * CUSER: The control user
 * ONLINE: The flag for only select online documents (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false),
 * ARCHIVE: The flag for select documents according to archive status (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false, 'PENDING', 'PEND'),
 * INDEXED: The flag for only select indexed documents (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false),
 * DELETE: The flag for only select documents that have been marked for delete (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false),
 * RELOAD: The flag for only select documents that have been marked for reload (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false),
 * LGRNOTE: The flag for only select documents with browser notes (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false),
 * LGRAUTO: The flag for only select documents which are automatically printed
 * DOCTYPE: The document type, for example *.txt or *.afp,
 * SRCSUBU: Only select document which were read-in under the user,
 * OWNER: The owner pattern,
 * DOCUSR1: The docuser1 pattern,
 * DOCUSR2: The docuser2 pattern,
 * DOCUSR3: The docuser3 pattern,
 * DOCUSR4: The docuser4 pattern,
 * DOCUSR5: The docuser5 pattern,
 * DOCUSR6: The docuser6 pattern,
 * DOCUSR7: The docuser7 pattern,
 * DOCUSR8: The docuser8 pattern,
 * EDTSTAT: The edit status (Possible values: 'X' for Error, 'P' for Pin, 'S' for Stamp, 'C' for Clip, 'H' for Hook),
 * SRCOBT: The origin of the document (Possible values: 'IMPORT', 'EDF', 'B93UX', 'MVS', 'RESCAN', 'RELOAD'),
 * JOBRC: The error code,
 * PRTSTAT: The output state (Possible values: 'P' for Printed, 'E' for Error, 'A' for Active, 'W' for Waiting, 'H' for Hold, 'D' for Delete, 'C' for Cancel, 'Q' for Requeued, 'R' for Retry, 'N' for Not found),
 * DCR: The output channel pattern,
 * BREQUEST: The requestor pattern
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getDocuments({ fields, searchParams, callback, keepPagination = false }) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENTS_START })

    // clear invalid values of object and encode
    searchParams = ObjectUtils.removeByValue(searchParams, [undefined, null, ''])

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    Object.keys(searchParams).forEach(key => queryParams.push(`${key}=${encodeURIComponent(searchParams[key])}`))

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/documents?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0020' && irc === '3018') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_NO_DOCUMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENTS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}


export function getDocumentsByGlobalIndex({ fields, searchParams, callback, keepPagination = false }) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENTS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    Object.keys(searchParams).forEach(key => queryParams.push(`${key}=${encodeURIComponent(searchParams[key])}`))

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/indexes/global?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0020' && irc === '3018') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_NO_DOCUMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENTS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_DOCUMENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and updates a document in standard selection.
 * @param {Object} documentDefinition possible fields:
 * 'DOCID', 'ARCHIVE', 'DELETE', 'RELOAD'
 * @param {String} useCase possible values: 'generalTab', 'statusTab', 'retentionTab'
 * @param {Boolean} refresh Do a get-request with params saved in preferences.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function updateDocument(documentDefinition, useCase, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_START })
    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: ${documentDefinition.FORM}`
    if (documentDefinition.EXT !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: ${documentDefinition.EXT}`
    }
    if (documentDefinition.REPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: ${documentDefinition.REPORT}`
    }
    let body = { DOCID: documentDefinition['DOCID'].length === 32 ? documentDefinition['DOCID'] : documentDefinition['DOCID'] + '0000000000000000' }
    if (useCase === 'generalTab') {
      body = {
        ...body,
        LTITLE: documentDefinition['LTITLE'],
        DOCUSR1: documentDefinition['DOCUSR1'],
        DOCUSR2: documentDefinition['DOCUSR2'],
        DOCUSR3: documentDefinition['DOCUSR3'],
        DOCUSR4: documentDefinition['DOCUSR4'],
        DOCUSR5: documentDefinition['DOCUSR5'],
        DOCUSR6: documentDefinition['DOCUSR6'],
        DOCUSR7: documentDefinition['DOCUSR7'],
        DOCUSR8: documentDefinition['DOCUSR8'],
        TEXTENCO: documentDefinition['TEXTENCO'],
        SAVEUTF8: documentDefinition['ENCODING'],
        OWNER: documentDefinition['OWNER'],
      }
    }
    else if (useCase === 'statusTab') {
      body = {
        ...body,
        EDTSTAT: documentDefinition['EDTSTAT'],
        EDTTEXT: documentDefinition['EDTTEXT'],
        STATUS: documentDefinition['STATUS'],
        CUSER: documentDefinition['CUSER'] !== null ? documentDefinition['CUSER'] : '',
      }
    }
    else if (useCase === 'retentionTab') {
      body = {
        ...body,
        ONLRETPD: documentDefinition['ONLRETPD'],
        ONLCTYPE: documentDefinition['ONLCTYPE'],
        ARCRETPD: documentDefinition['ARCRETPD'],
        ARCHMED: documentDefinition['ARCHMED'],
        ARCHNOTE: documentDefinition['ARCHNOTE']
      }
    }
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/document`, {
        method: 'put',
        body: body
      })
    )
    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('documents.modify_document_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('documents.update_document_error', lang, [documentDefinition['DOCID'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a document in standard selection.
 * @param {Object} updateObj
 * @param {Array} docInformation
 * @param {*} callback The callback which will be called when the request was successful. Can be null.
 */
export function updateMarkMulti(updateObj, docInformation, callback, refreshSearch) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    LoadingSpinnerActions.show()(dispatch)
    dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENTS_START })

    const selectLanguageKey = type => {
      if (updateObj['DELETE']) {
        if (UserUtils.isDOCX()) {
          return type === 'SINGLE' ? 'standardselection.mark_delete_success' : 'search.multiple_documents_were_marked_for_delete'
        }
        return type === 'SINGLE' ? 'standardselection.mark_delete_success_log' : 'search.multiple_logs_were_marked_for_delete'
      }
      else if (updateObj['DELETE'] === '') {
        if (UserUtils.isDOCX()) {
          return type === 'SINGLE' ? 'standardselection.unmark_delete_success' : 'search.multiple_documents_were_unmarked_for_delete'
        }
        return type === 'SINGLE' ? 'standardselection.unmark_delete_success_log' : 'search.multiple_logs_were_unmarked_for_delete'
      }
      if (updateObj['ARCHIVE']) {
        if (UserUtils.isDOCX()) {
          return type === 'SINGLE' ? 'standardselection.mark_archive_success' : 'search.multiple_documents_were_marked_for_archive'
        }
        return type === 'SINGLE' ? 'standardselection.mark_archive_success_log' : 'search.multiple_logs_were_marked_for_archive'
      }
      else if (updateObj['ARCHIVE'] === '') {
        if (UserUtils.isDOCX()) {
          return type === 'SINGLE' ? 'standardselection.unmark_archive_success' : 'search.multiple_documents_were_unmarked_for_archive'
        }
        return type === 'SINGLE' ? 'standardselection.unmark_archive_success_log' : 'search.multiple_logs_were_unmarked_for_archive'
      }
      if (updateObj['RELOAD']) {
        if (UserUtils.isDOCX()) {
          return type === 'SINGLE' ? 'standardselection.mark_reload_success' : 'search.multiple_documents_were_marked_for_reload'
        }
        return type === 'SINGLE' ? 'standardselection.mark_reload_success_log' : 'search.multiple_logs_were_marked_for_reload'
      }
      else if (updateObj['RELOAD'] === '') {
        if (UserUtils.isDOCX()) {
          return type === 'SINGLE' ? 'standardselection.unmark_reload_success' : 'search.multiple_documents_were_unmarked_for_reload'
        }
        return type === 'SINGLE' ? 'standardselection.unmark_reload_success_log' : 'search.multiple_logs_were_unmarked_for_reload'
      }
    }
    const getLanguageKeyParams = entry => {
      let docInfo = `${Lang.translate('general.form')}: "${entry.form}"`
      if (entry.extension !== '') {
        docInfo += `, ${Lang.translate('general.extension')}: "${entry.extension}"`
      }
      if (entry.report !== '') {
        docInfo += `, ${Lang.translate('general.report')}: "${entry.report}"`
      }
      return docInfo
    }
    const docCount = docInformation.length
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/documents`, {
        method: 'put',
        body: updateObj
      })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        const rsp = ResponseUtils.processResponse(
          jsondata.additionalInfo,
          docCount <= 10
            ? index => Lang.translate(selectLanguageKey('SINGLE'), lang, getLanguageKeyParams(docInformation[index]))
            : Lang.translate(selectLanguageKey('COMBINED')),
          dispatch,
          // last parameter can be used to tell the function, that the messages should not be combined
          docCount > 10
        )
        SnackbarActions.showMultiple(rsp.snackbarContent)(dispatch)
        if (!jsondata.success) {
          dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENTS_FAILED })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENTS_SUCCESS, payload: jsondata.data })
          // call callback on success
          if (callback) {
            callback()
          }

          refreshSearch
            ? refreshSearch()
            : LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        // let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        // if (error.isCanceled) {
        //   reason = Lang.translate('general.fetch_data_timeout', lang)
        // }
        // if (updateObj['DELETE'] === 'PENDING') {
        //   SnackbarActions.show(Lang.translate('standardselection.mark_delete_error', lang, [documentDefinition['DOCID'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        // } else {
        //   SnackbarActions.show(Lang.translate('standardselection.unmark_delete_error', lang, documentMessage, reason), SnackbarActions.TYPE_SUCCESS)(dispatch)
        // }
        // ! Check what happens here when we come into the catch block. Ask Marcus.
        LoadingSpinnerActions.hide()(dispatch)
        dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENTS_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a document in standard selection.
 * @param {*} documentDefinition
 * @param {*} callback The callback which will be called when the request was successful. Can be null.
 * @param {Function} refreshFunction The function will be called to refresh the data after modifying.
 */
export function markArchive(documentDefinition, callback, refreshFunction) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_START })
    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: "${documentDefinition.FORM}"`
    if (documentDefinition.EXT !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: "${documentDefinition.EXT}"`
    }
    if (documentDefinition.REPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: "${documentDefinition.REPORT}"`
    }

    const body = {
      DOCID: documentDefinition['DOCID'].length === 32
        ? documentDefinition['DOCID']
        : documentDefinition['DOCID'] + '0000000000000000',
      ARCHIVE: documentDefinition['ARCHIVE']
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/document`, {
        method: 'put',
        body: body
      })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_SUCCESS, payload: jsondata.data })
          if (documentDefinition['ARCHIVE'] === 'PENDING') {
            SnackbarActions.show(Lang.translate('standardselection.mark_archive_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          } else if (documentDefinition['ARCHIVE'] === 'DELETE') {
            SnackbarActions.show(Lang.translate('standardselection.delete_from_archive_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          } else {
            SnackbarActions.show(Lang.translate('standardselection.unmark_archive_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          }

          // call callback on success
          if (callback) {
            callback()
          }

          if (refreshFunction) {
            refreshFunction()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        if (documentDefinition['ARCHIVE'] === 'PENDING') {
          SnackbarActions.show(Lang.translate('standardselection.mark_archive_error', lang, [documentDefinition['DOCID'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        } else if (documentDefinition['ARCHIVE'] === 'DELETE') {
          SnackbarActions.show(Lang.translate('standardselection.delete_from_archive_error', lang, documentMessage, reason), SnackbarActions.TYPE_SUCCESS)(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('standardselection.unmark_archive_error', lang, documentMessage, reason), SnackbarActions.TYPE_SUCCESS)(dispatch)
        }
        dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a document in standard selection.
 * @param {*} documentDefinition
 * @param {*} callback The callback which will be called when the request was successful. Can be null.
 * @param {Function} refreshFunction The function will be called to refresh the data after modifying.
 */
export function markDelete(documentDefinition, callback, refreshFunction) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_START })
    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: "${documentDefinition.FORM}"`
    if (documentDefinition.EXT !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: "${documentDefinition.EXT}"`
    }
    if (documentDefinition.REPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: "${documentDefinition.REPORT}"`
    }

    const body = {
      DOCID: documentDefinition['DOCID'].length === 32
        ? documentDefinition['DOCID']
        : documentDefinition['DOCID'] + '0000000000000000',
      DELETE: documentDefinition['DELETE']
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/document`, {
        method: 'put',
        body: body
      })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED, payload: { error } })
        } else {
          dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_SUCCESS, payload: jsondata.data })
          if (documentDefinition['DELETE'] === 'PENDING') {
            SnackbarActions.show(Lang.translate('standardselection.mark_delete_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          } else {
            SnackbarActions.show(Lang.translate('standardselection.unmark_delete_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          }

          // call callback on success
          if (callback) {
            callback()
          }

          if (refreshFunction) {
            refreshFunction()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        if (documentDefinition['DELETE'] === 'PENDING') {
          SnackbarActions.show(Lang.translate('standardselection.mark_delete_error', lang, [documentDefinition['DOCID'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('standardselection.unmark_delete_error', lang, documentMessage, reason), SnackbarActions.TYPE_SUCCESS)(dispatch)
        }
        dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a document in standard selection.
 * @param {*} documentDefinition
 * @param {*} callback The callback which will be called when the request was successful. Can be null.
 * @param {Function} refreshFunction The function will be called to refresh the data after modifying.
 */
export function markReload(documentDefinition, callback, refreshFunction) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_START })
    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: ${documentDefinition.FORM}`
    if (documentDefinition.EXT !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: ${documentDefinition.EXT}`
    }
    if (documentDefinition.REPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: ${documentDefinition.REPORT}`
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/document`, {
        method: 'put',
        body: documentDefinition
      })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED, payload: { error } })
        } else {

          dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_SUCCESS, payload: jsondata.data })
          if (documentDefinition['RELOAD'] === 'PENDING') {
            SnackbarActions.show(Lang.translate('standardselection.mark_reload_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          } else {
            SnackbarActions.show(Lang.translate('standardselection.unmark_reload_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          }

          // call callback on success
          if (callback) {
            callback()
          }

          if (refreshFunction) {
            refreshFunction()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        if (documentDefinition['RELOAD'] === 'PENDING') {
          SnackbarActions.show(Lang.translate('standardselection.mark_reload_error', lang, [documentDefinition['DOCID'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('standardselection.unmark_reload_error', lang, documentMessage, reason), SnackbarActions.TYPE_SUCCESS)(dispatch)
        }
        dispatch({ type: CUSTOM_SELECTION_UPDATE_DOCUMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Gets the search values from preferences to refresh the search
 * @returns {Object}
 */
const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const activeTabIndex = prefs[Preferences.SEARCH_STD_ACTIVE_TAB]
    let lastTimeMode = prefs[Preferences.SEARCH_STD_LASTTIME_MODE]
    let sdate = ''
    let stime = ''
    let edate = ''
    let etime = ''
    let customLast = ''
    let customUnit = ''
    let outputStatusValues = ''
    let listTimestamp = ''

    if (activeTabIndex === 0) {
      if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_TODAY || lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_YESTERDAY) {
        sdate = prefs[Preferences.SEARCH_STD_LASTTIME_MODE].toUpperCase()
      } else if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_CUSTOM) {
        customLast = prefs[Preferences.SEARCH_STD_CUSTOMLAST]
        customUnit = prefs[Preferences.SEARCH_STD_CUSTOM_UNIT]
      }
    } else if (activeTabIndex === 1) {
      sdate = DateUtils.getDateFromUnixTimestamp(Utils.convertStringToInt(prefs[Preferences.SEARCH_STD_FROMDATE]), DateUtils.DDMMYYYY_DOT)
      stime = Utils.convertStringToInt(prefs[Preferences.SEARCH_STD_FROMTIME])
      edate = DateUtils.getDateFromUnixTimestamp(Utils.convertStringToInt(prefs[Preferences.SEARCH_STD_TODATE]), DateUtils.DDMMYYYY_DOT)
      etime = Utils.convertStringToInt(prefs[Preferences.SEARCH_STD_TOTIME])
    } else if (activeTabIndex === 2) {
      listTimestamp = prefs[Preferences.SEARCH_STD_LISTTIMESTAMP]
    }

    if (prefs[Preferences.SEARCH_STD_OUTPUT_STATUS_PRINTED]) {
      outputStatusValues += 'P'
    }
    if (prefs[Preferences.SEARCH_STD_OUTPUT_STATUS_ERROR]) {
      outputStatusValues += 'E'
    }
    if (prefs[Preferences.SEARCH_STD_OUTPUT_STATUS_HOLD]) {
      outputStatusValues += 'H'
    }
    if (prefs[Preferences.SEARCH_STD_OUTPUT_STATUS_WAITING]) {
      outputStatusValues += 'W'
    }
    if (prefs[Preferences.SEARCH_STD_OUTPUT_STATUS_DELETE]) {
      outputStatusValues += 'D'
    }
    if (prefs[Preferences.SEARCH_STD_OUTPUT_STATUS_NOTFOUND]) {
      outputStatusValues += 'N'
    }
    if (prefs[Preferences.SEARCH_STD_OUTPUT_STATUS_ACTIVE]) {
      outputStatusValues += 'A'
    }
    if (prefs[Preferences.SEARCH_STD_OUTPUT_STATUS_RETRY]) {
      outputStatusValues += 'R'
    }
    if (prefs[Preferences.SEARCH_STD_OUTPUT_STATUS_CANCELLED]) {
      outputStatusValues += 'C'
    }
    if (prefs[Preferences.SEARCH_STD_OUTPUT_STATUS_REQUEUED]) {
      outputStatusValues += 'Q'
    }
    const queryParams = {
      FROMLAST: customLast,
      TUNITS: customUnit,
      SDATE: sdate,
      STIME: stime,
      EDATE: edate,
      ETIME: etime,
      DOCID: listTimestamp,
      PROCESS: prefs[Preferences.SEARCH_STD_SELECT_BY_TYPE],
      FORM: prefs[Preferences.SEARCH_STD_FORM],
      EXTENSION: prefs[Preferences.SEARCH_STD_EXTENSION],
      REPORT: prefs[Preferences.SEARCH_STD_REPORT],
      RECI: prefs[Preferences.SEARCH_STD_RECIPIENT],
      FOLDER: prefs[Preferences.SEARCH_STD_FOLDER],
      JOBNAME: prefs[Preferences.SEARCH_STD_JOBNAME],
      TITLE: prefs[Preferences.SEARCH_STD_TITLE],
      FTITLE: prefs[Preferences.SEARCH_STD_TITLEFROMCOLUMN],
      LGRSTAT: prefs[Preferences.SEARCH_STD_CONTROL_STATUS],
      CUSER: prefs[Preferences.SEARCH_STD_CONTROL_USER],
      ONLINE: prefs[Preferences.SEARCH_STD_ONLINE],
      ARCHIVE: prefs[Preferences.SEARCH_STD_ARCHIVED],
      INDEXED: prefs[Preferences.SEARCH_STD_SELECT_BY_INDEX],
      DELETE: prefs[Preferences.SEARCH_STD_MARKED_FOR_DELETE],
      RELOAD: prefs[Preferences.SEARCH_STD_MARKED_FOR_RELOAD],
      LGRNOTE: prefs[Preferences.SEARCH_STD_SELECT_BY_NOTES],
      DOCTYPE: prefs[Preferences.SEARCH_STD_DOCTYPE],
      SRCSUBU: prefs[Preferences.SEARCH_STD_USER],
      OWNER: prefs[Preferences.SEARCH_STD_OWNER],
      DOCUSR1: prefs[Preferences.SEARCH_STD_DOCUSER1],
      DOCUSR2: prefs[Preferences.SEARCH_STD_DOCUSER2],
      DOCUSR3: prefs[Preferences.SEARCH_STD_DOCUSER3],
      DOCUSR4: prefs[Preferences.SEARCH_STD_DOCUSER4],
      DOCUSR5: prefs[Preferences.SEARCH_STD_DOCUSER5],
      DOCUSR6: prefs[Preferences.SEARCH_STD_DOCUSER6],
      DOCUSR7: prefs[Preferences.SEARCH_STD_DOCUSER7],
      DOCUSR8: prefs[Preferences.SEARCH_STD_DOCUSER8],
      EDITSTAT: prefs[Preferences.SEARCH_STD_EDITING_ICON],
      SRCOBT: prefs[Preferences.SEARCH_STD_OBTAINED],
      DCR: prefs[Preferences.SEARCH_STD_OUTPUTCHANNEL],
      BREQUEST: prefs[Preferences.SEARCH_STD_REQUESTOR],
      LGRAUTO: prefs[Preferences.SEARCH_STD_AUTOPRINT],
      PRTSTAT: outputStatusValues
    }
    getDocuments({ searchParams: queryParams, keepPagination: true })(dispatch)
  }
}

export function getUC4Jobs({ fields, searchObj, callback, keepPagination = false }) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_SELECTION_GET_UC4_JOBS_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/jobs?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_NO_UC4_JOBS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_GET_UC4_JOBS_FAILED })
          }
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_UC4_JOBS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.uc4_loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_UC4_JOBS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getUC4JobDetails(fields, jobObj, jobname, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_SELECTION_GET_UC4_JOB_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(jobObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/job?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CUSTOM_SELECTION_GET_UC4_JOB_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_UC4_JOB_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.uc4_details_error', lang, [jobname, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_UC4_JOB_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getZosJobs(fields, searchObj, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOBS_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/jobs?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_NO_ZOS_JOBS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOBS_FAILED })
          }
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOBS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.zos_loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOBS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getZosJobDetails(fields, jobObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOB_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(jobObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/job?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOB_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOB_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.zos_details_error', lang, [jobObj.JOBID, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOB_SUCCESS })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getZosJobLogs(jobObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_START })

    const queryParams = []
    for (const [key, value] of Object.entries(jobObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/joblogs?${queryParams.join('&')}`, { method: 'get' })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_NO_ZOS_JOB_LOGS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_FAILED })
          }
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_SUCCESS, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.zos_logs_loading_error', lang, [jobObj.JOBID, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_ZOS_JOB_LOGS_SUCCESS })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets print info of a document.
 * @param {Array} docids
 * @param {Function} callback
 */
export function getPrintInfo(docids, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    LoadingSpinnerActions.show()(dispatch)

    dispatch({ type: CUSTOM_SELECTION_GET_PRINT_INFO_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/documents/printinfo?DOCIDS=${docids}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    return cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          if (jsondata.additionalInfo) {
            const rsp = ResponseUtils.processResponse(jsondata.additionalInfo, undefined, dispatch)
            SnackbarActions.showMultiple(rsp.snackbarContent)(dispatch)
          }
          dispatch({ type: CUSTOM_SELECTION_GET_PRINT_INFO_FAILED })
          LoadingSpinnerActions.hide()(dispatch)
          return jsondata.additionalInfo
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_PRINT_INFO_SUCCESS, payload: jsondata.additionalInfo })

          if (callback) {
            callback()
          }
          LoadingSpinnerActions.hide()(dispatch)
          return jsondata.additionalInfo
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('documents.print_info_error', lang, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_PRINT_INFO_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getControlMJobs(fields, searchObj, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_SELECTION_GET_CONTROLM_JOBS_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/jobs?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_NO_CONTROLM_JOBS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_GET_CONTROLM_JOBS_FAILED })
          }
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_CONTROLM_JOBS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.zos_loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_CONTROLM_JOBS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getSyslogJobs(fields, searchObj, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: CUSTOM_SELECTION_GET_SYSLOG_JOBS_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/jobs?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_NO_SYSLOG_JOBS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: CUSTOM_SELECTION_GET_SYSLOG_JOBS_FAILED })
          }
        }
        else {
          dispatch({ type: CUSTOM_SELECTION_GET_SYSLOG_JOBS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.zos_loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CUSTOM_SELECTION_GET_SYSLOG_JOBS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getStonebranchJobs(fields, searchObj, callback = undefined, keepPagination = false) {
  return dispatch => {
    const reduxActions = {
      START: CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_START,
      SUCCESS: CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_SUCCESS,
      FAILED: CUSTOM_SELECTION_GET_STONEBRANCH_JOBS_FAILED,
      NOT_FOUND: CUSTOM_SELECTION_NO_STONEBRANCH_JOBS_FOUND,
      error: 'search.stonebranch_loading_error'
    }
    return SearchActions.getJobs(fields, searchObj, callback, keepPagination, reduxActions)(dispatch)
  }
}

export const resetCustomSelectionData = () => {
  return dispatch => {
    dispatch({ type: CUSTOM_SELECTION_RESET })
  }
}