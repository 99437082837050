import store from 'redux/Store'

export const BRW = 'BRW'
export const IDX = 'IDX'
export const IMP = 'IMP'
export const PRT = 'PRT'
export const BST = 'BST'
export const MSG = 'MSG'
export const PDI = 'PDI'

export const userprofilePanelRoutes = [
  { key: BRW, route: '/search/standardselection' },
  { key: IDX, route: '/search/customsearch' },
  { key: IMP, route: '/import/standardimport' },
  { key: PRT, route: '/queue/output' },
  { key: BST, route: '/queue/bundle' },
  { key: MSG, route: '/server/messagelog' },
  { key: PDI, route: '/server/printerdeviceinfo' }
]


const IS_ADMIN = 'YES'
const IS_GROUP_ADMIN = 'GRP'
const NOT_ADMIN = 'NO'

export const PRIVILEGES_ITEMS = [
  { key: IS_ADMIN, translationKey: 'general.admin' },
  { key: IS_GROUP_ADMIN, translationKey: 'general.groupadmin' },
  { key: NOT_ADMIN, translationKey: 'user.user' }
]

export const PRIVILEGES_ITEMS_SEARCH = [
  { key: '', translationKey: 'general.all' },
  ...PRIVILEGES_ITEMS
]

/**
 * @description Checks if the current logged in user has admin rights
 * @return True = If logged in is admin | False if user is not logged in, is subadmin (BRWSONLY) or has no userprofile
 */
export const isAdmin = () => {
  const loggedIn = store.getState().auth.loggedIn || false
  const serverdata = store.getState().auth.serverdata
  const userprofile = serverdata && (serverdata.userprofile ? serverdata.userprofile : undefined)
  return loggedIn && (serverdata && (!userprofile || (userprofile && !userprofile.BRWSONLY)))
}

export const isDOCX = () => store.getState().auth.serverdata?.server && store.getState().auth.serverdata.server.type === 'B93'
export const isLOGX = () => store.getState().auth.serverdata?.server && store.getState().auth.serverdata.server.type === 'B92'