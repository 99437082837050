import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as SortUtils from 'utils/SortUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CopyLpdQueueDialog from 'components/dialogs/copy_lpd_queue_dialog/CopyLpdQueueDialog'
import CreateLpdQueueDialog from 'components/dialogs/create_lpd_queue_dialog/CreateLpdQueueDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyLpdQueueDialog from 'components/dialogs/modify_lpd_queue_dialog/ModifyLpdQueueDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as LPDQueueActions from 'redux/actions/LpdQueueDefinitionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultLPDQueue extends Component {
  state = {
    tablekey: null,
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation(),
    createDialogData: undefined
  }

  fillHeaderInformation() {
    return [
      { rest: LPDQUEUE_ID, translation: 'definition.lpd_queue.id', default: true },
      { rest: LPDQUEUE_SPOOLING, translation: 'definition.lpd_queue.spooling', default: true },
      { rest: LPDQUEUE_TRANSFER, translation: 'definition.lpd_queue.transfer', default: true },
      { rest: LPDQUEUE_CHECK, translation: 'definition.lpd_queue.check', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: OWNER, translation: 'general.owner' },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER }
    ]
  }

  /**
   * @description Shows the create lpd queue dialog.
   */
  handleOnAddEntry = () => {
    this.setState({ showCreateDialog: true })
  }

  /**
   * @description Refreshs the current table.
   */
  handleOnRefresh = () => {
    const { preferences } = this.props
    const name = preferences[Preferences.DEFINITION_LPD_QUEUES_NAME]
    const description = preferences[Preferences.DEFINITION_LPD_QUEUES_DESCRIPTION]
    const owner = preferences[Preferences.DEFINITION_LPD_QUEUES_OWNER]

    this.props.getLpdQueues(name, description, owner)
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)


  /**
   * @description Shows the modify lpd queue dialog.
   * @param index The index of the lpd queue which should be modified.
   */
  handleOnModifyLpdQueue = index => {
    // get the lpd name from redux
    const name = this.props.lpdQueues.data[index][0]

    // declare success callback
    const callback = () => {
      this.setState({ showModifyDialog: true })
    }

    // get the specific lpd queue
    this.props.getLpdQueue(name, callback)
  }

  /**
   * @description Shows the delete lpd queue dialog.
   * @param index The index of the lpd queue which should be deleted.
   */
  handleOnDeleteLpdQueue = index => {
    // get the lpd name from redux
    const name = this.props.lpdQueues.data[index][0]

    // declare success callback
    const callback = () => {
      this.setState({ showDeleteDialog: true })
    }

    // get the specific lpdqueue
    this.props.getLpdQueue(name, callback)
  }

  /**
   * @description Shows the copy lpd queue dialog.
   * @param index The index of the lpd queue which should be copied.
   */
  handleOnCopyLpdQueue = index => {
    // get the lpd name from redux
    const name = this.props.lpdQueues.data[index][0]

    // declare success callback
    const callback = () => {
      this.setState({ showCopyDialog: true })
    }

    // get the specific lpdqueue
    this.props.getLpdQueue(name, callback)
  }

  /**
   * @description Deletes the selected lpd queue.
   */
  deleteLpdQueue = () => {
    const name = this.props.lpdQueue.LPD

    // declare success callback
    const callback = () => { this.setState({ showDeleteDialog: false }) }

    this.props.deleteLpdQueue(name, callback)
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id, lang, lpdQueues } = this.props
    // const [ , spooling, transfer] = lpdQueues.data[rowIndex]
    // const disabled = spooling !== 'NO' && transfer !== 'NO'
    // const tooltip = disabled
    // ? shouldn't this be part of redux

    // check if lpdQueues.data[rowIndex] is not undefined is necessary, because redux updates the data faster than datatable, so datatable useses this function with old index and we get an error, when less rows are in the new data (no proof, just a theory)
    const spooling = lpdQueues.data[rowIndex] && lpdQueues.data[rowIndex][lpdQueues.header.findIndex(entry => entry === 'QSSPOOL')] === 'YES'
    const transfer = lpdQueues.data[rowIndex] && lpdQueues.data[rowIndex][lpdQueues.header.findIndex(entry => entry === 'QSPRINT')] === 'YES'
    const disableDelete = spooling || transfer
    const deleteTooltip = disableDelete
      ? translate('definition.lpd_queue.delete_tooltip', lang)
      : translate('general.delete', lang)

    requestAnimationFrame(() => {
      var allTableButtons = Array.from(document.querySelectorAll('.button'))
      allTableButtons.forEach(button => {
        button.classList.remove('bux_disabled_actionbutton')
        let iconContainer = button.querySelector('div')
        if (iconContainer.classList.contains('disabled')) {
          button.classList.add('bux_disabled_actionbutton')
        }
      })
    })

    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit', lang)}
        onClick={() => { this.handleOnModifyLpdQueue(rowIndex) }}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={deleteTooltip}
        onClick={() => { this.handleOnDeleteLpdQueue(rowIndex) }}
        disabled={disableDelete}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options', lang)}>
        <TableButtonGroupItem
          onClick={() => { this.handleOnModifyLpdQueue(rowIndex) }}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit', lang)}
          title={translate('general.edit', lang)}
        />
        <TableButtonGroupItem
          onClick={() => { this.handleOnCopyLpdQueue(rowIndex) }}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy', lang)}
          title={translate('general.copy', lang)}
        />
        <TableButtonGroupItem
          onClick={() => { this.handleOnDeleteLpdQueue(rowIndex) }}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete', lang)}
          title={deleteTooltip}
          disabled={disableDelete}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create', lang)}
          onClick={() => this.showPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleOnRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings', lang)}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description gets the index of the header in redux state definitions.lpdQueues.header
   * @param {String} header header name of the header in redux state definitions.lpdQueues.header
   */
  headerData = header => this.props.lpdQueues.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, lpdQueues } = this.props
    const data = []
    let headers = this.getUsedHeader()
    lpdQueues.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === LPDQUEUE_SPOOLING) {
          dataBuffer.push(element[this.headerData('QSSPOOL')] === 'YES' ? translate('general.yes') : translate('general.no'))
        }
        else if (header === LPDQUEUE_TRANSFER) {
          dataBuffer.push(element[this.headerData('QSPRINT')] === 'YES' ? translate('general.yes') : translate('general.no'))
        }
        else if (header === LPDQUEUE_CHECK) {
          dataBuffer.push(element[this.headerData('QSTATUS')] === 'YES' ? translate('general.yes') : translate('general.no'))
        }
        else if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_LPDQUEUE]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_LPDQUEUE].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_LPDQUEUE]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_LPDQUEUE].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  showPrefilledCreateDialog = () => {
    const { preferences } = this.props
    this.setState({
      showCreateDialog: true,
      createDialogData: {
        LPD: preferences[Preferences.DEFINITION_LPD_QUEUES_NAME],
        OWNER: preferences[Preferences.DEFINITION_LPD_QUEUES_OWNER],
        LPDINFO: preferences[Preferences.DEFINITION_LPD_QUEUES_DESCRIPTION]
      }
    })
  }

  render = () => {
    const { id, lang, lpdQueues, lpdQueue, drawerExpanded, autoDrawer, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showModifyDialog, showCopyDialog, showDeleteDialog } = this.state
    let data = lpdQueues && lpdQueues.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {/* Create lpd queue dialog */}
        {showCreateDialog && (
          <CreateLpdQueueDialog
            id={`${id}_createlpdqueuedialog`}
            prefilledData={this.state.createDialogData}
            onClose={() => { this.setState({ showCreateDialog: false, createDialogData: undefined }) }}
          />
        )}

        {/* modify lpd queue dialog */}
        {showModifyDialog && (
          <ModifyLpdQueueDialog
            id={`${id}_modifylpdqueuedialog`}
            onClose={() => { this.setState({ showModifyDialog: false }) }}
          />
        )}

        {/* Copy lpd queue dialog */}
        {showCopyDialog && (
          <CopyLpdQueueDialog
            id={`${id}_copylpdqueuedialog`}
            onClose={() => { this.setState({ showCopyDialog: false }) }}
          />
        )}

        {/* delete lpd queue dialog */}
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_lpd_queue`}
            title={translate('definition.lpd_queue.del_lpd_queue', lang)}
            question={translate('definition.lpd_queue.question_del_lpd_queue', lang)}
            onClose={() => { this.setState({ showDeleteDialog: false }) }}
            onDelete={() => { this.deleteLpdQueue() }}>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_lpd_queue_id_key_text`}>
                  {translate('definition.lpd_queue.id', lang)}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_lpd_queue_id_value_text`}>
                  {lpdQueue.LPD}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_lpd_queue_title_key_text`}>
                  {`${translate('general.title', lang)}:`}
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_lpd_queue_title_value_text`}>
                  {lpdQueue.LPDINFO}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_LPDQUEUE }}
          />
        )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if lpdQueues not exist in redux
            lpdQueues
              ? (
                // show empty result if there are no lpdQueues after searching
                data
                  ? (
                    <DataTable
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={data}
                      selectable={true}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={this.handleOnModifyLpdQueue}
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptyResult
                      id={`${id}_empty_search_result`}
                      description={translate('emptyresult.no_lpd_queue_result')}
                      link={translate('emptyresult.create_lpd_queue_link')}
                      onClick={() => this.showPrefilledCreateDialog()}
                      headline={translate('emptyresult.no_result_headline')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_no_search`}
                  message={translate('nosearch.description')}
                />
              )
          }
        </ResultContainer>
      </>
    )
  }
}

const LPDQUEUE_ID = 'LPD'
const LPDQUEUE_SPOOLING = 'QSSPOOL'
const LPDQUEUE_TRANSFER = 'QSPRINT'
const LPDQUEUE_CHECK = 'QSTATUS'
const TITLE = 'LPDINFO'
const OWNER = 'OWNER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

SearchResultLPDQueue.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOf(['initialized', PropTypes.bool]),
}

const mapStateToProps = ({ definitions, auth }) => {
  return {
    lpdQueues: definitions.lpdQueues.lpdQueues,
    lpdQueue: definitions.lpdQueues.lpdQueue,
    keepPagination: definitions.lpdQueues.keepPagination,
    usertoken: auth.serverdata.token,
    user: auth.userid,
    lang: auth.serverdata.preferences.LANGUAGE,
    datemask: auth.serverdata.preferences.DATEMASK,
    preferences: auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getLpdQueues: (name, description, owner) => {
      LPDQueueActions.getLpdQueues(name, description, owner)(dispatch)
    },
    deleteLpdQueue: (name, callback) => {
      LPDQueueActions.deleteLpdQueue(name, callback)(dispatch)
    },
    getLpdQueue: (name, callback) => {
      LPDQueueActions.getLpdQueue(name, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultLPDQueue)