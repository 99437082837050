import { LOGOUT_SUCCESS } from '../actions/AuthenticationActions'
import {
  GET_RELOAD_QUEUES_START,
  GET_RELOAD_QUEUES_SUCCESS,
  GET_RELOAD_QUEUES_FAILED,
  NO_RELOAD_QUEUES_FOUND,
  GET_RELOAD_QUEUE_DETAILS_START,
  GET_RELOAD_QUEUE_DETAILS_SUCCESS,
  GET_RELOAD_QUEUE_DETAILS_FAILED,
  DELETE_RELOAD_QUEUE_START,
  DELETE_RELOAD_QUEUE_SUCCESS,
  DELETE_RELOAD_QUEUE_FAILED
} from '../actions/ReloadQueueActions'

const defaultState = {
  reloadDetails: undefined,
  reloads: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Get reload queues actions
    case GET_RELOAD_QUEUES_START:
      return { ...state }
    case GET_RELOAD_QUEUES_SUCCESS:
      return { ...state, reloads: action.payload }
    case GET_RELOAD_QUEUES_FAILED:
      return { ...state }
    case NO_RELOAD_QUEUES_FOUND:
      return { ...state, reloads: {} }
    /* ********************************************************************************** */
    // Get reload queue details actions
    case GET_RELOAD_QUEUE_DETAILS_START:
      return { ...state, reloadDetails: undefined }
    case GET_RELOAD_QUEUE_DETAILS_SUCCESS:
      return { ...state, reloadDetails: action.payload, keepPagination: action.keepPagination }
    case GET_RELOAD_QUEUE_DETAILS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // delete reload queue actions
    case DELETE_RELOAD_QUEUE_START:
      return { ...state }
    case DELETE_RELOAD_QUEUE_SUCCESS:
      return { ...state }
    case DELETE_RELOAD_QUEUE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case LOGOUT_SUCCESS:
      return { ...state, reloads: undefined, reloadDetails: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer