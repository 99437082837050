import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import {
  Button, Card, Column,
  Input,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

import { LetterCase } from 'BetaUX2Web-Components/src/types'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as GroupActions from 'redux/actions/GroupActions'
import * as Preferences from 'redux/general/Preferences'

class CreateGroupDialog extends Component {
  state = {
    groupid: {
      value: '',
      errorkey: ''
    },
    groupname: {
      value: '',
      errorkey: ''
    }
  }

  groupidInput = React.createRef()

  /**
   * @description Sets the initial focus.
   */
  componentDidMount() {
    // focus group id input initially
    this.groupidInput.current.focus()
  }

  /**
   * @description Handles the group id changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleOnGroupIDChanged(value, error) {
    this.setState({
      groupid: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Handles the groupname changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleOnGroupnameChanged(value, error) {
    this.setState({
      groupname: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Validates the groupid and adds an error under the textfield when validation failed.
   * @param {Boolean} allowFocus Flag to focus the textfield if validation failed.
   */
  validateGroupID(allowFocus) {
    if (this.state.groupid.value !== '') {
      return true
    } else {
      this.setState({ groupid: { ...this.state.groupid, errorkey: 'usermanagement.please_enter_a_groupid' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.groupidInput.current.focus()
      }

      return false
    }
  }

  /**
   * @description Closes the dialog.
   */
  handleOnClose = () => {
    this.props.onClose()
  }

  /**
   * @description Calls the rest api and creates the group.
   */
  handleOnSave = () => {
    // check data before sending login request
    if (this.validateGroupID(true)) {

      // call create request
      const cuser = this.props.userid
      const groupid = this.state.groupid.value
      const groupname = this.state.groupname.value

      // declare success callback
      const callback = () => { this.props.onClose() }

      this.props.createGroup(cuser, groupid, groupname, callback)
    }
  }

  render = () => {
    const { id, lang } = this.props
    return (
      <Modal onClose={this.handleOnClose}
        id={id}
        size={'s'}>
        <Header
          id={id}
          title={translate('usermanagement.create_group', lang)}
          onClose={this.handleOnClose}
        />
        <Main id={id}>
          <Card fullHeight>
            <Row>
              <Column
                colMD={12}
                offsetMD={0}>
                <Input
                  id={`${id}_body_card_row_col_group_groupid`}
                  ref={this.groupidInput}
                  onInputChanged={(val, err) => { this.handleOnGroupIDChanged(val, err) }}
                  value={this.state.groupid.value}
                  title={translate('group.group_id', lang)}
                  error={this.state.groupid.errorkey ? translate(this.state.groupid.errorkey, lang) : null}
                  maxLength={8}
                  required={`${translate('general.required_field')}`}
                  lettercase={LetterCase.uppercase}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}>
                <Input
                  id={`${id}_body_card_row_col_group_groupname`}
                  onInputChanged={(val, err) => { this.handleOnGroupnameChanged(val, err) }}
                  value={this.state.groupname.value}
                  title={translate('group.groupname', lang)}
                  error={this.state.groupname.errorkey ? translate(this.state.groupname.errorkey, lang) : null}
                  maxLength={32}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={id}>
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel', lang)}
            onClick={this.handleOnClose}
          />
          <Button
            id={`${id}_footer_save`}
            text={translate('general.save', lang)}
            onClick={this.handleOnSave}
            primary
            submit />
        </Footer>
      </Modal>
    )
  }
}

CreateGroupDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createGroup: (
      cuser,
      groupid,
      groupname,
      callback
    ) => {
      GroupActions.createGroup(
        cuser,
        groupid,
        groupname,
        callback
      )(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupDialog)