import * as LoadingSpinnerActions from './../actions/LoadingSpinnerActions'

const defaultState = {
  showLoader: false,
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Loading spinner actions
    case LoadingSpinnerActions.LOADING_SPINNER_SHOW:
      return { ...state, showLoader: true }
    case LoadingSpinnerActions.LOADING_SPINNER_HIDE:
      return { ...state, showLoader: false }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer