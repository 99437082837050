import React, { Component } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { connect } from 'react-redux'


/* Fetch-Polyfill for IE
  https://stackoverflow.com/questions/36484156/es6-fetch-is-undefined
*/
import 'isomorphic-fetch'

import './utils/Polyfill'

import './BetaUX2Web-Components/src/ressource/beta_icons/style.css'

import 'css/stylesheet.scss'
import './BetaUX2Web-Components/src/css/stylesheet.scss'

import Snackbar from 'BetaUX2Web-Components/src/components/snackbar/Snackbar'
import ErrorBoundary from 'errorhandler/ErrorBoundary'
import * as PreferencesActions from 'redux/actions/PreferencesActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'

// Screens
import LoginScreen from 'screens/login_screen/LoginScreen'
import MainScreen from 'screens/main_screen/MainScreen'
import Test from 'screens/test/Test' // TODO: Replace me with „Route not found” component
import PrivateRoute from 'components/private_route/PrivateRoute'
import { DocumentViewer } from 'components/documentViewer/DocumentViewer'
import * as DOC_VIEWER_ROUTES from 'components/documentViewer/constants/routes'

// Utils
import * as UserUtils from 'utils/UserUtils'
import {PrivateRouteTimeoutGuard} from './components/private_route/PrivateRouteTimeoutGuard'

class App extends Component {

  componentDidMount = () => {
    this.props.init()
  }

  /**
   * @description Decides which routes are returned.
   */
  getAvailableRoutes = () => {
    if (UserUtils.isDOCX()) {
      return this.getAvailableRoutesDocx()
    }
    else if (UserUtils.isLOGX()) {
      return this.getAvailableRoutesLogx()
    }
  }

  /**
   * @description Get all available routes for LOGX.
   */
  getAvailableRoutesLogx = () => {
    // || {} is required to get undefined for the destructed variables when userprofile is undefined
    const { VCIENMSG, VCIENPRQ, VCIENUTL, VCIENIMP, VCIENPRM } = this.props.userprofile || {}
    // This function only filters the top-level of the routes. The specific sub-route is filtered in the rendered Component based on the :<url-variable>

    const allRoutes = [
      // Document Viewer
      { available: true, route: <PrivateRoute exact path={DOC_VIEWER_ROUTES.DOCUMENT_VIEWER_URL_BASE} component={DocumentViewer} key={'documentviewer'} /> },
      // Search routes
      { available: true, route: <PrivateRoute exact path='/search' component={MainScreen} key={'search'} /> },
      { available: true, route: <PrivateRoute exact path='/search/standardselection' component={MainScreen} key={'search:standardselection'} /> },
      { available: true, route: <PrivateRoute exact path='/search/zos' component={MainScreen} key={'search:zos'} /> },
      { available: true, route: <PrivateRoute exact path='/search/uc4' component={MainScreen} key={'search:uc4'} /> },
      { available: true, route: <PrivateRoute exact path='/search/controlm' component={MainScreen} key={'search:controlm'} /> },
      { available: true, route: <PrivateRoute exact path='/search/customsearch' component={MainScreen} key={'search:customsearch'} /> },
      { available: true, route: <PrivateRoute exact path='/search/stonebranch' component={MainScreen} key={'search:stonebranch'} /> },
      { available: true, route: <PrivateRoute exact path='/search/syslog' component={MainScreen} key={'search:syslog'} /> },

      // Import
      { available: UserUtils.isAdmin() || VCIENIMP, route: <PrivateRoute exact path='/import' component={MainScreen} key={'import:importmode'} /> },
      { available: UserUtils.isAdmin() || VCIENIMP, route: <PrivateRoute exact path='/import/standardimport' component={MainScreen} key={'import:standardimport'} /> },

      // Queue routes
      { available: UserUtils.isAdmin() || VCIENPRQ, route: <PrivateRoute exact path='/queue' component={MainScreen} key={'queue'} /> },
      { available: UserUtils.isAdmin() || VCIENPRQ, route: <PrivateRoute exact path='/queue/output' component={MainScreen} key={'queue:output'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/queue/lpd' component={MainScreen} key={'queue:lpd'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/queue/reload' component={MainScreen} key={'queue:reload'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/queue/index' component={MainScreen} key={'queue:index'} /> },

      // Definition routes
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition' component={MainScreen} key={'definition'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/recipient' component={MainScreen} key={'definition:recipient'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/jobgroups' component={MainScreen} key={'definition:jobgroups'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/lpdqueue' component={MainScreen} key={'definition:lpdqueue'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/log' component={MainScreen} key={'definition:log'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/filterargument' component={MainScreen} key={'definition:filterargument'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/index' component={MainScreen} key={'definition:index'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/outputchannel' component={MainScreen} key={'definition:outputchannel'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/postprocessingnote' component={MainScreen} key={'definition:postprocessingnote'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/outputformat' component={MainScreen} key={'definition:outputformat'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/externalcmd' component={MainScreen} key={'definition:externalcmd'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/archivepool' component={MainScreen} key={'definition:archivepool'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/resulttable' component={MainScreen} key={'definition:resulttable'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/customdialog' component={MainScreen} key={'definition:customdialog'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/viewprofile' component={MainScreen} key={'definition:viewprofile'} /> },

      // Assignment routes
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/assignment' component={MainScreen} key={'assignment'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/assignment/recipient_log' component={MainScreen} key={'assignment:recipient_log'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/assignment/index_log' component={MainScreen} key={'assignment:index_log'} /> },

      // Statistic routes
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/statistic' component={MainScreen} key={'statistic'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/statistic/logx' component={MainScreen} key={'statistic:logx'} /> },

      // Usermanagement routes
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/usermanagement' component={MainScreen} key={'usermanagement'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/usermanagement/users' component={MainScreen} key={'usermanagement:users'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/usermanagement/groups' component={MainScreen} key={'usermanagement:groups'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/usermanagement/remoteusers' component={MainScreen} key={'usermanagement:remoteusers'} /> },

      // Security routes
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/security' component={MainScreen} key={'security'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/security/entity' component={MainScreen} key={'entities'} /> },

      // Server routes
      { available: UserUtils.isAdmin() || VCIENMSG || VCIENUTL || VCIENPRM, route: <PrivateRoute exact path='/server' component={MainScreen} key={'server'} /> },
      { available: UserUtils.isAdmin() || VCIENMSG, route: <PrivateRoute exact path='/server/messagelog' component={MainScreen} key={'server:messagelog'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/serverlogfile' component={MainScreen} key={'server:serverlogfile'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/systemdefaults' component={MainScreen} key={'server:systemdefaults'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/maintenance' component={MainScreen} key={'server:maintenance'} /> },
      { available: UserUtils.isAdmin() || VCIENUTL, route: <PrivateRoute exact path='/server/externalcmd' component={MainScreen} key={'server:externalcmd'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/accounting' component={MainScreen} key={'server:accounting'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/archivefile' component={MainScreen} key={'server:archivefile'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/lpdstatus' component={MainScreen} key={'server:lpdstatus'} /> },
      { available: UserUtils.isAdmin() || VCIENPRM, route: <PrivateRoute exact path='/server/outputprocesses' component={MainScreen} key={'server:outputprocesses'} /> },

      // Database routes
      { available: UserUtils.isAdmin() || VCIENMSG, route: <PrivateRoute exact path='/database' component={MainScreen} key={'database'} /> },
      { available: UserUtils.isAdmin() || VCIENMSG, route: <PrivateRoute exact path='/database/status' component={MainScreen} key={'database:status'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/database/table' component={MainScreen} key={'database:table'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/database/query' component={MainScreen} key={'database:query'} /> },

      // Test-Screen
      { available: true, route: <PrivateRoute exact path='/test' component={Test} key={'test'} /> }, // Test-Route
    ]

    // Available routes to return based on login user
    const availableRoutes = []
    allRoutes.forEach(routeObj => {
      // Add PrivateRoute-JSX-Object
      if (routeObj.available) { availableRoutes.push(routeObj.route) }
    })
    return availableRoutes
  }

  /**
   * @description Get all available routes for DOCX.
   */
  getAvailableRoutesDocx = () => {
    // || {} is required to get undefined for the destructed variables when userprofile is undefined
    const { VCIENIMP, VCIENMSG, VCIENPRQ, VCIENUTL, VCIENPRM } = this.props.userprofile || {}
    // This function only filters the top-level of the routes. The specific sub-route is filtered in the rendered Component based on the :<url-variable>

    const allRoutes = [
      // Document Viewer
      { available: true, route: <PrivateRoute exact path={DOC_VIEWER_ROUTES.DOCUMENT_VIEWER_URL_BASE} component={DocumentViewer} key={'documentviewer'} /> },
      // Search routes
      { available: true, route: <PrivateRoute exact path='/search' component={MainScreen} key={'search'} /> },
      { available: true, route: <PrivateRoute exact path='/search/standardselection' component={MainScreen} key={'search:standardselection'} /> },
      { available: true, route: <PrivateRoute exact path='/search/customsearch' component={MainScreen} key={'search:customsearch'} /> },

      // Import routes
      { available: true, route: <PrivateRoute exact path='/import' component={MainScreen} key={'import:importmode'} /> },
      { available: UserUtils.isAdmin() || VCIENIMP, route: <PrivateRoute exact path='/import/standardimport' component={MainScreen} key={'import:standardimport'} /> },
      { available: true, route: <PrivateRoute exact path='/import/customimport' component={MainScreen} key={'import:customimport'} /> },

      // Queue routes
      { available: UserUtils.isAdmin() || VCIENPRQ, route: <PrivateRoute exact path='/queue' component={MainScreen} key={'queue'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/queue/lpd' component={MainScreen} key={'queue:lpd'} /> },
      { available: UserUtils.isAdmin() || VCIENPRQ, route: <PrivateRoute exact path='/queue/output' component={MainScreen} key={'queue:output'} /> },
      { available: UserUtils.isAdmin() || VCIENPRQ, route: <PrivateRoute exact path='/queue/bundle' component={MainScreen} key={'queue:bundle'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/queue/reload' component={MainScreen} key={'queue:reload'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/queue/index' component={MainScreen} key={'queue:index'} /> },

      // Definition routes
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition' component={MainScreen} key={'definition'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/recipient' component={MainScreen} key={'definition:recipient'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/externalcmd' component={MainScreen} key={'definition:externalcmd'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/lpdqueue' component={MainScreen} key={'definition:lpdqueue'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/folder' component={MainScreen} key={'definition:folder'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/index' component={MainScreen} key={'definition:index'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/document' component={MainScreen} key={'definition:document'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/documentnode' component={MainScreen} key={'definition:documentnode'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/outputchannel' component={MainScreen} key={'definition:outputchannel'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/postprocessingnote' component={MainScreen} key={'definition:postprocessingnote'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/outputformat' component={MainScreen} key={'definition:outputformat'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/searchargument' component={MainScreen} key={'definition:searchargument'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/filterargument' component={MainScreen} key={'definition:filterargument'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/archivepool' component={MainScreen} key={'definition:archivepool'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/resulttable' component={MainScreen} key={'definition:resulttable'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/customdialog' component={MainScreen} key={'definition:customdialog'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/definition/viewprofile' component={MainScreen} key={'definition:viewprofile'} /> },

      // Assignment routes
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/assignment' component={MainScreen} key={'assignment'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/assignment/folder_document' component={MainScreen} key={'assignment:folder_document'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/assignment/index_document' component={MainScreen} key={'assignment:index_document'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/assignment/recipient_document' component={MainScreen} key={'assignment:recipient_document'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/assignment/node_document' component={MainScreen} key={'assignment:node_document'} /> },

      // Statistic routes
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/statistic' component={MainScreen} key={'statistic'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/statistic/pagesdocs' component={MainScreen} key={'statistic:pagesdocs'} /> },

      // Usermanagement routes
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/usermanagement' component={MainScreen} key={'usermanagement'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/usermanagement/users' component={MainScreen} key={'usermanagement:users'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/usermanagement/groups' component={MainScreen} key={'usermanagement:groups'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/usermanagement/remoteusers' component={MainScreen} key={'usermanagement:remoteusers'} /> },

      // Security routes
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/security' component={MainScreen} key={'security'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/security/entity' component={MainScreen} key={'entitites'} /> },

      // Server routes
      { available: UserUtils.isAdmin() || VCIENMSG || VCIENUTL || VCIENPRM, route: <PrivateRoute exact path='/server' component={MainScreen} key={'server'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/maintenance' component={MainScreen} key={'server:maintenance'} /> },
      { available: UserUtils.isAdmin() || VCIENUTL, route: <PrivateRoute exact path='/server/externalcmd' component={MainScreen} key={'server:externalcmd'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/accounting' component={MainScreen} key={'server:accounting'} /> },
      { available: UserUtils.isAdmin() || VCIENMSG, route: <PrivateRoute exact path='/server/messagelog' component={MainScreen} key={'server:messagelog'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/serverlogfile' component={MainScreen} key={'server:serverlogfile'} /> },
      { available: UserUtils.isAdmin() || VCIENMSG, route: <PrivateRoute exact path='/server/printerdeviceinfo' component={MainScreen} key={'server:printerdeviceinfo'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/systemdefaults' component={MainScreen} key={'server:systemdefaults'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/archivefile' component={MainScreen} key={'server:archivefile'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/server/lpdstatus' component={MainScreen} key={'server:lpdstatus'} /> },
      { available: UserUtils.isAdmin() || VCIENPRM, route: <PrivateRoute exact path='/server/outputprocesses' component={MainScreen} key={'server:outputprocesses'} /> },

      // Database routes
      { available: UserUtils.isAdmin() || VCIENMSG, route: <PrivateRoute exact path='/database' component={MainScreen} key={'database'} /> },
      { available: UserUtils.isAdmin() || VCIENMSG, route: <PrivateRoute exact path='/database/status' component={MainScreen} key={'database:status'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/database/table' component={MainScreen} key={'database:table'} /> },
      { available: UserUtils.isAdmin(), route: <PrivateRoute exact path='/database/query' component={MainScreen} key={'database:query'} /> },

      // Test-Screen
      { available: true, route: <PrivateRoute exact path='/test' component={Test} key={'test'} /> } // Test-Route
    ]

    // Available routes to return based on login user
    const availableRoutes = []
    allRoutes.forEach(routeObj => {
      // Add PrivateRoute-JSX-Object
      if (routeObj.available) { availableRoutes.push(routeObj.route) }
    })
    return availableRoutes
  }


  render = () => {
    const { content, deleteMsgFromSnackbar } = this.props

    return (
      <ErrorBoundary>
        <Snackbar content={content} onClose={deleteMsgFromSnackbar} />
        <PrivateRouteTimeoutGuard/>
        <Router>
          <Switch>
            <PrivateRoute exact path='/' component={LoginScreen} key={'login'} />
            {this.getAvailableRoutes()}
            <PrivateRoute exact path='/definition' component={MainScreen} key={'definition'} />
            {/* <PrivateRoute component={Test} /> TODO: Replace me with „Route not found” component */}
            {/* Fallback when a unknown route is called */}
            <PrivateRoute component={MainScreen} key={'fallback'} />
          </Switch>
        </Router>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    content: state.snackbar,
    userprofile: state.auth?.serverdata?.userprofile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteMsgFromSnackbar: (objToRemove) => {
      SnackbarActions.deleteMessage(objToRemove)(dispatch)
    },
    init: () => {
      PreferencesActions.init()(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)