import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Components
import {
  Button, Card, Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/';

const { Modal, Main, Header, Footer } = ModalComponent

// Utils
import * as DateUtils from 'utils/DateUtils';

// Redux
import { connect } from 'react-redux';
import * as RemoteUserActions from 'redux/actions/RemoteUserActions';
import * as Preferences from 'redux/general/Preferences';
import * as IPUtils from 'utils/IPUtils';

class ModifyRemoteUserDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    buxUser: {
      value: this.props.remoteUserToModify.BETAUSER,
      errorkey: ''
    },
    ipMask: {
      value: this.props.remoteUserToModify.IPMASK,
      errorkey: ''
    },
    ip6mask: {
      value: this.props.remoteUserToModify.IP6MASK,
      errorkey: ''
    }
  }

  buxUserInput = React.createRef()
  ipMaskInput = React.createRef()
  ip6maskInput = React.createRef()

  /**
   * @description Sets the initial focus.
   */
  componentDidMount = () => {
    // focus user id input initially
    this.buxUserInput.current.focus()
  }

  /**
   * @description Handles the buxuser changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleOnBuxUserChanged = (value, error) => {
    this.setState({
      buxUser: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Handles the ipmask changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleOnIpMaskChanged = (value, error) => {
    this.setState({
      ipMask: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Handles the ip6mask changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleIp6mask = (value, error) => {
    this.setState({
      ip6mask: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Validates the buxuser. Adds an error under textfield if validation failed.
   * @param {Boolean} allowFocus Flag to focus the textfield if validation failed.
   */
  validateBuxUser = (allowFocus) => {
    if (this.state.buxUser.value !== '') {
      return true
    } else {
      this.setState({ buxUser: { ...this.state.buxUser, errorkey: 'usermanagement.please_enter_a_buxuser' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.buxUserInput.current.focus()
      }

      return false
    }
  }

  /**
   * @description Validates the ipmask. Adds an error under textfield if validation failed.
   * @param {Boolean} allowFocus Flag to focus the textfield if validation failed.
   */
  validateIpMask = (allowFocus) => {
    if (this.state.ipMask.value !== '') {
      if (!IPUtils.isIPv4Mask(this.state.ipMask.value)) {
        this.setState({ ipMask: { ...this.state.ipMask, errorkey: 'usermanagement.ipmask_not_valid' } })
        // set the focus if it's allowed
        if (allowFocus) {
          this.ipMaskInput.current.focus()
        }

        return false
      }
      return true
    } else {
      this.setState({ ipMask: { ...this.state.ipMask, errorkey: 'usermanagement.please_enter_a_ipmask' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.ipMaskInput.current.focus()
      }

      return false
    }
  }

  /**
   * @description Validates the ipv6mask. Adds an error under textfield if validation failed.
   * @param {Boolean} allowFocus Flag to focus the textfield if validation failed.
   */
  validateIP6Mask = (allowFocus) => {
    if (this.state.ip6mask.value === '') {
      return true
    } else if (!IPUtils.isIPv6Mask(this.state.ip6mask.value)) {
      this.setState({ ip6mask: { ...this.state.ip6mask, errorkey: 'usermanagement.ipmask_not_valid' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.ip6maskInput.current.focus()
      }

      return false
    } else {
      return true
    }
  }

  /**
   * @description Closes the dialog.
   */
  handleOnClose = () => {
    this.props.onClose()
  }

  /**
   * @description Calls the rest api and modifies the remote user.
   */
  handleOnSave = () => {
    // check data before sending request
    if (this.validateBuxUser(true)) {
      if (this.validateIpMask(true)) {
        if (this.validateIP6Mask(true)) {
          // call update request
          const cUser = this.props.userId
          const unixUser = this.props.remoteUserToModify.UNIXUSER
          const buxUser = this.state.buxUser.value
          const ipMask = this.state.ipMask.value
          const ip6mask = this.state.ip6mask.value

          // declare success callback
          const callback = () => this.props.onClose()

          this.props.updateRemoteUser(cUser, unixUser, buxUser, ipMask, ip6mask, callback)
        }
      } else {
        this.validateIP6Mask(false)
      }
    } else {
      // we also want to check and show the error message under the other fields in case of unixuser is invalid
      // don't allow focus here because unixuser field has an error -> false parameter
      // we don't have to interlace the validate function because we don't have to set the focus
      this.validateIpMask(false)
      this.validateIP6Mask(false)
    }
  }

  render = () => {
    const { id, remoteUserToModify, datemask } = this.props
    const { buxUser, ipMask, ip6mask } = this.state
    return (
      <Modal onClose={this.handleOnClose}
        id={`${id}_modifyRemoteUserModal`}
        className={'bux_modifyRemoteUserModal'}
        size={'s'}>
        <Header
          id={`${id}_modifyRemoteUserModal`}
          title={translate('usermanagement.modify_remote_user')}
          onClose={this.handleOnClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('remoteuser.unixuser'), value: remoteUserToModify.UNIXUSER },
              { label: translate('general.last_changed'), value: `${DateUtils.getDate(datemask, remoteUserToModify.CDATE, remoteUserToModify.CTIME.substring(0, 8))} ${translate('general.by')} ${remoteUserToModify.CUSER}` },
            ]}
            columns={4}
          />
        </Header>
        <Main id={`${id}_modifyRemoteUserModal`}>
          <Card>
            <Row>
              <Column
                colMD={12}>
                <Input
                  id={`${id}_buxUserInput`}
                  ref={this.buxUserInput}
                  onInputChanged={(val, err) => { this.handleOnBuxUserChanged(val, err) }}
                  value={buxUser.value}
                  title={translate('usermanagement.bux_user')}
                  error={buxUser.errorkey && translate(buxUser.errorkey)}
                  maxLength={8}
                  onBlur={() => this.validateBuxUser()}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}>
                <Input
                  id={`${id}_ipMaskInput`}
                  ref={this.ipMaskInput}
                  onInputChanged={(val, err) => { this.handleOnIpMaskChanged(val, err) }}
                  value={ipMask.value}
                  title={translate('remoteuser.ip4mask')}
                  error={ipMask.errorkey && translate(ipMask.errorkey)}
                  maxLength={45}
                  onBlur={() => this.validateIpMask()}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}>
                <Input
                  id={`${id}_ip6mask`}
                  ref={this.ip6maskInput}
                  onInputChanged={(val, err) => this.handleIp6mask(val, err)}
                  value={ip6mask.value}
                  title={translate('remoteuser.ip6mask')}
                  error={ip6mask.errorkey && translate(ip6mask.errorkey)}
                  onBlur={() => this.validateIP6Mask()}
                  maxLength={45}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_modifyRemoteUserModal`}>
          <Button
            id={`${id}_cancelBtn`}
            text={translate('general.cancel')}
            onClick={this.handleOnClose}
          />
          <Button
            id={`${id}_saveBtn`}
            text={translate('general.save')}
            onClick={this.handleOnSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    remoteUserToModify: state.remoteuser.remoteUser,
    usertoken: state.auth.serverdata.token,
    userId: state.auth.userid,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRemoteUser: (
      cuser,
      unixuser,
      buxuser,
      ipmask,
      ip6mask,
      callback
    ) => {
      RemoteUserActions.updateRemoteUser(
        cuser,
        unixuser,
        buxuser,
        ipmask,
        ip6mask,
        callback
      )(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyRemoteUserDialog)