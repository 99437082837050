import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'

// components
import {
  Button,
  Column,
  Input,
  Modal as ModalComponent, NumericSpinner, Row, Switch, Tab,
  Tabs, Toggle
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as FilterArgumentActions from 'redux/actions/FilterArgumentActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'

import './CreateFilterArgumentDialog.scss'
import _ from 'lodash'
import { hasNoValues } from 'utils/ObjectUtils'

class CreateFilterArgumentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    prefilledData: PropTypes.object,
    onClose: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props);
    this.defaultState = {
      generalTab: {
        filterArgumentID: {
          value: '',
          errorkey: ''
        },
        owner: {
          value: '',
          errorkey: ''
        },
        title: {
          value: '',
          errorkey: ''
        }
      },
      formulaTab: {
        logicalCondition: true,
        values: [] // [{fromColumn: 0}, {toColumn: 0}, caseSensitive: false, searchPattern: false, negate: false, text: {value: '', errorkey: ''}, ref: React.createRef()]
      }
    }
    this.state = _.cloneDeep(this.defaultState)
  }

  resetState = () => {
    this.setState(_.cloneDeep(this.defaultState))
  }


  filterArgumentIdInput = React.createRef()

  /**
   * @description Sets the initial focus and initializes the values.
   */
  componentDidMount = () => {
    const { filterArgument } = this.props
    this.filterArgumentIdInput.current.focus()
    const values = []

    if (this.props.copyFilterArgument || this.props.prefilledData) {
      if (this.props.copyFilterArgument) {
        for (let i = 1; i < 9; i++) {
          if (filterArgument[`SLFSTR0${i}`] !== '') {
            values.push({
              fromColumn: filterArgument[`SLFFCA0${i}`],
              toColumn: filterArgument[`SLFTCA0${i}`],
              caseSensitive: filterArgument[`SLFCP0${i}`] === 'CASE',
              searchPattern: filterArgument[`SLFCP0${i}`] === 'MASK',
              negate: filterArgument[`SLFNM0${i}`],
              text: {
                value: filterArgument[`SLFSTR0${i}`],
                errorkey: ''
              },
              ref: React.createRef()
            })
          }
        }
      }

      const data = this.props.prefilledData ? this.props.prefilledData : this.props.copyFilterArgument ? filterArgument : undefined;
      this.setState({
        generalTab: {
          filterArgumentID: {
            value: data?.SLF ?? '',
            errorkey: ''
          },
          owner: {
            value: data?.OWNER ?? '',
            errorkey: ''
          },
          title: {
            value: data?.SLFTITLE ?? '',
            errorkey: ''
          }
        },
        formulaTab: {
          ...this.state.formulaTab,
          logicalCondition: this.props.copyFilterArgument ? (filterArgument.SLFARCL === 'OR') : true,
          values: values
        }
      })
    }
  }

  /**
   * @description Validates the filter argument id.
   */
  validateFilterArgumentID = () => {
    const { generalTab } = this.state
    if (generalTab.filterArgumentID.value !== '') {
      if (this.props.copyFilterArgument && generalTab.filterArgumentID.value === this.props.filterArgument.SLF) {
        return {
          filterArgumentID: {
            ...generalTab.filterArgumentID,
            errorkey: 'definition.filter_argument_copy_same_id'
          }
        }
      } else {
        return {}
      }
    }
    return {
      filterArgumentID: {
        ...generalTab.filterArgumentID,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Handles the input changes of the general tab.
   * @param {String} id The id the input field.
   * @param {String} value The new value.
   * @param {String} errorkey The new errorkey.
   */
  handleGeneralTabInputChanged = (id, value, errorkey) => {
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        [id]: {
          value: value,
          errorkey: errorkey,
        }
      }
    })
  }

  /**
   * @description Renders the general tab
   */
  renderGeneralTab = () => {
    const { id } = this.props
    const { generalTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_general_tab_filter_argument_id`}
              value={generalTab.filterArgumentID.value}
              title={translate('definition.filter_argument_id')}
              ref={this.filterArgumentIdInput}
              required={`${translate('general.required_field')}`}
              maxLength={16}
              onInputChanged={(value, errorkey) => this.handleGeneralTabInputChanged('filterArgumentID', value, errorkey)}
              error={generalTab.filterArgumentID.errorkey && translate(generalTab.filterArgumentID.errorkey)}
              onBlur={() => this.setState({ generalTab: { ...this.state.generalTab, ...this.validateFilterArgumentID() } })}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_general_tab_filter_argument_owner`}
              value={generalTab.owner.value}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={(value, errorkey) => this.handleGeneralTabInputChanged('owner', value, errorkey)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_general_tab_filter_argument_title`}
              value={generalTab.title.value}
              title={translate('general.title')}
              maxLength={40}
              onInputChanged={(value, errorkey) => this.handleGeneralTabInputChanged('title', value, errorkey)}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the supress trailer changes in print tab.
   * @param {Number} index The new index of the switch buttons.
   */
  handleSwitch = index => {
    this.setState({
      formulaTab: {
        ...this.state.formulaTab,
        logicalCondition: index === 1
      }
    })
  }

  /**
   * @description Renders the the additional row area.
   */
  renderFilterArgumentValuesArea = () => {
    return (
      <>
        <Row className={'bux_remove_margin'}>
          <Column colMD={2}>
            <label className='control-label el_input_label'>
              {translate('definition.filter_argument_from_column')}
            </label>
          </Column>
          <Column colMD={2}>
            <label className='control-label el_input_label'>
              {translate('definition.filter_argument_to_column')}
            </label>
          </Column>
          <Column colMD={1}>
            <label className='control-label el_input_label'>
              {translate('definition.filter_argument_case_sensitive')}
            </label>
          </Column>
          <Column colMD={1}>
            <label className='control-label el_input_label'>
              {translate('definition.filter_argument_search_pattern')}
            </label>
          </Column>
          <Column colMD={1}>
            <label className='control-label el_input_label'>
              {translate('definition.filter_argument_negate')}
            </label>
          </Column>
          <Column colMD={4}>
            <label className='control-label el_input_label' required>
              {translate('general.text')}
            </label>
          </Column>
        </Row>
        {this.renderFilterArgumentValuesRow()}
      </>
    )
  }

  /**
   * @description Renders the the formula tab
   */
  renderFormulaTab = () => {
    const { id } = this.props
    const { formulaTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_formulatab_logical_conditions`}
              title={translate('definition.filter_argument_logical_condition')}
              items={[translate('general.and'), translate('general.or')]}
              onClick={index => this.handleSwitch(index)}
              activeIndex={formulaTab.logicalCondition ? 1 : 0}
            />
          </Column>
          <Column colMD={3} offsetMD={3}>
            <div className={'bux_place_add_filter_argument_btn'}>
              {formulaTab.values.length < 8
                ? <Button
                  id={`${id}_addFormula`}
                  className='bux_content_width_btn'
                  title={' '}
                  text={translate('definition.filterargument_add_filterargument')}
                  onClick={this.handleAddFormula}
                />
                : <Button
                  id={`${id}_addFormula`}
                  className='bux_content_width_btn'
                  title={' '}
                  text={translate('definition.filterargument_add_filterargument')}
                  tooltip={translate('definition.filter_argument_max_reached')}
                  disabled
                />
              }
            </div>
          </Column>
        </Row>
        {formulaTab.values.length > 0 && this.renderFilterArgumentValuesArea()}
      </>
    )
  }

  /**
   * @description Adds additional row
   */
  handleAddFormula = () => {
    if (this.state.formulaTab.values.length < 8) {
      this.setState({
        formulaTab: {
          ...this.state.formulaTab,
          values: [
            ...this.state.formulaTab.values,
            {
              fromColumn: 0,
              toColumn: 0,
              caseSensitive: false,
              searchPattern: false,
              negate: false,
              text: {
                value: '',
                errorkey: ''
              },
              ref: React.createRef()
            }
          ]
        }
      })
    }
  }

  /**
   * @description Renders the additional inputs
   */
  renderFilterArgumentValuesRow = () => {
    const { id } = this.props
    const { formulaTab } = this.state
    return (
      formulaTab.values.map((el, i) => {
        return (
          <>
            <Row>
              <Column colMD={2}>
                <NumericSpinner
                  id={`${id}_filter_argument_titlefromcol_${i}`}
                  onChange={val => this.handleSpinner('fromColumn', i, val)}
                  value={el.fromColumn}
                  steps={1}
                  min={0}
                  max={32767}
                />
              </Column>
              <Column colMD={2}>
                <NumericSpinner
                  id={`${id}_filter_argument_titletocol_${i}`}
                  onChange={val => this.handleSpinner('toColumn', i, val)}
                  value={el.toColumn}
                  steps={1}
                  min={0}
                  max={32767}
                />
              </Column>
              <Column colMD={1}>
                <Toggle
                  id={`${id}_filter_argument_casesensitive_${i}`}
                  onCheck={() => this.handleToggle(i, 'caseSensitive', 'searchPattern')}
                  value={el.caseSensitive}
                  yes={translate('general.yes')}
                  no={translate('general.no')}
                  animated
                />
              </Column>
              <Column colMD={1}>
                <Toggle
                  id={`${id}_filter_argument_searchpattern_${i}`}
                  onCheck={() => this.handleToggle(i, 'searchPattern', 'caseSensitive')}
                  value={el.searchPattern}
                  yes={translate('general.yes')}
                  no={translate('general.no')}
                  animated
                />
              </Column>
              <Column colMD={1}>
                <Toggle
                  id={`${id}_filter_argument_negate_${i}`}
                  onCheck={() => this.handleToggle(i, 'negate')}
                  value={el.negate}
                  yes={translate('general.yes')}
                  no={translate('general.no')}
                  animated
                />
              </Column>
              <Column colMD={4}>
                <Input
                  id={`${id}_filter_argument_text_${i}`}
                  onInputChanged={val => this.handleItemInput(i, val)}
                  value={el.text.value}
                  error={el.text.errorkey && translate(el.text.errorkey)}
                  maxLength={48}
                  required={`${translate('general.required_field')}`}
                  ref={el.ref}
                  onBlur={() => this.setState({ formulaTab: { ...this.state.formulaTab, ...this.validateFilterArgument(i) } })}
                />
              </Column>
              <Column colMD={1}>
                <Button
                  id={`${id}_deleteformula_${i}`}
                  icon='delete'
                  onClick={() => this.handleDeleteFormula(i)}
                />
              </Column>
            </Row>
          </>
        )
      })
    )
  }

  /**
   * @description Handles the changes of the numeric spinners.
   * @param {String} key The key of the numeric spinner in state.
   * @param {Number} index The index of the numeric spinner (row).
   * @param {Number} val The new value.
   */
  handleSpinner = (key, index, val) => {
    const newValue = this.state.formulaTab.values
    newValue[index][key] = val
    this.setState({
      formulaTab: {
        ...this.state.formulaTab,
        values: newValue
      }
    })
  }

  /**
   * @description Handles the changes of the toggles.
   * @param {Number} index The index of the toggle (row).
   * @param {String} key The key of the toggle in state.
   */
  handleToggle = (index, key1, key2) => {
    const newValue = this.state.formulaTab.values
    newValue[index][key1] = !newValue[index][key1]
    newValue[index][key2] = false
    this.setState({
      formulaTab: {
        ...this.state.formulaTab,
        values: newValue
      }
    })
  }

  /**
   * @description Validates the formula tab.
   * @returns {Boolean} True if there is an error in that tab.
   */
  validateFormulaTab = () => {
    const validatorResult = {
      ...this.validateFormulaFilterArguments()
    }

    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ formulaTab: { ...this.state.formulaTab, ...validatorResult } }, () => {
        this.handleFocusFormulaTab()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input with an error in formula tab.
   */
  handleFocusFormulaTab = () => {
    const { formulaTab } = this.state
    const requiredInputs = []

    if (formulaTab.values) {
      for (let i = 0; i < formulaTab.values.length; i++) {
        requiredInputs.push(
          { inputRef: formulaTab.values[i].ref, errorkey: formulaTab.values[i].text.errorkey },
        )
      }
    }

    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].errorkey !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  /**
   * @description Validates the general tab.
   * @returns {Boolean} False if there is an error in that tab.
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateFilterArgumentID(),
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ generalTab: { ...this.state.generalTab, ...validatorResult } }, () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input with an error in general tab.
   */
  handleFocusGeneralTab = () => {
    const { generalTab } = this.state
    const requiredInputs = [
      { inputRef: this.filterArgumentIdInput, errorkey: generalTab.filterArgumentID.errorkey },
    ]
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].errorkey !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  /**
   * @description Deletes the input row.
   * @param {Number} index The index of the row to delete.
   */
  handleDeleteFormula = index => {
    this.setState({
      formulaTab: {
        ...this.state.formulaTab,
        values: this.state.formulaTab.values.filter((el, i) => i !== index)
      }
    })
  }

  /**
   * @description Handles the changes of text of a row.
   * @param {Number} index The index of the row to modify.
   * @param {String} val The new value.
   */
  handleItemInput = (index, val) => {
    const newValue = this.state.formulaTab.values
    newValue[index].text = { value: val, errorkey: '' }
    this.setState({
      formulaTab: {
        ...this.state.formulaTab,
        values: newValue
      }
    })
  }

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { createFilterArgument, onClose } = this.props
    const { generalTab, formulaTab } = this.state
    const errorTabs = [
      this.validateGeneralTab(),
      this.validateFormulaTab()
    ]
    if (errorTabs.every(d => d)) {
      const filterArguments = {
        'SLF': generalTab.filterArgumentID.value,
        'SLFTITLE': generalTab.title.value,
        'OWNER': generalTab.owner.value,
        'SLFARCL': formulaTab.logicalCondition ? 'OR' : 'AND',
      }
      for (let i = 0; i < formulaTab.values.length; i++) {
        filterArguments[`SLFFCA0${i + 1}`] = formulaTab.values[i].fromColumn
        filterArguments[`SLFTCA0${i + 1}`] = formulaTab.values[i].toColumn
        filterArguments[`SLFSTR0${i + 1}`] = formulaTab.values[i].text.value
        filterArguments[`SLFCP0${i + 1}`] = formulaTab.values[i].caseSensitive
          ? 'CASE'
          : formulaTab.values[i].searchPattern
            ? 'MASK'
            : 'NOCASE'
        filterArguments[`SLFNM0${i + 1}`] = formulaTab.values[i].negate
      }
      createFilterArgument(filterArguments, () => onClose())
    }
  }

  /**
   * @description Gets the error tabs as an array of numbers.
   * @returns {Array} The error tabs as an array of numbers.
   */
  handleErrorTabs = () => {
    const {
      generalTab: { filterArgumentID },
      formulaTab: { values }
    } = this.state
    let buffer = []
    if (filterArgumentID.errorkey !== undefined && filterArgumentID.errorkey !== '') {
      buffer.push(0)
    }
    if (values) {
      for (let i = 0; i < values.length; i++) {
        if (values[i].text.errorkey !== undefined && values[i].text.errorkey !== '') {
          buffer.push(1)
          break
        }
      }
    }

    return buffer
  }

  /**
   * @description Validate a filter argument. Adds an error under the input if it is empty.
   * @param {Number} index The index of the filter argument (row).
   * @returns {Object} The new filter argument object with the error included if the validation failed.
   */
  validateFilterArgument = (index) => {
    let clone = this.state.formulaTab.values

    if (clone[index] && clone[index].text.value === '') {
      clone[index].text = {
        value: clone[index].text.value,
        errorkey: 'general.input_required'
      }
      return { values: clone }
    }
    return {}
  }

  /**
   * @description Validates all filter arguments.
   */
  validateFormulaFilterArguments = () => {
    let clone = this.state.formulaTab.values

    let error = false
    for (let i = 0; i < clone.length; i++) {
      if (clone[i].text.value === '') {
        clone[i].text = {
          value: clone[i].text.value,
          errorkey: 'general.input_required'
        }
        error = true
      }
    }
    if (error) {
      return { values: clone }
    }
    return {}
  }

  render = () => {
    const { id, onClose, copyFilterArgument } = this.props
    return (
      <Modal
        id={id}
        className='bux_UserProfileModal'
        onClose={onClose}
        size={'l'}>
        <Header
          id={id}
          title={copyFilterArgument ? translate('definition.copy_filter_argument') : translate('definition.create_filter_argument')}
          onClose={onClose}>
        </Header>
        <Main id={id}>
          <Tabs
            id={id}
            errorTabs={this.handleErrorTabs()}>
            <Tab title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('definition.filter_argument_formula')}>
              {this.renderFormulaTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer>
          {this.props.prefilledData && !hasNoValues(this.props.prefilledData) &&
            <Button
              id={`${id}_resetbtn`}
              tooltip={translate('general.reset')}
              icon={'undo'}
              onClick={this.resetState}
            />
          }
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    filterArgument: state.definitions.filterarguments.filterArgument
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createFilterArgument: (filterArgument, callback) => {
      FilterArgumentActions.createFilterArgument(filterArgument, callback)(dispatch)
    },
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFilterArgumentDialog)