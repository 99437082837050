import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  Column,
  DownloadWrapper, Icon, Link, Row, TableButton,
  TableButtonGroup,
  TableButtonGroupGroup,
  TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'

import CtmJobInformationDialog from 'components/dialogs/ctm_job_information_dialog/CtmJobInformationDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import DocumentInformation from 'components/dialogs/document_information_dialog/DocumentInformation.jsx'
import DocumentPrint from 'components/dialogs/document_print_dialog/DocumentPrint.jsx'
import { DownloadDialog } from 'components/dialogs/download_dialog/DownloadDialog'
import MarkReloadDocumentDialog from 'components/dialogs/mark_reload_document_dialog/MarkReloadDocumentDialog'
import RerunDialog, * as RerunDialogTypes from 'components/dialogs/rerun_dialog/RerunDialog'
import StonebranchJobInformationDialog from 'components/dialogs/stonebranch_job_information_dialog/StonebramchJobInformationDialog'
import SyslogJobInformationDialog from 'components/dialogs/syslog_job_information_dialog/SyslogJobInformationDialog'
import UC4jobInformationDialog from 'components/dialogs/uc4job_information_dialog/UC4jobInformationDialog'
import ZosJobInformationDialog from 'components/dialogs/zos_job_information_dialog/ZosJobInformationDialog'
import { SelectionTable } from '../common/SelectionTable';
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'

import * as CustomSelectionActions from 'redux/actions/CustomSelectionActions'
import * as DocViewerActions from 'redux/actions/DocViewerActions'
import * as SearchActions from 'redux/actions/SearchActions'
import * as SearchControlMActions from 'redux/actions/SearchControlMActions'
import * as SearchSyslogActions from 'redux/actions/SearchSyslogActions'
import * as SearchUC4Actions from 'redux/actions/SearchUC4Actions'
import * as SearchZOSActions from 'redux/actions/SearchZOSActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as StandardSelectionActions from 'redux/actions/StandardSelectionActions'
import * as StonebranchActions from 'redux/actions/SearchStonebranchActions';
import * as Preferences from 'redux/general/Preferences'

import { HEADERS } from '../../../drawer/content/search/search_body/Search.types';

import * as ColumnUtils from 'utils/ColumnUtils'
import * as CommonUtils from 'utils/CommonUtils'
import * as DateUtils from 'utils/DateUtils'
import * as DocViewerUtils from 'utils/DocViewerUtils'
import * as SortUtils from 'utils/SortUtils'
import * as UrlUtils from 'utils/UrlUtils'
import * as UserUtils from 'utils/UserUtils'
import { convertIntoObject } from 'utils/Utils'
import { SearchByIndexDialog } from 'components/dialogs/search_by_index/SearchByIndexDialog'

class SearchResultCustomSearch extends Component {
  state = {
    showDocumentInformationDialog: false,
    showTableSettingsDialog: false,
    showReloadDialog: false,
    showPrintDialog: false,
    showPrintDialogMultiple: false,
    showRerunDialog: false,
    showUC4InformationDialog: false,
    showZOSInformationDialog: false,
    showCtmInformationDialog: false,
    showStonebranchInformationDialog: false,
    showSyslogInformationDialog: false,
    showDownloadDialog: false,
    selectedRow: undefined,
    selectedMimeType: undefined,
    selectedDownloadType: undefined,
    rerunType: undefined,
    header: (this.props.resultTable?.OBJECTS && this.props.resultTable?.OBJECTS.filter(d => d.SLITSEL === '*').length > 0) ? this.fillHeaderInformationResultTable() : this.fillHeaderInformation(),
    documentsToPrint: {},
    documentsToReload: {},
    checkedRows: undefined,
    parameterToBeUpdated: null,
  }

  /**
   * @description Fills the header information for the table columns.
   * @returns {Array} An array of column information and translation keys.
   */
  fillHeaderInformation() {
    const { customDialog, controlmJobs, syslogJobs, zosJobs, uc4jobs, stonebranchJobs } = this.props
    if (customDialog) {
      if (UserUtils.isDOCX() || (UserUtils.isLOGX() && customDialog[7] === 'BRWTAB')) {
        return [
          { rest: CREATIONDATE, translation: 'general.creation_date', default: true },
          { rest: FORM, translation: 'general.form', default: true },
          { rest: EXT, translation: 'general.extension', default: true },
          { rest: WREPORT, translation: 'general.report', default: true },
          { rest: SRCPAGES, translation: 'general.pages', default: true },
          { rest: SRCJOBI, translation: 'general.doctype', default: true },
          { rest: SRCJOBN, translation: 'general.jobname', default: true },
          { rest: LTITLE, translation: 'general.title', default: true },
          { rest: LGRFFARC },
          { rest: LGRFFDEL },
          { rest: LGRFFREL },
          { rest: LGRARCH },
          { rest: LTOKEN },
          { rest: RTOKEN },
          { rest: OFDOCID },
          { rest: B93DATE },
          { rest: B93TIME },
          { rest: SORTDT },
          { rest: LGRCUSR },
          { rest: PBDNAME },
          { rest: SRCPRCD },
          { rest: SRCSTPD },
          { rest: SRCDDND },
          { rest: DREPORT },
          { rest: SMODE },
          { rest: STATUS },
          { rest: SRCLNCT },
          { rest: SRCMLRL },
          { rest: LGRONLNE },
          { rest: LGRAUTO },
          { rest: LGRINDEX },
          { rest: LGRXEROX },
          { rest: LGRAFP },
          { rest: LGRTRC },
          { rest: LGRASCII },
          { rest: SRCRECFM },
          { rest: LGRMAXRL },
          { rest: LREFD },
          { rest: LGRNOTES },
          { rest: PRTSTAT },
          { rest: B93SDATE },
          { rest: B93STIME },
          { rest: EIXPAGES },
          { rest: SPLPAGES },
          { rest: SRCSUBU },
          { rest: JOBRC },
          { rest: OWNER },
          { rest: DOCUSR1 },
          { rest: DOCUSR2 },
          { rest: DOCUSR3 },
          { rest: DOCUSR4 },
          { rest: DOCUSR5 },
          { rest: DOCUSR6 },
          { rest: DOCUSR7 },
          { rest: DOCUSR8 },
          { rest: TEXTENCO },
          { rest: NLREFD },
          { rest: LGRSECIX },
          { rest: EDTSTAT },
          { rest: EDTTEXT },
          { rest: DOCSTAT },
          { rest: OUTSTAT },
          { rest: PENDING },
          { rest: BUXORGDT },
          { rest: BUXINPDT }
        ]
      }
      else if (UserUtils.isLOGX()) {
        // SLTITYPE
        if (customDialog[7] === 'BRWUC4') {
          const defaultOrderedHeaders = [SRCSUBDT, STRTDT, ENDDT, JOBERROR, UC4STATUS, CLIENT, RUNNUMBER, SYSTEM, JOBGROUP]
          return CommonUtils.hasProperties(uc4jobs) ? ColumnUtils.getColumnsObjects(uc4jobs.header, defaultOrderedHeaders) : []
        }
        else if (customDialog[7] === 'BRWZOS') {
          const defaultOrderedHeaders = [JOBNAME, JOBID, SRCSUBDT, STRTDT, ENDDT, JOBERROR, RETURNCODE, LOGSOURCE,
            NETID, JOBGROUP]
          return CommonUtils.hasProperties(zosJobs) ? ColumnUtils.getColumnsObjects(zosJobs.header, defaultOrderedHeaders) : []
        }
        else if (customDialog[7] === 'BRWCTM') {
          const defaultOrderedHeaders = [JOBNAME, ORDDATE, STRTDATE, ENDDATE, DATACENT, APPL, SUBAPPL, AGNTNAME,
            FILENAME, JOBERR, JOBRC, RUNCOUNT, JOBGROUP]
          return CommonUtils.hasProperties(controlmJobs) ? ColumnUtils.getColumnsObjects(controlmJobs.header, defaultOrderedHeaders) : []
        }
        else if (customDialog[7] === 'BRWSYSL') {
          const defaultOrderedHeaders = [JOBNAME, SYSTEM, JOBID, SRCSUBDT, ENDDT, SRCSUBU, CLASS, JOBGROUP]
          return CommonUtils.hasProperties(syslogJobs) ? ColumnUtils.getColumnsObjects(syslogJobs.header, defaultOrderedHeaders) : []
        }
        else if (customDialog[7] === 'BRWSTB') {
          const defaultOrderedHeaders = [JOBNAME, SRCSUBDT, STRTDATE, ENDDATE, JOBSTAT, JOBERR, JOBRC, RUNUSER, AGNTNAME, SYSID, JOBGROUP, SERVICES, SRCVERS, WORKFLOW, USR1NAM, USR1VAL, USR2NAM, USR2VAL]
          return CommonUtils.hasProperties(stonebranchJobs) ? ColumnUtils.getColumnsObjects(stonebranchJobs.header, defaultOrderedHeaders) : []
        }
      }
    }
  }


  /**
   * @description Checks if the headers need to be updated when a new custom dialog was loaded into the drawer.
   */
  componentDidUpdate = (prevProps, prevState) => {
    // ! WIP: Re-evaluate render cycle -> setState within componentDidUpdate is an anti-pattern
    // ! In this component there is a prolonged render cycle to parse the header data
    const { header } = this.state
    const { resultTable, customDialog } = this.props
    if ((resultTable?.OBJECTS && !prevProps.resultTable?.OBJECTS) || (!resultTable?.OBJECTS && prevProps.resultTable?.OBJECTS) || (resultTable?.OBJECTS && prevProps.resultTable?.OBJECTS)) {
      const newHeader = (resultTable?.OBJECTS && resultTable?.OBJECTS.filter(d => d.SLITSEL === '*').length > 0 && resultTable?.SLTINAME === (customDialog && customDialog[3])) ? this.fillHeaderInformationResultTable() : this.fillHeaderInformation()
      if (JSON.stringify(header) !== JSON.stringify(newHeader)) {
        this.setState({ header: newHeader })
      }
    }
    // Load new headers when the custom dialog type has changed.
    if ((customDialog && !prevProps.customDialog) || this.headerHasChanged(prevProps)) {
      const newHeader = (resultTable?.OBJECTS && resultTable?.OBJECTS.filter(d => d.SLITSEL === '*').length > 0 && resultTable?.SLTINAME === (customDialog && customDialog[3])) ? this.fillHeaderInformationResultTable() : this.fillHeaderInformation()
      if (JSON.stringify(header) !== JSON.stringify(newHeader)) {
        this.setState({ header: newHeader })
      }
    }

    if ((this.state.parameterToBeUpdated !== null) && (this.state.parameterToBeUpdated !== prevState.parameterToBeUpdated)) {
      this.updateDocumentMarker(this.state.parameterToBeUpdated)
    }

    if (
      (prevState.showDocumentInformationDialog && !this.state.showDocumentInformationDialog) ||
      (prevState.showUC4InformationDialog && !this.state.showUC4InformationDialog) ||
      (prevState.showZOSInformationDialog && !this.state.showZOSInformationDialog) ||
      (prevState.showCtmInformationDialog && !this.state.showCtmInformationDialog) ||
      (prevState.showStonebranchInformationDialog && !this.state.showStonebranchInformationDialog) ||
      (prevState.showSyslogInformationDialog && !this.state.showSyslogInformationDialog)
    ) {
      this.handleRefresh()
    }
  }

  updateDocumentMarker = (parameterToBeUpdated) => {
    const { document, markArchive, markDelete, markReload } = this.props

    const archiveStatus = document[parameterToBeUpdated] === false || document[parameterToBeUpdated] === '' ? 'PENDING' : ''
    const singleLtoken = document.LTOKEN
    const singleRtoken = document.RTOKEN
    const singleDocid = singleLtoken + singleRtoken

    const documentDefinition = {
      DOCID: singleDocid,
      FORM: document.FORM,
      EXT: document.EXT,
      REPORT: document.REPORT
    }

    if (parameterToBeUpdated === 'LGRFFARC') {
      markArchive({ ...documentDefinition, ARCHIVE: archiveStatus }, () => this.setState({ parameterToBeUpdated: null }), this.handleRefresh)
    }

    if (parameterToBeUpdated === 'LGRFFDEL') {
      markDelete({ ...documentDefinition, DELETE: archiveStatus }, () => this.setState({ parameterToBeUpdated: null }), this.handleRefresh)
    }

    if (parameterToBeUpdated === 'LGRFFREL') {
      markReload({ ...documentDefinition, RELOAD: archiveStatus }, () => this.setState({ parameterToBeUpdated: null }), this.handleRefresh)
    }
  }

  headerHasChanged = prevProps => {
    const { uc4Jobs, zosJobs, controlmJobs, syslogJobs } = this.props
    const prefJobs = [prevProps.uc4Jobs, prevProps.zosJobs, prevProps.controlmJobs, prevProps.syslogJobs]
    const currentJobs = [uc4Jobs, zosJobs, controlmJobs, syslogJobs]
    let result = false
    for (let i = 0; i < prefJobs.length; i++) {
      if (JSON.stringify(prefJobs[i]?.header) !== JSON.stringify(currentJobs[i]?.header)) {
        result = true
        break
      }
    }
    return result
  }

  /**
   * @description Gets the specific headers which are defined in the result table which was linked to the custom dialog.
   */
  fillHeaderInformationResultTable() {
    const { resultTable } = this.props
    const result = []
    resultTable.OBJECTS.forEach(entry => {
      if (entry.SLITSEL) {
        // !Usage of object key "translated" instead of "translation" because we get the translated string and not the language key.
        result.push({ rest: entry.SLINAME.toUpperCase(), translated: entry.SLITNAME || entry.SLICOM })
      }
    })
    return result
  }

  handleRefreshDocuments = keepPagination => {
    const { customDialog, cacheUpdatedSearchResults, preferences, getDocuments, getDocumentsStandardSelection } = this.props
    getDocuments({
      searchParams: preferences[Preferences.SEARCH_LAST_CUSTOM_SEARCH],
      keepPagination: keepPagination,
      callback: () => {
        cacheUpdatedSearchResults(customDialog[7])

        if (!preferences[Preferences.SEARCH_LAST_STANDARD_SEARCH] || Object.keys(preferences[Preferences.SEARCH_LAST_STANDARD_SEARCH]).length === 0) return;
        getDocumentsStandardSelection({ searchParams: preferences[Preferences.SEARCH_LAST_STANDARD_SEARCH] })
      }
    })
  }

  handleRefreshDocumentsByIndex = () => {
    const { customDialog, cacheUpdatedSearchResults, preferences, getDocumentsByGlobalIndex } = this.props
    getDocumentsByGlobalIndex({ searchParams: preferences[Preferences.SEARCH_LAST_CUSTOM_SEARCH], callback: () => cacheUpdatedSearchResults(customDialog[7]) })
  }

  handleRefreshUC4 = () => {
    const { customDialog, cacheUpdatedSearchResults, preferences, getUC4Jobs } = this.props
    getUC4Jobs({ searchObj: preferences[Preferences.SEARCH_LAST_CUSTOM_SEARCH], callback: () => cacheUpdatedSearchResults(customDialog[7]) })
  }

  handleRefreshZOS = () => {
    const { customDialog, cacheUpdatedSearchResults, preferences, getZosJobs } = this.props
    getZosJobs(preferences[Preferences.SEARCH_LAST_CUSTOM_SEARCH], () => cacheUpdatedSearchResults(customDialog[7]))
  }

  handleRefreshControlM = () => {
    const { customDialog, cacheUpdatedSearchResults, preferences, getControlMJobs } = this.props
    getControlMJobs(preferences[Preferences.SEARCH_LAST_CUSTOM_SEARCH], () => cacheUpdatedSearchResults(customDialog[7]))
  }

  handleRefreshSyslog = () => {
    const { customDialog, cacheUpdatedSearchResults, preferences, getSyslogJobs } = this.props
    getSyslogJobs(preferences[Preferences.SEARCH_LAST_CUSTOM_SEARCH], () => cacheUpdatedSearchResults(customDialog[7]))
  }

  /**
   * @description refreshes the table
   */
  handleRefresh = keepPagination => {
    const { customDialog } = this.props
    if (UserUtils.isDOCX() || (UserUtils.isLOGX && customDialog[7] === 'BRWTAB')) {
      if (customDialog[2] === 'SELGBL') {
        this.handleRefreshDocumentsByIndex(keepPagination)
      }
      else {
        this.handleRefreshDocuments(keepPagination)
      }
    }
    else if (UserUtils.isLOGX()) {
      if (customDialog[7] === 'BRWUC4') {
        this.handleRefreshUC4(keepPagination)
      }
      else if (customDialog[7] === 'BRWZOS') {
        this.handleRefreshZOS(keepPagination)
      }
      else if (customDialog[7] === 'BRWCTM') {
        this.handleRefreshControlM(keepPagination)
      }
      else if (customDialog[7] === 'BRWSYSL') {
        this.handleRefreshSyslog(keepPagination)
      }
    }
  }

  /**
   * @description Handles the reload action.
   * @param {Number} index The row index of document.
   */
  handleReload = index => {
    const { documents } = this.props
    const ltoken = documents.data[index][this.headerIndex('LTOKEN')]
    const rtoken = documents.data[index][this.headerIndex('RTOKEN')]
    const docid = ltoken + rtoken
    const callback = () => { this.setState({ showReloadDialog: true }) }
    this.props.getDocument(undefined, docid, '', callback)
  }

  /**
   * @description Handles delete from archive action.
   * @param {Number} index The row index of document.
   */
  handleDeleteFromArchive = index => {
    const { documents } = this.props
    const ltoken = documents.data[index][this.headerIndex('LTOKEN')]
    const rtoken = documents.data[index][this.headerIndex('RTOKEN')]
    const docid = ltoken + rtoken
    const callback = () => { this.setState({ showDeletefromArchiveDialog: true }) }

    this.props.getDocument(undefined, docid, '', callback)
  }

  /**
 * @description Handles the download functionality of documents in binary format.
 * @param {Number} rowIndex The current row.
 */
  handleDocumentDownload = rowIndex => {
    const { documents, downloadDocument } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    const docidFilename = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '')

    const form = documents.data[rowIndex][this.headerIndex('FORM')]
    const ext = documents.data[rowIndex][this.headerIndex('EXT')]
    const report = documents.data[rowIndex][this.headerIndex('REPORT')]
    let doctype = documents.data[rowIndex][this.headerIndex('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }

    // Generate name which will be suggested as download-filename
    // '*' is filtered because it would be replaced with '_' at chrome, it does not make problems when it is * followed by some texts than chrome just removes the * for any reason
    // undefined and '' could occur and are both being removed to avoid '..'
    const filename = [form, ext, report, docidFilename, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')
    downloadDocument(docid, filename)
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  handleDisplayDocument = rowIndex => {
    const { documents, displayDocument, bwesystemname, bwebaseurl } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    displayDocument(docid, bwebaseurl, bwesystemname)
  }

  /**
   * @description Deletes the current document from archive.
   */
  deleteFromArchive = () => {
    const { document, markArchive } = this.props
    const docid = this.props.document['DOCID']

    const documentDefinition = {
      ARCHIVE: 'DELETE',
      DOCID: docid,
      FORM: document.FORM,
      EXT: document.EXT,
      REPORT: document.REPORT
    }

    const callback = () => { this.setState({ showDeletefromArchiveDialog: false }) }
    markArchive(documentDefinition, callback)
  }

  /**
   * @description gets the index of the header in redux state documents.header
   * @param {String} header header name of the header in redux state documents.header
   */
  headerIndex = header => {
    const { customDialog, documents, zosJobs, uc4Jobs, controlmJobs, syslogJobs, stonebranchJobs } = this.props
    if (UserUtils.isDOCX() || (UserUtils.isLOGX() && customDialog[7] === 'BRWTAB')) {
      return documents.header.indexOf(header)
    }
    else if (UserUtils.isLOGX()) {
      if (customDialog[7] === 'BRWUC4') {
        return uc4Jobs.header.indexOf(header)
      }
      else if (customDialog[7] === 'BRWZOS') {
        return zosJobs.header.indexOf(header)
      }
      else if (customDialog[7] === 'BRWCTM') {
        return controlmJobs.header.indexOf(header)
      }
      else if (customDialog[7] === 'BRWSYSL') {
        return syslogJobs.header.indexOf(header)
      }
      else if (customDialog[7] === 'BRWSTB') {
        return stonebranchJobs.header.indexOf(header)
      }
    }
  }

  /**
   * @description This function will open all selected rows as tabs in the DocViewer based on their type.
   * @param {Array<number>} selectedRowsIndices This array contains the indices of the selected rows.
   */
  openSelectedRowsInDocViewer = (selectedRowsIndices) => {
    const { customDialog } = this.props

    selectedRowsIndices.forEach(rowIndex => {
      if (UserUtils.isDOCX()) {
        this.openDocumentViewerStandardSelection(rowIndex)
      }
      else {
        switch (customDialog[7]) {
          case 'BRWTAB': this.openDocumentViewerStandardSelection(rowIndex); break;
          case 'BRWUC4': this.openDocumentViewerUC4(rowIndex); break;
          case 'BRWZOS': this.openDocumentViewerZOS(rowIndex); break;
          case 'BRWCTM': this.openDocumentViewerControlM(rowIndex); break;
          case 'BRWSYSL': this.openDocumentViewerSyslog(rowIndex); break;
          case 'BRWSTB': this.openDocumentViewerStonebranch(rowIndex); break;
          default: break;
        }
      }
    })
  }

  /**
   * @description This function will handle opening a new tab with the parsed document data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewerStandardSelection = (rowIndex) => {
    const { documents, addTabToDocViewer } = this.props
    const docId = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    const pageCountHeaderIndex = documents.header.indexOf('SRCPAGES')
    const pageCount = pageCountHeaderIndex !== -1 ? parseInt(documents.data[rowIndex][pageCountHeaderIndex]) : 1

    addTabToDocViewer({
      id: docId,
      type: DocViewerUtils.SEARCH_TYPE_STANDARDSELECTION,
      header: documents.header,
      data: documents.data[rowIndex],
      pageCount: pageCount
    })
  }

  /**
   * @description This function will handle opening a new tab with the parsed document data for a sidefile.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewerStandardSelectionSideFile = (rowIndex) => {
    const { documents, addTabToDocViewer } = this.props
    const docId = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    const pageCountHeaderIndex = documents.header.indexOf('EIXPAGES')
    const pageCount = pageCountHeaderIndex !== -1 ? parseInt(documents.data[rowIndex][pageCountHeaderIndex]) : 1

    addTabToDocViewer({
      id: `${DocViewerUtils.IDX_MARKER}${docId}`,
      type: DocViewerUtils.SEARCH_TYPE_STANDARDSELECTION_SIDEFILE,
      header: documents.header,
      data: documents.data[rowIndex],
      pageCount: pageCount
    })
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewerZOS = (rowIndex) => {
    const { addTabToDocViewer, zosJobs } = this.props

    addTabToDocViewer({
      id: zosJobs.data[rowIndex][this.headerIndex('JOBID')],
      type: DocViewerUtils.SEARCH_TYPE_ZOS_ALL,
      header: zosJobs.header,
      data: zosJobs.data[rowIndex],
      pageCount: 0 // Will be populated in document viewer to avoid callback problems with multi-display
    })
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewerUC4 = (rowIndex) => {
    const { uc4Jobs, addTabToDocViewer } = this.props
    const docId = uc4Jobs.data[rowIndex][this.headerIndex('DOCID')]
    const pageCount = parseInt(uc4Jobs.data[rowIndex][this.headerIndex('SRCPAGES')])

    addTabToDocViewer({
      id: docId,
      type: DocViewerUtils.SEARCH_TYPE_UC4,
      header: uc4Jobs.header,
      data: uc4Jobs.data[rowIndex],
      pageCount: pageCount
    })
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewerControlM = (rowIndex) => {
    const { addTabToDocViewer, controlmJobs } = this.props

    addTabToDocViewer({
      id: controlmJobs.data[rowIndex][this.headerIndex('UKEY')],
      type: DocViewerUtils.SEARCH_TYPE_CONTROLM_ALL,
      header: controlmJobs.header,
      data: controlmJobs.data[rowIndex],
      pageCount: 0 // Will be populated in document viewer to avoid callback problems with multi-display
    })
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  openDocumentViewerSyslog = (rowIndex) => {
    const { addTabToDocViewer, syslogJobs } = this.props

    addTabToDocViewer({
      id: syslogJobs.data[rowIndex][this.headerIndex('UKEY')],
      type: DocViewerUtils.SEARCH_TYPE_SYSLOG_ALL,
      header: syslogJobs.header,
      data: syslogJobs.data[rowIndex],
      pageCount: 0 // Will be populated in document viewer to avoid callback problems with multi-display
    })
  }

  openDocumentViewerStonebranch = (rowIndex) => {
    const { addTabToDocViewer, stonebranchJobs } = this.props

    addTabToDocViewer({
      id: stonebranchJobs.data[rowIndex][this.headerIndex('UKEY')],
      type: DocViewerUtils.SEARCH_TYPE_STONEBRANCH_ALL,
      header: stonebranchJobs.header,
      data: stonebranchJobs.data[rowIndex],
      pageCount: 0 // Will be populated in document viewer to avoid callback problems with multi-display
    });
  }

  /**
   * @description Resets the checked rows after multi marking to have an equal behavior for all checked rows even if the entry was not changed through marking.
   */
  resetCheckedRows = () => {
    this.setState({ checkedRows: [] }, () => this.setState({ checkedRows: undefined }))
  }

  multiMarkAction = (rows, dropdownIndex) => {
    const { updateMarkMulti, documents } = this.props
    const docids = rows.map(row => {
      const ltoken = documents.data[row[row.length - 1]][this.headerIndex('LTOKEN')]
      const rtoken = documents.data[row[row.length - 1]][this.headerIndex('RTOKEN')] || '0000000000000000'
      return ltoken + rtoken
    })
    const docInformation = rows.map(row => {
      return {
        form: documents.data[row[row.length - 1]][this.headerIndex('FORM')],
        extension: documents.data[row[row.length - 1]][this.headerIndex('EXT')],
        report: documents.data[row[row.length - 1]][this.headerIndex('WREPORT')]
      }
    })
    const requestObj = {
      DOCIDS: docids
    }
    switch (dropdownIndex) {
      case 2:
        requestObj.DELETE = 'PENDING'
        updateMarkMulti(requestObj, docInformation, this.resetCheckedRows, () => this.handleRefresh(true))
        break
      case 3:
        requestObj.DELETE = ''
        updateMarkMulti(requestObj, docInformation, this.resetCheckedRows, () => this.handleRefresh(true))
        break
      case 4:
        requestObj.ARCHIVE = 'PENDING'
        updateMarkMulti(requestObj, docInformation, this.resetCheckedRows, () => this.handleRefresh(true))
        break
      case 5:
        requestObj.ARCHIVE = ''
        updateMarkMulti(requestObj, docInformation, this.resetCheckedRows, () => this.handleRefresh(true))
        break
      case 6:
        requestObj.RELOAD = 'PENDING'
        this.handleMultipleReload(docids)
        break
      case 7:
        requestObj.RELOAD = ''
        this.handleMultipleReload(docids)
        break
      case 8:
        this.handlePrintMultiple(docids)
        break
      case 9:
        this.handleMultipleRerun(docids, RerunDialogTypes.ITEM_FORCE_AUTO_PRINT)
        break
      case 10:
        this.handleMultipleRerun(docids, RerunDialogTypes.ITEM_BUNDLE)
        break
      default: break
    }
  }

  handleMultipleRerun = (docidsArr, type) => {
    const { getDocument } = this.props
    Promise.all(docidsArr.map(docid => {
      return getDocument(undefined, docid, '')
        .then(res => {
          return [docid, res]
        })
    }))
      .then(([...docs]) => {
        let buffer = {}
        docs.forEach(doc => {
          buffer = { ...buffer, [doc[0]]: doc[1] }
        })
        this.setState({ documentsToPrint: buffer, showRerunDialogMultiple: true, rerunType: type })
      })
  }

  handlePrintMultiple = docidsArr => {
    const { getDocument, getPrintInfo } = this.props
    Promise.all([
      Promise.all(docidsArr.map(docid => {
        return getPrintInfo(docid)
          .then(res => {
            return [docid, res]
          })
      })),
      Promise.all(docidsArr.map(docid => {
        return getDocument(undefined, docid, '')
          .then(res => {
            return [docid, res]
          })
      }))
    ])
      .then(([...res]) => {
        const printinfos = res[0]
        const docinfos = res[1]
        let buffer = {}
        for (let i = 0; i < printinfos.length; i++) {
          if (!printinfos[i][1][0].error) {
            buffer = { ...buffer, [printinfos[i][0]]: { printInfo: printinfos[i][1], docInfo: docinfos[i][1] } }
          }
        }
        if (Object.values(buffer).length > 0) {
          this.setState({ documentsToPrint: buffer, showPrintDialogMultiple: true })
        }
      })
  }

  handleMultipleReload = docidsArr => {
    const { getDocument } = this.props
    Promise.all(docidsArr.map(docid => {
      return getDocument(undefined, docid, '')
        .then(res => {
          return [docid, res]
        })
    }))
      .then(([...docs]) => {
        let buffer = {}
        docs.forEach(doc => {
          buffer = { ...buffer, [doc[0]]: doc[1] }
        })
        this.setState({ documentsToReload: buffer, showReloadDialogMultiple: true })
      })
  }


  /**
   * @description un-/marks the archive or delete status, unmarks the reload status
   * @param {Number} index the index of the current row
   * @param {String} markedParam the header which should be used to update the ARCHIVE, DELETE or RELOAD
   * possible: 'LGRFFARC', 'LGRFFDEL', 'LGRFFREL'
   * LGRFFREL is only used to unmark reload
   */
  updateMark = (index, markedParam) => {
    const { documents, getDocument } = this.props
    const currentLtoken = documents.data[index][this.headerIndex('LTOKEN')]
    const currentRtoken = documents.data[index][this.headerIndex('RTOKEN')]
    const currentDocid = currentLtoken + currentRtoken

    getDocument(undefined, currentDocid, '', () => this.setState({ parameterToBeUpdated: markedParam }))
  }

  getPreferenceKey = () => {
    const { customDialog } = this.props
    if (customDialog) {
      if (UserUtils.isDOCX()) {
        return Preferences.TABLE_SETTINGS_CUSTOMSELECTION_DOCUMENTS
      }
      else if (UserUtils.isLOGX()) {
        if (customDialog[7] === 'BRWTAB') {
          return Preferences.TABLE_SETTINGS_CUSTOMSELECTION_LOGS
        }
        else if (customDialog[7] === 'BRWUC4') {
          return Preferences.TABLE_SETTINGS_CUSTOMSELECTION_UC4
        }
        else if (customDialog[7] === 'BRWZOS') {
          return Preferences.TABLE_SETTINGS_CUSTOMSELECTION_ZOS
        }
        else if (customDialog[7] === 'BRWCTM') {
          return Preferences.TABLE_SETTINGS_CUSTOMSELECTION_CONTROLM
        }
        else if (customDialog[7] === 'BRWSYSL') {
          return Preferences.TABLE_SETTINGS_CUSTOMSELECTION_SYSLOG
        }
        else if (customDialog[7] === 'BRWSTB') {
          return Preferences.TABLE_SETTINGS_CUSTOMSELECTION_STONEBRANCH
        }
      }
    }
    return undefined
  }

  getPreferenceToUse = () => {
    const { customDialog, resultTable, preferences } = this.props
    if (customDialog) {
      const hasResultTable = resultTable?.OBJECTS && resultTable.OBJECTS.filter(d => d.SLITSEL === '*').length > 0 && resultTable.SLTINAME
      if (hasResultTable) {
        const allResultTablePrefs = preferences[Preferences.TABLE_SETTINGS_CUSTOMSELECTION_RESULTTABLES]
        const resultTableEntries = resultTable.OBJECTS.filter(d => d.SLITSEL === '*').map(d => d.SLINAME.toUpperCase())
        const specificResultTablePrefs = allResultTablePrefs ? allResultTablePrefs[resultTable.SLTINAME] : []
        if (allResultTablePrefs && allResultTablePrefs[resultTable.SLTINAME] && this.resultTablesMatchesPrefs([...specificResultTablePrefs.displayedHeaders, ...specificResultTablePrefs.hiddenHeaders], resultTableEntries)) {
          return specificResultTablePrefs
        }
      }
      else if (UserUtils.isDOCX()) {
        return preferences[Preferences.TABLE_SETTINGS_CUSTOMSELECTION_DOCUMENTS]
      }
      else if (UserUtils.isLOGX()) {
        if (customDialog[7] === 'BRWTAB') {
          return preferences[Preferences.TABLE_SETTINGS_CUSTOMSELECTION_LOGS]
        }
        else if (customDialog[7] === 'BRWUC4') {
          return preferences[Preferences.TABLE_SETTINGS_CUSTOMSELECTION_UC4]
        }
        else if (customDialog[7] === 'BRWZOS') {
          return preferences[Preferences.TABLE_SETTINGS_CUSTOMSELECTION_ZOS]
        }
        else if (customDialog[7] === 'BRWCTM') {
          return preferences[Preferences.TABLE_SETTINGS_CUSTOMSELECTION_CONTROLM]
        }
        else if (customDialog[7] === 'BRWSYSL') {
          return preferences[Preferences.TABLE_SETTINGS_CUSTOMSELECTION_SYSLOG]
        }
        else if (customDialog[7] === 'BRWSTB') {
          return preferences[Preferences.TABLE_SETTINGS_CUSTOMSELECTION_STONEBRANCH]
        }
      }
    }
    return undefined
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    const preferenceToUse = this.getPreferenceToUse()
    if (preferenceToUse) {
      return preferenceToUse.fillPage
    }
    else {
      return true
    }
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    const { preferences, resultTable } = this.props

    if (!resultTable || !resultTable.OBJECTS) {
      const preferenceToUse = this.getPreferenceToUse()
      if (preferenceToUse && header) {
        let buffer = []
        preferenceToUse.displayedHeaders.forEach(d => {
          // fallback if old preferences saved the columns as language keys and rest keys
          for (let i = 0; i < header.length; i++) {
            if (header[i].rest === d || header[i].translation === d) {
              buffer.push(header[i].rest)
              break
            }
          }
        })
        return buffer
      }
    }

    if (resultTable && header) {
      const allResultTablePrefs = preferences[Preferences.TABLE_SETTINGS_CUSTOMSELECTION_RESULTTABLES]
      const resultTableEntries = resultTable.OBJECTS ? resultTable.OBJECTS.filter(d => d.SLITSEL === '*').map(d => d.SLINAME.toUpperCase()) : []
      const specificResultTablePrefs = allResultTablePrefs ? allResultTablePrefs[resultTable.SLTINAME] : []
      if (allResultTablePrefs && allResultTablePrefs[resultTable.SLTINAME] && this.resultTablesMatchesPrefs([...specificResultTablePrefs.displayedHeaders, ...specificResultTablePrefs.hiddenHeaders], resultTableEntries)) {
        let buffer = []
        specificResultTablePrefs.displayedHeaders.forEach(d => {
          for (let i = 0; i < header.length; i++) {
            if (header[i].rest === d) {
              buffer.push(header[i].rest)
              break
            }
          }
        })
        return buffer
      }
      return this.getDefaultHeader()
    }

    return this.getDefaultHeader()
  }

  /**
   * @description Checks if the stored table settings matches the result table columns. Order does not matter.
   * @param {Array} resultTableEntries Array of REST-API keys for the result tabel columns.
   * @param {Array} prefsEntries Array of REST-API keys for the columns which are stored in the preferences.
   */
  resultTablesMatchesPrefs = (resultTableEntries, prefsEntries) => {
    if (prefsEntries.length !== resultTableEntries.length) {
      return false
    }
    return prefsEntries.every(d => resultTableEntries.includes(d))
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const { resultTable, customDialog } = this.props
    const buffer = []
    if (header) {
      if (customDialog && resultTable?.OBJECTS && resultTable?.SLTINAME === customDialog[3]) {
        header.forEach(h => buffer.push(h.rest))
      }
      else {
        header.filter(h => h.default).forEach(h => buffer.push(h.rest))
      }
    }
    return buffer
  }


  /**
   * @description Gets the translated headers.
   * @param {Array} choosenheaders The choosen headers which are not translated.
   * @returns {Array} The translated choosen headers.
   */
  getTranslatedHeaders = choosenheaders => {
    let filteredHeaders = []
    choosenheaders.forEach(h => {
      const temp = this.state.header.filter(entry => entry.rest === h)
      if (temp.length > 0) {
        filteredHeaders.push(temp[0])
      }
    })

    return filteredHeaders.map(entry => {
      let key = entry.translation
      if (key) {
        return translate(key)
      }
      return entry.translated || entry.rest
    })
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to show.
   * @param {Array} header The headers of the table.
   * @returns {Array} The column sort definitions.
   */
  getColumnSortDefs = (data, header) => {
    return SortUtils.getSortTypes(data, header.length)
  }

  createTableRowAction = index => {
    const { customDialog } = this.props
    if (!customDialog) return

    if (UserUtils.isDOCX() || (UserUtils.isLOGX() && customDialog[7] === 'BRWTAB')) {
      this.handleShowDocumentInformation(index)
    }

    if (UserUtils.isLOGX() && customDialog[7] === 'BRWUC4') {
      this.getUC4Details(index)
    }

    if (UserUtils.isLOGX() && customDialog[7] === 'BRWZOS') {
      this.getZosDetails(index)
    }

    if (UserUtils.isLOGX() && customDialog[7] === 'BRWCTM') {
      this.getCtmDetails(index)
    }

    if (UserUtils.isLOGX() && customDialog[7] === 'BRWSYSL') {
      this.getSyslogDetails(index)
    }

    if (UserUtils.isLOGX() && customDialog[7] === 'BRWSTB') {
      this.handleDisplayStonebranchJobInformation(index)
    }
  }

  /**
   * @description Handles the show document information action.
   * @param {Number} rowIndex The current row.
   */
  handleShowDocumentInformation = rowIndex => {
    const { documents } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + documents.data[rowIndex][this.headerIndex('RTOKEN')]
    const callback = () => { this.setState({ showDocumentInformationDialog: true }) }
    this.props.getDocument(undefined, docid, '', callback)
  }

  /**
   * @description Handles the open print document dialog action.
   * @param {Number} rowIndex The current row.
   */
  handleShowDocumentPrint = rowIndex => {
    const { documents, getDocument, getPrintInfo } = this.props
    let docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + documents.data[rowIndex][this.headerIndex('RTOKEN')]
    docid = docid.length === 32 ? docid : docid + '0000000000000000'
    getDocument(undefined, docid, '', () => {
      getPrintInfo(docid, () => this.setState({ showPrintDialog: true }))
    })
  }

  createActionButtons = rowIndex => {
    const { customDialog } = this.props
    if (customDialog) {
      if (UserUtils.isDOCX() || (UserUtils.isLOGX() && customDialog[7] === 'BRWTAB')) {
        return this.createActionButtonsDocument(rowIndex)
      }
      else if (UserUtils.isLOGX()) {
        if (customDialog[7] === 'BRWUC4') {
          return this.createActionButtonsUC4(rowIndex)
        }
        else if (customDialog[7] === 'BRWZOS') {
          return this.createActionButtonsZOS(rowIndex)
        }
        else if (customDialog[7] === 'BRWCTM') {
          return this.createActionButtonsControlM(rowIndex)
        }
        else if (customDialog[7] === 'BRWSYSL') {
          return this.createActionButtonsSyslog(rowIndex)
        }
        else if (customDialog[7] === 'BRWSTB') {
          return this.createActionButtonsStonebranch(rowIndex)
        }
      }
    }
    return []
  }

  /**
   * @description Handles the download functionality. Opens an additional modal, if download settigns  are available for a specific mimetype.
   * @param {Number} rowIndex The current row.
   * @param {String} mimetype The mimetype of the specific doucment.
   */
  handleDocumentDownloadText = (rowIndex, mimetype) => {
    const { showDownloadDialog } = this.state
    if (!showDownloadDialog && ['*.afp', '*.pcl', '*.pjl'].includes(mimetype)) {
      return this.openDownloadTextDialog(rowIndex)
    }
    return this.executeDownloadTextDirectly(rowIndex, mimetype)
  }

  executeDownloadTextDirectly = (rowIndex, mimetype) => {
    const { documents, downloadDocumentText } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    const docidFilename = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '')
    const form = documents.data[rowIndex][this.headerIndex('FORM')]
    const ext = documents.data[rowIndex][this.headerIndex('EXT')]
    let doctype = documents.data[rowIndex][this.headerIndex('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }
    const fileExtension = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[mimetype]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, docidFilename, doctype, fileExtension].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadDocumentText(docid, filename, mimetypeTranslation)
  }

  executeDownloadTextFromDialog = (showNOP, showTLE, showPJL) => {
    const { documents, downloadDocumentText } = this.props
    const { selectedRow, selectedMimeType } = this.state
    const docid = documents.data[selectedRow][this.headerIndex('LTOKEN')] + (documents.data[selectedRow][this.headerIndex('RTOKEN')] || '0000000000000000')
    const docidFilename = documents.data[selectedRow][this.headerIndex('LTOKEN')] + (documents.data[selectedRow][this.headerIndex('RTOKEN')] || '')
    const form = documents.data[selectedRow][this.headerIndex('FORM')]
    const ext = documents.data[selectedRow][this.headerIndex('EXT')]
    let doctype = documents.data[selectedRow][this.headerIndex('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }
    const fileExtension = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[selectedMimeType]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, docidFilename, doctype, fileExtension].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadDocumentText(docid, filename, mimetypeTranslation, showNOP, showTLE, showPJL, () => this.setState({
      showDownloadDialog: false,
      selectedRow: undefined,
      selectedMimeType: undefined,
      selectedDownloadType: undefined
    }))
  }

  openDownloadTextDialog = rowIndex => {
    const { documents } = this.props
    this.setState({ showDownloadDialog: true, selectedRow: rowIndex, selectedMimeType: documents.data[rowIndex][this.headerIndex('SRCJOBI')], selectedDownloadType: 'TEXT' })
  }

  /**
   * @description Handles the download functionality of documents in binary format.
   * @param displayMode
   * @param showPageHeader
   * @param showResources
   */
  handleDocumentDownloadHex = (displayMode, showPageHeader, showResources) => {
    const { selectedRow } = this.state
    const { documents, downloadDocumentHex } = this.props
    const docid = documents.data[selectedRow][this.headerIndex('LTOKEN')] + (documents.data[selectedRow][this.headerIndex('RTOKEN')] || '0000000000000000')
    const docidFilename = documents.data[selectedRow][this.headerIndex('LTOKEN')] + (documents.data[selectedRow][this.headerIndex('RTOKEN')] || '')

    const form = documents.data[selectedRow][this.headerIndex('FORM')]
    const ext = documents.data[selectedRow][this.headerIndex('EXT')]
    let doctype = documents.data[selectedRow][this.headerIndex('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }
    let fileExtension = 'hex'

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, docidFilename, doctype, fileExtension].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    downloadDocumentHex(docid, filename, displayMode, showPageHeader, showResources, () => this.setState({
      showDownloadDialog: false,
      selectedRow: undefined,
      selectedMimeType: undefined,
      selectedDownloadType: undefined
    }))
  }

  handleShowDocumentDownloadHexDialog = rowIndex => {
    const { documents } = this.props
    this.setState({ showDownloadDialog: true, selectedRow: rowIndex, selectedMimeType: documents.data[rowIndex][this.headerIndex('SRCJOBI')], selectedDownloadType: 'HEX' })
  }

  handleDocumentDownloadSideFile(rowIndex) {
    const { documents } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '0000000000000000')
    const docidFilename = documents.data[rowIndex][this.headerIndex('LTOKEN')] + (documents.data[rowIndex][this.headerIndex('RTOKEN')] || '')
    const form = documents.data[rowIndex][this.headerIndex('FORM')]
    const ext = documents.data[rowIndex][this.headerIndex('EXT')]
    let doctype = documents.data[rowIndex][this.headerIndex('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }
    let fileExtension = 'idx'
    const filename = [form, ext, docidFilename, doctype, fileExtension].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')
    this.props.downloadDocumentSideFile(docid, filename)
  }

  /**
   * @description Handles the rerun action.
   * @param {Number} rowIndex The current row.
   * @param {String|undefined} type The type of rerun. Use constants of RerunDialog!
   */
  handleRerun = (rowIndex, type = undefined) => {
    const { documents } = this.props
    const docid = documents.data[rowIndex][this.headerIndex('LTOKEN')] + documents.data[rowIndex][this.headerIndex('RTOKEN')]
    const callback = () => { this.setState({ showRerunDialog: true, rerunType: type }) }
    this.props.getDocument(undefined, docid, '', callback)
  }


  showRecipients = index => {
    const { documents } = this.props
    const header = documents.header
    const data = documents.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
      { rest: 'REPORT', ui: 'report' },
      { rest: 'WREPORT', ui: 'dynamicReport' },
    ]
    // fake the state like its used in assignment index document to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
      report: data[header.indexOf('DREPORT')],
      dynamicReport: data[header.indexOf('WREPORT')]
    }
    // builds the parameter object which is used for url
    const urlToPush = UserUtils.isDOCX()
      ? `/assignment/recipient_document${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
      : `/assignment/recipient_log${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Handles the open search by index dialog action.
   * @param {Number} rowIndex The current row.
   */
  handleShowSearchByIndex = rowIndex => {
    const { documents } = this.props
    const doc = documents.data[rowIndex]
    const docObj = convertIntoObject(documents.header, doc);
    this.setState({ searchByIndexDialogData: docObj, showSearchByIndexDialog: true })
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} index The index of the current row.
   */
  createActionButtonsDocument = index => {
    const { id, documents, userprofile } = this.props
    const rerunAutoprintAllowed = userprofile === undefined || userprofile?.VCIRAUP
    const rerunBundleRequestAllowed = userprofile === undefined || userprofile?.VCIRBUN
    const modifyReloadAllowed = userprofile === undefined || userprofile?.VCIREL
    const modifyArchiveAllowed = userprofile === undefined || userprofile?.VCIARC
    const modifyDeleteAllowed = userprofile === undefined || userprofile?.VCIDEL
    const mimetype = index !== undefined ? documents?.data[index][this.headerIndex('SRCJOBI')] : undefined
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${index}`}
        iconType='material'
        iconName='info'
        title={translate('general.information')}
        onClick={() => this.handleShowDocumentInformation(index)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${index}`}
        iconType='material'
        iconName='document'
        title={translate('general.display')}
        onClick={() => { this.openDocumentViewerStandardSelection(index) }}
      />,
      <TableButtonGroup
        id={`${id}_tableButtonMore_${index}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleShowDocumentInformation(index)}
          id={`${id}_infoBtn`}
          icon={<Icon id={'show_doc_icon'} name='info' className='actionIcon' />}
          text={translate('general.information')}
          title={translate('general.information')}
        />
        <TableButtonGroupItem
          id={`${id}_displayBtn`}
          icon={<Icon id={'display_beta_view_icon'} name='document' className='actionIcon' />}
          text={translate('general.display_in_beta_view')}
          title={translate('general.display_in_beta_view')}
          onClick={() => { this.handleDisplayDocument(index) }}
        />
        <TableButtonGroupItem
          onClick={() => this.handleShowDocumentPrint(index)}
          id={`${id}_printBtn`}
          icon={<Icon id={'show_print_icon'} name='print' className='actionIcon' />}
          text={translate('documentprint.print')}
          title={translate('documentprint.print')}
        />
        { documents?.data[index]?.[this.headerIndex('LGRINDEX')] === 'YES' &&
        <TableButtonGroupItem
          onClick={() => this.handleShowSearchByIndex(index)}
          id={`${id}_serachByIndex`}
          icon={<Icon name='search' className='actionIcon' />}
          text={translate('general.search_by_index')}
          title={translate('general.search_by_index')}
        />
        }
        <TableButtonGroupGroup id={`${id}_download_group_btn`} text={translate('general.download')}>
          <TableButtonGroupItem
            onClick={() => this.handleDocumentDownload(index)}
            id={`${id}_displayBtn`}
            icon={<Icon id={'download_icon'} name='download' className='actionIcon' />}
            text={translate('general.download')}
            title={translate('general.download')}
          />
          {
            ['*.ps', '*.pdf', '*.afp', '*.pcl', '*.pjl'].includes(mimetype) &&
            <TableButtonGroupItem
              onClick={() => this.handleDocumentDownloadText(index, mimetype)}
              id={`${id}_downloadTextBtn`}
              icon={<Icon id={'download_text_icon'} name='download' className={'actionIcon'} />}
              text={translate('general.download_as_text')}
              title={translate('general.download_as_text')}
            />
          }
          <TableButtonGroupItem
            onClick={() => this.handleShowDocumentDownloadHexDialog(index)}
            id={`${id}_downloadHexBtn`}
            icon={<Icon id={'download_hex_icon'} name='download' className={'actionIcon'} />}
            text={translate('standard_selection.download_as_hex')}
            title={translate('standard_selection.download_as_hex')}
          />
        </TableButtonGroupGroup>
        {index !== undefined && documents?.data[index][this.headerIndex('EIXPAGES')] > 0 && (
          <>
            <TableButtonGroupSeparator id={id}/>
            <TableButtonGroupGroup id={`${id}_sidefile_group_btn`} text={translate('standard_selection.sidefile')}>
              <TableButtonGroupItem
                id={`${id}_btn_display_docviewer_${index}`}
                iconType='material'
                icon={<Icon name='document' className='actionIcon'/>}
                text={translate('general.display')}
                title={translate('general.display')}
                onClick={() => this.openDocumentViewerStandardSelectionSideFile(index)}
              />
              <TableButtonGroupItem
                onClick={() => this.handleDocumentDownloadSideFile(index)}
                id={`${id}_btn_download_side_file`}
                icon={<Icon name='download' className={'actionIcon'}/>}
                text={translate('standard_selection.download_as_side_file')}
                title={translate('standard_selection.download_as_side_file')}
              />
            </TableButtonGroupGroup>
          </>
        )
        }
        {
          (rerunAutoprintAllowed || rerunBundleRequestAllowed) &&
          <TableButtonGroupSeparator id={id} />
        }
        {
          (rerunAutoprintAllowed || (rerunBundleRequestAllowed && UserUtils.isDOCX())) &&
          <TableButtonGroupGroup id={`${id}_rerun_group_btn`} text={translate('queue.rerun')}>
            {rerunAutoprintAllowed &&
              <>
                <TableButtonGroupItem
                  onClick={() => { this.handleRerun(index) }}
                  id={`${id}_rerunBtn`}
                  icon={<Icon id={'rerun_icon'} name='rerun' className='actionIcon' />}
                  text={translate('queue.rerun')}
                  title={translate('queue.rerun')}
                />
                <TableButtonGroupItem
                  onClick={() => { this.handleRerun(index, RerunDialogTypes.ITEM_FORCE_AUTO_PRINT) }}
                  id={`${id}_rerunAutoprintBtn`}
                  icon={<Icon id={'rerun_force_icon'} name='rerun' className='actionIcon' />}
                  text={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
                  title={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
                />
              </>
            }
            {
              UserUtils.isDOCX() &&
              rerunBundleRequestAllowed &&
              <TableButtonGroupItem
                onClick={() => { this.handleRerun(index, RerunDialogTypes.ITEM_BUNDLE) }}
                id={`${id}_rerunBundleRequestBtn`}
                icon={<Icon id={'rerun_bundle_icon'} name='rerun' className='actionIcon' />}
                text={translate('usermanagement.userprofile_basic_tab.rerun_bundle_request')}
                title={translate('usermanagement.userprofile_basic_tab.rerun_bundle_request')}
              />
            }
          </TableButtonGroupGroup>
        }
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => this.showRecipients(index)}
          id={`${id}_show_recipients_btn`}
          icon={<Icon id={'recipients_icon'} name='group' className='actionIcon' />}
          text={translate('general.display_recipients')}
          title={translate('general.display_recipients')}
        />
        <TableButtonGroupSeparator id={id} />
        { /* check if documents.data[index] is not undefined is necessary, because redux updates the data faster than datatable, so datatable uses this function with old index, and we get an error, when fewer rows are in the new data (no proof, just a theory) */}
        {modifyDeleteAllowed && (documents?.data[index] && documents?.data[index][this.headerIndex('LGRONLNE')] === 'YES' && (
          documents?.data[index] && documents?.data[index][this.headerIndex('LGRFFDEL')] === ''
            ? <TableButtonGroupItem
              onClick={() => { this.updateMark(index, 'LGRFFDEL') }}
              id={`${id}_markDeleteBtn`}
              icon={<Icon id={'delete_icon'} name='delete' className='actionIcon' />}
              text={translate('general.mark_delete')}
              title={translate('general.mark_delete')}
            />
            : <TableButtonGroupItem
              onClick={() => { this.updateMark(index, 'LGRFFDEL') }}
              id={`${id}_unmarkDeleteBtn`}
              icon={<Icon id={'close_icon'} name='close' className='actionIcon' />}
              text={translate('general.unmark_delete')}
              title={translate('general.unmark_delete')}
            />))}
        {modifyArchiveAllowed && (documents?.data[index] && (
          documents?.data[index][this.headerIndex('LGRONLNE')] === 'YES' && documents.data[index][this.headerIndex('LGRARCH')] === 'NO' && (
            documents?.data[index][this.headerIndex('LGRFFARC')] === ''
              ? <TableButtonGroupItem
                onClick={() => this.updateMark(index, 'LGRFFARC')}
                id={`${id}_markArchiveBtn`}
                icon={<Icon id={'archive_icon'} name='archive' className='actionIcon' />}
                text={translate('general.mark_archive')}
                title={translate('general.mark_archive')}
              />
              : <TableButtonGroupItem
                onClick={() => this.updateMark(index, 'LGRFFARC')}
                id={`${id}_unmarkArchiveBtn`}
                icon={<Icon id={'unarchive_icon'} name='unarchive' className='actionIcon' />}
                text={translate('general.unmark_archive')}
                title={translate('general.unmark_archive')}
              />)))}
        {modifyReloadAllowed && (documents?.data[index] && (
          documents?.data[index][this.headerIndex('LGRONLNE')] === 'NO' &&
          documents?.data[index][this.headerIndex('LGRARCH')] === 'YES' && (
            documents?.data[index][this.headerIndex('LGRFFREL')] === ''
              ? <TableButtonGroupItem
                onClick={() => { this.handleReload(index) }}
                id={`${id}_markReloadBtn`}
                icon={<Icon id={'reload_icon'} name='reload' className='actionIcon' />}
                text={translate('general.mark_reload')}
                title={translate('general.mark_reload')}
              />
              : <TableButtonGroupItem
                onClick={() => { this.updateMark(index, 'LGRFFREL') }}
                id={`${id}_unmarkReloadBtn`}
                icon={<Icon id={'cancel_reload_icon'} name='cancel_reload' className='actionIcon' />}
                text={translate('general.unmark_reload')}
                title={translate('general.unmark_reload')}
              />)))}
        {documents?.data[index] && (
          documents?.data[index][this.headerIndex('LGRARCH')] === 'YES' &&
          <TableButtonGroupItem
            onClick={() => { this.handleDeleteFromArchive(index) }}
            id={`${id}_delteFromArchiveBtn`}
            icon={<Icon id={'close_icon'} name='close' className='actionIcon' />}
            text={translate('general.delete_from_archive')}
            title={translate('general.delete_from_archive')}
          />)}
      </TableButtonGroup>
    ]
  }

  /**
   * @description Gets specific details of a job
   * @param {Number} index
   */
  getUC4Details = index => {
    const { getUC4JobDetails, uc4Jobs } = this.props
    const jobname = uc4Jobs.data[index][this.headerIndex('JOBNAME')]
    const jobObj = {
      LOGSOURCE: uc4Jobs.data[index][this.headerIndex('LOGSRC')],
      STARTDATE: uc4Jobs.data[index][this.headerIndex('STRTDATE')],
      STARTTIME: uc4Jobs.data[index][this.headerIndex('STRTTIME')],
      RUNNUMBER: uc4Jobs.data[index][this.headerIndex('RUNNUMB')],
      SYSTEMNAME: uc4Jobs.data[index][this.headerIndex('SYSNAME')],
    }
    getUC4JobDetails(jobObj, jobname, () => this.setState({ showUC4InformationDialog: true }))
  }

  /**
   * @description Handles the download functionality of logs in binary format.
   * @param {Number} rowIndex The current row.
   */
  handleUC4LogDownload = rowIndex => {
    const { uc4Jobs, downloadJobUC4 } = this.props
    const systemName = uc4Jobs.data[rowIndex][this.headerIndex('SYSNAME')]
    const runNumber = uc4Jobs.data[rowIndex][this.headerIndex('RUNNUMB')]
    let startDate = uc4Jobs.data[rowIndex][this.headerIndex('STRTDATE')]
    let startTime = uc4Jobs.data[rowIndex][this.headerIndex('STRTTIME')]
    const queryParams = {
      LOGSOURCE: 'UC4',
      STARTDATE: startDate,
      STARTTIME: startTime,
      RUNNUMBER: runNumber,
      SYSTEMNAME: systemName
    }

    // Replace all unwanted characters.
    startDate = startDate.replace(/[/.-]/g, '')
    startTime = startTime.replace(/:/g, '')

    // Generate name which will be suggested as download-filename
    const filename = [systemName, runNumber, startDate, startTime].filter(entry => entry !== undefined).join('.')
    downloadJobUC4(queryParams, filename)
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  // handleDisplayJobUC4 = rowIndex => {
  //   const { uc4Jobs, displayJobUC4, bwesystemname, bwebaseurl } = this.props
  //   const systemName = uc4Jobs.data[rowIndex][this.headerData('SYSNAME')]
  //   const runNumber = uc4Jobs.data[rowIndex][this.headerData('RUNNUMB')]
  //   const startDate = uc4Jobs.data[rowIndex][this.headerData('STRTDATE')]
  //   const startTime = uc4Jobs.data[rowIndex][this.headerData('STRTTIME')]
  //   const ukey = uc4Jobs.data[rowIndex][this.headerData('UKEY')]
  //   displayJobUC4(bwebaseurl, bwesystemname, startDate, startTime, systemName, runNumber, ukey)
  // }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} index The index of the current row.
   */
  createActionButtonsUC4 = index => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${index}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => this.getUC4Details(index)}
      />,
      <TableButton
        id={`${id}_tableButton_display_${index}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => this.openDocumentViewerUC4(index)}
      />,
      <TableButton
        id={`${id}_tableButton_download_${index}`}
        iconType={'material'}
        iconName={'download'}
        title={translate('general.download')}
        onClick={() => this.handleUC4LogDownload(index)}
      />,
    ]
  }

  /**
   * @description Gets specific details of a job
   * @param {Number} index
   */
  getZosDetails = index => {
    const { getZosJobDetails, zosJobs, getZosJobLogs } = this.props
    const jobObj = {
      LOGSOURCE: 'ZOSMIG',
      SRCSUBD: zosJobs.data[index][this.headerIndex('SRCSUBD')],
      SRCSUBT: zosJobs.data[index][this.headerIndex('SRCSUBT')],
      JOBNAME: zosJobs.data[index][this.headerIndex('JOBNAME')],
      JOBID: zosJobs.data[index][this.headerIndex('JOBID')]
    }
    getZosJobDetails(jobObj, () => {
      jobObj.LOGSOURCE = 'ZOS'
      getZosJobLogs(jobObj, () => this.setState({ showZOSInformationDialog: true }))
    })
  }

  /**
   * @description Handles the download functionality of logs in binary format.
   * @param {Number} rowIndex The current row.
   */
  handleJobDownload = rowIndex => {
    const { zosJobs, downloadJobZOS } = this.props
    const jobID = zosJobs.data[rowIndex][this.headerIndex('JOBID')]
    const jobName = zosJobs.data[rowIndex][this.headerIndex('JOBNAME')]
    let startDate = zosJobs.data[rowIndex][this.headerIndex('SRCSUBD')]
    let startTime = zosJobs.data[rowIndex][this.headerIndex('SRCSUBT')]
    const queryParams = {
      LOGSOURCE: 'ZOS',
      JOBID: jobID,
      JOBNAME: jobName,
      SRCSUBD: startDate,
      SRCSUBT: startTime,
    }

    // Replace all unwanted characters.
    startDate = startDate.replace(/[/.-]/g, '')
    startTime = startTime.replace(/:/g, '')

    // Generate name which will be suggested as download-filename
    const filename = [jobID, jobName, startDate, startTime].filter(entry => entry !== undefined).join('.')
    downloadJobZOS(queryParams, filename)
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  // handleDisplayJobZOS = rowIndex => {
  //   const { zosJobs, displayJobZOS, bwesystemname, bwebaseurl } = this.props
  //   const jobID = zosJobs.data[rowIndex][this.headerData('JOBID')]
  //   const jobName = zosJobs.data[rowIndex][this.headerData('JOBNAME')]
  //   const startDate = zosJobs.data[rowIndex][this.headerData('SRCSUBD')]
  //   const startTime = zosJobs.data[rowIndex][this.headerData('SRCSUBT')]
  //   const ukey = zosJobs.data[rowIndex][this.headerData('UKEY')]
  //   displayJobZOS(bwebaseurl, bwesystemname, startDate, startTime, jobName, jobID, ukey)
  // }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} index The index of the current row.
   */
  createActionButtonsZOS = index => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${index}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => this.getZosDetails(index)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${index}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => this.openDocumentViewerZOS(index)}
      />,
      <TableButton
        id={`${id}_tableButton_download_${index}`}
        iconType={'material'}
        iconName={'download'}
        title={translate('general.download')}
        onClick={() => this.handleJobDownload(index)}
      />
    ]
  }

  /**
  * @description Gets specific details of the job
  * @param {Number} rowIndex The current row.
  */
  getCtmDetails = rowIndex => {
    const { getCtmJobDetails, getControlMJobLogs, controlmJobs } = this.props

    const jobObj = {
      LOGSOURCE: controlmJobs.data[rowIndex][this.headerIndex('LOGSRC')],
      ORDERDATE: controlmJobs.data[rowIndex][this.headerIndex('ORDDATE')],
      ORDERID: controlmJobs.data[rowIndex][this.headerIndex('ORDERID')],
      RUNCOUNT: controlmJobs.data[rowIndex][this.headerIndex('RUNCOUNT')],
      DATACENTER: controlmJobs.data[rowIndex][this.headerIndex('DATACENT')]
    }

    getCtmJobDetails(jobObj, () => {
      if (jobObj.LOGSOURCE === 'CTMZOS') {
        const jobObj2 = {
          UKEY: controlmJobs.data[rowIndex][this.headerIndex('LINKUKEY')],
          ORDERDATE: controlmJobs.data[rowIndex][this.headerIndex('ORDDATE')],
          ORDERID: controlmJobs.data[rowIndex][this.headerIndex('ORDERID')],
          RUNCOUNT: controlmJobs.data[rowIndex][this.headerIndex('RUNCOUNT')],
          DATACENTER: controlmJobs.data[rowIndex][this.headerIndex('DATACENT')]
        }
        getCtmJobDetails(jobObj2, (data) => {
          const jobObjForLogs = {
            LOGSOURCE: 'ZOS',
            SRCSUBD: data['SRCSUBD'],
            SRCSUBT: data['SRCSUBT'],
            JOBNAME: data['JOBNAME'],
            JOBID: data['JOBID']
          }
          getControlMJobLogs(jobObjForLogs, () => {
            this.setState({ showCtmInformationDialog: rowIndex })
          })
        })
      } else {
        this.setState({ showCtmInformationDialog: rowIndex })
      }
    })
  }

  /**
   * @description Gets specific details of the job
   * @param {Number} index The current row
   */
  getSyslogDetails = index => {
    const { getSyslogJobDetails, getSyslogJobLogs, syslogJobs } = this.props
    const jobObj = {
      LOGSOURCE: syslogJobs.data[index][this.headerIndex('LOGSRC')],
      SRCSUBD: syslogJobs.data[index][this.headerIndex('SRCSUBD')],
      SRCSUBT: syslogJobs.data[index][this.headerIndex('SRCSUBT')],
      JOBNAME: syslogJobs.data[index][this.headerIndex('JOBNAME')],
      JOBID: syslogJobs.data[index][this.headerIndex('JOBID')]
    }
    getSyslogJobDetails(jobObj, () => {
      getSyslogJobLogs(jobObj, () => this.setState({ showSyslogInformationDialog: true }))
    })
  }

  // getStonebranchDetails = index => {
  //   const { getStonebranchJobDetails, stonebranchJobs } = this.props
  //   const jobObj = {
  //     [HEADERS.UKEY]: stonebranchJobs.data[index][this.headerData(HEADERS.UKEY)]
  //   }
  //   getStonebranchJobDetails(jobObj, () => {})
  // }

  /**
    * @description Handles the download functionality of logs in binary format.
    * @param {Number} rowIndex The current row.
    */
  handleJobDownloadControlM = rowIndex => {
    const { controlmJobs, downloadJobControlM } = this.props
    let orderDate = controlmJobs.data[rowIndex][this.headerIndex('ORDDATE')]
    const orderID = controlmJobs.data[rowIndex][this.headerIndex('ORDERID')]
    const runCount = controlmJobs.data[rowIndex][this.headerIndex('RUNCOUNT')]
    const datacenter = controlmJobs.data[rowIndex][this.headerIndex('DATACENT')]
    const queryParams = {
      LOGSOURCE: 'CTM',
      ORDERDATE: orderDate,
      ORDERID: orderID,
      RUNCOUNT: runCount,
      DATACENTER: datacenter,
    }

    // Replace all unwanted characters.
    orderDate = orderDate.replace(/[/.-]/g, '')

    // Generate name which will be suggested as download-filename
    const filename = [orderDate, orderID, runCount, datacenter].filter(entry => entry !== undefined).join('.') + '.log'
    downloadJobControlM(queryParams, filename)
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  // handleDisplayJobControlM = rowIndex => {
  //   const { controlmJobs, displayJobControlM, bwesystemname, bwebaseurl } = this.props
  //   const systemName = controlmJobs.data[rowIndex][this.headerData('SYSNAME')]
  //   const runNumber = controlmJobs.data[rowIndex][this.headerData('RUNNUMB')]
  //   const startDate = controlmJobs.data[rowIndex][this.headerData('STRTDATE')]
  //   const startTime = controlmJobs.data[rowIndex][this.headerData('STRTTIME')]
  //   const ukey = controlmJobs.data[rowIndex][this.headerData('UKEY')]
  //   displayJobControlM(bwebaseurl, bwesystemname, startDate, startTime, systemName, runNumber, ukey)
  // }

  /**
   * @description Creates the action buttons for the table of controlm data.
   * @param {Number} index The index of the current row.
   */
  createActionButtonsControlM = index => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${index}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => this.getCtmDetails(index)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${index}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => this.openDocumentViewerControlM(index)}
      />,
      <TableButton
        id={`${id}_tableButton_download_${index}`}
        iconType={'material'}
        iconName={'download'}
        title={translate('general.download')}
        onClick={() => this.handleJobDownloadControlM(index)}
      />
    ]
  }

  /**
   * @description Creates the action buttons for the table of syslog data.
   * @param {Number} index The index of the current row.
   */
  createActionButtonsSyslog = index => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${index}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => this.getSyslogDetails(index)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${index}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => this.openDocumentViewerSyslog(index)}
      />,
      <TableButton
        id={`${id}_tableButton_download_${index}`}
        iconType={'material'}
        iconName={'download'}
        title={translate('general.download')}
        onClick={() => this.handleJobDownloadSyslog(index)}
      />
    ]
  }

  handleStonebranchJobDownload = rowIndex => {
    const {stonebranchJobs, downloadJobStonebranch} = this.props
    const ukey = stonebranchJobs.data[rowIndex][this.headerIndex(HEADERS.UKEY)]
    const jobName = stonebranchJobs.data[rowIndex][this.headerIndex(HEADERS.JOBNAME)]

    const queryParams = {
      [HEADERS.UKEY]: ukey,
    }

    // Generate name which will be suggested as download-filename
    const filename = [jobName].filter(entry => entry !== undefined).join('.') + '.log'
    downloadJobStonebranch(queryParams, filename)
  }

  handleDisplayDocumentStonebranch = rowIndex => {
    const {displayDocumentStonebranch, stonebranchJobs, bwebaseurl, bwesystemname} = this.props
    const ukey = stonebranchJobs.data[rowIndex][this.headerIndex('UKEY')]
    displayDocumentStonebranch(bwebaseurl, bwesystemname, ukey)
  }

  handleDisplayStonebranchJobInformation = (rowIndex) => {
    const { getStonebranchJobDetails, getStonebranchJobLogs, stonebranchJobs } = this.props
    const jobObj = {
      UKEY: stonebranchJobs.data[rowIndex][this.headerIndex('UKEY')]
    }

    getStonebranchJobDetails(jobObj, () => {
      getStonebranchJobLogs(jobObj, () => {
        this.setState({ showStonebranchInformationDialog: true })
      })
    })
  }

  createActionButtonsStonebranch = index => {
    const {id} = this.props
    return [
      <TableButton
        id={`${id}_tableButtonInfo_${index}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => this.handleDisplayStonebranchJobInformation(index)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${index}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => this.openDocumentViewerStonebranch(index)}
      />,
      <TableButtonGroup
        id={`${id}_tableButtonMore_${index}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleDisplayStonebranchJobInformation(index)}
          id={`${id}_infoBtn`}
          icon={<Icon id={`${id}_info_icon`} name='info' className='actionIcon' />}
          text={translate('general.information')}
          title={translate('general.information')}
        />
        <TableButtonGroupItem
          id={`${id}_tableButtonDisplayInBetaView_${index}`}
          iconType='material'
          icon={<Icon id={`${id}_document_icon`} name='document' className='actionIcon' />}
          text={translate('general.display_in_beta_view')}
          title={translate('general.display_in_beta_view')}
          onClick={() => this.handleDisplayDocumentStonebranch(index)}
        />
        <TableButtonGroupItem
          id={`${id}_tableButton_download_${index}`}
          iconType={'material'}
          icon={<Icon id={`${id}_download_icon`} name='download' className='actionIcon' />}
          text={translate('general.download')}
          title={translate('general.download')}
          onClick={() => this.handleStonebranchJobDownload(index)}
        />
      </TableButtonGroup>
    ]
  }

  getDocsLogsData = () => {
    const { datemask, documents } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    documents?.data.forEach(element => {
      let dataBuffer = []
      usedHeader.forEach(header => {
        if (header === CREATIONDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerIndex('B93DATE')], element[this.headerIndex('B93TIME')]))
        }
        else if (header === LGRFFDEL) {
          dataBuffer.push(element[this.headerIndex('LGRFFDEL')] === 'PEND' ? translate('general.yes') : translate('general.no'))
        }
        else if (header === LGRFFARC) {
          dataBuffer.push(element[this.headerIndex('LGRFFARC')] === 'PEND' ? translate('general.yes') : translate('general.no'))
        }
        else if (header === LGRFFREL) {
          dataBuffer.push(element[this.headerIndex('LGRFFREL')] === 'PEND' ? translate('general.yes') : translate('general.no'))
        }
        else if (header === LGRARCH) {
          dataBuffer.push(element[this.headerIndex('LGRARCH')] === 'YES' ? translate('general.yes') : translate('general.no'))
        }
        else if (header === RTOKEN) {
          dataBuffer.push(element[this.headerIndex('RTOKEN')] || '0000000000000000')
        }
        else {
          const val = element[this.headerIndex(header)]?.toString() ?? ''
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(moment(`${val}:00:00`, DateUtils.PARSING_DATEMASK).format(`${datemask} ${DateUtils.TIME_DATEMASK_FULL_MILLISECONDS}`))
          }
          else if (val.length === 19 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm:ss')) {
            dataBuffer.push(moment(`${val}:00`, DateUtils.PARSING_DATEMASK).format(`${datemask} ${DateUtils.TIME_DATEMASK_FULL_MILLISECONDS}`))
          }
          else if (val.length === 22 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm:ss:SS')) {
            dataBuffer.push(moment(val, DateUtils.PARSING_DATEMASK).format(`${datemask} ${DateUtils.TIME_DATEMASK_FULL_MILLISECONDS}`))
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  getUC4Data = () => {
    const { datemask, uc4Jobs } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    uc4Jobs?.data && uc4Jobs.data.forEach(element => {
      let dataBuffer = []
      usedHeader.forEach(header => {
        if (header === ACTIVATION_DATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerIndex(ACTIVATION_DATE)]))
        }
        else if (header === ACTIVATION_TIME) {
          dataBuffer.push(element[this.headerIndex(ACTIVATION_TIME)])
        }
        else if (header === SRCSUBDT) {
          if (!!element[this.headerIndex(SRCSUBDT)]) {
            const [date, time] = element[this.headerIndex(SRCSUBDT)].split(' ')
            dataBuffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            dataBuffer.push(element[this.headerIndex(header)])
          }
        }
        else if (header === STRTDT) {
          if (!!element[this.headerIndex(STRTDT)]) {
            const [date, time] = element[this.headerIndex(STRTDT)].split(' ')
            dataBuffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            dataBuffer.push(element[this.headerIndex(header)])
          }
        }
        else if (header === ENDDT) {
          if (!!element[this.headerIndex(ENDDT)]) {
            const [date, time] = element[this.headerIndex(ENDDT)].split(' ')
            dataBuffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            dataBuffer.push(element[this.headerIndex(header)])
          }
        }
        else if (header === JOBERROR) {
          dataBuffer.push(element[this.headerIndex(JOBERROR)] === 'NO' ? translate('general.no') : translate('general.yes'))
        }
        else {
          dataBuffer.push(element[this.headerIndex(header)])
        }
      })
      data.push(dataBuffer)
    })
    return data
  }


  getZOSData = () => {
    const { datemask, zosJobs } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    zosJobs?.data && zosJobs.data.forEach(element => {
      let dataBuffer = []
      usedHeader.forEach(header => {
        if (header === ACTIVATION_DATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerIndex(ACTIVATION_DATE)]))
        }
        else if (header === ACTIVATION_TIME) {
          dataBuffer.push(element[this.headerIndex(ACTIVATION_TIME)])
        }
        else if (header === SRCSUBDT) {
          if (!!element[this.headerIndex(SRCSUBDT)]) {
            const [date, time] = element[this.headerIndex(SRCSUBDT)].split(' ')
            dataBuffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            dataBuffer.push(element[this.headerIndex(header)])
          }
        }
        else if (header === STRTDT) {
          if (!!element[this.headerIndex(STRTDT)]) {
            const [date, time] = element[this.headerIndex(STRTDT)].split(' ')
            dataBuffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            dataBuffer.push(element[this.headerIndex(header)])
          }
        }
        else if (header === ENDDT) {
          if (!!element[this.headerIndex(ENDDT)]) {
            const [date, time] = element[this.headerIndex(ENDDT)].split(' ')
            dataBuffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            dataBuffer.push(element[this.headerIndex(header)])
          }
        }
        else if (header === JOBERROR) {
          dataBuffer.push(element[this.headerIndex(JOBERROR)] === 'NO' ? translate('general.no') : translate('general.yes'))
        }
        else {
          dataBuffer.push(element[this.headerIndex(header)])
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  getControlMData = () => {
    const { datemask, controlmJobs } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    controlmJobs?.data && controlmJobs.data.forEach(element => {
      let dataBuffer = []
      usedHeader.forEach(header => {
        if (header === SRCSUBDT) {
          if (!!element[this.headerIndex(SRCSUBDT)]) {
            const [date, time] = element[this.headerIndex(SRCSUBDT)].split(' ')
            dataBuffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            dataBuffer.push(element[this.headerIndex(header)])
          }
        }
        else if (header === JOBERROR) {
          dataBuffer.push(element[this.headerIndex(JOBERROR)] === 'NO' ? translate('general.no') : translate('general.yes'))
        }
        else {
          dataBuffer.push(element[this.headerIndex(header)])
        }
      })
      data.push(dataBuffer)
    })
    return data
  }


  /**
    * @description Handles the download functionality of logs in binary format.
    * @param {Number} rowIndex The current row.
    */
  handleJobDownloadSyslog = rowIndex => {
    const { syslogJobs, downloadJobSyslog } = this.props
    const ukey = syslogJobs.data[rowIndex][this.headerIndex('UKEY')]
    const jobname = syslogJobs.data[rowIndex][this.headerIndex('JOBNAME')]
    const jobid = syslogJobs.data[rowIndex][this.headerIndex('JOBID')]
    const queryParams = {
      LOGSOURCE: 'SYSL',
      UKEY: ukey
    }

    // Generate name which will be suggested as download-filename
    const filename = [jobname, jobid].filter(entry => entry !== undefined).join('.') + '.log'
    downloadJobSyslog(queryParams, filename)
  }

  getSyslogData = () => {
    const { datemask, syslogJobs } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    syslogJobs?.data && syslogJobs.data.forEach(element => {
      let dataBuffer = []
      usedHeader.forEach(header => {
        if (header === SRCSUBDT) {
          if (!!element[this.headerIndex(SRCSUBDT)]) {
            const [date, time] = element[this.headerIndex(SRCSUBDT)].split(' ')
            dataBuffer.push(DateUtils.getDate(datemask, date, time))
          }
          else {
            dataBuffer.push(element[this.headerIndex(header)])
          }
        }
        else {
          dataBuffer.push(element[this.headerIndex(header)])
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  getStonebranchData() {
    const { stonebranchJobs } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    stonebranchJobs?.data && stonebranchJobs.data.forEach(element => {
      const dataBuffer = []
      usedHeader.forEach(header => {
        dataBuffer.push(element[this.headerIndex(header)])
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  // handleDisplayJobSyslog = rowIndex => {
  //   const { syslogJobs, displayJobSyslog, bwesystemname, bwebaseurl } = this.props
  //   const ukey = syslogJobs.data[rowIndex][this.headerData('UKEY')]
  //   displayJobSyslog(bwebaseurl, bwesystemname, ukey)
  // }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   * @returns {Array} The clean data.
   */
  getCleanData = () => {
    const { customDialog } = this.props
    if (UserUtils.isDOCX() || (UserUtils.isLOGX() && customDialog[7] === 'BRWTAB')) {
      return this.getDocsLogsData()
    }
    else if (UserUtils.isLOGX()) {
      if (customDialog[7] === 'BRWUC4') {
        return this.getUC4Data()
      }
      else if (customDialog[7] === 'BRWZOS') {
        return this.getZOSData()
      }
      else if (customDialog[7] === 'BRWCTM') {
        return this.getControlMData()
      }
      else if (customDialog[7] === 'BRWSYSL') {
        return this.getSyslogData()
      }
      else if (customDialog[7] === 'BRWSTB') {
        return this.getStonebranchData()
      }
    }
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader.
   * @returns {Array} The interaction buttons of the table above the headers.
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={() => this.handleRefresh(true)}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header ?? []}
          data={data}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  getDownloadItems = () => {
    const { customDialog } = this.props
    let result = [
      translate('general.mark_delete'),
      translate('general.unmark_delete'),
      translate('general.mark_archive'),
      translate('general.unmark_archive'),
      translate('general.mark_reload'),
      translate('general.unmark_reload'),
      translate('documentprint.print'),
      translate('usermanagement.userprofile_basic_tab.rerun_autoprint'),
      translate('usermanagement.userprofile_basic_tab.rerun_bundle_request')
    ]
    if (UserUtils.isDOCX()) {
      return result
    }
    else if (UserUtils.isLOGX() && customDialog?.[7] === 'BRWTAB') {
      result.splice(5, 1)
      return result
    }
    return undefined
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const {
      documents,
      document,
      id,
      drawerExpanded,
      autoDrawer,
      keepPagination,
      resultTable,
      customDialog,
      zosJobs,
      uc4Jobs,
      controlmJobs,
      printInfo,
      markReload,
      syslogJobs,
      stonebranchJobs
    } = this.props
    const {
      showTableSettingsDialog,
      showDocumentInformationDialog,
      showReloadDialog,
      showDeletefromArchiveDialog,
      showUC4InformationDialog,
      showZOSInformationDialog,
      showCtmInformationDialog,
      showStonebranchInformationDialog,
      showSyslogInformationDialog,
      showDownloadDialog,
      selectedMimeType,
      selectedDownloadType,
      showPrintDialog,
      showRerunDialog,
      documentsToPrint,
      showPrintDialogMultiple,
      showRerunDialogMultiple,
      showReloadDialogMultiple,
      showSearchByIndexDialog,
      searchByIndexDialogData,
      documentsToReload,
      checkedRows
    } = this.state
    const usedHeader = this.getUsedHeader()
    const fillPage = this.getFillPageInfo()
    let data = null
    if (customDialog) {
      if ((UserUtils.isDOCX() || (customDialog[7] === 'BRWTAB')) && documents) {
        data = documents.data && this.getCleanData()
      }
      else if (UserUtils.isLOGX()) {
        if (customDialog[7] === 'BRWUC4' && uc4Jobs) {
          data = uc4Jobs.data && this.getCleanData()
        }
        else if (customDialog[7] === 'BRWZOS' && zosJobs) {
          data = zosJobs.data && this.getCleanData()
        }
        else if (customDialog[7] === 'BRWCTM' && controlmJobs) {
          data = controlmJobs.data && this.getCleanData()
        }
        else if (customDialog[7] === 'BRWSYSL' && syslogJobs) {
          data = syslogJobs.data && this.getCleanData()
        }
        else if (customDialog[7] === 'BRWSTB' && stonebranchJobs) {
          data = stonebranchJobs.data && this.getCleanData()
        }
      }
    }

    return (
      <>
        {
          showDocumentInformationDialog &&
          <DocumentInformation
            id={id}
            onClose={() => { this.setState({ showDocumentInformationDialog: false }) }}
            document={document}
            refreshFunction={() => this.handleRefresh(true)}
          />
        }
        {
          showReloadDialog &&
          <MarkReloadDocumentDialog
            id={id}
            onClose={() => this.setState({ showReloadDialog: false })}
            document={document}
            markReload={(obj, callback, refreshFunction) => markReload(obj, callback, refreshFunction)}
            refreshFunction={() => this.handleRefresh(true)}
          />
        }
        {
          showReloadDialogMultiple &&
          <MarkReloadDocumentDialog
            id={id}
            onClose={() => this.setState({ showReloadDialogMultiple: false, docoumentsToReload: {} })}
            document={document}
            markReload={(obj, callback, refreshFunction) => markReload(obj, callback, refreshFunction)}
            documentsToReload={documentsToReload}
            refreshFunction={() => this.handleRefresh(true)}
          />
        }
        {
          showPrintDialog &&
          <DocumentPrint
            id={id}
            onClose={() => this.setState({ showPrintDialog: false })}
            document={document}
            printInfo={printInfo}
            refreshFunction={() => this.handleRefresh(true)}
          />
        }
        {
          showPrintDialogMultiple &&
          <DocumentPrint
            id={id}
            onClose={() => this.setState({ showPrintDialogMultiple: false, documentsToPrint: {} })}
            printInfo={printInfo}
            documentsToPrint={documentsToPrint}
          />
        }
        {
          showSearchByIndexDialog &&
          <SearchByIndexDialog
            id={`${id}_searchBYIndexDialog`}
            document={searchByIndexDialogData}
            onClose={() => this.setState({ searchByIndexDialogData: null, showSearchByIndexDialog: false })}
          />
        }
        {
          showRerunDialog &&
          <RerunDialog
            id={id}
            onClose={() => this.setState({ showRerunDialog: false })}
            type={this.state.rerunType}
            document={document}
            refreshFunction={() => this.handleRefresh(true)}
          />
        }
        {
          showRerunDialogMultiple &&
          <RerunDialog
            id={id}
            onClose={() => this.setState({ showRerunDialogMultiple: false, documentsToPrint: {} })}
            type={this.state.rerunType}
            documentsToPrint={documentsToPrint}
          />
        }
        {
          showDownloadDialog &&
          <DownloadDialog
            id={selectedDownloadType === 'TEXT' ? 'download_text_dialog' : 'download_hex_dialog'}
            onClose={() => this.setState({ showDownloadDialog: false, selectedRow: undefined, selectedMimeType: undefined, selectedDownloadType: undefined })}
            mimetype={selectedMimeType}
            download={selectedDownloadType === 'TEXT' ? this.executeDownloadTextFromDialog : this.handleDocumentDownloadHex}
            downloadType={selectedDownloadType}
            title={selectedDownloadType === 'TEXT' ? translate('general.download_as_text') : translate('standard_selection.download_as_hex')}
          />
        }
        {
          showDeletefromArchiveDialog &&
          <DeleteDialog
            id={`${id}_deletedocumentfromarchivedialog`}
            title={translate('general.delete_from_archive')}
            question={translate('search.question_delete_from_archive')}
            visible={showDeletefromArchiveDialog}
            onClose={() => { this.setState({ showDeletefromArchiveDialog: false }) }}
            onDelete={() => this.deleteFromArchive()}
          >
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_form`}>
                  {translate('general.form')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_form_value`}>
                  {document.FORM}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_extension`}>
                  {translate('general.extension')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_extension_value`}>
                  {document.EXT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_report`}>
                  {translate('general.report')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_report_value`}>
                  {document.WREPORT}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        }
        {showTableSettingsDialog &&
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: usedHeader, fillPage: fillPage, key: this.getPreferenceKey() }}
            resultTable={resultTable?.OBJECTS && resultTable.OBJECTS.filter(d => d.SLITSEL === '*').length > 0 && resultTable.SLTINAME}
          />
        }
        {showUC4InformationDialog &&
          <UC4jobInformationDialog
            id={`${id}_uc4jobinfo`}
            onClose={() => this.setState({ showUC4InformationDialog: false })}
          />
        }
        {showZOSInformationDialog &&
          <ZosJobInformationDialog
            id={`${id}_zosjobinfo`}
            onClose={() => this.setState({ showZOSInformationDialog: false })}
          />
        }
        {showCtmInformationDialog !== false &&
          <CtmJobInformationDialog
            id={`${id}_ctmjobinfo`}
            jobIndex={showCtmInformationDialog}
            onClose={() => this.setState({ showCtmInformationDialog: false })}
            controlmJobs={controlmJobs}
          />
        }
        {showStonebranchInformationDialog &&
          <StonebranchJobInformationDialog
            id={`${id}_stonebranchjobinfo`}
            onClose={() => this.setState({ showStonebranchInformationDialog: false })}
          />
        }
        {showSyslogInformationDialog &&
          <SyslogJobInformationDialog
            id={`${id}_syslogjobinfo`}
            onClose={() => this.setState({ showSyslogInformationDialog: false })}
          />
        }
        <SelectionTable
          id={this.props.id}
          headers={usedHeader}
          documents={{data, header: usedHeader}}
          keepPagination={keepPagination}
          fillPage={fillPage}
          createActionButtons={this.createActionButtons}
          createTableRowAction={this.createTableRowAction}
          additionalInteraction={this.createInteractionButtons(data, this.state.translatedHeaders)}
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}
          openDocViewerCallback={this.openSelectedRowsInDocViewer}
          checkedRows={checkedRows}
          checkedDropdownItems={this.getDownloadItems()}
          onCheckedDropdownAction={(checkedRows, index) => this.multiMarkAction(checkedRows, index)}
        />
      </>
    )
  }
}

const OFDOCID = 'OFDOCID'
const B93DATE = 'B93DATE'
const B93TIME = 'B93TIME'
const SORTDT = 'SORTDT'
const FORM = 'FORM'
const LGRCUSR = 'LGRCUSR'
const SRCJOBN = 'SRCJOBN'
const SRCJOBI = 'SRCJOBI'
const SRCPRCD = 'SRCPRCD'
const SRCSTPD = 'SRCSTPD'
const SRCDDND = 'SRCDDND'
const EXT = 'EXT'
const DREPORT = 'DREPORT'
const WREPORT = 'WREPORT'
const LTOKEN = 'LTOKEN'
const RTOKEN = 'RTOKEN'
const SMODE = 'SMODE'
const LTITLE = 'LTITLE'
const STATUS = 'STATUS'
const SRCPAGES = 'SRCPAGES'
const SRCLNCT = 'SRCLNCT'
const SRCMLRL = 'SRCMLRL'
const LGRONLNE = 'LGRONLNE'
const LGRARCH = 'LGRARCH'
const LGRAUTO = 'LGRAUTO'
const LGRFFARC = 'LGRFFARC'
const LGRFFDEL = 'LGRFFDEL'
const LGRFFREL = 'LGRFFREL'
const LGRINDEX = 'LGRINDEX'
const LGRXEROX = 'LGRXEROX'
const LGRAFP = 'LGRAFP'
const LGRTRC = 'LGRTRC'
const LGRASCII = 'LGRASCII'
const SRCRECFM = 'SRCRECFM'
const LGRMAXRL = 'LGRMAXRL'
const LREFD = 'LREFD'
const LGRNOTES = 'LGRNOTES'
const PRTSTAT = 'PRTSTAT'
const B93SDATE = 'B93SDATE'
const B93STIME = 'B93STIME'
const EIXPAGES = 'EIXPAGES'
const SPLPAGES = 'SPLPAGES'
const PBDNAME = 'PBDNAME'
const SRCSUBU = 'SRCSUBU'
const JOBRC = 'JOBRC'
const OWNER = 'OWNER'
const DOCUSR1 = 'DOCUSR1'
const DOCUSR2 = 'DOCUSR2'
const DOCUSR3 = 'DOCUSR3'
const DOCUSR4 = 'DOCUSR4'
const DOCUSR5 = 'DOCUSR5'
const DOCUSR6 = 'DOCUSR6'
const DOCUSR7 = 'DOCUSR7'
const DOCUSR8 = 'DOCUSR8'
const TEXTENCO = 'TEXTENCO'
const NLREFD = 'NLREFD'
const LGRSECIX = 'LGRSECIX'
const EDTSTAT = 'EDTSTAT'
const EDTTEXT = 'EDTTEXT'
const DOCSTAT = 'DOCSTAT'
const OUTSTAT = 'OUTSTAT'
const PENDING = 'PENDING'
const BUXORGDT = 'BUXORGDT'
const BUXINPDT = 'BUXINPDT'
const CREATIONDATE = 'CREATIONDATE'
const JOBNAME = 'JOBNAME'
const JOBSTAT = 'JOBSTAT'
// const SRCSUB = 'SRCSUB'
const ACTIVATION_DATE = 'SRCSUBD'
const ACTIVATION_TIME = 'SRCSUBT'
const JOBERROR = 'JOBERR'
const UC4STATUS = 'UC4STAT'
const LOGSOURCE = 'LOGSRC'
const CLIENT = 'CLNTNAME'
const RUNNUMBER = 'RUNNUMB'
const SYSTEM = 'SYSNAME'
const JOBGROUP = 'JOBGROUP'
const JOBID = 'JOBID'
const RETURNCODE = 'JOBRC'
const RUNUSER = 'RUNUSER'
const USR1NAM = 'USR1NAM'
const USR1VAL = 'USR1VAL'
const USR2NAM = 'USR2NAM'
const USR2VAL = 'USR2VAL'
const WORKFLOW = 'WORKFLOW'
const SRCVERS = 'SRCVERS'
const SERVICES = 'SERVICES'
const SYSID = 'SYSID'
const NETID = 'JNETID'
const CLASS = 'SRCCLAS'
const AGNTNAME = 'AGNTNAME'

const SRCSUBDT = 'SRCSUBDT'
const STRTDT = 'STRTDT'
const ENDDT = 'ENDDT'


const STRTDATE = 'STRTDATE'
const ENDDATE = 'ENDDATE'
const JOBERR = 'JOBERR'
const ORDDATE = 'ORDDATE'
const DATACENT = 'DATACENT'
const RUNCOUNT = 'RUNCOUNT'
const APPL = 'APPL'
const SUBAPPL = 'SUBAPPL'
const FILENAME = 'SRCDSN'

SearchResultCustomSearch.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    bwebaseurl: state.auth.serverdata.bwebaseurl,
    bwesystemname: state.auth.serverdata.bwesystemname,
    controlmJobs: state.search.customselection?.controlm?.controlmJobs,
    controlmJobDetails: state.search.controlm.controlmJobDetails,
    customDialog: state.customDialogs.customDialog,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    document: state.search.customselection.document,
    documents: state.search.customselection.documents,
    keepPagination: state.search.customselection.keepPagination,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    printInfo: state.search.customselection?.documentPrintInfo,
    resultTable: state.customDialogs.resultTable,
    syslogJobs: state.search.customselection?.syslog?.syslogJobs,
    syslogJobDetails: state.search.syslog.syslogJobDetails,
    stonebranchJobs: state.search.customselection?.stonebranchJobs,
    uc4Jobs: state.search.customselection?.uc4?.uc4Jobs,
    userid: state.auth.userid,
    userprofile: state.auth.serverdata.userprofile,
    usertoken: state.auth.serverdata.token,
    zosJobs: state.search.customselection?.zos?.zosJobs,
    zosJobDetails: state.search.zos.zosJobDetails
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getDocuments: ({ fields, searchParams, callback, keepPagination }) => {
      CustomSelectionActions.getDocuments({ fields, searchParams, callback, keepPagination })(dispatch)
    },
    getDocumentsStandardSelection: ({ fields, searchParams, callback }) => {
      StandardSelectionActions.getDocuments(fields, searchParams, callback)(dispatch)
    },
    getDocumentsByGlobalIndex: ({ fields, searchParams }) => {
      CustomSelectionActions.getDocumentsByGlobalIndex({ fields: fields, searchParams: searchParams })(dispatch)
    },
    getDocument: (fields, docid, process, callback) => {
      return CustomSelectionActions.getDocument(fields, docid, process, callback)(dispatch)
    },
    markArchive: (documentDefinition, callback, refreshFunction) => {
      CustomSelectionActions.markArchive(documentDefinition, callback, refreshFunction)(dispatch)
    },
    markDelete: (documentDefinition, callback, refreshFunction) => {
      CustomSelectionActions.markDelete(documentDefinition, callback, refreshFunction)(dispatch)
    },
    markReload: (documentDefinition, callback, refreshFunction) => {
      CustomSelectionActions.markReload(documentDefinition, callback, refreshFunction)(dispatch)
    },
    downloadDocument: (docid, downloadname, callback) => {
      CustomSelectionActions.downloadDocument(docid, downloadname, callback)(dispatch)
    },
    downloadDocumentSideFile: (docid, downloadname, displayMode, callback) => {
      CustomSelectionActions.downloadDocumentSideFile(docid, downloadname, displayMode, callback)(dispatch)
    },
    downloadDocumentText: (docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback) => {
      CustomSelectionActions.downloadDocumentText(docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback)(dispatch)
    },
    downloadDocumentHex: (docid, downloadname, displayMode, showPageHeader, showResources, callback) => {
      CustomSelectionActions.downloadDocumentHex(docid, downloadname, displayMode, showPageHeader, showResources, callback)(dispatch)
    },
    displayDocument: (docid, betaviewBaseurl, betaviewFavname, callback) => {
      CustomSelectionActions.displayDocument(docid, betaviewBaseurl, betaviewFavname, callback)(dispatch)
    },
    displayDocumentStonebranch: (bwebaseurl, bwesystemname, ukey) => SearchActions.displayJob(bwebaseurl, bwesystemname, undefined, undefined, undefined, undefined, ukey, undefined)(dispatch),
    getZosJobDetails: (jobObj, jobid, callback) => SearchZOSActions.getZosJobDetails(undefined, jobObj, jobid, callback)(dispatch),
    getZosJobLogs: (jobObj, callback) => SearchZOSActions.getZosJobLogs(jobObj, callback)(dispatch),
    getUC4JobDetails: (jobObj, jobname, callback) => SearchUC4Actions.getUC4JobDetails(undefined, jobObj, jobname, callback)(dispatch),
    getCtmJobDetails: (jobObj, callback) => SearchControlMActions.getCtmJobDetails(undefined, jobObj, callback)(dispatch),
    getStonebranchJobDetails: (jobObj, callback) => StonebranchActions.getStonebranchJobDetails(undefined, jobObj, callback)(dispatch),
    getStonebranchJobLogs: (jobObj, callback) => StonebranchActions.getStonebranchJobLogs(jobObj, callback)(dispatch),
    getSyslogJobDetails: (jobObj, callback) => SearchSyslogActions.getSyslogJobDetails(undefined, jobObj, callback)(dispatch),
    getSyslogJobLogs: (jobObj, callback) => SearchSyslogActions.getSyslogJobLogs(jobObj, callback)(dispatch),
    getZosJobs: (searchObj, callback) => CustomSelectionActions.getZosJobs(undefined, searchObj, callback)(dispatch),
    // downloadJobZOS: (queryParams, downloadname, callback) => SearchZOSActions.downloadJob(queryParams, downloadname, callback)(dispatch),
    getUC4Jobs: ({ searchObj, callback }) => CustomSelectionActions.getUC4Jobs({ searchObj: searchObj, callback: callback })(dispatch),
    getControlMJobs: (searchObj, callback) => CustomSelectionActions.getControlMJobs(undefined, searchObj, callback)(dispatch),
    getControlMJobLogs: (searchObj, callback) => SearchControlMActions.getControlMJobLogs(searchObj, callback)(dispatch),
    // downloadJobControlM: (queryParams, downloadname, callback) => SearchControlMActions.downloadJob(queryParams, downloadname, callback)(dispatch),
    downloadJobStonebranch: (queryParams, downloadname, callback) => StonebranchActions.downloadJob(queryParams, downloadname, callback)(dispatch),
    // displayJobControlM: (bwebaseurl, bwesystemname, startDate, startTime, systemName, runNumber, ukey) => SearchControlMActions.displayJob(bwebaseurl, bwesystemname, startDate, startTime, systemName, runNumber, ukey)(dispatch),
    getSyslogJobs: (searchObj, callback) => CustomSelectionActions.getSyslogJobs(undefined, searchObj, callback)(dispatch),
    downloadJobSyslog: (queryParams, downloadname, callback) => SearchSyslogActions.downloadJob(queryParams, downloadname, callback)(dispatch),
    displayJobSyslog: (betaviewBaseurl, betaviewFavname, ukey, callback) => SearchSyslogActions.displayJob(betaviewBaseurl, betaviewFavname, ukey, callback)(dispatch),
    getPrintInfo: (docids, callback) => CustomSelectionActions.getPrintInfo(docids, callback)(dispatch),
    // displayJobUC4: (betaviewBaseurl, betaviewFavname, sdate, stime, sysname, runnumber, ukey, callback) => {
    //   SearchUC4Actions.displayDocument(betaviewBaseurl, betaviewFavname, sdate, stime, sysname, runnumber, ukey, callback)(dispatch)
    // },
    // downloadJobUC4: (queryParams, downloadname, callback) => SearchUC4Actions.downloadLog(queryParams, downloadname, callback)(dispatch),
    // displayJobZOS: (betaviewBaseurl, betaviewFavname, sdate, stime, jobname, jobid, ukey, callback) => {
    //   SearchZOSActions.displayJob(betaviewBaseurl, betaviewFavname, sdate, stime, jobname, jobid, ukey, callback)(dispatch)
    // },
    updateMarkMulti: (updateObj, docInformation, callback, refreshFunction) => {
      CustomSelectionActions.updateMarkMulti(updateObj, docInformation, callback, refreshFunction)(dispatch)
    },
    addTabToDocViewer: (tabData, callback) => {
      DocViewerActions.addTabToDocViewer(tabData, callback)(dispatch)
    },
    cacheUpdatedSearchResults: (resultTableType) => CustomSelectionActions.cacheUpdatedSearchResults(resultTableType)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultCustomSearch))