export const TRANSLATIONS = {
  '00000030': 'Delete status now pending',
  '00000031': 'Delete status reset',
  '00000032': 'Archive status now pending',
  '00000033': 'Archive status reset',
  '00000034': 'Reload request queued',
  '00000035': 'Reload request removed',
  '00000036': 'User $0 inserted - $0 group connection(s) and $0 profile(s) copied',
  '00010001': 'Program $0 ended. Empty query string received.',
  '00010002': 'Program $0 ended. Invalid user token detected.',
  '00010003': 'Connection to BetaUX Server could not be established.',
  '00010004': 'Program $0 ended. Error during InitProgram',
  '00010005': 'Program $0 ended. Error selecting LGR/RGR/NTE RC($0-$0).',
  '00010006': 'Not possible. You are not defined as Control User',
  '00010007': 'Not possible. Document is not online.',
  '00010008': 'Please enter a value in the field "Select from last" or "Start Date"',
  '00010009': 'Program $0 ended. Incorrect database level.',
  '00010010': 'Recipient not found or Recipient is not of type RECI.',
  '00010011': 'Not possible. Document is marked for delete.',
  '00010012': 'Error open file $0',
  '00010013': 'Error reading file $0',
  '00010014': 'Maximum of record length ($0) reached. File: $0',
  '00010015': 'Program $0 ended. Storage allocation error',
  '00010016': 'Program $0 ended. Invalid value for $0',
  '00010017': 'Program $0 ended. Keyword B93_PRT_PORT not found',
  '00010018': 'Not possible. Document is online.',
  '00010019': 'Not possible. Document is not archived.',
  '00010020': 'Reports with Status = C are not changed.',
  '00010021': 'Program $0 ended. Error update LGR/RGR RC($0-$0)',
  '00010022': 'Not possible. Perform the request also for all reports',
  '00010023': 'No Report definition(s) found.',
  '00010024': 'Rerun Error RC($0)',
  '00010025': 'Not possible. Document is "archive expired".',
  '00010026': 'Not possible. Empty file $0',
  '00010027': 'Print request exceeds users print page limit.',
  '00010028': '$0 invalid - Contains illegal characters like: $ ? * " ; blank',
  '00010029': '$0 - is required.',
  '00010030': 'Not possible. Notes are not archived.',
  '00010031': 'No recipient assignment found',
  '00010032': 'Not possible. Notes are not online.',
  '00010033': 'Not possible. Subpool order is defined as Order for Reload in archive pool.',
  '00010034': 'Not possible. Order for Reload not defined in any subpool.',
  '00010035': 'Invalid Date or Date Format, please observe the Datemask.',
  '00010036': 'Not possible. Monitor already started.',
  '00010037': 'Not possible. Document contains an Encrypt Dictionary.',
  '00010038': 'Not possible. Search argument value contains Unicode characters',
  '00010039': 'Not possible. Character code > 255, Utf-8 encoding required',
  '00010040': 'Invalid Time Format (HH, HH:MM or HH:MM:SS).',
  '00010041': 'Server "$0" incompatible - Required version: $0',
  '00020001': 'Search Argument not defined.',
  '00020002': 'Search Argument Value not defined.',
  '00020003': 'Search Argument Formula not defined.',
  '00020008': 'Invalid Search Argument Formula.',
  '00030000': 'Missing mandatory parameter "$0".',
  '00030001': 'Buxtwapi - Invalid encoding detected.',
  '00050000': 'No data found.',
  '00050001': 'Not possible. $0 is required.',
  '00050002': 'Not possible. BTOKEN and BSEQNR are required.',
  '00050003': 'Not possible. LTOKEN and RTOKEN are required.',
  '00050004': 'Not possible. BATCH or BUNDLE request selected',
  '00050005': 'Failure to process requeue request. BQL-Command error RC($0,$1)',
  '00050006': 'Failure to process requeue request. BQL-Command error RC($0)',
  '00050007': '$d of $d requests could not be processed, see logfile.',
  '00060000': 'Invalid ID ($0)',
  '00060001': 'Error reading page($0), rc($d-$d), spoolptr($32.32s)',
  '00060002': 'Not possible. LGRONLNE and LGRARCH are NO.',
  '00060003': 'Not possible. Error during conversion in text - RC($0)',
  '00060004': 'Error open PageAcessBlock PAB RC($0)',
  '00060005': 'Error open Document, rc($d-$d), spoolptr($32.32s) ',
  '00070001': 'ReadTxt - Row > $0 bytes',
  '00070002': 'ReadPcl - Row > $0 bytes',
  '00070003': 'ReadPs  - Row > $0 bytes',
  '00070004': 'ReadAfp - Row > $0 bytes',
  '00070005': 'ReadAfp - Incorrect AFP character found',
  '00070006': 'ReadAfp - Record > 32756 Bytes',
  '00070007': 'ReadAfp - Invalid action code',
  '00071621': 'An index for a Side File has been given. Error: The Side File $0 is missing.',
  '00077013': 'Data transmission failed',
  '00080000': 'Your password will expire in $0 days.',
  '00080001': 'Login failed. User is required.',
  '00080010': 'Your password will expire within the next hours.',
  '00080016': 'Error occured on import ($0)',
  '00080400': 'Not possible. The user is not defined.',
  '00080401': 'Not possible. The unix user is not defined.',
  '00080800': 'The password is not authorized.',
  '00081201': 'Enter new password - old password has expired (by interval).',
  '00081202': 'Enter new password - old password has expired.',
  '00081203': 'Enter new password - password change forced by administrator.',
  '00081602': 'The new password is too short.',
  '00081603': 'The new password is not valid. This password already exists in history.',
  '00082800': 'The user has been revoked.',
  '00100001': 'TABLE is a required keyword for SELECT commands.',
  '00100002': 'FIELDS is a required keyword for SELECT commands.',
  '00100003': 'VALUES is not valid for SELECT commands.',
  '00100004': 'TABLE is a required keyword for UPDATE commands.',
  '00100005': 'FIELDS is a required keyword for UPDATE commands.',
  '00100006': 'VALUES is a required keyword for UPDATE commands.',
  '00100007': 'WHERE is a required keyword for UPDATE commands.',
  '00100008': 'TABLE is a required keyword for DELETE commands.',
  '00100009': 'FIELDS is not valid for DELETE commands.',
  '00100010': 'VALUES is not valid for DELETE commands.',
  '00100011': 'WHERE is a required keyword for DELETE commands.',
  '00110001': 'Too many hits, please narrow your selection',
  '00110002': 'Too many documents, please narrow your selection',
  '00160000': 'No data found.',
  '00200000': 'Unexpected error',
  '00200001': 'Unknown Keyword',
  '00200002': 'Table is not defined.',
  '00200003': 'Table set in error',
  '00200004': 'Field "$0" not defined',
  '00200005': 'Wrong value count - Command contains fewer or more VALUES than FIELDS.',
  '00200006': 'Value conversion error for field "$0".',
  '00200007': 'Comparison operator invalid or missing',
  '00200008': 'Boolean operator invalid',
  '00200009': 'Value invalid - field "$0"',
  '00200010': 'Parameter FIELDS is missing.',
  '00200011': 'WHERE clause is missing.',
  '00200012': 'Parameter VALUES missing',
  '00200013': 'Statement incomplete',
  '00200014': 'VALUE exceeds range of FIELD.',
  '00200015': 'Invalid key in ORDER clause',
  '00200016': 'Specified FILE not found',
  '00200017': 'Keyword missing "$0"',
  '00200019': 'FILE-ID invalid',
  '00200020': 'FILETYPE invalid',
  '00200030': 'Duplicate file name',
  '00200031': 'Status of file is not MODEL.',
  '00200032': 'CSI not a multiple of 4096',
  '00200033': 'File is not empty.',
  '00200034': 'Keyword missing',
  '00200036': 'Duplicate keyword in BQL statement',
  '00200111': 'Block allocation error - Database is full',
  '00200120': 'Record not deleted - Inconsistency between record and key',
  '00200121': 'Record not updated - Inconsistency between record and key',
  '00200122': 'Record not read - Inconsistency between record and key',
  '00200130': 'Duplicate table',
  '00200131': 'Table not in file',
  '00200140': 'Key not found - Inconsistency between record and key',
  '00200141': 'Record not updated - Inconsistency between record and key',
  '00200142': 'Entry already exists.',
  '00200143': 'Record not read     - Inconsistency between record and key',
  '00200144': 'Record not deleted  - Inconsistency between record and key',
  '00200145': 'Record not inserted - Error key block defect',
  '00200146': 'Record not inserted - Error record block defect',
  '00200173': 'Not possible. Index not found.',
  '00200176': 'Not possible. Global index not found.',
  '00200178': 'Not possible. Parameter ARGUMENT is missing.',
  '00200192': 'Not possible. Indextoken not defined.',
  '00200193': 'Not possible. Invalid value (Index type = DATE)',
  '00200195': 'Not possible. Invalid value (Index type = NUMERIC)',
  '00200196': 'Not possible. Invalid value (Index type = DECIMAL)',
  '00200198': 'Not possible. Invalid value (Index type = RNUM)',
  '00200212': 'Reference to list/report within the SPOOL is obsolete or invalid.',
  '00200213': 'Page cannot be displayed, invalid page number detected.',
  '00200216': 'Error in SPOOL Index, FILEID = 0',
  '00200217': 'Error in SPOOL Index, RBA = 0',
  '00200218': 'Error in SPOOL Index, SLOT = 0',
  '00200219': 'Error in SPOOL Index, invalid SLOT nummer',
  '00200625': 'Page cannot be displayed, invalid page number detected.',
  '00200633': 'Archive Server not available',
  '00200902': 'General printing error.',
  '00203003': 'Folder - not defined',
  '00203004': 'Predecessor not defined or not of type Address',
  '00203005': 'Output format - not defined',
  '00203006': 'Postprocessing note - not defined',
  '00203008': 'Predecessor for Recipient of type "Alias" is not defined.',
  '00203009': 'Predecessor not defined or not of type Recipient',
  '00203010': 'Recipient already defined as "Alias"',
  '00203011': 'Not possible. Recipient is already defined as a Recipient of type RECI or ADDR.',
  '00203012': 'Recipient already defined as TYPE = ADDR/RECI',
  '00203013': 'Predecessor already has a Predecessor.',
  '00203014': 'Recipient - not defined',
  '00203015': 'List - not defined',
  '00203016': 'Report - not defined',
  '00203017': 'Output channel - not defined',
  '00203018': 'No entry matches the selection criteria.',
  '00203019': 'List/Report is assigned to one or more Folder definitions.',
  '00203020': 'Retention is a required field if automatic archive or archive notes."',
  '00203021': 'Medium is a required field if automatic archive or archive notes.',
  '00203022': 'Archive medium and archive retention > 0 are required for archiving.',
  '00203023': 'if Type = PAGE, then "On Page" must be > 0.',
  '00203024': 'Search Argument not  defined',
  '00203025': 'Not possible. "Recipient <-> Document" entries exist.',
  '00203026': 'Not possible. Potprocessing note is used in one or more recipient definitions.',
  '00203027': 'Not possible. Postprocessing note is used in one or more document definitions.',
  '00203028': 'Not possible. Postprocessing note is used in one or more LRT definitions.',
  '00203029': 'Not possible. Postprocessing note is used in one or more "Recipient <-> Document" definitions.',
  '00203030': 'Not possible. Output Channel is used in one or more Recipient definitions.',
  '00203031': 'Not possible. Output Channel is used in one or more Document definitions.',
  '00203032': 'Not possible. Output Channel is used in one or more LRT definitions.',
  '00203033': 'Not possible. Output Channel is used in one or more "Recipient <-> Document" definitions.',
  '00203034': 'Search Mode is required for Report definition.',
  '00203035': 'Not possible to delete the standard definition STD.',
  '00203036': 'Archive Pool is a required field.',
  '00203037': 'Archive Media is a required field.',
  '00203038': 'Retention Period is a required field.',
  '00203039': 'Order for Reload is a required field.',
  '00203040': 'Archive Pool with the same Owner already defined',
  '00203041': 'Archive Pool with the same Owner, Archive Media and Retention Period already defined.',
  '00203042': 'Not possible. Retention Period > 30000',
  '00203044': 'File Size is a required field.',
  '00203045': 'Maximum of File Size is 500.',
  '00203046': 'Retention Period is a required field for Subpool.',
  '00203047': 'Order for Reload is a required field for Subpool.',
  '00203048': 'Archive Media is a required field for Subpool.',
  '00203049': 'File Name or Mask is a required field.',
  '00203050': 'Not possible. Output Format is used in one or more Recipient definitions.',
  '00203051': 'Not possible. Output Format is used in one or more Document definitions.',
  '00203052': 'Index ID is a required field.',
  '00203053': 'Not possible. Output Format is used in one or more "Recipient <-> Document" definitions.',
  '00203054': 'Not possible. Output Format is used in one or more LRT definitions.',
  '00203055': 'Not possible. Recipient is used in one or more Recipient definitions as Predecessor.',
  '00203056': 'Not possible. Index ID is used in one or more Index definitions.',
  '00203057': 'Index - not defined in Index ID Table',
  '00203058': 'Title is a required field.',
  '00203059': 'Search Pattern is a required field.',
  '00203060': 'Datemask is a required field.',
  '00203062': 'List/Report is assigned to one or more Index <-> Document definitions.',
  '00203063': 'Result Table is a required field.',
  '00203064': 'Result Table is assigned to one or more Select Dialog definitions',
  '00203065': 'Select Dialog is a required field.',
  '00203066': 'Result Table - not defined.',
  '00203067': 'Node Name is a required field.',
  '00203068': 'Parent Node not defined.',
  '00203069': 'Not possible. Node is used in one or more Node definitions as Parent Node.',
  '00203070': 'Identifier is a required field.',
  '00203071': 'Parent Node is not a Node.',
  '00203072': 'Parent Node is a required field of type DGI.',
  '00203073': 'Group is assigned to one or more "Group <-> Document" definitions.',
  '00203074': 'Group is not defined.',
  '00203075': 'Not possible. Successor Node exist.',
  '00203076': 'Identifier is a required field.',
  '00203077': 'List/Report is assigned to one or more "Node <-> Document" definitions.',
  '00203079': 'from line value > to line value',
  '00203080': 'Not possible. Report definitions exist.',
  '00203084': 'Not possible. "Recipient <-> Document" entries still exist.',
  '00203085': 'SMODE not allowed for LIST definition',
  '00203086': 'from column value > to column value',
  '00203088': 'Port is a required field.',
  '00203089': 'IP Address is a required field.',
  '00203090': 'Output Command is a required field.',
  '00203101': 'Output Channel cannot be deleted, it is defined as Alternate Output Channel.',
  '00203102': 'Alternate Output Channel must be different from Same Output Channel.',
  '00203103': 'No Alternate Output Channel defined but activated',
  '00203104': 'This Alternate Output Channel is not defined.',
  '00203105': 'Alternate Output Channel must be different from Same Output Channel.',
  '00203106': 'No Alternate Output Channel defined but activated',
  '00203107': 'This Alternate Output Channel is not defined.',
  '00203119': 'Application is a required field.',
  '00203126': 'if OVDCR = YES,  DCR is required.',
  '00203127': 'if OVPCR = YES,  PCR is required.',
  '00203128': 'If Print banner/trailer is set, then Line is required.',
  '00203129': 'If Control status = Controlled, Control user ID is required.',
  '00203130': 'Form is a required field.',
  '00203131': 'Recipient is a required field.',
  '00203132': 'Output Format is a required field.',
  '00203133': 'Output Channel and Type are required fields.',
  '00203134': 'Postprocessing note is a required field.',
  '00203136': 'Search Argument is a required field.',
  '00203137': 'Recipient Type is a required field.',
  '00203138': 'Folder is a required field.',
  '00203139': 'Not possible. "Folder <-> Documents" entries still exist.',
  '00203171': 'Search Argument and Length for first Burst-Window are required.',
  '00203172': '"Valid from" and "Valid to" are required fields for Report definition.',
  '00203173': 'Dyn. Report must be blank for Assignment= DEFAULT.',
  '00203174': 'Dyn. Report is a required field if Assignment not DEFAULT.',
  '00203175': 'Line, Column and Length for first Burst-Windoware required.',
  '00203176': 'Search formula required for Search mode ABSOLUTE or COND/BURST*',
  '00203177': 'Start and Stop Search formula required for Search mode START/STOP or LIMIT/BURST',
  '00203178': 'Dyn. Report must be blank for a List.',
  '00203179': 'Assignment DEFAULT or CONVERT is allowed only for BURST Reports.',
  '00203180': 'Dyn. Report is required if LTYPE = CONVERT.',
  '00203181': 'Dyn. Report must be Recipient if Assignment= STATIC.',
  '00203189': 'Search argument value already defined',
  '00203190': 'Not possible. Document not archived.',
  '00203191': 'Not possible. No valid IDX license.',
  '00203192': 'Not possible. No valid BDL license.',
  '00203193': 'Not possible. Document is online.',
  '00203401': 'Not authorized for this action.',
  '00203450': 'User is a required field.',
  '00203451': 'Group is a required field.',
  '00203452': 'User already defined as group in table GRP',
  '00203453': 'Group already defined as user in table USR',
  '00203454': 'User is connected to one ore more groups.',
  '00203455': 'User is connected to one ore more security profiles.',
  '00203456': 'Group is connected to one ore more security profiles.',
  '00203457': 'Profile is a required field.',
  '00203458': 'Profile for universal access(UACC) not defined.',
  '00203459': 'Not possible. One or more users or groups are connected to the profile.',
  '00203460': 'Unknown User or Group name',
  '00203461': 'UNIX User is a required field.',
  '00203462': 'User not defined',
  '00203463': 'Group not defined',
  '00203464': 'Not possible. Group contains one or more users.',
  '00203465': 'Invalid character found (valid are A-Z,0-9 and #,$,§)',
  '00203466': 'Invalid profile mask!',
  '00203467': 'Not possible. No valid index definition found.',
  '00203468': 'Not possible. No recipient assignment found.',
  '00203469': 'Not possible. No valid report definition found.',
  '00203470': 'Not possible. No Notes online.',
  '00203471': 'Not possible. Delete Notes not allowed',
  '00203472': 'Not possible. Keyword PDFPROC_TMPDIR (LST.TXT) is missing.',
  '00203473': 'Error during conversion in text (PDF2TXT,PS2TXT) or page extraction (PDF2PDF).',
  '00203474': 'Page extraction not possible. PDF Document contains an Encrypt Dictionary.',
  '00203475': 'Not possible. Text version not available.',
  '00203476': 'Not possible. Requeue or reprint request exceeds the limit.',
  '00203477': 'Not possible. TCP/IP connection to the Output Server failed.',
  '00203478': 'Not possible. Protocol not IPP .',
  '00203479': 'Not possible. Job is already deleted.',
  '00203480': 'Not possible. Job is incomplete. (Status = incoming)',
  '00203481': 'Not possible. Job is active. Stop the print request first.',
  '00203482': 'Not possible. Job is not active.',
  '00203483': 'Not possible. Alternate Output Channel is not available.',
  '00203484': 'Not possible. Output request exceeds the print page limit of the user.',
  '00203485': 'Not possible. Recipient must be of type RECI',
  '00203486': 'Not possible. Side file not available.',
  '00203487': 'Not possible. Table of notes is not online.',
  '00203488': 'Not possible. Document is neither online nor archived.',
  '00203489': 'You are not defined as control user.',
  '00203490': 'Not possible. Document is marked for deletion.',
  '00203491': 'Not possible. Search Argument is used in one or more Report definitions.',
  '00203492': 'Length of Search String greater than Column Range',
  '00203493': 'Please use x"22" instead of " in Search String.',
  '00203494': 'Lenght of Search String greater than 128 Byte',
  '00203495': 'Not possible. TCP/IP connection to LPD Server failed.',
  '00203496': 'Error IPP options',
  '00203497': 'Job not found in printer.',
  '00203498': 'Not possible, Document is archive expired.',
  '00203499': 'User session has expired!',
  '00203500': 'Not possible. TCP/IP connection to Archive Server failed.',
  '00240000': 'BQL-Connection error RC(24)',
  '00320000': 'BQL-Command abort RC(32)',
  '00360000': 'BQL-Command error RC(36)',
  '00420001': 'Program $0 returns with error code $1',
  '00420002': 'No output of program $0',
  '00420003': 'Missing mandatory parameter $0',
  '00420004': 'Program $0 raises signal $1',
  '00420005': 'Invalid value for parameter $0: $1',
  '00420006': 'Validation errors: $0',
  '00420007': 'Parameter of wrong type $0: $1',
  '00420008': 'Value of parameter $0 contains non supported unicode chars: $1',
  '00420009': 'Wrong value for parameter $0: $1',
  '00420010': 'File too large.',
  '00600004': 'Login failed. Beta UX user ID returned by the security exit does not exist.',
  '00600008': 'Login failed. Security exit not found or protocol.',
  '00600256': 'Login failed. No valid SLE license.',
  '00640004': 'External login failed. User not found.',
  '00640008': 'External login failed. Wrong password.',
  '00640016': 'External Logon failed. Problem with the authentification backend.',
  '00640032': 'External login failed. Invalid configuration of the logon exit.',
  '99990000': 'Illegal Command RC(9999)',
  'archive_pool.please_enter_id': 'Please enter a archive pool ID',
  'assignment.count_fail_created': '$0 assignments failed creation',
  'assignment.count_fail_deleted': '$0 assignments failed deletion',
  'assignment.count_successfully_created': '$0 assignments created',
  'assignment.count_successfully_deleted': '$0 assignments deleted',
  'assignment.create': '.',
  'assignment.create_assignment_error': 'Error on creating assignment ($0)',
  'assignment.create_assignment_success': 'Assignment created ($0)',
  'assignment.folder_document.copy_folder_document': 'Copy folder <-> document assignment',
  'assignment.folder_document.create_error': 'Error on creating assignment ($0)',
  'assignment.folder_document.create_folder_document': 'Create folder <-> document assignment',
  'assignment.folder_document.create_success': 'Assignment created ($0)',
  'assignment.folder_document.delete_assignment': 'Delete folder <-> document assignment',
  'assignment.folder_document.delete_error': 'Error on deleting assignment ($0)',
  'assignment.folder_document.delete_question': 'Do you really want to delete this folder <-> document assignment?',
  'assignment.folder_document.delete_success': 'Assignment deleted ($0)',
  'assignment.folder_document.dynamic_report': 'Dynamic report',
  'assignment.folder_document.folderid': 'Folder ID',
  'assignment.folder_document.get_folder_document_assignment_error': 'Error on getting folder document assignment "$0"',
  'assignment.folder_document.loading_error': 'Error on loading folder-document assignments ($0)',
  'assignment.folder_document.modify_error': 'Error on modifying assignment ($0)',
  'assignment.folder_document.modify_success': 'Assignment modified ($0)',
  'assignment.folder_document.please_enter_form': 'Please enter a form.',
  'assignment.folder_document.please_enter_id': 'Pleaser enter a folder ID.',
  'assignment.folder_document_assignment': 'Folder <-> document',
  'assignment.folder_document_delete_question_1': 'Do you really want to delete this folder',
  'assignment.folder_document_delete_question_2': ' document assignment?',
  'assignment.index_document.delete_error': 'Error on deleting assignment ($0)',
  'assignment.index_document.delete_index': 'Delete index <-> document assignment',
  'assignment.index_document.delete_question': 'Do you really want to delete this index <-> document assignment?',
  'assignment.index_document.delete_success': 'Assignment deleted ($0)',
  'assignment.index_document.get_assignment_error': 'Error on getting index document assignment "$0"',
  'assignment.index_document.loading_error': 'Error on loading index-document assignments ($0)',
  'assignment.index_document.modify_error': 'Error on modifying assignment ($0)',
  'assignment.index_document.modify_success': 'Assignment modified ($0)',
  'assignment.index_document_acif_index': 'ACIF index',
  'assignment.index_document_afpds_nop_record': 'AFPDS NOP record',
  'assignment.index_document_assignment': 'Index <-> document',
  'assignment.index_document_create_title': 'Create index <-> document assignment',
  'assignment.index_document_debug_mode': 'Debug mode',
  'assignment.index_document_delete_question_1': 'Do you really want to delete this index',
  'assignment.index_document_delete_question_2': ' document assignment?',
  'assignment.index_document_determine_index_values_from': 'Determine index values from',
  'assignment.index_document_document_text_browser': 'Document text browser',
  'assignment.index_document_document_variable': 'Document variable',
  'assignment.index_document_extract_index_value': 'Extract index value',
  'assignment.index_document_extract_index_values_only_from_pages': 'Extract index values only from pages',
  'assignment.index_document_format_index_value': 'Format index value',
  'assignment.index_document_ignore_index_value_with_wrong_data_format': 'Ignore index value with wrong data format',
  'assignment.index_document_ignore_index_values_from_pages': 'Ignore index values from pages',
  'assignment.index_document_index_values_are_required': 'Index values are required',
  'assignment.index_document_index_values_from': 'Index values from',
  'assignment.index_document_maximally_up_to_end_tag': 'Maximally up to end tag',
  'assignment.index_document_no': 'No',
  'assignment.index_document_pjl_statement': '@PJL statement',
  'assignment.index_document_rule_example_label': 'Example rule:',
  'assignment.index_document_rule_example_substitutes1': 'Substitutes "I1234567890ABC" with "567890-1234-I"',
  'assignment.index_document_rule_example_substitutes2': 'Substitutes "K 23456789 ABC" with "567890-#234-K"',
  'assignment.index_document_rule_example_value': '&VAL(5,6,0)-&VAL(1,4,#)-&VAL(0,1)',
  'assignment.index_document_sample_of_a_search_pattern_label': 'Sample of a search pattern:',
  'assignment.index_document_sample_of_a_search_pattern_value': '\'ABC\'P\'99999\'',
  'assignment.index_document_sample_of_a_time_string_label': 'Sample of a time string:',
  'assignment.index_document_sample_of_a_time_string_value': '%a %b %d %H:%M:%s GMT %Y',
  'assignment.index_document_sample_of_lcs_time1': 'LC_TIME: en_US - Date: Fri Aug 13 10:22:56 GMT',
  'assignment.index_document_sample_of_lcs_time2': 'LC_TIME: de_DE - Date: Fr Aug 13 10:22:56 GMT',
  'assignment.index_document_sample_of_lcs_time3': 'LC_TIME: fr_FR - Date: ven aoû 13 10:22:56 GMT',
  'assignment.index_document_samples_of_lc_time': 'Samples of LC_TIME:',
  'assignment.index_document_search_pattern_*': '* - Any single character',
  'assignment.index_document_search_pattern_a': 'A - Upper or lower case chars',
  'assignment.index_document_search_pattern_b': 'B - Blank',
  'assignment.index_document_search_pattern_l': 'L - Lower case chars',
  'assignment.index_document_search_pattern_q': '9 - Digit',
  'assignment.index_document_search_pattern_s': 'S - Special chars',
  'assignment.index_document_search_pattern_u': 'U - Upper case chars',
  'assignment.index_document_search_pattern_x': 'X - Hexadecimal',
  'assignment.index_document_search_pattern_z': 'Z - Digit or character',
  'assignment.index_document_security_check': 'Security check',
  'assignment.index_document_side_file': 'Side file',
  'assignment.index_document_strings_beginning_with_abc': 'Strings beginning with "ABC" and followed by 5 digits.',
  'assignment.index_document_substitude_values': 'Substitute values',
  'assignment.index_document_substitute_characters_before_saving_hits': 'Substitute characters before saving hits',
  'assignment.index_document_variables': 'Variables:',
  'assignment.index_document_variables1': '%a - Abbreviated weekday name (e.g., Sun)',
  'assignment.index_document_variables2': '%A - Full weekday name (e.g., Sunday)',
  'assignment.index_document_variables3': '%b - Abbreviated month name (e.g., Jan)',
  'assignment.index_document_variables4': '%B - Full month name (e.g., January)',
  'assignment.index_document_variables5': '%d - Day of month (e.g, 01)',
  'assignment.index_document_variables6': '%m - Month (01..12)',
  'assignment.index_document_variables7': '%y - Last two digits of year (00..99)',
  'assignment.index_document_variables8': '%Y - Year',
  'assignment.index_document_with_values': 'With values',
  'assignment.index_log_delete_question_2': ' log assignment?',
  'assignment.no': 'No',
  'assignment.no_alt': 'No',
  'assignment.no_match': 'matches your search criteria.',
  'assignment.no_match_single': 'matches your search criteria.',
  'assignment.node_document.create_error': 'Error on creating assignment "$0" ($1)',
  'assignment.node_document.create_success': 'Assignment created ($0)',
  'assignment.node_document.delete_assignment_failed': 'Error on deleting node <-> document assignment "$0" ($1)',
  'assignment.node_document.delete_assignment_success': 'Assignment deleted ($0)',
  'assignment.node_document.delete_error': 'Error of deleting node-document assignment "$0" ($1)',
  'assignment.node_document.loading_error': 'Error on loading node-document assignments ($0)',
  'assignment.node_document.modify_error': 'Error on modifying assignment "$0" ($1)',
  'assignment.node_document.modify_success': 'Assignment modified ($0)',
  'assignment.node_document_assignment.loading_error': 'Error on getting node-document assignment',
  'assignment.node_document_delete_question_1': 'Do you really want to delete this node',
  'assignment.node_document_delete_question_2': ' document assignment?',
  'assignment.recipient_document.delete_assignment_error': 'Error on deleting assignment "$0" ($1)',
  'assignment.recipient_document.delete_assignment_success': 'Assignment deleted ($0)',
  'assignment.recipient_document.delete_question': 'Do you really want to delete this recipient <-> document assignment?',
  'assignment.recipient_document.delete_title': 'Delete recipient <-> document assignment',
  'assignment.recipient_document_assignment': 'Recipient <-> document',
  'assignment.recipient_document_assignment.loading_error': 'Error on getting recipient-document assignment "$0" ($1)',
  'assignment.recipient_document_assignments.loading_error': 'Error on loading recipient-document assignments ($0)',
  'assignment.recipient_document_convert': 'Convert',
  'assignment.recipient_document_copy': 'Copy recipient <-> document assignment',
  'assignment.recipient_document_create': 'Create recipient <-> document assignment',
  'assignment.recipient_document_default': 'Default',
  'assignment.recipient_document_delete_question_1': 'Do you really want to delete this recipient',
  'assignment.recipient_document_delete_question_2': ' document assignment?',
  'assignment.recipient_document_static': 'Static',
  'assignment.you_can': 'You can create a new',
  'database.add_to_favorite': 'Add to favorites',
  'database.allocated_blocks': 'Allocated blocks',
  'database.archive': 'Archive',
  'database.archive_cleanup': 'Archive cleanup',
  'database.archive_notes': 'Archive notes',
  'database.block_size': 'Block size',
  'database.bqlquery_error': 'Error on executing BQL command ($0)',
  'database.create_database_file': 'Create database file',
  'database.create_file_error': 'Error on creating database file "$0" ($1)',
  'database.create_file_success': 'Database file with filename "$0" created',
  'database.daily': 'Daily maintenance',
  'database.delete_database_file': 'Delete database file',
  'database.delete_file_error': 'Error on deleting database file "$0" ($1)',
  'database.delete_file_success': 'Database file "$0" deleted',
  'database.disable_delete_status': 'Status has to be "Empty" or "Model"',
  'database.disable_delete_type': 'Only index and spool files can be deleted',
  'database.empty': 'Empty',
  'database.execute': 'Execute',
  'database.externalcmd_result': 'External command result',
  'database.favorites': 'Favorites',
  'database.fields_error': 'Error on loading database fields ($0)',
  'database.file': 'Database file',
  'database.file_id': 'File ID',
  'database.file_size': 'File size',
  'database.file_status_cls': 'Closed',
  'database.file_status_emp': 'Empty',
  'database.file_status_err': 'Error',
  'database.file_status_fmt': 'Formatted',
  'database.file_status_ful': 'Full',
  'database.file_status_mod': 'Model',
  'database.file_status_onl': 'Online',
  'database.file_status_opn': 'Open',
  'database.fileid': 'File ID',
  'database.filename': 'Filename',
  'database.filename_required': 'Please enter a filename',
  'database.files_error': 'Error on loading database files ($0)',
  'database.free': 'Free',
  'database.free_blocks': 'Free blocks',
  'database.function': 'Function',
  'database.get_file_error': 'Error on getting database file "$0" ($1)',
  'database.index_databases': 'Index databases (summarized)',
  'database.index_model': 'Index model',
  'database.keyfields_error': 'Error on loading database keyfields ($0)',
  'database.last_executed': 'Last executed',
  'database.maintenance': 'Maintenance',
  'database.maintenance_result': 'Maintenance result',
  'database.message_log_cleanup': 'Message log cleanup',
  'database.messagelog': 'Message log',
  'database.model': 'Model',
  'database.notes_cleanup': 'Notes cleanup',
  'database.online_cleanup': 'Online cleanup',
  'database.output_queue_cleanup': 'Output queue cleanup',
  'database.prefix': 'Prefix',
  'database.process_id': 'Process ID',
  'database.query': 'Query',
  'database.query_already_saved': 'Query already saved in favorites',
  'database.query_no_favorites': 'No favorites saved yet',
  'database.query_success': 'BQL-Query executed',
  'database.query_to_uppercase': 'Convert request query to uppercase',
  'database.question_delete_database_file': 'Do you really want to delete this database file?',
  'database.read_only': 'Read only',
  'database.reads': 'Reads',
  'database.reload': 'Reload',
  'database.result': 'Result',
  'database.result.message': 'Message',
  'database.result.message_id': 'Message ID',
  'database.result.time': 'Time',
  'database.return_code': 'Return code',
  'database.shortname': 'Shortname',
  'database.size': 'Size',
  'database.size_mb': 'Size (MB)',
  'database.spool_databases': 'Spool databases (summarized)',
  'database.spool_model': 'Spool model',
  'database.status': 'Status',
  'database.status_changed_to_read_only_success': 'Status changed from "read-only" to "read-write" ($0)',
  'database.status_changed_to_read_write_success': 'Status changed from "read-write" to "read-only" ($0)',
  'database.status_donutchart_free': 'Free',
  'database.status_donutchart_used': 'Used',
  'database.status_set_read_only': 'Set read-only',
  'database.status_set_read_write': 'Set read-write',
  'database.table_id': 'Table ID',
  'database.table_information': 'Database table information',
  'database.table_located_in': 'Located in',
  'database.table_name': 'Table name',
  'database.tablefields_error': 'Error on loading database tablefields ($0)',
  'database.tablekeys_error': 'Error on loading database tablekeys ($0)',
  'database.tables': 'Table',
  'database.tables_error': 'Error on loading database tables ($0)',
  'database.tables_field': 'Field',
  'database.tables_field_id': 'Field ID',
  'database.tables_field_name': 'Field name',
  'database.tables_fields': 'Fields',
  'database.tables_fields_conv': 'Conv.',
  'database.tables_fields_external': 'External',
  'database.tables_fields_external_length': 'External length',
  'database.tables_fields_external_type': 'External type',
  'database.tables_fields_external_value': 'External value',
  'database.tables_fields_internal': 'Internal',
  'database.tables_fields_internal_length': 'Internal length',
  'database.tables_fields_internal_type': 'Internal type',
  'database.tables_fields_internal_value': 'Internal value',
  'database.tables_fields_position': 'Position',
  'database.tables_key_id': 'Key ID',
  'database.tables_key_name': 'Key name',
  'database.tables_keys': 'Keys',
  'database.tables_lang': 'Lang.',
  'database.tables_long_name': 'Long name',
  'database.tables_sub_field': 'Sub-Field',
  'database.type': 'Type',
  'database.update_file_error': 'Error on modifying database file "$0" ($1)',
  'database.update_file_success': 'Database file "$0" modified',
  'database.used': 'Used',
  'database.writes': 'Writes',
  'definition.activate_alternative_after_retry_period': 'Activate alternative after retry period',
  'definition.activate_pjl_status_readback_ustatus': 'Activate PJL status readback (UStatus)',
  'definition.activate_snmp_setting': 'Activate SNMP settings',
  'definition.add_additional_field': 'Add additional field',
  'definition.address': 'Address',
  'definition.allow_banner_between_document_copies': 'Allow banner between document copies',
  'definition.alternative_activ': 'Alternative active',
  'definition.alternative_id': 'Alternative ID',
  'definition.application': 'Application',
  'definition.application_attributes': 'Application - Attributes',
  'definition.archive_date': 'Archive date',
  'definition.archive_file': 'Archive file',
  'definition.archive_files_error': 'Error on loading archive files ($0)',
  'definition.archive_pool': 'Archive pool',
  'definition.archive_pool_add_subpool': 'Add subpool',
  'definition.archive_pool_archive_file_management': 'Archive file management',
  'definition.archive_pool_attached_subpools': 'Attached subpools',
  'definition.archive_pool_create_initial_subpool': 'Create initial subpool with archive pool',
  'definition.archive_pool_delete': 'Delete archive pool',
  'definition.archive_pool_delete_question': 'Do you really want to delete the archive pool?',
  'definition.archive_pool_delete_script_command': 'Delete script/command',
  'definition.archive_pool_error': 'Error on loading archive pool definition "($0)" ($1)',
  'definition.archive_pool_file_managed_by': 'File managed by',
  'definition.archive_pool_file_name_mask': 'File name/mask',
  'definition.archive_pool_file_size': 'File size (4MB blocks)',
  'definition.archive_pool_id': 'Archive pool ID',
  'definition.archive_pool_order_for_reload': 'Order for reload',
  'definition.archive_pool_reload_order_value': 'Reload order value',
  'definition.archive_pool_reload_script_command': 'Reload script/command',
  'definition.archive_pool_retention_period_days': 'Retention period (days)',
  'definition.archive_pool_retention_period_level2': 'Retention period level 2',
  'definition.archive_pool_use_storage_api': 'Use storage API',
  'definition.archive_pools_error': 'Error on loading archive pools definition ($0)',
  'definition.attributes': 'Attributes',
  'definition.available_elements': 'Available elements',
  'definition.available_jobtypes_error': 'Error on loading available jobtypes ($0)',
  'definition.clone_folder_error': 'Error on cloning folder definition "$0" ($1)',
  'definition.clone_folder_success': 'Folder definition "$0" created (cloned folder definition "$1")',
  'definition.command': 'Command',
  'definition.command_attributes': 'Command - Attributes',
  'definition.community': 'Community',
  'definition.configuration': 'Configuration',
  'definition.copy_custom_dialog': 'Copy custom dialog',
  'definition.copy_document_node': 'Copy document node',
  'definition.copy_externalcmd': 'Copy external command',
  'definition.copy_filter_argument': 'Copy filter argument',
  'definition.copy_index': 'Copy index',
  'definition.copy_output_channel': 'Copy output channel',
  'definition.copy_output_channel_id_error': 'Output channel ID has to be changed',
  'definition.copy_output_format': 'Copy output format',
  'definition.copy_ppn': 'Copy postprocessing note',
  'definition.copy_result_table': 'Copy result table',
  'definition.copy_result_table_same_resulttableid_error': 'Result table ID has to be changed',
  'definition.copy_search_argument_title': 'Copy search argument',
  'definition.copy_view_profile': 'Copy view profile',
  'definition.create_archive_pool_error': 'Error on creating archive pool "$0" ($1)',
  'definition.create_archive_pool_success': 'Archive pool "$0" created',
  'definition.create_archive_pool_title': 'Create archive pool',
  'definition.create_archive_subpool_error': 'Error on creating archive subpool for archive pool "$0" ($1)',
  'definition.create_archive_subpool_success': 'Archive subpool for archive pool "$0" created',
  'definition.create_custom_dialog': 'Create custom dialog',
  'definition.create_custom_dialog_error': 'Error on creating custom dialog "$0" ($1)',
  'definition.create_custom_dialog_no_assigned_elements': 'No elements assigned.',
  'definition.create_custom_dialog_success': 'Custom dialog "$0" created',
  'definition.create_document_error': 'Error on creating document definition "$0" ($1)',
  'definition.create_document_node': 'Create document node',
  'definition.create_document_node_error': 'Error on creating document node "$0"',
  'definition.create_document_node_success': 'Document node "$0" created',
  'definition.create_document_success': 'Document definition "$0" created',
  'definition.create_ext_command_error': 'Error on creating external command definition "$0" ($1)',
  'definition.create_ext_command_success': 'External command definition "$0" created',
  'definition.create_externalcmd': 'Create external command',
  'definition.create_filter_argument': 'Create filter argument',
  'definition.create_filter_argument_error': 'Error on creating filter argument definition "$0" ($1)',
  'definition.create_filter_argument_success': 'Filter argument definition "$0" created',
  'definition.create_folder_error': 'Error on creating folder definition "$0" ($1)',
  'definition.create_folder_success': 'Folder definition "$0" created',
  'definition.create_index': 'Create index',
  'definition.create_index_error': 'Error on creating index definition "$0" ($1)',
  'definition.create_index_success': 'Index definition "$0" created',
  'definition.create_jobgroup': 'Create job group',
  'definition.create_jobgroup_error': 'Error on creating job group "$0" ($1)',
  'definition.create_jobgroup_success': 'Job group "$0" created',
  'definition.create_log_error': 'Error on creating log definition "$0" ($1)',
  'definition.create_log_success': 'Log definition "$0" created',
  'definition.create_lpd_queue_error': 'Error on creating LPD queue definition "$0" ($1)',
  'definition.create_lpd_queue_success': 'LPD Queue definition "$0" created',
  'definition.create_output_channel': 'Create output channel',
  'definition.create_output_channel_error': 'Error on creating output channel definition "$0" ($1)',
  'definition.create_output_channel_success': 'Output channel definition "$0" created',
  'definition.create_output_format': 'Create output format',
  'definition.create_output_format_error': 'Error on creating output format definition "$0" ($1)',
  'definition.create_output_format_success': 'Output format definition "$0" created',
  'definition.create_output_request_with_status_hold': 'Create output request with status \'hold\'',
  'definition.create_ppn': 'Create postprocessing note',
  'definition.create_ppn_error': 'Error on creating post processing note definition "$0" ($1)',
  'definition.create_ppn_success': 'Post processing note definition "$0" created',
  'definition.create_recipient_error': 'Error on creating recipient definition "$0" ($1)',
  'definition.create_recipient_success': 'Recipient definition (Recipient ID: "$0", Type: "$1") created',
  'definition.create_result_table': 'Create result table',
  'definition.create_result_table_error': 'Error on creating result table "$0"',
  'definition.create_result_table_success': 'Result table "$0" created',
  'definition.create_search_argument_error': 'Error on creating search argument definition "$0" ($1)',
  'definition.create_search_argument_success': 'Search argument definition "$0" ($1) created',
  'definition.create_search_argument_title': 'Create search argument',
  'definition.create_searchargument_value_error': 'Error on creating search argument value "$0"',
  'definition.create_searchargument_value_success': 'Search argument value "$0" created',
  'definition.create_view_profile': 'Create view profile',
  'definition.create_view_profile_error': 'Error on creating view profile "$0"',
  'definition.create_view_profile_success': 'View profile "$0" created',
  'definition.custom_dialog': 'Custom dialog',
  'definition.custom_dialog.preview.enable': 'Enable preview',
  'definition.custom_dialog_assigned_objects_error': 'Error on loading assigned objects for custom dialog ($0)',
  'definition.custom_dialog_copy_same_id': 'Dialog ID has to be changed',
  'definition.custom_dialog_default_objects_error': 'Error on loading default objects for custom dialog ($0)',
  'definition.custom_dialog_definitions': 'Custom dialog definitions',
  'definition.custom_dialog_error': 'Error on loading custom dialog ($0)',
  'definition.custom_dialog_id': 'Dialog ID',
  'definition.custom_dialogs_error': 'Error on loading custom dialogs ($0)',
  'definition.database_query_enable_delete_function': 'The delete option must be enabled above',
  'definition.dbfield': 'DB-Field',
  'definition.dbselect': 'DB-Select',
  'definition.dbvalue': 'DB-Value',
  'definition.debug_mode': 'Debug mode (don\'t remove temporary files)',
  'definition.definition_mode': 'Definition',
  'definition.del_output_channel': 'Delete output channel',
  'definition.del_output_format': 'Delete output format',
  'definition.delete_archive_pool_error': 'Error on deleting archive pool "$0" ($1)',
  'definition.delete_archive_pool_success': 'Archive pool "$0" deleted',
  'definition.delete_archive_subpool_error': 'Error on deleting archive subpool for archive pool "$0" ($1)',
  'definition.delete_archive_subpool_success': 'Archive subpool for archive pool "$0" deleted',
  'definition.delete_custom_dialog': 'Delete custom dialog',
  'definition.delete_custom_dialog_error': 'Error on deleting custom dialog definition "$0"',
  'definition.delete_custom_dialog_success': 'Custom dialog definition "$0" deleted',
  'definition.delete_document': 'Delete document',
  'definition.delete_document_error': 'Error on deleting document definition "$0" ($1)',
  'definition.delete_document_node': 'Delete document node',
  'definition.delete_document_node_error': 'Error on deleting document node definition "$0"',
  'definition.delete_document_node_success': 'Document node definition "$0" deleted',
  'definition.delete_document_success': 'Document definition "$0" deleted',
  'definition.delete_ext_command_error': 'Error on deleting external command definition "$0" ($1)',
  'definition.delete_ext_command_success': 'External command definition "$0" deleted',
  'definition.delete_filter_argument': 'Delete filter argument',
  'definition.delete_filter_argument_error': 'Error on deleting filter argument definition "$0" ($1)',
  'definition.delete_filter_argument_success': 'Filter argument definition "$0" deleted',
  'definition.delete_folder': 'Delete folder',
  'definition.delete_folder_error': 'Error on deleting folder definition "$0" ($1)',
  'definition.delete_folder_question': 'Do you really want to delete this folder?',
  'definition.delete_folder_success': 'Folder definition "$0" deleted',
  'definition.delete_index': 'Delete index',
  'definition.delete_index_error': 'Error on deleting index definition "$0" ($1)',
  'definition.delete_index_success': 'Index definition "$0" deleted',
  'definition.delete_jobgroup': 'Delete job group',
  'definition.delete_jobgroup_error': 'Error on deleting job group "$0" ($1)',
  'definition.delete_jobgroup_success': 'Job group "$0" deleted',
  'definition.delete_log': 'Delete log',
  'definition.delete_log_error': 'Error on deleting log definition "$0" ($1)',
  'definition.delete_log_success': 'Log definition "$0" deleted',
  'definition.delete_lpd_queue_error': 'Error on deleting LPD queue definition "$0" ($1)',
  'definition.delete_lpd_queue_success': 'LPD queue definition "$0" deleted',
  'definition.delete_output_channel_error': 'Error on deleting output channel definition "$0" ($1)',
  'definition.delete_output_channel_success': 'Output channel definition "$0" deleted',
  'definition.delete_output_format_error': 'Error on deleting output format definition "$0" ($1)',
  'definition.delete_output_format_success': 'Output format definition "$0" deleted',
  'definition.delete_ppn_error': 'Error on deleting postprocessing note definition "$0" ($1)',
  'definition.delete_ppn_success': 'Post processingnote definition "$0" deleted',
  'definition.delete_recipient_error': 'Error on deleting recipient definition ($0) ($1)',
  'definition.delete_recipient_success': 'Recipient definition ($0) deleted',
  'definition.delete_result_table': 'Delete result table',
  'definition.delete_result_table_error': 'Error on deleting result table definition "$0"',
  'definition.delete_result_table_success': 'Result table definition "$0" deleted',
  'definition.delete_search_argument': 'Delete search argument',
  'definition.delete_search_argument_error': 'Error on deleting search argument definition "$0" ($1)',
  'definition.delete_search_argument_success': 'Search argument definition "$0" ($1) deleted',
  'definition.delete_searchargument_value_error': 'Error on deleting search argument value "$0',
  'definition.delete_searchargument_value_success': 'Search argument value "$0" deleted',
  'definition.delete_view_profile': 'Delete view profile',
  'definition.delete_view_profile_error': 'Error on deleting view profile definition "$0"',
  'definition.delete_view_profile_success': 'View profile definition "$0" deleted',
  'definition.device': 'Device',
  'definition.display_hierarchy': 'Display hierarchy',
  'definition.document': 'Document',
  'definition.document_CP1250': 'CP1250 - Windows, Central and Eastern European',
  'definition.document_CP1251': 'CP1251 - Windows, Russian, Bulgarian, Serbian',
  'definition.document_CP1252': 'CP1252 - Windows, Western European',
  'definition.document_CP1253': 'CP1253 - Windows, Greek',
  'definition.document_CP1256': 'CP1256 - Windows, Arabic',
  'definition.document_CP850': 'CP850 - DOS-Latin-1',
  'definition.document_IBM0037': 'IBM0037 - USA, Canada - CECP',
  'definition.document_IBM0273': 'IBM0273 - Austria, Germany CECP',
  'definition.document_IBM0297': 'IBM0297 - France - CECP',
  'definition.document_IBM0420': 'IBM0420 - IBM, Arabic Bilingual (Ebcdic)',
  'definition.document_IBM0500': 'IBM0500 - International #5',
  'definition.document_IBM1140': 'IBM1140 - IBM, USA, Canada (Ebcdic)',
  'definition.document_IBM1141': 'IBM1141 - IBM, Austria, Germany (Ebcdic)',
  'definition.document_IBM1147': 'IBM1147 - IBM, France (Ebcdic)',
  'definition.document_IBM1148': 'IBM1148 - IBM, International (Ebcdic)',
  'definition.document_ISO8859_1': 'ISO8859-1 - ISO,Western European',
  'definition.document_ISO8859_15': 'ISO8859-15 - ISO, Western European with Euro',
  'definition.document_ISO8859_2': 'ISO8859-2 - ISO, Central and Eastern European',
  'definition.document_UCS_2BE': 'UCS-2BE - Unicode',
  'definition.document_UTF_8': 'UTF-8 - Unicode',
  'definition.document_absolute': 'Absolute',
  'definition.document_according_to_rules_1_8': 'According to rules 1 to 8',
  'definition.document_afp_scan_only_nops': '*.afp: Scan only NOPs',
  'definition.document_afp_scan_only_pjl': '*.pcl: Scan only @PJL',
  'definition.document_afp_scan_only_tles': '*.afp: Scan only TLEs',
  'definition.document_archive_notes': 'Archive notes',
  'definition.document_archive_text_version': 'Archive text version',
  'definition.document_assign_in_reverse_order': 'Assign in reverse order',
  'definition.document_assign_values_to_docusr_variables': 'Assign values to DOCUSR variables',
  'definition.document_auto_print': 'Auto print',
  'definition.document_autoburst': 'Autoburst',
  'definition.document_automatic_archive_document': 'Automatically archive document',
  'definition.document_banner': 'Document banner',
  'definition.document_banner_trailer_file_name': 'Banner/trailer file name',
  'definition.document_batch_bundle': 'Batch bundle',
  'definition.document_batch_print': 'Batch print',
  'definition.document_batch_print_bundle': 'Batch print/batch bundle',
  'definition.document_burst_mode': 'Burst mode',
  'definition.document_calculate_retention_by_start_date': 'Calculate retention by start date',
  'definition.document_clone_modal_title': 'Clone document definition',
  'definition.document_cond_burst1': 'Conditional Burst 1',
  'definition.document_cond_burst2': 'Conditional Burst 2',
  'definition.document_cond_burst3': 'Conditional Burst 3',
  'definition.document_content_extraction_from_column': 'Content extraction from column ',
  'definition.document_content_extraction_length': 'Content extraction length',
  'definition.document_content_extraction_relative_to': 'Content extraction relative to',
  'definition.document_copy_banner': 'Document copy banner',
  'definition.document_copy_modal_title': 'Copy document definition',
  'definition.document_create_modal_title': 'Create document definition',
  'definition.document_display_search_argument_and_values': 'Display search arguments and values',
  'definition.document_document_print_parameter': 'Document print parameter',
  'definition.document_exclude': 'Exclude',
  'definition.document_exclude_formula': 'Exclude formula',
  'definition.document_extract_date_from_document': 'Extract date from document',
  'definition.document_extract_variable_values': 'Extract variable values',
  'definition.document_filter': 'Filter',
  'definition.document_from_position_of_rule_1': 'From position of rule 1',
  'definition.document_header_lines': 'Header lines',
  'definition.document_header_lines_on_page_one': 'Header lines on page one',
  'definition.document_hold_bundle_request': 'Hold bundle request',
  'definition.document_limit_burst': 'Limit/Burst',
  'definition.document_lines_after_matching_lines': 'Lines after matching lines',
  'definition.document_lines_before_matching_lines': 'Lines before matching lines',
  'definition.document_mark_matches': 'Mark matches (=>)',
  'definition.document_maximum_burst_windows_is_reached': 'The maximum of 8 burst windows is reached',
  'definition.document_maximum_colors_is_reached': 'The maximum of 4 colors is reached',
  'definition.document_maximum_columns_is_reached': 'The maximum of 8 columns is reached',
  'definition.document_maximum_lines_pages': 'Maximum lines per page',
  'definition.document_media_cannot_be_none': 'Media have to be Tape, Disk or Optical Disk.',
  'definition.document_modify_modal_title': 'Modify document definition',
  'definition.document_no_data_found_report': '"No data found." report',
  'definition.document_node': 'Document node',
  'definition.document_node_copy_same_id': 'Node ID has to be changed',
  'definition.document_node_definitions': 'Document node definitions',
  'definition.document_node_error': 'Error on loading document node definition ($0)',
  'definition.document_node_no_childs_possible_for_group': 'No children possible for type "Group"',
  'definition.document_nodes_error': 'Error on loading document node definitions ($0)',
  'definition.document_nops_encoded_in_ebcdic': 'NOPs encoding EBCDIC',
  'definition.document_number_of_lines': 'Number of lines',
  'definition.document_number_of_values': 'Number of values',
  'definition.document_one_burst_window_required': 'At least one burst-window is required',
  'definition.document_online_print': 'Online print',
  'definition.document_other_textversion': 'Other text Version',
  'definition.document_page_offset_at_end_qualifying_pages': 'Page offset at end qualifying pages',
  'definition.document_page_offset_at_start_qualifying_pages': 'Page offset at start qualifying pages',
  'definition.document_pcl_textversion_in_ebcdic': 'PCL text version in EBCDIC',
  'definition.document_pdf_textversion_in_utf8': 'PDF text version in UTF-8',
  'definition.document_prefix_lines_with_betaux_page_line_number': 'Prefix lines with the Beta UX page and line number',
  'definition.document_prefix_lines_with_input_line_number': 'Prefix lines with the input line number',
  'definition.document_print_control_file_name': 'Print control file name',
  'definition.document_ptx_encoding_utf8': 'PTX encoding (UTF-8)',
  'definition.document_replace_txt_by': 'Replace *.txt by',
  'definition.document_report_generation_and_variable_extraction': 'Report generation & variable extraction',
  'definition.document_retention_days': 'Retention (Days)',
  'definition.document_search_argument_formula': 'Search argument formula',
  'definition.document_search_mode': 'Search mode',
  'definition.document_search_mode_error': 'Please select a search mode.',
  'definition.document_separator_between_noncontiguous_lines': 'Separator (--) between non-contiguous lines',
  'definition.document_skip_all_blank_values': 'Skip all blank values',
  'definition.document_skip_leading_blank_values_only': 'Skip leading blank values only',
  'definition.document_start_new_page_when_value_begins_at_column': 'Start new page when value begins at column',
  'definition.document_start_search_argument_formula': 'Start search argument formula',
  'definition.document_start_stop': 'Start/Stop',
  'definition.document_stop_after_number_of_matches': 'Stop after number of matches',
  'definition.document_stop_argument_formula_on_same_page': 'Stop argument formula on same page',
  'definition.document_stop_search_argument_formula': 'Stop search argument formula',
  'definition.document_supress_leading_blanks_and_repeated_blanks_between_words': 'Suppress leading blanks and repeated blanks between words',
  'definition.document_tles_encoded_in_ebcdic': 'TLEs encoding EBCDIC',
  'definition.document_trailer': 'Document trailer',
  'definition.document_use_copies_value_from_list_generation_record': 'Use copies value from list generation record',
  'definition.document_value_relative_position': 'Value relative position',
  'definition.document_variable_extraction': 'Variable extraction',
  'definition.documentdefinitions': 'Document definitions',
  'definition.documents_error': 'Error on loading document definitions ($0)',
  'definition.elements': 'Elements',
  'definition.empty_result_table_objects': 'Please select at least one item',
  'definition.end_date': 'End Date',
  'definition.example_for_multi_file_processing': 'Example for Multi-file processing',
  'definition.example_for_single_file_processing': 'Example for single-file processing',
  'definition.ext_commands_error': 'Error on loading external command definitions ($0)',
  'definition.extension': 'Extension',
  'definition.extension.title': 'Select Extension',
  'definition.external_commands': 'External commands',
  'definition.externalcmd.arg': 'Arguments',
  'definition.externalcmd.cmd': 'Command',
  'definition.externalcmd.del_ext_cmd': 'Delete external command',
  'definition.externalcmd.ext_cmd_id': 'External command ID',
  'definition.externalcmd.question_del_ext_cmd': 'Do you really want to delete this external command?',
  'definition.extras': 'Extras',
  'definition.filter_argument': 'Filter arguments',
  'definition.filter_argument_case_sensitive': 'Case sensitive',
  'definition.filter_argument_copy_same_id': 'Filter argument ID has to be changed',
  'definition.filter_argument_definition': 'Filter argument definitions',
  'definition.filter_argument_error': 'Error on loading filter argument definitions ($0)',
  'definition.filter_argument_formula': 'Formula',
  'definition.filter_argument_from_column': 'From column',
  'definition.filter_argument_id': 'Filter argument ID',
  'definition.filter_argument_logical_condition': 'Filter argument value logical conditions',
  'definition.filter_argument_max_reached': 'The maximum number of filter arguments allowed is 8.',
  'definition.filter_argument_negate': 'Negate',
  'definition.filter_argument_search_pattern': 'Search pattern',
  'definition.filter_argument_to_column': 'To column',
  'definition.filter_argument_values': 'Filter argument values',
  'definition.filter_argument_verify': 'Verify filter argument',
  'definition.filterargument_add_filterargument': 'Add filter argument',
  'definition.folder': 'Folder',
  'definition.folder_copy_modal_title': 'Copy folder',
  'definition.folder_copy_same_id_error': 'Folder ID has to be changed',
  'definition.folder_create_assigned_documents': 'Assigned documents',
  'definition.folder_create_documents_tab': 'Documents',
  'definition.folder_create_dynamic_report': 'Dynamic report',
  'definition.folder_create_folder_id': 'Folder ID',
  'definition.folder_create_general_tab': 'General',
  'definition.folder_create_modal_title': 'Create folder',
  'definition.folder_create_owner': 'Owner',
  'definition.folder_create_title': 'Title',
  'definition.folder_id': 'Folder ID',
  'definition.folder_id_required': 'Please enter a folder ID',
  'definition.folder_owner': 'Owner',
  'definition.folder_title': 'Title',
  'definition.folderdefinitions': 'Folder definitions',
  'definition.folders_error': 'Error on loading folder definitions ($0)',
  'definition.form': 'Form',
  'definition.form.title': 'Select Form',
  'definition.format_options': 'Format options',
  'definition.get_filter_argument_error': 'Error on getting filter argument definition "$0" ($1)',
  'definition.get_folder_error': 'Error on getting folder definition "$0" ($1)',
  'definition.get_index_error': 'Error on getting index definition "$0" ($1)',
  'definition.get_log_error': 'Error on getting log "$0" ($1)',
  'definition.get_lpd_queue_error': 'Error on getting LPD queue definition "$0" ($1)',
  'definition.get_output_channel_error': 'Error on getting output channel "$0" ($1)',
  'definition.get_output_format_error': 'Error on getting output format "$0" ($1)',
  'definition.get_ppn_error': 'Error on getting post processing note definition "$0" ($1)',
  'definition.get_recipient_error': 'Error on getting recipient definition "$0" ($1)',
  'definition.get_search_argument_error': 'Error on getting search argument definition "$0" ($1)',
  'definition.hit_limitation': 'Hit limitation',
  'definition.host': 'Host',
  'definition.ignore_documents_print_control_parameters': 'Ignore documents print control parameters',
  'definition.index': 'Index',
  'definition.index_blanks_between_words': 'Blanks between words',
  'definition.index_blanks_between_words_keep_all': 'Keep all',
  'definition.index_blanks_between_words_keep_one': 'Keep one',
  'definition.index_blanks_between_words_remove_all': 'Remove all',
  'definition.index_convert_values_to_upper': 'Convert index values to uppercase',
  'definition.index_copy_same_id': 'Index ID has to be changed',
  'definition.index_data_format': 'Data format',
  'definition.index_definitions': 'Index definitions',
  'definition.index_document_assignment.security_check': 'Security check',
  'definition.index_document_assignment.values_required': 'Values required',
  'definition.index_document_assignment.variant': 'Variant',
  'definition.index_id': 'Index ID',
  'definition.index_identifier': 'Identifier',
  'definition.index_maxlength': 'Max length',
  'definition.index_name_id': 'Index ID',
  'definition.index_number_of_decimal_places': 'Number of decimal places',
  'definition.index_numeric_left': 'Numeric (left-aligned)',
  'definition.index_numeric_right': 'Numeric (right-aligned)',
  'definition.index_verify': 'Verify index',
  'definition.indexes_error': 'Error on loading index definitions ($0)',
  'definition.initialization_print_control': 'Initialization print control',
  'definition.insert_carriage_return_before_each_line': 'Insert carriage return before each line',
  'definition.insert_carriage_return_before_line_feed': 'Insert carriage return before line feed',
  'definition.insert_page_breaks_after_formatting': 'Insert page breaks after formatting',
  'definition.ipt': 'IP/T',
  'definition.ipt_attributes': 'IP/T - Attributes',
  'definition.jobgroup_loading_error': 'Error on loading job group ($0)',
  'definition.jobgroups': 'Job groups',
  'definition.jobgroups_loading_error': 'Error on loading job groups ($0)',
  'definition.length_input_field': 'Length input field',
  'definition.level_3_archive': 'Level 3 archive',
  'definition.log': 'Log',
  'definition.log_clone_modal_title': 'Clone log definition',
  'definition.log_copy_modal_title': 'Copy log definition',
  'definition.log_create_modal_title': 'Create log definition',
  'definition.log_modify_modal_title': 'Modify log definition',
  'definition.logs_error': 'Error on loading logs definitions ($0)',
  'definition.lpd_queue': 'LPD queue',
  'definition.lpd_queue.check': 'Check',
  'definition.lpd_queue.del_lpd_queue': 'Delete LPD queue',
  'definition.lpd_queue.delete_tooltip': 'Spooling and Transfer needs to be disabled in order to delete',
  'definition.lpd_queue.id': 'LPD queue ID',
  'definition.lpd_queue.owner': 'Owner',
  'definition.lpd_queue.question_del_lpd_queue': 'Do you really want to delete this LPD queue?',
  'definition.lpd_queue.spooling': 'Spooling',
  'definition.lpd_queue.transfer': 'Transfer',
  'definition.lpd_queue_definitions': 'LPD queue definitions',
  'definition.lpd_queues_error': 'Error on loading LPD queues definitions ($0)',
  'definition.modify_archive_pool': 'Modify archive pool',
  'definition.modify_archive_pool_error': 'Error on modifying archive pool "$0" ($1)',
  'definition.modify_archive_pool_success': 'Archive pool "$0" modified',
  'definition.modify_custom_dialog': 'Modify custom dialog',
  'definition.modify_custom_dialog_error': 'Error on modifying custom dialog "$0" ($1)',
  'definition.modify_custom_dialog_success': 'Custom dialog "$0" modified',
  'definition.modify_document_definition_error': 'Error on modifying document definition "$0" ($1)',
  'definition.modify_document_definition_success': 'Document definition "$0" modified',
  'definition.modify_document_node': 'Modify document node',
  'definition.modify_externalcmd': 'Modify external command',
  'definition.modify_filter_argument': 'Modify filter argument',
  'definition.modify_folder': 'Modify folder',
  'definition.modify_jobgroup': 'Modify job group',
  'definition.modify_log_definition_error': 'Error on modifying log definition "$0" ($1)',
  'definition.modify_log_definition_success': 'Log definition "$0" modified',
  'definition.modify_output_channel': 'Modify output channel',
  'definition.modify_output_channel_error': 'Error on modifying output channel definition "$0" ($1)',
  'definition.modify_output_channel_success': 'Output channel definition "$0" modified',
  'definition.modify_output_format': 'Modify output format',
  'definition.modify_ppn': 'Modify postprocessing note',
  'definition.modify_result_table': 'Modify result table',
  'definition.modify_result_table_error': 'Error on modifying result table "$0" ($1)',
  'definition.modify_result_table_success': 'Result table "$0" modified',
  'definition.modify_searchargument_error': 'Error on modifying search argument "$0" ($1)',
  'definition.modify_searchargument_success': 'Search argument "$0" ($1) modified',
  'definition.modify_searchargument_title': 'Modify search argument',
  'definition.modify_view_profile': 'Modify view profile',
  'definition.multi_file_processing': 'Multi-file processing',
  'definition.node': 'Node',
  'definition.node_has_child_items': 'Node has child items',
  'definition.node_id': 'Node ID',
  'definition.node_selection': 'Node selection',
  'definition.off': 'Off',
  'definition.on': 'On',
  'definition.optional_parameters': 'Optional parameters',
  'definition.output_channel': 'Output channel',
  'definition.output_channel.title': 'Select Output channel',
  'definition.output_channel_id': 'Output channel ID',
  'definition.output_channel_id_created': 'Output channel "$0" created',
  'definition.output_channel_pjl_info': 'Only available whith protocol: PJL',
  'definition.output_channel_verify': 'Verify output channel',
  'definition.output_command': 'Output command',
  'definition.output_format_copy_same_id_error': 'Output format ID has to be changed',
  'definition.output_format_defaults': 'Output format defaults',
  'definition.output_format_id': 'Output format ID',
  'definition.output_format_id_required': 'Please enter an output format ID',
  'definition.output_format_values': 'Values',
  'definition.output_format_verify': 'Verify output format',
  'definition.output_in_utf8': 'Output in UTF-8',
  'definition.outputchannel': 'Output channels',
  'definition.outputchannel.alternative_id': 'Alternative output channel ID',
  'definition.outputchannel.id': 'Output channel ID',
  'definition.outputchannel.only_active_alternatives': 'Only active altern. output channels',
  'definition.outputchannel.type_any': 'Any',
  'definition.outputchannel.type_application': 'Application',
  'definition.outputchannel.type_cmd': 'Command',
  'definition.outputchannel.type_ipt': 'IP/T',
  'definition.outputchanneldefinitions': 'Output channel definitions',
  'definition.outputchannels_error': 'Error on loading output channel definitions ($0)',
  'definition.outputformat': 'Output formats',
  'definition.output_format': 'Output format',
  'definition.output_format.title': 'Select output format',
  'definition.outputformatdefinitions': 'Output format definitions',
  'definition.outputformats_error': 'Error on loading output format definitions ($0)',
  'definition.owner': 'Owner',
  'definition.page': 'Page',
  'definition.parameters_and_values': 'Parameters and values',
  'definition.port': 'Port',
  'definition.post_processing_note': 'Postprocessing notes',
  'definition.post_processing_note_definition': 'Postprocessing note definitions',
  'definition.postprocessing_command': 'Postprocessing command',
  'definition.postprocessing_note': 'Postprocessing note',
  'definition.postprocessing_note_id': 'Postprocessing note ID',
  'definition.postprocessing_note_verify': 'Verify postprocessing note',
  'definition.ppn.del_ppn': 'Delete postprocessing note',
  'definition.ppn.question_del_ppn': 'Do you really want to delete this Postprocessing note?',
  'definition.ppn_copy_same_id_error': 'Post processing note ID has to be changed',
  'definition.ppn_id': 'Postprocessing note ID',
  'definition.ppn_print_control_filename': 'Print control file name',
  'definition.ppndefinitions': 'Postprocessing note definitions',
  'definition.ppns_error': 'Error on loading post processing note definitions ($0)',
  'definition.preparation_command': 'Preparation command',
  'definition.preview': 'Preview',
  'definition.print_control_and_banner_file_prefix': 'Print control and banner file prefix',
  'definition.print_control_parameters_pic': 'Print control parameters (PIC)',
  'definition.print_info_error': 'Error loading the print information',
  'definition.print_lp': 'Print LP: lp -s -c -dprinter {%s}',
  'definition.print_lpr_folder': 'Print LPR: lpr -Pprinter &MFP_FOLDER./data.*',
  'definition.print_lpr_print': 'Print LPR: lpr -PPrinter {%s}',
  'definition.protocol': 'Protocol',
  'definition.provide_output_as_separate_files_in_working_directory': 'Provide output as separate files in working directory',
  'definition.question_del_output_channel': 'Do you really want to delete this output channel?',
  'definition.question_del_output_format': 'Do you really want to delete this output format?',
  'definition.question_delete_custom_dialog': 'Do you really want to delete this custom dialog?',
  'definition.question_delete_document': 'Do you really want to delete this document?',
  'definition.question_delete_document_node': 'Do you really want to delete the document node?',
  'definition.question_delete_filter_argument': 'Do you really want to delete this filter argument?',
  'definition.question_delete_index': 'Do you really want to delete this index?',
  'definition.question_delete_jobgroup': 'Do you really want to delete the job group?',
  'definition.question_delete_log': 'Do you really want to delete this log?',
  'definition.question_delete_result_table': 'Do you really want to delete this result table?',
  'definition.question_delete_search_argument': 'Do you really want to delete this search argument?',
  'definition.question_delete_view_profile': 'Do you really want to delete this view profile?',
  'definition.queue_name': 'Queue name',
  'definition.recipient': 'Recipient',
  'definition.recipient_banner': 'Recipient banner',
  'definition.recipient_no_childs_possible_for_alias': 'No children possible for type "Alias"',
  'definition.recipient_trailer': 'Recipient trailer',
  'definition.recipientdefinitions': 'Recipient definitions',
  'definition.recipients_error': 'Error on loading recipient definitions ($0)',
  'definition.report': 'Report',
  'definition.report.title': 'Select report',
  'definition.result_table': 'Result table',
  'definition.result_table_column_identifier': 'Column identifier',
  'definition.result_table_column_value': 'Column value',
  'definition.result_table_column_width': 'Column width',
  'definition.result_table_default_objects_error': 'Error on loading result table default objects ($0)',
  'definition.result_table_definitions': 'Result table definitions',
  'definition.result_table_error': 'Error on loading result table ($0)',
  'definition.result_table_field_format': 'Field format',
  'definition.result_table_field_length': 'Field length',
  'definition.result_table_field_name': 'Field name',
  'definition.result_table_field_value': 'Field value',
  'definition.result_table_id': 'Result table ID',
  'definition.result_table_modify_object': 'Modify object',
  'definition.result_table_objects_error': 'Error on loading result table objects ($0)',
  'definition.result_tables_error': 'Error on loading result tables ($0)',
  'definition.retry_period_minutes': 'Retry period (minutes)',
  'definition.save_as_cat': 'Save as ... cat {%s} > ../tmp/&FORM..&EXT..&REPORT',
  'definition.save_as_cp': 'Save as .... cp &MFP_FOLDER./* ../tmp',
  'definition.search_argument': 'Search argument',
  'definition.search_argument_burst_1': 'Burst-Window 1',
  'definition.search_argument_burst_2': 'Burst-Window 2',
  'definition.search_argument_burst_3': 'Burst-Window 3',
  'definition.search_argument_burst_4': 'Burst-Window 4',
  'definition.search_argument_burst_5': 'Burst-Window 5',
  'definition.search_argument_burst_6': 'Burst-Window 6',
  'definition.search_argument_burst_7': 'Burst-Window 7',
  'definition.search_argument_burst_8': 'Burst-Window 8',
  'definition.search_argument_case': 'Case',
  'definition.search_argument_column_position': 'Column position',
  'definition.search_argument_copy_same_id': 'Search argument ID has to be changed',
  'definition.search_argument_exclude_formula': 'Exclude formula',
  'definition.search_argument_on_page': 'On page',
  'definition.search_argument_operator': 'Operator',
  'definition.search_argument_stop': 'Search argument stop',
  'definition.search_argument_value': 'Search argument value',
  'definition.search_argument_values_available': 'Available search argument values',
  'definition.search_argument_values_disabled_info': 'Required modify dialog and type "Value"',
  'definition.search_argument_values_to_copy': 'Search argument values to copy',
  'definition.search_argument_verify': 'Verify search argument',
  'definition.search_arguments': 'Search arguments',
  'definition.searchargument_add_searchargument_value': 'Add search argument value',
  'definition.searchargument_attached_searchargument_values': 'Attached search argument values',
  'definition.searchargument_case_sensitive': 'Case sensitive',
  'definition.searchargument_columnposition_for_searchpattern': 'Column position for search pattern',
  'definition.searchargument_comparison_operator': 'Comparison operator',
  'definition.searchargument_error': 'Error on loading search argument definition ($0)',
  'definition.searchargument_id': 'Search argument ID',
  'definition.searchargument_save_searchargument_value_as_utf8': 'Save "Search argument value" as UTF-8',
  'definition.searchargument_searchargument_value': 'Search argument value',
  'definition.searchargument_tle_name': 'TLE name',
  'definition.searchargument_value_type': 'Search argument value type',
  'definition.skeleton_file': 'Skeleton (.skl file)',
  'definition.start_date': 'Start Date',
  'definition.sublog': 'Sub-Log',
  'definition.table_of_contents': 'Table of contents',
  'definition.termination_print_control': 'Termination print control',
  'definition.timed_seconds': 'Timed (seconds)',
  'definition.title': 'Title',
  'definition.transfer_documents_only': 'Transfer documents only',
  'definition.type': 'Type',
  'definition.update_document_node_error': 'Error on modifying document node definition "$0" ($1)',
  'definition.update_document_node_success': 'Document node definition "$0" modified',
  'definition.update_ext_command_error': 'Error on modifying external command definition "$0" ($1)',
  'definition.update_ext_command_success': 'External command definition "$0" modified',
  'definition.update_filter_argument_error': 'Error on modifying filter argument definition "$0" ($1)',
  'definition.update_filter_argument_success': 'Filter argument definition "$0" modified',
  'definition.update_folder_error': 'Error on modifying folder definition "$0" ($1)',
  'definition.update_folder_success': 'Folder definition "$0" modified',
  'definition.update_index': 'Modify index',
  'definition.update_index_error': 'Error on modifying index definition "$0" ($1)',
  'definition.update_index_success': 'Index definition "$0" modified',
  'definition.update_jobgroup_error': 'Error on modifying job group "$0" ($1)',
  'definition.update_jobgroup_success': 'Job group "$0" modified',
  'definition.update_lpd_queue_error': 'Error on modifying LPD queue definition "$0" ($1)',
  'definition.update_lpd_queue_success': 'LPD queue definition "$0" modified',
  'definition.update_output_format_error': 'Error on modifying output format definition "$0" ($1)',
  'definition.update_output_format_success': 'Output format definition "$0" modified',
  'definition.update_ppn_error': 'Error on modifying post processing note definition "$0" ($1)',
  'definition.update_ppn_success': 'Post processing note definition "$0" modified',
  'definition.update_recipient_error': 'Error on modifying recipient definition "$0" ($1)',
  'definition.update_recipient_success': 'Recipient definition "$0" modified',
  'definition.update_view_profile_error': 'Error on modifying view profile definition "$0" ($1)',
  'definition.update_view_profile_success': 'View profile definition "$0" modified',
  'definition.user_output_generation_record_ogr': 'Use output generation record (OGR)',
  'definition.verbose': 'Verbose',
  'definition.verify_document_error': 'Error on verifying document definition "$0" ($1)',
  'definition.verify_filter_argument_error': 'Error on verifying filter argument',
  'definition.verify_index_error': 'Error on verifying index definition',
  'definition.verify_log_error': 'Error on verifying log definition "$0" ($1)',
  'definition.verify_output_channel_error': 'Error on verifying output channel',
  'definition.verify_output_format_error': 'Error on verifying output format',
  'definition.verify_ppn_error': 'Error on verifying postprocessing note',
  'definition.verify_search_argument_error': 'Error on verifying search argument',
  'definition.view_profile': 'View profile',
  'definition.view_profile_copy_same_id': 'View profile ID has to be changed',
  'definition.view_profile_error': 'Error on loading view profile ($0)',
  'definition.view_profile_id': 'View profile ID',
  'definition.view_profile_search_criteria': 'Search criteria',
  'definition.view_profile_table_1': 'I. Table',
  'definition.view_profile_table_2': 'II. Table',
  'definition.view_profile_test_mode': 'Test mode',
  'definition.view_profile_test_user': 'Test user',
  'definition.view_profile_use_text_as_search_pattern': 'Search pattern',
  'definition.view_profiles_error': 'Error on loading view profiles ($0)',
  'definition.wait_minutes': 'Wait (minutes)',
  'definitions.custom_dialog_element_blocked': '"$0" is blocked by "$1".',
  'definitions.custom_dialog_element_blocked_sdate': '"$0" is blocked by "$1" with data format "DATEDATE".',
  'definiton.archive_pool_file_managed_by': 'File managed by',
  'documentinformation.generaltab': 'General',
  'documentinformation.generaltab_docuser1': 'DocUser 1',
  'documentinformation.generaltab_docuser2': 'DocUser 2',
  'documentinformation.generaltab_docuser3': 'DocUser 3',
  'documentinformation.generaltab_docuser4': 'DocUser 4',
  'documentinformation.generaltab_docuser5': 'DocUser 5',
  'documentinformation.generaltab_docuser6': 'DocUser 6',
  'documentinformation.generaltab_docuser7': 'DocUser 7',
  'documentinformation.generaltab_docuser8': 'DocUser 8',
  'documentinformation.generaltab_owner': 'Owner',
  'documentinformation.generaltab_saveutf': 'Save UTF-8 encoded',
  'documentinformation.generaltab_title': 'Title',
  'documentinformation.header_afp': 'AFP',
  'documentinformation.header_blocks': 'Blocks',
  'documentinformation.header_checksum': 'Checksum',
  'documentinformation.header_controlcharacters': 'Control characters',
  'documentinformation.header_doctype': 'DocType',
  'documentinformation.header_documentid': 'Document ID',
  'documentinformation.header_dynamicreport': 'Dyn. Report',
  'documentinformation.header_ebcdic': 'EBCDIC',
  'documentinformation.header_encoding': 'Encoding',
  'documentinformation.header_filename': 'Filename',
  'documentinformation.header_jobname': 'Jobname',
  'documentinformation.header_maxreordlength': 'Max record length',
  'documentinformation.header_obtained': 'Obtained',
  'documentinformation.header_orginaldatetime': 'Org. date/time',
  'documentinformation.header_owner': 'Owner',
  'documentinformation.header_pages': 'Pages',
  'documentinformation.header_searchmode': 'Search mode',
  'documentinformation.header_tablereferencecharacter': 'Table reference character',
  'documentinformation.modaltitle': 'Document information',
  'documentinformation.ogrtab': 'OGR',
  'documentinformation.ogrtab_title': 'OGR information',
  'documentinformation.onlineretention': 'Online retention',
  'documentinformation.pointerstab': 'Pointers',
  'documentinformation.pointerstab_archivepointer': 'Archive pointer',
  'documentinformation.pointerstab_archivetextpointer': 'Archive text pointer',
  'documentinformation.pointerstab_externalindexdataspoolpointer': 'External index data spool pointer',
  'documentinformation.pointerstab_indexpointer': 'Index pointer',
  'documentinformation.pointerstab_listtimestamp': 'List timestamp (LTOKEN)',
  'documentinformation.pointerstab_reporttimestamp': 'Report timestamp (RTOKEN)',
  'documentinformation.pointerstab_ressourcearchivepointer': 'Resource archive pointer',
  'documentinformation.pointerstab_ressourcespoolpointer': 'Resource spool pointer',
  'documentinformation.pointerstab_spoolpointer': 'Spool pointer',
  'documentinformation.pointerstab_textpointer': 'Text pointer',
  'documentinformation.pointerstab_title': 'Pointers and timestamps',
  'documentinformation.retentions': 'Retentions',
  'documentinformation.retentiontab': 'Retention',
  'documentinformation.retentiontab_additionalinformation': 'Additional information',
  'documentinformation.retentiontab_archived': 'Archived',
  'documentinformation.retentiontab_archiveexperation': 'Archive expiration',
  'documentinformation.retentiontab_archiveexperationdate': 'Archive expiration date',
  'documentinformation.retentiontab_archivenotes': 'Archive notes',
  'documentinformation.retentiontab_archiveretention': 'Archive retention',
  'documentinformation.retentiontab_disk': 'Disk',
  'documentinformation.retentiontab_generations': 'Generations',
  'documentinformation.retentiontab_indexarchived': 'Index archived',
  'documentinformation.retentiontab_indexonline': 'Index online',
  'documentinformation.retentiontab_lastdocumentaccess': 'Last document access',
  'documentinformation.retentiontab_lastnoteaccess': 'Last note access',
  'documentinformation.retentiontab_notesonline': 'Notes online',
  'documentinformation.retentiontab_online': 'Online',
  'documentinformation.retentiontab_onlineexpiration': 'Online expiration',
  'documentinformation.retentiontab_opticaldisk': 'Optical Disk',
  'documentinformation.retentiontab_references': 'References',
  'documentinformation.retentiontab_tape': 'Tape',
  'documentinformation.statustab': 'Status',
  'documentinformation.statustab_annotation': 'Annotation',
  'documentinformation.statustab_controlstatus': 'Control status',
  'documentinformation.statustab_controluser': 'Control user',
  'documentinformation.statustab_editingicon': 'Editing icon',
  'documentprint.copies': 'Copies',
  'documentprint.default': 'Default',
  'documentprint.from_page': 'From page',
  'documentprint.header_copies': 'Copies',
  'documentprint.header_datetime': 'Date / Time',
  'documentprint.header_doctype': 'DocType',
  'documentprint.header_dynamicreport': 'Dynamic report',
  'documentprint.header_encoding': 'Encoding',
  'documentprint.header_pages': 'Pages',
  'documentprint.header_title': 'Title',
  'documentprint.modaltitle': 'Print document',
  'documentprint.outputchannel': 'Output channel',
  'documentprint.outputformat': 'Output format',
  'documentprint.postprocessingnote': 'Postprocessing note ID',
  'documentprint.print': 'Print',
  'documentprint.print_param': 'Print parameter',
  'documentprint.priority': 'Priority',
  'documentprint.recipient': 'Recipient',
  'documentprint.to_page': 'To page',
  'documentprint.user': 'User',
  'documents.document_download_error': 'Error on download document ($0)',
  'documents.documents_error': 'Error on searching documents ($0)',
  'documents.documents_print_error': 'Error on printing document "$0" ($1)',
  'documents.documents_print_multiple_success': 'Documents printed on "$0"',
  'documents.documents_print_success': 'Document "$0" printed on "$1"',
  'documents.get_document_error': 'Error on getting document "$0" ($1)',
  'documents.modify_document_success': 'Document "$0" $1 tab modified',
  'documents.modify_document_success_log': 'Log "$0" $1 tab modified',
  'documents.print_info_error': 'Error on getting print information',
  'documents.reload_document': 'Reload document',
  'documents.reload_document_retention': 'Online retention after reload (days)',
  'documents.reprintbundle_error': 'Error on reprint or rebundle "$0"',
  'documents.rescan_error': 'Error on rerun rescan "$0"',
  'documents.update_document_error': 'Error on modifying document "$0" ($1)',
  'documents.update_document_error_log': 'Error on modifying log "$0" ($1)',
  'documents.update_document_success_mark': 'Document "$0" marked for "$1"', // TODO: use msg for un/mark
  'documents.update_document_success_unmark': 'Document "$0" unmarked for "$1"', // TODO: use msg for un/mark
  'documents.update_documentinformation_success': 'Document "$0" modified',
  'documentviewer.document_already_opened': 'This document is already opened in the DocViewer',
  'documentviewer.document_will_be_opened': 'This document will be opened in the DocViewer',
  'documentviewer.document_display': 'Display',
  'documentviewer.hex_view': 'HEX view',
  'documentviewer.hex_view_configuration': 'HEX view configuration',
  'documentviewer.hex_view_disabled': 'HEX view is not available for jobs',
  'documentviewer.no_cached_data': 'No cached data available',
  'documentviewer.no_data': 'No data available',
  'documentviewer.print_not_available': 'Print is not available for jobs',
  'documentviewer.print_not_available_sidefile': 'Print is not available for side files',
  'documentviewer.remove_index_highlight': 'Remove index highlight on page ',
  'documentviewer.removed_index_highlight': 'Index highlight was removed',
  'documentviewer.send_email': 'Send as E-mail',
  'documentviewer.selection_tooltip.start_row_column': 'Start row/column:',
  'documentviewer.selection_tooltip.end_row_column': 'End row/column:',
  'documentviewer.selection_tooltip.length': 'Length:',
  'documentviewer.session_expired': 'The session has expired, please log in again',
  'documentviewer.unexpected_error': 'Unexpected error ',
  'emptyresult.create_archive_pool_link': 'You can create a new archive pool.',
  'emptyresult.create_custom_dialog_link': 'You can create a new custom dialog.',
  'emptyresult.create_document_link': 'You can create a new document.',
  'emptyresult.create_document_node_link': 'You can create a new document node.',
  'emptyresult.create_externaldcmd_link': 'You can create a new external command.',
  'emptyresult.create_filter_argument_link': 'You can create a new filter argument.',
  'emptyresult.create_folder_document_assignment': 'You can create a new folder <-> document assignment.',
  'emptyresult.create_folder_link': 'You can create a new folder.',
  'emptyresult.create_group_link': 'You can create a new group.',
  'emptyresult.create_index_document_assignment': 'You can create a new index <-> document assignment.',
  'emptyresult.create_index_link': 'You can create a new index.',
  'emptyresult.create_jobgroup_link': 'You can create a new job group.',
  'emptyresult.create_log_link': 'You can create a new log.',
  'emptyresult.create_lpd_queue_link': 'You can create a new LPD queue.',
  'emptyresult.create_outputchannel_link': 'You can create a new output channel.',
  'emptyresult.create_outputformat_link': 'You can create a new output format',
  'emptyresult.create_ppn_link': 'You can create a new postprocessing note.',
  'emptyresult.create_recipient_document_assignment': 'You can create a new recipient <-> document assignment.',
  'emptyresult.create_recipient_link': 'You can create a new recipient.',
  'emptyresult.create_remote_user_link': 'You can create a new remote user.',
  'emptyresult.create_result_table_link': 'You can create a new result table.',
  'emptyresult.create_search_argument_link': 'You can create a new search argument.',
  'emptyresult.create_security_profile_link': 'You can create a new security profile.',
  'emptyresult.create_user_link': 'You can create a new user.',
  'emptyresult.create_view_profile_link': 'You can create a new view profile.',
  'emptyresult.no_archive_pool_result': 'No archive pool matches your seach criteria.',
  'emptyresult.no_custom_dialog_result': 'No custom dialog matches your search criteria.',
  'emptyresult.no_document_node_result': 'No document node matches your search criteria.',
  'emptyresult.no_document_result': 'No document matches your seach criteria.',
  'emptyresult.no_external_cmd_result': 'No external command matches your search criteria.',
  'emptyresult.no_filter_argument_result': 'No filter argument matches your search criteria.',
  'emptyresult.no_folder_document_assignment_result': 'No folder <-> document assignment matches your search criteria.',
  'emptyresult.no_folder_result': 'No folder matches your search criteria.',
  'emptyresult.no_groups_result': 'No group matches your search criteria.',
  'emptyresult.no_index_document_assignment_result': 'No index <-> document assignment matches your search criteria.',
  'emptyresult.no_index_result': 'No Index matches your search criteria.',
  'emptyresult.no_jobgroup_result': 'No job group matches your search criteria.',
  'emptyresult.no_log_result': 'No log matches your seach criteria.',
  'emptyresult.no_lpd_queue_result': 'No LPD queue matches your search criteria.',
  'emptyresult.no_outputchannel_result': 'No output channel matches your search criteria.',
  'emptyresult.no_outputformat_result': 'No output format matches your search criteria.',
  'emptyresult.no_ppn_result': 'No postprocessing note matches your search criteria.',
  'emptyresult.no_recipient_document_assignment_result': 'No recipient <-> document assignment matches your search criteria.',
  'emptyresult.no_recipient_result': 'No recipient matches your search criteria.',
  'emptyresult.no_remote_users_result': 'No remote user matches your search criteria.',
  'emptyresult.no_result_headline': 'No search results',
  'emptyresult.no_result_table_result': 'No result table matches your search criteria.',
  'emptyresult.no_search_argument_result': 'No search argument matches your search criteria.',
  'emptyresult.no_security_profiles_result': 'No security profile matches your search criteria.',
  'emptyresult.no_users_result': 'No user matches your search criteria.',
  'emptyresult.no_view_profile_result': 'No view profile matches your search criteria.',
  'emptysearchresult.description': 'No documents found',
  'error.from_page': 'Input must be lower or equal',
  'error.infile_required': 'INFILE is required',
  'error.logsource_required': 'LOGSOURCE is required',
  'error.to_page': 'Input must be higher or equal',
  'errorboundary.contact': 'Contact your IT administrators, they can forward the information to Beta Systems Software AG.',
  'errorboundary.goto_startpage': 'Click here to go to the start page.',
  'errorboundary.more_details': 'For more details, open the development interface (F12) and open the console to copy the last error.',
  'errorboundary.unexpected_error_occured': 'An unexpected error occurred!',
  'external_command.arguments': 'Arguments',
  'external_command.command': 'Command',
  'external_command.command_id_same_command_id': 'External command ID has to be changed',
  'external_command.command_required': 'Please enter a command',
  'external_command.description': 'Description',
  'external_command.id': 'External command ID',
  'external_command.id_required': 'Please enter an external command id',
  'filepicker.index_file': 'matching index file',
  'filepicker.index_files': 'matching index files',
  'filterargument.from_col_greater_to_col': 'Value needs to be greater than "From column" value',
  'filterargument.please_enter_a_filter_argument_id': 'Please enter a filter argument ID',
  'general.activate': 'Refresh',
  'general.activation': 'Activation',
  'general.activation_date': 'Activation date',
  'general.active': 'Active',
  'general.add_burst_window': 'Add burst-window',
  'general.add_group_mask': 'Add group mask',
  'general.additional': 'Additional',
  'general.admin': 'Admin',
  'general.afp': 'AFP',
  'general.afp_text_version': 'AFP text version',
  'general.agent': 'Agent',
  'general.agent_name': 'Agent name',
  'general.align_to_left': 'Align to left',
  'general.align_to_right': 'Align to right',
  'general.alignment': 'Alignment',
  'general.all': 'All',
  'general.all_reports': 'All reports',
  'general.alternate': 'Alternate',
  'general.and': 'And',
  'general.any': 'Any',
  'general.api': 'Storage/BMS',
  'general.archive': 'Archived',
  'general.archive_key': 'Archive Key',
  'general.archive_media': 'Archive media',
  'general.as_is': 'As is',
  'general.ascii_ebcdic': 'ASCII/EBCDIC',
  'general.assignedSecurityProfiles': 'Assigned Security profiles',
  'general.assignment': 'Assignments',
  'general.assignment_modal_title': 'assignment',
  'general.assignment_single': 'Assignment',
  'general.attempt_count': 'Attempt count',
  'general.available': 'Available',
  'general.background_hex': 'Background (Hex)',
  'general.betaview_not_configured': 'Document can not be shown, because there is no _beta view configured.',
  'general.black': 'Black',
  'general.block_format': 'Block format',
  'general.blue': 'Blue',
  'general.bseqnr': 'Bseqnr',
  'general.btoken': 'BToken',
  'general.btype': 'B-Type',
  'general.burst_window': 'Burst-Window',
  'general.burst_windows': 'Burst windows',
  'general.by': 'by',
  'general.bytes': 'Size in bytes',
  'general.cancel': 'Cancel',
  'general.canceled': 'Canceled',
  'general.case_sensitive': 'Case sensitive',
  'general.check_status': 'Check status',
  'general.checksum': 'Checksum',
  'general.child_identifier': 'Child identifier',
  'general.click_to_sort_column': 'Click to sort column $0 $1',
  'general.client': 'Client',
  'general.clone': 'Clone',
  'general.close': 'Close',
  'general.close_row': 'Close row',
  'general.code_page': 'Code Page',
  'general.colors': 'Colors',
  'general.column': 'Column',
  'general.column_position': 'Column position',
  'general.columns': 'Columns',
  'general.comma': 'Comma',
  'general.complete': 'Complete',
  'general.completely': 'Completely',
  'general.conditions': 'Conditions',
  'general.confirm': 'Confirm',
  'general.control_user_id': 'Control user ID',
  'general.controlled': 'Controlled',
  'general.copy': 'Copy',
  'general.copy_assignments': 'Copy assignments',
  'general.copy_group_mask': 'Copy group mask',
  'general.cpu_usage': 'CPU usage',
  'general.create_assignment': 'Create',
  'general.create_recipient_through_table_visualization': 'A new entry can be created in visualization: "Table".',
  'general.created': 'Created',
  'general.creation_date': 'Creation date',
  'general.current': 'Current',
  'general.custom': 'Custom',
  'general.custom_dialogs': 'Custom Dialogs',
  'general.data_compression': 'Data compression',
  'general.data_format': 'Data format',
  'general.database': 'Database',
  'general.datacenter': 'Data center',
  'general.date': 'Date',
  'general.date_field': 'Date field',
  'general.date_time': 'Date time',
  'general.dateformat': 'Date format',
  'general.datemask': 'Datemask',
  'general.datemask2': 'Datemask',
  'general.days': 'Days',
  'general.decimal': 'Decimal',
  'general.decimal_mark': 'Decimal mark',
  'general.default': 'Default',
  'general.definition': 'Definition',
  'general.delete': 'Delete',
  'general.delete_from_archive': 'Delete from archive',
  'general.delete_group_mask': 'Delete group mask',
  'general.delete_with_verify': 'Delete with verify',
  'general.delete_without_verify': 'Delete without verify',
  'general.deleted': 'Deleted',
  'general.description': 'Description',
  'general.destination': 'Destination',
  'general.detail': 'Detail',
  'general.detect_matches': 'Detect matches',
  'general.disabled': 'Disabled',
  'general.disk': 'Disk',
  'general.display': 'Display',
  'general.display_as': 'Display as',
  'general.display_document': 'Display document',
  'general.display_documents': 'Display documents',
  'general.display_folders': 'Display folders',
  'general.display_in_beta_view': 'Display in beta view',
  'general.display_indices': 'Display indices',
  'general.display_line_numbers': 'Display line numbers',
  'general.display_mode': 'Display mode',
  'general.display_nodes': 'Display nodes',
  'general.display_objects': 'Display objects',
  'general.display_recipients': 'Display recipients',
  'general.doctype': 'DocType',
  'general.document': 'Document',
  'general.document_modal_title': 'document',
  'general.documents': 'Documents',
  'general.docuser1': 'DocUser 1',
  'general.docuser2': 'DocUser 2',
  'general.docuser3': 'DocUser 3',
  'general.docuser4': 'DocUser 4',
  'general.docuser5': 'DocUser 5',
  'general.docuser6': 'DocUser 6',
  'general.docuser7': 'DocUser 7',
  'general.docuser8': 'DocUser 8',
  'general.docuservars': 'DocUser variables',
  'general.download': 'Download',
  'general.download_as_binary': 'Download as binary',
  'general.download_as_text': 'Download as text',
  'general.dynamic_generated': 'Dynamically generated',
  'general.dynamic_report': 'Dynamic report',
  'general.e_column': 'E-Column',
  'general.e_line': 'E-Line',
  'general.e_page': 'E-Page',
  'general.edit': 'Edit',
  'general.empty_table': 'No data available in table',
  'general.encoding': 'Encoding',
  'general.end': 'End',
  'general.end_date': 'End date',
  'general.end_sequenz': 'End sequence',
  'general.entries': 'entries',
  'general.epages': 'E-Pages',
  'general.equal': 'Equal',
  'general.error': 'Error',
  'general.exclusive': 'Exclusive',
  'general.execute': 'Execute',
  'general.exit_code': 'Exit code',
  'general.expires': 'Expires',
  'general.extension': 'Extension',
  'general.fetch_data_timeout': 'Timeout while fetching data!',
  'general.file': 'File',
  'general.file_extension': 'File extension',
  'general.file_name': 'File name',
  'general.file_name_or_mask': 'Filename or mask',
  'general.filename': 'Filename',
  'general.first_in_line_only': 'First in line only',
  'general.first_in_window_only': 'First in window only',
  'general.fix': 'Fixed',
  'general.folder': 'Folder',
  'general.folder_modal_title': 'folder',
  'general.foreground_hex': 'Foreground (Hex)',
  'general.form': 'Form',
  'general.form_tab': 'Form',
  'general.format': 'Format',
  'general.found_in': 'Found in',
  'general.from': 'From',
  'general.from_column': 'From column',
  'general.from_gen': 'From generation',
  'general.from_line': 'From line',
  'general.from_page': 'From page',
  'general.from_row': 'From row',
  'general.general': 'General',
  'general.global': 'Global',
  'general.greater_equal': 'Greater equal',
  'general.greater_than': 'Greater than',
  'general.green': 'Green',
  'general.group_masks': 'Group masks',
  'general.groupadmin': 'Group admin',
  'general.hex': 'HEX',
  // general headers
  'general.header.activation_date': 'Activation date',
  'general.header.agent': 'Agent',
  'general.header.credentials_name': 'Credential name',
  'general.header.end_date': 'End date',
  'general.header.instance_number': 'Instance number',
  'general.header.job_error': 'Job error',
  'general.header.job_group': 'Job group',
  'general.header.job_name': 'Job name',
  'general.header.job_status': 'Job status',
  'general.header.return_code': 'Return code',
  'general.header.run_user': 'Execution user',
  'general.header.service': 'Service',
  'general.header.source_version': 'Source version',
  'general.header.start_date': 'Start date',
  'general.header.system': 'System',
  'general.header.trigger_by': 'Trigger by',
  'general.header.trigger_name': 'Trigger name',
  'general.header.trigger_date': 'Trigger date',
  'general.header.updated': 'Updated',
  'general.header.user_field_1_label': 'User Field 1 Label',
  'general.header.user_field_1_value': 'User Field 1 Value',
  'general.header.user_field_2_label': 'User Field 2 Label',
  'general.header.user_field_2_value': 'User Field 2 Value',
  'general.header.workflow': 'Workflow',
  //
  'general.hide': 'Hide',
  'general.hierarchy': 'Hierarchy',
  'general.hit_page': 'Hit page',
  'general.hit_range': 'Hit range',
  'general.hits': 'Hits',
  'general.hours': 'Hours',
  'general.hsm': 'HSM',
  'general.identifier': 'Identifier',
  'general.import': 'Import',
  'general.in_length_of': 'In length of',
  'general.include_hit_line': 'Hit line',
  'general.include_lines_after': 'Include lines after',
  'general.include_pages_after': 'Include pages after',
  'general.include_pages_before': 'Include pages before',
  'general.incorrect_date': 'Wrong Dateformat',
  'general.index_modal_title': 'index',
  'general.index_values': 'Index values',
  'general.indexname': 'Index name',
  'general.info': 'Info',
  'general.info_code': 'Info code',
  'general.information': 'Information',
  'general.input_includes_disallowed_chars': 'Unallowed characters',
  'general.input_no_blanks': 'Blank characters not allowed.',
  'general.input_no_start_space': 'Input can not start with a space',
  'general.input_required': 'Input is required',
  'general.insert_child': 'Insert child',
  'general.invalid_date_format': 'Invalid date format',
  'general.invalid_hex_value': 'Valid hex required (e.g. #00FF00)',
  'general.invalid_time_format': 'Invalid time format',
  'general.invalid_value': 'Invalid value',
  'general.invalid_value_length': 'Invalid value length.',
  'general.items_lowercase': 'items',
  'general.job': 'Job',
  'general.job_data': 'Job data',
  'general.job_details': 'Job details',
  'general.job_error': 'Job error',
  'general.job_type': 'Job type',
  'general.jobgroup': 'Job group',
  'general.jobgroup_masks': 'Job group masks',
  'general.jobgroup_name': 'Job group name',
  'general.jobname': 'Jobname',
  'general.jobs': 'Jobs',
  'general.key': 'Key',
  'general.label': 'Label',
  'general.labeled_frame': 'Labeled frame',
  'general.language': 'Language',
  'general.last': 'Last',
  'general.last_access': 'Last access',
  'general.last_changed': 'Last changed',
  'general.last_login': 'Last login',
  'general.last_password_change': 'Last password change',
  'general.launch_date': 'Launch date',
  'general.lc_time': 'LC_TIME format',
  'general.left': 'Left',
  'general.length': 'Length',
  'general.less': 'less',
  'general.less_equal': 'Less equal',
  'general.less_than': 'Less than',
  'general.line': 'Line',
  'general.line_format': 'Line format',
  'general.line_start': 'Line start',
  'general.lines': 'Lines',
  'general.list': 'List',
  'general.list_timestamp': 'List timestamp',
  'general.local': 'Local',
  'general.log': 'Log',
  'general.log_lowercase': 'log',
  'general.log_modal_title': 'log',
  'general.log_source': 'Log source',
  'general.log_source_reader': 'Log source reader',
  'general.logs': 'Logs',
  'general.managed_by': 'Managed by',
  'general.manually': 'Manually',
  'general.mark': 'Mark',
  'general.mark_archive': 'Mark for archive',
  'general.mark_delete': 'Mark for delete',
  'general.mark_reload': 'Mark for reload',
  'general.marked_archive': 'Marked archive',
  'general.marked_delete': 'Marked delete',
  'general.marked_reload': 'Marked reload',
  'general.max_online_retention': 'Maximum online retention',
  'general.media': 'Media',
  'general.migration': 'Migration',
  'general.mimetype': 'Mimetype',
  'general.minutes': 'Minutes',
  'general.modify': 'Modify',
  'general.modify_priority': 'Modify priority',
  'general.more': 'more',
  'general.more_options': 'More options',
  'general.name': 'Name',
  'general.new': 'New',
  'general.new_priority': 'New priority',
  'general.no': 'No',
  'general.no_connection_to_server': 'No connection to server!',
  'general.no_customdialog_create': 'Create a custom dialog.',
  'general.no_customdialog_defined': 'No custom dialog defined.',
  'general.no_data_found': 'No data found.',
  'general.no_entry_selected': 'No entry selected.',
  'general.no_matching_records': 'No matching records found',
  'general.no_options_available': 'No options available',
  'general.no_result_data': 'No result data',
  'general.no_selected_files': 'No selected file(s)',
  'general.node': 'Node',
  'general.node_modal_title': 'node',
  'general.node_parent_nodes': 'Parent nodes',
  'general.none': 'None',
  'general.none_keiner': 'None',
  'general.not_equal': 'Not equal',
  'general.nothing': 'Nothing',
  'general.object_type': 'Object type',
  'general.objects': 'Objects',
  'general.of': 'of',
  'general.only_job_errors': 'Only job errors',
  'general.open_row': 'Open row',
  'general.operator': 'Operator',
  'general.optical_disk': 'Optical disk',
  'general.options': 'Options',
  'general.or': 'Or',
  'general.order': 'Order',
  'general.order_date': 'Order date',
  'general.order_id': 'Order ID',
  'general.output': 'Output',
  'general.output_channel': 'Output channel',
  'general.output_file': 'Output file',
  'general.output_format': 'Output format',
  'general.output_type': 'Output type',
  'general.owner': 'Owner',
  'general.page': 'Page',
  'general.pages': 'Pages',
  'general.page_first': 'First page',
  'general.page_last': 'Last page',
  'general.parameter': 'Parameter',
  'general.parent_name': 'Parent name',
  'general.parent_node': 'Parent node',
  'general.parent_node_id': 'Parent node ID',
  'general.parm': 'Parm',
  'general.partial': 'Partial',
  'general.pattern': 'Pattern',
  'general.pcj_pjl': 'PCL/PJL',
  'general.pdf': 'PDF',
  'general.pending': 'Pending',
  'general.pink': 'Pink',
  'general.please_enter_a_valid_date': 'Please enter a valid date',
  'general.please_enter_a_valid_time': 'Please enter a valid time',
  'general.please_enter_search_pattern': 'Please enter a search pattern',
  'general.please_enter_tle_name': 'Please enter a tle name',
  'general.point': 'Point',
  'general.position': 'Position',
  'general.preferences_saved_session': 'User preferences saved for current session',
  'general.preview_error': 'Error in configuration. Render not possible.',
  'general.preview_tab': 'Preview',
  'general.print_all': 'Print all with default settings',
  'general.print_priority': 'Print priority',
  'general.printed': 'Printed',
  'general.profile_mask': 'Profile mask',
  'general.queue': 'Queues',
  'general.range': 'Range',
  'general.read_in_date': 'Read in date',
  'general.recipient': 'Recipient',
  'general.recipient_modal_title': 'recipient',
  'general.red': 'Red',
  'general.refreshtime_in_seconds': 'Refresh interval in seconds',
  'general.relation': 'Relation',
  'general.relative': 'Relative',
  'general.relative_to': 'Relative to',
  'general.reload': 'Reload',
  'general.report': 'Report',
  'general.report_prefix': 'Report prefix',
  'general.request_all': 'Request all with default settings',
  'general.request_all_current_settings': 'Request all with current settings',
  'general.request_queued': 'Request queued',
  'general.request_result': 'Request result',
  'general.request_status': 'Request status',
  'general.requestor': 'Requestor',
  'general.required': 'Required',
  'general.required_field': 'required',
  'general.reset': 'Reset',
  'general.retention_day_or_month_has_to_be_greater_than_zero': 'Either day or month has to be greater 0',
  'general.retention_period': 'Retention period',
  'general.retention_period_days': 'Retention period (days)',
  'general.retry': 'Retry',
  'general.return_code': 'Return code',
  'general.reuse': 'Reuse',
  'general.revoked': 'Revoked',
  'general.right': 'Right',
  'general.rule': 'Rule',
  'general.run_as': 'Run as',
  'general.run_count': 'Run count',
  'general.run_number': 'Run number',
  'general.run_user': 'Execution user',
  'general.runtime': 'Runtime',
  'general.s_column': 'S-Column',
  'general.s_line': 'S-Line',
  'general.s_page': 'S-Page',
  'general.save': 'Save',
  'general.save_as_iso88591': 'Save as ISO-8859-1',
  'general.save_as_utf8': 'Save as UTF-8',
  'general.search': 'Search',
  // general search
  'general.search.date_field.days': 'Days',
  'general.search.date_field.end': 'End',
  'general.search.date_field.hours': 'Hours',
  'general.search.date_field.launch': 'Launch',
  'general.search.date_field.minutes': 'Minutes',
  'general.search.date_field.start': 'Start',
  'general.search.header.agent': 'Agent',
  'general.search.header.date_field': 'Date field',
  'general.search.header.execution_user': 'Execution user',
  'general.search.header.exit_code': 'Exit code',
  'general.search.header.filter_by_job_errors': 'Filter by job errors',
  'general.search.header.job_group': 'Job group',
  'general.search.header.job_name': 'Job name',
  'general.search.header.job_status': 'Status',
  'general.search.header.job_type': 'Job type',
  'general.search.header.launch': 'Launch',
  'general.search.header.runtime': 'Runtime',
  'general.search.header.service': 'Service',
  'general.search.header.services': 'Services',
  'general.search.header.source_version': 'Source version',
  'general.search.header.status': 'Job status',
  'general.search.header.system': 'System',
  'general.search.header.workflow': 'Workflow',
  'general.search.label.additional': 'Additional',
  'general.search.label.job_data': 'Job data',
  'general.search.label.select_by': 'Select by',
  'general.search.label.time': 'Time',
  'general.search_by_index': 'Search by index',
  'general.search_by_index.operator_and': 'AND',
  'general.search_by_index.operator_or': 'OR',
  'general.search_by_index.search_criteria': 'Search criteria',
  'general.search_by_index.search_results': 'Search results',
  'general.search_pattern_end': 'Search pattern end',
  'general.search_pattern_start': 'Search pattern start',
  'general.search_results': 'Search results',
  'general.search_text': 'Search text',
  'general.search_window': 'Search window',
  'general.searchpattern': 'Search pattern',
  'general.searchpattern_end': 'Search pattern end',
  'general.searchpattern_start': 'Search pattern start',
  'general.sec': 'SEC',
  'general.security': 'Security',
  'general.select': 'Select',
  'general.select_custom_dialog': 'Select custom dialog',
  'general.select_document': 'Select document',
  'general.select_index': 'Select index',
  'general.select_list': 'Select list',
  'general.select_priority': 'Select priority',
  'general.selected': 'selected',
  'general.selected_documents': 'Selected documents',
  'general.selected_files': 'selected files',
  'general.server': 'Server',
  'general.service': 'Service',
  'general.services': 'Services',
  'general.session_expired': 'The session has expired, please log in again',
  'general.settings': 'Settings',
  'general.show_nop': 'Show NOP',
  'general.show_page_header': 'Show page header',
  'general.show_pjl': 'Show PJL',
  'general.show_resources': 'Show resources',
  'general.show_tle': 'Show TLE',
  'general.skip': 'Skip',
  'general.skipped': 'Skipped',
  'general.snmp_status': 'SNMP status',
  'general.snmp_status_action': 'SNMP status action',
  'general.source': 'Source',
  'general.source_version': 'Source version',
  'general.spath': 'Spath',
  'general.specific': 'Specific',
  'general.spinner_error': 'Required: Value > 0',
  'general.standard': 'Standard',
  'general.start': 'Start',
  'general.start_date': 'Start date',
  'general.start_sequenz': 'Start sequence',
  'general.state': 'State',
  'general.statistic': 'Statistics',
  'general.status': 'Status',
  'general.stop': 'Stop',
  'general.string': 'String',
  'general.sub_application': 'Sub application',
  'general.submit_date': 'Submit date',
  'general.subpool': 'Subpool',
  'general.successful': 'Successful',
  'general.suffix': 'Suffix',
  'general.sum': 'Sum',
  'general.system': 'System',
  'general.system_id': 'System Id',
  'general.system_name': 'System name',
  'general.table': 'Table',
  'general.table_header': 'Table header',
  'general.tape': 'Tape',
  'general.target': 'Target',
  'general.text': 'Text',
  'general.text_documents': 'Text documents',
  'general.time': 'Time',
  'general.time_string': 'Time string',
  'general.time_string_format': 'Time string format',
  'general.timemask': 'Timemask',
  'general.title': 'Title',
  'general.title_type': 'Title type',
  'general.titlefromcol': 'Title from column',
  'general.tle': 'TLE',
  'general.to': 'To',
  'general.to_column': 'To column',
  'general.to_gen': 'To generation',
  'general.to_line': 'To line',
  'general.to_page': 'To page',
  'general.to_row': 'To row',
  'general.today': 'Today',
  'general.torquise': 'Torquise',
  'general.total': 'Total',
  'general.type': 'Type',
  'general.type_case': 'Type/Case',
  'general.unit': 'Unit',
  'general.unmark_archive': 'Unmark for archive',
  'general.unmark_delete': 'Unmark for delete',
  'general.unmark_reload': 'Unmark for reload',
  'general.unselect': 'Unselect',
  'general.update': 'Activate',
  'general.userOrGroup': 'User/Group',
  'general.usermanagement': 'User Management',
  'general.utf8': 'UTF-8',
  'general.valid_from': 'Valid from',
  'general.valid_to': 'Valid to',
  'general.value': 'Value',
  'general.values': 'Values',
  'general.variables': 'Variables',
  'general.variant': 'Variant',
  'general.verify': 'Verify',
  'general.verify_error': 'No references found!',
  'general.version': 'Version / API-Version',
  'general.visualization': 'Visualization',
  'general.waiting': 'Waiting',
  'general.width': 'Width',
  'general.workflow': 'Workflow',
  'general.yellow': 'Yellow',
  'general.yes': 'Yes',
  'general.yesterday': 'Yesterday',
  'group.assignedUsers': 'Assigned users',
  'group.available_users': 'Available users',
  'group.group': 'Group',
  'group.group_id': 'Group ID',
  'group.groupname': 'Group name',
  'group.groups': 'Groups',
  'group.searchUsers': 'Search User ID',
  'header.logout': 'Logout',
  'header.logout_error': 'Error on logout ($0)',
  'header.show_help': 'Show help',
  'import.annotation': 'Annotation',
  'import.autoprint': 'Autoprint',
  'import.copies': 'Copies',
  'import.custom_import': 'Custom import',
  'import.editicon': 'Edit icon',
  'import.editicon_clip': 'Clip',
  'import.editicon_error': 'Error',
  'import.editicon_hook': 'Hook',
  'import.editicon_pin': 'Pin',
  'import.editicon_stamp': 'Stamp',
  'import.enc_cp1250': 'CP1250',
  'import.enc_cp1251': 'CP1251',
  'import.enc_cp1252': 'CP1252',
  'import.enc_cp1253': 'CP1253',
  'import.enc_cp1254': 'CP1254',
  'import.enc_cp1256': 'CP1256',
  'import.enc_cp850': 'CP850',
  'import.enc_ibm0420': 'IBM0420',
  'import.enc_ibm1140': 'IBM1140',
  'import.enc_ibm1141': 'IBM1141',
  'import.enc_ibm1147': 'IBM1147',
  'import.enc_ibm1148': 'IBM1148',
  'import.enc_iso8859_1': 'ISO8859-1',
  'import.enc_iso8859_15': 'ISO8859-15',
  'import.enc_iso8859_2': 'ISO8859-2',
  'import.enc_utf_8': 'UTF-8',
  'import.encoding': 'Encoding',
  'import.fileformat_ascii_utf_8': 'Document encoding is ASCII/UTF-8 with MVS CC',
  'import.fileformat_ebcdic_nl': 'Document encoding is EBCDIC with NL = 0x25',
  'import.fileformat_ebcdic_nl_mvscc': 'Document encoding is EBCDIC with NL = 0x25 and MVS CC',
  'import.fileformat_ebcdic_nl_mvscc_trc': 'Document encoding is EBCDIC with NL = 0x25 and MVS CC + TRC',
  'import.fileformat_fileextension': 'Use file extension as filetype',
  'import.import_dialogs': 'Import dialogs',
  'import.import_mode': 'Import mode',
  'import.import_success': 'Files imported',
  'import.index_file_no_unique_match': 'An index file has no unique match',
  'import.no_files_selected_error': 'At least one file must be selected',
  'import.outputchannel': 'Output channel',
  'import.outputformat': 'Output format',
  'import.select_files': 'Select file(s)',
  'import.special_file_format': 'Spec. file format',
  'import.standard_import': 'Standard import',
  'index.please_enter_an_identifier': 'Please enter an identifier',
  'index.please_enter_an_index_id': 'Please enter an index ID',
  'infile_required_and_cannot_be_deleted': 'INFILE is required and cannot be deleted',
  'input.input_does_not_match_to_validation': 'Input does not match to validation!',
  'job.class': 'Class',
  'job.dd_name': 'DD name',
  'job.ds_id': 'Ds ID',
  'job.dsid': 'DS ID',
  'job.end_date': 'Job end date',
  'job.filter_by_job_errors': 'Filter by job errors',
  'job.high_cc': 'High CC',
  'job.job_data': 'Job data',
  'job.jobid': 'Job ID',
  'job.jobname1': 'Jobname 1',
  'job.jobname2': 'Jobname 2',
  'job.jobname3': 'Jobname 3',
  'job.jobname4': 'Jobname 4',
  'job.jobtype': 'Job type',
  'job.log_files': 'Log files',
  'job.messageclass': 'Message class',
  'job.netid': 'Net ID',
  'job.netid1': 'Net ID 1',
  'job.netid2': 'Net ID 2',
  'job.only_job_errors': 'Only job errors',
  'job.proc_step': 'Proc step',
  'job.return_code': 'Job returncode',
  'job.start_date': 'Job start date',
  'job.step_name': 'Step name',
  'job.step_return_code': 'Step returncode',
  'job.submit_date': 'Job submit date',
  'job.submit_time': 'Job submit time',
  'job.submit_user': 'Submit user',
  'job.submitted': 'Submitted',
  'job.timestamp': 'Job timestamp',
  'jobStatus.failed': 'Failed',
  'jobStatus.finished': 'Finished',
  'jobStatus.success': 'Success',
  'jobType.workflow': 'Workflow',
  'jobType.timer': 'Timer',
  'jobType.windows': 'Windows',
  'jobType.linuxWindows': 'Linux/Unix',
  'jobType.zOS': 'z/OS',
  'jobType.agentFileMonitor': 'Agent File Monitor',
  'jobType.manual': 'Manual',
  'jobType.email': 'Email',
  'jobType.fileTransfer': 'File Transfer',
  'jobType.sql': 'SQL',
  'jobType.remoteFileMonitor': 'Remote File Monitor',
  'jobType.taskMonitor': 'Task Monitor',
  'jobType.storedProcedure': 'Stored Procedure',
  'jobType.universalCommand': 'Universal Command',
  'jobType.systemMonitor': 'System Monitor',
  'jobType.applicationControl': 'Application Control',
  'jobType.sap': 'SAP',
  'jobType.variableMonitor': 'Variable Monitor',
  'jobType.webService': 'Web Service',
  'jobType.emailMonitor': 'Email Monitor',
  'jobType.peopleSoft': 'PeopleSoft',
  'jobType.recurring': 'Recurring',
  'jobType.universal': 'Universal',
  'language.en': 'English',
  'language.de': 'German',
  'language.fr': 'French',
  'language.english': 'English',
  'language.french': 'French',
  'language.german': 'German',
  'language.language_selection': 'Language selection',
  'loginscreen.apiversion_loading_error': 'Error loading REST-API version information ($0)',
  'loginscreen.backend_loading_error': 'Error loading server names ($0)',
  'loginscreen.change_password': 'Change password',
  'loginscreen.display_password': 'Display password',
  'loginscreen.hide_password': 'Hide password',
  'loginscreen.login': 'Login',
  'loginscreen.login_error': 'Error on login ($0)',
  'loginscreen.new_password': 'New password',
  'loginscreen.no_backend_systems': 'No backend systems are configured',
  'loginscreen.no_systemname_specified': 'Local default system',
  'loginscreen.password_not_equal_to_new_password': 'Password is not equal to new password!',
  'loginscreen.password_successfully_changed': 'Password changed',
  'loginscreen.please_enter_your_new_password': 'Please enter your new password',
  'loginscreen.please_enter_your_password': 'Please enter your password',
  'loginscreen.please_enter_your_user_data': 'Please enter your user data',
  'loginscreen.please_enter_your_username': 'Please enter your username',
  'loginscreen.repeat_new_password': 'Repeat new password',
  'loginscreen.show_password': 'Show password',
  'loginscreen.systemname': 'System name',
  'logoutdialog.question_save_session_values': 'Do you want to save your session values?',
  'logoutdialog.save_session_values': 'Save session values',
  'logsource_required_and_cannot_be_deleted': 'LOGSOURCE is required and cannot be deleted',
  'lpdqueue.completion_check': 'Completion check',
  'lpdqueue.copy_lpd_queue_same_id_error': 'LPD queue ID has to be changed',
  'lpdqueue.enable_spooling': 'Enable spooling',
  'lpdqueue.enable_transfer': 'Enable transfer',
  'lpdqueue.modal_title_copy': 'Copy LPD queue',
  'lpdqueue.modal_title_create': 'Create LPD queue',
  'lpdqueue.modal_title_modify': 'Modify LPD queue',
  'lpdqueue.please_enter_a_lpdqueue_id': 'Please enter a LPD queue ID',
  'lpdqueue.please_enter_a_program_path': 'Please enter a program path',
  'lpdqueue.transfer': 'Transfer',
  'lpdqueue.transfer_arguments': 'Arguments',
  'lpdqueue.transfer_arguments_desc_buxip': 'BUXIP - Beta UX IP address (buximport.exe)',
  'lpdqueue.transfer_arguments_desc_buxport': 'BUXPORT - Beta UX port (buximport.exe)',
  'lpdqueue.transfer_arguments_desc_lpcf': 'LPCF - Controle file (path-/filename)',
  'lpdqueue.transfer_arguments_desc_lpdf': 'LPDF - Transfer file (path-/filename)',
  'lpdqueue.transfer_arguments_desc_lpid': 'LPID - Job ID of current request (IP-hex.job.nnn)',
  'lpdqueue.transfer_arguments_desc_lpqn': 'LPQN - Queue name',
  'lpdqueue.transfer_arguments_desc_title': 'Available variables for \'Arguments\'',
  'lpdqueue.transfer_betaend': 'BETAend',
  'lpdqueue.transfer_buximport': 'BUX-Import',
  'lpdqueue.transfer_other': 'Other',
  'lpdqueue.transfer_program': 'Transfer program',
  'lpdqueue.transfer_program_path': 'Transfer program path',
  'maintenance.jobs_error': 'Error on loading predefined jobs ($0)',
  'maintencance.execute_job_error': 'Error on executing job "$0" ($1)',
  'maintencance.execute_utility_error': 'Error on executing utility "$0" ($1)',
  'maintencance.get_joboutput_error': 'Error on getting job output for PID "$0" ($1)',
  'messagelogs.message_logs_error': 'Error on loading message logs ($0)',
  'nosearch.description': 'You have not performed a search yet',
  'nosearch.no_result_data': 'No result data',
  'preferences.load_error': 'Error on loading user preferences ($0)',
  'preferences.save_error': 'Error on saving user preferences ($0)',
  'print.skip_disabled': 'Print all with default settings is enabled',
  'queue.auto_print': 'Auto print',
  'queue.automatically_requeued': 'Automatically requeued',
  'queue.batch_bundle': 'Batch bundle',
  'queue.batch_print': 'Batch print',
  'queue.btoken': 'BToken',
  'queue.bundle': 'Bundle queue',
  'queue.bundle_name': 'Bundle name',
  'queue.bundle_queue_error': 'Error on loading bundle queue ($0)',
  'queue.bundlejobname': 'Bundle jobname',
  'queue.delete_bundle_queue_error': 'Error on deleting bundle queue ($0)',
  'queue.delete_bundle_queue_success': 'Bundle request deleted',
  'queue.delete_bundle_request': 'Delete bundle request',
  'queue.delete_index_queue_error': 'Error on deleting index queue ($0)',
  'queue.delete_index_queue_success': 'Index request deleted ($0)',
  'queue.delete_index_request': 'Delete index request',
  'queue.delete_reload_queue_error': 'Error on deleting reload queue "$0" ($1)',
  'queue.delete_reload_queue_success': 'Reload request deleted ($0)',
  'queue.delete_reload_request': 'Delete reload request',
  'queue.deprecated_value_ouputqueue_application': 'Deprecated configuration value detected. Reselection required.',
  'queue.documentimported': 'Document imported',
  'queue.error_message': 'Error message',
  'queue.get_bundle_queue_details_error': 'Error on getting the details for bundle queue "$0" ($1)',
  'queue.get_index_queue_details_error': 'Error on getting the details for index queue "$0" ($1)',
  'queue.get_reload_bundle_details_error': 'Error on loading bundle request "$0" ($1)',
  'queue.get_reload_queue_details_error': 'Error on getting the details for reload queue "$0" ($1)',
  'queue.hold_bundle_queue_error': 'Error on hold bundle request "$0" ($1)',
  'queue.hold_bundle_queue_success': 'Bundle request held ($0)',
  'queue.index': 'Index queue',
  'queue.index_information': 'Index request information',
  'queue.index_queue_error': 'Error on loading index queue ($0)',
  'queue.index_rerun_error': 'Error on rerunning index request ($0)',
  'queue.index_rerun_success': 'Rerun successfully (DOCID: "$0")',
  'queue.insert_index_request_error': 'Error on inserting index request ($0)',
  'queue.insert_index_request_success': 'Index request successfully inserted',
  'queue.insert_printbundle_request_success': 'Print/Bundle request successfully inserted',
  'queue.insert_rescan_request_success': 'Rescan request successfully inserted',
  'queue.lpd': 'LPD queue',
  'queue.lpd_queue_command_error': 'Error on running the command on LPD queue',
  'queue.lpd_queue_rerun_request': 'Rerun LPD queue request',
  'queue.lpd_queue_remove_request': 'Remove LPD queue request',
  'queue.manually_requeued': 'Manually requeued',
  'queue.online_print': 'Online print',
  'queue.output': 'Output queue',
  'queue.output_channel_if_not_specified_or_inactive': 'Output channel if not specified or inactive',
  'queue.output_information': 'Output request information',
  'queue.output_queue_cancel_error': 'Error on canceling request (Output channel ID: "$0") ($1)',
  'queue.output_queue_cancel_success': 'Request canceled (Output channel ID: "$0")',
  'queue.output_queue_details_error': 'Error on loading output queue details ($0)',
  'queue.output_queue_error': 'Error on loading output queue ($0)',
  'queue.output_queue_hold_error': 'Error on holding request (Output channel ID: "$0") ($1)',
  'queue.output_queue_hold_success': 'Request held (Output channel ID: "$0")',
  'queue.output_queue_mark_as_delete_error': 'Error on mark as delete request (Output channel ID: "$0") ($1)',
  'queue.output_queue_mark_as_delete_success': 'Request marked as delete (Output channel id ID: "$0")',
  'queue.output_queue_modify_priority_error': 'Error on modify priority (Output channel ID: "$0")',
  'queue.output_queue_modify_priority_success': 'Priority modified (Output channel ID: "$0")',
  'queue.output_queue_monitor_request_error': 'Error on execute request ($0)',
  'queue.output_queue_release_error': 'Error on releasing request (Output channel ID: "$0") ($1)',
  'queue.output_queue_release_request': 'Release request',
  'queue.output_queue_release_success': 'Request released (Output channel ID: "$0")',
  'queue.output_queue_request_step_details_error': 'Error on loading output queue request step details ($0)',
  'queue.output_queue_requeue_error': 'Error on requeue (Output channel ID: "$0") ($1)',
  'queue.output_queue_requeue_success': 'Requeue successfully (Output channel ID: "$0", priority: "$1")',
  'queue.output_queue_rerun_error': 'Error on rerun (Output channel ID: "$0") ($1)',
  'queue.output_queue_rerun_success': 'Rerun successfully (Output channel ID: "$0", priority: "$1")',
  'queue.output_status_hold': 'Hold',
  'queue.outputchannel_id_document': 'Output channel ID/Document',
  'queue.pages': 'Pages',
  'queue.print_begin': 'Print begin',
  'queue.print_bundle': 'Print/Bundle',
  'queue.print_end': 'Print end',
  'queue.print_information': 'Print information',
  'queue.priority': 'Priority',
  'queue.question_delete_bundle_request': 'Do you really want to delete this bundle request?',
  'queue.question_delete_index_request': 'Do you really want to delete this index request?',
  'queue.question_delete_reload_request': 'Do you really want to delete this reload request?',
  'queue.queuedby': 'Queued by',
  'queue.release': 'Release',
  'queue.release_bundle_queue_error': 'Error on unhold bundle request "$0" ($1)',
  'queue.release_bundle_queue_success': 'Bundle request released ($0)',
  'queue.reload': 'Reload queue',
  'queue.reload_information': 'Reload request information',
  'queue.reload_queue_error': 'Error on loading reload queue ($0)',
  'queue.requestqueued': 'Request queued',
  'queue.requeue': 'Requeue',
  'queue.requeue_output_queue_request': 'Requeue output queue request',
  'queue.requeued': 'Requeued',
  'queue.rerun': 'Rerun',
  'queue.rerun_index_queue_request': 'Rerun index queue request',
  'queue.rerun_options': 'Rerun options',
  'queue.rerun_output_queue_request': 'Rerun output queue request',
  'queue.reset_bundle_queue_error': 'Error on reset bundle request "$0" ($1)',
  'queue.reset_bundle_queue_success': 'Bundle request reset ($0)',
  'queue.show_information': 'Show information',
  'queue.snmp': 'SNMP',
  'queue.specific_priority': 'Specific priority',
  'queue.spooling_begin': 'Spooling begin',
  'queue.spooling_end': 'Spooling end',
  'queue.spooling_failed': 'Spooling failed',
  'queue.srq': 'SRQ',
  'queue.status': 'Status',
  'queue.transfer_begin': 'Transfer begin',
  'queue.transfer_end': 'Transfer end',
  'queue.transfer_failed': 'Transfer failed',
  'queue.waiting_for_autoprint': 'Waiting for autoprint',
  'queue.which_output_channel_to_use': 'Which output channel to use',
  'queues.get_bundles_error': 'Error on loading bundle queues ($0)',
  'queues.only_status_hold': 'Only status hold',
  'queues.output_queue_error_check_status': 'Error on checking server status ($0)',
  'queues.output_queue_ipp_check_status': 'Check status',
  'queues.output_queue_ipp_request_result': 'Request result',
  'queues.output_queue_ipp_server_status': 'IPP server status',
  'queues.output_queue_ipp_status_action': 'IPP status action',
  'queues.output_queue_ipp_verbose_output': 'Verbose output',
  'queues.output_queue_server_attributes': 'Attributes',
  'queues.output_queue_server_job_information': 'Job information',
  'queues.output_queue_server_status': 'Job status',
  'queues.output_queue_status': 'Status',
  'queues.output_queue_validate_job': 'Validate job',
  'recipient.additional': 'Additional',
  'recipient.address': 'Address',
  'recipient.address_tab_address_text': 'Address text',
  'recipient.address_tab_email_address': 'E-Mail address',
  'recipient.banner': 'Banner',
  'recipient.banner_tab_banner_file_name': 'Banner file name',
  'recipient.banner_tab_banner_file_name_banner': 'Banner',
  'recipient.banner_tab_banner_file_name_trailer': 'Trailer',
  'recipient.banner_tab_banner_text': 'Banner text',
  'recipient.banner_tab_banner_text_banner_text_1': 'Banner text 1',
  'recipient.banner_tab_banner_text_banner_text_2': 'Banner text 2',
  'recipient.banner_tab_banner_text_banner_text_3': 'Banner text 3',
  'recipient.banner_tab_banner_text_banner_text_4': 'Banner text 4',
  'recipient.banner_tab_banner_text_banner_text_5': 'Banner text 5',
  'recipient.banner_tab_banner_text_banner_text_6': 'Banner text 6',
  'recipient.banner_tab_banner_text_banner_text_7': 'Banner text 7',
  'recipient.banner_tab_banner_text_banner_text_8': 'Banner text 8',
  'recipient.banner_tab_print_control_file_name': 'Print control file name',
  'recipient.copy_recipient_same_recipient_id': 'Recipient ID has to be changed',
  'recipient.delete_recipient': 'Delete recipient',
  'recipient.distribute_all': 'Always distributed',
  'recipient.distribute_any': 'Any',
  'recipient.distribute_autop': 'Autoprint',
  'recipient.distribute_bundle': 'Bundle',
  'recipient.distribute_online': 'Online',
  'recipient.distribution_via': 'Distribution via',
  'recipient.general': 'General',
  'recipient.general_tab_predecessor_id': 'Predecessor ID',
  'recipient.modal_title_copy': 'Copy recipient',
  'recipient.modal_title_create': 'Create recipient',
  'recipient.modal_title_modify': 'Modify recipient',
  'recipient.output_channel': 'Output channel',
  'recipient.output_format': 'Output format',
  'recipient.owner': 'Owner',
  'recipient.please_enter_a_banner_text_1': 'Please enter a banner text',
  'recipient.please_enter_a_recipientid': 'Please enter a recipient id',
  'recipient.postprocessing_note': 'Postprocessing note',
  'recipient.predecessor': 'Predecessor',
  'recipient.predecessor_id': 'Predecessor ID',
  'recipient.print': 'Print',
  'recipient.print_tab_print_banner_and_trailer': 'Print banner and trailer',
  'recipient.print_tab_separate_recipient_banner_trailer_for_online_autoprint': 'Separate recipient banner/trailer for online/autoprint',
  'recipient.print_tab_suppress_document_banner_trailer_for_bundling': 'Suppress document banner/trailer for bundling',
  'recipient.print_tab_suppress_postprocessing_note_in_packets': 'Suppress postprocessing note in packets',
  'recipient.print_tab_suppress_recipient_banner_trailer_for_bundling': 'Suppress recipient banner/trailer for bundling',
  'recipient.print_tab_suppress_trailers': 'Suppress trailers',
  'recipient.question_delete_recipient': 'Do you really want to delete the recipient?',
  'recipient.reci_id': 'Recipient ID',
  'recipient.switch_button.print_banner_and_trailer.no': 'No',
  'recipient.switch_button.print_banner_and_trailer.once': 'Once',
  'recipient.switch_button.print_banner_and_trailer.twice': 'Twice',
  'recipient.type': 'Type',
  'recipient.type_addr': 'Address',
  'recipient.type_alias': 'Alias',
  'recipient.type_any': 'Any',
  'recipient.type_reci': 'Recipient',
  'remoteuser.betaux_user_id': 'BetaUX user ID',
  'remoteuser.ip4mask': 'IPv4 mask',
  'remoteuser.ip6mask': 'IPv6 mask',
  'remoteuser.ipmask': 'IP Mask',
  'remoteuser.unixuser': 'Unix user',
  'rerun.auto_bundle_process_for_new_reports': 'Bundle process for new reports',
  'rerun.auto_print_for_new_reports': 'Automatic print process for new reports',
  'rerun.for_filter': 'For filter',
  'rerun.for_recipient': 'For recipient',
  'rerun.for_report': 'For report',
  'rerun.force_auto_print': 'Force automatic print',
  'rerun.process_to_rerun': 'Process to rerun',
  'rerun.scan': 'Scan',
  'rerun.skip_disabled': 'Request all with default settings is enabled',
  'search.archived': 'Archived',
  'search.autoprint': 'Autoprint',
  'search.b93ux': 'B93UX',
  'search.control': 'Control',
  'search.controlm': 'Control-M',
  'search.controlm_loading_error': 'Error on loading Control-M jobs ($0)',
  'search.controlstatus': 'Control status',
  'search.controluser': 'Control user',
  'search.ctm_details_error': 'Error on getting details of Control-M job "$0" ($1)',
  'search.custom_search': 'Custom selection',
  'search.documentstatus': 'Document status',
  'search.edf': 'EDF',
  'search.editing_icon': 'Editing icon',
  'search.filtered_by_rc': 'Filtered by return code',
  'search.hold': 'Hold',
  'search.list': 'List',
  'search.marked_for_delete': 'Marked for delete',
  'search.marked_for_reload': 'Marked for reload',
  'search.multiple_documents_were_marked_for_archive': 'Documents were marked for archive.',
  'search.multiple_documents_were_marked_for_delete': 'Documents were marked for delete.',
  'search.multiple_documents_were_marked_for_reload': 'Documents were marked for reload.',
  'search.multiple_documents_were_unmarked_for_archive': 'Documents were unmarked for archive.',
  'search.multiple_documents_were_unmarked_for_delete': 'Documents were unmarked for delete.',
  'search.multiple_documents_were_unmarked_for_reload': 'Documents were unmarked for reload.',
  'search.multiple_logs_were_marked_for_archive': 'Logs were marked for archive.',
  'search.multiple_logs_were_marked_for_delete': 'Logs were marked for delete.',
  'search.multiple_logs_were_marked_for_reload': 'Logs were marked for reload.',
  'search.mvs': 'MVS',
  'search.notes': 'Notes',
  'search.obtained': 'Obtained',
  'search.online': 'Online',
  'search.outputstatus': 'Output status',
  'search.outputstatus_active': 'Active (A)',
  'search.outputstatus_canceled': 'Canceled (C)',
  'search.outputstatus_delete': 'Deleted (D)',
  'search.outputstatus_error': 'Error (E)',
  'search.outputstatus_hold': 'Hold (H)',
  'search.outputstatus_notfound': 'Not found (N)',
  'search.outputstatus_printed': 'Printed (P)',
  'search.outputstatus_requeued': 'Requeued (Q)',
  'search.outputstatus_retry': 'Retry (R)',
  'search.outputstatus_waiting': 'Waiting (W)',
  'search.preview_loading_error': 'Error on loading preview data ($0)',
  'search.question_delete_from_archive': 'Do you really want to permanently delete the document from archive?',
  'search.question_delete_from_archive_log': 'Do you really want to permanently delete the log from archive?',
  'search.reader_date': 'Reader date',
  'search.reload': 'Reload',
  'search.rescan': 'Rescan',
  'search.search_dialog': 'Selection dialogs',
  'search.search_mode': 'Select dialog',
  'search.select_by': 'Select by',
  'search.standard_search': 'Standard selection',
  'search.standard_search_listtimestamp': 'Ltoken',
  'search.standard_search_reporttimestamp': 'Rtoken',
  'search.stonebranch': 'Stonebranch',
  'search.stonebranch_details_error': 'Error on loading Stonebranch jobs ($0)',
  'search.stonebranch_logs_loading_error': 'Error on loading logs for Stonebranch job "$0" ($1)',
  'search.syslog': 'Syslog',
  'search.syslog_details_error': 'Error on getting details of Syslog job "$0" ($1)',
  'search.syslog_loading_error': 'Error on loading Syslog jobs ($0)',
  'search.syslog_logs_loading_error': 'Error on loading logs for Syslog job "$0" ($1)',
  'search.uc4': 'UC4',
  'search.uc4_archive': 'UC4ARC',
  'search.uc4_archive_key_1': 'Archive key 1',
  'search.uc4_archive_key_2': 'Archive key 2',
  'search.uc4_details_error': 'Error on getting details of UC4 job "$0" ($1)',
  'search.uc4_loading_error': 'Error on loading UC4 jobs ($0)',
  'search.uc4_online': 'UC4ONL',
  'search.unhold': 'Unhold',
  'search.zos': 'z/OS',
  'search.zos_details_error': 'Error on loading zOS jobs ($0)',
  'search.zos_details_information_error': 'Error on loading log information for job log (&0)',
  'search.zos_job_information': 'Job information',
  'search.zos_loading_error': 'Error on loading ZOS jobs ($0)',
  'search.zos_logs_loading_error': 'Error on loading logs for zOS job "$0" ($1)',
  'search_argument.please_enter_id': 'Please enter a search argument ID',
  'search_argument.please_enter_report': 'Please enter a report',
  'security.access': 'Access',
  'security.access_alter': 'ALTER',
  'security.access_none': 'NONE',
  'security.access_read': 'READ',
  'security.access_update': 'UPDATE',
  'security.class': 'Class',
  'security.convert_to_upper': 'Convert input to uppercase',
  'security.copy_security_profile': 'Copy security profile',
  'security.create_security_profile': 'Create security profile',
  'security.delete_security': 'Delete Security-Profile',
  'security.description': 'Description',
  'security.entities': 'Entities',
  'security.entity_generation_rules': 'Entity generation rules',
  'security.function': 'Function',
  'security.modify_security_profile': 'Modify security profile',
  'security.please_enter_a_userid_or_groupid': 'Please enter a user or group id',
  'security.profile_category': 'Profile category',
  'security.profile_entry': 'Profile entry',
  'security.profile_entry_*': '**  - Any profile entry',
  'security.profile_entry_*.**': '*.** - All security profiles',
  'security.profile_entry_adm': 'ADM - Maintenance, batch utility load',
  'security.profile_entry_arc': 'ARC - Mark for archive',
  'security.profile_entry_ard': 'ARD - Archive delete',
  'security.profile_entry_bcm': 'BCM - Browse Control-M jobs',
  'security.profile_entry_bju': 'BJU - Browse UC4 jobs',
  'security.profile_entry_bjz': 'BJZ - Browse zOS jobs',
  'security.profile_entry_bna': 'BNA - Bundle for recipient (type addr)',
  'security.profile_entry_bnu': 'BNU - Bundle for recipient (type reci)',
  'security.profile_entry_bra': 'BRA - Browse lists/reports from archive',
  'security.profile_entry_brw': 'BRW - Browse lists/reports from spool',
  'security.profile_entry_bsb': 'BSB - Browse Stonebranch jobs',
  'security.profile_entry_bsl': 'BSL - Browse SYSLOG jobs',
  'security.profile_entry_del': 'DEL - Mark for delete',
  'security.profile_entry_dfa': 'DFA - Define address',
  'security.profile_entry_dfd': 'DFD - Define output channel',
  'security.profile_entry_dff': 'DFF - Define folder',
  'security.profile_entry_dfh': 'DFH - Define document hierarchy',
  'security.profile_entry_dfi': 'DFI - Define index names',
  'security.profile_entry_dfl': 'DFL - Define lists and reports in folder',
  'security.profile_entry_dfn': 'DFN - Define post processing notes',
  'security.profile_entry_dfp': 'DFP - Define output format',
  'security.profile_entry_dfr': 'DFR - Define lists and reports',
  'security.profile_entry_dfs': 'DFS - Define select dialogs',
  'security.profile_entry_dft': 'DFT - Define result tables',
  'security.profile_entry_dfu': 'DFU - Define recipient',
  'security.profile_entry_dfx': 'DFX - Define list and report index',
  'security.profile_entry_dst': 'DST - Change status',
  'security.profile_entry_egr': 'EGR - Edit list or report generation',
  'security.profile_entry_free': 'Free text',
  'security.profile_entry_gln': 'GLN - Select folder',
  'security.profile_entry_hex': 'HEX - Display document hexadecimal',
  'security.profile_entry_imm': 'IMM - Import Mimetype',
  'security.profile_entry_imp': 'IMP - Import lists',
  'security.profile_entry_nte': 'NTE - Add browser notes to lists or reports',
  'security.profile_entry_pcr': 'PCR - Print lists/reports on printer',
  'security.profile_entry_pra': 'PRA - Print lists/reports from archive',
  'security.profile_entry_prf': 'PRF - Print folder',
  'security.profile_entry_prq': 'PRQ - Print queue',
  'security.profile_entry_prt': 'PRT - Print lists/reports from spool',
  'security.profile_entry_rld': 'RLD - Mark for reload',
  'security.profile_entry_rli': 'RLI - Reload immediatly',
  'security.profile_entry_rsc': 'RSC - Rescan online reports',
  'security.profile_entry_rsi': 'RSI - Reindex list / report',
  'security.profile_entry_scm': 'SCM - Select Control-M jobs',
  'security.profile_entry_sec': 'SEC - Security administration',
  'security.profile_entry_sfd': 'SFD - Web and browser only user, select output channel',
  'security.profile_entry_sfh': 'SFH - Select document hierarchy',
  'security.profile_entry_sfp': 'SFP - Web and browser only user, select output format',
  'security.profile_entry_sfr': 'SFR - Web and browser only user, select output recipient',
  'security.profile_entry_shw': 'SHW - Select recipient',
  'security.profile_entry_sju': 'SJU - Select UC4 jobs',
  'security.profile_entry_sjz': 'SJZ - Select zOS jobs',
  'security.profile_entry_spt': 'SPT - Select search dialogs',
  'security.profile_entry_ssb': 'SSB - Select Stonebranch jobs',
  'security.profile_entry_ssi': 'SSI - Select security index',
  'security.profile_entry_ssl': 'SSL - Select SYSLOG jobs',
  'security.profile_entry_sys': 'SYS - Spool administration',
  'security.profile_entry_utl': 'UTL - Select external utilities',
  'security.profile_mask': 'Profile mask',
  'security.profile_mask_invalid_input': 'Invalid input in profile mask:',
  'security.profile_type': 'Profile type',
  'security.profile_type_admin_functions': 'Administrator functions',
  'security.profile_type_all_functions': 'All functions',
  'security.profile_type_job_functions': 'Job functions',
  'security.profile_type_security_functions': 'Security functions',
  'security.profile_type_subadmin_functions': 'Sub-Administrator functions',
  'security.profile_type_system_functions': 'System functions',
  'security.profile_type_user_functions': 'User functions',
  'security.profiles': 'Security profiles',
  'security.question_delete_security': 'Do you really want to delete this Security-Profile?',
  'security.user_group': 'User/Group',
  'security.user_group_id': 'User/Group ID',
  'security_profiles.get_placeholders_error': 'Error on getting the security profile placeholders ($0)',
  'server.accounting': 'Accounting',
  'server.accounting.acc_date': 'Record creation date',
  'server.accounting.acc_time': 'Record creation time',
  'server.accounting.action': 'Action (Archive, Reload, Delete)',
  'server.accounting.agentname': 'Agent',
  'server.accounting.application': 'Application',
  'server.accounting.archivename': 'Archive file name',
  'server.accounting.archiving_document': 'Archiving a document',
  'server.accounting.archiving_reloading_deleting': 'Archiving, reloading and deleting notes',
  'server.accounting.att_count': 'Attempt count',
  'server.accounting.bannerpages': 'Number of banner pages',
  'server.accounting.bjobid': 'Bundle job ID',
  'server.accounting.bjobname': 'Bundle job name',
  'server.accounting.bundle_copies': 'Copies',
  'server.accounting.bundle_print_name_only_print': 'Print/Bundle name',
  'server.accounting.bundle_print_name_print/view': 'Print/Bundle name or ONLINE-VIEW',
  'server.accounting.bytes': 'File size',
  'server.accounting.checkAll': 'All available fields in default order',
  'server.accounting.class': 'Job class',
  'server.accounting.clientname': 'Client',
  'server.accounting.copies': 'Copies',
  'server.accounting.datacenter': 'Data center',
  'server.accounting.dcr_name': 'Output Channel',
  'server.accounting.dcr_owner': 'DCR Owner',
  'server.accounting.dcr_protocol_typ': 'DCR Protocol',
  'server.accounting.dcr_type': 'Type',
  'server.accounting.ddname': 'DD name',
  'server.accounting.deleting_document_archive': 'Deleting a document from the archive',
  'server.accounting.deleting_document_spool': 'Deleting a document from the spool',
  'server.accounting.delivery_init_date': 'Job delivery start date',
  'server.accounting.delivery_init_time': 'Job delivery start time',
  'server.accounting.description': 'Description',
  'server.accounting.document_deletion_date': 'Date document input, Attachment (Note)',
  'server.accounting.document_deletion_time': 'Time document input, Attachment (Note)',
  'server.accounting.document_id': 'Document-ID',
  'server.accounting.end_date': 'End Date',
  'server.accounting.end_time': 'End Time',
  'server.accounting.expiration_date': 'Expiration date',
  'server.accounting.field': 'Field ID',
  'server.accounting.filetype': 'File type',
  'server.accounting.flag': 'Logon/Logoff Flag',
  'server.accounting.folder': 'Folder',
  'server.accounting.idx_cmd': 'IDX-CMD',
  'server.accounting.indexhits': 'Number of index hits',
  'server.accounting.indexies': 'Number of indexes',
  'server.accounting.indexing_document': 'Indexing a document',
  'server.accounting.input_date': 'Date document input',
  'server.accounting.input_time': 'Time document input',
  'server.accounting.ip_client': 'Client IP',
  'server.accounting.ip_server': 'Server IP',
  'server.accounting.job_end_date': 'Job end date',
  'server.accounting.job_end_time': 'Job end time',
  'server.accounting.job_id': 'Job ID',
  'server.accounting.job_start_date': 'Job start date',
  'server.accounting.job_start_time': 'Job start time',
  'server.accounting.job_submit_date': 'Job submit date',
  'server.accounting.job_submit_time': 'Job submit time',
  'server.accounting.jobgroup': 'Job group',
  'server.accounting.jobname': 'Jobname',
  'server.accounting.log_in/out_user': 'Logging in/out of a user',
  'server.accounting.net_id': 'Net ID',
  'server.accounting.number_of_notes': 'Number of notes',
  'server.accounting.obtained_uc4': 'Obtained: UC4ARC, UC4ONL',
  'server.accounting.obtained_zos': 'Obtained: ZOS, ZOSMIG',
  'server.accounting.order_date': 'Order date',
  'server.accounting.orderid': 'Order ID',
  'server.accounting.output_id': 'Output-ID',
  'server.accounting.outputting_document': 'Outputting a document (printing and online-display)',
  'server.accounting.owner': 'Owner',
  'server.accounting.pages': 'Pages',
  'server.accounting.pages_estimated': 'Estimated Pages',
  'server.accounting.print/bundle_document': 'Print/Bundle a document, output channel IP/T, protocol type PJL',
  'server.accounting.read_document': 'Read in a document (including reload)',
  'server.accounting.recipient': 'Recipient',
  'server.accounting.reload_date': 'Date document reload',
  'server.accounting.reload_time': 'Time document reload',
  'server.accounting.retention_period': 'Retention period',
  'server.accounting.returned': 'Returned',
  'server.accounting.runcount': 'Run count',
  'server.accounting.runnumber': 'Run number',
  'server.accounting.select_date/index': 'Select by Date/Index',
  'server.accounting.select_records': 'Select accounting records',
  'server.accounting.selectaccountingrecords': 'Select accounting records',
  'server.accounting.selected': 'Selected',
  'server.accounting.spool_blocks': 'Number of spool blocks',
  'server.accounting.start_date': 'Start Date',
  'server.accounting.start_time': 'Start Time',
  'server.accounting.sublevel': 'Sublevel',
  'server.accounting.submit_date': 'Job submit date',
  'server.accounting.submit_time': 'Job submit time',
  'server.accounting.submit_user': 'Submit user',
  'server.accounting.sysid': 'System ID',
  'server.accounting.systemname': 'System',
  'server.accounting.type': 'Type',
  'server.accounting.type050.title': 'Logging in/out of a user',
  'server.accounting.type100.title': 'Read in a document (including reload)',
  'server.accounting.type101.title': 'Outputting a document (printing and online-display)',
  'server.accounting.type102.title': 'Indexing a document',
  'server.accounting.type103.title': 'Print/Bundle a document,output channel IP/T, protocol type PJL',
  'server.accounting.type104.title': 'Select by Date/Index',
  'server.accounting.type105.title': 'Deleting a document from the spool',
  'server.accounting.type110.title': 'Archiving a document',
  'server.accounting.type120.title': 'Deleting a document from the archive',
  'server.accounting.type130.title': 'Archiving, reloading and deleting notes',
  'server.accounting.type200.title': 'Read in a ZOS job log',
  'server.accounting.type210.title': 'Read in a UC4 job',
  'server.accounting.type220.title': 'Read in a Control-M job',
  'server.accounting.type230.title': 'Read in a Stonebranch job',
  'server.accounting.user': 'User ID',
  'server.accounting.user_log_in/out_date': 'Logon date',
  'server.accounting.user_log_in/out_time': 'Logon time',
  'server.accounting_load_file_error': 'File format is not a valid accounting-file',
  'server.accounting_opened_Accounting_file': 'Opened accounting-file',
  'server.archive_file_archive_pending': 'Archive pending',
  'server.archive_file_information': 'Archive file information',
  'server.archive_file_level_2': 'Level 2',
  'server.archive_file_managed_by': 'Files managed by',
  'server.archive_file_management': 'Archive file management',
  'server.archive_file_objects_close': 'Close',
  'server.archive_file_objects_f_list': 'F-List',
  'server.archive_file_objects_f_page': 'F-Page',
  'server.archive_file_objects_f_pos': 'F-Pos',
  'server.archive_file_objects_l_page': 'L-Page',
  'server.archive_file_objects_lfnd_no': 'Lfnd-No',
  'server.archive_file_objects_list_no': 'List-No',
  'server.archive_file_objects_obj_len': 'Obj-Len',
  'server.archive_file_objects_obj_no': 'Obj-No',
  'server.archive_file_objects_order': 'Order',
  'server.archive_file_objects_rec_no': 'Rec-No',
  'server.archive_files': 'Archive files',
  'server.external_command_executed': '"$0" has been successfully executed',
  'server.external_command_executed_counted': '$0 external commands were successfully executed',
  'server.external_command_executed_fail': 'Error executing external command "$0" ($1)',
  'server.get_archive_file_error': 'Error on getting archive file "$0" ($1)',
  'server.get_archive_objects_error': 'Error on getting archive objects of file "$0" ($1)',
  'server.job_executed_fail': 'Error executing job "$0" ($1)',
  'server.jobs_executed_counted': '$0 jobs were successfully executed',
  'server.load_accounting_file': 'Load accounting file',
  'server.log_file': 'Server log file',
  'server.log_file_error': 'Error on loading log file ($0)',
  'server.lpd_status': 'LPD status',
  'server.message_id': 'Message ID',
  'server.output_processes': 'Output processes',
  'server.output_processes_error': 'Error on loading output processes ($0)',
  'server.print_err': 'Print err',
  'server.print_ok': 'Print ok',
  'server.printer_device_information': 'Printer device information',
  'server.printer_device_information_error': 'Error on loading printer device information',
  'server.request_active': 'Request active',
  'server.request_max': 'Request max',
  'server.spool_err': 'Spool err',
  'server.spool_ok': 'Spool ok',
  'server.system_defaults': 'System defaults',
  'server.trans_err': 'Trans err',
  'server.trans_ok': 'Trans ok',
  'server_activity': 'Server activity',
  'server_activity.active_users': 'Active users',
  'server_activity.active_users_application': 'Application',
  'server_activity.active_users_ip': 'IP',
  'server_activity.active_users_logon_time': 'Login time',
  'server_activity.active_users_pages': 'Pages',
  'server_activity.active_users_user_id': 'User ID',
  'server_activity.backend_encrypted': 'Server communication encrypted',
  'server_activity.backend_unencrypted': 'Server communication unencrypted',
  'server_activity.components': 'Components',
  'server_activity.components_component': 'Component',
  'server_activity.components_ip': 'IP',
  'server_activity.components_lastRequest': 'Last request',
  'server_activity.components_port': 'Port',
  'server_activity.components_process_id': 'Process ID',
  'server_activity.components_programm': 'Program',
  'server_activity.components_work_directory': 'Work directory',
  'server_activity.connection_time': 'Connection time',
  'server_activity.dependencies': 'Dependencies',
  'server_activity.dependencies_application': 'Application',
  'server_activity.dependencies_client_ip': 'Client IP',
  'server_activity.dependencies_client_process_id': 'BQL Client PID',
  'server_activity.dependencies_function': 'Function',
  'server_activity.dependencies_last_request': 'Last request',
  'server_activity.dependencies_process_id': 'BQL Server PID',
  'server_activity.dependencies_user': 'User',
  'server_activity.enqueues': 'Enqueues',
  'server_activity.enqueues_dequeues': 'Dequeues',
  'server_activity.enqueues_enqueues': 'Enqueues',
  'server_activity.enqueues_name': 'Name',
  'server_activity.enqueues_process_id': 'Process ID',
  'server_activity.enqueues_semaphore_ncnt': 'Semaphore NCNT',
  'server_activity.enqueues_semaphore_process_id': 'Semaphore PID',
  'server_activity.enqueues_semaphore_value': 'Semaphore value',
  'server_activity.enqueues_stack': 'Stack',
  'server_activity.search_component': 'Search component',
  'server_activity.search_dependencie': 'Search dependency',
  'server_activity.search_enqueue': 'Search enqueue',
  'server_activity.search_transaction': 'Search transaction',
  'server_activity.search_user': 'Search user',
  'server_activity.servername': 'Server name',
  'server_activity.status': 'Status',
  'server_activity.status_bql_gets': 'BQL Gets',
  'server_activity.status_bql_puts': 'BQL Puts',
  'server_activity.status_bsa_release': 'BSA release',
  'server_activity.status_bsa_release_date': 'BSA release date',
  'server_activity.status_current_proc': 'Current Processes',
  'server_activity.status_deq': 'Dequeues',
  'server_activity.status_enq': 'Enqueues',
  'server_activity.status_forks': 'Forks',
  'server_activity.status_ip_recv': 'IP/Received',
  'server_activity.status_ip_send': 'IP/Send',
  'server_activity.status_locks': 'Locks',
  'server_activity.status_port': 'Port',
  'server_activity.status_proc': 'Active / Max Processes',
  'server_activity.status_recv_in_byte': 'Received in Byte',
  'server_activity.status_send_in_byte': 'Send in Byte',
  'server_activity.status_server_start': 'Server start',
  'server_activity.status_start_pid': 'PID (buxstart.exe)',
  'server_activity.status_unlocks': 'Unlocks',
  'server_activity.status_users': 'Users | Remote Users',
  'server_activity.timeshift_to_server': 'Timeshift to server',
  'server_activity.transactions': 'Transactions',
  'server_activity.transactions_dequeues': 'Dequeues',
  'server_activity.transactions_enqueues': 'Enqueues',
  'server_activity.transactions_function': 'Function',
  'server_activity.transactions_gets': 'Gets',
  'server_activity.transactions_lock': 'Lock',
  'server_activity.transactions_memory': 'Memory',
  'server_activity.transactions_process_id': 'PID',
  'server_activity.transactions_puts': 'Puts',
  'server_activity.transactions_shared': 'Shared',
  'server_activity.transactions_state': 'Status',
  'server_activity.transactions_tcp_received_bytes': 'TCP received Bytes',
  'server_activity.transactions_tcp_received_packages': 'TCP received Packages',
  'server_activity.transactions_tcp_send_bytes': 'TCP sent Bytes',
  'server_activity.transactions_tcp_send_packages': 'TCP sent Packages',
  'server_activity.transactions_unlock': 'Unlock',
  'snackbar.no_matches': 'No entry matches your search criteria',
  'standard_selection.download_as_hex': 'Download as HEX',
  'standard_selection.download_as_hex_format': 'Download as HEX format',
  'standard_selection.download_as_side_file': 'Download side file',
  'standard_selection.sidefile': 'Sidefile',
  'standard_selection.list_time_stamp_error': 'Hexadecimal input with length 16 required',
  'standardselection.delete_from_archive_error': 'Error on delete from archive document ($0)',
  'standardselection.delete_from_archive_error_log': 'Error on delete from archive log ($0)',
  'standardselection.delete_from_archive_success': 'Document ($0) marked for delete from archive',
  'standardselection.delete_from_archive_success_log': 'Log ($0) marked for delete from archive',
  'standardselection.mark_archive_error': 'Error on mark for archive document ($0) ($1)',
  'standardselection.mark_archive_error_log': 'Error on mark for archive log ($0) ($1)',
  'standardselection.mark_archive_success': 'Document ($0) marked for archive',
  'standardselection.mark_archive_success_log': 'Log ($0) marked for archive',
  'standardselection.mark_delete_error': 'Error on mark for delete document ($0) ($1)',
  'standardselection.mark_delete_error_log': 'Error on mark for delete log ($0) ($1)',
  'standardselection.mark_delete_success': 'Document ($0) marked for delete',
  'standardselection.mark_delete_success_log': 'Log ($0) marked for delete',
  'standardselection.mark_reload_error': 'Error on mark for reload document "$0" ($1)',
  'standardselection.mark_reload_error_log': 'Error on mark for reload log "$0" ($1)',
  'standardselection.mark_reload_success': 'Document ($0) marked for reload',
  'standardselection.mark_reload_success_log': 'Log ($0) marked for reload',
  'standardselection.unmark_archive_error': 'Error on unmark for archive document "$0" ($1)',
  'standardselection.unmark_archive_error_log': 'Error on unmark for archive log "$0" ($1)',
  'standardselection.unmark_archive_success': 'Document ($0) unmarked for archive',
  'standardselection.unmark_archive_success_log': 'Log ($0) unmarked for archive',
  'standardselection.unmark_delete_error': 'Error on unmark for delete document ($0) ($1)',
  'standardselection.unmark_delete_error_log': 'Error on unmark for delete log ($0) ($1)',
  'standardselection.unmark_delete_success': 'Document ($0) unmarked for delete',
  'standardselection.unmark_delete_success_log': 'Log ($0) unmarked for delete',
  'standardselection.unmark_reload_error': 'Error on unmark for reload document ($0) ($1)',
  'standardselection.unmark_reload_error_log': 'Error on unmark for reload log ($0) ($1)',
  'standardselection.unmark_reload_success': 'Document ($0) unmarked for reload',
  'standardselection.unmark_reload_success_log': 'Log ($0) unmarked for reload',
  'statistic.activeusers_error': 'Error on loading active users statistic ($0)',
  'statistic.bsastats_date': 'date',
  'statistic.bsastats_documents_indexed': 'documents indexed',
  'statistic.bsastats_documents_total': 'documents total',
  'statistic.bsastats_error': 'Error on loading BSA statistic ($0)',
  'statistic.bsastats_jobs_total': 'jobs total',
  'statistic.bsastats_logs_indexed': 'logs indexed',
  'statistic.bsastats_logs_total': 'logs total',
  'statistic.bsastats_pages_archived': 'pages archived',
  'statistic.bsastats_pages_in': 'pages input',
  'statistic.bsastats_pages_out': 'pages output',
  'statistic.chart_barchart_hor': 'Bar chart (horizontal)',
  'statistic.chart_barchart_vert': 'Bar chart (vertical)',
  'statistic.chart_linechart': 'Line chart',
  'statistic.charttypes': 'Chart types',
  'statistic.components_error': 'Error on loading components statistic ($0)',
  'statistic.connects_error': 'Error on loading connects statistic ($0)',
  'statistic.documents_indexed': 'Documents indexed',
  'statistic.documents_total': 'Documents total',
  'statistic.enqueues_error': 'Error on loading enqueues statistic ($0)',
  'statistic.jobs_total': 'Jobs total',
  'statistic.logs_indexed': 'Logs indexed',
  'statistic.logs_total': 'Logs total',
  'statistic.logx': 'log|x',
  'statistic.negative_date_difference_error': 'The selected date must be after the previous date.',
  'statistic.pages_and_docs': 'Pages & documents',
  'statistic.pages_archived': 'Pages archived',
  'statistic.pages_in': 'Pages input',
  'statistic.pages_out': 'Pages output',
  'statistic.serverstatus_error': 'Error on loading serverstatus statistic ($0)',
  'statistic.transactions_error': 'Error on loading transactions statistic ($0)',
  'statistic.unit_day': 'Day',
  'statistic.unit_month': 'Month',
  'statistic.unit_recommendation': '(recommended)',
  'statistic.unit_year': 'Year',
  'systemdefaults.bql_trace': 'BQL_TRACE',
  'systemdefaults.bql_trace_error': 'BQL_TRACE_ERROR',
  'systemdefaults.bux_command_trace': 'BUX_COMMAND_TRACE',
  'systemdefaults.bux_doc_selection_by_lri_glr': 'BUX_DOC_SELECTION_BY_LRI_GLR',
  'systemdefaults.index_prio_default': 'INDEX_PRIO_DEFAULT',
  'systemdefaults.index_prio_import': 'INDEX_PRIO_IMPORT',
  'systemdefaults.index_prio_online': 'INDEX_PRIO_ONLINE',
  'systemdefaults.index_prio_rdrbux': 'INDEX_PRIO_RDR_BUX',
  'systemdefaults.index_prio_rdredf': 'INDEX_PRIO_RDREDF',
  'systemdefaults.index_prio_rdrmvs': 'INDEX_PRIO_RDRMVS',
  'systemdefaults.job_group_trace_exec': 'JOB_GROUP_TRACE_EXEC',
  'systemdefaults.job_group_trace_init': 'JOB_GROUP_TRACE_INIT',
  'systemdefaults.modify_success': 'Parameter $0 modified to $1',
  'systemdefaults.modify_systemdefaults': 'Modify system defaults',
  'systemdefaults.not_changeable': 'This parameter can only be changed in the configurations serverside.',
  'systemdefaults.rdr_generate_new_ldr': 'RDR_GENERATE_NEW_LDR',
  'systemdefaults.rdr_ldr_required': 'RDR_LDR_REQUIRED',
  'systemdefaults.reload_auto_threshold': 'RELOAD_AUTO_THRESHOLD',
  'systemdefaults.reload_use_threshold': 'RELOAD_USE_THRESHOLD',
  'systemdefaults.security_level': 'SECURITY_LEVEL',
  'systemdefaults.security_log': 'SECURITY_LOG',
  'systemdefaults.security_trace': 'SECURITY_TRACE',
  'systemdefaults.set_system_default_error': 'Error on changing system default "$0" ($1)',
  'systemdefaults.system_defaults_error': 'Error on loading system defaults ($0)',
  'systemdefaults.tcp_trace_intern': 'TCP_TRACE_INTERN',
  'systemdefaults.tcp_trace_recv': 'TCP_TRACE_RECV',
  'systemdefaults.tcp_trace_send': 'TCP_TRACE_SEND',
  'table.change_table_settings': 'Change table settings',
  'table.create': 'Create',
  'table.default_table_settings': 'Default',
  'table.download_as_csv': 'Download as CSV',
  'table.download_as_pdf': 'Download as PDF',
  'table.fill_screen': 'Fill screen',
  'table.filter_table': 'Filter table',
  'table.help': 'Help',
  'table.next_page': 'Next page',
  'table.of': 'of',
  'table.prev_page': 'Previous page',
  'table.refresh': 'Refresh',
  'table.settings': 'Settings',
  'table.settings_500_per_page': '500 per page',
  'table.settings_available_columns': 'Available columns',
  'table.settings_change_error': 'Error on table settings change.',
  'table.settings_change_success': 'Table settings saved.',
  'table.settings_column_position': 'Visible columns (in specific order)',
  'table.settings_fill_screen': 'Fill screen',
  'table.value_per_page': '$0 per Page',
  'table.view': 'View',
  'tabs.title.close_all': 'Close all',
  'tabs.confirmation_dialog.title': 'Confirmation',
  'tabs.confirmation_dialog.message': 'Are you sure you want to close all tabs?',
  'tooltip.tab_disabled_because_of_authorization': 'Due to security reasons this option is not available',
  'unknown_error_message': 'Unknown error message! (RC=$0 IRC=$1)',
  'upload.error': 'Error on upload ($0)',
  'user.administrator': 'Administrator',
  'user.assignedGroups': 'Assigned groups',
  'user.external_authentication': 'External authentication',
  'user.password': 'Password',
  'user.password_confirm': 'Repeat password',
  'user.password_expired': 'Password expired',
  'user.password_interval': 'Password interval (days)',
  'user.privileges': 'Privileges',
  'user.remoteusers': 'Remote users',
  'user.revoked': 'Revoked',
  'user.searchGroups': 'Search Group ID',
  'user.unassignedGroups': 'Available groups',
  'user.user': 'User',
  'user.user_id': 'User ID',
  'user.username': 'User name',
  'user.users': 'Users',
  'usermanagement.administrators_only': 'Administrators only',
  'usermanagement.bux_user': 'BetaUX user ID',
  'usermanagement.clone_info': 'All groups and security-profile-assignments of "$0" will be assigned to the new user.',
  'usermanagement.clone_user': 'Clone user',
  'usermanagement.clone_user_error': 'Error on cloning user "$0" ($1)',
  'usermanagement.clone_user_same_userid_error': 'User ID has to be changed',
  'usermanagement.clone_user_success': 'User "$0" created (cloned user "$1")',
  'usermanagement.copy_group': 'Copy group',
  'usermanagement.copy_group_same_groupid_error': 'Group ID has to be changed',
  'usermanagement.copy_remote_user': 'Copy remote user',
  'usermanagement.copy_remoteuser_same_unixuser_error': 'Unix user has to be changed',
  'usermanagement.copy_user': 'Copy user',
  'usermanagement.copy_user_same_userid_error': 'User ID has to be changed',
  'usermanagement.create_group': 'Create group',
  'usermanagement.create_group_error': 'Error on creating group "$0" ($1)',
  'usermanagement.create_group_success': 'Group "$0" created',
  'usermanagement.create_remote_user': 'Create remote user',
  'usermanagement.create_remote_user_error': 'Error on creating remote user "$0" ($1)',
  'usermanagement.create_remote_user_success': 'Remote user "$0" created',
  'usermanagement.create_security_profile_error': 'Error on creating security profile "$0" ($1)',
  'usermanagement.create_security_profile_success': 'Security profile "$0" created',
  'usermanagement.create_user': 'Create user',
  'usermanagement.create_user_error': 'Error on creating user "$0" ($1)',
  'usermanagement.create_user_group_assignment_error': 'Error on creating assignment between "$0" and "$1" ($2)',
  'usermanagement.create_user_group_assignment_failed': 'Assignment between "$0" and "$1" failed creation',
  'usermanagement.create_user_group_assignment_success': 'Assignment between "$0" and "$1" created',
  'usermanagement.create_user_success': 'User "$0" created',
  'usermanagement.create_userprofile_error': 'Error on creating user profile for user "$0" ($1)',
  'usermanagement.create_userprofile_success': 'User profile for User "$0" created',
  'usermanagement.delete_group': 'Delete group',
  'usermanagement.delete_group_error': 'Error on deleting group "$0" ($1)',
  'usermanagement.delete_group_success': 'Group "$0" with groupname "$1" deleted',
  'usermanagement.delete_profile': 'Delete profile',
  'usermanagement.delete_remote_user': 'Delete remote user',
  'usermanagement.delete_remote_user_error': 'Error on deleting remote user "$0" ($1)',
  'usermanagement.delete_remote_user_success': 'Remote user deleted (Unix user: "$0", User ID: "$1")',
  'usermanagement.delete_security_profile_error': 'Error on deleting security profile "$0" ($1)',
  'usermanagement.delete_security_profile_success': 'Security profile "$0" deleted',
  'usermanagement.delete_user': 'Delete user',
  'usermanagement.delete_user_error': 'Error on deleting user "$0" ($1)',
  'usermanagement.delete_user_group_assignment_error': 'Error on deleting assignment between "$0" and "$1" ($2)',
  'usermanagement.delete_user_group_assignment_failed': 'Assignment between "$0" and "$1" failed deletion',
  'usermanagement.delete_user_group_assignment_success': 'Assignment between "$0" and "$1" deleted',
  'usermanagement.delete_user_success': 'User "$0" with username "$1" deleted',
  'usermanagement.delete_userprofile': 'Delete user profile',
  'usermanagement.delete_userprofile_no_profile_available': 'The user "$0" has no user profile.',
  'usermanagement.delete_userprofile_success': 'User profile of user "$0" deleted',
  'usermanagement.external_authenticated_only': 'External authenticated only',
  'usermanagement.get_group_assignments_error': 'Error on getting group assignments ($0)',
  'usermanagement.get_group_error': 'Error on getting group "$0" ($1)',
  'usermanagement.get_groups_error': 'Error on getting groups ($0)',
  'usermanagement.get_remote_user_error': 'Error on getting remote user "$0" ($1)',
  'usermanagement.get_remote_users_error': 'Error on getting remote users ($0)',
  'usermanagement.get_security_profile_error': 'Error on getting security profile "$0" ($1)',
  'usermanagement.get_security_profiles_error': 'Error on getting security profiles ($0)',
  'usermanagement.get_user_assignments_error': 'Error on getting user assignments ($0)',
  'usermanagement.get_user_error': 'Error on getting user "$0" ($1)',
  'usermanagement.get_users_error': 'Error on getting users ($0)',
  'usermanagement.ipmask_not_valid': 'Entered IP mask is not valid',
  'usermanagement.modify_group': 'Modify group',
  'usermanagement.modify_remote_user': 'Modify remote user',
  'usermanagement.modify_user': 'Modify user',
  'usermanagement.new_password': 'New password',
  'usermanagement.new_password_confirm': 'Confirm new password',
  'usermanagement.password_confirm_not_equal_to_password': 'Password is not equal',
  'usermanagement.passwords_not_equal': 'Passwords do not match',
  'usermanagement.please_enter_a_buxuser': 'Please enter a betaUX user ID',
  'usermanagement.please_enter_a_groupid': 'Please enter a group id',
  'usermanagement.please_enter_a_ipmask': 'Please enter an IP mask',
  'usermanagement.please_enter_a_password': 'Please enter a password',
  'usermanagement.please_enter_a_unixuser': 'Please enter a unix user',
  'usermanagement.please_enter_a_userid': 'Please enter a user id',
  'usermanagement.question_delete_group': 'Do you really want to delete this group?',
  'usermanagement.question_delete_remote_user': 'Do you really want to delete this remote user?',
  'usermanagement.question_delete_user': 'Do you really want to delete this user?',
  'usermanagement.question_delete_userprofile': 'Do you really want to delete the user profile of the following user?',
  'usermanagement.refresh_security_profile_error': 'Error on refreshing security profiles ($1)',
  'usermanagement.refresh_security_profile_success': 'Security profiles refreshed',
  'usermanagement.refresh_security_profiles_error': 'Error while refreshing the Security profiles', // TODO: Does the server-response sends more information why it fails?! Commited for Sprint9-presentation
  'usermanagement.refresh_security_profiles_success': 'Security profiles refreshed',
  'usermanagement.revoked_users_only': 'Revoked users only',
  'usermanagement.update_group_error': 'Error on modifying group "$0" ($1)',
  'usermanagement.update_group_success': 'Group "$0" modified',
  'usermanagement.update_remote_user_error': 'Error on modifying remote user "$0" ($1)',
  'usermanagement.update_remote_user_success': 'Remote user "$0" modified',
  'usermanagement.update_security_profile_error': 'Error on modifying security profile "$0" ($1)',
  'usermanagement.update_security_profile_success': 'Security profile "$0" modified',
  'usermanagement.update_user_error': 'Error on modifying user "$0" ($1)',
  'usermanagement.update_user_success': 'User "$0" modified',
  'usermanagement.update_userprofile_error': 'Error on modifying user profile of user "$0" ($1)',
  'usermanagement.update_userprofile_success': 'User profile of User "$0" modified',
  'usermanagement.user_subadmin_required': 'Only available as subadministrator',
  'usermanagement.userprofile_basic_tab': 'Basic',
  'usermanagement.userprofile_basic_tab.betaview_role': '_beta view - Role',
  'usermanagement.userprofile_basic_tab.bundle_queue': 'Bundle queue',
  'usermanagement.userprofile_basic_tab.import_dialogs': 'Import dialogs',
  'usermanagement.userprofile_basic_tab.message_log': 'Message log',
  'usermanagement.userprofile_basic_tab.modify_archive_request': 'Modify archive request',
  'usermanagement.userprofile_basic_tab.modify_delete_request': 'Modify delete request',
  'usermanagement.userprofile_basic_tab.modify_reload_request': 'Modify reload request',
  'usermanagement.userprofile_basic_tab.modify_retention_period': 'Modify retention period',
  'usermanagement.userprofile_basic_tab.modify_status': 'Modify status',
  'usermanagement.userprofile_basic_tab.output_queue': 'Output queue',
  'usermanagement.userprofile_basic_tab.permissions': 'Permissions',
  'usermanagement.userprofile_basic_tab.printer_device_info': 'Printer device information',
  'usermanagement.userprofile_basic_tab.rerun_autoprint': 'Rerun autoprint',
  'usermanagement.userprofile_basic_tab.rerun_bundle_request': 'Rerun bundle request',
  'usermanagement.userprofile_basic_tab.search_dialogs': 'Search dialogs',
  'usermanagement.userprofile_basic_tab.standard_import': 'Standard import',
  'usermanagement.userprofile_basic_tab.standard_selection': 'Standard selection',
  'usermanagement.userprofile_basic_tab.start_panel': 'Start panel',
  'usermanagement.userprofile_basic_tab.subadmin': 'Subadministrator',
  'usermanagement.userprofile_create': 'Create user profile',
  'usermanagement.userprofile_import_tab': 'Import',
  'usermanagement.userprofile_import_tab.disabled': 'Disabled',
  'usermanagement.userprofile_import_tab.enabled_standard_import': 'Enable standard import',
  'usermanagement.userprofile_import_tab.jobname': 'Jobname',
  'usermanagement.userprofile_import_tab.required': 'Required',
  'usermanagement.userprofile_modify': 'Modify user profile',
  'usermanagement.userprofile_other_tab': 'Other',
  'usermanagement.userprofile_other_tab.disabled': 'Disabled',
  'usermanagement.userprofile_other_tab.enable_external_command': 'Enable external commands',
  'usermanagement.userprofile_other_tab.enable_message_log': 'Enable message log',
  'usermanagement.userprofile_other_tab.enable_monitor': 'Enable monitor',
  'usermanagement.userprofile_other_tab.required': 'Required',
  'usermanagement.userprofile_other_tab.select_output_channel': 'Select output channel',
  'usermanagement.userprofile_outputqueue_tab': 'Output queue',
  'usermanagement.userprofile_outputqueue_tab.disabled': 'Disabled',
  'usermanagement.userprofile_outputqueue_tab.enable_output_queue': 'Enable output queue',
  'usermanagement.userprofile_outputqueue_tab.requeue_output_channel': 'Requeue output channel',
  'usermanagement.userprofile_outputqueue_tab.required': 'Required',
  'usermanagement.userprofile_outputqueue_tab.select_output_channel': 'Select output channel',
  'usermanagement.userprofile_print_tab': 'Print',
  'usermanagement.userprofile_print_tab.disabled': 'Disabled',
  'usermanagement.userprofile_print_tab.high_prio_print': 'Allow printing with high priority',
  'usermanagement.userprofile_print_tab.output_channel': 'Output channel',
  'usermanagement.userprofile_print_tab.outputformat': 'Output format',
  'usermanagement.userprofile_print_tab.postprocessingnote': 'Postprocessing note',
  'usermanagement.userprofile_print_tab.print_limit_page': 'Print limit (page)',
  'usermanagement.userprofile_print_tab.print_parameter': 'Print parameter',
  'usermanagement.userprofile_print_tab.print_parameter_default': 'Default',
  'usermanagement.userprofile_print_tab.print_parameter_user': 'User',
  'usermanagement.userprofile_print_tab.recipient': 'Print recipient',
  'usermanagement.userprofile_print_tab.required': 'Required',
  'usermanagement.userprofile_tabs_tooltip_disabled': 'Subadministrator required',
  'usermanagement.userprofile_view_tab': 'View',
  'usermanagement.userprofile_view_tab.disabled': 'Disabled',
  'usermanagement.userprofile_view_tab.folder': 'Folder',
  'usermanagement.userprofile_view_tab.jobname': 'Jobname',
  'usermanagement.userprofile_view_tab.recipient': 'Recipient',
  'usermanagement.userprofile_view_tab.required': 'Required',
  'userpreferences.personalize': 'Personalize',
  'userpreferences.profile': 'Profile',
  'userpreferences.user_preferences': 'User preferences',
}