import * as SearchZOSActions from 'redux/actions/SearchZOSActions'
import { LOGOUT_SUCCESS } from '../actions/AuthenticationActions'

const defaultState = {
  zosJobs: undefined,
  zosJobDetails: undefined,
  zosJobLogs: undefined,
  zosJobLog: undefined,
  zosJobLogPrintInfo: undefined,
  zosJobLogInformation: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Get zos jobs
    case SearchZOSActions.GET_ZOS_JOBS_START:
      return { ...state }
    case SearchZOSActions.GET_ZOS_JOBS_SUCCESS:
      return { ...state, zosJobs: action.payload, keepPagination: action.keepPagination }
    case SearchZOSActions.GET_ZOS_JOBS_FAILED:
      return { ...state }
    case SearchZOSActions.NO_ZOS_JOBS_FOUND:
      return { ...state, zosJobs: {} }
      /* ********************************************************************************** */
    // Get zos job log
    case SearchZOSActions.ZOS_JOBS_GET_LOG_START:
      return { ...state }
    case SearchZOSActions.ZOS_JOBS_GET_LOG_SUCCESS:
      return { ...state, zosJobLog: action.payload }
    case SearchZOSActions.ZOS_JOBS_GET_LOG_FAILED:
      return { ...state, zosJobLog: undefined }
    case SearchZOSActions.ZOS_JOBS_NO_LOG_FOUND:
      return { ...state, zosJobLog: {} }
    /* ********************************************************************************** */
    // Get specific zos job
    case SearchZOSActions.GET_ZOS_JOB_START:
      return { ...state }
    case SearchZOSActions.GET_ZOS_JOB_SUCCESS:
      return { ...state, zosJobDetails: action.payload }
    case SearchZOSActions.GET_ZOS_JOB_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get zos job log print info
    case SearchZOSActions.ZOS_JOBS_GET_LOG_PRINT_INFO_START:
      return { ...state }
    case SearchZOSActions.ZOS_JOBS_GET_LOG_PRINT_INFO_SUCCESS:
      return { ...state, zosJobLogPrintInfo: action.payload }
    case SearchZOSActions.ZOS_JOBS_GET_LOG_PRINT_INFO_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get logs of a job
    case SearchZOSActions.GET_ZOS_JOB_LOGS_START:
      return { ...state }
    case SearchZOSActions.GET_ZOS_JOB_LOGS_SUCCESS:
      return { ...state, zosJobLogs: action.payload }
    case SearchZOSActions.GET_ZOS_JOB_LOGS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get zos job log
    case SearchZOSActions.ZOS_GET_JOB_LOG_INFORMATION_START:
      return { ...state }
    case SearchZOSActions.ZOS_GET_JOB_LOG_INFORMATION_SUCCESS:
      return { ...state, zosJobLogInformation: action.payload }
    case SearchZOSActions.ZOS_GET_JOB_LOG_INFORMATION_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    case LOGOUT_SUCCESS:
      return defaultState
    /* ********************************************************************************** */
    case SearchZOSActions.ZOS_JOBS_DOWNLOAD_JOB_START:
      return { ...state }
    case SearchZOSActions.ZOS_JOBS_DOWNLOAD_JOB_SUCCESS:
      return { ...state }
    case SearchZOSActions.ZOS_JOBS_DOWNLOAD_JOB_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer