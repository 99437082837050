import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Label from '../label/Label';

import './Checkbox.scss';

/**
 * Checkbox component created according to
 * _Checkbox_, _Checkbox + Label_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
export default class Checkbox extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM. */
    id: PropTypes.string.isRequired,
    /**
     * Function to be called on a change event.
     * @param {bool} value - current state of checkbox
     */
    onCheck: PropTypes.func.isRequired,
    /** The value of the component. */
    value: PropTypes.bool.isRequired,
    /** The tooltip which will be shown on hover. */
    tooltip: PropTypes.string,
    /** Disables components visually and functionally. */
    disabled: PropTypes.bool,
    /** The text which will be shown to the right of the checkbox. */
    label: PropTypes.string,
    /** Text to be displayed above the checkbox.*/
    title: PropTypes.string,
    /**
    * Sets dot and required word, next to the _title_, which indicates that fields is required.
    * If true - english version is used "required"
    * If string, user provide the word which appears after dot.
    * If _title_ is not set, indicator will not be shown.
    */
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    className: PropTypes.string,
  }

  state = {
    showAnimation: undefined,
    isFocused: false,
    isMouseOver: false,
    changeInComponent: false,
  }


  componentWillReceiveProps = () => {
    if (this.state.changeInComponent) {
      if (!this.state.showAnimation) {
        this.setState({ showAnimation: true });
      }
    }
  }

  onCheckboxChange = () => {
    const { value, onCheck} = this.props;
    this.setState({changeInComponent: true});
    onCheck(!value);
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { changeInComponent, showAnimation, isFocused, isMouseOver } = this.state;
    const { id, value, label, disabled, tooltip, title, required, className } = this.props;
    return (
      <div className={`bux_checkbox_general_container ${className || ''}`}>
        <Label
          id={`${id}_label`}
          title={title}
          required={required}
          disabled={disabled}
        />
        <div className={`bux_form-field bux_checkbox_container ${label && isFocused ? 'cl_checkbox_focused' : ''} ${label && isMouseOver ? 'cl_checkbox_mouseover' : ''}`} title={tooltip}>
          <div className={`bux_checkbox ${!label && isFocused ? 'cl_checkbox_focused' : ''} ${!label && isMouseOver ? 'cl_checkbox_mouseover' : ''}`}>
            {/* standard checkbox which will be rendered behind */}
            <input
              id={`${id}_checkbox_input`}
              disabled={disabled}
              checked={value}
              onChange={(e) => { this.onCheckboxChange(); e.currentTarget.blur() }}
              onFocus={() => this.setState({isFocused: true})}
              onBlur={() => this.setState({ isFocused: false })}
              onMouseOver={() => this.setState({ isMouseOver: true }) }
              onMouseOut={() => this.setState({ isMouseOver: false, showAnimation: false })}
              type='checkbox'
              className={`checkbox_native-control${changeInComponent && isMouseOver && showAnimation ? ' animation' : ''}`}
            />
            {/* svg which will be rendered for animated hook above the standard checkbox */}
            <div className='checkbox_background'>
               <svg className='checkbox_checkmark' viewBox='0 0 24 24'>
                <path className='checkbox_checkmark-path' d='M1.73,12.91 8.1,19.28 22.79,4.59' />
               </svg>
            </div>
          </div>
          {
            // the text of the checkbox
            label &&
            <label
              id={`${id}_checkbox_label`}
              className={`checkbox_label${disabled ? ' disabled_checkbox_label' : ''}`}
              htmlFor={`${id}_checkbox_input`}
              disabled={disabled}
              onMouseOver={() => this.setState({ isMouseOver: true }) }
              onMouseOut={() => this.setState({ isMouseOver: false })}
            >
              {label}
            </label>
          }
        </div>
      </div>
    );
  }
}