import store from 'redux/Store'
import * as DateUtils from 'utils/DateUtils'
import * as Preferences from 'redux/general/Preferences'

/**
 * @description Executes a fetch request to REST-API.
 * @param {String} url
 * @param {Object} fetchOptions
 * @returns
 */
export const restapiRequest = (url, fetchOptions) => {
  if (!url || typeof url !== 'string') {
    console.error('Parameter "url" has to be given and from type string')
  }

  const headerContent = fetchOptions?.headers
  // Delete headers information for request when spreading fetchOptions object to not override headers object
  delete fetchOptions.headers

  const bodyContent = fetchOptions?.body
  // Delete body information for request when spreading fetchOptions object to not override body object
  delete fetchOptions.body

  const fetchOptionsToUse = {
    headers: buildHeader(headerContent),
    // Just add a body key when its not a get request.
    ...(['post', 'put'].includes(fetchOptions?.method?.toLowerCase()) && {
      body: buildBody(bodyContent, fetchOptions?.method)
    }),
    // Just add when fetchOptions is given and an object (typeof null would result in object)
    ...(typeof fetchOptions === 'object' && fetchOptions !== null && fetchOptions)
  }
  // If the bodyContent is given as FormData for upload, then we remove the "Content-Type" because it is set automatically by the browser
  if (bodyContent?.toString() === '[object FormData]') {
    delete fetchOptionsToUse.headers['Content-Type']
  }
  return fetch(
    buildUrl(url, fetchOptions?.method),
    fetchOptionsToUse
  )
}

/**
 * @description Builds the url which is used for the request.
 * @param {String} url
 * @param {String} method
 * @returns {String}
 */
const buildUrl = (url, method) => {
  const prefs = store.getState()?.auth?.serverdata?.preferences
  const lang = prefs && prefs[Preferences.LANGUAGE]
  const date = /* (prefs && prefs[Preferences.DATEMASK]) || */ DateUtils.DDMMYYYY_DOT
  // Automatically adds the language and datemask when a delete or get request is used.
  return ['delete', 'get'].includes(method?.toLowerCase())
    // Decides which character to add, when the query still has parameters or not.
    ? url.concat(`${url.includes('?') ? '&' : '?'}${lang ? `LANGUAGE=${lang}` : ''}&DATEMASK=${date}`)
    : url
}

/**
 * @description Builds the header information which is used for the request.
 * @param {Object} headerContent
 * @returns {Object}
 */
const buildHeader = headerContent => {
  const usertoken = store.getState()?.auth?.serverdata?.token
  return {
    'Content-Type': 'application/json',
    ...(usertoken && { 'Authorization': `Bearer ${usertoken}` }),
    ...headerContent
  }
}

/**
 * @description Builds the body information which is used for the request.
 * @param {Object} bodyContent
 * @param {Object} method
 * @returns {String}
 */
const buildBody = (bodyContent, method) => {
  const prefs = store.getState()?.auth?.serverdata?.preferences
  const lang = prefs && prefs[Preferences.LANGUAGE]
  // If the bodyContent is given as FormData for upload or as an array, we do not stringify the value.
  return bodyContent.toString() === '[object FormData]'
    ? bodyContent
    : Array.isArray(bodyContent)
      ? JSON.stringify(bodyContent)
      : JSON.stringify({
        // Automatically adds the language and datemask when a put or post request is used. For delete and get requests, language and datemask is added in query.
        ...(['put', 'post'].includes(method?.toLowerCase()) && lang && { LANGUAGE: lang, DATEMASK: DateUtils.DDMMYYYY_DOT }),
        ...bodyContent
      })
}