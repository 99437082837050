import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './Footer.scss'

class Footer extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Text content of the footer */
    text: PropTypes.string
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, text } = this.props
    return (
      <footer id={id}>
        <label
          id={`${id}_text`}
          className='el_panel_info_text bux_footer_text'>
          {text}
        </label>
      </footer>
    )
  }
}

export default Footer