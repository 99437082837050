// actions
export const SAVE_LAST_SESSION = 'SAVE_LAST_SESSION'
export const REMOVE_LAST_SESSION = 'REMOVE_LAST_SESSION'

/**
 * Saves values from the last session to get access on a reconnect.
 * @param {String} userid The userid which was last logged in.
 * @param {String} page The last page path where the usertoken expired.
 */
export function saveLastSession(userid, page) {
  return dispatch => {
    dispatch({ type: SAVE_LAST_SESSION, payload: { userid: userid, page: page } })
  }
}

/**
 * Deletes the values from the last session.
 */
export function removeLastSession() {
  return dispatch => {
    dispatch({ type: REMOVE_LAST_SESSION })
  }
}