import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'

export const GET_SYSLOG_JOBS_START = 'GET_SYSLOG_JOBS_START'
export const GET_SYSLOG_JOBS_SUCCESS = 'GET_SYSLOG_JOBS_SUCCESS'
export const GET_SYSLOG_JOBS_FAILED = 'GET_SYSLOG_JOBS_FAILED'
export const NO_SYSLOG_JOBS_FOUND = 'NO_SYSLOG_JOBS_FOUND'

export const SYSLOG_JOBS_DOWNLOAD_JOB_START = 'SYSLOG_JOBS_DOWNLOAD_JOB_START'
export const SYSLOG_JOBS_DOWNLOAD_JOB_SUCCESS = 'SYSLOG_JOBS_DOWNLOAD_JOB_SUCCESS'
export const SYSLOG_JOBS_DOWNLOAD_JOB_FAILED = 'SYSLOG_JOBS_DOWNLOAD_JOB_FAILED'

export const GET_SYSLOG_JOB_START = 'GET_SYSLOG_JOB_START'
export const GET_SYSLOG_JOB_SUCCESS = 'GET_SYSLOG_JOB_SUCCESS'
export const GET_SYSLOG_JOB_FAILED = 'GET_SYSLOG_JOB_FAILED'

export const GET_SYSLOG_JOB_LOGS_START = 'GET_SYSLOG_JOB_LOGS_START'
export const GET_SYSLOG_JOB_LOGS_SUCCESS = 'GET_SYSLOG_JOB_LOGS_SUCCESS'
export const GET_SYSLOG_JOB_LOGS_FAILED = 'GET_SYSLOG_JOB_LOGS_FAILED'
export const NO_SYSLOG_JOB_LOGS_FOUND = 'NO_SYSLOG_JOB_LOGS_FOUND'

export const SYSLOG_JOBS_GET_LOG_START = 'SYSLOG_JOBS_GET_LOG_START'
export const SYSLOG_JOBS_GET_LOG_SUCCESS = 'SYSLOG_JOBS_GET_LOG_SUCCESS'
export const SYSLOG_JOBS_GET_LOG_FAILED = 'SYSLOG_JOBS_GET_LOG_FAILED'
export const SYSLOG_JOBS_NO_LOG_FOUND = 'SYSLOG_JOBS_NO_LOG_FOUND'

export const SYSLOG_JOBS_GET_LOG_PRINT_INFO_START = 'SYSLOG_JOBS_GET_LOG_PRINT_INFO_START'
export const SYSLOG_JOBS_GET_LOG_PRINT_INFO_SUCCESS = 'SYSLOG_JOBS_GET_LOG_PRINT_INFO_SUCCESS'
export const SYSLOG_JOBS_GET_LOG_PRINT_INFO_FAILED = 'SYSLOG_JOBS_GET_LOG_PRINT_INFO_FAILED'

export const SYSLOG_JOBS_DOWNLOAD_LOG_START = 'SYSLOG_JOBS_DOWNLOAD_LOG_START'
export const SYSLOG_JOBS_DOWNLOAD_LOG_SUCCESS = 'SYSLOG_JOBS_DOWNLOAD_LOG_SUCCESS'
export const SYSLOG_JOBS_DOWNLOAD_LOG_FAILED = 'SYSLOG_JOBS_DOWNLOAD_LOG_FAILED'

export const SYSLOG_LOG_UPDATE_LOG_START = 'SYSLOG_LOG_UPDATE_LOG_START'
export const SYSLOG_LOG_UPDATE_LOG_SUCCESS = 'SYSLOG_LOG_UPDATE_LOG_SUCCESS'
export const SYSLOG_LOG_UPDATE_LOG_FAILED = 'SYSLOG_LOG_UPDATE_LOG_FAILED'
export const SYSLOG_LOG_UPDATE_LOG_RESET = 'SYSLOG_LOG_UPDATE_LOG_RESET'

export function getSyslogJobs(fields, searchObj, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_SYSLOG_JOBS_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/jobs?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_SYSLOG_JOBS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_SYSLOG_JOBS_FAILED })
          }
        }
        else {
          dispatch({ type: GET_SYSLOG_JOBS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.syslog_loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_SYSLOG_JOBS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and downloads a single log.
 * @param {Object} queryParams Query params which are used to download the log
 * @param {Object} downloadname Name the save should be saved with
 * @param {Function} callback
 */
export function downloadJob(queryParams, downloadname, callback) {
  const usertoken = store.getState().auth.serverdata.token
  let url = `${Config.REST_API_URL}/api/jobs/job/download?USERTOKEN=${usertoken}&MIMETYPE=save`
  for (const [key, value] of Object.entries(queryParams)) {
    url += `&${key}=${encodeURIComponent(value)}`
  }
  if (downloadname) {
    url += `&RESFILENAME=${encodeURIComponent(downloadname)}`
  }
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    dispatch({ type: SYSLOG_JOBS_DOWNLOAD_JOB_START })
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(url, {
        method: 'get',
        headers: {
          token: undefined
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    cancelablePromise
      .promise
      .then(data => {
        // If data.status 200, document will be returned binary
        if (data.status === 200) {

          // Open download dialog:
          // Create a-tag with href, simulate click and delete it afterward.
          let element = document.createElement('a')
          element.setAttribute('href', data.url)
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)

          dispatch({ type: SYSLOG_JOBS_DOWNLOAD_JOB_SUCCESS })
        }
        else { // Else we expect the result (error) in a json stream
          data.json()
            .then(json => {
              let rc = json.error.rc
              let irc = json.error.irc
              // check general errors
              let error = GeneralErrorHandler.handleResponse(rc, irc, json.error.param, dispatch)
              SnackbarActions.show(error.message, error.type)(dispatch)
              dispatch({ type: SYSLOG_JOBS_DOWNLOAD_JOB_FAILED, payload: { error } })
            })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', undefined, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SYSLOG_JOBS_DOWNLOAD_JOB_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Build and opens the url to open the document in _bet aview.
 * @param {String} betaviewBaseurl URL of _beta view: e.g. http://server.com/betaview
 * @param {String} betaviewFavname Favoritename of _beta view which is connected to the given bux-server
 * @param {Function} callback
 */
export function displayJob(betaviewBaseurl, betaviewFavname, ukey, callback) {
  return dispatch => {
    if (!betaviewBaseurl || !betaviewFavname) { // Baseurl missing
      SnackbarActions.show(Lang.translate('general.betaview_not_configured'), SnackbarActions.TYPE_ERROR)(dispatch)
    }
    else { // Config given
      const token = encodeURIComponent(store.getState().auth.serverdata.token)
      const encodedUkey = encodeURIComponent(ukey)
      const bweurl = `${betaviewBaseurl}${betaviewBaseurl[betaviewBaseurl.length - 1] === '/' ? '' : '/'}opendocumentlink.action?utoken=${token}&ukey=${encodedUkey}&favid=${betaviewFavname}&datemask=DD.MM.YYYY`
      const windowid = 'docbrowser@bwequ4711:8080/betaview'
      window.open(bweurl, windowid)

      if (callback) {
        callback()
      }
    }
  }
}

export function getSyslogJobDetails(fields, jobObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_SYSLOG_JOB_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(jobObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/job?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_SYSLOG_JOB_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_SYSLOG_JOB_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.ctm_details_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_SYSLOG_JOB_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getSyslogJobLogs(jobObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_SYSLOG_JOB_LOGS_START })

    const queryParams = []
    for (const [key, value] of Object.entries(jobObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/joblogs?${queryParams.join('&')}`, { method: 'get' })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_SYSLOG_JOB_LOGS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_SYSLOG_JOB_LOGS_FAILED })
          }
        }
        else {
          dispatch({ type: GET_SYSLOG_JOB_LOGS_SUCCESS, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.zos_logs_loading_error', lang, [jobObj.JOBID, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_SYSLOG_JOB_LOGS_SUCCESS })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets a single log.
 * @param {Object} fields The fields to select (If undefined => Return all fields).
 * @param {String} docid The id of the log, corresponds to LTOKEN + RTOKEN
 * @param {String} process processing instructions, optional.
 * @param {Function} callback The callback which will be called when the request was successful. Can be undefined.
 */
export function getLog(fields, docid, process, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: SYSLOG_JOBS_GET_LOG_START })

    docid = docid.length === 32 ? docid : docid + '0000000000000000'
    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (docid) { queryParams.push(`DOCID=${encodeURIComponent(docid)}`) }
    if (process) { queryParams.push(`PROCESS=${encodeURIComponent(process)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/document?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SYSLOG_JOBS_GET_LOG_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: SYSLOG_JOBS_GET_LOG_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', lang, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SYSLOG_JOBS_GET_LOG_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets print info of a document.
 * @param {Array} docids
 * @param {Function} callback
 */
export function getPrintInfo(docids, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    LoadingSpinnerActions.show()(dispatch)

    dispatch({ type: SYSLOG_JOBS_GET_LOG_PRINT_INFO_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/documents/printinfo?DOCIDS=${docids}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SYSLOG_JOBS_GET_LOG_PRINT_INFO_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: SYSLOG_JOBS_GET_LOG_PRINT_INFO_SUCCESS, payload: jsondata.additionalInfo })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('documents.print_info_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SYSLOG_JOBS_GET_LOG_PRINT_INFO_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and downloads a single log.
 * @param {Object} docid ID of the doucment to download
 * @param {Object} filename Name the save should be saved with
 * @param {Function} callback
 */
export function downloadLog(docid, downloadname, callback) {
  return dispatch => {

    const usertoken = store.getState().auth.serverdata.token

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_START })
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/document/download?USERTOKEN=${usertoken}&DOCID=${docid}&MIMETYPE=save${downloadname ? `&RESFILENAME=${encodeURIComponent(downloadname)}` : ''}`, {
        method: 'get',
        headers: {
          token: undefined
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    cancelablePromise
      .promise
      .then(data => {
        // If data.status 200, document will be returned binary
        if (data.status === 200) {

          // Open download dialog:
          // Create a-tag with href, simulate click and delete it afterward.
          let element = document.createElement('a')
          element.setAttribute('href', data.url)
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)

          dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_SUCCESS })
        }
        else { // Else we expect the result (error) in a json stream
          data.json()
            .then(json => {
              let rc = json.error.rc
              let irc = json.error.irc
              // check general errors
              let error = GeneralErrorHandler.handleResponse(rc, irc, json.error.param, dispatch)
              SnackbarActions.show(error.message, error.type)(dispatch)
              dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_FAILED, payload: { error } })
            })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', undefined, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and downloads a single log.
 * @param {Object} docid ID of the doucment to download
 * @param {Object} downloadname Name the save should be saved with
 * @param {Function} callback
 */
export function downloadLogHex(docid, downloadname, displayMode, showPageHeader, showResources, callback) {
  return dispatch => {
    const usertoken = store.getState().auth.serverdata.token
    const paramTranslator = {
      USERTOKEN: usertoken,
      DOCID: docid,
      MIMETYPE: 'hex',
      HEX: 'YES',
      HEXBLOCK: displayMode,
      HEXPAB: showPageHeader,
      HEXRES: showResources
    }
    if (downloadname) {
      paramTranslator.RESFILENAME = encodeURIComponent(downloadname)
    }

    let url = `${Config.REST_API_URL}/api/documents/document/download?`
    // Get key value pairs from object and lopp them to build the url
    Object.entries(paramTranslator).forEach(([key, value], index) => {
      // Just adds the "&" if its not the first parameter
      let prefix = ''
      if (index > 0) {
        prefix = '&'
      }
      url += `${prefix}${key}=${value}`
    })

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_START })
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(url, {
        method: 'get',
        headers: {
          token: undefined
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    cancelablePromise
      .promise
      .then(data => {
        // If data.status 200, document will be returned binary
        if (data.status === 200) {

          // Open download dialog:
          // Create a-tag with href, simulate click and delete it afterward.
          let element = document.createElement('a')
          element.setAttribute('href', data.url)
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)

          dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_SUCCESS })
          if (callback) {
            callback()
          }
        }
        else { // Else we expect the result (error) in a json stream
          data.json()
            .then(json => {
              let rc = json.error.rc
              let irc = json.error.irc
              // check general errors
              let error = GeneralErrorHandler.handleResponse(rc, irc, json.error.param, dispatch)
              SnackbarActions.show(error.message, error.type)(dispatch)
              dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_FAILED, payload: { error } })
            })
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', undefined, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and downloads a single log.
 * @param {Object} docid ID of the doucment to download
 * @param {Object} downloadname Name the save should be saved with
 * @param {Function} callback
 */
export function downloadLogText(docid, downloadname, mimetype, showNOP, showTLE, showPJL, callback) {
  return dispatch => {
    const usertoken = store.getState().auth.serverdata.token
    const paramTranslator = {
      USERTOKEN: usertoken,
      DOCID: docid,
      MIMETYPE: mimetype,
      DISPTAB: 'YES',
      DISPNOP: showNOP || 'NO',
      DISPTLE: showTLE || 'NO',
      DISPPJL: showPJL || 'NO',
      DISPLINENO: (showNOP === 'YES' || showTLE === 'YES' || showPJL === 'YES') ? 'YES' : 'NO',
    }
    if (downloadname) {
      paramTranslator.RESFILENAME = encodeURIComponent(downloadname)
    }

    let url = `${Config.REST_API_URL}/api/documents/document/download?`
    // Get key value pairs from object and lopp them to build the url
    Object.entries(paramTranslator).forEach(([key, value], index) => {
      // Just adds the "&" if its not the first parameter
      let prefix = ''
      if (index > 0) {
        prefix = '&'
      }
      url += `${prefix}${key}=${value}`
    })

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_START })
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(url, {
        method: 'get',
        headers: {
          token: undefined
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    cancelablePromise
      .promise
      .then(data => {
        // If data.status 200, document will be returned binary
        if (data.status === 200) {

          // Open download dialog:
          // Create a-tag with href, simulate click and delete it afterward.
          let element = document.createElement('a')
          element.setAttribute('href', data.url)
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)

          dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_SUCCESS })
          if (callback) {
            callback()
          }
        }
        else { // Else we expect the result (error) in a json stream
          data.json()
            .then(json => {
              let rc = json.error.rc
              let irc = json.error.irc
              // check general errors
              let error = GeneralErrorHandler.handleResponse(rc, irc, json.error.param, dispatch)
              SnackbarActions.show(error.message, error.type)(dispatch)
              dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_FAILED, payload: { error } })
            })
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', undefined, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SYSLOG_JOBS_DOWNLOAD_LOG_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and updates a log in in zos job logs.
 * @param {*} logDefinition
 * @param {*} searchObj The search object which is used to refresh the job logs data.
 * @param {*} callback The callback which will be called when the request was successful. Can be null.
 */
export function markArchive(logDefinition, searchObj, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: SYSLOG_LOG_UPDATE_LOG_START })
    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: "${logDefinition.FORM}"`
    if (logDefinition.EXT !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: "${logDefinition.EXT}"`
    }
    if (logDefinition.REPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: "${logDefinition.REPORT}"`
    }

    const body = {
      DOCID: logDefinition['DOCID'],
      ARCHIVE: logDefinition['ARCHIVE']
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/document`, {
        method: 'put',
        body: body
      })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SYSLOG_LOG_UPDATE_LOG_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: SYSLOG_LOG_UPDATE_LOG_SUCCESS, payload: jsondata.data })
          if (logDefinition['ARCHIVE'] === 'PENDING') {
            SnackbarActions.show(Lang.translate('standardselection.mark_archive_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          }
          else if (logDefinition['ARCHIVE'] === 'DELETE') {
            SnackbarActions.show(Lang.translate('standardselection.delete_from_archive_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          }
          else {
            SnackbarActions.show(Lang.translate('standardselection.unmark_archive_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          }

          // call callback on success
          if (callback) {
            callback()
          }

          refreshLogs(searchObj)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        if (logDefinition['ARCHIVE'] === 'PENDING') {
          SnackbarActions.show(Lang.translate('standardselection.mark_archive_error', lang, [logDefinition['DOCID'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        }
        else if (logDefinition['ARCHIVE'] === 'DELETE') {
          SnackbarActions.show(Lang.translate('standardselection.delete_from_archive_error', lang, documentMessage, reason), SnackbarActions.TYPE_SUCCESS)(dispatch)
        }
        else {
          SnackbarActions.show(Lang.translate('standardselection.unmark_archive_error', lang, documentMessage, reason), SnackbarActions.TYPE_SUCCESS)(dispatch)
        }
        dispatch({ type: SYSLOG_LOG_UPDATE_LOG_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a document in standard selection.
 * @param {*} logDefinition
 * @param {*} searchObj The search object which is used to refresh the job logs data.
 * @param {*} callback The callback which will be called when the request was successful. Can be null.
 */
export function markDelete(logDefinition, searchObj, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: SYSLOG_LOG_UPDATE_LOG_START })
    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: "${logDefinition.FORM}"`
    if (logDefinition.EXT !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: "${logDefinition.EXT}"`
    }
    if (logDefinition.REPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: "${logDefinition.REPORT}"`
    }

    const body = {
      DOCID: logDefinition['DOCID'],
      DELETE: logDefinition['DELETE']
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/document`, {
        method: 'put',
        body: body
      })
    )
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SYSLOG_LOG_UPDATE_LOG_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: SYSLOG_LOG_UPDATE_LOG_SUCCESS, payload: jsondata.data })
          if (logDefinition['DELETE'] === 'PENDING') {
            SnackbarActions.show(Lang.translate('standardselection.mark_delete_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          }
          else {
            SnackbarActions.show(Lang.translate('standardselection.unmark_delete_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          }

          // call callback on success
          if (callback) {
            callback()
          }
          // refreshEverything(searchObj, docid)(dispatch)
          dispatch({ type: SYSLOG_LOG_UPDATE_LOG_RESET })
          refreshLogs(searchObj)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        if (logDefinition['DELETE'] === 'PENDING') {
          SnackbarActions.show(Lang.translate('standardselection.mark_delete_error', lang, [logDefinition['DOCID'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('standardselection.unmark_delete_error', lang, documentMessage, reason), SnackbarActions.TYPE_SUCCESS)(dispatch)
        }
        dispatch({ type: SYSLOG_LOG_UPDATE_LOG_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Build and opens the url to open the document in _bet aview.
 * @param {String} docid ID of the doucment to open
 * @param {String} betaviewBaseurl URL of _beta view: e.g. http://server.com/betaview
 * @param {String} betaviewFavname Favoritename of _beta view which is connected to the given bux-server
 * @param {Function} callback
 */
export function displayLog(docid, betaviewBaseurl, betaviewFavname, callback) {
  return dispatch => {
    if (!betaviewBaseurl || !betaviewFavname) { // Baseurl missing
      SnackbarActions.show(Lang.translate('general.betaview_not_configured'), SnackbarActions.TYPE_ERROR)(dispatch)
    }
    else { // Config given

      const token = encodeURIComponent(store.getState().auth.serverdata.token)
      const bweurl = `${betaviewBaseurl}${betaviewBaseurl[betaviewBaseurl.length - 1] === '/' ? '' : '/'}opendocumentlink.action?docid=${docid}&favid=${betaviewFavname}&utoken=${token}`
      const windowid = 'docbrowser@bwequ4711:8080/betaview'
      window.open(bweurl, windowid)

      if (callback) {
        callback()
      }
    }
  }
}

const refreshLogs = searchObj => {
  return dispatch => {
    getSyslogJobLogs(searchObj)(dispatch)
  }
}