import React from 'react'
import { DataTable } from '../../../../BetaUX2Web-Components/src';
import { getSortTypes } from 'utils/SortUtils';
import { translate } from 'language/Language';
import { useSelector } from 'react-redux';
import { GlobalState } from 'redux/store.types';
import * as Preferences from '../../../../redux/general/Preferences';

interface DataTableWrapperProps {
  id: string,
  header?: string[],
  data?: string[][],
  dataIds?: string[],
  checkedRows?: string[],
  fillPage: boolean,
  keepPagination?: boolean,
  menu?: boolean,
  selectable?: boolean,
  rowActions: (rowIndex: number) => JSX.Element[],
  onRowClick: (rowIndex: number) => void,
  checkedDropdownItems?: string[],
  onCheckedDropdownAction?: (rows: number[][], index: number) => void,
  additionalTableActions: JSX.Element[],
  openDocViewerCallback: (indexes: number[]) => void
  updateCheckedRowsToParent: (checkedRows: string[]) => void;
}

export const DataTableWrapper = (props: DataTableWrapperProps) => {
  const lang = useSelector((state: GlobalState) => state.auth.serverdata.preferences[Preferences.LANGUAGE])
  const datemask = useSelector((state: GlobalState) => state.auth.serverdata.preferences[Preferences.DATEMASK])

  return (
    <DataTable
      id={props.id}
      // Data:
      checkedRows={props.checkedRows}
      cleanData={props.data}
      columnSortDefs={getSortTypes(props.data, props.header?.length)}
      data={props.data}
      dataIds={props.dataIds}
      datemask={datemask}
      downloadItems={props.checkedDropdownItems}
      header={props.header}
      language={lang}
      // Conditions:
      fillPage={props.fillPage}
      keepPagination={props.keepPagination}
      menu={props.menu}
      selectable={props.selectable ?? true}
      translate={translate}
      // Children:
      additionalInteraction={props.additionalTableActions}
      createActionButtons={props.rowActions}
      // Handler:
      createTableRowAction={(index: number) => props.onRowClick(index)}
      openDocViewerCallback={props.openDocViewerCallback}
      showModal={props.onCheckedDropdownAction}
      updateCheckedRowsToParent={props.updateCheckedRowsToParent}
    />
  )
}