import React, { Component } from 'react';

// Style
import './Dropdown.scss';

// Components
import Label from '../label/Label';
import { DropdownData } from './Dropdown.types';
import { DropdownButton } from './dropdown_elements/DropdownButton';
import { DropdownItem } from './dropdown_elements/DropdownItem';
import { DropdownMenu } from './dropdown_elements/DropdownMenu';
import ErrorMessage from '../error_message/ErrorMessage';
import ReactDOM from 'react-dom';

export interface DropdownProps {
  /** Unique ID for identification in HTML DOM. */
  id: string;
  /** This ID will be used for integration tests to access the HTML element */
  dataTestId?: string | undefined;
  /** List of items */
  items: DropdownData[];
  /**
   * Function to be called on a change selection.
   * @param {number} index - index of current selected item
   * @param {DropdownData} item - current selected item
   */
  onChange?: (index: number, item: DropdownData) => void;
  /** List of items which should be displayed as a first before the _items_*/
  generalItems?: DropdownData[];
  /** Label to be displayed above the button.*/
  title?: string;
  /** Text to be displayed in tooltip when you hover component.*/
  tooltip?: string;
  /** Enable autofocus on render completion.*/
  autoFocus?: boolean;
  /** Error description which will be displayed below the component */
  error?: string;
  /** Set initially selected item */
  activeIndex: number;
  /** Disables components visually and functionally. */
  disabled?: boolean;
  /** Container id */
  containerID?: string;
  /** React ref */
  focusRef?: React.RefObject<HTMLButtonElement>;
  /** Will be shown in case of deprecated values which are not in the item list*/
  placeholder?: string;
  /**
   * Sets dot and required word, next to the _title_, which indicates that fields is required.
   * If true - english version is used "required"
   * If string, user provide the word which appears after dot.
   * If _title_ is not set, indicator will not be shown.
   */
  required?: boolean | string;
}

export interface DropdownState {
  showMenu: boolean;
  keyboardFocusedIndex: number;
}

/**
 * Dropdown component created according to
 * _Dropdown_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
export default class Dropdown extends Component<DropdownProps, DropdownState> {

  state: DropdownState = {
    showMenu: false,
    keyboardFocusedIndex: -1
  };

  wrapperRefButton: React.RefObject<HTMLDivElement> = React.createRef();
  wrapperRefList: React.RefObject<HTMLUListElement> = React.createRef();
  buttonRef: React.RefObject<HTMLButtonElement> = React.createRef();
  itemRefs = this.buildItemRefs();

  /**
   * @description Fallback when index is used which is bigger then items length. Changes the active index back to 0.
   */
  UNSAFE_componentWillUpdate = (nextProps: DropdownProps): void => {
    if (nextProps.activeIndex >= (nextProps.items.length + (nextProps.generalItems ? nextProps.generalItems.length : 0))) {
      this.props.onChange?.(0, nextProps.items[0]);
    }
    this.itemRefs = this.buildItemRefs();
  };

  /**
   * @description Scroll to the selected position
   */
  componentDidUpdate = (): void => {
    if (this.state.showMenu && this.props.activeIndex !== -1) {
      this.itemRefs[this.props.activeIndex]?.current?.scrollIntoView();
    }

    if (this.state.showMenu) {
      if (this.state.keyboardFocusedIndex !== -1) {
        this.itemRefs[this.state.keyboardFocusedIndex]?.current?.focus();
      }
    }
  };

  /**
   * @description Hides the menu if the click of the event is outside of the menu.
   * @param {Object} event The mouse event.
   */
  handleClickOutsideDropdown = (event: MouseEvent): void => {
    if (
      this.wrapperRefButton && this.wrapperRefButton.current && !this.wrapperRefButton.current.contains(event.target as Node) &&
      this.wrapperRefList && this.wrapperRefList.current && !this.wrapperRefList.current.contains(event.target as Node)
    ) {
      this.setState({
        showMenu: false,
        keyboardFocusedIndex: -1
      });
    }
  };

  /**
   * @description Hides the menu if the scroll of the event is outside of the menu.
   * @param {Object} event The mouse event.
   */
  handleScrollOutsideDropdown = (event: Event): void => {
    if (
      this.wrapperRefButton && this.wrapperRefButton.current && !this.wrapperRefButton.current.contains(event.target as Node) &&
      this.wrapperRefList && this.wrapperRefList.current && !this.wrapperRefList.current.contains(event.target as Node)
    ) {
      this.setState({
        showMenu: false,
        keyboardFocusedIndex: -1
      });
    }
  };

  /**
   * @description Event which detect the focus outside of the component.
   */
  handleFocusOutside = (event: FocusEvent): void => {
    if (this.wrapperRefButton && !this.wrapperRefButton.current?.contains(event.target as Node) &&
      this.wrapperRefList && this.wrapperRefList.current && !this.wrapperRefList.current.contains(event.target as Node)) {
      if (this.state.showMenu) {
        this.setState({
          showMenu: false,
          keyboardFocusedIndex: -1
        });
      }
    }
  };

  /**
   * @description Adds a mousedown event listener to hide the menu when clicking outside.
   */
  componentDidMount = (): void => {
    document.addEventListener('mousedown', this.handleClickOutsideDropdown);
    document.addEventListener('focus', this.handleFocusOutside, true);
    document.addEventListener('scroll', this.handleScrollOutsideDropdown, true);
  };

  /**
   * @description Removes the mousedown event listener.
   */
  componentWillUnmount = (): void => {
    document.removeEventListener('mousedown', this.handleClickOutsideDropdown);
    document.removeEventListener('focus', this.handleFocusOutside, true);
    document.removeEventListener('scroll', this.handleScrollOutsideDropdown, true);
  };

  /**
   * @description Builds the ref array
   * @returns {Array}
   */
  buildItemRefs(): React.RefObject<HTMLLIElement>[] {
    const {generalItems, items} = this.props;
    const refs: React.RefObject<HTMLLIElement>[] = [];
    if (generalItems) {
      generalItems.forEach(() => {
        refs.push(React.createRef());
      });
    }
    items.forEach(() => {
      refs.push(React.createRef());
    });

    return refs;
  }

  /**
   * @description Shows / hides the menu.
   */
  handleShowMenu = (): void => {
    this.setState(currState => ({
      showMenu: !currState.showMenu,
      keyboardFocusedIndex: -1
    }));
  };

  /**
   * @description Hides the menu and change the current selection.
   * @param {number} activeIndex The active index.
   * @param {DropdownData | string} item The active value.
   */
  handleOnClickItem = (activeIndex: number, item: DropdownData | string): void => {
    this.handleShowMenu();

    this.props.onChange?.(activeIndex, item);
  };

  /**
   * @description Handles key down for the component, to allow to keyboard navigation
   * "Enter" open and close list popup and also allows to select item from the list.
   * "ArrowDown" and "ArrowUp" allow to select item on the list.
   * "Escape" close opened list popup.
   */
  handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (this.state.showMenu) {
      switch (e.key) {
        case 'Enter':
          this.handleEnterKey(e);
          break;
        case 'ArrowDown':
        case 'ArrowUp':
          this.handleArrowsKeys(e);
          break;
        case 'Escape':
          this.handleEscapeKey();
          break;
        default:
          break;
      }
    }
  };

  /**
   * @description Handles on enter key down for the component, to allow to keyboard navigation
   * "Enter" open and close list popup and also allows to select item from the list.
   */
  handleEnterKey = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (this.state.showMenu && this.state.keyboardFocusedIndex !== -1) {
      this.handleOnClickItem(this.state.keyboardFocusedIndex,
        [...this.props.generalItems || [], ...this.props.items][this.state.keyboardFocusedIndex]);
      // set focus to focus ref
      this.props.focusRef && this.props.focusRef && this.props.focusRef.current && this.props.focusRef.current.focus();
      // if no focusRef set focus on dropdown button
      !this.props.focusRef && this.buttonRef && this.buttonRef.current && this.buttonRef.current.focus();
      e.preventDefault();
    }
  };

  /**
   * @description Handles on ArrowDown and ArrowUp keys down for the component, to allow to keyboard navigation
   */
  handleArrowsKeys = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    let currentIndex = this.state.keyboardFocusedIndex;
    if (currentIndex === -1) {
      currentIndex = this.props.activeIndex - 1;
    }
    this.changeKeyboardFocus(currentIndex + (e.key === 'ArrowDown' ? 1 : -1));
    // prevent scroll page on arrows keys
    e.preventDefault();
  };

  /**
   * @description Handles on Escape key down for the component, to allow to keyboard navigation
   */
  handleEscapeKey = (): void => {
    this.props.focusRef?.current?.focus();
    if (!this.props.focusRef)
      this.buttonRef?.current?.focus();
    this.setState({showMenu: false, keyboardFocusedIndex: -1});
  };

  /**
   * @description Changes state of keyboardFocusedItemIndex,
   * makes sure the index has right value and does not exceed the items length
   */
  changeKeyboardFocus = (index: number): void => {
    let newIndex = index;
    const allItemsLength = (this.props.generalItems ? this.props.generalItems.length : 0) + this.props.items.length;

    if (index >= allItemsLength) {
      newIndex = allItemsLength - 1;
    }
    if (index < 0) {
      newIndex = 0;
    }
    if (this.state.keyboardFocusedIndex !== newIndex) {
      this.setState({keyboardFocusedIndex: newIndex});
    }
  };

  /**
   * @description Renderes the component.
   */
  render = (): React.ReactNode => {
    const {
      id,
      dataTestId,
      title,
      activeIndex,
      autoFocus,
      disabled,
      containerID,
      error,
      tooltip,
      placeholder,
      required
    } = this.props;
    const {showMenu} = this.state;
    const items: React.ReactNode[] = [];
    const generalItems: React.ReactNode[] = [];
    const allItems: DropdownData[] = [];

    if (this.props.generalItems) {
      // create a dropdown item for each item string
      this.props.generalItems.forEach((item, i) => {
        generalItems.push(
          <DropdownItem
            id={`${id}_menu_list_item_${i}`}
            key={i}
            tabIndex={this.state.keyboardFocusedIndex === i ? 0 : -1}
            scrollRef={this.itemRefs[i]}
            item={item}
            onClick={() => this.handleOnClickItem(i, item)}
            selected={i === activeIndex}
          />
        );
        allItems.push(item);
      });
    }

    // create a dropdown item for each item string
    this.props.items.forEach((item, i) => {
      items.push(
        <DropdownItem
          id={`${id}_menu_list_item_${i + generalItems.length}`}
          key={i + generalItems.length}
          tabIndex={this.state.keyboardFocusedIndex === (i + generalItems.length) ? 0 : -1}
          scrollRef={this.itemRefs[i + generalItems.length]}
          item={item}
          onClick={() => this.handleOnClickItem(i + generalItems.length, item)}
          selected={i + generalItems.length === activeIndex}
        />
      );
      allItems.push(item);
    });
    return (
      <div
        id={id}
        data-testid={dataTestId}
        ref={this.wrapperRefButton}
        className={'dropdown_container'}
        title={tooltip}
        onKeyDown={this.handleKeyDown}>

        <Label
          id={`${id}_dropdown_title`}
          title={title}
          disabled={disabled}
          isError={!!error}
          required={required}
        />
        <DropdownButton
          id={id}
          dataTestId={dataTestId ? `${dataTestId}_button` : undefined}
          focusRef={this.props.focusRef || this.buttonRef}
          onClick={() => !disabled && this.handleShowMenu()}
          active={((this.props.activeIndex === -1 || this.props.activeIndex === undefined) && placeholder) ? ({text: placeholder} as DropdownData) : allItems[this.props.activeIndex]}
          open={this.state.showMenu}
          autoFocus={autoFocus}
          disabled={disabled}
          tabIndex={disabled ? -1 : 0}
          placeholder={placeholder}
        />
        <ErrorMessage id={`${id}_error`} dataTestId={dataTestId ? `${dataTestId}_error` : undefined} text={error}/>
        {
          showMenu && document.querySelector('#dropdownMenu_Portal') &&
          ReactDOM.createPortal(
            <DropdownMenu
              id={id}
              dataTestId={dataTestId ? `${dataTestId}_menu` : undefined}
              items={items}
              generalItems={generalItems}
              wrapperRef={this.wrapperRefList}
              containerID={containerID}/>,
            // @ts-ignore
            document.querySelector('#dropdownMenu_Portal'))
        }
      </div>
    );
  };
}