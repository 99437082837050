import PropTypes from 'prop-types'
import { Component } from 'react'

// Components
import {
  Label
} from 'BetaUX2Web-Components/src/'


// Style
import './Timepicker.scss'

// Dependence
import Datetime from '../datepicker/datepicker_ORIGIN/DateTime.jsx'
import { translate } from 'language/Language'

/**
 * Timepicker component created according to
 * _Input_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
export default class Timepicker extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM. */
    id: PropTypes.string.isRequired,
    /** Used for the locale of the timepicker. */
    language: PropTypes.oneOf(['en', 'de', 'fr']),
    /** The text which is shown above the timepicker. */
    title: PropTypes.string,
    /** The value which is shown inside the textfield. */
    value: PropTypes.string,
    /** The text which is shown inside the textfield if there is no value. */
    placeholder: PropTypes.string,
    /**
     * The function which will be called on change event.
     * @param {string} value Selected value
     */
    onChange: PropTypes.func,
    /** Used for disable this component. */
    disabled: PropTypes.bool,
    /** The error text which is shown below the textfield. */
    error: PropTypes.string,
    /** Id from the scrollable container which includes the picker. */
    parentContainer: PropTypes.string,
    /**
     * Callback which is called when the picker lost the focus and the validation failed.
     * @param {string} value Selected value
     */
    onInvalidTime: PropTypes.func,
    /** Callback which is called when the picker lost the focus. */
    onBlur: PropTypes.func,
    /** Callback which is called when the picker gained the focus. */
    onFocus: PropTypes.func,
    /** The focus reference of the textfield. */
    focusRef: PropTypes.object,
    /** React key */
    key: PropTypes.string,
    /**
    * Sets dot and required word, next to the _title_, which indicates that fields is required.
    * If true - english version is used "required"
    * If string, user provide the word which appears after dot.
    * If _title_ is not set, indicator will not be shown.
    */
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }

  /**
   * @description Checks, if the input value is a date. Execute onBlur function when given.
   */
  onBlur = () => {
    const { onInvalidTime, value, onBlur } = this.props
    if (onInvalidTime) {
      if (value !== '' && !value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        onInvalidTime(value)
      }
    }
    onBlur && onBlur()
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, language, title, value, placeholder, onChange, disabled, focusRef, error,
      parentContainer, onFocus, key, required } = this.props

    return (
      <div
        id={`${id}_timepicker`}
        className={'bux_timepicker_container'}
        key={key || undefined}>
        <Label
          id={`${id}_timepicker_label`}
          title={title}
          required={required ? `${translate('general.required_field')}` : false}
          disabled={disabled}
          isError={!!error}
        />
        <div
          id={`${id}_timepicker_input`}
          className={`bux_input bux_timepicker ${disabled && ' disabled'} input-group datepicker`}>
          <Datetime
            id={id}
            value={value}
            onChange={newValue => onChange(newValue)}
            onChoose={newValue => onChange(newValue.format('H:mm'))}
            inputProps={{
              onFocus: onFocus && (() => onFocus()),
              onBlur: this.onBlur,
              placeholder: placeholder,
              disabled: disabled,
              invalid: error ? 'true' : 'false'
            }}
            dateFormat={false}
            focusRef={focusRef}
            locale={language}
            timeFormat='H:mm'
            closeOnSelect={true}
            parentContainer={parentContainer}
          />
        </div>
        <div
          id={`${id}_timepicker_error`}
          className='we_decoratedErrorText'>
          {error || '' /* || is required to avoid 'undefined' */}
        </div>
      </div>
    )
  }
}