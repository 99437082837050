import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import { Component } from 'react'

// Components
import { DataTable, DownloadWrapper, Link, ResultContainer } from 'BetaUX2Web-Components/src/'

// Modal
import AccountingRecords from 'components/dialogs/accounting_records/AccountingRecords'

// Redux
import { connect } from 'react-redux'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'

// Utils
import { getAccountingRecords } from 'utils/AccountingRecordsUtils.js'
import * as UserUtils from 'utils/UserUtils'

// Style
import './SearchResultServerAccounting.scss'

class SearchResultServerAccounting extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }

  state = {
    isModalOpen: false,
    selectedTypes: {},
    loadedFileLines: undefined,
    loadedFileName: undefined
  }

  /**
   * @description Opens the accounting records for specific types.
   * @param {Array} types The selected types.
   */
  showAccountingRecords = types => {
    this.setState({
      isModalOpen: true,
      selectedTypes: types
    })
  }

  /**
   * @description Gets the selected types.
   * @param {Array} checkedRows The selected rows.
   */
  getSelectedTypes = checkedRows => {
    let buffer = {}
    let allKeys = Object.keys(getAccountingRecords().types)
    let allFields = Object.values(getAccountingRecords().types)
    checkedRows.forEach(row => {
      buffer[allKeys[allFields.findIndex(d => d.name === row[0])]] = getAccountingRecords().types[allKeys[allFields.findIndex(d => d.name === row[0])]]
    })
    return buffer
  }

  /**
   * @description Opens the selected file with the modify modal dialog.
   * @param {file} file The selected file.
   */
  onFileSelected = file => {
    let reader = new FileReader()
    reader.onload = () => {
      let lines = reader.result.split('\n')
      lines = lines.map(d => d.replace(/[\r]/g, ''))
      if (this.validateFile(lines)) {
        const types = getAccountingRecords().types
        if (UserUtils.isDOCX()) {
          delete types['type200']
          delete types['type210']
          delete types['type220']
        }
        this.setState({ isModalOpen: true, selectedTypes: types, loadedFileLines: lines, loadedFileName: file.name })
      } else {
        this.props.showSnackbar(translate('server.accounting_load_file_error'), SnackbarActions.TYPE_ERROR)
      }
    }
    reader.readAsText(file)
  }

  validateFile = fileContent => {
    if (UserUtils.isDOCX()) {
      return this.validateFileDocX(fileContent)
    }
    else if (UserUtils.isLOGX()) {
      return this.validateFileLogX(fileContent)
    }
  }

  /**
   * @description Checks if the files content is valid for the docx system.
   * @param {String} fileContent The file content.
   */
  validateFileDocX = fileContent => {
    let valid = true
    if (fileContent.length !== 14) {
      return false
    }
    fileContent.forEach((line, index) => {
      if (!valid) {
        return
      }
      switch (index) {
        case 0:
          if (!line.startsWith('RECORD_TYPE_050=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 1:
          if (!line.startsWith('RECORD_TYPE_100=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 2:
          if (!line.startsWith('RECORD_TYPE_101=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 3:
          if (!line.startsWith('RECORD_TYPE_102=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 4:
          if (!line.startsWith('RECORD_TYPE_103=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 5:
          if (!line.startsWith('RECORD_TYPE_104=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 6:
          if (!line.startsWith('RECORD_TYPE_105=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 7:
          if (!line.startsWith('RECORD_TYPE_110=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 8:
          if (!line.startsWith('RECORD_TYPE_120=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 9:
          if (!line.startsWith('RECORD_TYPE_130=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 10:
          if (line !== '* ----------------------------------------------------') {
            valid = false
          }
          break
        case 11:
          if (!line.startsWith('DATEMASK=')) {
            valid = false
          }
          break
        case 12:
          if (!line.startsWith('TIMEMASK=')) {
            valid = false
          }
          break
        case 13:
          if (!line.startsWith('OUTPUT_FILE_EXTENSION=')) {
            valid = false
          }
          break
        default:
          valid = false
          return
      }
    })
    return valid
  }

  /**
   * @description Checks if the files content is valid for the logx system.
   * @param {String} fileContent The file content.
   */
  validateFileLogX = fileContent => {
    let valid = true
    if (fileContent.length !== 16) {
      return false
    }
    fileContent.forEach((line, index) => {
      if (!valid) {
        return
      }
      switch (index) {
        case 0:
          if (!line.startsWith('RECORD_TYPE_050=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 1:
          if (!line.startsWith('RECORD_TYPE_100=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 2:
          if (!line.startsWith('RECORD_TYPE_101=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 3:
          if (!line.startsWith('RECORD_TYPE_102=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 4:
          if (!line.startsWith('RECORD_TYPE_103=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 5:
          if (!line.startsWith('RECORD_TYPE_104=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 6:
          if (!line.startsWith('RECORD_TYPE_105=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 7:
          if (!line.startsWith('RECORD_TYPE_110=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 8:
          if (!line.startsWith('RECORD_TYPE_120=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 9:
          if (!line.startsWith('RECORD_TYPE_130=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 10:
          if (!line.startsWith('RECORD_TYPE_200=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 11:
          if (!line.startsWith('RECORD_TYPE_210=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 12:
          if (!line.startsWith('RECORD_TYPE_220=') || !line.endsWith(';')) {
            valid = false
          }
          break
        case 13:
          if (line !== '* ----------------------------------------------------') {
            valid = false
          }
          break
        case 14:
          if (!line.startsWith('DATEMASK=')) {
            valid = false
          }
          break
        case 15:
          if (!line.startsWith('TIMEMASK=')) {
            valid = false
          }
          break
        case 16:
          if (!line.startsWith('OUTPUT_FILE_EXTENSION=')) {
            valid = false
          }
          break
        default:
          valid = false
          return
      }
    })
    return valid
  }

  /**
   * @description Create a downloadable object.
   * @param {Array} fileContent The file content.
   * @returns {Object} The downloadable object.
   */
  createDownloadObject = fileContent => {
    const checkedRowsProperty = 'checkedRows'
    const defaultOrderProperty = 'defaultOrder'
    let buffer = {}
    fileContent.forEach((line, index) => {
      let defaultOrder = false
      let checkedRows = []
      if (line.substring(16, 18) === '1;') {
        defaultOrder = true
      }
      else if (line.substring(16, 18) === '1,') {
        checkedRows = line.substring(18, line.length - 1).split(',')
      }
      if (index === 0) {
        buffer['type050'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 1) {
        buffer['type100'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 2) {
        buffer['type101'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 3) {
        buffer['type102'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 4) {
        buffer['type103'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 5) {
        buffer['type104'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 6) {
        buffer['type105'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 7) {
        buffer['type110'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 8) {
        buffer['type120'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 9) {
        buffer['type130'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 10) {
        buffer['type200'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 11) {
        buffer['type210'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
      else if (index === 12) {
        buffer['type220'] = { [checkedRowsProperty]: checkedRows, [defaultOrderProperty]: defaultOrder }
      }
    })
    return buffer
  }

  /**
   * @description Creates the header info object.
   * @param {Array} fileContent The file content.
   * @returns {Object} The header infor object.
   */
  createHeaderInfoObject = fileContent => {
    let buffer = {}
    fileContent.forEach((line, index) => {
      if (index === 11) {
        buffer['DATEMASK'] = line.substring(9, line.length)
      } else if (index === 12) {
        buffer['TIMEMASK'] = line.substring(9, line.length)
      } else if (index === 13) {
        buffer['OUTPUT_FILE_EXTENSION'] = line.substring(22, line.length)
      }
    })
    buffer['FILENAME'] = this.state.loadedFileName
    return buffer
  }

  /**
   * @description Creates the interaction buttons.
   * @returns {Array} the interaction buttons.
   */
  createInteractionButtons = () => {
    const valuesOfAccountingTable = []
    for (const entry of Object.values(getAccountingRecords().types)) {
      valuesOfAccountingTable.push([entry.name, entry.description])
    }
    return [
      <DownloadWrapper
        id='download_wrapper'
        header={getAccountingRecords().headers}
        data={[...valuesOfAccountingTable]}
        csvSplitter=';'
        filename='data.csv'
        tooltip={translate('table.download_as_csv')}>
        <Link
          id={'download'}
          iconName={'download'}
          onCLick={() => { }}
          tooltip={translate('table.download_as_csv')}
        />
      </DownloadWrapper>,
      <div className={'bux_open_file'}>
        <label title={translate('server.load_accounting_file')}>
          <span className={'beta_icon_upload'}></span>
          <input
            type={'file'}
            onChange={event => this.onFileSelected(Array.from(event.target.files)[0])}
          />
        </label>
      </div>
    ]
  }

  render = () => {
    const { id, drawerExpanded, autoDrawer, lang, datemask } = this.props
    const { isModalOpen, loadedFileLines } = this.state

    const valuesOfAccountingTable = []
    for (const [key, entry] of Object.entries(getAccountingRecords().types)) {
      if (UserUtils.isDOCX()) {
        if (!['type200', 'type210', 'type220', 'type230'].includes(key)) {
          valuesOfAccountingTable.push([entry.name, entry.description])
        }
      }
      else {
        valuesOfAccountingTable.push([entry.name, entry.description])
      }
    }

    const valuesOfSelectedTypesTable = []
    for (const [key, entry] of Object.entries(getAccountingRecords().types)) {
      if (UserUtils.isDOCX()) {
        if (!['type200', 'type210', 'type220', 'type230'].includes(key)) {
          valuesOfSelectedTypesTable.push([entry.name, entry.description])
        }
      }
      else {
        valuesOfSelectedTypesTable.push([entry.name, entry.description])
      }
    }

    return (
      <>
        {isModalOpen && (
          <AccountingRecords
            id={`${id}_accounntingrecords`}
            selected={this.state.selectedTypes}
            onClose={() => { this.setState({ isModalOpen: false, loadedFileLines: undefined, loadedFileName: undefined }) }}
            download={loadedFileLines ? this.createDownloadObject(loadedFileLines) : undefined}
            headerInfo={loadedFileLines ? this.createHeaderInfoObject(loadedFileLines) : undefined}
          />
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          <div className={'disableHover'}>
            <DataTable
              id={`${id}_accounntingrecords`}
              loading={false}
              header={getAccountingRecords().headers}
              data={valuesOfAccountingTable}
              cleanData={valuesOfAccountingTable}
              selectable
              createTableRowAction={() => { }}
              columnSortDefs={['string', 'string']}
              additionalInteraction={this.createInteractionButtons()}
              downloadItems={[translate('server.accounting.selectaccountingrecords')]}
              fillPage
              showModal={checkedRows => this.showAccountingRecords(this.getSelectedTypes(checkedRows))}
              translate={key => translate(key)}
              language={lang}
              datemask={datemask}
            />
          </div>
        </ResultContainer>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultServerAccounting)