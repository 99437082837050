import { createContext, useContext } from 'react';

import * as TYPES_DOC_BROWSER_HEX_AREA from '../types/DocumentBrowserHexArea.types';

export const DocumentBrowserHexSelectionContext = createContext<Record<string, never> | TYPES_DOC_BROWSER_HEX_AREA.IDocumentBrowserHexSelectionContext>({});

export const useDocumentBrowserHexSelectionContext = () => {
  const context = useContext(DocumentBrowserHexSelectionContext);

  if (!context) {
    throw new Error('useDocumentBrowserHexSelectionContext must be used within DocumentBrowserHexSelectionContext');
  }

  return context;
};