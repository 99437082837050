import {restapiRequest} from './RequestUtils';
import * as Config from '../config/Configs'
import { translate, translateNoError } from '../language/Language';

export const columnTranslation: Record<string, string> = {
  ACCESS: 'security.access',
  AGNTNAME: 'general.header.agent',
  APPL: 'definition.application',
  CLNTNAME: 'general.client',
  CREATIONDATE: 'general.creation_date',
  DATACENT: 'general.datacenter',
  DESCRIPTION: 'general.description',
  ENDDATE: 'general.header.end_date',
  ENDDT: 'general.end_date',
  ENTCODE: 'security.function',
  ENTDESC: 'security.description',
  ENTPRFX: 'security.class',
  ENTRULE: 'security.entity_generation_rules',
  EXT: 'general.extension',
  FORM: 'general.form',
  JHIGHCC: 'job.high_cc',
  JNETID: 'job.netid',
  JOBERR: 'general.header.job_error',
  JOBGROUP: 'general.header.job_group',
  JOBID: 'job.jobid',
  JOBNAME: 'general.header.job_name',
  JOBRC: 'general.header.return_code',
  JOBSTAT: 'general.header.job_status',
  LASTCHANGED: 'general.last_changed',
  LGRARCH: 'general.archive',
  LGRFFARC: 'general.marked_archive',
  LGRFFDEL: 'general.marked_delete',
  LGRFFREL: 'general.marked_reload',
  LGRONLNE: 'recipient.distribute_online',
  LOGSRC: 'general.log_source',
  LTITLE: 'general.title',
  LTOKEN: 'search.standard_search_listtimestamp',
  ORDDATE: 'general.order_date',
  ORDERID: 'general.order_id',
  OWNER: 'general.owner',
  PROFILEMASK: 'security.profile_mask',
  READERDATE: 'search.reader_date',
  RTOKEN: 'search.standard_search_reporttimestamp',
  RUNCOUNT: 'general.run_count',
  RUNNUMB: 'general.run_number',
  RUNTIME: 'general.runtime',
  RUNUSER: 'general.header.run_user',
  SERVICES: 'general.header.service',
  SRCCLAS: 'job.class',
  SRCJOBI: 'general.doctype',
  SRCJOBN: 'general.jobname',
  SRCPAGES: 'general.pages',
  SRCSUBD: 'job.submit_date',
  SRCSUBDT: 'general.activation_date',
  SRCSUBT: 'job.submit_time',
  SRCSUBU: 'user.user',
  SRCVERSION: 'general.header.source_version',
  SRCVERS: 'general.header.source_version',
  STRTDATE: 'general.header.start_date',
  STRTDT: 'general.header.start_date',
  SUBAPPL: 'general.sub_application',
  SYSNAME: 'general.header.system',
  UC4CPU: 'general.cpu_usage',
  UC4RC: 'general.return_code',
  UC4RRT: 'general.runtime',
  UC4STAT: 'general.status',
  USR1NAM: 'general.header.user_field_1_label',
  USR1VAL: 'general.header.user_field_1_value',
  USR2NAM: 'general.header.user_field_2_label',
  USR2VAL: 'general.header.user_field_2_value',
  USER_GROUP_ID: 'security.user_group_id',
  WORKFLOW: 'general.header.workflow',
  WREPORT: 'general.report',
}

export enum EResultTableType {
  BRWTAB = 'BRWTAB',
  BRWCTM = 'BRWCTM',
  BRWUC4 = 'BRWUC4',
  BRWZOS = 'BRWZOS',
  BRWSYSL = 'BRWSYSL',
  BRWSTB = 'BRWSTB'
}

export interface TranslationObjFromApi {
  key: string;
  value: string;
}

export interface HeaderColumnObj {
  rest: string;
  translation: string;
  default: boolean;
}

const createColumnObject = (header: string, isDefault: boolean, customTranslations?: Record<string, string>) => {
  const columnCustomTranslations = customTranslations && customTranslations[header]
  const columnStandardTranslations = isDefault && columnTranslation[header]

  return {
    rest: header,
    translation: columnCustomTranslations || columnStandardTranslations || undefined,
    default: isDefault
  }
}


export const getTranslationsFromApi = async (type?: EResultTableType): Promise<TranslationObjFromApi[]> => {
  const typeParam = type ? `?SLTITYPE=${type}` : ''
  const response = await restapiRequest(`${Config.REST_API_URL}/api/definition/defaultsearchresultobjects${typeParam}`, {method: 'get'})
    .then(r => r.json())
    .then(r => r.data)


  const columnNameIndex = response.header.indexOf('SLINAME');
  const columnDescIndex = response.header.indexOf('SLITNAME');

  return response.data
    .map((data: any) => {
      const key = data[columnNameIndex]?.toUpperCase()
      const translation = data[columnDescIndex]

      return {
        key,
        value: translation && translation.replace(':', '')
      }
    })

}

export const translateHeaderColumns = async (headers: string[], type?: EResultTableType) => {
  const translations: TranslationObjFromApi[] = await getTranslationsFromApi(type)
  return headers.map(value => {
    const translation = translations.find((translation: TranslationObjFromApi) => translation.key === value.toUpperCase())
    return translation?.value || (columnTranslation[value] ? translate(columnTranslation[value]) : value)
  })
}

export const getColumnsObjects = (allHeaders: string[], defaultHeaders: string[], customTranslations?: Record<string, string>) => {

  const defaultColumns = defaultHeaders
    .filter(header => allHeaders.includes(header))
    .map((header) => {
      return createColumnObject(header, true, customTranslations)
    })

  const restColumns = allHeaders
    .filter(header => !defaultHeaders.includes(header))
    .map((header) => {
      return createColumnObject(header, false, customTranslations)
    })

  return [...defaultColumns, ...restColumns]
}


export const joinOrderedColumnsWithRest = (allHeaders: string[], orderedColumns: HeaderColumnObj[], customTranslations?: Record<string, string>) => {
  const filteredColumns = orderedColumns.filter((columns) => allHeaders.includes(columns.rest))
  const orderedRestHeader = orderedColumns.map((column) => column.rest)
  const restColumns = allHeaders
    .filter(header => !orderedRestHeader.includes(header))
    .map((header) => {
      return createColumnObject(header, false, customTranslations)
    })
  return [...filteredColumns, ...restColumns]
}

/**
 * @description Gets the translated headers.
 * @returns {Array} The translated chosen headers.
 * @param allHeaders {{rest: string, translation: string}[]} All headers with translation key provided
 * @param chosenHeaders {string[]} Rest headers names
 */
export const getTranslatedHeaders = (allHeaders: {rest: string, translation: string}[], chosenHeaders: string[]) => {
  let filteredHeaders: any[] = []
  chosenHeaders.forEach(h => {
    const temp = allHeaders.filter((entry: {rest: string, translation: string}) => entry.rest === h)
    if (temp.length > 0) {
      filteredHeaders.push(temp[0])
    }
  })

  return filteredHeaders.map(entry => {
    const key = entry.translation ? entry.translation : entry.rest
    return translateNoError(key)
  })
}