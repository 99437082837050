import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'

// actions
export const GET_USERS_SECURITY_PROFILES_START = 'GET_USERS_SECURITY_PROFILES_START'
export const GET_USERS_SECURITY_PROFILES_SUCCESS = 'GET_USERS_SECURITY_PROFILES_SUCCESS'
export const GET_USERS_SECURITY_PROFILES_FAILED = 'GET_USERS_SECURITY_PROFILES_FAILED'
export const NO_USERS_SECURITY_PROFILES_FOUND = 'NO_USERS_SECURITY_PROFILES_FOUND'

export const NO_SECURITY_PROFILES_FOUND = 'NO_SECURITY_PROFILES_FOUND'

/**
 * @description Calls the rest api and gets security profiles. Similar to getSecurityProfiles of SecurityProfileActions. This is required, because the searched Securityprofiles in the Security-Search-Table should not be changed when securityprofile of a specific user get shown in the User-Result-Table. (separate variable in redux-store).
 * @param {String} userid The userid to search.
 * @param {*} callback The callback which will be called when the get users security profiles request was successful. Can be null.
 */
export function getUsersSecurityProfiles(userid, callback) {
  /* Escape URL-parameters */
  userid = encodeURIComponent(userid)

  return dispatch => {
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_USERS_SECURITY_PROFILES_START })
    LoadingSpinnerActions.show()(dispatch)

    const startTime = Date.now()

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/assignedsecurityprofiles?BETAUSER=${userid}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          // handle no data found here to dispatch NO_USERS_SECURITY_PROFILES_FOUND action
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_USERS_SECURITY_PROFILES_FOUND })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_USERS_SECURITY_PROFILES_SUCCESS, payload: { error } })
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_USERS_SECURITY_PROFILES_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          // wait a bit to show the user the loading animation
          const usedTime = Date.now() - startTime
          if (usedTime < 300) {
            setTimeout(() => {
              LoadingSpinnerActions.hide()(dispatch)
            }, 300 - usedTime)
          }
          else {
            LoadingSpinnerActions.hide()(dispatch)
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_security_profiles_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_USERS_SECURITY_PROFILES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}