import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'
import { DOCUMENT_SEARCH_MODE, DOCUMENT_TYPES } from 'utils/DefinitionUtils'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Switch from 'BetaUX2Web-Components/src/components/switch/Switch'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as StandardSelectionActions from 'redux/actions/DocumentDefinitionActions'
import {
  getOutputChannelDefinitions,
  getOutputFormatDefinitions,
  getPPNDefinitions
} from 'redux/actions/ModalSelectorActions'

class DefinitionDocument extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    form: '',
    extension: '',
    report: '',
    searchModeIndex: 0,
    typeIndex: 0,
    owner: '',
    title: '',
    outputChannelID: '',
    outputFormatID: '',
    ppnID: '',
    showOutputChannel: false,
    showOutputFormat: false,
    showPPN: false
  }

  state = {
    ...this.defaultState
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  initFieldsFromPreferences = () => {
    const { prefs } = this.props
    if (prefs) {
      const searchModeIndex = prefs[Preferences.DEFINITION_DOCUMENT_SEARCH_MODE]
        ? Math.max(DOCUMENT_SEARCH_MODE.findIndex(mode => mode.key === prefs[Preferences.DEFINITION_DOCUMENT_SEARCH_MODE]), 0)
        : 0
      const typeIndex = prefs[Preferences.DEFINITION_DOCUMENT_TYPE]
        ? Math.max(DOCUMENT_TYPES.findIndex(mode => mode.key === prefs[Preferences.DEFINITION_DOCUMENT_TYPE]), 0)
        : 0
      this.setState({
        form: prefs[Preferences.DEFINITION_DOCUMENT_FORM] || '',
        extension: prefs[Preferences.DEFINITION_DOCUMENT_EXT] || '',
        report: prefs[Preferences.DEFINITION_DOCUMENT_REPORT] || '',
        searchModeIndex,
        typeIndex,
        owner: prefs[Preferences.DEFINITION_DOCUMENT_OWNER] || '',
        title: prefs[Preferences.DEFINITION_DOCUMENT_TITLE] || '',
        outputChannelID: prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTCHANNEL_ID] || '',
        outputFormatID: prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTFORMAT_ID] || '',
        ppnID: prefs[Preferences.DEFINITION_DOCUMENT_PPN_ID] || ''
      })
    }
  }

  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   */
  handleInputChanged = (id, value) => this.setState({ [id]: value })

  /**
   * @description Requests the output channel definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handleOutputChannelSelector = () => {
    const { getOutputChannelDefinitions } = this.props
    const { outputChannelID } = this.state
    getOutputChannelDefinitions(
      ['DCR', 'DCRTITLE'],
      outputChannelID,
      () => this.setState({ showOutputChannel: true })
    )
  }

  /**
   * @description Requests the output format definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handleOutputFormatSelector = () => {
    const { getOutputFormatDefinitions } = this.props
    const { outputFormatID } = this.state
    getOutputFormatDefinitions(
      ['PCR', 'PCRTITLE'],
      outputFormatID,
      () => this.setState({ showOutputFormat: true })
    )
  }

  /**
   * @description Requests the PPN definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handlePPNSelector = () => {
    const { getPPNDefinitions } = this.props
    const { ppnID } = this.state
    getPPNDefinitions(
      ['PPN', 'PPNTITLE'],
      ppnID,
      () => this.setState({ showPPN: true })
    )
  }

  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    const { showOutputChannel, showOutputFormat, showPPN } = this.state
    return (
      <>
        {showOutputChannel && (
          <SelectorDialog
            id={`${id}_document_outputchannel_selector_dialog`}
            onClose={() => this.setState({ showOutputChannel: false })}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('definition.output_channel_id'),
              translate('general.title')
            ]}
            items={selector.outputchannels.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const outputChannelID = selector.outputchannels.data[selectedRows][this.headerData(selector.outputchannels, 'DCR')]
                this.setState({ outputChannelID })
              }
              this.setState({ showOutputChannel: false })
            }}
          />
        )}
        {showOutputFormat && (
          <SelectorDialog
            id={`${id}_document_outputformat_selector_dialog`}
            onClose={() => this.setState({ showOutputFormat: false })}
            title={translate('definition.outputformatdefinitions')}
            header={[
              translate('definition.output_format_id'),
              translate('general.title')
            ]}
            items={selector.outputformats.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const outputFormatID = selector.outputformats.data[selectedRows][this.headerData(selector.outputformats, 'PCR')]
                this.setState({ outputFormatID })
              }
              this.setState({ showOutputFormat: false })
            }}
          />
        )}
        {showPPN && (
          <SelectorDialog
            id={`${id}_document_ppn_selector_dialog`}
            onClose={() => this.setState({ showPPN: false })}
            title={translate('definition.ppndefinitions')}
            header={[
              translate('definition.ppn_id'),
              translate('general.title')
            ]}
            items={selector.ppns.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const ppnID = selector.ppns.data[selectedRows][this.headerData(selector.ppns, 'PPN')]
                this.setState({ ppnID })
              }
              this.setState({ showPPN: false })
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = event => {
    event.preventDefault()
    const { changePrefs, getDocuments } = this.props
    const {
      form,
      extension,
      report,
      searchModeIndex,
      typeIndex,
      owner,
      title,
      outputChannelID,
      outputFormatID,
      ppnID
    } = this.state
    const searchMode = DOCUMENT_SEARCH_MODE[searchModeIndex].key
    const type = DOCUMENT_TYPES[typeIndex].key
    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_DOCUMENT_FORM]: form,
      [Preferences.DEFINITION_DOCUMENT_EXT]: extension,
      [Preferences.DEFINITION_DOCUMENT_REPORT]: report,
      [Preferences.DEFINITION_DOCUMENT_SEARCH_MODE]: searchMode,
      [Preferences.DEFINITION_DOCUMENT_TYPE]: type,
      [Preferences.DEFINITION_DOCUMENT_OWNER]: owner,
      [Preferences.DEFINITION_DOCUMENT_TITLE]: title,
      [Preferences.DEFINITION_DOCUMENT_OUTPUTCHANNEL_ID]: outputChannelID,
      [Preferences.DEFINITION_DOCUMENT_OUTPUTFORMAT_ID]: outputFormatID,
      [Preferences.DEFINITION_DOCUMENT_PPN_ID]: ppnID,
    }
    changePrefs(prefsToChange)

    getDocuments(
      undefined,
      form,
      extension,
      report,
      searchMode,
      outputChannelID,
      outputFormatID,
      type,
      owner,
      title,
      ppnID
    )
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleReset = () => this.setState(this.defaultState)

  renderGeneralCard = () => {
    const { id } = this.props
    const { form, extension, report, searchModeIndex, typeIndex, owner, title } = this.state
    const searchModeItems = []
    DOCUMENT_SEARCH_MODE.forEach(el => {
      searchModeItems.push(translate(el.translationKey))
    })
    return (
      <Card title={translate('general.general')}>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_document_form`}
              title={translate('general.form')}
              value={form}
              onInputChanged={val => this.handleInputChanged('form', val)}
              maxLength={8}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_document_extension`}
              title={translate('general.extension')}
              value={extension}
              onInputChanged={val => this.handleInputChanged('extension', val)}
              maxLength={16}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_document_report`}
              title={translate('general.report')}
              value={report}
              onInputChanged={val => this.handleInputChanged('report', val)}
              maxLength={16}
            />
          </Column>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_document_searchmode`}
              title={translate('definition.document_search_mode')}
              items={searchModeItems}
              activeIndex={searchModeIndex}
              onChange={searchModeIndex => this.setState({ searchModeIndex })}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_document_type`}
              title={translate('general.type')}
              items={[
                translate('general.any'),
                translate('general.list'),
                translate('general.report')
              ]}
              onClick={index => this.handleInputChanged('typeIndex', index)}
              activeIndex={typeIndex}
              maxPerRow={3}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_document_owner`}
              title={translate('general.owner')}
              value={owner}
              onInputChanged={val => this.handleInputChanged('owner', val)}
              maxLength={8}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_document_title`}
              title={translate('general.title')}
              value={title}
              onInputChanged={val => this.handleInputChanged('title', val)}
              maxLength={80}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the additional card
   */
  renderAdditionalCard = () => {
    const { id } = this.props
    const { outputChannelID, outputFormatID, ppnID } = this.state

    return (
      <Card title={translate('general.additional')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_document_outputchannelid`}
              onInputChanged={val => this.handleInputChanged('outputChannelID', val)}
              value={outputChannelID}
              title={translate('definition.output_channel_id')}
              addon={{
                iconName: 'list',
                onClick: () => this.handleOutputChannelSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_document_outputformatid`}
              onInputChanged={val => this.handleInputChanged('outputFormatID', val)}
              value={outputFormatID}
              title={translate('definition.output_format_id')}
              addon={{
                iconName: 'list',
                onClick: () => this.handleOutputFormatSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_document_ppnid`}
              onInputChanged={val => this.handleInputChanged('ppnID', val)}
              value={ppnID}
              title={translate('definition.postprocessing_note_id')}
              addon={{
                iconName: 'list',
                onClick: () => this.handlePPNSelector()
              }}
            />
          </Column>
        </Row>
      </Card>
    )
  }


  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderSelectorDialogs()}
        {this.renderGeneralCard()}
        {this.renderAdditionalCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={() => { }}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getOutputFormatDefinitions: (fields, outputFormat, callback) => {
      getOutputFormatDefinitions(
        fields,
        outputFormat,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getPPNDefinitions: (fields, ppn, callback) => {
      getPPNDefinitions(
        fields,
        ppn,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getDocuments: (fields, form, extension, report, smode, outputChannelID, outputFormatID, process, owner, title, ppn, callback) => {
      StandardSelectionActions.getDocuments(fields, form, extension, report, smode, outputChannelID, outputFormatID, process, owner, title, ppn, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionDocument)