import * as SearchStonebranchActions from 'redux/actions/SearchStonebranchActions'
import { LOGOUT_SUCCESS } from '../actions/AuthenticationActions'

const defaultState = {
  stonebranchJobs: undefined,
  stonebranchJobDetails: undefined,
  stonebranchJobLogs: undefined,
  stonebranchJobLog: undefined,
  stonebranchJobLogPrintInfo: undefined,
  stonebranchJobLogInformation: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    case SearchStonebranchActions.GET_STONEBRANCH_JOBS_START:
      return { ...state }
    case SearchStonebranchActions.GET_STONEBRANCH_JOBS_SUCCESS:
      return { ...state, stonebranchJobs: action.payload, keepPagination: action.keepPagination }
    case SearchStonebranchActions.GET_STONEBRANCH_JOBS_FAILED:
      return { ...state }
    case SearchStonebranchActions.NO_STONEBRANCH_JOBS_FOUND:
      return { ...state, stonebranchJobs: {} }
    /* ********************************************************************************** */
    // Get specific job
    case SearchStonebranchActions.GET_STONEBRANCH_JOB_START:
      return { ...state }
    case SearchStonebranchActions.GET_STONEBRANCH_JOB_SUCCESS:
      return { ...state, stonebranchJobDetails: { ...state.stonebranchJobDetails, ...action.payload } }
    case SearchStonebranchActions.GET_STONEBRANCH_JOB_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get logs of a job
    case SearchStonebranchActions.GET_STONEBRANCH_JOB_LOGS_START:
      return { ...state }
    case SearchStonebranchActions.GET_STONEBRANCH_JOB_LOGS_SUCCESS:
      return { ...state, stonebranchJobLogs: action.payload }
    case SearchStonebranchActions.GET_STONEBRANCH_JOB_LOGS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get stonebranch job log
    case SearchStonebranchActions.STONEBRANCH_JOBS_GET_LOG_START:
      return { ...state }
    case SearchStonebranchActions.STONEBRANCH_JOBS_GET_LOG_SUCCESS:
      return { ...state, stonebranchJobLog: action.payload }
    case SearchStonebranchActions.STONEBRANCH_JOBS_GET_LOG_FAILED:
      return { ...state, stonebranchJobLog: undefined}
    case SearchStonebranchActions.NO_STONEBRANCH_JOB_LOGS:
      return { ...state, stonebranchJobLog: {} }
    /* ********************************************************************************** */
    // Get stonebranch job log print info
    case SearchStonebranchActions.STONEBRANCH_JOBS_GET_LOG_PRINT_INFO_START:
      return { ...state }
    case SearchStonebranchActions.STONEBRANCH_JOBS_GET_LOG_PRINT_INFO_SUCCESS:
      return { ...state, stonebranchJobLogPrintInfo: action.payload }
    case SearchStonebranchActions.STONEBRANCH_JOBS_GET_LOG_PRINT_INFO_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get stonebranch job log
    case SearchStonebranchActions.STONEBRANCH_GET_JOB_LOG_INFORMATION_START:
      return { ...state }
    case SearchStonebranchActions.STONEBRANCH_GET_JOB_LOG_INFORMATION_SUCCESS:
      return { ...state, stonebranchJobLogInformation: action.payload }
    case SearchStonebranchActions.STONEBRANCH_GET_JOB_LOG_INFORMATION_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    case LOGOUT_SUCCESS:
      return defaultState
    default: return state
  }

}

export default reducer