import React, { useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Button } from 'BetaUX2Web-Components/src';
import '../Drawer.scss';

import DocumentPrint from 'components/dialogs/document_print_dialog/DocumentPrint';

import { useTabContentContext } from '../../contexts/TabContentContext';

import { isSideFile, parseFilenameForDocumentDownload, parseFilenameForJobDownload } from '../../helper/utils';

import { translate } from 'language/Language';

import { loadDataForPrintModal } from 'redux/actions/DocViewerActions';
import { downloadDocument } from 'redux/actions/StandardSelectionActions';
import { downloadJob } from 'redux/actions/SearchControlMActions';

import * as TYPES_DOC_VIEWER from '../../types/DocumentViewer.types';

import { IDX_MARKER, isJob } from 'utils/DocViewerUtils';

export const DrawerFooter = () => {
  const dispatch = useDispatch();

  const { id } = useTabContentContext();

  const document = useSelector((state: RootStateOrAny) => state.search.standardselection.document);
  const printInfo = useSelector((state: RootStateOrAny) => state.search.standardselection.documentPrintInfo);
  const tab: TYPES_DOC_VIEWER.ITabData = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id));

  const [showPrintModal, setShowPrintModal] = useState(false);

  const handleDownloadDocumentDefault = () => {
    const docId = id.replace(IDX_MARKER, '');
    const filename = parseFilenameForDocumentDownload(tab);

    downloadDocument(docId, filename)(dispatch);
  };

  const handleDownloadJobDefault = () => {
    const { queryParams, filename } = parseFilenameForJobDownload(tab);
    if (!filename || Object.keys(queryParams).length === 0) return;

    downloadJob(queryParams, filename)(dispatch);
  };

  const handleShowPrintModal = () => {
    loadDataForPrintModal(id, () => setShowPrintModal(true))(dispatch);
  };

  return (
    <>
      {showPrintModal && (
        <DocumentPrint
          id={`drawer_${id}_print_modal`}
          onClose={() => setShowPrintModal(false)}
          document={document}
          printInfo={printInfo}
        />
      )}
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer drawer_content_document_info_footer'>
        <div className='interactionButtons footerButtons'>
          <Button
            id={`drawer_${id}_print_button`}
            icon={'print'}
            onClick={handleShowPrintModal}
            disabled={isJob(tab.type) || isSideFile(tab.id)}
            tooltip={(isJob(tab.type) && translate('documentviewer.print_not_available')) || (isSideFile(tab.id) && translate('documentviewer.print_not_available_sidefile'))}
          />
          <Button
            id={`drawer_${id}_download_button`}
            onClick={() => isJob(tab.type) ? handleDownloadJobDefault() : handleDownloadDocumentDefault()}
            text={translate('general.download')}
            primary
          />
        </div>
      </div>
    </>
  )
};