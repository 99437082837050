import PropTypes from 'prop-types'
import { Component } from 'react'
import * as SortUtils from 'utils/SortUtils'

// components
import { DataTable, DownloadWrapper, Link, ResultContainer, TableButton } from 'BetaUX2Web-Components/src/'

import DatabaseFieldsDialog from 'components/dialogs/database_fields_dialog/DatabaseFieldsDialog'
import DatabaseKeysDialog from 'components/dialogs/database_keys_dialog/DatabaseKeysDialog'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as DatabaseActions from 'redux/actions/DatabaseActions'
import * as Preferences from 'redux/general/Preferences'

class SearchResultDatabaseTable extends Component {
  state = {
    showKeys: false,
    showFields: false,
    tableHeader: {
      id: '',
      name: '',
      length: '',
      location: ''
    },
  }

  componentDidMount = () => {
    this.props.getTables()
  }

  /**
   * @description Refreshes the table
   */
  handleRefresh = () => this.props.getTables()

  /**
   * @description Opens the modal for the table information
   * @param rowIndex the index of the clicked row
   */
  handleShowKeys = rowIndex => {
    const { tables } = this.props
    const id = tables.data[rowIndex][this.headerData('XASNAME')]
    const name = tables.data[rowIndex][this.headerData('XALNAME')]
    const length = tables.data[rowIndex][this.headerData('XTRECLEN')]
    const location = tables.data[rowIndex][this.headerData('XTFNAME')]
    const callback = () => this.setState({
      showKeys: true,
      tableHeader: {
        id,
        name,
        length,
        location
      }
    })

    this.props.getTableKeys(id, callback)
  }

  /**
   * @description Handles the fields button in the keys table and shows their fields.
   * @param rowIndex The index of the current row.
   */
  handleShowFields = rowIndex => {
    const { tables } = this.props
    const id = tables.data[rowIndex][this.headerData('XASNAME')]
    const name = tables.data[rowIndex][this.headerData('XALNAME')]
    const length = tables.data[rowIndex][this.headerData('XTRECLEN')]
    const location = tables.data[rowIndex][this.headerData('XTFNAME')]
    const callback = () => {
      this.setState({
        showFields: true,
        tableHeader: {
          id,
          name,
          length,
          location
        }
      })
    }

    const table = tables.data[rowIndex][this.headerData('XASNAME')]
    this.props.getTableFields(table, callback)
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)


  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonFields_${rowIndex}`}
        iconType='material'
        iconName='fields'
        title={translate('database.tables_fields')}
        onClick={() => this.handleShowFields(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonKeys_${rowIndex}`}
        iconType='material'
        iconName='key'
        title={translate('database.tables_keys')}
        onClick={() => this.handleShowKeys(rowIndex)}
      />
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>
      ]
    )
  }

  /**
   * @description gets the index of the header in redux state tables.header
   * @param {String} header header name of the header in redux state tables.header
   */
  headerData = header => this.props.tables.header.indexOf(header)

  /**
   * @description builds the data to a an array which is used later
   */
  getData = () => {
    const { tables } = this.props
    const data = []
    tables.data.forEach(el => {
      data.push([
        el[this.headerData('XASNAME')],
        el[this.headerData('XALNAME')],
        parseInt(el[this.headerData('XTRECLEN')]),
        el[this.headerData('XTFNAME')]
      ])
    })
    return data
  }

  /**
   * @description Gets the header for the table. Includes empty header strings for the actions.
   */
  getDefaultHeader = () => {
    return [
      'database.table_id',
      'database.table_name',
      'general.length',
      'database.table_located_in'
    ]
  }

  render = () => {
    const { id, drawerExpanded, autoDrawer, tables, lang, datemask } = this.props
    const { tableHeader, showKeys, showFields } = this.state
    const data = tables && tables.data ? this.getData() : null
    let header = this.getDefaultHeader().map(h => translate(h))
    return (
      <>
        {showKeys &&
          <DatabaseKeysDialog
            id={`${id}_tablekeys`}
            tableHeader={tableHeader}
            onClose={() => this.setState({ showKeys: false })}
          />
        }
        {showFields &&
          <DatabaseFieldsDialog
            id={`${id}_tablefields`}
            tableHeader={tableHeader}
            onClose={() => this.setState({ showFields: false })}
          />
        }
        {data && (
          <ResultContainer
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}>
            <DataTable
              id={id}
              header={header}
              data={data}
              cleanData={data}
              selectable={true}
              createActionButtons={this.createActionButtons}
              createTableRowAction={this.handleShowFields}
              columnSortDefs={this.getColumnSortDefs(data, header)}
              additionalInteraction={this.createInteractionButtons(data, header)}
              fillPage
              translate={key => translate(key)}
              language={lang}
              datemask={datemask}
            />
          </ResultContainer>
        )}
      </>
    )
  }
}

SearchResultDatabaseTable.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    database: state.database,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    tables: state.database.tables,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTables: (callback) => {
      DatabaseActions.getTables(callback)(dispatch)
    },
    getTableKeys: (table, callback) => {
      DatabaseActions.getTableKeys(table, callback)(dispatch)
    },
    getTableFields: (table, callback) => {
      DatabaseActions.getTableFields(table, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultDatabaseTable)