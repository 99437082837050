import { Component } from 'react'
import { connect } from 'react-redux'

import {
  Button, Card, Column, DataTable, Dropdown, MetaDataGrid, Modal as ModalComponent, NumericSpinner, Row, Switch
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import { translate } from 'language/Language'
import { updateOutputQueue } from 'redux/actions/OutputQueueActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as Utils from 'utils/Utils'

import './RerunOutputQueue.scss'

class RerunOutputQueue extends Component {

  state = {
    newPriority: 0,
    rerunOptions: 0,
    startSequenz: 0,
    endSequenz: 0,
    startSequenzes: [],
    endSequenzes: []
  }

  componentDidMount = () => {
    const { outputQueue } = this.props
    const sequenzes = this.getSequenzes()
    this.setState({
      newPriority: parseInt(outputQueue.data[0][this.headerData('PRTPRIO')]),
      startSequenzes: sequenzes,
      endSequenzes: sequenzes,
      endSequenz: sequenzes.length - 1
    })
  }

  getSequenzes = () => {
    const { outputQueue } = this.props
    const result = []
    outputQueue.data.forEach(d => {
      if (!['INF', 'ALC', 'DEF', 'END'].includes(d[this.headerData('PRTDATA')])) {
        result.push(d[this.headerData('BSEQNR')])
      }
    })
    return result
  }

  headerData = header => this.props.outputQueue.header.indexOf(header)

  // ! STILL NEED TO CHECK IF ITS ALWAYS THE FIRST ENTRY IN THE OUTPUT DETAILS ARRAY
  renderHeader = () => {
    const { id, datemask, outputQueue } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.output_channel_id'), value: outputQueue.data[0][this.headerData('DCR')] },
          { label: translate('general.request_queued'), value: DateUtils.getDate(datemask, outputQueue.data[0][this.headerData('BDATE')], outputQueue.data[0][this.headerData('BTIME')].substring(0, 8)) },
          { label: translate('general.status'), value: outputQueue.data[0][this.headerData('BSTATUS')] },
          { label: translate('general.btoken'), value: outputQueue.data[0][this.headerData('BTOKEN')] },
          { label: translate('database.return_code'), value: outputQueue.data[0][this.headerData('PRTRC')] },
          { label: translate('general.form'), value: outputQueue.data[0][this.headerData('FORM')] },
          { label: translate('general.extension'), value: outputQueue.data[0][this.headerData('EXT')] },
          { label: translate('general.report'), value: outputQueue.data[0][this.headerData('REPORT')] },
          { label: translate('recipient.reci_id'), value: outputQueue.data[0][this.headerData('RECI')] },
          { label: translate('queue.priority'), value: outputQueue.data[0][this.headerData('PRTPRIO')] },
        ]}
        columns={4}
      />
    )
  }

  getData = () => {
    const { outputQueue } = this.props
    const result = []
    outputQueue.data.forEach(d => {
      if (!['INF', 'ALC', 'DEF', 'END'].includes(d[this.headerData('PRTDATA')])) {
        result.push([
          d[this.headerData('BSEQNR')],
          d[this.headerData('PRTDATA')],
          d[this.headerData('PRTBY')],
          d[this.headerData('FORM')],
          d[this.headerData('EXT')],
          d[this.headerData('REPORT')],
          d[this.headerData('RECI')],
          d[this.headerData('BSTATUS')],
          d[this.headerData('PRTPAGEO')],
          d[this.headerData('PRTPAGEI')],
          d[this.headerData('PRTCOPY')],
        ])
      }
    })
    return result

  }

  onChangeStartSequenz = index => {
    const { endSequenz, endSequenzes, startSequenzes } = this.state
    const currentEndSequenz = endSequenzes[endSequenz]
    // Gets the new start sequenz
    const newStartValue = startSequenzes[index]
    let newEndsequenzes = [...this.getSequenzes()]
    // Gets the position of the start sequenz
    const indexOfStartValue = newEndsequenzes.indexOf(newStartValue)
    // Trims all end sequenzes which are below the start sequenz
    newEndsequenzes = newEndsequenzes.splice(indexOfStartValue, newEndsequenzes.length)
    // Updates the old end sequenz index
    const newEndsequenz = newEndsequenzes.indexOf(currentEndSequenz) !== -1
      ? newEndsequenzes.indexOf(currentEndSequenz)
      : 0

    this.setState({
      startSequenz: index,
      endSequenz: newEndsequenz,
      endSequenzes: newEndsequenzes
    })
  }

  onChangeEndSequenz = index => {
    const { endSequenzes } = this.state
    // Gets the end sequenz
    const newEndValue = endSequenzes[index]
    let newStartSequenzes = [...this.getSequenzes()]
    // Gets the index of the value of the end sequenz in all start sequenzes
    const indexOfEndValue = newStartSequenzes.indexOf(newEndValue)
    newStartSequenzes = newStartSequenzes.splice(0, indexOfEndValue + 1)

    this.setState({
      endSequenz: index,
      startSequenzes: newStartSequenzes
    })
  }

  renderMain = () => {
    const { newPriority, rerunOptions, startSequenz, endSequenz, startSequenzes, endSequenzes } = this.state
    const { id, lang, datemask } = this.props
    const tableHeader = [
      translate('general.bseqnr'),
      translate('general.type'),
      translate('general.btype'),
      translate('general.form'),
      translate('general.extension'),
      translate('general.report'),
      translate('recipient.reci_id'),
      translate('general.status'),
      translate('queue.pages'),
      translate('general.epages'),
      translate('import.copies'),
    ]
    return (
      <>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_new_priority`}
              title={translate('general.new_priority')}
              onChange={val => this.setState({ newPriority: val })}
              value={newPriority}
              steps={1}
              min={0}
              max={15}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Switch
              id={`${id}_rerun_options`}
              title={translate('queue.rerun_options')}
              items={[translate('general.complete'), translate('general.partial')]}
              onClick={index => this.setState({ rerunOptions: index })}
              activeIndex={rerunOptions}
            />
          </Column>
          {
            rerunOptions === 1 &&
            <>
              <Column offsetMD={3} colMD={3}>
                <Dropdown
                  id={`${id}_start_sequenz`}
                  title={translate('general.start_sequenz')}
                  items={startSequenzes}
                  onChange={index => this.onChangeStartSequenz(index)}
                  activeIndex={startSequenz}
                />
              </Column>
              <Column colMD={3}>
                <Dropdown
                  id={`${id}_end_sequenz`}
                  title={translate('general.end_sequenz')}
                  items={endSequenzes}
                  onChange={index => this.onChangeEndSequenz(index)}
                  activeIndex={endSequenz}
                />
              </Column>
            </>
          }
        </Row>
        {
          rerunOptions === 1 &&
          <Row className={'bux_rerun_datatable_container'}>
            <Column colMD={12} className={'bux_full_height'}>
              <DataTable
                id={`${id}_sequenzes`}
                header={tableHeader}
                columnSortDefs={['string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string']}
                data={this.getData()}
                noAction
                language={lang}
                datemask={datemask}
                translate={key => translate(key)}
                menu={false}
              />
            </Column>
          </Row>
        }
      </>
    )
  }

  handleExecute = () => {
    const { startSequenz, endSequenz, startSequenzes, endSequenzes, newPriority, rerunOptions } = this.state
    const { outputQueue, updateOutputQueue, onClose } = this.props
    const rerunObj = rerunOptions === 1
      ? {
        startSequenz: startSequenzes[startSequenz],
        endSequenz: endSequenzes[endSequenz]
      }
      : undefined

    updateOutputQueue(
      outputQueue.data[0][this.headerData('BTOKEN')],
      outputQueue.data[0][this.headerData('DCR')],
      newPriority,
      rerunObj,
      'RERUN',
      onClose)
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={id}
          title={translate('queue.rerun_output_queue_request')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main>
          <Card className={Utils.getSpecificContainerStyle()}>
            {this.renderMain()}
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_execute`}
            text={translate('database.execute')}
            onClick={this.handleExecute}
            primary
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    outputQueue: state.queue.output.outputDetails,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateOutputQueue: (outputQueueID, outputChannelID, priority, rerunObj, command, callback) => {
      updateOutputQueue(outputQueueID, outputChannelID, priority, rerunObj, command, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RerunOutputQueue)