import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
// actions
export const STATISTIC_ENQUEUES_START = 'STATISTIC_ENQUEUES_START'
export const STATISTIC_ENQUEUES_SUCCESS = 'STATISTIC_ENQUEUES_SUCCESS'
export const STATISTIC_ENQUEUES_FAILED = 'STATISTIC_ENQUEUES_FAILED'

export const STATISTIC_COMPONENTS_START = 'STATISTIC_COMPONENTS_START'
export const STATISTIC_COMPONENTS_SUCCESS = 'STATISTIC_COMPONENTS_SUCCESS'
export const STATISTIC_COMPONENTS_FAILED = 'STATISTIC_COMPONENTS_FAILED'

export const STATISTIC_SERVERSTATUS_START = 'STATISTIC_SERVERSTATUS_START'
export const STATISTIC_SERVERSTATUS_SUCCESS = 'STATISTIC_SERVERSTATUS_SUCCESS'
export const STATISTIC_SERVERSTATUS_FAILED = 'STATISTIC_SERVERSTATUS_FAILED'

export const STATISTIC_ACTIVEUSERS_START = 'STATISTIC_ACTIVEUSERS_START'
export const STATISTIC_ACTIVEUSERS_SUCCESS = 'STATISTIC_ACTIVEUSERS_SUCCESS'
export const STATISTIC_ACTIVEUSERS_FAILED = 'STATISTIC_ACTIVEUSERS_FAILED'

export const STATISTIC_CONNECTS_START = 'STATISTIC_CONNECTS_START'
export const STATISTIC_CONNECTS_SUCCESS = 'STATISTIC_CONNECTS_SUCCESS'
export const STATISTIC_CONNECTS_FAILED = 'STATISTIC_CONNECTS_FAILED'

export const STATISTIC_TRANSACTIONS_START = 'STATISTIC_TRANSACTIONS_START'
export const STATISTIC_TRANSACTIONS_SUCCESS = 'STATISTIC_TRANSACTIONS_SUCCESS'
export const STATISTIC_TRANSACTIONS_FAILED = 'STATISTIC_TRANSACTIONS_FAILED'

export const STATISTIC_BSASTATS_START = 'STATISTIC_BSASTATS_START'
export const STATISTIC_BSASTATS_SUCCESS = 'STATISTIC_BSASTATS_SUCCESS'
export const STATISTIC_BSASTATS_FAILED = 'STATISTIC_BSASTATS_FAILED'

export const STATISTIC_DOWNLOAD_PDF_START = 'STATISTIC_DOWNLOAD_PDF_START'
export const STATISTIC_DOWNLOAD_PDF_SUCCESS = 'STATISTIC_DOWNLOAD_PDF_SUCCESS'
export const STATISTIC_DOWNLOAD_PDF_FAILED = 'STATISTIC_DOWNLOAD_PDF_FAILED'

export const STATISTIC_LOGX_BSASTATS_START = 'STATISTIC_LOGX_BSASTATS_START'
export const STATISTIC_LOGX_BSASTATS_SUCCESS = 'STATISTIC_LOGX_BSASTATS_SUCCESS'
export const STATISTIC_LOGX_BSASTATS_FAILED = 'STATISTIC_LOGX_BSASTATS_FAILED'

export const NO_ENQUEUES_FOUND = 'NO_ENQUEUES_FOUND'
export const NO_COMPONENTS_FOUND = 'NO_COMPONENTS_FOUND'
export const NO_ACTIVEUSERS_FOUND = 'NO_ACTIVEUSERS_FOUND'
export const NO_CONNECTS_FOUND = 'NO_CONNECTS_FOUND'
export const NO_TRANSACTIONS_FOUND = 'NO_TRANSACTIONS_FOUND'
export const NO_BSASTATS_FOUND = 'NO_BSASTATS_FOUND'

/**
 * @description Calling the rest api and do and enqueues request.
 */
export function getEnqueues() {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: STATISTIC_ENQUEUES_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/statistic/enqueues`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_ENQUEUES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: STATISTIC_ENQUEUES_FAILED })
          }
        }
        else {
          dispatch({ type: STATISTIC_ENQUEUES_SUCCESS, payload: jsondata })
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('statistic.enqueues_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: STATISTIC_ENQUEUES_FAILED })
      })
  }
}

/**
 * @description Calling the rest api and do and components request.
 */
export function getComponents() {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: STATISTIC_COMPONENTS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/statistic/components`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_COMPONENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: STATISTIC_COMPONENTS_FAILED })
          }
        }
        else {
          dispatch({ type: STATISTIC_COMPONENTS_SUCCESS, payload: jsondata })
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('statistic.components_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: STATISTIC_COMPONENTS_FAILED })
      })
  }
}

/**
 * @description Calling the rest api and do and serverstatus request.
 */
export function getServerstatus(callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: STATISTIC_SERVERSTATUS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/statistic/serverstatus`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: STATISTIC_SERVERSTATUS_FAILED })
        }
        else {
          dispatch({ type: STATISTIC_SERVERSTATUS_SUCCESS, payload: jsondata })
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('statistic.serverstatus_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: STATISTIC_SERVERSTATUS_FAILED })
      })
  }
}

/**
 * @description Calling the rest api and do and activeusers request.
 */
export function getActiveUsers(callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: STATISTIC_ACTIVEUSERS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/statistic/activeusers`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_ACTIVEUSERS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: STATISTIC_ACTIVEUSERS_FAILED })
          }
        }
        else {
          dispatch({ type: STATISTIC_ACTIVEUSERS_SUCCESS, payload: jsondata })
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('statistic.activeusers_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: STATISTIC_ACTIVEUSERS_FAILED })
      })
  }
}

/**
 * @description Calling the rest api and do and connects request.
 */
export function getConnects() {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: STATISTIC_CONNECTS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/statistic/connects`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_CONNECTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: STATISTIC_CONNECTS_FAILED })
          }
        }
        else {
          dispatch({ type: STATISTIC_CONNECTS_SUCCESS, payload: jsondata })
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('statistic.connects_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: STATISTIC_CONNECTS_FAILED })
      })
  }
}

/**
 * @description Calling the rest api and do and transactions request.
 */
export function getTransactions() {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: STATISTIC_TRANSACTIONS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/statistic/transactions`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_TRANSACTIONS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: STATISTIC_TRANSACTIONS_FAILED })
          }
        }
        else {
          dispatch({ type: STATISTIC_TRANSACTIONS_SUCCESS, payload: jsondata })
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('statistic.transactions_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: STATISTIC_TRANSACTIONS_FAILED })
      })
  }
}

/**
 * @description Calling the rest api and do and bsastats request.
 * @param {String} fromdate
 * @param {String} todate
 * @param {String} unit
 */
export function getBsaStats(fromdate, todate, unit) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: STATISTIC_BSASTATS_START})
    /* Build request-parameter for URL */
    const queryParams = []
    queryParams.push(`FROMDATE=${encodeURIComponent(fromdate)}`)
    queryParams.push(`TODATE=${encodeURIComponent(todate)}`)
    queryParams.push(`TUNITS=${encodeURIComponent(unit.toUpperCase())}`)

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/statistic/bsastats?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = {}

          // check specific errors
          // illegal date format
          if (rc === '0020' && irc === '0006') {
            const message = Lang.translate('general.invalid_date_format', lang)
            SnackbarActions.show(Lang.translate('statistic.bsastats_error', lang, message), SnackbarActions.TYPE_ERROR)(dispatch)
            dispatch({ type: STATISTIC_BSASTATS_FAILED })
          }
          else if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_BSASTATS_FOUND })
          } else {
            // check general errors
            error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(Lang.translate('statistic.bsastats_error', lang, error.message), error.type)(dispatch)
            dispatch({ type: STATISTIC_BSASTATS_FAILED })
          }
        }
        else {
          dispatch({ type: STATISTIC_BSASTATS_SUCCESS, payload: jsondata })
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('statistic.bsastats_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: STATISTIC_BSASTATS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do download bsastats pdf request.
 * @param {String} todate The ending search date of the bsastats.
 * @param {String} fromdate The starting search date of the bsastats.
 */
export function getDownloadPdf(todate, fromdate, filename) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const usertoken = store.getState().auth.serverdata.token

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: STATISTIC_DOWNLOAD_PDF_START})

    /* Build request-parameter for URL */
    const queryParams = []
    queryParams.push(`USERTOKEN=${encodeURIComponent(usertoken)}`)
    queryParams.push(`FROMDATE=${encodeURIComponent(fromdate)}`)
    queryParams.push(`TODATE=${encodeURIComponent(todate)}`)

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/statistic/bsastats/download?${queryParams.join('&')}`, {
        method: 'get',
        headers: {
          token: undefined
        },
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)
    cancelablePromise
      .promise
      .then(response => response)
      .then(jsondata => {
        if (jsondata.status !== 200) {
          jsondata.json().then(o => {
            let rc = o.error.rc
            let irc = o.error.irc

            let error = {}

            // check specific errors
            // illegal date format
            if (rc === '0020' && irc === '0006') {
              const message = Lang.translate('general.invalid_date_format', lang)
              SnackbarActions.show(Lang.translate('statistic.bsastats_error', lang, message), SnackbarActions.TYPE_ERROR)(dispatch)
              dispatch({ type: STATISTIC_DOWNLOAD_PDF_FAILED })
            }
            else if (rc === '0016' && irc === '0000') {
              SnackbarActions.show(Lang.translateRC(rc, irc, lang, o.error.param), SnackbarActions.TYPE_INFO)(dispatch)
              dispatch({ type: NO_BSASTATS_FOUND })
            } else {
              // check general errors
              error = GeneralErrorHandler.handleResponse(rc, irc, o.error.param, dispatch)
              SnackbarActions.show(Lang.translate('statistic.bsastats_error', lang, error.message), error.type)(dispatch)
              dispatch({ type: STATISTIC_DOWNLOAD_PDF_FAILED })
            }
          })
        }
        else {
          return jsondata.blob()
            .then(res => res && URL.createObjectURL(res))
            .then(url => {
              let element = document.createElement('a')
              element.setAttribute('href', url)
              element.style.display = 'none'
              element.download = filename
              document.body.appendChild(element)
              element.click()
              document.body.removeChild(element)
              dispatch({ type: STATISTIC_DOWNLOAD_PDF_SUCCESS })
              LoadingSpinnerActions.hide()(dispatch)
            })
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('statistic.bsastats_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: STATISTIC_DOWNLOAD_PDF_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}


/**
 * @description Calling the rest api and do and bsastats request.
 * @param {String} fromdate
 * @param {String} todate
 * @param {String} unit
 */
export function getLogxBsaStats(fromdate, todate, unit) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: STATISTIC_LOGX_BSASTATS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    queryParams.push(`FROMDATE=${encodeURIComponent(fromdate)}`)
    queryParams.push(`TODATE=${encodeURIComponent(todate)}`)
    queryParams.push(`TUNITS=${encodeURIComponent(unit.toUpperCase())}`)

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/statistic/bsastats?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = {}

          // check specific errors
          // illegal date format
          if (rc === '0020' && irc === '0006') {
            const message = Lang.translate('general.invalid_date_format', lang)
            SnackbarActions.show(Lang.translate('statistic.bsastats_error', lang, message), SnackbarActions.TYPE_ERROR)(dispatch)
            dispatch({ type: STATISTIC_LOGX_BSASTATS_FAILED })
          }
          else if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_BSASTATS_FOUND })
          } else {
            // check general errors
            error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(Lang.translate('statistic.bsastats_error', lang, error.message), error.type)(dispatch)
            dispatch({ type: STATISTIC_LOGX_BSASTATS_FAILED })
          }
        }
        else {
          dispatch({ type: STATISTIC_LOGX_BSASTATS_SUCCESS, payload: jsondata })
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('statistic.bsastats_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: STATISTIC_LOGX_BSASTATS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}