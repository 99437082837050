import React, {useEffect} from 'react';
import {autoFocusOnFirstTabIndex} from '../../utils/ComponentUtils';
import './Snackbar.scss';
import {SnackbarGroup} from './Snackbar.types';

// Other components
import SnackbarTile from './snackbar_tile/SnackbarTile';

interface SnackbarProps {
  /** Unique ID for Snackbar-HTML-element */
  id: string;
  /**
   * List of object which define snackbar-message(-group)
   */
  content: SnackbarGroup[];
  /**
   * Callback on remove snackbar-tile from snackbar.
   * @param {string} id Id of the snackbar
   */
  onClose: (id: number) => void;
}

/**
 * Snackbar component created according to
 * _Messages Banner_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
const Snackbar = ({...props}: SnackbarProps): JSX.Element => {

  useEffect(() => {
    if (props.content.length > 0) {
      autoFocusOnFirstTabIndex(props.id);
    }
  }, [props.content, props.id]);


  return (
    <>
      {/* Snackbar container which will contain all snackbartiles */}
      <div id={props.id} className={'bux_snackbar_container'}>
        <div id={`${props.id}_scroll_wrapper`} className={'bux_snackbar_scroll_wrapper'}>

          {/* Create snackbar tile per message object to render */}
          {props.content.map((snackbarObj, i) =>
            <SnackbarTile
              id={`snackbar_tile_${i}`}
              key={snackbarObj.id}
              onDestroy={() => props.onClose(snackbarObj.id)}
              content={snackbarObj.content}
              customTitle={snackbarObj.customTitle}
              index={i}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Snackbar;