import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ImportStandardImport from './import_standard_import/ImportStandardImport'
import ImportCustomImport from './import_custom_import/ImportCustomImport'

class ImportBody extends Component {

  getBody = () => {
    switch (this.props.importMode) {
      // standard import
      case 'standardimport': return <ImportStandardImport id={this.props.id} />
      // import dialogs
      case 'customimport': return <ImportCustomImport id={this.props.id} />
      // should not happen
      default: return null
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

ImportBody.propTypes = {
  id: PropTypes.string.isRequired,
}

export default ImportBody