import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './ResultContainer.scss'

export default class ResultContainer extends Component {

  static propTypes = {
    /** Defines if the drawer is expanded or not. Valid values are 'initialized' (initial state without animation), true and false. */
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    /**
     * The function provide control of the drawer expand/collapse behavior.
     *
     * @param {bool|string} value Value of drawer state. Valid values are 'initialized' (initial state without animation), true and false.
     */
    autoDrawer: PropTypes.func.isRequired,
    /** Sets drawer color */
    drawerColor: PropTypes.bool,
    /** Children of the component.*/
    children: PropTypes.node,
  }

  state = {
    autoCollapse: 1200
  }

  componentDidMount = () => {
    this.calcPosition()
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resize)
  }

  // is triggered when user toggle drawer
  componentWillReceiveProps = nextProps => {
    const { drawerExpanded } = this.props
    const { autoCollapse } = this.state
    if (nextProps.drawerExpanded !== drawerExpanded) {
      let window = document.body.clientWidth
      let el = document.querySelector('.bux_result_container')
      if (drawerExpanded !== 'initialized' && !drawerExpanded) {
        if (window > autoCollapse) {
          el.classList.remove('bux_show_result_left')
          el.classList.remove('bux_move_result_left')
          el.classList.add('bux_move_result_right')
        }
      } else {
        el.classList.remove('bux_show_result_right')
        el.classList.remove('bux_move_result_right')
        el.classList.add('bux_move_result_left')
      }
    }
  }

  /**
  * @description Calculates where the table is placed when the page is loaded.
  */
  calcPosition = () => {
    const { autoCollapse } = this.state
    let window = document.body.clientWidth
    let el = document.querySelector('.bux_result_container')
    if (window <= autoCollapse) {
      el.classList.add('bux_show_result_left')
    }
    else {
      el.classList.add('bux_show_result_right')
    }
  }

  resize = () => {
    const { drawerExpanded, autoDrawer } = this.props
    const { autoCollapse } = this.state
    let window = document.body.clientWidth
    if (drawerExpanded === 'initialized' || drawerExpanded) {
      let el = document.querySelector('.bux_result_container')
      if (window <= autoCollapse) {
        if (!el.classList.contains('bux_move_result_left') && !el.classList.contains('bux_show_result_left')) {
          autoDrawer(false)
        }
      } else {
        if (el.classList.contains('bux_move_result_left')) {
          el.classList.remove('bux_move_result_left')
          el.classList.add('bux_move_result_right')
        } else {
          el.classList.remove('bux_show_result_left')
          el.classList.add('bux_move_result_right')
        }
      }
    }
  }

  render = () => {
    const { children, drawerColor } = this.props
    return (
      <div className={`bux_result_container ${drawerColor ? 'drawerColor' : ''}`}>
        {children}
      </div>
    )
  }
}