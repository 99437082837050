import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CopySearchArgumentDialog from 'components/dialogs/copy_search_argument_dialog/CopySearchArgumentDialog'
import CreateSearchArgumentDialog from 'components/dialogs/create_search_argument_dialog/CreateSearchArgumentDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifySearchArgumentDialog from 'components/dialogs/modify_search_argument_dialog/ModifySearchArgumentDialog'
import VerifySearchArgumentDialog from 'components/dialogs/verify_search_argument_dialog/VerifySearchArgumentDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as SearchArgumentActions from 'redux/actions/SearchArgumentDefinitionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as SortUtils from 'utils/SortUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultSearchArgument extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired
  }

  state = {
    showTableSettings: false,
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showVerifyDialog: false,
    showDeleteDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: SEARCHARGUMENT_ID, translation: 'definition.searchargument_id', default: true },
      { rest: FORM, translation: 'general.form', default: true },
      { rest: EXTENSION, translation: 'general.extension', default: true },
      { rest: REPORT, translation: 'general.report', default: true },
      { rest: TYPE, translation: 'general.type', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: LAST_CHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description Handles the modal dialogs for create, modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { getSearchArgument, searchArguments } = this.props
    const sasid = searchArguments.data[index][this.headerData('SASID')]
    const form = searchArguments.data[index][this.headerData('FORM')]
    const ext = searchArguments.data[index][this.headerData('EXT')]
    const report = searchArguments.data[index][this.headerData('REPORT')]

    getSearchArgument(sasid, form, ext, report, () => this.setState({ [dialog]: true }))
  }

  /**
   * @description Gets the verify information and shows the dialog
   * @param {Number} index Index of the data array
   */
  handleVerify = index => {
    const { searchArguments, getSearchArgument, verifySearchArgument } = this.props
    const sasid = searchArguments.data[index][this.headerData('SASID')]
    const form = searchArguments.data[index][this.headerData('FORM')]
    const ext = searchArguments.data[index][this.headerData('EXT')]
    const report = searchArguments.data[index][this.headerData('REPORT')]
    getSearchArgument(sasid, form, ext, report, () => {
      const { searchArgument } = this.props
      const searchArgumentDef = {
        SASID: searchArgument[0].SASID,
        FORM: searchArgument[0].FORM,
        EXTENSION: searchArgument[0].EXTENSION,
        REPORT: searchArgument[0].REPORT
      }
      verifySearchArgument(searchArgumentDef, () => this.setState({ showVerifyDialog: true }))
    })
  }

  /**
   * @description Refreshes the table.
   */
  handleRefresh = () => {
    const { preferences, getSearchArguments } = this.props
    const searchargumentID = preferences[Preferences.DEFINITION_SEARCHARGUMENT_ID]
    const form = preferences[Preferences.DEFINITION_SEARCHARGUMENT_FORM]
    const extension = preferences[Preferences.DEFINITION_SEARCHARGUMENT_EXTENSION]
    const report = preferences[Preferences.DEFINITION_SEARCHARGUMENT_REPORT]

    getSearchArguments(undefined, searchargumentID, form, extension, report)
  }

  /**
   * @description Deletes the search argument with verify or forced
   * @param {String} process 2 options:
   * 'DELETE' to verify before deleting
   * 'FORCE' to force the delete process without verify
   */
  handleDelete = process => {
    const { searchArgument, deleteSearchArgument } = this.props
    const searchArguments = {
      SASID: searchArgument[0].SASID,
      FORM: searchArgument[0].FORM,
      EXTENSION: searchArgument[0].EXTENSION,
      REPORT: searchArgument[0].REPORT,
      PROCESS: process
    }
    deleteSearchArgument(searchArguments, () => this.setState({ showDeleteDialog: false }))
  }

  /**
   * @description Gets the index of the header in redux state definitions.searchArguments.header
   * @param {String} header header name of the header in redux state definitions.searchArguments.header
   */
  headerData = header => this.props.searchArguments.header.indexOf(header)

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to display.
   * @param {Array} header The header of the the columns.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)


  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          id={`${id}_verifyBtn`}
          icon={<Icon name='verify' className='actionIcon' />}
          text={translate('general.verify')}
          title={translate('general.verify')}
          onClick={() => this.handleVerify(rowIndex)}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.openPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettings: true })}
        />,
      ]
    )
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, searchArguments } = this.props
    const data = []
    const headers = this.getUsedHeader()
    searchArguments.data.forEach(el => {
      const dataBuffer = []
      headers.forEach(header => {
        if (header === TYPE) {
          const found = DefinitionUtils.SEARCH_ARGUMENT_TYPES.find(element => element.key === el[this.headerData('SASTYPE')])
          dataBuffer.push(found ? translate(found.translationKey) : el[this.headerData('SASTYPE')])
        }
        else if (header === LAST_CHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_SEARCHARGUMENT]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_SEARCHARGUMENT].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_SEARCHARGUMENT]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_SEARCHARGUMENT].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  openPrefilledCreateDialog = () => {
    const { preferences } = this.props
    this.setState({
      showCreateDialog: true,
      createDialogData: {
        SASID: preferences[Preferences.DEFINITION_SEARCHARGUMENT_ID],
        FORM: preferences[Preferences.DEFINITION_SEARCHARGUMENT_FORM],
        EXTENSION: preferences[Preferences.DEFINITION_SEARCHARGUMENT_EXTENSION],
        REPORT: preferences[Preferences.DEFINITION_SEARCHARGUMENT_REPORT],
      }
    })
  }

  render = () => {
    const { searchArguments, searchArgument, id, loading, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettings, showCreateDialog, showModifyDialog, showCopyDialog, showDeleteDialog, showVerifyDialog } = this.state
    const data = searchArguments && searchArguments.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {showCreateDialog && (
          <CreateSearchArgumentDialog
            id={`${id}_createsearchargument`}
            prefilledData={this.state.createDialogData}
            onClose={() => this.setState({ showCreateDialog: false, createDialogData: undefined })}
          />
        )}
        {showCopyDialog && (
          <CopySearchArgumentDialog
            id={`${id}_copysearchargument`}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        )}
        {
          showModifyDialog &&
          <ModifySearchArgumentDialog
            id={`${id}_modify_searchargument`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        }
        {showVerifyDialog && (
          <VerifySearchArgumentDialog
            id={`${id}_verifysearchargument`}
            onClose={() => this.setState({ showVerifyDialog: false })}
          />
        )}
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_deletesearchargumentdialog`}
            title={translate('definition.delete_search_argument')}
            question={translate('definition.question_delete_search_argument')}
            onClose={() => this.setState({ showDeleteDialog: false })}
            onDelete={() => this.handleDelete('FORCE')}
            onDeleteVerify={() => this.handleDelete('DELETE')}
            verifyDialog>
            <Row>
              <Column
                colMD={4}>
                <p id={`${id}_search_argument_id_key_text`}>
                  {translate('definition.searchargument_id')}:
                </p>
              </Column>
              <Column
                colMD={8}>
                <p id={`${id}_search_argument_id_value_text`}>
                  {searchArgument[0].SASID}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={4}>
                <p id={`${id}_search_argument_form_key_text`}>
                  {translate('general.form')}:
                </p>
              </Column>
              <Column
                colMD={8}>
                <p id={`${id}_search_argument_form_value_text`}>
                  {searchArgument[0].FORM}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={4}>
                <p id={`${id}_search_argument_extension_key_text`}>
                  {translate('general.extension')}:
                </p>
              </Column>
              <Column
                colMD={8}>
                <p id={`${id}_search_argument_extension_value_text`}>
                  {searchArgument[0].EXTENSION}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={4}>
                <p id={`${id}_search_argument_report_key_text`}>
                  {translate('general.report')}:
                </p>
              </Column>
              <Column
                colMD={8}>
                <p id={`${id}_search_argument_report_value_text`}>
                  {searchArgument[0].REPORT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={4}>
                <p id={`${id}_search_argument_type_key_text`}>
                  {translate('general.type')}:
                </p>
              </Column>
              <Column
                colMD={8}>
                <p id={`${id}_search_argument_type_value_text`}>
                  {translate(DefinitionUtils.SEARCH_ARGUMENT_TYPES.find(element => element.key === searchArgument[0].SASTYPE)?.translationKey)}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={4}>
                <p id={`${id}_search_argument_title_key_text`}>
                  {translate('general.title')}:
                </p>
              </Column>
              <Column
                colMD={8}>
                <p id={`${id}_search_argument_title_value_text`}>
                  {searchArgument[0].SASTITLE}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettings && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettings: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_SEARCHARGUMENT }}
          />)
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {searchArguments
            ? (
              data
                ? (
                  <DataTable
                    loading={loading}
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    fillPage={fillPage}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={index => this.handleDialog(index, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    selectable
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_search_argument_result')}
                    link={translate('emptyresult.create_search_argument_link')}
                    onClick={() => this.openPrefilledCreateDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const SEARCHARGUMENT_ID = 'SASID'
const FORM = 'FORM'
const EXTENSION = 'EXT'
const REPORT = 'REPORT'
const TYPE = 'SASTYPE'
const TITLE = 'SASTITLE'
const LAST_CHANGED = 'LAST_CHANGED'
const CDATE = 'CDATE'
const CTIME = 'CTIME'

const mapStateToProps = state => {
  return {
    searchArguments: state.definitions.searcharguments.searchArguments,
    searchArgument: state.definitions.searcharguments.searchArgument,
    keepPagination: state.definitions.searcharguments.keepPagination,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getSearchArguments: (fields, sasid, form, extension, report, callback) => {
      SearchArgumentActions.getSearchArguments(fields, sasid, form, extension, report, callback)(dispatch)
    },
    getSearchArgument: (sasid, form, ext, report, callback) => {
      SearchArgumentActions.getSearchArgument(sasid, form, ext, report, callback)(dispatch)
    },
    deleteSearchArgument: (searchArgument, callback) => {
      SearchArgumentActions.deleteSearchArgument(searchArgument, callback)(dispatch)
    },
    verifySearchArgument: (searchArgument, callback) => {
      SearchArgumentActions.verifySearchArgument(searchArgument, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultSearchArgument)