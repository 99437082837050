import { useEffect, useRef, useState } from 'react';

import * as TYPES_DOC_VIEWER from '../types/DocumentViewer.types';
import { getChangedTabsBasedOnIndexData } from './utils';

export const useCompareIndexToHighlight = (tabs: TYPES_DOC_VIEWER.ITabData[]) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);
  const currentTabsRef = useRef<TYPES_DOC_VIEWER.ITabData[] | []>([]);

  useEffect(() => {
    if (currentTabsRef.current.length < tabs.length) {
      const newTabs = tabs.filter((tab: TYPES_DOC_VIEWER.ITabData) => !currentTabsRef.current.some((t: TYPES_DOC_VIEWER.ITabData) => t.id === tab.id));

      const countNewTabs = newTabs.length;
      if (countNewTabs === 0) return;

      const indexNewestTab = tabs.findIndex((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === newTabs[countNewTabs - 1].id);
      if (indexNewestTab === -1) return;

      currentTabsRef.current = tabs;
      setActiveTabIndex(indexNewestTab);
    }

    if (currentTabsRef.current.length === tabs.length) {
      const changedIndexTabs = getChangedTabsBasedOnIndexData(currentTabsRef.current, tabs);

      const countChangedIndexTabs = changedIndexTabs.length;
      if (countChangedIndexTabs === 0) return;

      const indexChangedTab = tabs.findIndex((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === changedIndexTabs[countChangedIndexTabs - 1].id);
      if (indexChangedTab === -1) return;

      currentTabsRef.current = tabs;
      setActiveTabIndex(indexChangedTab);
    }

    if (currentTabsRef.current.length > tabs.length) {
      currentTabsRef.current = tabs;
      setActiveTabIndex(null);
    }
  }, [tabs]);

  return activeTabIndex;
};

export const usePrevious = (value: string | number) => {
  const ref = useRef(typeof value === 'string' ? '' : 0);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};