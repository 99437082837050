import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import Link from '../link/Link'

// Style
import './ModalDialog.scss'

/**
 * ModalDialog component created according to
 * _Modal_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
class ModalDialog extends Component {

  componentDidMount() {
    document.addEventListener('focus', this.handleFocus, true)
  }

  componentWillUnmount() {
    document.removeEventListener('focus', this.handleFocus, true)
  }

  handleFocus = (e) => {
    // Note: the modal dialog itself will also get the focus

    // consume the event
    e.preventDefault()

    // get the modal dialog as container element
    const containerElement = document.querySelector('.modalDialogBUX')
    if (containerElement === null || document.activeElement === null) {
      return
    }

    // check if the current focused element is inside the container element
    const isFocusOutsideModal = !containerElement.contains(document.activeElement)

    // if the focus is outside set it to the next component inside -> modal dialog
    if (isFocusOutsideModal) {
      const autofocusElement = containerElement.querySelector('[autofocus]')
      const wrapperElement = containerElement.querySelector('[tabindex]')

      // set the focus
      if (autofocusElement !== null) {
        autofocusElement.focus()
      } else if (wrapperElement !== null) {
        wrapperElement.focus()
      }
    }
  }

  render() {
    const { id, small, title, onClose, header, children } = this.props
    return (
      ReactDOM.createPortal(
        <div
          id={id}
          className='modalDialogBUX'>
          <div
            id={`${id}_content`}
            tabIndex={0}
            className={`modalDialogContent ${small ? 'smallSized' : 'normalSized'}`}>
            <div
              id={`${id}_content_header`}
              className='modalDialogHeader'>
              <h4
                id={`${id}_content_header_title`}
                className='no_select'>
                {title}
              </h4>
              <Link
                id={`${id}_CloseBtn`}
                iconName={'close'}
                tooltip={'Close'}
                onClick={onClose}
                className={'modalDialogClose'}
              />
              {header}
            </div>
            {children}
          </div>
        </div>
        , document.querySelector('#modalDialog_Portal')
      )
    )
  }
}

ModalDialog.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string.isRequired,
  /** Enables small size fo the component */
  small: PropTypes.bool,
  /** Text which will be display on the top of the modal */
  title: PropTypes.string,
  /** Function to be called on dialog close.*/
  onClose: PropTypes.func.isRequired,
  /** JSX element which can be placed after close button */
  header: PropTypes.any,
  /** Children of the component.*/
  children: PropTypes.node
}

export default ModalDialog