import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './ModifyGroupDialog.scss';

// Components
import {
  Button,
  Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent, RelationAssignments, Row,
  Tab,
  Tabs
} from 'BetaUX2Web-Components/src/';

const { Modal, Main, Header, Footer } = ModalComponent

// Utils
import * as DateUtils from 'utils/DateUtils';

// Redux
import { connect } from 'react-redux';
import * as GroupActions from 'redux/actions/GroupActions';
import * as SnackbarActions from 'redux/actions/SnackbarActions';
import * as UserGroupAssignmentActions from 'redux/actions/UserGroupAssignmentActions';
import * as Preferences from 'redux/general/Preferences';
import * as Utils from 'utils/Utils';

class ModifyGroupDialog extends Component {
  state = {
    groupname: {
      value: this.props.group.GRPNAME,
      errorkey: ''
    },
    searchUserId: {
      value: '',
      errorkey: ''
    },
    usersTabIsVisible: false,
    assigned: {
      sortedCol: 0,
      data: [],
      origData: []
    },
    unassigned: {
      sortedCol: 0,
      data: []
    },
    selectedIds: [],
    draggingId: null
  }

  groupnameInput = React.createRef()

  /**
   * @description Sets the initial focus and initialize the values.
   */
  componentDidMount() {
    // focus user id input initially
    this.groupnameInput.current.focus()

    this.setState({
      assigned: {
        sortedCol: 0,
        data: this.getAssignedTableData(),
      },
      unassigned: {
        sortedCol: 0,
        data: this.getUnassignedTableData(this.getOrigUnassignedIds())
      }
    })

    window.addEventListener('click', this.onWindowClick)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.users !== this.props.users && this.props.users) {
      this.setState({
        unassigned: {
          ...this.state.unassigned,
          data: this.getUnassignedTableData(this.getSearchedUserIds(this.props.users))
        }
      })
    }
  }

  /**
   * @description Removes the click listener which unselects items.
   */
  componentWillUnmount = () => {
    window.removeEventListener('click', this.onWindowClick)
  }

  /**
   * @description Unselects all items.
   */
  unselectAll = (callback) => {
    const internalCallback = () => {
      if (callback) {
        callback()
      }
    }

    this.setState({
      selectedIds: []
    }, () => internalCallback())
  }

  /**
   * @description Unselects all items if the user clicked on the screen.
   * @param {Object} event The mouse event.
   */
  onWindowClick = (event) => {
    if (event.defaultPrevented) {
      return
    }

    this.unselectAll()
  }

  /**
   * @description Calls the rest api and modifies the current group.
   */
  handleOnSave = () => {
    const { cuser, group, lang } = this.props

    // declare success callback
    const callback = () => { this.props.onClose() }

    this.props.updateGroup(cuser, group.BETAGRP, this.state.groupname.value, callback)

    const assignmentCount = this.getUserIdsToAssign().length
    const results = []
    const singleAssignRun = i => {
      const userid = this.getUserIdsToAssign()[i]
      userid && this.props.createAssignment(group.BETAGRP, userid, result => {
        if (result?.success) {
          results.push({ success: true, userid: userid })
        } else {
          results.push({ success: false, message: result?.message, userid: userid })
        }
        if (i < assignmentCount - 1) {
          singleAssignRun(i + 1)
        } else if (i === assignmentCount - 1) {
          const snackbarMessages = []
          const fullContent = []
          const successAssignment = results.filter(result => result.success)
          const failedAssignment = results.filter(result => !result.success)
          if (successAssignment.length > 0) {
            if (successAssignment.length <= 2) {
              successAssignment.forEach(sAss => {
                snackbarMessages.push({ text: translate('usermanagement.create_user_group_assignment_success', lang, [group.BETAGRP, sAss.userid]), type: SnackbarActions.TYPE_SUCCESS })
              })
            } else {
              successAssignment.forEach(sAss => {
                fullContent.push({ text: translate('usermanagement.create_user_group_assignment_success', lang, [group.BETAGRP, sAss.userid]), type: SnackbarActions.TYPE_SUCCESS })
              })
              snackbarMessages.push({ text: translate('assignment.count_successfully_created', lang, [successAssignment.length.toString()]), type: SnackbarActions.TYPE_SUCCESS })
            }
          }
          if (failedAssignment.length > 0) {
            if (failedAssignment.length <= 2) {
              failedAssignment.forEach(fAss => {
                snackbarMessages.push({ text: translate('usermanagement.create_user_group_assignment_failed', lang, [group.BETAGRP, fAss.userid]), type: SnackbarActions.TYPE_ERROR })
              })
            } else {
              failedAssignment.forEach(fAss => {
                fullContent.push({ text: translate('usermanagement.create_user_group_assignment_failed', lang, [group.BETAGRP, fAss.userid]), type: SnackbarActions.TYPE_ERROR })
              })
              snackbarMessages.push({ text: translate('assignment.count_fail_created', lang, [failedAssignment.length.toString()]), type: SnackbarActions.TYPE_ERROR })
            }
          }
          this.props.showMultiple(snackbarMessages, fullContent)
        }
      }, true)
    }
    singleAssignRun(0)

    const unassignmentCount = this.getUserIdsToUnassign().length
    const uresults = []
    const singleUnassignRun = i => {
      const userid = this.getUserIdsToUnassign()[i]
      userid && this.props.deleteAssignment(group.BETAGRP, userid, result => {
        if (result?.success) {
          uresults.push({ success: true, userid: userid })
        } else {
          uresults.push({ success: false, message: result?.message, userid: userid })
        }
        if (i < unassignmentCount - 1) {
          singleUnassignRun(i + 1)
        } else if (i === unassignmentCount - 1) {
          const snackbarMessages = []
          const fullContent = []
          const successAssignment = uresults.filter(result => result.success)
          const failedAssignment = uresults.filter(result => !result.success)
          if (successAssignment.length > 0) {
            if (successAssignment.length <= 2) {
              successAssignment.forEach(sAss => {
                snackbarMessages.push({ text: translate('usermanagement.delete_user_group_assignment_success', lang, [group.BETAGRP, sAss.userid]), type: SnackbarActions.TYPE_SUCCESS })
              })
            } else {
              successAssignment.forEach(sAss => {
                fullContent.push({ text: translate('usermanagement.delete_user_group_assignment_success', lang, [group.BETAGRP, sAss.userid]), type: SnackbarActions.TYPE_SUCCESS })
              })
              snackbarMessages.push({ text: translate('assignment.count_successfully_deleted', lang, [successAssignment.length.toString()]), type: SnackbarActions.TYPE_SUCCESS })
            }
          }
          if (failedAssignment.length > 0) {
            if (failedAssignment.length <= 2) {
              failedAssignment.forEach(fAss => {
                snackbarMessages.push({ text: translate('usermanagement.delete_user_group_assignment_failed', lang, [group.BETAGRP, fAss.userid]), type: SnackbarActions.TYPE_ERROR })
              })
            } else {
              failedAssignment.forEach(fAss => {
                fullContent.push({ text: translate('usermanagement.delete_user_group_assignment_failed', lang, [group.BETAGRP, fAss.userid]), type: SnackbarActions.TYPE_ERROR })
              })
              snackbarMessages.push({ text: translate('assignment.count_fail_deleted', lang, [failedAssignment.length.toString()]), type: SnackbarActions.TYPE_ERROR })
            }
          }
          this.props.showMultiple(snackbarMessages, fullContent)
        }
      }, true)
    }
    singleUnassignRun(0)
  }

  /**
   * @description Searches the users with the given string.
   * @param {Object} event The event which is fired from the click on the button.
   */
  handleSearch = (event) => {
    event.preventDefault()

    this.props.searchUsers(this.state.searchUserId.value)
  }

  /**
   * @description Handles the input changes.
   * @param {String} key The id of the textfield.
   * @param {String} value The new value.
   */
  handleChange(key, value) {
    this.setState({ [key]: value })
  }

  /**
   * @description Handles the groupname changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleGroupnameChanged(value, error) {
    this.setState({
      groupname: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Gets the original unassigned user ids.
   */
  getOrigUnassignedIds() {
    const { users, userGroupAssignments } = this.props
    let assignedUserIDs = []

    if (!users || users.data === undefined) {
      return []
    }

    if (userGroupAssignments.data !== undefined) {
      // get UserIDs
      userGroupAssignments.data.forEach(entry => {
        // we only need UserID
        assignedUserIDs.push(entry[userGroupAssignments.header.indexOf('BETAUSER')])
      })
    }

    // filter UserIDs
    const filteredUsers = users.data.filter(
      filteredUser => !assignedUserIDs.includes(filteredUser[users.header.indexOf('BETAUSER')])
    )
    // display list
    return filteredUsers.map(entry => {
      // return id & name
      return entry[users.header.indexOf('BETAUSER')]
    })
  }

  /**
   * @description Gets the userids to assign.
   */
  getUserIdsToAssign() {
    // Create id array for the new assigned data
    let newDataIds = this.state.assigned.data.map((newData) => {
      return newData[0]
    })

    // Check, wether the orig data is empty and return newIds
    if (this.props.userGroupAssignments?.data === undefined) {
      return newDataIds
    }

    // Create id array for the orig data
    let origDataIds = this.props.userGroupAssignments.data.map((origData) => {
      return origData[this.props.userGroupAssignments.header.indexOf('BETAUSER')]
    })

    // Only return the different ids between the new and old data
    return newDataIds.filter(id => !origDataIds.includes(id))
  }

  /**
   * @description Gets the user ids to unassign.
   */
  getUserIdsToUnassign() {
    // Create id array for the new assigned data
    let newDataIds = this.state.assigned.data.map((newData) => {
      return newData[0]
    })

    // In case there are no groups assinged before, do not delete assignments
    if (this.props.userGroupAssignments?.data === undefined) {
      return []
    }

    // Create id array for the orig data
    let origDataIds = this.props.userGroupAssignments?.data.map((origData) => {
      return origData[this.props.userGroupAssignments.header.indexOf('BETAUSER')]
    })

    // Only return the different ids between the new and old data
    return origDataIds.filter(id => !newDataIds.includes(id))
  }

  /**
   * @description Gets the searched user ids.
   */
  getSearchedUserIds = searchedUsers => {
    // Create id array for the searched users
    let searchedUserIDs = []

    if (searchedUsers && searchedUsers.data) {
      searchedUserIDs = searchedUsers.data.map(entry => {
        return entry[searchedUsers.header.indexOf('BETAUSER')]
      })
    }

    // Create id array for the current user data
    let currentData = this.state.assigned.data.map((entry) => {
      return entry[0]
    })

    // Only return the different ids between the new and old data
    return searchedUserIDs.filter(id => !currentData.includes(id)).map(item => {
      // Sloppy craftsmenship, because we expect an array
      return item
    })
  }


  /**
   * @description Gets the assigned table data.
   */
  getAssignedTableData = () => {
    const { userGroupAssignments } = this.props
    let tableData = []
    // return an empty array when there is no data
    if (userGroupAssignments?.data !== undefined) {
      // Get all original user ids
      userGroupAssignments.data.forEach(entry => {
        tableData.push([
          entry[userGroupAssignments.header.indexOf('BETAUSER')],
          entry[userGroupAssignments.header.indexOf('USERNAME')],
        ])
      })
    }
    return tableData
  }

  /**
   * @description Gets the unassigned table data.
   * @param {Array} unassignedUserIds The unassigned user ids.
   */
  getUnassignedTableData(unassignedUserIds) {
    const { users } = this.props

    let tableData = []
    if (users && users.data !== undefined) {
      // Map through our ids and get proper table data for all corresponding ids
      users.data.forEach(entry => {
        unassignedUserIds.forEach(id => {
          if (entry[users.header.indexOf('BETAUSER')] === id) {
            // Get id and group name
            tableData.push([
              entry[users.header.indexOf('BETAUSER')],
              entry[users.header.indexOf('USERNAME')],
            ])
          }
        })
      })
    }
    return tableData
  }

  /**
   * @description Reorders an item inside a list.
   * @param {Array} list The list.
   * @param {Number} startIndex The source index of the item to reorder.
   * @param {Number} endIndex The destination index.
   * @return {Array} The reordered list.
   */
  reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  /**
   * @description Moves an item from a list to another.
   * @param {Array} source The source list.
   * @param {Array} destination The destination list.
   * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
   * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
   * @returns {Object} An object with the new source and destination lists.
   */
  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = [...source]
    const destClone = [...destination]
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  /**
   * @description Sets the width and visibility properties for group dragging.
   * @param {Object} dragStart The dragStart object.
   */
  onBeforeDragStart = (dragStart) => {
    // get the id which is dragged
    let id = this.state[dragStart.source.droppableId].data[dragStart.source.index]
    const selected = this.state.selectedIds.find((headerId) => headerId === id)

    let newHeaderWidth = '0px'
    let visibility = 'hidden'

    if (selected) {
      // set the width of the grouping items based on the item which will be dragged
      if (document.querySelector(`#${dragStart.draggableId}`)) {
        newHeaderWidth = `${document.querySelector(`#${dragStart.draggableId}`).getBoundingClientRect().width}px`
        visibility = 'visible'
      }
      this.setState({ draggingId: id })
    } else {
      // if draggin an item that is not selected - unselect all items
      this.setState({ draggingId: id, selectedIds: [] })
    }

    document.querySelector(':root').style.setProperty('--row-width', newHeaderWidth)
    document.querySelector(':root').style.setProperty('--row-visibility', visibility)
  }

  /**
   * @description Performes the single drag action.
   * @param {Object} result Includes the new lists.
   */
  singleDrag = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      this.setState({ draggingId: null })
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorder(
        this.state[source.droppableId].data,
        source.index,
        destination.index
      )

      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: items
        },
        draggingId: null
      })
      // move if the dnd was between different lists
    } else {
      const result = this.move(
        this.state[source.droppableId].data,
        this.state[destination.droppableId].data,
        source,
        destination
      )

      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: result[source.droppableId]
        },
        [destination.droppableId]: {
          ...this.state[destination.droppableId],
          data: result[destination.droppableId]
        },
        draggingId: null
      })
    }
  }

  /**
   * @description Performes the multi drag action.
   * @param {Object} result Includes the new lists.
   */
  multiDrag = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      this.setState({ draggingId: null })
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorderMulti(
        this.state[source.droppableId].data,
        source.index,
        destination.index
      )

      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: items
        },
        draggingId: null
      })
      // move if the dnd was between different lists
    } else {
      const result = this.moveMulti(
        this.state[source.droppableId].data,
        this.state[destination.droppableId].data,
        source,
        destination
      )

      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: result[source.droppableId]
        },
        [destination.droppableId]: {
          ...this.state[destination.droppableId],
          data: result[destination.droppableId]
        },
        draggingId: null,
        selectedIds: result['orderedSelectedIds']
      })
    }
  }

  /**
   * @description Reorders multiple items.
   * @param {Array} list The list.
   * @param {Number} startIndex The source start index.
   * @param {Number} endIndex The destination index.
   */
  reorderMulti = (list, startIndex, endIndex) => {
    // do nothing when startindex and endindex are equal
    if (startIndex === endIndex) {
      return list
    }

    let result = [...list]

    const insertAtIndex = (() => {
      // gets the offset of the index where to insert the items
      const destinationIndexOffset = this.state.selectedIds.reduce((previous, current) => {
        const index = list.indexOf(current)

        if (current === this.state.draggingId) {
          if (index > endIndex && previous > 0) {
            return previous - 1
          } else {
            return previous
          }
        }

        if (index > endIndex) {
          return previous
        }

        return previous + 1
      }, 0,
      )

      return endIndex - destinationIndexOffset
    })()

    const orderedSelectedHeaders = [...this.state.selectedIds]
    orderedSelectedHeaders.sort((a, b) => {

      const indexOfA = list.indexOf(a)
      const indexOfB = list.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      // sorting by their order in the selected headers
      return -1
    })

    result = result.filter((id) => {
      return !this.state.selectedIds.includes(id)
    })

    result.splice(insertAtIndex, 0, ...orderedSelectedHeaders)

    return result
  }

  /**
   * @description Moves items from a list to another.
   * @param {Array} source The source list.
   * @param {Array} destination The destination list.
   * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
   * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
   * @returns {Object} An object with the new source and destination lists.
   */
  moveMulti = (source, destination, droppableSource, droppableDestination) => {
    let sourceClone = [...source]
    const destClone = [...destination]
    sourceClone = sourceClone.filter((id) => {
      return !this.state.selectedIds.includes(id)
    })

    const orderedSelectedHeaders = [...this.state.selectedIds]
    orderedSelectedHeaders.sort((a, b) => {
      const indexOfA = source.indexOf(a)
      const indexOfB = source.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      // sorting by their order in the selected headers
      return -1
    })

    destClone.splice(droppableDestination.index, 0, ...orderedSelectedHeaders)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    result['orderedSelectedIds'] = orderedSelectedHeaders

    return result
  }

  /**
   * @description The drag end action from dnd.
   * @param {Object} result The result from dnd.
   */
  onDragEnd = (result) => {
    // multi drag
    if (this.state.selectedIds.length > 1) {
      this.multiDrag(result)
    } else {
      // single drag
      this.singleDrag(result)
    }
  }

  /**
   * @description Toggles a selection of an item.
   * @param {String} id The id of the data to select or unselect.
   */
  toggleSelection = (id) => {
    let newSelectedIds = []
    if (!this.state.selectedIds.includes(id)) {
      newSelectedIds = [id]
    } else if (this.state.selectedIds.length > 1) {
      // was part of a seleted group -> will now become the only selected item
      newSelectedIds = [id]
    } else {
      // was previously selected but not in a group -> will now clear the selection
      newSelectedIds = []
    }

    this.setState({
      selectedIds: newSelectedIds
    })
  }

  /**
   * @description Toggles the selection in group (with ctrl).
   * @param {String} id The id of the data which was clicked.
   */
  toggleSelectionInGroup = (id) => {
    const index = this.state.selectedIds.indexOf(id)

    // if not selected -> add it to the selected items
    if (index === -1) {
      this.setState({ selectedIds: [...this.state.selectedIds, id] })
    } else {
      // item was previously selected -> remove it from the group
      const shallow = [...this.state.selectedIds]
      shallow.splice(index, 1)
      this.setState({ selectedIds: shallow })
    }
  }

  /**
   * @description Selects all elements between the last selected item and the item to select.
   * @param {Array} data The data.
   * @param {String} id The id of the data to select.
   */
  multiSelectTo = (data, id) => {
    const updated = this.multiSelect(data, id)

    if (updated === null) {
      return
    }

    this.setState({ selectedIds: updated })
  }

  /**
   * @description Multi selects items with shift.
   * @param {Array} data The data.
   * @param {String} id The id of the data which was clicked.
   * @returns {Array} The new selected headers or null.
   */
  multiSelect = (data, newId) => {
    if (!this.state.selectedIds.length) {
      return [newId]
    }

    const indexOfNew = data.indexOf(newId)
    const indexOfLast = data.indexOf(this.state.selectedIds[this.state.selectedIds.length - 1])

    if (indexOfNew === indexOfLast) {
      return null
    }

    const isSelectingForwards = indexOfNew > indexOfLast
    const start = isSelectingForwards ? indexOfLast : indexOfNew
    const end = isSelectingForwards ? indexOfNew : indexOfLast

    const inBetween = data.slice(start, end + 1)

    // add headers which are between the last selected item and the clicked item
    // if items between are already selected do nothing
    const toAdd = inBetween.filter((id) => {
      if (Utils.includesArray(this.state.selectedIds, id)) {
        return false
      }
      return true
    })

    const combined = [...this.state.selectedIds, ...toAdd]

    return combined
  }

  /**
   * @description Renders the user tab.
   */
  renderUsersTab() {
    const { id } = this.props
    const { searchUserId } = this.state

    const available = {
      title: translate('group.available_users'),
      data: this.state.unassigned.data,
      headers: [
        translate('user.user_id'),
        translate('user.username')
      ],
      columnSortDefs: ['string', 'string'],
      onSortEnd: (newData, sortedCol) => { this.setState({ unassigned: { sortedCol: sortedCol, data: newData } }) },
      sortedCol: this.state.unassigned.sortedCol,
      droppableID: 'unassigned'
    }

    const chosen = {
      title: translate('group.assignedUsers'),
      data: this.state.assigned.data,
      headers: [
        translate('user.user_id'),
        translate('user.username')
      ],
      columnSortDefs: ['string', 'string'],
      onSortEnd: (newData, sortedCol) => { this.setState({ assigned: { sortedCol: sortedCol, data: newData } }) },
      sortedCol: this.state.assigned.sortedCol,
      droppableID: 'assigned'
    }

    return (
      <>
        <Row>
          <Column
            colMD={6}>
            <Input
              id={`${id}_userSearch`}
              title={translate('group.searchUsers')}
              ref={this.searchUser}
              value={searchUserId.value}
              maxLength={8}
              onInputChanged={(val, err) => {
                this.handleChange('searchUserId', { value: val, errorkey: err })
              }}
              error={searchUserId.errorkey && translate(searchUserId.errorkey)}
              addon={{ iconName: 'search', isInner: false, onClick: this.handleSearch}}
            />
          </Column>
        </Row>

        <RelationAssignments
          id={`${id}_relation_assignments`}
          available={available}
          chosen={chosen}
          onBeforeDragStart={this.onBeforeDragStart}
          onDragEnd={this.onDragEnd}
          selectedIds={this.state.selectedIds}
          draggingId={this.state.draggingId}
          toggleSelection={this.toggleSelection}
          toggleSelectionInGroup={this.toggleSelectionInGroup}
          multiSelectTo={this.multiSelectTo}
          unselectAll={this.unselectAll}
          translate={key => translate(key)}
        />
      </>
    )
  }

  getDisabledTabs = () => {
    return this.props.assignmentsBlocked ? [1] : []
  }

  render = () => {
    const { id, lang, group, datemask, onClose, assignmentsBlocked } = this.props
    const { groupname } = this.state
    return (
      <Modal onClose={onClose}
        id={`${id}_modifyGroupDialog`}
        className={'bux_modifyGroupModal'}>
        <Header
          id={`${id}_modifyGroupDialog`}
          title={translate('usermanagement.modify_group', lang)}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('group.group_id', lang), value: group.BETAGRP },
              { label: translate('general.last_changed', lang), value: DateUtils.getDate(datemask, group.CDATE, group.CTIME.substring(0, 8)) + ' ' + translate('general.by', lang) + ' ' + group.CUSER },
            ]}
            columns={4}
          />
        </Header>
        <Main id={`${id}_modifyGroupDialog`}>
          <Tabs id={`${id}_tabs`} className={'bux_modal_datatable_container'} disabledTabs={this.getDisabledTabs()}>
            <Tab title={translate('general.general')}>
              <Row>
                <Column
                  colMD={12}>
                  <Input
                    id={`${id}_groupnameInput`}
                    ref={this.groupnameInput}
                    onInputChanged={(val, err) => { this.handleGroupnameChanged(val, err) }}
                    value={groupname.value}
                    title={translate('group.groupname', lang)}
                    error={groupname.errorkey && translate(groupname.errorkey, lang)}
                    maxLength={32}
                  />
                </Column>
              </Row>
            </Tab>
            <Tab title={translate('user.users')} tooltip={assignmentsBlocked && translate('tooltip.tab_disabled_because_of_authorization')}>
              {this.renderUsersTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer id={`${id}_modifyGroupDialog`}>
          <Button
            id={`${id}_cancelBtn`}
            text={translate('general.cancel', lang)}
            onClick={onClose}
          />
          <Button
            id={`${id}_saveBtn`}
            text={translate('general.save', lang)}
            onClick={this.handleOnSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

ModifyGroupDialog.propTypes = {
  id: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    group: state.group.group,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    users: state.usergroupassignment.users,
    userGroupAssignments: state.usergroupassignment.groupAssignments
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchUsers: (
      userid,
      callback
    ) => {
      UserGroupAssignmentActions.searchUsers(
        userid,
        callback
      )(dispatch)
    },
    updateGroup: (
      cuser,
      groupid,
      groupname,
      callback
    ) => {
      GroupActions.updateGroup(
        cuser,
        groupid,
        groupname,
        callback
      )(dispatch)
    },
    createAssignment: (
      groupid,
      userid,
      callback,
      callbackFlag
    ) => {
      UserGroupAssignmentActions.createUserGroupAssignment(
        groupid,
        userid,
        callback,
        callbackFlag
      )(dispatch)
    },
    deleteAssignment: (
      groupid,
      userid,
      callback,
      callbackFlag
    ) => {
      UserGroupAssignmentActions.deleteUserGroupAssignment(
        groupid,
        userid,
        callback,
        callbackFlag
      )(dispatch)
    },
    showMultiple: (snackbarMessages, customTitle) => SnackbarActions.showMultiple(snackbarMessages, customTitle)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyGroupDialog)