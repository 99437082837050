import React from 'react';
import Icon from '../icon/Icon';
import './Link.scss';

export interface LinkProps {
  /** Unique ID for identification in HTML DOM.*/
  id?: string;
  /** This ID will be used for integration tests to access the HTML element */
  dataTestId?: string;
  /** Text to be displayed in component.*/
  text?: string;
  /** Name of the icon to be displayed in component.*/
  iconName?: string;
  /** Function to be called on an click event.*/
  onClick?: (event: React.MouseEvent) => void;
  /** Text to be displayed as a tooltip when you hover component.*/
  tooltip?: string;
  /** Disables the component. Default: false. */
  disabled?: boolean;
  /** Style class from CSS for styling for component.*/
  className?: string;
  /** Style class from CSS for styling for the link (button container).*/
  linkClassName?: string;
  /** Style class from CSS for styling for the icon.*/
  iconClassName?: string;
  /** Style class from CSS for styling for the text.*/
  textClassName?: string;
  /**
   * Indicates order of elements in link
   * normal: icon + text
   * reverse: text + icon
   * Default: false (normal)
   **/
  reverse?: boolean;
  /** On key down */
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  /** On key down */
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /** Remove gap between icon and text */
  noGap?: boolean;
  tabIndex?: number;
}

/**
 * Link component created according to
 * _Link_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 *
 */
const Link = React.forwardRef(({
  id = '',
  dataTestId,
  onClick,
  text,
  iconName,
  reverse,
  tooltip,
  className = '',
  iconClassName = '',
  textClassName = '',
  linkClassName = '',
  disabled = false,
  noGap = false,
  onKeyDown,
  onMouseDown,
  tabIndex,
}: LinkProps, ref: React.ForwardedRef<HTMLButtonElement>): JSX.Element => {
  const linkClassNames = [
    iconName && 'link_with_icon',
    text && 'link_with_text',
    reverse && 'link_reverse',
    noGap && 'cl_no_icon_link_gap',
    linkClassName
  ].join(' ').trim().replace(/\s\s+/g, ' ');

  return (
    <div id={id} className={`link_container ${className}`} title={tooltip}>
      <button
        id={`${id}_link_button`}
        data-testid={dataTestId}
        ref={ref}
        onClick={onClick}
        type={'button'}
        onMouseLeave={e => e.currentTarget.blur()}
        title={tooltip}
        disabled={disabled}
        className={linkClassNames}
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        tabIndex={tabIndex ?? disabled ? -1 : 0}
      >
        {
          iconName && <Icon
            id={`${id}_link_icon`}
            name={iconName}
            title={tooltip}
            className={iconClassName}
          />
        }
        {
          text &&
          <span className={textClassName}>{text}</span>
        }
      </button>
    </div>
  );
});
Link.displayName = 'Link';
export default Link;