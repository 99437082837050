import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../icon/Icon'

import './ProgressTabs.scss'

/**
 * ProgressTabs component created according to
 * _WizardHeader_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
export class ProgressTabs extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Index of active tab */
    activeTab: PropTypes.number,
    /**
     * Function to be called on an tab change.
     * use together with with activeTab to change the tabs from parent (outside)
     * @param {number} index
     */
    onChange: PropTypes.func,
    /**
     * List of index of tab which should be disabled.
     * Use together with stayDisabled.
     */
    disabledTabs: PropTypes.arrayOf(PropTypes.number),
    /**
     * If true, tabs from _disabledTabs_ will stay disabled, otherwise tab will be
     * enabled if tab index > disabled tab index)
     */
    stayDisabled: PropTypes.bool,
    /**
     * _AdditionalLabels_ will render labels next to the title of the tabs, when tab are < active index
     * _AdditionalLabels_ are rendered in "()".
     */
    additionalLabels: PropTypes.arrayOf(PropTypes.string),
    /** Label to be displayed above the ProgressTab.*/
    title: PropTypes.string,
    /** Children of the component.*/
    children: PropTypes.node
  }

  state = {
    activeTab: this.props.activeTab || 0
  }

  /**
   * @description Handles the tab click.
   * @param {Number} index The index of the which was was clicked
   */
  handleTabClick = index => {
    const { disabledTabs, onChange } = this.props
    const activeTab = this.props.activeTab || this.state.activeTab
    if (onChange && (!disabledTabs || (disabledTabs && !this.proofDisabled(index)) || (index < activeTab))) {
      onChange(index)
    }

    if (!onChange) {
      this.setState({ activeTab: index })
    }
  }

  /**
   * @description Checks if the tab at the index is included in disabled tabs.
   * @param {Number} index The index of the tab to check.
   * @returns {Boolean}
   */
  proofDisabled = index => {
    const { disabledTabs } = this.props
    let result = false
    disabledTabs.forEach((_, tabIndex) => {
      if (disabledTabs[tabIndex] === index) {
        result = true
      }
    })
    return result
  }

  /**
   * @description Renders the tab labels.
   */
  renderLabels = () => {
    const { children, id, disabledTabs, additionalLabels, stayDisabled } = this.props
    const activeTab = this.props.activeTab || this.state.activeTab
    return children.map((tab, index) => {
      const { title } = tab.props
      return (
        <li
          id={`${id}_${index}`}
          key={index}
          tabIndex={disabledTabs?.includes(index) ? -1 : 0}
          className={
            `nav-item ${stayDisabled
              ? (disabledTabs && disabledTabs.includes(index) ? 'disabled' : '')
              : (disabledTabs && disabledTabs.includes(index) && index > activeTab ? 'disabled' : '')}
            ${activeTab === index ? 'active' : ''}`
          }
          onClick={() => this.handleTabClick(index)}
          onMouseOut={e => e.currentTarget.blur()}
          onKeyDown={(e) => { return e.key === 'Enter' && this.handleTabClick(index) && false }}>
          <span>{additionalLabels ? (index < activeTab && index !== activeTab ? title + ` (${additionalLabels[index]})` : title) : title}</span>
          {index < children.length - 1 && <Icon containerClassName={'progress_tab_next'} name={'chevron_right_small'} />}
        </li>
      )
    })
  }

  /**
   * @description Renders the tab content.
   */
  renderContent = () => {
    const { children } = this.props
    const activeTab = this.props.activeTab || this.state.activeTab
    return children.map((content, index) => index === activeTab && content)
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, title } = this.props
    return (
      <div className='bux_tile bux_progress_tab'>
        {title &&
          <label
            id={`${id}_progresstab_title`}
            className='el_info_label'>
            {title}
          </label>
        }
        <ul className='nav nav-tabs'>
          {this.renderLabels()}
        </ul>
        <div className={'bux_progress_tab_content_container'}>
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

export const ProgressTabContent = ({ id, children }) => {
  return (
    <div id={`${id}_progress_tab_content`} className={'bux_progress_tab_content'}>{children}</div>
  )
}