import PropTypes from 'prop-types'
import { Component } from 'react'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import * as SortUtils from 'utils/SortUtils'

// Components
import { DataTable, DownloadWrapper, EmptySearchResult, Link, NoSearch, ResultContainer } from 'BetaUX2Web-Components/src/'
import TableSettings from 'components/table_settings/TableSettings'

import * as PDI_CONSTANTS from 'components/drawer/content/server/server_body/server_printer_device_info/ServerPrinterDeviceInfo'
import { connect } from 'react-redux'
import * as PrinterDeviceInfoActions from 'redux/actions/ServerActions'
import * as Preferences from 'redux/general/Preferences'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultServerPrinterDeviceInfo extends Component {
  state = {
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: DATETIME, translation: 'general.date_time', default: true },
      { rest: OUTPUTCHANNELID, translation: 'definition.output_channel_id', default: true },
      { rest: MESSAGEID, translation: 'server.message_id', default: true },
      { rest: TEXT, translation: 'general.text', default: true },
      { rest: PDIDATE },
      { rest: PDITIME }
    ]
  }

  /**
   * @description gets the index of the header in redux state printerDeviceInfo.header
   * @param {String} header header name of the header in redux state printerDeviceInfo.header
   */
  headerData = header => this.props.printerDeviceInfo.header.indexOf(header)

  /**
   * @description Refreshs the current table.
   */
  handleRefresh = () => {
    const { preferences } = this.props

    const activeTabIndex = preferences[Preferences.SERVER_PDI_ACTIVE_TAB]
    const lastTimeMode = preferences[Preferences.SERVER_PDI_LASTTIME_MODE]
    let sdate = ''
    let stime = ''
    let edate = ''
    let etime = ''
    let customLast = ''
    let customUnit = ''

    if (activeTabIndex === 0) {
      if (lastTimeMode === PDI_CONSTANTS.LASTTIME_MODE_TODAY || lastTimeMode === PDI_CONSTANTS.LASTTIME_MODE_YESTERDAY) {
        sdate = preferences[Preferences.SERVER_PDI_LASTTIME_MODE].toUpperCase()
      } else if (lastTimeMode === PDI_CONSTANTS.LASTTIME_MODE_CUSTOM) {
        customLast = preferences[Preferences.SERVER_PDI_CUSTOM_LAST]
        customUnit = preferences[Preferences.SERVER_PDI_CUSTOM_UNIT]
      }
    } else if (activeTabIndex === 1) {
      sdate = DateUtils.getDateFromUnixTimestamp(preferences[Preferences.SERVER_PDI_FROMDATE], DateUtils.DDMMYYYY_DOT)
      stime = DateUtils.formatTimeToDefault(preferences[Preferences.SERVER_PDI_FROMTIME])
      edate = DateUtils.getDateFromUnixTimestamp(preferences[Preferences.SERVER_PDI_TODATE], DateUtils.DDMMYYYY_DOT)
      etime = DateUtils.formatTimeToDefault(preferences[Preferences.SERVER_PDI_TOTIME])
    }

    const searchParams = {
      FROMLAST: customLast,
      TUNITS: customUnit,
      SDATE: sdate,
      STIME: stime,
      EDATE: edate,
      ETIME: etime,
      DCR: preferences[Preferences.SERVER_PDI_OUTPUTCHANNELID],
      MSGNR: preferences[Preferences.SERVER_PDI_MESSAGEID],
      MSGTXT: preferences[Preferences.SERVER_PDI_TEXT]
    }

    this.props.getPrinterDeviceInfo(searchParams)
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData = () => {
    const { datemask, printerDeviceInfo } = this.props
    const data = []
    const headers = this.getUsedHeader()
    printerDeviceInfo.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === DATETIME) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('PDIDATE')], el[this.headerData('PDITIME')].substring(0, 8)))
        }
        else if (header === PDITIME) {
          buffer.push(DateUtils.getDate(' ', el[this.headerData('PDIDATE')], el[this.headerData('PDITIME')].substring(0, 8)))
        }
        else if (header === PDIDATE) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('PDIDATE')], el[this.headerData('PDITIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            buffer.push(`${val}:00`)
          }
          else {
            buffer.push(val)
          }
        }
      })
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_PRINTER_DEVICE_INFO]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_PRINTER_DEVICE_INFO].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_PRINTER_DEVICE_INFO]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_PRINTER_DEVICE_INFO].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  render = () => {
    const { id, printerDeviceInfo, drawerExpanded, autoDrawer, lang, datemask } = this.props
    const { showTableSettingsDialog } = this.state
    const data = printerDeviceInfo && printerDeviceInfo.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_PRINTER_DEVICE_INFO }}
          />
        )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {printerDeviceInfo
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                  />
                )
                : (
                  <EmptySearchResult
                    id={`${id}_emptysearchresult`}
                    description={translate('general.no_data_found')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const DATETIME = 'DATETIME'
const OUTPUTCHANNELID = 'DCR'
const MESSAGEID = 'MSGNR'
const TEXT = 'MSGTEXT'
const PDIDATE = 'PDIDATE'
const PDITIME = 'PDITIME'

SearchResultServerPrinterDeviceInfo.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    printerDeviceInfo: state.server.printerdeviceinfo,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPrinterDeviceInfo: (searchParam, callback) => {
      PrinterDeviceInfoActions.getPrinterDeviceInfo(searchParam, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultServerPrinterDeviceInfo)