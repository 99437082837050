import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

// action
export const CREATE_USER_START = 'CREATE_USER_START'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const GET_USER_FOR_PREFERENCES_START = 'GET_USER_FOR_PREFERENCES_START'
export const GET_USER_START = 'GET_USER_START'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILED = 'GET_USER_FAILED'

export const GET_USERS_START = 'GET_USERS_START'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'

export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const UPDATE_USER_START = 'UPDATE_USER_START'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'

export const CLONE_USER_START = 'CLONE_USER_START'
export const CLONE_USER_SUCCESS = 'CLONE_USER_SUCCESS'
export const CLONE_USER_FAILED = 'CLONE_USER_FAILED'

export const NO_USERS_FOUND = 'NO_USERS_FOUND'

/**
 * @description Calls the rest api and creates a new user.
 * @param {String} userid The userid of the new user.
 * @param {String} username The username of the new user.
 * @param {String} password The password of the new user.
 * @param {String} passwordInterval The password interval of the new user.
 * @param {boolean} revoked The revoked flag of the new user.
 * @param {boolean} administrator The administrator flag of the new user.
 * @param {boolean} passwordExpired The password expired flag of the new user.
 * @param {boolean} externalAuthenticate The external authenticate flag of the new user.
 * @param {Function} callback The callback which will be called when the create user request was successful. Can be null.
 */
export function createUser(userid, username, password, passwordInterval, revoked, administrator, passwordExpired, externalAuthenticate, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CREATE_USER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/user`, {
        method: 'post',
        body: {
          BETAUSER: userid,
          USERNAME: username,
          PASSWORD: password,
          PWDCINTV: passwordInterval,
          USRREVOK: revoked,
          ADMIN: administrator,
          FORCEPWD: passwordExpired,
          CKPWDEXT: externalAuthenticate
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CREATE_USER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: CREATE_USER_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.create_user_success', lang, userid), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }

          // call getUsers again to get the current result
          const searchUserID = prefs[Preferences.USERMANAGEMENT_USERS_USERID]
          const searchUsername = prefs[Preferences.USERMANAGEMENT_USERS_USERNAME]
          const searchAdminOnly = prefs[Preferences.USERMANAGEMENT_USERS_PRIVILEGES]
          const searchExternalAuthOnly = prefs[Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY] !== 'false'
            ? prefs[Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY]
            : ''
          const searchRevokedOnly = prefs[Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY] !== 'false'
            ? prefs[Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY]
            : ''
          // ! DO NOT HIDE LOADING SPINNER WHEN SUCCESS BECAUSE THE TEST NEEDS TO WAIT UNTIL THE REFRESH IS DONE
          // ! REFRESH WILL HIDE LOADING SPINNER WHEN FINISH
          getUsers(searchUserID, searchUsername, searchAdminOnly, searchExternalAuthOnly, searchRevokedOnly, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.create_user_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
        dispatch({ type: CREATE_USER_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets a user.
 * @param {String} userid The userid of the user to get.
 * @param {Function} callback The callback which will be called when the get user request was successful. Can be null.
 */
export function getUser(userid, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_USER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/user?BETAUSER=${encodeURIComponent(userid)}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_USER_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_USER_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback(jsondata.data)
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_user_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_USER_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets a user.
 * @param {String} userid The userid of the user to get.
 * @param {Function} callback The callback which will be called when the get user request was successful. Can be null.
 */
export function getUserForPreferences(userid, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_USER_FOR_PREFERENCES_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/user?BETAUSER=${encodeURIComponent(userid)}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata?.error?.rc
          let irc = jsondata?.error?.irc

          // Security profile blocks access
          if (rc.toString() === '0020' && irc.toString() === '3401') {
            LoadingSpinnerActions.hide()(dispatch)
            callback()
            return
          }

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_USER_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_USER_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_user_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_USER_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes a user.
 * @param {String} userid The userid of the user to delete.
 * @param {Function} callback The callback which will be called when the delete user request was successful. Can be null.
 */
export function deleteUser(userid, username, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: DELETE_USER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/user${createQueryParamsForFetch({ BETAUSER: userid })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DELETE_USER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DELETE_USER_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.delete_user_success', lang, [userid, username]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getUsers again to get the current result
          const searchUserID = prefs[Preferences.USERMANAGEMENT_USERS_USERID]
          const searchUsername = prefs[Preferences.USERMANAGEMENT_USERS_USERNAME]
          const searchAdminOnly = prefs[Preferences.USERMANAGEMENT_USERS_PRIVILEGES]
          const searchExternalAuthOnly = prefs[Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY] !== 'false'
            ? prefs[Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY]
            : ''
          const searchRevokedOnly = prefs[Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY] !== 'false'
            ? prefs[Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY]
            : ''
          getUsers(searchUserID, searchUsername, searchAdminOnly, searchExternalAuthOnly, searchRevokedOnly, undefined, true)(dispatch)
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.delete_user_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DELETE_USER_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a user.
 * @param {String} userid The userid of the user to update.
 * @param {String} username The new username.
 * @param {String} password The new password.
 * @param {String} passwordInterval The new password interval.
 * @param {boolean} revoked The new revoked flag.
 * @param {boolean} administrator The new administrator flag.
 * @param {boolean} passwordExpired The new password expired flag.
 * @param {boolean} externalAuthenticate The new external authenticate flag.
 * @param {Function} callback The callback which will be called when the update user request was successful. Can be null.
 */
export function updateUser(userid, username, password, passwordInterval, revoked, administrator, passwordExpired, externalAuthenticate, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: UPDATE_USER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/user`, {
        method: 'put',
        body: {
          BETAUSER: userid,
          USERNAME: username,
          PASSWORD: password,
          PWDCINTV: passwordInterval,
          USRREVOK: revoked,
          ADMIN: administrator,
          FORCEPWD: passwordExpired,
          CKPWDEXT: externalAuthenticate
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: UPDATE_USER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: UPDATE_USER_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.update_user_success', lang, userid), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
          LoadingSpinnerActions.hide()(dispatch)
          // call getUsers again to get the current result
          const searchUserID = prefs[Preferences.USERMANAGEMENT_USERS_USERID]
          const searchUsername = prefs[Preferences.USERMANAGEMENT_USERS_USERNAME]
          const searchAdminOnly = prefs[Preferences.USERMANAGEMENT_USERS_PRIVILEGES]
          const searchExternalAuthOnly = prefs[Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY] !== 'false'
            ? prefs[Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY]
            : ''
          const searchRevokedOnly = prefs[Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY] !== 'false'
            ? prefs[Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY]
            : ''
          getUsers(searchUserID, searchUsername, searchAdminOnly, searchExternalAuthOnly, searchRevokedOnly, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('usermanagement.update_user_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPDATE_USER_FAILED, payload: { error } })
      })
  }
}

/* This function is required, because if we use "updateUser" a search
   for users would be executed aftermath. This is misleading if no users
   were searched before and the "ChangeUserSettings" change the Users in the
   UserResultTable (Usermanagement -> Users). */

/**
 * @description This function is required, because if we use "updateUser" a search for users would be executed aftermath. This is misleading if no users were searched before and the "ChangeUserSettings" change the Users in the UserResultTable (Usermanagement -> Users).
 * @param {String} cuser The userid which updates the user.
 * @param {String} userid The userid of the user to update.
 * @param {String} username The new username.
 * @param {String} admin The value from the field ADMIN ['YES'|'NO'|'GRP'].
 * @param {Boolean} isLDAP Information if the updated user is authenticated via LDAP.
 * @param {Function} callback The callback which will be called when the update user preferences request was successful. Can be null.
 */
export function updateUserPreferences(cuser, userid, username, admin, isLDAP, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: UPDATE_USER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/user`, {
        method: 'put',
        body: {
          BETAUSER: userid,
          USERNAME: username,
          CUSER: cuser,
          ADMIN: admin,
          CKPWDEXT: isLDAP ? 'YES' : 'NO'
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: UPDATE_USER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: UPDATE_USER_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.update_user_success', lang, userid), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.update_user_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPDATE_USER_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and clones a user.
 * @param {String} userid The userid of the user to clone.
 * @param {String} newUserid The new userid.
 * @param {String} username The new username.
 * @param {String} password The new password.
 * @param {String} passwordInterval The new password interval.
 * @param {boolean} revoked The new revoked flag.
 * @param {boolean} administrator The new administrator flag.
 * @param {boolean} passwordExpired The new password expired flag.
 * @param {boolean} externalAuthenticate The new external authenticate flag.
 * @param {Function} callback The callback which will be called when the clone user request was successful. Can be null.
 */
export function cloneUser(userid, newUserid, username, password, passwordInterval, revoked, administrator, passwordExpired, externalAuthenticate, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CLONE_USER_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/user/clone`, {
        method: 'post',
        body: {
          BETAUSER: userid,
          NEWBETAUSER: newUserid,
          USERNAME: username,
          PASSWORD: password,
          PWDCINTV: passwordInterval,
          USRREVOK: revoked,
          ADMIN: administrator,
          FORCEPWD: passwordExpired,
          CKPWDEXT: externalAuthenticate
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CLONE_USER_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: CLONE_USER_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.clone_user_success', lang, [newUserid, userid]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
          LoadingSpinnerActions.hide()(dispatch)
          // call getUsers again to get the current result
          const searchUserID = prefs[Preferences.USERMANAGEMENT_USERS_USERID]
          const searchUsername = prefs[Preferences.USERMANAGEMENT_USERS_USERNAME]
          const searchAdminOnly = prefs[Preferences.USERMANAGEMENT_USERS_PRIVILEGES]
          const searchExternalAuthOnly = prefs[Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY] !== 'false'
            ? prefs[Preferences.USERMANAGEMENT_USERS_EXTERNAL_AUTH_ONLY]
            : ''
          const searchRevokedOnly = prefs[Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY] !== 'false'
            ? prefs[Preferences.USERMANAGEMENT_USERS_REVOKED_ONLY]
            : ''
          getUsers(searchUserID, searchUsername, searchAdminOnly, searchExternalAuthOnly, searchRevokedOnly, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('usermanagement.clone_user_error', lang, [userid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CLONE_USER_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the api and gets users.
 * @param {String} userid The userid to search.
 * @param {String} username The username to search.
 * @param {boolean} administrator The administrator flag to search.
 * @param {boolean} externAuth The external authenticate flag to search.
 * @param {boolean} userRevoked The user revoked flag to search.
 * @param {Function} callback The callback which will be called when the get users request was successful.
 * Can be null.
 */
export function getUsers(userid, username, administrator, externAuth, userRevoked, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_USERS_START })

    const startTime = Date.now()

    /* Build request-parameter for URL */
    const queryParams = []
    if (userid) { queryParams.push(`BETAUSER=${encodeURIComponent(userid)}`) }
    if (username) { queryParams.push(`USERNAME=${encodeURIComponent(username)}`) }
    if (administrator) { queryParams.push(`ADMIN=${encodeURIComponent(administrator)}`) }
    if (externAuth) { queryParams.push(`CKPWDEXT=${encodeURIComponent(externAuth)}`) }
    if (userRevoked) { queryParams.push(`USRREVOK=${encodeURIComponent(userRevoked)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/users?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          // handle no data found here to dispatch NO_USERS_FOUND action
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_USERS_FOUND })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_USERS_FAILED })
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_USERS_SUCCESS, payload: jsondata.data, keepPagination })

          // call callback on success
          if (callback) {
            callback()
          }

          // wait a bit to show the user the loading animation
          const usedTime = Date.now() - startTime
          if (usedTime < 300) {
            setTimeout(() => {
              LoadingSpinnerActions.hide()(dispatch)
            }, 300 - usedTime)
          }
          else {
            LoadingSpinnerActions.hide()(dispatch)
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_users_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_USERS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}