import { translate } from 'language/Language'
import { Component } from 'react'

import {
  Button, Card, Column, MetaDataGrid, Modal as ModalComponent, Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as QueueUtils from 'utils/QueueUtils'

import { getDate } from 'utils/DateUtils'

class IndexQueueRequestInformation extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  renderHeader = () => {
    const { id, indexDetails, datemask } = this.props
    const found = indexDetails && QueueUtils.getStatus().find(d => d.key === indexDetails.CIQSTAT)
    const status = indexDetails ? (found ? translate(found.translationKey) : indexDetails.CIQSTAT) : ''
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('queue.queuedby'), value: indexDetails.CIQUSER },
          { label: translate('queue.status'), value: status },
          { label: translate('queue.priority'), value: indexDetails.CIQPRIO },
          { label: translate('queue.requestqueued'), value: getDate(datemask, indexDetails.CIQDATE, indexDetails.CIQTIME.substring(0, 8)) },
          { label: translate('database.return_code'), value: indexDetails.CIQRC },
          { label: translate('general.info_code'), value: indexDetails.CIQINFO },
        ]}
        columns={4}
      />
    )
  }

  renderMain = () => {
    const { id, indexDetails, datemask } = this.props
    return (
      <Card>
        <Row>
          <Column colMD={3}>
            <label id={`${id}_document_info`} className={'cl_label'}>{translate('documentinformation.modaltitle')}</label>
          </Column>
        </Row>
        <Row>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('general.form'), value: indexDetails.FORM },
              { label: translate('general.extension'), value: indexDetails.EXT },
              { label: translate('general.report'), value: indexDetails.WREPORT },
              { label: translate('general.jobname'), value: indexDetails.SRCJOBN },
              { label: translate('definition.document_filter'), value: indexDetails.FILTER },
              { label: translate('general.pages'), value: indexDetails.SRCPAGES },
              { label: translate('general.type'), value: indexDetails.SRCJOBI },
              { label: translate('queue.documentimported'), value: getDate(datemask, indexDetails.B93DATE, indexDetails.B93TIME.substring(0, 8)) },
              { label: translate('general.title'), value: indexDetails.LTITLE },
              indexDetails?.CIQSTAT === 'ERROR' && { label: translate('queue.error_message'), value: indexDetails?.MSG ?? '' },
            ]}
            columns={4}
          />
        </Row>
      </Card>
    )
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={id}
          title={translate('queue.index_information')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main>
          {this.renderMain()}
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    indexDetails: state.queue.index.indexDetails
  }
}

export default connect(mapStateToProps)(IndexQueueRequestInformation)