export const UNIT_DAY = 'd'
export const UNIT_MONTH = 'm'
export const UNIT_YEAR = 'y'

export const UNITS = [
  { key: UNIT_DAY, translationKey: 'statistic.unit_day' },
  { key: UNIT_MONTH, translationKey: 'statistic.unit_month' },
  { key: UNIT_YEAR, translationKey: 'statistic.unit_year' }
]

export const CHART_LINECHART = 'linechart'
export const CHART_BARCHART_VERT = 'barchart_vert'
export const CHART_BARCHART_HOR = 'barchart_hor'
export const CHART_HIDE = 'hide'

export const CHARTS = [
  { key: CHART_LINECHART, translationKey: 'statistic.chart_linechart' },
  { key: CHART_BARCHART_VERT, translationKey: 'statistic.chart_barchart_vert' },
  { key: CHART_BARCHART_HOR, translationKey: 'statistic.chart_barchart_hor' }
]

export const LOGX_CHARTS = [
  { key: CHART_LINECHART, translationKey: 'statistic.chart_linechart' },
  { key: CHART_BARCHART_VERT, translationKey: 'statistic.chart_barchart_vert' },
  { key: CHART_BARCHART_HOR, translationKey: 'statistic.chart_barchart_hor' },
  { key: CHART_HIDE, translationKey: 'general.hide'}
]