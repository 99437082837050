import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'

import SearchResultUsers from './users/SearchResultUsers'
import SearchResultGroups from './groups/SearchResultGroups'
import SearchResultRemoteUsers from './remoteusers/SearchResultRemoteUsers'

class SearchResultUserManagement extends Component {

  getBody() {
    const { match, id, drawerExpanded, autoDrawer } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    // switch (definition) {
    switch (url) {
      // users search result
      case 'users':
        return (
          <SearchResultUsers
            id={`${id}_users`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer} />
        )
      // groups search result
      case 'groups':
        return (
          <SearchResultGroups
            id={`${id}_groups`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer} />
        )
      // remote users search result
      case 'remoteusers':
        return (
          <SearchResultRemoteUsers
            id={`${id}_remoteUsers`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer} />
        )
      // fallback is users
      default:
        return (
          // take care of the route because if you enter a wrong route you can create an infinite loop!
          <Redirect to='/usermanagement/groups' />
        )
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

SearchResultUserManagement.propTypes = {
  id: PropTypes.string.isRequired,
  match: PropTypes.any.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

export default SearchResultUserManagement