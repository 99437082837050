import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Style
import './UserPreferencesDialog.scss'

// Locals
import { ENGLISH, FRENCH, GERMAN, translate } from 'language/Language'

// Components
import {
  Button, Card, Column, Dropdown, Input,
  MetaDataGrid,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import * as PreferencesActions from 'redux/actions/PreferencesActions'
import * as UserActions from 'redux/actions/UserActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'

class UserPreferencesDialog extends Component {

  state = {
    username: {
      value: this.props.user.USERNAME,
      errorkey: ''
    },
    languages: [
      translate('language.english'),
      translate('language.german'),
      // translate('language.french'), // Not available until the translation for French.js is given
    ],
    activeLanguageIndex: 0,
    datemasks: DateUtils.DATEMASKS,
    activeDatemaskIndex: 0
  }

  constructor(props) {
    super(props)
    this.usernameInputRef = React.createRef()
  }

  /**
   * @description Initializes the dropdowns.
   */
  componentDidMount = () => {
    this.usernameInputRef.current.focus()

    this.setState({
      activeDatemaskIndex: this.getInitDatemaskIndex(),
      activeLanguageIndex: this.getInitLanguageIndex()
    })
  }

  /**
   * @description Gets the initial position of the datemask dropdown.
   */
  getInitDatemaskIndex = () => {
    // get the position of the current user selection
    let index = this.state.datemasks.indexOf(this.props.preferences[Preferences.DATEMASK])

    // default is position 0
    if (index < 0) {
      index = 0
    }
    return index
  }

  /**
   * @description Gets the initial position of the language dropdown.
   */
  getInitLanguageIndex = () => {
    const lang = this.props.preferences[Preferences.LANGUAGE]
    switch (lang) {
      case ENGLISH: return 0
      case GERMAN: return 1
      case FRENCH: return 2
      default: return 0
    }
  }

  /**
   * @description Closes the dialog.
   */
  handleOnClose = () => {
    this.props.onClose()
  }

  /**
   * @description Saves the user preferences.
   */
  handleOnSave = () => {
    // only modify username if it's different
    if (this.state.username.value !== this.props.user.USERNAME) {
      const cuser = this.props.userid
      const userid = this.props.user.BETAUSER // is equivalent to cuser because you modify yourself
      const admin = this.props.user.ADMIN
      const username = this.state.username.value
      const isLDAP = this.props.user?.CKPWDEXT
      // update user
      this.props.updateUserPreferences(cuser, userid, username, admin, isLDAP)
    }

    // build preferences to update
    const prefs = {
      [Preferences.DATEMASK]: this.state.datemasks[this.state.activeDatemaskIndex],
      [Preferences.LANGUAGE]: this.getSaveLanguage(this.state.activeLanguageIndex)
    }

    // change the prefs
    this.props.changePrefs(prefs)

    // close the dialog
    this.props.onClose()
  }

  /**
   * @description Changes the username.
   * @param value The new value.
   * @param error The new error key.
   */
  handleUsernameChanged = (value, error) => {
    this.setState({
      username: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Changes the selected index of the datemask.
   * @param activeIndex The new index position.
   */
  handleDatemaskChanged = activeIndex => {
    this.setState({
      activeDatemaskIndex: activeIndex
    })
  }

  /**
   * @description Changes the selected index of the language.
   * @param activeIndex The new index position.
   */
  handleLanguageChanged = activeIndex => {
    this.setState({
      activeLanguageIndex: activeIndex
    })
  }

  /**
   * @description Gets the language to be saved. Uses the language index from state.
   */
  getSaveLanguage = () => {
    switch (this.state.activeLanguageIndex) {
      case 0: return ENGLISH
      case 1: return GERMAN
      case 2: return FRENCH
      default: return ENGLISH
    }
  }

  /**
   * @description Renders the component
   */
  render = () => {
    const { id, userid, datemask, serverdata } = this.props
    return (
      <Modal onClose={this.handleOnClose} id={`${id}`} className={'bux_userPreferences'}>
        <Header
          id={`${id}`}
          title={translate('userpreferences.user_preferences')}
          onClose={this.handleOnClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('user.user_id'), value: userid },
              { label: translate('general.last_login'), value: serverdata?.lastlogindate && serverdata.lastlogintime ? DateUtils.getDate(datemask, serverdata.lastlogindate, serverdata.lastlogintime.substring(0, 8)) : '' },
              { label: translate('general.last_password_change'), value: serverdata?.lastpwdchangedate && serverdata.lastpwdchangetime ? DateUtils.getDate(datemask, serverdata.lastpwdchangedate, serverdata.lastpwdchangetime.substring(0, 8)) : '' },
            ]}
            columns={4}
          />
        </Header>
        <Main id={'id'}>
          <Card id={'userpreferences_body'}>
            <Row id={'userpreferences_body_username_row'}>
              <Column
                id={'userpreferences_body_username_row_col'}
                colMD={12}>
                <Input
                  id={'userpreferences_body_username'}
                  ref={this.usernameInputRef}
                  onInputChanged={this.handleUsernameChanged}
                  value={this.state.username.value}
                  title={translate('user.username')}
                  error={this.state.username.errorkey ? translate(this.state.username.errorkey) : null}
                  maxLength={32}
                />
              </Column>
            </Row>
            <Row id={'userpreferences_body_langdate_row'}>
              <Column
                id={'userpreferences_body_lang_col'}
                colMD={6}>
                <Dropdown
                  id={'userpreferences_body_lang'}
                  items={this.state.languages}
                  onChange={this.handleLanguageChanged}
                  activeIndex={this.state.activeLanguageIndex}
                  title={translate('general.language')}
                />
              </Column>
              <Column
                id={'userpreferences_body_date_col'}
                colMD={6}>
                <Dropdown
                  id={'userpreferences_body_date'}
                  items={this.state.datemasks}
                  onChange={this.handleDatemaskChanged}
                  activeIndex={this.state.activeDatemaskIndex}
                  title={translate('general.datemask')}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={'id'}>
          <Button
            id={`${id}_cancelBtn`}
            text={translate('general.cancel')}
            onClick={this.handleOnClose}
          />
          <Button
            id={`${id}_saveBtn`}
            text={translate('general.save')}
            onClick={this.handleOnSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

UserPreferencesDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    user: state.user.user,
    preferences: state.auth.serverdata.preferences,
    serverdata: state.auth.serverdata
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => { PreferencesActions.changePrefs(prefs, true)(dispatch) },
    updateUserPreferences: (cuser, userid, username, admin, isLDAP) => UserActions.updateUserPreferences(cuser, userid, username, admin, isLDAP)(dispatch),
    getUser: userid => UserActions.getUser(userid)(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferencesDialog)