import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const CREATE_USER_GROUP_ASSIGNMENT_START = 'CREATE_USER_GROUP_ASSIGNMENT_START'
export const CREATE_USER_GROUP_ASSIGNMENT_SUCCESS = 'CREATE_USER_GROUP_ASSIGNMENT_SUCCESS'
export const CREATE_USER_GROUP_ASSIGNMENT_FAILED = 'CREATE_USER_GROUP_ASSIGNMENT_FAILED'

export const GET_USER_ASSIGNMENTS_START = 'GET_USER_ASSIGNMENTS_START'
export const GET_USER_ASSIGNMENTS_SUCCESS = 'GET_USER_ASSIGNMENTS_SUCCESS'
export const GET_USER_ASSIGNMENTS_FAILED = 'GET_USER_ASSIGNMENTS_FAILED'

export const GET_GROUP_ASSIGNMENTS_START = 'GET_GROUP_ASSIGNMENTS_START'
export const GET_GROUP_ASSIGNMENTS_SUCCESS = 'GET_GROUP_ASSIGNMENTS_SUCCESS'
export const GET_GROUP_ASSIGNMENTS_FAILED = 'GET_GROUP_ASSIGNMENTS_FAILED'

export const DELETE_USER_GROUP_ASSIGNMENT_START = 'DELETE_USER_GROUP_ASSIGNMENT_START'
export const DELETE_USER_GROUP_ASSIGNMENT_SUCCESS = 'DELETE_USER_GROUP_ASSIGNMENT_SUCCESS'
export const DELETE_USER_GROUP_ASSIGNMENT_FAILED = 'DELETE_USER_GROUP_ASSIGNMENT_FAILED'

export const GET_USER_GROUPS_START = 'GET_USER_GROUPS_START'
export const GET_USER_GROUPS_SUCCESS = 'GET_USER_GROUPS_SUCCESS'
export const GET_USER_GROUPS_FAILED = 'GET_USER_GROUPS_FAILED'
export const SEARCH_USER_GROUPS_START = 'SEARCH_USER_GROUPS_START'
export const SEARCH_USER_GROUPS_SUCCESS = 'SEARCH_USER_GROUPS_SUCCESS'
export const SEARCH_USER_GROUPS_FAILED = 'SEARCH_USER_GROUPS_FAILED'
export const GET_GROUP_USERS_START = 'GET_GROUP_USERS_START'
export const GET_GROUP_USERS_SUCCESS = 'GET_GROUP_USERS_SUCCESS'
export const GET_GROUP_USERS_FAILED = 'GET_GROUP_USERS_FAILED'
export const SEARCH_GROUP_USERS_START = 'SEARCH_GROUP_USERS_START'
export const SEARCH_GROUP_USERS_SUCCESS = 'SEARCH_GROUP_USERS_SUCCESS'
export const SEARCH_GROUP_USERS_FAILED = 'SEARCH_GROUP_USERS_FAILED'

export const NO_SEARCH_USER_GROUPS_FOUND = 'NO_SEARCH_USER_GROUPS_FOUND'
export const NO_SEARCH_GROUP_USERS_FOUND = 'NO_SEARCH_GROUP_USERS_FOUND'
export const NO_USER_GROUPS_FOUND = 'NO_USER_GROUPS_FOUND'
export const NO_GROUP_USERS_FOUND = 'NO_GROUP_USERS_FOUND'
export const NO_USER_ASSIGNMENTS_FOUND = 'NO_USER_ASSIGNMENTS_FOUND'
export const NO_GROUP_ASSIGNMENTS_FOUND = 'NO_GROUP_ASSIGNMENTS_FOUND'

/**
 * @description Calls the rest api and creates a user group assignment.
 * @param {String} groupid The groupid for the user group assignment.
 * @param {String} userid The userid for the user group assignment.
 * @param {Function} callback The callback which will be called when the create user group assignment request was successful. Can be null.
 */
export function createUserGroupAssignment(groupid, userid, callback, callbackFlag = false) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CREATE_USER_GROUP_ASSIGNMENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/usergroupassignment`, {
        method: 'post',
        body: {
          BETAGRP: groupid,
          BETAUSER: userid,
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          dispatch({ type: CREATE_USER_GROUP_ASSIGNMENT_FAILED, payload: { error } })
          if (callbackFlag && callback) {
            callback(error)
          }
        }
        else {
          dispatch({ type: CREATE_USER_GROUP_ASSIGNMENT_SUCCESS })

          // call callback on success
          if (callback) {
            callback(jsondata)
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.create_user_group_assignment_error', lang, [userid, groupid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CREATE_USER_GROUP_ASSIGNMENT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets user assignments.
 * @param {String} userid The userid of the user.
 * @param {Function} callback The callback which will be called when the get user assignments request was successful. Will also be called when no data was found. Can be null.
 */
export function getUserAssignments(userid, callback, errorCallback) {
  /* Escape URL-parameters */
  userid = encodeURIComponent(userid)

  return dispatch => {
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_USER_ASSIGNMENTS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/usergroupassignments?BETAUSER=${userid}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          // handle no data found here to dispatch NO_USER_ASSIGNMENTS_FOUND action
          if (rc === '0016' && irc === '0000') {
            // don't show snackbar because there are multiple requests on this process and we only want to show an empty list inside the dialog
            // SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)

            dispatch({ type: NO_USER_ASSIGNMENTS_FOUND })

            // also call callback when no data was found
            if (callback) {
              callback()
            }
          }
          // if security profiles blocks this actions
          else if (rc === '0020' && irc === '3401') {
            errorCallback && errorCallback()
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_USER_ASSIGNMENTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: GET_USER_ASSIGNMENTS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_user_assignments_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_USER_ASSIGNMENTS_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets group assignments.
 * @param {String} groupid The groupid of the group.
 * @param {Function} callback The callback which will be called when the get group assignments request was successful. Will also called when no data was found. Can be null.
 */
export function getGroupAssignments(groupid, callback, errorCallback) {
  /* Escape URL-parameters */
  groupid = encodeURIComponent(groupid)

  return dispatch => {
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_GROUP_ASSIGNMENTS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/usergroupassignments?BETAGRP=${groupid}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          // handle no data found here to dispatch NO_GROUP_ASSIGNMENTS_FOUND action
          if (rc === '0016' && irc === '0000') {
            // don't show snackbar because there are multiple requests on this process and we only want to show an empty list inside the dialog
            // SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_GROUP_ASSIGNMENTS_FOUND })

            // also call callback when no data was found
            if (callback) {
              callback()
            }
          }
          // if security profiles blocks this actions
          else if (rc === '0020' && irc === '3401') {
            errorCallback && errorCallback()
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_GROUP_ASSIGNMENTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: GET_GROUP_ASSIGNMENTS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_group_assignments_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_GROUP_ASSIGNMENTS_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and deletes a user group assignment.
 * @param {String} groupid The groupid for deleting the user group assignment.
 * @param {String} userid The userid for deleteing the user group assignment.
 * @param {Function} callback The callback which will be called when the dele user group assignment request was successful. Can be null.
 */
export function deleteUserGroupAssignment(groupid, userid, callback, callbackFlag = false) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: DELETE_USER_GROUP_ASSIGNMENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/usergroupassignment${createQueryParamsForFetch({ BETAGRP: groupid, BETAUSER: userid })}`, { method: 'delete' })
    )
    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          dispatch({ type: DELETE_USER_GROUP_ASSIGNMENT_FAILED, payload: { error } })
          if (callbackFlag && callback) {
            callback(error)
          }
        }
        else {
          dispatch({ type: DELETE_USER_GROUP_ASSIGNMENT_SUCCESS })

          // call callback on success
          if (callback) {
            callback(jsondata)
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.delete_user_group_assignment_error', lang, [userid, groupid, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DELETE_USER_GROUP_ASSIGNMENT_FAILED, payload: { error } })
      })
  }
}

export function removeAllUsers() {
  return dispatch => dispatch({ type: NO_GROUP_USERS_FOUND })
}

/**
 * @description Calls the rest api and gets users.
 * @param {String} userid The userid to search.
 * @param {String} username The username to search.
 * @param {boolean} administrator The administrator flag to search.
 * @param {boolean} externAuth The external authenticate flag to search.
 * @param {boolean} userRevoked The user revoke flag to search.
 * @param {Function} callback The callback which will be called when the get users request was successful. Can be null.
 */
export function getUsers(userid, username, administrator, externAuth, userRevoked, callback) {
  return dispatch => {
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_GROUP_USERS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (userid) { queryParams.push(`BETAUSER=${encodeURIComponent(userid)}`) }
    if (username) { queryParams.push(`USERNAME=${encodeURIComponent(username)}`) }

    if (administrator) { queryParams.push(`ADMIN=${encodeURIComponent(administrator)}`) }
    if (externAuth) { queryParams.push(`CKPWDEXT=${encodeURIComponent(externAuth)}`) }
    if (userRevoked) { queryParams.push(`USRREVOK=${encodeURIComponent(userRevoked)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/users?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          // handle no data found here to dispatch NO_GROUP_USERS_FOUND action
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_GROUP_USERS_FOUND })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_GROUP_USERS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: GET_GROUP_USERS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_users_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_GROUP_USERS_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and searches users.
 * @param {String} userid The userid to search.
 * @param {Function} callback The callback which will be called when the search users request was successful. Can ben null.
 */
export function searchUsers(userid, callback) {
  /* Escape URL-parameters */
  if (userid) { userid = escape(userid) }

  return dispatch => {
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: SEARCH_GROUP_USERS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (userid) { queryParams.push(`BETAUSER=${userid}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/users?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          // handle no data found here to dispatch NO_SEARCH_GROUP_USERS_FOUND action
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_SEARCH_GROUP_USERS_FOUND })

            if (callback) {
              callback()
            }
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SEARCH_GROUP_USERS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SEARCH_GROUP_USERS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_users_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCH_GROUP_USERS_FAILED, payload: { error } })
      })
  }
}

export function removeAllGroups() {
  return dispatch => dispatch({ type: NO_USER_GROUPS_FOUND })
}

/**
 * @description Calls the rest api and gets groups.
 * @param {String} groupid The groupid to search.
 * @param {String} groupname The groupname to search.
 * @param {Function} callback The callback which will be called when the get groups request was successful. Can be null.
 */
export function getGroups(groupid, groupname, callback) {
  /* Escape URL-parameters */
  groupid = encodeURIComponent(groupid)
  groupname = encodeURIComponent(groupname)
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: GET_USER_GROUPS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/groups?BETAGRP=${groupid}&GRPNAME=${groupname}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // handle no data found here to dispatch NO_USER_GROUPS_FOUND action
          if (rc === '0016' && irc === '0000') {
            dispatch({ type: NO_USER_GROUPS_FOUND })

            if (callback) {
              callback()
            }
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_USER_GROUPS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: GET_USER_GROUPS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_groups_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_USER_GROUPS_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and searches groups.
 * @param {String} groupid The groupid to search.
 * @param {Function} callback The callback which will be called when the search groups request was successful. Can be null.
 */
export function searchGroups(groupid, callback) {
  /* Escape URL-parameters */
  groupid = encodeURIComponent(groupid)
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: SEARCH_USER_GROUPS_START })

    const queryParams = []
    if (groupid) { queryParams.push(`BETAGRP=${groupid}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/groups?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // handle no data found here to dispatch NO_SEARCH_USER_GROUPS_FOUND action
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_SEARCH_USER_GROUPS_FOUND })

            if (callback) {
              callback()
            }
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SEARCH_USER_GROUPS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SEARCH_USER_GROUPS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_groups_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCH_USER_GROUPS_FAILED, payload: { error } })
      })
  }
}