import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import Switch from 'BetaUX2Web-Components/src/components/switch/Switch'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'

import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as CustomDialogDefinitionActions from 'redux/actions/CustomDialogDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import { connect } from 'react-redux'
import { getAvailableJobTypes } from 'utils/CustomDialogSystemUtils'
import { translate } from 'language/Language'

import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as UserUtils from 'utils/UserUtils'

class DefinitionCustomDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  }

  defaultState = {
    dialogID: '',
    description: '',
    owner: '',
    resultTableID: '',
    commandIndex: 0,
    jobtypeIndex: 0,
    showCustomDialogDialog: false,
    showResultTableDialog: false
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount() {
    // initialize search fields
    const { prefs } = this.props
    const commandIndex = prefs[Preferences.DEFINITION_CUSTOM_DIALOG_COMMAND]
      ? UserUtils.isDOCX()
        ? Math.max(DefinitionUtils.CUSTOM_DIALOG_COMMANDS_DOCX.findIndex(d => d.key === prefs[Preferences.DEFINITION_CUSTOM_DIALOG_COMMAND]), 0)
        : Math.max(DefinitionUtils.CUSTOM_DIALOG_COMMANDS_LOGX.findIndex(d => d.key === prefs[Preferences.DEFINITION_CUSTOM_DIALOG_COMMAND]), 0)
      : 0
    const jobtypeIndex = prefs[Preferences.DEFINITION_CUSTOM_DIALOG_JOBTYPE]
      ? Math.max(['', ...getAvailableJobTypes()].findIndex(d => d.key === prefs[Preferences.DEFINITION_CUSTOM_DIALOG_JOBTYPE]), 0)
      : 0
    if (prefs) {
      this.setState({
        dialogID: prefs[Preferences.DEFINITION_CUSTOM_DIALOG_ID] || '',
        description: prefs[Preferences.DEFINITION_CUSTOM_DIALOG_DESCRIPTION] || '',
        owner: prefs[Preferences.DEFINITION_CUSTOM_DIALOG_OWNER] || '',
        resultTableID: prefs[Preferences.DEFINITION_CUSTOM_DIALOG_RESULT_TABLE_ID] || '',
        commandIndex,
        jobtypeIndex
      })
    }
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => this.setState(this.defaultState)

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param val New value of inputfield
   */
  handleInputChanged = (key, val) => this.setState({ [key]: val })

  /**
   * @description Handles the result table selector button
   */
  handleResultTableSelector = () => {
    this.props.getResultTableDefinitions(
      ['SLTINAME', 'SLTENAME'],
      this.state.resultTableID,
      () => this.setState({ showResultTableDialog: true })
    )
  }

  /**
   * @description Checks wether the jobs switch is active or not
   */
  isJobtypeActive = () => UserUtils.isLOGX() && DefinitionUtils.CUSTOM_DIALOG_COMMANDS_LOGX[this.state.commandIndex]?.key === DefinitionUtils.CUSTOM_DIALOG_COMMAND_LOGX_JOBS

  /**
   * @description Handles the search event. Saves the search values in preferences
   * and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = (event) => {
    event.preventDefault()
    const { dialogID, description, owner, resultTableID, commandIndex, jobtypeIndex } = this.state
    const command = UserUtils.isDOCX() ? DefinitionUtils.CUSTOM_DIALOG_COMMANDS_DOCX[commandIndex].key : DefinitionUtils.CUSTOM_DIALOG_COMMANDS_LOGX[commandIndex].key
    const jobtype = this.isJobtypeActive() ? [{key: ''}, ...getAvailableJobTypes()][jobtypeIndex].key : ''
    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_CUSTOM_DIALOG_ID]: dialogID,
      [Preferences.DEFINITION_CUSTOM_DIALOG_DESCRIPTION]: description,
      [Preferences.DEFINITION_CUSTOM_DIALOG_OWNER]: owner,
      [Preferences.DEFINITION_CUSTOM_DIALOG_RESULT_TABLE_ID]: resultTableID,
      [Preferences.DEFINITION_CUSTOM_DIALOG_COMMAND]: command,
      [Preferences.DEFINITION_CUSTOM_DIALOG_JOBTYPE]: jobtype
    }

    this.props.changePrefs(prefsToChange)
    this.props.getCustomDialogs(dialogID, description, owner, resultTableID, command, jobtype)
  }

  getTranslatedCommands = () => DefinitionUtils.CUSTOM_DIALOG_COMMANDS_DOCX.map(d => translate(d.translationKey))

  /**
   * @description Renders the command section based on system in which the user is logged in
   */
  renderCommand = () => {
    const { id } = this.props
    const { commandIndex, jobtypeIndex } = this.state
    const items = [{translation: 'general.any'}, ...getAvailableJobTypes()].map(({translation}) => translate(translation))
    if (UserUtils.isDOCX()) {
      return (
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_customdialog_command`}
              title={translate('definition.command')}
              maxPerRow={2}
              activeIndex={commandIndex}
              items={DefinitionUtils.CUSTOM_DIALOG_COMMANDS_DOCX.map(d => translate(d.translationKey))}
              onClick={index => this.handleInputChanged('commandIndex', index)}
            />
          </Column>
        </Row>
      )
    }
    return (
      <>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_customdialog_command`}
              title={translate('definition.command')}
              maxPerRow={2}
              activeIndex={commandIndex}
              items={DefinitionUtils.CUSTOM_DIALOG_COMMANDS_LOGX.map(d => translate(d.translationKey))}
              onClick={index => this.handleInputChanged('commandIndex', index)}
            />
          </Column>
        </Row>
        {this.isJobtypeActive() &&
          <Row>
            <Column colMD={12}>
              <Dropdown
                id={`${id}_customdialog_jobtype`}
                title={translate('job.jobtype')}
                activeIndex={jobtypeIndex}
                items={items}
                onChange={index => this.handleInputChanged('jobtypeIndex', index)}
              />
            </Column>
          </Row>}
      </>
    )
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { dialogID, description, owner, resultTableID } = this.state
    return (
      <Card title={translate('general.general')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_customdialog_dialogid`}
              onInputChanged={val => this.handleInputChanged('dialogID', val)}
              value={dialogID}
              title={translate('definition.custom_dialog_id')}
              maxLength={16}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_customdialog_description`}
              onInputChanged={val => this.handleInputChanged('description', val)}
              value={description}
              title={translate('general.description')}
              maxLength={64}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_customdialog_owner`}
              onInputChanged={val => this.handleInputChanged('owner', val)}
              value={owner}
              title={translate('general.owner')}
              maxLength={8}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_customdialog_resulttableid`}
              onInputChanged={val => this.handleInputChanged('resultTableID', val)}
              value={resultTableID}
              title={translate('definition.result_table_id')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.handleResultTableSelector()
              }}
            />
          </Column>
        </Row>
        {this.renderCommand()}
      </Card>
    )
  }

  /**
 * @description Renders the selector dialogs.
 */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    return (
      <>
        {this.state.showResultTableDialog && (
          <SelectorDialog
            id={`${id}_customdialog_resulttableselector_dialog`}
            onClose={() => this.setState({ showResultTableDialog: false })}
            title={translate('definition.result_table_definitions')}
            header={[
              translate('definition.result_table_id'),
              translate('general.description'),
            ]}
            items={selector.resulttables.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newResultTableID = selector.resulttables.data[selectedRows][selector.resulttables.header.indexOf('SLTINAME')]
                this.setState({ resultTableID: newResultTableID })
              }
              this.setState({ showResultTableDialog: false })
            }}
          />
        )}
      </>
    )
  }


  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_resulttable_main`}
        className={'bux_drawer_main'}>
        {/* Render open selector dialogs */}
        {this.renderSelectorDialogs()}
        {/* general card */}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {/* main */}
        {this.renderMain()}
        {/* footer */}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    selector: state.selector,
    availableJobtypes: state.definitions.customdialogs.availableJobtypes
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getResultTableDefinitions: (fields, resultTableID, callback) => {
      ModalSelectorActions.getResultTableDefinitions(fields, resultTableID, callback)(dispatch)
    },
    getCustomDialogs: (dialogID, description, owner, resultTableID, command, jobtype) => {
      CustomDialogDefinitionActions.getCustomDialogs(dialogID, description, owner, resultTableID, command, jobtype)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionCustomDialog)