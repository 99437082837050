import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as SortUtils from 'utils/SortUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CreateOutputChannelDialog from 'components/dialogs/create_output_channel_dialog/CreateOutputChannelDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyOutputChannelDialog from 'components/dialogs/modify_output_channel_dialog/ModifyOutputChannelDialog'
import SnmpStatusDialog from 'components/dialogs/snmp_status_dialog/SnmpStatusDialog'
import VerifyOutputChannel from 'components/dialogs/verify_output_channel/VerifyOutputChannel'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as OutputChannelActions from 'redux/actions/OutputChannelDefinitionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultOutputChannel extends Component {
  state = {
    showCreateDialog: false,
    showCopy: false,
    showModify: false,
    showDelete: false,
    showVerify: false,
    showSnmpStatus: false,
    showTableSettings: false,
    header: this.fillHeaderInformation(),
    createDialogData: undefined
  }

  fillHeaderInformation() {
    return [
      { rest: OUTPUTCHANNEL_ID, translation: 'definition.outputchannel.id', default: true },
      { rest: TYPE, translation: 'general.type', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: OUTPUTCHANNEL_ALTERNATIVE_ID, translation: 'definition.outputchannel.alternative_id', default: true },
      { rest: OUTPUTCHANNEL_ONLY_ACTIVE, translation: 'definition.outputchannel.only_active_alternatives', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description Gets the index of a specific header.
   * @param {String} header specific header of the outputChannels
   */
  headerData = header => this.props.outputChannels.header.indexOf(header)

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to display.
   * @param {Array} header The header of the the columns.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Handles the modal dialogs for create, modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { getOutputChannel, outputChannels } = this.props
    const name = outputChannels.data[index][this.headerData('DCR')]
    const callback = () => this.setState({ [dialog]: true })
    getOutputChannel(undefined, name, callback)
  }

  showVerify = index => {
    const { verifyOutputChannel, getOutputChannel, outputChannels } = this.props
    const name = outputChannels.data[index][this.headerData('DCR')]
    const callback = () => this.setState({ showVerify: true })
    getOutputChannel(undefined, name, () => {
      verifyOutputChannel(name, callback)
    })
  }

  /**
   * @description Refreshs the current table.
   */
  handleRefresh = () => {
    const { preferences, getOutputChannels } = this.props
    const id = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_ID]
    const type = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_TYPE]
    const owner = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_OWNER]
    const title = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_TITLE]
    const altID = preferences[Preferences.DEFINITION_OUTPUTCHANNEL_ALTERNATIVE_ID]
    const activeTypeIndex = DefinitionUtils.getOutputChannelTypes(true).findIndex(temp => temp.key === type)
    getOutputChannels(undefined, id, type, owner, title, altID, activeTypeIndex)
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create', lang)}
          onClick={() => this.showPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings', lang)}
          onClick={() => this.setState({ showTableSettings: true })}
        />,
      ]
    )
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id, outputChannels } = this.props

    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => { this.handleDialog(rowIndex, 'showModify') }}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => { this.handleDialog(rowIndex, 'showDelete') }}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
          onClick={() => { this.handleDialog(rowIndex, 'showModify') }}
        />
        <TableButtonGroupItem
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
          onClick={() => { this.handleDialog(rowIndex, 'showCopy') }}
        />
        <TableButtonGroupItem
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
          onClick={() => { this.handleDialog(rowIndex, 'showDelete') }}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          id={`${id}_verifyBtn`}
          icon={<Icon name='verify' className='actionIcon' />}
          text={translate('general.verify')}
          title={translate('general.verify')}
          onClick={() => { this.showVerify(rowIndex) }}
        />

        {/* only show snpm status when type is ip/t */}
        {
          (rowIndex !== undefined && outputChannels.data[rowIndex][this.headerData('DCRTYPE')] === DefinitionUtils.OUTPUTCHANNEL_TYPE_IPT) && (
            <>
              <TableButtonGroupSeparator id={id} />
              <TableButtonGroupItem
                id={`${id}_snmpStatusBtn`}
                icon={<Icon name='monitor' className='actionIcon' />}
                text={translate('general.snmp_status')}
                title={translate('general.snmp_status')}
                onClick={() => { this.handleDialog(rowIndex, 'showSnmpStatus') }}
              />
            </>
          )

        }
      </TableButtonGroup>
    ]
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, outputChannels } = this.props
    let data = []
    let headers = this.getUsedHeader()
    outputChannels.data.forEach(el => {
      const dataBuffer = []
      headers.forEach(header => {
        if (header === TYPE) {
          const type = el[this.headerData('DCRTYPE')]
          const found = DefinitionUtils.getOutputChannelTypes().find(element => element.key === type)
          dataBuffer.push(found ? translate(found.translationKey) : type)
        }
        else if (header === OUTPUTCHANNEL_ONLY_ACTIVE) {
          dataBuffer.push(translate(el[this.headerData('DCRRQFLG')] === 'YES' ? 'general.yes' : 'general.no'))
        }
        else if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the view data we want to show in the table.
   */
  getViewData = () => {
    const { datemask, outputChannels } = this.props
    let data = []
    let headers = this.getUsedHeader()
    outputChannels.data.forEach(el => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === TYPE) {
          const type = el[this.headerData('DCRTYPE')]
          const found = DefinitionUtils.getOutputChannelTypes().find(element => element.key === type)
          dataBuffer.push(found ? translate(found.translationKey) : type)
        }
        else if (header === OUTPUTCHANNEL_ONLY_ACTIVE) {
          dataBuffer.push(<Icon id={'check_icon'} name={`${el[this.headerData('DCRRQFLG')] === 'YES' ? 'check' : 'no_icon'}`} />)
        }
        else if (header === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          dataBuffer.push(el[outputChannels.header.indexOf(header)])
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Deletes the selected lpd queue.
   */
  deleteOutputChannel = () => {
    const name = this.props.outputChannel.DCR

    // declare success callback
    const callback = () => { this.setState({ showDelete: false }) }

    this.props.deleteOutputChannel(name, callback)
  }

  /**
 * @description Gets the used headers.
 * @returns {Array} The used headers.
 */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_OUTPUTCHANNEL]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_OUTPUTCHANNEL].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_OUTPUTCHANNEL]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_OUTPUTCHANNEL].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  showPrefilledCreateDialog = () => {
    const { preferences } = this.props
    this.setState({
      showCreateDialog: true,
      createDialogData: {
        DCR: preferences[Preferences.DEFINITION_OUTPUTCHANNEL_ID],
        OWNER: preferences[Preferences.DEFINITION_OUTPUTCHANNEL_OWNER],
        DCRTITLE: preferences[Preferences.DEFINITION_OUTPUTCHANNEL_TITLE],
        DCRTYPE: preferences[Preferences.DEFINITION_OUTPUTCHANNEL_TYPE],
        DCRRQ: preferences[Preferences.DEFINITION_OUTPUTCHANNEL_ALTERNATIVE_ID]
      }
    })
  }

  render = () => {
    const { outputChannel, outputChannels, id, loading, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettings, showCreateDialog, showModify, showCopy, showDelete, showVerify, showSnmpStatus } = this.state
    const cleanData = outputChannels && outputChannels.data ? this.getCleanData() : null
    const viewData = outputChannels && outputChannels.data ? this.getViewData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    const dcrType = outputChannel && DefinitionUtils.getOutputChannelTypes().find(element => element.key === outputChannel?.DCRTYPE)
    return (
      <>
        {showCreateDialog &&
          <CreateOutputChannelDialog
            id={`${id}_createdialog`}
            copyOutputChannel={false}
            prefilledData={this.state.createDialogData}
            onClose={() => { this.setState({ showCreateDialog: false, createDialogData: undefined }) }}
          />
        }
        {showModify &&
          <ModifyOutputChannelDialog
            id={`${id}_modifydialog`}
            onClose={() => this.setState({ showModify: false })}
          />
        }
        {showCopy &&
          <CreateOutputChannelDialog
            id={`${id}_copydialog`}
            copyOutputChannel={true}
            onClose={() => this.setState({ showCopy: false })}
          />
        }
        {showTableSettings && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettings: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_OUTPUTCHANNEL }}
          />
        )
        }
        {/* delete outputchannel dialog */}
        {showDelete && (
          <DeleteDialog
            id={`${id}_deletedialog`}
            title={translate('definition.del_output_channel')}
            question={translate('definition.question_del_output_channel')}
            onClose={() => { this.setState({ showDelete: false }) }}
            onDelete={() => { this.deleteOutputChannel() }}>
            {/* id */}
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_output_channel_id_key_text`}>
                  {translate('definition.output_channel_id')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_output_channel_id_value_text`}>
                  {outputChannel.DCR}
                </p>
              </Column>
            </Row>
            {/* type */}
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_output_channel_type_key_text`}>
                  {translate('definition.type')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_output_channel_type_value_text`}>
                  {dcrType ? translate(dcrType.translationKey) : outputChannel.DCRTYPE}
                </p>
              </Column>
            </Row>
            {/* title */}
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_output_channel_title_key_text`}>
                  {translate('definition.title')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_output_channel_title_value_text`}>
                  {outputChannel.DCRTITLE}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {
          showVerify && (
            <VerifyOutputChannel
              id={`${id}_verifydialog`}
              onClose={() => this.setState({ showVerify: false })}
            />
          )
        }
        {
          showSnmpStatus && (
            <SnmpStatusDialog
              id={id}
              onClose={() => this.setState({ showSnmpStatus: false })}
            />
          )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {// show nosearch if recipients not exist in redux
            outputChannels
              ? (
                // show empty result if there are no recipients after searching
                cleanData
                  ? (
                    <DataTable
                      loading={loading}
                      id={id}
                      header={translatedHeaders}
                      data={viewData}
                      cleanData={cleanData}
                      selectable={true}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={rowIndex => this.handleDialog(rowIndex, 'showModify')}
                      columnSortDefs={this.getColumnSortDefs(viewData, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(cleanData, translatedHeaders)}
                      fillPage={fillPage}
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptyResult
                      description={translate('emptyresult.no_outputchannel_result')}
                      id={`${id}_emptysearchresult`}
                      link={translate('emptyresult.create_outputchannel_link')}
                      onClick={() => this.showPrefilledCreateDialog()}
                      headline={translate('emptyresult.no_result_headline')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )}
        </ResultContainer>
      </>
    )
  }
}

const OUTPUTCHANNEL_ID = 'DCR'
const TYPE = 'DCRTYPE'
const TITLE = 'DCRTITLE'
const OUTPUTCHANNEL_ALTERNATIVE_ID = 'DCRRQ'
const OUTPUTCHANNEL_ONLY_ACTIVE = 'DCRRQFLG'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const LASTCHANGED = 'LASTCHANGED'

SearchResultOutputChannel.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    outputChannels: state.definitions.outputchannels.outputChannels,
    outputChannel: state.definitions.outputchannels.outputChannel,
    keepPagination: state.definitions.outputchannels.keepPagination,
    userid: state.auth.userid,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getOutputChannel: (fields, outputChannel, callback) => {
      OutputChannelActions.getOutputChannel(fields, outputChannel, callback)(dispatch)
    },
    getOutputChannels: (fields, outputChannel, type, title, requeue, enableRequeue, callback) => {
      OutputChannelActions.getOutputChannels(fields, outputChannel, type, title, requeue, enableRequeue, callback)(dispatch)
    },
    deleteOutputChannel: (outputChannel, callback) => {
      OutputChannelActions.deleteOutputChannel(outputChannel, callback)(dispatch)
    },
    verifyOutputChannel: (outputChannel, callback) => {
      OutputChannelActions.verifyOutputChannel(outputChannel, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultOutputChannel)