import hash from 'object-hash'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as SortUtils from 'utils/SortUtils'
import * as UrlUtils from 'utils/UrlUtils'

// Components
import {
  Column, DataHierarchy, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup,
  TableButtonGroupItem, TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CreateRecipientDialog from 'components/dialogs/create_recipient_dialog/CreateRecipientDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyRecipientDialog from 'components/dialogs/modify_recipient_dialog/ModifyRecipientDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as RecipientActions from 'redux/actions/RecipientDefinitionActions'
import * as RecipientHierarchyActions from 'redux/actions/RecipientHierarchyActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

// no childs const to reduce duplicate strings and avoid spellings bugs
const NO_CHILDS = 'NO_CHILDS'

class SearchResultRecipient extends Component {
  state = {
    showCreateRecipientDialog: false,
    createRecipientDialogData: undefined,
    showCopyRecipientDialog: false,
    showDeleteRecipientDialog: false,
    showModifyRecipientDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: RECI, translation: 'recipient.reci_id', default: true },
      { rest: TYPE, translation: 'recipient.type', default: true },
      { rest: BRWS, translation: 'recipient.distribution_via', default: true },
      { rest: PREDECESSOR_ID, translation: 'recipient.general_tab_predecessor_id', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: OWNER },
      { rest: DCR },
      { rest: PCR },
      { rest: PPN },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description Modifies the new data, which is loaded through rest api request when open a hierarchy row.
   * The data is modified like the other data in the data hierarchy to display just the specific data information we want.
   * After the modification, the data is updated into the redux state.
   */
  componentDidUpdate = nextProps => {
    const { hierarchyEntriesData, updateHierarchyEntriesData, datemask } = this.props
    const hierarchyHasExpanded = (hierarchyEntriesData?.length > 0 && !nextProps?.hierarchyEntriesData) || hierarchyEntriesData?.length > nextProps?.hierarchyEntriesData?.length // Note: Check for first expansion after render OR any other expansion -> 'hierarchyEntriesData' contains the new state in render cycle (not 'nextProps')

    if (hierarchyHasExpanded) {
      let buffer = []
      const usedHeaders = this.getDefaultHeader()
      const newHierarchyEntries = [...hierarchyEntriesData]
      const lastEntry = newHierarchyEntries[newHierarchyEntries.length - 1]
      const data = Array.isArray(lastEntry.data) ? lastEntry.data : [lastEntry.data]
      data.forEach(element => {
        let dataBuffer = []
        if (element !== NO_CHILDS) {
          usedHeaders.forEach(h => {
            if (h === TYPE) {
              const type = element[this.headerDataHierarchy('RTYPE')]
              const found = DefinitionUtils.getRecipientTypes().find(element => element.key === type)
              dataBuffer.push(found ? translate(found.translationKey) : type)
            }
            else if (h === BRWS) {
              const found = DefinitionUtils.getRecipientDistributions(false).find(d => d.key === element[this.headerDataHierarchy('BRWS')])
              dataBuffer.push(found ? translate(found.translationKey) : element[this.headerDataHierarchy('BRWS')])
            }
            else if (h === LASTCHANGED) {
              dataBuffer.push(DateUtils.getDate(datemask, element[this.headerDataHierarchy('CDATE')], element[this.headerDataHierarchy('CTIME')].substring(0, 8)))
            }
            else {
              dataBuffer.push(element[this.headerDataHierarchy(h)])
            }
          })
          buffer.push(dataBuffer)
        }
        else {
          buffer = NO_CHILDS
        }
      })
      if (buffer.length > 0) {
        lastEntry.data = buffer
        lastEntry.key = Array.isArray(buffer) ? buffer.map(d => hash(d)) : [hash(buffer)]
        newHierarchyEntries[newHierarchyEntries.length - 1] = lastEntry
        updateHierarchyEntriesData(newHierarchyEntries)
      }
    }
  }

  /**
   * @description Refreshes the table.
   */
  handleRefresh = () => {
    const { preferences, getRecipients } = this.props
    const rtype = preferences[Preferences.DEFINITION_RECI_TYPE]
    const distributionVia = preferences[Preferences.DEFINITION_RECI_DISTRIBUTVIA]
    const reci = preferences[Preferences.DEFINITION_RECI_ID]
    const preReci = preferences[Preferences.DEFINITION_RECI_PREDECESSORID]
    const owner = preferences[Preferences.DEFINITION_RECI_OWNER]
    const title = preferences[Preferences.DEFINITION_RECI_TITLE]
    const outputChannel = preferences[Preferences.DEFINITION_RECI_OUTPUTCHANNEL]
    const outputFormat = preferences[Preferences.DEFINITION_RECI_OUTPUTFORMAT]
    const ppn = preferences[Preferences.DEFINITION_RECI_POSTPROCESSNOTE]
    getRecipients(undefined, rtype, distributionVia, reci, preReci, owner, title, outputChannel, outputFormat, ppn)
  }

  /**
   * @description Shows the create recipient dialog.
   */
  handleOnAddEntry = () => {
    this.setState({ showCreateRecipientDialog: true, createRecipientDialogData: undefined })
  }

  handleOnAddEntryFromSearch = () => {
    const { preferences } = this.props
    const id = preferences[Preferences.DEFINITION_RECI_ID]
    const type = preferences[Preferences.DEFINITION_RECI_TYPE]
    const owner = preferences[Preferences.DEFINITION_RECI_OWNER]
    const predecessorId = preferences[Preferences.DEFINITION_RECI_PREDECESSORID]
    const distributionVia = preferences[Preferences.DEFINITION_RECI_DISTRIBUTVIA]
    const title = preferences[Preferences.DEFINITION_RECI_TITLE]
    const outputChannel = preferences[Preferences.DEFINITION_RECI_OUTPUTCHANNEL]
    const outputFormat = preferences[Preferences.DEFINITION_RECI_OUTPUTFORMAT]
    const postprocessingNote= preferences[Preferences.DEFINITION_RECI_POSTPROCESSNOTE]
    this.setState({
      showCreateRecipientDialog: true,
      createRecipientDialogData: {
        [RECI]: id,
        [TYPE]: type,
        [OWNER]: owner,
        [PREDECESSOR_ID]: predecessorId,
        [BRWS]: distributionVia,
        [TITLE]: title,
        [DCR]: outputChannel,
        [PCR]: outputFormat,
        [PPN]: postprocessingNote
      } })
  }

  /**
   * @description Shows the create recipient dialog.
   */
  handleOnAddChildEntry = data => {
    const reci = data[0]
    const typeTranslated = data[1]
    const predreci = data[3]
    const type = DefinitionUtils.getRecipientTypes().find(d => translate(d.translationKey) === typeTranslated)?.key
    this.props.getRecipient(undefined, reci, predreci, type, () => this.setState({ showCreateRecipientDialog: true }))
  }

  /**
   * @description Shows the copy recipient dialog, if the getrecipient request was successful.
   * @param {Number} index The index of the recipient which should be copied.
   */
  handleCopyRecipientDialogTable = index => {
    const reci = this.props.recipients.data[index][this.headerDataTable('RECI')]
    const predreci = this.props.recipients.data[index][this.headerDataTable('PREDRECI')]
    const type = this.props.recipients.data[index][this.headerDataTable('RTYPE')]
    const callback = () => this.setState({ showCopyRecipientDialog: true })
    this.props.getRecipient(undefined, reci, predreci, type, callback)
  }

  /**
   * @description Shows the copy recipient dialog, if the getrecipient request was successful.
   * @param {Number} data The index of the recipient which should be copied.
   */
  handleCopyRecipientDialogHierarchy = data => {
    const reci = data[0]
    const typeTranslated = data[1]
    const predreci = data[3]
    const type = DefinitionUtils.getRecipientTypes().find(d => translate(d.translationKey) === typeTranslated)?.key
    const callback = () => this.setState({ showCopyRecipientDialog: true })
    this.props.getRecipient(undefined, reci, predreci, type, callback)
  }

  /**
   * @description Shows the modify recipient dialog, if the getrecipient request was successful.
   * @param {Number} index The index of the recipient which should be modified.
   */
  handleModifyRecipientDialogTable = index => {
    const reci = this.props.recipients.data[index][this.headerDataTable('RECI')]
    const predreci = this.props.recipients.data[index][this.headerDataTable('PREDRECI')]
    const type = this.props.recipients.data[index][this.headerDataTable('RTYPE')]
    const callback = () => this.setState({ showModifyRecipientDialog: true })
    this.props.getRecipient(undefined, reci, predreci, type, callback)
  }

  /**
   * @description Shows the modify recipient dialog, if the getrecipient request was successful.
   * @param {Number} data The data of the recipient which should be modified.
   */
  handleModifyRecipientDialogHierarchy = data => {
    // need to do this with hardcoded numbers, because the data param has other order then rest api header
    const reci = data[0]
    const typeTranslated = data[1]
    const predreci = data[3]
    const type = DefinitionUtils.getRecipientTypes().find(d => translate(d.translationKey) === typeTranslated)?.key
    const callback = () => this.setState({ showModifyRecipientDialog: true })
    this.props.getRecipient(undefined, reci, predreci, type, callback)
  }

  /**
   * @description Shows the delete recipient dialog, if the getrecipient request was successful.
   * @param {Number} index The index of the recipient which should be deleted.
   */
  handleOnDeleteRecipientTable = index => {
    const reci = this.props.recipients.data[index][this.headerDataTable('RECI')]
    const predreci = this.props.recipients.data[index][this.headerDataTable('PREDRECI')]
    const type = this.props.recipients.data[index][this.headerDataTable('RTYPE')]
    const callback = () => this.setState({ showDeleteRecipientDialog: true })
    this.props.getRecipient(undefined, reci, predreci, type, callback)
  }

  /**
   * @description Shows the delete recipient dialog, if the getrecipient request was successful.
   * @param {Number} data The data of the recipient which should be deleted.
   */
  handleOnDeleteRecipientHierarchy = data => {
    const reci = data[0]
    const typeTranslated = data[1]
    const predreci = data[3]
    const type = DefinitionUtils.getRecipientTypes().find(d => translate(d.translationKey) === typeTranslated)?.key
    this.props.checkRecipientIsDeletable(reci, () => {
      const callback = () => this.setState({ showDeleteRecipientDialog: true })
      this.props.getRecipient(undefined, reci, predreci, type, callback)
    })


  }

  /**
   * @description Deletes the current recipient.
   */
  deleteRecipient = () => {
    const { recipient, preferences } = this.props
    const recipientDefinition = {
      RECI: recipient['RECI'],
      PREDRECI: recipient['PREDRECI'],
      RTYPE: recipient['RTYPE'],
    }
    let displayAs = preferences[Preferences.DEFINITION_RECI_DISPLAY_AS]
      ? Math.max(DefinitionUtils.RECIPIENT_DISPLAY_AS.findIndex(el => el.key === preferences[Preferences.DEFINITION_RECI_DISPLAY_AS]), 0)
      : 0
    const callback = () => this.setState({ showDeleteRecipientDialog: false }, () => {
      if (displayAs === 1) {
        this.updateRecipientHierarchyAfterDelete()
      }
    })
    this.props.deleteRecipient(recipientDefinition, callback)
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data to display.
   * @param {Array} header The header of the the columns.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  showDocuments = index => {
    const { recipients } = this.props
    const header = recipients.header
    const data = recipients.data[index]
    const keys = [
      { rest: 'RECI', ui: 'recipient' }
    ]

    const state = {
      recipient: data[header.indexOf('RECI')]
    }

    const urlToPush = `/assignment/recipient_document${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtonsTable = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => { this.handleModifyRecipientDialogTable(rowIndex) }}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => { this.handleOnDeleteRecipientTable(rowIndex) }}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => { this.handleModifyRecipientDialogTable(rowIndex) }}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => { this.handleCopyRecipientDialogTable(rowIndex) }}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        <TableButtonGroupItem
          onClick={() => { this.handleOnDeleteRecipientTable(rowIndex) }}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
        <TableButtonGroupSeparator />
        <TableButtonGroupItem
          onClick={() => this.showDocuments(rowIndex)}
          id={`${id}_show_documents_btn`}
          icon={<Icon name='document' className='actionIcon' />}
          text={translate('general.display_documents')}
          title={translate('general.display_documents')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Array} data The data of the current row.
   */
  createActionButtonsHierarchy = (data, index) => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${index}`}
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleModifyRecipientDialogHierarchy(data)}
      />,
      <TableButton
        id={`${id}_tableButtonInsertChild_${index}`}
        iconName='add'
        title={data[1] === 'Alias' ? translate('definition.recipient_no_childs_possible_for_alias') : translate('general.insert_child')}
        onClick={() => data[1] !== 'Alias' && this.handleOnAddChildEntry(data)}
        disabled={data[1] === 'Alias'}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${index}`}
        tooltip={translate('general.more_options')}
        parentContainerID={`${id}_datahierarchy_container`}>
        <TableButtonGroupItem
          onClick={() => this.handleModifyRecipientDialogHierarchy(data)}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => data[1] !== 'Alias' && this.handleOnAddChildEntry(data)}
          id={`${id}_insertChildBtn`}
          icon={<Icon name='add' className='actionIcon' />}
          text={translate('general.insert_child')}
          title={data[1] === 'Alias' ? translate('definition.recipient_no_childs_possible_for_alias') : translate('general.insert_child')}
          disabled={data[1] === 'Alias'}
        />
        <TableButtonGroupItem
          onClick={() => this.handleCopyRecipientDialogHierarchy(data)}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleOnDeleteRecipientHierarchy(data)}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtonsTable = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={this.handleOnAddEntryFromSearch}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   */
  createInteractionButtonsHierarchy = (data, header) => {
    return (
      [
        // <TableMenuButton
        //   id={'add'}
        //   iconName={'add'}
        //   tooltip={translate('table.create')}
        //   onClick={this.handleOnAddEntry}
        // />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
          />
        </DownloadWrapper>,
        // <TableMenuButton
        //   id={'settings'}
        //   iconName={'settings'}
        //   tooltip={translate('table.settings')}
        //   onClick={() => this.setState({ showTableSettingsDialog: true })}
        // />,
      ]
    )
  }

  /**
   * @description Gets the index of the header in redux state definitions.recipients.header
   * @param {String} header header name of the header in redux state definitions.recipients.header
   */
  headerDataTable = header => this.props.recipients.header.indexOf(header)


  headerDataHierarchy = header => this.props.recipientsHierarchy.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData = () => {
    const { datemask, recipients } = this.props
    let data = []
    let headers = this.getUsedHeader()
    recipients.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(h => {
        if (h === TYPE) {
          const type = element[this.headerDataTable('RTYPE')]
          const found = DefinitionUtils.getRecipientTypes().find(element => element.key === type)
          dataBuffer.push(found ? translate(found.translationKey) : type)
        }
        else if (h === BRWS) {
          const found = DefinitionUtils.getRecipientDistributions(false).find(d => d.key === element[this.headerDataTable('BRWS')])
          dataBuffer.push(found ? translate(found.translationKey) : element[this.headerDataTable('BRWS')])
        }
        else if (h === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerDataTable('CDATE')], element[this.headerDataTable('CTIME')].substring(0, 8)))
        }
        else if (h === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerDataTable('CDATE')], element[this.headerDataTable('CTIME')].substring(0, 8)))
        }
        else if (h === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerDataTable('CDATE')], element[this.headerDataTable('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerDataTable(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }


  hierarchyData = () => {
    const { datemask, recipientsHierarchy } = this.props
    const data = []
    recipientsHierarchy && recipientsHierarchy.data && recipientsHierarchy.data.forEach(el => {
      const rtype = DefinitionUtils.getRecipientTypes().find(element => element.key === el[this.headerDataHierarchy('RTYPE')])
      const id = el[this.headerDataHierarchy('RECI')]
      const type = rtype ? translate(rtype.translationKey) : el[this.headerDataHierarchy('RTYPE')]
      const pred = el[this.headerDataHierarchy('PREDRECI')]
      const title = el[this.headerDataHierarchy('TITLE')]
      const lastChanged = DateUtils.getDate(datemask, el[this.headerDataHierarchy('CDATE')], el[this.headerDataHierarchy('CTIME')].substring(0, 8))
      const distributionIndex = Math.max(DefinitionUtils.getRecipientDistributions(false).findIndex(d => d.key === el[this.headerDataHierarchy('BRWS')]), 0)
      const distributionVia = translate(DefinitionUtils.getRecipientDistributions(false)[distributionIndex]?.translationKey)
      const dataToPush = [id, type, distributionVia, pred, title, lastChanged]
      data.push(dataToPush)
    })
    return data
  }

  handleHierarchyClick = (parentKey, rootKey, hash, level, data) => {
    const { getHierarchyEntry, } = this.props
    const predReci = data[this.headerDataHierarchy('RECI')]
    getHierarchyEntry(predReci, parentKey, rootKey, hash, level)
  }

  updateRecipientHierarchyAfterCreate = (reci, predreci) => {
    const { datemask, preferences, hierarchyEntriesData, getRecipientsHierarchy, updateHierarchyEntriesData } = this.props
    const fieldsPrefs = undefined
    const rtypePrefs = preferences[Preferences.DEFINITION_RECI_TYPE]
    const distributionTypePrefs = preferences[Preferences.DEFINITION_RECI_DISTRIBUTVIA]
    const recipientPrefs = preferences[Preferences.DEFINITION_RECI_ID]
    const preRecipientPrefs = preferences[Preferences.DEFINITION_RECI_PREDECESSORID]
    const ownerPrefs = preferences[Preferences.DEFINITION_RECI_OWNER]
    const titlePrefs = preferences[Preferences.DEFINITION_RECI_TITLE]
    const outputchannelPrefs = preferences[Preferences.DEFINITION_RECI_OUTPUTCHANNEL]
    const outputformatPrefs = preferences[Preferences.DEFINITION_RECI_OUTPUTFORMAT]
    const ppnPrefs = preferences[Preferences.DEFINITION_RECI_POSTPROCESSNOTE]
    if (reci && !predreci) {
      getRecipientsHierarchy(fieldsPrefs, rtypePrefs, distributionTypePrefs, recipientPrefs, preRecipientPrefs, ownerPrefs, titlePrefs, outputchannelPrefs, outputformatPrefs, ppnPrefs)
    } else if (predreci) {
      getRecipientsHierarchy(fieldsPrefs, rtypePrefs, distributionTypePrefs, recipientPrefs, preRecipientPrefs, ownerPrefs, titlePrefs, outputchannelPrefs, outputformatPrefs, ppnPrefs, () => {
        // parse data from created recipient to fit into the hierarchy structure
        const recipient = reci.RECI
        const foundType = DefinitionUtils.getRecipientTypes().find(element => element.key === reci.RTYPE)
        const type = foundType ? translate(foundType.translationKey) : reci.RTYPE
        const foundDistributionVia = DefinitionUtils.getRecipientDistributions(false).find(d => d.key === reci.BRWS)
        const distributionVia = foundDistributionVia ? translate(foundDistributionVia.translationKey) : reci.BRWS
        const predecessor = reci.PREDRECI
        const title = reci.TITLE
        const lastChanged = DateUtils.getDate(datemask, reci.CDATE, reci.CTIME.substring(0, 8))
        const createdEntry = [recipient, type, distributionVia, predecessor, title, lastChanged]
        // new data which is passed to the redux store when updated with the created entry
        const dataToUpdate = []
        // loops through all open hierarchy entries
        hierarchyEntriesData && hierarchyEntriesData.forEach(entry => {
          const dataToSearch = entry.data !== NO_CHILDS ? [...entry.data] : entry.data
          if (dataToSearch !== NO_CHILDS) {
            for (let i = 0; i < dataToSearch.length; i++) {
              const currPredReci = dataToSearch[i][3]
              // check if open row should be updated with new entry
              if (currPredReci === predreci) {
                dataToSearch.push(createdEntry)
                break
              }
            }
          }
          dataToUpdate.push({ ...entry, data: dataToSearch })
        })
        dataToUpdate.length > 0 && updateHierarchyEntriesData(dataToUpdate)
      })
    }
  }

  updateRecipientHierarchyAfterModify = reci => {
    const { preferences, hierarchyEntriesData, getRecipientsHierarchy, updateHierarchyEntriesData, datemask } = this.props
    const fieldsPrefs = undefined
    const rtypePrefs = preferences[Preferences.DEFINITION_RECI_TYPE]
    const distributionTypePrefs = preferences[Preferences.DEFINITION_RECI_DISTRIBUTVIA]
    const recipientPrefs = preferences[Preferences.DEFINITION_RECI_ID]
    const preRecipientPrefs = preferences[Preferences.DEFINITION_RECI_PREDECESSORID]
    const ownerPrefs = preferences[Preferences.DEFINITION_RECI_OWNER]
    const titlePrefs = preferences[Preferences.DEFINITION_RECI_TITLE]
    const outputchannelPrefs = preferences[Preferences.DEFINITION_RECI_OUTPUTCHANNEL]
    const outputformatPrefs = preferences[Preferences.DEFINITION_RECI_OUTPUTFORMAT]
    const ppnPrefs = preferences[Preferences.DEFINITION_RECI_POSTPROCESSNOTE]
    getRecipientsHierarchy(fieldsPrefs, rtypePrefs, distributionTypePrefs, recipientPrefs, preRecipientPrefs, ownerPrefs, titlePrefs, outputchannelPrefs, outputformatPrefs, ppnPrefs, () => {
      // parse data from created recipient to fit into the hierarchy structure
      const recipient = reci.RECI
      const foundType = DefinitionUtils.getRecipientTypes().find(element => element.key === reci.RTYPE)
      const type = foundType ? translate(foundType.translationKey) : reci.RTYPE
      const foundDistributionVia = DefinitionUtils.getRecipientDistributions(false).find(d => d.key === reci.BRWS)
      const distributionVia = foundDistributionVia ? translate(foundDistributionVia.translationKey) : reci.BRWS
      const predecessor = reci.PREDRECI
      const title = reci.TITLE
      const lastChanged = DateUtils.getDate(datemask, reci.CDATE, reci.CTIME.substring(0, 8))
      const createdEntry = [recipient, type, distributionVia, predecessor, title, lastChanged]
      // new data which is passed to the redux store when updated with the created entry
      let dataToUpdate = []
      // is used to check, if the predecessor has changed so the entry needs to be moved when open
      let moveEntry = false
      // loops through all open hierarchy entries
      hierarchyEntriesData.forEach(entry => {
        let dataToSearch = entry.data !== NO_CHILDS ? [...entry.data] : entry.data
        if (dataToSearch !== NO_CHILDS) {
          for (let i = 0; i < dataToSearch.length; i++) {
            const currReci = dataToSearch[i][0]
            const currPredecessor = dataToSearch[i][3]
            // check if open row should be updated with new entry
            if (currReci === recipient) {
              if (currPredecessor === predecessor) {
                dataToSearch[i] = createdEntry
              }
              else {
                moveEntry = true
                // if the row has more than 1 child and the predecessor has changed, just remove the chlild from it to move it later on another position
                if (dataToSearch.length > 1) {
                  dataToSearch.splice(i, 1)
                }
                else {
                  dataToSearch = NO_CHILDS
                }
              }
              break
            }
          }
        }
        dataToUpdate.push({ ...entry, data: dataToSearch })
      })
      // readds the entry which was removed before when the predessor has changed and the other row is open
      if (moveEntry) {
        for (let i = 0; i < dataToUpdate.length; i++) {
          if (dataToUpdate[i].data !== NO_CHILDS) {
            for (let j = 0; j < dataToUpdate[i].data.length; j++) {
              const currPredessesor = dataToUpdate[i].data[j][3]
              if (currPredessesor === predecessor) {
                dataToUpdate[i].data.push(createdEntry)
                break
              }
            }
          }
        }
      }
      updateHierarchyEntriesData(dataToUpdate)
    })
  }

  /**
   * @description Executes a new search and filters out the deleted entry in all open entries.
   */
  updateRecipientHierarchyAfterDelete = () => {
    const { preferences, getRecipientsHierarchy, hierarchyEntriesData, updateHierarchyEntriesData } = this.props
    const fieldsPrefs = undefined
    const rtypePrefs = preferences[Preferences.DEFINITION_RECI_TYPE]
    const distributionTypePrefs = preferences[Preferences.DEFINITION_RECI_DISTRIBUTVIA]
    const recipientPrefs = preferences[Preferences.DEFINITION_RECI_ID]
    const preRecipientPrefs = preferences[Preferences.DEFINITION_RECI_PREDECESSORID]
    const ownerPrefs = preferences[Preferences.DEFINITION_RECI_OWNER]
    const titlePrefs = preferences[Preferences.DEFINITION_RECI_TITLE]
    const outputchannelPrefs = preferences[Preferences.DEFINITION_RECI_OUTPUTCHANNEL]
    const outputformatPrefs = preferences[Preferences.DEFINITION_RECI_OUTPUTFORMAT]
    const ppnPrefs = preferences[Preferences.DEFINITION_RECI_POSTPROCESSNOTE]
    getRecipientsHierarchy(fieldsPrefs, rtypePrefs, distributionTypePrefs, recipientPrefs, preRecipientPrefs, ownerPrefs, titlePrefs, outputchannelPrefs, outputformatPrefs, ppnPrefs, () => {
      const dataToUpdate = []
      // Hash of the deleted entry which will be used to check, if the deleted entry has a "No child found." row open.
      let deletedEntryKey
      if (hierarchyEntriesData && hierarchyEntriesData.length > 0) {
        for (let i = 0; i < hierarchyEntriesData.length - 1; i++) {
          if (Array.isArray(hierarchyEntriesData[i].data)) {
            let entryFoundInOpenRows = hierarchyEntriesData[i].data.findIndex(child => child[0] === this.props.recipient.RECI)
            if (entryFoundInOpenRows !== -1) {
              deletedEntryKey = hierarchyEntriesData[i].key[entryFoundInOpenRows]
              break
            }
          }
        }
        hierarchyEntriesData.forEach(entry => {
          // If the entry is the not deleted entry
          if (entry.parentKey !== deletedEntryKey) {
            // If the row has child rows open check if one child matches the deleted one.
            if (Array.isArray(entry.data)) {
              // If the deleted entry is found in child rows.
              if (entry.data.find(child => child[0] === this.props.recipient.RECI)) {
                // If the child amount is bigger than one, delete the child row and let the others open.
                if (entry.data.length > 1) {
                  dataToUpdate.push({
                    ...entry,
                    data: Array.isArray(entry.data) ? [...entry.data.filter(child => child[0] !== this.props.recipient.RECI)] : entry.data,
                    key: [...entry.key.filter(k => k !== deletedEntryKey)]
                  })
                }
              }
              else {
                dataToUpdate.push({ ...entry })
              }
            }
          }
        })
        updateHierarchyEntriesData(dataToUpdate)
      }
    })
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_RECIPIENT]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_RECIPIENT].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_RECIPIENT]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_RECIPIENT].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  render = () => {
    const { recipients, recipientsHierarchy, hierarchyEntriesData, id, loading, drawerExpanded, recipient,
      autoDrawer, preferences, updateHierarchyEntriesData, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateRecipientDialog, showCopyRecipientDialog, showModifyRecipientDialog, showDeleteRecipientDialog } = this.state
    const tableData = recipients && recipients.data ? this.getCleanData() : null
    const hierarchyData = recipientsHierarchy ? this.hierarchyData() : null
    const hierarchyHeader = getTranslatedHeaders(this.state.header, this.getDefaultHeader())
    const tableHeader = this.getUsedHeader()
    const translatedTableHeaders = getTranslatedHeaders(this.state.header, tableHeader)
    const fillPage = this.getFillPageInfo()
    const displayAs = preferences[Preferences.DEFINITION_RECI_DISPLAY_AS]
      ? Math.max(DefinitionUtils.RECIPIENT_DISPLAY_AS.findIndex(el => el.key === preferences[Preferences.DEFINITION_RECI_DISPLAY_AS]), 0)
      : 0

    return (
      <>
        {/* create recipient dialog */}
        {showCreateRecipientDialog && (
          <CreateRecipientDialog
            id={`${id}_createrecipientdialog`}
            copyRecipient={false}
            onClose={() => this.setState({ showCreateRecipientDialog: false })}
            hierarchyData={displayAs === 1 && recipient}
            hierarchyView={displayAs === 1}
            updateHierarchy={(reci, predreci) => {
              this.setState({ showCreateRecipientDialog: false }, () => {
                this.updateRecipientHierarchyAfterCreate(reci, predreci)
              })
            }}
            recipientDataFromSearch={this.state.createRecipientDialogData}
          />
        )}
        {/* copy recipient dialog */}
        {showCopyRecipientDialog && recipient && (
          <CreateRecipientDialog
            id={`${id}_copyrecipientdialog`}
            copyRecipient={true}
            onClose={() => this.setState({ showCopyRecipientDialog: false })}
            hierarchyView={displayAs === 1}
            updateHierarchy={(reci, predreci) => {
              this.setState({ showCopyRecipientDialog: false }, () => {
                this.updateRecipientHierarchyAfterCreate(reci, predreci)
              })
            }}
          />
        )}
        {/* modify recipient dialog */}
        {showModifyRecipientDialog && recipient && (
          <ModifyRecipientDialog
            id={`${id}_modifyrecipientdialog`}
            onClose={() => this.setState({ showModifyRecipientDialog: false })}
            hierarchyView={displayAs === 1}
            updateHierarchy={reci => {
              this.setState({ showModifyRecipientDialog: false }, () => {
                this.updateRecipientHierarchyAfterModify(reci)
              })
            }}
          />
        )}
        {showDeleteRecipientDialog && recipient && (
          <DeleteDialog
            id={`${id}_deleterecipientdialog`}
            title={translate('recipient.delete_recipient')}
            question={translate('recipient.question_delete_recipient')}
            onClose={() => { this.setState({ showDeleteRecipientDialog: false }) }}
            onDelete={() => this.deleteRecipient()}
          >
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_recipientid_text`}>
                  {translate('recipient.reci_id')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_recipientid`}>
                  {this.props.recipient.RECI}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_body_type_text`}>
                  {translate('general.type')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_body_type`}>
                  {translate(DefinitionUtils.getRecipientTypes().find(element => element.key === this.props.recipient.RTYPE)?.translationKey)}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: tableHeader, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_RECIPIENT }}
          />
        )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {
            displayAs === 0
              ? recipients // show nosearch if recipients not exist in redux
                ? tableData // show empty result if there are no recipients after searching
                  ? <DataTable
                    loading={loading}
                    id={id}
                    header={translatedTableHeaders}
                    data={tableData}
                    cleanData={tableData}
                    fillPage={fillPage}
                    createActionButtons={this.createActionButtonsTable}
                    createTableRowAction={index => this.handleModifyRecipientDialogTable(index)}
                    columnSortDefs={this.getColumnSortDefs(tableData, translatedTableHeaders)}
                    additionalInteraction={this.createInteractionButtonsTable(tableData, translatedTableHeaders)}
                    selectable
                    translate={key => translate(key)}
                    langugae={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                  : <EmptyResult
                    description={translate('emptyresult.no_recipient_result')}
                    id={`${id}_emptysearchresult`}
                    link={translate('emptyresult.create_recipient_link')}
                    onClick={this.handleOnAddEntryFromSearch}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                : <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              : recipientsHierarchy
                ? recipientsHierarchy.data
                  ? <DataHierarchy
                    id={id}
                    selectable
                    header={hierarchyHeader.map(h => translate(h, undefined, undefined, false) ? translate(h) : h)}
                    data={hierarchyData}
                    hierarchyEntries={hierarchyEntriesData}
                    onOpenChilds={(parentKey, rootKey, hash, level, data) => this.handleHierarchyClick(parentKey, rootKey, hash, level, data)}
                    onCloseChilds={newHierarchyEntries => updateHierarchyEntriesData(newHierarchyEntries)}
                    columnSortDefs={['string', 'string', 'string', 'string', 'date-time']}
                    isHierarchyOpen={hierarchyEntriesData ? hierarchyEntriesData.length > 0 : false}
                    closeHierarchy={() => updateHierarchyEntriesData([])}
                    additionalInteraction={() => this.createInteractionButtonsHierarchy(hierarchyData, hierarchyHeader)}
                    createActionButtons={this.createActionButtonsHierarchy}
                    actionButtonsLength={3}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    idColumnHeaderName={translate('recipient.reci_id')}
                    relationToIdColumnHeaderName={translate('recipient.general_tab_predecessor_id')}
                  />
                  : <NoSearch
                    id={`${id}_nodata`}
                    message={
                      <span className={'bux_center_text'}>
                        {translate('general.no_data_found')}
                        <br />
                        {translate('general.create_recipient_through_table_visualization')}
                      </span>
                    }
                  />
                : <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
          }
        </ResultContainer>
      </>
    )
  }
}

const RECI = 'RECI'
const TYPE = 'RTYPE'
const PREDECESSOR_ID = 'PREDRECI'
const TITLE = 'TITLE'
const OWNER = 'OWNER'
const BRWS = 'BRWS'
const DCR = 'DCR'
const PCR = 'PCR'
const PPN = 'PPN'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const LASTCHANGED = 'LASTCHANGED'

SearchResultRecipient.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const recipient = state => state.definitions.recipients.recipient
const recipients = state => state.definitions.recipients.recipients
const keepPagination = state => state.definitions.recipients.keepPagination
const recipientsHierarchy = state => state.hierarchy.recipient.hierarchyData
const hierarchyEntriesData = state => state.hierarchy.recipient.hierarchyEntriesData
const usertoken = state => state.auth.serverdata.token
const lang = state => state.auth.serverdata.preferences[Preferences.LANGUAGE]
const datemask = state => state.auth.serverdata.preferences[Preferences.DATEMASK]
const preferences = state => state.auth.serverdata.preferences
const hierarchy = state => state.hierarchy

const mapStateToProps = state => {
  return {
    recipient: recipient(state),
    recipients: recipients(state),
    keepPagination: keepPagination(state),
    recipientsHierarchy: recipientsHierarchy(state),
    hierarchyEntriesData: hierarchyEntriesData(state),
    usertoken: usertoken(state),
    lang: lang(state),
    datemask: datemask(state),
    preferences: preferences(state),
    hierarchy: hierarchy(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getRecipient: (fields, recipient, preRecipient, rtype, callback) => {
      RecipientActions.getRecipient(fields, recipient, preRecipient, rtype, callback)(dispatch)
    },
    deleteRecipient: (recipientDefinition, callback) => {
      RecipientActions.deleteRecipient(recipientDefinition, callback)(dispatch)
    },
    getHierarchyEntry: (preRecipient, parentKey, rootKey, hash, level, callback) => {
      RecipientHierarchyActions.getHierarchyEntry(undefined, '', '', '', preRecipient, '', '', '', '', '', parentKey, rootKey, hash, level, callback)(dispatch)
    },
    updateHierarchyEntriesData: newHierarchyData => {
      RecipientHierarchyActions.updateHierarchyEntriesData(newHierarchyData)(dispatch)
    },
    getRecipients: (fields, type, distributionType, recipientid, predecessor, owner, title, outputchannel, outputformat, postprocessingnote, callback) => {
      RecipientActions.getRecipients(
        fields,
        type,
        distributionType,
        recipientid,
        predecessor,
        owner,
        title,
        outputchannel,
        outputformat,
        postprocessingnote,
        callback
      )(dispatch)
    },
    getRecipientsHierarchy: (fields, rtype, distributionType, recipient, preRecipient, owner, title, outputchannel, outputformat, ppn, callback) => {
      RecipientHierarchyActions.getRecipientsHierarchy(
        fields,
        rtype,
        distributionType,
        recipient,
        preRecipient,
        owner,
        title,
        outputchannel,
        outputformat,
        ppn,
        callback
      )(dispatch)
    },
    checkRecipientIsDeletable: (preRecipient, callback) => RecipientActions.checkRecipientIsDeletable(preRecipient, callback)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultRecipient))