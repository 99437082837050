import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Switch from 'BetaUX2Web-Components/src/components/switch/Switch'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Card from 'BetaUX2Web-Components/src/components/card/Card'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as DocumentNodesActions from 'redux/actions/DocumentNodesDefinitionActions'
import * as DocumentNodesHierarchyActions from 'redux/actions/DocumentNodesHierarchyActions'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

class DefinitionDocumentNode extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    nodeID: '',
    parentNodeID: '',
    owner: '',
    identifier: '',
    type: 0,
    newDisplayAs: 0,
    showParentNodeDialog: false
  }

  state = { ...this.defaultState }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount = () => {
    this.initFieldsFromPreferences()
  }

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props
    if (preferences) {
      const nodeID = preferences[Preferences.DEFINITION_DOCUMENT_NODE_NODE_ID] || ''
      const parentNodeID = preferences[Preferences.DEFINITION_DOCUMENT_NODE_PARENT_NODE_ID] || ''
      const owner = preferences[Preferences.DEFINITION_DOCUMENT_NODE_OWNER] || ''
      const identifier = preferences[Preferences.DEFINITION_DOCUMENT_NODE_IDENTIFIER] || ''
      const type = preferences[Preferences.DEFINITION_DOCUMENT_NODE_TYPE]
        ? Math.max(DefinitionUtils.DOCUMENT_NODE_TYPES.findIndex(el => el.key === preferences[Preferences.DEFINITION_DOCUMENT_NODE_TYPE]), 0)
        : 0
      const displayAs = preferences[Preferences.DEFINITION_DOCUMENT_NODE_DISPLAY_AS]
        ? Math.max(DefinitionUtils.RECIPIENT_DISPLAY_AS.findIndex(el => el.key === preferences[Preferences.DEFINITION_DOCUMENT_NODE_DISPLAY_AS]), 0)
        : 0

      this.setState({
        nodeID,
        owner,
        parentNodeID,
        identifier,
        type,
        displayAs
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   */
  handleInput = (id, value) => this.setState({ [id]: value })

  /**
   * @description Handles the input changes of the parentnodeid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   */
  handleInputWithoutSpaces = (key, value) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInput(key, value)
  }

  /**
   * @description Handles the dropdown input field.
   * @index The new active type index.
   */
  handleDropdown = index => this.setState({ activeDataFormatIndex: index })

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmit = event => {
    event.preventDefault()
    const { changePrefs, getDocumentNodes, getDocumentNodesHierarchy, updateHierarchyEntriesData } = this.props
    const { nodeID, parentNodeID, owner, identifier, type, displayAs } = this.state

    const newType = DefinitionUtils.DOCUMENT_NODE_TYPES[type].key
    const newDisplayAs = DefinitionUtils.RECIPIENT_DISPLAY_AS[displayAs].key

    const prefsToChange = {
      [Preferences.DEFINITION_DOCUMENT_NODE_NODE_ID]: nodeID,
      [Preferences.DEFINITION_DOCUMENT_NODE_PARENT_NODE_ID]: parentNodeID,
      [Preferences.DEFINITION_DOCUMENT_NODE_OWNER]: owner,
      [Preferences.DEFINITION_DOCUMENT_NODE_IDENTIFIER]: identifier,
      [Preferences.DEFINITION_DOCUMENT_NODE_TYPE]: newType,
      [Preferences.DEFINITION_DOCUMENT_NODE_DISPLAY_AS]: newDisplayAs
    }
    changePrefs(prefsToChange)

    const documentNodeObj = {
      DNDNAME: nodeID,
      DNDPNAME: parentNodeID,
      OWNER: owner,
      DNDENAME: identifier,
      DNDTYPE: newType
    }

    if (displayAs === 0) {
      getDocumentNodes(documentNodeObj)
    }
    else {
      delete documentNodeObj['DNDTYPE']
      getDocumentNodesHierarchy(documentNodeObj, () => updateHierarchyEntriesData(undefined))
    }
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleReset = () => this.setState(this.defaultState)

  /**
   * @description Handles the node id selector button
   */
  handleParentNodeSelector = () => {
    this.props.getDocumentNodesDefinitionTypeNode(
      ['DNDNAME', 'DNDENAME'],
      this.state.parentNodeID,
      () => this.setState({ showParentNodeDialog: true })
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    const { nodeID, parentNodeID, owner, identifier, type, displayAs } = this.state

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        <Card title={translate('general.general')}>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_node_id`}
                title={translate('definition.node_id')}
                onInputChanged={val => this.handleInputWithoutSpaces('nodeID', val)}
                maxLength={25}
                value={nodeID}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_parent_node_id`}
                title={translate('general.parent_node_id')}
                onInputChanged={val => this.handleInputWithoutSpaces('parentNodeID', val)}
                maxLength={25}
                value={parentNodeID}
                addon={{
                  iconName: 'list',
                  onClick: () => this.handleParentNodeSelector(),
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_owner`}
                title={translate('general.owner')}
                onInputChanged={val => this.handleInput('owner', val)}
                maxLength={8}
                value={owner}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_identifier`}
                title={translate('definition.index_identifier')}
                onInputChanged={val => this.handleInput('identifier', val)}
                maxLength={64}
                value={identifier}
              />
            </Column>
          </Row>
          {
            displayAs === 0 &&
            <Row>
              <Column colMD={12}>
                <Switch
                  id={`${id}_type`}
                  title={translate('general.type')}
                  items={DefinitionUtils.DOCUMENT_NODE_TYPES.map(el => translate(el.translationKey))}
                  onClick={index => this.handleInput('type', index)}
                  activeIndex={type}
                  maxPerRow={3}
                />
              </Column>
            </Row>
          }
        </Card>
        <Card title={translate('general.visualization')}>
          <Row>
            <Column colMD={12}>
              <Switch
                id={`${id}_display_as`}
                title={translate('general.display_as')}
                items={DefinitionUtils.RECIPIENT_DISPLAY_AS.map(el => translate(el.translationKey))}
                onClick={index => this.handleInput('displayAs', index)}
                activeIndex={displayAs}
                maxPerRow={3}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props
    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={'drawer_content_definition_body_search'}
          text={translate('general.search')}
          onClick={this.handleSubmit}
          submit
          primary
        />
        <Button
          id={'drawer_content_definition_body_resetBtn'}
          icon='undo'
          iconType='material'
          onClick={this.handleReset}
        />
      </div>
    )
  }

  /**
  * @description Renders the selector dialogs.
  */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    return (
      <>
        {this.state.showParentNodeDialog && (
          <SelectorDialog
            id={`${id}_nodeselector_dialog`}
            onClose={() => this.setState({ showParentNodeDialog: false })}
            title={translate('definition.document_node_definitions')}
            header={[
              translate('definition.node_id'),
              translate('general.identifier')
            ]}
            items={selector.documentnodes.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newParentNodeID = selector.documentnodes.data[selectedRows][selector.documentnodes.header.indexOf('DNDNAME')]
                this.setState({
                  parentNodeID: newParentNodeID
                })
              }
              this.setState({ showParentNodeDialog: false })
            }}
          />
        )}
      </>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'>
        {this.renderSelectorDialogs()}
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => {
      PreferenceActions.changePrefs(prefs)(dispatch)
    },
    getDocumentNodes: (documentNodeObj, callback) => {
      DocumentNodesActions.getDocumentNodes(undefined, documentNodeObj, callback)(dispatch)
    },
    getDocumentNodesHierarchy: (documentNodeObj, callback) => {
      DocumentNodesHierarchyActions.getDocumentNodesHierarchy(documentNodeObj, callback)(dispatch)
    },
    updateHierarchyEntriesData: newHierarchyEntriesData => {
      DocumentNodesHierarchyActions.updateHierarchyEntriesData(newHierarchyEntriesData)(dispatch)
    },
    getDocumentNodesDefinitionTypeNode: (fields, nodeName, callback) => {
      ModalSelectorActions.getDocumentNodesDefinitionTypeNode(fields, nodeName, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionDocumentNode)