import * as SearchUC4Actions from 'redux/actions/SearchUC4Actions'
import { LOGOUT_SUCCESS } from '../actions/AuthenticationActions'

const defaultState = {
  uc4Jobs: undefined,
  uc4JobDetails: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Get uc4 jobs
    case SearchUC4Actions.GET_UC4_JOBS_START:
      return { ...state }
    case SearchUC4Actions.GET_UC4_JOBS_SUCCESS:
      return { ...state, uc4Jobs: action.payload, keepPagination: action.keepPagination }
    case SearchUC4Actions.GET_UC4_JOBS_FAILED:
      return { ...state }
    case SearchUC4Actions.NO_UC4_JOBS_FOUND:
      return { ...state, uc4Jobs: {} }
    /* ********************************************************************************** */
    // Get specific uc4 job
    case SearchUC4Actions.GET_UC4_JOB_START:
      return { ...state }
    case SearchUC4Actions.GET_UC4_JOB_SUCCESS:
      return { ...state, uc4JobDetails: action.payload }
    case SearchUC4Actions.GET_UC4_JOB_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    case LOGOUT_SUCCESS:
      return defaultState
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer