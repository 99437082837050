import { translate } from 'language/Language'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'


import {
  Button, Card, Column, DataTable,
  Icon,
  MetaDataGrid,
  Modal as ModalComponent, Row, TableButton, TableButtonGroup, TableButtonGroupGroup, TableButtonGroupItem, TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import * as Preferences from 'redux/general/Preferences'

import ZosJobLogInformation from 'components/dialogs/zos_job_log_information/ZosJobLogInformation'
import { addTabToDocViewer } from 'redux/actions/DocViewerActions'
import * as StonebranchAction from 'redux/actions/SearchStonebranchActions'
import * as DateUtils from 'utils/DateUtils'
import DocumentPrint from '../document_print_dialog/DocumentPrint'
// import { DownloadDialog } from '../download_dialog/DownloadDialog'
import * as UrlUtils from 'utils/UrlUtils'
import { DownloadDialog } from '../download_dialog/DownloadDialog'
import RerunDialog, * as RerunDialogTypes from '../rerun_dialog/RerunDialog'


const { getDate } = DateUtils

const StonebranchJobInformationDialog = withRouter(({ history, id, onClose }) => {

  const lang = useSelector(store => store.auth.serverdata.preferences[Preferences.LANGUAGE])
  const datemask = useSelector(store => store.auth.serverdata.preferences[Preferences.DATEMASK])
  const stonebranchJobs = useSelector(store => store.search.stonebranch.stonebranchJobs)
  const stonebranchJobDetails = useSelector(store => store.search.stonebranch.stonebranchJobDetails)
  const stonebranchJobLogs = useSelector(store => store.search.stonebranch.stonebranchJobLogs)
  const stonebranchJobLogInformation = useSelector(store => store.search.stonebranch.stonebranchJobLogInformation)
  const stonebranchJobLogPrintInfo = useSelector(store => store.search.stonebranch.stonebranchJobLogPrintInfo)
  const stonebranchJobLog = useSelector(store => store.search.stonebranch.stonebranchJobLog)
  const bwesystemname = useSelector(store => store.auth.serverdata.bwesystemname)
  const bwebaseurl = useSelector(store => store.auth.serverdata.bwebaseurl)
  const userprofile = useSelector(store => store.auth.serverdata.userprofile)

  const [showPrintDialog, setShowPrintDialog] = useState(false)
  const [showJobLogInformationDialog, setShowJobLogInformationDialog] = useState(false)
  const [showRerunDialog, setShowRerunDialog] = useState(false)
  const [showDownloadDialog, setShowDownloadDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState(undefined)
  const [selectedMimeType, setSelectedMimeType] = useState(undefined)
  const [selectedDownloadType, setSelectedDownloadType] = useState(undefined)
  const [rerunType, setRerunType] = useState(undefined)

  const dispatch = useDispatch()

  const renderHeader = () => {
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('general.jobname'), value: stonebranchJobDetails.JOBNAME },
          { label: translate('general.system_id'), value: stonebranchJobDetails.SYSID },
          { label: translate('general.jobgroup'), value: stonebranchJobDetails.JOBGROUP },
          { label: translate('general.exit_code'), value: stonebranchJobDetails.JOBRC },
          { label: translate('general.agent'), value: stonebranchJobDetails.AGNTNAME },
          { label: translate('general.run_user'), value: stonebranchJobDetails.RUNUSER },
          { label: translate('general.job_type'), value: stonebranchJobDetails.JOBTYPE },
          { label: translate('general.status'), value: stonebranchJobDetails.JOBSTAT },
          { label: translate('general.launch_date'), value: `${getDate(datemask, stonebranchJobDetails.SRCSUBD)} ${stonebranchJobDetails.SRCSUBT}` },
          { label: translate('general.start_date'), value: `${getDate(datemask, stonebranchJobDetails.STRTDATE)} ${stonebranchJobDetails.STRTTIME}` },
          { label: translate('general.end_date'), value: `${getDate(datemask, stonebranchJobDetails.ENDDATE)} ${stonebranchJobDetails.ENDTIME}` },
          { label: translate('general.runtime'), value: stonebranchJobDetails.RUNTIME },
          { label: translate('general.header.credentials_name'), value: stonebranchJobDetails.CREDS },
          { label: translate('general.header.instance_number'), value: stonebranchJobDetails.INSTNUM },
          { label: translate('general.source_version'), value: stonebranchJobDetails.SRCVERS },
          { label: translate('general.workflow'), value: stonebranchJobDetails.WORKFLOW },
          { label: translate('general.header.trigger_by'), value: stonebranchJobDetails.TRIGBY },
          { label: translate('general.header.trigger_name'), value: stonebranchJobDetails.TRIGGER },
          { label: translate('general.header.trigger_date'), value: `${getDate(datemask, stonebranchJobDetails.TRIGDATE)} ${stonebranchJobDetails.TRIGTIME}` },
          { label: translate('general.header.updated'), value: `${getDate(datemask, stonebranchJobDetails.UPDDATE)} ${stonebranchJobDetails.UPDTIME}` },
          { label: translate('general.source_version'), value: stonebranchJobDetails.SRCVERS },
          { label: translate('general.workflow'), value: stonebranchJobDetails.WORKFLOW },
          { label: stonebranchJobDetails.USR1NAM, value: stonebranchJobDetails.USR1VAL },
          { label: stonebranchJobDetails.USR2NAM, value: stonebranchJobDetails.USR2VAL },
          { label: translate('general.service'), value: stonebranchJobDetails.SERVICES && stonebranchJobDetails.SERVICES.replace(',', ', ')},
        ]}
        columns={4}
      />
    )
  }

  const handleLogInformation = rowIndex => {
    const joblogObj = {
      UKEY: stonebranchJobLogs.data[rowIndex][stonebranchJobLogs.header.indexOf('UKEY')]
    }
    StonebranchAction.getJobLogInformation(undefined, joblogObj, () => setShowJobLogInformationDialog(true))(dispatch)
  }

  /**
   * @description Handles the display functionality of documents in _beta view.
   * @param {Number} rowIndex The current row.
   */
  const handleDisplayLog = rowIndex => {
    const docid = stonebranchJobLogs.data[rowIndex][headerData('DOCID')]
    const ukey = stonebranchJobLogs.data[rowIndex][headerData('UKEY')]

    StonebranchAction.displayLog(docid, bwebaseurl, bwesystemname, ukey)(dispatch)
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  const openDocumentViewer = (rowIndex) => {
    const docId = stonebranchJobLogs.data[rowIndex][headerData('DOCID')]
    const pageCount = stonebranchJobLogs.data[rowIndex][headerData('SRCPAGES')]

    addTabToDocViewer({
      id: docId,
      type: 'stonebranch',
      header: stonebranchJobLogs.header,
      data: stonebranchJobLogs.data[rowIndex],
      pageCount: parseInt(pageCount)
    })(dispatch)
  }

  /**
* @description Handles the open print document dialog action.
* @param {Number} rowIndex The current row.
*/
  const handleShowLogPrint = rowIndex => {
    const docid = stonebranchJobLogs.data[rowIndex][headerData('DOCID')]
    StonebranchAction.getLog(undefined, docid, undefined, () => {
      StonebranchAction.getPrintInfo(docid, () => setShowPrintDialog(true))(dispatch)
    })(dispatch)
  }

  /**
* @description Handles the rerun action.
* @param {Number} rowIndex The current row.
* @param {String} type The type of rerun. Use constants of RerunDialog!
*/
  const handleRerun = (rowIndex, type) => {
    const docid = stonebranchJobLogs.data[rowIndex][headerData('DOCID')]
    const callback = () => { setRerunType(type); setShowRerunDialog(true) }
    StonebranchAction.getLog(undefined, docid, undefined, callback)(dispatch)
  }

  /**
   * @description Opens the recipient log assignment menu and prefills drawer values with values from log entry.
   * @param {*} index The rowindex
   */
  const showRecipients = index => {
    const header = stonebranchJobLogs.header
    const data = stonebranchJobLogs.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
      { rest: 'REPORT', ui: 'report' },
      { rest: 'WREPORT', ui: 'dynamicReport' },
    ]

    // fake the state like its used in assignment recipient log to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')] || '',
      extension: data[header.indexOf('EXT')] || '',
      report: data[header.indexOf('WREPORT')] || '',
      dynamicReport: ''
      //  Not possible to get the value at the moment.
      // dynamicReport: data[header.indexOf('DREPORT')],
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/recipient_log${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    history.push(urlToPush)
  }


  /**
 * @description Handles the download functionality of logs in binary format.
 * @param {Number} rowIndex The current row.
 */
  const handleLogDownloadBinary = rowIndex => {
    const docid = stonebranchJobLogs.data[rowIndex][headerData('DOCID')]
    const form = stonebranchJobLogs.data[rowIndex][headerData('FORM')]
    const ext = stonebranchJobLogs.data[rowIndex][headerData('EXT')]
    const report = stonebranchJobLogs.data[rowIndex][headerData('REPORT')]

    let doctype = stonebranchJobLogs.data[rowIndex][headerData('SRCJOBI')]
    if (doctype.lastIndexOf('.') !== -1) {
      doctype = doctype.substring(doctype.lastIndexOf('.') + 1)
    }

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => entry !== undefined).join('.')
    StonebranchAction.downloadLog(docid, filename)(dispatch)
  }

  /**
   * @description Handles the download functionality. Opens an additional modal, if download settings  are available for a specific mimetype.
   * @param {Number} rowIndex The current row.
   * @param {String} mimetype The mimetype of the specific doucment.
   */
  const handleDocumentDownloadText = (rowIndex, mimetype) => {
    if (!showDownloadDialog && ['*.afp', '*.pcl', '*.pjl'].includes(mimetype)) {
      return openDocumentDownloadDialog(rowIndex, 'TEXT')
    }
    return executeDownloadTextDirectly(rowIndex, mimetype)
  }

  /**
   * @description Executes the text download directly without an additional modal.
   * @param {Number} rowIndex The current row.
   * @param {String} mimetype The mimetype of the specific doucment.
   */
  const executeDownloadTextDirectly = (rowIndex, mimetype) => {
    const docid = stonebranchJobLogs.data[rowIndex][headerData('DOCID')]
    const form = stonebranchJobLogs.data[rowIndex][headerData('FORM')]
    const ext = stonebranchJobLogs.data[rowIndex][headerData('EXT')]
    const report = stonebranchJobLogs.data[rowIndex][headerData('REPORT')]
    let doctype = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[mimetype]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    StonebranchAction.downloadLogText(docid, filename, mimetypeTranslation)(dispatch)
  }

  const openDocumentDownloadDialog = (rowIndex, type) => {
    setSelectedRow(rowIndex)
    setSelectedMimeType(stonebranchJobLogs.data[rowIndex][headerData('SRCJOBI')])
    setSelectedDownloadType(type)
    setShowDownloadDialog(true)
  }

  const resetDownloadDialog = () => {
    setShowDownloadDialog(false)
    setSelectedRow(undefined)
    setSelectedMimeType(undefined)
    setSelectedDownloadType(undefined)
  }

  /**
   * @description Handles the download functionality of zos job logs in hex format.
   * @param {Number} rowIndex The current row.
   */
  const handleLogDownloadHex = (displayMode, showPageHeader, showResources) => {
    const docid = stonebranchJobLogs.data[selectedRow][headerData('DOCID')]

    const form = stonebranchJobLogs.data[selectedRow][headerData('FORM')]
    const ext = stonebranchJobLogs.data[selectedRow][headerData('EXT')]
    const report = stonebranchJobLogs.data[selectedRow][headerData('REPORT')]
    let doctype = 'hex'

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    StonebranchAction.downloadLogHex(docid, filename, displayMode, showPageHeader, showResources, () => resetDownloadDialog())(dispatch)
  }

  /**
   * @description Handles the download functionality of zos job logs in text format from download dialog.
   * @param {String} showNOP YES/NO value for show NOP
   * @param {String} showTLE YES/NO value for show TLE
   * @param {String} showPJL YES/NO value for show PJL
   */
  const executeLogTextFromDialog = (showNOP, showTLE, showPJL) => {
    const docid = stonebranchJobLogs.data[selectedRow][headerData('DOCID')]

    const form = stonebranchJobLogs.data[selectedRow][headerData('FORM')]
    const ext = stonebranchJobLogs.data[selectedRow][headerData('EXT')]
    const report = stonebranchJobLogs.data[selectedRow][headerData('REPORT')]
    let doctype = 'txt'

    const mimetypeTranslation = {
      '*.txt': 'txt',
      '*.ps': 'pstext',
      '*.afp': 'afptext',
      '*.pcl': 'pcltext',
      '*.pjl': 'pcltext',
      '*.pdf': 'pdftext',
    }[selectedMimeType]

    // Generate name which will be suggested as download-filename
    const filename = [form, ext, report, docid, doctype].filter(entry => ![undefined, '', '*'].includes(entry)).join('.')

    StonebranchAction.downloadLogText(docid, filename, mimetypeTranslation, showNOP, showTLE, showPJL, () => resetDownloadDialog())(dispatch)
  }

  /**
 * @description un-/marks the archive or delete status, unmarks the reload status
 * @param {Number} index the index of the current row
 * @param {String} markedParam the header which should be used to update the ARCHIVE, DELETE or RELOAD
 * possible: 'LGRFFARC', 'LGRFFDEL', 'LGRFFREL'
 * LGRFFREL is only used to unmark reload
 */
  const updateMark = (index, markedParam) => {
    const docid = stonebranchJobLogs.data[index][headerData('DOCID')]

    const callback = (stonebranchJobLog) => {
      const archiveStatus = stonebranchJobLog[markedParam] === false || stonebranchJobLog[markedParam] === '' ? 'PENDING' : ''
      let logDefinition = {
        DOCID: docid,
        UKEY: stonebranchJobLog.UKEY
      }
      const job = stonebranchJobDetails
      const searchObj = {
        LOGSOURCE: 'STB',
        UKEY: job[stonebranchJobs.header.indexOf('UKEY')]
      }
      if (markedParam === 'LGRFFARC') {
        logDefinition = {
          ...logDefinition,
          ARCHIVE: archiveStatus
        }
        StonebranchAction.markArchive(logDefinition, searchObj)(dispatch)
      }
      else if (markedParam === 'LGRFFDEL') {
        logDefinition = {
          ...logDefinition,
          DELETE: archiveStatus
        }
        StonebranchAction.markDelete(logDefinition, searchObj)(dispatch)
      }
    }

    StonebranchAction.getLog(undefined, docid, undefined, callback)(dispatch)
  }

  /**
    * @description Creates the action buttons for the table.
    * @param {Number} rowIndex The index of the current row.
    */
  const createActionButtons = rowIndex => {
    const mimetype = rowIndex !== undefined ? stonebranchJobLogs?.data[rowIndex][headerData('SRCJOBI')] : undefined
    const modifyArchiveAllowed = userprofile === undefined || userprofile?.VCIARC
    const modifyDeleteAllowed = userprofile === undefined || userprofile?.VCIDEL

    return [
      <TableButton
        id={`${id}_tableButtonInfo_${rowIndex}`}
        iconType={'material'}
        iconName={'info'}
        title={translate('general.information')}
        onClick={() => handleLogInformation(rowIndex)}
      />,
      <TableButton
        id={`${id}_tableButtonDisplay_${rowIndex}`}
        iconType={'material'}
        iconName={'document'}
        title={translate('general.display')}
        onClick={() => openDocumentViewer(rowIndex)}
      />,
      <TableButtonGroup
        id={`${id}_tableButtonMore_${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          id={`${id}_info_btn`}
          icon={<Icon name='info' className='actionIcon' />}
          text={translate('general.information')}
          title={translate('general.information')}
          onClick={() => handleLogInformation(rowIndex)}
        />
        <TableButtonGroupItem
          id={`${id}_display_btn`}
          icon={<Icon name='document' className='actionIcon' />}
          text={translate('general.display_in_beta_view')}
          title={translate('general.display_in_beta_view')}
          onClick={() => handleDisplayLog(rowIndex)}
        />
        <TableButtonGroupItem
          id={`${id}_print_btn`}
          icon={<Icon name='print' className='actionIcon' />}
          text={translate('documentprint.print')}
          title={translate('documentprint.print')}
          onClick={() => handleShowLogPrint(rowIndex)}
        />

        <TableButtonGroupGroup id={`${id}_download_group_btn`} text={translate('general.download')}>
          <TableButtonGroupItem
            onClick={() => handleLogDownloadBinary(rowIndex)}
            id={`${id}_download_btn`}
            icon={<Icon name={'download'} className={'actionIcon'} />}
            text={translate('general.download')}
            title={translate('general.download')}
          />
          {
            ['*.ps', '*.pdf', '*.afp', '*.pcl', '*.pjl'].includes(mimetype) &&
            <TableButtonGroupItem
              onClick={() => handleDocumentDownloadText(rowIndex, mimetype)}
              id={`${id}_download_text_btn`}
              icon={<Icon name={'download'} className={'actionIcon'} />}
              text={translate('general.download_as_text')}
              title={translate('general.download_as_text')}
            />
          }

          <TableButtonGroupItem
            onClick={() => openDocumentDownloadDialog(rowIndex, 'HEX')}
            id={`${id}_download_hex_btn`}
            icon={<Icon name={'download'} className={'actionIcon'} />}
            text={translate('standard_selection.download_as_hex')}
            title={translate('standard_selection.download_as_hex')}
          />
        </TableButtonGroupGroup>
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => handleRerun(rowIndex, RerunDialogTypes.ITEM_FORCE_AUTO_PRINT)}
          id={`${id}_rerun_autoprint_btn`}
          icon={<Icon name='rerun' className='actionIcon' />}
          text={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
          title={translate('usermanagement.userprofile_basic_tab.rerun_autoprint')}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          onClick={() => showRecipients(rowIndex)}
          id={`${id}_show_recipients_btn`}
          icon={<Icon name='group' className='actionIcon' />}
          text={translate('general.display_recipients')}
          title={translate('general.display_recipients')}
        />

        {(modifyArchiveAllowed || modifyDeleteAllowed) &&
          <TableButtonGroupSeparator id={id} />
        }
        { /* check if stonebranchJobLogs.data[rowIndex] is not undefined is necessary, because redux updates the data faster than datatable, so datatable useses this function with old index and we get an error, when less rows are in the new data (no proof, just a theory)*/}
        {modifyDeleteAllowed && (stonebranchJobLogs.data[rowIndex] &&
          (stonebranchJobLogs.data[rowIndex] && stonebranchJobLogs.data[rowIndex][headerData('LGRFFDEL')] === ''
            ? <TableButtonGroupItem
              onClick={() => updateMark(rowIndex, 'LGRFFDEL')}
              id={`${id}_markDeleteBtn`}
              icon={<Icon name='delete' className='actionIcon' />}
              text={translate('general.mark_delete')}
              title={translate('general.mark_delete')}
            />
            : <TableButtonGroupItem
              onClick={() => updateMark(rowIndex, 'LGRFFDEL')}
              id={`${id}_unmarkDeleteBtn`}
              icon={<Icon name='close' className='actionIcon' />}
              text={translate('general.unmark_delete')}
              title={translate('general.unmark_delete')}
            />))
        }

        {modifyArchiveAllowed && stonebranchJobLogs.data[rowIndex] && (
          stonebranchJobLogs.data[rowIndex][headerData('LGRFFARC')] === ''
            ? <TableButtonGroupItem
              onClick={() => updateMark(rowIndex, 'LGRFFARC')}
              id={`${id}_markArchiveBtn`}
              icon={<Icon name='archive' className='actionIcon' />}
              text={translate('general.mark_archive')}
              title={translate('general.mark_archive')}
            />
            : <TableButtonGroupItem
              onClick={() => updateMark(rowIndex, 'LGRFFARC')}
              id={`${id}_unmarkArchiveBtn`}
              icon={<Icon name='unarchive' className='actionIcon' />}
              text={translate('general.unmark_archive')}
              title={translate('general.unmark_archive')}
            />)}
      </TableButtonGroup>
    ]
  }

  const headerData = header => stonebranchJobLogs.header.indexOf(header)
  /**
  * @description gets the corrected/formatted data from the table
  */
  const getTableData = () => {
    const data = []
    stonebranchJobLogs.data.forEach(el => {
      const sysId = el[headerData('SYSID')]
      const attemptCount = el[headerData('ATTCOUNT')]
      const outputType = el[headerData('SRCDDND')]
      const outputFilename = el[headerData('SRCDSN')]
      const pages = el[headerData('SRCPAGES')]
      const lines = el[headerData('SRCLNCT')]
      const markedDelete = el[headerData('LGRFFDEL')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      const markedArchive = el[headerData('LGRFFARC')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      const markedReload = el[headerData('LGRFFREL')] === 'PENDING' ? translate('general.yes') : translate('general.no')
      const archived = el[headerData('LGRARCH')] === 'YES' ? translate('general.yes') : translate('general.no')
      const online = el[headerData('LGRONLNE')] === 'YES' ? translate('general.yes') : translate('general.no')
      data.push([sysId, attemptCount, outputType, outputFilename, pages, lines, markedDelete, markedArchive, markedReload, archived, online])
    })
    return data
  }
  const renderLogs = () => (
    <Card className={'bux_card_flex'}>
      <Row>
        <Column colMD={12}>
          <label id={`${id}_logfiles`}>{translate('job.log_files')}</label>
        </Column>
      </Row>
      <Row colMD={12} className='bux_full_height'>
        <Column colMD={12} className={'bux_full_height'}>
          {data && <DataTable
            id={id}
            header={[
              translate('general.system_id'),
              translate('general.attempt_count'),
              translate('general.output_type'),
              translate('general.output_file'),
              translate('general.pages'),
              translate('general.lines'),
              translate('general.marked_delete'),
              translate('general.marked_archive'),
              translate('general.marked_reload'),
              translate('general.archive'),
              translate('recipient.distribute_online'),
            ]}
            columnSortDefs={['string', 'string', 'number', 'number', 'number', 'string', 'string', 'string', 'string']}
            createActionButtons={createActionButtons}
            data={data}
            language={lang}
            datemask={datemask}
            translate={key => translate(key)}
            menu={false}
          />}
        </Column>
      </Row>
    </Card>
  )

  const data = stonebranchJobLogs && stonebranchJobLogs.data ? getTableData() : null
  return (
    <>
      {
        showJobLogInformationDialog &&
        <ZosJobLogInformation
          id={`${id}_info_dialog`}
          zosJobLogInformation={stonebranchJobLogInformation}
          onClose={() => setShowJobLogInformationDialog(false)}
        />
      }
      {
        showPrintDialog &&
        <DocumentPrint
          id={`${id}_print_dialog`}
          onClose={() => setShowPrintDialog(false)}
          document={stonebranchJobLog}
          printInfo={stonebranchJobLogPrintInfo}
        />
      }
      {
        showRerunDialog &&
        <RerunDialog
          id={`${id}_rerun_dialog`}
          onClose={() => setShowRerunDialog(false)}
          type={rerunType}
          document={stonebranchJobLog}
        />
      }
      {
        showDownloadDialog &&
        <DownloadDialog
          id={selectedDownloadType === 'TEXT' ? `${id}_download_text_dialog` : `${id}_download_hex_dialog`}
          onClose={() => resetDownloadDialog()}
          mimetype={selectedMimeType}
          download={selectedDownloadType === 'TEXT' ? executeLogTextFromDialog : handleLogDownloadHex}
          downloadType={selectedDownloadType}
          title={selectedDownloadType === 'TEXT' ? translate('general.download_as_text') : translate('standard_selection.download_as_hex')}
        />
      }
      <Modal
        id={'stonebranch_job_information_dialog'}
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={translate('general.job_details')}
          onClose={onClose}>
          {renderHeader()}
        </Header>
        <Main>
          {renderLogs()}
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    </>
  )
})

export default StonebranchJobInformationDialog