import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'

import * as PreferenceActions from 'redux/actions/PreferencesActions'
import { getViewProfiles } from 'redux/actions/ViewProfileDefinitionActions'

import { translate } from 'language/Language'
import * as Preferences from 'redux/general/Preferences'

import { connect } from 'react-redux'

class DefinitionViewProfile extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  }

  defaultState = {
    viewProfileID: '',
    title: '',
    docType: '',
    owner: ''
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount() {
    // initialize search fields
    const { prefs } = this.props

    if (prefs) {
      this.setState({
        viewProfileID: prefs[Preferences.DEFINITION_VIEW_PROFILE_ID] || this.defaultState.viewProfileID,
        title: prefs[Preferences.DEFINITION_VIEW_PROFILE_TITLE] || this.defaultState.title,
        docType: prefs[Preferences.DEFINITION_VIEW_PROFILE_DOCTYPE] || this.defaultState.docType,
        owner: prefs[Preferences.DEFINITION_VIEW_PROFILE_OWNER] || this.defaultState.owner
      })
    }
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => this.setState(this.defaultState)

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param val New value of inputfield
   */
  handleInputChanged = (key, val) => this.setState({ [key]: val })

  /**
   * @description Handles the search event. Saves the search values in preferences
   * and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = (event) => {
    event.preventDefault()
    const { viewProfileID, title, docType, owner } = this.state

    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_VIEW_PROFILE_ID]: viewProfileID,
      [Preferences.DEFINITION_VIEW_PROFILE_TITLE]: title,
      [Preferences.DEFINITION_VIEW_PROFILE_DOCTYPE]: docType,
      [Preferences.DEFINITION_VIEW_PROFILE_OWNER]: owner
    }

    this.props.changePrefs(prefsToChange)
    this.props.getViewProfiles(viewProfileID, title, docType, owner)
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { viewProfileID, title, docType, owner } = this.state

    return (
      <Card title={translate('general.general')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_viewprofile_viewprofileid`}
              onInputChanged={val => this.handleInputChanged('viewProfileID', val)}
              value={viewProfileID}
              title={translate('definition.view_profile_id')}
              maxLength={16}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_viewprofile_title`}
              onInputChanged={val => this.handleInputChanged('title', val)}
              value={title}
              title={translate('general.title')}
              maxLength={40}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_viewprofile_doctype`}
              onInputChanged={val => this.handleInputChanged('docType', val)}
              value={docType}
              title={translate('documentinformation.header_doctype')}
              maxLength={8}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_viewprofile_owner`}
              onInputChanged={val => this.handleInputChanged('owner', val)}
              value={owner}
              title={translate('general.owner')}
              maxLength={8}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_viewprofile_main`}
        className={'bux_drawer_main'}>
        {/* general card */}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {/* main */}
        {this.renderMain()}
        {/* footer */}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getViewProfiles: (viewProfileID, title, docType, owner) => {
      getViewProfiles(viewProfileID, title, docType, owner)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionViewProfile)