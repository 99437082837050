import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Switch from 'BetaUX2Web-Components/src/components/switch/Switch'

import * as PreferenceActions from 'redux/actions/PreferencesActions'

import { translate } from 'language/Language'
import * as Preferences from 'redux/general/Preferences'
import * as DefinitionUtils from 'utils/DefinitionUtils'

import { connect } from 'react-redux'
import * as LogDefinitionActions from 'redux/actions/LogDefinitionActions'

class DefinitionLog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  }

  defaultState = {
    host: '',
    application: '',
    subLog: '',
    typeIndex: 0,
    owner: '',
    title: ''
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  initFieldsFromPreferences = () => {
    const { prefs } = this.props
    if (prefs) {
      const typeIndex = prefs[Preferences.DEFINITION_LOG_TYPE]
        ? Math.max(DefinitionUtils.LOG_TYPES.findIndex(mode => mode.key === prefs[Preferences.DEFINITION_LOG_TYPE]), 0)
        : 0
      this.setState({
        host: prefs[Preferences.DEFINITION_LOG_HOST] || '',
        application: prefs[Preferences.DEFINITION_LOG_APPLICATION] || '',
        subLog: prefs[Preferences.DEFINITION_LOG_SUBLOG] || '',
        typeIndex,
        owner: prefs[Preferences.DEFINITION_LOG_OWNER] || '',
        title: prefs[Preferences.DEFINITION_LOG_TITLE] || '',
      })
    }
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }


  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => this.setState(this.defaultState)

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param val New value of inputfield
   */
  handleInputChanged = (key, val) => this.setState({ [key]: val })

  /**
   * @description Handles the submit search action.
   * @param {Object} event The event which is thrown by the button
   */
  handleSubmitSearch = event => {
    event.preventDefault()
    const { changePrefs, getLogs } = this.props
    const { host, application, subLog, typeIndex, owner, title, } = this.state
    const type = DefinitionUtils.LOG_TYPES[typeIndex].key

    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_LOG_HOST]: host,
      [Preferences.DEFINITION_LOG_APPLICATION]: application,
      [Preferences.DEFINITION_LOG_SUBLOG]: subLog,
      [Preferences.DEFINITION_LOG_TYPE]: type,
      [Preferences.DEFINITION_LOG_OWNER]: owner,
      [Preferences.DEFINITION_LOG_TITLE]: title,
    }
    changePrefs(prefsToChange)

    getLogs(
      undefined,
      host,
      application,
      subLog,
      type,
      owner,
      title,
    )
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { host, application, subLog, typeIndex, owner, title } = this.state

    return (
      <Card title={translate('general.general')}>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_log_host`}
              onInputChanged={val => this.handleInputChanged('host', val)}
              value={host}
              title={translate('definition.host')}
              maxLength={8}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_log_application`}
              onInputChanged={val => this.handleInputChanged('application', val)}
              value={application}
              title={translate('definition.application')}
              maxLength={16}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_log_sublog`}
              onInputChanged={val => this.handleInputChanged('subLog', val)}
              value={subLog}
              title={translate('definition.sublog')}
              maxLength={16}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_log_type`}
              title={translate('general.type')}
              items={DefinitionUtils.LOG_TYPES.map(el => translate(el.translationKey))}
              onClick={index => this.handleInputChanged('typeIndex', index)}
              activeIndex={typeIndex}
              maxPerRow={3}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_log_owner`}
              title={translate('general.owner')}
              value={owner}
              onInputChanged={val => this.handleInputChanged('owner', val)}
              maxLength={8}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_log_title`}
              title={translate('general.title')}
              value={title}
              onInputChanged={val => this.handleInputChanged('title', val)}
              maxLength={80}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_log_main`}
        className={'bux_drawer_main'}>
        {/* general card */}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {/* main */}
        {this.renderMain()}
        {/* footer */}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getLogs: (fields, host, application, subLog, process, owner, title, callback) => {
      LogDefinitionActions.getLogs(fields, host, application, subLog, process, owner, title, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionLog)