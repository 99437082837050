import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const NODE_DOCUMENT_GET_ASSIGNMENTS_START = 'NODE_DOCUMENT_GET_ASSIGNMENTS_START'
export const NODE_DOCUMENT_GET_ASSIGNMENTS_SUCCESS = 'NODE_DOCUMENT_GET_ASSIGNMENTS_SUCCESS'
export const NODE_DOCUMENT_GET_ASSIGNMENTS_FAILED = 'NODE_DOCUMENT_GET_ASSIGNMENTS_FAILED'

export const NODE_DOCUMENT_GET_ASSIGNMENT_START = 'NODE_DOCUMENT_GET_ASSIGNMENT_START'
export const NODE_DOCUMENT_GET_ASSIGNMENT_SUCCESS = 'NODE_DOCUMENT_GET_ASSIGNMENT_SUCCESS'
export const NODE_DOCUMENT_GET_ASSIGNMENT_FAILED = 'NODE_DOCUMENT_GET_ASSIGNMENT_FAILED'

export const NODE_DOCUMENT_DELETE_ASSIGNMENT_START = 'NODE_DOCUMENT_DELETE_ASSIGNMENT_START'
export const NODE_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS = 'NODE_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS'
export const NODE_DOCUMENT_DELETE_ASSIGNMENT_FAILED = 'NODE_DOCUMENT_DELETE_ASSIGNMENT_FAILED'

export const NODE_DOCUMENT_UPDATE_ASSIGNMENT_START = 'NODE_DOCUMENT_UPDATE_ASSIGNMENT_START'
export const NODE_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS = 'NODE_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS'
export const NODE_DOCUMENT_UPDATE_ASSIGNMENT_FAILED = 'NODE_DOCUMENT_UPDATE_ASSIGNMENT_FAILED'

export const NODE_DOCUMENT_CREATE_ASSIGNMENT_START = 'NODE_DOCUMENT_CREATE_ASSIGNMENT_START'
export const NODE_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS = 'NODE_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS'
export const NODE_DOCUMENT_CREATE_ASSIGNMENT_FAILED = 'NODE_DOCUMENT_CREATE_ASSIGNMENT_FAILED'

export const NO_NODE_DOCUMENT_ASSIGNMENTS_FOUND = 'NO_NODE_DOCUMENT_ASSIGNMENTS_FOUND'

/**
 * @description Writes the node assignment object into redux. We already have all data and there is no request for getting a single node document assignment.
 * @param {Object} nodeAssignment The node assignment object. Fields:
 * 'DNDNAME', 'FORM', 'EXT', 'DGIENAME', 'DGIDESC', 'OWNER', 'CUSER', 'CDATE', 'CTIME'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getNodeDocumentAssignment(nodeAssignment, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    dispatch({ type: NODE_DOCUMENT_GET_ASSIGNMENT_START })

    const properties = ['DNDNAME', 'FORM', 'EXT', 'DGIENAME', 'DGIDESC', 'OWNER', 'CUSER', 'CDATE', 'CTIME']
    // check if all properties exist
    if (ObjectUtils.hasProperties(nodeAssignment, properties)) {
      dispatch({ type: NODE_DOCUMENT_GET_ASSIGNMENT_SUCCESS, payload: nodeAssignment })

      // call callback on success
      if (callback) {
        callback()
      }
    }
    else {
      SnackbarActions.show(Lang.translate('assignment.node_document_assignment.loading_error'), SnackbarActions.TYPE_ERROR)(dispatch)
      dispatch({ type: NODE_DOCUMENT_GET_ASSIGNMENT_FAILED })
    }

    LoadingSpinnerActions.hide()(dispatch)
  }
}

/**
 * @description Calls the rest api and deletes a node document assignment.
 * @param {Object} nodeDocumentAssignment The nodeDocumentAssignment. Possible fields:
 * 'DNDNAME', 'FORM', 'EXT'
 * @param {Function} callback The callback which will be called when the delete node document assignment request was successful. Can be null.
 */
export function deleteNodeDocumentAssignment(nodeDocumentAssignment, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    const assignmentMessage = []
    assignmentMessage.push(`${Lang.translate('definition.node_id')}: ${nodeDocumentAssignment.DNDNAME}`)
    if (nodeDocumentAssignment.FORM !== '') {
      assignmentMessage.push(`${Lang.translate('general.form')}: ${nodeDocumentAssignment.FORM}`)
    }
    if (nodeDocumentAssignment.EXT !== '') {
      assignmentMessage.push(`${Lang.translate('general.extension')}: ${nodeDocumentAssignment.EXT}`)
    }
    const assignmentParam = assignmentMessage.join(', ')

    dispatch({ type: NODE_DOCUMENT_DELETE_ASSIGNMENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodedocumentassignment${createQueryParamsForFetch(ObjectUtils.removeByValue(nodeDocumentAssignment, [undefined, null]))}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: NODE_DOCUMENT_DELETE_ASSIGNMENT_FAILED })
        } else {
          dispatch({ type: NODE_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('assignment.node_document.delete_assignment_success', lang, assignmentParam), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.node_document.delete_assignment_failed', lang, [assignmentParam, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: NODE_DOCUMENT_DELETE_ASSIGNMENT_FAILED })
      })
  }
}

export function getNodeDocumentAssignments(nodeID, form, extension, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: NODE_DOCUMENT_GET_ASSIGNMENTS_START })

    const queryParams = []
    if (nodeID) { queryParams.push(`DNDNAME=${nodeID}`) }
    if (form) { queryParams.push(`FORM=${form}`) }
    if (extension) { queryParams.push(`EXT=${extension}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodedocumentassignments?${queryParams.join('&')}`, { method: 'get' })
    )
    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_NODE_DOCUMENT_ASSIGNMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: NODE_DOCUMENT_GET_ASSIGNMENTS_FAILED })
          }
        }
        else {
          dispatch({ type: NODE_DOCUMENT_GET_ASSIGNMENTS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.node_document.loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: NODE_DOCUMENT_GET_ASSIGNMENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a node document assignment
 * @param {Object} nodeAssignment The node assignment object. Fields:
 * 'DNDNAME', 'FORM', 'EXT', 'DGIENAME', 'DGIDESC', 'OWNER'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function createNodeDocument(nodeDocument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: NODE_DOCUMENT_CREATE_ASSIGNMENT_START })

    let assignmentMessage = `${Lang.translate('definition.node_id')}: ${nodeDocument['DNDNAME']}, ${Lang.translate('general.form')}: ${nodeDocument['FORM']}`
    if (nodeDocument['EXT']) {
      assignmentMessage += `, ${Lang.translate('general.extension')}: ${nodeDocument['EXT']}`
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodedocumentassignment`, {
        method: 'post',
        body: ObjectUtils.removeByValue(nodeDocument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: NODE_DOCUMENT_CREATE_ASSIGNMENT_FAILED })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: NODE_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS })
          SnackbarActions.show(Lang.translate('assignment.node_document.create_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.node_document.create_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and updates a node document assignment
 * @param {Object} nodeAssignment The node assignment object. Fields:
 * 'DNDNAME', 'FORM', 'EXT', 'DGIENAME', 'DGIDESC', 'OWNER'
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function updateNodeDocument(nodeDocument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: NODE_DOCUMENT_UPDATE_ASSIGNMENT_START })

    let assignmentMessage = `${Lang.translate('definition.node_id')}: ${nodeDocument['DNDNAME']}, ${Lang.translate('general.form')}: ${nodeDocument['FORM']}`
    if (nodeDocument['EXT']) {
      assignmentMessage += `, ${Lang.translate('general.extension')}: ${nodeDocument['EXT']}`
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documentnodedocumentassignment`, {
        method: 'put',
        body: ObjectUtils.removeByValue(nodeDocument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: NODE_DOCUMENT_UPDATE_ASSIGNMENT_FAILED })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: NODE_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('assignment.node_document.modify_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.node_document.modify_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Gets the search values from preferences to refresh the search
 * @returns {Function}
 */
export const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const nodeID = prefs[Preferences.ASSIGNMENT_NODE_DOC_NODEID]
    const form = prefs[Preferences.ASSIGNMENT_NODE_DOC_FORM]
    const exension = prefs[Preferences.ASSIGNMENT_NODE_DOC_EXTENSION]

    getNodeDocumentAssignments(nodeID, form, exension, undefined, true)(dispatch)
  }
}