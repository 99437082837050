import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as Utils from 'utils/Utils'

import './CreateFolderDocumentAssignmentDialog.scss'

// components
import { Button, Card, Column, Icon, Input, Modal as ModalComponent, RelationAssignments, Row } from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import { createFolderDocumentAssignment, getDocuments } from 'redux/actions/FolderDocumentAssignmentAction'
// Redux
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'

const {Modal, Main, Header, Footer} = ModalComponent

class CreateFolderDocumentAssignmentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    form: {
      value: '',
      error: ''
    },
    extension: {
      value: '',
      error: ''
    },
    report: {
      value: '',
      error: ''
    },
    folderID: {
      value: '',
      error: ''
    },
    showFolderSelectorDialog: false,
    showDocumentSelectorDialog: false,
    assigned: {
      sortedCol: 0,
      data: [],
      origData: []
    },
    unassigned: {
      sortedCol: 0,
      data: []
    },
    selectedIds: [],
    draggingId: null
  }

  formInput = React.createRef()
  folderIdInput = React.createRef()

  componentDidMount = () => {
    this.folderIdInput.current.focus()
    window.addEventListener('click', this.onWindowClick)
  }

  /**
   * @description Removes the click listener which unselects items.
   */
  componentWillUnmount = () => {
    window.removeEventListener('click', this.onWindowClick)
  }

  /**
   * @description Updates the unassigned documents when new props occurs.
   */
  // componentWillReceiveProps(_, nextProps) {
  //   // save the new unassigned data based on the last search in state
  //   this.setState({
  //     unassigned: {
  //       ...this.state.unassigned,
  //       data: this.getUnassignedTableData(this.getSearchedDocumentIds(nextProps.documents))
  //     }
  //   })
  // }

  /**
   * @description Unselects all items.
   */
  unselectAll = (callback) => {
    const internalCallback = () => {
      if (callback) {
        callback()
      }
    }

    this.setState({
      selectedIds: []
    }, () => internalCallback())
  }

  /**
   * @description Unselects all items if the user clicked on the screen.
   * @param {Object} event The mouse event.
   */
  onWindowClick = (event) => {
    if (event.defaultPrevented) {
      return
    }

    this.unselectAll()
  }

  /**
   * @description gets the index of the header in redux state
   * @param {Object} data specific redux object
   * @param {String} header header name of the header in redux
   */
  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param val New value of inputfield
   */
  handleInputChanged = (key, val) => {
    this.setState({
      [key]: {
        value: val,
        error: ''
      }
    })
  }

  /**
   * @description Requests the index definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handleFolderSelector = () => {
    const { getFolderDefinitions } = this.props
    const { folderID } = this.state
    const callback = () => this.setState({ showFolderSelectorDialog: true })

    getFolderDefinitions(['GLRNAME', 'GLRTITLE'], folderID.value, callback)
  }

  /**
   * @description Requests the document definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  handleDocumentSelector = () => {
    const { getDocumentDefinitions } = this.props
    const { form, extension, report } = this.state
    const callback = () => this.setState({ showDocumentSelectorDialog: true })

    getDocumentDefinitions(['FORM', 'EXT', 'REPORT'], form.value, extension.value, report.value, callback)
  }

  /**
   * @description Renders the selector dialogs
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    const { showFolderSelectorDialog, showDocumentSelectorDialog } = this.state
    return (
      <>
        {showFolderSelectorDialog && (
          <SelectorDialog
            id={`${id}_folderdefinition_selector_dialog`}
            onClose={() => this.setState({ showFolderSelectorDialog: false })}
            title={translate('definition.folderdefinitions')}
            header={[
              translate('definition.folder_id'),
              translate('general.title'),
            ]}
            items={selector.folders.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const folderID = selector.folders.data[selectedRows][this.headerData(selector.folders, 'GLRNAME')]
                this.setState({ folderID: { value: folderID } })
              }
              this.setState({ showFolderSelectorDialog: false })
            }}
          />
        )}
        {showDocumentSelectorDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report')
            ]}
            items={selector.documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const form = selector.documents.data[selectedRows][this.headerData(selector.documents, 'FORM')]
                const extension = selector.documents.data[selectedRows][this.headerData(selector.documents, 'EXT')]
                const report = selector.documents.data[selectedRows][this.headerData(selector.documents, 'REPORT')]
                this.setState({ form: { value: form }, extension: { value: extension }, report: { value: report } })
              }
              this.setState({ showDocumentSelectorDialog: false })
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Validates the folder id.
   */
  validateFolder = () => {
    const { folderID } = this.state
    if (folderID.value !== '') {
      return {}
    }
    return {
      folderID: {
        ...this.state.folderID,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = {
      ...this.validateFolder()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input which has an error.
   */
  handleFocus = () => {
    const { form, folderID } = this.state
    const requiredInputs = [
      { inputRef: this.formInput, error: form.error },
      { inputRef: this.folderIdInput, error: folderID.error },
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Gets the unassigned table data.
   */
  getUnassignedTableData = () => {
    const { documents } = this.props
    return documents.data
  }

  /**
   * @description Handles the search button
   */
  handleSearch = () => {
    const { getDocuments } = this.props
    const { form, extension, report } = this.state

    const getDocumentsCallback = (documents) => {
      this.setState({
        unassigned: {
          ...this.state.unassigned,
          data: documents?.data ?? []
        }
      })
    }
    getDocuments(['FORM', 'EXT', 'REPORT'], form.value, extension.value, report.value, getDocumentsCallback)
  }

  /**
   * @description Creates the entry
   */
  handleSave = () => {
    const { createFolderDocumentAssignment, onClose } = this.props
    const { folderID, assigned } = this.state
    if (this.validateInputs()) {
      for (let i = 0; i < assigned.data.length; i++) {
        const folderDef = {
          GLRNAME: folderID.value,
          FORM: assigned.data[i][0],
          EXT: assigned.data[i][1],
          REPORT: assigned.data[i][2],
          WREPORT: '*'
        }
        if (i === assigned.data.length - 1) {
          createFolderDocumentAssignment(folderDef, onClose)
        } else {
          createFolderDocumentAssignment(folderDef)
        }
      }

    }
  }

  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.create_assignment')} ${translate('general.folder_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'folder_document_relation_modal_title'} name={'relation'} tabIndex={-1}/>,
      <div key={'title_3'}>{`${translate('general.document_modal_title')}  ${translate('general.assignment_modal_title')}`}</div>,
    ]
  }

  /**
   * @description Reorders an item inside a list.
   * @param {Array} list The list.
   * @param {Number} startIndex The source index of the item to reorder.
   * @param {Number} endIndex The destination index.
   * @return {Array} The reordered list.
   */
  reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  /**
   * @description Moves an item from a list to another.
   * @param {Array} source The source list.
   * @param {Array} destination The destination list.
   * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
   * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
   * @returns {Object} An object with the new source and destination lists.
   */
  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = [...source]
    const destClone = [...destination]
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  /**
   * @description Sets the width and visibility properties for group dragging.
   * @param {Object} dragStart The dragStart object.
   */
  onBeforeDragStart = (dragStart) => {
    // get the id which is dragged
    let id = this.state[dragStart.source.droppableId].data[dragStart.source.index]
    const selected = this.state.selectedIds.find((headerId) => headerId === id)

    let newHeaderWidth = '0px'
    let visibility = 'hidden'

    if (selected) {
      // set the width of the grouping items based on the item which will be dragged
      if (document.querySelector(`#${dragStart.draggableId}`)) {
        newHeaderWidth = `${document.querySelector(`#${dragStart.draggableId}`).getBoundingClientRect().width}px`
        visibility = 'visible'
      }
      this.setState({ draggingId: id })
    } else {
      // if draggin an item that is not selected - unselect all items
      this.setState({ draggingId: id, selectedIds: [] })
    }

    document.querySelector(':root').style.setProperty('--row-width', newHeaderWidth)
    document.querySelector(':root').style.setProperty('--row-visibility', visibility)
  }

  /**
   * @description Performes the single drag action.
   * @param {Object} result Includes the new lists.
   */
  singleDrag = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      this.setState({ draggingId: null })
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorder(
        this.state[source.droppableId].data,
        source.index,
        destination.index
      )

      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: items
        },
        draggingId: null
      })
      // move if the dnd was between different lists
    } else {
      const result = this.move(
        this.state[source.droppableId].data,
        this.state[destination.droppableId].data,
        source,
        destination
      )

      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: result[source.droppableId]
        },
        [destination.droppableId]: {
          ...this.state[destination.droppableId],
          data: result[destination.droppableId]
        },
        draggingId: null
      })
    }
  }

  /**
 * @description Performes the multi drag action.
 * @param {Object} result Includes the new lists.
 */
  multiDrag = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      this.setState({ draggingId: null })
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorderMulti(
        this.state[source.droppableId].data,
        source.index,
        destination.index
      )

      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: items
        },
        draggingId: null
      })
      // move if the dnd was between different lists
    } else {
      const result = this.moveMulti(
        this.state[source.droppableId].data,
        this.state[destination.droppableId].data,
        source,
        destination
      )

      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: result[source.droppableId]
        },
        [destination.droppableId]: {
          ...this.state[destination.droppableId],
          data: result[destination.droppableId]
        },
        draggingId: null,
        selectedIds: result['orderedSelectedIds']
      })
    }
  }

  /**
 * @description Reorders multiple items.
 * @param {Array} list The list.
 * @param {Number} startIndex The source start index.
 * @param {Number} endIndex The destination index.
 */
  reorderMulti = (list, startIndex, endIndex) => {
    // do nothing when startindex and endindex are equal
    if (startIndex === endIndex) {
      return list
    }

    let result = [...list]

    const insertAtIndex = (() => {
      // gets the offset of the index where to insert the items
      const destinationIndexOffset = this.state.selectedIds.reduce((previous, current) => {
        const index = list.indexOf(current)

        if (current === this.state.draggingId) {
          if (index > endIndex && previous > 0) {
            return previous - 1
          } else {
            return previous
          }
        }

        if (index > endIndex) {
          return previous
        }

        return previous + 1
      }, 0)

      return endIndex - destinationIndexOffset
    })()

    const orderedSelectedHeaders = [...this.state.selectedIds]
    orderedSelectedHeaders.sort((a, b) => {

      const indexOfA = list.indexOf(a)
      const indexOfB = list.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      // sorting by their order in the selected headers
      return -1
    })

    result = result.filter((id) => {
      return !this.state.selectedIds.includes(id)
    })

    result.splice(insertAtIndex, 0, ...orderedSelectedHeaders)

    return result
  }

  /**
   * @description Moves items from a list to another.
   * @param {Array} source The source list.
   * @param {Array} destination The destination list.
   * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
   * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
   * @returns {Object} An object with the new source and destination lists.
   */
  moveMulti = (source, destination, droppableSource, droppableDestination) => {
    let sourceClone = [...source]
    const destClone = [...destination]
    sourceClone = sourceClone.filter((id) => {
      return !this.state.selectedIds.includes(id)
    })

    const orderedSelectedHeaders = [...this.state.selectedIds]
    orderedSelectedHeaders.sort((a, b) => {
      const indexOfA = source.indexOf(a)
      const indexOfB = source.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      // sorting by their order in the selected headers
      return -1
    })

    destClone.splice(droppableDestination.index, 0, ...orderedSelectedHeaders)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    result['orderedSelectedIds'] = orderedSelectedHeaders

    return result
  }

  /**
   * @description The drag end action from dnd.
   * @param {Object} result The result from dnd.
   */
  onDragEnd = (result) => {
    // multi drag
    if (this.state.selectedIds.length > 1) {
      this.multiDrag(result)
    } else {
      // single drag
      this.singleDrag(result)
    }
  }

  /**
   * @description Toggles a selection of an item.
   * @param {String} id The id of the data to select or unselect.
   */
  toggleSelection = (id) => {
    let newSelectedIds = []
    if (!this.state.selectedIds.includes(id)) {
      newSelectedIds = [id]
    } else if (this.state.selectedIds.length > 1) {
      // was part of a seleted group -> will now become the only selected item
      newSelectedIds = [id]
    } else {
      // was previously selected but not in a group -> will now clear the selection
      newSelectedIds = []
    }

    this.setState({
      selectedIds: newSelectedIds
    })
  }

  /**
   * @description Toggles the selection in group (with ctrl).
   * @param {String} id The id of the data which was clicked.
   */
  toggleSelectionInGroup = (id) => {
    const index = this.state.selectedIds.indexOf(id)

    // if not selected -> add it to the selected items
    if (index === -1) {
      this.setState({ selectedIds: [...this.state.selectedIds, id] })
    } else {
      // item was previously selected -> remove it from the group
      const shallow = [...this.state.selectedIds]
      shallow.splice(index, 1)
      this.setState({ selectedIds: shallow })
    }
  }

  /**
   * @description Selects all elements between the last selected item and the item to select.
   * @param {Array} data The data.
   * @param {String} id The id of the data to select.
   */
  multiSelectTo = (data, id) => {
    const updated = this.multiSelect(data, id)

    if (updated === null) {
      return
    }

    this.setState({ selectedIds: updated })
  }

  /**
   * @description Multi selects items with shift.
   * @param {Array} data The data.
   * @param {String} id The id of the data which was clicked.
   * @returns {Array} The new selected headers or null.
   */
  multiSelect = (data, newId) => {
    if (!this.state.selectedIds.length) {
      return [newId]
    }

    const indexOfNew = data.indexOf(newId)
    const indexOfLast = data.indexOf(this.state.selectedIds[this.state.selectedIds.length - 1])

    if (indexOfNew === indexOfLast) {
      return null
    }

    const isSelectingForwards = indexOfNew > indexOfLast
    const start = isSelectingForwards ? indexOfLast : indexOfNew
    const end = isSelectingForwards ? indexOfNew : indexOfLast

    const inBetween = data.slice(start, end + 1)

    // add headers which are between the last selected item and the clicked item
    // if items between are already selected do nothing
    const toAdd = inBetween.filter((id) => {
      if (Utils.includesArray(this.state.selectedIds, id)) {
        return false
      }
      return true
    })

    const combined = [...this.state.selectedIds, ...toAdd]

    return combined
  }

  /**
   * @description Renders the datatables for dnd
   */
  renderTable = () => {
    const { id } = this.props

    const available = {
      title: translate('general.search_results'),
      data: this.state.unassigned.data,
      headers: [
        translate('general.form'),
        translate('general.extension'),
        translate('general.report')
      ],
      columnSortDefs: ['string', 'string', 'string'],
      onSortEnd: (newData, sortedCol) => { this.setState({ unassigned: { sortedCol: sortedCol, data: newData } }) },
      sortedCol: this.state.unassigned.sortedCol,
      droppableID: 'unassigned'
    }

    const chosen = {
      title: translate('general.selected_documents'),
      data: this.state.assigned.data,
      headers: [
        translate('general.form'),
        translate('general.extension'),
        translate('general.report')
      ],
      columnSortDefs: ['string', 'string', 'string'],
      onSortEnd: (newData, sortedCol) => { this.setState({ assigned: { sortedCol: sortedCol, data: newData } }) },
      sortedCol: this.state.assigned.sortedCol,
      droppableID: 'assigned'
    }

    return (
      <RelationAssignments
        id={`${id}_relation_assignments`}
        available={available}
        chosen={chosen}
        onBeforeDragStart={this.onBeforeDragStart}
        onDragEnd={this.onDragEnd}
        selectedIds={this.state.selectedIds}
        draggingId={this.state.draggingId}
        toggleSelection={this.toggleSelection}
        toggleSelectionInGroup={this.toggleSelectionInGroup}
        multiSelectTo={this.multiSelectTo}
        unselectAll={this.unselectAll}
        translate={key => translate(key)}
      />
    )
  }

  render = () => {
    const { id, onClose } = this.props
    const { form, extension, report, folderID } = this.state
    return (
      <>
        {this.renderSelectorDialogs()}
        <Modal
          id='create_folder_document_assignment_dialog'
          className='bux_UserProfileModal'
          onClose={onClose}
          size={'l'}>
          <Header
            id={id}
            title={this.getModalTitle()}
            onClose={onClose}
          />
          <Main id={id}>
            <Card id={id} className={'bux_card_flex'}>
              <Row>
                <Column colMD={6}>
                  <Input
                    id={`${id}_folderid`}
                    onInputChanged={(val) => {
                      this.handleInputChanged('folderID', val)
                    }}
                    value={folderID.value}
                    title={translate('assignment.folder_document.folderid')}
                    ref={this.folderIdInput}
                    error={folderID.error && translate(folderID.error)}
                    maxLength={32}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleFolderSelector(),
                    }
                    }
                    onBlur={() => this.setState({ ...this.validateFolder() })}
                    required={`${translate('general.required_field')}`}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={12}>
                  <hr />
                </Column>
              </Row>
              <Row>
                <Column colMD={3}>
                  <Input
                    id={`${id}_form`}
                    onInputChanged={(val) => { this.handleInputChanged('form', val) }}
                    value={form.value}
                    title={translate('general.form')}
                    maxLength={8}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }
                    }
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_extension`}
                    onInputChanged={(val) => { this.handleInputChanged('extension', val) }}
                    value={extension.value}
                    title={translate('general.extension')}
                    maxLength={16}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }
                    }
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_report`}
                    onInputChanged={(val) => { this.handleInputChanged('report', val) }}
                    value={report.value}
                    title={translate('general.report')}
                    maxLength={16}
                    addon={{
                      iconName: 'list',
                      onClick: () => this.handleDocumentSelector(),
                    }
                    }
                  />
                </Column>
                <Column colMD={3}>
                  <Button
                    id={`${id}_searchbtn`}
                    text={translate('general.search')}
                    onClick={this.handleSearch}
                    className={'bux_align_right bux_mt18'}
                    primary
                  />
                </Column>
              </Row>
              {this.renderTable()}
            </Card>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              disabled={this.state.assigned.data.length === 0}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }

}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    selector: state.selector,
    documents: state.assignments.folderdocumentassignment.documents
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getFolderDefinitions: (fields, foldername, callback) => {
      ModalSelectorActions.getFolderDefinitions(
        fields,
        foldername,
        undefined, // folder title
        undefined, // owner
        undefined, // brw
        callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    createFolderDocumentAssignment: (folderDef, callback) => {
      createFolderDocumentAssignment(folderDef, callback)(dispatch)
    },
    getDocuments: (fields, form, extension, report, callback) => {
      getDocuments(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        undefined,
        undefined,
        callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFolderDocumentAssignmentDialog)