import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Form extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /**
     * Function to be called on an submit event.
     * @param event
     */
    onSubmit: PropTypes.func.isRequired,
    /** Style class from CSS for styling for button.*/
    className: PropTypes.string
  }

  /**
   * @description Renders the component
   */
  render = () => {
    const { id, onSubmit, className, children } = this.props
    return (
      <form
        id={id}
        onSubmit={event => onSubmit(event)}
        className={className ? className : ''}>
        {children}
      </form>
    )
  }
}


export default Form