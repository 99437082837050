import React, { Component } from 'react';
import * as d3 from 'd3';
import propTypes from 'prop-types';
import './BarChart.scss';
import moment from 'moment';
import { equalsArrays } from '../../../utils/Utils';

export default class BarChart extends Component {
  stillUpdate = false;

  state = {
    margin: {
      top: 40,
      right: 40,
      bottom: 60,
      left: 80
    },
    width: 0,
    height: 0,
    animations: true,
  };

  /**
   * @description Added the event which recalculate the chart when resize the window.
   */
  componentDidMount = () => {
    const { id, width, height } = this.props;
    const {
      margin: {
        top,
        right,
        bottom,
        left
      }
    } = this.state;
    const el = document.querySelector(`#barChart${id}`);
    window.addEventListener('resize', this.handleUpdate);
    requestAnimationFrame(() => {
      this.setState({
        aminations: true,
        width: width - left - right || (el.offsetWidth - 0.01 * el.offsetWidth) - left - right,
        height: height - top - bottom || el.offsetHeight - top - bottom - 1
      }, this.drawChart);
    });
  };

  /**
   * @description Removes the event which recalculate the chart when resize the window.
   */
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleUpdate);
  };

  /**
   * @description Updates the chart information when datemask or char data has changed.
   */
  componentDidUpdate = prevProps => {
    // updates chart when datemask or language has changed
    if (prevProps.dateFormat !== this.props.dateFormat || !equalsArrays(prevProps.keys, this.props.keys) || !equalsArrays(prevProps.data, this.props.data)) {
      d3.select(`#barChart${this.props.id}`)
        .select('svg')
        .remove();
      this.drawChart();
    }
    // will be executed when drawer expand/collapse
    if (this.props.drawerExpanded !== prevProps.drawerExpanded) {
      this.handleUpdate();
    }
  };

  /**
   * @description Added the interval for updating chart (e.g when drawer expand/collapse)
   */
  handleUpdate = () => {
    const chartUpdater = setInterval(this.updateChart, 1);
    setTimeout(() => this.stopUpdater(chartUpdater), 300);
  };

  /**
   * @description Stops the interval for updating the chart when expand/collapse drawer
   */
  stopUpdater = timer => {
    clearInterval(timer);
    this.updateChart();
    this.stillUpdate = false;
  };

  /**
   * @description Updates teh chart.
   */
  updateChart = () => {
    const { id } = this.props;
    const {
      margin: {
        top,
        right,
        bottom,
        left
      }
    } = this.state;
    const el = document.querySelector(`#barChart${id}`);
    d3.select(`#barChart${id}`)
      .select('svg')
      .remove();
    this.setState({
      animations: false,
      width: (el.offsetWidth - 0.01 * el.offsetWidth) - left - right,
      height: el.offsetHeight - top - bottom
    }, this.drawChart);
  };

  /**
   * @description Formats the numbers for better legibility (e.g. 154000 = 154.000).
   */
  formatValue = value => {
    const { language } = this.props;
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    switch (language) {
      case 'de': return value.toString().replace(regex, '.');
      case 'en': return value.toString().replace(regex, ',');
      case 'fr': return value.toString().replace(regex, ' ');
      default: return value;
    }
  };

  /**
 * @description Formats the input date into the given format.
 */
  formatDate = (input, timeFormat) => {
    const date = moment(input);
    return date.format(timeFormat.replace('%d', 'DD').replace('%m', 'MM').replace('%Y', 'YYYY'));
  };

  /**
   * @description Returns the datemask, which d3 needs to display it correctly in the chart.
   */
  getDatemask = () => {
    return this.props.dateFormat === undefined
      ? '%m/%d/%Y'
      : this.props.dateFormat.replace('MM', '%m').replace('DD', '%d').replace('YYYY', '%Y');
  };

  /**
   * @description Draws the chart.
   */
  drawChart = () => {
    const id = `#barChart${this.props.id}`;
    const { data, keys, barPadding, colors, barHover } = this.props;
    const bufferLines = [];
    let maxY = 0;

    let timeFormat = this.getDatemask();
    let changeTimeFormat = false;
    const parseTime = d3.timeParse('%d.%m.%Y');

    // select tooltip
    let tooltip = d3.select(id).selectAll(`#tooltip_barChart_${this.props.id}`);

    // create tooltip when no tooltip exists
    if (tooltip.empty()) {
      tooltip = d3.select(id)
        .append('div')
        .attr('class', 'tooltip_barChart')
        .attr('id', `tooltip_barChart_${this.props.id}`)
        .style('opacity', 0);
    }

    const svg = d3
      .select(id)
      .append('svg')
      .attr('width', this.state.width + this.state.margin.left + this.state.margin.right)
      .attr('height', this.state.height + this.state.margin.top + this.state.margin.bottom - 5);

    const legendHeight = (document.querySelector(id).offsetHeight - this.state.margin.top) / 2 - ((keys.length - 1) % 2 !== 0 ? ((keys.length - 2) * 90 + 40) / 2 : ((keys.length - 2) * 90) / 2);

    svg.append('svg')
      .append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(0, ${this.props.legend === 'left' ? legendHeight : 0})`);

    svg.append('svg')
      .attr('x', this.props.legend === 'left' ? 200 : 0)
      .attr('width', this.state.width + this.state.margin.left + this.state.margin.right - (this.props.legend === 'left' ? 200 : 0))
      .append('g')
      .attr('class', 'graph')
      .attr('transform', `translate(${this.state.margin.left}, ${this.state.margin.top})`);

    data.forEach((d, i) => {
      if (typeof d[0] === 'string') {
        if (d[0].length === 7) {
          if (!changeTimeFormat) {
            changeTimeFormat = true;
            if (timeFormat[1] === 'd') {
              timeFormat = timeFormat.substring(3, 8);
            }
            else if (timeFormat.indexOf('.') === 2) {
              if (timeFormat[1] === 'm') {
                timeFormat = '%m.%Y';
              }
              else if (timeFormat[1] === 'Y') {
                timeFormat = '%Y.%m';
              }
            }
            else if (timeFormat.indexOf('/') === 2) {
              if (timeFormat[1] === 'm') {
                timeFormat = '%m/%Y';
              }
              else if (timeFormat[1] === 'Y') {
                timeFormat = '%Y/%m';
              }
            }
            else if (timeFormat.indexOf('-') === 2) {
              if (timeFormat[1] === 'm') {
                timeFormat = '%m-%Y';
              }
              else if (timeFormat[1] === 'Y') {
                timeFormat = '%Y-%m';
              }
            }
          }
          data[i][0] = `01.${d[0]}`;
        }
        else if (d[0].length === 4) {
          data[i][0] = `01.01.${d[0]}`;
          timeFormat = '%Y';
        }
        data[i][0] = parseTime(d[0]);
      }
      for (let j = 1; j < data[0].length; j++) {
        data[i][j] = +d[j];
        if (d[j] > maxY) {
          maxY = d[j];
        }
      }
    });

    if (this.props.direction === 'vertical' || this.props.direction === undefined) {

      const xScale = d3
        .scaleBand()
        .rangeRound([0, this.state.width - (this.props.legend === 'left' ? 200 : 0)])
        .padding(barPadding);

      const yScale = d3.scaleLinear()
        .domain([0, maxY])
        .range([this.state.height, 0]);

      const xAxis = d3.axisBottom().scale(xScale).tickFormat(d3.timeFormat(timeFormat));

      const yAxis = d3
        .axisLeft()
        .scale(yScale);

      // xScale.domain(data.map(d => d.letter))
      // yScale.domain([0, d3.max(data, d => d.value)])

      xScale.domain(data.map(d => d[0]));
      yScale.domain([0, maxY]);

      // create x axis with text
      svg.select('.graph')
        .append('g')
        .style('font-size', '12px')
        .attr('class', 'x axis')
        .attr('transform', `translate(0, ${this.state.height})`)
        .call(xAxis);

      // create y axis with text
      svg.select('.graph')
        .append('g')
        .style('font-size', '12px')
        .attr('class', 'y axis')
        .call(yAxis.tickFormat(value => this.formatValue(value)))
        .append('text');

      // if user wants to show grid
      if (this.props.showGrid) {
        svg.select('.graph')
          .append('g')
          .attr('class', 'grid')
          .call(
            d3
              .axisLeft()
              .scale(yScale)
              .tickSize(-this.state.width + (this.props.legend === 'left' ? 200 : 0), 0, 0)
              .tickFormat('')
          )
          .attr('stroke', '#010514')
          .attr('stroke-width', 1)
          .style('opacity', 0.5)
          .select('g')
          .remove(); // remove bottom line from the grid

        // remove top line
        svg.select('.grid')
          .selectAll('path')
          .remove('path');
      }
      const barWidth = xScale.bandwidth() / data[0].length;

      for (let barNr = 1; barNr < data[0].length; barNr++) {
        let barCounter = -1;
        const dataline = [];
        for (let j = 0; j < data.length; j++) {
          const fusionArray = [];
          fusionArray[0] = data[j][0];
          fusionArray[1] = data[j][barNr];
          fusionArray[2] = j;
          dataline.push(fusionArray);
        }
        bufferLines.push(dataline);

        // add key to legend
        if (this.props.legend === 'top') {
          svg.select('.legend')
            .append('rect')
            .attr('fill', (barNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[barNr - 1])
            .attr('height', 10)
            .attr('width', 40)
            .attr('x', () => {
              let addSpace = 0;
              for (let i = 0; i < barNr - 1; i++) {
                addSpace += keys[i].length * 9;
              }
              return this.state.margin.left + ((barNr - 1) * 50) + addSpace + (barNr - 1) * 20;
            })
            .attr('y', 10);

          svg.selectAll('.legend').append('text')
            .text(keys[barNr - 1])
            .attr('font-weight', 'bold')
            .attr('fill', (barNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[barNr - 1])
            .attr('x', () => {
              let addSpace = 0;
              for (let i = 0; i < barNr - 1; i++) {
                addSpace += keys[i].length * 9;
              }
              return this.state.margin.left + ((barNr - 1) * 50) + 50 + addSpace + (barNr - 1) * 20;
            })
            .attr('y', 20);
        }
        else if (this.props.legend === 'left') {
          svg.select('.legend')
            .append('rect')
            .attr('fill', (barNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[barNr - 1])
            .attr('height', 10)
            .attr('width', 40)
            .attr('y', () => (barNr - 1) * 50);

          svg.selectAll('.legend').append('text')
            .text(keys[barNr - 1])
            .attr('font-weight', 'bold')
            .attr('fill', (barNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[barNr - 1])
            .attr('x', 50)
            .attr('y', () => (barNr - 1) * 50 + 9);
        }

        // add bars
        svg.select('.graph')
          .append('g')
          .selectAll('g')
          .data(bufferLines[barNr - 1])
          .enter()
          .append('rect')
          .attr('class', () => {
            barCounter++;
            return `bar barkey_${this.props.id} barkey_${this.props.id}_${barNr - 1} bar_${barCounter} bar_${barNr - 1}_${barCounter}`;
          })
          .style('fill', () => (barNr - 1 > colors.length) ? colors[colors.length - 1] : colors[barNr - 1])
          .attr('x', d => xScale(d[0]) + (barNr - 1) * barWidth + barWidth / 2)
          .attr('width', barWidth)
          .attr('y', this.state.height)
          // eslint-disable-next-line
          .on('mouseover', (event, d) => {
            const i = d[2];
            tooltip.style('display', 'flex');
            tooltip
              .transition()
              .duration(200)
              .style('opacity', 1);
            if (barHover === 'grouped') {
              d3.selectAll(`.barkey_${this.props.id}`).style('opacity', 0.8);
              tooltip.selectAll('span').remove();
              tooltip.append('span')
                .text(this.formatDate(d[0], timeFormat))
                .style('color', '#010514')
                .style('font-weight', 'bold');
              bufferLines.forEach((d, j) => {
                tooltip.append('span')
                  .text(`${keys[j]}: ${this.formatValue(d[i][1])}`)
                  .style('color', colors[j]);
              });
            }
            else if (barHover === 'one') {
              d3.select(`.barkey_${this.props.id}_${barNr - 1}`).style('opacity', 0.8);
              tooltip.selectAll('span').remove();
              tooltip.append('span')
                .text(this.formatDate(d[0], timeFormat))
                .style('color', '#010514')
                .style('font-weight', 'bold');
              tooltip.append('span')
                .text(`${keys[barNr - 1]}: ${this.formatValue(d[1])}`)
                .style('color', colors[barNr - 1]);
            }

            const tooltipDiv = document.querySelector(`#tooltip_barChart_${this.props.id}`);
            const tooltipDivWidth = tooltipDiv.offsetWidth;
            const tooltipDivHeight = tooltipDiv.offsetHeight;
            const el = document.querySelector(id);
            const xPosition = event.clientX;
            const yPosition = event.clientY;
            tooltip
              .style('left', `${xPosition + tooltipDivWidth <= el.offsetWidth - 5 ? xPosition : xPosition - tooltipDivWidth}px`)
              .style('top', `${yPosition + tooltipDivHeight <= el.offsetHeight - 5 ? yPosition + 20 : yPosition - tooltipDivHeight - 3}px`);
          })
          .on('mousemove', (event) => {
            const tooltipDiv = document.querySelector(`#tooltip_barChart_${this.props.id}`);
            const tooltipDivWidth = tooltipDiv.offsetWidth;
            const tooltipDivHeight = tooltipDiv.offsetHeight;
            const el = document.querySelector(id);
            const xPosition = event.clientX;
            const yPosition = event.clientY;
            tooltip
              .style('left', `${xPosition + tooltipDivWidth <= el.offsetWidth - 5 ? xPosition : xPosition - tooltipDivWidth}px`)
              .style('top', `${yPosition + tooltipDivHeight <= el.offsetHeight - 5 ? yPosition + 20 : yPosition - tooltipDivHeight - 3}px`);

          })
          .on('mouseout', (_, i) => {
            if (barHover === 'grouped') {
              d3.selectAll(`.bar_${barNr}`) && d3.selectAll(`.bar_${barNr}`).style('opacity', 1);
            }
            else if (barHover === 'one') {
              d3.select(`.barkey_${this.props.id}_${barNr - 1}`).style('opacity', 1);
            }
            tooltip
              .transition()
              .duration(500)
              .style('opacity', 0)
              .on('end', () => {
                tooltip.style('display', 'none');
              });
          });
        if (this.state.animations) {
          svg
            .selectAll('.bar')
            .attr('transform', `translate(0,${this.state.height * 2}) scale(1,-1)`)
            .transition()
            .duration(1000)
            // min height for a bar is 2px so you user can see the tooltip
            .attr('height', d => this.state.height - yScale(d[1]) > 2 ? this.state.height - yScale(d[1]) : 2);
        }
        else {
          svg
            .selectAll('.bar')
            .attr('transform', `translate(0,${this.state.height * 2}) scale(1,-1)`)
            // min height for a bar is 2px so you user can see the tooltip
            .attr('height', d => this.state.height - yScale(d[1]) > 2 ? this.state.height - yScale(d[1]) : 2);
        }
      }
    }
    else if (this.props.direction === 'horizontal') {
      const yScale = d3
        .scaleBand()
        .rangeRound([0, this.state.height])
        .padding(barPadding);

      const xScale = d3
        .scaleLinear()
        .range([0, this.state.width - (this.props.legend === 'left' ? 200 : 0)]);

      const yAxis = d3
        .axisLeft()
        .scale(yScale)
        .tickFormat(d3.timeFormat(timeFormat));

      const xAxis = d3
        .axisBottom()
        .scale(xScale);

      // xScale.domain([0, d3.max(data, d => d.value)])
      // yScale.domain(data.map(d => d.letter))

      xScale.domain([0, maxY]);
      yScale.domain(data.map(d => d[0]));

      svg.select('.graph')
        .append('g')
        .attr('class', 'x axis')
        .call(xAxis.tickFormat(value => this.formatValue(value)))
        .attr('transform', `translate(0, ${this.state.height})`);

      svg.select('.graph')
        .append('g')
        .attr('class', 'y axis')
        .call(yAxis);

      // if user wants to show grid
      if (this.props.showGrid) {
        svg.select('.graph')
          .append('g')
          .attr('class', 'grid')
          .call(
            d3
              .axisBottom()
              .scale(xScale)
              .tickSize(-this.state.height, 0, 0)
              .tickFormat('')
          )
          .attr('stroke', '#010514')
          .attr('stroke-width', 1)
          .attr('opacity', '0.5')
          .attr('transform', `translate(0, ${this.state.height})`)
          .select('g')
          .remove(); // remove bottom line from the grid

        // remove top line
        svg.select('.graph')
          .select('.grid')
          .selectAll('path')
          .remove('path');
      }

      const barWidth = yScale.bandwidth() / data[0].length;

      for (let barNr = 1; barNr < data[0].length; barNr++) {
        let barCounter = - 1;
        const dataline = [];
        for (let j = 0; j < data.length; j++) {
          const fusionArray = [];
          fusionArray[0] = data[j][0];
          fusionArray[1] = data[j][barNr];
          fusionArray[2] = j;
          dataline.push(fusionArray);
        }
        bufferLines.push(dataline);
        // add key to legend
        if (this.props.legend === 'top') {
          svg.select('.legend')
            .append('rect')
            .attr('fill', (barNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[barNr - 1])
            .attr('height', 10)
            .attr('width', 40)
            .attr('x', () => {
              let addSpace = 0;
              for (let i = 0; i < barNr - 1; i++) {
                addSpace += keys[i].length * 9;
              }
              return this.state.margin.left + ((barNr - 1) * 50) + addSpace + (barNr - 1) * 20;
            })
            .attr('y', 10);
        }
        else if (this.props.legend === 'left') {
          svg.select('.legend')
            .append('rect')
            .attr('fill', (barNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[barNr - 1])
            .attr('height', 10)
            .attr('width', 40)
            .attr('y', () => (barNr - 1) * 50);

          svg.selectAll('.legend').append('text')
            .text(keys[barNr - 1])
            .attr('font-weight', 'bold')
            .attr('fill', (barNr - 1 > colors.length - 1) ? colors[colors.length - 1] : colors[barNr - 1])
            .attr('x', 50)
            .attr('y', () => (barNr - 1) * 50 + 9);
        }

        svg.select('.graph')
          .append('g')
          .selectAll('g')
          .data(dataline)
          .enter()
          .append('rect')
          .attr('class', () => {
            barCounter++;
            return `bar barkey_${this.props.id} barkey_${this.props.id}_${barNr - 1} bar_${barCounter} bar_${barNr - 1}_${barCounter}`;
          })
          .style('fill', () => (barNr - 1 > colors.length) ? colors[colors.length - 1] : colors[barNr - 1])
          .attr('x', 1)
          .attr('y', d => yScale(d[0]) + (barNr - 1) * barWidth + barWidth / 2)
          .attr('height', barWidth)
          .attr('fill', '#0b62a4')
          // eslint-disable-next-line
          .on('mouseover', (event, data_i) => {
            tooltip
              .transition()
              .style('display', 'flex')
              .duration(200)
              .style('opacity', 1);
            if (barHover === 'grouped') {

              d3.selectAll(`.barkey_${this.props.id}`).style('opacity', 0.8);
              tooltip.selectAll('span').remove();
              tooltip.append('span')
                .text(this.formatDate(data_i[0], timeFormat))
                .style('color', '#010514')
                .style('font-weight', 'bold');
              bufferLines.forEach((d, j) => {
                const dataIndex = data_i[2];
                tooltip.append('span')
                  .text(`${keys[j]}: ${this.formatValue(d[dataIndex]?.[1])}`)
                  .style('color', colors[j]);
              });
            }
            else if (barHover === 'one') {
              d3.select(`.barkey_${this.props.id}_${barNr - 1}`).style('opacity', 0.8);
              tooltip.selectAll('span').remove();
              tooltip.append('span')
                .text(this.formatDate(data_i[0], timeFormat))
                .style('color', '#010514')
                .style('font-weight', 'bold');
              tooltip.append('span')
                .text(`${keys[barNr - 1]}: ${this.formatValue(data_i[1])}`)
                .style('color', colors[barNr - 1]);
            }
          })
          .on('mousemove', (event) => {
            tooltip
              .style('left', `${event.clientX + 5}px`)
              .style('top', `${event.clientY - 28}px`);
          })
          .on('mouseout', (_, i) => {
            if (barHover === 'grouped') {
              d3.selectAll(`.bar_${barNr}`).style('opacity', 1);
            }
            else if (barHover === 'one') {
              d3.select(`.barkey_${this.props.id}_${barNr - 1}`).style('opacity', 1);
            }
            tooltip
              .transition()
              .duration(500)
              .style('opacity', 0)
              .on('end', () => {
                tooltip.style('display', 'none');
              });
          });
        if (this.state.animations) {
          svg
            .selectAll('.bar')
            .transition()
            .duration(1000)
            .attr('width', d => xScale(d[1]));
        }
        else {
          svg
            .selectAll('.bar')
            .attr('width', d => xScale(d[1]));
        }
      }
    }
  };

  render() {
    return <div className={'barChart'} id={`barChart${this.props.id}`} />;
  }
}

BarChart.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: propTypes.string.isRequired,
  /**
   * Array of array of data.
   * The first element of array of data should contains label, rest value which should be represents by bar.
   *
   * ```js
   * [
   *  ["label", 10, 12, ...],
   *  ["label2", 0, 2, ...],
   *  ...
   * ]
   * ```
   */
  data: propTypes.arrayOf(propTypes.array).isRequired,
  /** Name of the value passed after label to the _data_, to show it in legend.*/
  keys: propTypes.arrayOf(propTypes.string).isRequired,
  /**
   * The colors are used to colorize the bars in the bar chart.
   * Each color in this array will be used for one bar.
   * If you define less colors then bars you have, the last color will be used for each additional bar.
   */
  colors: propTypes.arrayOf(propTypes.string),
  /**
   * The barPadding determines the space between the bar blocks.
   * The bigger the padding the smaller the bars will be.
   */
  barPadding: propTypes.number.isRequired,
  /** Defines behavior on bar hover */
  barHover: propTypes.oneOf(['grouped', 'one']).isRequired,
  /** Determines the direction of the bar chart. */
  direction: propTypes.string.isRequired,
  /** Date format of the date passed in _data_ */
  dateFormat: propTypes.string.isRequired,
  /** The width defines the width of the svg element which displays the bar chart. */
  width: propTypes.number,
  /** The height defines the height of the svg element which displays the bar chart. */
  height: propTypes.number,
  /**
   * Functionality connected with the _Drawer_ component.
   * When the drawer is expanded and a row is checked, the chart re-render.
   */
  drawerExpanded: propTypes.oneOfType([propTypes.bool, propTypes.string]),
  /** You can show lines in the bar chart if you prefer. */
  showGrid: propTypes.bool,
  /** Used for the locale of the barchart. */
  language: propTypes.oneOf(['de', 'en', 'fr']),
  /** Set position of the legend */
  legend: propTypes.oneOf(['left', 'top']),
};