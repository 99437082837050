import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

export const LOG_DEFINITION_GET_LOGS_START = 'LOG_DEFINITION_GET_LOGS_START'
export const LOG_DEFINITION_GET_LOGS_SUCCESS = 'LOG_DEFINITION_GET_LOGS_SUCCESS'
export const LOG_DEFINITION_GET_LOGS_FAILED = 'LOG_DEFINITION_GET_LOGS_FAILED'

export const LOG_DEFINITION_GET_LOG_START = 'LOG_DEFINITION_GET_LOG_START'
export const LOG_DEFINITION_GET_LOG_SUCCESS = 'LOG_DEFINITION_GET_LOG_SUCCESS'
export const LOG_DEFINITION_GET_LOG_FAILED = 'LOG_DEFINITION_GET_LOG_FAILED'

export const LOG_DEFINITION_VERIFY_START = 'LOG_DEFINITION_VERIFY_START'
export const LOG_DEFINITION_VERIFY_SUCCESS = 'LOG_DEFINITION_VERIFY_SUCCESS'
export const LOG_DEFINITION_VERIFY_FAILED = 'LOG_DEFINITION_VERIFY_FAILED'

export const NO_LOG_DEFINITION_LOGS_FOUND = 'NO_DEFINITION_LOGS_FOUND'

/**
 * @description Calling the rest api and do getLogs request.
 * @param {Object} fields The fields to select (If undefined -> Return all fields). Possible fields:
 * 'FORM', 'EXT', 'REPORT', 'SMODE', 'BMODE', 'LTITLE', 'OWNER', 'CDATE', 'CTIME'
 * @param {String} host The host pattern.
 * @param {String} application The extention pattern.
 * @param {String} subLog The subLog pattern.
 * @param {String} process The process mode. Possible Values:
 * 'LIST', 'REPORT', 'ALL' or ''
 * @param {String} owner The owner pattern.
 * @param {String} title The title pattern.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getLogs(fields, host, application, subLog, process, owner, title, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: LOG_DEFINITION_GET_LOGS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (host) { queryParams.push(`FORM=${encodeURIComponent(host)}`) }
    if (application) { queryParams.push(`EXT=${encodeURIComponent(application)}`) }
    if (subLog) { queryParams.push(`REPORT=${encodeURIComponent(subLog)}`) }
    if (process) { queryParams.push(`PROCESS=${encodeURIComponent(process)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`LTITLE=${encodeURIComponent(title)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documents?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_LOG_DEFINITION_LOGS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: LOG_DEFINITION_GET_LOGS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: LOG_DEFINITION_GET_LOGS_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.logs_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LOG_DEFINITION_GET_LOGS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getLog request.
 * @param {Object} fields The fields to select.
 * @param {String} host The host pattern.
 * @param {String} application The extention pattern.
 * @param {String} subLog The subLog pattern.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getLog(fields, host, application, subLog, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: LOG_DEFINITION_GET_LOG_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    queryParams.push(`FORM=${encodeURIComponent(host)}`)
    queryParams.push(`EXT=${encodeURIComponent(application)}`)
    queryParams.push(`REPORT=${encodeURIComponent(subLog)}`)

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: LOG_DEFINITION_GET_LOG_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: LOG_DEFINITION_GET_LOG_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.get_log_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LOG_DEFINITION_GET_LOG_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and modifies a log definition.
 * @param {Object} logDefinition The log definition.
 * @param {Function} callback The callback which will be called when the create log request was successful.
 */
export function modifyLog(logDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    let logMessage = ''
    logMessage += `${Lang.translate('definition.host')}: ${logDefinition.FORM}`
    if (logDefinition.EXT !== '') {
      logMessage += `, ${Lang.translate('definition.application')}: ${logDefinition.EXT}`
    }
    if (logDefinition.REPORT !== '') {
      logMessage += `, ${Lang.translate('definition.sublog')}: ${logDefinition.REPORT}`
    }

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document`, {
        method: 'put',
        body: ObjectUtils.removeByValue(logDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.modify_log_definition_success', lang, logMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }

          // call getDoduments again to get the current result
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.modify_log_definition_error', lang, [logMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a log definition.
 * @param {Object} logDefinition The log definition.
 * @param {Function} callback The callback which will be called when the create log request was successful.
 */
export function createLog(logDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    let logMessage = ''
    logMessage += `${Lang.translate('definition.host')}: ${logDefinition.FORM}`
    if (logDefinition.EXT !== '') {
      logMessage += `, ${Lang.translate('definition.application')}: ${logDefinition.EXT}`
    }
    if (logDefinition.REPORT !== '') {
      logMessage += `, ${Lang.translate('definition.sublog')}: ${logDefinition.REPORT}`
    }

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document`, {
        method: 'post',
        body: ObjectUtils.removeByValue(logDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.create_log_success', lang, logMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }
          // ! DO NOT HIDE LOADING SPINNER WHEN SUCCESS BECAUSE THE TEST NEEDS TO WAIT UNTIL THE REFRESH IS DONE
          // ! REFRESH WILL HIDE LOADING SPINNER WHEN FINISH
          // call getDoduments again to get the current result
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.create_log_error', lang, [logMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and sclone a log definition.
 * @param {Object} logDefinition The log definition.
 * @param {Function} callback The callback which will be called when the clone log request was successful.
 */
export function cloneLog(logDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    let logMessage = ''
    logMessage += `${Lang.translate('definition.host')}: ${logDefinition.NFORM}`
    if (logDefinition.NEXTENSION !== '') {
      logMessage += `, ${Lang.translate('definition.application')}: ${logDefinition.NEXTENSION}`
    }
    if (logDefinition.NREPORT !== '') {
      logMessage += `, ${Lang.translate('definition.sublog')}: ${logDefinition.NREPORT}`
    }

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document/clone`, {
        method: 'post',
        body: ObjectUtils.removeByValue(logDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.create_log_success', lang, logMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }
          // ! DO NOT HIDE LOADING SPINNER WHEN SUCCESS BECAUSE THE TEST NEEDS TO WAIT UNTIL THE REFRESH IS DONE
          // ! REFRESH WILL HIDE LOADING SPINNER WHEN FINISH
          // call getDoduments again to get the current result
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.create_log_error', lang, [logMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes an index.
 * @param {Object} logObj The the log object definition which should be deleted.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function deleteLog(logObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    let logMessage = ''
    logMessage += `${Lang.translate('definition.host')}: ${logObj.FORM}`
    if (logObj.EXT !== '') {
      logMessage += `, ${Lang.translate('definition.application')}: ${logObj.EXT}`
    }
    if (logObj.REPORT !== '') {
      logMessage += `, ${Lang.translate('definition.sublog')}: ${logObj.REPORT}`
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document${createQueryParamsForFetch({ FORM: logObj.FORM, EXT: logObj.EXT, REPORT: logObj.REPORT })}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.delete_log_success', lang, logMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          if (callback) {
            callback()
          }

          // call getDoduments again to get the current result
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_log_error', lang, [logMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function verifyLog(logObj, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let logMessage = ''
    logMessage += `${Lang.translate('definition.host')}: ${logObj.FORM}`
    if (logObj.EXT !== '') {
      logMessage += `, ${Lang.translate('definition.application')}: ${logObj.EXT}`
    }
    if (logObj.REPORT !== '') {
      logMessage += `, ${Lang.translate('definition.sublog')}: ${logObj.REPORT}`
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document/verify`, {
        method: 'post',
        body: ObjectUtils.removeByValue(logObj, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          if (rc.toString() === '0016' && irc.toString() === '0000') {
            SnackbarActions.show(Lang.translate('general.verify_error'), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
          dispatch({ type: LOG_DEFINITION_VERIFY_FAILED, payload: { error } })
        } else {
          dispatch({ type: LOG_DEFINITION_VERIFY_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.verify_log_error', lang, [logMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LOG_DEFINITION_VERIFY_FAILED, payload: { error } })
      })
  }
}

export const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    getLogs(
      undefined,
      prefs[Preferences.DEFINITION_LOG_HOST],
      prefs[Preferences.DEFINITION_LOG_APPLICATION],
      prefs[Preferences.DEFINITION_LOG_SUBLOG],
      prefs[Preferences.DEFINITION_LOG_TYPE],
      prefs[Preferences.DEFINITION_LOG_OWNER],
      prefs[Preferences.DEFINITION_LOG_TITLE],
      undefined,
      true
    )(dispatch)
  }
}