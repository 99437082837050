import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './ModifyUserDialog.scss';

// Components
import {
  Button,
  Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, RelationAssignments, Row, Switch, Tab,
  Tabs
} from 'BetaUX2Web-Components/src/';
import { TYPE_PASSWORD } from 'BetaUX2Web-Components/src/types';

const { Modal, Main, Header, Footer } = ModalComponent

// Utils
import * as UserUtils from 'utils/UserUtils';

// Redux
import { connect } from 'react-redux';
import * as SnackbarActions from 'redux/actions/SnackbarActions';
import * as UserActions from 'redux/actions/UserActions';
import * as UserGroupAssignmentActions from 'redux/actions/UserGroupAssignmentActions';
import * as Preferences from 'redux/general/Preferences';
import * as DateUtils from 'utils/DateUtils';
import * as Utils from 'utils/Utils';

class ModifyUserDialog extends Component {
  constructor(props) {
    super(props)
    this.usernameInput = React.createRef()
    this.passwordInput = React.createRef()
    this.passwordConfirmInput = React.createRef()
  }

  state = {
    userName: {
      value: this.props.userToModify.USERNAME,
      errorkey: ''
    },
    password: {
      value: '',
      errorkey: ''
    },
    passwordConfirm: {
      value: '',
      errorkey: ''
    },
    passwordInterval: parseInt(this.props.userToModify.PWDCINTV, 0),
    privileges: Math.max(UserUtils.PRIVILEGES_ITEMS.findIndex(d => d.key === this.props.userToModify.ADMIN), 0),
    passwordExpired: this.props.userToModify.FORCEPWD,
    revoked: this.props.userToModify.USRREVOK,
    externalAuthenticate: this.props.userToModify.CKPWDEXT,
    searchGroupId: {
      value: '',
      errorkey: ''
    },
    groupsTabIsVisible: false,
    assigned: {
      sortedCol: 0,
      data: [],
      origData: []
    },
    unassigned: {
      sortedCol: 0,
      data: []
    },
    selectedIds: [],
    draggingId: null
  }

  /**
   * @description Sets the initial focus and initializes the values.
   */
  componentDidMount() {
    // focus username input initially
    this.usernameInput.current?.focus()
    this.setState({
      assigned: {
        sortedCol: 0,
        data: this.getAssignedTableData(),
      },
      unassigned: {
        sortedCol: 0,
        data: this.getUnassignedTableData(this.getOrigUnassignedIds())
      }
    })

    window.addEventListener('click', this.onWindowClick)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.groups !== this.props.groups && this.props.groups) {
      this.setState({
        unassigned: {
          ...this.state.unassigned,
          data: this.getUnassignedTableData(this.getSearchedGroupIds(this.props.groups))
        }
      })
    }
  }

  /**
   * @description Removes the click listener which unselects items.
   */
  componentWillUnmount = () => {
    window.removeEventListener('click', this.onWindowClick)
  }

  /**
   * @description Unselects all items.
   */
  unselectAll = (callback) => {
    const internalCallback = () => {
      if (callback) {
        callback()
      }
    }

    this.setState({
      selectedIds: []
    }, () => internalCallback())
  }

  /**
   * @description Unselects all items if the user clicked on the screen.
   * @param {Object} event The mouse event.
   */
  onWindowClick = (event) => {
    if (event.defaultPrevented) {
      return
    }

    this.unselectAll()
  }

  /**
   * @description Validates the password.
   */
  validatePassword = () => {
    const { password, passwordConfirm } = this.state
    let passwordObj
    let passwordConfirmObj
    if (password.value === '' && passwordConfirm.value !== '') {
      passwordObj = { password: { value: '', errorkey: 'general.input_required' } }
    }
    if (passwordConfirm.value === '' && password.value !== '') {
      passwordConfirmObj = { passwordConfirm: { value: '', errorkey: 'general.input_required' } }
    }
    if (password.value !== passwordConfirm.value) {
      passwordConfirmObj = { passwordConfirm: { value: passwordConfirm.value, errorkey: 'usermanagement.password_confirm_not_equal_to_password' } }
    }
    if (passwordObj || passwordConfirmObj) {
      return {
        ...passwordObj,
        ...passwordConfirmObj
      }
    }
    return {}
  }

  /**
   * @description Validates the external authenticate.
   */
  validateExternalAuthenticate = () => {
    const { password, passwordConfirm, externalAuthenticate } = this.state
    // if external authenticate was true and is set to false the user needs to set a password to successfully save the changes
    if (this.props.userToModify.CKPWDEXT && !externalAuthenticate) {
      let passwordObj
      let passwordConfirmObj
      if (password.value === '') {
        passwordObj = { password: { value: '', errorkey: 'general.input_required' } }
      }
      if (passwordConfirm.value === '') {
        passwordConfirmObj = { passwordConfirm: { value: '', errorkey: 'general.input_required' } }
      }
      if (password.value !== passwordConfirm.value) {
        passwordConfirmObj = { passwordConfirm: { value: passwordConfirm.value, errorkey: 'usermanagement.passwords_not_equal' } }
      }
      if (passwordObj || passwordConfirmObj) {
        return {
          ...passwordObj,
          ...passwordConfirmObj
        }
      }
    }
    return {}
  }

  handleFocusGeneralTab = () => {
    const { password, passwordConfirm } = this.state
    if (password.errorkey !== '') {
      this.passwordInput.current?.focus()
    }
    else if (passwordConfirm.errorkey !== '') {
      this.passwordConfirmInput.current?.focus()
    }
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validatePassword(),
      ...this.validateExternalAuthenticate()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }


  /**
   * @description Calls the rest api and modifies the user.
   */
  handleOnSave = () => {
    const { userToModify, lang } = this.props
    const { userName, password, passwordInterval, revoked, privileges,
      passwordExpired, externalAuthenticate } = this.state
    const callback = () => {
      this.props.onClose()
    }
    if (this.validateGeneralTab()) {
      this.props.modifyUser(
        userToModify.BETAUSER,
        userName.value,
        password.value,
        passwordInterval,
        revoked,
        UserUtils.PRIVILEGES_ITEMS[privileges].key,
        passwordExpired,
        externalAuthenticate,
        callback
      )

      const assignmentCount = this.getGroupIdsToAssign().length
      const results = []
      const singleAssignRun = i => {
        const groupid = this.getGroupIdsToAssign()[i]
        groupid && this.props.createAssignment(groupid, userToModify.BETAUSER, result => {
          if (result?.success) {
            results.push({ success: true, groupid: groupid })
          } else {
            results.push({ success: false, message: result?.message, groupid: groupid })
          }
          if (i < assignmentCount - 1) {
            singleAssignRun(i + 1)
          } else if (i === assignmentCount - 1) {
            const snackbarMessages = []
            const fullContent = []
            const successAssignment = results.filter(result => result.success)
            const failedAssignment = results.filter(result => !result.success)
            if (successAssignment.length > 0) {
              if (successAssignment.length <= 2) {
                successAssignment.forEach(sAss => {
                  snackbarMessages.push({ text: translate('usermanagement.create_user_group_assignment_success', lang, [userToModify.BETAUSER, sAss.groupid]), type: SnackbarActions.TYPE_SUCCESS })
                })
              } else {
                successAssignment.forEach(sAss => {
                  fullContent.push({ text: translate('usermanagement.create_user_group_assignment_success', lang, [userToModify.BETAUSER, sAss.groupid]), type: SnackbarActions.TYPE_SUCCESS })
                })
                snackbarMessages.push({ text: translate('assignment.count_successfully_created', lang, [successAssignment.length.toString()]), type: SnackbarActions.TYPE_SUCCESS })
              }
            }
            if (failedAssignment.length > 0) {
              if (failedAssignment.length <= 2) {
                failedAssignment.forEach(fAss => {
                  snackbarMessages.push({ text: translate('usermanagement.create_user_group_assignment_failed', lang, [userToModify.BETAUSER, fAss.groupid]), type: SnackbarActions.TYPE_ERROR })
                })
              } else {
                failedAssignment.forEach(fAss => {
                  fullContent.push({ text: translate('usermanagement.create_user_group_assignment_failed', lang, [userToModify.BETAUSER, fAss.groupid]), type: SnackbarActions.TYPE_ERROR })
                })
                snackbarMessages.push({ text: translate('assignment.count_fail_created', lang, [failedAssignment.length.toString()]), type: SnackbarActions.TYPE_ERROR })
              }
            }
            this.props.showMultiple(snackbarMessages, fullContent)
          }
        }, true)
      }
      singleAssignRun(0)

      const unassignmentCount = this.getGroupIdsToUnassign().length
      const uresults = []
      const singleUnassignRun = i => {
        const groupid = this.getGroupIdsToUnassign()[i]
        groupid && this.props.deleteAssignment(groupid, userToModify.BETAUSER, result => {
          if (result?.success) {
            uresults.push({ success: true, groupid: groupid })
          } else {
            uresults.push({ success: false, message: result?.message, groupid: groupid })
          }
          if (i < unassignmentCount - 1) {
            singleUnassignRun(i + 1)
          } else if (i === unassignmentCount - 1) {
            const snackbarMessages = []
            const fullContent = []
            const successAssignment = uresults.filter(result => result.success)
            const failedAssignment = uresults.filter(result => !result.success)
            if (successAssignment.length > 0) {
              if (successAssignment.length <= 2) {
                successAssignment.forEach(sAss => {
                  snackbarMessages.push({ text: translate('usermanagement.delete_user_group_assignment_success', lang, [userToModify.BETAUSER, sAss.groupid]), type: SnackbarActions.TYPE_SUCCESS })
                })
              } else {
                successAssignment.forEach(sAss => {
                  fullContent.push({ text: translate('usermanagement.delete_user_group_assignment_success', lang, [userToModify.BETAUSER, sAss.groupid]), type: SnackbarActions.TYPE_SUCCESS })
                })
                snackbarMessages.push({ text: translate('assignment.count_successfully_deleted', lang, [successAssignment.length.toString()]), type: SnackbarActions.TYPE_SUCCESS })
              }
            }
            if (failedAssignment.length > 0) {
              if (failedAssignment.length <= 2) {
                failedAssignment.forEach(fAss => {
                  snackbarMessages.push({ text: translate('usermanagement.delete_user_group_assignment_failed', lang, [userToModify.BETAUSER, fAss.groupid]), type: SnackbarActions.TYPE_ERROR })
                })
              } else {
                failedAssignment.forEach(fAss => {
                  fullContent.push({ text: translate('usermanagement.delete_user_group_assignment_failed', lang, [userToModify.BETAUSER, fAss.groupid]), type: SnackbarActions.TYPE_ERROR })
                })
                snackbarMessages.push({ text: translate('assignment.count_fail_deleted', lang, [failedAssignment.length.toString()]), type: SnackbarActions.TYPE_ERROR })
              }
            }
            this.props.showMultiple(snackbarMessages, fullContent)
          }
        }, true)
      }
      singleUnassignRun(0)
    }
  }

  /**
   * @description Searches groups and updates the searched groups datatable.
   * @param {Object} event The click event.
   */
  handleSearch = event => {
    event.preventDefault()

    this.props.searchGroups(this.state.searchGroupId.value)
  }

  /**
   * @description Handles the changes of the textfields.
   * @param {String} key The id of the textfield.
   * @param {*} value The new value.
   */
  handleChange(key, value) {
    this.setState({ [key]: value })
  }

  /**
   * @description Change password-states and call function to check if both (new password and confirm new password) are equal.
   * @param {String} key The id of the textfield.
   * @param {String} val The new value.
   */
  handlePasswordInputChange = (key, val) => {
    this.setState(
      { [key]: val },
      () => this.handlePasswordConfirmChanged()
    )
  }

  /**
   * @description Check if both entered passwords (new password and confirm) are equal. Otherwise show an error below the confirm-password field.
   */
  handlePasswordConfirmChanged = () => {
    if (this.state.password.value !== this.state.passwordConfirm.value) {
      this.setState({
        passwordConfirm: {
          ...this.state.passwordConfirm,
          errorkey: 'usermanagement.passwords_not_equal'
        }
      })
    }
    else {
      this.setState({
        passwordConfirm: {
          ...this.state.passwordConfirm,
          errorkey: ''
        }
      })
    }
  }

  /**
   * @description Handles the switch button changes.
   * @param {String} key The id of the switch.
   * @param {String} index The new index of the switch button.
   */
  handleSwitchChange(key, index) {
    this.setState({ [key]: index === 0 })
  }

  /**
   * @description Gets the original unassigned ids.
   */
  getOrigUnassignedIds = () => {
    const { groups, userGroupAssignments } = this.props
    let assignedGroupIDs = []

    if (!groups || groups.data === undefined) {
      return []
    }

    if (userGroupAssignments.data !== undefined) {
      // get GroupIDs
      userGroupAssignments.data.forEach(entry => {
        // we only need GroupID
        assignedGroupIDs.push(entry[userGroupAssignments.header.indexOf('BETAGRP')])
      })
    }

    // filter GroupIDs
    const filteredGroups = groups.data.filter(
      filteredGroup => !assignedGroupIDs.includes(filteredGroup[groups.header.indexOf('BETAGRP')])
    )

    // display list
    return filteredGroups.map(entry => {
      // return id
      return entry[groups.header.indexOf('BETAGRP')]
    })
  }

  /**
   * @description Gets the group ids to assign.
   */
  getGroupIdsToAssign = () => {
    // Create id array for the new assigned data
    let newDataIds = this.state.assigned.data.map((newData) => {
      return newData[0]
    })

    // Check, wether the orig data is empty and return newIds
    if (this.props.userGroupAssignments?.data === undefined) {
      return newDataIds
    }

    // Create id array for the orig data
    let origDataIds = this.props.userGroupAssignments.data.map((origData) => {
      return origData[this.props.userGroupAssignments.header.indexOf('BETAGRP')]
    })

    // Only return the different ids between the new and old data
    return newDataIds.filter(id => !origDataIds.includes(id))
  }

  /**
   * @description Gets the group ids to unassign.
   */
  getGroupIdsToUnassign = () => {
    // Create id array for the new unassigned data
    let newDataIds = this.state.assigned.data.map((newData) => {
      return newData[0]
    })

    // In case there are no groups assinged before, do not delete assignments
    if (this.props.userGroupAssignments?.data === undefined) {
      return []
    }

    // Create id array for the orig data
    let origDataIds = this.props.userGroupAssignments?.data.map((origData) => {
      return origData[this.props.userGroupAssignments.header.indexOf('BETAGRP')]
    })

    // Only return the different ids between the new and old data
    return origDataIds.filter(id => !newDataIds.includes(id))
  }

  /**
   * @description Gets the search group ids.
   */
  getSearchedGroupIds = searchedGroups => {
    // Create id array for the searched Groups
    let searchedGroupIDs = []

    if (searchedGroups && searchedGroups.data) {
      searchedGroupIDs = searchedGroups.data.map(entry => {
        return entry[searchedGroups.header.indexOf('BETAGRP')]
      })
    }

    // Create id array for the current Group data
    let currentData = this.state.assigned.data.map((entry) => {
      return entry[0]
    })

    // Only return the different ids between the new and old data
    return searchedGroupIDs.filter(id => !currentData.includes(id)).map(item => {
      // Sloppy craftsmenship, because we expect an array
      return item
    })
  }

  /**
   * @description Gets the assigned table data.
   */
  getAssignedTableData = () => {
    const { userGroupAssignments } = this.props

    let tableData = []
    // return an empty array when there is no data
    if (userGroupAssignments?.data !== undefined) {
      // Get all original group ids
      userGroupAssignments.data.forEach(entry => {
        // Get id and group name
        tableData.push([
          entry[userGroupAssignments.header.indexOf('BETAGRP')],
          entry[userGroupAssignments.header.indexOf('GRPNAME')],
        ])
      })
    }
    return tableData
  }

  /**
   * @description Gets the unassigned table data.
   * @param {Array} unassignedGroupIds The unassigned group ids.
   */
  getUnassignedTableData = (unassignedGroupIds) => {
    const { groups } = this.props
    let tableData = []
    if (groups && groups.data !== undefined) {
      // Map through our ids and get proper table data for all corresponding ids
      groups.data.forEach(entry => {
        unassignedGroupIds.forEach(id => {
          if (entry[groups.header.indexOf('BETAGRP')] === id) {
            // Get id and group name
            tableData.push([
              entry[groups.header.indexOf('BETAGRP')],
              entry[groups.header.indexOf('GRPNAME')],
            ])
          }
        })
      })
    }
    return tableData
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab() {
    const { id } = this.props
    const { userName, password, passwordConfirm, passwordInterval,
      externalAuthenticate, passwordExpired, revoked } = this.state
    return (
      <>
        <div className={'bux_flex_row_container'}>
          <div className={'bux_flex_column_container'}>
            <Row>
              <Column colMD={6}>
                <label
                  id={`${id}_body_card_row_col_user_title`}
                  className='paddingBottomFromTitle'>
                  {translate('user.user')}
                </label>
                <Input
                  id={`${id}_body_card_row_col_user_username`}
                  ref={this.usernameInput}
                  value={userName.value}
                  title={translate('user.username')}
                  maxLength={32}
                  onInputChanged={(val, err) => {
                    this.handleChange('userName', { value: val, errorkey: err })
                  }}
                  error={userName.errorkey && translate(userName.errorkey)}
                />
                <Input
                  id={`${id}_body_card_row_col_user_password`}
                  ref={this.passwordInput}
                  title={translate('usermanagement.new_password')}
                  type={TYPE_PASSWORD}
                  value={password.value}
                  maxLength={8}
                  onInputChanged={(val, err) => {
                    this.handlePasswordInputChange('password', { value: val, errorkey: err })
                  }}
                  error={password.errorkey && translate(password.errorkey)}
                  required={this.props.userToModify.CKPWDEXT && !externalAuthenticate ? `${translate('general.required_field')}` : false}
                  disabled={externalAuthenticate}
                />
                <Input
                  id={`${id}_body_card_row_col_user_passwordconfirm`}
                  ref={this.passwordConfirmInput}
                  title={translate('usermanagement.new_password_confirm')}
                  type={TYPE_PASSWORD}
                  value={passwordConfirm.value}
                  maxLength={8}
                  onInputChanged={(val, err) => {
                    this.handlePasswordInputChange('passwordConfirm', { value: val, errorkey: err })
                  }}
                  error={passwordConfirm.errorkey && translate(passwordConfirm.errorkey)}
                  required={this.props.userToModify.CKPWDEXT && !externalAuthenticate ? `${translate('general.required_field')}` : false}
                  disabled={externalAuthenticate}
                />
                <NumericSpinner
                  id={`${id}_body_card_row_col_user_passwordinterval`}
                  title={translate('user.password_interval')}
                  value={passwordInterval}
                  max={255}
                  steps={1}
                  min={0}
                  onChange={newVal => { this.handleChange('passwordInterval', newVal) }}
                  disabled={externalAuthenticate}
                />
              </Column>
              <Column colMD={6}>
                <label
                  id={`${id}_body_card_row_col_additional_title`}
                  className='paddingBottomFromTitle'>
                  {translate('general.additional')}
                </label>
                <Switch
                  id={`${id}_body_card_row_col_additional_administrator`}
                  title={translate('user.privileges')}
                  onClick={index => this.setState({ privileges: index })}
                  activeIndex={this.state.privileges}
                  items={UserUtils.PRIVILEGES_ITEMS.map(item => translate(item.translationKey))}
                  maxPerRow={3}
                />
                <Switch
                  id={`${id}_body_card_row_col_additional_passwordexpired`}
                  title={translate('user.password_expired')}
                  onClick={index => this.handleSwitchChange('passwordExpired', index)}
                  items={[translate('general.yes'), translate('general.no')]}
                  activeIndex={externalAuthenticate ? 1 : passwordExpired ? 0 : 1}
                  disabled={externalAuthenticate}
                />
                <Switch
                  id={`${id}_body_card_row_col_additional_revoked`}
                  title={translate('user.revoked')}
                  onClick={index => this.handleSwitchChange('revoked', index)}
                  activeIndex={revoked ? 0 : 1}
                  items={[translate('general.yes'), translate('general.no')]}
                />
                <Switch
                  id={`${id}_body_card_row_col_additional_externalauthenticate`}
                  title={translate('user.external_authentication')}
                  onClick={index =>
                    this.handleSwitchChange('externalAuthenticate', index)
                  }
                  activeIndex={externalAuthenticate ? 0 : 1}
                  items={[translate('general.yes'), translate('general.no')]}
                />
              </Column>
            </Row>
          </div>
        </div>
      </>
    )
  }

  /**
   * @description Reorders an item inside a list.
   * @param {Array} list The list.
   * @param {Number} startIndex The source index of the item to reorder.
   * @param {Number} endIndex The destination index.
   * @return {Array} The reordered list.
   */
  reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  /**
   * @description Moves an item from a list to another.
   * @param {Array} source The source list.
   * @param {Array} destination The destination list.
   * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
   * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
   * @returns {Object} An object with the new source and destination lists.
   */
  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = [...source]
    const destClone = [...destination]
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  /**
   * @description Sets the width and visibility properties for group dragging.
   * @param {Object} dragStart The dragStart object.
   */
  onBeforeDragStart = (dragStart) => {
    // get the id which is dragged
    let id = this.state[dragStart.source.droppableId].data[dragStart.source.index]
    const selected = this.state.selectedIds.find((headerId) => headerId === id)

    let newHeaderWidth = '0px'
    let visibility = 'hidden'

    if (selected) {
      // set the width of the grouping items based on the item which will be dragged
      if (document.querySelector(`#${dragStart.draggableId}`)) {
        newHeaderWidth = `${document.querySelector(`#${dragStart.draggableId}`).getBoundingClientRect().width}px`
        visibility = 'visible'
      }
      this.setState({ draggingId: id })
    } else {
      // if draggin an item that is not selected - unselect all items
      this.setState({ draggingId: id, selectedIds: [] })
    }

    document.querySelector(':root').style.setProperty('--row-width', newHeaderWidth)
    document.querySelector(':root').style.setProperty('--row-visibility', visibility)
  }

  /**
   * @description Performes the single drag action.
   * @param {Object} result Includes the new lists.
   */
  singleDrag = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      this.setState({ draggingId: null })
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorder(
        this.state[source.droppableId].data,
        source.index,
        destination.index
      )

      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: items
        },
        draggingId: null
      })
      // move if the dnd was between different lists
    } else {
      const result = this.move(
        this.state[source.droppableId].data,
        this.state[destination.droppableId].data,
        source,
        destination
      )

      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: result[source.droppableId]
        },
        [destination.droppableId]: {
          ...this.state[destination.droppableId],
          data: result[destination.droppableId]
        },
        draggingId: null
      })
    }
  }

  /**
 * @description Performes the multi drag action.
 * @param {Object} result Includes the new lists.
 */
  multiDrag = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      this.setState({ draggingId: null })
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorderMulti(
        this.state[source.droppableId].data,
        source.index,
        destination.index
      )

      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: items
        },
        draggingId: null
      })
      // move if the dnd was between different lists
    } else {
      const result = this.moveMulti(
        this.state[source.droppableId].data,
        this.state[destination.droppableId].data,
        source,
        destination
      )

      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState({
        [source.droppableId]: {
          ...this.state[source.droppableId],
          data: result[source.droppableId]
        },
        [destination.droppableId]: {
          ...this.state[destination.droppableId],
          data: result[destination.droppableId]
        },
        draggingId: null,
        selectedIds: result['orderedSelectedIds']
      })
    }
  }

  /**
 * @description Reorders multiple items.
 * @param {Array} list The list.
 * @param {Number} startIndex The source start index.
 * @param {Number} endIndex The destination index.
 */
  reorderMulti = (list, startIndex, endIndex) => {
    // do nothing when startindex and endindex are equal
    if (startIndex === endIndex) {
      return list
    }

    let result = [...list]

    const insertAtIndex = (() => {
      // gets the offset of the index where to insert the items
      const destinationIndexOffset = this.state.selectedIds.reduce((previous, current) => {
        const index = list.indexOf(current)

        if (current === this.state.draggingId) {
          if (index > endIndex && previous > 0) {
            return previous - 1
          } else {
            return previous
          }
        }

        if (index > endIndex) {
          return previous
        }

        return previous + 1
      }, 0)

      return endIndex - destinationIndexOffset
    })()

    const orderedSelectedHeaders = [...this.state.selectedIds]
    orderedSelectedHeaders.sort((a, b) => {

      const indexOfA = list.indexOf(a)
      const indexOfB = list.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      // sorting by their order in the selected headers
      return -1
    })

    result = result.filter((id) => {
      return !this.state.selectedIds.includes(id)
    })

    result.splice(insertAtIndex, 0, ...orderedSelectedHeaders)

    return result
  }

  /**
   * @description Moves items from a list to another.
   * @param {Array} source The source list.
   * @param {Array} destination The destination list.
   * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
   * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
   * @returns {Object} An object with the new source and destination lists.
   */
  moveMulti = (source, destination, droppableSource, droppableDestination) => {
    let sourceClone = [...source]
    const destClone = [...destination]
    sourceClone = sourceClone.filter((id) => {
      return !this.state.selectedIds.includes(id)
    })

    const orderedSelectedHeaders = [...this.state.selectedIds]
    orderedSelectedHeaders.sort((a, b) => {
      const indexOfA = source.indexOf(a)
      const indexOfB = source.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      // sorting by their order in the selected headers
      return -1
    })

    destClone.splice(droppableDestination.index, 0, ...orderedSelectedHeaders)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    result['orderedSelectedIds'] = orderedSelectedHeaders

    return result
  }

  /**
   * @description The drag end action from dnd.
   * @param {Object} result The result from dnd.
   */
  onDragEnd = (result) => {
    // multi drag
    if (this.state.selectedIds.length > 1) {
      this.multiDrag(result)
    } else {
      // single drag
      this.singleDrag(result)
    }
  }

  /**
   * @description Toggles a selection of an item.
   * @param {String} id The id of the data to select or unselect.
   */
  toggleSelection = (id) => {
    let newSelectedIds = []
    if (!this.state.selectedIds.includes(id)) {
      newSelectedIds = [id]
    } else if (this.state.selectedIds.length > 1) {
      // was part of a seleted group -> will now become the only selected item
      newSelectedIds = [id]
    } else {
      // was previously selected but not in a group -> will now clear the selection
      newSelectedIds = []
    }

    this.setState({
      selectedIds: newSelectedIds
    })
  }

  /**
   * @description Toggles the selection in group (with ctrl).
   * @param {String} id The id of the data which was clicked.
   */
  toggleSelectionInGroup = (id) => {
    const index = this.state.selectedIds.indexOf(id)

    // if not selected -> add it to the selected items
    if (index === -1) {
      this.setState({ selectedIds: [...this.state.selectedIds, id] })
    } else {
      // item was previously selected -> remove it from the group
      const shallow = [...this.state.selectedIds]
      shallow.splice(index, 1)
      this.setState({ selectedIds: shallow })
    }
  }

  /**
   * @description Selects all elements between the last selected item and the item to select.
   * @param {Array} data The data.
   * @param {String} id The id of the data to select.
   */
  multiSelectTo = (data, id) => {
    const updated = this.multiSelect(data, id)

    if (updated === null) {
      return
    }

    this.setState({ selectedIds: updated })
  }

  /**
 * @description Multi selects items with shift.
 * @param {Array} data The data.
 * @param {String} id The id of the data which was clicked.
 * @returns {Array} The new selected headers or null.
 */
  multiSelect = (data, newId) => {
    if (!this.state.selectedIds.length) {
      return [newId]
    }

    const indexOfNew = data.indexOf(newId)
    const indexOfLast = data.indexOf(this.state.selectedIds[this.state.selectedIds.length - 1])

    if (indexOfNew === indexOfLast) {
      return null
    }

    const isSelectingForwards = indexOfNew > indexOfLast
    const start = isSelectingForwards ? indexOfLast : indexOfNew
    const end = isSelectingForwards ? indexOfNew : indexOfLast

    const inBetween = data.slice(start, end + 1)

    // add headers which are between the last selected item and the clicked item
    // if items between are already selected do nothing
    const toAdd = inBetween.filter((id) => {
      if (Utils.includesArray(this.state.selectedIds, id)) {
        return false
      }
      return true
    })

    const combined = [...this.state.selectedIds, ...toAdd]

    return combined
  }

  /**
   * @description Renders the groups tab.
   */
  renderGroupsTab() {
    const { id } = this.props
    const { searchGroupId } = this.state

    const available = {
      title: translate('user.unassignedGroups'),
      data: this.state.unassigned.data,
      headers: [
        translate('group.group_id'),
        translate('group.groupname')
      ],
      columnSortDefs: ['string', 'string'],
      onSortEnd: (newData, sortedCol) => { this.setState({ unassigned: { sortedCol: sortedCol, data: newData } }) },
      sortedCol: this.state.unassigned.sortedCol,
      droppableID: 'unassigned'
    }

    const chosen = {
      title: translate('user.assignedGroups'),
      data: this.state.assigned.data,
      headers: [
        translate('group.group_id'),
        translate('group.groupname')
      ],
      columnSortDefs: ['string', 'string'],
      onSortEnd: (newData, sortedCol) => { this.setState({ assigned: { sortedCol: sortedCol, data: newData } }) },
      sortedCol: this.state.assigned.sortedCol,
      droppableID: 'assigned'
    }

    return (
      <>
        <Row>
          <Column
            colMD={6}>
            <Input
              id={`${id}_groupSearch`}
              title={translate('user.searchGroups')}
              ref={this.searchGroup}
              value={searchGroupId.value}
              maxLength={8}
              onInputChanged={(val, err) => {
                this.handleChange('searchGroupId', { value: val, errorkey: err })
              }}
              error={searchGroupId.errorkey && translate(searchGroupId.errorkey)}
              addon={{ iconName: 'search', isInner: false, onClick: this.handleSearch }}
            />
          </Column>
        </Row>

        <RelationAssignments
          id={`${id}_relation_assignments`}
          available={available}
          chosen={chosen}
          onBeforeDragStart={this.onBeforeDragStart}
          onDragEnd={this.onDragEnd}
          selectedIds={this.state.selectedIds}
          draggingId={this.state.draggingId}
          toggleSelection={this.toggleSelection}
          toggleSelectionInGroup={this.toggleSelectionInGroup}
          multiSelectTo={this.multiSelectTo}
          unselectAll={this.unselectAll}
          translate={key => translate(key)}
        />
      </>
    )
  }

  /**
   * @description Renders the header.
   */
  renderHeader() {
    const { id, userToModify, datemask } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('user.user_id'), value: userToModify.BETAUSER },
          { label: translate('general.last_login'), value: DateUtils.getDate(datemask, userToModify.LLOGDATE, userToModify.LLOGTIME.substring(0, 8)) },
          { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, userToModify.CDATE, userToModify.CTIME.substring(0, 8)) + ' ' + translate('general.by') + ' ' + userToModify.CUSER },
        ]}
        columns={4}
      />
    )
  }

  getDisabledTabs = () => {
    return this.props.assignmentsBlocked ? [1] : []
  }

  checkErrorsInGeneralTab = () => {
    return (this.state.userName.errorkey ||
      this.state.password.errorkey ||
      this.state.passwordConfirm.errorkey);

  }

  render = () => {
    const { id, onClose, assignmentsBlocked } = this.props

    return (
      <Modal onClose={onClose}
        id={id}>
        <Header
          id={id}
          title={translate('usermanagement.modify_user')}
          onClose={this.props.onClose}>
          {this.renderHeader()}
        </Header>
        <Main>
          <Tabs
            id={id}
            className={'bux_modal_datatable_container'}
            disabledTabs={this.getDisabledTabs()}
            errorTabs={this.checkErrorsInGeneralTab() ? [0] : []}
          >
            <Tab id={`${id}_genral`} title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab id={`${id}_groups`} title={translate('group.groups')} tooltip={assignmentsBlocked && translate('tooltip.tab_disabled_because_of_authorization')}>
              {this.renderGroupsTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer id={id}>
          <Button
            id={`${id}_cancelBtn`}
            text={translate('general.cancel')}
            onClick={this.props.onClose}
          />
          <Button
            id={`${id}_saveBtn`}
            text={translate('general.save')}
            onClick={this.handleOnSave}
            disabled={this.checkErrorsInGeneralTab()}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

ModifyUserDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    userToModify: state.user.user,
    groups: state.usergroupassignment.groups,
    userGroupAssignments: state.usergroupassignment.userAssignments,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchGroups: (groupid, callback) => {
      UserGroupAssignmentActions.searchGroups(groupid, callback)(dispatch)
    },
    modifyUser: (userid, username, password, passwordInterval, revoked,
      privileges, passwordExpired, externalAuthenticate, callback) => {
      UserActions.updateUser(userid, username, password, passwordInterval,
        revoked, privileges, passwordExpired, externalAuthenticate, callback)(dispatch)
    },
    createAssignment: (groupid, userid, callback, callbackFlag) => {
      UserGroupAssignmentActions.createUserGroupAssignment(groupid,
        userid, callback, callbackFlag)(dispatch)
    },
    deleteAssignment: (groupid, userid, callback, callbackFlag) => {
      UserGroupAssignmentActions.deleteUserGroupAssignment(groupid, userid,
        callback, callbackFlag)(dispatch)
    },
    showMultiple: (snackbarMessages, customTitle) => SnackbarActions.showMultiple(snackbarMessages, customTitle)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyUserDialog)