import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as SortUtils from 'utils/SortUtils'
import * as UserUtils from 'utils/UserUtils'

import CopyResultTableDialog from 'components/dialogs/copy_result_table_dialog/CopyResultTableDialog'
import CreateResultTableDialog from 'components/dialogs/create_result_table_dialog/CreateResultTableDialog'
import ModifyResultTableDialog from 'components/dialogs/modify_result_table_dialog/ModifyResultTableDialog'
import * as ResultTableActions from 'redux/actions/ResultTableDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultResultTable extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired
  }

  state = {
    showTableSettingsDialog: false,
    showCreateDialog: false,
    createDialogData: undefined,
    showModifyDialog: false,
    showDeleteDialog: false,
    showCopyDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: RESULTTABLEID, translation: 'definition.result_table_id', default: true },
      { rest: DESCRIPTION, translation: 'general.description', default: true },
      { rest: JOBTYPE, translation: 'job.jobtype', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER },
    ]
  }

  componentDidMount = () => {
    if (UserUtils.isDOCX()) {
      // jobtype is only shown in logx. Filter it in docx.
      this.setState({ header: this.state.header.filter(el => el.rest !== JOBTYPE) })
    }
  }

  /**
  * @description Gets specific column sort definitions.
  */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Handles the action buttons to open dialogs
   * @param {Number} rowIndex
   * @param {String} dialog
   */
  handleDialog = (rowIndex, dialog) => {
    const { getResultTableDefaultObjects, resultTables, getResultTable } = this.props
    const id = resultTables.data[rowIndex][this.headerData('SLTINAME')]
    const jobType = DefinitionUtils.getResultTableJobTypes().find(jobtype => jobtype.key === resultTables.data[rowIndex][this.headerData(JOBTYPE)])

    getResultTableDefaultObjects(jobType.key, () => getResultTable(id, () => this.setState({ [dialog]: true })))
  }

  handleAdd = () => {
    const { getResultTableDefaultObjects, preferences } = this.props
    getResultTableDefaultObjects('BRWTAB', () => this.setState(
      {
        showCreateDialog: true,
        createDialogData: {
          SLTINAME: preferences[Preferences.DEFINITION_RESULT_TABLE_ID],
          SLTENAME: preferences[Preferences.DEFINITION_RESULT_TABLE_DESCRIPTION],
          OWNER: preferences[Preferences.DEFINITION_RESULT_TABLE_OWNER],
        }
      }))
  }

  /**
   * @description Handles the copy action to open the dialog.
   * @param {Number} rowIndex
   */
  handleCopyDialog = (rowIndex) => {
    const { resultTables, getResultTable } = this.props
    const id = resultTables.data[rowIndex][this.headerData('SLTINAME')]

    const dialogCallback = () => this.setState({ showCopyDialog: true })

    getResultTable(id, dialogCallback)
  }

  /**
   * @description Deletes the result table
   */
  handleDelete = () => {
    const { deleteResultTable, resultTable } = this.props
    const resultTableObj = {
      SLTINAME: resultTable.SLTINAME,
      SLTENAME: resultTable.SLTENAME,
      SLTITYPE: resultTable.SLTITYPE,
      OWNER: resultTable.OWNER
    }
    deleteResultTable(resultTableObj, () => this.setState({ showDeleteDialog: false }))
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => { this.handleDialog(rowIndex, 'showModifyDialog') }}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
          onClick={() => { this.handleDialog(rowIndex, 'showModifyDialog') }}
        />
        <TableButtonGroupItem
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
          onClick={() => this.handleCopyDialog(rowIndex)}
        />
        <TableButtonGroupItem
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.handleAdd()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.props.refreshSearch}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
  * @description gets the index of the header in redux state definitions.resultTables.header
  * @param {String} header header name of the header in redux definitions.state resultTables.header
  */
  headerData = header => this.props.resultTables.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, resultTables } = this.props
    const data = []
    const headers = this.getUsedHeader()

    resultTables.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === JOBTYPE) {
          const result = DefinitionUtils.getResultTableJobTypes().find(jobtype => jobtype.key === el[this.headerData(header)])
          buffer.push(result ? translate(result.translationKey) : el[this.headerData(header)])
        }
        else if (header === CTIME) {
          buffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            buffer.push(`${val}:00`)
          }
          else {
            buffer.push(val)
          }
        }
      })
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_RESULT_TABLE]) {
      const buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_RESULT_TABLE].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_RESULT_TABLE]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_RESULT_TABLE].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  render = () => {
    const { id, resultTables, resultTable, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showModifyDialog, showDeleteDialog, showCopyDialog } = this.state
    const data = resultTables && resultTables.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showCreateDialog && (
          <CreateResultTableDialog
            id={`${id}_createresulttable`}
            prefilledData={this.state.createDialogData}
            onClose={() => this.setState({ showCreateDialog: false, createDialogData: undefined })}
          />
        )}
        {showModifyDialog && (
          <ModifyResultTableDialog
            id={`${id}_modifyresulttable`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        )}
        {showCopyDialog && (
          <CopyResultTableDialog
            id={`${id}_copyresulttable`}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_RESULT_TABLE }}
          />
        )}
        {showDeleteDialog &&
          <DeleteDialog
            id={`${id}_resulttable`}
            title={translate('definition.delete_result_table')}
            question={translate('definition.question_delete_result_table')}
            onClose={() => this.setState({ showDeleteDialog: false })}
            onDelete={() => this.handleDelete()}>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_resulttableid_label`}>
                  {translate('definition.result_table_id')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_resulttableid_value`}>
                  {resultTable.SLTINAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                <p id={`${id}_description_label`}>
                  {translate('general.description')}:
                </p>
              </Column>
              <Column colMD={9}>
                <p id={`${id}_description_value`}>
                  {resultTable.SLTENAME}
                </p>
              </Column>
            </Row>
            {/* jobtype only shown in logx */}
            {UserUtils.isLOGX() && (
              <Row>
                <Column colMD={3}>
                  <p id={`${id}_jobtype_label`}>
                    {`${translate('job.jobtype')}:`}
                  </p>
                </Column>
                <Column colMD={9}>
                  <p id={`${id}_jobtype_value`}>
                    {translate(DefinitionUtils.getResultTableJobTypes().find(jobtype => jobtype.key === resultTable.SLTITYPE)?.translationKey)}
                  </p>
                </Column>
              </Row>
            )}
          </DeleteDialog>}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {resultTables
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={index => { this.handleDialog(index, 'showModifyDialog') }}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_result_table_result')}
                    link={translate('emptyresult.create_result_table_link')}
                    onClick={() => this.handleAdd()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const RESULTTABLEID = 'SLTINAME'
const DESCRIPTION = 'SLTENAME'
const JOBTYPE = 'SLTITYPE'
const OWNER = 'OWNER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    resultTables: state.definitions.resulttables.resultTables,
    resultTable: state.definitions.resulttables.resultTable,
    keepPagination: state.definitions.resulttables.keepPagination,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    refreshSearch: () => { ResultTableActions.refreshSearch()(dispatch) },
    getResultTable: (resultTableID, callback) => {
      ResultTableActions.getResultTable(resultTableID, callback)(dispatch)
    },
    deleteResultTable: (resultTable, callback) => {
      ResultTableActions.deleteResultTable(resultTable, callback)(dispatch)
    },
    getResultTableDefaultObjects: (buxCommand, callback) => {
      ResultTableActions.getResultTableDefaultObjects(buxCommand, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultResultTable)