import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'

export const GET_CUSTOM_DIALOG_START = 'GET_CUSTOM_DIALOG_START'
export const GET_CUSTOM_DIALOG_SUCCESS = 'GET_CUSTOM_DIALOG_SUCCESS'
export const GET_CUSTOM_DIALOG_FAILED = 'GET_CUSTOM_DIALOG_FAILED'

export const GET_CUSTOM_DIALOGS_START = 'GET_CUSTOM_DIALOGS_START'
export const GET_CUSTOM_DIALOGS_SUCCESS = 'GET_CUSTOM_DIALOGS_SUCCESS'
export const GET_CUSTOM_DIALOGS_FAILED = 'GET_CUSTOM_DIALOGS_FAILED'
export const NO_CUSTOM_DIALOGS_FOUND = 'NO_CUSTOM_DIALOGS_FOUND'

export const GET_CUSTOM_DIALOG_RESULT_TABLE_SUCCESS = 'GET_CUSTOM_DIALOG_RESULT_TABLE_SUCCESS'
export const GET_CUSTOM_DIALOG_RESULT_TABLE_FAILED = 'GET_CUSTOM_DIALOG_RESULT_TABLE_FAILED'

export const RESET_RESULT_TABLE = 'RESET_RESULT_TABLE'

export function getCustomDialog(sptiname, customDialog, callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_CUSTOM_DIALOG_START })

    /* Build request-parameter for URL */
    const queryParams = [`SPTINAME=${encodeURIComponent(sptiname)}`]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/searchprofileobjects?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_CUSTOM_DIALOG_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_CUSTOM_DIALOG_SUCCESS, payload: jsondata.data, dialog: customDialog })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.custom_dialog_error', lang, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_CUSTOM_DIALOG_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getCustomDialogs(callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_CUSTOM_DIALOG_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchprofiles`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_CUSTOM_DIALOGS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_CUSTOM_DIALOGS_FAILED, payload: { error } })
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_CUSTOM_DIALOGS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.custom_dialogs_error', lang, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_CUSTOM_DIALOGS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export const getCachedCustomDialog = (cacheKey, callback) => {
  return dispatch => {
    const customSelection = store.getState().search.customselection
    if (!(customSelection.cache && cacheKey in customSelection.cache)) return

    dispatch({
      type: GET_CUSTOM_DIALOG_SUCCESS,
      payload: customSelection.cache[cacheKey].customDialog.OBJECTS,
      dialog: customSelection.cache[cacheKey].customDialog
    })

    if (callback) {
      callback()
    }
  }
}

export const getCachedResultTable = (cacheKey, callback) => {
  return dispatch => {
    const customSelection = store.getState().search.customselection
    if (!(customSelection.cache && cacheKey in customSelection.cache)) return

    dispatch({ type: GET_CUSTOM_DIALOG_RESULT_TABLE_SUCCESS, payload: customSelection.cache[cacheKey].resultTable })

    if (callback) {
      callback()
    }
  }
}

export const getCachedCustomDialogConfig = () => {
  const prefs = store.getState().auth.serverdata.preferences
  const cacheKey = store.getState().auth.serverdata.systemname === 'DOCX' ? prefs[Preferences.SEARCH_CURRENT_CUSTOM_DIALOG_DOCX] : prefs[Preferences.SEARCH_CURRENT_CUSTOM_DIALOG_LOGX]
  const cachedCustomDialogConfig = store.getState().search.customselection.cache && cacheKey in store.getState().search.customselection.cache ? store.getState().search.customselection.cache[cacheKey].customDialogConfig : null

  return cachedCustomDialogConfig
}

export const getCustomDialogsForSearch = callback => {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_CUSTOM_DIALOG_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/searchprofiles`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_CUSTOM_DIALOGS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_CUSTOM_DIALOGS_FAILED, payload: { error } })
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_CUSTOM_DIALOGS_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.custom_dialogs_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_CUSTOM_DIALOGS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}


/**
 * @description Calling the rest api and requesting the get result table
 * @param {String} resultTableID
 * @param {Function} callback
 * @param {Boolean} keepPagination
 */
export function getResultTable(resultTableID, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchresult?SLTINAME=${encodeURIComponent(resultTableID)}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          dispatch({ type: GET_CUSTOM_DIALOG_RESULT_TABLE_FAILED })
        }
        else {
          LoadingSpinnerActions.hide()(dispatch)
          dispatch({ type: GET_CUSTOM_DIALOG_RESULT_TABLE_SUCCESS, payload: jsondata.data, keepPagination })
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.result_table_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export const resetResultTable = () => {
  return dispatch => {
    dispatch({ type: RESET_RESULT_TABLE })
  }
}