import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import * as ObjectUtils from 'utils/ObjectUtils'
import { restapiRequest } from 'utils/RequestUtils'

export const SERVER_GET_MESSAGE_LOG_START = 'SERVER_GET_MESSAGE_LOG_START'
export const SERVER_GET_MESSAGE_LOG_SUCCESS = 'SERVER_GET_MESSAGE_LOG_SUCCESS'
export const SERVER_GET_MESSAGE_LOG_FAILED = 'SERVER_GET_MESSAGE_LOG_FAILED'

export const SERVER_GET_SERVERLOGFILE_SERVER_LOG_FILE_START = 'SERVER_GET_SERVER_LOG_FILE_START'
export const SERVER_GET_SERVERLOGFILE_SERVER_LOG_FILE_SUCCESS = 'SERVER_GET_SERVER_LOG_FILE_SUCCESS'
export const SERVER_GET_SERVERLOGFILE_SERVER_LOG_FILE_FAILED = 'SERVER_GET_SERVER_LOG_FILE_FAILED'

export const SERVER_GET_SYSTEM_DEFAULTS_START = 'SERVER_GET_SYSTEM_DEFAULTS_START'
export const SERVER_GET_SYSTEM_DEFAULTS_SUCCESS = 'SERVER_GET_SYSTEM_DEFAULTS_SUCCESS'
export const SERVER_GET_SYSTEM_DEFAULTS_FAILED = 'SERVER_GET_SYSTEM_DEFAULTS_FAILED'

export const SERVER_SET_SYSTEM_DEFAULT_START = 'SERVER_SET_SYSTEM_DEFAULT_START'
export const SERVER_SET_SYSTEM_DEFAULT_SUCCESS = 'SERVER_SET_SYSTEM_DEFAULT_SUCCESS'
export const SERVER_SET_SYSTEM_DEFAULT_FAILED = 'SERVER_SET_SYSTEM_DEFAULT_FAILED'

export const NO_SERVER_MESSAGE_LOG_FOUND = 'NO_SERVER_MESSAGE_LOGS_FOUND'
export const NO_SERVER_SERVERLOGFILE_SERVER_LOG_FILE_FOUND = 'NO_SERVER_SERVER_LOG_FILES_FOUND'
export const NO_SERVER_SYSTEM_DEFAULTS_FOUND = 'NO_SERVER_SYSTEM_DEFAULTS_FOUND'

export const GET_PRINTER_DEVICE_INFORMATION_START = 'GET_PRINTER_DEVICE_INFORMATION_START'
export const GET_PRINTER_DEVICE_INFORMATION_SUCCESS = 'GET_PRINTER_DEVICE_INFORMATION_SUCCESS'
export const GET_PRINTER_DEVICE_INFORMATION_FAILED = 'GET_PRINTER_DEVICE_INFORMATION_FAILED'
export const NO_PRINTER_DEVICE_INFORMATION_FOUND = 'NO_PRINTER_DEVICE_INFORMATION_FOUND'

export const GET_LPD_QUEUES_STATUS_START = 'GET_LPD_QUEUES_STATUS_START'
export const GET_LPD_QUEUES_STATUS_SUCCESS = 'GET_LPD_QUEUES_STATUS_SUCCESS'
export const GET_LPD_QUEUES_STATUS_FAILED = 'GET_LPD_QUEUES_STATUS_FAILED'
export const NO_LPD_QUEUES_STATUS_FOUND = 'NO_LPD_QUEUES_STATUS_FOUND'

export const GET_OUTPUT_PROCESSES_START = 'GET_OUTPUT_PROCESSES_START'
export const GET_OUTPUT_PROCESSES_SUCCESS = 'GET_OUTPUT_PROCESSES_SUCCESS'
export const GET_OUTPUT_PROCESSES_FAILED = 'GET_OUTPUT_PROCESSES_FAILED'
export const NO_OUTPUT_PROCESSES_FOUND = 'NO_OUTPUT_PROCESSES_FOUND'

/**
 * @description Calling the rest api and list message logs.
 * @param {Function} callback the callback which will be called when the request was successful. Can be null.
 */
export function getMessageLog(searchParams, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SERVER_GET_MESSAGE_LOG_START })

    // clear invalid values of object and encode
    searchParams = ObjectUtils.removeByValue(searchParams, [undefined, null, ''])

    const queryParams = []
    Object.keys(searchParams).forEach(key => queryParams.push(`${key}=${encodeURIComponent(searchParams[key])}`))

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/system/msglog?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_SERVER_MESSAGE_LOG_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SERVER_GET_MESSAGE_LOG_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: SERVER_GET_MESSAGE_LOG_SUCCESS, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('messagelogs.message_logs_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SERVER_GET_MESSAGE_LOG_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and list message logs.
 * @param {Function} callback the callback which will be called when the request was successful. Can be null.
 */
export function getServerLogFile(searchParams, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SERVER_GET_SERVERLOGFILE_SERVER_LOG_FILE_START })

    // clear invalid values of object and encode
    searchParams = ObjectUtils.removeByValue(searchParams, [undefined, null, ''])

    const queryParams = []
    Object.keys(searchParams).forEach(key => queryParams.push(`${key}=${encodeURIComponent(searchParams[key])}`))

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/system/logfile?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_SERVER_SERVERLOGFILE_SERVER_LOG_FILE_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SERVER_GET_SERVERLOGFILE_SERVER_LOG_FILE_FAILED, payload: { error } })
          }
        } else {
          dispatch({ type: SERVER_GET_SERVERLOGFILE_SERVER_LOG_FILE_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(
          Lang.translate('server.log_file_error', lang, reason),
          SnackbarActions.TYPE_ERROR
        )(dispatch)
        dispatch({
          type: SERVER_GET_SERVERLOGFILE_SERVER_LOG_FILE_FAILED,
          payload: { error }
        })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and list system defaults.
 * @param {Function} callback the callback which will be called when the request was successful. Can be null.
 */
export function getSystemDefaults(callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SERVER_GET_SYSTEM_DEFAULTS_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/system/defaults`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            // this should never happen!
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_SERVER_SYSTEM_DEFAULTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SERVER_GET_SYSTEM_DEFAULTS_FAILED })
          }
        }
        else {
          dispatch({ type: SERVER_GET_SYSTEM_DEFAULTS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('systemdefaults.system_defaults_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SERVER_GET_SYSTEM_DEFAULTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and changes a system default.
 * @param {String} keyword The keyword of the system default.
 * @param {*} value The new value of the system default.
 * @param {Function} callback the callback which will be called when the request was successful. Can be null.
 */
export function setSystemDefault(keyword, value, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SERVER_SET_SYSTEM_DEFAULT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/system/default`, {
        method: 'post',
        body: {
          KEYWORD: keyword,
          VALUE: value,
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: SERVER_SET_SYSTEM_DEFAULT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: SERVER_SET_SYSTEM_DEFAULT_SUCCESS })

          SnackbarActions.show(Lang.translate('systemdefaults.modify_success', lang, [keyword, value]), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call the callback on success
          if (callback) {
            callback()
          }

          getSystemDefaults(undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('systemdefaults.set_system_default_error', lang, [keyword, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SERVER_SET_SYSTEM_DEFAULT_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calling the rest api and do getPrinterDeviceInfo request.
 * @param {*} searchParams The params for the search. Possible params:
 * FROMLAST: The from last date pattern,
 * TUNITS: The units of the time (only used on last tab -> custom),
 * SDATE: The start date (only used on date tab),
 * STIME: The start time (only used on date tab),
 * EDATE: The end date (only used on date tab),
 * ETIME: The end time (only used on date tab),
 * DCR: The id of the output channel,
 * MSGNR: The id of the message,
 * MSGTXT: The message text pattern
 * @param {*} callback The callback which will be called when the request was successful. Can be null.
 */
export function getPrinterDeviceInfo(searchParams, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_PRINTER_DEVICE_INFORMATION_START })

    searchParams = ObjectUtils.removeByValue(searchParams, [undefined, null, ''])

    const queryParams = []
    Object.keys(searchParams).forEach(key => queryParams.push(`${key}=${encodeURIComponent(searchParams[key])}`))

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/printer?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_PRINTER_DEVICE_INFORMATION_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_PRINTER_DEVICE_INFORMATION_FAILED, payload: { error } })
          }
        } else {
          dispatch({ type: GET_PRINTER_DEVICE_INFORMATION_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()

        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('server.printer_device_information_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_PRINTER_DEVICE_INFORMATION_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets a lpd queue.
 * @param {Array} fields
 * @param {Object} searchObj
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getLPDQueuesStatus(callback) {
  return dispatch => {

    LoadingSpinnerActions.show()(dispatch)
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_LPD_QUEUES_STATUS_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/lpdqueue/monitor`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_LPD_QUEUES_STATUS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_LPD_QUEUES_STATUS_FAILED })
          }
        }
        else {
          dispatch({ type: GET_LPD_QUEUES_STATUS_SUCCESS, payload: jsondata.data })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.get_lpd_queue_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_LPD_QUEUES_STATUS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and list message logs.
 * @param {Function} callback the callback which will be called when the request was successful. Can be null.
 */
export function getOutputProcesses(searchParams, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: GET_OUTPUT_PROCESSES_START })

    // clear invalid values of object and encode
    searchParams = ObjectUtils.removeByValue(searchParams, [undefined, null])

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/queues/output/monitor`, {
        method: 'POST',
        body: searchParams
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_OUTPUT_PROCESSES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_OUTPUT_PROCESSES_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: GET_OUTPUT_PROCESSES_SUCCESS, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('server.output_processes_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_OUTPUT_PROCESSES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}