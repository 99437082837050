export const CONFIGS = {
  productname: '_beta admin',
  buildhash: 'a79c64f211da9f57f4bdfedc50505f788dde6ad3',
  buildtimestamp: '01.08.2023 15:28:17 (MEST)',
  productversion: '5.3',
  servicepack: '2',
  patchlevel: '',
  fixpack: '%FIXPACK%',
  releasetype: 'GA',
}

export const REST_API_URL = process.env.REACT_APP_REST_API_URL || 'http://127.0.0.1:80'

// timeout for connect to server for fast response
export const CONNECT_TIMEOUT = 10000
// timeout for connect to server for long response
export const CONNECT_TIMEOUT_LONG = 20000

// timeout for fetching data for fast response
export const FETCH_DATA_TIMEOUT = 10000
// timeout for fetching data for long response
export const FETCH_DATA_TIMEOUT_LONG = 20000

// timeout for file imports - should be higher because upload can be <= 100 files
export const UPLOAD_DATA_TIMEOUT = 60000

// timeout for file imports - should be higher because upload can be <= 100 files
export const DOWNLOAD_TIMEOUT = 180000

export const LANGUAGE_STRICT_MODE = false