import { LOGOUT_SUCCESS } from '../actions/AuthenticationActions'
import * as OutputQueueActions from '../actions/OutputQueueActions'

const defaultState = {
  outputs: undefined,
  outputDetails: undefined,
  outputRequestDetails: undefined,
  outputRequestStepDetails: undefined,
  keepPagination: undefined,
  serverStatusCheck: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Get output queues actions
    case OutputQueueActions.GET_OUTPUT_QUEUES_START:
      return { ...state }
    case OutputQueueActions.GET_OUTPUT_QUEUES_SUCCESS:
      return { ...state, outputs: action.payload, keepPagination: action.keepPagination }
    case OutputQueueActions.GET_OUTPUT_QUEUES_FAILED:
      return { ...state }
    case OutputQueueActions.NO_OUTPUT_QUEUES_FOUND:
      return { ...state, outputs: {} }
    /* ********************************************************************************** */
    // get output queue information actions
    case OutputQueueActions.GET_OUTPUT_QUEUE_DETAILS_START:
      return { ...state }
    case OutputQueueActions.GET_OUTPUT_QUEUE_DETAILS_SUCCESS:
      return { ...state, outputDetails: action.payload }
    case OutputQueueActions.GET_OUTPUT_QUEUE_DETAILS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // get output queue information actions
    case OutputQueueActions.GET_OUTPUT_QUEUE_REQUEST_DETAILS_START:
      return { ...state }
    case OutputQueueActions.GET_OUTPUT_QUEUE_REQUEST_DETAILS_SUCCESS:
      return { ...state, outputRequestDetails: action.payload }
    case OutputQueueActions.GET_OUTPUT_QUEUE_REQUEST_DETAILS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // get output queue step details actions
    case OutputQueueActions.GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_START:
      return { ...state }
    case OutputQueueActions.GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_SUCCESS:
      return { ...state, outputRequestStepDetails: action.payload }
    case OutputQueueActions.GET_OUTPUT_QUEUE_REQUEST_STEP_DETAILS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    case OutputQueueActions.CHECK_IPP_STATUS_SUCCESS:
      return { ...state, serverStatusCheck: action.payload }
    /* ********************************************************************************** */
    // Logout actions
    case LOGOUT_SUCCESS:
      return {
        ...state,
        outputs: undefined,
        outputDetails: undefined,
        outputRequestDetails: undefined,
        outputRequestStepDetails: undefined
      }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer