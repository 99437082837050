import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as UrlUtils from 'utils/UrlUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CreateIndexDialog from 'components/dialogs/create_index_dialog/CreateIndexDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyIndexDialog from 'components/dialogs/modify_index_dialog/ModifyIndexDialog'
import VerifyIndex from 'components/dialogs/verify_index_dialog/VerifyIndex'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as IndexDefinitionActions from 'redux/actions/IndexDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as SortUtils from 'utils/SortUtils'
import * as UserUtils from 'utils/UserUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

// :kissing_closed_eyes:
class SearchResultIndex extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired
  }

  state = {
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    showVerifyDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation(),
    createDialogData: undefined
  }

  fillHeaderInformation() {
    return [
      { rest: INDEXID, translation: 'definition.index_name_id', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: DATAFORMAT, translation: 'definition.index_data_format', default: true },
      { rest: IDENTIFIER, translation: 'definition.index_identifier', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER },
    ]
  }

  /**
   * @description Handles the modal dialogs for create, modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { getIndex, indexes } = this.props
    const name = indexes.data[index][this.headerData('IXINAME')]
    const callback = () => this.setState({ [dialog]: true })
    getIndex(undefined, name, callback)
  }

  /**
   * @description Handles the refresh button
   */
  handleRefresh = () => {
    const { getIndexes, preferences } = this.props
    const name = preferences[Preferences.DEFINITION_INDEX_ID]
    const identifier = preferences[Preferences.DEFINITION_INDEX_IDENTIFIER]
    const dataFormat = preferences[Preferences.DEFINITION_INDEX_DATA_FORMAT]
    const owner = preferences[Preferences.DEFINITION_INDEX_OWNER]

    getIndexes(undefined, name, identifier, dataFormat, owner)
  }

  /**
   * @description Gets the verify information and shows the dialog
   */
  handleVerify = index => {
    const { indexes, getIndex, verifyIndex } = this.props
    const name = indexes.data[index][this.headerData('IXINAME')]
    const callback = () => this.setState({ showVerifyDialog: true })
    getIndex(undefined, name, () => {
      verifyIndex(name, callback)
    })
  }

  /**
   * @description Deletes the selected index.
   */
  deleteIndex = () => {
    const { index, deleteIndex } = this.props
    const callback = () => { this.setState({ showDeleteDialog: false }) }
    deleteIndex(index.IXINAME, callback)
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  showDocuments = index => {
    const { indexes } = this.props
    const header = indexes.header
    const data = indexes.data[index]
    const keys = [
      { rest: 'IXINAME', ui: 'index' }
    ]

    const state = {
      index: data[header.indexOf('IXINAME')]
    }

    const urlToPush = `/assignment/${UserUtils.isDOCX() ? 'index_document' : 'index_log'}${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id, lang } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit', lang)}
        onClick={() => { this.handleDialog(rowIndex, 'showModifyDialog') }}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete', lang)}
        onClick={() => { this.handleDialog(rowIndex, 'showDeleteDialog') }}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options', lang)}>
        <TableButtonGroupItem
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit', lang)}
          title={translate('general.edit', lang)}
          onClick={() => { this.handleDialog(rowIndex, 'showModifyDialog') }}
        />
        <TableButtonGroupItem
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy', lang)}
          title={translate('general.copy', lang)}
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
        />
        <TableButtonGroupItem
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete', lang)}
          title={translate('general.delete', lang)}
          onClick={() => { this.handleDialog(rowIndex, 'showDeleteDialog') }}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          id={`${id}_verifyBtn`}
          icon={<Icon name='verify' className='actionIcon' />}
          text={translate('general.verify', lang)}
          title={translate('general.verify', lang)}
          onClick={() => this.handleVerify(rowIndex)}
        />
        <TableButtonGroupItem
          onClick={() => this.showDocuments(rowIndex)}
          id={`${id}_show_documents_btn`}
          icon={<Icon name='document' className='actionIcon' />}
          text={translate('general.display_documents')}
          title={translate('general.display_documents')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.showPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description gets the index of the header in redux state definitions.indexes.header
   * @param {String} header header name of the header in redux definitions.state indexes.header
   */
  headerData = header => this.props.indexes.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, indexes } = this.props
    const data = []
    const dataFormatTranslation = DefinitionUtils.getIndexDataFormatTypes()
    const headers = this.getUsedHeader()
    indexes.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === DATAFORMAT) {
          const format = el[this.headerData('IXDTYPE')]
          const found = dataFormatTranslation.find(element => element.key === format)
          buffer.push(found ? translate(found.translationKey) : format)
        }
        else if (header === LASTCHANGED) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          buffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            buffer.push(`${val}:00`)
          }
          else {
            buffer.push(val)
          }
        }
      })
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_INDEX]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_INDEX].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_INDEX]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_INDEX].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  showPrefilledCreateDialog = () => {
    const { preferences } = this.props
    this.setState({
      showCreateDialog: true,
      createDialogData: {
        IXINAME: preferences[Preferences.DEFINITION_INDEX_ID],
        OWNER: preferences[Preferences.DEFINITION_INDEX_OWNER],
        IXENAME: preferences[Preferences.DEFINITION_INDEX_IDENTIFIER],
        IXDTYPE: preferences[Preferences.DEFINITION_INDEX_DATA_FORMAT]
      }
    })
  }

  render = () => {
    const { id, indexes, drawerExpanded, autoDrawer, index, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showCopyDialog, showDeleteDialog, showModifyDialog, showVerifyDialog } = this.state
    const data = indexes && indexes.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    const idxType = index && DefinitionUtils.getIndexDataFormatTypes().find(element => element.key === index?.IXDTYPE)
    return (
      <>
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_INDEX }}
          />
        )}
        {/* create index dialog */}
        {showCreateDialog && (
          <CreateIndexDialog
            id={`${id}_createdialog`}
            visible={showCreateDialog}
            copyIndex={false}
            prefilledData={this.state.createDialogData}
            onClose={() => { this.setState({ showCreateDialog: false, createDialogData: undefined }) }}
          />
        )}
        {/* modify index dialog */}
        {showModifyDialog && (
          <ModifyIndexDialog
            id={`${id}_modifydialog`}
            visible={showModifyDialog}
            onClose={() => { this.setState({ showModifyDialog: false }) }}
          />
        )}
        {showCopyDialog && (
          <CreateIndexDialog
            id={`${id}_copydialog`}
            visible={showCopyDialog}
            copyIndex={true}
            onClose={() => { this.setState({ showCopyDialog: false }) }}
          />
        )}
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_deletedialog`}
            title={translate('definition.delete_index')}
            question={translate('definition.question_delete_index')}
            onClose={() => { this.setState({ showDeleteDialog: false }) }}
            onDelete={() => { this.deleteIndex() }}>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_index_id_key_text`}>
                  {translate('definition.index_id')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_index_id_value_text`}>
                  {index.IXINAME}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_index_dataformat_key_text`}>
                  {translate('definition.index_data_format')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_index_dataformat_value_text`}>
                  {idxType ? translate(idxType.translationKey) : index.IXDTYPE}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_index_identifier_key_text`}>
                  {translate('definition.index_identifier')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_index_identifier_value_text`}>
                  {index.IXENAME}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showVerifyDialog && (
          <VerifyIndex
            id={`${id}_verifydialog`}
            onClose={() => this.setState({ showVerifyDialog: false })}
          />
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {indexes
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={index => { this.handleDialog(index, 'showModifyDialog') }}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_index_result')}
                    link={translate('emptyresult.create_index_link')}
                    onClick={() => this.showPrefilledCreateDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const INDEXID = 'IXINAME'
const IDENTIFIER = 'IXENAME'
const DATAFORMAT = 'IXDTYPE'
const OWNER = 'OWNER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    indexes: state.definitions.indexes.indexes,
    index: state.definitions.indexes.index,
    keepPagination: state.definitions.indexes.keepPagination,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getIndexes: (fields, index, identifier, dataFormat, owner, callback) => {
      IndexDefinitionActions.getIndexes(fields, index, identifier, dataFormat, owner, callback)(dispatch)
    },
    getIndex: (fields, index, callback) => {
      IndexDefinitionActions.getIndex(fields, index, callback)(dispatch)
    },
    deleteIndex: (index, callback) => {
      IndexDefinitionActions.deleteIndex(index, callback)(dispatch)
    },
    verifyIndex: (index, callback) => {
      IndexDefinitionActions.verifyIndex(index, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultIndex))