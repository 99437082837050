import * as LpdQueueDefinitionActions from '../actions/LpdQueueDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  lpdQueues: undefined,
  lpdQueue: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get lpd queue actions
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_START:
      return { ...state, lpdQueue: undefined }
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_SUCCESS:
      return { ...state, lpdQueue: action.payload }
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_GET_LPD_QUEUE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition get lpd queues actions
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_START:
      return { ...state }
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_SUCCESS:
      return { ...state, lpdQueues: action.payload.data, keepPagination: action.keepPagination }
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_GET_LPD_QUEUES_FAILED:
      return { ...state }
    case LpdQueueDefinitionActions.NO_LPD_QUEUE_DEFINITION_LPD_QUEUES_FOUND:
      return { ...state, lpdQueues: {} }
    /* ********************************************************************************** */
    // Definition create lpd queue actions
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_START:
      return { ...state }
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_SUCCESS:
      return { ...state }
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_CREATE_LPD_QUEUE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete lpd queue actions
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_START:
      return { ...state }
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_SUCCESS:
      return { ...state }
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_DELETE_LPD_QUEUE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update lpd queue actions
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_START:
      return { ...state }
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_SUCCESS:
      return { ...state }
    case LpdQueueDefinitionActions.LPD_QUEUE_DEFINITION_UPDATE_LPD_QUEUE_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, lpdQueues: undefined, lpdQueue: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer