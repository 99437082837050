import { createContext, useContext } from 'react';

import * as TYPES_DOC_VIEWER from '../types/DocumentViewer.types';

export const TabContentContext = createContext<Record<string, never> | TYPES_DOC_VIEWER.ITabContentContext>({});

export const useTabContentContext = () => {
  const context = useContext(TabContentContext);

  if (!context) {
    throw new Error('useTabContentContext must be used within TabContentProvider');
  }

  return context;
};