import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CreateOutputFormatDialog from 'components/dialogs/create_output_format_dialog/CreateOutputFormatDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyOutputFormatDialog from 'components/dialogs/modify_output_format_dialog/ModifyOutputFormatDialog'
import VerifyOutputFormat from 'components/dialogs/verify_output_format/VerifyOutputFormat'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import * as OutputFormatActions from 'redux/actions/OutputFormatDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as SortUtils from 'utils/SortUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultOutputFormat extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  state = {
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    showVerifyDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation(),
    createDialogData: undefined
  }

  fillHeaderInformation() {
    return [
      { rest: OUTPUTFORMATID, translation: 'definition.output_format_id', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER }
    ]
  }

  showVerify = index => {
    const { verifyOutputFormat, getOutputFormat, outputFormats } = this.props
    const name = outputFormats.data[index][this.headerData('PCR')]
    const callback = () => this.setState({ showVerifyDialog: true })
    getOutputFormat(undefined, name, () => {
      verifyOutputFormat(name, callback)
    })
  }

  /**
   * @description Handles the refresh button
   */
  handleRefresh = () => {
    const { getOutputFormats, preferences } = this.props
    const name = preferences[Preferences.DEFINITION_OUTPUTFORMAT_ID]
    const owner = preferences[Preferences.DEFINITION_OUTPUTFORMAT_OWNER]
    const title = preferences[Preferences.DEFINITION_OUTPUTFORMAT_TITLE]

    getOutputFormats(undefined, name, owner, title)
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id, lang } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit', lang)}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete', lang)}
        onClick={() => { this.handleDialog(rowIndex, 'showDeleteDialog') }}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options', lang)}>
        <TableButtonGroupItem
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit', lang)}
          title={translate('general.edit', lang)}
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
        />
        <TableButtonGroupItem
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy', lang)}
          title={translate('general.copy', lang)}
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
        />
        <TableButtonGroupItem
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete', lang)}
          title={translate('general.delete', lang)}
          onClick={() => { this.handleDialog(rowIndex, 'showDeleteDialog') }}
        />
        <TableButtonGroupSeparator id={id} />
        <TableButtonGroupItem
          id={`${id}_verifyBtn`}
          icon={<Icon name='verify' className='actionIcon' />}
          text={translate('general.verify', lang)}
          title={translate('general.verify', lang)}
          onClick={() => this.showVerify(rowIndex)}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Handles the modal dialogs for create, modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { getOutputFormat, outputFormats } = this.props
    const name = outputFormats.data[index][this.headerData('PCR')]
    const callback = () => this.setState({ [dialog]: true })
    getOutputFormat(undefined, name, callback)
  }

  /**
   * @description Deletes the selected lpd queue.
   */
  deleteOutputFormat = () => {
    const name = this.props.outputFormat.PCR

    // declare success callback
    const callback = () => { this.setState({ showDeleteDialog: false }) }

    this.props.deleteOutputFormat(name, callback)
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create', lang)}
          onClick={() => this.showPrefilledCreateDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings', lang)}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description gets the index of the header in redux state definitions.outputFormats.header
   * @param {String} header header name of the header in redux definitions.state outputFormats.header
   */
  headerData = header => this.props.outputFormats.header.indexOf(header)

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData = () => {
    const { datemask, outputFormats } = this.props
    const data = []
    const headers = this.getUsedHeader()
    outputFormats.data.forEach(el => {
      const buffer = []
      headers.forEach(header => {
        if (header === LASTCHANGED) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CTIME) {
          buffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          buffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            buffer.push(`${val}:00`)
          }
          else {
            buffer.push(val)
          }
        }
      })
      data.push(buffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_OUTPUTFORMAT]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_OUTPUTFORMAT].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_OUTPUTFORMAT]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DEFINITION_OUTPUTFORMAT].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  showPrefilledCreateDialog = () => {
    const { preferences } = this.props
    this.setState({
      showCreateDialog: true,
      createDialogData: {
        PCR: preferences[Preferences.DEFINITION_OUTPUTFORMAT_ID],
        OWNER: preferences[Preferences.DEFINITION_OUTPUTFORMAT_OWNER],
        PCRTITLE: preferences[Preferences.DEFINITION_OUTPUTFORMAT_TITLE]
      }
    })
  }

  render = () => {
    const { id, outputFormats, outputFormat, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showDeleteDialog, showModifyDialog, showCopyDialog, showVerifyDialog } = this.state
    const data = outputFormats && outputFormats.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {showCreateDialog && (
          <CreateOutputFormatDialog
            id={`${id}_createdialog`}
            copyOutputFormat={false}
            prefilledData={this.state.createDialogData}
            onClose={() => this.setState({ showCreateDialog: false, createDialogData: undefined })}
          />
        )}
        {showModifyDialog && (
          <ModifyOutputFormatDialog
            id={`${id}_modifydialog`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        )}
        {showCopyDialog && (
          <CreateOutputFormatDialog
            id={`${id}_copydialog`}
            copyOutputFormat={true}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_OUTPUTFORMAT }}
          />
        )
        }
        {/* delete outputformat dialog */}
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_deletedialog`}
            title={translate('definition.del_output_format')}
            question={translate('definition.question_del_output_format')}
            onClose={() => { this.setState({ showDeleteDialog: false }) }}
            onDelete={() => { this.deleteOutputFormat() }}>
            {/* id */}
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_output_format_id_key_text`}>
                  {translate('definition.output_format_id')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_output_format_id_value_text`}>
                  {outputFormat.PCR}
                </p>
              </Column>
            </Row>
            {/* title */}
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}_output_format_title_key_text`}>
                  {translate('definition.title')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}_output_format_title_value_text`}>
                  {outputFormat.PCRTITLE}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {
          showVerifyDialog && (
            <VerifyOutputFormat
              id={`${id}_verifydialog`}
              onClose={() => this.setState({ showVerifyDialog: false })}
            />
          )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {outputFormats
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={(rowIndex) => this.handleDialog(rowIndex, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_outputformat_result')}
                    link={translate('emptyresult.create_outputformat_link')}
                    onClick={() => this.showPrefilledCreateDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const OUTPUTFORMATID = 'PCR'
const TITLE = 'PCRTITLE'
const OWNER = 'OWNER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'
const LASTCHANGED = 'LASTCHANGED'

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    outputFormats: state.definitions.outputformats.outputFormats,
    outputFormat: state.definitions.outputformats.outputFormat,
    keepPagination: state.definitions.outputformats.keepPagination,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOutputFormats: (fields, outputFormat, owner, title, callback) => {
      OutputFormatActions.getOutputFormats(fields, outputFormat, owner, title, callback)(dispatch)
    },
    getOutputFormat: (fields, outputFormat, callback) => {
      OutputFormatActions.getOutputFormat(fields, outputFormat, callback)(dispatch)
    },
    deleteOutputFormat: (outputFormat, callback) => {
      OutputFormatActions.deleteOutputFormat(outputFormat, callback)(dispatch)
    },
    verifyOutputFormat: (outputFormat, callback) => {
      OutputFormatActions.verifyOutputFormat(outputFormat, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultOutputFormat)