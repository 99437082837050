import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as SortUtils from 'utils/SortUtils'

// components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem
} from 'BetaUX2Web-Components/src/'
import CopyRecipientDocumentAssignmentDialog from 'components/dialogs/copy_recipient_document_assignment_dialog/CopyRecipientDocumentAssignmentDialog'
import CreateRecipientDocumentAssignmentDialog from 'components/dialogs/create_recipient_document_assignment_dialog/CreateRecipientDocumentAssignmentDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyRecipientDocumentAssignmentDialog from 'components/dialogs/modify_recipient_document_assignment_dialog/ModifyRecipientDocumentAssignmentDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as RecipientDocumentAssignmentActions from 'redux/actions/RecipientDocumentAssignmentActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as UserUtils from 'utils/UserUtils'
import { getDataFromUrlParams } from 'utils/UrlUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultRecipientDocumentAssignment extends Component {
  state = {
    header: this.fillHeaderInformation(),
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    showTableSettingsDialog: false,
  }

  componentDidMount = () => {
    if (window.location.search) {
      // Case: User clicked 'Show recipients' for Document entry:
      const urlParameters = getDataFromUrlParams(window.location.search)
      this.initialSearchViaUrlParameters(urlParameters)
    }
  }

  initialSearchViaUrlParameters = (urlParameters) => {
    const prefsToChange = {
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_RECIPIENTID]: urlParameters.RECI,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_FORM]: urlParameters.FORM,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_EXTENSION]: urlParameters.EXT,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_REPORT]: urlParameters.REPORT,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_DYNAMICREPORT]: urlParameters.WREPORT,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_ASSIGNMENT]: urlParameters.LTYPE,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_DISTRIBUTEVIA]: urlParameters.BRWS,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTCHANNEL]: urlParameters.DCR,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTFORMAT]: urlParameters.PCR,
      [Preferences.ASSIGNMENT_RECIPIENT_DOC_PPN]: urlParameters.PPN
    }

    this.props.changePrefs(prefsToChange)

    this.props.getRecipientDocumentAssignments(
      undefined,
      urlParameters.RECI,
      urlParameters.FORM,
      urlParameters.EXT,
      urlParameters.REPORT,
      urlParameters.WREPORT,
      urlParameters.LTYPE,
      urlParameters.BRWS,
      urlParameters.DCR,
      urlParameters.PCR,
      urlParameters.PPN
    )
  }

  /**
   * @description Builds the header array with rest value and translation key.
   * @returns {Array} The header array.
   */
  fillHeaderInformation() {
    return [
      { rest: RECI, translation: 'recipient.reci_id', default: true },
      { rest: FORM, translation: 'general.form', default: true },
      { rest: EXT, translation: 'general.extension', default: true },
      { rest: REPORT, translation: 'general.report', default: true },
      { rest: WREPORT, translation: 'general.dynamic_report', default: true },
      { rest: LTYPE, translation: 'general.assignment', default: true },
      { rest: BRWS, translation: 'recipient.distribution_via', default: true },
      { rest: OWNER, translation: 'general.owner' },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description Refreshes the table
   */
  handleRefresh = () => {
    const { getRecipientDocumentAssignments, preferences } = this.props
    getRecipientDocumentAssignments(
      undefined,
      preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_RECIPIENTID],
      preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_FORM],
      preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_EXTENSION],
      preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_REPORT],
      preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_DYNAMICREPORT],
      preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_ASSIGNMENT],
      preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_DISTRIBUTEVIA],
      preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTCHANNEL],
      preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_OUTPUTFORMAT],
      preferences[Preferences.ASSIGNMENT_RECIPIENT_DOC_PPN],
    )
  }

  /**
   * @description Handles the modal dialogs for modify, copy, delete
   * @param {Number} index Index of the data array
   * @param {String} dialog Which dialog to open
   */
  handleDialog = (index, dialog) => {
    const { getRecipientDocumentAssignment, recipientAssignments } = this.props
    const recipient = recipientAssignments.data[index][this.headerData('RECI')]
    const form = recipientAssignments.data[index][this.headerData('FORM')]
    const extension = recipientAssignments.data[index][this.headerData('EXT')]
    const report = recipientAssignments.data[index][this.headerData('REPORT')]
    const dynamicReport = recipientAssignments.data[index][this.headerData('WREPORT')]
    const callback = () => this.setState({ [dialog]: true })
    getRecipientDocumentAssignment(undefined, recipient, form, extension, report, dynamicReport, callback)
  }

  /**
   * @description Deletes the current recipient document assignment.
   */
  deleteAssignment = () => {
    const { recipientAssignment, deleteRecipientDocumentAssignment } = this.props

    const assignment = {
      RECI: recipientAssignment['RECI'],
      FORM: recipientAssignment['FORM'],
      EXT: recipientAssignment['EXT'],
      REPORT: recipientAssignment['REPORT'],
      WREPORT: recipientAssignment['WREPORT'],
    }
    // declare success callback
    const callback = () => { this.setState({ showDeleteDialog: false }) }
    // deletes the specific recipient document assignment
    deleteRecipientDocumentAssignment(assignment, callback)
  }

  /**
   * @description Gets specific column sort definitions.
   * @param {Array} data The data array.
   * @param {Array} header The header array.
   * @returns {Array} The sort defs for each row.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Creates the action buttons for the table.
   * @param {Number} rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
      </TableButtonGroup>
    ]
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.setState({ showCreateDialog: true })}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description Gets the index of the header.
   * @param {String} header header name of the header.
   * @returns {Number}The index of the header.
   */
  headerData = header => this.props.recipientAssignments.header.indexOf(header)

  /**
   * @description Builds the data.
   * @returns {Array} The data.
   */
  getData = () => {
    const { recipientAssignments, datemask } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    recipientAssignments.data.forEach(element => {
      let dataBuffer = []
      usedHeader.forEach(h => {
        if (h === BRWS) {
          const found = DefinitionUtils.RECIPIENT_DISTRIBUTIONS.find(el => el.key === element[this.headerData(h)])
          dataBuffer.push(found ? translate(found.translationKey) : element[this.headerData(h)])
        }
        else if (h === LTYPE) {
          const found = DefinitionUtils.RECIPIENT_DOC_ASSIGNMENTS.find(el => el.key === element[this.headerData(h)])
          dataBuffer.push(found ? translate(found.translationKey) : element[this.headerData(h)])
        }
        else if (h === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.delete')} ${translate('general.recipient_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'folder_document_relation_modal_title'} name={'relation'} tabIndex={-1}/>,
      <div
        key={'title_3'}>
        {`${UserUtils.isDOCX()
          ? translate('general.document_modal_title')
          : translate('general.log_modal_title')}  ${translate('general.assignment_modal_title')}`}
      </div>,
    ]
  }

  getModalQuestion = () => {
    return [
      <div key={'title_1'}>{translate('assignment.recipient_document_delete_question_1')}</div>,
      <Icon key={'title_2'} id={'recipient_document_relation_modal_question'} name={'relation'} />,
      <div key={'title_3'}>{translate(UserUtils.isDOCX() ? 'assignment.recipient_document_delete_question_2' : 'assignment.index_log_delete_question_2')}</div>
    ]
  }

  getNoResultTitle = () => {
    if (UserUtils.isDOCX()) {
      return [
        `${translate('assignment.no_alt')} `,
        `${translate('general.recipient_modal_title')}`,
        <Icon key={'title_assignment_icon'} id={'recipient_document_relation_modal_title'} name={'relation'} />,
        `${translate('general.document_modal_title')} `,
        `${translate('general.assignment_modal_title')} `,
        ` ${translate('assignment.no_match_single')}`
      ]
    }
    else if (UserUtils.isLOGX()) {
      return [
        `${translate('assignment.no_alt')} `,
        `${translate('general.recipient_modal_title')}`,
        <Icon key={'title_assignment_icon'} id={'recipient_document_relation_modal_title'} name={'relation'} />,
        `${translate('general.log_modal_title')} `,
        `${translate('general.assignment_modal_title')} `,
        ` ${translate('assignment.no_match_single')}`
      ]
    }
  }

  getNoResultLink = () => {
    if (UserUtils.isDOCX()) {
      return [
        `${translate('assignment.you_can')} `,
        `${translate('general.recipient_modal_title')}`,
        <Icon key={'link_assignment_icon'} id={'recipient_document_relation_modal_title'} name={'relation'} />,
        `${translate('general.document_modal_title')} `,
        `${translate('general.assignment_modal_title')} `,
        ` ${translate('assignment.create')}`
      ]
    }
    else if (UserUtils.isLOGX()) {
      return [
        `${translate('assignment.you_can')} `,
        `${translate('general.recipient_modal_title')}`,
        <Icon key={'link_assignment_icon'} id={'recipient_document_relation_modal_title'} name={'relation'} />,
        `${translate('general.log_modal_title')} `,
        `${translate('general.assignment_modal_title')} `,
        ` ${translate('assignment.create')}`
      ]
    }
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_RECIPIENT_DOCUMENT_ASSIGNMENT]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_RECIPIENT_DOCUMENT_ASSIGNMENT].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_RECIPIENT_DOCUMENT_ASSIGNMENT]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_RECIPIENT_DOCUMENT_ASSIGNMENT].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  render = () => {
    const { id, drawerExpanded, autoDrawer, recipientAssignments, recipientAssignment, lang, datemask, keepPagination } = this.props
    const { showTableSettingsDialog, showDeleteDialog, showCreateDialog, showCopyDialog, showModifyDialog } = this.state
    const data = recipientAssignments && recipientAssignments.data ? this.getData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showCreateDialog && (
          <CreateRecipientDocumentAssignmentDialog
            id={`${id}_createrecipientdocumentassignment`}
            onClose={() => this.setState({ showCreateDialog: false })}
          />
        )}
        {showCopyDialog && (
          <CopyRecipientDocumentAssignmentDialog
            id={`${id}_copyrecipientdocumentassignment`}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        )}
        {showModifyDialog && (
          <ModifyRecipientDocumentAssignmentDialog
            id={`${id}_modifyrecipientdocumentassignment`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        )}
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_deleterecipientdocumentassignmentdialog`}
            title={this.getModalTitle()}
            question={this.getModalQuestion()}
            onClose={() => { this.setState({ showDeleteDialog: false }) }}
            onDelete={() => { this.deleteAssignment() }}
          >
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_recipientid_text`}>
                  {translate('recipient.reci_id')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_recipientid`}>
                  {recipientAssignment['RECI']}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_form_text`}>
                  {translate('general.form')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_form`}>
                  {recipientAssignment['FORM']}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_extension_text`}>
                  {translate('general.extension')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_extension`}>
                  {recipientAssignment['EXT']}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_report_text`}>
                  {translate('general.report')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_report`}>
                  {recipientAssignment['REPORT']}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}
                offsetMD={0}>
                <p
                  id={`${id}_dynamicreport_text`}>
                  {translate('general.dynamic_report')}:
                </p>
              </Column>
              <Column
                colMD={9}
                offsetMD={0}>
                <p
                  id={`${id}_dynamicreport`}>
                  {recipientAssignment['WREPORT']}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_RECIPIENT_DOCUMENT_ASSIGNMENT }}
          />
        )}
        {/* Render search result or empty-result-component */}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {recipientAssignments
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={rowIndex => this.handleDialog(rowIndex, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    fillPage={fillPage}
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={this.getNoResultTitle()}
                    link={this.getNoResultLink()}
                    onClick={() => this.setState({ showCreateDialog: true })}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const RECI = 'RECI'
const FORM = 'FORM'
const EXT = 'EXT'
const REPORT = 'REPORT'
const WREPORT = 'WREPORT'
const LTYPE = 'LTYPE'
const BRWS = 'BRWS'
const OWNER = 'OWNER'
const LASTCHANGED = 'LASTCHANGED'
const CDATE = 'CDATE'
const CTIME = 'CTIME'

SearchResultRecipientDocumentAssignment.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    preferences: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    recipientAssignments: state.assignments.recipientdocumentassignment.recipientDocumentAssignments,
    recipientAssignment: state.assignments.recipientdocumentassignment.recipientDocumentAssignment,
    keepPagination: state.assignments.recipientdocumentassignment.keepPagination,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getRecipientDocumentAssignments: (fields, recipientId, form, extension, report, dynamicReport, assignment, distributionVia, outputChannel, outputFormat, ppn, callback) => {
      RecipientDocumentAssignmentActions.getRecipientDocumentAssignments(fields, recipientId, form,
        extension, report, dynamicReport, assignment, distributionVia, outputChannel, outputFormat, ppn, callback)(dispatch)
    },
    getRecipientDocumentAssignment: (fields, recipient, form, extension, report, dynamicReport, callback) => {
      RecipientDocumentAssignmentActions.getRecipientDocumentAssignment(fields, recipient, form,
        extension, report, dynamicReport, callback)(dispatch)
    },
    deleteRecipientDocumentAssignment: (recipientDocumentAssignment, callback) => {
      RecipientDocumentAssignmentActions.deleteRecipientDocumentAssignment(recipientDocumentAssignment, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultRecipientDocumentAssignment)