import React from 'react';

import { DrawerMain } from './drawer_body/DrawerDocumentInfo';
import { DrawerHexMenu } from './drawer_body/DrawerHexMenu';
import { DrawerFooter } from './drawer_footer/DrawerFooter';
import './Drawer.scss';

import * as DATA from '../constants/data';

import { useDrawerContext } from '../contexts/DrawerContext';
import { useTabContentContext } from '../contexts/TabContentContext';

import { translate } from 'language/Language';

import { DocumentDrawerProvider } from '../provider/DrawerProvider';

export const Drawer = () => {
  const { viewMode } = useTabContentContext();

  const drawerContent = () => {
    switch (viewMode) {
      case DATA.TAB_CONFIG_VIEW_MODE_TEXT: return <DrawerDocumentInfo />;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK: return <DrawerHexConfig />;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER: return <DrawerHexConfig />;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER_RESOURCES: return <DrawerHexConfig />;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_RESOURCES: return <DrawerHexConfig />;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE: return <DrawerHexConfig />;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER: return <DrawerHexConfig />;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER_RESOURCES: return <DrawerHexConfig />;
      case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_RESOURCES: return <DrawerHexConfig />;
      default: return <DrawerDocumentInfo />;
    }
  };

  return (
    <DocumentDrawerProvider>
      {drawerContent()}
    </DocumentDrawerProvider>
  )
};

export const DrawerInfoContainer = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const { id } = useTabContentContext();
  const { documentInfo } = useDrawerContext();

  return (
    <div
      id={`${id}`}
      className='drawer_field'>
      <>
        <div id={`${id}_definition_container1`} className='drawer_header_big bux_drawer_header drawer_content_header'>
          <label
            id={`${id}_title`}
            className='drawer_header_text'>
            {translate('definition.document')}
            {': ' + documentInfo.jobname}
          </label>
        </div>
        {children}
      </>
    </div>
  );
};

/**
 * @description This component renders the document info in drawer.
 * @prop id - The ID of the open tab (e.g. Document ID, Job ID or UKey)
 */
export const DrawerDocumentInfo = (): JSX.Element => {
  return (
    <DrawerInfoContainer>
      <DrawerMain />
      <DrawerFooter />
    </DrawerInfoContainer>
  )
};

export const DrawerHexConfig = () => {
  return (
    <DrawerInfoContainer>
      <DrawerHexMenu />
    </DrawerInfoContainer>
  )
};