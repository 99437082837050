import PropTypes from 'prop-types'
import { Component } from 'react'

import {
  Button, Card, Column,
  Input,
  Row, Toggle
} from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

import { translate } from 'language/Language'

import { connect } from 'react-redux'
import * as BundleQueueActions from 'redux/actions/BundleQueueActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'

class QueueBundle extends Component {

  defaultState = {
    outputChannel: {
      value: '',
      error: ''
    },
    outputFormat: {
      value: '',
      error: ''
    },
    address: {
      value: '',
      error: ''
    },
    recipient: {
      value: '',
      error: ''
    },
    form: {
      value: '',
      error: ''
    },
    extension: {
      value: '',
      error: ''
    },
    report: {
      value: '',
      error: ''
    },
    wreport: {
      value: '',
      error: ''
    },
    jobname: {
      value: '',
      error: ''
    },
    onlyStatusHold: false,
    docuser1: {
      value: '',
      error: ''
    },
    docuser2: {
      value: '',
      error: ''
    },
    docuser3: {
      value: '',
      error: ''
    },
    docuser4: {
      value: '',
      error: ''
    },
    docuser5: {
      value: '',
      error: ''
    },
    docuser6: {
      value: '',
      error: ''
    },
    docuser7: {
      value: '',
      error: ''
    },
    docuser8: {
      value: '',
      error: ''
    },
    showOutputChannelSelectorDialog: false,
    showOutputFormatSelectorDialog: false,
    showDocumentSelectorDialog: false
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount() {
    // initialize search fields
    const { preferences } = this.props
    if (preferences) {
      let outputChannel = preferences[Preferences.QUEUE_BUNDLE_OUTPUT_CHANNEL] || ''
      let outputFormat = preferences[Preferences.QUEUE_BUNDLE_OUTPUT_FORMAT] || ''
      let address = preferences[Preferences.QUEUE_BUNDLE_ADDRESS] || ''
      let recipient = preferences[Preferences.QUEUE_BUNDLE_RECIPIENT] || ''
      let form = preferences[Preferences.QUEUE_BUNDLE_FORM] || ''
      let extension = preferences[Preferences.QUEUE_BUNDLE_EXTENSION] || ''
      let report = preferences[Preferences.QUEUE_BUNDLE_REPORT] || ''
      let wreport = preferences[Preferences.QUEUE_BUNDLE_WREPORT] || ''
      let jobname = preferences[Preferences.QUEUE_BUNDLE_JOBNAME] || ''
      // preference is saved as string so we need to convert it to bool
      let onlyStatusHold = preferences[Preferences.QUEUE_BUNDLE_ONLY_STATUS_HOLD] ? JSON.parse(preferences[Preferences.QUEUE_BUNDLE_ONLY_STATUS_HOLD]) : false
      let docuser1 = preferences[Preferences.QUEUE_BUNDLE_DOCUSER1] || ''
      let docuser2 = preferences[Preferences.QUEUE_BUNDLE_DOCUSER2] || ''
      let docuser3 = preferences[Preferences.QUEUE_BUNDLE_DOCUSER3] || ''
      let docuser4 = preferences[Preferences.QUEUE_BUNDLE_DOCUSER4] || ''
      let docuser5 = preferences[Preferences.QUEUE_BUNDLE_DOCUSER5] || ''
      let docuser6 = preferences[Preferences.QUEUE_BUNDLE_DOCUSER6] || ''
      let docuser7 = preferences[Preferences.QUEUE_BUNDLE_DOCUSER7] || ''
      let docuser8 = preferences[Preferences.QUEUE_BUNDLE_DOCUSER8] || ''

      this.setState({
        outputChannel: { value: outputChannel, error: '' },
        outputFormat: { value: outputFormat, error: '' },
        address: { value: address, error: '' },
        recipient: { value: recipient, error: '' },
        form: { value: form, error: '' },
        extension: { value: extension, error: '' },
        report: { value: report, error: '' },
        wreport: { value: wreport, error: '' },
        jobname: { value: jobname, error: '' },
        onlyStatusHold: onlyStatusHold,
        docuser1: { value: docuser1, error: '' },
        docuser2: { value: docuser2, error: '' },
        docuser3: { value: docuser3, error: '' },
        docuser4: { value: docuser4, error: '' },
        docuser5: { value: docuser5, error: '' },
        docuser6: { value: docuser6, error: '' },
        docuser7: { value: docuser7, error: '' },
        docuser8: { value: docuser8, error: '' },
      })
    }
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Sets the changes to the state.
   * @param {String} key The key in state.
   * @param {String} value The new value.
   * @param {String} error The new error key.
   */
  handleChange = (key, value, error) => {
    this.setState({
      [key]: {
        value,
        error
      }
    })
  }

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = (event) => {
    event.preventDefault()

    const {
      outputChannel, outputFormat, address, recipient, form, extension, report, wreport,
      jobname, onlyStatusHold, docuser1, docuser2, docuser3, docuser4, docuser5, docuser6,
      docuser7, docuser8 } = this.state

    // save search values in preferences
    const prefsToChange = {
      [Preferences.QUEUE_BUNDLE_OUTPUT_CHANNEL]: outputChannel.value,
      [Preferences.QUEUE_BUNDLE_OUTPUT_FORMAT]: outputFormat.value,
      [Preferences.QUEUE_BUNDLE_ADDRESS]: address.value,
      [Preferences.QUEUE_BUNDLE_RECIPIENT]: recipient.value,
      [Preferences.QUEUE_BUNDLE_FORM]: form.value,
      [Preferences.QUEUE_BUNDLE_EXTENSION]: extension.value,
      [Preferences.QUEUE_BUNDLE_REPORT]: report.value,
      [Preferences.QUEUE_BUNDLE_WREPORT]: wreport.value,
      [Preferences.QUEUE_BUNDLE_JOBNAME]: jobname.value,
      [Preferences.QUEUE_BUNDLE_ONLY_STATUS_HOLD]: onlyStatusHold,
      [Preferences.QUEUE_BUNDLE_DOCUSER1]: docuser1.value,
      [Preferences.QUEUE_BUNDLE_DOCUSER2]: docuser2.value,
      [Preferences.QUEUE_BUNDLE_DOCUSER3]: docuser3.value,
      [Preferences.QUEUE_BUNDLE_DOCUSER4]: docuser4.value,
      [Preferences.QUEUE_BUNDLE_DOCUSER5]: docuser5.value,
      [Preferences.QUEUE_BUNDLE_DOCUSER6]: docuser6.value,
      [Preferences.QUEUE_BUNDLE_DOCUSER7]: docuser7.value,
      [Preferences.QUEUE_BUNDLE_DOCUSER8]: docuser8.value,
    }

    const searchParams = {
      'DCR': outputChannel.value,
      'PCR': outputFormat.value,
      'PREDRECI': address.value,
      'RECI': recipient.value,
      'FORM': form.value,
      'EXT': extension.value,
      'REPORT': report.value,
      'WREPORT': wreport.value,
      'SRCJOBN': jobname.value,
      'BUNDLSTA': onlyStatusHold ? 'H' : '',
      'DOCUSR1': docuser1.value,
      'DOCUSR2': docuser2.value,
      'DOCUSR3': docuser3.value,
      'DOCUSR4': docuser4.value,
      'DOCUSR5': docuser5.value,
      'DOCUSR6': docuser6.value,
      'DOCUSR7': docuser7.value,
      'DOCUSR8': docuser8.value,
    }

    this.props.changePrefs(prefsToChange)

    this.props.getBundleQueues(searchParams)
  }

  /**
   * @description Requests the outputchannel definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  openOutputChannelDefinitionSelector = () => {
    this.props.getOutputChannelDefinitions(
      ['DCR', 'DCRTITLE'],
      this.state.outputChannel.value,
      () => { this.setState({ showOutputChannelSelectorDialog: true }) }
    )
  }

  /**
   * @description Requests the outputformat definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  openOutputFormatDefinitionSelector = () => {
    this.props.getOutputFormatDefinitions(
      ['PCR', 'PCRTITLE'],
      this.state.outputFormat.value,
      () => this.setState({ showOutputFormatSelectorDialog: true })
    )
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  onOpenRecipientDefinitionSelector = () => {
    this.props.getRecipientDefinitions(
      ['RECI', 'TITLE'],
      this.state.recipient.value,
      () => { this.setState({ showRecipientSelectorDialog: true }) }
    )
  }

  /**
   * @description Requests the document definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  openDocumentDefinitionsSelector = () => {
    this.props.getDocumentDefinitions(
      ['FORM', 'EXT', 'REPORT'],
      this.state.form.value,
      this.state.extension.value,
      this.state.report.value,
      () => this.setState({ showDocumentSelectorDialog: true })
    )
  }

  /**
   * @description Gets the index of the header in redux state
   * @param {Object} data specific redux object
   * @param {String} header header name of the header in redux
   */
  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    const { showOutputChannelSelectorDialog, showOutputFormatSelectorDialog,
      showRecipientSelectorDialog, showDocumentSelectorDialog } = this.state

    return (
      <>
        {showOutputChannelSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputchannelselector_dialog`}
            onClose={() => { this.setState({ showOutputChannelSelectorDialog: false }) }}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('import.outputchannel'),
              translate('general.title')
            ]}
            items={selector.outputchannels.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputchannel = selector.outputchannels.data[selectedRows][selector.outputchannels.header.indexOf('DCR')]
                this.setState({
                  outputChannel: { value: newOutputchannel, error: '' },
                  showOutputChannelSelectorDialog: false
                })
              }
            }}
          />
        )}
        {showOutputFormatSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputformatdefinition_selector_dialog`}
            onClose={() => this.setState({ showOutputFormatSelectorDialog: false })}
            title={translate('definition.outputformatdefinitions')}
            header={[
              translate('import.outputformat'),
              translate('general.title')
            ]}
            items={selector.outputformats.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const outputFormat = selector.outputformats.data[selectedRows][selector.outputformats.header.indexOf('PCR')]
                this.setState({
                  outputFormat: { value: outputFormat, error: '' },
                  showOutputFormatSelectorDialog: false
                })
              }
            }}
          />
        )}
        {showRecipientSelectorDialog && (
          <SelectorDialog
            id={`${id}_recipientdefinition_selector_dialog`}
            onClose={() => { this.setState({ showRecipientSelectorDialog: false }) }}
            title={translate('definition.recipientdefinitions')}
            header={[
              translate('general.recipient'),
              translate('general.title'),
            ]}
            items={selector.recipients.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newRecipient = selector.recipients.data[selectedRows][selector.recipients.header.indexOf('RECI')]
                this.setState({
                  recipient: { value: newRecipient, error: '' },
                  showRecipientSelectorDialog: false
                })
              }
            }}
          />
        )}
        {showDocumentSelectorDialog && (
          <SelectorDialog
            id={`${id}_indexdocument_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report')
            ]}
            items={selector.documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const form = selector.documents.data[selectedRows][this.headerData(selector.documents, 'FORM')]
                const extension = selector.documents.data[selectedRows][this.headerData(selector.documents, 'EXT')]
                const report = selector.documents.data[selectedRows][this.headerData(selector.documents, 'REPORT')]
                this.setState({
                  form: { value: form, error: '' },
                  extension: { value: extension, error: '' },
                  report: { value: report, error: '' },
                  showDocumentSelectorDialog: false
                })
              }
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { outputChannel, outputFormat, address, recipient, form, extension, report,
      wreport, jobname, onlyStatusHold } = this.state

    return (
      <Card
        title={translate('general.general')}>
        {/* output channel + output format row */}
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_output_channel`}
              value={outputChannel.value}
              title={translate('definition.output_channel_id')}
              maxLength={16}
              onInputChanged={(value, error) => this.handleChange('outputChannel', value, error)}
              addon={{
                iconName: 'list',
                onClick: () => this.openOutputChannelDefinitionSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_outputformat`}
              value={outputFormat.value}
              title={translate('definition.output_format_id')}
              maxLength={16}
              onInputChanged={(value, error) => this.handleChange('outputFormat', value, error)}
              addon={{
                iconName: 'list',
                onClick: () => this.openOutputFormatDefinitionSelector()
              }}
            />
          </Column>
        </Row>
        {/* address + recipient row */}
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_address`}
              value={address.value}
              title={translate('recipient.address')}
              maxLength={16}
              onInputChanged={(value, error) => this.handleChange('address', value, error)}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_recipient`}
              onInputChanged={(value, error) => this.handleChange('recipient', value, error)}
              value={recipient.value}
              title={translate('recipient.reci_id')}
              error={recipient.error && translate(recipient.error)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenRecipientDefinitionSelector()
              }}
            />
          </Column>
        </Row>
        {/* form + extension row */}
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_form`}
              onInputChanged={(value, error) => this.handleChange('form', value, error)}
              value={form.value}
              title={translate('general.form')}
              error={form.error && translate(form.error)}
              maxLength={8}
              addon={{
                iconName: 'list',
                onClick: () => this.openDocumentDefinitionsSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_extension`}
              onInputChanged={(value, error) => this.handleChange('extension', value, error)}
              value={extension.value}
              title={translate('general.extension')}
              error={extension.error && translate(extension.error)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.openDocumentDefinitionsSelector()
              }}
            />
          </Column>
        </Row>
        {/* report + dynamic report row */}
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_report`}
              onInputChanged={(value, error) => this.handleChange('report', value, error)}
              value={report.value}
              title={translate('general.report')}
              error={report.error && translate(report.error)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.openDocumentDefinitionsSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_dynamic_report`}
              onInputChanged={(value, error) => this.handleChange('wreport', value, error)}
              value={wreport.value}
              title={translate('general.dynamic_report')}
              error={wreport.error && translate(wreport.error)}
              maxLength={16}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_jobname`}
              onInputChanged={(value, error) => this.handleChange('jobname', value, error)}
              value={jobname.value}
              title={translate('general.jobname')}
              error={jobname.error && translate(jobname.error)}
              maxLength={8}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Toggle
              id={`${id}_only_status_hold`}
              onCheck={() => this.setState(currState => ({ onlyStatusHold: !currState.onlyStatusHold }))}
              value={onlyStatusHold}
              animated
              title={translate('queues.only_status_hold')}
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the docuser variables card.
   */
  renderDocUserVariablesCard = () => {
    const { id } = this.props
    const { docuser1, docuser2, docuser3, docuser4, docuser5, docuser6,
      docuser7, docuser8 } = this.state

    return (
      <Card
        title={translate('general.docuservars')}>
        {/* docuser1 + docuser2 row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser1 */}
            <Input
              id={`${id}_docuser1`}
              onInputChanged={(val, err) => { this.handleChange('docuser1', val, err) }}
              value={docuser1.value}
              title={translate('general.docuser1')}
              error={docuser1.error && translate(docuser1.error)}
              maxLength={32}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser2 */}
            <Input
              id={`${id}_docuser2`}
              onInputChanged={(val, err) => { this.handleChange('docuser2', val, err) }}
              value={docuser2.value}
              title={translate('general.docuser2')}
              error={docuser2.error && translate(docuser2.error)}
              maxLength={32}
            />
          </Column>
        </Row>
        {/* docuser3 + docuser4 row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser3 */}
            <Input
              id={`${id}_docuser3`}
              onInputChanged={(val, err) => { this.handleChange('docuser3', val, err) }}
              value={docuser3.value}
              title={translate('general.docuser3')}
              error={docuser3.error && translate(docuser3.error)}
              maxLength={32}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser4 */}
            <Input
              id={`${id}_docuser4`}
              onInputChanged={(val, err) => { this.handleChange('docuser4', val, err) }}
              value={docuser4.value}
              title={translate('general.docuser4')}
              error={docuser4.error && translate(docuser4.error)}
              maxLength={32}
            />
          </Column>
        </Row>
        {/* docuser5 + docuser6 row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser5 */}
            <Input
              id={`${id}_docuser5`}
              onInputChanged={(val, err) => { this.handleChange('docuser5', val, err) }}
              value={docuser5.value}
              title={translate('general.docuser5')}
              error={docuser5.error && translate(docuser5.error)}
              maxLength={32}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser6 */}
            <Input
              id={`${id}_docuser6`}
              onInputChanged={(val, err) => { this.handleChange('docuser6', val, err) }}
              value={docuser6.value}
              title={translate('general.docuser6')}
              error={docuser6.error && translate(docuser6.error)}
              maxLength={32}
            />
          </Column>
        </Row>
        {/* docuser7 + docuser8 row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser7 */}
            <Input
              id={`${id}_docuser7`}
              onInputChanged={(val, err) => { this.handleChange('docuser7', val, err) }}
              value={docuser7.value}
              title={translate('general.docuser7')}
              error={docuser7.error && translate(docuser7.error)}
              maxLength={32}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* docuser8 */}
            <Input
              id={`${id}_docuser8`}
              onInputChanged={(val, err) => { this.handleChange('docuser8', val, err) }}
              value={docuser8.value}
              title={translate('general.docuser8')}
              error={docuser8.error && translate(docuser8.error)}
              maxLength={32}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {/* the selector dialogs */}
        {this.renderSelectorDialogs()}
        {/* general card */}
        {this.renderGeneralCard()}
        {/* docuser variables card */}
        {this.renderDocUserVariablesCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={'drawer_content_queue_body_search'}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary />
        <Button
          id={'drawer_content_queue_body_resetBtn'}
          icon='undo'
          iconType='material'
          className='bux_iconResteBtn'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {/* main */}
        {this.renderMain()}
        {/* footer */}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getOutputFormatDefinitions: (fields, outputFormat, callback) => {
      ModalSelectorActions.getOutputFormatDefinitions(
        fields,
        outputFormat,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getRecipientDefinitions: (fields, recipient, callback) => {
      ModalSelectorActions.getRecipientDefinitions(
        fields,
        undefined, // rtype
        undefined, // distribution type
        recipient,
        undefined, // preRecipient
        undefined, // owner
        undefined, // title
        undefined, // outputchannel
        undefined, // outputformat
        undefined, // ppn
        callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    getBundleQueues: (searchParams, callback) => {
      BundleQueueActions.getBundleQueues(searchParams, callback)(dispatch)
    },
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) }
  }
}

QueueBundle.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueBundle)