
import * as Config from 'config/Configs'
import * as Lang from 'language/Language'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import store from 'redux/Store'
import { restapiRequest } from 'utils/RequestUtils'

export const NOT_FOUND = 'LOCAL_INDEX_INFO_REQUEST_START'

export const LOCAL_INDEX_INFO_REQUEST_START = 'LOCAL_INDEX_INFO_REQUEST_START'
export const LOCAL_INDEX_INFO_REQUEST_SUCCEED = 'LOCAL_INDEX_INFO_REQUEST_SUCCEED'
export const LOCAL_INDEX_INFO_REQUEST_FAILED = 'LOCAL_INDEX_INFO_REQUEST_FAILED'

export const SEARCH_BY_LOCAL_INDEX_REQUEST_START = 'SEARCH_BY_LOCAL_INDEX_REQUEST_START'
export const SEARCH_BY_LOCAL_INDEX_REQUEST_SUCCEED = 'SEARCH_BY_LOCAL_INDEX_REQUEST_SUCCEED'
export const SEARCH_BY_LOCAL_INDEX_REQUEST_FAILED = 'SEARCH_BY_LOCAL_INDEX_REQUEST_FAILED'

export function getLocalIndexesInfo(docId, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: LOCAL_INDEX_INFO_REQUEST_START })

    const queryParams = []
    queryParams.push(`DOCID=${encodeURIComponent(docId)}`)

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/indexes/local/info?${queryParams.join('&')}`, {
        method: 'get',
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NOT_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: LOCAL_INDEX_INFO_REQUEST_FAILED })
          }
        }
        else {
          dispatch({ type: LOCAL_INDEX_INFO_REQUEST_SUCCEED, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback(jsondata.data)
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('request_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: LOCAL_INDEX_INFO_REQUEST_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function searchByLocalIndexes(docId, indexArguments, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: SEARCH_BY_LOCAL_INDEX_REQUEST_START })

    const queryParams = []
    queryParams.push(`DOCID=${encodeURIComponent(docId)}`)
    queryParams.push(`IDXARG=${encodeURIComponent(indexArguments)}`)

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/indexes/local?${queryParams.join('&')}`, {
        method: 'get',
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NOT_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: SEARCH_BY_LOCAL_INDEX_REQUEST_FAILED })
          }
        }
        else {
          dispatch({ type: SEARCH_BY_LOCAL_INDEX_REQUEST_SUCCEED, payload: jsondata.data })

          // call the callback on success
          if (callback) {
            callback(jsondata.data)
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('request_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: SEARCH_BY_LOCAL_INDEX_REQUEST_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}