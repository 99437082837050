import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { DownloadWrapper, Icon, Link, TableButton, TableButtonGroup, TableButtonGroupItem } from 'BetaUX2Web-Components/src/'
import StonebranchJobInformationDialog from 'components/dialogs/stonebranch_job_information_dialog/StonebramchJobInformationDialog'
import * as SEARCH_CONSTANTS from 'components/drawer/content/search/search_body/search_standard_search/SearchStandardSearch'
import { SelectionTable } from '../common/SelectionTable'
import TableSettings from 'components/table_settings/TableSettings'

import { translate } from 'language/Language'

import { addTabToDocViewer } from 'redux/actions/DocViewerActions'
import { displayJob } from 'redux/actions/SearchActions'
import { downloadJob, getStonebranchJobDetails, getStonebranchJobs, getStonebranchJobLogs } from 'redux/actions/SearchStonebranchActions'
import * as Preferences from 'redux/general/Preferences'

import { HEADERS, SEARCH_HEADERS } from '../../../drawer/content/search/search_body/Search.types';

import { fillHeaderInformation, getCleanData, getSavedOrDefaultHeader } from '../search-documents-utils'
import { EResultTableType, translateHeaderColumns } from 'utils/ColumnUtils'
import * as DateUtils from 'utils/DateUtils'
import { SEARCH_TYPE_STONEBRANCH_ALL } from 'utils/DocViewerUtils'

const defaultOrderedHeaders = [HEADERS.JOBNAME, HEADERS.SRCSUBDT, HEADERS.STRTDT, HEADERS.ENDDT,
  HEADERS.JOBSTAT, HEADERS.JOBERR, HEADERS.JOBRC, HEADERS.RUNUSER, HEADERS.AGNTNAME, HEADERS.SYSTEMNAME,
  HEADERS.JOBGROUP, HEADERS.SERVICES, HEADERS.SOURCE_VERSION, HEADERS.WORKFLOW, HEADERS.USR1NAM, HEADERS.USR1VAL,
  HEADERS.USR2NAM, HEADERS.USR2VAL]

const SearchResultStonebranch = ({ id, drawerExpanded, autoDrawer }) => {
  const preferences = useSelector(store => store.auth.serverdata.preferences)
  const lang = useSelector(store => store.auth.serverdata.preferences[Preferences.LANGUAGE])
  const datemask = useSelector(store => store.auth.serverdata.preferences[Preferences.DATEMASK])
  const keepPagination = useSelector(store => store.search.standardselection.keepPagination)
  const stonebranchJobs = useSelector(store => store.search.stonebranch.stonebranchJobs)
  const bwesystemname = useSelector(store => store.auth.serverdata.bwesystemname);
  const bwebaseurl = useSelector(store => store.auth.serverdata.bwebaseurl);
  const dispatch = useDispatch()
  const [showTableSettings, setShowTableSettings] = useState(false)
  const [showJobInformationDialog, setShowJobInformationDialog] = useState(false)
  const [allHeader, setAllHeader] = useState([])
  const [translatedHeader, setTranslatedHeader] = useState([])
  const [settingsTranslatedHeader, setSettingsTranslatedHeader] = useState([])
  const [usedHeader, setUsedHeader] = useState([])
  const [data, setData] = useState(null)

  useEffect(() => {
    const newHeader = fillHeaderInformation(stonebranchJobs, defaultOrderedHeaders)
    setAllHeader(newHeader)
  }, [stonebranchJobs])

  useEffect(() => {
    if (allHeader.length > 0 && usedHeader.length > 0) {
      updateHeadersTranslation()
    }
  }, [allHeader, usedHeader, lang, updateHeadersTranslation])

  useEffect(() => {
    if (allHeader.length > 0) {
      setUsedHeader(getSavedOrDefaultHeader(allHeader, preferences, Preferences.TABLE_SETTINGS_SEARCH_STONEBRANCH))
    }
  }, [preferences, allHeader])

  useEffect(() => {
    if (allHeader.length > 0 && stonebranchJobs) {
      setData(getCleanData(stonebranchJobs, usedHeader, datemask))
    }
    else {
      setData(null)
    }
  }, [allHeader, usedHeader, stonebranchJobs, datemask])

  /**
   * @description gets the index of the header in redux state controlmJobs.header
   * @param {String} header header name of the header in redux state controlmJobs.header
   */
  const headerIndex = useCallback(header => stonebranchJobs.header.indexOf(header), [stonebranchJobs])

  const updateHeadersTranslation = useCallback(() => {
    const header = getSavedOrDefaultHeader(allHeader, preferences, Preferences.TABLE_SETTINGS_SEARCH_STONEBRANCH)

    translateHeaderColumns(header, EResultTableType.BRWSTB).then(translatedHeaders => {
      setTranslatedHeader(translatedHeaders)
    })

    translateHeaderColumns(allHeader.map(v => v.rest), EResultTableType.BRWSTB).then(translatedHeaders => {
      const settingsHeader = translatedHeaders.map((v, index) => {
        return {
          rest: allHeader[index].rest,
          translated: v
        }
      })
      setSettingsTranslatedHeader(settingsHeader)
    })
  }, [allHeader, preferences])

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  const getFillPageInfo = () => {
    if (preferences[Preferences.TABLE_SETTINGS_SEARCH_STONEBRANCH]) {
      return preferences[Preferences.TABLE_SETTINGS_SEARCH_STONEBRANCH].fillPage
    } else {
      return true
    }
  }

  /**
   * @description This function will open all selected rows as tabs in the DocViewer.
   * @param {Array<number>} selectedRowsIndices This array contains the indices of the selected rows.
   */
  const openSelectedRowsInDocViewer = (selectedRowsIndices) => {
    selectedRowsIndices.forEach(rowIndex => openDocumentViewer(rowIndex))
  }

  /**
   * @description This function will handle opening a new tab with the parsed job data.
   * @param {Number} rowIndex The row index of the selected search result
   */
  const openDocumentViewer = useCallback((rowIndex) => {
    addTabToDocViewer({
      id: stonebranchJobs.data[rowIndex][headerIndex('UKEY')],
      type: SEARCH_TYPE_STONEBRANCH_ALL,
      header: stonebranchJobs.header,
      data: stonebranchJobs.data[rowIndex],
      pageCount: 0 // Will be populated in document viewer to avoid callback problems with multi-display
    })(dispatch);
  }, [stonebranchJobs, dispatch, headerIndex])

  /**
    * @description Handles the download functionality of logs in binary format.
    * @param {Number} rowIndex The current row.
    */
  const handleJobDownload = rowIndex => {
    const ukey = stonebranchJobs.data[rowIndex][headerIndex(HEADERS.UKEY)]
    const jobName = stonebranchJobs.data[rowIndex][headerIndex(HEADERS.JOBNAME)]

    const queryParams = {
      [HEADERS.UKEY]: ukey,
    }

    // Generate name which will be suggested as download-filename
    const filename = [jobName].filter(entry => entry !== undefined).join('.') + '.log'
    downloadJob(queryParams, filename)(dispatch)
  }

  /**
    * @description Handles the display functionality of documents in _beta view.
    * @param {Number} rowIndex The current row.
    */
  const handleDisplayDocmentInBetaView = rowIndex => {
    const ukey = stonebranchJobs.data[rowIndex][headerIndex('UKEY')]
    displayJob(bwebaseurl, bwesystemname, undefined, undefined, undefined, undefined, ukey)(dispatch);
  }


  const handleRefresh = () => {
    const activeTabIndex = preferences[Preferences.SEARCH_STONEBRANCH_ACTIVE_TAB]
    let lastTimeMode = preferences[Preferences.SEARCH_STONEBRANCH_LASTTIME_MODE]
    let sdate = ''
    let stime = ''
    let edate = ''
    let etime = ''
    let customLast = ''
    let customUnit = ''

    if (activeTabIndex === 0) {
      if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_TODAY || lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_YESTERDAY) {
        sdate = preferences[Preferences.SEARCH_STONEBRANCH_LASTTIME_MODE].toUpperCase()
      } else if (lastTimeMode === SEARCH_CONSTANTS.LASTTIME_MODE_CUSTOM) {
        customLast = preferences[Preferences.SEARCH_STONEBRANCH_TIME_CUSTOM_LAST]
        customUnit = preferences[Preferences.SEARCH_STONEBRANCH_TIME_CUSTOM_UNIT]
      }
    } else if (activeTabIndex === 1) {
      sdate = preferences[Preferences.SEARCH_STONEBRANCH_START_DATE] !== '' ? moment(preferences[Preferences.SEARCH_STONEBRANCH_START_DATE], DateUtils.DDMMYYYY_DOT).format(DateUtils.DDMMYYYY_DOT) : ''
      stime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_STONEBRANCH_START_TIME])
      edate = preferences[Preferences.SEARCH_STONEBRANCH_END_DATE] !== '' ? moment(preferences[Preferences.SEARCH_STONEBRANCH_END_DATE], DateUtils.DDMMYYYY_DOT).format(DateUtils.DDMMYYYY_DOT) : ''
      etime = DateUtils.formatTimeToDefault(preferences[Preferences.SEARCH_STONEBRANCH_END_TIME])
    }

    const searchParams = {
      [SEARCH_HEADERS.FROMLAST]: customLast,
      [SEARCH_HEADERS.TUNITS]: customUnit,
      [SEARCH_HEADERS.SDATE]: sdate,
      [SEARCH_HEADERS.STIME]: stime,
      [SEARCH_HEADERS.EDATE]: edate,
      [SEARCH_HEADERS.ETIME]: etime,
      [SEARCH_HEADERS.LOGSOURCE]: preferences[Preferences.SEARCH_STONEBRANCH_LOGSOURCE],
      [SEARCH_HEADERS.TUSAGE]: preferences[Preferences.SEARCH_STONEBRANCH_USAGE],
      [SEARCH_HEADERS.JOBERR]: preferences[Preferences.SEARCH_STONEBRANCH_ONLY_JOB_ERRORS],
      [SEARCH_HEADERS.JOBGROUP]: preferences[Preferences.SEARCH_STONEBRANCH_JOBGROUP],
      [SEARCH_HEADERS.LJOBNAME]: preferences[Preferences.SEARCH_STONEBRANCH_JOBNAME],
      [SEARCH_HEADERS.AGENTNAME]: preferences[Preferences.SEARCH_STONEBRANCH_AGENT],
      [SEARCH_HEADERS.SYSTEMNAME]: preferences[Preferences.SEARCH_STONEBRANCH_SYSTEM],
      [SEARCH_HEADERS.JOBSTATUS]: preferences[Preferences.SEARCH_STONEBRANCH_STATUS],
      [SEARCH_HEADERS.SERVICE]: preferences[Preferences.SEARCH_STONEBRANCH_SERVICE],
      [SEARCH_HEADERS.SRCVERSION]: preferences[Preferences.SEARCH_STONEBRANCH_SOURCE_VERSION],
      [SEARCH_HEADERS.WORKFLOW]: preferences[Preferences.SEARCH_STONEBRANCH_WORKFLOW],
      [SEARCH_HEADERS.RUNUSER]: preferences[Preferences.SEARCH_STONEBRANCH_RUN_USER]
    }

    if (preferences[Preferences.SEARCH_STONEBRANCH_RETURN_CODE_INPUT] && preferences[Preferences.SEARCH_STONEBRANCH_RETURN_CODE_INPUT] !== '') {
      searchParams[HEADERS.JOBRC] = `${preferences[Preferences.SEARCH_STONEBRANCH_RETURN_CODE_SWITCH]}${preferences[Preferences.SEARCH_STONEBRANCH_RETURN_CODE_INPUT]}`
    }

    if (preferences[Preferences.SEARCH_STONEBRANCH_RUNTIME_INPUT] && preferences[Preferences.SEARCH_STONEBRANCH_RUNTIME_INPUT] !== '') {
      searchParams[HEADERS.RUNTIME] = `${preferences[Preferences.SEARCH_STONEBRANCH_RUNTIME_SWITCH]}${preferences[Preferences.SEARCH_STONEBRANCH_RUNTIME_INPUT]}`
    }

    getStonebranchJobs(undefined, searchParams)(dispatch)
  }

  const handleDisplayJobInformation = (rowIndex) => {
    const jobObj = {
      UKEY: stonebranchJobs.data[rowIndex][headerIndex('UKEY')]
    }

    getStonebranchJobDetails(undefined, jobObj, () => {
      getStonebranchJobLogs(jobObj, () => {
        setShowJobInformationDialog(true)
      })(dispatch)
    })(dispatch)
  }

  return (
    <>
      {
        showTableSettings &&
        <TableSettings
          id={id}
          onClose={() => setShowTableSettings(false)}
          headers={settingsTranslatedHeader}
          prefs={{ headers: usedHeader, fillPage: getFillPageInfo(), key: Preferences.TABLE_SETTINGS_SEARCH_STONEBRANCH }}
        />
      }
      {showJobInformationDialog && (
        <StonebranchJobInformationDialog
          id={`${id}_stonebranchjobinfo`}
          onClose={() => setShowJobInformationDialog(false)}
        />
      )}
      <SelectionTable
        id={id}
        headers={usedHeader}
        documents={stonebranchJobs}
        keepPagination={keepPagination}
        fillPage={getFillPageInfo()}
        createActionButtons={index => getActionButtons(index, id, handleDisplayJobInformation, openDocumentViewer, handleJobDownload, handleDisplayDocmentInBetaView)}
        createTableRowAction={index => handleDisplayJobInformation(index)}
        additionalInteraction={getMenuButtons(data, translatedHeader, () => setShowTableSettings(true), handleRefresh)}
        drawerExpanded={drawerExpanded}
        autoDrawer={autoDrawer}
        openDocViewerCallback={openSelectedRowsInDocViewer}
        resultTableType={EResultTableType.BRWSTB}
      />
    </>
  )
}

const getActionButtons = (index, id, handleDisplayJobInformation, openDocumentViewer, handleJobDownload, handleDisplayDocmentInBetaView) => {
  return [
    <TableButton
      id={`${id}_tableButtonInfo_${index}`}
      iconType={'material'}
      iconName={'info'}
      title={translate('general.information')}
      onClick={() => handleDisplayJobInformation(index)}
    />,
    <TableButton
      id={`${id}_tableButtonDisplay_${index}`}
      iconType={'material'}
      iconName={'document'}
      title={translate('general.display')}
      onClick={() => openDocumentViewer(index)}
    />,
    <TableButtonGroup
      id={`${id}_tableButtonMore_${index}`}
      tooltip={translate('general.more_options')}>
      <TableButtonGroupItem
        onClick={() => handleDisplayJobInformation(index)}
        id={`${id}_infoBtn`}
        icon={<Icon name='info' className='actionIcon' />}
        text={translate('general.information')}
        title={translate('general.information')}
      />
      <TableButtonGroupItem
        id={`${id}_tableButtonDisplayInBetaView_${index}`}
        iconType='material'
        icon={<Icon name='document' className='actionIcon' />}
        text={translate('general.display_in_beta_view')}
        title={translate('general.display_in_beta_view')}
        onClick={() => handleDisplayDocmentInBetaView(index)}
      />
      <TableButtonGroupItem
        id={`${id}_tableButton_download_${index}`}
        iconType={'material'}
        icon={<Icon name='download' className='actionIcon' />}
        text={translate('general.download')}
        title={translate('general.download')}
        onClick={() => handleJobDownload(index)}
      />
    </TableButtonGroup>
  ]
}

const getMenuButtons = (data, header, showTableSettings, handleRefresh) => {
  return (
    [
      <Link
        id={'cached'}
        iconName={'refresh'}
        tooltip={translate('table.refresh')}
        onClick={() => handleRefresh()}
      />,
      <DownloadWrapper
        id='download_wrapper'
        header={header}
        data={[...data ?? []]}
        csvSplitter=';'
        filename='data.csv'
        tooltip={translate('table.download_as_csv')}>
        <Link
          id={'download'}
          iconName={'download'}
          onCLick={() => { }}
          tooltip={translate('table.download_as_csv')}
        />
      </DownloadWrapper>,
      <Link
        id={'settings'}
        iconName={'settings'}
        tooltip={translate('table.settings')}
        onClick={() => showTableSettings()}
      />
    ]
  )
}

export default SearchResultStonebranch