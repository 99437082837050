import { Component } from 'react'

import {
  Button, Card, Column,
  Input,
  Row, Switch
} from 'BetaUX2Web-Components/src/'
import TimeCard from 'components/time_card/TimeCard'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

import { translate } from 'language/Language'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as SearchUC4Actions from 'redux/actions/SearchUC4Actions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as Utils from 'utils/Utils'

import { connect } from 'react-redux'
import { validateTimecardErrors } from '../SearchUtils'

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.hours' },
  { key: UNIT_DAYS, translationKey: 'general.days' },
]

const SUBMIT = 'SUBMIT'
const START = 'START'
const END = 'END'

const DISPLAY_JOBS_BY_ITEMS = [
  { key: SUBMIT, translationKey: 'general.activation' },
  { key: START, translationKey: 'general.start' },
  { key: END, translationKey: 'general.end' }
]

const FILTER_BY_JOB_ERRORS_ALL = ''
const FILTER_BY_JOB_ERRORS_SUCCESS = 'NO'
const FILTER_BY_JOB_ERRORS_ERROR = 'YES'

const ONLY_JOB_ERRORS_ITEMS = [
  { key: FILTER_BY_JOB_ERRORS_ALL, translationKey: 'general.all' },
  { key: FILTER_BY_JOB_ERRORS_SUCCESS, translationKey: 'general.successful' },
  { key: FILTER_BY_JOB_ERRORS_ERROR, translationKey: 'general.error' }
]

class SearchUc4Search extends Component {

  defaultState = {
    showJobgroupsDialog: false,
    time: {
      activeTimeTabIndex: 0,
      type: 0,
      period: 1,
      unit: 2,
      startDate: {
        value: '',
        error: ''
      },
      startTime: {
        value: '',
        error: ''
      },
      endDate: {
        value: '',
        error: ''
      },
      endTime: {
        value: '',
        error: ''
      }
    },
    displayJobsBy: 0,
    jobgroup: '',
    jobname: '',
    systemname: '',
    runNumber: '',
    client: '',
    objecttype: '',
    parentname: '',
    agentname: '',
    archivekey1: '',
    archivekey2: '',
    onlyJobErrors: 0,
    logSourceReader: 0,
    returnCodeSwitch: 0,
    returnCodeInput: '',
    statusSwitch: 0,
    statusInput: '',
    cpuUsageSwitch: 0,
    cpuUsageInput: '',
    runtimeSwitch: 0,
    runtimeInput: ''
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount = () => {
    // initialize search fields
    const { preferences, datemask } = this.props

    if (preferences) {
      let activeTimeTabIndex = Utils.convertStringToInt(preferences[Preferences.SEARCH_UC4_ACTIVE_TAB]) || 0
      let startDate = activeTimeTabIndex === 1 && preferences[Preferences.SEARCH_UC4_START_DATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SEARCH_UC4_START_DATE])
        : ''

      let startTime = preferences[Preferences.SEARCH_UC4_START_TIME] || ''
      startTime = Utils.convertStringToInt(startTime)

      let endDate = preferences[Preferences.SEARCH_UC4_END_DATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SEARCH_UC4_END_DATE])
        : ''

      let endTime = preferences[Preferences.SEARCH_UC4_END_TIME] || ''
      endTime = Utils.convertStringToInt(endTime)

      const resultIndexUnit = Math.max(UNITS.findIndex(d => d.key === preferences[Preferences.SEARCH_UC4_TIME_CUSTOM_UNIT]), 0)
      const unitIndex = resultIndexUnit !== -1 ? resultIndexUnit : 2

      const resultIndexUsage = Math.max(DISPLAY_JOBS_BY_ITEMS.findIndex(d => d.key === preferences[Preferences.SEARCH_UC4_USAGE]), 0)
      const displayJobsBy = resultIndexUsage !== -1 ? resultIndexUsage : 0

      const jobname = preferences[Preferences.SEARCH_UC4_JOBNAME] || ''
      const systemname = preferences[Preferences.SEARCH_UC4_SYSTEMNAME] || ''
      const runNumber = preferences[Preferences.SEARCH_UC4_RUN_NUMBER] || ''
      const client = preferences[Preferences.SEARCH_UC4_CLIENT] || ''
      const objecttype = preferences[Preferences.SEARCH_UC4_OBJECT_TYPE] || ''
      const parentname = preferences[Preferences.SEARCH_UC4_PARENT_NAME] || ''
      const agentname = preferences[Preferences.SEARCH_UC4_AGENT_NAME] || ''
      const archivekey1 = preferences[Preferences.SEARCH_UC4_ARCHIVE_KEY_1] || ''
      const archivekey2 = preferences[Preferences.SEARCH_UC4_ARCHIVE_KEY_2] || ''
      const resultIndexOnlyJobErrors = Math.max(ONLY_JOB_ERRORS_ITEMS.findIndex(d => d.key === preferences[Preferences.SEARCH_UC4_ONLY_JOB_ERRORS]), 0)
      const onlyJobErrors = resultIndexOnlyJobErrors !== -1 ? resultIndexOnlyJobErrors : 0
      const logSourceReader = { 'UC4': 0, 'UC4ARC': 1, 'UC4ONL': 2, 'UC4MIG': 3 }[preferences[Preferences.SEARCH_UC4_LOGSOURCE]] || 0
      const returnCodeSwitch = { '<': 0, '>': 1, '=': 2, '#': 3 }[preferences[Preferences.SEARCH_UC4_RETURNCODE_SWITCH]] || 0
      const returnCodeInput = preferences[Preferences.SEARCH_UC4_RETURNCODE_INPUT] || ''
      const statusSwitch = { '<': 0, '>': 1, '=': 2, '#': 3 }[preferences[Preferences.SEARCH_UC4_STATUS_SWITCH]] || 0
      const statusInput = preferences[Preferences.SEARCH_UC4_STATUS_INPUT] || ''
      const cpuUsageSwitch = { '<': 0, '>': 1, '=': 2, '#': 3 }[preferences[Preferences.SEARCH_UC4_CPU_USAGE_SWITCH]] || 0
      const cpuUsageInput = preferences[Preferences.SEARCH_UC4_CPU_USAGE_INPUT] || ''
      const runtimeSwitch = { '<': 0, '>': 1, '=': 2, '#': 3 }[preferences[Preferences.SEARCH_UC4_RUNTIME_SWITCH]] || 0
      const runtimeInput = preferences[Preferences.SEARCH_UC4_RUNTIME_INPUT] || ''

      // set preference values or fallback default values
      this.setState({
        time: {
          activeTimeTabIndex,
          type: Utils.convertStringToInt(preferences[Preferences.SEARCH_UC4_LASTTIME_MODE]) || 0,
          period: Utils.convertStringToInt(preferences[Preferences.SEARCH_UC4_TIME_CUSTOM_LAST]) || 1,
          unit: unitIndex,
          startDate: {
            value: startDate,
            error: ''
          },
          startTime: {
            value: startTime,
            error: ''
          },
          endDate: {
            value: endDate,
            error: ''
          },
          endTime: {
            value: endTime,
            error: ''
          }
        },
        displayJobsBy,
        jobname,
        systemname,
        runNumber,
        client,
        objecttype,
        parentname,
        agentname,
        archivekey1,
        archivekey2,
        onlyJobErrors,
        logSourceReader,
        returnCodeSwitch,
        returnCodeInput,
        statusSwitch,
        statusInput,
        cpuUsageSwitch,
        cpuUsageInput,
        runtimeSwitch,
        runtimeInput
      })
    }
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = event => {
    const { time, displayJobsBy, jobgroup, jobname, systemname, runNumber, client, objecttype, parentname, agentname, archivekey1, archivekey2, onlyJobErrors,
      logSourceReader, returnCodeSwitch, returnCodeInput, statusSwitch, statusInput, cpuUsageSwitch, cpuUsageInput, runtimeSwitch, runtimeInput } = this.state
    const { datemask, getUC4Jobs, changePrefs } = this.props
    event.preventDefault()
    const errors = validateTimecardErrors(time, datemask)
    this.setState({ ...this.state, time: { ...time, ...errors } })
    if (Object.keys(errors).length === 0) {
      const prefsToChange = {
        [Preferences.SEARCH_UC4_ACTIVE_TAB]: time.activeTimeTabIndex,
        [Preferences.SEARCH_UC4_LASTTIME_MODE]: time.type,
        [Preferences.SEARCH_UC4_TIME_CUSTOM_LAST]: time.period,
        [Preferences.SEARCH_UC4_TIME_CUSTOM_UNIT]: UNITS[time.unit].key,
        [Preferences.SEARCH_UC4_START_DATE]: DateUtils.getRequestFormat(time.startDate.value, datemask),
        [Preferences.SEARCH_UC4_START_TIME]: DateUtils.formatTimeToDefault(time.startTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_UC4_END_DATE]: DateUtils.getRequestFormat(time.endDate.value, datemask),
        [Preferences.SEARCH_UC4_END_TIME]: DateUtils.formatTimeToDefault(time.endTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_UC4_USAGE]: DISPLAY_JOBS_BY_ITEMS[displayJobsBy].key,
        [Preferences.SEARCH_UC4_JOBGROUP]: jobgroup,
        [Preferences.SEARCH_UC4_JOBNAME]: jobname,
        [Preferences.SEARCH_UC4_SYSTEMNAME]: systemname,
        [Preferences.SEARCH_UC4_RUN_NUMBER]: runNumber,
        [Preferences.SEARCH_UC4_CLIENT]: client,
        [Preferences.SEARCH_UC4_OBJECT_TYPE]: objecttype,
        [Preferences.SEARCH_UC4_PARENT_NAME]: parentname,
        [Preferences.SEARCH_UC4_AGENT_NAME]: agentname,
        [Preferences.SEARCH_UC4_ARCHIVE_KEY_1]: archivekey1,
        [Preferences.SEARCH_UC4_ARCHIVE_KEY_2]: archivekey2,
        [Preferences.SEARCH_UC4_ONLY_JOB_ERRORS]: ONLY_JOB_ERRORS_ITEMS[onlyJobErrors].key,
        [Preferences.SEARCH_UC4_LOGSOURCE]: { 0: 'UC4', 1: 'UC4ARC', 2: 'UC4ONL', 3: 'UC4MIG' }[logSourceReader],
        [Preferences.SEARCH_UC4_RETURNCODE_SWITCH]: { 0: '<', 1: '>', 2: '=', 3: '#' }[returnCodeSwitch],
        [Preferences.SEARCH_UC4_RETURNCODE_INPUT]: returnCodeInput,
        [Preferences.SEARCH_UC4_STATUS_SWITCH]: { 0: '<', 1: '>', 2: '=', 3: '#' }[statusSwitch],
        [Preferences.SEARCH_UC4_STATUS_INPUT]: statusInput,
        [Preferences.SEARCH_UC4_CPU_USAGE_SWITCH]: { 0: '<', 1: '>', 2: '=', 3: '#' }[cpuUsageSwitch],
        [Preferences.SEARCH_UC4_CPU_USAGE_INPUT]: cpuUsageInput,
        [Preferences.SEARCH_UC4_RUNTIME_SWITCH]: { 0: '<', 1: '>', 2: '=', 3: '#' }[runtimeSwitch],
        [Preferences.SEARCH_UC4_RUNTIME_INPUT]: runtimeInput,
      }

      const searchParams = {
        'LOGSOURCE': { 0: 'UC4', 1: 'UC4ARC', 2: 'UC4ONL', 3: 'UC4MIG' }[logSourceReader],
        'TUSAGE': DISPLAY_JOBS_BY_ITEMS[displayJobsBy].key,
        'JOBGROUP': jobgroup,
        'LJOBNAME': jobname,
        'SYSTEMNAME': systemname,
        'RUNNUMBER': runNumber,
        'CLIENTNAME': client,
        'OBJECTTYPE': objecttype,
        'PARENTNAME': parentname,
        'AGENTNAME': agentname,
        'ARCHKEY1': archivekey1,
        'ARCHKEY2': archivekey2,
        'JOBERR': ONLY_JOB_ERRORS_ITEMS[onlyJobErrors].key
      }

      if (time.activeTimeTabIndex === 0) {
        if (time.type === 0) {
          searchParams['SDATE'] = 'TODAY'
        }
        else if (time.type === 1) {
          searchParams['SDATE'] = 'YESTERDAY'
        }
        else if (time.type === 2) {
          searchParams['FROMLAST'] = time.period
          searchParams['TUNITS'] = DateUtils.UNITS[time.unit].key
        }
      }
      else if (time.activeTimeTabIndex === 1) {
        searchParams['SDATE'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.startTime.value !== '') {
          searchParams['STIME'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.TIME_DATEMASK)
        }
        searchParams['EDATE'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.endTime.value !== '') {
          searchParams['ETIME'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.TIME_DATEMASK)
        }
      }

      if (returnCodeInput !== '') {
        searchParams['UC4RC'] = `${{ 0: '<', 1: '>', 2: '=', 3: '#' }[returnCodeSwitch]}${returnCodeInput}`
      }
      if (statusInput !== '') {
        searchParams['UC4STAT'] = `${{ 0: '<', 1: '>', 2: '=', 3: '#' }[statusSwitch]}${statusInput}`
      }
      if (cpuUsageInput !== '') {
        searchParams['UC4CPU'] = `${{ 0: '<', 1: '>', 2: '=', 3: '#' }[cpuUsageSwitch]}${cpuUsageInput}`
      }
      if (runtimeInput !== '') {
        searchParams['UC4RRT'] = `${{ 0: '<', 1: '>', 2: '=', 3: '#' }[runtimeSwitch]}${runtimeInput}`
      }
      changePrefs(prefsToChange)
      getUC4Jobs(searchParams)
    }
  }

  /**
   * @description Handles the jobgroup selector button
   */
  handleJobgroupSelector = () => {
    this.props.getJobgroups(
      ['JGIGNAME', 'JGITITLE'],
      { JGIGNAME: this.state.jobgroup },
      () => this.setState({ showJobgroupsDialog: true })
    )
  }

  handleTimeCardChange = (key, val, err) => {
    this.setState(state => ({
      time: {
        ...state.time,
        [key]: typeof state.time[key] === 'object'
          ? {
            value: val,
            error: err || ''
          }
          : val
      }
    }))
  }

  timeCardValues = () => {
    const { time } = this.state
    return {
      tabIndex: time.activeTimeTabIndex,
      lastTimeModeIndex: time.type,
      customLast: time.period,
      customUnitIndex: time.unit,
      fromDate: {
        value: time.startDate.value,
        error: time.startDate.error
      },
      fromTime: {
        value: time.startTime.value,
        error: time.startTime.error
      },
      toDate: {
        value: time.endDate.value,
        error: time.endDate.error
      },
      toTime: {
        value: time.endTime.value,
        error: time.endTime.error
      }
    }
  }

  /**
   * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
   *              Needed for update function inside the TimeCard.
   * @returns {Object}
   */
  timeCardStateKeys = () => {
    return {
      tabIndex: 'activeTimeTabIndex',
      lastTimeModeIndex: 'type',
      customLast: 'period',
      customUnitIndex: 'unit',
      fromDate: 'startDate',
      fromTime: 'startTime',
      toDate: 'endDate',
      toTime: 'endTime'
    }
  }

  /**
   * @description Renders the time card.
   */
  renderTimeCard = () => {
    const { id, lang, datemask } = this.props
    return (
      <TimeCard
        id={id}
        lang={lang}
        datemask={datemask}
        values={this.timeCardValues()}
        stateKeys={this.timeCardStateKeys()}
        onValuesChange={this.handleTimeCardChange}
        parentContainer={'drawer_content_search_body_main'}
        translate={key => translate(key)}
      />
    )
  }

  renderSelectBy = () => {
    const { displayJobsBy } = this.state
    const { id } = this.props
    return (
      <Card
        id={`${id}_select_by`}
        title={translate('search.select_by')}>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_display_jobs_by`}
              title={translate('general.date_field')}
              items={DISPLAY_JOBS_BY_ITEMS.map(d => translate(d.translationKey))}
              activeIndex={displayJobsBy}
              onClick={index => this.setState({ displayJobsBy: index })}
              maxPerRow={3}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  renderJobData = () => {
    const { jobgroup, jobname, systemname, runNumber, client, objecttype, parentname, agentname, archivekey1, archivekey2, onlyJobErrors } = this.state
    const { id } = this.props
    return (
      <Card
        id={`${id}_job_data`}
        title={translate('general.job_data')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_jobgroup`}
              onInputChanged={value => this.setState({ jobgroup: value })}
              value={jobgroup}
              title={translate('general.jobgroup')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.handleJobgroupSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_jobname`}
              onInputChanged={val => this.setState({ jobname: val })}
              value={jobname}
              title={translate('server.accounting.jobname')}
              maxLength={200}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_systemname`}
              onInputChanged={val => this.setState({ systemname: val })}
              value={systemname}
              title={translate('loginscreen.systemname')}
              maxLength={200}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_run_number`}
              onInputChanged={val => (val === '' || val.match(/^[0-9]*$/)) && this.setState({ runNumber: val })}
              value={runNumber}
              title={translate('general.run_number')}
              maxLength={16}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_client`}
              onInputChanged={val => this.setState({ client: val })}
              value={client}
              title={translate('general.client')}
              maxLength={16}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_object_type`}
              onInputChanged={val => this.setState({ objecttype: val })}
              value={objecttype}
              title={translate('general.object_type')}
              maxLength={8}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_parentname`}
              onInputChanged={val => this.setState({ parentname: val })}
              value={parentname}
              title={translate('general.parent_name')}
              maxLength={200}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_agentname`}
              onInputChanged={val => this.setState({ agentname: val })}
              value={agentname}
              title={translate('general.agent_name')}
              maxLength={200}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_archive_key_1`}
              onInputChanged={val => this.setState({ archivekey1: val })}
              value={archivekey1}
              title={`${translate('general.archive_key')} 1`}
              maxLength={60}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_archive_key_2`}
              onInputChanged={val => this.setState({ archivekey2: val })}
              value={archivekey2}
              title={`${translate('general.archive_key')} 2`}
              maxLength={60}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_only_job_errors`}
              title={translate('job.filter_by_job_errors')}
              maxPerRow={3}
              items={ONLY_JOB_ERRORS_ITEMS.map(d => translate(d.translationKey))}
              activeIndex={onlyJobErrors}
              onClick={index => this.setState({ onlyJobErrors: index })}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  onChangeNumericInput = (key, value) => {
    if (value === '' || value.match(/^[0-9]*$/)) {
      this.setState({ [key]: value })
    }
  }

  renderAdditional = () => {
    const { logSourceReader, returnCodeSwitch, returnCodeInput, statusSwitch, statusInput, cpuUsageSwitch, cpuUsageInput, runtimeSwitch, runtimeInput } = this.state
    const { id } = this.props
    return (
      <Card id={`${id}_job_data`} title={translate('general.additional')}>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_log_source_reader`}
              title={translate('general.log_source_reader')}
              activeIndex={logSourceReader}
              onClick={index => this.setState({ logSourceReader: index })}
              items={[translate('general.all'), translate('database.archive'), translate('search.online'), translate('general.migration')]}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_return_code`}
              title={translate('general.return_code')}
              activeIndex={returnCodeSwitch}
              onClick={index => this.setState({ returnCodeSwitch: index })}
              items={['<', '>', '=', '!=']}
              maxPerRow={4}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_return_code`}
              title={' '}
              value={returnCodeInput}
              onInputChanged={value => this.onChangeNumericInput('returnCodeInput', value)}
              maxLength={5}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_status`}
              title={translate('general.status')}
              activeIndex={statusSwitch}
              onClick={index => this.setState({ statusSwitch: index })}
              items={['<', '>', '=', '!=']}
              maxPerRow={4}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_status`}
              title={' '}
              value={statusInput}
              onInputChanged={value => this.onChangeNumericInput('statusInput', value)}
              maxLength={5}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_cpu_usage`}
              title={translate('general.cpu_usage')}
              activeIndex={cpuUsageSwitch}
              onClick={index => this.setState({ cpuUsageSwitch: index })}
              items={['<', '>', '=', '!=']}
              maxPerRow={4}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_cpu_usage`}
              title={' '}
              value={cpuUsageInput}
              onInputChanged={value => this.onChangeNumericInput('cpuUsageInput', value)}
              maxLength={5}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_runtime`}
              title={translate('general.runtime')}
              activeIndex={runtimeSwitch}
              onClick={index => this.setState({ runtimeSwitch: index })}
              items={['<', '>', '=', '!=']}
              maxPerRow={4}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_runtime`}
              title={' '}
              value={runtimeInput}
              onInputChanged={value => this.onChangeNumericInput('runtimeInput', value)}
              maxLength={5}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderTimeCard()}
        {this.renderSelectBy()}
        {this.renderJobData()}
        {this.renderAdditional()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props
    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { showJobgroupsDialog } = this.state
    const { id, selector } = this.props
    return (
      <>
        {showJobgroupsDialog && (
          <SelectorDialog
            id={`${id}_jobgroups_selector_dialog`}
            onClose={() => this.setState({ showJobgroupsDialog: false })}
            title={translate('definition.jobgroups')}
            header={[translate('general.jobgroup_name'), translate('general.title')]}
            items={selector.jobgroups.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newJobgroup = selector.jobgroups.data[selectedRows][selector.jobgroups.header.indexOf('JGIGNAME')]
                this.setState({ jobgroup: newJobgroup })
              }
              this.setState({ showJobgroupsDialog: false })
            }}
          />
        )}
        <form
          id={id}
          className={'bux_drawer_form'}
          onSubmit={this.handleSubmitSearch}>
          {this.renderMain()}
          {this.renderFooter()}
        </form>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences.DATEMASK,
    selector: state.selector
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch),
    getUC4Jobs: (searchObj, callback) => SearchUC4Actions.getUC4Jobs(undefined, searchObj, callback)(dispatch),
    getJobgroups: (fields, searchObj, callback) => ModalSelectorActions.getJobgroups(fields, searchObj, callback)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchUc4Search)