import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'

import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import { hasNoValues } from 'utils/ObjectUtils'
import * as Utils from 'utils/Utils'

// components
import {
  Button, Card, Column, Dropdown, Input,
  Modal as ModalComponent, NumericSpinner, Row, Switch
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as IndexDefinitionActions from 'redux/actions/IndexDefinitionActions'

class CreateIndexDialog extends Component {
  constructor(props) {
    super(props)
    this.defaultState = {
      indexId: {
        value: this.props.copyIndex ? this.props.index.IXINAME : '',
        errorkey: ''
      },
      owner: {
        value: this.props.copyIndex ? this.props.index.OWNER : '',
        errorkey: ''
      },
      identifier: {
        value: this.props.copyIndex ? this.props.index.IXENAME : '',
        errorkey: ''
      },
      maxLength: this.props.copyIndex ? this.props.index.IXMAXLEN : 0,
      activeDataFormatIndex: this.props.copyIndex
        ? DefinitionUtils.getIndexDataFormatTypes(false).findIndex(element => element.key === this.props.index.IXDTYPE)
        : Math.max(DefinitionUtils.getIndexDataFormatTypes(false).findIndex(element => element.key === DefinitionUtils.INDEX_DATA_FORMAT_STRING), 0),
      convertToUpper: this.props.copyIndex ? this.props.index.IXCASE : false,
      activeBlankBetweenWordsIndex: this.props.copyIndex ? 0 : Math.max(DefinitionUtils.INDEX_BLANKS_BETWEEN_WORDS.findIndex(element => element.key === DefinitionUtils.INDEX_BLANKS_BETWEEN_WORDS_KEEP_ALL), 0),
      numberOfDecimalPlaces: this.props.copyIndex ? this.props.index.IXNPLACE : 0
    }

    this.state = _.cloneDeep(this.defaultState)
  }

  indexIdInput = React.createRef()
  identifierInput = React.createRef()

  resetState = () => {
    this.setState(_.cloneDeep(this.defaultState))
  }

  /**
   * @description Sets the initial focus and initializes the values.
   */
  componentDidMount = () => {
    const { index } = this.props

    if (!this.props.copyIndex && this.props.prefilledData) {
      const activeDataFormatIndex = DefinitionUtils.getIndexDataFormatTypes(false).findIndex(element => element.key === this.props.prefilledData?.IXDTYPE)

      this.setState({
        indexId: {
          value: this.props.prefilledData?.IXINAME ?? '',
          errorkey: ''
        },
        owner: {
          value: this.props.prefilledData?.OWNER ?? '',
          errorkey: ''
        },
        identifier: {
          value: this.props.prefilledData?.IXENAME ?? '',
          errorkey: ''
        },
        activeDataFormatIndex: activeDataFormatIndex !== -1 ? activeDataFormatIndex : 0, // Fallback in case of 'Any' selection in search mask
      })
    }

    if (this.props.copyIndex) {
      this.setState({
        // only init active blank between words when data format is string
        activeBlankBetweenWordsIndex: index.IXDTYPE === DefinitionUtils.INDEX_DATA_FORMAT_STRING ? DefinitionUtils.INDEX_BLANKS_BETWEEN_WORDS.findIndex(element => element.key === index.IXBLANK) : this.state.activeBlankBetweenWordsIndex
      })
    }

    this.indexIdInput.current.focus()
  }

  /**
   * @description Handles the input changes of the input fields.
   * @param {String} id The id the input field.
   * @param {String} value The new value.
   * @param {String} errorkey The new errorkey.
   */
  handleInputChanged = (id, value, errorkey) => {
    this.setState({
      [id]: {
        value: value,
        errorkey: errorkey,
      }
    })
  }

  /**
   * @description Handles the changes values without error messages..
   * @param {String} id The id the field.
   * @param {String} value The new value.
   */
  handleChange = (id, value) => {
    this.setState({
      [id]: value
    })
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInputChanged(key, value, error)
  }

  /**
   * @description Validates the index id.
   */
  validateIndexId = () => {
    const { indexId } = this.state
    if (indexId.value !== '') {
      if (this.props.copyIndex && indexId.value === this.props.index.IXINAME) {
        return {
          indexId: {
            ...indexId,
            errorkey: 'definition.index_copy_same_id'
          }
        }
      } else {
        return {}
      }
    }
    return {
      indexId: {
        ...indexId,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the identifier.
   */
  validateIdentifier = () => {
    if (this.state.identifier.value !== '') {
      return {}
    }
    return {
      identifier: {
        ...this.state.identifier,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = {
      ...this.validateIndexId(),
      ...this.validateIdentifier()
    }

    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input which has an error.
   */
  handleFocus = () => {
    const requiredInputs = [
      { inputRef: this.indexIdInput, error: this.state.indexId.errorkey },
      { inputRef: this.identifierInput, error: this.state.identifier.errorkey }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { indexId, owner, identifier, maxLength, activeDataFormatIndex, convertToUpper, activeBlankBetweenWordsIndex, numberOfDecimalPlaces } = this.state

    if (this.validateInputs()) {
      const dataFormats = DefinitionUtils.getIndexDataFormatTypes(false)
      const activeDataFormat = dataFormats[activeDataFormatIndex].key
      const indexDefinition = {
        IXINAME: indexId.value,
        OWNER: owner.value,
        IXENAME: identifier.value,
        IXMAXLEN: maxLength,
        IXDTYPE: activeDataFormat,
      }

      // add number of decimal places when dataformat is decimal
      if (activeDataFormat === DefinitionUtils.INDEX_DATA_FORMAT_DECIMAL) {
        indexDefinition['IXNPLACE'] = numberOfDecimalPlaces
      } else if (activeDataFormat === DefinitionUtils.INDEX_DATA_FORMAT_STRING) {
        // add additional values when dataformat is string
        indexDefinition['IXCASE'] = convertToUpper
        indexDefinition['IXBLANK'] = DefinitionUtils.INDEX_BLANKS_BETWEEN_WORDS[activeBlankBetweenWordsIndex].key
      }

      const callback = () => { this.props.onClose() }
      this.props.createIndex(indexDefinition, callback)
    }
  }

  /**
   * @description Gets the translated data format items.
   * @returns {Array} The translated data format items.
   */
  getTranslatedDataFormatItems = () => {
    const items = []
    DefinitionUtils.getIndexDataFormatTypes(false).forEach(type => {
      items.push(translate(type.translationKey))
    })
    console.log(items)
    return items
  }

  /**
   * @description Gets the translated blank between words items.
   * @returns {Array} The translated blank between words items.
   */
  getTranslatedBlankBetweenWordItems = () => {
    const items = []
    DefinitionUtils.INDEX_BLANKS_BETWEEN_WORDS.forEach(type => {
      items.push(translate(type.translationKey))
    })
    return items
  }

  /**
   * @description: Ensures that the max-length numeric spinner always has a valid
   * values, based on the min-max range for the given data-format
   */
  setNumericSpinnerValueForMinMax = () => {
    const { min, max } = this.getMaxLengthRange()
    const { maxLength } = this.state

    const newMaxLength = maxLength > max
      ? max
      : maxLength < min
        ? min
        : maxLength

    this.setState({
      maxLength: newMaxLength
    })
  }

  /**
   * @description: Returns the range (min, max) for the "Max length"-spinner based on
   * the seletion in the Data-format dropdown
   * Data = 10 - 10,
   * String = 0 - 240
   * <others> = 0 - 64
   */
  getMaxLengthRange = () => {
    const dataFormats = DefinitionUtils.getIndexDataFormatTypes(false)
    const { activeDataFormatIndex } = this.state

    return dataFormats[activeDataFormatIndex].key === DefinitionUtils.INDEX_DATA_FORMAT_DATE
      ? { min: 10, max: 10 }
      : dataFormats[activeDataFormatIndex].key === DefinitionUtils.INDEX_DATA_FORMAT_STRING
        ? { min: 0, max: 240 }
        : { min: 0, max: 64 }
  }

  /**
 * Render the create dialog for Index-definitions
 */
  render = () => {
    const { id, onClose, copyIndex } = this.props
    const { indexId, owner, identifier, maxLength, activeDataFormatIndex, convertToUpper, activeBlankBetweenWordsIndex, numberOfDecimalPlaces } = this.state
    const dataFormats = DefinitionUtils.getIndexDataFormatTypes(false)
    const { min, max } = this.getMaxLengthRange()

    return (
      <Modal onClose={onClose}
        id={id}>
        <Header
          id={id}
          title={copyIndex ? translate('definition.copy_index') : translate('definition.create_index')}
          onClose={onClose}>
        </Header>
        <Main
          id={id}>
          <Card>
            <Row>
              <Column
                colMD={6}>
                { /* index id */}
                <Input
                  id={`${id}_index_id`}
                  value={indexId.value}
                  title={translate('definition.index_id')}
                  ref={this.indexIdInput}
                  required={`${translate('general.required_field')}`}
                  maxLength={16}
                  onInputChanged={(value, errorkey) => this.handleChangeWithoutSpaces('indexId', value, errorkey)}
                  error={indexId.errorkey && translate(indexId.errorkey)}
                  onBlur={() => this.setState({ ...this.validateIndexId() })}
                />
              </Column>
              <Column
                colMD={3}>
                {/* owner */}
                <Input
                  id={`${id}_owner`}
                  value={owner.value}
                  title={translate('general.owner')}
                  maxLength={8}
                  onInputChanged={(value, errorkey) => this.handleInputChanged('owner', value, errorkey)}
                  error={owner.errorkey && translate(owner.errorkey)}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}>
                {/* identifier */}
                <Input
                  id={`${id}_identifier`}
                  value={identifier.value}
                  title={translate('definition.index_identifier')}
                  ref={this.identifierInput}
                  maxLength={64}
                  required={`${translate('general.required_field')}`}
                  onInputChanged={(value, errorkey) => this.handleInputChanged('identifier', value, errorkey)}
                  error={identifier.errorkey && translate(identifier.errorkey)}
                  onBlur={() => this.setState({ ...this.validateIdentifier() })}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={12}>
                {/* hairline */}
                <hr />
              </Column>
            </Row>
            <Row isTitle>
              <Column colMD={12}>
                {/* attributes label */}
                <label>{translate('definition.attributes')}</label>
              </Column>
            </Row>
            <Row>
              <Column colMD={3}>
                {/* max length */}
                <NumericSpinner
                  id={`${id}_max_length`}
                  title={translate('definition.index_maxlength')}
                  value={maxLength}
                  steps={1}
                  min={min}
                  max={max}
                  disabled={min === max} /* Disable for fixed values */
                  onChange={value => this.handleChange('maxLength', value)}
                />
              </Column>
              <Column colMD={3} className={'bux_pr8'}>
                {/* data format */}
                <Dropdown
                  id={`${id}_index_dataformat`}
                  title={translate('definition.index_data_format')}
                  items={this.getTranslatedDataFormatItems()}
                  activeIndex={activeDataFormatIndex}
                  onChange={
                    index => this.setState({ activeDataFormatIndex: index },
                      () => { this.setNumericSpinnerValueForMinMax() })
                  }
                />
              </Column>
              {
                // render when dataformat decimal is selected
                dataFormats[activeDataFormatIndex].key === DefinitionUtils.INDEX_DATA_FORMAT_DECIMAL && (
                  <Column colMD={3}>
                    {/* number of decimal place */}
                    <NumericSpinner
                      id={`${id}_number_of_decimal_places`}
                      title={translate('definition.index_number_of_decimal_places')}
                      value={numberOfDecimalPlaces}
                      steps={1}
                      max={16}
                      min={0}
                      onChange={value => this.handleChange('numberOfDecimalPlaces', value)}
                    />
                  </Column>
                )
              }
            </Row>
            {/* render when dataformat string is selected */}
            {
              dataFormats[activeDataFormatIndex].key === DefinitionUtils.INDEX_DATA_FORMAT_STRING && (
                <Row>
                  <Column colMD={6}>
                    <Switch
                      id={`${id}_convert_to_uppercase`}
                      title={translate('definition.index_convert_values_to_upper')}
                      items={[translate('general.yes'), translate('general.no')]}
                      onClick={(index) => this.setState({ convertToUpper: index === 0 })}
                      activeIndex={convertToUpper ? 0 : 1}
                    />
                  </Column>
                  <Column colMD={6}>
                    <Switch
                      id={`${id}_blanks_between_words`}
                      title={translate('definition.index_blanks_between_words')}
                      items={this.getTranslatedBlankBetweenWordItems()}
                      onClick={(index) => this.setState({ activeBlankBetweenWordsIndex: index })}
                      activeIndex={activeBlankBetweenWordsIndex}
                      maxPerRow={3}
                    />
                  </Column>
                </Row>
              )
            }
          </Card>

        </Main>
        <Footer>
          {this.props.prefilledData && !hasNoValues(this.props.prefilledData) &&
            <Button
              id={`${id}_resetbtn`}
              tooltip={translate('general.reset')}
              icon={'undo'}
              onClick={this.resetState}
            />
          }
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

CreateIndexDialog.propTypes = {
  copyIndex: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  prefilledData: PropTypes.object,
  visible: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    userid: state.auth.userid,
    index: state.definitions.indexes.index
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createIndex: (indexDefinition, callback) => {
      IndexDefinitionActions.createIndex(indexDefinition, callback)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateIndexDialog)