import * as FilterArgumentActions from '../actions/FilterArgumentActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  filterArguments: undefined,
  filterArgument: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get filter arguments actions
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_START:
      return { ...state }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_SUCCESS:
      return { ...state, filterArguments: action.payload.data, keepPagination: action.keepPagination }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENTS_FAILED:
      return { ...state }
    case FilterArgumentActions.NO_FILTERARGUMENT_DEFINITION_FILTERARGUMENTS_FOUND:
      return { ...state, filterArguments: {} }
    /* ********************************************************************************** */
    // Definition create filter argument actions
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_START:
      return { ...state }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_SUCCESS:
      return { ...state }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_CREATE_FILTERARGUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition get filter argument actions
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_START:
      return { ...state, filterArgument: undefined }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_SUCCESS:
      return { ...state, filterArgument: action.payload }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_GET_FILTERARGUMENT_FAILED:
      return { ...state }
    // Definition delete filter argument actions
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_START:
      return { ...state }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_SUCCESS:
      return { ...state }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_DELETE_FILTERARGUMENT_FAILED:
      return { ...state }
    // Definition update filter argument actions
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_START:
      return { ...state }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_SUCCESS:
      return { ...state }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_UPDATE_FILTERARGUMENT_FAILED:
      return { ...state }
    // Definition verify output channel
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_START:
      return { ...state }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_SUCCESS:
      return { ...state, verify: action.payload }
    case FilterArgumentActions.FILTERARGUMENT_DEFINITION_VERIFY_FILTERARGUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, filterArguments: undefined, filterArgument: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer