
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import ErrorMessage from '../error_message/ErrorMessage';
import Icon from '../icon/Icon';
import Label from '../label/Label';

// Style
import './Combobox.scss'

/**
 * Combobox component created according to
 * _Input_ and _Dropdown_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
export default class Combobox extends Component {

  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Reference to access DOM nodes */
    focusRef: PropTypes.any,
    /**
     * Function to be called on change selection in component
     * @param {string} value New selected value
     * @param {string} error Error msg from _regexError_ if _reqexValidation_ fails, otherwise empty string.
     */
    onChange: PropTypes.func.isRequired,
    /** Initial value of the combobox */
    value: PropTypes.string,
    /** Label to be displayed above the combobox.*/
    title: PropTypes.string,
    /** Error description to be displayed below the combobox.*/
    error: PropTypes.string,
    /** Tooltip description for expand button on dropdown */
    addonTooltip: PropTypes.string,
    /** Sets max length of entered value into input.*/
    maxLength: PropTypes.number,
    /** Tab index */
    tabindex: PropTypes.string,
    /** Function to be called on an blur event.*/
    onBlur: PropTypes.func,
    /** Disables components visually and functionally. */
    disabled: PropTypes.bool,
    /** If true, sets keys of _items_ to uppercase. */
    uppercase: PropTypes.bool,
    /**
     * Sets asterisk, next to the _title_, which indicates that fields is required.
     * If _title_ is not set, asterisk will not be shown.
     */
    required: PropTypes.bool,
    /** Ignores the min width property of inputs */
    ignoreMinWidth: PropTypes.bool,
    /**
     * Function to translate following key by our own.
     *
     * - `general.custom`
     * - `general.required`
     *
     * @param {string} key
     */
    translate: PropTypes.func.isRequired,
    /** Regex pattern for validation */
    regexValidation: PropTypes.any,
    /** Error description in case of regex validation failure */
    regexError: PropTypes.string,
    /**
     * Function to be called when "space"-key is pressed.
     * @deprecated
     */
    onAddon: PropTypes.func,
    /** Array of items */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        key: PropTypes.string
      })),
    /** Id of the container */
    containerID: PropTypes.string,
    /** Text to be displayed when no _value_ passed */
    placeholder: PropTypes.string,
    /** Style class from CSS for styling for input container.*/
    inputClass: PropTypes.string,
    /** Style class from CSS for styling for component container.*/
    containerClass: PropTypes.string
  }

  setWrapperRef = React.createRef()
  setButtonRef = React.createRef()
  setWrapperRefList = React.createRef()
  handleClickOutside = React.createRef()
  handleScrollOutside = React.createRef()

  state = {
    mousedown: false,
    hasFocus: false,
    showItems: false,
    keyboardFocusedIndex: -1,
  }

  /**
   * @description
   */
  componentWillUpdate = () => {
    this.itemRefs = this.buildItemsRefs()
  }

  /**
   * @description Updates focus on list element
   */
  componentDidUpdate = () => {
    if (this.state.showItems) {
      if (this.state.keyboardFocusedIndex !== -1) {
        this.itemRefs[this.state.keyboardFocusedIndex] &&
          this.itemRefs[this.state.keyboardFocusedIndex].current &&
          this.itemRefs[this.state.keyboardFocusedIndex].current.focus()
      }
    }
  }
  /**
   * @description Adds a mousedown event listener to hide the menu when clicking outside.
   */
  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('focus', this.handleFocusOutside, true);
    document.addEventListener('scroll', this.handleScrollOutside, true)
  }

  /**
   * @description Removes the mousedown event listener.
   */
  componentWillUnmount = () => {
    const { onBlur } = this.props
    onBlur && onBlur()
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('focus', this.handleFocusOutside, true);
    document.removeEventListener('scroll', this.handleScrollOutside, true)
  }

  /**
   * @description Builds the ref array
   * @returns {Array}
   */
  buildItemsRefs() {
    const { items } = this.props
    const refs = []
    // first ref is for custom item
    refs.push(React.createRef())
    items.forEach(() => {
      refs.push(React.createRef())
    })
    return refs
  }

  /**
   * @description Hides the menu if the click of the event is outside of the menu.
   * @param {Object} event The mouse event.
   */
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.wrapperRefList && !this.wrapperRefList.contains(event.target)) {
      this.setState({ showItems: false, keyboardFocusedIndex: -1 })
    }
  }

  /**
   * @description Hides the menu if the scroll of the event is outside of the menu.
   * @param {Object} event The mouse event.
   */
  handleScrollOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.wrapperRefList && !this.wrapperRefList.contains(event.target)) {
      this.setState({ showItems: false, keyboardFocusedIndex: -1 })
    }
  }

  /**
   * @description Event which detect the focus outside of the component.
   */
  handleFocusOutside = (event) => {
    if (this.wrapperRefButton && !this.wrapperRefButton.current?.contains(event.target)) {
      if (this.state.showMenu) {
        this.setState({
          showMenu: false,
          keyboardFocusedIndex: -1
        });
      }
    }
  };

  /**
   * @description Sets the wrapper reference to compare the click event component
   * and decide if it's the same component.
   * @param {Object} node The new reference.
   */
  setWrapperRef = node => {
    this.wrapperRef = node
  }


  /**
   * @description Sets the wrapper reference to compare the click event component
   * and decide if it's the same component.
   * @param {Object} node The new reference.
   */
  setButtonRef = node => {
    this.buttonRef = node
  }

  /**
   * @description Sets the wrapper reference to compare the click event component
   * and decide if it's the same component.
   * @param {Object} node The new reference.
   */
  setWrapperRefList = node => {
    this.wrapperRefList = node
  }

  handleChange = val => {
    const { onChange, regexValidation, regexError, uppercase } = this.props
    let error = ''
    if (uppercase) {
      val = val.toUpperCase()
    }
    if (regexValidation) {
      if (!val.match(regexValidation)) {
        error = regexError
      }
    }
    onChange(val, error)
  }

  /**
   * @description Handles key down for the component, to allow to keyboard navigation
   * "Enter" open and close list popup and also allows to select item from the list.
   * "ArrowDown" and "ArrowUp" allow to select item on the list.
   * "Escape" close opened list popup.
   */
  handleKeyDown = (e) => {
    if (this.state.showItems) {
      switch (e.key) {
        case 'Enter':
          this.handleEnterKey(e)
          break
        case 'ArrowDown':
        case 'ArrowUp':
          this.handleArrowsKeys(e)
          break
        case 'Escape':
          this.handleEscapeKey()
          break
        default:
          return false
      }
    }
    return false
  }

  /**
   * @description Handles on enter key down for the component, to allow to keyboard navigation
   * "Enter" open and close list popup and also allows to select item from the list.
   */
  handleEnterKey = (e) => {
    if (this.state.keyboardFocusedIndex !== -1) {
      this.handleItemClick(this.props.items[this.state.keyboardFocusedIndex - 1]?.key)
      this.buttonRef && this.buttonRef.focus()
      e.preventDefault()
    }
  }

  /**
   * @description Handles on ArrowDown and ArrowUp keys down for the component, to allow to keyboard navigation
   */
  handleArrowsKeys = (e) => {
    let currentIndex = this.state.keyboardFocusedIndex
    if (currentIndex === -1) {
      currentIndex = this.props.items.findIndex((item) => item.key === this.props.value)
    }
    this.changeKeyboardFocus(currentIndex + (e.key === 'ArrowDown' ? 1 : -1))
    // prevent scroll page on arrows keys
    e.preventDefault()
  }

  /**
   * @description Handles on Escape key down for the component, to allow to keyboard navigation
   */
  handleEscapeKey = () => {
    this.buttonRef && this.buttonRef.focus()
    this.setState({ showItems: false })
  }

  /**
   * @description Changes state of keyboardFocusedIndex,
   * makes sure the index has right value and does not exceed the items length
   */
  changeKeyboardFocus = (index) => {
    let newIndex = index
    const length = this.props.items.length + 1
    if (index >= length) {
      newIndex = length - 1
    }
    if (index < 0) {
      newIndex = 0
    }
    if (this.state.keyboardFocusedIndex !== newIndex) {
      this.setState({ keyboardFocusedIndex: newIndex })
    }
  }

  /**
   * @description inverts the value of isListOpen
   */
  toggleDropdown = () => {
    this.setState(prevState => ({ showItems: !prevState.showItems, keyboardFocusedIndex: -1 }))
  }

  /**
   * @description Execute the onAddon event when "space"-key is pressed.
   * @param {Object} event
   */
  handleOnAddonWithKey = event => {
    const keyCode = event.keyCode || event.which
    // space
    if (keyCode === 32) {
      this.props.onAddon()
    }
  }

  /**
   * @description Adds the focus style to a specific element.
   * @param {id} id The id from that element.
   */
  addFocusStyle = id => {
    if (!this.state.mousedown) {
      const el = document.querySelector(`#${id}`)
      if (document.activeElement.id === id) {
        el.classList.add('bux_dropdown_focus_style')
      }
    }
  }

  /**
   * @description Removes the focus style from a specific element.
   * @param {id} id The id from that element.
   */
  removeFocusStyle = id => {
    const el = document.querySelector(`#${id}`)
    el.classList.remove('bux_dropdown_focus_style')
  }

  /**
   * @description Adds the active style on a specific element.
   * @param {object} event The event of the specific action on an element.
   * @param {string} id The id from that element.
   */
  addActiveStyle = (event, id) => {
    let permission = true
    if (event) {
      permission = false
      if (event.keyCode === 13) {
        permission = true
      }
    }
    if (permission) {
      const el = document.querySelector(`#${id}`)
      el.classList.remove('bux_focus_style')
      el.classList.add('bux_input_active_addon')
    }
  }

  /**
   * @description Adds the active style on a specific element.
   * @param {object} event The event of the specific action on an element.
   * @param {string} id The id from that element.
   */
  removeActiveStyle = (event, id) => {
    let permission = true
    if (event) {
      permission = false
      if (event.keyCode === 13) {
        permission = true
      }
    }
    if (permission) {
      const el = document.querySelector(`#${id}`)
      el.classList.remove('bux_input_active_addon')
      const hoveredAddon = document.querySelector(`#${this.props.id}_div :hover`)
      if (document.activeElement.id === id && (!hoveredAddon || hoveredAddon.id !== id)) {
        el.classList.add('bux_focus_style')
      }
    }
  }

  /**
   * @description Execute the onBlur event (when given) and removes the focus style.
   */
  addonHandleBlur = () => {
    const { onBlur, id } = this.props
    // timeout is needed because the body receives the focus instant before the focus is set to the next element
    setTimeout(() => {
      const idsFromFocusableElements = [`${id}_input_dropdown_input`, `${id}_input_dropdown_btn`, `${id}_menu_list`]
      if (!idsFromFocusableElements.includes(document.activeElement.id)) {
        onBlur && onBlur()
      }
    }, 1)
    this.removeFocusStyle(`${id}_input_dropdown_btn`)
  }

  /**
   * @description Execute the onBlur event (when given) and removes teh focus style.
   */
  inputHandleBlur = () => {
    const { onBlur, id } = this.props
    // timeout is needed because the body receives the focus instant before the focus is set to the next element
    setTimeout(() => {
      const idsFromFocusableElements = [`${id}_input_dropdown_input`, `${id}_input_dropdown_btn`, `${id}_menu_list`]
      if (!idsFromFocusableElements.includes(document.activeElement.id)) {
        onBlur && onBlur()
      }
    }, 1)
    this.setState({ hasFocus: false })
  }

  /**
   * @description Sets the mousedown flag to true, removes the focus style and adds the active style.
   * @param {id} id The id from that element.
   */
  handleMouseDown = id => {
    this.setState({ mousedown: true }, () => {
      this.removeFocusStyle(id)
    })
  }

  /**
   * @description Sets the mousedown flag to false and removes the active style.
   * @param {id} id The id from that element.
   */
  handleMouseUp = () => {
    this.setState({ mousedown: false })
  }

  /**
   * @description Sets the mousedown flag to false, removes the active style and adds the focus style.
   * @param {id} id The id from that element.
   */
  handleMouseLeave = id => {
    if (document.activeElement.id === id) {
      this.setState({ mousedown: false }, () => {
        this.addFocusStyle(id)
      })
    }
  }
  handleItemClick = item => {
    const { focusRef } = this.props
    item !== undefined && this.handleChange(item)
    this.setState({ showItems: false }, () => focusRef && focusRef.current && focusRef.current.focus())
  }

  getItems = () => {
    const { id, value } = this.props
    const items = [
      <li
        ref={this.itemRefs[0]}
        tabIndex={this.keyboardFocusedIndex === 0 ? 0 : -1}
        id={`${id}_menu_list_item_${0}`}
        key={`${id}_menu_list_item_${0}`}
        title={this.props.translate('general.custom')}
        onClick={() => this.handleItemClick()}
        className={`${this.props.items.findIndex(d => d.key === value) === -1 ? 'selected' : ''} menu_item`}>
        {this.props.translate('general.custom')}
      </li>,
      <li key={`${id}_menu_list_item_separator`} className={'dropdown-divider'}></li>
    ]
    this.props.items.forEach((item, i) => {
      items.push(
        <li
          tabIndex={(i + 1) === this.keyboardFocusedIndex ? 0 : -1}
          ref={this.itemRefs[i + 1]}
          key={`${id}_menu_list_item_${i + 1}`}
          id={`${id}_menu_list_item_${i + 1}`}
          title={item.key}
          onClick={() => this.handleItemClick(item.key)}
          className={`${item.key === value ? 'selected' : ''} menu_item`}>
          {item.display}
        </li>
      )
    })
    return items
  }

  renderItems = () => {
    const { id, containerID } = this.props
    const items = this.getItems()
    const getDropdownStyling = () => {
      const el = document.querySelector(`#${id}_input_dropdown_container`)
      const elError = document.querySelector(`#${id}_input_dropdown_error`)
      const elErrorHeight = elError?.getBoundingClientRect().height ?? 0;
      const container = containerID
        ? document.querySelector(`#${containerID}`)
        : [document.querySelector('.bux_drawer_main'), document.querySelector('.tileContainer')]
      let containerOffsetTopScroll = 0
      if (!Array.isArray(container)) {
        containerOffsetTopScroll = container.scrollTop
      } else {
        // check if dropdown in inside modal
        if (document.querySelector('.bux_modal')) {
          if (container[1]) {
            containerOffsetTopScroll = container[1].scrollTop
          }
        }
        // check if dropdown is inside the drawer
        else if (document.querySelector(`.bux_drawer_main #${id}_dropdown`)) {
          if (container[0]) {
            containerOffsetTopScroll = container[0].scrollTop
          }
        }
      }
      const maxHeightToUse = window.innerHeight - el.getBoundingClientRect().top - el.getBoundingClientRect().height
      const possibleItems = Math.floor(maxHeightToUse / 40)
      return {
        marginTop: el.getBoundingClientRect().height - elErrorHeight - containerOffsetTopScroll - window.scrollY -1,
        marginLeft: -window.scrollX,
        maxWidth: el.getBoundingClientRect().width,
        height: 42 + (items.length < possibleItems ? (items.length - 1) * 40 : (possibleItems - 1) * 40)
      }
    }
    const dropdownStyles = getDropdownStyling()
    return (
      <ul
        id={`${id}_menu_list`}
        tabIndex={-1}
        className='dropdown-menu bux_dropdown_menu'
        style={{ marginTop: dropdownStyles.marginTop, marginLeft: dropdownStyles.marginLeft, width: dropdownStyles.maxWidth, height: dropdownStyles.height }}
        ref={this.setWrapperRefList}>
        {items}
      </ul>
    )
  }

  render = () => {
    const { showItems } = this.state
    const {
      id,
      tabindex,
      focusRef,
      maxLength,
      addonTooltip,
      error,
      disabled,
      placeholder,
      value,
      inputClass,
      containerClass,
      ignoreMinWidth,
      title,
      required,
      translate
    } = this.props
    const errorClass = error && 'error'
    return (
      <div
        id={`${id}_input_dropdown_container`}
        className={`bux_input_dropdown ${containerClass || ''} ${ignoreMinWidth ? 'bux_ignore_min_width' : ''}`}
        ref={this.setWrapperRef}
        onKeyDown={this.handleKeyDown}>
        <Label
          id={`${id}_input_dropdown_label`}
          title={title}
          required={ required ? translate?.('general.required') : false}
          disabled={disabled}
          isError={!!error}
        />
        <div className={`bux_input_dropdown_input_container input-group ${inputClass || ''}`}>
          <input
            id={`${id}_input_dropdown_input`}
            ref={focusRef}
            value={value}
            invalid={error ? 'true' : 'false'}
            tabIndex={tabindex}
            maxLength={maxLength}
            type={'text'}
            className={`form-control ${errorClass} ${showItems && 'dropdownMenuOpen'}`}
            disabled={disabled}
            placeholder={placeholder}
            onChange={event => this.handleChange(event.target.value)}
            onFocus={() => this.setState({ hasFocus: true })}
            onBlur={() => this.inputHandleBlur()}
          />
          <button
            id={`${id}_input_dropdown_btn`}
            ref={this.setButtonRef}
            className={`input-group-addon ${showItems && 'dropdownMenuOpen'}`}
            tabIndex={tabindex || 0}
            title={addonTooltip || null}
            onClick={() => this.toggleDropdown()}
            onMouseOut={e => e.currentTarget.blur()}
            type={'button'}
            disabled={disabled}>
            <Icon id={`${id}_addon`} name={showItems ? 'chevron_up' : 'chevron_down'} />
          </button>
        </div>
        <ErrorMessage id={`${id}_input_dropdown_error`} text={error} />
        {
          showItems &&
          this.renderItems()
        }
      </div>
    )
  }
}