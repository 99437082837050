import { Dropdown } from 'BetaUX2Web-Components/src/'
import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as Preferences from 'redux/general/Preferences'
import ServerBody from './server_body/ServerBody'

class BUXServer extends Component {

  /**
   * @description Handles the changes of the server dropdown.
   * @param {Number} activeIndex The new active index.
   */
  handleChangeServer = activeIndex => {
    const link = this.getLink(activeIndex)

    if (link !== undefined) {
      this.props.history.push(link)
    }
  }

  /**
   * @description Gets the titles of the items.
   */
  getItems = () => {
    const items = []

    this.props.items.map(item =>
      items.push(item.title)
    )

    return items
  }

  /**
   * @description Gets the link of the active index from the items.
   * @param {Number} activeIndex The current active index of the dropdown.
   */
  getLink = (activeIndex) => {
    // get the object with the current active index and return the link
    return this.props.items.find((_, currentIndex) => currentIndex === activeIndex).link
  }

  /**
   * @description Gets the active index of an server mode.
   * @param {String} servermode The server mode.
   */
  getActiveIndex = servermode => {
    // get the index of the match with the link of the item and the servermode
    let index = this.props.items.findIndex((item) => item.link.substring(item.link.lastIndexOf('/') + 1) === servermode)

    // select first index if there is no item selected
    if (index === -1)
      index = 0

    return index
  }

  render = () => {
    const { id, match } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }
    return (
      <div id={id} className='drawer_field'>
        <div id={`${id}_definition_container1`} className='drawer_header_big bux_drawer_header'>
          <div id={`${id}_definition_container2`} className='row search_row'>
            <div id={`${id}_definition_container3`} className='dropdown el_dropdown'>
              <Dropdown
                id={`${id}_server`}
                title={translate('general.server')}
                items={this.getItems()}
                activeIndex={this.getActiveIndex(url)}
                onChange={activeIndex => { this.handleChangeServer(activeIndex) }}
              />
            </div>
          </div>
        </div>

        <ServerBody id={`${id}_body`} serverMode={url} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

BUXServer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withRouter(BUXServer))