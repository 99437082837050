import * as MaintenanceActions from '../actions/MaintenanceActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  jobExecute: undefined,
  jobs: undefined,
  utility: undefined,
  jobOutput: undefined,
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Maintenance executeJob actions
    case MaintenanceActions.MAINTENANCE_EXECUTE_JOB_START:
      return { ...state, jobExecute: undefined }
    case MaintenanceActions.MAINTENANCE_EXECUTE_JOB_SUCCESS:
      return { ...state, jobExecute: action.payload }
    case MaintenanceActions.MAINTENANCE_EXECUTE_JOB_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Maintenance getJobs actions
    case MaintenanceActions.MAINTENANCE_GET_JOBS_START:
      return { ...state }
    case MaintenanceActions.MAINTENANCE_GET_JOBS_SUCCESS:
      return { ...state, jobs: action.payload.data }
    case MaintenanceActions.MAINTENANCE_GET_JOBS_FAILED:
      return { ...state }
    case MaintenanceActions.NO_MAINTENANCE_JOBS_FOUND:
      return { ...state, jobs: {} }
    /* ********************************************************************************** */
    // Maintenance executeUtility actions
    case MaintenanceActions.MAINTENANCE_EXECUTE_UTILITY_START:
      return { ...state, utility: undefined }
    case MaintenanceActions.MAINTENANCE_EXECUTE_UTILITY_SUCCESS:
      return { ...state, utility: action.payload }
    case MaintenanceActions.MAINTENANCE_EXECUTE_UTILITY_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Maintenance getJobOutput actions
    case MaintenanceActions.MAINTENANCE_GET_JOBOUTPUT_START:
      return { ...state, jobOutput: undefined }
    case MaintenanceActions.MAINTENANCE_GET_JOBOUTPUT_SUCCESS:
      return { ...state, jobOutput: action.payload }
    case MaintenanceActions.MAINTENANCE_GET_JOBOUTPUT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return {
        ...state, jobExecute: undefined, jobs: undefined, utility: undefined, jobOutput: undefined,
      }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer