import * as DateUtils from 'utils/DateUtils';
import moment from 'moment';

interface TimeValue {
  value: string;
  error: string;
}

interface TimeCardType {
  activeTimeTabIndex: number;
  type: number;
  period: number;
  unit: number;
  startDate: TimeValue;
  startTime: TimeValue;
  endDate: TimeValue;
  endTime: TimeValue;
}

interface TimeCardErrorType {
  startDate?: TimeValue | undefined;
  startTime?: TimeValue | undefined;
  endDate?: TimeValue | undefined;
  endTime?: TimeValue | undefined;
}

export const validateTimecardErrors = (time: TimeCardType, datemask: string) => {
  const errorObj: TimeCardErrorType = {};
  if (time.activeTimeTabIndex === 1) {
    if (time.startDate.value !== '' && !DateUtils.isDate(time.startDate.value, datemask) && !DateUtils.validDateStrings.includes(time.startDate.value.toUpperCase())) {
      errorObj.startDate = {
        value: time.startDate.value,
        error: 'general.invalid_date_format'
      }
    }
    if (time.endDate.value !== '' && !DateUtils.isDate(time.endDate.value, datemask) && !DateUtils.validDateStrings.includes(time.endDate.value.toUpperCase())) {
      errorObj.endDate = {
        value: time.endDate.value,
        error: 'general.invalid_date_format'
      }
    }
    if (time.startTime.value !== '' && !time.startTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
      errorObj.startTime = {
        value: time.startTime.value,
        error: 'general.invalid_time_format'
      }
    }
    if (time.endTime.value !== '' && !time.endTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
      errorObj.endTime = {
        value: time.endTime.value,
        error: 'general.invalid_time_format'
      }
    }
  }
  // Set error if the to date is before the from date and both inputs are filled with a date.
  if (!errorObj.endDate) {
    if (time.startDate.value !== '' && time.endDate.value !== '') {
      if (DateUtils.isDate(time.startDate.value, datemask) && DateUtils.isDate(time.endDate.value, datemask)) {
        const startDate = moment(time.startDate.value, datemask)
        const endDate = moment(time.endDate.value, datemask)
        if (endDate.isBefore(startDate)) {
          errorObj.endDate = {
            value: time.endDate.value,
            error: 'statistic.negative_date_difference_error'
          }
        }
      }
    }
  }

  return errorObj
}