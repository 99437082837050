import React from 'react';
import { FlyoutData, FlyoutDataObject, isFlyoutDataObjectType } from '../Flyout.types';
import Icon from '../../icon/Icon';

interface FlyoutItemProps {
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** Text to be displayed in the item */
  item: FlyoutData;
  /** Function to be called on an click event.*/
  onClick: () => void;
  /** Indicates if item is selected or not*/
  selected: boolean;
  focusRef: React.RefObject<HTMLLIElement>;
  tabIndex: number;
  /** Don't use selected class */
  isSelectable?: boolean;
}

const FlyoutItem = ({ id, item, onClick, selected, focusRef, tabIndex, isSelectable = true }: FlyoutItemProps): JSX.Element => {
  const text = isFlyoutDataObjectType(item) ? (item as FlyoutDataObject).text : item as string;
  const icon = isFlyoutDataObjectType(item) ? (item as FlyoutDataObject).icon : undefined;
  const selectedClass = (selected && isSelectable) ? 'selected' : '';
  return (
    <li
      id={id}
      ref={focusRef}
      tabIndex={tabIndex || -1}
      className={selectedClass}
      onClick={() => onClick()}>
      {icon && <Icon id='menu_item_icon' name={icon} />}
      <span>{text}</span>
    </li>
  );
};

export default FlyoutItem;