export const LOADING_SPINNER_SHOW = 'LOADING_SPINNER_SHOW'
export const LOADING_SPINNER_HIDE = 'LOADING_SPINNER_HIDE'

/**
 * @description Shows the loading spinner.
 */
export function show() {
  return dispatch => {
    dispatch({
      type: LOADING_SPINNER_SHOW,
    })
  }
}

/**
 * @description Hides the loading spinner.
 */
export function hide() {
  return dispatch => {
    dispatch({
      type: LOADING_SPINNER_HIDE,
    })
  }
}