import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Card from 'BetaUX2Web-Components/src/components/card/Card'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as OutputFormatActions from 'redux/actions/OutputFormatDefinitionActions'

class DefinitionOutputFormat extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    outputFormatID: {
      value: '',
      errorkey: ''
    },
    owner: {
      value: '',
      errorkey: ''
    },
    title: {
      value: '',
      errorkey: ''
    }
  }

  state = {
    ...this.defaultState
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount = () => {
    this.initFieldsFromPreferences()
  }

  /**
  * @description Initializes the import fields with the values saved in preferences.
  */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props

    if (preferences) {
      const outputFormatID = preferences[Preferences.DEFINITION_OUTPUTFORMAT_ID] || ''
      const owner = preferences[Preferences.DEFINITION_OUTPUTFORMAT_OWNER] || ''
      const title = preferences[Preferences.DEFINITION_OUTPUTFORMAT_TITLE] || ''

      this.setState({
        outputFormatID: {
          value: outputFormatID,
          errorkey: ''
        },
        owner: {
          value: owner,
          errorkey: ''
        },
        title: {
          value: title,
          errorkey: ''
        }
      })
    }
  }

  /**
   * @description Handles changes in input fields.
   * @param id The id of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInput = (id, value, errorkey = '') => {
    this.setState({ [id]: { value, errorkey } })
  }

  /**
   * @description Handles the search action.
   * @param event The event which is thrown by the button
   */
  handleSearch = event => {
    event.preventDefault()
    const { outputFormatID, owner, title } = this.state
    const { getOutputFormats, changePrefs } = this.props

    const prefsToChange = {
      [Preferences.DEFINITION_OUTPUTFORMAT_ID]: outputFormatID.value,
      [Preferences.DEFINITION_OUTPUTFORMAT_OWNER]: owner.value,
      [Preferences.DEFINITION_OUTPUTFORMAT_TITLE]: title.value
    }

    changePrefs(prefsToChange)
    getOutputFormats(undefined, outputFormatID.value, owner.value, title.value)
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleReset = () => this.setState(this.defaultState)

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props
    const { outputFormatID, owner, title } = this.state

    return (
      <div
        id={`${id}_main_outputformat`}
        className={'bux_drawer_main'}>
        <Card
          title={translate('general.general')}>
          <Row>
            <Column
              colMD={6}>
              <Input
                id={`${id}_outputformat_id`}
                onInputChanged={(val, err) => { this.handleInput('outputFormatID', val, err) }}
                value={outputFormatID.value}
                title={translate('definition.output_format_id')}
                error={outputFormatID.errorkey && translate(outputFormatID.errorkey)}
                maxLength={16}
              />
            </Column>
            <Column
              colMD={6}>
              <Input
                id={`${id}_outputformat_owner`}
                onInputChanged={(val, err) => { this.handleInput('owner', val, err) }}
                value={owner.value}
                title={translate('definition.folder_owner')}
                error={owner.errorkey && translate(owner.errorkey)}
                maxLength={8}
              />
            </Column>
          </Row>
          <Row>
            <Column
              colMD={12}>
              <Input
                id={`${id}_outputformat_title`}
                onInputChanged={(val, err) => { this.handleInput('title', val, err) }}
                value={title.value}
                title={translate('general.title')}
                error={title.errorkey && translate(title.errorkey)}
                maxLength={80}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleReset}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={() => { }}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getOutputFormats: (fields, outputFormat, owner, title, callback) => {
      OutputFormatActions.getOutputFormats(fields, outputFormat, owner, title, callback)(dispatch)
    },
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionOutputFormat)