export function prepareClassNames(array: (string | boolean | number | null | undefined)[]): string {
  return array.filter(Boolean).join(' ').trim().replace(/\s\s+/g, ' ');
}

export function autoFocusOnFirstTabIndex(id: string): void {

  // get the modal dialog as container element
  const containerElement = document.querySelector(`#${id}`);
  if (containerElement === null || document.activeElement === null) {
    return;
  }
  // check if the current focused element is inside the container element
  const isFocusOutsideModal = !containerElement.contains(document.activeElement);
  // if the focus is outside set it to the next component inside -> modal dialog
  if (isFocusOutsideModal) {
    const wrapperElement = containerElement.querySelector('[tabindex = "0"]') as HTMLElement;
    // set the focus
    if (wrapperElement !== null) {
      wrapperElement.focus();
    }
  }
}

// eslint-disable-next-line
export function getStyle(element: HTMLElement, styleProp: any): string {
  // eslint-disable-next-line
  let propValue: any = undefined;
  if (element?.style) {
    propValue = element.style[styleProp];
  }
  return propValue;
}