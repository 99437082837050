import PropTypes from 'prop-types'
import { Component } from 'react'

// components
import { DataTable, DownloadWrapper, Link, NoSearch, ResultContainer } from 'BetaUX2Web-Components/src/'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as DatabaseActions from 'redux/actions/DatabaseActions'
import * as PreferencesActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as SortUtils from 'utils/SortUtils'
import * as Utils from 'utils/Utils'

class SearchResultDatabaseQuery extends Component {

  /**
   * @description Refreshs the current table.
   */
  handleRefresh = () => {
    const { preferences } = this.props
    const command = preferences[Preferences.DATABASE_QUERY_QUERY]
    const toUpper = preferences[Preferences.DATABASE_QUERY_TOUPPER]
    this.props.executeBqlQuery(toUpper ? command.toUpperCase() : command)
  }

  // reset redux state
  componentDidUpdate = () => {
    const { bqlQuery, orgBqlQuery } = this.props
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DATABASE_QUERY] && !Utils.equalsArrays(orgBqlQuery, bqlQuery)) {
      this.props.changePrefs({ [Preferences.TABLE_SETTINGS_DATABASE_QUERY]: undefined })
    }
  }

  /**
   * @description Gets the header for the table.
   */
  getHeader = () => this.props.bqlQuery && this.props.bqlQuery.header

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData = () => {
    const { datemask, bqlQuery } = this.props
    const dateRegEx = /^\d{2}([./-])\d{2}\1\d{4}$/
    const data = []
    let headers = this.props.preferences[Preferences.TABLE_SETTINGS_DATABASE_QUERY]
      ? this.props.preferences[Preferences.TABLE_SETTINGS_DATABASE_QUERY].displayedHeaders
      : this.getDefaultHeader()
    bqlQuery.data.forEach(element => {
      let dataBuffer = []
      headers.forEach(header => {
        let el = element[bqlQuery.header.indexOf(header)]
        if (el.match(dateRegEx)) {
          dataBuffer.push(DateUtils.getDate(datemask, el))
        }
        else {
          dataBuffer.push(el)
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
          />
        </DownloadWrapper>
      ]
    )
  }

  getDefaultHeader() {
    const { bqlQuery } = this.props
    let buffer = []
    bqlQuery && bqlQuery.header.forEach(header => {
      buffer.push(header)
    })
    return buffer
  }

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  render = () => {
    const { id, bqlQuery, drawerExpanded, autoDrawer, lang, datemask } = this.props
    const data = bqlQuery ? this.getCleanData() : null
    let header = this.getDefaultHeader()
    return (
      <>
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {bqlQuery
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={header.map(h => translate(h, undefined, undefined, false) ? translate(h) : h)}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    columnSortDefs={this.getColumnSortDefs(data, header)}
                    additionalInteraction={this.createInteractionButtons(data, header.map(h => translate(h, undefined, undefined, false) ? translate(h) : h))}
                    fillPage
                    translate={key => translate(key)}
                    language={lang}
                    datemask={datemask}
                  />
                )
                : (
                  <NoSearch
                    id={`${id}_nosearch`}
                    type={'query'}
                    message={translate('nosearch.no_result_data')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                type={'query'}
                message={translate('nosearch.no_result_data')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

SearchResultDatabaseQuery.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    bqlQuery: state.database.copiedBqlQuery,
    orgBqlQuery: state.database.bqlQuery,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    executeBqlQuery: (bqlCommand, callback) => {
      DatabaseActions.executeBqlQuery(bqlCommand, callback)(dispatch)
    },
    changePrefs: (prefs, callback) => {
      PreferencesActions.changePrefs(prefs, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultDatabaseQuery)