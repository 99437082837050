export const TRANSLATIONS = {
  '00000030': 'Löschanforderung gesetzt',
  '00000031': 'Löschanforderung zurückgesetzt',
  '00000032': 'Archivierungsanforderung gesetzt',
  '00000033': 'Archivierungsanforderung zurückgesetzt',
  '00000034': 'Rückladeanforderung eingereiht',
  '00000035': 'Rückladeanforderung gelöscht',
  '00000036': 'Benutzer $0 eingefügt - $0 Gruppenverbindung(en) und $0 Profil(e) kopiert',
  '00010001': 'Programm $0 endet. Querystring ist leer.',
  '00010002': 'Programm $0 endet. Ungültiger Wert für Usertoken',
  '00010003': 'Verbindung zum BetaUX Server konnte nicht hergestellt werden.',
  '00010004': 'Program $0 endet. Fehler in Routine InitProgram',
  '00010005': 'Program $0 endet. Fehler beim Selektieren des LGR/RGR/NTE RC($0-$0)',
  '00010006': 'Nicht möglich. Sie sind nicht als Kontrollbenutzer definiert.',
  '00010007': 'Nicht möglich. Dokument ist nicht online.',
  '00010008': 'Bitte geben Sie für "Auswahl der letzten" oder "Startdatum" einen Wert ein.',
  '00010009': 'Programm $0 endet. Datenbankstand inkorrekt.',
  '00010010': 'Empfänger nicht definiert oder Empfänger ist nicht vom Typ RECI.',
  '00010011': 'Nicht möglich. Dokument ist zum Löschen markiert.',
  '00010012': 'Fehler beim Öffnen der Datei $0',
  '00010013': 'Fehler beim Lesen der Datei $0',
  '00010014': 'Maximale Zeilenlänge ($0) erreicht. Datei: $0',
  '00010015': 'Programm $0 endet. Speicherallokierungsfehler',
  '00010016': 'Programm $0 endet. Ungültiger Wert für $0',
  '00010017': 'Programm $0 endet. Schlüsselwort $0 nicht gefunden',
  '00010018': 'Nicht möglich. Dokument ist online.',
  '00010019': 'Nicht möglich. Dokument ist nicht archiviert.',
  '00010020': 'Reports mit Status = C wurden nicht verändert.',
  '00010021': 'Programm $0 endet. Fehler beim Verändern des LGR/RGR RC($0-$0)',
  '00010022': 'Nicht möglich. Für alle Reports ausführen',
  '00010023': 'Keine Reportdefinition(en) gefunden',
  '00010024': 'Fehler beim Wiederholen RC($0)',
  '00010025': 'Nicht möglich. Archiv-Verweilzeit für Dokument ist abgelaufen.',
  '00010026': 'Nicht möglich. Die Datei $0 ist leer. ',
  '00010027': 'Die Ausgabeanforderung überschreitet das vordefinierte Seiten-Limit.',
  '00010028': '$0 ungültig - Unerlaubte Zeichen enthalten wie: % ? * " ; Leerzeichen',
  '00010029': '$0 - ist erforderlich',
  '00010030': 'Nicht möglich. Notizen sind nicht archiviert.',
  '00010031': 'Keine Empfängerzuordnung vorhanden',
  '00010032': 'Nicht möglich. Notizen sind nicht online. ',
  '00010033': 'Nicht möglich. Die Subpool-Order ist als Rückladereihenfolge (Order) im Archivpool definiert.',
  '00010034': 'Nicht möglich. Rückladereihenfolge (Order) ist in keinem Subpool definiert.',
  '00010035': 'Ungültiges Datum oder Datumsformat, bitte entsprechend der Datumsmaske eingeben',
  '00010036': 'Nicht möglich. Der Monitor ist bereits gestartet.',
  '00010037': 'Nicht möglich. PDF-Dokument enhält ein Encrypt Verzeichnis.',
  '00010038': 'Nicht möglich. Suchargumentwert enthält Unicode-Zeichen.',
  '00010039': 'Nicht möglich. UTF-8-Kodierung erforderlich bei Zeichennummer (Code) > 255.',
  '00010040': 'Ungültiges Zeitformat (HH, HH:MM or HH:MM:SS).',
  '00010041': 'Server "$0" inkompatibel - Erforderliche Version: $0',
  '00020001': 'Suchargument nicht definiert.',
  '00020002': 'Suchargumentwert nicht definiert.',
  '00020003': 'Suchargumentformel nicht definiert.',
  '00020008': 'Suchargumentformel ist ungültig.',
  '00030000': 'Fehlender Parameter "$0".',
  '00030001': 'Buxtwapi - Ungültiges Encoding.',
  '00050000': 'Keine Daten gefunden',
  '00050001': 'Nicht möglich. $0 ist erforderlich. ',
  '00050002': 'Nicht möglich. BTOKEN und BSEQNR sind erforderlich.',
  '00050003': 'Nicht möglich. LTOKEN und RTOKEN sind erforderlich.',
  '00050004': 'Nicht möglich. BATCH oder BUNDLE Auftrag selektiert',
  '00050005': 'Fehler in der Verarbeitung des Requeue-Auftrags. BQL-Kommando Fehler RC($0,$0)',
  '00050006': 'Fehler in der Verarbeitung des Requeue-Auftrags. BQL-Kommando Fehler RC($0)',
  '00050007': '%d von %d Aufträgen konnte(n) nicht ausgeführt werden, s. Logfile.',
  '00060000': 'Ungültige ID ($0)',
  '00060001': 'Fehler beim Lesen der Seite($0), rc(%d-%d), spoolptr(%32.32s) ',
  '00060002': 'Nicht möglich. LGRONLNE und LGRARCH sind NO.',
  '00060003': 'Nicht möglich. Fehler bei der Konvertierung in Text - RC($0)',
  '00060004': 'Fehler beim Öffnen des PageAcessBlock PAB RC($0)',
  '00060005': 'Fehler beim Öffnen des Dokument, rc(%d-%d), spoolptr(%32.32s)',
  '00070001': 'ReadTxt - Zeile > $0 Bytes',
  '00070002': 'ReadPcl - Zeile > $0 Bytes',
  '00070003': 'ReadPs  - Zeile > $0 Bytes',
  '00070004': 'ReadAfp - Zeile > $0 Bytes',
  '00070005': 'ReadAfp - Ungültiges AFP Zeichen gefunden',
  '00070006': 'ReadAfp - Datensatz > 32756 Bytes',
  '00070007': 'ReadAfp - Ungültiger Action-Code',
  '00071621': 'Ein Index für eine Zusatzdatei wurde angegeben. Fehler: Die Zusatzdatei $0 fehlt.',
  '00077013': 'Datenübertragung nicht erfolgreich',
  '00080000': 'Ihr Passwort wird in $0 Tagen ablaufen.',
  '00080001': 'Anmelden fehlgeschlagen. Benutzer ist erforderlich.',
  '00080010': 'Ihr Passwort wird innerhalb der nächsten Stunden ablaufen.',
  '00080016': 'Fehler beim Importieren ($0)',
  '00080400': 'Nicht möglich. Der Benutzer ist nicht definiert.',
  '00080401': 'Nicht möglich. Der Unix Benutzer ist nicht definiert.',
  '00080800': 'Das Passwort ist ungültig.',
  '00081201': 'Bitte neues Passwort eingeben - altes Passwort ist abgelaufen (nach Intervall).',
  '00081202': 'Bitte neues Passwort eingeben - altes Passwort ist abgelaufen.',
  '00081203': 'Bitte neues Passwort eingeben - Neueingabe durch Administrator gefordert.',
  '00081602': 'Das neue Passwort ist zu kurz.',
  '00081603': 'Das neue Passwort ist ungültig. Es wurde bereits in der Vergangenheit verwendet.',
  '00082800': 'Nicht möglich. Der Benutzer ist gesperrt.',
  '00100001': 'TABLE ist ein erforderliches Schlüsselwort für den SELECT Befehl.',
  '00100002': 'FIELDS ist ein erforderliches Schlüsselwort für den SELECT Befehl.',
  '00100003': 'VALUES ist nicht valide für den SELECT Befehl.',
  '00100004': 'Für ein UPDATE Kommando muss das Schüsselwort TABLE angegeben werden. ',
  '00100005': 'Für ein UPDATE Kommando muss das Schüsselwort FIELDS angegeben werden. ',
  '00100006': 'Für ein UPDATE Kommando muss das Schüsselwort VALUES angegeben werden. ',
  '00100007': 'Für ein UPDATE Kommando muss das Schüsselwort WHERE angegeben werden. ',
  '00100008': 'Table ist ein erforderliches Schlüsselwort für den DELETE Befehl.',
  '00100009': 'Für ein DELETE Kommando darf das Schüsselwort FIELDS nicht angegeben werden. ',
  '00100010': 'Für ein DELETE Kommando darf das Schüsselwort VALUES nicht angegeben werden. ',
  '00100011': 'Für ein DELETE Kommando muss das Schüsselwort WHERE angegeben werden. ',
  '00110001': 'Zu viele Treffer - bitte die Auswahl präzisieren',
  '00110002': 'Zu viele Dokumente - bitte die Auswahl präzisieren',
  '00160000': 'Keine Daten gefunden!',
  '00200000': 'Unerwarteter Fehler',
  '00200001': 'Unbekanntes Schlüsselwort',
  '00200002': 'Tabelle ist nicht definiert.',
  '00200003': 'Tabelle ist fehlerhaft.',
  '00200004': 'Feld "$0" ist nicht definiert.',
  '00200005': 'Falsche Werteanzahl - der Befehl enthält weniger oder mehr Werte (VALUES) als Felder (FIELDS).',
  '00200006': 'Fehler bei der Wertumrechnung für Feld "$0".',
  '00200007': 'Ungültiger oder fehlender Vergleichsoperator.',
  '00200008': 'Boolescher Operator ungültig.',
  '00200009': 'Ungültiger Wert - Feld "$0".',
  '00200010': 'Parameter FIELDS fehlt.',
  '00200011': 'WHERE Bedingung fehlt.',
  '00200012': 'Parameter VALUES fehlt',
  '00200013': 'Statement unvollständig.',
  '00200014': 'VALUE übersteigt den Bereich von FIELD.',
  '00200015': 'Falscher Schlüssel in ORDER Bedingung.',
  '00200016': 'Angegebene Datei (FILE) wurde nicht gefunden.',
  '00200017': 'Schlüsselwort "$0" fehlt.',
  '00200019': 'FILE-ID ungültig.',
  '00200020': 'FILETYPE ungültig.',
  '00200030': 'Dateiname existiert bereits.',
  '00200031': 'Datei hat nicht den Status MODEL.',
  '00200032': 'CSI ist kein Vielfaches von 4096.',
  '00200033': 'Datei ist nicht leer.',
  '00200034': 'Schlüsselwort fehlt.',
  '00200036': 'Doppeltes Schlüsselwort im BQL-Statement.',
  '00200111': 'Block-Allokationsfehler - die Datenbank ist voll.',
  '00200120': 'Datensatz nicht gelöscht - Inkonsistenz zwischen Datensatz und Schlüssel.',
  '00200121': 'Datensatz nicht aktualisiert - Inkonsistenz zwischen Datensatz und Schlüssel.',
  '00200122': 'Datensatz nicht gelesen - Inkonsistenz zwischen Datensatz und Schlüssel.',
  '00200130': 'Doppelte Tabelle.',
  '00200131': 'Tabelle nicht in Datei enthalten.',
  '00200140': 'Schlüssel nicht gefunden - Inkonsistenz zwischen Datensatz und Schlüssel.',
  '00200141': 'Datensatz nicht aktualisiert - Inkonsistenz zwischen Datensatz und Schlüssel.',
  '00200142': 'Eintrag existiert bereits.',
  '00200143': 'Datensatz nicht gelesen - Inkonsistenz zwischen Datensatz und Schlüssel.',
  '00200144': 'Datensatz nicht gelöscht - Inkonsistenz zwischen Datensatz und Schlüssel.',
  '00200145': 'Datensatz nicht eingefügt - Schlüsselblock defekt.',
  '00200146': 'Datensatz nicht eingefügt - Datensatzblock defekt.',
  '00200173': 'Nicht möglich. Index nicht gefunden.',
  '00200176': 'Nicht möglich. Globaler Index nicht gefunden.',
  '00200178': 'Nicht möglich. Parameter ARGUMENT fehlt.',
  '00200192': 'Nicht möglich. Indextoken nicht definiert.',
  '00200193': 'Nicht möglich. Ungültiger Wert (Indextyp DATE).',
  '00200195': 'Nicht möglich. Ungültiger Wert (Indextyp NUMERIC).',
  '00200196': 'Nicht möglich. Ungültiger Wert (Indextyp DECIMAL).',
  '00200198': 'Nicht möglich. Ungültiger Wert (Indextyp RNUM)',
  '00200212': 'Die Referenz der Liste/Report in der SPOOL ist veraltet oder ungültig.',
  '00200213': 'Die Seite kann nicht angezeigt werden, die Seitennummer ist ungültig.',
  '00200216': 'Fehler im SPOOL-Index, FILEID = 0',
  '00200217': 'Fehler im SPOOL-Index, RBA = 0',
  '00200218': 'Fehler im SPOOL-Index, SLOT = 0',
  '00200219': 'Fehler im SPOOL-Index, ungültig SLOT-Nummer',
  '00200625': 'Die Seite kann nicht angezeigt werden, die Seitennummer ist ungültig.',
  '00200633': 'Archiv-Server nicht verfügbar',
  '00200902': 'Allgemeiner Fehler beim Drucken.',
  '00203003': 'Ordner - nicht definiert',
  '00203004': 'Vorgänger nicht definiert oder nicht vom Typ Adresse',
  '00203005': 'Ausgabeformat - nicht definiert',
  '00203006': 'Nachbearbeitungsnotiz - nicht definiert',
  '00203008': 'Vorgänger für einen Empfänger vom Typ ALIAS ist nicht definiert.',
  '00203009': 'Vorgänger ist nicht vom Typ Empfänger.',
  '00203010': 'Empfänger bereits als ALIAS definiert.',
  '00203011': 'Nicht möglich. Empfänger existiert bereits als Type RECI oder ADDR.',
  '00203012': 'Empfänger existiert bereits als TYPE = ADDR/RECI.',
  '00203013': 'Vorgänger hat bereits einen Vorgänger.',
  '00203014': 'Empfänger - nicht definiert',
  '00203015': 'Liste - nicht definiert',
  '00203016': 'Report - nicht definiert',
  '00203017': 'Ausgabekanal - nicht definiert',
  '00203018': 'Keine Einträge stimmen mit den Auswahlkriterien überein.',
  '00203019': 'Nicht möglich. Es sind noch "Ordner <-> Dokument" Definitionen vorhanden.',
  '00203020': 'Bei "automatisch archivieren/Notizen archivieren" ist auch ein Wert für  "Verweilzeit" anzugeben.  ',
  '00203021': 'Bei "automatisch archivieren/Notizen archivieren" ist auch Archiv-"Medium" anzugeben. ',
  '00203022': 'Zum Archivieren muss die Archiv-"Verweilzeit" > 0 sein und ein Archiv-"Medium" angegeben werden.',
  '00203023': '"Auf Seite" > 0 erforderlich, wenn TYP = PAGE ist',
  '00203024': 'Suchargument- nicht definiert',
  '00203025': 'Nicht möglich, es sind noch "Empfänger <-> Dokument" Definitionen vorhanden.',
  '00203026': 'Nicht möglich. Diese Nachbearbeitungsnotiz wird in einem oder mehren Recipienten Definitionen verwendet.',
  '00203027': 'Nicht möglich. Diese Nachbearbeitungsnotiz wird in einem oder mehreren Dokument Definitionen verwendet.',
  '00203028': 'Nicht möglich. Nachbereitungsnotiz wird in einer/mehreren LRT Definitionen verwendet.',
  '00203029': 'Nicht möglich. Nachbereitungsnotiz wird in einer/mehreren "Empfänger <-> Dokument" Definitionen verwendet.',
  '00203030': 'Nicht möglich. Ausgabekanal wird in einer/mehreren Empfänger Definitionen verwendet.',
  '00203031': 'Nicht möglich. Ausgabekanal wird in einer/mehreren Dokument Definitionen verwendet.',
  '00203032': 'Nicht möglich. Ausgabekanal wird in einer/mehreren LRT Definitionen verwendet.',
  '00203033': 'Nicht möglich. Ausgabekanal wird in einer/mehreren "Empfänger <-> Dokument" Definitionen verwendet.',
  '00203034': 'Suchmodus- Eingabe erforderlich für eine Reportdefinition',
  '00203035': 'Es ist nicht möglich die Standarddefinition zu löschen.',
  '00203036': 'Archiv-Pool - Eingabe erforderlich ',
  '00203037': 'Archiv-Medium - Eingabe erforderlich ',
  '00203038': 'Verweilzeit - Eingabe erforderlich ',
  '00203039': 'Rückladereihenfolge (Order) - Eingabe erforderlich ',
  '00203040': 'Archiv-Pool mit gleichem Besitzer existiert bereits. ',
  '00203041': 'Archiv-Pool mit gleichem Besitzer, Archiv-Medium und Verweilzeit existiert bereits. ',
  '00203042': 'Nicht möglich. Verweilzeit Periode > 30000',
  '00203044': 'Dateigröße - Eingabe erforderlich ',
  '00203045': 'Maximum der Dateigröße ist 500. ',
  '00203046': 'Verweilzeit - Eingabe erforderlich für Subpool',
  '00203047': 'Rückladereihenfolge (Order)- Eingabe erforderlich für Subpool ',
  '00203048': 'Archiv-Medium- Eingabe erforderlich für Subpool ',
  '00203049': 'Dateiname oder Maske - Eingabe erforderlich ',
  '00203050': 'Nicht möglich. Ausgabeformat wird in einer/mehreren Empfängerdefinitionen verwendet.',
  '00203051': 'Nicht möglich. Ausgabeformat wird in einer/mehreren Dokumentdefinitionen verwendet.',
  '00203052': 'Indexname - Eingabe erforderlich',
  '00203053': 'Nicht möglich. Ausgabeformat wird in einer/mehreren "Empfänger <-> Dokument" Definitionen verwendet.',
  '00203054': 'Nicht möglich. Ausgabeformat wird in einer/mehreren LRT Definitionen verwendet.',
  '00203055': 'Nicht möglich. Empfänger wird in einer/mehreren Empfängerdefinitionen als Vorgänger verwendet.',
  '00203056': 'Nicht möglich. Indexname wird in einer/mehreren Indexdefinitionen verwendet.',
  '00203057': 'Index - nicht definiert in der Indexnamen Tabelle',
  '00203058': 'Titel - Eingabe erforderlich',
  '00203059': 'Suchmuster - Eingabe erforderlich',
  '00203060': 'Datumsformat - Eingabe erforderlich',
  '00203062': 'Nicht möglich. Es sind noch "Index <-> Dokument" Definitionen vorhanden.',
  '00203063': 'Ergebnistabelle - Eingabe erforderlich',
  '00203064': 'Nicht möglich. Ergebnistabelle wird in einer/mehreren Auswahldialogdefinitionen verwendet. ',
  '00203065': 'Auswahldialog - Eingabe erforderlich',
  '00203066': 'Ergebnistabelle - nicht definiert',
  '00203067': 'Knotenname - Eingabe erforderlich',
  '00203068': 'Vorgängerknoten - nicht definiert ',
  '00203069': 'Nicht möglich. Knoten wird in einer/mehreren Knotendefinitionen als Vorgängerknoten verwendet.',
  '00203070': 'Bezeichner - Eingabe erforderlich',
  '00203071': 'Vorgängerknoten ist kein Knoten.',
  '00203072': 'Vorgängerknoten - Eingabe erforderlich bei Typ DGI',
  '00203073': 'Nicht möglich. Es sind noch "Gruppe <-> Dokument" Definitionen vorhanden.',
  '00203074': 'Gruppe ist nicht definiert.',
  '00203075': 'Nicht möglich. Es existieren ein/mehre Nachfolgeknoten',
  '00203076': 'Bezeichner - Eingabe erforderlich',
  '00203077': 'Nicht möglich. Es sind noch "Knoten <-> Dokument" Definitionen vorhanden.',
  '00203079': 'von Zeilenwert > bis Zeilenwert',
  '00203080': 'Nicht möglich, es existieren noch Reportdefinitionen.',
  '00203084': 'Nicht möglich, es sind noch "Empfänger <-> Dokument" Definitionen vorhanden.',
  '00203085': 'SMODE für LIST-Definition nicht zulässig.',
  '00203086': 'von Spaltenwert > bis Spaltenwert',
  '00203088': 'Port - Eingabe erforderlich',
  '00203089': 'IP-Adresse - Eingabe erforderlich',
  '00203090': 'Ausgabebefehl - Eingabe erforderlich',
  '00203101': 'Ausgabekanal kann nicht gelöscht werden, weil er als Ersatz-Ausgabekanal definiert ist.',
  '00203102': 'Ersatz-Ausgabekanal muss sich vom Original-Ausgabekanal unterscheiden.',
  '00203103': 'Kein Ersatz-Ausgabekanal angegeben, aber aktiviert ',
  '00203104': 'Dieser Ersatz-Ausgabekanal ist nicht definiert.',
  '00203105': 'Ersatz-Ausgabekanal muss sich vom Original-Ausgabekanal unterscheiden.',
  '00203106': 'Kein Ersatz-Ausgabekanal angegeben, aber aktiviert ',
  '00203107': 'Dieser Ersatz-Ausgabekanal ist nicht definiert.',
  '00203119': 'Applikation - Eingabe erforderlich ',
  '00203126': 'Bei OVDCR = YES muss eine DCR angegeben werden.',
  '00203127': 'Bei OVPCR = YES muss eine PCR angegeben werden.',
  '00203128': 'Bitte "Banner Text 1" angeben, wenn "Deck- und Endeblätter drucken" aktiviert ist.',
  '00203129': 'Für Status = CHECK ist die Kontrollbenutzer ID erforderlich.',
  '00203130': 'Form - Eingabe erforderlich ',
  '00203131': 'Empfänger - Eingabe erforderlich ',
  '00203132': 'Ausgabeformat - Eingabe erforderlich ',
  '00203133': 'Ausgabekanal und Typ - Eingabe erforderlich ',
  '00203134': 'Nachbereitungsnotiz - Eingabe erforderlich ',
  '00203136': 'Suchargument - Eingabe erforderlich ',
  '00203137': 'Empfängertyp - Eingabe erforderlich',
  '00203138': 'Ordner - Eingabe erforderlich',
  '00203139': 'Nicht möglich, es sind noch "Ordner <-> Dokumente" Definitionen vorhanden.',
  '00203171': 'Angaben für Suchargument und Länge sind für das erste Burst-Fenster erforderlich.',
  '00203172': '"Gültig von" und "Gültig bis" - Eingabe erforderlich bei Report Definition',
  '00203173': 'Bei Zuordnung = DEFAULT muss der Name des Dyn. Report leer sein.',
  '00203174': 'Dyn. Report- Eingabe erforderlich bei Zuordnungungleich DEFAULT',
  '00203175': 'Angaben für Zeile, Spalte und Länge sind für das erste Burst-Fenster erforderlich.',
  '00203176': 'Bei Suchmodus ABSOLUTE oder COND/BURST* ist die Angabe der Suchformel erforderlich.',
  '00203177': 'Bei Suchmodus START/STOP oder LIMIT/BURST ist die Angabe der Start- und Stoppsuchformel erforderlich.',
  '00203178': 'Dyn. Report muss für eine Liste leer sein.',
  '00203179': 'Zuordnung DEFAULT oder CONVERT ist nur für BURST Reports erlaubt.',
  '00203180': 'Dyn. Report - Eingabe erforderlich bei Zuordnung= CONVERT',
  '00203181': 'Bei Zuordnung= STATIC muss der Name des Dyn. Report ein Empfänger sein.',
  '00203189': 'Dieser Suchargumentwert existiert bereits.',
  '00203190': 'Nicht möglich. Dieses Dokoment ist nicht archiviert',
  '00203191': 'Nicht möglich. Keine gültige IDX-Lizenz.',
  '00203192': 'Nicht möglich. Keine gültige BDL-Lizenz.',
  '00203193': 'Nicht möglich. Das Dokument ist online.',
  '00203401': 'Nicht autorisiert für diese Aktion.',
  '00203450': 'Benutzer - Eingabe erforderlich ',
  '00203451': 'Gruppe - Eingabe erforderlich ',
  '00203452': 'Benutzer existiert bereits als Gruppe.',
  '00203453': 'Gruppe existiert bereits als Benutzer.',
  '00203454': 'Nicht möglich. Es bestehen noch "Benutzer <-> Gruppe" Verbindungen.',
  '00203455': 'Nicht möglich. Es bestehen noch "Benutzer <-> Profil" Verbindungen.',
  '00203456': 'Nicht möglich. Es bestehen noch "Gruppe <-> Profil" Verbindungen.',
  '00203457': 'Profil - Eingabe erforderlich ',
  '00203458': 'Nicht möglich. Kein Profil für "universal access(UACC)" definiert.',
  '00203459': 'Nicht möglich. Mit dem Profil sind noch Benutzer oder Gruppe(n) verbunden.',
  '00203460': 'Unbekannter Benutzer oder Gruppe ',
  '00203461': 'UNIX Benutzer - Eingabe erforderlich ',
  '00203462': 'Benutzer existiert nicht!',
  '00203463': 'Gruppe nicht definiert',
  '00203464': 'Nicht möglich. Gruppe enthält einen oder mehrere Benutzer.',
  '00203465': 'Ungültiges Zeichen (gültig sind A-Z,0-9 und #,$,§)',
  '00203466': 'Ungültige Profilmaske!',
  '00203467': 'Nicht möglich. Keine gültige Indexdefinition vorhanden.',
  '00203468': 'Nicht möglich. Keine Empfängerzuordnung vorhanden.',
  '00203469': 'Nicht möglich. Keine gültige Reportdefinition vorhanden.',
  '00203470': 'Nicht möglich. Notizen sind nicht online. ',
  '00203471': 'Nicht möglich. Das Löschen von Notizen ist nicht erlaubt. ',
  '00203472': 'Nicht möglich. Schlüsselwort PDFPROC_TMPDIR (LST.TXT) nicht vorhanden. ',
  '00203473': 'Fehler bei der Konvertierung in Text (PDF2TXT,PS2TXT) oder bei der Seitenextraktion (PDF2PDF).',
  '00203474': 'Seitenextraktion nicht möglich, PDF Dokument enthält ein Encrypt Verzeichnis.',
  '00203475': 'Nicht möglich. Textversion nicht vorhanden.',
  '00203476': 'Nicht möglich. Requeue oder Reprint Abforderung überschreitet das Limit.',
  '00203477': 'Nicht möglich. TCP/IP Verbindung zum Ausgabe-Server kann nicht hergestellt werden.',
  '00203478': 'Nicht möglich. Protokoll ist nicht IPP.',
  '00203479': 'Nicht möglich. Der Job wurde bereits gelöscht.',
  '00203480': 'Nicht möglich. Der Job ist unvollständig (Status incoming).',
  '00203481': 'Nicht möglich. Aktive Jobs müssen vorher gestoppt werden. ',
  '00203482': 'Nicht möglich. Der Job ist nicht aktiv.',
  '00203483': 'Nicht möglich. Kein Ersatz-Ausgabekanal definiert oder er ist inaktiv.',
  '00203484': 'Die Ausgabeanforderung überschreitet Ihr Seiten-Limit',
  '00203485': 'Nicht möglich. Empfänger muss vom Typ RECI sein',
  '00203486': 'Nicht möglich, Keine Zusatzdatei vorhanden',
  '00203487': 'Nicht möglich, Tabelle der Notizen ist nicht online.',
  '00203488': 'Nicht möglich, Dokument ist nicht online und nicht archiviert.',
  '00203489': 'Sie sind nicht als Kontrollbenutzer definiert.',
  '00203490': 'Nicht möglich. Dokument ist zum Löschen markiert.',
  '00203491': 'Nicht möglich. Suchargument wird in einer/mehreren Reportdefinitionen verwendet.',
  '00203492': 'Länge des zu suchenden Texts ist größer als die Spaltenbreite.',
  '00203493': 'Bitte benutzen Sie x"22" anstatt " im Suchtext.',
  '00203494': 'Länge des zu suchenden Texts ist größer als 128 Zeichen.',
  '00203495': 'Nicht möglich. TCP/IP Verbindung zum LPD-Server kann nicht hergestellt werden.',
  '00203496': 'Error IPP option',
  '00203497': 'Auftrag im Drucker nicht gefunden.',
  '00203498': 'Nicht möglich. Document Archiv ist abgelaufen.',
  '00203499': 'Benutzersitzung ist abgelaufen!',
  '00203500': 'Nicht möglich. TCP/IP Verbindung zum Archiv-Server kann nicht hergestellt werden.',
  '00240000': 'BQL Verbindungsfehler RC(24) - Übertragungsfehler.',
  '00320000': 'BQL-Befehl abgebrochen RC(32).',
  '00360000': 'BQL-Befehl Fehler',
  '00420001': 'Programm $0 liefert Fehlercode $1',
  '00420002': 'Keine Ausgabe des Programms $0',
  '00420003': 'Fehlender Pflichtparameter $0',
  '00420004': 'Programm $0 löst Signal $1 aus',
  '00420005': 'Ungültiger Wert für Parameter $0: $1',
  '00420006': 'Validierungsfehler: $0',
  '00420007': 'Parameter vom falschen Typ $0: $1',
  '00420008': 'Wert des Parameters $0 enthält nicht unterstützte Unicode-Zeichen: $1',
  '00420009': 'Falscher Wert für Parameter $0: $1',
  '00420010': 'Datei zu groß.',
  '00600004': 'Anmelden fehlgeschlagen. Beta UX Benutzer-ID, die von der Sicherheitsroutine zurückgeliefert wurde, existiert nicht.',
  '00600008': 'Anmelden fehlgeschlagen. Sicherheitsroutine oder Protokoll nicht gefunden.',
  '00600256': 'Anmelden fehlgeschlagen. Keine gültige SLE Lizenz.',
  '00640004': 'Externes Anme fehlgeschlagen. Benutzer nicht gefunden.',
  '00640008': 'Externes Anmelden fehlgeschlagen. Falsches Passwort.',
  '00640016': 'Externes Anmelden fehlgeschlagen. Problem mit dem Authentifizierungsbackend.',
  '00640032': 'Externes Anmelden fehlgeschlagen. Ungültige Konfiguration der Anmelderoutine.',
  '99990000': 'PMS999900E Ungültiger Befehl RC(9999).',
  'archive_pool.please_enter_id': 'Bitte geben Sie eine Archivpool-ID ein.',
  'assignment.count_fail_created': '$0 Zuordnungen beim Erstellen fehlgeschlagen',
  'assignment.count_fail_deleted': '$0 Zuordnungen beim Löschen fehlgeschlagen',
  'assignment.count_successfully_created': '$0 Zuordnungen erstellt',
  'assignment.count_successfully_deleted': '$0 Zuordnungen gelöscht',
  'assignment.create': 'erstellen.',
  'assignment.create_assignment_error': 'Fehler beim Erstellen der Zuordnung ($0)',
  'assignment.create_assignment_success': 'Zuordnung erstellt ($0)',
  'assignment.folder_document.copy_folder_document': 'Ordner <-> Dokument Zuordnung kopieren',
  'assignment.folder_document.create_error': 'Fehler beim Erstellen Zuordnung ($0)',
  'assignment.folder_document.create_folder_document': 'Ordner <-> Dokument Zuordnung anlegen',
  'assignment.folder_document.create_success': 'Zuordnung erstellt ($0)',
  'assignment.folder_document.delete_assignment': 'Ordner <-> Dokument Zuordnung löschen',
  'assignment.folder_document.delete_error': 'Fehler beim Löschen der Zuordnung ($0)',
  'assignment.folder_document.delete_question': 'Wollen Sie die Ordner <-> Dokument Zuordnung wirklich löschen?',
  'assignment.folder_document.delete_success': 'Zuordnung gelöscht ($0$1$2$3$4)',
  'assignment.folder_document.dynamic_report': 'Dynamischer Report',
  'assignment.folder_document.folderid': 'Ordner-ID',
  'assignment.folder_document.get_folder_document_assignment_error': 'Fehler beim Laden der Ordner Dokument Zuordnung "$0"',
  'assignment.folder_document.loading_error': 'Fehler beim Laden der Ordner-Dokument Zuordngungen ($0)',
  'assignment.folder_document.modify_error': 'Fehler beim Bearbeiten Zuordnung ($0)',
  'assignment.folder_document.modify_success': 'Zuordnung bearbeitet ($0)',
  'assignment.folder_document.please_enter_form': 'Bitte geben Sie eine Form ein.',
  'assignment.folder_document.please_enter_id': 'Bitte geben Sie eine Ordner-ID ein.',
  'assignment.folder_document_assignment': 'Ordner <-> Dokument',
  'assignment.folder_document_delete_question_1': 'Wollen Sie die Ordner ',
  'assignment.folder_document_delete_question_2': ' Dokument Zuordnung wirklich löschen?',
  'assignment.index_document.delete_error': 'Fehler beim Löschen der Zurodnung ($0)',
  'assignment.index_document.delete_index': 'Index <-> Dokument Zuordnung löschen',
  'assignment.index_document.delete_question': 'Wollen Sie die Index <-> Dokument Zuordnung wirklich löschen?',
  'assignment.index_document.delete_success': 'Zuordnung gelöscht ($0)',
  'assignment.index_document.get_assignment_error': 'Fehler beim Laden der Index Dokument Zuordnung "$0"',
  'assignment.index_document.loading_error': 'Fehler beim Laden der Index-Dokument Zuordngungen ($0)',
  'assignment.index_document.modify_error': 'Fehler beim Modifizieren der Beziehung ($0)',
  'assignment.index_document.modify_success': 'Zuordnung bearbeitet ($0)',
  'assignment.index_document_acif_index': 'ACIF-Index',
  'assignment.index_document_afpds_nop_record': 'AFPDS NOP Eintrag',
  'assignment.index_document_assignment': 'Index <-> Dokument',
  'assignment.index_document_create_title': 'Index <-> Dokument Zuordnung anlegen',
  'assignment.index_document_debug_mode': 'Testmodus',
  'assignment.index_document_delete_question_1': 'Wollen Sie die Index ',
  'assignment.index_document_delete_question_2': ' Dokument Zuordnung wirklich löschen?',
  'assignment.index_document_determine_index_values_from': 'Indexwerte ermitteln aus',
  'assignment.index_document_document_text_browser': 'Dokumenten-Textbrowser',
  'assignment.index_document_document_variable': 'Dokumentvariable',
  'assignment.index_document_extract_index_value': 'Indexwert extrahieren',
  'assignment.index_document_extract_index_values_only_from_pages': 'Indexwerte nur aus Seiten extrahieren',
  'assignment.index_document_format_index_value': 'Indexwert formatieren',
  'assignment.index_document_ignore_index_value_with_wrong_data_format': 'Indexwerte mit falschem Datenformat ignorieren',
  'assignment.index_document_ignore_index_values_from_pages': 'Indexwerte aus Seiten ignorieren',
  'assignment.index_document_index_values_are_required': 'Indexwerte sind erforderlich',
  'assignment.index_document_index_values_from': 'Indexwerte aus',
  'assignment.index_document_maximally_up_to_end_tag': 'Maximal bis Endekennzeichen',
  'assignment.index_document_no': 'Keine',
  'assignment.index_document_pjl_statement': '@PJL-Anweisung',
  'assignment.index_document_rule_example_label': 'Beispielregel:',
  'assignment.index_document_rule_example_substitutes1': 'Ersetzt "I1234567890ABC" durch "567890-1234-I"',
  'assignment.index_document_rule_example_substitutes2': 'Ersetzt "K 23456789 ABC" durch "567890-#234-K"',
  'assignment.index_document_rule_example_value': '&VAL(5,6,0)-&VAL(1,4,#)-&VAL(0,1)',
  'assignment.index_document_sample_of_a_search_pattern_label': 'Beispiel eines Suchmusters:',
  'assignment.index_document_sample_of_a_search_pattern_value': '\'ABC\'P\'99999\'',
  'assignment.index_document_sample_of_a_time_string_label': 'Beispiel für einen Zeit-String:',
  'assignment.index_document_sample_of_a_time_string_value': '%a %b %d %H:%M:%s GMT %Y',
  'assignment.index_document_sample_of_lcs_time1': 'LC_TIME: en_US - Datum: Fri Aug 13 10:22:56 GMT',
  'assignment.index_document_sample_of_lcs_time2': 'LC_TIME: de_DE - Datum: Fr Aug 13 10:22:56 GMT',
  'assignment.index_document_sample_of_lcs_time3': 'LC_TIME: fr_FR - Datum: ven aoû 13 10:22:56 GMT',
  'assignment.index_document_samples_of_lc_time': 'Beispiele für LC_TIME:',
  'assignment.index_document_search_pattern_*': '* - Beliebiges Zeichen',
  'assignment.index_document_search_pattern_a': 'A - Klein-/Großbuchstaben',
  'assignment.index_document_search_pattern_b': 'B - Leerzeichen',
  'assignment.index_document_search_pattern_l': 'L - Kleinbuchstaben',
  'assignment.index_document_search_pattern_q': '9 - Ziffer',
  'assignment.index_document_search_pattern_s': 'S - Sonderzeichen',
  'assignment.index_document_search_pattern_u': 'U - Großbuchstaben',
  'assignment.index_document_search_pattern_x': 'X - Hexadezimal',
  'assignment.index_document_search_pattern_z': 'Z - Ziffer oder Buchstabe',
  'assignment.index_document_security_check': 'Sicherheitsüberprüfung',
  'assignment.index_document_side_file': 'Zusatzdatei',
  'assignment.index_document_strings_beginning_with_abc': 'Zeichenketten beginnend mit "ABC" gefolgt von 5 Ziffern.',
  'assignment.index_document_substitude_values': 'Auszutauschende Werte',
  'assignment.index_document_substitute_characters_before_saving_hits': 'Zeichen vor dem Speichern austauschen',
  'assignment.index_document_variables': 'Variablen:',
  'assignment.index_document_variables1': '%a - Kurzer Wochentagsname (z.B. So)',
  'assignment.index_document_variables2': '%A - Voller Wochentagsname (z.B. Sonntag)',
  'assignment.index_document_variables3': '%b - Kurzer Monatsname (z.B. Jan)',
  'assignment.index_document_variables4': '%B - Voller Monatsname (z.B. Januar)',
  'assignment.index_document_variables5': '%d - Tag (z.B. 01)',
  'assignment.index_document_variables6': '%m - Monat (01..12)',
  'assignment.index_document_variables7': '%y - Zweistellige Jahresangabe (00..99)',
  'assignment.index_document_variables8': '%Y - Jahr',
  'assignment.index_document_with_values': 'Ersetzungswert',
  'assignment.index_log_delete_question_2': ' Log Zuordnung wirklich löschen?',
  'assignment.no': 'Kein',
  'assignment.no_alt': 'Keine',
  'assignment.no_match': 'entsprechen Ihren Suchkriterien.',
  'assignment.no_match_single': 'entspricht Ihren Suchkriterien.',
  'assignment.node_document.create_error': 'Fehler beim Erstellen der Zuordnung "$0" ($1)',
  'assignment.node_document.create_success': 'Zuordnung erstellt ($0)',
  'assignment.node_document.delete_assignment_failed': 'Fehler beim löschen der Knoten-Dokument Zuordnung "$0" ($1)',
  'assignment.node_document.delete_assignment_success': 'Zuordnung gelöscht ($0)',
  'assignment.node_document.delete_error': 'Fehler beim Löschen der Zuordnung "$0" ($1)',
  'assignment.node_document.loading_error': 'Fehler beim Laden der Knoten-Dokument Zuordngungen ($0)',
  'assignment.node_document.modify_error': 'Fehler beim Bearbeiten Zuordnung "$0" ($1)',
  'assignment.node_document.modify_success': 'Zuordnung bearbeitet ($0)',
  'assignment.node_document_assignment.loading_error': 'Fehler beim Laden der Knoten-Dokument Zuordnung',
  'assignment.node_document_delete_question_1': 'Wollen Sie die Knoten ',
  'assignment.node_document_delete_question_2': ' Dokument Zuordnung wirklich löschen?',
  'assignment.recipient_document.delete_assignment_error': 'Fehler beim Löschen der Zuordnung "$0" ($1)',
  'assignment.recipient_document.delete_assignment_success': 'Zuordnung gelöscht ($0)',
  'assignment.recipient_document.delete_question': 'Wollen Sie die Empfänger <-> Dokument Zuordnung wirklich löschen?',
  'assignment.recipient_document.delete_title': 'Empfänger <-> Dokument Zuordnung löschen',
  'assignment.recipient_document_assignment': 'Empfänger <-> Dokument',
  'assignment.recipient_document_assignment.loading_error': 'Fehler beim Laden der Empfänger-Dokument Zuordnung "$0" ($1)',
  'assignment.recipient_document_assignments.loading_error': 'Fehler beim Laden der Empfänger-Dokument Zuordnungen ($0)',
  'assignment.recipient_document_convert': 'Umwandeln',
  'assignment.recipient_document_copy': 'Empfänger <-> Dokument Zuordnung kopieren',
  'assignment.recipient_document_create': 'Empfänger <-> Dokument Zuordnung anlegen',
  'assignment.recipient_document_default': 'Standard',
  'assignment.recipient_document_delete_question_1': 'Wollen Sie die Empfänger ',
  'assignment.recipient_document_delete_question_2': ' Dokument Zuordnung wirklich löschen?',
  'assignment.recipient_document_static': 'Statisch',
  'assignment.you_can': 'Sie können eine neue',
  'database.add_to_favorite': 'Als Favorit speichern',
  'database.allocated_blocks': 'Zugewiesene Blöcke',
  'database.archive': 'Archive',
  'database.archive_cleanup': 'Archive cleanup',
  'database.archive_notes': 'Archive notes',
  'database.block_size': 'Blockgröße',
  'database.bqlquery_error': 'Fehler beim Ausführen des BQL Kommandos ($0)',
  'database.create_database_file': 'Datenbankdatei erstellen',
  'database.create_file_error': 'Fehler beim Erstellen der Datenbankdatei "$0" ($1)',
  'database.create_file_success': 'Datenbankdatei mit dem Dateiname "$0" erstellt',
  'database.daily': 'Daily maintenance',
  'database.delete_database_file': 'Datenbankdatei löschen',
  'database.delete_file_error': 'Fehler beim Löschen der Datenbankdatei "$0" ($1)',
  'database.delete_file_success': 'Datenbankdatei "$0" gelöscht',
  'database.disable_delete_status': 'Status muss "Leer" oder "Modell" sein',
  'database.disable_delete_type': 'Nur Index-/Spooldateien können gelöscht werden',
  'database.empty': 'Leer',
  'database.execute': 'Ausführen',
  'database.externalcmd_result': 'Externer Befehl - Ergebnis',
  'database.favorites': 'Favoriten',
  'database.fields_error': 'Fehler beim Laden der Datenbankfelder ($0)',
  'database.file': 'Datenbank Datei',
  'database.file_id': 'Datei-ID',
  'database.file_size': 'Dateigröße',
  'database.file_status_cls': 'Geschlossen',
  'database.file_status_emp': 'Leer',
  'database.file_status_err': 'Fehler',
  'database.file_status_fmt': 'Formatiert',
  'database.file_status_ful': 'Voll',
  'database.file_status_mod': 'Modell',
  'database.file_status_onl': 'Online',
  'database.file_status_opn': 'Offen',
  'database.fileid': 'Datei-ID',
  'database.filename': 'Dateiname',
  'database.filename_required': 'Bitte geben sie einen Dateinamen ein',
  'database.files_error': 'Fehler beim Laden der Datenbankdateien ($0)',
  'database.free': 'Frei',
  'database.free_blocks': 'Freie Blöcke',
  'database.function': 'Funktion',
  'database.get_file_error': 'Fehler beim Laden der Datenbankdatei "$0" ($1)',
  'database.index_databases': 'Index-Datenbanken (summiert)',
  'database.index_model': 'Index Modell',
  'database.keyfields_error': 'Fehler beim Laden der Datenbank Schlüsselfeld ($0)',
  'database.last_executed': 'Zuletzt ausgeführt',
  'database.maintenance': 'Wartung',
  'database.maintenance_result': 'Wartungsergebnis',
  'database.message_log_cleanup': 'Message log cleanup',
  'database.messagelog': 'Meldungen',
  'database.model': 'Modell',
  'database.notes_cleanup': 'Notes cleanup',
  'database.online_cleanup': 'Online cleanup',
  'database.output_queue_cleanup': 'Output queue cleanup',
  'database.prefix': 'Präfix',
  'database.process_id': 'Prozess-ID',
  'database.query': 'Abfrage',
  'database.query_already_saved': 'Abfrage ist bereits als Favorit gespeichert',
  'database.query_no_favorites': 'Keine Favoriten gespeichert',
  'database.query_success': 'BQL-Query ausgeführt',
  'database.query_to_uppercase': 'Abfrage in Großbuchstaben umwandeln',
  'database.question_delete_database_file': 'Möchten Sie die Datenbankdatei wirklich löschen?',
  'database.read_only': 'Read-only',
  'database.reads': 'Gelesen',
  'database.reload': 'Reload',
  'database.result': 'Ergebnis',
  'database.result.message': 'Meldung',
  'database.result.message_id': 'Meldungs-ID',
  'database.result.time': 'Zeit',
  'database.return_code': 'Rückgabewert',
  'database.shortname': 'Kurzname',
  'database.size': 'Größe',
  'database.size_mb': 'Größe (MB)',
  'database.spool_databases': 'Spool-Datenbanken (summiert)',
  'database.spool_model': 'Spool Modell',
  'database.status': 'Status',
  'database.status_changed_to_read_only_success': 'Status von "nur lesen" nach "lesen-schreiben" geändert ($0)',
  'database.status_changed_to_read_write_success': 'Status von "lesen-schreiben" nach "nur lesen" geändert ($0)',
  'database.status_donutchart_free': 'Frei',
  'database.status_donutchart_used': 'Benutzt',
  'database.status_set_read_only': 'Nur lesen',
  'database.status_set_read_write': 'Lesen und schreiben',
  'database.table_id': 'Tabellen-ID',
  'database.table_information': 'Datenbanktabellen Informationen',
  'database.table_located_in': 'Enthalten in',
  'database.table_name': 'Tabellenname',
  'database.tablefields_error': 'Fehler beim Laden der Datenbank Tabellenfelder ($0)',
  'database.tablekeys_error': 'Fehler beim Laden der Datenbank Tabellenschlüssel ($0)',
  'database.tables': 'Tabellen',
  'database.tables_error': 'Fehler beim Laden der Datenbanktabellen ($0)',
  'database.tables_field': 'Feld',
  'database.tables_field_id': 'Feld-ID',
  'database.tables_field_name': 'Feldname',
  'database.tables_fields': 'Felder',
  'database.tables_fields_conv': 'Konv.',
  'database.tables_fields_external': 'Extern',
  'database.tables_fields_external_length': 'Externe Länge',
  'database.tables_fields_external_type': 'Externer Typ',
  'database.tables_fields_external_value': 'Externer Wert',
  'database.tables_fields_internal': 'Intern',
  'database.tables_fields_internal_length': 'Interne Länge',
  'database.tables_fields_internal_type': 'Interner Typ',
  'database.tables_fields_internal_value': 'Interner Wert',
  'database.tables_fields_position': 'Position',
  'database.tables_key_id': 'Schlüssel-ID',
  'database.tables_key_name': 'Schlüsselname',
  'database.tables_keys': 'Schlüssel',
  'database.tables_lang': 'Spr.',
  'database.tables_long_name': 'Langer Name',
  'database.tables_sub_field': 'Sub-Feld',
  'database.type': 'Typ',
  'database.update_file_error': 'Fehler beim Bearbeiten der Datenbankdatei "$0" ($1)',
  'database.update_file_success': 'Datenbankdatei "$0" wurde bearbeitet',
  'database.used': 'Benutzt',
  'database.writes': 'Geschrieben',
  'definition.activate_alternative_after_retry_period': 'Ersatz automatisch nach Retry-Zeitraum aktivieren',
  'definition.activate_pjl_status_readback_ustatus': 'Aktiviere PJL-Statusrückgabe (UStatus)',
  'definition.activate_snmp_setting': 'Aktiviere SNMP-Einstellungen',
  'definition.add_additional_field': 'Zusätzliches Feld hinzufügen',
  'definition.address': 'Adresse',
  'definition.allow_banner_between_document_copies': 'Deckblatt zwischen Dokumentenkopien zulassen',
  'definition.alternative_activ': 'Ersatz aktiv',
  'definition.alternative_id': 'Ersatz-ID',
  'definition.application': 'Applikation',
  'definition.application_attributes': 'Applikation - Eigenschaften',
  'definition.archive_date': 'Archivierungsdatum',
  'definition.archive_file': 'Archivdatei',
  'definition.archive_files_error': 'Fehler beim Laden der Archivdateien ($0)',
  'definition.archive_pool': 'Archivpool',
  'definition.archive_pool_add_subpool': 'Subpool hinzufügen',
  'definition.archive_pool_archive_file_management': 'Archivdateiverwaltung',
  'definition.archive_pool_attached_subpools': 'Anhängende Subpools',
  'definition.archive_pool_create_initial_subpool': 'Initialen Subpool mit Archivpool erstellen',
  'definition.archive_pool_delete': 'Archivpool löschen',
  'definition.archive_pool_delete_question': 'Wollen Sie diesen Archivpool wirklich löschen?',
  'definition.archive_pool_delete_script_command': 'Löschskript/-befehl',
  'definition.archive_pool_error': 'Fehler beim Laden der Archivpooldefinition "($0)" ($1)',
  'definition.archive_pool_file_managed_by': 'Datei verwaltet durch',
  'definition.archive_pool_file_name_mask': 'Dateiname oder -maske',
  'definition.archive_pool_file_size': 'Dateigröße (4MB Blöcke)',
  'definition.archive_pool_id': 'Archivpool-ID',
  'definition.archive_pool_order_for_reload': 'Rückladereihenfolge',
  'definition.archive_pool_reload_order_value': 'Wert für Rückladereihenfolge',
  'definition.archive_pool_reload_script_command': 'Rückladeskript/-befehl',
  'definition.archive_pool_retention_period_days': 'Verweilzeit (Tage)',
  'definition.archive_pool_retention_period_level2': 'Verweilzeit (Tage) für Level 2',
  'definition.archive_pool_use_storage_api': 'Speicher-API verwenden',
  'definition.archive_pools_error': 'Fehler beim Laden der Archivpooldefinitionen ($0)',
  'definition.attributes': 'Attribute',
  'definition.available_elements': 'Verfügbare Elemente',
  'definition.available_jobtypes_error': 'Fehler beim Laden der verfügbaren Jobtypen "$0"',
  'definition.clone_folder_error': 'Fehler beim Klonen der Ordnerdefinition "$0" ($1)',
  'definition.clone_folder_success': 'Ordnerdefinition "$0" wurde hinzugefügt (Klone von Ordnerdefinition "$1")',
  'definition.command': 'Befehl',
  'definition.command_attributes': 'Befehl - Eigenschaften',
  'definition.community': 'Community',
  'definition.configuration': 'Konfiguration',
  'definition.copy_custom_dialog': 'Benutzerdefinierten Dialog kopieren',
  'definition.copy_document_node': 'Dokumentknoten kopieren',
  'definition.copy_externalcmd': 'Externen Befehl kopieren',
  'definition.copy_filter_argument': 'Filterargument kopieren',
  'definition.copy_index': 'Index kopieren',
  'definition.copy_output_channel': 'Ausgabekanal kopieren',
  'definition.copy_output_channel_id_error': 'Ausgabekanal-ID muss geändert werden',
  'definition.copy_output_format': 'Ausgabeformat kopieren',
  'definition.copy_ppn': 'Nachbearbeitungsnotiz kopieren',
  'definition.copy_result_table': 'Ergebnistabelle kopieren',
  'definition.copy_result_table_same_resulttableid_error': 'Ergebnistabellen-ID muss geändert werden',
  'definition.copy_search_argument_title': 'Suchargument kopieren',
  'definition.copy_view_profile': 'Anzeigeprofil kopieren',
  'definition.create_archive_pool_error': 'Fehler beim Erstellen des Archivpools "$0" ($1)',
  'definition.create_archive_pool_success': 'Archivpool "$0" erstellt',
  'definition.create_archive_pool_title': 'Archivpool anlegen',
  'definition.create_archive_subpool_error': 'Fehler beim Erstellen des Archivsubpools für Archivepool "$0" ($1)',
  'definition.create_archive_subpool_success': 'Archivsubpool für Archivepool "$0" erstellt',
  'definition.create_custom_dialog': 'Benutzerdefinierten Dialog erstellen',
  'definition.create_custom_dialog_error': 'Fehler beim Erstellen des benutzerdefinierten Dialogs "$0" ($1)',
  'definition.create_custom_dialog_no_assigned_elements': 'Keine Elemente hinzugefügt.',
  'definition.create_custom_dialog_success': 'Benutzerdefinierten Dialog "$0" wurde erstellt',
  'definition.create_document_error': 'Fehler beim Erstellen der Dokumentendefinition "$0" ($1)',
  'definition.create_document_node': 'Dokumentknoten erstellen',
  'definition.create_document_node_error': 'Fehler beim Erstellen des Dokumentknoten "$0" ($1)',
  'definition.create_document_node_success': 'Dokumentknoten "$0" wurde erstellt',
  'definition.create_document_success': 'Dokumentdefinition "$0" erstellt',
  'definition.create_ext_command_error': 'Fehler beim Erstellen der Befehldefinition "$0" ($1)',
  'definition.create_ext_command_success': 'Befehldefinition "$0" wurde erstellt',
  'definition.create_externalcmd': 'Externen Befehl erstellen',
  'definition.create_filter_argument': 'Filterargument erstellen',
  'definition.create_filter_argument_error': 'Fehler beim Erstellen der Filterargumentdefinition "$0" ($1)',
  'definition.create_filter_argument_success': 'Filterargument Definition "$0" wurde erstellt',
  'definition.create_folder_error': 'Fehler beim Erstellen der Ordnerdefinition "$0" ($1)',
  'definition.create_folder_success': 'Ordnerdefinition "$0" wurde erstellt',
  'definition.create_index': 'Index erstellen',
  'definition.create_index_error': 'Fehler beim Erstellen der Indexdefinition "$0" ($1)',
  'definition.create_index_success': 'Indexdefinition "$0" wurde erstellt',
  'definition.create_jobgroup': 'Jobgruppe erstellen',
  'definition.create_jobgroup_error': 'Fehler beim Erstellen des Jobgruppe "$0" ($1)',
  'definition.create_jobgroup_success': 'Jobgruppe "$0" erstellt',
  'definition.create_log_error': 'Fehler beim Erstellen der Logdefinition "$0" ($1)',
  'definition.create_log_success': 'Logdefinition "$0" erstellt',
  'definition.create_lpd_queue_error': 'Fehler beim Erstellen der LPD-Warteschlangendefinition "$0" ($1)',
  'definition.create_lpd_queue_success': 'LPD-Warteschlangendefinition "$0" wurde erstellt',
  'definition.create_output_channel': 'Ausgabekanaldefinition erstellen',
  'definition.create_output_channel_error': 'Fehler beim Erstellen des Ausgabekanals "$0" ($1)',
  'definition.create_output_channel_success': 'Ausgabekanaldefinition "$0" wurde erstellt',
  'definition.create_output_format': 'Ausgabeformatdefinition erstellen',
  'definition.create_output_format_error': 'Fehler beim Erstellen des Ausgabeformates "$0" ($1)',
  'definition.create_output_format_success': 'Ausgabeformatdefinition "$0" wurde erstellt',
  'definition.create_output_request_with_status_hold': 'Ausgabeauftrag mit Status \'Halten\' erzeugen',
  'definition.create_ppn': 'Nachbearbeitungsnotiz erstellen',
  'definition.create_ppn_error': 'Fehler beim Erstellen der Nachbearbeitungsnotizdefinition "$0" ($1)',
  'definition.create_ppn_success': 'Nachbearbeitungsnotizdefinition "$0" wurde erstellt',
  'definition.create_recipient_error': 'Fehler beim Erstellen der Empfängerdefinition "$0" ($1)',
  'definition.create_recipient_success': 'Empfängerdefinition (Empfänger ID: "$0", Typ: "$1") wurde erstellt',
  'definition.create_result_table': 'Ergebnistabelle erstellen',
  'definition.create_result_table_error': 'Fehler beim Erstellen der Ergebnistabelle "$0" ($1)',
  'definition.create_result_table_success': 'Ergebnistabelle "$0" wurde erstellt',
  'definition.create_search_argument_error': 'Fehler beim Erstellen des Sucharguments "$0" ($1)',
  'definition.create_search_argument_success': 'Suchargument "$0" ($1) erstellt',
  'definition.create_search_argument_title': 'Suchargument erstellen',
  'definition.create_searchargument_value_error': 'Fehler beim Erstellen des Suchargumentwerts "$0"',
  'definition.create_searchargument_value_success': 'Suchargumentwert "$0" erfolgreich erstellt',
  'definition.create_view_profile': 'Anzeigeprofil erstellen',
  'definition.create_view_profile_error': 'Fehler beim Erstellen des Anzeigeprofils "$0" ($1)',
  'definition.create_view_profile_success': 'Anzeigeprofil "$0" wurde erstellt',
  'definition.custom_dialog': 'Benutzerdefinierter Dialog',
  'definition.custom_dialog_assigned_objects_error': 'Fehler beim Laden der zugewiesenen Elemente für benutzerdefinierten Dialog ($0)',
  'definition.custom_dialog_copy_same_id': 'Dialog-ID muss geändert werden',
  'definition.custom_dialog_default_objects_error': 'Fehler beim Laden der Standardelemente für benutzerdefinierten Dialog ($0)',
  'definition.custom_dialog_definitions': 'Benutzerdefinierte Dialogdefinitionen',
  'definition.custom_dialog_error': 'Fehler beim Laden des benutzerdefinierten Dialogs ($0)',
  'definition.custom_dialog_id': 'Dialog-ID',
  'definition.custom_dialogs_error': 'Fehler beim Laden der benutzerdefinierten Dialoge ($0)',
  'definition.database_query_enable_delete_function': 'Die Löschoption muss oben freigeschaltet werden',
  'definition.dbfield': 'DB-Feld',
  'definition.dbselect': 'DB-Auswahl',
  'definition.dbvalue': 'DB-Wert',
  'definition.debug_mode': 'Testmodus (temporäre Dateien stehenlassen)',
  'definition.definition_mode': 'Definitionen',
  'definition.del_output_channel': 'Ausgabekanal löschen',
  'definition.del_output_format': 'Ausgabeformat löschen',
  'definition.delete_archive_pool_error': 'Fehler beim Löschen des Archivpools "$0" ($1)',
  'definition.delete_archive_pool_success': 'Archivpool "$0" gelöscht',
  'definition.delete_archive_subpool_error': 'Fehler beim Löschen des Archivsubpools für Archivepool "$0" ($1)',
  'definition.delete_archive_subpool_success': 'Archivsubpool für Archivepool "$0" gelöscht',
  'definition.delete_custom_dialog': 'Benutzerdefinierten Dialog löschen',
  'definition.delete_custom_dialog_error': 'Fehler beim Löschen des benutzerdefinierten Dialogs "$0"',
  'definition.delete_custom_dialog_success': 'Benutzerdefinierten Dialog "$0" gelöscht',
  'definition.delete_document': 'Dokument löschen',
  'definition.delete_document_error': 'Fehler beim Löschen der Dokumentdefinition "$0" ($1)',
  'definition.delete_document_node': 'Dokumentknoten löschen',
  'definition.delete_document_node_error': 'Fehler beim Löschen der Dokumentknotendefinition "$0"',
  'definition.delete_document_node_success': 'Dokumentknotendefinition "$0" gelöscht',
  'definition.delete_document_success': 'Dokumentdefinition "$0" wurde gelöscht',
  'definition.delete_ext_command_error': 'Fehler beim Löschen der Befehldefinition "$0" ($1)',
  'definition.delete_ext_command_success': 'Befehldefinition "$0" wurde gelöscht',
  'definition.delete_filter_argument': 'Filterargument löschen',
  'definition.delete_filter_argument_error': 'Fehler beim Löschen der Filterargumentdefinition "$0" ($1)',
  'definition.delete_filter_argument_success': 'Filterargumentdefinition "$0" wurde gelöscht',
  'definition.delete_folder': 'Ordner löschen',
  'definition.delete_folder_error': 'Fehler beim Löschen der Ordnerdefinition "$0" ($1)',
  'definition.delete_folder_question': 'Möchten Sie wirklich diesen Ordner löschen?',
  'definition.delete_folder_success': 'Folderdefinition "$0" wurde gelöscht',
  'definition.delete_index': 'Index löschen',
  'definition.delete_index_error': 'Fehler beim Löschen der Indexdefinition "$0" ($1)',
  'definition.delete_index_success': 'Indexdefinition "$0" wurde gelöscht',
  'definition.delete_jobgroup': 'Jobgruppe löschen',
  'definition.delete_jobgroup_error': 'Fehler beim Löschen der Jogruppe "$0" ($1)',
  'definition.delete_jobgroup_success': 'Jobgruppe "$0" gelöscht',
  'definition.delete_log': 'Log löschen',
  'definition.delete_log_error': 'Fehler beim Löschen der Logdefinition "$0" ($1)',
  'definition.delete_log_success': 'Logdefinition "$0" wurde gelöscht',
  'definition.delete_lpd_queue_error': 'Fehler beim Löschen der LPD-Warteschlangendefinition "$0" ($1)',
  'definition.delete_lpd_queue_success': 'LPD-Warteschlangendefinition "$0" wurde gelöscht',
  'definition.delete_output_channel_error': 'Fehler beim Löschen der Ausgabekanaldefinition "$0" ($1)',
  'definition.delete_output_channel_success': 'Ausgabekanaldefinition "$0" wurde gelöscht',
  'definition.delete_output_format_error': 'Fehler beim Löschen der Ausgabeformatdefinition "$0" ($1)',
  'definition.delete_output_format_success': 'Ausgabeformatdefinition "$0" wurde gelöscht',
  'definition.delete_ppn_error': 'Fehler beim Löschen der Nachbearbeitungsnotizdefinition "$0" ($1)',
  'definition.delete_ppn_success': 'Nachbearbeitungsnotizdefinition "$0" wurde gelöscht',
  'definition.delete_recipient_error': 'Fehler beim Löschen der Empfängerdefinition ($0) ($1)',
  'definition.delete_recipient_success': 'Empfängerdefinition ($0) wurde gelöscht',
  'definition.delete_result_table': 'Ergebnistabelle löschen',
  'definition.delete_result_table_error': 'Fehler beim Löschen der Ergebnistabelle "$0"',
  'definition.delete_result_table_success': 'Ergebnistabelle "$0" gelöscht',
  'definition.delete_search_argument': 'Suchargument löschen',
  'definition.delete_search_argument_error': 'Fehler beim Löschen der Suchargumentdefinition "$0" ($1)',
  'definition.delete_search_argument_success': 'Suchargumentdefinition "$0" ($1) wurde gelöscht',
  'definition.delete_searchargument_value_error': 'Fehler beim Löschen des Suchargumentwerts "$0',
  'definition.delete_searchargument_value_success': 'Suchargumentwert "$0" erfolgreich gelöscht',
  'definition.delete_view_profile': 'Anzeigeprofil löschen',
  'definition.delete_view_profile_error': 'Fehler beim Löschen des Anzeigeprofils "$0"',
  'definition.delete_view_profile_success': 'Anzeigeprofil "$0" gelöscht',
  'definition.device': 'Gerät',
  'definition.display_hierarchy': 'Hierarchieansicht',
  'definition.document': 'Dokument',
  'definition.document_CP1250': 'CP1250 - Windows, Zentral- and Osteuropa',
  'definition.document_CP1251': 'CP1251 - Windows, Russland, Bulgarien, Serbien',
  'definition.document_CP1252': 'CP1252 - Windows, Westeuropa',
  'definition.document_CP1253': 'CP1253 - Windows, Griechenland',
  'definition.document_CP1256': 'CP1256 - Windows, Arabisch',
  'definition.document_CP850': 'CP850 - DOS-Latein-1',
  'definition.document_IBM0037': 'IBM0037 - USA, Kanada - CECP',
  'definition.document_IBM0273': 'IBM0273 - Österreich, Germany CECP',
  'definition.document_IBM0297': 'IBM0297 - Frankreich - CECP',
  'definition.document_IBM0420': 'IBM0420 - IBM, Arabisch Zweisprachig (Ebcdic)',
  'definition.document_IBM0500': 'IBM0500 - International #5',
  'definition.document_IBM1140': 'IBM1140 - IBM, USA, Kanada (Ebcdic)',
  'definition.document_IBM1141': 'IBM1141 - IBM, Österreich, Germany (Ebcdic)',
  'definition.document_IBM1147': 'IBM1147 - IBM, Frankreich (Ebcdic)',
  'definition.document_IBM1148': 'IBM1148 - IBM, International (Ebcdic)',
  'definition.document_ISO8859_1': 'ISO8859-1 - ISO, Westeuropa',
  'definition.document_ISO8859_15': 'ISO8859-15 - ISO, Westeuropa mit Euro',
  'definition.document_ISO8859_2': 'ISO8859-2 - ISO, Zentral- und Osteuropa',
  'definition.document_UCS_2BE': 'UCS-2BE - Unicode',
  'definition.document_UTF_8': 'UTF-8 - Unicode',
  'definition.document_absolute': 'Absolute',
  'definition.document_according_to_rules_1_8': 'Nach Regel 1 bis Regel 8',
  'definition.document_afp_scan_only_nops': '*.afp: Suche nur NOPs',
  'definition.document_afp_scan_only_pjl': '*.pcl: Suche nur @PJL',
  'definition.document_afp_scan_only_tles': '*.afp: Suche nur TLEs',
  'definition.document_archive_notes': 'Notizen archivieren',
  'definition.document_archive_text_version': 'Textversion archivieren',
  'definition.document_assign_in_reverse_order': 'In umgekehrter Reihenfolge zuweisen',
  'definition.document_assign_values_to_docusr_variables': 'Werte den DOCUSR-Variablen zuordnen',
  'definition.document_auto_print': 'Auto print',
  'definition.document_autoburst': 'Autoburst',
  'definition.document_automatic_archive_document': 'Dokument automatisch archivieren',
  'definition.document_banner': 'Dokument Deckblatt',
  'definition.document_banner_trailer_file_name': 'Deckblatt/Endeblatt Dateiname',
  'definition.document_batch_bundle': 'Stapelbündelung',
  'definition.document_batch_print': 'Stapeldruck',
  'definition.document_batch_print_bundle': 'Stapeldruck/Stapelbündelung',
  'definition.document_burst_mode': 'Burstmodus',
  'definition.document_calculate_retention_by_start_date': 'Berechne Verweilzeit ab Startdatum',
  'definition.document_clone_modal_title': 'Dokumentdefinition klonen',
  'definition.document_cond_burst1': 'Conditional Burst 1',
  'definition.document_cond_burst2': 'Conditional Burst 2',
  'definition.document_cond_burst3': 'Conditional Burst 3',
  'definition.document_content_extraction_from_column': 'Extrahiere Inhalt ab Spalte',
  'definition.document_content_extraction_length': 'Länge des zu extrahierenden Inhalts',
  'definition.document_content_extraction_relative_to': 'Extrahiere Inhalt relativ zum',
  'definition.document_copy_banner': 'Dokument Kopiedeckblatt',
  'definition.document_copy_modal_title': 'Dokumentdefinition kopieren',
  'definition.document_create_modal_title': 'Dokumentdefinition erstellen',
  'definition.document_display_search_argument_and_values': 'Suchargumente und -werte',
  'definition.document_document_print_parameter': 'Dokumentdruckparameter',
  'definition.document_exclude': 'Exclude',
  'definition.document_exclude_formula': 'Ausschlussformel',
  'definition.document_extract_date_from_document': 'Datum aus Dokument extrahieren',
  'definition.document_extract_variable_values': 'Variablenwerte extrahieren',
  'definition.document_filter': 'Filter',
  'definition.document_from_position_of_rule_1': 'Ab Position der 1. Regel',
  'definition.document_header_lines': 'Kopfzeilen',
  'definition.document_header_lines_on_page_one': 'Kopfzeilen von Seite 1',
  'definition.document_hold_bundle_request': 'Bündelaufträge halten',
  'definition.document_limit_burst': 'Limit/Burst',
  'definition.document_lines_after_matching_lines': 'Zeilen nach Trefferzeilen',
  'definition.document_lines_before_matching_lines': 'Zeilen vor Trefferzeilen',
  'definition.document_mark_matches': 'Treffer kennzeichnen (=>)',
  'definition.document_maximum_burst_windows_is_reached': 'Das Maximum von 8 Burst-Fenster ist erreicht',
  'definition.document_maximum_colors_is_reached': 'Das Maximum von 4 Farben ist erreicht',
  'definition.document_maximum_columns_is_reached': 'Das Maximum von 8 Spalten ist erreicht',
  'definition.document_maximum_lines_pages': 'Maximale Zeilen pro Seite',
  'definition.document_media_cannot_be_none': 'Medium muss Band, Platte oder Optische Platte sein.',
  'definition.document_modify_modal_title': 'Dokumentdefinition bearbeiten',
  'definition.document_no_data_found_report': '"Keine Daten gefunden"-Report',
  'definition.document_node': 'Dokumentknoten',
  'definition.document_node_copy_same_id': 'Knoten-ID muss geändert werden',
  'definition.document_node_definitions': 'Dokumentknotendefinitionen',
  'definition.document_node_error': 'Fehler beim Laden der Dokumentknotendefinition ($0)',
  'definition.document_node_no_childs_possible_for_group': 'Keine Kinder möglich bei Typ "Gruppe"',
  'definition.document_nodes_error': 'Fehler beim Laden der Dokumentknotendefinitionen ($0)',
  'definition.document_nops_encoded_in_ebcdic': 'NOPs kodiert in EBCDIC',
  'definition.document_number_of_lines': 'Zeilenanzahl',
  'definition.document_number_of_values': 'Anzahl der Werte',
  'definition.document_one_burst_window_required': 'Es ist mindestens 1 Burst-Fenster erforderlich',
  'definition.document_online_print': 'Online print',
  'definition.document_other_textversion': 'Andere Textversionen',
  'definition.document_page_offset_at_end_qualifying_pages': 'Seitenverschiebung für das Ende der Seitenextraktion',
  'definition.document_page_offset_at_start_qualifying_pages': 'Seitenverschiebung für den Beginn der Seitenextraktion',
  'definition.document_pcl_textversion_in_ebcdic': 'PCL Textversion in EBCDIC',
  'definition.document_pdf_textversion_in_utf8': 'PDF Textversion in UTF-8',
  'definition.document_prefix_lines_with_betaux_page_line_number': 'Beta UX Seiten- und Zeilennummer voranstellen',
  'definition.document_prefix_lines_with_input_line_number': 'Input-Zeilenummer voranstellen',
  'definition.document_print_control_file_name': 'Datei für Druckersteuerung',
  'definition.document_ptx_encoding_utf8': 'PTX Kodierung (UTF-8)',
  'definition.document_replace_txt_by': '*.txt ersetzen durch',
  'definition.document_report_generation_and_variable_extraction': 'Reporterstellung und Variablenextraktion',
  'definition.document_retention_days': 'Verweilzeit (Tage)',
  'definition.document_search_argument_formula': 'Suchargumentformel',
  'definition.document_search_mode': 'Suchmodus',
  'definition.document_search_mode_error': 'Bitte einen Suchmodus auswählen.',
  'definition.document_separator_between_noncontiguous_lines': 'Trennzeile (--) zwischen nicht aufeinander folgenden Zeilen',
  'definition.document_skip_all_blank_values': 'Alle leeren Werte überspringen',
  'definition.document_skip_leading_blank_values_only': 'Leere Werte nur am Anfang überspringen',
  'definition.document_start_new_page_when_value_begins_at_column': 'Seitenumbruch wenn Wert in Spalte beginnt',
  'definition.document_start_search_argument_formula': 'Start-Suchargumentformel',
  'definition.document_start_stop': 'Start/Stop',
  'definition.document_stop_after_number_of_matches': 'Stop nach Anzahl von Treffern',
  'definition.document_stop_argument_formula_on_same_page': 'Stop-Suchargumentformel auf der gleichen Seite',
  'definition.document_stop_search_argument_formula': 'Stop-Suchargumentformel',
  'definition.document_supress_leading_blanks_and_repeated_blanks_between_words': 'Leerzeichen am Anfang und bis auf eins zwischen den Wörtern entfernen',
  'definition.document_tles_encoded_in_ebcdic': 'TLEs kodiert in EBCDIC',
  'definition.document_trailer': 'Dokument Endeblatt',
  'definition.document_use_copies_value_from_list_generation_record': 'Anzahl Kopien aus Listengenerationsatz verwenden',
  'definition.document_value_relative_position': 'Relative Werteposition',
  'definition.document_variable_extraction': 'Variablenextraktion',
  'definition.documentdefinitions': 'Dokumentdefinitionen',
  'definition.documents_error': 'Fehler beim Laden der Dokumentdefinitionen ($0)',
  'definition.elements': 'Elemente',
  'definition.empty_result_table_objects': 'Bitte mindestens ein Element auswählen',
  'definition.end_date': 'Enddatum',
  'definition.example_for_multi_file_processing': 'Beispiele für Mehr-Datei-Verfahren',
  'definition.example_for_single_file_processing': 'Beispiele für Ein-Datei-Verfahren',
  'definition.ext_commands_error': 'Fehler beim Laden der externen Befehldefinitionen ($0)',
  'definition.external_commands': 'Externe Befehle',
  'definition.externalcmd.arg': 'Argumente',
  'definition.externalcmd.cmd': 'Befehl',
  'definition.externalcmd.del_ext_cmd': 'Externes Kommando löschen',
  'definition.externalcmd.ext_cmd_id': 'Externe Befehls-ID',
  'definition.externalcmd.question_del_ext_cmd': 'Soll dieser externe Befehl wirklich gelöschet werden?',
  'definition.extras': 'Extras',
  'definition.filter_argument': 'Filterargumente',
  'definition.filter_argument_case_sensitive': 'Groß-/\nKleinschreibung',
  'definition.filter_argument_copy_same_id': 'Filterargument-ID muss geändert werden',
  'definition.filter_argument_definition': 'Filterargumentdefinitionen',
  'definition.filter_argument_error': 'Fehler beim Laden der Filterargumentdefinition ($0)',
  'definition.filter_argument_formula': 'Formel',
  'definition.filter_argument_from_column': 'Von Spalte',
  'definition.filter_argument_id': 'Filterargument-ID',
  'definition.filter_argument_logical_condition': 'Logische Verknüpfung der Filterargumente',
  'definition.filter_argument_max_reached': 'Die maximale Anzahl an Filterargumentwerten ist 8.',
  'definition.filter_argument_negate': 'Negieren',
  'definition.filter_argument_search_pattern': 'Suchmuster',
  'definition.filter_argument_to_column': 'Bis Spalte',
  'definition.filter_argument_values': 'Filterargument Werte',
  'definition.filter_argument_verify': 'Filterargument verifizieren',
  'definition.filterargument_add_filterargument': 'Füge Filterargument hinzu',
  'definition.folder': 'Ordner',
  'definition.folder_copy_modal_title': 'Ordner kopieren',
  'definition.folder_copy_same_id_error': 'Ordner-ID muss geändert werden',
  'definition.folder_create_assigned_documents': 'Zugeordnete Dokumente',
  'definition.folder_create_documents_tab': 'Dokumente',
  'definition.folder_create_dynamic_report': 'Dynamic report',
  'definition.folder_create_folder_id': 'Ordner-ID',
  'definition.folder_create_general_tab': 'Generell',
  'definition.folder_create_modal_title': 'Ordner erstellen',
  'definition.folder_create_owner': 'Besitzer',
  'definition.folder_create_title': 'Titel',
  'definition.folder_id': 'Ordner-ID',
  'definition.folder_id_required': 'Bitte geben sie eine Ordner-ID ein',
  'definition.folder_owner': 'Besitzer',
  'definition.folder_title': 'Titel',
  'definition.folderdefinitions': 'Ordnerdefinitionen',
  'definition.folders_error': 'Fehler beim Laden der Ordnerdefinitionen ($0)',
  'definition.format_options': 'Formatierungsoptionen',
  'definition.get_filter_argument_error': 'Fehler beim Laden der Filterargumentdefinition "$0" ($1)',
  'definition.get_folder_error': 'Fehler beim Laden der Ordnerdefinition "$0" ($1)',
  'definition.get_index_error': 'Fehler beim Laden der Indexdefinition "$0" ($1)',
  'definition.get_log_error': 'Fehler beim Laden des Logs "$0" ($1)',
  'definition.get_lpd_queue_error': 'Fehler beim Laden der LPD-Warteschlangendefinition "$0" ($1)',
  'definition.get_output_channel_error': 'Fehler beim Laden des Ausgabekanals "$0" ($1)',
  'definition.get_output_format_error': 'Fehler beim Laden des Ausgabeformats "$0" ($1)',
  'definition.get_ppn_error': 'Fehler beim Laden der Nachbearbeitungsnotizdefinition "$0" ($1)',
  'definition.get_recipient_error': 'Fehler beim Laden der Empfängerdefinition "$0" ($1)',
  'definition.get_search_argument_error': 'Fehler beim Laden der Suchargumentdefinition "$0" ($1)',
  'definition.hit_limitation': 'Trefferlimitierung',
  'definition.host': 'Host',
  'definition.ignore_documents_print_control_parameters': 'Druckersteuerparameter der Dokumente ignorieren',
  'definition.index': 'Index',
  'definition.index_blanks_between_words': 'Leerzeichen zwischen Wörtern',
  'definition.index_blanks_between_words_keep_all': 'Alle erhalten',
  'definition.index_blanks_between_words_keep_one': 'Eins erhalten',
  'definition.index_blanks_between_words_remove_all': 'Alle entfernen',
  'definition.index_convert_values_to_upper': 'Indexwerte in Großbuchstaben umwandeln',
  'definition.index_copy_same_id': 'Index-ID muss geändert werden',
  'definition.index_data_format': 'Datenformat',
  'definition.index_definitions': 'Index Definitionen',
  'definition.index_document_assignment.security_check': 'Sicherheitsprüfung',
  'definition.index_document_assignment.values_required': 'Werte benötigt',
  'definition.index_document_assignment.variant': 'Variante',
  'definition.index_id': 'Index-ID',
  'definition.index_identifier': 'Bezeichner',
  'definition.index_maxlength': 'Maximale Länge',
  'definition.index_name_id': 'Index-ID',
  'definition.index_number_of_decimal_places': 'Anzahl der Dezimalstellen',
  'definition.index_numeric_left': 'Numerisch (linksbündig)',
  'definition.index_numeric_right': 'Numerisch (rechtsbündig)',
  'definition.index_verify': 'Index verifizieren',
  'definition.indexes_error': 'Fehler beim Laden der Indexdefinitionen "$0"',
  'definition.initialization_print_control': 'Druckersteuerparameter vor den Druckdaten',
  'definition.insert_carriage_return_before_each_line': 'Carriage Return vor jeder Zeile einfügen',
  'definition.insert_carriage_return_before_line_feed': 'Carriage Return vor Line Feed einfügen',
  'definition.insert_page_breaks_after_formatting': 'Seitenumbrüche nach Formatierung einfügen',
  'definition.ipt': 'IP/T',
  'definition.ipt_attributes': 'IP/T - Eigenschaften',
  'definition.jobgroup_loading_error': 'Fehler beim Laden der Jobgruppe ($0)',
  'definition.jobgroups': 'Jobgruppen',
  'definition.jobgroups_loading_error': 'Fehler beim Laden der Jobgruppen ($0)',
  'definition.length_input_field': 'Länge Eingabefeld',
  'definition.level_3_archive': 'Level 3 Archiv',
  'definition.log': 'Log',
  'definition.log_clone_modal_title': 'Logdefinition klonen',
  'definition.log_copy_modal_title': 'Logdefinition kopieren',
  'definition.log_create_modal_title': 'Logdefinition erstellen',
  'definition.log_modify_modal_title': 'Logdefinition bearbeiten',
  'definition.logs_error': 'Fehler beim Laden der Logdefinitionen ($0)',
  'definition.lpd_queue': 'LPD-Warteschlange',
  'definition.lpd_queue.check': 'Check',
  'definition.lpd_queue.del_lpd_queue': 'LPD-Warteschlange löschen',
  'definition.lpd_queue.delete_tooltip': 'Spooling und Transfer muss deaktiviert sein, um gelöscht zu werden',
  'definition.lpd_queue.id': 'LPD-Warteschlangen-ID',
  'definition.lpd_queue.owner': 'Besitzer',
  'definition.lpd_queue.question_del_lpd_queue': 'Möchten Sie diese LPD-Warteschlange wirklich löschen?',
  'definition.lpd_queue.spooling': 'Spooling',
  'definition.lpd_queue.transfer': 'Transfer',
  'definition.lpd_queue_definitions': 'LPD-Warteschlangendefinitionen',
  'definition.lpd_queues_error': 'Fehler beim Laden der LPD-Warteschlangendefinitionen ($0)',
  'definition.modify_archive_pool': 'Archivpool bearbeiten',
  'definition.modify_archive_pool_error': 'Fehler beim Bearbeiten des Archivpools "$0" ($1)',
  'definition.modify_archive_pool_success': 'Archivpool "$0" bearbeitet',
  'definition.modify_custom_dialog': 'Benutzerdefinierten Dialog bearbeiten',
  'definition.modify_custom_dialog_error': 'Fehler beim Bearbeiten des benutzerdefinierten Dialogs "$0" ($1)',
  'definition.modify_custom_dialog_success': 'Benutzerdefinierten Dialog "$0" wurde bearbeitet',
  'definition.modify_document_definition_error': 'Fehler beim Bearbeiten der Dokumentendefinition "$0" ($1)',
  'definition.modify_document_definition_success': 'Dokumentdefinition "$0" bearbeitet',
  'definition.modify_document_node': 'Dokumentknoten bearbeiten',
  'definition.modify_externalcmd': 'Externen Befehl bearbeiten',
  'definition.modify_filter_argument': 'Filterargument bearbeiten',
  'definition.modify_folder': 'Ordner bearbeiten',
  'definition.modify_jobgroup': 'Jobgruppe bearbeiten',
  'definition.modify_log_definition_error': 'Fehler beim Bearbeiten der Logdefinition "$0" ($1)',
  'definition.modify_log_definition_success': 'Logdefinition "$0" bearbeitet',
  'definition.modify_output_channel': 'Ausgabekanal bearbeiten',
  'definition.modify_output_channel_error': 'Fehler beim Bearbeiten der Ausgabekanaldefinition "$0" ($1)',
  'definition.modify_output_channel_success': 'Ausgabekanaldefinition "$0" bearbeitet',
  'definition.modify_output_format': 'Ausgabeformat bearbeiten',
  'definition.modify_ppn': ' Nachbearbeitungsnotiz bearbeiten',
  'definition.modify_result_table': 'Ergebnistabelle bearbeiten',
  'definition.modify_result_table_error': 'Fehler beim Bearbeiten der Ergebnistabelle "$0" ($1)',
  'definition.modify_result_table_success': 'Ergebnistabelle "$0" bearbeitet',
  'definition.modify_searchargument_error': 'Fehler beim Bearbeiten des Sucharguments "$0" ($1)',
  'definition.modify_searchargument_success': 'Suchargument "$0" ($1) bearbeitet',
  'definition.modify_searchargument_title': 'Suchargument bearbeiten',
  'definition.modify_view_profile': 'Anzeigeprofil bearbeiten',
  'definition.multi_file_processing': 'Mehr-Datei-Verfahren',
  'definition.node': 'Knoten',
  'definition.node_has_child_items': 'Knoten hat Unterknoten',
  'definition.node_id': 'Knoten-ID',
  'definition.node_selection': 'Knotenauswahl',
  'definition.off': 'Aus',
  'definition.on': 'An',
  'definition.optional_parameters': 'Zusätzliche Parameter',
  'definition.output_channel_id': 'Ausgabekanal-ID',
  'definition.output_channel_id_created': 'Ausgabekanal "$0" erstellt',
  'definition.output_channel_pjl_info': 'Nur Verfügbar bei Protokoll: PJL',
  'definition.output_channel_verify': 'Ausgabekanal prüfen',
  'definition.output_command': 'Ausgabebefehl',
  'definition.output_format_copy_same_id_error': 'Ausgabeformat-ID muss geändert werden',
  'definition.output_format_defaults': 'Ausgabeformat Standardeinstellungen',
  'definition.output_format_id': 'Ausgabeformat-ID',
  'definition.output_format_id_required': 'Bitte geben sie eine Ausgabeformat-ID ein',
  'definition.output_format_values': 'Werte',
  'definition.output_format_verify': 'Ausgabeformat prüfen',
  'definition.output_in_utf8': 'Ausgabe in UTF-8',
  'definition.outputchannel': 'Ausgabekanal',
  'definition.outputchannel.alternative_id': 'Alternative Ausgabekanal-ID',
  'definition.outputchannel.id': 'Ausgabekanal-ID',
  'definition.outputchannel.only_active_alternatives': 'Nur aktive altern. Ausgabekanäle',
  'definition.outputchannel.type_any': 'Jeden',
  'definition.outputchannel.type_application': 'Applikation',
  'definition.outputchannel.type_cmd': 'Befehl',
  'definition.outputchannel.type_ipt': 'IP/T',
  'definition.outputchanneldefinitions': 'Ausgabekanaldefinitionen',
  'definition.outputchannels_error': 'Fehler beim Laden der Ausgabekanaldefinitionen ($0)',
  'definition.outputformat': 'Ausgabeformate',
  'definition.outputformatdefinitions': 'Ausgabeformatdefinitionen',
  'definition.outputformats_error': 'Fehler beim Laden der Ausgabeformatdefinitionen ($0)',
  'definition.owner': 'Besitzer',
  'definition.page': 'Seite',
  'definition.parameters_and_values': 'Parameter und Werte',
  'definition.port': 'Port',
  'definition.post_processing_note': 'Nachbearbeitungsnotiz',
  'definition.post_processing_note_definition': 'Nachbearbeitungsnotizdefinitionen',
  'definition.postprocessing_command': 'Nachbearbeitungsbefehl',
  'definition.postprocessing_note': 'Nachbearbeitungsnotiz',
  'definition.postprocessing_note_id': 'Nachbearbeitungsnotiz-ID',
  'definition.postprocessing_note_verify': 'Nachbearbeitungsnotiz prüfen',
  'definition.ppn.del_ppn': 'Nachbearbeitungsnotiz löschen',
  'definition.ppn.question_del_ppn': 'Möchten Sie wirklich diese Nachbearbeitungsnotiz löschen?',
  'definition.ppn_copy_same_id_error': 'Nachbearbeitungsnotiz-ID muss geändert werden',
  'definition.ppn_id': 'Nachbearbeitungsnotiz-ID',
  'definition.ppn_print_control_filename': 'Dateiname für Druckersteuerung',
  'definition.ppndefinitions': 'Nachbearbeitungsnotiz Definition',
  'definition.ppns_error': 'Fehler beim Laden der Nachbearbeitungsnotizdefinitionen ($0)',
  'definition.preparation_command': 'Aufbereitungsbefehl',
  'definition.preview': 'Vorschau',
  'definition.print_control_and_banner_file_prefix': 'Präfix für Druckersteuer- und Deckblattdateien',
  'definition.print_control_parameters_pic': 'Druckersteuerparameter (PIC)',
  'definition.print_info_error': 'Fehler beim Laden der Druckinformationen',
  'definition.print_lp': 'Drucken LP: lp -s -c -dprinter {%s} ',
  'definition.print_lpr_folder': 'Drucken LPR: lpr -PPrinter &MFP_FOLDER./data.*',
  'definition.print_lpr_print': 'Drucken LPR: lpr -PPrinter {%s} ',
  'definition.protocol': 'Protokoll',
  'definition.provide_output_as_separate_files_in_working_directory': 'Ausgabe als separate Dateien im Arbeitsverzeichnis bereitstellen',
  'definition.question_del_output_channel': 'Möchten Sie diesen Ausgabekanal wirklich löschen?',
  'definition.question_del_output_format': 'Möchten Sie dieses Ausgabeformat wirklich löschen?',
  'definition.question_delete_custom_dialog': 'Möchten sie wirklich diesen benutzerdefinierten Dialog löschen?',
  'definition.question_delete_document': 'Möchten Sie wirklich dieses Dokument löschen?',
  'definition.question_delete_document_node': 'Wollen Sie diesen Dokumentknoten wirklich löschen?',
  'definition.question_delete_filter_argument': 'Wollen Sie das Filterargument wirklich löschen?',
  'definition.question_delete_index': 'Wollen Sie diesen Index wirklich löschen?',
  'definition.question_delete_jobgroup': 'Wollen Sie diese Jobgruppe wirklich löschen?',
  'definition.question_delete_log': 'Möchten Sie wirklich diesen Log löschen?',
  'definition.question_delete_result_table': 'Möchten sie wirklich diese Ergebnistabelle löschen?',
  'definition.question_delete_search_argument': 'Möchten Sie das Suchargument wirklich löschen?',
  'definition.question_delete_view_profile': 'Möchten sie wirklich dieses Anzeigeprofile löschen?',
  'definition.queue_name': 'Queue-Name',
  'definition.recipient': 'Empfänger',
  'definition.recipient_banner': 'Empfänger Deckblatt',
  'definition.recipient_no_childs_possible_for_alias': 'Keine Kinder möglich bei Typ "Alias"',
  'definition.recipient_trailer': 'Empfänger Endeblatt',
  'definition.recipientdefinitions': 'Empfängerdefinitionen',
  'definition.recipients_error': 'Fehler beim Laden der Empfängerdefinitionen ($0)',
  'definition.result_table': 'Ergebnistabelle',
  'definition.result_table_column_identifier': 'Spaltenbezeichnung',
  'definition.result_table_column_value': 'Spaltenwert',
  'definition.result_table_column_width': 'Spaltenbreite',
  'definition.result_table_default_objects_error': 'Fehler beim Laden der Standard Ergebnistabellenobjekte "$0"',
  'definition.result_table_definitions': 'Ergebnistabellendefinitionen',
  'definition.result_table_error': 'Fehler beim Laden der Ergebnistabelle "$0"',
  'definition.result_table_field_format': 'Feldformat',
  'definition.result_table_field_length': 'Feldlänge',
  'definition.result_table_field_name': 'Feldname',
  'definition.result_table_field_value': 'Feldwert',
  'definition.result_table_id': 'Ergebnistabellen-ID',
  'definition.result_table_modify_object': 'Objekt bearbeiten',
  'definition.result_table_objects_error': 'Fehler beim Laden der Ergebnistabellenobjekte "$0"',
  'definition.result_tables_error': 'Fehler beim Laden der Ergebnistabellen "$0"',
  'definition.retry_period_minutes': 'Retry-Zeitraum (Minuten)',
  'definition.save_as_cat': 'Speichern als ... cat {%s}> ../tmp/&FORM..&EXT..&REPORT',
  'definition.save_as_cp': 'Speichern als ... cp &MFP_FOLDER./* ../tmp',
  'definition.search_argument': 'Suchargument',
  'definition.search_argument_burst_1': 'Burst-Fenster 1',
  'definition.search_argument_burst_2': 'Burst-Fenster 2',
  'definition.search_argument_burst_3': 'Burst-Fenster 3',
  'definition.search_argument_burst_4': 'Burst-Fenster 4',
  'definition.search_argument_burst_5': 'Burst-Fenster 5',
  'definition.search_argument_burst_6': 'Burst-Fenster 6',
  'definition.search_argument_burst_7': 'Burst-Fenster 7',
  'definition.search_argument_burst_8': 'Burst-Fenster 8',
  'definition.search_argument_case': 'Fall',
  'definition.search_argument_column_position': 'Spaltenposition',
  'definition.search_argument_copy_same_id': 'Suchargument-ID muss geändert werden',
  'definition.search_argument_exclude_formula': 'Ausschlussformel',
  'definition.search_argument_on_page': 'Auf Seite',
  'definition.search_argument_operator': 'Operator',
  'definition.search_argument_stop': 'Suchargument stop',
  'definition.search_argument_value': 'Suchargumentwert',
  'definition.search_argument_values_available': 'Verfügbare Suchargumentwerte',
  'definition.search_argument_values_disabled_info': 'Bearbeiten-Dialog und Typ "Wert" erforderlich',
  'definition.search_argument_values_to_copy': 'Zu kopierende Suchargumentwerte',
  'definition.search_argument_verify': 'Suchargument verifizieren',
  'definition.search_arguments': 'Suchargumente',
  'definition.searchargument_add_searchargument_value': 'Suchargumentwert hinzufügen',
  'definition.searchargument_attached_searchargument_values': 'Anhängende Suchargumentwerte',
  'definition.searchargument_case_sensitive': 'Groß-/Kleinschreibung beachten',
  'definition.searchargument_columnposition_for_searchpattern': 'Spaltenposition für Suchmuster',
  'definition.searchargument_comparison_operator': 'Vergleichsoperator',
  'definition.searchargument_error': 'Fehler beim Laden der Suchargumentdefinitionen ($0)',
  'definition.searchargument_id': 'Suchargument-ID',
  'definition.searchargument_save_searchargument_value_as_utf8': 'Suchargumentwert in UTF-8 speichern',
  'definition.searchargument_searchargument_value': 'Suchargumentwert',
  'definition.searchargument_tle_name': 'TLE-Name',
  'definition.searchargument_value_type': 'Suchargumentwerttyp',
  'definition.skeleton_file': 'Skeleton (.skl Datei)',
  'definition.start_date': 'Startdatum',
  'definition.sublog': 'Sub-Log',
  'definition.table_of_contents': 'Inhaltsverzeichnis',
  'definition.termination_print_control': 'Druckersteuerparameter nach den Druckdaten',
  'definition.timed_seconds': 'Intervall (Sekunden)',
  'definition.title': 'Titel',
  'definition.transfer_documents_only': 'Nur Dokumente übertragen',
  'definition.type': 'Typ',
  'definition.update_document_node_error': 'Fehler beim Bearbeiten der Dokumentknotendefinition "$0" ($1)',
  'definition.update_document_node_success': 'Dokumentknotendefinition "$0" bearbeitet',
  'definition.update_ext_command_error': 'Fehler beim Bearbeiten der Befehldefinition "$0" ($1)',
  'definition.update_ext_command_success': 'Befehldefinition "$0" bearbeitet',
  'definition.update_filter_argument_error': 'Fehler beim Bearbeiten der Filterargumentdefinition "$0" ($1)',
  'definition.update_filter_argument_success': 'Filterargumentdefinition "$0" bearbeitet',
  'definition.update_folder_error': 'Fehler beim Bearbeiten der Ordnerdefinition "$0" ($1)',
  'definition.update_folder_success': 'Ordnerdefinition "$0" bearbeitet',
  'definition.update_index': 'Index bearbeiten',
  'definition.update_index_error': 'Fehler beim Bearbeiten der Indexdefinition "$0" ($1)',
  'definition.update_index_success': 'Indexdefinition "$0" bearbeitet',
  'definition.update_jobgroup_error': 'Fehler beim Bearbeiten der Jobgruppe "$0" ($1)',
  'definition.update_jobgroup_success': 'Jobgruppe "$0" bearbeitet',
  'definition.update_lpd_queue_error': 'Fehler beim Bearbeiten der LPD-Warteschlangendefinition "$0" ($1)',
  'definition.update_lpd_queue_success': 'LPD-Warteschlangendefinition "$0" bearbeitet',
  'definition.update_output_format_error': 'Fehler beim Bearbeiten der Ausgabekanaldefinition "$0" ($1)',
  'definition.update_output_format_success': 'Ausgabekanal "$0" bearbeitet',
  'definition.update_ppn_error': 'Fehler beim Bearbeiten der Nachbearbeitungsnotizdefinition "$0" ($1)',
  'definition.update_ppn_success': 'Nachbearbeitungsnotizdefinition "$0" bearbeitet',
  'definition.update_recipient_error': 'Fehler beim Bearbeiten der Empfängerdefinition "$0" ($1)',
  'definition.update_recipient_success': 'Empfängerdefinition "$0" bearbeitet',
  'definition.update_view_profile_error': 'Fehler beim Bearbeiten der Anzeigeprofildefinition "$0" ($1)',
  'definition.update_view_profile_success': 'Anzeigeprofildefinition "$0" bearbeitet',
  'definition.user_output_generation_record_ogr': 'Verwende Ausgabegenerationssatz (OGR)',
  'definition.verbose': 'Ausführlich',
  'definition.verify_document_error': 'Fehler beim Verifizieren der Dokumentdefinition "$0" ($1)',
  'definition.verify_filter_argument_error': 'Fehler beim Verifizieren des Filterargumentes',
  'definition.verify_index_error': 'Fehler beim Verifizieren der Indexdefinition',
  'definition.verify_log_error': 'Fehler beim Verifizieren der Logdefinition "$0" ($1)',
  'definition.verify_output_channel_error': 'Fehler beim Verifizieren des Ausgabekanals',
  'definition.verify_output_format_error': 'Fehler beim Verifizieren des Ausgabeformats',
  'definition.verify_ppn_error': 'Fehler beim Verifizieren der Nachbearbeitungsnotiz',
  'definition.verify_search_argument_error': 'Fehler beim Verifizieren des Suchargumentes',
  'definition.view_profile': 'Anzeigeprofil',
  'definition.view_profile_copy_same_id': 'Anzeigeprofil-ID muss ge#ndert werden',
  'definition.view_profile_error': 'Fehler beim Laden des Anzeigeprofils "$0"',
  'definition.view_profile_id': 'Anzeigeprofil-ID',
  'definition.view_profile_search_criteria': 'Suchkriterien',
  'definition.view_profile_table_1': 'I. Tabelle',
  'definition.view_profile_table_2': 'II. Tabelle',
  'definition.view_profile_test_mode': 'Testmodus',
  'definition.view_profile_test_user': 'Testbenutzer',
  'definition.view_profile_use_text_as_search_pattern': 'Suchmuster',
  'definition.view_profiles_error': 'Fehler beim Laden der Anzeigeprofile "$0"',
  'definition.wait_minutes': 'Warten (Minuten)',
  'definitions.custom_dialog_element_blocked': '"$0" wird von "$1" blockiert.',
  'definitions.custom_dialog_element_blocked_sdate': '"$0" wird von "$1" mit Datenformat "DATEDATE" blockiert.',
  'definiton.archive_pool_file_managed_by': 'Datei verwaltet durch',
  'documentinformation.generaltab': 'Allgemein',
  'documentinformation.generaltab_docuser1': 'DocUser 1',
  'documentinformation.generaltab_docuser2': 'DocUser 2',
  'documentinformation.generaltab_docuser3': 'DocUser 3',
  'documentinformation.generaltab_docuser4': 'DocUser 4',
  'documentinformation.generaltab_docuser5': 'DocUser 5',
  'documentinformation.generaltab_docuser6': 'DocUser 6',
  'documentinformation.generaltab_docuser7': 'DocUser 7',
  'documentinformation.generaltab_docuser8': 'DocUser 8',
  'documentinformation.generaltab_owner': 'Besitzer',
  'documentinformation.generaltab_saveutf': 'Werte in UTF-8 speichern',
  'documentinformation.generaltab_title': 'Titel',
  'documentinformation.header_afp': 'AFP',
  'documentinformation.header_blocks': 'Blöcke',
  'documentinformation.header_checksum': 'Prüfsumme',
  'documentinformation.header_controlcharacters': 'Steuerzeichen',
  'documentinformation.header_doctype': 'Dokumententyp',
  'documentinformation.header_documentid': 'Dokument-ID',
  'documentinformation.header_dynamicreport': 'Dyn. Report',
  'documentinformation.header_ebcdic': 'EBCDIC',
  'documentinformation.header_encoding': 'Kodierung',
  'documentinformation.header_filename': 'Dateiname',
  'documentinformation.header_jobname': 'Jobname',
  'documentinformation.header_maxreordlength': 'Maximale Datensatzlänge',
  'documentinformation.header_obtained': 'Quelle',
  'documentinformation.header_orginaldatetime': 'Org. Datum/Zeit',
  'documentinformation.header_owner': 'Besitzer',
  'documentinformation.header_pages': 'Seiten',
  'documentinformation.header_searchmode': 'Suchmodus',
  'documentinformation.header_tablereferencecharacter': 'Tabellenreferenzzeichen',
  'documentinformation.modaltitle': 'Dokumentinformationen',
  'documentinformation.ogrtab': 'OGR',
  'documentinformation.ogrtab_title': 'OGR Informationen',
  'documentinformation.onlineretention': 'Online-Verweilzeit',
  'documentinformation.pointerstab': 'Zeiger',
  'documentinformation.pointerstab_archivepointer': 'Archivzeiger',
  'documentinformation.pointerstab_archivetextpointer': 'Archivtextzeiger',
  'documentinformation.pointerstab_externalindexdataspoolpointer': 'Externer Index-Datenspool-Zeiger',
  'documentinformation.pointerstab_indexpointer': 'Indexzeiger',
  'documentinformation.pointerstab_listtimestamp': 'Listenzeitstempel (LTOKEN)',
  'documentinformation.pointerstab_reporttimestamp': 'Reportzeitstempel (RTOKEN)',
  'documentinformation.pointerstab_ressourcearchivepointer': 'Ressourcen Archivzeiger',
  'documentinformation.pointerstab_ressourcespoolpointer': 'Ressourcen Spoolzeiger',
  'documentinformation.pointerstab_spoolpointer': 'Spoolzeiger',
  'documentinformation.pointerstab_textpointer': 'Textzeiger',
  'documentinformation.pointerstab_title': 'Zeiger und Zeitstempel',
  'documentinformation.retentions': 'Verweilzeiten',
  'documentinformation.retentiontab': 'Verweilzeit',
  'documentinformation.retentiontab_additionalinformation': 'Zusätzliche Informationen',
  'documentinformation.retentiontab_archived': 'Archiviert',
  'documentinformation.retentiontab_archiveexperation': 'Archiv-Verfall',
  'documentinformation.retentiontab_archiveexperationdate': 'Verfallsdatum archivieren',
  'documentinformation.retentiontab_archivenotes': 'Notizen archivieren',
  'documentinformation.retentiontab_archiveretention': 'Archivaufbewahrung',
  'documentinformation.retentiontab_disk': 'Platte',
  'documentinformation.retentiontab_generations': 'Generationen',
  'documentinformation.retentiontab_indexarchived': 'Index archiviert',
  'documentinformation.retentiontab_indexonline': 'Online-Index',
  'documentinformation.retentiontab_lastdocumentaccess': 'Letzter Dokumentenzugriff',
  'documentinformation.retentiontab_lastnoteaccess': 'Letzter Zugriff auf die Notiz',
  'documentinformation.retentiontab_notesonline': 'Notizen online',
  'documentinformation.retentiontab_online': 'Online',
  'documentinformation.retentiontab_onlineexpiration': 'Online-Verfall',
  'documentinformation.retentiontab_opticaldisk': 'Optische Platte',
  'documentinformation.retentiontab_references': 'Verweise',
  'documentinformation.retentiontab_tape': 'Band',
  'documentinformation.statustab': 'Status',
  'documentinformation.statustab_annotation': 'Anmerkung',
  'documentinformation.statustab_controlstatus': 'Kontrollstatus',
  'documentinformation.statustab_controluser': 'Kontrollbenutzer',
  'documentinformation.statustab_editingicon': 'Bearbeitungssymbol',
  'documentprint.copies': 'Kopien',
  'documentprint.default': 'Standard',
  'documentprint.from_page': 'Von Seite',
  'documentprint.header_copies': 'Kopien',
  'documentprint.header_datetime': 'Datum / Zeit',
  'documentprint.header_doctype': 'DokTyp',
  'documentprint.header_dynamicreport': 'Dynamischer Report',
  'documentprint.header_encoding': 'Encoding',
  'documentprint.header_pages': 'Seiten',
  'documentprint.header_title': 'Titel',
  'documentprint.modaltitle': 'Dokument drucken',
  'documentprint.outputchannel': 'Ausgabekanal',
  'documentprint.outputformat': 'Ausgabeformat',
  'documentprint.postprocessingnote': 'Nachbearbeitungsnotiz-ID',
  'documentprint.print': 'Drucken',
  'documentprint.print_param': 'Druckparameter',
  'documentprint.priority': 'Priorität',
  'documentprint.recipient': 'Empfänger',
  'documentprint.to_page': 'Bis Seite',
  'documentprint.user': 'Benutzer',
  'documents.document_download_error': 'Fehler beim Herunterladen des Dokuments ($0)',
  'documents.documents_error': 'Fehler beim Suchen der Dokumente ($0)',
  'documents.documents_print_error': 'Fehler beim Drucken des Dokuments "$0" ($1)',
  'documents.documents_print_multiple_success': 'Dokumente gedruckt auf "$0"',
  'documents.documents_print_success': 'Dokument "$0" gedruckt auf "$1"',
  'documents.get_document_error': 'Fehler beim Laden des Dokuments "$0" ($1)',
  'documents.modify_document_success': 'Dokumentinformationen "$0" $1 Tab bearbeitet',
  'documents.modify_document_success_log': 'Loginformationen "$0" $1 Tab bearbeitet',
  'documents.print_info_error': 'Fehler beim Abrufen von Druckinformationen',
  'documents.reload_document': 'Dokument neu laden',
  'documents.reload_document_retention': 'Online Beibehaltung nach Neuladen (Tage)',
  'documents.reprintbundle_error': 'Fehler beim Wiederholen des Druck- oder Bündelauftrags "$0"',
  'documents.rescan_error': 'Fehler bei der Wiederholung des Scans "$0"',
  'documents.update_document_error': 'Fehler beim Bearbeiten des Dokuments "$0" ($1)',
  'documents.update_document_error_log': 'Fehler beim Bearbeiten des Logs "$0" ($1)',
  'documents.update_document_success_mark': 'Dokument "$0" für "$1" markiert', // TODO: use msg for un/mark
  'documents.update_document_success_unmark': 'Dokument "$0" für "$1" entmarkiert', // TODO: use msg for un/mark
  'documents.update_documentinformation_success': 'Document "$0" modified',
  'documentviewer.document_already_opened': 'Das Dokument ist bereits im DocViewer geöffnet.',
  'documentviewer.document_will_be_opened': 'Das Dokument wird im DocViewer geöffnet',
  'documentviewer.document_display': 'Anzeigen',
  'documentviewer.hex_view': 'HEX-Anzeige',
  'documentviewer.hex_view_configuration': 'HEX Einstellung',
  'documentviewer.hex_view_disabled': 'HEX-Anzeige ist für Jobs nicht verfügbar',
  'documentviewer.no_cached_data': 'Keine Daten sind zwischengespeichert',
  'documentviewer.no_data': 'Keine Daten verfügbar',
  'documentviewer.print_not_available': 'Druckfunktion ist nicht verfügbar für Jobs',
  'documentviewer.print_not_available_sidefile': 'Druckfunktion ist nicht verfügbar für Sidefiles',
  'documentviewer.remove_index_highlight': 'Indexmarkierung entfernen auf Seite ',
  'documentviewer.removed_index_highlight': 'Indexmarkierung wurde entfernt',
  'documentviewer.send_email': 'Als E-mail versenden',
  'documentviewer.selection_tooltip.start_row_column': 'Start Zeile/Spalte:',
  'documentviewer.selection_tooltip.end_row_column': 'Ende Zeile/Spalte:',
  'documentviewer.selection_tooltip.length': 'Länge:',
  'documentviewer.session_expired': 'Die Sitzung ist abgelaufen, bitte melden sie sich erneut an',
  'documentviewer.unexpected_error': 'Unerwarteter Fehler ',
  'emptyresult.create_archive_pool_link': 'Sie können einen neuen Archivpool anlegen.',
  'emptyresult.create_custom_dialog_link': 'Sie können einen neuen benutzerdefinierten Dialog erstellen.',
  'emptyresult.create_document_link': 'Sie können ein neues Dokument anlegen.',
  'emptyresult.create_document_node_link': 'Sie können einen neuen Dokumentknoten anlegen.',
  'emptyresult.create_externaldcmd_link': 'Sie können einen neuen externen Befehl anlegen.',
  'emptyresult.create_filter_argument_link': 'Sie können ein neues Filterargument erstellen.',
  'emptyresult.create_folder_document_assignment': 'Sie können eine neue Ordner <-> Dokument Zuordnung erstellen.',
  'emptyresult.create_folder_link': 'Sie können einen neuen Ordner anlegen.',
  'emptyresult.create_group_link': 'Sie können eine neue Gruppe anlegen.',
  'emptyresult.create_index_document_assignment': 'Sie können eine neue Index <-> Dokument Zuordnung erstellen.',
  'emptyresult.create_index_link': 'Sie können einen neuen Index anlegen.',
  'emptyresult.create_jobgroup_link': 'Sie können eine neue Jobgruppe anlegen.',
  'emptyresult.create_log_link': 'Sie können ein neues Log anlegen.',
  'emptyresult.create_lpd_queue_link': 'Sie können eine neue LPD-Warteschlange erstellen.',
  'emptyresult.create_outputchannel_link': 'Sie können einen neuen Ausgabekanal erstellen.',
  'emptyresult.create_outputformat_link': 'Sie können ein neues Ausgabeformat erstellen.',
  'emptyresult.create_ppn_link': 'Sie können eine neue Nachbearbeitungsnotiz anlegen.',
  'emptyresult.create_recipient_document_assignment': 'Sie können eine neue Empfänger <-> Dokument Zuordnung erstellen.',
  'emptyresult.create_recipient_link': 'Sie können einen neuen Empfänger anlegen.',
  'emptyresult.create_remote_user_link': 'Sie können einen neuen externen Benutzer anlegen.',
  'emptyresult.create_result_table_link': 'Sie können eine neue Ergebnistablle erstellen.',
  'emptyresult.create_search_argument_link': 'Sie können ein neues Suchargument erstellen.',
  'emptyresult.create_security_profile_link': 'Sie können ein neues Sicherheitsprofil anlegen.',
  'emptyresult.create_user_link': 'Sie können einen neuen Benutzer anlegen.',
  'emptyresult.create_view_profile_link': 'Sie können ein neues Anzeigeprofil erstellen.',
  'emptyresult.no_archive_pool_result': 'Kein Archivpool entspricht Ihren Suchkriterien.',
  'emptyresult.no_custom_dialog_result': 'Kein benutzerdefinierter Dialog entspricht Ihren Suchkriterien.',
  'emptyresult.no_document_node_result': 'Kein Dokumentknoten entspricht Ihren Suchkriterien.',
  'emptyresult.no_document_result': 'Kein Dokument entspricht Ihren Suchkriterien.',
  'emptyresult.no_external_cmd_result': 'Kein externer Befehl entspricht Ihren Suchkriterien.',
  'emptyresult.no_filter_argument_result': 'Kein Filterargument entspricht Ihren Suchkriterien.',
  'emptyresult.no_folder_document_assignment_result': 'Keine Ordner <-> Dokument Zuordnung entspricht Ihren Suchkriterien.',
  'emptyresult.no_folder_result': 'Kein Ordner entspricht Ihren Suchkriterien.',
  'emptyresult.no_groups_result': 'Keine Gruppe entspricht Ihren Suchkriterien.',
  'emptyresult.no_index_document_assignment_result': 'Keine Index <-> Dokument Zuordnung entspricht Ihren Suchkriterien.',
  'emptyresult.no_index_result': 'Kein Index entspricht Ihren Suchkriterien.',
  'emptyresult.no_jobgroup_result': 'Kein Jobgruppe entspricht Ihren Suchkriterien.',
  'emptyresult.no_log_result': 'Kein Log entspricht Ihren Suchkriterien.',
  'emptyresult.no_lpd_queue_result': 'Keine LPD-Warteschlange entspricht Ihren Suchkriterien.',
  'emptyresult.no_outputchannel_result': 'Keine Ausgabekanäle entsprechen Ihren Suchkriterien.',
  'emptyresult.no_outputformat_result': 'Keine Ausgabeformate entsprechen Ihren Suchkriterien.',
  'emptyresult.no_ppn_result': 'Keine Nachbearbeitungsnotiz entspricht Ihren Suchkriterien.',
  'emptyresult.no_recipient_document_assignment_result': 'Keine Empfänger <-> Dokument Zuordnung entsprechen Ihren Suchkriterien.',
  'emptyresult.no_recipient_result': 'Kein Empfänger entspricht Ihren Suchkriterien.',
  'emptyresult.no_remote_users_result': 'Kein externer Benutzer entsprechen Ihren Suchkriterien.',
  'emptyresult.no_result_headline': 'Kein Suchergebnis',
  'emptyresult.no_result_table_result': 'Keine Ergebnistabelle entspricht Ihren Suchkriterien.',
  'emptyresult.no_search_argument_result': 'Kein Suchargument entspricht Ihren Suchkriterien.',
  'emptyresult.no_security_profiles_result': 'Kein Sicherheitsprofil entspricht Ihren Suchkriterien.',
  'emptyresult.no_users_result': 'Kein Benutzer entspricht Ihren Suchkriterien.',
  'emptyresult.no_view_profile_result': 'Kein Anzeigeprofile entspricht Ihren Suchkriterien.',
  'emptysearchresult.description': 'Kein Dokument gefunden',
  'error.from_page': 'Eingabe muss kleiner oder gleich sein',
  'error.infile_required': 'INFILE ist erforderlich',
  'error.logsource_required': 'LOGSOURCE ist erforderlich',
  'error.to_page': 'Eingabe muss höher oder gleich sein',
  'errorboundary.contact': 'Kontaktieren Sie Ihren IT-Administratoren, diese können die Informationen an die Beta Systems Software AG weiterleiten.',
  'errorboundary.goto_startpage': 'Klicken Sie hier, um zur Startseite zu gehen.',
  'errorboundary.more_details': 'Für weitere Details öffnen Sie die Entwicklungsoberfläche (F12) und öffnen Sie die Konsole, um den letzten Fehler zu kopieren.',
  'errorboundary.unexpected_error_occured': 'Ein unerwarteter Fehler ist aufgetreten!',
  'external_command.arguments': 'Argumente',
  'external_command.command': 'Befehl',
  'external_command.command_id_same_command_id': 'Externe Befehls ID muss geändert werden',
  'external_command.command_required': 'Bitte geben Sie ein Befehl an',
  'external_command.description': 'Beschreibung',
  'external_command.id': 'Externer Befehl ID',
  'external_command.id_required': 'Bitte geben Sie eine ID an',
  'filepicker.index_file': 'passende Indexdatei',
  'filepicker.index_files': 'passende Indexdateien',
  'filterargument.from_col_greater_to_col': 'Wert muss größer als "Von Spalte" Wert sein',
  'filterargument.please_enter_a_filter_argument_id': 'Bitte geben Sie eine Filterargument-ID ein',
  'general.activate': 'Aktualisieren',
  'general.activation': 'Aktivierung',
  'general.activation_date': 'Aktivierungsdatum',
  'general.active': 'Aktiv',
  'general.add_burst_window': 'Burst-Fenster hinzufügen',
  'general.add_group_mask': 'Gruppenmaske hinzufügen',
  'general.additional': 'Zusätzlich',
  'general.admin': 'Admin',
  'general.afp': 'AFP',
  'general.afp_text_version': 'AFP Textversion',
  'general.agent': 'Agent',
  'general.agent_name': 'Agentenname',
  'general.align_to_left': 'Linksbündig',
  'general.align_to_right': 'Rechtsbündig',
  'general.alignment': 'Ausrichtung',
  'general.all': 'Alle',
  'general.all_reports': 'Alle Berichte',
  'general.alternate': 'Ersatz',
  'general.and': 'Und',
  'general.any': 'Jeden',
  'general.api': 'Speicher/BMS',
  'general.archive': 'Archiviert',
  'general.archive_key': 'Archivschlüssel',
  'general.archive_media': 'Archivmedium',
  'general.as_is': 'Wie gegeben',
  'general.ascii_ebcdic': 'ASCII/EBCDIC',
  'general.assignedSecurityProfiles': 'Zugewiesene Sicherheitsprofile',
  'general.assignment': 'Zuordnungen',
  'general.assignment_modal_title': 'Zuordnung',
  'general.assignment_single': 'Zuordnung',
  'general.attempt_count': 'Versuchsanzahl',
  'general.available': 'Verfügbar',
  'general.background_hex': 'Hintergrund (Hex)',
  'general.betaview_not_configured': 'Das Dokument kann nicht angezeigt werden, da kein _beta view konfiguriert ist.',
  'general.black': 'Schwarz',
  'general.block_format': 'Blockformat',
  'general.blue': 'Blau',
  'general.bseqnr': 'Bseqnr',
  'general.btoken': 'BToken',
  'general.btype': 'B-Typ',
  'general.burst_window': 'Burst-Fenster',
  'general.burst_windows': 'Burst-Fenster',
  'general.by': 'von',
  'general.bytes': 'Dateigröße in Bytes',
  'general.cancel': 'Abbrechen',
  'general.canceled': 'Abgebrochen',
  'general.case_sensitive': 'Groß-/Kleinschreibung',
  'general.check_status': 'Status prüfen',
  'general.checksum': 'Prüfsumme',
  'general.child_identifier': 'Nachfolgebezeichner',
  'general.click_to_sort_column': 'Klicken um die Spalte $0 $1 zu sortieren',
  'general.client': 'Mandant',
  'general.clone': 'Klonen',
  'general.close': 'Schließen',
  'general.close_row': 'Zeile schließen',
  'general.code_page': 'Code Page',
  'general.colors': 'Farben',
  'general.column': 'Spalte',
  'general.column_position': 'Spaltenposition',
  'general.columns': 'Spalten',
  'general.comma': 'Komma',
  'general.complete': 'Vollständig',
  'general.completely': 'Komplett',
  'general.conditions': 'Bedingungen',
  'general.confirm': 'Bestätigen',
  'general.control_user_id': 'Kontrollbenutzer-ID',
  'general.controlled': 'Control',
  'general.copy': 'Kopieren',
  'general.copy_assignments': 'Zuordnungen kopieren',
  'general.copy_group_mask': 'Gruppenmaske kopieren',
  'general.cpu_usage': 'CPU Benutzung',
  'general.create_assignment': 'Anlegen',
  'general.create_recipient_through_table_visualization': 'Ein neuer Eintrag kann in der Ansicht "Tabelle" erstellt werden.',
  'general.created': 'Erstellt',
  'general.creation_date': 'Erstelldatum',
  'general.current': 'Aktuell',
  'general.custom': 'Individuell',
  'general.custom_dialogs': 'Benutzerdefinierte Dialoge',
  'general.data_compression': 'Daten komprimieren',
  'general.data_format': 'Datenformat',
  'general.database': 'Datenbank',
  'general.datacenter': 'Datenzentrum',
  'general.date': 'Datum',
  'general.date_field': 'Datumsfeld',
  'general.date_time': 'Datum/Zeit',
  'general.dateformat': 'Datumsformat',
  'general.datemask': 'Datumsmaske',
  'general.datemask2': 'Datumsformat',
  'general.days': 'Tage',
  'general.decimal': 'Dezimal',
  'general.decimal_mark': 'Dezimaltrenner',
  'general.default': 'Standard',
  'general.definition': 'Definition',
  'general.delete': 'Löschen',
  'general.delete_from_archive': 'Vom Archiv löschen',
  'general.delete_group_mask': 'Gruppenmaske löschen',
  'general.delete_with_verify': 'Löschen mit Verifizierung',
  'general.delete_without_verify': 'Löschen ohne Verifizierung',
  'general.deleted': 'Gelöscht',
  'general.description': 'Beschreibung',
  'general.destination': 'Ziel',
  'general.detail': 'Detail',
  'general.detect_matches': 'Vorkommen',
  'general.disabled': 'Deaktiviert',
  'general.disk': 'Platte',
  'general.display': 'Anzeigen',
  'general.display_as': 'Anzeigen als',
  'general.display_document': 'Dokument anzeigen',
  'general.display_documents': 'Dokumente anzeigen',
  'general.display_folders': 'Ordner anzeigen',
  'general.display_in_beta_view': 'Anzeige in beta view',
  'general.display_indices': 'Indizes anzeigen',
  'general.display_line_numbers': 'Zeilennummern anzeigen',
  'general.display_mode': 'Anzeigemodus',
  'general.display_nodes': 'Knoten anzeigen',
  'general.display_objects': 'Objekte anzeigen',
  'general.display_recipients': 'Empfänger anzeigen',
  'general.doctype': 'Dok-Typ',
  'general.document': 'Dokument',
  'general.document_modal_title': 'Dokument',
  'general.documents': 'Dokumente',
  'general.docuser1': 'DocUser 1',
  'general.docuser2': 'DocUser 2',
  'general.docuser3': 'DocUser 3',
  'general.docuser4': 'DocUser 4',
  'general.docuser5': 'DocUser 5',
  'general.docuser6': 'DocUser 6',
  'general.docuser7': 'DocUser 7',
  'general.docuser8': 'DocUser 8',
  'general.docuservars': 'DocUser-Variablen',
  'general.download': 'Herunterladen',
  'general.download_as_binary': 'Als Binär-Datei runterladen',
  'general.download_as_text': 'Als Textdatei runterladen',
  'general.dynamic_generated': 'Dynamisch generiert',
  'general.dynamic_report': 'Dynamischer Report',
  'general.e_column': 'E-Spalte',
  'general.e_line': 'E-Zeile',
  'general.e_page': 'E-Seite',
  'general.edit': 'Bearbeiten',
  'general.empty_table': 'Keine Daten gefunden.',
  'general.encoding': 'Kodierung',
  'general.end': 'Ende',
  'general.end_date': 'Enddatum',
  'general.end_sequenz': 'Ende Sequenz',
  'general.entries': 'Einträge',
  'general.epages': 'E-Seiten',
  'general.equal': 'Gleich',
  'general.error': 'Fehler',
  'general.exclusive': 'Exklusiv',
  'general.execute': 'Ausführen',
  'general.exit_code': 'Rückgabewert',
  'general.expires': 'Läuft ab',
  'general.extension': 'Extension',
  'general.fetch_data_timeout': 'Zeitüberschreitung beim Laden der Daten!',
  'general.file': 'Datei',
  'general.file_extension': 'Dateiendung',
  'general.file_name': 'Dateiname',
  'general.file_name_or_mask': 'Dateiname oder Maske',
  'general.filename': 'Dateiname',
  'general.first_in_line_only': 'Erstmalig in der Zeile',
  'general.first_in_window_only': 'Erstmalig im Fenster',
  'general.fix': 'Fix',
  'general.folder': 'Ordner',
  'general.folder_modal_title': 'Ordner',
  'general.foreground_hex': 'Vordergrund (Hex)',
  'general.form': 'Form',
  'general.form_tab': 'Form',
  'general.format': 'Format',
  'general.found_in': 'Gefunden in',
  'general.from': 'Von',
  'general.from_column': 'Von Spalte',
  'general.from_gen': 'Von Generation',
  'general.from_line': 'Von Zeile',
  'general.from_page': 'Von Seite',
  'general.from_row': 'Von Zeile',
  'general.general': 'Allgemein',
  'general.global': 'Global',
  'general.greater_equal': 'Größer gleich',
  'general.greater_than': 'Größer als',
  'general.green': 'Grün',
  'general.group_masks': 'Gruppenmasken',
  'general.groupadmin': 'Gruppenadmin',
  // headers
  'general.header.agent': 'Agent',
  'general.header.credentials_name': 'Zugangsname',
  'general.header.end_date': 'Enddatum',
  'general.header.instance_number': 'Instanznummer',
  'general.header.job_error': 'Jobfehler',
  'general.header.job_group': 'Jobgruppe',
  'general.header.job_name': 'Jobname',
  'general.header.job_status': 'Jobstatus',
  'general.header.job_type': 'Jobtyp',
  'general.header.return_code': 'Rückgabewert',
  'general.header.run_user': 'Anwender',
  'general.header.start_date': 'Startdatum',
  'general.header.system': 'System',
  'general.header.trigger_by': 'Ausgelöst von',
  'general.header.trigger_name': 'Name des Auslösers',
  'general.header.trigger_date': 'Auslösezeit',
  'general.header.updated': 'Geändert am',
  //
  'general.hex': 'HEX',
  'general.hide': 'Ausblenden',
  'general.hierarchy': 'Hierarchie',
  'general.hit_page': 'Trefferseite',
  'general.hit_range': 'Trefferbereich',
  'general.hits': 'Treffer',
  'general.hours': 'Stunden',
  'general.hsm': 'HSM',
  'general.identifier': 'Bezeichner',
  'general.import': 'Importieren',
  'general.in_length_of': 'In der Länge',
  'general.include_hit_line': 'Trefferzeile',
  'general.include_lines_after': 'Mit Zeilen danach',
  'general.include_pages_after': 'Mit Seiten danach',
  'general.include_pages_before': 'Mit Seiten davor',
  'general.incorrect_date': 'Falsches Datumsformat',
  'general.index_modal_title': 'Index',
  'general.index_values': 'Indexwerte',
  'general.indexname': 'Indexname',
  'general.info': 'Info',
  'general.info_code': 'Infowert',
  'general.information': 'Information',
  'general.input_includes_disallowed_chars': 'Unerlaubte Zeichen',
  'general.input_no_blanks': 'Keine Leerzeichen erlaubt.',
  'general.input_no_start_space': 'Eingabe kann nicht mit einem Leerzeichen beginnen',
  'general.input_required': 'Eingabe ist erforderlich',
  'general.insert_child': 'Kind einfügen',
  'general.invalid_date_format': 'Ungültiges Datumsformat',
  'general.invalid_hex_value': 'Gültiger Hexwert erwartet (e.g. #00FF00)',
  'general.invalid_time_format': 'Ungültiges Zeitformat',
  'general.invalid_value': 'Ungültiger Wert',
  'general.invalid_value_length': 'Ungültige Wertlänge.',
  'general.items_lowercase': 'Einträge',
  'general.job': 'Job',
  'general.job_data': 'Jobdaten',
  'general.job_details': 'Job Details',
  'general.job_error': 'Fehlerhafter Job',
  'general.jobgroup': 'Jobgruppe',
  'general.jobgroup_masks': 'Jobgruppen-Masken',
  'general.jobgroup_name': 'Jobgruppenname',
  'general.jobname': 'Jobname',
  'general.job_type': 'Jobtyp',
  'general.jobs': 'Jobs',
  'general.key': 'Schlüssel',
  'general.label': 'Label',
  'general.labeled_frame': 'Labeled Frame',
  'general.language': 'Sprache',
  'general.last': 'Zuletzt',
  'general.last_access': 'Letzter Zugriff',
  'general.last_changed': 'Letzte Änderung',
  'general.last_login': 'Letzte Anmeldung',
  'general.last_password_change': 'Letzte Passwortänderung',
  'general.launch': 'DE_Launch',
  'general.launch_date': 'Ausführungsdatum',
  'general.lc_time': 'LC_TIME Format',
  'general.left': 'Links',
  'general.length': 'Länge',
  'general.less': 'weniger',
  'general.less_equal': 'Kleiner gleich',
  'general.less_than': 'Kleiner als',
  'general.line': 'Zeile',
  'general.line_format': 'Zeilenformat',
  'general.line_start': 'Zeilenanfang',
  'general.lines': 'Zeilen',
  'general.list': 'Liste',
  'general.list_timestamp': 'Listenzeitstempel',
  'general.local': 'Lokal',
  'general.log': 'Log',
  'general.log_lowercase': 'log',
  'general.log_modal_title': 'Log',
  'general.log_source': 'Log-Quelle',
  'general.log_source_reader': 'Logdatenquelle',
  'general.logs': 'Logs',
  'general.managed_by': 'Verwaltet durch',
  'general.manually': 'Manuell',
  'general.mark': 'Mark',
  'general.mark_archive': 'Zum Archivieren markieren',
  'general.mark_delete': 'Zum Löschen markieren',
  'general.mark_reload': 'Zum Neuladen markieren',
  'general.marked_archive': 'Archivierung markiert',
  'general.marked_delete': 'Löschen markiert',
  'general.marked_reload': 'Neuladen markiert',
  'general.max_online_retention': 'Maximale Online-Verweilzeit',
  'general.media': 'Medium',
  'general.migration': 'Migration',
  'general.mimetype': 'Mimetype',
  'general.minutes': 'Minuten',
  'general.modify': 'bearbeiten',
  'general.modify_priority': 'Priorität ändern',
  'general.more': 'mehr',
  'general.more_options': 'Mehr Optionen',
  'general.name': 'Name',
  'general.new': 'Neu',
  'general.new_priority': 'Neue Priorität',
  'general.no': 'Nein',
  'general.no_connection_to_server': 'Keine Verbindung zum Server!',
  'general.no_customdialog_create': 'Benutzerdefinierten Dialog erstellen.',
  'general.no_customdialog_defined': 'Kein benutzerdefinierter Dialog vorhanden.',
  'general.no_data_found': 'Keine Daten gefunden.',
  'general.no_entry_selected': 'Kein Eintrag ausgewählt.',
  'general.no_matching_records': 'Keine zutreffenden Einträge gefunden.',
  'general.no_options_available': 'Keine Optionen verfügbar',
  'general.no_result_data': 'Keine Ergebnisdaten',
  'general.no_selected_files': 'Keine Datei(en) ausgewählt',
  'general.node': 'Knoten',
  'general.node_modal_title': 'Knoten',
  'general.node_parent_nodes': 'Hauptknoten',
  'general.none': 'Keine',
  'general.none_keiner': 'Keiner',
  'general.not_equal': 'Ungleich',
  'general.nothing': 'Keine',
  'general.object_type': 'Objekttyp',
  'general.objects': 'Objekte',
  'general.of': 'von',
  'general.only_job_errors': 'Nur fehlerhafte Jobs',
  'general.open_row': 'Zeile öffnen',
  'general.operator': 'Operator',
  'general.optical_disk': 'Optische Platte',
  'general.options': 'Optionen',
  'general.or': 'Oder',
  'general.order': 'Reihenfolge',
  'general.order_date': 'Bestelldatum',
  'general.order_id': 'Auftrags ID',
  'general.output': 'Ausgabe',
  'general.output_file': 'Ausgabedatei',
  'general.output_type': 'Ausgabetyp',
  'general.owner': 'Besitzer',
  'general.page': 'Seite',
  'general.pages': 'Seiten',
  'general.page_first': 'Erste Seite',
  'general.page_last': 'Letzte Seite',
  'general.parameter': 'Parameter',
  'general.parent_name': 'Vorgängername',
  'general.parent_node': 'Hauptknoten',
  'general.parent_node_id': 'Hauptknoten-ID',
  'general.parm': 'Parm',
  'general.partial': 'Teilweise',
  'general.pattern': 'Muster',
  'general.pcj_pjl': 'PCL/PJL',
  'general.pdf': 'PDF',
  'general.pending': 'Ausstehend',
  'general.pink': 'Rosa',
  'general.please_enter_a_valid_date': 'Bitte geben Sie ein gültiges Datum an.',
  'general.please_enter_a_valid_time': 'Bitte geben Sie eine gültige Zeit an',
  'general.please_enter_search_pattern': 'Bitte geben Sie ein Suchmuster ein.',
  'general.please_enter_tle_name': 'Bitte geben Sie einen TLE Namen ein.',
  'general.point': 'Punkt',
  'general.position': 'Position',
  'general.preferences_saved_session': 'Benutzereinstellungen für aktuelle Sitzung gespeichert',
  'general.preview_error': 'Fehler in Konfiguration. Rendern nicht möglich.',
  'general.preview_tab': 'Vorschau',
  'general.print_all': 'Drucken mit Standardeinstellungen',
  'general.print_priority': 'Druckpriorität',
  'general.printed': 'Gedruckt',
  'general.profile_mask': 'Profilmaske',
  'general.queue': 'Queue',
  'general.range': 'Bereich',
  'general.read_in_date': 'Einlesedatum',
  'general.recipient': 'Empfänger',
  'general.recipient_modal_title': 'Empfänger',
  'general.red': 'Rot',
  'general.refreshtime_in_seconds': 'Aktualisierungsrate in Sekunden',
  'general.relation': 'Relation',
  'general.relative': 'Relativ',
  'general.relative_to': 'Relativ zum',
  'general.reload': 'Neu laden',
  'general.report': 'Report',
  'general.report_prefix': 'Reportpräfix',
  'general.request_all': 'Alle mit Standardeinstellungen anfordern',
  'general.request_all_current_settings': 'Alle mit aktuellen Einstellungen verarbeiten',
  'general.request_queued': 'Anfrage erstellt',
  'general.request_result': 'Abfrageergebnis',
  'general.request_status': 'Request status',
  'general.requestor': 'Auftraggeber',
  'general.required': 'Pflichtfeld',
  'general.required_field': 'erforderlich',
  'general.reset': 'Zurücksetzen',
  'general.retention_day_or_month_has_to_be_greater_than_zero': 'Tag oder Monat muss größer sein als 0',
  'general.retention_period': 'Aufbewahrungsfrist',
  'general.retention_period_days': 'Aufbewahrungsfrist (Tage)',
  'general.retry': 'Wiederholt',
  'general.return_code': 'Rückgabewert',
  'general.reuse': 'Wiederverwenden',
  'general.revoked': 'Gesperrt',
  'general.right': 'Rechts',
  'general.rule': 'Regel',
  'general.run_as': 'Ausgeführt als',
  'general.run_count': 'Laufnummer',
  'general.run_number': 'Laufnummer',
  'general.run_user': 'Ausführende Person',
  'general.runtime': 'Laufzeit',
  'general.s_column': 'S-Spalte',
  'general.s_line': 'S-Zeile',
  'general.s_page': 'S-Seite',
  'general.save': 'Speichern',
  'general.save_as_iso88591': 'Speichern als ISO-8859-1',
  'general.save_as_utf8': 'Speichern als UTF-8',
  'general.search': 'Suchen',
  'general.search_pattern_end': 'Suchmusterende',
  'general.search_pattern_start': 'Suchmusterstart',
  'general.search_results': 'Suchergebnisse',
  'general.search_text': 'Suchtext',
  'general.search_window': 'Suchfenster',
  'general.searchpattern': 'Suchmuster',
  'general.searchpattern_end': 'Suchmusterende',
  'general.searchpattern_start': 'Suchmusteranfang',
  'general.search.date_field.launch': 'Ausführung',
  'general.search.date_field.start': 'Start',
  'general.search.date_field.end': 'Ende',
  'general.search.header.agent': 'Agent',
  'general.search.header.date_field': 'Datumsfeld',
  'general.search.header.execution_user': 'Ausführende Person',
  'general.search.header.exit_code': 'Rückgabewert',
  'general.search.header.filter_by_job_errors': 'Filtern nach fehlerhaften Jobs',
  'general.search.header.job_group': 'Jobgruppe',
  'general.search.header.job_name': 'Jobname',
  'general.search.header.job_status': 'Status',
  'general.search.header.job_type': 'Jobtyp',
  'general.search.header.launch': 'Ausführung',
  'general.search.header.runtime': 'Laufzeit',
  'general.search.header.service': 'Service',
  'general.search.header.services': 'Services',
  'general.search.header.source_version': 'Ursprüngliche Version',
  'general.search.header.status': 'Jobstatus',
  'general.search.header.system': 'System',
  'general.search.header.workflow': 'Workflow',
  'general.search.label.additional': 'Zusätzlich',
  'general.search.label.job_data': 'Jobdaten',
  'general.search.label.select_by': 'Auswahl nach',
  'general.search.label.time': 'Zeit',
  'general.search_by_index': 'Indexsuche',
  'general.search_by_index.operator_and': 'UND',
  'general.search_by_index.operator_or': 'ODER',
  'general.search_by_index.search_criteria': 'Suchkriterien',
  'general.search_by_index.search_results': 'Suchergebnisse',
  'general.sec': 'SEC',
  'general.security': 'Sicherheit',
  'general.select': 'Auswählen',
  'general.select_custom_dialog': 'Benutzerdefinierten Dialog auswählen',
  'general.select_document': 'Dokument auswählen',
  'general.select_index': 'Index auswählen',
  'general.select_list': 'Auswahlliste',
  'general.select_priority': 'Priorität auswählen',
  'general.selected': 'ausgewählt',
  'general.selected_documents': 'Ausgewählte Dokumente',
  'general.selected_files': 'Dateien ausgewählt',
  'general.server': 'Server',
  'general.service': 'Service',
  'general.session_expired': 'Die Sitzung ist abgelaufen, bitte melden Sie sich erneut an',
  'general.settings': 'Einstellungen',
  'general.show_nop': 'NOP anzeigen',
  'general.show_page_header': 'Seitentitel anzeigen',
  'general.show_pjl': 'PJL anzeigen',
  'general.show_resources': 'Ressourcen anzeigen',
  'general.show_tle': 'TLE anzeigen',
  'general.skip': 'Überspringen',
  'general.skipped': 'Übersprungen',
  'general.snmp_status': 'SNMP Status',
  'general.snmp_status_action': 'SNMP-Status Aktion',
  'general.source': 'Quelle',
  'general.source_version': 'Ursprüngliche Version',
  'general.spath': 'Spath',
  'general.specific': 'Spezifisch',
  'general.spinner_error': 'Notwendig: Wert > 0',
  'general.standard': 'Standard',
  'general.start': 'Start',
  'general.start_date': 'Startdatum',
  'general.start_sequenz': 'Start Sequenz',
  'general.state': 'Status',
  'general.statistic': 'Statistiken',
  'general.status': 'Status',
  'general.stop': 'Stoppen',
  'general.string': 'Zeichenkette',
  'general.sub_application': 'Unteranwendung',
  'general.submit_date': 'Einstelldatum',
  'general.subpool': 'Subpool',
  'general.successful': 'Erfolgreich',
  'general.suffix': 'Suffix',
  'general.sum': 'Summe',
  'general.system': 'System',
  'general.system_name': 'Systemname',
  'general.table': 'Table',
  'general.table_header': 'Tabellenkopf',
  'general.tape': 'Band',
  'general.target': 'Ziel',
  'general.text': 'Text',
  'general.text_documents': 'Textdokumente',
  'general.time': 'Zeit',
  'general.time_string': 'Zeit-String',
  'general.time_string_format': 'Zeit-Zeichenkette Format',
  'general.timemask': 'Zeitformat',
  'general.title': 'Titel',
  'general.title_type': 'Titeltyp',
  'general.titlefromcol': 'Titel von Spalte',
  'general.tle': 'TLE',
  'general.to': 'Bis',
  'general.to_column': 'Bis Spalte',
  'general.to_gen': 'Bis Generation',
  'general.to_line': 'Bis Zeile',
  'general.to_page': 'Bis Seite',
  'general.to_row': 'Bis Zeile',
  'general.today': 'Heute',
  'general.torquise': 'Türkis',
  'general.total': 'Total',
  'general.type': 'Typ',
  'general.type_case': 'Typ/Case',
  'general.unit': 'Einheit',
  'general.unmark_archive': 'Zur Archivierung entmarkieren',
  'general.unmark_delete': 'Zum Löschen entmarkieren',
  'general.unmark_reload': 'Neuladen abbrechen',
  'general.unselect': 'Deselektieren',
  'general.update': 'Aktivieren',
  'general.userOrGroup': 'Nutzer/Gruppen',
  'general.usermanagement': 'Benutzerverwaltung',
  'general.utf8': 'UTF-8',
  'general.valid_from': 'Gültig ab',
  'general.valid_to': 'Gültig bis',
  'general.value': 'Wert',
  'general.values': 'Werte',
  'general.variables': 'Variablen',
  'general.variant': 'Variante',
  'general.verify': 'Prüfen',
  'general.verify_error': 'Keine Referenzen gefunden!',
  'general.version': 'Version / API-Version',
  'general.visualization': 'Visualisierung',
  'general.waiting': 'Wartend',
  'general.width': 'Breite',
  'general.yellow': 'Gelb',
  'general.yes': 'Ja',
  'general.yesterday': 'Gestern',
  'group.assignedUsers': 'Zugeordnete Benutzer',
  'group.available_users': 'Verfügbare Benutzer',
  'group.group': 'Gruppe',
  'group.group_id': 'Gruppen-ID',
  'group.groupname': 'Gruppenname',
  'group.groups': 'Gruppen',
  'group.searchUsers': 'Benutzer-ID suchen',
  'header.logout': 'Abmelden',
  'header.logout_error': 'Fehler beim Abmelden ($0)',
  'header.show_help': 'Hilfe anzeigen',
  'import.annotation': 'Anmerkung',
  'import.autoprint': 'Ausgabe sofort',
  'import.copies': 'Kopien',
  'import.custom_import': 'Benutzerdefinierter Import',
  'import.editicon': 'Bearbeitungssymbol',
  'import.editicon_clip': 'Büroklammer',
  'import.editicon_error': 'Fehler',
  'import.editicon_hook': 'Haken',
  'import.editicon_pin': 'Pin',
  'import.editicon_stamp': 'Stempel',
  'import.enc_cp1250': 'CP1250',
  'import.enc_cp1251': 'CP1251',
  'import.enc_cp1252': 'CP1252',
  'import.enc_cp1253': 'CP1253',
  'import.enc_cp1254': 'CP1254',
  'import.enc_cp1256': 'CP1256',
  'import.enc_cp850': 'CP850',
  'import.enc_ibm0420': 'IBM0420',
  'import.enc_ibm1140': 'IBM1140',
  'import.enc_ibm1141': 'IBM1141',
  'import.enc_ibm1147': 'IBM1147',
  'import.enc_ibm1148': 'IBM1148',
  'import.enc_iso8859_1': 'ISO8859-1',
  'import.enc_iso8859_15': 'ISO8859-15',
  'import.enc_iso8859_2': 'ISO8859-2',
  'import.enc_utf_8': 'UTF-8',
  'import.encoding': 'Kodierung',
  'import.fileformat_ascii_utf_8': 'Dokument kodiert in ASCII/UTF-8 mit MVS CC',
  'import.fileformat_ebcdic_nl': 'Dokument kodiert in EBCDIC mit NL = 0x25',
  'import.fileformat_ebcdic_nl_mvscc': 'Dokument kodiert in EBCDIC mit NL = 0x25 und MVS CC',
  'import.fileformat_ebcdic_nl_mvscc_trc': 'Dokument kodiert in EBCDIC with NL = 0x25 und MVS CC + TRC',
  'import.fileformat_fileextension': 'Dokumenttyp entspricht Dateinamenerweiterung',
  'import.import_dialogs': 'Import-Dialoge',
  'import.import_mode': 'Import-Modus',
  'import.import_success': 'Dateien importiert',
  'import.index_file_no_unique_match': 'Eine Index-Datei hat keine eindeutige Zuordnung',
  'import.no_files_selected_error': 'Es muss mindestens eine Datei ausgewählt werden',
  'import.outputchannel': 'Ausgabekanal',
  'import.outputformat': 'Ausgabeformat',
  'import.select_files': 'Datei(en) auswählen',
  'import.special_file_format': 'Spezielles Dateiformat',
  'import.standard_import': 'Standard Import',
  'index.please_enter_an_identifier': 'Bitte geben Sie einen Bezeichner an',
  'index.please_enter_an_index_id': 'Bitte geben Sie eine Index-ID an',
  'infile_required_and_cannot_be_deleted': 'INFILE ist erforderlich und kann nicht gelöscht werden',
  'input.input_does_not_match_to_validation': 'Die Eingabe stimmt nicht mit der Validierung überein!',
  'job.class': 'Klasse',
  'job.dd_name': 'DD Name',
  'job.ds_id': 'Ds-ID',
  'job.dsid': 'DS ID',
  'job.end_date': 'Job Enddatum',
  'job.filter_by_job_errors': 'Filtern nach fehlerhaften Jobs',
  'job.high_cc': 'High CC',
  'job.job_data': 'Job Daten',
  'job.jobid': 'Job-ID',
  'job.jobname1': 'Jobname 1',
  'job.jobname2': 'Jobname 2',
  'job.jobname3': 'Jobname 3',
  'job.jobname4': 'Jobname 4',
  'job.jobtype': 'Job-Typ',
  'job.log_files': 'Log-Dateien',
  'job.messageclass': 'Nachrichtenklasse',
  'job.netid': 'Net-ID',
  'job.netid1': 'Net-ID 1',
  'job.netid2': 'Net-ID 2',
  'job.only_job_errors': 'Nur fehlerhafte Jobs',
  'job.proc_step': 'Prozessschritt',
  'job.return_code': 'Job Rückgabewert',
  'job.start_date': 'Job Startdatum',
  'job.step_name': 'Schrittname',
  'job.step_return_code': 'Schritt Rückgabewert',
  'job.submit_date': 'Job Erstelldatum',
  'job.submit_time': 'Job Erstellzeit',
  'job.submit_user': 'Einstellender Benutzer',
  'job.submitted': 'Eingestellt',
  'job.timestamp': 'Jobzeitstempel',
  'language.en': 'Englisch',
  'language.fr': 'Französisch',
  'language.de': 'Deutsch',
  'language.english': 'Englisch',
  'language.french': 'Französisch',
  'language.german': 'Deutsch',
  'language.language_selection': 'Sprachauswahl',
  'loginscreen.apiversion_loading_error': 'Fehler beim Laden der REST-API Versionsinformationen ($0)',
  'loginscreen.backend_loading_error': 'Fehler beim Laden der Servernamen ($0)',
  'loginscreen.change_password': 'Passwort ändern',
  'loginscreen.display_password': 'Passwort anzeigen',
  'loginscreen.hide_password': 'Passwort verbergen',
  'loginscreen.login': 'Anmelden',
  'loginscreen.login_error': 'Fehler beim Anmelden ($0)',
  'loginscreen.new_password': 'Neues Passwort',
  'loginscreen.no_backend_systems': 'Keine Backend-Systeme konfiguriert',
  'loginscreen.no_systemname_specified': 'Lokales Standardsystem',
  'loginscreen.password_not_equal_to_new_password': 'Passwort stimmt mit Neues Passwort nicht überein!',
  'loginscreen.password_successfully_changed': 'Passwort erfolgreich geändert',
  'loginscreen.please_enter_your_new_password': 'Bitte geben Sie Ihr neues Passwort ein',
  'loginscreen.please_enter_your_password': 'Bitte geben Sie Ihr Passwort ein',
  'loginscreen.please_enter_your_user_data': 'Bitte geben Sie Ihre Benutzerdaten ein',
  'loginscreen.please_enter_your_username': 'Bitte geben Sie Ihren Benutzernamen ein',
  'loginscreen.repeat_new_password': 'Neues Passwort wiederholen',
  'loginscreen.show_password': 'Passwort anzeigen',
  'loginscreen.systemname': 'Systemname',
  'logoutdialog.question_save_session_values': 'Wollen Sie ihre Sitzungswerte speichern?',
  'logoutdialog.save_session_values': 'Sitzungswerte speichern',
  'logsource_required_and_cannot_be_deleted': 'LOGSOURCE ist erforderlich und kann nicht gelöscht werden',
  'lpdqueue.completion_check': 'Abschlussprüfung',
  'lpdqueue.copy_lpd_queue_same_id_error': 'LPD-Warteschlangen-ID muss geändert werden',
  'lpdqueue.enable_spooling': 'Spooling aktivieren',
  'lpdqueue.enable_transfer': 'Transfer aktivieren',
  'lpdqueue.modal_title_copy': 'LPD-Warteschlange kopieren',
  'lpdqueue.modal_title_create': 'LPD-Warteschlange erstellen',
  'lpdqueue.modal_title_modify': 'LPD-Warteschlange bearbeiten',
  'lpdqueue.please_enter_a_lpdqueue_id': 'Bitte geben Sie eine LPD-Warteschlangen-ID an',
  'lpdqueue.please_enter_a_program_path': 'Bitte geben Sie einen Programmpfad an',
  'lpdqueue.transfer': 'Transfer',
  'lpdqueue.transfer_arguments': 'Argumente',
  'lpdqueue.transfer_arguments_desc_buxip': 'BUXIP - Beta UX IP-Adresse (buximport.exe)',
  'lpdqueue.transfer_arguments_desc_buxport': 'BUXPORT - Beta UX Port (buximport.exe)',
  'lpdqueue.transfer_arguments_desc_lpcf': 'LPCF - Kontrolldatei (Pfad-/Dateiname)',
  'lpdqueue.transfer_arguments_desc_lpdf': 'LPDF - Transfer-Datei (Pfad-/Dateiname)',
  'lpdqueue.transfer_arguments_desc_lpid': 'LPID - Job-ID des aktuellen Request (IP-hex.job-nnn)',
  'lpdqueue.transfer_arguments_desc_lpqn': 'LPQN - Warteschlangenname',
  'lpdqueue.transfer_arguments_desc_title': 'Verfügbare Variablen für \'Argumente\'',
  'lpdqueue.transfer_betaend': 'BETAend',
  'lpdqueue.transfer_buximport': 'BUX-Import',
  'lpdqueue.transfer_other': 'Andere',
  'lpdqueue.transfer_program': 'Transfer-Programm',
  'lpdqueue.transfer_program_path': 'Transfer-Programmpfad',
  'maintenance.jobs_error': 'Fehler beim Laden der vordefinierten Jobs ($0)',
  'maintencance.execute_job_error': 'Fehler beim Ausführen des Jobs "$0" ($1)',
  'maintencance.execute_utility_error': 'Fehler beim Ausführen der Utility "$0" ($1)',
  'maintencance.get_joboutput_error': 'Fehler beim Laden der Jobausgabe für PID "$0" ($1)',
  'messagelogs.message_logs_error': 'Fehler beim Laden der Meldungen ($0)',
  'nosearch.description': 'Sie haben noch keine Suche ausgeführt',
  'nosearch.no_result_data': 'Kein Ergebnis vorhanden',
  'preferences.load_error': 'Fehler beim Laden der Benutzereinstellungen ($0)',
  'preferences.save_error': 'Fehler beim Speichern der Benutzereinstellungen ($0)',
  'print.skip_disabled': 'Alle mit Standardeinstellungen drucken ist aktiviert',
  'queue.auto_print': 'Automatischer Druck',
  'queue.automatically_requeued': 'Automatisch umgeleitet',
  'queue.batch_bundle': 'Stapelbündeln',
  'queue.batch_print': 'Stapeldruck',
  'queue.btoken': 'BToken',
  'queue.bundle': 'Bündelaufträge',
  'queue.bundle_name': 'Bündelname',
  'queue.bundle_queue_error': 'Fehler beim Laden der Bündelaufträge ($0)',
  'queue.bundlejobname': 'Bündel Jobname',
  'queue.delete_bundle_queue_error': 'Fehler beim Löschen des Bündelauftrags ($0)',
  'queue.delete_bundle_queue_success': 'Bündelauftrag "$0" gelöscht',
  'queue.delete_bundle_request': 'Bündelauftrag löschen',
  'queue.delete_index_queue_error': 'Fehler beim Löschen des Indizierungsauftrags ($0)',
  'queue.delete_index_queue_success': 'Indizierungsauftrag gelöscht ($0)',
  'queue.delete_index_request': 'Indizierungsauftrag löschen',
  'queue.delete_reload_queue_error': 'Fehler beim Löschen des Rückladeauftrags "$0" ($1)',
  'queue.delete_reload_queue_success': 'Rückladeauftrag "$0" gelöscht',
  'queue.delete_reload_request': 'Rückladeauftrag löschen',
  'queue.deprecated_value_ouputqueue_application': 'Veralteter Konfigurationswert erkannt. Neuselektion notwendig.',
  'queue.documentimported': 'Dokument importiert',
  'queue.error_message': 'Fehlermeldung',
  'queue.get_bundle_queue_details_error': 'Fehler beim Laden des Bündelauftrages "$0" ($1)',
  'queue.get_index_queue_details_error': 'Fehler beim Laden des Indizierungsauftrag "$0" ($1)',
  'queue.get_reload_bundle_details_error': 'Fehler beim Laden des Bündelauftrages "$0" ($1)',
  'queue.get_reload_queue_details_error': 'Fehler beim Laden des Rückladeauftrages "$0" ($1)',
  'queue.hold_bundle_queue_error': 'Fehler beim Anhalten des Bündelauftrags "$0" ($1)',
  'queue.hold_bundle_queue_success': 'Bündelauftrag angehalten ($0)',
  'queue.index': 'Indizierungsaufträge',
  'queue.index_information': 'Informationen Indizierungsauftrag',
  'queue.index_queue_error': 'Fehler beim Laden der Indexaufträge ($0)',
  'queue.index_rerun_error': 'Fehler beim Wiederholen des Indexauftrags ($0)',
  'queue.index_rerun_success': 'Wiederholen des Indizierungsauftrag erfolgreich ausgeführt (DOCID: "$0")',
  'queue.insert_index_request_error': 'Fehler beim Erstellen des Indexauftrags ($0)',
  'queue.insert_index_request_success': 'Indexauftrag erfolgreich eingefügt.',
  'queue.insert_printbundle_request_success': 'Druck-/Bündelauftrag erfolgreich eingefügt.',
  'queue.insert_rescan_request_success': 'Scanauftrag erfolgreich eingefügt.',
  'queue.lpd': 'LPD-Aufträge',
  'queue.lpd_queue_command_error': 'Error on running the command on LPD queue',
  'queue.lpd_queue_rerun_request': 'Rerun LPD queue request',
  'queue.lpd_queue_remove_request': 'Remove LPD queue request',
  'queue.manually_requeued': 'Manuell umgeleitet',
  'queue.online_print': 'Online-Druck',
  'queue.output': 'Ausgabeaufträge',
  'queue.output_channel_if_not_specified_or_inactive': 'Ausgangskanal, wenn nicht angegeben oder inaktiv',
  'queue.output_information': 'Informationen Ausgabeauftrag',
  'queue.output_queue_cancel_error': 'Fehler beim Abbrechen des Auftrags (Ausgabekanal-ID: "$0") ($1)',
  'queue.output_queue_cancel_success': 'Auftrag abgebrochen (Ausgabekanal-ID: "$0")',
  'queue.output_queue_details_error': 'Fehler beim Laden der Ausgabeauftragsdetails ($0)',
  'queue.output_queue_error': 'Fehler beim Laden der Ausgabeaufträge ($0)',
  'queue.output_queue_hold_error': 'Fehler beim Halten des Auftrags (Ausgabekanal-ID: "$0") ($1)',
  'queue.output_queue_hold_success': 'Auftrag gehalten (Ausgabekanal-ID: "$0")',
  'queue.output_queue_mark_as_delete_error': 'Fehler beim zum Löschen markieren des Auftrags (Ausgabekanal-ID: "$0") ($1)',
  'queue.output_queue_mark_as_delete_success': 'Auftrag zum Löschen markiert (Ausgabekanal-ID: "$0")',
  'queue.output_queue_modify_priority_error': 'Fehler beim Modifizieren der Priorität (Ausgabekanal-ID: "$0")',
  'queue.output_queue_modify_priority_success': 'Priorität modifiziert (Ausgabekanal-ID: "$0")',
  'queue.output_queue_monitor_request_error': 'Fehler beim Ausführen der Abfrage ($0)',
  'queue.output_queue_release_error': 'Fehler beim Freigeben des Auftrags (Ausgabekanal-ID: "$0") ($1)',
  'queue.output_queue_release_request': 'Auftrag freigeben',
  'queue.output_queue_release_success': 'Auftrag freigegeben (Ausgabekanal-ID: "$0")',
  'queue.output_queue_request_step_details_error': 'Fehler beim Laden der Ausgabeauftragsdetails ($0)',
  'queue.output_queue_requeue_error': 'Fehler beim Umleiten des Ausgabewarteschlangen-Auftrags (Ausgabekanal-ID: "$0") ($1)',
  'queue.output_queue_requeue_success': 'Ausgabewarteschlangen-Auftrag erfolgreich umgeleitet (Ausgabekanal-ID: "$0", Priorität: "$1")',
  'queue.output_queue_rerun_error': 'Fehler beim Ausführen des Ausgabewarteschlangen-Auftrags (Ausgabekanal-ID: "$0") ($1)',
  'queue.output_queue_rerun_success': 'Ausgabewarteschlangen-Auftrag erfolgreich ausgeführt (Ausgabekanal-ID: "$0", Priorität: "$1")',
  'queue.output_status_hold': 'Anhalten',
  'queue.outputchannel_id_document': 'Ausgabekanal-ID/Dokument',
  'queue.pages': 'Seiten',
  'queue.print_begin': 'Druckbeginn',
  'queue.print_bundle': 'Drucken/Bündeln',
  'queue.print_end': 'Druckende',
  'queue.print_information': 'Druckinformationen',
  'queue.priority': 'Priorität',
  'queue.question_delete_bundle_request': 'Wollen Sie diesen Bündelauftrag wirklich löschen?',
  'queue.question_delete_index_request': 'Wollen Sie diesen Indizierungsauftrag wirklich löschen?',
  'queue.question_delete_reload_request': 'Wollen Sie diesen Rückladeauftrag wirklich löschen?',
  'queue.queuedby': 'Hinzugefügt von',
  'queue.release': 'Freigeben',
  'queue.release_bundle_queue_error': 'Fehler beim Freigeben des Bündelauftrags "$0" ($1)',
  'queue.release_bundle_queue_success': 'Bündelauftrag freigegeben ($0)',
  'queue.reload': 'Rückladeaufträge',
  'queue.reload_information': 'Informationen Rückladeauftrag',
  'queue.reload_queue_error': 'Fehler beim Laden der Rückladeaufträge ($0)',
  'queue.requestqueued': 'Anfrage erstellt',
  'queue.requeue': 'Umleiten',
  'queue.requeue_output_queue_request': 'Umleiten der Ausgabekanalanfrage',
  'queue.requeued': 'Umgeleitet',
  'queue.rerun': 'Wiederholen',
  'queue.rerun_index_queue_request': 'Wiederholen des Indizierungsauftrags',
  'queue.rerun_options': 'Wiederholungsoptionen',
  'queue.rerun_output_queue_request': 'Ausgabewarteschlangen-Auftrag erneut ausführen',
  'queue.reset_bundle_queue_error': 'Fehler beim Zurücksetzen des Bündelauftrags "$0" ($1)',
  'queue.reset_bundle_queue_success': 'Bündelauftrag zurückgesetzt ($0)',
  'queue.show_information': 'Informationen anzeigen',
  'queue.snmp': 'SNMP',
  'queue.specific_priority': 'Spezifische Priorität',
  'queue.spooling_begin': 'Spooling Start',
  'queue.spooling_end': 'Spooling Ende',
  'queue.spooling_failed': 'Spooling Fehler',
  'queue.srq': 'SRQ',
  'queue.status': 'Status',
  'queue.transfer_begin': 'Transfer Start',
  'queue.transfer_end': 'Transfer Ende',
  'queue.transfer_failed': 'Transfer Fehler',
  'queue.waiting_for_autoprint': 'Waiting for autoprint',
  'queue.which_output_channel_to_use': 'Welcher Ausgabekanal soll genutzt werden',
  'queues.get_bundles_error': 'Fehler beim Laden der Bündel Queue ($0)',
  'queues.only_status_hold': 'Nur Status halten',
  'queues.output_queue_error_check_status': 'Fehler beim Überprüfen vom Server Status ($0)',
  'queues.output_queue_ipp_check_status': 'Status prüfen',
  'queues.output_queue_ipp_request_result': 'Abfrageergebnis',
  'queues.output_queue_ipp_server_status': 'IPP-Server Status',
  'queues.output_queue_ipp_status_action': 'IPP-Status Aktion',
  'queues.output_queue_ipp_verbose_output': 'Ausführliche AUsgabe',
  'queues.output_queue_server_attributes': 'Attribute',
  'queues.output_queue_server_job_information': 'Job Informationen',
  'queues.output_queue_server_status': 'Jobstatus',
  'queues.output_queue_status': 'Status',
  'queues.output_queue_validate_job': 'Job überprüfen',
  'recipient.additional': 'Zusätzlich',
  'recipient.address': 'Adresse',
  'recipient.address_tab_address_text': 'Anschrift',
  'recipient.address_tab_email_address': 'E-Mail Adresse',
  'recipient.banner': 'Deckblatt',
  'recipient.banner_tab_banner_file_name': 'Datei für',
  'recipient.banner_tab_banner_file_name_banner': 'Deckblatt',
  'recipient.banner_tab_banner_file_name_trailer': 'Endeblatt',
  'recipient.banner_tab_banner_text': 'Deckblatt',
  'recipient.banner_tab_banner_text_banner_text_1': 'Banner Text 1',
  'recipient.banner_tab_banner_text_banner_text_2': 'Banner Text 2',
  'recipient.banner_tab_banner_text_banner_text_3': 'Banner Text 3',
  'recipient.banner_tab_banner_text_banner_text_4': 'Banner Text 4',
  'recipient.banner_tab_banner_text_banner_text_5': 'Banner Text 5',
  'recipient.banner_tab_banner_text_banner_text_6': 'Banner Text 6',
  'recipient.banner_tab_banner_text_banner_text_7': 'Banner Text 7',
  'recipient.banner_tab_banner_text_banner_text_8': 'Banner Text 8',
  'recipient.banner_tab_print_control_file_name': 'Datei für Druckersteuerung',
  'recipient.copy_recipient_same_recipient_id': 'Empfänger-ID muss geändert werden',
  'recipient.delete_recipient': 'Empfänger löschen',
  'recipient.distribute_all': 'Alle',
  'recipient.distribute_any': 'Jeden',
  'recipient.distribute_autop': 'Autom. Druck',
  'recipient.distribute_bundle': 'Bündeln',
  'recipient.distribute_online': 'Online',
  'recipient.distribution_via': 'Verteilen via',
  'recipient.general': 'Allgemein',
  'recipient.general_tab_predecessor_id': 'Vorgänger-ID',
  'recipient.modal_title_copy': 'Empfänger kopieren',
  'recipient.modal_title_create': 'Empfänger erstellen',
  'recipient.modal_title_modify': 'Empfänger bearbeiten',
  'recipient.output_channel': 'Ausgabekanal',
  'recipient.output_format': 'Ausgabeformat',
  'recipient.owner': 'Besitzer',
  'recipient.please_enter_a_banner_text_1': 'Bitte geben Sie einen Text an',
  'recipient.please_enter_a_recipientid': 'Bitte geben Sie eine Empfänger-ID an',
  'recipient.postprocessing_note': 'Nachbereitungsnotiz',
  'recipient.predecessor': 'Vorgänger',
  'recipient.predecessor_id': 'Vorgänger-ID',
  'recipient.print': 'Drucken',
  'recipient.print_tab_print_banner_and_trailer': 'Deck- und Endeblätter drucken',
  'recipient.print_tab_separate_recipient_banner_trailer_for_online_autoprint': 'Separates Empfängerdeckblatt/-endeblatt bei Online- und autom. Druck',
  'recipient.print_tab_suppress_document_banner_trailer_for_bundling': 'Dokumentdeckblatt/-endeblatt beim Bündeln unterdrücken',
  'recipient.print_tab_suppress_postprocessing_note_in_packets': 'Nachbereitungsnotiz in Paketen unterdrücken',
  'recipient.print_tab_suppress_recipient_banner_trailer_for_bundling': 'Empfängerdeckblatt/-endeblatt beim Bündeln unterdrücken',
  'recipient.print_tab_suppress_trailers': 'Endeblätter unterdrücken',
  'recipient.question_delete_recipient': 'Wollen Sie diesen Empfänger wirklich löschen?',
  'recipient.reci_id': 'Empfänger-ID',
  'recipient.switch_button.print_banner_and_trailer.no': 'Nicht drucken',
  'recipient.switch_button.print_banner_and_trailer.once': 'Einmal drucken',
  'recipient.switch_button.print_banner_and_trailer.twice': 'Zweimal drucken',
  'recipient.type': 'Typ',
  'recipient.type_addr': 'Adresse',
  'recipient.type_alias': 'Alias',
  'recipient.type_any': 'Jeden',
  'recipient.type_reci': 'Empfänger',
  'remoteuser.betaux_user_id': 'BetaUX Benutzer-ID',
  'remoteuser.ip4mask': 'IPv4-Maske',
  'remoteuser.ip6mask': 'IPv6-Maske',
  'remoteuser.ipmask': 'IP-Maske',
  'remoteuser.unixuser': 'Unix Benutzer',
  'rerun.auto_bundle_process_for_new_reports': 'Bündelungsprozess für neue Berichte',
  'rerun.auto_print_for_new_reports': 'Automatischer Druck für neue Berichte',
  'rerun.for_filter': 'Für Filter',
  'rerun.for_recipient': 'Für Empfänger',
  'rerun.for_report': 'Für Bericht',
  'rerun.force_auto_print': 'Automatischer Druck erzwingen',
  'rerun.process_to_rerun': 'Prozess zum Wiederholen',
  'rerun.scan': 'Scannen',
  'rerun.skip_disabled': 'Alle mit Standardeinstellungen anfordern ist aktiviert',
  'search.archived': 'Archiviert',
  'search.autoprint': 'Sofortige Ausgabe',
  'search.b93ux': 'B93UX',
  'search.control': 'Control',
  'search.controlm': 'Control-M',
  'search.controlm_loading_error': 'Fehler beim Laden der Control-M Jobs ($0)',
  'search.controlstatus': 'Kontrollstatus',
  'search.controluser': 'Kontrollbenutzer',
  'search.ctm_details_error': 'Fehler beim Laden der Informationen für Control-M Job "$0" ($1)',
  'search.custom_search': 'Benutzerdefinierte Suche',
  'search.documentstatus': 'Dokumentstatus',
  'search.edf': 'EDF',
  'search.editing_icon': 'Bearbeitungssymbol',
  'search.filtered_by_rc': 'Nach Rückgabewert filtern',
  'search.hold': 'Anhalten',
  'search.list': 'Liste',
  'search.marked_for_delete': 'Zum Löschen markiert',
  'search.marked_for_reload': 'Zum Rückladen markiert',
  'search.multiple_documents_were_marked_for_archive': 'Dokumente wurden zum Archivieren markiert.',
  'search.multiple_documents_were_marked_for_delete': 'Dokumente wurden zum Löschen markiert.',
  'search.multiple_documents_were_marked_for_reload': 'Dokumente wurden zum Rückladen markiert.',
  'search.multiple_documents_were_unmarked_for_archive': 'Dokumente wurden zum Archivieren entmarkiert.',
  'search.multiple_documents_were_unmarked_for_delete': 'Dokumente wurden zum Löschen entmarkiert.',
  'search.multiple_documents_were_unmarked_for_reload': 'Dokumente wurden zum Rückladen entmarkiert.',
  'search.multiple_logs_were_marked_for_archive': 'Logs wurden zum Archivieren markiert.',
  'search.multiple_logs_were_marked_for_delete': 'Logs wurden zum Löschen markiert.',
  'search.multiple_logs_were_marked_for_reload': 'Logs wurden zum Rückladen markiert.',
  'search.mvs': 'MVS',
  'search.notes': 'Notizen',
  'search.obtained': 'Quelle',
  'search.online': 'Online',
  'search.outputstatus': 'Ausgabestatus',
  'search.outputstatus_active': 'Aktiv (A)',
  'search.outputstatus_canceled': 'Abgebrochen (C)',
  'search.outputstatus_delete': 'Gelöscht (D)',
  'search.outputstatus_error': 'Fehler (E)',
  'search.outputstatus_hold': 'Gehalten (H)',
  'search.outputstatus_notfound': 'Nicht gefunden (N)',
  'search.outputstatus_printed': 'Gedruckt (P)',
  'search.outputstatus_requeued': 'Umgeleitet (Q)',
  'search.outputstatus_retry': 'Wiederholt (R)',
  'search.outputstatus_waiting': 'Eingereiht (W)',
  'search.preview_loading_error': 'Fehler beim Laden der Vorschaudaten ($0)',
  'search.question_delete_from_archive': 'Sind Sie sicher, dass Sie das Dokument entgültig from Archiv löschen wollen?',
  'search.question_delete_from_archive_log': 'Sind Sie sicher, dass Sie das Log entgültig from Archiv löschen wollen?',
  'search.reader_date': 'Einlesedatum',
  'search.reload': 'Neu laden',
  'search.rescan': 'Erneut scannen',
  'search.search_dialog': 'Suchdialoge',
  'search.search_mode': 'Suchmodus',
  'search.select_by': 'Auswahl nach',
  'search.standard_search': 'Standardsuche',
  'search.standard_search_listtimestamp': 'Ltoken',
  'search.standard_search_reporttimestamp': 'Rtoken',
  'search.stonebranch': 'Stonebranch',
  'search.stonebranch_details_error': 'Fehler beim Laden der Informationen für Stonebranch Job "$0" ($1)',
  'search.stonebranch_logs_loading_error': 'Fehler beim Laden der Logs für Stonebranch Job "$0" ($1)',
  'search.syslog': 'Syslog',
  'search.syslog_details_error': 'Fehler beim Laden der Informationen für Syslog Job "$0" ($1)',
  'search.syslog_loading_error': 'Fehler beim Laden der Syslog jobs ($0)',
  'search.syslog_logs_loading_error': 'Fehler beim Laden der Logs für Syslog Job "$0" ($1)',
  'search.uc4': 'UC4',
  'search.uc4_archive': 'UC4ARC',
  'search.uc4_archive_key_1': 'Archivschlüssel 1',
  'search.uc4_archive_key_2': 'Archivschlüssel 2',
  'search.uc4_details_error': 'Fehler beim Laden der Informationen für UC4 Job "$0" ($1)',
  'search.uc4_loading_error': 'Fehler beim Laden der UC4 Jobs ($0)',
  'search.uc4_online': 'UC4ONL',
  'search.unhold': 'Freigeben',
  'search.zos': 'z/OS',
  'search.zos_details_error': 'Fehler beim Laden der Informationen für zOS Job "$0" ($1)',
  'search.zos_details_information_error': 'Fehler beim Laden der Loginformationen für Log ($0)',
  'search.zos_job_information': 'Job Information',
  'search.zos_loading_error': 'Fehler beim Laden der ZOS jobs ($0)',
  'search.zos_logs_loading_error': 'Fehler beim Laden der Logs für zOS Job "$0" ($1)',
  'search_argument.please_enter_id': 'Bitte geben Sie eine Suchargument-ID ein.',
  'search_argument.please_enter_report': 'Bitte geben Sie eine Suchargument Report ein.',
  'security.access': 'Zugriff',
  'security.access_alter': 'ÄNDERN',
  'security.access_none': 'KEINEN',
  'security.access_read': 'LESEN',
  'security.access_update': 'AKTUAL.', // TODO: FIXME: Bessere Übersetzung finden: Update vs Alter
  'security.class': 'Klasse',
  'security.convert_to_upper': 'In Großbuchstaben umwandeln',
  'security.copy_security_profile': 'Sicherheitsprofil kopieren',
  'security.create_security_profile': 'Sicherheitsprofil anlegen',
  'security.delete_security': 'Sicherheitsprofil löschen',
  'security.description': 'Beschreibung',
  'security.entities': 'Entitäten',
  'security.entity_generation_rules': 'Regeln zur Entitätserzeugung',
  'security.function': 'Funktion',
  'security.modify_security_profile': 'Sicherheitsprofil ändern',
  'security.please_enter_a_userid_or_groupid': 'Bitte geben Sie einen Benutzer- oder Gruppenname ein',
  'security.profile_category': 'Profilkategorie',
  'security.profile_entry': 'Profileintrag',
  'security.profile_entry_*': '**  - Alle Profileinträge',
  'security.profile_entry_*.**': '*.** - Alle Sicherheitsprofile',
  'security.profile_entry_adm': 'ADM - Wartung und Stapelverarbeitung',
  'security.profile_entry_arc': 'ARC - Zum Archivieren markieren',
  'security.profile_entry_ard': 'ARD - Archiv löschen',
  'security.profile_entry_bcm': 'BCM - Control-M-Jobs ansehen',
  'security.profile_entry_bju': 'BJU - UC4-Jobs ansehen',
  'security.profile_entry_bjz': 'BJZ - zOS-Jobs ansehen',
  'security.profile_entry_bna': 'BNA - Bündel für Empfänger (Typ Adresse)',
  'security.profile_entry_bnu': 'BNU - Bündel für Empfänger (Typ Empfänger)',
  'security.profile_entry_bra': 'BRA - Dokumente aus Archiv anzeigen',
  'security.profile_entry_brw': 'BRW - Dokumente aus Spool anzeigen',
  'security.profile_entry_bsb': 'BSB - Stonebranch-Jobs ansehen',
  'security.profile_entry_bsl': 'BSL - SYSLOG-Jobs ansehen',
  'security.profile_entry_del': 'DEL - Zum Löschen markieren',
  'security.profile_entry_dfa': 'DFA - Adresse definieren',
  'security.profile_entry_dfd': 'DFD - Ausgabekanal definieren',
  'security.profile_entry_dff': 'DFF - Ordner definieren',
  'security.profile_entry_dfh': 'DFH - Dokumenthiearchie definieren',
  'security.profile_entry_dfi': 'DFI - Indexname definieren',
  'security.profile_entry_dfl': 'DFL - Dokumente Ordnern zuordnen',
  'security.profile_entry_dfn': 'DFN - Nachbearbeitungsnotiz definieren',
  'security.profile_entry_dfp': 'DFP - Ausgabeformat definieren',
  'security.profile_entry_dfr': 'DFR - Dokumente definieren',
  'security.profile_entry_dfs': 'DFS - Suchdialoge definieren',
  'security.profile_entry_dft': 'DFT - Ergebnistabellen definieren',
  'security.profile_entry_dfu': 'DFU - Empfänger definieren',
  'security.profile_entry_dfx': 'DFX - Dokument-Indizies definieren',
  'security.profile_entry_dst': 'DST - Status bearbeiten',
  'security.profile_entry_egr': 'EGR - Dokument-Generation bearbeiten',
  'security.profile_entry_free': 'Freie Eingabe',
  'security.profile_entry_gln': 'GLN - Ordner auswählen',
  'security.profile_entry_hex': 'HEX - Hexadezimale Dokumentenansicht',
  'security.profile_entry_imm': 'IMM - Import Mimetype',
  'security.profile_entry_imp': 'IMP - Listen importieren',
  'security.profile_entry_nte': 'NTE - Browser-Notizen zu Listen oder Reports hinzufügen',
  'security.profile_entry_pcr': 'PCR - Dokumente drucken',
  'security.profile_entry_pra': 'PRA - Dokumente aus Archiv drucken',
  'security.profile_entry_prf': 'PRF - Dokumente aus Ordner drucken',
  'security.profile_entry_prq': 'PRQ - Druckaufträge anzeigen',
  'security.profile_entry_prt': 'PRT - Dokumente aus Spool drucken',
  'security.profile_entry_rld': 'RLD - Zum Rückladen markieren',
  'security.profile_entry_rli': 'RLI - Sofort Rückladen',
  'security.profile_entry_rsc': 'RSC - Online Reports erneut scannen',
  'security.profile_entry_rsi': 'RSI - Dokumente indizieren',
  'security.profile_entry_scm': 'SCM - Control-M-Jobs auswählen',
  'security.profile_entry_sec': 'SEC - Sicherheitseinstellungen bearbeiten',
  'security.profile_entry_sfd': 'SFD - Subadministrator: Ausgabekanal auswählen',
  'security.profile_entry_sfh': 'SFH - Dokumentenhierarchie auswählen',
  'security.profile_entry_sfp': 'SFP - Subadministrator: Ausgabeformat auswählen',
  'security.profile_entry_sfr': 'SFR - Subadministrator: Ausgabeempfänger auswählen',
  'security.profile_entry_shw': 'SHW - Empfänger auswählen',
  'security.profile_entry_sju': 'SJU - UC4-Jobs auswählen',
  'security.profile_entry_sjz': 'SJZ - zOS-Jobs auswählen',
  'security.profile_entry_spt': 'SPT - Suchdialog auswählen',
  'security.profile_entry_ssb': 'SSB - Stonebranch-Jobs auswählen',
  'security.profile_entry_ssi': 'SSI - Sicherheitsindex auswählen',
  'security.profile_entry_ssl': 'SSL - SYSLOG-Jobs auswählen',
  'security.profile_entry_sys': 'SYS - Spool administrieren',
  'security.profile_entry_utl': 'UTL - Externe Befehle auswählen',
  'security.profile_mask': 'Profilmaske',
  'security.profile_mask_invalid_input': 'Ungültige Eingabe in der Profilmaske: ',
  'security.profile_type': 'Profiltyp',
  'security.profile_type_admin_functions': 'Administrator-Funktionen',
  'security.profile_type_all_functions': 'Alle Funktionen',
  'security.profile_type_job_functions': 'Job-Funktionen',
  'security.profile_type_security_functions': 'Sicherheits-Funktionen',
  'security.profile_type_subadmin_functions': 'Sub-Administrator-Funktionen',
  'security.profile_type_system_functions': 'System-Funktionen',
  'security.profile_type_user_functions': 'User-Funktionen',
  'security.profiles': 'Sicherheitsprofile',
  'security.question_delete_security': 'Wollen Sie das Sicherheitsprofil wirklich löschen?',
  'security.user_group': 'Benutzer/Gruppe',
  'security.user_group_id': 'Benutzer-/Gruppenname',
  'security_profiles.get_placeholders_error': 'Fehler beim Laden der Sicherheitsprofil Platzhalter ($0)',
  'server.accounting': 'Accounting',
  'server.accounting.acc_date': 'Datensatzerstellungsdatum',
  'server.accounting.acc_time': 'Datensatzerstellungszeit',
  'server.accounting.action': 'Aktion (Archivieren, Rückladen, Löschen)',
  'server.accounting.agentname': 'Agent',
  'server.accounting.application': 'Anwendung',
  'server.accounting.archivename': 'Archivdateiname',
  'server.accounting.archiving_document': 'Archivieren eines Dokuments',
  'server.accounting.archiving_reloading_deleting': 'Archivieren, Zurückladen und Löschen von Notizen',
  'server.accounting.att_count': 'Versuchsanzahl',
  'server.accounting.bannerpages': 'Deckblätter',
  'server.accounting.bjobid': 'Bündeljob-ID',
  'server.accounting.bjobname': 'Bündeljobname',
  'server.accounting.bundle_copies': 'Kopien',
  'server.accounting.bundle_print_name_only_print': 'Druck-/Bündelname',
  'server.accounting.bundle_print_name_print/view': 'Druck-/Bündelname oder ONLINE-VIEW',
  'server.accounting.bytes': 'Dateigröße',
  'server.accounting.checkAll': 'Alle verfügbaren Felder in Standardreihenfolge',
  'server.accounting.class': 'Jobklasse',
  'server.accounting.clientname': 'Kunde',
  'server.accounting.copies': 'Kopien',
  'server.accounting.datacenter': 'Datenzentrum',
  'server.accounting.dcr_name': 'Ausgabekanal',
  'server.accounting.dcr_owner': 'DCR Besitzer',
  'server.accounting.dcr_protocol_typ': 'DCR Protokoll',
  'server.accounting.dcr_type': 'Typ',
  'server.accounting.ddname': 'DD Name',
  'server.accounting.deleting_document_archive': 'Löschen eines Dokuments aus dem Archiv',
  'server.accounting.deleting_document_spool': 'Löschen eines Dokuments aus der Spool',
  'server.accounting.delivery_init_date': 'Job delivery start date',
  'server.accounting.delivery_init_time': 'Job delivery start time',
  'server.accounting.description': 'Beschreibung',
  'server.accounting.document_deletion_date': 'Einlesedatum des Dokuments, Dateianhang (Notiz)',
  'server.accounting.document_deletion_time': 'Einlesezeit des Dokuments, Dateianhang (Notiz)',
  'server.accounting.document_id': 'Dokument-ID',
  'server.accounting.end_date': 'Enddatum',
  'server.accounting.end_time': 'Endzeit',
  'server.accounting.expiration_date': 'Verfallsdatum',
  'server.accounting.field': 'Feld-ID',
  'server.accounting.filetype': 'Dateityp',
  'server.accounting.flag': 'Anmelde-/Abmelde-Flag',
  'server.accounting.folder': 'Ordner',
  'server.accounting.idx_cmd': 'IDX-CMD',
  'server.accounting.indexhits': 'Anzahl der Indextreffer',
  'server.accounting.indexies': 'Indexanzahl',
  'server.accounting.indexing_document': 'Indexierung eines Dokuments',
  'server.accounting.input_date': 'Einlesedatum des Dokuments',
  'server.accounting.input_time': 'Einlesezeit des Dokuments',
  'server.accounting.ip_client': 'Client IP',
  'server.accounting.ip_server': 'Server IP',
  'server.accounting.job_end_date': 'Job Abschlussdatum',
  'server.accounting.job_end_time': 'Job Abschlusszeit',
  'server.accounting.job_id': 'Job ID',
  'server.accounting.job_start_date': 'Job Startdatum',
  'server.accounting.job_start_time': 'Job Startzeit',
  'server.accounting.job_submit_date': 'Job Übermittlungsdatum',
  'server.accounting.job_submit_time': 'Job Übermittlungszeit',
  'server.accounting.jobgroup': 'Jobgruppe',
  'server.accounting.jobname': 'Jobname',
  'server.accounting.log_in/out_user': 'Ein-/Ausloggen eines Benutzers',
  'server.accounting.net_id': 'Net ID',
  'server.accounting.number_of_notes': 'Notizenanzahl',
  'server.accounting.obtained_uc4': 'Enthalten: UC4ARC, UC4ONL',
  'server.accounting.obtained_zos': 'Enthalten: ZOS, ZOSMIG',
  'server.accounting.order_date': 'Auftragsdatum',
  'server.accounting.orderid': 'Auftrag-ID',
  'server.accounting.output_id': 'Ausgabe-ID',
  'server.accounting.outputting_document': 'Ausgabe eines Dokuments (drucken und anzeigen)',
  'server.accounting.owner': 'Besitzer',
  'server.accounting.pages': 'Seiten',
  'server.accounting.pages_estimated': 'Geschätzte Seiten', // ! fehlende Übersetzung in BUX
  'server.accounting.print/bundle_document': 'Drucken/Bündeln eines Dokuments, Ausgabekanal IP/T, Protokoll Typ PJL',
  'server.accounting.read_document': 'Einlesen eines Dokuments (einschließlich Reload)',
  'server.accounting.recipient': 'Empfänger',
  'server.accounting.reload_date': 'Rückladedatum des Dokuments',
  'server.accounting.reload_time': 'Rückladezeit des Dokuments',
  'server.accounting.retention_period': 'Verweilzeit',
  'server.accounting.returned': 'Zurückgegeben',
  'server.accounting.runcount': 'Laufnummer',
  'server.accounting.runnumber': 'Laufnummer',
  'server.accounting.select_date/index': 'Auswahl nach Zeit/Index',
  'server.accounting.select_records': 'Accounting-Datensätze auswählen',
  'server.accounting.selectaccountingrecords': 'Accounting-Datensätze auswählen',
  'server.accounting.selected': 'Ausgewählt',
  'server.accounting.spool_blocks': 'Anzahl Spoolblöcke',
  'server.accounting.start_date': 'Startdatum',
  'server.accounting.start_time': 'Startzeit',
  'server.accounting.sublevel': 'Sublevel',
  'server.accounting.submit_date': 'Job-Einstelldatum',
  'server.accounting.submit_time': 'Job-Einstellzeit',
  'server.accounting.submit_user': 'Einstellender Benutzer',
  'server.accounting.sysid': 'System ID',
  'server.accounting.systemname': 'System',
  'server.accounting.type': 'Typ',
  'server.accounting.type050.title': 'Einloggen/Ausloggen eines Benutzers',
  'server.accounting.type100.title': 'Einlesen eines Dokuments (einschließlich Reload)',
  'server.accounting.type101.title': 'Ausgabe eines Dokuments (drucken und anzeigen)',
  'server.accounting.type102.title': 'Indexierung eines Dokuments',
  'server.accounting.type103.title': 'Drucken/Bündeln eines Dokuments,Ausgabekanal IP/T, Protokoll Typ PJL',
  'server.accounting.type104.title': 'Auswahl nach Zeit / Index',
  'server.accounting.type105.title': 'Löschen eines Dokuments aus der Spool',
  'server.accounting.type110.title': 'Archivieren eines Dokuments',
  'server.accounting.type120.title': 'Löschen eines Dokuments aus dem Archiv',
  'server.accounting.type130.title': 'Archivieren, Zurückladen und Löschen von Notizen',
  'server.accounting.type200.title': 'Einlesen eines zOS Job logs',
  'server.accounting.type210.title': 'Einlesen eines UC4 Jobs',
  'server.accounting.type220.title': 'Einlesen eines Control-M Jobs',
  'server.accounting.type230.title': 'Einlesen eines Stonebranch Jobs',
  'server.accounting.user': 'Benutzerkennung',
  'server.accounting.user_log_in/out_date': 'Anmeldedatum',
  'server.accounting.user_log_in/out_time': 'Anmeldezeit',
  'server.accounting_load_file_error': 'Datei ist keine gültige Accounting-Datei',
  'server.accounting_opened_Accounting_file': 'Geöffnete Accounting-Datei',
  'server.archive_file_archive_pending': 'Archivierung ausstehend',
  'server.archive_file_information': 'Archivdatei Informationen',
  'server.archive_file_level_2': 'Level 2',
  'server.archive_file_managed_by': 'Dateiverwaltung mittels',
  'server.archive_file_management': 'Archivdateiverwaltung',
  'server.archive_file_objects_close': 'Schließen',
  'server.archive_file_objects_f_list': 'F-List',
  'server.archive_file_objects_f_page': 'F-Page',
  'server.archive_file_objects_f_pos': 'F-Pos',
  'server.archive_file_objects_l_page': 'L-Page',
  'server.archive_file_objects_lfnd_no': 'Lfnd-No',
  'server.archive_file_objects_list_no': 'List-No',
  'server.archive_file_objects_obj_len': 'Obj-Len',
  'server.archive_file_objects_obj_no': 'Obj-No',
  'server.archive_file_objects_order': 'Order',
  'server.archive_file_objects_rec_no': 'Rec-No',
  'server.archive_files': 'Archivdateien',
  'server.external_command_executed': '"$0" wurde erfolgreich ausgeführt',
  'server.external_command_executed_counted': '$0 externe Befehle wurden erfolgreich ausgeführt',
  'server.external_command_executed_fail': 'Fehler beim Ausführen des externen Befehls "$0" ($1)',
  'server.get_archive_file_error': 'Fehler beim Laden der Archivdatei "$0" ($1)',
  'server.get_archive_objects_error': 'Fehler beim Laden der Archivobjekte der Datei "$0" ($1)',
  'server.job_executed_fail': 'Fehler beim Ausführen des Jobs "$0" ($1)',
  'server.jobs_executed_counted': '$0 Jobs wurden erfolgreich ausgeführt',
  'server.load_accounting_file': 'Lade Accounting-Datensätze',
  'server.log_file': 'Serverprotokolldatei',
  'server.log_file_error': 'Fehler beim Laden der Serverprotokolldatei ($0)',
  'server.lpd_status': 'LPD Status',
  'server.message_id': 'Nachricht-ID',
  'server.output_processes': 'Ausgabeprozesse',
  'server.output_processes_error': 'Fehler beim Laden der Ausgabeprozesse ($0)',
  'server.print_err': 'Print err',
  'server.print_ok': 'Print ok',
  'server.printer_device_information': 'Druckerinformationen',
  'server.printer_device_information_error': 'Fehler beim Laden der Druckerinformationen',
  'server.request_active': 'Anfragen aktiv',
  'server.request_max': 'Anfragen max',
  'server.spool_err': 'Spool err',
  'server.spool_ok': 'Spool ok',
  'server.system_defaults': 'Systemeinstellungen',
  'server.trans_err': 'Trans err',
  'server.trans_ok': 'Trans ok',
  'server_activity': 'Serveraktivität',
  'server_activity.active_users': 'Aktive Nutzer',
  'server_activity.active_users_application': 'Applikation',
  'server_activity.active_users_ip': 'IP',
  'server_activity.active_users_logon_time': 'Anmeldezeit',
  'server_activity.active_users_pages': 'Seiten',
  'server_activity.active_users_user_id': 'Nutzer-ID',
  'server_activity.backend_encrypted': 'Serverkommunikation verschlüsselt',
  'server_activity.backend_unencrypted': 'Serverkommunikation unverschlüsselt',
  'server_activity.components': 'Komponenten',
  'server_activity.components_component': 'Komponente',
  'server_activity.components_ip': 'IP',
  'server_activity.components_lastRequest': 'Letzte Abfrage',
  'server_activity.components_port': 'Port',
  'server_activity.components_process_id': 'Process-ID',
  'server_activity.components_programm': 'Programm',
  'server_activity.components_work_directory': 'Arbeitsverzeichnis',
  'server_activity.connection_time': 'Verbindungszeitpunkt',
  'server_activity.dependencies': 'Abhängigkeiten',
  'server_activity.dependencies_application': 'Anwendung',
  'server_activity.dependencies_client_ip': 'Client-IP',
  'server_activity.dependencies_client_process_id': 'BQL Client PID',
  'server_activity.dependencies_function': 'Funktion',
  'server_activity.dependencies_last_request': 'Letzte Abfrage',
  'server_activity.dependencies_process_id': 'BQL Server PID',
  'server_activity.dependencies_user': 'Nutzer',
  'server_activity.enqueues': 'Enqueues',
  'server_activity.enqueues_dequeues': 'Dequeues',
  'server_activity.enqueues_enqueues': 'Enqueues',
  'server_activity.enqueues_name': 'Name',
  'server_activity.enqueues_process_id': 'Prozess-ID',
  'server_activity.enqueues_semaphore_ncnt': 'Semaphore NCNT',
  'server_activity.enqueues_semaphore_process_id': 'Semaphore PID',
  'server_activity.enqueues_semaphore_value': 'Semaphore Wert',
  'server_activity.enqueues_stack': 'Stack',
  'server_activity.search_component': 'Search component',
  'server_activity.search_dependencie': 'Search dependency',
  'server_activity.search_enqueue': 'Suche Enqueue',
  'server_activity.search_transaction': 'Suche Transaktion',
  'server_activity.search_user': 'Suche Nutzer',
  'server_activity.servername': 'Server-Name',
  'server_activity.status': 'Status',
  'server_activity.status_bql_gets': 'BQL Gets',
  'server_activity.status_bql_puts': 'BQL Puts',
  'server_activity.status_bsa_release': 'BSA Release',
  'server_activity.status_bsa_release_date': 'BSA Releasedatum',
  'server_activity.status_current_proc': 'Aktuelle Prozesse',
  'server_activity.status_deq': 'Dequeues',
  'server_activity.status_enq': 'Enqueues',
  'server_activity.status_forks': 'Forks',
  'server_activity.status_ip_recv': 'IP/Empfangen',
  'server_activity.status_ip_send': 'IP/Gesendet',
  'server_activity.status_locks': 'Locks',
  'server_activity.status_port': 'Port',
  'server_activity.status_proc': 'Aktive / Max Prozesse',
  'server_activity.status_recv_in_byte': 'Empfangen in Byte',
  'server_activity.status_send_in_byte': 'Gesendet in Byte',
  'server_activity.status_server_start': 'Serverstart',
  'server_activity.status_start_pid': 'PID (buxstart.exe)',
  'server_activity.status_unlocks': 'Unlocks',
  'server_activity.status_users': 'Nutzer | Remote-Nutzer',
  'server_activity.timeshift_to_server': 'Zeitverschiebung zum Server',
  'server_activity.transactions': 'Transaktionen',
  'server_activity.transactions_dequeues': 'Dequeues',
  'server_activity.transactions_enqueues': 'Enqueues',
  'server_activity.transactions_function': 'Funktion',
  'server_activity.transactions_gets': 'Gets',
  'server_activity.transactions_lock': 'Lock',
  'server_activity.transactions_memory': 'Speicher',
  'server_activity.transactions_process_id': 'PID',
  'server_activity.transactions_puts': 'Puts',
  'server_activity.transactions_shared': 'Geteilt Speicher',
  'server_activity.transactions_state': 'Status',
  'server_activity.transactions_tcp_received_bytes': 'TCP empfangene Bytes',
  'server_activity.transactions_tcp_received_packages': 'TCP empfangene Pakete',
  'server_activity.transactions_tcp_send_bytes': 'TCP gesendete Bytes',
  'server_activity.transactions_tcp_send_packages': 'TCP gesendete Pakete',
  'server_activity.transactions_unlock': 'Unlock',
  'snackbar.no_matches': 'Keine Einträge entsprechen Ihren Suchkriterien',
  'standard_selection.download_as_hex': 'Als HEX-Datei runterladen',
  'standard_selection.download_as_hex_format': 'Herunterladen als HEX-Format',
  'standard_selection.download_as_side_file': 'Side file runterladen',
  'standard_selection.sidefile': 'Sidefile',
  'standard_selection.list_time_stamp_error': 'Hexadezimale Eingabe mit Länge 16 erforderlich',
  'standardselection.delete_from_archive_error': 'Fehler beim Markieren zum Löschen des Dokumentes "$0" ($1) aus dem Archiv',
  'standardselection.delete_from_archive_error_log': 'Fehler beim Markieren zum Löschen des Logs "$0" ($1) aus dem Archiv',
  'standardselection.delete_from_archive_success': 'Dokument ($0) zum Löschen aus dem Archiv markiert',
  'standardselection.delete_from_archive_success_log': 'Log ($0) zum Löschen aus dem Archiv markiert',
  'standardselection.mark_archive_error': 'Fehler beim Markieren zum Archivieren des Dokumentes "$0" ($1)',
  'standardselection.mark_archive_error_log': 'Fehler beim Markieren zum Archivieren des Logs "$0" ($1)',
  'standardselection.mark_archive_success': 'Dokument ($0) zum Archivieren markiert',
  'standardselection.mark_archive_success_log': 'Log ($0) zum Archivieren markiert',
  'standardselection.mark_delete_error': 'Fehler beim Markieren zum Löschen des Dokumentes "$0" ($1)',
  'standardselection.mark_delete_error_log': 'Fehler beim Markieren zum Löschen des Logs "$0" ($1)',
  'standardselection.mark_delete_success': 'Dokument ($0) zum Löschen markiert',
  'standardselection.mark_delete_success_log': 'Log ($0) zum Löschen markiert',
  'standardselection.mark_reload_error': 'Fehler beim Markieren zum Nachladen des Dokumentes "$0" ($1)',
  'standardselection.mark_reload_error_log': 'Fehler beim Markieren zum Nachladen des Logs "$0" ($1)',
  'standardselection.mark_reload_success': 'Dokument ($0) zum Nachladen markiert',
  'standardselection.mark_reload_success_log': 'Log ($0) zum Nachladen markiert',
  'standardselection.unmark_archive_error': 'Fehler beim Entmarkieren zum Archivieren des Dokumentes "$0" ($1)',
  'standardselection.unmark_archive_error_log': 'Fehler beim Entmarkieren zum Archivieren des Logs "$0" ($1)',
  'standardselection.unmark_archive_success': 'Dokument ($0) zum Archivieren entmarkiert',
  'standardselection.unmark_archive_success_log': 'Log ($0) zum Archivieren entmarkiert',
  'standardselection.unmark_delete_error': 'Fehler beim Entmarkieren zum Löschen des Dokumentes "$0" ($1)',
  'standardselection.unmark_delete_error_log': 'Fehler beim Entmarkieren zum Löschen des Logs "$0" ($1)',
  'standardselection.unmark_delete_success': 'Dokument ($0) zum Löschen entmarkiert',
  'standardselection.unmark_delete_success_log': 'Log ($0) zum Löschen entmarkiert',
  'standardselection.unmark_reload_error': 'Fehler beim Entmarkieren zum Nachladen des Dokumentes "$0" ($1)',
  'standardselection.unmark_reload_error_log': 'Fehler beim Entmarkieren zum Nachladen des Logs "$0" ($1)',
  'standardselection.unmark_reload_success': 'Dokument ($0) zum Nachladen entmarkiert',
  'standardselection.unmark_reload_success_log': 'Log ($0) zum Nachladen entmarkiert',
  'statistic.activeusers_error': 'Fehler beim Laden der aktiven Benutzer-Statistik ($0)',
  'statistic.bsastats_date': 'Datum',
  'statistic.bsastats_documents_indexed': 'Dokumente indexiert',
  'statistic.bsastats_documents_total': 'Dokumente Gesamt',
  'statistic.bsastats_error': 'Fehler beim Laden der BSA-Statistik ($0)',
  'statistic.bsastats_jobs_total': 'Jobs Gesamt',
  'statistic.bsastats_logs_indexed': 'Logs indiziert',
  'statistic.bsastats_logs_total': 'Logs Gesamt',
  'statistic.bsastats_pages_archived': 'Seiten archiviert',
  'statistic.bsastats_pages_in': 'Seiten eingelesen',
  'statistic.bsastats_pages_out': 'Seiten ausgelesen',
  'statistic.chart_barchart_hor': 'Balkendiagramm (Horizontal)',
  'statistic.chart_barchart_vert': 'Balkendiagramm (Vertikal)',
  'statistic.chart_linechart': 'Liniendiagramm',
  'statistic.charttypes': 'Diagrammtypen',
  'statistic.components_error': 'Fehler beim Laden der Komponenten-Statistik ($0)',
  'statistic.connects_error': 'Fehler beim Laden der Verbindungs-Statistik ($0)',
  'statistic.documents_indexed': 'Dokumente Indiziert',
  'statistic.documents_total': 'Dokumente Gesamt',
  'statistic.enqueues_error': 'Fehler beim Laden der Warteschlangen-Statistik ($0)',
  'statistic.jobs_total': 'Jobs Gesamt',
  'statistic.logs_indexed': 'Logs indiziert',
  'statistic.logs_total': 'Logs Gesamt',
  'statistic.logx': 'log|x',
  'statistic.negative_date_difference_error': 'Das ausgewählte Datum darf nicht vor dem anderen Datum liegen.',
  'statistic.pages_and_docs': 'Seiten & Dokumente',
  'statistic.pages_archived': 'Archivierte Seiten',
  'statistic.pages_in': 'Importierte Seiten',
  'statistic.pages_out': 'Exportierte Seiten',
  'statistic.serverstatus_error': 'Fehler beim Laden der Serverstatus-Statistik ($0)',
  'statistic.transactions_error': 'Fehler beim Laden der Transaktions-Statistik ($0)',
  'statistic.unit_day': 'Tag',
  'statistic.unit_month': 'Monat',
  'statistic.unit_recommendation': '(empfohlen)',
  'statistic.unit_year': 'Jahr',
  'systemdefaults.bql_trace': 'BQL_TRACE',
  'systemdefaults.bql_trace_error': 'BQL_TRACE_ERROR',
  'systemdefaults.bux_command_trace': 'BUX_COMMAND_TRACE',
  'systemdefaults.bux_doc_selection_by_lri_glr': 'BUX_DOC_SELECTION_BY_LRI_GLR',
  'systemdefaults.index_prio_default': 'INDEX_PRIO_DEFAULT',
  'systemdefaults.index_prio_import': 'INDEX_PRIO_IMPORT',
  'systemdefaults.index_prio_online': 'INDEX_PRIO_ONLINE',
  'systemdefaults.index_prio_rdrbux': 'INDEX_PRIO_RDR_BUX',
  'systemdefaults.index_prio_rdredf': 'INDEX_PRIO_RDREDF',
  'systemdefaults.index_prio_rdrmvs': 'INDEX_PRIO_RDRMVS',
  'systemdefaults.job_group_trace_exec': 'JOB_GROUP_TRACE_EXEC',
  'systemdefaults.job_group_trace_init': 'JOB_GROUP_TRACE_INIT',
  'systemdefaults.modify_success': 'Parameter $0 bearbeitet zu $1',
  'systemdefaults.modify_systemdefaults': 'Systemeinstellungen bearbeiten',
  'systemdefaults.not_changeable': 'Dieser Parameter kann nur in der serverseitigen Konfiguration geändert werden.',
  'systemdefaults.rdr_generate_new_ldr': 'RDR_GENERATE_NEW_LDR',
  'systemdefaults.rdr_ldr_required': 'RDR_LDR_REQUIRED',
  'systemdefaults.reload_auto_threshold': 'RELOAD_AUTO_THRESHOLD',
  'systemdefaults.reload_use_threshold': 'RELOAD_USE_THRESHOLD',
  'systemdefaults.security_level': 'SECURITY_LEVEL',
  'systemdefaults.security_log': 'SECURITY_LOG',
  'systemdefaults.security_trace': 'SECURITY_TRACE',
  'systemdefaults.set_system_default_error': 'Fehler beim Ändern der Systemeinstellung "$0" ($1)',
  'systemdefaults.system_defaults_error': 'Fehler beim Laden der Systemeinstellungen ($0)',
  'systemdefaults.tcp_trace_intern': 'TCP_TRACE_INTERN',
  'systemdefaults.tcp_trace_recv': 'TCP_TRACE_RECV',
  'systemdefaults.tcp_trace_send': 'TCP_TRACE_SEND',
  'table.change_table_settings': 'Tabelleneinstellungen ändern',
  'table.create': 'Erstellen',
  'table.default_table_settings': 'Voreinstellung',
  'table.download_as_csv': 'Als CSV-Datei herunterladen',
  'table.download_as_pdf': 'Als PDF-Datei herunterladen',
  'table.fill_screen': 'Bildschirmfüllend',
  'table.filter_table': 'Tabelle filtern',
  'table.help': 'Hilfe',
  'table.next_page': 'Nächste',
  'table.of': 'von',
  'table.prev_page': 'Vorherige Seite',
  'table.refresh': 'Aktualisieren',
  'table.settings': 'Einstellungen',
  'table.settings_500_per_page': '500 pro Seite',
  'table.settings_available_columns': 'Verfügbare Spalten',
  'table.settings_change_error': 'Fehler beim Ändern der Tabelleneinstellungen.',
  'table.settings_change_success': 'Tabelleneinstellungen gespeichert.',
  'table.settings_column_position': 'Sichtbare Spalten (in spezifischer Reihenfolge)',
  'table.settings_fill_screen': 'Seite füllend',
  'table.value_per_page': '$0 pro Seite',
  'table.view': 'Ansicht',
  'tabs.title.close_all': 'Alle Tabs schließen',
  'tabs.confirmation_dialog.title': 'Bestätigung',
  'tabs.confirmation_dialog.message': 'Alle Tabs schließen?',
  'tooltip.tab_disabled_because_of_authorization': 'Aus Sicherheitsgründen ist diese Option nicht verfügbar',
  'unknown_error_message': 'Unbekannte Fehlermeldung! (RC=$0 IRC=$1)',
  'upload.error': 'Fehler beim Hochladen ($0)',
  'user.administrator': 'Administrator',
  'user.assignedGroups': 'Zugewiesene Gruppen',
  'user.external_authentication': 'Extern authentifiziert',
  'user.password': 'Passwort',
  'user.password_confirm': 'Passwort wiederholen',
  'user.password_expired': 'Passwort abgelaufen',
  'user.password_interval': 'Passwortintervall (Tage)',
  'user.privileges': 'Berechtigung',
  'user.remoteusers': 'Externe Benutzer',
  'user.revoked': 'Gesperrt',
  'user.searchGroups': 'Gruppen-ID suchen',
  'user.unassignedGroups': 'Verfügbare Gruppen',
  'user.user': 'Benutzer',
  'user.user_id': 'Benutzer-ID',
  'user.username': 'Benutzername',
  'user.users': 'Benutzer',
  'usermanagement.administrators_only': 'Nur Administratoren',
  'usermanagement.bux_user': 'BetaUX Benutzer-ID',
  'usermanagement.clone_info': 'Alle Gruppen und Sicherheitsprofile von "$0" werden dem neuen Benutzer zugewiesen.',
  'usermanagement.clone_user': 'Benutzer klonen',
  'usermanagement.clone_user_error': 'Fehler beim Klonen des Benutzers "$0" ($1)',
  'usermanagement.clone_user_same_userid_error': 'Benutzer-ID muss geändert werden',
  'usermanagement.clone_user_success': 'Benutzer "$0" hinzugefügt (Klone von Benutzer "$1")',
  'usermanagement.copy_group': 'Gruppe kopieren',
  'usermanagement.copy_group_same_groupid_error': 'Gruppen-ID muss geändert werden',
  'usermanagement.copy_remote_user': 'Externer Benutzer kopieren',
  'usermanagement.copy_remoteuser_same_unixuser_error': 'Unix Benutzer muss geändert werden',
  'usermanagement.copy_user': 'Benutzer kopieren',
  'usermanagement.copy_user_same_userid_error': 'Benutzer-ID muss geändert werden',
  'usermanagement.create_group': 'Gruppe erstellen',
  'usermanagement.create_group_error': 'Fehler beim Erstellen der Gruppe "$0" ($1)',
  'usermanagement.create_group_success': 'Gruppe "$0" erstellt',
  'usermanagement.create_remote_user': 'Externen Benutzer erstellen',
  'usermanagement.create_remote_user_error': 'Fehler beim Erstellen des externen Benutzers $0 ($1)',
  'usermanagement.create_remote_user_success': 'Externe Benutzer "$0" erstellt',
  'usermanagement.create_security_profile_error': 'Fehler beim Erstellen des Sicherheitsprofils "$0" ($1)',
  'usermanagement.create_security_profile_success': 'Sicherheitsprofile "$0" erstellt',
  'usermanagement.create_user': 'Benutzer erstellen',
  'usermanagement.create_user_error': 'Fehler beim Erstellen des Benutzers "$0" ($1)',
  'usermanagement.create_user_group_assignment_error': 'Fehler beim Erstellen der Zuordnung zwischen "$0" und "$1" ($2)',
  'usermanagement.create_user_group_assignment_failed': 'Zuordnung zwischen "$0" und "$1" fehlgeschlagen',
  'usermanagement.create_user_group_assignment_success': 'Zuordnung zwischen "$0" und "$1" erstellt',
  'usermanagement.create_user_success': 'Benutzer "$0" erstellt',
  'usermanagement.create_userprofile_error': 'Fehler beim Erstellen des Benutzerprofiles für den Benutzer "$0" ($1)',
  'usermanagement.create_userprofile_success': 'Benutzerprofil für Benutzer "$0" erstellt',
  'usermanagement.delete_group': 'Gruppe löschen',
  'usermanagement.delete_group_error': 'Fehler beim Löschen der Gruppe "$0" ($1)',
  'usermanagement.delete_group_success': 'Gruppe "$0" mit Gruppennamen "$1" gelöscht',
  'usermanagement.delete_profile': 'Profil löschen',
  'usermanagement.delete_remote_user': 'Externer Benutzer löschen',
  'usermanagement.delete_remote_user_error': 'Fehler beim Löschen des externen Benutzers "$0" ($1)',
  'usermanagement.delete_remote_user_success': 'Externer Benutzer gelöscht (Unix Benutzer: "$0", Benutzer-ID: "$1")',
  'usermanagement.delete_security_profile_error': 'Fehler beim Löschen des Sicherheitsprofils "$0" ($1)',
  'usermanagement.delete_security_profile_success': 'Sicherheitsprofil "$0" gelöscht',
  'usermanagement.delete_user': 'Benutzer löschen',
  'usermanagement.delete_user_error': 'Fehler beim Löschen des Benutzers "$0" ($1)',
  'usermanagement.delete_user_group_assignment_error': 'Fehler beim Löschen der Zuordnung zwischen "$0" und "$1" ($2)',
  'usermanagement.delete_user_group_assignment_failed': 'Löschung der Zuordnung zwischen "$0" und "$1" fehlgeschlagen',
  'usermanagement.delete_user_group_assignment_success': 'Zuordnung zwischen "$0" und "$1" gelöscht',
  'usermanagement.delete_user_success': 'Benutzer "$0" mit Benutzernamen "$1" gelöscht',
  'usermanagement.delete_userprofile': 'Benutzerprofil löschen',
  'usermanagement.delete_userprofile_no_profile_available': 'Der Benutzer "$0" hat kein Benutzerprofil.',
  'usermanagement.delete_userprofile_success': 'Benutzerprofile des Benutzers "$0" gelöscht',
  'usermanagement.external_authenticated_only': 'Nur extern authentifiziert',
  'usermanagement.get_group_assignments_error': 'Fehler beim Laden der Gruppenzuordnungen ($0)',
  'usermanagement.get_group_error': 'Fehler beim Laden der Gruppe "$0" ($1)',
  'usermanagement.get_groups_error': 'Fehler beim Laden der Gruppen ($0)',
  'usermanagement.get_remote_user_error': 'Fehler beim Laden des externen Benutzers "$0" ($1)',
  'usermanagement.get_remote_users_error': 'Fehler beim Laden der externen Benutzer ($0)',
  'usermanagement.get_security_profile_error': 'Fehler beim Laden des Sicherheitsprofiles "$0" ($1)',
  'usermanagement.get_security_profiles_error': 'Fehler beim Laden der Sicherheitsprofile ($0)',
  'usermanagement.get_user_assignments_error': 'Fehler beim Laden der Benutzerzuordnungen ($0)',
  'usermanagement.get_user_error': 'Fehler beim Laden des Benutzers "$0" ($1)',
  'usermanagement.get_users_error': 'Fehler beim Laden der Benutzer ($0)',
  'usermanagement.ipmask_not_valid': 'Eingegebene IP-Maske nicht gültig',
  'usermanagement.modify_group': 'Gruppe bearbeiten',
  'usermanagement.modify_remote_user': 'Externer Benutzer ändern',
  'usermanagement.modify_user': 'Benutzer bearbeiten',
  'usermanagement.new_password': 'Neues Passwort',
  'usermanagement.new_password_confirm': 'Neues Password wiederholen',
  'usermanagement.password_confirm_not_equal_to_password': 'Passwort stimmt nicht mit überein',
  'usermanagement.passwords_not_equal': 'Passwörter stimmen nicht überein',
  'usermanagement.please_enter_a_buxuser': 'Bitte geben Sie eine BetaUX Benutzer-ID ein',
  'usermanagement.please_enter_a_groupid': 'Bitte geben Sie eine Gruppen-ID ein',
  'usermanagement.please_enter_a_ipmask': 'Bitte geben Sie eine IP-Maske ein',
  'usermanagement.please_enter_a_password': 'Bitte geben Sie ein Passwort ein',
  'usermanagement.please_enter_a_unixuser': 'Bitte geben Sie einen Unix Benutzer ein',
  'usermanagement.please_enter_a_userid': 'Bitte geben Sie eine Benutzer-ID ein',
  'usermanagement.question_delete_group': 'Wollen Sie diese Gruppe wirklich löschen?',
  'usermanagement.question_delete_remote_user': 'Wollen Sie wirklich diesen externen Benutzer löschen?',
  'usermanagement.question_delete_user': 'Wollen Sie diesen Benutzer wirklich löschen?',
  'usermanagement.question_delete_userprofile': 'Wollen Sie das Benutzerprofile des folgenden Benutzers wirklich löschen?',
  'usermanagement.refresh_security_profile_error': 'Fehler beim Aktualisieren der Sicherheitsprofile ($1)',
  'usermanagement.refresh_security_profile_success': 'Sicherheitsprofile aktualisiert',
  'usermanagement.refresh_security_profiles_error': 'Fehler beim Aktualisieren der Sicherheitsprofile', // TODO: Does the server-response sends more information why it fails?! Commited for Sprint9-presentation
  'usermanagement.refresh_security_profiles_success': 'Sicherheitsprofile aktualisiert',
  'usermanagement.revoked_users_only': 'Nur gesperrte Benutzer',
  'usermanagement.update_group_error': 'Fehler beim Bearbeiten der Gruppe "$0" ($1)',
  'usermanagement.update_group_success': 'Gruppe "$0" bearbeitet',
  'usermanagement.update_remote_user_error': 'Fehler beim Bearbeiten des externen Benutzers "$0" ($1)',
  'usermanagement.update_remote_user_success': 'Externe Benutzer "$0" bearbeitet',
  'usermanagement.update_security_profile_error': 'Fehler beim Bearbeiten des Sicherheitsprofils "$0" ($1)',
  'usermanagement.update_security_profile_success': 'Sicherheitsprofil "$0" bearbeitet',
  'usermanagement.update_user_error': 'Fehler beim Bearbeiten des Benutzers "$0" ($1)',
  'usermanagement.update_user_success': 'Benutzer "$0" bearbeitet',
  'usermanagement.update_userprofile_error': 'Fehler beim Modifizieren des Benutzerprofiles vom Benutzer "$0" ($1)',
  'usermanagement.update_userprofile_success': 'Benutzerprofile vom Benutzer "$0" bearbeitet',
  'usermanagement.user_subadmin_required': 'Nur als Subadministrator verfügbar',
  'usermanagement.userprofile_basic_tab': 'Allgemein',
  'usermanagement.userprofile_basic_tab.betaview_role': '_beta view - Rolle',
  'usermanagement.userprofile_basic_tab.bundle_queue': 'Bündel-Queue',
  'usermanagement.userprofile_basic_tab.import_dialogs': 'Import-dialoge',
  'usermanagement.userprofile_basic_tab.message_log': 'Meldungsprotokoll',
  'usermanagement.userprofile_basic_tab.modify_archive_request': 'Archivierungsanforderung ändern',
  'usermanagement.userprofile_basic_tab.modify_delete_request': 'Löschanforderung ändern',
  'usermanagement.userprofile_basic_tab.modify_reload_request': 'Rückladeanforderung ändern',
  'usermanagement.userprofile_basic_tab.modify_retention_period': 'Aufbewahrungsfrist ändern',
  'usermanagement.userprofile_basic_tab.modify_status': 'Status ändern',
  'usermanagement.userprofile_basic_tab.output_queue': 'Ausgabe-Queue',
  'usermanagement.userprofile_basic_tab.permissions': 'Berechtigungen',
  'usermanagement.userprofile_basic_tab.printer_device_info': 'Druckerinformation',
  'usermanagement.userprofile_basic_tab.rerun_autoprint': 'Automatisches Drucken erneut ausführen',
  'usermanagement.userprofile_basic_tab.rerun_bundle_request': 'Bündelanforderung erneut ausführen',
  'usermanagement.userprofile_basic_tab.search_dialogs': 'Suchdialoge',
  'usermanagement.userprofile_basic_tab.standard_import': 'Standardimport',
  'usermanagement.userprofile_basic_tab.standard_selection': 'Standardauswahl',
  'usermanagement.userprofile_basic_tab.start_panel': 'Startpanel',
  'usermanagement.userprofile_basic_tab.subadmin': 'Sub-Administrator',
  'usermanagement.userprofile_create': 'Benutzerprofil erstellen',
  'usermanagement.userprofile_import_tab': 'Einlesen',
  'usermanagement.userprofile_import_tab.disabled': 'Gesperrt',
  'usermanagement.userprofile_import_tab.enabled_standard_import': 'Standardimport',
  'usermanagement.userprofile_import_tab.jobname': 'Jobname',
  'usermanagement.userprofile_import_tab.required': 'Erforderlich',
  'usermanagement.userprofile_modify': 'Benutzerprofil bearbeiten',
  'usermanagement.userprofile_other_tab': 'Andere',
  'usermanagement.userprofile_other_tab.disabled': 'Gesperrt',
  'usermanagement.userprofile_other_tab.enable_external_command': 'Externe Befehle',
  'usermanagement.userprofile_other_tab.enable_message_log': 'Meldungen',
  'usermanagement.userprofile_other_tab.enable_monitor': 'Monitor',
  'usermanagement.userprofile_other_tab.required': 'Erforderlich',
  'usermanagement.userprofile_other_tab.select_output_channel': 'Ausgabekanal auswählen',
  'usermanagement.userprofile_outputqueue_tab': 'Ausgabeaufträge',
  'usermanagement.userprofile_outputqueue_tab.disabled': 'Gesperrt',
  'usermanagement.userprofile_outputqueue_tab.enable_output_queue': 'Ausgabeaufträge',
  'usermanagement.userprofile_outputqueue_tab.requeue_output_channel': 'Ausgabekanal umleiten',
  'usermanagement.userprofile_outputqueue_tab.required': 'Erforderlich',
  'usermanagement.userprofile_outputqueue_tab.select_output_channel': 'Ausgabekanal',
  'usermanagement.userprofile_print_tab': 'Drucken',
  'usermanagement.userprofile_print_tab.disabled': 'Gesperrt',
  'usermanagement.userprofile_print_tab.high_prio_print': 'Drucken mit hoher Priorität erlauben',
  'usermanagement.userprofile_print_tab.output_channel': 'Ausgabekanal',
  'usermanagement.userprofile_print_tab.outputformat': 'Ausgabeformat',
  'usermanagement.userprofile_print_tab.postprocessingnote': 'Nachbereitungsnotiz',
  'usermanagement.userprofile_print_tab.print_limit_page': 'Drucklimit (Seiten)',
  'usermanagement.userprofile_print_tab.print_parameter': 'Druckparameter',
  'usermanagement.userprofile_print_tab.print_parameter_default': 'Standard',
  'usermanagement.userprofile_print_tab.print_parameter_user': 'Benutzer',
  'usermanagement.userprofile_print_tab.recipient': 'Empfänger',
  'usermanagement.userprofile_print_tab.required': 'Erforderlich',
  'usermanagement.userprofile_tabs_tooltip_disabled': 'Subadministrator erforderlich',
  'usermanagement.userprofile_view_tab': 'Anzeigen',
  'usermanagement.userprofile_view_tab.disabled': 'Gesperrt',
  'usermanagement.userprofile_view_tab.folder': 'Ordner',
  'usermanagement.userprofile_view_tab.jobname': 'Jobname',
  'usermanagement.userprofile_view_tab.recipient': 'Empfänger',
  'usermanagement.userprofile_view_tab.required': 'Erforderlich',
  'userpreferences.personalize': 'Personalisieren',
  'userpreferences.profile': 'Profil',
  'userpreferences.user_preferences': 'Benutzereinstellungen',
}