import { translate } from 'language/Language'; // Translation
import React, { Component } from 'react'
import './DeleteDialog.scss'


// Components
import {
  Button, Modal as ModalComponent
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent


// Redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'

class DeleteDialog extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleEscape)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscape)
  }

  handleEscape = event => {
    if (event.key === 'Escape') {
      this.props.onClose()
    }
  }

  render = () => {
    return (
      <Modal id={this.props.id} size="xs" className="badm_size_overwriter">
        <Header
          id={this.props.id}
          title={this.props.title}
          onClose={this.props.onClose}
        />
        <Main id={this.props.id}>
          <div className='badm_delete_dialog_container'>
            <label
              id={`${this.props.id}_body_label`}
              className='deleteDialogQuestion'>
              {this.props.question}
            </label>
            <div
              id={`${this.props.id}_body_info`}
              className='deleteDialogTitles'>
              {this.props.children}
            </div>
          </div>
        </Main>
        <Footer id={this.props.id}>
          {
            this.props.verifyDialog
              ? <>
                <Button
                  id={`${this.props.id}_footer_cancel`}
                  text={translate('general.cancel', this.props.lang)}
                  onClick={() => this.props.onClose()} />
                <Button
                  id={`${this.props.id}_footer_delete_force`}
                  text={translate('general.delete_without_verify', this.props.lang)}
                  onClick={() => this.props.onDelete()}
                  primary
                  submit
                  className={'bux_max_content'} />
                <Button
                  id={`${this.props.id}_footer_delete_verify`}
                  text={translate('general.delete_with_verify', this.props.lang)}
                  onClick={() => this.props.onDeleteVerify()}
                  primary
                  submit
                  className={'bux_max_content'} />
              </>
              : <>
                <Button
                  id={`${this.props.id}_footer_cancel`}
                  text={translate('general.cancel', this.props.lang)}
                  onClick={() => this.props.onClose()} />
                <Button
                  id={`${this.props.id}_footer_delete`}
                  text={translate('general.delete', this.props.lang)}
                  onClick={() => this.props.onDelete()}
                  primary
                  submit />
              </>
          }
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

export default connect(mapStateToProps)(DeleteDialog)