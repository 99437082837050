import moment from 'moment'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'

import {LineChart, BarChart, NoSearch, ResultContainer} from 'BetaUX2Web-Components/src/'
import StatisticMenu from 'components/search_result/statistic/statistic_menu/StatisticMenu'

import * as StatisticActions from 'redux/actions/StatisticActions'
import * as Preferences from 'redux/general/Preferences'

class SearchResultStatisticLogx extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired
  }

  /**
   * @description refreshes the statistic dialog with the dates from last search
   */
  handleOnRefresh = () => {
    const { preferences } = this.props
    const fromDate = preferences.STATISTIC_LOGX_FROMDATE
    const toDate = preferences.STATISTIC_LOGX_TODATE
    const unit = preferences.STATISTIC_LOGX_UNIT
    this.props.getLogxBsaStats(fromDate, toDate, unit)
  }

  /**
   * @description returns the searched date for the statistic menu
   */
  dateOutput = () => {
    let { preferences, datemask } = this.props
    if (datemask === undefined) {
      datemask = DateUtils.MMDDYYYY_SLASH
    }
    let fromDate = preferences.STATISTIC_LOGX_FROMDATE
    if (DateUtils.isUnixTimestamp(fromDate)) {
      fromDate = DateUtils.getDateFromUnixTimestamp(fromDate, datemask)
    }
    else {
      fromDate = moment(fromDate, DateUtils.DDMMYYYY_DOT).format(datemask)
    }
    let toDate = preferences.STATISTIC_LOGX_TODATE || ''
    if (toDate !== '') {
      if (DateUtils.isUnixTimestamp(toDate)) {
        toDate = DateUtils.getDateFromUnixTimestamp(toDate, datemask)
      }
      else {
        toDate = moment(toDate, DateUtils.DDMMYYYY_DOT).format(datemask)
      }
    }
    else {
      toDate = DateUtils.formatDate(Date.now(), datemask)
    }
    return {
      from: fromDate,
      to: toDate
    }
  }

  /**
   * @description Gets the translated header for the downloaded csv file
   */
  getStatisticHeader = () => {
    return [
      translate('statistic.bsastats_date'),
      translate('statistic.bsastats_pages_in'),
      translate('statistic.bsastats_pages_out'),
      translate('statistic.bsastats_pages_archived'),
      translate('statistic.bsastats_logs_total'),
      translate('statistic.bsastats_logs_indexed'),
      translate('statistic.bsastats_jobs_total')
    ]
  }

  /**
   * @description formats the data in a new array to display the charts
   * @param {array} datasToFormat The data from bsaLogxStats that should be formatted
   * @param {String} bsaStatType statistic type: pages or documents
   */
  formatData = (datasToFormat, bsaStatType) => {
    const data = []
    datasToFormat.forEach(dataToFormat => {
      if (bsaStatType === 'pages') {
        data.push([dataToFormat[0], dataToFormat[1], dataToFormat[2], dataToFormat[3]])
      }
      else if (bsaStatType === 'logs') {
        data.push([dataToFormat[0], dataToFormat[4], dataToFormat[5]])
      }
      else if (bsaStatType === 'jobs') {
        data.push([dataToFormat[0], dataToFormat[7]])
      }
      else {
        data.push([dataToFormat[0], dataToFormat[1], dataToFormat[2], dataToFormat[3], dataToFormat[4], dataToFormat[5]])
      }
    })
    return data
  }

  /**
   * @description gets the keys which are needed to output the labels on the charts
   * @param {array} header header of the fetched data
   * @param {String} bsaStatType statistic type: pages or documents
   */
  getKeys = (header, bsaStatType) => {
    let keys = []
    if (bsaStatType === 'pages') {
      keys.push(header[1], header[2], header[3])
    }
    else if (bsaStatType === 'logs') {
      keys.push(header[4], header[5])
    } else if (bsaStatType === 'jobs') {
      keys.push(header[7])
    }
    keys = this.translateKeys(keys)
    return keys
  }

  /**
   * @description translates the keys which are used in the legend
   * @param {array} keys the keys which should translated
   */
  translateKeys = keys => {
    const translatedKeys = []

    keys.map(key => {
      switch (key) {
        case keys[keys.indexOf('PAGES_IN')]:
          return translatedKeys.push(translate('statistic.pages_in'))
        case keys[keys.indexOf('PAGES_OUT')]:
          return translatedKeys.push(translate('statistic.pages_out'))
        case keys[keys.indexOf('PAGES_ARCHIVED')]:
          return translatedKeys.push(translate('statistic.pages_archived'))
        case keys[keys.indexOf('JOBLOGS_TOTAL')]:
          return translatedKeys.push(translate('statistic.logs_total'))
        case keys[keys.indexOf('JOBLOGS_INDEXED')]:
          return translatedKeys.push(translate('statistic.logs_indexed'))
        case keys[keys.indexOf('JOBS_TOTAL')]:
          return translatedKeys.push(translate('statistic.jobs_total'))
        default: console.error(`Key <${key}> cannot get translated!`); return null
      }
    })
    return translatedKeys
  }

  chartHidden = () => (
    this.props.preferences.STATISTIC_LOGX_PAGES_CHART === 'hide' ||
    this.props.preferences.STATISTIC_LOGX_LOGS_CHART === 'hide' ||
    this.props.preferences.STATISTIC_LOGX_JOBS_CHART === 'hide'
  )

  /**
   * @description chooses the charts based on chosen charttype in the Chart type input boxes
   * @param {String} preference from redux state to display chart for pages or documents
   * @param {String} bsaStatType statistic type: pages or documents
   * @param {String} id the id for the specific chart
   */
  chooseChart = (preference, bsaStatType, id, height) => {
    const { bsaLogxStats, drawerExpanded, datemask, lang } = this.props
    const data = this.formatData(bsaLogxStats.data, bsaStatType)

    const linechart = <LineChart
      id={id}
      keys={this.getKeys(bsaLogxStats.header, bsaStatType)}
      data={data}
      colors={['#0068b4', '#66ccc9', '#00386e', '#4e5463', '#80bb3d', '#ffc159']}
      curveStyle={'monotonex'}
      dotHover={'vertical'}
      dotStyle={'circle'}
      yAxisScale={'linear'}
      axis={'show'}
      drawerExpanded={drawerExpanded}
      dateFormat={datemask}
      language={lang}
    />

    const barchartVert = <BarChart
      id={id}
      keys={this.getKeys(bsaLogxStats.header, bsaStatType)}
      data={data}
      barPadding={0.1}
      direction={'vertical'}
      barHover={'grouped'}
      showGrid={true}
      colors={['#0068b4', '#66ccc9', '#00386e', '#4e5463', '#80bb3d', '#ffc159']}
      legend={'top'}
      drawerExpanded={drawerExpanded}
      dateFormat={datemask}
      language={lang}
    />

    const barchartHor = <BarChart
      id={id}
      keys={this.getKeys(bsaLogxStats.header, bsaStatType)}
      data={data}
      barPadding={0.1}
      direction={'horizontal'}
      barHover={'grouped'}
      showGrid={true}
      colors={['#0068b4', '#66ccc9', '#00386e', '#4e5463', '#80bb3d', '#ffc159']}
      legend={'left'}
      drawerExpanded={drawerExpanded}
      dateFormat={datemask}
      language={lang}
    />

    return (
      <>
        {
          preference !== 'hide'
            ? <div id={`chart${id}_container`} style={{ height }}>
              {
                {
                  'linechart': linechart,
                  'barchart_vert': barchartVert,
                  'barchart_hor': barchartHor,
                }[preference]
              }
            </div>
            : <></>
        }
      </>
    )
  }

  /**
   * @description renders the charts with statistic menu if there are data
   */
  getBody = () => {
    const { id, bsaLogxStats, preferences } = this.props
    let chartCounter = 0
    preferences.STATISTIC_LOGX_PAGES_CHART !== 'hide' && chartCounter++
    preferences.STATISTIC_LOGX_LOGS_CHART !== 'hide' && chartCounter++
    preferences.STATISTIC_LOGX_JOBS_CHART !== 'hide' && chartCounter++
    const containerHeight = { 1: '100%', 2: '100%', 3: '150%' }[chartCounter]
    const chartHeight = { 1: '100%', 2: '50%', 3: '33%' }[chartCounter]
    return (
      bsaLogxStats?.data
        ? (
          <div className={'statistics_container'}>
            <StatisticMenu
              onRefreshClick={this.handleOnRefresh}
              dateOutput={this.dateOutput()}
              header={this.getStatisticHeader}
              bsaStats={this.formatData(bsaLogxStats.data)}
              id={`${id}_statistic_menu`}
            />
            <div className="chart_container" style={{ height: `calc(${containerHeight} - 50px)` }}>
              {this.chooseChart(preferences.STATISTIC_LOGX_PAGES_CHART, 'pages', '1', chartHeight)}
              {this.chooseChart(preferences.STATISTIC_LOGX_LOGS_CHART, 'logs', '2', chartHeight)}
              {this.chooseChart(preferences.STATISTIC_LOGX_JOBS_CHART, 'jobs', '3', chartHeight)}
            </div>
          </div>
        )
        : (
          <NoSearch
            id={`${id}_nosearch`}
            message={translate('nosearch.description')}
          />
        )
    )
  }

  render = () => {
    const { drawerExpanded, autoDrawer } = this.props
    return (
      <ResultContainer
        drawerExpanded={drawerExpanded}
        autoDrawer={autoDrawer}>
        {this.getBody()}
      </ResultContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    bsaLogxStats: state.statistic.bsalogxstats,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLogxBsaStats: (fromDate, toDate, unit) => StatisticActions.getLogxBsaStats(fromDate, toDate, unit)(dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultStatisticLogx)