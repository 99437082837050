import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'
import * as Utils from 'utils/Utils'

// components
import {
  Button, Card, Column, Icon, Input,
  MetaDataGrid,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as NodeDocumentActions from 'redux/actions/NodeDocumentAssignmentActions'
import * as Preferences from 'redux/general/Preferences'

class ModifyNodeDocumentAssignmentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    owner: this.props.documentNode.OWNER,
    identifier: {
      value: this.props.documentNode.DGIENAME,
      error: ''
    },
    description: this.props.documentNode.DGIDESC,
  }

  identifierInput = React.createRef()

  componentDidMount = () => {
    this.identifierInput.current.focus()
  }

  /**
  * @description gets the index of the header in redux state
  * @param {Object} data specific redux object
  * @param {String} header header name of the header in redux
  */
  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param val New value of inputfield
   */
  handleInputChanged = (key, value, error) => {
    this.setState({
      [key]: typeof this.state[key] === 'object'
        ? { value, error }
        : value
    })
  }

  /**
   * @description Validates the identifier id.
   */
  validateIdentifier = () => {
    const { identifier } = this.state
    if (identifier.value !== '') {
      return {}
    }
    return {
      identifier: {
        ...this.state.identifier,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = {
      ...this.validateIdentifier()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocus()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input which has an error.
   */
  handleFocus = () => {
    const { identifier } = this.state
    const requiredInputs = [
      { inputRef: this.identifierInput, error: identifier.error }
    ]
    Utils.setFocus(requiredInputs)
  }

  /**
   * @description Creates the entry
   */
  handleSave = () => {
    const { modifyNodeDocument, documentNode, onClose } = this.props
    const { owner, identifier, description } = this.state
    const errors = [
      this.validateInputs()
    ]
    if (errors.every(d => d)) {
      const nodeDocumentObj = {
        DNDNAME: documentNode.DNDNAME,
        FORM: documentNode.FORM,
        EXT: documentNode.EXT,
        DGIENAME: identifier.value,
        DGIDESC: description,
        OWNER: owner
      }

      modifyNodeDocument(nodeDocumentObj, () => onClose())
    }
  }

  /**
   * @description Gets the modal title for delete dialog.
   * @returns {Array} The modal title as an array.
   */
  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.modify')} ${translate('general.node_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'node_document_relation_modal_title'} name={'relation'} tabIndex={-1}/>,
      <div key={'title_3'}>{`${translate('general.document_modal_title')}  ${translate('general.assignment_modal_title')}`}</div>,
    ]
  }

  /**
   * @description Renders the header.
   */
  renderHeader = () => {
    const { id, documentNode, datemask } = this.props
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('general.form'), value: documentNode.FORM },
          { label: translate('general.extension'), value: documentNode.EXT },
          { label: translate('definition.node_id'), value: documentNode.DNDNAME },
          { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, documentNode.CDATE, documentNode.CTIME.substring(0, 8)) + ' ' + translate('general.by') + ' ' + documentNode.CUSER },
        ]}
        columns={5}
      />
    )
  }

  render = () => {
    const { id, onClose } = this.props
    const { owner, identifier, description } = this.state
    return (
      <>
        <Modal
          id='modify_node_document_assignment_dialog'
          className='bux_UserProfileModal'
          onClose={onClose}>
          <Header
            id={id}
            title={this.getModalTitle()}
            onClose={onClose}>
            {this.renderHeader()}
          </Header>
          <Main id={id}>
            <Card>
              <Row>
                <Column colMD={6}>
                  <Input
                    id={`${id}_identifier`}
                    onInputChanged={(val, error) => this.handleInputChanged('identifier', val, error)}
                    value={identifier.value}
                    title={translate('general.identifier')}
                    ref={this.identifierInput}
                    error={identifier.error}
                    maxLength={80}
                    onBlur={() => this.setState({ ...this.validateIdentifier() })}
                    required={`${translate('general.required_field')}`}
                  />
                </Column>
                <Column colMD={3}>
                  <Input
                    id={`${id}_owner`}
                    value={owner}
                    title={translate('general.owner')}
                    maxLength={8}
                    onInputChanged={value => this.handleInputChanged('owner', value)}
                  />
                </Column>
              </Row>
              <Row>
                <Column colMD={6}>
                  <Input
                    id={`${id}_description`}
                    value={description}
                    title={translate('general.description')}
                    maxLength={80}
                    onInputChanged={value => this.handleInputChanged('description', value)}
                  />
                </Column>
              </Row>
            </Card>
          </Main>
          <Footer>
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn`}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }

}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    documentNode: state.assignments.nodedocumentassignment.nodeDocumentAssignment
  }
}

const mapDispatchToProps = dispatch => {
  return {
    modifyNodeDocument: (nodeDocument, callback) => {
      NodeDocumentActions.updateNodeDocument(nodeDocument, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyNodeDocumentAssignmentDialog)