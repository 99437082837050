export const SINGLE = 'SINGLE'
export const CARD = 'CARD'
export const FLFR = 'FLFR'

const CARD_TYPE = 'LF'
const LABEL_TYPE = 'LB'
const LEFT_TYPE = 'FL'
const RIGHT_TYPE = 'FR'


const FLFR_BREAKING_ELEMENTS = ['DTFRAME', 'DATETIME', 'DATEDATE', 'CCHOICE2', 'AMOUNT']

const createEmptyContext = () => ({
  index: null,
  result: [],
  cardIndex: null,
  flfrIndex: null,
  container: null,
  left: false,
  right: false,
})

const updateContext = (context, element, headerIndex) => {
  const type = element[headerIndex('SPIOP')]
  const formType = element[headerIndex('SPIDTYPE')]
  context.container = context.result[context.cardIndex]?.objects || context.result

  if (type === CARD_TYPE) {
    context.left = context.right = false
    context.cardIndex = context.result.push({type: CARD, objects: [], title: element[headerIndex('SPIENAME')]}) - 1
  } else if (type === LABEL_TYPE) {
    context.left = context.right = false
  } else if (FLFR_BREAKING_ELEMENTS.includes(formType)) {
    context.left = context.right = false
  } else if (type === LEFT_TYPE) {
    context.left = true
    context.right = false
    context.flfrIndex = context.container.push({type: FLFR, left: [], right: []}) - 1
  } else if (type === RIGHT_TYPE && !context.left) {
    context.right = true
    context.flfrIndex = context.container.push({type: FLFR, left: [], right: []}) - 1
  } else if (type === RIGHT_TYPE && context.right) {
    context.right = true
    context.flfrIndex = context.container.push({type: FLFR, left: [], right: []}) - 1
  } else if (type === RIGHT_TYPE) {
    context.right = true
  }
}

const updateResult = (element, context) => {
  if (!context.left && !context.right) {
    context.container.push({type: SINGLE, object: element, index: context.index})
  } else if (context.left && !context.right) {
    context.container[context.flfrIndex].left.push({object: {...element, displayAsDropdown: true}, index: context.index})
  } else if (context.right) {
    context.container[context.flfrIndex].right.push({object: {...element, displayAsDropdown: true}, index: context.index})
  }
}

export const build = (elements, headerIndex) => {
  let context = createEmptyContext()

  for (let index = 0; index < elements.length; index++) {
    const currentElement = elements[index]
    const currentType = currentElement[headerIndex('SPIOP')]

    context.index = index
    updateContext(context, currentElement, headerIndex)

    if ([CARD_TYPE, LEFT_TYPE, RIGHT_TYPE].includes(currentType)) continue

    updateResult(currentElement, context)
  }

  return context.result
}