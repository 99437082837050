import * as LogDefinitionActions from '../actions/LogDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  logs: undefined,
  log: undefined,
  verify: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get logs actions
    case LogDefinitionActions.LOG_DEFINITION_GET_LOGS_START:
      return { ...state }
    case LogDefinitionActions.LOG_DEFINITION_GET_LOGS_SUCCESS:
      return { ...state, logs: action.payload.data, keepPagination: action.keepPagination }
    case LogDefinitionActions.LOG_DEFINITION_GET_LOGS_FAILED:
      return { ...state }
    case LogDefinitionActions.LOG_DEFINITION_GET_LOG_START:
      return { ...state }
    case LogDefinitionActions.LOG_DEFINITION_GET_LOG_SUCCESS:
      return { ...state, log: action.payload.data }
    case LogDefinitionActions.LOG_DEFINITION_GET_LOG_FAILED:
      return { ...state }
    case LogDefinitionActions.NO_LOG_DEFINITION_LOGS_FOUND:
      return { ...state, logs: {} }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return defaultState
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer