import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Components
import {
  Button, Card, Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row, Switch
} from 'BetaUX2Web-Components/src/';
import { LetterCase, TYPE_PASSWORD } from 'BetaUX2Web-Components/src/types';


const { Modal, Main, Header, Footer } = ModalComponent

// Utils
import * as UserUtils from 'utils/UserUtils';

// Redux
import { connect } from 'react-redux';
import * as UserActions from 'redux/actions/UserActions';
import * as Preferences from 'redux/general/Preferences';

import './CreateUserDialog.scss';

export const COPY_USER = 'COPY_USER'
export const CLONE_USER = 'CLONE_USER'
export const CREATE_USER = 'CREATE_USER'

class CreateUserDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  userIdInput = React.createRef()
  passwordInput = React.createRef()
  passwordConfirmInput = React.createRef()

  state = {
    userId: {
      value:
        this.props.aimUser === COPY_USER ||
          this.props.aimUser === CLONE_USER
          ? this.props.user.BETAUSER
          : '',
      errorkey: '',
    },
    userName: {
      value:
        this.props.aimUser === COPY_USER ||
          this.props.aimUser === CLONE_USER
          ? this.props.user.USERNAME
          : '',
      errorkey: '',
    },
    password: {
      value: '',
      errorkey: '',
    },
    passwordConfirm: {
      value: '',
      errorkey: '',
    },
    passwordInterval:
      this.props.aimUser === COPY_USER || this.props.aimUser === CLONE_USER
        ? parseInt(this.props.user.PWDCINTV, 0)
        : 0,
    privileges:
      this.props.aimUser === COPY_USER || this.props.aimUser === CLONE_USER
        ? Math.max(
          UserUtils.PRIVILEGES_ITEMS.findIndex(
            (d) => d.key === this.props.user.ADMIN
          ),
          0
        )
        : 2,
    passwordExpired:
      this.props.aimUser === COPY_USER || this.props.aimUser === CLONE_USER
        ? this.props.user.FORCEPWD
        : false,
    revoked:
      this.props.aimUser === COPY_USER || this.props.aimUser === CLONE_USER
        ? this.props.user.USRREVOK
        : false,
    externalAuthenticate:
      this.props.aimUser === COPY_USER || this.props.aimUser === CLONE_USER
        ? this.props.user.CKPWDEXT
        : false,
  }

  /**
   * @description Sets the initial focus.
   */
  componentDidMount() {
    // focus user id input initially
    this.userIdInput.current.focus()
  }

  /**
   * @description Handles the input changes.
   * @param {String} key State key to update.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleOnInputChanged(key, value, error) {
    this.setState({
      [key]: {
        value: value,
        errorkey: error,
      },
    })
  }

  /**
   * @description Handles the password changes. Adds an error under password confirm is passwords are not equal.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleOnPasswordChanged(value, error) {
    this.setState({
      password: {
        value: value,
        errorkey: error,
      },
    })

    const errorPwdConfirm =
      value !== this.state.passwordConfirm.value
        ? 'usermanagement.password_confirm_not_equal_to_password'
        : null

    this.setState((currState) => ({
      passwordConfirm: {
        value: currState.passwordConfirm.value,
        errorkey: errorPwdConfirm,
      },
    }))
  }

  /**
   * @description Handles the password confirm changes.
   * @param {String} value The new value.
   * @param {String} error The new errorkey.
   */
  handleOnPasswordConfirmChanged(value, error) {
    const errorkey =
      this.state.password.value !== value
        ? 'usermanagement.password_confirm_not_equal_to_password'
        : error

    this.setState({
      passwordConfirm: {
        value: value,
        errorkey: errorkey,
      },
    })
  }

  /**
   * @description Handles the password interval changes.
   * @param {Number} value The new value.
   */
  handleOnPasswordIntervalChanged(value) {
    this.setState({
      passwordInterval: value,
    })
  }

  /**
   * @description Handles the password expired changes.
   * @param {Number} index The new index of the switch buttons.
   */
  handleOnPasswordExpiredChanged(index) {
    this.setState({
      passwordExpired: index === 0,
    })
  }

  /**
   * @description Handles the external authenticate changes.
   * @param {Number} index The new index of the switch buttons.
   */
  handleOnExternalAuthenticateChanged(index) {
    this.setState({
      externalAuthenticate: index === 0,
    })
  }

  /**
   * @description Handles the revoked changes.
   * @param {Number} index The new index of the switch buttons.
   */
  handleOnRevokedChanged(index) {
    this.setState({
      revoked: index === 0,
    })
  }

  /**
   * @description Validates the user id.
   */
  validateUserID = () => {
    const { userId } = this.state
    let errorkey = ''

    switch (this.props.aimUser) {
      case CREATE_USER:
        errorkey = 'usermanagement.create_user_same_userid_error'
        break
      case COPY_USER:
        errorkey = 'usermanagement.copy_user_same_userid_error'
        break
      case CLONE_USER:
        errorkey = 'usermanagement.clone_user_same_userid_error'
        break
      default:
        errorkey = ''
        break
    }

    if (userId.value === '') {
      return {
        userId: { value: '', errorkey: 'general.input_required' },
      }
    }
    if ((this.props.aimUser === COPY_USER || this.props.aimUser === CLONE_USER) && userId.value === this.props.user.BETAUSER) {
      return {
        userId: { ...this.state.userId, errorkey },
      }
    }
    return {}
  }

  /**
   * @description Validates the password.
   */
  validatePassword = () => {
    const { password, passwordConfirm, externalAuthenticate } = this.state
    let passwordObj
    let passwordConfirmObj
    if (password.value === '' && !externalAuthenticate) {
      passwordObj = {
        password: { value: '', errorkey: 'general.input_required' },
      }
    }
    if (passwordConfirm.value === '' && !externalAuthenticate) {
      passwordConfirmObj = {
        passwordConfirm: { value: '', errorkey: 'general.input_required' },
      }
    }
    if (password.value !== passwordConfirm.value) {
      passwordConfirmObj = {
        passwordConfirm: {
          value: passwordConfirm.value,
          errorkey: 'usermanagement.password_confirm_not_equal_to_password',
        },
      }
    }
    if (passwordObj || passwordConfirmObj) {
      return {
        ...passwordObj,
        ...passwordConfirmObj,
      }
    }
    return {}
  }

  handleFocusGeneralTab = () => {
    const { userId, password, passwordConfirm } = this.state
    if (userId.errorkey !== '') {
      this.userIdInput.current.focus()
    } else if (password.errorkey !== '') {
      this.passwordInput.current.focus()
    } else if (passwordConfirm.errorkey !== '') {
      this.passwordConfirmInput.current.focus()
    }
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateUserID(),
      ...this.validatePassword(),
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }

  /**
   * @description Handle the title of the modal
   */
  handleTitle = (aimUser) => {
    switch (aimUser) {
      case CREATE_USER:
        return translate('usermanagement.create_user')
      case COPY_USER:
        return translate('usermanagement.copy_user')
      case CLONE_USER:
        return translate('usermanagement.clone_user')
      default:
        return ''
    }
  }

  /**
   * @description Closes the dialog.
   */
  handleOnClose = () => {
    this.props.onClose()
  }

  /**
   * @description Calls the rest api and creates the user.
   */
  handleOnSave = () => {
    // check data before sending login request
    if (this.validateGeneralTab()) {
      // call create request
      const {
        userId,
        userName,
        password,
        passwordInterval,
        privileges,
        passwordExpired,
        revoked,
        externalAuthenticate,
      } = this.state

      const callback = () => this.props.onClose()

      if (this.props.aimUser === CLONE_USER) {
        const useridToClone = this.props.user.BETAUSER
        this.props.cloneUser(
          useridToClone,
          userId.value,
          userName.value,
          password.value,
          passwordInterval,
          revoked,
          UserUtils.PRIVILEGES_ITEMS[privileges].key,
          passwordExpired,
          externalAuthenticate,
          callback
        )
      } else {
        this.props.createUser(
          userId.value,
          userName.value,
          password.value,
          passwordInterval,
          revoked,
          UserUtils.PRIVILEGES_ITEMS[privileges].key,
          passwordExpired,
          externalAuthenticate,
          callback
        )
      }
    }
  }

  renderModalHeader = () => {
    const { id, user, aimUser } = this.props
    return <Header
      id={`${id}`}
      title={this.handleTitle(aimUser)}
      onClose={this.handleOnClose}
    >
      {aimUser === CLONE_USER ? (
        <MetaDataGrid
          id={`${id}_header`}
          metaData={[
            { label: translate('user.user_id'), value: user.BETAUSER },
            { label: translate('user.username'), value: user.USERNAME },
          ]}
          columns={4}
        />
      ) : null}
    </Header>
  }

  renderUserColumn = () => {
    const { id, user, aimUser, lang } = this.props
    const {
      userId,
      userName,
      password,
      passwordConfirm,
      passwordInterval,
      externalAuthenticate,
    } = this.state

    return <>
      <Row isTitle>
        <Column colMD={12}>
          <label
            id={`${id}_body_card_row_col_user_title`}
            className="paddingBottomFromTitle"
          >
            {translate('user.user')}
          </label>
        </Column>
      </Row>
      <Row className={'bux_pr8'}>
        <Column colMD={12}>
          <Input
            id={`${id}_body_card_row_col_user_userId`}
            ref={this.userIdInput}
            onInputChanged={(val, err) => { this.handleOnInputChanged('userId', val, err) }}
            value={userId.value}
            title={translate('user.user_id')}
            error={userId.errorkey ? translate(userId.errorkey) : null}
            onBlur={() => this.setState({ ...this.validateUserID() })}
            maxLength={8}
            required={`${translate('general.required_field')}`}
            lettercase={LetterCase.uppercase}
          />

          <Input
            id={`${id}_body_card_row_col_user_username`}
            onInputChanged={(val, err) => { this.handleOnInputChanged('userName', val, err) }}
            value={userName.value}
            title={translate('user.username')}
            error={userName.errorkey ? translate(userName.errorkey) : null}
            maxLength={32}
          />

          <Input
            id={`${id}_body_card_row_col_user_password`}
            ref={this.passwordInput}
            type={TYPE_PASSWORD}
            onInputChanged={(val, err) => { this.handleOnPasswordChanged(val, err) }}
            value={password.value}
            title={translate('user.password')}
            error={password.errorkey ? translate(password.errorkey) : null}
            onBlur={() => this.setState({ ...this.validatePassword() })}
            maxLength={8}
            required={!externalAuthenticate ? `${translate('general.required_field')}` : false}
            disabled={externalAuthenticate}
          />

          <Input
            id={`${id}_body_card_row_col_user_passwordconfirm`}
            ref={this.passwordConfirmInput}
            type={TYPE_PASSWORD}
            onInputChanged={(val, err) => { this.handleOnPasswordConfirmChanged(val, err) }}
            value={passwordConfirm.value}
            title={translate('user.password_confirm')}
            error={passwordConfirm.errorkey ? translate(passwordConfirm.errorkey) : null}
            onBlur={() => this.setState({ ...this.validatePassword() })}
            maxLength={8}
            required={!externalAuthenticate ? `${translate('general.required_field')}` : false}
            disabled={externalAuthenticate}
          />

          <NumericSpinner
            id={`${id}_body_card_row_col_user_passwordinterval`}
            onChange={(newVal) => { this.handleOnPasswordIntervalChanged(newVal) }}
            max={255}
            value={passwordInterval}
            steps={1}
            min={0}
            title={translate('user.password_interval')}
            disabled={externalAuthenticate}
          />
          {aimUser === CLONE_USER ? (
            <p id={`${id}main_cloneinfo`} style={{ fontWeight: 700, color: '#4e5463' }}>
              {translate('usermanagement.clone_info', lang, user.BETAUSER)}
            </p>
          ) : null}
        </Column>
      </Row>
    </>

  }

  renderAdditionalColumn = () => {
    const { id, aimUser } = this.props
    const {
      privileges,
      passwordExpired,
      externalAuthenticate,
      revoked,
    } = this.state
    return <>
      <Row isTitle className={'bux_pl8'}>
        <Column colMD={12}>
          <label
            id={`${id}_body_card_row_col_additional_title`}
            className="paddingBottomFromTitle">
            {translate('general.additional')}
          </label>
        </Column>
      </Row>
      <Row className={'bux_pl8'}>
        <Column colMD={12}>
          <Switch
            id={`${id}_body_card_row_col_additional_administrator`}
            title={translate('user.privileges')}
            items={UserUtils.PRIVILEGES_ITEMS.map((item) => translate(item.translationKey))}
            onClick={(index) => this.setState({ privileges: index })}
            activeIndex={privileges}
            maxPerRow={3}
          />
          <Switch
            id={`${id}_body_card_row_col_additional_passwordexpired`}
            title={translate('user.password_expired')}
            items={[translate('general.yes'), translate('general.no')]}
            onClick={(index) => this.handleOnPasswordExpiredChanged(index)}
            activeIndex={externalAuthenticate ? 0 : passwordExpired ? 0 : 1}
            disabled={externalAuthenticate}
          />
          {
            // Not necessary to show because revoked is always false on new user
            aimUser === COPY_USER || aimUser === CLONE_USER ? (
              <Switch
                id={`${id}_body_card_row_col_additional_revoked`}
                title={translate('user.revoked')}
                items={[translate('general.yes'), translate('general.no')]}
                onClick={(index) => this.handleOnRevokedChanged(index)}
                activeIndex={revoked ? 0 : 1}
              />
            ) : null
          }

          <Switch
            id={`${id}_body_card_row_col_additional_externalauthenticate`}
            title={translate('user.external_authentication')}
            items={[translate('general.yes'), translate('general.no')]}
            onClick={(index) => this.handleOnExternalAuthenticateChanged(index)}
            activeIndex={externalAuthenticate ? 0 : 1}
          />
        </Column>
      </Row>
    </>
  }

  render = () => {
    const { id } = this.props

    return (
      <Modal onClose={this.handleOnClose} id={`${id}`}>
        {this.renderModalHeader()}
        <Main id={`${id}`}>
          <Card>
            <Row>
              <Column>
                {this.renderUserColumn()}
              </Column>
              <Column>
                {this.renderAdditionalColumn()}
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_createUserModal`}>
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel')}
            onClick={this.handleOnClose}
          />
          <Button
            id={`${id}_footer_save`}
            text={translate('general.save')}
            onClick={this.handleOnSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userId: state.auth.userid,
    user: state.user.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (
      userId,
      userName,
      password,
      passwordInterval,
      revoked,
      privileges,
      passwordExpired,
      externalAuthenticate,
      callback
    ) => {
      UserActions.createUser(
        userId,
        userName,
        password,
        passwordInterval,
        revoked,
        privileges,
        passwordExpired,
        externalAuthenticate,
        callback
      )(dispatch)
    },
    cloneUser: (
      userid,
      newUserid,
      username,
      password,
      passwordInterval,
      revoked,
      privileges,
      passwordExpired,
      externalAuthenticate,
      callback
    ) => {
      UserActions.cloneUser(
        userid,
        newUserid,
        username,
        password,
        passwordInterval,
        revoked,
        privileges,
        passwordExpired,
        externalAuthenticate,
        callback
      )(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserDialog)