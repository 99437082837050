import { Component } from 'react'

import {
  Button, Card, Column,
  Input, Row, Switch
} from 'BetaUX2Web-Components/src/'
import { LetterCase } from 'BetaUX2Web-Components/src/types'
import TimeCard from 'components/time_card/TimeCard'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as SearchZOSActions from 'redux/actions/SearchZOSActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as Utils from 'utils/Utils'

import { connect } from 'react-redux'
import { validateTimecardErrors } from '../SearchUtils'

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.hours' },
  { key: UNIT_DAYS, translationKey: 'general.days' },
]

const SUBMIT = 'SUBMIT'
const START = 'START'
const END = 'END'

const DISPLAY_JOBS_BY_ITEMS = [
  { key: SUBMIT, translationKey: 'job.submitted' },
  { key: START, translationKey: 'general.start' },
  { key: END, translationKey: 'general.end' }
]

const FILTER_BY_JOB_ERRORS_ALL = ''
const FILTER_BY_JOB_ERRORS_SUCCESS = 'NO'
const FILTER_BY_JOB_ERRORS_ERROR = 'YES'

const ONLY_JOB_ERRORS_ITEMS = [
  { key: FILTER_BY_JOB_ERRORS_ALL, translationKey: 'general.all' },
  { key: FILTER_BY_JOB_ERRORS_SUCCESS, translationKey: 'general.successful' },
  { key: FILTER_BY_JOB_ERRORS_ERROR, translationKey: 'general.error' }
]

const LOG_SOURCE_READER_ALL = 'ZOS'
const LOG_SOURCE_READER_MIGRATION = 'ZOSMIG'

const LOG_SOURCE_READER_ITEMS = [
  { key: LOG_SOURCE_READER_ALL, translationKey: 'general.all' },
  { key: LOG_SOURCE_READER_MIGRATION, translationKey: 'general.migration' }
]

class SearchZosSearch extends Component {

  defaultState = {
    showJobgroupsDialog: false,
    time: {
      activeTimeTabIndex: 0,
      activeLastTimeModeIndex: 0,
      customLast: 1,
      activeUnitIndex: 2,
      startDate: {
        value: '',
        error: ''
      },
      startTime: {
        value: '',
        error: ''
      },
      endDate: {
        value: '',
        error: ''
      },
      endTime: {
        value: '',
        error: ''
      }
    },
    jobgroup: '',
    displayJobsBy: 0,
    jobname1: '',
    jobname2: '',
    jobname3: '',
    jobname4: '',
    jobId: '',
    returnCode: '',
    netId1: '',
    netId2: '',
    zosClass: '',
    onlyJobErrors: 0,
    logSourceReader: 0
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount = () => {
    // initialize search fields
    const { preferences, datemask } = this.props

    if (preferences) {
      let activeTimeTabIndex = Utils.convertStringToInt(preferences[Preferences.SEARCH_ZOS_ACTIVE_TAB]) || 0
      let startDate = activeTimeTabIndex === 1 && preferences[Preferences.SEARCH_ZOS_START_DATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SEARCH_ZOS_START_DATE])
        : ''

      let startTime = preferences[Preferences.SEARCH_ZOS_START_TIME] || ''
      startTime = Utils.convertStringToInt(startTime)

      let endDate = preferences[Preferences.SEARCH_ZOS_END_DATE]
        ? DateUtils.getDate(datemask, preferences[Preferences.SEARCH_ZOS_END_DATE])
        : ''

      let endTime = preferences[Preferences.SEARCH_ZOS_END_TIME] || ''
      endTime = Utils.convertStringToInt(endTime)

      const resultIndexUnit = UNITS.findIndex(d => d.key === preferences[Preferences.SEARCH_ZOS_TIME_CUSTOM_UNIT])
      const unitIndex = resultIndexUnit !== -1 ? resultIndexUnit : 2

      const resultIndexUsage = DISPLAY_JOBS_BY_ITEMS.findIndex(d => d.key === preferences[Preferences.SEARCH_ZOS_USAGE])
      const displayJobsBy = resultIndexUsage !== -1 ? resultIndexUsage : 0

      const onlyJobErrors = Math.max(ONLY_JOB_ERRORS_ITEMS.findIndex(d => d.key === preferences[Preferences.SEARCH_ZOS_ONLY_JOB_ERRORS]), 0)
      const logSourceReader = Math.max(LOG_SOURCE_READER_ITEMS.findIndex(d => d.key === preferences[Preferences.SEARCH_ZOS_LOGSOURCE]), 0)

      const jobname1 = preferences[Preferences.SEARCH_ZOS_JOBNAME1] || ''
      const jobname2 = preferences[Preferences.SEARCH_ZOS_JOBNAME2] || ''
      const jobname3 = preferences[Preferences.SEARCH_ZOS_JOBNAME3] || ''
      const jobname4 = preferences[Preferences.SEARCH_ZOS_JOBNAME4] || ''
      const jobId = preferences[Preferences.SEARCH_ZOS_JOBID] || ''
      const returnCode = preferences[Preferences.SEARCH_ZOS_RETURNCODE] || ''
      const netId1 = preferences[Preferences.SEARCH_ZOS_NETID1] || ''
      const netId2 = preferences[Preferences.SEARCH_ZOS_NETID2] || ''
      const zosClass = preferences[Preferences.SEARCH_ZOS_CLASS] || ''

      // set preference values or fallback default values
      this.setState({
        time: {
          activeTimeTabIndex,
          type: Utils.convertStringToInt(preferences[Preferences.SEARCH_ZOS_LASTTIME_MODE]) || 0,
          period: Utils.convertStringToInt(preferences[Preferences.SEARCH_ZOS_TIME_CUSTOM_LAST]) || 1,
          unit: unitIndex,
          startDate: {
            value: startDate,
            error: ''
          },
          startTime: {
            value: startTime,
            error: ''
          },
          endDate: {
            value: endDate,
            error: ''
          },
          endTime: {
            value: endTime,
            error: ''
          }
        },
        displayJobsBy,
        jobname1,
        jobname2,
        jobname3,
        jobname4,
        jobId,
        returnCode,
        netId1,
        netId2,
        zosClass,
        onlyJobErrors,
        logSourceReader
      })
    }
  }

  /**
   * @description Handles the jobgroup selector button
   */
  handleJobgroupSelector = () => {
    this.props.getJobgroups(
      ['JGIGNAME', 'JGITITLE'],
      { JGIGNAME: this.state.jobgroup },
      () => this.setState({ showJobgroupsDialog: true })
    )
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Handles changes on input fields.
   * @param {String} id The id of the field to change
   * @param {String} value The new value
   */
  handleInputChanged = (id, value) => {
    this.setState({ [id]: value })
  }

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = event => {
    const { time, displayJobsBy, jobgroup, jobname1, jobname2, jobname3, jobname4, jobId,
      returnCode, netId1, netId2, zosClass, onlyJobErrors, logSourceReader } = this.state
    const { datemask, getZosJobs, changePrefs } = this.props

    event.preventDefault()
    const errors = validateTimecardErrors(time, datemask)
    this.setState({ ...this.state, time: { ...time, ...errors } })
    if (Object.keys(errors).length === 0) {
      const prefsToChange = {
        [Preferences.SEARCH_ZOS_ACTIVE_TAB]: time.activeTimeTabIndex,
        [Preferences.SEARCH_ZOS_LASTTIME_MODE]: time.type,
        [Preferences.SEARCH_ZOS_TIME_CUSTOM_LAST]: time.period,
        [Preferences.SEARCH_ZOS_TIME_CUSTOM_UNIT]: UNITS[time.unit].key,
        [Preferences.SEARCH_ZOS_START_DATE]: DateUtils.getRequestFormat(time.startDate.value, datemask),
        [Preferences.SEARCH_ZOS_START_TIME]: DateUtils.formatTimeToDefault(time.startTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_ZOS_END_DATE]: DateUtils.getRequestFormat(time.endDate.value, datemask),
        [Preferences.SEARCH_ZOS_END_TIME]: DateUtils.formatTimeToDefault(time.endTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.SEARCH_ZOS_USAGE]: DISPLAY_JOBS_BY_ITEMS[displayJobsBy].key,
        [Preferences.SEARCH_ZOS_LOGSOURCE]: LOG_SOURCE_READER_ITEMS[logSourceReader].key,
        [Preferences.SEARCH_ZOS_JOBGROUP]: jobgroup,
        [Preferences.SEARCH_ZOS_JOBNAME1]: jobname1,
        [Preferences.SEARCH_ZOS_JOBNAME2]: jobname2,
        [Preferences.SEARCH_ZOS_JOBNAME3]: jobname3,
        [Preferences.SEARCH_ZOS_JOBNAME4]: jobname4,
        [Preferences.SEARCH_ZOS_JOBID]: jobId,
        [Preferences.SEARCH_ZOS_RETURNCODE]: returnCode,
        [Preferences.SEARCH_ZOS_NETID1]: netId1,
        [Preferences.SEARCH_ZOS_NETID2]: netId2,
        [Preferences.SEARCH_ZOS_CLASS]: zosClass,
        [Preferences.SEARCH_ZOS_ONLY_JOB_ERRORS]: ONLY_JOB_ERRORS_ITEMS[onlyJobErrors].key,
      }
      changePrefs(prefsToChange)

      const searchParams = {
        'LOGSOURCE': LOG_SOURCE_READER_ITEMS[logSourceReader].key,
        'TUSAGE': DISPLAY_JOBS_BY_ITEMS[displayJobsBy].key,
        'JOBGROUP': jobgroup,
        'JOBNAME1': jobname1,
        'JOBNAME2': jobname2,
        'JOBNAME3': jobname3,
        'JOBNAME4': jobname4,
        'JOBID': jobId,
        'JOBNETID1': netId1,
        'JOBNETID2': netId2,
        'JOBCLASS': zosClass,
        'JOBERR': ONLY_JOB_ERRORS_ITEMS[onlyJobErrors].key,
        'JOBRC': returnCode
      }

      if (time.activeTimeTabIndex === 0) {
        prefsToChange[Preferences.QUEUE_OUTPUT_START_DATE] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_START_TIME] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_END_DATE] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_END_TIME] = ''
        if (time.type === 0) {
          searchParams['SDATE'] = 'TODAY'
          prefsToChange[Preferences.QUEUE_OUTPUT_START_DATE] = 'TODAY'
        }
        else if (time.type === 1) {
          searchParams['SDATE'] = 'YESTERDAY'
          prefsToChange[Preferences.QUEUE_OUTPUT_START_DATE] = 'YESTERDAY'
        }
        else if (time.type === 2) {
          searchParams['FROMLAST'] = time.period
          searchParams['TUNITS'] = DateUtils.UNITS[time.unit].key
        }
      }
      else if (time.activeTimeTabIndex === 1) {
        searchParams['SDATE'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.startTime.value !== '') {
          searchParams['STIME'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.TIME_DATEMASK)
        }
        searchParams['EDATE'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.endTime.value !== '') {
          searchParams['ETIME'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.TIME_DATEMASK)
        }

        prefsToChange[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_LAST] = ''
        prefsToChange[Preferences.QUEUE_OUTPUT_TIME_CUSTOM_UNIT] = ''
      }
      getZosJobs(searchParams)
    }
  }

  /**
   * @description Handles the changes in timecard.
   * @param {String} key The key of the input in state.
   * @param {String} val The new value of the input.
   * @param {String} err The new error of the input.
   */
  handleTimeCardChange = (key, val, err) => {
    this.setState(state => ({
      time: {
        ...state.time,
        [key]: typeof state.time[key] === 'object'
          ? {
            value: val,
            error: err || ''
          }
          : val
      }
    }))
  }

  /**
   * @description Returns the values for the timecard.
   * @returns {Object} The timecard values.
   */
  timeCardValues = () => {
    const { time } = this.state
    return {
      tabIndex: time.activeTimeTabIndex,
      lastTimeModeIndex: time.type,
      customLast: time.period,
      customUnitIndex: time.unit,
      fromDate: {
        value: time.startDate.value,
        error: time.startDate.error
      },
      fromTime: {
        value: time.startTime.value,
        error: time.startTime.error
      },
      toDate: {
        value: time.endDate.value,
        error: time.endDate.error
      },
      toTime: {
        value: time.endTime.value,
        error: time.endTime.error
      }
    }
  }

  /**
   * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
   * Needed for update function inside the TimeCard.
   * @returns {Object} The object with the state keys.
   */
  timeCardStateKeys = () => {
    return {
      tabIndex: 'activeTimeTabIndex',
      lastTimeModeIndex: 'type',
      customLast: 'period',
      customUnitIndex: 'unit',
      fromDate: 'startDate',
      fromTime: 'startTime',
      toDate: 'endDate',
      toTime: 'endTime',
    }
  }

  /**
   * @description Renders the time card.
   */
  renderTimeCard = () => {
    const { id, lang, datemask } = this.props
    return (
      <TimeCard
        id={id}
        lang={lang}
        datemask={datemask}
        values={this.timeCardValues()}
        stateKeys={this.timeCardStateKeys()}
        onValuesChange={this.handleTimeCardChange}
        parentContainer={'drawer_content_search_body_main'}
        translate={key => translate(key)}
      />
    )
  }

  /**
   * @description Renders the select by card.
   */
  renderSelectByCard = () => {
    const { displayJobsBy } = this.state
    const { id } = this.props
    return (
      <Card
        id={`${id}_select_by`}
        title={translate('search.select_by')}>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_display_jobs_by`}
              title={translate('general.date_field')}
              items={DISPLAY_JOBS_BY_ITEMS.map(d => translate(d.translationKey))}
              activeIndex={displayJobsBy}
              onClick={index => this.setState({ displayJobsBy: index })}
              maxPerRow={3}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the job data card.
   */
  renderJobDataCard = () => {
    const { id } = this.props

    return (
      <Card
        id={`${id}_job_data`}
        title={translate('job.job_data')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_jobgroup`}
              onInputChanged={value => this.setState({ jobgroup: value })}
              value={this.state.jobgroup}
              title={translate('general.jobgroup')}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.handleJobgroupSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            {/* jobname1 */}
            <Input
              id={`${id}_jobname1`}
              onInputChanged={(val) => { this.handleInputChanged('jobname1', val) }}
              value={this.state.jobname1}
              title={translate('job.jobname1')}
              maxLength={8}
              lettercase={LetterCase.uppercase}
            />
          </Column>
          <Column colMD={6}>
            {/* jobname2 */}
            <Input
              id={`${id}_jobname2`}
              onInputChanged={(val) => { this.handleInputChanged('jobname2', val) }}
              value={this.state.jobname2}
              title={translate('job.jobname2')}
              maxLength={8}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
        {/* jobname3 + jobname4 row */}
        <Row>
          <Column colMD={6}>
            {/* jobname3 */}
            <Input
              id={`${id}_jobname3`}
              onInputChanged={(val) => { this.handleInputChanged('jobname3', val) }}
              value={this.state.jobname3}
              title={translate('job.jobname3')}
              maxLength={8}
              lettercase={LetterCase.uppercase}
            />
          </Column>
          <Column colMD={6}>
            {/* jobname4 */}
            <Input
              id={`${id}_jobname4`}
              onInputChanged={(val) => { this.handleInputChanged('jobname4', val) }}
              value={this.state.jobname4}
              title={translate('job.jobname4')}
              maxLength={8}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
        {/* jobid + returncode row */}
        <Row>
          <Column colMD={6}>
            {/* jobid */}
            <Input
              id={`${id}_jobid`}
              onInputChanged={(val) => { this.handleInputChanged('jobId', val) }}
              value={this.state.jobId}
              title={translate('job.jobid')}
              maxLength={8}
              lettercase={LetterCase.uppercase}
            />
          </Column>
          <Column colMD={6}>
            {/* returncode */}
            <Input
              id={`${id}_returncode`}
              onInputChanged={(val) => { this.handleInputChanged('returnCode', val) }}
              value={this.state.returnCode}
              title={translate('general.return_code')}
              maxLength={16}
            />
          </Column>
        </Row>
        {/* netid1 + netid2 row */}
        <Row>
          <Column colMD={6}>
            {/* netid1 */}
            <Input
              id={`${id}_netid1`}
              onInputChanged={(val) => { this.handleInputChanged('netId1', val) }}
              value={this.state.netId1}
              title={translate('job.netid1')}
              maxLength={16}
              lettercase={LetterCase.uppercase}
            />
          </Column>
          <Column colMD={6}>
            {/* netid2 */}
            <Input
              id={`${id}_netid2`}
              onInputChanged={(val) => { this.handleInputChanged('netId2', val) }}
              value={this.state.netId2}
              title={translate('job.netid2')}
              maxLength={16}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
        {/* class row */}
        <Row>
          <Column colMD={6}>
            {/* class */}
            <Input
              id={`${id}_class`}
              onInputChanged={(val) => { this.handleInputChanged('zosClass', val) }}
              value={this.state.zosClass}
              title={translate('job.class')}
              maxLength={1}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
        {/* only job errors row */}
        <Row>
          <Column colMD={12}>
            {/* only job errors */}
            <Switch
              id={`${id}_only_job_errors`}
              title={translate('job.filter_by_job_errors')}
              maxPerRow={3}
              items={ONLY_JOB_ERRORS_ITEMS.map(el => translate(el.translationKey))}
              onClick={(index) => this.setState({ onlyJobErrors: index })}
              activeIndex={this.state.onlyJobErrors}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  renderAdditional = () => {
    const { id } = this.props
    return (
      <Card id={`${id}_additional`} title={translate('general.additional')}>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_log_source_reader`}
              title={translate('general.log_source_reader')}
              maxPerRow={3}
              items={LOG_SOURCE_READER_ITEMS.map(el => translate(el.translationKey))}
              onClick={index => this.setState({ logSourceReader: index })}
              activeIndex={this.state.logSourceReader}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderTimeCard()}
        {this.renderSelectByCard()}
        {this.renderJobDataCard()}
        {this.renderAdditional()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props
    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { showJobgroupsDialog } = this.state
    const { id, selector } = this.props
    return (
      <>
        {showJobgroupsDialog && (
          <SelectorDialog
            id={`${id}_jobgroups_selector_dialog`}
            onClose={() => this.setState({ showJobgroupsDialog: false })}
            title={translate('definition.jobgroups')}
            header={[translate('general.jobgroup_name'), translate('general.title')]}
            items={selector.jobgroups.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newJobgroup = selector.jobgroups.data[selectedRows][selector.jobgroups.header.indexOf('JGIGNAME')]
                this.setState({ jobgroup: newJobgroup })
              }
              this.setState({ showJobgroupsDialog: false })
            }}
          />
        )}
        <form
          id={id}
          className={'bux_drawer_form'}
          onSubmit={this.handleSubmitSearch}>
          {this.renderMain()}
          {this.renderFooter()}
        </form>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences.DATEMASK,
    selector: state.selector
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch),
    getZosJobs: (searchObj, callback) => SearchZOSActions.getZosJobs(undefined, searchObj, callback)(dispatch),
    getJobgroups: (fields, searchObj, callback) => ModalSelectorActions.getJobgroups(fields, searchObj, callback)(dispatch)
  }
}

SearchZosSearch.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchZosSearch)