import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Style
import './Window.scss'

export default class Window extends Component {

  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string,
    /** Text to be display as a title of Window.*/
    title: PropTypes.string,
    /** Jsx element represents icon */
    icon: PropTypes.element,
    /** Specified the max height of the Window */
    maxHeight: PropTypes.number,
    /** Children of the component.*/
    children: PropTypes.node
  }

  state = {
    isScrollable: false
  }

  /**
   * @description updates the isScrollable state
   */
  componentDidUpdate = () => {
    if (this.isScrollable() !== this.state.isScrollable) {
      this.setState({ isScrollable: this.isScrollable() })
    }
  }

  /**
   * @description Returns if the window is scrollable.
   * @returns {bool}
   */
  isScrollable = () => {
    const { id } = this.props
    const el = document.querySelector(`#${id}_window_content`)
    return el && el.scrollHeight > el.clientHeight
  }

  /**
   * @description Renders the component
   */
  render = () => {
    const { isScrollable } = this.state
    const { id, title, icon, children, maxHeight } = this.props
    const containerHeight = { maxHeight: `${maxHeight}px` || '100%' }
    const contentHeight = { height: title ? 'calc(100% - 24px)' : '100%' }
    return (
      <div id={`${id}_window`} className={'bux_window'} style={containerHeight}>
        <div className={`bux_window_headline_container ${isScrollable ? 'bux_move_icon_when_scrollable' : ''}`}>
          {
            title &&
            <label id={`${id}_window_title`} className={'bux_window_title'}>{title}</label>
          }
          {icon}
        </div>
        <div id={`${id}_window_content`} className={'bux_window_content'} style={contentHeight}>{children}</div>
      </div>
    )
  }
}