import assign from 'object-assign'
import createClass from 'create-react-class'
import moment from 'moment'
//! There is a bug from react, that we need to import the different locales from moment like this to use it.
//! https://stackoverflow.com/questions/49788259/moment-js-change-locale-not-working
//! https://create-react-app.dev/docs/troubleshooting/#momentjs-locales-are-missing
import 'moment/locale/de'
import 'moment/locale/fr'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import CalendarContainer from './src/CalendarContainer.jsx'
import onClickOutside from 'react-onclickoutside'

const viewModes = Object.freeze({
	YEARS: 'years',
	MONTHS: 'months',
	DAYS: 'days',
	TIME: 'time',
})

const allowedSetTime = ['hours', 'minutes', 'seconds', 'milliseconds']

const componentProps = {
	fromProps: ['value', 'isValidDate', 'renderDay', 'renderMonth', 'renderYear', 'timeConstraints'],
	fromState: ['viewDate', 'selectedDate', 'updateOn'],
	fromThis: ['setDate', 'setTime', 'showView', 'addTime', 'subtractTime', 'updateSelectedDate', 'localMoment', 'handleClickOutside']
}

export default class Datetime extends Component {

	state = {
		...this.getInitialState(this.props)
	}

	componentWillReceiveProps = nextProps => {
		let formats = this.getFormats(nextProps)
		let	updatedState = {}

		if (nextProps.value !== this.props.value ||
			formats.datetime !== this.getFormats(this.props).datetime) {
			updatedState = this.getStateFromProps(nextProps)
		}
		if (updatedState.open === undefined) {
			if (typeof nextProps.open !== 'undefined') {
				updatedState.open = nextProps.open
			}
			else if (nextProps.value !== this.props.value && this.props.closeOnSelect && this.state.currentView !== viewModes.TIME) {
				updatedState.open = false
			}
			else {
				updatedState.open = this.state.open
			}
		}

		if (nextProps.viewMode !== this.props.viewMode) {
			updatedState.currentView = nextProps.viewMode
		}

		if (nextProps.locale !== this.props.locale) {
			if (this.state.viewDate) {
				let updatedViewDate = this.state.viewDate.clone().locale(nextProps.locale)
				updatedState.viewDate = updatedViewDate
			}
			if (this.state.selectedDate) {
				let updatedSelectedDate = this.state.selectedDate.clone().locale(nextProps.locale)
				updatedState.selectedDate = updatedSelectedDate
				updatedState.inputValue = updatedSelectedDate.format(formats.datetime)
			}
		}

		if (nextProps.utc !== this.props.utc || nextProps.displayTimeZone !== this.props.displayTimeZone) {
			if (nextProps.utc) {
				if (this.state.viewDate)
					updatedState.viewDate = this.state.viewDate.clone().utc()
				if (this.state.selectedDate) {
					updatedState.selectedDate = this.state.selectedDate.clone().utc()
					updatedState.inputValue = updatedState.selectedDate.format(formats.datetime)
				}
			}
			else if (nextProps.displayTimeZone) {
				if (this.state.viewDate)
					updatedState.viewDate = this.state.viewDate.clone().tz(nextProps.displayTimeZone)
				if (this.state.selectedDate) {
					updatedState.selectedDate = this.state.selectedDate.clone().tz(nextProps.displayTimeZone)
					updatedState.inputValue = updatedState.selectedDate.tz(nextProps.displayTimeZone).format(formats.datetime)
				}
			}
			else {
				if (this.state.viewDate)
					updatedState.viewDate = this.state.viewDate.clone().local()
				if (this.state.selectedDate) {
					updatedState.selectedDate = this.state.selectedDate.clone().local()
					updatedState.inputValue = updatedState.selectedDate.format(formats.datetime)
				}
			}
		}

		if (nextProps.viewDate !== this.props.viewDate) {
			updatedState.viewDate = moment(nextProps.viewDate)
		}

		this.checkTZ(nextProps)
		this.setState(updatedState)
	}

	componentDidMount = () => {
		this.calcPosition()
		if (this.props.parentContainer) {
			const parentContainer = document.querySelector(`#${this.props.parentContainer}`)
			parentContainer && parentContainer.addEventListener('scroll', this.onScroll)
		}
	}

	componentDidUpdate = () => {
		this.calcPosition()
	}

	componentWillUnmount = () => {
		if (this.props.parentContainer) {
			const parentContainer = document.querySelector(`#${this.props.parentContainer}`)
			parentContainer &&parentContainer.removeEventListener('scroll', this.onScroll)
		}
	}

	getInitialState(props) {
		this.checkTZ(props)

		var state = this.getStateFromProps(props)

		if (state.open === undefined)
			state.open = !props.input

		state.currentView = props.dateFormat ?
			(props.viewMode || state.updateOn || viewModes.DAYS) : viewModes.TIME
		return state
	}

	parseDate(date, formats) {
		let parsedDate

		if (date && typeof date === 'string')
			parsedDate = this.localMoment(date, formats.datetime)
		else if (date)
			parsedDate = this.localMoment(date)

		if (parsedDate && !parsedDate.isValid())
			parsedDate = null

		return parsedDate
	}

	getStateFromProps(props) {
		let formats = this.getFormats(props)
		let	date = props.value || props.defaultValue
		let	selectedDate
		let viewDate
		let updateOn
		let inputValue

		selectedDate = this.parseDate(date, formats)

		viewDate = this.parseDate(props.viewDate, formats)

		viewDate = selectedDate
			?	selectedDate.clone().startOf('month')
			: viewDate
				? viewDate.clone().startOf('month')
				: this.localMoment().startOf('month')

		updateOn = this.getUpdateOn(formats)

		if (selectedDate)
			inputValue = selectedDate.format(formats.datetime)
		else if (date.isValid && !date.isValid())
			inputValue = ''
		else {
			inputValue = date || ''
		}
		return {
			updateOn: updateOn,
			inputFormat: formats.datetime,
			viewDate: viewDate,
			selectedDate: selectedDate,
			inputValue: inputValue,
			open: props.open
		}
	}

	getUpdateOn(formats) {
		if (formats.date.match(/[lLD]/)) {
			return viewModes.DAYS
		} else if (formats.date.indexOf('M') !== -1) {
			return viewModes.MONTHS
		} else if (formats.date.indexOf('Y') !== -1) {
			return viewModes.YEARS
		}
		return viewModes.DAYS
	}

	getFormats(props) {
		let formats = {
			date: props.dateFormat || '',
			time: props.timeFormat || ''
		}
		let	locale = this.localMoment(props.date, null, props).localeData()

		if (formats.date === true) {
			formats.date = locale.longDateFormat('L')
		}
		else if (this.getUpdateOn(formats) !== viewModes.DAYS) {
			formats.time = ''
		}

		if (formats.time === true) {
			formats.time = locale.longDateFormat('LT')
		}

		formats.datetime = formats.date && formats.time
		? formats.date + ' ' + formats.time
		: formats.date || formats.time

		return formats
	}

	onInputChange = e => {
		let value = e.target === null ? e : e.target.value
		let	localMoment = this.localMoment(value, this.state.inputFormat)
		let	update = { inputValue: value }

		if (localMoment.isValid() && !this.props.value) {
			update.selectedDate = localMoment
			update.viewDate = localMoment.clone().startOf('month')
		}
		else {
			update.selectedDate = null
		}

		return this.setState(update, function () {
			return this.props.onChange(this.state.inputValue)
		})
	}

	onInputKey = e => {
		if (e.which === 9 && this.props.closeOnTab) {
			this.closeCalendar()
		}
	}

	showView = view => {
		return () => {
			this.state.currentView !== view && this.props.onViewModeChange(view)
			this.setState({ currentView: view })
		}
	}

	setDate = type => {
		let	nextViews = {
			month: viewModes.DAYS,
			year: viewModes.MONTHS,
		}
		return e => {
			this.setState({
				viewDate: this.state.viewDate.clone()[type](parseInt(e.target.getAttribute('data-value'), 10)).startOf(type),
				currentView: nextViews[type]
			})
			this.props.onViewModeChange(nextViews[type])
		}
	}

	subtractTime = (amount, type, toSelected) => {
		return () => {
			this.props.onNavigateBack(amount, type)
			this.updateTime('subtract', amount, type, toSelected)
		}
	}

	addTime = (amount, type, toSelected) => {
		return () => {
			this.props.onNavigateForward(amount, type)
			this.updateTime('add', amount, type, toSelected)
		}
	}

	updateTime = (op, amount, type, toSelected) => {
		let update = {}
		let	date = toSelected ? 'selectedDate' : 'viewDate'

		update[date] = this.state[date].clone()[op](amount, type)
		this.setState(update)
	}

	setTime = (type, value) => {
		let index = allowedSetTime.indexOf(type) + 1
		let	state = this.state
		let	date = (state.selectedDate || state.viewDate).clone()
		let	nextType

		// It is needed to set all the time properties
		// to not to reset the time
		date[type](value)
		for (; index < allowedSetTime.length; index++) {
			nextType = allowedSetTime[index]
			date[nextType](date[nextType]())
		}

		if (!this.props.value) {
			this.setState({
				selectedDate: date,
				inputValue: date.format(state.inputFormat)
			})
		}
		this.props.onChoose(date)
	}

	updateSelectedDate = (e, close) => {
		e.preventDefault()
		let target = e.currentTarget
		let	modifier = 0
		let	viewDate = this.state.viewDate
		let	currentDate = this.state.selectedDate || viewDate
		let	date

		if (target.className.indexOf('rdtDay') !== -1) {
			if (target.className.indexOf('rdtNew') !== -1)
				modifier = 1
			else if (target.className.indexOf('rdtOld') !== -1)
				modifier = -1
			date = viewDate.clone()
				.month(viewDate.month() + modifier)
				.date(parseInt(target.getAttribute('data-value'), 10));
		}
		else if (target.className.indexOf('rdtMonth') !== -1) {
			date = viewDate.clone()
				.month(parseInt(target.getAttribute('data-value'), 10))
				.date(currentDate.date())
		}
		else if (target.className.indexOf('rdtYear') !== -1) {
			date = viewDate.clone()
				.month(currentDate.month())
				.date(currentDate.date())
				.year(parseInt(target.getAttribute('data-value'), 10))
		}
		else {
			const now = moment()
			date = viewDate.clone()
				.month(now.month())
				.date(now.date())
				.year(now.year())
		}

		date.hours(currentDate.hours())
			.minutes(currentDate.minutes())
			.seconds(currentDate.seconds())
			.milliseconds(currentDate.milliseconds())

		if (!this.props.value) {
			const open = !(this.props.closeOnSelect && close)
			if (!open) {
				this.props.onBlur(date)
			}

			this.setState({
				selectedDate: date,
				viewDate: date.clone().startOf('month'),
				inputValue: date.format(this.state.inputFormat),
				open: open
			})
		}
		else {
			if (this.props.closeOnSelect && close) {
				this.closeCalendar()
			}
		}
		this.props.onChoose(date)
	}

	openCalendar = e => {
		this.calcPosition()
		if (!this.state.open) {
			this.setState({ open: true }, function () {
				this.props.onFocus(e)
			})
		}
	}

	toggleCalendar = e => {
		this.setState(prevState => ({ open: !prevState.open }), () => {
			if (this.state.open) {
				this.props.onFocus(e)
			}
			else {
				this.props.onBlur(this.state.selectedDate || this.state.inputValue);
			}
		})
	}

	closeCalendar = e => {
		if (e) {
			e.preventDefault()
		}
		this.setState({ open: false }, () => {
			this.props.onBlur(this.state.selectedDate || this.state.inputValue)
		})
	}

	handleClickOutside = e => {
		if (this.state.open) {
			if (!(document.querySelector(`#${this.props.id}_div`).contains(e.target))) {
				if (this.state.open) {
					this.setState({ open: false }, () => {
						this.props.onBlur(this.state.selectedDate || this.state.inputValue)
					})
				}
			}
		}
	}

	localMoment(date, format, props) {
		props = props || this.props
		var m = null

		if (props.utc) {
			m = moment.utc(date, format, props.strictParsing)
		}
		else if (props.displayTimeZone) {
			m = moment.tz(date, format, props.displayTimeZone)
		}
		else {
			m = moment(date, format, props.strictParsing)
		}

		if (props.locale) {
			m.locale(props.locale)
		}

		return m
	}

	checkTZ(props) {
		var con = console

		if (props.displayTimeZone && !this.tzWarning && !moment.tz) {
			this.tzWarning = true
			con && con.error('react-datetime: displayTimeZone prop with value "' + props.displayTimeZone + '" is used but moment.js timezone is not loaded.')
		}
	}

	getComponentProps = () => {
		let formats = this.getFormats(this.props)
		let newProps = { dateFormat: formats.date, timeFormat: formats.time }

		componentProps.fromProps.forEach(name => {
			newProps[name] = this.props[name]
		})
		componentProps.fromState.forEach(name => {
			newProps[name] = this.state[name]
		})
		componentProps.fromThis.forEach(name => {
			newProps[name] = this[name]
		})

		return newProps
	}

	overrideEvent = (handler, action) => {
		if (!this.overridenEvents) {
			this.overridenEvents = {}
		}

		if (!this.overridenEvents[handler]) {
			this.overridenEvents[handler] = e => {
				let result
				if (this.props.inputProps && this.props.inputProps[handler]) {
					result = this.props.inputProps[handler](e)
				}
				if (result !== false) {
					action(e)
				}
			}
		}

		return this.overridenEvents[handler]
	}

	selectActiveDate = event => {
		this.updateSelectedDate(event, true)
	}

	onScroll = () => {
		if (this.state.open) {
			this.closeCalendar()
		}
	}

	calcPosition = () => {
		const el = document.querySelector(`#${ this.props.id }_div`)

		const elPosX = el.getBoundingClientRect().left
		const elPosY = el.getBoundingClientRect().top
		const elHeight = el.getBoundingClientRect().height
		const flyoutMenuHeight = 280
		// min height of the smallest resolution
		const minHeight = 768
		// take the window height if bigger then min height of screen resolution
		const windowHeight = window.innerHeight < minHeight ? minHeight : window.innerHeight
		let flyoutMenuTop = elPosY + elHeight + 5 + window.scrollY
		// if there is not enough space to fly out bottom, then fly out top
		if (elPosY + elHeight + flyoutMenuHeight + window.scrollY > windowHeight) {
			flyoutMenuTop = elPosY - flyoutMenuHeight - 3 + window.scrollY
		}
		document.querySelector(`#${ this.props.id }_menu`).setAttribute(
			'style', `top: ${ flyoutMenuTop }px;left: ${ elPosX }px;`
		)
	}

	getBeforeAfterStyling = () => {
		let el = document.querySelector(`#${this.props.id}_div`)
		if (el) {
			const elPosY = el.getBoundingClientRect().top
			const elHeight = el.getBoundingClientRect().height
			const flyoutMenuHeight = 280
			// min height of the smallest resolution
			const minHeight = 768
			// take the window height if bigger then min height of screen resolution
			const windowHeight = window.innerHeight < minHeight ? minHeight : window.innerHeight
			let beforeAfterClass = 'bux_datepicker_arrowborder_up'
			// if there is not enough space to fly out bottom, then fly out top
			if (elPosY + elHeight + flyoutMenuHeight + window.scrollY > windowHeight) {
				beforeAfterClass = 'bux_datepicker_arrowborder_down'
			}
			return beforeAfterClass
		}
	}

	render = () => {
		// TODO: Make a function or clean up this code,
		// logic right now is really hard to follow
		let className = 'rdt' + (this.props.className
			? (Array.isArray(this.props.className)
				? ' ' + this.props.className.join(' ')
				: ' ' + this.props.className)
			: '')
		let	children = []

		if (this.props.input) {
			let finalInputProps = assign(
				{ id: `${this.props.id}_input`, type: 'text', className: `form-control ${this.props.inputClass ? this.props.inputClass : ''}`, value: this.state.inputValue },
				this.props.inputProps,
				{
					onClick: this.overrideEvent('onClick', this.openCalendar),
					//onFocus: this.overrideEvent('onFocus', this.openCalendar),
					onChange: this.overrideEvent('onChange', this.onInputChange),
					onKeyDown: this.overrideEvent('onKeyDown', this.onInputKey),
				}
			);

			if (this.props.renderInput) {
				children = [React.createElement('div', { key: 'i' }, this.props.renderInput(finalInputProps, this.openCalendar, this.closeCalendar))];
			}
			else {
				children = [
					React.createElement('div', { key: 'i', id: `${this.props.id}_div`, className: ' input_bux input-group withButton' }, [
						React.createElement('input', assign({ key: 'input', ref: this.props.focusRef }, finalInputProps)),
						/* I removed the onFocus event here, because in IE the onFocus event is triggert first, so onClick is fired after and the totggle event closes the picker immediately.
						I checked the tab order and I noticed, the picker icons never get a focus when tab is pressed, so the onFocus event is not necessary.*/
						React.createElement('span', { key: 'inputAddon', id: `${this.props.id}_datepicker_input_addon`, className: 'picker_icon input-group-addon el_input_button', onClick: this.toggleCalendar },
							/* I don't use literal template here, because i'm not sure if this code is transpiled with bable for compatibility. Literal templates are es6. */
							React.createElement('i', { key: 'inputAddon_Icon', id: `${this.props.id}_datepicker_input_addon_icon`, className: 'beta_' + (this.props.dateFormat ? 'calendar' : 'clock') })
						)
					])
				]
			}
		}
		else {
			className += ' rdtStatic'
		}
		if (this.props.open || (this.props.open === undefined && this.state.open)) {
			className += ' rdtOpen'
		}
		//!Still not working. Maybe implemented in the future.
		// return (
		// 	<div key={'before_portal_wrapper'}>
		// 		{
		// 			ReactDOM.createPortal(
		// 				<ClickableWrapper className={`flyoutmenu-wrapper ${className}`} onClickOut={this.handleClickOutside}>
		// 					<div key={`${this.props.id}_dt`} id={`${this.props.id}_menu`} className={`rdtPicker ${this.getBeforeAfterStyling()}`}>
		// 						<div key={`${this.props.id}_control`} className={'calCtrl'}>
		// 							<button key={`${this.props.id}_now`} className={'nowBtn beta_icon_today'} onClick={event => this.selectActiveDate(event)}/>
		// 							<button key={`${this.props.id}_close`} className={'closeBtn beta_icon_close'} onClick={this.closeCalendar}/>
		// 						</div>
		// 						<CalendarContainer key={`${this.props.id}_currentview`} view={this.state.currentView} viewProps={this.getComponentProps()}/>
		// 					</div>
		// 				</ClickableWrapper>,
		// 				document.querySelector('#dropdownMenu_Portal')
		// 			)
		// 		}
		// 	</div>
		// )
		return React.createElement('div', { key: 'befor_portal_wrapper' },
			children.concat(
				ReactDOM.createPortal(
					React.createElement(ClickableWrapper, { key: `${this.props.id}_flyoutmenu-wrapper`, className: `flyoutmenu-wrapper ${className}`, onClickOut: this.handleClickOutside },
					React.createElement('div', { key: `${this.props.id}_dt`, id: `${this.props.id}_menu`, className: `rdtPicker ${this.getBeforeAfterStyling()}` }, [
						React.createElement('div', { key: `${this.props.id}_control`, className: 'calCtrl' }, [
							this.props.dateFormat ? React.createElement('button', { key: `${this.props.id}_now`, className: 'nowBtn beta_today', onClick: (event) => { this.selectActiveDate(event); } }) : null,
							React.createElement('button', { key: `${this.props.id}_close`, id: `${this.props.id}_close`, className: 'closeBtn beta_close', onClick: this.closeCalendar }),
						]),
						React.createElement(CalendarContainer, { key: `${this.props.id}_currentview`, view: this.state.currentView, viewProps: this.getComponentProps() })
						]
					)),
					document.querySelector('#dropdownMenu_Portal')
				)
			)
		)
	}
}

Datetime.defaultProps = {
	className: '',
	defaultValue: '',
	inputProps: {},
	input: true,
	onFocus: function () { },
	onBlur: function () { },
	onChange: function () { },
	onViewModeChange: function () { },
	onNavigateBack: function () { },
	onNavigateForward: function () { },
	timeFormat: true,
	timeConstraints: {},
	dateFormat: true,
	strictParsing: true,
	closeOnSelect: false,
	closeOnTab: true,
	utc: false
}

const ClickableWrapper = onClickOutside(createClass({
	render: function () {
		return React.createElement('div', { className: this.props.className }, this.props.children)
	},
	handleClickOutside: function (e) {
		this.props.onClickOut(e)
	}
}))