import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Icon from '../icon/Icon'
import { Tab } from './Tab'

import { prepareClassNames } from '../../utils/ComponentUtils'

// Style
import './Tabs.scss'
/**
 * Tab and Tabs component created according to
 * _TabBar_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
class Tabs extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Text to be displayed in tooltip when you hover component.*/
    tooltip: PropTypes.string,
    /**
     * Array of indexes of items from _children_.
     * For these children, the error icon should be displayed next to the title of tab.
     */
    errorTabs: PropTypes.array,
    /** Index of selected tab. Starts at 0, default is 0. */
    activeTab: PropTypes.number,
    /**
     * Function to be called on an tab change.
     * @param index - index of selected tab
     */
    onTabChanged: PropTypes.func,
    /** Array of indexes which should be disabled */
    disabledTabs: PropTypes.any,
    /** Label to be displayed above the tabs.*/
    title: PropTypes.string,
    /** Style class from CSS for styling for tabs.*/
    className: PropTypes.string,
    /** If true background is transparent if false or undefined - white */
    transparentBackground: PropTypes.bool,
    /** If true remove padding form tile container */
    noPadding: PropTypes.bool,
    /** If true container of tiles will be scrollable */
    scrollable: PropTypes.bool,
    /** Children of the component.*/
    children: PropTypes.node

  }

  state = {
    activeTab: this.props.activeTab || 0
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.activeTab !== undefined && this.props.activeTab !== undefined && nextProps.activeTab !== this.props.activeTab) {
      this.setState({ activeTab: nextProps.activeTab })
    }
  }

  /**
   * @description Changes the active tab.
   * @param {Number} index The new active tab index.
   */
  changeActiveIndex = index => {
    const { onTabChanged } = this.props

    if (this.state.activeTab !== index) {
      if (onTabChanged) {
        const oldIndex = this.state.activeTab
        onTabChanged(index, oldIndex)
      }
      this.setState({ activeTab: index })
    }
  }

  /**
   * @description Checks if the tab at the index is included in disabled tabs.
   * @param {Number} index The index of the tab to check.
   * @returns {boolean}
   */
  proofDisabled = index => {
    const { disabledTabs } = this.props
    let result = false
    // eslint-disable-next-line
    disabledTabs && disabledTabs.map((_, tabIndex) => {
      if (disabledTabs[tabIndex] === index) {
        result = true
      }
    })
    return result
  }

  /**
   * @description Handles the tab click.
   * @param {Number} index The index of the tab which was clicked.
   */
  handleTabClick = index => {
    const { disabledTabs } = this.props
    if (!disabledTabs || (disabledTabs && !this.proofDisabled(index))) {
      this.changeActiveIndex(index)
    }
  }

  handleKeyDown = (e, index) => {
    return e.key === 'Enter' && this.handleTabClick(index) && false
  }

  /**
   * @description Renders the tab labels.
   * @param {Array} tabs tabs array
   * @param {Array} errorTabs Tabs who can have an error icon
   * @returns {Array} jsx li elements
   */
  renderTabLabels = (tabs, errorTabs) => {
    const { id } = this.props
    return tabs.map((tab, index) => {
      return (
        <li
          id={`${id}_content_tab_${index}`}
          key={`${id}_tab-${index}`}
          tabIndex={0}
          className={`nav bux_tab_label_container${index === this.state.activeTab ? ' active' : ''} ${this.proofDisabled(index) ? 'disabled' : ''}`}
          style={{ cursor: this.proofDisabled(index) ? 'default' : '' }}
          title={this.proofDisabled(index) ? tab.props?.tooltip : ''}
          onClick={() => this.handleTabClick(index)}
          onMouseOut={e => e.currentTarget.blur()}
          onKeyDown={e => this.handleKeyDown(e, index)}>
          <div
            id={`tabtext_${index}`}
            className={`${this.proofDisabled(index) ? 'disabled' : ''}`}>
            {tab.props?.title}
          </div>
          {
            errorTabs && errorTabs.includes(index) &&
            <Icon
              id={`${id}_error_${index}`}
              name={'alert_small'}
            />
          }
        </li>
      )
    })
  }

  /**
   * @description Renders the tab content.
   * @param {Array} tabs Children array which comes from props
   * @returns {Array} if tabindex of array === active tab
   */
  renderTabContent = tabs => {
    return tabs.map((tab, index) => {
      return (
        index === this.state.activeTab &&
        <Fragment key={`${this.props.id}_tab_${index}`}>
          {tab}
        </Fragment>
      )
    })
  }

  render() {
    const { children, title, id, className, errorTabs, transparentBackground, noPadding, scrollable } = this.props

    const classNames = prepareClassNames([
      'bux_tile',
      transparentBackground && 'cl_tabs_transparent',
      scrollable && 'cl_scrollable_conatiner',
      className])

    const tileContainerClassNames = prepareClassNames([
      'tileContainer',
      noPadding ? 'cl_tile_no_padded' : 'cl_tile_padded'])

    let tabs = Array.isArray(children) ? children : [children]
    tabs = tabs.filter(tab => tab !== undefined)

    return (
      <div className={classNames}>
        {
          title &&
          <label
            id={`${id}_title`}
            className='el_info_label'>
            {title}
          </label>
        }
        <ul className='tileLinks nav el_tabs bg-muted bux_tabs'>
          {this.renderTabLabels(tabs, errorTabs)}
        </ul>
        <div
          id={`${id}_tilecontainer`}
          className={tileContainerClassNames}
          tabIndex={-1}>
          {this.renderTabContent(tabs)}
        </div>
      </div>
    )
  }
}

export {
  Tabs,
  Tab
}