import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './SwitchItem.scss'

class SwitchItem extends Component {
  onClick = event => {
    this.props.onClick(event)
  }

  render() {
    const {
      tabindex,
      autoFocus,
      disabled,
      onKeyDown,
      onKeyUp,
      onFocus,
      onBlur,
      onMouseMove,
      onMouseLeave,
      onMouseDown,
      onMouseUp,
      errorIcons,
      focusRef
    } = this.props
    const toggle = this.props.toggle && 'active'

    return (
      // <div className={` ${toggle}`} tabIndex={tabindex}>
      <button
        id={this.props.id}
        tabIndex={tabindex}
        ref={focusRef}
        className={`bux_switchbtn button bux_switchbtn_border w-100 ${toggle}`}
        onClick={this.onClick}
        onFocus={onFocus || undefined}
        onBlur={onBlur || undefined}
        onKeyDown={onKeyDown || undefined}
        onKeyUp={onKeyUp || undefined}
        onMouseDown={onMouseDown || undefined}
        onMouseUp={onMouseUp || undefined}
        onMouseMove={onMouseMove || undefined}
        onMouseLeave={onMouseLeave || undefined}
        onMouseOut={e => e.currentTarget.blur()}
        type='button'
        disabled={disabled}
        autoFocus={autoFocus}>
        <span className={'bux_switchbtn_span'}>
          {this.props.text}
        </span>
        {errorIcons && <i className={'beta_icon_alert_small switch_error_icon'}></i>}
      </button>
      // </div>
    )
  }
}

SwitchItem.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string.isRequired,
  /** Function to be called on an click event.*/
  onClick: PropTypes.func.isRequired,
  /** Indicates whether the switch cis selected or not */
  toggle: PropTypes.bool,
  /** Tab index */
  tabindex: PropTypes.string,
  /** Disables item.*/
  disabled: PropTypes.bool,
  /** Ref */
  focusRef: PropTypes.any,
  /** Enable autofocus on render completion.*/
  autoFocus: PropTypes.bool,
  errorIcon: PropTypes.bool,
  /** Rect functions for access outside of the component */
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseMove: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
}

export default SwitchItem