import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

import * as UrlUtils from 'utils/UrlUtils'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as FolderDocumentAssignmentAction from 'redux/actions/FolderDocumentAssignmentAction'

import { translate } from 'language/Language'
import * as Preferences from 'redux/general/Preferences'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class AssignmentFolderDocument extends Component {

  defaultState = {
    folderid: {
      value: '',
      errorkey: ''
    },
    form: {
      value: '',
      errorkey: ''
    },
    extension: {
      value: '',
      errorkey: ''
    },
    report: {
      value: '',
      errorkey: ''
    },
    dynamicReport: {
      value: '',
      errorkey: ''
    },
    showFolderSelectorDialog: false,
    showFormExtensionReportSelectorDialog: false,
  }

  keys = [
    { rest: 'GLRNAME', ui: 'folderid' },
    { rest: 'FORM', ui: 'form' },
    { rest: 'EXT', ui: 'extension' },
    { rest: 'REPORT', ui: 'report' },
    { rest: 'WREPORT', ui: 'dynamicReport' },
  ]

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initialize the search values from preferences.
   */
  componentDidMount() {
    const { prefs } = this.props
    if (window.location.href.indexOf('?') !== -1) {
      this.initFieldsFromUrl()
    }
    else {
      // initialize search fields
      if (prefs) {
        // folder id
        const folderid = prefs[Preferences.ASSIGNMENT_FOLDER_DOC_FOLDERID] || ''
        // form
        const form = prefs[Preferences.ASSIGNMENT_FOLDER_DOC_FORM] || ''
        // extension
        const extension = prefs[Preferences.ASSIGNMENT_FOLDER_DOC_EXTENSION] || ''
        // report
        const report = prefs[Preferences.ASSIGNMENT_FOLDER_DOC_REPORT] || ''
        // dynamic reprot
        const dynamicReport = prefs[Preferences.ASSIGNMENT_FOLDER_DOC_DYNAMICREPORT] || ''

        this.setState({
          folderid: { value: folderid, errorkey: '' },
          form: { value: form, errorkey: '' },
          extension: { value: extension, errorkey: '' },
          report: { value: report, errorkey: '' },
          dynamicReport: { value: dynamicReport, errorkey: '' },
        })
      }
    }
  }

  /**
   * @description Fills the state with the values from the url and executes the search when the parameter is given.
   */
  initFieldsFromUrl = () => {
    const newValues = UrlUtils.getDataFromUrlParams(this.props.location.search, this.keys)
    this.setState({
      folderid: {
        value: newValues.folderid || '',
        errorkey: ''
      },
      form: {
        value: newValues.form || '',
        errorkey: ''
      },
      extension: {
        value: newValues.extension || '',
        errorkey: ''
      },
      report: {
        value: newValues.report || '',
        errorkey: ''
      },
      dynamicReport: {
        value: newValues.dynamicReport || '',
        errorkey: ''
      }
    }, () => {
      if (UrlUtils.urlContainsExecute()) {
        this.search()
      }
    })
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  onOpenFolderDefinitionSelector = () => {
    const callback = () => {
      this.setState({ showFolderSelectorDialog: true })
    }
    this.props.getFolderDefinitions(
      ['GLRNAME', 'GLRTITLE'],
      this.state.folderid.value,
      callback)
  }

  /**
   * @description Requests the document definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  onOpenFormExtReportSelector = () => {

    const callback = () => {
      this.setState({ showFormExtensionReportSelectorDialog: true })
    }

    this.props.getDocumentDefinitions(
      ['FORM', 'EXT', 'REPORT'],
      this.state.form.value,
      this.state.extension.value,
      this.state.report.value,
      callback
    )
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Handles the changes on inputfield.
   * @param key Key for inputfield in state
   * @param val New value of inputfield
   */
  handleInputChanged = (key, val, err) => {
    this.setState({
      [key]: {
        value: val,
        errorkey: err
      }
    })
  }

  /**
   * @description Handles the search event. Saves the search values in preferences
   * and call the rest api to search.
   * @param event The event
   */
  handleSubmitSearch = event => {
    event.preventDefault()
    this.search()
  }

  /**
   * @description Executes the search.
   */
  search = () => {
    const { folderid, form, extension, report, dynamicReport } = this.state

    // save search values in preferences
    const prefsToChange = {
      [Preferences.ASSIGNMENT_FOLDER_DOC_FOLDERID]: folderid.value,
      [Preferences.ASSIGNMENT_FOLDER_DOC_FORM]: form.value,
      [Preferences.ASSIGNMENT_FOLDER_DOC_EXTENSION]: extension.value,
      [Preferences.ASSIGNMENT_FOLDER_DOC_REPORT]: report.value,
      [Preferences.ASSIGNMENT_FOLDER_DOC_DYNAMICREPORT]: dynamicReport.value
    }

    this.props.changePrefs(prefsToChange)
    this.props.searchFolderDocumentAssignments(
      undefined,
      folderid.value,
      form.value,
      extension.value,
      report.value,
      dynamicReport.value,
    )

    // builds the parameter object which is used for url
    const urlToPush = `/assignment/folder_document${UrlUtils.createUrlParamsFromObject(this.state, this.keys)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { folderid, form, extension, report, dynamicReport } = this.state

    return (
      <Card
        title={translate('general.general')}>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_folderdocument_folderid`}
              onInputChanged={(val, err) => {
                this.handleInputChanged('folderid', val, err)
              }}
              value={folderid.value}
              title={translate('assignment.folder_document.folderid')}
              error={folderid.errorkey && translate(folderid.errorkey)}
              maxLength={32}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFolderDefinitionSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_folderdocument_form`}
              onInputChanged={(val, err) => { this.handleInputChanged('form', val, err) }}
              value={form.value}
              title={translate('general.form')}
              error={form.errorkey && translate(form.errorkey)}
              maxLength={8}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFormExtReportSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_folderdocument_extension`}
              onInputChanged={(val, err) => {
                this.handleInputChanged('extension', val, err)
              }}
              value={extension.value}
              title={translate('general.extension')}
              error={extension.errorkey && translate(extension.errorkey)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFormExtReportSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_folderdocument_report`}
              onInputChanged={(val, err) => {
                this.handleInputChanged('report', val, err)
              }}
              value={report.value}
              title={translate('general.report')}
              error={report.errorkey && translate(report.errorkey)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFormExtReportSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_folderdocument_dynamicreport`}
              title={translate('assignment.folder_document.dynamic_report')}
              maxLength={16}
              value={dynamicReport.value}
              onInputChanged={(val, err) => {
                this.handleInputChanged('dynamicReport', val, err)
              }}
            />
          </Column>
        </Row>
      </Card>
    )
  }


  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id } = this.props

    // folder definitions
    let folderDefinitions
    if (this.state.showFolderSelectorDialog) {
      folderDefinitions = this.props.selector.folders
    }

    // document definitions
    let documentDefinitions
    if (this.state.showFormExtensionReportSelectorDialog) {
      documentDefinitions = this.props.selector.documents
    }

    return (
      <>
        {this.state.showFolderSelectorDialog && (
          <SelectorDialog
            id={`${id}_folderdocument_folderdefinition_selector_dialog` /* ! The component Modal should append "_modal" to the id himself, therefor there is no need to append it on this Level. */}
            onClose={() => { this.setState({ showFolderSelectorDialog: false }) }}
            title={translate('definition.folderdefinitions')}
            header={[
              translate('definition.folder_id'),
              translate('general.title'),
            ]}
            items={folderDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newFolderID = folderDefinitions
                  .data[selectedRows][folderDefinitions.header.indexOf('GLRNAME')]
                this.setState({
                  folderid: { value: newFolderID, errorkey: '' }
                })
              }
              /* Close dialog */
              this.setState({
                showFolderSelectorDialog: false
              })
            }}
          />
        )}

        {this.state.showFormExtensionReportSelectorDialog && (
          <SelectorDialog
            id={`${id}_folderdocument_documentdefinition_selector_dialog` /* ! The component Modal should append "_modal" to the id himself, therefor there is no need to append it on this Level. */}
            onClose={() => {
              this.setState({
                showFormExtensionReportSelectorDialog: false
              })
            }}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report'),
            ]}
            items={documentDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newForm = documentDefinitions
                  .data[selectedRows][documentDefinitions.header.indexOf('FORM')]
                const newExt = documentDefinitions
                  .data[selectedRows][documentDefinitions.header.indexOf('EXT')]
                const newReport = documentDefinitions
                  .data[selectedRows][documentDefinitions.header.indexOf('REPORT')]
                this.setState({
                  form: { value: newForm, errorkey: '' },
                  extension: { value: newExt, errorkey: '' },
                  report: { value: newReport, errorkey: '' },
                })
              }
              /* Close dialog */
              this.setState({
                showFormExtensionReportSelectorDialog: false
              })
            }}
          />
        )}
      </>
    )
  }


  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_folderdocument_main`}
        className={'bux_drawer_main'}>
        {/* Render open selector dialogs */}
        {this.renderSelectorDialogs()}
        {/* general card */}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {/* main */}
        {this.renderMain()}
        {/* footer */}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    selector: state.selector,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getFolderDefinitions: (fields, foldername, callback) => {
      ModalSelectorActions.getFolderDefinitions(
        fields,
        foldername,
        undefined, // folder title
        undefined, // owner
        undefined, // brw
        callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    searchFolderDocumentAssignments: (fields, folderid, form,
      extension, report, dynamicReport, callback) => {
      FolderDocumentAssignmentAction.getFolderDocumentAssignments(
        fields,
        folderid,
        form,
        extension,
        report,
        dynamicReport,
        callback
      )(dispatch)
    }
  }
}

AssignmentFolderDocument.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssignmentFolderDocument))