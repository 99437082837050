import React, { Component } from 'react'
import './Main.scss'
import PropTypes from 'prop-types'

class Main extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Children of the component.*/
    children: PropTypes.node
  }

  /**
   * @description Renders the component
   */
  render = () => {
    const { id, children } = this.props
    return (
      <main id={id}>
        {children}
      </main>
    )
  }
}

export default Main