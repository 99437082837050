export const STATUS_VALUE_ANY = ''
export const STATUS_VALUE_ACTIVE = 'ACTIVE'
export const STATUS_VALUE_WAITING = 'WAITING'
export const STATUS_VALUE_ERROR = 'ERROR'
export const STATUS_VALUES = [
  { key: STATUS_VALUE_ANY, translationKey: 'general.any' },
  { key: STATUS_VALUE_ACTIVE, translationKey: 'general.active' },
  { key: STATUS_VALUE_WAITING, translationKey: 'general.waiting' },
  { key: STATUS_VALUE_ERROR, translationKey: 'general.error' }
]

/**
 * @description Gets the status values.
 * @param {Boolean} includeAll Flag to include all status.
 */
export const getStatus = includeAll => includeAll
  ? STATUS_VALUES
  : STATUS_VALUES.filter(el => el.key !== STATUS_VALUE_ANY)

// items for requeued
const REQUEUED_ANY = ''
const REQUEUED_MANUELLY_REQUEUED = 'MRQ'
const REQUEUED_AUTOMATICALLY_REQUEUED = 'ARD'
const REQUEUED_SRQ = 'SRQ'

export const REQUEUED_ITEMS = [
  { key: REQUEUED_ANY, translationKey: 'general.any' },
  { key: REQUEUED_MANUELLY_REQUEUED, translationKey: 'queue.manually_requeued' },
  { key: REQUEUED_AUTOMATICALLY_REQUEUED, translationKey: 'queue.automatically_requeued' },
  { key: REQUEUED_SRQ, translationKey: 'queue.srq' }
]

// items for print/bundle
export const PRINT_BUNDLE_ANY = ''
const PRINT_BUNDLE_ONLINE_PRINT = 'ONLINE-PRINT'
const PRINT_BUNDLE_AUTO_PRINT = 'AUTO-PRINT'
const PRINT_BUNDLE_BATCH_BUNDLE = 'BATCH-BUNDLE'
const PRINT_BUNDLE_BATCH_PRINT = 'BATCH-PRINT'

export const PRINT_BUNDLE_ITEMS = [
  { key: PRINT_BUNDLE_ANY, translationKey: 'general.any' },
  { key: PRINT_BUNDLE_ONLINE_PRINT, translationKey: 'queue.online_print' },
  { key: PRINT_BUNDLE_AUTO_PRINT, translationKey: 'queue.auto_print' },
  { key: PRINT_BUNDLE_BATCH_BUNDLE, translationKey: 'queue.batch_bundle' },
  { key: PRINT_BUNDLE_BATCH_PRINT, translationKey: 'queue.batch_print' }
]

// items for display
export const DISPLAY_ALL = 'A'
const DISPLAY_JOB = 'J'
const DISPLAY_DESTINATION = 'JD'
const DISPLAY_DOCUMENTS = 'D'

export const DISPLAY_ITEMS = [
  { key: DISPLAY_ALL, translationKey: 'general.all' },
  { key: DISPLAY_JOB, translationKey: 'general.job' },
  { key: DISPLAY_DESTINATION, translationKey: 'general.destination' },
  { key: DISPLAY_DOCUMENTS, translationKey: 'general.document' }
]

// use for status icons
const OUTPUT_QUEUE_STATUS_VALUE_WAITING = 'WAITING'
const OUTPUT_QUEUE_STATUS_VALUE_REQUEUED = 'REQUEUED'
const OUTPUT_QUEUE_STATUS_VALUE_HOLD = 'HOLD'
const OUTPUT_QUEUE_STATUS_VALUE_RETRY = 'RETRY'
const OUTPUT_QUEUE_STATUS_VALUE_ACTIVE = 'ACTIVE'
const OUTPUT_QUEUE_STATUS_VALUE_ERROR = 'ERROR'
const OUTPUT_QUEUE_STATUS_VALUE_DELETED = 'DELETED'
const OUTPUT_QUEUE_STATUS_VALUE_CANCELLED = 'CANCEL'
const OUTPUT_QUEUE_STATUS_VALUE_PRINTED = 'PRINTED'
const OUTPUT_QUEUE_STATUS_VALUE_SKIPPED = 'SKIPPED'
const ICON_OUTLINE = 'none'
const ICON_FILLED = 'undefined'
const betaAnthrazit30 = '#adb1be'
const betaAnthrazit70 = '#4e5463'
const dciBlue = '#0068b4'
const turquoise = '#66ccc9'
const green = '#80bb3d'
const redCorporate = '#d31409'

export const OUTPUT_QUEUE_STATUS_VALUES = [
  { key: OUTPUT_QUEUE_STATUS_VALUE_WAITING, icon: ICON_OUTLINE, color: betaAnthrazit30, translationKey: 'general.waiting' },
  { key: OUTPUT_QUEUE_STATUS_VALUE_REQUEUED, icon: ICON_OUTLINE, color: dciBlue, translationKey: 'queue.requeued' },
  { key: OUTPUT_QUEUE_STATUS_VALUE_HOLD, icon: ICON_OUTLINE, color: redCorporate, translationKey: 'queue.output_status_hold' },
  { key: OUTPUT_QUEUE_STATUS_VALUE_RETRY, icon: ICON_FILLED, color: turquoise, translationKey: 'general.retry' },
  { key: OUTPUT_QUEUE_STATUS_VALUE_ACTIVE, icon: ICON_FILLED, color: green, translationKey: 'general.active' },
  { key: OUTPUT_QUEUE_STATUS_VALUE_ERROR, icon: ICON_FILLED, color: redCorporate, translationKey: 'general.error' },
  { key: OUTPUT_QUEUE_STATUS_VALUE_DELETED, icon: ICON_FILLED, color: betaAnthrazit70, translationKey: 'general.delete' },
  { key: OUTPUT_QUEUE_STATUS_VALUE_CANCELLED, icon: ICON_OUTLINE, color: betaAnthrazit70, translationKey: 'general.cancelled' },
  { key: OUTPUT_QUEUE_STATUS_VALUE_PRINTED, icon: ICON_OUTLINE, color: green, translationKey: 'general.printed' },
  { key: OUTPUT_QUEUE_STATUS_VALUE_SKIPPED, icon: ICON_FILLED, color: betaAnthrazit30, translationKey: 'general.skipped' },
]