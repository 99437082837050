import React from 'react';

import { Ruler } from 'BetaUX2Web-Components/src'
import { RulerPosition } from 'BetaUX2Web-Components/src/types'

import * as ATTRIBUTES from '../../constants/attributes';
import { IDocumentBrowserRulerAreaProps } from 'components/documentViewer/types/DocumentBrowser.types';

export const DocumentBrowserRulerArea = ({ children, id, maxBlockLength, pageNumber, totalLineCount, isHexView }: IDocumentBrowserRulerAreaProps) => {
  return (
    <>
      <div className='doc_browser_area_interaction_ruler_vertical'>
        <Ruler
          id={`ruler_vertical_${pageNumber}`}
          max={totalLineCount !== 0 ? totalLineCount : undefined}
          onHeldColumnsRowsChanged={() => null}
          position={RulerPosition.Vertical}
          noGrip
        />
      </div>
      <div className='doc_browser_area_interaction_content_wrapper'>
        <div className={isHexView ? 'doc_browser_area_interaction_ruler_horizontal_hex' : 'doc_browser_area_interaction_ruler_horizontal'}>
          <Ruler
            id={`ruler_horizontal_${pageNumber}`}
            max={maxBlockLength !== 0 ? maxBlockLength : undefined}
            onHeldColumnsRowsChanged={() => null}
            position={RulerPosition.Horizontal}
            noGrip
          />
        </div>
        <pre className={`${ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT}`}>
          <p id={`doc_char_tester_${id}`} className='doc_browser_char_tester'>.</p>
          {children}
        </pre>
      </div>
    </>
  )
};