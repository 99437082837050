import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as CustomDialogActions from 'redux/actions/CustomDialogActions'

// Locals
import { translate } from 'language/Language'

// Components
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'
import ImportBody from './import_body/ImportBody'

// React Router
import { withRouter } from 'react-router'

class Import extends Component {

  state = {
    customDialogIndex: 0
  }

  componentDidMount = () => {
    const { customDialogIndex } = this.state
    const { match } = this.props
    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }
    if (url === 'customimport') {
      this.props.getCustomDialogs(() => {
        const { customDialogs } = this.props
        const dialogsToUse = customDialogs && customDialogs.data ? customDialogs.data.filter(d => d[customDialogs.header.indexOf('SPTUXCMD')] === 'IMPORT') : []
        dialogsToUse.length > 0 && this.props.getCustomDialog(dialogsToUse[customDialogIndex][customDialogs.header.indexOf('SPTINAME')], dialogsToUse[customDialogIndex])
      })
    }
  }

  handleCustomDialogChanged = val => {
    const { customDialogs } = this.props
    this.setState({ customDialogIndex: val }, () => {
      const dialogsToUse = customDialogs && customDialogs.data ? customDialogs.data.filter(d => d[customDialogs.header.indexOf('SPTUXCMD')] === 'IMPORT') : []
      dialogsToUse.length > 0 && this.props.getCustomDialog(dialogsToUse[this.state.customDialogIndex][customDialogs.header.indexOf('SPTINAME')], dialogsToUse[this.state.customDialogIndex])
    })
  }

  /**
   * @description Get the items for the import mode.
   * @returns {Array} The items for import mode.
   */
  getImportModeItems() {
    const items = []

    if (this.props.items) {
      this.props.items.forEach((item) =>
        items.push(item.title)
      )
    }

    return items
  }

  /**
   * @description Gets the index of a import mode to select.
   * @param {String} importmode The import mode to select.
   * @returns {Number} The active import mode index.
   */
  getActiveImportModeIndex(importmode) {
    // get the index of the match with the link of the item and the import mode
    let index = this.props.items.findIndex((item) => item.link.substring(item.link.lastIndexOf('/') + 1) === importmode)

    // select first index if there is no item selected
    if (index === -1) {
      index = 0
    }

    return index
  }

  /**
   * @description Changes the selected position of the import mode dropdown.
   * @param {Number} activeIndex The new selected index position of the import mode dropdown.
   */
  handleChangeImportMode(activeIndex) {
    const link = this.getLink(activeIndex)

    if (link !== undefined) {
      this.props.history.push(link)
    }
  }

  /**
   * @description Gets the link for a specific index.
   * @param {Number} activeIndex The index to find the link.
   */
  getLink(activeIndex) {
    // get the object with the current active index and return the link
    return this.props.items.find((item, currentIndex) => currentIndex === activeIndex).link
  }

  /**
   * @description Ensures that the dropdown for the import-selection and the
   * required drawer-content is rendered based on the current URL.
   */
  render = () => {
    const { customDialogIndex } = this.state
    const { id, match, customDialogs } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }
    const dialogsToUse = customDialogs && customDialogs.data ? customDialogs.data.filter(d => d[customDialogs.header.indexOf('SPTUXCMD')] === 'IMPORT') : []
    const items = dialogsToUse.length > 0 ? dialogsToUse.map(d => d[customDialogs.header.indexOf('SPTENAME')]) : [translate('general.none')]

    return (
      <div id={id} className='drawer_field'>
        <div id={`${id}_definition_container1`} className='drawer_header_big bux_drawer_header' style={{ height: '91px' }}>
          <div id={`${id}_definition_container2`} className='row search_row'>
            <div id={`${id}_definition_container3`} className={`dropdown el_dropdown ${url === 'customimport' ? 'bux_flex' : ''}`}>
              <div className={url === 'customimport' ? 'bux_half_width' : ''}>
                <Dropdown
                  id={`${id}_definition`}
                  title={translate('import.import_mode')}
                  items={this.getImportModeItems()}
                  activeIndex={this.getActiveImportModeIndex(url)}
                  onChange={(activeIndex) => { this.handleChangeImportMode(activeIndex) }}
                />
              </div>
              {
                url === 'customimport' &&
                <div className={'bux_half_width'}>
                  <Dropdown
                    id={`${id}_custom_dialogs`}
                    title={translate('general.select_custom_dialog')}
                    items={items}
                    onChange={val => this.handleCustomDialogChanged(val)}
                    activeIndex={customDialogIndex}
                  />
                </div>
              }
            </div>
          </div>
        </div>
        <ImportBody id={`${id}_body`} importMode={url} />
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    customDialogs: state.customDialogs.customDialogs
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCustomDialog: (sptiname, customDialog, callback) => CustomDialogActions.getCustomDialog(sptiname, customDialog, callback)(dispatch),
    getCustomDialogs: callback => CustomDialogActions.getCustomDialogs(callback)(dispatch)
  }
}

Import.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Import))