import PropTypes from 'prop-types'
import { Component, Fragment } from 'react'
import { connect } from 'react-redux'

// import * as DateUtils from 'utils/DateUtils'
import { translate } from 'language/Language'
import * as SortUtils from 'utils/SortUtils'

// Components
import { DataTable, DownloadWrapper, EmptyResult, Link, NoSearch, ResultContainer } from 'BetaUX2Web-Components/src/'

import * as SecurityProfileActions from 'redux/actions/SecurityProfileActions'
import * as Preferences from 'redux/general/Preferences'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultEntities extends Component {
  state = {
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: ENTCODE, translation: 'security.function', default: true },
      { rest: ENTDESC, translation: 'security.description', default: true },
      { rest: ENTPRFX, translation: 'security.class', default: true },
      { rest: ENTRULE, translation: 'security.entity_generation_rules', default: true },
    ]
  }


  componentDidMount = () => {
    this.handleOnRefresh()
    if (this.props.entityParts !== undefined) {
      this.getData()
    }
  }

  /**
 * @description Calls the last search again.
 */
  handleOnRefresh = () => {
    this.props.getEntityParts()
  }


  /**
   * @description gets the index of the header in redux state securityProfiles.header
   * @param {String} header header name of the header in redux state securityProfiles.header
   */
  headerData = header => this.props.entityParts.header.indexOf(header)

  /**
   * @description Gets the data for the table.
   */
  getData = () => {
    const { entityParts, } = this.props

    let data = []
    let headers = this.getUsedHeader()
    entityParts.data.forEach((element) => {
      let dataBuffer = []
      headers.forEach((header) => {
        const val = element[this.headerData(header)].toString()

        dataBuffer.push(val)

      })
      data.push(dataBuffer)
    })
    return data
  }


  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleOnRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv', lang)}
          />
        </DownloadWrapper>,
      ]
    )
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SECURITYPROFILES]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_SECURITYPROFILES].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_SECURITYPROFILES]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_SECURITYPROFILES].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  render = () => {
    const { entityParts, id, loading, drawerExpanded, lang, autoDrawer, datemask, keepPagination } = this.props
    let data = entityParts && entityParts.data ? this.getData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <Fragment>
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          { // show nosearch if Entity Parts not exist in redux
            entityParts
              ? (
                // show empty result if there are no security entities after searching
                data
                  ? (
                    <DataTable
                      loading={loading}
                      id={id}
                      header={translatedHeaders}
                      data={data}
                      cleanData={data}
                      createActionButtons={this.createActionButtons}
                      createTableRowAction={this.handleOnModifySecurityProfile}
                      columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                      additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                      fillPage={fillPage}
                      translate={key => translate(key)}
                      language={lang}
                      datemask={datemask}
                      keepPagination={keepPagination}
                    />
                  )
                  : (
                    <EmptyResult
                      description={translate('emptyresult.no_security_profiles_result')}
                      id={`${id}_emptyresult`}
                      link={translate('emptyresult.create_security_profile_link')}
                      headline={translate('emptyresult.no_result_headline')}
                    />
                  )
              )
              : (
                <NoSearch
                  id={`${id}_nosearch`}
                  message={translate('nosearch.description')}
                />
              )
          }
        </ResultContainer>
      </Fragment>
    )
  }
}

const ENTCODE = 'ENTCODE'
const ENTDESC = 'ENTDESC'
const ENTPRFX = 'ENTPRFX'
const ENTRULE = 'ENTRULE'


SearchResultEntities.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    keepPagination: state.security.keepPagination,
    entityParts: state.security.entityParts,
    usertoken: state.auth.serverdata.token,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getEntityParts: () => {
      SecurityProfileActions.getEntityParts()(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultEntities)