import moment from 'moment'

//* Constants *//
export const DEFAULT_MASK = 'DD.MM.YYYY'
export const DEFAULT_MAX_ENTRIES_PER_PAGE = 500
export const INDEX_SHIFT_INDEX_FROM_ROW = 1
export const INDEX_SHIFT_HASH_FROM_ROW = 2
export const INDEX_SHIFT_LENGTH_RAW_ROW = 2
export const TIME_DATEMASK = 'HH:mm:ss'
export const TIME_DATEMASK_MILLISECONDS = 'HH:mm:ss:SS'

//* Row helper *//
export const getIndexFromRow = (row: string[]): string => row[row.length - INDEX_SHIFT_INDEX_FROM_ROW];
export const getHashFromRow = (row: string[]): string => row[row.length - INDEX_SHIFT_HASH_FROM_ROW];
export const getLengthRawRow = (row: string[]): number => row.length - INDEX_SHIFT_LENGTH_RAW_ROW;

//* Datatable sorting helper *//
type TComparisonRefNumber = -1 | 0 | 1
type TDataTableCell = string | JSX.Element
export type TDataTableColumnType = 'date' | 'date_clean' | 'date-time' | 'date-time_clean' | 'icon' | 'icon_clean' | 'number' | 'number_clean' | 'progressbar' | 'progressbar_clean' | 'string' | 'string_clean'

export const columnIsTypeDate = (columnType: TDataTableColumnType) => {
  if (columnType === 'date' || columnType === 'date-time' || columnType === 'date-time_clean') {
    return true
  } else {
    return false
  }
}

export const compareColumn = ({ columnType, cellA, cellB, datemask, sortAscending }: { columnType: TDataTableColumnType, cellA: TDataTableCell, cellB: TDataTableCell, datemask: string, sortAscending: boolean }): TComparisonRefNumber => {
  const [a, b] = getCompareValues({ columnType: columnType, cellA: cellA, cellB: cellB, datemask: datemask })

  if (a === null || b === null) {
    console.log(`Cannot sort column with type ${columnType}`)
    return 0 // Do not change order if 'columnType' is unknown
  }

  if (!columnIsTypeDate(columnType)) {
    if (sortAscending) {
      return a > b ? -1 : b > a ? 1 : 0
    } else {
      return a > b ? 1 : b > a ? -1 : 0
    }
  }

  if (columnIsTypeDate(columnType)) {
    const c = a as moment.Moment
    const d = b as moment.Moment

    // Handle comparison with invalid date(s):
    if (!c.isValid() && !d.isValid()) return 0;
    if (!c.isValid() && d.isValid()) return -1;
    if (c.isValid() && !d.isValid()) return 1;

    // Handle comparison with valid dates:
    if (sortAscending) {
      return (c.isAfter(d) ? -1 : c.isBefore(d) ? 1 : 0)
    } else {
      return (c.isAfter(d) ? 1 : c.isBefore(d) ? -1 : 0)
    }
  }

  console.log(`Cannot sort column with type ${columnType}`)
  return 0 // Do not change order if 'columnType' is unknown
}

export const getCompareValues = ({ columnType, cellA, cellB, datemask }: { columnType: TDataTableColumnType, cellA: TDataTableCell, cellB: TDataTableCell, datemask: string }) => {
  switch (columnType) {
    case 'date': return getCompareValuesForDate(cellA as string, cellB as string, datemask);
    case 'date_clean': return getCompareValuesForDate(cellA as string, cellB as string, datemask);
    case 'date-time': return getCompareValuesForDateTime(cellA as string, cellB as string, datemask);
    case 'date-time_clean': return getCompareValuesForDateTime(cellA as string, cellB as string, datemask);
    case 'icon': return getCompareValuesForIcon(cellA as JSX.Element, cellB as JSX.Element);
    case 'icon_clean': return getCompareValuesForText(cellA as string, cellB as string);
    case 'number': return getCompareValuesForNumber(cellA as string, cellB as string);
    case 'number_clean': return getCompareValuesForNumber(cellA as string, cellB as string);
    case 'progressbar': return getCompareValuesForProgressBar(cellA as JSX.Element, cellB as JSX.Element);
    case 'progressbar_clean': return getCompareValuesForText(cellA as string, cellB as string);
    case 'string': return getCompareValuesForText(cellA as string, cellB as string);
    case 'string_clean': return getCompareValuesForText(cellA as string, cellB as string);
    default: return [null, null];
  }
}

export const getCompareValuesForDate = (dateA: string, dateB: string, datemask: string) => {
  return [moment(dateA, datemask), moment(dateB, datemask)]
}

export const getCompareValuesForDateTime = (datetimeA: string, datetimeB: string, datemask: string) => {
  return [parseDateObject(datetimeA, datemask), parseDateObject(datetimeB, datemask)]
}

export const getCompareValuesForIcon = (IconA: JSX.Element, IconB: JSX.Element): [string, string] => {
  return [IconA.props.name, IconB.props.name]
}

export const getCompareValuesForNumber = (cellA: string, cellB: string): [number, number] => {
  return [parseInt(cellA), parseInt(cellB)]
}

export const getCompareValuesForProgressBar = (ProgressBarA: JSX.Element, ProgressBarB: JSX.Element): [number, number] => {
  const valueProgressBarA: number = ProgressBarA.props.value
  const maxProgressBarA: number = ProgressBarA.props.max
  const totalProgressBarA = maxProgressBarA * 100
  const totalProgressBarAFloat = parseFloat((totalProgressBarA.toFixed(2)))

  const valueProgressBarB: number = ProgressBarB.props.value
  const maxProgressBarB: number = ProgressBarB.props.max
  const totalProgressBarB = maxProgressBarB * 100
  const totalProgressBarBFloat = parseFloat((totalProgressBarB.toFixed(2)))

  const hasValuesProgressBarA = valueProgressBarA !== 0 && maxProgressBarA !== 0
  const hasValuesProgressBarB = valueProgressBarB !== 0 && maxProgressBarB !== 0

  const a = hasValuesProgressBarA ? valueProgressBarA / totalProgressBarAFloat : 100
  const b = hasValuesProgressBarB ? valueProgressBarB / totalProgressBarBFloat : 100

  return [a, b]
}

export const getCompareValuesForText = (cellA: string, cellB: string): [string, string] => {
  return [cellA.toLowerCase(), cellB.toLowerCase()]
}

export const getGroupedTableData = (ungroupedData: string[][], indexGroupingKey: number) => {
  return ungroupedData.reduce((acc, row) => {
    const groupingKey = row[indexGroupingKey]

    if (!(groupingKey in acc)) {
      acc[groupingKey] = []
    }

    acc[groupingKey].push(row)
    return acc
  }, {} as { [groupingKey: string]: string[][]})
}

export const parseDateObject = (datetime: string, datemask: string) => {
  // This function is used to check if a value is a valid date too (e.g for datatable sorting).
  const timeLength = typeof datetime === 'string' && datetime.includes(' ') && datetime.split(' ').length > 1 ? datetime.split(' ')[1].length : 0
  const TIMEMASK_TO_USE = { 8: TIME_DATEMASK, 11: TIME_DATEMASK_MILLISECONDS, 0: '' }[timeLength]

  return moment(datetime, `${datemask} ${TIMEMASK_TO_USE}`, true)
}