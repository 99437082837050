import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as UrlUtils from 'utils/UrlUtils'
import * as UserUtils from 'utils/UserUtils'

// Components
import {
  Column, DataTable, DownloadWrapper, EmptyResult, Icon, Link, NoSearch, ResultContainer, Row, TableButton, TableButtonGroup, TableButtonGroupItem,
  TableButtonGroupSeparator
} from 'BetaUX2Web-Components/src/'
import CloneDocumentDefinitionDialog from 'components/dialogs/clone_document_definition_dialog/CloneDocumentDefinitionDialog'
import CopyDocumentDefinitionDialog from 'components/dialogs/copy_document_definition_dialog/CopyDocumentDefinitionDialog'
import CreateDocumentDefinitionDialog from 'components/dialogs/create_document_definition_dialog/CreateDocumentDefinitionDialog'
import DeleteDialog from 'components/dialogs/delete_dialog/DeleteDialog'
import ModifyDocumentDefinitionDialog from 'components/dialogs/modify_document_definition_dialog/ModifyDocumentDefinitionDialog'
import VerifyDocumentDefinitionDialog from 'components/dialogs/verify_document_definition_dialog/VerifyDocumentDefinitionDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import { deleteDocument, getDocument, getDocuments, verifyDocument } from 'redux/actions/DocumentDefinitionActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultDocument extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired
  }

  state = {
    header: this.fillHeaderInformation(),
    showTableSettingsDialog: false,
    showCreateDialog: false,
    showModifyDialog: false,
    showCopyDialog: false,
    showDeleteDialog: false,
    showVerifyDialog: false,
    showCloneDialog: false
  }

  fillHeaderInformation() {
    return [
      { rest: FORM, translation: 'general.form', default: true },
      { rest: EXT, translation: 'general.extension', default: true },
      { rest: REPORT, translation: 'general.report', default: true },
      { rest: SMODE, translation: 'definition.document_search_mode', default: true },
      { rest: BURSTMODE, translation: 'definition.document_burst_mode', default: true },
      { rest: TITLE, translation: 'general.title', default: true },
      { rest: LASTCHANGED, translation: 'general.last_changed', default: true },
      { rest: OWNER, translation: 'general.owner', default: true },
      { rest: CDATE },
      { rest: CTIME }
    ]
  }

  /**
   * @description gets the index of the header in redux state header
   * @param {String} header header name of the header in redux state header
   */
  headerData = header => this.props.documents.header.indexOf(header)

  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = () => [{ type: 'date-time', col: 4 }]

  /**
   * @description Handles the refresh button
   */
  handleRefresh = () => {
    const { prefs, getDocuments } = this.props
    getDocuments(
      undefined,
      prefs[Preferences.DEFINITION_DOCUMENT_FORM],
      prefs[Preferences.DEFINITION_DOCUMENT_EXT],
      prefs[Preferences.DEFINITION_DOCUMENT_REPORT],
      prefs[Preferences.DEFINITION_DOCUMENT_SEARCH_MODE],
      prefs[Preferences.DEFINITION_DOCUMENT_TYPE],
      prefs[Preferences.DEFINITION_DOCUMENT_OWNER],
      prefs[Preferences.DEFINITION_DOCUMENT_TITLE],
      prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTCHANNEL_ID],
      prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTFORMAT_ID],
      prefs[Preferences.DEFINITION_DOCUMENT_PPN_ID],
    )
  }

  /**
   * @description We need 'clean data' for download as csv (data in textual representation)
   */
  getCleanData() {
    const { datemask, documents } = this.props
    const data = []
    const usedHeader = this.getUsedHeader()
    documents.data.forEach(element => {
      const dataBuffer = []
      usedHeader.forEach(h => {
        if (h === SMODE && element[this.headerData(SMODE)] !== '') {
          const found = DefinitionUtils.DOCUMENT_DEFINITION_SEARCH_MODES.find(d => d.key === element[this.headerData('SMODE')])
          dataBuffer.push(found ? translate(found.translationKey) : element[this.headerData('SMODE')])
        }
        else if (h === BURSTMODE && element[this.headerData(BURSTMODE)] !== '') {
          const found = DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODES.find(d => d.key === element[this.headerData('BMODE')])
          dataBuffer.push(found ? translate(found.translationKey) : element[this.headerData('BMODE')])
        }
        else if (h === LASTCHANGED) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (h === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, element[this.headerData('CDATE')], element[this.headerData('CTIME')].substring(0, 8), false))
        }
        else {
          const val = element[this.headerData(h)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  openPrefilledDialog = () => {
    const { prefs } = this.props
    const form = prefs[Preferences.DEFINITION_DOCUMENT_FORM]
    const ext = prefs[Preferences.DEFINITION_DOCUMENT_EXT]
    const report = prefs[Preferences.DEFINITION_DOCUMENT_REPORT]
    const searchMode = prefs[Preferences.DEFINITION_DOCUMENT_SEARCH_MODE]
    const owner = prefs[Preferences.DEFINITION_DOCUMENT_OWNER]
    const title = prefs[Preferences.DEFINITION_DOCUMENT_TITLE]
    const outputChannelId = prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTCHANNEL_ID]
    const outputFormatId = prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTFORMAT_ID]
    const postprocessingNote = prefs[Preferences.DEFINITION_DOCUMENT_PPN_ID]
    const data = { form, ext, report, searchMode, owner, title, outputChannelId, outputFormatId, postprocessingNote }
    this.setState({ showCreateDialog: true, createDialogData: data })
  }

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'add'}
          iconName={'add'}
          tooltip={translate('table.create')}
          onClick={() => this.openPrefilledDialog()}
        />,
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings')}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  handleDialog = (index, dialog) => {
    const { getDocument, documents } = this.props
    const callback = () => this.setState({ [dialog]: true })
    const form = documents.data[index][this.headerData('FORM')]
    const extension = documents.data[index][this.headerData('EXT')]
    const report = documents.data[index][this.headerData('REPORT')]
    getDocument(form, extension, report, callback)
  }

  showIndices = index => {
    const { documents } = this.props
    const header = documents.header
    const data = documents.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
      { rest: 'REPORT', ui: 'report' },
    ]
    // fake the state like its used in assignment index document to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
      report: data[header.indexOf('REPORT')],
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/index_document${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  showRecipients = index => {
    const { documents } = this.props
    const header = documents.header
    const data = documents.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
      { rest: 'REPORT', ui: 'report' },
      { rest: 'WREPORT', ui: 'dynamicReport' },
    ]
    // fake the state like its used in assignment index document to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
      report: data[header.indexOf('REPORT')],
      dynamicReport: ''
      // ! Not possible to get the value at the moment.
      // dynamicReport: data[header.indexOf('WREPORT')],
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/recipient_document${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  showFolders = index => {
    const { documents } = this.props
    const header = documents.header
    const data = documents.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
      { rest: 'REPORT', ui: 'report' },
      { rest: 'WREPORT', ui: 'dynamicReport' },
    ]

    // fake the state like its used in assignment index document to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
      report: data[header.indexOf('REPORT')],
      dynamicReport: ''
      // ! Not possible to get the value at the moment.
      // dynamicReport: data[header.indexOf('WREPORT')],
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/folder_document${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  showNodes = index => {
    const { documents } = this.props
    const header = documents.header
    const data = documents.data[index]
    const keys = [
      { rest: 'FORM', ui: 'form' },
      { rest: 'EXT', ui: 'extension' },
    ]
    // fake the state like its used in assignment index document to make the url utils function to work
    const state = {
      form: data[header.indexOf('FORM')],
      extension: data[header.indexOf('EXT')],
    }
    // builds the parameter object which is used for url
    const urlToPush = `/assignment/node_document${UrlUtils.createUrlParamsFromObject(state, keys, true)}`
    this.props.history.push(urlToPush)
  }

  showVerify = index => {
    const { documents, verifyDocument } = this.props
    const documentObj = {
      FORM: documents.data[index][this.headerData('FORM')],
      EXTENSION: documents.data[index][this.headerData('EXT')],
      REPORT: documents.data[index][this.headerData('REPORT')]
    }

    verifyDocument(documentObj, () => this.setState({ showVerifyDialog: true }))
  }

  /**
   * @description Checks for the status of the row entry to decide if the verify button should be rendered
   * @param {Number} index
   */
  isSearchModeActive = index => {
    const { documents } = this.props
    const sMode = documents.data[index]?.[this.headerData('SMODE')]
    const bMode = documents.data[index]?.[this.headerData('BMODE')]
    if (sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_ABSOLUTE || sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_START_STOP) {
      return true
    }
    if (sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_AUTOBURST) {
      if (bMode === DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODE_RELATIVE) {
        return true
      }
    }

    if (sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_LIMIT_BURST || sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_COND_BURST1 || sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_COND_BURST2 || sMode === DefinitionUtils.DOCUMENT_SEARCH_MODE_COND_BURST3) {
      if (bMode === DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODE_RELATIVE || bMode === DefinitionUtils.DOCUMENT_DEFINITION_BURSTMODE_FIX) {
        return true
      }
    }
    return false
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id } = this.props
    return [
      <TableButton
        id={`${id}_tableButtonEdit_${rowIndex}`}
        iconType='material'
        iconName='edit'
        title={translate('general.edit')}
        onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
      />,
      <TableButton
        id={`${id}_tableButtonDelete_${rowIndex}`}
        iconType='material'
        iconName='delete'
        title={translate('general.delete')}
        onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
      />,
      <TableButtonGroup
        id={`${id}_moreButton${rowIndex}`}
        tooltip={translate('general.more_options')}>
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showModifyDialog')}
          id={`${id}_editBtn`}
          icon={<Icon name='edit' className='actionIcon' />}
          text={translate('general.edit')}
          title={translate('general.edit')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showCopyDialog')}
          id={`${id}_copyBtn`}
          icon={<Icon name='copy' className='actionIcon' />}
          text={translate('general.copy')}
          title={translate('general.copy')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showCloneDialog')}
          id={`${id}_cloneBtn`}
          icon={<Icon name='clone' className='actionIcon' />}
          text={translate('general.clone')}
          title={translate('general.clone')}
        />
        <TableButtonGroupItem
          onClick={() => this.handleDialog(rowIndex, 'showDeleteDialog')}
          id={`${id}_deleteBtn`}
          icon={<Icon name='delete' className='actionIcon' />}
          text={translate('general.delete')}
          title={translate('general.delete')}
        />
        <TableButtonGroupSeparator id={id} />
        {
          UserUtils.isDOCX() &&
          <>
            <TableButtonGroupItem
              onClick={() => this.showRecipients(rowIndex)}
              id={`${id}_show_recipients_btn`}
              icon={<Icon name='group' className='actionIcon' />}
              text={translate('general.display_recipients')}
              title={translate('general.display_recipients')}
            />
            <TableButtonGroupItem
              onClick={() => this.showFolders(rowIndex)}
              id={`${id}_show_folders_btn`}
              icon={<Icon name='folder' className='actionIcon' />}
              text={translate('general.display_folders')}
              title={translate('general.display_folders')}
            />
          </>
        }
        <TableButtonGroupItem
          onClick={() => this.showIndices(rowIndex)}
          id={`${id}_show_indices_btn`}
          icon={<Icon name='reference' className='actionIcon' />}
          text={translate('general.display_indices')}
          title={translate('general.display_indices')}
        />
        {
          UserUtils.isDOCX() &&
          <TableButtonGroupItem
            onClick={() => this.showNodes(rowIndex)}
            id={`${id}_show_nodes_btn`}
            icon={<Icon name='node' className='actionIcon' />}
            text={translate('general.display_nodes')}
            title={translate('general.display_nodes')}
          />
        }
        {
          this.isSearchModeActive(rowIndex) &&
          <>
            <TableButtonGroupSeparator id={id} />
            <TableButtonGroupItem
              onClick={() => this.showVerify(rowIndex)}
              id={`${id}_verify_arguments`}
              icon={<Icon name='verify' className='actionIcon' />}
              text={translate('general.verify')}
              title={translate('general.verify')}
            />
          </>
        }
      </TableButtonGroup>
    ]
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.prefs[Preferences.TABLE_SETTINGS_DEFINITION_DOCUMENT]) {
      let buffer = []
      this.props.prefs[Preferences.TABLE_SETTINGS_DEFINITION_DOCUMENT].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.prefs[Preferences.TABLE_SETTINGS_DEFINITION_DOCUMENT]) {
      return this.props.prefs[Preferences.TABLE_SETTINGS_DEFINITION_DOCUMENT].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }

  deleteDocument = () => {
    const { document, deleteDocument } = this.props
    const callback = () => this.setState({ showDeleteDialog: false })
    const documentObj = {
      FORM: document.FORM,
      EXT: document.EXT,
      REPORT: document.REPORT
    }
    deleteDocument(documentObj, callback)
  }

  render = () => {
    const { documents, id, drawerExpanded, autoDrawer, lang, datemask, document, keepPagination } = this.props
    const { showTableSettingsDialog, showCreateDialog, showDeleteDialog, showCopyDialog, showModifyDialog, showVerifyDialog, showCloneDialog } = this.state
    const data = documents && documents.data ? this.getCleanData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()

    return (
      <>
        {
          showTableSettingsDialog &&
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DEFINITION_DOCUMENT }}
          />
        }
        {
          showCreateDialog &&
          <CreateDocumentDefinitionDialog
            id={`${id}_create_dialog`}
            data={this.state.createDialogData}
            onClose={() => this.setState({ showCreateDialog: false, createDialogData: undefined })}
          />
        }
        {
          showCopyDialog &&
          <CopyDocumentDefinitionDialog
            id={`${id}_copy_dialog`}
            onClose={() => this.setState({ showCopyDialog: false })}
          />
        }
        {
          showModifyDialog &&
          <ModifyDocumentDefinitionDialog
            id={`${id}_modify_dialog`}
            onClose={() => this.setState({ showModifyDialog: false })}
          />
        }
        {
          showVerifyDialog &&
          <VerifyDocumentDefinitionDialog
            id={`${id}_verify_dialog`}
            onClose={() => this.setState({ showVerifyDialog: false })}
          />
        }
        {
          showCloneDialog &&
          <CloneDocumentDefinitionDialog
            id={`${id}_clone_dialog`}
            onClose={() => this.setState({ showCloneDialog: false })}
          />
        }
        {showDeleteDialog && (
          <DeleteDialog
            id={`${id}_deletedocumentdialog`}
            title={translate('definition.delete_document')}
            question={translate('definition.question_delete_document')}
            onClose={() => this.setState({ showDeleteDialog: false })}
            onDelete={() => this.deleteDocument()}>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}`}>
                  {translate('general.form')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}`}>
                  {document.FORM}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}`}>
                  {translate('general.extension')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}`}>
                  {document.EXT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}`}>
                  {translate('general.report')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}`}>
                  {document.REPORT}
                </p>
              </Column>
            </Row>
            <Row>
              <Column
                colMD={3}>
                <p id={`${id}`}>
                  {translate('general.title')}:
                </p>
              </Column>
              <Column
                colMD={9}>
                <p id={`${id}`}>
                  {document.LTITLE}
                </p>
              </Column>
            </Row>
          </DeleteDialog>
        )}
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {documents
            ? (
              data
                ? (
                  <DataTable
                    id={id}
                    header={translatedHeaders}
                    data={data}
                    cleanData={data}
                    selectable={true}
                    createActionButtons={this.createActionButtons}
                    createTableRowAction={index => this.handleDialog(index, 'showModifyDialog')}
                    columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
                    additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
                    translate={key => translate(key)}
                    fillPage={fillPage}
                    language={lang}
                    datemask={datemask}
                    keepPagination={keepPagination}
                  />
                )
                : (
                  <EmptyResult
                    id={`${id}_emptysearchresult`}
                    description={translate('emptyresult.no_document_result')}
                    link={translate('emptyresult.create_document_link')}
                    onClick={() => this.openPrefilledDialog()}
                    headline={translate('emptyresult.no_result_headline')}
                  />
                )
            )
            : (
              <NoSearch
                id={`${id}_nosearch`}
                message={translate('nosearch.description')}
              />
            )}
        </ResultContainer>
      </>
    )
  }
}

const FORM = 'FORM'
const EXT = 'EXT'
const REPORT = 'REPORT'
const SMODE = 'SMODE'
const BURSTMODE = 'BMODE'
const TITLE = 'LTITLE'
const OWNER = 'OWNER'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const LASTCHANGED = 'LASTCHANGED'

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    prefs: state.auth.serverdata.preferences,
    documents: state.definitions.documents.documents,
    document: state.definitions.documents.document,
    keepPagination: state.definitions.documents.keepPagination,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDocuments: (fields, form, extension, report, smode, process, owner, title, ppn, callback) => {
      getDocuments(fields, form, extension, report, smode, process, owner, title, ppn, callback)(dispatch)
    },
    getDocument: (form, extension, report, callback) => {
      getDocument(undefined, form, extension, report, callback)(dispatch)
    },
    deleteDocument: (documentObj, callback) => {
      deleteDocument(documentObj, callback)(dispatch)
    },
    verifyDocument: (documentObj, callback) => {
      verifyDocument(documentObj, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultDocument))