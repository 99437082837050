import * as BASE_UTILS from 'utils/DocViewerUtils';
import * as DATA from '../constants/data';
import * as TYPES_DOC_BROWSER from '../types/DocumentBrowser.types';
import * as TYPES_DOC_BROWSER_HEX_AREA from '../types/DocumentBrowserHexArea.types';
import * as TYPES_DOC_VIEWER from '../types/DocumentViewer.types';

export const getChangedTabsBasedOnIndexData = (oldTabs: TYPES_DOC_VIEWER.ITabData[], newTabs: TYPES_DOC_VIEWER.ITabData[]): TYPES_DOC_VIEWER.ITabData[] => {
  const changedTabs = newTabs.filter((tab: TYPES_DOC_VIEWER.ITabData) => !oldTabs.some((t: TYPES_DOC_VIEWER.ITabData) => {
    if (!('value' in t.indexToHighlight) || !('value' in tab.indexToHighlight)) return false;
    if (!('position' in t.indexToHighlight) || !('position' in tab.indexToHighlight)) return false;

    return (t.indexToHighlight.value === tab.indexToHighlight.value) && (t.indexToHighlight.position.row === tab.indexToHighlight.position.row) && (t.indexToHighlight.position.column === tab.indexToHighlight.position.column)
  }));

  return changedTabs;
};

/**
 * @description This function will parse an 8-digit hex character sequence (e.g. '42455441' for resembling the text sequence 'BETA') into chunks of 2-digit hex characters (e.g. ['42', '45', '54', '41']).
 * @param hex8Digit An 8-digit hex character sequence
 */
export const getChunks = (hex8Digit: string): string[] | [] => {
  return hex8Digit.match(/.{1,2}/g) ?? [];
};

export const isSideFile = (tabId: TYPES_DOC_VIEWER.ITabData['id']) => {
  return tabId.includes(BASE_UTILS.IDX_MARKER);
};

/**
 * description This function will parse a name for the file, which will be download.
 * @param tab Current state of the tab
 */
export const parseFilenameForDocumentDownload = (tab: TYPES_DOC_VIEWER.ITabData): string => {
  const FORM = tab.data[(tab.header.findIndex(key => key === 'FORM'))];
  const EXT = tab.data[(tab.header.findIndex(key => key === 'EXT'))];
  const MIMETYPE = !tab.id.includes(BASE_UTILS.IDX_MARKER) ? tab.data[(tab.header.findIndex(key => key === 'SRCJOBI'))] : '*.idx';

  const docIdFilename = tab.id.replace('0000000000000000', '').replace(BASE_UTILS.IDX_MARKER, '');
  const docType = MIMETYPE.lastIndexOf('.') !== -1 ? MIMETYPE.substring(MIMETYPE.lastIndexOf('.') + 1) : MIMETYPE;

  // Generate name which will be suggested as download-filename
  const filename = [FORM, EXT, docIdFilename, docType].filter(entry => ![undefined, '', '*'].includes(entry)).join('.');

  return filename;
};

/**
 * description This function will parse a name for the file, which will be download.
 * @param tab Current state of the tab
 */
export const parseFilenameForJobDownload = (tab: TYPES_DOC_VIEWER.ITabData): { queryParams: object, filename: string } => {
  if (tab.type === BASE_UTILS.SEARCH_TYPE_CONTROLM_ALL) {
    const rawOrderDate = tab.data[(tab.header.findIndex(key => key === 'ORDDATE'))];
    const orderID = tab.data[(tab.header.findIndex(key => key === 'ORDERID'))];
    const runCount = tab.data[(tab.header.findIndex(key => key === 'RUNCOUNT'))];
    const datacenter = tab.data[(tab.header.findIndex(key => key === 'DATACENT'))];
    const logSource = tab.data[(tab.header.findIndex(key => key === 'LOGSRC'))];

    const queryParams = {
      LOGSOURCE: logSource,
      ORDERDATE: rawOrderDate,
      ORDERID: orderID,
      RUNCOUNT: runCount,
      DATACENTER: datacenter,
    }

    // Replace all unwanted characters.
    const orderDate = rawOrderDate.replace(/[/.-]/g, '')

    // Generate name which will be suggested as download-filename
    const filename = [orderDate, orderID, runCount, datacenter].filter(entry => entry !== undefined).join('.') + '.log';

    return { queryParams: queryParams, filename: filename };
  }

  if (tab.type === BASE_UTILS.SEARCH_TYPE_STONEBRANCH_ALL) {
    const ukey = tab.data[(tab.header.findIndex(key => key === 'UKEY'))];
    const jobName = tab.data[(tab.header.findIndex(key => key === 'JOBNAME'))];

    const queryParams = {
      UKEY: ukey,
    }

    // Generate name which will be suggested as download-filename
    const filename = [jobName].filter(entry => entry !== undefined).join('.') + '.log';

    return { queryParams: queryParams, filename: filename };
  }

  if (tab.type === BASE_UTILS.SEARCH_TYPE_SYSLOG_ALL) {
    const ukey = tab.data[(tab.header.findIndex(key => key === 'UKEY'))];
    const jobname = tab.data[(tab.header.findIndex(key => key === 'JOBNAME'))];
    const jobid = tab.data[(tab.header.findIndex(key => key === 'JOBID'))];

    const queryParams = {
      LOGSOURCE: 'SYSL',
      UKEY: ukey
    }

    // Generate name which will be suggested as download-filename
    const filename = [jobname, jobid].filter(entry => entry !== undefined).join('.') + '.log';

    return { queryParams: queryParams, filename: filename };
  }

  if (tab.type === BASE_UTILS.SEARCH_TYPE_ZOS_ALL) {
    const jobID = tab.data[(tab.header.findIndex(key => key === 'JOBID'))];
    const jobName = tab.data[(tab.header.findIndex(key => key === 'JOBNAME'))];
    const rawStartDate = tab.data[(tab.header.findIndex(key => key === 'SRCSUBD'))];
    const rawStartTime = tab.data[(tab.header.findIndex(key => key === 'SRCSUBT'))];

    const queryParams = {
      LOGSOURCE: 'ZOS',
      JOBID: jobID,
      JOBNAME: jobName,
      SRCSUBD: rawStartDate,
      SRCSUBT: rawStartTime,
    }

    // Replace all unwanted characters.
    const startDate = rawStartDate.replace(/[/.-]/g, '')
    const startTime = rawStartTime.replace(/:/g, '')

    // Generate name which will be suggested as download-filename
    const filename = [jobID, jobName, startDate, startTime].filter(entry => entry !== undefined).join('.');

    return { queryParams: queryParams, filename: filename };
  }

  return { queryParams: {}, filename: '' };
};

/**
 * @description This function will parse the matched numbers of the selector and calculate the related indices for segment, line, hex-8-digit, chunk and text. These indices will be used to target the corresponding text part selector.
 * @param match An object containing the matched numbers of the selector
 */
export const getMatchIndicesHexToText = (match: RegExpMatchArray): TYPES_DOC_BROWSER_HEX_AREA.IHexBlockActiveBlockConfig => {
  const [hexSegmentIndexString, lineIndexString, hex8DigitIndexString, chunkIndexString] = match;

  const hexSegmentIndex = parseInt(hexSegmentIndexString);
  const lineIndex = parseInt(lineIndexString);
  const hex8DigitIndex = parseInt(hex8DigitIndexString);
  const chunkIndex = parseInt(chunkIndexString);
  const textIndex = chunkIndex + (hex8DigitIndex !== 0 ? hex8DigitIndex * 4 : hex8DigitIndex);

  return { hexSegmentIndex, lineIndex, hex8DigitIndex, chunkIndex, textIndex };
};

/**
 * @description This function will parsethe matched numbers of the selector and calculate the related indices for segment, line, hex-8-digit, chunk and text. These indices will be used to target the corresponding hex part selector.
 * @param match An object containing the matched numbers of the selector
 */
export const getMatchIndicesTextToHex = (match: RegExpMatchArray): TYPES_DOC_BROWSER_HEX_AREA.IHexBlockActiveBlockConfig => {
  const [hexSegmentIndexString, lineIndexString, textIndexString] = match;

  const hexSegmentIndex = parseInt(hexSegmentIndexString);
  const lineIndex = parseInt(lineIndexString);
  const textIndex = parseInt(textIndexString);
  const hex8DigitIndex = Math.floor(textIndex / 4);
  const chunkIndex = textIndex - (hex8DigitIndex * 4);

  return { hexSegmentIndex, lineIndex, hex8DigitIndex, chunkIndex, textIndex };
};

/**
 * @description This function will parse the matched numbers of the selector into the related indices for segment, line and text. These indices will be used to target the corresponding line selectors (text and hex).
 * @param match An object containing the matched numbers of the selector
 */
export const getMatchIndicesLineHex = (match: RegExpMatchArray): TYPES_DOC_BROWSER_HEX_AREA.IHexLineActiveBlockConfig => {
  const [hexSegmentIndexString, lineIndexString, textIndexString] = match;

  const hexSegmentIndex = parseInt(hexSegmentIndexString);
  const lineIndex = parseInt(lineIndexString);
  const textIndex = parseInt(textIndexString);

  return { hexSegmentIndex, lineIndex, textIndex };
};

/* //! TBC - BADM-2014: Tab title, see point 2
WIP: Clarify how tab title data should be rendered
*/
export const getTabTitleIndex = (type: TYPES_DOC_VIEWER.ITabData['type']) => {
  switch (type) {
    case BASE_UTILS.SEARCH_TYPE_STANDARDSELECTION: return 17;
    case BASE_UTILS.SEARCH_TYPE_STANDARDSELECTION_SIDEFILE: return 17;
    case BASE_UTILS.SEARCH_TYPE_CUSTOMSELECTION: return 0;
    case BASE_UTILS.SEARCH_TYPE_ZOS: return 0;
    case BASE_UTILS.SEARCH_TYPE_UC4: return 1;
    case BASE_UTILS.SEARCH_TYPE_CONTROLM: return 1;
    case BASE_UTILS.SEARCH_TYPE_SYSLOG: return 0;
    default: return 0;
  }
};

export const getViewMode = (hexMode: TYPES_DOC_BROWSER.TDocumentHexMode, showPageHeader: TYPES_DOC_BROWSER.TDocumentHexShowHeader, showResources: TYPES_DOC_BROWSER.TDocumentHexShowResources): TYPES_DOC_VIEWER.TTabViewMode | undefined => {
  if (hexMode === DATA.DOC_VIEWER_HEX_MODE_BLOCK && showPageHeader === DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO && showResources === DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO) return DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK;
  if (hexMode === DATA.DOC_VIEWER_HEX_MODE_BLOCK && showPageHeader === DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES && showResources === DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO) return DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER;
  if (hexMode === DATA.DOC_VIEWER_HEX_MODE_BLOCK && showPageHeader === DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES && showResources === DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES) return DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER_RESOURCES;
  if (hexMode === DATA.DOC_VIEWER_HEX_MODE_BLOCK && showPageHeader === DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO && showResources === DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES) return DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_RESOURCES;
  if (hexMode === DATA.DOC_VIEWER_HEX_MODE_LINE && showPageHeader === DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO && showResources === DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO) return DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE;
  if (hexMode === DATA.DOC_VIEWER_HEX_MODE_LINE && showPageHeader === DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES && showResources === DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO) return DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER;
  if (hexMode === DATA.DOC_VIEWER_HEX_MODE_LINE && showPageHeader === DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES && showResources === DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES) return DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER_RESOURCES;
  if (hexMode === DATA.DOC_VIEWER_HEX_MODE_LINE && showPageHeader === DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO && showResources === DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES) return DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_RESOURCES;
};

export const getViewModeConfigurationParameter = (viewMode: TYPES_DOC_VIEWER.TTabViewMode | undefined): { currentHexMode: TYPES_DOC_BROWSER.TDocumentHexMode, currentPageHeader: TYPES_DOC_BROWSER.TDocumentHexShowHeader, currentResources: TYPES_DOC_BROWSER.TDocumentHexShowResources } | null => {
  if (viewMode === DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK) {
    return {
      currentHexMode: DATA.DOC_VIEWER_HEX_MODE_BLOCK,
      currentPageHeader: DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO,
      currentResources: DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO
    }
  }

  if (viewMode === DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER) {
    return {
      currentHexMode: DATA.DOC_VIEWER_HEX_MODE_BLOCK,
      currentPageHeader: DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES,
      currentResources: DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO
    }
  }

  if (viewMode === DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER_RESOURCES) {
    return {
      currentHexMode: DATA.DOC_VIEWER_HEX_MODE_BLOCK,
      currentPageHeader: DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES,
      currentResources: DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES
    }
  }

  if (viewMode === DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_RESOURCES) {
    return {
      currentHexMode: DATA.DOC_VIEWER_HEX_MODE_BLOCK,
      currentPageHeader: DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO,
      currentResources: DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES
    }
  }

  if (viewMode === DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE) {
    return {
      currentHexMode: DATA.DOC_VIEWER_HEX_MODE_LINE,
      currentPageHeader: DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO,
      currentResources: DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO
    }
  }

  if (viewMode === DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER) {
    return {
      currentHexMode: DATA.DOC_VIEWER_HEX_MODE_LINE,
      currentPageHeader: DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES,
      currentResources: DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_NO
    }
  }

  if (viewMode === DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER_RESOURCES) {
    return {
      currentHexMode: DATA.DOC_VIEWER_HEX_MODE_LINE,
      currentPageHeader: DATA.DOC_VIEWER_HEX_SHOW_HEADER_YES,
      currentResources: DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES
    }
  }

  if (viewMode === DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_RESOURCES) {
    return {
      currentHexMode: DATA.DOC_VIEWER_HEX_MODE_LINE,
      currentPageHeader: DATA.DOC_VIEWER_HEX_SHOW_HEADER_NO,
      currentResources: DATA.DOC_VIEWER_HEX_SHOW_RESOURCES_YES
    }
  }

  return null
}

/**
 * @description This function will group the list into a structure which represents segments. A segment (= array) in line mode consists of multiple lines (objects) with the same 'marker'.
 * @param list The list contains each line represented as an object in the form of { marker: string, hexAndText: string } (line mode).
 * @param key The key by which to group the lines by, e.g. if the key is 'marker' and the value for the 1st to 4th line for 'marker' is '0001', then the key in the grouped object will be '0001', which will hold information about each line with the same value.
 */
export const groupByKey = (list: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent[], key: keyof TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent): TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent[][] => {
  const pageDataIndex = list.findIndex((line: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent) => line.marker === 'pagedata');
  const resourcesIndex = list.findIndex((line: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent) => line.marker === 'res-page');

  const workingIndex = pageDataIndex !== -1 ? pageDataIndex : resourcesIndex;

  const data = list.slice(workingIndex).reduce((hash, lineData) => ({ ...hash, [lineData[key]]: (hash[lineData[key]] || []).concat(lineData) }), {} as {[key: string]: TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent[]});

  delete data['--------'];
  delete data[''];

  // An array consisting of multiple arrays (= segments). A segment consists of one or many objects represeting a row, e.g. { marker: string, hexAndText: string }
  const groupedData = Object.entries(data).map(([key, value]) => {
    if (key === 'header' || key === 'pagedata' || key === 'res-page') return [{ marker: key, hexAndTextLine: ''}];

    if (value.length === 1) return null;

    return [...value, { marker: '--------', hexAndTextLine: '' }];
  }).filter((item): item is TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent[] => !!item);

  return groupedData;
};

/**
 * @description This function will determine if the type hitns at a document with resources (true for *.afp, *.pcl, *.ps).
 * @param tab The tab object
 */
export const isDocumentWithResources = (tab: TYPES_DOC_VIEWER.ITabData): boolean => {
  const indexExt = tab.header.indexOf(BASE_UTILS.extensionKeyMap[tab.type]);
  const mimeTypeTxt = tab.data[indexExt] ?? 'save';

  return ['*.afp', '*.pcl', '*.ps'].includes(mimeTypeTxt);
};

/**
 * @description This function will parse the general hex block data to determine max block length (for horizontal ruler) and the parsed content.
 * @param hexBlockData The unparsed text representation in block format of the HEX content of the document.
 */
export const parseHexBlockData = (hexBlockData: TYPES_DOC_VIEWER.TPageContent): TYPES_DOC_BROWSER_HEX_AREA.IParsedHexBlockData => {
  if (!hexBlockData) return { maxBlockLength: 0, content: [] };

  const lines = hexBlockData.split('\n');
  const MAX_BLOCK_LENGTH = (lines.reduce((a, b) => a.length > b.length ? a : b, '')).length

  const segmentData = parseHexBlockSegmentData(lines);

  return { maxBlockLength: MAX_BLOCK_LENGTH, content: segmentData };
};

/**
 * @description This function will parse the general hex line data to determine max block length (for horizontal ruler), lineCount (for vertical ruler) and the parsed header/body content.
 * @param hexLineData The unparsed text representation in line format of the HEX content of the document.
 */
export const parseHexLineData = (hexLineData: TYPES_DOC_VIEWER.TPageContent): TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineData => {
  if (!hexLineData) return { maxBlockLength: 0, groupedHeaderData: { data: [], lineCount: 0 }, groupedPageData: { data: [], lineCount: 0 } };

  const lines = hexLineData.split('\n');

  // *WIP: BADM-2211: Shortening the line to 500 letters is roughly 3800px in maximum width, which is 1 meter monitor size
  // Some documents, e.g. Noctua Demo PDFs, have misaligned data and they have more than 10.000 letters per line,
  // which results in hundreds of thousands of DOM nodes per document. The app will crash, because the PC runs out of memory to render it.
  // Limiting the maximum number of lines will prevent this memory issue until the backend adjusts the parsing of the HEX line data.
  const SHORTENED_LINES = lines.map((line: string) => line.substring(0, 500));

  const MAX_BLOCK_LENGTH = (SHORTENED_LINES.reduce((a, b) => a.length > b.length ? a : b, '')).length

  const headerIndex = SHORTENED_LINES.findIndex((line: string) => line.trim() === 'header');
  const pageDataIndex = SHORTENED_LINES.findIndex((line: string) => line.trim() === 'pagedata');

  // *TBC (Backend): For line mode the header data will be in block mode every time until backend enabled line mode for header
  // The header data is parsed for the rendering as quasi-block mode
  const headerSegmentData = headerIndex !== -1 ? [...parseHexBlockSegmentData(SHORTENED_LINES.slice(headerIndex, pageDataIndex)), { marker: '--------', hex: null, text: '' }] : [];

  const pageSegmentData = parseHexLineSegmentData(SHORTENED_LINES.slice(pageDataIndex));
  const groupedPageData = groupByKey(pageSegmentData, 'marker');

  return {
    groupedHeaderData: {
      data: headerSegmentData,
      lineCount: headerSegmentData.length
    },
    groupedPageData: {
      data: groupedPageData,
      lineCount: pageSegmentData.length
    },
    maxBlockLength: MAX_BLOCK_LENGTH,
  };
};

/**
 * @description This function will parse the general hex line data to determine max block length (for horizontal ruler), lineCount (for vertical ruler) and the parsed header/body content.
 * @param hexLineData The unparsed text representation in line format of the HEX content of the document.
 */
export const parseHexLineDataWithResources = (hexLineData: TYPES_DOC_VIEWER.TPageContent): TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataWithResources => {
  if (!hexLineData) return { maxBlockLength: 0, groupedResourcesData: { data: [], lineCount: 0 }, groupedPageData: { data: [], lineCount: 0 } };

  const lines = hexLineData.split('\n');
  const MAX_BLOCK_LENGTH = (lines.reduce((a, b) => a.length > b.length ? a : b, '')).length

  // 'pagedata' block is appearing only once in the HEX view line data:
  const pageDataIndex = lines.findIndex((line: string) => line.trim() === 'pagedata');

  // 'res-page' block can appear multiple times in the HEX view line data based on the document:
  const resourceIndices = lines.map((line: string, i: number) => line.trim() === 'res-page' ? i : null).filter((i) => i !== null) as number[];

  // This will parse all 'res-page' block data and group them, so that they can be displayed sequentially in the component:
  const groupedResourcesData = resourceIndices.flatMap((_, i: number) => {
    const startIndex = resourceIndices[i];
    const endIndex = i !== resourceIndices.length - 1 ? resourceIndices[i + 1] : pageDataIndex !== -1 ? pageDataIndex : undefined; // End index is either index of next 'res-page', index of 'pagedata' or until end of data (= undefined)

    const parsedResourcesData = parseHexLineSegmentData(lines.slice(startIndex, endIndex));
    const groupedResourcesData = groupByKey(parsedResourcesData, 'marker');
    return groupedResourcesData
  });

  const pageSegmentData = parseHexLineSegmentData(lines.slice(pageDataIndex));
  const groupedPageData = groupByKey(pageSegmentData, 'marker');

  const lineCountBodySegmentData = groupedPageData.reduce((acc: number, val: any) => acc + val.length, 0);
  const lineCountResourcesSegmentData = groupedResourcesData.reduce((acc: number, val: any) => acc + val.length, 0);

  return {
    groupedPageData: {
      data: groupedPageData,
      lineCount: lineCountBodySegmentData
    },
    groupedResourcesData: {
      data: groupedResourcesData,
      lineCount: lineCountResourcesSegmentData
    },
    maxBlockLength: MAX_BLOCK_LENGTH,
  };
};

/**
 * @description This function will parse the general hex line data to determine max block length (for horizontal ruler), lineCount (for vertical ruler) and the parsed header/body content.
 * @param hexLineData The unparsed text representation in line format of the HEX content of the document.
 */
export const parseHexLineDataWithHeaderWithResources = (hexLineData: TYPES_DOC_VIEWER.TPageContent): TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataWithHeaderWithResources => {
  if (!hexLineData) return { maxBlockLength: 0, groupedHeaderResourcesData: { data: [{ header: { data: [], lineCount: 0 }, resources: { data: [], lineCount: 0 }, startLineIndex: 0 }], lineCount: 0 }, groupedPageData: { data: [], lineCount: 0 } };

  const lines = hexLineData.split('\n');
  const MAX_BLOCK_LENGTH = (lines.reduce((a, b) => a.length > b.length ? a : b, '')).length

  // *TBC (Backend): For line mode the header data will be in block mode every time until backend enabled line mode for header
  // 'header' block can appear multiple times in the HEX view line data based on the document:
  const headerIndices = lines.map((line: string, i: number) => line.trim() === 'header' ? i : null).filter((i) => i !== null) as number[];

  // 'pagedata' block is appearing only once in the HEX view line data:
  const pageDataIndex = lines.findIndex((line: string) => line.trim() === 'pagedata');

  // 'res-page' block can appear multiple times in the HEX view line data based on the document:
  const resourceIndices = lines.map((line: string, i: number) => line.trim() === 'res-page' ? i : null).filter((i) => i !== null) as number[];

  const groupedHeaderResourcesData = headerIndices.map((headerIndex, i) => {

    const headerStartIndex: number = headerIndex;
    const headerEndIndex: number | undefined = i < resourceIndices.length ? resourceIndices[i] : pageDataIndex !== -1 ? pageDataIndex : undefined;
    const resourcesStartIndex = i < resourceIndices.length ? resourceIndices[i] : undefined;
    const resourcesEndIndex = i + 1 < headerIndices.length ? headerIndices[i + 1] : pageDataIndex !== -1 ? pageDataIndex : undefined;

    const groupedHeaderData = [...parseHexBlockSegmentData(lines.slice(headerStartIndex, headerEndIndex)), { marker: '--------', hex: null, text: '' }]
    const resourcesSegmentData = resourcesStartIndex ? parseHexLineSegmentData(lines.slice(resourcesStartIndex, resourcesEndIndex)) : [];
    const groupedResourcesData = resourcesSegmentData.length !== 0 ? groupByKey(resourcesSegmentData, 'marker') : [];

    return {
      header: {
        data: groupedHeaderData,
        lineCount: groupedHeaderData.length
      },
      resources: {
        data: groupedResourcesData,
        lineCount: groupedResourcesData.reduce((acc, val) => acc + val.length, 0)
      },
      startLineIndex: headerIndex
    }
  });

  const pageSegmentData = parseHexLineSegmentData(lines.slice(pageDataIndex));
  const groupedPageData = groupByKey(pageSegmentData, 'marker');

  const lineCountHeaderResources = groupedHeaderResourcesData.reduce((acc, val) => acc + val.header.lineCount + val.resources.lineCount, 0);
  const lineCountPageSegmentData = groupedPageData.reduce((acc: number, val: any) => acc + val.length, 0);

  return {
    groupedHeaderResourcesData: {
      data: groupedHeaderResourcesData,
      lineCount: lineCountHeaderResources
    },
    groupedPageData: {
      data: groupedPageData,
      lineCount: lineCountPageSegmentData
    },
    maxBlockLength: MAX_BLOCK_LENGTH
  };
};

/**
 * @description This function will parse the line into an object which holds the information about the different parts of the line (marker area, hex area, text area).
 * @param lines An array consisting of the lines which are part of the hex block data
 */
export const parseHexBlockSegmentData = (lines: string[]): TYPES_DOC_BROWSER_HEX_AREA.IParsedHexBlockDataContent[] => {
  return lines.map((line: string) => {
    const lineSet = line.split('*');
    const leftLine = lineSet[0].trim().split(' ');
    const markerLine = leftLine[0] ?? null;
    const hexLine = leftLine?.slice(1) ?? null;
    const textLine = hexLine ? lineSet.slice(1, -1).join(' ').slice(1, -1) : ''; // Remove 1st and last whitespace

    return { marker: markerLine, hex: hexLine, text: textLine };
  });
};

/**
 * @description This function will parse the line into an object which holds the information about the different parts of the line (marker area, hex+text area).
 * @param lines An array consisting of the lines which are part of the hex line data
 */
export const parseHexLineSegmentData = (lines: string[]): TYPES_DOC_BROWSER_HEX_AREA.IParsedHexLineDataContent[] => {
  return lines.map((line: string) => {
    const [markerLine, ...hexAndTextLine] = line.split(' '); // Split by 1st occurrence of whitespace

    return { marker: markerLine, hexAndTextLine: hexAndTextLine ? hexAndTextLine.join(' ') : '' };
  });
};

/**
 * @description This function will parse a unique marker to identify an index.
 * @param indexToHighlight Object with information about the index to highlight in the document.
 */
export const parseIndexToHighlightMarker = (indexToHighlight: TYPES_DOC_VIEWER.TIndexToHighlight) => {
  if (!('position' in indexToHighlight) || !('value' in indexToHighlight)) return '';

  return `${indexToHighlight.position.page}_${indexToHighlight.position.row}_${indexToHighlight.value}`;
};

/**
 * @description This function formats the page content according to the max block length based on LGRMAXRL or the longest line based on the document type.
 * @param tab The tab object
 */
export const parsePageContent = (tab: TYPES_DOC_VIEWER.ITabData): TYPES_DOC_VIEWER.IParsedTextDataContent => {
  const paginatedContentRaw = tab.pagesText.data && tab.pagesText.data[0] ? tab.pagesText.data[0].split('\n') : []

  if (paginatedContentRaw.length === 0) {
    return { maxBlockLength: 0, content: [] }
  }

  const MAX_BLOCK_LENGTH_INDEX = tab.header.indexOf(BASE_UTILS.blockLengthMap[tab.type])
  const MAX_BLOCK_LENGTH_DEFAULT = MAX_BLOCK_LENGTH_INDEX !== -1 ? parseInt(tab.data[MAX_BLOCK_LENGTH_INDEX]) : 0
  const MAX_BLOCK_LENGTH_ALT = (paginatedContentRaw.reduce((a, b) => a.length > b.length ? a : b, '')).length
  const MAX_BLOCK_LENGTH = MAX_BLOCK_LENGTH_DEFAULT !== 0 ? MAX_BLOCK_LENGTH_DEFAULT : MAX_BLOCK_LENGTH_ALT

  const content = paginatedContentRaw.map((c) => c.slice(0, MAX_BLOCK_LENGTH))
  return { maxBlockLength: MAX_BLOCK_LENGTH, content: content }
};