import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ActionButtons.scss'

export default class ActionButtons extends Component {

  componentDidMount = () => {
    const actionButtons = Array.from(document.querySelectorAll('.bux_hierarchy_actionbutton'))
    actionButtons.forEach(actionButton => {
      actionButton.classList.add('bux_remove_left_border')
    })
  }

  render() {
    const { dataObj, createActionButtons, actionButtonsLength, index, rootKey, parentKey, level } = this.props
    return (
      createActionButtons && createActionButtons(dataObj, index, rootKey, parentKey, level).map((button, i) => {
        const multiplicator = (actionButtonsLength - i - 1) >= 0 ? (actionButtonsLength - i - 1) : 0
        return (
          <td className={'bux_hierarchy_actionbutton'} key={`actionButton_${i}`} style={{ right: multiplicator * 40 + 'px' }}>
            {button}
          </td>
        )
      })
    )
  }
}

ActionButtons.propTypes = {
  /** Data of the row */
  dataObj: PropTypes.any,
  /**
   * Function that returns array of JSX elements which are displayed at end of each row.
   * @param {} dataObj
   * @param {number} index
   * @param {string} rootKey
   * @param {string} parentKey
   * @param {number} level
  */
  createActionButtons: PropTypes.func,
  /** Length of the created action buttons.*/
  actionButtonsLength: PropTypes.number,
  /** Index of the row */
  index: PropTypes.number,
  /** Hashed data of the root row */
  rootKey: PropTypes.any,
  /** Hashed data of the parent row */
  parentKey: PropTypes.any,
  /** Level in the hierarchy depth */
  level: PropTypes.any
}