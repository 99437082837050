import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

// Locals
import { translate } from 'language/Language'

// Components
import { Dropdown } from 'BetaUX2Web-Components/src/'
import SearchBody from './search_body/SearchBody'

import * as CustomDialogActions from 'redux/actions/CustomDialogActions'
import * as CustomSelectionActions from 'redux/actions/CustomSelectionActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'

// React Router
import { withRouter } from 'react-router'

class Search extends Component {

  state = {
    customDialogIndex: 0
  }

  componentDidMount = () => {
    const { match, getCustomDialogs } = this.props
    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }
    if (url === 'customsearch') {
      getCustomDialogs()
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.customDialogs !== this.props.customDialogs) {
      this.loadCustomDialogData()
    }
  }

  loadCustomDialogData = () => {
    const { customDialogIndex } = this.state
    const { changePrefs, customDialogs, customSelectionCache, getCustomDialog, loadDataFromCache, preferences, resetCustomSelectionData } = this.props

    const dialogsToUse = customDialogs ? customDialogs.data : []
    const headersToUse = customDialogs ? customDialogs.header : []

    const preferenceKeyToUse = this.props.systemname === 'DOCX' ? Preferences.SEARCH_CURRENT_CUSTOM_DIALOG_DOCX : Preferences.SEARCH_CURRENT_CUSTOM_DIALOG_LOGX
    const indexFromStorage = dialogsToUse.findIndex(data => preferences[preferenceKeyToUse] === data[headersToUse.indexOf('SPTINAME')])
    const indexToUse = indexFromStorage !== -1 ? indexFromStorage : customDialogIndex

    const searchProfileNameInternal = dialogsToUse[indexToUse][customDialogs.header.indexOf('SPTINAME')]

    changePrefs({ [preferenceKeyToUse]: searchProfileNameInternal })

    const isCached = customSelectionCache && searchProfileNameInternal in customSelectionCache

    if (isCached) {
      const resultTableType = dialogsToUse[indexToUse][customDialogs.header.indexOf('SLTITYPE')]
      loadDataFromCache(searchProfileNameInternal, resultTableType)
    }

    if (!isCached && dialogsToUse.length > 0) {
      resetCustomSelectionData()
      getCustomDialog(searchProfileNameInternal, dialogsToUse[indexToUse])
    }

    this.setState({
      customDialogIndex: indexToUse
    })
  }

  handleCustomDialogChanged = index => {
    const { changePrefs, customDialogs, customSelectionCache, getCustomDialog, loadDataFromCache, resetCustomSelectionData } = this.props

    this.setState({ customDialogIndex: index }, () => {
      const dialogsToUse = customDialogs ? customDialogs.data : []
      if (dialogsToUse.length === 0) return

      const preferenceKeyToChange = this.props.systemname === 'DOCX' ? Preferences.SEARCH_CURRENT_CUSTOM_DIALOG_DOCX : Preferences.SEARCH_CURRENT_CUSTOM_DIALOG_LOGX
      const searchProfileNameInternal = dialogsToUse[index][customDialogs.header.indexOf('SPTINAME')]

      changePrefs({ [preferenceKeyToChange]: searchProfileNameInternal })

      const isCached = customSelectionCache && searchProfileNameInternal in customSelectionCache

      if (isCached) {
        const resultTableType = dialogsToUse[index][customDialogs.header.indexOf('SLTITYPE')]
        loadDataFromCache(searchProfileNameInternal, resultTableType)
      } else {
        resetCustomSelectionData()
        getCustomDialog(searchProfileNameInternal, dialogsToUse[index])
      }
    })
  }

  /**
   * @description Get the items for the search mode.
   * @returns {Array} The search mode items.
   */
  getSearchModeItems() {
    const items = []

    this.props.items.forEach((item) =>
      items.push(item.title)
    )

    return items
  }

  /**
   * @description Gets the index of a search mode to select.
   * @param {String} searchmode The search mode to select.
   * @returns {Number} The active index.
   */
  getActiveSearchModeIndex(searchmode) {
    // get the index of the match with the link of the item and the search mode
    let index = this.props.items.findIndex((item) => item.link.substring(item.link.lastIndexOf('/') + 1) === searchmode)

    // select first index if there is no item selected
    if (index === -1) {
      index = 0
    }

    return index
  }

  /**
   * @description Changes the selected position of the search mode dropdown.
   * @param {Number} activeIndex The new selected index position of the search mode dropdown.
   */
  handleChangeSearchMode(activeIndex) {
    const link = this.getLink(activeIndex)

    if (link !== undefined) {
      this.props.history.push(link)
    }
  }

  /**
   * @description Gets the link for a specific index.
   * @param {Number} activeIndex The index to find the link.
   */
  getLink(activeIndex) {
    // get the object with the current active index and return the link
    return this.props.items.find((item, currentIndex) => currentIndex === activeIndex).link
  }

  render = () => {
    const { customDialogIndex } = this.state
    const { id, match, customDialogs } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    const dialogsToUse = customDialogs ? customDialogs.data : []
    const items = dialogsToUse.length > 0 ? dialogsToUse.map(d => d[customDialogs.header.indexOf('SPTENAME')]) : [translate('general.none')]

    return (
      <div id={id} className='drawer_field'>
        <div id={`${id}_definition_container1`} className='drawer_header_big bux_drawer_header' style={{ height: '91px' }}>
          <div id={`${id}_definition_container2`} className='row search_row'>
            <div id={`${id}_definition_container3`} className={`dropdown el_dropdown ${url === 'customsearch' ? 'bux_flex' : ''}`}>
              <div className={url === 'customsearch' ? 'bux_half_width' : ''}>
                <Dropdown
                  id={`${id}_definition`}
                  title={translate('search.search_mode')}
                  items={this.getSearchModeItems()}
                  activeIndex={this.getActiveSearchModeIndex(url)}
                  onChange={activeIndex => this.handleChangeSearchMode(activeIndex)}
                />
              </div>
              {
                url === 'customsearch' &&
                <div className={'bux_half_width'}>
                  <Dropdown
                    id={`${id}_custom_dialogs`}
                    title={translate('general.select_custom_dialog')}
                    items={items}
                    onChange={index => this.handleCustomDialogChanged(index)}
                    activeIndex={customDialogIndex}
                  />
                </div>
              }
            </div>
          </div>
        </div>

        <SearchBody id={`${id}_body`} searchMode={url} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    customDialogs: state.customDialogs.customDialogs,
    customSelectionCache: state.search.customselection.cache,
    preferences: state.auth.serverdata.preferences,
    systemname: state.auth?.serverdata?.systemname
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => PreferenceActions.changePrefs(prefs)(dispatch),
    getCustomDialog: (sptiname, customDialog, callback) => CustomDialogActions.getCustomDialog(sptiname, customDialog, callback)(dispatch),
    getCustomDialogs: callback => CustomDialogActions.getCustomDialogsForSearch(callback)(dispatch),
    getResultTable: (resultTableID, callback) => CustomDialogActions.getResultTable(resultTableID, callback)(dispatch),
    loadDataFromCache: (cacheKey, resultTableType, callback) => {
      CustomSelectionActions.getCachedSearchResults(cacheKey, resultTableType)(dispatch)
      CustomDialogActions.getCachedCustomDialog(cacheKey)(dispatch)
      PreferenceActions.getCachedSearchParameters(cacheKey)(dispatch)
      CustomDialogActions.getCachedResultTable(cacheKey, callback)(dispatch)
    },
    resetCustomSelectionData: () => CustomSelectionActions.resetCustomSelectionData()(dispatch)
  }
}

Search.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search))