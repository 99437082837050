import { translate } from 'language/Language'
import { Component } from 'react'

import {
  Button, Card, Column, DataTable, MetaDataGrid, Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

import { connect } from 'react-redux'
import * as Utils from 'utils/Utils'

import * as Preferences from 'redux/general/Preferences'

class VerifyOutputFormat extends Component {

  /**
   * @description Gets the index of a specific header.
   * @param {String} header specific header of the verified output channel
   */
  headerData = header => this.props.verify.header.indexOf(header)

  getData = () => {
    const { verify } = this.props
    let buffer = []
    verify.data.forEach(d => {
      buffer.push([
        d[this.headerData('LOCATION')],
        d[this.headerData('RECI')],
        d[this.headerData('FORM')],
        d[this.headerData('EXT')],
        d[this.headerData('REPORT')]
      ])
    })
    return buffer
  }

  render = () => {
    const { id, onClose, outputFormat, lang, datemask } = this.props
    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={id}
          title={translate('definition.output_format_verify')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('definition.output_format_id'), value: outputFormat['PCR'] },
              { label: translate('general.owner'), value: outputFormat['OWNER'] },
              { label: translate('general.title'), value: outputFormat['PCRTITLE'] },
            ]}
            columns={4}
          />
        </Header>
        <Main>
          <Card className={Utils.getSpecificContainerStyle()}>
            <Row className={'bux_modal_container'}>
              <Column colMD={12} className={'bux_full_height'}>
                <DataTable
                  id={`${id}_verify`}
                  header={[
                    translate('general.found_in'),
                    translate('recipient.reci_id'),
                    translate('general.form'),
                    translate('general.extension'),
                    translate('general.report')
                  ]}
                  columnSortDefs={['string', 'string', 'string', 'string', 'string']}
                  data={this.getData()}
                  noAction
                  language={lang}
                  datemask={datemask}
                  translate={key => translate(key)}
                  menu={false}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }

}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    outputFormat: state.definitions.outputformats.outputFormat,
    verify: state.definitions.outputformats.verify
  }
}

export default connect(mapStateToProps)(VerifyOutputFormat)