import PropTypes from 'prop-types'
import { Component } from 'react'
import * as SortUtils from 'utils/SortUtils'

// components
import { DataTable, DownloadWrapper, Link, ResultContainer, TableButton } from 'BetaUX2Web-Components/src/'
import ExternalcmdResultDialog from 'components/dialogs/external_command_result_dialog/ExternalcmdResultDialog'
import TableSettings from 'components/table_settings/TableSettings'

// redux
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as ExternalcmdActions from 'redux/actions/ExternalCommandDefinitionActions'
import * as MaintenanceActions from 'redux/actions/MaintenanceActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import { getTranslatedHeaders } from 'utils/ColumnUtils';

class SearchResultServerExternalCommand extends Component {

  state = {
    pids: {},
    currentExternalcmdState: {
      pid: '',
      id: '',
      desc: ''
    },
    showResultDialog: false,
    showTableSettingsDialog: false,
    header: this.fillHeaderInformation()
  }

  fillHeaderInformation() {
    return [
      { rest: EXTERNALCMD_ID, translation: 'external_command.id', default: true },
      { rest: DESCRIPTION, translation: 'external_command.description', default: true },
      { rest: COMMAND, translation: 'external_command.command', default: true },
      { rest: ARGUMENTS, translation: 'external_command.arguments', default: true },
      { rest: PROCESS_ID, translation: 'database.process_id', default: true },
      { rest: UTLDATE },
      { rest: UTLTIME },
      { rest: CDATE },
      { rest: CTIME },
      { rest: CUSER }
    ]
  }

  /**
   * @description Sends the getExternalCommands request.
   */
  componentDidMount = () => {
    const callback = () => {
      const pids = {}
      this.props.externalcmds?.data.forEach(el => {
        pids[el[0]] = ''
      })
      this.setState(() => ({ pids }))
    }
    this.props.getExternalcmds('*', '', '', callback)
  }

  handleResult = rowIndex => {
    const pid = this.getExternalcmdData()[rowIndex][4]
    const id = this.getExternalcmdData()[rowIndex][0]
    const desc = this.getExternalcmdData()[rowIndex][1]
    const callback = () => {
      this.setState({
        showResultDialog: true,
        currentExternalcmdState: {
          pid, id, desc
        }
      })
    }
    this.props.getJobOutput(pid, callback)
  }

  /**
   * @description Refreshes the table
   */
  handleRefresh = () => {
    this.props.getExternalcmds('*', '', '')
  }

  componentDidUpdate = () => {
    var allTableButtons = Array.from(document.querySelectorAll('.button'))
    allTableButtons.forEach(button => {
      button.classList.remove('bux_disabled_actionbutton')
      let iconContainer = button.querySelector('div')
      if (iconContainer.classList.contains('disabled')) {
        button.classList.add('bux_disabled_actionbutton')
      }
    })
  }

  /**
   * @description Creates the action buttons for the table.
   * @param rowIndex The index of the current row.
   */
  createActionButtons = rowIndex => {
    const { id, lang } = this.props
    const pid = this.getExternalcmdData()[rowIndex] && this.getExternalcmdData()[rowIndex][4] !== undefined ? this.getExternalcmdData()[rowIndex][4] : ''
    const disableShowResult = pid === ''
    return [
      <TableButton
        id={`${id}_tableButtonExecute_${rowIndex}`}
        iconType='material'
        iconName='execute'
        title={translate('database.execute', lang)}
        onClick={() => { this.handleExecute(rowIndex) }}
      />,
      <TableButton
        id={`${id}_tableButtonResult_${rowIndex}`}
        iconType='material'
        iconName='result'
        title={translate('database.result', lang)}
        disabled={disableShowResult}
        onClick={() => { this.handleResult(rowIndex) }}
      />
    ]
  }

  /**
   * @description handles the execution when execute button is clicked
   * @param rowIndex The index of the current row
   */
  handleExecute = rowIndex => {
    const externalcmds = this.props.externalcmds.data
    const utl = externalcmds[rowIndex][0]
    // create new array out of state to get the correct row
    const pids = Object.keys(this.state.pids)
    const pidType = pids[rowIndex]
    const callback = (utility) => {
      this.setState(prevState => ({
        ...prevState,
        pids: {
          ...prevState.pids,
          [pidType]: utility.PID
        }
      }))
    }
    this.props.executeExternalcmd(utl, callback)
  }

  /**
   * @description gets the index of the header in redux state externalcmds.header
   * @param {String} header header name of the header in redux state externalcmds.header
   */
  headerData = header => this.props.externalcmds.header.indexOf(header)

  /**
   * @description Creates the buttons for the tablemenu.
   * @param data The data which is shown in the table.
   * @param header The headers which are shown in the tableheader
   */
  createInteractionButtons = (data, header) => {
    const { lang } = this.props
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh', lang)}
          onClick={this.handleRefresh}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => { }}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>,
        <Link
          id={'settings'}
          iconName={'settings'}
          tooltip={translate('table.settings', lang)}
          onClick={() => this.setState({ showTableSettingsDialog: true })}
        />,
      ]
    )
  }

  /**
   * @description builds the data to a an array which is used later
   */
  getExternalcmdData = () => {
    const { externalcmds, datemask } = this.props
    const data = []
    let headers = this.getUsedHeader()
    let pidValues = Object.values(this.state.pids)
    externalcmds.data.forEach((el, i) => {
      let dataBuffer = []
      headers.forEach(header => {
        if (header === PROCESS_ID) {
          dataBuffer.push(pidValues[i] !== undefined ? pidValues[i].toString() : '')
        }
        else if (header === CTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8)))
        }
        else if (header === CDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('CDATE')], el[this.headerData('CTIME')].substring(0, 8), false))
        }
        else if (header === UTLTIME) {
          dataBuffer.push(DateUtils.getDate(' ', el[this.headerData('UTLDATE')], el[this.headerData('UTLTIME')].substring(0, 8)))
        }
        else if (header === UTLDATE) {
          dataBuffer.push(DateUtils.getDate(datemask, el[this.headerData('UTLDATE')], el[this.headerData('UTLTIME')].substring(0, 8), false))
        }
        else {
          const val = el[this.headerData(header)].toString()
          if (val.length === 16 && DateUtils.isDate(val, 'DD.MM.YYYY HH:mm')) {
            dataBuffer.push(`${val}:00`)
          }
          else {
            dataBuffer.push(val)
          }
        }
      })
      data.push(dataBuffer)
    })
    return data
  }

  /**
   * @description Gets the used headers.
   * @returns {Array} The used headers.
   */
  getUsedHeader = () => {
    const { header } = this.state
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DATABASE_EXTERNALCMD]) {
      let buffer = []
      this.props.preferences[Preferences.TABLE_SETTINGS_DATABASE_EXTERNALCMD].displayedHeaders.forEach(d => {
        // fallback if old preferences saved the columns as language keys and rest keys
        for (let i = 0; i < header.length; i++) {
          if (header[i].rest === d || header[i].translation === d) {
            buffer.push(header[i].rest)
            break
          }
        }
      })
      return buffer
    } else {
      return this.getDefaultHeader()
    }
  }

  /**
   * @description Gets the fill page info.
   * @returns {Boolean} The fill page info.
   */
  getFillPageInfo = () => {
    if (this.props.preferences[Preferences.TABLE_SETTINGS_DATABASE_EXTERNALCMD]) {
      return this.props.preferences[Preferences.TABLE_SETTINGS_DATABASE_EXTERNALCMD].fillPage
    } else {
      return true
    }
  }

  /**
   * @description Gets the default headers for the table.
   * @returns {Array} The default headers.
   */
  getDefaultHeader = () => {
    const { header } = this.state
    const buffer = []
    if (header) {
      header.filter(h => h.default).forEach(h => buffer.push(h.rest))
    }
    return buffer
  }


  /**
   * @description Gets specific column sort definitions.
   */
  getColumnSortDefs = (data, header) => SortUtils.getSortTypes(data, header.length)

  multiExecution = rows => {
    const { executeExternalcmd, showMultiple, lang } = this.props
    const extCmdIds = rows.map(row => row[this.headerData(EXTERNALCMD_ID)])
    const newPids = { ...this.state.pids }
    const results = []
    const cmdCount = extCmdIds.length
    const singleExecution = iteration => {
      const id = extCmdIds[iteration]
      executeExternalcmd(id, result => {
        // if one execute request fails, there will be no result
        if (result?.PID) {
          newPids[id] = result.PID
          results.push({ id, success: true })
        }
        else {
          results.push({ id, success: false, message: result?.message })
        }
        if (iteration < cmdCount - 1) {
          singleExecution(iteration+1)
        }
        // after last iteration update all pids for successfully executed ones
        else if (iteration === cmdCount - 1) {
          this.setState({ pids: newPids })
          const snackbarMessages = []
          const successExecution = results.filter(result => result.success)
          const failedExecution = results.filter(result => !result.success)
          if (successExecution.length > 0) {
            if (successExecution.length <= 2) {
              successExecution.forEach(exec => {
                snackbarMessages.push({ text: translate('server.external_command_executed', lang, [exec.id]), type: SnackbarActions.TYPE_SUCCESS })
              })
            }
            else {
              snackbarMessages.push({ text: translate('server.external_command_executed_counted', lang, [successExecution.length.toString()]), type: SnackbarActions.TYPE_SUCCESS })
            }
          }
          if (failedExecution.length > 0) {
            failedExecution.forEach(exec => {
              snackbarMessages.push({ text: translate('server.external_command_executed_fail', lang, [exec.id, exec.message]), type: SnackbarActions.TYPE_ERROR })
            })
          }
          showMultiple(snackbarMessages)
        }
      }, true)
    }
    singleExecution(0)
  }

  render = () => {
    const { id, externalcmds, drawerExpanded, autoDrawer, lang, datemask } = this.props
    const { showTableSettingsDialog, showResultDialog, currentExternalcmdState } = this.state
    const data = externalcmds && externalcmds.data ? this.getExternalcmdData() : null
    const header = this.getUsedHeader()
    const translatedHeaders = getTranslatedHeaders(this.state.header, header)
    const fillPage = this.getFillPageInfo()
    return (
      <>
        {showResultDialog &&
          <ExternalcmdResultDialog
            id={`${id}_externalcmdresult`}
            externalcmd={currentExternalcmdState}
            onClose={() => this.setState({ showResultDialog: false })}
          />
        }
        {showTableSettingsDialog && (
          <TableSettings
            id={id}
            onClose={() => this.setState({ showTableSettingsDialog: false })}
            headers={this.state.header}
            prefs={{ headers: header, fillPage: fillPage, key: Preferences.TABLE_SETTINGS_DATABASE_EXTERNALCMD }}
          />
        )
        }
        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {data && (
            <DataTable
              id={id}
              header={translatedHeaders}
              data={data}
              cleanData={data}
              selectable={true}
              createActionButtons={this.createActionButtons}
              columnSortDefs={this.getColumnSortDefs(data, translatedHeaders)}
              additionalInteraction={this.createInteractionButtons(data, translatedHeaders)}
              fillPage={fillPage}
              translate={key => translate(key)}
              language={lang}
              datemask={datemask}
              noAction
              // downloadItems={[translate('general.execute')]}
              // showModal={(checkedRows, index) => this.multiExecution(checkedRows, index)}
            />
          )}
        </ResultContainer>
      </>
    )
  }
}

const EXTERNALCMD_ID = 'UTL'
const DESCRIPTION = 'UTLINFO'
const COMMAND = 'UTLPROG'
const ARGUMENTS = 'UTLARGS'
const PROCESS_ID = 'PROCESS_ID'
const UTLDATE = 'UTLDATE'
const UTLTIME = 'UTLTIME'
const CDATE = 'CDATE'
const CTIME = 'CTIME'
const CUSER = 'CUSER'

SearchResultServerExternalCommand.propTypes = {
  id: PropTypes.string.isRequired,
  drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    preferences: state.auth.serverdata.preferences,
    maintenance: state.maintenance,
    externalcmds: state.definitions.externalCommands.externalCommands,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getExternalcmds: (name, description, programName, callback) => {
      ExternalcmdActions.getExternalCommands(name, description, programName, undefined, callback)(dispatch)
    },
    executeExternalcmd: (utility, callback, executeCallbackAlways) => {
      MaintenanceActions.executeUtility(utility, callback, executeCallbackAlways)(dispatch)
    },
    getJobOutput: (pid, callback) => {
      MaintenanceActions.getJobOutput(pid, callback)(dispatch)
    },
    showMultiple: snackbarMessages => SnackbarActions.showMultiple(snackbarMessages)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultServerExternalCommand)