import { combineReducers } from 'redux'
import { withReduxStateSync } from 'redux-state-sync'

import ArchiveFileReducer from 'redux/reducer/ArchiveFileServerReducer'
import ArchivePoolDefinitionReducer from 'redux/reducer/ArchivePoolDefinitionReducer'
import AuthenticationReducer from 'redux/reducer/AuthenticationReducer'
import CustomDialogReducer from 'redux/reducer/CustomDialogReducer'
import DatabaseReducer from 'redux/reducer/DatabaseReducer'
import DocumentDefinitionReducer from 'redux/reducer/DocumentDefinitionReducer'
import DocViewerReducer from 'redux/reducer/DocViewerReducer'
import LogDefinitionReducer from 'redux/reducer/LogDefinitionReducer'
import DocumentNodesDefinitionReducer from 'redux/reducer/DocumentNodesDefinitionReducer'
import DocumentNodeHierarchyReducer from 'redux/reducer/DocumentNodesHierarchyReducer'
import StandardSelectionReducer from 'redux/reducer/StandardSelectionReducer'
import CustomSelectionReducer from 'redux/reducer/CustomSelectionReducer'
import ExternalCommandDefinitionReducer from 'redux/reducer/ExternalCommandDefinitionReducer'
import FilterArgumentReducer from 'redux/reducer/FilterArgumentReducer'
import FolderDefinitionReducer from 'redux/reducer/FolderDefinitionReducer'
import JobgroupsDefinitionReducer from 'redux/reducer/JobgroupsDefinitionReducer'
import FolderDocumentAssignmentReducer from 'redux/reducer/FolderDocumentAssignmentReducer'
import GroupReducer from 'redux/reducer/GroupReducer'
import IndexDefinitionReducer from 'redux/reducer/IndexDefinitionReducer'
import IndexDocumentAssignmentReducer from 'redux/reducer/IndexDocumentAssignmentReducer'
import LastSessionReducer from 'redux/reducer/LastSessionReducer'
import LoadingSpinnerReducer from 'redux/reducer/LoadingSpinnerReducer'
import LpdQueueDefinitionReducer from 'redux/reducer/LpdQueueDefinitionReducer'
import MaintenanceReducer from 'redux/reducer/MaintenanceReducer'
import ModalSelectorReducer from 'redux/reducer/ModalSelectorReducer'
import NodeDocumentAssignmentReducer from 'redux/reducer/NodeDocumentAssignmentReducer'
import OutputChannelDefinitionReducer from 'redux/reducer/OutputChannelDefinitionReducer'
import OutputFormatDefinitionReducer from 'redux/reducer/OutputFormatDefinitionReducer'
import PostProcessingNoteDefinitionReducer from 'redux/reducer/PostProcessingNoteDefinitionReducer'
import RecipientDefinitionReducer from 'redux/reducer/RecipientDefinitionReducer'
import ResultTableDefinitionReducer from 'redux/reducer/ResultTableDefinitionReducer'
import CustomDialogDefinitionReducer from 'redux/reducer/CustomDialogDefinitionReducer'
import ViewProfileDefinitionReducer from 'redux/reducer/ViewProfileDefinitionReducer'
import RecipientDocumentAssignmentReducer from 'redux/reducer/RecipientDocumentAssignmentReducer'
import RecipientHierarchyReducer from 'redux/reducer/RecipientHierarchyReducer'
import ReloadQueueReducer from 'redux/reducer/ReloadQueueReducer'
import BundleQueueReducer from 'redux/reducer/BundleQueueReducer'
import IndexQueueReducer from 'redux/reducer/IndexQueueReducer'
import OutputQueueReducer from 'redux/reducer/OutputQueueReducer'
import LPDQueueReducer from 'redux/reducer/LPDQueueReducer'
import RemoteUserReducer from 'redux/reducer/RemoteUserReducer'
import SearchArgumentReducer from 'redux/reducer/SearchArgumentDefinitionReducer'
import SecurityProfileReducer from 'redux/reducer/SecurityProfileReducer'
import ServerReducer from 'redux/reducer/ServerReducer'
import SnackbarReducer from 'redux/reducer/SnackbarReducer'
import StatisticReducer from 'redux/reducer/StatisticReducer'
import UploadReducer from 'redux/reducer/UploadReducer'
import UserGroupAssigmentReducer from 'redux/reducer/UserGroupAssignmentReducer'
import UserProfileReducer from 'redux/reducer/UserProfileReducer'
import UserReducer from 'redux/reducer/UserReducer'
import UserSecurityAssigmentReducer from 'redux/reducer/UserSecurityAssigmentReducer'
import SearchUC4Reducer from 'redux/reducer/SearchUC4Reducer'
import SearchZOSReducer from 'redux/reducer/SearchZOSReducer'
import SearchControlMReducer from 'redux/reducer/SearchControlMReducer'
import SearchStonebranchReducer from 'redux/reducer/SearchStonebranchReducer'
import SearchSyslogReducer from 'redux/reducer/SearchSyslogReducer'

const rootReducer = combineReducers({
  archivefiles: ArchiveFileReducer,
  assignments: combineReducers({
    folderdocumentassignment: FolderDocumentAssignmentReducer,
    indexdocumentassignment: IndexDocumentAssignmentReducer,
    recipientdocumentassignment: RecipientDocumentAssignmentReducer,
    nodedocumentassignment: NodeDocumentAssignmentReducer
  }),
  auth: AuthenticationReducer,
  customDialogs: CustomDialogReducer,
  database: DatabaseReducer,
  definitions: combineReducers({
    archivePool: ArchivePoolDefinitionReducer,
    customdialogs: CustomDialogDefinitionReducer,
    documents: DocumentDefinitionReducer,
    documentNodes: DocumentNodesDefinitionReducer,
    externalCommands: ExternalCommandDefinitionReducer,
    filterarguments: FilterArgumentReducer,
    folders: FolderDefinitionReducer,
    indexes: IndexDefinitionReducer,
    jobgroups: JobgroupsDefinitionReducer,
    lpdQueues: LpdQueueDefinitionReducer,
    logs: LogDefinitionReducer,
    outputchannels: OutputChannelDefinitionReducer,
    outputformats: OutputFormatDefinitionReducer,
    ppns: PostProcessingNoteDefinitionReducer,
    recipients: RecipientDefinitionReducer,
    searcharguments: SearchArgumentReducer,
    resulttables: ResultTableDefinitionReducer,
    viewprofiles: ViewProfileDefinitionReducer
  }),
  docviewer: DocViewerReducer,
  group: GroupReducer,
  hierarchy: combineReducers({
    documentNodes: DocumentNodeHierarchyReducer,
    recipient: RecipientHierarchyReducer
  }),
  lastsession: LastSessionReducer,
  loadingspinner: LoadingSpinnerReducer,
  maintenance: MaintenanceReducer,
  queue: combineReducers({
    reload: ReloadQueueReducer,
    bundle: BundleQueueReducer,
    index: IndexQueueReducer,
    output: OutputQueueReducer,
    lpd: LPDQueueReducer
  }),
  remoteuser: RemoteUserReducer,
  search: combineReducers({
    standardselection: StandardSelectionReducer,
    customselection: CustomSelectionReducer,
    uc4: SearchUC4Reducer,
    zos: SearchZOSReducer,
    controlm: SearchControlMReducer,
    stonebranch: SearchStonebranchReducer,
    syslog: SearchSyslogReducer
  }),
  security: SecurityProfileReducer,
  selector: ModalSelectorReducer,
  server: ServerReducer,
  snackbar: SnackbarReducer,
  statistic: StatisticReducer,
  upload: UploadReducer,
  user: UserReducer,
  usergroupassignment: UserGroupAssigmentReducer,
  userprofile: UserProfileReducer,
  usersecurityassignment: UserSecurityAssigmentReducer
})

export default withReduxStateSync(rootReducer)