import * as UserGroupAssignmentActions from '../actions/UserGroupAssignmentActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  userAssignments: undefined,
  groupAssignments: undefined,
  groups: undefined,
  users: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Create user group assignment actions
    case UserGroupAssignmentActions.CREATE_USER_GROUP_ASSIGNMENT_START:
      return { ...state }
    case UserGroupAssignmentActions.CREATE_USER_GROUP_ASSIGNMENT_SUCCESS:
      return { ...state }
    case UserGroupAssignmentActions.CREATE_USER_GROUP_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get user assignments actions
    case UserGroupAssignmentActions.GET_USER_ASSIGNMENTS_START:
      return { ...state, userAssignments: undefined }
    case UserGroupAssignmentActions.GET_USER_ASSIGNMENTS_SUCCESS:
      return { ...state, userAssignments: action.payload }
    case UserGroupAssignmentActions.GET_USER_ASSIGNMENTS_FAILED:
      return { ...state }
    case UserGroupAssignmentActions.NO_USER_ASSIGNMENTS_FOUND:
      return { ...state, userAssignments: {} }
    /* ********************************************************************************** */
    // Get user groups actions
    case UserGroupAssignmentActions.SEARCH_USER_GROUPS_START:
      return { ...state }
    case UserGroupAssignmentActions.SEARCH_USER_GROUPS_SUCCESS:
      return { ...state, groups: action.payload }
    case UserGroupAssignmentActions.SEARCH_USER_GROUPS_FAILED:
      return { ...state }
    case UserGroupAssignmentActions.NO_USER_GROUPS_FOUND:
      return { ...state, groups: {} }
    /* ********************************************************************************** */
    // Get group assignments actions
    case UserGroupAssignmentActions.GET_GROUP_ASSIGNMENTS_START:
      return { ...state, groupAssignments: undefined }
    case UserGroupAssignmentActions.GET_GROUP_ASSIGNMENTS_SUCCESS:
      return { ...state, groupAssignments: action.payload }
    case UserGroupAssignmentActions.GET_GROUP_ASSIGNMENTS_FAILED:
      return { ...state }
    case UserGroupAssignmentActions.NO_GROUP_ASSIGNMENTS_FOUND:
      return { ...state, groupAssignments: {} }
    /* ********************************************************************************** */
    // Delete user group assignments actions
    case UserGroupAssignmentActions.DELETE_USER_GROUP_ASSIGNMENT_START:
      return { ...state }
    case UserGroupAssignmentActions.DELETE_USER_GROUP_ASSIGNMENT_SUCCESS:
      return { ...state }
    case UserGroupAssignmentActions.DELETE_USER_GROUP_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get group users actions
    case UserGroupAssignmentActions.SEARCH_GROUP_USERS_START:
      return { ...state }
    case UserGroupAssignmentActions.SEARCH_GROUP_USERS_SUCCESS:
      return { ...state, users: action.payload }
    case UserGroupAssignmentActions.SEARCH_GROUP_USERS_FAILED:
      return { ...state }
    case UserGroupAssignmentActions.NO_GROUP_USERS_FOUND:
      return { ...state, users: {} }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return {
        ...state,
        userAssignments: undefined,
        groupAssignments: undefined,
        groups: undefined,
        users: undefined,
        searchedGroups: undefined,
        searchedUsers: undefined,
      }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer