import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

// actions
export const DOCUMENT_DEFINITION_GET_DOCUMENTS_START = 'DOCUMENT_DEFINITION_GET_DOCUMENTS_START'
export const DOCUMENT_DEFINITION_GET_DOCUMENTS_SUCCESS = 'DOCUMENT_DEFINITION_GET_DOCUMENTS_SUCCESS'
export const DOCUMENT_DEFINITION_GET_DOCUMENTS_FAILED = 'DOCUMENT_DEFINITION_GET_DOCUMENTS_FAILED'

export const DOCUMENT_DEFINITION_GET_DOCUMENT_START = 'DOCUMENT_DEFINITION_GET_DOCUMENT_START'
export const DOCUMENT_DEFINITION_GET_DOCUMENT_SUCCESS = 'DOCUMENT_DEFINITION_GET_DOCUMENT_SUCCESS'
export const DOCUMENT_DEFINITION_GET_DOCUMENT_FAILED = 'DOCUMENT_DEFINITION_GET_DOCUMENT_FAILED'

export const DOCUMENT_DEFINITION_VERIFY_START = 'DOCUMENT_DEFINITION_VERIFY_START'
export const DOCUMENT_DEFINITION_VERIFY_SUCCESS = 'DOCUMENT_DEFINITION_VERIFY_SUCCESS'
export const DOCUMENT_DEFINITION_VERIFY_FAILED = 'DOCUMENT_DEFINITION_VERIFY_FAILED'

export const NO_DOCUMENT_DEFINITION_DOCUMENTS_FOUND = 'NO_DEFINITION_DOCUMENTS_FOUND'

/**
 * @description Calling the rest api and do getDocuments request.
 * @param {Object} fields The fields to select (If undefined -> Return all fields). Possible fields:
 * 'FORM', 'EXT', 'REPORT', 'SMODE', 'BMODE', 'LTITLE', 'OWNER', 'CDATE', 'CTIME'
 * @param {String} form The form pattern.
 * @param {String} extension The extention pattern.
 * @param {String} report The report pattern.
 * @param {String} smode The smode. Possible values:
 * 'START/STOP', 'ABSOLUTE', 'CONTROL', 'AUTOBURST', 'LIMIT/BURST', 'COND/BURST1',
 * 'COND/BURST2', 'COND/BURST3', 'FILTER', 'EXCLUDE', or ''
 * @param {String} process The process mode. Possible Values:
 * 'LIST', 'REPORT', 'ALL' or ''
 * @param {String} owner The owner pattern.
 * @param {String} title The report pattern.
 * @param {String} ppn The post process note pattern.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getDocuments(fields, form, extension, report, smode, outputChannelID, outputFormatID, process, owner, title, ppn, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: DOCUMENT_DEFINITION_GET_DOCUMENTS_START })

    /* Build request-parameter for URL */
    const queryParams = []
    // queryParams.push(`FORM=${encodeURIComponent(form)}`)
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (form) { queryParams.push(`FORM=${encodeURIComponent(form)}`) }
    if (extension) { queryParams.push(`EXT=${encodeURIComponent(extension)}`) }
    if (report) { queryParams.push(`REPORT=${encodeURIComponent(report)}`) }
    if (smode) { queryParams.push(`SMODE=${encodeURIComponent(smode)}`) }
    if (outputChannelID) { queryParams.push(`DCR=${encodeURIComponent(outputChannelID)}`) }
    if (outputFormatID) { queryParams.push(`PCR=${encodeURIComponent(outputFormatID)}`) }
    if (process) { queryParams.push(`PROCESS=${encodeURIComponent(process)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (title) { queryParams.push(`LTITLE=${encodeURIComponent(title)}`) }
    if (ppn) { queryParams.push(`PPN=${encodeURIComponent(ppn)}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/documents?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_DOCUMENT_DEFINITION_DOCUMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: DOCUMENT_DEFINITION_GET_DOCUMENTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: DOCUMENT_DEFINITION_GET_DOCUMENTS_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.documents_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DOCUMENT_DEFINITION_GET_DOCUMENTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and do getDocument request.
 * @param {Object} fields The fields to select.
 * @param {String} form The form pattern.
 * @param {String} extension The extention pattern.
 * @param {String} report The report pattern.
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export function getDocument(fields, form, extension, report, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: DOCUMENT_DEFINITION_GET_DOCUMENT_START })

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    queryParams.push(`FORM=${encodeURIComponent(form)}`)
    queryParams.push(`EXT=${encodeURIComponent(extension)}`)
    queryParams.push(`REPORT=${encodeURIComponent(report)}`)

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DOCUMENT_DEFINITION_GET_DOCUMENT_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DOCUMENT_DEFINITION_GET_DOCUMENT_SUCCESS, payload: jsondata })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('documents.get_document_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DOCUMENT_DEFINITION_GET_DOCUMENT_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and modifies a document definition.
 * @param {Object} documentDefinition The document definition.
 * @param {Function} callback The callback which will be called when the create document request was successful.
 */
export function modifyDocument(documentDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences

    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: ${documentDefinition.FORM}`
    if (documentDefinition.EXT !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: ${documentDefinition.EXT}`
    }
    if (documentDefinition.REPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: ${documentDefinition.REPORT}`
    }

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document`, {
        method: 'put',
        body: ObjectUtils.removeByValue(documentDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.modify_document_definition_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }

          // call getDoduments again to get the current result
          const form = prefs[Preferences.DEFINITION_DOCUMENT_FORM] || ''
          const extension = prefs[Preferences.DEFINITION_DOCUMENT_EXT] || ''
          const report = prefs[Preferences.DEFINITION_DOCUMENT_REPORT]
          const searchMode = prefs[Preferences.DEFINITION_DOCUMENT_SEARCH_MODE]
          const outputChannelID = prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTCHANNEL_ID] || ''
          const outputFormatID = prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTFORMAT_ID] || ''
          const type = prefs[Preferences.DEFINITION_DOCUMENT_TYPE]
          const owner = prefs[Preferences.DEFINITION_DOCUMENT_OWNER] || ''
          const title = prefs[Preferences.DEFINITION_DOCUMENT_TITLE] || ''
          const ppn = prefs[Preferences.DEFINITION_DOCUMENT_PPN_ID] || ''
          getDocuments(undefined, form, extension, report, searchMode, outputChannelID, outputFormatID, type, owner, title, ppn, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.modify_document_definition_error', lang, [documentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a document definition.
 * @param {Object} documentDefinition The document definition.
 * @param {Function} callback The callback which will be called when the create document request was successful.
 */
export function createDocument(documentDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: ${documentDefinition.FORM}`
    if (documentDefinition.EXT !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: ${documentDefinition.EXT}`
    }
    if (documentDefinition.REPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: ${documentDefinition.REPORT}`
    }

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document`, {
        method: 'post',
        body: ObjectUtils.removeByValue(documentDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.create_document_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }

          // call getDoduments again to get the current result
          const form = prefs[Preferences.DEFINITION_DOCUMENT_FORM] || ''
          const extension = prefs[Preferences.DEFINITION_DOCUMENT_EXT] || ''
          const report = prefs[Preferences.DEFINITION_DOCUMENT_REPORT]
          const searchMode = prefs[Preferences.DEFINITION_DOCUMENT_SEARCH_MODE]
          const outputChannelID = prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTCHANNEL_ID] || ''
          const outputFormatID = prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTFORMAT_ID] || ''
          const type = prefs[Preferences.DEFINITION_DOCUMENT_TYPE]
          const owner = prefs[Preferences.DEFINITION_DOCUMENT_OWNER] || ''
          const title = prefs[Preferences.DEFINITION_DOCUMENT_TITLE] || ''
          const ppn = prefs[Preferences.DEFINITION_DOCUMENT_PPN_ID] || ''
          getDocuments(undefined, form, extension, report, searchMode, outputChannelID, outputFormatID, type, owner, title, ppn, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.create_document_error', lang, [documentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and clones a document definition.
 * @param {Object} documentDefinition The document definition.
 * @param {Function} callback The callback which will be called when the clone document request was successful.
 */
export function cloneDocument(documentDefinition, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: ${documentDefinition.NFORM}`
    if (documentDefinition.NEXTENSION !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: ${documentDefinition.NEXTENSION}`
    }
    if (documentDefinition.NREPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: ${documentDefinition.NREPORT}`
    }

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document/clone`, {
        method: 'post',
        body: ObjectUtils.removeByValue(documentDefinition, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.create_document_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }

          // call getDoduments again to get the current result
          const form = prefs[Preferences.DEFINITION_DOCUMENT_FORM] || ''
          const extension = prefs[Preferences.DEFINITION_DOCUMENT_EXT] || ''
          const report = prefs[Preferences.DEFINITION_DOCUMENT_REPORT]
          const searchMode = prefs[Preferences.DEFINITION_DOCUMENT_SEARCH_MODE]
          const outputChannelID = prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTCHANNEL_ID] || ''
          const outputFormatID = prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTFORMAT_ID] || ''
          const type = prefs[Preferences.DEFINITION_DOCUMENT_TYPE]
          const owner = prefs[Preferences.DEFINITION_DOCUMENT_OWNER] || ''
          const title = prefs[Preferences.DEFINITION_DOCUMENT_TITLE] || ''
          const ppn = prefs[Preferences.DEFINITION_DOCUMENT_PPN_ID] || ''
          getDocuments(undefined, form, extension, report, searchMode, outputChannelID, outputFormatID, type, owner, title, ppn, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.create_document_error', lang, [documentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes an index.
 * @param {String} index The index definition which should be deleted.
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function deleteDocument(documentObj, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: ${documentObj.FORM}`
    if (documentObj.EXT !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: ${documentObj.EXT}`
    }
    if (documentObj.REPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: ${documentObj.REPORT}`
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document${createQueryParamsForFetch({ FORM: documentObj.FORM, EXT: documentObj.EXT, REPORT: documentObj.REPORT })}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.delete_document_success', lang, documentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)
          if (callback) {
            callback()
          }

          // call getDoduments again to get the current result
          const form = prefs[Preferences.DEFINITION_DOCUMENT_FORM] || ''
          const extension = prefs[Preferences.DEFINITION_DOCUMENT_EXT] || ''
          const report = prefs[Preferences.DEFINITION_DOCUMENT_REPORT]
          const searchMode = prefs[Preferences.DEFINITION_DOCUMENT_SEARCH_MODE]
          const outputChannelID = prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTCHANNEL_ID] || ''
          const outputFormatID = prefs[Preferences.DEFINITION_DOCUMENT_OUTPUTFORMAT_ID] || ''
          const type = prefs[Preferences.DEFINITION_DOCUMENT_TYPE]
          const owner = prefs[Preferences.DEFINITION_DOCUMENT_OWNER] || ''
          const title = prefs[Preferences.DEFINITION_DOCUMENT_TITLE] || ''
          const ppn = prefs[Preferences.DEFINITION_DOCUMENT_PPN_ID] || ''
          getDocuments(undefined, form, extension, report, searchMode, outputChannelID, outputFormatID, type, owner, title, ppn, undefined, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_document_error', lang, [documentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function verifyDocument(documentObj, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let documentMessage = ''
    documentMessage += `${Lang.translate('general.form')}: ${documentObj.FORM}`
    if (documentObj.EXT !== '') {
      documentMessage += `, ${Lang.translate('general.extension')}: ${documentObj.EXT}`
    }
    if (documentObj.REPORT !== '') {
      documentMessage += `, ${Lang.translate('general.report')}: ${documentObj.REPORT}`
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/document/verify`, {
        method: 'post',
        body: ObjectUtils.removeByValue(documentObj, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          if (rc.toString() === '0016' && irc.toString() === '0000') {
            SnackbarActions.show(Lang.translate('general.verify_error'), SnackbarActions.TYPE_INFO)(dispatch)
          } else {
            SnackbarActions.show(error.message, error.type)(dispatch)
          }
          dispatch({ type: DOCUMENT_DEFINITION_VERIFY_FAILED, payload: { error } })
        } else {
          dispatch({ type: DOCUMENT_DEFINITION_VERIFY_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.verify_document_error', lang, [documentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DOCUMENT_DEFINITION_VERIFY_FAILED, payload: { error } })
      })
  }
}