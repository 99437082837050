import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './EmptySearchResult.scss'

export default class EmptySearchResult extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Text to display */
    description: PropTypes.string.isRequired,
    /** Style class from CSS for styling for button.*/
    className: PropTypes.string
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, description, className } = this.props
    return (
      <div
        id='EmptySearchResult'
        className={`${className} body_container_overflow bux_empty_search_result_container`}>
        <p
          id={`${id}_description`}
          className='bux_empty_search_result_description'>
          {description}
        </p>
      </div>
    )
  }
}