import * as CustomDialogDefinitionActions from '../actions/CustomDialogDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  customDialogs: undefined,
  customDialog: undefined,
  keepPagination: undefined,
  defaultObjects: undefined,
  assignedObjects: undefined,
  availableJobtypes: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get custom dialogs actions
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_START:
      return { ...state }
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_SUCCESS:
      return { ...state, customDialogs: action.payload.data, keepPagination: action.keepPagination }
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOGS_FAILED:
      return { ...state }
    case CustomDialogDefinitionActions.NO_CUSTOM_DIALOG_DEFINITION_CUSTOM_DIALOGS_FOUND:
      return { ...state, customDialogs: {} }
    /* ********************************************************************************** */
    // Definition get custom dialog default objects actions
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_START:
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_START:
      return { ...state }
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_SUCCESS:
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_SUCCESS:
      const successDefaultObjects = state.defaultObjects ? state.defaultObjects : {}
      if (state.availableJobtypes.data.filter(d => d[0] === 'SELJOB').map(d => d[1]).includes(action.args)) {
        successDefaultObjects[action.args] = action.payload.data
      }
      else {
        successDefaultObjects[action.buxCmd] = action.payload.data
      }
      return { ...state, defaultObjects: successDefaultObjects }
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_DEFAULT_OBJECTS_FAILED:
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_INDEX_DEFAULT_OBJECTS_FAILED:
      return { ...state }
    case CustomDialogDefinitionActions.NO_CUSTOM_DIALOG_DEFINITION_DEFAULT_OBJECTS_FOUND:
    case CustomDialogDefinitionActions.NO_CUSTOM_DIALOG_DEFINITION_INDEX_DEFAULT_OBJECTS_FOUND:
      const noDefaultObjectsFound = state.defaultObjects ? state.defaultObjects : {}
      noDefaultObjectsFound[action.buxCmd] = undefined
      return { ...state, defaultObjects: noDefaultObjectsFound }
    /* ********************************************************************************** */
    // Definition get custom dialog assigned objects actions
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_START:
      return { ...state }
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_SUCCESS:
      return { ...state, assignedObjects: action.payload.data }
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_ASSIGNED_OBJECTS_FAILED:
      return { ...state }
    case CustomDialogDefinitionActions.NO_CUSTOM_DIALOG_DEFINITION_ASSIGNED_OBJECTS_FOUND:
      return { ...state, assignedObjects: {} }
    /* ********************************************************************************** */
    // Definition get custom dialog action
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOG_START:
      return { ...state, customDialog: undefined }
    case CustomDialogDefinitionActions.CUSTOM_DIALOG_DEFINITION_GET_CUSTOM_DIALOG_SUCCESS:
      return { ...state, customDialog: action.payload.data }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return {
        ...state,
        customDialogs: undefined,
        customDialog: undefined,
        keepPagination: undefined,
        defaultObjects: {},
        assignedObjects: undefined,
        availableJobtypes: undefined
      }
    /* ********************************************************************************** */
    case CustomDialogDefinitionActions.GET_CUSTOM_DIALOG_OBJECTS_SUCCESS:
      return {
        ...state,
        customDialog: {
          ...state.customDialog,
          OBJECTS: {
            header: action.payload.header,
            data: action.payload.data
          }
        }
      }
    case CustomDialogDefinitionActions.GET_CUSTOM_DIALOG_OBJECTS_FAILED:
      return {
        ...state,
        customDialog: {
          ...state.customDialog,
          OBJECTS: {}
        }
      }
    /* ********************************************************************************** */
    case CustomDialogDefinitionActions.GET_CUSTOM_DIALOG_AVAILABLE_JOBTYPES_SUCCESS:
      return { ...state, availableJobtypes: action.payload }
    case CustomDialogDefinitionActions.GET_CUSTOM_DIALOG_AVAILABLE_JOBTYPES_FAILED:
      return { ...state, availableJobtypes: undefined }
    default: return state
  }
}

export default reducer