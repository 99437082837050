import * as SearchSyslogActions from 'redux/actions/SearchSyslogActions'
import { LOGOUT_SUCCESS } from '../actions/AuthenticationActions'

const defaultState = {
  syslogJobs: undefined,
  syslogJobDetails: undefined,
  syslogJobLogs: undefined,
  syslogJobLog: undefined,
  syslogJobLogPrintInfo: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Get syslog jobs
    case SearchSyslogActions.GET_SYSLOG_JOBS_START:
      return { ...state }
    case SearchSyslogActions.GET_SYSLOG_JOBS_SUCCESS:
      return { ...state, syslogJobs: action.payload, keepPagination: action.keepPagination }
    case SearchSyslogActions.GET_SYSLOG_JOBS_FAILED:
      return { ...state }
    case SearchSyslogActions.NO_SYSLOG_JOBS_FOUND:
      return { ...state, syslogJobs: {} }
    /* ********************************************************************************** */
    // Get specific uc4 job
    case SearchSyslogActions.GET_SYSLOG_JOB_START:
      return { ...state }
    case SearchSyslogActions.GET_SYSLOG_JOB_SUCCESS:
      return { ...state, syslogJobDetails: action.payload }
    case SearchSyslogActions.GET_SYSLOG_JOB_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get logs of a job
    case SearchSyslogActions.GET_SYSLOG_JOB_LOGS_START:
      return { ...state }
    case SearchSyslogActions.GET_SYSLOG_JOB_LOGS_SUCCESS:
      return { ...state, syslogJobLogs: action.payload }
    case SearchSyslogActions.GET_SYSLOG_JOB_LOGS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get log of a job
    case SearchSyslogActions.SYSLOG_JOBS_GET_LOG_START:
      return { ...state }
    case SearchSyslogActions.SYSLOG_JOBS_GET_LOG_SUCCESS:
      return { ...state, syslogJobLog: action.payload }
    case SearchSyslogActions.SYSLOG_JOBS_GET_LOG_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Get syslog job log print info
    case SearchSyslogActions.SYSLOG_JOBS_GET_LOG_PRINT_INFO_START:
      return { ...state }
    case SearchSyslogActions.SYSLOG_JOBS_GET_LOG_PRINT_INFO_SUCCESS:
      return { ...state, syslogJobLogPrintInfo: action.payload }
    case SearchSyslogActions.SYSLOG_JOBS_GET_LOG_PRINT_INFO_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    case SearchSyslogActions.SYSLOG_LOG_UPDATE_LOG_RESET:
      return { ...state, syslogJobLog: undefined }
    /* ********************************************************************************** */
    case LOGOUT_SUCCESS:
      return defaultState
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer