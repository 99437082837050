import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import ErrorMessage from '../error_message/ErrorMessage';
import Label from '../label/Label';
import SwitchItem from './switch_item/SwitchItem';

// Style
import './Switch.scss'

/**
 * Switch component created according to
 * _Segmented Control_, _Switchable Buttons_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
export default class Switch extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** This ID will be used for integration tests to access the HTML element */
    dataTestId: PropTypes.string,
    /**
     * Function to be called on an click event.
     * @param index - index of clicked item
     */
    onClick: PropTypes.func.isRequired,
    /** Array of item as a strings */
    items: PropTypes.array.isRequired,
    /** Label to be displayed above the switch.*/
    title: PropTypes.string,
    /** Index of selected item. Starts at 0, default is 0. */
    activeIndex: PropTypes.number,
    /** Maximum item per row to be displayed. Default is 2. */
    maxPerRow: PropTypes.number,
    /** Tab index */
    tabindex: PropTypes.string,
    /** Enable autofocus on render completion.*/
    autoFocus: PropTypes.bool,
    /** Disables interaction with the component.*/
    disabled: PropTypes.bool,
    /** Refs */
    refs: PropTypes.array,
    /** Array of indexes, for which item from _items_, error icon should be displayed */
    errorIcons: PropTypes.any,
    /** Error description */
    error: PropTypes.string
  }

  state = {
    mousedown: false
  }

  componentDidMount = () => {
    this.setDefaultIfOutside()
  }

  componentDidUpdate = () => {
    this.setDefaultIfOutside()
  }

  /**
   * @description Fallback if the index is outside the range.
   */
  setDefaultIfOutside = () => {
    const { activeIndex, items, onClick } = this.props
    if (activeIndex > (items.length - 1) || activeIndex < 0) {
      onClick(0)
    }
  }

  /**
   * @description Adds the focus style to a specific element.
   * @param {string} id The id from that element.
   */
  addFocusStyle = id => {
    if (!this.state.mousedown) {
      const el = document.querySelector(`#${id}`)
      if (document.activeElement.id === id) {
        el.classList.add('bux_focus_style')
      }
    }
  }

  /**
   * @description Removes the focus style from a specific element.
   * @param {string} id The id from that element.
   */
  removeFocusStyle = id => {
    const el = document.querySelector(`#${id}`)
    el.classList.remove('bux_focus_style')
  }

  /**
   * @description Adds the active style on a specific element.
   * @param {object} event The event of the specific action on an element.
   * @param {string} id The id from that element.
   */
  addActiveStyle = (event, id) => {
    if (event.keyCode === 13) {
      const el = document.querySelector(`#${id}`)
      el.classList.remove('bux_focus_style')
      el.classList.add('bux_switchbtn_active_style')
    }
  }

  /**
   * @description Removes the active style on a specific element.
   * @param {object} event The event of the specific action on an element.
   * @param {string} id The id from that element.
   */
  removeActiveStyle = (event, id) => {
    if (event.keyCode === 13) {
      const el = document.querySelector(`#${id}`)
      el.classList.remove('bux_switchbtn_active_style')
      el.classList.add('bux_focus_style')
    }
  }

  /**
   * @description Sets the mousedown flag to true.
   */
  handleMouseDown = () => {
    if (!this.state.mousedown) {
      this.setState({ mousedown: true })
    }
  }

  /**
   * @description Sets the mousedown flag to false.
   */
  handleMouseUp = () => {
    if (this.state.mousedown) {
      this.setState({ mousedown: false })
    }
  }

  /**
   * @description Sets the mousedown flag to false.
   * @param {string} id The id from that element.
   */
  handleMouseLeave = id => {
    if (this.state.mousedown) {
      this.setState({ mousedown: false }, () => this.addFocusStyle(id))
    }
    else {
      this.addFocusStyle(id)
    }
  }

  /**
   * @description Renders one row with buttons. Its possible to have Switch buttons with more than one row.
   * @param {number} rowNr row number
   * @param {number} perRow how many buttons per row
   * @param {Array} btns Buttons
   * @returns {Array}
   */
  renderRowWithButtons = (rowNr, perRow, btns) => {
    const { id, dataTestId, tabindex, autoFocus, disabled, onClick, activeIndex, errorIcons, refs } = this.props
    return (
      <div
        key={`${id}_container_btngroup_${rowNr}`}
        id={`${id}_container_btngroup_${rowNr}`}
        className={'bux_Switch btn-group d-flex'}>
        {
          btns.map((item, i) => {
            const index = rowNr * perRow + i
            return (
              <div
                id={`${id}_container_btngroup_${rowNr}_${i}`}
                data-testid={dataTestId}
                key={`${rowNr}-${i}`}
                className='btn-group f-basis-100'
                role='group'>
                <SwitchItem
                  id={`${id}_container_btngroup_${rowNr}_${i}_item`}
                  focusRef={refs && refs[index]}
                  tabindex={tabindex}
                  text={item}
                  toggle={index === activeIndex}
                  onClick={() => onClick(index, item)}
                  autoFocus={i === 0 && autoFocus}
                  disabled={disabled}
                  onFocus={() => this.addFocusStyle(`${id}_container_btngroup_${rowNr}_${i}_item`)}
                  onBlur={() => this.removeFocusStyle(`${id}_container_btngroup_${rowNr}_${i}_item`)}
                  onKeyDown={event => this.addActiveStyle(event, `${id}_container_btngroup_${rowNr}_${i}_item`)}
                  onKeyUp={event => this.removeActiveStyle(event, `${id}_container_btngroup_${rowNr}_${i}_item`)}
                  onMouseMove={() => this.removeFocusStyle(`${id}_container_btngroup_${rowNr}_${i}_item`)}
                  onMouseLeave={() => this.handleMouseLeave(`${id}_container_btngroup_${rowNr}_${i}_item`)}
                  onMouseDown={() => this.handleMouseDown()}
                  onMouseUp={() => this.handleMouseUp()}
                  errorIcons={errorIcons && errorIcons.includes(i)}
                />
              </div>
            )
          })
        }
      </div>
    )
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, maxPerRow, items, title, disabled, error } = this.props
    const output = []
    const perRow = maxPerRow || 2
    const btns = items
    let rowCount = 0
    /* Loop while there are buttons to render */
    while (btns.length > (rowCount * perRow)) {
      /* Select next btns to render, skip the already rendered */
      const btnsChunk = btns.slice(
        rowCount * perRow,
        rowCount * perRow + Math.min(perRow, btns.length - (rowCount * perRow))
      )
      /* Save grouped buttons in array */
      output.push(this.renderRowWithButtons(rowCount, perRow, btnsChunk))
      rowCount += 1 /* Required for unique id */
    }
    return (
      <div
        id={id} className={`bux_switchbtn_general_container ${disabled && 'disabled'}`}>
        <Label
          id={`${id}_title`}
          title={title}
          disabled={disabled}
          isError={!!error}
        />
        <div className='we_btn-toolbar bux_switchbtn_container' id={`${id}_container`}>
          {output.map(row => row)}
        </div>
        <ErrorMessage id={`${id}_error`} text={error} />
      </div>
    )
  }
}