import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import { Component } from 'react'

// Components
import {
  Button, Card, Column, DataTable, MetaDataGrid, Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'

class ExternalcmdResultDialog extends Component {

  /**
   * @description Renders the header which includes the non editable information
   */
  renderHeader = () => {
    const { id, externalcmd } = this.props

    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.externalcmd.ext_cmd_id'), value: externalcmd.id },
          { label: translate('database.process_id'), value: externalcmd.pid },
          { label: translate('external_command.description'), value: externalcmd.desc },
        ]}
        columns={3}
      />
    )
  }

  /**
   * @description gets the index of the header in redux state maintenance.jobs.header
   * @param {String} header header name of the header in redux state jobOutput.jobs.header
   */
  headerData = header => this.props.jobOutput.header.indexOf(header)

  /**
   * @description gets the corrected/formatted data from the table
   */
  getTableData = () => {
    const data = []
    this.props.jobOutput.data.forEach(el => {
      const time = el[this.headerData('MSGTIME')]
      const msgID = el[this.headerData('MSGNR')]
      const msg = el[this.headerData('MSGTEXT')]
      data.push([time, msgID, msg])
    })
    return data
  }

  render = () => {
    const { id, lang, onClose, jobOutput, datemask } = this.props
    const data = jobOutput && jobOutput.data ? this.getTableData() : null
    return (
      <Modal onClose={onClose}
        id={id}>
        <Header
          id={`${id}`}
          title={translate('database.externalcmd_result')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main id={id}>
          <Card>
            <Row>
              <Column>
                <DataTable
                  id={`${id}`}
                  header={[
                    translate('database.result.time'),
                    translate('database.result.message_id'),
                    translate('database.result.message')
                  ]}
                  columnSortDefs={['string', 'string', 'string']}
                  data={data}
                  noAction
                  language={lang}
                  datemask={datemask}
                  translate={key => translate(key)}
                  menu={false}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer>
          <Button
            id={`${id}_closebtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

ExternalcmdResultDialog.propTypes = {
  id: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    jobOutput: state.maintenance.jobOutput
  }
}


export default connect(mapStateToProps)(ExternalcmdResultDialog)