import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import { Component } from 'react'

// Components
import {
  DataTable, DownloadWrapper, Link, ResultContainer, TableButton
} from 'BetaUX2Web-Components/src/'

import SystemDefaultsFreeTextDialog from 'components/dialogs/system_defaults_free_text_dialog/SystemDefaultsFreeTextDialog'
import SystemDefaultsNumericSpinnerDialog from 'components/dialogs/system_defaults_numeric_spinner_dialog/SystemDefaultsNumericSpinnerDialog'
import SystemDefaultsSwitchableDialog from 'components/dialogs/system_defaults_switchable_dialog/SystemDefaultsSwitchableDialog'

import { connect } from 'react-redux'
import * as ServerActions from 'redux/actions/ServerActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as Preferences from 'redux/general/Preferences'
import * as SystemDefaultUtils from 'utils/SystemDefaultUtils'

class SearchResultServerSystemDefaults extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }

  state = {
    showNumericModal: false,
    showSwitchableModal: false,
    showFreeTextModal: false,
    switchableModal: {
      values: [],
      selected: 0,
      defaultIndex: 0
    },
    numericModal: {
      min: 0,
      max: 10,
      value: 0,
      defaultValue: 0
    },
    freeTextModal: {
      value: ''
    },
    keyword: undefined,
  }

  /**
   * @description Sends the getFiles request.
   */
  componentDidMount = () => {
    this.props.getSystemDefaults()
  }

  /**
   * @description Gets the header for the table.
   * @returns {Array} The translated headers.
   */
  getHeader = () => {
    return [
      translate('general.parameter'),
      translate('general.value'),
    ]
  }

  /**
   * @description Gets the view data.
   * @returns {Array} The view data.
   */
  getViewData = () => {
    const { systemdefaults } = this.props

    let data = []
    systemdefaults.data.forEach(element => {
      const parameter = element[this.headerData('KEYWORD')]
      let translatedParameter = SystemDefaultUtils.getParameterTranslation(parameter)
      const value = element[this.headerData('VALUE')]
      data.push([translatedParameter, value])
    })
    return data
  }

  /**
   * @description Gets the index of a specific header.
   * @param {String} header specific header of the users
   */
  headerData = header => this.props.systemdefaults.header.indexOf(header)

  /**
   * @description Creates the buttons for the tablemenu.
   * @param {Array} data The data which is shown in the table.
   * @param {Array} header The headers which are shown in the tableheader
   * @returns {Array} Array of interaction buttons.
   */
  createInteractionButtons = (data, header) => {
    return (
      [
        <Link
          id={'cached'}
          iconName={'refresh'}
          tooltip={translate('table.refresh')}
          onClick={() => {
            this.props.getSystemDefaults()
          }}
        />,
        <DownloadWrapper
          id='download_wrapper'
          header={header}
          data={[...data]}
          csvSplitter=';'
          filename='data.csv'
          tooltip={translate('table.download_as_csv')}>
          <Link
            id={'download'}
            iconName={'download'}
            onCLick={() => {}}
            tooltip={translate('table.download_as_csv')}
          />
        </DownloadWrapper>
      ]
    )
  }

  /**
   * @description Creates the action buttons for a specific row.
   * @param {Number} index The row index.
   * @returns {Array} The actionbuttons for a specific row.
   */
  createActionButtons = index => {
    const { systemdefaults, id } = this.props
    let changeable
    if (index) {
      changeable = systemdefaults.data[index][this.headerData('CHANGEABLE')] === 'YES'
    }

    requestAnimationFrame(() => {
      var allTableButtons = Array.from(document.querySelectorAll('.button'))
      allTableButtons.forEach(button => {
        button.classList.remove('bux_disabled_actionbutton')
        let iconContainer = button.querySelector('div')
        if (iconContainer && iconContainer.classList.contains('disabled')) {
          button.classList.add('bux_disabled_actionbutton')
        }
      })
    })

    return [
      <TableButton
        id={`${id}_tableButtonEdit_${index}`}
        iconType='material'
        iconName='edit'
        title={!changeable ? translate('systemdefaults.not_changeable') : translate('general.edit')}
        onClick={() => this.handleOnModifySystemDefault(index)}
        disabled={!changeable}
      />
    ]
  }

  /**
   * @description Opens the modify dialog for the specific system default.
   * @param {Number} index The current row.
   */
  handleOnModifySystemDefault = (index) => {
    const { systemdefaults } = this.props

    const rowData = systemdefaults.data[index]
    const isChangeable = systemdefaults.data[index][this.headerData('CHANGEABLE')] === 'YES'
    const keyword = rowData[systemdefaults.header.indexOf('KEYWORD')]
    let currentValue = rowData[systemdefaults.header.indexOf('VALUE')]

    if (index && !isChangeable) {
      // the current system default is not changeable so return
      return
    }

    const dialogType = SystemDefaultUtils.getModifyDialogType(keyword)

    // numeric spinner modal
    if (dialogType === SystemDefaultUtils.TYPE_NUMERIC) {
      const min = SystemDefaultUtils.getNumericMinValue(keyword)
      const max = SystemDefaultUtils.getNumericMaxValue(keyword)

      // try to convert to number
      currentValue = parseInt(currentValue)
      if (isNaN(currentValue)) {
        // use min value as default if value is NaN
        currentValue = min
      } else {
        // check if value is in range / otherwise use min or max value
        if (currentValue < min) {
          currentValue = min
        }
        if (currentValue > max) {
          currentValue = max
        }
      }

      this.setState({
        showNumericModal: true,
        dialogType: dialogType,
        keyword: keyword,
        numericModal: {
          ...this.state.numericModal,
          min: min,
          max: max,
          value: currentValue,
        }
      })
      // switchable buttons modal
    } else if (dialogType === SystemDefaultUtils.TYPE_SWITCHABLE) {
      const values = SystemDefaultUtils.getSwitchableValues(keyword)
      let selected = values.findIndex(entry => entry.key === currentValue)

      // use default when current value invalid
      if (selected === -1) {
        const defaultVal = SystemDefaultUtils.getSwitchableDefault(keyword).key
        selected = values.findIndex(entry => entry.key === defaultVal)
      }

      this.setState({
        showSwitchableModal: true,
        dialogType: dialogType,
        keyword: keyword,
        switchableModal: {
          ...this.state.switchableModal,
          values: values,
          selected: selected,
        }
      })
      // text input modal (fallback)
    } else if (dialogType === SystemDefaultUtils.TYPE_FREE_TEXT) {

      this.setState({
        showFreeTextModal: true,
        dialogType: dialogType,
        keyword: keyword,
        freeTextModal: {
          value: currentValue
        }
      })
    }
  }

  /**
   * @description Saves the system default.
   * @param {String} keyword The keyword of the system default.
   * @param {*} value The new value.
   */
  onSaveSystemDefault = (keyword, value, closeCallback) => {
    this.props.setSystemDefault(keyword, value, closeCallback)
  }

  /**
   * @description Renders the modal dialogs which can modify the system defaults.
   */
  renderModalDialogs = () => {
    const { id } = this.props
    const { keyword, showSwitchableModal, showNumericModal, showFreeTextModal } = this.state

    // numeric spinner modal
    if (showNumericModal) {
      const closeCallback = () => { this.setState({ showNumericModal: false }) }
      return (
        <SystemDefaultsNumericSpinnerDialog
          id={`${id}_modify_system_defaults_numeric_dialog`}
          parameter={keyword}
          value={this.state.numericModal.value}
          min={this.state.numericModal.min}
          max={this.state.numericModal.max}
          onClose={closeCallback}
          onSave={(keyword, value) => { this.onSaveSystemDefault(keyword, value, closeCallback) }}
        />
      )
      // switchable buttons modal
    } else if (showSwitchableModal) {
      const closeCallback = () => { this.setState({ showSwitchableModal: false }) }
      return (
        <SystemDefaultsSwitchableDialog
          id={`${id}_modify_system_defaults_switchable_dialog`}
          parameter={keyword}
          selected={this.state.switchableModal.selected}
          values={this.state.switchableModal.values}
          onClose={closeCallback}
          onSave={(keyword, value) => { this.onSaveSystemDefault(keyword, value, closeCallback) }}
        />
      )
    } else if (showFreeTextModal) {
      const closeCallback = () => { this.setState({ showFreeTextModal: false }) }
      return (
        <SystemDefaultsFreeTextDialog
          id={`${id}_modify_system_defaults_free_text_dialog`}
          parameter={keyword}
          value={this.state.freeTextModal.value}
          onClose={closeCallback}
          onSave={(keyword, value) => { this.onSaveSystemDefault(keyword, value, closeCallback) }}
        />
      )
    }
  }

  render = () => {
    const { id, systemdefaults, drawerExpanded, autoDrawer, lang, datemask, keepPagination } = this.props
    let header = (systemdefaults && systemdefaults.data) ? this.getHeader() : null
    let data = systemdefaults && systemdefaults.data ? this.getViewData() : null
    return (
      <>
        {this.renderModalDialogs()}

        <ResultContainer
          drawerExpanded={drawerExpanded}
          autoDrawer={autoDrawer}>
          {data && (
            <DataTable
              id={`${id}_systemdefaults`}
              header={header}
              data={data}
              cleanData={data}
              selectable
              createTableRowAction={this.handleOnModifySystemDefault}
              createActionButtons={this.createActionButtons}
              columnSortDefs={['string', 'string']}
              additionalInteraction={this.createInteractionButtons(data, header)}
              fillPage
              translate={key => translate(key)}
              language={lang}
              datemask={datemask}
              keepPagination={keepPagination}
            />
          )}
        </ResultContainer>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    systemdefaults: state.server.systemdefaults,
    keepPagination: state.server.keepPagination,
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getSystemDefaults: () => {
      ServerActions.getSystemDefaults()(dispatch)
    },
    setSystemDefault: (keyword, value, callback) => {
      ServerActions.setSystemDefault(keyword, value, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultServerSystemDefaults)