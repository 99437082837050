import moment from 'moment'
import PropTypes from 'prop-types'
import { Component } from 'react'

import { Button, Card, Column, Input, Row, Switch } from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import { translate } from 'language/Language'
import { connect } from 'react-redux'
import * as IndexQueueActions from 'redux/actions/IndexQueueActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'
import * as DateUtils from 'utils/DateUtils'
import * as QueueUtils from 'utils/QueueUtils'
import * as Utils from 'utils/Utils'
import TimeCard from '../../../../../time_card/TimeCard'

const UNIT_MINUTES = 'M'
const UNIT_HOURS = 'H'
const UNIT_DAYS = 'D'

const UNITS = [
  { key: UNIT_MINUTES, translationKey: 'general.minutes' },
  { key: UNIT_HOURS, translationKey: 'general.hours' },
  { key: UNIT_DAYS, translationKey: 'general.days' },
]

class QueueIndex extends Component {

  defaultState = {
    time: {
      activeTimeTabIndex: 0,
      type: 0,
      period: 1,
      unit: 2,
      startDate: {
        value: '',
        error: ''
      },
      startTime: {
        value: '',
        error: ''
      },
      endDate: {
        value: '',
        error: ''
      },
      endTime: {
        value: '',
        error: ''
      },
    },
    form: {
      value: '',
      error: ''
    },
    extension: {
      value: '',
      error: ''
    },
    report: {
      value: '',
      error: ''
    },
    activeStatusIndex: 0,
    showDocumentSelectorDialog: false
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Sets default state given from preference or fallbacks for the component.
   */
  componentDidMount = () => {
    const { preferences, datemask } = this.props
    let activeTimeTabIndex = Utils.convertStringToInt(preferences[Preferences.QUEUE_INDEX_ACTIVE_TAB]) || 0
    let activeStatusIndex = QueueUtils.STATUS_VALUES.findIndex(entry => entry.key === preferences[Preferences.QUEUE_INDEX_STATUS])
    if (activeStatusIndex === -1) {
      activeStatusIndex = Math.max(QueueUtils.STATUS_VALUES.findIndex(entry => entry.key === QueueUtils.STATUS_VALUE_ANY), 0)
    }

    // ! it could be, that old preferences are stored as object by mistake, which will throw a error here
    // ! delete the preferences of the user for bundle queue to fix this error
    let startDate = activeTimeTabIndex === 1 && preferences[Preferences.QUEUE_INDEX_START_DATE]
      ? DateUtils.getDate(datemask, preferences[Preferences.QUEUE_INDEX_START_DATE])
      : ''

    let startTime = preferences[Preferences.QUEUE_INDEX_START_TIME] || ''
    startTime = Utils.convertStringToInt(startTime)

    let endDate = preferences[Preferences.QUEUE_INDEX_END_DATE]
      ? DateUtils.getDate(datemask, preferences[Preferences.QUEUE_INDEX_END_DATE])
      : ''

    let endTime = preferences[Preferences.QUEUE_INDEX_END_TIME] || ''
    endTime = Utils.convertStringToInt(endTime)

    const getUnitIndex = () => {
      const prefsKey = preferences[Preferences.QUEUE_INDEX_TIME_CUSTOM_UNIT]
      let result = 2
      for (let i = 0; i < UNITS.length; i++) {
        if (UNITS[i].key === prefsKey) {
          result = i
        }
      }
      return result
    }

    // set preference values or fallback default values
    this.setState({
      time: {
        activeTimeTabIndex,
        type: Utils.convertStringToInt(preferences[Preferences.QUEUE_INDEX_LASTTIME_MODE]) || 0,
        period: Utils.convertStringToInt(preferences[Preferences.QUEUE_INDEX_TIME_CUSTOM_LAST]) || 1,
        unit: getUnitIndex(),
        startDate: {
          value: startDate,
          error: ''
        },
        startTime: {
          value: startTime,
          error: ''
        },
        endDate: {
          value: endDate,
          error: ''
        },
        endTime: {
          value: endTime,
          error: ''
        },
      },
      form: {
        value: preferences[Preferences.QUEUE_INDEX_FORM] || '',
        error: ''
      },
      extension: {
        value: preferences[Preferences.QUEUE_INDEX_EXTENSION] || '',
        error: ''
      },
      report: {
        value: preferences[Preferences.QUEUE_INDEX_REPORT] || '',
        error: ''
      },
      activeStatusIndex,
    })
  }

  /**
   * @description Resets the search values to default values.
   */
  resetSearchCriteria = () => {
    this.setState(this.defaultState)
  }

  /**
   * @description Sets the changes to the state.
   * @param {String} key The key in state.
   * @param {String} value The new value.
   * @param {String} error The new error key.
   */
  handleChange = (key, value, error) => {
    this.setState({ [key]: { value, error } })
  }

  /**
   * @description Validates all elements from the timecard component. Checks if date inputs are valid dates and if given, the end date is not before the start date.
   */
  validateTimecardErrors = () => {
    const { time } = this.state
    const { datemask } = this.props
    let errorObj = {}
    if (time.activeTimeTabIndex === 1) {
      if (time.startDate.value !== '' && !DateUtils.isDate(time.startDate.value, datemask) && !DateUtils.validDateStrings.includes(time.startDate.value.toUpperCase())) {
        errorObj.startDate = {
          value: time.startDate.value,
          error: 'general.invalid_date_format'
        }
      }
      if (time.endDate.value !== '' && !DateUtils.isDate(time.endDate.value, datemask) && !DateUtils.validDateStrings.includes(time.endDate.value.toUpperCase())) {
        errorObj.endDate = {
          value: time.endDate.value,
          error: 'general.invalid_date_format'
        }
      }
      if (time.startTime.value !== '' && !time.startTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errorObj.startTime = {
          value: time.startTime.value,
          error: 'general.invalid_time_format'
        }
      }
      if (time.endTime.value !== '' && !time.endTime.value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        errorObj.endTime = {
          value: time.endTime.value,
          error: 'general.invalid_time_format'
        }
      }
    }
    // Set error if the to date is before the from date and both inputs are filled with a date.
    if (!errorObj.endDate) {
      if (time.startDate.value !== '' && time.endDate.value !== '') {
        if (DateUtils.isDate(time.startDate.value, datemask) && DateUtils.isDate(time.endDate.value, datemask)) {
          const startDate = moment(time.startDate.value, datemask)
          const endDate = moment(time.endDate.value, datemask)
          if (endDate.isBefore(startDate)) {
            errorObj.endDate = {
              value: time.endDate.value,
              error: 'statistic.negative_date_difference_error'
            }
          }
        }
      }
    }
    this.setState({ time: { ...time, ...errorObj } })
    return Object.keys(errorObj).length
  }

  /**
   * @description Handles the search event. Saves the search values in preferences and call the rest api to search.
   * @param {Object} event The event
   */
  handleSubmitSearch = (event) => {
    event.preventDefault()
    const { time, form, extension, report, activeStatusIndex } = this.state
    const { datemask } = this.props

    const errors = this.validateTimecardErrors()
    if (errors === 0) {
      // save search values in preferences
      const prefsToChange = {
        [Preferences.QUEUE_INDEX_ACTIVE_TAB]: time.activeTimeTabIndex,
        [Preferences.QUEUE_INDEX_LASTTIME_MODE]: time.type,
        [Preferences.QUEUE_INDEX_TIME_CUSTOM_LAST]: time.period,
        [Preferences.QUEUE_INDEX_TIME_CUSTOM_UNIT]: UNITS[time.unit].key,
        [Preferences.QUEUE_INDEX_START_DATE]: DateUtils.getRequestFormat(time.startDate.value, datemask),
        [Preferences.QUEUE_INDEX_START_TIME]: DateUtils.formatTimeToDefault(time.startTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.QUEUE_INDEX_END_DATE]: DateUtils.getRequestFormat(time.endDate.value, datemask),
        [Preferences.QUEUE_INDEX_END_TIME]: DateUtils.formatTimeToDefault(time.endTime.value, DateUtils.TIME_DATEMASK),
        [Preferences.QUEUE_INDEX_FORM]: form.value,
        [Preferences.QUEUE_INDEX_EXTENSION]: extension.value,
        [Preferences.QUEUE_INDEX_REPORT]: report.value,
        [Preferences.QUEUE_INDEX_STATUS]: QueueUtils.STATUS_VALUES[activeStatusIndex].key
      }

      let searchParams = {
        'FORM': form.value,
        'EXT': extension.value,
        'REPORT': report.value,
        'STATUS': QueueUtils.getStatus(true)[activeStatusIndex].key
      }

      if (time.activeTimeTabIndex === 0) {
        prefsToChange[Preferences.QUEUE_INDEX_START_DATE] = ''
        prefsToChange[Preferences.QUEUE_INDEX_START_TIME] = ''
        prefsToChange[Preferences.QUEUE_INDEX_END_DATE] = ''
        prefsToChange[Preferences.QUEUE_INDEX_END_TIME] = ''
        if (time.type === 0) {
          searchParams['SDATE'] = 'TODAY'
          prefsToChange[Preferences.QUEUE_INDEX_START_DATE] = 'TODAY'
        }
        else if (time.type === 1) {
          searchParams['SDATE'] = 'YESTERDAY'
          prefsToChange[Preferences.QUEUE_INDEX_START_DATE] = 'YESTERDAY'
        }
        else if (time.type === 2) {
          searchParams['FROMLAST'] = time.period
          searchParams['TUNITS'] = UNITS[time.unit].key
        }
      }
      else if (time.activeTimeTabIndex === 1) {
        searchParams['SDATE'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.startTime.value !== '') {
          searchParams['STIME'] = DateUtils.getTimeshiftDate(time.startDate.value, time.startTime.value, DateUtils.TIME_DATEMASK)
        }
        searchParams['EDATE'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.DDMMYYYY_DOT)
        if (time.endTime.value !== '') {
          searchParams['ETIME'] = DateUtils.getTimeshiftDate(time.endDate.value, time.endTime.value, DateUtils.TIME_DATEMASK)
        }

        prefsToChange[Preferences.QUEUE_INDEX_TIME_CUSTOM_LAST] = ''
        prefsToChange[Preferences.QUEUE_INDEX_TIME_CUSTOM_UNIT] = ''
      }

      this.props.changePrefs(prefsToChange)
      this.props.getIndexQueues(searchParams)
    }
  }

  /**
   * @description Requests the document definition with the current selection.
   * On successful request it opens the selector dialog.
   */
  openDocumentDefinitionsSelector = () => {
    this.props.getDocumentDefinitions(
      ['FORM', 'EXT', 'REPORT'],
      this.state.form.value,
      this.state.extension.value,
      this.state.report.value,
      () => this.setState({ showDocumentSelectorDialog: true })
    )
  }

  /**
   * @description Gets the index of the header in redux state
   * @param {Object} data specific redux object
   * @param {String} header header name of the header in redux
   */
  headerData = (data, header) => data.header.indexOf(header)

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    const { showDocumentSelectorDialog } = this.state

    return (
      <>
        {showDocumentSelectorDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report')
            ]}
            items={selector.documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const form = selector.documents.data[selectedRows][this.headerData(selector.documents, 'FORM')]
                const extension = selector.documents.data[selectedRows][this.headerData(selector.documents, 'EXT')]
                const report = selector.documents.data[selectedRows][this.headerData(selector.documents, 'REPORT')]
                this.setState({
                  form: { value: form, error: '' },
                  extension: { value: extension, error: '' },
                  report: { value: report, error: '' },
                  showDocumentSelectorDialog: false
                })
              }
            }}
          />
        )}
      </>
    )
  }

  handleTimeCardChange = (key, val, err) => {
    this.setState(state => ({
      time: {
        ...state.time,
        [key]: typeof state.time[key] === 'object'
          ? {
            value: val,
            error: err || ''
          }
          : val
      }
    }))
  }

  timeCardValues = () => {
    const { time } = this.state
    return {
      tabIndex: time.activeTimeTabIndex,
      lastTimeModeIndex: time.type,
      customLast: time.period,
      customUnitIndex: time.unit,
      fromDate: {
        value: time.startDate.value,
        error: time.startDate.error
      },
      fromTime: {
        value: time.startTime.value,
        error: time.startTime.error
      },
      toDate: {
        value: time.endDate.value,
        error: time.endDate.error
      },
      toTime: {
        value: time.endTime.value,
        error: time.endTime.error
      }
    }
  }

  /**
   * @description Returns an object which stores the state keys of this component maped to the TimeCards state keys.
   *              Needed for update function inside the TimeCard.
   * @returns {Object}
   */
  timeCardStateKeys = () => {
    return {
      tabIndex: 'activeTimeTabIndex',
      lastTimeModeIndex: 'type',
      customLast: 'period',
      customUnitIndex: 'unit',
      fromDate: 'startDate',
      fromTime: 'startTime',
      toDate: 'endDate',
      toTime: 'endTime'
    }
  }

  /**
   * @description Renders the time card.
   */
  renderTimeCard = () => {
    const { id, language, datemask } = this.props

    return (
      <TimeCard
        id={id}
        lang={language}
        datemask={datemask}
        values={this.timeCardValues()}
        stateKeys={this.timeCardStateKeys()}
        onValuesChange={this.handleTimeCardChange}
        parentContainer={'drawer_content_queue_body_index_queue_main'}
        translate={key => translate(key)}
      />
    )
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id } = this.props
    const { form, extension, report, activeStatusIndex } = this.state

    return (
      <Card title={translate('general.general')}>
        {/* form + extension row */}
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_form`}
              onInputChanged={(value, error) => this.handleChange('form', value, error)}
              value={form.value}
              title={translate('general.form')}
              error={form.error && translate(form.error)}
              maxLength={8}
              addon={{
                iconName: 'list',
                onClick: () => this.openDocumentDefinitionsSelector()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_extension`}
              onInputChanged={(value, error) => this.handleChange('extension', value, error)}
              value={extension.value}
              title={translate('general.extension')}
              error={extension.error && translate(extension.error)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.openDocumentDefinitionsSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_report`}
              onInputChanged={(value, error) => this.handleChange('report', value, error)}
              value={report.value}
              title={translate('general.report')}
              error={report.error && translate(report.error)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.openDocumentDefinitionsSelector()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Switch
              id={`${id}_status`}
              title={translate('general.status')}
              items={[
                translate('general.any'),
                translate('general.active'),
                translate('general.waiting'),
                translate('general.error')
              ]}
              maxPerRow={2}
              activeIndex={activeStatusIndex}
              onClick={index => this.setState({ activeStatusIndex: index })}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the main components.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {/* selector dialogs */}
        {this.renderSelectorDialogs()}
        {/* time card */}
        {this.renderTimeCard()}
        {/* general card */}
        {this.renderGeneralCard()}
      </div>
    )
  }

  /**
   * @description Renders the footer components.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div id={`${id}_footer`} className='bux_drawer_footer'>
        <Button
          id={'drawer_content_queue_body_search'}
          text={translate('general.search')}
          onClick={() => this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={'drawer_content_queue_body_resetBtn'}
          icon='undo'
          iconType='material'
          onClick={this.resetSearchCriteria}
        />
      </div>
    )
  }

  /**
   * @description Renders the components.
   */
  render = () => {
    const { id } = this.props

    return (
      <form
        id={id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {/* main */}
        {this.renderMain()}
        {/* footer */}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    language: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences.DATEMASK,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDocumentDefinitions: (fields, form, extension, report, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    getIndexQueues: (searchParams, callback) => {
      IndexQueueActions.getIndexQueues(searchParams, callback)(dispatch)
    },
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) }
  }
}

QueueIndex.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueIndex)