import * as StatisticActions from '../actions/StatisticActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  bsastats: undefined,
  bsalogxstats: undefined,
  enqueues: undefined,
  components: undefined,
  serverstatus: undefined,
  activeUsers: undefined,
  connects: undefined,
  transactions: undefined,
  downloadBsastatsPdf: {}
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Statistic bsastats actions
    case StatisticActions.STATISTIC_BSASTATS_START:
      return { ...state }
    case StatisticActions.STATISTIC_BSASTATS_SUCCESS:
      return { ...state, bsastats: action.payload.data }
    case StatisticActions.STATISTIC_BSASTATS_FAILED:
      return { ...state, bsastats: {} }
    /* ********************************************************************************** */
    // Statistic download pdf actions
    case StatisticActions.STATISTIC_DOWNLOAD_PDF_START:
      return { ...state }
    case StatisticActions.STATISTIC_DOWNLOAD_PDF_SUCCESS:
      return { ...state, downloadBsastatsPdf: action.payload }
    case StatisticActions.STATISTIC_DOWNLOAD_PDF_FAILED:
      return { ...state, downloadBsastatsPdf: {} }
    /* ********************************************************************************** */
    // Statistic bsalogxstats actions
    case StatisticActions.STATISTIC_LOGX_BSASTATS_START:
      return { ...state }
    case StatisticActions.STATISTIC_LOGX_BSASTATS_SUCCESS:
      return { ...state, bsalogxstats: action.payload.data }
    case StatisticActions.STATISTIC_LOGX_BSASTATS_FAILED:
      return { ...state, bsalogxstats: {} }
    /* ********************************************************************************** */
    // Statistic enqueues actions
    case StatisticActions.STATISTIC_ENQUEUES_START:
      return { ...state }
    case StatisticActions.STATISTIC_ENQUEUES_SUCCESS:
      return { ...state, enqueues: action.payload.data }
    case StatisticActions.STATISTIC_ENQUEUES_FAILED:
      return { ...state }
    case StatisticActions.NO_ENQUEUES_FOUND:
      return { ...state, enqueues: {} }
    /* ********************************************************************************** */
    // Statistic components actions
    case StatisticActions.STATISTIC_COMPONENTS_START:
      return { ...state }
    case StatisticActions.STATISTIC_COMPONENTS_SUCCESS:
      return { ...state, components: action.payload.data }
    case StatisticActions.STATISTIC_COMPONENTS_FAILED:
      return { ...state }
    case StatisticActions.NO_COMPONENTS_FOUND:
      return { ...state, components: {} }
    /* ********************************************************************************** */
    // Statistic serverstatus actions
    case StatisticActions.STATISTIC_SERVERSTATUS_START:
      return { ...state }
    case StatisticActions.STATISTIC_SERVERSTATUS_SUCCESS:
      return { ...state, serverstatus: action.payload.data }
    case StatisticActions.STATISTIC_SERVERSTATUS_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Statistic active users actions
    case StatisticActions.STATISTIC_ACTIVEUSERS_START:
      return { ...state }
    case StatisticActions.STATISTIC_ACTIVEUSERS_SUCCESS:
      return { ...state, activeUsers: action.payload.data }
    case StatisticActions.STATISTIC_ACTIVEUSERS_FAILED:
      return { ...state }
    case StatisticActions.NO_ACTIVEUSERS_FOUND:
      return { ...state, activeUsers: {} }
    /* ********************************************************************************** */
    // Statistic connects actions
    case StatisticActions.STATISTIC_CONNECTS_START:
      return { ...state }
    case StatisticActions.STATISTIC_CONNECTS_SUCCESS:
      return { ...state, connects: action.payload.data }
    case StatisticActions.STATISTIC_CONNECTS_FAILED:
      return { ...state }
    case StatisticActions.NO_CONNECTS_FOUND:
      return { ...state, connects: {} }
    /* ********************************************************************************** */
    // Statistic transactions actions
    case StatisticActions.STATISTIC_TRANSACTIONS_START:
      return { ...state }
    case StatisticActions.STATISTIC_TRANSACTIONS_SUCCESS:
      return { ...state, transactions: action.payload.data }
    case StatisticActions.STATISTIC_TRANSACTIONS_FAILED:
      return { ...state }
    case StatisticActions.NO_TRANSACTIONS_FOUND:
      return { ...state, transactions: {} }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return {
        ...state,
        enqueues: undefined,
        components: undefined,
        serverstatus: undefined,
        activeUsers: undefined,
        connects: undefined,
        transactions: undefined,
        bsastats: undefined,
        bsalogxstats: undefined
      }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer