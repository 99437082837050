import { Column, Input, Row, TitledLabel } from 'BetaUX2Web-Components/src';
import { IndexCriteria, IndexInfo } from './SearchByIndex.types';
import { useEffect, useState } from 'react';
import { translate } from 'language/Language';

import './SearchByIndexDialog.scss';

interface IndexSearchCriteriaProps {
  indexes: IndexInfo[];
  criteria: Record<number, IndexCriteria>;
  onCriteriaValueChanged: (criteria: Record<number, IndexCriteria>) => void;
}

export const IndexSearchCriteria = ({ ...props }: IndexSearchCriteriaProps) => {
  const [editedCriteria, setEditedCriteria] = useState<Record<number, IndexCriteria>>(props.criteria)

  useEffect(() => {
    setEditedCriteria(props.criteria)
  }, [props.criteria])
  const onInputChange = (value: string, index: number, isFrom: boolean) => {
    const newEditedCriteria = { ...editedCriteria }
    if (newEditedCriteria[index]) {
      newEditedCriteria[index][isFrom ? 'from' : 'to'] = value;
    }
    else {
      newEditedCriteria[index] = {
        internalIndexName: props.indexes[index].internalIndexName,
        from: isFrom ? value : '',
        to: isFrom ? '' : value,
      }
    }
    setEditedCriteria(newEditedCriteria);
    props.onCriteriaValueChanged(newEditedCriteria);
  }

  const covertCriterionIntoInputFields = (criterion: IndexInfo, index: number) => {
    return <div className={'criterion_container_column'} key={`${index}`}>
      <TitledLabel id={`criterion_label_${criterion.internalIndexName}`} text={criterion.externalIndexName + ' (' + criterion.numberOfExisting + ')'} boldText/>
      <Row className={'criterion_container'}>
        <Column>
          <div className={'criterion_container'}>
            <Input
              id={`criterion_from_${criterion.internalIndexName}`}
              title={translate('general.from')}
              maxLength={criterion.maxLength}
              value={editedCriteria[index]?.from}
              onInputChanged={(value) => onInputChange(value, index, true)} />
            <Input
              id={`criterion_to_${criterion.internalIndexName}`}
              title={translate('general.to')}
              maxLength={criterion.maxLength}
              value={editedCriteria[index]?.to}
              onInputChanged={(value) => onInputChange(value, index, false)} />
          </div>
        </Column>
      </Row>
    </div>
  };

  return (<div className='criteria_container'>
    {
      props.indexes.map((criterion, i) => covertCriterionIntoInputFields(criterion, i))
    }
  </div>);
}