/**
 * @description Checks if the arrays are equal.
 * @param {Array} array1 The first array to check.
 * @param {Array} array2 The second array to check.
 * @param {Boolean} strict Flag for strict check.
 * @returns {Boolean} True if the arrays are equal.
 */
export function equalsArrays(array1, array2, strict) {
  if (!array1 || !array2)
    return false

  if (arguments.length === 2)
    strict = true

  if (array1.length !== array2.length)
    return false

  for (var i = 0; i < array1.length; i++) {
    if (array1[i] instanceof Array && array2[i] instanceof Array) {
      if (!equalsArrays(array1[i], array2[i], strict))
        return false
    }
    else if (strict && array1[i] !== array2[i]) {
      return false
    }
    else if (!strict) {
      return array1.sort().equals(array2.sort(), true)
    }
  }
  return true
}

/**
 * @description Checks if the the passed array exists in the passed arrays param.
 * @param {Array} arrays An array of arrays.
 * @param {Array} array The array which should be checked if it exists in the arrays param.
 * @returns {Boolean} True if the passed array exists in the passed arrays.
 */
export function includesArray(arrays, array) {
  for (let i = 0; i < arrays.length; i++) {
    const element = arrays[i]

    if (equalsArrays(element, array, true)) {
      return true
    }
  }

  return false
}