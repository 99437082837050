import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

export const VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_START = 'VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_START'
export const VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_SUCCESS = 'VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_SUCCESS'
export const VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_FAILED = 'VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_FAILED'
export const NO_VIEW_PROFILE_DEFINITION_VIEW_PROFILES_FOUND = 'NO_VIEW_PROFILE_DEFINITION_VIEW_PROFILES_FOUND'

export const VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILE_SUCCESS = 'VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILE_SUCCESS'

export const VIEW_PROFILE_DEFINITION_DELETE_SUCCESS = 'VIEW_PROFILE_DEFINITION_DELETE_SUCCESS'

/**
 * @description Calling the rest api and requests get view profiles.
 * @param {String} viewProfileID
 * @param {String} title
 * @param {String} docType
 * @param {String} owner
 * @param {Function} callback
 * @param {Boolean} keepPagination
 */
export function getViewProfiles(viewProfileID, title, docType, owner, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_START })
    const queryParams = []
    if (viewProfileID) { queryParams.push(`PBDNAME=${encodeURIComponent(viewProfileID)}`) }
    if (title) { queryParams.push(`PBDTITLE=${encodeURIComponent(title)}`) }
    if (docType) { queryParams.push(`SRCJOBI=${encodeURIComponent(docType)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/viewprofiles?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_VIEW_PROFILE_DEFINITION_VIEW_PROFILES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_FAILED })
          }
        } else {
          dispatch({ type: VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.view_profiles_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and requests get view profile.
 * @param {String} viewProfileID
 * @param {String} docType
 * @param {Function} callback
 * @param {Boolean} keepPagination
 */
export function getViewProfile(viewProfileID, docType, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const queryParams = []
    queryParams.push(`PBDNAME=${encodeURIComponent(viewProfileID)}`)
    queryParams.push(`SRCJOBI=${encodeURIComponent(docType)}`)

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/viewprofile?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        }
        else {
          dispatch({ type: VIEW_PROFILE_DEFINITION_GET_VIEW_PROFILE_SUCCESS, payload: jsondata, keepPagination })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.view_profile_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes the entry
 * @param {Object} viewProfile
 * @param {Function} callback
 */
export function deleteViewProfile(viewProfile, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/viewprofile${createQueryParamsForFetch(ObjectUtils.removeByValue(viewProfile, [undefined, null]))}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        }
        else {
          dispatch({ type: VIEW_PROFILE_DEFINITION_DELETE_SUCCESS })
          SnackbarActions.show(Lang.translate('definition.delete_view_profile_success', lang, viewProfile['PBDNAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_view_profile_error', lang, [viewProfile['PBDNAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a view profile entry
 * @param {Object} viewProfileObj
 * @param {Function} callback
 */
export function createViewProfile(viewProfileObj, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/viewprofile`, {
        method: 'post',
        body: ObjectUtils.removeByValue(viewProfileObj, [undefined, null])
      })
    )
    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.create_view_profile_success', lang, viewProfileObj['PBDNAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.create_view_profile_error', lang, [viewProfileObj['PBDNAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and updates a view profile
 * @param {Object} viewProfileObj
 * @param {Function} callback
 */
export function updateViewProfile(viewProfileObj, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/viewprofile`, {
        method: 'put',
        body: ObjectUtils.removeByValue(viewProfileObj, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
        } else {

          SnackbarActions.show(Lang.translate('definition.update_view_profile_success', lang, viewProfileObj['PBDNAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.update_view_profile_error', lang, [viewProfileObj['PBDNAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Refreshes the search with redux prefs
 */
export const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    getViewProfiles(
      prefs[Preferences.DEFINITION_VIEW_PROFILE_ID],
      prefs[Preferences.DEFINITION_VIEW_PROFILE_TITLE],
      prefs[Preferences.DEFINITION_VIEW_PROFILE_DOCTYPE],
      prefs[Preferences.DEFINITION_VIEW_PROFILE_OWNER]
    )(dispatch)
  }
}