import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'

/**
 * @description Gets the snackbar content and successObjects from a response.
 * @param {Array} data The array of data. Typical from rest api as additionalInfo.
 * @param {String} successMessage The translated message for success.
 * @param {Object} dispatch The dispatchable object.
 * @param {Boolean} combine Decides if the successmessages should be combined or not. Is usefull when successmessages has parameters which are different for each message.
 * @returns {Object} {'snackbarContent': [], 'successObjects': []}
 */
export function processResponse(data, successMessage, dispatch, combine = true) {
  const errorsCombined = []
  const successObjects = []

  // loop through all data objects
  data.forEach((entry, index) => {
    if (entry.success) {
      // Count the success
      // Semicolon is necessary for the tenery in the next line to work because of the brackets
      const idx = errorsCombined.findIndex(e => e.success);
      (idx === -1 || !combine)
        // if no success objects exists create it
        // in some cases we need to pass parameters to the successmessage, so its helpfull to declare a function which provides the language key with params
        ? errorsCombined.push({ count: 1, success: true, rc: 0, irc: 0, message: typeof successMessage === 'function' ? successMessage(index) : successMessage })
        // if success object already exist increase counter by 1
        : errorsCombined[idx].count++
      successObjects.push(entry.token)
    } else {
      const idx = errorsCombined.findIndex(e => e.rc === entry.error.rc && e.irc === entry.error.irc)
      if (idx === -1) {
        // if no error object with the rc + irc exist create it

        // Special handling for RC8 IRC16, then the error is not in "param" but in "message" of REST-API
        if (entry.error.rc === '0008' && entry.error.irc === '0016') {
          errorsCombined.push({ count: 1, success: false, rc: entry.error.rc, irc: entry.error.irc, param: entry.error.param ? [entry.error.message] : [] })
        } else {
          errorsCombined.push({ count: 1, success: false, rc: entry.error.rc, irc: entry.error.irc, param: entry.error.param ? [entry.error.param] : [] })
        }
      } else {
        // if error object already exist increase it by 1
        errorsCombined[idx].count++
        if (errorsCombined[idx].param !== undefined) {
          errorsCombined[idx].param.push(entry.error.param)
        }
      }
    }
  })

  // create snackbar content
  const snackbarContent = errorsCombined.map(
    errorCombObj => {
      let errorObj
      if (!errorCombObj.success) {
        errorObj = GeneralErrorHandler.handleResponse(errorCombObj.rc, errorCombObj.irc, errorCombObj.param.filter(e => e !== '').join(', '), dispatch)
        return { text: `(${errorCombObj.count}) ${errorObj.message}`, type: 'error' }
      } else {
        return { text: `(${errorCombObj.count}) ${errorCombObj.message}`, type: 'success' }
      }
    }
  )

  return {
    'snackbarContent': snackbarContent,
    'successObjects': successObjects
  }
}

export const parseErrorParams = input => {
  // try catch is used, because the rest api do not send a stringified object which we can parse when its just a single parameter.
  try {
    const parsedInput = JSON.parse(input)
    if (Array.isArray(parsedInput)) {
      // I used this way because the langugae keys for the different RCs have mostly just one parameter. So the array just could have one entry in which I write all parameters.
      const result = parsedInput.map(d => d.param)
      return [result.join(', ')]
    }
  }
  catch {
    return input
  }
}