import React, {Fragment} from 'react';

export interface TabProps {
  title: string;
  translateTitle?: boolean;
  // labelIcon?: string;
  date?: string;
  content: JSX.Element | string;
  disableClose?: boolean;
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
export const Tab = (_props: TabProps): JSX.Element => {
  return <Fragment/>;
};