import { applyMiddleware, compose, createStore } from 'redux'
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync'
import rootReducer from './reducer/Index'

import * as LocalStorage from './middleware/LocalStorage'
import * as SNACKBAR_ACTIONS from './actions/SnackbarActions';

const config = {
  // blacklist: Listed actions will not be triggered in other tabs to prevent duplicate snackbar notifications
  blacklist: [SNACKBAR_ACTIONS.SNACKBAR_HIDE, SNACKBAR_ACTIONS.SNACKBAR_MULTIPLE_SHOW, SNACKBAR_ACTIONS.SNACKBAR_REMOVE, SNACKBAR_ACTIONS.SNACKBAR_SHOW],
  // prepareState: State of new tab will initialize with empty snackbar state to prevent existing snackbar notification to be shown upon tab opening
  prepareState: (state) => ({ ...state, snackbar: []}),
};

const localStorageStore = LocalStorage.loadState()
const middleware = [createStateSyncMiddleware(config)]

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;
const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // Other, if needed ..
)

const store = createStore(
  rootReducer,
  localStorageStore,
  enhancer
)

store.subscribe(() => {
  LocalStorage.saveState(store.getState())
})

initStateWithPrevTab(store)

export default store