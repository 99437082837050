import React, { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

import { DocumentBrowser } from '../components/DocumentBrowser';
import { Drawer } from '../components/Drawer';
import { DrawerNavbarButton } from '../components/drawer_navbar/DrawerNavbarButton';
import { EditableWidthDrawer } from 'components/editable-width_drawer/EditableWidthDrawer';

import { TabContentContext } from '../contexts/TabContentContext';

import * as ATTRIBUTES from '../constants/attributes';
import * as DATA from '../constants/data';
import * as ROUTES from '../constants/routes';

import { translate } from 'language/Language';

import * as TYPES_DOC_VIEWER from '../types/DocumentViewer.types';
import { isJob } from 'utils/DocViewerUtils';

const NUM_PAGE_INITIAL = 1;

export const TabContentProvider = ({ id }: { id: TYPES_DOC_VIEWER.TTabId }) => {
  const tabType: TYPES_DOC_VIEWER.ITabData['type'] = useSelector((state: RootStateOrAny) => state.docviewer.tabs.find((tab: TYPES_DOC_VIEWER.ITabData) => tab.id === id).type);

  const [pageNumber, setPageNumber] = useState<number>(NUM_PAGE_INITIAL);
  const [viewMode, setViewMode] = useState<TYPES_DOC_VIEWER.TTabViewMode>(DATA.TAB_CONFIG_VIEW_MODE_TEXT);
  const cacheKey = `${id}_${viewMode}_${pageNumber}`;

  /**
   * @description This function will update the page number to render the related content.
   * @param newPageNumber New page number to use as index for rendering page contents (see DocumentBrowserPageArea).
   * @param pageCount
   */
  const handlePageChange = (newPageNumber: number, pageCount: number) => {
    const isInvalidPageNumber = isNaN(newPageNumber) || newPageNumber < 1 || newPageNumber > pageCount;

    setPageNumber(isInvalidPageNumber ? pageNumber : newPageNumber);
  }

  const getNavbarButtons = (): JSX.Element[] => {
    return [
      <DrawerNavbarButton
        id={`${ATTRIBUTES.DRAWER_ID}_nav_documentInfo`}
        key={`${ATTRIBUTES.DRAWER_ID}_nav_documentInfo`}
        icon={'document'}
        title={translate('documentinformation.modaltitle')}
        link={ROUTES.DOCUMENT_VIEWER_URL_BASE}
        links={[]}
        selected={viewMode === DATA.TAB_CONFIG_VIEW_MODE_TEXT}
        onAutoDrawer={() => viewMode !== DATA.TAB_CONFIG_VIEW_MODE_TEXT ? setViewMode(DATA.TAB_CONFIG_VIEW_MODE_TEXT) : null}
      />,
      <DrawerNavbarButton
        id={`${ATTRIBUTES.DRAWER_ID}_nav_hex_view`}
        key={`${ATTRIBUTES.DRAWER_ID}_nav_hex_view`}
        icon={'show_hex'}
        title={translate('documentviewer.hex_view')}
        link={ROUTES.DOCUMENT_VIEWER_URL_BASE}
        links={[]}
        selected={viewMode !== DATA.TAB_CONFIG_VIEW_MODE_TEXT}
        onAutoDrawer={() => viewMode === DATA.TAB_CONFIG_VIEW_MODE_TEXT ? setViewMode(DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE) : null}
        isDisabled={isJob(tabType)}
      />
    ]
  }

  return (
    <TabContentContext.Provider value={{ cacheKey, id, handlePageChange, pageNumber, viewMode, setViewMode }}>
      <EditableWidthDrawer
        id={`${id}_drawer`}
        contentLeft={<Drawer />}
        contentRight={<DocumentBrowser />}
        navbarButtons={getNavbarButtons()} // Provider is tightly coupled to 'EditableWidthDrawer' component due to logic of 'selected' prop in 'NavbarButton';
      />
    </TabContentContext.Provider>
  )
};