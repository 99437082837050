import React, { ReactNode, ReactNodeArray } from 'react';

import './Column.scss';


interface ColumnProps {
  /** Optional id of container div */
  id?: string,
  /** For all sizes */
  col?: number;
  /** Extra small col property for <576px (Phones) */
  colXS?: number;
  /** Small col property for >=576px (Tables) */
  colSM?: number;
  /** Medium col property for >=768px (Small laptops) */
  colMD?: number;
  /** Large col property for >=992px (Laptops and desktops) */
  colLG?: number;
  /** Extra large col property for >=1200px (Laptops and desktops) */
  colXL?: number;
  /** Offset for all sizes */
  offset?: number;
  /** Extra small offset property to move columns to the right */
  offsetXS?: number;
  /** Small offset property to move columns to the right */
  offsetSM?: number;
  /** Medium offset property to move columns to the right */
  offsetMD?: number;
  /** Large offset property to move columns to the right */
  offsetLG?: number;
  /** Extra large offset property to move columns to the right */
  offsetXL?: number;
  /** Add outside padding */
  padded?: boolean;
  /** Add outside padding */
  noGap?: boolean;
  /** Enables column to take full hight of parent.*/
  fullHeight?: boolean;
  /** Style class from CSS for styling for column.*/
  className?: string;
  /** Children of the component.*/
  children?: ReactNode | ReactNodeArray;
}

const Column = ({ ...props }: ColumnProps): JSX.Element => {

  const classes = ['cl_column'];
  // append col settings
  if (props.col) { classes.push(`col-${props.col}`) }
  if (props.colXS) { classes.push(`col-${props.colXS}`) }
  if (props.colSM) { classes.push(`col-sm-${props.colSM}`) }
  if (props.colMD) { classes.push(`col-md-${props.colMD}`) }
  if (props.colLG) { classes.push(`col-lg-${props.colLG}`) }
  if (props.colXL) { classes.push(`col-xl-${props.colXL}`) }
  if (!props.colMD && props.colLG) { classes.push(`col-sm-${props.colLG}`) }

  // error in console if there is no col property
  if (classes.length === 1) {
    classes.push('col');
  }

  // append offset settings
  if (props.offset) { classes.push(`offset-${props.offset}`) }
  if (props.offsetXS) { classes.push(`offset-${props.offsetXS}`) }
  if (props.offsetSM) { classes.push(`offset-sm-${props.offsetSM}`) }
  if (props.offsetMD) { classes.push(`offset-md-${props.offsetMD}`) }
  if (props.offsetLG) { classes.push(`offset-lg-${props.offsetLG}`) }
  if (props.offsetXL) { classes.push(`offset-xl-${props.offsetXL}`) }

  if (props.fullHeight) { classes.push('cl_full_height') }

  return (
    <div
      id={props.id}
      className={`
          ${classes.join(' ')}
          ${props.className ? props.className : ''}
          ${props.noGap ? 'cl_no_gap' : ''}
          ${props.padded ? 'cl_padded' : ''}
        `}>
      {props.children}
    </div>
  );
};


export default Column;