import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const INDEX_DOCUMENT_GET_ASSIGNMENTS_START = 'INDEX_DOCUMENT_GET_ASSIGNMENTS_START'
export const INDEX_DOCUMENT_GET_ASSIGNMENTS_SUCCESS = 'INDEX_DOCUMENT_GET_ASSIGNMENTS_SUCCESS'
export const INDEX_DOCUMENT_GET_ASSIGNMENTS_FAILED = 'INDEX_DOCUMENT_GET_ASSIGNMENTS_FAILED'

export const INDEX_DOCUMENT_GET_ASSIGNMENT_START = 'INDEX_DOCUMENT_GET_ASSIGNMENT_START'
export const INDEX_DOCUMENT_GET_ASSIGNMENT_SUCCESS = 'INDEX_DOCUMENT_GET_ASSIGNMENT_SUCCESS'
export const INDEX_DOCUMENT_GET_ASSIGNMENT_FAILED = 'INDEX_DOCUMENT_GET_ASSIGNMENT_FAILED'

export const INDEX_DOCUMENT_CREATE_ASSIGNMENT_START = 'INDEX_DOCUMENT_CREATE_ASSIGNMENT_START'
export const INDEX_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS = 'INDEX_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS'
export const INDEX_DOCUMENT_CREATE_ASSIGNMENT_FAILED = 'INDEX_DOCUMENT_CREATE_ASSIGNMENT_FAILED'

export const INDEX_DOCUMENT_DELETE_ASSIGNMENT_START = 'INDEX_DOCUMENT_DELETE_ASSIGNMENT_START'
export const INDEX_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS = 'INDEX_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS'
export const INDEX_DOCUMENT_DELETE_ASSIGNMENT_FAILED = 'INDEX_DOCUMENT_DELETE_ASSIGNMENT_FAILED'

export const INDEX_DOCUMENT_UPDATE_ASSIGNMENT_START = 'INDEX_DOCUMENT_UPDATE_ASSIGNMENT_START'
export const INDEX_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS = 'INDEX_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS'
export const INDEX_DOCUMENT_UPDATE_ASSIGNMENT_FAILED = 'INDEX_DOCUMENT_UPDATE_ASSIGNMENT_FAILED'

export const NO_INDEX_DOCUMENT_ASSIGNMENTS_FOUND = 'NO_INDEX_DOCUMENT_ASSIGNMENTS_FOUND'

export function getIndexDocumentAssignments(fields, indexID, form, ext, report, scope, sortBy, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: INDEX_DOCUMENT_GET_ASSIGNMENTS_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    if (indexID) { queryParams.push(`IXINAME=${encodeURIComponent(indexID)}`) }
    if (form) { queryParams.push(`FORM=${encodeURIComponent(form)}`) }
    if (ext) { queryParams.push(`EXT=${encodeURIComponent(ext)}`) }
    if (report) { queryParams.push(`REPORT=${encodeURIComponent(report)}`) }
    if (scope) { queryParams.push(`IXSCOPE=${encodeURIComponent(scope)}`) }
    if (sortBy) { queryParams.push(`SORTBY=${encodeURIComponent(sortBy)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/indexdocumentassignments?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_INDEX_DOCUMENT_ASSIGNMENTS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: INDEX_DOCUMENT_GET_ASSIGNMENTS_FAILED })
          }
        }
        else {
          dispatch({ type: INDEX_DOCUMENT_GET_ASSIGNMENTS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.index_document.loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: INDEX_DOCUMENT_GET_ASSIGNMENTS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getIndexDocumentAssignment(fields, indexID, form, ext, report, variantNumber, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: INDEX_DOCUMENT_GET_ASSIGNMENT_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    queryParams.push(`IXINAME=${encodeURIComponent(indexID)}`)
    queryParams.push(`FORM=${encodeURIComponent(form)}`)
    queryParams.push(`EXT=${encodeURIComponent(ext)}`)
    queryParams.push(`REPORT=${encodeURIComponent(report)}`)
    queryParams.push(`IXINSTNR=${encodeURIComponent(variantNumber)}`)

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/indexdocumentassignment?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: INDEX_DOCUMENT_GET_ASSIGNMENT_FAILED })
          LoadingSpinnerActions.hide()(dispatch)
        } else {
          dispatch({ type: INDEX_DOCUMENT_GET_ASSIGNMENT_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }

          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('assignment.index_document.get_assignment_error', lang, [indexID, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: INDEX_DOCUMENT_GET_ASSIGNMENT_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function createIndexDocumentAssignment(indexDocument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]
    let assignmentMessage = ''
    assignmentMessage += `${Lang.translate('definition.index_id')}: ${indexDocument.IXINAME}`
    assignmentMessage += `, ${Lang.translate('general.form')}: ${indexDocument.FORM}`
    if (indexDocument.EXT !== '') {
      assignmentMessage += `, ${Lang.translate('general.extension')}: ${indexDocument.EXT}`
    }
    if (indexDocument.REPORT !== '') {
      assignmentMessage += `, ${Lang.translate('general.report')}: ${indexDocument.REPORT}`
    }
    dispatch({ type: INDEX_DOCUMENT_CREATE_ASSIGNMENT_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/indexdocumentassignment`, {
        method: 'post',
        body: ObjectUtils.removeByValue(indexDocument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: INDEX_DOCUMENT_CREATE_ASSIGNMENT_FAILED })
        } else {
          dispatch({ type: INDEX_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS })

          SnackbarActions.show(Lang.translate('assignment.create_assignment_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          relaod()(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('assignment.create_assignment_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: INDEX_DOCUMENT_CREATE_ASSIGNMENT_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and updates an index document assignment.
 * @param {Object} indexDocument The index document assignment. Possible fields in object:
 * FORM (required): The form. (String)
 * EXT (required): The extension. (String)
 * REPORT (required): The report. (String)
 * IXINAME (required): The index name. (String)
 * IXINSTNR (required): The variant number. (Integer)
 * IXSMASK: The index search mask. (String)
 * IXSSCOL: The start column for index search mask. (Integer)
 * IXSECOL: The end column for index search mask. (Integer)
 * IXSSLINE: The start line for index search mask. (Integer)
 * IXSELINE: The end line for index search mask. (Integer)
 * IXEXTPOS: The extract position. (Integer)
 * IXEXTLEN: The extract length. (Integer)
 * IXSCOPE: The scope of the index. ('GLOBAL' or 'LOCAL')
 * IXBASED: The index base on:
 *   'DOCV' for doc user variables
 *   'ACIF' for ACIF index
 *   'NOPS' for AFPDS NOP record
 *   'PJL' for PJL statement
 *   'ETXT' for side file
 *   'MASK' for document
 * IXMATCH: The index search mask detection:
 *   'PFIRST' for detect matches: first in page only
 *   'LFIRST' for detect matches: first in line only
 *   'ANY' for detect matches: all
 * IXSKIPF: Flag for skip if format error. (Boolean)
 * IXDEBUG: Flag for write debug messages for index. (Boolean)
 * IXENODAT: Flag for index required. (Boolean)
 * IXSCHECK: Flag for security index. (Boolean)
 * IXUTISTR: Flag for use time string. (Boolean)
 * IXRUL1I: Substitute rule 1 input. (String)
 * IXRUL1O: Substitute rule 1 output. (String)
 * IXRUL2I: Substitute rule 2 input. (String)
 * IXRUL2O: Substitute rule 2 output. (String)
 * IXRUL3I: Substitute rule 3 input. (String)
 * IXRUL3O: Substitute rule 3 output. (String)
 * IXRUL4I: Substitute rule 4 input. (String)
 * IXRUL4O: Substitute rule 4 output. (String)
 * IXDATASC: Index hits refer to section. ('PAGE', 'LINE', 'HLINE', 'START', 'END')
 * IXPMASK: Search mask for include page. (String)
 * IXPSCOL: Search mask for incude page start column. (Integer)
 * IXPECOL: Search mask for incude page end column. (Integer)
 * IXPSLINE: Search mask for include page start line. (Integer)
 * IXPELINE: Search mask for include page end line. (Integer)
 * IXXMASK: Search mask for exclude page. (String)
 * IXXSCOL: Search mask for exclude page start column. (Integer)
 * IXXECOL: Search mask for exclude page end column. (Integer)
 * IXXSLINE: Search mask for exclude page start line. (Integer)
 * IXXELINE: Search mask for exclude page end line. (Integer)
 * IXUDTSTR: The user defined time string. (String)
 * IXUDTIME: The user defined LC_TIME. (String)
 * OWNER: The owner of the index definition. (String)
 * VDATEB: Valid from date.
 * VDATEE: Valid to date.
 * IXEXTREL: Extract in relation to search pattern.
 *   'LS' for line start
 *   'SS' for search pattern start
 *   'ES' for search pattern end
 * IXIDMASK: The index input date mask. (String)
 * IXEMASK: The extract value end mask. (String)
 * IXFOMRUL: The index format rule. (String)
 * IALIGNTR: Flag for align to right. (Boolean)
 * IDSPOINT: Flag for decimal separator is point. (Boolean)
 * @param {Function} callback The callback which will be called if the request was successful.
 */
export function updateIndexDocumentAssignment(indexDocument, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let assignmentMessage = ''
    assignmentMessage += `${Lang.translate('definition.index_id')}: ${indexDocument.IXINAME}`
    assignmentMessage += `, ${Lang.translate('general.form')}: ${indexDocument.FORM}`
    if (indexDocument.EXT !== '') {
      assignmentMessage += `, ${Lang.translate('general.extension')}: ${indexDocument.EXT}`
    }
    if (indexDocument.REPORT !== '') {
      assignmentMessage += `, ${Lang.translate('general.report')}: ${indexDocument.REPORT}`
    }

    dispatch({ type: INDEX_DOCUMENT_UPDATE_ASSIGNMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/indexdocumentassignment`, {
        method: 'put',
        body: ObjectUtils.removeByValue(indexDocument, [undefined, null])
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: INDEX_DOCUMENT_UPDATE_ASSIGNMENT_FAILED })
        }
        else {
          dispatch({ type: INDEX_DOCUMENT_UPDATE_ASSIGNMENT_SUCCESS, payload: jsondata.data })

          SnackbarActions.show(Lang.translate('assignment.index_document.modify_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          relaod()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('assignment.index_document.modify_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: INDEX_DOCUMENT_UPDATE_ASSIGNMENT_FAILED })
      })
  }
}

export function deleteIndexDocumentAssignment(indexDocument, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    let assignmentMessage = ''
    assignmentMessage += `${Lang.translate('definition.index_id')}: ${indexDocument.IXINAME}`
    assignmentMessage += `, ${Lang.translate('general.form')}: ${indexDocument.FORM}`
    if (indexDocument.EXT !== '') {
      assignmentMessage += `, ${Lang.translate('general.extension')}: ${indexDocument.EXT}`
    }
    if (indexDocument.REPORT !== '') {
      assignmentMessage += `, ${Lang.translate('general.report')}: ${indexDocument.REPORT}`
    }

    dispatch({ type: INDEX_DOCUMENT_DELETE_ASSIGNMENT_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/indexdocumentassignment${createQueryParamsForFetch(indexDocument)}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(res => res.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: INDEX_DOCUMENT_DELETE_ASSIGNMENT_FAILED })
        } else {
          dispatch({ type: INDEX_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS })

          SnackbarActions.show(
            Lang.translate('assignment.index_document.delete_success', lang, assignmentMessage), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          relaod()(dispatch)
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(
          Lang.translate('assignment.index_document.delete_error', lang, [assignmentMessage, reason]), SnackbarActions.TYPE_ERROR)(dispatch)

        dispatch({ type: INDEX_DOCUMENT_DELETE_ASSIGNMENT_FAILED })
      })
  }
}

const relaod = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    const indexID = prefs[Preferences.ASSIGNMENT_INDEX_DOC_INDEXID]
    const form = prefs[Preferences.ASSIGNMENT_INDEX_DOC_FORM]
    const ext = prefs[Preferences.ASSIGNMENT_INDEX_DOC_EXTENSION]
    const report = prefs[Preferences.ASSIGNMENT_INDEX_DOC_REPORT]
    const available = prefs[Preferences.ASSIGNMENT_INDEX_DOC_AVAILABLE]
    getIndexDocumentAssignments(undefined, indexID, form, ext, report, available, 'I', undefined, true)(dispatch)
  }
}