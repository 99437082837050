import moment from 'moment'
import PropTypes from 'prop-types'
import { Component } from 'react'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'

// components
import {
  Button, Card, Column,
  Input,
  Row, Switch
} from 'BetaUX2Web-Components/src/'
import Datepicker from 'components/datepicker/Datepicker'

// redux
import { connect } from 'react-redux'
import * as ArchiveFileActions from 'redux/actions/ArchiveFileServerActions'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'
import * as ServerUtils from 'utils/ServerUtils'

class ServerArchiveFile extends Component {

  defaultState = {
    archivePoolID: '',
    owner: '',
    file: '',
    archiveDate: {
      value: '',
      errorkey: ''
    },
    level3ArchiveIndex: 0
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
   * @description Updates the date when the datemask was changed.
   */
  componentDidUpdate = prevProps => {
    const { datemask } = this.props
    if (datemask !== prevProps.datemask) {
      // just updated the date when the date fits the previous datemask
      if (DateUtils.isDate(this.state.archiveDate.value, prevProps.datemask)) {
        const archiveDate = {
          value: moment(this.state.archiveDate.value, prevProps.datemask).format(datemask),
          errorkey: ''
        }
        this.setState({ archiveDate })
      }
      else {
        // remove error when date fits the new datemask
        if (DateUtils.isDate(this.state.archiveDate.value, datemask)) {
          this.setState({ archiveDate: { ...this.state.archiveDate, errorkey: '' }})
        }
      }
    }
  }

  /**
 * @description Initializes the import fields with the values saved in preferences.
 */
  initFieldsFromPreferences = () => {
    const { preferences, datemask } = this.props

    if (preferences) {
      const archivePoolID = preferences[Preferences.SERVER_ARCHIVEFILE_ARCHIVEPOOL_ID] || ''
      const owner = preferences[Preferences.SERVER_ARCHIVEFILE_OWNER] || ''
      const file = preferences[Preferences.SERVER_ARCHIVEFILE_FILE] || ''

      let archiveDate = preferences[Preferences.SERVER_ARCHIVEFILE_ARCHIVEDATE] || ''
      if (DateUtils.isUnixTimestamp(archiveDate)) {
        archiveDate = DateUtils.getDateFromUnixTimestamp(archiveDate, datemask)
      }
      else {
        archiveDate = ''
      }

      const level3Archive = preferences[Preferences.SERVER_ARCHIVEFILE_LEVEL3ARCHIVE] || ServerUtils.ARCHIVEFILE_LEVEL3ARCHIVE_ANY
      const level3ArchiveIndex = Math.max(ServerUtils.ARCHIVEFILE_LEVEL3ARCHIVE.findIndex(temp => temp.key === level3Archive), 0)
      this.setState({
        archivePoolID,
        owner,
        file,
        archiveDate: {
          value: archiveDate,
          errorkey: ''
        },
        level3ArchiveIndex
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param id The id of the field to change
   * @param value The new value
   */
  handleInputChanged = (id, value, errorkey) => {
    const valueToUse = errorkey !== undefined ? { value: value, errorkey: errorkey } : value
    this.setState({ [id]: valueToUse })
  }

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = event => {
    event.preventDefault()
    const { changePrefs, getArchiveFiles, datemask } = this.props
    const { archivePoolID, owner, file, archiveDate, level3ArchiveIndex } = this.state

    const date = DateUtils.getTimeshiftDate(archiveDate.value, '', DateUtils.DDMMYYYY_DOT)
    const level3Archive = ServerUtils.ARCHIVEFILE_LEVEL3ARCHIVE[level3ArchiveIndex].key

    const prefsToChange = {
      [Preferences.SERVER_ARCHIVEFILE_ARCHIVEPOOL_ID]: archivePoolID,
      [Preferences.SERVER_ARCHIVEFILE_OWNER]: owner,
      [Preferences.SERVER_ARCHIVEFILE_FILE]: file,
      [Preferences.SERVER_ARCHIVEFILE_ARCHIVEDATE]: DateUtils.getUnixTimestamp(archiveDate.value, datemask),
      [Preferences.SERVER_ARCHIVEFILE_LEVEL3ARCHIVE]: level3Archive
    }

    changePrefs(prefsToChange)
    getArchiveFiles(undefined, archivePoolID, file, date, level3Archive, owner)
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleOnReset = () => {
    this.setState(this.defaultState)
  }


  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { archivePoolID, owner, file, archiveDate, level3ArchiveIndex } = this.state
    const { id, lang, datemask } = this.props
    return (
      <div
        id={`${id}_main_archive_file`}
        className={'bux_drawer_main'}>
        <Card
          title={translate('general.general')}>
          <Row>
            <Column
              colMD={6}>
              <Input
                id={`${id}_archive_file_id`}
                onInputChanged={val => this.handleInputChanged('archivePoolID', val)}
                value={archivePoolID}
                title={translate('definition.archive_pool_id')}
                maxLength={8}
              />
            </Column>
            <Column
              colMD={6}>
              <Input
                id={`${id}_archive_file_owner`}
                onInputChanged={val => this.handleInputChanged('owner', val)}
                value={owner}
                title={translate('general.owner')}
                maxLength={8}
              />
            </Column>
          </Row>
          <Row>
            <Column
              colMD={12}>
              <Input
                id={`${id}_archive_file_file`}
                onInputChanged={val => this.handleInputChanged('file', val)}
                value={file}
                title={translate('definition.archive_file')}
                maxLength={256}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={6}>
              <Datepicker
                id={`${id}_archive_file_archive_date`}
                title={translate('definition.archive_date')}
                value={archiveDate.value}
                error={archiveDate.errorkey ? translate(archiveDate.errorkey) : ''}
                onChange={val => this.handleInputChanged('archiveDate', val, '')}
                language={lang}
                dateFormat={datemask}
                onInvalidDate={() => this.setState({ archiveDate: { ...this.state.archiveDate, errorkey: 'general.invalid_date_format' }})}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={12}>
              <Switch
                id={`${id}_archive_file_level3`}
                title={translate('definition.level_3_archive')}
                items={[
                  translate('general.any'),
                  translate('general.pending'),
                  translate('general.yes'),
                  translate('general.no'),
                ]}
                onClick={index => this.handleInputChanged('level3ArchiveIndex', index)}
                activeIndex={level3ArchiveIndex}
                maxPerRow={2}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search')}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleOnReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={() => { }}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences.DATEMASK
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getArchiveFiles: (fields, pool, file, archiveDate, level3arc, owner, callback) => {
      ArchiveFileActions.getArchiveFiles(fields, pool, file, archiveDate, level3arc, owner, callback)(dispatch)
    },
    changePrefs: prefs => {
      PreferenceActions.changePrefs(prefs)(dispatch)
    }
  }
}

ServerArchiveFile.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerArchiveFile)