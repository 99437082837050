import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Row from '../row/Row'
import Column from '../column/Column'
import DataTable from '../datatable/DataTable'
import * as DnDUtils from '../../utils/DnDUtils'
import * as Utils from '../../utils/Utils'
import { DragDropContext } from '@hello-pangea/dnd'


export default class RelationAssignments extends Component {

  /**
   * @description Creates the table row action for the datatable.
   * @param {Array} data The data of the datatable.
   * @param {Number} index The index of the row.
   * @param {Object} event The event which is thrown on clicking the row.
   */
  createTableRowAction = (data, index, event) => {
    if (event.defaultPrevented) {
      return
    }

    if (event.button !== 0) {
      return
    }

    event.preventDefault()

    this.performAction(event, data, data[index])
  }

  /**
   * @description Performes the select or unselect action.
   * @param {Object} event The mouse event.
   * @param {Array} data The data.
   * @param {String} id The id of the data which was clicked.
   */
  performAction = (event, data, id) => {
    const action = () => {
      // add to group (ctrl was pressed)
      if (DnDUtils.isToggleSelectionInGroup(event)) {
        this.props.toggleSelectionInGroup(id)
        return
      }

      // add all between selection
      if (event.shiftKey) {
        this.props.multiSelectTo(data, id)
        return
      }

      this.props.toggleSelection(id)
    }

    // dont allow to group items from different lists
    if (this.props.selectedIds.length > 0 && !Utils.includesArray(data, this.props.selectedIds[0])) {
      // Call delayed avoid race-condition
      this.props.unselectAll(action)
    } else {
      // call undelayed
      action()
    }
  }

  render() {
    const { id, lang, datemask } = this.props
    return (
      <DragDropContext
        onBeforeDragStart={this.props.onBeforeDragStart}
        onDragEnd={this.props.onDragEnd}>
        <Row className={'bux_user_group_assignment_datalist_row'}>
          {/* available list */}
          <Column
            colMD={6}
            className={'bux_user_group_assignment_datatable_column'}>
            <label className='control-label el_input_label'>
              {this.props.available.title}
            </label>
            <DataTable
              id={`${id}_available_list`}
              showDragHandle
              createTableRowAction={(index, event) => this.createTableRowAction(this.props.available.data, index, event)}
              header={this.props.available.headers}
              data={this.props.available.data}
              columnSortDefs={this.props.available.columnSortDefs}
              // onSortEnd={this.props.available.onSortEnd} TODO:
              // sortedCol={this.props.available.sortedCol} TODO:
              droppableID={this.props.available.droppableID}
              useParentContext
              selectedIds={this.props.selectedIds}
              draggingId={this.props.draggingId}
              language={lang}
              menu={false}
              datemask={datemask}
              translate={this.props.translate}
            />
          </Column>
          <Column
            colMD={6}
            className={'bux_user_group_assignment_datatable_column'}>
            <label className='control-label el_input_label'>
              {this.props.chosen.title}
            </label>
            <DataTable
              id={`${id}_chosen_list`}
              enableDnd
              showDragHandle
              createTableRowAction={(index, event) => this.createTableRowAction(this.props.chosen.data, index, event)}
              header={this.props.chosen.headers}
              data={this.props.chosen.data}
              columnSortDefs={this.props.chosen.columnSortDefs}
              // onSortEnd={this.props.chosen.onSortEnd} TODO:
              // sortedCol={this.props.chosen.sortedCol} TODO:
              menu={false}
              droppableID={this.props.chosen.droppableID}
              useParentContext
              selectedIds={this.props.selectedIds}
              draggingId={this.props.draggingId}
              language={lang}
              datemask={datemask}
              translate={this.props.translate}
            />
          </Column>
        </Row>
      </DragDropContext>
    )
  }
}

RelationAssignments.propTypes = {
  /** Unique ID for identification in HTML DOM.*/
  id: PropTypes.string.isRequired,
  /** Data for the left table */
  available: PropTypes.shape({
    title: PropTypes.string,
    headers: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.array,
    columnSortDefs: PropTypes.arrayOf(PropTypes.string),
    droppableID: PropTypes.string
  }).isRequired,
  /** Data for the right table */
  chosen: PropTypes.shape({
    title: PropTypes.string,
    headers: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.array,
    columnSortDefs: PropTypes.arrayOf(PropTypes.string),
    droppableID: PropTypes.string
  }).isRequired,
  /** Used to display the numbers in the right language format (e.g. 130.000 or 130,000 or 130 000).*/
  lang: PropTypes.string,
  /** Used to display the date entries in the correct format. */
  datemask: PropTypes.string,
  /**
   * Function to support toggle selection in group using ctrl
   * @param {string} id
   */
  toggleSelectionInGroup: PropTypes.func,
  /**
   * Function which provide support for selection between between the last selected item and the item to select.
   * @param {array} data
   * @param {string} id
   */
  multiSelectTo: PropTypes.func,
  /**
   * Function to support toggle selection of an item
   * @param {string} id
   */
  toggleSelection: PropTypes.func,
  /** Array of selected ids */
  selectedIds: PropTypes.array,
  /** Dragging id */
  draggingId: PropTypes.string,
  /**
   * Function to support deselection for all items
   * @param {function} action
   */
  unselectAll: PropTypes.func,
  /**
   * Function called on start of dragging action.
   * Could be used to sets the width and visibility properties for group dragging.
   * @param {object} dragStart
   */
  onBeforeDragStart: PropTypes.func,
  /**
   * Function called on the end of dragging action.
   * Could be used to change data of DataTables.
   * @param {object} dragStart
   */
  onDragEnd: PropTypes.func,
  /**
   * Translate function key => key
   * you will need to translate following keys by our own: see _DataTable_ component.
   *
   * @param {string} key
   */
  translate: PropTypes.func,
}