import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'

import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as UrlUtils from 'utils/UrlUtils'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as Preferences from 'redux/general/Preferences'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import { getNodeDocumentAssignments } from 'redux/actions/NodeDocumentAssignmentActions'

class AssignmentNodeDocument extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  defaultState = {
    nodeID: '',
    form: '',
    extension: '',
    showNodeDialog: false,
    showDocumentDialog: false
  }

  keys = [
    { rest: 'DNDNAME', ui: 'nodeID' },
    { rest: 'FORM', ui: 'form' },
    { rest: 'EXT', ui: 'extension' },
  ]

  state = { ...this.defaultState }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount = () => {
    if (window.location.href.indexOf('?') !== -1) {
      this.initFieldsFromUrl()
    }
    else {
      this.initFieldsFromPreferences()
    }
  }

  /**
   * @description Fills the state with the values from the url and executes the search when the parameter is given.
   */
  initFieldsFromUrl = () => {
    this.setState({
      ...this.state,
      ...UrlUtils.getDataFromUrlParams(this.props.location.search, this.keys)
    }, () => {
      if (UrlUtils.urlContainsExecute()) {
        this.search()
      }
    })
  }

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props
    if (preferences) {
      const nodeID = preferences[Preferences.ASSIGNMENT_NODE_DOC_NODEID] || ''
      const form = preferences[Preferences.ASSIGNMENT_NODE_DOC_FORM] || ''
      const extension = preferences[Preferences.ASSIGNMENT_NODE_DOC_EXTENSION] || ''

      this.setState({
        nodeID,
        form,
        extension
      })
    }
  }

  /**
   * @description Handles the changes on inputfield.
   * @param {String} key Key for inputfield in state
   * @param {String} val New value of inputfield
   */
  handleInput = (key, val) => this.setState({ [key]: val })

  /**
   * @description Handles the input changes of the parentnodeid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   */
  handleInputWithoutSpaces = (key, value) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleInput(key, value)
  }

  /**
   * @description Handles the node id selector button
   */
  handleNodeSelector = () => {
    this.props.getDocumentNodesDefinition(
      ['DNDNAME', 'DNDENAME'],
      this.state.nodeID,
      () => this.setState({ showNodeDialog: true })
    )
  }

  /**
   * @description Handles the form and extension selector button
   */
  handleDocumentSelector = () => {
    this.props.getDocumentDefinitions(
      ['FORM', 'EXT', 'REPORT'],
      this.state.form,
      this.state.extension,
      () => this.setState({ showDocumentDialog: true })
    )
  }

  /**
   * @description Handles the search button
   */
  handleSubmit = event => {
    event.preventDefault()
    this.search()
  }

  /**
   * @description Executes the search.
   */
  search = () => {
    const { nodeID, form, extension } = this.state
    const prefsToChange = {
      [Preferences.ASSIGNMENT_NODE_DOC_NODEID]: nodeID,
      [Preferences.ASSIGNMENT_NODE_DOC_FORM]: form,
      [Preferences.ASSIGNMENT_NODE_DOC_EXTENSION]: extension
    }

    this.props.changePrefs(prefsToChange)
    this.props.getNodeDocumentAssignments(nodeID, form, extension)

    // builds the parameter object which is used for url
    const urlToPush = `/assignment/node_document${UrlUtils.createUrlParamsFromObject(this.state, this.keys)}`
    this.props.history.push(urlToPush)
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props

    return (
      <>
        {this.state.showNodeDialog && (
          <SelectorDialog
            id={`${id}_nodedefinition_selector_dialog`}
            onClose={() => this.setState({ showNodeDialog: false })}
            title={translate('definition.document_node_definitions')}
            header={[
              translate('definition.node_id'),
              translate('general.identifier')
            ]}
            items={selector.documentnodes.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newNodeID = selector.documentnodes.data[selectedRows][selector.documentnodes.header.indexOf('DNDNAME')]
                this.setState({ nodeID: newNodeID })
              }
              this.setState({ showNodeDialog: false })
            }}
          />
        )}

        {this.state.showDocumentDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showDocumentDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.report'),
            ]}
            items={selector.documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newForm = selector.documents
                  .data[selectedRows][selector.documents.header.indexOf('FORM')]
                const newExt = selector.documents
                  .data[selectedRows][selector.documents.header.indexOf('EXT')]
                this.setState({
                  form: newForm,
                  extension: newExt
                })
              }
              this.setState({ showDocumentDialog: false })
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Renders the main card
   */
  renderMain = () => {
    const { id } = this.props
    const { nodeID, form, extension } = this.state

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderSelectorDialogs()}
        <Card title={translate('general.general')}>
          <Row>
            <Column colMD={12}>
              <Input
                id={`${id}_nodedocument_nodeid`}
                title={translate('definition.node_id')}
                onInputChanged={val => this.handleInputWithoutSpaces('nodeID', val)}
                maxLength={25}
                value={nodeID}
                addon={{
                  iconName: 'list',
                  onClick: () => this.handleNodeSelector()
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column colMD={6}>
              <Input
                id={`${id}_nodedocument_form`}
                title={translate('general.form')}
                onInputChanged={val => this.handleInput('form', val)}
                maxLength={8}
                value={form}
                addon={{
                  iconName: 'list',
                  onClick: () => this.handleDocumentSelector()
                }}
              />
            </Column>
            <Column colMD={6}>
              <Input
                id={`${id}_nodedocument_extension`}
                title={translate('general.extension')}
                onInputChanged={val => this.handleInput('extension', val)}
                maxLength={16}
                value={extension}
                addon={{
                  iconName: 'list',
                  onClick: () => this.handleDocumentSelector()
                }}
              />
            </Column>
          </Row>
        </Card>

      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props
    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={'drawer_content_assignment_body_search'}
          text={translate('general.search')}
          onClick={this.handleSubmit}
          submit
          primary
        />
        <Button
          id={'drawer_content_assignment_body_resetBtn'}
          icon='undo'
          iconType='material'
          onClick={() => this.setState(this.defaultState)}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    selector: state.selector,
    preferences: state.auth.serverdata.preferences,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefs: prefs => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getDocumentNodesDefinition: (fields, nodeName, callback) => {
      ModalSelectorActions.getDocumentNodesDefinition(fields, nodeName, callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        undefined, // report
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    getNodeDocumentAssignments: (nodeID, form, extension, callback) => {
      getNodeDocumentAssignments(nodeID, form, extension, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssignmentNodeDocument))