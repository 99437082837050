import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { LetterCase } from 'BetaUX2Web-Components/src/types'
// Components
import {
  Button, Card, Column,
  Input,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as RemoteUserActions from 'redux/actions/RemoteUserActions'
import * as Preferences from 'redux/general/Preferences'
import * as IPUtils from 'utils/IPUtils'

class CreateRemoteUserDialog extends Component {
  state = {
    unixuser: {
      value: this.props.remoteUserToCopy.UNIXUSER,
      errorkey: '',
    },
    buxuser: {
      value: this.props.remoteUserToCopy.BETAUSER,
      errorkey: '',
    },
    ipmask: {
      value: this.props.remoteUserToCopy.IPMASK,
      errorkey: '',
    },
    ip6mask: {
      value: this.props.remoteUserToCopy.IP6MASK,
      errorkey: ''
    }
  }

  unixuserInput = React.createRef()
  buxuserInput = React.createRef()
  ipmaskInput = React.createRef()
  ip6maskInput = React.createRef()

  /**
   * @description Sets the initial focus.
   */
  componentDidMount() {
    // focus unixuser input initially
    this.unixuserInput.current.focus()
  }

  /**
   * @description Changes the value and error key for unixuser.
   * @param {String} value The new value.
   * @param {String} error The new error key.
   */
  handleOnUnixuserChanged(value, error) {
    this.setState({
      unixuser: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Changes the value and error key for buxuser.
   * @param {String} value The new value.
   * @param {String} error The new error key.
   */
  handleOnBuxuserChanged(value, error) {
    this.setState({
      buxuser: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Handles the ip6mask changes.
   * @param {String} value The new value.
   * @param {String} error The new error key.
   */
  handleIp6mask = (value, error) => {
    this.setState({
      ip6mask: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Changes the value and error key for ip mask.
   * @param {String} value The new value.
   * @param {String} error The new error key.
   */
  handleOnIpmaskChanged(value, error) {
    this.setState({
      ipmask: {
        value: value,
        errorkey: error
      }
    })
  }

  /**
   * @description Validates the unixuser input. Also sets error key and focus.
   * @param {Boolean} allowFocus Flag if it's allowed to set the focus to the unixuser field on error.
   */
  validateUnixuser(allowFocus) {
    if (this.state.unixuser.value !== '') {
      return true
    }
    else {
      this.setState({ unixuser: { ...this.state.unixuser, errorkey: 'usermanagement.please_enter_a_unixuser' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.unixuserInput.current.focus()
      }

      return false
    }
  }

  /**
   * @description Validates the buxuser input. Also sets error key and focus.
   * @param {Boolean} allowFocus Flag if it's allowed to set the focus to the buxuser field on error.
   */
  validateBuxuser(allowFocus) {
    if (this.state.buxuser.value !== '') {
      if (!this.checkCopiedUnixUser()) {
        // set the docus if it's allowed
        if (allowFocus) {
          this.unixuserInput.current.focus()
        }

        return false
      }

      return true
    } else {
      this.setState({ buxuser: { ...this.state.buxuser, errorkey: 'usermanagement.please_enter_a_buxuser' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.buxuserInput.current.focus()
      }

      return false
    }
  }

  /**
   * @description Validates the ip mask input. Also sets error key and focus.
   * @param {Boolean} allowFocus Flag if it's allowed to set the focus to the ip mask field on error.
   */
  validateIPMask(allowFocus) {
    if (this.state.ipmask.value !== '') {
      if (!IPUtils.isIPv4Mask(this.state.ipmask.value)) {
        this.setState({ ipmask: { ...this.state.ipmask, errorkey: 'usermanagement.ipmask_not_valid' } })
        // set the focus if it's allowed
        if (allowFocus) {
          this.ipmaskInput.current.focus()
        }

        return false
      }
      return true
    } else {
      this.setState({ ipmask: { ...this.state.ipmask, errorkey: 'usermanagement.please_enter_a_ipmask' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.ipmaskInput.current.focus()
      }

      return false
    }
  }

  /**
   * @description Validates the ipv6mask. Adds an error under textfield if validation failed.
   * @param {Boolean} allowFocus Flag to focus the textfield if validation failed.
   */
  validateIP6Mask = (allowFocus) => {
    if (this.state.ip6mask.value === '') {
      return true
    } else if (!IPUtils.isIPv6Mask(this.state.ip6mask.value)) {
      this.setState({ ip6mask: { ...this.state.ip6mask, errorkey: 'usermanagement.ipmask_not_valid' } })

      // set the focus if it's allowed
      if (allowFocus) {
        this.ip6maskInput.current.focus()
      }

      return false
    } else {
      return true
    }
  }

  /**
   * @description Closes this dialog.
   */
  handleOnClose = () => {
    this.props.onClose()
  }

  /**
   * @description Validates the input and creates a remote user with the current input values.
   */
  handleOnSave() {
    // check data before sending request
    if (this.validateUnixuser(true)) {
      if (this.validateBuxuser(true)) {
        if (this.validateIPMask(true)) {
          if (this.validateIP6Mask(true)) {

            // call create request
            const cuser = this.props.userid
            const unixuser = this.state.unixuser.value
            const buxuser = this.state.buxuser.value
            const ipmask = this.state.ipmask.value
            const ip6mask = this.state.ip6mask.value

            // declare success callback
            const callback = () => { this.handleOnClose() }

            this.props.createRemoteUser(cuser, unixuser, buxuser, ipmask, ip6mask, callback)
          }
        } else {
          this.validateIP6Mask(false)
        }
      } else {
        this.validateIPMask(false)
        this.validateIP6Mask(false)
      }
    } else {
      // we also want to check and show the error message under the other fields in case of unixuser is invalid
      // don't allow focus here because unixuser field has an error -> false parameter
      // we don't have to interlace the validate function because we don't have to set the focus
      this.validateBuxuser(false)
      this.validateIPMask(false)
      this.validateIP6Mask(false)
    }
  }

  /**
   * @description Checks if the current unixuser is equal to the copied unixuser.
   * Adds an error key to the unixuser with it's equal.
   */
  checkCopiedUnixUser() {
    if (this.state.unixuser.value === this.props.remoteUserToCopy.UNIXUSER) {
      this.setState({ unixuser: { ...this.state.unixuser, errorkey: 'usermanagement.copy_remoteuser_same_unixuser_error' } })
      return false
    }

    return true
  }

  render = () => {
    const { id, onClose } = this.props
    const { buxuser, unixuser, ipmask, ip6mask } = this.state
    return (
      <Modal onClose={onClose}
        id={`${id}_copyRemoteUserModal`}
        className={'bux_copyRemoteUserModal'}
        size={'s'}>
        <Header
          id={`${id}_copyRemoteUserModal`}
          title={translate('usermanagement.copy_remote_user')}
          onClose={this.handleOnClose}
        />
        <Main id={`${id}_copyRemoteUserModal`}>
          <Card>
            <Row>
              <Column
                colMD={12}
                offsetMD={0}>
                <Input
                  id={`${id}_body_card_row_1_col_unixuser`}
                  ref={this.unixuserInput}
                  onInputChanged={(val, err) => { this.handleOnUnixuserChanged(val, err) }}
                  value={unixuser.value}
                  title={translate('remoteuser.unixuser')}
                  error={unixuser.errorkey && translate(unixuser.errorkey)}
                  maxLength={8}
                  onBlur={() => this.checkCopiedUnixUser()}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}
                offsetMD={0}>
                <Input
                  id={`${id}_body_card_row_2_col_buxuser`}
                  ref={this.buxuserInput}
                  onInputChanged={(val, err) => { this.handleOnBuxuserChanged(val, err) }}
                  value={buxuser.value}
                  title={translate('remoteuser.betaux_user_id')}
                  error={buxuser.errorkey && translate(buxuser.errorkey)}
                  onBlur={() => this.validateBuxuser()}
                  maxLength={8}
                  required={`${translate('general.required_field')}`}
                  lettercase={LetterCase.uppercase}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}
                offsetMD={0}>
                <Input
                  id={`${id}_body_card_row_3_col_ipmask`}
                  ref={this.ipmaskInput}
                  onInputChanged={(val, err) => { this.handleOnIpmaskChanged(val, err) }}
                  value={ipmask.value}
                  title={translate('remoteuser.ip4mask')}
                  error={ipmask.errorkey && translate(ipmask.errorkey)}
                  maxLength={45}
                  onBlur={() => this.validateIPMask()}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column
                colMD={12}>
                <Input
                  id={`${id}_ip6mask`}
                  ref={this.ip6maskInput}
                  onInputChanged={(val, err) => this.handleIp6mask(val, err)}
                  value={ip6mask.value}
                  error={this.state.ip6mask.errorkey && translate(this.state.ip6mask.errorkey)}
                  title={translate('remoteuser.ip6mask')}
                  maxLength={45}
                  onBlur={() => this.validateIP6Mask()}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_copyRemoteUserModal`}>
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel')}
            onClick={() => onClose()}
          />
          <Button
            id={`${id}_footer_save`}
            text={translate('general.save')}
            onClick={() => this.handleOnSave()}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

CreateRemoteUserDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    remoteUserToCopy: state.remoteuser.remoteUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createRemoteUser: (cuser, unixuser, buxuser, ipmask, ip6mask, callback) => {
      RemoteUserActions.createRemoteUser(cuser, unixuser, buxuser, ipmask, ip6mask, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRemoteUserDialog)