import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'
// actions
export const CREATE_SECURITY_PROFILE_START = 'CREATE_SECURITY_PROFILE_START'
export const CREATE_SECURITY_PROFILE_SUCCESS = 'CREATE_SECURITY_PROFILE_SUCCESS'
export const CREATE_SECURITY_PROFILE_FAILED = 'CREATE_SECURITY_PROFILE_FAILED'

export const GET_SECURITY_PROFILE_START = 'GET_SECURITY_PROFILE_START'
export const GET_SECURITY_PROFILE_SUCCESS = 'GET_SECURITY_PROFILE_SUCCESS'
export const GET_SECURITY_PROFILE_FAILED = 'GET_SECURITY_PROFILE_FAILED'

export const DELETE_SECURITY_PROFILE_START = 'DELETE_SECURITY_PROFILE_START'
export const DELETE_SECURITY_PROFILE_SUCCESS = 'DELETE_SECURITY_PROFILE_SUCCESS'
export const DELETE_SECURITY_PROFILE_FAILED = 'DELETE_SECURITY_PROFILE_FAILED'

export const UPDATE_SECURITY_PROFILE_START = 'UPDATE_SECURITY_PROFILE_START'
export const UPDATE_SECURITY_PROFILE_SUCCESS = 'UPDATE_SECURITY_PROFILE_SUCCESS'
export const UPDATE_SECURITY_PROFILE_FAILED = 'UPDATE_SECURITY_PROFILE_FAILED'

export const REFRESH_SECURITY_PROFILES_START = 'REFRESH_SECURITY_PROFILES_START'
export const REFRESH_SECURITY_PROFILES_SUCCESS = 'REFRESH_SECURITY_PROFILES_SUCCESS'
export const REFRESH_SECURITY_PROFILES_FAILED = 'REFRESH_SECURITY_PROFILES_FAILED'

export const GET_SECURITY_PROFILES_START = 'GET_SECURITY_PROFILES_START'
export const GET_SECURITY_PROFILES_SUCCESS = 'GET_SECURITY_PROFILES_SUCCESS'
export const GET_SECURITY_PROFILES_FAILED = 'GET_SECURITY_PROFILES_FAILED'

export const GET_SECURITY_PROFILES_ENTITY_PARTS_START = 'GET_SECURITY_PROFILES_ENTITY_PARTS_START'
export const GET_SECURITY_PROFILES_ENTITY_PARTS_SUCCESS = 'GET_SECURITY_PROFILES_ENTITY_PARTS_SUCCESS'
export const GET_SECURITY_PROFILES_ENTITY_PARTS_FAILED = 'GET_SECURITY_PROFILES_ENTITY_PARTS_FAILED'

export const NO_SECURITY_PROFILES_FOUND = 'NO_SECURITY_PROFILES_FOUND'

/**
 * @description Calls the rest api and creates a security profile.
 * @param {String} cuser The userid which creates the security profile.
 * @param {String} profilemask The profilemask for the creation.
 * @param {String} betauser The betauser for the creation. Can be empty if the profile is general.
 * @param {String} profiledesc The profile description for the creation. Can be empty.
 * @param {String} access The access type for the creation.
 * @param {Function} callback The callback which will be called when the create security profile request was successful. Can be null.
 */
export function createSecurityProfile(cuser, profilemask, betauser, profiledesc, access, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: CREATE_SECURITY_PROFILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/securityprofile`, {
        method: 'post',
        body: {
          PROFILE: profilemask,
          BETAUSER: betauser,
          PRODESC: profiledesc,
          ACCESS: access,
          CUSER: cuser
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: CREATE_SECURITY_PROFILE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: CREATE_SECURITY_PROFILE_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.create_security_profile_success', lang, profilemask), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getSecurityProfiles again to get the current result
          const searchProfilemask = prefs[Preferences.SECURITY_PROFILEMASK]
          const searchBetauser = prefs[Preferences.SECURITY_BETAUSER]
          getSecurityProfiles(searchProfilemask, searchBetauser, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.create_security_profile_error', lang, [profilemask, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: CREATE_SECURITY_PROFILE_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets a security profile.
 * @param {String} profilemask The profilemask of the security profile to get.
 * @param {String} betauser The betauser of the security profile to get.
 * @param {Function} callback The callback which will be called if the get security profile request was successful. Can be null.
 */
export function getSecurityProfile(profilemask, betauser, callback) {
  /* Escape URL-parameters */
  profilemask = encodeURIComponent(profilemask)
  betauser = encodeURIComponent(betauser)

  return dispatch => {
    /* Show loading-animation and block user-input */
    LoadingSpinnerActions.show()(dispatch)

    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_SECURITY_PROFILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest((`${Config.REST_API_URL}/api/usermanagement/securityprofile?PROFILE=${profilemask}&BETAUSER=${betauser}`), { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_SECURITY_PROFILE_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_SECURITY_PROFILE_SUCCESS, payload: jsondata.data })

          // call callback on success
          if (callback) {
            callback()
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_security_profile_error', lang, [profilemask, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_SECURITY_PROFILE_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and deletes a security profile.
 * @param {String} profilemask The profile mask of the security profile to delete.
 * @param {String} betauser The betauser of the security profile to delete.
 * @param {Function} callback The callback which will be called when the delete security profile request was successful. Can be null.
 */
export function deleteSecurityProfile(profilemask, betauser, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: DELETE_SECURITY_PROFILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/securityprofile${createQueryParamsForFetch({ PROFILE: profilemask, BETAUSER: betauser })}`, { method: 'delete' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: DELETE_SECURITY_PROFILE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: DELETE_SECURITY_PROFILE_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.delete_security_profile_success', lang, profilemask), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getSecurityProfiles again to get the current result
          const searchProfilemask = prefs[Preferences.SECURITY_PROFILEMASK]
          const searchBetauser = prefs[Preferences.SECURITY_BETAUSER]
          getSecurityProfiles(searchProfilemask, searchBetauser, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.delete_security_profile_error', lang, [profilemask, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: DELETE_SECURITY_PROFILE_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and updates a security profile.
 * @param {String} cuser The userid which updates the security profile.
 * @param {String} profilemask The profilemask of the security profile to update.
 * @param {String} betauser The betauser of the security profile to update.
 * @param {String} profiledesc The new profile description.
 * @param {String} access The new access type.
 * @param {Function} callback The callback which will be called when the update security profile request was successful. Can be null.
 */
export function updateSecurityProfile(cuser, profilemask, betauser, profiledesc, access, callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: UPDATE_SECURITY_PROFILE_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/securityprofile`, {
        method: 'put',
        body: {
          PROFILE: profilemask,
          BETAUSER: betauser,
          PRODESC: profiledesc,
          ACCESS: access,
          CUSER: cuser
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: UPDATE_SECURITY_PROFILE_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: UPDATE_SECURITY_PROFILE_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.update_security_profile_success', lang, profilemask), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }

          // call getSecurityProfiles again to get the current result
          const searchProfilemask = prefs[Preferences.SECURITY_PROFILEMASK]
          const searchBetauser = prefs[Preferences.SECURITY_BETAUSER]
          getSecurityProfiles(searchProfilemask, searchBetauser, true)(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.update_security_profile_error', lang, [profilemask, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: UPDATE_SECURITY_PROFILE_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and refreshs the security profiles.
 * @param {Function} callback The callback which will be called when the refresh security profiles request was successful. Can be null.
 */
export function refreshSecurityProfiles(callback) {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]
    dispatch({ type: REFRESH_SECURITY_PROFILES_START })

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/refreshsecurityprofiles`, { method: 'post', body: {} })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: REFRESH_SECURITY_PROFILES_FAILED, payload: { error } })
        }
        else {
          dispatch({ type: REFRESH_SECURITY_PROFILES_SUCCESS })

          SnackbarActions.show(Lang.translate('usermanagement.refresh_security_profiles_success', lang), SnackbarActions.TYPE_SUCCESS)(dispatch)

          // call callback on success
          if (callback) {
            callback()
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.refresh_security_profiles_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: REFRESH_SECURITY_PROFILES_FAILED, payload: { error } })
      })
  }
}

/**
 * @description Calls the rest api and gets security profiles.
 * @param {String} profilemask The profilemask to search.
 * @param {String} betauser The betauser to search.
 */
export function getSecurityProfiles(profilemask, betauser, keepPagination = false) {
  /* Escape URL-parameters */
  profilemask = encodeURIComponent(profilemask)
  betauser = encodeURIComponent(betauser)

  return dispatch => {
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_SECURITY_PROFILES_START })
    LoadingSpinnerActions.show()(dispatch)

    const startTime = Date.now()

    /* Build request-parameter for URL */
    const queryParams = []
    if (profilemask) { queryParams.push(`PROFILE=${profilemask}`) }
    if (betauser) { queryParams.push(`BETAUSER=${betauser}`) }

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/securityprofiles?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          // handle no data found here to dispatch NO_SECURITY_PROFILES_FOUND action
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_SECURITY_PROFILES_FOUND })
          }
          else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_SECURITY_PROFILES_FAILED })
          }
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_SECURITY_PROFILES_SUCCESS, payload: jsondata.data, keepPagination })

          // wait a bit to show the user the loading animation
          const usedTime = Date.now() - startTime
          if (usedTime < 300) {
            setTimeout(() => {
              LoadingSpinnerActions.hide()(dispatch)
            }, 300 - usedTime)
          }
          else {
            LoadingSpinnerActions.hide()(dispatch)
          }
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('usermanagement.get_security_profiles_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_SECURITY_PROFILES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and gets security profile placeholders.
 */
export function getEntityParts() {
  return dispatch => {
    // get the language from redux
    const lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    dispatch({ type: GET_SECURITY_PROFILES_ENTITY_PARTS_START })
    LoadingSpinnerActions.show()(dispatch)

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/usermanagement/securityentities`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_SECURITY_PROFILES_ENTITY_PARTS_FAILED, payload: { error } })
        }
        else {

          dispatch({ type: GET_SECURITY_PROFILES_ENTITY_PARTS_SUCCESS, payload: jsondata.data })
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('security_profiles.get_placeholders_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_SECURITY_PROFILES_ENTITY_PARTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}