import React from 'react';
import './DocumentBrowser.scss';

import { DocumentBrowserPageAreaText } from './browser_body/DocumentBrowserPageAreaText';
import { DocumentBrowserPageAreaHexBlock } from './browser_body/DocumentBrowserPageAreaHexBlock';
import { DocumentBrowserPageAreaHexLine } from './browser_body/DocumentBrowserPageAreaHexLine';
import { DocumentBrowserPageAreaHexLineWithResources } from './browser_body/DocumentBrowserPageAreaHexLineWithResources';
import { DocumentBrowserNavigation } from './browser_header/DocumentBrowserNavigation';

import * as DATA from '../constants/data';

import { useTabContentContext } from '../contexts/TabContentContext';

import { DocumentBrowserProvider } from '../provider/DocumentBrowserProvider';
import { DocumentBrowserHexSelectionProvider } from '../provider/DocumentBrowserHexSelectionProvider';
import { DocumentBrowserPageAreaHexLineWithHeaderWithResources } from './browser_body/DocumentBrowserPageAreaHexLineWithHeaderWithResources';

/**
 * @description This component renders the page content area (= 'Document Browser').\
 * It utilizes the provider pattern to take care of state across multiple tabs, e.g. to store the opened page number for each tab individually without interference between tabs.
 * @prop [id] - The ID of the open tab (e.g. Document ID, Job ID or UKey)
 */
export const DocumentBrowser = () => {
  return (
    <div className='doc_browser_area'>
      <DocumentBrowserProvider>
        <DocumentBrowserHexSelectionProvider>
          <DocumentBrowserNavigation />
          <DocumentBrowserBody />
        </DocumentBrowserHexSelectionProvider>
      </DocumentBrowserProvider>
    </div>
  )
};

const DocumentBrowserBody = () => {
  const { viewMode } = useTabContentContext();

  switch (viewMode) {
    case DATA.TAB_CONFIG_VIEW_MODE_TEXT: return <DocumentBrowserPageAreaText />;
    case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK: return <DocumentBrowserPageAreaHexBlock />;
    case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER: return <DocumentBrowserPageAreaHexBlock />;
    case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_HEADER_RESOURCES: return <DocumentBrowserPageAreaHexBlock />;
    case DATA.TAB_CONFIG_VIEW_MODE_HEX_BLOCK_RESOURCES: return <DocumentBrowserPageAreaHexBlock />;
    case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE: return <DocumentBrowserPageAreaHexLine />;
    case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER: return <DocumentBrowserPageAreaHexLine />;
    case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_HEADER_RESOURCES: return <DocumentBrowserPageAreaHexLineWithHeaderWithResources />;
    case DATA.TAB_CONFIG_VIEW_MODE_HEX_LINE_RESOURCES: return <DocumentBrowserPageAreaHexLineWithResources />;
    default: return <DocumentBrowserPageAreaText />;
  }
};