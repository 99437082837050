import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import {
  Label
} from 'BetaUX2Web-Components/src/'


// Dependence
import { validDateStrings } from '../../utils/DateUtils'
import Datetime from './datepicker_ORIGIN/DateTime.jsx'

// Dependence Style
import './datepicker_ORIGIN/css/react-datetime.css'

// Style
import './Datepicker.scss'

/**
 * Datepicker component created according to
 * _Input_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */

export default class Datepicker extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Used to display the date in the right language format*/
    language: PropTypes.oneOf(['en', 'de', 'fr']),
    /** Used to display the date entries in the correct format. */
    dateFormat: PropTypes.string,
    /** Label to be displayed above the Datepicker input.*/
    title: PropTypes.string,
    /** Initial value */
    value: PropTypes.string,
    /** Text for empty Datepicker input */
    placeholder: PropTypes.string,
    /** Disables possibility of interaction ith Datepicker */
    disabled: PropTypes.bool,
    /**
     * Function to be called on a change date.
     * @param {string} date - current selected date
     */
    onChange: PropTypes.func,
    /** Function to be called on blur. */
    onBlur: PropTypes.func,
    /** Function to be called on focus. */
    onFocus: PropTypes.func,
    /** Error message */
    error: PropTypes.string,
    /** Id from the scrollable container which includes the picker. */
    parentContainer: PropTypes.string,
    /** Is triggered, when the date is invalid and not empty */
    onInvalidDate: PropTypes.func,
    /** Allows to pick date only in the future. */
    onlyFutureDates: PropTypes.bool,
    /** Allows to pick all days. */
    bothDates: PropTypes.bool,
    /** Allows to pick all days in the past and one day in the future. */
    oneAdditionalDate: PropTypes.bool,
    /** Reference to access DOM nodes */
    focusRef: PropTypes.any,
    /** React key */
    key: PropTypes.string,
    /**
    * Sets dot and required word, next to the _title_, which indicates that fields is required.
    * If true - english version is used "required"
    * If string, user provide the word which appears after dot.
    * If _title_ is not set, indicator will not be shown.
    */
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  }

  componentWillUnmount = () => {
    this.onBlur()
  }

  /**
   * @description Returns, if the given date matches the pattern.
   * @param {String} date The date which will be checked.
   * @param {String} pattern The pattern which is used to check the date.
   * @returns {Boolean}
   */
  isDate = (date, pattern) => moment(date, pattern, true).isValid()

  /**
   * @description Checks, if the input value is a date. Execute onBlur function when given.
   */
  onBlur = () => {
    const { onInvalidDate, value, dateFormat, onBlur } = this.props
    if (onInvalidDate) {
      if (value !== '' && !this.isDate(value, dateFormat) && !validDateStrings.includes(value.toUpperCase())) {
        onInvalidDate(value)
      }
    }
    onBlur && onBlur()
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const {
      id,
      language,
      dateFormat,
      title,
      value,
      placeholder,
      disabled,
      onChange,
      onFocus,
      error,
      parentContainer,
      focusRef,
      key,
      onlyFutureDates,
      bothDates,
      oneAdditionalDate,
      required
    } = this.props

    const errorClass = error && 'we_decoratedErrorField'
    return (
      <div
        id={`${id}_datepicker`}
        className={'bux_datepicker'}
        key={key || undefined}>
        <Label
          id={`${id}_datepicker_label`}
          title={title}
          required={required}
          disabled={disabled}
          isError={!!error}
        />
        <div
          id={`${id}_datepicker_input`}
          className={`${disabled ? ' disabled' : ''}`}>
          <Datetime
            id={id}
            inputClass={errorClass}
            inputProps={{
              onFocus: onFocus && (() => onFocus()),
              placeholder: placeholder,
              disabled: disabled,
              invalid: error ? 'true' : 'false',
              onBlur: this.onBlur
            }}
            focusRef={focusRef}
            timeFormat={false}
            locale={language}
            value={value}
            dateFormat={dateFormat}
            onChange={newValue => onChange(newValue)}
            onChoose={newValue => onChange(newValue.format(dateFormat))}
            closeOnSelect={true}
            isValidDate={current => {
              if (onlyFutureDates) {
                return current.isAfter(moment())
              }
              else if (bothDates) {
                return true
              }
              else if (oneAdditionalDate) {
                return current.isBefore(moment().add(1, 'days'))
              }
              return current.isBefore(moment())
            }}
            onBlur={this.onBlur}
            parentContainer={parentContainer}
          />
        </div>
        <div
          id={`${id}_datepicker_error`}
          className={'bux_datepicker_errorMessage'}>
          {error || '' /* || is required to avoid 'undefined' */}
        </div>
      </div>
    )
  }
}