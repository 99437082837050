import React, { Component } from 'react';
import './Flyout.scss';
import FlyoutItem from './flyout_item/FlyoutItem';
import Link from '../link/Link';
import { FlyoutAlignment, FlyoutData } from './Flyout.types';
import { prepareClassNames } from '../../utils/ComponentUtils';


interface FlyoutProps {
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** Name of the icon to be displayed in Button.*/
  iconName?: string;
  /** Text label to be displayed in Button. Label will appears with chevron on the end of the text to indicates if menu is open*/
  text?: string;
  /** List of items */
  items: FlyoutData[];
  /** Current selected item */
  activeIndex?: number;
  /**
   * Function to be called on a change selection.
   * @param {number} index - index of current selected item
   * @param {FlyoutData} item - current selected item
   */
  onChange?: (index: number, item: FlyoutData) => void;
  /** Text to be displayed in tooltip when you hover component.*/
  tooltip: string;
  /** Alignment */
  alignment: FlyoutAlignment;
  /** Style class from CSS for styling for container.*/
  className?: string;
  /** Style class from CSS for styling for icon.*/
  iconClassName?: string;
  /** Style class from CSS for styling for text.*/
  textClassName?: string;
  /** Tab index */
  tabIndex?: number;
  /** Hides chevron */
  noChevron?: boolean;
  /** Don't use selected class */
  isSelectable?: boolean;
}

interface FlyoutState {
  showMenu: boolean;
  activeIndex: number;
  keyboardFocusedIndex: number;
}

export default class Flyout extends Component<FlyoutProps, FlyoutState> {

  state = {
    showMenu: false,
    activeIndex: this.props.activeIndex !== undefined ? this.props.activeIndex : -1,
    keyboardFocusedIndex: -1,
  };

  buttonRef: React.RefObject<HTMLButtonElement> = React.createRef();
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  wrapperULRef: React.RefObject<HTMLUListElement> = React.createRef();
  itemsRefs = this.buildItemsRefs();


  /**
   * @description Hides the menu if the scroll of the event is outside of the menu.
   * @param {Object} event The mouse event.
   */
  handleScrollOutsideDropdown = (event: Event): void => {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target as Node)) {
      this.setState({
        showMenu: false,
      });
    }
  };

  /**
   * @description Adds an event to detect the click outside the component.
   */
  componentDidMount = (): void => {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('focus', this.handleFocusOutside, true);
    document.addEventListener('scroll', this.handleScrollOutsideDropdown, true);
  };

  /**
   * @description Removes the event which detect the click outside of the component.
   */
  componentWillUnmount = (): void => {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('focus', this.handleFocusOutside, true);
    document.removeEventListener('scroll', this.handleScrollOutsideDropdown, true);
  };

  /**
   * @description
   */
  UNSAFE_componentWillUpdate = (): void => {
    this.itemsRefs = this.buildItemsRefs();
  };


  /**
   * @description Updates the calculation, where the list should be rendered.
   */
  componentDidUpdate = (prevProps: FlyoutProps): void => {
    if (this.state.showMenu && this.state.keyboardFocusedIndex !== -1) {
      this.itemsRefs[this.state.keyboardFocusedIndex]?.current?.focus();
    }
    if (this.props.activeIndex !== prevProps.activeIndex) {
      this.setState({ activeIndex: this.props.activeIndex !== undefined ? this.props.activeIndex : -1 });
    }
  };


  /**
   * @description Event which detect the click outside of the component.
   */
  handleClickOutside = (event: MouseEvent): void => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target as Node)) {
      this.setState({
        showMenu: false,
        keyboardFocusedIndex: -1
      });
    }
  };

  /**
   * @description Event which detect the focus outside of the component.
   */
  handleFocusOutside = (event: FocusEvent): void => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target as Node)) {
      if (this.state.showMenu) {
        this.setState({
          showMenu: false,
          keyboardFocusedIndex: -1
        });
      }
    }
  };


  /**
   * @description Builds the ref array
   * @returns {Array}
   */
  buildItemsRefs(): React.RefObject<HTMLLIElement>[] {
    const { items } = this.props;
    const refs: React.RefObject<HTMLLIElement>[] = [];
    items.forEach(() => {
      refs.push(React.createRef());
    });

    return refs;
  }

  /**
   * @description Handles key down for the component.
   * "Enter" key browse the files, open and close list popup if there are more than one files, and also allows to remove selected item from the list.
   * "ArrowDown" and "ArrowUp" allow to select item on the list.
   * "Escape" close opened list popup.
   */
  handleKeyDownOnUL = (e: React.KeyboardEvent<HTMLUListElement>): void => {
    if (e.key === 'Enter') {
      if (this.state.showMenu && this.state.keyboardFocusedIndex !== -1) {
        this.handleOnClickItem(this.state.keyboardFocusedIndex);
      }
    } else if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && this.state.showMenu) {
      const currentIndex = this.state.keyboardFocusedIndex;
      this.changeKeyboardFocus(currentIndex + (e.key === 'ArrowDown' ? 1 : -1));
      // prevent scroll page on arrows keys
      e.preventDefault();
    } else if (e.key === 'Escape' && this.state.showMenu) {
      this.setState({ showMenu: false, keyboardFocusedIndex: -1 });
      this.buttonRef?.current?.focus();
    }
  };

  /**
   * @description Changes state of keyboardFocusedIndex,
   * makes sure the index has right value and does not exceed the files length
   */
  changeKeyboardFocus = (index: number): void => {
    let newIndex = index;
    if (index >= this.props.items.length) {
      newIndex = this.props.items.length - 1;
    }
    if (index < 0) {
      newIndex = 0;
    }
    if (this.state.keyboardFocusedIndex !== newIndex) {
      this.setState({ keyboardFocusedIndex: newIndex });
    }
  };

  /**
   * @description Toggles the visibility of the menu.
   */
  handleShowMenu = (): void => {
    if (this.props.items.length !== 0) {
      this.setState(currState => ({
        showMenu: !currState.showMenu
      }));
    }
  };

  /**
   * @description Handles the click on a item inside the menu.
   */
  handleOnClickItem = (activeIndex: number): void => {
    this.setState({ activeIndex });
    this.handleShowMenu();
    this.props.onChange?.(activeIndex, this.props.items[activeIndex]);
  };

  handleKeyDownOnButton = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (e.key === 'Enter') {
      if (this.state.showMenu && this.state.keyboardFocusedIndex !== -1) {
        this.handleOnClickItem(this.state.keyboardFocusedIndex);
      }
      this.buttonRef?.current?.focus();
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      let currentIndex = this.state.keyboardFocusedIndex;
      if (currentIndex === -1) {
        currentIndex = this.state.activeIndex;
      }
      this.setState({ keyboardFocusedIndex: currentIndex });
      e.preventDefault();
    } else if (e.key === 'Escape' && this.state.showMenu) {
      this.buttonRef?.current?.focus();
      this.setState({ showMenu: false, keyboardFocusedIndex: -1 });
    }
  };

  getSizeAndPosition = (): { top?: number; left?: number; right?: number } => {
    const el = document.querySelector(`#${this.props.id}_button`);
    const elTop = el?.getBoundingClientRect().top || 0;
    const elLeft = el?.getBoundingClientRect().left || 0;
    const elRight = el?.getBoundingClientRect().right || 0;
    const elBottom = el?.getBoundingClientRect().bottom || 0;
    const elHeight = el?.getBoundingClientRect().height || 0;

    let position = {};

    switch (this.props.alignment) {
      case FlyoutAlignment.left:
        position = {
          top: elTop + elHeight,
          left: elLeft,
        };
        break;
      case FlyoutAlignment.right:
        position = {
          top: elBottom,
          right: window.innerWidth - elRight
        };
        break;
      case FlyoutAlignment.center:
        position = {
          top: elTop + elHeight,
          right: 'auto',
        };
        break;
    }
    return position;
  };

  /**
   * @description Renders the component.
   */
  render = (): JSX.Element => {
    const { id, tooltip, iconName, text, iconClassName = '', textClassName = '', noChevron, isSelectable } = this.props;
    const { showMenu, activeIndex } = this.state;


    const items: JSX.Element[] = [];
    // create a dropdown item for each item string
    this.props.items.map((item, i) =>
      items.push(
        <FlyoutItem
          id={`${id}_div_menu_list_item_${i}`}
          key={i}
          focusRef={this.itemsRefs[i]}
          tabIndex={this.state.keyboardFocusedIndex === i ? 0 : -1}
          item={item}
          onClick={() => this.handleOnClickItem(i)}
          selected={i === activeIndex}
          isSelectable={isSelectable}
        />
      )
    );

    const classNames = prepareClassNames([
      'cl_flyout',
      this.props.className]);

    return (
      <div
        id={id}
        ref={this.wrapperRef}
        className={classNames}
      >
        {iconName &&
          <Link
            id={`${id}_button`}
            iconName={iconName}
            ref={this.buttonRef}
            tooltip={tooltip}
            onClick={() => this.handleShowMenu()}
            onKeyDown={this.handleKeyDownOnButton}
            tabIndex={this.props.tabIndex}
            iconClassName={iconClassName}/>
        }
        {text &&
          <Link
            id={`${id}_button`}
            text={text}
            iconName={noChevron ? undefined : (showMenu ? 'chevron_up' : 'chevron_down')}
            ref={this.buttonRef}
            tooltip={tooltip}
            onClick={() => this.handleShowMenu()}
            onKeyDown={this.handleKeyDownOnButton}
            linkClassName={'cl_link_no_gap'}
            textClassName={textClassName}
            tabIndex={this.props.tabIndex}
            reverse/>
        }

        {showMenu && (
          <ul
            id={`${id}_list`}
            ref={this.wrapperULRef}
            className={'cl_flyout_menu inner'}
            onKeyDown={this.handleKeyDownOnUL}
            style={{ ...this.getSizeAndPosition() }}>
            {items}
          </ul>
        )}
      </div>
    );
  };
}
