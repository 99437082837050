import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

// Components
import ErrorMessage from '../error_message/ErrorMessage';
import Icon from '../icon/Icon'
import Label from '../label/Label'

// Style
import './Filepicker.scss'

// Global function
const css = (el, props) => el.setAttribute('style', props)

/**
 * Filepicker component created according to
 * _Input_ and _Dropdown_ from style guide
 * [DCI UI-Styleguide 3-20210707](https://xd.adobe.com/view/a347c843-3381-4110-8cd4-631ce38598fa-f614/grid)
 */
export default class Filepicker extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /**
     * List of files
     * Type: File
     * ```js
     * {
     *   lastModified: number,
     *   lastModifiedDate: Date,
     *   name: string,
     *   size: number,
     *   type: string,
     *   webkitRelativePath: strings
     * }
     * ```
     */
    /** This ID will be used for integration tests to access the HTML element */
    dataTestId: PropTypes.string,
    files: PropTypes.array.isRequired,
    /**
     * Function to be called on an selected files
     * @param {array<File>} selectedFiles - new selected files
     */
    onChange: PropTypes.func.isRequired,
    /** Label to be displayed above the button.*/
    title: PropTypes.string,
    /** Error description to be displayed below the Filepicker.*/
    error: PropTypes.string,
    /** Disables components visually and functionally. */
    disabled: PropTypes.bool,
    /**
    * Sets dot and required word, next to the _title_, which indicates that fields is required.
    * If true - english version is used "required"
    * If string, user provide the word which appears after dot.
    * If _title_ is not set, indicator will not be shown.
    */
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /** The string which is displayed in the picker input field when no file is selected */
    noSelectedString: PropTypes.string,
    /** The string which is displayed in the picker input field when files are selected */
    selectedString: PropTypes.string,
    /** Allow to select multiple files. Default is true. */
    multiple: PropTypes.bool,
    /**
     * Names of files which causes an error or are incorrect.
     * This files will be highlighted in the Filepicker.
     */
    errorItems: PropTypes.arrayOf(PropTypes.string)
  }

  state = {
    isListOpen: false,
    keyboardFocusedIndex: -1,
  }

  setWrapperRefButton = React.createRef()
  setWrapperRefList = React.createRef()
  handleClickOutside = React.createRef()
  handleScrollOutside = React.createRef()
  setRefInput = React.createRef()
  setRefContainer = React.createRef()
  itemRefs = this.buildItemsRefs()


  /**
   * @description Adds a mousedown & scroll event listener.
   */
  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('scroll', this.handleScrollOutside, true)
  }

  /**
   * @description Removes the mousedown event listener.
   */
  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('scroll', this.handleScrollOutside, true)
  }

  /**
   * @description
   */
  componentWillUpdate = () => {
    this.itemRefs = this.buildItemsRefs()
  }

  /**
   * @description Updates the calculation, where the list should be rendered.
   */
  componentDidUpdate = () => {
    const { id, files } = this.props
    if (this.state.isListOpen && files.length > 1) {
      const el = document.querySelector(`#${id}_wrapper`)
      const elPosX = el.getBoundingClientRect().x
      const elPosY = el.getBoundingClientRect().y
      const elWidth = el.getBoundingClientRect().width
      const elHeight = el.getBoundingClientRect().height
      css(document.querySelector(`#${id}_menu_list`), `
        margin-top: ${elPosY + elHeight - 1}px;
        left: ${elPosX}px !important;
        min-width: ${elWidth}px;
        `
      )
    }
    if (this.state.isListOpen) {
      if (this.state.keyboardFocusedIndex !== -1) {
        this.itemRefs[this.state.keyboardFocusedIndex] &&
          this.itemRefs[this.state.keyboardFocusedIndex].current &&
          this.itemRefs[this.state.keyboardFocusedIndex].current.focus()
      }
    }

  }

  /**
   * @description Sets the wrapper reference to compare the click event component
   * and decide if it's the same component.
   * @param {Object} node The new reference.
   */
  setWrapperRefList = node => {
    this.wrapperRefList = node
  }

  /**
   * @description Sets the wrapper reference to compare the click event component
   * and decide if it's the same component.
   * @param {Object} node The new reference.
   */
  setWrapperRefButton = node => {
    this.wrapperRefButton = node
  }


  /**
   * @description Builds the ref array
   * @returns {Array}
   */
  buildItemsRefs() {
    const { files } = this.props
    const refs = []
    files.forEach(() => {
      refs.push(React.createRef())
    })

    return refs
  }

  /**
   * @description closes file-list if click is outside of file-list
   * @param {Object} event the click event
   */
  handleClickOutside = event => {
    if (this.wrapperRefButton && !this.wrapperRefButton.contains(event.target) && this.wrapperRefList && !this.wrapperRefList.contains(event.target)) {
      this.setState({ isListOpen: false })
    }
  }

  /**
   * @description Hides the menu if the scroll event is outside of the menu.
   * @param {Object} event The mouse event.
   */
  handleScrollOutside = event => {
    if (this.wrapperRefButton && !this.wrapperRefButton.contains(event.target) && this.wrapperRefList && !this.wrapperRefList.contains(event.target)) {
      this.setState({ isListOpen: false })
    }
  }

  /**
   * @description Sets the wrapper reference to compare the click event component
   * and decide if it's the same component.
   * @param {Object} node The new reference.
   */
  setRefInput = node => {
    this.inputRef = node
  }

  /**
   * @description Sets the wrapper reference to compare the click event component
   * and decide if it's the same component.
   * @param {Object} node The new reference.
   */
  setRefContainer = node => {
    this.containerRef = node
  }

  /**
   * @description inverts the value of isListOpen
   */
  toggleDropdown = () => {
    this.setState(prevState => ({ isListOpen: !prevState.isListOpen, keyboardFocusedIndex: -1 }))
  }

  /**
   * @description Removes the file of a specific index.
   * @param {Number} index The index of the item which should be removed
   */
  removeItem = index => {
    let newFiles = this.props.files
    newFiles.splice(index, 1)

    this.props.onChange(newFiles)
  }

  /**
   * @description Creates the tooltip list when hovering.
   * @returns {String}
   */
  createTooltipList = () => {
    let list = ''
    for (let [index, file] of this.props.files.entries()) {
      list += `${++index}. ${file.name}\n`
    }
    return list
  }

  /**
   * @description Handles key down for the component.
   * "Enter" key browse the files, open and close list popup if there are more than one files, and also allows to remove selected item from the list.
   * "ArrowDown" and "ArrowUp" allow to select item on the list.
   * "Escape" close opened list popup.
   */
  handleKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
        this.handleEnterKey()
        break
      case 'ArrowDown':
      case 'ArrowUp':
        this.handleArrowsKeys(e)
        break
      case 'Escape':
        this.handleEscapeKey()
        break
      default:
        break
    }
    return false
  }

  handleEnterKey = () => {
    if (this.props.files.length > 1) {
      if (this.state.isListOpen) {
        this.removeItem(this.keyboardFocusedIndex)
      } else {
        this.toggleDropdown()
      }
    } else {
      this.inputRef && this.inputRef.click()
    }
  }

  handleArrowsKeys = (e) => {
    if (this.state.isListOpen) {
      this.changeKeyboardFocus(this.state.keyboardFocusedIndex + (e.key === 'ArrowDown' ? 1 : -1))
      // prevent scroll page on arrows keys
      e.preventDefault()
    }
  }

  handleEscapeKey = () => {
    if (this.state.isListOpen) {
      this.containerRef && this.containerRef.focus()
      this.setState({ isListOpen: false })
    }
  }

  /**
   * @description Handles key down for the component addon.
   */
  handleAddonKeyDown = (e) => {
    return e.key === 'Enter' && this.inputRef.click() && false
  }

  /**
   * @description Changes state of keyboardFocusedIndex,
   * makes sure the index has right value and does not exceed the files length
   */
  changeKeyboardFocus = (index) => {
    let newIndex = index
    if (index >= this.props.files.length) {
      newIndex = this.props.files.length - 1
    }
    if (index < 0) {
      newIndex = 0
    }
    if (this.state.keyboardFocusedIndex !== newIndex) {
      this.setState({ keyboardFocusedIndex: newIndex })
    }
  }

  onClick = () => {
    this.toggleDropdown()
  }

  onEnter = event => {
    event.key === 'Enter' && this.toggleDropdown()
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, dataTestId, files, title, onChange, required, disabled, error, noSelectedString, selectedString, multiple = true, errorItems, displayedFileCount } = this.props
    const errorClass = error ? 'we_decoratedErrorField' : ''
    return (
      <div
        className={'bux_filepicker'}
        ref={this.setWrapperRefButton}
        title={this.createTooltipList()}>
        <Label
          id={`${id}_filepicker_label`}
          title={title}
          required={required}
          disabled={disabled}
          isError={!!error}
        />
        <div
          id={`${id}_container`}
          data-testid={`${dataTestId}_container`}
          className={'bux_filepicker_container'}
        >
          <label id={`${id}_wrapper`} className='bux_filepicker_wrapper'>
            <input
              id={`${id}_filepicker`}
              data-testid={dataTestId}
              ref={this.setRefInput}
              type='file'
              disabled={disabled}
              multiple={multiple}
              tabIndex={-1}
              onChange={event => onChange(Array.from(event.target.files))}
            />
            <span
              className={`bux_filepicker_display ${errorClass}`}
              invalid={error ? 'true' : 'false'}
              disabled={disabled}
              tabIndex={disabled ? -1 : 0}
              ref={this.setRefContainer}
              onKeyDown={this.handleKeyDown}
              onMouseUp={e => e.currentTarget.blur()}
            >
              {files.length === 0 &&
                <span>{noSelectedString}</span>}
              {files.length === 1 &&
                <span>{files[0].name}</span>}
              {files.length > 1 &&
                <button
                  data-testid={`${dataTestId}_button`}
                  type='button'
                  tabIndex={-1}
                  onClick={this.onClick}
                  onMouseOut={e => e.currentTarget.blur()}
                >
                  <span>
                    {`${displayedFileCount} ${selectedString}`}
                  </span>
                  {
                    this.state.isListOpen
                      ? <Icon name='chevron_up' />
                      : <Icon name='chevron_down' />
                  }
                </button>
              }
            </span>

            <span
              className='bux_filepicker_btnAddon'
              disabled={disabled}
              tabIndex={0}
              onMouseOut={e => e.currentTarget.blur()}
              onKeyDown={this.handleAddonKeyDown}
            >
              <Icon name='upload' />
            </span>
          </label>

          {
            files.length > 1 && this.state.isListOpen &&
            ReactDOM.createPortal(
              <List
                id={id}
                items={files}
                errorItems={errorItems}
                expand={this.state.isListOpen}
                removeItem={this.removeItem}
                wrapperRef={this.setWrapperRefList}
                itemsRefs={this.itemRefs}
                keyboardFocusedIndex={this.state.keyboardFocusedIndex}
                onKeyDown={this.handleKeyDown}
              />,
              document.querySelector('#dropdownMenu_Portal')
            )
          }
        </div>
        <ErrorMessage id={`${id}_error`} dataTestId={dataTestId ? `${dataTestId}_error` : undefined} text={error} />
      </div>
    )
  }
}

const List = ({ id, items, expand, removeItem, wrapperRef, errorItems, keyboardFocusedIndex, itemsRefs, onKeyDown }) => {
  return (
    <ul
      ref={wrapperRef}
      id={`${id}_menu_list`}
      className={`bux_filepicker_fileList dropdown-menu${expand ? ' open' : ' close'}`}>
      {
        items.map((item, index) => (
          <li
            tabIndex={keyboardFocusedIndex === index ? 0 : -1}
            ref={itemsRefs[index]}
            id={`${id}_item${index}`}
            key={`${id}_item${index}`}
            onClick={() => removeItem(index)}
            onKeyDown={onKeyDown}
            className={`menu_item  ${errorItems && errorItems.map(d => d.toLowerCase()).includes(item.name.substring(0, item.name.lastIndexOf('.'))?.toLowerCase()) ? 'item_error' : ''}`}>
            <Icon
              name='close'
              className={`${keyboardFocusedIndex === index ? 'keyboard_focus' : ''}`}
            />
            {item.name}
          </li>
        ))
      }
    </ul>
  )
}