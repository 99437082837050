import * as StandardSelectionActions from '../actions/StandardSelectionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  documents: undefined,
  document: undefined,
  documentPrintInfo: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Document get documents actions
    case StandardSelectionActions.STANDARD_SELECTION_GET_DOCUMENTS_START:
      return { ...state }
    case StandardSelectionActions.STANDARD_SELECTION_GET_DOCUMENTS_SUCCESS:
      return { ...state, documents: action.payload.data, keepPagination: action.keepPagination }
    case StandardSelectionActions.STANDARD_SELECTION_GET_DOCUMENTS_FAILED:
      return { ...state }
    case StandardSelectionActions.STANDRD_SELECTION_NO_DOCUMENT_DOCUMENTS_FOUND:
      return { ...state, documents: {} }
    /* ********************************************************************************** */
    // Document get document actions
    case StandardSelectionActions.STANDARD_SELECTION_GET_DOCUMENT_START:
      return { ...state, document: undefined }
    case StandardSelectionActions.STANDARD_SELECTION_GET_DOCUMENT_SUCCESS:
      return { ...state, document: action.payload }
    case StandardSelectionActions.STANDARD_SELECTION_GET_DOCUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Document get print info
    case StandardSelectionActions.STANDARD_SELECTION_GET_PRINT_INFO_START:
      return { ...state, documentPrintInfo: undefined }
    case StandardSelectionActions.STANDARD_SELECTION_GET_PRINT_INFO_SUCCESS:
      return { ...state, documentPrintInfo: action.payload }
    case StandardSelectionActions.STANDARD_SELECTION_GET_PRINT_INFO_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Document rescan
    case StandardSelectionActions.STANDARD_SELECTION_RESCAN_DOC_START:
      return { ...state }
    case StandardSelectionActions.STANDARD_SELECTION_RESCAN_DOC_SUCCESS:
      return { ...state }
    case StandardSelectionActions.STANDARD_SELECTION_RESCAN_DOC_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Document reprintbundle
    case StandardSelectionActions.STANDARD_SELECTION_REPRINTBUNDLE_DOC_START:
      return { ...state }
    case StandardSelectionActions.STANDARD_SELECTION_REPRINTBUNDLE_DOC_SUCCESS:
      return { ...state }
    case StandardSelectionActions.STANDARD_SELECTION_REPRINTBUNDLE_DOC_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Document print document action
    case StandardSelectionActions.STANDARD_SELECTION_PRINT_DOCUMENT_START:
      return { ...state }
    case StandardSelectionActions.STANDARD_SELECTION_PRINT_DOCUMENT_SUCCESS:
      return { ...state }
    case StandardSelectionActions.STANDARD_SELECTION_PRINT_DOCUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Document update documents action
    case StandardSelectionActions.STANDARD_SELECTION_UPDATE_DOCUMENT_START:
      return { ...state }
    case StandardSelectionActions.STANDARD_SELECTION_UPDATE_DOCUMENT_SUCCESS:
      return { ...state }
    case StandardSelectionActions.STANDARD_SELECTION_UPDATE_DOCUMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return defaultState
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer