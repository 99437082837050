import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Dropdown from '../dropdown/Dropdown'
import DownloadWrapper from '../download_wrapper/DownloadWrapper'
import Icon from '../icon/Icon'

import hash from 'object-hash'

// no childs const to reduce duplicate strings and avoid spellings bugs
const NO_CHILDS = 'NO_CHILDS'

export default class HierarchyMenu extends Component {

  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Header which are displayed in header of DataHierarchy component.*/
    header: PropTypes.arrayOf(PropTypes.string).isRequired,
    /**
     * Data which is displayed in DataHierarchy component.
     * This data array just stores the root level of the hierarchy.
     * */
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    /**
     * Function to be called on change of the selected item in dropdown list
     * @param {number} index Index of the dropdown list
     */
    changeDownloadAction: PropTypes.func.isRequired,
    /** Function which returns array of JSX elements which are displayed in the menu. */
    additionalInteraction: PropTypes.func,
    /** Array of selected rows */
    checkedRows: PropTypes.arrayOf(PropTypes.number).isRequired,
    /** Index of download functionality in dropdown list */
    downloadActionIndex: PropTypes.number.isRequired,
    /** Items which should be display in dropdown */
    downloadItems: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function to translate following keys by our own.
     *
     * - `general.entries` => will be displayed in hierarchy menu to display how much entries are rendered
     * - `general.selected` => will be displayed when select one entry
     * - `table.download_as_csv` => will be displayed when entries are selected to download
     *
     * @param {string} key
     */
    translate: PropTypes.func
  }

  /**
   * @description Renders the download action when checked a row.
   */
  renderDownloadAction = () => {
    const { id, header, checkedRows } = this.props
    if (checkedRows.length > 0) {
      return (
        <DownloadWrapper
          id={'download_wrapper'}
          header={header}
          data={[...this.getCheckedRowsData()]}
          csvSplitter={';'}
          filename={'data.csv'}>
          <button
            id={'we_id_table_action_btn'}
            type={'button'}
            className={'input-group-addon el_input_button el_table_head_action_input_addon bux_table_menu_open'}>
            <Icon
              id={`${id}_actionBtn_icon`}
              name={'open'}
            />
          </button>
        </DownloadWrapper>
      )
    }
  }


  /**
   * @description Gets the data from the checked rows to make it downloadable.
   * @returns {Array.<Array>}
   */
  getCheckedRowsData = () => {
    const { data, checkedRows } = this.props
    let buffer = []
    checkedRows.forEach(row => {
      data.forEach(d => {
        let dataToPush = d
        if (this.isChild(d)) {
          if (d.data !== NO_CHILDS) {
            dataToPush = d.data
          }
        }
        if (hash(dataToPush) === row) {
          buffer.push(dataToPush)
        }
      })
    })
    return buffer
  }

  /**
   * @description Returns if an element is an child.
   * @return {bool}
   */
  isChild = el => {
    return el instanceof Object && !Array.isArray(el)
  }

  /**
   * @description Filters out all 'NO_CHILDS' rows and returns the data length.
   * @returns {number}
   */
  getDataLength = () => {
    let counter = 0
    this.props.data.forEach(d => {
      if (this.isChild(d)) {
        if (d.data !== NO_CHILDS) {
          counter++
        }
      }
      else {
        counter++
      }
    })
    return counter
  }

  /**
   * @description Renders the whole table menu.
   */
  render = () => {
    const {
      checkedRows,
      additionalInteraction,
      id,
      changeDownloadAction,
      downloadActionIndex,
      downloadItems,
      translate
    } = this.props
    return (
      <div className={'bux_interaction_container'}>
        <span>
          {`${this.getDataLength()} ${translate('general.entries')}`}
        </span>
        <div className={'bux_hierarchy_interaction'}>
          {additionalInteraction && (
            Array.isArray(additionalInteraction())
              ? additionalInteraction().map((d, index) => React.cloneElement(d, { key: `${index}_interaction` }))
              : additionalInteraction())
          }
        </div>
        {
          checkedRows.length > 0 &&
          <div className='bux_actionDropdown'>
            {/* selected rows information */}
            <label className='el_table_head_action_input_label bux_hierarchy_selected'>
              {`${checkedRows.length} ${translate('general.selected')}`}
            </label>
            {/* dropdown of user actions */}
            <Dropdown
              id={`${id}_dropdown`}
              activeIndex={downloadActionIndex}
              items={
                downloadItems
                ? [{ text: translate('table.download_as_csv') }, ...downloadItems.map(t => ({ text: t })) ]
                : [{ text: translate('table.download_as_csv') }]
              }
              onChange={index => changeDownloadAction(index)}
            />
            {/* component to execute the action of the dropdown */}
            {
              downloadActionIndex === 0 && this.renderDownloadAction()
            }
          </div>
        }
      </div>
    )
  }
}