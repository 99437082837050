import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'
import * as Utils from 'utils/Utils'

import Card from 'BetaUX2Web-Components/src/components/card/Card'
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import NumericSpinner from 'BetaUX2Web-Components/src/components/numeric_spinner/NumericSpinner'
import Switch from 'BetaUX2Web-Components/src/components/switch/Switch'
import Filepicker from 'BetaUX2Web-Components/src/components/filepicker/Filepicker'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as UploadActions from 'redux/actions/UploadActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'

const ENC_NONE = ''
const ENC_ISO8859_1 = 'ISO8859-1'
const ENC_ISO8859_2 = 'ISO8859-2'
const ENC_ISO8859_15 = 'ISO8859-15'
const ENC_UTF_8 = 'UTF-8'
const ENC_CP850 = 'CP850'
const ENC_CP1250 = 'CP1250'
const ENC_CP1251 = 'CP1251'
const ENC_CP1252 = 'CP1252'
const ENC_CP1253 = 'CP1253'
const ENC_CP1254 = 'CP1254'
const ENC_CP1256 = 'CP1256'
const ENC_IBM0420 = 'IBM0420'
const ENC_IBM1140 = 'IBM1140'
const ENC_IBM1141 = 'IBM1141'
const ENC_IBM1147 = 'IBM1147'
const ENC_IBM1148 = 'IBM1148'

const ENCODINGS = [
  { key: ENC_NONE, translationKey: 'general.none' },
  { key: ENC_ISO8859_1, translationKey: 'import.enc_iso8859_1' },
  { key: ENC_ISO8859_2, translationKey: 'import.enc_iso8859_2' },
  { key: ENC_ISO8859_15, translationKey: 'import.enc_iso8859_15' },
  { key: ENC_UTF_8, translationKey: 'import.enc_utf_8' },
  { key: ENC_CP850, translationKey: 'import.enc_cp850' },
  { key: ENC_CP1250, translationKey: 'import.enc_cp1250' },
  { key: ENC_CP1251, translationKey: 'import.enc_cp1251' },
  { key: ENC_CP1252, translationKey: 'import.enc_cp1252' },
  { key: ENC_CP1253, translationKey: 'import.enc_cp1253' },
  { key: ENC_CP1254, translationKey: 'import.enc_cp1254' },
  { key: ENC_CP1256, translationKey: 'import.enc_cp1256' },
  { key: ENC_IBM0420, translationKey: 'import.enc_ibm0420' },
  { key: ENC_IBM1140, translationKey: 'import.enc_ibm1140' },
  { key: ENC_IBM1141, translationKey: 'import.enc_ibm1141' },
  { key: ENC_IBM1147, translationKey: 'import.enc_ibm1147' },
  { key: ENC_IBM1148, translationKey: 'import.enc_ibm1148' },
]

const SPEC_FILE_FORMAT_NO = ''
const SPEC_FILE_FORMAT_FILEEXTENSION = 'fileextension'
const SPEC_FILE_FORMAT_EBCDIC_NL = 'MVS'
const SPEC_FILE_FORMAT_EBCDIC_NL_MVSCC = 'MVS-CC'
const SPEC_FILE_FORMAT_EBCDIC_NL_MVSCC_TRC = 'MVS-CC-T'
const SPEC_FILE_FORMAT_ASCII_UTF_8 = 'TXT-CC'

const SPEC_FILE_FORMATS = [
  { key: SPEC_FILE_FORMAT_NO, translationKey: 'general.no' },
  { key: SPEC_FILE_FORMAT_FILEEXTENSION, translationKey: 'import.fileformat_fileextension' },
  { key: SPEC_FILE_FORMAT_EBCDIC_NL, translationKey: 'import.fileformat_ebcdic_nl' },
  { key: SPEC_FILE_FORMAT_EBCDIC_NL_MVSCC, translationKey: 'import.fileformat_ebcdic_nl_mvscc' },
  { key: SPEC_FILE_FORMAT_EBCDIC_NL_MVSCC_TRC, translationKey: 'import.fileformat_ebcdic_nl_mvscc_trc' },
  { key: SPEC_FILE_FORMAT_ASCII_UTF_8, translationKey: 'import.fileformat_ascii_utf_8' },
]

const EDIT_ICON_NONE = ''
const EDIT_ICON_ERROR = 'X'
const EDIT_ICON_HOOK = 'H'
const EDIT_ICON_CLIP = 'C'
const EDIT_ICON_PIN = 'P'
const EDIT_ICON_STAMP = 'S'

const EDIT_ICONS = [
  { key: EDIT_ICON_NONE, translationKey: 'general.none' },
  { key: EDIT_ICON_ERROR, translationKey: 'import.editicon_error' },
  { key: EDIT_ICON_HOOK, translationKey: 'import.editicon_hook' },
  { key: EDIT_ICON_CLIP, translationKey: 'import.editicon_clip' },
  { key: EDIT_ICON_PIN, translationKey: 'import.editicon_pin' },
  { key: EDIT_ICON_STAMP, translationKey: 'import.editicon_stamp' },
]

class ImportStandardImport extends Component {
  defaultState = {
    files: [],
    filesErrorkey: '',
    activeEncodingIndex: Math.max(ENCODINGS.findIndex(entry => entry.key === ENC_NONE), 0),
    activeSpecialFileFormatIndex: Math.max(SPEC_FILE_FORMATS.findIndex(entry => entry.key === SPEC_FILE_FORMAT_NO), 0),
    form: {
      value: '',
      errorkey: ''
    },
    extension: {
      value: '',
      errorkey: ''
    },
    jobname: {
      value: '',
      errorkey: ''
    },
    title: {
      value: '',
      errorkey: ''
    },
    recipient: {
      value: '',
      errorkey: ''
    },
    activeEditIconIndex: Math.max(EDIT_ICONS.findIndex(entry => entry.key === EDIT_ICON_NONE), 0),
    outputformat: {
      value: '',
      errorkey: ''
    },
    outputchannel: {
      value: '',
      errorkey: ''
    },
    copies: 1,
    autoprint: false,
    annotation: {
      value: '',
      errorkey: ''
    },
    docuser1: {
      value: '',
      errorkey: ''
    },
    docuser2: {
      value: '',
      errorkey: ''
    },
    docuser3: {
      value: '',
      errorkey: ''
    },
    docuser4: {
      value: '',
      errorkey: ''
    },
    docuser5: {
      value: '',
      errorkey: ''
    },
    docuser6: {
      value: '',
      errorkey: ''
    },
    docuser7: {
      value: '',
      errorkey: ''
    },
    docuser8: {
      value: '',
      errorkey: ''
    },
  }

  state = {
    ...this.defaultState, // Spread defaultState-object
    showDocumentDefinitionSelectorDialog: false,
    showRecipientDefinitionSelectorDialog: false,
    showOutputformatDefinitionSelectorDialog: false,
    showOutputchannelDefinitionSelectorDialog: false,
  }

  formInput = React.createRef()
  extensionInput = React.createRef()
  jobnameInput = React.createRef()

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
   * @description Initializes the import fields with the values saved in preferences.
   */
  initFieldsFromPreferences = () => {
    const { preferences, userprofile } = this.props

    if (preferences) {
      // encoding
      let encoding = preferences[Preferences.IMPORT_STD_ENCODING]
      if (!encoding) {
        encoding = ENC_NONE
      }
      const activeEncodingIndex = Math.max(ENCODINGS.findIndex(temp => temp.key === encoding), 0)

      // special file format
      let specialFileFormat = preferences[Preferences.IMPORT_STD_SPECIAL_FILE_FORMAT]
      if (!specialFileFormat) {
        specialFileFormat = SPEC_FILE_FORMAT_NO
      }
      const activeSpecialFileFormatIndex = Math.max(SPEC_FILE_FORMATS.findIndex(temp => temp.key === specialFileFormat), 0)

      // form (default: '')
      let form = userprofile
        ? userprofile.VCIIM01 !== ''
          ? userprofile.VCIIM01
          : preferences[Preferences.IMPORT_STD_FORM] || ''
        : preferences[Preferences.IMPORT_STD_FORM] || ''

      // extension (default: '')
      let extension = userprofile
        ? userprofile.VCIIM02 !== ''
          ? userprofile.VCIIM02
          : preferences[Preferences.IMPORT_STD_EXTENSION] || ''
        : preferences[Preferences.IMPORT_STD_EXTENSION] || ''

      // jobname (default: '')
      let jobname = userprofile
        ? userprofile.VCIIM03 !== ''
          ? userprofile.VCIIM03
          : preferences[Preferences.IMPORT_STD_JOBNAME] || ''
        : preferences[Preferences.IMPORT_STD_JOBNAME] || ''

      // title (default: '')
      let title = preferences[Preferences.IMPORT_STD_TITLE] || ''

      // recipient (default: '')
      let recipient = preferences[Preferences.IMPORT_STD_RECIPIENT] || ''

      // edit icon
      let editIcon = preferences[Preferences.IMPORT_STD_EDIT_ICON] || EDIT_ICON_NONE
      const activeEditIconIndex = Math.max(EDIT_ICONS.findIndex(temp => temp.key === editIcon), 0)

      // output format (default: '')
      let outputformat = preferences[Preferences.IMPORT_STD_OUTPUT_FORMAT] || ''

      // output channel (default: '')
      let outputchannel = preferences[Preferences.IMPORT_STD_OUTPUT_CHANNEL] || ''


      // copies
      let copies = preferences[Preferences.IMPORT_STD_COPIES]
      if (!copies) {
        copies = 1
      }
      else {
        if (Utils.isString(copies)) {
          copies = parseInt(copies)
        }
      }

      // autoprint
      let autoprint = preferences[Preferences.IMPORT_STD_AUTOPRINT]
      if (!autoprint) {
        autoprint = false
      }
      else {
        if (Utils.isString(autoprint)) {
          autoprint = autoprint === 'true'
        }
      }

      // annotation (default: '')
      let annotation = preferences[Preferences.IMPORT_STD_ANNOTATION] || ''

      // Set variables vor DocUser 1-8 (default: '')
      let docuser1 = preferences[Preferences.IMPORT_STD_DOCUSER1] || ''
      let docuser2 = preferences[Preferences.IMPORT_STD_DOCUSER2] || ''
      let docuser3 = preferences[Preferences.IMPORT_STD_DOCUSER3] || ''
      let docuser4 = preferences[Preferences.IMPORT_STD_DOCUSER4] || ''
      let docuser5 = preferences[Preferences.IMPORT_STD_DOCUSER5] || ''
      let docuser6 = preferences[Preferences.IMPORT_STD_DOCUSER6] || ''
      let docuser7 = preferences[Preferences.IMPORT_STD_DOCUSER7] || ''
      let docuser8 = preferences[Preferences.IMPORT_STD_DOCUSER8] || ''

      this.setState({
        files: [],
        activeEncodingIndex: activeEncodingIndex,
        activeSpecialFileFormatIndex: activeSpecialFileFormatIndex,
        form: {
          value: form,
          errorkey: ''
        },
        extension: {
          value: extension,
          errorkey: ''
        },
        jobname: {
          value: jobname,
          errorkey: ''
        },
        title: {
          value: title,
          errorkey: ''
        },
        recipient: {
          value: recipient,
          errorkey: ''
        },
        activeEditIconIndex: activeEditIconIndex,
        outputformat: {
          value: outputformat,
          errorkey: ''
        },
        outputchannel: {
          value: outputchannel,
          errorkey: ''
        },
        copies: copies,
        autoprint: autoprint,
        annotation: {
          value: annotation,
          errorkey: ''
        },
        docuser1: {
          value: docuser1,
          errorkey: ''
        },
        docuser2: {
          value: docuser2,
          errorkey: ''
        },
        docuser3: {
          value: docuser3,
          errorkey: ''
        },
        docuser4: {
          value: docuser4,
          errorkey: ''
        },
        docuser5: {
          value: docuser5,
          errorkey: ''
        },
        docuser6: {
          value: docuser6,
          errorkey: ''
        },
        docuser7: {
          value: docuser7,
          errorkey: ''
        },
        docuser8: {
          value: docuser8,
          errorkey: ''
        },
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param {String} id The id of the field to change
   * @param {String} value The new value
   * @param {String} errorkey The new errorkey
   */
  handleInputChanged = (id, value, errorkey) => {
    this.setState({
      [id]: {
        value: value,
        errorkey: errorkey
      }
    })
  }

  /**
   * @description Handles the submit import action.
   * @param {Object} event The event which is thrown by the button
   */
  handleSubmitImport = (event) => {
    event.preventDefault()

    const {
      files,
      activeEncodingIndex,
      activeSpecialFileFormatIndex,
      form,
      extension,
      jobname,
      title,
      recipient,
      activeEditIconIndex,
      outputformat,
      outputchannel,
      copies,
      autoprint,
      annotation,
      docuser1,
      docuser2,
      docuser3,
      docuser4,
      docuser5,
      docuser6,
      docuser7,
      docuser8,
    } = this.state

    // check if values are correct
    if (!this.verifyFields()) {
      return
    }

    // save import values in preferences
    const prefsToChange = {
      [Preferences.IMPORT_STD_ENCODING]: ENCODINGS[activeEncodingIndex].key,
      [Preferences.IMPORT_STD_SPECIAL_FILE_FORMAT]: SPEC_FILE_FORMATS[activeSpecialFileFormatIndex].key,
      [Preferences.IMPORT_STD_FORM]: form.value,
      [Preferences.IMPORT_STD_EXTENSION]: extension.value,
      [Preferences.IMPORT_STD_JOBNAME]: jobname.value,
      [Preferences.IMPORT_STD_TITLE]: title.value,
      [Preferences.IMPORT_STD_RECIPIENT]: recipient.value,
      [Preferences.IMPORT_STD_EDIT_ICON]: EDIT_ICONS[activeEditIconIndex].key,
      [Preferences.IMPORT_STD_OUTPUT_FORMAT]: outputformat.value,
      [Preferences.IMPORT_STD_OUTPUT_CHANNEL]: outputchannel.value,
      [Preferences.IMPORT_STD_COPIES]: copies,
      [Preferences.IMPORT_STD_AUTOPRINT]: autoprint,
      [Preferences.IMPORT_STD_ANNOTATION]: annotation.value,
      [Preferences.IMPORT_STD_DOCUSER1]: docuser1.value,
      [Preferences.IMPORT_STD_DOCUSER2]: docuser2.value,
      [Preferences.IMPORT_STD_DOCUSER3]: docuser3.value,
      [Preferences.IMPORT_STD_DOCUSER4]: docuser4.value,
      [Preferences.IMPORT_STD_DOCUSER5]: docuser5.value,
      [Preferences.IMPORT_STD_DOCUSER6]: docuser6.value,
      [Preferences.IMPORT_STD_DOCUSER7]: docuser7.value,
      [Preferences.IMPORT_STD_DOCUSER8]: docuser8.value,
    }

    this.props.changePrefs(prefsToChange)

    let encoding = ENCODINGS[activeEncodingIndex].key
    if (encoding === ENC_NONE) {
      encoding = ''
    }

    // build import params
    const importParams = {
      'FORM': form.value,
      'EXTENSION': extension.value,
      'JOBNAME': jobname.value,
      'RECI': recipient.value,
      'DCR': outputchannel.value,
      'PCR': outputformat.value,
      'TITLE': title.value,
      'COPIES': copies,
      'AUTOP': autoprint,
      'SRCSUBU': this.props.userid,
      'DOCUSR': [
        docuser1.value, docuser2.value, docuser3.value, docuser4.value, docuser5.value, docuser6.value, docuser7.value, docuser8.value
      ],
      'SPFORMAT': SPEC_FILE_FORMATS[activeSpecialFileFormatIndex].key !== SPEC_FILE_FORMAT_FILEEXTENSION ? SPEC_FILE_FORMATS[activeSpecialFileFormatIndex].key : '',
      'USEEXT': SPEC_FILE_FORMATS[activeSpecialFileFormatIndex].key === SPEC_FILE_FORMAT_FILEEXTENSION,
      'EDTSTAT': EDIT_ICONS[activeEditIconIndex].key,
      'TEXTENCO': ENCODINGS[activeEncodingIndex].key,
    }

    // enable sidefile handling if index files exist
    if (this.getIndexFiles().length > 0) {
      importParams['PROCESS'] = 'SIDEFILE'
    }

    const callback = (successFiles) => {
      let newFiles = [...this.state.files]
      newFiles = newFiles.filter(el => !successFiles.includes(el.name))
      this.onChangeFiles(newFiles)
    }

    this.props.upload(
      importParams,
      files,
      callback
    )
  }

  /**
   * @description Validates the form.
   * @returns {Object} If validation failed new object with error message for state otherwise empty object.
   */
  validateForm = () => {
    const { form } = this.state
    if (form.value !== '') {
      return {}
    }
    return {
      form: {
        ...this.state.form,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the jobname.
   * @returns {Object} If validation failed new object with error message for state otherwise empty object.
   */
  validateJobname = () => {
    const { jobname } = this.state
    if (jobname.value !== '') {
      return {}
    }
    return {
      jobname: {
        ...this.state.jobname,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Verifies the import fields and adds error under required fields if they're not filled.
   */
  verifyFields = () => {
    const { userprofile } = this.props
    let verifyOk = true
    if (this.state.files.length === 0) {
      this.setState({ filesErrorkey: 'import.no_files_selected_error' })
      verifyOk = false
    }

    if (this.state.filesErrorkey !== '') {
      verifyOk = false
    }

    // form
    let tempVerify = this.verfiyField('form', true, verifyOk && this.formInput)
    if (verifyOk) {
      verifyOk = tempVerify
    }

    // extension
    tempVerify = this.verfiyField('extension', userprofile && userprofile.VCIIM02R, verifyOk && this.extensionInput)
    if (verifyOk) {
      verifyOk = tempVerify
    }

    // jobname
    tempVerify = this.verfiyField('jobname', true, verifyOk && this.jobnameInput)
    if (verifyOk) {
      verifyOk = tempVerify
    }

    return verifyOk
  }

  /**
   * @description Verifies a field and adds error under required fields if they're not filled.
   * @param {String} fieldKey The key of the field in state
   * @param {String} required The flag if the field is required (Important for adding error below when value is empty)
   * @param {Boolean} focus The reference to the field to focus when verification fails (pass nothing if you don't want to focus the field)
   */
  verfiyField = (fieldKey, required, focus) => {
    let verifyOk = true
    const field = this.state[fieldKey]

    if (required) {
      // add error when field is empty and required
      if (field.value === '') {
        this.setState({ [fieldKey]: { value: '', errorkey: 'general.input_required' } })
        verifyOk = false
      }
    }

    // switch verify flag if there's an errorkey
    if (verifyOk && field.errorkey && field.errorkey !== '') {
      verifyOk = false
    }

    // focus field on error
    if (!verifyOk && focus) {
      focus.current.focus()
    }

    return verifyOk
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleOnReset = () => {
    const { userprofile } = this.props
    const form = {
      value: userprofile &&
        userprofile.VCIIM01D
        ? userprofile.VCIIM01 !== ''
          ? userprofile.VCIIM01
          : this.state.form.value
        : '',
      error: ''
    }
    const extension = {
      value: userprofile &&
        userprofile.VCIIM02D
        ? userprofile.VCIIM02 !== ''
          ? userprofile.VCIIM02
          : this.state.extension.value
        : '',
      error: ''
    }
    const jobname = {
      value: userprofile &&
        userprofile.VCIIM03D
        ? userprofile.VCIIM03 !== ''
          ? userprofile.VCIIM03
          : this.state.jobname.value
        : '',
      error: ''
    }
    this.setState({
      ...this.defaultState,
      form,
      extension,
      jobname
    })
  }

  /**
   * @description Requests the document definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenDocumentDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showDocumentDefinitionSelectorDialog: true })
    }

    this.props.getDocumentDefinitions(
      ['FORM', 'EXT', 'LTITLE'],
      this.state.form.value,
      this.state.extension.value,
      callback)
  }

  /**
   * @description Requests the recipient definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenRecipientDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showRecipientDefinitionSelectorDialog: true })
    }

    this.props.getRecipientDefinitions(
      ['RECI', 'TITLE'],
      this.state.recipient.value,
      callback)
  }

  /**
   * @description Requests the outputchannel definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenOutputchannelDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showOutputchannelDefinitionSelectorDialog: true })
    }

    this.props.getOutputchannelDefinitions(
      ['DCR', 'DCRTYPE', 'DCRTITLE'],
      this.state.outputchannel.value,
      callback)
  }

  /**
   * @description Requests the outputformat definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenOutputformatDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showOutputformatDefinitionSelectorDialog: true })
    }

    this.props.getOutputformatDefinitions(
      ['PCR', 'PCRTITLE'],
      this.state.outputformat.value,
      callback)
  }

  /**
   * @description Gets the translated encodings.
   * @returns {Array} the ranslated encodings.
   */
  getTranslatedEncodings = () => {
    const encodings = []
    ENCODINGS.forEach(entry => {
      encodings.push(translate(entry.translationKey))
    })

    return encodings
  }

  /**
   * @description Gets the translated special file formats.
   * @returns {Array} The translated special file formats.
   */
  getTranslatedSpecialFileFormats = () => {
    const specialFileFormats = []
    SPEC_FILE_FORMATS.forEach(entry => {
      specialFileFormats.push(translate(entry.translationKey))
    })

    return specialFileFormats
  }

  /**
   * @description Gets the translated edit icons.
   * @returns {Array} The translated edit icons.
   */
  getTranslatedEditIcons = () => {
    const editIcons = []
    EDIT_ICONS.forEach(entry => {
      editIcons.push(translate(entry.translationKey))
    })

    return editIcons
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    // document definitions
    let documentDefinitions
    if (this.state.showDocumentDefinitionSelectorDialog) {
      documentDefinitions = this.props.selector.documents
    }

    // recipient definitions
    let recipientDefinitions
    if (this.state.showRecipientDefinitionSelectorDialog) {
      recipientDefinitions = this.props.selector.recipients
    }

    // outputchannel definitions
    let outputChannelDefinitions
    if (this.state.showOutputchannelDefinitionSelectorDialog) {
      outputChannelDefinitions = this.props.selector.outputchannels
    }

    // outputformat definitions
    let outputFormatDefinitions
    if (this.state.showOutputformatDefinitionSelectorDialog) {
      outputFormatDefinitions = this.props.selector.outputformats
    }

    return (
      <Fragment>
        {this.state.showDocumentDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_documentdefinition_selector_dialog`}
            onClose={() => { this.setState({ showDocumentDefinitionSelectorDialog: false }) }}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.form'),
              translate('general.extension'),
              translate('general.title'),
            ]}
            items={documentDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newForm = documentDefinitions.data[selectedRows][documentDefinitions.header.indexOf('FORM')]
                const newExtension = documentDefinitions.data[selectedRows][documentDefinitions.header.indexOf('EXT')]

                this.setState({
                  form: { value: newForm, errorkey: '' },
                  extension: { value: newExtension, errorkey: '' },
                  showDocumentDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showDocumentDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}


        {this.state.showRecipientDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_recipientdefinition_selector_dialog`}
            onClose={() => { this.setState({ showRecipientDefinitionSelectorDialog: false }) }}
            title={translate('definition.recipientdefinitions')}
            header={[
              translate('general.recipient'),
              translate('general.title'),
            ]}
            items={recipientDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newRecipient = recipientDefinitions.data[selectedRows][recipientDefinitions.header.indexOf('RECI')]
                this.setState({
                  recipient: { value: newRecipient, errorkey: '' },
                  showRecipientDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showRecipientDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showOutputformatDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_outputformatdefinition_selector_dialog`}
            onClose={() => { this.setState({ showOutputformatDefinitionSelectorDialog: false }) }}
            title={translate('definition.outputformatdefinitions')}
            header={[
              translate('import.outputformat'),
              translate('general.title'),
            ]}
            items={outputFormatDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputformat = outputFormatDefinitions.data[selectedRows][outputFormatDefinitions.header.indexOf('PCR')]
                this.setState({
                  outputformat: { value: newOutputformat, errorkey: '' },
                  showOutputformatDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputformatDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

        {this.state.showOutputchannelDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_outputchanneldefinition_selector_dialog`}
            onClose={() => { this.setState({ showOutputchannelDefinitionSelectorDialog: false }) }}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('import.outputchannel'),
              translate('general.type'),
              translate('general.title'),
            ]}
            items={outputChannelDefinitions.data}
            onSelect={(selectedRows) => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputchannel = outputChannelDefinitions.data[selectedRows][outputChannelDefinitions.header.indexOf('DCR')]
                this.setState({
                  outputchannel: { value: newOutputchannel, errorkey: '' },
                  showOutputchannelDefinitionSelectorDialog: false
                })
              } else {
                this.setState({
                  showOutputchannelDefinitionSelectorDialog: false
                })
              }
            }}
          />
        )}

      </Fragment>
    )
  }

  /**
   * @description Will be called when the filepicker list changes.
   * @param {Array} files The new files.
   */
  onChangeFiles = (files) => {
    let idxFiles = files.filter(el => el.name.endsWith('idx'))
    const normalFiles = files.filter(el => !el.name.endsWith('idx'))
    let errorKey = ''

    const errorItems = []
    // check if duplicate documents matches to a single idx file
    idxFiles.forEach(el => {
      const matchingDocs = []

      const filename = el.name.substring(0, el.name.indexOf('.idx'))
      normalFiles.forEach((el1) => {
        if (el1.name.substring(0, el1.name.lastIndexOf('.'))?.toLowerCase() === filename?.toLowerCase()) {
          matchingDocs.push(el1)
        }
      })

      if (matchingDocs.length > 1) {
        errorKey = 'import.index_file_no_unique_match'
        errorItems.push(filename)
      }
    })

    files = normalFiles.concat(idxFiles)
    files.sort((a, b) => {
      const x = a.name.toUpperCase()
      const y = b.name.toUpperCase()
      if (x < y) {
        return -1
      }
      else if (x > y) {
        return 1
      }
      return 0
    })

    // idx files will be ignored for max file count of 100
    const maxFileCount = 100 + idxFiles.length
    this.setState({ files: files.splice(0, maxFileCount), filesErrorkey: errorKey, errorItems: errorItems })
  }

  /**
   * @description Gets the index files which ends with .idx
   * @returns {Array} The index files.
   */
  getIndexFiles = () => {
    const { files } = this.state
    return this.state.files.filter(el => {
      if (el.name.endsWith('.idx')) {
        let name = el.name.substring(0, el.name.indexOf('.idx'))
        return files.find(f => f.name.substring(0, f.name.lastIndexOf('.')) === name && !f.name.endsWith('.idx'))
      }
      else return undefined
    })
  }

  /**
   * @description Gets the selected string for the filepicker. This text is only show when multiple files are selected.
   * @returns {String} The selected string.
   */
  getSelectedString = () => {
    const indexFiles = this.getIndexFiles()
    let selectedString = translate('general.selected_files')
    if (indexFiles.length > 0) {
      selectedString = `${selectedString} (${indexFiles.length} `

      if (indexFiles.length === 1) {
        selectedString = `${selectedString}${translate('filepicker.index_file')})`
      }
      else {
        selectedString = `${selectedString}${translate('filepicker.index_files')})`
      }
    }
    return selectedString
  }

  /**
   * @description Renders the file card.
   */
  renderFileCard = () => {
    const { id } = this.props
    const encodingItems = this.getTranslatedEncodings()
    const specialFileFormatItems = this.getTranslatedSpecialFileFormats()
    const indexFiles = this.getIndexFiles()
    const displayedFileCount = this.state.files.length - indexFiles.length

    return (
      <Card
        title={translate('general.file')}>
        <Row>
          <Column colMD={12}>
            <Filepicker
              id={`${id}_filepicker`}
              displayedFileCount={displayedFileCount}
              files={this.state.files}
              onChange={(files) => this.onChangeFiles(files)}
              title={translate('import.select_files')}
              error={this.state.filesErrorkey && translate(this.state.filesErrorkey)}
              errorItems={this.state.errorItems}
              required={`${translate('general.required_field')}`}
              noSelectedString={translate('general.no_selected_files')}
              selectedString={this.getSelectedString()}
            />
          </Column>
        </Row>
        {/* encoding + special file format row */}
        <Row>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* encoding */}
            <Dropdown
              id={`${id}_encoding`}
              items={encodingItems}
              activeIndex={this.state.activeEncodingIndex}
              onChange={(activeIndex) => { this.setState({ activeEncodingIndex: activeIndex }) }}
              title={translate('import.encoding')}
            />
          </Column>
          <Column
            colMD={6}
            offsetMD={0}>
            {/* special file format */}
            <Dropdown
              id={`${id}_special_file_format`}
              items={specialFileFormatItems}
              activeIndex={this.state.activeSpecialFileFormatIndex}
              onChange={(activeIndex) => { this.setState({ activeSpecialFileFormatIndex: activeIndex }) }}
              title={translate('import.special_file_format')}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the general card.
   */
  renderGeneralCard = () => {
    const { id, userprofile } = this.props

    return (
      <Card
        title={translate('general.general')}>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_form`}
              ref={this.formInput}
              onInputChanged={(val, err) => { this.handleInputChanged('form', val, err) }}
              value={this.state.form.value}
              title={translate('general.form')}
              error={this.state.form.errorkey && translate(this.state.form.errorkey)}
              maxLength={8}
              required={`${translate('general.required_field')}`}
              disabled={userprofile && userprofile.VCIIM01D}
              onBlur={() => this.setState({ ...this.validateForm() })}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenDocumentDefinitionDialog()
              }}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_extension`}
              ref={this.extensionInput}
              onInputChanged={(val, err) => { this.handleInputChanged('extension', val, err) }}
              value={this.state.extension.value}
              title={translate('general.extension')}
              error={this.state.extension.errorkey && translate(this.state.extension.errorkey)}
              maxLength={16}
              required={userprofile && userprofile.VCIIM02R ? `${translate('general.required_field')}` : false}
              disabled={userprofile && userprofile.VCIIM02D}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenDocumentDefinitionDialog()
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_jobname`}
              ref={this.jobnameInput}
              onInputChanged={(val, err) => { this.handleInputChanged('jobname', val, err) }}
              value={this.state.jobname.value}
              title={translate('general.jobname')}
              error={this.state.jobname.errorkey && translate(this.state.jobname.errorkey)}
              maxLength={8}
              required={`${translate('general.required_field')}`}
              disabled={userprofile && userprofile.VCIIM03D}
              onBlur={() => this.setState({ ...this.validateJobname() })}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_title`}
              onInputChanged={(val, err) => { this.handleInputChanged('title', val, err) }}
              value={this.state.title.value}
              title={translate('general.title')}
              error={this.state.title.errorkey && translate(this.state.title.errorkey)}
              maxLength={80}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the additional card.
   */
  renderAdditionalCard = () => {
    const { id } = this.props
    const editIconItems = this.getTranslatedEditIcons()

    return (
      <Card
        title={translate('general.additional')}>
        {/* recipient + edit icon row */}
        <Row>
          <Column colMD={6}>
            {/* recipient */}
            <Input
              id={`${id}_recipient`}
              onInputChanged={(val, err) => { this.handleInputChanged('recipient', val, err) }}
              value={this.state.recipient.value}
              title={translate('general.recipient')}
              error={this.state.recipient.errorkey && translate(this.state.recipient.errorkey)}
              maxLength={32}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenRecipientDefinitionDialog()
              }}
            />
          </Column>
          <Column colMD={6}>
            {/* edit icon */}
            <Dropdown
              id={`${id}_editicon`}
              items={editIconItems}
              activeIndex={this.state.activeEditIconIndex}
              onChange={(activeIndex) => { this.setState({ activeEditIconIndex: activeIndex }) }}
              title={translate('import.editicon')}
            />
          </Column>
        </Row>
        {/* outputformat + output channel row */}
        <Row>
          <Column colMD={6}>
            {/* output format */}
            <Input
              id={`${id}_outputformat`}
              onInputChanged={(val, err) => { this.handleInputChanged('outputformat', val, err) }}
              value={this.state.outputformat.value}
              title={translate('import.outputformat')}
              error={this.state.outputformat.errorkey && translate(this.state.outputformat.errorkey)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputformatDefinitionDialog()
              }}
            />
          </Column>
          <Column colMD={6}>
            {/* output channel */}
            <Input
              id={`${id}_outputchannel`}
              onInputChanged={(val, err) => { this.handleInputChanged('outputchannel', val, err) }}
              value={this.state.outputchannel.value}
              title={translate('import.outputchannel')}
              error={this.state.outputchannel.errorkey && translate(this.state.outputchannel.errorkey)}
              maxLength={16}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputchannelDefinitionDialog()
              }}
            />
          </Column>
        </Row>
        {/* copies + autoprint row */}
        <Row>
          <Column colMD={6}>
            {/* copies */}
            <NumericSpinner
              id={`${id}_copies`}
              onChange={(newVal) => { this.setState({ copies: newVal }) }}
              max={256}
              value={this.state.copies}
              steps={1}
              min={1}
              title={translate('import.copies')}
            />
          </Column>
          <Column colMD={6}>
            {/* autoprint */}
            <Switch
              id={`${id}_copies`}
              activeIndex={this.state.autoprint ? 0 : 1}
              items={[translate('general.yes'), translate('general.no')]}
              title={translate('import.autoprint')}
              onClick={(index) => this.setState({ autoprint: index === 0 })}
            />
          </Column>
        </Row>
        {/* annotation */}
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_annotation`}
              onInputChanged={(val, err) => { this.handleInputChanged('annotation', val, err) }}
              value={this.state.annotation.value}
              title={translate('import.annotation')}
              error={this.state.annotation.errorkey && translate(this.state.annotation.errorkey)}
              maxLength={32}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the docuser variables card.
   */
  renderDocUserVariablesCard = () => {
    const { id } = this.props

    return (
      <Card
        title={translate('general.docuservars')}>
        {/* docuser1 + docuser2 row */}
        <Row>
          <Column colMD={6}>
            {/* docuser1 */}
            <Input
              id={`${id}_docuser1`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser1', val, err) }}
              value={this.state.docuser1.value}
              title={translate('general.docuser1')}
              error={this.state.docuser1.errorkey && translate(this.state.docuser1.errorkey)}
              maxLength={32}
            />
          </Column>
          <Column colMD={6}>
            {/* docuser2 */}
            <Input
              id={`${id}_docuser2`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser2', val, err) }}
              value={this.state.docuser2.value}
              title={translate('general.docuser2')}
              error={this.state.docuser2.errorkey && translate(this.state.docuser2.errorkey)}
              maxLength={32}
            />
          </Column>
        </Row>
        {/* docuser3 + docuser4 row */}
        <Row>
          <Column colMD={6}>
            {/* docuser3 */}
            <Input
              id={`${id}_docuser3`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser3', val, err) }}
              value={this.state.docuser3.value}
              title={translate('general.docuser3')}
              error={this.state.docuser3.errorkey && translate(this.state.docuser3.errorkey)}
              maxLength={32}
            />
          </Column>
          <Column colMD={6}>
            {/* docuser4 */}
            <Input
              id={`${id}_docuser4`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser4', val, err) }}
              value={this.state.docuser4.value}
              title={translate('general.docuser4')}
              error={this.state.docuser4.errorkey && translate(this.state.docuser4.errorkey)}
              maxLength={32}
            />
          </Column>
        </Row>
        {/* docuser5 + docuser6 row */}
        <Row>
          <Column colMD={6}>
            {/* docuser5 */}
            <Input
              id={`${id}_docuser5`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser5', val, err) }}
              value={this.state.docuser5.value}
              title={translate('general.docuser5')}
              error={this.state.docuser5.errorkey && translate(this.state.docuser5.errorkey)}
              maxLength={32}
            />
          </Column>
          <Column colMD={6}>
            {/* docuser6 */}
            <Input
              id={`${id}_docuser6`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser6', val, err) }}
              value={this.state.docuser6.value}
              title={translate('general.docuser6')}
              error={this.state.docuser6.errorkey && translate(this.state.docuser6.errorkey)}
              maxLength={32}
            />
          </Column>
        </Row>
        {/* docuser7 + docuser8 row */}
        <Row>
          <Column colMD={6}>
            {/* docuser7 */}
            <Input
              id={`${id}_docuser7`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser7', val, err) }}
              value={this.state.docuser7.value}
              title={translate('general.docuser7')}
              error={this.state.docuser7.errorkey && translate(this.state.docuser7.errorkey)}
              maxLength={32}
            />
          </Column>
          <Column colMD={6}>
            {/* docuser8 */}
            <Input
              id={`${id}_docuser8`}
              onInputChanged={(val, err) => { this.handleInputChanged('docuser8', val, err) }}
              value={this.state.docuser8.value}
              title={translate('general.docuser8')}
              error={this.state.docuser8.errorkey && translate(this.state.docuser8.errorkey)}
              maxLength={32}
            />
          </Column>
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_main`}
        className={'bux_drawer_main'}>
        {this.renderSelectorDialogs()}  {/* selector dialogs */}
        {this.renderFileCard()}         {/* file card */}
        {this.renderGeneralCard()}      {/* general card */}
        {this.renderAdditionalCard()}   {/* additional card */}
        {this.renderDocUserVariablesCard()} {/* docuser variables card */}
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_import`}
          text={translate('general.import')}
          onClick={this.handleSubmitImport}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleOnReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitImport}>
        {this.renderMain()}     {/* main */}
        {this.renderFooter()}   {/* footer */}
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    userid: state.auth.userid,
    selector: state.selector,
    userprofile: state.auth.serverdata.userprofile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    upload: (importParams, files, callback) => UploadActions.upload(importParams, files, callback)(dispatch),
    changePrefs: (prefs) => { PreferenceActions.changePrefs(prefs)(dispatch) },
    getDocumentDefinitions: (fields, form, extension, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        undefined, // report
        undefined, // smode
        undefined, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback)(dispatch)
    },
    getRecipientDefinitions: (fields, recipient, callback) => {
      ModalSelectorActions.getRecipientDefinitions(
        fields,
        undefined, // rtype
        undefined, // distribution type
        recipient,
        undefined, // preRecipient
        undefined, // owner
        undefined, // title
        undefined, // outputchannel
        undefined, // outputformat
        undefined, // ppn
        callback)(dispatch)
    },
    getOutputchannelDefinitions: (fields, outputchannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputchannel,
        undefined, // type
        undefined, // title
        undefined, // requeue
        undefined, // enable requeue
        callback)(dispatch)
    },
    getOutputformatDefinitions: (fields, outputformat, callback) => {
      ModalSelectorActions.getOutputFormatDefinitions(
        fields,
        outputformat,
        undefined, // owner
        undefined, // title
        callback)(dispatch)
    },
  }
}

ImportStandardImport.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportStandardImport)