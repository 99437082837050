import * as Lang from 'language/Language'
import * as Config from 'config/Configs'
import * as ObjectUtils from 'utils/ObjectUtils'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SearchActions from './SearchActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'
import { createQueryParamsForFetch } from 'utils/UrlUtils'

export const RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_START = 'RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_START'
export const RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_SUCCESS = 'RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_SUCCESS'
export const RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_FAILED = 'RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_FAILED'
export const NO_RESULT_TABLE_DEFINITION_RESULT_TABLES_FOUND = 'NO_RESULT_TABLE_DEFINITION_RESULT_TABLES_FOUND'

export const RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_SUCCESS = 'RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_SUCCESS'

export const RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_OBJECTS_SUCCESS = 'RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_OBJECTS_SUCCESS'

export const RESULT_TABLE_DEFINITION_DELETE_SUCCESS = 'RESULT_TABLE_DEFINITION_DELETE_SUCCESS'

export const RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_START = 'RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_START'
export const RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_FAILED = 'RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_FAILED'
export const RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_SUCCESS = 'RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_SUCCESS'

export const RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_START = 'RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_START'
export const RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_SUCCESS = 'RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_SUCCESS'
export const RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_FAILED = 'RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_FAILED'

export const RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_START = 'RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_START'
export const RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_SUCCESS = 'RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_SUCCESS'
export const RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_FAILED = 'RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_FAILED'
export const RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_NOT_FOUND = 'RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_NOT_FOUND'
export const RESULT_TABLE_DEFINITION_REMOVE_PREVIEW_RESULTS = 'RESULT_TABLE_DEFINITION_REMOVE_PREVIEW_RESULTS'

/**
 * @description Calling the rest api and requests get result tables.
 * @param {String} resultTableID The result table id.
 * @param {String} description The description of the result table.
 * @param {String} owner The owner of the result table.
 * @param {String} jobType The jobtype. Is only used in logx. Possible Values: ('', 'BRWTAB', 'BRWUC4', 'BRWZOS')
 * @param {Function} callback The callback which will be called when the request was successful. Can be null.
 */
export function getResultTables(resultTableID, description, owner, jobType, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_START })
    const queryParams = []
    if (resultTableID) { queryParams.push(`SLTINAME=${encodeURIComponent(resultTableID)}`) }
    if (description) { queryParams.push(`SLTENAME=${encodeURIComponent(description)}`) }
    if (owner) { queryParams.push(`OWNER=${encodeURIComponent(owner)}`) }
    if (jobType) { queryParams.push(`SLTITYPE=${encodeURIComponent(jobType)}`) }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchresults?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_RESULT_TABLE_DEFINITION_RESULT_TABLES_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_FAILED })
          }
        } else {
          dispatch({ type: RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_SUCCESS, payload: jsondata, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('definition.result_tables_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RESULT_TABLE_DEFINITION_GET_RESULT_TABLES_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calling the rest api and requesting the get result table
 * @param {String} resultTableID
 * @param {Function} callback
 * @param {Boolean} keepPagination
 */
export function getResultTable(resultTableID, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchresult?SLTINAME=${encodeURIComponent(resultTableID)}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        }
        else {
          const actionCallback = () => {
            dispatch({ type: RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_SUCCESS, payload: jsondata, keepPagination })
            if (callback) {
              callback()
            }
          }
          // load default objects into redux when no OBJECTS was found in the specific result table to use them in the modify dialog.
          if (!jsondata.data.OBJECTS) {
            getResultTableDefaultObjects(jsondata.data?.SLTITYPE, actionCallback)(dispatch)
          }
          else {
            actionCallback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.result_table_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and creates a result table definition.
 * @param {Object} resultTable "SLTINAME", "SLTENAME", "SLTITYPE", "OWNER",
 * @param {Function} callback The callback which will be called when the create result table request was successful.
 */
export function createResultTable(resultTable, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchresult`, {
        method: 'post',
        body: ObjectUtils.removeByValue(resultTable, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        } else {
          SnackbarActions.show(Lang.translate('definition.create_result_table_success', lang, resultTable['SLTINAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)
          // call callback on success
          if (callback) {
            callback()
          }
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.create_result_table_error', lang, [resultTable['SLTINAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

/**
 * @description Calls the resut api and modifies a result table definition.
 * @param {Object} resultTable The result table object. Possible fields:
 * 'SLTENAME', 'SLTUXCMD', 'SLTINAME', 'MAXENTRY', 'SLTDND', 'DNDNAME', 'OWNER', 'OBJECTS'
 * @param {Function} callback The callback which will be called if the request was successful.
 */
export function modifyResultTable(resultTable, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    const prefs = store.getState().auth.serverdata.preferences
    dispatch({ type: RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_START })

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchresult`, {
        method: 'put',
        body: ObjectUtils.removeByValue(resultTable, [undefined, null])
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check general errors
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_FAILED })
        } else {
          LoadingSpinnerActions.hide()(dispatch)
          SnackbarActions.show(Lang.translate('definition.modify_result_table_success', lang, resultTable['SLTINAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)
          dispatch({ type: RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_SUCCESS })
          // call callback on success
          if (callback) {
            callback()
          }
          refreshSearch()(dispatch)
        }
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        LoadingSpinnerActions.hide()(dispatch)
        SnackbarActions.show(Lang.translate('definition.modify_result_table_error', lang, [resultTable['SLTINAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RESULT_TABLE_DEFINITION_UPDATE_RESULT_TABLE_FAILED })
      })
  }
}

/**
 * @description Calls the rest api and deletes an entry
 * @param {Object} resultTable
 * @param {Function} callback
 */
export function deleteResultTable(resultTable, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/searchresult${createQueryParamsForFetch(ObjectUtils.removeByValue(resultTable, [undefined, null]))}`, { method: 'delete' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        }
        else {
          dispatch({ type: RESULT_TABLE_DEFINITION_DELETE_SUCCESS })
          SnackbarActions.show(Lang.translate('definition.delete_result_table_success', lang, resultTable['SLTINAME']), SnackbarActions.TYPE_SUCCESS)(dispatch)

          if (callback) {
            callback()
          }

          refreshSearch()(dispatch)
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.delete_result_table_error', lang, [resultTable['SLTINAME'], reason]), SnackbarActions.TYPE_ERROR)(dispatch)
      })
  }
}

export function getResultTableDefaultObjects(buxCommand, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_START })

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/definition/defaultsearchresultobjects?SPTUXCMD=${encodeURIComponent('SELDOC')}&SLTITYPE=${encodeURIComponent(buxCommand)}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc
          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
          SnackbarActions.show(error.message, error.type)(dispatch)
        }
        else {
          dispatch({ type: RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_SUCCESS, key: buxCommand, payload: jsondata })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('definition.result_table_objects_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RESULT_TABLE_DEFINITION_GET_RESULT_TABLE_DEFAULT_OBJECTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Refreshes the search with redux prefs
 */
export const refreshSearch = () => {
  return dispatch => {
    const prefs = store.getState().auth.serverdata.preferences
    getResultTables(
      prefs[Preferences.DEFINITION_RESULT_TABLE_ID],
      prefs[Preferences.DEFINITION_RESULT_TABLE_DESCRIPTION],
      prefs[Preferences.DEFINITION_RESULT_TABLE_OWNER],
      prefs[Preferences.DEFINITION_RESULT_TABLE_JOBTYPE]
    )(dispatch)
  }
}

/**
 * @description Calling the rest api to fetch the preview results based on search type.
 * @param {Object} fields The fields to select (If undefined => Return all fields). Possible fields:
 * "OFDOCID", "B93DATE", "B93TIME", "SORTDT", "FORM", "LGRCUSR", "SRCJOBN", "SRCJOBI", "SRCPRCD",
 * "SRCSTPD", "SRCDDND", "EXT",  "DREPORT", "WREPORT", "LTOKEN", "RTOKEN", "SMODE", "LTITLE",
 * "STATUS", "SRCPAGES", "SRCLNCT", "SRCMLRL", "LGRONLNE", "LGRARCH", "LGRAUTOP", "LGRFFARC",
 * "LGRFFDEL", "LGRFFREL", "LGRINDEX", "LGRXEROX", "LGRAFP", "LGRTRC", "LGRASCII", "SRCRECFM",
 * "LGRMAXRL", "LREFD", "LGRNOTES", "PRTSTAT", "B93SDATE", "B93STIME", "EIXPAGES", "SPLPAGES",
 * "PBDNAME", "SRCSUBU", "JOBRC", "OWNER", "DOCUSR1", "DOCUSR2", "DOCUSR3", "DOCUSR4", "DOCUSR5",
 * "DOCUSR6", "DOCUSR7", "DOCUSR8", "TEXTENCO", "NLREFD", "LGRSECIX", "EDTSTAT", "EDTTEXT",
 * "DOCSTAT","OUTSTAT", "PENDING", "BUXORGDT", "BUXINPDT"
 * @param {Object} searchParams The params for the search. Possible params:
 * MAXENTRY: maximum number of hits to be returned,
 * FROMLAST: The from last date pattern,
 * TUNITS: The units of the time (only used on last tab -> custom),
 * SDATE: The start date (only used on date tab),
 * STIME: The start time (only used on date tab),
 * EDATE: The end date (only used on date tab),
 * ETIME: The end time (only used on date tab),
 * TOKEN: The list-token (only used on list timestamp tab),
 * PROCESS: The type of the document (Possible values: LIST, REPORT, ALL),
 * FORM: The form pattern,
 * EXTENSION: The extension pattern,
 * REPORT: The report pattern,
 * RECI: The recipient pattern,
 * FOLDER: The folder pattern,
 * TAB: The tab name pattern,
 * NODE: The node name pattern,
 * JOBNAME: The jobname pattern,
 * TITLE: The title pattern,
 * FTITLE: The column position of text in title,
 * LGRSTAT: The control status (Possible values: H, C)
 * CUSER: The control user
 * ONLINE: The flag for only select online documents (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false),
 * ARCHIVE: The flag for select documents according to archive status (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false, 'PENDING', 'PEND'),
 * INDEXED: The flag for only select indexed documents (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false),
 * DELETE: The flag for only select documents that have been marked for delete (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false),
 * RELOAD: The flag for only select documents that have been marked for reload (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false),
 * LGRNOTE: The flag for only select documents with browser notes (Possible values: 'YES', 'NO', 'JA', 'NEIN', 'OUI', 'NON', true, false),
 * LGRAUTO: The flag for only select documents which are automatically printed
 * DOCTYPE: The document type, for example *.txt or *.afp,
 * SRCSUBU: Only select document which were read-in under the user,
 * OWNER: The owner pattern,
 * DOCUSR1: The docuser1 pattern,
 * DOCUSR2: The docuser2 pattern,
 * DOCUSR3: The docuser3 pattern,
 * DOCUSR4: The docuser4 pattern,
 * DOCUSR5: The docuser5 pattern,
 * DOCUSR6: The docuser6 pattern,
 * DOCUSR7: The docuser7 pattern,
 * DOCUSR8: The docuser8 pattern,
 * EDTSTAT: The edit status (Possible values: 'X' for Error, 'P' for Pin, 'S' for Stamp, 'C' for Clip, 'H' for Hook),
 * SRCOBT: The origin of the document (Possible values: 'IMPORT', 'EDF', 'B93UX', 'MVS', 'RESCAN', 'RELOAD'),
 * JOBRC: The error code,
 * PRTSTAT: The output state (Possible values: 'P' for Printed, 'E' for Error, 'A' for Active, 'W' for Waiting, 'H' for Hold, 'D' for Delete, 'C' for Cancel, 'Q' for Requeued, 'R' for Retry, 'N' for Not found),
 * DCR: The output channel pattern,
 * BREQUEST: The requestor pattern
 * @param {Function} callback The callback which will be called when the request was successful.
 */
export const getPreviewDocuments = ({ fields, searchParams, callback, keepPagination = false, isGlobalIndexSearch }) => {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences

    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_START })

    // clear invalid values of object and encode
    searchParams = ObjectUtils.removeByValue(searchParams, [undefined, null, ''])

    /* Build request-parameter for URL */
    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    Object.keys(searchParams).forEach(key => queryParams.push(`${key}=${encodeURIComponent(searchParams[key])}`))

    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/documents/${!isGlobalIndexSearch ? 'documents' : 'indexes/global'}?${queryParams.join('&')}`, { method: 'get' })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0020' && irc === '3018') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_NOT_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_FAILED, payload: { error } })
          }
        }
        else {
          dispatch({ type: RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout', lang)
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export const getPreviewJobs = ({ fields, searchParams, callback, keepPagination = false }) => {
  return dispatch => {
    SearchActions.getJobs(fields, searchParams, callback, keepPagination, {
      START: RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_START,
      SUCCESS: RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_SUCCESS,
      FAILED: RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_FAILED,
      NOT_FOUND: RESULT_TABLE_DEFINITION_GET_PREVIEW_RESULTS_NOT_FOUND,
      error: 'search.preview_loading_error'
    })(dispatch)
  }
}

export const removePreviewResults = () => {
  return dispatch => {
    dispatch({ type: RESULT_TABLE_DEFINITION_REMOVE_PREVIEW_RESULTS })
  }
}