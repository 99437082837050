import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Components
import {
  Button, Card, Column,
  Input,
  MetaDataGrid,
  Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/';

const { Modal, Main, Header, Footer } = ModalComponent

// Utils
import * as DateUtils from 'utils/DateUtils';

// Redux
import { connect } from 'react-redux';
import * as ExternalCommandActions from 'redux/actions/ExternalCommandDefinitionActions';
import * as Preferences from 'redux/general/Preferences';

class ModifyExternalCmdDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  externalcmdInput = React.createRef()
  commandInput = React.createRef()
  argumentInput = React.createRef()
  descriptionInput = React.createRef()

  state = {
    externalcmdid: {
      value: this.props.selectedDialog.id,
      errorkey: ''
    },
    description: {
      value: this.props.selectedDialog.desc,
      errorkey: ''
    },
    command: {
      value: this.props.selectedDialog.cmd,
      errorkey: ''
    },
    argument: {
      value: this.props.selectedDialog.argument,
      errorkey: ''
    },
  }

  componentDidMount() {
    this.descriptionInput.current.focus()
  }

  /**
 * @description Handles changes on input fields.
 * @param id The id of the field to change
 * @param value The new value
 * @param errorkey The new errorkey
 */
  handleInputChanged = (id, value, errorkey) => {
    this.setState({ [id]: { value, errorkey } })
  }

  /**
   * @description Validates the command.
   */
  validateCommand = () => {
    if (this.state.command.value !== '') {
      return {}
    }
    return {
      command: {
        ...this.state.command,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateInputs = () => {
    const validatorResult = { ...this.validateCommand() }

    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        this.commandInput.current && this.commandInput.current.focus()
      })
    }
    return errors === 0
  }

  /**
   * @description modifies the selected command and saves the new values
   */
  handleOnSave = () => {
    const { updateExternalCmd, onClose } = this.props
    const { externalcmdid, command, description, argument } = this.state
    if (this.validateInputs()) {
      const commandToModify = {
        UTL: externalcmdid.value,
        UTLINFO: description.value,
        UTLPROG: command.value,
        UTLARGS: argument.value
      }
      updateExternalCmd(commandToModify, () => onClose())
    }
  }

  render = () => {
    const { id, selectedDialog, datemask, onClose } = this.props
    const { externalcmdid, command, description, argument } = this.state

    return (
      <Modal onClose={onClose}
        id={`${id}_modify_externalcmd`}
        className={'bux_modifyExternalCmdDialog'}>
        <Header
          id={`${id}_modify_external_cmd_dialog`}
          title={translate('definition.modify_externalcmd')}
          onClose={onClose}>
          <MetaDataGrid
            id={`${id}_header`}
            metaData={[
              { label: translate('external_command.id'), value: externalcmdid.value },
              { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, selectedDialog.date, selectedDialog.time.substring(0, 8)) + ' ' + translate('general.by') + ' ' + selectedDialog.user },
            ]}
            columns={4}
          />
        </Header>
        <Main id={'modal_main'}>
          <Card
            id={`${id}_body_card`}>
            <Row>
              <Column id={`${id}_externalcmd_description_col`} colMD={12}>
                <Input
                  id={`${id}_externalcmd_description_input`}
                  ref={this.descriptionInput}
                  onInputChanged={(val, err) => { this.handleInputChanged('description', val, err) }}
                  value={description.value}
                  title={translate('external_command.description')}
                  maxLength={64}
                />
              </Column>
            </Row>
            <Row>
              <Column id={`${id}_externalcmd_command_col`} colMD={12}>
                <Input
                  id={`${id}_externalcmd_command_input`}
                  ref={this.commandInput}
                  onInputChanged={(val, err) => { this.handleInputChanged('command', val, err) }}
                  value={command.value}
                  title={translate('external_command.command')}
                  error={command.errorkey ? translate(command.errorkey) : null}
                  onBlur={() => this.setState({ ...this.validateCommand() })}
                  maxLength={256}
                  required={`${translate('general.required_field')}`}
                />
              </Column>
            </Row>
            <Row>
              <Column id={`${id}_externalcmd_argument_col`} colMD={12}>
                <Input
                  id={`${id}_externalcmd_argument_input`}
                  ref={this.argumentInput}
                  onInputChanged={(val, err) => { this.handleInputChanged('argument', val, err) }}
                  value={argument.value}
                  title={translate('external_command.arguments')}
                  error={argument.errorkey ? translate(argument.errorkey) : null}
                  maxLength={256}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_modal_footer`}>
          <Button
            id={`${id}_footer_cancel`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_footer_save`}
            text={translate('general.save')}
            onClick={this.handleOnSave}
            primary
            submit
          />
        </Footer>
      </Modal >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateExternalCmd: (extCommandDefinition, callback) => {
      ExternalCommandActions.updateExternalCommand(extCommandDefinition, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyExternalCmdDialog)