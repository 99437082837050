import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { formatInt } from 'utils/Utils'
import * as DateUtils from 'utils/DateUtils'
import { archiveMediaItems } from 'utils/DefinitionUtils'
import { translate } from 'language/Language'

import {
  Button,
  Column,
  DataTable,
  Modal as ModalComponent,
  Row,
  TitledLabel,
  Tab,
  Tabs,
  MetaDataGrid
} from 'BetaUX2Web-Components/src/'

import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'

const { Modal, Main, Header, Footer } = ModalComponent

class ArchiveFileInformationDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  /**
   * @description Gets the table header
   */
  tableHeader = () => {
    return [
      translate('general.key'),
      translate('server.archive_file_objects_lfnd_no'),
      translate('server.archive_file_objects_order'),
      translate('server.archive_file_objects_obj_no'),
      translate('server.archive_file_objects_f_list'),
      translate('server.archive_file_objects_f_page'),
      translate('server.archive_file_objects_l_page'),
      translate('server.archive_file_objects_rec_no'),
      translate('server.archive_file_objects_obj_len'),
      translate('server.archive_file_objects_close')
    ]
  }

  getTableData = () => {
    const { archiveObjects } = this.props
    const data = []
    archiveObjects.data.forEach(el => {
      data.push([
        el[archiveObjects.header.findIndex(d => d === 'ATOKEN')],
        el[archiveObjects.header.findIndex(d => d === 'ALFNDNR')],
        el[archiveObjects.header.findIndex(d => d === 'AORORDER')],
        el[archiveObjects.header.findIndex(d => d === 'AOROBJNR')],
        el[archiveObjects.header.findIndex(d => d === 'AORLSTNR')],
        el[archiveObjects.header.findIndex(d => d === 'AORLSTNF')],
        el[archiveObjects.header.findIndex(d => d === 'AORFPOS')],
        el[archiveObjects.header.findIndex(d => d === 'AORFPAGE')],
        el[archiveObjects.header.findIndex(d => d === 'AORLPAGE')],
        el[archiveObjects.header.findIndex(d => d === 'AORBUFFN')],
        el[archiveObjects.header.findIndex(d => d === 'AORBUFFL')]
      ])
    })
    return data
  }

  /**
   * @description Renders the header
   */
  renderHeader = () => {
    const { id, archiveFile, datemask, lang } = this.props
    const media = translate(archiveMediaItems().find(d => d.key === archiveFile.AGRMED).translationKey)
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.archive_pool'), value: archiveFile.ARCPOOL },
          { label: translate('general.media'), value: media },
          { label: translate('general.owner'), value: archiveFile.OWNER },
          { label: translate('general.retention_period'), value: formatInt(archiveFile.AGRRETPD, lang) },
          { label: translate('general.expires'), value: DateUtils.getDate(datemask, archiveFile.AGREXPDT) },
          { label: translate('general.last_access'), value: DateUtils.getDate(datemask, archiveFile.AGRLDATE) },
          { label: translate('definition.archive_file'), value: archiveFile.AGRFILE },
        ]}
        columns={5}
      />
    )
  }

  /**
   * @description Renders the general tab
   */
  renderGeneralTab = () => {
    const { id, archiveFile, datemask, lang } = this.props
    return (
      <>
        <Row>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_created`}
              title={translate('general.created')}
              text={DateUtils.getDate(
                datemask,
                archiveFile.AGRDATE,
                archiveFile.AGRTIME.substring(0, 8))}
            />
          </Column>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_order`}
              title={translate('general.order')}
              text={archiveFile.AGRORDER}
            />
          </Column>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_level2`}
              title={translate('server.archive_file_level_2')}
              text={formatInt(archiveFile.ADSRETP2, lang)}
            />
          </Column>
          <Column colMD={3}>
            <TitledLabel
              id={`${id}_archivepending`}
              title={translate('server.archive_file_archive_pending')}
              text={
                archiveFile.ADSAPI
                  ? archiveFile.ADSPEND === 'YES'
                    ? translate('general.yes')
                    : translate('general.no')
                  : translate('general.no')
              }
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <label id={`${id}_archive_file_management`}>{translate('server.archive_file_management')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <TitledLabel
              id={`${id}_managedby`}
              title={translate('server.archive_file_managed_by')}
              text={archiveFile.ADSAPI ? translate('general.api') : translate('general.hsm')}
            />
          </Column>
          <Column colMD={6}>
            <TitledLabel
              id={`${id}_key`}
              title={translate('general.key')}
              text={archiveFile.ATOKEN + ' - ' + archiveFile.ALFNDNR}
            />
          </Column>
        </Row>
        {archiveFile.ADSAPI && (
          <>
            <Row>
              <Column colMD={6}>
                <TitledLabel
                  id={`${id}_reloadscript`}
                  title={translate('definition.archive_pool_reload_script_command')}
                  text={archiveFile.ADSRESTC}
                />
              </Column>
              <Column colMD={6}>
                <TitledLabel
                  id={`${id}_deletescript`}
                  title={translate('definition.archive_pool_delete_script_command')}
                  text={archiveFile.ADSDELEC}
                />
              </Column>
            </Row>
            <Row>
              <Column colMD={6}>
                <TitledLabel
                  id={`${id}_param`}
                  title={translate('general.parm')}
                  text={archiveFile.AGRPARM}
                />
              </Column>
            </Row>
          </>
        )}
      </>
    )
  }

  /**
   * @description Renders the objects tab
   */
  renderObjectsTab = () => {
    const { id, archiveObjects, lang, datemask } = this.props
    const data = archiveObjects && archiveObjects.data ? this.getTableData() : null
    return (
      <Row>
        <Column colMD={12}>
          <div className={'bux_datalist_searchargument_values_container'}>
            <DataTable
              id={`${id}_objects`}
              header={[
                translate('general.key'),
                translate('server.archive_file_objects_lfnd_no'),
                translate('server.archive_file_objects_order'),
                translate('server.archive_file_objects_obj_no'),
                translate('server.archive_file_objects_list_no'),
                translate('server.archive_file_objects_f_list'),
                translate('server.archive_file_objects_f_pos'),
                translate('server.archive_file_objects_f_page'),
                translate('server.archive_file_objects_l_page'),
                translate('server.archive_file_objects_rec_no'),
                translate('server.archive_file_objects_obj_len'),
              ]}
              columnSortDefs={['string', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'number', 'number']}
              data={data}
              language={lang}
              datemask={datemask}
              translate={key => translate(key)}
              menu={false}
            />
          </div>
        </Column>
      </Row>
    )
  }

  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id={'archive_file_information_dialog'}
        onClose={onClose}>
        <Header
          id={id}
          title={translate('server.archive_file_information')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main id={id}>
          <Tabs id={id}>
            <Tab title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('general.objects')}>
              {this.renderObjectsTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer id={id}>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    archiveFile: state.archivefiles.archiveFile,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    archiveObjects: state.archivefiles.archiveObjects
  }
}


export default connect(mapStateToProps)(ArchiveFileInformationDialog)