import React, {ReactNode, useEffect, useState} from 'react';

import './Drawer.scss';

import Navbar from './navbar/Navbar';
import Content from './content/Content';

interface DrawerProps {
  id: string;
  // eslint-disable-next-line no-unused-vars
  onDrawerVisibilityChanged: (value: boolean) => void;
  drawerExpanded: boolean | 'initialized';
  navbarButtons?: ReactNode | ReactNode[];
  content?: ReactNode | ReactNode[];
  navbarClassName?: string;
  className?: string;
}

export const OPEN_DRAWER_ANIMATION_TIME_MS = 250;
export const CLOSE_DRAWER_ANIMATION_TIME_MS = 200;

type DrawerStateClassNameSuffixType = 'open' | 'open_animation' | 'close' | 'close_animation'

const Drawer = (props: DrawerProps): JSX.Element => {
  const {id, onDrawerVisibilityChanged, drawerExpanded, navbarClassName, navbarButtons, content} = props;
  const [drawerStateClassNameSuffix, setDrawerStateClassNameSuffix] = useState<DrawerStateClassNameSuffixType>('open');

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (drawerExpanded === true) {
      setDrawerStateClassNameSuffix('open_animation');
      timeoutId = setTimeout(() => setDrawerStateClassNameSuffix('open'), OPEN_DRAWER_ANIMATION_TIME_MS);
    } else if (!drawerExpanded) {
      setDrawerStateClassNameSuffix('close_animation');
      timeoutId = setTimeout(() => setDrawerStateClassNameSuffix('close'), CLOSE_DRAWER_ANIMATION_TIME_MS);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [drawerExpanded, setDrawerStateClassNameSuffix]);

  return (
    <aside
      id={id}
      className={`drawer drawer_${drawerStateClassNameSuffix} ${props.className}`}>
      <Content
        id={`${id}_content`}
        content={content}
      />
      <Navbar
        id={`${id}_nav`}
        className={navbarClassName}
        onVisibilityChange={onDrawerVisibilityChanged}
        visibility={drawerExpanded}>
        {navbarButtons}
      </Navbar>
    </aside>
  );
};

export default Drawer;