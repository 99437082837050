import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropdown from 'BetaUX2Web-Components/src/components/dropdown/Dropdown'
import DatabaseBody from './database_body/DatabaseBody'

import { translate } from 'language/Language'

import * as Preferences from 'redux/general/Preferences'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class Database extends Component {

  /**
   * @description Handles the changes of the database dropdown.
   * @param {Number} activeIndex The new active index.
   */
  handleChangeDatabase = (activeIndex) => {
    const link = this.getLink(activeIndex)

    if (link !== undefined) {
      this.props.history.push(link)
    }
  }

  /**
   * @description Gets the titles of the items.
   */
  getItems = () => {
    const items = []

    this.props.items.map((item) =>
      items.push(item.title)
    )

    return items
  }

  /**
   * @description Gets the link of the active index from the items.
   * @param {Number} activeIndex The current active index of the dropdown.
   */
  getLink = (activeIndex) => {
    // get the object with the current active index and return the link
    return this.props.items.find((item, currentIndex) => currentIndex === activeIndex).link
  }

  /**
   * @description Gets the active index of an database mode.
   * @param {String} databasemode The database mode.
   */
  getActiveIndex = (databasemode) => {
    // get the index of the match with the link of the item and the databasemode
    let index = this.props.items.findIndex((item) => item.link.substring(item.link.lastIndexOf('/') + 1) === databasemode)

    // select first index if there is no item selected
    if (index === -1)
      index = 0

    return index
  }

  render = () => {
    const { id, match } = this.props

    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }

    return (
      <div id={id} className='drawer_field'>
        <div id={`${id}_definition_container1`} className='drawer_header_big bux_drawer_header'>
          <div id={`${id}_definition_container2`} className='row search_row'>
            <div id={`${id}_definition_container3`} className='dropdown el_dropdown'>
              <Dropdown
                id={`${id}_database`}
                title={translate('general.database')}
                items={this.getItems()}
                activeIndex={this.getActiveIndex(url)}
                onChange={(activeIndex) => { this.handleChangeDatabase(activeIndex) }}
              />
            </div>
          </div>
        </div>

        <DatabaseBody
          id={`${id}_body`}
          activeIndex={this.getActiveIndex(url)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

Database.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(withRouter(Database))