import { translate } from 'language/Language'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'

// components
import {
  Button, Checkbox, Column, Dropdown, Input,
  Modal as ModalComponent, NumericSpinner, Row, Switch, Tab,
  Tabs, Toggle
} from 'BetaUX2Web-Components/src/'
import { LetterCase } from 'BetaUX2Web-Components/src/types'
import Datepicker from '../../datepicker/Datepicker'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

const { Modal, Main, Header, Footer } = ModalComponent

// redux
import { connect } from 'react-redux'
import * as LogDefinitionActions from 'redux/actions/LogDefinitionActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as Preferences from 'redux/general/Preferences'
import { hasNoValues } from 'utils/ObjectUtils'
import _ from 'lodash'

class CreateLogDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    prefilledData: PropTypes.object,
  }
  defaultState = {
    showPTXSelectorDialog: false,
    showOutputFormatDefinitionSelectorDialog: false,
    showOutputChannelDefinitionSelectorDialog: false,
    showPostProcessingNoteDefinitionSelectorDialog: false,
    showFilterArgumentDefinitionSelectorDialog: false,
    generalTab: {
      host: {
        value: '',
        error: ''
      },
      application: '',
      owner: '',
      subLog: '',
      searchMode: 0,
      validFrom: {
        value: moment().format(this.props.datemask),
        error: ''
      },
      validTo: {
        value: moment('31.12.9999', DateUtils.DDMMYYYY_DOT).format(this.props.datemask),
        error: ''
      },
      controlStatus: 0,
      controlUserID: '',
      titleType: 0,
      title: '',
      page: 0,
      line: 0,
      column: 0,
      length: 0
    },
    retentionTab: {
      onlineRetention: 0,
      type: 0,
      archiveRetention: 0,
      media: 0,
      calculateRetentionByStartDate: {
        value: 1,
        error: ''
      },
      day: 0,
      month: 0,
      automaticArchiveDocument: 1,
      archiveNotes: 1,
      archiveTextVersion: 1
    },
    /**
     * @description State of the tab for 'Attributes'
     * @param {number} dataCompression Switch state of 'Data compression', 0 = 'Yes' (default), 1 = 'No'
     * @param {number} checkSum Switch state of 'Checksum', 0 = 'Yes', 1 = 'No' (default)
     * @param {number} encoding Dropdown state of 'Encoding', 0 = 'None', Rest see 'DOCUMENT_DEFINITION_ENCODINGS'
     * @param {string} replaceTxtBy Text input field state of 'Replace *.txt by'
     * @param {number} maximumLinesPages Number input field state of 'Maximum lines per page'
     * @param {number} headerLines  Number input field state of 'Header lines'
     * @param {string} startNewPageWhenValueBeginsAtColumnText1 Text input field state of 'Start new page when value beings at column'
     * @param {number} startNewPageWhenValueBeginsAtColumnNumber1 Number input field state of 'Column' (tied to startNewPageWhenValueBeginsAtColumnText1)
     * @param {string} startNewPageWhenValueBeginsAtColumnText2 Text input field state of 2nd 'Start new page when value beings at column'
     * @param {number} startNewPageWhenValueBeginsAtColumnNumber2 Number input field state of 'Column' (tied to startNewPageWhenValueBeginsAtColumnText2)
     * @param {number} pdfTextVersionInUTF8 Switch state of 'PDF text version in UTF-8', 0 = 'Yes', 1 = 'No' (default)
     * @param {boolean} extractDateFromDocument Checkbox state of 'Extract date from log' (default 'false')
     * @param {object} searchPattern Contains parsed text input field value within 'value' and 'error'
     * @param {number} fromLine Number input field state of 'From line' for 'Search pattern'
     * @param {number} toLine Number input field state of 'To line' for 'Search pattern'
     * @param {number} fromColumn  Number input field state of 'From column' for 'Search pattern'
     * @param {number} toColumn  Number input field state of 'To column' for 'Search pattern'
     * @param {number} contentExtractionFromColumn Number input field state of 'Content extraction from column'
     * @param {number} contentExtractionLength Number input field state of 'Content extraction length'
     * @param {number} contentExtractionRelativeTo Switch state of 'Content extraction relative to', 0 = 'Line start' (default), 1 = 'Search pattern start', 2 = 'Search pattern end'
     * @param {string} timeStringFormat Text input field state for 'Time string format'
     * @param {string} lctimeFormat Text input field state for 'LC_TIME format'
     */
    attributesTab: {
      dataCompression: 0,
      checkSum: 1,
      encoding: 0,
      replaceTxtBy: '',
      maximumLinesPages: 0,
      headerLines: 0,
      startNewPageWhenValueBeginsAtColumnText1: '',
      startNewPageWhenValueBeginsAtColumnNumber1: 0,
      startNewPageWhenValueBeginsAtColumnText2: '',
      startNewPageWhenValueBeginsAtColumnNumber2: 0,
      pdfTextVersionInUTF8: 1,
      extractDateFromDocument: false,
      searchPattern: {
        value: '',
        error: ''
      },
      fromLine: 0,
      toLine: 0,
      fromColumn: 0,
      toColumn: 0,
      contentExtractionFromColumn: 0,
      contentExtractionLength: 0,
      contentExtractionRelativeTo: 0,
      timeStringFormat: '',
      lctimeFormat: ''
    },
    printTab: {
      autoprint: 1,
      outputChannelID: '',
      outputFormatID: '',
      postprocessingNote: '',
      printControlFileName: '',
      printPriority: 0,
      bannerTrailerFileNameBanner: '',
      bannerTrailerFileNameTrailer: '',
      printControlFileNameBanner: '',
      printControlFileNameTrailer: ''
    },
    variablesTab: {
      variableExtraction: 0,
      numberOfLines: 0,
      numberOfValues: 0,
      suppressLeadingBlanksAndRepeatedBlanksBetweenWords: 1,
      valueRelativePosition: [
        { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
        { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
        { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
        { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
        { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
        { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
        { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 },
        { line: 0, column: 0, length: 0, searchPattern: '', fromLine: 0, toLine: 0, fromColumn: 0, toColumn: 0 }
      ],
      skipAllBlankValues: false,
      assignInReverseOrder: false,
      skipLeadingBlankValuesOnly: false
    },
    filterTab: {
      filterArgumentID: {
        value: '',
        error: ''
      },
      stopAfterNumberOfMatches: 0,
      headerLinesOnPageOne: 0,
      linesBeforeMatchingLines: 0,
      linesAfterMatchingLines: 0,
      separatorBetweenNonContiguousLines: false,
      markMatches: false,
      prefixLinesWithInputLineNumber: false,
      noDataFoundReport: false,
      prefixLinesWithBetaUxPageAndLineNumber: false
    }
  }
  state = _.cloneDeep(this.defaultState);

  validFromInput = React.createRef()
  validToInput = React.createRef()
  hostInput = React.createRef()
  searchPatternInput = React.createRef()
  filterArgumentIdInput = React.createRef()

  onReset = () => {
    this.setState(_.cloneDeep(this.defaultState))
  }

  componentDidMount = () => {
    if (this.props.copyLog || this.props.prefilledData) {
      const { log: logToCopy, datemask } = this.props
      const log = this.props.prefilledData ? this.props.prefilledData : (this.props.copyLog ? logToCopy : undefined);
      const generalTab = {
        host: { value: log?.FORM, error: '' },
        application: log?.EXT,
        owner: log?.OWNER,
        subLog: log?.REPORT,
        searchMode: Math.max(DefinitionUtils.getLogSearchModes().findIndex(d => d.key === log?.SMODE), 0),
        validFrom: { value: !!log?.VDATEB ? DateUtils.formatDate(moment(log?.VDATEB, DateUtils.DDMMYYYY_DOT), datemask) : moment().format(this.props.datemask), error: '' },
        validTo: { value: !!log?.VDATEE ? DateUtils.formatDate(moment(log?.VDATEE, DateUtils.DDMMYYYY_DOT), datemask) : moment('31.12.9999', DateUtils.DDMMYYYY_DOT).format(this.props.datemask), error: '' },
        controlStatus: Math.max(DefinitionUtils.DOCUMENT_DEFINITION_STATI.findIndex(d => d.key === log?.STATUS), 0),
        controlUserID: log?.LDRCUSR || '',
        title: log?.LTITLE || '',
        page: 0,
        line: 0,
        column: 0,
        length: 0
      }

      if (log?.DYNTIPAG || log?.DYNTIROW || log?.DYNTICOL || log?.DYNTILEN) {
        generalTab.titleType = 1
        generalTab.page = log?.DYNTIPAG
        generalTab.line = log?.DYNTIROW
        generalTab.column = log?.DYNTICOL
        generalTab.length = log?.DYNTILEN
      } else {
        generalTab.titleType = 0
      }

      const retentionTab = {
        onlineRetention: log?.ONLRETPD || 0,
        calculateRetentionByStartDate: { value: 1, error: '' },
        type: Math.max(DefinitionUtils.DOCUMENT_DEFINITION_TYPES.findIndex(d => d.key === log?.CTYPE), 0),
        archiveRetention: log?.ARCRETPD || 0,
        automaticArchiveDocument: log?.AUTOARCH ? 0 : 1,
        archiveNotes: log?.ARCHNOTE ? 0 : 1,
        archiveTextVersion: log?.ARCHDOCT ? 0 : 1,
      }

      if (log?.ARCRETPD > 0) {
        // is needed because the rest api used different values for put/get requests
        let keyToFind = { 'DISK': 'D', 'TAPE': 'T', 'ODISK': 'O' }[log?.ARCHMED]
        retentionTab.media = DefinitionUtils.DOCUMENT_DEFINITION_MEDIA.findIndex(d => d.key === keyToFind)
      }
      else {
        retentionTab.media = 0
      }

      if (log?.ARCRETDD || log?.ARCRETMM) {
        retentionTab.calculateRetentionByStartDate.value = 0
        retentionTab.day = log?.ARCRETDD
        retentionTab.month = log?.ARCRETMM
      }
      else {
        retentionTab.calculateRetentionByStartDate.value = 1
        retentionTab.day = 0
        retentionTab.month = 0
      }

      const attributesTab = {
        dataCompression: log?.LDRCOMP ? 0 : 1,
        checkSum: log?.LDRCHKSM ? 0 : 1,
        encoding: Math.max(DefinitionUtils.DOCUMENT_DEFINITION_ENCODINGS.findIndex(d => d.key === log?.TEXTENCO), 0),
        replaceTxtBy: log?.LDRNMIME || '',
        maximumLinesPages: log?.LDRLNECT || 0,
        headerLines: log?.DOCHLINE || 0,
        startNewPageWhenValueBeginsAtColumnText1: log?.LDRNPAGE || '',
        startNewPageWhenValueBeginsAtColumnNumber1: log?.LDRNPAGC || 0,
        startNewPageWhenValueBeginsAtColumnText2: log?.LDRNDOC || '',
        startNewPageWhenValueBeginsAtColumnNumber2: log?.LDRNDOCC || 0,
        pdfTextVersionInUTF8: log?.LDRPDFU8 ? 0 : 1,
        extractDateFromDocument: global.isSet(log?.LDRDSMSK),
        searchPattern: { value: log?.LDRDSMSK || '', error: '' },
        fromLine: log?.LDRDMKSL || 0,
        toLine: log?.LDRDMKEL || 0,
        fromColumn: log?.LDRDMKSC || 0,
        toColumn: log?.LDRDMKEC || 0,
        contentExtractionFromColumn: log?.LDRDEPOS || 0,
        contentExtractionLength: log?.LDRDELEN || 0,
        contentExtractionRelativeTo: Math.max(DefinitionUtils.DOCUMENT_DEFINITION_CONTENT_EXTRACTION_RELATIVE_TO.findIndex(d => d.key === { 'LS': '0', 'SS': 'S', 'ES': 'E' }[log?.LDRDEREL]), 0),
        timeStringFormat: log?.LDRDTSTR || '',
        lctimeFormat: log?.LDRDTIME || ''
      }

      const printTab = {
        autoprint: log?.AUTO ? 0 : 1,
        outputChannelID: log?.DCR || '',
        outputFormatID: log?.PCR || '',
        postprocessingNote: log?.PPN || '',
        printControlFileName: log?.LISTPIC || '',
        printPriority: log?.PRTPRIO || 0,
        bannerTrailerFileNameBanner: log?.BHPRG || '',
        bannerTrailerFileNameTrailer: log?.THPRG || '',
        printControlFileNameBanner: log?.DJDE || '',
        printControlFileNameTrailer: log?.DJDET || ''
      }

      const variablesTab = {
        variableExtraction: log?.LDRDMODE ? 1 : 0,
        numberOfLines: 0,
        numberOfValues: 0,
        suppressLeadingBlanksAndRepeatedBlanksBetweenWords: log?.LDRDCOMP ? 0 : 1,
        skipAllBlankValues: log?.LDRDSAER,
        assignInReverseOrder: log?.LDRDINV,
        skipLeadingBlankValuesOnly: log?.LDRDSSER,
        valueRelativePosition: []
      }

      if (!log?.LDRDMODE) {
        for (let i = 0; i < 8; i++) {
          const buffer = {
            line: log[`LDRV0${i + 1}R`],
            column: log[`LDRV0${i + 1}C`],
            length: log[`LDRV0${i + 1}L`],
            searchPattern: log[`LDRV0${i + 1}S`],
            fromLine: log[`LDRV0${i + 1}SR`],
            toLine: log[`LDRV0${i + 1}SC`],
            fromColumn: log[`LDRV0${i + 1}ER`],
            toColumn: log[`LDRV0${i + 1}EC`]
          }
          variablesTab.valueRelativePosition.push(buffer)
        }
      } else {
        variablesTab.numberOfLines = log?.LDRVWROW || 0
        variablesTab.numberOfValues = log?.LDRDMNUM || 0
        for (let i = 0; i < 8; i++) {
          let buffer = {}
          if (i === 0) {
            buffer = {
              line: log?.LDRV01R,
              column: log?.LDRV01C,
              length: log?.LDRV01L,
              searchPattern: log?.LDRV01S,
              fromLine: log?.LDRV01SR,
              toLine: log?.LDRV01SC,
              fromColumn: log?.LDRV01ER,
              toColumn: log?.LDRV01EC
            }
          } else {
            buffer = {
              line: 0,
              column: 0,
              length: 0,
              searchPattern: '',
              fromLine: 0,
              toLine: 0,
              fromColumn: 0,
              toColumn: 0
            }
          }
          variablesTab.valueRelativePosition.push(buffer)
        }
      }

      const filterTab = {
        filterArgumentID: { value: log?.SLF, error: '' },
        stopAfterNumberOfMatches: log?.SLFMLINE || 0,
        headerLinesOnPageOne: log?.SLFHLINE || 0,
        linesBeforeMatchingLines: log?.SLFBLINE || 0,
        linesAfterMatchingLines: log?.SLFALINE || 0,
        separatorBetweenNonContiguousLines: log?.SLFSLINE,
        markMatches: log?.SLFMMARK,
        prefixLinesWithInputLineNumber: log?.SLFNLINE,
        noDataFoundReport: log?.SLFNODAT,
        prefixLinesWithBetaUxPageAndLineNumber: log?.SLFULINE
      }

      let updateObj = { generalTab, retentionTab, printTab, variablesTab }
      if (log?.SMODE === 'FILTER') {
        updateObj = { ...updateObj, filterTab }
      } else {
        updateObj = { ...updateObj, attributesTab }
      }
      this.setState({ ...updateObj })
    }
    this.hostInput.current.focus()
  }

  /**
   * @description Handles the input changes of the input fields.
   * @param {String} tab The tab in that the value has changed.
   * @param {String} key The elements state key.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChange = (tab, key, value, error) => {
    this.setState(state => ({
      [tab]: {
        ...state[tab],
        [key]: typeof state[tab][key] === 'object'
          ? { value, error: error || '' }
          : value
      }
    }))
  }

  /**
   * @description Handles the input changes of the spinner fields inside the retentionTab.
   * @param {String} key The elements state key.
   * @param {String} value The new value.
   */

  handleSpinnerChange = (key, value) => {
    const temp = { ...this.state.retentionTab }
    temp[key] = value
    if (value > 0 || temp.calculateRetentionByStartDate.error !== '') {
      temp.calculateRetentionByStartDate.error = ''
    }
    this.setState({
      retentionTab: temp
    })
  }

  /**
   * @description Handles the save action.
   * @param {Boolean} saveAsUtf8 Flag if the definition should be saved as utf-8. If false it will be saved as iso-8859-1.
   */
  handleSave = (saveAsUtf8) => {
    const { createLog, onClose } = this.props
    const { generalTab, retentionTab, attributesTab, printTab, variablesTab, filterTab } = this.state

    const errorTabs = [
      this.validateGeneralTab(),
      this.validateRetentionTab(),
      this.validateAttributesTab(),
      this.validateFilterTab()
    ]
    if (errorTabs.every(d => d)) {
      const generalTabObj = {
        FORM: generalTab.host.value,
        EXT: generalTab.application,
        OWNER: generalTab.owner,
        REPORT: generalTab.subLog,
        SMODE: generalTab.subLog === ''
          ? DefinitionUtils.LOG_DEFINITION_SEARCH_MODES[0].key
          : DefinitionUtils.getLogSearchModes()[generalTab.searchMode].key,
        VDATEB: DateUtils.getTimeshiftDate(generalTab.validFrom.value, '', DateUtils.DDMMYYYY_DOT),
        VDATEE: DateUtils.getTimeshiftDate(generalTab.validTo.value, '', DateUtils.DDMMYYYY_DOT),
        STATUS: DefinitionUtils.DOCUMENT_DEFINITION_STATI[generalTab.controlStatus].key,
        LDRCUSR: generalTab.controlUserID,
        LTITLE: generalTab.title,
      }

      // just send to server when title type is 'dynamic generated'
      if (generalTab.titleType === 1) {
        generalTabObj.DYNTIPAG = generalTab.page
        generalTabObj.DYNTIROW = generalTab.line
        generalTabObj.DYNTICOL = generalTab.column
        generalTabObj.DYNTILEN = generalTab.length
      }

      const retentionTabObj = {
        ONLRETPD: retentionTab.onlineRetention,
        CTYPE: DefinitionUtils.DOCUMENT_DEFINITION_TYPES[retentionTab.type].key,
        ARCRETPD: retentionTab.archiveRetention,
        AUTOARCH: retentionTab.automaticArchiveDocument === 0,
        ARCHNOTE: retentionTab.archiveNotes === 0,
        ARCHDOCT: retentionTab.archiveTextVersion === 0
      }

      if (retentionTab.archiveRetention > 0) {
        retentionTabObj.ARCHMED = DefinitionUtils.DOCUMENT_DEFINITION_MEDIA[retentionTab.media].key
      }

      if (retentionTab.calculateRetentionByStartDate.value === 1) {
        retentionTabObj.ARCRETDD = 0
        retentionTabObj.ARCRETMM = 0
      }
      else {
        retentionTabObj.ARCRETDD = retentionTab.day
        retentionTabObj.ARCRETMM = retentionTab.month
      }

      const attributesTabObj = {
        LDRCOMP: attributesTab.dataCompression === 0,
        LDRCHKSM: attributesTab.checkSum === 0,
        TEXTENCO: DefinitionUtils.DOCUMENT_DEFINITION_ENCODINGS[attributesTab.encoding].key,
        LDRNMIME: attributesTab.replaceTxtBy,
        LDRLNECT: attributesTab.maximumLinesPages,
        DOCHLINE: attributesTab.headerLines || 0,
        LDRNPAGE: attributesTab.startNewPageWhenValueBeginsAtColumnText1,
        LDRNPAGC: attributesTab.startNewPageWhenValueBeginsAtColumnNumber1,
        LDRNDOC: attributesTab.startNewPageWhenValueBeginsAtColumnText2,
        LDRNDOCC: attributesTab.startNewPageWhenValueBeginsAtColumnNumber2,
        LDRPDFU8: attributesTab.pdfTextVersionInUTF8 === 0,
      }

      if (attributesTab.extractDateFromDocument) {
        attributesTabObj.LDRDSMSK = attributesTab.searchPattern.value
        attributesTabObj.LDRDMKSL = attributesTab.fromLine
        attributesTabObj.LDRDMKEL = attributesTab.toLine
        attributesTabObj.LDRDMKSC = attributesTab.fromColumn
        attributesTabObj.LDRDMKEC = attributesTab.toColumn
        attributesTabObj.LDRDEPOS = attributesTab.contentExtractionFromColumn
        attributesTabObj.LDRDELEN = attributesTab.contentExtractionLength
        attributesTabObj.LDRDEREL = DefinitionUtils.DOCUMENT_DEFINITION_CONTENT_EXTRACTION_RELATIVE_TO[attributesTab.contentExtractionRelativeTo].key
        attributesTabObj.LDRDTSTR = attributesTab.timeStringFormat
        attributesTabObj.LDRDTIME = attributesTab.lctimeFormat
      }

      const printTabObj = {
        AUTO: printTab.autoprint === 0,
        DCR: printTab.outputChannelID,
        PCR: printTab.outputFormatID,
        PPN: printTab.postprocessingNote,
        LISTPIC: printTab.printControlFileName,
        PRTPRIO: printTab.printPriority || 0,
        BHPRG: printTab.bannerTrailerFileNameBanner,
        THPRG: printTab.bannerTrailerFileNameTrailer,
        DJDE: printTab.printControlFileNameBanner,
        DJDET: printTab.printControlFileNameTrailer
      }

      const variablesTabObj = {
        LDRDMODE: variablesTab.variableExtraction === 1,
        LDRDCOMP: variablesTab.suppressLeadingBlanksAndRepeatedBlanksBetweenWords === 0,
        LDRDSAER: variablesTab.skipAllBlankValues,
        LDRDINV: variablesTab.assignInReverseOrder,
        LDRDSSER: variablesTab.skipLeadingBlankValuesOnly
      }

      if (variablesTab.variableExtraction === 0) {
        variablesTabObj.LDRVWROW = 0
        variablesTabObj.LDRDMNUM = 0
        variablesTab.valueRelativePosition.forEach((value, index) => {
          variablesTabObj[`LDRV0${index + 1}R`] = value.line
          variablesTabObj[`LDRV0${index + 1}C`] = value.column
          variablesTabObj[`LDRV0${index + 1}L`] = value.length
          variablesTabObj[`LDRV0${index + 1}S`] = value.searchPattern
          variablesTabObj[`LDRV0${index + 1}SR`] = value.fromLine
          variablesTabObj[`LDRV0${index + 1}SC`] = value.toLine
          variablesTabObj[`LDRV0${index + 1}ER`] = value.fromColumn
          variablesTabObj[`LDRV0${index + 1}EC`] = value.toColumn
        })
      }
      else if (variablesTab.variableExtraction === 1) {
        variablesTabObj.LDRVWROW = variablesTab.numberOfLines
        variablesTabObj.LDRDMNUM = variablesTab.numberOfValues
        variablesTabObj.LDRV01R = variablesTab.valueRelativePosition[0].line
        variablesTabObj.LDRV01C = variablesTab.valueRelativePosition[0].column
        variablesTabObj.LDRV01L = variablesTab.valueRelativePosition[0].length
        variablesTabObj.LDRV01S = variablesTab.valueRelativePosition[0].searchPattern
        variablesTabObj.LDRV01SR = variablesTab.valueRelativePosition[0].fromLine
        variablesTabObj.LDRV01SC = variablesTab.valueRelativePosition[0].toLine
        variablesTabObj.LDRV01ER = variablesTab.valueRelativePosition[0].fromColumn
        variablesTabObj.LDRV01EC = variablesTab.valueRelativePosition[0].toColumn
      }

      const filterTabObj = {
        SLF: filterTab.filterArgumentID.value,
        SLFMLINE: filterTab.stopAfterNumberOfMatches,
        SLFHLINE: filterTab.headerLinesOnPageOne,
        SLFBLINE: filterTab.linesBeforeMatchingLines,
        SLFALINE: filterTab.linesAfterMatchingLines,
        SLFSLINE: filterTab.separatorBetweenNonContiguousLines,
        SLFMMARK: filterTab.markMatches,
        SLFNLINE: filterTab.prefixLinesWithInputLineNumber,
        SLFNODAT: filterTab.noDataFoundReport,
        SLFULINE: filterTab.prefixLinesWithBetaUxPageAndLineNumber
      }

      let logObj = {
        ...generalTabObj,
        ...retentionTabObj,
        ...printTabObj,
        ...variablesTabObj,
        SAVEUTF8: saveAsUtf8
      }

      // search mode is none
      if (generalTab.searchMode === 0 && generalTab.subLog === '') {
        logObj = { ...logObj, ...attributesTabObj }
      }
      else if (generalTab.searchMode === 0) {
        logObj = { ...logObj, ...filterTabObj }
      }

      createLog(logObj, () => onClose())
    }
  }

  /**
   * @description Validates the valid from.
   */
  validateValidFromDate = () => {
    const { generalTab } = this.state
    const { datemask } = this.props
    if (![0].includes(generalTab.searchMode)) {
      return {}
    }
    if (generalTab.validFrom.value !== '') {
      if (DateUtils.isDate(generalTab.validFrom.value, datemask)) {
        return {}
      }
    }
    return {
      validFrom: {
        ...this.state.generalTab.validFrom,
        error: translate('general.please_enter_a_valid_date')
      }
    }
  }

  /**
   * @description Validates the valid from.
   */
  validateValidToDate = () => {
    const { generalTab } = this.state
    const { datemask } = this.props
    if (![0].includes(generalTab.searchMode)) {
      return {}
    }
    if (generalTab.validTo.value !== '') {
      if (DateUtils.isDate(generalTab.validTo.value, datemask)) {
        return {}
      }
    }
    return {
      validTo: {
        ...this.state.generalTab.validTo,
        error: translate('general.please_enter_a_valid_date')
      }
    }
  }

  /**
   * @description Validates the host.
   */
  validateHost = () => {
    const { generalTab } = this.state
    if (generalTab.host.value !== '') {
      return {}
    }
    return {
      host: {
        ...this.state.generalTab.host,
        error: translate('general.input_required')
      }
    }
  }

  validateGeneralTab = () => {
    const { generalTab } = this.state
    const validatorResult = {
      ...this.validateHost(),
      ...this.validateValidFromDate(),
      ...this.validateValidToDate()
    }
    const error = Object.keys(validatorResult).length
    if (error > 0) {
      this.setState(state => ({ generalTab: { ...state.generalTab, ...validatorResult } }), () => {
        generalTab.host.error !== '' && this.hostInput.current && this.hostInput.current.focus()
      })
    }
    return error === 0
  }

  /**
   * @description Validates the retention start date.
   */
  validateRetentionDate = () => {
    const { retentionTab } = this.state

    if ((retentionTab.calculateRetentionByStartDate.value === 0 && (retentionTab.day > 0 || retentionTab.month > 0))) {
      return {}
    }
    if (retentionTab.calculateRetentionByStartDate.value === 1 && (retentionTab.day === 0 || retentionTab.month === 0)) {
      return {}
    }
    return {
      calculateRetentionByStartDate: {
        ...this.state.retentionTab.calculateRetentionByStartDate,
        error: translate('general.retention_day_or_month_has_to_be_greater_than_zero')
      }
    }
  }

  /**
   * @description Validates the retention tab.
   */
  validateRetentionTab = () => {
    const validatorResult = {
      ...this.validateRetentionDate()
    }

    const error = Object.keys(validatorResult).length
    if (error > 0) {
      this.setState(state => ({ retentionTab: { ...state.retentionTab, ...validatorResult } }))
    }
    return error === 0
  }

  /**
   * @description Handles the picker validation. Used for onBlur because this validation is different from validation when save definition.
   * @param {string} key The state key.
   */
  handlePickerValidation = key => {
    const { generalTab } = this.state
    const { datemask } = this.props
    const result = generalTab[key]
    // if the field is required, the date picker must have a value
    if ([0].includes(generalTab.searchMode)) {
      if (result.value !== '') {
        result.error = this.handlePickerError(result.value, datemask)
      }
      else {
        result.error = translate('general.input_required')
      }
    }
    else {
      if (result.value !== '') {
        result.error = this.handlePickerError(result.value, datemask)
      }
    }
    this.setState({ generalTab: { ...generalTab, [key]: result } })
  }

  handlePickerError = (val, datemask) => {
    if (!DateUtils.isDate(val, datemask)) {
      return translate('general.please_enter_a_valid_date')
    }
    return ''
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { generalTab } = this.state
    const { datemask, lang, id } = this.props
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_host`}
              required={`${translate('general.required_field')}`}
              title={translate('definition.host')}
              onInputChanged={val => this.handleChange('generalTab', 'host', val, '')}
              maxLength={8}
              value={generalTab.host.value}
              error={generalTab.host.error}
              ref={this.hostInput}
              onBlur={() => this.setState({ generalTab: { ...this.state.generalTab, ...this.validateHost() } })}
              lettercase={LetterCase.uppercase}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_application`}
              title={translate('definition.application')}
              onInputChanged={val => this.handleChange('generalTab', 'application', val)}
              maxLength={16}
              value={generalTab.application}
              lettercase={LetterCase.uppercase}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Input
              id={`${id}_owner`}
              title={translate('general.owner')}
              onInputChanged={val => this.handleChange('generalTab', 'owner', val)}
              maxLength={8}
              value={generalTab.owner}
              lettercase={LetterCase.uppercase}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_sublog`}
              title={translate('definition.sublog')}
              onInputChanged={val => this.handleChange('generalTab', 'subLog', val)}
              maxLength={16}
              value={generalTab.subLog}
              lettercase={LetterCase.uppercase}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Dropdown
              id={`${id}_searchmode`}
              title={translate('definition.document_search_mode')}
              // display none when dropdown is disabled
              items={generalTab.subLog === ''
                ? [translate('general.none')]
                : DefinitionUtils.getLogSearchModes().map(d => translate(d.translationKey))
              }
              activeIndex={generalTab.searchMode}
              onChange={index => this.handleChange('generalTab', 'searchMode', index, '')}
              disabled={generalTab.subLog === ''}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Datepicker
              id={`${id}_valid_from`}
              title={translate('general.valid_from')}
              value={generalTab.validFrom.value}
              onChange={date => this.handleChange('generalTab', 'validFrom', date, '')}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateValidFromDate() } }))}
              // use different function here, because the default validation of toDate do not allowed empty input
              onInvalidDate={val => this.setState(state => ({
                generalTab: { ...state.generalTab, validFrom: { value: val, error: translate('general.please_enter_a_valid_date') } }
              }))}
              dateFormat={datemask}
              error={generalTab.validFrom.error}
              focusRef={this.validFromInput}
              bothDates
              required={[0].includes(generalTab.searchMode) ? `${translate('general.required_field')}` : false}
              language={lang}
              parentContainer={`${id}_tilecontainer`}
            />
          </Column>
          <Column colMD={3}>
            <Datepicker
              id={`${id}_valid_to`}
              title={translate('general.valid_to')}
              value={generalTab.validTo.value}
              onChange={date => this.handleChange('generalTab', 'validTo', date, '')}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateValidToDate() } }))}
              // use different function here, because thedefault validation of toDate do not allowed empty input
              onInvalidDate={val => this.setState(state => ({
                generalTab: { ...state.generalTab, validTo: { value: val, error: translate('general.please_enter_a_valid_date') } }
              }))}
              dateFormat={datemask}
              error={generalTab.validTo.error}
              focusRef={this.validToInput}
              bothDates
              required={[0].includes(generalTab.searchMode) ? `${translate('general.required_field')}` : false}
              language={lang}
              parentContainer={`${id}_tilecontainer`}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_control_status`}
              title={translate('documentinformation.statustab_controlstatus')}
              items={DefinitionUtils.DOCUMENT_DEFINITION_STATI.map(d => translate(d.translationKey))}
              activeIndex={generalTab.controlStatus}
              onChange={index => this.handleChange('generalTab', 'controlStatus', index)}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_control_user_id`}
              title={translate('general.control_user_id')}
              onInputChanged={val => this.handleChange('generalTab', 'controlUserID', val)}
              maxLength={8}
              value={generalTab.controlUserID}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_title`}>{translate('general.title')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_title_type`}
              title={translate('general.title_type')}
              items={[translate('general.fix'), translate('general.dynamic_generated')]}
              onClick={index => this.handleChange('generalTab', 'titleType', index)}
              activeIndex={generalTab.titleType}
            />
          </Column>
          <Column colMD={8}>
            <Input
              id={`${id}_title`}
              title={translate('general.title')}
              onInputChanged={val => this.handleChange('generalTab', 'title', val)}
              maxLength={80}
              value={generalTab.title}
            />
          </Column>
        </Row>
        {
          generalTab.titleType === 1 &&
          <Row>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_page`}
                title={translate('general.page')}
                onChange={val => this.handleChange('generalTab', 'page', val)}
                value={generalTab.page}
                min={0}
                max={32767}
                steps={1}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_line`}
                title={translate('general.line')}
                onChange={val => this.handleChange('generalTab', 'line', val)}
                value={generalTab.line}
                min={0}
                max={9999}
                steps={1}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_column`}
                title={translate('general.column')}
                onChange={val => this.handleChange('generalTab', 'column', val)}
                value={generalTab.column}
                min={0}
                max={999}
                steps={1}
              />
            </Column>
            <Column colMD={3}>
              <NumericSpinner
                id={`${id}_length`}
                title={translate('general.length')}
                onChange={val => this.handleChange('generalTab', 'length', val)}
                value={generalTab.length}
                min={0}
                max={80}
                steps={1}
              />
            </Column>
          </Row>
        }
      </>
    )
  }

  /**
   * @description Renders the retention tab.
   */
  renderRetentionTab = () => {
    const { id } = this.props
    const { retentionTab } = this.state
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_online_retention`}>{translate('documentinformation.onlineretention')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <NumericSpinner
              id={`${id}_online_retention`}
              title={translate('definition.document_retention_days')}
              onChange={val => this.handleChange('retentionTab', 'onlineRetention', val)}
              value={retentionTab.onlineRetention}
              min={0}
              max={9999}
              steps={1}
            />
          </Column>
          <Column colMD={4} className={'bux_pr8'}>
            <Dropdown
              id={`${id}_type`}
              title={translate('general.type')}
              items={DefinitionUtils.DOCUMENT_DEFINITION_TYPES.map(d => translate(d.translationKey))}
              activeIndex={retentionTab.type}
              onChange={index => this.handleChange('retentionTab', 'type', index)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_archive_retention`}>{translate('documentinformation.retentiontab_archiveretention')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <NumericSpinner
              id={`${id}_archive_retention`}
              title={translate('definition.document_retention_days')}
              onChange={val => this.handleChange('retentionTab', 'archiveRetention', val)}
              value={retentionTab.archiveRetention}
              min={0}
              max={30000}
              steps={1}
            />
          </Column>
          <Column colMD={4} className={'bux_pr8'}>
            <Dropdown
              id={`${id}_media`}
              title={translate('general.media')}
              items={retentionTab.archiveRetention === 0
                ? [translate('general.none')]
                : DefinitionUtils.DOCUMENT_DEFINITION_MEDIA.map(d => translate(d.translationKey))}
              activeIndex={retentionTab.media}
              onChange={index => this.handleChange('retentionTab', 'media', index, '')}
              disabled={retentionTab.archiveRetention === 0}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_calculate_retention_by_start_date`}
              title={translate('definition.document_calculate_retention_by_start_date')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('retentionTab', 'calculateRetentionByStartDate', index)}
              activeIndex={retentionTab.calculateRetentionByStartDate.value}
              error={retentionTab.calculateRetentionByStartDate.error}
            />
          </Column>
          <Column colMD={2}>
            <NumericSpinner
              id={`${id}_day`}
              title={translate('statistic.unit_day')}
              onChange={val => this.handleSpinnerChange('day', val)}
              value={retentionTab.day}
              min={0}
              max={31}
              steps={1}
              disabled={retentionTab.calculateRetentionByStartDate.value === 1}
            />
          </Column>
          <Column colMD={2} className={'bux_pr8'}>
            <NumericSpinner
              id={`${id}_month`}
              title={translate('statistic.unit_month')}
              onChange={val => this.handleSpinnerChange('month', val)}
              value={retentionTab.month}
              min={0}
              max={12}
              steps={1}
              disabled={retentionTab.calculateRetentionByStartDate.value === 1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_automatic_archive_document`}
              title={translate('definition.document_automatic_archive_document')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('retentionTab', 'automaticArchiveDocument', index)}
              activeIndex={retentionTab.automaticArchiveDocument}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_archive_notes`}
              title={translate('definition.document_archive_notes')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('retentionTab', 'archiveNotes', index)}
              activeIndex={retentionTab.archiveNotes}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_archive_text_version`}
              title={translate('definition.document_archive_text_version')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('retentionTab', 'archiveTextVersion', index)}
              activeIndex={retentionTab.archiveTextVersion}
            />
          </Column>
        </Row>
      </>
    )
  }

  validateSearchModeAttributesTab = () => {
    const { generalTab, attributesTab } = this.state
    if (generalTab.subLog !== '' || !attributesTab.extractDateFromDocument || (attributesTab.extractDateFromDocument && attributesTab.searchPattern.value !== '')) {
      return {}
    }
    return {
      searchPattern: {
        ...this.state.attributesTab.searchPattern,
        error: translate('general.input_required')
      }
    }
  }

  validateAttributesTab = () => {
    const validatorResult = {
      ...this.validateSearchModeAttributesTab()
    }
    const error = Object.keys(validatorResult).length
    if (error > 0) {
      this.setState({ attributesTab: { ...this.state.attributesTab, ...validatorResult } }, () => {
        this.searchPatternInput.current && this.searchPatternInput.current.focus()
      })
    }
    return error === 0
  }

  /**
   * @description Renders the attributes tab.
   */
  renderAttributesTab = () => {
    const { attributesTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_data_compression`}
              title={translate('general.data_compression')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('attributesTab', 'dataCompression', index)}
              activeIndex={attributesTab.dataCompression}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_checksum`}
              title={translate('general.checksum')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('attributesTab', 'checkSum', index)}
              activeIndex={attributesTab.checkSum}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_text_documents`}>{translate('general.text_documents')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_encoding`}
              title={translate('documentinformation.header_encoding')}
              items={DefinitionUtils.DOCUMENT_DEFINITION_ENCODINGS.map(d => translate(d.translationKey))}
              activeIndex={attributesTab.encoding}
              onChange={index => this.handleChange('attributesTab', 'encoding', index)}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_replace_txt_by`}
              title={translate('definition.document_replace_txt_by')}
              onInputChanged={val => this.handleChange('attributesTab', 'replaceTxtBy', val)}
              maxLength={8}
              value={attributesTab.replaceTxtBy}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_maximum_lines_pages`}
              title={translate('definition.document_maximum_lines_pages')}
              onChange={val => this.handleChange('attributesTab', 'maximumLinesPages', val)}
              value={attributesTab.maximumLinesPages}
              min={0}
              max={999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_header_lines`}
              title={translate('definition.document_header_lines')}
              onChange={val => this.handleChange('attributesTab', 'headerLines', val)}
              value={attributesTab.headerLines}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_start_new_page_when_value_begins_at_column_text_1`}
              title={translate('definition.document_start_new_page_when_value_begins_at_column')}
              onInputChanged={val => this.handleChange('attributesTab', 'startNewPageWhenValueBeginsAtColumnText1', val)}
              maxLength={64}
              value={attributesTab.startNewPageWhenValueBeginsAtColumnText1}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <NumericSpinner
              id={`${id}_start_new_page_when_value_begins_at_column_number_1`}
              title={translate('general.column')}
              onChange={val => this.handleChange('attributesTab', 'startNewPageWhenValueBeginsAtColumnNumber1', val)}
              value={attributesTab.startNewPageWhenValueBeginsAtColumnNumber1}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_start_new_page_when_value_begins_at_column_text_2`}
              onInputChanged={val => this.handleChange('attributesTab', 'startNewPageWhenValueBeginsAtColumnText2', val)}
              maxLength={64}
              value={attributesTab.startNewPageWhenValueBeginsAtColumnText2}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <NumericSpinner
              id={`${id}_start_new_page_when_value_begins_at_column_number_2`}
              title={' '}
              onChange={val => this.handleChange('attributesTab', 'startNewPageWhenValueBeginsAtColumnNumber2', val)}
              value={attributesTab.startNewPageWhenValueBeginsAtColumnNumber2}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_other_text_version`}>{translate('definition.document_other_textversion')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_pdf_text_version_in_utf8`}
              title={translate('definition.document_pdf_textversion_in_utf8')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('attributesTab', 'pdfTextVersionInUTF8', index)}
              activeIndex={attributesTab.pdfTextVersionInUTF8}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Checkbox
              id={`${id}_extract_date_from_document`}
              label={translate('definition.document_extract_date_from_document')}
              value={attributesTab.extractDateFromDocument}
              onCheck={isChecked => this.handleChange('attributesTab', 'extractDateFromDocument', isChecked)}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_search_pattern`}
              ref={this.searchPatternInput}
              title={translate('definition.filter_argument_search_pattern')}
              onInputChanged={val => this.handleChange('attributesTab', 'searchPattern', val, '')}
              maxLength={32}
              value={attributesTab.searchPattern.value}
              error={attributesTab.searchPattern.error}
              disabled={!attributesTab.extractDateFromDocument}
              required={`${translate('general.required_field')}`}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_from_line`}
              title={translate('general.from_line')}
              onChange={val => this.handleChange('attributesTab', 'fromLine', val)}
              value={attributesTab.fromLine}
              min={0}
              max={32767}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_to_line`}
              title={translate('general.to_line')}
              onChange={val => this.handleChange('attributesTab', 'toLine', val)}
              value={attributesTab.toLine}
              min={0}
              max={32767}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
        </Row>
        <Row>
          <Column offsetMD={6} colMD={3} className={'bux_pl8'}>
            <NumericSpinner
              id={`${id}_from_column`}
              title={translate('general.from_column')}
              onChange={val => this.handleChange('attributesTab', 'fromColumn', val)}
              value={attributesTab.fromColumn}
              min={0}
              max={32767}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_to_column`}
              title={translate('general.to_column')}
              onChange={val => this.handleChange('attributesTab', 'toColumn', val)}
              value={attributesTab.toColumn}
              min={0}
              max={32767}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_content_extraction_from_column`}
              title={translate('definition.document_content_extraction_from_column')}
              onChange={val => this.handleChange('attributesTab', 'contentExtractionFromColumn', val)}
              value={attributesTab.contentExtractionFromColumn}
              min={0}
              max={32767}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_content_extraction_length`}
              title={translate('definition.document_content_extraction_length')}
              onChange={val => this.handleChange('attributesTab', 'contentExtractionLength', val)}
              value={attributesTab.contentExtractionLength}
              min={0}
              max={50}
              steps={1}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
          <Column colMD={6}>
            <Switch
              id={`${id}_content_extraction_relative_to`}
              title={translate('definition.document_content_extraction_relative_to')}
              items={[
                translate('general.line_start'),
                translate('general.search_pattern_start'),
                translate('general.search_pattern_end')
              ]}
              onClick={index => this.handleChange('attributesTab', 'contentExtractionRelativeTo', index)}
              activeIndex={attributesTab.contentExtractionRelativeTo}
              disabled={!attributesTab.extractDateFromDocument}
              maxPerRow={3}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_time_string_format`}
              title={translate('general.time_string_format')}
              onInputChanged={val => this.handleChange('attributesTab', 'timeStringFormat', val)}
              maxLength={32}
              value={attributesTab.timeStringFormat}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Input
              id={`${id}_lctime_format`}
              title={translate('general.lc_time')}
              onInputChanged={val => this.handleChange('attributesTab', 'lctimeFormat', val)}
              maxLength={32}
              value={attributesTab.lctimeFormat}
              disabled={!attributesTab.extractDateFromDocument}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the print tab.
   */
  renderPrintTab = () => {
    const { printTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label
              id={`${id}_document_print_parameter`}>
              {translate('definition.document_document_print_parameter')}
            </label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Switch
              id={`${id}_autoprint`}
              title={translate('import.autoprint')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handleChange('printTab', 'autoprint', index)}
              activeIndex={printTab.autoprint}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Input
              id={`${id}_output_channel`}
              value={printTab.outputChannelID}
              title={translate('recipient.output_channel')}
              maxLength={16}
              onInputChanged={val => this.handleChange('printTab', 'outputChannelID', val)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputChannelDefinitionDialog(),
              }
              }
            />
          </Column>
          <Column colMD={4}>
            <Input
              id={`${id}_output_format`}
              value={printTab.outputFormatID}
              title={translate('recipient.output_format')}
              maxLength={16}
              onInputChanged={val => this.handleChange('printTab', 'outputFormatID', val)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputFormatDefinitionDialog(),
              }
              }
            />
          </Column>
          <Column colMD={4}>
            <Input
              id={`${id}_postprocessing_note`}
              value={printTab.postprocessingNote}
              title={translate('recipient.postprocessing_note')}
              maxLength={16}
              onInputChanged={val => this.handleChange('printTab', 'postprocessingNote', val)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenPostProcessingNoteDefinitionDialog(),
              }
              }
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Input
              id={`${id}_print_control_file_name`}
              title={translate('definition.ppn_print_control_filename')}
              onInputChanged={val => this.handleChange('printTab', 'printControlFileName', val)}
              maxLength={8}
              value={printTab.printControlFileName}
            />
          </Column>
          <Column colMD={2}>
            <NumericSpinner
              id={`${id}_print_priority`}
              title={translate('general.print_priority')}
              onChange={val => this.handleChange('printTab', 'printPriority', val)}
              value={printTab.printPriority}
              min={0}
              max={15}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_banner_trailer_file_name`}>{translate('definition.document_banner_trailer_file_name')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_banner_trailer_file_name_banner`}
              title={translate('recipient.banner')}
              onInputChanged={val => this.handleChange('printTab', 'bannerTrailerFileNameBanner', val)}
              maxLength={8}
              value={printTab.bannerTrailerFileNameBanner}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_banner_trailer_file_name_trailer`}
              title={translate('recipient.banner_tab_banner_file_name_trailer')}
              onInputChanged={val => this.handleChange('printTab', 'bannerTrailerFileNameTrailer', val)}
              maxLength={8}
              value={printTab.bannerTrailerFileNameTrailer}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_print_control_file_name`}>{translate('definition.document_print_control_file_name')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_print_control_file_name_banner`}
              title={translate('recipient.banner')}
              onInputChanged={val => this.handleChange('printTab', 'printControlFileNameBanner', val)}
              maxLength={8}
              value={printTab.printControlFileNameBanner}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_print_control_file_name_trailer`}
              title={translate('recipient.banner_tab_banner_file_name_trailer')}
              onInputChanged={val => this.handleChange('printTab', 'printControlFileNameTrailer', val)}
              maxLength={8}
              value={printTab.printControlFileNameTrailer}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the variables tab.
   */
  renderVariablesTab = () => {
    const { id } = this.props
    const { variablesTab } = this.state
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_extract_variable_values`}>{translate('definition.document_extract_variable_values')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_variable_extraction`}
              title={translate('definition.document_variable_extraction')}
              items={[
                translate('definition.document_according_to_rules_1_8'),
                translate('definition.document_from_position_of_rule_1')
              ]}
              onClick={index => this.handleChange('variablesTab', 'variableExtraction', index)}
              activeIndex={variablesTab.variableExtraction}
            />
          </Column>
          {variablesTab.variableExtraction === 1 && (
            <>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_number_of_lines`}
                  title={translate('definition.document_number_of_lines')}
                  onChange={val => this.handleChange('variablesTab', 'numberOfLines', val)}
                  value={variablesTab.numberOfLines}
                  min={0}
                  max={32}
                  steps={1}
                />
              </Column>
              <Column colMD={3}>
                <NumericSpinner
                  id={`${id}_number_of_values`}
                  title={translate('definition.document_number_of_values')}
                  onChange={val => this.handleChange('variablesTab', 'numberOfValues', val)}
                  value={variablesTab.numberOfValues}
                  min={0}
                  max={8}
                  steps={1}
                />
              </Column>
            </>
          )}
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_supress_leading_blanks_and_repeated_blanks_between_words`}
              title={translate('definition.document_supress_leading_blanks_and_repeated_blanks_between_words')}
              items={[
                translate('general.yes'),
                translate('general.no')
              ]}
              onClick={index => this.handleChange('variablesTab', 'suppressLeadingBlanksAndRepeatedBlanksBetweenWords', index)}
              activeIndex={variablesTab.suppressLeadingBlanksAndRepeatedBlanksBetweenWords}
            />
          </Column>
        </Row>
        {this.renderValueRelativePosition()}
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_assign_values_to_docusr_variables`}>{translate('definition.document_assign_values_to_docusr_variables')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Toggle
              id={`${id}_skip_all_blank_values`}
              onCheck={val => this.handleChange('variablesTab', 'skipAllBlankValues', val)}
              value={variablesTab.skipAllBlankValues}
              title={translate('definition.document_skip_all_blank_values')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
          <Column colMD={4}>
            <Toggle
              id={`${id}_assign_in_reverse_order`}
              onCheck={val => this.handleChange('variablesTab', 'assignInReverseOrder', val)}
              value={variablesTab.assignInReverseOrder}
              title={translate('definition.document_assign_in_reverse_order')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={4}>
            <Toggle
              id={`${id}_skip_leading_blank_values_only`}
              onCheck={val => this.handleChange('variablesTab', 'skipLeadingBlankValuesOnly', val)}
              value={variablesTab.skipLeadingBlankValuesOnly}
              title={translate('definition.document_skip_leading_blank_values_only')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the changes of the numeric spinners.
   * @param {String} key The key of the numeric spinner in state.
   * @param {Number} index The index of the numeric spinner (row).
   * @param {Number} val The new value.
   */
  handleRelativePosition = (key, index, val) => {
    const newValue = this.state.variablesTab.valueRelativePosition
    newValue[index][key] = val
    this.setState({
      variablesTab: {
        ...this.state.variablesTab,
        valueRelativePosition: newValue
      }
    })
  }

  /**
   * @description Renders the value relative position section
   */
  renderValueRelativePosition = () => {
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={7}>
            <label id={`${id}_value_relative_position`}>{translate('definition.document_value_relative_position')}</label>
          </Column>
          <Column colMD={5}>
            <label id={`${id}_search`}>{translate('general.search')}</label>
          </Column>
        </Row>
        {this.renderValueRelativePositionRows()}
      </>
    )
  }

  renderValueRelativePositionRows = () => {
    const { id } = this.props
    const { variablesTab } = this.state
    return (
      variablesTab.valueRelativePosition.map((el, i) => (
        <>
          <Row>
            <Column colMD={4}>
              <Row removePadding>
                <Column colMD={5}>
                  <Row removePadding>
                    <Column colMD={2}>
                      <label className={'bux_mb0 bux_mt28'}>{i + 1}.</label>
                    </Column>
                    <Column colMD={10}>
                      <NumericSpinner
                        id={`${id}_line_${i}`}
                        onChange={val => this.handleRelativePosition('line', i, val)}
                        value={el.line}
                        min={0}
                        max={32767}
                        steps={1}
                        disableButtons
                        title={i === 0 ? translate('general.line') : ' '}
                        disabled={variablesTab.variableExtraction === 1 && i > 0}
                      />
                    </Column>
                  </Row>
                </Column>
                <Column colMD={4}>
                  <NumericSpinner
                    id={`${id}_column_${i}`}
                    onChange={val => this.handleRelativePosition('column', i, val)}
                    value={el.column}
                    min={0}
                    max={32767}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.column') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_length_${i}`}
                    onChange={val => this.handleRelativePosition('length', i, val)}
                    value={el.length}
                    min={0}
                    max={32}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.length') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
              </Row>
            </Column>
            <Column colMD={3}>
              <Input
                id={`${id}_searchpattern_${i}`}
                onInputChanged={val => this.handleRelativePosition('searchPattern', i, val)}
                maxLength={32}
                value={el.searchPattern}
                disabled={variablesTab.variableExtraction === 1 && i > 0}
                title={i === 0 ? translate('general.searchpattern') : ' '}
              />
            </Column>
            <Column colMD={5}>
              <Row removePadding>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_from_line_${i}`}
                    onChange={val => this.handleRelativePosition('fromLine', i, val)}
                    value={el.fromLine}
                    min={0}
                    max={32767}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.from_line') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_to_line_${i}`}
                    onChange={val => this.handleRelativePosition('toLine', i, val)}
                    value={el.toLine}
                    min={0}
                    max={32767}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.to_line') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_from_column_${i}`}
                    onChange={val => this.handleRelativePosition('fromColumn', i, val)}
                    value={el.fromColumn}
                    min={0}
                    max={32767}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.from_column') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
                <Column colMD={3}>
                  <NumericSpinner
                    id={`${id}_to_column_${i}`}
                    onChange={val => this.handleRelativePosition('toColumn', i, val)}
                    value={el.toColumn}
                    min={0}
                    max={32767}
                    steps={1}
                    disableButtons
                    title={i === 0 ? translate('general.to_column') : ' '}
                    disabled={variablesTab.variableExtraction === 1 && i > 0}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </>
      ))
    )
  }

  /**
   * @description Validates the filter argument ID
   */
  validateFilterArgumentID = () => {
    const { generalTab, filterTab } = this.state
    if (generalTab.subLog !== '') {
      if (filterTab.filterArgumentID.value !== '') {
        return {}
      }
      return {
        filterArgumentID: {
          ...this.state.filterTab.filterArgumentID,
          error: 'general.input_required'
        }
      }
    } else {
      return {}
    }
  }

  /**
   * @description Validates the filter tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateFilterTab = () => {
    const validatorResult = { ...this.validateFilterArgumentID() }
    const errors = Object.keys(this.validateFilterArgumentID()).length
    if (errors > 0) {
      this.setState({ filterTab: { ...this.state.filterTab, ...validatorResult } }, () => {
        this.filterArgumentIdInput.current && this.filterArgumentIdInput.current.focus()
      })
    }
    return errors === 0
  }

  /**
   * @description Renders the filter tab.
   */
  renderFilterTab = () => {
    const { filterTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_filterargumentid`}
              value={filterTab.filterArgumentID.value}
              title={translate('definition.filter_argument_id')}
              ref={this.filterArgumentIdInput}
              maxLength={16}
              error={filterTab.filterArgumentID.error && translate(filterTab.filterArgumentID.error)}
              onInputChanged={val => this.handleChange('filterTab', 'filterArgumentID', val, '')}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenFilterArgumentDefinitionDialog(),
              }
              }
              onBlur={() => this.setState({ filterTab: { ...this.state.filterTab, ...this.validateFilterArgumentID() } })}
              required={`${translate('general.required_field')}`}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_stop_after_number_of_matches`}
              title={translate('definition.document_stop_after_number_of_matches')}
              onChange={val => this.handleChange('filterTab', 'stopAfterNumberOfMatches', val)}
              value={filterTab.stopAfterNumberOfMatches}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_header_lines_on_page_one`}
              title={translate('definition.document_header_lines_on_page_one')}
              onChange={val => this.handleChange('filterTab', 'headerLinesOnPageOne', val)}
              value={filterTab.headerLinesOnPageOne}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_lines_before_matching_lines`}
              title={translate('definition.document_lines_before_matching_lines')}
              onChange={val => this.handleChange('filterTab', 'linesBeforeMatchingLines', val)}
              value={filterTab.linesBeforeMatchingLines}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_lines_after_matching_lines`}
              title={translate('definition.document_lines_after_matching_lines')}
              onChange={val => this.handleChange('filterTab', 'linesAfterMatchingLines', val)}
              value={filterTab.linesAfterMatchingLines}
              min={0}
              max={99}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Toggle
              id={`${id}_separator_between_noncontiguous_lines`}
              onCheck={val => this.handleChange('filterTab', 'separatorBetweenNonContiguousLines', val)}
              value={filterTab.separatorBetweenNonContiguousLines}
              title={translate('definition.document_separator_between_noncontiguous_lines')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
          <Column colMD={6}>
            <Toggle
              id={`${id}_mark_matches`}
              onCheck={val => this.handleChange('filterTab', 'markMatches', val)}
              value={filterTab.markMatches}
              title={translate('definition.document_mark_matches')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Toggle
              id={`${id}_prefix_lines_with_input_line_number`}
              onCheck={val => this.handleChange('filterTab', 'prefixLinesWithInputLineNumber', val)}
              value={filterTab.prefixLinesWithInputLineNumber}
              title={translate('definition.document_prefix_lines_with_input_line_number')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
          <Column colMD={6}>
            <Toggle
              id={`${id}_no_data_found_report`}
              onCheck={val => this.handleChange('filterTab', 'noDataFoundReport', val)}
              value={filterTab.noDataFoundReport}
              title={translate('definition.document_no_data_found_report')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Toggle
              id={`${id}_prefix_lines_with_betaux_page_line_number`}
              onCheck={val => this.handleChange('filterTab', 'prefixLinesWithBetaUxPageAndLineNumber', val)}
              value={filterTab.prefixLinesWithBetaUxPageAndLineNumber}
              title={translate('definition.document_prefix_lines_with_betaux_page_line_number')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
      </>
    )
  }

  handleErrorTabs = () => {
    const { generalTab, retentionTab, attributesTab, filterTab } = this.state
    let buffer = []
    // possible general tab errors
    const isFormError = generalTab.host.error !== ''
    const isValidFromError = generalTab.validFrom.error !== ''
    const isValidToError = generalTab.validTo.error !== ''
    // possible retention tab errors
    const isValidRetentionError = retentionTab.calculateRetentionByStartDate.error !== ''
    // possible attributes tab errors
    const isSearchPatternError = generalTab.subLog === '' && attributesTab.searchPattern.error !== ''
    // possible filter tab errors
    const isFilterArgumentError = filterTab.filterArgumentID.error !== ''

    if (isFormError || isValidFromError || isValidToError) {
      buffer.push(0)
    }
    if (isValidRetentionError) {
      buffer.push(1)
    }
    if (isSearchPatternError) {
      buffer.push(2)
    }
    if (isFilterArgumentError && generalTab.subLog !== '') {
      buffer.push(5)
    }
    return buffer
  }

  /**
   * @description Returns the disabled tab indices.
   * @returns {Array.<Number>}
   */
  getDisabledTabs = () => {
    const { generalTab } = this.state
    let buffer = []
    switch (generalTab.searchMode) {
      case 0:
        if (generalTab.subLog === '') {
          // if search mode value is none
          buffer.push(5)
        }
        else {
          // if search mode value is filter
          buffer.push(2)
        }
        break
      default:
        break
    }
    return buffer
  }

  /**
 * @description Requests the outputformat definition with the current selection. On successful request it opens the seletor dialog?.
 */
  onOpenOutputFormatDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showOutputFormatDefinitionSelectorDialog: true })
    }

    this.props.getOutputFormatDefinitions(
      ['PCR', 'PCRTITLE'],
      this.state.printTab.outputFormat,
      callback)
  }

  /**
   * @description Requests the outputchannel definition with the current selection. On successful request it opens the seletor dialog?.
   */
  onOpenOutputChannelDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showOutputChannelDefinitionSelectorDialog: true })
    }
    this.props.getOutputChannelDefinitions(
      ['DCR', 'DCRTYPE', 'DCRTITLE'],
      this.state.printTab.outputChannelID,
      callback)
  }

  /**
   * @description Requests the postprocessingnote definition with the current selection. On successful request it opens the seletor dialog?.
   */
  onOpenPostProcessingNoteDefinitionDialog = () => {
    const callback = () => {
      this.setState({ showPostProcessingNoteDefinitionSelectorDialog: true })
    }

    this.props.getPostProcessingNotesDefinitions(
      ['PPN', 'PPNTITLE'],
      this.state.printTab.postprocessingNote,
      callback)
  }

  /**
   * @description Requests the filter argument definition with the current selection. On successful request it opens the seletor dialog?.
   */
  onOpenFilterArgumentDefinitionDialog = () => {
    const callback = () => this.setState({ showFilterArgumentDefinitionSelectorDialog: true })
    this.props.getFilterArgumentsDefinitions(
      ['SLF', 'SLFTITLE'],
      this.state.filterTab.filterArgumentID.value,
      callback
    )
  }

  /**
 * @description Renders the selector dialogs.
 */
  renderSelectorDialogs = () => {
    const {
      showPTXSelectorDialog,
      showOutputFormatDefinitionSelectorDialog,
      showOutputChannelDefinitionSelectorDialog,
      showPostProcessingNoteDefinitionSelectorDialog,
      showFilterArgumentDefinitionSelectorDialog
    } = this.state
    const { id } = this.props
    // outputformat definitions
    let outputFormatDefinitions
    if (showOutputFormatDefinitionSelectorDialog) {
      outputFormatDefinitions = this.props.selector.outputformats
    }

    // outputchannel definitions
    let outputChannelDefinitions
    if (showOutputChannelDefinitionSelectorDialog) {
      outputChannelDefinitions = this.props.selector.outputchannels
    }

    // postprocessingnote definitions
    let postProcessingNoteDefinitions
    if (showPostProcessingNoteDefinitionSelectorDialog) {
      postProcessingNoteDefinitions = this.props.selector.ppns
    }

    // filter argument definitions
    let filterArgumentDefinitions
    if (showFilterArgumentDefinitionSelectorDialog) {
      filterArgumentDefinitions = this.props.selector.filterarguments
    }

    return (
      <>
        {showOutputFormatDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputformatselector_dialog`}
            onClose={() => {
              this.setState({ showOutputFormatDefinitionSelectorDialog: false })
            }}
            title={translate('definition.outputformatdefinitions')}
            header={[
              translate('import.outputformat'),
              translate('general.title'),
            ]}
            items={outputFormatDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputformat = outputFormatDefinitions.data[selectedRows][outputFormatDefinitions.header.indexOf('PCR')]
                this.setState({
                  showOutputFormatDefinitionSelectorDialog: false,
                  printTab: {
                    ...this.state.printTab,
                    outputFormatID: newOutputformat
                  }
                })
              }
            }}
          />
        )}
        {showOutputChannelDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_outputchannelselector_dialog`}
            onClose={() => {
              this.setState({ showOutputChannelDefinitionSelectorDialog: false })
            }}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('import.outputchannel'),
              translate('general.type'),
              translate('general.title')
            ]}
            items={outputChannelDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputchannel = outputChannelDefinitions.data[selectedRows][outputChannelDefinitions.header.indexOf('DCR')]
                this.setState({
                  showOutputChannelDefinitionSelectorDialog: false,
                  printTab: {
                    ...this.state.printTab,
                    outputChannelID: newOutputchannel
                  }
                })
              }
            }}
          />
        )}
        {showPostProcessingNoteDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_postprocessingnoteselector_dialog`}
            onClose={() => this.setState({ showPostProcessingNoteDefinitionSelectorDialog: false })}
            title={translate('definition.post_processing_note_definition')}
            header={[
              translate('definition.post_processing_note'),
              translate('general.title')
            ]}
            items={postProcessingNoteDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newPostprocessingNode = postProcessingNoteDefinitions.data[selectedRows][postProcessingNoteDefinitions.header.indexOf('PPN')]
                this.setState({
                  showPostProcessingNoteDefinitionSelectorDialog: false,
                  printTab: {
                    ...this.state.printTab,
                    postprocessingNote: newPostprocessingNode
                  }
                })
              }
            }}
          />
        )}
        {showPTXSelectorDialog && (
          <SelectorDialog
            id={`${id}_ptx_encoding_selector_dialog`}
            onClose={() => this.setState({ showPTXSelectorDialog: false })}
            title={translate('definition.document_ptx_encoding_utf8')}
            header={[
              translate('general.code_page'),
              translate('general.title'),
              translate('general.ascii_ebcdic'),
              translate('general.utf8'),
            ]}
            items={DefinitionUtils.PTX_ENCODINGS}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const ptxEncoding = DefinitionUtils.PTX_ENCODINGS[selectedRows][0] !== ''
                  ? {
                    key: DefinitionUtils.PTX_ENCODINGS[selectedRows][0],
                    display: DefinitionUtils.PTX_ENCODINGS[selectedRows][0],
                    encoding: DefinitionUtils.PTX_ENCODINGS[selectedRows][2]
                  }
                  : {
                    key: '',
                    display: DefinitionUtils.PTX_ENCODINGS[selectedRows][1],
                    encoding: DefinitionUtils.PTX_ENCODINGS[selectedRows][2]
                  }
                this.setState({ showPTXSelectorDialog: false, attributesTab: { ...this.state.attributesTab, ptxEncoding } })
              }
            }}
          />
        )}
        {showFilterArgumentDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${this.props.id}_filterargumentselector_dialog`}
            onClose={() => this.setState({ showFilterArgumentDefinitionSelectorDialog: false })}
            title={translate('definition.filter_argument_definition')}
            header={[
              translate('definition.filter_argument_id'),
              translate('general.title')
            ]}
            items={filterArgumentDefinitions.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const newFilterArgumentID = filterArgumentDefinitions.data[selectedRows][filterArgumentDefinitions.header.indexOf('SLF')]
                this.setState({
                  showFilterArgumentDefinitionSelectorDialog: false,
                  filterTab: {
                    ...this.state.filterTab,
                    filterArgumentID: { value: newFilterArgumentID, error: '' }
                  }
                })
              }
            }}
          />
        )}
      </>
    )
  }

  render = () => {
    const { id, onClose, lang, copyLog } = this.props
    return (
      <>
        {this.renderSelectorDialogs()}
        <Modal onClose={onClose}
          id={id}
          className={'bux_UserProfileModal'}
          size={'l'}>
          <Header
            id={`${id}_modalHeader`}
            title={copyLog ? translate('definition.log_copy_modal_title') : translate('definition.log_create_modal_title')}
            onClose={onClose} />
          <Main id={id}>
            <Tabs
              id={id}
              lang={lang}
              disabledTabs={this.getDisabledTabs()}
              errorTabs={this.handleErrorTabs()}>
              <Tab title={translate('general.general')}>
                {this.renderGeneralTab()}
              </Tab>
              <Tab title={translate('documentinformation.retentiontab')}>
                {this.renderRetentionTab()}
              </Tab>
              <Tab title={translate('definition.attributes')}>
                {this.renderAttributesTab()}
              </Tab>
              <Tab title={translate('recipient.print')}>
                {this.renderPrintTab()}
              </Tab>
              <Tab title={translate('general.variables')}>
                {this.renderVariablesTab()}
              </Tab>
              <Tab title={translate('definition.document_filter')}>
                {this.renderFilterTab()}
              </Tab>
            </Tabs>
          </Main>
          <Footer>
            {this.props.prefilledData && !hasNoValues(this.props.prefilledData) &&
              <Button
                id={`${id}_resetbtn`}
                tooltip={translate('general.reset')}
                icon={'undo'}
                onClick={this.onReset}
              />
            }
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={`${id}_savebtn_iso88591`}
              text={translate('general.save_as_iso88591')}
              onClick={() => this.handleSave(false)}
              primary
              submit
            />
            <Button
              id={`${id}_savebtn_utf8`}
              text={translate('general.save_as_utf8')}
              onClick={() => this.handleSave(true)}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    datemask: state.auth.serverdata.preferences.DATEMASK,
    selector: state.selector,
    log: state.definitions.logs.log
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createLog: (logDefinition, callback) => {
      LogDefinitionActions.createLog(logDefinition, callback)(dispatch)
    },
    getOutputFormatDefinitions: (fields, outputFormat, callback) => {
      ModalSelectorActions.getOutputFormatDefinitions(
        fields,
        outputFormat,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getPostProcessingNotesDefinitions: (fields, ppn, callback) => {
      ModalSelectorActions.getPPNDefinitions(
        fields,
        ppn,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getFilterArgumentsDefinitions: (fields, filterArgument, callback) => {
      ModalSelectorActions.getFilterArgumentsDefinitions(
        fields,
        undefined,
        filterArgument,
        undefined,
        callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLogDialog)