import React, { Component } from 'react'
import PropTypes from 'prop-types'

import QueueBundle from './queue_bundle/QueueBundle'
import QueueIndex from './queue_index/QueueIndex'
import QueueLPD from './queue_lpd/QueueLPD'
import QueueOutput from './queue_output/QueueOutput'
import QueueReload from './queue_reload/QueueReload'

class QueueBody extends Component {

  getBody = () => {
    const { id } = this.props
    switch (this.props.queueMode) {
      case 'bundle': return <QueueBundle id={`${id}_bundle_queue`} />
      case 'index': return <QueueIndex id={`${id}_index_queue`} />
      case 'lpd': return <QueueLPD id={`${id}_lpd_queue`} />
      case 'output': return <QueueOutput id={`${id}_output_queue`} />
      case 'reload': return <QueueReload id={`${id}_reload_queue`} />
      // should not happen
      default: return null
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}

QueueBody.propTypes = {
  id: PropTypes.string.isRequired,
}

export default QueueBody