import * as TranslationDe from './German'
import * as TranslationEn from './English'
import * as TranslationFr from './French'
import * as OverrideLogxKeys from './logxSpecificKeys'

import store from 'redux/Store'
import * as Preferences from 'redux/general/Preferences'
import * as Config from 'config/Configs'
import * as UserUtils from 'utils/UserUtils'

const logxTranslationsDE = { ...TranslationDe.TRANSLATIONS, ...OverrideLogxKeys.TRANSLATIONS, ...OverrideLogxKeys.TRANSLATIONS_DE }
const logxTranslationsEN = { ...TranslationEn.TRANSLATIONS, ...OverrideLogxKeys.TRANSLATIONS, ...OverrideLogxKeys.TRANSLATIONS_EN }

export const getLanguageKey = value => {
  let lang
  let values
  let keys
  if (store.getState().auth.serverdata) {
    lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
  }

  if (lang === GERMAN) {
    values = Object.values(TranslationDe.TRANSLATIONS)
    keys = Object.keys(TranslationDe.TRANSLATIONS)
  }
  else if (lang === ENGLISH) {
    values = Object.values(TranslationEn.TRANSLATIONS)
    keys = Object.keys(TranslationEn.TRANSLATIONS)
  }
  else if (lang === FRENCH) {
    values = Object.values(TranslationFr.TRANSLATIONS)
    keys = Object.keys(TranslationFr.TRANSLATIONS)
  }
  let valueIndex = values.indexOf(value)
  return keys[valueIndex]
}

export function translateRC(rc, irc = 0, lang, params) {

  // rc and irc should have a length of 4 -> fill start with zero
  if (rc === undefined) {
    rc = '0000'
  }
  else if (typeof rc !== typeof String) {
    rc = rc.toString().padStart(4, '0')
  }
  else {
    rc = rc.padStart(4, '0')
  }
  if (typeof irc !== typeof String) {
    irc = irc.toString().padStart(4, '0')
  }
  else {
    irc = irc.padStart(4, '0')
  }
  return translate(rc.concat(irc), lang, params)
}

export function translate(key, lang = undefined, params = undefined, strict = Config.LANGUAGE_STRICT_MODE, consoleLog = true) {
  if (!lang) {
    if (store.getState().auth.serverdata) {
      lang = store.getState().auth.serverdata.preferences[Preferences.LANGUAGE]
    }
  }
  let translation = null
  switch (lang) {
    case GERMAN: translation = translateGerman(key)
      break
    case ENGLISH: translation = translateEnglish(key)
      break
    case FRENCH: translation = translateFrench(key)
      break
    default: translation = translateEnglish(key)
  }

  // if the key does not exist, use english translation
  if (!translation) {
    if (strict) {
      throw Error(`language key does not exist: ${key}`)
    }
    if (lang !== ENGLISH && lang !== '') {
      consoleLog && console.warn(`Translation for <${key}> does not exist in language <${lang}>.`)
      translation = translateEnglish(key)

      // all translation must be available in english
      if (!translation)
        consoleLog && console.error(`Translation for <${key}> does not exist in language <${ENGLISH}>.`)
    }
    // all translation must be available in english
    else {
      consoleLog && console.error(`Translation for <${key}> does not exist in language <${ENGLISH}>.`)
    }
    // Return key instead of translations if there are no translations at all. It will help locate the missing translation
    if (!translation) translation = key
  }

  return resolveParams(translation, params)
}

export function translateNoError(key) {
  return translate(key, undefined, undefined, false, false);
}

function translateGerman(key) {
  let translation = TranslationDe.TRANSLATIONS
  if (UserUtils.isLOGX()) {
    return logxTranslationsDE[key]
  }
  return translation[key]
}

function translateEnglish(key) {
  let translation = TranslationEn.TRANSLATIONS
  if (UserUtils.isLOGX()) {
    return logxTranslationsEN[key]
  }
  return translation[key]
}

function translateFrench(key) {
  return TranslationFr.TRANSLATIONS[key]
}

function resolveParams(translation, params) {
  if (!params || !translation)
    return translation

  // we also accept a single string which we have to convert to an array to use map
  let paramArray
  if (!(params instanceof Array)) {
    paramArray = [params]
  }
  else {
    paramArray = params
  }
  // replace all $ values with the corresponding params
  // TODO: take care of > 9 params -> $1 will be found on $10
  paramArray.forEach((param, i) => {
    // Fallback if a route do not returns the known structure like "success", "error", "additionalInfo" etc.
    if (typeof param === 'string') {
      // ! Special replace for dollar signs https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_string_as_a_parameter
      // ! $$ -> $ means two replace of $ requires four $
      param = param.split('$').join('$$')
      translation = translation.replace(`$${i}`, param)
    }
  })
  return translation
}

export const GERMAN = 'de'
export const ENGLISH = 'en'
export const FRENCH = 'fr'