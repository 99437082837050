import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Style
import './Progressbar.scss'

export default class Progressbar extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Value of the progressbar .*/
    value: PropTypes.number.isRequired,
    /** Maximum value.*/
    max: PropTypes.number.isRequired,
    /** Label to be displayed above the progressbar.*/
    title: PropTypes.string,
    /**
     * Function to format the displayed value of the Progressbar (fullness)
     * @param {number} number
     */
    textFormatter: PropTypes.func,
    /** Color settings */
    color: PropTypes.shape({
      foreground: PropTypes.string,
      background: PropTypes.string,
      font: PropTypes.string,
    })
  }

  state = {
    progress: 0
  }

  /**
   * @description Set initial state value.
   */
  componentDidMount = () => {
    const { max, value } = this.props
    this.setState({
      progress: (max !== 0 && value !== 0) ? (value / max * 100).toFixed(2) : 0
    })
  }

  /**
   * @description Updates the state value.
   */
  componentDidUpdate = prevProps => {
    if (prevProps.value !== this.props.value) {
      const { max, value } = this.props
      this.setState({
        progress: (max !== 0 && value !== 0) ? (value / max * 100).toFixed(2) : 0
      })
    }
  }

  /**
   * @description Translates the given background color to the new color with the opacity 0.55. This is needed, because the IE can't handle rgba and a general opacity of 0.55 for
   *  the element would affect the child elements.
   * @param {String} hex The hex value of the color e.g. #ffffff. Needs to be 7 digits long.
   * @returns {String} hex value of the color
   */
  useOpacityOnColor = hex => {
    let val1 = hex.substring(1, 3)
    let val2 = hex.substring(3, 5)
    let val3 = hex.substring(5, 7)
    val1 = Math.floor(255 - 0.55 * (255 - this.toDecimal(val1)))
    val2 = Math.floor(255 - 0.55 * (255 - this.toDecimal(val2)))
    val3 = Math.floor(255 - 0.55 * (255 - this.toDecimal(val3)))
    val1 = this.toHex(val1)
    val2 = this.toHex(val2)
    val3 = this.toHex(val3)
    return `#${val1}${val2}${val3}`
  }

  /**
   * @description Translates a two-digit hex value into decimal number.
   * @param {Number} hex The hex value.
   * @returns {Number}
   */
  toDecimal = hex => {
    let allNums = hex.split('')
    let calcedNumber = 0
    allNums.forEach((num, i) => {
      let lastNumber = i === allNums.length - 1
      switch (num) {
        case 'a':
          calcedNumber += 10 * (!lastNumber ? (allNums.length - 1 - i) * 16 : 1)
          break
        case 'b':
          calcedNumber += 11 * (!lastNumber ? (allNums.length - 1 - i) * 16 : 1)
          break
        case 'c':
          calcedNumber += 12 * (!lastNumber ? (allNums.length - 1 - i) * 16 : 1)
          break
        case 'd':
          calcedNumber += 13 * (!lastNumber ? (allNums.length - 1 - i) * 16 : 1)
          break
        case 'e':
          calcedNumber += 14 * (!lastNumber ? (allNums.length - 1 - i) * 16 : 1)
          break
        case 'f':
          calcedNumber += 15 * (!lastNumber ? (allNums.length - 1 - i) * 16 : 1)
          break
        default:
          calcedNumber += parseInt(num) * (!lastNumber ? (allNums.length - 1 - i) * 16 : 1)
          break
      }
    })
    return calcedNumber
  }

  /**
   * @description Translates a decimal number into a two-digit hex value.
   * @param {Number} num The decimal number.
   * @returns {String} hex value
   */
  toHex = num => {
    let result = []
    while (num > 16) {
      result.push(this.translateSingleNumToHex(num % 16))
      num = Math.floor(num / 16)
    }
    result.push(this.translateSingleNumToHex(num))
    return result.reverse().join('')
  }

  /**
   * @description Translates a decimal number which is smaller than 16 to the hex value.
   * @param {Number} num The decimal number.
   * @returns {Number|String} default string else number
   */
  translateSingleNumToHex = num => {
    let result
    switch (num) {
      case 10:
        result = 'a'
        break
      case 11:
        result = 'b'
        break
      case 12:
        result = 'c'
        break
      case 13:
        result = 'd'
        break
      case 14:
        result = 'e'
        break
      case 15:
        result = 'f'
        break
      default:
        result = num
    }
    return result
  }

  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, title, textFormatter, color = { foreground: '#00386e', background: '#ffffff', font: '#f55555' } } = this.props
    const { progress } = this.state
    return (
      <div className={'bux_state'}>
        {title && (
          <label id={`${id}_state_label`} className='control-label el_input_label'>
            {title}
          </label>
        )}
        <Progress
          id={`${id}_state`}
          progress={progress}
          textFormatter={textFormatter}
          color={{
            foreground: color.foreground,
            background: this.useOpacityOnColor(color.background),
            font: color.font
          }}
        />
      </div>
    )
  }
}

const Progress = ({ id, progress, color, textFormatter }) => {
  return (
    <div className='bux_state-wrapper' style={{ backgroundColor: color.background }}>
      <div className='bux_state-progress_bar' style={{ backgroundColor: color.foreground, width: `${progress}%` }} />
      <div id={`${id}_progress_value`} className='bux_state-value' style={{ color: color.font }}>{textFormatter ? textFormatter(progress) : progress}%</div>
    </div>
  )
}

// !GUB react.width !== text.width therefore the color transition is asynchronous.
// TODO: fix Bug above for performance saving
// const Progress = ({ id, value }) => {
//   return (
//     <div id={`${id}_progressbar_wrapper`} className='wrapper'></div>
//       <svg id={`${id}_progress`} height='100%' width='100%'>
//         <defs>
//           <linearGradient id='progress'>
//             <stop
//               offset={`${value}%`}
//               stop-color='#ffffff'
//             />
//             <stop
//               offset={`${value}%`}
//               stop-color='#002140'
//             />
//           </linearGradient>
//           <linearGradient id='progressbar'>
//             <stop
//               offset={`${value}%`}
//               stop-color='#002140'
//             />
//             <stop
//               offset={`${value}%`}
//               stop-color='#ffffff'
//             />
//           </linearGradient>
//         </defs>
//         <g>
//           <rect
//             id={`${id}_progress_bar`}
//             fill='url(#progressbar)'
//             width='100%'
//             height='100%'
//           />
//           <text
//             id={`${id}_progress_state`}
//             x='50%' y='50%'
//             fill='url(#progress)'
//             dominant-baseline='middle'
//             text-anchor='middle'>
//             {`${value}%`}
//           </text>
//         </g>
//       </svg>
//     </div>
//   )
// }