import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Redirect } from 'react-router-dom'

import SearchResultServerAccounting from './accounting/SearchResultServerAccounting'
import SearchResultServerExternalCommand from './external_command/SearchResultServerExternalCommand'
import SearchResultServerLogFile from './server_log_file/SearchResultServerLogFile'
import SearchResultServerMaintenance from './maintenance/SearchResultServerMaintenance'
import SearchResultServerMessageLog from './message_log/SearchResultServerMessageLog'
import SearchResultServerPrinterDeviceInfo from './printer_device_info/SearchResultServerPrinterDeviceInfo'
import SearchResultServerSystemDefaults from './system_defaults/SearchResultServerSystemDefaults'
import SearchResultArchiveFile from './archive_file/SearchResultArchiveFile'
import SearchResultLpdStatus from './lpd_status/SearchResultLpdStatus'
import SearchResultOutputProcesses from './output_processes/SearchResultOutputProcesses'

// Redux
import { connect } from 'react-redux'
// Utils
import * as UserUtils from 'utils/UserUtils'

class SearchResultServer extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    match: PropTypes.any.isRequired,
    drawerExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
  }

  handleDefault = () => {
    // || {} is required to get undefined for the destructed variables when userprofile is undefined
    const { VCIENMSG, VCIENUTL, VCIENPRM } = this.props.userprofile || {}

    if (UserUtils.isAdmin()) {
      return <Redirect to='/server/messagelog' /> // for admin Messagelog is default
    }
    else {
      if (VCIENMSG) {
        // Not admin but allowed to see messagelog
        return <Redirect to='/server/messagelog' />
      }
      else if (VCIENUTL) {
        // Not admin, not messagelog, but extern.cmd allowed
        return <Redirect to='/server/externalcmd' />
      }
      else if (VCIENPRM) {
        // Not admin but allowed to see output processes
        return <Redirect to='/server/outputprocesses' />
      }
      else {
        // Fallback when not access to /server-features
        return <Redirect to='/search/standardselection' />
      }
    }
  }

  getBody = () => {
    const { match, id, drawerExpanded, autoDrawer } = this.props
    let url = match.url
    // removes first slash
    url = url.substring(1, url.length)
    // cuts out the main url part of url for better comparisation
    if (url.indexOf('/') !== -1) {
      url = url.substring(url.lastIndexOf('/') + 1)
    }
    switch (url) {
      case 'maintenance':
        return (
          <SearchResultServerMaintenance
            id={`${id}_maintenance`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'externalcmd':
        return (
          <SearchResultServerExternalCommand
            id={`${id}_externalcmd`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'accounting':
        return (
          <SearchResultServerAccounting
            id={`${id}_accounting`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'messagelog':
        return (
          <SearchResultServerMessageLog
            id={`${id}_messagelog`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'serverlogfile':
        return (
          <SearchResultServerLogFile
            id={`${id}_logfile`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'printerdeviceinfo':
        return (
          <SearchResultServerPrinterDeviceInfo
            id={`${id}_printerdeviceinfo`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'systemdefaults':
        return (
          <SearchResultServerSystemDefaults
            id={`${id}_systemdefaults`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'archivefile':
        return (
          <SearchResultArchiveFile
            id={`${id}_archivefile`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'lpdstatus':
        return (
          <SearchResultLpdStatus
            id={`${id}_lpdstatus`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      case 'outputprocesses':
        return (
          <SearchResultOutputProcesses
            id={`${id}_outputprocesses`}
            drawerExpanded={drawerExpanded}
            autoDrawer={autoDrawer}
          />
        )
      // fallback is database status
      default: return this.handleDefault()
    }
  }

  render = () => {
    return (
      this.getBody()
    )
  }
}


const mapStateToProps = state => {
  return {
    userprofile: state.auth.serverdata && state.auth.serverdata.userprofile
  }
}


export default connect(mapStateToProps)(SearchResultServer)