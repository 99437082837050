import * as Lang from 'language/Language'
import * as Config from 'config/Configs'

import store from 'redux/Store'
import * as GeneralErrorHandler from 'redux/actions/GeneralErrorHandler'
import * as FetchTimeout from 'redux/actions/FetchTimeout'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as LoadingSpinnerActions from 'redux/actions/LoadingSpinnerActions'
import * as Preferences from 'redux/general/Preferences'
import { restapiRequest } from 'utils/RequestUtils'

export const GET_UC4_JOBS_START = 'GET_UC4_JOBS_START'
export const GET_UC4_JOBS_SUCCESS = 'GET_UC4_JOBS_SUCCESS'
export const GET_UC4_JOBS_FAILED = 'GET_UC4_JOBS_FAILED'
export const NO_UC4_JOBS_FOUND = 'NO_UC4_JOBS_FOUND'

export const GET_UC4_JOB_START = 'GET_UC4_JOB_START'
export const GET_UC4_JOB_SUCCESS = 'GET_UC4_JOB_SUCCESS'
export const GET_UC4_JOB_FAILED = 'GET_UC4_JOB_FAILED'

export const ZOS_JOBS_DOWNLOAD_LOG_START = 'ZOS_JOBS_DOWNLOAD_LOG_START'
export const ZOS_JOBS_DOWNLOAD_LOG_SUCCESS = 'ZOS_JOBS_DOWNLOAD_LOG_SUCCESS'
export const ZOS_JOBS_DOWNLOAD_LOG_FAILED = 'ZOS_JOBS_DOWNLOAD_LOG_FAILED'

export function getUC4Jobs(fields, searchObj, callback, keepPagination = false) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_UC4_JOBS_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(searchObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/jobs?${queryParams.join('&')}`, {
        method: 'get',
      })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          // check specific errors
          if (rc === '0016' && irc === '0000') {
            SnackbarActions.show(Lang.translateRC(rc, irc, lang, jsondata.error.param), SnackbarActions.TYPE_INFO)(dispatch)
            dispatch({ type: NO_UC4_JOBS_FOUND })
          } else {
            // check general errors
            let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)
            SnackbarActions.show(error.message, error.type)(dispatch)
            dispatch({ type: GET_UC4_JOBS_FAILED })
          }
        }
        else {
          dispatch({ type: GET_UC4_JOBS_SUCCESS, payload: jsondata.data, keepPagination })

          // call the callback on success
          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.uc4_loading_error', lang, reason), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_UC4_JOBS_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

export function getUC4JobDetails(fields, jobObj, jobname, callback) {
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)

    const prefs = store.getState().auth.serverdata.preferences
    // get the language from redux
    const lang = prefs[Preferences.LANGUAGE]

    dispatch({ type: GET_UC4_JOB_START })

    const queryParams = []
    if (fields) { queryParams.push(`FIELDS=${fields}`) }
    for (const [key, value] of Object.entries(jobObj)) {
      if (value !== '') {
        queryParams.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(`${Config.REST_API_URL}/api/jobs/job?${queryParams.join('&')}`, { method: 'get' })
    )

    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.FETCH_DATA_TIMEOUT)

    cancelablePromise
      .promise
      .then(response => response.json())
      .then(jsondata => {
        if (!jsondata.success) {
          let rc = jsondata.error.rc
          let irc = jsondata.error.irc

          let error = GeneralErrorHandler.handleResponse(rc, irc, jsondata.error.param, dispatch)

          SnackbarActions.show(error.message, error.type)(dispatch)
          dispatch({ type: GET_UC4_JOB_FAILED, payload: { error } })
          LoadingSpinnerActions.hide()(dispatch)
        }
        else {
          dispatch({ type: GET_UC4_JOB_SUCCESS, payload: jsondata.data })

          if (callback) {
            callback()
          }
        }

        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('search.uc4_details_error', lang, [jobname, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: GET_UC4_JOB_FAILED })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Calls the rest api and downloads a single log.
 * @param {Object} queryParams Query params which are used to download the log
 * @param {Object} downloadname Name the save should be saved with
 * @param {Function} callback
 */
export function downloadLog(queryParams, downloadname, callback) {
  const usertoken = store.getState().auth.serverdata.token
  let url = `${Config.REST_API_URL}/api/jobs/job/download?USERTOKEN=${usertoken}&MIMETYPE=save`
  for (const [key, value] of Object.entries(queryParams)) {
    url += `&${key}=${encodeURIComponent(value)}`
  }
  if (downloadname) {
    url+= `&RESFILENAME=${encodeURIComponent(downloadname)}`
  }
  return dispatch => {
    LoadingSpinnerActions.show()(dispatch)
    // get the language from redux
    dispatch({ type: ZOS_JOBS_DOWNLOAD_LOG_START })
    // create cancelable promise
    const cancelablePromise = FetchTimeout.makeCancelable(
      restapiRequest(url, {
        method: 'get',
        headers: {
          token: undefined
        }
      })
    )

    // set timeout for fetching data
    setTimeout(() => {
      cancelablePromise.cancel()
    }, Config.DOWNLOAD_TIMEOUT)

    cancelablePromise
      .promise
      .then(data => {
        // If data.status 200, document will be returned binary
        if (data.status === 200) {

          // Open download dialog:
          // Create a-tag with href, simulate click and delete it afterward.
          let element = document.createElement('a')
          element.setAttribute('href', data.url)
          element.style.display = 'none'
          document.body.appendChild(element)
          element.click()
          document.body.removeChild(element)

          dispatch({ type: ZOS_JOBS_DOWNLOAD_LOG_SUCCESS })
        }
        else { // Else we expect the result (error) in a json stream
          data.json()
            .then(json => {
              let rc = json.error.rc
              let irc = json.error.irc
              // check general errors
              let error = GeneralErrorHandler.handleResponse(rc, irc, json.error.param, dispatch)
              SnackbarActions.show(error.message, error.type)(dispatch)
              dispatch({ type: ZOS_JOBS_DOWNLOAD_LOG_FAILED, payload: { error } })
            })
          if (callback) {
            callback()
          }
        }
        LoadingSpinnerActions.hide()(dispatch)
      })
      .catch(error => {
        let reason = error.toString()
        // show fetch data timeout as error message if promise was canceled
        if (error.isCanceled) {
          reason = Lang.translate('general.fetch_data_timeout')
        }
        SnackbarActions.show(Lang.translate('documents.documents_error', undefined, [process, reason]), SnackbarActions.TYPE_ERROR)(dispatch)
        dispatch({ type: ZOS_JOBS_DOWNLOAD_LOG_FAILED, payload: { error } })
        LoadingSpinnerActions.hide()(dispatch)
      })
  }
}

/**
 * @description Build and opens the url to open the document in _bet aview.
 * @param {String} betaviewBaseurl URL of _beta view: e.g. http://server.com/betaview
 * @param {String} betaviewFavname Favoritename of _beta view which is connected to the given bux-server
 * @param sdate
 * @param stime
 * @param sysname
 * @param runnumber
 * @param ukey
 * @param {Function} callback
 */
export function displayDocument(betaviewBaseurl, betaviewFavname, sdate, stime, sysname, runnumber, ukey, callback) {
  return dispatch => {
    if (!betaviewBaseurl || !betaviewFavname) { // Baseurl missing
      SnackbarActions.show(Lang.translate('general.betaview_not_configured'), SnackbarActions.TYPE_ERROR)(dispatch)
    }
    else { // Config given

      const token = encodeURIComponent(store.getState().auth.serverdata.token)
      const encodedUkey = encodeURIComponent(ukey)
      const bweurl = `${betaviewBaseurl}${betaviewBaseurl[betaviewBaseurl.length - 1] === '/' ? '' : '/'}opendocumentlink.action?utoken=${token}&ukey=${encodedUkey}&favid=${betaviewFavname}&datemask=DD.MM.YYYY`
      const windowid = 'docbrowser@bwequ4711:8080/betaview'
      window.open(bweurl, windowid)

      if (callback) {
        callback()
      }
    }
  }
}