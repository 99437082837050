import { translate } from 'language/Language'; // Translation
import PropTypes from 'prop-types';
import { Component } from 'react';

// Components
import {
  Button, Card, Column, DataTable, MetaDataGrid, Modal as ModalComponent,
  Row
} from 'BetaUX2Web-Components/src/';

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux';
import * as Preferences from 'redux/general/Preferences';
import * as Utils from 'utils/Utils';

class UserSecurityProfileDialog extends Component {

  /**
   * @description Gets the translated titles of the columns.
   */
  getListTitle = () => {
    return [
      translate('security.profile_mask'),
      translate('security.user_group'),
      translate('security.access'),
      translate('general.description'),
    ]
  }

  /**
   * @description Gets the right translation for access.
   * @param {String} access the access value.
   */
  getAccessTranslation = (access) => {
    const translations = {
      NONE: translate('security.access_none'),
      READ: translate('security.access_read'),
      ALTER: translate('security.access_alter'),
      UPDATE: translate('security.access_update')
    }

    const translated = translations[access]
    return translated
  }

  /**
   * @description Gets the information to show in the list.
   */
  getListAssignedData = () => {
    const { userSecurityAssigments } = this.props

    // return an empty array when there is no data
    if (userSecurityAssigments) {
      if (userSecurityAssigments.data === undefined) {
        return []
      }
      const entries = []
      userSecurityAssigments.data.map(entry => {
        return entries.push([entry[0], entry[1], this.getAccessTranslation(entry[2]), entry[3]])
      })
      return entries
    } else {
      return []
    }
  }

  /**
   * @description Renders the header which includes non editable information.
   */
  renderHeader = () => {
    const { id, userToModify } = this.props

    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('user.user_id'), value: userToModify.BETAUSER },
          { label: translate('user.username'), value: userToModify.USERNAME },
          { label: translate('general.last_login'), value: `${userToModify.LLOGDATE} ${userToModify.LLOGTIME.substring(0, 8)}` },
          { label: translate('general.last_changed'), value: `${userToModify.CDATE} ${userToModify.CTIME.substring(0, 8)}` },
        ]}
        columns={4}
      />
    )
  }

  render = () => {
    const { id, lang, onClose, datemask } = this.props

    return (
      <Modal onClose={onClose} id={`${id}_assignedSecurityProfiles`}>
        <Header
          id={`${id}_assignedSecurityProfiles`}
          title={translate('general.assignedSecurityProfiles')}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main id={`${id}_assignedSecurityProfiles`}>
          <Card className={Utils.getSpecificContainerStyle()}>
            <Row className={'bux_modal_container'}>
              <Column colMD={12} className={'bux_full_height'}>
                <DataTable
                  id='userSecurityProfilesDatalist'
                  header={this.getListTitle()}
                  data={this.getListAssignedData()}
                  columnSortDefs={['string', 'string', 'string', 'string']}
                  noAction
                  language={lang}
                  datemask={datemask}
                  translate={key => translate(key)}
                  menu={false}
                />
              </Column>
            </Row>
          </Card>
        </Main>
        <Footer id={`${id}_assignedSecurityProfiles`}>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.close')}
            onClick={onClose}
          />
        </Footer>
      </Modal>
    )
  }
}

UserSecurityProfileDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    userToModify: state.user.user,
    allGroups: state.group.groups,
    userSecurityAssigments: state.usersecurityassignment.userSecurityAssignments,
  }
}

export default connect(mapStateToProps)(UserSecurityProfileDialog)