import * as JobgroupsDefinitionActions from '../actions/JobgroupsDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  jobgroups: undefined,
  jobgroup: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    case JobgroupsDefinitionActions.GET_JOBGROUPS_DEFINITION_START:
      return { ...state }
    case JobgroupsDefinitionActions.GET_JOBGROUPS_DEFINITION_SUCCESS:
      return { ...state, jobgroups: action.payload, keepPagination: action.keepPagination }
    case JobgroupsDefinitionActions.GET_JOBGROUPS_DEFINITION_FAILED:
      return { ...state }
    case JobgroupsDefinitionActions.NO_JOBGROUPS_DEFINITION_FOUND:
      return { ...state, jobgroups: {} }
    /* ********************************************************************************** */
    case JobgroupsDefinitionActions.GET_JOBGROUP_DEFINITION_START:
      return { ...state, jobgroup: undefined }
    case JobgroupsDefinitionActions.GET_JOBGROUP_DEFINITION_SUCCESS:
      return { ...state, jobgroup: action.payload }
    case JobgroupsDefinitionActions.GET_JOBGROUP_DEFINITION_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    case AuthActions.LOGOUT_SUCCESS:
      return defaultState
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer