
import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as DefinitionUtils from 'utils/DefinitionUtils'

// components
import {
  Button,
  Column, Dropdown, Input,
  Modal as ModalComponent, NumericSpinner, Row, Switch, Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import * as JobgroupsDefinitionActions from 'redux/actions/JobgroupsDefinitionActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'

import './CreateJobgroupDialog.scss'
import _ from 'lodash'
import { hasNoValues } from 'utils/ObjectUtils'

class CreateJobgroupDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    prefilledData: PropTypes.object,
    onClose: PropTypes.func.isRequired
  }

  defaultState = {
    generalTab: {
      jobgroupName: { value: '', error: '' },
      owner: { value: '', error: '' },
      title: { value: '', error: '' },
      archiveRetention: 0,
      onlineRetention: 0,
      archiveMedia: 0,
      type: 0

    },
    jobgroupMasksTab: {
      groupMasks: []
    }
  }
  state = _.cloneDeep(this.defaultState)

  jobgroupNameInput = React.createRef()
  titleInput = React.createRef()

  /**
   * @description Sets the initial focus.
   */
  componentDidMount = () => {
    if (this.props.prefilledData) {
      const data = this.props.prefilledData;
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          jobgroupName: { value: data.JGIGNAME ?? '', error: '' },
          owner: { value: data.OWNER ?? '', error: '' },
          title: { value: data.JGITITLE ?? '', error: '' },
        },
      })
    }

    this.jobgroupNameInput.current && this.jobgroupNameInput.current.focus()
  }

  /**
   * @description Validates the filter argument id.
   */
  validateInputIfEmpty = key => {
    const { generalTab } = this.state
    if (generalTab[key].value !== '') {
      return {}
    }
    return {
      [key]: {
        ...generalTab[key],
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Handles the input changes of the general tab.
   * @param {String} key The key of the input field.
   * @param {String} value The new value.
   */
  handleGeneralTabInputChanged = (key, value) => {
    const { generalTab } = this.state
    this.setState({
      generalTab: {
        ...generalTab,
        [key]: typeof generalTab[key] === 'object'
          ? {
            value: value,
            error: '',
          }
          : value
      }
    })
  }

  /**
   * @description Renders the general tab
   */
  renderGeneralTab = () => {
    const { id } = this.props
    const { generalTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_jobgroup_name`}
              ref={this.jobgroupNameInput}
              title={translate('general.jobgroup_name')}
              value={generalTab.jobgroupName.value}
              error={generalTab.jobgroupName.error}
              onInputChanged={value => this.handleGeneralTabInputChanged('jobgroupName', value)}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateInputIfEmpty('jobgroupName') } }))}
              maxLength={16}
              required={`${translate('general.required_field')}`}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_owner`}
              value={generalTab.owner.value}
              title={translate('general.owner')}
              maxLength={8}
              onInputChanged={val => this.handleGeneralTabInputChanged('owner', val)}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_title`}
              ref={this.titleInput}
              value={generalTab.title.value}
              error={generalTab.title.error}
              title={translate('general.title')}
              onInputChanged={val => this.handleGeneralTabInputChanged('title', val)}
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateInputIfEmpty('title') } }))}
              maxLength={64}
              required={`${translate('general.required_field')}`}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_archive_retention`}
              title={translate('documentinformation.retentiontab_archiveretention')}
              value={generalTab.archiveRetention}
              onChange={val => this.handleGeneralTabInputChanged('archiveRetention', val)}
              min={0}
              max={30000}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_online_retention`}
              title={translate('documentinformation.onlineretention')}
              value={generalTab.onlineRetention}
              onChange={val => this.handleGeneralTabInputChanged('onlineRetention', val)}
              min={0}
              max={9999}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_archive_media`}
              title={translate('general.archive_media')}
              items={DefinitionUtils.archiveMediaItems(false).map(d => generalTab.archiveRetention === 0
                ? translate('general.none')
                : translate(d.translationKey))}
              activeIndex={generalTab.archiveMedia}
              onChange={index => this.handleGeneralTabInputChanged('archiveMedia', index)}
              disabled={generalTab.archiveRetention === 0}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_type`}
              title={translate('general.type')}
              items={DefinitionUtils.JOBGROUP_DEFINITION_TYPES.map(d => translate(d.translationKey))}
              activeIndex={generalTab.type}
              onChange={index => this.handleGeneralTabInputChanged('type', index)}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Adds a new group mask entry at the bottom with default values.
   */
  addGroupMask = () => {
    const { jobgroupMasksTab } = this.state
    const el = { filenameOrMask: { value: '', error: '' }, exclusive: 1, ref: React.createRef() }
    this.setState({ jobgroupMasksTab: { ...jobgroupMasksTab, groupMasks: [...jobgroupMasksTab.groupMasks, el] } })
  }

  /**
   * @description Copies a group mask and add it at the end.
   * @param {String} filenameOrMask
   * @param {Number} exclusive
   */
  copyGroupMask = (filenameOrMask, exclusive) => {
    const { jobgroupMasksTab } = this.state
    const el = { filenameOrMask: { value: filenameOrMask.value, error: '' }, exclusive, ref: React.createRef() }
    this.setState({ jobgroupMasksTab: { ...jobgroupMasksTab, groupMasks: [...jobgroupMasksTab.groupMasks, el] } })
  }

  /**
   * @description Deletes a group mask entry.
   * @param {Number} index
   */
  deleteGroupMask = index => {
    const { jobgroupMasksTab } = this.state
    this.setState({ jobgroupMasksTab: { ...jobgroupMasksTab, groupMasks: jobgroupMasksTab.groupMasks.filter((_, i) => i !== index) } })
  }

  /**
   * @description Handles the change of a value inside the jobgroup masks tab.
   * @param {String} key
   * @param {Number} index
   * @param {String} value
   */
  changeGroupMaskValue = (key, index, value) => {
    const { jobgroupMasksTab } = this.state
    let buffer = [...jobgroupMasksTab.groupMasks]
    buffer[index][key] = typeof buffer[index][key] === 'object' ? { value, error: '' } : value
    this.setState({
      jobgroupMasksTab: {
        ...jobgroupMasksTab,
        groupMasks: buffer
      }
    })
  }

  /**
   * @description Validates the filename or mask input field.
   * @param {Number} index
   */
  validateFilenameOrMask = index => {
    const { jobgroupMasksTab } = this.state
    const buffer = jobgroupMasksTab.groupMasks
    if (buffer[index] && buffer[index].filenameOrMask.value === '') {
      buffer[index].filenameOrMask = { value: '', error: translate('general.input_required') }
    }
    return buffer
  }

  /**
   * @description Renders the UI for the group masks tab.
   */
  renderGroupMasks = () => {
    const { id } = this.props
    return this.state.jobgroupMasksTab.groupMasks.map((d, i) => {
      return (
        <Row key={i}>
          <Column colMD={6}>
            <Input
              id={`${id}_file_name_or_mask_${i}`}
              ref={d.ref}
              title={i === 0 && translate('general.file_name_or_mask')}
              value={d.filenameOrMask.value}
              error={d.filenameOrMask.error}
              onInputChanged={value => this.changeGroupMaskValue('filenameOrMask', i, value)}
              onBlur={() => this.setState(state => ({ jobgroupMasksTab: { ...state.jobgroupMasksTab, groupMasks: [...this.validateFilenameOrMask(i)] } }))}
              maxLength={80}
              required={i === 0 ? `${translate('general.required_field')}` : false}
            />
          </Column>
          <Column colMD={4}>
            <Switch
              id={`${id}_exclusive_${i}`}
              title={i === 0 ? translate('general.exclusive') : ' '}
              activeIndex={d.exclusive}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.changeGroupMaskValue('exclusive', i, index)}
            />
          </Column>
          <Column colMD={1}>
            <Button
              id={`${id}_copy_group_mask_${i}`}
              className={'bux_move_group_mask_button'}
              icon={'copy'}
              tooltip={translate('general.copy_group_mask')}
              onClick={() => this.copyGroupMask(d.filenameOrMask, d.exclusive)}
            />
          </Column>
          <Column colMD={1}>
            <Button
              id={`${id}_delete_group_mask_${i}`}
              className={'bux_move_group_mask_button'}
              icon={'delete'}
              tooltip={translate('general.delete_group_mask')}
              onClick={() => this.deleteGroupMask(i)}
            />
          </Column>
        </Row>
      )
    })
  }

  /**
   * @description Renders the the formula tab
   */
  renderJobgroupMasksTab = () => {
    const { id } = this.props
    const { jobgroupMasksTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={3}>
            <label id={`${id}_group_masks_label`} className={'bux_center_headline'}>{translate('general.group_masks')}</label>
          </Column>
          <Column colMD={3} offsetMD={6}>
            <div className={'bux_place_add_group_mask_btn'}>
              {
                <Button
                  id={`${id}_add_group_mask`}
                  icon={'add'}
                  tooltip={translate('general.add_group_mask')}
                  onClick={() => this.addGroupMask()}
                />
              }
            </div>
          </Column>
        </Row>
        {jobgroupMasksTab.groupMasks.length > 0 && this.renderGroupMasks()}
      </>
    )
  }

  /**
   * @description Validates the general tab.
   * @returns {Boolean} False if there is an error in that tab.
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateInputIfEmpty('jobgroupName'),
      ...this.validateInputIfEmpty('title'),
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ generalTab: { ...this.state.generalTab, ...validatorResult } }, () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input with an error in general tab.
   */
  handleFocusGeneralTab = () => {
    const { generalTab } = this.state
    const requiredInputs = [
      { inputRef: this.jobgroupNameInput, errorkey: generalTab.jobgroupName.error },
      { inputRef: this.titleInput, errorkey: generalTab.title.error },
    ]
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].errorkey !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  /**
   * @description Validates the group masks inside the jobgroup masks tab.
   */
  validateGroupMasks = () => {
    const { jobgroupMasksTab } = this.state
    let buffer = [...jobgroupMasksTab.groupMasks]
    let error = false
    buffer.forEach(d => {
      if (d.filenameOrMask.value === '') {
        d.filenameOrMask = { value: '', error: translate('general.input_required') }
        error = true
      }
    })
    if (error) {
      return buffer
    }
    return []
  }

  /**
   * @description Validates the required fields in the jobgroup masks tab.
   */
  validateJobgroupMasksTab = () => {
    const validatorResult = this.validateGroupMasks()
    const errors = validatorResult.length
    if (errors > 0) {
      this.setState({ jobgroupMasksTab: { ...this.state.jobgroupMasksTab, groupMasks: validatorResult } }, () => {
        this.handleFocusJobgroupMasksTab()
      })
    }
    return errors === 0
  }

  /**
 * @description Tries to focus the next input with an error in jobgroup masks tab.
 */
  handleFocusJobgroupMasksTab = () => {
    const { jobgroupMasksTab } = this.state
    const requiredInputs = jobgroupMasksTab.groupMasks.map(d => {
      return { inputRef: d.ref, errorkey: d.filenameOrMask.error }
    })
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].errorkey !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }


  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { createJobgroup, onClose } = this.props
    const { generalTab, jobgroupMasksTab } = this.state
    const errorTabs = [
      this.validateGeneralTab(),
      this.validateJobgroupMasksTab()
    ]
    if (errorTabs.every(d => d)) {
      const createObj = {
        JGIGNAME: generalTab.jobgroupName.value,
        OWNER: generalTab.owner.value,
        ARCHMED: generalTab.archiveRetention === 0
          ? DefinitionUtils.ARCHIVEPOOL_MEDIA_NONE
          : DefinitionUtils.archiveMediaItems(false)[generalTab.archiveMedia].key,
        JGITITLE: generalTab.title.value,
        ARCRETPD: generalTab.archiveRetention,
        ONLRETPD: generalTab.onlineRetention,
        CTYPE: DefinitionUtils.JOBGROUP_DEFINITION_TYPES[generalTab.type].key,
        OBJECTS: jobgroupMasksTab.groupMasks.map(d => { return { JGIGNAME: generalTab.jobgroupName.value, JGMMASK: d.filenameOrMask.value, JGMEXCL: d.exclusive === 0 } })
      }
      createJobgroup(createObj, onClose)
    }
  }

  /**
   * @description Gets the error tabs as an array of numbers.
   * @returns {Array} The error tabs as an array of numbers.
   */
  handleErrorTabs = () => {
    const {
      generalTab: { jobgroupName, title },
      jobgroupMasksTab: { groupMasks }
    } = this.state
    let buffer = []
    if (jobgroupName.error !== '' || title.error !== '') {
      buffer.push(0)
    }
    groupMasks.forEach(d => {
      if (d.filenameOrMask.error !== '') {
        buffer.push(1)
      }
    })
    return buffer
  }

  onReset = () => {
    this.setState(_.cloneDeep(this.defaultState));
  }
  /**
   * @description Renders the component.
   */
  render = () => {
    const { id, onClose } = this.props
    return (
      <Modal
        id='create_jobgroup_dialog'
        className='bux_UserProfileModal'
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={translate('definition.create_jobgroup')}
          onClose={onClose}>
        </Header>
        <Main id={id}>
          <Tabs
            id={id}
            errorTabs={this.handleErrorTabs()}>
            <Tab title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('general.jobgroup_masks')}>
              {this.renderJobgroupMasksTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer>
          {this.props.prefilledData && !hasNoValues(this.props.prefilledData) &&
            <Button
              id={`${id}_resetbtn`}
              tooltip={translate('general.reset')}
              icon={'undo'}
              onClick={this.onReset}
            />
          }
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save')}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {
    createJobgroup: (createObj, callback) => {
      JobgroupsDefinitionActions.createJobgroup(createObj, callback)(dispatch)
    },
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobgroupDialog)