import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { translate } from 'language/Language'

// components
import Row from 'BetaUX2Web-Components/src/components/row/Row'
import Column from 'BetaUX2Web-Components/src/components/column/Column'
import Input from 'BetaUX2Web-Components/src/components/input/Input'
import Button from 'BetaUX2Web-Components/src/components/button/Button'
import Card from 'BetaUX2Web-Components/src/components/card/Card'

// redux
import { connect } from 'react-redux'
import * as Preferences from 'redux/general/Preferences'
import * as PreferenceActions from 'redux/actions/PreferencesActions'
import * as LPDQueueActions from 'redux/actions/LpdQueueDefinitionActions'

class DefinitionLPDQueue extends Component {

  defaultState = {
    id: {
      value: '',
      errorkey: ''
    },
    owner: {
      value: '',
      errorkey: ''
    },
    title: {
      value: '',
      errorkey: ''
    }
  }

  state = {
    ...this.defaultState,
  }

  /**
   * @description Initializes the search fields with the values saved in preferences.
   */
  componentDidMount() {
    this.initFieldsFromPreferences()
  }

  /**
 * @description Initializes the import fields with the values saved in preferences.
 */
  initFieldsFromPreferences = () => {
    const { preferences } = this.props

    if (preferences) {
      const id = preferences[Preferences.DEFINITION_LPD_QUEUES_NAME] || ''
      const owner = preferences[Preferences.DEFINITION_LPD_QUEUES_OWNER] || ''
      const title = preferences[Preferences.DEFINITION_LPD_QUEUES_DESCRIPTION] || ''

      this.setState({
        id: {
          value: id,
          errorkey: ''
        },
        owner: {
          value: owner,
          errorkey: ''
        },
        title: {
          value: title,
          errorkey: ''
        }
      })
    }
  }

  /**
   * @description Handles changes on input fields.
   * @param key The key of the field to change
   * @param value The new value
   * @param errorkey The new errorkey
   */
  handleInputChanged = (key, value, errorkey = '') => {
    this.setState({
      [key]: {
        value,
        errorkey
      }
    })
  }

  /**
   * @description Handles the submit search action.
   * @param event The event which is thrown by the button
   */
  handleSubmitSearch = event => {
    event.preventDefault()

    const { id, owner, title } = this.state

    // save search values in preferences
    const prefsToChange = {
      [Preferences.DEFINITION_LPD_QUEUES_NAME]: id.value,
      [Preferences.DEFINITION_LPD_QUEUES_OWNER]: owner.value,
      [Preferences.DEFINITION_LPD_QUEUES_DESCRIPTION]: title.value,
    }

    this.props.changePrefs(prefsToChange)
    this.props.searchLPDQueue(
      id.value,
      title.value,
      owner.value
    )
  }

  /**
   * @description Resets the current values to the default values.
   */
  handleOnReset = () => {
    this.setState(this.defaultState)
  }


  /**
   * @description Renders the components which are in main.
   */
  renderMain = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_main_lpd_queue`}
        className={'bux_drawer_main'}>
        <Card
          title={translate('general.general', lang)}>
          <Row>
            <Column
              colMD={12}>
              <Input
                id={`${id}_lpd_queue_general_id`}
                onInputChanged={(val, err) => { this.handleInputChanged('id', val, err) }}
                value={this.state.id.value}
                title={translate('definition.lpd_queue.id', lang)}
                error={this.state.id.errorkey && translate(this.state.id.errorkey, lang)}
                maxLength={16}
              />
            </Column>
          </Row>
          <Row>
            <Column
              colMD={12}>
              <Input
                id={`${id}_lpd_queue_general_owner`}
                onInputChanged={(val, err) => { this.handleInputChanged('owner', val, err) }}
                value={this.state.owner.value}
                title={translate('definition.lpd_queue.owner', lang)}
                error={this.state.owner.errorkey && translate(this.state.owner.errorkey, lang)}
                maxLength={8}
              />
            </Column>
          </Row>
          <Row>
            <Column
              colMD={12}>
              <Input
                id={`${id}_lpd_queue_general_title`}
                onInputChanged={(val, err) => { this.handleInputChanged('title', val, err) }}
                value={this.state.title.value}
                title={translate('general.title', lang)}
                error={this.state.title.errorkey && translate(this.state.title.errorkey, lang)}
                maxLength={64}
              />
            </Column>
          </Row>
        </Card>
      </div>
    )
  }

  /**
   * @description Renders the footer.
   */
  renderFooter = () => {
    const { id, lang } = this.props

    return (
      <div
        id={`${id}_footer`}
        className='bux_drawer_footer'>
        <Button
          id={`${id}_search`}
          text={translate('general.search', lang)}
          onClick={this.handleSubmitSearch}
          submit
          primary
        />
        <Button
          id={`${id}_resetBtn`}
          icon='undo'
          iconType='material'
          onClick={this.handleOnReset}
        />
      </div>
    )
  }

  render = () => {
    return (
      <form
        id={this.props.id}
        className='bux_drawer_form'
        onSubmit={this.handleSubmitSearch}>
        {this.renderMain()}
        {this.renderFooter()}
      </form>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    token: auth.serverdata.token,
    preferences: auth.serverdata.preferences,
    lang: auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchLPDQueue: (name, description, owner) => {
      LPDQueueActions.getLpdQueues(name, description, owner)(dispatch)
    },
    changePrefs: prefs => {
      PreferenceActions.changePrefs(prefs)(dispatch)
    }
  }
}

DefinitionLPDQueue.propTypes = {
  id: PropTypes.string.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(DefinitionLPDQueue)