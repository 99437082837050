export enum HEADERS {
  AGNTNAME = 'AGNTNAME',
  EDATE = 'EDATE',
  ENDDATE = 'ENDDATE',
  ENDDT = 'ENDDT',
  ETIME = 'ETIME',
  FROMLAST = 'FROMLAST',
  JGIGNAME = 'JGIGNAME',
  JGITITLE = 'JGITITLE',
  JOBERR = 'JOBERR',
  JOBGROUP = 'JOBGROUP',
  JOBNAME = 'JOBNAME',
  JOBRC = 'JOBRC',
  JOBSTAT = 'JOBSTAT',
  RUNTIME = 'RUNTIME',
  RUNUSER = 'RUNUSER',
  SDATE = 'SDATE',
  SERVICES = 'SERVICES',
  SOURCE_VERSION = 'SRCVERS',
  SRCPAGES = 'SRCPAGES',
  SRCSUBDT = 'SRCSUBDT',
  STIME = 'STIME',
  STRTDATE = 'STRTDATE',
  STRTDT = 'STRTDT',
  SYSTEMNAME = 'SYSTEMNAME',
  TUNITS = 'TUNITS',
  UKEY = 'UKEY',
  USR1NAM = 'USR1NAM',
  USR1VAL = 'USR1VAL',
  USR2NAM = 'USR2NAM',
  USR2VAL = 'USR2VAL',
  WORKFLOW = 'WORKFLOW',
}

export enum SEARCH_HEADERS {
  AGENTNAME = 'AGENTNAME',
  EDATE = 'EDATE',
  ETIME = 'ETIME',
  FROMLAST = 'FROMLAST',
  JGIGNAME = 'JGIGNAME',
  JGITITLE = 'JGITITLE',
  JOBERR = 'JOBERR',
  JOBGROUP = 'JOBGROUP',
  LJOBNAME = 'LJOBNAME',
  JOBRC = 'JOBRC',
  JOBSTATUS = 'JOBSTATUS',
  JOBTYPE = 'JOBTYPE',
  LOGSOURCE = 'LOGSOURCE',
  RUNTIME = 'RUNTIME',
  RUNUSER = 'RUNUSER',
  SDATE = 'SDATE',
  SERVICE = 'SERVICE',
  SRCVERSION = 'SRCVERSION',
  SRCSUBDT = 'SRCSUBDT',
  STIME = 'STIME',
  STRTDATE = 'STRTDATE',
  SYSTEMNAME = 'SYSTEMNAME',
  TUNITS = 'TUNITS',
  UKEY = 'UKEY',
  USER1_FIELD_NAME = 'USER1NAM',
  USER1_FIELD_VAL = 'USER1VAL',
  USER2_FIELD_NAME = 'USER2NAM',
  USER2_FIELD_VAL = 'USER2VAL',
  WORKFLOW = 'WORKFLOW',
}