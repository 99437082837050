import { Button } from 'BetaUX2Web-Components/src';
import React, {ForwardedRef, forwardRef, useCallback, useEffect, useState} from 'react';

import './TabLabel.scss';


interface TabLabelProps {
  id: string;
  date?: string;
  title: string | JSX.Element;
  selected?: boolean;
  focusable: boolean;
  onClick: () => void;
  onClose: () => void;
  onFocus: () => void;
  onBlur: () => void;
  disableClose?: boolean;
}

export const TabLabel = forwardRef<HTMLLIElement, TabLabelProps>((props: TabLabelProps, ref: ForwardedRef<HTMLLIElement>): JSX.Element => {
  const {title, date, selected, onClick, id, focusable, onClose, disableClose, onBlur, onFocus} = props;

  const [containerClasses, setContainerClasses] = useState('tab-label-container'); // Note: Init with class to avoid 'glitching' of text in case of tab removal

  useEffect(() => {
    const classes = ['tab-label-container'];
    if (selected) classes.push('tab-label-selected');
    setContainerClasses(classes.join(' '));
  }, [selected, onClose]);

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter') onClick();
  }, [onClick]);

  const onCloseButtonClick = useCallback(() => {
    setContainerClasses(prev => `${prev} tab-close`);
    setTimeout(onClose, 300);
  }, [onClose, setContainerClasses]);

  const onTabClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    onClick();
  }, [onClick]);

  return (
    <li
      id={`tab_label_${id}`}
      className={containerClasses}
      onKeyDown={onKeyDown}
      tabIndex={focusable ? 0 : -1}
      onBlur={onBlur}
      onFocus={(e) => {
        e.preventDefault();
        onFocus();
      }}
      ref={ref}
    >
      <div
        className={'tab-label-content'}
        onMouseDown={onTabClick}
      >
        {
          date &&
          <div className={'tab-date'}>
            {date}
          </div>
        }
        <b title={title as string ?? ''} className={'tab-title'}>
          {title}
        </b>
      </div>
      {!disableClose && <Button
        id={''}
        tabIndex={-1}
        icon={'close_small'}
        className={'closeButton'}
        fill={false}
        onClick={onCloseButtonClick}
      />}
    </li>
  );
});

TabLabel.displayName = 'TabLabelComponent';