import { translate } from 'language/Language'
import PropTypes from 'prop-types'
import React, { Component } from 'react'


import {
  Button,
  Column, Dropdown, Input,
  Modal as ModalComponent, MultilineInput, Row, Switch, Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'
import * as DefinitionUtils from 'utils/DefinitionUtils'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as RecipientActions from 'redux/actions/RecipientDefinitionActions.js'
import * as RecipientHierarchyActions from 'redux/actions/RecipientHierarchyActions'
import { hasNoValues } from 'utils/ObjectUtils'

const defaultState = {
  generalTab: {
    recipientId: {
      value: '',
      errorkey: '',
    },
    typeIndex: 0,
    predecessorId: '',
    owner: '',
    distributionIndex: 0,
    title: ''
  },
  printTab: {
    outputFormat: '',
    outputChannel: '',
    postprocessingNote: '',
    printBannerAndTrailer: 0,
    separatRecipientBanner: 0,
    suppressTrailers: 0,
    suppressRecipientBanner: 0,
    suppressDocumentBanner: 0,
    suppressPostprocessingNote: 0,
    showOutputFormatDefinitionSelectorDialog: false,
    showOutputChannelDefinitionSelectorDialog: false,
    showPostProcessingNoteDefinitionSelectorDialog: false
  },
  bannerTab: {
    bannerText: {
      bannerText1: '',
      bannerText2: '',
      bannerText3: '',
      bannerText4: '',
      bannerText5: '',
      bannerText6: '',
      bannerText7: '',
      bannerText8: ''
    },
    bannerFileName: {
      banner: '',
      trailer: ''
    },
    printControlFileName: {
      banner: '',
      trailer: ''
    }
  },
  addressTab: {
    addressText: '',
    eMailAddress: ''
  }
}
class CreateRecipientDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    hierarchyData: PropTypes.bool,
    recipientDataFromSearch: PropTypes.object,
  }

  state = { ...defaultState }

  recipientIdInput = React.createRef()

  getIndexOfType = (recipientType) => {
    if (!recipientType) return 0;
    const index = this.getTranslatedGeneralTabTypeItems().indexOf(
      translate(
        DefinitionUtils.getRecipientTypes().filter(d => d.key === recipientType)[0].translationKey)
    );
    return index !== -1 ? index : 0;
  }

  getIndexOfDistribution = (distribution) => {
    if (!distribution) return 0;
    const index = this.getTranslatedGeneralTabDistributionItems().indexOf(
      translate(
        DefinitionUtils.getRecipientDistributions(true).filter(d => d.key === distribution)[0].translationKey)
    )
    return index !== -1 ? index : 0;
  }

  /**
   * @description Sets the initial focus and initializes the values when copying.
   */
  componentDidMount = () => {
    this.recipientIdInput.current.focus()
    let aTitleTexts = ''

    if (this.props.copyRecipient && this.props.recipientToCopy) {
      for (let i = 1; i <= 8; i++) {
        const aTitleItem = this.props.recipientToCopy[`ATITLE${i}`];
        aTitleTexts += aTitleItem
        if (i < 8) {
          aTitleTexts += '\n'
        }
      }
    }

    const recipient = this.props.recipientDataFromSearch ? this.props.recipientDataFromSearch : (this.props.copyRecipient ? this.props.recipientToCopy : undefined)
    const newState = {
      generalTab: {
        recipientId: {
          value: recipient?.RECI || '',
          errorkey: '',
        },
        typeIndex: this.getIndexOfType(recipient?.RTYPE),
        predecessorId: recipient?.PREDRECI || '',
        owner: recipient?.OWNER || '',
        distributionIndex: this.getIndexOfDistribution(recipient?.BRWS),
        title: recipient?.TITLE || ''
      },
      printTab: {
        outputFormat: recipient?.PCR || '',
        outputChannel: recipient?.DCR || '',
        postprocessingNote: recipient?.PPN || '',
        printBannerAndTrailer: recipient?.PRTBAN2 ? 2 : (recipient?.PRTBAN1 ? 1 : 0),
        separatRecipientBanner: recipient?.OVBAN || 0,
        suppressTrailers: recipient?.PRTBANB || 0,
        suppressRecipientBanner: recipient?.OVBUN || 0,
        suppressDocumentBanner: recipient?.OVLBB || 0,
        suppressPostprocessingNote: recipient?.OVPPN || 0,
        showOutputFormatDefinitionSelectorDialog: false,
        showOutputChannelDefinitionSelectorDialog: false,
        showPostProcessingNoteDefinitionSelectorDialog: false
      },
      bannerTab: {
        bannerText: {
          bannerText1: recipient?.BANNER1 || '',
          bannerText2: recipient?.BANNER2 || '',
          bannerText3: recipient?.BANNER3 || '',
          bannerText4: recipient?.BANNER4 || '',
          bannerText5: recipient?.BANNER5 || '',
          bannerText6: recipient?.BANNER6 || '',
          bannerText7: recipient?.BANNER7 || '',
          bannerText8: recipient?.BANNER8 || ''
        },
        bannerFileName: {
          banner: recipient?.BHPRG || '',
          trailer: recipient?.THPRG || ''
        },
        printControlFileName: {
          banner: recipient?.DJDE || '',
          trailer: recipient?.DJDET || ''
        }
      },
      addressTab: {
        addressText: aTitleTexts,
        eMailAddress: recipient?.MAILADR || ''
      }
    }
    this.setState({ ...newState })


    this.recipientIdInput.current.focus()
  }

  /**
   * @description Handles the input changes on the general tab.
   * @param {String} id The id of the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleGeneralTabInputChanged = (id, value, error) => {
    if (id === 'recipientId') {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          [id]: {
            value: value,
            errorkey: error
          }
        }
      })
    } else {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          [id]: value
        }
      })
    }
  }

  /**
   * @description Handles the input changes of the id and parentid without spaces.
   * @param {String} key The id the input field.
   * @param {String} value The new value.
   * @param {String} error The new error.
   */
  handleChangeGeneralTabWithoutSpaces = (key, value, error) => {
    // ignore new value if it includes a space
    if (value.includes(' ')) {
      return
    }

    this.handleGeneralTabInputChanged(key, value, error)
  }

  /**
   * @description Handles the type changes in general tab.
   * @param {Number} index The new index of the dropdown.
   */
  handleGeneralTabTypeChange = index => {
    const { generalTab } = this.state

    if (this.props.copyRecipient) {
      const { recipientToCopy } = this.props
      this.setState({
        generalTab: {
          ...generalTab,
          typeIndex: index
        }
      }, () => {
        const types = DefinitionUtils.RECIPIENT_TYPES.filter((_, i) => i !== 0)
        if (types[generalTab.typeIndex].key !== recipientToCopy.RTYPE) {
          this.setState({
            generalTab: {
              ...generalTab,
              recipientId: {
                ...generalTab.recipientId,
                errorkey: ''
              }
            }
          })
        }
      })
    } else {
      this.setState({
        generalTab: {
          ...generalTab,
          typeIndex: index
        }
      })
    }
  }

  /**
   * @description Handles the distribution changes in general tab.
   * @param {Number} index The new index of the dropdown.
   */
  handleGeneralTabDistributionChange = index => {
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        distributionIndex: index
      }
    })
  }

  /**
   * @description Gets the translated type items in general tab.
   * @returns {Array} The translated type items.
   */
  getTranslatedGeneralTabTypeItems = () => {
    const types = DefinitionUtils.getRecipientTypes()
    const items = []

    types.forEach(type => {
      items.push(translate(type.translationKey))
    })

    return items
  }

  /**
   * @description Gets the translated distribution items in general tab.
   * @returns {Array} The translated distribution items.
   */
  getTranslatedGeneralTabDistributionItems = () => {
    const distributions = DefinitionUtils.getRecipientDistributions()
    const items = []

    distributions.forEach(distribution => {
      items.push(translate(distribution.translationKey))
    })

    return items
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { id } = this.props
    const { generalTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_general_tab_recipient_id`}
              value={generalTab.recipientId.value}
              title={translate('recipient.reci_id')}
              ref={this.recipientIdInput}
              required={`${translate('general.required_field')}`}
              maxLength={16}
              onInputChanged={(value, error) => this.handleChangeGeneralTabWithoutSpaces('recipientId', value, error)}
              error={generalTab.recipientId.errorkey && translate(generalTab.recipientId.errorkey)}
              onBlur={() => this.setState({ generalTab: { ...this.state.generalTab, ...this.validateRecipientID() } })}
            />
          </Column>
          <Column colMD={6}>
            <Dropdown
              id={`${id}_general_tab_type`}
              items={this.getTranslatedGeneralTabTypeItems()}
              activeIndex={generalTab.typeIndex}
              onChange={this.handleGeneralTabTypeChange}
              title={translate('recipient.type')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_general_tab_predecessor_id`}
              value={generalTab.predecessorId}
              title={translate('recipient.general_tab_predecessor_id')}
              maxLength={16}
              onInputChanged={value => this.handleChangeGeneralTabWithoutSpaces('predecessorId', value)}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_general_tab_owner`}
              value={generalTab.owner}
              title={translate('recipient.owner')}
              maxLength={8}
              onInputChanged={value => this.handleGeneralTabInputChanged('owner', value)}
            />
          </Column>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_general_tab_distribution`}
              items={this.getTranslatedGeneralTabDistributionItems()}
              activeIndex={generalTab.distributionIndex}
              onChange={this.handleGeneralTabDistributionChange}
              title={translate('recipient.distribution_via')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_general_tab_title`}
              value={generalTab.title}
              title={translate('general.title')}
              maxLength={80}
              onInputChanged={value => this.handleGeneralTabInputChanged('title', value)}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the input changes in print tab.
   * @param {String} id The id of the input field.
   * @param {String} value The new value.
   */
  handlePrintTabInputChanged = (id, value) => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        [id]: value
      }
    })
  }

  /**
   * @description Handles the print banner and trailer changes in print tab.
   * @param {Number} index The new index of the switch buttons.
   */
  handlePrintTabPrintBannerAndTrailerChanged = index => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        printBannerAndTrailer: index
      }
    })
  }

  /**
   * @description Handles the suppress trailer changes in print tab.
   * @param {Number} index The new index of the switch buttons.
   */
  handlePrintTabSuppressTrailersChanged = index => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        suppressTrailers: index === 0
      }
    })
  }

  /**
   * @description Handles the suppress recipient banner changes in print tab.
   * @param {Number} index new index of the switch buttons.
   */
  handlePrintTabSuppressRecipientBannerChanged = index => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        suppressRecipientBanner: index === 0
      }
    })
  }

  /**
   * @description Handles suppress document banner changes in print tab.
   * @param {Number} index The new index of the switch buttons.
   */
  handlePrintTabSuppressDocumentBannerChanged = index => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        suppressDocumentBanner: index === 0
      }
    })
  }

  /**
   * @description Handles the suppress post processing note changes in print tab.
   * @param {Number} index The new index of the switch buttons.
   */
  handlePrintTabSuppressPostprocessingNoteChanged = index => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        suppressPostprocessingNote: index === 0
      }
    })
  }

  /**
   * @description Handles the separate recipient banner changes in print tab.
   * @param {Number} index The new index of the switch buttons.
   */
  handlePrintTabSeparateRecipientBannerChanged = index => {
    this.setState({
      printTab: {
        ...this.state.printTab,
        separatRecipientBanner: index === 0
      }
    })
  }

  /**
   * @description Gets the translated print banner and trailer items.
   * @returns {Array} The print banner and trailer items.
   */
  getPrintBannerAndTrailerItems = () => {
    let items = [
      translate('recipient.switch_button.print_banner_and_trailer.no'),
      translate('recipient.switch_button.print_banner_and_trailer.once'),
      translate('recipient.switch_button.print_banner_and_trailer.twice')
    ]

    return items
  }

  /**
   * @description Requests the outputformat definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenOutputFormatDefinitionDialog = () => {
    const callback = () => {
      this.setState({
        printTab: {
          ...this.state.printTab,
          showOutputFormatDefinitionSelectorDialog: true
        }
      })
    }

    this.props.getOutputFormatDefinitions(
      ['PCR', 'PCRTITLE'],
      this.state.printTab.outputFormat,
      callback)
  }

  /**
   * @description Requests the outputchannel definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenOutputChannelDefinitionDialog = () => {
    const callback = () => {
      this.setState({
        printTab: {
          ...this.state.printTab,
          showOutputChannelDefinitionSelectorDialog: true
        }
      })
    }

    this.props.getOutputChannelDefinitions(
      ['DCR', 'DCRTYPE', 'DCRTITLE'],
      this.state.printTab.outputChannel,
      callback)
  }

  /**
   * @description Requests the postprocessingnote definition with the current selection. On successful request it opens the seletor dialog.
   */
  onOpenPostProcessingNoteDefinitionDialog = () => {
    const callback = () => {
      this.setState({
        printTab: {
          ...this.state.printTab,
          showPostProcessingNoteDefinitionSelectorDialog: true
        }
      })
    }

    this.props.getPostProcessingNotesDefinitions(
      ['PPN', 'PPNTITLE'],
      this.state.printTab.postprocessingNote,
      callback)
  }

  /**
   * @description Renders the selector dialogs.
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    // outputformat definitions
    let outputFormatDefinitions
    if (this.state.printTab.showOutputFormatDefinitionSelectorDialog) {
      outputFormatDefinitions = selector.outputformats
    }

    // outputchannel definitions
    let outputChannelDefinitions
    if (this.state.printTab.showOutputChannelDefinitionSelectorDialog) {
      outputChannelDefinitions = selector.outputchannels
    }

    // postprocessingnote definitions
    let postProcessingNoteDefinitions
    if (this.state.printTab.showPostProcessingNoteDefinitionSelectorDialog) {
      postProcessingNoteDefinitions = selector.ppns
    }

    return (
      <>
        {this.state.printTab.showOutputFormatDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputformatselector_dialog`}
            onClose={() => {
              this.setState({
                printTab: {
                  ...this.state.printTab,
                  showOutputFormatDefinitionSelectorDialog: false
                }
              })
            }}
            title={translate('definition.outputformatdefinitions')}
            header={[
              translate('import.outputformat'),
              translate('general.title'),
            ]}
            items={outputFormatDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputformat = outputFormatDefinitions.data[selectedRows][outputFormatDefinitions.header.indexOf('PCR')]
                this.setState({
                  printTab: {
                    ...this.state.printTab,
                    outputFormat: newOutputformat,
                    showOutputFormatDefinitionSelectorDialog: false
                  }
                })
              } else {
                this.setState({
                  printTab: {
                    ...this.state.printTab,
                    showOutputFormatDefinitionSelectorDialog: false
                  }
                })
              }
            }}
          />
        )}
        {this.state.printTab.showOutputChannelDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_outputchannelselector_dialog`}
            onClose={() => {
              this.setState({
                printTab: {
                  ...this.state.printTab,
                  showOutputChannelDefinitionSelectorDialog: false
                }
              })
            }}
            title={translate('definition.outputchanneldefinitions')}
            header={[
              translate('import.outputchannel'),
              translate('general.type'),
              translate('general.title')
            ]}
            items={outputChannelDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newOutputchannel = outputChannelDefinitions.data[selectedRows][outputChannelDefinitions.header.indexOf('DCR')]
                this.setState({
                  printTab: {
                    ...this.state.printTab,
                    outputChannel: newOutputchannel,
                    showOutputChannelDefinitionSelectorDialog: false
                  }
                })
              } else {
                this.setState({
                  printTab: {
                    ...this.state.printTab,
                    showOutputChannelDefinitionSelectorDialog: false
                  }
                })
              }
            }}
          />
        )}
        {this.state.printTab.showPostProcessingNoteDefinitionSelectorDialog && (
          <SelectorDialog
            id={`${id}_postprocessingnoteselector_dialog`}
            onClose={() => {
              this.setState({
                printTab: {
                  ...this.state.printTab,
                  showPostProcessingNoteDefinitionSelectorDialog: false
                }
              })
            }}
            title={translate('definition.post_processing_note_definition')}
            header={[
              translate('definition.postprocessing_note'),
              translate('general.title')
            ]}
            items={postProcessingNoteDefinitions.data}
            onSelect={selectedRows => {
              // only change values if there is a selected row
              if (selectedRows.length > 0) {
                const newPostprocessingNode = postProcessingNoteDefinitions.data[selectedRows][postProcessingNoteDefinitions.header.indexOf('PPN')]
                this.setState({
                  printTab: {
                    ...this.state.printTab,
                    postprocessingNote: newPostprocessingNode,
                    showPostProcessingNoteDefinitionSelectorDialog: false
                  }
                })
              } else {
                this.setState({
                  printTab: {
                    ...this.state.printTab,
                    showPostProcessingNoteDefinitionSelectorDialog: false
                  }
                })
              }
            }}
          />
        )}
      </>
    )
  }

  /**
   * @description Renders the print tab.
   */
  renderPrintTab = () => {
    const { id } = this.props
    const { printTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_print_tab_output_format`}
              value={printTab.outputFormat}
              title={translate('recipient.output_format')}
              maxLength={16}
              onInputChanged={value => this.handlePrintTabInputChanged('outputFormat', value)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputFormatDefinitionDialog(),
              }}
            />
          </Column>
          <Column colMD={6}>
            <Switch
              id={`${id}_print_tab_suppressTrailers`}
              title={translate('recipient.print_tab_suppress_trailers')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handlePrintTabSuppressTrailersChanged(index)}
              activeIndex={printTab.suppressTrailers ? 0 : 1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_print_tab_output_channel`}
              value={printTab.outputChannel}
              title={translate('recipient.output_channel')}
              maxLength={16}
              onInputChanged={value => this.handlePrintTabInputChanged('outputChannel', value)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenOutputChannelDefinitionDialog(),
              }}
            />
          </Column>
          <Column colMD={6}>
            <Switch
              id={`${id}_print_tab_suppress_recipient_banner`}
              title={translate('recipient.print_tab_suppress_recipient_banner_trailer_for_bundling')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handlePrintTabSuppressRecipientBannerChanged(index)}
              activeIndex={printTab.suppressRecipientBanner ? 0 : 1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_print_tab_postprocessing_note`}
              value={printTab.postprocessingNote}
              title={translate('recipient.postprocessing_note')}
              maxLength={16}
              onInputChanged={value => this.handlePrintTabInputChanged('postprocessingNote', value)}
              addon={{
                iconName: 'list',
                onClick: () => this.onOpenPostProcessingNoteDefinitionDialog(),
              }}
            />
          </Column>
          <Column colMD={6}>
            <Switch
              id={`${id}_print_tab_suppress_document_banner`}
              title={translate('recipient.print_tab_suppress_document_banner_trailer_for_bundling')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handlePrintTabSuppressDocumentBannerChanged(index)}
              activeIndex={printTab.suppressDocumentBanner ? 0 : 1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_print_tab_print_banner_and_trailer`}
              title={translate('recipient.print_tab_print_banner_and_trailer')}
              items={this.getPrintBannerAndTrailerItems()}
              maxPerRow={3}
              onClick={index => this.handlePrintTabPrintBannerAndTrailerChanged(index)}
              activeIndex={printTab.printBannerAndTrailer}
            />
          </Column>
          <Column colMD={6}>
            <Switch
              id={`${id}_print_tab_suppress_postprocessing_note`}
              title={translate('recipient.print_tab_suppress_postprocessing_note_in_packets')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handlePrintTabSuppressPostprocessingNoteChanged(index)}
              activeIndex={printTab.suppressPostprocessingNote ? 0 : 1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_print_tab_separate_recipient_banner`}
              title={translate('recipient.print_tab_separate_recipient_banner_trailer_for_online_autoprint')}
              items={[translate('general.yes'), translate('general.no')]}
              onClick={index => this.handlePrintTabSeparateRecipientBannerChanged(index)}
              activeIndex={printTab.separatRecipientBanner ? 0 : 1}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the input banner text changes in banner tab.
   * @param {String} id The id of the banner textfield.
   * @param {String} bannerText The new banner text.
   */
  handleBannerTabInputBannerTextChange = (id, bannerText) => {
    this.setState({
      bannerTab: {
        ...this.state.bannerTab,
        bannerText: {
          ...this.state.bannerTab.bannerText,
          [id]: bannerText
        }
      }
    })
  }

  /**
   * @description Handles the input banner filename changes in banner tab.
   * @param {String} id The id of the banner filename textfield.
   * @param {String} bannerFileName The new value.
   */
  handleBannerTabInputBannerFileNameChanged = (id, bannerFileName) => {
    this.setState({
      bannerTab: {
        ...this.state.bannerTab,
        bannerFileName: {
          ...this.state.bannerTab.bannerFileName,
          [id]: bannerFileName
        }
      }
    })
  }

  /**
   * @description Handles the input print control filename changes in banner tab.
   * @param {String} id The id of the input print control filename textfield.
   * @param {String} printControlFileName The new value.
   */
  handleBannerTabInputPrintControlFileNameChanged = (id, printControlFileName) => {
    this.setState({
      bannerTab: {
        ...this.state.bannerTab,
        printControlFileName: {
          ...this.state.bannerTab.printControlFileName,
          [id]: printControlFileName
        }
      }
    })
  }

  /**
   * @description Renders the banner tab.
   */
  renderBannerTab = () => {
    const { id } = this.props
    const { bannerTab } = this.state
    return (
      <>
        <Row isTitle>
          <Column colMD={2}>
            <label
              id={`${id}_banner_tab_column_banner_text_header`}>
              {translate('recipient.banner_tab_banner_text')}
            </label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_banner_tab_banner_text_1`}
              title={translate('recipient.banner_tab_banner_text_banner_text_1')}
              value={bannerTab.bannerText.bannerText1}
              maxLength={9}
              tabIndex={0}
              onInputChanged={bannerText => { this.handleBannerTabInputBannerTextChange('bannerText1', bannerText) }}>
            </Input>
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Input
              id={`${id}_banner_tab_banner_text_2`}
              title={translate('recipient.banner_tab_banner_text_banner_text_2')}
              value={bannerTab.bannerText.bannerText2}
              maxLength={9}
              tabIndex={1}
              onInputChanged={bannerText => { this.handleBannerTabInputBannerTextChange('bannerText2', bannerText) }}>
            </Input>
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_banner_tab_banner_text_3`}
              title={translate('recipient.banner_tab_banner_text_banner_text_3')}
              value={bannerTab.bannerText.bannerText3}
              maxLength={32}
              tabIndex={2}
              onInputChanged={bannerText => { this.handleBannerTabInputBannerTextChange('bannerText3', bannerText) }}>
            </Input>
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_banner_tab_banner_text_4`}
              title={translate('recipient.banner_tab_banner_text_banner_text_4')}
              value={bannerTab.bannerText.bannerText4}
              maxLength={32}
              tabIndex={3}
              onInputChanged={bannerText => { this.handleBannerTabInputBannerTextChange('bannerText4', bannerText) }}>
            </Input>
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_banner_tab_banner_text_5`}
              title={translate('recipient.banner_tab_banner_text_banner_text_5')}
              value={bannerTab.bannerText.bannerText5}
              maxLength={32}
              tabIndex={4}
              onInputChanged={bannerText => { this.handleBannerTabInputBannerTextChange('bannerText5', bannerText) }}>
            </Input>
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_banner_tab_banner_text_6`}
              title={translate('recipient.banner_tab_banner_text_banner_text_6')}
              value={bannerTab.bannerText.bannerText6}
              maxLength={32}
              tabIndex={5}
              onInputChanged={bannerText => { this.handleBannerTabInputBannerTextChange('bannerText6', bannerText) }}>
            </Input>
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Input
              id={`${id}_banner_tab_banner_text_7`}
              title={translate('recipient.banner_tab_banner_text_banner_text_7')}
              value={bannerTab.bannerText.bannerText7}
              maxLength={32}
              tabIndex={6}
              onInputChanged={bannerText => { this.handleBannerTabInputBannerTextChange('bannerText7', bannerText) }}>
            </Input>
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_banner_tab_banner_text_8`}
              title={translate('recipient.banner_tab_banner_text_banner_text_8')}
              value={bannerTab.bannerText.bannerText8}
              maxLength={32}
              tabIndex={7}
              onInputChanged={bannerText => { this.handleBannerTabInputBannerTextChange('bannerText8', bannerText) }}>
            </Input>
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={2}>
            <label
              id={`${id}_banner_tab_column_banner_file_name`}>
              {translate('recipient.banner_tab_banner_file_name')}
            </label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_banner_tab_banner_file_name_banner_1`}
              title={translate('recipient.banner_tab_banner_file_name_banner')}
              value={bannerTab.bannerFileName.banner}
              maxLength={8}
              tabIndex={8}
              onInputChanged={banner => { this.handleBannerTabInputBannerFileNameChanged('banner', banner) }}>
            </Input>
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Input
              id={`${id}_banner_tab_banner_banner_file_name_trailer_1`}
              title={translate('recipient.banner_tab_banner_file_name_trailer')}
              value={bannerTab.bannerFileName.trailer}
              maxLength={8}
              tabIndex={9}
              onInputChanged={trailer => { this.handleBannerTabInputBannerFileNameChanged('trailer', trailer) }}>
            </Input>
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={3}>
            <label
              id={`${id}_banner_tab_column_print_control_file_name`}>
              {translate('recipient.banner_tab_print_control_file_name')}
            </label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_banner_tab_print_control_file_name_banner_1`}
              title={translate('recipient.banner_tab_banner_file_name_banner')}
              value={bannerTab.printControlFileName.banner}
              maxLength={8}
              tabIndex={10}
              onInputChanged={banner => { this.handleBannerTabInputPrintControlFileNameChanged('banner', banner) }}>
            </Input>
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Input
              id={`${id}_banner_tab_print_control_file_name_trailer_1`}
              title={translate('recipient.banner_tab_banner_file_name_trailer')}
              value={bannerTab.printControlFileName.trailer}
              maxLength={8}
              tabIndex={11}
              onInputChanged={trailer => { this.handleBannerTabInputPrintControlFileNameChanged('trailer', trailer) }}>
            </Input>
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Handles the input changes in address tab.
   * @param {String} id The id of the textfield.
   * @param {String} value The new value.
   */
  handleAddressTabInputChanged = (id, value) => {
    this.setState({
      addressTab: {
        ...this.state.addressTab,
        [id]: value
      }
    })
  }

  /**
   * @description Renders the address tab.
   */
  renderAddressTab = () => {
    const { id } = this.props
    const { addressTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={12}>
            <MultilineInput
              tabIndex={0}
              title={translate('recipient.address_tab_address_text')}
              value={addressTab.addressText}
              cols={68}
              rows={8}
              id={id}
              onInputChanged={addressText => this.handleAddressTabInputChanged('addressText', addressText)}
              mono
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <Input
              tabIndex={1}
              id={`${id}_address_tab_address_text_0`}
              title={translate('recipient.address_tab_email_address')}
              value={addressTab.eMailAddress}
              maxLength={64}
              onInputChanged={eMailAdresse => this.handleAddressTabInputChanged('eMailAddress', eMailAdresse)}>
            </Input>
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Splits the address text after each line ('\n').
   * @returns {Array} The splitted address text.
   */
  splitAddressText = () => {
    return this.state.addressTab.addressText.split('\n')
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateRecipientID(),
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ generalTab: { ...this.state.generalTab, ...validatorResult } }, () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }

  /**
   * @description Tries to focus the next input of the general tab which has an error.
   */
  handleFocusGeneralTab = () => {
    const { generalTab } = this.state
    const requiredInputs = [
      { inputRef: this.recipientIdInput, error: generalTab.recipientId.error },
    ]
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].error !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  /**
   * @description Calls the rest api to create the recipient.
   * @param {Boolean} saveAsUtf8 Flag if the definition should be saved as utf-8. If false it will be saved as iso-8859-1.
   */
  handleSave = (saveAsUtf8) => {
    const { generalTab, printTab, bannerTab, addressTab } = this.state

    const errorTabs = [
      this.validateGeneralTab(),
    ]

    if (errorTabs.every(d => d)) {
      const splittedAddressText = this.splitAddressText()
      const recipientToCreate = {
        RECI: generalTab.recipientId.value,
        PREDRECI: generalTab.predecessorId,
        RTYPE: DefinitionUtils.getRecipientTypes()[generalTab.typeIndex].key,
        OWNER: generalTab.owner,
        TITLE: generalTab.title,
        BRWS: DefinitionUtils.getRecipientDistributions()[generalTab.distributionIndex].key,
        PCR: printTab.outputFormat,
        DCR: printTab.outputChannel,
        PPN: printTab.postprocessingNote,
        MAILADR: addressTab.eMailAddress,
        OVPCR: '', // not displayed in gui
        OVDCR: '', // not displayed in gui
        OVPPN: printTab.suppressPostprocessingNote ? 'YES' : 'NO',
        OVBUN: printTab.suppressRecipientBanner ? 'YES' : 'NO',
        PRTBAN: printTab.printBannerAndTrailer === 0 ? 'NO' : 'YES',
        PRTBAN2: printTab.printBannerAndTrailer === 2 ? 'YES' : 'NO',
        PRTBANB: printTab.suppressTrailers ? 'YES' : 'NO',
        PRTTOC: '', // not displayed in gui
        OVBAN: printTab.separatRecipientBanner ? 'YES' : 'NO',
        OVLBB: printTab.suppressDocumentBanner ? 'YES' : 'NO',
        BHPRG: bannerTab.bannerFileName.banner,
        THPRG: bannerTab.bannerFileName.trailer,
        DJDE: bannerTab.printControlFileName.banner,
        DJDET: bannerTab.printControlFileName.trailer,
        BANNER1: bannerTab.bannerText.bannerText1,
        BANNER2: bannerTab.bannerText.bannerText2,
        BANNER3: bannerTab.bannerText.bannerText3,
        BANNER4: bannerTab.bannerText.bannerText4,
        BANNER5: bannerTab.bannerText.bannerText5,
        BANNER6: bannerTab.bannerText.bannerText6,
        BANNER7: bannerTab.bannerText.bannerText7,
        BANNER8: bannerTab.bannerText.bannerText8,
        ATITLE1: splittedAddressText[0] ? splittedAddressText[0] : '',
        ATITLE2: splittedAddressText[1] ? splittedAddressText[1] : '',
        ATITLE3: splittedAddressText[2] ? splittedAddressText[2] : '',
        ATITLE4: splittedAddressText[3] ? splittedAddressText[3] : '',
        ATITLE5: splittedAddressText[4] ? splittedAddressText[4] : '',
        ATITLE6: splittedAddressText[5] ? splittedAddressText[5] : '',
        ATITLE7: splittedAddressText[6] ? splittedAddressText[6] : '',
        ATITLE8: splittedAddressText[7] ? splittedAddressText[7] : '',
        CUSER: this.props.userid,
        SAVEUTF8: saveAsUtf8
      }

      this.props.createRecipient(
        recipientToCreate,
        () => {
          this.props.hierarchyView
            ? this.props.getRecipientForHierarchy(generalTab.recipientId.value, DefinitionUtils.getRecipientTypes()[generalTab.typeIndex].key, generalTab.predecessorId, createdRecipient => {
              this.props.updateHierarchy(createdRecipient, generalTab.predecessorId !== '' ? generalTab.predecessorId : undefined)
            }) : this.props.onClose()
        }
      )
    }
  }

  /**
   * @description Checks if the new recipient id is different to the copied one. Adds an error under the textfield if it is not different.
   */
  checkCopiedRecipientId = () => {
    if (this.props.copyRecipient) {
      if (this.state.generalTab.recipientId.value === this.props.recipientToCopy.RECI) {
        this.setState({
          generalTab: {
            ...this.state.generalTab,
            recipientId: {
              ...this.state.generalTab.recipientId,
              errorkey: 'recipient.copy_recipient_same_recipient_id'
            }
          }
        })
        return false
      }
      return true
    }
  }

  /**
   * @description Validates the recipient id.
   * @returns {Object} If validation failed new object with error message for state otherwise empty object.
   */
  validateRecipientID = () => {
    const { generalTab } = this.state
    if (generalTab.recipientId.value !== '') {
      // check if the recipient id is different to the copied
      if (this.props.copyRecipient && generalTab.recipientId.value === this.props.recipientToCopy.RECI && DefinitionUtils.RECIPIENT_TYPES.filter((_, i) => i !== 0)[generalTab.typeIndex].key === this.props.recipientToCopy.RTYPE) {
        return {
          recipientId: {
            ...generalTab.recipientId,
            errorkey: 'recipient.copy_recipient_same_recipient_id'
          }
        }
      } else {
        return {}
      }
    }
    return {
      recipientId: {
        ...generalTab.recipientId,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Gets the tab indices as array which has errors.
   * @returns {Array} The tab indices which has errors.
   */
  handleErrorTabs = () => {
    const { generalTab } = this.state
    let buffer = []
    // possible general tab errors
    const isRecipientIdError = generalTab.recipientId.errorkey !== ''

    if (isRecipientIdError) {
      buffer.push(0)
    }

    return buffer
  }

  onReset = () => {
    this.setState({...defaultState})
  }

  render = () => {
    const { id, onClose, copyRecipient } = this.props
    return (
      <Modal onClose={onClose}
        id={copyRecipient
          ? 'copy_recipient_dialog_modal' : 'create_recipient_dialog'}
        className='bux_UserProfileModal'>
        <Header
          id={`${id}_modalHeader`}
          title={this.props.copyRecipient ? translate('recipient.modal_title_copy') : translate('recipient.modal_title_create')}
          onClose={onClose}>
        </Header>
        <Main
          id={id}>
          {this.renderSelectorDialogs()}
          <Tabs
            id={id}
            errorTabs={this.handleErrorTabs()}
          >
            <Tab title={translate('recipient.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('recipient.print')}>
              {this.renderPrintTab()}
            </Tab>
            <Tab title={translate('recipient.banner')}>
              {this.renderBannerTab()}
            </Tab>
            <Tab title={translate('recipient.address')}>
              {this.renderAddressTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer>
          {this.props.recipientDataFromSearch && !hasNoValues(this.props.recipientDataFromSearch) &&
            <Button
              id={`${id}_resetbtn`}
              tooltip={translate('general.reset')}
              icon={'undo'}
              onClick={this.onReset}
            />
          }
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn_iso88591`}
            text={translate('general.save_as_iso88591')}
            onClick={() => this.handleSave(false)}
            primary
            submit
          />
          <Button
            id={`${id}_savebtn_utf8`}
            text={translate('general.save_as_utf8')}
            onClick={() => this.handleSave(true)}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    recipientToCopy: state.definitions.recipients.recipient,
    selector: state.selector
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createRecipient: (reciDefinition, cb) => {
      RecipientActions.createRecipient(reciDefinition, cb)(dispatch)
    },
    getOutputFormatDefinitions: (fields, outputFormat, callback) => {
      ModalSelectorActions.getOutputFormatDefinitions(
        fields,
        outputFormat,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getOutputChannelDefinitions: (fields, outputChannel, callback) => {
      ModalSelectorActions.getOutputChannelDefinitions(
        fields,
        outputChannel,
        undefined,
        undefined,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getPostProcessingNotesDefinitions: (fields, ppn, callback) => {
      ModalSelectorActions.getPPNDefinitions(
        fields,
        ppn,
        undefined,
        undefined,
        callback)(dispatch)
    },
    getRecipientForHierarchy: (reci, rtype, predecessor, callback) => RecipientHierarchyActions.getRecipientForHierarchy(reci, rtype, predecessor, callback)(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRecipientDialog)