import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'

// components
import {
  Button, Card,
  Checkbox, Column, Dropdown, Input,
  MetaDataGrid,
  Modal as ModalComponent,
  Row, Switch
} from 'BetaUX2Web-Components/src/'
import SelectorDialog from 'components/dialogs/selector_dialog/SelectorDialog'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as IndexQueueActions from 'redux/actions/IndexQueueActions'
import * as ModalSelectorActions from 'redux/actions/ModalSelectorActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'
import * as StandardSelectionActions from 'redux/actions/StandardSelectionActions'
import * as Preferences from 'redux/general/Preferences'

import * as UserUtils from 'utils/UserUtils'
import * as Utils from 'utils/Utils'

export const ITEM_FORCE_AUTO_PRINT = 'AUTOPRINT'
export const ITEM_BUNDLE = 'BUNDLE'
export const ITEM_SCAN = 'SCAN'
export const ITEM_INDEX = 'INDEX'
export const ITEM_FILTER = 'FILTER'

export const PROCESS_TO_RERUN_ITEMS = [
  { key: ITEM_FORCE_AUTO_PRINT, translationKey: 'rerun.force_auto_print' },
  { key: ITEM_BUNDLE, translationKey: 'recipient.distribute_bundle' },
  { key: ITEM_SCAN, translationKey: 'rerun.scan' },
  { key: ITEM_INDEX, translationKey: 'definition.index' },
  { key: ITEM_FILTER, translationKey: 'definition.document_filter' },
]

const getProcessToRerunItems = existsReport => {
  // The structure has to match structure in 'renderAdditionalFields' (see below)
  // scan and filter only exists if document report is empty
  if (!existsReport) {
    if (UserUtils.isDOCX()) {
      return [
        { key: ITEM_FORCE_AUTO_PRINT, translationKey: 'rerun.force_auto_print' },
        { key: ITEM_BUNDLE, translationKey: 'recipient.distribute_bundle' },
        { key: ITEM_SCAN, translationKey: 'rerun.scan' },
        { key: ITEM_INDEX, translationKey: 'definition.index' },
        { key: ITEM_FILTER, translationKey: 'definition.document_filter' }
      ]
    } else if (UserUtils.isLOGX()) {
      return [
        { key: ITEM_FORCE_AUTO_PRINT, translationKey: 'rerun.force_auto_print' },
        { key: ITEM_INDEX, translationKey: 'definition.index' },
        { key: ITEM_FILTER, translationKey: 'definition.document_filter' }
      ]
    }
  } else {
    if (UserUtils.isDOCX()) {
      return [
        { key: ITEM_FORCE_AUTO_PRINT, translationKey: 'rerun.force_auto_print' },
        { key: ITEM_BUNDLE, translationKey: 'recipient.distribute_bundle' },
        { key: ITEM_INDEX, translationKey: 'definition.index' },
      ]
    } else if (UserUtils.isLOGX()) {
      return [
        { key: ITEM_FORCE_AUTO_PRINT, translationKey: 'rerun.force_auto_print' },
        { key: ITEM_INDEX, translationKey: 'definition.index' },
      ]
    }
  }
}

class RerunDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.string
  }

  state = {
    processToRerunIndex: 0,
    forRecipient: '',
    forReport: {
      value: '',
      error: ''
    },
    autoPrintForNewReports: false,
    autoBundleProcessforNewReports: false,
    forFilter: {
      value: '',
      error: ''
    },
    showRecipientDocumentAssignmentSelectorDialog: false,
    showReportSelectorDialog: false,
    showFilterSelectorDialog: false,
    currentProgress: 1,
    processAll: false
  }

  processToRerunInput = React.createRef()
  forReportInput = React.createRef()
  forFilterInput = React.createRef()

  /**
   * @description Initializes the values.
   */
  componentDidMount = () => {
    const { type, document, documentsToPrint } = this.props
    const { currentProgress } = this.state
    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentProgress-1] : document

    if (type) {
      const processToRerunIndex = Math.max(getProcessToRerunItems(documentToUse.REPORT !== '').findIndex(el => el.key === type), 0)
      this.setState({ processToRerunIndex: processToRerunIndex })
    }

    this.processToRerunInput.current.focus()
  }

  /**
   * @description Requests the recipient definition with the current selection.
   * On successful request it opens the seletor dialog.
   */
  openRecipientSelector = () => {
    const { document, getRecipientDocumentAssignments, documentsToPrint } = this.props
    const { forRecipient, currentProgress } = this.state
    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentProgress-1] : document

    getRecipientDocumentAssignments(
      // ['RECI', 'TITLE'],
      undefined,
      forRecipient,
      documentToUse.FORM,
      documentToUse.EXT,
      documentToUse.REPORT,
      documentToUse.WREPORT,
      () => this.setState({ showRecipientDocumentAssignmentSelectorDialog: true }))
  }

  /**
   * @description Requests the report definition with the current selection (Only with process 'REPORT').
   * On successful request it opens the selector dialog.
   */
  openReportSelector = () => {
    const { document, getDocumentDefinitions, documentsToPrint } = this.props
    const { forReport, currentProgress } = this.state
    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentProgress-1] : document

    const callback = () => this.setState({ showReportSelectorDialog: true })

    getDocumentDefinitions(['REPORT', 'VDATEB', 'VDATEE', 'SMODE'], documentToUse.FORM, documentToUse.EXT, forReport.value, undefined, 'REPORT', callback)
  }

  /**
   * @description Requests the document definition with the current selection (Only with smode 'FILTER').
   * On successful request it opens the selector dialog.
   */
  openFilterSelector = () => {
    const { document, getDocumentDefinitions, documentsToPrint } = this.props
    const { forFilter, currentProgress } = this.state
    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentProgress-1] : document

    const callback = () => this.setState({ showFilterSelectorDialog: true })

    getDocumentDefinitions(['REPORT', 'VDATEB', 'VDATEE'], documentToUse.FORM, documentToUse.EXT, forFilter.value, 'FILTER', undefined, callback)
  }

  /**
   * @description Gets the print definition in case of autoprint or bundle.
   * @returns {Object} The print definition.
   */
  getPrintDefinition = process => {
    const { document, selector, documentsToPrint } = this.props
    const { forRecipient, currentProgress, processAll } = this.state
    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentProgress-1] : document

    let docid
    if (documentsToPrint && processAll) {
      docid = []
      const documents = Object.values(documentsToPrint)
      for (let i = currentProgress - 1; i < documents.length; i++) {
        docid.push(documents[i]?.DOCID)
      }
    }
    else {
      docid = [documentToUse.DOCID]
    }

    const reci = forRecipient.length > 0 ? selector.recipientDocumentAssignments.data[0][selector.recipientDocumentAssignments.header.indexOf('RECI')] : '*'
    const dcr = forRecipient.length > 0 ? selector.recipientDocumentAssignments.data[0][selector.recipientDocumentAssignments.header.indexOf('DCR')] : ''
    const pcr = forRecipient.length > 0 ? selector.recipientDocumentAssignments.data[0][selector.recipientDocumentAssignments.header.indexOf('PCR')] : ''

    return (
      {
        'DOCIDS': docid,
        'RECI': reci,
        'DCR': dcr,
        'PCR': pcr,
        'REQUEST': 'REOUTP',
        'PROCESS': process
      }
    )
  }

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const {
      document, rescanDocument, reprintBundleDocument, userid,
      getRecipientDocumentAssignments, queueIndexRequest, documentsToPrint, onClose
    } = this.props
    const {
      forRecipient, forFilter, forReport, processToRerunIndex, autoBundleProcessforNewReports, autoPrintForNewReports, currentProgress, processAll
    } = this.state

    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentProgress-1] : document
    let docid = documentToUse.DOCID

    const closeCallback = successObjects => {
      if (successObjects !== undefined && successObjects?.length > 0) {
        if (!processAll) {
          this.nextDocument()
        }
        else {
          onClose()
        }
      }
    }

    const processToRerunItems = getProcessToRerunItems(documentToUse.REPORT !== '')

    // autoprint
    if (processToRerunItems[processToRerunIndex].key === ITEM_FORCE_AUTO_PRINT) {
      const reprintBundleCallback = () => reprintBundleDocument(this.getPrintDefinition('AUTO-P'), closeCallback)

      // only call document assignment if the recipient is not empty.
      // Otherwise just continue with an empty recipient. DCR and PCR will be set to '' too.
      if (forRecipient.length > 0) {
        getRecipientDocumentAssignments(
          undefined,
          forRecipient,
          documentToUse.FORM,
          documentToUse.EXT,
          documentToUse.REPORT,
          documentToUse.WREPORT,
          reprintBundleCallback)
      } else {
        reprintBundleCallback()
      }
    }
    // bundle
    else if (processToRerunItems[processToRerunIndex].key === ITEM_BUNDLE) {
      const reprintBundleCallback = () => reprintBundleDocument(this.getPrintDefinition('BUNDLE'), closeCallback)

      // only call document assignment if the recipient is not empty.
      // Otherwise just continue with an empty recipient. DCR and PCR will be set to '' too.
      if (forRecipient.length > 0) {
        getRecipientDocumentAssignments(
          undefined,
          forRecipient,
          documentToUse.FORM,
          documentToUse.EXT,
          documentToUse.REPORT,
          documentToUse.WREPORT,
          reprintBundleCallback)
      } else {
        reprintBundleCallback()
      }
    }
    // scan
    else if (processToRerunItems[processToRerunIndex].key === ITEM_SCAN) {
      if (this.validateScan()) {
        let scanProcess = ''
        // autoprint and bundle is true
        if (autoPrintForNewReports && autoBundleProcessforNewReports) {
          scanProcess = 'STOUTP'
        }
        // only autoprint is true
        else if (autoPrintForNewReports && !autoBundleProcessforNewReports) {
          scanProcess = 'AUTO-P'
        }
        // only bundle is true
        else if (!autoPrintForNewReports && autoBundleProcessforNewReports) {
          scanProcess = 'BUNDLE'
        }
        const rescanDefinition = {
          'DOCIDS': [docid],
          'REPORT': forReport.value,
          'REQUEST': 'RESCAN',
          'PROCESS': scanProcess
        }
        rescanDocument(rescanDefinition, closeCallback)
      }
    }
    // index
    else if (processToRerunItems[processToRerunIndex].key === ITEM_INDEX) {
      queueIndexRequest(docid, undefined, userid, 'IX', closeCallback)
    }
    // filter
    else if (processToRerunItems[processToRerunIndex].key === ITEM_FILTER) {
      if (this.validateFilter()) {
        queueIndexRequest(docid, forFilter.value, userid, 'FX', closeCallback)
      }
    }
  }

  nextDocument = () => {
    const { currentProgress } = this.state
    const { onClose, documentsToPrint } = this.props
    if (documentsToPrint && currentProgress < Object.values(documentsToPrint).length) {
      this.setState({ currentProgress: currentProgress + 1, forRecipient: '' })
    }
    else {
      onClose()
    }
  }

  /**
   * @description Validates the for filter.
   */
  validateForFilter = () => {
    const { forFilter } = this.state
    if (forFilter.value !== '') {
      return {}
    }
    return {
      forFilter: {
        ...this.state.forFilter,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the for filter inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateFilter = () => {
    const validatorResult = {
      ...this.validateForFilter(),
    }

    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        const requiredInputs = [
          { inputRef: this.forFilterInput, error: this.state.forFilter.error },
        ]
        Utils.setFocus(requiredInputs)
      })
    }
    return errors === 0
  }

  /**
   * @description Validates the for report.
   */
  validateForReport = () => {
    const { forReport } = this.state
    if (forReport.value !== '') {
      return {}
    }
    return {
      forReport: {
        ...this.state.forReport,
        error: translate('general.input_required')
      }
    }
  }

  /**
   * @description Validates the scan inputs. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateScan = () => {
    const validatorResult = {
      ...this.validateForReport(),
    }

    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ ...validatorResult }, () => {
        const requiredInputs = [
          { inputRef: this.forReportInput, error: this.state.forReport.error },
        ]
        Utils.setFocus(requiredInputs)
      })
    }
    return errors === 0
  }

  /**
   * @description Renders the additional fields if process to rerun is bundle process.
   */
  renderAdditionalBundleProcessFields = () => {
    const { id } = this.props
    const { forRecipient } = this.state

    return (
      <Column colMD={3}>
        <Input
          id={`${id}_for_recipient`}
          onInputChanged={(value) => this.setState({ forRecipient: value })}
          value={forRecipient}
          title={translate('rerun.for_recipient')}
          maxLength={16}
          addon={{
            iconName: 'list',
            onClick: () => this.openRecipientSelector()
          }}
        />
      </Column>
    )
  }

  /**
   * @description Renders the additional fields if process to rerun is autoprint.
   */
  renderAdditionalAutoprintFields = () => {
    const { id } = this.props
    const { forRecipient } = this.state

    return (
      <Column colMD={3}>
        <Input
          id={`${id}_for_recipient`}
          onInputChanged={(value) => this.setState({ forRecipient: value })}
          value={forRecipient}
          title={translate('rerun.for_recipient')}
          maxLength={16}
          addon={{
            iconName: 'list',
            onClick: () => this.openRecipientSelector()
          }}
        />
      </Column>
    )
  }

  /**
   * @description Renders the additional fields if process to rerun is filter.
   */
  renderAdditionalFilterFields = () => {
    const { id } = this.props
    const { forFilter } = this.state

    return (
      <Column colMD={3}>
        <Input
          id={`${id}_for_filter`}
          onInputChanged={(value) => this.setState({ forFilter: { value: value, error: '' } })}
          value={forFilter.value}
          title={translate('rerun.for_filter')}
          maxLength={16}
          ref={this.forFilterInput}
          error={forFilter.error ? forFilter.error : null}
          onBlur={() => this.setState({ ...this.validateForFilter() })}
          required={`${translate('general.required_field')}`}
          addon={{
            iconName: 'list',
            onClick: () => this.openFilterSelector()
          }}
        />
      </Column>
    )
  }

  /**
   * @description Renders the additional fields if process to rerun is scan.
   */
  renderAdditionalScanFields = () => {
    const { id } = this.props
    const { forReport, autoPrintForNewReports, autoBundleProcessforNewReports } = this.state

    return (
      <>
        <Column colMD={3}>
          <Input
            id={`${id}_for_report`}
            onInputChanged={(value) => this.setState({ forReport: { value: value, error: '' } })}
            value={forReport.value}
            title={translate('rerun.for_report')}
            maxLength={16}
            ref={this.forReportInput}
            error={forReport.error ? forReport.error : null}
            onBlur={() => this.setState({ ...this.validateForReport() })}
            required={`${translate('general.required_field')}`}
            addon={{
              iconName: 'list',
              onClick: () => this.openReportSelector()
            }}
          />
        </Column>
        <Column colMD={3}>
          <Switch
            id={`${id}_auto_print_for_new_reports`}
            title={translate('rerun.auto_print_for_new_reports')}
            items={[translate('general.yes'), translate('general.no')]}
            onClick={index => this.setState({ autoPrintForNewReports: index === 0 })}
            activeIndex={autoPrintForNewReports ? 0 : 1}
          />
        </Column>
        <Column colMD={3}>
          <Switch
            id={`${id}_auto_bundle_process_for_new_reports`}
            title={translate('rerun.auto_bundle_process_for_new_reports')}
            items={[translate('general.yes'), translate('general.no')]}
            onClick={index => this.setState({ autoBundleProcessforNewReports: index === 0 })}
            activeIndex={autoBundleProcessforNewReports ? 0 : 1}
          />
        </Column>
      </>
    )
  }

  /**
   * @description Translates the shortcut into a readable version
   * @param {String} shortcut
   */
  translateStatus = shortcut => {
    switch (shortcut) {
      case 'H': return translate('search.hold')
      case 'C': return translate('search.control')
      default: return shortcut
    }
  }

  /**
   * @description Renders the header.
   */
  renderHeader = () => {
    const { id, document, documentsToPrint } = this.props
    const { currentProgress } = this.state
    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentProgress-1] : document
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('general.form'), value: documentToUse.FORM },
          { label: translate('general.extension'), value: documentToUse.EXT },
          { label: translate('general.report'), value: documentToUse.REPORT },
          { label: translate('general.dynamic_report'), value: documentToUse.WREPORT },
          { label: translate('general.status'), value: this.translateStatus(documentToUse?.STATUS) },
          { label: translate('general.doctype'), value: documentToUse.SRCJOBI },
          { label: translate('search.online'), value: documentToUse.LGRONLNE, yesNo: true },
          { label: translate('search.autoprint'), value: documentToUse.LGRAUTO, yesNo: true },
        ]}
        columns={4}
        translate={translate}
      />
    )
  }

  /**
   * @description Renders the additional fields based on the current selection of processToRerun
   */
  renderAdditionalFields = () => {
    const { document, documentsToPrint } = this.props
    const { processToRerunIndex, currentProgress } = this.state

    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentProgress-1] : document

    // The structure has to match structure in 'getProcessToRerunItems' (see above)
    if (UserUtils.isDOCX() && documentToUse.REPORT === '') {
      switch (processToRerunIndex) {
        // autoprint
        case 0: return this.renderAdditionalAutoprintFields()
        // bundle
        case 1: return this.renderAdditionalBundleProcessFields()
        // scan
        case 2: return this.renderAdditionalScanFields()
        // index which is on position 3 has no additional fields
        // filter
        case 4: return this.renderAdditionalFilterFields()
        default: return <></>
      }
    }

    if (UserUtils.isLOGX() && documentToUse.REPORT === '') {
      switch (processToRerunIndex) {
        // autoprint
        case 0: return this.renderAdditionalAutoprintFields()
        // index which is on position 1 has no additional fields
        // filter
        case 2: return this.renderAdditionalFilterFields()
        default: return <></>
      }
    }

    if (UserUtils.isDOCX() && documentToUse.REPORT !== '') {
      switch (processToRerunIndex) {
        // autoprint
        case 0: return this.renderAdditionalAutoprintFields()
        // bundle
        case 1: return this.renderAdditionalBundleProcessFields()
        // index which is on position 2 has no additional fields
        default: return <></>
      }
    }

    if (UserUtils.isLOGX() && documentToUse.REPORT !== '') {
      switch (processToRerunIndex) {
        // autoprint
        case 0: return this.renderAdditionalAutoprintFields()
        // index which is on position 1 has no additional fields
        default: return <></>
      }
    }
  }

  /**
   * @description Renders the main section.
   */
  renderMain = () => {
    const { id, type, document, documentsToPrint } = this.props
    const { processToRerunIndex, currentProgress } = this.state

    const documentToUse = documentsToPrint ? Object.values(documentsToPrint)[currentProgress-1] : document

    return (
      <Card>
        <Row>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_process_to_rerun`}
              focusRef={this.processToRerunInput}
              items={getProcessToRerunItems(documentToUse.REPORT !== '').map(el => translate(el.translationKey))}
              activeIndex={processToRerunIndex}
              disabled={getProcessToRerunItems(documentToUse.REPORT !== '').findIndex(el => el.key === type) !== -1}
              onChange={index => this.setState({ processToRerunIndex: index })}
              title={translate('rerun.process_to_rerun')}
            />
          </Column>
          {this.renderAdditionalFields()}
        </Row>
      </Card>
    )
  }

  /**
   * @description Renders the selector dialogs
   */
  renderSelectorDialogs = () => {
    const { id, selector } = this.props
    const { showRecipientDocumentAssignmentSelectorDialog, showReportSelectorDialog, showFilterSelectorDialog } = this.state

    // filter all items with smode filter
    // remove smode  because we only need smode for filter and dont want to show it in selector dialog
    let reportItems = []
    if (selector.documents && selector.documents.data) {
      reportItems = selector.documents.data
        .filter(el => el[3] !== 'FILTER')
        .map(el => el.slice(0, -1))
    }

    // only use form, distribute via and assignment for selector dialog
    let recipientAssignmentItems = []
    if (selector.recipientDocumentAssignments && selector.recipientDocumentAssignments.data) {
      recipientAssignmentItems = selector.recipientDocumentAssignments.data
        .map(el => {
          const row = []
          // form
          row.push(el[selector.recipientDocumentAssignments.header.indexOf('RECI')])
          // distribute via
          row.push(el[selector.recipientDocumentAssignments.header.indexOf('BRWS')])
          // assignment
          row.push(el[selector.recipientDocumentAssignments.header.indexOf('LTYPE')])
          return row
        })
    }

    return (
      <>
        {showRecipientDocumentAssignmentSelectorDialog && (
          <SelectorDialog
            id={`${id}_recipientdefinition_selector_dialog`}
            onClose={() => this.setState({ showRecipientDocumentAssignmentSelectorDialog: false })}
            title={translate('definition.recipientdefinitions')}
            header={[
              translate('general.recipient'),
              translate('recipient.distribution_via'),
              translate('general.assignment_single'),
            ]}
            items={recipientAssignmentItems}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const recipientID = selector.recipientDocumentAssignments.data[selectedRows][selector.recipientDocumentAssignments.header.indexOf('RECI')]
                this.setState({ forRecipient: recipientID })
              }
              this.setState({ showRecipientDocumentAssignmentSelectorDialog: false })
            }}
          />
        )}
        {showReportSelectorDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showReportSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.report'),
              translate('general.valid_from'),
              translate('general.valid_to')
            ]}
            items={reportItems}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const report = reportItems[selectedRows][selector.documents.header.indexOf('REPORT')]
                this.setState({ forReport: { value: report, error: '' } })
              }
              this.setState({ showReportSelectorDialog: false })
            }}
          />
        )}
        {showFilterSelectorDialog && (
          <SelectorDialog
            id={`${id}_documentdefinition_selector_dialog`}
            onClose={() => this.setState({ showFilterSelectorDialog: false })}
            title={translate('definition.documentdefinitions')}
            header={[
              translate('general.report'),
              translate('general.valid_from'),
              translate('general.valid_to')
            ]}
            items={selector.documents.data}
            onSelect={selectedRows => {
              if (selectedRows.length > 0) {
                const report = selector.documents.data[selectedRows][selector.documents.header.indexOf('REPORT')]
                this.setState({ forFilter: { value: report, error: '' } })
              }
              this.setState({ showFilterSelectorDialog: false })
            }}
          />
        )}
      </>
    )
  }

  render = () => {
    const { id, onClose, documentsToPrint } = this.props
    const { processAll, currentProgress } = this.state
    return (
      <>
        {this.renderSelectorDialogs()}
        <Modal
          id={`${id}_rerun_dialog`}
          onClose={onClose}>
          <Header
            id={`${id}_header`}
            title={translate('queue.rerun')}
            onClose={onClose}>
            {this.renderHeader()}
          </Header>
          <Main>
            {this.renderMain()}
          </Main>
          <Footer>
            {
              documentsToPrint &&
              <>
                <span className={'badm_print_progress'}>{`${currentProgress} of ${Object.values(documentsToPrint).length} ${translate('general.items_lowercase')}`}</span>
                <Checkbox
                  id={`${id}_request_all`}
                  onCheck={isChecked => this.setState({ processAll: isChecked })}
                  value={processAll}
                  label={translate('general.request_all')}
                />
              </>
            }
            <Button
              id={`${id}_cancelbtn`}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            {
              documentsToPrint &&
              <Button
                id={`${id}_skip_button`}
                text={translate('general.skip')}
                onClick={() => this.nextDocument()}
                disabled={processAll}
                tooltip={processAll && translate('rerun.skip_disabled')}
              />
            }
            <Button
              id={`${id}_savebtn`}
              text={processAll ? `${translate('general.confirm')} (${Object.values(documentsToPrint).length - currentProgress + 1})` : translate('general.confirm')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    preferences: state.auth.serverdata.preferences,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    selector: state.selector,
    recipient: state.definitions.recipients.recipient
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
    getRecipientDocumentAssignments: (fields, recipient, form, extension, report, wreport, callback) => {
      ModalSelectorActions.getRecipientDocumentAssignments(
        fields,
        recipient,
        form,
        extension,
        report,
        wreport,
        undefined, // assignment
        undefined, // distribution type
        undefined, // outputchannel
        undefined, // outputformat
        undefined, // ppn
        callback)(dispatch)
    },
    getDocumentDefinitions: (fields, form, extension, report, smode, process, callback) => {
      ModalSelectorActions.getDocumentDefinitions(
        fields,
        form,
        extension,
        report,
        smode, // smode
        process, // process
        undefined, // owner
        undefined, // title
        undefined, // ppn
        callback,
        true)(dispatch)
    },
    rescanDocument: (definition, callback) => {
      StandardSelectionActions.rescanDocument(
        definition,
        callback
      )(dispatch)
    },
    reprintBundleDocument: (definition, callback) => {
      StandardSelectionActions.reprintBundleDocument(
        definition,
        callback
      )(dispatch)
    },
    queueIndexRequest: (docids, filter, user, process, callback) => {
      IndexQueueActions.queueIndexRequest(
        docids,
        filter,
        user,
        undefined,
        process,
        callback
      )(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RerunDialog)