import * as DocViewerActions from '../actions/DocViewerActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  tabs: [],
}

// Note on reconciliation: 'isOpen' manually set to 'true' to handle reconciliation of 'redux-state-sync' library
// Mounting the Document Viewer will set the open status to 'true', but the library will receive the initial state from the main app which is 'false' when opening 1st tab or when reloading the page.
// Receiving the initial state is done after mounting, effectively overwriting the mounting effect to set the document viewer open status to 'true'

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // DocViewer actions: Add document data to tabs
    case DocViewerActions.DOCVIEWER_ADD_TAB_START:
      return { ...state }
    case DocViewerActions.DOCVIEWER_ADD_TAB_SUCCESS:
      return { ...state, tabs: [...state.tabs, { ...action.payload.tabDataToBeAdded, pagesHexBlock: {}, pagesHexLine: {}, pagesText: { data: [] }, isFetching: false, indexToHighlight: {} }] }
    case DocViewerActions.DOCVIEWER_ADD_TAB_FAIL:
      return { ...state }
    /* ********************************************************************************** */
    // DocViewer actions: Add text content to document data
    case DocViewerActions.DOCVIEWER_ADD_TAB_CONTENT_TEXT_START:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, isFetching: true } : tab) }
    case DocViewerActions.DOCVIEWER_ADD_TAB_CONTENT_TEXT_SUCCESS:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, pagesText: { ...action.payload.pagesText, data: action.payload.pagesText.data }, pagesHexBlock: {}, pagesHexLine: {}, isFetching: false } : tab) } // Disable caching by removing cached content for same page to store only latest rendered data
    case DocViewerActions.DOCVIEWER_ADD_TAB_CONTENT_TEXT_FAIL:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, isFetching: false, error: action.payload.error } : tab) }
    // DocViewer actions: Add hex block content to document data
    case DocViewerActions.DOCVIEWER_ADD_TAB_CONTENT_HEX_BLOCK_START:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, isFetching: true } : tab) }
    case DocViewerActions.DOCVIEWER_ADD_TAB_CONTENT_HEX_BLOCK_SUCCESS:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, pagesText: { data: [] }, pagesHexBlock: action.payload.pagesHexBlock, pagesHexLine: {}, isFetching: false } : tab) } // Disable caching by removing cached content to store only latest rendered data
    case DocViewerActions.DOCVIEWER_ADD_TAB_CONTENT_HEX_BLOCK_FAIL:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, isFetching: false, error: action.payload.error } : tab) }
    // DocViewer actions: Add hex line content to document data
    case DocViewerActions.DOCVIEWER_ADD_TAB_CONTENT_HEX_LINE_START:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, isFetching: true } : tab) }
    case DocViewerActions.DOCVIEWER_ADD_TAB_CONTENT_HEX_LINE_SUCCESS:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, pagesText: { data: [] }, pagesHexBlock: {}, pagesHexLine: action.payload.pagesHexLine, isFetching: false } : tab) } // Disable caching by removing cached content to store only latest rendered data
    case DocViewerActions.DOCVIEWER_ADD_TAB_CONTENT_HEX_LINE_FAIL:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, isFetching: false, error: action.payload.error } : tab) }
    // DocViewer actions: Getting page count via job details request
    case DocViewerActions.DOCVIEWER_GET_PAGE_COUNT_START:
      return { ...state }
    case DocViewerActions.DOCVIEWER_GET_PAGE_COUNT_SUCCESS:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, pageCount: action.payload.pageCount } : tab) }
    case DocViewerActions.DOCVIEWER_GET_PAGE_COUNT_FAIL:
      return { ...state }
    /* ********************************************************************************** */
    // DocViewer actions: Add index highlight to document
    case DocViewerActions.DOCVIEWER_ADD_HIGHLIGHT_INDEX:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, indexToHighlight: action.payload.indexData } : tab)}
    /* ********************************************************************************** */
    // DocViewer actions: Remove index highlight from document
    case DocViewerActions.DOCVIEWER_REMOVE_HIGHLIGHT_INDEX:
      return { ...state, tabs: state.tabs.map((tab) => tab.id === action.payload.id ? { ...tab, indexToHighlight: {} } : tab)}
    /* ********************************************************************************** */
    // DocViewer actions: Remove document data from tabs
    case DocViewerActions.DOCVIEWER_REMOVE_TABS_SUCCESS:
      return { ...state, tabs: state.tabs.filter((tabData) => !action.payload.tabIdsToBeRemoved.includes(tabData.id)) }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return defaultState
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer