import React from 'react';
import './TitledLabel.scss';


interface TitledLabelProps {
  /** Unique ID for identification in HTML DOM. */
  id: string;
  /** This ID will be used for integration tests to access the HTML element */
  dataTestId?: string;
  /** The text which is shown above the TitledLabel. */
  title?: string;
  /** The text content */
  text?: string;
  /** If true, the title will be with bold font.*/
  boldTitle?: boolean;
  /** If true, the text will be with bold font.*/
  boldText?: boolean;
  /** Disable label*/
  disabled?: boolean;
  /** Margin bottom*/
  marginBottom?: boolean;
  /** If true and value is empty will be replace with '-'*/
  noEmpty?: true;
  /** style */
  style?: React.CSSProperties;

}

export const TitledLabel = (props: TitledLabelProps): JSX.Element => {
  const { id, dataTestId, title, text, boldTitle, boldText, disabled, marginBottom, noEmpty, style } = props;
  return (
    <div
      className='title_label_container'
      style={marginBottom ? {marginBottom: '.25rem', ...style} : {marginBottom: '0', ...style}}>
      <div
        id={`${id}_title`}
        title={title}
        data-testid={dataTestId ? `${dataTestId}_title` : undefined}
        className={`title_label_desc ${boldTitle && 'title_bold'} ${disabled && 'title_label_disabled'}`}>
        {title}
      </div>
      <div
        id={`${id}_text`}
        data-testid={`${dataTestId}_text`}
        title={text}
        className={`title_label_value ${disabled && 'title_label_disabled'} ${boldText && 'title_bold'}` }>
        {noEmpty ? (text ? text : '-'): text }
      </div>
    </div>
  );
};