import * as IndexDefinitionActions from '../actions/IndexDefinitionActions'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  indexes: undefined,
  index: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get indexes actions
    case IndexDefinitionActions.INDEX_DEFINITION_GET_INDEXES_START:
      return { ...state }
    case IndexDefinitionActions.INDEX_DEFINITION_GET_INDEXES_SUCCESS:
      return { ...state, indexes: action.payload, keepPagination: action.keepPagination }
    case IndexDefinitionActions.INDEX_DEFINITION_GET_INDEXES_FAILED:
      return { ...state }
    case IndexDefinitionActions.NO_INDEX_DEFINITION_INDEXES_FOUND:
      return { ...state, indexes: {} }
    /* ********************************************************************************** */
    // Definition get index actions
    case IndexDefinitionActions.INDEX_DEFINITION_GET_INDEX_START:
      return { ...state, index: undefined }
    case IndexDefinitionActions.INDEX_DEFINITION_GET_INDEX_SUCCESS:
      return { ...state, index: action.payload }
    case IndexDefinitionActions.INDEX_DEFINITION_GET_INDEX_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition create index actions
    case IndexDefinitionActions.INDEX_DEFINITION_CREATE_INDEX_START:
      return { ...state }
    case IndexDefinitionActions.INDEX_DEFINITION_CREATE_INDEX_SUCCESS:
      return { ...state }
    case IndexDefinitionActions.INDEX_DEFINITION_CREATE_INDEX_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete index actions
    case IndexDefinitionActions.INDEX_DEFINITION_DELETE_INDEX_START:
      return { ...state }
    case IndexDefinitionActions.INDEX_DEFINITION_DELETE_INDEX_SUCCESS:
      return { ...state }
    case IndexDefinitionActions.INDEX_DEFINITION_DELETE_INDEX_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition update index actions
    case IndexDefinitionActions.INDEX_DEFINITION_UPDATE_INDEX_START:
      return { ...state }
    case IndexDefinitionActions.INDEX_DEFINITION_UPDATE_INDEX_SUCCESS:
      return { ...state }
    case IndexDefinitionActions.INDEX_DEFINITION_UPDATE_INDEX_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition verify index actions
    case IndexDefinitionActions.INDEX_DEFINITION_VERIFY_START:
      return { ...state }
    case IndexDefinitionActions.INDEX_DEFINITION_VERIFY_SUCCESS:
      return { ...state, verify: action.payload }
    case IndexDefinitionActions.INDEX_DEFINITION_VERIFY_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, indexes: undefined, index: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer