export interface IndexInfo {
  externalIndexName: string;
  internalIndexName: string;
  maxLength: number;
  numberOfExisting: number;
  type: string;
  securityIndex: boolean;
  upperCase: string;
}

export interface IndexCriteria {
  internalIndexName: string;
  from: string;
  to: string;
}

export enum CriteriaOperator {
  LESS = 'LT',
  LESS_EQUAL = 'LE',
  GREATER = 'GT',
  GREATER_EQUAL = 'GE',
  EQUAL = 'EQ'
}


export interface SearchByIndexResult {
  internalIndexName: string;
  maxLength: number;
  value: string;
  position: IndexPosition;
  positionFrom: IndexPosition;
  positionTo: IndexPosition;
  docId: string;
}

export interface IndexPosition {
  page: number;
  row: number;
  column: number;
}

// helpers
export const convertResponseObjToIndexInfo = (respObj: Record<string, string>[] | null | undefined): IndexInfo[] => {
  let convertedArray: IndexInfo[] = []
  if (respObj) {
    convertedArray = respObj.map((obj) => {
      return ({
        externalIndexName: obj.IXENAME,
        internalIndexName: obj.IXINAME,
        maxLength: parseInt(obj.IDXLNG),
        numberOfExisting: parseInt(obj.IDXCOUNT),
        type: obj.IDXTYPE,
        securityIndex: obj.SECINDEX === 'Y',
        upperCase: obj.UPCS

      } as IndexInfo);
    })
  }
  return convertedArray;
}

export const indexPositionInitialFromString = (page: string, row: string, col: string) => {
  return {page: parseInt(page), row: parseInt(row), column: parseInt(col)}
}


export const convertResponseObjToSearchByIndexResult = (respObj: Record<string, string>[] | null | undefined): SearchByIndexResult[] => {
  let convertedArray: SearchByIndexResult[] = []
  if (respObj) {
    convertedArray = respObj.map((obj) => {
      return ({
        internalIndexName: obj.IXINAME,
        maxLength: parseInt(obj.IDXLNG),
        value: obj.IDX,
        position: indexPositionInitialFromString(obj.PAGE, obj.ROW, obj.COL),
        positionFrom: indexPositionInitialFromString(obj.PAGEF, obj.ROWF, obj.COLF),
        positionTo: indexPositionInitialFromString(obj.PAGET, obj.ROWT, obj.COLT),
        docId: obj.DOCID
      } as SearchByIndexResult);
    })
  }
  return convertedArray;
}