import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { translate } from 'language/Language'

// components
import {
  Button,
  Column, Dropdown, Icon, Input,
  MetaDataGrid,
  Modal as ModalComponent, NumericSpinner, Row, Switch, Tab,
  Tabs,
  Toggle, Tooltip
} from 'BetaUX2Web-Components/src/'
import Datepicker from '../../datepicker/Datepicker'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as IndexDocumentAssignmentActions from 'redux/actions/IndexDocumentAssignmentActions'
import * as Preferences from 'redux/general/Preferences'
import * as AssignmentUtils from 'utils/AssignmentUtils'
import * as DateUtils from 'utils/DateUtils'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as UserUtils from 'utils/UserUtils'

class ModifyIndexDocumentAssignmentDialog extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    generalTab: {
      errorTabs: [],
      dateFormat: 0,
      dateMask: 0,
      timeString: '',
      lcTime: '',
      decimalMark: 0,
      validFrom: {
        value: '',
        error: ''
      },
      validTo: {
        value: '',
        error: ''
      },
      available: 0,
      ignoreIndexValueWithWrongDataFormat: false,
      indexValuesAreRequired: false,
      debugMode: false,
      securityCheck: false
    },
    sourceTab: {
      determineIndexValuesFrom: 0,
      documentVariable: 0,
      tleName: {
        value: '',
        error: ''
      },
      indexValuesFrom: 0,
      searchPatternDocumentTextBrowser: {
        value: '',
        error: ''
      },
      searchPatternPJL: {
        value: '',
        error: ''
      },
      fromLine: 0,
      toLine: 0,
      fromColumn: 0,
      toColumn: 0,
      detectMatches: 2,
      hitRange: 0
    },
    indexValuesTab: {
      fromColumn: 0,
      relativeTo: 0,
      inLengthOf: 1,
      maximallyUpToEndTag: '',
      alignment: 0,
      rule: '',
      substituteValues1: '',
      withValues1: '',
      substituteValues2: '',
      withValues2: '',
      substituteValues3: '',
      withValues3: '',
      substituteValues4: '',
      withValues4: '',
    },
    conditionsTab: {
      extractSearchPattern: '',
      extractFromLine: 0,
      extractToLine: 0,
      extractFromColumn: 0,
      extractToColumn: 0,
      ignoreSearchPattern: '',
      ignoreFromLine: 0,
      ignoreToLine: 0,
      ignoreFromColumn: 0,
      ignoreToColumn: 0,
    }
  }

  validFromInput = React.createRef()
  validToInput = React.createRef()
  searchPatternDocumentTextBrowserInput = React.createRef()
  searchPatternPJLInput = React.createRef()
  tleNameInput = React.createRef()

  /**
   * @description Initializes the values from the preferences.
   */
  componentDidMount = () => {
    const { indexDocumentAssignment } = this.props

    let indexValuesFrom = 0
    const determineIndexValuesFrom = AssignmentUtils.DETERMINE_INDEX_VALUES_FROM.findIndex(el => {
      if (el.key instanceof Array) {
        const result = el.key.includes(indexDocumentAssignment.IXBASED)
        // set indexValuesFrom only in document text browser -> this is the only item which is an array
        if (result) {
          indexValuesFrom = el.key.findIndex(el2 => el2 === indexDocumentAssignment.IXBASED)
        } else {
          indexValuesFrom = el.key.findIndex(el2 => el2 === AssignmentUtils.MASK)
        }
        return result
      } else {
        return el.key === indexDocumentAssignment.IXBASED
      }
    })

    // document text browser has an array as key thats why we use it as the unique indicator
    const searchPatternDocumentTextBrowser = AssignmentUtils.DETERMINE_INDEX_VALUES_FROM[determineIndexValuesFrom].key instanceof Array &&
      indexDocumentAssignment.IXSMASK ? indexDocumentAssignment.IXSMASK : ''

    const initDocumentVariable = () => {
      if (indexDocumentAssignment.IXBASED === AssignmentUtils.DOCV) {
        return this.getDocumentVariableItems().findIndex(el => el === indexDocumentAssignment.IXSMASK)
      } else {
        return this.getDocumentVariableItems().findIndex(el => el === '&DOCUSR1')
      }
    }
    const dateMaskIndex = indexDocumentAssignment.IXIDMASK
      ? this.getDatemaskItems().indexOf(indexDocumentAssignment.IXIDMASK)
      : 0
    this.setState({
      generalTab: {
        errorTabs: [],
        dateFormat: indexDocumentAssignment.IXIDMASK ? 0 : 1,
        dateMask: dateMaskIndex,
        timeString: indexDocumentAssignment.IXUDTSTR,
        lcTime: indexDocumentAssignment.IXUDTIME,
        decimalMark: indexDocumentAssignment.IDSPOINT ? 1 : 0,
        validFrom: {
          value: DateUtils.toMoment(indexDocumentAssignment.VDATEB, DateUtils.DDMMYYYY_DOT),
          error: ''
        },
        validTo: {
          value: DateUtils.toMoment(indexDocumentAssignment.VDATEE, DateUtils.DDMMYYYY_DOT),
          error: ''
        },
        available: indexDocumentAssignment.IXSCOPE === 'GLOBAL' ? 1 : 0,
        ignoreIndexValueWithWrongDataFormat: indexDocumentAssignment.IXSKIPF,
        indexValuesAreRequired: indexDocumentAssignment.IXENODAT,
        debugMode: indexDocumentAssignment.IXDEBUG,
        securityCheck: indexDocumentAssignment.IXSCHECK
      },
      sourceTab: {
        ...this.state.sourceTab,
        determineIndexValuesFrom: determineIndexValuesFrom,
        documentVariable: initDocumentVariable(),
        tleName: {
          value: indexDocumentAssignment.IXBASED === AssignmentUtils.ACIF && indexDocumentAssignment.IXSMASK ? indexDocumentAssignment.IXSMASK : '',
          error: ''
        },
        indexValuesFrom: indexValuesFrom,
        searchPatternDocumentTextBrowser: {
          value: searchPatternDocumentTextBrowser,
          error: ''
        },
        searchPatternPJL: {
          value: AssignmentUtils.DETERMINE_INDEX_VALUES_FROM[determineIndexValuesFrom].key === AssignmentUtils.PJL && indexDocumentAssignment.IXSMASK ? indexDocumentAssignment.IXSMASK : '',
          error: ''
        },
        fromLine: indexDocumentAssignment.IXSSLINE ? parseInt(indexDocumentAssignment.IXSSLINE, 10) : 0,
        toLine: indexDocumentAssignment.IXSELINE ? parseInt(indexDocumentAssignment.IXSELINE, 10) : 0,
        fromColumn: indexDocumentAssignment.IXSSCOL ? parseInt(indexDocumentAssignment.IXSSCOL, 10) : 0,
        toColumn: indexDocumentAssignment.IXSECOL ? parseInt(indexDocumentAssignment.IXSECOL, 10) : 0,
        detectMatches: indexDocumentAssignment.IXMATCH ? Math.max(AssignmentUtils.DETECT_MATCHES.findIndex(el => el.key === indexDocumentAssignment.IXMATCH), 0) : Math.max(AssignmentUtils.DETECT_MATCHES.findIndex(el => el.key === AssignmentUtils.ANY), 0),
        hitRange: indexDocumentAssignment.IXDATASC
          ? Math.max(AssignmentUtils.HIT_RANGE.findIndex(el => el.key === indexDocumentAssignment.IXDATASC), 0)
          : Math.max(AssignmentUtils.HIT_RANGE.findIndex(el => el.key === AssignmentUtils.PAGE), 0)
      },
      indexValuesTab: {
        fromColumn: parseInt(indexDocumentAssignment.IXEXTPOS, 10),
        relativeTo: Math.max(AssignmentUtils.RELATIVE_TO.findIndex(el => el.key === indexDocumentAssignment.IXEXTREL), 0),
        inLengthOf: parseInt(indexDocumentAssignment.IXEXTLEN, 10),
        maximallyUpToEndTag: indexDocumentAssignment.IXEMASK,
        alignment: indexDocumentAssignment.IALIGNTR ? 1 : 0,
        rule: indexDocumentAssignment.IXFOMRUL,
        substituteValues1: indexDocumentAssignment.IXRUL1I,
        withValues1: indexDocumentAssignment.IXRUL1O,
        substituteValues2: indexDocumentAssignment.IXRUL2I,
        withValues2: indexDocumentAssignment.IXRUL2O,
        substituteValues3: indexDocumentAssignment.IXRUL3I,
        withValues3: indexDocumentAssignment.IXRUL3O,
        substituteValues4: indexDocumentAssignment.IXRUL4I,
        withValues4: indexDocumentAssignment.IXRUL4O,
      },
      conditionsTab: {
        extractSearchPattern: indexDocumentAssignment.IXPMASK,
        extractFromLine: parseInt(indexDocumentAssignment.IXPSLINE, 10),
        extractToLine: parseInt(indexDocumentAssignment.IXPELINE, 10),
        extractFromColumn: parseInt(indexDocumentAssignment.IXPSCOL, 10),
        extractToColumn: parseInt(indexDocumentAssignment.IXPECOL, 10),
        ignoreSearchPattern: indexDocumentAssignment.IXXMASK,
        ignoreFromLine: parseInt(indexDocumentAssignment.IXXSLINE, 10),
        ignoreToLine: parseInt(indexDocumentAssignment.IXXELINE, 10),
        ignoreFromColumn: parseInt(indexDocumentAssignment.IXXSCOL, 10),
        ignoreToColumn: parseInt(indexDocumentAssignment.IXXECOL, 10),
      }
    })
  }

  /**
   * @description Gets the datemask items.
   * @returns {Array} The datemask items.
   */
  getDatemaskItems() {
    return [
      'DD/MM/YYYY',
      'DD/MM/YY',
      'MM/DD/YYYY',
      'MM/DD/YY',
      'DD.MM.YYYY',
      'DD.MM.YY',
      'YYYY-MM-DD',
      'YY-MM-DD',
      'YYYY.DDD',
      'YY.DDD'
    ]
  }

  /**
   * @description Will be called when a input value is changed in general tab.
   * @param {String} key The key in state.
   * @param {*} val The new value.
   * @param {Boolean} isRequired Flag if the input is required.
   */
  handleGeneralTabInputChanged = (key, val, isRequired) => {
    if (isRequired) {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          [key]: {
            value: val,
            error: ''
          }
        }
      })
    } else {
      this.setState({
        generalTab: {
          ...this.state.generalTab,
          [key]: val
        }
      })
    }
  }

  /**
   * @description Renders the header.
   */
  renderHeader = () => {
    const { id, indexDocumentAssignment, datemask } = this.props
    const idxType = DefinitionUtils.getIndexDataFormatTypes(false).find(el => el.key === indexDocumentAssignment.IXDTYPE)
    return (
      <MetaDataGrid
        id={`${id}_header`}
        metaData={[
          { label: translate('definition.index_id'), value: indexDocumentAssignment?.IXINAME},
          { label: translate('general.data_format'), value: idxType ? translate(idxType.translationKey) : indexDocumentAssignment.IXDTYPE },
          { label: translate('general.variant'), value: indexDocumentAssignment.IXINSTNR },
          { label: translate('general.identifier'), value: indexDocumentAssignment.IXENAME },
          { label: translate('general.form'), value: indexDocumentAssignment.FORM },
          { label: translate('general.extension'), value: indexDocumentAssignment.EXT },
          { label: translate('general.report'), value: indexDocumentAssignment.REPORT },
          { label: translate('general.last_changed'), value: DateUtils.getDate(datemask, indexDocumentAssignment.CDATE, indexDocumentAssignment.CTIME.substring(0, 8)) + ' ' + translate('general.by') + ' ' + indexDocumentAssignment.CUSER },
        ]}
        columns={4}
      />
    )
  }

  /**
   * @description Validates the general tab.
   * @returns {Boolean} False if there is an error in the general tab.
   */
  validateGeneralTab = () => {
    const validatorResult = {
      ...this.validateValidFromDate(),
      ...this.validateValidToDate()
    }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ generalTab: { ...this.state.generalTab, ...validatorResult } }, () => {
        this.handleFocusGeneralTab()
      })
    }
    return errors === 0
  }

  /**
   * @description Validates the valid from.
   */
  validateValidFromDate = () => {
    const { generalTab } = this.state
    const { datemask } = this.props
    if (generalTab.validFrom.value !== '') {
      if (DateUtils.isDate(generalTab.validFrom.value, datemask)) {
        return {}
      }
    }
    return {
      validFrom: {
        ...this.state.generalTab.validFrom,
        error: 'general.please_enter_a_valid_date'
      }
    }
  }

  /**
   * @description Validates the valid from.
   */
  validateValidToDate = () => {
    const { generalTab } = this.state
    const { datemask } = this.props
    if (generalTab.validTo.value !== '') {
      if (DateUtils.isDate(generalTab.validTo.value, datemask)) {
        return {}
      }
    }
    return {
      validTo: {
        ...this.state.generalTab.validTo,
        error: 'general.please_enter_a_valid_date'
      }
    }
  }

  /**
   * @description Focuses the first input with an error in general tab.
   */
  handleFocusGeneralTab = () => {
    const { generalTab } = this.state
    const requiredInputs = [
      { inputRef: this.validFromInput, error: generalTab.validFrom.error },
      { inputRef: this.validToInput, error: generalTab.validTo.error }
    ]
    for (let i = 0; i < requiredInputs.length; i++) {
      if (requiredInputs[i].error !== '') {
        if (requiredInputs[i].inputRef.current) {
          requiredInputs[i].inputRef.current.focus()
          break
        }
      }
    }
  }

  /**
   * @description Validates the source tab.
   * @returns {Boolean} False if there is an error in source tab.
   */
  validateSourceTab = () => {
    const { sourceTab } = this.state
    if (sourceTab.determineIndexValuesFrom === 0) {
      return this.validateSearchPatternDocumentTextBrowser()
    } else if (sourceTab.determineIndexValuesFrom === 2) {
      return this.validateTleName()
    } else if (sourceTab.determineIndexValuesFrom === 3) {
      return this.validateSearchPatternPJL()
    } else {
      return true
    }
  }

  /**
   * @description Validates the document text browser search pattern.
   */
  validateSearchPatternDocumentTextBrowser = () => {
    const { sourceTab } = this.state
    if (sourceTab.searchPatternDocumentTextBrowser.value !== '') {
      return {}
    }
    return {
      searchPatternDocumentTextBrowser: {
        ...this.state.sourceTab.searchPatternDocumentTextBrowser,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the tle name.
   * @param {Boolean} allowFocus Flag to allow the focus on error.
   */
  validateTleName = () => {
    const { sourceTab } = this.state
    if (sourceTab.tleName.value !== '') {
      return {}
    }
    return {
      tleName: {
        ...this.state.sourceTab.tleName,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the pjl statement search pattern.
   */
  validateSearchPatternPJL = () => {
    const { sourceTab } = this.state
    if (sourceTab.searchPatternPJL.value !== '') {
      return {}
    }
    return {
      searchPatternPJL: {
        ...this.state.sourceTab.searchPatternPJL,
        error: 'general.input_required'
      }
    }
  }

  /**
   * @description Handles the save action.
   * @param {Boolean} saveAsUtf8 Flag if the definition should be saved as utf-8. If false it will be saved as iso-8859-1.
   */
  handleSave = (saveAsUtf8) => {
    const { generalTab, sourceTab, indexValuesTab, conditionsTab } = this.state
    const { indexDocumentAssignment, updateIndexDocumentAssignment, onClose, datemask } = this.props
    const errorTabs = [
      this.validateGeneralTab(),
      this.validateSourceTab()
    ]
    if (errorTabs.every(d => d)) {
      const indexDocumentAssignmentObj = {
        'FORM': indexDocumentAssignment.FORM,
        'EXT': indexDocumentAssignment.EXT,
        'REPORT': indexDocumentAssignment.REPORT,
        'IXINAME': indexDocumentAssignment.IXINAME,
        'IXINSTNR': indexDocumentAssignment.IXINSTNR,
        'VDATEB': DateUtils.formatDate(moment(generalTab.validFrom.value, datemask), DateUtils.DDMMYYYY_DOT, DateUtils.TIMESHIFT_ADD),
        'VDATEE': DateUtils.formatDate(moment(generalTab.validTo.value, datemask), DateUtils.DDMMYYYY_DOT, DateUtils.TIMESHIFT_ADD),
        'IXSCOPE': generalTab.available === 0 ? 'LOCAL' : 'GLOBAL',
        'IXSKIPF': generalTab.ignoreIndexValueWithWrongDataFormat,
        'IXDEBUG': generalTab.debugMode,
        'IXENODAT': generalTab.indexValuesAreRequired,
        'IXSCHECK': generalTab.securityCheck,
        'IXEXTPOS': indexValuesTab.fromColumn,
        'IXEXTREL': AssignmentUtils.RELATIVE_TO[indexValuesTab.relativeTo].key,
        'IXEXTLEN': indexValuesTab.inLengthOf,
        'IXEMASK': indexValuesTab.maximallyUpToEndTag,
        'IALIGNTR': indexValuesTab.alignment === 1,
        'IXFOMRUL': indexValuesTab.rule,
        'IXRUL1I': indexValuesTab.substituteValues1,
        'IXRUL1O': indexValuesTab.withValues1,
        'IXRUL2I': indexValuesTab.substituteValues2,
        'IXRUL2O': indexValuesTab.withValues2,
        'IXRUL3I': indexValuesTab.substituteValues3,
        'IXRUL3O': indexValuesTab.withValues3,
        'IXRUL4I': indexValuesTab.substituteValues4,
        'IXRUL4O': indexValuesTab.withValues4,
        'IXPMASK': conditionsTab.extractSearchPattern,
        'IXPSLINE': conditionsTab.extractFromLine,
        'IXPELINE': conditionsTab.extractToLine,
        'IXPSCOL': conditionsTab.extractFromColumn,
        'IXPECOL': conditionsTab.extractToColumn,
        'IXXMASK': conditionsTab.ignoreSearchPattern,
        'IXXSLINE': conditionsTab.ignoreFromLine,
        'IXXELINE': conditionsTab.ignoreToLine,
        'IXXSCOL': conditionsTab.ignoreFromColumn,
        'IXXECOL': conditionsTab.ignoreToColumn,
        'SAVEUTF8': saveAsUtf8
      }
      if (generalTab.dateFormat === 1) {
        indexDocumentAssignmentObj.IXUTISTR = true
        indexDocumentAssignmentObj.IXUDTSTR = generalTab.timeString
        indexDocumentAssignmentObj.IXUDTIME = generalTab.lcTime
        indexDocumentAssignmentObj.IDSPOINT = generalTab.decimalMark === 1
        indexDocumentAssignmentObj.IXIDMASK = ''
      } else {
        indexDocumentAssignmentObj.IXUTISTR = false
        indexDocumentAssignmentObj.IXIDMASK = this.getDatemaskItems()[generalTab.dateMask]
      }
      if (sourceTab.determineIndexValuesFrom === 0) {
        indexDocumentAssignmentObj.IXBASED = AssignmentUtils.DETERMINE_INDEX_VALUES_FROM[sourceTab.determineIndexValuesFrom].key[sourceTab.indexValuesFrom]
        indexDocumentAssignmentObj.IXSMASK = sourceTab.searchPatternDocumentTextBrowser.value
        indexDocumentAssignmentObj.IXSSLINE = sourceTab.fromLine
        indexDocumentAssignmentObj.IXSELINE = sourceTab.toLine
        indexDocumentAssignmentObj.IXSSCOL = sourceTab.fromColumn
        indexDocumentAssignmentObj.IXSECOL = sourceTab.toColumn
        indexDocumentAssignmentObj.IXMATCH = AssignmentUtils.DETECT_MATCHES[sourceTab.detectMatches].key
        indexDocumentAssignmentObj.IXDATASC = AssignmentUtils.HIT_RANGE[sourceTab.hitRange].key
      } else if (sourceTab.determineIndexValuesFrom === 1) {
        indexDocumentAssignmentObj.IXBASED = AssignmentUtils.DETERMINE_INDEX_VALUES_FROM[sourceTab.determineIndexValuesFrom].key
        indexDocumentAssignmentObj.IXSMASK = this.getDocumentVariableItems()[sourceTab.documentVariable]
      } else if (sourceTab.determineIndexValuesFrom === 2) {
        indexDocumentAssignmentObj.IXBASED = AssignmentUtils.DETERMINE_INDEX_VALUES_FROM[sourceTab.determineIndexValuesFrom].key
        indexDocumentAssignmentObj.IXSMASK = sourceTab.tleName.value
        indexDocumentAssignmentObj.IXDATASC = sourceTab.hitRange === 0 ? 'PAGE' : 'START'
      } else {
        indexDocumentAssignmentObj.IXBASED = AssignmentUtils.DETERMINE_INDEX_VALUES_FROM[sourceTab.determineIndexValuesFrom].key
        indexDocumentAssignmentObj.IXSMASK = sourceTab.searchPatternPJL.value
        indexDocumentAssignmentObj.IXDATASC = sourceTab.hitRange === 0 ? 'PAGE' : 'START'
      }
      updateIndexDocumentAssignment(indexDocumentAssignmentObj, () => onClose())
    }
  }

  /**
   * @description Gets the title of this dialog.
   * @returns {Array} The title of this dialog.
   */
  getModalTitle = () => {
    return [
      <div key={'title_1'}>{`${translate('general.modify')} ${translate('general.index_modal_title')}`}</div>,
      <Icon key={'title_2'} id={'index_document_relation_modal_title'} name={'relation'} tabIndex={-1}/>,
      <div
        key={'title_3'}>
        {`${UserUtils.isDOCX()
          ? translate('general.document_modal_title')
          : translate('general.log_modal_title')}  ${translate('general.assignment_modal_title')}`}
      </div>,
    ]
  }

  /**
   * @description Gets the error tabs.
   * @returns {Array} The error tabs.
   */
  getErrorTabs = () => {
    const {
      generalTab: { validFrom, validTo },
      sourceTab: { searchPatternDocumentTextBrowser, searchPatternPJL, tleName }
    } = this.state
    let buffer = []
    if (validFrom.error !== '' || validTo.error !== '') {
      buffer.push(0)
    }
    if (searchPatternDocumentTextBrowser.error !== '' || searchPatternPJL.error !== '' || tleName.error !== '') {
      buffer.push(1)
    }
    return buffer
  }

  /**
   * @description Handles the changes in source tab.
   * @param {String} key The key in state.
   * @param {*} value The new value.
   * @param {Boolean} isRequired Flag if the input is required.
   */
  handleSourceTabInputChanged = (key, val, isRequired) => {
    if (isRequired) {
      this.setState({
        sourceTab: {
          ...this.state.sourceTab,
          [key]: {
            value: val,
            error: ''
          }
        }
      })
    } else {
      this.setState({
        sourceTab: {
          ...this.state.sourceTab,
          [key]: val
        }
      }, () => {
        if (key === 'determineIndexValuesFrom') {
          this.resetSourceTabErrors()
        }
      })
    }
  }

  /**
   * @description Resets the errors in source tab.
   */
  resetSourceTabErrors = () => {
    this.setState({
      sourceTab: {
        ...this.state.sourceTab,
        tleName: {
          ...this.state.sourceTab.tleName,
          error: ''
        },
        searchPatternDocumentTextBrowser: {
          ...this.state.sourceTab.searchPatternDocumentTextBrowser,
          error: ''
        },
        searchPatternPJL: {
          ...this.state.sourceTab.searchPatternPJL,
          error: ''
        },
      }
    })
  }

  /**
   * @description Gets the document variable items.
   * @returns {Array} The document variable items.
   */
  getDocumentVariableItems = () => {
    return [
      '&DOCUSR1',
      '&DOCUSR2',
      '&DOCUSR3',
      '&DOCUSR4',
      '&DOCUSR5',
      '&DOCUSR6',
      '&DOCUSR7',
      '&DOCUSR8',
      '&EXTENSION',
      '&FILENAME',
      '&FORM',
      '&JOBNAME',
      '&ODATE',
      '&WREPORT'
    ]
  }

  /**
   * @description Handles the changes in index values tab.
   * @param {String} key The key of the field from the state.
   * @param {*} value The new value.
   */
  handleIndexValuesTabInputChanged = (key, val) => {
    this.setState({
      indexValuesTab: {
        ...this.state.indexValuesTab,
        [key]: val
      }
    })
  }

  /**
   * @description Handles the changes in conditions tab.
   * @param {String} key The key of the field from the state.
   * @param {*} value The new value.
   */
  handleConditionsTabInputChanged = (key, val) => {
    this.setState({
      conditionsTab: {
        ...this.state.conditionsTab,
        [key]: val
      }
    })
  }


  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { id, lang } = this.props
    const { generalTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_general_tab_date_format`}
              title={translate('general.dateformat')}
              items={[
                translate('general.datemask'),
                translate('general.manually')
              ]}
              onClick={index => this.handleGeneralTabInputChanged('dateFormat', index)}
              activeIndex={generalTab.dateFormat}
            />
          </Column>
          {
            generalTab.dateFormat === 1
              ? <>
                <Column colMD={3}>
                  <Input
                    id={`${id}_general_tab_time_string`}
                    value={generalTab.timeString}
                    maxLength={32}
                    onInputChanged={value => this.handleGeneralTabInputChanged('timeString', value)}
                    title={translate('general.time_string')}
                  />
                </Column>
                <Column colMD={3} className={'bux_flex_row'}>
                  <Input
                    id={`${id}_general_tab_lc_time`}
                    value={generalTab.lcTime}
                    maxLength={32}
                    onInputChanged={value => this.handleGeneralTabInputChanged('lcTime', value)}
                    title={translate('general.lc_time')}
                  />
                  <Tooltip
                    event={'click'}
                    className={'bux_place_help_icon'}
                    id={`${id}_general_tab_lc_time`}
                    tooltipContent={[
                      <label>{translate('assignment.index_document_sample_of_a_time_string_label')}</label>,
                      <p>{translate('assignment.index_document_sample_of_a_time_string_value')}</p>,
                      <br></br>,
                      <label>{translate('assignment.index_document_samples_of_lc_time')}</label>,
                      <p>{translate('assignment.index_document_sample_of_lcs_time1')}</p>,
                      <p>{translate('assignment.index_document_sample_of_lcs_time2')}</p>,
                      <p>{translate('assignment.index_document_sample_of_lcs_time3')}</p>,
                      <br></br>,
                      <label>{translate('assignment.index_document_variables')}</label>,
                      <p>{translate('assignment.index_document_variables1')}</p>,
                      <p>{translate('assignment.index_document_variables2')}</p>,
                      <p>{translate('assignment.index_document_variables3')}</p>,
                      <p>{translate('assignment.index_document_variables4')}</p>,
                      <p>{translate('assignment.index_document_variables5')}</p>,
                      <p>{translate('assignment.index_document_variables6')}</p>,
                      <p>{translate('assignment.index_document_variables7')}</p>,
                      <p>{translate('assignment.index_document_variables8')}</p>,
                    ]}>
                    <Icon
                      id={`${id}_general_tab_lc_time_tooltip`}
                      name={'info_filled'}
                    />
                  </Tooltip>
                </Column>
              </>
              : <Column colMD={3}>
                <Dropdown
                  id={`${id}_general_tab_datemask`}
                  title={translate('general.datemask')}
                  items={this.getDatemaskItems()}
                  activeIndex={generalTab.dateMask}
                  onChange={value => this.handleGeneralTabInputChanged('dateMask', value)}
                />
              </Column>
          }
        </Row>
        {
          generalTab.dateFormat === 1 &&
          <Row>
            <Column colMD={6}>
              <Switch
                id={`${id}_general_tab_decimal_mark`}
                title={translate('general.decimal_mark')}
                items={[
                  translate('general.comma'),
                  translate('general.point')
                ]}
                onClick={index => this.handleGeneralTabInputChanged('decimalMark', index)}
                activeIndex={generalTab.decimalMark}
              />
            </Column>
          </Row>
        }
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <label id={`${id}_general_tab_select_index`}>{translate('general.select_index')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Datepicker
              id={`${id}_general_tab_valid_from`}
              title={translate('general.valid_from')}
              value={generalTab.validFrom.value}
              onChange={date => this.handleGeneralTabInputChanged('validFrom', date, true)}
              dateFormat={this.props.datemask}
              required={`${translate('general.required_field')}`}
              error={generalTab.validFrom.error && translate(generalTab.validFrom.error)}
              focusRef={this.validFromInput}
              bothDates
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateValidFromDate() } }))}
              parentContainer={`${id}_tilecontainer`}
              language={lang}
            />
          </Column>
          <Column colMD={3}>
            <Datepicker
              id={`${id}_general_tab_valid_to`}
              title={translate('general.valid_to')}
              value={generalTab.validTo.value}
              onChange={date => this.handleGeneralTabInputChanged('validTo', date, true)}
              dateFormat={this.props.datemask}
              required={`${translate('general.required_field')}`}
              error={generalTab.validTo.error && translate(generalTab.validTo.error)}
              focusRef={this.validToInput}
              bothDates
              onBlur={() => this.setState(state => ({ generalTab: { ...state.generalTab, ...this.validateValidToDate() } }))}
              parentContainer={`${id}_tilecontainer`}
              language={lang}
            />
          </Column>
          <Column colMD={6}>
            <Switch
              id={`${id}_general_tab_available`}
              title={translate('general.available')}
              items={[
                translate('general.local'),
                translate('general.global')
              ]}
              onClick={index => this.handleGeneralTabInputChanged('available', index)}
              activeIndex={generalTab.available}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Toggle
              id={`${id}_general_tab_ignore_index_value_with_wrong_data_format`}
              onCheck={val => this.handleGeneralTabInputChanged('ignoreIndexValueWithWrongDataFormat', val)}
              value={generalTab.ignoreIndexValueWithWrongDataFormat}
              title={translate('assignment.index_document_ignore_index_value_with_wrong_data_format')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
          <Column colMD={6}>
            <Toggle
              id={`${id}_general_tab_index_values_are_required`}
              onCheck={val => this.handleGeneralTabInputChanged('indexValuesAreRequired', val)}
              value={generalTab.indexValuesAreRequired}
              title={translate('assignment.index_document_index_values_are_required')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Toggle
              id={`${id}_general_tab_debug_mode`}
              onCheck={val => this.handleGeneralTabInputChanged('debugMode', val)}
              value={generalTab.debugMode}
              title={translate('assignment.index_document_debug_mode')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
          <Column colMD={6}>
            <Toggle
              id={`${id}_general_tab_security_check`}
              onCheck={val => this.handleGeneralTabInputChanged('securityCheck', val)}
              value={generalTab.securityCheck}
              title={translate('assignment.index_document_security_check')}
              animated
              yes={translate('general.yes')}
              no={translate('general.no')}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the source tab.
   */
  renderSourceTab = () => {
    const { id } = this.props
    const { sourceTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={3}>
            <Dropdown
              id={`${id}_source_tab_determine_index_values_from`}
              title={translate('assignment.index_document_determine_index_values_from')}
              items={AssignmentUtils.DETERMINE_INDEX_VALUES_FROM.map(d => translate(d.translationKey))}
              activeIndex={sourceTab.determineIndexValuesFrom}
              onChange={value => this.handleSourceTabInputChanged('determineIndexValuesFrom', value)}
            />
          </Column>
          {
            sourceTab.determineIndexValuesFrom === 0 &&
            <Column colMD={6} className={'bux_pr8'}>
              <Switch
                id={`${id}_source_tab_index_values_from`}
                title={translate('assignment.index_document_index_values_from')}
                items={[
                  translate('general.document'),
                  translate('assignment.index_document_side_file'),
                  translate('assignment.index_document_afpds_nop_record')
                ]}
                onClick={index => this.handleSourceTabInputChanged('indexValuesFrom', index)}
                activeIndex={sourceTab.indexValuesFrom}
                maxPerRow={3}
              />
            </Column>
          }
          {
            sourceTab.determineIndexValuesFrom === 1 &&
            <Column colMD={3}>
              <Dropdown
                id={`${id}_source_tab_document_variable`}
                title={translate('assignment.index_document_document_variable')}
                items={this.getDocumentVariableItems()}
                activeIndex={sourceTab.documentVariable}
                onChange={value => this.handleSourceTabInputChanged('documentVariable', value)}
              />
            </Column>
          }
        </Row>
        {
          {
            0: this.renderAdditionalDocumentTextBrowserContent(),
            2: this.renderAdditionalAcifIndexContent(),
            3: this.renderAdditionalPjlStatementContent()
          }[sourceTab.determineIndexValuesFrom]
        }
      </>
    )
  }

  /**
   * @description Renders the additional document text browser content.
   */
  renderAdditionalDocumentTextBrowserContent = () => {
    const { id } = this.props
    const { sourceTab } = this.state
    return (
      <>
        <Row>
          <Column colMD={12} className={'bux_flex_row'}>
            <Input
              id={`${id}_source_tab_search_pattern`}
              value={sourceTab.searchPatternDocumentTextBrowser.value}
              title={translate('definition.filter_argument_search_pattern')}
              onInputChanged={value => this.handleSourceTabInputChanged('searchPatternDocumentTextBrowser', value, true)}
              maxLength={240}
              required={`${translate('general.required_field')}`}
              error={sourceTab.searchPatternDocumentTextBrowser.error && translate(sourceTab.searchPatternDocumentTextBrowser.error)}
              ref={this.searchPatternDocumentTextBrowserInput}
            />
            <Tooltip
              event={'click'}
              className={'bux_place_help_icon'}
              id={`${id}_source_tab_search_pattern`}
              tooltipContent={[
                <label>{translate('assignment.index_document_sample_of_a_search_pattern_label')}</label>,
                <p>{translate('assignment.index_document_sample_of_a_search_pattern_value')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_strings_beginning_with_abc')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_search_pattern_q')}</p>,
                <p>{translate('assignment.index_document_search_pattern_x')}</p>,
                <p>{translate('assignment.index_document_search_pattern_z')}</p>,
                <p>{translate('assignment.index_document_search_pattern_l')}</p>,
                <p>{translate('assignment.index_document_search_pattern_b')}</p>,
                <p>{translate('assignment.index_document_search_pattern_a')}</p>,
                <p>{translate('assignment.index_document_search_pattern_u')}</p>,
                <p>{translate('assignment.index_document_search_pattern_s')}</p>,
                <p>{translate('assignment.index_document_search_pattern_*')}</p>,
              ]}>
              <Icon
                id={`${id}_source_tab_search_pattern_tooltip`}
                name={'info_filled'}
              />
            </Tooltip>
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_general_tab_search_window`}>{translate('general.search_window')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_source_tab_from_line`}
              title={translate('general.from_line')}
              onChange={val => this.handleSourceTabInputChanged('fromLine', val)}
              value={sourceTab.fromLine}
              min={0}
              max={32767}
              steps={1}
              disabled={sourceTab.indexValuesFrom === 2}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_source_tab_to_line`}
              title={translate('general.to_line')}
              onChange={val => this.handleSourceTabInputChanged('toLine', val)}
              value={sourceTab.toLine}
              min={0}
              max={32767}
              steps={1}
              disabled={sourceTab.indexValuesFrom === 2}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_source_tab_from_column`}
              title={translate('general.from_column')}
              onChange={val => this.handleSourceTabInputChanged('fromColumn', val)}
              value={sourceTab.fromColumn}
              min={0}
              max={32767}
              steps={1}
              disabled={sourceTab.indexValuesFrom === 2}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_source_tab_to_column`}
              title={translate('general.to_column')}
              onChange={val => this.handleSourceTabInputChanged('toColumn', val)}
              value={sourceTab.toColumn}
              min={0}
              max={32767}
              steps={1}
              disabled={sourceTab.indexValuesFrom === 2}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_source_tab_detect_matches`}
              title={translate('general.detect_matches')}
              items={AssignmentUtils.DETECT_MATCHES.map(d => translate(d.translationKey))}
              onClick={index => this.handleSourceTabInputChanged('detectMatches', index)}
              activeIndex={sourceTab.detectMatches}
              maxPerRow={3}
            />
          </Column>
          <Column colMD={3} className={'bux_pr8'}>
            <Dropdown
              id={`${id}_source_tab_hit_range`}
              title={translate('general.hit_range')}
              items={AssignmentUtils.HIT_RANGE.map(d => translate(d.translationKey))}
              activeIndex={sourceTab.hitRange}
              onChange={value => this.handleSourceTabInputChanged('hitRange', value)}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the additional acif index content.
   */
  renderAdditionalAcifIndexContent = () => {
    const { sourceTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={12}>
            <Input
              id={`${id}_source_tab_tle_name`}
              value={sourceTab.tleName.value}
              title={translate('definition.searchargument_tle_name')}
              onInputChanged={value => this.handleSourceTabInputChanged('tleName', value, true)}
              maxLength={64}
              required={`${translate('general.required_field')}`}
              error={sourceTab.tleName.error && translate(sourceTab.tleName.error)}
              ref={this.tleNameInput}
              onBlur={() => this.setState({ sourceTab: { ...this.state.sourceTab, ...this.validateTleName() } })}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_source_tab_hit_Range`}
              title={translate('general.hit_range')}
              items={[
                translate('general.hit_page'),
                translate('general.include_pages_after')
              ]}
              onClick={index => this.handleSourceTabInputChanged('hitRange', index)}
              activeIndex={sourceTab.hitRange}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the additional pjl statement content.
   */
  renderAdditionalPjlStatementContent = () => {
    const { sourceTab } = this.state
    const { id } = this.props
    return (
      <>
        <Row>
          <Column colMD={12} className={'bux_flex_row'}>
            <Input
              id={`${id}_source_tab_search_pattern`}
              value={sourceTab.searchPatternPJL.value}
              title={translate('definition.filter_argument_search_pattern')}
              onInputChanged={value => this.handleSourceTabInputChanged('searchPatternPJL', value, true)}
              maxLength={240}
              required={`${translate('general.required_field')}`}
              error={sourceTab.searchPatternPJL.error && translate(sourceTab.searchPatternPJL.error)}
              ref={this.searchPatternPJLInput}
              onBlur={() => this.setState({ sourceTab: { ...this.state.sourceTab, ...this.validateSearchPatternPJL() } })}
            />
            <Tooltip
              event={'click'}
              className={'bux_place_help_icon'}
              id={`${id}_source_tab_search_pattern_tooltip`}
              tooltipContent={[
                <label>{translate('assignment.index_document_sample_of_a_time_string_label')}</label>,
                <p>{translate('assignment.index_document_sample_of_a_time_string_value')}</p>,
                <br></br>,
                <label>{translate('assignment.index_document_samples_of_lc_time')}</label>,
                <p>{translate('assignment.index_document_sample_of_lcs_time1')}</p>,
                <p>{translate('assignment.index_document_sample_of_lcs_time2')}</p>,
                <p>{translate('assignment.index_document_sample_of_lcs_time3')}</p>,
                <br></br>,
                <label>{translate('assignment.index_document_variables')}</label>,
                <p>{translate('assignment.index_document_variables1')}</p>,
                <p>{translate('assignment.index_document_variables2')}</p>,
                <p>{translate('assignment.index_document_variables3')}</p>,
                <p>{translate('assignment.index_document_variables4')}</p>,
                <p>{translate('assignment.index_document_variables5')}</p>,
                <p>{translate('assignment.index_document_variables6')}</p>,
                <p>{translate('assignment.index_document_variables7')}</p>,
                <p>{translate('assignment.index_document_variables8')}</p>,
              ]}>
              <Icon
                id={`${id}_source_tab_search_pattern_tooltip`}
                name={'info_filled'}
              />
            </Tooltip>
          </Column>
        </Row>
        <Row>
          <Column colMD={6}>
            <Switch
              id={`${id}_source_tab_hit_Range`}
              title={translate('general.hit_range')}
              items={[
                translate('general.hit_page'),
                translate('general.include_pages_after')
              ]}
              onClick={index => this.handleSourceTabInputChanged('hitRange', index)}
              activeIndex={sourceTab.hitRange}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the index values tab.
   */
  renderIndexValuesTab = () => {
    const { id } = this.props
    const { indexValuesTab } = this.state
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_index_values_tab_extract_index_value`}>{translate('assignment.index_document_extract_index_value')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_index_values_tab_from_column`}
              title={translate('general.from_column')}
              onChange={val => this.handleIndexValuesTabInputChanged('fromColumn', val)}
              value={indexValuesTab.fromColumn}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={9}>
            <Switch
              id={`${id}_index_values_tab_relative_to`}
              title={translate('general.relative_to')}
              items={AssignmentUtils.RELATIVE_TO.map(d => translate(d.translationKey))}
              onClick={index => this.handleIndexValuesTabInputChanged('relativeTo', index)}
              activeIndex={indexValuesTab.relativeTo}
              maxPerRow={3}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_index_values_tab_in_length_of`}
              title={translate('general.in_length_of')}
              onChange={val => this.handleIndexValuesTabInputChanged('inLengthOf', val)}
              value={indexValuesTab.inLengthOf}
              min={1}
              max={64}
              steps={1}
            />
          </Column>
          <Column colMD={6}>
            <Input
              id={`${id}_source_tab_search_pattern`}
              value={indexValuesTab.maximallyUpToEndTag}
              title={translate('assignment.index_document_maximally_up_to_end_tag')}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('maximallyUpToEndTag', value)}
              maxLength={32}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_index_values_tab_format_index_value`}>{translate('assignment.index_document_format_index_value')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Switch
              id={`${id}_index_values_tab_alignment`}
              title={translate('general.alignment')}
              items={[
                translate('general.align_to_left'),
                translate('general.align_to_right')
              ]}
              onClick={index => this.handleIndexValuesTabInputChanged('alignment', index)}
              activeIndex={indexValuesTab.alignment}
            />
          </Column>
          <Column colMD={9} className={'bux_flex_row'}>
            <Input
              id={`${id}_index_values_tab_rule`}
              value={indexValuesTab.rule}
              title={translate('general.rule')}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('rule', value)}
              maxLength={64}
            />
            <Tooltip
              event={'click'}
              className={'bux_place_help_icon'}
              id={`${id}_source_tab_search_pattern`}
              tooltipContent={[
                <label>{translate('assignment.index_document_rule_example_label')}</label>,
                <p>{translate('assignment.index_document_rule_example_value')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_rule_example_substitutes1')}</p>,
                <p>{translate('assignment.index_document_rule_example_substitutes2')}</p>,
              ]}>
              <Icon
                id={`${id}_source_tab_search_pattern_tooltip`}
                name={'info_filled'}
              />
            </Tooltip>
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_index_values_tab_substitute_characters_before_saving_hits`}>{translate('assignment.index_document_substitute_characters_before_saving_hits')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_substitute_values1`}
              value={indexValuesTab.substituteValues1}
              title={translate('assignment.index_document_substitude_values')}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('substituteValues1', value)}
              maxLength={32}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_with_values1`}
              value={indexValuesTab.withValues1}
              title={translate('assignment.index_document_with_values')}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('withValues1', value)}
              maxLength={32}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_substitute_values2`}
              value={indexValuesTab.substituteValues2}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('substituteValues2', value)}
              maxLength={32}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_with_values2`}
              value={indexValuesTab.withValues2}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('withValues2', value)}
              maxLength={32}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_substitute_values3`}
              value={indexValuesTab.substituteValues3}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('substituteValues3', value)}
              maxLength={32}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_with_values3`}
              value={indexValuesTab.withValues3}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('withValues3', value)}
              maxLength={32}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_substitute_values4`}
              value={indexValuesTab.substituteValues4}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('substituteValues4', value)}
              maxLength={32}
            />
          </Column>
          <Column colMD={3}>
            <Input
              id={`${id}_index_values_tab_with_values4`}
              value={indexValuesTab.withValues4}
              onInputChanged={value => this.handleIndexValuesTabInputChanged('withValues4', value)}
              maxLength={32}
            />
          </Column>
        </Row>
      </>
    )
  }

  /**
   * @description Renders the conditions tab.
   */
  renderConditionsTab = () => {
    const { id } = this.props
    const { conditionsTab } = this.state
    return (
      <>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_conditions_tab_extract_index_values_only_from_pages`}>{translate('assignment.index_document_extract_index_values_only_from_pages')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={12} className={'bux_flex_row'}>
            <Input
              id={`${id}_conditions_tab_extract_search_pattern`}
              value={conditionsTab.extractSearchPattern}
              title={translate('definition.filter_argument_search_pattern')}
              onInputChanged={value => this.handleConditionsTabInputChanged('extractSearchPattern', value)}
              maxLength={64}
            />
            <Tooltip
              event={'click'}
              className={'bux_place_help_icon'}
              id={`${id}_conditions_tab_extract_search_pattern`}
              tooltipContent={[
                <label>{translate('assignment.index_document_sample_of_a_search_pattern_label')}</label>,
                <p>{translate('assignment.index_document_sample_of_a_search_pattern_value')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_strings_beginning_with_abc')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_search_pattern_q')}</p>,
                <p>{translate('assignment.index_document_search_pattern_x')}</p>,
                <p>{translate('assignment.index_document_search_pattern_z')}</p>,
                <p>{translate('assignment.index_document_search_pattern_l')}</p>,
                <p>{translate('assignment.index_document_search_pattern_b')}</p>,
                <p>{translate('assignment.index_document_search_pattern_a')}</p>,
                <p>{translate('assignment.index_document_search_pattern_u')}</p>,
                <p>{translate('assignment.index_document_search_pattern_s')}</p>,
                <p>{translate('assignment.index_document_search_pattern_*')}</p>,
              ]}>
              <Icon
                id={`${id}_conditions_tab_extract_search_pattern_tooltip`}
                name={'info_filled'}
              />
            </Tooltip>
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_conditions_tab_extract_search_window`}>{translate('general.search_window')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_extract_from_line`}
              title={translate('general.from_line')}
              onChange={val => this.handleConditionsTabInputChanged('extractFromLine', val)}
              value={conditionsTab.extractFromLine}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_extract_to_line`}
              title={translate('general.to_line')}
              onChange={val => this.handleConditionsTabInputChanged('extractToLine', val)}
              value={conditionsTab.extractToLine}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_extract_from_column`}
              title={translate('general.from_column')}
              onChange={val => this.handleConditionsTabInputChanged('extractFromColumn', val)}
              value={conditionsTab.extractFromColumn}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_extract_to_column`}
              title={translate('general.to_column')}
              onChange={val => this.handleConditionsTabInputChanged('extractToColumn', val)}
              value={conditionsTab.extractToColumn}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
        </Row>
        <Row>
          <Column colMD={12}>
            <hr />
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_conditions_tab_ignore_index_values_from_pages`}>{translate('assignment.index_document_ignore_index_values_from_pages')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={12} className={'bux_flex_row'}>
            <Input
              id={`${id}_conditions_tab_ignore_search_pattern`}
              value={conditionsTab.ignoreSearchPattern}
              title={translate('definition.filter_argument_search_pattern')}
              onInputChanged={value => this.handleConditionsTabInputChanged('ignoreSearchPattern', value)}
              maxLength={64}
            />
            <Tooltip
              event={'click'}
              className={'bux_place_help_icon'}
              id={`${id}_conditions_tab_ignore_search_pattern`}
              tooltipContent={[
                <label>{translate('assignment.index_document_sample_of_a_search_pattern_label')}</label>,
                <p>{translate('assignment.index_document_sample_of_a_search_pattern_value')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_strings_beginning_with_abc')}</p>,
                <br></br>,
                <p>{translate('assignment.index_document_search_pattern_q')}</p>,
                <p>{translate('assignment.index_document_search_pattern_x')}</p>,
                <p>{translate('assignment.index_document_search_pattern_z')}</p>,
                <p>{translate('assignment.index_document_search_pattern_l')}</p>,
                <p>{translate('assignment.index_document_search_pattern_b')}</p>,
                <p>{translate('assignment.index_document_search_pattern_a')}</p>,
                <p>{translate('assignment.index_document_search_pattern_u')}</p>,
                <p>{translate('assignment.index_document_search_pattern_s')}</p>,
                <p>{translate('assignment.index_document_search_pattern_*')}</p>,
              ]}>
              <Icon
                id={`${id}_conditions_tab_ignore_search_pattern_tooltip`}
                name={'info_filled'}
              />
            </Tooltip>
          </Column>
        </Row>
        <Row isTitle>
          <Column colMD={12}>
            <label id={`${id}_conditions_tab_ignore_search_window`}>{translate('general.search_window')}</label>
          </Column>
        </Row>
        <Row>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_ignore_from_line`}
              title={translate('general.from_line')}
              onChange={val => this.handleConditionsTabInputChanged('ignoreFromLine', val)}
              value={conditionsTab.ignoreFromLine}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_ignore_to_line`}
              title={translate('general.to_line')}
              onChange={val => this.handleConditionsTabInputChanged('ignoreToLine', val)}
              value={conditionsTab.ignoreToLine}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_ignore_from_column`}
              title={translate('general.from_column')}
              onChange={val => this.handleConditionsTabInputChanged('ignoreFromColumn', val)}
              value={conditionsTab.ignoreFromColumn}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
          <Column colMD={3}>
            <NumericSpinner
              id={`${id}_conditions_tab_ignore_to_column`}
              title={translate('general.to_column')}
              onChange={val => this.handleConditionsTabInputChanged('ignoreToColumn', val)}
              value={conditionsTab.ignoreToColumn}
              min={0}
              max={32767}
              steps={1}
            />
          </Column>
        </Row>
      </>
    )
  }

  render = () => {
    const { id, onClose } = this.props

    return (
      <Modal
        id={id}
        onClose={onClose}>
        <Header
          id={`${id}_modalHeader`}
          title={this.getModalTitle()}
          onClose={onClose}>
          {this.renderHeader()}
        </Header>
        <Main id={id}>
          <Tabs
            id={id}
            errorTabs={this.getErrorTabs()}>
            <Tab title={translate('general.general')}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('general.source')}>
              {this.renderSourceTab()}
            </Tab>
            <Tab title={translate('general.index_values')}>
              {this.renderIndexValuesTab()}
            </Tab>
            <Tab title={translate('general.conditions')}>
              {this.renderConditionsTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer id={id}>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel')}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn_iso88591`}
            text={translate('general.save_as_iso88591')}
            onClick={() => this.handleSave(false)}
            primary
            submit
          />
          <Button
            id={`${id}_savebtn_utf8`}
            text={translate('general.save_as_utf8')}
            onClick={() => this.handleSave(true)}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}
const mapStateToProps = state => {
  return {
    usertoken: state.auth.serverdata.token,
    prefs: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    selector: state.selector,
    indexDocumentAssignment: state.assignments.indexdocumentassignment.indexDocumentAssignment,
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateIndexDocumentAssignment: (indexDocument, callback) => {
      IndexDocumentAssignmentActions.updateIndexDocumentAssignment(indexDocument, callback)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyIndexDocumentAssignmentDialog)