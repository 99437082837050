import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import { translate } from 'language/Language'
import * as DefinitionUtils from 'utils/DefinitionUtils'
import * as Utils from 'utils/Utils'

// components
import {
  Button, Checkbox, Column, Dropdown, Input,
  Modal as ModalComponent, MultilineInput, Row,
  Tab,
  Tabs
} from 'BetaUX2Web-Components/src/'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { connect } from 'react-redux'
import * as LpdQueueDefinitionActions from 'redux/actions/LpdQueueDefinitionActions'
import * as Preferences from 'redux/general/Preferences'

class CopyLpdQueueDialog extends Component {

  constructor(props) {
    super(props)
    this.lpdQueueIdInput = React.createRef()
    this.programPathInput = React.createRef()
  }

  state = {
    generalTab: {
      lpdQueueId: {
        value: this.props.lpdQueue.LPD,
        errorkey: ''
      },
      owner: {
        value: this.props.lpdQueue.OWNER,
        errorkey: ''
      },
      title: {
        value: this.props.lpdQueue.LPDINFO,
        errorkey: ''
      },
      enableSpooling: this.props.lpdQueue.QSSPOOL,
      enableTransfer: this.props.lpdQueue.QSPRINT,
      completionCheck: this.props.lpdQueue.QSTATUS,
    },
    transferTab: {
      activeTransferProgramIndex: 0,
      programPath: {
        value: this.props.lpdQueue.LPDPROG,
        errorkey: '',
      },
      arguments: this.props.lpdQueue.LPDARGS,
    }
  }

  /**
   * @description Sets the initial values.
   */
  componentDidMount = () => {
    this.lpdQueueIdInput.current.focus()

    let index = DefinitionUtils.LPDQUEUE_TRANSFER_PROGRAMS.findIndex(entry => entry.key === this.props.lpdQueue.LPDPROG)
    if (index === -1) {
      index = DefinitionUtils.LPDQUEUE_TRANSFER_PROGRAMS.findIndex(entry => entry.key === DefinitionUtils.LPDQUEUE_TRANSFER_PROGRAM_OTHER)
    }

    this.setState({
      transferTab: {
        ...this.state.transferTab,
        activeTransferProgramIndex: index
      }
    })
  }

  /**
   * @description Handles the input changes of the general tab.
   * @param {String} id The id the input field.
   * @param {String} value The new value.
   * @param {String} errorkey The new errorkey.
   */
  handleGeneralTabInputChanged = (id, value, errorkey) => {
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        [id]: {
          value: value,
          errorkey: errorkey,
        }
      }
    })
  }

  /**
   * @description Handles the check changes of the general tab.
   * @param {String} id The id the checkbox.
   * @param {Boolean} value The new value.
   */
  handleGeneralTabOnCheckChanged = (id, value) => {
    this.setState({
      generalTab: {
        ...this.state.generalTab,
        [id]: value,
      }
    })
  }

  /**
   * @description Handles the program path changes of the transfer tab.
   * @param {String} value The new value.
   * @param {String} errorkey The new errorkey.
   */
  handleTransferTabProgramPathChanged = (value, errorkey) => {
    this.setState({
      transferTab: {
        ...this.state.transferTab,
        programPath: {
          value: value,
          errorkey: errorkey
        }
      }
    })
  }

  /**
   * @description Handles the program changed of the transfer tab.
   * @param {Number} index The new index of the dropdown.
   */
  handleTransferTabProgramChanged = (index) => {
    const { lpdQueue } = this.props
    if (index !== this.state.transferTab.activeTransferProgramIndex) {
      this.setState({
        transferTab: {
          ...this.state.transferTab,
          activeTransferProgramIndex: index,
          programPath: {
            value: DefinitionUtils.LPDQUEUE_TRANSFER_PROGRAMS[index].key !== ''
              ? DefinitionUtils.LPDQUEUE_TRANSFER_PROGRAMS[index].key
              : lpdQueue.LPDPROG,
            errorkey: ''
          }
        }
      })
    }
  }

  /**
   * @description Handles the argument changes of the transfer tab.
   * @param {String} value The new value.
   */
  handleTransferTabArgumentsChanged = (value) => {
    this.setState({
      transferTab: {
        ...this.state.transferTab,
        arguments: value
      }
    })
  }

  /**
   * @description Gets the translated programs.
   */
  getTranslatedPrograms = () => {
    const { lang } = this.props

    const programs = []
    DefinitionUtils.LPDQUEUE_TRANSFER_PROGRAMS.forEach(entry => {
      programs.push(translate(entry.translationKey, lang))
    })

    return programs
  }

  /**
   * @description Validates the lpd queue id.
   */
  validateID = () => {
    const { generalTab } = this.state
    if (generalTab.lpdQueueId.value !== '') {
      if (generalTab.lpdQueueId.value === this.props.lpdQueue.LPD) {
        return {
          lpdQueueId: {
            ...this.state.generalTab.lpdQueueId,
            errorkey: 'lpdqueue.copy_lpd_queue_same_id_error'
          }
        }
      } else {
        return {}
      }
    }
    return {
      lpdQueueId: {
        ...this.state.generalTab.lpdQueueId,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the general tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateGeneralTab = () => {
    const validatorResult = { ...this.validateID() }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ generalTab: { ...this.state.generalTab, ...validatorResult } }, () => {
        this.lpdQueueIdInput.current && this.lpdQueueIdInput.current.focus()
      })
    }
    return errors === 0
  }

  /**
   * @description Validates the program path
   */
  validateProgramPath = () => {
    const { transferTab } = this.state
    if (transferTab.programPath.value !== '') {
      return {}
    }
    return {
      programPath: {
        ...this.state.transferTab.programPath,
        errorkey: 'general.input_required'
      }
    }
  }

  /**
   * @description Validates the transfer tab. Adds errors under inputs and tries to focus them.
   * @returns {Boolean} False if the validation failed.
   */
  validateTransferTab = () => {
    const validatorResult = { ...this.validateProgramPath() }
    const errors = Object.keys(validatorResult).length
    if (errors > 0) {
      this.setState({ transferTab: { ...this.state.transferTab, ...validatorResult } }, () => {
        this.programPathInput.current && this.programPathInput.current.focus()
      })
    }
    return errors === 0
  }

  /**
   * @description Gets the error tabs as an array of numbers.
   * @returns {Array} The error tabs as an array of numbers.
   */
  handleErrorTabs = () => {
    const {
      generalTab: { lpdQueueId },
      transferTab: { programPath }
    } = this.state

    const buffer = []
    if (lpdQueueId.errorkey !== '') {
      buffer.push(0)
    }
    if (programPath.errorkey !== '') {
      buffer.push(1)
    }

    return buffer
  }

  /**
   * @description Handles the save action.
   */
  handleSave = () => {
    const { generalTab, transferTab } = this.state

    // replace all \n with whitespace
    const args = Utils.replaceAll(transferTab.arguments, '\n', ' ')
    const errorTabs = [
      this.validateGeneralTab(),
      this.validateTransferTab()
    ]

    if (errorTabs.every(d => d)) {
      const lpdQueueDefinition = {
        LPD: generalTab.lpdQueueId.value,
        LPDINFO: generalTab.title.value,
        LPDPROG: transferTab.programPath.value,
        LPDARGS: args,
        CUSER: this.props.userid,
        OWNER: generalTab.owner.value,
        QSSPOOL: generalTab.enableSpooling,
        QSPRINT: generalTab.enableTransfer,
        QSTATUS: generalTab.completionCheck,
      }

      const callback = () => this.props.onClose()
      this.props.createLpdQueue(lpdQueueDefinition, callback)

    }
  }

  /**
   * @description Renders the general tab.
   */
  renderGeneralTab = () => {
    const { id, lang } = this.props
    const { generalTab } = this.state
    return (
      <Fragment>
        <Row>
          <Column
            colMD={6}>
            {/* lpd queue id */}
            <Input
              id={`${id}_general_tab_lpdqueue_id`}
              value={generalTab.lpdQueueId.value}
              title={translate('definition.lpd_queue.id', lang)}
              ref={this.lpdQueueIdInput}
              required={`${translate('general.required_field')}`}
              maxLength={16}
              onInputChanged={(value, errorkey) => this.handleGeneralTabInputChanged('lpdQueueId', value, errorkey)}
              error={generalTab.lpdQueueId.errorkey && translate(generalTab.lpdQueueId.errorkey, lang)}
              onBlur={() => this.setState({ generalTab: { ...this.state.generalTab, ...this.validateID() } })}
            />
          </Column>
          <Column
            colMD={6}>
            {/* owner */}
            <Input
              id={`${id}_general_tab_owner`}
              value={generalTab.owner.value}
              title={translate('general.owner', lang)}
              maxLength={8}
              onInputChanged={(value, errorkey) => this.handleGeneralTabInputChanged('owner', value, errorkey)}
              error={generalTab.owner.errorkey && translate(generalTab.owner.errorkey, lang)}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={12}>
            {/* title */}
            <Input
              id={`${id}_general_tab_title`}
              value={generalTab.title.value}
              title={translate('general.title', lang)}
              maxLength={64}
              onInputChanged={(value, errorkey) => this.handleGeneralTabInputChanged('title', value, errorkey)}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={4}>
            {/* enable spooling */}
            <Checkbox
              id={`${id}_general_tab_enableSpooling`}
              tabindex={0}
              onCheck={(val) => this.handleGeneralTabOnCheckChanged('enableSpooling', val)}
              value={generalTab.enableSpooling}
              label={translate('lpdqueue.enable_spooling', this.props.lang)}
            />
          </Column>
          <Column
            colMD={4}>
            {/* enable transfer */}
            <Checkbox
              id={`${id}_general_tab_enableTransfer`}
              tabindex={0}
              onCheck={(val) => this.handleGeneralTabOnCheckChanged('enableTransfer', val)}
              value={generalTab.enableTransfer}
              label={translate('lpdqueue.enable_transfer', this.props.lang)}
            />
          </Column>
          <Column
            colMD={4}>
            {/* completion check */}
            <Checkbox
              id={`${id}_general_tab_completionCheck`}
              tabindex={0}
              onCheck={(val) => this.handleGeneralTabOnCheckChanged('completionCheck', val)}
              value={generalTab.completionCheck}
              label={translate('lpdqueue.completion_check', this.props.lang)}
            />
          </Column>
        </Row>
      </Fragment>
    )
  }

  /**
   * @description Renders the transfer tab.
   */
  renderTransferTab = () => {
    const { id, lang } = this.props
    const { transferTab } = this.state

    const programItems = this.getTranslatedPrograms()

    return (
      <Fragment>
        <Row>
          <Column
            colMD={4}>
            {/* transfer program */}
            <Dropdown
              id={`${id}_transfer_tab_program`}
              items={programItems}
              activeIndex={transferTab.activeTransferProgramIndex}
              onChange={this.handleTransferTabProgramChanged}
              title={translate('lpdqueue.transfer_program', lang)}
            />
          </Column>
          <Column
            colMD={8}>
            {/* program path */}
            <Input
              id={`${id}_transfer_tab_program_path`}
              value={transferTab.programPath.value}
              title={translate('lpdqueue.transfer_program_path', lang)}
              disabled={DefinitionUtils.LPDQUEUE_TRANSFER_PROGRAMS[transferTab.activeTransferProgramIndex].key !== DefinitionUtils.LPDQUEUE_TRANSFER_PROGRAM_OTHER}
              ref={this.programPathInput}
              error={transferTab.programPath.errorkey && translate(transferTab.programPath.errorkey, lang)}
              required={`${translate('general.required_field')}`}
              maxLength={256}
              onInputChanged={(value, errorkey) => this.handleTransferTabProgramPathChanged(value, errorkey)}
              onBlur={() => this.setState({ transferTab: { ...this.state.transferTab, ...this.validateProgramPath() } })}
            />
          </Column>
        </Row>
        <Row>
          <Column
            colMD={6}>
            {/* arguments */}
            <MultilineInput
              id={`${id}_transfer_tab_arguments`}
              value={transferTab.arguments}
              onInputChanged={this.handleTransferTabArgumentsChanged}
              title={translate('lpdqueue.transfer_arguments', lang)}
              maxLength={256}
              maxLengthIncludesLinebreaks
              rows={6}
              mono
            />
          </Column>
          <Column
            colMD={6}>
            {/* description for arguments */}
            <label
              className={'control-label el_input_label'}>
              {translate('lpdqueue.transfer_arguments_desc_title', lang)}:
            </label>
            <label
              className={'control-label el_input_label'}>
              {translate('lpdqueue.transfer_arguments_desc_lpqn', lang)}
            </label>
            <label
              className={'control-label el_input_label'}>
              {translate('lpdqueue.transfer_arguments_desc_lpid', lang)}
            </label>
            <label
              className={'control-label el_input_label'}>
              {translate('lpdqueue.transfer_arguments_desc_lpdf', lang)}
            </label>
            <label
              className={'control-label el_input_label'}>
              {translate('lpdqueue.transfer_arguments_desc_lpcf', lang)}
            </label>
            <label
              className={'control-label el_input_label'}>
              {translate('lpdqueue.transfer_arguments_desc_buxip', lang)}
            </label>
            <label
              className={'control-label el_input_label'}>
              {translate('lpdqueue.transfer_arguments_desc_buxport', lang)}
            </label>
          </Column>
        </Row>
      </Fragment>
    )
  }

  render = () => {
    const { id, lang, onClose } = this.props
    return (
      <Modal onClose={onClose}
        id='copy_lpd_queue_dialog'
        className='bux_UserProfileModal'>
        <Header
          id={`${id}_modalHeader`}
          title={translate('lpdqueue.modal_title_copy', lang)}
          onClose={onClose}>
        </Header>
        <Main
          id={id}>
          <Tabs
            id={id}
            lang={lang}
            errorTabs={this.handleErrorTabs()}>
            <Tab title={translate('general.general', lang)}>
              {this.renderGeneralTab()}
            </Tab>
            <Tab title={translate('lpdqueue.transfer', lang)}>
              {this.renderTransferTab()}
            </Tab>
          </Tabs>
        </Main>
        <Footer>
          <Button
            id={`${id}_cancelbtn`}
            text={translate('general.cancel', lang)}
            onClick={onClose}
          />
          <Button
            id={`${id}_savebtn`}
            text={translate('general.save', lang)}
            onClick={this.handleSave}
            primary
            submit
          />
        </Footer>
      </Modal>
    )
  }
}

CopyLpdQueueDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
    usertoken: state.auth.serverdata.token,
    userid: state.auth.userid,
    lpdQueue: state.definitions.lpdQueues.lpdQueue,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createLpdQueue: (lpdQueueDefinition, cb) => {
      LpdQueueDefinitionActions.createLpdQueue(lpdQueueDefinition, cb)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyLpdQueueDialog)