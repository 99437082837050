import { Component } from 'react'

// Components
import {
  Button, Card, Column, Icon, Modal as ModalComponent,
  Row, Switch
} from 'BetaUX2Web-Components/src/'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'

const { Modal, Main, Header, Footer } = ModalComponent

// Redux
import { translate, translateNoError } from 'language/Language'
import { connect } from 'react-redux'
import * as PreferencesActions from 'redux/actions/PreferencesActions'
import * as SnackbarActions from 'redux/actions/SnackbarActions'

// css
import './TableSettings.scss'
import { DEFAULT_TABLE_HEADER_KEYS, PREFERENCE_HEADERS_MAP } from 'utils/TableUtils'

class TableSettings extends Component {

  state = {
    fillPage: this.props.prefs.fillPage,
    hoveredRow: null,
    hiddenHeaders: [],
    choosenHeaders: [],
    selectedHeaders: [],
    draggingHeaderId: null
  }

  /**
   * @description Initializes the hidden headers. Also adds a window click listener for unselect items.
   */
  componentDidMount = () => {
    const { headers, prefs } = this.props
    const choosenHeaders = []
    const hiddenHeaders = []

    // fill choosenheaders
    prefs.headers.forEach(h => {
      headers.forEach((entry, index) => {
        if (entry.rest === h) {
          choosenHeaders.push(index)
        }
      })
    })

    // fill hiddenheaders
    headers.forEach((_, index) => {
      if (!choosenHeaders.includes(index) && !hiddenHeaders.includes(index)) {
        hiddenHeaders.push(index)
      }
    })
    // sort hidden headers
    hiddenHeaders.sort((a, b) => {
      let itemA = headers[a].translation && translateNoError(headers[a].translation)
      if (!itemA) {
        itemA = headers[a].translated || headers[a].rest
      }
      itemA = itemA.toLowerCase()

      let itemB = headers[b].translation && translateNoError(headers[b].translation)
      if (!itemB) {
        itemB = headers[b].translated || headers[b].rest
      }
      itemB = itemB.toLowerCase()

      if (itemA > itemB) {
        return 1
      }
      if (itemB > itemA) {
        return -1
      }
      return 0
    })

    this.setState({
      hiddenHeaders: hiddenHeaders,
      choosenHeaders: choosenHeaders
    })

    window.addEventListener('click', this.onWindowClick)
  }

  /**
   * @description Removes the click listener which unselects items.
   */
  componentWillUnmount = () => {
    window.removeEventListener('click', this.onWindowClick)
  }

  /**
   * @description Selects all elements between the last selected item and the item to select.
   * @param {Array} headers The headers (in this case hiddenHeaders or choosenHeaders).
   * @param {String} id The id of the header to select.
   */
  multiSelectTo = (headers, id) => {
    const updated = this.multiSelect(headers, id)

    if (updated === null) {
      return
    }

    this.setState({ selectedHeaders: updated })
  }

  /**
   * @description Unselects all headers.
   */
  unselectAll = () => {
    this.setState({
      selectedHeaders: []
    })
  }

  /**
   * @description Unselects all headers if the user clicked on the screen.
   * @param {Object} event The mouse event.
   */
  onWindowClick = (event) => {
    if (event.defaultPrevented) {
      return
    }

    this.unselectAll()
  }

  /**
   * @description The click action on the items which selects or unselects items.
   * @param {Object} event The mouse event.
   * @param {Array} headers The headers (in this case hiddenHeaders or choosenHeaders).
   * @param {String} id The id of the header which was clicked.
   */
  onClick = (event, headers, id) => {
    if (event.defaultPrevented) {
      return
    }

    if (event.button !== 0) {
      return
    }

    event.preventDefault()

    this.performAction(event, headers, id)
  }

  /**
   * @description Checks if the action is a toggle selection in group type.
   * @param {Object} event The mouse event.
   */
  isToggleSelectionInGroup = (event) => {
    const isUsingWindows = navigator.userAgent.includes('Windows')
    return isUsingWindows ? event.ctrlKey : event.metaKey
  }

  /**
   * @description Performes the select or unselect action.
   * @param {Object} event The mouse event.
   * @param {Array} headers The headers (in this case hiddenHeaders or choosenHeaders).
   * @param {String} id The id of the header which was clicked.
   */
  performAction = (event, headers, id) => {
    const action = () => {
      // add to group (ctrl was pressed)
      if (this.isToggleSelectionInGroup(event)) {
        this.toggleSelectionInGroup(id)
        return
      }

      // add all between selection
      if (event.shiftKey) {
        this.multiSelectTo(headers, id)
        return
      }

      this.toggleSelection(id)
    }

    // dont allow to group items from different lists
    if (this.state.selectedHeaders.length > 0 && !headers.includes(this.state.selectedHeaders[0])) {
      // Call delayed avoid race-condition
      this.setState({
        selectedHeaders: []
      }, () => { action() })
    } else {
      // call undelayed
      action()
    }
  }

  /**
   * @description Toggles a selection of an item.
   * @param {String} id The id of the header to select or unselect.
   */
  toggleSelection = (id) => {
    let newSelectedHeaders = []
    if (!this.state.selectedHeaders.includes(id)) {
      newSelectedHeaders = [id]
    } else if (this.state.selectedHeaders.length > 1) {
      // was part of a seleted group -> will now become the only selected item
      newSelectedHeaders = [id]
    } else {
      // was previously selected but not in a group -> will now clear the selection
      newSelectedHeaders = []
    }

    this.setState({
      selectedHeaders: newSelectedHeaders
    })
  }

  /**
   * @description Multi selects items with shift.
   * @param {Array} headers The headers (in this case hiddenHeaders or choosenHeaders).
   * @param {String} id The id of the header which was clicked.
   * @returns {Array} The new selected headers or null.
   */
  multiSelect = (headers, newId) => {
    if (!this.state.selectedHeaders.length) {
      return [newId]
    }

    const indexOfNew = headers.indexOf(newId)
    const indexOfLast = headers.indexOf(this.state.selectedHeaders[this.state.selectedHeaders.length - 1])

    if (indexOfNew === indexOfLast) {
      return null
    }

    const isSelectingForwards = indexOfNew > indexOfLast
    const start = isSelectingForwards ? indexOfLast : indexOfNew
    const end = isSelectingForwards ? indexOfNew : indexOfLast

    const inBetween = headers.slice(start, end + 1)

    // add headers which are between the last selected item and the clicked item
    // if items between are already selected do nothing
    const toAdd = inBetween.filter((id) => {
      if (this.state.selectedHeaders.includes(id)) {
        return false
      }
      return true
    })

    const combined = [...this.state.selectedHeaders, ...toAdd]

    return combined
  }

  /**
   * @description Toggles the selection in group (with ctrl).
   * @param {String} id The id of the header which was clicked.
   */
  toggleSelectionInGroup = (id) => {
    const index = this.state.selectedHeaders.indexOf(id)

    // if not selected -> add it to the selected items
    if (index === -1) {
      this.setState({ selectedHeaders: [...this.state.selectedHeaders, id] })
    } else {
      // item was previously selected -> remove it from the group
      const shallow = [...this.state.selectedHeaders]
      shallow.splice(index, 1)
      this.setState({ selectedHeaders: shallow })
    }
  }

  /**
   * @description Gets the default headers.
   * @returns {Array} The default headers.
   */
  getDefaultHeaders = () => {
    const { resultTable, headers } = this.props
    const buffer = []
    headers.forEach(entry => {
      if (resultTable) {
        buffer.push(entry.rest)
      }
      else {
        if (entry.default !== undefined && entry.default) {
          buffer.push(entry.rest)
        }
      }
    })
    return buffer
  }

  /**
   * @description Saves the configuration.
   */
  handleSave = () => {
    const { choosenHeaders, fillPage } = this.state
    const { changePrefsInRedux, onClose, showSnackbar, prefs, headers, resultTable, preferences } = this.props
    let prefsObj = {}
    const visibleHeader = []
    choosenHeaders.forEach(index => visibleHeader.push(headers[index].rest))

    if (resultTable) {
      const hiddenHeaders = []
      this.state.hiddenHeaders.forEach(index => hiddenHeaders.push(headers[index].rest))
      prefsObj = {
        TABLE_SETTINGS_CUSTOMSELECTION_RESULTTABLES: {
          ...preferences.TABLE_SETTINGS_CUSTOMSELECTION_RESULTTABLES,
          [resultTable]: {
            'fillPage': fillPage,
            'displayedHeaders': visibleHeader,
            'hiddenHeaders': hiddenHeaders
          }
        }
      }
    }
    else {
      prefsObj = {
        [prefs.key]: {
          'fillPage': fillPage,
          'displayedHeaders': visibleHeader
        }
      }
    }

    changePrefsInRedux(prefsObj)

    showSnackbar(translate('table.settings_change_success'), SnackbarActions.TYPE_SUCCESS)
    return onClose()
  }

  /**
   * @description Resets the headers to default.
   */
  handleReset = () => {
    const { headers, prefs } = this.props

    const resultTableType = Object.keys(PREFERENCE_HEADERS_MAP).find(resultTableType => prefs.key === PREFERENCE_HEADERS_MAP[resultTableType])

    const newChoosenHeaders = []
    const newAvailableHeaders = []

    headers.forEach((header, index) => {
      if (resultTableType) {
        if (DEFAULT_TABLE_HEADER_KEYS[resultTableType].includes(header.rest)) {
          newChoosenHeaders.push(index)
        } else {
          newAvailableHeaders.push(index)
        }
      } else {
        if (header.default) {
          newChoosenHeaders.push(index)
        } else {
          newAvailableHeaders.push(index)
        }
      }
    })

    newAvailableHeaders.sort((a, b) => {
      let itemA = headers[a].translation && translateNoError(headers[a].translation)
      if (!itemA) {
        itemA = headers[a].translated || headers[a].rest
      }
      itemA = itemA.toLowerCase()

      let itemB = headers[b].translation && translateNoError(headers[b].translation)
      if (!itemB) {
        itemB = headers[b].translated || headers[b].rest
      }
      itemB = itemB.toLowerCase()

      if (itemA > itemB) {
        return 1
      }
      if (itemB > itemA) {
        return -1
      }
      return 0
    })

    this.setState({ fillPage: true, choosenHeaders: newChoosenHeaders, hiddenHeaders: newAvailableHeaders })
  }

  /**
   * @description Gets the elements to render.
   * @param {Array} items The array of indices.
   * @returns {Array} The elements to render.
   */
  getElements = (items) => {
    const { headers } = this.props
    const buffer = []
    items.forEach((item, i) => {
      let header = headers[item].translation && translateNoError(headers[item].translation)
      if (!header) {
        header = headers[item].translated || headers[item].rest
      }
      return (
        buffer.push(
          [<Icon key={`usersDraggableIcon_${i}`} id={`usersDraggableIcon_${i}`} name={'drag'} />, header]
        )
      )
    })
    return buffer
  }

  /**
   * @description Reorders an item inside a list.
   * @param {Array} list The list.
   * @param {Number} startIndex The source index of the item to reorder.
   * @param {Number} endIndex The destination index.
   * @return {Array} The reordered list.
   */
  reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  /**
   * @description Reorders multiple items.
   * @param {Array} list The list.
   * @param {Number} startIndex The source start index.
   * @param {Number} endIndex The destination index.
   */
  reorderMulti = (list, startIndex, endIndex) => {
    // do nothing when startindex and endindex are equal
    if (startIndex === endIndex) {
      return list
    }

    let result = [...list]

    const insertAtIndex = (() => {
      // gets the offset of the index where to insert the items
      const destinationIndexOffset = this.state.selectedHeaders.reduce((previous, current) => {
        const index = list.indexOf(current)

        if (current === this.state.draggingHeaderId) {
          if (index > endIndex && previous > 0) {
            return previous - 1
          } else {
            return previous
          }
        }

        if (index > endIndex) {
          return previous
        }

        return previous + 1
      }, 0)
      return endIndex - destinationIndexOffset
    })()

    const orderedSelectedHeaders = [...this.state.selectedHeaders]
    orderedSelectedHeaders.sort((a, b) => {
      // this would sort the dragging item to the top
      // if(a === this.state.draggingHeaderId) {
      //   return -1
      // }
      // if(b === this.state.draggingHeaderId) {
      //   return 1
      // }

      const indexOfA = list.indexOf(a)
      const indexOfB = list.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      // sorting by their order in the selected headers
      return -1
    })

    result = result.filter((id) => {
      return !this.state.selectedHeaders.includes(id)
    })

    result.splice(insertAtIndex, 0, ...orderedSelectedHeaders)

    return result
  }

  /**
   * @description Moves an item from a list to another.
   * @param {Array} source The source list.
   * @param {Array} destination The destination list.
   * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
   * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
   * @returns {Object} An object with the new source and destination lists.
   */
  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = [...source]
    const destClone = [...destination]
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone

    return result
  }

  /**
   * @description Moves items from a list to another.
   * @param {Array} source The source list.
   * @param {Array} destination The destination list.
   * @param {Object} droppableSource The droppableSource we get from the result on onDragEnd.
   * @param {Object} droppableDestination The droppableDestination we get from the result on onDragEnd.
   * @returns {Object} An object with the new source and destination lists.
   */
  moveMulti = (source, destination, droppableSource, droppableDestination) => {
    let sourceClone = [...source]
    const destClone = [...destination]
    sourceClone = sourceClone.filter((id) => {
      return !this.state.selectedHeaders.includes(id)
    })

    const orderedSelectedHeaders = [...this.state.selectedHeaders]
    orderedSelectedHeaders.sort((a, b) => {
      // this would sort the dragging item to the top
      // if(a === this.state.draggingHeaderId) {
      //   return -1
      // }
      // if(b === this.state.draggingHeaderId) {
      //   return 1
      // }

      const indexOfA = source.indexOf(a)
      const indexOfB = source.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      // sorting by their order in the selected headers
      return -1
    })

    destClone.splice(droppableDestination.index, 0, ...orderedSelectedHeaders)

    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    result['orderedSelectedHeaders'] = orderedSelectedHeaders

    return result
  }

  /**
   * @description Sets the width and visibility properties for group dragging.
   * @param {Object} dragStart The dragStart object.
   */
  onBeforeDragStart = (dragStart) => {
    // get the id which is dragged
    let id = this.state[dragStart.source.droppableId][dragStart.source.index]
    const selected = this.state.selectedHeaders.find((headerId) => headerId === id)

    let newHeaderWidth = '0px'
    let visibility = 'hidden'

    if (selected) {
      // set the width of the grouping items based on the item which will be dragged
      if (document.querySelector(`#${dragStart.source.droppableId}_0`)) {
        newHeaderWidth = `${document.querySelector(`#${dragStart.source.droppableId}_0`).getBoundingClientRect().width}px`
        visibility = 'visible'
      }
      this.setState({ draggingHeaderId: id })
    } else {
      // if draggin an item that is not selected - unselect all items
      this.setState({ draggingHeaderId: id, selectedHeaders: [] })
    }

    document.querySelector(':root').style.setProperty('--header-width', newHeaderWidth)
    document.querySelector(':root').style.setProperty('--header-visibility', visibility)
  }

  /**
   * @description The drag end action from dnd.
   * @param {Object} result The result from dnd.
   */
  onDragEnd = (result) => {
    // multi drag
    if (this.state.selectedHeaders.length > 1) {
      this.multiDrag(result)
    } else {
      // single drag
      this.singleDrag(result)
    }
  }

  /**
   * @description Performes the single drag action.
   * @param {Object} result Includes the new lists.
   */
  singleDrag = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      this.setState({ draggingHeaderId: null })
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorder(
        this.state[source.droppableId],
        source.index,
        destination.index
      )

      this.setState({
        [source.droppableId]: items,
        draggingHeaderId: null
      })
      // move if the dnd was between different lists
    } else {
      const result = this.move(
        this.state[source.droppableId],
        this.state[destination.droppableId],
        source,
        destination
      )

      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState({
        [source.droppableId]: result[source.droppableId],
        [destination.droppableId]: result[destination.droppableId],
        draggingHeaderId: null
      })
    }
  }

  /**
   * @description Performes the multi drag action.
   * @param {Object} result Includes the new lists.
   */
  multiDrag = (result) => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      this.setState({ draggingHeaderId: null })
      return
    }

    // reorder if the dnd was on the same list
    if (source.droppableId === destination.droppableId) {
      const items = this.reorderMulti(
        this.state[source.droppableId],
        source.index,
        destination.index
      )

      this.setState({
        [source.droppableId]: items,
        draggingHeaderId: null
      })
      // move if the dnd was between different lists
    } else {
      const result = this.moveMulti(
        this.state[source.droppableId],
        this.state[destination.droppableId],
        source,
        destination
      )

      // remove and add operations were done on clones
      // set the new lists (source and destination) to the state
      this.setState({
        [source.droppableId]: result[source.droppableId],
        [destination.droppableId]: result[destination.droppableId],
        draggingHeaderId: null,
        selectedHeaders: result['orderedSelectedHeaders']
      })
    }
  }

  /**
   * @description Gets the item style for the element we drag.
   * @param {Object} draggableStyle The current style which we get from Draggable (provided)
   * @param {Object} snapshot The snapshot which we get from Draggable.
   * @returns {Object} The item style.
   */
  getItemStyle = (draggableStyle, snapshot) => {
    // add margin when to bottom on dnd
    if (!snapshot.isDropAnimating) {
      return {
        ...draggableStyle,
        margin: '0 0 8px 0',
      }
    }

    // enable width transition (this is important if we want to dnd between containers that have different item widths)
    if (snapshot.draggingOver) {
      const containerSize = document.getElementById(snapshot.draggingOver).offsetWidth
      const { moveTo } = snapshot.dropAnimation

      const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`

      return {
        ...draggableStyle,
        width: `${containerSize}px`,
        transform: `${translate}`,
        transition: '330ms'
      }
    }

    // fallback
    return {
      ...draggableStyle,
      margin: '0 0 8px 0',
    }
  }

  render = () => {
    const { fillPage, choosenHeaders, hiddenHeaders, selectedHeaders, draggingHeaderId } = this.state
    const { id, onClose, headers } = this.props

    const hiddenHeaderElements = this.getElements(hiddenHeaders)
    const draggableElements = this.getElements(choosenHeaders)

    return (
      <>
        <Modal onClose={onClose}
          id='table_settings'
          className={'bux_UserProfileModal bux_no_user_select'}>
          <Header
            id={`${id}_modalHeader`}
            title={translate('table.change_table_settings')}
            onClose={onClose} />
          <Main id={id}>
            <Card>
              {/* enables the dnd functionality */}
              <DragDropContext
                onBeforeDragStart={this.onBeforeDragStart}
                onDragEnd={this.onDragEnd}>
                <Row>
                  <Column colMD={6}>
                    <Switch
                      id={`${id}_view`}
                      title={translate('table.view')}
                      items={[
                        translate('table.settings_fill_screen'),
                        translate('table.settings_500_per_page')
                      ]}
                      onClick={index => this.setState({ fillPage: index === 0 })}
                      activeIndex={fillPage ? 0 : 1}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column colMD={6}>
                    {/* available columns */}
                    <label className={'headerListTitle'}>{translate('table.settings_available_columns')}</label>
                    <div className={'headersDraggableContainer'}>
                      {/* makes list droppable */}
                      <Droppable droppableId={'hiddenHeaders'}>
                        {(provided) => (
                          <ul ref={provided.innerRef} id={'hiddenHeaders'}>
                            {hiddenHeaderElements.map((item, index) => {
                              return (
                                // makes items draggable
                                <Draggable key={`hiddenHeaders_${index}`} draggableId={`hiddenHeaders_${index}`} index={index}>
                                  {(provided, snapshot) => {
                                    const isSelected = selectedHeaders.includes(hiddenHeaders[index])
                                    const isGhosting = isSelected && draggingHeaderId && draggingHeaderId !== hiddenHeaders[index]
                                    const shouldShowSelection = snapshot.isDragging && selectedHeaders.length > 1

                                    // change visible item to the first selected item when group dragging
                                    if (shouldShowSelection) {
                                      let untranslatedItem = headers[hiddenHeaders[hiddenHeaders.indexOf(selectedHeaders[0])]]
                                      untranslatedItem = untranslatedItem.translation ? untranslatedItem.translation : untranslatedItem.rest
                                      item[1] = translateNoError(untranslatedItem)
                                    }
                                    return (
                                      <li
                                        id={`hiddenHeaders_${index}`}
                                        className={shouldShowSelection ? 'header_grouping' : ''}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={(event) => this.onClick(event, hiddenHeaders, hiddenHeaders[index])}
                                        style={this.getItemStyle(
                                          provided.draggableProps.style,
                                          snapshot)
                                        }>
                                        <div className={`headersDraggableElementsContainer
                                      ${isSelected ? ' headerSelected' : ''}
                                      ${isGhosting ? ' headerGhosting' : ''}`}>
                                          {item}
                                        </div>
                                        {shouldShowSelection && (
                                          <div className='selectionCount'>
                                            {selectedHeaders.length}
                                          </div>
                                        )}
                                      </li>
                                    )
                                  }}
                                </Draggable>
                              )
                            })}
                            {/* render placeholder important for dnd */}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </div>
                  </Column>
                  <Column colMD={6}>
                    {/* choosen columns */}
                    <label className={'headerListTitle'}>{translate('table.settings_column_position')}</label>
                    <div className={'headersDraggableContainer'}>
                      {/* makes list droppable */}
                      <Droppable droppableId={'choosenHeaders'}>
                        {(provided) => (
                          <ul ref={provided.innerRef} id={'choosenHeaders'}>
                            {draggableElements.map((item, index) => {
                              return (
                                // makes items draggable
                                <Draggable key={`choosenHeaders_${index}`} draggableId={`choosenHeaders_${index}`} index={index}>
                                  {(provided, snapshot) => {
                                    const isSelected = selectedHeaders.includes(choosenHeaders[index])
                                    const isGhosting = isSelected && draggingHeaderId && draggingHeaderId !== choosenHeaders[index]
                                    const shouldShowSelection = snapshot.isDragging && selectedHeaders.length > 1

                                    // change visible item to the first selected item when group dragging
                                    if (shouldShowSelection) {
                                      let untranslatedItem = headers[choosenHeaders[choosenHeaders.indexOf(selectedHeaders[0])]]
                                      untranslatedItem = untranslatedItem.translation ? untranslatedItem.translation : untranslatedItem.rest
                                      item[1] = translateNoError(untranslatedItem)
                                    }
                                    return (
                                      <li
                                        id={`choosenHeaders_${index}`}
                                        className={shouldShowSelection ? 'header_grouping' : ''}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={(event) => this.onClick(event, choosenHeaders, choosenHeaders[index])}
                                        style={this.getItemStyle(
                                          provided.draggableProps.style,
                                          snapshot)
                                        }>
                                        <div className={`headersDraggableElementsContainer
                                      ${isSelected ? ' headerSelected' : ''}
                                      ${isGhosting ? ' headerGhosting' : ''}`}>
                                          {item}
                                        </div>
                                        {shouldShowSelection && (
                                          <div className='selectionCount'>
                                            {this.state.selectedHeaders.length}
                                          </div>
                                        )}
                                      </li>
                                    )
                                  }}
                                </Draggable>
                              )
                            })}
                            {/* render placeholder */}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </div>
                  </Column>
                </Row>
              </DragDropContext>
            </Card>
          </Main>
          <Footer id={id}>
            <Button
              id={'tablesettings_cancelbtn'}
              text={translate('general.cancel')}
              onClick={onClose}
            />
            <Button
              id={'tablesettings_defaultbtn'}
              text={translate('general.default')}
              onClick={this.handleReset}
            />
            <Button
              id={'tablesettings_savebtn'}
              text={translate('general.save')}
              onClick={this.handleSave}
              primary
              submit
            />
          </Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    preferences: state.auth.serverdata.preferences
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changePrefsInRedux: prefs => {
      PreferencesActions.changePrefs(prefs)(dispatch)
    },
    showSnackbar: (message, type) => {
      SnackbarActions.show(message, type)(dispatch)
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableSettings)