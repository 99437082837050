import React, { useEffect, useRef } from 'react';
import { DocumentBrowserHexSelectionContext } from '../contexts/DocumentBrowserHexSelectionContext';

import * as ATTRIBUTES from '../constants/attributes';

import { useTabContentContext } from '../contexts/TabContentContext';

import * as DOC_VIEWER_UTILS from '../helper/utils';

import * as TYPES_DOC_VIEWER from '../types/DocumentViewer.types';

export const DocumentBrowserHexSelectionProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { cacheKey } = useTabContentContext() as TYPES_DOC_VIEWER.ITabContentContext;

  const selectedHexRefBlock = useRef<HTMLElement | null>(null); // Block mode
  const selectedTextRefBlock = useRef<HTMLElement | null>(null); // Block mode

  const selectedHex1RefLine = useRef<HTMLElement | null>(null); // Line mode
  const selectedHex2RefLine = useRef<HTMLElement | null>(null); // Line mode
  const selectedHex3RefLine = useRef<HTMLElement | null>(null); // Line mode
  const selectedTextRefLine = useRef<HTMLElement | null>(null); // Line mode

  const addHighlightBlock = () => {
    selectedHexRefBlock.current?.classList.add(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);
    selectedTextRefBlock.current?.classList.add(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);
  };

  const addHighlightLine = () => {
    selectedTextRefLine.current?.classList.add(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);
    selectedHex1RefLine.current?.classList.add(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);
    selectedHex2RefLine.current?.classList.add(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);
    selectedHex3RefLine.current?.classList.add(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);
  };

  const removeHighlightBlock = () => {
    selectedHexRefBlock.current?.classList.remove(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);
    selectedTextRefBlock.current?.classList.remove(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);

    selectedHexRefBlock.current = null;
    selectedTextRefBlock.current = null;
  };

  const removeHighlightLine = () => {
    selectedTextRefLine.current?.classList.remove(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);
    selectedHex1RefLine.current?.classList.remove(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);
    selectedHex2RefLine.current?.classList.remove(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);
    selectedHex3RefLine.current?.classList.remove(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT);

    selectedTextRefLine.current = null;
    selectedHex1RefLine.current = null;
    selectedHex2RefLine.current = null;
    selectedHex3RefLine.current = null;
  };

  const handleSelectMixed = (event: React.MouseEvent<HTMLElement> | Event) => {
    const parentId = (event.target as Element).getAttribute && (event.target as Element).getAttribute('id');

    if (parentId?.includes(ATTRIBUTES.ID_HEX_BLOCK_SEGMENT)) { removeHighlightLine(); return handleSelectBlock(event); }
    if (parentId?.includes(ATTRIBUTES.ID_HEX_LINE_SEGMENT)) { removeHighlightBlock(); return handleSelectLine(event); }

    removeHighlightBlock();
    removeHighlightLine();
  };

  const handleSelectBlock = (event: React.MouseEvent<HTMLElement> | Event) => {
    if (!isTabVisible()) return; // Guard: Stop event listener call on rendered, but inactive tab to propagate (needed due to the way tabs in <Tabs> are created)

    const parentId = (event.target as Element).getAttribute && (event.target as Element).getAttribute('id');
    if (!parentId || !parentId?.includes(ATTRIBUTES.ID_HEX_BLOCK_SEGMENT)) return removeHighlightBlock(); // Handle unselect on irrelevant element (click away)
    if ((event.target as Element).classList.contains(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT)) return removeHighlightBlock(); // Handle unselect on click on same block

    const match = parentId.match(/\d+/g);
    if (!match) return removeHighlightBlock(); // Handle unselect on flawed element ID

    const isHexNode = parentId.includes('chunk');

    const { hexSegmentIndex, lineIndex, hex8DigitIndex, chunkIndex, textIndex } = isHexNode ? DOC_VIEWER_UTILS.getMatchIndicesHexToText(match) : DOC_VIEWER_UTILS.getMatchIndicesTextToHex(match);
    removeHighlightBlock();

    selectedHexRefBlock.current = isHexNode ? (event.target as HTMLElement) : document.getElementById(`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${hexSegmentIndex}_line${lineIndex}_hex${hex8DigitIndex}_chunk${chunkIndex}_${cacheKey}`);
    selectedTextRefBlock.current = !isHexNode ? (event.target as HTMLElement) : document.getElementById(`${ATTRIBUTES.ID_HEX_BLOCK_SEGMENT}${hexSegmentIndex}_line${lineIndex}_text${textIndex}_${cacheKey}`);

    addHighlightBlock();
  };

  const handleSelectLine = (event: React.MouseEvent<HTMLElement> | Event) => {
    if (!isTabVisible()) return; // Guard: Stop event listener call on rendered, but inactive tab to propagate (needed due to the way tabs in <Tabs> are created)

    const parentId = (event.target as Element).getAttribute && (event.target as Element).getAttribute('id');

    if (!parentId || !parentId?.includes(ATTRIBUTES.ID_HEX_LINE_SEGMENT)) return removeHighlightLine(); // Handle unselect on irrelevant element (click away)
    if ((event.target as Element).classList.contains(ATTRIBUTES.CSS_CLASS_BROWSER_CONTENT_HIGHLIGHT)) return removeHighlightLine(); // Handle unselect on click on same block

    const match = parentId.match(/\d+/g);
    if (!match) return removeHighlightLine(); // Handle unselect on flawed element ID

    const { hexSegmentIndex, lineIndex, textIndex } = DOC_VIEWER_UTILS.getMatchIndicesLineHex(match);

    removeHighlightLine();

    // A 5th HEX line per segment appears for some document types (e.g. .afp). The 1st line usually is an informative line without context to the actual HEX codes:
    const has5thHexLine = document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${hexSegmentIndex}_line${4}_text${textIndex}_${cacheKey}`) !== null;

    if (!has5thHexLine) {
      selectedTextRefLine.current = lineIndex === 0 ? (event.target as HTMLElement) : document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${hexSegmentIndex}_line${0}_text${textIndex}_${cacheKey}`);
      selectedHex1RefLine.current = lineIndex === 1 ? (event.target as HTMLElement) : document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${hexSegmentIndex}_line${1}_text${textIndex}_${cacheKey}`);
      selectedHex2RefLine.current = lineIndex === 2 ? (event.target as HTMLElement) : document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${hexSegmentIndex}_line${2}_text${textIndex}_${cacheKey}`);
      selectedHex3RefLine.current = lineIndex === 3 ? (event.target as HTMLElement) : document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${hexSegmentIndex}_line${3}_text${textIndex}_${cacheKey}`);
    } else {
      if (lineIndex === 0) return removeHighlightLine(); // Handle click on unrelated info line

      selectedTextRefLine.current = lineIndex === 1 ? (event.target as HTMLElement) : document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${hexSegmentIndex}_line${1}_text${textIndex}_${cacheKey}`);
      selectedHex1RefLine.current = lineIndex === 2 ? (event.target as HTMLElement) : document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${hexSegmentIndex}_line${2}_text${textIndex}_${cacheKey}`);
      selectedHex2RefLine.current = lineIndex === 3 ? (event.target as HTMLElement) : document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${hexSegmentIndex}_line${3}_text${textIndex}_${cacheKey}`);
      selectedHex3RefLine.current = lineIndex === 4 ? (event.target as HTMLElement) : document.getElementById(`${ATTRIBUTES.ID_HEX_LINE_SEGMENT}${hexSegmentIndex}_line${4}_text${textIndex}_${cacheKey}`);
    }

    addHighlightLine();
  };

  const isTabVisible = (): boolean => {
    const tabContainerElement = document.getElementById(`${ATTRIBUTES.ID_HEX_CONTAINER}_${cacheKey}`)?.closest(`.${ATTRIBUTES.CSS_CLASS_TAB_CONTENT_CONTAINER}`);
    if (!tabContainerElement) return false;

    return (tabContainerElement as HTMLElement).style.display !== 'none';
  };

  // Case: Handling unselect if user clicks away from HEX area
  useEffect(() => {
    if (!cacheKey) return;

    cacheKey.includes('hex_block') && !cacheKey.includes('hex_line') && document.getElementById(ATTRIBUTES.ID_TAB_CONTENT_WRAPPER)?.addEventListener('click', handleSelectBlock);
    !cacheKey.includes('hex_block') && cacheKey.includes('hex_line') && document.getElementById(ATTRIBUTES.ID_TAB_CONTENT_WRAPPER)?.addEventListener('click', handleSelectMixed);

    return () => {
      cacheKey.includes('hex_block') && !cacheKey.includes('hex_line') && document.getElementById(ATTRIBUTES.ID_TAB_CONTENT_WRAPPER)?.removeEventListener('click', handleSelectBlock);
      !cacheKey.includes('hex_block') && cacheKey.includes('hex_line') && document.getElementById(ATTRIBUTES.ID_TAB_CONTENT_WRAPPER)?.removeEventListener('click', handleSelectMixed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheKey]);

  return (
    <DocumentBrowserHexSelectionContext.Provider value={{}}>
      {children}
    </DocumentBrowserHexSelectionContext.Provider>
  )
};