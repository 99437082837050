import React, { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

import './DocumentViewer.scss';

import Header from 'components/header/Header';
import { Tab } from './components/Tab';
import { Tabs } from './components/Tabs';

import * as ATTRIBUTES from './constants/attributes';

import { isEqualDocViewerTabsState } from './helper/equalityChecks';
import { useCompareIndexToHighlight } from './helper/hooks';
import { getTabTitleIndex } from './helper/utils';

import { TabContentProvider } from './provider/TabContentProvider';

import { isValidUserSession } from 'redux/actions/AuthenticationActions';
import * as DocViewerActions from 'redux/actions/DocViewerActions';

import * as TYPES_DOC_VIEWER from './types/DocumentViewer.types';

import { documentViewerBroadcastChannel as channel, MESSAGE_CLOSE_ORPHAN_DOCVIEWER } from 'utils/BroadcastChannelUtils';
import { SEARCH_TYPE_STANDARDSELECTION_SIDEFILE } from 'utils/DocViewerUtils';

/**
 * @description This channel listener will close the current window in the specified case.
 * This will become necessary when the main app refreshes and the window object reference is lost.
 * When this happens, then the window can no longer be accessed by the main app. In this function the window will be re-initialized via window.open,
 * so that the window can be closed even in case browser settings disallow scripts to close windows (e.g. Firefox -> about:config -> dom.allow_scripts_to_close_windows).
 * @param event Broadcast Channel event
 */
channel.onmessage = (event) => {
  if (event.data.includes(MESSAGE_CLOSE_ORPHAN_DOCVIEWER)) {
    const docviewer = window.open(window.location.toString(), '_self');
    docviewer?.close();
  }
};

export const DocumentViewer = () => {
  const dispatch = useDispatch();

  const auth: TYPES_DOC_VIEWER.IAuth = useSelector((state: RootStateOrAny) => state.auth);
  const docViewerTabs: TYPES_DOC_VIEWER.ITabData[] = useSelector((state: RootStateOrAny) => state.docviewer.tabs, (oldState: TYPES_DOC_VIEWER.ITabData[], newState: TYPES_DOC_VIEWER.ITabData[]) => isEqualDocViewerTabsState(oldState, newState))

  const activeTabIndex = useCompareIndexToHighlight(docViewerTabs); // This is used to determine if a tab should be selected/focused based upon changes of the index data

  /**
   * @description This function will dispatch the action to remove documents by its ID from the 'docviewer' state.\
   * It handles 3 separate cases of user input:\
   * a) Closing 1 tab -> array contains 1 index of tab-bar navigation\
   * b) Closing all tabs -> array contains all indices of tab-bar navigation\
   * c) Closing selected tabs -> array contains multiple indices of tab-bar navigation (currently not possible to use CTRL+Click selection on tab-bar)\
   * @param tabIndices Indices of the tabs in the tab-bar navigation, which will be closed
   */
  const handleTabsClose = (tabIndices: number[]) => {
    const documentIdsToRemove = tabIndices.map((index: number) => docViewerTabs[index].id);
    DocViewerActions.removeDocumentsFromDocViewer(documentIdsToRemove)(dispatch);
  };

  /**
   * @description This function will validate if the current user has a valid token by making a GET usermanagement/user request and
   * triggers a logout if the response indicates a failed request.
   */
  const validateUserSession = () => {
    isValidUserSession(auth.userid, auth.serverdata.server.type, auth.serverdata.server.version, {})(dispatch);
  };

  useEffect(() => {
    validateUserSession();
    // Note: useEffect with empty array to trigger on mount/unmount -> gets misinterpreted by ESLint due to function ref
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={ATTRIBUTES.COMPONENT_ID}>
      <Header
        id={`${ATTRIBUTES.COMPONENT_ID}_header`}
        loggedIn={auth.loggedIn ?? false}
        minimal
        history
      />
      <Tabs
        id={'BrowserTab'}
        activeTabIndex={activeTabIndex}
        onClose={handleTabsClose}
        enableConfirmationDialog
      >
        {docViewerTabs.map(({ id, type, data }: TYPES_DOC_VIEWER.ITabData) => {
          //* WIP: BADM-2183
          const tabTitleIndex = getTabTitleIndex(type);
          const defaultTitle = type !== SEARCH_TYPE_STANDARDSELECTION_SIDEFILE ? data[tabTitleIndex] : `Sidefile - ${data[tabTitleIndex]}`
          const title = defaultTitle && defaultTitle.trim() ? defaultTitle : `No title - ${id}`
          //*

          return (
            <Tab
              key={id}
              /* //! TBC - BADM-2183 */
              title={title}
              content={<TabContentProvider id={id} />}
            />
          )
        })}
      </Tabs>
    </div>
  );
};