import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import './SearchResultPagesDocuments.scss'

import { translate } from 'language/Language'
import * as DateUtils from 'utils/DateUtils'

import { BarChart, LineChart, NoSearch, ResultContainer } from 'BetaUX2Web-Components/src/'
import StatisticMenu from 'components/search_result/statistic/statistic_menu/StatisticMenu'

import * as StatisticActions from 'redux/actions/StatisticActions'
import * as Preferences from 'redux/general/Preferences'

class SearchResultPagesDocuments extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired
  }

  /**
   * @description refreshes the statistic dialog with the dates from last search
   */
  handleOnRefresh = () => {
    const { preferences } = this.props
    const fromDate = preferences.STATISTIC_FROMDATE
    const toDate = preferences.STATISTIC_TODATE
    const unit = preferences.STATISTIC_UNIT
    this.props.getBsaStats(fromDate, toDate, unit)
  }

  /**
   * @description returns the searched date for the statistic menu
   */
  dateOutput = () => {
    let { preferences, datemask } = this.props
    if (datemask === undefined) {
      datemask = DateUtils.MMDDYYYY_SLASH
    }
    const fromDate = DateUtils.getDate(datemask, preferences.STATISTIC_FROMDATE)
    const toDate = preferences.STATISTIC_TODATE !== '' ? DateUtils.getDate(datemask, preferences.STATISTIC_TODATE) : DateUtils.formatDate(Date.now(), datemask)
    return {
      from: fromDate,
      to: toDate
    }
  }

  /**
   * @description Gets the translated header for the downloaded csv file
   */
  getStatisticHeader = () => {
    return [
      translate('statistic.bsastats_date'),
      translate('statistic.bsastats_pages_in'),
      translate('statistic.bsastats_pages_out'),
      translate('statistic.bsastats_pages_archived'),
      translate('statistic.bsastats_documents_total'),
      translate('statistic.bsastats_documents_indexed'),
    ]
  }

  /**
   * @description formats the data in a new array to display the charts
   * @param {array} datasToFormat The data from bsaStats that should be formatted
   * @param {String} bsaStatType statistic type: pages or documents
   */
  formatData = (datasToFormat, bsaStatType) => {
    const data = []
    datasToFormat.forEach(dataToFormat => {
      if (bsaStatType === 'pages') {
        data.push([dataToFormat[0], dataToFormat[1], dataToFormat[2], dataToFormat[3]])
      }
      else if (bsaStatType === 'documents') {
        data.push([dataToFormat[0], dataToFormat[4], dataToFormat[5]])
      }
      else {
        data.push([dataToFormat[0], dataToFormat[1], dataToFormat[2], dataToFormat[3], dataToFormat[4], dataToFormat[5]])
      }
    })
    return data
  }

  /**
   * @description gets the keys which are needed to output the labels on the charts
   * @param {array} header header of the fetched data
   * @param {String} bsaStatType statistic type: pages or documents
   */
  getKeys = (header, bsaStatType) => {
    let keys = []
    if (bsaStatType === 'pages') {
      keys.push(header[1], header[2], header[3])
    }
    else if (bsaStatType === 'documents') {
      keys.push(header[4], header[5])
    }
    keys = this.translateKeys(keys)
    return keys
  }

  /**
   * @description translates the keys which are used in the legend
   * @param {array} keys the keys which should translated
   */
  translateKeys = keys => {
    const { lang } = this.props
    const translatedKeys = []
    keys.map(key => {
      switch (key) {
        case keys[keys.indexOf('PAGES_IN')]:
          return translatedKeys.push(translate('statistic.pages_in', lang))
        case keys[keys.indexOf('PAGES_OUT')]:
          return translatedKeys.push(translate('statistic.pages_out', lang))
        case keys[keys.indexOf('PAGES_ARCHIVED')]:
          return translatedKeys.push(translate('statistic.pages_archived', lang))
        case keys[keys.indexOf('DOCUMENTS_TOTAL')]:
          return translatedKeys.push(translate('statistic.documents_total', lang))
        case keys[keys.indexOf('DOCUMENTS_INDEXED')]:
          return translatedKeys.push(translate('statistic.documents_indexed', lang))
        default: console.error(`Key <${key}> cannot get translated!`); return null
      }
    })
    return translatedKeys
  }

  /**
   * @description chooses the charts based on chosen charttype in the Chart type input boxes
   * @param {String} preference from redux state to display chart for pages or documents
   * @param {String} bsaStatType statistic type: pages or documents
   * @param {String} id the id for the specific chart
   */
  chooseChart = (preference, bsaStatType, id) => {
    const { bsaStats, drawerExpanded, datemask, lang } = this.props
    const data = this.formatData(bsaStats.data, bsaStatType)
    if (preference === 'linechart') {
      return <LineChart
        id={id}
        keys={this.getKeys(bsaStats.header, bsaStatType)}
        data={data}
        colors={['#0068b4', '#66ccc9', '#00386e', '#4e5463', '#80bb3d', '#ffc159']}
        curveStyle={'monotonex'}
        dotHover={'vertical'}
        dotStyle={'circle'}
        yAxisScale={'linear'}
        axis={'show'}
        drawerExpanded={drawerExpanded}
        dateFormat={datemask}
        language={lang}
      />
    } else if (preference === 'barchart_vert') {
      return <BarChart
        id={id}
        keys={this.getKeys(bsaStats.header, bsaStatType)}
        data={data}
        barPadding={0.1}
        direction={'vertical'}
        barHover={'grouped'}
        showGrid={true}
        colors={['#0068b4', '#66ccc9', '#00386e', '#4e5463', '#80bb3d', '#ffc159']}
        legend={'top'}
        drawerExpanded={drawerExpanded}
        dateFormat={datemask}
        language={lang}
      />
    } else {
      return <BarChart
        id={id}
        keys={this.getKeys(bsaStats.header, bsaStatType)}
        data={data}
        barPadding={0.1}
        direction={'horizontal'}
        barHover={'grouped'}
        showGrid={true}
        colors={['#0068b4', '#66ccc9', '#00386e', '#4e5463', '#80bb3d', '#ffc159']}
        legend={'left'}
        drawerExpanded={drawerExpanded}
        dateFormat={datemask}
        language={lang}
      />
    }
  }

  /**
   * @description renders the charts with statistic menu if there are data
   */
  getBody = () => {
    const { id, bsaStats, preferences } = this.props
    return (
      bsaStats?.data
        ? (
          <div className={'statistics_container'}>
            <StatisticMenu
              onRefreshClick={this.handleOnRefresh}
              dateOutput={this.dateOutput()}
              header={this.getStatisticHeader}
              bsaStats={this.formatData(bsaStats.data)}
              id={`${id}_statistic_menu`}
            />
            <div className={'chart_container'} style={{ height: 'calc(100% - 50px)' }}>
              <div id={'chart1_container'}>
                {this.chooseChart(preferences.STATISTIC_PAGES_CHART, 'pages', '1')}
              </div>
              <div id={'chart2_container'}>
                {this.chooseChart(preferences.STATISTIC_DOCUMENTS_CHART, 'documents', '2')}
              </div>
            </div>
          </div>
        )
        : (
          <NoSearch
            id={`${id}_nosearch`}
            message={translate('nosearch.description')}
          />
        )
    )
  }

  render = () => {
    const { drawerExpanded, autoDrawer } = this.props
    return (
      <ResultContainer
        drawerExpanded={drawerExpanded}
        autoDrawer={autoDrawer}>
        {this.getBody()}
      </ResultContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    bsaStats: state.statistic.bsastats,
    usertoken: state.auth.serverdata.token,
    preferences: state.auth.serverdata.preferences,
    datemask: state.auth.serverdata.preferences[Preferences.DATEMASK],
    lang: state.auth.serverdata.preferences[Preferences.LANGUAGE],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getBsaStats: (fromdate, todate, unit) => {
      StatisticActions.getBsaStats(fromdate, todate, unit)(dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultPagesDocuments)