import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'BetaUX2Web-Components/src';

import * as TYPES_DRAWER_NAVBAR_BUTTON from '../../types/DrawerNavbarButton.types';
import { translate } from 'language/Language';

export const DrawerNavbarButton = (props: TYPES_DRAWER_NAVBAR_BUTTON.IPropsDrawerNavBarButton) => {
  const [showHoverMenu, setShowHoverMenu] = useState(false);

  return (
    <div
      title={props.isDisabled ? translate('documentviewer.hex_view_disabled') : props.title}
      id={props.id}
      className='drawer_nav_item'
      onClick={props.onAutoDrawer && (() => !props.isDisabled && props.onAutoDrawer())}
      onMouseEnter={() => !props.isDisabled && setShowHoverMenu(true)}
      onMouseLeave={() => !props.isDisabled && setShowHoverMenu(false)}>
      <Link
        title={props.isDisabled ? translate('documentviewer.hex_view_disabled') : props.title}
        id={`${props.id}_link`}
        to={props.link}>
        <button
          disabled={props.isDisabled}
          id={`${props.id}_link_button`}
          className={`nav_button drawer_nav_button ${props.selected ? 'selected' : ''} ${showHoverMenu ? 'nav_selected' : ''}`}>
          <Icon id={props.id} name={props.icon} disabled={props.isDisabled}/>
        </button>
      </Link>
      {
        showHoverMenu && (
          <div
            id={`${props.id}_nav_menu`}
            className='drawer_nav_menu'>
            <Link
              id={`${props.id}_nav_menu_titlelink`}
              to={props.link}
              className='drawer_nav_menu_headline'>
              {props.title}
            </Link>
            {props.links.map((link: { [key: string]: string }, index: number) =>
              <Link
                id={`${props.id}_links_${index}`}
                key={index}
                to={link.link}
                onMouseLeave={e => e.currentTarget.blur()}>
                <div className={'bux_title_link_container'} onClick={props.onAutoDrawer && (() => props.onAutoDrawer())}>
                  {link.title}
                </div>
              </Link>
            )}
          </div>
        )
      }
    </div>
  )
};