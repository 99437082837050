import React, { ReactNode } from 'react';
import Icon from '../../icon/Icon';
import { DropdownData, DropdownDataset, isANode, isDropdownDatasetType } from '../Dropdown.types';
import { prepareClassNames } from '../../../utils/ComponentUtils';


interface DropdownItemProps {
  /** Unique ID for identification in HTML DOM.*/
  id: string;
  /** Content of the dropdown item, it could be text or an array */
  item: DropdownData;
  /** Function to be called on an click event.*/
  onClick?: (event: React.MouseEvent) => void;
  /** Indicates if item is selected or not*/
  selected?: boolean;
  /** Tab index */
  tabIndex?: number;
  /** React ref */
  scrollRef?: React.RefObject<HTMLLIElement>;
  /** Style class from CSS for styling for component.*/
  className?: string;
}


export const DropdownItem = (props: DropdownItemProps): JSX.Element => {
  const text = isDropdownDatasetType(props.item) ? (props.item as DropdownDataset).text : props.item as (ReactNode | string);
  const icon = isDropdownDatasetType(props.item) ? (props.item as DropdownDataset).icon : undefined;
  const stringText = (typeof props.item === 'string') ? props.item : (isDropdownDatasetType(props.item) ? (props.item as DropdownDataset).text : undefined);

  const classNames = prepareClassNames([
    props.selected ? 'selected ' : '',
    'menu_item',
    props.className,
    isANode(props.item) && 'menu_item_node'
  ]);

  return (
    <li
      id={props.id}
      tabIndex={props.tabIndex}
      ref={props.scrollRef}
      onClick={props.onClick}
      className={`${classNames}`}>
      {icon && <Icon id="menu_item_icon" name={icon}/>}
      <span title={stringText}>{text}</span>
    </li>
  );
};