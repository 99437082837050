import * as FolderDocumentAssignmentActions from '../actions/FolderDocumentAssignmentAction'
import * as AuthActions from '../actions/AuthenticationActions'

const defaultState = {
  folderDocumentAssignment: undefined,
  folderDocumentAssignments: undefined,
  documents: undefined,
  keepPagination: undefined
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    /* ********************************************************************************** */
    // Definition get folder document assignments actions
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_GET_ASSIGNMENTS_START:
      return { ...state }
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_GET_ASSIGNMENTS_SUCCESS:
      return { ...state, folderDocumentAssignments: action.payload, keepPagination: action.keepPagination }
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_GET_ASSIGNMENTS_FAILED:
      return { ...state }
    case FolderDocumentAssignmentActions.NO_FOLDER_DOCUMENT_ASSIGNMENTS_FOUND:
      return { ...state, folderDocumentAssignments: {} }
    /* ********************************************************************************** */
    // Definition get folder document assignment actions
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_GET_ASSIGNMENT_START:
      return { ...state, folderDocumentAssignment: undefined }
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_GET_ASSIGNMENT_SUCCESS:
      return { ...state, folderDocumentAssignment: action.payload }
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_GET_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition create folder document assignment actions
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_CREATE_ASSIGNMENT_START:
      return { ...state }
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_CREATE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_CREATE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition delete folder document assignment actions
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_DELETE_ASSIGNMENT_START:
      return { ...state }
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_DELETE_ASSIGNMENT_SUCCESS:
      return { ...state }
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_DELETE_ASSIGNMENT_FAILED:
      return { ...state }
    /* ********************************************************************************** */
    // Definition get documents actions
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_GET_DOCUMENTS_START:
      return { ...state }
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_GET_DOCUMENTS_SUCCESS:
      return { ...state, documents: action.payload.data }
    case FolderDocumentAssignmentActions.FOLDER_DOCUMENT_GET_DOCUMENTS_FAILED:
      return { ...state }
    case FolderDocumentAssignmentActions.NO_FOLDER_DOCUMENT_DOCUMENTS_FOUND:
      return { ...state, documents: {} }
    /* ********************************************************************************** */
    // Logout actions
    case AuthActions.LOGOUT_SUCCESS:
      // reset data of this reducer on logout
      return { ...state, folderDocumentAssignment: undefined, folderDocumentAssignments: undefined }
    /* ********************************************************************************** */
    default: return state
  }
}

export default reducer