import React, { Component } from 'react'
import Icon from '../../../icon/Icon'
import PropTypes from 'prop-types'

import './TableButtonGroupGroup.scss'

export default class TableButtonGroupGroup extends Component {
  static propTypes = {
    /** Unique ID for identification in HTML DOM.*/
    id: PropTypes.string.isRequired,
    /** Function to be called on an click event.*/
    onClick: PropTypes.func,
    /** Text to be displayed.*/
    text: PropTypes.string,
    /** Icon as a jsx element to be displayed in component.*/
    icon: PropTypes.element,
    /** Text to be displayed in tooltip when you hover component.*/
    title: PropTypes.string,
    /** Disables this component. Default: false. */
    disabled: PropTypes.bool
  }

  state = {
    hover: false,
    hoverSub: false,
    right: 0
  }

  componentDidMount = () => {
    const el = document.querySelector(`#${this.props.id}`)
    const width = el?.getBoundingClientRect().width
    this.setState({ right: width - 2 })
  }

  showMain = () => {
    if (!this.state.hover) {
      this.setState({ hover: true })
    }
  }

  showSub = () => {
    if (!this.state.hoverSub) {
      this.setState({ hoverSub: true })
    }
  }

  hideMain = () => {
    if (this.state.hover) {
      this.setState({ hover: false })
    }
  }

  hideSub = () => {
    if (this.state.hoverSub) {
      this.setState({ hoverSub: false })
    }
  }

  render = () => {
    const { id, text, title = text, children } = this.props
    const { hover, hoverSub, right } = this.state
    return (
      <>
        {
          (hover || hoverSub) &&
          <div className={'bux_more_vert_sub_menu'} onMouseEnter={this.showSub} onMouseMove={this.showSub} onMouseLeave={this.hideSub} style={{ right }}>
            {children}
          </div>
        }
        <li id={id} tabIndex={0} title={title} className={'table_button_group_group'} onMouseMove={this.showMain} onMouseLeave={this.hideMain}>
          <Icon id={id} name={'chevron_left_small'} />
          {text}
        </li>
      </>
    )
  }
}